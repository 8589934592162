import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { space, typography } from 'styled-system';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import {
    QOEUserDropLogger,
    userDropCases,
} from 'utils/constants/qoeLogConstants';
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        this.setState(error);
        console.error(error, errorInfo);

        if (getAirmeetUtilInstance()) {
            QOEUserDropLogger(userDropCases.BOUNDRY_ERROR.label);
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <Container>
                    <Text fontSize={5}>Something went wrong</Text>
                    <Text fontSize={5}>Please reload and try again</Text>
                    <Button
                        mt={3}
                        className='btn btn-primary'
                        onClick={() => window.location.reload()}
                    >
                        Reload
                    </Button>
                </Container>
            );
        }
        return this.props.children;
    }
}
const Container = styled.div`
    height: 100vh;
    width: 30%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: center;
    align-items: center;
    justify-content: center;
`;
const Text = styled.p`
    ${typography}
`;
const Button = styled.button`
    ${space}
`;
const mapStateToProps = (state) => ({
    auth: state.auth,
});
export default connect(mapStateToProps)(ErrorBoundary);
