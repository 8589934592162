import { getMembersInContextPath } from 'hooks/live-airmeet/useUserPresence';
import { CONTEXT_TYPE } from 'hooks/attendance/constants';
import { PRESENCE_ACTION_TYPE } from 'utils/constants/featureNames';

const presenceWriter = ({ airmeetId, client, payload, metaData }) => {
    const userId = metaData?.userId;
    const contextId = metaData?.contextId;
    const context = metaData?.context;

    if (!userId || !contextId || !context) {
        throw new Error('Invalid user presence firebase key');
    }

    const usersPresencePath = getMembersInContextPath(
        airmeetId,
        contextId,
        context
    );
    const usersPresenceKey = `${usersPresencePath}/${userId}`;
    const currentUserPresenceKey = `${airmeetId}/userPresence/${userId}`;
    const liveCounterPath = `${airmeetId}/liveCounter/${context}/${contextId}`;

    const presenceTasks = [];
    presenceTasks.push(client.setDataAsync(usersPresenceKey, payload));

    let userCurrentLocation = null;

    client.replaceOnDisconnect(usersPresenceKey, null);
    client.replaceOnDisconnect(currentUserPresenceKey, null);

    const isNotCloudUser = payload?.metaData?.user_type !== 'cloud_user';

    // don't add onDisconnect on liveCounter or increase counter if user is cloud_user
    if (isNotCloudUser) {
        client.replaceOnDisconnect(liveCounterPath, client.getIncrementRef(-1));
    }

    if (
        metaData?.actionType === PRESENCE_ACTION_TYPE.LEAVE &&
        metaData?.context !== CONTEXT_TYPE.EVENT
    ) {
        userCurrentLocation = {
            contextId: airmeetId,
            context: CONTEXT_TYPE.EVENT,
        };

        if (isNotCloudUser) {
            presenceTasks.push(
                client.setDataAsync(liveCounterPath, client.getIncrementRef(-1))
            );
            client.cancelOnDisconnect(liveCounterPath);
        }
    }

    if (payload) {
        userCurrentLocation = {
            contextId,
            context,
        };
    }

    presenceTasks.push(
        client.setDataAsync(currentUserPresenceKey, userCurrentLocation)
    );

    if (metaData?.actionType === PRESENCE_ACTION_TYPE.JOIN) {
        if (isNotCloudUser) {
            presenceTasks.push(
                client.setDataAsync(
                    `${airmeetId}/liveCounter/${context}/${contextId}`,
                    client.getIncrementRef(1)
                )
            );
        }
    }

    return Promise.all(presenceTasks);
};

export default presenceWriter;
