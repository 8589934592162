import { useEffect, useState } from 'react';

const useCloudHostLiveState = ({
    airmeetId,
    currentSession,
    firebaseClient,
    userKey = 'cloud_user_id', // cloud_user_id/recording_user_id
}) => {
    const [isOnline, setOnlneStatus] = useState(false);
    const cloudUserId = currentSession?.[userKey];
    const clouserPath = cloudUserId
        ? `${airmeetId}/onlineUsers/${cloudUserId}`
        : null;

    useEffect(() => {
        if (!firebaseClient) return;
        if (!currentSession) setOnlneStatus(false);

        if (!clouserPath) {
            return;
        }
        const emitter = firebaseClient.getEmitter();

        const updateOnlineState = ({ value }) => {
            setOnlneStatus(!!value);
        };

        emitter.on(`${clouserPath}_value`, updateOnlineState);
        firebaseClient.getDataRef(clouserPath);
        return () => {
            emitter.off(`${clouserPath}_value`, updateOnlineState);
        };
    }, [clouserPath, firebaseClient]);

    return isOnline;
};

export default useCloudHostLiveState;
