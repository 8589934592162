import { CALL_API } from '../api';

export const FILE_UPLOADER_REQUEST = 'FILE_UPLOADER_REQUEST';
export const FILE_UPLOADER_RESPONSE = 'FILE_UPLOADER_RESPONSE';
export const FILE_UPLOADER_REMOVE_FROM_UPLOADER =
    'FILE_UPLOADER_REMOVE_FROM_UPLOADER';
export const FILE_UPLOADER_STATUS_CHANGE = 'FILE_UPLOADER_STATUS_CHANGE';
export const FILE_UPLOADER_STATUS_RESET = 'FILE_UPLOADER_STATUS_RESET';

export function uplaodFile(requestFileId, file, imageType = 'airmeet') {
    const data = new FormData();
    data.append('image', file);
    data.append('imageType', imageType);
    return {
        [CALL_API]: {
            types: [FILE_UPLOADER_REQUEST, FILE_UPLOADER_RESPONSE],
            endpoint: '/uploadImage',
            method: 'POST',
            body: data,
            transform: (response) => {
                return {
                    file: {
                        path: response.imagePath,
                        url: response.imageURL,
                        ...response,
                    },
                    requestFileId,
                };
            },
        },
    };
}

export function uploadBase64Image(
    requestFileId,
    imgBase64,
    imgPrefixPath = 'airmeet'
) {
    return {
        [CALL_API]: {
            types: [FILE_UPLOADER_REQUEST, FILE_UPLOADER_RESPONSE],
            endpoint: `/uploadBase64Image`,
            method: 'POST',
            type: 'json',
            body: {
                imgBase64,
                imgPrefixPath,
            },
            transform: (response) => {
                return {
                    file: {
                        path: response.imagePath,
                        url: response.imageURL,
                        ...response,
                    },
                    requestFileId,
                };
            },
        },
    };
}

export function removeFromFileUploader(requestFileId, defaultFile = '') {
    return {
        payload: { requestFileId, defaultFile },
        type: FILE_UPLOADER_REMOVE_FROM_UPLOADER,
    };
}

export function changeFileUploadStatus(requestFileId, uploadStatus) {
    return {
        type: FILE_UPLOADER_STATUS_CHANGE,
        payload: { requestFileId, uploadStatus },
    };
}

export function resetFileUploadStatus(requestFileId) {
    return {
        type: FILE_UPLOADER_STATUS_RESET,
        payload: { requestFileId },
    };
}
