import queryString from 'querystring';
import React, { useEffect, useState } from 'react';
import useFooter from '../../hooks/useFooter';
import useHeader from '../../hooks/useHeader';
import { HEADER_TEMPLATES } from '../header/templates';
import { APPLE_AUTH_ERROR_MESSAGE } from './utils';

function OAuthPopup({ defaultState = 'fdsf78fyds7fm' }) {
    const [error, setError] = useState('');
    const [validation, setValidation] = useState(defaultState);
    const [token, setToken] = useState('');
    const [source, setSource] = useState('');
    const [method, setMethod] = useState('');

    // Disable the header, and footer
    useHeader(HEADER_TEMPLATES.none);
    useFooter({ visible: false });

    useEffect(() => {
        const qs = window.location.search || '?';
        const params = queryString.parse(qs.substring(1));
        if (params.state) {
            // Validation state for preventing CSRF
            setValidation(params.state);
        }

        // Token, or bust
        if (params.method) {
            setToken(params.token);
            setSource(params.source);
            setMethod(params.method);
        } else {
            setError(params.error || 'Login failed. Please try again.');
        }
    }, []);

    useEffect(() => {
        if (window.opener) {
            // Inform the opener of either the token, or error
            window.opener.postMessage(
                {
                    [token ? 'token' : 'error']: token ? token : error,
                    source,
                    method,
                    state: validation,
                },
                window.location.origin
            );
        }
        //Close tab if user cancelled login
        if (
            !!token ||
            error === 'user_cancelled_login' ||
            error === APPLE_AUTH_ERROR_MESSAGE
        ) {
            window.close();
        }
    }, [error, token]);

    return error && error !== APPLE_AUTH_ERROR_MESSAGE ? (
        <div className='login-error'>
            <div>{error}</div>
            <div>
                <button onClick={() => window.close()}>
                    Close this window
                </button>
            </div>
        </div>
    ) : null;
}

export default OAuthPopup;
