import TabsV2 from 'atoms/Tabs/TabsV2';
import { FREE_ATTENDEE, FREE_PLAN } from 'hooks/metered-pricing/config';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ElementSettings from './ElementSettings/';
import ThemeSettings from './ThemeSettings';

export default function CustomizeBranding({
    isConference,
    handleChange,
    community_id,
    theme_info,
    setShowPageOverlay,
    live_config,
    logEvent,
}) {
    const subscriptionData = useSelector(
        (state) => state.Community.subscriptionData
    );
    const myCommunity = useSelector((state) => state.Community.myCommunity);

    const isPaidSubscription = useMemo(() => {
        if (!isConference) {
            if (subscriptionData?.subscription_type) {
                if (
                    ![FREE_PLAN, FREE_ATTENDEE].includes(
                        subscriptionData?.subscription_type
                    )
                ) {
                    return true;
                }
            } else {
                // Get subscription_type for meetup for em/cm
                let currentCommunity = null;

                currentCommunity = (myCommunity?.em || []).find(
                    (comm) => comm.communityId === community_id
                );
                if (!currentCommunity) {
                    currentCommunity = (myCommunity?.cm || []).find(
                        (comm) => comm.communityId === community_id
                    );
                }

                if (
                    currentCommunity?.subscription_type &&
                    ![FREE_PLAN, FREE_ATTENDEE].includes(
                        currentCommunity?.subscription_type
                    )
                ) {
                    return true;
                }
            }
            return false;
        }
    }, [myCommunity, community_id, isConference, subscriptionData]);

    //TODO: Improve this hack
    const handleEventBrandingChange = (type, value, prop, props) => {
        if (handleChange) {
            const liveConfig = { ...cloneDeep(live_config || {}) };

            if (prop) {
                liveConfig.eventBranding = {
                    ...(live_config?.eventBranding || {}),
                };
                liveConfig.eventBranding[prop] = value;
            } else if (isObject(props) && !isEmpty(props)) {
                liveConfig.eventBranding = {
                    ...(live_config?.eventBranding || {}),
                    ...props,
                };
            }
            handleChange(type, liveConfig);
        }
    };

    return isConference || isPaidSubscription ? (
        <TabsV2
            tabs={[
                {
                    id: 'theme',
                    label: 'Theme settings',
                    content: (
                        <ThemeSettings
                            theme_info={theme_info}
                            handleChange={handleChange}
                            isConference={isConference}
                            isPaidSubscription={isPaidSubscription}
                            setShowPageOverlay={setShowPageOverlay}
                            live_config={live_config}
                            handleEventBrandingChange={
                                handleEventBrandingChange
                            }
                            logEvent={logEvent}
                        />
                    ),
                },
                {
                    id: 'element',
                    label: 'Element styles',
                    content: (
                        <ElementSettings
                            live_config={live_config}
                            onChange={handleEventBrandingChange}
                            theme_info={theme_info}
                        />
                    ),
                },
            ]}
        />
    ) : (
        <ThemeSettings
            theme_info={theme_info}
            handleChange={handleChange}
            isConference={isConference}
            isPaidSubscription={isPaidSubscription}
            setShowPageOverlay={setShowPageOverlay}
            live_config={live_config}
            handleEventBrandingChange={handleEventBrandingChange}
        />
    );
}
