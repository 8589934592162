import {
    Box,
    FlexDiv,
    UnstyledButton,
} from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text/';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Radio = ({
    value,
    ariaLabel,
    id,
    name,
    label = '',
    disabled,
    onClick,
    isChecked,
    showLabel = false,
    children,
    ...props
}) => {
    const [checked, setChecked] = useState(isChecked);
    const onRadioClick = () => {
        if (disabled) {
            return;
        }
        const currVal = !checked;
        onClick && onClick({ name, id, value: currVal });
    };
    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);

    return (
        <RadioGroupRoot className='RadioGroupRoot' name={name} {...props}>
            <RadioGroupItem
                type='button'
                onClick={onRadioClick}
                role='radio option'
                mr={label && '6px'}
                disabled={disabled}
                aria-checked={checked}
                aria-label={`Radio Option Select ${label}`}
                checked={checked}
                id={id}
            >
                {checked && (
                    <Box size='12px' bg='brandDefault' borderRadius='50%' />
                )}
            </RadioGroupItem>
            {showLabel && label ? (
                <Label className='LabelRoot' htmlFor={id}>
                    <Text variant='body2'>{label}</Text>
                </Label>
            ) : children ? (
                <Label className='LabelRoot' htmlFor={id}>
                    {children}
                </Label>
            ) : null}
        </RadioGroupRoot>
    );
};

export default Radio;

const RadioGroupRoot = styled(FlexDiv)`
    .LabelRoot {
        color: ${({ theme }) => theme.colors.text.default.primary};
        margin-left: ${({ theme }) => theme.space.x2};
    }
    &[data-disabled] {
        opacity: 0.5;
    }
`;

const RadioGroupItem = styled(UnstyledButton)`
    background-color: ${({ theme }) => theme.colors.fill.default3};
    border: 2px solid
        ${({ theme, checked }) =>
            checked ? theme.colors.brandDefault : theme.colors.border.other1};
    border-radius: 100%;
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        outline: 2px solid ${({ theme }) => theme.colors.fill.default1};
        box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.border.other1};
    }
    &:focus {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.border.other1};
    }
    &[data-state='checked'] {
        border-color: ${({ theme }) => theme.colors.brandDefault};
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        outline: none;
        box-shadow: none;
    }
`;

const Label = styled.label`
    flex: 1;
`;
