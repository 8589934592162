import Box from 'atoms/Box';
import { UnstyledButton } from 'components/molecules/utils/basicComponents';
import SVGIcon from 'foundations/icon';
import { Typography } from 'foundations/typography';
import keys from 'locale/keys';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';
import { s3toCDNImage } from 'utils/core';
import {
    changeFileUploadStatus,
    removeFromFileUploader,
    resetFileUploadStatus,
    uplaodFile,
} from '../../../store/actions/fileUplaoder';
import { FILE_UPLOAD_ERROR_CODES } from './utils';

const stylePreviewObject = {
    maxHeight: '80%',
    maxWidth: '80%',
};

export const FILE_UPLOAD_STATUS = {
    UPLOADING: 'UPLOADING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

const API_MAX_IN_MB = 10;
const BYTES_IN_MB = 1024 * 1024;
const BOOTH_POSTER_REQUEST_KEY = 'boothPosterUrl';
class FileUploader extends Component {
    constructor(props) {
        super(props);
        const size =
            props.size > API_MAX_IN_MB && !props.isPDF
                ? API_MAX_IN_MB
                : props.size;
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onRemoveHandler = this.onRemoveHandler.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.modalHandler = this.modalHandler.bind(this);
        this.onChangeClickHandler = this.onChangeClickHandler.bind(this);
        this.resetSelectedFileData = this.resetSelectedFileData.bind(this);
        this.onClose = this.onClose.bind(this);
        this.fileInputRef = React.createRef();
        this.state = {
            selectedFile: null,
            loaded: props.fileURL ? 1 : -1,
            fileURL: props.fileURL,
            errors: null,
            size: (size || API_MAX_IN_MB) * BYTES_IN_MB,
            uploadSize: null,
            fileName: '',
            showImg: true,
            removeImg: props.existingImg ? true : false,
            preLoadedFileURL: props.existingImg,
            removeImgHandler: props.removeImgHandler || null,
            customError: props.customError || false,
            errorMsg: props.errorMsg || null,
            hasFilter: props.hasFilter || false,
            isSVG: false,
            format: props.formats || [
                'image/png',
                'image/jpeg',
                'image/webp',
                'image/gif',
                'image/svg+xml',
            ],
            removeText: props.removeText || props.t(keys.GENERAL_CLEAR),
            deleteIcon: props.deleteIcon || false,
        };
    }

    componentDidUpdate(oldProps) {
        const { props } = this;
        if (props.fileURL !== oldProps.fileURL) {
            this.setState({
                loaded: 1,
                fileURL: props?.fileURL,
            });
        }

        if (this.props.showUploadModal && !isEqual(oldProps, props)) {
            this.props.setIsInEditMode(
                ((this.props.existingImg && !this.props.showImageSize) ||
                    !this.props.defaultImageUploaded) &&
                    this.props.showUploadModal
            );
        }
    }

    componentDidMount() {
        const file = this.props.existingImg;
        if (this.state.preLoadedFileURL) {
            let uploadedFormat = file
                .slice(((file.lastIndexOf('.') - 1) >>> 0) + 2)
                .toUpperCase();
            if (uploadedFormat === 'SVG') {
                this.setState({
                    isSVG: true,
                });
            }
        }
        if (this.props.existingImg) {
            this.setState({
                selectedFile: file,
                loaded: 1,
                fileURL: this.props.existingImg,
            });
        }

        if (this.props.showUploadModal) {
            this.props.setIsInEditMode(
                ((this.props.existingImg && !this.props.showImageSize) ||
                    !this.props.defaultImageUploaded) &&
                    this.props.showUploadModal
            );
        }
    }

    componentWillUnmount() {
        const { requestFileId, dispatch } = this.props;
        if (this.state.loaded) {
            dispatch(removeFromFileUploader(requestFileId));
        }
        dispatch(resetFileUploadStatus(requestFileId));
    }

    closeModal() {
        const close = this.props.closeModal;
        if (isFunction(close)) {
            return close();
        }
        return null;
    }

    checkUploadedFiles = (event) => {
        let files = event.target.files;
        let size = this.state.size;
        let errors = []; // create empty array
        const types = this.state.format;
        for (let x = 0; x < files.length; x++) {
            let uploadedFormat = files[x].name
                .slice(((files[x].name.lastIndexOf('.') - 1) >>> 0) + 2)
                .toUpperCase();
            if (types.every((type) => files[x].type !== type)) {
                errors.push({
                    code: FILE_UPLOAD_ERROR_CODES.UNSUPPORTED_FORMAT,
                    message: uploadedFormat + ' is not a supported format\n',
                    meta: { format: uploadedFormat },
                });
            }
            if (files[x].size > size) {
                errors.push({
                    code: FILE_UPLOAD_ERROR_CODES.FILE_SIZE_EXCEEDED,
                    message:
                        'The uploaded file is over ' + size / 1024 + 'kb\n',
                    meta: { size },
                });
            }
            if (uploadedFormat === 'SVG') {
                this.setState({
                    isSVG: true,
                });
            } else {
                this.setState({
                    isSVG: false,
                });
            }
        }
        if (errors.length > 0) {
            event.target.value = null;
            return { status: false, errors };
        }

        return { status: true, errors: null, errorCodes: null };
    };

    resetSelectedFileData = () => {
        this.setState({
            selectedFile: null,
            loaded: 1,
            errors: null,
            fileName: '',
            uploadSize: null,
            removeImg: false,
        });
    };

    onRemoveHandler = () => {
        const {
            dispatch,
            requestFileId,
            defaultImage,
            isImageUploaded,
            showUploadModal,
        } = this.props;
        const { selectedFile, removeImg, loaded } = this.state;

        if (
            showUploadModal &&
            selectedFile instanceof File &&
            removeImg &&
            !loaded
        ) {
            this.resetSelectedFileData();
        } else {
            this.setState({
                showImg: defaultImage || false,
                removeImg: false,
                preLoadedFileURL: '',
                isSVG: false,
            });
            dispatch(removeFromFileUploader(requestFileId));
            if (this.state.removeImgHandler) {
                this.props.removeImgHandler(isImageUploaded, requestFileId);
            }
        }
        if (this.props?.showUploadModal) {
            this.closeModal();
        }
        this.clearInputValue();
    };
    clearInputValue = () => {
        this.fileInputRef.current.value = ''; // to clear the input value.
    };
    onChangeClickHandler = () => {
        this.clearInputValue();
        this.fileInputRef.current.click();
        this.closeModal();
    };
    uploadFile = (file) => {
        const {
            requestFileId,
            dispatch,
            filePrefix,
            isProfileImage,
        } = this.props;
        dispatch(
            uplaodFile(requestFileId, file, filePrefix, isProfileImage)
        ).then((data) => {
            if (data.error) {
                changeFileUploadStatus(requestFileId, FILE_UPLOAD_STATUS.ERROR);
                let errMessage =
                    data?.payload?.json?.message ||
                    this.props.t(keys.GENERAL_SOMETHING_WENT_WRONG);
                this.setState({
                    selectedFile: null,
                    loaded: 0,
                    errors: errMessage,
                });
                dispatch(
                    changeFileUploadStatus(
                        requestFileId,
                        FILE_UPLOAD_STATUS.ERROR
                    )
                );
                if (this.props.onError) {
                    this.props.onError([
                        {
                            code: FILE_UPLOAD_ERROR_CODES.GENERAL,
                            message: errMessage,
                        },
                    ]);
                }
                if (this.props.setPicErr) this.props.setPicErr(errMessage);
                return;
            }
            this.props.successUploadHandler &&
                this.props.successUploadHandler(
                    requestFileId === BOOTH_POSTER_REQUEST_KEY
                        ? {
                              ...data?.payload,
                              fileName: file.name,
                          }
                        : data?.payload,
                    file
                );
            requestFileId === BOOTH_POSTER_REQUEST_KEY &&
                this.props.getFileInfo(file);

            if (this.props?.showUploadModal) {
                if (this.state.selectedFile instanceof File) {
                    resetFileUploadStatus();
                }
                this.closeModal();
            }
        });
    };

    modalHandler = (file) => {
        const { errors, fileURL, selectedFile } = this.state;
        if (this.props?.showUploadModal) {
            const propsData = {
                img: file || fileURL || selectedFile,
                changeImg: this.onChangeClickHandler,
                removeImg: this.onRemoveHandler,
                uploadImg: this.uploadFile,
                onClose: this.onClose,
                minCropBoxHeight: this.props?.minCropBoxHeight,
                minCropBoxWidth: this.props?.minCropBoxWidth,
            };
            this.props.handleUploadModal(propsData);
        } else if (errors !== null) {
            this.closeModal();
        }
    };

    onClose = () => {
        const { existingImg } = this.props;
        this.clearInputValue();
        this.setState({
            selectedFile: existingImg,
            loaded: 1,
            fileURL: existingImg,
        });
    };

    onChangeHandler = (event) => {
        const { requestFileId, dispatch, existingImg, fadeError } = this.props;
        if (!this.props?.showUploadModal) {
            dispatch(
                changeFileUploadStatus(
                    requestFileId,
                    FILE_UPLOAD_STATUS.UPLOADING
                )
            );
        }
        const uploadStatus = this.checkUploadedFiles(event);
        if (!uploadStatus.status) {
            this.setState({
                selectedFile: null,
                loaded: 0,
                errors: uploadStatus?.errors[0]?.message,
            });
            if (this.props.onError) {
                this.props.onError(uploadStatus?.errors);
            }
            if (this.props.setPicErr) {
                this.props.setPicErr(uploadStatus?.errors[0]?.message);
            }
            dispatch(
                changeFileUploadStatus(requestFileId, FILE_UPLOAD_STATUS.ERROR)
            );
            if (fadeError) {
                setTimeout(() => {
                    this.setState({
                        selectedFile: existingImg,
                        loaded: 1,
                        errors: null,
                    });
                    dispatch(
                        changeFileUploadStatus(
                            requestFileId,
                            FILE_UPLOAD_STATUS.SUCCESS
                        )
                    );
                }, 3000);
            }
            return false;
        }
        const file = event.target.files[0];
        this.setState({
            selectedFile: file || existingImg,
            loaded: 0,
            errors: null,
            fileName: file.name,
            uploadSize: (file.size / 1024).toFixed(2) + 'kB',
        });
        if (this.props.removeLogo) {
            this.setState({
                removeImg: true,
                showImg: true,
            });
        }
        this.props.setPicErr && this.props.setPicErr(null);
        if (this.props.getRequiredCalback) {
            this.props.getRequiredCalback({
                changeImg: this.onChangeClickHandler,
                removeImg: this.onRemoveHandler,
                uploadImg: this.uploadFile,
                onClose: this.onClose,
            });
        }
        // Skip image cropper for GIF
        const isGif =
            typeof file?.name === 'string' && file?.name.endsWith('.gif');
        if (!this.props?.showUploadModal || isGif) {
            this.uploadFile(file);
        }
        if (!isGif) {
            this.modalHandler(file);
        }
    };

    render() {
        const {
            fileURL: uplaodFileUrl,
            existingImg,
            customImageStyles = {},
        } = this.props;
        const {
            loaded,
            errors,
            fileName,
            uploadSize,
            removeImg,
            showImg,
            fileURL,
            errorMsg,
            customError,
            hasFilter,
            isSVG,
            removeText,
            deleteIcon,
        } = this.state;
        const imageStyling = { ...stylePreviewObject, ...customImageStyles };

        const Logo = () => (
            <SVG src={uplaodFileUrl || fileURL} style={imageStyling} />
        );
        const renderImagePreview = () => {
            const {
                showImageSize,
                imageType,
                defaultImageUploaded,
                isCustomUploader,
                showCustomIcons = false,
                getCustomIcons = noop(),
                showUploadModal,
                removeBtnAria = 'Remove uploaded file',
                isShowEditImage = true,
            } = this.props;
            return (
                <>
                    {removeImg && showImageSize && !showCustomIcons && (
                        <div className='upload-details'>
                            <span>
                                {fileName}, {uploadSize}
                            </span>
                            <UnstyledButton
                                className={`delete-upload ${
                                    deleteIcon && 'delete-upload-icon'
                                }`}
                                onClick={this.onRemoveHandler}
                                aria-label={removeBtnAria}
                            >
                                {deleteIcon ? (
                                    <SVGIcon
                                        icon='delete'
                                        width='14px'
                                        height='14px'
                                        fill='semantic.1'
                                    />
                                ) : (
                                    removeText
                                )}
                            </UnstyledButton>
                        </div>
                    )}

                    {((removeImg && !showImageSize) || !defaultImageUploaded) &&
                        !showCustomIcons &&
                        (!showUploadModal || !isShowEditImage ? (
                            isCustomUploader ? (
                                <div className='custom-uploader-wrapper'>
                                    <div
                                        className='remove-logo'
                                        onClick={this.onRemoveHandler}
                                    >
                                        <Typography
                                            color={'lighting.6'}
                                            variant={'subtext2'}
                                        >
                                            Remove
                                        </Typography>
                                    </div>
                                    <div className='change-img'>
                                        <Typography
                                            color={'ambience.24'}
                                            variant={'subtext2'}
                                        >
                                            Change
                                        </Typography>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className='remove-logo'
                                    onClick={this.onRemoveHandler}
                                >
                                    <i className='fa fa-trash'></i>
                                    <label>Remove {imageType}</label>
                                </div>
                            )
                        ) : (
                            <PlaceholderButton
                                onClick={() => this.modalHandler()}
                                width='100%'
                                height='100% '
                                className='remove-logo'
                            >
                                <SVGIcon
                                    icon='edit'
                                    width='20px !important'
                                    height='20px !important'
                                    fill='white'
                                />
                                <label>Edit</label>
                            </PlaceholderButton>
                        ))}
                    {!this.props.hidePreview && (
                        <div
                            className={
                                showImg
                                    ? 'image-preview'
                                    : 'image-preview d-none'
                            }
                        >
                            {hasFilter && isSVG ? (
                                Logo()
                            ) : (
                                <img
                                    src={
                                        uplaodFileUrl || fileURL || existingImg
                                    }
                                    alt='imagePreview'
                                    style={imageStyling}
                                />
                            )}
                            {!showCustomIcons && (
                                <div className='edit-upload'>
                                    <SVGIcon
                                        icon='edit'
                                        width='16px'
                                        height='16px'
                                        fill='white'
                                        className='d-none'
                                    />
                                </div>
                            )}
                            {showCustomIcons &&
                                getCustomIcons({
                                    onRemoveHandler: this.onRemoveHandler,
                                })}
                        </div>
                    )}
                </>
            );
        };
        return (
            <div>
                <input
                    type='file'
                    name='file'
                    title=''
                    onChange={this.onChangeHandler}
                    accept={this.state.format.join(', ')}
                    ref={this.fileInputRef}
                    id={this.props.id}
                    aria-label={this.props.ariaLabel || 'upload file'}
                />
                {loaded === 1 &&
                    (uplaodFileUrl || fileURL || existingImg) &&
                    renderImagePreview()}
                {!customError ? (
                    errorMsg ? (
                        <p className='m-0 pb-1 text-center text-danger'>
                            {errors !== null && errorMsg}
                        </p>
                    ) : (
                        <p className='m-0 pb-1 text-center text-danger'>
                            {errors !== null && errors}
                        </p>
                    )
                ) : null}
            </div>
        );
    }
}
const mapStateToProps = (store, props) => {
    const createAirmeetMasterImageUrl =
        store.FileUploader.files[props.requestFileId];
    const fileURL = createAirmeetMasterImageUrl
        ? createAirmeetMasterImageUrl.url
        : props.imageUrl
        ? props.imageUrl
        : '';
    const isImageUploaded = store.FileUploader.files[props.requestFileId] || '';
    return { ...props, fileURL: s3toCDNImage(fileURL), isImageUploaded };
};
export default connect(mapStateToProps)(FileUploader);

const PlaceholderButton = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;

    &.remove-logo {
        bottom: 0px !important;
    }

    & label {
        font-size: 15px;
        line-height: 18px;
        color: ${({ theme }) => `${theme.colors.ambience[0]}`};
        padding-left: 6px;
        font-weight: 700;
        margin: 0;
    }
`;
