import {
    useContainerRoute,
    useContainerStore,
} from 'atoms/Container/container';
import { useCallback } from 'react';

function useContainerDeepLocation() {
    const {
        state: { rootLocation },
        setState,
    } = useContainerStore();

    const { route, setRoute } = useContainerRoute();

    const setDeepLocation = useCallback(
        (root, path = '', targetContainerId = null) => {
            setState('rootLocation', root, targetContainerId);
            setRoute(path, targetContainerId);
        },
        [setState, setRoute]
    );

    return {
        route,
        setRoute,
        rootLocation,
        setDeepLocation,
    };
}

export default useContainerDeepLocation;
