import Btn from 'atoms/Button/btn';
import SVGSprite, { StyledSvgSprite } from 'components/general/SVGSprite';
import {
    accessibilityStyles,
    cursor,
    FlexDiv,
    UnstyledButton,
} from 'components/molecules/utils/basicComponents';
import { alpha } from 'foundations/theme';
import { Typography } from 'foundations/typography';
import animationData from 'lotties/threeDotsLoader.json';
import React from 'react';
import Lottie from 'react-lottie';
import styled, { css } from 'styled-components';
import {
    background,
    border,
    color,
    flexbox,
    layout,
    space,
} from 'styled-system';
import { select } from 'utils/constants/common';
import { cdnImage } from 'utils/core';
import { IS_CUSTOM_TENANT } from 'utils/tenant';

const noop = () => {};

const Button = React.forwardRef((props, ref) => {
    const {
        onClick = noop,
        children,
        style = {},
        variant = '',
        icon,
        iconStyle,
        type = '',
        disabled,
        provider,
        className,
        width,
        loading = false,
        loaderHeight = '100%',
        loaderWidth = '60px',
        state,
        typography,
        btnTextStyles = {},
        overrideOpacity = false,
        id = null,
        color,
        iconWrapStyle = {},
        addTypography = false,
        tooltipStyles = {},
        iconFill = '',
        buttonBackground,
        isThemeV2 = false,
        ...rest
    } = props || {};
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    if (isThemeV2) {
        return <Btn {...props} />;
    }

    return (
        <>
            {variant === 'primary' && (
                <ButtonPrimary
                    varient={variant}
                    onClick={!loading ? onClick : undefined}
                    style={{ ...style }}
                    type={type}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    state={state}
                    width={width}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    ref={ref}
                    loading={loading ? 1 : 0}
                    buttonBackground={buttonBackground}
                    {...rest}
                >
                    {loading ? (
                        <span role='status' aria-label='loading'>
                            <span aria-hidden='true'>
                                <Lottie
                                    options={defaultOptions}
                                    height={loaderHeight}
                                    width={loaderWidth}
                                    isClickToPauseDisabled={true}
                                />
                            </span>
                        </span>
                    ) : (
                        <>
                            {icon && (
                                <IcoWrap pr={2} style={iconWrapStyle || {}}>
                                    <SVGSprite
                                        icon={icon}
                                        width='30px'
                                        height='30px'
                                        style={iconStyle ? iconStyle : {}}
                                        aria-hidden='true'
                                    />
                                </IcoWrap>
                            )}
                            <StyledText
                                variant={typography ? typography : 'button1'}
                                color={color}
                                disabled={disabled}
                                {...btnTextStyles}
                            >
                                {children}
                            </StyledText>
                        </>
                    )}
                </ButtonPrimary>
            )}
            {variant === 'outline' && (
                <ButtonOutline
                    varient={variant}
                    onClick={onClick}
                    style={{ ...style }}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    typographyColor={color}
                    {...rest}
                >
                    {icon && (
                        <IcoWrap pr={2}>
                            <SVGSprite
                                icon={icon}
                                width='30px'
                                height='30px'
                                style={iconStyle ? iconStyle : {}}
                            />
                        </IcoWrap>
                    )}
                    {addTypography && (
                        <Typography
                            variant={typography ? typography : 'button1'}
                        >
                            {children}
                        </Typography>
                    )}
                    {!addTypography && children}
                </ButtonOutline>
            )}
            {variant === 'outlinegray' && (
                <ButtonOutlineGray
                    varient={variant}
                    onClick={!loading ? onClick : undefined}
                    style={{ ...style }}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    {...rest}
                >
                    {loading ? (
                        <Lottie
                            options={defaultOptions}
                            height={loaderHeight}
                            width={loaderWidth}
                            isClickToPauseDisabled={true}
                        />
                    ) : (
                        <>
                            {icon && (
                                <IcoWrap pr={2}>
                                    <SVGSprite
                                        icon={icon}
                                        width='30px'
                                        height='30px'
                                        style={iconStyle ? iconStyle : {}}
                                    />
                                </IcoWrap>
                            )}
                            {children}
                        </>
                    )}
                </ButtonOutlineGray>
            )}
            {variant === 'outlinewhite' && (
                <ButtonOutlineWhite
                    varient={variant}
                    onClick={onClick}
                    style={{ ...style }}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    width={width}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    {...rest}
                >
                    {icon && (
                        <IcoWrap pr={2}>
                            <SVGSprite
                                icon={icon}
                                width='30px'
                                height='30px'
                                style={iconStyle ? iconStyle : {}}
                            />
                        </IcoWrap>
                    )}
                    {children}
                </ButtonOutlineWhite>
            )}
            {variant === 'selected' && (
                <ButtonSelected
                    varient={variant}
                    onClick={onClick}
                    style={{ ...style }}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    {...rest}
                >
                    {icon && (
                        <IcoWrap pr={2}>
                            <SVGSprite
                                icon={icon}
                                width='30px'
                                height='30px'
                                style={iconStyle ? iconStyle : {}}
                            />
                        </IcoWrap>
                    )}
                    {children}
                </ButtonSelected>
            )}
            {variant === 'white' && (
                <ButtonWhite
                    varient={variant}
                    onClick={onClick}
                    style={{ ...style }}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    width={width}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    {...rest}
                >
                    {icon && (
                        <IcoWrap pr={2}>
                            <SVGSprite
                                icon={icon}
                                width='30px'
                                height='30px'
                                style={iconStyle ? iconStyle : {}}
                            />
                        </IcoWrap>
                    )}
                    <Typography variant={typography ? typography : 'button1'}>
                        {children}
                    </Typography>
                </ButtonWhite>
            )}
            {variant === 'yellow' && (
                <ButtonYellow
                    varient={variant}
                    onClick={onClick}
                    style={{ ...style }}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    width={width}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    {...rest}
                >
                    {icon && (
                        <IcoWrap pr={2}>
                            <SVGSprite
                                icon={icon}
                                width='30px'
                                height='30px'
                                style={iconStyle ? iconStyle : {}}
                            />
                        </IcoWrap>
                    )}
                    <Typography variant={typography ? typography : 'button1'}>
                        {children}
                    </Typography>
                </ButtonYellow>
            )}
            {variant === 'danger' && (
                <ButtonDanger
                    varient={variant}
                    onClick={onClick}
                    style={{ ...style }}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    width={width}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    {...rest}
                >
                    <Typography
                        variant={typography ? typography : 'button1'}
                        style={{ ...btnTextStyles }}
                    >
                        {children}
                    </Typography>
                </ButtonDanger>
            )}
            {variant === 'gray' && (
                <ButtonGray
                    varient={variant}
                    onClick={onClick}
                    style={{ ...style }}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    width={width}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    {...rest}
                >
                    {icon && (
                        <IcoWrap pr={1}>
                            <StyledSvgSprite
                                icon={icon}
                                width='30px'
                                height='30px'
                                style={iconStyle ? iconStyle : {}}
                                fill={iconFill}
                            />
                        </IcoWrap>
                    )}
                    <Typography
                        variant={typography ? typography : 'button1'}
                        {...btnTextStyles}
                    >
                        {children}
                    </Typography>
                </ButtonGray>
            )}
            {variant === 'lightgray' && (
                <ButtonLightGray
                    varient={variant}
                    onClick={onClick}
                    style={{ ...style }}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    width={width}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    {...rest}
                >
                    {icon && (
                        <IcoWrap pr={1}>
                            <SVGSprite
                                icon={icon}
                                width='30px'
                                height='30px'
                                style={iconStyle ? iconStyle : {}}
                            />
                        </IcoWrap>
                    )}
                    <Typography variant={typography ? typography : 'button3'}>
                        {children}
                    </Typography>
                </ButtonLightGray>
            )}
            {variant === 'link' && (
                <ButtonLink
                    type='button'
                    key='button'
                    varient={variant}
                    onClick={onClick}
                    style={{ ...style }}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    width={width}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    loading={loading ? 1 : 0}
                    {...rest}
                >
                    {loading ? (
                        <Lottie
                            options={defaultOptions}
                            height={loaderHeight}
                            width={loaderWidth}
                            isClickToPauseDisabled={true}
                        />
                    ) : (
                        <>
                            {icon && (
                                <IcoWrap pr={2} style={iconWrapStyle || {}}>
                                    <SVGSprite
                                        icon={icon}
                                        width='30px'
                                        height='30px'
                                        style={iconStyle ? iconStyle : {}}
                                    />
                                </IcoWrap>
                            )}
                            <Typography
                                variant={typography || 'button2'}
                                color={color || 'accentSecondary.1'}
                                {...btnTextStyles}
                            >
                                {children}
                            </Typography>
                        </>
                    )}
                </ButtonLink>
            )}
            {variant === 'with-info-tooltip' && (
                <ButtonWithTooltip
                    varient={variant}
                    onClick={onClick}
                    style={{ ...style }}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    {...rest}
                >
                    {icon && (
                        <IcoWrap
                            pr={1}
                            display='inline-flex'
                            verticalAlign='middle'
                        >
                            <SVGSprite
                                icon={icon}
                                width={22}
                                height={22}
                                style={iconStyle ? iconStyle : {}}
                            />
                        </IcoWrap>
                    )}
                    {addTypography && (
                        <Typography
                            variant={typography ? typography : 'button2'}
                            color={color || 'accentBlush.3'}
                            {...btnTextStyles}
                        >
                            {children}
                        </Typography>
                    )}
                    {!addTypography && children}
                    {!disabled && props.tooltip && (
                        <TooltipContainer>
                            <StyledSVGSprite
                                icon={'icon-fill-info'}
                                fill={'#9D9AAC'}
                                width={'13px'}
                                height={'13px'}
                                ml={'4px'}
                            />
                            <Tooltip
                                className='tooltiptext'
                                px={'12px'}
                                py={'12px'}
                                tooltipPosition={props.tooltipPosition}
                                tooltipStyles={tooltipStyles}
                                {...tooltipStyles}
                            >
                                <Typography
                                    variant='subtext2'
                                    fontSize='12px'
                                    lineHeight='16px'
                                    color='#69657B'
                                >
                                    {props.tooltip}
                                </Typography>
                            </Tooltip>
                        </TooltipContainer>
                    )}
                    {!disabled && props.premiumIcon && (
                        <PremiumIcon
                            src={cdnImage('images/paid-feature-diamond.svg')}
                            alt='paid-feature'
                        />
                    )}
                </ButtonWithTooltip>
            )}

            {variant === 'darkgray' && (
                <ButtonDarkGray
                    varient={variant}
                    onClick={!loading ? onClick : undefined}
                    style={{ ...style }}
                    type={type}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    state={state}
                    width={width}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    loading={loading ? 1 : 0}
                    {...rest}
                >
                    {loading ? (
                        <Lottie
                            options={defaultOptions}
                            height={loaderHeight}
                            width={loaderWidth}
                            isClickToPauseDisabled={true}
                        />
                    ) : (
                        <>
                            {icon && (
                                <IcoWrap pr={2}>
                                    <SVGSprite
                                        icon={icon}
                                        width='30px'
                                        height='30px'
                                        style={iconStyle ? iconStyle : {}}
                                    />
                                </IcoWrap>
                            )}
                            <StyledText
                                variant={typography ? typography : 'button1'}
                                disabled={disabled}
                                {...btnTextStyles}
                            >
                                {children}
                            </StyledText>
                        </>
                    )}
                </ButtonDarkGray>
            )}
            {variant === 'secondary' && (
                <ButtonSecondary
                    varient={variant}
                    onClick={!loading ? onClick : undefined}
                    style={{ ...style }}
                    type={type}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    state={state}
                    width={width}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    loading={loading ? 1 : 0}
                    textColor={color}
                    {...rest}
                >
                    {loading ? (
                        <Lottie
                            options={defaultOptions}
                            height={loaderHeight}
                            width={loaderWidth}
                            isClickToPauseDisabled={true}
                        />
                    ) : (
                        <>
                            {icon && (
                                <IcoWrap pr={2} style={iconWrapStyle || {}}>
                                    <SVGSprite
                                        icon={icon}
                                        width='30px'
                                        height='30px'
                                        style={iconStyle ? iconStyle : {}}
                                    />
                                </IcoWrap>
                            )}
                            <StyledText
                                variant={typography ? typography : 'button1'}
                                disabled={disabled}
                                {...btnTextStyles}
                            >
                                {children}
                            </StyledText>
                        </>
                    )}
                </ButtonSecondary>
            )}
            {variant === 'primaryDark' && (
                <ButtonPrimaryDark
                    varient={variant}
                    onClick={!loading ? onClick : undefined}
                    style={{ ...style }}
                    type={type}
                    disabled={disabled}
                    provider={provider}
                    className={className}
                    state={state}
                    width={width}
                    overrideOpacity={overrideOpacity}
                    id={id}
                    loading={loading ? 1 : 0}
                    {...rest}
                >
                    {loading ? (
                        <Lottie
                            options={defaultOptions}
                            height={loaderHeight}
                            width={loaderWidth}
                            isClickToPauseDisabled={true}
                        />
                    ) : (
                        <>
                            {icon && (
                                <IcoWrap pr={2}>
                                    <SVGSprite
                                        icon={icon}
                                        width='30px'
                                        height='30px'
                                        style={iconStyle ? iconStyle : {}}
                                    />
                                </IcoWrap>
                            )}
                            <StyledText
                                variant={typography ? typography : 'button1'}
                                disabled={disabled}
                                color={color}
                                {...btnTextStyles}
                            >
                                {children}
                            </StyledText>
                        </>
                    )}
                </ButtonPrimaryDark>
            )}
            {variant === 'onlineFilter' && (
                <ButtonOnlineFilter
                    onClick={onClick}
                    disabled={disabled}
                    id={id}
                    {...rest}
                >
                    <Typography
                        variant='caption'
                        color={color}
                        style={{ ...btnTextStyles }}
                    >
                        {children}
                    </Typography>
                </ButtonOnlineFilter>
            )}
        </>
    );
});
export default Button;

const TooltipContainer = styled(FlexDiv)`
    position: relative;
    display: inline;
    &:hover {
        .tooltiptext {
            visibility: visible;
        }
    }
`;

const Tooltip = styled(FlexDiv)`
    visibility: hidden;
    width: 210px;
    height: auto;
    background: #fff;
    border-radius: 4px;
    position: absolute;
    ${({ gallery }) =>
        gallery &&
        `height:104px;
        margin-top:0;
        margin-bottom:8px;
    `}
    ${({ tooltipPosition, tooltipStyles }) => {
        if (tooltipPosition === 'bottom')
            return css`
                top: 35px;
                right: -42px;
            `;
        if (tooltipPosition === 'top')
            return css`
                top: ${tooltipStyles?.top ? tooltipStyles?.top : '-65px'};
                right: -31px;
            `;
        return css``;
    }}
    z-index: 2;

    &:after {
        content: ' ';
        position: absolute;
        width: 19.01px;
        height: 14.01px;
        background: #ffffff;
        border-radius: 2px;
        transform: matrix(-0.71, -0.61, 0.81, -0.71, 0, 0);

        ${({ tooltipPosition }) => {
            if (tooltipPosition === 'bottom')
                return css`
                    top: -3px;
                    right: 38px;
                `;
            if (tooltipPosition === 'top')
                return css`
                    bottom: -3px;
                    right: 30px;
                `;
            return css``;
        }}
    }
`;

const ButtonPrimary = styled.button`
    width: 100%;
    ${color}
    ${flexbox}

    color: ${({ theme }) =>
        theme?.primaryButtonTextColor || theme.colors.ambience[0]};
    align-items: center;
    padding: 14px 5px;
    font-style: normal;
    font-size: 16px;
    line-height: 125%;
    height: 50px;
    background: ${({ theme, buttonBackground }) =>
        buttonBackground
            ? select(buttonBackground)(theme.colors)
            : theme.colors.accentPrimary[1]};
    border: 1px solid transparent;
    border-radius: 8px;
    position: relative;
    transition: all 0.3s ease;
    font-family: ${({ theme }) => theme.fonts.book};
    ${layout}
    ${space}
    ${border}
    cursor: pointer;
    &:disabled {
        background-color: ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.ambience[18]
                : theme.colors.ambience[12]};
        cursor: not-allowed;
        opacity: ${({ theme }) => (theme.isLightTheme ? '1' : '0.5')};
    }
    &:hover {
        opacity: ${({ disabled, theme }) =>
            disabled ? (theme.isLightTheme ? 1 : 0.5) : 0.8};
        ${({ loading }) =>
            loading &&
            `
            cursor: default;
        `};
    }
    &:active {
        transform: scale ${(disabled) => (disabled ? 0 : 0.98)};
    }
    &:focus {
        outline: none;
    }
    ${accessibilityStyles('2px')}
    }

    ${(props) =>
        (props.state === 'empty' ||
            props.state === 'normal' ||
            props.state === 'error') &&
        `
        width: auto;
        padding: 13px 35px;
        position: relative;
    `}
    ${(props) => props.overrideOpacity && '&:hover{opacity: 1;}'}
    ${(props) =>
        (props.state === 'loading' || props.state === 'success') &&
        `
        max-width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        pointer-events: none;
    `}
    position: relative;
    font-family: ${({ theme }) => theme.fonts.book};
    p {
        word-break: normal;
    }
    & > p {
        ${({ theme }) => `color: ${theme?.primaryButtonTextColor};`}
    }
`;
const IcoWrap = styled.span`
    ${layout}
    ${space}
    ${color}
    ${border}
`;

const ButtonOnlineFilter = styled(UnstyledButton)`
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    background: ${({ background }) => background};
    margin-left: ${({ ml }) => ml};
`;
const ButtonSecondary = styled(ButtonPrimary)`
    border-color: ${({ theme }) => theme.colors.ambience[15]};
    color: ${({ theme, textColor }) =>
        textColor ? select(textColor)(theme.colors) : theme.colors.ambience[2]};
    background: ${({ theme }) => theme.colors.ambience[20]};
    p {
        color: ${({ theme, textColor }) =>
            textColor
                ? select(textColor)(theme.colors)
                : theme.colors.ambience[2]};
    }
`;
const ButtonOutline = styled(ButtonPrimary)`
    background: ${({ theme, buttonBackground }) =>
        buttonBackground
            ? select(buttonBackground)(theme.colors)
            : 'transparent'};
    border-color: ${({ theme, outlineColor }) =>
        outlineColor
            ? select(outlineColor)(theme.colors)
            : theme.colors.accentPrimary[1]};
    color: ${({ theme, typographyColor }) =>
        typographyColor
            ? select(typographyColor)(theme.colors)
            : theme.colors.accentPrimary[1]};
    & > p {
        color: ${({ theme, typographyColor }) =>
            typographyColor
                ? select(typographyColor)(theme.colors)
                : theme.colors.accentPrimary[1]};
    }
`;
const ButtonSelected = styled(ButtonPrimary)`
    background: ${({ theme }) => theme.colors.ambience[24]};
    color: ${({ theme }) => theme.colors.ambience[0]};
    & > p {
        color: ${({ theme }) => theme.colors.ambience[0]};
    }
`;
const ButtonOutlineGray = styled(ButtonPrimary)`
    border-color: ${({ theme }) => theme.colors.ambience[6]};
    color: ${({ theme }) => theme.colors.ambience[6]};
    background: transparent;
    & > p {
        color: ${({ theme }) => theme.colors.ambience[6]};
    }
`;
const ButtonLightGray = styled(ButtonPrimary)`
    border-color: ${({ theme }) =>
        theme.isLightTheme
            ? theme.colors.ambience[18]
            : theme.colors.ambience[6]};

    background: ${({ theme }) =>
        theme.isLightTheme
            ? theme.colors.ambience[23]
            : theme.colors.ambience[1]};
    p {
        color: ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.ambience[1]
                : theme.colors.ambience[23]};
    }
`;
const ButtonOutlineWhite = styled(ButtonPrimary)`
    border-color: ${({ theme }) => theme.colors.ambience[0]};
    color: ${({ theme }) => theme.colors.ambience[0]};
    background: transparent;
    & > p {
        color: ${({ theme }) => theme.colors.ambience[0]};
    }
    @media (max-width: 1024px) {
        font-size: 13.5px;
    }
`;
const ButtonWhite = styled(ButtonPrimary)`
    &:disabled {
        background-color: ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.ambience[21]
                : theme.colors.ambience[2]};
        opacity: 0.2;
    }
    background: ${({ theme }) =>
        theme.isLightTheme
            ? theme.colors.ambience[24]
            : theme.colors.ambience[0]};
    p {
        color: ${({ theme, disabled }) =>
            disabled
                ? theme.isLightTheme
                    ? theme.colors.ambience[1]
                    : theme.colors.ambience[23]
                : theme.colors.accentPrimary[1]};
    }
`;
const ButtonYellow = styled(ButtonPrimary)`
    background: ${({ theme }) => theme.colors.semantic[0]};
    p {
        color: ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.ambience[0]
                : theme.colors.ambience[24]};
    }
    @media (max-width: 1024px) {
        p {
            font-size: 13.5px;
        }
    }
`;
const ButtonDanger = styled(ButtonPrimary)`
    background: rgba(255, 91, 76, 0.16);
    p {
        color: ${({ theme }) => theme.colors.semantic[1]};
    }
    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: ${({ theme }) =>
            theme.isLightTheme ? '1px solid black' : '1px solid white'};
}
    }
`;
const ButtonGray = styled(ButtonPrimary)`
    ${background}
    background: ${({ theme, ...props }) =>
        props.isProfileButton
            ? theme.colors.ambience[18]
            : theme.colors.ambience[21]};
    p {
        color: ${({ theme, ...props }) =>
            props.isProfileButton
                ? theme.colors.ambience[0]
                : theme.isLightTheme
                ? theme.colors.ambience[1]
                : theme.colors.ambience[0]};
        font-size: ${(props) => (props.isProfileButton ? '14px' : '16px')};
    }
    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: ${({ theme }) =>
            theme.isLightTheme ? '1px solid black' : '1px solid white'};
}
    }
    ${(props) =>
        props.isProfileButton
            ? `
            p {
                font-family:${props.theme.fonts.book};
            }
            svg {
                margin-bottom: 3px;
            }`
            : ''}
`;
const ButtonLink = styled.button`
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    &:disabled {
        cursor: not-allowed;
    }
    ${layout}
    ${space}
    ${border}
    &:focus{
        outline:none;
    }
    &:focus-visible {
        outline: ${({ theme }) =>
            theme.isLightTheme
                ? '2px solid black !important'
                : '2px solid white !important'}
    }
    ${space}
    ${cursor}
`;
const StyledText = styled(Typography)`
    color: ${({ theme, disabled }) =>
        theme.isLightTheme
            ? disabled
                ? theme.colors.ambience[15]
                : theme.colors.ambience[24]
            : theme.colors.ambience[0]};
    ${color}
`;

const ButtonWithTooltip = styled(ButtonPrimary)`
    background: ${({ theme }) => theme.colors.accentBlush[0]};
    color: ${({ theme }) =>
        IS_CUSTOM_TENANT
            ? theme.colors.accentPrimary[0]
            : theme.colors.accentBlush[3]};
    fontsize: 14px;
    text-align: left;
    padding-left: 18px;
    padding-top: 17px;
    padding-bottom: 17px;
    height: auto;
    & > p {
        color: ${({ theme }) =>
            IS_CUSTOM_TENANT
                ? theme.colors.accentPrimary[0]
                : theme.colors.accentBlush[3]};
    }
    &:disabled {
        background-color: ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.ambience[22]
                : theme.colors.accentBlush[0]};
        cursor: not-allowed;
        opacity: ${({ theme }) => (theme.isLightTheme ? '1' : '0.35')};
    }
    &:hover {
        opacity: ${({ disabled, theme }) =>
            disabled ? (theme.isLightTheme ? 1 : 0.5) : 1};
        background-color: ${({ theme }) =>
            theme.isLightTheme
                ? alpha(theme.colors.ambience[22], 0.8)
                : alpha(theme.colors.accentBlush[0], 0.8)};
    }
`;

const StyledSVGSprite = styled(SVGSprite)`
    ${layout}
    ${space}
`;

const PremiumIcon = styled.img`
    position: absolute;
    right: 14px;
    top: 20px;
`;

const ButtonDarkGray = styled.button`
    width: 100%;
    color: ${({ theme }) => theme.colors.ambience[0]};
    align-items: center;
    padding: 14px 15px;
    font-style: normal;
    font-size: 16px;
    line-height: 125%;
    height: 50px;
    background: ${({ theme }) => theme.colors.ambience[20]};
    border: 1px solid ${({ theme }) => theme.colors.ambience[20]};
    border-radius: 8px;
    position: relative;
    transition: all 0.3s ease;
    font-family: ${({ theme }) => theme.fonts.book};
    cursor: pointer;

    ${color}
    ${flexbox}
    ${layout}
    ${space}
    ${border}

    &:hover {
        opacity: 0.8;
    }

    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: ${({ theme }) =>
            theme.isLightTheme ? '1px solid black' : '1px solid white'};
}
    }
    font-family: ${({ theme }) => theme.fonts.book};
    p {
        word-break: normal;
    }
`;

const ButtonPrimaryDark = styled(ButtonPrimary)`
    background: ${({ theme }) => theme.colors.accentPrimary[1]};
`;
