import {
    CONFERENCE_MODE,
    MEETUP_MODE,
} from 'components/community/CreateAirmeetForm/constants';
import { getEventTypeLabel } from 'utils/airmeet';
import { EVENT_TYPES } from 'utils/constants/airmeet';
import { IS_CUSTOM_TENANT, getCurrentTenantData } from 'utils/tenant';
import theme from '../../foundations/theme';

export const ORG_TYPE = 'ORG_TYPE';
export const AREA_OF_SPECIALISATION = 'AREA_OF_SPECIALISATION';
export const EVENT_TYPE = 'EVENT_TYPE';
export const EMAIL_INPUT = 'EMAIL_INPUT';
export const OTHER = 'Others';
export const PENDING = 'PENDING';
export const ACTIVE = 'ACTIVE';
export const L1ToastTitle = 'Uh-oh, that is a little lengthy';
export const L1ToastOrgErrorMessage =
    'Your org type can be anything you want. But please write it in under 35 characters.';
export const L1ToastEventErrorMessage =
    'Your event type can be anything you want. But please write it in under 35 characters.';

export const DEMO_EVENTS_WIDGET = 'DEMO_EVENTS_WIDGET';
export const HOST_EVENT_WIDGET = 'HOST_EVENT_WIDGET';
export const INFO_WIDGET = 'INFO_WIDGET';
export const OPEN_EVENTS_WIDGET = 'OPEN_EVENTS_WIDGET';
export const SLIDER_WIDGET = 'SLIDER_WIDGET';
export const LOUNGE_SLIDER = 'LOUNGE_SLIDER';
export const CREATE_ORG_SLIDER = 'CREATE_ORG_SLIDER';
export const MEETUP_TAG = 'Meetup';
export const DOMORE_AIRMEET_WIDGET = 'DOMORE_AIRMEET_WIDGET';
export const EVENT_LINK = 'https://youtu.be/9gfbPTwM73g';
export const FREE_TAG = 'free';
export const TRIAL_TAG = 'trial';

export const INFO_WIDGET_DESKTOP_CONFIG = {
    title: `🚀Let's create your next event`,
    description: `Start planning your event now. Create your own customised event from scratch and host webinars, workshops, networking, and single session events.`,
    widgetType: INFO_WIDGET,
    imageSrc: '/images/community/illustration/create-event.png',
    ctaText: 'Create event',
    backgroundColor: theme.colors.accentBlush[0],
    ctaColor: theme.colors.accentPrimary[1],
};

export const SLIDER_WIDGET_SOCIAL_CONFIG = {
    title: `A dedicated space for networking`,
    description: `Use the social lounge at your event to drive networking. Your attendees can join discussions with like minded people for 1:1 video conversations.`,
    widgetType: SLIDER_WIDGET,
    imageSrc: '/images/community/illustration/social_lounge.png',
    altImageText: 'social-lounge-graphic',
    ctaText: 'Try social lounge',
    backgroundColor: theme.colors.accentCarrot[0],
    ctaColor: theme.colors.accentCarrot[3],
    textColor: theme.colors.accentCarrot[2],
};

export const DEMO_EVENT_CONFIG = {
    title: '🎙Experience a demo event',
    subTitle: `${
        IS_CUSTOM_TENANT ? 'Events' : 'Airmeets'
    } are highly customizable, and can be personalized to suit your brand need. Experience any demo of your 
    liking to see how ${getCurrentTenantData('name')} can make your event 10x.`,
    widgetType: DEMO_EVENTS_WIDGET,
    showViewAll: true,
};

export const DEMO_EVENT_MOBILE_CONFIG = {
    ...DEMO_EVENT_CONFIG,
    subTitle: `${
        IS_CUSTOM_TENANT ? 'Events' : 'Airmeets'
    } are highly customizable, and can be personalized to suit your brand need. Experience any demo of your liking to see how ${getCurrentTenantData(
        'name'
    )} can make your event 10x.`,
};
export const DEMO_EVENT_DASHBOARD_CONFIG = {
    ...DEMO_EVENT_CONFIG,
    subTitle: `${
        IS_CUSTOM_TENANT ? 'Events' : 'Airmeets'
    } are highly customizable, and can be personalized to suit your brand need. Experience our library of events to see how ${getCurrentTenantData(
        'name'
    )} can make your event 10x.`,
};
export const DOMORE_AIRMEET_CONFIG = {
    title: `&#128522; Do more with ${getCurrentTenantData('name')}`,
    subTitle: `${getCurrentTenantData(
        'name'
    )} has amazing unique offerings, from creating custom registration form to integration with multiple partners. We also help you to monetize your event.`,
    widgetType: DOMORE_AIRMEET_WIDGET,
    cards: [
        {
            backgroundColor: theme.colors.accentBrick[0],
            titleColor: theme.colors.accentRose[2],
            title: 'Capture attendee details',
            subTitleColor: theme.colors.accentBrick[2],
            subTitle: `Capture the right information with 'Custom registration forms' to get intended audience.`,
            imageSrc1: '/images/community/illustration/capture.png',
            imageAlt1: 'Capture attendee details',
        },
        {
            backgroundColor: theme.colors.accentSea[0],
            titleColor: theme.colors.accentSea[3],
            title: `Integrate ${getCurrentTenantData('name')}`,
            subTitleColor: theme.colors.accentSea[2],
            subTitle: `Connect your ${getCurrentTenantData(
                'name'
            )} account with eventbrite, hubspot,mailchimp, salesforce and a whole lot of other services.`,
            imageSrc1: '/images/community/illustration/airmeet-item.png',
            imageAlt1: 'airmeet-item',
            imageSrc2: '/images/community/illustration/integrate.png',
            imageAlt2: `Integrate ${getCurrentTenantData('name')}`,
        },
        {
            backgroundColor: theme.colors.accentSun[0],
            titleColor: theme.colors.accentSun[2],
            title: 'Monetize your events',
            subTitleColor: theme.colors.accentSun[2],
            subTitle:
                'Connect your stripe account and monetize your events with tickets. You can add different tier of tickets also.',
            imageSrc1: '/images/community/illustration/phonecard.png',
            imageAlt1: 'Capture attendee details',
        },
    ],
};

export const OPEN_EVENT_CONFIG = {
    title: 'Explore live events',
    subTitle: `Dive into some of the live events at ${getCurrentTenantData(
        'name'
    )} hosted by organizers like you.`,
    widgetType: OPEN_EVENTS_WIDGET,
    showViewAll: false,
    imageSrc: '/images/live-camera.svg',
    showImage: true,
};

export const HOST_EVENT_CONFIG = {
    title: '',
    subTitle: '',
    widgetType: HOST_EVENT_WIDGET,
    showViewAll: false,
};

export const EVENTS_DUMMY_CARDS = [
    {
        uid: 'f6c36b76-c9e3-11eb-b8bc-0242ac130003',
        title: getEventTypeLabel({ eventType: EVENT_TYPES.MEETUP }),
        shareIcon: false,
        subHeading:
            'Great for webinars, workshops, networking, and single session events.',
        image: '/images/onboarding-meetup.svg',
        imgBg: 'rgba(212,245,225,1)',
        createMode: MEETUP_MODE,
        tagType: FREE_TAG,
    },
    {
        uid: 'faa97f00-c9e3-11eb-b8bc-0242ac130003',
        title: getEventTypeLabel({ eventType: EVENT_TYPES.CONFERENCE }),
        shareIcon: false,
        subHeading:
            'Ideal for large events like summits, fairs or multi-track events.',
        image: '/images/onboarding-conference.svg',
        imgBg: 'rgba(253,234,245,1)',
        createMode: CONFERENCE_MODE,
        tagType: TRIAL_TAG,
    },
];

// Header config
export const QUESTIONS_SCREEN_SOURCE = 'onboarding questions';
export const PENDING_LANDING_PAGE_SCREEN_SOURCE =
    'onboarding pending landing page';
export const ACTIVE_LANDING_PAGE_SCREEN_SOURCE =
    'onboarding active landing page';

export const DEMO_EVENT_INITIAL_STATE = {
    demoEvents: [],
    demoEventsErrorMessage: '',
    socialLoungeDemoEvent: {},
    totalDemoEvents: 0,
    totalDemoPages: 0,
};

// Create org initiation ways
export const HOST_OWN_EVENTS_INITIATION = 'HOST_OWN_EVENTS_INITIATION';
export const PROGRESS_SLIDER_INITIATION = 'PROGRESS_SLIDER_INITIATION';
export const CREATE_EVENT_HEADER_INITIATION = 'CREATE_EVENT_HEADER_INITIATION';
export const OPEN_EVENTS_TAG_COLOR = {
    0: '#6A4CFF',
    1: '#F1A50D',
    2: '#5389F2',
};

export const CREATE_ORG_INTERMEDIATE_SCREEN = {
    heading: 'Complete setting up your account first',
    subheading: 'Before you proceed.',
    mainDescription:
        'It is mandatory to setup your organization before you start hosting an event. Add the organization name and logo under whichyou want to run your events.',
    secondaryDescription:
        'This will only take a few minutes, and we will be able to provide with personalized assistance.',
};

export const CREATE_ORG_STEPS = {
    1: {
        text: 'Add basic details',
        percentValue: 33.33,
    },
    2: {
        text: 'Select organization type',
        percentValue: 66.66,
    },
    3: {
        text: 'Add team member',
        percentValue: 100,
    },
};

export const NAME_PRESENT = 'NAME_PRESENT';

export const CREATE_ORG_INITIALS = {
    orgName: '',
    orgLogoUrl: '',
    orgType: '',
    communityAreaOfSpecialisation: '',
    email: '',
    newsLetterOptIn: true,
};

export const COPY = 'Copy';

export const COPIED = 'Copied';

export const TEAM_MEMBER_INVITED =
    'Congratulations! You are currently on a free plan and have setup your organization with one other team member.';

export const TEAM_MEMBER_NOT_INVITED =
    'Congratulations! You are currently on a free plan and you can add up to one other team member with your current plan.';

export const MEETUP = 'Meetup';
export const NOT_SELECTED = 'Not Selected';

//Button text
export const BUTTON_TEXT = {
    select: 'Select',
    change: 'Change',
    next: 'Next',
    continue: 'Continue',
};

export const L1_SCREEN_TEXT = {
    desktopHeading: 'Help us build a personalized experience for you',
    mobileHeading: 'Help us build a better experience for you',
    desktopSubheading:
        'Tell us a little bit about yourself so we can help you get started',
    mobileSubheading:
        'Answer these questions for a more personalize experience',
    orgTypeHeading: 'What is your organization type?',
    mobileOrgTypeSubheading:
        'Select your organization type, it will suggest suitable templates for your events.',
    desktopOrgTypeSubheading:
        'This will help us suggest suitable templates for your events.',
};
