import { ENTERPRISE } from 'components/community/billing-plans/data';

export const RESUME_SUBSCRIPTION = 'RESUME_SUBSCRIPTION';
export const CHANGE_SUBSCRIPTION_PAUSE_STATE =
    'CHANGE_SUBSCRIPTION_PAUSE_STATE';
export const UPSELL_ENTERPRISE_PLAN = 'UPSELL_ENTERPRISE_PLAN';
export const CHANGE_SUBSCRIPTION = 'CHANGE_SUBSCRIPTION';
export const PAUSE_SUBSCRIPTION = 'PAUSE_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CHANGE_SUBSCRIPTION_PLAN_SUGGESTION = ` Looking to change your plan? Contact our sales team to know more.`;
export const SECONDARY_CTA_CONFIG = {
    [CHANGE_SUBSCRIPTION]: {
        // icon: 'icon-open-in-new-tab',
        text: 'View Billing Plans',
        url: 'https://www.airmeet.com/hub/pricing/',
    },
};
// this approach helps when we want to change order of the cards, by changing just the position of const in array
export const SUBSCRIPTION_PAUSED_OPTIONS = [
    RESUME_SUBSCRIPTION,
    CHANGE_SUBSCRIPTION_PAUSE_STATE,
    UPSELL_ENTERPRISE_PLAN,
];
export const SUBSCRIPTION_NOT_PAUSED_OPTIONS = [
    CHANGE_SUBSCRIPTION,
    PAUSE_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION,
];
export const HEADER_TEXT_CONFIG = {
    [RESUME_SUBSCRIPTION]: 'Resume my subscription',
    [CHANGE_SUBSCRIPTION_PAUSE_STATE]: 'Change my subscription',
    [UPSELL_ENTERPRISE_PLAN]: `What’s in our ${ENTERPRISE} Plan?`,
    [CHANGE_SUBSCRIPTION]: 'Change my subscription',
    [PAUSE_SUBSCRIPTION]: 'Pause my subscription',
    [CANCEL_SUBSCRIPTION]: 'Cancel my subscription',
};

export const NON_RENEWING = 'NON_RENEWING';
export const CANCELLED = 'CANCELLED';
export const PAUSED = 'PAUSED';
export const ACTIVE = 'ACTIVE';
export const ONE_TIME_PAYMENT = 'once';
// ========= event analytics =================
export const PAUSE_SUBSCRIPTION_FORM_SUBMITTED = 'pause_subscription';
export const CANCEL_SUBSCRIPTION_FORM_SUBMITTED = 'cancel_subscription';
export const CHANGE_SUBSCRIPTION_CTA = 'change_subscription';
export const MANAGE_SUBSCRIPTION_CTA = 'manage_subscription_cta';
export const CONTACT_SALES_CTA = 'one_time_payment_contact_sales';
export const CANCEL_SUBSCRIPTION_PAGE_VIEW = 'cancel_subscription_page_view';
export const PAUSE_SUBSCRIPTION_PAGE_VIEW = 'pause_subscription_page_view';
export const RESUME_SUBSCRIPTION_CTA = 'resume_subscription_cta';
export const CUSTOMIZE_ENTERPRISE_PLAN_CTA = 'customize_enterprise_plan_cta';
export const CUSTOMIZE_ENTERPRISE_PLAN_FORM_SUBMIT =
    'customize_enterprise_plan_form_submit';
export const CUSTOMIZE_ENTERPRISE_PLAN_FORM_CANCEL =
    'customize_enterprise_plan_form_cancel';
export const MANAGE_SUBSCRIPTION_BACK_BUTTON_PRESSED =
    'manage_subscription_back_button_pressed';
export const MANAGE_SUBSCRIPTION_FAILURE_MODAL_CTA =
    'manage_subscription_failure_modal_cta_clicked';
export const MANAGE_SUBSCRIPTION_SUCCESS_MODAL_CTA =
    'manage_subscription_success_modal_cta_clicked';
export const PAUSE_SUBSCRIPTION_PRIMARY_CTA = 'pause_subscription_primary_cta';
export const PAUSE_SUBSCRIPTION_SECONDARY_CTA =
    'pause_subscription_secondary_cta';
export const CANCEL_SUBSCRIPTION_PRIMARY_CTA =
    'cancel_subscription_primary_cta';
export const CANCEL_SUBSCRIPTION_SECONDARY_CTA =
    'cancel_subscription_secondary_cta';
export const DISABLED_CANCEL_SUBSCRIPTION_PRIMARY_CTA =
    'disabled_cancel_subscription_primary_cta_clicked';
export const DISABLED_PAUSE_SUBSCRIPTION_PRIMARY_CTA =
    'disabled_pause_subscription_primary_cta_clicked';
export const ATTENDEE_ADD_ON_CONTACT_SALES =
    'Attendee_add_on_contact_us_clicked';

export const ManageSubscriptionEvents = {
    [PAUSE_SUBSCRIPTION_FORM_SUBMITTED]: PAUSE_SUBSCRIPTION_FORM_SUBMITTED,
    [CANCEL_SUBSCRIPTION_FORM_SUBMITTED]: CANCEL_SUBSCRIPTION_FORM_SUBMITTED,
    [CHANGE_SUBSCRIPTION_CTA]: CHANGE_SUBSCRIPTION_CTA,
    [MANAGE_SUBSCRIPTION_CTA]: MANAGE_SUBSCRIPTION_CTA,
    [CANCEL_SUBSCRIPTION_PAGE_VIEW]: CANCEL_SUBSCRIPTION_PAGE_VIEW,
    [PAUSE_SUBSCRIPTION_PAGE_VIEW]: PAUSE_SUBSCRIPTION_PAGE_VIEW,
    [RESUME_SUBSCRIPTION_CTA]: RESUME_SUBSCRIPTION_CTA,
    [CUSTOMIZE_ENTERPRISE_PLAN_CTA]: CUSTOMIZE_ENTERPRISE_PLAN_CTA,
    [CUSTOMIZE_ENTERPRISE_PLAN_FORM_SUBMIT]: CUSTOMIZE_ENTERPRISE_PLAN_FORM_SUBMIT,
    [CUSTOMIZE_ENTERPRISE_PLAN_FORM_CANCEL]: CUSTOMIZE_ENTERPRISE_PLAN_FORM_CANCEL,
    [MANAGE_SUBSCRIPTION_BACK_BUTTON_PRESSED]: MANAGE_SUBSCRIPTION_BACK_BUTTON_PRESSED,
    [MANAGE_SUBSCRIPTION_FAILURE_MODAL_CTA]: MANAGE_SUBSCRIPTION_FAILURE_MODAL_CTA,
    [MANAGE_SUBSCRIPTION_SUCCESS_MODAL_CTA]: MANAGE_SUBSCRIPTION_SUCCESS_MODAL_CTA,
    [PAUSE_SUBSCRIPTION_PRIMARY_CTA]: PAUSE_SUBSCRIPTION_PRIMARY_CTA,
    [PAUSE_SUBSCRIPTION_SECONDARY_CTA]: PAUSE_SUBSCRIPTION_SECONDARY_CTA,
    [CANCEL_SUBSCRIPTION_PRIMARY_CTA]: CANCEL_SUBSCRIPTION_PRIMARY_CTA,
    [CANCEL_SUBSCRIPTION_SECONDARY_CTA]: CANCEL_SUBSCRIPTION_SECONDARY_CTA,
    [DISABLED_CANCEL_SUBSCRIPTION_PRIMARY_CTA]: DISABLED_CANCEL_SUBSCRIPTION_PRIMARY_CTA,
    [DISABLED_PAUSE_SUBSCRIPTION_PRIMARY_CTA]: DISABLED_PAUSE_SUBSCRIPTION_PRIMARY_CTA,
    [ATTENDEE_ADD_ON_CONTACT_SALES]: ATTENDEE_ADD_ON_CONTACT_SALES,
};
