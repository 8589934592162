import { StyledSvgSprite } from 'components/general/SVGSprite';
import WithTooltip from 'components/general/WithTooltip';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import { getCardBackgroundStyle } from 'foundations/themeV2/utils';
import React from 'react';
import styled from 'styled-components';

export default function FormAccordion({
    title,
    children,
    defaultIsOpen = true,
    info,
    isOpen = false,
    setIsOpen,
    ...rest
}) {
    const toggleAccordion = () => {
        setIsOpen && setIsOpen((isOpen) => !isOpen);
    };

    return (
        <Wrapper flexDirection='column' alignItems='stretch' {...rest}>
            <Header onClick={toggleAccordion} alignItems='center'>
                <FlexDiv
                    flex='1'
                    alignItems='center'
                    borderBottom='1px solid'
                    borderColor={isOpen ? 'border.default2' : 'transparent'}
                    py='x4'
                >
                    <FlexDiv alignItems='center' flex='1'>
                        <Text
                            variant='h3'
                            color='text.default.primary'
                            mr={info ? '8px' : ''}
                        >
                            {title}
                        </Text>
                        {info && (
                            <WithTooltip
                                isThemeV2={true}
                                tooltip={info}
                                place='bottom'
                            >
                                <StyledSvgSprite
                                    icon='icon-info-outline'
                                    height='16px'
                                    width='16px'
                                    fill='text.default.primary'
                                />
                            </WithTooltip>
                        )}
                    </FlexDiv>
                    <Arrow
                        fill='text.default.primary'
                        icon='chevron_right'
                        height='1.5rem'
                        width='1.5rem'
                        ml='x4'
                        isOpen={isOpen}
                    />
                </FlexDiv>
            </Header>
            <Body isOpen={isOpen} className={'accordion__body'}>
                <Content>{children}</Content>
            </Body>
        </Wrapper>
    );
}

const Wrapper = styled(FlexDiv)`
    max-width: 50rem;
    border-radius: ${({ theme }) => theme.radii.default};
    ${({ theme }) => getCardBackgroundStyle({ theme })}
`;

const Header = styled(FlexDiv)`
    cursor: pointer;
    padding: 0 ${({ theme }) => theme.space.x5};
`;

const Body = styled(FlexDiv)`
    max-height: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    overflow: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    transition: max-height 0.3s ease-in-out;
    flex: 1;
`;

const Arrow = styled(StyledSvgSprite)`
    transition: all 0.3s ease-in-out;
    transform: ${({ isOpen }) => `rotate(${isOpen ? 270 : 90}deg)`};
`;

const Content = styled(FlexDiv)`
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    padding: ${({ theme }) => `${theme.space.x4} ${theme.space.x5}`};
    width: 100%;
`;
