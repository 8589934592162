import { USER_STAGE_VISIBILITY } from 'hooks/live-airmeet/useUserVisibilityOnStageAction';

const UserStageVisibilityWriter = async ({
    airmeetId,
    client,
    payload,
    metaData,
    logger,
}) => {
    const { userId, sessionId } = metaData;

    if (!sessionId) {
        throw new Error('Invalid stage reactions firebase key');
    }
    const { getTimeStamp } = payload;
    let { data } = payload;
    if (data && getTimeStamp) {
        data = {
            ...(data || {}),
            ...client.getServerTimestampRef(),
        };
    }
    logger.info('Updating Invited Speaker Visibility.', data);

    const firebaseVisibleUserkey = `${airmeetId}/meta-data/sessions/${sessionId}/genral/${USER_STAGE_VISIBILITY}/${userId}`;
    await client.setDataAsync(firebaseVisibleUserkey, data);
};

export default UserStageVisibilityWriter;
