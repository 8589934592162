import { MANAGE_SEATS_PAGE } from 'components/ManageSeatsLimit/constants';
import {
    INVITE_MEMBER_PATH,
    ONBOARDING_PATH,
    SSO_PATH,
} from 'components/auth/utils';
import { BILLING_PAGE_TAB } from 'components/community/constants';
import { WELCOME_MODAL_PATH } from 'components/newOnboardingFlow/v3/config';
import AppWebViewRoute from 'components/routes/AppWebView';
import useInitFullStory from 'hooks/fullStory/useInitFullStory';
import useGTMSegmentation from 'hooks/gtm-segmentation/useGTMSegmentation';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import StripeResult from 'views/stripeConnect';
import OAuthPopup from './components/auth/OAuthPopup';
import SelectCommunity from './components/community/SelectCommunity/SelectCommunity';
import PrivateRoute from './components/routes/PrivateRoute';
import PublicRoute from './components/routes/PublicRoute';
import usePageStyle from './hooks/usePageStyle';

const ManageSubscription = React.lazy(() =>
    import('./components/ManageSubscription')
);
const ManageSeatsLimit = React.lazy(() =>
    import('./components/ManageSeatsLimit')
);
const Redirect = React.lazy(() => import('./views/redirect'));
const Profile = React.lazy(() => import('./components/dashboard/profile'));
const ProviderAuthCallbackPopup = React.lazy(() =>
    import(
        './components/community/LiveStreamDestinations/ProviderAuthCallbackPopup'
    )
);
const NotFound = React.lazy(() => import('./components/errors/NotFound'));
const EditPublicAirmeet = React.lazy(() =>
    import('./components/community/editPublicAirmeet')
);
const EventSeriesDashboard = React.lazy(() =>
    import('./components/community/EventSeries/EventSeriesDashboard/')
);
const ContentHubDashboard = React.lazy(() =>
    import('./components/community/ContentHub/Dashboard')
);
const CMS = React.lazy(() => import('./views/CMS'));
const ThirdPartyCoupon = React.lazy(() =>
    import('./views/third-party-coupons')
);
const BackStageAuth = React.lazy(() => import('./components/backstage/auth'));
const Attendees = React.lazy(() => import('./views/attendees'));
const CommunityDashboard = React.lazy(() =>
    import('./views/communityDashboard')
);
const Events = React.lazy(() =>
    import('./components/newOnboardingFlow/List/events')
);
const PublicviewPage = React.lazy(() => import('./views/community/profile'));
const Host = React.lazy(() => import('./views/host'));
const LiveAirmeet = React.lazy(() => import('./views/live-airmeet'));
const LiveAirmeetInviteLogin = React.lazy(() =>
    import('./views/live-airmeet/LiveAirmeetInviteLogin')
);
const AirmeetDetail = React.lazy(() =>
    import('./views/video-broadcast/airmeetDetail')
);
const EditEventLandingPage = React.lazy(() =>
    import('./views/EventLandingPage/EditEventLandingPage')
);
const EventSeriesLandingPage = React.lazy(() =>
    import('./views/EventSeriesLandingPage/')
);
const EventSeriesLandingPageMobile = React.lazy(() =>
    import('./views/EventSeriesLandingPage/EventSerieslandingPageMobile')
);
const EditSeriesLandingPage = React.lazy(() =>
    import('./views/EventSeriesLandingPage/EditSeriesLandingPage')
);
const customRegistrationMobile = React.lazy(() =>
    import('./views/customRegistrationMobile')
);
const StripeKyc = React.lazy(() =>
    import('./components/community/editPublicAirmeet/Tickets/stripeRedirect')
);
const TicketDetail = React.lazy(() =>
    import('./views/attendeeTicketing/ticketDetail')
);
const OrderTicketInfoDetails = React.lazy(() =>
    import('./views/attendeeTicketing/orderDetail')
);
const SignUpPage = React.lazy(() => import('./components/auth/SignUpPage'));
const SSO = React.lazy(() => import('./components/auth/SSOPage'));
const UserInvitePageScreen = React.lazy(() =>
    import('./components/UserInvitePageScreen')
);
const IntegrationAuthCallbackPopup = React.lazy(() =>
    import('./components/community/integrations/IntegrationAuthCallbackPopup')
);
const MobileBoothAppWrapper = React.lazy(() =>
    import('./components/molecules/summit/mobile/MobileBoothAppWrapper')
);
const Integrations = React.lazy(() => import('./components/Integrations'));

const OnboardingV3 = React.lazy(() =>
    import('./components/newOnboardingFlow/v3')
);
const UnsubscribeEmails = React.lazy(() =>
    import('./components/UnsubscribeEmails')
);
const PreCallTestPage = React.lazy(() =>
    import('./components/systemCheck/preCallTest')
);
const ContentHubPublicPage = React.lazy(() => import('./views/CMS/PublicPage'));

const MobileGMAppWrapper = React.lazy(() =>
    import(
        './components/molecules/summit/center/sessions/GroupMeeting/mobile/MobileGMAppWrapper'
    )
);

const VideoEditor = React.lazy(() => import('./components/videoEditor'));

const AirProfile = React.lazy(() => import('./views/airProfile'));

const PRIVATE_TYPE_ROUTE = 'private';
const PUBLIC_TYPE_ROUTE = 'public';
const RESTRICTED_TYPE_ROUTE = 'restricted';
const MOBILE_WEBVIEW_ROUTE = 'webview';

const routes = [
    // Immediate
    {
        // Hub redirection paths
        path: ['/', '/pricing', '/privacy-policy', '/terms-of-use', '/signup'],
        Component: Redirect,
        type: PUBLIC_TYPE_ROUTE,
    },
    { path: '/auth/callback', Component: OAuthPopup, type: PUBLIC_TYPE_ROUTE },
    {
        path: '/live-stream/auth/callback',
        Component: ProviderAuthCallbackPopup,
        type: PUBLIC_TYPE_ROUTE,
    },
    {
        path: '/event/session',
        Component: BackStageAuth,
        type: PUBLIC_TYPE_ROUTE,
    },
    // Lazy
    { path: '/c/:id', Component: PublicviewPage, type: PUBLIC_TYPE_ROUTE },
    { path: '/e/:id', Component: AirmeetDetail, type: PUBLIC_TYPE_ROUTE },
    {
        path: '/e/:id/edit',
        Component: EditEventLandingPage,
        type: PRIVATE_TYPE_ROUTE,
    },
    {
        path: '/es/:eventSeriesId/edit',
        Component: EditSeriesLandingPage,
        type: PRIVATE_TYPE_ROUTE,
    },
    {
        path: [
            '/es/:eventSeriesId/mobile-series',
            '/es/:eventSeriesId/:tabId/mobile-series',
        ],
        Component: EventSeriesLandingPageMobile,
        type: PUBLIC_TYPE_ROUTE,
    },
    {
        path: ['/es/:eventSeriesId', '/es/:eventSeriesId/:tabId'],
        Component: EventSeriesLandingPage,
        type: PUBLIC_TYPE_ROUTE,
    },
    {
        path: '/e/:id/mobile-registration',
        Component: customRegistrationMobile,
        type: PUBLIC_TYPE_ROUTE,
    },
    { path: '/e/:id/ticket', Component: TicketDetail, type: PUBLIC_TYPE_ROUTE },
    {
        path: '/order-detail',
        Component: OrderTicketInfoDetails,
        type: PUBLIC_TYPE_ROUTE,
    },

    {
        path: ['/select-community'],
        Component: SelectCommunity,
        type: PRIVATE_TYPE_ROUTE,
    },
    {
        path: [
            '/community-manager/:id/user-profile/:userId',
            '/community-manager/:id/user-profile/:userId/:eventId',
        ],
        Component: AirProfile,
        type: PRIVATE_TYPE_ROUTE,
    },
    {
        path: [
            `/community-manager/:id/${BILLING_PAGE_TAB}/manage-subscription`,
        ],
        Component: ManageSubscription,
        type: PRIVATE_TYPE_ROUTE,
    },
    {
        path: [
            `/community-manager/:id/${BILLING_PAGE_TAB}/${MANAGE_SEATS_PAGE}`,
        ],
        Component: ManageSeatsLimit,
        type: PRIVATE_TYPE_ROUTE,
    },
    {
        path: ['/community-manager/:id/:tab/demo'],
        Component: Events,
        type: PRIVATE_TYPE_ROUTE,
    },

    {
        path: [
            '/community-manager/:id',
            '/community-manager/:id/:tab',
            '/community-manager/:id/:tab/:tabname',
        ],
        Component: CommunityDashboard,
        type: PRIVATE_TYPE_ROUTE,
    },

    { path: '/attendees', Component: Attendees, type: PRIVATE_TYPE_ROUTE },
    { path: '/host', Component: Host, type: PRIVATE_TYPE_ROUTE },
    {
        path: '/event/:id',
        Component: LiveAirmeet,
        loginComponent: LiveAirmeetInviteLogin,
        type: PRIVATE_TYPE_ROUTE,
        allowGuest: true,
    },
    {
        path: '/webview/event/:id/booth-mobile',
        Component: MobileBoothAppWrapper,
        type: MOBILE_WEBVIEW_ROUTE,
        allowGuest: true,
    },
    {
        path: '/webview/event/:id/group-meeting-mobile/:sessionid',
        Component: MobileGMAppWrapper,
        type: MOBILE_WEBVIEW_ROUTE,
        allowGuest: true,
    },
    {
        path: '/event/:id/ticket',
        Component: TicketDetail,
        type: PRIVATE_TYPE_ROUTE,
        allowGuest: true,
    },
    {
        path: '/cms',
        Component: CMS,
        type: PRIVATE_TYPE_ROUTE,
        allowGuest: false,
    },
    {
        path: '/profile',
        Component: Profile,
        type: PRIVATE_TYPE_ROUTE,
        allowGuest: false,
    },
    {
        path: [
            '/airmeets/:id',
            '/airmeets/:id/:tab',
            `/airmeets/:id/:tab/${WELCOME_MODAL_PATH}`,
            '/airmeets/:id/:tab/:subTab',
            '/airmeets/:id/:tab/*',
        ],
        Component: EditPublicAirmeet,
        type: PRIVATE_TYPE_ROUTE,
    },
    {
        path: [
            '/event-series/:eventSeriesId',
            '/event-series/:eventSeriesId/:tab',
            '/event-series/:eventSeriesId/:tab/:subTab',
            '/event-series/:eventSeriesId/:tab/*',
        ],
        Component: EventSeriesDashboard,
        type: PRIVATE_TYPE_ROUTE,
    },
    {
        path: [
            '/community/:communityId/content-hub/:contentHubId/:tabs',
            '/community/:communityId/content-hub/:contentHubId/:tabs/:subTab',
        ],
        Component: ContentHubDashboard,
        type: PRIVATE_TYPE_ROUTE,
    },
    {
        path: ['/content-hub/:contentHubId'],
        Component: ContentHubPublicPage,
        type: PUBLIC_TYPE_ROUTE,
    },
    {
        path: ['/signin', '/signin/:screen'],
        Component: SignUpPage,
        type: PUBLIC_TYPE_ROUTE,
    },
    {
        path: '/pre-event-system-check',
        Component: PreCallTestPage,
        type: PUBLIC_TYPE_ROUTE,
    },
    {
        path: SSO_PATH,
        Component: SSO,
        type: PUBLIC_TYPE_ROUTE,
    },
    {
        path: [INVITE_MEMBER_PATH, `${INVITE_MEMBER_PATH}/:id`],
        Component: UserInvitePageScreen,
        type: PUBLIC_TYPE_ROUTE,
    },
    {
        path: '/stripe/kyc',
        Component: StripeKyc,
        type: PRIVATE_TYPE_ROUTE,
    },
    {
        path: '/stripe/oauth/redirect',
        Component: StripeResult,
        type: PUBLIC_TYPE_ROUTE,
    },
    {
        path: '/integrations',
        Component: Integrations,
        type: PUBLIC_TYPE_ROUTE,
    },
    {
        path: '/integrations/auth/callback',
        Component: IntegrationAuthCallbackPopup,
        type: PUBLIC_TYPE_ROUTE,
    },
    {
        path: [ONBOARDING_PATH],
        Component: OnboardingV3,
        type: PRIVATE_TYPE_ROUTE,
    },
    {
        path: '/emails/:id/unsubscribe',
        Component: UnsubscribeEmails,
        type: PUBLIC_TYPE_ROUTE,
    },
    {
        path: '/:thirdParty',
        Component: ThirdPartyCoupon,
        type: PUBLIC_TYPE_ROUTE,
    },
    {
        path: '/video-editor/:id',
        Component: VideoEditor,
        type: PRIVATE_TYPE_ROUTE,
    },
];

// Development only
if (process.env.REACT_APP_ENV !== 'production') {
    const DevelopmentEnvFlag = React.lazy(() =>
        import('./views/development/EnvFlag')
    );
    routes.push({
        path: `/development/env`,
        Component: DevelopmentEnvFlag,
        type: PUBLIC_TYPE_ROUTE,
    });
}

function Routes() {
    usePageStyle({ fillHeight: true });
    useGTMSegmentation();
    useInitFullStory();

    return (
        <Switch>
            {routes.map(({ path, Component, type, ...props }) => {
                if (type === PUBLIC_TYPE_ROUTE)
                    return (
                        <PublicRoute
                            restricted={false}
                            key={path}
                            component={Component}
                            path={path}
                            exact
                            {...props}
                        />
                    );
                if (type === RESTRICTED_TYPE_ROUTE)
                    return (
                        <PublicRoute
                            restricted={true}
                            key={path}
                            component={Component}
                            path={path}
                            exact
                            {...props}
                        />
                    );
                if (type === MOBILE_WEBVIEW_ROUTE)
                    return (
                        <AppWebViewRoute
                            key={path}
                            component={Component}
                            path={path}
                            exact
                            {...props}
                        />
                    );
                if (type === PRIVATE_TYPE_ROUTE)
                    return (
                        <PrivateRoute
                            key={path}
                            component={Component}
                            path={path}
                            exact
                            {...props}
                        />
                    );
                return path;
            })}
            <Route component={NotFound} />
        </Switch>
    );
}

export default Routes;
