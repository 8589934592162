import {
    VERIFY_EMAIL_REQUEST,
    VERIFY_EMAIL_SUCCESS,
} from '../actions/verifyEmail';

const initialState = {
    error: null,
    token: null,
    processing: false,
};

function emailVerification(state = initialState, { type, payload }) {
    switch (type) {
        case VERIFY_EMAIL_REQUEST:
            return {
                ...state,
                error: null,
                processing: true,
            };
        case VERIFY_EMAIL_SUCCESS:
            if (payload instanceof Error) {
                return {
                    ...state,
                    error: payload.json.message,
                    processing: false,
                };
            }

            const { token } = payload;
            return {
                ...state,
                token,
                processing: false,
            };
        default:
            return state;
    }
}

export default emailVerification;
