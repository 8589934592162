import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import Button from 'atoms/Button/button';
import { Typography } from 'foundations/typography';
import { StyledSvgSprite } from 'components/general/SVGSprite';

function OnBoardingCmDetails({ imgUrl, name }) {
    return (
        <Wrapper>
            <AvatarImgWrap mr='20px'>
                <StyledSvgSprite
                    icon={'icon-separator'}
                    height={'24px'}
                    width={'24px'}
                />
            </AvatarImgWrap>
            <AvatarImgWrap mr='12px'>
                <AvatarImg src={imgUrl || ''} alt={'avatar-img'} />
            </AvatarImgWrap>
            <Typography variant={'subtitle1'} color={'ambience.8'}>
                {name || ''}
            </Typography>
        </Wrapper>
    );
}

export default OnBoardingCmDetails;

const Wrapper = styled.div`
    display: flex;
    width: auto;
    align-items: center;
`;

const AvatarImgWrap = styled.div`
    ${space}
`;

const AvatarImg = styled.img`
    height: 29px;
    width: 29px;
    border-radius: 100%;
`;
