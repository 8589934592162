/* eslint-disable no-undef */
import { stringify } from 'querystring';
import { isWorkerThread } from 'utils/constants/workers';
import { logErrorForCH } from './cloudHostLog';
import {
    REACT_APP_AIRGENIE_BASE_URL,
    REACT_APP_ANALYTICS_BASE_URL,
    REACT_APP_API_BASE_URL,
    REACT_APP_BFF_BASE_URL,
    REACT_APP_CAS_API_BASE_URL,
    REACT_APP_CAS_BFF_BASE_URL,
    REACT_APP_CONTENT_HUB_BASE_URL,
    REACT_APP_INTERCEPTOR_BASE_URL,
    REACT_APP_NOTIFICATION_BASE_URL,
    REACT_APP_OPS_BASE_URL,
    REACT_APP_PERFLOW_BASE_URL,
    REACT_APP_PLATFORM_BASE_URL,
    REACT_APP_POLYGLOT_BASE_URL,
} from './constants/airmeet';

const fetchWithRetry = require('fetch-retry')(fetch);

const convertJson = (response) => {
    if (response.status === 204) {
        return { json: {}, response };
    }
    return response.json().then((json) => ({ json, response }));
};

const convertErrors = async (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    const error = new Error(response.statusText);
    error.status = response.status;
    error.response = response;
    logErrorForCH(response.url, response.status);
    await response
        .json()
        .then((json) => (error.json = json))
        .catch();
    throw error;
};

const tryStoredToken = async () => {
    if (isWorkerThread) {
        return null;
    }

    const { default: service } = await import('utils/authService');
    return service.token;
};

/**
 *  Service Name Constants
 * */
export const API_SERVICE = {
    ZEUS: 'ZEUS',
    BFF: 'BFF',
    POLL: 'POLL',
    PLATFORM: 'PLATFORM',
    PLATFORM_MULTI_REGION: 'PLATFORM_MULTI_REGION',
    ANALYTICS: 'ANALYTICS',
    NOTIFICATION: 'NOTIFICATION',
    OPS: 'OPS',
    INTERCEPTOR: 'INTERCEPTOR',
    CONTENT_HUB: 'CONTENT_HUB',
    CAS_ZEUS: 'CAS_ZEUS',
    CAS_BFF: 'CAS_BFF',
    PERFLOW: 'PERFLOW',
    AIRGENIE: 'AIRGENIE',
    POLYGLOT: 'POLYGLOT',
};

/**
 * This function gives back the base url for a service
 * @param {string} service service name
 * @returns Base URL for the service from env. defaults to ZEUS
 */
export const getServiceBaseUrl = (service) => {
    const {
        BFF,
        POLL,
        PLATFORM,
        ANALYTICS,
        NOTIFICATION,
        CONTENT_HUB,
        OPS,
        INTERCEPTOR,
        CAS_ZEUS,
        PLATFORM_MULTI_REGION,
        CAS_BFF,
        PERFLOW,
        AIRGENIE,
        POLYGLOT,
    } = API_SERVICE;
    switch (service) {
        case BFF:
            return REACT_APP_BFF_BASE_URL;
        case POLL:
            return process.env.REACT_APP_POLL_BASE_URL;
        case PLATFORM:
            return REACT_APP_PLATFORM_BASE_URL;
        case PLATFORM_MULTI_REGION:
            return '';
        case ANALYTICS:
            return REACT_APP_ANALYTICS_BASE_URL;
        case NOTIFICATION:
            return REACT_APP_NOTIFICATION_BASE_URL;
        case OPS:
            return REACT_APP_OPS_BASE_URL || REACT_APP_API_BASE_URL;
        case INTERCEPTOR:
            return REACT_APP_INTERCEPTOR_BASE_URL;
        case CONTENT_HUB:
            return REACT_APP_CONTENT_HUB_BASE_URL;
        case CAS_ZEUS:
            return REACT_APP_CAS_API_BASE_URL;
        case CAS_BFF:
            return REACT_APP_CAS_BFF_BASE_URL;
        case PERFLOW:
            return REACT_APP_PERFLOW_BASE_URL;
        case AIRGENIE:
            return REACT_APP_AIRGENIE_BASE_URL;
        case POLYGLOT:
            return REACT_APP_POLYGLOT_BASE_URL;
        // Fallback to ZEUS for default
        default:
            return REACT_APP_API_BASE_URL;
    }
};

const DEFAULT_RETRY_CONFIG = {
    retries: 2,
    retryDelay: 2000,
    retryOn: [500, 502, 503],
};

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
export async function callApi({
    endpoint: base,
    method = 'GET',
    headers = {},
    query = {},
    body = null,
    type = null,
    page = null,
    token = null,
    transform = null,
    service = null,
    credentials = 'include',
    appendBaseUrl = true,
    withRetry = false,
    canIgnoreCredentials = true,
    forceSkipRetry = false,
    returnResponse = false,
    retryConfigOverrides = {},
}) {
    const BASE_URL = getServiceBaseUrl(service);
    token = token || (await tryStoredToken());

    if (typeof base !== 'string') {
        throw new Error('Specify a string endpoint URL.');
    }

    // Attach token if provided
    if (token) {
        headers['X-AccessToken'] = token;
    }

    // Pagination
    if (page != null) {
        query.page = page;
    }

    // Normalize the URL
    const endpoint =
        appendBaseUrl && base.indexOf(BASE_URL) === -1 ? BASE_URL + base : base;

    // Add headers
    headers = {
        Accept: 'application/json',
        ...headers,
    };

    if (type === 'json') {
        body = body ? JSON.stringify(body) : null;
        if (body || method !== 'GET') {
            headers['Content-Type'] = 'application/json';
        }
    }

    const opts = {
        method,
        headers,
        body,
        credentials:
            canIgnoreCredentials && headers['X-AccessToken']
                ? 'omit'
                : credentials,
    };

    // If query params exist, add them
    const fullUrl = `${endpoint}${
        Object.keys(query).length ? `?${stringify(query)}` : ''
    }`;

    if (!forceSkipRetry && (method === 'GET' || withRetry)) {
        return fetchWithRetry(fullUrl, {
            ...opts,
            ...DEFAULT_RETRY_CONFIG,
            ...retryConfigOverrides,
        })
            .then(convertErrors)
            .then(convertJson)
            .then(({ json, response }) => {
                if (transform) {
                    return transform(json);
                }
                return returnResponse ? { json, response } : json;
            })
            .catch((err) => {
                if (err.json && err.json.message) {
                    err.message = err.json.message;
                }
                throw err;
            });
    } else {
        return fetch(fullUrl, opts)
            .then(convertErrors)
            .then(convertJson)
            .then(({ json, response }) => {
                if (transform) {
                    return transform(json);
                }
                return json;
            })
            .catch((err) => {
                if (err.json && err.json.message) {
                    err.message = err.json.message;
                }
                throw err;
            });
    }
}
