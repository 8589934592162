import { FlexDiv } from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import { Typography } from 'foundations/typography';
import React from 'react';
import styled from 'styled-components';
import SearchFilterInput from './SearchFilterInput';

export default function SearchFilter({
    tags,
    options,
    selectedOptions,
    setSelectedOptions,
    type = 'session',
    icon = 'icon-session-person',
    isDropdownVisible,
    setIsDropdownVisible,
}) {
    return (
        <FlexDiv pt={2}>
            <FilterTagsWrapper>
                <FilterTag>
                    <Icon
                        icon={icon}
                        height='16px'
                        width='16px'
                        mr='6px'
                        fill='accentPrimary.0'
                    />
                    <Typography variant='caption' color='ambience.0'>
                        {Array.isArray(selectedOptions) &&
                        selectedOptions.length > 0
                            ? `Selected ${type}${
                                  selectedOptions.length === 1 ? '' : 's'
                              } (${selectedOptions.length})`
                            : `All ${type}s`}
                    </Typography>
                </FilterTag>
            </FilterTagsWrapper>
            <SearchFilterInput
                tags={tags}
                options={options}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                type={type}
                isDropdownVisible={isDropdownVisible}
                setIsDropdownVisible={setIsDropdownVisible}
            />
        </FlexDiv>
    );
}

const FilterTagsWrapper = styled(FlexDiv)`
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
`;

const FilterTag = styled(FlexDiv)`
    background: rgba(157, 154, 172, 0.16);
    border: 1px solid ${({ theme }) => theme.colors.accentPrimary[0]};
    border-radius: 16px;
    padding: 8px 12px;
    align-items: center;
`;
