import identity from 'lodash/identity';
import { useContext, useMemo } from 'react';
import Session from '../context/Session';

/**
 * Accepts a selector function which can be used to select a value at any
 * level of the Session context, like the `useSelector` hook from redux
 *
 * @param {(context) => {}} callback
 */
function useSessionContext(callback = identity) {
    // The session context
    const ctx = useContext(Session);
    // Return the up-to-date selected value
    const finalValue = callback(ctx);
    return useMemo(() => finalValue, [finalValue]);
}
export default useSessionContext;
