import Box from 'atoms/Box';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import React from 'react';
import styled from 'styled-components';
import { cdnImage } from 'utils/core';

const QuickTip = ({ quickTipDesc, isThemeV2 = false }) => (
    <Wrapper
        justifyContent='space-between'
        alignItems={isThemeV2 ? 'flex-start' : 'center'}
        mt={isThemeV2 ? 'x6' : '16px'}
        isThemeV2={isThemeV2}
    >
        {isThemeV2 ? (
            <>
                <Background />
                <StyledSvgSprite
                    icon='tips-updates'
                    width='1.5rem'
                    height='1.5rem'
                    mr='x2'
                    fill='sem.info'
                />
            </>
        ) : null}
        <Box maxWidth={isThemeV2 ? '' : '282px'}>
            <QuickTipTitle
                variant={isThemeV2 ? 'caption-bold' : 'subtext0-bold'}
                mb={isThemeV2 ? 'x1' : '8px'}
                isThemeV2={isThemeV2}
            >
                Quick tip
            </QuickTipTitle>
            <QuickTipDesc
                isThemeV2={isThemeV2}
                variant={isThemeV2 ? 'caption' : 'subtext2'}
            >
                {quickTipDesc ||
                    'Make sure to add a small introduction to your video to ease-in the attendees.'}
            </QuickTipDesc>
        </Box>
        {isThemeV2 ? null : (
            <img
                src={cdnImage('/images/dashboard/quick-tip.svg')}
                alt={'quick tip'}
                width={42}
                height={100}
            />
        )}
    </Wrapper>
);

const QuickTipDesc = styled(Typography)`
    color: ${({ theme, isThemeV2 }) =>
        isThemeV2
            ? theme.colors.text.default.primary
            : theme?.isLightTheme
            ? theme.colors.ambience[16]
            : theme.colors.ambience[9]};
`;

const QuickTipTitle = styled(Typography)`
    color: ${({ theme, isThemeV2 }) =>
        isThemeV2
            ? theme.colors.text.default.primary
            : theme?.isLightTheme
            ? theme.colors.ambience[16]
            : theme.colors.ambience[15]};
`;

const Wrapper = styled(FlexDiv)`
    ${({ isThemeV2, theme }) =>
        isThemeV2
            ? `
        position: relative;
        padding: ${theme.space.x3} ${theme.space.x7} ${theme.space.x3} ${theme.space.x4};
        border-radius: ${theme.radii.default};
    `
            : ''}
`;

const Background = styled(FlexDiv)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.2;
    background-color: ${({ theme }) => theme.colors.sem.info};
    border-radius: ${({ theme }) => theme.radii.default};
`;

export default QuickTip;
