import keys from 'locale/keys';
import React from 'react';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import { useSelector } from 'react-redux';
import LowNetworkSwitchResolution from '../allOnline/ResolutionChangeButton/LowNetworkSwitchResolution';

const NetworkStatus = () => {
    const { isLive, isBackStage } = useSelector((state) => state.layout.header);
    const { t } = useCustomTranslation();
    return (
        <div className='top-info-msg network-status'>
            <span>{t(keys.NETWORK_MESSAGE_UNSTABLE_INTERNET)}</span>
            {(isLive || isBackStage) && <LowNetworkSwitchResolution />}
        </div>
    );
};
export default NetworkStatus;
