import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';

const { CHAT_REACTION } = FEATURE_NAMES;
enum API_CHAT_TYPE {
    SESSION_FEED = 'SESSION_CHAT',
    EVENT_FEED = 'EVENT_CHAT',
}
const chatReactionWriter = async ({ airmeetId, client, metaData, logger }) => {
    if (!airmeetId) {
        throw new Error('Invalid chat reaction firebase key');
    } else {
        const chatType = metaData.chatType
            ? metaData.chatType
            : API_CHAT_TYPE.EVENT_FEED;
        let reactionBasePath;
        let chatReactionUserPath;
        if (chatType === API_CHAT_TYPE.EVENT_FEED) {
            reactionBasePath = `${airmeetId}/chats/General/${metaData.chatKey}/chatReaction/${metaData.reactionType}/count`;
            chatReactionUserPath = `/${airmeetId}/chats/reactions/${metaData.chatKey}/${metaData.reactionType}/${metaData.userId}/timestamp`;
        } else {
            if (!metaData.sessionId) {
                throw new Error('Session Id missing in metaData');
            }
            reactionBasePath = `${airmeetId}/liveAirmeet/sessions/${metaData.sessionId}/messages/${metaData.chatKey}/chatReaction/${metaData.reactionType}/count`;
            chatReactionUserPath = `/${airmeetId}/liveAirmeet/sessions/${metaData.sessionId}/reactions/${metaData.chatKey}/${metaData.reactionType}/${metaData.userId}/timestamp`;
        }
        switch (metaData.action) {
            case FEATURE_ACTIONS[CHAT_REACTION].TOGGLE_REACTION:
                logger.info('Adding New Reaction');
                const updateData = {};
                updateData[reactionBasePath] = metaData.selfLike
                    ? client.getIncrementRef(1)
                    : client.getIncrementRef(-1);
                updateData[chatReactionUserPath] = metaData.selfLike
                    ? Date.now()
                    : null;

                if (!metaData.chatKey || !metaData.userId) {
                    throw new Error('Invalid add comment firebase key');
                }
                return client.atomicUpdate(updateData);
            default:
                return null;
        }
    }
};

export default chatReactionWriter;
