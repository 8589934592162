import { motion } from 'framer-motion';
import styled from 'styled-components';
import { background, color } from 'styled-system';

export const RadioPill = styled.button`
    padding: 8px 12px;
    border-radius: 12px;
    font-size: 14px;
    line-height: 16px;
    box-shadow: none;
    outline: none;
    margin-right: 16px;
    transition: all 0.3s;
    color: ${({ checked, theme }) =>
        checked
            ? theme.colors.ambience[theme?.isLightTheme ? 0 : 0]
            : theme.colors.ambience[theme?.isLightTheme ? 0 : 4]};
    background-color: ${({ checked, theme }) =>
        checked
            ? theme.colors.ambience[theme?.isLightTheme ? 23 : 22]
            : theme?.isLightTheme
            ? theme.colors.ambience[23]
            : 'rgba(38, 37, 45, 0.64)'};
    border: 1px solid
        ${({ checked, theme }) =>
            checked
                ? theme.colors.accentPrimary[0]
                : theme.colors.ambience[20]};
    font-weight: ${(props) => (props.checked ? 'bold' : 'normal')};
    &:focus {
        box-shadow: none;
        outline: none;
    }
`;

export const ListItem = styled.li`
    display: flex;
    align-items: center;
    padding: 4px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.ambience[21]};
    cursor: pointer;
    transition: all 0.3s;
    background-color: ${({ selected, theme }) =>
        selected ? theme.colors.ambience[23] : theme.colors.ambience[24]};
    border-left: solid 4px
        ${({ selected, theme }) =>
            selected ? theme.colors.accentPrimary[0] : 'transparent'};
    &:hover {
        background-color: ${({ theme }) => theme.colors.ambience[23]};
        border-left: solid 4px ${({ theme }) => theme.colors.accentPrimary[0]};
    }
`;

export const ProgressContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
`;

export const Dot = styled(motion.div)`
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    ${background}
    ${color}
    cursor: pointer;
`;

export const EmptyAvatar = styled.div`
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: ${({ theme }) => theme.colors.ambience[21]};
`;

export const EmptyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    justify-content: stretch;
    padding-top: 44px;
    height: 100%;
`;

export const EmptyContent = styled.div`
    width: 64px;
    height: 8px;
    background: ${({ theme }) => theme.colors.ambience[21]};
    border-radius: 6px;
    flex-grow: 1;
`;
