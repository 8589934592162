import classnames from 'classnames';
import LoaderV2 from 'components/general/LoaderV2';
import { TruncatedTypography } from 'components/molecules/TruncatedTypography/TruncatedTypography';
import { Box } from 'components/molecules/utils/basicComponents';
import useTheme from 'hooks/useTheme';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ActionItem from './actionItem';
import RowSelector from './rowSelector';

const Actions = ({ actions, row, liveEvent }) => (
    <>
        {!row.actionItemDisabled &&
            actions.map((action, id) => {
                if (
                    Array.isArray(row.visibleActions) &&
                    row.visibleActions.length
                ) {
                    if (row.visibleActions.includes(action?.actionType))
                        return (
                            <ActionItem
                                key={`action-${id}`}
                                {...action}
                                row={row}
                                isDisabled={row?.shouldDisable}
                                liveEvent={liveEvent}
                            />
                        );
                    else return null;
                }

                return (
                    <ActionItem
                        key={`action-${id}`}
                        {...action}
                        row={row}
                        isDisabled={row?.shouldDisable}
                        liveEvent={liveEvent}
                    />
                );
            })}
    </>
);

const TBody = ({
    headers,
    rowData,
    actions,
    isLoading,
    rowSelection,
    onSelect,
    actionsWidth,
    noDataMsg,
    renderEmptyState,
    checkboxBgColor = null,
    forceLoading = false,
    liveEvent = false,
}) => {
    let emptyColSpan = (headers || []).length;
    if (rowSelection) {
        emptyColSpan = emptyColSpan + 1;
    }
    if (actions) {
        emptyColSpan = emptyColSpan + 1;
    }
    const { theme } = useTheme();
    return (
        <tbody>
            {forceLoading && isLoading
                ? null
                : rowData &&
                  rowData.map((row, id) => (
                      <tr
                          key={`tr-${id}`}
                          className={
                              row._isSelectionDisabled ? 'rowDisabled' : ''
                          }
                      >
                          {rowSelection && (
                              <td
                                  className={'checkbox-outer'}
                                  style={{
                                      backgroundColor:
                                          row?.attendee_status === 'BLOCKED' &&
                                          (theme?.isLightTheme
                                              ? '#F9D8D8'
                                              : '#311C1C'),
                                  }}
                              >
                                  {!row._isSelectionHidden && (
                                      <RowSelector
                                          id={`check-${id}`}
                                          className={'row-checkbox'}
                                          onSelect={(checked) =>
                                              onSelect(row, checked)
                                          }
                                          checked={row._isSelected}
                                          disabled={row._isSelectionDisabled}
                                          checkboxBgColor={checkboxBgColor}
                                      />
                                  )}
                              </td>
                          )}
                          {headers.map((header, id) => {
                              return row.colSpanConfig &&
                                  row.colSpanConfig[header.key] ===
                                      -1 ? null : (
                                  <TableCell
                                      key={`td-${id}`}
                                      className={header.colClass}
                                      cellClass={header.cellClass}
                                      cellKey={header.key}
                                      value={
                                          header.render
                                              ? header.render(row)
                                              : row[header.key]
                                      }
                                      width={header.width}
                                      colSpan={
                                          row.colSpanConfig
                                              ? row.colSpanConfig[header.key]
                                              : 0
                                      }
                                      truncate={header.truncate}
                                      truncateTextVariant={
                                          header.truncateTextVariant
                                      }
                                      truncateTextColor={
                                          header.truncateTextColor
                                      }
                                      textWrapperWidth={header.textWrapperWidth}
                                      truncateTextFontSize={
                                          header.truncateTextFontSize
                                      }
                                      truncateToolClass={
                                          header.truncateToolClass
                                      }
                                      theme={theme}
                                      background={
                                          row?.attendee_status === 'BLOCKED' &&
                                          (theme?.isLightTheme
                                              ? '#F9D8D8'
                                              : '#311C1C')
                                      }
                                  />
                              );
                          })}
                          {actions && (
                              <td
                                  className='action-body text-center'
                                  style={
                                      actionsWidth
                                          ? {
                                                width: actionsWidth,
                                            }
                                          : {}
                                  }
                              >
                                  <Actions
                                      liveEvent={liveEvent}
                                      actions={actions}
                                      row={row}
                                  />
                              </td>
                          )}
                      </tr>
                  ))}
            {((forceLoading && isLoading) ||
                !rowData ||
                rowData.length === 0) &&
                (renderEmptyState() ? (
                    renderEmptyState()
                ) : (
                    <EmptyTableState
                        isLoading={isLoading}
                        colSpan={emptyColSpan}
                        noDataMsg={noDataMsg}
                    />
                ))}
        </tbody>
    );
};

const TableCell = ({
    cellKey,
    cellClass = '',
    className = '',
    value,
    width,
    colSpan = 0,
    truncate = false,
    truncateTextVariant = 'subtext1',
    truncateTextColor = 'ambience.8',
    truncateTextFontSize = '14px',
    textWrapperWidth = '300px',
    truncateToolClass = 'participants-list-tooltip',
    background,
    theme,
}) => {
    return (
        <td
            style={{
                width: width ? width : null,
                background: background,
            }}
            className={classnames(cellClass)}
            colSpan={colSpan}
        >
            {truncate ? (
                <Box
                    className={classnames(cellKey)}
                    maxWidth={textWrapperWidth}
                >
                    <StyledTruncatedTypography
                        variant={truncateTextVariant}
                        text={value}
                        color={truncateTextColor}
                        tooltipPlace='bottom'
                        toolClass={truncateToolClass}
                        tooltipStyle={{
                            color: theme.colors.ambience[0],
                        }}
                        truncateTextFontSize={truncateTextFontSize}
                    />
                </Box>
            ) : (
                <div className={classnames(cellKey, className)}>{value}</div>
            )}
        </td>
    );
};

const EmptyTableState = ({ isLoading, colSpan, noDataMsg }) => {
    return (
        <tr>
            <td colSpan={colSpan}>
                {isLoading && <LoaderV2 size={20} />}
                {!isLoading &&
                    (noDataMsg ? (
                        noDataMsg
                    ) : (
                        <div className='no-record'>No records found</div>
                    ))}
            </td>
        </tr>
    );
};

TBody.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.object),
    rowData: PropTypes.arrayOf(PropTypes.object),
    actions: PropTypes.arrayOf(PropTypes.object),
};

TableCell.propTypes = {
    cellKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
};

export default TBody;
const StyledTruncatedTypography = styled(TruncatedTypography)`
    ${({ truncateTextFontSize }) =>
        truncateTextFontSize &&
        `
        font-size: ${truncateTextFontSize};
    `}
`;
