export const LAYOUT_ITEM = {
    BACKGROUND_IMAGE: 'BACKGROUND_IMAGE',
    VIDEO: 'VIDEO',
    LINK: 'LINK',
    IFRAMEPOPOVER: 'IFRAMEPOPOVER',
    LIVE_UPCOMING_SESSIONS: 'LIVE_UPCOMING_SESSIONS',
};

export class SessionWidgetUtil {
    static defaultPerPage = 1;
    static defaultAutoScroll = false;
    static minPerPage = 1;
    static maxPerPage = 2;

    static getZoomRatio(containerWidth, outerWidth = window.outerWidth) {
        return outerWidth / containerWidth;
    }
    static getZoomLevel(containerWidth) {
        return Math.floor(SessionWidgetUtil.getZoomRatio(containerWidth) * 100);
    }
    static getWrapperZoomWidth(containerWidth, wrapperWidth) {
        return SessionWidgetUtil.getZoomRatio(containerWidth) * wrapperWidth;
    }
    static getAspectRatio(containerWidth) {
        return (2.2 * SessionWidgetUtil.getZoomLevel(containerWidth)) / 100;
    }
    static getMinHeight(containerWidth) {
        return 350 / SessionWidgetUtil.getAspectRatio(containerWidth);
    }
    static getMinWidth(perPage) {
        return 350 * perPage;
    }

    static getGridBoxWidth(boxWidth, wrapperWidth) {
        return Math.floor(boxWidth / (LAYOUT_GRID_COLUMNS / wrapperWidth));
    }

    static getItemWidth(wrapperWidth) {
        return wrapperWidth / 1.8;
    }
    static getWidth(wrapperWidth, perPage) {
        return SessionWidgetUtil.getItemWidth(wrapperWidth) * perPage;
    }

    static getAutoScroll(item) {
        return item['meta']['autoScroll'];
    }

    static getPerPage(item) {
        return item['meta']['perPage'];
    }

    static getTitle(item) {
        return item['meta']['title'];
    }

    static updateSessionWidgetConfig(
        itemData,
        updatedConfig,
        wrapperWidth,
        containerWidth,
        resolver
    ) {
        const updatedPerPage = updatedConfig.perPage;
        const isPerPageUpdated =
            updatedPerPage !== SessionWidgetUtil.getPerPage(itemData);
        const isTitleUpdated =
            updatedConfig.title !== SessionWidgetUtil.getTitle(itemData);
        const isAutoScrollUpdated =
            updatedConfig.autoScroll !==
            SessionWidgetUtil.getAutoScroll(itemData);
        const zoomWidth = SessionWidgetUtil.getWrapperZoomWidth(
            containerWidth,
            wrapperWidth
        );

        if (isPerPageUpdated) {
            const boxWidth = SessionWidgetUtil.getWidth(
                zoomWidth,
                updatedPerPage
            );
            itemData['meta']['perPage'] = updatedPerPage;
            itemData['meta'][
                'gridBoxWidthDefault'
            ] = SessionWidgetUtil.getGridBoxWidth(boxWidth, wrapperWidth);
            itemData['meta']['itemWidthDefault'] = boxWidth;
            itemData['w'] =
                itemData['h'] *
                updatedPerPage *
                SessionWidgetUtil.getAspectRatio(containerWidth);
            itemData['wRatio'] = itemData['w'] / containerWidth;
            itemData['i'] = Math.random().toString();
            itemData['minW'] = SessionWidgetUtil.getMinWidth(updatedPerPage);
            itemData['minH'] = SessionWidgetUtil.getMinHeight(containerWidth);
        }
        itemData['meta']['title'] = updatedConfig.title;
        itemData['meta']['autoScroll'] = updatedConfig.autoScroll;

        const hasChange =
            isTitleUpdated || isPerPageUpdated || isAutoScrollUpdated;

        if (hasChange && resolver) {
            resolver(itemData);
        }

        return hasChange;
    }

    static getDefaultItemConfigs(
        wrapperWidth,
        containerWidth,
        { defaultTitle }
    ) {
        const zoomWidth = SessionWidgetUtil.getWrapperZoomWidth(
            containerWidth,
            wrapperWidth
        );
        const perPage = SessionWidgetUtil.defaultPerPage;
        const boxWidth = SessionWidgetUtil.getWidth(zoomWidth, perPage);
        return {
            dimension: {
                w: boxWidth,
                h:
                    boxWidth /
                    perPage /
                    SessionWidgetUtil.getAspectRatio(containerWidth),
                minW: SessionWidgetUtil.getMinWidth(perPage),
                minH: SessionWidgetUtil.getMinHeight(containerWidth),
                i: Math.random().toString(),
            },
            meta: {
                gridBoxWidthDefault: SessionWidgetUtil.getGridBoxWidth(
                    boxWidth,
                    wrapperWidth
                ),
                itemWidthDefault: boxWidth,
                perPage,
                title: defaultTitle,
                autoScroll: SessionWidgetUtil.defaultAutoScroll,
            },
        };
    }
}

export const LAYOUT_GRID_COLUMNS = 1200;

export const LAYOUT_TYPES = {
    DEFAULT: 'default',
    CUSTOM: 'custom',
    TEMPLATE: 'template',
    POSTER: 'poster',
};

export const CUSTOM_EDIT_LAYOUT = 'edit_custom';

export const LAYOUT_VERSION = '1';

export const RECEPTION_BACKGROUND_IMAGE = 'RECEPTION_BACKGROUND_IMAGE';
export const BOOTH_BACKGROUND_IMAGE = 'BOOTH_BACKGROUND_IMAGE';
export const RECEPTION = 'RECEPTION';
export const BOOTH = 'BOOTH';
export const ARENA = 'ARENA';
export const RESOURCES = 'RESOURCES';

export const LINK_OPTIONS = {
    [RECEPTION]: [
        {
            value: 'lounge',
            label: 'Lounge',
        },
        {
            value: 'arena',
            label: 'Booths',
        },
        {
            value: 'sessions',
            label: 'Schedule',
        },
        {
            value: 'speedNetworking',
            label: 'Speed Networking',
        },
        {
            value: 'external',
            label: 'External Link',
        },
    ],
};
