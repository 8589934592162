import SecondaryFirebaseClient from 'containers/SecondaryFirebaseClient';
import useToasts from 'hooks/useToasts';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'store/actions/auth';
import { updateFirwallBlockState } from 'store/actions/genral';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import { webRTCFirewallDetectionStates } from 'utils/constants/live-airmeet';
import {
    QOEUserDropLogger,
    userDropCases,
} from 'utils/constants/qoeLogConstants';
import { logger } from 'utils/logger';
import FirebaseClient, {
    FIREBASE_ERROR_CODES,
    FIREBASE_ERROR_MESSAGES,
} from '../containers/FirebaseClient';
import ReplicaFirebaseClient from '../containers/ReplicaFirebaseClient';
import WriterReplicaFirebaseClient from '../containers/WriterReplicaFirebaseClient';
export default function useFirebaseClient(
    authId,
    airmeetId,
    shardInfo,
    options = { primary: true }
) {
    const dispatch = useDispatch();

    const { errorToast } = useToasts();
    const [firebaseClient, setFirebaseClient] = useState(null);
    const shardId = shardInfo?.shardId;
    const firebaseProxyConfig = options?.firebaseProxyConfig;
    useEffect(() => {
        // Can pass default empty object, will connect default db in this scenario
        if (!shardInfo || !authId) return;

        const appName = `liveAirmeet_${authId}_${airmeetId}`;

        const flagInfo = {
            isLongPollingDisabled: options?.isLongPollingDisabled,
        };

        let instance;
        if (options?.advancedConfig?.writer) {
            instance = new WriterReplicaFirebaseClient(
                appName,
                shardInfo,
                flagInfo,
                firebaseProxyConfig,
                options?.advancedConfig?.writer,
                options.primary
            );
            instance.resetWritesProxy();
        } else if (options?.primary === true) {
            instance = new FirebaseClient(
                appName,
                shardInfo,
                flagInfo,
                firebaseProxyConfig
            );
        } else if (options?.writeReplicas?.length > 0) {
            instance = new ReplicaFirebaseClient(
                appName,
                shardInfo,
                flagInfo,
                firebaseProxyConfig
            );
        } else {
            instance = new SecondaryFirebaseClient(
                appName,
                shardInfo,
                flagInfo,
                firebaseProxyConfig
            );
        }

        if (options.source) {
            logger.debug(
                `${options.source}${options.primary ? '|primary' : ''} using`,
                shardInfo.shardId,
                options.writeReplicas
            );
        }

        if (options?.writeReplicas?.length > 0) {
            instance.setWriteReplicas(
                options.writeReplicas.map((config) => {
                    let replica = new FirebaseClient(
                        `liveAirmeet_${authId}_${airmeetId}`,
                        config,
                        firebaseProxyConfig
                    );
                    // replicas need not init
                    replica.authId = authId;
                    return replica;
                })
            );
        }

        const onTokenRefresh = ({ user, error }) => {
            logger.info('onTokenRefresh - token refresh event', {
                user,
                error,
            });
            if (getAirmeetUtilInstance()) {
                QOEUserDropLogger(userDropCases.FIREBASE_TOKEN_EXPIRED.label);
            }
            if (error) {
                if (
                    error?.message ===
                    FIREBASE_ERROR_MESSAGES.UNABLE_TO_LOAD_IN_TIME
                ) {
                    logger.error(
                        'Error in loading events check in time, error event handler',
                        { error, options, shardInfo }
                    );
                } else {
                    logger.error(
                        'Error in Firebase client init, error event handler',
                        { error, options, shardInfo }
                    );
                }

                if (error.code === FIREBASE_ERROR_CODES.CONNECTION_TIMEOUT) {
                    logger.info(
                        'Dispatching firewall block state from firebase error event handler',
                        { error, options, shardInfo }
                    );
                    dispatch(
                        updateFirwallBlockState(
                            webRTCFirewallDetectionStates.firebaseFailed
                        )
                    );
                } else if (
                    error instanceof Error &&
                    error.message === FIREBASE_ERROR_CODES.INVALID_AUTH_USER
                ) {
                    logger.info(
                        'logging out user from firebase error event handler',
                        { error, options, shardInfo }
                    );
                    dispatch(logoutUser());
                    errorToast(
                        'Could not authenticate your request. Please try logging in again.'
                    );
                } else {
                    logger.info('reloading from firebase error event handler', {
                        error,
                        options,
                        shardInfo,
                    });
                    window.location.reload();
                }
            }
        };

        if (options?.primary) {
            logger.info('Adding token-refresh handler', {
                source: options,
                shardInfo,
            });
            instance.getEmitter().on('token-refresh', onTokenRefresh);
        }

        instance
            .init(authId, airmeetId)
            .then(() => {
                setFirebaseClient(instance);
            })
            .catch((error) => {
                logger.error(
                    'Error in Firebase client init, error catch handler',
                    error
                );
                onTokenRefresh({
                    user: null,
                    error: {
                        code: FIREBASE_ERROR_CODES.CONNECTION_TIMEOUT,
                    },
                });
            });

        return () => {
            if (options?.primary) {
                logger.info('Removing token-refresh handler', {
                    source: options,
                    shardInfo,
                });
                instance.getEmitter().off('token-refresh', onTokenRefresh);
            }
        };
    }, [authId, !!shardInfo, shardId]); // not adding options as they will never change and we do not want re-trigger

    return firebaseClient;
}
