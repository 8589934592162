import {
    ADD_TOAST,
    REMOVE_ALL_TOASTS,
    REMOVE_TOAST,
    REMOVE_TOASTS_BY_GROUP,
} from 'store/actions/toasts';

function toasts(state = [], action) {
    const { payload, type } = action;
    switch (type) {
        case ADD_TOAST:
            return [payload, ...state];
        case REMOVE_TOAST:
            return state.filter((toast) => toast.id !== payload);
        case REMOVE_TOASTS_BY_GROUP:
            return state.filter((toast) => toast.group !== payload);
        case REMOVE_ALL_TOASTS:
            return payload;
        default:
            return state;
    }
}

export default toasts;
