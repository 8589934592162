import { SET_BOOKMARKED_SESSIONS } from '../actions/bookmarkedSessions';

const initialState = {
    data: null,
};

function bookmarkedSessions(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case SET_BOOKMARKED_SESSIONS:
            return { ...state, data: payload };
        default:
            return state;
    }
}

export default bookmarkedSessions;
