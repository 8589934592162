import flow from 'lodash/flow';
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FirebaseStorageClient from '../../containers/FirebaseStorageClient';
import { STORAGE_KEY_USER } from '../../utils/constants/live-airmeet';

/**
 * React hook bindings for the `FirebaseStorageClient` interface
 * Implements a sub-set of functions that emulate localStorage on the browser:
 * `getItem`, `setItem`, `removeItem`
 */
function useUserStorage({ airmeetId, firebaseClient }) {
    const [userStorage, setUserStorage] = useState({});
    const [storageClient, setStorageClient] = useState(null);
    const authUser = useSelector((state) => state.auth.user);

    // Setup the storage client
    useEffect(() => {
        if (airmeetId && firebaseClient && authUser.id) {
            const instance = new FirebaseStorageClient(
                firebaseClient,
                airmeetId,
                authUser.id
            );

            setStorageClient(instance);
        }
    }, [airmeetId, firebaseClient, authUser.id]);

    // Hook on the update event from storage
    useEffect(() => {
        if (!storageClient) {
            return;
        }

        const onUpdate = ({ eventOverrides, ...rest }) =>
            setUserStorage({
                ...rest,
                ...((eventOverrides && eventOverrides[airmeetId]) || {}),
            });
        storageClient.on('storage-update', onUpdate);
        return () => storageClient.off('storage-update', onUpdate);
    }, [storageClient, airmeetId]);

    const _getItem = useCallback(
        (key) => memoize((storage, key) => get(storage, key))(userStorage, key),
        [userStorage]
    );

    const _setItem = useCallback(
        async ({
            key,
            value,
            external = null,
            updateValue = null,
            eventOnly = false,
        }) => {
            if (!storageClient) {
                return;
            }

            // Convert to Firebase type path
            key = key.replace(/\./g, '/');

            if (external) {
                if (!updateValue) {
                    // By default just replace the value at `key`
                    updateValue = () => value;
                }
                storageClient.externalStorageKey(
                    external,
                    key,
                    updateValue,
                    eventOnly
                );
            } else {
                storageClient.updateStorageKey(key, value, eventOnly);
            }
        },
        [storageClient, airmeetId]
    );

    /**
     * Memoized get for a key within the entire storage area
     */
    const getItem = _getItem;

    /**
     * Memoized get for a key within the public storage area
     */
    const getExternalItem = useCallback((key) => _getItem(`external.${key}`), [
        _getItem,
    ]);

    /**
     * Get for a key within browser session storage
     */
    const getSessionItem = useCallback(
        flow(
            _generateSessionItemKey,
            sessionStorage.getItem.bind(sessionStorage), // need to bind because getItem() depend on a context
            JSON.parse
        ),
        []
    );

    /**
     * Setter for any key in the entire storage area
     */
    const setItem = useCallback(
        async (key, value, eventOnly = false) =>
            _setItem({ key, value, eventOnly }),
        [_setItem]
    );

    /**
     * Setter for any key in the public storage area
     */
    const setExternalItem = useCallback(
        async (external, key, value, eventOnly) => {
            const isUpdateFunc = typeof value === 'function';
            return _setItem({
                key,
                value: isUpdateFunc ? null : value,
                external,
                updateValue: isUpdateFunc ? value : null,
                eventOnly,
            });
        },
        [_setItem]
    );

    /**
     * Setter for any key in the browser session storage
     */
    const setSessionItem = useCallback(
        (key, value) =>
            sessionStorage.setItem(
                _generateSessionItemKey(key),
                JSON.stringify(value)
            ),
        []
    );

    const removeItem = useCallback(
        async (key, eventOnly) => _setItem({ key, value: null, eventOnly }),
        [_setItem]
    );

    const removeExternalItem = useCallback(
        async (external, key, updateValue, eventOnly) =>
            _setItem({ key, external, value: null, updateValue, eventOnly }),
        [_setItem]
    );

    const removeSessionItem = useCallback(
        (key) => sessionStorage.removeItem(_generateSessionItemKey(key)),
        []
    );

    return {
        _TYPE: STORAGE_KEY_USER,
        value: userStorage,
        getItem,
        getExternalItem,
        getSessionItem,
        setItem,
        setExternalItem,
        setSessionItem,
        removeItem,
        removeExternalItem,
        removeSessionItem,
    };
}

const _generateSessionItemKey = (key) => `airmeet-${key}`;

export default useUserStorage;
