import { useLiveSessionContext } from 'context/Session';
import { useEffect } from 'react';
import { logger } from 'utils/logger';

const useStageMuteListener = ({
    isAudioMuted,
    stageService,
    isPersistentBackstageArea,
}) => {
    const stageAudioVolume = useLiveSessionContext(
        (ctx) => ctx.stageAudioVolume
    );
    const backstageAudioVolume = useLiveSessionContext(
        (ctx) => ctx.backstageAudioVolume
    );
    const audioVolume = isPersistentBackstageArea
        ? backstageAudioVolume
        : stageAudioVolume;

    const isChannelJoined = stageService?.channelJoined;
    useEffect(() => {
        if (!stageService || !isChannelJoined) {
            return;
        }
        logger.info('change volume locally', {
            audioVolume,
            isPersistentBackstageArea,
        });
        stageService.changeVolumeLevelLocally(audioVolume);
    }, [audioVolume, stageService, isChannelJoined, isPersistentBackstageArea]);

    return null;
};

export default useStageMuteListener;
