import Btn from 'atoms/Button/btn';
import {
    getEventEntryAccessOptions,
    getSeriesEntryAccessOptions,
} from 'components/community/editPublicAirmeet/constants';
import WithTooltip from 'components/general/WithTooltip';
import { MODAL_TYPE_EVENT_ENTRY } from 'components/modals';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import upperFirst from 'lodash/upperFirst';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showAppModal } from 'store/actions/layout';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import FormAccordion from '../FormAccordion';
import { ATTENDEE } from 'components/meteredPricing/MeterModal/constantsV2';

export default function EventEntryField({
    eventEntryValue,
    setEventEntryValue,
    domainList,
    setDomainList,
    editMode,
    airmeetId,
    fixedEventEntryType = null,
    isEventSeries = false,
    airmeetFormat,
    isInPersonEvent,
    isEventEntryOpen,
    setIsEventEntryOpen,
}) {
    const subscriptionData =
        useSelector((state) => state.Community.subscriptionData) || {};
    const EVENT_ENTRY_ACCESS_OPTIONS = isEventSeries
        ? getSeriesEntryAccessOptions()
        : getEventEntryAccessOptions({
              isInPersonEvent,
              airmeetFormat,
              isOnRbpPlan: subscriptionData?.limit_level !== ATTENDEE,
          });
    const dispatch = useDispatch();
    const hide_auth_providers = useSelector(
        (state) => state?.lounge?.airmeet?.hide_auth_providers
    );
    const hideAuthProviders = hide_auth_providers && editMode;
    const ticketsConfigured = useSelector(
        (state) => state?.lounge?.airmeet?.ticketsInfo?.ticketsConfigured
    );
    const airmeetStatus = useSelector(
        (state) => state?.lounge?.airmeet?.status
    );
    const isSsoDomainRestricted = useSelector(
        (state) => state?.lounge?.airmeet?.is_sso_domain_restricted
    );
    const ssoDomains = useSelector(
        (state) => state?.lounge?.airmeet?.allowed_sso_domains
    );
    const seriesId = useSelector(
        (state) => state?.eventSeries?.dashEventSeries?.uid
    );

    const hasTicketing = ticketsConfigured && editMode;
    const eventEntryOptionLabel = useMemo(() => {
        if (isSsoDomainRestricted)
            return 'Only attendees authenticated via single sign-on (SSO)';

        if (hasTicketing) return 'Ticket holders and invited participants';

        let eventEntryOption = null;
        Object.keys(EVENT_ENTRY_ACCESS_OPTIONS || []).forEach((key) => {
            if (
                EVENT_ENTRY_ACCESS_OPTIONS?.[key]?.value ===
                (fixedEventEntryType ? fixedEventEntryType : eventEntryValue)
            ) {
                eventEntryOption = EVENT_ENTRY_ACCESS_OPTIONS?.[key];
            }
        });
        return (
            eventEntryOption?.labelV2Full ||
            eventEntryOption?.labelV2 ||
            eventEntryOption?.label
        );
    }, [
        eventEntryValue,
        fixedEventEntryType,
        hasTicketing,
        isSsoDomainRestricted,
        EVENT_ENTRY_ACCESS_OPTIONS,
    ]);

    const radioChangeHandler = (value, domainList, onClose) => {
        setEventEntryValue(value);
        setDomainList(domainList);
        onClose && setTimeout(() => onClose(), 500);
    };

    const showEventEntryModal = () => {
        dispatch(
            showAppModal(MODAL_TYPE_EVENT_ENTRY, {
                radioChangeHandler: radioChangeHandler,
                eventEntryValue,
                domains: domainList || [],
                hideAuthProviders,
                airmeetId,
                isSsoDomainRestricted,
                ssoDomains,
                isInPersonEvent,
                isSeriesEntry: isEventSeries,
                backdropStyles: { zIndex: 1057 },
                airmeetFormat,
            })
        );
    };
    const isPartOfEventSeries = !isEventSeries && seriesId;

    return (
        <FormAccordion
            title={`Who can attend this ${
                isEventSeries ? 'series' : airmeetFormat
            }?`}
            info={
                isPartOfEventSeries
                    ? 'Event entry is disabled for events in a series. The series entry type would be applicable for all the events in the series. You can edit the event entry type after removing this event from the series.'
                    : airmeetStatus !== AIRMEET_STATUS.FINISHED
                    ? isInPersonEvent
                        ? 'Configure how participants can discover and register for the event'
                        : 'Configure how participants can discover and enter the event'
                    : 'Cannot configure the entry type as event is completed. '
            }
            defaultIsOpen={!!(airmeetId || editMode)}
            mt='x6'
            isOpen={isEventEntryOpen}
            setIsOpen={setIsEventEntryOpen}
        >
            <FlexDiv alignItems='center'>
                <Text variant='body2' color='text.default.secondary' mr='x4'>
                    {eventEntryOptionLabel}
                </Text>
                <WithTooltip
                    tooltip={
                        airmeetStatus === AIRMEET_STATUS.FINISHED
                            ? `${upperFirst(
                                  airmeetFormat
                              )} entry cannot be changed as it has ended.`
                            : fixedEventEntryType || isPartOfEventSeries
                            ? `${upperFirst(
                                  airmeetFormat
                              )}  entry cannot be changed as it is a part of a series.`
                            : hasTicketing &&
                              `${upperFirst(
                                  airmeetFormat
                              )}  entry cannot be changed as it is a ticketed ${airmeetFormat}.`
                    }
                    place='bottom'
                    isThemeV2={true}
                >
                    <Btn
                        variant='link'
                        onClick={showEventEntryModal}
                        disabled={
                            hasTicketing ||
                            fixedEventEntryType ||
                            isPartOfEventSeries ||
                            airmeetStatus === AIRMEET_STATUS.FINISHED
                        }
                        type='button'
                    >
                        Change
                    </Btn>
                </WithTooltip>
            </FlexDiv>
        </FormAccordion>
    );
}
