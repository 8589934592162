import get from 'lodash/get';
import set from 'lodash/set';
import { useCallback, useMemo } from 'react';

/**
 * Removes hidden tabs, builds the URL & adds isPremium prop.
 */
const sanitizeTabs = ({
    tabs,
    baseUrl,
    entityId,
    featureData = {},
    entityName = 'airmeets',
}) => {
    const finalTabs = [];
    const url = (baseUrl || `/${entityName}/${entityId}`).replace('#', '/');
    if (Array.isArray(tabs) && tabs?.length > 0) {
        (tabs || []).forEach((tab) => {
            if (!tab?.isHidden) {
                const tabUrl = `${url}${
                    tab?.isHashRoute ? `#${tab?.id}` : `/${tab?.id}`
                }`;
                if (tab?.component) {
                    set(featureData, `${tab?.id}.url`, tabUrl);
                    set(featureData, `${tab?.id}.isPremium`, tab?.isPremium);
                }
                tab.url = tabUrl;
                const newTab = tab;
                const { tabs: newTabs } = sanitizeTabs({
                    tabs: newTab.tabs,
                    entityId,
                    baseUrl: tabUrl,
                    featureData,
                });
                newTab.tabs = newTabs;
                finalTabs.push(newTab);
            }
        });
    }

    return { tabs: finalTabs, featureData };
};

/**
 * Common hook to get the final tabs tree along with the module level details like the module URL, isVisible or isPremium.
 */
export default function useDashboardTabs({
    tabs: allTabs,
    entityId,
    entityName,
}) {
    const { tabs, featureData } = useMemo(
        () =>
            sanitizeTabs({
                tabs: allTabs,
                entityId,
                entityName,
            }),
        [allTabs, entityId, entityName]
    );

    const getIsFeatureVisible = useCallback((id) => id in (featureData || {}), [
        featureData,
    ]);

    const getFeatureUrl = useCallback(
        (id) => get(featureData || {}, `${id}.url`),
        [featureData]
    );

    const getFeatureIsPremium = useCallback(
        (id) => get(featureData || {}, `${id}.isPremium`),
        [featureData]
    );

    return {
        tabs,
        getIsFeatureVisible,
        getFeatureUrl,
        getFeatureIsPremium,
    };
}
