import CloseButton from 'components/molecules/buttons/CloseButton';
import React from 'react';
import styled, { css } from 'styled-components';

function BacklessModal({
    children,
    size = 'small',
    className = '',
    contentClassName = '',
    onClose = false,
}) {
    return (
        <ModalContainer className={className}>
            {onClose ? <ModalClose onClick={onClose} /> : null}
            <ModalContent className={contentClassName} size={size}>
                {children}
            </ModalContent>
        </ModalContainer>
    );
}

const ModalContainer = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1060;
    background-color: rgba(0, 0, 0, 0.85);
`;

const ModalClose = styled(CloseButton)`
    position: absolute;
    top: 1rem;
    right: 1rem;
`;

const ModalContent = styled.div`
    position: absolute;

    left: ${(props) => (props.size === 'small' ? 0 : '2rem')};
    right: ${(props) => (props.size === 'small' ? 0 : '2rem')};
    top: ${(props) => (props.size === 'small' ? 0 : '2rem')};
    bottom: ${(props) => (props.size === 'small' ? 0 : '2rem')};

    overflow-y: auto;

    ${(props) =>
        props.size === 'small'
            ? css`
                  width: 500px;
                  height: 180px;
              `
            : (props.size = 'large'
                  ? css`
                        width: calc(100vw - 4rem);
                        height: calc(100vh - 4rem);
                    `
                  : '')}

    margin: auto;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
`;

export default BacklessModal;
