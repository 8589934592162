import Btn from 'atoms/Button/btn';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { Typography } from 'foundations/typography';
import React from 'react';
import { cdnImage } from 'utils/core';

const SelectFileFromComputer = ({ isThemeV2 = false, hasError = false }) => (
    <>
        {isThemeV2 ? (
            <StyledSvgSprite
                icon={hasError ? 'icon-warning-triangle-outline' : 'video'}
                width={24}
                height={24}
                fill={hasError ? 'sem.error' : 'sem.info'}
            />
        ) : (
            <img
                src={cdnImage('/images/dashboard/airmeet-fishing.svg')}
                alt='fishing'
                width={213}
                height={116}
            />
        )}
        <Typography
            variant={isThemeV2 ? 'body2' : 'button2'}
            color={isThemeV2 ? 'text.default.secondary' : 'ambience.24'}
            mt={isThemeV2 ? 'x4' : '18px'}
            isThemeV2={isThemeV2}
        >
            {isThemeV2
                ? 'Drag and drop video files here or'
                : 'Drag and drop video files here to start upload'}
        </Typography>
        {isThemeV2 ? null : (
            <Typography
                variant='subtext0'
                color='ambience.14'
                mt='4px'
                mb='2px'
            >
                or
            </Typography>
        )}
        {isThemeV2 ? (
            <Btn variant='link' mt='x1'>
                Click to upload
            </Btn>
        ) : (
            <Typography variant='button1' color='accentPrimary.0'>
                Select from your computer
            </Typography>
        )}
    </>
);

export default SelectFileFromComputer;
