import FocusTrap from 'atoms/FocusTrap';
import { UnstyledButton } from 'components/molecules/utils/basicComponents';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import useTheme from 'hooks/useTheme';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
    background,
    border,
    flexbox,
    layout,
    position,
    space,
} from 'styled-system';
import { closeAppModal } from '../../store/actions/layout';

const Modal = (props) => {
    const { theme, isLightTheme } = useTheme();
    const dispatch = useDispatch();
    const {
        style,
        wrapperStyle,
        showCloseIcon = true,
        children,
        onClose = () => {},
        wrapperClosable = false,
        buttonclose = {},
        focusTrapSupport = false,
        focusTrapOptions = {},
        ariaLabel = '',
    } = props || {};
    const [isClosed, setIsClosed] = useState(false);

    useLockBodyScroll(); // To Prevent the scroll on background layer when popup is active

    const handleClose = () => {
        setIsClosed(true);
        dispatch(closeAppModal());
        onClose();
    };

    const content = !isClosed ? (
        <ModalWrapper
            style={wrapperStyle}
            onClick={() => (wrapperClosable ? handleClose() : null)}
        >
            <ModalDialog
                my={['0px', 50]}
                mx={['0px', 'auto']}
                position={'relative'}
                background={theme.colors.ambience[21]}
                borderRadius={[8, 24]}
                maxHeight={['100%', 580, 580]}
                height={['100vh', '80vh', '80vh']}
                style={style}
                onClick={(e) => e.stopPropagation()} // To avoid accidental clicks below the modal
                role='dialog'
                aria-label={ariaLabel}
            >
                {showCloseIcon && (
                    <CloseBtn
                        onClick={handleClose}
                        background={
                            isLightTheme
                                ? 'transparent'
                                : theme.colors.ambience[18]
                        }
                        style={buttonclose || {}}
                        className={'btn-close'}
                        aria-label='Close modal'
                    >
                        &times;
                    </CloseBtn>
                )}
                {children}
            </ModalDialog>
        </ModalWrapper>
    ) : null;

    return (
        <>
            {focusTrapSupport ? (
                <FocusTrap focusTrapOptions={focusTrapOptions}>
                    {content}
                </FocusTrap>
            ) : (
                content
            )}
        </>
    );
};

export default Modal;

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
`;

const CloseBtn = styled(UnstyledButton)`
    ${layout}
    ${space}
    ${flexbox}
    ${position}
    ${border}
    ${background}
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 3px;
    right: 3px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 14px;
    color: ${({ theme }) =>
        theme.isLightTheme ? theme.colors.ambience[5] : theme.colors.white};
    background-color: transparent !important;
    font-size: 30px;
    outline: none;
    line-height: 1.2;
    text-align: center;
    transition: all 0.3s;
    &:hover {
        opacity: 0.7;
    }
`;
const ModalDialog = styled.div`
    ${layout}
    ${space}
    ${flexbox}
    ${position}
    ${border}
    ${background}
    max-width: 500px;
    width: 100%;
    background: ${({ theme }) =>
        theme.isLightTheme ? theme.colors.white : '#202026'};
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
        margin-right: 5px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.4);
        border-radius: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        width: 6px;
    }

    &::-webkit-scrollbar-track:horizontal {
        background: rgba(255, 255, 255, 0.1);
    }

    &::-webkit-scrollbar-thumb:horizontal {
        background: rgba(255, 255, 255, 0.4);
        border-radius: 5px;
    }
`;
