import Button from 'atoms/Button/button';
import Loader from 'components/general/Loader';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { alpha } from 'foundations/theme';
import { Typography } from 'foundations/typography';
import styled from 'styled-components';
import { space } from 'styled-system';

export const HeaderBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const HeaderInfoBox = styled.div`
    display: flex;
    align-items: center;
`;

export const PageTitle = styled(Typography)`
    line-height: normal;
    padding: 0;
`;

export const Card = styled.div`
    display: flex;
    flex-grow: 1;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    border: ${({ hideOutline, theme }) =>
        hideOutline
            ? '0'
            : `1px solid ${
                  theme?.isLightTheme ? theme.colors.ambience[22] : '#1d1c22'
              }`};
    border-radius: 16px;
    padding: ${({ hideOutline }) => (hideOutline ? '0 0' : '16px 24px')};
    margin-bottom: 24px;
    height: calc(100% - 24px);
    ${({ theme, hideOutline }) =>
        theme?.isLightTheme && !hideOutline
            ? `
        background-color: ${theme.colors.ambience[24]};
    `
            : ''}
`;

export const CardHeader = styled.div`
    display: flex;
    align-items: center;
`;

export const CardTitle = styled.div`
    color: ${({ theme }) => theme.colors.ambience[0]};
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    flex-grow: 1;
    padding-bottom: 5px;
`;

export const CardBody = styled.div`
    position: relative;
    padding-top: 24px;
    display: flex;
    align-items: center;
    text-align: left;
    flex-grow: 1;
    .react-multi-carousel-list.carousel-container {
        width: 100%;
        height: 100%;
        padding-top: 44px;
    }
    .react-multi-carousel-dot-list {
        position: absolute;
        top: 2px;
        right: 60px;
        left: auto;
        bottom: auto;
    }
`;

export const Chart = styled.div`
    ${space}
    width: ${({ dimensions }) => dimensions || '80px'};
    height: ${({ dimensions }) => dimensions || '80px'};
    position: relative;
`;

export const Legend = styled.span`
    display: inline-block;
    margin-right: 12px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.backgroundColor};
`;

export const PanelContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: stretch;
    padding: 32px;
    height: 100%;
`;

export const PanelHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`;

export const PanelBody = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const HrVertical = styled.hr`
    width: 1px;
    height: 100%;
    border-left: solid 1px #1d1c22;
    margin: 0;
`;

export const LoadingWrapper = styled.div`
    height: 100%;
    width: 100%;
    flex-grow: 1;
    align-self: stretch;
    display: flex;
`;

export const Loading = styled(Loader)`
    padding: 0;
    width: 30px;
    height: 30px;
    text-align: center;
    align-self: center;
    flex-grow: 1;
    margin: auto;
`;

export const EmptyWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: ${({ theme }) =>
        theme?.isLightTheme
            ? alpha(theme.colors.ambience[23], 0.8)
            : 'rgba(14, 13, 17, 0.8)'};
    z-index: 10;
    border: 0;
`;

export const EmptyContent = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20%;
    background: ${({ theme }) =>
        theme.colors.ambience[theme?.isLightTheme ? 24 : 22]};
    border: 1px solid
        ${({ borderColor, theme }) =>
            borderColor ||
            (theme?.isLightTheme ? theme.colors.ambience[22] : '#f1a50d')};
    border-radius: 8px;
    color: ${({ theme }) =>
        theme.colors.ambience[theme?.isLightTheme ? 1 : 24]};
    padding: 28px;
    svg {
        margin-right: 24px;
    }
    p {
        display: inline-block;
    }
`;

export const GreyButton = styled(Button)`
    font-weight: 700;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    margin-left: 12px;
    background-color: ${({ theme, isReplay }) =>
        isReplay ? 'transparent' : theme.colors.accentPrimary[0]};
    border: 1px solid transparent;
    width: auto;
    &:disabled {
        background-color: ${({ theme, isReplay }) =>
            isReplay ? 'transparent' : theme.colors.ambience[19]};
        opacity: 1;
    }
`;

export const PieChartImg = styled.img`
    height: 80px;
    width: auto;
    margin-right: 30px;
    margin-left: 30px;
`;

export const RefreshButton = styled(Button)`
    display: flex;
    align-items: center;
`;

export const RefreshIcon = styled(StyledSvgSprite)`
    ${({ loading }) => loading && 'animation: spin 2s linear infinite;'}
`;
