import { callApi } from 'utils/apiService';
import { useCallback, useRef } from 'react';

interface IBookmarkSessionsResponse {
    bookmarkedSessionsUIDs: string[];
}

const useFetchBookmarkSessions = () => {
    const fetchBookmarkedSessions = useCallback(async (airmeetId: string) => {
        const endpoint = `/airmeet/${airmeetId}/bookmarked-sessions`;
        return await callApi({ endpoint });
    }, []);

    const fetchBookmarkPromiseRef = useRef<Promise<
        IBookmarkSessionsResponse
    > | null>(null);

    const getBookmarkedSessions = useCallback(
        (
            airmeetId: string,
            forceRefresh = false
        ): Promise<IBookmarkSessionsResponse> => {
            if (!fetchBookmarkPromiseRef.current || forceRefresh) {
                fetchBookmarkPromiseRef.current = new Promise(
                    (resolve, reject) => {
                        fetchBookmarkedSessions(airmeetId)
                            .then(resolve)
                            .catch((err) => {
                                fetchBookmarkPromiseRef.current = null;
                                reject(err);
                            });
                    }
                );
            }
            return fetchBookmarkPromiseRef.current;
        },
        [fetchBookmarkedSessions]
    );

    return {
        fetchBookmarkedSessions: getBookmarkedSessions,
    };
};

export default useFetchBookmarkSessions;
