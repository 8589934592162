import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

const defaultData = {
    data: null,
    statusCode: 200,
    statusMessage: 'SUCCESS',
};

function getRandInt(min = 10, max = 100) {
    return Math.round(Math.random() * (max - min) + min);
}

export default function getDummyData(endpoint, query) {
    switch (endpoint) {
        case 'attendance-analytics':
            return attendanceData;
        case 'booth-analytics':
            return getBoothsData(query);
        case 'session-analytics':
            return getSessionsData(query);
        case 'poll-analytics':
            return pollsData;
        case 'ticket-analytics':
            return ticketsData;
        case 'attendee-traffic':
            return attendeeTrafficData;
        case 'demographics':
            return demographicsData;
        case 'registrations-by-fields':
            return registrationFieldsData;
        case 'engagement/attendance':
            return getEngagementAttendanceData(query);
        case 'engagement/activity':
            return getEngagementActivityData(query);
        default:
            return null;
    }
}

const attendanceData = {
    ...defaultData,
    data: {
        event: {
            all: {
                registrations: 1000,
                attendees: 700,
                invite_attendees: 30,
                self_attendees: 170,
                ticket_attendees: 150,
            },
            repeat: {
                registrations: 30,
                attendees: 25,
                invite_attendees: 3,
                self_attendees: 10,
                ticket_attendees: 9,
            },
            speakers: 20,
            exhibitors: 30,
            total_registrations: getRandInt(),
            total_attendees: getRandInt(),
            session_attendees: getRandInt(),
            booth_attendees: getRandInt(),
            lounge_attendees: getRandInt(),
            meeting_attendees: getRandInt(),
            average_time_spent: getRandInt(),
        },
        networking: {
            total_who_used_sl: 100,
            total_who_used_sn: 50,
            total_who_found_match: 30,
            day_count: 20,
            sl_attendance: {
                '2021-10-20': getRandInt(),
                '2021-10-21': getRandInt(),
            },
            average_time_spent: getRandInt(),
        },
    },
};

const getBoothsData = (query) => {
    let booths = Array(10)
        .fill(0)
        .map((item, index) => ({
            visits: getRandInt(),
            cta_clicks: getRandInt(),
            video_views: getRandInt(),
            booth_id: `booth-${index}`,
            booth_name: `Booth ${index}`,
            interacted_exhibitor: getRandInt(),
            downloads: getRandInt(),
            meetings: getRandInt(),
            average_time_spent: getRandInt(),
        }));

    const ids = query?.['ids[]'] || [];
    if (Array.isArray(ids) && ids.length > 0) {
        booths = booths.filter((booth) => ids.includes(booth?.booth_id));
    }

    return {
        ...defaultData,
        data: {
            stats: {
                average_time_spent: getRandInt(),
                average_attendance: getRandInt(),
                total_attendance: getRandInt(),
                interacted_exhibitor: getRandInt(),
                meeting_count: getRandInt(),
                attendance_per_day: {
                    '2021-10-20': getRandInt(),
                    '2021-10-21': getRandInt(),
                },
                activity: {
                    cta: getRandInt(),
                    downloads: getRandInt(),
                    broadcast: getRandInt(),
                    banner_video: getRandInt(),
                    interacted_exhibitor: getRandInt(),
                    total: getRandInt(),
                },
            },
            booths,
        },
    };
};

const getSessionsData = (query) => {
    let sessions = Array(10)
        .fill(0)
        .map((item, index) => {
            const date = index % 3 === 0 ? '2021-10-20' : '2021-10-21';
            const hrs = getRandInt(0, 23);
            const mins = getRandInt(0, 59);
            const time = `${hrs < 10 ? `0${hrs}` : hrs}:${
                mins < 10 ? `0${mins}` : mins
            }`;
            const start_time = moment(
                `${date} ${time}`,
                'YYYY-MM-DD HH:mm'
            ).valueOf();

            return {
                session_id: `session-${index}`,
                session_name: `Session ${index}`,
                attendance: getRandInt(),
                chat_text: getRandInt(),
                messages: getRandInt(),
                questions: getRandInt(),
                reactions: getRandInt(),
                average_time_spent: getRandInt(),
                start_time,
                duration: getRandInt(30, 100),
            };
        });

    const ids = query?.['ids[]'] || [];
    if (Array.isArray(ids) && ids.length > 0) {
        sessions = sessions.filter((session) =>
            ids.includes(session?.session_id)
        );
    }

    const { start_time, end_time } = query || {};
    const momentStartTime = start_time && moment(start_time);
    const momentEndTime = end_time && moment(end_time);

    if (
        momentStartTime &&
        momentStartTime.isValid() &&
        momentEndTime &&
        momentEndTime.isValid()
    ) {
        sessions = sessions.filter(
            (session) =>
                moment(session?.start_time).isSameOrAfter(momentStartTime) &&
                moment(session?.start_time).isSameOrBefore(momentEndTime)
        );
    }

    return {
        ...defaultData,
        data: {
            stats: {
                average_time_spent: getRandInt(),
                average_attendance: getRandInt(),
                total_attendance: getRandInt(),
                average_reaction_percentage: getRandInt(),
                per_day: {
                    '2021-10-20': getRandInt(),
                    '2021-10-21': getRandInt(),
                },
                activity: {
                    raised_hand: getRandInt(),
                    question: getRandInt(),
                    poll: getRandInt(),
                    messages: getRandInt(),
                    reactions: getRandInt(),
                    total: getRandInt(),
                },
            },
            sessions,
        },
    };
};

const pollsData = {
    ...defaultData,
    data: {
        polls: Array(10)
            .fill(0)
            .map((item, index) => ({
                total_response: getRandInt(),
                poll_level:
                    getRandInt() % 2 === 0 ? 'Event Level' : 'Session Level',
                poll_options: Array(4)
                    .fill(0)
                    .map((item, i) => ({
                        response_count_percentage: `${getRandInt()}%`,
                        option: `Option ${i}`,
                    })),
                user_profile_img: 'https://picsum.photos/50',
                user_name: `User ${index}`,
                session_name: `Session ${index}`,
                poll_question: `Question ${index}`,
            })),
    },
};

const ticketsData = {
    ...defaultData,
    data: {
        totalTicketSaleCount: 1000,
        totalTicketEarnings: [{ currency: 'USD', amount: 100 }],
        ticketSales: Array(5)
            .fill(0)
            .map((item, index) => ({
                ticket_name: `Ticket ${index}`,
                ticket_count: 100 * (10 - index),
            })),
    },
};

const demographicsData = {
    ...defaultData,
    data: {
        countries: {
            ZW: {
                name: 'Zimbabwe',
                count: 100,
            },
            CL: {
                name: 'Chile',
                count: 90,
            },
            ZA: {
                name: 'South Africa',
                count: 80,
            },
            NA: {
                name: 'Namibia',
                count: 70,
            },
            DZ: {
                name: 'Algeria',
                count: 60,
            },
            BR: {
                name: 'Brazil',
                count: 50,
            },
            SN: {
                name: 'Senegal',
                count: 40,
            },
            IT: {
                name: 'Italy',
                count: 30,
            },
            GT: {
                name: 'Guatemala',
                count: 20,
            },
            TH: {
                name: 'Thailand',
                count: 10,
            },
            IN: {
                name: 'India',
                count: 110,
            },
        },
        devices: {
            Mobile: 100,
            Tablet: 50,
            Desktop: 300,
        },
        browsers: {
            Chrome: 1000,
            Firefox: 500,
            Edge: 2000,
        },
        os: {
            'Mac OS': 1000,
            Ubuntu: 100,
            Windows: 5000,
        },
        traffic_source: {
            sources_visits: {
                Facebook: 10000,
                LinkedIn: 5000,
                Twitter: 2000,
                WhatsApp: 1000,
                Reddit: 600,
                Instagram: 500,
                Others: 200,
            },
            sources_unique_visits: {
                Facebook: 5000,
                LinkedIn: 2500,
                Twitter: 1000,
                WhatsApp: 500,
                Reddit: 300,
                Instagram: 250,
                Others: 100,
            },
            sources: {
                Facebook: 2500,
                LinkedIn: 1250,
                Twitter: 500,
                WhatsApp: 250,
                Reddit: 150,
                Instagram: 125,
                Others: 50,
            },
            mediums_visits: {
                'Medium 1': 100,
                'Medium 2': 300,
                'Medium 3': 500,
                'Medium 4': 600,
                'Medium 5': 200,
            },
            mediums_unique_visits: {
                'Medium 1': 50,
                'Medium 2': 150,
                'Medium 3': 250,
                'Medium 4': 300,
                'Medium 5': 100,
            },
            mediums: {
                'Medium 1': 25,
                'Medium 2': 75,
                'Medium 3': 125,
                'Medium 4': 150,
                'Medium 5': 50,
            },
            campaigns_visits: {
                'Campaign 1': 100,
                'Campaign 2': 200,
                'Campaign 3': 500,
                'Campaign 4': 400,
                'Campaign 5': 700,
            },
            campaigns_unique_visits: {
                'Campaign 1': 50,
                'Campaign 2': 100,
                'Campaign 3': 250,
                'Campaign 4': 200,
                'Campaign 5': 350,
            },
            campaigns: {
                'Campaign 1': 25,
                'Campaign 2': 50,
                'Campaign 3': 125,
                'Campaign 4': 100,
                'Campaign 5': 125,
            },
        },
    },
};

const attendeeTrafficData = {
    ...defaultData,
    data: {
        Zimbabwe: {
            tiers: {
                Gold: 100,
                Silver: 200,
                Bronze: 300,
            },
            count: 600,
        },
        Chile: {
            tiers: {
                Gold: 200,
                Silver: 50,
                Bronze: 300,
            },
            count: 550,
        },
        'South Africa': {
            tiers: {
                Gold: 300,
                Silver: 300,
                Bronze: 400,
            },
            count: 1000,
        },
        Namibia: {
            tiers: {
                Gold: 400,
                Silver: 300,
                Bronze: 100,
            },
            count: 800,
        },
        Algeria: {
            tiers: {
                Gold: 300,
                Silver: 200,
                Bronze: 100,
            },
            count: 600,
        },
        Brazil: {
            tiers: {
                Gold: 300,
                Silver: 100,
                Bronze: 100,
            },
            count: 500,
        },
        Senegal: {
            tiers: {
                Gold: 100,
                Silver: 100,
                Bronze: 100,
            },
            count: 300,
        },
        Italy: {
            tiers: {
                Gold: 100,
                Silver: 100,
                Bronze: 200,
            },
            count: 400,
        },
        Guatemala: {
            tiers: {
                Gold: 100,
                Silver: 100,
                Bronze: 100,
            },
            count: 300,
        },
        Thailand: {
            tiers: {
                Gold: 200,
                Silver: 100,
                Bronze: 100,
            },
            count: 400,
        },
    },
};

const registrationFieldsData = {
    ...defaultData,
    data: {
        Designation: {
            'Designation option 1': 10,
            'Designation option 2': 30,
            'Designation option 3': 30,
            'Designation option 4': 80,
            'Designation option 5': 84,
            'Designation option 6': 70,
            'Designation option 7': 100,
            'Designation option 8': 50,
            'Designation option 9': 40,
            'Designation option 10': 66,
            'Designation option 11': 20,
            'Designation option 12': 10,
        },
        Industry: { 'Industry option 1': 20, 'Industry option 2': 30 },
        Gender: { 'Gender option 1': 10, 'Gender option 2': 40 },
        'Income Bracket': { 'option 1': 70, 'option 2': 30 },
        'Year Of Joining': { 'option 1': 40, 'option 2': 50 },
        'T-Shirt Size': { 'option 1': 20, 'option 2': 80 },
    },
};

const START_TIME = '08:00:00';
const END_TIME = '13:00:00';
const START_DATE = '2021-12-25';
const END_DATE = '2021-12-30';

export const eventStartTime = moment(`${START_DATE}T${START_TIME}`).format();
export const eventEndTime = moment(`${END_DATE}T${END_TIME}`).format();

const getTimeStamps = (interval = 30, start_time, end_time) => {
    const timestamps = [];
    let timestamp = moment(`${START_DATE}T${START_TIME}`);
    while (timestamp.isSameOrBefore(`${END_DATE}T${END_TIME}`)) {
        timestamps.push(timestamp.valueOf());

        if (
            timestamp.isSameOrAfter(
                moment(`${timestamp.format('YYYY-MM-DD')}T${END_TIME}`)
            )
        ) {
            // Change date if reach end time
            const newTimestamp = moment(
                `${timestamp.add(1, 'day').format('YYYY-MM-DD')}T${START_TIME}`
            );
            timestamp = cloneDeep(newTimestamp);
        } else {
            // Change time
            timestamp = cloneDeep(timestamp.add(interval, 'minutes'));
        }
    }

    const momentStartTime = moment(start_time);
    const momentEndTime = moment(end_time);

    return !start_time ||
        !end_time ||
        !momentStartTime.isValid() ||
        !momentEndTime.isValid()
        ? timestamps
        : timestamps.filter(
              (timestamp) =>
                  moment(timestamp).isSameOrAfter(momentStartTime) &&
                  moment(timestamp).isSameOrBefore(momentEndTime)
          );
};

const getEngagementAttendanceData = (query = {}) => {
    const { interval = 30 } = query || {};

    const { start_time, end_time } = query || {};
    const timestamps = getTimeStamps(interval, start_time, end_time);

    if (!Array.isArray(timestamps) || timestamps.length <= 1)
        return { ...defaultData, data: {} };

    return {
        ...defaultData,
        data: {
            series: {
                info: {
                    count: timestamps.length,
                    interval,
                    startTime: timestamps[0],
                    endTime: timestamps[timestamps.length - 1],
                },
                name: 'Event attendance',
                records: timestamps.map((timestamp) => ({
                    t: timestamp,
                    v: {
                        booth_count: getRandInt(),
                        event_count: getRandInt(),
                        lounge_count: getRandInt(),
                        session_count: getRandInt(),
                    },
                })),
            },
            stats: {
                average_time_spent: getRandInt(),
                booth_attendees: getRandInt(),
                lounge_attendees: getRandInt(),
                session_attendees: getRandInt(),
                total_attendees: getRandInt(),
            },
        },
    };
};

const getEngagementActivityData = (query = {}) => {
    const { interval = 30 } = query || {};

    const { start_time, end_time } = query || {};
    const timestamps = getTimeStamps(interval, start_time, end_time);

    if (!Array.isArray(timestamps) || timestamps.length <= 1)
        return { ...defaultData, data: {} };

    return {
        ...defaultData,
        data: {
            series: {
                info: {
                    count: timestamps.length,
                    interval,
                    startTime: timestamps[0],
                    endTime: timestamps[timestamps.length - 1],
                },
                name: 'Event activity',
                records: timestamps.map((timestamp) => ({
                    t: timestamp,
                    v: {
                        booth_active: getRandInt(),
                        event_active: getRandInt(),
                        lounge_active: getRandInt(),
                        session_active: getRandInt(),
                    },
                })),
            },
            stats: {
                booth_active: getRandInt(),
                event_active: getRandInt(),
                lounge_active: getRandInt(),
                session_active: getRandInt(),
            },
        },
    };
};
