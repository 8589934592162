import Loader from 'components/general/Loader';
import useQuery from 'hooks/useQuery';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { getStripeOAuthRedirect } from 'store/actions/cmDashboard/tickets';
import styled from 'styled-components';
import { logger } from 'utils/logger';
import NotFound from '../../components/errors/NotFound';
const localLog = logger.init('AirmeetDetails:', 'red');

const CANCEL_STRIPE_FLOW = 'access_denied';
function StripeResult() {
    const dispatch = useDispatch();
    // Get Query Params
    const query = useQuery();
    const state = query.get('state');
    const error = query.get('error');
    const code = query.get('code');
    const errorDescription = query.get('error_description');

    const [notFound, setNotFound] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const getRedirectURL = useCallback(
        async (data) => {
            return await dispatch(getStripeOAuthRedirect(data));
        },
        [state]
    );

    const validateAndRedirect = useCallback(async () => {
        if ((!state && !error) || isMobile) {
            setLoading(false);
            setNotFound(true);
        } else {
            try {
                const data = {
                    stateId: state,
                    oauthCode: code,
                    error,
                    errorDescription,
                };
                localLog('Calling stripe oauth redirect api', data);
                const response = await getRedirectURL(data);
                setLoading(false);
                if (response?.error) {
                    localLog(
                        'Got Error in Stripe oauth redirect api',
                        response
                    );
                    throw new Error('Error in stripe oauth redirect api');
                } else {
                    let { error, redirectUrl } = response?.payload?.data || {};
                    localLog('Redirecting to Tickets section', redirectUrl);
                    if (error === CANCEL_STRIPE_FLOW) {
                        error = '';
                    }
                    if (redirectUrl) {
                        if (error) {
                            redirectUrl += `?error=${error}`;
                        }
                        window.location.replace(redirectUrl);
                    }
                }
            } catch (error) {
                localLog('Caught an error', error);
                let user = JSON.parse(localStorage.getItem('user'));
                const communityId = user.community.communityId;
                const path = `/community-manager/${communityId}/events`;
                window.location.replace(path);
            }
        }
    }, [state, error]);

    useEffect(() => {
        localLog('Calling validateAndRedirect');
        validateAndRedirect();
    }, [state]);

    if (isLoading) {
        return <Loader />;
    }
    return <PageWrapper>{notFound && <NotFound />}</PageWrapper>;
}

export default StripeResult;
const PageWrapper = styled.div`
    background: ${({ theme }) =>
        theme.isLightTheme ? theme.colors.ambience[23] : 'hsl(250, 10%, 8%)'};
`;
