import LoaderV2 from 'components/general/LoaderV2';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import React from 'react';
import styled from 'styled-components';

function LoadingOverlay() {
    return (
        <Wrapper>
            <LoaderV2 />
        </Wrapper>
    );
}

export default LoadingOverlay;

const Wrapper = styled(FlexDiv)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: ${({ theme }) => theme.radii.default};
`;
