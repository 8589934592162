import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import React from 'react';
import styled from 'styled-components';
import { cdnImage } from 'utils/core';
import { Card } from '../styledComponents';
import LoadingOverlay from './LoadingOverlay';

function StatCard({
    title,
    value,
    units = '',
    img = '/images/dashboard/analytics/il-analytics-stat-eye.svg',
    isLoading = false,
}) {
    return (
        <StyledCard>
            <FlexDiv
                flexDirection='column'
                alignItems='stretch'
                justifyContent='space-between'
                pr='x2'
                flex='1'
                pb='x3'
            >
                {title && (
                    <Text variant='body2' color='text.default.primary'>
                        {title}
                    </Text>
                )}
                <FlexDiv alignItems='flex-end'>
                    <Text variant='h2' color='text.default.primary'>
                        {value}
                    </Text>
                    {units && (
                        <Text
                            variant='body2'
                            color='text.default.secondary'
                            pl='x1'
                            pb='x0'
                        >
                            {units}
                        </Text>
                    )}
                </FlexDiv>
            </FlexDiv>
            <Img src={cdnImage(img)} alt='Stat' />
            {isLoading && <LoadingOverlay />}
        </StyledCard>
    );
}

const StyledCard = styled(Card)`
    padding: ${({ theme }) =>
        `${theme.space.x3} ${theme.space.x4} 0 ${theme.space.x4}`};
    flex-direction: row;
    align-items: center;
`;

const Img = styled.img`
    height: 3.5rem;
    width: 3.5rem;
`;

export default StatCard;
