import createReducer from 'utils/createReducer';
import {
    SET_CLOSED_CAPTIONS_LOADED,
    TOGGLE_CLOSED_CAPTIONS,
    SET_CLOSED_CAPTIONS_CONFIG,
} from '../actions/closedCaptions';

const initialState = {
    isLoaded: false,
    isEnabled: false,
    ccSubscriptionLanguage: null,
    config: {},
};

const handlers = {
    [TOGGLE_CLOSED_CAPTIONS]: (state, { payload }) => {
        return {
            ...state,
            isEnabled: payload?.length > 0,
            ccSubscriptionLanguage: payload,
        };
    },
    [SET_CLOSED_CAPTIONS_LOADED]: (state, { payload }) => {
        return { ...state, isLoaded: payload };
    },
    [SET_CLOSED_CAPTIONS_CONFIG]: (state, { payload }) => {
        return { ...state, config: { ...state.config, ...payload } };
    },
};

export default createReducer(initialState, handlers);
