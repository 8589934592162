import useNewDefaultReceptionFlag from 'components/DefaultReception/hooks/useNewDefaultReceptionFlag';
import { getIsConferenceEvent } from 'context/LiveAirmeet';
import { DEFAULT_RECEPTION_BANNER_URL } from 'utils/constants/reception';
import { cdnImage } from 'utils/core';
import useVenueData from './reception/useVenueData';
import useDeviceInfo from './useDeviceInfo';
import useLiveAirmeetContext from './useLiveAirmeetContext';

const { select } = require('utils/constants/common');

function useBanner() {
    const {
        community_name,
        name: subtitle,
        airmeetId,
        branding_info: { branding_logo } = {},
        event_organiser_name,
    } = useLiveAirmeetContext(select('airmeet.data.currentAirmeet', {}));
    const isConferenceEvent = useLiveAirmeetContext(getIsConferenceEvent);
    const { isMobile } = useDeviceInfo();
    const title = event_organiser_name ? event_organiser_name : community_name;
    const { data: venueData, loading: venueLoading } = useVenueData({
        airmeetId,
        isConferenceEvent,
    });

    const { bannerImg, isDefaultBanner, logo: brandLogo, isDefaultLogo } =
        venueData || {};

    const isNewDefaultReception = useNewDefaultReceptionFlag();

    const defaultImg = cdnImage(
        isNewDefaultReception
            ? DEFAULT_RECEPTION_BANNER_URL
            : 'images/summit/reception_bg.png'
    );

    return {
        title,
        subtitle,
        image: bannerImg,
        isMobile,
        defaultImg,
        brandLogo: isConferenceEvent ? brandLogo : branding_logo,
        isDefaultLogo,
        isDefaultBanner,
        venueLoading,
    };
}

export default useBanner;
