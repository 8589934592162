import createReducer from '../../utils/createReducer';
import { GET_URL_PARAMS_RESPONSE } from '../actions/urlParamsBuilder';

const initialState = {
    urlParamsData: [],
};

const handlers = {
    [GET_URL_PARAMS_RESPONSE]: (state, { payload }) => {
        return {
            ...state,
            urlParamsData: payload?.data,
        };
    },
};

export default createReducer(initialState, handlers);
