import withErrorBoundary, {
    DashboardModules,
} from 'components/hocs/withErrorBoundary';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { useShallowEqualSelector } from 'hooks/common';
import upperFirst from 'lodash/upperFirst';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAirmeetFormat } from 'utils/airmeet';
import { logger } from 'utils/logger';
import { ANALYTICS_REGISTRATIONS_TAB } from '../../constants/tabs';
import { AnalyticsContext } from '../AnalyticsProvider';
import SpecialRolesFilterSwitch from '../components/SpecialRolesFilterSwitch';
import TopBanner from '../components/TopBanner';
import DemographicsCard from './DemographicsCard';
import DevicesCard from './DevicesCard';
import RegistrationDetailsCard from './RegistrationDetailsCard';
import TicketsCard from './TicketsCard';
import TrafficSourceCard from './TrafficSourcesCard';
import TurnoutCard from './TurnoutCard';

export default withErrorBoundary(function RegistrationsTab() {
    const {
        hasTicketingData,
        attendanceLoading,
        attendanceData,
        getRequest,
        eventStarted,
        lastRefreshedTime,
        isInPersonEvent,
    } = useContext(AnalyticsContext);
    const airmeet = useSelector((state) => state.cmDashboard.airmeet);
    const { event_type } = airmeet || {};
    const airmeetFormat = getAirmeetFormat({
        eventType: event_type,
    });
    const [isDemographicsLoading, setIsDemographicsLoading] = useState(false);
    const [demographicsData, setDemographicsData] = useState(null);

    const [isAttendeeTrafficLoading, setIsAttendeeTrafficLoading] = useState(
        false
    );
    const [attendeeTrafficData, setAttendeeTrafficData] = useState(null);

    const [
        isRegistrationDetailsLoading,
        setIsRegistrationDetailsLoading,
    ] = useState(false);
    const [registrationDetailsData, setRegistrationDetailsData] = useState(
        null
    );
    const hasCustomSelectFields = useShallowEqualSelector((state) => {
        const formFields =
            state?.lounge?.airmeet?.customFormInfo?.formFieldsConstant;
        if (Array.isArray(formFields) && formFields.length > 0) {
            return formFields.some((item) =>
                ['radio', 'checkbox'].includes(item?.type?.inputType)
            );
        }
        return false;
    });
    const { countries, traffic_source, devices, browsers, os } =
        demographicsData || {};

    useEffect(() => {
        const fetchData = async () => {
            setIsDemographicsLoading(true);
            try {
                const response = await getRequest({ endpoint: 'demographics' });
                if ('statusCode' in response) {
                    if (response?.statusCode === 200) {
                        setDemographicsData(response?.data);
                    }
                } else {
                    setDemographicsData(response);
                }
            } catch (e) {
                logger.error(e);
            }
            setIsDemographicsLoading(false);
        };
        fetchData();
    }, [getRequest]);
    useEffect(() => {
        const fetchData = async () => {
            setIsAttendeeTrafficLoading(true);
            try {
                const response = await getRequest({
                    endpoint: 'attendee-traffic',
                });
                if ('statusCode' in response) {
                    if (response?.statusCode === 200) {
                        setAttendeeTrafficData(response?.data);
                    }
                } else {
                    setAttendeeTrafficData(response);
                }
            } catch (e) {
                logger.error(e);
            }
            setIsAttendeeTrafficLoading(false);
        };

        if (hasTicketingData) fetchData();
    }, [hasTicketingData, getRequest]);

    useEffect(() => {
        const fetchData = async () => {
            setIsRegistrationDetailsLoading(true);
            try {
                const response = await getRequest({
                    endpoint: 'registrations-by-fields',
                });
                if ('statusCode' in response) {
                    if (response?.statusCode === 200) {
                        setRegistrationDetailsData(response?.data);
                    }
                } else {
                    setRegistrationDetailsData(response);
                }
            } catch (e) {
                logger.error(e);
            }
            setIsRegistrationDetailsLoading(false);
        };

        if (hasCustomSelectFields) fetchData();
    }, [hasCustomSelectFields, getRequest]);
    useEffect(() => {
        let timer;
        if (window.location.href.includes('trafficSourceCard')) {
            const trafficCard = document.getElementById('trafficSourceCard');
            timer = setTimeout(
                () =>
                    trafficCard.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center',
                    }),
                2000
            );
        }
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <TopBanner
                title={`Understand your ${airmeetFormat} audience and participation`}
                subtitle={`Get insights on demographics, traffic sources, device attributes and registration form responses of your audience. This does not include ${upperFirst(
                    airmeetFormat
                )} Replay data.`}
                imgSrc='/images/dashboard/analytics/il-cheer.svg'
                lastUpdatedAt={lastRefreshedTime}
            />
            <FlexDiv alignItems='center' justifyContent='flex-end' pb='x4'>
                <SpecialRolesFilterSwitch tabId={ANALYTICS_REGISTRATIONS_TAB} />
            </FlexDiv>
            <TurnoutCard loading={attendanceLoading} data={attendanceData} />
            {isInPersonEvent ? null : (
                <DemographicsCard
                    data={countries}
                    loading={isDemographicsLoading}
                    attendanceData={attendanceData}
                    airmeetFormat={airmeetFormat}
                />
            )}
            {hasCustomSelectFields && (
                <RegistrationDetailsCard
                    data={registrationDetailsData}
                    loading={isRegistrationDetailsLoading}
                    airmeetFormat={airmeetFormat}
                />
            )}
            {hasTicketingData && (
                <TicketsCard
                    data={attendeeTrafficData}
                    loading={isAttendeeTrafficLoading}
                    eventStarted={eventStarted}
                    airmeetFormat={airmeetFormat}
                />
            )}
            <TrafficSourceCard
                data={traffic_source}
                loading={isDemographicsLoading}
                eventStarted={eventStarted}
                airmeetFormat={airmeetFormat}
            />
            {isInPersonEvent ? null : (
                <DevicesCard
                    devices={devices}
                    browsers={browsers}
                    os={os}
                    loading={isDemographicsLoading}
                    eventStarted={eventStarted}
                    airmeetFormat={airmeetFormat}
                />
            )}
        </>
    );
}, DashboardModules.ANALYTICS_REGISTRATION);
