import Box from 'atoms/Box';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { getCustomScroll } from 'components/molecules/utils/customStyles';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import React, { useMemo } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import styled from 'styled-components';
import { Legend } from '../RegistrationsTab/styledComponents';
import useChartFilterData from '../hooks/useChartFilterData';
import { CardHeader, CardTitle } from '../styledComponents';
import { DEFAULT_DATE_PILL, getCommonBarOptions } from '../utils';
import ChartCard from './ChartCard';
import ChartPills from './ChartPills';
import SearchFilter from './SearchFilter';
import StatBox from './StatBox';

const MAX_TICK_CHARS = 30;
const TICK_WIDTH = 200;
const MIN_TICK_HEIGHT = 24;
const MIN_CHART_HEIGHT = 300;

export default function HorizontalBarsCard({
    getRequest,
    timezone,
    searchTags,
    searchOptions,
    title,
    type = 'session',
    icon,
    topDataInfo = [],
    baseColorRGB = '238,81,175',
    xAxisLabel = 'Number of people',
    endpoint = 'session-analytics',
    perDayDataPath,
    dataKey = 'sessions',
    chartItemLabelKey,
    chartItemDataKey = 'attendance',
    averageKey,
    getChartLabel,
    getTooltip,
}) {
    const { theme } = useTheme();
    const {
        loading,
        selectedOptions,
        setSelectedOptions,
        isDropdownVisible,
        setIsDropdownVisible,
        topData,
        data,
        pills,
        selectedPill,
        setSelectedPill,
        average,
    } = useChartFilterData({
        endpoint,
        perDayDataPath,
        topDataInfo,
        getRequest,
        timezone,
        averageKey,
    });

    const {
        chartData,
        chartLabels,
        isEmptyChart,
        chartHeight,
        metaData,
    } = useMemo(() => {
        // Chart Data
        let listData = [];
        let chartData = [];
        let chartLabels = [];
        let metaData = [];
        if (Array.isArray(data)) {
            listData = data;
        } else {
            listData = data?.[dataKey];
        }
        (listData || []).forEach((item) => {
            chartLabels.push(
                (getChartLabel
                    ? getChartLabel(item)
                    : item?.[chartItemLabelKey]) || ''
            );
            chartData.push(item?.[chartItemDataKey] || 0);
            metaData.push(item);
        });

        const isEmptyChart =
            selectedPill === DEFAULT_DATE_PILL?.value &&
            (!Array.isArray(selectedOptions) || selectedOptions.length === 0) &&
            (!Array.isArray(chartData) || chartData.length === 0);

        let chartHeight = chartData.length * MIN_TICK_HEIGHT;
        if (chartHeight < MIN_CHART_HEIGHT) {
            chartHeight = MIN_CHART_HEIGHT;
        }

        return { chartData, chartLabels, isEmptyChart, chartHeight, metaData };
    }, [
        data,
        chartItemLabelKey,
        chartItemDataKey,
        dataKey,
        selectedOptions,
        selectedPill,
        getChartLabel,
    ]);

    return (
        <FlexDiv flexDirection='column' alignItems='stretch' flex='1'>
            <CardHeader>
                <CardTitle>{title}</CardTitle>
            </CardHeader>
            {Array.isArray(searchOptions) && searchOptions.length > 0 && (
                <SearchFilter
                    tags={searchTags}
                    options={searchOptions}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    type={type}
                    icon={icon}
                    isDropdownVisible={isDropdownVisible}
                    setIsDropdownVisible={setIsDropdownVisible}
                />
            )}
            <ChartCard
                loading={loading}
                isEmpty={isEmptyChart}
                emptyBannerProps={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    showIcons: true,
                    borderColor: '#69657B',
                    title:
                        'This report is still being generated, check back soon!',
                }}
                hideOutline={true}
                minLoadingHeight='558px'
                style={{ paddingTop: '0px' }}
                bodyStyle={{ paddingTop: '0px' }}
            >
                <FlexDiv
                    flexDirection='column'
                    alignItems='stretch'
                    flexGrow='1'
                >
                    {Array.isArray(topData) && topData.length > 0 ? (
                        <FlexDiv>
                            {(topData || []).map((item) => (
                                <StatBox key={item?.key} {...(item || {})} />
                            ))}
                        </FlexDiv>
                    ) : null}
                    <FlexDiv>
                        <FlexDiv
                            flexDirection='column'
                            alignItems='stretch'
                            flex='1'
                        >
                            <ChartContainer>
                                <ChartWrapper style={{ height: chartHeight }}>
                                    <Box height={chartHeight} width='100%'>
                                        <HorizontalBar
                                            data={{
                                                labels: chartLabels,
                                                datasets: [
                                                    {
                                                        data: chartData,
                                                        backgroundColor: `rgb(${baseColorRGB})`,
                                                        borderWidth: 0,
                                                        metaData,
                                                    },
                                                ],
                                            }}
                                            options={{
                                                ...getCommonBarOptions({
                                                    padding: {
                                                        left: TICK_WIDTH,
                                                        right: 20,
                                                        top: 0,
                                                        bottom: 0,
                                                    },
                                                    xTicks: {
                                                        display: false,
                                                        precision: 0,
                                                    },
                                                    yTicks: {
                                                        display: true,
                                                        mirror: true,
                                                        padding: TICK_WIDTH,
                                                        callback: function (
                                                            value
                                                        ) {
                                                            if (
                                                                value &&
                                                                value?.toString &&
                                                                value.length >
                                                                    MAX_TICK_CHARS
                                                            ) {
                                                                return (
                                                                    value
                                                                        .toString()
                                                                        .substr(
                                                                            0,
                                                                            MAX_TICK_CHARS
                                                                        ) +
                                                                    '...'
                                                                );
                                                            }
                                                            return value;
                                                        },
                                                    },
                                                    cornerRadius: 0,
                                                    fullCornerRadius: false,
                                                    fontColor: '#BEBCC8',
                                                    barThickness: 8,
                                                    maxBarThickness: 8,
                                                    theme,
                                                }),
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                animation: {},
                                                lineAtIndex: average,
                                                tooltips: getTooltip
                                                    ? {
                                                          // Disable the on-canvas tooltip
                                                          enabled: false,
                                                          custom: getTooltip,
                                                          callbacks: {
                                                              label: function (
                                                                  tooltipItem,
                                                                  data
                                                              ) {
                                                                  const label =
                                                                      data
                                                                          .labels[
                                                                          tooltipItem
                                                                              .index
                                                                      ];
                                                                  const value =
                                                                      data
                                                                          .datasets[
                                                                          tooltipItem
                                                                              .datasetIndex
                                                                      ].data[
                                                                          tooltipItem
                                                                              .index
                                                                      ];
                                                                  const metaData =
                                                                      data
                                                                          .datasets[
                                                                          tooltipItem
                                                                              .datasetIndex
                                                                      ]
                                                                          .metaData[
                                                                          tooltipItem
                                                                              .index
                                                                      ];
                                                                  return {
                                                                      label,
                                                                      value,
                                                                      metaData,
                                                                  };
                                                              },
                                                          },
                                                      }
                                                    : {},
                                            }}
                                        />
                                    </Box>
                                </ChartWrapper>
                            </ChartContainer>
                            {/* Sticky x-axis at the bottom of the chart */}
                            <FlexDiv
                                height='50px'
                                flexDirection='column'
                                alignItems='stretch'
                                mb='x4'
                                mt='-24px'
                            >
                                <HorizontalBar
                                    data={{
                                        labels: chartLabels,
                                        datasets: [
                                            {
                                                data: chartData,
                                                backgroundColor: 'rgb(0,0,0,0)',
                                                borderWidth: 0,
                                                metaData,
                                            },
                                        ],
                                    }}
                                    options={{
                                        ...getCommonBarOptions({
                                            padding: {
                                                left: TICK_WIDTH,
                                                right: 20,
                                                top: 0,
                                                bottom: 0,
                                            },
                                            xTicks: {
                                                display: true,
                                                precision: 0,
                                            },
                                            yTicks: {
                                                display: false,
                                            },
                                        }),
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        animation: {},
                                    }}
                                />
                                <FlexDiv justifyContent='flex-end'>
                                    <XAxisLabel
                                        variant='caption'
                                        color='ambience.6'
                                        pb={2}
                                        pr='x4'
                                    >
                                        {xAxisLabel}
                                    </XAxisLabel>
                                </FlexDiv>
                            </FlexDiv>
                        </FlexDiv>
                        <FlexDiv
                            flexDirection='column'
                            alignItems='stretch'
                            pl='x4'
                        >
                            <FlexDiv pb={2} alignItems='center'>
                                <Legend background={`rgb(${baseColorRGB})`} />
                                <Typography
                                    variant='caption'
                                    color='ambience.6'
                                    pl={1}
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    {type}s
                                </Typography>
                            </FlexDiv>
                            <FlexDiv pb={2} alignItems='center'>
                                <Legend background='#F29F43' />
                                <Typography
                                    variant='caption'
                                    color='ambience.6'
                                    pl={1}
                                >
                                    Average Attendance
                                </Typography>
                            </FlexDiv>
                        </FlexDiv>
                    </FlexDiv>
                    <ChartPills
                        pills={pills}
                        selectedPill={selectedPill}
                        setSelectedPill={setSelectedPill}
                        isEmptyChart={isEmptyChart}
                    />
                </FlexDiv>
            </ChartCard>
        </FlexDiv>
    );
}

const ChartContainer = styled(FlexDiv)`
    flex-grow: 1;
    overflow: hidden auto;
    max-height: 600px;
    flex-direction: column;
    align-items: stretch;
    ${({ theme, forceDarkMode }) => getCustomScroll({ theme, forceDarkMode })};
`;

const ChartWrapper = styled(FlexDiv)`
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    position: relative;
`;

const XAxisLabel = styled(Typography)`
    align-self: flex-end;
    padding-top: 12px;
`;
