import { CALL_API } from '../../api';

export const INTEGRATION_KEY_REQUEST = 'INTEGRATION_KEY_REQUEST';
export const INTEGRATION_KEY_RESPONSE = 'INTEGRATION_KEY_RESPONSE';
export const ADD_INTEGRATION_KEY_REQUEST = 'ADD_INTEGRATION_KEY_REQUEST';
export const ADD_INTEGRATION_KEY_RESPONSE = 'ADD_INTEGRATION_KEY_RESPONSE';
export const INTEGRATION_UPDATE_REQUEST = 'INTEGRATION_UPDATE_REQUEST';
export const INTEGRATION_UPDATE_SUCCESS = 'INTEGRATION_UPDATE_SUCCESS';

//Dashboard integration
export function getIntegrationKeys(communityId) {
    return {
        [CALL_API]: {
            types: [INTEGRATION_KEY_REQUEST, INTEGRATION_KEY_RESPONSE],
            endpoint: `/community-api-keys/${communityId}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function addIntegrationKey(communityId, label) {
    let data = {
        label: label,
    };

    return {
        [CALL_API]: {
            types: [ADD_INTEGRATION_KEY_REQUEST, ADD_INTEGRATION_KEY_RESPONSE],
            endpoint: `/community-api-keys/${communityId}`,
            body: data,
            type: 'json',
            method: 'POST',
        },
    };
}
export function updateIntegrationKey(communityId, data) {
    return {
        [CALL_API]: {
            types: [INTEGRATION_UPDATE_REQUEST, INTEGRATION_UPDATE_SUCCESS],
            endpoint: `/community-api-keys/${communityId}`,
            body: data,
            type: 'json',
            method: 'PUT',
        },
    };
}
