import Button from 'atoms/Button/button';
import InputV2 from 'atoms/Input';
import RadioV2 from 'atoms/Radio/RadioV2';
import { SESSION_RESOURCES_TAB } from 'components/community/editPublicAirmeet/constants/tabs';
import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import { getCustomScroll } from 'components/molecules/utils/customStyles';
import { Typography } from 'foundations/typography';
import usePreRecordedSessionVideoUploader from 'hooks/community/usePreRecordedSessionVideoUploader';
import useEventDashboardFeatures from 'hooks/dashboard/useEventDashboardFeatures';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { cdnImage } from 'utils/core';
import history from '../../../../../../../history';

export default function VideoLibrary({
    onClose = Function,
    mapVideoToSession = Function,
    videosList = Array,
    shouldRefresh = Boolean,
    getVideos = Function,
    airmeetId,
    isThemeV2 = false,
    airmeetFormat = 'event',
}) {
    const [selectedVideoIndex, setSelectVideoIndex] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [filteredVideoList, setfilteredVideoList] = useState(videosList);

    const { getFeatureUrl } = useEventDashboardFeatures();

    const { getVideoDuration } = usePreRecordedSessionVideoUploader();

    useEffect(() => {
        shouldRefresh && getVideos();
    }, [shouldRefresh, getVideos]);

    const handleAddVideo = useCallback(async () => {
        if (
            selectedVideoIndex === undefined ||
            selectedVideoIndex === null ||
            !videosList?.length ||
            !videosList[selectedVideoIndex]
        ) {
            return;
        }

        const duration = await getVideoDuration(
            videosList[selectedVideoIndex].url,
            true
        );
        mapVideoToSession({
            videoInfo: { ...videosList[selectedVideoIndex], duration },
            clear: false,
            duration,
        });
        onClose();
    }, [
        mapVideoToSession,
        selectedVideoIndex,
        videosList,
        onClose,
        getVideoDuration,
    ]);

    const handleGoToVideo = () => {
        history.push(getFeatureUrl(SESSION_RESOURCES_TAB));
    };
    const handleSearch = (e) => {
        const { value } = e.target;
        setSearchText(value);
    };

    const handleClear = (e) => {
        setSearchText('');
    };
    useEffect(() => {
        const options = videosList.filter((item) => {
            if (searchText === '') {
                return item;
            }
            return item.title.toLowerCase().includes(searchText.toLowerCase());
        });
        setfilteredVideoList(options);
    }, [searchText]);
    return (
        <FlexDiv mt='x2' flexDirection='column' flex={1}>
            {!videosList?.length && (
                <VideoEmptyState
                    handleGoToVideo={handleGoToVideo}
                    isThemeV2={isThemeV2}
                    airmeetFormat={airmeetFormat}
                />
            )}
            {videosList?.length > 0 && (
                <>
                    <Box mb={'24px'}>
                        <InputV2
                            id='search'
                            name='search'
                            onChange={handleSearch}
                            value={searchText}
                            placeholder={`Search by ${airmeetFormat}, recording name`}
                            rightIcon='search-icon'
                            onClear={handleClear}
                        />
                    </Box>
                    <Typography
                        variant={isThemeV2 ? 'h5' : 'subtext2-bold'}
                        color={
                            isThemeV2 ? 'text.default.primary' : 'ambience.23'
                        }
                        mb={isThemeV2 ? '' : '20px'}
                        isThemeV2={isThemeV2}
                    >
                        Select a Video
                    </Typography>
                    <ScrollView>
                        {filteredVideoList.map((video, index) => (
                            <VideoItem
                                key={'video_list' + index}
                                onClick={() => setSelectVideoIndex(index)}
                            >
                                <RadioV2
                                    iconSize={20}
                                    isChecked={index === selectedVideoIndex}
                                    name={'video'}
                                    uncheckedFill={
                                        isThemeV2
                                            ? 'text.default.secondary'
                                            : 'ambience.14'
                                    }
                                    checkedFill={
                                        isThemeV2
                                            ? 'brandDefault'
                                            : 'semantic.2'
                                    }
                                    checkedIcon={'checkmark_circle'}
                                />
                                <FlexDiv ml={'12px'}>
                                    <Box
                                        maxWidth={'124px'}
                                        width={'100%'}
                                        height={'70px'}
                                        borderRadius={'4px'}
                                        overflow={'hidden'}
                                        mr={'12px'}
                                    >
                                        <img
                                            src={
                                                video.thumbnail ||
                                                cdnImage(
                                                    '/images/dashboard/default-video-thumbnail.png'
                                                )
                                            }
                                            width={124}
                                            height={70}
                                            alt='video thumb'
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        <Typography
                                            variant={
                                                isThemeV2
                                                    ? 'caption-bold'
                                                    : 'subtext0-bold'
                                            }
                                            color={
                                                isThemeV2
                                                    ? 'text.default.primary'
                                                    : 'ambience.20'
                                            }
                                            isThemeV2={isThemeV2}
                                        >
                                            {video.title}
                                        </Typography>
                                    </Box>
                                </FlexDiv>
                            </VideoItem>
                        ))}
                    </ScrollView>

                    <Box pt={isThemeV2 ? 'x7' : '24px'}>
                        <StyledButton
                            variant={'primary'}
                            disabled={selectedVideoIndex === null}
                            onClick={handleAddVideo}
                            isThemeV2={isThemeV2}
                        >
                            Add video
                        </StyledButton>
                    </Box>
                </>
            )}
        </FlexDiv>
    );
}

const VideoEmptyState = ({ handleGoToVideo, isThemeV2, airmeetFormat }) => {
    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            justifyContent={'center'}
            mt={'18px'}
        >
            <img
                src={cdnImage('/images/bad-luck.svg')}
                alt={'bad luck'}
                width={127}
                height={140}
            />
            <Typography
                variant={isThemeV2 ? 'h5' : 'subtitle1'}
                color={isThemeV2 ? 'text.default.primary' : 'ambience.19'}
                textAlign={'center'}
                mt={isThemeV2 ? 'x4' : '18px'}
                isThemeV2={isThemeV2}
            >
                Nothing to see here!
            </Typography>
            <Typography
                variant={isThemeV2 ? 'caption' : 'subtext0'}
                color={isThemeV2 ? 'text.default.secondary' : 'ambience.8'}
                mt={'5px'}
                mb={'16px'}
                mx={'auto'}
                style={{
                    maxWidth: '358px',
                }}
                textAlign='center'
            >
                You haven't uploaded any videos to the {airmeetFormat} yet.
                Please add a few videos from the <b>'Videos'</b> section.
            </Typography>
            <Box>
                <Button
                    variant={'primary'}
                    onClick={handleGoToVideo}
                    width={isThemeV2 ? '' : '116px'}
                    isThemeV2={isThemeV2}
                >
                    Go to Videos
                </Button>
            </Box>
        </Box>
    );
};

const ScrollView = styled(Box)`
    margin-top: 20px;
    flex 1;
    overflow: auto;
    max-height: calc(100vh - 310px);
    
    ${({ verticalWidth = '12px', theme, forceDarkMode = true }) =>
        getCustomScroll({ theme, verticalWidth, forceDarkMode })}
`;

const StyledButton = styled(Button)`
    ${({ disabled, theme }) =>
        disabled &&
        `
        background: ${theme.colors.accentPrimary[0]} !important;
        opacity: 0.48 !important;
        color: ${theme.colors.ambience[16]}!important
    `}
`;

const VideoItem = styled(Box)`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 8px 12px;
`;
