import ImageEditor from 'components/molecules/imageEditor/ImageEditor';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { getZIndex } from 'foundations/themeV2/zIndex';
import isNumber from 'lodash/isNumber';
import animationData from 'lotties/threeDotsLoader.json';
import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';

const DEFAULT_ZOOM = 1;
const ROTATION = 0;

const PreviewImage = ({
    blur,
    opacity,
    scaledZoom,
    imgUrl,
    isImageLoading,
    setIsImageLoading,
    setCropData,
    children,
    previewHeight,
    imageHeight = 720,
    imageWidth = 1440,
}) => {
    const onReady = () => setIsImageLoading(false);

    return (
        <ImageCropperWrapper blur={blur} previewHeight={previewHeight}>
            {isImageLoading ? (
                <LoaderWrapper>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationData,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice',
                            },
                        }}
                        height={'40px'}
                        width={'80px'}
                        isClickToPauseDisabled={true}
                    />
                </LoaderWrapper>
            ) : null}
            <ImageEditor
                zoom={scaledZoom ? scaledZoom + DEFAULT_ZOOM : DEFAULT_ZOOM}
                rotate={ROTATION}
                aspectRatio={imageWidth / imageHeight}
                initialAspectRatio={imageWidth / imageHeight}
                img={imgUrl}
                onCrop={setCropData}
                style={{
                    backgroundColor: '#C4C4C4',
                    width: '100%',
                    height: '100%',
                }}
                wrapperStyles={{
                    height: '100%',
                }}
                zoomOnTouch={false}
                zoomOnWheel={false}
                cropBoxResizable={false}
                minCropBoxHeight={imageHeight}
                minCropBoxWidth={imageWidth}
                autoCropArea={1}
                cropBoxBorderRadius={8}
                onReady={onReady}
                skipCDNise={true}
            />
            <Overlay opacity={1 - opacity} />
            {children}
        </ImageCropperWrapper>
    );
};

export default PreviewImage;

const ImageCropperWrapper = styled(FlexDiv)`
    width: 100%;
    position: relative;
    justify-content: center;
    height: 85%;

    & .cropper-view-box {
        outline-color: hsla(185, 100%, 61%, 1);
    }

    & .cropper-container {
        border: 0.405px dashed #39eeff;
        background: rgba(74, 239, 255, 0.3);
    }

    ${({ blur }) =>
        isNumber(blur) && blur > 0
            ? `
            & .cropper-container img {
                filter: blur(${blur / 20}px);
            }
    `
            : ''}
`;

const Overlay = styled(FlexDiv)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    ${({ opacity, theme }) =>
        isNumber(opacity) &&
        `
        background-color: ${theme.colors.text.other.primary};
        opacity: ${opacity}
    `};
`;

const LoaderWrapper = styled(FlexDiv)`
    position: absolute;
    height: 100%;
    width: 100%;
    align-items: center;
    z-index: ${({ theme }) =>
        getZIndex({
            zIndexType: theme.zIndices.types.above,
            zIndexRange: theme.zIndices.ranges['2xl'],
        })};
    background-color: ${({ theme }) => theme.colors.overlay.default1};
    border-radius: ${({ theme }) => theme.radii.default};
`;
