import Button from 'atoms/Button/button';
import JoditEditorWrapper from 'components/general/Form/JoditEditor';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import {
    Box,
    FlexDiv,
    UnstyledButton,
} from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import styled from 'styled-components';
import FormAccordion from '../FormAccordion';
import EventEntryField from '../FormFields/EventEntryField';
import { Organiser } from '../FormFields/Organiser';
import SupportEmail from '../FormFields/SupportEmail';
import WaitlistField from '../FormFields/WaitlistField';

function EventDetails({
    formData,
    onValueChange,
    errors,
    pageMode,
    isEventEnded,
    openAirGenie,
    isLongDescVisible = true,
    eventEntryValue,
    setEventEntryValue,
    domainList,
    setDomainList,
    editMode,
    airmeetId,
    airmeetFormat,
    isHybridEvent,
    isInPersonEvent,
    fixedEventEntryType,
    isFullScreen = false,
    isEventEntryOpen,
    setIsEventEntryOpen,
    isAdditionalInfoOpen,
    setIsAdditionalInfoOpen,
}) {
    const handleLongDescChanged = (content) => {
        onValueChange && onValueChange('longDesc', content);
    };

    return (
        <Box pb={isFullScreen ? '' : '140px'}>
            {isHybridEvent ? null : (
                <EventEntryField
                    eventEntryValue={eventEntryValue}
                    setEventEntryValue={setEventEntryValue}
                    domainList={domainList}
                    setDomainList={setDomainList}
                    editMode={editMode}
                    airmeetId={airmeetId}
                    fixedEventEntryType={fixedEventEntryType}
                    isInPersonEvent={isInPersonEvent}
                    airmeetFormat={airmeetFormat}
                    isEventEntryOpen={isEventEntryOpen}
                    setIsEventEntryOpen={setIsEventEntryOpen}
                />
            )}
            <FormAccordion
                title='Additional information'
                defaultIsOpen={!!airmeetId}
                mt='x6'
                isOpen={isAdditionalInfoOpen}
                setIsOpen={setIsAdditionalInfoOpen}
            >
                {isInPersonEvent && (
                    <WaitlistField
                        onValueChange={onValueChange}
                        value={
                            formData?.isWaitlistEnabled &&
                            eventEntryValue !== 'invitation'
                        }
                        disabled={eventEntryValue === 'invitation'}
                    />
                )}
                <FlexDiv alignItems='center' mb={1}>
                    <Text variant='body2' color='text.default.primary' flex='1'>
                        {upperFirst(airmeetFormat)} description
                    </Text>
                    {openAirGenie ? (
                        <UnstyledButton
                            onClick={() =>
                                openAirGenie &&
                                openAirGenie({
                                    name: 'longDesc',
                                    label: `${airmeetFormat} description`,
                                    apiParams: {
                                        type: 'eventDesc',
                                    },
                                    maxResponseLength: 10000,
                                })
                            }
                            type='button'
                        >
                            <FlexDiv alignItems='center'>
                                <StyledSvgSprite
                                    icon='magic_wand'
                                    width='1rem'
                                    height='1rem'
                                    mr='x1'
                                    fill='module.airgenie'
                                />
                                <Text
                                    variant='caption-bold'
                                    color='module.airgenie'
                                >
                                    Auto-generate
                                </Text>
                            </FlexDiv>
                        </UnstyledButton>
                    ) : null}
                </FlexDiv>
                <StyledEditor>
                    {isLongDescVisible ? (
                        <JoditEditorWrapper
                            key='longDesc'
                            id='longDesc'
                            name='longDesc'
                            editorContent={formData?.longDesc}
                            onChangeHandler={handleLongDescChanged}
                            config={{ height: '190px' }}
                        />
                    ) : null}
                </StyledEditor>
                <FlexDiv mt='x6'>
                    <Box className='form-field-outer' flex='1' pr='x2'>
                        <Organiser
                            formData={formData}
                            errors={errors}
                            pageMode={pageMode}
                            label='Organizer name'
                            onChange={(e) =>
                                onValueChange(
                                    'eventOrganiserName',
                                    e?.target?.value
                                )
                            }
                            subLabel={`Business or community name organizing the ${airmeetFormat}.`}
                        />
                    </Box>
                    <Box className='form-field-outer' flex='1' pl='x1'>
                        <SupportEmail
                            formData={formData}
                            errors={errors}
                            pageMode={pageMode}
                            onChange={(e) =>
                                onValueChange(
                                    'supportEmail',
                                    e?.target?.value.trim()
                                )
                            }
                            subLabel='Email id on landing page for attendees to contact organizer.'
                            isEventEnded={isEventEnded}
                        />
                    </Box>
                </FlexDiv>
            </FormAccordion>
        </Box>
    );
}

export default EventDetails;

export const StyledEditor = styled(Box)`
    > div {
        flex: 1;
        padding: 0;
        margin: 0;
    }
    textarea {
        opacity: 0;
    }
    .jodit-container {
        border-radius: 8px !important;
        min-height: 200px !important;
        background-color: ${({ theme }) =>
            theme.colors.ambience[19]} !important;
        border-color: ${({ theme }) => theme.colors.ambience[19]} !important;
        .jodit-workplace,
        .jodit-wysiwyg {
            border-color: ${({ theme }) =>
                theme.colors.ambience[19]} !important;
            min-height: 64px !important;
            background-color: ${({ theme }) =>
                theme.colors.ambience[22]} !important;
        }
        .jodit-toolbar__box:not(:empty)
            .jodit-toolbar-editor-collection::after {
            background-color: ${({ theme }) =>
                theme.colors.ambience[19]} !important;
        }
    }
`;

export const TooltipContent = styled(Box)`
    padding: 16px 24px;
    background: ${({ theme }) => theme.colors.ambience[0]};
    border-radius: 8px;
    max-width: 262px;
`;

export const StyledButton = styled(Button)`
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    p {
        color: ${({ theme, isDisabled }) =>
            isDisabled && theme.colors.ambience[14]};
    }
`;
