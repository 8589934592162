import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from 'utils/logger';
import { createLeads, getLeads, updateLeads } from 'store/actions/leads';
import { CREATE_LEAD, UPDATE_LEAD } from './utils';
import useIpMetaInfo from 'hooks/useIpMetaInfo';
import isFunction from 'lodash/isFunction';

const leadDataOperations = {
    [CREATE_LEAD]: createLeads,
    [UPDATE_LEAD]: updateLeads,
};

function useLeadHandler() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const { queryMetaInfo } = useIpMetaInfo({ isAuto: false });

    const handleGetLeadsData = useCallback(
        async (leadName, setLoading = null) => {
            if (isFunction(setLoading)) setLoading(true);
            const payload = {
                email: user?.email,
                name: leadName,
            };
            try {
                await dispatch(getLeads(payload));
                if (isFunction(setLoading)) setLoading(false);
            } catch (error) {
                logger.error(error);
            }
        },
        [dispatch, user]
    );

    const handleLeadAction = useCallback(
        async (operation, payload, leadName) => {
            let leadPayload = payload;

            if (!leadPayload?.leads[0]?.metadata?.country) {
                const { country } = await queryMetaInfo();
                if (country)
                    leadPayload = {
                        ...leadPayload,
                        leads: [
                            {
                                ...leadPayload?.leads[0],
                                metadata: {
                                    ...leadPayload?.leads[0]?.metadata,
                                    country,
                                },
                            },
                        ],
                    };
            }

            const response = await dispatch(
                leadDataOperations[operation](leadPayload)
            );
            if (!response.error) {
                handleGetLeadsData(leadName);
            }
        },
        [dispatch, handleGetLeadsData, queryMetaInfo]
    );

    const handleCreateLead = useCallback(
        async (payload, leadName) => {
            try {
                await handleLeadAction(CREATE_LEAD, payload, leadName);
            } catch (error) {
                logger.error(error);
            }
        },
        [handleLeadAction]
    );

    const handleUpdateLead = useCallback(
        async (payload, leadName) => {
            try {
                await handleLeadAction(UPDATE_LEAD, payload, leadName);
            } catch (error) {
                logger.error(error);
            }
        },
        [handleLeadAction]
    );

    return {
        handleGetLeadsData,
        handleCreateLead,
        handleUpdateLead,
    };
}

export default useLeadHandler;
