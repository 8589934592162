import { useEffect, useMemo } from 'react';
import { getCroppedAttendeeImageURL } from 'utils/constants/users';
import { getStageUserIds } from 'utils/users';

const useAirmeetUserServicePrefetch = (airmeet, userService) => {
    const loadedUsers = airmeet?.assocUsers;
    useMemo(() => {
        if (userService && loadedUsers) {
            userService.addUsers(Object.values(loadedUsers));
        }
    }, [loadedUsers, userService]);

    useEffect(() => {
        if (!userService || !airmeet) {
            return;
        }

        async function prefetch() {
            userService.addUsers(airmeet?.session_hosts || []);
            userService.addUsers(airmeet?.session_cohosts || []);

            if (airmeet.sessions) {
                for (let session of airmeet.sessions) {
                    if (session.cloud_user_id_seq) {
                        userService.addUsers([
                            {
                                id: session.cloud_user_id,
                                name: 'CloudUser',
                                id_seq: session.cloud_user_id_seq,
                                user_type: 'cloud_user',
                                profile_img: '',
                            },
                        ]);
                    }
                    if (session.recording_user_id_seq) {
                        userService.addUsers([
                            {
                                id: session.recording_user_id,
                                name: 'CloudUser',
                                id_seq: session.recording_user_id_seq,
                                user_type: 'cloud_user',
                                profile_img: '',
                            },
                        ]);
                    }

                    session?.speakerList &&
                        session.speakerList.forEach((speaker) => {
                            if (!speaker.speaker_img_original) {
                                speaker.speaker_img_original =
                                    speaker.speaker_img;
                            }

                            speaker.speaker_img = getCroppedAttendeeImageURL(
                                speaker.speaker_img
                            );

                            if (!speaker.profile_img && speaker.speaker_img) {
                                speaker.profile_img = speaker.speaker_img;
                            }

                            if (
                                !speaker.profile_img_original &&
                                speaker.speaker_img
                            ) {
                                speaker.profile_img_original =
                                    speaker.speaker_img;
                            }
                        });
                    userService.addUsers(session.speakerList || []);
                }
            }

            const userIds = getStageUserIds(airmeet);
            userService.fetchUsers(userIds, {
                prioritize: true,
            });
        }

        prefetch();
    }, [airmeet, userService]);
};

export default useAirmeetUserServicePrefetch;
