import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { emptyObject } from 'utils/constants/common';
import FirebaseLogger from 'utils/firebaseLogger';

const useSetAnalyticsData = ({
    airmeetId,
    airmeet,
    session,
    user,
    userStageRole,
    userRole,
}) => {
    const airmeetVisitId = useSelector(({ liveAirmeet }) => {
        return liveAirmeet.airmeetVisitId;
    });

    const {
        community_name: communityName,
        community_id: communityId,
        status: airmeetStatus,
    } = airmeet || emptyObject;
    const { id: userId, eid } = user || emptyObject;
    const { sessionid: sessionId, type: sessionType, status: sessionStatus } =
        session || emptyObject;
    const logData = useMemo(() => {
        const data = {
            airmeet_visit_id: airmeetVisitId,
            community_name: communityName,
            community_id: communityId,
            attendee_id: userId,
            airmeet_id: airmeetId,
            airmeet_status: airmeetStatus,
            session_status: sessionStatus,
            user_id: userId,
            user_role: userRole,
            user_stage_role: userStageRole,
            airmeet_session_id: sessionId,
            airmeet_session_type: sessionType,
            user_eid: eid,
        };
        FirebaseLogger.setCommonData(data);
        return data;
    }, [
        airmeetVisitId,
        communityId,
        communityName,
        airmeetId,
        airmeetStatus,
        sessionStatus,
        userStageRole,
        userRole,
        sessionId,
        sessionType,
        userId,
        eid,
    ]);
};

export default useSetAnalyticsData;
