import useCustomBaseUrl from 'hooks/useCustomBaseUrl';
import React from 'react';
import styled from 'styled-components';
import { redirectToHomePage } from 'utils/redirections';
import { getCurrentTenantData } from 'utils/tenant';
import history from '../../history';
import { cdnImage } from '../../utils/core';

function AppLogo({
    logoOnly = false,
    center = false,
    isLightTheme,
    isOnOnboarding,
}) {
    const cPath = window.location.pathname;
    const inDashboard =
        cPath.indexOf('community-manager') > -1 ||
        cPath.indexOf('airmeets') > -1;
    const logoPath = logoOnly
        ? cdnImage(getCurrentTenantData('logoNoTextUrl'))
        : isLightTheme
        ? cdnImage(getCurrentTenantData('logoLightThemeUrl'))
        : cdnImage(getCurrentTenantData('logoUrl'));
    const { baseUrl, whiteLabelText } = useCustomBaseUrl();
    return (
        <span
            className='logo'
            style={{ textAlign: center ? 'center' : 'inherit' }}
        >
            <ImageWrapper
                src={logoPath}
                alt={whiteLabelText(getCurrentTenantData('name'), '')}
                title={whiteLabelText(getCurrentTenantData('name'), '')}
                className='m-100'
                style={{
                    cursor:
                        cPath.indexOf('/event/') !== 0 && !isOnOnboarding
                            ? 'pointer'
                            : 'default',
                }}
                onClick={() => {
                    inDashboard
                        ? history.replace('/signin')
                        : cPath.indexOf('/event/') !== 0 &&
                          !isOnOnboarding &&
                          redirectToHomePage(baseUrl);
                }}
                tabIndex={0}
                aria-label={`${getCurrentTenantData('name')} Logo`}
                role='link'
            />
        </span>
    );
}

const ImageWrapper = styled.img`
    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: solid;
    }
`;

export default AppLogo;
