import { stringify } from 'querystring';
import { API_SERVICE } from '../../utils/apiService';
import { CALL_API } from '../api';

export const FETCH_COMMUNITY_REQUEST = 'FETCH_COMMUNITY_REQUEST';
export const FETCH_COMMUNITY_RESPONSE = 'FETCH_COMMUNITY_RESPONSE';
export const FETCH_MY_COMMUNITIES_REQUEST = 'FETCH_MY_COMMUNITIES_REQUEST';
export const FETCH_MY_COMMUNITIES_RESPONSE = 'FETCH_MY_COMMUNITIES_RESPONSE';
export const FETCH_COMMUNTIY_USER_DETAILS_REQUEST =
    'FETCH_COMMUNTIY_USER_DETAILS_REQUEST';
export const FETCH_COMMUNTIY_USER_DETAILS_RESPONSE =
    'FETCH_COMMUNTIY_USER_DETAILS_RESPONSE';
export const FETCH_COMMUNITY_AIRMEETS_REQUEST =
    'FETCH_COMMUNITY_AIRMEETS_REQUEST';
export const FETCH_COMMUNITY_AIRMEETS_RESPONSE =
    'FETCH_COMMUNITY_AIRMEETS_RESPONSE';
export const PUT_COMMUNITY_REQUEST = 'PUT_COMMUNITY_REQUEST';
export const PUT_COMMUNITY_RESPONSE = 'PUT_COMMUNITY_RESPONSE';
export const CREATE_COMMUNITY_REQUEST = 'CREATE_COMMUNITY_REQUEST';
export const CREATE_COMMUNITY_RESPONSE = 'CREATE_COMMUNITY_RESPONSE';
export const CREATE_APPSUMO_CODE_REQUEST = 'CREATE_APPSUMO_CODE_REQUEST';
export const CREATE_APPSUMO_CODE_RESPONSE = 'CREATE_APPSUMO_CODE_RESPONSE';
export const FETCH_COMMUNITY_CURRENT_BILLING_PLANS_RESPONSE =
    'FETCH_COMMUNITY_CURRENT_BILLING_PLANS_RESPONSE';
export const FETCH_COMMUNITY_CURRENT_BILLING_PLANS_REQUEST =
    'FETCH_COMMUNITY_CURRENT_BILLING_PLANS_REQUEST';
export const RESET_USER_BILLING_PLAN = 'RESET_USER_BILLING_PLAN';
export const RESET_COMMUNITY_LOADING_INDICATOR =
    'RESET_COMMUNITY_LOADING_INDICATOR';
export const UPDATE_EVENT_MANAGERS_LIST = 'UPDATE_EVENT_MANAGERS_LIST';
export const SET_DEFAULT_COMMUNITY = 'SET_DEFAULT_COMMUNITY';
export const PAUSE_SUBSCRIPTION_RESPONSE = 'PAUSE_SUBSCRIPTION_RESPONSE';
export const PAUSE_SUBSCRIPTION_REQUEST = 'PAUSE_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_RESPONSE = 'CANCEL_SUBSCRIPTION_RESPONSE';
export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const RESUME_SUBSCRIPTION_REQUEST = 'RESUME_SUBSCRIPTION_REQUEST';
export const RESUME_SUBSCRIPTION_RESPONSE = 'RESUME_SUBSCRIPTION_RESPONSE';
export const FETCH_FIRST_CREATED_AIRMEET_REQUEST =
    'FETCH_FIRST_CREATED_AIRMEET_REQUEST';
export const FETCH_FIRST_CREATED_AIRMEET_RESPONSE =
    'FETCH_FIRST_CREATED_AIRMEET_RESPONSE';
export const FETCH_PAGINATED_AIRMEETS_REQUEST =
    'FETCH_PAGINATED_AIRMEETS_REQUEST';
export const FETCH_PAGINATED_AIRMEETS_RESPONSE =
    'FETCH_PAGINATED_AIRMEETS_RESPONSE';
export const RESET_COMMUNITY_AIRMEETS = 'RESET_COMMUNITY_AIRMEETS';
export const SET_FIRST_CREATED_AIRMEET = 'SET_FIRST_CREATED_AIRMEET';
export const SET_AIRMEETS = 'SET_AIRMEETS';
export const FETCH_USER_PROPERTIES_REQUEST = 'FETCH_USER_PROPERTIES_REQUEST';
export const FETCH_USER_PROPERTIES_RESPONSE = 'FETCH_USER_PROPERTIES_RESPONSE';
export const GET_EVENT_ORGANISER_REQUEST = 'GET_EVENT_ORGANISER_REQUEST';
export const GET_EVENT_ORGANISER_RESPONSE = 'GET_EVENT_ORGANISER_RESPONSE';
export const UPDATE_EVENT_ORGANISERS_REQUEST =
    'UPDATE_EVENT_ORGANISERS_REQUEST';
export const UPDATE_EVENT_ORGANISERS_RESPONSE =
    'UPDATE_EVENT_ORGANISERS_RESPONSE';
export const FETCH_ONE_ARCHIVED_AIRMEET_REQUEST =
    'FETCH_ONE_ARCHIVED_AIRMEET_REQUEST';
export const FETCH_ONE_ARCHIVED_AIRMEET_RESPONSE =
    'FETCH_ONE_ARCHIVED_AIRMEET_RESPONSE';
export const START_FREE_TRIAL_REQUEST = 'START_FREE_TRIAL_REQUEST';
export const START_FREE_TRIAL_RESPONSE = 'START_FREE_TRIAL_RESPONSE';

export function getCommunity(communityId) {
    return {
        [CALL_API]: {
            types: [FETCH_COMMUNITY_REQUEST, FETCH_COMMUNITY_RESPONSE],
            endpoint: `/airmeet/community?communityId=${communityId}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function fetchCommunityUserDetails(userIds) {
    const queryParams = stringify({ userIds });
    return {
        [CALL_API]: {
            types: [
                FETCH_COMMUNTIY_USER_DETAILS_REQUEST,
                FETCH_COMMUNTIY_USER_DETAILS_RESPONSE,
            ],
            endpoint: `/users?${queryParams}`,
            method: 'GET',
        },
    };
}

export function getCommunityAirmeets(communityId, showDuplicates = false) {
    return {
        [CALL_API]: {
            types: [
                FETCH_COMMUNITY_AIRMEETS_REQUEST,
                FETCH_COMMUNITY_AIRMEETS_RESPONSE,
            ],
            endpoint: `/airmeet/community/getAirmeets?communityId=${communityId}&showDuplicates=${showDuplicates}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function getFirstCreatedAirmeet({ communityId }) {
    return {
        [CALL_API]: {
            types: [
                FETCH_FIRST_CREATED_AIRMEET_REQUEST,
                FETCH_FIRST_CREATED_AIRMEET_RESPONSE,
            ],
            endpoint: `/airmeet/community/v2/getAirmeets?communityId=${communityId}&sortBy=createdAt&order=ASC&pageSize=1&page=1`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function getOneArchivedAirmeet({ communityId }) {
    return {
        [CALL_API]: {
            types: [
                FETCH_ONE_ARCHIVED_AIRMEET_REQUEST,
                FETCH_ONE_ARCHIVED_AIRMEET_RESPONSE,
            ],
            endpoint: `/airmeet/community/v2/getAirmeets?communityId=${communityId}&sortBy=createdAt&order=ASC&pageSize=1&page=1&archived=true`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function getPaginatedAirmeets(params) {
    const queryParams = stringify(params || {});
    return {
        [CALL_API]: {
            types: [
                FETCH_PAGINATED_AIRMEETS_REQUEST,
                FETCH_PAGINATED_AIRMEETS_RESPONSE,
            ],
            endpoint: `/airmeet/community/v2/getAirmeets?${queryParams}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function getCurrentBillingPlanForCommunity(communityId) {
    return {
        [CALL_API]: {
            types: [
                FETCH_COMMUNITY_CURRENT_BILLING_PLANS_REQUEST,
                FETCH_COMMUNITY_CURRENT_BILLING_PLANS_RESPONSE,
            ],
            endpoint: `/airmeet/community/subscription?community_id=${communityId}`,
            method: 'GET',
            service: API_SERVICE.BFF,
            type: 'json',
        },
    };
}

export function pauseSubscription(apiData) {
    return {
        [CALL_API]: {
            types: [PAUSE_SUBSCRIPTION_REQUEST, PAUSE_SUBSCRIPTION_RESPONSE],
            endpoint: `/subscription/pause`,
            method: 'POST',
            body: apiData,
            service: API_SERVICE.BFF,
            type: 'json',
        },
    };
}

export function cancelSubscription(apiData) {
    return {
        [CALL_API]: {
            types: [CANCEL_SUBSCRIPTION_REQUEST, CANCEL_SUBSCRIPTION_RESPONSE],
            endpoint: `/subscription/cancel`,
            method: 'POST',
            body: apiData,
            service: API_SERVICE.BFF,
            type: 'json',
        },
    };
}

export function resumeSubscription(apiData = {}) {
    return {
        [CALL_API]: {
            types: [RESUME_SUBSCRIPTION_REQUEST, RESUME_SUBSCRIPTION_RESPONSE],
            endpoint: '/subscription/resume',
            method: 'POST',
            body: apiData,
            service: API_SERVICE.BFF,
            type: 'json',
        },
    };
}

export function getMyCommunities() {
    return {
        [CALL_API]: {
            types: [
                FETCH_MY_COMMUNITIES_REQUEST,
                FETCH_MY_COMMUNITIES_RESPONSE,
            ],
            endpoint: `/airmeet/community/getMyCommunities`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}
export function getOrganiserRole(airmeetId) {
    return {
        [CALL_API]: {
            types: [GET_EVENT_ORGANISER_REQUEST, GET_EVENT_ORGANISER_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/user-details`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function putCommunity(payload) {
    let apiData = {
        is_active: true,
        theme: 'airmeet-community-theme-1',
        ...payload,
    };

    return {
        [CALL_API]: {
            types: [PUT_COMMUNITY_REQUEST, PUT_COMMUNITY_RESPONSE],
            endpoint: `/airmeet/community`,
            body: { ...apiData },
            type: 'json',
            method: 'PUT',
        },
    };
}

export function createCommunity(payload) {
    return {
        [CALL_API]: {
            types: [CREATE_COMMUNITY_REQUEST, CREATE_COMMUNITY_RESPONSE],
            endpoint: `/airmeet/community`,
            body: {
                ...payload,
            },
            type: 'json',
            method: 'POST',
        },
    };
}

export function createAppSumoCode({ communityId, payload }) {
    return {
        [CALL_API]: {
            types: [CREATE_APPSUMO_CODE_REQUEST, CREATE_APPSUMO_CODE_RESPONSE],
            endpoint: `/airmeet/${communityId}/couponUpdate`,
            body: {
                ...payload,
            },
            type: 'json',
            method: 'POST',
        },
    };
}

export function resetUserBillingPlanData() {
    return {
        type: RESET_USER_BILLING_PLAN,
    };
}

export function resetCommunityLoadingIndicator() {
    return {
        type: RESET_COMMUNITY_LOADING_INDICATOR,
    };
}
export const updateEmList = (emIDs) => ({
    type: UPDATE_EVENT_MANAGERS_LIST,
    payload: { emList: emIDs },
});

export const setDefaultCommunity = (community) => ({
    type: SET_DEFAULT_COMMUNITY,
    payload: { community },
});

export function resetCommunityAirmeets() {
    return {
        type: RESET_COMMUNITY_AIRMEETS,
    };
}

export function setAirmeets(payload) {
    return {
        type: SET_AIRMEETS,
        payload,
    };
}

export function setFirstCreatedAirmeet(payload) {
    return {
        type: SET_FIRST_CREATED_AIRMEET,
        payload,
    };
}

export function getUserProperties({ communityId, clientIds = [] }) {
    const clientIdParams = clientIds
        .map((clientId) => `clientId=${clientId}`)
        .join('&');

    return {
        [CALL_API]: {
            types: [
                FETCH_USER_PROPERTIES_REQUEST,
                FETCH_USER_PROPERTIES_RESPONSE,
            ],
            endpoint: `/community/${communityId}/communityInfo?${clientIdParams}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function updateEventExecutives({
    airmeetId,
    executivesList,
    isEventSeries,
}) {
    return {
        [CALL_API]: {
            types: [
                UPDATE_EVENT_ORGANISERS_REQUEST,
                UPDATE_EVENT_ORGANISERS_RESPONSE,
            ],
            endpoint: `/${
                isEventSeries ? 'series' : 'airmeet'
            }/${airmeetId}/organisers`,
            body: {
                [isEventSeries ? 'eventSeriesExecutives' : 'eventExecutives']: [
                    ...executivesList,
                ],
            },
            type: 'json',
            method: 'PUT',
        },
    };
}

export function startTrial(communityId) {
    return {
        [CALL_API]: {
            types: [START_FREE_TRIAL_REQUEST, START_FREE_TRIAL_RESPONSE],
            endpoint: `/community/${communityId}/startTrial`,
            type: 'json',
            method: 'PUT',
        },
    };
}
