import { getDateStr } from 'components/molecules/card/AirmeetCard/utils';
import camelCase from 'lodash/camelCase';
import intersectionBy from 'lodash/intersectionBy';
import isEmpty from 'lodash/isEmpty';
import mapKeys from 'lodash/mapKeys';

export const getTimeInDaysHoursMinSec = (delta) => {
    // calculate (and subtract) whole days
    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let seconds = parseInt(delta % 60);

    return {
        days,
        hours,
        minutes,
        seconds,
    };
};

export const checkMultiDate = ({ startDate, endDate }) => {
    let isMultiDate = false;

    var startDateValue = new Date(startDate);
    var endDateValue = new Date(endDate);

    if (
        startDateValue.getFullYear() !== endDateValue.getFullYear() ||
        startDateValue.getMonth() !== endDateValue.getMonth() ||
        startDateValue.getDate() !== endDateValue.getDate()
    ) {
        isMultiDate = true;
    }
    return isMultiDate;
};
export const getOptimizedVideoUrl = async (
    originalVideoUrl = '',
    ignoreCompressed = false
) => {
    if (ignoreCompressed) {
        return originalVideoUrl;
    }
    const compressedVideoUrl = originalVideoUrl.replace(
        '/original/',
        '/compressed/'
    );
    const isCompressedUrlValid = await isValidURL(compressedVideoUrl);
    return isCompressedUrlValid ? compressedVideoUrl : originalVideoUrl;
};

export const isValidURL = (url) => {
    return fetch(url, { method: 'HEAD' })
        .then((res) => res.status === 200)
        .catch((err) => {
            return false;
        });
};

export const getLabel = (str, group = '') => {
    let key = group ? `${group}_${str}` : str;
    return key.split(/\W/).join('_').toUpperCase();
};

// `/airmeets/${airmeetId}/sessions` => 'sessions'
export const getLastSegmentInUrlPath = (fullPathname = '') =>
    fullPathname.substring(fullPathname.lastIndexOf('/') + 1);

// { airmeet_started_count: 1 } => { airmeetStartedCount: 1}
export const convertToCamelCaseObject = (object) => {
    if (!isEmpty(object))
        return mapKeys(object, (value, key) => camelCase(key));
};

export const getChannelLiveStreamingUrl = (channelName, scale = false) => {
    return `${
        scale
            ? process.env.REACT_APP_RTMP_SERVER_SCALE_URL
            : process.env.REACT_APP_RTMP_SERVER_URL
    }/${channelName}`;
};

export const commonKeysInObjects = (object_1, object_2) => {
    const array_1 = Object.keys(object_1);
    const array_2 = Object.keys(object_2);
    const commonKeys = intersectionBy(array_1, array_2);
    return commonKeys;
};

export const getAddToCalendarData = ({
    name,
    startTime,
    endTime,
    timezone,
    description,
    location,
}) => {
    const startDate = getDateStr({
        start_time: startTime,
        timezone,
        format: 'YYYY-MM-DD',
    });
    const endDate = getDateStr({
        end_time: endTime,
        timezone,
        format: 'YYYY-MM-DD',
    });
    const startTimeVal = getDateStr({
        start_time: startTime,
        timezone,
        format: 'HH:mm',
    });
    const endTimeVal = getDateStr({
        end_time: endTime,
        timezone,
        format: 'HH:mm',
    });
    return {
        name,
        startDate: startDate,
        endDate: endDate,
        startTime: startTimeVal,
        endTime: endTimeVal,
        location,
        timeZone: timezone,
        description,
        options: `'Apple','Google','Microsoft365','Outlook.com','Yahoo','iCal',`,
        trigger: 'click',
        inline: true,
        listStyle: 'dropdown-static',
    };
};

export const isCallable = (cb) => typeof cb === 'function';
export const getTransformedData = (dataSource, dataFormat) => {
    const result = {};
    for (const key in dataSource) {
        if (dataSource.hasOwnProperty(key)) {
            if (dataFormat[key]) {
                result[dataFormat[key]] = dataSource[key];
            } else {
                result[key] = dataSource[key];
            }
        }
    }
    return result;
};
export const getFormattedTime = (time) => {
    //  prefix zero with time to have 2 digit time always
    return time < 10 && time !== '00' ? `0${time}` : time;
};
const pad = (num) => ('0' + num).slice(-2);
export const sToHhMmSsString = (timeInS) => {
    let minutes = Math.floor(timeInS / 60);
    timeInS = Math.floor(timeInS % 60);
    const hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    if (hours < 1) {
        return `${pad(minutes)}:${pad(timeInS)}`;
    }
    return `${pad(hours)}:${pad(minutes)}:${pad(timeInS)}`;
};
