export const SUMMARY_TAB = 'summary';
export const SCHEDULE_TAB = 'schedule';
export const SESSIONS_TAB = 'sessions';
export const SESSION_SETTINGS_TAB = 'session-settings';
export const EVENT_SPACES_TAB = 'event-spaces';
export const EVENT_SPACE_SETTINGS_TAB = 'event-space-settings';
export const SPEAKERS_TAB = 'speakers';
export const HOSTS_TAB = 'hosts';
export const RECORDINGS_TAB = 'recordings';
export const CLIPS_TAB = 'clips';
export const REGISTRATIONS_TAB = 'registrations';
export const TICKETS_TAB = 'tickets';
export const ACCESS_RULES_TAB = 'rules'; //TODO: Deprecate ACCESS_RULES_TAB when old dashboard is removed
export const PEOPLE_TAB = 'people';
export const REG_FORM_TAB = 'reg-form';
export const PARTICIPANTS_TAB = 'participants';
export const EXHIBITORS_TAB = 'exhibitors';
export const EVENT_ENTRY_TAB = 'event-entry';
export const STAGE_BACKDROP_TAB = 'backdrop';
export const STAGE_WAITING_SCREEN_TAB = 'waitingScreen';
export const STAGE_TAB = 'stage';
export const PRE_RECORDED_VIDEO_TAB = 'video';
export const SESSION_RESOURCES_TAB = 'session-resources';
export const LIVE_STREAM_TAB = 'live-stream';
export const ADVANCE_SESSION_SETTINGS = 'advance-session-settings';
export const ADVANCE_EVENT_SPACE_SETTINGS = 'advance-event-space-settings';
export const ACCESSIBILITY_TAB = 'accessibility-setting';
export const SPONSORS_TAB = 'sponsors';
export const EMAILS_TAB = 'emails';
export const BRANDING_TAB = 'branding';
export const EVENT_BRANDING_TAB = 'event-branding';
export const LOUNGE_TAB = 'lounge';
export const BOOTHS_TAB = 'booths';
export const VENUE_SETTINGS_TAB = 'venue';
export const RECEPTION_TAB = 'reception';
export const INTEGRATIONS_TAB = 'integrations';
export const ATTENDEE_ENGAGEMENT_TAB = 'attendee-engagement';
export const LEADERBOARD_TAB = 'leaderboard';
export const INTERESTS_TAB = 'interests';
export const PRE_EVENT_ENTRY_TAB = 'pre-event-entry';
export const POST_EVENT_ENTRY_TAB = 'post-event-entry';
export const STREAM_INTO_AIRMEET_TAB = 'stream-into-airmeet';
export const LANDING_PAGE_TAB = 'landing-page';
export const RESOURCES_TAB = 'resources';
export const CREATE_CONTENT_HUB = 'create-content-hub';
export const WORKFLOWS_TAB = 'workflows';
export const AUTOMATED_WORKFLOWS_TAB = 'automated-workflows';
export const CLOSED_CAPTIONS_TAB = 'closed-captions';
export const TRACKING_URL_TAB = 'tracking-url';
export const TRANSCRIPTS_TAB = 'transcripts';

// Audience tabs
export const AUDIENCE_TAB = 'audience';
export const AUDIENCE_SUMMARY_TAB = 'summary';
export const AUDIENCE_USERS_TAB = 'users';
export const AUDIENCE_SEGMENTS_TAB = 'segments';

// Analytics tabs
export const ANALYTICS_TAB = 'analytics';
export const ANALYTICS_REPORTS_LIBRARY_TAB = 'reports-library';
export const ANALYTICS_REPORTS_DOWNLOADS_TAB = 'reports-downloads';
export const ANALYTICS_SUMMARY_TAB = 'analytics-summary';
export const ANALYTICS_REGISTRATIONS_TAB = 'registrations';
export const ANALYTICS_ENGAGEMENT_TAB = 'engagement';
export const ANALYTICS_POST_EVENT_TAB = 'post-event';

// Settings tabs
export const SETTINGS_TAB = 'settings';
export const EVENT_EXPERIENCE_SETTINGS_TAB = 'event-experience-settings';
export const PRE_POST_EVENT_ENTRY_SETTINGS_TAB =
    'pre-post-event-entry-settings';
export const RECORDINGS_SETTINGS_TAB = 'recordings-settings';
export const STREAMING_RESOLUTION = 'streaming-resolution';
export const MISCELLANEOUS_SETTINGS_TAB = 'miscellaneous-settings';

export const DEFAULT_EVENT_DASHBOARD_TAB = SUMMARY_TAB;
export const HOSTS_COHOSTS_TAB = 'host-cohosts';
