/**************************************************************
 * @fileOverview Metered Pricing Reducers (WIP)
 **************************************************************/

import { EVENT_TYPES } from 'utils/constants/airmeet';
import {
    CANCEL_ALERT,
    FETCH_REGISTRANT_ALERT_RESPONSE,
    FETCH_REGISTRANT_STRIP_INFO_RESPONSE,
    RESET_METER_DATA,
    RESET_METER_SWITCH,
} from '../actions/meteredPricing';

const initialState = {
    meterData: {},
    registrantStat: {},
    isMeterDataLoading: true,
    error: false,
};

function meteredPricing(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case FETCH_REGISTRANT_STRIP_INFO_RESPONSE:
            return {
                ...state,
                meterData: {
                    ...state.meterData,
                    ...payload,
                },
                isMeterDataLoading: false,
                error: error === undefined ? false : error,
            };
        case FETCH_REGISTRANT_ALERT_RESPONSE:
            return {
                ...state,
                registrantStat: payload,
            };
        case CANCEL_ALERT:
            let newRegistrantStat = null;
            if (payload?.alertType === EVENT_TYPES.MEETUP) {
                state.registrantStat.meetups.shift();
                newRegistrantStat = {
                    ...state.registrantStat,
                };
            } else {
                newRegistrantStat = {
                    ...state.registrantStat,
                    conference: {},
                };
            }
            return {
                ...state,
                registrantStat: newRegistrantStat,
            };
        case RESET_METER_SWITCH:
            return {
                ...state,
                meterData: {
                    ...state.meterData,
                    metered_pricing_status: payload?.meterStatus,
                    metered_pricing_enabled: payload?.meterEnabled,
                },
            };
        case RESET_METER_DATA:
            return {
                ...state,
                meterData: initialState.meterData,
                isMeterDataLoading: initialState.isMeterDataLoading,
                error: initialState.error,
            };
        default:
            return state;
    }
}

export default meteredPricing;
