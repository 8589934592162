import { API_SERVICE } from 'utils/apiService';
import { CALL_API } from 'store/api';

export const FETCH_ATTENDEE_ADDONS_REQUEST = 'FETCH_ATTENDEE_ADDONS_REQUEST';
export const FETCH_ATTENDEE_ADDONS_RESPONSE = 'FETCH_ATTENDEE_ADDONS_RESPONSE';

export const fetchAttendeeAddons = (communityId) => {
    return {
        [CALL_API]: {
            types: [
                FETCH_ATTENDEE_ADDONS_REQUEST,
                FETCH_ATTENDEE_ADDONS_RESPONSE,
            ],
            endpoint: `/attendee-addon/stats?communityId=${communityId}`,
            type: 'json',
            methog: 'GET',
        },
    };
};
