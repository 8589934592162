const WEBSITE_DEFAULT_RTM_CHANNEL = 'website-airmeet';

const HandRaiseMessageType = {
    request: 'hand-raised-request',
    withdraw: 'hand-raised-withdraw',
    accepted: 'hand-raised-accepted',
    rejected: 'hand-raised-rejected',
    active: 'hand-raised-active',
    completed: 'hand-raised-completed',
    leaveRequest: 'hand-raised-leave-request',
};
const RtmClientMessages = {
    speakerJoined: 'speaker-joined',
    inviteToStage: 'invite-to-stage-invite',
    inviteToStageAccepted: 'invite-to-stage-accepted',
    inviteToStageJoined: 'invite-to-stage-joined',
    inviteToStageRejected: 'invite-to-stage-rejected',
    inviteToStageHostCancelled: 'invite-to-stage-host-cancelled',
    inviteToStageHostRemoved: 'invite-to-stage-host-removed',
    inviteToStageNotAllowed: 'invite-to-stage-not-allowed',
    inviteToStagePublishFail: 'invite-to-stage-publish-fail',
    inviteToStagePublishFailAttendee: 'invite-to-stage-publish-fail-attendee',
    inviteToStageJoinedSuccess: 'invite-to-stage-joined-success',
    inviteToStagePermissionMissing: 'invite-to-stage-permission-missing',
    inviteToStageAwaitPermission: 'invite-to-stage-await-permission',
    inviteToStageClosePrompt: 'invite-to-stage-close-prompt',
    hostRequestedMuteSpeakerAudioInBroadcast:
        'host-requested-mute-speaker-audio-in-broadcast',
    hostRequestedMuteSpeakerVideoInBroadcast:
        'host-requested-mute-speaker-video-in-broadcast',
    hostRequestedStopScreenshareInBroadcast:
        'host-requested-stop-speaker-screenshare-in-broadcast',
    hostRequestedStopPublishStreamInBroadcast:
        'host-requested-stop-publish-stream-in-broadcast',
    hostAddedInvitedSpeakerInBroadcast:
        'host_added_invited_speaker_in_broadcast',
    hostRemovedInvitedSpeakerInBroadcast:
        'host_removed_invited_speaker_in_broadcast',
    muteRemoteAudioRequestTable: 'mute_remote_audio_request_tables',
    muteRemoteVideoRequestTable: 'mute_remote_video_request_tables',
    coHostUpdated: 'co-host-updated',
    coHostRemoved: 'co-host-removed',
    requestToJoinStage: 'request-to-join-stage',
    requestToJoinStageAccepted: 'request-to-join-stage-accepted',
    requestToJoinStagePermissionMissing:
        'request-to-join-stage-permission-missing',
    requestToJoinStageRequesteeJoined: 'request-to-join-stage-requestee-joined',
    requestTojoinStageAwaitingPermission:
        'request-to-join-stage-awaiting-permission',
    requestToJoinStageRejected: 'request-to-join-stage-rejected',
    requestToJoinStageNotAllowed: 'request-to-join-stage-not-allowed',
    requestToJoinStageCancelled: 'request-to-join-stage-cancelled',
    requestToJoinStageRequesteeOnSameSession:
        'request-to-join-stage-requestee-on-same-session',
    moveScreenShare: 'move-backstage-stage-screenshare',
};
const Events = {
    beforeUnload: 'before-unload',
    rtcLiveStreamRemoved: 'rtc-live-stream-removed',
    rtcLiveStreamAdded: 'rtc-live-stream-added',
    rtcLiveRemoteStreamAdded: 'rtc-live-remote-stream-added',
    rtcLiveStreamPublishFail: 'rtc-stream-publish-fail',
    rtcVideoStreamRemoved: 'rtc-video-stream-removed',
    rtcVideoLocalStreamPublished: 'rtc-video-local-stream-published',
    rtcVideoActiveSpeaker: 'rtc-video-active-speaker',
    rtcLiveNetwokrQualityUpdated: 'rtc-live-network-quality-updated',
    rtcVideoStreamAdded: 'rtc-video-stream-added',
    rtcLiveRemoteVolumeIndicator: 'rtc-video-volume-indicator',
    rtcBroadCastStreamNotAllowedAutoPlay:
        'rtc-broadcast-stream-not-allowed-auto-play',
    rtcBrodCastLocalStreamVideoMute: 'rtc-bordcast-local-stream-video-mute',
    rtcBrodCastLocalStreamVideoUnmute: 'rtc-bordcast-local-stream-video-unmute',
    rtcBrodCastLocalStreamAudioMute: 'rtc-bordcast-local-stream-audio-mute',
    rtcBrodCastLocalStreamAudioUnmute: 'rtc-bordcast-local-stream-audio-unmute',
    rtcLiveStreamPublished: 'rtc-live-stream-published',
    rtcLiveStreamUnpublished: 'rtc-live-stream-unpublished',
    rtcLiveConnectionStateChange: 'rtc-bordcast-connection-state-change',
    rtcLiveStreamSubscribeFailed: 'rtc-live-stream-subscribe-failed',
    speakerJoined: 'speaker-joined',
    inviteToStage: 'invite-to-stage-invite',
    inviteToStageAccepted: 'invite-to-stage-accepted',
    inviteToStageJoined: 'invite-to-stage-joined',
    inviteToStageRejected: 'invite-to-stage-rejected',
    inviteToStageHostCancelled: 'invite-to-stage-host-cancelled',
    inviteToStageHostRemoved: 'invite-to-stage-host-removed',
    inviteToStageNotAllowed: 'invite-to-stage-not-allowed',
    inviteToStagePublishFail: 'invite-to-stage-publish-fail',
    inviteToStagePublishFailAttendee: 'invite-to-stage-publish-fail-attendee',
    inviteToStageJoinedSuccess: 'invite-to-stage-joined-success',
    inviteToStagePermissionMissing: 'invite-to-stage-permission-missing',
    inviteToStageAwaitPermission: 'invite-to-stage-await-permission',
    inviteToStageClosePrompt: 'invite-to-stage-close-prompt',
    hostRequestedMuteSpeakerAudioInBroadcast:
        'host-requested-mute-speaker-audio-in-broadcast',
    hostRequestedMuteSpeakerVideoInBroadcast:
        'host-requested-mute-speaker-video-in-broadcast',
    hostRequestedStopScreenshareInBroadcast:
        'host-requested-stop-speaker-screenshare-in-broadcast',
    hostRequestedStopPublishStreamInBroadcast:
        'host-requested-stop-publish-stream-in-broadcast',
    hostAddedInvitedSpeakerInBroadcast:
        'host_added_invited_speaker_in_broadcast',
    hostRemovedInvitedSpeakerInBroadcast:
        'host_removed_invited_speaker_in_broadcast',
    muteRemoteAudioRequestTable: 'mute_remote_audio_request_tables',
    muteRemoteVideoRequestTable: 'mute_remote_video_request_tables',
    rtcLiveStreamMediaSourceFailed: 'media-stream-source-failed',
    coHostUpdated: 'co-host-updated',
    coHostRemoved: 'co-host-removed',
    localStreamReconnectEnd: 'local-stream-reconnect-end',
    localStreamReconnectStart: 'local-stream-reconnect-start',
    syncPollAnswer: 'sync-poll-answer',
    streamAddedScreen: 'stream-added-screen',
    streamAddedCustomMedia: 'stream-added-custom-media',
    streamRemoveScreen: 'stream-remove-screen',
    streamRemoveCustomMedia: 'stream-remove-custom-media',
    remoteStreamFirstAudioFrameDecode: 'remote-stream-first-audio-frame-decode',
    remoteStreamFirstVideoFrameDecode: 'remote-stream-first-video-frame-decode',
    onMuteVideo: 'onMuteVideo',
    onUnmuteVideo: 'onUnmuteVideo',
    onMuteAudio: 'onMuteAudio',
    onUnmuteAudio: 'onUnmuteAudio',
    localAudioCheck: 'local-audio-check',
    activeSpeaker: 'active-speaker',
    beforeSwitchStageSdkVersion: 'before_switch_stage_sdk_version',
    stageSdkVersionUpdated: 'stage_sdk_version_updated',
    requestToJoinStage: 'request-to-join-stage',
    requestToJoinStageAccepted: 'request-to-join-stage-accepted',
    requestToJoinStagePermissionMissing:
        'request-to-join-stage-permission-missing',
    requestToJoinStageRequesteeJoined: 'request-to-join-stage-requestee-joined',
    requestTojoinStageAwaitingPermission:
        'request-to-join-stage-awaiting-permission',
    requestToJoinStageRejected: 'request-to-join-stage-rejected',
    requestToJoinStageNotAllowed: 'request-to-join-stage-not-allowed',
    requestToJoinStageCancelled: 'request-to-join-stage-cancelled',
    requestToJoinStageRequesteeOnSameSession:
        'request-to-join-stage-requestee-on-same-session',
    newActiveSpeaker: 'new-active-speaker',
    inactiveSpeaker: 'inactive-speaker',
    videoUnmuteFailed: 'video-unmute-failed',
    audioUnmuteFailed: 'audio-unmute-failed',
    permissionsRevoked: 'permissions-revoked',
    moveScreenShare: 'move-backstage-screenshare',
    enableAVControlsPB: 'enable-audio-video-persistent-backstage',
    conferenceVolumeIndicator: 'users-speaking-status-conference',
};

const ErrorEvents = {
    RTCTokenError: 'token-error',
};

const CONNECTION_STATUS_DISCONNECTED = 'DISCONNECTED';
const CONNECTION_STATUS_CONNECTING = 'CONNECTING';
const CONNECTION_STATUS_CONNECTED = 'CONNECTED';
const CONNECTION_STATUS_RECONNECTING = 'RECONNECTING';
const CONNECTION_STATUS_ABORTED = 'ABORTED';

export {
    WEBSITE_DEFAULT_RTM_CHANNEL,
    HandRaiseMessageType,
    RtmClientMessages,
    Events,
    ErrorEvents,
    CONNECTION_STATUS_DISCONNECTED,
    CONNECTION_STATUS_CONNECTING,
    CONNECTION_STATUS_CONNECTED,
    CONNECTION_STATUS_RECONNECTING,
    CONNECTION_STATUS_ABORTED,
};
