import * as Label from '@radix-ui/react-label';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import { Text } from 'foundations/themeV2/text';
import useTheme from 'hooks/useTheme';
import React from 'react';
import RcSelect, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import { emptyObject } from 'utils/constants/common';

type SizeEnum = 'xsmall' | 'small' | 'large';

interface SelectProps {
    label?: string;
    errorMsg?: string;
    disabled?: boolean;
    loading?: boolean;
    styles?: object;
    hideIndicatorSeparator?: boolean;
    size?: SizeEnum;
    components?: Object;
    wrapperStyles?: object;
    roundCorners?: boolean;
    isAsync?: boolean;
}

const Select: React.FC<SelectProps> = ({
    label,
    errorMsg,
    disabled = false,
    loading = false,
    styles = emptyObject,
    hideIndicatorSeparator = true,
    size = 'large',
    components = {},
    wrapperStyles = emptyObject,
    roundCorners = false,
    isAsync = false,
    ...rest
}) => {
    const { theme } = useTheme();

    const props = {
        isDisabled: disabled,
        isLoading: loading,
        size,
        styles: {
            ...getDefaultStyles({
                theme,
                isError: !!errorMsg,
                size,
                roundCorners,
            }),
            ...(styles || {}),
        },
        components: hideIndicatorSeparator
            ? {
                  IndicatorSeparator: () => null,
                  DropdownIndicator,
                  Option,
                  ...(components || {}),
              }
            : { ...(components || {}) },
        ...rest,
    };

    return (
        <>
            {label && (
                <InputLabel>
                    <Text
                        variant={size === 'large' ? 'body2' : 'caption'}
                        color={
                            size === 'xsmall'
                                ? 'text.default.secondary'
                                : 'text.default.primary'
                        }
                    >
                        {label}
                    </Text>
                </InputLabel>
            )}
            <FlexDiv
                flexDirection='column'
                alignItems='stretch'
                flexWrap='wrap'
                position='relative'
                minWidth={0}
                {...wrapperStyles}
            >
                {isAsync ? <AsyncSelect {...props} /> : <RcSelect {...props} />}
                <Info>
                    {errorMsg && (
                        <ErrorMsg>
                            <Text variant='caption' color='sem.error'>
                                {errorMsg}
                            </Text>
                        </ErrorMsg>
                    )}
                </Info>
            </FlexDiv>
        </>
    );
};

export default Select;

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <StyledIcon
                icon={props?.selectProps.icon || 'chevron_down'}
                height={props?.selectProps.size === 'small' ? '14px' : '18px'}
                width={props?.selectProps.size === 'small' ? '14px' : '18px'}
                isDisabled={props?.isDisabled}
                isFocused={props?.isFocused}
                size={props?.selectProps.size}
                isOpen={props?.selectProps.menuIsOpen}
            />
        </components.DropdownIndicator>
    );
};

const Option = (props) => {
    const { isSelected, data } = props || {};
    const { label } = data || {};

    return (
        <components.Option {...props}>
            <FlexDiv alignItems={'center'}>
                <OptionLabel>{label}</OptionLabel>
                {isSelected ? (
                    <StyledCheck
                        icon='checkmark'
                        height='20px'
                        width='20px'
                        minWidth='20px'
                    />
                ) : null}
            </FlexDiv>
        </components.Option>
    );
};

const getDefaultStyles = ({ theme, isError, size, roundCorners }) => ({
    control: (styles, state) => ({
        ...styles,
        minHeight: size === 'xsmall' ? '0' : size === 'small' ? '28px' : '40px',
        marginLeft: size === 'xsmall' ? theme.space.x1 : '0',
        backgroundColor:
            size === 'xsmall'
                ? state.isFocused && !state?.isDisabled
                    ? theme.colors.fill.other1
                    : 'transparent'
                : state.isFocused && !state?.isDisabled
                ? theme.colors.fill.default1
                : theme.colors.fill.default2,
        border:
            size === 'xsmall'
                ? 'none'
                : `1px solid ${
                      state?.isDisabled
                          ? theme.colors.border.default2
                          : isError
                          ? theme.colors.sem.error
                          : state?.isFocused
                          ? theme.colors.border.other1
                          : theme.colors.border.default1
                  }`,
        outline:
            state?.isFocused && size !== 'xsmall'
                ? `4px solid ${theme.colors.border.default1}`
                : '',
        color: state?.isDisabled
            ? theme.colors.text.default.disabled
            : theme.colors.text.default.primary,
        borderRadius: roundCorners
            ? theme.radii.x4
            : size === 'xsmall'
            ? '0'
            : theme.radii.default,
        fontFamily: size === 'xsmall' ? theme.fonts.bold : theme.fonts.regular,
        fontSize:
            size === 'xsmall' || size === 'small'
                ? theme.fontSizes.x1
                : theme.fontSizes.x2,
        lineHeight:
            size === 'small' ? theme.lineHeights.x1 : theme.lineHeights.x2,
        boxShadow: 'none',
        '&:hover': {
            border:
                size === 'xsmall'
                    ? 'none'
                    : `1px solid ${
                          isError
                              ? theme.colors.sem.error
                              : theme.colors.border.other1
                      }`,
            backgroundColor:
                size === 'xsmall' ? 'transparent' : theme.colors.fill.default1,
            textDecoration: size === 'xsmall' ? 'underline' : 'none',
        },
        ...(state?.isDisabled
            ? {
                  cursor: 'default',
                  pointerEvents: 'none',
              }
            : {}),
        ...(state?.isFocused && size === 'xsmall'
            ? {
                  color: theme.colors.text.other.primary,
                  '&:hover': {
                      color: state?.isDisabled
                          ? theme.colors.text.default.disabled
                          : theme.colors.text.other.primary,
                  },
              }
            : {}),
        ...(isError
            ? {
                  boxShadow: `0 0 0 4px ${theme.colors.fill.default2}`,
              }
            : {}),
    }),
    container: (styles) => {
        return {
            ...styles,
            maxWidth: '100%',
        };
    },
    valueContainer: (styles) => {
        return {
            ...styles,
            paddingLeft:
                size === 'xsmall'
                    ? '0'
                    : size === 'small'
                    ? theme.space.x3
                    : theme.space.x4,
            padding: size === 'xsmall' ? '0' : '',
        };
    },
    singleValue: (styles, state) => ({
        ...styles,
        position: size === 'xsmall' ? 'relative' : 'absolute',
        transform: size === 'xsmall' ? 'none' : 'translateY(-50%)',
        maxWidth: size === 'xsmall' ? '100%' : 'calc(100% - 8px)',
        color: isError
            ? theme.colors.sem.error
            : state?.isDisabled
            ? theme.colors.text.default.disabled
            : size === 'xsmall'
            ? 'inherit'
            : theme.colors.text.default.primary,
    }),
    input: (styles, state) => ({
        ...styles,
        color: isError
            ? theme.colors.sem.error
            : state?.isDisabled
            ? theme.colors.text.default.disabled
            : size === 'xsmall'
            ? 'inherit'
            : theme.colors.text.default.primary,
    }),
    placeholder: (styles, state) => {
        return {
            ...styles,
            color: state?.isDisabled
                ? theme.colors.text.default.disabled
                : theme.colors.text.default.secondary,
        };
    },
    dropdownIndicator: (styles) => ({
        ...styles,
        padding:
            size === 'xsmall'
                ? '0'
                : size === 'small'
                ? theme.space.x1
                : theme.space.x2,
        paddingRight: size === 'xsmall' ? '0' : '10px',
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: theme.colors.fill.default1,
        border: `1px solid ${theme.colors.border.default1}`,
        borderRadius: theme.radii.x3,
        boxShadow: theme.shadows.x1,
        zIndex: '5',
    }),
    menuList: (styles) => ({
        ...styles,
        maxHeight: '250px',
        color: theme.colors.text.default.primary,
        paddingTop: '12px',
        paddingBottom: '12px',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isFocused ? theme.colors.fill.other3 : 'transparent',
        color: isDisabled
            ? theme.colors.text.default.disabled
            : theme.colors.text.default.primary,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        fontFamily: theme.fonts.regular,
        fontSize: size === 'small' ? theme.fontSizes.x1 : theme.fontSizes.x2,
        lineHeight:
            size === 'small' ? theme.lineHeights.x1 : theme.lineHeights.x2,
        padding:
            size === 'small'
                ? `${theme.space.x2} ${theme.space.x3}`
                : `${theme.space.x3} ${theme.space.x4}`,
    }),
    multiValue: (styles) => ({
        ...styles,
        borderRadius: theme.radii.default,
        backgroundColor: theme.colors.brandDefault,
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        paddingLeft: '12px',
        fontFamily: theme.fonts.regular,
        fontSize: theme.fontSizes.x2,
        lineHeight: theme.lineHeights.x2,
        color: theme.colors.text.other.primary,
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: theme.colors.text.other.primary,
        ':hover': {
            backgroundColor: 'transparent',
            cursor: 'pointer',
        },
        svg: {
            height: theme.fontSizes.x3,
            width: theme.fontSizes.x3,
        },
    }),
});

const StyledIcon = styled(Icon)`
    fill: ${({ theme, isDisabled, isFocused, size, isOpen }) =>
        isDisabled
            ? theme.colors.text.default.disabled
            : isFocused && size === 'xsmall'
            ? theme.colors.text.other.primary
            : theme.colors.text.default.primary};
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '0')};
    transition: transform 0.3s ease-in-out;
`;

const StyledCheck = styled(Icon)`
    fill: ${({ theme }) => theme.colors.text.default.secondary};
    align-self: flex-end;
`;

const OptionLabel = styled.span`
    flex: 1;
`;

const InputLabel = styled(Label.Root)`
    display: block;
    margin-bottom: ${({ theme }) => theme.space.x1};
`;

const Info = styled(FlexDiv)`
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`;

const ErrorMsg = styled.div`
    display: inline-flex;
    padding: 0;
    margin-top: ${({ theme }) => theme.space.x1};
    margin-right: ${({ theme }) => theme.space.x3};
`;
