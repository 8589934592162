import { API_SERVICE } from 'utils/apiService';
import { CALL_API } from '../api';

const OAUTH_SINGNUP_TOKEN_REQUEST = 'OAUTH_SINGNUP_TOKEN_REQUEST';
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';

const openRoutes = ['/oauth/linkedin', '/', '/event/session'];

export function storeLastURL(path) {
    let cPath = path ? path : window.location.pathname;
    if (!openRoutes.includes(cPath)) {
        localStorage.setItem('last_location', cPath);
    }
}

export function clearLastURL() {
    localStorage.removeItem('last_location');
}

export function showError() {
    return {
        type: 'Error',
        payload: {
            msg: 'There were some error',
            error: true,
        },
    };
}

/**
 * Fetch the latest airmeet token using an external token as auth
 */
export function fetchAuthUserDetail() {
    return {
        [CALL_API]: {
            types: [LOGIN_REQUEST, USER_LOGIN_SUCCESS],
            endpoint: `/user`,
            type: 'json',
            method: 'GET',
        },
    };
}
export function fetchAuthNewUserRegionDetail() {
    return {
        [CALL_API]: {
            types: [
                'PATCH_USER_DETAILS_REQUEST',
                'PATCH_USER_DETAILS_RESPONSE',
            ],
            endpoint: '/user',
            type: 'json',
            method: 'PATCH',
        },
    };
}

export function loginWith(userData) {
    localStorage.setItem('user', JSON.stringify(userData));
    return {
        type: 'Login',
        payload: {
            userData: userData,
            error: false,
        },
    };
}

export function onLogout() {
    return {
        type: 'Login',
        payload: null,
    };
}

export function getUsersDetail(userIds) {
    return {
        [CALL_API]: {
            types: ['GET_USERS_DETAIL_REQUEST', 'GET_USERS_DETAIL_RESPONSE'],
            endpoint: `/users?userIds=${userIds}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function putUserDetails({ guest = false, ...details }) {
    return {
        [CALL_API]: {
            types: ['PUT_USER_DETAILS', 'UPDATE_USER_DETAILS'],
            endpoint: '/users',
            type: 'json',
            method: 'PUT',
            body: details,
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}

export function putEventUserDetails(
    airmeetId,
    userId,
    { overrideRegionUrl, ...params }
) {
    const req = {
        types: ['PUT_EVENT_USER_DETAILS', 'UPDATE_EVENT_USER_DETAILS'],
        endpoint: '/airmeet/user',
        type: 'json',
        method: 'PUT',
        body: { airmeetId, userId, ...params },
    };

    if (overrideRegionUrl) {
        req.service = API_SERVICE.CAS_ZEUS;
    }

    return {
        [CALL_API]: req,
    };
}
export function getUserAirmeets(userAuthToken) {
    return {
        [CALL_API]: {
            types: [
                'GET_USERS_AIRMEETS_REQUEST',
                'GET_USERS_AIRMEETS_RESPONSE',
            ],
            endpoint: `/user/events`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}
export function putUserProfileDetails({
    firstName,
    lastName,
    designation,
    company,
    city,
    country,
    profile_img,
    bio,
    last_active_region,
    metadata,
    phoneNumber,
    isdCode,
}) {
    return {
        [CALL_API]: {
            types: ['PUT_USER_DETAILS_REQUEST', 'PUT_USERS_DETAILS_RESPONSE'],
            endpoint: '/users',
            type: 'json',
            body: {
                firstName,
                lastName,
                company,
                city,
                country,
                designation,
                profile_img,
                bio: bio,
                last_active_region,
                metadata: metadata ? metadata : {},
                phoneNumber,
                isdCode,
            },
            method: 'PUT',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}

export function addAttendee(payload, airmeetId) {
    return {
        [CALL_API]: {
            types: ['ADD_ATTENDEE_REQUEST', 'ADD_ATTENDEE_RESPONSE'],
            endpoint: `/airmeet/${airmeetId}/addAttendee`,
            type: 'json',
            method: 'POST',
            body: payload,
            service: API_SERVICE.ZEUS,
        },
    };
}

export function updateAttendeeDetails(payload, airmeetId) {
    return {
        [CALL_API]: {
            types: ['UPDATE_ATTENDEE_REQUEST', 'UPDATE_ATTENDEE_RESPONSE'],
            endpoint: `/airmeet/${airmeetId}/updateAttendee`,
            type: 'json',
            method: 'PUT',
            body: payload,
            service: API_SERVICE.ZEUS,
        },
    };
}

export function blockUnblockAttendee(payload, airmeetId, action) {
    return {
        [CALL_API]: {
            types: ['UPDATE_ATTENDEE_REQUEST', 'UPDATE_ATTENDEE_RESPONSE'],
            endpoint: `/airmeet/${airmeetId}/user/${action}`,
            type: 'json',
            method: 'PUT',
            body: payload,
            service: API_SERVICE.ZEUS,
        },
    };
}
export function deleteAttendee(payload, airmeetId) {
    return {
        [CALL_API]: {
            types: ['DELETE_ATTENDEE_REQUEST', 'DELETE_ATTENDEE_RESPONSE'],
            endpoint: `/airmeet/${airmeetId}/user`,
            type: 'json',
            method: 'DELETE',
            body: payload,
            service: API_SERVICE.ZEUS,
        },
    };
}
