import get from 'lodash/get';
import React from 'react';
import styled, { css } from 'styled-components';
import {
    color,
    flexbox,
    layout,
    space,
    system,
    typography,
} from 'styled-system';

const Text = React.forwardRef((props, ref) => {
    let { children, variant, color, ...rest } = props;

    let commonProps = {
        ...rest,
        color,
        ref,
        variant,
    };
    let Component = null;

    switch (variant) {
        case 'h1':
            Component = H1;
            break;
        case 'h2':
            Component = H2;
            break;
        case 'h3':
            Component = H3;
            break;
        case 'h4':
            Component = H4;
            break;
        case 'h5':
            Component = H5;
            break;

        case 'h1p':
            Component = H1p;
            break;
        case 'h2p':
            Component = H2p;
            break;
        case 'h3p':
            Component = H3p;
            break;
        case 'h4p':
            Component = H4p;
            break;
        case 'h5p':
            Component = H5p;
            break;

        case 'body1':
            Component = Body1; // aka Body/16px
            break;
        case 'body2':
            Component = Body2; // aka Body/14px
            break;

        case 'caption':
            Component = Caption; // aka Caption/12px
            break;
        case 'caption-bold':
            Component = Caption;
            break;
        case 'caption10':
            Component = Caption10; // aka Caption/10px
            break;
        case 'caption10-bold':
            Component = Caption10;
            break;
        case 'subtext2-regular':
            Component = Subtext2;
            break;
        default:
            break;
    }

    if (Component) return <Component {...commonProps}>{children}</Component>;

    return null;
});

export { Text };

const getColor = (theme, color) =>
    color && (get(theme, `colors.${color}`) || color);

export const commonStyles = css`
    text-transform: ${(props) => props.textTransform};
    word-wrap: break-word;
    color: ${({ theme, color }) =>
        getColor(theme, color) || theme.colors.text.default.primary};
    font-family: ${({ theme, variant }) => {
        switch (variant) {
            case 'body1':
            case 'body2':
            case 'caption':
            case 'caption10':
            case 'subtext2-regular':
                return theme.fonts.regular;
            default:
                return theme.fonts.bold;
        }
    }};
    ::selection {
        background: ${({ theme }) => theme.colors.brandDefault};
        color: ${({ theme }) =>
            //TODO: Remove white fallback when theme v2 is complete
            theme?.primaryButtonTextColor ||
            theme.colors.white ||
            theme.colors.text.other.primary};
    }

    a {
        color: ${({ theme }) =>
            theme?.isDashboardBrandingPage
                ? theme.colors.brandDefault
                : theme.colors.text.default.primary};
        font-family: ${({ theme }) => theme.fonts.bold};
        text-decoration: underline;
        &:hover {
            color: ${({ theme }) =>
                theme?.isDashboardBrandingPage
                    ? theme.colors.brandDefault
                    : theme.colors.text.default.primary};
            background-color: ${({ theme }) => theme.colors.fill.default2};
        }
        &:visited{
            color: ${({ theme }) => theme.colors.text.default.secondary};
        }
    }

    ${space}
    ${color}
    ${typography}
    ${flexbox}
    ${layout}
    ${system({ cursor: true })}
    ${system({ whiteSpace: true })}
`;

const H1 = styled.h1`
    ${commonStyles}
    font-size: ${({ theme }) => theme.fontSizes.x7};
    line-height: ${({ theme }) => theme.lineHeights.x7};
    @media (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSizes.x6};
        line-height: ${({ theme }) => theme.lineHeights.x6};
    }
`;

const H2 = styled.h2`
    ${commonStyles}
    font-size: ${({ theme }) => theme.fontSizes.x5};
    line-height: ${({ theme }) => theme.lineHeights.x5};
`;

const H3 = styled.h3`
    ${commonStyles}
    font-size: ${({ theme }) => theme.fontSizes.x4};
    line-height: ${({ theme }) => theme.lineHeights.x4};
`;

const H4 = styled.h4`
    ${commonStyles}
    font-size: ${({ theme }) => theme.fontSizes.x3};
    line-height: ${({ theme }) => theme.lineHeights.x3};
`;

const H5 = styled.h5`
    ${commonStyles}
    font-size: ${({ theme }) => theme.fontSizes.x2};
    line-height: ${({ theme }) => theme.lineHeights.x2};
`;

// --- Required for using heading styles as p tag for a11y ---
const H1p = styled.p`
    ${commonStyles}
    font-size: ${({ theme }) => theme.fontSizes.x7};
    line-height: ${({ theme }) => theme.lineHeights.x7};
    @media (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSizes.x6};
        line-height: ${({ theme }) => theme.lineHeights.x6};
    }
`;

const H2p = styled.p`
    ${commonStyles}
    font-size: ${({ theme }) => theme.fontSizes.x5};
    line-height: ${({ theme }) => theme.lineHeights.x5};
`;

const H3p = styled.p`
    ${commonStyles}
    font-size: ${({ theme }) => theme.fontSizes.x4};
    line-height: ${({ theme }) => theme.lineHeights.x4};
`;

const H4p = styled.p`
    ${commonStyles}
    font-size: ${({ theme }) => theme.fontSizes.x3};
    line-height: ${({ theme }) => theme.lineHeights.x3};
`;

const H5p = styled.p`
    ${commonStyles}
    font-size: ${({ theme }) => theme.fontSizes.x2};
    line-height: ${({ theme }) => theme.lineHeights.x2};
`;

const Paragraph = styled.p`
    ${commonStyles}
`;

// aka Body/16px
const Body1 = styled(Paragraph)`
    font-size: ${({ theme }) => theme.fontSizes.x3};
    line-height: ${({ theme }) => theme.lineHeights.x3};
`;

// aka Body/14px
const Body2 = styled(Paragraph)`
    font-size: ${({ theme }) => theme.fontSizes.x2};
    line-height: ${({ theme }) => theme.lineHeights.x2};
`;

// aka Caption/12px
const Caption = styled(Paragraph)`
    font-size: ${({ theme }) => theme.fontSizes.x1};
    line-height: ${({ theme }) => theme.lineHeights.x1};
`;

// aka Caption/10px
const Caption10 = styled(Paragraph)`
    font-size: ${({ theme }) => theme.fontSizes.x0};
    line-height: ${({ theme }) => theme.lineHeights.x0};
`;

const Subtext2 = styled(Paragraph)`
    font-size: ${({ theme }) => theme.fontSizes.x1};
    line-height: ${({ theme }) => theme.lineHeights.x1};
`;

Text.defaultProps = {
    variant: 'body1',
};
