import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { logger } from 'utils/logger';
import { DEFAULT_DATE_PILL, formatDuration } from '../utils';

export default function useChartFilterData({
    endpoint,
    perDayDataPath,
    topDataInfo,
    getRequest,
    timezone,
    averageKey,
}) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [selectedPill, setSelectedPill] = useState(DEFAULT_DATE_PILL?.value);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            if (getRequest) {
                const query = {};

                if (
                    Array.isArray(selectedOptions) &&
                    selectedOptions.length > 0
                ) {
                    query['ids[]'] = selectedOptions;
                }

                if (
                    selectedPill &&
                    selectedPill !== DEFAULT_DATE_PILL?.value &&
                    moment(selectedPill).isValid()
                ) {
                    query.start_time = moment
                        .tz(selectedPill, timezone)
                        .startOf('day')
                        .valueOf();
                    query.end_time = moment
                        .tz(selectedPill, timezone)
                        .endOf('day')
                        .valueOf();
                }

                const response = await getRequest({ endpoint, query });
                if ('statusCode' in response) {
                    if (response?.statusCode === 200) {
                        setData(response?.data);
                    }
                } else {
                    setData(response);
                }
            }
        } catch (e) {
            logger.error(e);
            setData([]);
        }
        setLoading(false);
    }, [getRequest, selectedOptions, endpoint, selectedPill, timezone]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const { pills, topData } = useMemo(() => {
        // pills
        let pills = [];
        const perDayData = get(data, perDayDataPath) || {};
        if (!isEmpty(perDayData)) {
            pills = [
                ...Object.keys(perDayData).map((key) => ({
                    value: key,
                    label: moment(key).format('MMM DD'),
                })),
            ];
        }

        // topData
        let topData = [];
        if (Array.isArray(topDataInfo) && topDataInfo.length > 0) {
            topData = [
                ...(topDataInfo || []).map((item) => ({
                    ...(item || {}),
                    value: (item?.key || '').includes('time')
                        ? formatDuration(get(data, item?.key))
                        : get(data, item?.key) ?? '-',
                })),
            ];
        }
        if (!isEmpty(perDayData)) {
            topData.push(
                ...Object.keys(perDayData)
                    .slice(0, 5)
                    .map((key, index) => ({
                        key: key,
                        title: `Day ${index + 1} Joiners`,
                        value: perDayData?.[key],
                    }))
            );
        }

        return {
            pills,
            topData,
        };
    }, [data, perDayDataPath, topDataInfo]);

    return {
        selectedOptions,
        setSelectedOptions,
        isDropdownVisible,
        setIsDropdownVisible,
        selectedPill,
        setSelectedPill,
        loading,
        setLoading,
        data,
        setData,
        pills,
        topData,
        average: averageKey ? get(data, averageKey) : 0,
    };
}
