// Step names
export const ORG_TYPE_SELECTION = 'ORG_TYPE_SELECTION';
export const SPECIALIZATION_SELECTION = 'SPECIALIZATION_SELECTION';
export const EVENT_TYPE_SELECTION = 'EVENT_TYPE_SELECTION';
export const CREATE_FIRST_EVENT = 'CREATE_FIRST_EVENT';
export const ORG_INPUT = 'ORG_INPUT';

export const PROGRESS_BAR_CONFIG = {
    [ORG_TYPE_SELECTION]: {
        title: 'About your organization',
        value: 38,
    },
    [SPECIALIZATION_SELECTION]: {
        title: 'About your organization',
        value: 57,
    },
    [EVENT_TYPE_SELECTION]: {
        title: 'About your events',
        value: 76,
    },
    [CREATE_FIRST_EVENT]: {
        title: 'About your next event',
        value: 95,
    },
    [ORG_INPUT]: {
        title: 'More about you',
        value: 45,
    },
};

// Unique path strings for each step
export const ORG_TYPE_PATH = 'orgtype';
export const ASSOCIATIONS_OR_COMMUNITIES_PATH = 'commtype';
export const AGENCY_PATH = 'agencytype';
export const SOFTWARE_OR_ITES_PATH = 'saas';
export const OTHERS_PATH = 'industry';
export const EVENT_TYPE_PATH = 'eventtype';
export const CREATE_FIRST_AM_PATH = 'firstam';
export const WELCOME_MODAL_PATH = 'welcomemodal';
export const ABOUT_YOURSELF = 'aboutyourself';

// Type of other input fields
export const AREA_OF_SPECIALIZATION_TYPE = 'AREA_OF_SPECIALIZATION_TYPE';
export const EVENT_TYPE = 'EVENT_TYPE';
export const ORG_SUB_TYPE = 'ORG_SUB_TYPE';

// Organization types
export const ASSOCIATION_COMMUNITY = 'Association / Community';
export const AGENCY = 'Agency';
export const SOFTWARE_IT_SERVICES = 'Software / IT Services';

export const OTHERS = 'Others';

export const OTHERS_CHAR_LIMIT = 50;

export const BUSINESS_NEEDS = [
    {
        id: '1',
        title: 'Reception',
        icon: 'icon-Reception',
        width: '58px',
        height: '56px',
    },
    {
        id: '2',
        title: 'Stage',
        icon: 'icon-Stage',
        width: '78px',
        height: '56px',
    },
    {
        id: '3',
        title: 'Social Lounge',
        icon: 'icon-Social-lounge',
        width: '84px',
        height: '31px',
    },
    {
        id: '4',
        title: 'Speed Networking',
        icon: 'icon-Speed-networking',
        width: '77px',
        height: '56px',
    },
    {
        id: '5',
        title: 'Exhibitor Booths',
        icon: 'icon-Exhibitor-booth',
        width: '56px',
        height: '56px',
    },
    {
        id: '6',
        title: 'Branding',
        icon: 'icon-Branding',
        width: '62px',
        height: '56px',
    },
    {
        id: '7',
        title: 'Sponsors',
        icon: 'icon-Sponsors',
        width: '39px',
        height: '56px',
    },
    {
        id: '8',
        title: 'Stream-In',
        icon: 'icon-Stream-in',
        width: '43px',
        height: '56px',
    },
    {
        id: '9',
        title: 'Stream-Out',
        icon: 'icon-Stream-out',
        width: '34px',
        height: '56px',
    },
];

export const ORG_TYPES_ICON_MAP = {
    [ASSOCIATION_COMMUNITY]: 'icon-People-Audience',
    [AGENCY]: 'icon-Building',
    [SOFTWARE_IT_SERVICES]: 'icon-View-Desktop',
    [OTHERS]: 'icon-Office-Apps',
};

export const EVENT_TYPES = [
    'Webinars',
    'Conferences',
    'Meetup/Networking events',
    'Job Fairs',
    'Hackathons',
    'Training/Workshops',
    'Award Ceremonies',
    'Others',
];

export const CONFERENCE_URL_PARAM = 'conference';

export const BTN_ID_MAP = {
    [ORG_TYPE_SELECTION]: 'org-type-continue-btn',
    [SPECIALIZATION_SELECTION]: 'specialization-continue-btn',
    [EVENT_TYPE_SELECTION]: 'event-type-continue-btn',
};

export const EVENT_ATTENDEE_ENTRY = 'Attend an event';
export const OTHER_RADIO_BTN = 'Other';

export const PRIMARY_GOAL = [
    {
        text: 'Host an event',
        id: 1,
    },
    {
        text: 'Explore the platform',
        id: 2,
    },
    {
        text: EVENT_ATTENDEE_ENTRY,
        id: 3,
    },
    {
        text: OTHER_RADIO_BTN,
        id: 4,
    },
];
