import { API_SERVICE } from 'utils/apiService';
import AuthService from '../../utils/authService';
import { CALL_API } from '../api';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_RESPONSE = 'USER_LOGOUT_RESPONSE';
export const USER_DATA_UPDATE = 'USER_DATA_UPDATE';
export const USER_MANAGED_COMMUNITY_UPDATE = 'USER_MANAGED_COMMUNITY_UPDATE';
export const USER_LANGUAGE_UPDATE_REQUEST = 'USER_LANGUAGE_UPDATE_REQUEST';
export const USER_LANGUAGE_UPDATE_RESPONSE = 'USER_LANGUAGE_UPDATE_RESPONSE';

export const LOCALSTORAGE_TOKEN_RESPONSE = 'LOCALSTORAGE_TOKEN_RESPONSE';
export const UPDATE_EXHIBITOR = 'UPDATE_EXHIBITOR';
export const LOCALSTORAGE_LOGGED_IN_AT = 'LOCALSTORAGE_LOGGED_IN_AT';
export const AUTH_PROCESSING = 'AUTH_PROCESSING';

export const INVITED_USER_LOGIN_SUCCESS = 'INVITED_USER_LOGIN_SUCCESS';
export const INVITED_USER_LOGIN_FAILURE = 'INVITED_USER_LOGIN_FAILURE';
export const MAGIC_LINK_REQUEST = 'MAGIC_LINK_REQUEST';
export const MAGIC_LINK_RESPONSE = 'MAGIC_LINK_RESPONSE';

export const GUEST_USER_LOGIN_REQUEST = 'GUEST_USER_LOGIN_REQUEST';
export const GUEST_USER_LOGIN_SUCCESS = 'GUEST_USER_LOGIN_SUCCESS';
export const USER_LOGIN_WITH_TOKEN = 'USER_LOGIN_WITH_TOKEN';
export const UPDATE_SERIES_MAGIC_LINK_INFO = 'UPDATE_SERIES_MAGIC_LINK_INFO';

export const SET_FIRST_TIME_SIGNUP = 'SET_FIRST_TIME_SIGNUP';
export const RESET_FIRST_TIME_SIGNUP = 'RESET_FIRST_TIME_SIGNUP';

export const USER_UPDATE_METADATA = 'USER_UPDATE_METADATA';

export function setProcessing(processing = true) {
    return {
        type: AUTH_PROCESSING,
        payload: processing,
    };
}

export function loginFromLocalStorage() {
    const cachedUser = AuthService.getCachedUser();
    if (cachedUser) {
        return {
            type: USER_LOGIN_SUCCESS,
            payload: cachedUser,
        };
    }

    return { type: USER_LOGIN_FAILURE };
}

export function loginUser(user) {
    localStorage.setItem('user', user);
    if (localStorage.getItem('user')) {
        return {
            type: USER_LOGIN_SUCCESS,
            payload: JSON.parse(user),
        };
    }
    return { type: USER_LOGIN_FAILURE };
}

export function loggedInWithToken({ token, accessCode, user }) {
    return {
        type: USER_LOGIN_WITH_TOKEN,
        payload: {
            token: token,
            user: user,
            accessCode: accessCode,
        },
    };
}
export function loggedInWithSeriesMl({ accessCode, user }) {
    return {
        type: UPDATE_SERIES_MAGIC_LINK_INFO,
        payload: {
            user: user,
            accessCode: accessCode,
        },
    };
}

export function logoutUser() {
    return {
        [CALL_API]: {
            types: [USER_LOGOUT_REQUEST, USER_LOGOUT_RESPONSE],
            endpoint: '/user/logout',
            type: 'json',
            method: 'POST',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}

export function updateUserInfo(updates) {
    return {
        type: USER_DATA_UPDATE,
        payload: updates || {},
    };
}

export function updateLoggedInAt(loggedInAt) {
    return {
        type: LOCALSTORAGE_LOGGED_IN_AT,
        payload: {
            loggedInAt: loggedInAt
                ? loggedInAt === true
                    ? Date.now()
                    : loggedInAt
                : null,
        },
    };
}

export function updateExhibitor(data) {
    return {
        type: UPDATE_EXHIBITOR,
        payload: { ...data },
    };
}

export function updateUserManagedCommunity(community = null, role) {
    return {
        type: USER_MANAGED_COMMUNITY_UPDATE,
        payload: community == null ? null : { community, userRole: role },
    };
}

export function getMagicLinkInfo({ airmeetId, code }) {
    return {
        [CALL_API]: {
            types: [MAGIC_LINK_REQUEST, MAGIC_LINK_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/direct-access-user`,
            type: 'json',
            method: 'POST',
            credentials: 'omit',
            body: {
                code,
            },
        },
    };
}

export function updateUserLanguage({ userId, airmeetId, lang }) {
    const data = {
        userId,
        metadata: { lang },
    };

    return {
        [CALL_API]: {
            types: [
                USER_LANGUAGE_UPDATE_REQUEST,
                USER_LANGUAGE_UPDATE_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/update`,
            type: 'json',
            method: 'PUT',
            body: data,
        },
    };
}
