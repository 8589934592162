import useTimezoneUtils from 'components/community/common/TimezoneSelector/useTimezoneUtils';
import { useMemo } from 'react';

export default function useTimezoneValues({ timezone }) {
    const { options } = useTimezoneUtils();

    const timeZone = useMemo(
        () =>
            (options || []).find(
                (op) =>
                    op?.value === timezone ||
                    (op?.alias || []).find((it) => it === timezone)
            )?.value,
        [options, timezone]
    );
    return timeZone;
}
