import { CALL_API } from 'store/api';
import { API_SERVICE } from 'utils/apiService';

export const FETCH_LIVE_LEADERBOARD_REQUEST = 'FETCH_LIVE_LEADERBOARD_REQUEST';
export const FETCH_LIVE_LEADERBOARD_RESPONSE =
    'FETCH_LIVE_LEADERBOARD_RESPONSE';
export const GET_LEADERBOARD_INFO = 'GET_LEADERBOARD_INFO';
export const GET_LEADERBOARD_USER_JOURNEY = 'GET_LEADERBOARD_USER_JOURNEY';
export const UPDATE_LEADERBOARD_STATUS_REQUEST =
    'UPDATE_LEADERBOARD_STATUS_REQUEST';
export const UPDATE_LEADERBOARD_STATUS_RESPONSE =
    'UPDATE_LEADERBOARD_STATUS_RESPONSE';
export const UPDATE_LEADERBOARD_STATUS_STATE =
    'UPDATE_LEADERBOARD_STATUS_STATE';
export const FETCH_LEADERBOARD_PRIZE_DETAILS_REQUEST =
    'FETCH_LEADERBOARD_PRIZE_DETAILS_REQUEST';
export const FETCH_LEADERBOARD_PRIZE_DETAILS_RESPONSE =
    'FETCH_LEADERBOARD_PRIZE_DETAILS_RESPONSE';
export const FETCH_LEADERBOARD_ACTIONS_REQUEST =
    'FETCH_LEADERBOARD_ACTIONS_REQUEST';
export const FETCH_LEADERBOARD_ACTIONS_RESPONSE =
    'FETCH_LEADERBOARD_ACTIONS_RESPONSE';
export const UPDATE_LEADERBOARD_ENABLED_STATE =
    'UPDATE_LEADERBOARD_ENABLED_STATE';
export const FETCH_LEADERBOARD_HIGHLIGHTS_REQUEST =
    'FETCH_LEADERBOARD_HIGHLIGHTS_REQUEST';
export const FETCH_LEADERBOARD_HIGHLIGHTS_RESPONSE =
    'FETCH_LEADERBOARD_HIGHLIGHTS_RESPONSE';

export function fetchLiveLeaderboard({ airmeetId, pageNum, userId }) {
    return {
        [CALL_API]: {
            types: [
                FETCH_LIVE_LEADERBOARD_REQUEST,
                FETCH_LIVE_LEADERBOARD_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/leaderboard/live?page_number=${pageNum}&uid=${userId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateLeaderboardStatus({ airmeetId, data }) {
    return {
        [CALL_API]: {
            types: [UPDATE_LEADERBOARD_STATUS_REQUEST],
            endpoint: `/airmeet/${airmeetId}/leaderboard/info`,
            body: data,
            type: 'json',
            method: 'PATCH',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateLeaderboardStatusState(data) {
    return {
        type: UPDATE_LEADERBOARD_STATUS_STATE,
        payload: data,
    };
}

export function setIsLeaderboardEnabled(data) {
    return {
        type: UPDATE_LEADERBOARD_ENABLED_STATE,
        payload: data,
    };
}

export function fetchLeaderboardPrizeDetails({ airmeetId }) {
    return {
        [CALL_API]: {
            types: [
                FETCH_LEADERBOARD_PRIZE_DETAILS_REQUEST,
                FETCH_LEADERBOARD_PRIZE_DETAILS_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/leaderboard/prize-info`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function fetchLeaderboardUserActions({ airmeetId, userId }) {
    return {
        [CALL_API]: {
            types: [
                FETCH_LEADERBOARD_ACTIONS_REQUEST,
                FETCH_LEADERBOARD_ACTIONS_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/leaderboard/user-journey/${userId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function fetchLeaderboardHighlights({ airmeetId }) {
    return {
        [CALL_API]: {
            types: [
                FETCH_LEADERBOARD_HIGHLIGHTS_REQUEST,
                FETCH_LEADERBOARD_HIGHLIGHTS_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/leaderboard/highlights`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}
