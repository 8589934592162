import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { logger } from 'utils/logger';
import LoadingOverlay from './components/LoadingOverlay';
import PostEventBarChart from './components/PostEventBarChart';
import { Card, TextSelect } from './styledComponents';
import { POST_EVENT_TIME_FILTER_OPTIONS } from './utils';
import { getTotalRegistrations } from './utils/api';

function RegistrationsCard({ logEvent, airmeetId }) {
    const [selectedOption, setSelectedOption] = useState(
        POST_EVENT_TIME_FILTER_OPTIONS[0]
    );
    const [isLoading, setIsLoading] = useState(true);
    const [apiData, setApiData] = useState({});

    const handleFilterChange = (option) => {
        logEvent &&
            logEvent('regFilterChange', { prevOption: selectedOption, option });
        setSelectedOption(option);
    };

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await getTotalRegistrations({
                airmeetId,
                sampleBy: selectedOption?.value,
            });
            if (!isEmpty(response?.data)) {
                setApiData(response?.data);
            }
        } catch (e) {
            logger.error('PostEventTab.fetchData: ', e);
        } finally {
            setIsLoading(false);
        }
    }, [airmeetId, selectedOption]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const { total = 0, chartData, chartLabels } = useMemo(() => {
        const startDate = moment().startOf(selectedOption?.value);
        const endDate = moment();
        const allData = apiData?.all_registrations || {};

        let total = 0;
        const chartData = [];
        const chartLabels = [];
        Object.keys(allData).forEach((key) => {
            if (
                key &&
                moment(key).isSameOrAfter(startDate) &&
                moment(key).isSameOrBefore(endDate)
            ) {
                const value = allData[key];
                total += value;
                chartData.push(value);
                chartLabels.push(key.replace(' 00:00:00', ''));
            }
        });

        return { total, chartData, chartLabels };
    }, [apiData, selectedOption]);

    return (
        <Card flex='1'>
            <FlexDiv alignItems='center' justifyContent='space-between'>
                <Text
                    variant='body2'
                    color='text.default.primary'
                    flex='1'
                    pr='x4'
                >
                    Total On-demand registrations
                </Text>
                <FlexDiv alignItems='center'>
                    <Text variant='caption' color='text.default.secondary'>
                        Filter by
                    </Text>
                    <TextSelect
                        options={POST_EVENT_TIME_FILTER_OPTIONS}
                        hideIndicatorSeparator={true}
                        value={selectedOption}
                        onChange={handleFilterChange}
                        hasBorder={false}
                        flex='1'
                        isSearchable={false}
                    />
                </FlexDiv>
            </FlexDiv>
            <Text variant='h2' color='text.default.primary' pb='x5'>
                {total}
            </Text>
            <PostEventBarChart
                chartLabels={chartLabels}
                chartData={chartData}
                height='14rem'
            />
            {isLoading ? <LoadingOverlay /> : null}
        </Card>
    );
}

export default RegistrationsCard;
