import queryString from 'querystring';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'utils/logger';

// Hook to detect and re-direct the user to proper domain based on custom domain settings
export default function useCustomDomainManager() {
    const airmeetId = useSelector((state) => state.lounge?.airmeet?.airmeetId);
    const customDomain = useSelector(
        (state) => state.lounge?.airmeet?.custom_domain?.domainName
    );

    useEffect(() => {
        try {
            // airmeetId check to verify if airmeet data has loaded or not
            if (airmeetId && window?.location?.replace) {
                const hostname = window?.location?.hostname;
                const pathname = window?.location?.pathname || '';
                let isEdit = false;

                try {
                    if (
                        pathname.startsWith('/e/') &&
                        pathname.endsWith('/edit')
                    ) {
                        isEdit = true;
                    }

                    const qs = window.location.search || '?';
                    const params = queryString.parse(qs.substring(1));
                    if (params?.edit === 'true') {
                        isEdit = true;
                    }
                } catch (e) {
                    logger.error(
                        'useCustomDomainManager: parse query strings',
                        e
                    );
                }

                if (
                    hostname &&
                    hostname !== '' &&
                    ((pathname.startsWith('/e/') && !isEdit) ||
                        pathname.startsWith('/event/'))
                ) {
                    // If the app is hosted on custom domain and customDomain is not defined then redirect to homepage
                    // User should not be able to access any other event on a custom domain
                    if (!customDomain || customDomain === '') {
                        if (
                            !hostname.includes('airmeet.com') &&
                            !hostname.includes('localhost')
                        ) {
                            window.location.href = '/';
                        }
                    } else {
                        // If customDomain is defined and customDomain is not the same as the domain the app is opened on then redirect to the customDomain homepage.
                        // User should access the custom domain event only on the configured domain and nowhere else.
                        const customDomainValue = customDomain
                            .replace('www.', '')
                            .replace('http://', '')
                            .replace('https://', '');
                        const hostnameValue = hostname.replace('www.', '');

                        if (customDomainValue !== hostnameValue) {
                            window.location.href = customDomain.includes('http')
                                ? customDomain
                                : `https://${customDomain}`;
                        }
                    }
                }
            }
        } catch (e) {}
    }, [airmeetId, customDomain]);
}
