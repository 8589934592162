import { getIsMeetupEventChatRHSEnabled } from 'context/LiveAirmeet';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import React from 'react';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import CustomizeEventExperience from '../widgets/CustomizeEventExperience';
import CustomizeMeetupOptions from '../widgets/CustomizeMeetupOptions';
import CustomizeNavigationLabels from '../widgets/CustomizeNavigationLabels';
import CustomizeSessionExperience from '../widgets/CustomizeSessionExperience';

export default function CustomizeSettings({
    isConference,
    live_config,
    setPromptData,
    handleChange,
    logEvent,
    isScheduleMeetingEnabled,
    isUGCEnabled,
    isCustomNavLabelsEnabled,
    custom_text,
    setDisableSave,
    status,
}) {
    const isMeetupEventChatRHSEnabled = useLiveAirmeetContext(
        getIsMeetupEventChatRHSEnabled
    );
    return isConference ? (
        <>
            <CustomizeEventExperience
                liveConfig={live_config}
                setPromptData={setPromptData}
                onChange={handleChange}
                logEvent={logEvent}
                isScheduleMeetingEnabled={isScheduleMeetingEnabled}
            />
            {isCustomNavLabelsEnabled && (
                <CustomizeNavigationLabels
                    onChange={handleChange}
                    customText={custom_text}
                    setDisableSave={setDisableSave}
                />
            )}
            {status !== AIRMEET_STATUS.FINISHED && (
                <CustomizeSessionExperience
                    liveConfig={live_config}
                    setPromptData={setPromptData}
                    onChange={handleChange}
                    logEvent={logEvent}
                />
            )}
        </>
    ) : (
        <>
            <CustomizeMeetupOptions
                liveConfig={live_config}
                setPromptData={setPromptData}
                onChange={handleChange}
                isConference={isConference}
                logEvent={logEvent}
                isUGCEnabled={isUGCEnabled}
                isSessionLevelStageSetting={!isMeetupEventChatRHSEnabled}
            />
            {!isMeetupEventChatRHSEnabled &&
                status !== AIRMEET_STATUS.FINISHED && (
                    <CustomizeSessionExperience
                        liveConfig={live_config}
                        setPromptData={setPromptData}
                        onChange={handleChange}
                        logEvent={logEvent}
                    />
                )}
        </>
    );
}
