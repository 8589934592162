import React, { useContext } from 'react';
import { AnalyticsContext } from '../AnalyticsProvider';
import HorizontalBarsCard from '../components/HorizontalBarsCard';
import { formatDuration, getCustomTooltip } from '../utils';

const getTooltipTitle = (title, bodyLines) => {
    const label = bodyLines?.[0]?.[0]?.metaData?.booth_name || '';

    return `
        <tr>
            <th style="color: #BEBCC8; border-color: transparent; border-width: 0; font-size: 10px; line-height: 1.5em;">
                ${label}
            </th>
        </tr>
    `;
};

const getTooltipBody = (bdy) => {
    const { visits, average_time_spent } = bdy?.metaData || {};

    return `
        <tr>
            <td style="color: #FCFCFC; font-size: 18px; line-height: 1em; font-weight: bold; padding-top: 20px;">
                ${visits}<br />
                <span style="font-size: 10px; line-height: 1.5em; font-weight: normal;">
                    Attendance
                </span>
            </td>
            <td style="color: #FCFCFC; font-size: 18px; line-height: 1em; font-weight: bold; padding-top: 20px; text-align-left; padding-left: 1em;">
                ${formatDuration(average_time_spent)}<br />
                <span style="font-size: 10px; line-height: 1.5em; font-weight: normal;">
                    Average Time Spent
                </span>
            </td>
        </tr>
    `;
};

function getTooltip(tooltipModel) {
    const ref = this;
    return getCustomTooltip({
        ref,
        tooltipModel,
        getTooltipTitle,
        getTooltipBody,
    });
}

export default function BoothAttendanceCard() {
    const { getRequest, timezone, boothTags, boothOptions } = useContext(
        AnalyticsContext
    );

    return (
        <HorizontalBarsCard
            getRequest={getRequest}
            timezone={timezone}
            searchTags={boothTags}
            searchOptions={boothOptions}
            title='Booth Turnout'
            type='booth'
            icon='booths'
            baseColorRGB='1,195,126'
            endpoint='booth-analytics'
            perDayDataPath='stats.attendance_per_day'
            dataKey='booths'
            chartItemLabelKey='booth_name'
            chartItemDataKey='visits'
            topDataInfo={[
                {
                    key: 'stats.total_attendance',
                    title: 'Total Booth Visitors',
                },
                {
                    key: 'stats.average_attendance',
                    title: 'Average Booth Visitors',
                    info:
                        'This figure represents the all-day or per-day average attendance within your selected booth(s).',
                },
                {
                    key: 'stats.average_time_spent',
                    title: 'Average Time Spent',
                    info:
                        'This figure represents the all-day or per-day average time spent by attendees within your selected booth(s).',
                },
            ]}
            averageKey='stats.average_attendance'
            getTooltip={getTooltip}
        />
    );
}
