import { useFullScreenContext } from 'context/FullScreen';
import { FULLSCREEN_PORTAL_ID } from 'components/general/fullScreen';
import React from 'react';
import Portal from './Portal';

function FullScreenPortal({ container, children }) {
    const { fullScreen } = useFullScreenContext();
    container = fullScreen ? FULLSCREEN_PORTAL_ID : container;
    return (
        <>
            {container ? (
                <Portal container={container}> {children}</Portal>
            ) : (
                children
            )}
        </>
    );
}

export default FullScreenPortal;
