import isEmpty from 'lodash/isEmpty';
import { getEventLandingBannerImgSrc } from 'utils/core';
import {
    DEFAULT_SERIES_LANDING_PAGE_CONFIG,
    ES_LP_TABS,
    HAPPENING_BLOCK,
    PAST_EVENT_BLOCK,
    REGISTERED_CARD,
    SERIES_HERO_IMG,
    SPEAKER_BLOCK,
    SPONSORS_BLOCK,
    UPCOMING_BLOCK,
} from './constants';
import { cdnImage } from '../../../utils/core';

export const createSpeakerMap = (events, speakers) => {
    const eventSpeakerMap = {};
    if (isEmpty(speakers) || isEmpty(events)) return eventSpeakerMap;
    events.forEach((event) => {
        if (event?.uid) {
            eventSpeakerMap[event?.uid] = [];
        }
    });

    speakers.forEach((speaker) => {
        if (
            !speaker.hidden &&
            Array.isArray(eventSpeakerMap[speaker?.airmeet_id])
        ) {
            eventSpeakerMap[speaker?.airmeet_id].push(speaker);
        }
    });
    return eventSpeakerMap;
};

export const getDefaultSeriesLandingPageConfig = ({
    eventSeries = {},
    skipExtra = false,
}) => {
    const config = {
        ...(DEFAULT_SERIES_LANDING_PAGE_CONFIG || {}),
        ...(eventSeries?.config?.landingPageBranding || {}),
    };
    if (!skipExtra) {
        config.extra = {
            heroImage: getEventLandingBannerImgSrc(
                eventSeries?.seriesBannerUrl,
                true
            ),
            seriesOrganiserName: eventSeries?.seriesOrganisedBy,
            supportEmail: eventSeries?.supportEmail,
        };
    }

    return config;
};
export const getSeriesSponsorInfo = ({ sponsorData }) => {
    if (isEmpty(sponsorData?.sponsor_tiers)) return [];

    const sponsors = [];
    sponsorData.sponsor_tiers.forEach((sponsor) => {
        if (!isEmpty(sponsor?.sponsors)) {
            sponsors.push([...sponsor?.sponsors]);
        }
    });
    return sponsors;
};
export const getVisibleSeriesSections = ({
    seriesSections = [],
    upcomingEvents,
    ongoingEvent = [],
    pastEvents,
    speakers,
    sponsorData,
    hideSeriesRegisterBtn = false,
}) => {
    const sections =
        Array.isArray(seriesSections) && seriesSections.length > 0
            ? seriesSections
            : ES_LP_TABS;
    const hiddenSections = [];
    let includeHeroImg = true;
    if (isEmpty(ongoingEvent)) {
        hiddenSections.push(HAPPENING_BLOCK);
    }
    if (isEmpty(upcomingEvents)) {
        hiddenSections.push(UPCOMING_BLOCK);
    }
    if (isEmpty(pastEvents)) {
        hiddenSections.push(PAST_EVENT_BLOCK);
    }
    if (isEmpty(speakers) || speakers.every((item) => item?.hidden)) {
        hiddenSections.push(SPEAKER_BLOCK);
    }
    if (isEmpty(sponsorData)) {
        hiddenSections.push(SPONSORS_BLOCK);
    }
    if (hideSeriesRegisterBtn) {
        hiddenSections.push(REGISTERED_CARD);
    }

    let filteredSection = sections.filter((tab) => {
        if (tab?.id === SERIES_HERO_IMG) {
            // to check if this is present inside the array
            includeHeroImg = false;
        }
        return !hiddenSections.includes(tab?.id) && !tab?.hide;
    });
    if (includeHeroImg) {
        filteredSection.unshift(ES_LP_TABS[0]);
    }
    return filteredSection;
};
export const onClickViewDetailMobile = (link) => {
    if ('Android' in window) {
        return window.Android.onEventViewDetailsCTAClicked(link);
    } else if ('webkit' in window) {
        return window.webkit.messageHandlers.onEventViewDetailsCTAClicked.postMessage(
            link
        );
    }
};
export const onClickEnterEventMobile = (link) => {
    if ('Android' in window) {
        return window.Android.onEnterEventCTAClicked(link);
    } else if ('webkit' in window) {
        return window.webkit.messageHandlers.onEnterEventCTAClicked.postMessage(
            link
        );
    }
};
export const extractCodeFromUrl = (eventMl = '') => {
    const eventUrl = new URL(eventMl);
    if (eventUrl?.searchParams) {
        return eventUrl?.searchParams?.get('code');
    }
};
export const getBrandingImage = (liveConfig) => {
    let image = cdnImage('/images/default-landing-banner-min.png');
    if (liveConfig?.landingPageBranding?.eventCarousel?.slides[0]) {
        image =
            liveConfig?.landingPageBranding?.eventCarousel?.slides[0]?.imageUrl;
    }
    return image;
};
