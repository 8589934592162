import { FlexDiv } from 'components/molecules/utils/basicComponents';
import moment from 'moment';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { logger } from 'utils/logger';
import { AnalyticsContext } from '../AnalyticsProvider';
import BarChartCard from '../components/BarChartCard';

export default function LoungeAttendanceCard() {
    const { getRequest } = useContext(AnalyticsContext);
    const [selectedPill, setSelectedPill] = useState();
    const [loading, setLoading] = useState();
    const [data, setData] = useState([]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getRequest({
                endpoint: 'attendance-analytics',
            });
            if ('statusCode' in response) {
                if (response?.statusCode === 200) {
                    setData(response?.data);
                }
            } else {
                setData(response);
            }
        } catch (e) {
            logger.error(e);
        }
        setLoading(false);
    }, [getRequest]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const { isEmptyChart, topData, chartLabels, chartData } = useMemo(() => {
        const topData = [
            {
                key: 'Total Lounge Joiners',
                count: data?.networking?.total_who_used_sl ?? '-',
            },
        ];

        const items = data?.networking?.sl_attendance || {};
        const chartData = [];
        const chartLabels = [];
        Object.keys(items).forEach((key, index) => {
            chartData.push(items[key]);
            const dateMoment = moment(key);
            const chartLabel = dateMoment.isValid()
                ? dateMoment.format('MMM DD')
                : key;
            chartLabels.push(chartLabel);

            if (index < 4) {
                topData.push({
                    key: `Day ${index + 1} Joiners`,
                    count: items[key],
                });
            }
        });

        const isEmptyChart =
            !Array.isArray(chartData) || chartData.length === 0;

        return { isEmptyChart, topData, chartData, chartLabels };
    }, [data]);

    return (
        <FlexDiv flexDirection='column' alignItems='stretch'>
            <BarChartCard
                title='Lounge Turnout'
                topData={topData}
                chartLabels={chartLabels}
                chartData={chartData}
                loading={loading}
                isEmpty={isEmptyChart}
                backgroundColor='rgb(62,165,252)'
                hideOutline={true}
                xAxisLabel='Number of people'
                types={[]}
                selectedType={selectedPill}
                setSelectedType={setSelectedPill}
                chartHeight='300px'
                maintainAspectRatio={false}
            />
        </FlexDiv>
    );
}
