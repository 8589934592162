import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import upperFirst from 'lodash/upperFirst';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { getAirmeetFormat } from 'utils/airmeet';
import { EVENT_SUB_TYPES, EVENT_TYPES } from 'utils/constants/airmeet';
import { ANALYTICS_SUMMARY_TAB } from '../../constants/tabs';
import { AnalyticsContext } from '../AnalyticsProvider';
import SpecialRolesFilterSwitch from '../components/SpecialRolesFilterSwitch';
import TopBanner from '../components/TopBanner';
import TopBarsCard from '../components/TopBarsCard';
import LoungeCard from './LoungeCard';
import PollsCard from './PollsCard/';
import TicketingCard from './TicketingCard';
import TurnoutCard from './TurnoutCard';

export default function SummaryTab() {
    const { showBoothsCard, showTicketingCard, lastRefreshedTime } = useContext(
        AnalyticsContext
    );
    const airmeet = useSelector((state) => state.cmDashboard.airmeet);
    const { event_type, event_sub_type } = airmeet || {};
    const airmeetFormat = getAirmeetFormat({
        eventType: event_type,
    });
    const isLiteMeetup =
        event_type === EVENT_TYPES.MEETUP &&
        event_sub_type === EVENT_SUB_TYPES.LITE;

    return (
        <>
            <TopBanner
                title={`Measure your ${airmeetFormat} success in a nutshell`}
                subtitle={`Get a snapshot of your live ${airmeetFormat} performance here. ${upperFirst(
                    airmeetFormat
                )} Replay data is not included.`}
                imgSrc='/images/dashboard/analytics/il-pie-chart.svg'
                lastUpdatedAt={lastRefreshedTime}
            />
            <FlexDiv alignItems='center' justifyContent='flex-end' pb='x4'>
                <SpecialRolesFilterSwitch tabId={ANALYTICS_SUMMARY_TAB} />
            </FlexDiv>
            <Box className='row'>
                <Box className='col-lg-4'>
                    <TurnoutCard />
                </Box>
                <Box className='col-lg-8'>
                    <TicketingCard />
                    {!showTicketingCard && !isLiteMeetup && (
                        <LoungeCard horizontal={true} />
                    )}
                </Box>
            </Box>
            {!isLiteMeetup && (
                <Box className='row'>
                    {showTicketingCard && (
                        <Box className='col-lg-4'>
                            <LoungeCard />
                        </Box>
                    )}

                    <Box
                        className={`col-lg-${showTicketingCard ? '4' : '6'}`}
                        style={{ display: showBoothsCard ? '' : 'none' }}
                    >
                        <TopBarsCard type='booths' />
                    </Box>
                    <Box
                        className={`col-lg-${
                            showTicketingCard
                                ? showBoothsCard
                                    ? '4'
                                    : '8'
                                : showBoothsCard
                                ? '6'
                                : '12'
                        }`}
                    >
                        <TopBarsCard type='sessions' />
                    </Box>
                </Box>
            )}
            <PollsCard airmeetFormat={airmeetFormat} />
        </>
    );
}
