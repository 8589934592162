import { STAGE_BANNERS } from 'components/StageRoom/Banners/constants';
import {
    ATTENDEE,
    BOOTH,
    BOOTH_TABLE_JOIN,
    CHAT_REPLY,
    DM_INITIATE,
    EMOJI_EXPRESS,
    ENTER_EVENT,
    ENTER_SESSION,
    EVENT,
    EVENT_REGISTER,
    POST_EVENT_FEED,
    SESSION,
    SESSION_RAISE_HAND,
    SESSION_RAISE_HAND_ACCEPT,
    TABLE_INTERACTION,
} from 'components/molecules/leaderboard/leaderboardActions.js';
import 'firebase/analytics';
import firebase from 'firebase/app';
import { ManageSubscriptionEvents } from 'hooks/manage-subscription/useManageSubscriptionOptions/constants';
import momenttz from 'moment-timezone';
import UAParser from 'ua-parser-js';
import {
    BOOTH_LOUNGE_NAME,
    SOCIAL_LOUNGE_NAME,
    getContextName,
} from 'utils/constants/social-lounge';
import PermissionUtils from 'utils/permission-utils';
import { v4 as uuid } from 'uuid';
import { getUrlParams } from './core';
import { bindModuleLogger } from './loggers/localLogger';

//import ReactGA from 'react-ga';

const logger = bindModuleLogger('Analytics', 'blue');

//Firebase token
const API_KEY = process.env.REACT_APP_FIREBASE_EVENTLOG_API_KEY;
const AUTH_DOMIAN = process.env.REACT_APP_FIREBASE_EVENTLOG_AUTH_DOMIAN;
const DATABASE_URL = process.env.REACT_APP_FIREBASE_EVENTLOG_DATABASE_URL;
const PROJECT_ID = process.env.REACT_APP_FIREBASE_EVENTLOG_PROJECT_ID;
const STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_EVENTLOG_STORAGE_BUCKET;
const MESSAGING_SENDER_ID =
    process.env.REACT_APP_FIREBASE_EVENTLOG_MESSAGING_SENDER_ID;
const APP_ID = process.env.REACT_APP_FIREBASE_EVENTLOG_APP_ID;
const MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_EVENTLOG_MEASUREMENT_ID;

const SESSION_STORAGE_USER_JOURNEY_KEY = '_am_jid';

export const CmDashboardEvents = {
    Community: {
        community_dashboard_login: 'community_dashboard_login',
    },
    Events: {
        host_meetup_clicked: 'host_meetup_clicked',
        host_conference_clicked: 'host_conference_clicked',
        create_event_clicked: 'create_event_clicked',
        create_event_meetup: 'create_event_meetup',
        create_event_conference: 'create_event_conference',
        event_created: 'event_created',
        event_dash_nav_changed: 'event_dash_nav_changed',
        header_more_menu_clicked: 'header_more_menu_clicked',
        header_share_event_btn_clicked: 'header_share_event_btn_clicked',
        header_share_btn_clicked: 'header_share_btn_clicked',
        clicked_on_cross_on_create_event_modal:
            'clicked_on_cross_on_create_event_modal',
        cm_clicks_on_template: 'cm_clicks_on_template',
        onb_clicked_create_event_from_scratch:
            'onb_clicked_create_event_from_scratch',
        onb_clicked_view_all_templates: 'onb_clicked_view_all_templates',
        onb_clicked_book_a_demo: 'onb_clicked_book_a_demo',
    },
    EventSeries: {
        es_create_clicked: 'es_create_clicked',
        es_created: 'es_created',
        event_series_preview_modal_create_clicked:
            'event_series_preview_modal_create_clicked',
        event_series_form_back_clicked: 'event_series_form_back_clicked',
    },
    GettingStarted: {
        gs_visited_getting_started_tab: 'gs_visited_getting_started_tab',
        gs_clicked_getting_started: 'gs_clicked_getting_started',
        gs_copied_event_link: 'gs_copied_event_link',
        gs_clicked_customize_event: 'gs_clicked_customize_event',
        gs_clicked_archive_event: 'gs_clicked_archive_event',
        gs_clicked_start_event: 'gs_clicked_start_event',
        gs_visited_help_center: 'gs_visited_help_center',
        gs_visited_support_lounge: 'gs_visited_support_lounge',
    },
    HelpCenter: {
        hc_visited_help_center_tab: 'hc_visited_help_center_tab',
        hc_clicked_help_center: 'hc_clicked_help_center',
        hc_visited_help_center: 'hc_visited_help_center',
        hc_visited_support_lounge: 'hc_visited_support_lounge',
    },
    Sessions: {
        schedule_tab_clicked: 'schedule_tab_clicked',
        hide_completed_session: 'hide_completed_session',
        unhide_completed_session: 'unhide_completed_session',
        add_an_item_clicked: 'add_an_item_clicked',
        session_created: 'session_created',
        updated_event_time_from_session_flow:
            'updated_event_time_from_session_flow',
        session_edited: 'session_edited',
        activity_type_clicked: 'activity_type_clicked',
        upload_video_clicked: 'upload_video_clicked',
        select_from_videos_clicked: 'select_from_videos_clicked',
        track_level_streaming_used: 'track_level_streaming_used',
        session_level_streaming_created: 'session_level_streaming_created',
        edit_session_after_end: 'edit_session_after_end',
    },
    Speakers: {
        speakers_and_hosts_tab_clicked: 'speakers_and_hosts_tab_clicked',
        add_speaker_clicked: 'add_speaker_clicked',
        speaker_profile_created: 'speaker_profile_created',
        speakers_list_view: 'speakers_list_view',
        D3_speakers_card_view: 'D3_speakers_card_view',
        send_invites_to_speaker_clicked: 'send_invites_to_speaker_clicked',
        invitation_sent_to_speaker: 'invitation_sent_to_speaker',
        previewed_speaker_profile: 'previewed_speaker_profile',
        assign_session_clicked: 'assign_session_clicked',
        hide_host_event: 'hide_host_event',
    },
};

export const EventAnalyticsLoggerTypes = {
    //registration
    regOauthDone: 'reg_oauth_done',
    regUpdateNametag: 'reg_update_nametag',
    calAddClicked: 'cal_add_clicked',
    regComplete: 'reg_complete',
    autoRegComplete: 'auto_reg_complete',
    userRegUtm: 'user_registration_utm',
    //regStart                    : 'reg_start',

    //Live session
    //Host event
    sessionStart: 'session_start',
    sessionEnd: 'session_end',
    sessionBreak: 'session_break',
    sessionResume: 'session_resume',
    recordedSessionVideoPlayed: 'recorded_session_video_played',
    recordedSessionVideoSearched: 'recorded_session_video_searched',

    //Attendee event
    attendeeJoinedSession: 'attendee_joined_session',
    sessionProgress: 'session_progress',
    attendeeExitedSession: 'attendee_exited_session',

    //chat event
    chatMessage: 'chat_text',
    postSessionFeedMessage: 'post_session_feed_message',
    reportMessage: 'report_message',
    removeMessage: 'remove_message',
    directMessage: 'direct_message',
    unblockRequest: 'unblock_request',
    declineRequest: 'decline_request',
    acceptRequest: 'accept_request',
    blockRequest: 'block_request',
    createRoom: 'create_room',
    localChatMessage: 'local_chat_message',
    directTabRequest: 'Click_Direct_tab',
    messageRequest: 'Click_message_request',
    directMessageThreadClick: 'direct_message_thread_click',
    directMessageNotificationOnTabClick:
        'direct_message_notification_on_tab_click',
    directMessageReplied: 'direct_message_replied',
    pinnedMessage: 'pinned_message',

    //gifEvent
    gifIconClicked: 'gifs_icon_clicked',
    gifSearched: 'gifs_searched',
    gifPosted: 'gifs_posted',
    gifDeleted: 'gifs_deleted_after_posting',

    //comments on question
    qna_disabled_enabled: 'qna_disabled_enabled',
    qna_comments_added: 'qna_comments_added',
    qna_comments_marked_as_answer: 'qna_comments_marked_as_answer',
    qna_comments_deleted: 'qna_comments_deleted',

    //chat Reaction
    chatReactionAdded: 'chat_reaction_add',
    chatReactionRemoved: 'chat_reaction_remove',

    //Chat Moderation
    chatModerationToggle: 'chat_moderation_toggle',

    //emoji
    emojiClicked: 'emoji_clicked',
    emojiSent: 'emoji_sent',

    //backstage events
    backstageJoined: 'backstage_joined',
    backstageExit: 'backstage_exit',

    raiseHandRequest: 'raise_hand_requested',
    raiseHandAccepted: 'raise_hand_accepted',
    stageUserCountChanged: 'stage_user_count_changed',
    fullScreen: 'full_screen',
    browserPermissionsProvideAccessClick:
        'browser-permissions-provide-access-click',
    browserPermissionsHelpPopup: 'browser-permissions-help-popup',
    browserPermissionsImpression: 'browser-permissions-impression',
    switchingBetweenLiteModePopup: 'facing-problems-popup',
    switchingBetweenLiteMode: 'switching-lite',
    switchingBetweenAboutLiteModeTooltip: 'lite-mode-popup',
    hostRequestedActionOnStage: {
        muteVideo: 'force_mute_video',
        muteAudio: 'force_mute_audio',
        stopScreenshare: 'force_remove_screenshare',
        removeSpeakerFromStage: 'remove_speaker_from_stage',
        removeAttendeeFromStage: 'remove_attendee_from_stage',
    },
    receivedHostRequestedAction: {
        muteVideo: 'received_force_mute_video',
        muteAudio: 'received_force_mute_audio',
        stopScreenshare: 'received_force_remove_screenshare',
    },
    inviteToStage: {
        inviteSent: 'invite_sent',
        inviteAccepted: 'invite_accepted',
        inviteRejected: 'invite_rejected',
        inviteWithdrawn: 'invite_withdrawn',
        inviteCancelled: 'invite_cancelled',
        inviteJoinNow: 'invite_join_now',
        inviteJoinLater: 'invite_join_later',
    },
    banners: {
        bannersClicked: 'banners_clicked',
        createBannerClicked: 'banner_create_banner_clicked',
        bannerPreviewClicked: 'banner_preview_clicked',
        bannerEdited: 'banner_edited',
        bannerSaved: 'banner_saved',
        bannerShowOnStage: 'banner_show_on_stage',
        bannerHideFromStage: 'banner_hide_from_stage',
        attendeeClickedOnCTA: 'banner_participant_clicked_on_CTA',
        attendeeViewedBanner: 'banner_participant_viewed',
        bannerSelected: 'banner_selected',
        attendeeDismissedBanner: 'banner_participant_dismissed',
    },
    ctaBanner: {
        bannersClicked: 'ISC_banners_clicked',
        createBannerClicked: 'ISC_organiser_clicked_CTA',
        bannerSelected: 'ISC_organiser_selected_banner',
        bannerSaved: 'ISC_organiser_clicked_save_CTA',
        bannerPreviewClicked: 'ISC_organiser_previewed_CTA',
        bannerShowOnStage: 'ISC_organiser_shown_on_stage_CTA',
        bannerHideFromStage: 'ISC_organiser_hide_CTA',
        bannerEdited: 'ISC_organiser_edit_CTA',
        attendeeViewedBanner: 'ISC_attendee_saw_CTA',
        attendeeClickedOnCTA: 'ISC_participant_clicked_CTA',
        attendeeDismissedBanner: 'ISC_attendee_dismissed_CTA',
    },
    hideUnhideUser: {
        hideUser: 'hide_user',
        unhideUser: 'unhide_user',
    },
    EMeditWelcomeMessage: 'EM_edit_welcome_message',
    participantSettingsCheck: 'participant_settings_check',
    participantSettingsSave: 'participant_settings_save',

    platformFeedback: {
        logUsersRating: 'platform_feedback_submittted',
        feedbackModalShown: 'platform_feedback_shown',
        feedbackSkipped: 'platform_feedback_skipped',
    },

    productTourStarted: 'product_tour_started',
    productTourSkipped: 'product_tour_skipped',
    productTourCompleted: 'product_tour_completed',

    //social lounge
    attendeeJoinedTable: 'attendee_joined_table',
    attendeeLeftTable: 'attendee_left_table',
    attendeeInteractionStart: 'attendee_interaction_start',
    attendeeInteractionEnd: 'attendee_interaction_end',
    attendeeInteractedAudio: 'attendee_interacted_audio',
    attendeeInteractedVideo: 'attendee_interacted_video',
    attendeeMutedAudio: 'attendee_muted_audio',
    attendeeMutedVideo: 'attendee_muted_video',
    attendeeAudioMutedByOtherUser: 'tm_audio_mute',
    attendeeVideoMutedByOtherUser: 'tm_camera_mute',
    attendeeTableSwitch: 'attendee_table_switch',
    attendeeTableMinimized: 'attendee_table_minimized',
    attendeeTableRestored: 'attendee_table_restored',
    ExitAirmeetButtonClick: 'Exit_Airmeet_Button_Click',
    attendeeTableScreenshare: 'attendee_table_screenshare',
    EMeditTable: 'EM_edit_table',
    customMedia: {
        videoStarted: 'pre-recorded-video-started',
        videoPaused: 'pre-recorded-video-paused',
        videoEnded: 'pre-recorded-video-ended',
        layoutChanged: 'layout-changed',
        videoSelected: 'pre-recorded-video-selected',
        videoUnSelected: 'pre-recorded-video-unselected',
    },
    liveSessionExitClicked: 'exit_clicked',
    tableLayoutChanged: 'table_layout_changed',
    slTableFull: 'SL_table_full',
    userReconnectedOnTable: 'user_reconnected_on_table',

    /*** permissions workflow setup ***/
    // Pre-airmeet
    preAirmeet: {
        permissionsPage: 'permissions_page',
        permissionsButtonClicked: 'permissions_button_clicked',
        setupPreviewPage: 'setup_preview_page',
        joinListeningMode: 'join_listening_mode',
        helpIcon: 'help_icon',
        joinEvent: 'join_event',
        testSetup: 'test_setup',
    },
    introVideo: {
        onSkipBtnClicked: 'wva_skip_button_clicked',
        userSawSkipBtn: 'wva_users_saw_skip_button',
        audioOnAutomatically: 'wva_audio_on_automatically',
        onUnmuteButtonClicked: 'wva_ummute_clicked',
        videoWatchedDuration: 'wva_video_watch_duration',
    },
    //Hybrid events
    hybridEvent: {
        sawCreateHybridEventOption: 'hybrid_saw_create_new_event_option',
        clickHybridEventCard: 'hybrid_create_hybrid_event_click',
        hybridEventCreated: 'hybrid_event_created',
        onEnterHybridVenue: 'hybrid_events_enter_venue_location',
        onUseTicketing: 'hybrid_events_used_ticketing',
        hybridEventCsvUploaded: 'hybrid_event_csv_uploaded',
        hybridSessionVenue: 'hybrid_events_enter_venue_location_session',
        hybridSessionVenueCapacity: 'hybrid_events_added_venue_capacity',
        hybridControlWhoCanJoin: 'hybrid_events_used_control_who_can_join',
        controlWhoJoinsWithAttendanceType:
            'hybrid_events_used_control_who_can_join_based_on_ticketing_attendance_type',
        sessionDisableDiscoverability:
            'hybrid_session_disabled_discoverability',
        onAttendeeCheckIn: 'hybrid_event_attendee_check_in',
        attendeeBulkCheckIn: 'hybrid_event_attendee_bulk_check_in',
    },
    // In-airmeet
    inAirmeet: {
        permissionPopup: 'permission_popup',
        permissionButtonClicked: 'permission_button_clicked',
        permissionPopupClosed: 'permission_popup_closed',
        setupPreviewPopup: 'setup_preview_popup',
        mainCTAClicked: 'main_CTA_clicked',
        setupcheckPopupTest: 'setup_preview_popup_test',
        permissionBlockedPopup: 'permission_blocked_popup',
        permissionBlockedTryAgainClicked: 'permission_blocked_tryagain_clicked',
        permissionSkipped: 'permission_skipped_clicked',
    },
    //pricing page events
    pricing: {
        pricingBtnClick: 'clicked_on_pricing',
        getStartedForFree: 'clicked_on_get_started_for_free',
        bookCallBtnClick: 'clicked_on_book_a_call',
    },
    // summit banner events
    summitUIBanner: {
        sneakPreviewBtn: 'clicked_on_sneak_preview',
        cancelBtn: 'clicked_on_cross_button',
    },
    //permissions workflow setup
    permissionsPage: 'permissions_page',
    permissionsButtonClicked: 'permissions_button_clicked',
    setupPreviewPage: 'setup_preview_page',
    joinListeningMode: 'join_listening_mode',
    helpIcon: 'help_icon',
    joinEvent: 'join_event',
    wantedExit: 'wanted_exit',
    enteredAirmeet: 'entered_airmeet',
    testSetup: 'test_setup',
    cancelledExit: 'wanted_exit_cancel_click',

    //Dashboard events
    hostEvent: 'clicked_host_an_event',
    orgCreated: 'created_org',
    eventCreated: 'create_first_airmeet',
    startAirmeet: 'start_first_airmeet',
    copyLink: 'copied_am_link',
    abandonedStage: 'abandoned_stage',
    signedUp: 'signed_up',
    capturedEmailId: 'captured_email_id',

    // Event landing page events
    eventLandingPage: {
        userVisit: 'LP_user_visit',
        sponsorClick: 'LP_sponsor_click',
        userExit: 'LP_user_exited',
    },
    // community team member invite events
    inviteTeamMemberButtonClicked: 'invite_team_member_btn_clicked',
    invitationSent: 'invitation_sent',
    invitationAccepted: 'invitation_accepted',
    invitationAcceptanceError: 'invitation_acceptance_error',

    //Event entry tab events
    eventEntryTab: {
        eventEntryTabCsvUpload: 'event_entry_tab_csv_upload',
        eventEntryTabCsvExported: 'event_entry_tab_csv_exported',
        eventEntryTabSettingChange: 'event_entry_tab_setting_change',
        eventEntryTabPromotionRequest: 'event_entry_tab_promotion_request',
    },

    emailAuthEventEntry: {
        selectedEventEntry: 'event_entry_type_selected',
        submittedEmailId: 'event_entry_submitted_emailid',
    },

    //schedule page events
    schedulePage: 'schedule_page',
    scheduleLinkClicked: 'schedule_link_clicked',
    scheduleSessionBookmarked: 'schedule_session_bookmarked',
    scheduleSearch: 'schedule_searched',
    scheduleFilterClicked: 'schedule_filter_clicked',
    scheduleDatePicker: 'schedule_datepicker_clicked',
    scheduleJoinSessionButtonClicked: 'schedule_join_session_button_clicked',
    scheduleSessionDetails: 'schedule_join_session_page',
    scheduleBackStageButtonClicked: 'schedule_backstage_button_clicked',
    scheduleSessionBookTabClicked: 'schedule_session_book_tab_clicked',

    newUIBanner: {
        summitBannerClicked: 'summit_banner_clicked',
    },

    lhs: {
        lhsIconClicked: 'lhs_icon_clicked',
    },

    // Reception page events
    receptionPage: {
        recReceptionViewed: 'rec_reception_viewed',
        recPrimaryCardClicked: 'rec_primary_card_clicked',
        recJoinSessionClicked: 'rec_join_session_clicked',
        recSecondaryCardClicked: 'rec_secondary_card_clicked',
        recSponsorButtonClicked: 'rec_sponsor_button_clicked',
        recSponsorSectionDisplayed: 'rec_sponsor_section_displayed',
        recSponsorLogoClicked: 'rec_sponsor_logo_clicked',
        recReceptionIconClicked: 'rec_reception_icon_clicked',
        recSecondaryCardView: 'rec_secondary_card_view',
        recJoinNetworkingClicked: 'rec_join_networking_clicked',
    },

    //schedule page events
    schedulePage: {
        scheduleBookmarkTabClicked: 'schedule_bookmark_tab_clicked',
        schScheduleLoaded: 'schedule_viewed',
        schBackstageButtonClicked: 'schedule_backstage_button_clicked',
        schJoinSession: 'schedule_join_session_clicked',
        scheduleSessionBookmarked: 'schedule_session_bookmarked',
        scheduleSessionUnbookmarked: 'schedule_session_unbookmarked',
        scheduleSearched: 'schedule_searched',
        scheduleSearchClicked: 'schedule_search_clicked',
        scheduleFiltered: 'schedule_filtered',
        scheduleDateClicked: 'schedule_date_clicked',
        landedSessionDetailsPage: 'landed_session_details_page',
        scheduleSessionAddToCalendar: 'schedule_session_add_to_calendar',
        liveSessionFilterClicked: 'live_session_filter_clicked',
        viewSessionDetailsClicked: 'view_session_details_clicked',
        clickOutsideScheduleOverlayClosed:
            'click_outside_schedule_overlay_closed',
    },

    // Booth page events
    boothPage: {
        boothJoined: 'booth_joined',
        boothEdited: 'booth_edited',
        boothLeft: 'booth_left',
        boothLeadShared: 'booth_lead_shared',
        externalLinkClicked: 'booth_external_links_clicked',
    },

    // Arena page events
    arenaPage: {
        visited: 'booths_arena_visitor',
    },

    // Notifications Announcements events
    notificationAnnouncement: {
        announcementDone: 'announcement_done',
        announcementImpressions: 'announcement_impressions',
        announcementClicks: 'announcement_clicks',
    },

    // Walkthrough video banner on reception events
    walkthroughReceptionVideo: {
        walkthroughVideoPlayed: 'walkthrough_video_played',
        walkthroughVideoBannerClicked: 'walkthrough_video_banner_clicked',
    },

    // Session playback
    sessionPlayback: {
        sessionPlaybackEnabledSession: 'session_playback_enabled_session',
        sessionPlaybackDisabledSession: 'session_playback_disabled_session',
        sessionPlaybackVideoPlayed: 'session_playback_video_played',
        sessionPlaybackDurationViewed: 'session_playback_duration_veiwed',
        sessionPlaybackDisabledMaster: 'session_playback_disabled_master',
        sessionPlaybackEnabledMaster: 'session_playback_enabled_master',
    },

    scheduleMeeting: {
        scheduleMeetingCreated: 'SM_meeting_created',
        scheduleMeetingSawNotification: 'SM_saw_meeting_notification',
        scheduleMeetingSawInviteesList: 'SM_saw_meeting_invitees_list',
        scheduleMeetingInviteAction: 'SM_invite_Action',
        scheduleMeetingSawDndNotification: 'SM_saw_DND_notification',
        scheduleMeetingDndStatusChange: 'SM_DND_status_change',
        scheduleMeetingJoinClicked: 'SM_users_tried_joining_meeting',
        scheduleMeetingJoinSuccess: 'SM_user_joined_meeting',
        scheduleMeetingLeftSuccess: 'SM_user_left_meeting',
        scheduleMeetingCancelled: 'SM_meeting_cancelled',
        scheduleMeetingSawReminder: 'SM_saw_meeting_reminder',
    },

    richProfile: {
        richProfileView: 'rich_profile_view',
        richProfileSMclick: 'rich_profile_SM_click',
    },

    stagePip: {
        stagePipAttendeeLeft: 'stage_pip_attendee_left',
        stagePipAttendeeJoined: 'stage_pip_attendee_joined',
        stagePipAttendeeBackToSession: 'stage_pip_attendee_returned_to_session',
        stagePipAttendeeJoinedOtherSpace:
            'stage_pip_attendee_joined_other_space',
    },

    //App installation hook for mobile-web
    hookForAppInstallation: {
        hookForAppInstallationViewed: 'hook_for_app_installation_viewed',
        hookForAppInstallationClicked: 'hook_for_app_installation_clicked',
        hookForAppInstallationCancelled: 'hook_for_app_installation_cancelled',
    },

    //video library and stream pre-recorded videos menu
    videoLibrary: {
        videoLibraryTabClicked: 'video_library_tab_clicked',
        libraryVideoUploadClicked: 'library_video_upload_clicked',
        libraryVideoUploaded: 'library_video_uploaded',
        libraryVideoUploadStart: 'library_video_upload_start',
        librarySearchApplied: 'library_searched_for_video',
        libraryFilterApplied: 'library_filter_applied',
        preRecordedTabClicked: 'pre-recorded_tab_clicked',
        videoUploadClicked: 'video_upload_clicked',
        videoLinkClicked: 'video_link_clicked',
        videoUploadStarted: 'video_upload_started',
        videoUploaded: 'video_uploaded',
        videoLinkStarted: 'video_link_started',
        videoLinked: 'video_linked',
    },

    videoLibraryRecordings: {
        recordingClicksOnCtas: 'recording_clicks_on_ctas',
        recordingFilterApplied: 'recording_filter_applied',
        recordingSearchApplied: 'recording_searched_for_recording',
        recordingActionAfterTickMark: 'recording_action_after_tick_mark',
        recordingTabClicked: 'community_recording_tab_clicked',
    },

    backdrop: {
        backdropLibTabClicked: 'stage_backdrop_clicked',
        backdropHelpVideoPlayed: 'backdrop_help_video_played',
        backdropGuidelineDownloaded: 'backdrop_guideline_downloaded',
        uploadBackdropImage: 'upload_backdrop_image',
        backdropUploadClickedZeroState: 'upload_backdrop_image_zero_state',
        viewPreview: 'view_preview',
        uploadDone: 'upload_done',
        backstageStageBackdropClicked: 'backstage_stage_backdrop_clicked',
        backstageRemoveBackdrop: 'backstage_remove_backdrop',
        backstageUpdateBackdrop: 'backstage_update_backdrop',
        videogridOnsizeClicked: 'videogrid_resize_clicked',
        backdropEditClicked: 'backdrop_edit_clicked',
        backdropRelocationModalCtaClicked:
            'backdrop_relocation_modal_cta_clicked',
        newBackdropLocationInfoPopupShown:
            'new_backdrop_location_info_popup_shown',
        newBackdropLocationInfoPopupDismissed:
            'new_backdrop_location_info_popup_dismissed',
    },
    liveStream: {
        streamDestinationsClicked: 'stream_destinations_clicked',
        streamButtonClicked: 'stream_button_clicked',
        liveStreamClicked: 'live_stream_clicked',
        addDestinationClicked: 'add_destination_clicked',
    },
    streamingResolution: 'streaming_resolution_changed',
    //Auth event
    initiateAuth: 'initiate_auth',
    clickedAuth: 'clicked_auth',
    asClickedOauth: 'as_clicked_Oauth',
    signUpSuccess: 'sign_up_successfully',
    signUpClick: 'sign_up_btn_clicked',
    loginSucess: 'login_successfully',
    logoutClicked: 'logout_clicked',
    guestLogin: 'enter_airmeet_as_guest',
    forgetPassword: 'forget_pwd_success',
    loginButtonClicked: 'clicked_login_btn',
    resendOtpClicked: 'clicked_resend_otp',
    captureOtpSignup: 'captured_otp_signup',
    signupAppleEmailLinkError: 'signup_apple_email_not_linked',
    captureOtpSignin: 'captured_otp_signin',
    backbuttonClicked: 'back_button_clicked',
    clickedOauthContinue: 'clicked_oauth_continue',
    asClickedContinueOnEmail: 'as_clicked_continue_on_email',
    asNewSignup: 'as_new_signup',
    asUserLoggedIn: 'as_user_logged_in',
    asSubmitOTP: 'as_submit_otp',
    asClickedSignupCreateAccountPage: 'as_clicked_signup_create_acc_page',

    // host sign-in analytics event
    hostSignIn: {
        hsOAuthClicked: 'hs_clicked_Oauth',
        hsContinueOnEmailClicked: 'hs_clicked_continue_on_email',
        hsNewSignup: 'hs_new_signup',
        hsUserLoggedIn: 'hs_user_logged_in',
        hsSubmitOtp: 'hs_submit_otp',
        hsClickedSignupCreateAccountPage: 'hs_clicked_signup_create_acc_page',
    },

    //live session bottom control
    liveSession: {
        microphoneClicked: 'new_microphone_clicked',
        cameraClicked: 'new_camera_clicked',
        screenshareClicked: 'new_screenshare_clicked',
        raiseHand: 'new_raise_hand',
        attendeeLeftStage: 'new_attendee_left_stage',
        emojiClicked: 'new_emoji_clicked',
        emojiSent: 'new_emoji_sent',
        hideClicked: 'new_hide_clicked',
        settingsClicked: 'new_settings_clicked',
        pollClicked: 'new_poll_clicked',
        recordingToggle: 'new_recording_toggle',
        moreOptionsClicked: 'new_more_options_clicked',
        moreOptionsSettingsClicked: 'new_more_options_settings_clicked',
        maxmiseScreen: 'maxmise_screen',
        pinClicked: 'pin_clicked',
        dominantMode: 'dominant_mode',
        pinnedSpeakerLimitReached: 'pinned_speaker_limit_reached',
        stageLimitReached: 'stage_limit_reached',
        gridLayout: 'grid_layout',
        raiseHandStageJoining: 'raise_hand_joining_stage',
        activeSpeakerLayout: 'activespeaker_layout',
        newlayoutInitiated: 'newlayout_initiated',
        sidebarLayout: 'sidebar_layout',
        newsLayout: 'news_layout',
        contentLayout: 'content_layout',
        oneOnOne: 'pip_layout',
        allSpeakersArea: 'all_speakers_area',
        videoInScreenShare: 'video_in_screenshare',
        videoInScreenShareLive: 'video_in_screenshare_live',
        stageLayoutEnabled: 'stage_layout_enabled',
        stageLayoutDisabled: 'stage_layout_disabled',
        stageLayoutSettingClicked: 'update_stage_layout_setting_clicked',
        muteStageAudio: 'mute_stage_audio',
        unmuteStageAudio: 'unmute_stage_audio',
        muteBackStageAudio: 'mute_backstage_audio',
        unmuteBackStageAudio: 'unmute_backstage_audio',
        newStageLayoutNudgeShown: 'new_stage_layout_nudge_shown',
        newStageLayoutNudgeDismissed: 'new_stage_layout_nudge_dismissed',
        newStageLayoutNudgeTrynowClicked:
            'new_stage_layout_nudge_trynow_clicked',
        newStageLayoutNudgeLearnmoreClicked:
            'new_stage_Layout_nudge_learnmoreclicked',
        moveToStageClicked: 'move_to_stage_clicked',
        moveToBackstageClicked: 'move_to_backstage_clicked',
        backstageWidgetCollapsed: 'backstage_widget_collapsed',
        backstageWidgetUncollapsed: 'backstage_widget_uncollapsed',
        screenshareMovedToStage: 'screenshare_moved_to_stage',
    },
    // user access
    blockUser: 'block_user',
    unblockUser: 'unblock_user',
    connectionStatus: {
        connected: 'user_connected',
        disconnected: 'user_disconnected',
        reconnected: 'user_reconnected',
    },
    unsupportedBrowser: {
        incompatibleBrowserPageShown: 'incompatible_browser_page_shown',
        copyAirmeetLink: 'copy_airmeet_link',
        continueHere: 'continue_here',
        downloadAppClicked: 'download_app_clicked',
    },
    // netowrk analytics event
    lobbyEnter: 'nl_enter',
    lobbyExit: 'nl_exit',
    lobbyMatch: 'nl_match',
    extendTimer: 'extend_sn_time',
    snRatingSubmitted: 'SN_rating_submitted',
    snRatingSkipped: 'SN_rating_skipped',

    // Resolution switch event
    streamingModeInfoShown: 'streaming_mode_info_shown',
    changeStreamingMode: 'change_streaming_mode',
    changeStreamingModeClicked: 'change_streaming_mode_clicked',
    unsupportedBrowser: {
        unsupportedBrowserPageShown: 'unsupported_browser_page_shown',
        copyEventLinkClicked: 'copy_event_link_clicked',
        joinInLiteModeClicked: 'join_in_lite_mode_clicked',
        downloadChromeClicked: 'download_chrome_clicked',
        downloadEdgeClicked: 'download_edge_clicked',
        updateBrowserClicked: 'update_browser_clicked',
    },
    firewallDetection: {
        failedToConnect: 'access_blocked_by_firewall',
        retryClicked: 'retry_access_blocked_by_firewall',
        joinInLiteModeClicked: 'join_lite_mode_access_blocked_by_firewall',
        liteModeTooltipChecked: 'lite_mode_tooltip_access_blocked_by_firewall',
        firewallBlockedConnectedViaProxy:
            'firewall_blocked_connected_via_proxy',
        firewallBlockedCommunicationPageShown:
            'access_blocked_by_firewall_page_shown',
    },
    removeSpeaker: 'remove_speaker',
    assignHost: 'assign_host',

    // Cohost events
    coHost: {
        manageCohostClicked: 'manage_cohost_clicked',
        addedCohost: 'cohost_added',
        removeCohost: 'cohost_removed',
    },

    exhibitor: {
        added: 'booth_exhibitor_added',
    },
    copyExhibitorInfo: {
        copyActionClicked: 'copy_exhibitor_row_clicked',
        copyIndividualExhibitorClicked: 'copy_exhibitor_info_clicked',
    },
    boothConfig: {
        boothConfigAttempted: 'booth_config_attempted',
        boothConfigUsed: 'booth_config_used',
        boothBroadcastDisabled: 'booth_broadcast_disabled',
        boothTablesDisabled: 'booth_tables_disabled',
        boothTablesNumbersChanged: 'booth_tables_numbers_changed',
        boothChatDisabled: 'booth_chat_disabled',
    },
    selfServeBillingPage: {
        billingPageView: 'billing_page_view',
        buyNowButtonClicked: 'buy_now_button_clicked',
        contactSalesButtonClicked: 'contact_sales_button_clicked',
        annualMonthlyToggle: 'annual_monthly_toggle',
        oneTimeBilling: 'one_time_billing',
        subscriptionBilling: 'subscription_billing',
        oneTimeValidity: 'one_time_validity',
        subscriptionValidity: 'subscription_validity',
        getInTouch: 'get_in_touch',
        contactSupport: 'contact_support',
        requestPlanChange: 'request_plan_change',
        featureListClicked: 'click_on_detailed_feature_list',
        faqClicked: 'click_on_faq',
        hoverFeatureItems: 'hover_on_items',
        upgradeMyAccountClicked: 'upgrade_my_account',
        disabledUpgradeMyAccountClicked: 'disabled_upgrade_my_account_clicked',
        applyCouponClicked: 'apply_coupon_clicked',
        removeCouponClicked: 'remove_coupon_clicked',
        planSizeChanged: 'plan_size_changed',
        addingBillingDetails: 'adding_billing_details',
        addingCardDetails: 'adding_card_details',
        filledBillingDetails: 'filled_billing_details',
        filledCardDetails: 'filled_card_details',
        viewCustomerPortal: 'view_customer_portal',
        subscriptionCreationSuccess: 'subscription_creation_success',
        subscriptionCreationFailure: 'subscription_creation_failure',
        billingTabsToggled: 'billing_tabs_toggled',
        conferencePremiumContactSalesButtonClicked:
            'cp_contact_sales_button_clicked',
        conferenceStarterBuyNowButtonClicked: 'cs_buy_now_button_clicked',
        swproContactSalesClicked: 'clicked_contact_sales_swpro',
        lockinConsentCaptured: 'lockin_consent_captured',
        standardTalkToUsButtonClicked: 'standard_talk_to_us_button_clicked',
        proTalkToUsButtonClicked: 'pro_talk_to_us_button_clicked',
        comparePlansButtonClicked: 'compare_plans_button_clicked',
        oneTimeEventOptionClicked: 'one_time_event_option_clicked',
        premiumWebinarBuyNowButtonClicked: 'pw_buy_now_button_clicked',
        virtualEventsTalkToUsButtonClicked: 've_talk_to_us_button_clicked',
        allInSuiteTalkToUsButtonClicked: 'ais_talk_to_us_button_clicked',
        backButtonClicked: 'back_button_clicked',
        privacyPolicyClicked: 'privacy_policy_clicked',
        termsOfServiceClicked: 'terms_of_service_clicked',
    },
    // closed captions
    closedCaptions: {
        on: 'closed_captions_turned_on',
        off: 'closed_captions_turned_off',
    },

    poll: {
        pollViewed: 'poll_viewed',
        pollVoted: 'poll_voted',
    },
    ticketing: {
        createTicket: 'ticketing_create_ticket',
        bookTicket: 'ticketing_book_ticket',
        ticketSelected: 'ticketing_select_ticket',
        ticketDetailForm: 'ticketing_detail_form',
        ticketOrderFailed: 'ticketing_order_failed',
        ticketOrderSuccess: 'ticketing_order_success',
        ticketOrderProcessing: 'ticketing_order_processing',
    },

    ticketingPromoCode: {
        // Organizer side
        addCouponClicked: 'add_coupon_clicked',
        couponDetailsSaved: 'coupon_details_saved',
        couponHideClicked: 'coupon_hide_clicked',
        couponDeleteClicked: 'coupon_delete_clicked',
        couponEditClicked: 'coupon_edit_clicked',
        couponWithdrawClicked: 'coupon_withdraw_clicked',
        couponResetClicked: 'coupon_reset_clicked',
        // Attendee side
        participantCouponApplied: 'participant_coupon_applied',
        participantBookedTicket: 'participant_booked_ticket',
    },

    meteredPricing: {
        dashboardSwitch: 'Dashboard_switch',
        editButtonWithinAirmeet: 'Edit_button_within_airmeet',
        contactSalesWithinModal: 'Contact_sales_within_modal',
        switchAirmeetWithinModal: 'Switch_Airmeet_within_modal',
        ninetyPercentAlertClosed: '90%Alert_Closed',
        hundredPercentAlertClosed: '100%Alert_Closed',
        ninetyPercentAlertSetRegistrationLimitClicked:
            '90%alert_set_registration_limit_clicked',
        hundredPercentAlertSetRegistrationLimitClicked:
            '100%alert_set_registration_limit_clicked',
        limitExceededAlertSetRegistrationLimitClicked:
            'Limit_exceeded_alert_set_registration_limit_clicked',
        ninetyPercentAlertViewed: '90%Alert_viewed',
        hundredPercentAlertViewed: '100%Alert_viewed',
        eventAccessEdit: 'Event_access_edit',
        premiumWithoutCard: 'premium_without_card',
        allSeatsFilledPageView: 'All_seats_filled_Pageview',
        educatePolicyModalButtonClick: 'educate_policy_modal_button',
        addPaymentSourceViewed: 'add_payment_source_viewed',
        cancelPaymentSource: 'cancel_payment_source',
        addPaymentSourceError: 'add_payment_source_error',
        addPaymentSourceCancelNudge: 'add_payment_source_cancel_nudge',
        paymentSourceAddedSuccessfully: 'payment_source_added_successfully',
        paymentSourceCardDetailsFilled: 'payment_source_card_details_filled',
        paymentSourceBillingDetailsFilled:
            'payment_source_billing_details_filled',
        clickedLiveAlertClose: 'clicked_live_alert_close',
        clickedLiveAlertPrimary: 'clicked_live_alert_primary',
        viewedLiveAlert: 'viewed_live_alert',
        speakerLimitModalPrimaryCta: 'speaker_limit_modal_primary_cta',
        speakerLimitModalSecondaryCta: 'speaker_limit_modal_secondary_cta',
        speakerLimitModalSwitchState: 'speaker_limit_modal_switch_state',
        exhibitorLimitModalPrimaryCta: 'exhibitor_limit_modal_primary_cta',
        exhibitorLimitModalSecondaryCta: 'exhibitor_limit_modal_secondary_cta',
        exhibitorLimitModalSwitchState: 'exhibitor_limit_modal_switch_state',
    },
    termsOfService: {
        agreeTermsOfService: 'agree_terms_of_service',
        disagreeTermsOfService: 'disagree_terms_of_service',
        signoutTermsOfService: 'signout_terms_of_service',
        seenTermsOfService: 'seen_terms_of_service',
    },
    hooks: {
        upsellHookClicked: 'upsell_hook_clicked',
    },
    customReception: {
        customReceptionClicked: 'custom_reception_clicked',
        customReceptionChanged: 'custom_reception_changes_done',
        customReceptionVideoAdded: 'custom_reception_video_added',
        customReceptionLinkAdded: 'custom_reception_link_added',
        customReceptionLinkClicked: 'custom_reception_link_clicked',
        customReceptionIframePopOverAdded:
            'iframe_reception_popover_integrated',
        customReceptionIframePluginAdded: 'iframe_reception_plugin_integrated',
        customReceptionIframePopOverClicked:
            'iframe_reception_attendee_clicked_popover',
        customReceptionVideosViewedFullScreen:
            'custom_reception_video_views_full_screen',
        customReceptionSessionWidgetDropped: 'session_widget_dropped',
        customReceptionSessionWidgetAdded: 'session_widget_added',
    },
    customBooth: {
        customBoothClicked: 'custom_booth_clicked',
        customBoothChanged: 'custom_booth_changes_done',
        customBoothVideoAdded: 'custom_booth_video_added',
        customBoothLinkAdded: 'custom_booth_external_link_added',
        customBoothLinkClicked: 'custom_booth_external_link_clicked',
        customBoothVideosViewedFullScreen:
            'custom_booth_video_views_full_screen',
    },
    customCsvUploadAnalytics: {
        createCustomTemplateClicked: 'create_custom_template_clicked',
        customTemplateEmailInvite: 'custom_template_email_invite_sent',
        customTemplateError: 'custom_template_got_an_error',
        customTemplateDownloadErrorCsv:
            'custom_template_download_error_csv_click',
        downloadTemplateClicked: 'download_template_clicked',
        customTemplateCreated: 'custom_template_created',
    },
    csvModificationsAnalytics: {
        csvUploadAddAttendeeClicked: 'csv_upload_add_attendee_clicked',
        csvUploadTypeSelectedClicked: 'csv_upload_type_selected_next_clicked',
        csvUploadEnablePayAsUGoClicked:
            'csv_upload_enable_pay_as_u_go_selected',
        csvUploadContactSalesClicked: 'csv_upload_contact_sales_selected',
        csvUploadUpgradePlanClicked: 'csv_upload_upgrade_plan_selected',
        csvUploadSkipClicked: 'csv_upload_skip_selected',
    },
    docViewer: {
        onClickFullScreen: 'poster_booth_full_screen_click',
        posterBoothDocDownload: 'poster_booth_doc_download',
    },
    boothBroadcast: {
        boothLiveBroadcastStart: 'booth_live_broadcast_start',
        boothLiveBroadcastEnd: 'booth_live_broadcast_end',
        boothChatSent: 'booth_chat_sent',
        boothVisitorsNotifyRequest: 'booth_visitors_notify_Request',
        boothLiveUserLeft: 'booth_live_user_left',
        broadcastVisitorsViewBroadcast:
            'booth_live_broadcast_visitors_view_broadcast',
        boothBroadcastScreenshareStart: 'booth_broadcast_screenshare_start,',
        boothBroadcastScreenshareEnd: 'booth_broadcast_screenshare_end',
    },
    boothOffer: {
        boothInterestedClick: 'booth_interested_click',
        boothIMInterestedLinkClicked: 'booth_im_interested_link_clicked',
        boothExhibitorOfferText: 'booth_exhibitor_offer_text',
    },
    boothLeads: {
        boothExhibitorPanelClicked: 'booth_exhibitor_panel_click',
        boothExhibitorInBoothDM: 'booth_exhibitor_in_booth_dm',
        boothExhibitorLeadsDM: 'booth_exhibitor_leads_dm',
    },
    eventListing: {
        eventListingLanding: 'EL_event_listing_landing',
        eventFilterClick: 'EL_event_filter_click',
        eventViewDetails: 'EL_event_view_details',
        eventArchivedClick: 'EL_event_archived_events_click',
        preArchiveEventClick: 'EL_pre_archive_event_click',
        postArchiveEventClick: 'EL_post_archive_event_click',
        preRestoreEventClick: 'EL_pre_restore_event_click',
        postRestoreEventClick: 'EL_post_restore_event_click',
        requestDemoClicked: 'EL_request_demo_clicked',
    },
    // event series
    eventSeries: {
        // landing page events.
        userVisitSeriesLP: 'LP_user_visit_series',
        clickRegister: 'reg_clicked_series',
        completeSeriesRegister: 'reg_complete_series',
        eventCardClicked: 'view_event_card_clicked',
        viewAllEventClicked: 'view_all_events_clicked',

        //series dashboard events.
        createNewEventInSeries: 'es_create_new_event_in_series_clicked',
        addExistingEventToSeries: 'es_add_event_to_series_clicked',
        continueAddEventInSeries: 'es_event_added_to_series',
        confirmRemoveEventFromSeries: 'es_remove_event',
        saveRegForm: 'es_reg_form_saved',
        previewRegForm: 'es_reg_form_preview',
        saveLandingPage: 'es_landing_page_saved',
        clickEditEmail: 'es_edit_email_clicked',
        clickNextBtnEditEmail: 'es_edit_email_content_next_clicked',
        confirmEditEmailSchedule: 'es_edit_email_schedule_done',
    },
    preEventAccess: {
        eventAccess: 'PE_pre_event_access',
        airmeetStatusChanged: 'PE_airmeet_status_changed',
        airmeetVisited: 'PE_airmeet_visited',
        detectedMagicLinkRedirection: 'PE_detected_magiclink_redirection',
    },
    /**
     * present within airmeet events
     */
    present: {
        presentClicked: 'present_clicked',
        presentWithinClicked: 'present_within_clicked',
        presentWithinFileSelected: 'present_within_file_selected',
        presentWithinFileNotLoaded: 'present_within_file_not_loaded',
        presentWithinRemoveClicked: 'present_within_remove_clicked',
        presentWithinShowPreview: 'present_within_show_preview',
        presentWithinHidePreview: 'present_within_hide_preview',
        presentWithinMaximise: 'present_within_maximise',
    },

    /**
     * show on stage airmeet events
     */
    showOnStage: {
        hideQuestionFromStage: 'hide_question_from_stage',
        showQuestionOnStage: 'show_question_on_stage',
        markAsAnsweredOnStage: 'mark_question_on_stage_as_answered',
        showChatOnStage: 'show_chat_on_stage',
        hideChatOnStage: 'hide_chat_on_stage',
        showPollOnStage: 'show_poll_on_stage',
    },
    /**
     * show on stage airmeet events
     */
    showOnStageContent: {
        removeVideoFromStage: 'remove_video_from_stage',
        addVideoOnStage: 'add_video_on_stage',
    },
    sessionsDashboard: {
        controlSessionAccessClicked: 'control_session_access_clicked',
        controlSessionAccessTypeClicked: 'control_session_access_type_clicked',
        controlSessionAccessDone: 'control_session_access_done',
        controlSessionAccessRevoked: 'control_session_access_revoked',
    },

    analyticsDashboard: {
        analyticsClicked: 'analytics_clicked',
        reportEmailed: 'report_emailed',
        analyticsFeedbackClicked: 'analytics_feedback_clicked',
        maybeLaterClicked: 'maybe_later_clicked',
        analyticsFeedbackSubmitted: 'analytics_feedback_submitted',
        seeAllClicked: 'see_all_clicked',
        analyticsSubTabClicked: 'analytics_subtab_clicked',
        includeSpecialRoles: 'analytics_include_special_roles',

        // Post Event Tab
        regFilterChange: 'al_post-evt_reg_filter_change',
        trafficSourceTypeChange: 'al_post-evt_traffic-source_type_change',
        sessionFilterChange: 'al_post-evt_session_filter_change',
        viewersDistFilterChange: 'al_post-evt_viewers-dist_filter_change',
        viewProfile: 'al_post-evt_view-profile',
    },

    audienceDashboard: {
        audienceClicked: 'audience_clicked',
        audienceSubTabClicked: 'audience_subtab_clicked',
        newSegmentClicked: 'audience_new_segment_clicked',
        filtersSectionViewed: 'audience_filter_section_viewed',
        filtersAdded: 'audience_filters_added',
        globalAggregationSelected:
            'audience_global_filter_aggregation_selected',
        filtersApplied: 'audience_filters_applied',
        downloadCSVClicked: 'audience_download_csv_clicked',
        addColumnClicked: 'audience_add_column_clicked',
        columnsSelected: 'audience_columns_selected',
        saveSegmentClicked: 'audience_save_segment_clicked',
        addFiltersClicked: 'audience_add_filters_clicked',
        editFiltersClicked: 'audience_edit_filters_clicked',
        segmentCreateUpdate: 'audience_segment_create_update',
        saveSuggestedSegment: 'audience_save_suggested_segment',
    },

    reportsDashboard: {
        generateClick: 'reports_generate_click',
        downloadClick: 'reports_download_click',
    },

    communityAudienceDashboard: {
        audienceClicked: 'account_audience_clicked',
        audienceSubTabClicked: 'account_audience_subtab_clicked',
        newSegmentClicked: 'account_audience_new_segment_clicked',
        filtersSectionViewed: 'account_audience_filter_section_viewed',
        filtersAdded: 'account_audience_filters_added',
        globalAggregationSelected:
            'account_audience_global_filter_aggregation_selected',
        filtersApplied: 'account_audience_filters_applied',
        downloadCSVClicked: 'account_audience_download_csv_clicked',
        addColumnClicked: 'account_audience_add_column_clicked',
        columnsSelected: 'account_audience_columns_selected',
        saveSegmentClicked: 'account_audience_save_segment_clicked',
        addFiltersClicked: 'account_audience_add_filters_clicked',
        editFiltersClicked: 'account_audience_edit_filters_clicked',
        segmentCreateUpdate: 'account_audience_segment_create_update',
    },

    billingPlanUpgrade: {
        upgradeMyAccountClicked: 'upgrade_my_account',
        payAnnualMonthlyNudgeClicked: 'pay_annual_monthly_nudge',
        applyCouponClicked: 'apply_coupon_clicked',
    },

    customizeEvent: {
        clearEventFeed: 'clear_event_feed',
        clearSessionsMessages: 'clear_sessions_messages',
        clearSessionsQuestions: 'clear_sessions_questions',
        clearSessionsPolls: 'clear_sessions_polls',
        stageLabel: 'stage_label',
        autoBrandClickApply: 'auto-brand_click_apply',
        autoBrandUsed: 'auto-brand_used',
        autoBrandReset: 'auto-brand_click_reset',
    },

    eventBranding: {
        eventBrandingUsed: 'IECA_used',
        eventBrandingThemeUsed: 'IECA_theme_used',
        eventBrandingHighlightColorUsed: 'IECA_highlight_color_used',
        eventBrandingButtonTextColorUsed: 'IECA_button_text_color_used',
        eventBrandingBackgroundOptionUsed: 'IECA_background_option_used',
        autoBrandingClickTryNow: 'auto-brand_click_try-now',
    },

    customLandingPage: {
        LPCA_used: 'LPCA_used',
        LPCA_used_customize_template_themes:
            'LPCA_used_customize_template_themes',
        LPCA_used_background_options: 'LPCA_used_background_options',
        LPCA_files_uploaded: 'LPCA_files_uploaded',
        LPCA_reorder_function_used: 'LPCA_reorder_function_used',
    },

    //Localization Events
    localization: {
        localizationUsersSawLanguageChangePopup:
            'localization_users_saw_language_change_popup',
        localizationUserSelectedLanguage: 'localization_user_selected_language',
        localizationLanguageUsed: 'localization_language_used',
    },

    conferenceTrial: {
        liveDemoButton: 'live_demo_button',
        liveDemoContactSales: 'live_demo_contact_sales',
        hostTrialConference: 'host_trial_conference',
        modalTrialTooltipTagHover: 'modal_trial_tooltip_tag_hover',
        modalTrialTooltipTextClick: 'modal_trial_tooltip_text_click',
        modalTrialTooltipContactSales: 'modal_trial_tooltip_contact_sales',
        dashboardContactSales: 'dashboard_contact_sales',
        airmeetsContactSales: 'airmeets_contact_sales',
        contactSalesReconnectMessage: 'contact_sales_reconnect_message',
        trialBannerCloseButton: 'trial_banner_close_button',
        eventEntryNudgeContactSales: 'event_entry_nudge_contact_sales',
    },

    //Custom Registration
    customRegistration: {
        CRClicksPlanAndPricing: 'CR_clicks_plan_and_pricing',
        CRClicksPreviewForm: 'CR_clicks_preview_form',
        CRClickRegCompleteButton: 'CR_click_reg_complete_button',
        calAddClicked: 'cal_add_clicked',
        CRCopyLink: 'CR_copy_link',
        CRClickChangeBtn: 'edit_registration_form',
        clickAddConditionalField: 'conditional_field_click_add',
        clickControlWhoCanView: 'control_who_can_view_clicked',
    },

    //Airmeet Duplication
    airmeetDuplication: {
        duplicateEventClicked: 'duplicate_event_clicked',
        submittedDuplicateEventDetails: 'submitted_duplicate_event_details',
    },
    //Update User Profile Events
    userProfile: {
        updateProfileClicked: 'UP_clicked_update_profile',
        updateProfileSaved: 'UP_updated_profile',
        updateProfileDiscarded: 'UP_discarded_changes',
        profilePicUpdated: 'UP_added_profile_photo',
    },

    userProfileLeaderboard: {
        updatedInterests: 'updated_interests',
        updatedIntro: 'updated_intro',
        updatedSocialLinks: 'updated_social_links',
    },

    // new onboarding flow
    onboarding: {
        onOnboardingForm: 'on_onboarding_form',
        submittedOnboardingForm: 'submitted_onboarding_form',
        abandonedOnboardingForm: 'abandoned_onboarding_form',
        abandonedOnboardingLanding: 'abandoned_onboarding_landing',
        abandonedOnboardingDemoList: 'abandoned_onboarding_demo_list',
        logoutOnboardingForm: 'logout_onboarding_form',
        logoutOnboardingLanding: 'logout_onboarding_landing',
        logoutOnboardingDemoList: 'logout_onboarding_demo_list',
        clickedDemoEvent: 'clicked_demo_event',
        clickedViewAllDemos: 'clicked_view_all_demos',
        joinedDemoEvent: 'joined_demo_event',
        exitedDemoEvent: 'exited_demo_event',
        clickedExploreSl: 'clicked_explore_sl',
        joinedSl: 'joined_sl',
        connectedOnSl: 'connected_on_sl',
        clickedOpenEvent: 'clicked_open_event',
        joinedOpenEvent: 'joined_open_event',
        clickedCreateEvent: 'clicked_create_event',
        step1Completed: 'step1_completed',
        step2Completed: 'step2_completed',
        step3Completed: 'step3_completed',
        clickedHostMeetup: 'clicked_host_meetup',
        clickedHostConference: 'clicked_host_conference',
        createdEventSuccessfully: 'created_event_successfully',
        createOrgFirstClicked: 'create_org_first_clicked',
        createEventStartTimeError: 'onboarding_create_event_start_time_error',
        startedOnboardingJourney: 'started_onboarding_journey',
        clickedContinueStep0: 'clicked_continue_step0',
        clickedContinueStep1: 'clicked_continue_step1',
        clickedContinueStep2: 'clicked_continue_step2',
        clickedContinueStep3: 'clicked_continue_step3',
        clickedContinueComm: 'clicked_continue_comm',
        clickedContinueAgency: 'clicked_continue_agency',
        clickedContinueSaas: 'clicked_continue_saas',
        clickedContinueOthers: 'clicked_continue_others',
        changedStartDate: 'changed_start_date',
        changedStartTime: 'changed_start_time',
        changedEventName: 'changed_event_name',
        changedOrgName: 'changed_org_name',
        clickedCreateEventOnboarding: 'clicked_create_event_onboarding',
        clickedExploreAirmeet: 'clicked_explore_airmeet',
        startedCompleteYourProfile:
            'users_landed_on_complete_your_profile_page',
        clickedContinueCompleteYourProfile:
            'clicked_continue_on_complete_your_profile_page',
    },
    peopleSearch: 'people_search',

    //dm notifications
    dmNotifications: {
        dmNotificationDismissed: 'DM_notification_dismissed',
        dmNotificationClicked: 'DM_notification_clicked',
    },

    //raise hand notifications
    raiseHandNotification: {
        notificationClicked: 'raise_hand_notification_clicked',
        dismissed: 'raise_hand_notification_dismissed',
    },

    // session join via notification
    trackEvents: {
        sessionJoinPrompt: 'session_join_prompt',
        trackFilterClicked: 'track_filter_clicked',
        sessionAttendeeJoinedViaPrompt: 'session_attendee_joined_via_prompt',
    },

    // Breakout events
    breakoutEvents: {
        userJoinedRoom: 'breakout_room_user_joined',
        userExitRoom: 'breakout_room_user_exit',

        announcementSent: 'breakout_announcement_post_clicked',

        breakoutRoomNameEdited: 'breakout_room_name_edited',
        breakoutRoomEdited: 'breakout_room_edited',
        breakoutAnnouncementCreate: 'breakout_announcement_create_clicked',
        breakoutTimer: 'breakout_timer',

        iconClicked: 'breakout_icon_clicked',
        createRoomClicked: 'breakout_createroom_clicked',
        randomClicked: 'breakout_random_clicked',
        csvClicked: 'breakout_csv_clicked',
        csvError: 'breakout_csv_error',
        randomSave: 'breakout_random_save',
        csvSave: 'breakout_csv_save',
        randomStart: 'breakout_random_start',
        csvStart: 'breakout_csv_start',
        randomDiscoverableTrue: 'breakout_random_discoverable_true',
        randomDiscoverableFalse: 'breakout_random_discoverable_false',
        csvDiscoverableTrue: 'breakout_csv_discoverable_true',
        csvDiscoverableFalse: 'breakout_csv_discoverable_false',
        randomClosed: 'breakout_random_closed',
        csvClosed: 'breakout_csv_closed',
        allowUsers: 'breakout_allow',
        newAttendeeJoined: 'breakout_newattendee_joined',
        joinedWithoutMic: 'breakout_room_user_joined_without_mic',
    },
    airmeetEventType: {
        track: 'track',
        nonTrack: 'non_track',
    },

    // join session via constants
    joinSessionVia: {
        accept: 'accept',
        reject: 'reject',
        autoJoin: 'auto_join',
    },

    trackFilterPageType: {
        landing: 'landing',
        agenda: 'agenda',
    },

    joinSessionNudgeType: {
        small: 'small',
        big: 'big',
    },

    trackTypeAllTracks: 'All Tracks',

    /**
     * live session translation with Interprefy
     */
    liveSessionTranslation: {
        InterprefyIconClicked: 'interprefy_icon_clicked',
        StreamStatusChanged: 'stream_status_changed',
    },

    //Event Loading States
    eventLoadingStates: {
        browserCheck: 'browser_check_shown',
        networkCheck: 'network_check_shown',
        explicitFirewallCheck: 'explicit_firewall_check_shown',
        checksComplete: 'checks_complete_shown',
    },

    embeddedRegistrationFormEvents: {
        embedFormClicked: 'embed_form_clicked',
        embedFormChooseColorClicked: 'embed_form_choose_color_clicked',
        embedFormColorSelected: 'embed_form_color_selected',
        embedFormCopyCodeClicked: 'embed_form_copy_code_clicked',
        embedFormPreviewClicked: 'embed_form_preview_clicked',
        embedFormUserDetailsSubmitted: 'embedded_form_user_details_submitted',
        embedFormDone: 'embed_form_done',
    },
    exhibitorChangingBoothFeatureEvents: {
        ECBFEnablesOrganizer: 'ECBF_organizer_enables_exhibitor',
        ECBFEnableDisableChat: 'ECBF_enable_disable_chat',
        ECBFDisableTable: 'ECBF_edit_disable_table',
        ECBFEnableDisableBroadcast: 'ECBF_enable_disable_broadcast',
    },

    //Network Error Types
    networkErrorTypes: {
        firewallError: 'firewall_error_shown',
        firebaseError: 'firebase_error_shown',
    },

    questionsEvent: {
        moderationToggled: 'QA_moderation_toggle',
        moderationAction: 'QA_moderation_approved_rejected',
        questionAction: 'QA_moderation_answered_unanswered',
        questionPosted: 'question',
        questionUpvoted: 'question_upvoted',
    },

    //Invite to table
    inviteToTable: {
        table_user_clicked_invite_others_button:
            'table_user_clicked_invite_others_button',
        table_user_invite_sent_public_specific:
            'table_user_invite_sent_public_specific',
        table_users_invited: 'table_users_invited',
        table_invite_shown_to_receiver: 'table_invite_shown_to_receiver',
        table_invite_accepted_declined: 'table_invite_accepted_declined',
        inviteOnFeedClicked: 'SL_invite_on_feed_clicked',
    },

    teamMember: {
        clickedContactSalesModal: 'clicked_contact_sales_modal',
        clickedContactSalesNudge: 'clicked_contact_sales_nudge',
        clickedUpgradePlanModal: 'clicked_upgrade_plan_modal',
        clickedBuyExtraSeatsModal: 'clicked_buy_extra_seats_modal',
        clickedUpgradePlanNudge: 'clicked_upgrade_plan_nudge',
        buyMoreSeatsClicked: 'buy_more_seats_cta_clicked',
        teamMemberAddonSeatsStep: 'team_member_addon_seats_step',
        teamMemberAddonSeatsAction: 'team_member_addon_seats_action',
        teamMemberAddonBackButtonClicked:
            'team_member_addon_back_button_clicked',
        teamMemberAddonPaymentStep: 'team_member_addon_payment_step',
        teamMemberAddonPaymentAction: 'team_member_addon_payment_action',
        manageSeatsLimitClicked: 'manage_seats_limit_cta_clicked',
        manageTeamMemberSeatsAddon: 'manage_team_member_seats_addon',
        manageTeamMemberSeatsBuyNowClicked:
            'manage_team_member_seats_buy_now_clicked',
        manageTeamMemberReduceSeatsClicked:
            'manage_team_member_reduce_seats_clicked',
        manageTeamMemberReduceSeatsPopupAction:
            'manage_team_member_reduce_seats_popup_action',
        manageTeamMemberReduceSeatsLimit:
            'manage_team_member_reduce_seats_limit',
        manageTeamMemberReduceSeatsLimitAction:
            'manage_team_member_reduce_seats_limit_action',
        manageTeamMemberReduceSeatsLimitBackButtonClicked:
            'manage_team_member_reduce_seats_limit_back_button_clicked',
        manageTeamMemberDeletion: 'manage_team_member_deletion',
        manageTeamMemberDeletionAction: 'manage_team_member_deletion_action',
    },

    //SupportEmail
    supportEmail: {
        supportEmailEntered: 'support_email_entered',
        supportEmailSelected: 'support_email_selected',
        supportEmailClicked: 'support_email_clicked',
        supportEmailRemoved: 'support_email_removed',
    },
    //User Created Tables
    ugc: {
        attendeeJoinedUGCTable: 'attendee_joined_ugc_table',
        createTableToggleClicked: 'create_table_toggle_clicked',
        createNewTableClick: 'create_new_table_click',
        createNewTableSave: 'create_new_table_save',
        deleteTable: 'delete_table',
        modifyLounge: 'modify_lounge',
    },

    /**
     * * dashboard events
     * 1. events tab events
     * 2. Schedule tab events
     * 3.
     */
    cmDash: CmDashboardEvents,

    manageSubscription: ManageSubscriptionEvents,

    // Stage chat events
    speakerChatSent: 'speaker_chat_sent',

    //leaderboard events
    leaderboardEvent: {
        leaderboardSharedOnFeed: 'LB_leaderboard_shared_on_feed',
        attendeeOpenedLeaderboard: 'LB_attendee_opened_leaderboard',
        leaderboardStopped: 'LB_leaderboard_stopped',
        leaderboardWinnersAnnounced: 'LB_leaderboard_winners_announced',
        leaderboardViewPrizes: 'LB_attendee_viewed_prizes',
        leaderboardViewWinners: 'LB_attendee_viewed_winners',
        leaderboardEnded: 'LB_leaderboard_ended',
        resetleaderboard: 'LB_leaderboard_reset',
        startLeaderboard: 'LB_leaderboard_start',
        userHistoryViewed: 'LB_leaderboard_user_history_viewed',
        attendeeHistoryViewed: 'LB_leaderboard_attendee_history_viewed',
        historyTabViewed: 'LB_leaderboard_history_tab_viewed',
        leaderboardRefreshed: 'LB_leaderboard_refreshed',
    },

    /**
     * Email management
     */
    emailManagement: {
        email_management_clicked: 'email_management_clicked',
        new_email_clicked: 'new_email_clicked',
        preview_email: 'preview_email',
        edit_email_clicked: 'edit_email_clicked',
        pause_email_clicked: 'pause_email_clicked',
        email_paused: 'email_paused',
        pause_email_dismissed: 'pause_email_dismissed',
        pause_email_viewed: 'pause_email_viewed',
        cd_email_dismissed: 'cd_email_dismissed',
        cd_contact_support_clicked: 'cd_contact_support_clicked',
        cd_users_saw_banner: 'cd_users_saw_banner',

        // new v2 events
        em_filter_clicked: 'em_filter_clicked',
        em_merge_tag_email_subject_clicked:
            'em_merge_tag_email_subject_clicked',
        em_merge_tag_email_subject_selected:
            'em_merge_tag_email_subject_selected',
        em_new_email_details_next_clicked: 'em_new_email_details_next_clicked',
        em_new_email_content_next_clicked: 'em_new_email_content_next_clicked',
        em_new_email_schedule_clicked: 'em_new_email_schedule_clicked',
        em_new_email_schedule_done: 'em_new_email_schedule_done',
        em_edit_email_content_next_clicked:
            'em_edit_email_content_next_clicked',
        em_edit_email_schedule_clicked: 'em_edit_email_schedule_clicked',
        em_edit_email_schedule_done: 'em_edit_email_schedule_done',
        send_test_email_clicked: 'send_test_email_clicked',
        test_email_sent: 'test_email_sent',
        invalid_merge_tag_detected: 'invalid_merge_tag_detected',
        mandatory_merge_tag_missing: 'mandatory_merge_tag_missing',

        email_templates_banner_dismissed: 'email_templates_banner_dismissed',
        email_templates_users_saw_banner: 'email_templates_users_saw_banner',

        email_performance_icon_clicked: 'email_performance_icon_clicked',
    },

    // Live session exit
    exitSessionClicked: 'exit_session_clicked',
    exitSessionConfirmation: 'exit_session_confirmation',

    // Collapsible RHS
    collapse_RHS: 'collapse_RHS',
    // typeform submitted
    typeform_submitted: 'typeform_submitted',
    profilePage: {
        clickedDiscoverEvents: 'clicked_discover_events',
        landedOnMyProfile: 'mp_landed_on_my_profile_page',
    },

    preEventAndSocialShare: {
        entered_pre_event: 'entered_pre_event',
        educational_menu_clicked: 'educational_menu_clicked',
        social_sharing_clicked: 'social_sharing_clicked',
    },
    backgroundFilter: {
        enabled: 'background_filter_enabled',
        supported: 'background_filter_supported',
        filter_selected: 'filter_selected',
    },

    postEventReplay: {
        postEventReplayEnabled: 'post_event_replay_enabled',
        postEventReplayDisabled: 'post_event_replay_disabled',
    },

    fluidLounge: {
        fsMaximizeMinimizeCall: 'fs_maximize_minimize_call',
        fsMegaphoneUsed: 'fs_megaphone_used',
        fsUserJoinedCluster: 'fs_user_joined_cluster',
        fsRoomsCreated: 'fs_rooms_created',
        fsSessionCapacityFull: 'fs_session_capacity_full',
        fsReacted: 'fs_reacted',
        fsCustomBgUpload: 'fs_custom_background_uploaded',
        fsBackgroundSaved: 'fs_background_saved',
        fsPreviewButtonClicked: 'fs_preview_button_clicked',
        fsZoomInClicked: 'fs_zoom_in',
        fsZoomOutClicked: 'fs_zoom_out',
        fsZoomResetClicked: 'fs_zoom_reset',
        fsDashboardModalConfirm: 'fs_dashboard_modal_confirm',
        fsDashboardModalClose: 'fs_dashboard_modal_close',
        fsOnboardingStarted: 'fs_onboarding_open',
        fsOnboardingClosed: 'fs_onboarding_close',
    },
    //group meeting
    groupMeetingStarted: 'group_meeting_started',
    groupMeetingEnded: 'group_meeting_ended',
    userJoinedGroupMeeting: 'user_joined_group_meeting',
    userLeftGroupMeeting: 'user_left_group_meeting',
    videoAnnouncement: {
        clickVideoAnnouncement: 'cta_click_live_video_announcement',
        startVideoAnnouncement: 'start_live_video_announcement',
        audioVideoCheckAnnouncement:
            'audio_video_check_live_video_announcement',
        endVideoAnnouncement: 'end_live_video_announcement',
        videoAnnouncementViewed: 'viewed_live_video_announcement',
        videoAnnouncementClosed: 'closed_live_video_announcement',
        videoAnnouncementAudioMuted: 'audio_muted_live_video_announcement',
        videoAnnouncementMaximized: 'maximized_live_video_announcement',
    },
    //profile nudge
    updateProfileNudge: {
        profileNudgeShown: 'profile_nudge_shown',
        profileNudgeDismissed: 'profile_nudge_dismissed',
        profileNudgeEditProfileClicked: 'profile_nudge_edit_profile_clicked',
    },
    loungeSearch: {
        tableSearched: 'lounge_user_searched_for_a_table',
        searchClear: 'lounge_user_cleared_search_box',
    },
    advancedPeopleSearch: {
        peopleSearchFilterAppliedCanceled:
            'people_search_filter_applied_canceled',
        clearAllClicked: 'people_search_filter_clear_all_clicked',
        filterClicked: 'people_search_filter_clicked',
        advancedSearchClicked: 'advanced_search_clicked',
        searchFieldSelected: 'advanced_search_field_selected',
    },
    dashboardSummary: {
        dashboardSummaryPageCTA: 'dashboard_summary_page_CTA',
    },
    helpWidget: {
        helpWidgetOpened: 'help_widget_opened',
    },
    dashboardRollout: {
        switchedDashboard: 'dnt_switched_to_dashboard',
        sawNewDashboardBanner: 'dnt_saw_new_dashboard_banner',
        closedNewDashboardBanner: 'dnt_closed_new_dashboard_banner',
        sawRatingModal: 'dnt_community_saw_rating_modal',
        ratingModalAction: 'dnt_community_action_on_rating_modal',
    },
    //system check
    systemCheck: {
        preCallCheckStarted: 'precheck_started',
        preCallCheckAgain: 'precheck_again',
        preCallCheckCancelled: 'precheck_cancel',
        facingProblemsClicked: 'precheck_facing_problems_clicked',
        preCallCheckResults: 'precheck_results',
        preCallCheckRHSTabOpen: 'precheck_rhs_tab_open',
        preCallPageVisit: 'precheck_page_visit',
        preCallCheckIssueResolved: 'precheck_issue_resolved',
        preCheckCloseCTAClicked: 'precheck_close_button_clicked',
    },
    // big nudge for parallel session
    bigNudge: {
        shown: 'big_nudge_shown',
        joinNow: 'big_nudge_join_now',
        dismissed: 'big_nudge_dismissed',
        viewSchedule: 'big_nudge_view_schedule',
    },

    //resource management
    rhManagement: {
        rhResourceFileFormatUnsupported: 'rh_resource_file_format_unsupported',
        rhResourceTabEnabledInLiveEvent:
            'rh_resource_Tab_enabled_in_live_event',
        rhFilesAdded: 'rh_files_added',
        rhResourceAddedFromLiveSessionRHS:
            'rh_resource_added_from_live_session_RHS',
        rhResourceRemoved: 'rh_resource_removed',
        rhResourceUploadedSpeaker: 'rh_resource_uploaded_speaker',
        rhResourceAdded: 'rh_resource_added',
        rhResourceShownOnStage: 'rh_resource_shown_on_stage',
        rhAttendeesViewedResource: 'rh_attendees_viewed_resource',
        rhAttendeesDownloadedResource: 'rh_attendees_downloaded_resource',
        rhAttendeeClickedOnWidgetCTA: 'rh_attendee_clicked_on_widget_CTA',
        rhResourceWidgetAdded: 'rh_resource_widget_added',
        rhAttendeesUsedSearch: 'rh_attendees_used_search',
        rhAttendeeViewedResource: 'rh_attendee_viewed_resource',
        rhResourceHidden: 'rh_resource_hidden',
        rhAttendeeDownloadedResource: 'rh_attendee_downloaded_resource',
        rhAttendeeClickedResource: 'rh_attendee_clicked_resource_link',
        rhResourceDownloadDisabled: 'rh_resource_download_disabled',
        rhAttendeesUsedFilter: 'rh_attendees_used_filter',
        rhResourceLinkedToSession: 'rh_resource_linked_to_session',
        rhAttendeeClickedOnResourcesTab: 'rh_attendee_clicked_on_resources_tab',
        rhFileViews: 'rh_file_views',
        rhFileDownloads: 'rh_file_downloads',
        rhOrganiserTriedToLinkResource: 'rh_organiser_tried_to_link_resource',
    },

    //resource personalization
    rpManagement: {
        RPVisibilitySettingsClicked: 'RP_visibility_settings_clicked',
        RPSawOnlyInvalidSegment: 'RP_saw_only_invalid_segment',
        RPGoToSegmentsClicked: 'RP_go_to_segments_clicked',
        RPResourcePersonalizationSaved: 'RP_resource_personalization_saved',
        RPSawValidSegment: 'RP_saw_valid_segment',
        RPSawInvalidSegment: 'RP_saw_invalid_segment',
    },

    typeformIntegration: {
        clicked: 'TF_integration_clicked',
        saved: 'TF_integration_saved',
        widget_saved: 'TF_widget_enabled_integration_saved',
        directTabClicked: 'TF_integration_direct_tab_clicked',
        chatWidgetClicked: 'TF_integration_chat_widget_clicked',
    },
    requestToJoinStage: {
        userRequestedToJoinStage: 'HRS_user_requested_to_join_stage',
        userAcceptedToJoinStage: 'HRS_user_accepted_to_join_stage',
        userDeclinedToJoinStage: 'HRS_user_declined_to_join_stage',
        userRequestToJoinNotAllowed: 'HRS_user_request_to_join_not_allowed',
        requestedUserJoinedStageSuccess:
            'HRS_user_requested_to_join_stage_success',
        userRequestToJoinStagePermissionMissing:
            'HRS_user_request_to_join_stage_permission_missing',
    },
    welcomeVideoAnalytics: {
        wvaVideoAdded: 'wva_video_added',
        wvaPreviewClicked: 'wva_preview_clicked',
        wvaAddToEvent: 'wva_add_to_event',
    },
    waitingAreaManageScheduleClicked: 'waiting_area_manage_schedule_clicked',

    // Copy Booth Link events
    copyBoothLink: {
        copyBoothLinkClicked: 'BLA_visitors_clicked_copy_booth_url',
        attendeesRegisteredViaCopyBoothLink:
            'BLA_attendees_register_through_booth_url',
    },

    // speaker onboarding
    speakerOnboarding: {
        backstageSawJoiningModalAndDismissed:
            'backstage_saw_joining_modal_and_dismissed',
        speakerOnboardingJoinBackstageClicked:
            'speaker_onboarding_join_backstage_clicked',
    },

    // product update
    productUpdate: {
        ackFormatUpdate: 'ack_format_update',
        ackTemplatesIntro: 'ack_templates_intro',
    },

    // registration management
    registrationManagement: {
        editAttendeeChangesSaved: 'rm_edit_attendee_changes_saved',
        attendeeBlocked: 'rm_attendee_blocked',
        attendeeUnblocked: 'rm_attendee_unblocked',
        attendeeDeleted: 'registrant_deleted',
    },

    customFont: {
        eventBrandingClicked: 'event_branding_clicked',
        fontChanged: 'font_changed',
        customFontIconClicked: 'font_custom_font_icon_clicked',
        customFontUploaded: 'font_custom_font_uploaded',
        fontFamilyDropdownClicked: 'font_family_dropdown_clicked',
        fontSaved: 'font_saved',
    },

    dashLightTheme: {
        tooltipShown: 'dash_light_theme_tooltip_shown',
        tooltipClicked: 'dash_light_theme_tooltip_clicked',
        lightThemeToggled: 'dash_light_theme_toggled',
    },

    airGenie: {
        clickAutoGenerate: 'ag_click_autogenerate',
        clickGenerate: 'ag_click_generate',
        clickApply: 'ag_click_apply',
    },

    beaconBar: {
        open: 'beacon_bar_open',
        click: 'beacon_bar_click',
    },

    // Email draft
    emailDraft: {
        savedAutomatically: 'ed_saved_draft_automatically',
        savedManually: 'ed_saved_draft_manually',
        continuedEditing: 'ed_continued_editing_draft',
        discarded: 'ed_discarded_draft',
    },
    waitingScreen: {
        uploadClicked: 'waiting_screen_upload_clicked',
        wsUploaded: 'waiting_screen_uploaded',
        wsEventUpdated: 'waiting_screen_event_updated',
        wsSessionUpdated: 'waiting_screen_session_updated',
    },
    airProfile: {
        clickProfile: 'airprofile_click_view_profile',
        clickActivityTab: 'airprofile_click_activity_tab',
        clickSocialLink: 'airprofile_click_social_link',
        interestsFeedback: 'air-profile_interests_feedback',
    },
    URLTrack: {
        UrlTrackingCreated: 'url_tracking_created',
    },

    // Email Domain Restriction
    emailDomainRestriction: {
        saved: 'edr_domain_restriction_saved',
        attendeeBlockedFromRegistering:
            'edr_attendees_blocked_from_registering',
    },

    // Analytics Events
    analyticsEventsDashboard: {
        visited: 'aed_visited_dashboard',
        changedTab: 'aed_changed_tab',
        timeSeriesChangePage: 'aed_time_series_change_page',
        topEventsSortChange: 'aed_top_events_sort_change',
        topEventsEventClicked: 'aed_top_events_event_clicked',
        downloadClicked: 'aed_download_clicked',
        downloadSuccessful: 'aed_download_successful',
        downloadFailed: 'aed_download_failed',
        filterClicked: 'aed_filter_clicked',
        filterDrawerClosed: 'aed_filter_drawer_closed',
        filterDrawerSaved: 'aed_filter_drawer_saved',
        filterDrawerFiltersSelected: 'aed_filter_drawer_filters_selected',
    },

    // Audience Summary Dashboard
    audienceSummaryDashboard: {
        visited: 'asd_visited_dashboard',
        filterClicked: 'asd_filter_clicked',
        trafficSourcesValueChanged: 'asd_traffic_sources_value_changed',
        trafficSourcesTypeChanged: 'asd_traffic_sources_type_changed',
        topAttendeesSortChanged: 'asd_top_attendees_sort_changed',
        topAttendeesViewProfileClicked:
            'asd_top_attendees_view_profile_clicked',
        demographicsTabChanged: 'asd_demographics_tab_changed',
    },

    // CommunityEmailTemplate
    communityEmailTemplates: {
        etcCommunityViewedEmailTemplateTab:
            'etc_community_viewed_email_template_tab',
        etcCommClickedCreateTemplateFromScratch:
            'etc_comm_clicked_create_template_from_scratch',
        etcSavedCustomTemplate: 'etc_saved_custom_template',
        etcEditedDefaultSystemTemplate: 'etc_edited_default_system_template',
        etcDeletedTemplate: 'etc_deleted_template',
        etcDuplicatedTemplate: 'etc_duplicated_template',
        eteCustomEmailCreated: 'ete_custom_email_created',
        eteSaveAsTemplate: 'ete_save_as_template',
        eteOtherTemplateSelectedWhileEditingSystemTemplate:
            'ete_other_template_selected_while_editing_system_template',
    },

    videoEditor: {
        clEditRecording: 've_community_level_edit_recording_clicked',
        clClipsTab: 've_community_level_clips_tab_clicked',
        clClipsDownload: 've_community_level_download_clicked',
        clClipsDelete: 've_community_level_delete_clicked',
        clClipsPlay: 've_community_level_play_clicked',
        clClipsMultiselect: 've_community_level_multiselect_clicked',
        clClipsMultiselectAll: 've_community_level_select_all_clicked',
        clClipsMultiselectDelete:
            've_community_level_multiselect_delete_clicked',
        clClipsMultiselectDownload:
            've_community_level_multiselect_download_clicked',
        clClipsSearch: 've_community_level_users_completed_search',
        elEditRecording: 've_airmeet_level_edit_recording_clicked',
        elClipsTab: 've_airmeet_level_clips_tab_clicked',
        elClipsDownload: 've_airmeet_level_download_clicked',
        elClipsDelete: 've_airmeet_level_delete_clicked',
        elClipsPlay: 've_airmeet_level_play_clicked',
        elClipsMultiselect: 've_airmeet_level_multiselect_clicked',
        elClipsMultiselectAll: 've_airmeet_level_select_all_clicked',
        elClipsMultiselectDelete: 've_airmeet_level_multiselect_delete_clicked',
        elClipsMultiselectDownload:
            've_airmeet_level_multiselect_download_clicked',
        elClipsDownloadAll: 've_airmeet_level_select_all_download_clicked',
        elClipsSearch: 've_airmeet_level_users_completed_search',
        layoutChange: 've_layout_change_clicked',
        undo: 've_undo_clicked',
        redo: 've_redo_clicked',
        addMedia: 've_media_added',
        split: 've_split_clicked',
        zoom: 've_zoom_clicked',
        seek: 've_seek_clicked',
        timelineDrag: 've_timeline_drag_clicked',
        timelineCrop: 've_timeline_crop_clicked',
        timelineDelete: 've_timeline_delete_clicked',
        addLogo: 've_logo_added',
        enteredText: 've_entered_text',
        textFontStyle: 've_text_font_style',
        textFontSize: 've_text_font_size',
        textBg: 've_text_background',
        videoBg: 've_video_background',
        exportVideo: 've_export_video_clicked',
    },

    eventRecordings: {
        tableCtaClicked: 'event_rec_table_cta_clicked',
        headerRecDownloadClicked: 'event_rec_header_rec_download_clicked',
    },

    freeTrial: {
        freeTrialRestartClicked: 'free_trial_restart_trial_clicked',
        freeTrialBuyNowClicked: 'free_trial_buy_now_clicked',
    },
};

class FirebaseLogger {
    userJourneyID = null;
    _enableCustomProxy = false;
    _isProxyChecked = false;
    _enablePreviewMode = false;
    /**
        airmeet_visit_id: airmeetVisitId,
        community_name: communityName,
        community_id: communityId,
        attendee_id: userId,
        airmeet_id: airmeetId,
        airmeet_status: airmeetStatus,
        session_status: sessionStatus,
        user_id: userId,
        user_role: userRole,
        user_stage_role: userStageRole,
        airmeet_session_id: sessionId,
        airmeet_session_type: sessionType,
        user_eid: eid,
     */
    _defaultEventData = {};
    constructor() {
        this.init();
        try {
            this.userJourneyID = sessionStorage.getItem(
                SESSION_STORAGE_USER_JOURNEY_KEY
            );
        } catch {
            this.userJourneyID = uuid();
            logger.info('sessionStorage is disabled');
        }
        if (!this.userJourneyID) {
            this.userJourneyID = uuid();
            sessionStorage.setItem(
                SESSION_STORAGE_USER_JOURNEY_KEY,
                this.userJourneyID
            );
        }
    }

    init() {
        // Your web app's Firebase configuration
        const firebaseConfig = {
            apiKey: API_KEY,
            authDomain: AUTH_DOMIAN,
            databaseURL: DATABASE_URL,
            projectId: PROJECT_ID,
            storageBucket: STORAGE_BUCKET,
            messagingSenderId: MESSAGING_SENDER_ID,
            appId: APP_ID,
            measurementId: MEASUREMENT_ID,
        };
        // Initialize Firebase
        this.app = firebase.initializeApp(firebaseConfig, 'event-logger');
        //google analytics
        // ReactGA.initialize('UA-153536315-1');
        // ReactGA.pageview('/homepage');
    }

    enableCustomProxy(enabled) {
        this._enableCustomProxy = enabled;
        this.loaded = false;
    }

    enablePreviewMode(enabled) {
        this._enablePreviewMode = enabled;
    }

    async checkProxyRequired() {
        // if proxy already enabled return
        if (this._enableCustomProxy || this._isProxyChecked) {
            return true;
        }

        try {
            await fetch('https://www.google-analytics.com/g/collect');
        } catch {
            logger.info('Enabling Custom Proxy');
            this.enableCustomProxy(true);
            this._isProxyChecked = true;
        }
        this._isProxyChecked = true;
        return true;
    }
    loadScript(url, ID) {
        if (!this._enableCustomProxy) {
            return Promise.resolve(true);
        }

        return new Promise((resolve) => {
            const existingScript = document.getElementById(ID);
            if (existingScript) {
                return resolve();
            }

            const script = document.createElement('script');
            script.src = url;
            script.id = ID;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            script.onload = resolve;
            /**
             * in case custom proxy script failed to load it will resolve this promise
             * and intialize firebase Analytics with default analytics script
             * */
            script.onerror = (err) => {
                logger.error('Error loading custom proxy script: ', err);
                resolve();
            };
        });
    }
    async loadCustomAnalytics() {
        if (this.loaded) {
            return true;
        }
        const TAG_ID = 'airmeetTag';
        // https://www.googletagmanager.com/gtag/js?id=<MeasurementID>&l=dataLayer
        const tagsPath = `https://ga-proxy.airmeet.com/airmeet-tagjs?id=${MEASUREMENT_ID}&l=dataLayer`;

        let tasks = [this.loadScript(tagsPath, TAG_ID)];
        await Promise.all(tasks);
        this.analytics = firebase.analytics(this.app);
        this.loaded = true;
        return true;
    }

    captureAppcuesEvents(eventName, eventData = {}) {
        const isOrganizer = localStorage.getItem('isOrganiser');
        if (isOrganizer && window.Appcues) {
            window.Appcues.track(eventName, eventData);
        }
    }

    addlogEvent(eventType, eventData = {}) {
        const params = { ...this._defaultEventData, ...eventData };
        params['timestamp'] = momenttz.tz('Asia/Kolkata').format('HH:mm:ss');
        params['timestamp_utc'] = momenttz.tz('Asia/Kolkata').unix();
        params['journeyID'] = this.userJourneyID;
        params['device_type'] = deviceType || 'desktop';
        params['airmeet_preview_mode'] = this._enablePreviewMode;
        params['is_cloud_user'] = PermissionUtils.isEventCloudHost();
        this.checkProxyRequired()
            .then(() => {
                return this.loadCustomAnalytics();
            })
            .then(() => {
                this.analytics.logEvent(eventType, params);
                logger.logEvent(eventType, params);
            });
    }

    getDefaultEventData() {
        return this._defaultEventData;
    }

    setDefaultEventData(defaultData) {
        this._defaultEventData = { ...this._defaultEventData, ...defaultData };
        return this;
    }
}

if (!window.EventAnalyticsLogger) {
    window.EventAnalyticsLogger = new FirebaseLogger();
}
const Adapter = window.EventAnalyticsLogger;

// User device information
const parser = new UAParser();
const {
    browser: { name: browserName } = {},
    device: { type: deviceType } = {},
    os: { name: osName } = {},
} = parser.getResult();

export default {
    enableCustomProxy(enabled) {
        Adapter.enableCustomProxy(enabled);
    },

    enablePreviewMode(enabled) {
        Adapter.enablePreviewMode(enabled);
    },

    setCommonData(data) {
        Adapter.setDefaultEventData(data);
    },

    //regestration event
    //regestration start
    // regStart(utm_source){
    //     Adapter.addlogEvent(EventAnalyticsLoggerTypes.regStart, {utm_source:utm_source});
    // },
    //even triggered when user logs in
    oauthCompleted(id, airmeet_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.regOauthDone, {
            attendee_id: id,
            airmeet_id: airmeet_id,
        });
    },
    //regestration updated nametag
    updateUser(user, tags) {
        const tag = tags.toString();
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.regUpdateNametag, {
            city: user.city,
            country: user.country,
            company: user.company,
            designation: user.designation,
            uid: user.id,
            tags: tag,
        });
    },
    //Add to my calender
    addedToCalender(userid, airmeetId) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.calAddClicked, {
            attendee_id: userid,
            airmeetId: airmeetId,
        });
    },
    //feedback modal popup state
    platformFeedbackModalShown(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.platformFeedback.feedbackModalShown,
            data
        );
    },
    //Registeration complete
    registrationComplete(userid, airmeet_id, email_id = null, autoReg = false) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.regComplete, {
            attendee_id: userid,
            airmeet_id: airmeet_id,
            url_for_utm: window.location.href,
            utms: getUrlParams(),
            LBActionCode: EVENT_REGISTER,
            LBEntityType: EVENT,
            LBEntityId: airmeet_id,
            LBAirmeetId: airmeet_id,
            LBUserID: userid,
            email_id,
            autoReg,
        });
    },
    userRegUtm(
        userid,
        airmeet_id,
        email_id = null,
        autoReg = false,
        eid = null,
        flow = '',
        customHref = '',
        customUtms = null
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.userRegUtm, {
            attendee_id: userid,
            airmeet_id: airmeet_id,
            url_for_utm: customHref || window.location.href,
            utms: customUtms || getUrlParams(),
            LBActionCode: EVENT_REGISTER,
            LBEntityType: EVENT,
            LBEntityId: airmeet_id,
            LBAirmeetId: airmeet_id,
            LBUserID: userid,
            email_id,
            autoReg,
            user_eid: eid,
            flow,
            event_name: EventAnalyticsLoggerTypes.userRegUtm,
        });
    },
    autoRegistrationComplete(userid, airmeet_id, email_id = null) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.autoRegComplete, {
            attendee_id: userid,
            airmeet_id: airmeet_id,
            url_for_utm: window.location.href,
            utms: getUrlParams(),
            LBActionCode: EVENT_REGISTER,
            LBEntityType: EVENT,
            LBEntityId: airmeet_id,
            LBAirmeetId: airmeet_id,
            LBUserID: userid,
            email_id,
        });
    },
    //host sign in
    hostSignIn(event, data) {
        Adapter.addlogEvent(event, {
            ...data,
        });
    },
    initiateAuthorization(source) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.initiateAuth, {
            source: source,
        });
    },
    authClicked(authType) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.clickedAuth, {
            auth_type: authType,
        });
    },
    asClickedOauth(payload) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.asClickedOauth, payload);
    },

    signUpComplete(source, auth_type, user_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.signUpSuccess, {
            source: source,
            auth_type: auth_type,
            email_id: user_id,
        });
    },
    signUpButtonClicked() {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.signUpClick, {});
    },
    loginSucess(source, auth_type, user_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.loginSucess, {
            source: source,
            auth_type: auth_type,
            email_id: user_id,
        });
    },
    asUserLoggedIn(payload) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.asUserLoggedIn, payload);
    },

    loginButtonClicked(emailId) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.loginButtonClicked, {
            email_id: emailId,
        });
    },
    logoutClicked(user_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.logoutClicked, {
            email_id: user_id,
        });
    },
    guestLoginForAnEvent(name_card_detail) {
        const { name, company, designation, city, country } = name_card_detail;
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.guestLogin, {
            name,
            company,
            designation,
            city,
            country,
        });
    },
    forgetPassword(user_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.forgetPassword, {
            email_id: user_id,
        });
    },
    resendOtpClicked(emailId) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.resendOtpClicked, {
            email_id: emailId,
        });
    },
    captureOtpSignup(source, emailId, authType) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.captureOtpSignup, {
            email_id: emailId,
            source: source,
            auth_type: authType,
        });
    },

    captureOtpSignin(source, emailId) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.captureOtpSignin, {
            email_id: emailId,
            source: source,
        });
    },

    signupAppleEmailLinkError(authType) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.signupAppleEmailLinkError,
            {
                auth_type: authType,
            }
        );
    },
    backbuttonClicked(emailId, step) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.backbuttonClicked, {
            email_id: emailId,
            step: step,
        });
    },
    capturedEmailId(emailId, source, authType, userType) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.capturedEmailId, {
            email_id: emailId,
            source: source,
            auth_type: authType,
            user_type: userType,
        });
    },
    clickedOauthContinue(emailId, userId, emailNewsletterStatus) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.clickedOauthContinue, {
            email_id: emailId,
            uid: userId,
            email_newsletter_status: emailNewsletterStatus,
        });
    },
    asClickedContinueOnEmail(payload) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.asClickedContinueOnEmail,
            payload
        );
    },
    asNewSignup(payload) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.asNewSignup, payload);
    },

    asSubmitOTP(payload) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.asSubmitOTP, payload);
    },
    asClickedSignupCreateAccountPage(payload) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.asClickedSignupCreateAccountPage,
            payload
        );
    },

    // Community Team Member Invite
    inviteTeamMemberButtonClicked(communityId, userId) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inviteTeamMemberButtonClicked,
            {
                community_id: communityId,
                uid: userId,
            }
        );
    },

    invitationSent(communityId, userId, invitedUserEmailId) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.invitationSent, {
            community_id: communityId,
            uid: userId,
            email_id: invitedUserEmailId,
        });
    },
    invitationAccepted(communityId, userId, invitedUserEmailId) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.invitationAccepted, {
            community_id: communityId,
            uid: userId,
            email_id: invitedUserEmailId,
        });
    },
    invitationAcceptanceError(communityId, invitedUserEmailId, errorMsg) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.invitationAcceptanceError,
            {
                community_id: communityId,
                email_id: invitedUserEmailId,
                error_msg: errorMsg,
            }
        );
    },
    //pricing page
    onClickPricingBtn(type, email_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.pricing.pricingBtnClick, {
            type,
            email_id,
        });
    },
    onClickGetStartedForFree() {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.pricing.getStartedForFree,
            {}
        );
    },
    bookCallBtn(type, email_id) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.pricing.bookCallBtnClick,
            {
                type,
                email_id,
            }
        );
    },
    // summit UI banner
    onCLickPreviewBtn(email_id, community_id, community_name) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.summitUIBanner.sneakPreviewBtn,
            {
                email_id,
                community_id,
                community_name,
            }
        );
    },
    onClickCrossBtn() {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.summitUIBanner.cancelBtn,
            {}
        );
    },

    //LIve session
    sessionStart(
        airmeet_id,
        session_id,
        attendee_count,
        connectedViaProxy,
        sessionType
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.sessionStart, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            attendee_count: attendee_count,
            connected_via_proxy: connectedViaProxy,
            airmeet_session_type: sessionType,
        });
    },

    sessionBreak(airmeet_id, session_id, attendee_count) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.sessionBreak, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            attendee_count: attendee_count,
        });
    },

    sessionResume(airmeet_id, session_id, attendee_count) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.sessionResume, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            attendee_count: attendee_count,
        });
    },

    sessionEnd(airmeet_id, session_id, attendee_count, sessionType) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.sessionEnd, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            attendee_count: attendee_count,
            airmeet_session_type: sessionType,
        });
    },

    recordedSessionVideoPlayed() {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.recordedSessionVideoPlayed
        );
    },

    recordedSessionVideoSearched() {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.recordedSessionVideoSearched
        );
    },

    attendeeJoinedSession({
        attendeeId,
        session_id,
        session_name,
        userRole,
        userStageRole,
        sessionVisitId,
        hasTrackAdded,
        data = {},
    }) {
        const contextData = {
            context_visit_id: sessionVisitId,
            context_id: session_id,
            context_name: 'SESSION',
            context_action_type: 'JOIN',
        };
        const isIncludedForLB =
            data.session_type &&
            data.session_type !== 'SPEED_NETWORKING' &&
            data.session_type !== 'FLUID_LOUNGE';
        const leaderboardSessionData = isIncludedForLB
            ? {
                  LBActionCode: ENTER_SESSION,
                  LBEntityType: SESSION,
                  LBEntityId: session_id,
                  LBAirmeetId: Adapter.getDefaultEventData().airmeet_id,
                  LBUserID: attendeeId,
              }
            : {};
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.attendeeJoinedSession, {
            attendee_id: attendeeId,
            airmeet_session_id: session_id,
            session_name: session_name,
            ...contextData,
            user_role: userRole,
            userStageRole,
            session_visit_id: sessionVisitId,
            type: hasTrackAdded
                ? EventAnalyticsLoggerTypes.airmeetEventType.track
                : EventAnalyticsLoggerTypes.airmeetEventType.nonTrack,
            ...data,
            ...leaderboardSessionData,
        });
    },
    sessionProgress(airmeet_id, session_id, attendee_count) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.sessionProgress, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            attendee_count: attendee_count,
        });
    },
    attendeeExitedSession({
        attendeeId,
        session_id,
        userRole,
        userStageRole,
        sessionVisitId,
        hasTrackAdded,
        data = {},
    }) {
        const contextData = {
            context_visit_id: sessionVisitId,
            context_id: session_id,
            context_name: 'SESSION',
            context_action_type: 'LEAVE',
        };
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.attendeeExitedSession, {
            attendee_id: attendeeId,
            airmeet_session_id: session_id,
            ...contextData,
            user_role: userRole,
            userStageRole,
            session_visit_id: sessionVisitId,
            type: hasTrackAdded
                ? EventAnalyticsLoggerTypes.airmeetEventType.track
                : EventAnalyticsLoggerTypes.airmeetEventType.nonTrack,
            ...data,
        });
    },

    //chat event
    chatMessage(airmeet_id, session_id, attendee_id, is_reply) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.chatMessage, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            attendee_id: attendee_id,
            LBActionCode: POST_EVENT_FEED,
            LBEntityType: EVENT,
            LBEntityId: airmeet_id,
            LBAirmeetId: airmeet_id,
            LBUserID: attendee_id,
            is_reply: is_reply,
        });
    },

    postSessionFeedMessage(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.postSessionFeedMessage, {
            ...data,
        });
    },

    //gifs_icon_clicked gifs_searched gifs_posted gifs_attendees_used_gifs
    gifIconClicked({ airmeet_id, user_id, user_role }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.gifIconClicked, {
            airmeet_id: airmeet_id,
            user_id: user_id,
            user_role: user_role,
        });
    },

    gifPosted(
        airmeet_id,
        sessionId,
        user_id,
        user_role,
        gif_id,
        gif_title,
        is_reply,
        timestamp
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.gifPosted, {
            airmeet_id,
            airmeet_session_id: sessionId,
            user_id,
            gif_id,
            gif_title,
            user_role,
            is_reply,
            timestamp,
        });
    },

    gifSearched({ airmeet_id, user_id, user_role, term }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.gifSearched, {
            airmeet_id,
            user_id,
            user_role,
            term,
        });
    },

    gifDeleted({ airmeet_id, user_id, gif_id, gif_title, user_role }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.gifDeleted, {
            airmeet_id,
            user_id,
            gif_id,
            gif_title,
            user_role,
        });
    },

    reportMessage({
        airmeetId,
        airmeetSessionId,
        reportingAttendeeId,
        reportedAttendeeId,
        messageText,
    }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.reportMessage, {
            airmeet_id: airmeetId,
            airmeet_session_id: airmeetSessionId,
            reporting_attendee_id: reportingAttendeeId,
            reported_attendee_id: reportedAttendeeId,
            message_text: messageText,
        });
    },

    removeMessage({
        airmeetId,
        airmeetSessionId,
        attendeeId,
        messageText,
        fromPlace,
    }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.removeMessage, {
            airmeet_id: airmeetId,
            airmeet_session_id: airmeetSessionId,
            attendee_id: attendeeId,
            message_text: messageText,
            remove_post_btn_location: fromPlace,
        });
    },

    // qna comments
    qnaCommentsStatusChange(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.qna_disabled_enabled, {
            ...data,
        });
    },
    onAddingNewComment(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.qna_comments_added, {
            ...data,
        });
    },
    qnaCommentMarkedAsAnswer(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.qna_comments_marked_as_answer,
            {
                ...data,
            }
        );
    },
    qnaCommentDeleted(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.qna_comments_deleted, {
            ...data,
        });
    },

    // chat reaction
    addChatReaction(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.chatReactionAdded, {
            ...data,
        });
    },
    removeChatReaction(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.chatReactionRemoved, {
            ...data,
        });
    },

    //direct message
    directMessage(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.directMessage, {
            ...data,
        });
    },

    //direct message notification click
    directMessageNotificationOnTabClick(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.directMessageNotificationOnTabClick,
            {
                ...data,
            }
        );
    },

    directMessageThreadClick(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.directMessageThreadClick,
            {
                ...data,
            }
        );
    },

    //direct message replied
    directMessageReplied(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.directMessageReplied, {
            LBActionCode: CHAT_REPLY,
            LBEntityType: ATTENDEE,
            LBEntityId: data.attendee2_id,
            LBAirmeetId: Adapter.getDefaultEventData().airmeet_id,
            LBUserID: data.attendee1_id,
            ...data,
        });
    },

    helpWidget(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.helpWidget.helpWidgetOpened,
            {
                ...data,
            }
        );
    },

    //accept request
    acceptRequest(airmeet_id, attendee1_id, attendee2_id, room_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.acceptRequest, {
            airmeet_id: airmeet_id,
            sender_id: attendee1_id,
            receiver_id: attendee2_id,
            room_id: room_id,
        });
    },
    //decline request
    declineRequest(airmeet_id, attendee1_id, attendee2_id, room_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.declineRequest, {
            airmeet_id: airmeet_id,
            sender_id: attendee1_id,
            receiver_id: attendee2_id,
            room_id: room_id,
        });
    },

    //block request
    blockRequest(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.blockRequest, {
            ...data,
        });
    },

    //unblock request
    unblockRequest(airmeet_id, attendee1_id, attendee2_id, room_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.unblockRequest, {
            airmeet_id: airmeet_id,
            sender_id: attendee1_id,
            receiver_id: attendee2_id,
            room_id: room_id,
        });
    },
    directTabRequest(
        airmeet_id,
        session_id,
        community_id,
        community_name,
        status,
        user_role,
        notification
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.directTabRequest, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            community_id: community_id,
            community_name: community_name,
            session: status,
            user_role: user_role,
            notification: notification,
        });
    },
    messageRequest(
        airmeet_id,
        session_id,
        community_id,
        community_name,
        status,
        user_role,
        notification
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.messageRequest, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            community_id: community_id,
            community_name: community_name,
            session: status,
            user_role: user_role,
            notification: notification,
        });
    },

    //create room
    //decline request
    createRoom(airmeet_id, attendee1_id, attendee2_id, room_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.createRoom, {
            airmeet_id: airmeet_id,
            sender_id: attendee1_id,
            receiver_id: attendee2_id,
            room_id: room_id,
            LBActionCode: DM_INITIATE,
            LBEntityType: ATTENDEE,
            LBEntityId: airmeet_id,
            LBAirmeetId: airmeet_id,
            LBUserID: attendee1_id,
        });
    },

    pinnedMessage({
        community_id,
        airmeet_id,
        user_id,
        user_role,
        action,
        chat_type,
        event_type,
        chat_message,
    }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.pinnedMessage, {
            community_id,
            airmeet_id,
            user_id,
            user_role,
            action,
            chat_type,
            event_type,
            chat_message,
        });
    },

    //emoji event emoji_sent emoji_clicked
    emojiClicked(airmeet_id, session_id, attendee_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.emojiClicked, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            attendee_id: attendee_id,
        });
    },

    emojiSent(airmeet_id, session_id, attendee_id, emoji_type, input_device) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.emojiSent, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            attendee_id: attendee_id,
            emoji_type: emoji_type,
            input_device: input_device,
            LBActionCode: EMOJI_EXPRESS,
            LBEntityType: SESSION,
            LBEntityId: session_id,
            LBAirmeetId: airmeet_id,
            LBUserID: attendee_id,
        });
    },

    // Raise hand Notifications

    raiseHandNotificationClicked() {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.raiseHandNotification.notificationClicked
        );
    },

    raiseHandNotificationDismissed() {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.raiseHandNotification.dismissed
        );
    },

    joinSessionPromptShown(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.trackEvents.sessionJoinPrompt,
            data
        );
    },

    sessionJoinedViaPrompt(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.trackEvents
                .sessionAttendeeJoinedViaPrompt,
            data
        );
    },

    trackFilterClicked(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.trackEvents.trackFilterClicked,
            data
        );
    },

    stageUserCountChanged(totalCount, raiseHandUsers, isLive) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.stageUserCountChanged, {
            stage_user_count: totalCount,
            raise_hand_user_count: raiseHandUsers,
            is_live: isLive,
        });
    },

    // Breakout
    breakout(event, data = {}) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.breakoutEvents[event], {
            ...data,
        });
    },

    ctaBanner(event, data = {}) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.ctaBanner[event], {
            ...data,
        });
    },

    breakoutRandomStart(data = {}) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.breakoutEvents.randomStart,
            {
                ...data,
            }
        );
        Adapter.addlogEvent(
            data?.discoverable
                ? EventAnalyticsLoggerTypes.breakoutEvents
                      .randomDiscoverableTrue
                : EventAnalyticsLoggerTypes.breakoutEvents
                      .randomDiscoverableFalse,
            {
                ...data,
            }
        );
    },

    breakoutCsvStart(data = {}) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.breakoutEvents.csvStart, {
            ...data,
        });
        Adapter.addlogEvent(
            data?.discoverable
                ? EventAnalyticsLoggerTypes.breakoutEvents.csvDiscoverableTrue
                : EventAnalyticsLoggerTypes.breakoutEvents.csvDiscoverableFalse,
            {
                ...data,
            }
        );
    },

    //backstage event
    backstageJoined(
        airmeet_id,
        session_id,
        session_status,
        user_role,
        connectedViaProxy
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.backstageJoined, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            session_status: session_status,
            user_role: user_role,
            connected_via_proxy: connectedViaProxy,
        });
    },

    backstageExit(airmeet_id, session_id, session_status, user_role) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.backstageExit, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            session_status: session_status,
            user_role: user_role,
        });
    },

    //table chat
    localChatMessage(airmeet_id, attendee_id, session_id, channel_name) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.localChatMessage, {
            airmeet_id: airmeet_id,
            attendee_id: attendee_id,
            airmeet_session_id: session_id,
            channel_name: channel_name,
        });
    },

    //Hand Raise event
    raiseHandRequest(sessionId, userid, airmeetId, sessionName) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.raiseHandRequest, {
            airmeet_session_id: sessionId,
            attendee_id: userid,
            airmeet_id: airmeetId,
            session_name: sessionName,
            LBActionCode: SESSION_RAISE_HAND,
            LBEntityType: SESSION,
            LBEntityId: sessionId,
            LBAirmeetId: airmeetId,
            LBUserID: userid,
        });
    },

    raiseHandAccepted(
        sessionId,
        userid,
        airmeetId,
        connectedViaProxy,
        request_user_id,
        sessionName
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.raiseHandAccepted, {
            airmeet_session_id: sessionId,
            attendee_id: request_user_id,
            airmeet_id: airmeetId,
            connected_via_proxy: connectedViaProxy,
            session_name: sessionName,
            LBActionCode: SESSION_RAISE_HAND_ACCEPT,
            LBEntityType: SESSION,
            LBEntityId: sessionId,
            LBAirmeetId: airmeetId,
            LBUserID: request_user_id,
        });
    },

    //live session exit button clicked
    liveSessionExitClicked(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.liveSessionExitClicked,
            data
        );
    },

    //enter full screen
    fullScreen(airmeet_id, sessionId, userid, additionalParams) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.fullScreen, {
            airmeet_id: airmeet_id,
            sessionId: sessionId,
            attendee_id: userid,
            browser: browserName,
            ...additionalParams,
        });
    },

    //On editing welcome message
    EMeditWelcomeMessage(airmeet_id, session_id, attendee_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.EMeditWelcomeMessage, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            attendee_id: attendee_id,
        });
    },

    //audio video setting button is clicked
    participantSettingsCheck(airmeet_id, session_id, attendee_id) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.participantSettingsCheck,
            {
                airmeet_id: airmeet_id,
                airmeet_session_id: session_id,
                attendee_id: attendee_id,
            }
        );
    },
    //audio video setting button is saved
    participantSettingsSave(airmeet_id, session_id, attendee_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.participantSettingsSave, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            attendee_id: attendee_id,
        });
    },
    //on opening Native popUp
    browserPermissionsProvideAccessClick(sessionId, userid) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.browserPermissionsProvideAccessClick,
            {
                sessionId: sessionId,
                uid: userid,
            }
        );
    },
    //on opening Info popUp
    browserPermissionsHelpPopup(sessionId, userid) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.browserPermissionsHelpPopup,
            { sessionId: sessionId, uid: userid }
        );
    },
    //Permission not granted
    browserPermissionsImpression(sessionId, userid) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.browserPermissionsImpression,
            { sessionId: sessionId, uid: userid }
        );
    },
    //host muted speaker/attendee(users live/backtage) media:{audio, video and screenshare} in a live session or on backstage
    hostRequestedAction(action, airmeetId, attendeeId, sessionId, userRole) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.hostRequestedActionOnStage[action],
            { airmeetId, attendeeId, sessionId, userRole }
        );
    },

    // Recieved the mute action requested by host
    receivedHostRequestedAction(
        mediaType,
        airmeetId,
        attendeeId,
        sessionId,
        mutedUserRole
    ) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.receivedHostRequestedAction[mediaType],
            { airmeetId, attendeeId, sessionId, mutedUserRole }
        );
    },

    //Toggling light mode popup
    switchingBetweenLiteModePopup(
        state,
        airmeetId,
        sessionId,
        userid,
        communityName,
        communityId
    ) {
        const parser = new UAParser();
        const browser = parser.getBrowser().name;
        const deviceType = parser.getDevice().type;
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.switchingBetweenLiteModePopup,
            {
                state,
                airmeet_id: airmeetId,
                airmeet_session_id: sessionId,
                attendee_id: userid,
                browser,
                deviceType: deviceType || 'desktop',
                community_name: communityName,
                community_id: communityId,
            }
        );
    },
    //Toggling light mode popup
    switchingBetweenAboutLiteModeTooltip(
        state,
        airmeetId,
        sessionId,
        userid,
        communityName,
        communityId
    ) {
        const parser = new UAParser();
        const browser = parser.getBrowser().name;
        const deviceType = parser.getDevice().type;
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.switchingBetweenAboutLiteModeTooltip,
            {
                state,
                airmeet_id: airmeetId,
                airmeet_session_id: sessionId,
                attendee_id: userid,
                browser,
                deviceType: deviceType || 'desktop',
                community_name: communityName,
                community_id: communityId,
            }
        );
    },
    //Toggling light mode state
    switchingBetweenLiteMode(convertedMode, airmeetId, sessionId, userid) {
        const parser = new UAParser();
        const browser = parser.getBrowser().name;
        const deviceType = parser.getDevice().type;
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.switchingBetweenLiteMode,
            {
                mode: convertedMode,
                airmeet_id: airmeetId,
                airmeet_session_id: sessionId,
                attendee_id: userid,
                browser,
                deviceType: deviceType || 'desktop',
            }
        );
    },

    //inviting to stage
    inviteToStage(airmeetId, session_id, userid, sourceOfEvent = 'RHS') {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inviteToStage.inviteSent,
            {
                airmeet_id: airmeetId,
                airmeet_session_id: session_id,
                invited_attendee_id: userid,
                source: sourceOfEvent,
            }
        );
    },

    inviteAccepted(airmeetId, session_id, session_name, userid) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inviteToStage.inviteAccepted,
            {
                airmeet_id: airmeetId,
                airmeet_session_id: session_id,
                session_name: session_name,
                invited_attendee_id: userid,
            }
        );
    },

    inviteRejected(airmeetId, session_id, userid) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inviteToStage.inviteRejected,
            {
                airmeet_id: airmeetId,
                airmeet_session_id: session_id,
                invited_attendee_id: userid,
            }
        );
    },

    inviteWithdrawn(airmeetId, session_id, userid) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inviteToStage.inviteWithdrawn,
            {
                airmeet_id: airmeetId,
                airmeet_session_id: session_id,
                invited_attendee_id: userid,
            }
        );
    },

    inviteJoinNow({ sessionId, senderId, sessionName }) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inviteToStage.inviteJoinNow,
            {
                invite_session_id: sessionId,
                sender_id: senderId,
                session_name: sessionName,
            }
        );
    },

    inviteJoinLater({ sessionId, senderId }) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inviteToStage.inviteJoinLater,
            {
                invite_session_id: sessionId,
                sender_id: senderId,
            }
        );
    },

    //Social Lounge events
    //Join table
    attendeeJoinedTable({
        table_id,
        lounge_id,
        lounge_name,
        sourceOfTableJoin,
        meta_data,
    }) {
        const isIncludedForLB = lounge_name === 'booth';
        const leaderboardTableData = isIncludedForLB
            ? {
                  LBActionCode: lounge_name === 'booth' && BOOTH_TABLE_JOIN,
                  LBEntityType: lounge_name === 'booth' && BOOTH,
                  LBEntityId: lounge_id,
                  LBAirmeetId: Adapter.getDefaultEventData().airmeet_id,
                  LBUserID: Adapter.getDefaultEventData().attendee_id,
              }
            : {};
        const contextData = {
            context_visit_id: meta_data?.table_visit_id,
            context_id: table_id,
            context_name: getContextName(lounge_name),
            context_action_type: 'JOIN',
        };

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.attendeeJoinedTable, {
            table_id: table_id,
            ...contextData,
            lounge_id,
            lounge_name,
            source_of_table_join: sourceOfTableJoin,
            ...leaderboardTableData,
            ...meta_data,
        });
    },

    //Leave table
    attendeeLeftTable({ table_id, lounge_id, lounge_name, source, meta_data }) {
        const contextData = {
            context_visit_id: meta_data?.table_visit_id,
            context_id: table_id,
            context_name: getContextName(lounge_name),
            context_action_type: 'LEAVE',
        };

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.attendeeLeftTable, {
            table_id: table_id,
            ...contextData,
            lounge_id,
            lounge_name,
            source,
            ...meta_data,
        });
    },

    //Interaction started
    attendeeInteractionStart(
        airmeet_id,
        attendee_id,
        session_id,
        table_id,
        other_attendee_ids,
        session_status,
        lounge_name,
        loungeId
    ) {
        const isIncludedForLB = [
            BOOTH_LOUNGE_NAME,
            SOCIAL_LOUNGE_NAME,
        ].includes(lounge_name);
        const leaderboardTableData = isIncludedForLB
            ? {
                  LBActionCode:
                      lounge_name === BOOTH_LOUNGE_NAME
                          ? BOOTH_TABLE_JOIN
                          : TABLE_INTERACTION,
                  LBEntityType:
                      lounge_name === BOOTH_LOUNGE_NAME ? BOOTH : ATTENDEE,
                  LBEntityId: loungeId,
                  LBAirmeetId: airmeet_id,
                  LBUserID: attendee_id,
              }
            : {};
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.attendeeInteractionStart,
            {
                airmeet_id: airmeet_id,
                attendee_id: attendee_id,
                airmeet_session_id: session_id,
                table_id: table_id,
                other_attendee_ids: other_attendee_ids,
                session_status: session_status,
                ...leaderboardTableData,
            }
        );
    },
    //audio video event on table

    attendeeInteractedAudio(
        airmeet_id,
        attendee_id,
        session_id,
        table_id,
        other_attendee_ids,
        is_minimized,
        session_status
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.attendeeInteractedAudio, {
            airmeet_id: airmeet_id,
            attendee_id: attendee_id,
            airmeet_session_id: session_id,
            table_id: table_id,
            other_attendee_ids: other_attendee_ids,
            is_minimized,
            session_status: session_status,
        });
    },

    attendeeInteractedVideo(
        airmeet_id,
        attendee_id,
        session_id,
        table_id,
        other_attendee_ids,
        is_minimized,
        session_status
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.attendeeInteractedVideo, {
            airmeet_id: airmeet_id,
            attendee_id: attendee_id,
            airmeet_session_id: session_id,
            table_id: table_id,
            other_attendee_ids: other_attendee_ids,
            is_minimized,
            session_status: session_status,
        });
    },

    attendeeMutedAudio(
        airmeet_id,
        attendee_id,
        session_id,
        table_id,
        other_attendee_ids,
        is_minimized,
        session_status
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.attendeeMutedAudio, {
            airmeet_id: airmeet_id,
            attendee_id: attendee_id,
            airmeet_session_id: session_id,
            table_id: table_id,
            other_attendee_ids: other_attendee_ids,
            is_minimized,
            session_status: session_status,
        });
    },

    attendeeMutedVideo(
        airmeet_id,
        attendee_id,
        session_id,
        table_id,
        other_attendee_ids,
        is_minimized,
        session_status
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.attendeeMutedVideo, {
            airmeet_id: airmeet_id,
            attendee_id: attendee_id,
            airmeet_session_id: session_id,
            table_id: table_id,
            other_attendee_ids: other_attendee_ids,
            is_minimized,
            session_status: session_status,
        });
    },

    // table moderation events
    attendeeAudioMutedByOtherUser(
        airmeet_id,
        attendee_id,
        attendee_user_role,
        session_id,
        table_id,
        other_attendee_ids,
        is_minimized,
        session_status,
        who_user_id,
        who_user_role,
        mute_happened_from
    ) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.attendeeAudioMutedByOtherUser,
            {
                airmeet_id: airmeet_id,
                attendee_id: attendee_id,
                attendee_user_role: attendee_user_role,
                airmeet_session_id: session_id,
                table_id: table_id,
                other_attendee_ids: other_attendee_ids,
                is_minimized,
                session_status: session_status,
                who_user_id: who_user_id,
                who_user_role: who_user_role,
                mute_happened_from,
            }
        );
    },

    attendeeVideoMutedByOtherUser(
        airmeet_id,
        attendee_id,
        attendee_user_role,
        session_id,
        table_id,
        other_attendee_ids,
        is_minimized,
        session_status,
        who_user_id,
        who_user_role,
        mute_happened_from
    ) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.attendeeVideoMutedByOtherUser,
            {
                airmeet_id: airmeet_id,
                attendee_id: attendee_id,
                attendee_user_role: attendee_user_role,
                airmeet_session_id: session_id,
                table_id: table_id,
                other_attendee_ids: other_attendee_ids,
                is_minimized,
                session_status: session_status,
                who_user_id: who_user_id,
                who_user_role: who_user_role,
                mute_happened_from: mute_happened_from,
            }
        );
    },
    //Interaction ended
    attendeeInteractionEnd(airmeet_id, attendee_id, session_id, table_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.attendeeInteractionEnd, {
            airmeet_id: airmeet_id,
            attendee_id: attendee_id,
            airmeet_session_id: session_id,
            table_id: table_id,
        });
    },

    //screenshare on tabe
    attendeeTableScreenshare(
        airmeet_id,
        attendee_id,
        session_id,
        table_id,
        connectedViaProxy
    ) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.attendeeTableScreenshare,
            {
                airmeet_id: airmeet_id,
                attendee_id: attendee_id,
                airmeet_session_id: session_id,
                table_id: table_id,
                connected_via_proxy: connectedViaProxy,
            }
        );
    },

    //Attendee table switch
    attendeeTableSwitch({
        table_id1,
        table_id2,
        lounge_id,
        lounge_name,
        meta_data,
    }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.attendeeTableSwitch, {
            old_table_id: table_id1,
            new_table_id: table_id2,
            lounge_id,
            lounge_name,
            ...meta_data,
        });
    },

    //minimize table
    attendeeTableMinimized(
        airmeet_id,
        lounge_id,
        attendee_id,
        table_id,
        member_count
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.attendeeTableMinimized, {
            airmeet_id,
            lounge_id,
            attendee_id,
            table_id,
            member_count,
        });
    },

    attendeeTableRestored(
        airmeet_id,
        lounge_id,
        attendee_id,
        table_id,
        member_count
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.attendeeTableRestored, {
            airmeet_id,
            lounge_id,
            attendee_id,
            table_id,
            member_count,
        });
    },

    //Exit airmeet button click
    ExitAirmeetButtonClick(airmeet_id, attendee_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.ExitAirmeetButtonClick, {
            airmeet_id: airmeet_id,
            attendee_id: attendee_id,
        });
    },

    //table edit
    EMeditTable(
        airmeet_id,
        session_id,
        attendee_id,
        table_id,
        tabletitle,
        ImgError,
        Table_fixed,
        fixed,
        Imageurl
    ) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.EMeditTable, {
            airmeet_id: airmeet_id,
            airmeet_session_id: session_id,
            attendee_id: attendee_id,
            table_id: table_id,
            tabletitle: tabletitle,
            imgError: ImgError,
            table_fixed: Table_fixed,
            fixed: fixed,
            Imageurl: Imageurl,
        });
    },

    EMeditTableV2({
        airmeet_id,
        lounge_id,
        session_id,
        user_id,
        user_role,
        table_id,
        table_title,
        image_added,
        participants_allowed,
        table_type,
        timestamp,
    }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.EMeditTable, {
            airmeet_id,
            lounge_id,
            session_id,
            user_id,
            user_role,
            table_id,
            table_title,
            image_added,
            participants_allowed,
            table_type,
            timestamp,
        });
    },

    // custom media (video player) playback events
    customMediaPlayback(
        type,
        airmeetId,
        sessionId,
        videoUrl,
        connectedViaProxy
    ) {
        const payload = {
            airmeetId,
            sessionId,
            videoUrl,
        };
        if (type === 'videoStarted') {
            payload.connected_via_proxy = connectedViaProxy;
        }

        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.customMedia[type],
            payload
        );
    },
    // custom media layout change (media mode/speaker mode)
    customMediaLayoutChange(newLayout, oldLayout) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.customMedia.layoutChanged,
            {
                newLayout,
                oldLayout,
            }
        );
    },

    //custom media selected
    customMediaSelected(payload) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.customMedia.videoSelected,
            payload
        );
    },

    //custom media selected
    customMediaUnSelected(payload) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.customMedia.videoUnSelected,
            payload
        );
    },
    //to save users feedback
    platformFeedback(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.platformFeedback.logUsersRating,
            data
        );
    },

    platformFeedbackSkipped(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.platformFeedback.feedbackSkipped,
            data
        );
    },
    //Product Tour started
    productTourStarted(userId, userRole, airmeetId, tourType, sessionId) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.productTourStarted, {
            attendee_id: userId,
            user_role: userRole,
            airmeet_id: airmeetId,
            tour_type: tourType,
            airmeet_session_id: sessionId,
        });
    },
    //Product Tour skipped
    productTourSkipped(userId, userRole, airmeetId, tourType, sessionId) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.productTourSkipped, {
            attendee_id: userId,
            user_role: userRole,
            airmeet_id: airmeetId,
            tour_type: tourType,
            airmeet_session_id: sessionId,
        });
    },
    //Product Tour completed
    productTourCompleted(userId, userRole, airmeetId, tourType, sessionId) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.productTourCompleted, {
            attendee_id: userId,
            user_role: userRole,
            airmeet_id: airmeetId,
            tour_type: tourType,
            airmeet_session_id: sessionId,
        });
    },

    /***** Permissions Workflow Setup Start ********/
    // Pre-airmeet logs
    permissionsPageShown(user_id, user_role, airmeet_id) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.preAirmeet.permissionsPage,
            {
                attendee_id: user_id || '',
                user_role,
                airmeet_id,
            }
        );
    },

    permissionsButtonClicked(user_id, user_role, airmeet_id) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.preAirmeet.permissionsButtonClicked,
            {
                attendee_id: user_id || '',
                user_role,
                airmeet_id,
                browser: browserName,
            }
        );
    },

    setupPreviewPage(user_id, user_role, airmeet_id, permissions_page_shown) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.preAirmeet.setupPreviewPage,
            {
                attendee_id: user_id || '',
                user_role,
                airmeet_id,
                browser: browserName,
                device_type: deviceType || 'desktop',
                permissions_page_shown,
            }
        );
    },

    joinListeningMode(user_id, user_role, airmeet_id) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.preAirmeet.joinListeningMode,
            {
                attendee_id: user_id || '',
                user_role,
                airmeet_id,
                browser: browserName,
            }
        );
    },

    helpIcon(user_id, user_role, airmeet_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.preAirmeet.helpIcon, {
            attendee_id: user_id || '',
            user_role,
            airmeet_id,
            browser: browserName,
        });
    },

    joinEvent(user_id, user_role, airmeet_id, camera_on, mic_on) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.preAirmeet.joinEvent, {
            attendee_id: user_id || '',
            user_role,
            airmeet_id,
            browser: browserName,
            device_type: deviceType || 'desktop',
            camera_on,
            mic_on,
        });
    },

    wantedExit({
        attendeeId,
        airmeetId,
        airmeetVisitId,
        wantedExitId,
        sessionVisitId,
        airmeetSessionId,
    }) {
        const contextData = {
            context_visit_id: airmeetVisitId,
            context_id: airmeetId,
            context_name: 'EVENT',
            context_action_type: 'LEAVE',
        };

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.wantedExit, {
            attendee_id: attendeeId,
            airmeet_id: airmeetId,
            airmeet_visit_id: airmeetVisitId,
            ...contextData,
            wanted_exit_id: wantedExitId,
            session_visit_id: sessionVisitId,
            airmeet_session_id: airmeetSessionId,
        });
    },

    cancelledExit({
        attendeeId,
        airmeetId,
        airmeetVisitId,
        wantedExitId,
        sessionVisitId,
    }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.cancelledExit, {
            attendee_id: attendeeId,
            airmeet_id: airmeetId,
            airmeet_visit_id: airmeetVisitId,
            wanted_exit_id: wantedExitId,
            session_visit_id: sessionVisitId,
        });
    },

    enteredAirmeet({
        attendeeId,
        airmeetId,
        attendance_type,
        wasBlocked,
        blockedUserId,
        connectedViaProxy,
        airmeetVisitId,
        airmeet_name,
        ...rest
    }) {
        if (airmeetVisitId === undefined) {
            airmeetVisitId = Adapter.getDefaultEventData().airmeet_visit_id;
        }
        const contextData = {
            context_visit_id: airmeetVisitId,
            context_id: airmeetId,
            context_name: 'EVENT',
            context_action_type: 'JOIN',
        };

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.enteredAirmeet, {
            attendee_id: attendeeId,
            airmeet_id: airmeetId,
            was_blocked: wasBlocked,
            attendance_type,
            blocked_attendee_id: blockedUserId,
            connected_via_proxy: connectedViaProxy,
            airmeet_visit_id: airmeetVisitId,
            airmeet_name: airmeet_name,
            ...contextData,
            device_type: deviceType || 'desktop',
            browser: browserName,
            os: osName,
            LBActionCode: ENTER_EVENT,
            LBEntityType: EVENT,
            LBEntityId: airmeetId,
            LBAirmeetId: airmeetId,
            LBUserID: attendeeId,
            ...rest,
        });
    },

    testSetup(user_id, user_role, airmeet_id) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.preAirmeet.testSetup, {
            attendee_id: user_id || '',
            user_role,
            airmeet_id,
        });
    },
    // intro videos
    introVideosLoggerEvent(data, event) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.introVideo[event], data);
    },
    // hybrid event
    hybridEventFirebaseLogs(data, event) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.hybridEvent[event], data);
    },
    // In-airmeet logs
    inAirmeetPermissionPopupTriggered(data) {
        data.browser = browserName;
        data.device_type = deviceType || 'desktop';
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inAirmeet.permissionPopup,
            data
        );
    },

    inAirmeetPermissionButtonClicked(data) {
        data.browser = browserName;
        data.device_type = deviceType || 'desktop';
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inAirmeet.permissionButtonClicked,
            data
        );
    },

    inAirmeetPermissionPopupClosed(data) {
        data.browser = browserName;
        data.device_type = deviceType || 'desktop';
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inAirmeet.permissionPopupClosed,
            data
        );
    },

    inAirmeetSetupPreviewPopup(data) {
        data.browser = browserName;
        data.device_type = deviceType || 'desktop';
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inAirmeet.setupPreviewPopup,
            data
        );
    },

    inAirmeetMainCTAClicked(data) {
        data.browser = browserName;
        data.device_type = deviceType || 'desktop';
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inAirmeet.mainCTAClicked,
            data
        );
    },

    inAirmeetSetupCheckPopupTest(data) {
        data.browser = browserName;
        data.device_type = deviceType || 'desktop';
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inAirmeet.setupcheckPopupTest,
            data
        );
    },

    inAirmeetPermissionBlockedPopup(data) {
        data.browser = browserName;
        data.device_type = deviceType || 'desktop';
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inAirmeet.permissionBlockedPopup,
            data
        );
    },

    inAirmeetPermissionBlockedTryAgainClicked(data) {
        data.browser = browserName;
        data.device_type = deviceType || 'desktop';
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inAirmeet
                .permissionBlockedTryAgainClicked,
            data
        );
    },

    inAirmeetPermissionSkipPermission(data) {
        data.browser = browserName;
        data.device_type = deviceType || 'desktop';
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.inAirmeet.permissionSkipped,
            data
        );
    },

    /***** Permissions Workflow Setup End ********/

    /** Event Landing Page Events */
    eventUserVisitInfo(airmeet_details, source_url, lp_visit_id) {
        const {
            airmeet_name,
            airmeet_id,
            community_name,
            community_id,
            user_role,
            attendee_id,
        } = airmeet_details;
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.eventLandingPage.userVisit,
            {
                airmeet_id: airmeet_id,
                community_id: community_id,
                airmeet: airmeet_name,
                community: community_name,
                user_role: user_role,
                attendee_id: attendee_id,
                source_url: source_url,
                LP_visit_id: lp_visit_id,
                url_for_utm: window.location.href,
                utms: getUrlParams(),
            }
        );
    },
    eventUserExitInfo(airmeet_details, lp_visit_id) {
        const {
            airmeet_name,
            airmeet_id,
            community_name,
            community_id,
            user_role,
            attendee_id,
        } = airmeet_details;
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.eventLandingPage.userExit,
            {
                airmeet_id: airmeet_id,
                community_id: community_id,
                airmeet: airmeet_name,
                community: community_name,
                user_role: user_role,
                attendee_id: attendee_id,
                LP_visit_id: lp_visit_id,
            }
        );
    },
    eventSponsorClickInfo(airmeet_details, sponsor_id, sponsor_name) {
        const {
            airmeet_name,
            airmeet_id,
            community_name,
            community_id,
            user_role,
            attendee_id,
        } = airmeet_details;
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.eventLandingPage.sponsorClick,
            {
                airmeet_id: airmeet_id,
                community_id: community_id,
                airmeet: airmeet_name,
                community: community_name,
                user_role: user_role,
                attendee_id: attendee_id,
                sponsor_id: sponsor_id,
                sponsor_name: sponsor_name,
            }
        );
    },
    /** Event Landing Page Events End */

    /** Event entry tab Events
     * @param {String} airmeet_id
     * @param {String} community_id
     * @param {String} community_name
     * @param {String} community_title
     */

    eventEntryTab: (event, data) => {
        if (!event || !EventAnalyticsLoggerTypes.eventEntryTab[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.eventEntryTab[event], {
            ...data,
        });
    },

    //Dashboard events
    hostEvent() {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.hostEvent, {});
    },
    orgCreated(org, email) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.orgCreated, {
            org_name: org,
            email: email,
        });
    },
    eventCreated(org, email) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.eventCreated, {
            org_name: org,
            email: email,
        });
    },
    startAirmeet(org, email) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.startAirmeet, {
            org_name: org,
            email: email,
        });
    },
    copyLink(org, email) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.copyLink, {
            org_name: org,
            email: email,
        });
    },
    abandonedStage(org, email) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.abandonedStage, {
            active_stage: org,
            email: email,
        });
    },
    signedUp(type, method) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.signedUp, {
            type: type,
            method: method,
        });
    },
    videoLibraryEvents(event, data) {
        if (!EventAnalyticsLoggerTypes.videoLibrary[event]) {
            return;
        }
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.videoLibrary[event],
            data
        );
    },
    videoLibraryRecordingsEvents(event, data) {
        Adapter.addlogEvent(event, data);
    },

    liveStreamEvents(event, logData) {
        if (!EventAnalyticsLoggerTypes.liveStream[event]) {
            return;
        }
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.liveStream[event],
            logData
        );
    },

    streamingResolutionEvent(event, data) {
        Adapter.addlogEvent(event, { ...data });
    },

    blockUser({ userId, airmeetId, fromPlace }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.blockUser, {
            attendee_id: userId,
            airmeet_id: airmeetId,
            block_btn_location: fromPlace,
        });
    },

    unblockUser(userId, airmeetId) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.unblockUser, {
            attendee_id: userId,
            airmeet_id: airmeetId,
        });
    },

    hideUnhideUser(event, data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.hideUnhideUser[event],
            data
        );
    },

    liveSessionEvents(event, data) {
        if (!EventAnalyticsLoggerTypes.liveSession[event]) {
            return;
        }
        const parser = new UAParser();
        const browser = parser.getBrowser().name;
        const logData = {
            ...data,
            browser,
            userid: Adapter.getDefaultEventData().user_id,
        };
        if (event === 'raiseHand' && data.action === 'attendee_raised') {
            this.raiseHandRequest(
                data.airmeet_session_id,
                Adapter.getDefaultEventData().user_id,
                Adapter.getDefaultEventData().airmeet_id,
                data.session_name
            );
        } else if (event === 'raiseHand' && data.action === 'host_accepted') {
            this.raiseHandAccepted(
                data.airmeet_session_id,
                Adapter.getDefaultEventData().user_id,
                Adapter.getDefaultEventData().airmeet_id,
                data.connectViaProxy,
                data.request_user_id,
                data.session_name
            );
        } else {
            Adapter.addlogEvent(
                EventAnalyticsLoggerTypes.liveSession[event],
                logData
            );
        }
    },

    connectionStatusEvents(event, data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.connectionStatus[event],
            data
        );
    },
    incompatibleBrowserShown(airmeet_id, attendee_id, user_role, browser) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.unsupportedBrowser
                .incompatibleBrowserPageShown,
            {
                airmeet_id,
                attendee_id,
                user_role,
                browser,
            }
        );
    },
    copyAirmeetLink(airmeet_id, attendee_id, user_role, browser) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.unsupportedBrowser.copyAirmeetLink,
            {
                airmeet_id,
                attendee_id,
                user_role,
                browser,
            }
        );
    },
    continueHere(airmeet_id, attendee_id, user_role, browser) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.unsupportedBrowser.continueHere,
            {
                airmeet_id,
                attendee_id,
                user_role,
                browser,
            }
        );
    },
    downloadAppClicked(
        airmeet_id,
        attendee_id,
        user_role,
        unsupported_browser
    ) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.unsupportedBrowser.downloadAppClicked,
            {
                airmeet_id,
                attendee_id,
                user_role,
                unsupported_browser,
            }
        );
    },
    lobbyEnter(lobbyId) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.lobbyEnter, {
            lobby_id: lobbyId,
        });
    },
    lobbyExit(lobbyId) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.lobbyExit, {
            lobby_id: lobbyId,
        });
    },
    lobbyMatch(lobbyId, status, connectedViaProxy) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.lobbyMatch, {
            lobby_id: lobbyId,
            status: status,
            connected_via_proxy: connectedViaProxy,
        });
    },

    networkingSessionMatch(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.lobbyMatch, data);
    },

    extendTimer(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.extendTimer, data);
    },

    snRatingSubmitted(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.snRatingSubmitted, data);
    },

    snRatingSkipped(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.snRatingSkipped, data);
    },

    resolutionSwitchButtonClicked({
        userid,
        airmeetId,
        community_id,
        community_name,
        sessionid,
        status,
        info_tooltip_shown,
        mode,
        userRole,
    }) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.changeStreamingModeClicked,
            {
                userid: userid,
                airmeet_id: airmeetId,
                airmeet_session_id: sessionid,
                status: status,
                info_tooltip_shown: info_tooltip_shown,
                mode: mode,
                user_role: userRole,
                browserName,
                community_id,
                community_name,
            }
        );
    },

    resolutionSwitch({
        userid,
        airmeetId,
        community_id,
        community_name,
        sessionid,
        status,
        info_tooltip_shown,
        mode,
        userRole,
    }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.changeStreamingMode, {
            userid: userid,
            airmeet_id: airmeetId,
            airmeet_session_id: sessionid,
            status: status,
            info_tooltip_shown: info_tooltip_shown,
            mode: mode,
            user_role: userRole,
            browserName,
            community_id,
            community_name,
        });
    },

    changeResolModeInfoShow({
        userid,
        airmeetId,
        community_id,
        community_name,
        sessionid,
        status,
        mode,
        userRole,
        scenario,
    }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.streamingModeInfoShown, {
            userid: userid,
            airmeet_id: airmeetId,
            airmeet_session_id: sessionid,
            status: status,
            mode: mode,
            user_role: userRole,
            browserName,
            community_id,
            community_name,
            scenario,
        });
    },

    unsupportedBrowserPageShown(airmeet_id, attendee_id, user_role, device) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.unsupportedBrowser
                .unsupportedBrowserPageShown,
            {
                airmeet_id,
                attendee_id,
                user_role,
                browser: browserName,
                device: device || 'desktop',
            }
        );
    },
    copyEventLinkClicked(airmeet_id, attendee_id, user_role, device) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.unsupportedBrowser.copyEventLinkClicked,
            {
                airmeet_id,
                attendee_id,
                user_role,
                browser: browserName,
                device: device || 'desktop',
            }
        );
    },
    joinInLiteModeClicked(airmeet_id, attendee_id, user_role, device) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.unsupportedBrowser.joinInLiteModeClicked,
            {
                airmeet_id,
                attendee_id,
                user_role,
                browser: browserName,
                device: device || 'desktop',
            }
        );
    },
    downloadChromeClicked(airmeet_id, attendee_id, user_role, device) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.unsupportedBrowser.downloadChromeClicked,
            {
                airmeet_id,
                attendee_id,
                browser: browserName,
                device: device || 'desktop',
                user_role,
            }
        );
    },
    downloadEdgeClicked(airmeet_id, attendee_id, user_role, device) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.unsupportedBrowser.downloadEdgeClicked,
            {
                airmeet_id,
                attendee_id,
                browser: browserName,
                device: device || 'desktop',
                user_role,
            }
        );
    },
    updateBrowserClicked(airmeet_id, attendee_id, user_role, device) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.unsupportedBrowser.updateBrowserClicked,
            {
                airmeet_id,
                attendee_id,
                browser: browserName,
                device: device || 'desktop',
                user_role,
            }
        );
    },
    firewallDetection(event, proxyType, airmeet_id, attendee_id, user_role) {
        const parser = new UAParser();
        const browser = parser.getBrowser().name;
        let deviceType = parser.getDevice().type || 'Desktop';

        Adapter.addlogEvent(event, {
            proxyType,
            airmeet_id,
            attendee_id,
            user_role,
            browser,
            device: deviceType,
        });
    },

    removeSpeakerEvents(data) {
        const logData = { ...data, browser: browserName };
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.removeSpeaker, logData);
    },

    questionsEvent(event, data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.questionsEvent[event], {
            ...data,
        });
    },

    /**
     * host delegation Events
     * @param {String} airmeetId the airmeet UUID
     * @param {String} communityId the airmeet community UUID
     * @param {String} sessionId the airmeet session UUID
     * @param {String} senderId user ID of the assign host request sender
     * @param {String} receiverId user ID of the assigned host request receiver
     * @param {String} browser the event logging user's browser
     * @param {String} state the state of the assign host request
     */
    assignHostEvents: (payload) => {
        const parser = new UAParser();
        const browser = parser.getBrowser().name;
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.assignHost, {
            ...payload,
            browser,
        });
    },

    /**
     * Booth events handler
     * @param {String} airmeet_id
     * @param {String} airmeet_name
     * @param {String} community_id
     * @param {String} community_name
     * @param {String} user_role
     * @param {String} user_id
     * @param {String} booth_id
     * @param {String} booth_name
     * @param {String} booth_visit_id // optional - needed for join/left event
     * @param {String} booth_visit_source //optional - needed for join/left event
     * @param {String} source // optional- needed for join/left event
     * @param {String} email // optional - needed for lead event
     * @param {String} link_name // mandatory for external links clicked event
     */
    boothPage: (event, data) => {
        const { booth_visit_id, booth_id } = data;
        const action =
            event === 'boothJoined'
                ? 'JOIN'
                : event === 'boothLeft'
                ? 'LEAVE'
                : '';
        const contextData = {
            context_visit_id: booth_visit_id,
            context_id: booth_id,
            context_name: 'BOOTH',
            context_action_type: action,
        };

        data = { ...data, ...contextData };
        if (!EventAnalyticsLoggerTypes.boothPage[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.boothPage[event], {
            ...data,
        });
    },

    /**
     * Booth visitors events handler
     * @param {String} airmeet_id
     * @param {String} community_id
     * @param {String} user_role
     * @param {String} user_id
     * @param {String} booth_id
     * @param {String} post_source_visit_click //optional - needed for panel clicks after opening panel
     * @param {String} source // optional- needed for tab clicked event
     */
    boothLeads: (event, data) => {
        if (!EventAnalyticsLoggerTypes.boothLeads[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.boothLeads[event], {
            ...data,
        });
    },

    /**
     * Announcements events handler
     * @param {String} airmeet_id
     * @param {String} airmeet_title
     * @param {String} community_id
     * @param {String} community_title
     * @param {String} session_id
     * @param {String} announcement // announcement text
     * @param {String} type_of_announcement
     * @param {String} source_of_announcement // from alerts tab or preview pop up
     * @param {String} alert_button_state // are there any unread announcement
     * @param {String} user_id_saw_announcement
     * @param {String} user_role_saw_announcement
     * @param {String} user_id_made_announcement
     * @param {String} user_role_made_announcement
     */
    notificationAnnouncement: (event, data) => {
        if (!EventAnalyticsLoggerTypes.notificationAnnouncement[event]) {
            return;
        }

        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.notificationAnnouncement[event],
            {
                ...data,
            }
        );
    },

    /**
     * Walkthrough reception video events handler
     * @param {String} airmeet_id
     * @param {String} airmeet_title
     * @param {String} community_id
     * @param {String} community_title
     * @param {String} user_role
     * @param {String} user_id
     */

    walkthroughReceptionVideo: (event, data) => {
        if (!EventAnalyticsLoggerTypes.walkthroughReceptionVideo[event]) {
            return;
        }

        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.walkthroughReceptionVideo[event],
            {
                ...data,
            }
        );
    },

    /**
     * Session playback events handler
     * @param {String} airmeet_id
     * @param {String} airmeet_title
     * @param {String} community_id
     * @param {String} community_title
     * @param {String} user_role
     * @param {String} user_id
     * @param {String} session_id
     * @param {String} duration_viewed
     * @param {String} total_viewed_in_percentage
     */

    sessionPlayback: (event, data) => {
        if (!EventAnalyticsLoggerTypes.sessionPlayback[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.sessionPlayback[event], {
            ...data,
        });
    },

    scheduleMeeting: (event, data) => {
        if (!EventAnalyticsLoggerTypes.scheduleMeeting[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.scheduleMeeting[event], {
            ...data,
        });
    },

    richProfile: (event, data) => {
        if (!EventAnalyticsLoggerTypes.richProfile[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.richProfile[event], {
            ...data,
        });
    },

    stagePip: (event, data) => {
        if (!EventAnalyticsLoggerTypes.stagePip[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.stagePip[event], {
            ...data,
        });
    },

    hookForAppInstallation: (event, data) => {
        if (!EventAnalyticsLoggerTypes.hookForAppInstallation[event]) {
            return;
        }

        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.hookForAppInstallation[event],
            {
                ...data,
            }
        );
    },

    /**
     * Reception events handler
     * @param {String} airmeet_id
     * @param {String} airmeet_name
     * @param {String} community_id
     * @param {String} community_name
     * @param {String} user_role
     * @param {String} user_id
     * @param {Number} timestamp
     * @param {String} source // optional
     * @param {String} card_type // optional- needed for secondary card click
     */
    receptionPage: (event, data) => {
        if (!EventAnalyticsLoggerTypes.receptionPage[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.receptionPage[event], {
            ...data,
        });
    },

    newUIBanner: (event, data) => {
        if (!EventAnalyticsLoggerTypes.newUIBanner[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.newUIBanner[event], {
            ...data,
        });
    },

    /**
     * Reception events handler
     * @param {String} airmeet_id
     * @param {String} airmeet_name
     * @param {String} community_id
     * @param {String} community_name
     * @param {String} user_role
     * @param {String} user_id
     * @param {Number} timestamp
     * @param {String} source // optional
     * @param {String} card_type // optional- needed for secondary card click
     */
    schedulePage: (event, data) => {
        if (!EventAnalyticsLoggerTypes.schedulePage[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.schedulePage[event], {
            ...data,
        });
    },

    /**
     * Reception events handler
     * @param {String} airmeet_id
     * @param {String} airmeet_name
     * @param {String} community_id
     * @param {String} community_name
     * @param {String} user_role
     * @param {String} user_id
     * @param {Number} timestamp
     * @param {String} source // optional
     * @param {String} card_type // optional- needed for secondary card click
     */
    lhs: (event, data) => {
        if (!EventAnalyticsLoggerTypes.lhs[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.lhs[event], {
            ...data,
        });
    },

    /**
     * Arena events handler
     * @param {String} airmeet_id
     * @param {String} airmeet_name
     * @param {String} community_id
     * @param {String} community_name
     * @param {String} user_role
     * @param {String} user_id
     */
    arenaPage: (event, data) => {
        if (!EventAnalyticsLoggerTypes.arenaPage[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.arenaPage[event], {
            ...data,
        });
    },

    /**
     * CoHost events handler
     * @param {String} airmeet_id
     * @param {String} airmeet_name
     * @param {String} community_id
     * @param {String} community_name
     * @param {String} user_role
     * @param {String} user_id
     * @param {String} airmeet_session_id
     * @param {Array} user_ids (Only for cohost_added)
     * @param {Array} user_roles (Only for cohost_added)
     */
    coHost: (event, data = {}) => {
        if (!event) {
            return;
        }

        Adapter.addlogEvent(event, data);
    },

    /**
     * Ticketing Events Handler
     * @param {String} airmeet_id
     * @param {String} airmeet_name
     * @param {String} community_id
     * @param {String} community_name
     * @param {String} user_role
     * @param {String} uId
     * @param {String} order_id
     */
    ticketing: (event, data) => {
        event &&
            Adapter.addlogEvent(event, {
                url_for_utm: window.location.href,
                utms: getUrlParams(),
                ...(data || {}),
            });
    },

    ticketingPromoCode: (event, data) => {
        event &&
            Adapter.addlogEvent(
                EventAnalyticsLoggerTypes.ticketingPromoCode[event],
                data
            );
    },

    hooks: (event, data) => {
        event && Adapter.addlogEvent(event, data);
    },
    exhibitor: (event, data) => {
        const eventName = EventAnalyticsLoggerTypes.exhibitor[event];
        eventName && Adapter.addlogEvent(eventName, data);
    },
    copyExhibitorInfo: (event, data) => {
        if (!EventAnalyticsLoggerTypes.copyExhibitorInfo[event]) {
            return;
        }
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.copyExhibitorInfo[event],
            {
                ...data,
            }
        );
    },

    boothConfig: (event, data) => {
        if (!EventAnalyticsLoggerTypes.boothConfig[event]) {
            return;
        }
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.boothConfig[event], {
            ...data,
        });
    },

    selfServeBillingPage: (event, data = {}) => {
        if (!event || !EventAnalyticsLoggerTypes.selfServeBillingPage[event]) {
            return;
        }

        Adapter.addlogEvent(
            'bp_' + EventAnalyticsLoggerTypes.selfServeBillingPage[event],
            {
                ...data,
            }
        );
    },

    manageSubscriptions: (event, data = {}) => {
        if (!event || !EventAnalyticsLoggerTypes.manageSubscription[event]) {
            return;
        }

        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.manageSubscription[event],
            {
                ...data,
            }
        );
    },
    /**
     * @param {Boolean} is_enabled
     * @param {String} airmeet_id
     * @param {String} user_id
     * @param {String} community_id
     * @param {String} user_role
     * @param {String} user_stage_role
     * @param {String} session_id
     */
    closedCaptions: ({
        is_enabled,
        airmeet_id,
        user_id,
        community_id,
        user_role,
        user_stage_role,
        session_id,
    }) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.closedCaptions[is_enabled ? 'on' : 'off'],
            {
                airmeet_id,
                user_id,
                community_id,
                user_role,
                user_stage_role,
                session_id,
            }
        );
    },

    preEventAccess: (data) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.preEventAccess.eventAccess,
            data
        );
    },

    preEventAirmeetStatusChanged: (data) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.preEventAccess.airmeetStatusChanged,
            data
        );
    },
    preEventAirmeetVisited: (data) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.preEventAccess.airmeetVisited,
            data
        );
    },
    preEventDetectedMagicLinkRedirection: (data) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.preEventAccess
                .detectedMagicLinkRedirection,
            data
        );
    },

    poll: (event, data) => {
        if (!EventAnalyticsLoggerTypes.poll[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.poll[event], {
            ...data,
        });
    },

    meteredPricing: (event, data) => {
        if (!event || !EventAnalyticsLoggerTypes.meteredPricing[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.meteredPricing[event], {
            ...data,
        });
    },
    termsOfService: (event, data) => {
        if (!event || !EventAnalyticsLoggerTypes.termsOfService[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.termsOfService[event], {
            ...data,
        });
    },
    analyticsDashboard: (event, data) => {
        if (!EventAnalyticsLoggerTypes.analyticsDashboard[event]) {
            return;
        }

        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.analyticsDashboard[event],
            {
                ...data,
            }
        );
    },
    audienceDashboard: (event, data, isCommunityPage = false) => {
        if (isCommunityPage) {
            if (!EventAnalyticsLoggerTypes.communityAudienceDashboard[event]) {
                return;
            }

            Adapter.addlogEvent(
                EventAnalyticsLoggerTypes.communityAudienceDashboard[event],
                {
                    ...data,
                }
            );
        } else {
            if (!EventAnalyticsLoggerTypes.audienceDashboard[event]) {
                return;
            }

            Adapter.addlogEvent(
                EventAnalyticsLoggerTypes.audienceDashboard[event],
                {
                    ...data,
                }
            );
        }
    },
    reportsDashboard: (event, data) => {
        if (!EventAnalyticsLoggerTypes.reportsDashboard[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.reportsDashboard[event], {
            ...data,
        });
    },
    eventEntryOptionsEvent: (event, data) => {
        if (!EventAnalyticsLoggerTypes.emailAuthEventEntry[event]) {
            return;
        }

        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.emailAuthEventEntry[event],
            {
                ...data,
            }
        );
    },
    customReceptionEvents(event, data) {
        if (!EventAnalyticsLoggerTypes.customReception[event]) {
            return;
        }
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.customReception[event], {
            ...data,
        });
    },
    customBoothEvents(event, data) {
        if (!EventAnalyticsLoggerTypes.customBooth[event]) {
            return;
        }
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.customBooth[event], {
            ...data,
        });
    },
    boothOfferEvents(event, data) {
        if (!EventAnalyticsLoggerTypes.boothOffer[event]) {
            return;
        }
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.boothOffer[event], {
            ...data,
        });
    },
    boothBroadcastEvent(event, data) {
        if (!EventAnalyticsLoggerTypes.boothBroadcast[event]) {
            return;
        }
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.boothBroadcast[event], {
            ...data,
        });
    },
    customCsvUploadAnalyticsEvent(event, data) {
        if (!EventAnalyticsLoggerTypes.customCsvUploadAnalytics[event]) {
            return;
        }
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.customCsvUploadAnalytics[event],
            {
                ...data,
            }
        );
    },
    welcomeVideoAnalyticsEvent(event, data) {
        if (!EventAnalyticsLoggerTypes.welcomeVideoAnalytics[event]) {
            return;
        }
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.welcomeVideoAnalytics[event],
            {
                ...data,
            }
        );
    },
    csvModificationsAnalyticsEvent(event, data) {
        if (!EventAnalyticsLoggerTypes.csvModificationsAnalytics[event]) {
            return;
        }
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.csvModificationsAnalytics[event],
            {
                ...data,
            }
        );
    },
    posterBoothEvents(event, data) {
        if (!EventAnalyticsLoggerTypes.docViewer[event]) {
            return;
        }
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.docViewer[event], {
            ...data,
        });
    },
    eventListingEvent(event, data) {
        if (!EventAnalyticsLoggerTypes.eventListing[event]) {
            return;
        }
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.eventListing[event], {
            ...data,
        });
    },

    eventSeriesEvent(event, data) {
        if (!EventAnalyticsLoggerTypes.eventSeries[event]) {
            return;
        }
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.eventSeries[event], {
            ...data,
            url_for_utm: window.location.href,
            utms: getUrlParams(),
        });
    },
    eventSeriesDashEvent(event, data) {
        if (!EventAnalyticsLoggerTypes.eventSeries[event]) {
            return;
        }
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.eventSeries[event], {
            ...data,
        });
    },
    present: (event, data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.present[event], {
            ...data,
            browser: browserName,
        });
    },
    showOnStage: (event, data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.showOnStage[event], {
            ...data,
            browser: browserName,
        });
    },
    showOnStageContent: (event, data) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.showOnStageContent[event],
            {
                ...data,
                browser: browserName,
            }
        );
    },
    sessionsDashboard(event, data) {
        if (!EventAnalyticsLoggerTypes.sessionsDashboard[event]) {
            return;
        }
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.sessionsDashboard[event],
            {
                ...data,
            }
        );
    },

    billingPlanUpgrade(event, data) {
        Adapter.addlogEvent(event, data);
    },

    customizeEvent: (event, data) => {
        if (!EventAnalyticsLoggerTypes.customizeEvent[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.customizeEvent[event], {
            browser: browserName,
            ...data,
        });
    },

    eventBranding: (event, data) => {
        if (!EventAnalyticsLoggerTypes.eventBranding[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.eventBranding[event], {
            browser: browserName,
            ...data,
        });
    },

    customLandingPage: (event, data) => {
        if (!EventAnalyticsLoggerTypes.customLandingPage[event]) {
            return;
        }

        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.customLandingPage[event],
            {
                browser: browserName,
                ...data,
            }
        );
    },

    tableLayoutChanged({
        community_id,
        airmeet_id,
        user_id,
        user_role,
        cta,
        table_id,
    }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.tableLayoutChanged, {
            community_id,
            airmeet_id,
            user_id,
            user_role,
            cta,
            table_id,
        });
    },

    slTableFull({
        community_id,
        airmeet_id,
        table_id,
        timestamp,
        table_capacity,
        lounge_type,
    }) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.slTableFull, {
            community_id,
            airmeet_id,
            table_id,
            timestamp,
            table_capacity,
            lounge_type,
        });
    },

    /**
     * Localization events handler
     * @param {String} community_id
     * @param {String} airmeet_id
     * @param {String} attendee_id
     * @param {String} browser_language
     * @param {String} proposed_language // needed only for initial language suggestion popup
     * @param {String} user_selected_language // needed only to know user selected language after language popup
     * @param {String} source // needed only to know user selected language after language popup
     * @param {String} used_language // needed which language finally user is using
     */
    localization: (event, data) => {
        if (!EventAnalyticsLoggerTypes.localization[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.localization[event], {
            ...data,
        });
    },
    conferenceTrial: (event, data) => {
        if (!event || !EventAnalyticsLoggerTypes.conferenceTrial[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.conferenceTrial[event], {
            ...data,
        });
    },

    //Custom Registration
    crClicksPlanAndPricing(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.customRegistration.CRClicksPlanAndPricing,
            { ...data }
        );
    },

    crClicksPreviewForm(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.customRegistration.CRClicksPreviewForm,
            { ...data }
        );
    },

    crClickRegCompleteButton(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.customRegistration
                .CRClickRegCompleteButton,
            { ...data }
        );
    },

    calAddClicked(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.customRegistration.calAddClicked,
            { ...data }
        );
    },
    customRegistrationLogEvents(data, key) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.customRegistration[key], {
            ...data,
        });
    },
    crCopyLink(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.customRegistration.CRCopyLink,
            { ...data }
        );
    },

    //Airmeet Duplication
    duplicateEventClicked(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.airmeetDuplication.duplicateEventClicked,
            { ...data }
        );
    },

    saveDuplicateAirmeetClicked(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.airmeetDuplication
                .submittedDuplicateEventDetails,
            { ...data }
        );
    },

    updateUserProfileClicked(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.userProfile.updateProfileClicked,
            { ...data }
        );
    },

    updateUserProfileSaved(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.userProfile.updateProfileSaved,
            { ...data }
        );
    },

    updateUserProfileDiscarded(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.userProfile.updateProfileDiscarded,
            { ...data }
        );
    },

    updateUserProfilePic(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.userProfile.profilePicUpdated,
            { ...data }
        );
    },

    updateUserProfileLeaderboard(event, data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.userProfileLeaderboard[event],
            { ...data }
        );
    },
    /**
     *  Onboarding Flow
     *
     **/
    onboarding(event, data) {
        Adapter.addlogEvent(event, {
            ...data,
            browser: browserName,
        });
    },
    teamMember(event, data) {
        Adapter.addlogEvent(event, {
            ...data,
        });
    },
    peopleSearch(data) {
        const parser = new UAParser();
        const deviceOS = parser.getOS().name;
        const deviceType =
            deviceOS === 'Android'
                ? 'Android'
                : deviceOS === 'iOS'
                ? 'iOS'
                : 'desktop';
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.peopleSearch, {
            ...data,
            event_source: deviceType,
        });
    },

    liveSessionTranslation: (event) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.liveSessionTranslation[event]
        );
    },

    eventLoadingStates: (event, data) => {
        if (!EventAnalyticsLoggerTypes.eventLoadingStates[event]) {
            return;
        }

        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.eventLoadingStates[event],
            {
                ...data,
            }
        );
    },

    embeddedRegistrationFormEvents: (event, data) => {
        if (!EventAnalyticsLoggerTypes.embeddedRegistrationFormEvents[event]) {
            return;
        }

        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.embeddedRegistrationFormEvents[event],
            {
                ...data,
            }
        );
    },
    exhibitorChangingBoothFeatureEvents: (event, data) => {
        if (
            !EventAnalyticsLoggerTypes.exhibitorChangingBoothFeatureEvents[
                event
            ]
        ) {
            return;
        }

        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.exhibitorChangingBoothFeatureEvents[
                event
            ],
            {
                ...data,
            }
        );
    },

    networkErrorTypes: (event, data) => {
        if (!EventAnalyticsLoggerTypes.networkErrorTypes[event]) {
            return;
        }

        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.networkErrorTypes[event],
            {
                ...data,
            }
        );
    },

    cmDash: ({ category, event, eventData = {} }) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.cmDash[category][event], {
            ...eventData,
            browser: browserName,
        });
    },

    dmNotificationDismissed(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.dmNotifications.dmNotificationDismissed,
            {
                ...data,
            }
        );
    },

    dmNotificationClicked(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.dmNotifications.dmNotificationClicked,
            {
                ...data,
            }
        );
    },

    speakerChatSent(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.speakerChatSent, data);
    },

    inviteToTable: (event, data) => {
        if (!EventAnalyticsLoggerTypes.inviteToTable[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.inviteToTable[event], {
            ...data,
        });
    },

    supportEmailClicked(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.supportEmail.supportEmailClicked,
            { ...data }
        );
    },

    supportEmailSelected(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.supportEmail.supportEmailSelected,
            { ...data }
        );
    },

    supportEmailEntered(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.supportEmail.supportEmailEntered,
            { ...data }
        );
    },

    supportEmailRemoved(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.supportEmail.supportEmailRemoved,
            { ...data }
        );
    },
    leaderboardEvent(event, data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.leaderboardEvent[event], {
            ...data,
        });
    },

    sessionRHS: (event, data) => {
        Adapter.addlogEvent(event, { ...data });
    },

    ugcTables: (event, data) => {
        if (!EventAnalyticsLoggerTypes.ugc[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.ugc[event], {
            ...data,
        });
    },

    /**
     *
     * @param {String} eventName
     * @param {Object} eventData
     */
    emailManagement: (eventName = '', eventData = {}) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.emailManagement[eventName],
            {
                ...eventData,
            }
        );
    },

    /**
     * Live session exit handler
     * @param {String} community_id
     * @param {String} community_name
     * @param {String} airmeet_id
     * @param {String} user_id
     * @param {String} user_role
     * @param {String} user_stage_role
     * @param {String} airmeet_session_id
     * @param {String} browser
     * @param {String} session_status
     * @param {String} status
     */
    liveSessionExit: (event, data = {}) => {
        if (!event) {
            return;
        }

        Adapter.addlogEvent(event, {
            browser: browserName,
            ...data,
        });
    },

    /**
     * Collapsible RHS
     * @param {String} community_id
     * @param {String} community_name
     * @param {String} airmeet_id
     * @param {String} userid
     * @param {String} user_role
     * @param {String} airmeet_session_id
     * @param {String} browser
     * @param {String} status
     */
    collapsibleRHS: (data = {}) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.collapse_RHS, {
            browser: browserName,
            ...data,
        });
    },

    profilePage: (eventName = '', eventData = {}) => {
        Adapter.addlogEvent(eventName, {
            ...eventData,
        });
    },

    preEventAndSocialShare: (event, data) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.preEventAndSocialShare[event],
            {
                ...data,
                event_name:
                    EventAnalyticsLoggerTypes.preEventAndSocialShare[event],
            }
        );
    },
    filterSupported: (data = {}) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.backgroundFilter.supported,
            {
                ...data,
            }
        );
    },
    filterSelected: (data = {}) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.backgroundFilter.filter_selected,
            {
                ...data,
            }
        );
    },
    postEventReplay: (event, data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.postEventReplay[event], {
            ...data,
            event_name: EventAnalyticsLoggerTypes.postEventReplay[event],
        });
    },
    fluidLounge: (event, data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.fluidLounge[event], {
            ...data,
            event_name: EventAnalyticsLoggerTypes.fluidLounge[event],
        });
    },
    userReconnectedOnTable: (data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.userReconnectedOnTable, {
            ...data,
            event_name: EventAnalyticsLoggerTypes.userReconnectedOnTable,
        });
    },
    // group meeting
    groupMeetingStarted: (data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.groupMeetingStarted, {
            ...data,
        });
    },
    groupMeetingEnded: (data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.groupMeetingEnded, {
            ...data,
        });
    },
    userJoinedGroupMeeting: (data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.userJoinedGroupMeeting, {
            airmeetId: Adapter.getDefaultEventData().airmeet_id,
            ...data,
        });
    },
    userLeftGroupMeeting: (data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.userLeftGroupMeeting, {
            airmeetId: Adapter.getDefaultEventData().airmeet_id,
            ...data,
        });
    },
    videoAnnouncement: (eventName = '', eventData = {}) => {
        Adapter.addlogEvent(eventName, {
            ...eventData,
            browser: browserName,
            userid: Adapter.getDefaultEventData().user_id,
        });
    },

    updateProfileNudge: (event, data) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.updateProfileNudge[event],
            {
                ...data,
            }
        );
    },
    loungeSearch: (event, data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.loungeSearch[event], {
            ...data,
            event_name: EventAnalyticsLoggerTypes.loungeSearch[event],
        });
    },
    advancedPeopleSearch: (event, data) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.advancedPeopleSearch[event],
            {
                ...data,
                event_name:
                    EventAnalyticsLoggerTypes.advancedPeopleSearch[event],
            }
        );
    },
    dashboardSummary: (event, data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.dashboardSummary[event], {
            ...data,
            event_name: EventAnalyticsLoggerTypes.dashboardSummary[event],
        });
    },
    dashboardRollout: (event, data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.dashboardRollout[event], {
            ...data,
            event_name: EventAnalyticsLoggerTypes.dashboardRollout[event],
        });
    },
    systemCheck: (event, data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.systemCheck[event], {
            ...data,
            event_name: EventAnalyticsLoggerTypes.systemCheck[event],
        });
    },
    rhManagement: (event, data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.rhManagement[event], {
            ...data,
            event_name: EventAnalyticsLoggerTypes.rhManagement[event],
        });
    },
    rpManagement: (event, data) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.rpManagement[event], {
            ...data,
            event_name: EventAnalyticsLoggerTypes.rpManagement[event],
        });
    },
    chatModerationToggle(data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.chatModerationToggle, {
            ...data,
        });
    },
    bigNudge: (event, data = {}) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.bigNudge[event], {
            ...data,
        });
    },
    typeFormSubmitted: (data = {}) => {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.typeform_submitted, {
            ...data,
        });
    },
    typeformIntegrationEvent: (event, data = {}) => {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.typeformIntegration[event],
            { ...data }
        );
    },
    requestToJoinStage: (event, data = {}) => {
        Adapter.addlogEvent(event, { ...data });
    },
    waitingAreaManageScheduleClicked(data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.waitingAreaManageScheduleClicked,
            {
                ...data,
            }
        );
    },
    // Copy Booth Link Event
    copyBoothLinkEvent(data, event) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.copyBoothLink[event],
            data
        );
    },
    speakerOnboarding(event, data) {
        Adapter.addlogEvent(event, {
            ...data,
        });
    },

    // product update
    productUpdate(event, data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.productUpdate[event], {
            ...data,
        });
    },

    // registration management
    registrationManagement(event, data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.registrationManagement[event],
            {
                ...data,
            }
        );
    },

    customFont(event, data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.customFont[event], {
            ...Adapter.getDefaultEventData(),
            ...data,
        });
    },

    dashLightTheme(event, data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.dashLightTheme[event], {
            ...Adapter.getDefaultEventData(),
            ...data,
        });
    },

    airGenie(event, data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.airGenie[event], {
            ...Adapter.getDefaultEventData(),
            ...(data || {}),
        });
    },

    beaconBar(event, data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.beaconBar[event], {
            ...Adapter.getDefaultEventData(),
            ...(data || {}),
        });
    },

    banners(event, bannerType, data = {}) {
        if (bannerType === STAGE_BANNERS.CTA_BANNER)
            return Adapter.addlogEvent(
                EventAnalyticsLoggerTypes.ctaBanner[event],
                {
                    ...data,
                }
            );

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.banners[event], {
            ...data,
            bannerType,
        });
    },

    emailDraft(event, data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.emailDraft[event], {
            ...Adapter.getDefaultEventData(),
            ...(data || {}),
        });
    },
    backdropLibraryEvents(event, data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.backdrop[event], {
            ...Adapter.getDefaultEventData(),
            ...(data || {}),
        });
    },
    waitingScreenLibraryEvents(event, data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.waitingScreen[event], {
            ...Adapter.getDefaultEventData(),
            ...(data || {}),
        });
    },
    airProfile(event, data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.airProfile[event], {
            ...Adapter.getDefaultEventData(),
            ...(data || {}),
        });
    },
    URLTrack(event, data) {
        Adapter.addlogEvent(EventAnalyticsLoggerTypes.URLTrack[event], {
            ...Adapter.getDefaultEventData(),
            ...(data || {}),
        });
    },

    emailDomainRestriction(event, data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.emailDomainRestriction[event],
            {
                ...Adapter.getDefaultEventData(),
                ...(data || {}),
            }
        );
    },

    analyticsEventsDashboard(event, data) {
        let key = '';
        if (event in EventAnalyticsLoggerTypes.analyticsEventsDashboard) {
            key = EventAnalyticsLoggerTypes.analyticsEventsDashboard[event];
        } else if (
            event in EventAnalyticsLoggerTypes.communityAudienceDashboard
        ) {
            key = EventAnalyticsLoggerTypes.communityAudienceDashboard[event];
        }

        if (!key) return;

        Adapter.addlogEvent(key, {
            ...Adapter.getDefaultEventData(),
            ...(data || {}),
        });
    },

    audienceSummaryDashboard(event, data) {
        let key = '';
        if (event in EventAnalyticsLoggerTypes.audienceSummaryDashboard) {
            key = EventAnalyticsLoggerTypes.audienceSummaryDashboard[event];
        } else if (
            event in EventAnalyticsLoggerTypes.communityAudienceDashboard
        ) {
            key = EventAnalyticsLoggerTypes.communityAudienceDashboard[event];
        }

        if (!key) return;

        Adapter.addlogEvent(key, {
            ...Adapter.getDefaultEventData(),
            ...(data || {}),
        });
    },

    communityEmailTemplates(event, data) {
        Adapter.addlogEvent(
            EventAnalyticsLoggerTypes.communityEmailTemplates[event],
            {
                ...Adapter.getDefaultEventData(),
                ...(data || {}),
            }
        );
    },

    videoEditor(event, data) {
        Adapter.addlogEvent(event, {
            ...data,
            browser: browserName,
        });
    },

    eventRecordings: (event, data) => {
        if (!EventAnalyticsLoggerTypes.eventRecordings[event]) {
            return;
        }

        Adapter.addlogEvent(EventAnalyticsLoggerTypes.eventRecordings[event], {
            ...data,
        });
    },

    freeTrial(event, data) {
        Adapter.addlogEvent(event, {
            ...data,
            browser: browserName,
        });
    },
};

/**
 * utility functions
 * @todo add a function that generates user connectivity related attributes like browser user agent,
 * internet connection speed,
 * latency etc.
 */
