// TODO: Needs optimization
// eslint-disable-next-line no-restricted-imports
import { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import LRUCache from 'quick-lru';
import { WORKER_NAME_USER_CACHE } from 'utils/constants/workers';
import { bindModuleLogger } from 'utils/loggers/localLogger';
import { UserDataMeta, UserObject } from 'utils/typeDefs/userDataTypes';
import { SPAWN_TIMEOUT, startWorker } from 'workers';

const log = bindModuleLogger(`UserDataCache:UserDataFactory`, 'yellow');

export const USE_WORKER = false;

export interface UserData {
    [key: string]: any;
}

export interface PendingUserData {
    [uid: string]: any;
}

export interface PendingUserDataByID {
    [idSeq: number]: any;
}

export interface UserDataPool {
    [uid: string]: UserData;
}

export default class UserDataFactory extends LRUCache<string, UserObject> {
    userDataWorker: any = null;
    users: { [key: string]: UserData } = {};
    intUsers: { [key: string]: UserData } = {};

    pendingUIDPool: PendingUserData = {};
    pendingIDSeqPool: PendingUserData = {};

    idSeqMap: { [idSeq: number]: string } = {};

    debouncedFlush: DebouncedFunc<() => void> = debounce(
        this._flushPending.bind(this),
        100,
        {
            trailing: true,
            maxWait: 1000,
        }
    );

    _processing: boolean = false;

    static _instance: UserDataFactory = null;
    static getInstance() {
        return UserDataFactory._instance;
    }
    static initFactory(meta: UserDataMeta): Promise<UserDataFactory> {
        if (UserDataFactory._instance) {
            return Promise.resolve(UserDataFactory._instance);
        }

        UserDataFactory._instance = new UserDataFactory({
            maxSize: 5000,
        });
        return UserDataFactory._instance.init(meta);
    }
    static uidMapping(idSeq: number) {
        const factory = UserDataFactory._instance;
        if (!factory) {
            return null;
        }

        return factory.idSeqMap[idSeq] || null;
    }
    static poolData(filter: { uid?: string; idSeq?: number }) {
        const factory = UserDataFactory._instance;
        if (!factory || (!filter.uid && !filter.idSeq)) {
            return null;
        }

        let { uid, idSeq } = filter;
        if (idSeq) {
            uid = factory.idSeqMap[idSeq];
        }

        return uid && UserDataFactory._instance.get(uid);
    }

    async init(meta: UserDataMeta) {
        if (!USE_WORKER) return this;

        try {
            // Create the worker
            this.userDataWorker = await startWorker(WORKER_NAME_USER_CACHE);
            // Init the worker
            await this.userDataWorker.setup(meta);
        } catch (err) {
            log.error('Failed to setup the userDataWorker', err);
        }
        return this;
    }

    setUsers(
        users: { [key: string]: UserData },
        intUsers: { [int: string]: UserData }
    ) {
        // TODO: verify if cloning is needed
        this.users = users;
        this.intUsers = intUsers;
    }

    async _flushPending() {
        log.info('Flushing pending user data requests');
        // Wait for requests/previous run to complete
        const uidToProcess = Object.keys(this.pendingUIDPool);
        const idSeqToProcess = Object.keys(this.pendingIDSeqPool);
        if (
            (uidToProcess.length <= 0 && idSeqToProcess.length <= 0) ||
            this._processing
        ) {
            log.info(
                'Cannot flush queue of length:',
                `UIDs: ${uidToProcess.length}`,
                `IDSeqs: ${idSeqToProcess.length}`,
                this._processing ? 'Currently ongoing flush' : ''
            );
            return;
        }

        if (USE_WORKER && !this.userDataWorker) {
            throttledNoWorkerLog();
            return;
        }

        // Block more requests
        this._processing = true;

        try {
            let workerData;
            if (USE_WORKER) {
                workerData = await this.userDataWorker.fetchUsers({
                    uids: uidToProcess,
                    idSeqs: idSeqToProcess,
                });
                log.info('Got data from worker', JSON.stringify(workerData));
            }

            // Done, inform all listeners and move on by clearing the pending item
            uidToProcess.forEach((uid) => {
                if (!workerData) {
                    workerData = this.users;
                }
                if (!workerData[uid]) {
                    return;
                }
                const cacheKey = workerData[uid].id;
                this.set(cacheKey, workerData[uid]);

                this.pendingUIDPool[uid]._pendingResolve(this.get(cacheKey));
                this.pendingUIDPool[uid].pending = null;
                delete this.pendingUIDPool[uid];
            });

            idSeqToProcess.forEach((idSeq) => {
                if (!workerData) {
                    workerData = this.intUsers;
                }
                if (!workerData[idSeq]) {
                    return;
                }
                const cacheKey = workerData[idSeq].id;
                this.set(cacheKey, workerData[idSeq]);

                this.idSeqMap[idSeq] = cacheKey;

                this.pendingIDSeqPool[idSeq]._pendingResolve(
                    this.get(cacheKey)
                );
                this.pendingIDSeqPool[idSeq].pending = null;
                delete this.pendingIDSeqPool[idSeq];
            });
        } catch (err) {
            // In case of failure, retry
            log.error('Error fetching user data from worker', err.toString());
        } finally {
            // Unset processing flag, and try flishing any more pending requests
            this._processing = false;
            this.debouncedFlush();
        }
    }

    fetchUser(filter: { uid?: string; idSeq?: number }) {
        let { uid, idSeq } = filter;

        // Try using a cached mapping
        if (idSeq) {
            uid = this.idSeqMap[idSeq];
        } else if (!uid) {
            throw new Error(
                'Fetching must be called with atleast one uid, or idSeq as filter'
            );
        }

        // Already have a pending/completed request
        if (uid && this.has(uid)) {
            return this.get(uid);
        }

        // Setup a pending request
        // Track the promise for resolution later
        if (idSeq) {
            this.pendingIDSeqPool[idSeq] = {
                id_seq: idSeq,
            };

            this.pendingIDSeqPool[idSeq].pending = new Promise<UserData>(
                (resolve) => {
                    this.pendingIDSeqPool[idSeq]._pendingResolve = resolve;
                }
            );
            this.set(uid, this.pendingIDSeqPool[idSeq]);
        } else {
            this.pendingUIDPool[uid] = {
                uid,
            };

            this.pendingUIDPool[uid].pending = new Promise<UserData>(
                (resolve) => {
                    this.pendingUIDPool[uid]._pendingResolve = resolve;
                }
            );
            this.set(uid, this.pendingUIDPool[uid]);
        }

        // Make a request for the data
        this.debouncedFlush();

        return idSeq ? this.pendingIDSeqPool[idSeq] : this.pendingUIDPool[uid];
    }

    fetchUsersByUID(uidArray: string[]) {
        return uidArray.reduce((all, curr) => {
            const data = this.fetchUser({ uid: curr });
            return {
                ...all,
                [curr]: data,
            };
        }, {});
    }

    fetchUsersByIDSeq(idSeqArray: number[]) {
        return idSeqArray.reduce((all, curr) => {
            const data = this.fetchUser({ idSeq: curr });
            return {
                ...all,
                [curr]: data,
            };
        }, {});
    }
}

const throttledNoWorkerLog = throttle(
    () => {
        log.error('Worker is not initialised');
    },
    SPAWN_TIMEOUT,
    { leading: true }
);
