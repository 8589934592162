import * as RdxToast from '@radix-ui/react-toast';
import Toast, { ToastViewport } from 'atoms/Toast';
import ToastPopup from 'components/molecules/ToastPopup';
import React from 'react';
import { useSelector } from 'react-redux';
import { TOAST_VERSION_2 } from 'utils/constants/toasts';
import PermissionUtils from 'utils/permission-utils';
import { TOAST_TYPE_CUSTOM } from '../../store/actions/toasts';
import FullScreenPortal from './FullScreenPortal';

function Toasts() {
    const toasts = useSelector((state) => state.toasts);

    if (PermissionUtils.isEventCloudHost()) {
        return null;
    }

    return (
        <div>
            <FullScreenPortal>
                <RdxToast.Provider swipeDirection='up'>
                    {toasts.map((toast) =>
                        toast?.version === TOAST_VERSION_2 &&
                        toast?.type === TOAST_TYPE_CUSTOM ? (
                            <ToastPopup
                                key={toast.id}
                                {...{ ...toast, show: true }}
                            />
                        ) : (
                            <Toast toast={toast} key={toast?.id} />
                        )
                    )}
                    <ToastViewport />
                </RdxToast.Provider>
            </FullScreenPortal>
        </div>
    );
}

export default Toasts;
