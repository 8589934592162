import { usePrevious } from 'hooks/common';
import { useState, useEffect, useRef } from 'react';
import { SESSION_STATUS } from 'utils/constants/sessions';
import { logger } from 'utils/logger';
import { LITE_MODE_DELAY } from 'utils/constants/live-airmeet';

const useLiteModeGracePeriod = (showLiteMode, currentSessionStatus) => {
    const [liteModeGracePeriod, setLiteModeGracePeriod] = useState(null);
    const litemodeActiveGracePeriodTimer = useRef();
    const previousSessionStatus = usePrevious(
        currentSessionStatus,
        currentSessionStatus
    );

    const addLiteModeGracePeriod =
        showLiteMode &&
        SESSION_STATUS.ONGOING.includes(previousSessionStatus) &&
        [SESSION_STATUS.FINISHED, SESSION_STATUS.PAUSED].includes(
            currentSessionStatus
        );

    useEffect(() => {
        if (!addLiteModeGracePeriod) {
            return;
        }
        setLiteModeGracePeriod(Date.now() + LITE_MODE_DELAY + 20);
        litemodeActiveGracePeriodTimer.current = setTimeout(() => {
            setLiteModeGracePeriod(null);
            logger.info('Clearing lite mode grace period.');
        }, LITE_MODE_DELAY);
    }, [addLiteModeGracePeriod]);

    useEffect(() => {
        if (
            SESSION_STATUS.ONGOING.includes(currentSessionStatus) &&
            litemodeActiveGracePeriodTimer.current
        ) {
            litemodeActiveGracePeriodTimer.current &&
                clearTimeout(litemodeActiveGracePeriodTimer.current);
        }
    }, [currentSessionStatus]);

    useEffect(() => {
        return () => {
            litemodeActiveGracePeriodTimer.current &&
                clearTimeout(litemodeActiveGracePeriodTimer.current);
        };
    }, []);

    return liteModeGracePeriod;
};

export default useLiteModeGracePeriod;
