import useDataWriter from 'hooks/useDataWriter';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import { LOG_LEVEL } from 'utils/constants/logger';
import firebaseLogger from 'utils/firebaseLogger';
import { logger } from 'utils/logger';
import { v4 as uuid } from 'uuid';
const localLog = logger.init('useShowOnStage', 'yellow', LOG_LEVEL.INFO);
export const contentTypes = {
    VIDEO: 'video',
    BANNERS: 'banners',
    WAITING_SCREEN: 'waitingScreen',
};

const { ADD, REMOVE, CLEAR } = FEATURE_ACTIONS[
    FEATURE_NAMES.SHOW_ON_STAGE_CONTENT
];

function useShowOnStageContent({ stageRoomNode, stageId }) {
    const [contentData, setContentData] = useState(null);
    const {
        user,
        firebaseClient,
        airmeet: {
            airmeetId,
            data: { userRole, userStageRole, currentAirmeet },
        },
    } = useLiveAirmeetContext();

    const { write: dataWriter } = useDataWriter(
        FEATURE_NAMES.SHOW_ON_STAGE_CONTENT,
        {
            useFrontendWriterFallback: false,
        }
    );
    const logStageContentEvent = useCallback(
        (eventName, source, otherParam = {}) => {
            const payload = {
                airmeet_id: airmeetId,
                community_name: currentAirmeet.community_name,
                community_id: currentAirmeet.community_id,
                user_role: userRole,
                user_stage_role: userStageRole,
                airmeet_session_id: stageId,
                uid: user.id,
            };

            if (source) {
                payload.source = source;
            }

            firebaseLogger.showOnStageContent(eventName, {
                ...payload,
                ...otherParam,
            });
        },
        [
            airmeetId,
            currentAirmeet.community_name,
            currentAirmeet.community_id,
            userRole,
            userStageRole,
            stageId,
            user.id,
        ]
    );

    const _path = useMemo(() => `${stageRoomNode}/showOnStageContent`, [
        stageRoomNode,
    ]);

    const addStageContent = useCallback(
        async (contentType, contentId, data) => {
            localLog('Reserving stage for ', data);
            if (data && !data.uid) {
                data.uid = uuid();
            }
            try {
                await dataWriter(data, {
                    action: ADD,
                    contentType,
                    contentId,
                    stageId,
                });
            } catch (e) {
                localLog('Unable to update content on stage', e);
            }
        },
        [dataWriter, stageId]
    );

    const removeStageContent = useCallback(
        async (contentType, contentId) => {
            localLog('removing stage', { contentType, contentId });

            try {
                await dataWriter(null, {
                    action: REMOVE,
                    contentType,
                    contentId,
                    stageId,
                });
            } catch (e) {
                localLog('Unable to remove content from stage ', e);
            }
        },
        [dataWriter, stageId]
    );

    const removeStageContentType = useCallback(
        async (contentType) => {
            localLog('clear content type', { contentType });
            try {
                await dataWriter(null, {
                    action: CLEAR,
                    contentType,
                    stageId,
                });
            } catch (e) {
                localLog('Unable to clear content type from stage ', e);
            }
        },
        [dataWriter, stageId]
    );

    useEffect(() => {
        const handleChanges = ({ value }) => {
            logger.debug(
                'got handle form database value change form firebase',
                value
            );
            setContentData(value);
        };
        if (!firebaseClient) {
            return;
        }
        firebaseClient.getDataSync(_path, handleChanges);
        return () => {
            if (!firebaseClient) {
                return;
            }
            firebaseClient.clearDataSync(_path, handleChanges);
        };
    }, [stageId, firebaseClient, _path]);

    const getContentTypeData = useCallback(
        (contentType, contentId, defaultValue = null) => {
            return contentData && contentData[contentType]
                ? contentData[contentType][contentId]
                : defaultValue;
        },
        [contentData]
    );

    return {
        addStageContent,
        removeStageContent,
        removeStageContentType,
        getContentTypeData,
        stageContentData: contentData,
        // stageContentFeatureData,
        contentTypes,
        logStageContentEvent,
    };
}
export default useShowOnStageContent;
