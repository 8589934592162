import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';

const { QUESTIONS } = FEATURE_NAMES;

const questionsWriter = async ({
    airmeetId,
    sessionId,
    client,
    payload,
    metaData,
    logger,
}) => {
    if (!airmeetId || !sessionId) {
        throw new Error('Invalid question firebase key');
    }

    let questionBasePath = `${airmeetId}/liveAirmeet/sessions/${sessionId}`;

    if (metaData.action === FEATURE_ACTIONS[QUESTIONS].ADD_QUESTION) {
        logger.info('Adding New Question');

        if (!metaData.messageKey || !metaData.userId) {
            throw new Error('Invalid add question firebase key');
        }

        const writes = {};
        const addQuestionKey = metaData.isModerationEnabled
            ? `${questionBasePath}/moderationQuestions/${metaData.messageKey}`
            : `${questionBasePath}/questions/${metaData.messageKey}`;
        const updateStatusKey = `${questionBasePath}/userQuestions/${metaData.userId}/${metaData.messageKey}`;

        writes[addQuestionKey] = payload;
        writes[updateStatusKey] = metaData.quesStatus;

        const result = await client.atomicUpdate(writes);
        const snapshot = result?.snapshot || null;
        return snapshot?.val();
    }

    if (metaData.action === FEATURE_ACTIONS[QUESTIONS].TOGGLE_ANSWER_STATUS) {
        logger.info('Toggle Question answer Status');

        const fireBaseKey = `${questionBasePath}/questions/${metaData.chatKey}`;

        const { snapshot } = await client.runTransaction(
            fireBaseKey,
            (question) => {
                if (!question) {
                    return question;
                }
                question.isAnswered = !question.isAnswered;
                metaData.quesStatus = question.isAnswered;
                return question;
            }
        );
        return snapshot.val();
    }

    if (metaData.action === FEATURE_ACTIONS[QUESTIONS].UPVOTE_QUESTION) {
        logger.info('Upvote Question');

        const { snapshot } = await client.runTransaction(
            `${questionBasePath}/questions/${metaData.chatKey}`,
            (question) => {
                if (!question.upVoteCounts) {
                    question.upVoteCounts = {};
                }

                if (
                    question.upVoteCounts.upVoters &&
                    question.upVoteCounts.upVoters[metaData.authUserId]
                ) {
                    question.upVoteCounts.upVotes =
                        question.upVoteCounts.upVotes - 1;
                    question.upVoteCounts.upVoters[metaData.authUserId] = false;
                } else {
                    question.upVoteCounts.upVotes = question.upVoteCounts
                        .upVotes
                        ? question.upVoteCounts.upVotes + 1
                        : 1;
                    if (!question.upVoteCounts.upVoters) {
                        question.upVoteCounts.upVoters = {};
                    }
                    question.upVoteCounts.upVoters[metaData.authUserId] = true;
                }
                return question;
            }
        );
        return snapshot.val();
    }

    if (metaData.action === FEATURE_ACTIONS[QUESTIONS].REMOVE_QUESTION) {
        if (!metaData.questionId || !metaData.userId) {
            throw new Error('Invalid add question firebase key');
        }
        logger.info('Remove Question', metaData.questionId);
        const removeQuestionKey = `${questionBasePath}/questions/${metaData.questionId}`;

        const result = await client.setData(removeQuestionKey, payload);
        const snapshot = result?.snapshot || null;
        return snapshot?.val();
    }
};

export default questionsWriter;
