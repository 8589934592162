export function CreateAirmeet(state = '', action) {
    switch (action.type) {
        case 'CreateAirmeet':
            return action.payload;
        case 'Error':
            return action.payload;
        default:
            return state;
    }
}

export function AirmeetStatus(state = '', action) {
    switch (action.type) {
        case 'AirmeetStatus':
            return action.payload;
        case 'Error':
            return action.payload;
        default:
            return state;
    }
}
