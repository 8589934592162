import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { useEffect, useState } from 'react';
/**
 * @name useSystemTimeDifference
 * @description Returns system time difference between firebase db and system clock.
 * @usage
 * @return {number} The time difference in milliseconds.
 */
const useSystemTimeDifference = () => {
    const { firebaseClient } = useLiveAirmeetContext();
    const [timeDifference, setTimeDifference] = useState(
        firebaseClient.timeDifference
    );

    useEffect(() => {
        if (!firebaseClient) {
            return;
        }
        getTimeDiff();
    }, [firebaseClient]);

    const getTimeDiff = async () => {
        const timeDiff = await firebaseClient.getCurrentTimeStampDifference();
        setTimeDifference(timeDiff);
    };

    return timeDifference;
};

export default useSystemTimeDifference;
