import { useShallowEqualSelector } from 'hooks/common';
import useDataWriter from 'hooks/useDataWriter';
import { ALL_FEATURES_CONFIG } from 'hooks/useFeatureControl';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import {
    getHandRaisePendingRequestCountSelector,
    getSessionRaiseHandRequestListSelector,
} from 'store/selectors';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import { FEATURE_NAMES } from 'utils/constants/featureNames';
import { LOG_LEVEL } from 'utils/constants/logger';
import { logger } from 'utils/logger';
import { HAND_RAISE_REQUEST_STATUS } from '../../store/actions/handRaise';
import { checkToAllowCameraPermission } from '../../utils/airmeet';
import { useStageRTCContext } from 'context/StageRTCProvider';
export const QOELog = logger.init('RAISE_HAND', 'green', LOG_LEVEL.INFO);

const emptyObj = {};

export const getMyRequest = (list, userId) => {
    return list.find(
        ({ status, user: requestedUser, requestId }) =>
            requestId &&
            requestedUser?.id === userId &&
            [
                HAND_RAISE_REQUEST_STATUS.pending,
                HAND_RAISE_REQUEST_STATUS.accepted,
                HAND_RAISE_REQUEST_STATUS.active,
            ].includes(status)
    );
};

export const useMaxPendingRequestCapacity = () => {
    const { featuresConfig } = useLiveAirmeetContext();
    return featuresConfig &&
        featuresConfig[ALL_FEATURES_CONFIG.MAX_PENDING_RAISE_HAND_REQUEST]
        ? featuresConfig[ALL_FEATURES_CONFIG.MAX_PENDING_RAISE_HAND_REQUEST]
        : 500;
};

export const useHasReachedMaxPendingRequestLimit = (sessionId) => {
    const pendingRequestCount = useSelector(
        getHandRaisePendingRequestCountSelector(sessionId)
    );
    const maxPendingRequestLimit = useMaxPendingRequestCapacity();
    return useMemo(() => {
        if (!maxPendingRequestLimit) {
            return false;
        }
        return pendingRequestCount >= maxPendingRequestLimit;
    }, [pendingRequestCount, maxPendingRequestLimit]);
};

const useRaiseHandActions = ({ sessionId, airmeetId, user }) => {
    const airmeetService = getAirmeetUtilInstance();
    const { stageService, channelName } = useStageRTCContext() || emptyObj;
    const actionData = useMemo(
        () => ({
            sessionId,
        }),
        [sessionId]
    );

    const { write: dataWriter } = useDataWriter(
        FEATURE_NAMES.STAGE_RAISE_HAND,
        {
            useFrontendWriterFallback: false,
        }
    );

    const self = useMemo(
        () => ({
            isAccepted: (request) => {
                return (
                    request &&
                    request.status === HAND_RAISE_REQUEST_STATUS.accepted
                );
            },
            isActive: (request) => {
                return (
                    request &&
                    request.status === HAND_RAISE_REQUEST_STATUS.active
                );
            },
            isPending: (request) => {
                return (
                    request &&
                    request.status === HAND_RAISE_REQUEST_STATUS.pending
                );
            },
            isCompleted: (request) => {
                return (
                    request &&
                    request.status === HAND_RAISE_REQUEST_STATUS.completed
                );
            },
            isRejected: (request) => {
                return (
                    request &&
                    request.status === HAND_RAISE_REQUEST_STATUS.declined
                );
            },
            isLeaveRequest: ({ status }) => {
                return status === HAND_RAISE_REQUEST_STATUS.leaveRequest;
            },
            getMyRequest: (list) => {
                return getMyRequest(list, user.id);
            },
            getCurrentProcessingRequest: (list) => {
                return list.filter(
                    (request) =>
                        self.isActive(request) || self.isAccepted(request)
                );
            },

            accepted: async (user, requestId) => {
                await dataWriter(
                    { requestId },
                    {
                        ...actionData,
                        action: 'accepted',
                    }
                );
            },
            active: async (requests) => {
                const request = self.getMyRequest(requests);

                if (!request) {
                    // If not found any active request then unpublish the stream
                    stageService && stageService.handRiseLeave();
                    return false;
                }

                if (stageService && !stageService.hasStreamPublish()) {
                    return self.activeHandler(request);
                }

                if (self.isActive(request)) {
                    return true;
                }

                if (self.isAccepted(request)) {
                    const { requestId } = request;
                    // If request still in accepted mode then allow to update state of request to active
                    const res = await dataWriter(
                        { requestId },
                        {
                            ...actionData,
                            action: 'active',
                        }
                    );
                    return res !== undefined ? res : true;
                }

                if (!self.isActive(request)) {
                    // If not found any active request then unpublish the stream
                    // If current request is not in the accepted or active mode then unpublish the stream
                    stageService && stageService.handRiseLeave();
                }

                return false;
            },
            activeProcess: () => {
                stageService && stageService.handRiseActive();
            },
            activeHandler: async (request) => {
                if (request.user.id !== user.id) {
                    return;
                }
                if (stageService && stageService.hasStreamPublish()) {
                    return;
                }
                const { requestId } = request;

                return await dataWriter(
                    { requestId },
                    {
                        ...actionData,
                        action: 'activeHandler',
                    }
                );
            },
            rejectHandler: (request) => {
                if (request.user.id !== user.id || !self.isRejected(request)) {
                    return;
                }
                if (stageService && !stageService.hasStreamPublish()) {
                    return;
                }
                stageService && stageService.handRiseLeave();
            },
            rejected: async (user, requestId) => {
                return await dataWriter(
                    { requestId },
                    {
                        ...actionData,
                        action: 'rejected',
                    }
                );
            },
            leave: async (user, requestId, shouldStageLeave = true) => {
                if (shouldStageLeave) {
                    stageService && stageService.handRiseLeave();
                }
                return await dataWriter(
                    { requestId },
                    {
                        ...actionData,
                        action: 'leave',
                    }
                );
            },
            request: async (errorCallback) => {
                return checkToAllowCameraPermission()
                    .then(() => {
                        return dataWriter(
                            {
                                errorCallback,
                                user: {
                                    id: user.id,
                                    id_seq: user.id_seq,
                                    name: user.name,
                                    profile_img: user.profile_img,
                                },
                            },
                            {
                                ...actionData,
                                action: 'request',
                            }
                        );
                    })
                    .catch((error) => {
                        errorCallback && errorCallback(error);
                    });
            },
            withdraw: async (requestId) => {
                return await dataWriter(
                    { requestId },
                    {
                        ...actionData,
                        action: 'withdraw',
                    }
                );
            },
            leaveRequest: async (user, requestId) => {
                return await dataWriter(
                    { requestId, channelName, requestUser: user },
                    {
                        ...actionData,
                        action: 'leaveRequest',
                    }
                );
            },
            handelLeaveRequest: async (request) => {
                if (request.user.id === user.id) {
                    self.leave(request.user, request.requestId);
                } else {
                    QOELog(
                        `HANDLE_LEAVE_REQUEST: Hand Raise request #[${request.requestId}]: Unsubscribe remote stream: ${request.user.id} by user id :${user?.id} in session ${sessionId}`
                    );
                    stageService &&
                        stageService.unsubscribeStream(request.id_seq);
                }
            },
            bindOffLineEvent: (request) => {
                const { requestId, status, user } = request;
                return dataWriter(
                    { requestId, status, requestedUserId: user?.id },
                    {
                        ...actionData,
                        action: 'bindOffLineEvent',
                    }
                );
            },
            dismissAll: () => {},
            getPendingRequestList: (list) => {
                return list.filter(
                    ({ status }) => status === HAND_RAISE_REQUEST_STATUS.pending
                );
            },
            getActiveOrPendingRequestList: (list) => {
                return list.filter(({ status }) =>
                    [
                        HAND_RAISE_REQUEST_STATUS.pending,
                        HAND_RAISE_REQUEST_STATUS.accepted,
                        HAND_RAISE_REQUEST_STATUS.active,
                    ].includes(status)
                );
            },
        }),
        [sessionId, airmeetService, user, actionData, dataWriter, channelName]
    );
    return self;
};

export default useRaiseHandActions;

export const useRaiseHandStoreList = () => {
    const reduxStore = useStore();
    const methods = useMemo(
        () => ({
            getRaiseHandRequestList: (sessionId = null) => {
                const state = reduxStore.getState();
                const list = state.HandRaised.requestedList;
                if (!sessionId) {
                    return list;
                }
                return getSessionRaiseHandRequestListSelector(sessionId)(state);
            },
        }),
        [reduxStore]
    );
    return methods;
};

export const getUserRaiseHandRequestSelector = (sessionId, userId) => {
    return (store) => {
        const requests = getSessionRaiseHandRequestListSelector(sessionId)(
            store
        );
        return getMyRequest(requests, userId);
    };
};

export const useSessionRaisedHandRequestList = (sessionId) => {
    const raisedHandRaiseListSelector = useMemo(
        () => getSessionRaiseHandRequestListSelector(sessionId),
        [sessionId]
    );

    return useShallowEqualSelector(raisedHandRaiseListSelector);
};

export const useUserRaiseHandRequest = (sessionId, userId) => {
    const userRaisedHandRequestSelector = useMemo(
        () => getUserRaiseHandRequestSelector(sessionId, userId),
        [sessionId, userId]
    );

    return useShallowEqualSelector(userRaisedHandRequestSelector);
};
