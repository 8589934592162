import { ATTENDANCE_PATH, CONTEXT_TYPE } from 'hooks/attendance/constants';
interface IFluidspaceClientPath {
    airmeetId: string;
    sessionId?: string;
    userId?: string;
    zoneId?: string;
}
class FluidspaceClientPath {
    private readonly airmeetId: string;
    private readonly sessionId: string;
    private readonly userId: string;
    private readonly zoneId: string;
    constructor({
        airmeetId,
        sessionId,
        userId,
        zoneId,
    }: IFluidspaceClientPath) {
        if (!airmeetId) {
            throw new Error(
                'Airmeet Id required to initialize FluidspaceClientPath'
            );
        }
        this.airmeetId = airmeetId;
        this.sessionId = sessionId;
        this.userId = userId;
        this.zoneId = zoneId;
    }
    private get currentFluidSession() {
        return `${this.airmeetId}/socialFloor/${this.sessionId}`;
    }
    private get currentZone() {
        return `${this.currentFluidSession}/zones/${this.zoneId}`;
    }
    private get liveCounter() {
        return `${this.airmeetId}/${ATTENDANCE_PATH}`;
    }
    private get sessionLiveCounter() {
        return `${this.liveCounter}/${CONTEXT_TYPE.FLUID_SPACE}`;
    }
    private get userPresencePath() {
        return `${this.airmeetId}/userPresence`;
    }
    get zoneLiveCounter() {
        return `${this.liveCounter}/${CONTEXT_TYPE.FLUID_SPACE_ZONE}`;
    }
    get sessionUserPresence() {
        return `${this.userPresencePath}/${CONTEXT_TYPE.FLUID_SPACE}-${this.sessionId}`;
    }
    get userLiveCountInCurrentZone() {
        return `${this.zoneLiveCounter}/${this.zoneId}`;
    }
    get userLiveCountInCurrentSession() {
        return `${this.sessionLiveCounter}/${this.sessionId}`;
    }
    get waitingQueue() {
        return `${this.currentFluidSession}/waitingUsers`;
    }
    get waitingQueueUser() {
        return `${this.waitingQueue}/${this.userId}`;
    }
    get userMegaphone() {
        return `${this.currentZone}/megaPhoneUsers/${this.userId}`;
    }
    get selfUserInZone() {
        return `${this.currentZone}/players/${this.userId}`;
    }
    get selfUserReaction() {
        return `${this.selfUserInZone}/reaction`;
    }
    zoneUserPresence(zoneId = this.zoneId) {
        return `${this.userPresencePath}/${CONTEXT_TYPE.FLUID_SPACE_ZONE}-${zoneId}`;
    }
    getLiveCountForZone(zoneId: string) {
        return `${this.zoneLiveCounter}/${zoneId}`;
    }
}

export default FluidspaceClientPath;
