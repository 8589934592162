import { combineReducers, createStore } from 'redux';
import commonMiddleware from '../middleware';
import browserNotification from './BrowserNotification';
import Community from './Community';
import DmNotifications from './DmNotifications';
import dndUsers from './DndUsers';
import FileUploader from './FileUploader';
import HandRaised from './HandRaised';
import leaderboard from './Leaderboard';
import lounge from './Lounge';
import MainNavigation from './MainNavigation';
import meetingList from './MeetingList';
import RHS from './RHS';
import scheduleMeeting from './ScheduleMeeting';
import airmeet from './airmeet';
import airmeetRecorder from './airmeetRecorder';
import auth from './auth';
import UIBanner from './banner';
import blockUserModal from './blockUserModal';
import bookmarkedSessions from './bookmarkedSessions';
import booth from './booth';
import breakout from './breakout';
import { AirmeetStatus, CreateAirmeet } from './channel';
import closedCaptions from './closedCaptions';
import cmDashboard from './cmDashboard';
import addons from './cmDashboard/addons';
import tickets from './cmDashboard/tickets';
import members from './community/members';
import contentHub from './contentHub';
import customReception from './customReception';
import emailLogin from './emailLogin';
import emailSignup from './emailSignup';
import emailVerification from './emailVerification';
import eventSeries from './eventSeries';
import forgotPassword from './forgotPassword';
import genral from './genral';
import groupMeeting from './groupMeeting';
import integrationTab from './integrationTab';
import inviteTableDndUsers from './inviteTableDndUsers';
import layout from './layout';
import leads from './leads';
import leaveSession from './leaveSession';
import liveAirmeet from './liveAirmeet';
import liveAnnouncement from './liveAnnouncement';
import Login from './login';
import meteredPricing from './meteredPricing';
import onboarding from './onboarding';
import onlineUsers from './onlineUsers';
import PeopleSearch from './peopleSearch';
import pipMode from './pipMode';
import profile from './profile';
import recordedVideo from './recordedVideo';
import resources from './resources';
import sessionAccessible from './sessionAccessible';
import systemCheck from './systemCheck';
import Ticketing from './ticketing';
import toasts from './toasts';
import urlParamsBuilder from './urlParamsBuilder';
import userLevelSessionUpdates from './userLevelSessionUpdates';
import stageWaitingScreen from './stageWaitingScreen';

const cReducer = combineReducers({
    auth,
    airmeet,
    AirmeetStatus,
    browserNotification,
    Community,
    CreateAirmeet,
    FileUploader,
    genral,
    HandRaised,
    lounge,
    Login,
    toasts,
    layout,
    airmeetRecorder,
    liveAirmeet,
    emailLogin,
    emailSignup,
    emailVerification,
    forgotPassword,
    cmDashboard,
    integrationTab,
    recordedVideo,
    booth,
    addons,
    tickets,
    UIBanner,
    Ticketing,
    closedCaptions,
    meteredPricing,
    customReception,
    scheduleMeeting,
    meetingList,
    dndUsers,
    leads,
    onlineUsers,
    onboarding,
    members,
    bookmarkedSessions,

    DmNotifications,
    profile,
    leaderboard,
    MainNavigation,
    RHS,
    liveAnnouncement,

    inviteTableDndUsers,
    groupMeeting,

    breakout,
    leaveSession,
    userLevelSessionUpdates,
    PeopleSearch,
    blockUserModal,
    resources,
    pipMode,
    systemCheck,
    sessionAccessible,
    contentHub,
    urlParamsBuilder,
    stageWaitingScreen,
    eventSeries,
});

const getStore = () => {
    return commonMiddleware()(createStore);
};

const store = getStore()(
    cReducer,
    process.env.NODE_ENV === 'development' &&
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
);

export default store;
