import { FEATURE_NAMES } from 'utils/constants/featureNames';
import { logger } from 'utils/logger';
import { FIREBASE_APP_CONFIG } from '../utils/constants/firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';

class FirebaseApp {
    static service;

    static getInstance() {
        if (FirebaseApp.service) {
            return FirebaseApp.service;
        }

        const defaultApp = firebase.apps.find((app) => {
            return app.name === '[DEFAULT]';
        });
        if (defaultApp) {
            logger.debug(
                'Using existing default firebase app for firestore client init'
            );
        } else {
            firebase.initializeApp(FIREBASE_APP_CONFIG);
        }

        var dbRef = firebase.firestore();

        FirebaseApp.service = dbRef;

        return dbRef;
    }
}

export default function useFirestoreClient(featureName) {
    if (featureName !== FEATURE_NAMES.LOUNGE_TABLES) {
        return {};
    }

    const dbRef = FirebaseApp.getInstance();

    return { client: dbRef };
}
