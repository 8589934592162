import { useDispatch, useSelector } from 'react-redux';
import { showFixedModal } from 'store/actions/layout';
import { MODAL_TYPE_UPLOAD_MEDIA } from 'components/modals';
import {
    CLIPS_TAB_ID,
    MEDIA_TAB_ID,
    RECORDINGS_TAB_ID,
} from 'components/community/mediaLibrary/constants';
import { useCallback } from 'react';

const useSelectFromVideos = ({ hideOverlay = true }) => {
    const dispatch = useDispatch();
    const { airmeet } = useSelector((state) => state.cmDashboard);
    const communityId = useSelector(
        (state) => state.Community.current.communityId
    );

    const openSelectFromVideosModal = useCallback(
        ({ onAddMediaSuccess }) => {
            dispatch(
                showFixedModal(MODAL_TYPE_UPLOAD_MEDIA, {
                    communityId: communityId || airmeet.community_id,
                    isThemeV2: true,
                    selectFromVideosOnly: true,
                    onAddMediaSuccess,
                    tabs: [
                        {
                            id: MEDIA_TAB_ID,
                            title: 'Video',
                            disabled: false,
                            searchPlaceholder: 'Search by video name',
                        },
                        {
                            id: RECORDINGS_TAB_ID,
                            title: 'Session recordings',
                            disabled: false,
                            searchPlaceholder: 'Search by session name',
                        },
                        {
                            id: CLIPS_TAB_ID,
                            title: 'Clips',
                            disabled: false,
                            searchPlaceholder: 'Search by clip name',
                        },
                    ],
                    hideOverlay,
                })
            );
        },
        [airmeet, dispatch, hideOverlay, communityId]
    );

    return { openSelectFromVideosModal };
};

export default useSelectFromVideos;
