import has from 'lodash/has';
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { getCurrentTenantData } from 'utils/tenant';
import { FONT_FAMILY } from '../utils';

const defaultChartInfo = {
    labels: Array(5).fill(''),
    data: Array(5).fill(5),
    backgroundColor: Array(5).fill('#26252D'),
};

const primaryColor = getCurrentTenantData('primaryColor');
const defaultColor = `hsl(${primaryColor.H},${primaryColor.S}%,${primaryColor.L}%)`;

export default function BarChart({
    chartLabels,
    chartData,
    backgroundColor = defaultColor,
    hoverBackgroundColor = null,
    label = 'Count',
    isEmpty = false,
    datasets = null,
    displayLegend = true,
    maxBarThickness = 72,
    responsive = true,
    maintainAspectRatio = true,
    xAxes = null,
    yAxes = null,
    options: optionsProp = {},
}) {
    const chartOptions = useMemo(() => {
        const options = {
            responsive,
            maintainAspectRatio,
            legend: {
                display: displayLegend,
                position: 'right',
                align: 'center',
                labels: {
                    usePointStyle: true,
                    boxWidth: 6,
                    font: {
                        family: FONT_FAMILY,
                        size: 10,
                        weight: 400,
                    },
                    color: '#BEBCC8',
                },
            },
            scales: {
                xAxes: xAxes || [
                    {
                        stacked: true,
                        ticks: {},
                        maxBarThickness,
                    },
                ],
                yAxes: yAxes || [
                    {
                        stacked: true,
                        ticks: { precision: 0, beginAtZero: true },
                    },
                ],
            },
            ...(optionsProp || {}),
        };

        if (isEmpty) {
            if (has(options, 'scales.yAxes.0.ticks')) {
                options.scales.yAxes[0].ticks.max = 500;
                options.scales.yAxes[0].ticks.display = false;
            }
            if (has(options, 'scales.xAxes.0.ticks')) {
                options.scales.xAxes[0].ticks.display = false;
            }
            options.animation = {};
            options.tooltips = {
                enabled: false,
            };
        }

        return options;
    }, [
        isEmpty,
        displayLegend,
        maxBarThickness,
        xAxes,
        yAxes,
        maintainAspectRatio,
        responsive,
        optionsProp,
    ]);

    const datasetObject = useMemo(() => {
        const dataObj = {
            label: isEmpty ? '' : label,
            data: isEmpty ? defaultChartInfo?.data : chartData,
            backgroundColor: isEmpty
                ? defaultChartInfo?.backgroundColor
                : backgroundColor,
        };

        if (hoverBackgroundColor) {
            dataObj.hoverBackgroundColor = hoverBackgroundColor;
        }

        return dataObj;
    }, [backgroundColor, chartData, hoverBackgroundColor, isEmpty, label]);

    return (
        <Bar
            data={{
                labels:
                    (isEmpty ? defaultChartInfo?.labels : chartLabels) || [],
                datasets: datasets || [datasetObject],
            }}
            options={chartOptions}
        />
    );
}
