let globalID = 0;
function* generator(baseID, global, debugPrefix = '') {
    while (true) {
        if (global) globalID++;
        else baseID++;
        yield global ? globalID : baseID;
    }
}

const groups = {};

export const globalIDs = generator(globalID, true);
export const groupsIDs = (groupName, baseID = 0, debugPrefix = '') => {
    if (!groups[groupName]) {
        groups[groupName] = generator(
            baseID,
            false,
            `${debugPrefix ? debugPrefix + ':' : ''}${groupName}:`
        );
    }

    return groups[groupName];
};
export const localIDs = (baseID, debugPrefix = '') =>
    generator(baseID, false, debugPrefix);
