import Button from 'atoms/Button/button';
import Modal from 'atoms/Modal/modal';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import {
    Box,
    FlexDiv,
    StyledImage,
} from 'components/molecules/utils/basicComponents';
import { alpha } from 'foundations/theme';
import { Typography } from 'foundations/typography';
import React from 'react';
import styled from 'styled-components';
import { emptyObject, noop } from 'utils/constants/common';

type TitleProps = {
    variant?: string;
    text: string;
    props?: object;
};

type SubtitleProps = TitleProps;

type NoteProps = TitleProps;

type ImageProps = {
    src: string;
    props?: object;
};

type ButtonProps = {
    variant: string;
    text: string;
    props?: object;
    onClick: Function;
    width?: string;
};

type InfoTypes = {
    variant?: string;
    icon?: string;
    text?: string;
    list?: string[] | TitleProps[];
    props?: object;
    iconProps?: object;
};

type InfoProps = InfoTypes;

interface ConformationModalProps {
    title?: TitleProps;
    subtitle?: SubtitleProps;
    icon?: string | null;
    onClose?: Function;
    modalStyles?: object;
    contentStyles?: object;
    buttonWrapperStyles?: object;
    image?: ImageProps | null;
    buttons?: ButtonProps[];
    note?: NoteProps;
    showCloseIcon?: boolean;
    showButtonsSeparator?: boolean;
    overlayBackground?: string | null;
    info?: InfoProps;
}

const ConfirmationModal: React.FC<ConformationModalProps> = ({
    title,
    subtitle,
    icon = null,
    onClose = noop,
    modalStyles = emptyObject,
    contentStyles = emptyObject,
    buttonWrapperStyles = emptyObject,
    image = null,
    buttons = [],
    note = null,
    showCloseIcon = false,
    showButtonsSeparator = true,
    overlayBackground = null,
    info = null,
}) => {
    return (
        <Modal
            style={{
                maxWidth: '416px',
                top: '35%',
                transform: 'translateY(-35%)',
                height: 'auto',
                ...modalStyles,
            }}
            wrapperStyle={{
                backdropFilter: 'blur(4px)',
                backgroundColor: overlayBackground
                    ? overlayBackground
                    : 'rgba(0, 0, 0, 0.7)',
            }}
            showCloseIcon={showCloseIcon}
            onClose={onClose}
        >
            <FlexDiv
                flexDirection='column'
                alignItems='center'
                background='linear-gradient(33deg,rgba(29,28,34,1) 0%,rgba(48,43,74,0.7) 70%,rgba(56,49,91,0.7) 100%)'
                pt={5}
                {...contentStyles}
            >
                {image?.src ? (
                    <StyledImage
                        src={image.src}
                        width='140px'
                        {...image.props}
                    />
                ) : icon ? (
                    <StyledSvgSprite icon={icon} />
                ) : null}
                {title?.text ? (
                    <Typography
                        variant={title?.variant || 'h5'}
                        color='ambience.0'
                        textAlign='center'
                        mt={4}
                        mb={3}
                        {...title?.props}
                    >
                        {title.text}
                    </Typography>
                ) : null}
                {Array.isArray(info?.list) && info?.list?.length ? (
                    <FlexDiv {...info?.props}>
                        {info?.icon ? (
                            <Box height='fit-content'>
                                <StyledSvgSprite
                                    icon={info.icon}
                                    {...info?.iconProps}
                                />
                            </Box>
                        ) : null}
                        <StyledList>
                            {info.list.map((item) =>
                                item ? (
                                    <li>
                                        <Typography
                                            variant={
                                                item?.variant || 'subtext2'
                                            }
                                            color='ambience.6'
                                            {...item?.props}
                                        >
                                            {item?.text || item}
                                        </Typography>
                                    </li>
                                ) : null
                            )}
                        </StyledList>
                    </FlexDiv>
                ) : info?.text || info ? (
                    <Typography
                        variant={info?.variant || 'subtext2'}
                        color='ambience.6'
                        textAlign='center'
                        mb='20px'
                        {...info?.props}
                    >
                        {info?.text || info}
                    </Typography>
                ) : null}
                {subtitle?.text ? (
                    <Typography
                        variant={subtitle?.variant || 'subtext2'}
                        color='ambience.6'
                        textAlign='center'
                        mb='20px'
                        {...subtitle?.props}
                    >
                        {subtitle.text}
                    </Typography>
                ) : null}
                {note?.text && (
                    <Typography
                        variant={note.variant || 'subtext2-bold'}
                        color='ambience.6'
                        textAlign='center'
                        mb='20px'
                        {...note?.props}
                    >
                        {note.text}
                    </Typography>
                )}
                {buttons.length ? (
                    <ButtonGroups
                        showButtonsSeparator={showButtonsSeparator}
                        py='20px'
                        {...buttonWrapperStyles}
                    >
                        {buttons.map((button, index) => (
                            <Button
                                key={`${button.variant}-${button.text}-${index}`}
                                variant={button.variant}
                                width={button.width}
                                onClick={button.onClick}
                                {...button?.props}
                            >
                                {button.text}
                            </Button>
                        ))}
                    </ButtonGroups>
                ) : null}
            </FlexDiv>
        </Modal>
    );
};

export default ConfirmationModal;

const ButtonGroups = styled(FlexDiv)`
    width: 100%;
    justify-content: center;
    ${({ theme, showButtonsSeparator }) =>
        showButtonsSeparator &&
        `border-top: 1px solid ${alpha(theme.colors.ambience[0], 0.1)};`}
`;

const StyledList = styled.ul`
    padding-inline-start: 20px;
    margin: 0px;
`;
