import { SESSION_TYPE_HOSTING } from 'components/community/editPublicAirmeet/constants';
import { useEffect } from 'react';
import { SESSION_STATUS_ONGOING } from 'store/constants';
import { logger } from 'utils/logger';
import { isHost, isOrganizer } from 'utils/users';
import { ALL_FEATURES } from './useFeatureControl';

const useEnableStageVisibility = ({
    firebaseClient,
    currentAirmeet = {},
    enabledFeatures = [],
    userRole,
}) => {
    const {
        sessions,
        airmeetId,
        enableStageVisibilityControl,
    } = currentAirmeet;

    const isFeatureEnable =
        enableStageVisibilityControl ||
        !(enabledFeatures || []).includes(
            ALL_FEATURES.DISABLE_STAGE_VISIBILITY_CONTROL
        );

    useEffect(() => {
        if (
            !firebaseClient ||
            !airmeetId ||
            isFeatureEnable ||
            (!isOrganizer(userRole) && !isHost(userRole))
        )
            return;

        const isAnyOngoingSession = (sessions || []).find((session) => {
            return (
                session.type === SESSION_TYPE_HOSTING &&
                session.status === SESSION_STATUS_ONGOING
            );
        });

        if (isAnyOngoingSession) return;

        const hideSpeakerFeatureKey = `${airmeetId}/featureControl/features/blacklist/disableStageVisibilityControl`;
        firebaseClient.setData(hideSpeakerFeatureKey, true);
        logger.info('Enabled stage visibility control from hook');
    }, [sessions, firebaseClient, airmeetId, isFeatureEnable]);
};
export default useEnableStageVisibility;
