import { StyledSvgSprite } from 'components/general/SVGSprite';
import SmartUploader from 'components/general/SmartUploader';
import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import { useMainNavigation } from 'context/MainNavigation';
import { Text } from 'foundations/themeV2/text/';
import { usePrevious } from 'hooks/common';
import useCommunityPlanInfo from 'hooks/community/useCommunityPlanInfo';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import { USER_ROLE } from 'utils/constants/users';
import { s3toCDNImage } from 'utils/core';
import ImageSelector from '../../../widgets/ImageSelector';
import GenerateBackgroundImageSuggestions from './GenerateBackgroundImageSuggestions';
import PreviewImageDrawer from './PreviewImageDrawer';
import {
    DEFAULT_BACKGROUND_BLUR,
    DEFAULT_BACKGROUND_IMAGES,
    DEFAULT_BACKGROUND_OPACITY,
    DEFAULT_BACKGROUND_ZOOM,
} from './utils';

const { EXHIBITOR, HOST, SPEAKER, ORGANIZER, COHOST } = USER_ROLE;
const UPLOAD_IMAGE_REQUEST_KEY = 'customBackgroundImage';

export default function ImageBackground({ onChange, value }) {
    const { setOutsideClickDisabled } = useMainNavigation();
    const [isAirGenieDrawerOpen, setIsAirGenieDrawerOpen] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);
    const [uploadCallbacks, setCallbacks] = useState({ removeImg: () => {} });
    const uploadedImgUrl = useSelector(
        (state) => state.FileUploader.files[UPLOAD_IMAGE_REQUEST_KEY]?.imageURL
    );
    const prevUploadedImgUrl = usePrevious(uploadedImgUrl);
    const {
        firebaseClient,
        featuresConfig,
        platformConfig,
        airmeet: {
            data: {
                currentAirmeet: { status, community_id: communityId },
                userRole,
            },
        },
    } = useLiveAirmeetContext();
    const { isPaidPlan } = useCommunityPlanInfo(communityId);

    const showAirmeetStatusBar = useMemo(
        () =>
            [
                AIRMEET_STATUS.CREATED,
                AIRMEET_STATUS.PAUSED,
                AIRMEET_STATUS.FINISHED,
                AIRMEET_STATUS.PRE_EVENT_ACCESS,
            ].includes(status) &&
            [EXHIBITOR, COHOST, HOST, ORGANIZER, SPEAKER].includes(userRole),
        [status, userRole]
    );

    const topValue = () => {
        if (showAirmeetStatusBar) {
            return '64px';
        } else {
            return '0px';
        }
    };

    useEffect(() => {
        if (uploadedImgUrl && uploadedImgUrl !== prevUploadedImgUrl) {
            setPreviewImageUrl(uploadedImgUrl);
        }
    }, [uploadedImgUrl, prevUploadedImgUrl]);

    const handleImageChange = (image) => {
        onChange &&
            onChange({
                ...(value || {}),
                value: image?.url,
                meta: {
                    ...(value?.meta || {}),
                    zoom: DEFAULT_BACKGROUND_ZOOM,
                    blur: DEFAULT_BACKGROUND_BLUR,
                    opacity: DEFAULT_BACKGROUND_OPACITY,
                },
            });
    };
    const handleUploaderCallbacks = (prop) => {
        setCallbacks({ ...prop });
    };
    const imageList = useMemo(() => {
        return [...DEFAULT_BACKGROUND_IMAGES, ...(value?.meta?.images || [])];
    }, [value]);

    const handleDrawerClose = () => {
        setPreviewImageUrl(null);
    };

    const handleEditImage = (image) => {
        setPreviewImageUrl(image?.url);
    };

    const handleDeleteImage = (image, isChecked) => {
        onChange &&
            onChange(
                cloneDeep({
                    ...(value || {}),
                    value: isChecked ? null : value?.value,
                    meta: {
                        ...(value?.meta || {}),
                        images: (value?.meta?.images || []).filter(
                            (img) => img?.url !== image?.url
                        ),
                    },
                })
            );
    };

    const onUploadSuccess = ({ file }, fileData) => {
        setPreviewImageUrl(file?.url);
        setFileData(fileData);
        onUploadDrawerClose();
    };

    const handleAirGenieDrawerClose = () => {
        setOutsideClickDisabled(false);
        setIsAirGenieDrawerOpen(false);
    };

    const openAirGenieImageDrawer = () => {
        onUploadDrawerClose();
        setOutsideClickDisabled(true);
        setIsAirGenieDrawerOpen(true);
    };

    const onUploadClick = () => {
        setOutsideClickDisabled(true);
        setIsUploadDrawerOpen(true);
    };

    const onUploadDrawerClose = () => {
        setOutsideClickDisabled(false);
        setIsUploadDrawerOpen(false);
    };

    return (
        <Box position='relative'>
            {isPaidPlan ? (
                <AutoGenerateWrapper
                    alignItems='center'
                    onClick={openAirGenieImageDrawer}
                >
                    <StyledSvgSprite
                        icon='magic_wand'
                        fill='module.airgenie'
                        mr='x2'
                        height='20px'
                        width='20px'
                    />
                    <Text variant='caption-bold' color='module.airgenie'>
                        Auto-generate image
                    </Text>
                </AutoGenerateWrapper>
            ) : null}
            {Array.isArray(imageList) && imageList.length > 0 && (
                <ImageSelector
                    images={imageList}
                    onChange={handleImageChange}
                    value={value?.value}
                    uploadFileId={UPLOAD_IMAGE_REQUEST_KEY}
                    showEditOption={true}
                    showDeleteOption={true}
                    onEditImage={handleEditImage}
                    onDeleteImage={handleDeleteImage}
                    showUploadOption={true}
                    onUploadClick={onUploadClick}
                    isPaidPlan={isPaidPlan}
                    handleUploaderCallbacks={handleUploaderCallbacks}
                />
            )}
            <Text variant='caption' color='text.default.secondary'>
                Recommended Dimensions, Format, Size: 1440 x 720px | JPG, JPEG,
                PNG | Maximum up to 5 MB
            </Text>
            {previewImageUrl && (
                <PreviewImageDrawer
                    fileData={fileData}
                    imageUrl={s3toCDNImage(previewImageUrl)}
                    isDefaultImage={
                        !!imageList.find((img) => img?.url === previewImageUrl)
                            ?.isDefault
                    }
                    value={value}
                    onChange={onChange}
                    onRemoveImage={uploadCallbacks.removeImg}
                    onClose={handleDrawerClose}
                    showInsidePortal={true}
                />
            )}
            {isUploadDrawerOpen && (
                <SmartUploader
                    onClose={onUploadDrawerClose}
                    onUploadSuccess={onUploadSuccess}
                    openAirGenieImageDrawer={openAirGenieImageDrawer}
                    uploadFileId={UPLOAD_IMAGE_REQUEST_KEY}
                    drawerHeight={
                        showAirmeetStatusBar ? 'calc(100vh - 64px)' : '100vh'
                    }
                    hideAirGenieImageGen={!isPaidPlan}
                    top={topValue()}
                    showInsidePortal
                />
            )}
            {isAirGenieDrawerOpen && (
                <GenerateBackgroundImageSuggestions
                    value={value}
                    onChange={onChange}
                    onClose={handleAirGenieDrawerClose}
                    showInsidePortal
                    firebaseClient={firebaseClient}
                    featuresConfig={featuresConfig}
                    platformConfig={platformConfig}
                />
            )}
        </Box>
    );
}

const AutoGenerateWrapper = styled(FlexDiv)`
    padding: ${({ theme }) => `${theme.space.x1} ${theme.space.x3}`};
    border: 1px solid ${({ theme }) => theme.colors.border.default1};
    border-radius: ${({ theme }) => theme.radii.x4};
    background: ${({ theme }) => theme.colors.grey.fill2};
    position: absolute;
    right: 0;
    top: -40px;
    cursor: pointer;
`;
