const defaultBrand = {
    light100: 'rgba(241, 241, 241, 1)',
    dark100: 'rgba(15, 15, 15, 1)',
};

const bw = {
    black100: 'rgba(15, 15, 15, 1)',
    black60: 'rgba(15, 15, 15, 0.6)',
    black40: 'rgba(15, 15, 15, 0.4)',
    black20: 'rgba(15, 15, 15, 0.2)',
    black5: 'rgba(15, 15, 15, 0.05)',
    white100: 'rgba(241, 241, 241, 1)',
    white60: 'rgba(241, 241, 241, 0.6)',
    white40: 'rgba(241, 241, 241, 0.4)',
    white20: 'rgba(241, 241, 241, 0.2)',
    white10: 'rgba(241, 241, 241, 0.1)',
};

const semantic = [
    'rgba(255, 204, 130, 1)',
    'rgba(109, 198, 151, 1)',
    'rgba(250, 127, 127, 1)',
    'rgba(147, 179, 243, 1)',
    'rgba(149, 99, 29, 1)',
    'rgba(41, 122, 78, 1)',
    'rgba(201, 54, 54, 1)',
    'rgba(43, 103, 222, 1)',
];

const grey = [
    'rgba(38, 37, 45, 1)',
    'rgba(136, 136, 136, 1)',
    'rgba(57, 55, 67, 0.5)',
    'rgba(242, 242, 242, 1)',
    'rgba(229, 229, 229, 0.6)',
    'rgba(255, 255, 255, 0.4)',
];

const purple = ['rgba(149, 128, 255, 1)', 'rgba(106, 76, 255, 1)'];
const pink = ['rgba(238, 81, 175, 1)', 'rgba(194, 19, 123, 1)'];

const others = ['rgba(233, 233, 237, 1)'];

const highContrast = ['rgba(255, 255, 1, 1)', 'rgba(110, 232, 252, 1)'];

const viz = {
    carrot: {
        '100': 'rgba(251, 228, 201, 1)',
        '600': 'rgba(242, 159, 67, 1)',
        '800': 'rgba(217, 121, 15, 1)',
        '00': 'rgba(145, 81, 10, 1)',
    },
    brick: {
        '100': 'rgba(250, 219, 223, 1)',
        '600': 'rgba(215, 41, 62, 1)',
        '800': 'rgba(176, 27, 44, 1)',
        '00': 'rgba(125, 19, 32, 1)',
    },
    rose: {
        '100': 'rgba(252, 226, 242, 1)',
        '600': 'rgba(238, 81, 175, 1)',
        '800': 'rgba(194, 19, 123, 1)',
        '00': 'rgba(97, 9, 61, 1)',
    },
    orchid: {
        '100': 'rgba(240, 228, 247, 1)',
        '600': 'rgba(164, 95, 210, 1)',
        '800': 'rgba(127, 50, 179, 1)',
        '00': 'rgba(64, 25, 89, 1)',
    },
    blush: {
        '100': 'rgba(234, 230, 252, 1)',
        '600': 'rgba(130, 106, 237, 1)',
        '800': 'rgba(83, 50, 230, 1)',
        '00': 'rgba(38, 16, 136, 1)',
    },
    sky: {
        '100': 'rgba(223, 240, 255, 1)',
        '600': 'rgba(62, 165, 252, 1)',
        '800': 'rgba(2, 94, 173, 1)',
        '00': 'rgba(1, 47, 86, 1)',
    },
    sea: {
        '100': 'rgba(219, 246, 248, 1)',
        '600': 'rgba(41, 199, 214, 1)',
        '800': 'rgba(29, 142, 153, 1)',
        '00': 'rgba(20, 99, 107, 1)',
    },
    spring: {
        '100': 'rgba(212, 245, 225, 1)',
        '600': 'rgba(1, 195, 126, 1)',
        '800': 'rgba(0, 152, 98, 1)',
        '00': 'rgba(0, 101, 65, 1)',
    },
};

export const colors = {
    dark: {
        brandDefault: defaultBrand.light100,
        brandDashboard: purple[0],
        text: {
            default: {
                primary: bw.white100,
                secondary: bw.white60,
                disabled: bw.white20,
            },
            other: {
                primary: bw.black100,
                secondary: bw.black60,
                disabled: bw.black20,
            },
        },
        fill: {
            default1: grey[0],
            default2: bw.black40,
            default3: bw.black20,
            other1: bw.white100,
            other2: bw.white60,
            other3: bw.white10,
        },
        border: {
            default1: bw.white20,
            default2: bw.white10,
            other1: bw.white60,
            other2: bw.black60,
        },
        overlay: {
            default1: bw.black60,
        },
        sem: {
            warning: semantic[0],
            success: semantic[1],
            error: semantic[2],
            info: semantic[3],
        },
        grey: {
            fill1: grey[1],
            fill2: grey[2],
        },
        module: {
            airgenie: pink[0],
            viz,
        },
        background: {
            card: grey[2],
            page: bw.black100,
        },
    },
    light: {
        brandDefault: defaultBrand.dark100,
        brandDashboard: purple[1],
        text: {
            default: {
                primary: bw.black100,
                secondary: bw.black60,
                disabled: bw.black20,
            },
            other: {
                primary: bw.white100,
                secondary: bw.white60,
                disabled: bw.white20,
            },
        },
        fill: {
            default1: grey[3],
            default2: bw.white40,
            default3: bw.white40,
            other1: bw.black100,
            other2: bw.black60,
            other3: bw.black5,
        },
        border: {
            default1: bw.black20,
            default2: bw.black5,
            other1: bw.black60,
            other2: bw.white60,
        },
        overlay: {
            default1: bw.black60,
        },
        sem: {
            warning: semantic[4],
            success: semantic[5],
            error: semantic[6],
            info: semantic[7],
        },
        grey: {
            fill1: grey[1],
            fill2: grey[4],
        },
        module: {
            airgenie: pink[1],
            viz,
        },
        background: {
            card: bw.white40,
            page: others[0],
        },
    },
    highContrast: {
        brandDefault: highContrast[0],
        brandDashboard: highContrast[0],
        text: {
            default: {
                primary: highContrast[1],
                secondary: bw.white100,
                disabled: bw.white100,
            },
            other: {
                primary: bw.black100,
                secondary: bw.black100,
                disabled: bw.black100,
            },
        },
        fill: {
            default1: bw.black100,
            default2: bw.black100,
            default3: bw.black100,
            other1: bw.black100,
            other2: bw.black100,
            other3: bw.black100,
        },
        border: {
            default1: bw.white100,
            default2: bw.white100,
            other1: bw.white100,
            other2: bw.black100,
        },
        overlay: {
            default1: bw.black100,
        },
        sem: {
            warning: highContrast[1],
            success: highContrast[1],
            error: highContrast[1],
            info: highContrast[1],
        },
        grey: {
            fill1: bw.white100,
            fill2: bw.black100,
        },
        module: {
            airgenie: pink[0],
            viz,
        },
        background: {
            card: bw.black100,
            page: bw.black100,
        },
    },
};
