import _isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
function useLocalStorage(key, defaultValue = '') {
    const user = useSelector((state) => state.auth.user);
    const [state, setState] = useState(
        () => JSON.parse(window.localStorage.getItem(key)) || defaultValue
    );
    const localStorageVal =
        (window.localStorage.getItem(key) &&
            JSON.parse(window.localStorage.getItem(key))) ||
        null;
    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(state));
    }, [state, key]);
    useEffect(() => {
        if (localStorageVal !== null) {
            if (!user) {
                if (!_isEqual(state, localStorageVal)) {
                    window.location.reload();
                }
            }
        }
    }, [localStorageVal]);

    return [state, setState];
}

export default useLocalStorage;
