import { cdnImage } from 'utils/core';

export const VIEW_SUPPORTED_BROWSERS_LINK =
    'https://help.airmeet.com/support/solutions/articles/82000443316-which-browsers-does-airmeet-support-';

export const UPDATE_BROWSER_LINKS = {
    Chrome: 'https://www.google.com/chrome/',
    Firefox: 'https://www.mozilla.org/en-US/firefox/new/',
    Edge: 'https://www.microsoft.com/en-us/edge',
};

export const SHOW_WELCOME_SCREEN_TIMEOUT = 100;

export const LOADING_EVENTS_TYPES = {
    browserCheck: 'browserCheck',
    networkCheck: 'networkCheck',
    explicitFirewallCheck: 'explicitFirewallCheck',
    checksComplete: 'checksComplete',
};

export const EventLoadingStates = {
    browserCheck: {
        title: 'Checking your browser',
        progress: 25,
        img_path: cdnImage('/images/eventLoader/validating-browser-emoji.svg'),
    },
    networkCheck: {
        title: 'Analyzing your network settings',
        progress: 50,
        img_path: cdnImage('/images/eventLoader/checking-network-settings.svg'),
    },
    explicitFirewallCheck: {
        title: 'Give us a few more seconds',
        subtitle:
            'Looks like you are behind a firewall, we are trying to connect you via proxy server',
        progress: 75,
        img_path: cdnImage('/images/eventLoader/checking-firewall.svg'),
    },
    checksComplete: {
        title: 'Welcome',
        progress: 100,
        img_path: cdnImage('/images/eventLoader/greeting-user.svg'),
    },
};

export const NETWORK_ERROR_TYPES = {
    firewallError: 'firewallError',
    firebaseError: 'firebaseError',
};

export const NetworkErrorTypes = {
    firewallError: {
        reasonForError: 'firewall settings',
        linkToResolve:
            'https://help.airmeet.com/support/solutions/articles/82000443373-how-do-i-prepare-my-network-in-case-of-firewall-or-vpn-to-access-airmeet-',
    },
    firebaseError: {
        reasonForError: 'DNS configuration',
        linkToResolve:
            'https://help.airmeet.com/en/support/solutions/articles/82000630120-how-to-check-your-dns-configuration-',
    },
};

export const NonInteractiveDrawbacks = [
    'You will experience a 30 second lag in the sessions.',
    'You will not be able to engage in the session via raise hand, emojis, Q&A and polls.',
    'You will be missing out on the networking session in the social lounge.',
];
