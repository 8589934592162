import { useEffect, useRef } from 'react';
import { updateFilterSupport } from 'utils/FilterManager';
import FilterStorageManager from 'utils/FilterStorageManager';
import firebaseLogger from 'utils/firebaseLogger';

const useFilterSupport = ({ userId, airmeetId }) => {
    FilterStorageManager.setAirmeetId(airmeetId);

    const logFilterSupport = useRef(null);
    logFilterSupport.current = (isSupported = false) => {
        firebaseLogger.filterSupported({
            user_id: userId,
            airmeet_id: airmeetId,
            status: isSupported,
        });
    };

    useEffect(() => {
        const checkSupport = async () => {
            const supportedStatus = await updateFilterSupport();
            logFilterSupport.current(supportedStatus);
        };
        checkSupport();
    }, []);
};

export default useFilterSupport;
