export const FEATURE_NAMES = {
    STAGE_REACTIONS: 'reactions',
    USER_PRESENCE: 'userPresence',
    EVENT_CHAT: 'EVENT_CHAT',
    SESSION_CHAT: 'SESSION_CHAT',
    STAGE_CHAT: 'STAGE_CHAT',
    DM_CHAT: 'DM_CHAT',
    EVENT_CHAT_MODERATION: 'EVENT_CHAT_MODERATION',
    SESSION_CHAT_MODERATION: 'SESSION_CHAT_MODERATION',
    MODERATION_TOGGLE_LEVEL: 'MODERATION_TOGGLE_LEVEL',
    TABLE_INVITE: 'TABLE_INVITE',
    SOCIAL_LOUNGE: 'SOCIAL_LOUNGE',
    LOUNGE_TABLES: 'LOUNGE_TABLES',
    QUESTIONS: 'QUESTIONS',
    REPORT_MESSAGE: 'REPORT_MESSAGE',
    STAGE_RAISE_HAND: 'STAGE_RAISE_HAND',
    BREAKOUT_ROOMS: 'BREAKOUT_ROOMS',
    LIVE_VIDEO_ANNOUNCEMENT: 'LIVE_VIDEO_ANNOUNCEMENT',
    FLUID_SPACE: 'fluidSpace',
    USE_MEGAPHONE: 'USE_MEGAPHONE',
    FLUID_SPACE_REACTION: 'fluidSpaceReaction',
    QNACOMMENTS: 'QNACOMMENTS',
    TXN_UPDATES: 'txnUpdates',
    CHAT_REACTION: 'CHAT_REACTION',
    USER_PROFILE: 'USER_PROFILE',
    DEFAULT: 'DEFAULT',
    STAGE: 'stage',
    STAGE_INVITE: 'STAGE_INVITE',
    USER_STAGE_VISIBILITY: 'USER_STAGE_VISIBILITY',
    ONLINE_EXHIBITORS: 'ONLINE_EXHIBITORS',
    SOCIAL_STREAMING: 'SOCIAL_STREAMING',
    STAGE_LAYOUT: 'STAGE_LAYOUT_CONTROLS',
    SHOW_ON_STAGE: 'SHOW_ON_STAGE',
    SHOW_ON_STAGE_CONTENT: 'SHOW_ON_STAGE_CONTENT',
    SPEAKER_QUESTION_MODERATION: 'SPEAKER_QUESTION_MODERATION',
    STAGE_BANNER: 'STAGE_BANNER',
    USER_HEAR_BEAT: 'USER_HEAR_BEAT',
    STREAM_PUBLISHER: 'STREAM_PUBLISHER',
    BOOTH_TABLES: 'BOOTH_TABLES',
    BREAKOUT_TABLES: 'BREAKOUT_TABLES',
};

export const FEATURE_ACTIONS = {
    QUESTIONS: {
        ADD_QUESTION: 'addQuestion',
        UPDATE_QUESTION_STATUS: 'updateQuestionStatus',
        TOGGLE_ANSWER_STATUS: 'toggleAnswerStatus',
        UPVOTE_QUESTION: 'upvoteQuestion',
        REMOVE_QUESTION: 'removeQuestion',
    },
    REPORT_MESSAGE: {
        ADD_REPORT: 'addReport',
        UPDATE_REPORT: 'updatedReport',
    },
    DM_CHAT: {
        BLOCK_DM: 'blockDMChat',
        ACCEPT_DM: 'acceptDMChat',
        SEND_CHAT: 'sendChat',
        CREATE_ROOM: 'createRoom',
        UPDATE_LAST_VIEWED: 'updateLastViewed',
    },
    [FEATURE_NAMES.EVENT_CHAT]: {
        ADD_MESSAGE: 'ADD_MESSAGE',
        REMOVE_MESSAGE: 'REMOVE_MESSAGE',
    },
    [FEATURE_NAMES.SESSION_CHAT]: {
        ADD_MESSAGE: 'ADD_MESSAGE',
        REMOVE_MESSAGE: 'REMOVE_MESSAGE',
    },
    [FEATURE_NAMES.CHAT_REACTION]: {
        TOGGLE_REACTION: 'toggleReaction',
    },
    [FEATURE_NAMES.SOCIAL_LOUNGE]: {
        ADD_USER_TABLE: 'addUserTable',
        ADD_ORG_TABLE: 'addOrgTable',
        ADD_MULTIPLE_TABLE: 'addMultipleTable',
        DELETE_TABLE: 'deleteTable',
        DELETE_MULTIPLE_TABLE: 'deleteMultipleTable',
        UPDATE_TABLE: 'updateTable',
        UPDATE_CONFIG: 'updateConfig',
        UPDATE_FLAG: 'updateFlag',
        UPDATE_MODERATION_SETTING: 'updateModerationSetting',
    },
    [FEATURE_NAMES.LOUNGE_TABLES]: {
        JOIN: 'JOIN',
        LEAVE: 'LEAVE',
        RESERVE_SCREEN: 'RESERVE_SCREEN',
        LEAVE_SCREEN: 'LEAVE_SCREEN',
        MUTE_OTHER_USER_AUDIO: 'MUTE_OTHER_USER',
        MUTE_OTHER_USER_VIDEO: 'MUTE_OTHER_USER_VIDEO',
    },
    [FEATURE_NAMES.FLUID_SPACE]: {
        FIND_ZONE: 'FIND_ZONE',
    },
    [FEATURE_NAMES.QNACOMMENTS]: {
        ADD_COMMENT: 'addComment',
        MARK_ANSWER: 'markAnswer',
        POST_COMMENT_AS_ANSWER: 'postCommentAsAnswer',
        REMOVE_COMMENT: 'removeComment',
        DECREMENT_COMMENT: 'decrementComment',
    },
    [FEATURE_NAMES.LIVE_VIDEO_ANNOUNCEMENT]: {
        RESERVE: 'RESERVE',
        LEAVE: 'LEAVE',
    },
    [FEATURE_NAMES.STAGE_INVITE]: {
        SEND_INVITE: 'SEND_INVITE',
        REMOVE_FROM_STAGE: 'REMOVE_FROM_STAGE',
        CANCEL_INVITE: 'CANCEL_INVITE',
        UPDATE_USER_INVITATION_DATA: 'UPDATE_USER_INVITATION_DATA',
        UPDATE_INIVITATION_STATUS: 'UPDATE_INIVITATION_STATUS',
    },
    [FEATURE_NAMES.ONLINE_EXHIBITORS]: {
        JOIN: 'JOIN',
        LEAVE: 'LEAVE',
    },
    [FEATURE_NAMES.SOCIAL_STREAMING]: {
        START: 'START',
        STOP: 'STOP',
    },
    [FEATURE_NAMES.STAGE_LAYOUT]: {
        CHANGE_LAYOUT: 'CHANGE_LAYOUT',
        CHANGE_LAYOUT_MODE: 'CHANGE_LAYOUT_MODE',
        PIN: 'PIN',
        UNPIN: 'UNPIN',
        FOCUS: 'FOCUS',
        UPDATE_ORDER: 'UPDATE_ORDER',
    },
    [FEATURE_NAMES.SHOW_ON_STAGE]: {
        ADD: 'ADD',
        REMOVE: 'REMOVE',
    },
    [FEATURE_NAMES.SHOW_ON_STAGE_CONTENT]: {
        ADD: 'ADD',
        REMOVE: 'REMOVE',
        CLEAR: 'CLEAR',
    },
    [FEATURE_NAMES.STAGE_BANNER]: {
        ADD: 'ADD',
        UPDATE: 'UPDATE',
        DELETE: 'DELETE',
        SHOW: 'SHOW',
        HIDE: 'HIDE',
        ADD_IMAGE: 'ADD_IMAGE',
        DELETE_IMAGE: 'DELETE_IMAGE',
    },
    [FEATURE_NAMES.USER_HEAR_BEAT]: {
        WRITE: 'WRITE',
    },
    [FEATURE_NAMES.STREAM_PUBLISHER]: {
        ADD: 'ADD',
        REMOVE: 'REMOVE',
        ON_DISCONNECT: 'ON_DISCONNECT',
    },
};

export const SERVER_FEATURE_NAME = {
    ...FEATURE_NAMES,
    EVENT_CHAT: 'event_chat',
    SESSION_CHAT: 'session_chat',
    CHAT_REACTION: 'CHAT_REACTION',
};

export const PRESENCE_ACTION_TYPE = {
    JOIN: 'JOIN',
    LEAVE: 'LEAVE',
};

export const DATA_SOURCE = {
    FIREBASE: 'firebase',
    FIRESTORE: 'firestore',
    ABLY: 'ably',
};

export const SHARD_FEATURE_NAMES = {
    REACTIONS: 'reactions',
    CHAT: 'chat',
    PRIMARY: 'primary',
};
