import { Typography } from 'foundations/typography';
import isNumber from 'lodash/isNumber';
import React, { useContext } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { cdnImage } from 'utils/core';
import { AnalyticsContext } from '../AnalyticsProvider';
import { useSelector } from 'react-redux';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Chart,
    Legend,
    Loading,
    LoadingWrapper,
} from '../styledComponents';
import { commonDoughnutOptions } from '../utils';
import { getLiveConfig } from 'store/selectors';

export default function LoungeCard({ horizontal = false }) {
    const {
        reportNotGenerated,
        attendanceLoading: loading,
        attendanceData: data,
    } = useContext(AnalyticsContext);
    const { total_attendees, all } = data?.event || {};
    const { total_who_used_sl, total_who_used_sn, total_who_found_match } =
        data?.networking || {};

    const totalAttendees = total_attendees || all?.attendees || 0;
    let joinedTablePercent = null,
        speedPercent = null,
        foundMatchPercent = null;
    if (isNumber(totalAttendees)) {
        if (totalAttendees > 0) {
            joinedTablePercent = isNumber(total_who_used_sl)
                ? Math.round((total_who_used_sl * 100) / totalAttendees)
                : 0;
            speedPercent = isNumber(total_who_used_sn)
                ? Math.round((total_who_used_sn * 100) / totalAttendees)
                : 0;
            foundMatchPercent = isNumber(total_who_found_match)
                ? Math.round((total_who_found_match * 100) / totalAttendees)
                : 0;
        } else {
            joinedTablePercent = 0;
            speedPercent = 0;
            foundMatchPercent = 0;
        }
    }
    const isEmpty =
        !reportNotGenerated &&
        !loading &&
        !joinedTablePercent &&
        !speedPercent &&
        !foundMatchPercent;

    const { hideSocialLounge } = useSelector(getLiveConfig);

    return (
        <Card style={{ flexDirection: horizontal ? 'row' : 'column' }}>
            {isEmpty ? (
                <>
                    {!horizontal && (
                        <CardHeader>
                            <CardTitle>Social Lounge</CardTitle>
                        </CardHeader>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: horizontal ? 'row' : 'column',
                            justifyContent: 'center',
                            alignItems: 'stretch',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: horizontal
                                    ? 'stretch'
                                    : 'flex-start',
                                flexDirection: 'column',
                                flexGrow: horizontal && 1,
                                marginRight: horizontal && '12px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                }}
                            >
                                {horizontal && (
                                    <CardHeader
                                        style={{
                                            flexGrow: 1,
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <CardTitle>Social Lounge</CardTitle>
                                    </CardHeader>
                                )}
                                <div style={{ alignSelf: 'flex-end' }}>
                                    <Typography
                                        variant='body1'
                                        color='ambience.0'
                                        style={{
                                            marginBottom: '12px',
                                        }}
                                    >
                                        No data to display
                                    </Typography>
                                    {hideSocialLounge ? (
                                        <Typography
                                            variant='subtext2'
                                            color='ambience.12'
                                            style={{
                                                fontSize: '12px',
                                                lineHeight: '20px',
                                            }}
                                        >
                                            Social lounge was disabled for this
                                            event.
                                            <Typography
                                                variant='subtext2'
                                                color='ambience.12'
                                                style={{
                                                    fontSize: '12px',
                                                    lineHeight: '20px',
                                                    marginBottom:
                                                        !horizontal && '32px',
                                                }}
                                            >
                                                Next time, enable the social
                                                lounge to see how your
                                                participants network and
                                                interact.
                                            </Typography>
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant='subtext2'
                                            color='ambience.12'
                                            style={{
                                                fontSize: '12px',
                                                lineHeight: '20px',
                                                marginBottom:
                                                    !horizontal && '32px',
                                            }}
                                        >
                                            Looks like none of your participants
                                            used the social lounge to network
                                            and interact.
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: horizontal
                                    ? 'center'
                                    : 'flex-start',
                            }}
                        >
                            <img
                                src={cdnImage('/images/blindfold.svg')}
                                alt='No data found'
                                style={{ maxHeight: '120px' }}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div
                        style={{
                            flexGrow: 1,
                            borderRight: horizontal && 'solid 1px #1D1C22',
                            marginRight: horizontal && '24px',
                        }}
                    >
                        <CardHeader>
                            <CardTitle>Social Lounge</CardTitle>
                        </CardHeader>
                        {loading ? (
                            <LoadingWrapper style={{ minHeight: '123px' }}>
                                <Loading />
                            </LoadingWrapper>
                        ) : (
                            <CardBody
                                style={{
                                    height: '100%',
                                    paddingBottom: horizontal && '1em',
                                }}
                            >
                                <Chart mr={3}>
                                    <Doughnut
                                        data={{
                                            labels: [
                                                'Attendees joined a table',
                                            ],
                                            datasets: [
                                                {
                                                    data: [
                                                        joinedTablePercent || 0,
                                                        joinedTablePercent > 100
                                                            ? 0
                                                            : 100 -
                                                              (joinedTablePercent ||
                                                                  0),
                                                    ],
                                                    backgroundColor: [
                                                        '#FFCC82',
                                                        '#26252D',
                                                    ],
                                                    borderWidth: 0,
                                                },
                                            ],
                                        }}
                                        options={{
                                            ...commonDoughnutOptions,
                                        }}
                                    />
                                </Chart>
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginBottom: '24px',
                                        }}
                                    >
                                        <Typography
                                            variant={'body1'}
                                            color='ambience.0'
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            <Legend backgroundColor='#FFCC82' />{' '}
                                            {joinedTablePercent ?? '- '}%
                                        </Typography>
                                        <Typography
                                            variant={'subtext2'}
                                            color='ambience.4'
                                        >
                                            Attendees joined a table
                                        </Typography>
                                    </div>
                                </div>
                            </CardBody>
                        )}
                    </div>
                    {!horizontal && (
                        <hr
                            style={{
                                width: '100%',
                                borderTop: 'solid 1px #1D1C22',
                                margin: '24px 0',
                            }}
                        />
                    )}

                    <div style={{ flexGrow: 1 }}>
                        <CardHeader>
                            <CardTitle>Speed Networking</CardTitle>
                        </CardHeader>
                        {loading ? (
                            <LoadingWrapper style={{ minHeight: '163px' }}>
                                <Loading />
                            </LoadingWrapper>
                        ) : (
                            <CardBody
                                style={{
                                    height: '100%',
                                    paddingBottom: horizontal && '1em',
                                }}
                            >
                                <Chart mr={3}>
                                    <Doughnut
                                        data={{
                                            datasets: [
                                                {
                                                    data: [
                                                        speedPercent || 0,
                                                        speedPercent > 100
                                                            ? 0
                                                            : 100 -
                                                              (speedPercent ||
                                                                  0),
                                                    ],
                                                    backgroundColor: [
                                                        '#6A4CFF',
                                                        '#26252D',
                                                    ],
                                                    borderWidth: 0,
                                                    labels: [
                                                        'Attendees used Speed Networking',
                                                    ],
                                                },
                                                {
                                                    data: [
                                                        foundMatchPercent || 0,
                                                        foundMatchPercent > 100
                                                            ? 0
                                                            : 100 -
                                                              (foundMatchPercent ||
                                                                  0),
                                                    ],
                                                    backgroundColor: [
                                                        '#5AB8BF',
                                                        '#1D1C22',
                                                    ],
                                                    borderWidth: 0,
                                                    labels: [
                                                        'Participants found a match',
                                                    ],
                                                },
                                            ],
                                        }}
                                        options={{
                                            ...commonDoughnutOptions,
                                            cutoutPercentage: 90 * (90 / 100),
                                        }}
                                    />
                                </Chart>
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginBottom: '24px',
                                        }}
                                    >
                                        <Typography
                                            variant={'subtitle1'}
                                            color='ambience.0'
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            <Legend backgroundColor='#6A4CFF' />{' '}
                                            {speedPercent ?? '- '}%
                                        </Typography>
                                        <Typography
                                            variant={'subtext2'}
                                            color='ambience.4'
                                        >
                                            Attendees used Speed Networking
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography
                                            variant='body1'
                                            color='ambience.0'
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            <Legend backgroundColor='#5AB8BF' />{' '}
                                            {foundMatchPercent ?? '- '}%
                                        </Typography>
                                        <Typography
                                            variant={'subtext2'}
                                            color='ambience.4'
                                        >
                                            Participants found a match
                                        </Typography>
                                    </div>
                                </div>
                            </CardBody>
                        )}
                    </div>
                </>
            )}
        </Card>
    );
}
