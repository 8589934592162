import { stringify } from 'querystring';
import { API_SERVICE } from '../../../utils/apiService';
import { CALL_API } from '../../api';

export const CREATE_AIRMEET_REQUEST = 'CM_DASHBOARD/CREATE_AIRMEET_REQUEST';
export const CREATE_AIRMEET_RESPONSE = 'CM_DASHBOARD/CREATE_AIRMEET_RESPONSE';
export const EDIT_AIRMEET_REQUEST = 'CM_DASHBOARD/EDIT_AIRMEET_REQUEST';
export const EDIT_AIRMEET_RESPONSE = 'CM_DASHBOARD/EDIT_AIRMEET_RESPONSE';
export const PATCH_AIRMEET_REQUEST = 'CM_DASHBOARD/PATCH_AIRMEET_REQUEST';
export const PATCH_AIRMEET_RESPONSE = 'CM_DASHBOARD/PATCH_AIRMEET_RESPONSE';
export const FETCH_AIRMEET_REQUEST = 'CM_DASHBOARD/FETCH_AIRMEET_REQUEST';
export const FETCH_AIRMEET_RESPONSE = 'CM_DASHBOARD/FETCH_AIRMEET_RESPONSE';
export const FETCH_CREATED_AIRMEET_REQUEST =
    'CM_DASHBOARD/FETCH_CREATED_AIRMEET_REQUEST';
export const FETCH_CREATED_AIRMEET_RESPONSE =
    'CM_DASHBOARD/FETCH_CREATED_AIRMEET_RESPONSE';
export const DELETE_SPEAKER_REQUEST = 'CM_DASHBOARD/DELETE_SPEAKER_REQUEST';
export const DELETE_SPEAKER_RESPONSE = 'CM_DASHBOARD/DELETE_SPEAKER_RESPONSE';
export const DELETE_SESSION_REQUEST = 'CM_DASHBOARD/DELETE_SESSION_REQUEST';
export const DELETE_SSESSION_RESPONSE = 'CM_DASHBOARD/DELETE_SSESSION_RESPONSE';
export const RESET_CREATED_STATE = 'CM_DASHBOARD/RESET_CREATED_STATE';
export const MANAGE_EVENT_ENTRY_PARTICIPANTS_REQUEST =
    'CM_DASHBOARD/MANAGE_EVENT_ENTRY_PARTICIPANTS_REQUEST';
export const MANAGE_EVENT_ENTRY_PARTICIPANTS_RESPONSE =
    'CM_DASHBOARD/MANAGE_EVENT_ENTRY_PARTICIPANTS_RESPONSE';
export const MANAGE_EVENT_ENTRY_FAILED_PARTICIPANTS_REQUEST =
    'CM_DASHBOARD/MANAGE_EVENT_ENTRY_FAILED_PARTICIPANTS_REQUEST';
export const MANAGE_EVENT_ENTRY_FAILED_PARTICIPANTS_RESPONSE =
    'CM_DASHBOARD/MANAGE_EVENT_ENTRY_FAILED_PARTICIPANTS_RESPONSE';
export const MANAGE_RECORDINGS_REQUEST =
    'CM_DASHBOARD/MANAGE_RECORDINGS_REQUEST';
export const MANAGE_RECORDINGS_RESPONSE =
    'CM_DASHBOARD/MANAGE_RECORDINGS_RESPONSE';
export const DELETE_RECORDINGS_REQUEST =
    'CM_DASHBOARD/DELETE_RECORDINGS_REQUEST';
export const DELETE_RECORDINGS_RESPONSE =
    'CM_DASHBOARD/DELETE_RECORDINGS_RESPONSE';
export const PATCH_ATTENDEES_CHECK_IN_REQUEST =
    'CM_DASHBOARD/PATCH_ATTENDEES_CHECK_IN_REQUEST';
export const PATCH_ATTENDEES_CHECK_IN_RESPONSE =
    'CM_DASHBOARD/PATCH_ATTENDEES_CHECK_IN_RESPONSE';
export const SET_REGISTRANTS_DATA = 'CM_DASHBOARD/SET_REGISTRANTS_DATA';

export const CREATE_SPONSORS_REQUEST = 'CM_DASHBOARD/CREATE_SPONSORS_REQUEST';
export const CREATE_SPONSORS_RESPONSE = 'CM_DASHBOARD/CREATE_SPONSORS_RESPONSE';
export const FETCH_SPONSORS_REQUEST = 'CM_DASHBOARD/FETCH_SPONSORS_REQUEST';
export const FETCH_SPONSORS_RESPONSE = 'CM_DASHBOARD/FETCH_SPONSORS_RESPONSE';
export const UPDATE_SPONSORS_REQUEST = 'CM_DASHBOARD/UPDATE_SPONSORS_REQUEST';
export const UPDATE_SPONSORS_RESPONSE = 'CM_DASHBOARD/UPDATE_SPONSORS_RESPONSE';
export const DELETE_SPONSORS_REQUEST = 'CM_DASHBOARD/DELETE_SPONSORS_REQUEST';
export const DELETE_SPONSORS_RESPONSE = 'CM_DASHBOARD/DELETE_SPONSORS_RESPONSE';

export const CREATE_TIERS_REQUEST = 'CM_DASHBOARD/CREATE_TIERS_REQUEST';
export const CREATE_TIERS_RESPONSE = 'CM_DASHBOARD/CREATE_TIERS_RESPONSE';
export const FETCH_TIERS_REQUEST = 'CM_DASHBOARD/FETCH_TIERS_REQUEST';
export const FETCH_TIERS_RESPONSE = 'CM_DASHBOARD/FETCH_TIERS_RESPONSE';
export const UPDATE_TIERS_REQUEST = 'CM_DASHBOARD/UPDATE_TIERS_REQUEST';
export const UPDATE_TIERS_RESPONSE = 'CM_DASHBOARD/UPDATE_TIERS_RESPONSE';
export const DELETE_TIERS_REQUEST = 'CM_DASHBOARD/DELETE_TIERS_REQUEST';
export const DELETE_TIERS_RESPONSE = 'CM_DASHBOARD/DELETE_TIERS_RESPONSE';
export const GET_TICKETS_REQUEST = 'CM_DASHBOARD/GET_TICKETS_REQUEST';
export const GET_TICKETS_RESPONSE = 'CM_DASHBOARD/GET_TICKETS_RESPONSE';
export const UPDATE_AIRMEET_DATA = 'UPDATE_AIRMEET_DATA';
export const RESET_AIRMEET_DATA = 'RESET_AIRMEET_DATA';

export const CREATE_AIRMEET_DUPLICATION_REQUEST =
    'CREATE_AIRMEET_DUPLICATION_REQUEST';
export const CREATE_AIRMEET_DUPLICATION_RESPONSE =
    'CREATE_AIRMEET_DUPLICATION_RESPONSE';

export const DELETE_AIRMEET_REQUEST = 'DELETE_AIRMEET_REQUEST';
export const DELETE_AIRMEET_RESPONSE = 'DELETE_AIRMEET_RESPONSE';

export const GET_DUPLICATE_AIRMEET_STATUS_REQUEST =
    'GET_DUPLICATE_AIRMEET_STATUS_REQUEST';
export const GET_DUPLICATE_AIRMEET_STATUS_RESPONSE =
    'GET_DUPLICATE_AIRMEET_STATUS_RESPONSE';
export const GET_LEADERBOARD_INFO = 'GET_LEADERBOARD_INFO';
export const START_LEADERBOARD = 'START_LEADERBOARD';
export const GET_LEADERBOARD_INFO_RESPONSE = 'GET_LEADERBOARD_INFO_RESPONSE';
export const FETCH_LEADERBOARD_ACTIONS_REQUEST =
    'FETCH_LEADERBOARD_ACTIONS_REQUEST';
export const FETCH_LEADERBOARD_ACTIONS_RESPONSE =
    'FETCH_LEADERBOARD_ACTIONS_RESPONSE';
export const UPDATE_LEADERBOARD_ACTIONS = 'UPDATE_LEADERBOARD_ACTIONS';
export const UPDATE_LEADERBOARD_WINNERS = 'UPDATE_LEADERBOARD_WINNERS';

export const IS_SETUP_REPLAY_BANNER_CLICKED = 'IS_SETUP_REPLAY_BANNER_CLICKED';
export const UPDATE_PRE_EVENT = 'UPDATE_PRE_EVENT';
export const SHOW_INTERESTS_TAB_BY_DEFAULT = 'SHOW_INTERESTS_TAB_BY_DEFAULT';
export const FETCH_TEMPLATES_REQUEST = 'FETCH_TEMPLATES_REQUEST';
export const FETCH_TEMPLATES_RESPONSE = 'FETCH_TEMPLATES_RESPONSE';

export const AIRMEET_STREAMING_RESOLUTION_UPDATE_REQUEST =
    'AIRMEET_STREAMING_RESOLUTION_UPDATE_REQUEST';
export const AIRMEET_STREAMING_RESOLUTION_UPDATE_SUCCESS =
    'AIRMEET_STREAMING_RESOLUTION_UPDATE_SUCCESS';
export const FETCH_AIRMEET_CLIPS_REQUEST =
    'CM_DASHBOARD/FETCH_AIRMEET_CLIPS_REQUEST';
export const FETCH_AIRMEET_CLIPS_RESPONSE =
    'CM_DASHBOARD/FETCH_AIRMEET_CLIPS_RESPONSE';
export const DELETE_CLIPS_REQUEST = 'CM_DASHBOARD/DELETE_CLIPS_REQUEST';
export const DELETE_CLIPS_RESPONSE = 'CM_DASHBOARD/DELETE_CLIPS_RESPONSE';
export const GET_AIRMEET_DUPLICATION_STATUS_REQUEST =
    'GET_AIRMEET_DUPLICATION_STATUS_REQUEST';
export const GET_AIRMEET_DUPLICATION_STATUS_RESPONSE =
    'GET_AIRMEET_DUPLICATION_STATUS_RESPONSE';
export const MARK_AIRMEET_DUPLICATION_SUCCESS_REQUEST =
    'MARK_AIRMEET_DUPLICATION_SUCCESS_REQUEST';
export const MARK_AIRMEET_DUPLICATION_SUCCESS_RESPONSE =
    'MARK_AIRMEET_DUPLICATION_SUCCESS_RESPONSE';
export const GET_AIRMEET_DUPLICATION_ERRORS_REQUEST =
    'GET_AIRMEET_DUPLICATION_ERRORS_REQUEST';
export const GET_AIRMEET_DUPLICATION_ERRORS_RESPONSE =
    'GET_AIRMEET_DUPLICATION_ERRORS_RESPONSE';

export function createAirmeet(payload) {
    return {
        [CALL_API]: {
            types: [CREATE_AIRMEET_REQUEST, CREATE_AIRMEET_RESPONSE],
            endpoint: `/airmeet/`,
            body: { ...payload },
            type: 'json',
            method: 'POST',
        },
    };
}

export function deleteAirmeet(airmeetId) {
    return {
        [CALL_API]: {
            types: [DELETE_AIRMEET_REQUEST, DELETE_AIRMEET_RESPONSE],
            endpoint: `/airmeet/${airmeetId}`,
            type: 'json',
            method: 'DELETE',
        },
    };
}

export function createAirmeetDuplicate(airmeetId, payload) {
    return {
        [CALL_API]: {
            types: [
                CREATE_AIRMEET_DUPLICATION_REQUEST,
                CREATE_AIRMEET_DUPLICATION_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/duplication`,
            body: { ...payload },
            type: 'json',
            method: 'POST',
        },
    };
}

export function fetchDuplicateAirmeetStatus(airmeetId) {
    return {
        [CALL_API]: {
            types: [
                GET_DUPLICATE_AIRMEET_STATUS_REQUEST,
                GET_DUPLICATE_AIRMEET_STATUS_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/status?airmeetId=${airmeetId}`,
        },
    };
}

export function queryEventParticipants({ airmeetId, body, isEventSeries }) {
    return {
        [CALL_API]: {
            types: [
                MANAGE_EVENT_ENTRY_PARTICIPANTS_REQUEST,
                MANAGE_EVENT_ENTRY_PARTICIPANTS_RESPONSE,
            ],
            endpoint: isEventSeries
                ? `/series/${airmeetId}/participants`
                : `/${airmeetId}/participants`,
            type: 'json',
            method: 'POST',
            body,
            service: API_SERVICE.BFF,
        },
    };
}

export function patchAttendeesCheckIn({ airmeetId, userIds }) {
    return {
        [CALL_API]: {
            types: [
                PATCH_ATTENDEES_CHECK_IN_REQUEST,
                PATCH_ATTENDEES_CHECK_IN_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/attendees/checkin`,
            type: 'json',
            method: 'PATCH',
            body: { userIds },
        },
    };
}

export function getFailedUploadsParticipant({
    airmeetId,
    isEventSeries,
    pageNumber,
    pageSize,
}) {
    return {
        [CALL_API]: {
            types: [
                MANAGE_EVENT_ENTRY_FAILED_PARTICIPANTS_REQUEST,
                MANAGE_EVENT_ENTRY_FAILED_PARTICIPANTS_RESPONSE,
            ],
            endpoint: `/${
                isEventSeries ? 'series' : 'airmeet'
            }/${airmeetId}/attendee-list-upload/fails?pageNumber=${pageNumber}&resultSize=${pageSize}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.OPS,
        },
    };
}

export function getRecordings(airmeetId) {
    return {
        [CALL_API]: {
            types: [MANAGE_RECORDINGS_REQUEST, MANAGE_RECORDINGS_RESPONSE],
            endpoint: `/airmeet/recordings?airmeetId=${airmeetId}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function deleteRecordings(airmeetId, sessionIdQueryString) {
    return {
        [CALL_API]: {
            types: [DELETE_RECORDINGS_REQUEST, DELETE_RECORDINGS_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/recordings?${sessionIdQueryString}`,
            type: 'json',
            method: 'DELETE',
        },
    };
}

export function editAirmeet(airmeetId, payload) {
    return {
        [CALL_API]: {
            types: [EDIT_AIRMEET_REQUEST, EDIT_AIRMEET_RESPONSE],
            endpoint: `/airmeet/${airmeetId}`,
            body: { ...payload },
            type: 'json',
            method: 'PUT',
        },
    };
}

export function patchAirmeet(airmeetId, payload) {
    return {
        [CALL_API]: {
            types: [PATCH_AIRMEET_REQUEST, PATCH_AIRMEET_RESPONSE],
            endpoint: `/airmeet/${airmeetId}`,
            body: { ...payload },
            type: 'json',
            method: 'PATCH',
        },
    };
}

export function resetCmDashboardCreatedState() {
    return {
        type: RESET_CREATED_STATE,
    };
}

export function updateAirmeetState(payload) {
    return {
        type: UPDATE_AIRMEET_DATA,
        payload,
    };
}

export function fetchAirmeetInfo(airmeetId, includes = '') {
    const params = { airmeetId, includes };

    return {
        [CALL_API]: {
            types: [FETCH_AIRMEET_REQUEST, FETCH_AIRMEET_RESPONSE],
            endpoint: `/airmeet?${stringify(params)}`,
            extra: airmeetId,
            method: 'GET',
        },
    };
}

// Separate Action to contain the create popup state,
// in order to support edit and duplicate airmeet features
export function fetchCreatedAirmeetInfo(airmeetId, includes = '') {
    const params = { airmeetId, includes };

    return {
        [CALL_API]: {
            types: [
                FETCH_CREATED_AIRMEET_REQUEST,
                FETCH_CREATED_AIRMEET_RESPONSE,
            ],
            endpoint: `/airmeet?${stringify(params)}`,
            extra: airmeetId,
            method: 'GET',
        },
    };
}

export function createSponsors({ airmeetId, data, isEventSeries }) {
    return {
        [CALL_API]: {
            types: [CREATE_SPONSORS_REQUEST, CREATE_SPONSORS_RESPONSE],
            endpoint: isEventSeries
                ? `/series/${airmeetId}/sponsor`
                : `/airmeet/${airmeetId}/sponsor`,
            body: data,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}

export function fetchSponsorsInfo({ airmeetId, isEventSeries }) {
    return {
        [CALL_API]: {
            types: [FETCH_SPONSORS_REQUEST, FETCH_SPONSORS_RESPONSE],
            endpoint: isEventSeries
                ? `/series/${airmeetId}/sponsor`
                : `/airmeet/${airmeetId}/sponsor`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateSponsors({ airmeetId, sponsorId, data, isEventSeries }) {
    return {
        [CALL_API]: {
            types: [UPDATE_SPONSORS_REQUEST, UPDATE_SPONSORS_RESPONSE],
            endpoint: isEventSeries
                ? `/series/${airmeetId}/sponsor/${sponsorId}`
                : `/airmeet/${airmeetId}/sponsor/${sponsorId}`,
            body: data,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateSponsorOrder({ airmeetId, data, isEventSeries }) {
    return {
        [CALL_API]: {
            types: [UPDATE_SPONSORS_REQUEST, UPDATE_SPONSORS_RESPONSE],
            endpoint: isEventSeries
                ? `/series/${airmeetId}/sponsor`
                : `/airmeet/${airmeetId}/sponsor`,
            body: data,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.BFF,
        },
    };
}

export function deleteSponsor({ airmeetId, sponsorId, isEventSeries }) {
    return {
        [CALL_API]: {
            types: [DELETE_SPONSORS_REQUEST, DELETE_SPONSORS_RESPONSE],
            endpoint: isEventSeries
                ? `/series/${airmeetId}/sponsor/${sponsorId}`
                : `/airmeet/${airmeetId}/sponsor/${sponsorId}`,
            type: 'json',
            method: 'DELETE',
            service: API_SERVICE.BFF,
        },
    };
}

export function createTiers({ airmeetId, data, isEventSeries }) {
    return {
        [CALL_API]: {
            types: [CREATE_TIERS_REQUEST, CREATE_TIERS_RESPONSE],
            endpoint: isEventSeries
                ? `/series/${airmeetId}/sponsor-tiers`
                : `/airmeet/${airmeetId}/sponsor-tiers`,
            body: data,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}

export function getTiers({ airmeetId, isEventSeries }) {
    return {
        [CALL_API]: {
            types: [FETCH_TIERS_REQUEST, FETCH_TIERS_RESPONSE],
            endpoint: isEventSeries
                ? `/series/${airmeetId}/sponsor-tiers`
                : `/airmeet/${airmeetId}/sponsor-tiers`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateTiers({ airmeetId, data, isEventSeries }) {
    return {
        [CALL_API]: {
            types: [UPDATE_TIERS_REQUEST, UPDATE_TIERS_RESPONSE],
            endpoint: isEventSeries
                ? `/series/${airmeetId}/sponsor-tiers`
                : `/airmeet/${airmeetId}/sponsor-tiers`,
            body: data,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.BFF,
        },
    };
}

export function deleteTier({ airmeetId, tierId, isEventSeries }) {
    return {
        [CALL_API]: {
            types: [DELETE_TIERS_REQUEST, DELETE_TIERS_RESPONSE],
            endpoint: isEventSeries
                ? `/series/${airmeetId}/sponsor-tiers/${tierId}`
                : `/airmeet/${airmeetId}/sponsor-tiers/${tierId}`,
            type: 'json',
            method: 'DELETE',
            service: API_SERVICE.BFF,
        },
    };
}
export function getTicketsInfo({ airmeetId }) {
    return {
        [CALL_API]: {
            types: [GET_TICKETS_REQUEST, GET_TICKETS_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/tickets`,
            extra: airmeetId,
            method: 'GET',
        },
    };
}
export function resetAirmeetData() {
    return {
        type: RESET_AIRMEET_DATA,
    };
}

export function fetchLeaderboardInfo({ airmeetId }) {
    return {
        [CALL_API]: {
            types: [GET_LEADERBOARD_INFO, GET_LEADERBOARD_INFO_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/leaderboard/info`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function startLeaderboard({ airmeetId, data }) {
    return {
        [CALL_API]: {
            types: [START_LEADERBOARD],
            endpoint: `/airmeet/${airmeetId}/leaderboard/toggle`,
            body: data,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}

export function fetchLeaderboardActions({ airmeetId }) {
    return {
        [CALL_API]: {
            types: [
                FETCH_LEADERBOARD_ACTIONS_REQUEST,
                FETCH_LEADERBOARD_ACTIONS_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/leaderboard/actions`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateStreamingResolution({ airmeetId, resolution }) {
    return {
        [CALL_API]: {
            types: [
                AIRMEET_STREAMING_RESOLUTION_UPDATE_REQUEST,
                AIRMEET_STREAMING_RESOLUTION_UPDATE_SUCCESS,
            ],
            endpoint: `/airmeet`,
            body: {
                airmeetId,
                max_supported_streaming_resolution: resolution,
            },
            type: 'json',
            method: 'PUT',
        },
    };
}

export function updateLeaderboardActions({ airmeetId, data }) {
    return {
        [CALL_API]: {
            types: [UPDATE_LEADERBOARD_ACTIONS],
            endpoint: `/airmeet/${airmeetId}/leaderboard/actions`,
            body: data,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateLeaderboardInfo({ airmeetId, data }) {
    return {
        [CALL_API]: {
            types: [UPDATE_LEADERBOARD_WINNERS],
            endpoint: `/airmeet/${airmeetId}/leaderboard/info`,
            body: data,
            type: 'json',
            method: 'PATCH',
            service: API_SERVICE.BFF,
        },
    };
}
export function setSetupReplayBannerClicked(status) {
    return {
        type: IS_SETUP_REPLAY_BANNER_CLICKED,
        payload: {
            status,
        },
    };
}

export function updatePreEventEntry({ airmeetId, data }) {
    return {
        [CALL_API]: {
            types: [UPDATE_PRE_EVENT],
            endpoint: `/airmeet/${airmeetId}/pre-event-config`,
            body: data,
            type: 'json',
            method: 'PATCH',
            service: API_SERVICE.ZEUS,
        },
    };
}
export function publishInterceptorEvent(data) {
    return {
        [CALL_API]: {
            types: ['PUBLISH_PECA'],
            endpoint: '/publish',
            body: data,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.INTERCEPTOR,
        },
    };
}

export const fetchInterests = ({ airmeetId }) => {
    return {
        [CALL_API]: {
            types: ['FETCH_INTERESTS'],
            endpoint: `/airmeet/${airmeetId}/interests`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
};

export const updateInterests = ({ airmeetId, data }) => {
    return {
        [CALL_API]: {
            types: ['UPDATE_INTERESTS'],
            endpoint: `/airmeet/${airmeetId}/interests`,
            body: data,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.BFF,
        },
    };
};

export function setShowInterestsTabByDefault(status) {
    return {
        type: SHOW_INTERESTS_TAB_BY_DEFAULT,
        payload: {
            status,
        },
    };
}
export function getAllTemplates(communityId, category) {
    return {
        [CALL_API]: {
            types: [FETCH_TEMPLATES_REQUEST, FETCH_TEMPLATES_RESPONSE],
            endpoint: `/airmeet/community/${communityId}/templates${
                category ? `?category=${category}` : ''
            }`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function fetchAirmeetStatus(airmeetId) {
    return {
        [CALL_API]: {
            types: [FETCH_CREATED_AIRMEET_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/status`,
        },
    };
}

export function getClips(airmeetId) {
    return {
        [CALL_API]: {
            types: [FETCH_AIRMEET_CLIPS_REQUEST, FETCH_AIRMEET_CLIPS_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/project`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function deleteClips(body) {
    return {
        [CALL_API]: {
            types: [DELETE_CLIPS_REQUEST, DELETE_CLIPS_RESPONSE],
            endpoint: '/projects',
            type: 'json',
            method: 'DELETE',
            service: API_SERVICE.BFF,
            body: body,
        },
    };
}
export function fetchAirmeetDuplicationStatus(airmeetId) {
    return {
        [CALL_API]: {
            types: [
                GET_AIRMEET_DUPLICATION_STATUS_REQUEST,
                GET_AIRMEET_DUPLICATION_STATUS_RESPONSE,
            ],
            endpoint: `/airmeet/duplication/status?airmeet_ids=${airmeetId}`,
        },
    };
}
export function fetchAirmeetDuplicationSuccess(airmeetId) {
    return {
        [CALL_API]: {
            types: [
                MARK_AIRMEET_DUPLICATION_SUCCESS_REQUEST,
                MARK_AIRMEET_DUPLICATION_SUCCESS_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/duplication/success`,
            method: 'POST',
            type: 'json',
        },
    };
}
export function getAirmeetDuplicationErrors(airmeetId) {
    return {
        [CALL_API]: {
            types: [
                GET_AIRMEET_DUPLICATION_ERRORS_REQUEST,
                GET_AIRMEET_DUPLICATION_ERRORS_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/duplication/errors`,
            method: 'GET',
        },
    };
}

export function setRegistrantsData(payload) {
    return {
        type: SET_REGISTRANTS_DATA,
        payload,
    };
}
