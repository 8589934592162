import ColorThief from 'colorthief';

const AUTO_BRAND_NB_BRAND_COLORS = 5;
export const AUTO_BRAND_NB_BG_COLORS = 2;

export const getColorsFromImage = (src, callback) => {
    const img = new Image();

    img.addEventListener('load', () => {
        if (callback) {
            const colorThief = new ColorThief();
            callback(colorThief.getPalette(img, AUTO_BRAND_NB_BRAND_COLORS));
        }
    });

    img.crossOrigin = 'Anonymous';
    img.src = src;
};
