import { Message } from '../types';
import DataObserverBase from './DataObserverBase';

class UserProfileObserver extends DataObserverBase {
    subscribe(onData: Function, metadata?: any): Function {
        if (!metadata.userId) {
            throw new Error('Invalid User Id');
        }

        const onSnapshot = (message: Message) => {
            onData(message);
        };

        const channel = `${this.airmeetId}/onlineUsers/${metadata.userId}`;

        const dataSourceUnsubscribe = super.subscribeImpl(channel, {
            onSnapshot,
        });

        return () => {
            dataSourceUnsubscribe && dataSourceUnsubscribe();
        };
    }
}

export default UserProfileObserver;
