import Switch from 'atoms/Switch/Switch';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import WithTooltip from 'components/general/WithTooltip';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import React, { useContext } from 'react';
import { AnalyticsContext } from '../AnalyticsProvider';

export default function SpecialRolesFilterSwitch({ tabId }) {
    const {
        isOnlyAttendees,
        setIsOnlyAttendees,
        isLimitLevelAttendee,
        logEvent,
    } = useContext(AnalyticsContext);

    const handleChange = () => {
        setIsOnlyAttendees((val) => {
            logEvent('includeSpecialRoles', {
                tabId,
                status: val ? 'ON' : 'OFF',
            });
            return !val;
        });
    };

    return isLimitLevelAttendee ? (
        <Switch
            checked={!isOnlyAttendees}
            onCheckedChange={handleChange}
            uncheckedLabel={<Label />}
            checkedLabel={<Label />}
        />
    ) : null;
}

const Label = () => {
    return (
        <FlexDiv alignItems='center'>
            Include special roles
            <WithTooltip
                tooltip='Special roles include organizers, hosts, team members, speakers & exhibitors.'
                isThemeV2={true}
            >
                <StyledSvgSprite
                    icon='info'
                    fill='ambience.0'
                    ml='x1'
                    height='16px'
                    width='16px'
                />
            </WithTooltip>
        </FlexDiv>
    );
};
