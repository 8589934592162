import Btn from 'atoms/Button/btn';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import { getCardBackgroundStyle } from 'foundations/themeV2/utils';
import history from 'history.js';
import useEventDashboardFeatures from 'hooks/dashboard/useEventDashboardFeatures';
import React from 'react';
import styled from 'styled-components';
import { cdnImage } from 'utils/core';
import { POST_EVENT_ENTRY_TAB } from '../../constants/tabs';

export default function PostEventTabEmpty({
    title,
    description,
    showCTA = false,
}) {
    const { getFeatureUrl } = useEventDashboardFeatures({});

    const handleBtnClick = () => {
        history.push(getFeatureUrl(POST_EVENT_ENTRY_TAB));
    };

    return (
        <Wrapper>
            <Image
                src={cdnImage('/images/dashboard/analytics/il-events.svg')}
            />
            {title && (
                <Text variant='h5' color='text.default.primary' pt='x3'>
                    {title}
                </Text>
            )}
            {description && (
                <Text variant='body2' color='text.default.secondary' pt='x2'>
                    {description}
                </Text>
            )}
            {showCTA && (
                <Btn variant='secondary' onClick={handleBtnClick} mt='x4'>
                    Enable event replay
                </Btn>
            )}
        </Wrapper>
    );
}

const Wrapper = styled(FlexDiv)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${({ theme }) => theme.space.x5};
    border-radius: ${({ theme }) => theme.radii.default};
    border: 1px solid ${({ theme }) => theme.colors.border.default2};
    min-height: 50vh;
    text-align: center;
    ${({ theme }) => getCardBackgroundStyle({ theme })}

    p {
        max-width: 25rem;
    }
`;

const Image = styled.img`
    height: 5rem;
    width: 5rem;
`;
