import theme from 'foundations/theme';
import {
    passwordStrengthDetail,
    WEAK_PASSWORD,
    PASSWORD_NOT_STRONG,
    STRONG_PASSWORD,
    PASSWORD_ERROR_TYPES,
    getPwErrorConfig,
} from 'components/emailAuth/constants';
import isEmail from 'utils/isEmail';

export const EMAIL_SCREEN = 'email';
export const OTP_SCREEN = 'otp';
export const OTP_APP_SUMO_SCREEN = 'otpAppSumo';
export const PASSWORD_SCREEN = 'password';
export const PASSWORD_APP_SUMO_SCREEN = 'passwordAppSumo';
export const FORGOT_PASSWORD_SCREEN = 'forgotPassword';
export const RESET_PASSWORD_SCREEN = 'resetPassword';
export const LOGIN_PASSWORD_SCREEN = 'loginPassword';
export const SSO_LOGIN_SCREEN = 'ssoLogin';
export const ACCOUNT_EMAIL_LINKING_UNAVAILABLE_SCREEN =
    'accountEmailLinkingUnavailable';
export const POST_FIRST_SIGNUP = 'postFirstSignup';
export const PRIMARY_GOAL = 'primaryGoal';
export const COMPLETE_PROFILE = 'completeProfile';
export const UNIFIED_FLOW = 'unified';
export const AUTH_COMPLETE = 'done';
export const LOGIN_FLOW = 'LOGIN';
export const SIGNUP_FLOW = 'SIGNUP';
export const COMPLETION_FLOW = 'complete';
export const SSO_FLOW = 'sso_flow';
export const APPLE_AUTH_ERROR_MESSAGE =
    'Please connect an email address in your Apple account settings or use another way to sign in.';
export const EVENT_LANDING_PATH = '/e/';
export const SERIES_LANDING_PATH = '/es/';
export const SIGNUP_PAGE_PATH = '/signin';
export const INVITE_MEMBER_PATH = '/invite';
export const SSO_PATH = '/sso';
export const ONBOARDING_PATH = '/onboarding';
export const SWITCH_ACCOUNT_ID = 'switch';
export const INVALID_TOKEN_ID = 'invalid';
export const EXPIRED_TOKEN_ID = 'expired';
export const INVITE_FLOW_SOURCE = 'MEMBER_INVITATION';
export const PASSWORD_NOT_STRONG_ENOUGH = 'Password is not strong enough';
export const BTN_DISABLE_STYLE = {
    background: theme.colors.ambience[19],
};
export const BTN_DISABLE_TEXT_STYLE = {
    color: theme.colors.ambience[14],
};
export const L1_SCREEN = 'l1';

export const SIGNIN_REFERRER = 'signup_referrer';
export const RESTRICTED_DOMAIN_ERROR_MESSAGE =
    'This email domain is restricted for registration. Contact the organizer or use an alternative email address.';

export const STEP_ROUTE_MAPPING = {
    [OTP_SCREEN]: 'otp',
    [OTP_APP_SUMO_SCREEN]: 'otp',
    [EMAIL_SCREEN]: 'auth',
    [PASSWORD_SCREEN]: 'email_signup',
    [LOGIN_PASSWORD_SCREEN]: 'enterpwd',
    [FORGOT_PASSWORD_SCREEN]: 'forgotpwd',
    [RESET_PASSWORD_SCREEN]: 'resetpwd',
    [SSO_LOGIN_SCREEN]: 'ssologin',
    [POST_FIRST_SIGNUP]: 'oauth_signup',
    [ACCOUNT_EMAIL_LINKING_UNAVAILABLE_SCREEN]: 'apple_error',
    [PRIMARY_GOAL]: 'primary_goal', // What would you like to do screen
    [COMPLETE_PROFILE]: 'complete_profile',
};

export const getStepRouteMapping = ({ authStep, isOrganiser }) => {
    return `${SIGNUP_PAGE_PATH}/${!isOrganiser ? 'as_' : ''}${
        STEP_ROUTE_MAPPING[authStep]
    }`;
};
export const showPasswordStrength = (isValidPassword, isCharSequenced) => {
    let passwordStrength = {};
    if (!isValidPassword) {
        passwordStrength = passwordStrengthDetail[PASSWORD_NOT_STRONG];
    } else if (isValidPassword && isCharSequenced) {
        passwordStrength = passwordStrengthDetail[WEAK_PASSWORD];
    } else {
        passwordStrength = passwordStrengthDetail[STRONG_PASSWORD];
    }
    return passwordStrength;
};

export const showPasswordErrors = (passwordErrors) => {
    const {
        MIN_LENGTH,
        MAX_LENGTH,
        NO_LOWERCASE,
        NO_UPPERCASE,
        NO_SPECIAL_SYMBOL,
        HAS_SPACE,
        NO_NUMBER,
        HAS_SEQUENTIAL,
    } = PASSWORD_ERROR_TYPES;

    const errors = [];

    // Check all the password error scenarios
    // Min and Max length
    const lengthError = getPwErrorConfig({
        errorKey: MIN_LENGTH,
        isError: passwordErrors[MIN_LENGTH] || passwordErrors[MAX_LENGTH],
    });
    errors.push(lengthError);

    // lowercase/uppercase check
    const caseError = getPwErrorConfig({
        errorKey: NO_LOWERCASE,
        isError: passwordErrors[NO_LOWERCASE] || passwordErrors[NO_UPPERCASE],
    });
    errors.push(caseError);

    // number/special symbol check
    const numberSpecialSymbolError = getPwErrorConfig({
        errorKey: NO_NUMBER,
        isError: passwordErrors[NO_NUMBER] || passwordErrors[NO_SPECIAL_SYMBOL],
    });
    errors.push(numberSpecialSymbolError);

    // Space check
    const spaceError = getPwErrorConfig({
        errorKey: HAS_SPACE,
        isError: passwordErrors[HAS_SPACE],
    });
    errors.push(spaceError);

    // Sequential check
    const sequentialError = getPwErrorConfig({
        errorKey: HAS_SEQUENTIAL,
        isError: passwordErrors[HAS_SEQUENTIAL],
    });
    errors.push(sequentialError);

    const visibleErrors = errors.filter((error) => !error.isHidden);
    return visibleErrors;
};

export const getDomain = (domain) => {
    let domainName =
        typeof domain === 'string' ? domain.trim().toLowerCase() : domain;
    if (isEmail(domainName)) {
        domainName = domainName.split('@')[1];
    }
    return domainName;
};
