import moment from 'moment';
import { LOG_LEVEL, LOG_METHODS } from 'utils/constants/logger';
import { LogInterceptor } from 'utils/loggers/LogInterface';

export const ConsoleInterceptor: LogInterceptor = {
    key: 'console',
    intercept: ({ level, prefix, color }, ...args) => {
        const method =
            LOG_METHODS[level] && LOG_METHODS[level] in console
                ? LOG_METHODS[level]
                : LOG_METHODS[LOG_LEVEL.VERBOSE];
        console[method](
            `%c[${moment().format('HH:mm:ss:SSS')}]` + '%c ' + `%c${prefix}`,
            'background: blue; color: white;',
            'background: none;',
            `background: black; color: ${color};`,
            ...args
        );
    },
    excludedLevels: LOG_LEVEL.RAW_JSON,
    minLevel:
        process.env.REACT_APP_ENV === 'test' ||
        process.env.REACT_APP_ENV === 'dev'
            ? LOG_LEVEL.VERBOSE
            : LOG_LEVEL.WARN,
};

export default ConsoleInterceptor;
