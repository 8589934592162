import Button from 'atoms/Button/button';
import SVGIcon from 'foundations/icon';
import React from 'react';
import styled from 'styled-components';

export default function AllButton(props) {
    return (
        <Button
            variant='link'
            style={{
                alignSelf: 'flex-end',
                padding: '8px',
            }}
            {...props}
        >
            <StyledSpan>
                <span
                    style={{
                        marginRight: '8px',
                    }}
                >
                    See all
                </span>
                <SVGIcon
                    icon='chevron_right'
                    fill='accentPrimary.0'
                    height='16px'
                    width='16px'
                />
            </StyledSpan>
        </Button>
    );
}

const StyledSpan = styled.span`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.accentPrimary[0]};
`;
