import Button from 'atoms/Button/button';
import LoaderV2 from 'components/general/LoaderV2';
import { MODAL_TYPE_AIRMEET_STATUS_CONFIRMATION } from 'components/modals';
import { MODAL_TYPE_ENTRY_FLOW } from 'components/modals/constants';
import { Box } from 'components/molecules/utils/basicComponents';
import { getCustomScroll } from 'components/molecules/utils/customStyles';
import Icon from 'foundations/icon';
import { alpha } from 'foundations/theme';
import { Text } from 'foundations/themeV2/text';
import usePreRecordedSessionVideoUploader from 'hooks/community/usePreRecordedSessionVideoUploader';
import useTheme from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSession } from 'store/actions/cmDashboard/session';
import { showAppModal } from 'store/actions/layout';
import styled from 'styled-components';
import { cdnImage } from 'utils/core';
import { logger } from 'utils/logger';
import UploadProgress from '../UploadProgress';
import QuickTip from './QuickTip';
import SelectFileFromComputer from './SelectFileFromComputer';
import UploadError from './UploadError';
import UploadSuccess from './UploadSuccess';

const UploadVideo = ({
    mapVideoToSession = Function,
    onClose = Function,
    videosList = Array,
    refreshGrid = Function,
    setUploadStatus = Function,
    shouldShowConfirmationModal = Boolean,
    setShouldShowConfirmationModal = Function,
    scrollMaxHeight: scrollMaxHeightProp,
    isVariantV2 = false,
    isThemeV2 = false,
    isSummaryPage = false,
    sessionObjDetails,
    isNOPreviewUpload = false,
    formData,
}) => {
    const scrollMaxHeight =
        scrollMaxHeightProp ||
        (isThemeV2 ? 'calc(100vh - 260px)' : 'calc(100vh - 324px)');
    const { theme } = useTheme();
    const dispatch = useDispatch();
    const [isMappingVideoToSession, setIsMappingVideoToSession] = useState(
        false
    );

    const {
        handleOnSelect,
        uploadStatus,
        uploadError,
        loading,
        uploadProgress,
        handlePreviewVideo,
        clearSelection,
        uploadNew,
        onCancel,
        onSubmit,
        fileData,
    } = usePreRecordedSessionVideoUploader({
        refreshGrid,
        videosList,
        mapVideoToSession,
        onClose,
        formData,
    });
    const handleOnSubmit = async () => {
        try {
            setIsMappingVideoToSession(true);
            onSubmit();
            if (isSummaryPage) {
                await dispatch(
                    updateSession(
                        sessionObjDetails.airmeetId,
                        sessionObjDetails.uid,
                        sessionObjDetails
                    )
                );
            }
        } catch (err) {
            logger.error(err, 'Failed to upload video');
        }
    };
    useEffect(() => {
        setUploadStatus(uploadStatus);
    }, [uploadStatus]);

    /**
     * pre fetch error image as user may be not connected to internet on error
     * https://app.shortcut.com/airmeet/story/122363/after-disconnecting-and-reconnecting-back-clicking-on-retry-upload-getting-loader
     */
    useEffect(() => {
        new Image().src = cdnImage('/images/dashboard/file-error.svg');
    }, []);

    /**
     * handle ongoing upload on unmount
     */
    const modalStyleObj = {
        confirmBoxStyle: {
            padding: '20px 40px 46px !important',
        },
        topBoxStyle: {
            padding: '46px 48px 0 !important',
            height: 'auto !important',
        },
        topboxstylebefore: {
            top: '-200px',
        },
        iconWrapperStyle: {
            width: '128px !important',
            height: '145px !important',
            marginBottom: '44px !important',
        },
        imagestyles: {
            width: '128px',
            height: '145px',
        },
        modalStyle: {
            maxWidth: '416px',
        },
        modalBodyStyle: {
            minHeight: '200px',
        },
        backdropStyles: {
            zIndex: '9999',
        },
        styles: {
            zIndex: '9999 !important',
        },
        buttonWrapperStyles: {
            paddingTop: '26px',
            borderTop: `1px solid ${
                isThemeV2
                    ? theme.colors.border.default2
                    : alpha(theme.colors.ambience[0], 0.1)
            }`,
            marginTop: '46px',
        },
    };

    const handleOnClose = () => {
        if (uploadStatus.status === 'uploading') {
            dispatch(
                showAppModal(MODAL_TYPE_AIRMEET_STATUS_CONFIRMATION, {
                    hasBG: true,
                    headingTypoVariant: 'subtitle1',
                    subHeading:
                        'A video upload is in progress. Do you want to cancel it?',
                    typographyVariant: 'subtext2',
                    typographyColor: 'ambience.6',
                    btnBgColor: '',
                    content: () => {},
                    rejectBtnText: 'No',
                    isSeparatorAllowed: false,
                    disableIconBorder: true,
                    enableCloseButton: false,
                    styles: modalStyleObj['styles'],
                    confirmBoxStyle: modalStyleObj['confirmBoxStyle'],
                    topBoxStyle: modalStyleObj['topBoxStyle'],
                    iconWrapperStyle: modalStyleObj['iconWrapperStyle'],
                    modalStyle: modalStyleObj['modalStyle'],
                    modalBodyStyle: modalStyleObj['modalBodyStyle'],
                    backdropStyles: modalStyleObj['backdropStyles'],
                    imagestyles: modalStyleObj['imagestyles'],
                    buttonWrapperStyles: modalStyleObj['buttonWrapperStyles'],
                    enableBackdrop: true,
                    onAccept: () => {
                        onCancel();
                        onClose();
                    },
                    image: 'images/dashboard/roar.svg',
                    heading: 'Upload in progress!',
                    acceptBtnText: 'Yes, cancel',
                    onReject: () => setShouldShowConfirmationModal(false),
                })
            );
        } else {
            onClose();
        }
    };

    const handleRemoveVideo = () => {
        dispatch(
            showAppModal(MODAL_TYPE_ENTRY_FLOW, {
                hasBG: false,
                title: 'Do you really want to remove the welcome video?',
                titleVariant: 'h6',
                titleColor: 'ambience.0',
                subTitle:
                    'Are you sure you want to remove the welcome video! All the saved data will be gone.',
                icon: '/images/dashboard/delete-file.svg',
                iconWidth: 224,
                iconHeight: 154,
                iconStyles: { marginBottom: '24px' },
                subTextStyles: { maxWidth: '396px' },
                BtnSecondaryText: 'Cancel',
                BtnPrimaryText: 'Remove anyway',
                BtnPrimaryStyles: {
                    background: isThemeV2
                        ? theme.colors.brandDefault
                        : theme.colors.accentPrimary[0],
                },
                modalStyle: {
                    maxWidth: '460px',
                    minHeight: '498px',
                },
            })
        );
    };

    useEffect(() => {
        if (shouldShowConfirmationModal) {
            handleOnClose();
        }
    }, [shouldShowConfirmationModal]);

    return (
        <Box
            mt={isThemeV2 ? '' : '22px'}
            display='flex'
            flexDirection='column'
            flex={1}
        >
            <ScrollView maxHeight={scrollMaxHeight}>
                <UploadContainer
                    hasError={uploadError}
                    isVideoUploading={uploadStatus.status === 'uploading'}
                    isThemeV2={isThemeV2}
                >
                    {loading || uploadStatus.status === 'finishing' ? (
                        <LoaderV2 isThemeV2={isThemeV2} />
                    ) : uploadStatus.status === 'uploading' ? (
                        <UploadProgress
                            progress={uploadProgress}
                            onCancel={onCancel}
                            isThemeV2={isThemeV2}
                            icon='VIDEO'
                            filesMetaData={[fileData]}
                        />
                    ) : uploadError ? (
                        isThemeV2 ? (
                            <SelectFileFromComputer
                                isThemeV2={isThemeV2}
                                hasError={uploadError}
                            />
                        ) : (
                            <UploadError isThemeV2={isThemeV2} />
                        )
                    ) : uploadStatus.status === 'finished' ? (
                        <UploadSuccess
                            fileData={fileData}
                            uploadNew={uploadNew}
                            clearSelection={
                                isVariantV2 ? handleRemoveVideo : clearSelection
                            }
                            isVariantV2={isVariantV2}
                            isThemeV2={isThemeV2}
                        />
                    ) : (
                        <SelectFileFromComputer
                            isThemeV2={isThemeV2}
                            hasError={uploadError}
                        />
                    )}
                    <input
                        type='file'
                        id='sessionVideo'
                        name='sessionVideo'
                        onChange={handleOnSelect}
                        accept='.mp4'
                        onDrop={handleOnSelect}
                    />
                </UploadContainer>
                {uploadError ? (
                    <Text variant='caption' color='sem.error' pt='x2'>
                        There was some error while uploading, please upload the
                        file again.
                    </Text>
                ) : null}
                {uploadStatus.status === 'finished' && !isNOPreviewUpload && (
                    <Button
                        variant='link'
                        color={isThemeV2 ? '' : 'accentPrimary.0'}
                        mt={isThemeV2 ? 'x1' : '8px'}
                        onClhandleOnSubmitick={handlePreviewVideo}
                        disabled={!fileData?.path}
                        isThemeV2={isThemeV2}
                    >
                        <Icon
                            icon={'icon_preview'}
                            width={24}
                            height={24}
                            fill={
                                isThemeV2 ? 'brandDefault' : 'accentPrimary.0'
                            }
                            mr={isThemeV2 ? 'x1' : '8px'}
                        />
                        Preview Upload
                    </Button>
                )}
                <Text
                    variant='caption'
                    color='text.default.secondary'
                    mb='x4'
                    mt='x2'
                >
                    Files only in mp4 format, size upto 5GB is supported
                </Text>
                <QuickTip isThemeV2={isThemeV2} />
            </ScrollView>
            <Box pt={isThemeV2 ? 'x6' : '24px'}>
                <StyledButton
                    variant='primary'
                    onClick={handleOnSubmit}
                    loading={
                        loading ||
                        isMappingVideoToSession ||
                        ['uploading', 'finishing'].includes(uploadStatus.status)
                    }
                    disabled={!fileData.path}
                    isThemeV2={isThemeV2}
                >
                    {uploadError ? 'Retry Upload' : 'Done'}
                </StyledButton>
            </Box>
        </Box>
    );
};

export default React.memo(UploadVideo);

const ScrollView = styled(Box)`
    flex 1;
    overflow: auto;
    ${({ verticalWidth = '12px', theme, forceDarkMode = true }) =>
        getCustomScroll({ theme, verticalWidth, forceDarkMode })}
`;

const StyledButton = styled(Button)`
    ${({ disabled, theme, isThemeV2 }) =>
        !isThemeV2 &&
        disabled &&
        `
        background: ${theme.colors.accentPrimary[0]} !important;
        opacity: 0.48 !important;
    `}
`;

const UploadContainer = styled.div`
    position: relative;
    height: ${({ isThemeV2 }) => (isThemeV2 ? '17.5rem' : '224px')};
    background: ${({ theme, isThemeV2 }) =>
        isThemeV2
            ? theme.colors.fill.default2
            : alpha(theme.colors.ambience[9], 0.16)};
    border: 1px dashed
        ${({ theme, hasError, isVideoUploading, isThemeV2 }) =>
            isVideoUploading
                ? 'transparent'
                : hasError
                ? isThemeV2
                    ? theme.colors.sem.error
                    : theme.colors.lighting[6]
                : isThemeV2
                ? theme.colors.border.default1
                : theme.colors.ambience[18]};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    input[type='file'] {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 1;
    }
`;
