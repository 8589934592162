import { FlexDiv } from 'components/molecules/utils/basicComponents';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { logger } from 'utils/logger';
import StatCard from './components/StatCard';
import { getViewingTimeSummary } from './utils/api';

export default function ViewingTimeStatCards({ airmeetId, sessionId }) {
    const [isLoading, setIsLoading] = useState(true);
    const [apiData, setApiData] = useState({});

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await getViewingTimeSummary({
                airmeetId,
                sessionId,
            });
            if (!isEmpty(response?.data)) {
                setApiData(response?.data);
            }
        } catch (e) {
            logger.error('PostEventTab.fetchData: ', e);
        } finally {
            setIsLoading(false);
        }
    }, [airmeetId, sessionId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const data = useMemo(() => {
        const data = { ...(apiData || {}) };

        Object.keys(data).forEach((key) => {
            const value = data[key];
            if (isNumber(value)) {
                if (value < 60) {
                    data[key] = {
                        val: value,
                        unit: `second${value === 1 ? '' : 's'}`,
                    };
                } else {
                    const minutes = Math.round(value / 60);
                    data[key] = {
                        val: minutes,
                        unit: `minute${minutes === 1 ? '' : 's'}`,
                    };
                }
            }
        });

        return data;
    }, [apiData]);

    return (
        <FlexDiv alignItems='stretch' gap='1rem'>
            <StatCard
                title='Total viewing time'
                value={data?.total_duration?.val}
                units={data?.total_duration?.unit}
                isLoading={isLoading}
            />
            <StatCard
                title='Avg viewing time per attendee'
                value={data?.average_duration?.val}
                units={data?.average_duration?.unit}
                isLoading={isLoading}
            />
        </FlexDiv>
    );
}
