import SecondaryFirebaseClient from './SecondaryFirebaseClient';

export default class ReplicaFirebaseClient extends SecondaryFirebaseClient {
    constructor(...args) {
        super(...args);
        this.writeReplicas = [];
    }

    setWriteReplicas(replicas) {
        this.writeReplicas = replicas;
    }

    writeToReplicas(action, args) {
        for (let replica of this.writeReplicas) {
            if ('set' === action) {
                replica.setData(...args);
            } else if ('update' === action) {
                replica.updateData(...args);
            }
        }
    }

    setData(type, data, unbind = false, showError = true, callback) {
        let result = super.setData(type, data, unbind, showError, callback);
        this.writeToReplicas('set', [type, data, unbind, showError]);
        return result;
    }

    updateData(type, data, unbind = false) {
        let result = super.updateData(type, data, unbind);
        this.writeToReplicas('update', [type, data, unbind]);
        return result;
    }

    pushData(type, data) {
        let response = super.pushData(type, data);
        this.writeToReplicas('set', [response.ref.path.toString(), data]);
        return response;
    }

    runTransaction() {
        throw new Error('Cannot have txns in replicating clients');
    }
}
