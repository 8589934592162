import {
    BANNER_STATE,
    getBannerImageKey,
} from 'components/StageRoom/Banners/constants';
import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';

const { ADD, UPDATE, DELETE, SHOW, ADD_IMAGE, DELETE_IMAGE } = FEATURE_ACTIONS[
    FEATURE_NAMES.STAGE_BANNER
];

const stageBannerWriter = async ({ airmeetId, client, payload, metaData }) => {
    if (!airmeetId) {
        throw new Error('airmeetId is missing');
    }

    if (!metaData?.action) {
        throw new Error('No action is specified for cta banner');
    }

    const { action, bannerName } = metaData;

    const bannerPath = `${airmeetId}/banners/${bannerName}`;

    switch (action) {
        case ADD: {
            const { bannerMapPath } = metaData;
            const ref = client.ref(bannerPath);
            const bannerId = ref.push().getKey();

            if (!bannerId) {
                throw new Error('bannerId is required to create a banner');
            }

            if (!bannerMapPath) {
                throw new Error('bannerMapPath is required to create a banner');
            }

            const addBanner = {};
            addBanner[`${bannerPath}/${bannerId}`] = { ...payload, bannerId };
            addBanner[`${bannerMapPath}/${bannerId}`] = {
                status: BANNER_STATE.DRAFT,
                updatedAt: payload?.createdAt,
            };
            await client.atomicUpdate(addBanner);
            return bannerId;
        }
        case UPDATE: {
            const { bannerId, bannerMapPath } = metaData;
            if (!bannerId) {
                throw new Error('bannerId is required to update a banner');
            }
            if (!bannerMapPath) {
                throw new Error('bannerMapPath is required to update a banner');
            }

            const addBanner = {};
            addBanner[`${bannerPath}/${bannerId}`] = payload;

            return client.atomicUpdate(addBanner);
        }

        case DELETE: {
            const { bannerId, bannerMapPath } = metaData;
            if (!bannerId) {
                throw new Error('bannerId is required to delete a banner');
            }

            if (!bannerMapPath) {
                throw new Error('bannerMapPath is required to delete a banner');
            }

            return client.setDataAsync(`${bannerMapPath}/${bannerId}`, null);
        }

        case SHOW: {
            const { bannerMapPath, bannerId } = metaData;
            const showBanner = {};
            if (payload?.shownAt) {
                showBanner[`${bannerMapPath}/${bannerId}/shownAt`] =
                    payload?.shownAt;
            }
            return client.atomicUpdate(showBanner);
        }

        case ADD_IMAGE: {
            const { bannerName } = metaData;
            const baseImageKey = getBannerImageKey(airmeetId, bannerName);

            if (!baseImageKey) {
                throw new Error('baseImageKey is required to add image');
            }

            const ref = client.ref(baseImageKey);
            const imageId = ref.push().getKey();

            if (!imageId) {
                throw new Error('imageId is required to add image');
            }

            if (!bannerName) {
                throw new Error('bannerName is required to add image');
            }

            return client.setDataAsync(`${baseImageKey}/${imageId}`, {
                ...payload,
                imageId,
            });
        }

        case DELETE_IMAGE: {
            const { imageId, bannerName } = metaData;
            if (!imageId) {
                throw new Error('imageId is required to delete image');
            }

            if (!bannerName) {
                throw new Error('bannerName is required to add image');
            }

            const baseImageKey = getBannerImageKey(airmeetId, bannerName);
            return client.setDataAsync(`${baseImageKey}/${imageId}`, null);
        }

        default: {
            throw new Error('No banner action has been found');
        }
    }
};

export default stageBannerWriter;
