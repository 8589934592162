import { RESOLUTIONS_1080P } from 'containers/StageResolutionsList';
import { hasRTMPStreamingEnabledFromRecorder } from 'context/LiveAirmeet';
import { usePrevious } from 'hooks/common';
import useDeviceInfo from 'hooks/useDeviceInfo';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import useNetworkStatus from 'hooks/useNetworkStatus';
import useSessionContext from 'hooks/useSessionContext';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    getClosedCaptions,
    getIsSessionGenralDataLoaded,
    getStageBroadcastChannels,
} from 'store/selectors';
import { getInviteToStagePermissionProcess } from 'store/selectors/liveAirmeet';
import { useUserData } from 'store/selectors/users';
import { RTC_SOURCE_MODULES, noop } from 'utils/constants/common';
import {
    LIVE_AIRMEET_STATE,
    STAGE_DEFAULT_AV_STATES,
} from 'utils/constants/live-airmeet';
import { SESSION_TYPES } from 'utils/constants/sessions';
import { USER_ROLE } from 'utils/constants/users';
import { UserLoginType } from 'utils/constants/common';
import { logger } from 'utils/logger';
import PermissionUtils from 'utils/permission-utils';
import { SDK_INTERACTION, hasAccess } from 'utils/userAccessControl';
import { v4 as uuid } from 'uuid';

const { ATTENDEE } = USER_ROLE;
const RESET_DISABLE_AUTO_JOIN_STATE_DURATION = 60 * 1000; // in sec

export default function useLiveEventClient({
    stageService,
    showCDNStreams,
    currentSession,
    airmeet,
    currentState,
    userStageRole,
    userRole,
    streamVideoProfile,
    disableAirmeetBackstage = false,
}) {
    const liteModeActive = useSelector(
        (state) => state.liveAirmeet.liteModeActive
    );
    const isInviteToStagePermissionInProgress = useSelector(
        getInviteToStagePermissionProcess
    );

    const mirrorLocalVideo = useSelector(
        (store) => store.liveAirmeet.mirrorVideo
    );
    const isBreakoutActive = useSelector(
        (store) => store.liveAirmeet.isBreakoutActive
    );
    const stageBroadcastChannels = useSelector(getStageBroadcastChannels);
    const backStageChannelId = stageBroadcastChannels?.backStage;
    const liveStageChannelId = stageBroadcastChannels?.liveStage;
    const stageChannels = [backStageChannelId, liveStageChannelId];
    const { isMobile } = useDeviceInfo();
    const isSessionGenralDataLoaded = useSelector(getIsSessionGenralDataLoaded);
    const allowToJoinStageChannel =
        !PermissionUtils.isEventCloudHost() ||
        PermissionUtils.isSessionCloudHost() ||
        PermissionUtils.isSessionScreenRecordingUser();
    const disableJoinInChannel =
        !allowToJoinStageChannel ||
        !isSessionGenralDataLoaded ||
        liteModeActive ||
        isBreakoutActive ||
        isMobile ||
        showCDNStreams ||
        (currentSession &&
            currentSession.type &&
            ![SESSION_TYPES.HOSTING, SESSION_TYPES.PRE_RECORDED].includes(
                currentSession.type
            ));
    const is1080PSession =
        currentSession?.streaming_resolution === RESOLUTIONS_1080P;
    const [joinedStageChannel, setJoinedChannel] = useState(null);
    const [joinChannelError, setChannelJoinError] = useState(null);
    const [allowToPublish, setAllowToPublishState] = useState(false);
    const publishHandler = useRef(noop);
    // Track the current joining request
    const JoiningProcess = useRef(false);

    const [hasInReConnectingState, setReConnectingState] = useState(false);
    const { isOnline } = useNetworkStatus();

    const hasToSubscribe = PermissionUtils.canSubscribeStream();

    const cloudUserInfo = useUserData(currentSession?.cloud_user_id);
    const enablePublishLiveStream = useLiveAirmeetContext(
        hasRTMPStreamingEnabledFromRecorder
    );
    const { disableAutoJoinState, setDisableAutoJoin } = useSessionContext();

    const methods = useRef({});
    const {
        isClosedCaptioningEnabled,
        enableCCSubscriptionViaFirebase,
    } = useLiveAirmeetContext();

    const { config: closedCaptionConfParams } = useSelector(getClosedCaptions);
    const allowToPublishLatestReq = useRef(false);

    const getBackstageChannelId = (fetchOnly = false) => {
        if (
            !airmeet ||
            disableJoinInChannel ||
            userRole === ATTENDEE ||
            (!fetchOnly && LIVE_AIRMEET_STATE.backStage !== currentState)
        ) {
            return;
        }
        return currentSession
            ? backStageChannelId || currentSession.sessionid
            : disableAirmeetBackstage
            ? null
            : airmeet.airmeetId;
    };

    // Channel Name which need to join to session
    const channelId = disableJoinInChannel
        ? null
        : LIVE_AIRMEET_STATE.goLive === currentState && currentSession
        ? liveStageChannelId || currentSession.sessionid
        : getBackstageChannelId();
    const previousChannelId = usePrevious(channelId, channelId);
    const previousJoinedChannelId = usePrevious(joinedStageChannel);

    const isCustomMedia = stageService?.hasPublishPreRecordedStream();

    const canCloudHostSwitchChannel =
        isCustomMedia &&
        currentState === LIVE_AIRMEET_STATE.goLive &&
        stageChannels.includes(channelId) &&
        joinedStageChannel !== channelId;

    const canUserSwitchChannel =
        channelId !== null &&
        joinedStageChannel &&
        hasAccess(SDK_INTERACTION.ALLOWED_SWITCH_CHANNEL) &&
        stageChannels.includes(joinedStageChannel) &&
        stageChannels.includes(channelId) &&
        joinedStageChannel !== channelId;

    const switchingChannel =
        backStageChannelId &&
        liveStageChannelId &&
        (canUserSwitchChannel || canCloudHostSwitchChannel);
    const uid = useMemo(uuid, []);
    methods.current.addLog = (message, props = {}) => {
        logger.info(
            `useLiveEventClient - ${message}`,
            JSON.stringify({
                uid,
                ...props,
                channelId,
                isOnline,
                joinedStageChannel,
                previousChannelId,
                currentState,
                userRole,
                userStageRole,
                disableJoinInChannel,
                status: currentSession?.status,
                is1080PSession,
                disableAutoJoinState,
                stageChannels,
                switchingChannel,
                switchChannelAccess: hasAccess(
                    SDK_INTERACTION.ALLOWED_SWITCH_CHANNEL
                ),
            })
        );
    };

    methods.current.joinStageChannel = (
        publishVideo = false,
        callback = () => {}
    ) => {
        methods.current.addLog('Join Channel invoke', {
            JoiningProcess: JoiningProcess.current,
            channelId,
            liteModeActive,
        });
        if (
            !channelId ||
            liteModeActive ||
            (JoiningProcess.current && JoiningProcess.current === channelId)
        ) {
            return;
        }
        JoiningProcess.current = channelId;
        methods.current.addLog('Joining Channel');
        publishVideo = false;

        const skipStreamsToSubscribing = {};
        if (cloudUserInfo) {
            skipStreamsToSubscribing[cloudUserInfo.id_seq] = cloudUserInfo.id;
        }

        const userLoginType = PermissionUtils.isUserSessionCloudHost()
            ? UserLoginType.CLOUD_HOST
            : PermissionUtils.isSessionScreenRecordingUser()
            ? UserLoginType.SCREEN_RECORDER
            : UserLoginType.USER;
        stageService
            .join(
                channelId,
                {
                    attendeeMode: publishVideo ? 'video' : 'audience',
                    resolution: streamVideoProfile,
                    skipStreamsToSubscribing: skipStreamsToSubscribing || {},
                    canSubscribe: hasToSubscribe,
                    isClosedCaptioningEnabled,
                    is1080Enabled: is1080PSession,
                    enableCCSubscriptionViaFirebase,
                    sourceModule:
                        LIVE_AIRMEET_STATE.goLive === currentState
                            ? RTC_SOURCE_MODULES.LIVE_STAGE
                            : RTC_SOURCE_MODULES.BACK_STAGE,
                    userLoginType,
                    closedCaptionConfParams,
                },
                (response) => {}
            )
            .then((response) => {
                methods.current.addLog('Joined Channel', {
                    response,
                });
                JoiningProcess.current = false;
                setJoinedChannel(stageService.channelName);
                setChannelJoinError(null);
                callback();
            })
            .catch(function ([e, rejectFromSdk]) {
                methods.current.addLog('Joining Channel Failed', {
                    error: e,
                    rejectFromSdk,
                });
                JoiningProcess.current = false;
                setJoinedChannel(null);
                if (rejectFromSdk) {
                    methods.current.addLog('Joining Channel Failed Set ERROR');
                    setChannelJoinError(e);
                }
            });
    };

    const isReadyToPublish = useMemo(() => {
        const canStartPublish =
            !isInviteToStagePermissionInProgress && joinedStageChannel;
        if (!canStartPublish) {
            methods.current.addLog(
                `Modifying allow to publish isReadyToPublish. Permission modal in progress : ${isInviteToStagePermissionInProgress},  joinedStageChannel: ${joinedStageChannel} }`
            );
        }
        return canStartPublish;
    }, [joinedStageChannel, isInviteToStagePermissionInProgress]);

    const setAllowToPublish = useCallback(
        (allowToPublishFlag, source = '') => {
            allowToPublishLatestReq.current = allowToPublishFlag;
            methods.current.addLog(
                `Update allowToPublish State request received from  ${source}`,
                allowToPublishFlag
            );
            if (!isReadyToPublish && allowToPublishFlag) {
                methods.current.addLog(
                    `skip[hold] to allowToPublish State request received from  ${source}`,
                    allowToPublishFlag
                );
                return;
            }
            setAllowToPublishState(allowToPublishFlag);
        },
        [setAllowToPublishState, isReadyToPublish]
    );

    useEffect(() => {
        if (
            allowToPublishLatestReq.current &&
            isReadyToPublish &&
            allowToPublish !== allowToPublishLatestReq.current
        ) {
            methods.current.addLog(
                `Setting allow to publish state to this ${allowToPublishLatestReq.current}, as current state and latest both are not equal `
            );
            setAllowToPublish(allowToPublishLatestReq.current);
        }
    }, [isReadyToPublish, allowToPublish, setAllowToPublish]);

    const publishStageStream = (props) => {
        if (PermissionUtils.isEventCloudHost() && enablePublishLiveStream) {
            methods.current.addLog(
                'Not need to publishStageStream for cloud host in case enable streaming form recorder'
            );
            return;
        }
        const attendeeMode = PermissionUtils.isEventCloudHost()
            ? 'audio-only'
            : 'video';

        stageService
            .publishStreamIfNot({
                attendeeMode,
                ...props,
                mirror: mirrorLocalVideo,
            })
            .catch((e) => {
                logger.info('Publish stream failed', e);

                if (e?.reason === 'mismatch_channel') {
                    // retrying to publish stream to check whether it can publish or not
                    publishHandler.current();
                }
            });
    };

    const channelJoined =
        joinedStageChannel !== null &&
        (joinedStageChannel === channelId || switchingChannel);

    publishHandler.current = () => {
        const canPublish = allowToPublish && channelJoined;
        if (!joinedStageChannel) {
            methods.current.addLog(
                'publishHandler call without joined stage channel',
                {
                    canPublish,
                    channelJoined,
                }
            );
        }

        if (canPublish) {
            methods.current.addLog('Publish stream call, allowToPublish:', {
                canPublish,
                joinedStageChannel,
            });
            stageService.logDataToSDK({
                msg: `userRoleUpdateSource -  Role upgrade requested as ${userStageRole} to publish`,
            });
            publishStageStream({
                isEventCloudHost: PermissionUtils.isSessionCloudHost(), // using this param to mute cloudhost's audio
            });
        } else {
            methods.current.addLog('Un-publish stream call, allowToPublish:', {
                canPublish,
                joinedStageChannel,
            });
            unpublishStageStream();
        }
    };

    const unpublishStageStream = () => {
        methods.current.addLog(`Un-publish stream method called`, {
            allowToPublish,
        });
        stageService.unpublishStream(true);
    };

    methods.current.leaveStageChannel = async (props = {}) => {
        const { disableAutoJoin = false } = props;
        setDisableAutoJoin(disableAutoJoin);
        stageService && (await stageService.leaveBroadCastRTC());
        methods.current.addLog('Leave Channel', {
            disableAutoJoin,
        });
        setJoinedChannel(null);
    };

    useEffect(() => {
        if (previousChannelId === channelId) {
            return;
        }
        if (switchingChannel) {
            methods.current.addLog(
                'base on previousChannelId updated: switch channel is enabled',
                {
                    previousChannelId,
                    channelId,
                    allowToPublish,
                    joinedStageChannel,
                    switchingChannel,
                }
            );
            return;
        }
        methods.current.addLog(
            'base on previousChannelId updated: reset allowedToPublish',
            {
                previousChannelId,
                channelId,
                allowToPublish,
                joinedStageChannel,
                switchingChannel,
            }
        );
        setAllowToPublish(false);

        // We need to reset the allowToPublish base on channelId update, So not add any other dependency here
    }, [channelId, switchingChannel]);

    useEffect(() => {
        if (
            joinedStageChannel &&
            ((channelId !== joinedStageChannel && !switchingChannel) ||
                liteModeActive)
        ) {
            methods.current.addLog('Leave Channel on mismatch channel id', {
                channelId,
                joinedStageChannel,
            });
            stageService.logDataToSDK({
                msg:
                    'leaveChannelSource - switched to another session or mismatch of channel id',
            });
            methods.current.leaveStageChannel().then(() => {
                if ([LIVE_AIRMEET_STATE.backStage].includes(currentState)) {
                    methods.current.joinStageChannel(true);
                }
            });
        }
    }, [channelId, joinedStageChannel, liteModeActive, switchingChannel]);

    useEffect(() => {
        if (joinedStageChannel) {
            const onConnectionStateChange = (curState) => {
                if (typeof curState === 'object') {
                    curState = curState?.currState;
                }

                const isConnected = curState === 'CONNECTED';
                methods.current.addLog('onConnectionStateChange', {
                    isConnected,
                    curState,
                });
                setReConnectingState(!isConnected);
            };

            stageService.on('connection-state-change', onConnectionStateChange);
            return () => {
                if (stageService) {
                    stageService.off(
                        'connection-state-change',
                        onConnectionStateChange
                    );
                }
            };
        } else {
            setReConnectingState(false);
        }
    }, [joinedStageChannel]);
    useEffect(() => {
        if (disableAutoJoinState) {
            const timeout = setTimeout(() => {
                methods.current.addLog(
                    'Set the disableAutoJoinState to false after timeout'
                );
                setDisableAutoJoin(false);
            }, RESET_DISABLE_AUTO_JOIN_STATE_DURATION);
            return () => {
                timeout && clearTimeout(timeout);
            };
        }
    }, [disableAutoJoinState, setDisableAutoJoin]);
    const leaveChannelTimerEnable =
        joinedStageChannel && hasInReConnectingState;
    useEffect(() => {
        if (leaveChannelTimerEnable) {
            const timeout = setTimeout(() => {
                methods.current.addLog('onInReConnectingState: TIMEOUT');
                const msg = 'Leaving channel - due to the 20 seconds timeout';
                logger.info(msg);
                stageService.logDataToSDK({
                    msg:
                        'leaveChannelSource - 20 seconds timeout after network disconnect',
                });
                methods.current.leaveStageChannel();
            }, 20 * 1000);
            return () => {
                timeout && clearTimeout(timeout);
            };
        }
    }, [leaveChannelTimerEnable, stageService]);

    // call switch channel API
    useEffect(() => {
        if (switchingChannel) {
            methods.current.addLog('Switching channel invoked');
            stageService &&
                stageService
                    .switchChannel(channelId)
                    .then(({ success, errorCode, hasChannelJoined }) => {
                        if (success) setJoinedChannel(channelId);
                        else {
                            if (
                                [
                                    'mismatch_channel',
                                    'already_in_channel',
                                ].includes(errorCode) &&
                                !hasChannelJoined
                            ) {
                                methods.current.addLog(
                                    'Retrying join channel since switch channel is skipped'
                                );
                                joinStageChannel();
                            } else if (
                                errorCode === 'already_in_channel' &&
                                hasChannelJoined
                            ) {
                                return;
                            } else {
                                setChannelJoinError(true);
                                setJoinedChannel(null);
                            }
                        }
                    });
        }
    }, [switchingChannel, stageService]);

    useEffect(() => {
        if (joinedStageChannel && joinedStageChannel === channelId) {
            if (
                ![
                    LIVE_AIRMEET_STATE.backStage,
                    LIVE_AIRMEET_STATE.goLive,
                ].includes(currentState) ||
                liteModeActive
            ) {
                stageService.logDataToSDK({
                    msg: `leaveChannelSource - because user moved to ${currentState}`,
                });
                methods.current.leaveStageChannel();
            }
        } else {
            if (
                isOnline &&
                !disableAutoJoinState &&
                [
                    LIVE_AIRMEET_STATE.backStage,
                    LIVE_AIRMEET_STATE.goLive,
                ].includes(currentState) &&
                !liteModeActive &&
                !isBreakoutActive &&
                !joinedStageChannel &&
                !switchingChannel
            ) {
                methods.current.joinStageChannel();
            } else if (
                [
                    LIVE_AIRMEET_STATE.backStage,
                    LIVE_AIRMEET_STATE.goLive,
                ].includes(currentState) &&
                !liteModeActive
            ) {
                methods.current.addLog('Not meet req for join', {
                    isOnline,
                    disableAutoJoinState,
                    joinedStageChannel,
                    isBreakoutActive,
                });
            }
        }
    }, [
        joinedStageChannel,
        currentState,
        disableAutoJoinState,
        liteModeActive,
        isBreakoutActive,
        isOnline,
        channelId,
        switchingChannel,
    ]);

    useEffect(() => {
        methods.current.addLog('Updated allowToPublish', { allowToPublish });
        if (!joinedStageChannel) {
            methods.current.addLog('Channel not joined yet');
            return;
        }

        if (!previousJoinedChannelId && !allowToPublish) {
            methods.current.addLog(
                'Ignore the publisher handler as user just join channel, and allow to publish false'
            );
            return;
        }

        const timeout = setTimeout(() => {
            publishHandler.current();
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [allowToPublish, joinedStageChannel]);

    useEffect(() => {
        if (streamVideoProfile && stageService?.setResolution) {
            stageService.setResolution(streamVideoProfile);
        }
    }, [streamVideoProfile, stageService]);

    // updating the source module
    useEffect(() => {
        if (joinedStageChannel && stageService) {
            stageService.updateSourceModuleName(
                LIVE_AIRMEET_STATE.goLive === currentState
                    ? RTC_SOURCE_MODULES.LIVE_STAGE
                    : RTC_SOURCE_MODULES.BACK_STAGE
            );
        }
    }, [joinedStageChannel, currentState, stageService]);

    // setting up audio/video controls on joining stage
    useEffect(() => {
        if (!stageService) {
            return;
        }
        stageService.setDefaultAVState({
            defaultAudioMute: STAGE_DEFAULT_AV_STATES.isAudioMute,
            defaultVideoMute: STAGE_DEFAULT_AV_STATES.isVideoMute,
        });
    }, [stageService]);

    const joinStageChannel = useCallback(
        async (publishVideo, callback = () => {}) => {
            return methods.current.joinStageChannel(publishVideo, callback);
        },
        []
    );

    const leaveStageChannel = useCallback(async (props = {}) => {
        return methods.current.leaveStageChannel(props);
    }, []);

    const stageChannelId =
        channelId ||
        getBackstageChannelId(
            PermissionUtils.isSessionCloudHost() &&
                LIVE_AIRMEET_STATE.goLive !== currentState
        );

    return {
        stageJoining: JoiningProcess.current,
        joinedStageChannel: joinedStageChannel,
        joinStageChannel,
        channelName: channelId,
        joinChannelError,
        allowToPublish,
        setAllowToPublish,
        leaveStageChannel,
        setDisableAutoJoin,
        disableJoinInChannel,
        stageChannelId,
        channelJoined,
        switchingChannel,
    };
}
