import TooltipV2 from 'atoms/Tooltip/Tooltip.tsx';
import Tooltip from 'components/tooltip/Tooltip';
import { Typography } from 'foundations/typography';
import React from 'react';

const WithTooltip = (props) => {
    const {
        tooltip,
        place,
        children,
        offset,
        className,
        style,
        clickable,
        enableTimeout,
        toolClass,
        renderElement,
        tooltipContentProps = {},
        containerId = '',
        isCapture = false,
        isThemeV2 = false,
        maxWidth = '200px',
        hasWrapper = true,
        isSmall = false,
    } = props;
    return tooltip || renderElement ? (
        isThemeV2 ? (
            <TooltipV2
                content={renderElement || tooltip}
                hasWrapper={hasWrapper}
                side={place}
                sideOffset={offset}
                className={className}
                containerId={containerId}
                maxWidth={maxWidth}
                isSmall={isSmall}
            >
                {children}
            </TooltipV2>
        ) : (
            <Tooltip
                type='iconInfo'
                toolClass={toolClass ? toolClass : 'summitTooltip'}
                className={className}
                style={style}
                place={place}
                containerId={containerId}
                isCapture={isCapture}
                renderElement={
                    renderElement ? (
                        renderElement
                    ) : (
                        <Typography
                            variant='subtext2'
                            color='ambience.21'
                            {...(tooltipContentProps || {})}
                        >
                            {tooltip}
                        </Typography>
                    )
                }
                renderTrigger={children}
                offset={offset}
                clickable={clickable}
                enableTimeout={enableTimeout}
            />
        )
    ) : (
        children
    );
};

export default WithTooltip;
