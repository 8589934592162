import {
    getQueryParamFromUrl,
    accessCodeInUrl,
    setAccessCodeInSession,
    authenticateAccessCode,
} from 'hooks/live-airmeet/useAccessCodeUserToken';
import React, { useMemo } from 'react';
import { Route } from 'react-router-dom';
import useRouteBase from './common';
import history from '../../history';

const PublicRoute = ({ component: Component, ...rest }) => {
    useRouteBase({ ...rest });
    const isEventRoute = rest.exact && '/e/:id' === rest.path;
    const isEventSeriesRoute =
        rest.exact &&
        (rest.path.includes('/es/:eventSeriesId') ||
            rest.path.includes('/es/:eventSeriesId/mobile-series'));
    useMemo(() => {
        if (!isEventRoute && !isEventSeriesRoute) {
            return false;
        }
        let queryName = getQueryParamFromUrl(history);
        if (queryName) {
            setAccessCodeInSession({
                queryParam: queryName,
                tokenVal: accessCodeInUrl(queryName, history),
                airmeetId: isEventSeriesRoute
                    ? rest.computedMatch.params?.eventSeriesId
                    : rest.computedMatch.params?.id,
                replaceUrl: rest.location.pathname,
                isEventSeries: isEventSeriesRoute,
            });
        } else {
            authenticateAccessCode(
                isEventSeriesRoute
                    ? rest.computedMatch.params?.eventSeriesId
                    : rest.computedMatch.params?.id,
                isEventSeriesRoute
            );
        }
    }, [
        isEventRoute,
        isEventSeriesRoute,
        rest.computedMatch.params.id,
        rest.computedMatch.params.eventSeriesId,
        rest.location.pathname,
    ]);

    return (
        <Route
            {...rest}
            render={(props) => {
                return <Component {...props} />;
            }}
        />
    );
};

export default PublicRoute;
