import ErrorBoundary from 'components/general/ErrorBoundary';
import Loader from 'components/general/Loader';
import App from 'containers/App';
import { AirmeetThemeProvider } from 'context/ThemeProvider';
import moment from 'moment';
import momentdurationformat from 'moment-duration-format'; // must needed for using .format() function with duration()
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AppProvider } from './context/App';
import './i18next';
import store from './store/reducers';
import './wdyr';
import { defineCustomElements } from '@duetds/date-picker/dist/loader';

// Script to load webworker from same origin
// https://github.com/GoogleChromeLabs/worker-plugin/issues/36#issuecomment-664970411

if (process.env.REACT_APP_CDN_ASSETS_PATH) {
    (function () {
        var _Worker = window.Worker;
        window.Worker = function (url, opts) {
            if (url.includes(process.env.REACT_APP_CDN_ASSETS_PATH)) {
                const workerPath = `importScripts(${JSON.stringify(url)})`;
                var blob = new Blob([workerPath], {
                    type: 'text/javascript',
                });
                url = URL.createObjectURL(blob);
            }
            return new _Worker(url, opts);
        };
    })();
}

momentdurationformat(moment); // must needed for using .format() function with duration()

moment.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        ss: '%d secs',
        m: 'a min',
        mm: '%d mins',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        w: 'a week',
        ww: '%d weeks',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years',
    },
});

// Register Duet Date Picker
defineCustomElements(window);

const EmbeddedPageWrapper = React.lazy(() =>
    import('widgets/EmbeddablePageWrapper')
);

/**
 * ******************************** GUM Debugging ********************************
 * Please contact RTC team before making any changes
 */
const GUM_DEBUG_KEY = 'overwriteGUM';
const overwriteGUM = () => {
    const gum = navigator.getUserMedia;
    if (!Array.isArray(window.gumInvokes)) {
        window.gumInvokes = [];
    }

    console.warn(
        'GUM is overwritten. Using deprecated navigator.getUserMedia for debugging purpose'
    );

    window.checkForLeakedStream = () => {
        let found = false;
        window.gumInvokes.forEach((item) => {
            const ms = item.stream;
            if (ms) {
                const aTrack = ms.getAudioTracks()[0];
                const vTrack = ms.getVideoTracks()[0];

                const aLive = aTrack?.readyState === 'live';
                const vLive = vTrack?.readyState === 'live';

                if (aLive || vLive) {
                    found = true;
                    console.log(`Leaked - a:${aLive}, v:${vLive}`, item);
                }
            }
        });
        if (!found) {
            console.log('No tracks are leaked');
        }
    };

    if (!window.originalGUM) {
        window.originalGUM = navigator.mediaDevices.getUserMedia;
    }
    navigator.mediaDevices.getUserMedia = (arg) => {
        return new Promise((resolve, reject) => {
            const e = new Error();
            const obj = {
                arg: arg,
                stack: e.stack,
            };
            window.gumInvokes.push(obj);
            gum.call(
                navigator,
                arg,
                (stream) => {
                    obj.stream = stream;
                    const faultyDeviceId = localStorage.getItem(
                        'faultyDeviceId'
                    );

                    if (faultyDeviceId) {
                        const tracks = stream.getTracks();
                        let isDeviceUsed = false;
                        tracks.forEach((track) => {
                            const settings = track.getSettings();
                            if (settings.deviceId === faultyDeviceId) {
                                isDeviceUsed = true;
                            }
                        });

                        if (isDeviceUsed) {
                            setTimeout(() => {
                                resolve(stream);
                            }, 10000);
                        } else {
                            resolve(stream);
                        }
                    } else {
                        resolve(stream);
                    }
                },
                (err) => {
                    obj.error = err;
                    reject(err);
                }
            );
        });
    };
};
const restoreGUM = () => {
    if (window.originalGUM) {
        navigator.mediaDevices.getUserMedia = window.originalGUM;
    }
};
window.enableGumDebugging = () => {
    sessionStorage.setItem(GUM_DEBUG_KEY, true);
    overwriteGUM();
};
window.stopGumDebugging = () => {
    sessionStorage.removeItem(GUM_DEBUG_KEY);
    restoreGUM();
};
if (sessionStorage.getItem(GUM_DEBUG_KEY)) {
    overwriteGUM();
}
/**
 * ******************************** GUM Debugging Ends ********************************
 */

ReactDOM.render(
    <Provider store={store}>
        <AirmeetThemeProvider>
            <AppProvider>
                <ErrorBoundary>
                    <Suspense fallback={<Loader fullPage={true} type='logo' />}>
                        <BrowserRouter>
                            <Switch>
                                <Route path='/widgets'>
                                    <EmbeddedPageWrapper />
                                </Route>
                                <Route path='/'>
                                    <App />
                                </Route>
                            </Switch>
                        </BrowserRouter>
                    </Suspense>
                </ErrorBoundary>
            </AppProvider>
        </AirmeetThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
