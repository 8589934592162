import {
    instanceTypes,
    ISDKConfigWithEnvAndToken,
    setupSDKEnvironment,
} from '@airmeet/rtc-sdk';
// @ts-ignore
import rtcSDKConfig from '@airmeet/rtc-sdk/dist/config/config-SDK_ENV_TARGET.json';
// @ts-ignore
import precallConfig from '@airmeet/rtc-sdk/dist/config/precall/config-SDK_ENV_TARGET.json';

const isProdOrPreprod =
    process.env.REACT_APP_RTC_SDK_ENV === 'production' ||
    process.env.REACT_APP_RTC_SDK_ENV === 'preprod';

const envConfigs = {
    [instanceTypes.SDK]: rtcSDKConfig,
    [instanceTypes.PRECALL]: precallConfig,
};

export const setRTCSDKEnv = (
    type: string,
    airmeetId: string = '',
    userId: string = ''
) => {
    if (type === instanceTypes.PRECALL && (!airmeetId || !userId)) {
        airmeetId = envConfigs[type].airmeetId;
        userId = envConfigs[type].authToken;
    }
    const config: ISDKConfigWithEnvAndToken = {
        envConfig: envConfigs[type],
        airmeetId,
        authToken: userId,
        upstreamAuthUrl: isProdOrPreprod
            ? ''
            : process.env.REACT_APP_API_BASE_URL,
    };
    setupSDKEnvironment(type, config);
};

export { instanceTypes, rtcSDKConfig };
