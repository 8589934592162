import ReconnectingWebSocket, { Options } from 'reconnecting-websocket';

/**
 *  WebSocket?: any; // WebSocket constructor, if none provided, defaults to global WebSocket
 *  maxReconnectionDelay?: number; // max delay in ms between reconnections
 *  minReconnectionDelay?: number; // min delay in ms between reconnections
 *  reconnectionDelayGrowFactor?: number; // how fast the reconnection delay grows
 *  minUptime?: number; // min time in ms to consider connection as stable
 *  connectionTimeout?: number; // retry connect if not connected after this time, in ms
 *  maxRetries?: number; // maximum number of retries
 *  maxEnqueuedMessages?: number; // maximum number of messages to buffer until reconnection
 *  startClosed?: boolean; // start websocket in CLOSED state, call `.reconnect()` to connect
 *  debug?: boolean; // enables debug output
 */
export interface WebsocketOptions extends Options {
    onError?: (event: Error) => void;
}

interface ConnectionMeta {
    [key: string]: string | number | null | Function;
}

interface WebsocketConfig {
    connection: ReconnectingWebSocket | null;
    meta: ConnectionMeta;
    options: WebsocketOptions;
}

const wsConfig: WebsocketConfig = {
    connection: null,
    meta: {},
    // Overrides some defaults from:
    // https://www.npmjs.com/package/reconnecting-websocket#default-values
    options: {
        maxEnqueuedMessages: 1000,
        // Enable this to debug
        debug: false,
    },
};

// Setup the WS connection
export function initializeConnection(
    authTokenOrUserId: string,
    options?: WebsocketOptions
) {
    // Update the options
    wsConfig.options = { ...wsConfig.options, ...options };

    // Setup the connection, if not already done
    wsConfig.connection =
        wsConfig.connection ||
        new ReconnectingWebSocket(
            process.env.REACT_APP_INTERCEPTOR_ENDPOINT,
            [authTokenOrUserId],
            wsConfig.options
        );

    const client = wsConfig.connection;

    client.onerror = function (error) {
        if (options.onError)
            options.onError(
                error.error
                    ? error.error
                    : new Error(error.message ? error.message : error.type)
            );
        console.error('Interceptor Client Error', error);
    };

    client.onopen = function () {
        console.log('Interceptor Client Connected');
    };

    client.onclose = function () {
        console.log('Interceptor Client Closed');
    };
}

export function closeInterceptorConnection() {
    if (wsConfig.connection) {
        wsConfig.connection.close();
    }
}

export function configureMeta(callback: (meta: ConnectionMeta) => void) {
    callback(wsConfig.meta);
}
// The websocket library we use supports auto reconnection with
// exponential backoff and message bufferring between connects
export function sendMessage(eventType: string, ...args: any[]) {
    const argsString = args.join(' ');

    return sendJSON({
        eventType,
        data: argsString,
    });
}

export function sendJSON(json: { [key: string]: any }) {
    const { connection: client, meta: connectionMeta } = wsConfig;
    if (client !== null) {
        client.send(
            JSON.stringify({
                ...connectionMeta,
                ...json,
            })
        );
    }
}

// const hostname = window.location.hostname.replace('www.', '');
// export const getEnvUrl = (url) => {
//     if (!url || url === '') return url;

//     if (!['www.airmeet.com', 'localhost'].includes(window.location.hostname)) {
//         return url.replace('airmeet.com', hostname);
//     }

//     return url;
// };
