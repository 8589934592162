import { FlexDiv } from 'components/molecules/utils/basicComponents';
import SVGIcon from 'foundations/icon';
import { Typography } from 'foundations/typography';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { layout, space } from 'styled-system';
import { noop } from 'utils/constants/common';

const Checkbox = React.forwardRef(
    (
        {
            name,
            id,
            label,
            labelVariant,
            labelColor,
            labelStyle = {},
            onClick = noop,
            handleKeyDown = () => {},
            iconSize,
            disabled,
            labelLightThemeColor = '',
            isChecked = false,
            type = 'button',
            uncheckedFill = 'ambience.6',
            allowClickOnLabel = false,
            filledSvgColor,
            tooltipPosition = 'top',
            tooltipText,
            showToolTip = false,
            tooltipStyle = {},
            iconMarginTop = '',
            children,
            checkedIcon = 'checkbox_checked',
            checkboxOuterStyle = {},
            uncheckedIcon = 'checkbox_unchecked',
            ariaLabel,
            ...props
        },
        ref
    ) => {
        const [checked, setChecked] = useState(isChecked);
        const onCheckboxClick = (e) => {
            if (disabled) {
                return;
            }
            const currVal = !checked;
            onClick({ name, id, value: currVal });
            e.stopPropagation();
        };
        useEffect(() => {
            setChecked(isChecked);
        }, [isChecked]);

        const handleDivclick = (event) => {
            if (allowClickOnLabel) {
                onCheckboxClick(event);
            }
        };
        return (
            <FlexDiv alignItems='center' {...props} onClick={handleDivclick}>
                <CheckBoxButtonOuter
                    mr={(label || children) && '6px'}
                    style={checkboxOuterStyle}
                >
                    <CheckboxIconButton
                        type={type}
                        onClick={onCheckboxClick}
                        onKeyDown={handleKeyDown}
                        height={iconSize}
                        role='checkbox'
                        width={iconSize}
                        minWidth={iconSize}
                        disabled={disabled}
                        aria-label={
                            ariaLabel || label
                                ? `option ${ariaLabel || label}`
                                : 'option'
                        }
                        aria-checked={checked}
                        ref={ref}
                    >
                        {checked ? (
                            <SVGIcon
                                icon={checkedIcon}
                                fill={
                                    filledSvgColor
                                        ? filledSvgColor
                                        : 'accentPrimary.0'
                                }
                                height='100%'
                                width='100%'
                                mt={iconMarginTop ? iconMarginTop : '-6px'}
                            />
                        ) : (
                            <SVGIcon
                                icon={uncheckedIcon}
                                fill={disabled ? 'ambience.18' : uncheckedFill}
                                height='100%'
                                width='100%'
                                mt={iconMarginTop ? iconMarginTop : '-6px'}
                            />
                        )}
                    </CheckboxIconButton>
                    {tooltipText && (
                        <TooltipTypography
                            className={
                                showToolTip
                                    ? 'show-tooltip checkbox-tooltip'
                                    : 'checkbox-tooltip'
                            }
                            variant={'subtext0'}
                            color={'ambience.21'}
                            style={tooltipStyle}
                        >
                            {tooltipText}
                        </TooltipTypography>
                    )}
                </CheckBoxButtonOuter>
                {label && (
                    <CheckboxTypography
                        variant={labelVariant ? labelVariant : 'subtext2'}
                        color={labelColor ? labelColor : 'ambience.6'}
                        style={labelStyle}
                        lightThemeColor={
                            labelLightThemeColor
                                ? labelLightThemeColor
                                : 'ambience.6'
                        }
                    >
                        {label}
                    </CheckboxTypography>
                )}
                {children}
            </FlexDiv>
        );
    }
);

const CheckboxIconButton = styled.button`
    background: none;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 0;
    height: 16px;
    width: 12px;

    &:disabled {
        cursor: not-allowed;
    }
    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: ${({ theme }) =>
            theme.isLightTheme
                ? theme.isNewOnboardingPage
                    ? `2px solid ${theme.colors.accentPrimary[1]}`
                    : '2px solid black'
                : '2px solid white'};
    }

    ${space}
    ${layout}
`;
const CheckboxTypography = styled(Typography)`
    &:hover {
        cursor: pointer;
    }
`;
const CheckBoxButtonOuter = styled.div`
    position: relative;
    ${space}
    ${layout}
    button: hover + p {
        visibility: visible;
        opacity: 1;
    }
`;

const TooltipTypography = styled(Typography)`
    visibility: hidden;
    width: 120px;
    background-color: ${({ theme }) => theme.colors.white};
    text-align: left;
    border-radius: 8px;
    padding: 12px;
    position: absolute;
    z-index: 1;
    bottom: 130%;
    left: -10px;
    opacity: 0;
    transition: opacity 0.3s;

    &:after {
        content: '';
        position: absolute;
        top: 84%;
        left: 18px;
        width: 12px;
        height: 12px;
        border-radius: 4px;
        transform: rotate(45deg);
        background-color: ${({ theme }) => theme.colors.white};
    }
    &.show-tooltip {
        visibility: visible !important;
        opacity: 1 !important;
    }
`;

export default Checkbox;
