import get from 'lodash/get';

export const noop = () => {};

export const noopPromise = () => Promise.resolve();

export const noComponent = () => null;

export const isAutomationMode = () =>
    Boolean(process.env.REACT_APP_AUTOMATION_MODE);
/** General selector function to access data at any level within store or context */
export const select = (path, defaultValue) => (state) =>
    get(state, path, defaultValue);

export const conferenceModes = {
    LIVE: 'live',
    RTC: 'rtc',
};
export const videoCodecs = {
    H264: 'h264',
    VP8: 'vp8',
    VP9: 'vp9',
};

export const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID;
export const RTC_ROLES = {
    HOST: 'host',
    AUDIENCE: 'audience',
};
export const VENDOR_AGORA = 'Agora';
export const VENDOR_DAILY = 'Daily';

export const emptyObject = Object.freeze({});
export const emptyArray = Object.freeze([]);

export const SDK_EVENTS = {
    USER_JOINED: 'user-joined',
    USER_LEFT: 'user-left',
    REMOTE_AUDIO_PUBLISHED: 'remote-audio-track-published',
    REMOTE_AUDIO_UNPUBLISHED: 'remote-audio-track-unpublished',
    REMOTE_SCREEN_PUBLISHED: 'remote-screen-track-published',
    REMOTE_SCREEN_AUDIO_PUBLISHED: 'remote-screen-audio-track-published',
    REMOTE_SCREEN_UNPUBLISHED: 'remote-screen-track-unpublished',
    REMOTE_VIDEO_UNPUBLISHED: 'remote-video-track-unpublished',
    REMOTE_VIDEO_PUBLISHED: 'remote-video-track-published',
    AUDIO_VIDEO_STATUS_CHANGED: 'capture-audio-video-status-changed',
    NETWORK_QUALITY_CHANGED: 'network-quality-change', // this event is being handled in NetworkDetectionsV3
    VOLUME_INDICATOR_CHANGED: 'volume-indicator', // this event is being handled in MembersV2 for performance reason
    USER_ACTIVE: 'new-active-remote-user',
    USER_INACTIVE: 'new-inactive-remote-user',
    NEW_DOMINANT_SPEAKER: 'new-dominant-speaker',
    SCREEN_SHARE_STREAM_STOPPED: 'local-screen-track-unpublished-natively',
    USER_RECONNECT_START: 'remote-subscription-reconnecting',
    USER_RECONNECT_FINISH: 'remote-subscription-reconnected',
    LOCAL_MUTE_VOICE_ACTIVITY: 'local-voice-activity-while-mute',
    REMOTE_CUSTOM_VIDEO_PUBLISHED: 'remote-custom-video-track-published',
    REMOTE_CUSTOM_AUDIO_PUBLISHED: 'remote-custom-audio-track-published',
    REMOTE_CUSTOM_VIDEO_UNPUBLISHED: 'remote-custom-video-track-unpublished',
    REMOTE_CUSTOM_AUDIO_UNPUBLISHED: 'remote-custom-audio-track-unpublished',
    REMOTE_VIDEO_UNMUTED_LOCALLY: 'remote-video-unmuted-locally',
    CONNECTION_STATE_CHANGE: 'connection-state-change',
    REMOTE_USER_AUDIO_VIDEO_STATUS: 'remote-user-audio-video-status-updated',
    LOCAL_AUDIO_TRACK_UNPUBLISHED: 'local-audio-track-unpublished',
    LOCAL_VIDEO_TRACK_UNPUBLISHED: 'local-video-track-unpublished',
    USER_SWITCH_CHANNEL_STATE_UPDATED: 'user-switch-channel-state-updated',
    CAPTION_RECEIVED: 'caption-received',
    STREAM_AUTOPLAY_FAILED: 'autoplay-failed',
    CAM_MIC_DEVICE_BLOCKED: 'device-blocked',
    CAM_MIC_PERMISSION_DENIED: 'permission-denied',
    ERROR: 'error',
    SELECTED_DEVICE_CHANGE: 'selected-device-change',
    USER_AUDIO_INTERRUPTED: 'user-audio-interrupted',
};

export const PRE_RECORDED_VIDEO_MODULE = {
    MEDIA: 'media',
    RESOURCE: 'resource',
    RECORDING: 'recording',
    CLIP: 'clip',
};

export const validationErrors = {
    FIELD_ERROR: 'Special characters like < > are not allowed.',
    RESPONSE_ERROR:
        'Special characters like < > are not allowed. Please remove them and try again.',
    SMALL_FIELD_ERROR: 'Using < > is not allowed.',
};
export const mediaTypes = {
    AUDIO: 'audio',
    VIDEO: 'video',
    SCREEN: 'screen',
    SCREEN_AUDIO: 'screenAudio',
    CUSTOM_VIDEO: 'customVideo',
    CUSTOM_PDF: 'customPDF',
    CUSTOM_AUDIO: 'customAudio',
};

export const STAGE_STREAM_MODES = {
    highResolution: 'hd',
    lowResolution: 'ld',
    audioOnly: 'audio',
    liteMode: 'liteMode',
};
export const pinTypes = {
    FOR_ALL: 'for_all',
    FOR_SELF: 'for_self',
};
export const streamOptimizationMode = {
    DETAIL: 'detail',
    MOTION: 'motion',
};
export const LITE_MODE_DIMENSIONS = { width: 1280, height: 720 };

export const STAGE_PLAY_VIDEO_CONTENT_ID = 'stage';
export const STAGE_ACTIVE_BANNER_CONTENT_TYPE = 'banners';
export const STAGE_WAITING_SCREEN_CONTENT_ID = 'stage';

export const TRANSACTION_FAIL_REASONS = {
    DISCONNECT: 'disconnect',
    OVERRIDDEN_BY_SET: 'set',
};

export const RTC_SOURCE_MODULES = {
    LIVE_STAGE: 'liveStage',
    BACK_STAGE: 'backStage',
    SOCIAL_LOUNGE: 'socialLounge',
    SPEED_NETWORK: 'speedNetwork',
    BOOTH_LOUNGE: 'boothLounge',
    BOOTH_BROADCAST: 'boothBroadcast',
    FLUID_LOUNGE: 'fluidLounge',
    LIVE_ANNOUNCEMENT: 'liveAnnouncement',
    SCHEDULED_MEETING: 'scheduledMeeting',
    BREAKOUT_ROOM: 'breakoutRoom',
    GROUP_MEETING: 'groupMeeting',
};

export const CHANNEL_TAGS = {
    GENERAL: 'General',
};

export const SDK_CODES = {
    ERROR: {
        AUDIO: [8007, 8011],
        VIDEO: [7007, 7008, 7010, 7011],
    },
};

export const SESSION_REPLAY_PLAYER_ID = 'session_replay_player';

export const isSessionReplayPlayerMounted = () => {
    return !!document.getElementById(SESSION_REPLAY_PLAYER_ID);
};

export const UserLoginType = {
    SCREEN_RECORDER: 'screenRecorder',
    CLOUD_HOST: 'cloudHost',
    USER: 'user',
};
