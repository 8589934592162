/**************************************************************
 * @fileOverview Leads Reducers
 **************************************************************/

import {
    CONFERENCE_TRIAL_CONTACT_LEAD,
    CONFERENCE_TRIAL_LIVE_DEMO,
    CONFERENCE_TRIAL_EVENT_CREATED,
    FETCH_LEADS_RESPONSE,
} from '../actions/leads';

const initialState = {
    [CONFERENCE_TRIAL_CONTACT_LEAD]: [],
    [CONFERENCE_TRIAL_LIVE_DEMO]: [],
    [CONFERENCE_TRIAL_EVENT_CREATED]: [],
};

function leads(state = initialState, action) {
    const { type, payload, extra } = action;
    switch (type) {
        case FETCH_LEADS_RESPONSE:
            const leads = payload?.data;
            const { leadName } = extra;
            return {
                ...state,
                [leadName]: [...leads],
            };
        default:
            return state;
    }
}

export default leads;
