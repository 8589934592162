const userProfileWriter = ({
    airmeetId,
    client,
    payload,
    metaData,
    logger,
}) => {
    const userId = metaData?.userId;

    if (!userId) {
        throw new Error('Invalid user presence firebase key');
    }

    const userLoginPath = `${airmeetId}/onlineUsers/${userId}`;

    return client.setDataAsync(userLoginPath, payload);
};

export default userProfileWriter;
