import { API_SERVICE } from 'utils/apiService';
import { CALL_API } from '../api';

export const FETCH_CREATE_PROJECT_REQUEST = 'FETCH_CREATE_PROJECT_REQUEST';
export const FETCH_CREATE_PROJECT_RESPONSE = 'FETCH_CREATE_PROJECT_RESPONSE';
export const FETCH_UPDATE_PROJECT_REQUEST = 'FETCH_UPDATE_PROJECT_REQUEST';
export const FETCH_UPDATE_PROJECT_RESPONSE = 'FETCH_UPDATE_PROJECT_RESPONSE';

export function createProject(payload) {
    return {
        [CALL_API]: {
            types: [
                FETCH_CREATE_PROJECT_REQUEST,
                FETCH_CREATE_PROJECT_RESPONSE,
            ],
            endpoint: '/project',
            method: 'POST',
            body: {
                ...payload,
            },
            service: API_SERVICE.BFF,
            type: 'json',
        },
    };
}

export function updateProject(data) {
    return {
        [CALL_API]: {
            types: [
                FETCH_UPDATE_PROJECT_REQUEST,
                FETCH_UPDATE_PROJECT_RESPONSE,
            ],
            endpoint: '/project',
            method: 'PUT',
            body: {
                ...data,
            },
            service: API_SERVICE.BFF,
            type: 'json',
        },
    };
}
