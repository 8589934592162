import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import React from 'react';
import { getCurrentTenantData } from 'utils/tenant';
import ChartCard from '../components/ChartCard';
import { getEmptyBannerCommonProps } from '../utils';
import BarChart from './BarChart';
import Pill from './Pill';
import StatBox from './StatBox';

const primaryColor = getCurrentTenantData('primaryColor');
const defaultColor = `hsl(${primaryColor.H},${primaryColor.S}%,${primaryColor.L}%)`;

export default function BarChartCard({
    title,
    topData,
    chartLabels,
    chartData,
    backgroundColor = defaultColor,
    hoverBackgroundColor = null,
    label = 'Count',
    types = [],
    selectedType,
    setSelectedType,
    loading,
    isEmpty = false,
    datasets = null,
    hideOutline = false,
    xAxisLabel = null,
    eventStarted = false,
    chartHeight,
    responsive = true,
    maintainAspectRatio = true,
    airmeetFormat,
    emptyTitle = '',
    emptyContentStyle = {},
    chartOptions = {},
    ...rest
}) {
    return (
        <ChartCard
            title={title}
            loading={loading}
            isEmpty={isEmpty}
            hideOutline={hideOutline}
            emptyBannerProps={getEmptyBannerCommonProps({
                eventStarted,
                airmeetFormat,
                emptyTitle,
                emptyContentStyle,
            })}
            {...rest}
        >
            <FlexDiv
                flexDirection='column'
                alignItems='stretch'
                flexGrow={1}
                style={{ minWidth: 0, overflow: 'hidden' }}
            >
                <FlexDiv>
                    {(topData || []).map((item, index) =>
                        index > 4 ? null : (
                            <StatBox
                                key={item?.key}
                                value={item?.count ?? '-'}
                                title={item?.key}
                            />
                        )
                    )}
                </FlexDiv>
                {xAxisLabel && xAxisLabel !== '' && (
                    <Typography variant='caption' color='ambience.6' pb={2}>
                        {xAxisLabel}
                    </Typography>
                )}
                <FlexDiv>
                    <FlexDiv
                        flexGrow={1}
                        style={chartHeight ? { height: chartHeight } : {}}
                    >
                        <BarChart
                            chartLabels={chartLabels}
                            chartData={chartData}
                            backgroundColor={backgroundColor}
                            hoverBackgroundColor={hoverBackgroundColor}
                            label={label}
                            isEmpty={isEmpty}
                            datasets={datasets}
                            responsive={responsive}
                            maintainAspectRatio={maintainAspectRatio}
                            options={chartOptions || {}}
                        />
                    </FlexDiv>
                </FlexDiv>
                <FlexDiv alignItems='center' justifyContent='center' pt={3}>
                    {(types || []).map((type) => (
                        <Pill
                            title={type}
                            mr={2}
                            selected={selectedType === type}
                            onClick={() => setSelectedType(type)}
                            key={type}
                            isEmpty={isEmpty}
                        />
                    ))}
                </FlexDiv>
            </FlexDiv>
        </ChartCard>
    );
}
