import EventBridge from 'event-bridge';
import ChannelBridge from 'event-bridge/ChannelBridge';
import { DefaultChannelEvents } from 'event-bridge/constants';
import EventEmitter from 'events';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { REACT_APP_API_BASE_URL } from 'utils/constants/airmeet';
import { LOG_LEVEL } from 'utils/constants/logger';
import { WORKER_NAME_INTERVALS } from 'utils/constants/workers';
import { startWorker } from 'workers';
import { EVENT_INTERVAL_RUN } from 'workers/interval-tracker/IntervalTrackerEvents';
import AuthService from '../utils/authService';
import {
    FIREBASE_APP_CONFIG,
    FIREBASE_TOKEN_REFRESH_DURATION,
} from '../utils/constants/firebase';
import { logger } from '../utils/logger';
import FirebaseProxyHelper from './FirebaseProxyHelper';

const firebaseLog = logger.init('liveAirmeet-db', 'green');
const firebaseErrorLog = logger.init('liveAirmeet-db', 'red', LOG_LEVEL.ERROR);

export const FIREBASE_ERROR_CODES = {
    CONNECTION_TIMEOUT: 'CONNECTION_TIMEOUT',
    INVALID_AUTH_USER: 'INVALID_AUTH_USER',
};

export const FIREBASE_ERROR_MESSAGES = {
    UNABLE_TO_LOAD_IN_TIME: 'has loaded not completed in given time',
    UNABLE_TO_FETCH_SYSTEM_TIME_DIFF: 'unable to fetch system time difference',
};

const MAX_RETRIES = 2;
const AUTH_PROXY_ENABLE_TIMEOUT = 10000;

function atomicUpdatePaths(basePath, updates) {
    return Object.entries(updates).reduce((paths, [key, value]) => {
        paths[`${basePath}/${key}`] = value;
        return paths;
    }, {});
}

export const getToken = (userId, airmeet_id) => {
    const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
    };

    if (AuthService.token) {
        headers['X-AccessToken'] = AuthService.token;
    }

    const method = 'POST';
    const fullUrl = `${REACT_APP_API_BASE_URL}/firebase/auth-token`;
    const body = JSON.stringify({
        id: userId,
        airmeet_id,
    });
    const opts = {
        method,
        headers,
        body,
        credentials: headers['X-AccessToken'] ? 'omit' : 'include',
    };
    const convertJson = (response) => {
        if (response.ok && response.status >= 200 && response.status < 300) {
            return response
                .json()
                .then((json) => ({ json, response }))
                .catch((e) => {
                    logger.error(e);

                    return { json: {}, response: null };
                });
        } else if (response.status === 401) {
            throw new Error(
                FIREBASE_ERROR_CODES.INVALID_AUTH_USER,
                response.status
            );
        } else {
            throw new Error('invalid_token_response', response.status);
        }
    };
    return fetch(fullUrl, opts)
        .then(convertJson)
        .then(({ json }) => {
            if (json.token) {
                return json.token;
            } else {
                throw new Error(
                    'Firebase auth token not found in API response'
                );
            }
        });
};

export default class FirebaseClient {
    static events = {
        value: 'value',
    };

    _appName = null;
    _firebaseProxyConfig;
    _dataRefs = {};
    _typeBaseData = {};
    _typeCallbacks = {};
    _emitter = null;
    _dataRefOnValueCallback = {};
    timeDifference = null;
    isTracingEnabled = false;

    get firebaseProxyHelper() {
        const instanceName = this.getInstanceName();
        return this.getFirebaseProxyHelperInstance(instanceName);
    }

    _typeCallback = ({ type, key, value }) => {
        if (this._typeCallbacks[type]) {
            let callbacks = [...this._typeCallbacks[type]];
            for (let callback of callbacks) {
                callback({ key, value });
            }
        }
    };

    getInstanceName() {
        return this._appName + '_' + this.databaseURL;
    }

    constructor(
        appName = 'liveAirmeet',
        appInfo,
        flagInfo = {},
        firebaseProxyConfig
    ) {
        const findApp = firebase.apps.find((app) => app.name === appName);
        this.app =
            findApp || firebase.initializeApp(FIREBASE_APP_CONFIG, appName);

        if (process.env.REACT_APP_REALTIME_LOGGING) {
            firebase.database.enableLogging(
                process.env.REACT_APP_REALTIME_LOGGING === 'local'
            );
        }

        this.user = null;
        this._dataRefs = {};
        this._typeBaseData = {};
        this._emitter = new EventEmitter();

        this.authId = null;
        this.airmeetId = null;
        this.shardType = appInfo.shardType;
        this.databaseURL = appInfo.shardEndpoint;
        this._connectionTimeout = 120 * 1000; // 2 minutes
        this.connectionRetry = 0;
        this.enforceFirebaseWS = flagInfo?.isLongPollingDisabled;
        this.attachedRetryCallback = false;
        this._appName = appName;
        this._firebaseProxyConfig = firebaseProxyConfig;
    }

    getFirebaseProxyHelperInstance(id) {
        return FirebaseProxyHelper.getFirebaseProxyHelperInstance(
            id,
            this._firebaseProxyConfig
        );
    }

    _handleConnectionError = (error) => {
        let skipRetry = error?.skipRetry || false;
        const isEnabledAuthProxy = this.firebaseProxyHelper.getAuthProxyStatus();
        if (
            skipRetry === false &&
            error?.message !== FIREBASE_ERROR_CODES.INVALID_AUTH_USER &&
            this.connectionRetry < MAX_RETRIES
        ) {
            this.connectionRetry++;
            this.log(
                `Calling Token Refresh Retry ${
                    isEnabledAuthProxy ? 'with' : 'without'
                } auth proxy`,
                this.connectionRetry,
                error
            );
            this._tokenRefresh();
            return;
        }

        if (!isEnabledAuthProxy && this.connectionRetry === MAX_RETRIES) {
            this.firebaseProxyHelper.enableFirebaseAuthProxy(this.authService);
            this.connectionRetry = 0;
            this.log(
                'Calling Token Refresh Retry with Firebase proxy',
                this.connectionRetry,
                error
            );
            this._tokenRefresh();
            return;
        }

        if (
            error &&
            ((error.message &&
                [
                    'Failed to authenticate Firebase user',
                    'network error',
                ].includes(error.message)) ||
                (error.code && error.code === 'auth/network-request-failed'))
        ) {
            error = {
                code: FIREBASE_ERROR_CODES.CONNECTION_TIMEOUT,
                message: 'timeout',
            };
        }

        firebaseErrorLog(JSON.stringify(error));

        this._emitter.emit('token-refresh', {
            user: null,
            error,
        });
    };

    async init(authId, airmeetId) {
        this.authId = authId;
        this.airmeetId = airmeetId;

        // Init the Firebase auth service
        this._initAuthService();
        // Init the local token refresh interval tracker
        await this._initTokenTracker();

        // Manuall run the first token setup
        this._tokenRefresh();
        this.log('First token refresh triggered...');

        this._initTimestampOffsetListener();

        this.cancelAuthProxyEnableTimeout = this.startAuthProxyEnableTimeout();

        return new Promise((resolve, reject) => {
            this._emitter.once('token-refresh', ({ user, error }) => {
                if (!error && user) {
                    resolve(user);
                } else {
                    reject(
                        error ||
                            new Error(
                                `Failed to authenticate Firebase user '${authId}' on Airmeet ${airmeetId}`
                            )
                    );
                }
            });
        });
    }

    _initAuthService() {
        const authService = firebase.auth(this.app);

        this.authService = authService;

        // init firebase proxy auth service
        this.firebaseProxyHelper.onCreateAuth(authService);

        // Setup the Firebase auth options
        authService.setPersistence(firebase.auth.Auth.Persistence.SESSION);
        authService.onIdTokenChanged(
            (user) => {
                this.log('Token refresh firebase callback', user);
                // run after login success to clear timeout and proxy timer tasks
                if (this.hasCompletedLogin) {
                    this.afterLoginSuccess('onIdTokenChanged');
                }
                if (user && !this.user) {
                    // first time check for time differnece
                    const cancelErrorEmitTask = this.startErrorEmitTimer(
                        FIREBASE_ERROR_MESSAGES.UNABLE_TO_FETCH_SYSTEM_TIME_DIFF
                    );

                    // User is signed in, or token is refreshed
                    this.getCurrentTimeStampDifference('onIdTokenChanged').then(
                        () => {
                            this.log('CURRENT TIMESTAMP DIFF RESOLVED!');
                            cancelErrorEmitTask();
                            this._emitter.emit('token-refresh', {
                                user,
                                error: null,
                            });
                        }
                    );
                } else if (user) {
                    // still register for new token timer
                    this.log(
                        'Emitting token refresh so timer can register in else case'
                    );
                    this._emitter.emit('token-refresh', {
                        user,
                        error: null,
                    });
                }

                this.user = user;
            },
            (err) => {
                logger.error('Error in Firebase token refresh', err);
                this._emitter.emit('token-refresh', {
                    user: null,
                    error: err,
                });
            }
        );
    }

    startErrorEmitTimer(message) {
        const {
            UNABLE_TO_LOAD_IN_TIME,
            UNABLE_TO_FETCH_SYSTEM_TIME_DIFF,
        } = FIREBASE_ERROR_MESSAGES;

        this.log('Scheduling connection timer - ', message);
        const timeout = setTimeout(() => {
            // if (!this._isAuthenticated) {
            this.log('Firing connection error timer - ', message);

            let skipRetry =
                message === UNABLE_TO_FETCH_SYSTEM_TIME_DIFF ||
                message === UNABLE_TO_LOAD_IN_TIME;

            this._handleConnectionError({
                code: FIREBASE_ERROR_CODES.CONNECTION_TIMEOUT,
                message,
                skipRetry,
            });
            // }
        }, this._connectionTimeout);

        return () => {
            this.log('Clearing connection timer - ', message);
            clearTimeout(timeout);
        };
    }

    async _initTokenTracker() {
        // Setup our event bridge channel, and timeout callback
        const firebaseRefreshBridge = new ChannelBridge(
            'FirebaseClient-token-refresh-timer',
            DefaultChannelEvents,
            {
                callbacks: {
                    [EVENT_INTERVAL_RUN]: () => {
                        this.log('Refreshing token after timer callback');
                        this._tokenRefresh();
                    },
                },
            }
        );
        firebaseRefreshBridge.register(EventBridge);

        // Start the interval tracker
        const timerWorker = await startWorker(WORKER_NAME_INTERVALS);

        // Track any refreshes, and restart the timeout
        this._emitter.on('token-refresh', ({ user, error }) => {
            if (user && !error) {
                this.log(
                    'FirebaseClient refresh token success at',
                    new Date().toISOString()
                );
                timerWorker.registerChannelTimeout(
                    'FirebaseClient-token-refresh-timer',
                    FIREBASE_TOKEN_REFRESH_DURATION * 60 * 1000,
                    false
                );
            } else {
                logger.error(
                    'FirebaseClient refresh token failure at',
                    new Date().toISOString(),
                    error
                );
            }
        });
    }

    startAuthProxyEnableTimeout() {
        this.log('Starting firebase auth proxy enable timeout', {
            delay: AUTH_PROXY_ENABLE_TIMEOUT,
        });
        const authTimeout = setTimeout(() => {
            if (false === this.firebaseProxyHelper.getAuthProxyStatus()) {
                this.log('Running firebase auth proxy enable error handler');
                this.connectionRetry = MAX_RETRIES;
                this._handleConnectionError({
                    skipRetry: true,
                });
            } else {
                this.log(
                    'Firebase auth proxy has already been enabled, not doing anything'
                );
            }
        }, AUTH_PROXY_ENABLE_TIMEOUT);

        return () => {
            this.log('Clearing firebase auth proxy enable timeout');
            clearTimeout(authTimeout);
        };
    }

    /*
    Login process will try 3 attempts before without proxy and then 3 attempts with proxy before issuing error
    Proxy will be enabled after 3 failures or after 10 secs elapsed from first attempts, whichever happens first
    If auth is not complete in 2 mins, it will throw user to error screen
    All the previous attempts before enabling proxy, will silently fail, if the proxy has succeeded
    */
    _tokenRefresh() {
        this.hasCompletedLogin = false;
        console.warn('FirebaseClient refreshing token');
        // Sign in with custom token
        // [START authwithtoken]

        return this.user
            ? this.user.getIdToken(true)
            : getToken(this.authId, this.airmeetId)
                  .then(
                      (token) => {
                          this.log('User token fetched');
                          if (!this.cancelAuthErrorEmitTask) {
                              this.cancelAuthErrorEmitTask = this.startErrorEmitTimer(
                                  'timeout'
                              );
                          }
                          this.authService
                              .signInWithCustomToken(token)
                              .then((user) => {
                                  this.hasCompletedLogin = true;
                                  firebaseLog(
                                      'signed in to firebase as user',
                                      user.user.toJSON()
                                  );
                                  // this.afterLoginSuccess('_tokenRefresh');
                              })
                              .catch((error) => {
                                  if (!this.hasCompletedLogin) {
                                      this.log(
                                          `Error in Firebase client authentication with  ${this.connectionRetry} login attempts, error:`,
                                          error
                                      );
                                      console.error(
                                          'Error in Firebase client authentication',
                                          error
                                      );
                                      this._handleConnectionError(error);
                                  } else {
                                      this.log(
                                          'firebase login task already finished, not doing anything'
                                      );
                                  }
                              });
                      }

                      // [END authwithtoken]
                  )
                  .catch((error) => {
                      // Handle Errors here.
                      const { code, message } = error;
                      // [START_EXCLUDE]
                      if (code === 'auth/invalid-custom-token') {
                          console.log(message);
                          logger.error('The token you provided is not valid.');
                      } else {
                          logger.error(error);
                      }
                      this._handleConnectionError(error);
                      // [END_EXCLUDE]
                  });
    }

    afterLoginSuccess(context) {
        this.log('User login sucesss', { context: context });
        //this.hasCompletedLogin = true;
        this.cancelAuthErrorEmitTask && this.cancelAuthErrorEmitTask();
        this.cancelAuthProxyEnableTimeout &&
            this.cancelAuthProxyEnableTimeout();
    }

    _initTimestampOffsetListener() {
        const ref = this.ref('.info/serverTimeOffset');
        ref.on('value', (snapshot) => {
            let timeDifference = snapshot.val();
            if (timeDifference !== this.timeDifference) {
                this._onLoadSystemTimeDifference(timeDifference);
            }
        });
    }

    _onLoadSystemTimeDifference(timeDifference) {
        this.timeDifference = timeDifference;
        this.log('Info node: Loaded timestamp difference', {
            diff: this.timeDifference,
        });
        if (null === this.timeDifference) {
            logger.error(
                'Info node: Timestamp difference value received as null'
            );
            this.timeDifference = 0;
        }
        this._emitter.emit('server-time-diff-change', this.timeDifference);
    }

    async getCurrentTimeStampDifference(context) {
        if (null !== this.timeDifference) {
            return Promise.resolve(this.timeDifference);
        }

        logger.debug('Reading timestamp difference', { context: context });
        return new Promise((resolve) => {
            this.getEmitter().once('server-time-diff-change', (...args) => {
                logger.debug(
                    'Resolved timestamp diff from internal event',
                    ...args
                );
                resolve(...args);
            });
        });
    }

    async getCurrentTimeStamp() {
        let timeDiff = await this.getCurrentTimeStampDifference();
        return Date.now() + timeDiff;
    }

    async getRemainingTimeInSec(value) {
        const currentTimeStamp = await this.getCurrentTimeStamp();
        return Math.ceil((value - currentTimeStamp) / 1000);
    }

    cleanup() {
        if (!this.database) {
            return;
        }

        this.database.goOffline();
    }

    reload() {
        if (!this.database) {
            return;
        }

        this.unload();
        this.database.goOnline();
    }

    getEmitter() {
        return this._emitter;
    }

    get database() {
        if (!this.authId || !this.databaseURL) {
            this.log(
                'Error in loading database, details not loaded',
                this.authId,
                this.databaseURL
            );
        }
        this._database = this.authId
            ? this.databaseURL
                ? this.app.database(this.databaseURL)
                : firebase.database(this.app)
            : null;
        if (this._database?.repo_ && this.enforceFirebaseWS) {
            this._database.repo_.repoInfo_webSocketOnly = true;
        }

        if (this._database && this.attachedRetryCallback === false) {
            this.attachedRetryCallback = true;
            this.firebaseProxyHelper.onCreateDatabase(this._database);
        }

        return this._database;
    }

    getServerTimestampRef() {
        return firebase.database.ServerValue.TIMESTAMP;
    }

    /* This is added to keep an eye on the references we are creating in the system */
    attachProxy() {
        if (!this.attachedProxy) {
            this.attachedProxy = true;
            let original = this.database.ref;
            this.database.ref = (...args) => {
                if ('dev' === process.env.REACT_APP_ENV) {
                    this.log('Created database ref', args[0]);
                }
                return original.call(this.database, ...args);
            };
        }
    }

    // TODO: Add a "paths" file which allows access to various paths accessed by the app
    ref(type, options = {}) {
        this.attachProxy();
        let ref = this.database.ref(`${type}`);
        logger.debug('Server time offset ref - ', ref);
        const {
            orderByKey,
            orderByChild,
            orderByValue,
            limitFirst,
            limitLast,
        } = options || {};

        // Handle ordering
        ref = orderByKey
            ? ref.orderByKey()
            : orderByChild
            ? ref.orderByChild(orderByChild)
            : orderByValue
            ? ref.orderByValue()
            : ref;

        // Handle limits
        ref = limitFirst
            ? ref.limitToFirst(limitFirst)
            : limitLast
            ? ref.limitToLast(limitLast)
            : ref;

        return ref;
    }

    getDataRef(type, options = { listeners: true }) {
        this.attachProxy();
        if (this._dataRefs[type]) {
            return this._dataRefs[type];
        }
        const dataRef = this.ref(type, options);

        if (options.listeners === false) {
            return dataRef;
        }
        this._dataRefs[type] = dataRef;
        this._dataRefOnValueCallback[type] = (snapshot) => {
            let data = null;
            if (
                options.orderByKey ||
                options.orderByChild ||
                options.orderByValue
            ) {
                data = [];

                // We need to add child one by one for save in same order in which data recived
                snapshot.forEach((child) => {
                    data.push({
                        key: child.key,
                        value: child.val(),
                    });
                });
            } else {
                data = snapshot.val();
            }

            this.traceLog(
                `new update received: ${type}`,
                data ? '' : `data - ${data}`
            );

            this._typeBaseData[type] = data;

            this._emitter.emit('value', {
                type,
                key: snapshot.key,
                value: data,
            });
            this._emitter.emit(`${type}_value`, {
                type,
                key: snapshot.key,
                value: data,
            });
        };
        dataRef.on('value', this._dataRefOnValueCallback[type]);
        return dataRef;
    }

    async getValue(type) {
        if (!this.hasAlreadyLoadedDataRef(type)) {
            this.getDataRef(type);
            await new Promise((res) => this._emitter.on(`${type}_value`, res));
        }

        return this.getLoadedValues(type);
    }

    getLoadedValues(type) {
        if (typeof this._typeBaseData[type] === 'undefined') {
            return;
        }
        return this._typeBaseData[type];
    }

    hasAlreadyLoadedDataRef(type) {
        return !!this._dataRefs[type];
    }

    getDataOnce(type, callback) {
        if (this.hasAlreadyLoadedDataRef(type)) {
            callback({ value: this.getLoadedValues(type) });
        } else {
            this.getDataRef(type);
            this._emitter.once(`${type}_value`, callback);
        }
    }

    getDataSync(type, callback, options = { listeners: true, reload: false }) {
        this.traceLog(`Added sync for - ${type}`);
        if (options.reload && options.listeners) {
            logger.info(`Req for reload the ref of ${type}`, options);
            // Cleanup the previous ref from firebase and de-attach the event
            this.offEvents(type);
        }

        if (!this._typeCallbacks[type]) {
            this._typeCallbacks[type] = [];
            this._emitter.on(`${type}_value`, this._typeCallback);
        }

        this._typeCallbacks[type].push(callback);

        if (this.hasAlreadyLoadedDataRef(type)) {
            callback({ value: this.getLoadedValues(type) });
        } else {
            this.getDataRef(type, options);
        }
    }

    clearDataSync(type, callback) {
        if (this._typeCallbacks[type]) {
            let indexOfCallback = this._typeCallbacks[type].indexOf(callback);
            if (indexOfCallback >= 0) {
                this._typeCallbacks[type].splice(indexOfCallback, 1);
                this.traceLog(`removed firebase listener for - ${type}`);
            }

            if (this._typeCallbacks[type].length <= 0) {
                this._emitter.off(`${type}_value`, this._typeCallback);
                this.unload(type);
                if (typeof this._typeBaseData[type] !== 'undefined') {
                    delete this._typeBaseData[type];
                }
            }
        }
    }

    setDataAsync(
        type,
        data,
        { unbind = false, delay = 1, showError = true } = {}
    ) {
        return new Promise(async (resolve, reject) => {
            this.getDataRef(type, { listeners: false });

            const work = () => {
                const itemRef = this.setData(
                    type,
                    data,
                    unbind,
                    showError,
                    (error) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve(itemRef);
                        }
                    }
                );
            };

            setTimeout(() => {
                work();
            }, delay || 1);
        });
    }

    // Will be deprecated soon in favour of above promise approach
    setData(type, data, unbind = false, showError = true, callback) {
        let ref = this.getDataRef(type, { listeners: false });
        const itemRef = ref.set(data, (error) => {
            if (error) {
                if (showError) {
                    logger.error(`Failed to set ${ref.path}`, error, {
                        message: error.message,
                    });
                } else {
                    this.log(`Failed to set ${ref.path}`, error, {
                        message: error.message,
                    });
                }
            } else {
                logger.debug(`Set values to ${ref.path}`);
            }
            callback && callback(error);
        });

        if (unbind) {
            this.offEvents(type);
        }

        return itemRef;
    }

    updateData(type, data, unbind = false) {
        let ref = this.getDataRef(type, { listeners: false });
        const itemRef = ref.update(data, (error) => {
            if (error) {
                logger.error(`Failed to update ${ref.path}`, error, {
                    message: error.message,
                });
            }
        });
        if (unbind) {
            this.offEvents(type);
        }

        return itemRef;
    }

    atomicUpdate(data) {
        return this.database.ref().update(data);
    }

    async atomicUpdateAsync(data) {
        return this.atomicUpdate(data);
    }

    async atomicUpdateFromPayloadPaths(basePath, payload) {
        const data = atomicUpdatePaths(basePath, payload);
        return this.atomicUpdateAsync(data);
    }

    pushData(type, data) {
        let ref = this.getDataRef(type, { listeners: false });
        return ref.push(data, (error) => {
            if (error) {
                logger.error(`Failed to push ${ref.path}`, error, {
                    message: error.message,
                });
            }
        });
    }

    replaceOnDisconnect(type, data, onComplete) {
        const ref =
            typeof type === 'string'
                ? this.getDataRef(type, { listeners: false })
                : type;
        ref.onDisconnect().cancel();
        this.setOnDisconnect(type, data, onComplete);
    }

    setOnDisconnect(type, data, onComplete = null) {
        const ref =
            typeof type === 'string'
                ? this.getDataRef(type, { listeners: false })
                : type;

        ref.onDisconnect().set(data, (error) => {
            if (onComplete !== null) {
                onComplete(error);
            }
            if (error) {
                logger.error(`Failed to set ${ref.path}`, error, {
                    message: error.message,
                });
            } else {
                this.log(`Set on disconnect ${ref.path}`);
            }
        });
    }

    removeOnDisconnect(type, onComplete) {
        // The incoming type could be a ref, or a database path
        // Passing the ref is useful to remove the exact element created using `pushData`
        const ref =
            typeof type === 'string'
                ? this.getDataRef(type, { listeners: false })
                : type;

        const onRemove = (error) => {
            if (onComplete) {
                onComplete(error);
            }
            this.log(`Removed on disconnect ${ref.path}`);
        };
        ref.onDisconnect().remove(onRemove);
    }

    cancelOnDisconnect(type) {
        const ref =
            typeof type === 'string'
                ? this.getDataRef(type, { listeners: false })
                : type;

        ref.onDisconnect().cancel();
        this.log(`Cancelled on disconnect ${ref.path}`);
    }

    offEvents(type, unbindRefListener = true) {
        if (unbindRefListener) {
            const ref = this.getDataRef(type, { listeners: false });
            ref.off();
        }

        this.log(
            `In firebase client offEvent, detach all listeners from ${type}`
        );

        if (this._dataRefOnValueCallback[type]) {
            this._dataRefs[type] &&
                this._dataRefs[type].off(
                    'value',
                    this._dataRefOnValueCallback[type]
                );
            this.log(
                `In firebase client unload, detach 'value' event listener from ${type}`
            );
            delete this._dataRefOnValueCallback[type];
        }
        if (this._dataRefs[type]) {
            delete this._dataRefs[type];
        }
    }

    unload(type) {
        this.offEvents(type, false);

        if (this._typeCallbacks[type]) {
            delete this._typeCallbacks[type];
        }
    }

    queryData(type, key, value, onResult = (err, results) => {}) {
        return new Promise((resolve, reject) => {
            let dataRef = this.database.ref(`${type}`);
            if (key) {
                dataRef = dataRef.orderByChild(key);
                if (value !== null) {
                    dataRef = dataRef.equalTo(value);
                }
            }
            dataRef.once('value', (snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const resultArr =
                        typeof data === 'object'
                            ? Object.keys(data).map((key) => ({
                                  ...data[key],
                                  _nodeId: key,
                              }))
                            : data;
                    onResult(false, resultArr);
                    resolve(resultArr);
                } else {
                    onResult(true, null);
                    resolve(null);
                }
            });
        });
    }

    deleteNode(type) {
        const ref = this.getDataRef(type, { listeners: false });
        ref.remove();
    }

    runTransaction(type, updateFun, successCallback, notifyOnce) {
        let ref = this.getDataRef(type, { listeners: false });
        return ref.transaction(
            updateFun,
            (error, committed) => {
                if (error) {
                    logger.error(
                        `Failed to run transaction ${ref.path}`,
                        error,
                        {
                            message: error.message,
                        }
                    );
                }
                if (successCallback) return successCallback(error, committed);
            },
            !notifyOnce
        );
    }

    getIncrementRef(value) {
        return firebase.database.ServerValue.increment(value);
    }

    log(message, ...args) {
        logger.info(`FirebaseClient - ${message}`, ...args);
    }

    traceLog(message, data) {
        if (!this.isTracingEnabled) return;
        this.log(message, data);
    }

    setTraceLoggerEnabled(value) {
        this.isTracingEnabled = value;
    }
}
