import createReducer from '../../utils/createReducer';
import { SEND_BROWSER_NOTIFICATION } from '../actions/BrowserNotification';

const initialState = {
    title: '',
    tags: '',
    body: '',
    icon: `${window.location.origin}/assets/BrowserNotification/notifications.png`,
};

const handlers = {};

handlers[SEND_BROWSER_NOTIFICATION] = (state = initialState, action) => {
    const { title, body, sound, duplicates } = action.payload;
    return {
        ...state,
        title,
        body,
        sound,
        ...(duplicates && {
            tag: `${Date.now()}${Math.ceil(Math.random() * 10000)}`,
        }),
    };
};

export default createReducer(initialState, handlers);
