import createReducer from '../../utils/createReducer';
import {
    ACCEPT_HAND_RAISE_REQUEST,
    ACTIVE_HAND_RAISE_REQUEST,
    COMPLETED_HAND_RAISE_REQUEST,
    FETCH_HAND_RAISE_REQUESTED_LIST_RESPONSE,
    GET_HAND_RAISE_REQUEST,
    HAND_RAISE_REQUESTED_LIST_ON_LOAD,
    HAND_RAISE_REQUEST_STATUS,
    REJECT_HAND_RAISE_REQUEST,
    SET_FIREBASE_PRIMARY_SHARDS_FOR_RAISE_HAND,
    UPDATE_RAISE_HAND_REQUEST_COUNTER,
    WITHDRAW_HAND_RAISE_REQUEST,
} from '../actions/handRaise';

const handlers = {};
const initialState = {
    requestStatusCount: {},
    connectViaFirebasePrimaryShards: null,
    requestedList: {},
};

handlers[HAND_RAISE_REQUESTED_LIST_ON_LOAD] = (
    state = initialState,
    action
) => {
    const { sessions } = action.payload;
    return { ...state, requestedList: { ...sessions } };
};

handlers[FETCH_HAND_RAISE_REQUESTED_LIST_RESPONSE] = (
    state = initialState,
    action
) => {
    return { ...state };
};

const updateRequestStatus = (requests, requestId, status) =>
    requests.map((row) => {
        if (row.requestId !== requestId) {
            return row;
        }
        return { ...row, status };
    });

handlers[GET_HAND_RAISE_REQUEST] = (state = initialState, action) => {
    const { sessionId, user, requestId } = action.payload;
    let { requestedList } = state;
    if (!requestedList[sessionId]) {
        requestedList[sessionId] = [];
    }
    // Check for redundant
    requestedList[sessionId] = requestedList[sessionId].filter((row) => {
        return (
            row.requestId !== requestId ||
            row.status !== HAND_RAISE_REQUEST_STATUS.pending ||
            row.user.id !== user.id
        );
    });
    requestedList[sessionId].push({
        user,
        requestId,
        status: HAND_RAISE_REQUEST_STATUS.pending,
    });
    return { ...state, requestedList: { ...requestedList } };
};

handlers[WITHDRAW_HAND_RAISE_REQUEST] = (state = initialState, action) => {
    const { sessionId, requestId } = action.payload;
    let { requestedList } = state;
    if (!requestedList[sessionId]) {
        requestedList[sessionId] = [];
    }
    requestedList[sessionId] = updateRequestStatus(
        requestedList[sessionId],
        requestId,
        HAND_RAISE_REQUEST_STATUS.withdraw
    );
    return { ...state, requestedList: { ...requestedList } };
};

handlers[ACCEPT_HAND_RAISE_REQUEST] = (state = initialState, action) => {
    const { sessionId, requestId, user } = action.payload;
    let { requestedList } = state;
    if (!requestedList[sessionId]) {
        requestedList[sessionId] = [];
    }

    requestedList[sessionId] = updateRequestStatus(
        requestedList[sessionId],
        requestId,
        HAND_RAISE_REQUEST_STATUS.accepted
    );
    return { ...state, requestedList: { ...requestedList } };
};

handlers[ACTIVE_HAND_RAISE_REQUEST] = (state = initialState, action) => {
    const { sessionId, requestId, user } = action.payload;
    let { requestedList } = state;
    if (!requestedList[sessionId]) {
        requestedList[sessionId] = [];
    }
    requestedList[sessionId] = updateRequestStatus(
        requestedList[sessionId],
        requestId,
        HAND_RAISE_REQUEST_STATUS.active
    );
    return { ...state, requestedList: { ...requestedList } };
};

handlers[REJECT_HAND_RAISE_REQUEST] = (state = initialState, action) => {
    const { sessionId, requestId } = action.payload;
    let { requestedList } = state;
    if (!requestedList[sessionId]) {
        requestedList[sessionId] = [];
    }
    requestedList[sessionId] = updateRequestStatus(
        requestedList[sessionId],
        requestId,
        HAND_RAISE_REQUEST_STATUS.declined
    );
    return { ...state, requestedList: { ...requestedList } };
};

handlers[COMPLETED_HAND_RAISE_REQUEST] = (state = initialState, action) => {
    const { sessionId, requestId } = action.payload;
    let { requestedList } = state;
    if (!requestedList[sessionId]) {
        requestedList[sessionId] = [];
    }
    requestedList[sessionId] = updateRequestStatus(
        requestedList[sessionId],
        requestId,
        HAND_RAISE_REQUEST_STATUS.completed
    );
    return { ...state, requestedList: { ...requestedList } };
};

handlers[UPDATE_RAISE_HAND_REQUEST_COUNTER] = (
    state = initialState,
    action
) => {
    const { sessionId, counter } = action.payload;
    const { requestStatusCount } = state;
    requestStatusCount[sessionId] = counter;
    return { ...state, requestStatusCount: { ...requestStatusCount } };
};
handlers[SET_FIREBASE_PRIMARY_SHARDS_FOR_RAISE_HAND] = (
    state = initialState,
    action
) => {
    const { connectViaFirebasePrimaryShards } = action.payload;
    if (
        state.connectViaFirebasePrimaryShards ===
        connectViaFirebasePrimaryShards
    ) {
        return state;
    }
    return {
        ...state,
        connectViaFirebasePrimaryShards: connectViaFirebasePrimaryShards,
    };
};

export default createReducer(initialState, handlers);
