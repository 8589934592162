import { SET_BLOCK_USER_MODAL_VISIBILITY } from 'store/actions/blockUserModal';

const initialState = {};

function blockUserModal(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case SET_BLOCK_USER_MODAL_VISIBILITY:
            return { ...state, ...payload };
        default:
            return state;
    }
}

export default blockUserModal;
