import useIsNonInvitedSpeaker from 'hooks/sessions/useIsNonInvitedSpeaker';
import useAuthActionHandler from 'hooks/useAuthActionHandler';
import useDeviceInfo from 'hooks/useDeviceInfo';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { showFullProfile } from 'store/actions/profile';
import styled from 'styled-components/macro';
import { accessibilityOnKeyPress } from 'utils/accessibilityOnKeyPress';
import { noop } from 'utils/constants/common';
import FirebaseLogger from 'utils/firebaseLogger';

const EditUserButton = ({ children, className, backdropClassName }) => {
    const dispatch = useDispatch();
    const {
        isLoggedIn,
        isDirectUser,
        isAccessCodeUser,
    } = useAuthActionHandler();
    const {
        airmeet: { data },
        user: { id: userId },
    } = useLiveAirmeetContext();
    const { isDesktopMode, isAndroid, isIOS } = useDeviceInfo();
    const deviceSource = isDesktopMode
        ? 'Web'
        : (isAndroid && 'Android') || (isIOS && 'iOS');

    const { currentAirmeet, userRole } = data;

    const isSpeaker = useIsNonInvitedSpeaker();

    const airmeetId = currentAirmeet.airmeetId;
    const allowToEdit = useMemo(
        () =>
            (!isLoggedIn && isDirectUser) ||
            (isLoggedIn && !isAccessCodeUser) ||
            isSpeaker,
        [isLoggedIn, isDirectUser, isAccessCodeUser, isSpeaker]
    );

    const showEditUserModal = () => {
        if (!allowToEdit) {
            return;
        }
        dispatch(showFullProfile(userId));
        FirebaseLogger.updateUserProfileClicked({
            airmeetId,
            communityId: currentAirmeet.community_id,
            userId,
            userRole,
            deviceInfo: deviceSource,
        });
    };

    const handleKeyPressEdit = (event) => {
        if (accessibilityOnKeyPress(event) && allowToEdit) {
            showEditUserModal();
        }
    };

    return (
        <EditUserButtonWapper
            allowToEdit={allowToEdit}
            onClick={allowToEdit ? showEditUserModal : noop}
            onKeyPress={handleKeyPressEdit}
            role='button'
            tabIndex={0}
            aria-label='Edit User'
        >
            {children}
        </EditUserButtonWapper>
    );
};

export default EditUserButton;

const EditUserButtonWapper = styled.div`
    ${({ allowToEdit }) =>
        allowToEdit &&
        `
        cursor: pointer;
        .profile._thumb_only {
            cursor: pointer !important;
        }
    `}
    &:focus-visible {
        outline: ${({ theme }) =>
            theme.isLightTheme ? '1px solid black' : '1px solid white'};
    }
`;
