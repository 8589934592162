import createReducer from 'utils/createReducer';
import {
    SET_ACTIVE_BOOTH_SPACE,
    SET_ACTIVE_LOUNGE,
} from '../actions/MainNavigation';

const initialState = {
    activeLounge: {},
    activeBoothSpace: {},
};

const handlers = {};

handlers[SET_ACTIVE_LOUNGE] = (state = initialState, { payload }) => {
    return { ...state, activeLounge: payload.activeLounge };
};
handlers[SET_ACTIVE_BOOTH_SPACE] = (state = initialState, { payload }) => {
    return { ...state, activeBoothSpace: payload.activeBoothSpace };
};

export default createReducer(initialState, handlers);
