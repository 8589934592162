import Button from 'atoms/Button/button';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import RcSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from 'react';
import styled from 'styled-components';
import { emptyObject, noop } from 'utils/constants/common';

export default function ThemeSlider({
    value,
    onChange,
    min = 0,
    max = 1,
    step = 0.01,
    wrapperStyles = emptyObject,
    disabled = false,
    iconSize = '24px',
    iconFill = null,
    iconLeft = 'ic-subtract-circle-o',
    iconRight = 'ic-add-circle-o',
}) {
    const clickStep = (max - min) / 10;

    return (
        <FlexDiv alignItems='center' pb='24px' style={wrapperStyles}>
            <Button
                variant='link'
                onClick={() =>
                    onChange &&
                    !disabled &&
                    onChange(value - clickStep >= min ? value - clickStep : min)
                }
            >
                <IconButton
                    icon={iconLeft}
                    height={iconSize}
                    width={iconSize}
                    {...(iconFill
                        ? {
                              fill: iconFill,
                          }
                        : {})}
                />
            </Button>
            <StyledSlider
                min={min}
                max={max}
                step={step}
                onChange={disabled ? noop : onChange}
                value={value}
            />
            <Button
                variant='link'
                onClick={() =>
                    onChange &&
                    !disabled &&
                    onChange(value + clickStep <= max ? value + clickStep : max)
                }
            >
                <IconButton
                    icon={iconRight}
                    height={iconSize}
                    width={iconSize}
                    {...(iconFill
                        ? {
                              fill: iconFill,
                          }
                        : {})}
                />
            </Button>
        </FlexDiv>
    );
}

const StyledSlider = styled(RcSlider)`
    margin-left: 20px;
    margin-right: 20px;
    .rc-slider-rail {
        background-color: ${({ theme }) =>
            theme.colors.ambience[theme?.isLightTheme ? 20 : 6]};
    }
    .rc-slider-handle {
        border: 1px solid ${({ theme }) => theme.colors.ambience[6]};
        background-color: ${({ theme }) =>
            theme.colors.ambience[theme?.isLightTheme ? 20 : 0]};
        width: 24px;
        height: 24px;
        box-shadow: none;
        outline: 0;
        margin-top: -10px;
        &:hover {
            border: 1px solid ${({ theme }) => theme.colors.accentPrimary[0]};
        }
    }
    .rc-slider-track {
        background-color: ${({ theme }) => theme.colors.accentPrimary[0]};
    }
`;

const IconButton = styled(StyledSvgSprite)`
    cursor: pointer;
`;
