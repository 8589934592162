import InfoBanner from 'components/molecules/infoBanner/infoBanner';
import { alpha } from 'foundations/theme';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const CustomInfoBanner = styled(InfoBanner)`
    background: ${(props) => alpha(props.theme.colors.ambience[24], 0.8)};
`;

const ErrorBanner = ({
    error,
    clearIncident,
    hideDelay,
    onConfirm,
    resolutionMessage,
    message,
    permanent,
}) => {
    const [displayed, setDisplayed] = useState(false);

    const hideTimer = useRef(null);

    useEffect(() => {
        if (hideTimer.current) {
            clearTimeout(hideTimer.current);
            hideTimer.current = null;
        }
        if (error) {
            setDisplayed(true);
            if (!permanent) {
                hideTimer.current = setTimeout(() => {
                    setDisplayed(false);
                    clearIncident();
                }, hideDelay);
            }
        } else {
            setDisplayed(false);
        }
    }, [error, hideDelay, clearIncident, permanent]);

    useEffect(
        () => () => {
            if (hideTimer.current) clearTimeout(hideTimer.current);
        },
        []
    );

    return (
        displayed && (
            <CustomInfoBanner
                contentColor={'ambience.0'}
                linkColor={'accentPrimary.1'}
                isUIBanner={true}
                style={{ zIndex: 100 }}
                onCancelBanner={clearIncident}
                content={message}
                linkContent={resolutionMessage}
                onClickLink={onConfirm}
            />
        )
    );
};

export default ErrorBanner;
