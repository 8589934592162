import { CALL_API } from 'store/api';
import { API_SERVICE } from 'utils/apiService';

export const API_REQUEST_MAP = {
    POLLS: 'POLLS',
    SESSIONS: 'SESSIONS',
    CTAS: 'CTAS',
    RESOURCES: 'RESOURCES',
    QUESTIONS: 'QUESTIONS',
    OVERVIEW: 'OVERVIEW',
    EVENTS_ATTENDED: 'EVENTS_ATTENDED',
    PROFILE_DETAILS: 'PROFILE_DETAILS',
    INTERESTS: 'INTERESTS',
};

export const GET_PROFILE_POLLS_DATA_REQUEST = 'GET_PROFILE_POLLS_DATA_REQUEST';
export const GET_PROFILE_POLLS_DATA_RESPONSE =
    'GET_PROFILE_POLLS_DATA_RESPONSE';
export const GET_PROFILE_SESSIONS_DATA_REQUEST =
    'GET_PROFILE_SESSIONS_DATA_REQUEST';
export const GET_PROFILE_SESSIONS_DATA_RESPONSE =
    'GET_PROFILE_SESSIONS_DATA_RESPONSE';
export const GET_PROFILE_CTAS_DATA_REQUEST = 'GET_PROFILE_CTAS_DATA_REQUEST';
export const GET_PROFILE_CTAS_DATA_RESPONSE = 'GET_PROFILE_CTAS_DATA_RESPONSE';
export const GET_PROFILE_RESOURCES_DATA_REQUEST =
    'GET_PROFILE_RESOURCES_DATA_REQUEST';
export const GET_PROFILE_RESOURCES_DATA_RESPONSE =
    'GET_PROFILE_RESOURCES_DATA_RESPONSE';
export const GET_PROFILE_QUESTIONS_DATA_REQUEST =
    'GET_PROFILE_QUESTIONS_DATA_REQUEST';
export const GET_PROFILE_QUESTIONS_DATA_RESPONSE =
    'GET_PROFILE_QUESTIONS_DATA_RESPONSE';
export const GET_PROFILE_OVERVIEW_DATA_REQUEST =
    'GET_PROFILE_OVERVIEW_DATA_REQUEST';
export const GET_PROFILE_OVERVIEW_DATA_RESPONSE =
    'GET_PROFILE_OVERVIEW_DATA_RESPONSE';
export const GET_EVENTS_ATTENDED_DATA_REQUEST =
    'GET_EVENTS_ATTENDED_DATA_REQUEST';
export const GET_EVENTS_ATTENDED_DATA_RESPONSE =
    'GET_EVENTS_ATTENDED_DATA_RESPONSE';
export const GET_PROFILE_DETAILS_DATA_REQUEST =
    'GET_PROFILE_DETAILS_DATA_REQUEST';
export const GET_PROFILE_DETAILS_DATA_RESPONSE =
    'GET_PROFILE_DETAILS_DATA_RESPONSE';
export const GET_PROFILE_INTERESTS_DATA_REQUEST =
    'GET_PROFILE_INTERESTS_DATA_REQUEST';
export const GET_PROFILE_INTERESTS_DATA_RESPONSE =
    'GET_PROFILE_INTERESTS_DATA_RESPONSE';

export const getProfilePollsData = ({
    communityId,
    userId,
    offset = 0,
    pageSize = 20,
}) => {
    return {
        [CALL_API]: {
            types: [
                GET_PROFILE_POLLS_DATA_REQUEST,
                GET_PROFILE_POLLS_DATA_RESPONSE,
            ],
            endpoint: `/unified/community/${communityId}/polls`,
            method: 'POST',
            body: {
                uid: userId,
                offset: offset,
                size: pageSize,
            },
            type: 'json',
            service: API_SERVICE.ANALYTICS,
        },
    };
};

export const getProfileSessionsData = ({
    communityId,
    userId,
    offset = 0,
    pageSize = 20,
}) => {
    return {
        [CALL_API]: {
            types: [
                GET_PROFILE_SESSIONS_DATA_REQUEST,
                GET_PROFILE_SESSIONS_DATA_RESPONSE,
            ],
            endpoint: `/unified/community/${communityId}/sessions`,
            method: 'POST',
            body: {
                uid: userId,
                offset: offset,
                size: pageSize,
            },
            type: 'json',
            service: API_SERVICE.ANALYTICS,
        },
    };
};

export const getProfileCtaData = ({
    communityId,
    userId,
    offset = 0,
    pageSize = 20,
}) => {
    return {
        [CALL_API]: {
            types: [
                GET_PROFILE_CTAS_DATA_REQUEST,
                GET_PROFILE_CTAS_DATA_RESPONSE,
            ],
            endpoint: `/unified/community/${communityId}/ctas`,
            method: 'POST',
            body: {
                uid: userId,
                offset: offset,
                size: pageSize,
            },
            type: 'json',
            service: API_SERVICE.ANALYTICS,
        },
    };
};

export const getProfileResourcesData = ({
    communityId,
    userId,
    offset = 0,
    pageSize = 20,
}) => {
    return {
        [CALL_API]: {
            types: [
                GET_PROFILE_RESOURCES_DATA_REQUEST,
                GET_PROFILE_RESOURCES_DATA_RESPONSE,
            ],
            endpoint: `/unified/community/${communityId}/resources`,
            method: 'POST',
            body: {
                uid: userId,
                offset: offset,
                size: pageSize,
            },
            type: 'json',
            service: API_SERVICE.ANALYTICS,
        },
    };
};

export const getProfileQuestionsData = ({
    communityId,
    userId,
    offset = 0,
    pageSize = 20,
}) => {
    return {
        [CALL_API]: {
            types: [
                GET_PROFILE_QUESTIONS_DATA_REQUEST,
                GET_PROFILE_QUESTIONS_DATA_RESPONSE,
            ],
            endpoint: `/unified/community/${communityId}/questions`,
            method: 'POST',
            body: {
                uid: userId,
                offset: offset,
                size: pageSize,
            },
            type: 'json',
            service: API_SERVICE.ANALYTICS,
        },
    };
};

export const getProfileOverviewData = ({
    communityId,
    userId,
    offset = 0,
    pageSize = 20,
}) => {
    return {
        [CALL_API]: {
            types: [
                GET_PROFILE_OVERVIEW_DATA_REQUEST,
                GET_PROFILE_OVERVIEW_DATA_RESPONSE,
            ],
            endpoint: `/unified/community/${communityId}/overview`,
            method: 'POST',
            body: {
                uid: userId,
                offset: offset,
                size: pageSize,
            },
            type: 'json',
            service: API_SERVICE.ANALYTICS,
        },
    };
};

export const getEventsAttendedData = ({
    communityId,
    userId,
    offset = 0,
    pageSize = 20,
}) => {
    return {
        [CALL_API]: {
            types: [
                GET_EVENTS_ATTENDED_DATA_REQUEST,
                GET_EVENTS_ATTENDED_DATA_RESPONSE,
            ],
            endpoint: `/unified/community/${communityId}/events`,
            method: 'POST',
            body: {
                uid: userId,
                offset: offset,
                size: pageSize,
            },
            type: 'json',
            service: API_SERVICE.ANALYTICS,
        },
    };
};

export const getProfileDetailsData = ({
    communityId,
    userId,
    offset = 0,
    pageSize = 20,
}) => {
    return {
        [CALL_API]: {
            types: [
                GET_PROFILE_DETAILS_DATA_REQUEST,
                GET_PROFILE_DETAILS_DATA_RESPONSE,
            ],
            endpoint: `/unified/community/${communityId}/profile`,
            method: 'POST',
            body: {
                uid: userId,
                offset: offset,
                size: pageSize,
            },
            type: 'json',
            service: API_SERVICE.ANALYTICS,
        },
    };
};

export const getProfileUid = ({ communityId, email }) => {
    return {
        [CALL_API]: {
            types: [
                GET_PROFILE_DETAILS_DATA_REQUEST,
                GET_PROFILE_DETAILS_DATA_RESPONSE,
            ],
            endpoint: `/unified/community/${communityId}/get-uuid`,
            method: 'POST',
            type: 'json',
            body: {
                email,
            },
            service: API_SERVICE.ANALYTICS,
        },
    };
};

export const getProfileInterestsData = ({ communityId, userId }) => {
    return {
        [CALL_API]: {
            types: [
                GET_PROFILE_INTERESTS_DATA_REQUEST,
                GET_PROFILE_INTERESTS_DATA_RESPONSE,
            ],
            endpoint: `/unified/community/${communityId}/get-interests`,
            method: 'POST',
            body: {
                uid: userId,
                offset: 0,
                size: 20,
            },
            type: 'json',
            service: API_SERVICE.ANALYTICS,
        },
    };
};

const apiHandler = {
    [API_REQUEST_MAP.POLLS]: getProfilePollsData,
    [API_REQUEST_MAP.SESSIONS]: getProfileSessionsData,
    [API_REQUEST_MAP.CTAS]: getProfileCtaData,
    [API_REQUEST_MAP.RESOURCES]: getProfileResourcesData,
    [API_REQUEST_MAP.QUESTIONS]: getProfileQuestionsData,
    [API_REQUEST_MAP.OVERVIEW]: getProfileOverviewData,
    [API_REQUEST_MAP.EVENTS_ATTENDED]: getEventsAttendedData,
    [API_REQUEST_MAP.PROFILE_DETAILS]: getProfileDetailsData,
    [API_REQUEST_MAP.INTERESTS]: getProfileInterestsData,
};

export default apiHandler;
