import * as ScrollArea from '@radix-ui/react-scroll-area';
import React from 'react';
import styled from 'styled-components';

interface ScrollProps {
    type?: string;
    scrollWidth?: string;
    orientation?: string;
}

const ScrollSection: React.FC<ScrollProps> = ({
    type = 'auto',
    scrollWidth = '8px',
    orientation = 'vertical',
    children,
}) => (
    <ScrollWrapper
        type={type}
        scrollWidth={scrollWidth}
        className='ScrollWrapper'
    >
        <ScrollViewport className='ScrollViewport'>{children}</ScrollViewport>
        <ScrollScrollbar
            className='ScrollScrollbar'
            scrollWidth={scrollWidth}
            orientation={orientation}
        >
            <ScrollThumb className='ScrollThumb' />
        </ScrollScrollbar>
        <ScrollCorner className='ScrollCorner' />
    </ScrollWrapper>
);

export default ScrollSection;

const ScrollWrapper = styled(ScrollArea.Root)`
    width: 100%;
    height: 100%;
    overflow: hidden;
    --scrollbar-size: ${({ theme }) => theme.space.x3};
`;

const ScrollViewport = styled(ScrollArea.Viewport)`
    width: 100%;
    height: 100%;
    border-radius: inherit;
`;

const ScrollScrollbar = styled(ScrollArea.Scrollbar)`
    display: flex;
    /* ensures no selection */
    user-select: none;
    /* disable browser handling of all panning and zooming gestures on touch devices */
    touch-action: none;
    padding: 0;
    background-color: transparent;
    transition: background-color 160ms ease-out, width 160ms ease-out;

    &[data-orientation='vertical'] {
        width: ${({ scrollWidth }) => scrollWidth};
        &:hover {
            width: ${({ scrollWidth }) => `calc(${scrollWidth} + 4px)`};
        }
    }
    &[data-orientation='horizontal'] {
        flex-direction: column;
        height: ${({ scrollWidth }) => scrollWidth};
        &:hover {
            height: ${({ scrollWidth }) => `calc(${scrollWidth} + 4px)`};
        }
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.fill.default3};
        border-left: 1px solid ${({ theme }) => theme.colors.border.default1};
        &[data-orientation='vertical'] {
            width: ${({ theme }) => `calc(${theme.space.x3} - 1px)`};
        }
    }
`;

const ScrollThumb = styled(ScrollArea.Thumb)`
    flex: 1;
    background: ${({ theme }) => theme.colors.fill.other2};
    border-radius: ${({ theme }) => theme.radii.default};
    position: relative;
`;

const ScrollCorner = styled(ScrollArea.Corner)`
    background: ${({ theme }) => theme.colors.fill.other2};
`;
