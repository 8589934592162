import FormField from 'components/community/common/FormField';
import React from 'react';
import { CHAR_LIMIT_MAP } from '../validations';

export default function SupportEmail({
    formData,
    errors,
    pageMode = false,
    label = 'Support email',
    subLabel = '',
    ...rest
}) {
    return (
        <FormField
            id='supportEmail'
            name='supportEmail'
            label={label}
            placeholder='Support email'
            filledValue={formData.supportEmail}
            isError={errors.supportEmail}
            errorMsg={errors.supportEmail}
            maxLength={CHAR_LIMIT_MAP['supportEmail']?.max}
            inputValue={pageMode ? formData?.supportEmail : null}
            value={formData?.supportEmail}
            subLabel={subLabel}
            isThemeV2={true}
            {...rest}
        />
    );
}
