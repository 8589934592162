import Switch from 'atoms/Switch/Switch';
import WithTooltip from 'components/general/WithTooltip';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import React from 'react';
import { noop } from 'utils/constants/common';

export default function WaitlistField({
    onValueChange = noop,
    value = false,
    label = 'Enable Waitlist mode',
    description = 'Enables you to selectively pick whom you want to allow to register for your event.',
    disabled = false,
}) {
    const handleChange = () => {
        if (onValueChange) {
            onValueChange('isWaitlistEnabled', !value);
        }
    };

    return (
        <FlexDiv mb='x6' alignItems='flex-start'>
            <FlexDiv
                flexDirection='column'
                alignItems='stretch'
                flex='1'
                pr='x4'
            >
                <Typography variant='subtext0' color='ambience.0' pb='x1'>
                    {label}
                </Typography>
                <Typography variant='subtext2' color='ambience.6'>
                    {description}
                </Typography>
            </FlexDiv>
            <WithTooltip
                tooltip={
                    disabled &&
                    'Waitlist mode is not supported with the selected registration access type'
                }
                place='bottom'
                isThemeV2={true}
            >
                <Switch
                    uncheckedLabel='Off'
                    checkedLabel='On'
                    checked={value}
                    onCheckedChange={handleChange}
                    disabled={disabled}
                />
            </WithTooltip>
        </FlexDiv>
    );
}
