import FirebaseClient from 'containers/FirebaseClient';
import { DATA_SOURCE } from 'utils/constants/featureNames';
import { bindModuleLogger } from 'utils/logger';
import sendTraceReport from '../sendTraceReport';
import {
    Listeners,
    Message,
    QueryParams,
    RealtimeActionTypes,
    RealTimeDataSource,
    Unsubscribe,
} from '../types';
import FirebaseEmitter from './FirebaseEmitter';

const TIMESTAMP = 'timestamp';
const logger = bindModuleLogger('Firebase DataSource');

class FirebaseDataSource implements RealTimeDataSource {
    private client: FirebaseClient;
    private perf: any;
    private refMap: Map<string, firebase.database.Reference>;
    private _emitter: FirebaseEmitter;

    constructor(firebaseClient: FirebaseClient, perf: any) {
        if (!firebaseClient) {
            throw new Error('Invalid Firebase Client');
        }
        this.client = firebaseClient;
        this.perf = perf;
        this.refMap = new Map();
        this._emitter = FirebaseEmitter.newInstance();
    }

    getRef(channel: string): firebase.database.Reference {
        if (!channel) {
            throw new Error('Channel cannot be empty');
        }
        if (false === this.refMap.has(channel)) {
            this.refMap.set(channel, this.client.ref(channel));
        }
        return this.refMap.get(channel);
    }

    subscribe(
        channel: string,
        listeners: Listeners,
        options: any = {}
    ): Unsubscribe {
        logger.debug(
            'Adding Listeners',
            Object.keys(listeners),
            channel,
            options
        );

        const firebaseRef = this.getRef(channel);
        let activeListeners = new Map();

        if (listeners.onAdd || listeners.onMessage) {
            const addSubscription = (snapshot) => {
                const key = snapshot.key;
                const value = snapshot.val();

                let message: Message = {
                    payload: value,
                    metadata: { key },
                    action: RealtimeActionTypes.ADDED,
                };

                if (message?.payload?.metrics) {
                    message.payload.metrics.tr = Date.now();
                    sendTraceReport({
                        perf: this.perf,
                        source: DATA_SOURCE.FIREBASE,
                        key,
                        metrics: message.payload.metrics,
                    });
                }

                if (listeners.onAdd) {
                    listeners.onAdd(message);
                }
                if (listeners.onMessage) {
                    listeners.onMessage(message);
                }
            };

            activeListeners.set('child_added', addSubscription);
        }

        if (listeners.onChange || listeners.onMessage) {
            const subscription = (snapshot) => {
                const key = snapshot.key;
                const value = snapshot.val();

                let message: Message = {
                    payload: value,
                    metadata: { key },
                    action: RealtimeActionTypes.CHANGED,
                };

                if (listeners.onChange) {
                    listeners.onChange(message);
                }
                if (listeners.onMessage) {
                    listeners.onMessage(message);
                }
            };

            activeListeners.set('child_changed', subscription);
        }

        if (listeners.onRemove || listeners.onMessage) {
            const subscription = (snapshot) => {
                const key = snapshot.key;
                const value = snapshot.val();
                let message: Message = {
                    payload: value,
                    metadata: { key },
                    action: RealtimeActionTypes.REMOVED,
                };

                if (listeners.onRemove) {
                    listeners.onRemove(message);
                }
                if (listeners.onMessage) {
                    listeners.onMessage(message);
                }
            };

            activeListeners.set('child_removed', subscription);
        }

        this.client.getCurrentTimeStamp().then((timestamp) => {
            let addAndChangeRef = firebaseRef
                .orderByChild(options.orderBy || TIMESTAMP)
                .startAt(timestamp);

            if (activeListeners.has('child_added')) {
                addAndChangeRef.on(
                    'child_added',
                    activeListeners.get('child_added')
                );
            }

            if (activeListeners.has('child_changed')) {
                addAndChangeRef.on(
                    'child_changed',
                    activeListeners.get('child_changed')
                );
            }

            if (activeListeners.has('child_removed')) {
                let removeRef = firebaseRef;
                if (
                    options &&
                    options.orderBy &&
                    typeof options.onRemoveLimit === 'number'
                ) {
                    removeRef
                        .orderByChild(options.orderBy)
                        .limitToLast(options.onRemoveLimit);
                }
                removeRef.on(
                    'child_removed',
                    activeListeners.get('child_removed')
                );
            }
        });

        if (listeners.onSnapshot || listeners.onMessage) {
            const subscription = (snapshot) => {
                const key = snapshot.key;
                const value = snapshot.val();

                if (options?.enableReaderEmitter) {
                    this.emitData(channel, snapshot);
                }

                let message: Message = {
                    payload: value,
                    metadata: { key },
                    action: RealtimeActionTypes.SNAPSHOT,
                };

                if (listeners.onSnapshot) {
                    listeners.onSnapshot(message);
                }
                if (listeners.onMessage) {
                    listeners.onMessage(message);
                }
            };

            firebaseRef.on('value', subscription);
            activeListeners.set('value', subscription);
        }

        return () => {
            activeListeners.forEach((value, key) => {
                logger.debug('Removing listeners', key, channel);
                firebaseRef.off(key, value);
            });
            this.refMap.delete(channel);
        };
    }

    fetch(query: QueryParams): Promise<any> {
        let ref = this.getRef(query.metadata.channel);
        let firebaseQuery: firebase.database.Query;
        if (query.pageNumber !== undefined) {
            if (query.pageNumber === -1) {
                firebaseQuery = ref
                    .limitToLast(query.pageSize)
                    .orderByChild(query.orderBy);
            } else {
                firebaseQuery = ref
                    .limitToFirst(query.pageSize)
                    .startAt(query.pageNumber)
                    .orderByChild(query.orderBy);
            }
        }
        return new Promise((resolve, reject) => {
            if (firebaseQuery) {
                firebaseQuery.once('value', (snap) => {
                    const values = [];
                    // We need to add child one by one for save in same order in which data received
                    snap.forEach((child) => {
                        values.push({
                            value: {
                                _id: child.key,
                                ...child.val(),
                            },
                        });
                    });
                    resolve(values);
                });
            } else {
                reject(new Error('Cannot directly fetch the node'));
            }
        });
    }

    fetchOnce(metaData: any): Promise<any> {
        let ref = this.getRef(metaData.channel);
        return new Promise((resolve) => {
            ref.once('value', (snap) => {
                const data = snap.val();
                resolve(data);
            });
        });
    }

    registerOnDisconnect(channel: string, message: any): Promise<boolean> {
        throw new Error('Not Yet implemented');
    }

    cancelOnDisconnect(channel: string): Promise<boolean> {
        throw new Error('Not Yet implemented');
    }

    private emitData(type, snapshot) {
        this._emitter.emit(`${type}_value`, {
            type,
            key: snapshot.key,
            value: snapshot.val(),
        });
    }
}

export default FirebaseDataSource;
