import { useEffect, useRef, useState } from 'react';
import { Events } from 'utils/constants/containers/airmeet';
import { LOG_LEVEL } from 'utils/constants/logger';
import { logger } from 'utils/logger';
import { useStageRTCContext } from 'context/StageRTCProvider';
const InfoLog = logger.init('useActiveSpeaker', LOG_LEVEL.INFO);

const useActiveSpeaker = ({ streams = [], streamKeys }) => {
    const [activeSpeakerStreams, setActiveSpeakersStreams] = useState({});
    const { stageService: rtcClient } = useStageRTCContext();

    const updateActiveSpeaker = useRef(() => {});
    updateActiveSpeaker.current = () => {
        const activeSpeaker = rtcClient.getActiveSpeaker();
        if (/**!activeSpeaker */ !streams.length) {
            return;
        }

        const streamsToUpdate = {};

        let activeSpeakerStream = streams.find(
            (stream) =>
                parseInt(stream.id) === parseInt(activeSpeaker) &&
                streamKeys[stream.id]
        )?.id;

        const mediaStream = streams.find(
            ({ isScreenShare, isCustomMedia }) => isScreenShare || isCustomMedia
        )?.id;

        if (!activeSpeakerStream && rtcClient.getMainStream()) {
            activeSpeakerStream = streams.find(
                (stream) =>
                    parseInt(stream.id) ===
                        parseInt(rtcClient.getMainStream().getId()) &&
                    streamKeys[stream.id]
            )?.id;
        }

        if (!activeSpeakerStream) {
            activeSpeakerStream = streams.find(
                (stream) => streamKeys[stream.id]
            )?.id;
        }

        if (activeSpeakerStream) {
            streamsToUpdate[activeSpeakerStream] = {
                isMain: !mediaStream,
            };
        }

        if (mediaStream) {
            streamsToUpdate[mediaStream] = { isMain: true };
        }

        setActiveSpeakersStreams((prevStreamsToUpdate) => {
            const prevObjectKeys = Object.keys(prevStreamsToUpdate);
            const newObjectKeys = Object.keys(streamsToUpdate);
            if (prevObjectKeys.length !== newObjectKeys.length) {
                return streamsToUpdate;
            }
            const difference = newObjectKeys.filter(
                (x) => !prevObjectKeys.includes(x)
            );
            return difference.length > 0
                ? streamsToUpdate
                : prevStreamsToUpdate;
        });
    };

    useEffect(() => {
        const setActiveSpeakerStreams = () => {
            updateActiveSpeaker && updateActiveSpeaker.current();
        };
        rtcClient.on(Events.activeSpeaker, setActiveSpeakerStreams);
        return () => {
            rtcClient.off(Events.activeSpeaker, setActiveSpeakerStreams);
        };
    }, [rtcClient]);

    useEffect(() => {
        updateActiveSpeaker && updateActiveSpeaker.current();
    }, [streamKeys]);

    InfoLog(
        'returning this ',
        activeSpeakerStreams,
        'with these stream keys',
        streamKeys
    );

    return {
        activeSpeakerStreams,
    };
};

export default useActiveSpeaker;
