import Button from 'atoms/Button/button';
import classnames from 'classnames';
import { alpha } from 'foundations/theme';
import useTheme from 'hooks/useTheme';
import React, { useEffect } from 'react';
import PermissionUtils from 'utils/permission-utils';
import styled from 'styled-components';

function LiveEventPrompt({
    heading,
    message,
    resolveText,
    rejectText,
    onClose,
    onClick,
    rejectEnable = true,
    resolveEnable = true,
    classType = 'warning',
    timer = null,
}) {
    const { theme } = useTheme();

    useEffect(() => {
        if (timer) {
            let timeout = setTimeout(() => {
                onClose();
            }, timer);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, []);
    return PermissionUtils.isEventCloudHost() ? null : (
        <div
            className={classnames(
                'modal fade light-theme g-modal show',
                classType
            )}
        >
            <div className='modal-dialog' role='document'>
                <div className='modal-content'>
                    <div className='modal-body'>
                        <BoxDiv className='steps-one '>
                            {!heading && <h2>{message}</h2>}
                            {heading && (
                                <>
                                    <h2>{heading}</h2>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: message,
                                        }}
                                    ></p>
                                </>
                            )}
                        </BoxDiv>
                        <div className='action-btns'>
                            {rejectEnable && (
                                <Button
                                    onClick={onClose}
                                    variant='danger'
                                    style={{
                                        backgroundColor: alpha(
                                            theme.colors.semantic[1],
                                            0.05
                                        ),
                                        maxWidth: '150px',
                                        marginRight: '1em',
                                    }}
                                    btnTextStyles={{
                                        color: theme.colors.accentBrick[2],
                                    }}
                                >
                                    {rejectText || 'No'}
                                </Button>
                            )}
                            {resolveEnable && (
                                <PromptYesButton
                                    onClick={(evt) => onClick(evt, onClose)}
                                    variant='primary'
                                >
                                    {resolveText || 'Yes'}
                                </PromptYesButton>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LiveEventPrompt;

const PromptYesButton = styled(Button)`
    height: auto;
    padding: 6px 12px;
    border-radius: 4px;
    line-height: normal;

    > p {
        margin-bottom: 0 !important;
    }
`;

const BoxDiv = styled.div`
    word-break: break-word;
`;
