import { useEffect, useRef, useState } from 'react';

const isServerReachable = function () {
    try {
        const url = process.env.REACT_APP_SUPPORTED_BROWSER_LIST;
        // IE vs. standard XHR creation
        const request = new (window.ActiveXObject || XMLHttpRequest)(
            'Microsoft.XMLHTTP'
        );
        const timeout = setTimeout(() => {
            request.abort();
        }, 200);

        request.open(
            'GET',
            `${url}?rand=${Math.random()}`,
            false // to a synchronous request
        );
        request.send();
        clearTimeout(timeout);
        const result = request.status;
        // Make sure the server is reachable
        return result >= 200 && result < 500;
    } catch (e) {
        return false;
    }
};

const checkHasOnline = () => {
    return window.navigator.onLine || isServerReachable();
};

const useNetworkStatus = () => {
    const [isOnline, setOnline] = useState(checkHasOnline());
    const trackOnlineRef = useRef(isOnline);

    useEffect(() => {
        const onChange = () => {
            setOnline(trackOnlineRef.current && checkHasOnline());
        };
        const onOffline = function (e) {
            trackOnlineRef.current = false;
            onChange();
        };

        const onOnline = function (e) {
            trackOnlineRef.current = true;
            onChange();
        };

        window.addEventListener('offline', onOffline);
        window.addEventListener('online', onOnline);

        return () => {
            window.removeEventListener('offline', onOffline);
            window.removeEventListener('online', onOnline);
        };
    }, []);

    return { isOnline };
};

export default useNetworkStatus;
