import ColorInput from 'atoms/ColorInput/ColorInput';
import ColorPickerV2 from 'atoms/ColorPicker/ColorPickerV2';
import RadioGroupV2 from 'atoms/Radio/RadioGroupV2';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { LazyLoaded } from 'hooks/common';
import useTheme from 'hooks/useTheme';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { nearestHexColorCode, rgbObjToStr, rgbToHex } from 'utils/colors';
import { cdnImage } from 'utils/core';

const ColorData = LazyLoaded(
    React.lazy(() =>
        import('components/lounge/rhsV2/settingsTab/widgets/colorData')
    )
);

export default function ImageRadioGroup({
    options,
    type = 'topNavBg',
    bgColor,
    value,
    onValueChange,
    ...rest
}) {
    const { theme } = useTheme();
    const [enableColorPicker, setEnableColorPicker] = useState(false);
    const [colorData, setColorData] = useState([]);

    const { defaultBgColor, defaultBgColorName } = useMemo(
        () => ({
            defaultBgColor: theme?.isLightTheme
                ? { r: 255, g: 255, b: 255 }
                : { r: 0, g: 0, b: 0 },
            defaultBgColorName: theme?.isLightTheme ? 'White' : 'Black',
        }),
        [theme]
    );

    const showColorPicker = () => {
        setEnableColorPicker(true);
    };

    const handleColorSave = (hsl, hex, rgb) => {
        if (onValueChange) {
            onValueChange({ bgColor: rgb, bgType: 'solidColor' });
        }
    };

    const handleTypeChange = (value) => {
        const obj = { bgType: value };
        if (value === 'solidColor') {
            obj.bgColor = bgColor || defaultBgColor;
        }
        onValueChange(obj);
    };

    const hideColorPicker = () => {
        setEnableColorPicker(false);
    };

    const bgColorName = useMemo(() => {
        if (bgColor) {
            const colorHex = rgbToHex({ ...(bgColor || {}) });
            const clrName = nearestHexColorCode({
                colorHex,
                colorData,
            });
            return clrName?.name;
        }

        return defaultBgColorName;
    }, [bgColor, colorData, defaultBgColorName]);

    return (
        <Wrapper
            flexDirection='column'
            alignItems='stretch'
            position='relative'
        >
            <ColorData callBack={setColorData} />
            <RadioGroupV2
                {...rest}
                onValueChange={handleTypeChange}
                name={type}
                value={value}
                options={(options || []).map((option) => ({
                    ...option,
                    label: (
                        <BgItem
                            isChecked={value === option?.value}
                            {...option}
                            type={type}
                            bgColor={bgColor || defaultBgColor}
                            bgColorName={bgColorName}
                            showColorPicker={showColorPicker}
                            defaultBgColor={defaultBgColor}
                            theme={theme}
                        />
                    ),
                }))}
            />
            {enableColorPicker ? (
                <ColorPickerV2
                    onClose={hideColorPicker}
                    value={bgColor || defaultBgColor}
                    onSave={handleColorSave}
                    gradient={false}
                    isOpacityEnabled={false}
                    title={'Select color'}
                    colorData={colorData}
                />
            ) : null}
        </Wrapper>
    );
}

const BgItem = ({
    value,
    label,
    colorInput = false,
    type,
    bgColor,
    isChecked = false,
    showColorPicker,
    bgColorName,
    defaultBgColor,
    theme,
}) => {
    return (
        <BgItemWrapper
            isChecked={isChecked}
            flexDirection='column'
            alignItems='stretch'
        >
            <ImageWrapper
                flexDirection='column'
                alignItems='stretch'
                imgHeight={type === 'topNavBg' ? '48px' : '104px'}
            >
                <BgImg
                    src={cdnImage(
                        type === 'topNavBg'
                            ? `/images/top-nav-bg-sample${
                                  theme?.isLightTheme ? '-light' : ''
                              }.png`
                            : `/images/card-bg-sample${
                                  theme?.isLightTheme ? '-light' : ''
                              }.png`
                    )}
                    alt='Background'
                />
                <BgIlImg
                    src={cdnImage(
                        type === 'topNavBg'
                            ? '/images/top-nav-bg-il-sample.svg'
                            : '/images/card-bg-il-sample.svg'
                    )}
                    alt='Background Illustration'
                />
                {type === 'topNavBg' ? (
                    <>
                        <NavBg type={value} color={bgColor} />
                        <NavImg
                            src={cdnImage(
                                `/images/top-nav-sample${
                                    theme?.isLightTheme ? '-light' : ''
                                }.svg`
                            )}
                            alt='Top Nav'
                        />
                    </>
                ) : (
                    <CardBg type={value} color={bgColor}>
                        <FlexDiv alignItems='center' pb='8px'>
                            <Circle bg='fill.other2' />
                            <FlexDiv
                                flexDirection='column'
                                alignItems='stretch'
                            >
                                <Strip width='73px' mb='4px' bg='fill.other2' />
                                <Strip width='46px' bg='fill.other2' />
                            </FlexDiv>
                        </FlexDiv>
                        <FlexDiv
                            flexDirection='column'
                            alignItems='stretch'
                            pb='8px'
                        >
                            <Strip width='184px' mb='4px' bg='fill.other3' />
                            <Strip width='184px' mb='4px' bg='fill.other3' />
                            <Strip width='184px' bg='fill.other3' />
                        </FlexDiv>
                        <FlexDiv alignItems='center'>
                            <SampleButton mr='4px' />
                            <SampleButton />
                        </FlexDiv>
                    </CardBg>
                )}
            </ImageWrapper>
            <LabelWrapper alignItems='center'>
                <FlexDiv flex='1'>{label}</FlexDiv>
                {colorInput ? (
                    <ColorInput
                        value={bgColor || defaultBgColor}
                        name={bgColorName}
                        onClick={showColorPicker}
                    />
                ) : null}
            </LabelWrapper>
        </BgItemWrapper>
    );
};

const Wrapper = styled(FlexDiv)`
    .RadioGroupRoot {
        .ItemWrapper {
            position: relative;
            .RadioGroupItem {
                position: absolute;
                bottom: 27px;
                left: 14px;
            }
            .LabelRoot {
                flex: 1;
                margin: 0;
            }
        }
    }
`;

const BgItemWrapper = styled(FlexDiv)`
    border-radius: ${({ theme }) => theme.radii.default};
    border: solid 1px
        ${({ theme, isChecked }) =>
            isChecked
                ? theme.colors.border.other1
                : theme.colors.border.default2};
    margin-bottom: 16px;
    overflow: hidden;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.fill.default3};
`;

const ImageWrapper = styled(FlexDiv)`
    height: ${({ imgHeight }) => imgHeight};
    position: relative;
`;

const NavImg = styled.img`
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;
`;

const BgIlImg = styled.img`
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
`;

const BgImg = styled.img`
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
`;

const LabelWrapper = styled(FlexDiv)`
    padding: 6px 8px 6px 40px;
    min-height: 42px;
`;

const NavBg = styled(FlexDiv)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 31.8px;
    z-index: 3;
    ${({ type, color, theme }) =>
        type === 'glassmorphic'
            ? `
            background-color: ${theme.overlay.bgColor};
            backdrop-filter: ${theme.overlay.bgBlur};
    `
            : type === 'solidColor'
            ? `
            background-color: ${rgbObjToStr(color)};
    `
            : ''}
`;

const CardBg = styled(FlexDiv)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    flex-direction: column;
    align-items: stretch;
    padding: 8px;
    border-radius: ${({ theme }) => theme.radii.default};
    ${({ type, color, theme }) =>
        type !== 'solidColor'
            ? `
        background-color: ${theme.overlay.bgColor};
        backdrop-filter: ${theme.overlay.bgBlur};
        border: solid 1px ${theme.colors.border.default2};
`
            : `
        background-color: ${rgbObjToStr(color)};
`}
`;

const Circle = styled(FlexDiv)`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 4px;
`;

const Strip = styled(FlexDiv)`
    height: 4px;
    border-radius: 20px;
`;

const SampleButton = styled(FlexDiv)`
    border-radius: ${({ theme }) => theme.radii.default};
    background-color: ${({ theme }) => theme.colors.sem.info};
    height: 16px;
    width: 42px;
`;
