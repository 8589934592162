export enum FILTER_TYPE {
    TRACK = 'TRACK',
    TAGS = 'TAGS',
    TYPE = 'TYPE',
    MEETINGS = 'MEETINGS',
    BOOKMARKS = 'BOOKMARKS',
    CASUAL_CATCHUP = 'CASUAL_CATCHUP',
    VENUE = 'VENUE',
    LIVE_SESSIONS = 'LIVE_SESSIONS',
}

export enum SESSION_TYPES {
    STREAMING = 'STREAMING',
    HOSTING = 'HOSTING',
    BREAK = 'BREAK',
    SPEED_NETWORKING = 'SPEED_NETWORKING',
    SCHEDULE_MEETING = 'SCHEDULE_MEETING',
    BOOTH = 'BOOTH',
    FLUID_LOUNGE = 'FLUID_LOUNGE',
    PRE_RECORDED = 'PRE_RECORDED',
    GROUP_MEETING = 'LARGE_CALL',
    CASUAL_CATCHUP = 'CASUAL_CATCHUP',
    // TODO: future scope
    LOUNGE = 'LOUNGE',
}

export const HOSTING_SESSIONS = [
    SESSION_TYPES.HOSTING,
    SESSION_TYPES.STREAMING,
    SESSION_TYPES.PRE_RECORDED,
] as const;

export enum SESSION_STATUS {
    ONGOING = 'ONGOING',
    PAUSED = 'PAUSED',
    FINISHED = 'FINISHED',
    CREATED = 'CREATED',
}
export interface SessionProps {
    sessionList: Session[];
    toolTipSessionId: string;
    updateBookmarkCount: () => void;
    bookMarkCountMap: {
        [sessionId: string]: number;
    };
    handleListItemRender: (...args: string[]) => void;
    isButtonScrollRef: React.MutableRefObject<boolean>;
    overScanRef: React.MutableRefObject<boolean>;
    isUserSearching: boolean;
    scrollIndexSubscribe: (
        callback: (...args: unknown[]) => void
    ) => () => void;
    source?: string;
}

export interface getFilteredSessionListProps {
    selectedTags: any[];
    selectedTypes: any[];
    selectedTracks: any[];
    bookMarkFilter: boolean;
    searchText: string;
    myMeetings: boolean;
    selectedVenues: any[];
    liveSessions: boolean;
}

export interface Session {
    sessionid: string;
    name: string;
    start_time: number;
    status: SESSION_STATUS;
    duration: number;
    cloud_user_id: string;
    summary: string;
    master_img_url: string;
    intro_img_url: string;
    remaining_time: number;
    is_branding_enabled: boolean;
    is_custom_tables_enabled: boolean;
    branding_info: string;
    is_media_enabled: boolean;
    community_description: string;
    speaker_id: string[];
    speakerList: object[];
    visible: boolean;
    session_meta?: {
        boothId?: string;
    };
    type: SESSION_TYPES;
    date: string;
    isHeader: boolean;
    end_time: number;
    label: string;
    meeting_id: string;
    host_id: string[];
    cohost_ids: string[];
}

export interface SessionDates {
    [year: string]: {
        [month: string]: {
            date: string;
            day: string;
            isDisabled: boolean;
            year: string;
        }[];
    };
}
