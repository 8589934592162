import usePlanFlags from 'hooks/billing-plans/usePlanFlags';
import useDashboardFeatureFlags from 'hooks/community/useDashboardFeatureFlags';
import useConferenceTrialLeadHandler from 'hooks/useConferenceTrialLeadHandler';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EVENT_SUB_TYPES, EVENT_TYPES } from 'utils/constants/airmeet';
import { getIsNewLandingPage } from 'utils/core';
import { getDashboardTabs } from './dashboardTabs';
import useDashboardTabs from './useDashboardTabs';
import useEventDashboardPermissions from './useEventDashboardPermissions';

/**
 * Common hook to get the final tabs tree along with the module level details like the module URL, isVisible or isPremium.
 */
export default function useEventDashboardFeatures(props) {
    const { firebaseClient, tableFirebaseClient } = props || {};
    const { airmeet } = useSelector((state) => state.cmDashboard);
    const loungeAirmeetId = useSelector(
        (state) => state?.lounge?.airmeet?.airmeetId
    );
    const subscription_type = useSelector(
        (state) => state?.Community?.current?.subscription_type
    );
    const is_analytics_v2_enabled = useSelector(
        (state) => state?.lounge?.airmeet?.is_analytics_v2_enabled
    );
    const { permissionMappingToTabs } = useEventDashboardPermissions();

    const live_config = useSelector(
        (state) => state?.lounge?.airmeet?.live_config
    );
    const created_at = useSelector(
        (state) => state?.lounge?.airmeet?.created_at
    );
    const couponType = useSelector(
        (state) => state.Community?.current?.coupon_type
    );
    const userEmail = useSelector((state) => state?.auth?.user?.email);

    const {
        branding,
        booths,
        venue_settings,
        sponsors,
        is_ticketing_enabled,
        isLeaderboardEnabled,
        isCustomInterestsEnabled,
        is_custom_reception_enabled,
        is_venue_settings_logo_enabled,
        allowed_max_supported_streaming_resolution,
        is_free_plan_user_in_meetup,
        is_custom_email_template_enabled: is_custom_email_template_enabled_for_community,
        isAutomatedWorkflowEnabled,
        isUnifiedProfilesEnabled,
        isWaitlistFeatureAllowed,
        is_video_editor_enabled,
    } = useDashboardFeatureFlags({ airmeet });
    const { hasContactSalesClicked } = useConferenceTrialLeadHandler();
    const { isFreeTrialPlanDeactivated } = usePlanFlags();
    const is_custom_email_enabled_for_airmeet = useSelector(
        (state) => state?.lounge?.airmeet?.is_custom_email_enabled
    );
    const isResourceManagementEnabled = useSelector(
        (state) => state.lounge.airmeet.is_resource_management_enabled
    );
    const isSocialShareEnabled = useSelector(
        (state) => state?.lounge?.airmeet?.is_social_share_enabled
    );
    const participantsTabEnhanced = useSelector(
        (state) => state?.lounge?.airmeet?.participants_tab_enhanced
    );
    const closedCaptionsFlag = useSelector(
        (state) =>
            state?.lounge?.airmeet?.enabled_feature_data_v2?.closed_captions
    );

    const { airmeetId: dashAirmeetId, event_sub_type, event_type } =
        airmeet || {};
    const airmeetId = dashAirmeetId || loungeAirmeetId;
    const isHybridEvent =
        event_sub_type === EVENT_SUB_TYPES.HYBRID_CONFERENCE &&
        event_type === EVENT_TYPES.CONFERENCE;
    const isInPersonEvent =
        event_sub_type === EVENT_SUB_TYPES.IN_PERSON_CONFERENCE &&
        event_type === EVENT_TYPES.CONFERENCE;
    const isAppSumoUser = !!couponType || subscription_type === 'APPSUMO';

    const allTabs = useMemo(
        () =>
            getDashboardTabs({
                airmeet,
                hasContactSalesClicked,
                venue_settings,
                is_custom_reception_enabled,
                is_venue_settings_logo_enabled,
                allowed_max_supported_streaming_resolution,
                firebaseClient,
                tableFirebaseClient,
                booths,
                is_free_plan_user_in_meetup,
                sponsors,
                isLeaderboardEnabled,
                isCustomInterestsEnabled,
                branding,
                is_ticketing_enabled,
                is_custom_email_template_enabled_for_community,
                is_custom_email_enabled_for_airmeet,
                is_analytics_v2_enabled,
                subscription_type,
                isSocialShareEnabled,
                permissionMappingToTabs,
                isHybridEvent,
                isNewLandingPage: getIsNewLandingPage(created_at, live_config),
                isResourceManagementEnabled,
                isAppSumoUser,
                isAutomatedWorkflowEnabled,
                isUnifiedProfilesEnabled,
                closedCaptionsFlag,
                participantsTabEnhanced,
                isInPersonEvent,
                isWaitlistFeatureAllowed,
                is_video_editor_enabled,
                isFreeTrialPlanDeactivated,
                userEmail,
            }),
        [
            airmeet,
            hasContactSalesClicked,
            venue_settings,
            is_custom_reception_enabled,
            is_venue_settings_logo_enabled,
            allowed_max_supported_streaming_resolution,
            firebaseClient,
            tableFirebaseClient,
            booths,
            is_free_plan_user_in_meetup,
            sponsors,
            isLeaderboardEnabled,
            isCustomInterestsEnabled,
            branding,
            is_ticketing_enabled,
            is_custom_email_template_enabled_for_community,
            is_custom_email_enabled_for_airmeet,
            is_analytics_v2_enabled,
            subscription_type,
            isSocialShareEnabled,
            permissionMappingToTabs,
            isHybridEvent,
            created_at,
            live_config,
            isResourceManagementEnabled,
            isAppSumoUser,
            isAutomatedWorkflowEnabled,
            isUnifiedProfilesEnabled,
            closedCaptionsFlag,
            participantsTabEnhanced,
            isInPersonEvent,
            isWaitlistFeatureAllowed,
            is_video_editor_enabled,
            isFreeTrialPlanDeactivated,
            userEmail,
        ]
    );

    const {
        tabs,
        getIsFeatureVisible,
        getFeatureUrl,
        getFeatureIsPremium,
    } = useDashboardTabs({ tabs: allTabs, entityId: airmeetId });

    return {
        tabs,
        getIsFeatureVisible,
        getFeatureUrl,
        getFeatureIsPremium,
        isAppSumoUser,
    };
}
