import dmChatWriter from 'hooks/dataWriter/dmChatWriter';
import eventChatWriter from 'hooks/dataWriter/eventChatWriter';
import heartBeatWriter from 'hooks/dataWriter/heartBeatWriter';
import inviteToStageWriter from 'hooks/dataWriter/inviteToStageWriter';
import pinCommentWriter from 'hooks/dataWriter/pinCommentWriter';
import presenceWriter from 'hooks/dataWriter/presenceWriter';
import raiseHandDataWriter from 'hooks/dataWriter/raiseHandDataWriter';
import sessionChatWriter from 'hooks/dataWriter/sessionChatWriter';
import userProfileWriter from 'hooks/dataWriter/userProfileWriter';
import userStageVisibilityWriter from 'hooks/dataWriter/userStageVisibilityWriter';
import isObject from 'lodash/isObject';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { callApi } from 'utils/apiService';
import {
    FEATURE_NAMES,
    SERVER_FEATURE_NAME,
} from 'utils/constants/featureNames';
import firebaseLogger from 'utils/firebaseLogger';
import { bindModuleLogger } from 'utils/logger';
import { v4 as uuid } from 'uuid';
import LocalUpdates from '../services/realtime/observer/LocalUpdates';
import {
    DataWriterConfig,
    DataWriterResponse,
    Message,
    QueueType,
    WriteStatus,
} from '../services/realtime/types';
import RequestQueue from '../services/realtime/writer/RequestQueue';
import {
    REACT_APP_DATA_WRITER_API_URL,
    REACT_APP_NEW_RTS_URL,
} from '../utils/constants/airmeet';
import breakoutRoomsDataWriter from './dataWriter/breakoutRoomsDataWriter';
import chatReactionWriter from './dataWriter/chatReactionDataWriter';
import chatReactionWriterOld from './dataWriter/chatReactionDataWriterOld';
import commentsWriter from './dataWriter/commentsWriter';
import stageBannerWriter from './dataWriter/stageBannerWriter';
import onlineExhibitorsWriter from './dataWriter/exhibitorOnlineDataWriter';
import fluidSpaceReactionWriter from './dataWriter/fluidSpaceReactionWriter';
import fluidSpaceWriter from './dataWriter/fluidSpaceWriter';
import liveVideoAnnouncementWriter from './dataWriter/liveVideoAnnouncementWriter';
import moderationToggleLevel from './dataWriter/moderationToggleLevel';
import questionsWriter from './dataWriter/questionsWriter';
import reportMessageWriter from './dataWriter/reportMessageWriter';
import sessionChatModerationWriter from './dataWriter/sessionChatModerationWriter';
import showOnStageContentDataWriter from './dataWriter/showOnStageContentDataWriter';
import showOnStageDataWriter from './dataWriter/showOnStageDataWriter';
import socialLoungeWriter from './dataWriter/socialLoungeWriter';
import socialStreamingWriter from './dataWriter/socialStreamingWriter';
import speakerQuestionModerationToggle from './dataWriter/speakerQuestionModerationToggle';
import stageLayoutWriter from './dataWriter/stageLayoutWriter';
import stageReactionWriter from './dataWriter/stageReactionWriter';
import streamPublisherWriter from './dataWriter/streamPublisherWriter';
import tableInvite from './dataWriter/tableInvite';
import tablePresenceWriter from './dataWriter/tablePresenceWriter';
import startMegaphone from './dataWriter/useMegaphone';
import useChatReactionMultipleEmojiFeatureFlag from './useChatReactionMultipleEmojiFeatureFlag';
import useDataReader from './useDataReader';
import { ALL_FEATURES_CONFIG } from './useFeatureControl';
import useFeatureFirebaseClient from './useFeatureFirebaseClient';
import useLiveAirmeetContext from './useLiveAirmeetContext';

const logger = bindModuleLogger('Data Writer Hook');

const DEFAULT_CONFIG: DataWriterConfig = {
    requiredTxnStatusConfirmation: WriteStatus.ACCEPTED,
    optimisticUpdates: false,
    queueType: QueueType.NONE,
    useFrontendWriterFallback: true,
    forceFrontendWrite: false,
};

const EMPTY_OBJ = {};

const DEFAULT_ENABLED = {
    [FEATURE_NAMES.STAGE_REACTIONS]:
        ['dev', 'test'].includes(process.env.REACT_APP_ENV) &&
        Math.random() <
            Number(process.env.REACT_APP_DATA_WRITER_ROLLOUT_PERCENTAGE),
};

const requestQueues = new Map<string, RequestQueue<DataWriterResponse>>();

function getRandomBetween(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

const pushDataToApi = async (
    { endpoint, type, method, body, metaData },
    config: DataWriterConfig
): Promise<DataWriterResponse> => {
    const retryConfigOverrides = {
        retries: 2,
        retryDelay: function (attempt, error, response) {
            const backOffTime = Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
            const jitter = getRandomBetween(100, 500); // jitter so that all users do not try at the same time
            const delay = backOffTime + jitter;
            logger.info(`Scheduling Retry after - ${delay}`, {
                key: body?.metaData?.key,
                featureName: body?.featureName,
                time: Date.now(),
                error,
                response,
            });
            return delay;
        },
    };

    logger.debug(
        'Scheduling processing request',
        body?.metaData?.key,
        Date.now()
    );

    const task = async () => {
        logger.debug('Processing request', {
            key: body?.metaData?.key,
            featureName: body?.featureName,
            time: Date.now(),
        });
        let results = await callApi({
            endpoint,
            type: 'json',
            method: 'POST',
            body,
            appendBaseUrl: false,
            credentials: 'include',
            query: {
                airmeetId: metaData.airmeetId,
                userId: metaData.userId,
            },
            withRetry:
                !config.useFrontendWriterFallback ||
                body?.featureName === FEATURE_NAMES.USER_PRESENCE,
            retryConfigOverrides: retryConfigOverrides,
        });
        return results;
    };

    if (config.queueType === QueueType.NONE) {
        return await task();
    } else {
        const queueName =
            config.queueType === QueueType.DEDICATED
                ? body.featureName
                : 'global';
        let queue = requestQueues.get(queueName);
        if (!queue) {
            queue = new RequestQueue<DataWriterResponse>();
            requestQueues.set(queueName, queue);
        }
        let requestName;
        if (body?.featureName === FEATURE_NAMES.USER_PRESENCE) {
            requestName = `${body?.metaData.context}-${body?.metaData.contextId}-${body?.metaData?.actionType}`;
        } else {
            requestName = body?.metaData?.key;
        }

        return await queue.enqueue(task, requestName);
    }
};

const pushDataToFirebase = ({
    airmeetId,
    sessionId,
    featureName,
    client,
    payload,
    metaData,
    isMultipleEmoji,
}) => {
    if (!airmeetId) {
        throw new Error('Invalid firebase key');
    }

    switch (featureName) {
        case FEATURE_NAMES.STAGE_REACTIONS: {
            return stageReactionWriter({
                airmeetId,
                sessionId,
                client,
                payload,
                metaData,
            });
        }
        case FEATURE_NAMES.USER_PRESENCE: {
            return presenceWriter({
                airmeetId,
                client,
                payload,
                metaData,
            });
        }
        case FEATURE_NAMES.EVENT_CHAT: {
            return eventChatWriter({
                airmeetId,
                client,
                payload,
                metaData,
                logger,
            });
        }

        case FEATURE_NAMES.SESSION_CHAT: {
            return sessionChatWriter({
                airmeetId,
                sessionId,
                client,
                metaData,
                payload,
                logger,
            });
        }

        case FEATURE_NAMES.QUESTIONS: {
            return questionsWriter({
                airmeetId,
                sessionId,
                client,
                payload,
                metaData,
                logger,
            });
        }

        case FEATURE_NAMES.DM_CHAT: {
            return dmChatWriter({
                airmeetId,
                client,
                payload,
                metaData,
                logger,
            });
        }

        case FEATURE_NAMES.PIN_COMMENT: {
            return pinCommentWriter({ airmeetId, client, payload, logger });
        }

        case FEATURE_NAMES.REPORT_MESSAGE: {
            return reportMessageWriter({
                airmeetId,
                client,
                payload,
                metaData,
                logger,
            });
        }

        case FEATURE_NAMES.SESSION_CHAT_MODERATION: {
            return sessionChatModerationWriter({
                airmeetId,
                sessionId,
                client,
                payload,
                logger,
            });
        }

        case FEATURE_NAMES.STAGE_RAISE_HAND: {
            return raiseHandDataWriter({
                sessionId,
                airmeetId,
                client,
                payload,
                metaData,
                logger,
            });
        }

        case FEATURE_NAMES.MODERATION_TOGGLE_LEVEL: {
            return moderationToggleLevel({
                airmeetId,
                client,
                payload,
                metaData,
                logger,
            });
        }

        case FEATURE_NAMES.USE_MEGAPHONE: {
            return startMegaphone({
                airmeetId,
                client,
                payload,
                metaData,
                logger,
            });
        }

        case FEATURE_NAMES.FLUID_SPACE_REACTION: {
            return fluidSpaceReactionWriter({
                airmeetId,
                client,
                payload,
                metaData,
                firebaseLogger: firebaseLogger,
            });
        }

        case FEATURE_NAMES.TABLE_INVITE: {
            return tableInvite({
                airmeetId,
                client,
                payload,
                metaData,
                logger,
            });
        }

        case FEATURE_NAMES.LOUNGE_TABLES:
        case FEATURE_NAMES.BOOTH_TABLES:
        case FEATURE_NAMES.BREAKOUT_TABLES: {
            return tablePresenceWriter({
                airmeetId,
                client,
                payload,
                metaData,
                logger,
            });
        }

        case FEATURE_NAMES.BREAKOUT_ROOMS: {
            return breakoutRoomsDataWriter({
                airmeetId,
                client,
                sessionId,
                metaData,
                payload,
            });
        }
        case FEATURE_NAMES.QNACOMMENTS: {
            return commentsWriter({
                sessionId,
                airmeetId,
                client,
                payload,
                metaData,
                logger,
            });
        }

        case FEATURE_NAMES.FLUID_SPACE: {
            return fluidSpaceWriter({
                airmeetId,
                client,
                metaData,
                logger,
                firebaseLogger: firebaseLogger,
            });
        }

        case FEATURE_NAMES.LIVE_VIDEO_ANNOUNCEMENT: {
            return liveVideoAnnouncementWriter({
                client,
                metaData,
                payload,
                logger,
            });
        }
        case FEATURE_NAMES.CHAT_REACTION: {
            /* need to change here */
            if (isMultipleEmoji) {
                return chatReactionWriter({
                    airmeetId,
                    client,
                    metaData,
                    logger,
                });
            } else {
                return chatReactionWriterOld({
                    airmeetId,
                    client,
                    metaData,
                    logger,
                });
            }
        }

        case FEATURE_NAMES.USER_PROFILE: {
            return userProfileWriter({
                airmeetId,
                client,
                metaData,
                payload,
                logger,
            });
        }

        case FEATURE_NAMES.STAGE_INVITE: {
            return inviteToStageWriter({
                sessionId,
                airmeetId,
                client,
                payload,
                metaData,
                logger,
            });
        }
        case FEATURE_NAMES.USER_STAGE_VISIBILITY: {
            return userStageVisibilityWriter({
                airmeetId,
                client,
                payload,
                metaData,
                logger,
            });
        }
        case FEATURE_NAMES.ONLINE_EXHIBITORS: {
            return onlineExhibitorsWriter({
                airmeetId,
                client,
                metaData,
                payload,
                logger,
            });
        }
        case FEATURE_NAMES.SOCIAL_STREAMING: {
            return socialStreamingWriter({
                airmeetId,
                sessionId,
                client,
                metaData,
                payload,
            });
        }
        case FEATURE_NAMES.SHOW_ON_STAGE: {
            return showOnStageDataWriter({
                airmeetId,
                client,
                metaData,
                payload,
                logger,
            });
        }

        case FEATURE_NAMES.SHOW_ON_STAGE_CONTENT: {
            return showOnStageContentDataWriter({
                airmeetId,
                client,
                metaData,
                payload,
                logger,
            });
        }

        case FEATURE_NAMES.STAGE_LAYOUT: {
            return stageLayoutWriter({ airmeetId, client, metaData, payload });
        }
        case FEATURE_NAMES.SPEAKER_QUESTION_MODERATION: {
            return speakerQuestionModerationToggle({
                airmeetId,
                client,
                metaData,
                payload,
                logger,
            });
        }

        case FEATURE_NAMES.STAGE_BANNER: {
            return stageBannerWriter({
                airmeetId,
                client,
                metaData,
                payload,
                logger,
                sessionId,
            });
        }
        case FEATURE_NAMES.SOCIAL_LOUNGE: {
            return socialLoungeWriter({
                airmeetId,
                client,
                metaData,
                payload,
                logger,
            });
        }
        case FEATURE_NAMES.USER_HEAR_BEAT: {
            return heartBeatWriter({
                airmeetId,
                client,
                metaData,
                payload,
            });
        }
        case FEATURE_NAMES.STREAM_PUBLISHER: {
            return streamPublisherWriter({
                client,
                payload,
                metaData,
                logger,
            });
        }

        default:
            break;
    }
};

export const useDataWriterEnabled = (
    featureName: string,
    config: DataWriterConfig
) => {
    config = useMemo(() => Object.assign({}, DEFAULT_CONFIG, config), [config]);
    const { featuresConfig, platformConfig } =
        useLiveAirmeetContext() || config.liveContextOverride;

    let dataWriterConfig =
        platformConfig &&
        platformConfig[ALL_FEATURES_CONFIG.DATA_WRITER_CONFIG];

    if (
        featuresConfig &&
        featuresConfig[ALL_FEATURES_CONFIG.DATA_WRITER_CONFIG]
    ) {
        dataWriterConfig =
            featuresConfig[ALL_FEATURES_CONFIG.DATA_WRITER_CONFIG];
    }

    const isFeatureDataWriterEnabled =
        !config.forceFrontendWrite &&
        (DEFAULT_ENABLED[featureName] ||
            (dataWriterConfig?.applyToAll as boolean) ||
            Object.keys(dataWriterConfig?.features || {}).includes(
                featureName
            ));
    return {
        isFeatureDataWriterEnabled,
        dataWriterConfig,
    };
};

export default function useDataWriter(
    featureName: string,
    config?: DataWriterConfig
) {
    config = useMemo(() => Object.assign({}, DEFAULT_CONFIG, config), [config]);
    const { sessionId: sessionIdFromConfig } = config;

    const { isMultipleEmoji } = useChatReactionMultipleEmojiFeatureFlag();

    const {
        firebaseClient,
        airmeet: {
            airmeetId,
            data: { currentSession, currentAirmeet },
        },
        user: { id: userId },
    } = useLiveAirmeetContext() || config.liveContextOverride;

    const { community_id } = currentAirmeet || EMPTY_OBJ;

    const sessionId = sessionIdFromConfig || currentSession?.sessionid;

    let { client } = useFeatureFirebaseClient(featureName, {
        liveContextOverride: config.liveContextOverride,
    });

    // this condition ensures correct read/writes for lounge table moderation.
    if (config.forceFrontendWrite) {
        client = firebaseClient;
    }

    const {
        isFeatureDataWriterEnabled: isDataWriterEnabled,
        dataWriterConfig,
    } = useDataWriterEnabled(featureName, config);

    let isInstanceRTSEnabled = false;

    if (
        currentAirmeet?.firebaseReplicas?.[FEATURE_NAMES.DEFAULT] &&
        currentAirmeet?.firebaseReplicas?.[FEATURE_NAMES.DEFAULT][0]
            .enableNewRTS === true
    ) {
        isInstanceRTSEnabled = true;
    } else {
        isInstanceRTSEnabled = dataWriterConfig?.enableNewRTS ? true : false;
    }

    useEffect(() => {
        logger.debug(
            `Data writer status for ${featureName}`,
            isDataWriterEnabled
        );
    }, [isDataWriterEnabled, featureName]);

    const serverFeatureName = getServerFeatureName(featureName);

    /*
        Attach listener on error channel
        Localnotify error of feature
    */

    const { observer: txnObserver } = useDataReader(FEATURE_NAMES.TXN_UPDATES, {
        liveContextOverride: config.liveContextOverride,
        targetFeature: featureName,
    });
    const pendingWrites = useRef(new Map<string, Message>());

    useEffect(() => {
        if (
            false === isDataWriterEnabled ||
            config.requiredTxnStatusConfirmation < WriteStatus.ERROR
        ) {
            return;
        }
        const onTxnUpdate = (message: Message) => {
            const socketMessage = pendingWrites.current.get(
                message.metadata.key
            );
            if (!socketMessage) {
                return;
            }
            let txnUpdate = message.payload;
            let txnStatus = txnUpdate.status;

            socketMessage.metadata.status = txnStatus;
            socketMessage.metadata.txnDetails = txnUpdate;

            LocalUpdates.getInstance().notifyStatusChange(
                featureName,
                socketMessage,
                txnStatus
            );
        };
        const errorHandlingUnsubscribe = txnObserver.subscribe(onTxnUpdate, {
            featureName,
        });

        return () => {
            errorHandlingUnsubscribe();
        };
    }, [
        config.requiredTxnStatusConfirmation,
        featureName,
        txnObserver,
        isDataWriterEnabled,
    ]);

    const attachSuccessFailureHandling = useCallback(
        ({ resolve, reject, socketMessage }) => {
            pendingWrites.current.set(
                socketMessage.metadata.key,
                socketMessage
            );

            const localUpdateHandler = (message) => {
                if (message.metadata?.key === socketMessage.metadata.key) {
                    const clear = () => {
                        unsubscribeLocalUpdates();
                        pendingWrites.current.delete(message.metadata.key);
                    };
                    if (message.metadata.status === WriteStatus.SUCCESS) {
                        clear();
                        resolve(message);
                    } else if (message.metadata.status === WriteStatus.ERROR) {
                        clear();
                        reject(message);
                    }
                }
            };

            const localUpdateSubscription = {
                onAdd: undefined,
                onChange: undefined,
            };

            if (config.optimisticUpdates) {
                localUpdateSubscription.onChange = localUpdateHandler;
            } else {
                localUpdateSubscription.onAdd = localUpdateHandler;
            }
            const unsubscribeLocalUpdates = LocalUpdates.getInstance().subscribe(
                featureName,
                localUpdateSubscription
            );
        },
        [featureName, config.optimisticUpdates]
    );

    const write = useCallback(
        async (payload, metaData = {}) => {
            try {
                metaData.userId = metaData?.userId || userId;
                metaData.communityId = community_id;
                metaData.userAgent = 'web';

                if (payload !== undefined) {
                    /*
                     When using backend writer with another service, reactions firebase client will be null, 
                     so we are using the object firebase sends internally to avoid using timestamp
                    */
                    if (isObject(payload) && !payload['.sv']) {
                        // payload should not be setting timestamp itself
                        payload = {
                            ...payload,
                            timestamp: {
                                '.sv': 'timestamp',
                            },
                        };
                    }
                }

                if (!isDataWriterEnabled) {
                    return pushDataToFirebase({
                        airmeetId,
                        sessionId,
                        featureName,
                        client,
                        payload,
                        metaData,
                        isMultipleEmoji,
                    });
                }

                metaData.key = uuid();

                const socketMessage: Message = {
                    payload,
                    metadata: {
                        ...metaData,
                        status: WriteStatus.PENDING,
                    },
                };
                LocalUpdates.getInstance().addPendingUpdate(
                    featureName,
                    socketMessage,
                    { emitLocal: config.optimisticUpdates }
                );

                let results: DataWriterResponse;
                const resultPromise = new Promise((resolve, reject) => {
                    if (
                        config.requiredTxnStatusConfirmation <
                        WriteStatus.SUCCESS
                    ) {
                        resolve(results);
                    } else {
                        attachSuccessFailureHandling({
                            resolve,
                            reject,
                            socketMessage,
                            requiredTxnStatusConfirmation:
                                config.requiredTxnStatusConfirmation,
                        });
                    }
                });

                const URL =
                    isInstanceRTSEnabled === true
                        ? REACT_APP_NEW_RTS_URL
                        : REACT_APP_DATA_WRITER_API_URL;

                const body = {
                    airmeetId,
                    sessionId,
                    featureName: serverFeatureName,
                    data: payload,
                    metaData,
                };

                let isRetryComplete = false;

                try {
                    results = await pushDataToApi(
                        {
                            endpoint: URL,
                            type: 'json',
                            method: 'POST',
                            body,
                            metaData: {
                                airmeetId,
                                userId: metaData.userId,
                            },
                        },
                        {
                            queueType: config.queueType,
                            useFrontendWriterFallback:
                                config.useFrontendWriterFallback,
                        }
                    );

                    if (!results.success) {
                        const error = {
                            message: `Server sent success false ${featureName}`,
                            txnID: metaData.key,
                        };
                        logger.error('Request failed', error);
                        LocalUpdates.getInstance().notifyStatusChange(
                            featureName,
                            socketMessage,
                            WriteStatus.ERROR
                        );
                        throw results;
                    }

                    socketMessage.metadata.serverRequestId =
                        results?.data?.requestId;
                    LocalUpdates.getInstance().notifyStatusChange(
                        featureName,
                        socketMessage,
                        WriteStatus.ACCEPTED
                    );

                    // TODO - Remove when contextual failure analysis is complete
                    if (featureName === FEATURE_NAMES.USER_PRESENCE) {
                        logger.info(
                            `Request Success for ${FEATURE_NAMES.USER_PRESENCE}`,
                            body?.metaData
                        );
                    }

                    if (
                        config.requiredTxnStatusConfirmation <
                        WriteStatus.SUCCESS
                    ) {
                        LocalUpdates.getInstance().clearPendingUpdate(
                            featureName,
                            socketMessage
                        );
                    }

                    return resultPromise;
                } catch (e) {
                    LocalUpdates.getInstance().notifyStatusChange(
                        featureName,
                        socketMessage,
                        WriteStatus.ERROR
                    );

                    logger.info(
                        `Scheduling Retry after - Frontend Writer ${FEATURE_NAMES.USER_PRESENCE} - ${metaData?.context}`
                    );

                    // Retry with different URL
                    if (isRetryComplete === false) {
                        isRetryComplete = true;

                        try {
                            results = await pushDataToApi(
                                {
                                    endpoint: REACT_APP_DATA_WRITER_API_URL,
                                    type: 'json',
                                    method: 'POST',
                                    body,
                                    metaData: {
                                        airmeetId,
                                        userId: metaData.userId,
                                    },
                                },
                                {
                                    queueType: QueueType.NONE,
                                    useFrontendWriterFallback: true,
                                }
                            );
                            return resultPromise;
                        } catch (err) {
                            logger.error(
                                'Writer API Retry Failed, Pushing via frontend writer',
                                err
                            );
                            if (featureName === FEATURE_NAMES.USER_PRESENCE) {
                                logger.info(
                                    'data writer call failed for presence',
                                    {
                                        airmeetId: airmeetId,
                                        sessionId: sessionId,
                                        payload: payload,
                                        metaData: metaData,
                                    }
                                );
                            }
                            return pushDataToFirebase({
                                airmeetId,
                                sessionId,
                                featureName,
                                client,
                                payload,
                                metaData,
                                isMultipleEmoji,
                            });
                        }
                    }

                    if (config.useFrontendWriterFallback) {
                        let logSender =
                            e && Object.keys(e).length > 0
                                ? logger.error.bind(logger)
                                : logger.info.bind(logger);
                        logSender(
                            `switching to frontend writer for feature ${featureName}`,
                            { error: e, txnID: metaData.key }
                        );

                        return pushDataToFirebase({
                            airmeetId,
                            sessionId,
                            featureName,
                            client,
                            payload,
                            metaData,
                            isMultipleEmoji,
                        });
                    } else {
                        logger.error(
                            `API call failed from backend ${featureName}`,
                            { error: e, txnID: metaData.key }
                        );
                    }
                }
            } catch (e) {
                logger.error(`Failed to write data ${featureName}`, {
                    error: e,
                });
                return Promise.reject(e);
            }
        },
        [
            airmeetId,
            featureName,
            isDataWriterEnabled,
            sessionId,
            client,
            serverFeatureName,
            userId,
            community_id,
            config.optimisticUpdates,
            config.requiredTxnStatusConfirmation,
            attachSuccessFailureHandling,
            config.queueType,
            config.useFrontendWriterFallback,
            isMultipleEmoji,
            isInstanceRTSEnabled,
        ] // never make config a dep here
    );

    return {
        write,
        isDataWriterEnabled,
    };
}

function getServerFeatureName(featureName) {
    let serverFeatureName = featureName;
    switch (featureName) {
        case FEATURE_NAMES.EVENT_CHAT: {
            serverFeatureName = SERVER_FEATURE_NAME.EVENT_CHAT;
            break;
        }
        case FEATURE_NAMES.SESSION_CHAT: {
            serverFeatureName = SERVER_FEATURE_NAME.SESSION_CHAT;
            break;
        }
        case FEATURE_NAMES.CHAT_REACTION: {
            serverFeatureName = SERVER_FEATURE_NAME.CHAT_REACTION;
            break;
        }
        case FEATURE_NAMES.SOCIAL_LOUNGE: {
            serverFeatureName = SERVER_FEATURE_NAME.SOCIAL_LOUNGE;
            break;
        }
        case FEATURE_NAMES.QUESTIONS: {
            serverFeatureName = SERVER_FEATURE_NAME.QUESTIONS;
            break;
        }
        case FEATURE_NAMES.QNACOMMENTS: {
            serverFeatureName = SERVER_FEATURE_NAME.QNACOMMENTS;
            break;
        }
    }
    return serverFeatureName;
}
