import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    startSessionRecording,
    stopSessionRecording,
    stopSessionRecordingAPICall,
    updateLayoutSessionRecording,
} from 'store/actions/airmeet';
import { useUserData } from 'store/selectors/users';
import {
    isCustomMediaStream,
    isScreenShareStream,
} from 'utils/constants/live-airmeet';
import { logger } from 'utils/logger';
import PermissionUtils from 'utils/permission-utils';
import useEventSessionStop from './useEventSessionStop';
import useRecordingHandler from './useRecordingHandler';

const printLog = logger.init('SessionRecording', 'red');
let updateLayoutRequestTimeout;

const recordingSelector = (state) => state.airmeetRecorder.recording;

export const attachAccessCodeToLiveStreamingURL = (urlString, params = {}) => {
    const url = new URL(urlString);
    if (params.code) {
        url.searchParams.set('code', params.code);
    }
    if (process.env.REACT_APP_ENV !== 'production') {
        url.searchParams.set('utf', process.env.REACT_APP_SERVER_BASE_URL);
    }
    if (params.recording) {
        url.searchParams.set('recording', 1);
    }
    return url.toString();
};

function useSessionRecording({
    airmeet,
    currentSession,
    stageService,
    isLive,
    layoutInfo,
    isMounted,
    hideUserStreams,
    allowRecording = false,
}) {
    const dispatch = useDispatch();
    const {
        enable,
        enableOptions = {},
        setRecStartStatus,
    } = useRecordingHandler();
    const recording = useSelector(recordingSelector);

    const layoutInfoRef = useRef(layoutInfo);
    const { sessionid, status, type } = currentSession || {};
    const airmeetId = airmeet.airmeetId;
    const updateReqParamsRef = useRef({});

    useEventSessionStop({
        airmeetId,
        session: currentSession,
        onSessionStop: (content) => {
            if (PermissionUtils.canSendRecordingUpdates()) {
                stopRecording();
            }
        },
    });

    const canStartRecording =
        isLive &&
        enable &&
        enableOptions.auto === enable &&
        PermissionUtils.canSendRecordingUpdates() &&
        status === 'ONGOING' &&
        allowRecording &&
        isMounted;

    useEffect(() => {
        if (
            canStartRecording &&
            (!recording || recording.sessionId !== sessionid)
        ) {
            const startRecording = () => {
                printLog('recording start request');
                dispatch(startSessionRecording(sessionid)).then(
                    ({ payload: recording }) => {
                        if (
                            recording &&
                            recording.sessionId &&
                            recording.sessionId === sessionid
                        ) {
                            logger.info(
                                `Session recording started for sessionId : ${sessionid}`
                            );
                            setRecStartStatus();
                        }
                    }
                );
            };

            startRecording();
        }
    }, [canStartRecording]);

    const cloudUser = useUserData(currentSession?.cloud_user_id);

    const updatedRecordingLayout = async (streamsLayoutInfo) => {
        if (type === 'STREAMING') {
            return;
        }
        if (!(streamsLayoutInfo && streamsLayoutInfo.layoutType)) {
            return;
        }
        layoutInfoRef.current = streamsLayoutInfo;

        const {
            // maxResolutionUid,
            layoutType,
            noOfStreams,
            mainStreamUids,
            smallStreamUids,
        } = streamsLayoutInfo;

        const [maxResolutionUid] =
            mainStreamUids && mainStreamUids.length > 0 ? mainStreamUids : [0];

        if (!isLive || noOfStreams === 0) {
            return;
        }

        if (recording && recording.sessionId) {
            const excludeStreamsUid = [];
            if (currentSession && cloudUser) {
                excludeStreamsUid.push(parseInt(cloudUser.id_seq));
            }

            stageService.getStreams().forEach((stream) => {
                const id = stream.getId();

                const cloudHostIdForSession = parseInt(cloudUser?.id_seq);
                if (
                    false === isNaN(cloudHostIdForSession) &&
                    !excludeStreamsUid.includes(cloudHostIdForSession)
                ) {
                    excludeStreamsUid.push(cloudHostIdForSession);
                }

                if (
                    hideUserStreams &&
                    !isScreenShareStream(id) &&
                    !isCustomMediaStream(id) &&
                    !excludeStreamsUid.includes(id)
                ) {
                    excludeStreamsUid.push(parseInt(id));
                }
            });

            printLog(`update layout request`, streamsLayoutInfo);
            const updateReqParams = {
                airmeetId: airmeet.airmeetId,
                sessionId: sessionid,
                maxResolutionUid,
                layoutType,
                noOfStreams,
                excludeStreamsUid,
                mainStreamUids,
                smallStreamUids,
            };

            if (
                updateReqParamsRef.current !== JSON.stringify(updateReqParams)
            ) {
                updateReqParamsRef.current = JSON.stringify(updateReqParams);
                printLog(
                    `update layout request params`,
                    updateReqParamsRef.current
                );
                updateLayoutRequestTimeout &&
                    clearTimeout(updateLayoutRequestTimeout);
                updateLayoutRequestTimeout = setTimeout(() => {
                    dispatch(
                        updateLayoutSessionRecording(
                            JSON.parse(updateReqParamsRef.current)
                        )
                    ).then(({ payload }) => {
                        printLog(`updated layout`, updateReqParamsRef.current);
                    });
                }, 50);
            }
        }
    };

    const recordingSessionId = recording ? recording.sessionId : null;
    const canUpdate = PermissionUtils.canSendRecordingUpdates();
    useEffect(() => {
        if (recordingSessionId === sessionid && canUpdate) {
            updatedRecordingLayout(layoutInfoRef.current);
        }
    }, [recordingSessionId, canUpdate, hideUserStreams]);

    // useEffect(() => {
    //     if (recordingSessionId === sessionid) {
    //         updatedRecordingLayout(layoutInfoRef.current);
    //     }
    // }, [hideUserStreams]);

    const stopRecording = (callApi = false) => {
        const canAllowRecording =
            enable &&
            enableOptions.auto === enable &&
            PermissionUtils.canSendRecordingUpdates();

        if (canAllowRecording && recording && recordingSessionId) {
            dispatch(
                stopSessionRecording({
                    //   ...recording,
                    sessionId: sessionid,
                })
            );
            printLog(`stop request`);
            logger.info(
                `Session recording stopped for sessionId : ${sessionid}`
            );
            if (callApi) {
                dispatch(
                    stopSessionRecordingAPICall({
                        //   ...recording,
                        sessionId: sessionid,
                    })
                );
            }
        }
    };

    useEffect(() => {
        if (
            !allowRecording &&
            isMounted &&
            sessionid &&
            recordingSessionId === sessionid
        ) {
            logger.info(
                `Session recording stopped for sessionId in mid of session live : ${sessionid}`
            );
            stopRecording();
        }
    }, [allowRecording, isMounted, recordingSessionId, sessionid]);

    return { updatedRecordingLayout, stopRecording };
}

export default useSessionRecording;
