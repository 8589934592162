import * as D from '@radix-ui/react-dialog';
import Btn from 'atoms/Button/btn';
import FocusTrap from 'atoms/FocusTrap';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import {
    FlexDiv,
    accessibilityStyles,
} from 'components/molecules/utils/basicComponents';
import { Text, commonStyles } from 'foundations/themeV2/text';
import { getZIndex } from 'foundations/themeV2/zIndex';
import useTheme from 'hooks/useTheme';
import React from 'react';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';

export default function BaseModalV2({
    title = '',
    subtitle = '',
    description = '',
    primaryText = '',
    secondaryText = '',
    onPrimaryClick = noop,
    onSecondaryClick = noop,
    onClose = noop,
    children = null,
    footer = null,
    isBgClose = false,
    width = '90vw',
    maxWidth = '450px',
    maxHeight = '85vh',
    imgSrc = '',
    imgAlt = '',
    hideClose = false,
    imgWidth = '160',
    imgHeight = 'auto',
    borderRadius = null,
    border = null,
    top = '50%',
    primaryBtnStyles = {},
    secondaryBtnStyles = {},
    contentStyle = {},
    primaryBtnProps = {},
    focusTrapOptions = {},
}) {
    const { theme } = useTheme();

    return (
        <D.Root open={true} className='DialogRoot'>
            <D.Portal className='DialogPortal'>
                <FocusTrap focusTrapOptions={focusTrapOptions}>
                    <div>
                        <Overlay
                            className='DialogOverlay'
                            onClick={isBgClose ? onClose : noop}
                        />
                        <Content
                            className='DialogContent'
                            width={width}
                            maxWidth={maxWidth}
                            maxHeight={maxHeight}
                            style={contentStyle}
                            borderRadius={borderRadius ?? theme.radii.default}
                            border={
                                border ??
                                `1px solid ${theme.colors.border.default1}`
                            }
                            top={top}
                        >
                            {imgSrc ? (
                                <ImgWrapper className='DialogImgWrapper'>
                                    <img
                                        className='DialogImg'
                                        src={imgSrc}
                                        alt={imgAlt || title}
                                        width={imgWidth}
                                        height={imgHeight}
                                    />
                                </ImgWrapper>
                            ) : null}
                            {title ? (
                                <Title className='DialogTitle'>{title}</Title>
                            ) : null}
                            {subtitle ? (
                                <Text
                                    variant='body1'
                                    color='text.default.secondary'
                                    className='DialogSubtitle'
                                >
                                    {subtitle}
                                </Text>
                            ) : null}
                            {description ? (
                                <Description
                                    className='DialogDescription'
                                    variant='body2'
                                >
                                    {description}
                                </Description>
                            ) : null}
                            {hideClose ? null : (
                                <D.Close asChild className='DialogClose'>
                                    <Close
                                        className='DialogCloseButton'
                                        aria-label='Close dialog'
                                        onClick={onClose}
                                    >
                                        <StyledSvgSprite
                                            icon='icon-times'
                                            height='1.5rem'
                                            width='1.5rem'
                                            fill='text.default.primary'
                                        />
                                    </Close>
                                </D.Close>
                            )}
                            {children ? children : null}
                            {footer || primaryText || secondaryText ? (
                                <Footer className='DialogFooter'>
                                    {footer ? (
                                        footer
                                    ) : (
                                        <>
                                            {primaryText ? (
                                                <Btn
                                                    variant='primary'
                                                    onClick={onPrimaryClick}
                                                    className='DialogFooterPrimaryBtn'
                                                    flex='1'
                                                    style={primaryBtnStyles}
                                                    {...(primaryBtnProps || {})}
                                                >
                                                    {primaryText}
                                                </Btn>
                                            ) : null}
                                            {secondaryText ? (
                                                <Btn
                                                    variant='secondary'
                                                    ml={primaryText ? 'x4' : ''}
                                                    onClick={
                                                        onSecondaryClick ||
                                                        onClose
                                                    }
                                                    className='DialogFooterSecondaryBtn'
                                                    flex='1'
                                                    style={secondaryBtnStyles}
                                                >
                                                    {secondaryText}
                                                </Btn>
                                            ) : null}
                                        </>
                                    )}
                                </Footer>
                            ) : null}
                        </Content>
                    </div>
                </FocusTrap>
            </D.Portal>
        </D.Root>
    );
}

const Overlay = styled(D.Overlay)`
    background-color: ${({ theme }) => theme.overlay.bgColor};
    position: fixed;
    inset: 0;
    animation: overlayShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: ${({ theme }) =>
        getZIndex({
            zIndexType: theme.zIndices.types.below,
            zIndexRange: theme.zIndices.ranges.xl,
        })};

    @keyframes overlayShow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const Content = styled(D.Content)`
    background-color: ${({ theme }) => theme.colors.fill.default1};
    border-radius: ${({ borderRadius }) => borderRadius};
    border: ${({ border }) => border};
    padding: ${({ theme }) => theme.space.x7};
    position: fixed;
    top: ${({ top }) => top};
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${({ width }) => width};
    max-width: ${({ maxWidth }) => maxWidth};
    max-height: ${({ maxHeight }) => maxHeight};
    animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    &:focus {
        outline: none;
    }
    z-index: ${({ theme }) =>
        getZIndex({
            zIndexType: theme.zIndices.types.above,
            zIndexRange: theme.zIndices.ranges.xl,
        })};

    @keyframes contentShow {
        from {
            opacity: 0;
            transform: translate(-50%, -48%) scale(0.96);
        }
        to {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }
`;

const Title = styled(D.Title)`
    ${commonStyles}
    font-size: ${({ theme }) => theme.fontSizes.x4};
    line-height: ${({ theme }) => theme.lineHeights.x4};
    padding-bottom: ${({ theme }) => theme.space.x3};
    color: ${({ theme }) => theme.colors.text.default.primary};
`;

const Description = styled(D.Description)`
    ${commonStyles}
    font-size: ${({ theme }) => theme.fontSizes.x3};
    line-height: ${({ theme }) => theme.lineHeights.x3};
    color: ${({ theme }) => theme.colors.text.default.primary};
    padding-top: ${({ theme }) => theme.space.x7};
`;

const Footer = styled(FlexDiv)`
    align-items: center;
    padding-top: ${({ theme }) => theme.space.x6};
`;

const ImgWrapper = styled(FlexDiv)`
    align-items: center;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.space.x5};
`;

const Close = styled.button`
    all: unset;
    position: absolute;
    top: ${({ theme }) => theme.space.x7};
    right: ${({ theme }) => theme.space.x7};
    ${accessibilityStyles()}
`;
