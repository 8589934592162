import { cdnImage } from 'utils/core';
import { getCurrentTenantData } from 'utils/tenant';
import { IS_CUSTOM_TENANT } from '../tenant';

export const EVENTBRITE = 'EVENTBRITE';
export const MAILCHIMP = 'MAILCHIMP';
export const EVENTBRITE_DESCRIPTION_AIRMEET = `It allows to synchronize newer registration from eventbrite with ${getCurrentTenantData(
    'name'
)} registrants`;
export const SALESFORCE_REDIRECT = 'salesforce';
export const MAILCHIMP_DESCRIPTION_AIRMEET = `It allows to push ${getCurrentTenantData(
    'name'
)} registrants to your mailchimp audience mailing list`;
export const HUBSPOT = 'HUBSPOT';
export const FACEBOOK_PIXEL = 'FACEBOOK PIXEL';
export const HUBSPOT_DESCRIPTION_AIRMEET = `It allows to synchronize newer registration from hubspot with ${getCurrentTenantData(
    'name'
)} registrants`;
export const MARKETO = 'MARKETO';
export const MARKETO_DESCRIPTION_AIRMEET = `It allows to synchronize new leads from Marketo with ${getCurrentTenantData(
    'name'
)} registrants.`;
export const ZAPIER = 'ZAPIER';
export const SALESFORCE = 'SALESFORCE';
export const INTERPREFY = 'INTERPREFY';
export const SALESMATE = 'SALESMATE';
export const ACTIVECAMPAIGN = 'ACTIVECAMPAIGN';
export const SLACK = 'SLACK';
export const TWITTER = 'TWITTER';
export const DRIP = 'DRIP';
export const MAILJET = 'MAILJET';
export const GOOGLE_SHEET = 'GOOGLE SHEET';
export const SURVEY_MONKEY = 'SURVEYMONKEY';
export const PIPEDRIVE = 'PIPEDRIVE';
export const PAYPAL = 'PAYPAL';
export const SALESFORCE_PARDOT = 'PARDOT';
export const KEAP = 'KEAP';

export const FACEBOOK_LEAD_ADS = 'FACEBOOK LEAD ADS';
export const LINKEDIN_LEAD_GEN_FORMS = 'LINKEDIN LEAD GEN FORMS';
export const ACCREDIBLE_CERTIFICATES = 'ACCREDIBLE CERTIFICATES';
export const BADGR = 'BADGR';
export const CREDLY = 'CREDLY';
export const AWEBER = 'AWEBER';
export const CUSTOMER_IO = 'CUSTOMER.IO';
export const GETRESPONSE = 'GETRESPONSE';
export const SHARPSPRING = 'SHARPSPRING';
export const INSTAPAGE = 'INSTAPAGE';
export const JOTFORM = 'JOTFORM';
export const UNBOUNCE = 'UNBOUNCE';

export const MICROSOFT_DYNAMICS = 'MICROSOFT DYNAMICS';
export const GOOGLE_TAG_MANAGER = 'GOOGLE TAG MANAGER';
export const POWER_AUTOMATE = 'POWER AUTOMATE';
export const ORACLE_ELOQUA = 'ELOQUA';
export const MICROSOFT_DYNAMICS_365 = 'MICROSOFT DYNAMICS 365';
export const SENDOSO = 'SENDOSO';
export const PARDOT = 'PARDOT';
export const MAILERLITE = 'MAILERLITE';

// CTA options
export const CONNECT = 'CONNECT';
export const UPGRADE = 'UPGRADE';
export const CONTACT = 'CONTACT US';
export const AIRMEET_LOGO = IS_CUSTOM_TENANT
    ? cdnImage('images/integrations/zapier.png')
    : cdnImage('images/integrations/airmeet.png');

// Integrations App Connector
export const INTEGRATION_APP_CONNECTORS = [];

export const SHOW_RESYNC_BUTTON = ['hubspot', 'marketo', 'salesforce'];
// Enable Deeper integration
export const REGISTRATION_PATH = '/images/integrations/shape.png';
export const ENABLE_DEEPER_INTEGRATIONS = [
    ACTIVECAMPAIGN,
    PARDOT,
    'SALESFORCE-PARDOT',
];
// Enable integration button
export const ENABLE_EDIT_BUTTON = [
    'HubSpot',
    'Marketo',
    'Microsoft Dynamics 365',
    'Pipedrive',
    'ActiveCampaign',
    'Keap',
    'Sendoso',
    'Slack',
    'Pardot',
    'pardot',
    'MailerLite',
    'Salesforce',
];

export const COBALT_CONNECTOR = [
    SLACK,
    'MAILERLITE',
    'PIPEDRIVE',
    'ACTIVECAMPAIGN',
    'KEAP',
];

export const INTEGRATIONS_RE_AUTH_POSSIBLE = ['HUBSPOT', 'PARDOT'];

export const INTEGRATION_APP_CHANGED_NAME = {
    pardot: 'salesforce-pardot',
};
export const GET_NAME_FROM_INTEGRATION_NAME = {
    pardot: 'SalesForce Pardot',
};

export const Kb_url = {
    HUBSPOT:
        'https://help.airmeet.com/support/solutions/articles/82000879104-integrate-airmeet-with-hubspot-application-v2',
    MARKETO:
        'https://help.airmeet.com/support/solutions/articles/82000672901-integrate-airmeet-with-marketo',
    SALESFORCE:
        'https://help.airmeet.com/support/solutions/articles/82000611861-integrate-airmeet-with-salesforce',
};

export const integrationText = {
    [EVENTBRITE]: {
        name: 'Eventbrite',
        connectModalTitle: 'Connect Eventbrite',
        disconnectModalTitle: 'Disconnect Eventbrite',
        connectModalSubtitle:
            'Add or update registrant list from eventbrite based on your event activity',
        disconnectModalSubtitle: `Disconnect your eventbrite account from ${getCurrentTenantData(
            'name'
        )}.`,
        howToConfig: [
            'Enter the Eventbrite Private Token to connect with your Eventbrite account.',
            `Visit the ${getCurrentTenantData(
                'name'
            )} Integrations page and enable Eventbrite.`,
            `Select the event you wish to connect to that ${getCurrentTenantData(
                'name'
            )}, and you're set.`,
        ],
        notes: [
            'You will no longer recieve any data configured with Eventbrite once you disconnect the same.',
        ],
        basicInfo: `Easily sync Eventbrite event registrations to an ${getCurrentTenantData(
            'name'
        )}. Select the event whose participants you'd like to include in an ${getCurrentTenantData(
            'name'
        )}, and then all future participants will be added to ${getCurrentTenantData(
            'name'
        )} automatically.`,
        features: `Only Name, Email, Organization, and Designation of the participants will be added to ${getCurrentTenantData(
            'name'
        )}. Custom Fields cannot be added.`,
        detailPageNotes: [
            `Participants registered on Eventbrite before enabling this integration for the event will not be added automatically. You have to export from Eventbrite and upload a CSV file in ${getCurrentTenantData(
                'name'
            )} to add participants.`,
            `Do ensure that the ${getCurrentTenantData(
                'name'
            )} Entry Rules are set as desired. Select /'Only invited participants' if you want to limit access to participants added from Eventbrite.`,
            `${getCurrentTenantData(
                'name'
            )} sends an email to participants on registration and another email when the event is about to start. If you wish to use Eventbrite to send emails to your participants, you can email <a href="mailto:support@airmeet.com" target="_blank">support@airmeet.com</a> to disable participant emails on registration and event reminders.`,
        ],
        knowledge_base: `Visit our <a href="https://help.airmeet.com/support/solutions/articles/82000518377-integrate-airmeet-with-eventbrite" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        knowledgeBase:
            'https://help.airmeet.com/support/solutions/articles/82000518377-integrate-airmeet-with-eventbrite',
    },
    [MAILCHIMP]: {
        name: 'Mailchimp',
        connectModalTitle: 'Connect Mailchimp',
        disconnectModalTitle: 'Disconnect Mailchimp ',
        connectModalSubtitle:
            'Add or update registrant list from mailchimp based on your event activity',
        disconnectModalSubtitle: `Disconnect your mailchimp account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfig: [
            'Connect with your Mailchimp account.',
            `Visit the ${getCurrentTenantData(
                'name'
            )} Integrations page and enable Mailchimp.`,
            `Select the audience you wish to connect to that ${getCurrentTenantData(
                'name'
            )}, and you're set.`,
            'Sync the participant’s direct access link to your contact.',
            'Select the additional registration fields to sync with Mailchimp.',
        ],
        notes: [
            'You will no longer recieve any data configured with Mailchimp once you disconnect the same.',
        ],
        basicInfo: `Seamlessly sync your participants with Mailchimp Easily sync ${getCurrentTenantData(
            'name'
        )} event registrations to a Mailchimp Audience. Select the audience where you'd like to include your ${getCurrentTenantData(
            'name'
        )} registrations, and then all future registrations will be added to the Mailchimp Audience automatically. The contacts added to Mailchimp will be tagged with your event name. The participant’s direct access link will also be added to a MERGE field.`,
        features: `Only the Name and Email of the participant will be added to ${getCurrentTenantData(
            'name'
        )}. Custom Fields will not be added.`,
        detailPageNotes: [
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added to Mailchimp automatically. You have to export from ${getCurrentTenantData(
                'name'
            )} and upload those participants in Mailchimp.`,
            `${getCurrentTenantData(
                'name'
            )} sends an email to participants on registration and another email when the event is about to start. If you wish to use Mailchimp to send emails to your participants, you can email <a href="mailto:support@airmeet.com" target="_blank">support@airmeet.com</a> to disable participant emails on registration and event reminders.`,
        ],
        knowledge_base: `Visit our <a href="https://help.airmeet.com/support/solutions/articles/82000611860-integrate-airmeet-with-mailchimp" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        knowledgeBase:
            'https://help.airmeet.com/support/solutions/articles/82000611860-integrate-airmeet-with-mailchimp',
    },
    [HUBSPOT]: {
        name: 'HubSpot',
        apiId: 'hubspot',
        connectModalTitle: 'Connect HubSpot',
        disconnectModalTitle: 'Disconnect HubSpot',
        connectModalSubtitle:
            'Add or update registrant list from hubspot based on your event activity',
        disconnectModalSubtitle: `Disconnect your hubspot account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfig: [
            'Connect with your HubSpot account',
            `Visit the ${getCurrentTenantData(
                'name'
            )} Integrations page and enable HubSpot`,
            `Create a workflow in HubSpot using the Custom ${getCurrentTenantData(
                'name'
            )} Action`,
        ],
        notes: [
            `Contacts in HubSpot can be filtered based on the ${getCurrentTenantData(
                'name'
            )} Name property. Direct Access Link for the event will be added to the contact properties in HubSpot`,
        ],
        basicInfo: `Easily sync HubSpot contacts with your ${getCurrentTenantData(
            'name'
        )} event. Create a custom workflow within HubSpot to enroll contacts to an ${getCurrentTenantData(
            'name'
        )} using the Custom ${getCurrentTenantData(
            'name'
        )} Action. Contacts enrolled using the workflow will be registered for the ${getCurrentTenantData(
            'name'
        )} event.`,
        features: `Only the Name and Email of the participant will be added to ${getCurrentTenantData(
            'name'
        )}. Custom Fields will not be added.`,
        detailPageNotes: [
            `Contacts in HubSpot can be filtered based on the ${getCurrentTenantData(
                'name'
            )} Name property. Direct Access Link for the event will be added to the contact properties in HubSpot.`,
        ],
        knowledge_base: `Visit our <a href="https://help.airmeet.com/support/solutions/articles/82000879104-integrate-airmeet-with-hubspot-application-v2" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        knowledgeBase:
            'https://help.airmeet.com/support/solutions/articles/82000879104-integrate-airmeet-with-hubspot-application-v2',
    },
    [MARKETO]: {
        name: 'Marketo',
        apiId: 'marketo',
        connectModalTitle: 'Connect Marketo',
        disconnectModalTitle: 'Disconnect Marketo',
        connectModalSubtitle:
            'Add or update registrant list from marketo based on your event activity',
        disconnectModalSubtitle: `Disconnect your Marketo account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfigDesc:
            'Connect with your Marketo account using the Client ID, Client Secret and REST API Endpoint URL.',
        howToConfig: [
            `Visit the ${getCurrentTenantData(
                'name'
            )} Integrations page and enable Marketo.`,
            'Click on Save to enable the integration',
            'Create a webhook in Marketo and copy the Webhook url for the event.',
        ],
        notes: [
            'You will no longer recieve any data configured with Marketo once you disconnect the same.',
        ],
        basicInfo: `Easily sync Marketo leads with your ${getCurrentTenantData(
            'name'
        )} event as participants. Use the event webhook URL provided to create a webhook inside Marketo. Contacts enrolled using the webhook will be registered for the ${getCurrentTenantData(
            'name'
        )} event.`,
        detailPageNotes: [
            `To push event participants data directly from ${getCurrentTenantData(
                'name'
            )} to your Marketo lead list, Use this Zap template via our Zapier integration.`,
        ],
        features: `Only the Name and Email of the leads from the Marketo list will be added to the participant profile in ${getCurrentTenantData(
            'name'
        )}. Custom Fields will not be added.`,
        knowledge_base: `Visit our <a href="https://help.airmeet.com/support/solutions/articles/82000672901-integrate-airmeet-with-marketo" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        knowledgeBase:
            'https://help.airmeet.com/support/solutions/articles/82000672901-integrate-airmeet-with-marketo',
    },
    [ZAPIER]: {
        name: 'Zapier',
        connectModalTitle: 'Connect Zapier',
        disconnectModalTitle: 'Disconnect Zapier',
        connectModalSubtitle:
            'Add or update registrant list from zapier based on your event activity',
        disconnectModalSubtitle: `Disconnect your Zapier account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfig: [
            'On your browser, search for "zapier.com"',
            `Sign up with the same email/social media you have used for ${getCurrentTenantData(
                'name'
            )}.`,
            `Connect your Zapier account with ${getCurrentTenantData(
                'name'
            )} Organizer Account.`,
        ],
        notes: [
            'You will no longer recieve any data configured with zapier once you disconnect the same.',
        ],
        detailPageNotes: [
            `Organizer need to provide  Access key and Secret key at the time of Integration. This can be generated from API access key tab under Integrations from ${getCurrentTenantData(
                'name'
            )}.`,
        ],
        basicInfo: `Zapier is for users who want to update the google sheet attendee list in ${getCurrentTenantData(
            'name'
        )} or integrate with Email marketing services such as MailChimp and HubSpot. Once the Zapier account is co-joined with the ${getCurrentTenantData(
            'name'
        )} account, all the data in the google sheet will be updated automatically in ${getCurrentTenantData(
            'name'
        )}.`,
        features: `Only the Name and Email of the participant will be added to ${getCurrentTenantData(
            'name'
        )}. Custom Fields will not be added.`,
        knowledge_base: `Visit our <a href="https://help.airmeet.com/support/solutions/articles/82000444114-zapier-v2-integrate-sync-multiple-platform-applications-on-airmeet" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        knowledgeBase:
            'https://help.airmeet.com/support/solutions/articles/82000444114-zapier-v2-integrate-sync-multiple-platform-applications-on-airmeet',
    },
    [SALESFORCE]: {
        name: 'Salesforce',
        connectModalTitle: 'Connect Salesforce',
        disconnectModalTitle: 'Disconnect Salesforce',
        connectModalSubtitle:
            'Add or update registrant list from Salesforce based on your event activity',
        disconnectModalSubtitle: `Disconnect your Salesforce account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfig: [
            'Click on Connect to install the salesforce application.',
            'While installing the application kindly log in to the Salesforce dev account on another browser tab simultaneously.',
            'Log in to your Salesforce developer account if not already created and proceed further.',
            'Click on the Install button and provide relevant permissions.',
        ],
        notes: [
            'You will no longer recieve any data configured with Salesforce once you disconnect the same.',
        ],
        basicInfo: `Get a holistic view of attendee records by syncing registration details between ${getCurrentTenantData(
            'name'
        )} and Salesforce. Accelerate sales pipeline with targeted post-event campaigns and lead nurture programs.`,
        detailPageNotes: [
            'It is recommended to keep the event entry set to Only invited participants Event dashboard > Event entry & Participants tab > Edits > Only invited participants.',
        ],
        features: `Only the Name and Email of the leads from the Salesforce list will be added to the participant profile in ${getCurrentTenantData(
            'name'
        )}.`,
        knowledge_base: `Visit our <a href="https://help.airmeet.com/support/solutions/articles/82000611861-integrate-airmeet-with-salesforce" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        knowledgeBase:
            'https://help.airmeet.com/support/solutions/articles/82000611861-integrate-airmeet-with-salesforce',
    },
    [INTERPREFY]: {
        name: 'Interprefy',
        connectModalTitle: 'Connect Interprefy',
        disconnectModalTitle: 'Disconnect Interprefy',
        connectModalSubtitle:
            'Add or update registrant list from Interprefy based on your event activity',
        disconnectModalSubtitle: `Disconnect your Interprefy account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfig: [
            'Fill in the  form with your requirements which then will be sent to the Interprefy which you get on clicking connect.',
            'After purchasing the licenses from interprefy, they will issue you a private token key that needs to be entered for that particular session on the event dashboard.',
            'The event organizer can test the interprefy functionality backstage before the event opens via preview mode or during the live event.',
        ],
        notes: [
            'You will no longer recieve any data configured with interprefy once you disconnect the same.',
        ],
        basicInfo:
            'Interprefy offers real-time translation solutions to provide a truly multilingual experience for virtual events participants. In addition, it opens the possibility for event organizers to break the language barriers and reaches a global audience.',
        detailPageNotes: [
            'Participants must mute the session’s original audio to hear the translation (if they do not, they will get both the original audio and the translated audio over the top of each other).',
            `Interprefy doesn't work for RTMP-based sessions (Stream into ${getCurrentTenantData(
                'name'
            )})`,
            'Interprefy is not supported in Lite mode for participants.',
            `Currently, Interprefy is not supported in ${getCurrentTenantData(
                'name'
            )} mobile browser version & on mobile applications.`,
        ],
        knowledge_base: `Visit our <a href="https://help.airmeet.com/support/solutions/articles/82000660138-set-up-multi-language-interpretation-on-airmeet-via-interprefy" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        knowledgeBase:
            'https://help.airmeet.com/support/solutions/articles/82000660138-set-up-multi-language-interpretation-on-airmeet-via-interprefy',
    },
    [ACTIVECAMPAIGN]: {
        name: 'ActiveCampaign',
        connectModalTitle: 'Connect ActiveCampaign',
        disconnectModalTitle: 'Disconnect ActiveCampaign',
        connectModalSubtitle:
            'Add or update registrant list from ActiveCampaign based on your event activity',
        disconnectModalSubtitle: `Disconnect your ActiveCampaign account from  ${getCurrentTenantData(
            'name'
        )}`,
        howToConfigDesc:
            'Connect with your ActiveCampaign account using the API URL and API Key.',
        howToConfig: [
            'Connect with your ActiveCampaign Account',
            'Visit the Airmeet Integrations page and enable ActiveCampaign to configure the sync settings',
        ],
        notes: [
            `Supports mapping of custom registration fields with ActiveCampaign contact fields`,
            `Contacts in ActiveCampaign can be filtered based on the ActiveCampaign filters. A direct Access Link for the event will be added to Contacts.`,
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically. You must export from ${getCurrentTenantData(
                'name'
            )} and upload a CSV file in ActiveCampaign to add those participants.`,
            `Disconnect ActiveCampaign and ${getCurrentTenantData(
                'name'
            )} accounts from the ${getCurrentTenantData(
                'name'
            )} community dashboard`,
        ],
        basicInfo: `Easily sync ActiveCampaign contacts with your ${getCurrentTenantData(
            'name'
        )} event as participants.`,
        detailPageNotes: [
            `Supports mapping of custom registration fields with ActiveCampaign contact fields`,
            `Contacts in ActiveCampaign can be filtered based on the ActiveCampaign filters. A direct Access Link for the event will be added to Contacts.`,
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically. You must export from ${getCurrentTenantData(
                'name'
            )} and upload a CSV file in ActiveCampaign to add those participants.`,
            `Disconnect ActiveCampaign and ${getCurrentTenantData(
                'name'
            )} accounts from the Airmeet community dashboard`,
        ],
        features: `Only the Name and Email of the contact from the ActiveCampaign list will be added to the participant profile in ${getCurrentTenantData(
            'name'
        )}. Custom Fields will not be added.`,
        knowledge_base: `Visit our <a href="https://help.airmeet.com/support/solutions/articles/82000895839-integrate-airmeet-with-activecampaign" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        imageUrl: '/images/integrations/activecampaign.svg',
        filterHeading: 'ActiveCampaign List',
        filterSubHeading: 'Select lists from your ActiveCampaign account',
        filterPath: '/data/contact-lists',
        knowledgeBase:
            'https://help.airmeet.com/support/solutions/articles/82000895839-integrate-airmeet-with-activecampaign',
    },
    [DRIP]: {
        name: 'Drip',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Drip',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with drip once you disconnect the same.',
        ],
        basicInfo: `This integration automatically adds all new registrants from ${getCurrentTenantData(
            'name'
        )} to your Drip account as subscribers.`,
        introSteps: {
            title: `Add new ${getCurrentTenantData(
                'name'
            )} registrants to Drip as subscribers`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Attendee Registered',
                description: 'When this happens',
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Create or Update Subscriber',
                description: 'Then do this',
            },
        },
    },
    [GOOGLE_SHEET]: {
        name: 'Google Sheet',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Google Spreadsheet',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with Google Spreadsheet once you disconnect the same.',
        ],
        basicInfo: `With this integration, add every new ${getCurrentTenantData(
            'name'
        )} registration to a Google Sheets row.`,
        introSteps: {
            title: `Create Google Sheets rows for new ${getCurrentTenantData(
                'name'
            )} registrations`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Attendee Registered',
                description: 'When this happens',
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Create Spreadsheet Row',
                description: 'Then do this',
            },
        },
    },
    [MAILJET]: {
        name: 'Mailjet',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Mailjet',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with Mailjet once you disconnect the same.',
        ],
        basicInfo: `With this integration, Zapier will automatically add each new registrant from ${getCurrentTenantData(
            'name'
        )} to your Mailjet list as a new subscriber. Grow your prospects effortlessly, with every new sign-up recorded for your particular event on ${getCurrentTenantData(
            'name'
        )}!`,
        introSteps: {
            title: `Add new registrants from ${getCurrentTenantData(
                'name'
            )} as Mailjet subscribers`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Attendee Registered',
                description: 'When this happens',
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Subscribe Contact to Mailjet List',
                description: 'Then do this',
            },
        },
    },
    [MICROSOFT_DYNAMICS]: {
        name: 'Microsoft Dynamics',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Microsoft Dynamics 365 CRM',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with Microsoft Dynamics once you disconnect the same.',
        ],
        basicInfo: `With this integration, Zapier will automatically add each new registrant from ${getCurrentTenantData(
            'name'
        )} to your Microsoft Dynamics list as a new subscriber. Grow your prospects effortlessly, with every new sign-up recorded for your particular event on ${getCurrentTenantData(
            'name'
        )}!`,
        introSteps: {
            title: `Add new registrants from ${getCurrentTenantData(
                'name'
            )} to Microsoft Dynamics 365 CRM as contacts`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Attendee Registered',
                description: 'When this happens',
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Create Contact',
                description: 'Then do this',
            },
        },
    },
    [SALESMATE]: {
        name: 'Salesmate',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Salesmate',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with Salesmate once you disconnect the same.',
        ],
        basicInfo: `With this integration, Zapier will automatically add all new registrants from ${getCurrentTenantData(
            'name'
        )} to your Salesmate account as new contacts. Grow your prospects effortlessly!`,
        introSteps: {
            title: `Add new ${getCurrentTenantData(
                'name'
            )} registrants to Salesmate as contacts`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Attendee Registered',
                description: 'When this happens',
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Create Contact',
                description: 'Then do this',
            },
        },
    },
    [SLACK]: {
        name: 'Slack',
        howToConfig: [
            'Connect with your Slack account',
            'Visit the Airmeet Integrations page and enable Slack to configure the settings',
        ],
        notes: [
            'You will no longer recieve any data configured with Slack once you disconnect the same.',
        ],
        basicInfo: `Keep everyone on your team in the loop about new registrations for your ${getCurrentTenantData(
            'name'
        )} events. This integration allows you to send notifications to Slack automatically as soon as a new registrant is added. You can also send notifications for event attendance, poll responses and questions asked during the event.`,
        features: 'Select a different Slack Channel for each notification type',
        'Event Attendance': {
            subtext:
                'Select the Slack Channel where you want to send event attendance messages',
        },
        detailPageNotes: [
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically.`,
            `Disconnect Slack and ${getCurrentTenantData(
                'name'
            )} accounts from the Airmeet community dashboard`,
        ],
        knowledge_base: `Visit our <a href="https://help.airmeet.com/en/support/solutions/articles/82000895836-integrate-airmeet-with-slack" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        'Poll Response': {
            subtext:
                'Select the Slack Channel where you want to send poll responses for the event',
        },
        'Question Asked': {
            subtext:
                'Select the Slack Channel where you want to send questions asked during the event',
        },
        'Booth Attendance': {
            subtext:
                'Select the Slack Channel where you want to send booth attendance messages',
        },
        'Session Attendance': {
            subtext:
                'Select the Slack Channel where you want to send session attendance messages',
        },
        'Event Registration': {
            subtext:
                'Select the Slack Channel where you want to send event registration messages',
        },
        'Automated Workflows': {
            subtext:
                'Select the slack channel where you want to send the workflow alerts',
        },
        imageUrl: '/images/integrations/rs-slack.svg',
        connectModalTitle: 'Connect Slack',
        // disconnectModalTitle: 'Disconnect Slack',
        connectModalSubtitle:
            'Send messages to a Slack channel for Event Registrations and more',
        // disconnectModalSubtitle: `Disconnect your Keap account from ${getCurrentTenantData(
        //     'name'
        // )}`,
        howToConfigDesc: 'Connect with your Slack account using OAuth Login',
        knowledgeBase:
            'https://help.airmeet.com/en/support/solutions/articles/82000895836-integrate-airmeet-with-slack',
    },
    [TWITTER]: {
        name: 'Twitter',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Twitter',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with Twitter once you disconnect the same.',
        ],
        basicInfo:
            "With this Zap, you'll automatically notify your Twitter audience via a new tweet whenever your event opens. Grab attendees to your online events without slowing down for manual messaging.",
        introSteps: {
            title: `Post tweets when new events are live on ${getCurrentTenantData(
                'name'
            )}`,
            step1: {
                action: 'Trigger',
                title: `Step 1: ${getCurrentTenantData('name')} Start`,
                description: 'When this happens',
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Create Tweet',
                description: 'Then do this',
            },
        },
    },
    [GOOGLE_TAG_MANAGER]: {
        name: 'Google Tag Manager',
        connectModalTitle: ' Add Google Tag Manager',
        disconnectModalTitle: 'Disconnect Google Tag Manager',
        connectModalSubtitle:
            'The Google Tag Manager ID will be added to all the landing pages of events and event series created after adding the ID here.',
        disconnectModalSubtitle: `Disconnect your Google Tag Manager account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfig: [
            'Enable the integration in the Community Integrations dashboard',
            `Visit the ${getCurrentTenantData(
                'name'
            )} Integrations page and enable Google Tag Manager`,
            'Add your Google Tag Manager ID',
        ],
        notes: ['Google Tag Manager code will be added to the landing page'],
        basicInfo: `Embed Google Tag Manager code on the ${getCurrentTenantData(
            'name'
        )} event landing page. Connect Google Tag Manager to ${getCurrentTenantData(
            'name'
        )} for improved customer experience through conversion tracking and remarketing with Google Ads, Google Analytics, LinkedIn, Twitter and over 90 supported vendors`,
        knowledge_base: `Visit our <a href="https://help.airmeet.com/en/support/solutions/articles/82000880354-integrate-airmeet-with-google-tag-manager" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}`,
        knowledgeBase:
            'https://help.airmeet.com/en/support/solutions/articles/82000880354-integrate-airmeet-with-google-tag-manager',
    },
    [POWER_AUTOMATE]: {
        name: 'Power Automate',
        connectModalTitle: 'Connect Power Automate',
        disconnectModalTitle: 'Disconnect Power Automate',
        connectModalSubtitle:
            'Add or update registrant list from Power Automate based on your event activity',
        disconnectModalSubtitle: `Disconnect your hubspot account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfig: [
            'On your browser, visit " https://powerautomate.microsoft.com/ "',
            'Sign in with your Microsoft Account.',
            `Create a new flow with the ${getCurrentTenantData(
                'name'
            )} Connector`,
            `Connect your Power Automate account to your ${getCurrentTenantData(
                'name'
            )} Organizer Account with the ${getCurrentTenantData(
                'name'
            )} Access key.`,
        ],
        notes: [
            `Organizer needs to provide Access key and Secret key at the time of Integration. This can be generated from the API access key tab under Integrations from ${getCurrentTenantData(
                'name'
            )}.`,
        ],
        basicInfo: `Connect ${getCurrentTenantData(
            'name'
        )} to over 500 apps on Microsoft Power Automate Platform, eg. Dynamics 365, Teams. Streamline repetitive tasks and paperless processes with Microsoft Power Automate—so you can focus your attention where it’s needed most.`,
        features:
            'With the Power Automate connector, you can add and retrieve event registrations, manage the event listing, setup reminders, track event attendance and more.',
        detailPageNotes: [
            `Organizer needs to provide Access key and Secret key at the time of Integration. This can be generated from the API access key tab under Integrations from ${getCurrentTenantData(
                'name'
            )}.`,
        ],
        knowledge_base: `Visit our <a href="https://help.airmeet.com/support/solutions/articles/82000879966-integrate-airmeet-with-microsoft-power-automate" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        knowledgeBase:
            'https://help.airmeet.com/support/solutions/articles/82000879966-integrate-airmeet-with-microsoft-power-automate',
    },
    [FACEBOOK_PIXEL]: {
        name: 'Facebook Pixel',
        connectModalTitle: ' Add Facebook Pixel ID',
        disconnectModalTitle: 'Disconnect Facebook Pixel',
        connectModalSubtitle:
            'The Facebook Pixel ID will be added to all the landing pages of events and event series created after adding the ID here.',
        disconnectModalSubtitle: `Disconnect your Facebook Pixel account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfig: [
            'Enable the integration in the Community Integrations dashboard',
            `Visit the ${getCurrentTenantData(
                'name'
            )} Integrations page and enable Facebook Pixel`,
            'Add your Facebook Pixel ID ',
        ],
        notes: ['Facebook Pixel code will be added to the landing page'],
        basicInfo: `Embed Facebook Pixel code on the ${getCurrentTenantData(
            'name'
        )} event landing page. Create Custom Audiences for Facebook Ads based on event page visitors. Optimise delivery to people who are likely to take action.`,
        knowledge_base: `Visit our <a href="https://help.airmeet.com/en/support/solutions/articles/82000879915-integrate-airmeet-with-facebook-pixel" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}`,
        knowledgeBase:
            'https://help.airmeet.com/en/support/solutions/articles/82000879915-integrate-airmeet-with-facebook-pixel',
    },
    [SURVEY_MONKEY]: {
        name: 'Survey Monkey',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Survey Monkey',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with SurveyMoney once you disconnect the same.',
        ],
        basicInfo: `This integration will automatically send surveys via SurveyMonkey to your ${getCurrentTenantData(
            'name'
        )} event attendees post-event completion.`,
        introSteps: {
            title: `Send SurveyMoney surveys for new finished ${getCurrentTenantData(
                'name'
            )} events`,
            step1: {
                action: 'Trigger',
                title: `Step 1: ${getCurrentTenantData('name')} Finish`,
                description: `Triggers when an ${getCurrentTenantData(
                    'name'
                )} is ended by the host.`,
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Send Survey',
                description:
                    'Send a survey invitation to one or more contacts and/or emails.',
            },
        },
    },
    [PIPEDRIVE]: {
        name: 'Pipedrive',
        connectModalTitle: 'Connect Pipedrive',
        disconnectModalTitle: 'Disconnect Pipedrive',
        connectModalSubtitle:
            'Add or update registrant list from Pipedrive based on your event activity',
        disconnectModalSubtitle: `Disconnect your Pipedrive account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfigDesc:
            'Connect with your Pipedrive account using the API Key.',
        howToConfig: [
            `Connect with your Pipedrive Account`,
            `Visit the ${getCurrentTenantData(
                'name'
            )} Integrations page and enable Pipedrive to configure the sync settings`,
        ],
        notes: [
            `Supports mapping of custom registration fields with Pipedrive contact fields`,
            `Contacts in Pipedrive can be filtered based on the Pipedrive filters. A direct Access Link for the event will be added to Contacts.`,
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically. You must export from ${getCurrentTenantData(
                'name'
            )} and upload a CSV file in Pipedrive to add those participants.`,
            `Disconnect Pipedrive and ${getCurrentTenantData(
                'name'
            )} accounts from the ${getCurrentTenantData(
                'name'
            )} community dashboard`,
        ],
        basicInfo: `Easily sync Pipedrive contacts with your ${getCurrentTenantData(
            'name'
        )} event registration. Select the Pipedrive filters on your Airmeet event settings page to enable the sync. Contacts registered with the PipeDrive filters will be added to the Airmeet. New registrations received on Airmeet will be added to the Pipedrive account as a contact.`,
        detailPageNotes: [
            `Supports mapping of custom registration fields with Pipedrive contact fields`,
            `Contacts in Pipedrive can be filtered based on the Pipedrive filters. A direct Access Link for the event will be added to Contacts.`,
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically. You must export from ${getCurrentTenantData(
                'name'
            )} and upload a CSV file in Pipedrive to add those participants.`,
            `Disconnect Pipedrive and ${getCurrentTenantData(
                'name'
            )} accounts from the ${getCurrentTenantData(
                'name'
            )} community dashboard`,
        ],
        features:
            'Supports mapping of custom registration fields with Pipedrive contact fields.',
        knowledge_base: `Visit our <a href="https://help.airmeet.com/support/solutions/articles/82000895838-integrate-airmeet-with-pipedrive" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        imageUrl: '/images/integrations/pipedrive.svg',
        filterHeading: 'Pipedrive filter',
        filterSubHeading: 'Select filters from your Pipedrive account',
        filterPath: '/data/person-filters',
        knowledgeBase:
            'https://help.airmeet.com/support/solutions/articles/82000895838-integrate-airmeet-with-pipedrive',
    },
    [PAYPAL]: {
        name: 'PayPal',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to PayPal',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with PayPal once you disconnect the same.',
        ],
        basicInfo: `With this integration in place, once your customers make their order on PayPal, you can give them access to your upcoming ${getCurrentTenantData(
            'name'
        )} event automatically. Set up this Zap to save yourself from tedious manual updates.`,
        introSteps: {
            title: `Add new PayPal customers as registrants to ${getCurrentTenantData(
                'name'
            )} events`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Successful Sale',
                description:
                    'Only successfully completed payments trigger this.',
                notAirmeet: true,
            },
            step2: {
                action: 'Action',
                title: 'Step 2:Add Registrant',
                description: `Add Registrant To ${getCurrentTenantData(
                    'name'
                )}`,
            },
        },
    },
    [SALESFORCE_PARDOT]: {
        name: 'Pardot',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Pardot',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with Salesforce Pardot once you disconnect the same.',
        ],
        basicInfo: `With this integration, Zapier will automatically add all new registrants from ${getCurrentTenantData(
            'name'
        )} to your Pardot account as new prospects. Grow your prospects effortlessly, every new sign up is recorded for your particular event on ${getCurrentTenantData(
            'name'
        )}!`,
        introSteps: {
            title: `Add new registrants from ${getCurrentTenantData(
                'name'
            )} to Pardot as prospects`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Attendee Registered',
                description: `Triggers when a new attendee registers for an ${getCurrentTenantData(
                    'name'
                )}.`,
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Create Prospect',
                description: 'Creates a new Prospect record within Pardot.',
            },
        },
    },
    [KEAP]: {
        name: 'Keap',
        connectModalTitle: 'Connect Keap',
        disconnectModalTitle: 'Disconnect Keap',
        connectModalSubtitle:
            'Add or update registrant list from Keap based on your event activity',
        disconnectModalSubtitle: `Disconnect your Keap account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfigDesc: 'Connect with your Keap account using OAuth Login',
        howToConfig: [
            `Connect with your Keap Account`,
            `Visit the ${getCurrentTenantData(
                'name'
            )} Integrations page and enable Keap to configure the sync settings`,
        ],
        notes: [
            `Supports mapping of custom registration fields with Keap contact fields`,
            `Contacts in Keap can be filtered based on the Keap filters. A direct Access Link for the event will be added to Contacts.`,
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically. You must export from ${getCurrentTenantData(
                'name'
            )} and upload a CSV file in keap to add those participants.`,
            `Disconnect keap and ${getCurrentTenantData(
                'name'
            )} accounts from the ${getCurrentTenantData(
                'name'
            )} community dashboard`,
        ],
        basicInfo: `Easily sync Keap contacts with your ${getCurrentTenantData(
            'name'
        )} event as participants.`,
        detailPageNotes: [
            `Supports mapping of custom registration fields with Keap contact fields`,
            `Contacts in Keap can be filtered based on the Keap filters. A direct Access Link for the event will be added to Contacts.`,
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically. You must export from ${getCurrentTenantData(
                'name'
            )} and upload a CSV file in keap to add those participants.`,
            `Disconnect keap and ${getCurrentTenantData(
                'name'
            )} accounts from the ${getCurrentTenantData(
                'name'
            )} community dashboard`,
        ],
        features: `Only the Name and Email of the contact from the Keap list will be added to the participant profile in  ${getCurrentTenantData(
            'name'
        )}. Custom Fields will not be added.`,
        knowledge_base: `Visit our <a href="https://help.airmeet.com/support/solutions/articles/82000895840-integrate-airmeet-with-keap" target="_blank">Knowledge base</a> to know more about integrations on  ${getCurrentTenantData(
            'name'
        )}.`,
        imageUrl: '/images/integrations/rs-keap.svg',
        filterHeading: 'Keap Tag',
        filterSubHeading: 'Select tag from your keap account',
        filterPath: '/data/contact-tags',
        knowledgeBase:
            'https://help.airmeet.com/support/solutions/articles/82000895840-integrate-airmeet-with-keap',
    },
    [FACEBOOK_LEAD_ADS]: {
        name: 'Facebook Lead Ads',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Facebook Lead Ads',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with Facebook lead ADs once you disconnect the same.',
        ],
        basicInfo: `Looking for a quick and easy way to capture Facebook leads in ${getCurrentTenantData(
            'name'
        )}? With this Zapier automation, new leads from Facebook Ads are automatically added to ${getCurrentTenantData(
            'name'
        )} as registrants.`,
        introSteps: {
            title: `Create registrants in ${getCurrentTenantData(
                'name'
            )} from new leads in Facebook Lead Ads`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: New Lead',
                description: 'Triggers when a new lead is created.',
                notAirmeet: true,
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Add Registrant',
                description: `Add Registrant To ${getCurrentTenantData(
                    'name'
                )}`,
            },
        },
    },
    [LINKEDIN_LEAD_GEN_FORMS]: {
        name: 'LinkedIn',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to LinkedIn Lead Gen Forms',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with LinkedIn Lead Gen once you disconnect the same.',
        ],
        basicInfo: `With this Zapier automation, new leads from LinkedIn Lead Gen Forms are automatically added to ${getCurrentTenantData(
            'name'
        )} as registrants -- no human error, no human effort. Let this automation work on auto-pilot so that you stay focused on more important tasks.`,
        introSteps: {
            title: `Create registrants in ${getCurrentTenantData(
                'name'
            )} from new LinkedIn Lead Gen Form leads`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: New Lead Gen Form Response',
                description: 'Triggers when you receive a new form response.',
                notAirmeet: true,
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Add Registrant',
                description: `Add Registrant To ${getCurrentTenantData(
                    'name'
                )}`,
            },
        },
    },
    [ACCREDIBLE_CERTIFICATES]: {
        name: 'Accredible Certificates',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Accredible Certificates',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with Accredible Certificates once you disconnect the same.',
        ],
        basicInfo: `With this integration, Zapier will automatically create a Accredible Certificates certificate or digital badge every time a new attendee joins an event on ${getCurrentTenantData(
            'name'
        )}. It's an easy way to give attendees proof of attendance!`,
        introSteps: {
            title: `Create Accredible Certificates when new attendees join events on ${getCurrentTenantData(
                'name'
            )}`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Attendee Joined an Event',
                description: 'Triggers whenever an attendee joins an event',
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Create Credential',
                description: 'Creates a new certificate or badge.',
            },
        },
    },
    [BADGR]: {
        name: 'Badgr',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Badgr',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with Badgr once you disconnect the same.',
        ],
        basicInfo: `Want to award a badge when someone attends your ${getCurrentTenantData(
            'name'
        )}? This integration lets you do that automatically, effortlessly keeping your audience engaged with digital badges from Badgr.`,
        introSteps: {
            title: `Award Badgr badges when new attendees join ${getCurrentTenantData(
                'name'
            )} sessions`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Attendee Joined an Event',
                description: 'Triggers whenever an attendee joins an event',
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Issue Badge',
                description: 'Creates a new certificate or badge.',
            },
        },
    },
    [CREDLY]: {
        name: 'Credly',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Credly',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with Credly once you disconnect the same.',
        ],
        basicInfo: `With this integration, Zapier will automatically issue a badge from Credly when an attendee joins an event on ${getCurrentTenantData(
            'name'
        )}. This Zap gives you an easy way to give attendees a proof of attendance!`,
        introSteps: {
            title: `Issue badges in Credly when new attendees join events in ${getCurrentTenantData(
                'name'
            )}`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Attendee Joined an Event',
                description: 'Triggers whenever an attendee joins an event',
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Issue Badge',
                description: 'Issues a badge.',
            },
        },
    },
    [AWEBER]: {
        name: 'AWeber',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to AWeber',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with AWeber once you disconnect the same.',
        ],
        basicInfo: `This integration automatically adds all new registrants from the ${getCurrentTenantData(
            'name'
        )} event to your AWeber account as subscribers. Keep your outreach on target without the need for duplicate data entry.`,
        introSteps: {
            title: `Add new ${getCurrentTenantData(
                'name'
            )} registrants to AWeber as subscribers`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Registrant Added on a Specific Event',
                description: 'Registrant Added on a specific event',
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Create Subscriber',
                description: 'Creates a new subscriber.',
            },
        },
    },
    [CUSTOMER_IO]: {
        name: 'Customer.io',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Customer.io',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with Customer.io once you disconnect the same.',
        ],
        basicInfo: `This integration automatically adds each new registrant from an ${getCurrentTenantData(
            'name'
        )} event to your Customer.io account as a person. Set up this Zap to save yourself from tedious duplicate data entry work.`,
        introSteps: {
            title: `Generate Customer.io persons from new ${getCurrentTenantData(
                'name'
            )} registrants`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Registrant Added on a Specific Event',
                description: 'Registrant Added on a specific event',
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Create or Update a Person',
                description:
                    'Creates a new person. If the person already exists, it will be updated.',
            },
        },
    },
    [GETRESPONSE]: {
        name: 'GetResponse',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to GetResponse',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with GetResponse once you disconnect the same.',
        ],
        basicInfo: `Tired of manually managing your contacts across platforms? With this integration, Zapier will automatically add each new registrant from an ${getCurrentTenantData(
            'name'
        )} event to your GetResponse account as a new contact.`,
        introSteps: {
            title: `Add new registrants from ${getCurrentTenantData(
                'name'
            )} events to GetResponse as contacts`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Registrant Added on a Specific Event',
                description: 'Registrant Added on a specific event',
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Create Contact',
                description: 'Creates a new contact.',
            },
        },
    },
    [SHARPSPRING]: {
        name: 'SharpSpring',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to SharpSpring',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with SharpSpring once you disconnect the same.',
        ],
        basicInfo: `With this integration, Zapier will automatically add all new registrants from ${getCurrentTenantData(
            'name'
        )} event to your SharpSpring account as leads. Every new sign-up is automatically recorded for your particular event on ${getCurrentTenantData(
            'name'
        )}. Grow your prospects effortlessly!`,
        introSteps: {
            title: `Save new ${getCurrentTenantData(
                'name'
            )} event registrants to SharpSpring as leads`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Registrant Added on a Specific Event',
                description: 'Registrant Added on a specific event',
            },
            step2: {
                action: 'Action',
                title: 'Step 2: Create/Update Lead',
                description:
                    'Create a Lead in SharpSpring or update by email address.',
            },
        },
    },
    [INSTAPAGE]: {
        name: 'Instapage',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Instapage',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with Instapage once you disconnect the same.',
        ],
        basicInfo: `Once activated, this integration will automatically create an ${getCurrentTenantData(
            'name'
        )} attendee from a new form submission in Instapage. Let this automation work on auto-pilot so that you stay focused on more important tasks.`,
        introSteps: {
            title: `Register attendees in ${getCurrentTenantData(
                'name'
            )} with custom fields from new form submissions in Instapage`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Form Submission',
                description: 'Triggers when a new form is submitted.',
                notAirmeet: true,
            },
            step2: {
                action: 'Action',
                title:
                    'Step 2: Register Attendee With Custom Registration Form',
                description: 'Register Attendee With Custom Registration Form',
            },
        },
    },
    [JOTFORM]: {
        name: 'JotForm',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to JotForm',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with JotForm once you disconnect the same.',
        ],
        basicInfo: `Quickly add registrations to ${getCurrentTenantData(
            'name'
        )} with this Zapier template. Once this integration is in place, whenever someone new fills out your Jotform form, they will be added as an Airmet registrant automatically.`,
        introSteps: {
            title: `Add ${getCurrentTenantData(
                'name'
            )} registrants from new Jotform submissions`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: Form Submission',
                description: 'Triggers when a new form is submitted.',
                notAirmeet: true,
            },
            step2: {
                action: 'Action',
                title:
                    'Step 2: Register Attendee With Custom Registration Form',
                description: 'Register Attendee With Custom Registration Form',
            },
        },
    },
    [UNBOUNCE]: {
        name: 'Unbounce',
        howToConfig: [
            'Connect your accounts to Zapier',
            'Choose which data to send to Unbounce',
            'Start automating your work',
        ],
        notes: [
            'You will no longer recieve any data configured with Unbounce once you disconnect the same.',
        ],
        basicInfo: `Looking for a quick and easy way to register Unbounce form submissions in ${getCurrentTenantData(
            'name'
        )}? With this Zapier automation, each new form submission from Unbounce is added to ${getCurrentTenantData(
            'name'
        )} as a registrant, automatically.`,
        introSteps: {
            title: `Register attendees in ${getCurrentTenantData(
                'name'
            )} with custom registration fields from new form submissions in Unbounce`,
            step1: {
                action: 'Trigger',
                title: 'Step 1: New Form Submission',
                description:
                    'Triggers when a new Form Submission is received on a Landing Page, Overlay or Sticky Bar',
                notAirmeet: true,
            },
            step2: {
                action: 'Action',
                title:
                    'Step 2: Register Attendee With Custom Registration Form',
                description: 'Register Attendee With Custom Registration Form',
            },
        },
    },
    [ORACLE_ELOQUA]: {
        name: 'Oracle Eloqua',
        connectModalTitle: 'Connect Oracle Eloqua',
        disconnectModalTitle: 'Disconnect Oracle Eloqua ',
        connectModalSubtitle:
            'Add or update registrant list from Eloqua based on your event activity',
        disconnectModalSubtitle: `Disconnect your Oracle Eloqua account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfig: [
            'Connect with your Oracle Eloqua account',
            `Visit the ${getCurrentTenantData(
                'name'
            )} Integrations page and enable Oracle Eloqua to configure the sync settings`,
            `Use the ${getCurrentTenantData(
                'name'
            )} Registration Action canvas element on Oracle Eloqua to register users on an ${getCurrentTenantData(
                'name'
            )}`,
        ],
        notes: [
            'Supports mapping of custom registration fields with Oracle Eloqua contact fields',
            `Contacts in Oracle Eloqua can be filtered based on the ${getCurrentTenantData(
                'name'
            )} Name property. Direct Access Link for the event will be added to the contact properties in Oracle Eloqua.`,
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically. You have to export from ${getCurrentTenantData(
                'name'
            )} and upload a CSV file in Oracle Eloqua to add those participants.`,
            `To disconnect Oracle Eloqua and ${getCurrentTenantData(
                'name'
            )} accounts, please disconnect the app on both ${getCurrentTenantData(
                'name'
            )} and Oracle Eloqua`,
        ],
        basicInfo: `Connect ${getCurrentTenantData(
            'name'
        )} with your Oracle Eloqua account to create optimized, personalized and cross-channel campaigns to target and engage the right audience at the right time. Target and engage the right audience at the right time while providing real-time reporting and insights using campaign and lead management tools. Add ${getCurrentTenantData(
            'name'
        )} Registration Action to your campaign canvas to register users on your ${getCurrentTenantData(
            'name'
        )}. Sync registrations received on ${getCurrentTenantData(
            'name'
        )} with Oracle Eloqua by enabling the sync on your ${getCurrentTenantData(
            'name'
        )} event page.`,
        features:
            'Supports mapping of custom registration fields with Oracle Eloqua contact fields',
        detailPageNotes: [
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added to Mailchimp automatically. You have to export from ${getCurrentTenantData(
                'name'
            )} and upload those participants in Mailchimp.`,
            `${getCurrentTenantData(
                'name'
            )} sends an email to participants on registration and another email when the event is about to start. If you wish to use Mailchimp to send emails to your participants, you can email <a href="mailto:support@airmeet.com" target="_blank">support@airmeet.com</a> to disable participant emails on registration and event reminders.`,
        ],
        knowledge_base: `Visit our <a href="https://help.airmeet.com/en/support/solutions/articles/82000884787-integrate-airmeet-with-oracle-eloqua" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        knowledgeBase:
            'https://help.airmeet.com/en/support/solutions/articles/82000884787-integrate-airmeet-with-oracle-eloqua',
    },
    [MICROSOFT_DYNAMICS_365]: {
        name: 'Microsoft Dynamics 365',
        connectModalTitle: 'Connect Microsoft Dynamics 365',
        disconnectModalTitle: 'Disconnect Microsoft Dynamics 365 ',
        connectModalSubtitle:
            'Sync event registrations with a Microsoft Dynamics 365 Event',
        disconnectModalSubtitle: `Disconnect your Microsoft Dynamics 365 account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfig: [
            'Connect with your Microsoft Dynamics 365 account',
            `Visit the ${getCurrentTenantData(
                'name'
            )} Integrations page and enable Microsoft Dynamics 365 to configure the settings`,
        ],
        notes: [
            'Supports mapping of custom registration fields with Microsoft Dynamics 365 contact fields',
            'Contacts in Microsoft Dynamics 365 can be filtered based on the Event Registration membership. Direct Access Link for the event will be added to the Event Registration properties in Microsoft Dynamics 365 which will be associated with Contacts.',
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically. You have to export from ${getCurrentTenantData(
                'name'
            )} and upload a CSV file in Microsoft Dynamics 365 to add those participants.`,
            `Disconnect Microsoft Dynamics 365 and ${getCurrentTenantData(
                'name'
            )} accounts from the ${getCurrentTenantData(
                'name'
            )} community dashboard`,
        ],
        basicInfo: `Easily sync Microsoft Dynamics 365 event registrations with your ${getCurrentTenantData(
            'name'
        )} event. Select the Microsoft Dynamics event on your ${getCurrentTenantData(
            'name'
        )} event settings page to enable the sync. Contacts registered with the Microsoft Dynamics 365 event will be added to the ${getCurrentTenantData(
            'name'
        )}. New registrations received on ${getCurrentTenantData(
            'name'
        )} will be added to the Microsoft Dynamics 365 event.`,
        features:
            'Supports mapping of custom registration fields with Microsoft Dynamics 365 contact fields',
        detailPageNotes: [
            'Supports mapping of custom registration fields with Microsoft Dynamics 365 contact fields',
            'Contacts in Microsoft Dynamics 365 can be filtered based on the Event Registration membership. Direct Access Link for the event will be added to the Event Registration properties in Microsoft Dynamics 365 which will be associated with Contacts.',
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically. You have to export from ${getCurrentTenantData(
                'name'
            )} and upload a CSV file in Microsoft Dynamics 365 to add those participants.`,
            `Disconnect Microsoft Dynamics 365 and ${getCurrentTenantData(
                'name'
            )} accounts from the ${getCurrentTenantData(
                'name'
            )} community dashboard`,
        ],
        knowledge_base: `Visit our <a href="https://help.airmeet.com/en/support/solutions/articles/82000893822-integrate-airmeet-with-microsoft-dynamics-365" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        knowledgeBase:
            'https://help.airmeet.com/en/support/solutions/articles/82000893822-integrate-airmeet-with-microsoft-dynamics-365',
    },
    [SENDOSO]: {
        name: 'Sendoso',
        connectModalTitle: 'Connect Sendoso',
        disconnectModalTitle: 'Disconnect Sendoso ',
        connectModalSubtitle: `Send a Gift via Sendoso for Event Registrations and more`,
        disconnectModalSubtitle: `Disconnect your Sendoso account from ${getCurrentTenantData(
            'name'
        )}`,
        howToConfig: [
            `Connect with your Sendoso account`,
            `Visit the ${getCurrentTenantData(
                'name'
            )} Integrations page and enable Sendoso to configure the settings`,
        ],
        notes: [
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically.`,
            `Disconnect Sendoso and ${getCurrentTenantData(
                'name'
            )}  accounts from the Airmeet community dashboard`,
        ],
        basicInfo: `Sendoso is a sending platform that allows you to send gifts, physical and virtual, to anyone automatically. Automatically send Sendoso Gifts to event registrations and event attendees.`,
        features: `Select different Sendoso gifts for registration and attendance`,
        detailPageNotes: [
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically.`,
            `Disconnect Sendoso and ${getCurrentTenantData(
                'name'
            )}  accounts from the Airmeet community dashboard`,
        ],
        knowledge_base: `Visit our <a href="https://help.airmeet.com/support/solutions/articles/82000895837-integrate-airmeet-with-sendoso" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        sendosoDropdown: {
            'trigger.airmeet.attendee.registered': {
                title: `Select Gift for Registrations`,
                subtitle: `Select gift from Sendoso account which you want to send to event registrations`,
            },
            'trigger.airmeet.attendee.joined': {
                title: `Select Gift for Attendees`,
                subtitle: `Select gift from Sendoso account which you want to send to event attendees`,
            },
        },
        knowledgeBase:
            'https://help.airmeet.com/support/solutions/articles/82000895837-integrate-airmeet-with-sendoso',
    },
    [PARDOT]: {
        name: 'Pardot',
        imageUrl: '/images/integrations/activecampaign.svg',
        filterPath: '/data/prospect-lists',
        filterHeading: 'Pardot List',
        filterSubHeading: 'Select lists from your Pardot account',
        howToConfig: [
            'Connect with your Pardot Account',
            `Visit the ${getCurrentTenantData(
                'name'
            )} Integrations page and enable Pardot to configure the sync settings`,
        ],
        basicInfo:
            'Easily sync Pardot Prospects with your event as participants. Prospects added to a Pardot List are added to the event automatically.',
        detailPageNotes: [
            'Supports mapping of custom registration fields with Pardot Prospect fields',
            'Prospects in Pardot are added to a specific Pardot List. A direct Access Link for the event will be added to Prospects.',
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically. You must export from ${getCurrentTenantData(
                'name'
            )} and upload a CSV file in Pardot to add those participants.`,
            `Disconnect Pardot and Airmeet accounts from the ${getCurrentTenantData(
                'name'
            )} community dashboard`,
        ],
        knowledge_base: `Visit our <a href="https://help.airmeet.com/en/support/solutions/articles/82000898234-integrate-airmeet-with-salesforce-pardot" target="_blank">Knowledge base</a> to know more about integrations on ${getCurrentTenantData(
            'name'
        )}.`,
        features:
            'Supports mapping of custom registration fields with Pardot Prospect fields. Attendees can be sent to a separate Pardot List. Unique join links are updated in Pardot. Engagement details can be sent back to Pardot as Pardot External Activities.',
        notes: [
            'Supports mapping of custom registration fields with Pardot Prospect fields',
            'Prospects in Pardot are added to a specific Pardot List. A direct Access Link for the event will be added to Prospects.',
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically. You must export from ${getCurrentTenantData(
                'name'
            )} and upload a CSV file in Pardot to add those participants.`,
            `Disconnect Pardot and Airmeet accounts from the ${getCurrentTenantData(
                'name'
            )} community dashboard`,
        ],
        imageUrl: '/images/integrations/Pardot-2.svg',
        exportSubHeading:
            'Map Airmeet registration fields with Pardot prospect properties for adding / updating prospects in your Pardot account.',
        importSubHeading:
            'Map the Airmeet registration fields with Pardot prospect properties here for pre-filling the registration form on Airmeet.',
        knowledgeBase:
            'https://help.airmeet.com/en/support/solutions/articles/82000898234-integrate-airmeet-with-salesforce-pardot',
    },
    [MAILERLITE]: {
        name: 'MailerLite',
        howToConfig: [
            'Connect with your MailerLite account',
            'Visit the Airmeet Integrations page and enable MailerLite to configure the settings',
        ],
        detailPageNotes: [
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically.`,
            `Disconnect MailerLite and ${getCurrentTenantData(
                'name'
            )} accounts from the Airmeet community dashboard`,
            `${getCurrentTenantData(
                'name'
            )} sends an email to participants on registration and another email when the event is about to start. If you wish to use MailerLite to send emails to your participants, you can disable participant emails on registration and event reminders from the ‘Email Communications’ page.`,
        ],
        knowledge_base: `Visit our <a href="https://help.airmeet.com/en/support/solutions/articles/82000896464-integrate-airmeet-with-mailerlite" target="_blank">Knowledge base</a> to know more about integrations on Airmeet.`,
        basicInfo: `Easily export your event registrations to MailerLite. Simply enable the integration for your event and map the contact fields. All future registrations will be added to MailerLite automatically. A group will be created in MailerLite, and all registrations for the event will be added to that group. The participant’s direct access link will also be added to a contact field.`,
        imageUrl: '/images/integrations/mailerlite.svg',
        notes: [
            `Participants registered for the ${getCurrentTenantData(
                'name'
            )} Event before enabling this integration for the event will not be added automatically.`,
            `Disconnect MailerLite and ${getCurrentTenantData(
                'name'
            )} accounts from the Airmeet community dashboard`,
            `${getCurrentTenantData(
                'name'
            )} sends an email to participants on registration and another email when the event is about to start. If you wish to use MailerLite to send emails to your participants, you can disable participant emails on registration and event reminders from the ‘Email Communications’ page.`,
        ],
        knowledgeBase:
            'https://help.airmeet.com/en/support/solutions/articles/82000896464-integrate-airmeet-with-mailerlite',
    },
};
export const redirectionURL = {
    [ZAPIER]: 'https://zapier.com/apps/airmeet/integrations',
    [INTERPREFY]: 'https://share.hsforms.com/1phsg22j-R06uyv8oLrjGVw3wowi',
    [SALESFORCE]:
        'https://login.salesforce.com/packaging/installPackage.apexp?p0=04t5e0000012HPh',
    [SALESMATE]:
        'https://zapier.com/apps/airmeet/integrations/salesmate/681036/add-new-airmeet-registrants-to-salesmate-as-contacts',
    [ACTIVECAMPAIGN]:
        'https://zapier.com/apps/activecampaign/integrations/airmeet/645590/add-new-airmeet-registrants-to-activecampaign-as-contacts',
    [SLACK]:
        'https://zapier.com/apps/airmeet/integrations/slack/686335/post-slack-notifications-for-new-airmeet-registrants',
    [TWITTER]:
        'https://zapier.com/apps/airmeet/integrations/twitter/685177/post-tweets-when-new-events-are-live-on-airmeet',
    [DRIP]:
        'https://zapier.com/apps/airmeet/integrations/drip/681459/add-new-airmeet-registrants-to-drip-as-subscribers',
    [MAILJET]:
        'https://zapier.com/apps/airmeet/integrations/mailjet/681483/add-new-registrants-from-airmeet-as-mailjet-subscribers',
    [GOOGLE_SHEET]:
        'https://zapier.com/apps/airmeet/integrations/google-sheets/645585/create-google-sheets-rows-for-new-airmeet-registrations',
    [MICROSOFT_DYNAMICS]:
        'https://zapier.com/apps/airmeet/integrations/microsoft-dynamics-crm/681011/add-new-registrants-from-airmeet-to-microsoft-dynamics-365-crm-as-contacts',
    [HUBSPOT]: process.env.REACT_APP_HUBSPOT_URL,
    [POWER_AUTOMATE]: process.env.REACT_APP_POWER_AUTOMATE_URL,
    [SURVEY_MONKEY]:
        'https://zapier.com/apps/airmeet/integrations/surveymonkey/737456/send-surveymoney-surveys-for-new-finished-airmeet-events',
    [PIPEDRIVE]:
        'https://zapier.com/apps/airmeet/integrations/pipedrive/737414/create-pipedrive-deals-for-new-airmeet-registrants',
    [PAYPAL]:
        'https://zapier.com/apps/airmeet/integrations/paypal/737342/add-new-paypal-customers-as-registrants-to-airmeet-events',
    [SALESFORCE_PARDOT]:
        'https://zapier.com/apps/airmeet/integrations/pardot/681269/add-new-registrants-from-airmeet-to-pardot-as-prospects',
    [KEAP]:
        'https://zapier.com/apps/airmeet/integrations/keap/681225/add-new-registrants-from-airmeet-to-keap-as-contacts',
    [FACEBOOK_LEAD_ADS]:
        'https://zapier.com/apps/airmeet/integrations/facebook-lead-ads/734758/create-registrants-in-airmeet-from-new-leads-in-facebook-lead-ads',
    [LINKEDIN_LEAD_GEN_FORMS]:
        'https://zapier.com/apps/airmeet/integrations/linkedin-lead-gen-forms/734664/create-registrants-in-airmeet-from-new-linkedin-lead-gen-form-leads',
    [ACCREDIBLE_CERTIFICATES]:
        'https://zapier.com/apps/accredible/integrations/airmeet/760718/create-accredible-certificates-when-new-attendees-join-events-on-airmeet',
    [BADGR]:
        'https://zapier.com/apps/airmeet/integrations/badgr/774668/award-badgr-badges-when-new-attendees-join-airmeet-sessions',
    [CREDLY]:
        'https://zapier.com/apps/airmeet/integrations/credlys-acclaim-platform/760738/issue-badges-in-credly-when-new-attendees-join-events-in-airmeet',
    [AWEBER]:
        'https://zapier.com/apps/airmeet/integrations/aweber/734621/add-new-airmeet-registrants-to-aweber-as-subscribers',
    [CUSTOMER_IO]:
        'https://zapier.com/apps/airmeet/integrations/customerio/734641/generate-customerio-persons-from-new-airmeet-registrants',
    [GETRESPONSE]:
        'https://zapier.com/apps/airmeet/integrations/getresponse/734630/add-new-registrants-from-airmeet-events-to-getresponse-as-contacts',
    [SHARPSPRING]:
        'https://zapier.com/apps/airmeet/integrations/sharpspring/734662/save-new-airmeet-event-registrants-to-sharpspring-as-leads',
    [INSTAPAGE]:
        'https://zapier.com/apps/airmeet/integrations/instapage/810010/register-attendees-in-airmeet-with-custom-fields-from-new-form-submissions-in-instapage',
    [JOTFORM]:
        'https://zapier.com/apps/airmeet/integrations/jotform/810021/add-airmeet-registrants-from-new-jotform-submissions',
    [UNBOUNCE]:
        'https://zapier.com/apps/airmeet/integrations/unbounce/810017/register-attendees-in-airmeet-with-custom-registration-fields-from-new-form-submissions-in-unbounce',
    [ORACLE_ELOQUA]: process.env.REACT_APP_ELOQUA_INSTALL_URL,
    [MICROSOFT_DYNAMICS_365]: `https://login.microsoftonline.com/92353216-3f84-46db-a0c7-1f9341696bac/oauth2/v2.0/authorize?client_id=30c0bf33-491f-40ff-ae97-fe3d4e77b132&response_type=code&redirect_uri=https://api-gateway.airmeet.com/staging/platform-integration/v1/oauth/platforms/callback/Microsoft%20Dynamics%20365&response_mode=query`,
};

export const customFieldMapping = {
    HUBSPOT_FIELD_MAPPED: [
        {
            fieldName: 'First Name',
            extFieldName: 'First Name',
        },
        {
            fieldName: 'Last Name',
            extFieldName: 'Last Name',
        },
        {
            fieldName: 'Job Title',
            extFieldName: 'Job Title',
        },
        {
            fieldName: 'Organization',
            extFieldName: 'Company Name',
        },
        {
            fieldName: 'City',
            extFieldName: 'City',
        },
        {
            fieldName: 'Country',
            extFieldName: 'Country/Region',
        },
    ],
    ELOQUA_FIELD_MAPPED: [
        {
            fieldName: 'First Name',
            extFieldName: 'First Name',
        },
        {
            fieldName: 'Last Name',
            extFieldName: 'Last Name',
        },
        {
            fieldName: 'Job Title',
            extFieldName: 'Title',
        },
        {
            fieldName: 'Organization',
            extFieldName: 'Company',
        },
        {
            fieldName: 'City',
            extFieldName: 'City',
        },
        {
            fieldName: 'Country',
            extFieldName: 'Country',
        },
    ],
    MAILCHIMP_FIELD_MAPPED: [
        {
            fieldName: 'First Name',
            extFieldName: 'First Name',
        },
        {
            fieldName: 'Last Name',
            extFieldName: 'Last Name',
        },
    ],
    MARKETO_FIELD_MAPPED: [
        {
            fieldName: 'First Name',
            extFieldName: 'First Name',
        },
        {
            fieldName: 'Last Name',
            extFieldName: 'Last Name',
        },
        {
            fieldName: 'Job Title',
            extFieldName: 'Job Title',
        },
        {
            fieldName: 'Organization',
            extFieldName: 'Company Name',
        },
        {
            fieldName: 'City',
            extFieldName: 'City',
        },
        {
            fieldName: 'Country',
            extFieldName: 'Country',
        },
    ],
    MICROSOFT_DYNAMICS_365__FIELD_MAPPED: [
        {
            fieldName: 'First Name',
            extFieldName: 'FirstName',
        },
        {
            fieldName: 'Last Name',
            extFieldName: 'LastName',
        },
        {
            fieldName: 'Job Title',
            extFieldName: 'JobTitle',
        },
        {
            fieldName: 'Organization',
            extFieldName: 'Company',
        },
        {
            fieldName: 'City',
            extFieldName: 'Address1_City',
        },
        {
            fieldName: 'Country',
            extFieldName: 'Address1_Country',
        },
    ],
    ACTIVECAMPAIGN_FIELD_MAPPED: [
        {
            fieldName: 'First Name',
            extFieldName: 'First Name',
        },
        {
            fieldName: 'Last Name',
            extFieldName: 'Last Name',
        },
    ],
    PIPEDRIVE_FIELD_MAPPED: [
        {
            fieldName: 'First Name',
            extFieldName: 'First name',
        },
        {
            fieldName: 'Last Name',
            extFieldName: 'Last name',
        },
        {
            fieldName: 'Job Title',
            extFieldName: 'Job Title',
        },
        {
            fieldName: 'Organization',
            extFieldName: 'Company',
        },
        {
            fieldName: 'City',
            extFieldName: 'City',
        },
        {
            fieldName: 'Country',
            extFieldName: 'Country',
        },
    ],
    KEAP_FIELD_MAPPED: [
        {
            fieldName: 'First Name',
            extFieldName: 'Given Name',
        },
        {
            fieldName: 'Last Name',
            extFieldName: 'Family Name',
        },
        {
            fieldName: 'Job Title',
            extFieldName: 'Job Title',
        },
        {
            fieldName: 'Organization',
            extFieldName: 'Company Name',
        },
        {
            fieldName: 'City',
            extFieldName: 'Locality',
        },
        {
            fieldName: 'Country',
            extFieldName: 'Country Code',
        },
    ],
    PARDOT_FIELD_MAPPED: [
        {
            fieldName: 'First Name',
            extFieldName: 'First Name',
        },
        {
            fieldName: 'Last Name',
            extFieldName: 'Last Name',
        },
        {
            fieldName: 'Job Title',
            extFieldName: 'Job Title',
        },
        {
            fieldName: 'Organization',
            extFieldName: 'Company',
        },
        {
            fieldName: 'City',
            extFieldName: 'City',
        },
        {
            fieldName: 'Country',
            extFieldName: 'Country',
        },
    ],
    MAILERLITE_FIELD_MMAPPED: [
        {
            fieldName: 'First Name',
            extFieldName: 'Name',
        },
        {
            fieldName: 'Last Name',
            extFieldName: 'Last name',
        },
        // {
        //     fieldName: 'Job Title',
        //     extFieldName: 'Job Title',
        // },
        {
            fieldName: 'Organization',
            extFieldName: 'Company',
        },
        {
            fieldName: 'City',
            extFieldName: 'City',
        },
        {
            fieldName: 'Country',
            extFieldName: 'Country',
        },
    ],
};
export const COUNTRY_ALPHA_3_CODE = [
    {
        code: 'AFG',
        name: 'Afghanistan',
    },
    {
        code: 'ALA',
        name: 'Åland Islands',
    },
    {
        code: 'ALB',
        name: 'Albania',
    },
    {
        code: 'DZA',
        name: 'Algeria',
    },
    {
        code: 'ASM',
        name: 'American Samoa',
    },
    {
        code: 'AND',
        name: 'Andorra',
    },
    {
        code: 'AGO',
        name: 'Angola',
    },
    {
        code: 'AIA',
        name: 'Anguilla',
    },
    {
        code: 'ATA',
        name: 'Antarctica',
    },
    {
        code: 'ATG',
        name: 'Antigua and Barbuda',
    },
    {
        code: 'ARG',
        name: 'Argentina',
    },
    {
        code: 'ARM',
        name: 'Armenia',
    },
    {
        code: 'ABW',
        name: 'Aruba',
    },
    {
        code: 'AUS',
        name: 'Australia',
    },
    {
        code: 'AUT',
        name: 'Austria',
    },
    {
        code: 'AZE',
        name: 'Azerbaijan',
    },
    {
        code: 'BHS',
        name: 'Bahamas',
    },
    {
        code: 'BHR',
        name: 'Bahrain',
    },
    {
        code: 'BGD',
        name: 'Bangladesh',
    },
    {
        code: 'BRB',
        name: 'Barbados',
    },
    {
        code: 'BLR',
        name: 'Belarus',
    },
    {
        code: 'BEL',
        name: 'Belgium',
    },
    {
        code: 'BLZ',
        name: 'Belize',
    },
    {
        code: 'BEN',
        name: 'Benin',
    },
    {
        code: 'BMU',
        name: 'Bermuda',
    },
    {
        code: 'BTN',
        name: 'Bhutan',
    },
    {
        code: 'BOL',
        name: 'Bolivia, Plurinational State of',
    },
    {
        code: 'BES',
        name: 'Bonaire, Sint Eustatius and Saba',
    },
    {
        code: 'BIH',
        name: 'Bosnia and Herzegovina',
    },
    {
        code: 'BWA',
        name: 'Botswana',
    },
    {
        code: 'BVT',
        name: 'Bouvet Island',
    },
    {
        code: 'BRA',
        name: 'Brazil',
    },
    {
        code: 'IOT',
        name: 'British Indian Ocean Territory',
    },
    {
        code: 'BRN',
        name: 'Brunei Darussalam',
    },
    {
        code: 'BGR',
        name: 'Bulgaria',
    },
    {
        code: 'BFA',
        name: 'Burkina Faso',
    },
    {
        code: 'BDI',
        name: 'Burundi',
    },
    {
        code: 'KHM',
        name: 'Cambodia',
    },
    {
        code: 'CMR',
        name: 'Cameroon',
    },
    {
        code: 'CAN',
        name: 'Canada',
    },
    {
        code: 'CPV',
        name: 'Cape Verde',
    },
    {
        code: 'CYM',
        name: 'Cayman Islands',
    },
    {
        code: 'CAF',
        name: 'Central African Republic',
    },
    {
        code: 'TCD',
        name: 'Chad',
    },
    {
        code: 'CHL',
        name: 'Chile',
    },
    {
        code: 'CHN',
        name: 'China',
    },
    {
        code: 'CXR',
        name: 'Christmas Island',
    },
    {
        code: 'CCK',
        name: 'Cocos (Keeling) Islands',
    },
    {
        code: 'COL',
        name: 'Colombia',
    },
    {
        code: 'COM',
        name: 'Comoros',
    },
    {
        code: 'COG',
        name: 'Congo',
    },
    {
        code: 'COD',
        name: 'Congo, the Democratic Republic of the',
    },
    {
        code: 'COK',
        name: 'Cook Islands',
    },
    {
        code: 'CRI',
        name: 'Costa Rica',
    },
    {
        code: 'CIV',
        name: "Côte d'Ivoire",
    },
    {
        code: 'HRV',
        name: 'Croatia',
    },
    {
        code: 'CUB',
        name: 'Cuba',
    },
    {
        code: 'CUW',
        name: 'Curaçao',
    },
    {
        code: 'CYP',
        name: 'Cyprus',
    },
    {
        code: 'CZE',
        name: 'Czech Republic',
    },
    {
        code: 'DNK',
        name: 'Denmark',
    },
    {
        code: 'DJI',
        name: 'Djibouti',
    },
    {
        code: 'DMA',
        name: 'Dominica',
    },
    {
        code: 'DOM',
        name: 'Dominican Republic',
    },
    {
        code: 'ECU',
        name: 'Ecuador',
    },
    {
        code: 'EGY',
        name: 'Egypt',
    },
    {
        code: 'SLV',
        name: 'El Salvador',
    },
    {
        code: 'GNQ',
        name: 'Equatorial Guinea',
    },
    {
        code: 'ERI',
        name: 'Eritrea',
    },
    {
        code: 'EST',
        name: 'Estonia',
    },
    {
        code: 'ETH',
        name: 'Ethiopia',
    },
    {
        code: 'FLK',
        name: 'Falkland Islands (Malvinas)',
    },
    {
        code: 'FRO',
        name: 'Faroe Islands',
    },
    {
        code: 'FJI',
        name: 'Fiji',
    },
    {
        code: 'FIN',
        name: 'Finland',
    },
    {
        code: 'FRA',
        name: 'France',
    },
    {
        code: 'GUF',
        name: 'French Guiana',
    },
    {
        code: 'PYF',
        name: 'French Polynesia',
    },
    {
        code: 'ATF',
        name: 'French Southern Territories',
    },
    {
        code: 'GAB',
        name: 'Gabon',
    },
    {
        code: 'GMB',
        name: 'Gambia',
    },
    {
        code: 'GEO',
        name: 'Georgia',
    },
    {
        code: 'DEU',
        name: 'Germany',
    },
    {
        code: 'GHA',
        name: 'Ghana',
    },
    {
        code: 'GIB',
        name: 'Gibraltar',
    },
    {
        code: 'GRC',
        name: 'Greece',
    },
    {
        code: 'GRL',
        name: 'Greenland',
    },
    {
        code: 'GRD',
        name: 'Grenada',
    },
    {
        code: 'GLP',
        name: 'Guadeloupe',
    },
    {
        code: 'GUM',
        name: 'Guam',
    },
    {
        code: 'GTM',
        name: 'Guatemala',
    },
    {
        code: 'GGY',
        name: 'Guernsey',
    },
    {
        code: 'GIN',
        name: 'Guinea',
    },
    {
        code: 'GNB',
        name: 'Guinea-Bissau',
    },
    {
        code: 'GUY',
        name: 'Guyana',
    },
    {
        code: 'HTI',
        name: 'Haiti',
    },
    {
        code: 'HMD',
        name: 'Heard Island and McDonald Islands',
    },
    {
        code: 'VAT',
        name: 'Holy See (Vatican City State)',
    },
    {
        code: 'HND',
        name: 'Honduras',
    },
    {
        code: 'HKG',
        name: 'Hong Kong',
    },
    {
        code: 'HUN',
        name: 'Hungary',
    },
    {
        code: 'ISL',
        name: 'Iceland',
    },
    {
        code: 'IND',
        name: 'India',
    },
    {
        code: 'IDN',
        name: 'Indonesia',
    },
    {
        code: 'IRN',
        name: 'Iran, Islamic Republic of',
    },
    {
        code: 'IRQ',
        name: 'Iraq',
    },
    {
        code: 'IRL',
        name: 'Ireland',
    },
    {
        code: 'IMN',
        name: 'Isle of Man',
    },
    {
        code: 'ISR',
        name: 'Israel',
    },
    {
        code: 'ITA',
        name: 'Italy',
    },
    {
        code: 'JAM',
        name: 'Jamaica',
    },
    {
        code: 'JPN',
        name: 'Japan',
    },
    {
        code: 'JEY',
        name: 'Jersey',
    },
    {
        code: 'JOR',
        name: 'Jordan',
    },
    {
        code: 'KAZ',
        name: 'Kazakhstan',
    },
    {
        code: 'KEN',
        name: 'Kenya',
    },
    {
        code: 'KIR',
        name: 'Kiribati',
    },
    {
        code: 'PRK',
        name: "Korea, Democratic People's Republic of",
    },
    {
        code: 'KOR',
        name: 'Korea, Republic of',
    },
    {
        code: 'KWT',
        name: 'Kuwait',
    },
    {
        code: 'KGZ',
        name: 'Kyrgyzstan',
    },
    {
        code: 'LAO',
        name: "Lao People's Democratic Republic",
    },
    {
        code: 'LVA',
        name: 'Latvia',
    },
    {
        code: 'LBN',
        name: 'Lebanon',
    },
    {
        code: 'LSO',
        name: 'Lesotho',
    },
    {
        code: 'LBR',
        name: 'Liberia',
    },
    {
        code: 'LBY',
        name: 'Libya',
    },
    {
        code: 'LIE',
        name: 'Liechtenstein',
    },
    {
        code: 'LTU',
        name: 'Lithuania',
    },
    {
        code: 'LUX',
        name: 'Luxembourg',
    },
    {
        code: 'MAC',
        name: 'Macao',
    },
    {
        code: 'MKD',
        name: 'Macedonia, the former Yugoslav Republic of',
    },
    {
        code: 'MDG',
        name: 'Madagascar',
    },
    {
        code: 'MWI',
        name: 'Malawi',
    },
    {
        code: 'MYS',
        name: 'Malaysia',
    },
    {
        code: 'MDV',
        name: 'Maldives',
    },
    {
        code: 'MLI',
        name: 'Mali',
    },
    {
        code: 'MLT',
        name: 'Malta',
    },
    {
        code: 'MHL',
        name: 'Marshall Islands',
    },
    {
        code: 'MTQ',
        name: 'Martinique',
    },
    {
        code: 'MRT',
        name: 'Mauritania',
    },
    {
        code: 'MUS',
        name: 'Mauritius',
    },
    {
        code: 'MYT',
        name: 'Mayotte',
    },
    {
        code: 'MEX',
        name: 'Mexico',
    },
    {
        code: 'FSM',
        name: 'Micronesia, Federated States of',
    },
    {
        code: 'MDA',
        name: 'Moldova, Republic of',
    },
    {
        code: 'MCO',
        name: 'Monaco',
    },
    {
        code: 'MNG',
        name: 'Mongolia',
    },
    {
        code: 'MNE',
        name: 'Montenegro',
    },
    {
        code: 'MSR',
        name: 'Montserrat',
    },
    {
        code: 'MAR',
        name: 'Morocco',
    },
    {
        code: 'MOZ',
        name: 'Mozambique',
    },
    {
        code: 'MMR',
        name: 'Myanmar',
    },
    {
        code: 'NAM',
        name: 'Namibia',
    },
    {
        code: 'NRU',
        name: 'Nauru',
    },
    {
        code: 'NPL',
        name: 'Nepal',
    },
    {
        code: 'NLD',
        name: 'Netherlands',
    },
    {
        code: 'NCL',
        name: 'New Caledonia',
    },
    {
        code: 'NZL',
        name: 'New Zealand',
    },
    {
        code: 'NIC',
        name: 'Nicaragua',
    },
    {
        code: 'NER',
        name: 'Niger',
    },
    {
        code: 'NGA',
        name: 'Nigeria',
    },
    {
        code: 'NIU',
        name: 'Niue',
    },
    {
        code: 'NFK',
        name: 'Norfolk Island',
    },
    {
        code: 'MNP',
        name: 'Northern Mariana Islands',
    },
    {
        code: 'NOR',
        name: 'Norway',
    },
    {
        code: 'OMN',
        name: 'Oman',
    },
    {
        code: 'PAK',
        name: 'Pakistan',
    },
    {
        code: 'PLW',
        name: 'Palau',
    },
    {
        code: 'PSE',
        name: 'Palestinian Territory, Occupied',
    },
    {
        code: 'PAN',
        name: 'Panama',
    },
    {
        code: 'PNG',
        name: 'Papua New Guinea',
    },
    {
        code: 'PRY',
        name: 'Paraguay',
    },
    {
        code: 'PER',
        name: 'Peru',
    },
    {
        code: 'PHL',
        name: 'Philippines',
    },
    {
        code: 'PCN',
        name: 'Pitcairn',
    },
    {
        code: 'POL',
        name: 'Poland',
    },
    {
        code: 'PRT',
        name: 'Portugal',
    },
    {
        code: 'PRI',
        name: 'Puerto Rico',
    },
    {
        code: 'QAT',
        name: 'Qatar',
    },
    {
        code: 'REU',
        name: 'Réunion',
    },
    {
        code: 'ROU',
        name: 'Romania',
    },
    {
        code: 'RUS',
        name: 'Russian Federation',
    },
    {
        code: 'RWA',
        name: 'Rwanda',
    },
    {
        code: 'BLM',
        name: 'Saint Barthélemy',
    },
    {
        code: 'SHN',
        name: 'Saint Helena, Ascension and Tristan da Cunha',
    },
    {
        code: 'KNA',
        name: 'Saint Kitts and Nevis',
    },
    {
        code: 'LCA',
        name: 'Saint Lucia',
    },
    {
        code: 'MAF',
        name: 'Saint Martin (French part)',
    },
    {
        code: 'SPM',
        name: 'Saint Pierre and Miquelon',
    },
    {
        code: 'VCT',
        name: 'Saint Vincent and the Grenadines',
    },
    {
        code: 'WSM',
        name: 'Samoa',
    },
    {
        code: 'SMR',
        name: 'San Marino',
    },
    {
        code: 'STP',
        name: 'Sao Tome and Principe',
    },
    {
        code: 'SAU',
        name: 'Saudi Arabia',
    },
    {
        code: 'SEN',
        name: 'Senegal',
    },
    {
        code: 'SRB',
        name: 'Serbia',
    },
    {
        code: 'SYC',
        name: 'Seychelles',
    },
    {
        code: 'SLE',
        name: 'Sierra Leone',
    },
    {
        code: 'SGP',
        name: 'Singapore',
    },
    {
        code: 'SXM',
        name: 'Sint Maarten (Dutch part)',
    },
    {
        code: 'SVK',
        name: 'Slovakia',
    },
    {
        code: 'SVN',
        name: 'Slovenia',
    },
    {
        code: 'SLB',
        name: 'Solomon Islands',
    },
    {
        code: 'SOM',
        name: 'Somalia',
    },
    {
        code: 'ZAF',
        name: 'South Africa',
    },
    {
        code: 'SGS',
        name: 'South Georgia and the South Sandwich Islands',
    },
    {
        code: 'SSD',
        name: 'South Sudan',
    },
    {
        code: 'ESP',
        name: 'Spain',
    },
    {
        code: 'LKA',
        name: 'Sri Lanka',
    },
    {
        code: 'SDN',
        name: 'Sudan',
    },
    {
        code: 'SUR',
        name: 'Suriname',
    },
    {
        code: 'SJM',
        name: 'Svalbard and Jan Mayen',
    },
    {
        code: 'SWZ',
        name: 'Swaziland',
    },
    {
        code: 'SWE',
        name: 'Sweden',
    },
    {
        code: 'CHE',
        name: 'Switzerland',
    },
    {
        code: 'SYR',
        name: 'Syrian Arab Republic',
    },
    {
        code: 'TWN',
        name: 'Taiwan, Province of China',
    },
    {
        code: 'TJK',
        name: 'Tajikistan',
    },
    {
        code: 'TZA',
        name: 'Tanzania, United Republic of',
    },
    {
        code: 'THA',
        name: 'Thailand',
    },
    {
        code: 'TLS',
        name: 'Timor-Leste',
    },
    {
        code: 'TGO',
        name: 'Togo',
    },
    {
        code: 'TKL',
        name: 'Tokelau',
    },
    {
        code: 'TON',
        name: 'Tonga',
    },
    {
        code: 'TTO',
        name: 'Trinidad and Tobago',
    },
    {
        code: 'TUN',
        name: 'Tunisia',
    },
    {
        code: 'TUR',
        name: 'Turkey',
    },
    {
        code: 'TKM',
        name: 'Turkmenistan',
    },
    {
        code: 'TCA',
        name: 'Turks and Caicos Islands',
    },
    {
        code: 'TUV',
        name: 'Tuvalu',
    },
    {
        code: 'UGA',
        name: 'Uganda',
    },
    {
        code: 'UKR',
        name: 'Ukraine',
    },
    {
        code: 'ARE',
        name: 'United Arab Emirates',
    },
    {
        code: 'GBR',
        name: 'United Kingdom',
    },
    {
        code: 'USA',
        name: 'United States',
    },
    {
        code: 'UMI',
        name: 'United States Minor Outlying Islands',
    },
    {
        code: 'URY',
        name: 'Uruguay',
    },
    {
        code: 'UZB',
        name: 'Uzbekistan',
    },
    {
        code: 'VUT',
        name: 'Vanuatu',
    },
    {
        code: 'VEN',
        name: 'Venezuela, Bolivarian Republic of',
    },
    {
        code: 'VNM',
        name: 'Viet Nam',
    },
    {
        code: 'VGB',
        name: 'Virgin Islands, British',
    },
    {
        code: 'VIR',
        name: 'Virgin Islands, U.S.',
    },
    {
        code: 'WLF',
        name: 'Wallis and Futuna',
    },
    {
        code: 'ESH',
        name: 'Western Sahara',
    },
    {
        code: 'YEM',
        name: 'Yemen',
    },
    {
        code: 'ZMB',
        name: 'Zambia',
    },
    {
        code: 'ZWE',
        name: 'Zimbabwe',
    },
];
