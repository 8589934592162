export const ALLOW_EXTRA_REGISTRANTS = 'ALLOW_EXTRA_REGISTRANTS';
export const ADD_EXTRA_REGISTRANTS = 'ADD_EXTRA_REGISTRANTS';
export const INVALID_CARD = 'INVALID_CARD';
export const ABSENT_CARD = 'ABSENT_CARD';
export const CANNOT_ADD_SPEAKER = 'CANNOT_ADD_SPEAKER';
export const CANNOT_ADD_EXHIBITOR = 'CANNOT_ADD_EXHIBITOR';
export const UPGRADE = 'UPGRADE';
export const ATTENDEE = 'ATTENDEE';
export const REGISTRATION = 'REGISTRATION';
// METERED PRICING MODAL TYPES
export const attendeeMeterModalConfig = {
    [ALLOW_EXTRA_REGISTRANTS]: {
        header: 'Pay as you go billing',
        subHeader: 'per additional attendee',
        externalActionUrl: 'https://airmeet.typeform.com/to/NfzPPDdq', // replace url with typeform url
    },
    [ADD_EXTRA_REGISTRANTS]: {
        header: 'Add extra attendee',
        subHeader:
            'You can add extra attendees to the yearly quota by talking to our sales team. ',
        externalActionUrl: 'https://airmeet.typeform.com/to/NfzPPDdq', // replace url with typeform url
    },
    [ABSENT_CARD]: {
        header: 'Enable pay as you go billing',
        subHeader: ' Contact our sales to activate this to your account.',
        externalActionUrl: 'https://airmeet.typeform.com/to/NfzPPDdq', // replace url with typeform url
    },
    [UPGRADE]: {
        header: 'Upgrade to enable pay as you go billing',
        subHeader: ' beyond your plan limits by upgrading your current plan',
    },
    [INVALID_CARD]: {
        header: 'Invalid Card Details',
        subHeader:
            'Looks like the card details provided are incorrect/invalid. Please contact support to add new card details and activate extra attendees',
        externalActionUrl: 'https://airmeet.typeform.com/to/NfzPPDdq', // replace url with typeform url
    },
    [CANNOT_ADD_SPEAKER]: {
        header: 'Cannot add speakers',
        subHeader: '',
        textOne:
            'You cannot add more speakers since you have reached your attendee limit.',
        textTwo: 'To add a new speaker, please allow extra attendees.',
        contactSalesText:
            'To add a new speaker, please contact our sales team to enable extra attendees.',
        freePlanText:
            'To add a new speaker, please allow extra attendees by upgrading to a premium plan.',
    },
    [CANNOT_ADD_EXHIBITOR]: {
        header: 'Cannot add exhibitors',
        subHeader: '',
        textOne:
            'You cannot add more exhibitors since you have reached your attendees limit.',
        textTwo: 'To add a new exhibitor, please allow extra attendees. ',
        contactSalesText:
            'To add a new exhibitor, please contact our sales team to enable extra attendees. ',
        freePlanText:
            'To add a new exhibitor, please allow extra attendees by upgrading to a premium plan.',
    },
};
