import React from 'react';
import InputPicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function TimePicker({
    onChange,
    name,
    defaultTime,
    id,
    min,
    max,
    disabled,
    allowFocus = false,
    timeFormat = 'h:mm aa',
    dateFormat = 'h:mm aa',
    placeholderText = '',
    autoComplete = 'on',
    ...rest
}) {
    // Resetting max if min > max
    max = min < max ? max : min;
    return (
        <>
            <InputPicker
                autoComplete={autoComplete}
                id={id}
                name={name}
                selected={defaultTime}
                onChange={(value) => {
                    onChange({ name, value });
                }}
                placeholderText={placeholderText}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption='Time'
                timeFormat={timeFormat}
                dateFormat={dateFormat}
                maxTime={max}
                minTime={min}
                disabled={disabled}
                // Disabling manual edits, use calendar for selecting values
                onFocus={(e) => {
                    allowFocus && e.target.select();
                    !allowFocus && e.target.blur();
                }}
                {...rest}
            />
        </>
    );
}
