import { Typography } from 'foundations/typography';
import React from 'react';
import usePortal from 'react-cool-portal';
import styled from 'styled-components';
import { color, flexbox, layout, position, space } from 'styled-system';
const noop = () => {};
function InfoBanner({
    content,
    linkContent,
    linkPath,
    position,
    storageKeyName,
    containerId,
    bgColor,
    contentColor,
    linkColor,
    linkLineBreak,
    setVisibility = noop,
    centerText = false,
    isImage = false,
    imagePath = '',
    isUIBanner = false,
    onCancelBanner = noop,
    onClickLink = noop,
    linkUnderline = false,
    hideCrossBtn = false,
    topValue,
    leftValue,
    isFullWidth = true,
    bannerWidth,
    moveLogoToTheRightForMobile = false,
    shouldClearEmptySpaceBeforeLinkContent = false,
    ...customStyles
}) {
    const { Portal } = usePortal({
        containerId: containerId,
        clickOutsideToHide: false,
        escToHide: false,
    });

    const handlePageOpen = (path) => {
        path && window.open(path, '_blank');
        if (isUIBanner) {
            onClickLink();
            return;
        }
    };
    const bannerHide = () => {
        if (isUIBanner) {
            onCancelBanner();
            return;
        }
        localStorage.setItem(storageKeyName, 'false');
        setVisibility(false);
    };
    return (
        <Portal>
            <Box
                justifyContent={[
                    centerText ? 'center' : 'flex-start',
                    'center',
                ]}
                px={['24px', '0px']}
                py={['10px', '20px', '10px']}
                pr={[centerText ? '24px' : 'calc(100vw - 240px)', null]}
                position={position}
                bgColor={bgColor}
                isUIBanner={isUIBanner}
                isFullWidth={isFullWidth}
                width={bannerWidth}
                topValue={topValue}
                leftValue={leftValue}
                className='am-info-banner'
                {...customStyles}
            >
                {isImage && (
                    <ImageWrapper className='bannerImg-wrapper'>
                        <Span
                            className='bannerImg'
                            top={moveLogoToTheRightForMobile ? '0px' : '-13px'}
                            right={
                                moveLogoToTheRightForMobile ? '193px' : '10px'
                            }
                        >
                            <Image
                                src={imagePath}
                                alt='Rocket'
                                title='Rocket'
                                width={
                                    moveLogoToTheRightForMobile ? '40px' : ''
                                }
                            />
                        </Span>
                    </ImageWrapper>
                )}
                <Typography
                    variant='subtext1'
                    color={contentColor}
                    mr={1}
                    textAlign={[
                        centerText ? 'center' : 'flex-start',
                        'initial',
                    ]}
                    className='am-info-banner--content'
                >
                    {content}
                    {linkContent ? (
                        <>
                            {' '}
                            <PrivacyLink
                                variant='span'
                                color={linkColor}
                                mr={1}
                                ml={
                                    isUIBanner &&
                                    !shouldClearEmptySpaceBeforeLinkContent &&
                                    '12px'
                                }
                                linkLineBreak={linkLineBreak}
                                linkUnderline={linkUnderline}
                                onClick={() => handlePageOpen(linkPath)}
                            >
                                {linkContent}
                            </PrivacyLink>
                        </>
                    ) : null}
                </Typography>
                {!hideCrossBtn && (
                    <IconWrap
                        onClick={bannerHide}
                        position={['absolute', 'static']}
                        top={['10px', 'auto']}
                        right={['20px', 'auto']}
                        className='am-info-banner--close'
                    >
                        <Typography variant='subtext1' color={contentColor}>
                            &#10005;
                        </Typography>
                    </IconWrap>
                )}
            </Box>
        </Portal>
    );
}
export default InfoBanner;

const Box = styled.div`
    ${space}
    ${color}
    ${layout}
    width: ${({ isFullWidth }) => isFullWidth && '100vw'};
    position: fixed;
    bottom: ${({ position }) => (position === 'top' ? 'auto' : '0px')};
    top: ${({ position }) => (position === 'top' ? '0px' : 'auto')};
    background-color: ${({ bgColor }) => bgColor};
    z-index: 999;
    top: ${({ topValue }) => topValue && topValue};
    left: ${({ leftValue }) => leftValue && leftValue};
    display: flex;
    flex-wrap: wrap;
    ${flexbox};
`;
const Image = styled.img``;
const IconWrap = styled.div`
    ${position}
    cursor: pointer;
`;
const ImageWrapper = styled.div`
    position: relative;
    padding-right: 45px;
`;

const Span = styled.span`
    position: absolute;
    top: ${(props) => props.top} /* -13px */;
    right: ${(props) => props.right} /* 10px */;
`;

const PrivacyLink = styled(Typography)`
    ${(props) =>
        props.linkLineBreak &&
        `
        display: block;
    `}
    cursor: pointer;
    ${({ linkUnderline, color }) =>
        linkUnderline &&
        `text-decoration-line: underline; text-decoration-color: ${color}; `}
`;
