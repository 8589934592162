import LRUCache from 'quick-lru';
import { API_SERVICE, callApi } from 'utils/apiService';
import { getCroppedAttendeeImageURL } from 'utils/constants/users';
import { bindModuleLogger } from 'utils/logger';
import UserRoles from 'utils/userRoles';
import UserIdService from './UserIdService';
import { User, UserFetchOptions, UserServiceParams } from './types';

const logger = bindModuleLogger('User Service');

interface LoadOptions {
    useFallback?: boolean;
    fallbackLoadOptions?: UserFetchOptions;
}

export const SUBSCRIPTION_DELAY = 10000;
export default class UserService {
    private static service: UserService;
    static getInstance(): UserService {
        if (UserService.service) {
            return UserService.service;
        }
        throw new Error('Call before set instance');
    }

    static newInstance(params: UserServiceParams): UserService {
        if (UserService.service) {
            return UserService.service;
        }
        UserService.service = new UserService(params);
        return UserService.service;
    }

    private params: UserServiceParams;
    private cache: LRUCache<string, User>;
    private prioritizedCache: LRUCache<string, User>;
    private ongoingRequests: Map<string, Promise<User>>;
    private userIdService: UserIdService;

    constructor(params: UserServiceParams) {
        this.cache = new LRUCache({ maxSize: 2000 });
        this.prioritizedCache = new LRUCache({ maxSize: 500 });
        this.params = params;
        this.ongoingRequests = new Map();
        this.userIdService = UserIdService.getInstance(params);
    }

    async preFetchUsers() {
        const URL = `/airmeet/${this.params.airmeetId}/users`;

        try {
            let results = await callApi({
                endpoint: URL,
            });

            if (results?.length > 0) {
                this.addUsers(results);
            }
        } catch (e) {
            logger.error('Failed to fetch users', e);
        }
    }

    fetchUsers(
        userIds: Array<string>,
        options?: UserFetchOptions
    ): Promise<User[]> {
        const promiseQueue: Array<Promise<User>> = [];

        const needToFetchUserIds = {};
        logger.debug('Loading data from fetchUsers API', userIds);
        userIds.forEach((userId) => {
            if (!userId) {
                return;
            }

            if (true !== options?.force) {
                if (this.hasCached(userId)) {
                    const cachedUser = this.getCached(userId);
                    logger.debug('Loading data from cache', userId);
                    promiseQueue.push(Promise.resolve(cachedUser));
                    return;
                }
            }

            if (this.ongoingRequests.has(userId)) {
                logger.debug('Multiplexing API calls', userId);
                promiseQueue.push(this.ongoingRequests.get(userId));
                return;
            }

            needToFetchUserIds[userId] = {};
            const userPromise = new Promise<User>(async (resolve, reject) => {
                needToFetchUserIds[userId].resolve = resolve;
                needToFetchUserIds[userId].reject = reject;
            });
            needToFetchUserIds[userId].userPromise = userPromise;
            promiseQueue.push(userPromise);
            this.ongoingRequests.set(userId, userPromise);
        });

        const userFilter = Object.keys(needToFetchUserIds);

        if (userFilter.length === 0) {
            return Promise.all(promiseQueue);
        }
        logger.debug('Loading data from fetchUsers API Call', userFilter);
        const usersPromise = new Promise<User[]>(async (resolve, reject) => {
            try {
                let results = await this.callUsersAPI(userFilter, options);
                logger.debug('Loaded data from API', userFilter, results);
                results.forEach((response) => {
                    const userId = response.id;
                    this.onUserApiResult(userId, response, options?.prioritize);
                    needToFetchUserIds[userId]?.resolve(this.getCached(userId));
                    delete needToFetchUserIds[userId];
                });
                // Handle the users which not found from response
                userFilter.forEach((userId) => {
                    if (!needToFetchUserIds[userId]) {
                        return;
                    }
                    needToFetchUserIds[userId].reject('USER_NOT_FOUND');
                    this.ongoingRequests.delete(userId);
                });
                const allSettled = await Promise.allSettled(promiseQueue);
                const filterResolve = allSettled.filter(
                    (row) => row.status === 'fulfilled'
                );
                const foundUsers = filterResolve.map((row) => row?.value);
                return resolve(foundUsers);
            } catch (e) {
                userFilter.forEach((userId) => {
                    needToFetchUserIds[userId].reject(e);
                    this.ongoingRequests.delete(userId);
                });
                logger.error('Failed loading data from API', userFilter, e);
                reject(e);
            }
        });
        return usersPromise;
    }

    fetchUser(userId: string, options?: UserFetchOptions): Promise<User> {
        if (!userId) throw new Error('User id is a required field');
        logger.debug('Loading data from API', userId);
        return new Promise<User>(async (resolve, reject) => {
            try {
                const users = await this.fetchUsers([userId], options);
                if (users.length === 0) {
                    reject('USER_NOT_FOUND');
                }
                resolve(this.getCached(userId));
            } catch (e) {
                reject(e);
            }
        });
    }

    private async callUsersAPI(
        userFilter: Array<string>,
        options?: UserFetchOptions
    ) {
        if (options?.includeUnregistered) {
            return this.callUnregisteredUsersAPI(userFilter, options);
        } else {
            return this.callRegisteredUsersAPI(userFilter, options);
        }
    }

    private async callUnregisteredUsersAPI(
        userFilter: Array<string>,
        options?: UserFetchOptions
    ) {
        const body = {
            pageNumber: 1,
            pageSize: 500,
            sortKey: 'name',
            sortDirection: 'ASC',
            searchKey: 'id',
            fullData: false,
            searchValues: userFilter,
        };
        let results = await callApi({
            endpoint: `/${this.params.airmeetId}/participants-search`,
            type: 'json',
            method: 'POST',
            body,
            appendBaseUrl: true,
            service: API_SERVICE.BFF,
            credentials: 'include',
        });
        return results?.registrants;
    }

    private callRegisteredUsersAPI(
        userFilter: Array<string>,
        options?: UserFetchOptions
    ) {
        const URL = `/airmeet/${this.params.airmeetId}/users`;
        const query = {
            filter: userFilter.join(','),
        };
        if (userFilter.length > 1) {
            // @ts-ignore: Specific use case
            query.recall = 'dfd';
        }
        return callApi({
            endpoint: URL,
            query,
        });
    }

    private onUserApiResult(userId, response, prioritize) {
        if (!response) {
            return;
        }
        response = {
            ...response,
            profile_img: getCroppedAttendeeImageURL(response.profile_img),
            profile_img_original: response.profile_img,
        };

        this.onUserLoaded(userId, response, prioritize);
        this.ongoingRequests.delete(userId);
    }

    subscribe(
        id: string,
        callback: (user: User) => void,
        loadOptions: LoadOptions = { useFallback: true }
    ): Function {
        logger.debug('Adding subscription', id);

        const onSnapShot = async (snapshot) => {
            const value = snapshot.payload;
            if (!value && loadOptions.useFallback) {
                let response = await this.fetchUser(
                    id,
                    loadOptions.fallbackLoadOptions
                );
                if (response) {
                    const userData = response;
                    this.onUserLoaded(id, userData);
                    callback(this.getCached(id));
                    return;
                }
            }

            if (value) {
                this.onUserLoaded(id, value?.info);
                callback(this.getCached(id));
            }
        };

        const unsubscribe = this.params.observer.subscribe(onSnapShot, {
            userId: id,
        });

        if (this.hasCached(id)) {
            const cachedUser = this.getCached(id);
            logger.debug('Sending data from cache', id);
            callback(cachedUser);
        }

        return () => {
            logger.debug('Removing subscription', id);
            unsubscribe && unsubscribe();
        };
    }

    hasCached(id: string): boolean {
        return this.cache.has(id) || this.prioritizedCache.has(id);
    }

    getCached(id: string): User | undefined {
        return this.cache.get(id) || this.prioritizedCache.get(id);
    }

    addUsers(users: Array<User>) {
        users.forEach((user) => {
            if (user?.name) {
                user.profile_img = getCroppedAttendeeImageURL(user.profile_img);
                this.onUserLoaded(user.id, user, true);
            }
        });
    }

    // call instance from here
    private onUserLoaded(id: string, user: User, isPriority: boolean = false) {
        if (!user) {
            return;
        }
        user.isLoading = false;
        let update = { ...(this.getCached(id) || {}), ...user };
        this.cache.set(id, update);
        if (isPriority || this.prioritizedCache.has(id)) {
            this.prioritizedCache.set(id, update);
        }
        UserRoles.setUser(update);

        this.userIdService.onUserIdDetailsLoaded(user.id_seq, update);
    }
}
