export const BANNER_NAME = {
    CTA_BANNER: 'ctaBanner',
};

export const STAGE_BANNERS = {
    CTA_BANNER: 'ctaBanner',
    LOWER_THIRDS: 'lowerThirds',
};

export const BANNER_TYPES = {
    STAGE_CTA_BANNER: 'STAGE_CTA_BANNER',
    STAGE_LOWER_THIRDS: 'STAGE_LOWER_THIRDS',
};

export const BANNER_STATE = {
    DRAFT: 'DRAFT',
    PUBLISHED: 'PUBLISHED',
};

export const BANNER_VARIANTS = {
    [BANNER_TYPES.STAGE_CTA_BANNER]: {
        TOAST: 'TOAST',
        FULLSCREEN: 'FULLSCREEN',
    },
    [BANNER_TYPES.STAGE_LOWER_THIRDS]: {
        STATIC: 'STATIC',
    },
};

export const BANNER_LIVE_PREVIEW = 'BANNER_LIVE_PREVIEW';

export const getBannerImageKey = (airmeetId, bannerName) => {
    if (!airmeetId) {
        throw new Error('airmeetId is required to getBannerImageKey');
    }
    if (!bannerName) {
        throw new Error('bannerName is required to getBannerImageKey');
    }
    return `${airmeetId}/banners/bannerImages/${bannerName}`;
};

export const DEFAULT_BANNER_DATA = {
    [STAGE_BANNERS.CTA_BANNER]: {
        description: '',
        bannerType: BANNER_TYPES.STAGE_CTA_BANNER,
        variant: 'TOAST',
    },
    [STAGE_BANNERS.LOWER_THIRDS]: {
        description: '',
        bannerType: BANNER_TYPES.STAGE_LOWER_THIRDS,
        variant: 'STATIC',
    },
};

export const BANNER_CREATION_STEP = {
    [BANNER_TYPES.STAGE_CTA_BANNER]: 2,
    [BANNER_TYPES.STAGE_LOWER_THIRDS]: 1,
};
