import createReducer from '../../utils/createReducer';
import {
    UPDATE_SHOW_DM_NOTIFICATION,
    SET_LAST_DM_NOTIFICATION_TIME,
} from '../actions/DmNotifications';

const initialState = {
    showDMNotification: false,
    lastDMNotificationTime: 0,
};

const handlers = {
    [UPDATE_SHOW_DM_NOTIFICATION]: (state = initialState, { payload }) => {
        return {
            ...state,
            showDMNotification: payload,
        };
    },
    [SET_LAST_DM_NOTIFICATION_TIME]: (state = initialState, { payload }) => {
        return {
            ...state,
            lastDMNotificationTime: payload,
        };
    },
};

export default createReducer(initialState, handlers);
