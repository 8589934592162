import { MODAL_TYPE_VIDEO } from 'components/modals';
import useToasts from 'hooks/useToasts';
import useVideoUploader from 'hooks/useVideoUploader';
import moment from 'moment';
import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showFixedModal } from 'store/actions/layout';
import { noop } from 'utils/constants/common';
import { TOAST_VERSION_2 } from 'utils/constants/toasts';

/**
 * 5GB
 */
const MAX_VIDEO_UPLOAD_SIZE = 5000000000;
const SUPPORTED_FILE_TYPES = ['video/mp4'];

interface hookProps {
    videosList?: Array<any> | null;
    refreshGrid?: Function | null;
    mapVideoToSession?: Function | null;
    onClose?: Function;
    formData?: Object | null;
}

interface fileDataType {
    poster: string;
    file: File;
    capturePosterAfterUpload: Boolean;
    path: string;
    status: string;
}

const getUserId = (state) => state.auth.user.id;
const getCommunityDetails = (state) => state.cmDashboard.airmeet;

const usePreRecordedSessionVideoUploader = ({
    videosList = [],
    refreshGrid = noop,
    mapVideoToSession = noop,
    onClose = noop,
    formData = {},
}: hookProps = {}) => {
    const [fileData, setFileData] = useState<fileDataType>({
        file: null,
        poster: null,
        capturePosterAfterUpload: true,
        path: '',
        status: 'not-started',
    });

    const progressRef = useRef(null);
    const progPerRef = useRef(null);
    const addToLibraryRef = useRef({ checked: true });

    const userId = useSelector(getUserId);
    const community = useSelector((state) => state.Community.current);
    const {
        community_id: communityId,
        airmeetId,
        community_name: communityName,
    } = useSelector(getCommunityDetails);
    const dispatch = useDispatch();
    const { successToast, warningToast, errorToast } = useToasts();
    const {
        submitHandler,
        uploadStatus,
        uploadError,
        abortUpload,
        loading,
        uploadProgress,
        thumbnailURL,
        videoTitle,
        resetState: resetVideoUploader,
    } = useVideoUploader({
        progPerRef,
        progressRef,
        setFileData,
        fileData,
        setModelState: () => {},
        entityData: {
            entity_type: airmeetId ? 'airmeet' : 'community',
            entity_id: airmeetId ? airmeetId : community.communityId,
        },
        communityData: {
            entity_type: 'community',
            entity_id: community.communityId,
        },
        logData: {
            airmeet_id: airmeetId,
            community_id: communityId,
            community_name: communityName,
            userId,
        },
        returnProgressData: true,
        addToLibraryRef,
        communityVideos: videosList,
        refreshCommunityVideos: true,
        skipAirmeetMapping: airmeetId ? true : false,
        videoItems: [],
        refreshGrid,
        showUploadCancelledToast: false,
        formData,
    });

    const handleSubmit = useRef(submitHandler);

    const handleOnSelect = useCallback(
        (event) => {
            if (event?.dataTransfer?.files?.length > 1) {
                return warningToast({
                    message:
                        'Please select a single video file, this session type does not currently support multiple videos.',
                    version: TOAST_VERSION_2,
                });
            }

            const [file] = event?.target?.files?.length
                ? event.target.files
                : event?.dataTransfer?.files?.length
                ? event.dataTransfer.files
                : [];

            resetInput();

            if (!file) {
                throw new Error(
                    `No file selected, ${{
                        file,
                        eventTarget: event.target,
                        eventDataTransfer: event.dataTransfer,
                    }}`
                );
            }

            /**
             * Check if file is supported
             */
            if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
                return errorToast({
                    message: `Sorry, we only support ${SUPPORTED_FILE_TYPES.map(
                        (f) => f.split('/')[1]
                    ).join(' and ')} videos.`,
                    version: TOAST_VERSION_2,
                });
            }

            if (file.size > MAX_VIDEO_UPLOAD_SIZE) {
                return errorToast({
                    message:
                        'File size is too large. Please choose a file less than 5GB.',
                    version: TOAST_VERSION_2,
                });
            }

            setFileData((prev) => ({
                ...prev,
                file,
            }));

            handleSubmit.current({ file });
        },
        [errorToast, warningToast]
    );

    const handlePreviewVideo = () => {
        dispatch(
            showFixedModal(MODAL_TYPE_VIDEO, {
                backdropStyles: {
                    zIndex: 1051,
                },
                videoOptions: {
                    sources: [
                        {
                            type: 'video/mp4',
                            src: fileData.path,
                        },
                    ],
                    autoplay: true,
                },
            })
        );
    };

    const clearSelection = useCallback(() => {
        setFileData({
            file: null,
            status: 'not-started',
            path: '',
            poster: null,
            capturePosterAfterUpload: true,
        });
        resetVideoUploader();

        successToast({
            message:
                'Your selection has been cleared. Please upload a new video.',
            version: TOAST_VERSION_2,
        });
    }, [resetVideoUploader]);

    const uploadNew = useCallback(() => {
        document.getElementById('sessionVideo').click();
    }, []);

    const onSubmit = useCallback(async () => {
        if (uploadError) {
            submitHandler({ file: fileData.file });

            return;
        }

        const duration = await getVideoDuration(fileData.path, true);

        mapVideoToSession({
            videoInfo: {
                url: fileData.path,
                type: 'video/mp4',
                owner: '',
                title: videoTitle,
                thumbnail: thumbnailURL,
                created: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                duration,
            },
            clear: false,
            duration,
        });
        onClose();
    }, [
        onClose,
        mapVideoToSession,
        submitHandler,
        fileData.path,
        thumbnailURL,
        videoTitle,
        uploadError,
        fileData,
    ]);

    const onCancel = useCallback(() => {
        abortUpload();
        clearSelection();
    }, [abortUpload, clearSelection]);

    const getVideoDuration = (video: string, inMinutes: boolean) => {
        return new Promise((resolve) => {
            const videoElement = document.createElement('video');
            videoElement.src = video;
            videoElement.onloadedmetadata = () => {
                const duration = videoElement.duration;
                // clear the video element and remove it from the DOM
                videoElement.src = '';
                videoElement.remove();

                resolve(inMinutes ? Math.ceil(duration / 60 / 1) : duration);
            };
        });
    };

    const resetInput = () => {
        const videoInputEl = <HTMLInputElement>(
            document.getElementById('sessionVideo')
        );

        if (videoInputEl) {
            videoInputEl.value = null;
        }
    };

    return {
        fileData,
        handleOnSelect,
        submitHandler,
        uploadStatus,
        uploadError,
        abortUpload,
        loading,
        uploadProgress,
        thumbnailURL,
        videoTitle,
        handlePreviewVideo,
        clearSelection,
        uploadNew,
        onCancel,
        onSubmit,
        getVideoDuration,
    };
};

export default usePreRecordedSessionVideoUploader;
