import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';

export const UPDATE_USER_INFO_INTERVAL_MS = 120000;

export const USER_ROLE = {
    HOST: 'host',
    SPEAKER: 'speaker',
    ATTENDEE: 'attendee',
    ORGANIZER: 'organizer',
    EXHIBITOR: 'exhibitor',
    COHOST: 'cohost',
};

export const ADMIN_USER_ROLES = [USER_ROLE.HOST, USER_ROLE.ORGANIZER];

export const TABLE_ROLE = {
    MODERATOR: 'moderator',
};

export const EVENT_MODERATOR = 'EVENT_MODERATOR';
export const CLOUD_USER = 'cloud_user';

export const AIRMEET_DOMAIN = 'airmeet.com';

export const getCroppedAttendeeImageURL = (originalUrl, w = 136, h = 136) =>
    !originalUrl || originalUrl.includes('?')
        ? originalUrl
        : `${originalUrl}?w=${w}&h=${h}`;

export const getHash = (user) => {
    const isCustomRegistration = !isEmpty(user?.userProfile);
    let str = '';
    if (isCustomRegistration) {
        str =
            Object.values(user?.userProfile || {}).join('') + user.profile_img;
    } else {
        str = `${
            user.name +
            user.profile_img +
            user.designation +
            user.company +
            user.city +
            user.country +
            !!user.guest
        }`;
    }
    var hash = 0,
        i,
        chr;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

export const userTransform = (user) => {
    return {
        ...user,
        hash: getHash(user),
        profile_img: getCroppedAttendeeImageURL(user.profile_img),
        profile_img_original: user.profile_img,
    };
};

export const getUserInfo = (
    user,
    params = {},
    is_custom_registration_enabled = true
) => {
    const userProfile = user?.userProfile;
    return {
        city: user.city || '',
        company: user.company || '',
        country: user.country || '',
        designation: user.designation || '',
        guest: user.guest || null,
        id: user.id || null,
        id_seq: user.id_seq || null,
        name: user.name,
        first_name: user.firstName || '',
        last_name: user.lastName || '',
        profile_img: user.profile_img,
        tags: [],
        user_type: user.user_type || '',
        airmeetRoles: user.airmeetRoles || null,
        hash: user.hash ? user.hash : getHash(user),
        ...params,
        ...(is_custom_registration_enabled && {
            userProfile: Array.isArray(userProfile)
                ? transformUserProfile(userProfile, 'fieldId', 'value')
                : pickBy(userProfile, identity),
        }),
    };
};

function transformUserProfile(array, keyName, valueName) {
    return array.reduce((acc, val) => {
        acc[val[keyName]] = val[valueName];
        return acc;
    }, {});
}

export const USER_ATTENDANCE_TYPES = {
    IN_PERSON: 'IN_PERSON',
    HYBRID: 'HYBRID',
    ONLINE: 'ONLINE',
};
