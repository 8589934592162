import { FlexDiv } from 'components/molecules/utils/basicComponents';
import useTheme from 'hooks/useTheme';
import React from 'react';
import styled from 'styled-components';
import { getAmbienceColorId } from 'utils/core';

export default function WhiteSideBar({
    children,
    newOnBoardingFlow = false,
    ambienceCode = '2',
    overRideRelativeCss = false,
    hasSeparator = false,
    pageMode = false,
    maxWidth = '472px',
    containerStyles = {},
}) {
    const { theme } = useTheme();
    const getColorId = (colorId) =>
        getAmbienceColorId({
            colorId,
            isAgnostic: newOnBoardingFlow,
            theme,
        });

    return (
        <LayoverContainer
            hasRelativeArrow={true}
            ambienceColorId={getColorId}
            pageMode={pageMode}
            ambienceCode={ambienceCode}
            overRideRelativeCss={overRideRelativeCss}
            maxWidth={maxWidth}
            style={{ ...containerStyles }}
        >
            <InnerContainer>{children}</InnerContainer>
            {hasSeparator && <Separator />}
        </LayoverContainer>
    );
}
const InnerContainer = styled(FlexDiv)`
    width: 100%;
`;
const LayoverContainer = styled(FlexDiv)`
    position: fixed;
    top: 0;
    right: 0;
    background-color: ${({ theme, ambienceColorId, ambienceCode }) =>
        theme.colors.ambience[ambienceColorId(ambienceCode)]};
    height: 100%;
    max-height: 100%;
    padding: 32px 32px 40px;
    align-items: flex-start;
    z-index: 100000;
    max-width: ${({ maxWidth }) => maxWidth};
    width: ${({ pageMode }) => (pageMode ? '30vw' : '100%')};
    animation: slideIn 0.2s linear;
    ${InnerContainer} {
        flex: 1;
        position: ${({ overRideRelativeCss }) =>
            overRideRelativeCss ? 'unset' : 'relative'};
        z-index: 2;
        height: 100%;
        flex-direction: column;
        max-width: 100%;
    }
    @keyframes slideIn {
        0% {
            right: -1000px;
        }
        100% {
            right: 0;
        }
    }
`;

const Separator = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.ambience[3]};
    position: absolute;
    bottom: 116px;
    width: 100%;
    left: 0;
`;
