import React from 'react';
import styled from 'styled-components';

export default function LoaderV2({
    size = 40,
    color = null,
    isThemeV2 = false,
}) {
    return (
        <Wrapper size={size} color={color} isThemeV2={isThemeV2}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    margin: auto;
    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: ${({ size }) => size}px;
        height: ${({ size }) => size}px;
        margin: ${({ size }) => size / 10}px;
        border: ${({ size }) => size / 10}px solid
            ${({ color, theme, isThemeV2 }) =>
                color ||
                (isThemeV2
                    ? theme.colors.brandDefault
                    : theme.colors.accentPrimary[0])};
        border-radius: 50%;
        animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${({ color, theme, isThemeV2 }) =>
                color ||
                (isThemeV2
                    ? theme.colors.brandDefault
                    : theme.colors.accentPrimary[0])}
            transparent transparent transparent;
    }
    div:nth-child(1) {
        animation-delay: -0.45s;
    }
    div:nth-child(2) {
        animation-delay: -0.3s;
    }
    div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
