import { DataReaderOptions } from 'hooks/useDataReader';
import { bindModuleLogger } from 'utils/logger';
import {
    DataObserver,
    Listeners,
    QueryParams,
    RealTimeDataSource,
    Unsubscribe,
    WriteStatus,
} from '../types';
import LocalUpdates from './LocalUpdates';

const logger = bindModuleLogger('DataObserver Base');

class DataObserverBase implements DataObserver {
    public featureName: string;
    private dataSource: RealTimeDataSource;
    private dataSourcePromise: Promise<RealTimeDataSource>;
    private dataSourcePromiseResolve: Function;

    public airmeetId: string;
    public sessionId: string;
    public userId: string;

    constructor(options: DataReaderOptions) {
        this.dataSourcePromise = new Promise((resolve) => {
            this.dataSourcePromiseResolve = resolve;
        });
        this.airmeetId = options?.airmeetId;
        this.sessionId = options?.sessionId;
        this.userId = options?.userId;
    }

    setDataSource(realtimeDataSource: RealTimeDataSource) {
        this.dataSource = realtimeDataSource;
        this.dataSourcePromiseResolve(realtimeDataSource);
    }

    subscribeImpl(
        channel: string,
        listeners: Listeners,
        options?: any
    ): Unsubscribe {
        const originalOnAdd = listeners.onAdd;
        const unsubscribeLocalUpdates = LocalUpdates.getInstance().subscribe(
            this.featureName,
            {
                onAdd: (message) => {
                    logger.debug(
                        `Local ${this.featureName} ${message.metadata.key} added`,
                        message
                    );
                    originalOnAdd && originalOnAdd(message);
                },
                onChange: (message) => {
                    logger.debug(
                        `Local ${this.featureName} ${message.metadata.key} updated`,
                        message
                    );
                    listeners.onChange && listeners.onChange(message);
                },
            }
        );

        if (this.dataSource) {
            logger.debug(
                `Enabling listeners ${Object.keys(
                    listeners
                )} for Channel ${channel}`
            );
            const attachLocalWrapper = (key) => {
                const original = listeners[key];
                if (!original) {
                    return;
                }
                logger.debug(
                    `Wrapped ${this.featureName} ${key} with local update handler`
                );
                listeners.onAdd = (message) => {
                    if (
                        false ===
                        LocalUpdates.getInstance().notifyStatusChange(
                            this.featureName,
                            message,
                            WriteStatus.SUCCESS
                        )
                    ) {
                        original(message);
                    }
                };
            };

            attachLocalWrapper('onAdd');
            attachLocalWrapper('onMessage');

            const unsubscribeData = this.dataSource.subscribe(
                channel,
                listeners,
                options
            );

            return () => {
                unsubscribeData && unsubscribeData();
                unsubscribeLocalUpdates && unsubscribeLocalUpdates();
            };
        } else {
            //
            return this.createPendingSubscriptionTask(
                channel,
                listeners,
                options
            );
        }
    }

    createPendingSubscriptionTask(
        channel: string,
        listeners: Listeners,
        options?: any
    ): Unsubscribe {
        let unsubscribe: Unsubscribe;
        logger.debug(`Waiting for ${this.dataSource} to set for ${channel}`);
        this.dataSourcePromise.then(() => {
            unsubscribe = this.subscribeImpl(channel, listeners, options);
        });

        return () => {
            unsubscribe && unsubscribe();
        };
    }

    subscribe(onData: Function, metadata: any): Unsubscribe {
        throw new Error('Not implemented in base class');
    }

    fetch(onData: Function, metadata: any): void {
        throw new Error('Not implemented in base class');
    }

    fetchImpl(query: QueryParams): Promise<any> {
        return this.dataSource.fetch(query);
    }

    fetchOnce(metadata?: any): Promise<Array<Object>> {
        return this.dataSource.fetchOnce(metadata);
    }
}

export default DataObserverBase;
