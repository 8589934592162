import { StyledSvgSprite } from 'components/general/SVGSprite';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text/';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import useTheme from 'hooks/useTheme';
import keys from 'locale/keys';
import React, { useMemo } from 'react';
import { checkColorContrast, stringToRgb } from 'utils/colors';
import ImageRadioGroup from './ImageRadioGroup';

const CARD_BG_OPTIONS = [
    { value: 'glassmorphic', label: 'Glassmorphic' },
    { value: 'solidColor', label: 'Solid color', colorInput: true },
];

const DEFAULT_CARD_BG_ID = CARD_BG_OPTIONS[0]?.value;

export default function CardBgSetting({ live_config, onChange, theme_info }) {
    const { t } = useCustomTranslation();
    const { theme } = useTheme();

    const handleChange = (value) => {
        onChange &&
            onChange(
                'live_config',
                {
                    ...(live_config?.eventBranding?.card || {}),
                    ...(value || {}),
                },
                'card'
            );
    };

    const isGoodContrast = useMemo(() => {
        if (
            live_config?.eventBranding?.card?.bgType !== 'solidColor' ||
            !live_config?.eventBranding?.card?.bgColor
        )
            return true;

        const fontColor = theme.colors.text.default.secondary;
        return checkColorContrast(
            stringToRgb(fontColor),
            live_config?.eventBranding?.card?.bgColor
        );
    }, [live_config, theme]);

    return (
        <>
            <Text variant='h5' color='text.default.primary' pb='4px' pt='16px'>
                {t(keys.RHS_CUSTOMIZE_CARD_PANEL_BG_TITLE)}
            </Text>
            <Text variant='caption' color='text.default.secondary' pb='1em'>
                {t(keys.RHS_CUSTOMIZE_CARD_PANEL_BG_SUBTITLE)}
            </Text>
            <ImageRadioGroup
                options={CARD_BG_OPTIONS}
                onValueChange={handleChange}
                value={
                    live_config?.eventBranding?.card?.bgType ||
                    DEFAULT_CARD_BG_ID
                }
                bgColor={live_config?.eventBranding?.card?.bgColor}
                type='cardBg'
            />
            {!isGoodContrast && (
                <FlexDiv alignItems='center' pb='x4'>
                    <StyledSvgSprite
                        icon='error_circle'
                        fill='sem.warning'
                        height='14px'
                        width='14px'
                        minWidth='14px'
                        minHeight='14px'
                        mr='6px'
                    />
                    <Text variant='caption' color='text.default.primary'>
                        This color would create readability issues with the
                        selected ambience.
                    </Text>
                </FlexDiv>
            )}
        </>
    );
}
