import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import { logger } from '../../utils/logger';

const { ONLINE_EXHIBITORS } = FEATURE_NAMES;

export const EXHIBITOR_ACTIONS = {
    JOIN_BOOTH: 'JOIN_BOOTH',
    LEAVE_BOOTH: 'LEAVE_BOOTH',
    START_BOOTH_BROADCAST: 'START_BOOTH_BROADCAST',
};

const wrapTransaction = async (transactionFunction, message) => {
    let err;
    try {
        const { committed } = await transactionFunction();
        err = (!committed && message) || !committed;
    } catch (error) {
        logger.error('Error running Firebase transaction', error);
    }

    return { error: err };
};

const onlineExhibitorsWriter = async ({
    airmeetId,
    client,
    payload,
    metaData,
    logger,
}) => {
    const {
        action,
        isOnline,
        onError,
        boothId,
        authUserId,
        isUserExhibitor,
    } = metaData;
    const { reserveData } = payload;

    const basePath = `${airmeetId}/booths/${boothId}`;
    const exhibitorPath = `${basePath}/liveInfo/onlineExhibitors/${authUserId}`;
    const broadcastPath = `${basePath}/liveInfo/broadcast`;
    const newBoothPath = `${airmeetId}/booths`;
    const newExhibitorPath = `${newBoothPath}/onlineExhibitors/${boothId}/${authUserId}`;
    const newBroadcastPath = `${newBoothPath}/liveBroadcast/${boothId}`;
    switch (action) {
        case EXHIBITOR_ACTIONS.JOIN_BOOTH:
            if (!boothId || !isUserExhibitor) return;
            if (isOnline) {
                client.setData(exhibitorPath, true);
                client.setData(newExhibitorPath, true);
            }
            client.setOnDisconnect(exhibitorPath, null);
            client.setOnDisconnect(newExhibitorPath, null);

            // remove exhibitor from subscriptions list if any
            // happens when user had attendee role and subscribed for notification,
            // and after promoted as exhibitor
            client.setData(`${basePath}/subscriptions/${authUserId}`, null);
            break;
        case EXHIBITOR_ACTIONS.LEAVE_BOOTH:
            client.setData(exhibitorPath, null);
            client.setData(newExhibitorPath, null);
            break;
        case EXHIBITOR_ACTIONS.START_BOOTH_BROADCAST:
            const reserve = async (reserveData, onError, path) => {
                logger.info('Reserving booth session', reserveData);
                const { error } = await wrapTransaction(
                    () =>
                        client.runTransaction(
                            path,
                            function (user) {
                                if (!user || user.exhibitor === authUserId) {
                                    client.replaceOnDisconnect(path, null);
                                    return reserveData;
                                }
                                return;
                            },
                            null,
                            true
                        ),
                    'Another exhibitor already started the session'
                );

                if (error) {
                    onError &&
                        onError(
                            typeof error === 'string'
                                ? error
                                : 'Another exhibitor already started the session'
                        );
                    return false;
                } else {
                    return true;
                }
            };
            return [broadcastPath, newBroadcastPath].every((path) => {
                return reserve(reserveData, onError, path);
            });
        default:
            break;
    }
    if (payload?.actionType === FEATURE_ACTIONS[ONLINE_EXHIBITORS].JOIN) {
        const path = exhibitorPath;
        await client.setDataAsync(path, null);
        client.cancelOnDisconnect(path);
        // Below is for new node, which we have added.
        await client.setDataAsync(newExhibitorPath, null);
        client.cancelOnDisconnect(newExhibitorPath);
    }
};

export default onlineExhibitorsWriter;
