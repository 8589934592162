import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsBackStage } from 'store/selectors';
import { SESSION_TYPES } from 'utils/constants/sessions';
import { logger } from 'utils/logger';
import PermissionUtils from 'utils/permission-utils';
import { isSessionHostOrCohost } from 'utils/userAccessControl';
import useLiveAirmeetContext from '../useLiveAirmeetContext';
import { useLiveSessionContext } from 'context/Session';

const RECORDING_ENABLE_STATUS = {
    auto: 'auto',
    off: 'off',
};
const RECORDING_STATUS = {
    auto: 'on',
    off: 'off',
};
export default function useRecordingHandler(props) {
    const { defaultNull = false } = props || {};
    const [enable, enableRecording] = useState(
        defaultNull ? null : RECORDING_ENABLE_STATUS.off
    );
    const isBackStage = useSelector(getIsBackStage);
    const {
        featureDataClients: { stage: firebaseClient },
        airmeet: { airmeetId, data },
        user: { id: userId },
    } = useLiveAirmeetContext();

    const { currentSession } = data;
    const sessionId = currentSession ? currentSession.sessionid : null;
    const allowToAccess = currentSession;
    const isPreRecordedSessionCH =
        (PermissionUtils.isSessionCloudHost() ||
            PermissionUtils.isSessionScreenRecordingUser()) &&
        currentSession.type === SESSION_TYPES.PRE_RECORDED;

    const isGroupMeetingSession = useLiveSessionContext(
        (ctx) => ctx.isGroupMeetingSession
    );
    const allowToUpdate =
        allowToAccess &&
        ((isSessionHostOrCohost() && (isBackStage || isGroupMeetingSession)) ||
            isPreRecordedSessionCH);

    const defaultStatus = useMemo(() => {
        if (
            !['demo', 'production'].includes(process.env.REACT_APP_ENV) &&
            !isPreRecordedSessionCH
        ) {
            return RECORDING_ENABLE_STATUS.off;
        }

        if (
            [
                SESSION_TYPES.HOSTING,
                SESSION_TYPES.STREAMING,
                SESSION_TYPES.PRE_RECORDED,
            ].includes(currentSession?.type)
        ) {
            return RECORDING_ENABLE_STATUS.auto;
        }

        return RECORDING_ENABLE_STATUS.off;
    }, [currentSession, isPreRecordedSessionCH]);

    const fireBaseKey = sessionId
        ? `${airmeetId}/meta-data/sessions/${sessionId}/recording/enable`
        : '';
    const fireBaseStatusKey = sessionId
        ? `${airmeetId}/meta-data/sessions/${sessionId}/recording/status`
        : '';
    const firebaseRecStartKey = sessionId
        ? `${airmeetId}/meta-data/sessions/${sessionId}/recording/hasRecordingStarted`
        : '';
    const handler = (value) => {
        if (allowToUpdate && fireBaseKey) {
            firebaseClient.setData(fireBaseKey, value);
            firebaseClient.setData(
                fireBaseStatusKey,
                RECORDING_STATUS[value] || null
            );
            logger.info(
                `Session recording toggle changed to ${value} by userId ${userId} for sessionId : ${sessionId}`
            );
        }
    };

    const setRecStartStatus = () => {
        if (firebaseRecStartKey) {
            firebaseClient.setData(firebaseRecStartKey, true);
        }
    };

    useEffect(() => {
        if (allowToAccess) {
            const valueUpdated = ({ value }) => {
                if (value === null) {
                    handler(defaultStatus);
                } else {
                    enableRecording(value);
                }
            };
            firebaseClient
                .getEmitter()
                .on(`${fireBaseKey}_value`, valueUpdated);
            if (
                firebaseClient.getLoadedValues(fireBaseKey) ||
                firebaseClient.getLoadedValues(fireBaseKey) === null
            ) {
                logger.info(
                    'getLoadedValues recording',
                    firebaseClient.getLoadedValues(fireBaseKey)
                );
                valueUpdated({
                    value: firebaseClient.getLoadedValues(fireBaseKey),
                });
            }
            firebaseClient.getDataRef(fireBaseKey);

            return () => {
                firebaseClient
                    .getEmitter()
                    .off(`${fireBaseKey}_value`, valueUpdated);
            };
        }
    }, [fireBaseKey, allowToAccess]);

    return allowToAccess
        ? {
              handler,
              allowToUpdate,
              enable,
              enableOptions: RECORDING_ENABLE_STATUS,
              currentSession: currentSession,
              setRecStartStatus,
          }
        : {
              allowToUpdate: false,
              enable,
              enableOptions: RECORDING_ENABLE_STATUS,
              handler: () => {},
              setRecStartStatus: () => {},
          };
}
