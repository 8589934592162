import useUsersLocationData from 'components/allOnline/persistentStageBar/useUsersLocationData';
import { useLiveSessionContext } from 'context/Session';
import { emptyObject } from 'utils/constants/common';
import get from 'lodash/get';
import React, { useContext } from 'react';

const RTCStageArea = React.createContext(null);

const RTCStageAreaProviderWrapper = (props) => {
    const isPersistentBackstageEnabled = useLiveSessionContext(
        (ctx) => ctx.isPersistentBackstageEnabled
    );

    if (isPersistentBackstageEnabled) {
        return <RTCStageAreaProvider {...props} />;
    }

    return (
        <RTCStageArea.Provider
            value={{
                persistanceBackstage: emptyObject,
            }}
        >
            {props.children}
        </RTCStageArea.Provider>
    );
};

const RTCStageAreaProvider = ({ children }) => {
    const {
        liveStageUsers,
        backStageUsers,
        allowToPublishOnStage,
        allowToPublishOnBackstage,
        liveUsersCount,
        backStageUsersCount,
    } = useUsersLocationData();

    return (
        <RTCStageArea.Provider
            value={{
                persistanceBackstage: {
                    liveStageUsers,
                    backStageUsers,
                    allowToPublishOnStage,
                    allowToPublishOnBackstage,
                    liveUsersCount,
                    backStageUsersCount,
                },
            }}
        >
            {children}
        </RTCStageArea.Provider>
    );
};

export default RTCStageAreaProviderWrapper;

export function useRTCStageAreaContext(callback = (context) => context) {
    // The stage RTC context
    const ctx = useContext(RTCStageArea);

    // Return the up-to-date selected value
    return callback(ctx);
}

export const getPersistentBSLocationsData = (ctx) =>
    get(ctx, ['persistanceBackstage']);
