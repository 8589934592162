import Button from 'atoms/Button/button';
import FormField from 'components/community/common/FormField';
import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import { alpha } from 'foundations/theme';
import { Typography } from 'foundations/typography';
import SliderLib from 'rc-slider';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';

export const BannerImg = styled.img`
    height: 63px;
    width: auto;
`;

export const SegmentRow = styled(FlexDiv)`
    background: ${({ theme }) =>
        theme?.isLightTheme
            ? theme.colors.ambience[24]
            : alpha(theme.colors.ambience[21], 0.5)};
    border: 1px solid
        ${({ theme }) =>
            theme?.isLightTheme
                ? theme.colors.ambience[23]
                : theme.colors.black};
    border-radius: 4px;
    padding: 8px 20px;
    min-height: 50px;
    transition: all 0.3s;
    position: relative;
    &:hover {
        background: ${({ theme }) =>
            theme?.isLightTheme
                ? theme.colors.ambience[22]
                : alpha(theme.colors.ambience[20], 0.75)};
    }
`;

export const SegmentRowOverlay = styled(FlexDiv)`
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
`;

export const SegmentActionButton = styled(Button)`
    display: flex;
    align-items: center;
    padding-left: 24px;
    position: relative;
`;

export const DropdownMenu = styled(FlexDiv)`
    position: absolute;
    top 25px;
    right: 0;
    border-radius: 8px;
    background-color: ${({ theme }) =>
        theme.colors.ambience[theme?.isLightTheme ? 24 : 20]};
    padding: 8px 0;
    min-width: 108px;
    z-index: 3;
    ${({ theme }) =>
        theme?.isLightTheme
            ? `
        border: solid 1px ${theme.colors.ambience[22]};
    `
            : ''}
`;

export const DropdownMenuItem = styled(Typography)`
    padding: 8px;
    white-space: nowrap;
`;

export const SegmentNameInput = styled(FormField)`
    flex-grow: 1;
    input {
        padding-left: 30px;
        padding-right: 30px;
        text-indent: 25px;
        color: ${({ theme }) => theme.colors.accentPrimary[0]};
    }
`;

export const SelectedSegment = styled(FlexDiv)`
    flex-grow: 1;
    background: ${({ theme }) =>
        theme?.isLightTheme
            ? theme.colors.ambience[24]
            : 'rgb(48, 46, 56, 0.25)'};
    border: 2px solid
        ${({ theme }) =>
            theme?.isLightTheme
                ? theme.colors.ambience[22]
                : 'rgb(149, 128, 255, 0.25)'};
    border-radius: 4px;
    padding: 8px 12px;
    p {
        font-weight: bold;
        font-style: italic;
        font-size: 12px;
        line-height: 16px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

export const StyledHr = styled.hr`
    width: 100%;
    border-top: 1px solid
        ${({ theme }) => theme.colors.ambience[theme?.isLightTheme ? 21 : 14]};
    opacity: ${({ opacity }) => opacity || 1};
    margin: 25px 0;
`;

export const VerticalTabItem = styled.div`
    padding: 8px 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
    border: 1px solid #302e38;
    position: relative;
    color: ${({ theme, isActive }) =>
        isActive
            ? theme.colors.ambience[theme?.isLightTheme ? 24 : 0]
            : theme.colors.ambience[6]};
    background-color: ${({ theme, isActive }) =>
        isActive ? theme.colors.accentPrimary[0] : 'transparent'};
    &:hover {
        color: ${({ theme }) => theme.colors.ambience[0]};
    }
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(45px, -50%) rotate(45deg);
        border-radius: 2px;
        height: 24px;
        width: 24px;
        z-index: 0;
        display: ${({ isActive }) => (isActive ? 'block' : 'none')};
        background-color: ${({ theme }) =>
            theme.colors.ambience[theme?.isLightTheme ? 22 : 20]};
    }
`;

export const VerticalTabContentWrapper = styled(FlexDiv)`
    flex-grow: 1;
    justify-content: stretch;
    border-radius: 8px;
    padding: 30px 18px;
    background-color: ${({ theme }) =>
        theme.colors.ambience[theme?.isLightTheme ? 22 : 20]};
`;

export const RoundedInput = styled(FormField)`
    flex-grow: 1;
    &.rounded-input {
        height: 32px;
        border-radius: 50px;
        border: 1px solid #302e38;
        padding: 0 0 0 30px;
        align-items: center;
        display: flex;
        background-color: ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.ambience[24]
                : theme.colors.ambience[22]};
        ${({ formFieldStyle }) => formFieldStyle || ''}
    }
    input {
        font-size: 14px;
        line-height: 16px;
        padding: 0;
        background-color: transparent !important;
        ${({ inputStyle }) => inputStyle || ''}
    }
`;

export const Subtext = styled(Typography)`
    font-size: 10px;
    line-height: 14px;
`;

const createSliderWithTooltip = SliderLib.createSliderWithTooltip;
const Slider = createSliderWithTooltip(SliderLib);
export const StyledSlider = styled(Slider)`
    .rc-slider-handle {
        border: solid 1px ${({ theme }) => theme.colors.ambience[20]};
        background-color: ${({ theme }) => theme.colors.accentPrimary[0]};
        width: 16px;
        height: 16px;
        &:hover {
            border-color: ${({ theme }) => theme.colors.ambience[20]};
            background-color: ${({ theme }) => theme.colors.accentPrimary[1]};
        }
        &.rc-slider-handle-click-focused {
            border-color: ${({ theme }) => theme.colors.ambience[20]};
        }
    }
    .rc-slider-rail,
    .rc-slider-track {
        background-color: ${({ theme }) => theme.colors.white};
    }
    .rc-slider-tooltip {
        .rc-slider-tooltip-content {
            .rc-slider-tooltip-arrow {
                border-top-color: ${({ theme }) => theme.colors.ambience[0]};
            }
            .rc-slider-tooltip-inner {
                background-color: ${({ theme }) => theme.colors.ambience[0]};
                box-shadow: none;
                padding: 5px 10px;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                color: ${({ theme }) => theme.colors.ambience[22]};
            }
        }
    }
`;

export const SliderInput = styled.input`
    box-shadow: none;
    outline: 0;
    color: ${({ theme }) => theme.colors.ambience[8]};
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    border-radius: 4px;
    padding: 4px 8px;
    width: 50px;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colors.accentPrimary[0]};
    background-color: ${({ theme }) => theme.colors.ambience[21]};

    // Hide arrows
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    &[type='number'] {
        -moz-appearance: textfield;
    }
`;

export const AttendeesTableWrapper = styled(FlexDiv)`
    position: relative;
    margin-bottom: 40px;
    ${({ isViewSegmentTable, theme }) =>
        isViewSegmentTable &&
        `
    .dashboard-table-outer .data-table--dense tbody tr {
        background-color:${theme.colors.ambience[24]} !important
}`}
    .attendees-table-wrapper {
        overflow-y: visible;
        margin-bottom: 40px;
        .data-table--dense.attendees-table {
            margin-top: 0 !important;
            .checkmark {
                height: 16px;
                width: 16px;
                &::after {
                    left: 4px;
                    top: 2px;
                    width: 5px;
                    height: 8px;
                }
            }
            input:checked ~ .checkmark {
                background-color: ${({ theme }) =>
                    theme.colors.accentPrimary[0]};
                border-color: ${({ theme }) => theme.colors.accentPrimary[0]};
            }
            ${({ theme }) =>
                theme.isLightTheme &&
                `input:checked ~ .checkmark::after {
                filter: invert(1);
            }`};
            thead {
                position: static;
                tr {
                    position: static;
                    th {
                        position: static;
                        height: 44px;
                        padding: 0 16px;
                        color: ${({ theme }) => theme.colors.ambience[6]};
                        .data-table__th__text {
                            padding: 0 40px 0 0;
                        }
                        &:nth-child(2) {
                            font-weight: 600;
                            color: ${({ theme }) => theme.colors.ambience[0]};
                        }
                        &:not(:first-child):not(:last-child) {
                            span:not(.tooltip-icon-wrapper) {
                                font-weight: normal;
                                font-size: 10px;
                                line-height: 20px;
                                width: 100%;
                                display: inline-block;
                                border-right: 1px solid
                                    ${({ theme }) =>
                                        theme.colors.ambience[
                                            theme?.isLightTheme ? 18 : 19
                                        ]};
                            }
                        }
                    }
                    ${({ theme }) =>
                        theme?.isLightTheme
                            ? `
                            background-color: ${theme.colors.ambience[22]} !important;
                        `
                            : ''}
                }
                .checkbox-outer {
                    input:indeterminate ~ .checkmark {
                        background-color: ${({ theme }) =>
                            theme.colors.accentPrimary[0]};
                        border-color: ${({ theme }) =>
                            theme.colors.accentPrimary[0]};
                        &:after {
                            top: 6px;
                            width: 7px;
                        }
                    }
                }
                ${({ theme }) =>
                    theme.isLightTheme &&
                    `input:indeterminate ~ .checkmark::after {
                filter: invert(1);
            }`};
            }
            tbody {
                tr {
                    position: relative;
                    height: 52px;
                    transition: all 0.3s;
                    ${({ theme, addCustomHover }) =>
                        addCustomHover
                            ? `
                    
                                &:after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    height: inherit;
                                    width: calc(100% - 2px);
                                    background: ${theme.colors.ambience[20]};
                                    border: 1px solid #69657b;
                                    border-radius: 4px;
                                    z-index: -1;
                                    display: none;
                                }
                                &:hover {
                                    &:after {
                                        display: block;
                                    }
                                }
                            `
                            : ''}
                    &:hover {
                        background: rgba(38, 37, 45, 0.32);
                    }
                    td {
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                        padding: 0 16px;
                        color: ${({ theme }) => theme.colors.ambience[6]};
                        &:nth-child(2) {
                            color: ${({ theme }) => theme.colors.ambience[0]};
                        }
                        ${({ theme }) =>
                            theme?.isLightTheme
                                ? `
                            border-color: ${theme.colors.ambience[21]};
                        `
                                : ''}
                    }
                }
            }
        }
        .dashboard-table-bottom {
            position: absolute;
            right: 0;
            bottom: -10px;
            .pagination {
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    border-radius: 8px;
                    height: 24px;
                    min-width: 24px;
                    padding: 4px;
                    display: inline-block;
                    color: ${({ theme }) => theme.colors.ambience[0]};
                }
                .active a {
                    background: rgba(157, 154, 172, 0.16);
                    border: 1px solid
                        ${({ theme }) => theme.colors.accentPrimary[0]};
                }
            }
        }
    }
`;

export const PageNumber = styled(FlexDiv)`
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 24px;
    min-width: 24px;
    background: rgba(157, 154, 172, 0.16);
    border: 1px solid #69657b;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    padding: 4px;
    color: ${({ theme }) => theme.colors.ambience[0]};
`;

export const PageNumberWrapper = styled(FlexDiv)`
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 50px;
    p {
        font-size: 12px;
        line-height: 16px;
    }
`;

export const UserAvatar = styled.img`
    border-radius: 50%;
    width: 20px;
    height: 20px;
    object-fit: cover;
`;

export const ModalBody = styled(FlexDiv)`
    flex-grow: 1;
    overflow-y: auto;
    padding: 40px 30px 22px 30px;
    flex-direction: column;
    align-items: stretch;
    color: ${({ theme }) => theme.colors.ambience[6]};
`;

export const ModalFooter = styled(FlexDiv)`
    border-top: 1px solid ${({ theme }) => alpha(theme.colors.ambience[0], 0.1)};
    padding: 22px 30px 16px 30px;
    align-items: center;
    justify-content: center;
`;

export const DeleteSegmentLabel = styled(FlexDiv)`
    padding: 8px 16px;
    background-color: ${({ theme }) => alpha(theme.colors.ambience[21], 0.5)};
    border: 1px solid ${({ theme }) => alpha(theme.colors.ambience[14], 0.5)};
    border-radius: 4px;
    margin-top: 12px;
`;

export const DeleteSegmentLabelName = styled(Typography)`
    font-size: 12px;
    line-height: 16px;
`;

export const SegmentModalCancelButton = styled(Button)`
    background-color: ${({ theme }) =>
        theme?.isLightTheme
            ? theme.colors.ambience[22]
            : theme.colors.ambience[19]};
    border: ${({ theme }) =>
        theme?.isLightTheme ? `solid 1px ${theme.colors.ambience[18]}` : ''};
    max-width: 150px;
`;

export const SegmentModalAcceptButton = styled(Button)`
    max-width: 150px;
`;

export const TypePill = styled(Box)`
    align-self: stretch;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 12px;
    min-height: 22px;
    color: ${({ theme, isChecked }) =>
        isChecked
            ? theme.colors.ambience[theme.isLightTheme ? 24 : 0]
            : theme.colors.ambience[0]};
    background-color: ${({ isChecked, theme }) =>
        isChecked
            ? theme.colors.accentPrimary[0]
            : theme.colors.ambience[theme.isLightTheme ? 19 : 14]};
`;

export const FilterDivider = styled.hr`
    width: 100%;
    border-top: 1px dashed #69657b;
    opacity: 0.5;
    margin-bottom: 20px;
`;
export const InputWrapper = styled(FlexDiv)`
    position: relative;
    flex-direction: columns;
    align-items: stretch;
    padding-bottom: 32px;
    .count {
        position: absolute;
        top: 58px;
        right: 10px;
    }
`;

export const TabsWrapper = styled(FlexDiv)`
    .tabsRoot {
        margin-bottom: ${({ theme }) => theme.space.x4};
        .tabsTrigger {
            margin-right: ${({ theme }) => theme.space.x6};
            flex: 0;
        }
    }
    .tabsContent {
        padding: 0;
    }
`;
