import Box from 'atoms/Box';
import Btn from 'atoms/Button/btn';
import Select from 'atoms/SelectV2';
import StreamKeysDrawer from 'components/community/editPublicAirmeet/AirmeetStreamKey/StreamKeysDrawer';
import { Subtext } from 'components/community/editPublicAirmeet/Audience/styledComponents';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { MODAL_TYPE_CONFIRM } from 'components/modals/constants';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import usePlanFlags from 'hooks/billing-plans/usePlanFlags';
import useRTMPKeyDetails from 'hooks/useRTMPKeyDetails';
import useTheme from 'hooks/useTheme';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showAppModal } from 'store/actions/layout';
import styled from 'styled-components';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import { SESSION_TYPES } from 'utils/constants/sessions';
import { canShowPremiumFeatures } from 'utils/core';
import { EVENT_SESSION_TYPE_OPTIONS } from '../utils';
import PreRecordedVideoField from './PreRecordVideoField';

export default function SessionTypeField({
    formData,
    onValueChange,
    communityId,
    setFormData,
    setErrors,
    editMode,
    airmeetFormat = 'event',
}) {
    const { theme } = useTheme();
    const { isFreeTrialPlan } = usePlanFlags();

    const dispatch = useDispatch();
    const { airmeet } = useSelector((state) => state.cmDashboard);

    const [streamKeysVisible, setStreamKeysVisible] = useState(false);
    const keyDetails = useRTMPKeyDetails({ airmeet });
    const airmeetKeyEnabled = !!keyDetails?.airmeet_level_key?.stream_key;

    const sessionTypeSelectedOption = useMemo(() => {
        const value =
            formData?.sessionType || EVENT_SESSION_TYPE_OPTIONS[0]?.value;
        return EVENT_SESSION_TYPE_OPTIONS.find((item) => item?.value === value);
    }, [formData]);
    const sessionVideos =
        Array.isArray(formData?.sessionMeta?.videos) &&
        formData?.sessionMeta?.videos.length > 0
            ? formData?.sessionMeta?.videos
            : [];
    const handleSessionTypeChange = (v) => {
        const prevSession = EVENT_SESSION_TYPE_OPTIONS.find(
            (item) => item?.value === formData?.sessionType
        )?.label;
        if (editMode) {
            dispatch(
                showAppModal(MODAL_TYPE_CONFIRM, {
                    title: 'Session type',
                    subTitle: (
                        <>
                            Are you sure, you want to change the session type
                            from ‘{prevSession}’ to ‘{v?.label}’?
                            <br />
                            <br />
                            Doing this might result in loss of some data.
                        </>
                    ),
                    acceptText: 'Proceed',
                    cancelText: 'Cancel',
                    modalStyle: {
                        background: theme.colors.ambience[21],
                    },
                    enableBgClick: true,
                    onAccept: (closeModal) => {
                        closeModal();
                        onValueChange('sessionType', v?.value);
                    },
                })
            );
        } else {
            onValueChange('sessionType', v?.value);
        }
    };
    const subscription_type = useSelector(
        (state) => state?.Community?.current?.subscription_type
    );

    const filteredSessionTypeList = useMemo(() => {
        const isPaidPlan = canShowPremiumFeatures(subscription_type, false);

        if (isPaidPlan && !isFreeTrialPlan) return EVENT_SESSION_TYPE_OPTIONS;
        if (isFreeTrialPlan)
            return EVENT_SESSION_TYPE_OPTIONS.filter(
                (session) => session.enabledForFreeTrial
            );
        return EVENT_SESSION_TYPE_OPTIONS.filter(
            (session) => session.plan === 'free'
        );
    }, [isFreeTrialPlan, subscription_type]);

    const customOptionRenderer = (option) => {
        const { label, subText } = option.data;
        return (
            <StyledBox onClick={() => handleSessionTypeChange(option.data)}>
                <Text variant='body2' color='text.default.primary'>
                    {label}
                </Text>
                <Subtext variant='subtext0' color='text.default.secondary'>
                    {subText}
                </Subtext>
            </StyledBox>
        );
    };
    const airmeetSessions = airmeet?.sessions || [];
    const isSelectDisabled =
        (airmeet && airmeetSessions[0]?.status === AIRMEET_STATUS.FINISHED) ||
        airmeetSessions[0]?.status === AIRMEET_STATUS.ONGOING ||
        airmeetSessions[0]?.status === AIRMEET_STATUS.PAUSED;
    return (
        <FlexDiv alignItems='center' mb='x6'>
            <SelectWrapper isDisabled={isSelectDisabled}>
                <Select
                    label='Select session type *'
                    options={filteredSessionTypeList}
                    value={sessionTypeSelectedOption}
                    onChange={(v) => handleSessionTypeChange(v)}
                    menuPortalTarget={document.body}
                    components={{ Option: customOptionRenderer }}
                    disabled={isSelectDisabled}
                    styles={{
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                        }),
                        option: (styles, { isDisabled }) => ({
                            ...styles,
                            ':active': {
                                background: theme.colors.fill.other3,
                            },
                            ':hover': {
                                background: theme.colors.fill.other3,
                            },
                            cursor: isDisabled ? 'not-allowed' : 'pointer',
                        }),
                        selectContainer: (styles, { isDisabled }) => ({
                            ...styles,
                            cursor: isDisabled ? 'not-allowed' : 'pointer',
                        }),
                        indicatorsContainer: () => ({
                            svg: {
                                fill: `${theme.colors.text.default.secondary} !important`,
                            },
                        }),
                        dropdownIndicator: (styles, { isDisabled }) => ({
                            ...styles,
                            opacity: isDisabled ? 0.4 : 1,
                        }),
                    }}
                    isSearchable={false}
                    menuShouldBlockScroll={true}
                    hideIndicatorSeparator={true}
                />
            </SelectWrapper>
            {sessionTypeSelectedOption?.value ===
                SESSION_TYPES.PRE_RECORDED && (
                <FlexDiv
                    flexDirection='column'
                    alignItems='flex-start'
                    pl='x7'
                    justifyContent='space-between'
                >
                    <Text
                        variant='body2'
                        color='text.default.primary'
                        style={{
                            visibility:
                                Array.isArray(sessionVideos) &&
                                sessionVideos.length > 0
                                    ? 'hidden'
                                    : '',
                        }}
                    >
                        Add video *
                    </Text>
                    <PreRecordedVideoField
                        communityId={communityId}
                        setFormData={setFormData}
                        setErrors={setErrors}
                        formData={formData}
                        airmeet={airmeet}
                        sessionVideos={sessionVideos}
                        airmeetFormat={airmeetFormat}
                        videoChangeDisabled={isSelectDisabled}
                    />
                </FlexDiv>
            )}
            {sessionTypeSelectedOption?.value === SESSION_TYPES.STREAMING &&
                airmeetKeyEnabled && (
                    <>
                        <Btn
                            variant='link'
                            mt='1.75rem'
                            ml='x7'
                            type='button'
                            onClick={() => setStreamKeysVisible(true)}
                        >
                            <StyledSvgSprite
                                icon='eye_show'
                                height='1.25rem'
                                width='1.25rem'
                                mr='x1'
                            />
                            View Stream Keys
                        </Btn>
                        <StreamKeysDrawer
                            visible={streamKeysVisible}
                            onClose={() => setStreamKeysVisible(false)}
                            keyDetails={Object.fromEntries(
                                Object.entries(keyDetails).filter(
                                    ([key]) => key !== 'session_level_keys'
                                )
                            )}
                            isThemeV2={true}
                            airmeetFormat={airmeetFormat}
                        />
                    </>
                )}
        </FlexDiv>
    );
}

const SelectWrapper = styled(FlexDiv)`
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    max-width: 20rem;
    label {
        margin-bottom: ${({ theme }) => theme.space.x2};
    }
    >div{
        cursor:${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')}
`;
const StyledBox = styled(Box)`
    cursor: pointer;
    margin-left: 16px;
    padding-bottom: 10px;
`;
