export const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const isChromeiPad = () => {
    return navigator?.userAgent?.match('CriOS');
};

export const isMobileSize = () => {
    if (window.innerWidth <= 800 && window.innerHeight <= 830) {
        return true;
    }
    return false;
};

export const isChrome = () => {
    return (
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor)
    );
};

// for detailed chrome browser check, to avoid some false positives
// https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
export const isChromeBrowser = () => {
    // please note,
    // that IE11 now returns undefined again for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // but needs to check if window.opr is not undefined
    // and new IE Edge outputs to true now for window.chrome
    // and if not iOS Chrome check
    // so use the below updated condition
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== 'undefined';
    var isIEedge = winNav.userAgent.indexOf('Edge') > -1;
    var isIOSChrome = winNav.userAgent.match('CriOS');

    if (isIOSChrome) {
        // is Google Chrome on IOS
        return true;
    } else if (
        isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false
    ) {
        // is Google Chrome
        return true;
    } else {
        // not Google Chrome
        return false;
    }
};

export const isFirefox = () => {
    // eslint-disable-next-line
    return typeof InstallTrigger !== 'undefined';
};

export const calculateTextWidth = (txt, font) => {
    const c = document.getElementById('myCanvas');
    const ctx = c.getContext('2d');
    ctx.font = font;
    const width = ctx.measureText(txt).width;
    return width;
};
