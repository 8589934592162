import { getEventTypeLabel } from 'utils/airmeet';

export const getEventTagColor = ({ tagType, styleAttrs = {} }) => {
    // API handles default color logic(if not configured). This value must always be a valid color
    if (styleAttrs) {
        const { bg_color: color } = styleAttrs[tagType] || {};
        return color;
    }
    return '';
};

export const getBadgeText = (tagName, tagType) => {
    if (tagName) return tagName;
    return getEventTypeLabel({ eventType: tagType });
};

export const getSubstring = (string = '', start, end) => {
    return string.substring(start, end);
};

// 14days in epoch time
export const endDateToStartDateTimeDiff = 1209600000;
