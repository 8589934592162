import { useContext, useEffect } from 'react';
import { ContainerStore } from './container';

function useContainerStore(initialState = null) {
    const store = useContext(ContainerStore);

    useEffect(() => {
        if (initialState) {
            for (let key of Object.keys(initialState)) {
                store.setState(key, initialState[key]);
            }
        }
    }, []);

    return store;
}

export default useContainerStore;
