import FirebaseClient from './FirebaseClient';

export default class SecondaryFirebaseClient extends FirebaseClient {
    async init(authId, airmeetId) {
        this.authId = authId;
        this.airmeetId = airmeetId;
    }

    async getCurrentTimeStampDifference(...args) {
        if (!this.timeStampListenerInit) {
            super._initTimestampOffsetListener();
            this.timeStampListenerInit = true;
        }
        return super.getCurrentTimeStampDifference(...args);
    }
}
