import { API_SERVICE } from 'utils/apiService';
import { CALL_API } from '../api';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export function forgotPassword({ email, token, useV3Captcha }) {
    return {
        [CALL_API]: {
            types: [FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS],
            endpoint: '/user/forgot-password',
            body: {
                email,
                token,
                useV3Captcha,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}

export function resetPassword({
    email,
    otp,
    currentPassword,
    password,
    secure = false,
}) {
    return {
        [CALL_API]: {
            types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS],
            endpoint: secure
                ? '/user/secure/reset-password'
                : '/user/reset-password',
            body: {
                email,
                otp,
                password,
                currentPassword,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}
