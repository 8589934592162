export const SET_SHOW_MODAL = 'SET_SHOW_SYSTEM_MODAL';
export const SET_REMOVE_MODAL = 'SET_REMOVE_SYSTEM_MODAL';

export const setShowSystemCheckModal = (payload) => ({
    type: SET_SHOW_MODAL,
    payload,
});
export const setRemoveSystemCheckModal = () => ({
    type: SET_REMOVE_MODAL,
});
