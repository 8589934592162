import isObject from 'lodash/isObject';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    addToast,
    removeToast as removeToastAction,
    TOAST_TYPE_CUSTOM,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_INFO,
    TOAST_TYPE_PRIMARY,
    TOAST_TYPE_SUCCESS,
    TOAST_TYPE_WARNING,
} from 'store/actions/toasts';

const getToasts = (state) => state.toasts;

const useToasts = () => {
    const dispatch = useDispatch();
    const toasts = useSelector(getToasts);

    const showToast = useCallback(
        (type, ...args) => {
            let data = {};
            const [props] = args;
            if (isObject(props)) {
                data = { type, ...props };
                // Primary toast are not supported on toast version 2
                if (type === TOAST_TYPE_PRIMARY) {
                    data.version = null;
                }
            } else {
                const [
                    message,
                    duration,
                    closeButton,
                    group,
                    title,
                    version,
                    minHeight,
                    width,
                    renderElement,
                    icon,
                    startCount,
                    endCount,
                    iconPos,
                    closeToastOnly,
                    zIndex,
                    positionTop,
                    positionRight,
                    positionLeft,
                    descStyles,
                ] = args;

                data = {
                    type,
                    message,
                    duration,
                    closeButton,
                    group,
                    title,
                    version,
                    minHeight,
                    width,
                    renderElement,
                    icon,
                    startCount,
                    endCount,
                    iconPos,
                    closeToastOnly,
                    zIndex,
                    positionTop,
                    positionRight,
                    positionLeft,
                    descStyles,
                };
            }

            if (data.markup || data.messageComponent) {
                data = {
                    ...data,
                    message: {
                        ...(data.markup && { markup: data.markup }),
                        ...(data.messageComponent && {
                            component: data.messageComponent,
                        }),
                    },
                };
            }

            return dispatch(addToast(data));
        },
        [dispatch]
    );

    const infoToast = useCallback(
        (...args) => {
            return showToast(TOAST_TYPE_INFO, ...args);
        },
        [showToast]
    );
    const errorToast = useCallback(
        (...args) => {
            return showToast(TOAST_TYPE_ERROR, ...args);
        },
        [showToast]
    );
    const warningToast = useCallback(
        (...args) => {
            return showToast(TOAST_TYPE_WARNING, ...args);
        },
        [showToast]
    );
    const primaryToast = useCallback(
        (...args) => {
            return showToast(TOAST_TYPE_PRIMARY, ...args);
        },
        [showToast]
    );
    const customToast = useCallback(
        (...args) => {
            return showToast(TOAST_TYPE_CUSTOM, ...args);
        },
        [showToast]
    );
    const successToast = useCallback(
        (...args) => {
            return showToast(TOAST_TYPE_SUCCESS, ...args);
        },
        [showToast]
    );

    const removeToast = useCallback(
        (toastId) => {
            dispatch(removeToastAction(toastId));
        },
        [dispatch]
    );

    const isToastPresent = useCallback(
        (toastId) => toasts.find(({ id }) => toastId === id),
        [toasts]
    );

    const getAllToasts = toasts;

    return {
        infoToast,
        errorToast,
        warningToast,
        primaryToast,
        successToast,
        customToast,
        removeToast,
        isToastPresent,
        getAllToasts,
    };
};
export default useToasts;
