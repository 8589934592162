import { useDispatch, useSelector } from 'react-redux';
import { setFooterProps } from '../store/actions/layout';
import usePropStack from './usePropStack';

/**
 * Sets the footer visibility/props for the current page.
 * By default the footer remains visible
 * Can also pass non-function props to the footer
 */
function useFooter({ ...props }) {
    const dispatch = useDispatch();
    const { footer } = useSelector((state) => state.layout);

    const setNewProps = (newProps) => {
        // Add the secret hidden prop
        dispatch(
            setFooterProps({
                ...newProps,
                hidden: props.visible !== true,
            })
        );
    };

    const resetOldProps = (oldProps) => {
        // Default visible
        dispatch(setFooterProps(oldProps));
    };

    usePropStack({
        resetProps: footer,
        setProps: props,
        onSet: setNewProps,
        onReset: resetOldProps,
    });

    return null;
}

export default useFooter;
