import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AuthService from '../utils/authService';

function useInfoBanner(storageKey, { skipUserCheck }) {
    const [visibility, setVisibilty] = useState(undefined);
    const cachedUser = AuthService.user || AuthService.guest;
    const loggedInUser = useSelector((state) => state.auth.user);
    const getBanner = () => localStorage.getItem(storageKey);

    const setBanner = (value) => {
        localStorage.setItem(storageKey, value);
    };
    const getVisibility = () => {
        return skipUserCheck
            ? ['false'].includes(getBanner())
            : ['false'].includes(getBanner()) || cachedUser || loggedInUser;
    };
    useEffect(() => {
        if (getVisibility()) {
            setVisibilty(false);
        } else {
            setVisibilty(true);
            setBanner('true');
        }
    }, [loggedInUser]);

    return visibility;
}

export default useInfoBanner;
