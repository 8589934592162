import { DATA_CENTER_REGION_IDS } from 'utils/constants/dataCenter';
import { API_SERVICE } from '../../../utils/apiService';
import {
    REACT_APP_API_BASE_URL,
    REACT_APP_API_BASE_URL_EU,
    REACT_APP_API_BASE_URL_IN,
    REACT_APP_API_BASE_URL_US,
} from '../../../utils/constants/airmeet';
import { getEmailDomain } from 'utils/core.js';
import { AIRMEET_DOMAIN } from 'utils/constants/users';
import { ADMIN } from 'components/community/team/config';
import { CALL_API } from '../../api';
export const INVITE_MEMBER_REQUEST = 'INVITE_MEMBER_REQUEST';
export const DELETE_INVITED_MEMBER_REQUEST = 'DELETE_INVITED_MEMBER_REQUEST';
export const TEAM_MEMBERS_REQUEST = 'TEAM_MEMBERS_REQUEST';
export const TEAM_MEMBERS_RESPONSE = 'TEAM_MEMBERS_RESPONSE';
export const INVITE_MEMBER_RESPONSE = 'INVITE_MEMBER_RESPONSE';
export const UPDATE_INVITATION_REQUEST = 'UPDATE_INVITATION_REQUEST';
export const INVITE_MEMBER_TOKEN_VALIDATION_REQUEST =
    'INVITE_MEMBER_TOKEN_VALIDATION_REQUEST';
export const UPCOMING_EVENTS_REQUEST = 'UPCOMING_EVENTS_REQUEST';
export const ROLES_REQUEST = 'ROLES_REQUEST';
export const ROLES_RESPONSE = 'ROLES_RESPONSE';
export const CHANGE_ROLE_REQUEST = 'CHANGE_ROLE_REQUEST';
export const ROLE_PERMISSIONS_REQUEST = 'ROLE_PERMISSIONS_REQUEST';
export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const RESET_ROLE_REQUEST = 'RESET_ROLE_REQUEST';
export const USER_PERMISSIONS_REQUEST = 'USER_PERMISSIONS_REQUEST';
export const USER_PERMISSIONS_RESPONSE = 'USER_PERMISSIONS_RESPONSE';
export const EVENT_ORGANISERS_REQUEST = 'EVENT_ORGANISERS_REQUEST';
export const TEAM_MEMBER_ADDON_ESTIMATE_REQUEST =
    'TEAM_MEMBER_ADDON_ESTIMATE_REQUEST';
export const TEAM_MEMBER_ADDON_PURCHASE_REQUEST =
    'TEAM_MEMBER_ADDON_PURCHASE_REQUEST';
export const TEAM_MEMBER_ADDON_REDUCE_SEATS_REQUEST =
    'TEAM_MEMBER_ADDON_REDUCE_SEATS_REQUEST';
export const TEAM_MEMBER_BULK_DELETE_REQUEST =
    'TEAM_MEMBER_BULK_DELETE_REQUEST';
export const SET_IS_ORGANIZER_MAPPED_TO_EVENT =
    'SET_IS_ORGANIZER_MAPPED_TO_EVENT';

const teamMembersTransform = (payload = {}) => {
    const newTeam = (payload.team_members || []).map((listItem) => {
        const memberDomain = getEmailDomain(listItem.email || '');
        let internalDomain = false;
        if (memberDomain === AIRMEET_DOMAIN) {
            internalDomain = true;
        }
        const commonFields = {
            ...listItem,
            internalDomain,
        };
        // Existing event managers added before release
        // of roles and permissions feature will all be
        // assigned role of admin
        if (!listItem?.role) {
            const role = Object.assign({}, { id: 1, name: ADMIN });
            return {
                ...commonFields,
                role,
            };
        }
        return {
            ...commonFields,
        };
    });

    return {
        ...payload,
        team_members: newTeam,
    };
};

export function inviteMember(payload) {
    return {
        [CALL_API]: {
            types: [INVITE_MEMBER_REQUEST],
            endpoint: `/airmeet/community/team-member/invite`,
            body: {
                ...payload,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}

export function deleteInvitedMember(payload) {
    return {
        [CALL_API]: {
            types: [DELETE_INVITED_MEMBER_REQUEST],
            endpoint: '/airmeet/community/team-member/invite',
            body: {
                ...payload,
            },
            type: 'json',
            method: 'DELETE',
            service: API_SERVICE.BFF,
        },
    };
}

export function validateInvitation(payload) {
    return {
        [CALL_API]: {
            types: [INVITE_MEMBER_TOKEN_VALIDATION_REQUEST],
            endpoint: '/airmeet/community/team-member/invite/validity',
            body: {
                ...payload,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}
export function updateInvitation(payload) {
    return {
        [CALL_API]: {
            types: [UPDATE_INVITATION_REQUEST],
            endpoint: '/airmeet/community/team-member/invite',
            body: {
                ...payload,
            },
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.BFF,
        },
    };
}

export function fetchCommunityTeamMembers(communityId) {
    return {
        [CALL_API]: {
            types: [TEAM_MEMBERS_REQUEST, TEAM_MEMBERS_RESPONSE],
            endpoint: `/airmeet/community/${communityId}/team-members`,
            method: 'GET',
            service: API_SERVICE.BFF,
            transform: teamMembersTransform,
        },
    };
}

export function fetchEventOrganisers(
    communityId,
    entityId,
    isEventSeries = false
) {
    return {
        [CALL_API]: {
            types: [EVENT_ORGANISERS_REQUEST, TEAM_MEMBERS_RESPONSE],
            endpoint: `/airmeet/community/${communityId}/team-members?${
                isEventSeries ? 'seriesId' : 'airmeetId'
            }=${entityId}`,
            method: 'GET',
            service: API_SERVICE.BFF,
            transform: teamMembersTransform,
        },
    };
}

export function fetchAllRoles(communityId) {
    return {
        [CALL_API]: {
            types: [ROLES_REQUEST, ROLES_RESPONSE],
            endpoint: `/roles?communityId=${communityId}`,
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function changeRole(payload) {
    const { communityId, user_id, role_id } = payload;
    return {
        [CALL_API]: {
            types: [CHANGE_ROLE_REQUEST],
            endpoint: `/role/user?community_id=${communityId}`,
            body: {
                user_id,
                role_id,
            },
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.BFF,
        },
    };
}

export function fetchUserPermissions(
    communityId,
    airmeetId,
    region = '',
    seriesId = ''
) {
    let zeusApiServiceEndpoint = REACT_APP_API_BASE_URL;

    switch (region) {
        case DATA_CENTER_REGION_IDS.IN:
            zeusApiServiceEndpoint = REACT_APP_API_BASE_URL_IN;
            break;
        case DATA_CENTER_REGION_IDS.EU:
            zeusApiServiceEndpoint = REACT_APP_API_BASE_URL_EU;
            break;
        case DATA_CENTER_REGION_IDS.US:
            zeusApiServiceEndpoint = REACT_APP_API_BASE_URL_US;
            break;
        default:
            break;
    }
    return {
        [CALL_API]: {
            types: [USER_PERMISSIONS_REQUEST, USER_PERMISSIONS_RESPONSE],
            endpoint: `${zeusApiServiceEndpoint}/permissions/user?communityId=${communityId}${
                airmeetId
                    ? `&airmeetId=${airmeetId}`
                    : seriesId && `&eventSeriesId=${seriesId}`
            }`,
            method: 'GET',
            service: API_SERVICE.ZEUS,
            appendBaseUrl: false,
        },
    };
}

export function fetchRolePermissions(communityId, roleId) {
    return {
        [CALL_API]: {
            types: [ROLE_PERMISSIONS_REQUEST],
            endpoint: `/role/${roleId}/permission-group?community_id=${communityId}`,
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateRolePermissions({
    communityId,
    roleId,
    permissionGroupId,
    toggleValue: toggleEnabled,
}) {
    return {
        [CALL_API]: {
            types: [UPDATE_ROLE_REQUEST],
            endpoint: `/role/${roleId}/permission-group?community_id=${communityId}`,
            body: {
                permission_group_id: permissionGroupId,
                toggle_enabled: toggleEnabled,
            },
            type: 'json',
            method: 'PATCH',
            service: API_SERVICE.BFF,
        },
    };
}

export function resetRolePermissions(communityId, roleId) {
    return {
        [CALL_API]: {
            types: [RESET_ROLE_REQUEST],
            endpoint: `/role/${roleId}/permission-group/reset?community_id=${communityId}`,
            method: 'PATCH',
            service: API_SERVICE.BFF,
        },
    };
}

export function memberEventsList(payload) {
    const { communityId, email } = payload;
    return {
        [CALL_API]: {
            types: [UPCOMING_EVENTS_REQUEST],
            endpoint: `/airmeet/community/events`,
            body: {
                communityId,
                managerEmail: email,
            },
            type: 'json',
            method: 'POST',
        },
    };
}

export function teamMemberAddonEstimates(payload) {
    const { communityId, addonSeatsCount } = payload;
    return {
        [CALL_API]: {
            types: [TEAM_MEMBER_ADDON_ESTIMATE_REQUEST],
            endpoint: `/airmeet/community/${communityId}/subscription/add-on/estimate`,
            body: {
                addons_to_be_added: [
                    {
                        type: 'TEAM_MEMBER',
                        quantity: addonSeatsCount,
                    },
                ],
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}

export function purchaseTeamMemberAddon(payload) {
    return {
        [CALL_API]: {
            types: [TEAM_MEMBER_ADDON_PURCHASE_REQUEST],
            endpoint: '/community/subscription/add-on/team-members',
            body: {
                ...payload,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateAddonSeatsCount(payload) {
    const { community_id, no_of_seats } = payload;
    return {
        [CALL_API]: {
            types: [TEAM_MEMBER_ADDON_REDUCE_SEATS_REQUEST],
            endpoint: '/community/subscription/add-on/team-members',
            body: {
                community_id,
                no_of_seats,
            },
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.BFF,
        },
    };
}

export function deleteTeamMembers(payload) {
    const {
        communityId,
        eventManagersToBeRemoved,
        invitedMembersToBeRemoved,
    } = payload;
    return {
        [CALL_API]: {
            types: [TEAM_MEMBER_BULK_DELETE_REQUEST],
            endpoint: `/airmeet/community/${communityId}/team-members`,
            body: {
                confirmed: [...eventManagersToBeRemoved],
                unconfirmed: [...invitedMembersToBeRemoved],
            },
            type: 'json',
            method: 'DELETE',
            service: API_SERVICE.BFF,
        },
    };
}

export function setIsCurrentUserRoleMappedToEvent(payload) {
    return {
        type: SET_IS_ORGANIZER_MAPPED_TO_EVENT,
        payload,
    };
}
