export const BLOCK_USER_PLACES = {
    ATTENDEE_LIST: 'attendee list',
    ATTENDEE_GRID: 'attendee grid',
    MANAGE_ABUSE: 'manage abuse',
    GENERAL_CHAT: 'general chat',
    QUESTIONS: 'questions',
    EVENT_FEED: 'RHS event feed',
    SESSION_FEED: 'RHS session feed',
    BOOTH_FEED: 'Booth feed',
    PROFILE_CARD: 'Profile card',
    PERSON_CARD: 'Person card',
};

export const REMOVE_POST_PLACES = {
    GENERAL_CHAT: 'general chat',
    QUESTIONS: 'questions',
    EVENT_FEED: 'RHS event feed',
    SESSION_FEED: 'RHS session feed',
    BOOTH_FEED: 'Booth feed',
    STAGE_CHAT: 'Stage chat',
};

export const BLOCKED_USER_LOCALSTORAGE_KEY = 'airmeet-blocked-id';

export const BLOCKED_USER_PICTURE =
    '/images/eventDetail/default-avatar-profile.jpg';
