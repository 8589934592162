export const TOGGLE_FULL_PROFILE = 'TOGGLE_FULL_PROFILE';
export const TOGGLE_PROFILE_NUDGE = 'TOGGLE_PROFILE_NUDGE';
export const SET_RICH_PROFILE_INTERESTS = 'SET_RICH_PROFILE_INTERESTS';

export const setRichProfileInterests = (data) => {
    return {
        payload: data,
        type: SET_RICH_PROFILE_INTERESTS,
    };
};

export const showFullProfile = (id) => {
    return {
        payload: id,
        type: TOGGLE_FULL_PROFILE,
    };
};

export const hideFullProfile = () => {
    return {
        payload: null,
        type: TOGGLE_FULL_PROFILE,
    };
};

export const hideProfileNudge = () => {
    return {
        payload: true,
        type: TOGGLE_PROFILE_NUDGE,
    };
};
