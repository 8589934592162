export default function createReducer(initial, handlers) {
    return (state = initial, action) => {
        const handler = handlers[action.type];

        if (!handler) {
            return state;
        }
        return handler(state, action);
    };
}
