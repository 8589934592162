export const cssPxValueFormatter = (value) => {
    if (!value || !['string', 'number'].includes(typeof value)) {
        return;
    }
    if (/[0-9]+px$/.test(value)) {
        return value;
    }
    if (/[0-9]+[^A-Za-z]*$/.test(value)) {
        return `${value}px`;
    }
    return;
};
