import { localLogger } from 'utils/loggers/localLogger';
import LogInterface, { LoggerOptions } from './LogInterface';

interface LoggerWithPrefixOptions extends LoggerOptions {
    logPrefix?: string;
}

export class LogWithPrefixInterface extends LogInterface {
    logPrefix: string = '';

    constructor(options: LoggerWithPrefixOptions) {
        super(options);

        this.logPrefix = options.logPrefix || '';
    }

    setLogPrefix(prefix: string) {
        this.logPrefix = prefix;
    }

    log(...args: any[]) {
        super.log(this.logPrefix, ...args);
    }

    debug(...args: any[]) {
        super.debug(this.logPrefix, ...args);
    }

    info(...args: any[]) {
        super.info(this.logPrefix, ...args);
    }

    warn(...args: any[]) {
        super.warn(this.logPrefix, ...args);
    }

    error(...args: any[]) {
        super.error(this.logPrefix, ...args);
    }

    fatal(...args: any[]) {
        super.fatal(this.logPrefix, ...args);
    }
}

const FILTER_PREFIX = 'Background filters :: ';
export default function FilterLogger(prefix: string, color = 'green') {
    if (prefix) {
        prefix = `${FILTER_PREFIX} ${prefix}`;
    } else {
        prefix = FILTER_PREFIX;
    }
    return new LogWithPrefixInterface({
        ...localLogger.options,
        logPrefix: prefix,
        color,
    });
}

export const genericFilterLogger = FilterLogger('');
export const previewPageFilterLogger = FilterLogger('Permission Workflow : ');
export const testModalFilterLogger = FilterLogger('Setup modal : ');
export const tableFilterLogger = FilterLogger('Table : ');
export const stageFilterLogger = FilterLogger('Live Stage : ');
export const boothFilterLogger = FilterLogger('Booth broadcast : ');
export const snFilterLogger = FilterLogger('Speed Networking : ');
export const liveAnnouncementFilterLogger = FilterLogger(
    'Announcement broadcast: '
);
export const fluidSpaceLogger = FilterLogger('Fluid Space Logger : ');

export const filterLogs = {
    enabled: 'filters are enabled for event',
    disabled: 'filters are disabled for event',
    supported: 'filters are supported for current user',
    notSupported: 'filters are not supported for current user',
    applyStart: 'applyFilter called',
    applySuccess: 'filter applied successfully',
    applyFail: 'error occured while applying filter',
    removeStart: 'removeFilter called',
    removeSuccess: 'filter removed successfully',
    removeFail: 'error occured while removing filter',
};
