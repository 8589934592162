import type ILiveAirmeet from 'context/types/liveAirmeet';

export enum OperationType {
    SET,
    UPDATE,
    REMOVE,
    PUSH,
}

export enum ContextualMode {
    READ_ONLY,
    READ_ONLY_COUNTERS,
    READ_ONLY_USERS,
    WRITE_ONLY,
}

export enum WriteStatus {
    PENDING,
    ACCEPTED,
    SUCCESS,
    ERROR,
}
export interface TxnDetails {
    status: WriteStatus;
    code?: number;
    message?: string;
    meta?: any;
}
export interface RealtimeMessageMetadata {
    key: string;
    status?: WriteStatus;
    serverRequestId?: string;
    txnDetails?: TxnDetails;
}
export interface Message {
    action?: string;
    payload?: any;
    metadata?: RealtimeMessageMetadata;
}

export enum SortDirection {
    ASC,
    DESC,
}

export interface Filters {}
export interface QueryParams {
    sort?: SortDirection;
    orderBy?: string;
    pageSize?: number;
    pageNumber?: number; // Use -1 to fetch last page
    filters?: Filters;
    metadata?: any;
}
export type Unsubscribe = Function;

export interface Listeners {
    onAdd?: (message: Message) => any;
    onChange?: (message: Message) => any;
    onRemove?: (message: Message) => any;
    onSnapshot?: (message: Message) => any;
    onMessage?: (message: Message) => any;
}

export enum RealtimeActionTypes {
    ADDED = 'added',
    REMOVED = 'removed',
    CHANGED = 'changed',
    SNAPSHOT = 'snapshot',
}
export interface RealTimeDataSource {
    subscribe(channel: string, listeners: Listeners, options?: any): Function;
    fetch(query: QueryParams): Promise<any>;
    registerOnDisconnect(channel: string, message: any): Promise<boolean>;
    cancelOnDisconnect(channel: string): Promise<boolean>;
    fetchOnce(metaData: any): Promise<any>;
}

export interface DataObserver {
    setDataSource(realtimeDataSource: RealTimeDataSource): void;
    subscribe(onData: Function, metadata?: any): Unsubscribe;
    fetch(onData: Function, metadata?: any): void;
    fetchOnce(metadata?: any): Promise<Array<Object>>;
}

export enum QueueType {
    NONE, // Process in parallel without sequencing
    DEDICATED, // Dedicated queue for feature not impacted by other features, sequencing maintained for each feature individually
    GLOBAL, // Best fit for features if their is sequence dependency
}

export interface DataWriterConfig {
    maxRetries?: number;
    sessionId?: string;
    optimisticUpdates?: boolean;
    requiredTxnStatusConfirmation?: WriteStatus;
    queueType?: QueueType;
    useFrontendWriterFallback?: boolean;
    forceFrontendWrite?: boolean;
    liveContextOverride?: ILiveAirmeet; // to be used if context if data writer is being used outside live context
}

export interface DataWriterResponse {
    success: boolean;
    data: {
        requestId: string;
    };
}
