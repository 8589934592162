import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const usePlanFlags = () => {
    const {
        is_trial_plan: cmIsTrialPlan,
        is_deactivated: cmIsTrialPlanDeactivated,
    } = useSelector((state) => state.Community.current);
    const {
        is_trial_plan: amIsTrialPlan,
        is_deactivated: amIsTrialPlanDeactivated,
    } = useSelector((state) => state.lounge.airmeet);
    const eventSeriesPublicDetail = useSelector(
        (state) => state?.eventSeries?.combinedInfo?.seriesInfo
    );
    const {
        is_trial_plan: seriesIsTrialPlan,
        is_deactivated: seriesIsTrialPlanDeactivated,
    } = eventSeriesPublicDetail || {};

    const isFreeTrialPlan = useMemo(
        () => seriesIsTrialPlan ?? amIsTrialPlan ?? cmIsTrialPlan,
        [cmIsTrialPlan, amIsTrialPlan, seriesIsTrialPlan]
    );

    const isFreeTrialPlanDeactivated = useMemo(
        () =>
            seriesIsTrialPlanDeactivated ??
            amIsTrialPlanDeactivated ??
            cmIsTrialPlanDeactivated,
        [
            cmIsTrialPlanDeactivated,
            amIsTrialPlanDeactivated,
            seriesIsTrialPlanDeactivated,
        ]
    );

    return {
        isFreeTrialPlan,
        isFreeTrialPlanDeactivated,
    };
};

export default usePlanFlags;
