import { useMemo } from 'react';
import { API_CONTAINER } from 'utils/constants/containers/containerIds';
import {
    API_RUN_COMPLETE,
    API_RUN_START,
} from 'utils/constants/containers/notifications';
import { useContainerNotifications, useContainerStore } from './container';

export type APIConfig =
    | string
    | { [apiKey: string]: { [configKey: string]: any } };

export interface APIStoreData {
    loading: boolean;
    data: null | any;
    refresh: (props?: any) => Promise<{ payload: any; error: Error }>;
}

export type ContainerAPIStoreCache = {
    [apiKey: string]: APIStoreData;
};

function useContainerAPIStore(...apis: APIConfig[]): ContainerAPIStoreCache {
    const {
        state: { apiCache },
    } = useContainerStore();

    const { notify, onNotify, offNotify } = useContainerNotifications();

    // Pick out the requested values from the cache anytime the values change
    return useMemo(
        () =>
            apis.reduce<ContainerAPIStoreCache>((allData, conf) => {
                if (conf === null) {
                    return allData;
                }

                const dataKey =
                    typeof conf === 'string' ? conf : Object.keys(conf)[0];

                allData[dataKey] = {
                    loading: false,
                    data: null,
                    refresh: (props = null) =>
                        new Promise<{ payload: any; error: Error }>(
                            (resolve) => {
                                // Wait till call completes
                                const onAPICompleted = ({
                                    data: { payload, error },
                                }) => {
                                    offNotify(API_RUN_COMPLETE, onAPICompleted);
                                    resolve({ payload, error });
                                };
                                onNotify(API_RUN_COMPLETE, onAPICompleted);

                                // Trigger the call
                                notify(
                                    API_RUN_START,
                                    {
                                        key: dataKey,
                                        props:
                                            typeof conf === 'string'
                                                ? props
                                                : {
                                                      ...Object.values(conf)[0],
                                                      ...props,
                                                  },
                                    },
                                    API_CONTAINER
                                );
                            }
                        ),
                    ...(apiCache ? apiCache[dataKey] : null),
                };
                return allData;
            }, {}),
        apis.map(
            (conf) =>
                apiCache &&
                conf &&
                (typeof conf === 'string'
                    ? apiCache[conf]
                    : apiCache[Object.keys(conf)[0]])
        )
    );
}

export default useContainerAPIStore;
