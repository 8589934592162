export const SET_SHOW_LEAVE_SESSION_MODAL = 'SET_SHOW_LEAVE_SESSION_MODAL';
export const SET_LEAVE_SESSION_MODAL_RESPONSE =
    'SET_LEAVE_SESSION_MODAL_RESPONSE';
export const RESET_LEAVE_SESSION_MODAL = 'RESET_LEAVE_SESSION_MODAL';

export const setShowLeaveSessionModal = (payload) => ({
    type: SET_SHOW_LEAVE_SESSION_MODAL,
    payload: payload,
});
export const setLeaveSessionModalResponse = () => ({
    type: SET_LEAVE_SESSION_MODAL_RESPONSE,
});
export const resetLeaveSessionModal = () => ({
    type: RESET_LEAVE_SESSION_MODAL,
});
