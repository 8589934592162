import { EVENTS_PAGE_TAB } from 'components/community/constants';
import { MODAL_TYPE_CREATE_MULTI_COMMUNITY } from 'components/modals';
import { getMyCommunities } from 'store/actions';
import { closeAppModal } from 'store/actions/layout';
import { logger } from 'utils/logger';
import { getUrlParamsString } from 'utils/redirections';
import { IS_CUSTOM_TENANT } from 'utils/tenant';
import history from '../../history';
import {
    REACT_APP_EU_BASE_URL,
    REACT_APP_GLOBAL_BASE_URL,
    REACT_APP_US_BASE_URL,
} from './airmeet';
import { TOAST_VERSION_2 } from './toasts';

export const DATA_CENTER_REGION_IDS = {
    IN: 'IN',
    EU: 'EU',
    US: 'US',
};

export const dataCenterRegions = [
    {
        id: DATA_CENTER_REGION_IDS.IN, // it is global
        label: 'India',
        baseUrl: REACT_APP_GLOBAL_BASE_URL,
    },
];

if (REACT_APP_US_BASE_URL) {
    dataCenterRegions.push({
        id: DATA_CENTER_REGION_IDS.US,
        label: 'United States',
        baseUrl: REACT_APP_US_BASE_URL,
    });
}

if (REACT_APP_EU_BASE_URL) {
    dataCenterRegions.push({
        id: DATA_CENTER_REGION_IDS.EU,
        label: 'Ireland',
        baseUrl: REACT_APP_EU_BASE_URL,
    });
}

export const globalProfileRedirection = () => {
    const params = getUrlParamsString([
        { key: 'source', value: window.location.href },
    ]);
    window.location.href = `${
        IS_CUSTOM_TENANT ? '' : REACT_APP_GLOBAL_BASE_URL
    }/profile${params}`;
};

export const getUserLastActiveRegion = (user) => {
    const id = IS_CUSTOM_TENANT
        ? DATA_CENTER_REGION_IDS.IN
        : user?.last_active_region || DATA_CENTER_REGION_IDS.IN;

    return (
        dataCenterRegions.find((data) => data.id === id) ||
        dataCenterRegions.find((data) => data.id === DATA_CENTER_REGION_IDS.IN)
    );
};

export const getCurrentRegion = () => {
    return (
        dataCenterRegions.find(
            (region) => region?.baseUrl === window?.location?.origin
        ) || dataCenterRegions[0]
    );
};

const curRegion = getCurrentRegion();
export const isGlobalRegion = curRegion?.id === DATA_CENTER_REGION_IDS.IN;
export const isUSRegion = curRegion?.id === DATA_CENTER_REGION_IDS.US;
export const isEURegion = curRegion?.id === DATA_CENTER_REGION_IDS.EU;

export const handleCommunityResponse = async ({
    result,
    errorToast,
    toastRef,
    dispatch,
    regionCommunity,
}) => {
    if (result.error) {
        errorToast({
            title: 'Could not create the community',
            message:
                result?.payload?.json?.message ||
                'Please try creating the community again.',
            version: TOAST_VERSION_2,
            closeToastOnly: true,
        }).then(({ payload }) => (toastRef.current = payload.id));
        if (regionCommunity) {
            const redirectedTab = EVENTS_PAGE_TAB;
            window.location.href = `/community-manager/${regionCommunity.communityId}/${redirectedTab}`;
        }
        return;
    }
    try {
        await dispatch(getMyCommunities());
        dispatch(closeAppModal(MODAL_TYPE_CREATE_MULTI_COMMUNITY));
        history.push({
            pathname: `/community-manager/${result?.payload?.communityId}/${EVENTS_PAGE_TAB}`,
            search: 'success=true',
            state: { allowSuccessMessage: true },
        });
    } catch (e) {
        logger.error('Datacenter.handleCommunityResponse: ', e);
    }
};
