import Tooltip from 'atoms/Tooltip/Tooltip';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import React from 'react';
import styled from 'styled-components';
import { EVENT_SUB_TYPES, EVENT_TYPES } from 'utils/constants/airmeet';
import { SESSION_TYPES } from 'utils/constants/sessions';
import { canShowPremiumFeatures } from 'utils/core';

export const ERROR_CODES = {
    AIRMEET_DOWN_TIME_ERROR: 'AIRMEET_DOWN_TIME_ERROR',
};

export const getAirmeetDetails = ({
    formData,
    subscription_type,
    eventType,
    getTzAdjustedPayload,
    pageMode,
    onboardingInitiation,
    endDateToStartDateTimeDiff,
}) => {
    const premiumFeaturesVisible = canShowPremiumFeatures(
        subscription_type,
        eventType === EVENT_TYPES.CONFERENCE &&
            subscription_type !== 'ENTERPRISE' &&
            subscription_type !== 'APPSUMO'
    );

    const metadata = {
        name: formData.name,
        shortDesc: formData.description,
        private: formData.isPrivate,
        noauth: formData.noAuth,
        attendeeListEnabled: formData.isAttendeeList,
    };
    const miscInfo = {
        enableRtmpKey: premiumFeaturesVisible,
        eventImage: formData.eventImage,
        longDesc: formData.longDesc,
        networking: formData.hasVirtualTables,
        tableCount:
            (formData.hasVirtualTables && +formData.tableCount) || undefined,
    };
    const adjustedTime = getTzAdjustedPayload(formData, true);
    const timeInfo = {
        startTime: adjustedTime.initDate.getTime(),
        endTime:
            pageMode && onboardingInitiation
                ? adjustedTime.initDate.getTime() + endDateToStartDateTimeDiff
                : adjustedTime.finalDate.getTime(),
        tz: adjustedTime.timeZone,
    };
    return {
        metadata,
        miscInfo,
        timeInfo,
    };
};

const OptionTooltipContent = ({ title, points = [] }) => {
    return (
        <FlexDiv flexDirection='column' alignItems='stretch'>
            {title && (
                <Text variant='h5' color='text.default.primary' pb='x2'>
                    {title}
                </Text>
            )}
            {Array.isArray(points) && points.length > 0 ? (
                <Ul>
                    {points.map((item) => (
                        <Li key={item?.id}>
                            <Text
                                variant='caption'
                                color='text.default.secondary'
                            >
                                {item?.label}
                            </Text>
                        </Li>
                    ))}
                </Ul>
            ) : null}
        </FlexDiv>
    );
};

export const EVENT_SESSION_TYPE_OPTIONS = [
    {
        value: SESSION_TYPES.HOSTING,
        label: 'Live broadcast session',
        subText: 'Can be talks, workshops, discussions, celebrations etc',
        plan: 'free',
        enabledForFreeTrial: true,
    },
    {
        value: SESSION_TYPES.GROUP_MEETING,
        label: 'Session as a video call',
        subText: 'Unmoderated round-table conversations/discussions',
        plan: 'free',
        enabledForFreeTrial: true,
    },
    {
        value: SESSION_TYPES.PRE_RECORDED,
        label: 'Pre recorded video session',
        subText: 'Create quick automated pre-recorded webinar',
        plan: 'paid',
        enabledForFreeTrial: true,
    },
    {
        value: SESSION_TYPES.STREAMING,
        label: 'Stream into Airmeet',
        subText: 'Broadcast from a 3rd party platform into Airmeet by RTMP',
        plan: 'paid',
        enabledForFreeTrial: false,
    },
];

const litePoints = [
    { id: 1, label: 'Only one session in webinar' },
    { id: 2, label: 'Attendees to land directly at stage' },
    { id: 3, label: 'No networking functionality available' },
    { id: 4, label: 'Webinar starts at the scheduled start time' },
];

const meetupPoints = [
    { id: 1, label: 'Multiple sessions can be added' },
    { id: 2, label: 'Attendees may land at lounge' },
    { id: 3, label: 'Networking activities can be added' },
];

const Ul = styled.ul`
    margin: 0 0 0 ${({ theme }) => theme.space.x2};
    padding: 0;
    color: ${({ theme }) => theme.colors.text.default.secondary};
`;

const Li = styled.li`
    margin: 0;
    padding: 0;
`;

const Content = styled.span`
    display: inline-block;
    margin-right: ${({ theme }) => theme.space.x2};
`;

export const MEETUP_SUB_TYPE_OPTIONS = [
    {
        value: EVENT_SUB_TYPES.LITE,
        label: (
            <>
                <Content>Single session</Content>
                <Tooltip
                    content={
                        <OptionTooltipContent
                            title='Single session'
                            points={litePoints}
                        />
                    }
                    hasWrapper={true}
                    maxWidth='15rem'
                >
                    <StyledSvgSprite
                        icon='icon-info-outline'
                        height='1rem'
                        width='1rem'
                        fill='text.default.secondary'
                    />
                </Tooltip>
            </>
        ),
    },
    {
        value: EVENT_TYPES.MEETUP,
        label: (
            <>
                <Content>Multi session</Content>
                <Tooltip
                    content={
                        <OptionTooltipContent
                            title='Multi session'
                            points={meetupPoints}
                        />
                    }
                    hasWrapper={true}
                    maxWidth='15rem'
                >
                    <StyledSvgSprite
                        icon='icon-info-outline'
                        height='1rem'
                        width='1rem'
                        fill='text.default.secondary'
                    />
                </Tooltip>
            </>
        ),
    },
];

export const ACCORDION_MAP = {
    basicInfo: [
        'name',
        'initDate',
        'initTime',
        'finalDate',
        'finalTime',
        'duration',
        'timeZone',
        'eventVenue',
        'venue_capacity',
    ],
    additionalInfo: ['supportEmail', 'longDesc', 'eventOrganiserName'],
};
