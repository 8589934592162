import React from 'react';
import BaseModalV2 from './templates/BaseModalV2';
import { cdnImage } from 'utils/core';
import { Box } from 'components/molecules/utils/basicComponents';
import styled from 'styled-components';
import useTheme from 'hooks/useTheme';
import { Text } from 'foundations/themeV2/text';
import { closeAppModal } from 'store/actions/layout';
import { MODAL_TYPE_EVENT_NOT_ACCESSIBLE } from './constants';
import { useDispatch } from 'react-redux';
import useDeviceInfo from 'hooks/useDeviceInfo';

const EventNotAccessibleModal = () => {
    const { theme } = useTheme();
    const { isMobile } = useDeviceInfo();

    const dispatch = useDispatch();

    return (
        <Wrapper>
            <BaseModalV2
                imgSrc={cdnImage('/images/warning-close.svg')}
                border={
                    isMobile
                        ? 'none'
                        : `1px solid ${theme.colors.border.default1}`
                }
                borderRadius={theme.radii.x4}
                onClose={() => {
                    dispatch(closeAppModal(MODAL_TYPE_EVENT_NOT_ACCESSIBLE));
                }}
                width={isMobile ? '100vw' : '90vw'}
                top={isMobile ? '78%' : '50%'}
            >
                <Text
                    variant={isMobile ? 'body1' : 'h4'}
                    color='text.default.primary'
                >
                    Event can not be accessed at the moment. Please contact the
                    organizer
                </Text>
            </BaseModalV2>
        </Wrapper>
    );
};

export default EventNotAccessibleModal;

const Wrapper = styled(Box)``;
