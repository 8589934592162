import Checkbox from 'atoms/Checkbox/Checkbox';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import { Typography } from 'foundations/typography';
import useOutsideClickHandler from 'hooks/useOutsideClickHandler';
import useTheme from 'hooks/useTheme';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { RoundedInput } from '../../EngagementTab/styledComponents';
import { getSearchMarkLabel } from '../../utils';

export default function SearchFilterInput({
    tags,
    options,
    selectedOptions,
    setSelectedOptions,
    type = 'session',
    isDropdownVisible,
    setIsDropdownVisible,
}) {
    const searchDropdownRef = useRef(null);
    const [selectedTag, setSelectedTag] = useState('ALL');
    const [searchText, setSearchText] = useState('');
    const { theme } = useTheme();

    useOutsideClickHandler(searchDropdownRef, () => {
        setIsDropdownVisible(false);
        setSelectedTag('ALL');
    });

    const handleSelectAll = () => {
        setSelectedOptions((options || []).map((option) => option?.id));
    };

    const handleDeselectAll = () => {
        setSelectedOptions([]);
    };

    const handleSelect = (value) => {
        setSelectedOptions(() => {
            if ((selectedOptions || []).includes(value)) {
                return selectedOptions.filter((id) => value !== id);
            }
            return [...(selectedOptions || []), value];
        });
    };

    const visibleOptions = useMemo(() => {
        if (
            (!searchText || searchText.trim() === '') &&
            (!selectedTag || selectedTag === 'ALL')
        )
            return options;

        return (options || [])
            .filter(
                (option) =>
                    (option?.label || '')
                        .trim()
                        .toLowerCase()
                        .includes((searchText || '').trim().toLowerCase()) &&
                    (!selectedTag ||
                        selectedTag === 'ALL' ||
                        (option?.tags || []).includes(selectedTag))
            )
            .map((option) => {
                const label = getSearchMarkLabel(searchText, option?.label);
                return { ...(option || {}), label };
            });
    }, [options, searchText, selectedTag]);

    return (
        <SearchWrapper>
            <Icon
                icon='search'
                fill='ambience.14'
                height='14px'
                width='14px'
                position='absolute'
                mt='9px'
                ml='12px'
            />
            <RoundedInput
                id='search'
                name='search'
                placeholder='Search'
                variant='v2'
                className='rounded-input'
                isBorder={false}
                value={searchText}
                onChange={(e) =>
                    setSearchText && setSearchText(e?.target?.value)
                }
                autocomplete='off'
                onFocus={() =>
                    setIsDropdownVisible && setIsDropdownVisible(true)
                }
            />
            {isDropdownVisible && (
                <Dropdown ref={searchDropdownRef}>
                    <DropdownTags>
                        <DropdownTag
                            isActive={selectedTag === 'ALL'}
                            onClick={() => setSelectedTag('ALL')}
                        >
                            All Tags
                        </DropdownTag>
                        {(tags || []).map((tag) => (
                            <DropdownTag
                                key={tag?.id}
                                isActive={selectedTag === tag?.id}
                                onClick={() => setSelectedTag(tag?.id)}
                            >
                                {tag?.name}
                            </DropdownTag>
                        ))}
                    </DropdownTags>
                    <DropdownItems>
                        <FlexDiv alignItems='center' pb={1} alignSelf='stretch'>
                            <Typography
                                variant='caption'
                                color={
                                    theme?.isLightTheme
                                        ? 'ambience.22'
                                        : 'ambience.6'
                                }
                                style={{ flexGrow: 1 }}
                            >
                                Select one or more {type}(s)
                            </Typography>
                            <FlexDiv alignItems='center'>
                                <Typography
                                    variant='caption'
                                    color={
                                        theme?.isLightTheme
                                            ? 'ambience.22'
                                            : 'ambience.6'
                                    }
                                    onClick={handleSelectAll}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Select All
                                </Typography>
                                <Typography
                                    variant='caption'
                                    color={
                                        theme?.isLightTheme
                                            ? 'ambience.22'
                                            : 'ambience.6'
                                    }
                                    mx={0}
                                >
                                    |
                                </Typography>
                                <Typography
                                    variant='caption'
                                    color='semantic.1'
                                    onClick={handleDeselectAll}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Deselect All
                                </Typography>
                            </FlexDiv>
                        </FlexDiv>
                        <FlexDiv
                            flexDirection='column'
                            alignItems='stretch'
                            alignSelf='stretch'
                            overflow='auto'
                        >
                            {(visibleOptions || []).map((option) => (
                                <Checkbox
                                    label={
                                        <span
                                            style={{
                                                color: theme?.isLightTheme
                                                    ? theme.colors.ambience[22]
                                                    : theme.colors.ambience[6],
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: option?.label,
                                            }}
                                        />
                                    }
                                    mb={1}
                                    uncheckedFill='ambience.10'
                                    iconSize='28px'
                                    filledSvgColor='accentPrimary.1'
                                    allowClickOnLabel={true}
                                    name='newsLetterOptIn'
                                    isChecked={(selectedOptions || []).includes(
                                        option?.id
                                    )}
                                    onClick={() => handleSelect(option?.id)}
                                    labelVariant='subtext0'
                                    key={option?.id}
                                />
                            ))}
                        </FlexDiv>
                    </DropdownItems>
                </Dropdown>
            )}
        </SearchWrapper>
    );
}

const SearchWrapper = styled(FlexDiv)`
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    position: relative;
`;

const Dropdown = styled(FlexDiv)`
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    max-height: 300px;
    align-items: stretch;
    padding: 24px 16px 16px 16px;
    background: #26252d;
    border-radius: 8px;
    z-index: 10;
`;

const DropdownTags = styled(FlexDiv)`
    flex-direction: column;
    align-self: stretch;
    overflow: hidden auto;
    min-width: 130px;
    padding-right: 16px;
`;

const DropdownTag = styled(FlexDiv)`
    align-self: stretch;
    padding: 4px 8px;
    background: #69657b;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color: #fcfcfc;
    width: 100%;
    margin-bottom: 16px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
        background: ${({ theme }) => theme.colors.accentPrimary[0]};
        color: #fcfcfc;
    }
    ${({ isActive, theme }) =>
        isActive &&
        `
        background: ${theme.colors.accentPrimary[0]};
        color: #fcfcfc;
    `}
`;

const DropdownItems = styled(FlexDiv)`
    flex-grow: 1;
    align-items: flex-start;
    padding-left: 20px;
    flex-direction column;
    overflow: hidden;
`;
