import { CONTEXT_TYPE } from 'hooks/attendance/constants';
import { getMemberInContextKey } from 'hooks/live-airmeet/useUserPresence';
import { PresenceContext } from 'services/presence/UserPresenceService';
import { Message } from '../types';
import DataObserverBase from './DataObserverBase';

class UserPresenceObserver extends DataObserverBase {
    subscribe(onData: Function, metadata?: any): Function {
        if (!metadata.userId) {
            throw new Error('Invalid User Id');
        }

        const onSnapshot = (message: Message) => {
            onData(message);
        };

        const presencePath = this.getPresencePath(metadata.context);
        const channel = `${this.airmeetId}/${presencePath}/${metadata.userId}`;
        const dataSourceUnsubscribe = super.subscribeImpl(channel, {
            onSnapshot,
        });

        return () => {
            dataSourceUnsubscribe && dataSourceUnsubscribe();
        };
    }

    getDefaultContext() {
        return {
            name: CONTEXT_TYPE.EVENT,
            id: this.airmeetId,
        };
    }

    getPresencePath(context?: PresenceContext) {
        context = context || this.getDefaultContext();
        return `userPresence/${getMemberInContextKey(
            context.id,
            context.name
        )}`;
    }
}

export default UserPresenceObserver;
