import Filter from '@airmeet/filter';
import noop from 'lodash/noop';
import { FILTER_SUPPORTED } from './constants/controls';
import { cdnImage } from './core';
import {
    filterLogs,
    genericFilterLogger,
    previewPageFilterLogger,
    stageFilterLogger,
    testModalFilterLogger,
    liveAnnouncementFilterLogger,
} from './loggers/filterLogger';
import { rtcSDKConfig } from 'utils/rtcSdkEnv.ts';

window.logger = genericFilterLogger;

/**
 * @todo - should move into sdk
 */
export const FILTER_TYPE = {
    blur: 'blur',
    bg: 'bg',
    none: 'none',
};

const MAX_RETRY_COUNT = 40;
const RETRY_INTERVAL = 250;

export const DEFAULT_BLUR_RADIUS = 0.7;

export const getBackgroundImage = (url = '') => {
    return url.startsWith('http')
        ? `${url}?t=${Number(Date.now())}`
        : cdnImage(url);
};

/**
 * @todo - need to verify whether this module can be incorporated within the SDK filter module
 */
class FilterManager {
    constructor(config = {}) {
        this.isSupported = isFilterSupported();
        this.logger = config.logger;
        this.originalMediaStreamTrack = null;

        this._taskInProgress = null;
        this._nextTaskInQueue = null;
        this._mutexIntervalTimer = null;
        this._mutexResolver = noop;

        if (this.isSupported) {
            this.filterInstance = new Filter({
                logger: this.logger,
            });
            this.filterInstance.init(
                rtcSDKConfig.rtcSdkAssetsBaseUrl,
                'filtermanager constructor'
            );
            this.filterInstance.updateBlurRadius(DEFAULT_BLUR_RADIUS);
        }
    }

    setOriginalTrackEnabled(enabled) {
        if (this.originalMediaStreamTrack) {
            this.originalMediaStreamTrack.enabled = enabled;
        }
    }

    isFilterActive() {
        return (
            this.isSupported &&
            (this.filterInstance.isFilterActive() ||
                (Boolean(this._taskInProgress) &&
                    this._taskInProgress !== FILTER_TYPE.none))
        );
    }

    _endWaitOfQueuedTask() {
        clearInterval(this._mutexIntervalTimer);
        this._mutexResolver();

        this._mutexIntervalTimer = null;
        this._mutexResolver = noop;
    }

    _enqueAndWait(taskId) {
        this._nextTaskInQueue = taskId;
        this.logger.debug(
            `Enqueuing ${taskId} as ${this._taskInProgress} is in progress`
        );
        // release the currently queued task, as it will be overwritten by the new incoming task
        this._endWaitOfQueuedTask();

        return new Promise((resolve) => {
            let retryCount = 0;

            this._mutexResolver = resolve;
            this._mutexIntervalTimer = setInterval(() => {
                if (!this._taskInProgress) {
                    this._endWaitOfQueuedTask();
                } else {
                    retryCount++;
                    if (retryCount > MAX_RETRY_COUNT) {
                        this.logger.error(
                            `Task ${this._taskInProgress} took too long to complete. Might lose state.`
                        );
                        this._endWaitOfQueuedTask();
                    }
                }
            }, RETRY_INTERVAL);
        });
    }

    _isPreviousTaskInProgress() {
        return this._taskInProgress !== null;
    }

    _registerNewTask(taskId) {
        // register taskId as the currently executing task.
        this._taskInProgress = taskId;
        // clear out the next task in queue, since it is being executed now.
        this._nextTaskInQueue = null;
    }

    _markCurrentTaskAsComplete() {
        this._taskInProgress = null;
    }

    async applyFilter(stream, type, details) {
        if (!stream) {
            throw new Error('No stream provided');
        }
        if (!this.isSupported) {
            throw new Error('Filter not supported');
        }
        let taskId = type;
        let fullImagePath = '';
        if (type === FILTER_TYPE.bg && details.pathToImage) {
            fullImagePath = getBackgroundImage(details.pathToImage);
            taskId = `${type}_${fullImagePath}`;
        }
        this.logger.info(
            `${filterLogs.applyStart} with type ${type} ${
                type === FILTER_TYPE.bg && fullImagePath
                    ? `and bg ${fullImagePath}`
                    : ''
            }`
        );
        if (this._nextTaskInQueue === taskId) {
            // duplicate request came while the same previous request is not yet resolved.
            this.logger.debug(
                'Same task as previous one received in applyFilter. Returning.'
            );
            return;
        }
        if (this._isPreviousTaskInProgress()) {
            try {
                // push the current task in queue and wait for previous task to finish
                await this._enqueAndWait(taskId);
            } catch (err) {
                this.logger.error(
                    'Error while waiting for previous task to complete',
                    err
                );
            }
            /**
             * After awaiting for previous task to complete, check if more recent task compared to the
             * currently awaiting task came in. TaskId of the most recent task will be stored in the
             * `_nextTaskInQueue` flag. All the other interim tasks can be ignored since it will be
             * overwritten by the most recent task anyway.
             */
            if (this._nextTaskInQueue !== taskId) {
                this.logger.debug(
                    `Not proceeding with ${taskId} since more recent task ${this._nextTaskInQueue} came in.`
                );
                return;
            }
            this.logger.debug(`Resuming task ${taskId}`);
        }

        this._registerNewTask(taskId);

        if (!this.originalMediaStreamTrack) {
            let curVideoTrack;
            if (stream.hasVideo() && stream.stream) {
                curVideoTrack = stream.stream.getVideoTracks()?.[0];
            }
            if (curVideoTrack instanceof MediaStreamTrack) {
                this.originalMediaStreamTrack = curVideoTrack.clone();
                this.logger.debug(
                    'Original track was null. Cloning track from stream.'
                );
            } else {
                this.logger.error('Provided stream does not have video track');
                throw Error('Video track not found');
            }
        }
        const videoTrack = this.originalMediaStreamTrack;
        const mediaStream = new MediaStream();
        mediaStream.addTrack(videoTrack);

        let filteredStream = null;
        if (type === FILTER_TYPE.blur) {
            const blurStart = performance.now();
            filteredStream = await this.filterInstance.activateBlur(
                mediaStream,
                DEFAULT_BLUR_RADIUS
            );
            const timeTakenToBlur = performance.now() - blurStart;
            this.logger.debug(
                `Blur applied to current stream. Time taken: ${timeTakenToBlur}ms`
            );
        } else if (type === FILTER_TYPE.bg && details.pathToImage) {
            const bgFilterStart = performance.now();
            filteredStream = await this.filterInstance.activateVirtualBg(
                mediaStream,
                fullImagePath
            );
            const timeTakenToApplyBg = performance.now() - bgFilterStart;
            this.logger.debug(
                `Bg applied to current stream. Time taken: ${timeTakenToApplyBg}ms`
            );
        } else {
            throw new Error('Unrecognized filter type');
        }

        const filteredVideoTrack = filteredStream.getVideoTracks()[0];
        const currentStreamTrack = stream.getVideoTrack();

        if (
            currentStreamTrack &&
            currentStreamTrack.id !== filteredVideoTrack.id
        ) {
            const streamReplaceStart = performance.now();
            try {
                await stream.replaceTrack(filteredVideoTrack);
                const timeTakenToReplace =
                    performance.now() - streamReplaceStart;
                this.logger.debug(
                    `Original track replaced with filtered track. Time taken: ${timeTakenToReplace}ms`
                );
            } catch (err) {
                const timeTakenToError = performance.now() - streamReplaceStart;
                this.logger.error(
                    `Error occurred while replacing original track with filtered track. Time taken: ${timeTakenToError}ms.`,
                    err
                );
                throw new Error(err);
            } finally {
                this._markCurrentTaskAsComplete();
            }
        } else {
            this._markCurrentTaskAsComplete();
        }
    }

    async stopFilter(stream) {
        if (!stream) {
            throw new Error('No stream provided');
        }
        if (!this.isSupported) {
            throw new Error('Filter not supported');
        }
        if (!this.originalMediaStreamTrack) {
            this.logger.debug(
                'Attempt to stop filter but original track not found. Filter is not active'
            );
            return;
        }

        this.logger.info(filterLogs.removeStart);

        const taskId = FILTER_TYPE.none;
        if (this._nextTaskInQueue === taskId) {
            // duplicate request came while the same previous request is not yet resolved.
            this.logger.debug(
                'Same task as previous one received in stopFilter. Returning.'
            );
            return;
        }

        if (this._isPreviousTaskInProgress()) {
            try {
                // push the current task in queue and wait for previous task to finish
                await this._enqueAndWait(taskId);
            } catch (err) {
                this.logger.error(
                    'Error while waiting for previous task to complete',
                    err
                );
            }
            /**
             * After awaiting for previous task to complete, check if more recent task compared to the
             * currently awaiting task came in. TaskId of the most recent task will be stored in the
             * `_nextTaskInQueue` flag. All the other interim tasks can be ignored since it will be
             * overwritten by the most recent task anyway.
             */
            if (this._nextTaskInQueue !== taskId) {
                this.logger.debug(
                    `Not proceeding with ${taskId} since more recent task ${this._nextTaskInQueue} came in.`
                );
                return;
            }
            this.logger.debug(`Resuming task ${taskId}`);
        }

        this._registerNewTask(taskId);

        const originalMediaStreamTrack = this.originalMediaStreamTrack;

        const onSuccessReplaceTrack = async () => {
            try {
                await this.filterInstance.deactivate();
                this.logger.debug('Filter deactivated successfully');
            } catch (err) {
                this.logger.warn('Failed to deactivate filter', err);
            }
        };

        const streamReplaceStart = performance.now();
        try {
            this.originalMediaStreamTrack = null;
            if (stream.hasVideo()) {
                await stream.replaceTrack(originalMediaStreamTrack);
                const timeTakenToReplace =
                    performance.now() - streamReplaceStart;
                this.logger.debug(
                    `Filtered track replaced with original video track. Time taken: ${timeTakenToReplace}ms`
                );
            } else {
                originalMediaStreamTrack.stop();
            }
            await onSuccessReplaceTrack();
        } catch (err) {
            const timeTakenToError = performance.now() - streamReplaceStart;
            this.logger.error(
                `Error occurred while replacing filtered track with original track. Time taken: ${timeTakenToError}ms. ${err}`
            );
        } finally {
            this._markCurrentTaskAsComplete();
        }
    }

    async close() {
        if (this.originalMediaStreamTrack) {
            this.originalMediaStreamTrack.stop();
            this.originalMediaStreamTrack = null;
        }
        if (this.filterInstance) {
            const isFilterActive = this.filterInstance.isFilterActive();
            const filterDeactivateStart = performance.now();
            await this.filterInstance.deactivate();
            const timeTakenToClose = performance.now() - filterDeactivateStart;
            if (isFilterActive) {
                // log only when filter was active initially.
                this.logger.info(
                    `Filter closed. Time taken: ${timeTakenToClose}ms`
                );
            }
        }
    }
}

let testModalFilter = null;
export const getTestModalFilter = () => {
    if (!testModalFilter) {
        testModalFilter = new FilterManager({
            logger: testModalFilterLogger,
        });
    }
    return testModalFilter;
};

let permissionWorkflowFilter = null;
export const getPermissionWorkflowFilter = () => {
    if (!permissionWorkflowFilter) {
        permissionWorkflowFilter = new FilterManager({
            logger: previewPageFilterLogger,
        });
    }
    return permissionWorkflowFilter;
};

let boardCastFilter = null;
export const getBroadcastFilter = () => {
    if (!boardCastFilter) {
        boardCastFilter = new FilterManager({
            logger: stageFilterLogger,
        });
    }
    return boardCastFilter;
};

let liveAnnouncementPreviewFilter = null;
export const getAnnouncementPreviewFilter = () => {
    if (!liveAnnouncementPreviewFilter) {
        liveAnnouncementPreviewFilter = new FilterManager({
            logger: liveAnnouncementFilterLogger,
        });
    }
    return liveAnnouncementPreviewFilter;
};

let supportedFilterInstance = null;
let filterSupportDetails = null;

export const printFilterSupportDetails = () => {
    const isSupported = filterSupportDetails?.status || false;
    if (isSupported) {
        genericFilterLogger.info(filterLogs.supported, filterSupportDetails);
    } else {
        const { airmeet, vendor } = filterSupportDetails || {};
        if (airmeet && !airmeet.status) {
            const criteria = airmeet.unmatchedCriteria;
            genericFilterLogger.info(
                `${filterLogs.notSupported}. Reason: ${criteria}.`,
                filterSupportDetails
            );
        } else if (vendor && !vendor.status) {
            let criteria = '';
            for (
                let i = 0, checkParams = Object.keys(vendor.details);
                i < checkParams.length;
                i++
            ) {
                if (!vendor.details[checkParams[i]]) {
                    criteria = checkParams[i];
                    break;
                }
            }
            genericFilterLogger.info(
                `${filterLogs.notSupported}. Reason: ${criteria}.`,
                filterSupportDetails
            );
        } else {
            genericFilterLogger.warn(
                'filter support details are not present',
                filterSupportDetails
            );
        }
    }
};

export const updateFilterSupport = async () => {
    try {
        if (!supportedFilterInstance) {
            supportedFilterInstance = new Filter({
                logger: genericFilterLogger,
            });
            await supportedFilterInstance.init(
                rtcSDKConfig.rtcSdkAssetsBaseUrl,
                'updatefiltersuppoprt in filtermanger'
            );
        }
        filterSupportDetails = await supportedFilterInstance.isSupported();
        printFilterSupportDetails();
    } catch (err) {
        genericFilterLogger.error(
            'error occured while fetching filter support information',
            err
        );
    } finally {
        localStorage.setItem(
            FILTER_SUPPORTED,
            filterSupportDetails?.status || false
        );
    }

    return filterSupportDetails ? filterSupportDetails.status : false;
};

// the value is updated is localStorage from useFilter hook, by calling updateFilterSupport
export const isFilterSupported = () =>
    Boolean(JSON.parse(localStorage.getItem(FILTER_SUPPORTED)));

export default FilterManager;
// dummy change
