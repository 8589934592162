import { getCurrentTenantData } from 'utils/tenant';

export const TOP_NAV_HEIGHT = 64;

export const TAB_MAX_WIDTH = 767;

export const ES_LP_TABS = [
    {
        id: 'series_hero_img',
        label: 'Hero image',
        hide: false,
    },
    { id: 'happeningNow', label: 'Happening now', hide: false },
    { id: 'upcomingEvents', label: 'Upcoming', hide: false },
    { id: 'pastEvents', label: 'Past Events', hide: false },
    { id: 'speakers', label: 'Speakers', hide: false },
    { id: 'sponsors', label: 'Sponsors', hide: false },
    { id: 'registerCard', label: 'Register Card', hide: false },
];
export const TABS_IN_HEADER = [
    { id: 'overview', label: 'Overview' },
    { id: 'events', label: 'Events List' },
];
const defaultColor = getCurrentTenantData('primaryColor');
export const DEFAULT_SERIES_LANDING_PAGE_CONFIG = {
    layout: 'modern',
    ambience: 'dark',
    primaryColor: `hsl(${defaultColor.H},${defaultColor.S}%,${defaultColor.L}%)`,
    primaryButtonTextColor: 'black',
};
export const UPCOMING_BLOCK = 'upcomingEvents';
export const HAPPENING_BLOCK = 'happeningNow';
export const PAST_EVENT_BLOCK = 'pastEvents';
export const SPONSORS_BLOCK = 'sponsors';
export const SPEAKER_BLOCK = 'speakers';
export const REGISTERED_CARD = 'registerCard';
export const SERIES_HERO_IMG = 'series_hero_img';
export const ES_LP_TAB_IDS = TABS_IN_HEADER.map((tab) => tab?.id);

// series events buttons
export const REGISTER = 'Register';
export const ENTER_EVENT = 'Enter Event';
export const ADD_TO_CALENDAR = 'Add to Calendar';
export const EXPLORE_EVENT = 'Explore Event';
export const WATCH_REPLAY = 'Watch Replay';
export const REGISTERED = 'Registered';
export const COMPLETED = 'Completed';

export const defaultEventSeriesKeys = [
    {
        label:
            'Register to block your calendar and get important notifications',
        key: 'EVENT_SERIES_REGISTER_TO_BLOCK',
    },
    { label: 'Upcoming', key: 'SESSION_UPCOMING' },
    { label: 'Sponsors', key: 'LEADERBOARD_SPONSORS' },
    { label: 'Register for series', key: 'EVENT_SERIES_REGISTER_FOR_SERIES' },
    { label: 'Happening now', key: 'EVENT_SERIES_HAPPENING_NOW' },
    {
        label: 'Please enter a valid phone number',
        key: 'EVENT_SERIES_VALID_PHONE_NO',
    },
    {
        label: 'Phone number cannot be empty',
        key: 'EVENT_SERIES_PHONE_NO_EMPTY',
    },
    { label: 'event', key: 'EVENT' },
    { label: 'Details', key: 'GENERAL_DETAILS' },
    { label: 'Events', key: 'GENERAL_EVENTS' },
    { label: 'Ascending', key: 'GENERAL_ASCENDING' },
    { label: 'Descending', key: 'GENERAL_DESCENDING' },
    { label: 'All', key: 'GENERAL_ALL' },
    { label: 'Finished', key: 'GENERAL_FINISHED' },
    { label: 'Paused', key: 'GENERAL_SESSION_STATUS_PAUSED' },
    { label: 'Ongoing', key: 'GENERAL_SESSION_STATUS_ONGOING' },
    { label: 'Upcoming', key: 'GENERAL_SESSION_STATUS_UPCOMING' },
];
