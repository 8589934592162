import keys from 'locale/keys';

export const POLL_STATE = {
    DRAFT: 'draft',
    PUBLISHED: 'published',
    CLOSED: 'closed',
};

export const POLLS_SHOW_RESULT = {
    HIDE: 'hide',
    AFTER_VOTE: 'after_vote',
    AFTER_POLL_END: 'after_poll_end',
};

export const ALL_POLLS = 'All';

export const POLLS_FILTER_OPTIONS = (t) => [
    { value: ALL_POLLS, label: t(keys.GENERAL_ALL) },
    { value: POLL_STATE.PUBLISHED, label: t(keys.GENERAL_SESSION_STATUS_LIVE) },
    { value: POLL_STATE.CLOSED, label: t(keys.POLLS_STATUS_CLOSED) },
];

export const POLLS_FILTER_OPTIONS_ADMIN = (t) => [
    { value: ALL_POLLS, label: t(keys.GENERAL_ALL) },
    { value: POLL_STATE.PUBLISHED, label: t(keys.POLLS_STATUS_PUBLISHED) },
    { value: POLL_STATE.DRAFT, label: t(keys.POLLS_STATUS_DRAFTS) },
    { value: POLL_STATE.CLOSED, label: t(keys.POLLS_STATUS_CLOSED) },
];

export const POLLS_SHOW_RESULT_OPTIONS = (t) => [
    {
        value: POLLS_SHOW_RESULT.AFTER_VOTE,
        label: t(keys.POLLS_CREATE_SHOW_RESULT_AFTER_VOTE),
    },
    {
        value: POLLS_SHOW_RESULT.AFTER_POLL_END,
        label: t(keys.POLLS_CREATE_SHOW_RESULT_AFTER_POLL_END),
    },
    {
        value: POLLS_SHOW_RESULT.HIDE,
        label: t(keys.POLLS_CREATE_SHOW_RESULT_HIDE),
    },
];

export const POLL_TYPE = {
    EVENT: 'event',
    SESSION: 'session',
};
