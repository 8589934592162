import * as RdxTooltip from '@radix-ui/react-tooltip';
import { Box } from 'components/molecules/utils/basicComponents';
import { useFullScreenContext } from 'context/FullScreen';
import { FULLSCREEN_PORTAL_ID } from 'components/general/fullScreen';
import { getZIndex } from 'foundations/themeV2/zIndex';
import React from 'react';
import styled from 'styled-components';

type SideEnum = 'left' | 'right' | 'top' | 'bottom' | undefined;
type AlignEnum = 'start' | 'center' | 'end';

interface TooltipProps {
    content: JSX.Element;
    side?: SideEnum;
    sideOffset?: number;
    align?: AlignEnum;
    alignOffset: number;
    container?: HTMLElement;
    isSmall?: boolean;
    hasWrapper?: boolean;
    fullscreenSupport?: boolean;
    containerId?: string;
    maxWidth?: string;
    disableHoverableContent?: boolean;
    show: boolean;
    disableScreenReader?: boolean;
    delayDuration?: number;
    isOpen?: boolean;
    onOpenChange?: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({
    children,
    content,
    side = 'top',
    sideOffset = 5,
    align = 'center',
    alignOffset = 0,
    container,
    isSmall = false,
    hasWrapper = false,
    fullscreenSupport = false,
    containerId,
    maxWidth = '100%',
    disableHoverableContent = false,
    show = true,
    disableScreenReader = false,
    delayDuration = 0,
    isOpen = undefined,
    onOpenChange,
    ...rest
}) => {
    const tooltipBlock = () => (
        <TooltipContent
            className='Content'
            sideOffset={sideOffset}
            side={side}
            align={align}
            alignOffset={alignOffset}
            isSmall={isSmall}
            {...rest}
        >
            <Box maxWidth={maxWidth} aria-hidden={disableScreenReader}>
                {content}
            </Box>
            {!isSmall && <RdxTooltip.Arrow className='TooltipArrow' />}
        </TooltipContent>
    );

    if (!show) {
        return children;
    }
    const portalElement = containerId
        ? document.getElementById(containerId)
        : container;

    return (
        <RdxTooltip.Provider>
            <RdxTooltip.Root
                delayDuration={delayDuration}
                disableHoverableContent={disableHoverableContent}
                onOpenChange={onOpenChange}
                open={isOpen}
            >
                <RdxTooltip.Trigger asChild>
                    {hasWrapper ? (
                        <span role='button' tabIndex={0}>
                            {children}
                        </span>
                    ) : (
                        children
                    )}
                </RdxTooltip.Trigger>
                {fullscreenSupport ? (
                    <FullScreenPortal
                        containerId={containerId ? containerId : 'root'}
                    >
                        {tooltipBlock()}
                    </FullScreenPortal>
                ) : (
                    <RdxTooltip.Portal
                        className='Portal'
                        container={portalElement}
                    >
                        {tooltipBlock()}
                    </RdxTooltip.Portal>
                )}
            </RdxTooltip.Root>
        </RdxTooltip.Provider>
    );
};

export default Tooltip;

const FullScreenPortal = ({ containerId, children }) => {
    const { fullScreen } = useFullScreenContext();
    const destinationContainer = fullScreen
        ? FULLSCREEN_PORTAL_ID
        : containerId;
    const portalElement = document.getElementById(destinationContainer);

    return (
        <RdxTooltip.Portal className='Portal' container={portalElement}>
            {children}
        </RdxTooltip.Portal>
    );
};

const TooltipContent = styled(RdxTooltip.Content)`
    border-radius: ${({ theme, isSmall }) =>
        !isSmall ? theme.radii.x3 : theme.radii.default};
    padding: ${({ theme, isSmall }) =>
        !isSmall ? theme.space.x4 : `${theme.space.x1} ${theme.space.x3}`};
    color: ${({ theme }) => theme.colors.text.default.primary};
    background-color: ${({ theme }) => theme.colors.fill.default1};
    border: 1px solid ${({ theme }) => theme.colors.border.default1};
    word-wrap: anywhere;

    user-select: none;
    animation-duration: 300ms;
    animation-timing-function: ease-out;
    will-change: opacity;
    animation-name: fade;
    z-index: ${({ theme }) =>
        getZIndex({
            zIndexType: theme.zIndices.types.above,
            zIndexRange: theme.zIndices.ranges['3xl'],
        })};

    a {
        color: ${({ theme }) =>
            theme?.isDashboardBrandingPage
                ? theme.colors.brandDefault
                : theme.colors.text.default.primary};
        font-family: ${({ theme }) => theme.fonts.bold};
        text-decoration: underline;
    }

    @keyframes fade {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .TooltipArrow {
        content: ' ';
        display: block;
        height: 12px;
        width: 12px;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.fill.default1};
        polygon {
            display: none;
        }
    }

    &[data-side='top'] {
        ${({ isSmall }) =>
            !isSmall &&
            `            
            margin-bottom: 6px;
        `}
        .TooltipArrow {
            border-bottom-right-radius: ${({ theme }) => theme.radii.x0};
            border-bottom: 1px solid
                ${({ theme }) => theme.colors.border.default1};
            border-right: 1px solid
                ${({ theme }) => theme.colors.border.default1};
            bottom: 0;
            left: ${({ alignOffset }) => `calc(50% + ${alignOffset}px)`};
            transform: translateX(-50%) rotate(45deg);
        }
    }
    &[data-side='right'] {
        ${({ isSmall }) =>
            !isSmall &&
            `            
            margin-left: 6px;
        `}
        .TooltipArrow {
            border-top-left-radius: ${({ theme }) => theme.radii.x0};
            border-top: 1px solid ${({ theme }) => theme.colors.border.default1};
            border-left: 1px solid
                ${({ theme }) => theme.colors.border.default1};
            left: -6px;
            top: ${({ alignOffset }) => `calc(50% + ${alignOffset}px)`};
            transform: translateY(-100%) rotate(225deg);
        }
    }
    &[data-side='bottom'] {
        ${({ isSmall }) =>
            !isSmall &&
            `            
            margin-top: 6px;
        `}
        .TooltipArrow {
            border-top-left-radius: ${({ theme }) => theme.radii.x0};
            border-top: 1px solid ${({ theme }) => theme.colors.border.default1};
            border-left: 1px solid
                ${({ theme }) => theme.colors.border.default1};
            top: -12px;
            left: ${({ alignOffset }) => `calc(50% +  ${alignOffset}px)`};
            transform: translateX(-50%) rotate(225deg);
        }
    }
    &[data-side='left'] {
        ${({ isSmall }) =>
            !isSmall &&
            `            
            margin-right: 6px;
        `}
        .TooltipArrow {
            border-top-right-radius: ${({ theme }) => theme.radii.x0};
            border-top: 1px solid ${({ theme }) => theme.colors.border.default1};
            border-right: 1px solid
                ${({ theme }) => theme.colors.border.default1};
            right: -6px;
            top: ${({ alignOffset }) => `calc(50% + ${alignOffset}px)`};
            transform: translateY(-100%) rotate(135deg);
        }
    }
`;
