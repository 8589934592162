export const FILE_TYPE_MAP = {
    Document: [
        '.pdf',
        '.ppt',
        '.csv',
        '.txt',
        '.odt',
        '.csv',
        '.xls',
        '.xlsx',
        '.xlsm',
        '.ods',
    ],
    Audio: ['.mp3'],
    video: ['.avi', '.h264', '.h265', '.mkv', '.mov', '.mp4'],
    image: ['.gif', '.jpg', '.jpeg', '.png', '.webp', '.svg', '.JPG', '.JPEG'],
};

export const CHANGE_IMAGE = ['image'];
export const CHANGE_VIDEO = ['video', 'sessionrecordings'];
export const SORT_OPTIONS = [
    { id: 0, label: 'Latest', sort_type: 'desc', sort_by: 'created_at' },
    { id: 1, label: 'Oldest', sort_type: 'asc', sort_by: 'created_at' },
    {
        id: 2,
        label: 'Last Modified',
        sort_type: 'desc',
        sort_by: 'updated_at',
    },
    { id: 3, label: 'Name (A - Z)', sort_type: 'asc', sort_by: 'name' },
    { id: 4, label: 'Name (Z - A)', sort_type: 'desc', sort_by: 'name' },
];

export const DEFAULT_FILTERS = {
    from_date: '',
    to_date: '',
    search_text: '',
    content_type: [],
    created_by: [],
    sort_type: '',
    sort_by: '',
};

export const DISPLAY_FILE_TYPE_MAP = {
    Document: {
        fileExtensions: [
            '.pdf',
            '.ppt',
            '.csv',
            '.txt',
            '.odt',
            '.csv',
            '.xls',
            '.xlsx',
            '.xlsm',
            '.ods',
        ],
        additionalText: '',
    },
    Audio: { fileExtensions: ['.mp3'], additionalText: '' },
    video: {
        fileExtensions: ['.avi', '.mkv', '.mov', '.mp4'],
        additionalText: '',
    },
    image: {
        fileExtensions: [
            '.gif',
            '.jpg',
            '.jpeg',
            '.png',
            '.webp',
            '.svg',
            '.JPG',
            '.JPEG',
        ],
        additionalText:
            'Upload file dimension: 500px x 500px. Max upload file size: 64MB.',
    },
};
