import classnames from 'classnames';
import Icon from 'foundations/icon';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';
import { Typography } from '../../../foundations/typography';
import { SPONSOR_IMG_FILE_PREFIX } from '../../community/editPublicAirmeet/Sponsors/Constants';
import FileUploader, {
    FILE_UPLOAD_STATUS,
} from '../../general/Form/FileUploader';
import SVGSprite, { StyledSvgSprite } from '../../general/SVGSprite';

const BOOTH_LOGO = 'booth_logo';
/**
 * Custom Image Uploader, a wrapper over existing File uploader component
 * Used across the Community Manager flows
 */
export default function ImageUploader({
    name,
    placeholder = 'Upload Image',
    renderPlaceholder,
    filePrefix,
    imageUrl,
    requestFileId,
    errorMsg = 'Only JPEG, SVG or PNG format & no larger than 5MB.',
    className = '',
    formats,
    removeLogo,
    sizeLimit,
    renderRemoveOption,
    imageType = 'logo',
    removeOption = false,
    showImageSize = true,
    defaultImage = false,
    fadeError = false,
    defaultImageUploaded = true,
    removeImgHandler = null,
    retryOption = false,
    preLoadedFileURL = '',
    submitErrorMsg = null,
    disabledUpload = false,
    successUploadHandler,
    isCustomUploader = false,
    style = {},
    // To enable modal img edit/crop upload
    handleUploadModal,
    closeModal,
    showUploadModal = false,
    minCropBoxHeight,
    minCropBoxWidth,
    placeholderWrapperStyle = {},
    hideErrorState = false,
    onError = noop,
    id,
    hidePreview = false,
    isShowEditImage = true,
    ariaLabel,
    getRequiredCalback = noop,
}) {
    const { t } = useCustomTranslation();
    const imageUploadStatus = useSelector(
        (state) => state.FileUploader.uploadStatus[requestFileId]
    );
    const [isError, setisError] = useState(false);
    const [isInEditMode, setIsInEditMode] = useState(false);
    useEffect(() => {
        setisError(imageUploadStatus === FILE_UPLOAD_STATUS.ERROR);
    }, [imageUploadStatus]);
    const errorPara = errorMsg?.split('\n').map((i, ind) => (
        <p
            key={ind}
            className={
                (filePrefix === SPONSOR_IMG_FILE_PREFIX ||
                    filePrefix === BOOTH_LOGO) &&
                'pt-0'
            }
        >
            {i}
        </p>
    ));

    return (
        <>
            <Wrapper
                className={`custom-upload${
                    disabledUpload ? ' custom-upload--disabled' : ''
                } ${className}`}
                style={style || {}}
            >
                <div
                    className={classnames('placeholder-text', {
                        invisible: !hideErrorState && isError,
                    })}
                    style={placeholderWrapperStyle || {}}
                >
                    {showUploadModal && isInEditMode
                        ? null
                        : (renderPlaceholder && renderPlaceholder()) || (
                              <>
                                  <div>
                                      <i className='fa fa-plus-circle'></i>
                                  </div>
                                  <label htmlFor={name}>{placeholder}</label>
                              </>
                          )}
                </div>
                {!disabledUpload && (
                    <FileUploader
                        t={t}
                        name={name}
                        filePrefix={filePrefix}
                        imageUrl={imageUrl}
                        requestFileId={requestFileId}
                        removeLogo={removeLogo}
                        formats={formats}
                        customError={true}
                        size={sizeLimit} // size is in MB
                        renderRemoveOption={renderRemoveOption || null}
                        imageType={imageType}
                        showImageSize={showImageSize}
                        defaultImage={defaultImage}
                        fadeError={fadeError}
                        defaultImageUploaded={defaultImageUploaded}
                        removeImgHandler={removeImgHandler}
                        existingImg={preLoadedFileURL}
                        successUploadHandler={successUploadHandler}
                        isCustomUploader={isCustomUploader}
                        showUploadModal={showUploadModal}
                        handleUploadModal={handleUploadModal}
                        closeModal={closeModal}
                        setIsInEditMode={setIsInEditMode}
                        isInEditMode={isInEditMode}
                        minCropBoxHeight={minCropBoxHeight}
                        minCropBoxWidth={minCropBoxWidth}
                        onError={onError}
                        id={id}
                        hidePreview={hidePreview}
                        isShowEditImage={isShowEditImage}
                        ariaLabel={ariaLabel}
                        getRequiredCalback={getRequiredCalback}
                    />
                )}

                {!hideErrorState &&
                    imageUploadStatus === FILE_UPLOAD_STATUS.ERROR && (
                        <div className='upload-error'>
                            {(retryOption && (
                                <>
                                    <div className='failed-icon'>
                                        {isCustomUploader ? (
                                            <>
                                                <Icon
                                                    fill={'#F8BCBA'}
                                                    icon={
                                                        'icon-cloud-upload-error'
                                                    }
                                                />
                                                <Typography
                                                    color={'ambience.0'}
                                                    variant={'h5'}
                                                    fontSize={'16px'}
                                                >
                                                    Error
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <SVGSprite icon='icon-error'></SVGSprite>
                                                {filePrefix !==
                                                    SPONSOR_IMG_FILE_PREFIX ||
                                                filePrefix !== BOOTH_LOGO ? (
                                                    <Typography
                                                        variant={'h5'}
                                                        color={'ambience.0'}
                                                        style={{
                                                            lineHeight: 0,
                                                        }}
                                                    >
                                                        Upload failed
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        color={'ambience.0'}
                                                        variant={'h6'}
                                                        className={'ml-2'}
                                                        style={{
                                                            lineHeight: 0,
                                                        }}
                                                    >
                                                        Upload failed
                                                    </Typography>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        <Typography
                                            variant={'caption2'}
                                            color={'ambience.0'}
                                            style={{
                                                lineHeight: 1,
                                                opacity: 1,
                                            }}
                                        >
                                            {errorPara}
                                        </Typography>
                                    </div>
                                    <div className='failed-icon retry'>
                                        <StyledSvgSprite
                                            fill='ambience.0'
                                            icon='icon-retry-no-fill'
                                        />
                                        {filePrefix !==
                                            SPONSOR_IMG_FILE_PREFIX ||
                                        filePrefix !== BOOTH_LOGO ? (
                                            <Typography
                                                color={'ambience.0'}
                                                variant={'h5'}
                                                style={{ lineHeight: 0 }}
                                            >
                                                Retry
                                            </Typography>
                                        ) : (
                                            <Typography
                                                color={'ambience.0'}
                                                variant={'h6'}
                                                style={{ lineHeight: 0 }}
                                            >
                                                Retry
                                            </Typography>
                                        )}
                                    </div>
                                </>
                            )) || (
                                <>
                                    {isCustomUploader ? (
                                        <>
                                            <Icon
                                                icon={'icon-cloud-upload-error'}
                                                fill={'#F8BCBA'}
                                            />
                                            <h5>Error</h5>
                                            <p role='alert'>{errorMsg}</p>
                                        </>
                                    ) : (
                                        <>
                                            {' '}
                                            <SVGSprite icon='icon-error'></SVGSprite>
                                            <Typography
                                                color={'ambience.0'}
                                                variant={'h5'}
                                                style={{ lineHeight: 1 }}
                                            >
                                                File upload error
                                            </Typography>
                                            <Typography
                                                color={'ambience.0'}
                                                variant={'body1'}
                                                style={{ lineHeight: 2 }}
                                                role='alert'
                                            >
                                                {errorMsg}
                                            </Typography>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                {imageUploadStatus === FILE_UPLOAD_STATUS.UPLOADING && (
                    <div id='loading-spinner'>
                        <div className='spin-icon'></div>
                    </div>
                )}
            </Wrapper>
            {submitErrorMsg && !imageUrl && (
                <div id='d-flex justify-content-end'>
                    <p className='error-msg px-0'>{submitErrorMsg}</p>
                </div>
            )}
        </>
    );
}

const Wrapper = styled.div`
    ${({ theme }) =>
        theme?.isLightTheme
            ? `
        background-color: ${theme.colors.ambience[24]} !important;
    `
            : ''}
`;
