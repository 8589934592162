import React from 'react';
import {
    CardBody,
    Card as CardComponent,
    CardHeader,
    CardTitle,
    Loading,
    LoadingWrapper,
} from '../styledComponents';
import EmptyCardBanner from './EmptyCardBanner';

export default function ChartCard({
    title,
    actions,
    loading,
    children,
    bodyStyle = {},
    minLoadingHeight = '200px',
    isEmpty = false,
    emptyBannerProps = {},
    style = {},
    hideOutline = false,
}) {
    return (
        <CardComponent hideOutline={hideOutline} style={style}>
            {(title || actions) && (
                <CardHeader>
                    <CardTitle>{title}</CardTitle>
                    {actions}
                </CardHeader>
            )}
            {loading ? (
                <LoadingWrapper style={{ minHeight: minLoadingHeight }}>
                    <Loading />
                </LoadingWrapper>
            ) : (
                <CardBody style={bodyStyle}>
                    {children}
                    {isEmpty && (
                        <EmptyCardBanner
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}
                            {...(emptyBannerProps || {})}
                        />
                    )}
                </CardBody>
            )}
        </CardComponent>
    );
}
