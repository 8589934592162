import Btn from 'atoms/Button/btn';
import Tooltip from 'atoms/Tooltip/Tooltip';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import WithTooltip from 'components/general/WithTooltip';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import useSummitBranding from 'hooks/summit/useSummitBranding';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { hexToHSL, rgbToHex } from 'utils/colors';
import { noop } from 'utils/constants/common';
import { DEFAULT_BRAND_LOGO } from 'utils/constants/tenantDetails';
import { getCurrentTenantData } from 'utils/tenant';
import { DEFAULT_BACKGROUND_TYPE } from '../CustomizeBackground/utils';
import { AUTO_BRAND_NB_BG_COLORS, getColorsFromImage } from './utils';

export default function AutoBrandBanner({
    handleChange,
    handleEventBrandingChange,
    theme_info,
    isAutoBrandApplied = false,
    brandColors = [],
    logEvent = noop,
}) {
    const { logo } = useSummitBranding();
    const isDefaultLogo = useMemo(
        () =>
            !logo?.src ||
            (logo?.src || '').includes(DEFAULT_BRAND_LOGO) ||
            (logo?.src || '').includes(getCurrentTenantData('logoUrl')) ||
            (logo?.src || '').includes(
                getCurrentTenantData('logoLightThemeUrl')
            ) ||
            (logo?.src || '').includes(getCurrentTenantData('logoNoTextUrl')) ||
            (logo?.src || '').includes(
                getCurrentTenantData('logoOnlyTextUrl')
            ) ||
            (logo?.src || '').includes(
                getCurrentTenantData('logoOnlyTextLightThemeUrl')
            ),
        [logo]
    );

    const onColorChange = (colors) => {
        const hexArr = [];
        let primaryButtonTextColor = 'hsl(0,0%,100%)';
        let brandColors = [];
        if (handleChange && Array.isArray(colors) && colors.length > 0) {
            colors.forEach((color, index) => {
                const rgbObj = {
                    r: color[0],
                    g: color[1],
                    b: color[2],
                };
                brandColors.push(rgbObj);

                if (index < AUTO_BRAND_NB_BG_COLORS) {
                    const hex = rgbToHex(rgbObj);
                    const hsl = hexToHSL(hex);

                    hexArr.push(hex);
                    if (index === 0) {
                        handleChange(
                            'primary_color',
                            `hsl(${hsl.h},${hsl.s}%,${hsl.l}%)`,
                            null,
                            hsl
                        );

                        // Decide which ambience and primary button text color is best fit for the given primary color
                        const luma =
                            0.2126 * rgbObj.r +
                            0.7152 * rgbObj.g +
                            0.0722 * rgbObj.b;

                        // Change ambience
                        if (theme_info?.is_light_theme) {
                            if (luma > 40) {
                                handleChange('is_light_theme', false);
                            }
                        } else if (luma <= 40) {
                            handleChange('is_light_theme', true);
                        }

                        // Change primary button text color
                        if (hsl.l >= 40) {
                            primaryButtonTextColor = 'hsl(250,0%,20%)';
                        }
                    }
                }
            });
        }

        if (handleEventBrandingChange) {
            handleEventBrandingChange('live_config', null, null, {
                background: {
                    type: 'linearGradient',
                    value: hexArr.reverse(),
                },
                primaryButtonTextColor,
                isAutoBrandApplied: true,
                brandColors,
            });
        }
    };

    const handleClick = () => {
        if (isAutoBrandApplied) {
            logEvent('autoBrandReset');
            const defaultColor = getCurrentTenantData('primaryColor');
            // Reset branding
            handleChange('is_light_theme', false);
            handleChange(
                'primary_color',
                `hsl(${defaultColor.H},${defaultColor.S}%,${defaultColor.L}%)`,
                null
            );
            handleEventBrandingChange('live_config', null, null, {
                background: {
                    type: DEFAULT_BACKGROUND_TYPE,
                },
                primaryButtonTextColor: 'hsl(0,0%,100%)',
                isAutoBrandApplied: false,
            });
        } else {
            logEvent('autoBrandClickApply');
            // Apply Auto branding
            getColorsFromImage(logo?.src, onColorChange);
        }
    };

    return isDefaultLogo ? null : (
        <Wrapper>
            <FlexDiv alignItems='flex-end'>
                <FlexDiv
                    flex='1'
                    flexDirection='column'
                    alignItems='stretch'
                    pr='12px'
                >
                    <Text variant='h5' color='text.default.primary' pb='8px'>
                        Auto Brand{' '}
                        <span role='img' aria-label='auto brand'>
                            ✨
                        </span>
                    </Text>
                    <Text variant='caption' color='text.default.secondary'>
                        Picking colors from logo to set right theme for the
                        event
                    </Text>
                </FlexDiv>
                <WithTooltip
                    tooltip={
                        isAutoBrandApplied &&
                        'Resetting will remove the colours applied by AutoBrand and any other colours applied by you.'
                    }
                    place='top'
                >
                    <Btn variant='primary' onClick={handleClick}>
                        <StyledSvgSprite
                            icon={
                                isAutoBrandApplied
                                    ? 'arrow-reset'
                                    : 'magic_wand'
                            }
                            width='16px'
                            height='16px'
                            mr='4px'
                            mb='4px'
                        />
                        {isAutoBrandApplied ? 'Reset' : 'Apply'}
                    </Btn>
                </WithTooltip>
            </FlexDiv>
            {isAutoBrandApplied ? (
                <FlexDiv alignItems='center' pt='16px'>
                    <FlexDiv alignItems='center' flex='1' pr='10px'>
                        <Text variant='caption' color='text.default.primary'>
                            Logo
                        </Text>
                        <Tooltip
                            content={
                                <Text
                                    variant='caption'
                                    color='text.default.secondary'
                                >
                                    Please reload the page if the latest logo
                                    that you uploaded is not reflecting here.
                                </Text>
                            }
                            side='bottom'
                            hasWrapper={true}
                            maxWidth='208px'
                        >
                            <StyledSvgSprite
                                icon='ic-info'
                                height='12px'
                                width='12px'
                                fill='text.default.primary'
                                ml='3px'
                                mr='8px'
                            />
                        </Tooltip>
                        <Logo src={logo?.src} alt={logo?.alt} />
                    </FlexDiv>
                    {Array.isArray(brandColors) && brandColors.length > 0 ? (
                        <FlexDiv alignItems='center'>
                            <Text
                                variant='caption'
                                color='text.default.primary'
                            >
                                Applied color(s)
                            </Text>
                            {brandColors
                                .slice(0, AUTO_BRAND_NB_BG_COLORS)
                                .map((color) => (
                                    <ColorCircle
                                        key={`rgb(${color.r},${color.g},${color.b})`}
                                        bg={`rgb(${color.r},${color.g},${color.b})`}
                                    />
                                ))}
                        </FlexDiv>
                    ) : null}
                </FlexDiv>
            ) : null}
        </Wrapper>
    );
}

const Wrapper = styled(FlexDiv)`
    border-radius: ${({ theme }) => theme?.radii.default};
    border: 1px solid ${({ theme }) => theme.colors.border.default2};
    background-color: ${({ theme }) => theme.colors.fill.other3};
    backdrop-filter: ${({ theme }) => theme.overlay.bgBlur};
    padding: 16px;
    margin-bottom: 32px;
    flex-direction: column;
    align-items: stretch;
`;

const Logo = styled.img`
    height: 20px;
    width: auto;
`;

const ColorCircle = styled(FlexDiv)`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-left: 4px;
    border: solid 1px ${({ theme }) => theme.colors.border.default1};
`;
