import useSystemTimeDifference from 'hooks/useSystemTimeDifference';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const BREAKOUT_SESSION_END_TIMER = 30;

const useBreakoutSessionTimer = ({ activeBreakoutId, endAt }) => {
    const timeDifference = useSystemTimeDifference();
    const [timeLeftState, setTimeLeft] = useState(null);
    const [timeLeftInMin, setTimeLeftInMin] = useState(null);
    const timeLeftInMinRef = useRef(null);

    const getTimeLeft = useCallback(() => {
        if (!endAt) return null;

        const timeLeftInSecTemp =
            (endAt - (Date.now() + timeDifference)) / 1000;
        return Math.round(Math.max(timeLeftInSecTemp, 0));
    }, [endAt, timeDifference]);

    const timeLeft = useMemo(() => {
        return timeLeftState === null ? getTimeLeft() : timeLeftState;
    }, [endAt, timeLeftState]);

    useEffect(() => {
        setTimeLeft(getTimeLeft());
        if (!endAt) {
            setTimeLeftInMin(null);
            return;
        }
        const timer = setInterval(() => {
            const timeLeftCal = getTimeLeft();
            const timeLeftInMinCalc = Math.ceil(timeLeftCal / 60);
            timeLeftInMinRef.current = timeLeftCal;
            if (timeLeftCal < BREAKOUT_SESSION_END_TIMER)
                setTimeLeft(timeLeftCal);
            if (timeLeftInMinCalc !== timeLeftInMinRef.current)
                setTimeLeftInMin(timeLeftInMinCalc);
            if (timeLeftCal <= 0) clearInterval(timer);
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [endAt, getTimeLeft]);

    const isBreakoutDurationLapsed = endAt && timeLeft === 0;
    return { timeLeft, timeLeftInMin, isBreakoutDurationLapsed };
};

export default useBreakoutSessionTimer;
