import { getDateStr } from 'components/molecules/card/AirmeetCard/utils';
import get from 'lodash/get';
import {
    DEFAULT_EVENT_DURATION,
    EVENT_SUB_TYPES,
    EVENT_TYPES,
} from 'utils/constants/airmeet';
import { DEFAULT_TABLE_COUNT } from 'utils/constants/social-lounge';
import useTimezoneUtils from '../../common/TimezoneSelector/useTimezoneUtils';
import { getNearestQuarter } from '../../common/utils';

export const useInitialValues = (
    airmeet,
    community,
    templateTotalSessionDuration,
    eventType
) => {
    const { localTzOption } = useTimezoneUtils();
    const { name: org_name } = community || {};
    const initialState = {
        name: '',
        description: '',
        isPrivate: true,
        isAttendeeList: false,
        noAuth: false,
        initDate: getNearestQuarter().toDate(),
        finalDate: templateTotalSessionDuration
            ? getNearestQuarter()
                  .add(templateTotalSessionDuration, 'minutes')
                  .toDate()
            : getNearestQuarter().add(1, 'hours').toDate(),
        timeZone: get(localTzOption, 'value', ''),
        hasVirtualTables: true,
        hasTicketing: false,
        tableCount: DEFAULT_TABLE_COUNT,
        longDesc: '',
        supportEmail: '',
        eventOrganiserName: org_name || '', // The default value of eventOrganiserName will be the community name
        eventVenue: {},
        duration: DEFAULT_EVENT_DURATION,
    };

    if (!airmeet && eventType === EVENT_TYPES.MEETUP) {
        initialState.meetupSubType = EVENT_SUB_TYPES.LITE;
    }

    if (airmeet) {
        const {
            name,
            short_desc,
            is_private,
            noauth_enabled,
            attendee_list_enabled,
            start_time,
            end_time,
            timezone,
            tableCount,
            event_type,
            long_desc,
            support_email,
            event_organiser_name,
            community_name,
            hybrid_event_venue,
            event_sub_type,
            sessions = [],
        } = airmeet || {};
        const data = {
            ...initialState,
            name,
            description: short_desc,
            isPrivate: is_private,
            noAuth: noauth_enabled,
            isAttendeeList: attendee_list_enabled,
            initDate: new Date(
                getDateStr({
                    start_time,
                    timezone: timezone,
                    format: 'YYYY-MM-DD HH:mm:ss',
                })
            ),
            finalDate: new Date(
                getDateStr({
                    end_time,
                    timezone: timezone,
                    format: 'YYYY-MM-DD HH:mm:ss',
                })
            ),
            timeZone: timezone,
            tableCount: tableCount,
            eventType: event_type || eventType,
            longDesc: long_desc,
            supportEmail: support_email,
            eventOrganiserName:
                event_organiser_name || community_name || org_name,
            eventVenue: hybrid_event_venue,
            venue_capacity: hybrid_event_venue?.venue_capacity,
        };

        if (event_sub_type === EVENT_SUB_TYPES.LITE) {
            data.meetupSubType = EVENT_SUB_TYPES.LITE;
            if (Array.isArray(sessions) && sessions.length > 0) {
                data.sessionType = sessions[0]?.type;
                data.duration = sessions[0].duration;
                if (data.sessionType === 'PRE_RECORDED') {
                    data.sessionMeta = sessions[0]?.session_meta;
                }
            }
        }

        return data;
    }
    return initialState;
};
