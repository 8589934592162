import React, { Children, cloneElement, isValidElement } from 'react';

/**
 * ChildrenWithProps
 * This component is use for pass the props from Parent Component to children
 * @param {children, *} props
 */
const ChildrenWithProps = ({ children, ...props }) => {
    const childrenWithProps = Children.map(children, (child) => {
        // Checking isValidElement is the safe way and avoids a TS error too.
        if (isValidElement(child)) {
            return cloneElement(child, props || {});
        }

        return child;
    });

    return <>{childrenWithProps}</>;
};

export default ChildrenWithProps;
