import { DataReaderOptions } from 'hooks/useDataReader';
import { useMemo } from 'react';
import ChatReactionObserver from 'services/realtime/observer/ChatReactionDataObserver';
import ChatReactionObserverOld from 'services/realtime/observer/ChatReactionDataObserverOld';
import ReactionObserver from 'services/realtime/observer/ReactionDataObserver';
import TableDataObserver from 'services/realtime/observer/TableDataObserver';
import UserPresenceObserver from 'services/realtime/observer/UserPresenceObserver';
import UserProfileObserver from 'services/realtime/observer/UserProfileObserver';
import { FEATURE_NAMES } from 'utils/constants/featureNames';
import ChatObserver from '../../services/realtime/observer/ChatObserver';
import DataObserverBase from '../../services/realtime/observer/DataObserverBase';
import TxnStatusObserver from '../../services/realtime/observer/TxnStatusObserver';

interface DataObserverOptions {
    eventObserverOptions: DataReaderOptions;
    sessionObserverOptions: DataReaderOptions;
    isMultipleEmoji?: boolean;
}
const useRealTimeDataObserver = (
    featureName: string,
    options: DataObserverOptions
): DataObserverBase => {
    const eventObserver = useMemo(() => {
        switch (featureName) {
            case FEATURE_NAMES.TXN_UPDATES:
                return new TxnStatusObserver(options.eventObserverOptions);

            case FEATURE_NAMES.EVENT_CHAT:
                return new ChatObserver(options.eventObserverOptions);

            case FEATURE_NAMES.USER_PROFILE:
                return new UserProfileObserver(options.eventObserverOptions);

            case FEATURE_NAMES.USER_PRESENCE:
                return new UserPresenceObserver(options.eventObserverOptions);

            case FEATURE_NAMES.LOUNGE_TABLES:
            case FEATURE_NAMES.BOOTH_TABLES:
            case FEATURE_NAMES.BREAKOUT_TABLES:
                return new TableDataObserver(options.eventObserverOptions);
        }
    }, [featureName, options.eventObserverOptions]);

    const sessionObserver = useMemo(() => {
        switch (featureName) {
            case FEATURE_NAMES.STAGE_REACTIONS:
                return new ReactionObserver(options.sessionObserverOptions);

            case FEATURE_NAMES.STAGE_CHAT:
            case FEATURE_NAMES.SESSION_CHAT:
                return new ChatObserver(options.sessionObserverOptions);

            case FEATURE_NAMES.CHAT_REACTION: {
                /* need to change here */
                if (options.isMultipleEmoji) {
                    return new ChatReactionObserver(
                        options.sessionObserverOptions
                    );
                } else {
                    return new ChatReactionObserverOld(
                        options.sessionObserverOptions
                    );
                }
            }

            case FEATURE_NAMES.USER_PRESENCE:
                return new UserPresenceObserver(options.sessionObserverOptions);
        }
    }, [featureName, options.isMultipleEmoji, options.sessionObserverOptions]);

    const observer = eventObserver || sessionObserver;

    observer.featureName = featureName;
    return observer;
};

export default useRealTimeDataObserver;
