import RestrictedAccountTooltip from 'atoms/RestrictedAccountTooltip';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import RelativeToolTip from 'components/tooltip/relativeToolTip';
import useTheme from 'hooks/useTheme';
import React from 'react';
import styled from 'styled-components';
import { areSomeOfCurrentNavTabsSubTabsVisibleAndClickable } from 'utils/dashboard';

export default function NavTab({
    tabs = [],
    activeTabId,
    onChange,
    hideActiveBorder,
    navItemIdPrefix = null,
    isCommunityPage = false,
    ...rest
}) {
    const { theme } = useTheme();
    return (
        <StyledNavTab {...rest}>
            {tabs.map((tab) => {
                if (
                    // Hide header tab which is not visible OR
                    // none of the header tab's sub tabs are
                    // clickable and visible
                    tab.hasOwnProperty('visible') &&
                    (!tab?.visible ||
                        (tab.tabs?.length &&
                            !areSomeOfCurrentNavTabsSubTabsVisibleAndClickable(
                                tab
                            )))
                ) {
                    return null;
                }
                return (
                    <NavItem
                        key={`nav-item-${tab?.id}`}
                        id={
                            navItemIdPrefix
                                ? `${navItemIdPrefix}_${tab?.id}`
                                : ''
                        }
                        onClick={() =>
                            !tab?.disabled &&
                            (!tab.hasOwnProperty('clickable') ||
                                tab?.clickable) &&
                            onChange(tab)
                        }
                        isActive={tab?.id === activeTabId}
                        disabled={
                            (tab.hasOwnProperty('clickable') &&
                                !tab?.clickable) ||
                            tab.disabled
                        }
                        hideActiveBorder={hideActiveBorder}
                    >
                        {tab.hasOwnProperty('clickable') && !tab?.clickable && (
                            <RestrictedAccountTooltip />
                        )}
                        {tab?.title || tab?.label}
                        {tab?.info && (
                            <RelativeToolTip
                                place='relative-tooltip top'
                                svgWidth='13.34px'
                                svgHeight='13.34px'
                                svgIcon='icon-fill-info'
                                svgFill='accentPrimary.0'
                                tooltipText={tab?.info}
                                textColor='ambience.18'
                                textVariant='caption'
                                textAlign='left'
                                minWidth={288}
                            />
                        )}
                        {tab?.rhs || null}
                    </NavItem>
                );
            })}
            {isCommunityPage && (
                <RelativeToolTip
                    place='top'
                    svgWidth='16px'
                    svgHeight='16px'
                    svgIcon='icon-fill-info'
                    svgFill={theme.colors.accentPrimary[0]}
                    tooltipContainerStyle={{
                        top: 'calc(-100% + 20px)',
                    }}
                    tooltipText={
                        <>
                            <p>
                                Please note that Country, Traffic Source,
                                Device, Browser filters and columns will be
                                available for events created on or after March
                                1, 2022.
                            </p>
                            <p>
                                Also, the data gets refreshed at every half-hour
                                interval.
                            </p>
                        </>
                    }
                    textColor={
                        theme?.isLightTheme ? 'ambience.1' : 'ambience.24'
                    }
                    textVariant='subtext1'
                    textAlign='left'
                    minWidth={300}
                    showStyledSvgSprite={true}
                />
            )}
        </StyledNavTab>
    );
}

const StyledNavTab = styled(FlexDiv)`
    display: flex;
    border-bottom: 1px solid
        ${({ theme, borderColor }) =>
            borderColor ||
            (theme?.isLightTheme
                ? theme.colors.ambience[21]
                : 'rgba(38, 37, 45, 0.64)')};
    position: relative;
`;

const NavItem = styled.div`
    margin-right: 24px;
    padding-bottom: 10px;
    position: relative;
    font-size: 15px;
    line-height: 22px;
    color: ${({ theme, disabled }) =>
        disabled
            ? '#69657B'
            : theme?.isLightTheme
            ? theme.colors.ambience[1]
            : theme.colors.ambience[6]};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    ${({ hideActiveBorder }) =>
        !hideActiveBorder &&
        `&:after {
            content: '';
            display: block;
            width: 100%;
            height: 4px;
            background: transparent;
            border-radius: 8px;
            position: absolute;
            bottom: 0;
    }`}

    p {
        position: relative;
    }

    .relative-tooltip {
        margin-left: 10px;
        svg {
            fill: ${({ theme }) => theme.colors.accentPrimary[0]};
        }
    }

    ${({ theme, isActive, disabled, hideActiveBorder }) =>
        isActive &&
        !disabled &&
        `
        color: ${theme.colors.ambience[0]};
        font-weight: bold;
        ${
            !hideActiveBorder &&
            `&:after {
                background: ${theme.colors.accentPrimary[0]};
            }`
        }
    `}
`;
