import pickBy from 'lodash/pickBy';
import startsWith from 'lodash/startsWith';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import { LIVE_AIRMEET_STATE } from 'utils/constants/live-airmeet';
import { logger } from '../logger';
import { LOG_LEVEL } from './logger';

export const QOELog = logger.init('QOE', 'green', LOG_LEVEL.INFO);
export const QOEFirewallLogger = (constant, userId, reason = null) => {
    QOELog(
        `${constant} by user id : ${userId} ${reason ? `, with ${reason}` : ''}`
    );
};
export const QOELogger = (constant, localId, reason = null) => {
    QOELog(
        `${constant} by local stream id : ${localId} ${
            reason ? `, with ${reason}` : ''
        }`
    );
};

export const QOEUserDropLoggerDirect = (
    userDropCase,
    key,
    rtcClient = {},
    currentState,
    reason = null
) => {
    QOELog(
        generateUserDropString(
            key,
            userDropCase,
            (rtcClient && rtcClient.accountUid) || null,
            reason,
            currentState
        )
    );
};
export const tableLogConstants = {
    ATTEMPTING_TO_JOIN_CHANNEL: 'ATTEMPTING_TO_JOIN_CHANNEL',
    CHANNEL_JOINED_SUCCESSFULLY: 'CHANNEL_JOINED_SUCCESSFULLY',
    FAILED_TO_JOIN_CHANNEL: 'FAILED_TO_JOIN_CHANNEL',
    FAILED_TO_JOIN_CHANNEL_AFTER_RETRIES:
        'FAILED_TO_JOIN_CHANNEL_AFTER_RETRIES',

    ATTEMPTING_TO_PUBLISH: 'ATTEMPTING_TO_PUBLISH',
    PUBLISHED_STREAM_SUCCESSFULLY: 'PUBLISHED_STREAM_SUCCESSFULLY',
    FAILED_TO_PUBLISH_STREAM: 'FAILED_TO_PUBLISH_STREAM',

    ATTEMPTING_TO_SUBSCRIBE_TO_REMOTE_STREAM:
        'ATTEMPTING_TO_SUBSCRIBE_TO_REMOTE_STREAM',
    SUBSCRIBED_TO_REMOTE_STREAM_SUCCESSFULLY:
        'SUBSCRIBED_TO_REMOTE_STREAM_SUCCESSFULLY',
    FAILED_TO_SUBSCRIBE_TO_REMOTE_STREAM:
        'FAILED_TO_SUBSCRIBE_TO_REMOTE_STREAM',
};
export const broadcastLogConstants = {
    BROADCAST_ATTEMPTING_TO_JOIN_CHANNEL:
        'BROADCAST_ATTEMPTING_TO_JOIN_CHANNEL',
    BROADCAST_CHANNEL_JOINED_SUCCESSFULLY:
        'BROADCAST_CHANNEL_JOINED_SUCCESSFULLY',
    BROADCAST_FAILED_TO_JOIN_CHANNEL: 'BROADCAST_FAILED_TO_JOIN_CHANNEL',
    BROADCAST_FAILED_TO_JOIN_CHANNEL_AFTER_RETRIES:
        'BROADCAST_FAILED_TO_JOIN_CHANNEL_AFTER_RETRIES',
    BROADCAST_ATTEMPTING_TO_UNSUBSCRIBE: 'BROADCAST_ATTEMPTING_TO_UNSUBSCRIBE',
    BROADCAST_SET_ENABLE_SUBSCRIBING: 'BROADCAST_SET_ENABLE_SUBSCRIBING',
    BROADCAST_ATTEMPTING_TO_PUBLISH: 'BROADCAST_ATTEMPTING_TO_PUBLISH',
    BROADCAST_ATTEMPTING_TO_UNPUBLISH: 'BROADCAST_ATTEMPTING_TO_UNPUBLISH',
    BROADCAST_PUBLISHED_STREAM_SUCCESSFULLY:
        'BROADCAST_PUBLISHED_STREAM_SUCCESSFULLY',
    BROADCAST_FAILED_TO_PUBLISH_STREAM: 'BROADCAST_FAILED_TO_PUBLISH_STREAM',
    BROADCAST_FAILED_TO_GET_VIDEO_STREAM:
        'BROADCAST_FAILED_TO_GET_VIDEO_STREAM',
    BROADCAST_FAILED_TO_GET_TRACK_IN_STREAM:
        'BROADCAST_FAILED_TO_GET_TRACK_IN_STREAM',

    BROADCAST_ATTEMPTING_TO_SUBSCRIBE_TO_REMOTE_STREAM:
        'BROADCAST_ATTEMPTING_TO_SUBSCRIBE_TO_REMOTE_STREAM',
    BROADCAST_SUBSCRIBED_TO_REMOTE_STREAM_SUCCESSFULLY:
        'BROADCAST_SUBSCRIBED_TO_REMOTE_STREAM_SUCCESSFULLY',
    BROADCAST_FAILED_TO_SUBSCRIBE_TO_REMOTE_STREAM:
        'BROADCAST_FAILED_TO_SUBSCRIBE_TO_REMOTE_STREAM',
    BROADCAST_AV_CUSTOM_FIX: 'BROADCAST_AV_CUSTOM_FIX',
    BROADCAST_MEDIA_STREAM_CONFIG: 'BROADCAST_MEDIA_STREAM_CONFIG',
    BROADCAST_JOINING_CHANNEL_TOKEN_NOT_FOUND:
        'BROADCAST_JOINING_CHANNEL_TOKEN_NOT_FOUND',
};

export const firewallLogConstants = {
    FIREWALL_ATTEMPTING_TO_JOIN_CHANNEL_REGULAR_MANNER:
        'FIREWALL_ATTEMPTING_TO_JOIN_CHANNEL_REGULAR_MANNER',
    FIREWALL_CHANNEL_JOINED_SUCCESSFULLY_REGULAR_MANNER:
        'FIREWALL_CHANNEL_JOINED_SUCCESSFULLY_REGULAR_MANNER',
    FIREWALL_FAILED_TO_JOIN_CHANNEL_REGULAR_MANNER:
        'FIREWALL_FAILED_TO_JOIN_CHANNEL_REGULAR_MANNER',

    FIREWALL_ATTEMPTING_TO_JOIN_CHANNEL_WITH_AGORA_PROXY:
        'FIREWALL_ATTEMPTING_TO_JOIN_CHANNEL_WITH_AGORA_PROXY',
    FIREWALL_CHANNEL_JOINED_SUCCESSFULLY_WITH_AGORA_PROXY:
        'FIREWALL_CHANNEL_JOINED_SUCCESSFULLY_WITH_AGORA_PROXY',
    FIREWALL_FAILED_TO_JOIN_CHANNEL_WITH_AGORA_PROXY:
        'FIREWALL_FAILED_TO_JOIN_CHANNEL_WITH_AGORA_PROXY',

    FIREWALL_ATTEMPTING_TO_JOIN_CHANNEL_WITH_AIRMEET_PROXY:
        'FIREWALL_ATTEMPTING_TO_JOIN_CHANNEL_WITH_AIRMEET_PROXY',
    FIREWALL_CHANNEL_JOINED_SUCCESSFULLY_WITH_AIRMEET_PROXY:
        'FIREWALL_CHANNEL_JOINED_SUCCESSFULLY_WITH_AIRMEET_PROXY',
    FIREWALL_FAILED_TO_JOIN_CHANNEL_WITH_AIRMEET_PROXY:
        'FIREWALL_FAILED_TO_JOIN_CHANNEL_WITH_AIRMEET_PROXY',

    FIREWALL_ATTEMPTING_TO_JOIN_CHANNEL_WITH_AIRMEET_PROXY_AND_TURN_SERVER:
        'FIREWALL_ATTEMPTING_TO_JOIN_CHANNEL_WITH_AIRMEET_PROXY_AND_TURN_SERVER',
    FIREWALL_CHANNEL_JOINED_SUCCESSFULLY_WITH_AIRMEET_PROXY_AND_TURN_SERVER:
        'FIREWALL_CHANNEL_JOINED_SUCCESSFULLY_WITH_AIRMEET_PROXY_AND_TURN_SERVER',
    FIREWALL_FAILED_TO_JOIN_CHANNEL_WITH_AIRMEET_PROXY_AND_TURN_SERVER:
        'FIREWALL_FAILED_TO_JOIN_CHANNEL_WITH_AIRMEET_PROXY_AND_TURN_SERVER',

    FIREWALL_FAILED_TO_JOIN_CHANNEL_AFTER_RETRIES:
        'FIREWALL_FAILED_TO_JOIN_CHANNEL_AFTER_RETRIES',
};

export const userDropCases = {
    REFRESHED_WHILE_RECONNECTING: {
        label: 'REFRESHED_WHILE_RECONNECTING',
        expectedState: 'DISCONNECTED',
    },
    TABLE_LEFT_WHILE_RECONNECTING: {
        label: 'TABLE_LEFT_WHILE_RECONNECTING',
        expectedState: 'DISCONNECTED',
    },
    STAGE_LEFT_WHILE_RECONNECTING: {
        label: 'STAGE_LEFT_WHILE_RECONNECTING',
        expectedState: 'DISCONNECTED',
    },
    AGORA_GAVE_UP_ON_RECOVERABILITY: {
        label: 'AGORA_GAVE_UP_ON_RECOVERABILITY',
        expectedState: 'DISCONNECTED',
    },
    FIREBASE_TOKEN_EXPIRED: {
        label: 'FIREBASE_TOKEN_EXPIRED',
        expectedState: 'CONNECTED',
    },
    AGORA_TOKEN_EXPIRED: {
        label: 'AGORA_TOKEN_EXPIRED',
        expectedState: 'CONNECTED',
    },
    AGORA_EXCEPTION: {
        label: 'AGORA_EXCEPTION',
        expectedState: 'CONNECTED',
    },
    BOUNDRY_ERROR: {
        label: 'BOUNDRY_ERROR',
        expectedState: 'CONNECTED',
    },
    GENUINE_LEAVE: {
        label: 'GENUINE_LEAVE',
        expectedState: 'DISCONNECTED',
    },
    GENUINE_LEAVE_TABLE: {
        label: 'GENUINE_LEAVE_TABLE',
        expectedState: 'DISCONNECTED',
    },
    GENUINE_LEAVE_STAGE: {
        label: 'GENUINE_LEAVE_STAGE',
        expectedState: 'DISCONNECTED',
    },
};
export const clientInUse = (userDropCase = '') => {
    let clients = {
        bordCastRTCClient: {
            key: 'bordCastRTCClient',
            label: 'BROADCAST_RTC_CLIENT',
        },
        bordCastScreenShareRTCClient: {
            key: 'bordCastScreenShareRTCClient',
            label: 'BROADCAST_SCREEN_RTC_CLIENT',
        },
        broadCastCustomMediaRTCClient: {
            key: 'broadCastCustomMediaRTCClient',
            label: 'BROADCAST_CUSTOM_MEDIA_RTC_CLIENT',
        },
        lobbyClient: {
            key: 'lobbyClient',
            label: 'LOBBY_CLIENT',
        },
        videoRTCClient: {
            key: 'videoRTCClient',
            label: 'VIDEO_RTC_CLIENT',
        },
        screenRTCClient: {
            key: 'screenRTCClient',
            label: 'SCREEN_RTC_CLIENT',
        },
    };
    switch (userDropCase) {
        case userDropCases.TABLE_LEFT_WHILE_RECONNECTING.label:
        case userDropCases.GENUINE_LEAVE_TABLE.label:
            return pickBy(clients, (_, key) => !startsWith(key, 'b'));
        case userDropCases.STAGE_LEFT_WHILE_RECONNECTING.label:
        case userDropCases.GENUINE_LEAVE_STAGE.label:
        case LIVE_AIRMEET_STATE.backStage:
        case LIVE_AIRMEET_STATE.goLive:
            return pickBy(clients, (_, key) => startsWith(key, 'b'));
        case LIVE_AIRMEET_STATE.socialLounge:
            return pickBy(
                clients,
                (_, key) =>
                    startsWith(key, 'video') || startsWith(key, 'screen')
            );
        case '':
            return clients;
        default:
            return {};
    }
};
export const generateUserDropString = (
    clientInUse,
    userDropCase,
    userId,
    reason,
    currentState
) =>
    `USER_DROP, SITUATION:${userDropCase} ${
        clientInUse ? `,in client: ${clientInUse}` : ''
    } ${userId ? `, with userid : ${userId}` : ''} ${
        currentState ? `, user on ${currentState}` : ''
    } ${reason ? `, with: reason ${reason}` : ''}  `;

export const QOEUserDropLogger = (userDropCase, reason = null) => {
    const airmeet = getAirmeetUtilInstance();
    for (let client in clientInUse(userDropCase)) {
        let currentClient = airmeet[client];
        if (
            currentClient &&
            currentClient.client &&
            currentClient.client.getConnectionState() ==
                userDropCases[userDropCase].expectedState
        ) {
            QOELog(
                generateUserDropString(
                    clientInUse(userDropCase)[client].label,
                    userDropCase,
                    currentClient.accountUid,
                    reason
                )
            );
        }
    }
};
