import { FEATURE_NAMES } from 'utils/constants/featureNames';
import useLiveAirmeetContext from './useLiveAirmeetContext';

export default function useFeatureFirebaseClient(
    featureName?: string,
    options?
) {
    const {
        firebaseClient,
        featureDataClients: {
            reactions: reactionsFirebaseClient,
            chat: chatFirebaseClient,
            stage: stageFirebaseClient,
            tables: tableFirebaseClient,
            fluidSpace: fluidSpaceClient,
            [FEATURE_NAMES.USER_PROFILE]: userProfileClient,
            [FEATURE_NAMES.USER_PRESENCE]: userPresenceClient,
            [FEATURE_NAMES.BOOTH_TABLES]: boothTablesClient,
            [FEATURE_NAMES.BREAKOUT_TABLES]: breakoutTablesClient,
        },
    } = useLiveAirmeetContext() || options?.liveContextOverride;

    let client;

    switch (featureName) {
        case FEATURE_NAMES.STAGE_REACTIONS:
            client = reactionsFirebaseClient;
            break;
        case FEATURE_NAMES.EVENT_CHAT:
        case FEATURE_NAMES.SESSION_CHAT:
        case FEATURE_NAMES.SESSION_CHAT_MODERATION:
        case FEATURE_NAMES.CHAT_REACTION:
        case FEATURE_NAMES.DM_CHAT:
        case FEATURE_NAMES.MODERATION_TOGGLE_LEVEL: {
            client = chatFirebaseClient;
            break;
        }

        case FEATURE_NAMES.LOUNGE_TABLES:
        case FEATURE_NAMES.TABLE_INVITE:
            client = tableFirebaseClient;
            break;

        case FEATURE_NAMES.STAGE_CHAT:
        case FEATURE_NAMES.STAGE_RAISE_HAND:
        case FEATURE_NAMES.STAGE_INVITE:
        case FEATURE_NAMES.USER_STAGE_VISIBILITY:
        case FEATURE_NAMES.STREAM_PUBLISHER:
            client = stageFirebaseClient;
            break;

        case FEATURE_NAMES.FLUID_SPACE:
        case FEATURE_NAMES.USE_MEGAPHONE:
        case FEATURE_NAMES.FLUID_SPACE_REACTION: {
            client = fluidSpaceClient;
            break;
        }

        case FEATURE_NAMES.USER_PROFILE:
            client = userProfileClient;
            break;

        case FEATURE_NAMES.USER_PRESENCE:
            client = userPresenceClient;
            break;

        case FEATURE_NAMES.BOOTH_TABLES:
            client = boothTablesClient;
            break;

        case FEATURE_NAMES.BREAKOUT_TABLES:
            client = breakoutTablesClient;
            break;

        case FEATURE_NAMES.SOCIAL_LOUNGE:
            client = tableFirebaseClient;
            break;

        default:
            client = firebaseClient;
            break;
    }

    return {
        client,
    };
}
