import Icon from 'foundations/icon';
import React from 'react';
import styled from 'styled-components';
import { color } from 'styled-system';
import { select } from 'utils/constants/common';

const RadioButton = (props) => {
    const {
        id,
        changed,
        value,
        isSelected,
        label,
        additionalInfo = false,
        additionalText = '',
        additionalTextValue = '',
        icon = '',
        isDisable = false,
        children = null,
        radioLabel = true,
        name = '',
        activeOnSelect,
        buttonAlignment = '',
        readOnly = false,
        alignment = 'center',
        iconBeforeLabel = false,
        filledRadioColor,
        parentStyles,
        className = '',
        labelStyle = {},
        hideRadio = false,
        labelColor = 'ambience.4',
        radioIconColor,
        radioIconPosition,
    } = props || {};
    return (
        <Radio
            isSelected={isSelected}
            activeOnSelect={activeOnSelect}
            buttonAlignment={buttonAlignment}
            alignment={alignment}
            iconBeforeLabel={iconBeforeLabel}
            filledRadioColor={filledRadioColor}
            radioStyles={parentStyles}
            className={className}
            hideRadio={hideRadio}
            radioIconColor={radioIconColor}
            radioIconPosition={radioIconPosition}
        >
            <LeftCol className='LeftCol'>
                <input
                    id={id}
                    onChange={changed}
                    value={value}
                    type='radio'
                    checked={isSelected}
                    disabled={isDisable}
                    name={name}
                    readOnly={readOnly}
                />
                <label
                    className={isDisable ? 'disableStyle' : undefined}
                    htmlFor={id}
                >
                    {!radioLabel && label}
                    {radioLabel && (
                        <RadioLabel style={labelStyle || {}} color={labelColor}>
                            {label}
                        </RadioLabel>
                    )}
                    {children}
                    {additionalInfo && additionalText && (
                        <SubText>{additionalText}</SubText>
                    )}

                    {icon && (
                        <IconWrapper iconBeforeLabel={iconBeforeLabel}>
                            <Icon
                                icon={icon}
                                width={24}
                                height={24}
                                fill={'ambience.12'}
                            />
                        </IconWrapper>
                    )}
                </label>
                {additionalInfo && additionalTextValue && (
                    <div>{additionalTextValue}</div>
                )}
            </LeftCol>
        </Radio>
    );
};

const RadioLabel = styled.span`
    ${color}
    font-size: 16px;
    line-height: 20px;
    font-family: ${({ theme }) => theme.fonts.medium};
    margin-bottom: 8px;
    display: block;
    max-width: 300px;
`;
const SubText = styled.span`
    font-size: 12px;
    line-height: 16px;
    font-family: ${({ theme }) => theme.fonts.book};
    color: ${({ theme }) => theme.colors.ambience[12]};
    display: block;
    max-width: 300px;
`;

const IconWrapper = styled.span`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 1;
    ${({ iconBeforeLabel }) =>
        iconBeforeLabel &&
        `
        right: auto;
        left: 48px;
    `}
`;

const Radio = styled.div`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    ${({ isSelected, activeOnSelect, theme, filledRadioColor }) =>
        isSelected &&
        activeOnSelect &&
        ` 
            border: 1px solid  ${
                filledRadioColor
                    ? theme.colors.accentPrimary[1]
                    : theme.colors.accentPrimary[0]
            };

    `}

    ${({ props }) => `
        display: ${props?.buttonAlignment === 'bottom' ? 'block' : 'flex'};  
    `}
    input[type='radio'] {
        display: none;
        &:checked + disbaled + label:before {
            border-color: ${({ theme, filledRadioColor, radioIconColor }) =>
                radioIconColor
                    ? select(radioIconColor)(theme.colors)
                    : filledRadioColor
                    ? theme.colors.accentPrimary[1]
                    : theme.colors.accentPrimary[0]};
        }
        &:checked + disbaled + label svg {
            fill: ${({ theme, filledRadioColor, radioIconColor }) =>
                radioIconColor
                    ? select(radioIconColor)(theme.colors)
                    : filledRadioColor
                    ? theme.colors.accentPrimary[1]
                    : theme.colors.accentPrimary[0]};
        }
        &:checked + disbaled + label:after {
            transform: translateY(-50%);
            display: block;
        }
        &:checked + label:before {
            border-color: ${({ theme, filledRadioColor, radioIconColor }) =>
                radioIconColor
                    ? select(radioIconColor)(theme.colors)
                    : filledRadioColor
                    ? theme.colors.accentPrimary[1]
                    : theme.colors.accentPrimary[0]};
        }
        &:checked + label svg {
            fill: ${({ theme, filledRadioColor, radioIconColor }) =>
                radioIconColor
                    ? select(radioIconColor)(theme.colors)
                    : filledRadioColor
                    ? theme.colors.accentPrimary[1]
                    : theme.colors.accentPrimary[0]};
        }
        &:checked + label:after {
            transform: translateY(-50%);
            display: block;
        }
    }
    > p {
        font-size: ${({ theme }) => theme.fontSizes[1]};
        color: #a8a5b6;
        padding-left: 52px;
        margin-top: 8px;
        &.disable {
            opacity: 0.4;
        }
    }

    label {
        position: relative;
        font-size: ${({ theme }) => theme.fontSizes[3]};
        font-family: ${({ theme }) => theme.fonts.medium};
        line-height: ${({ theme }) => theme.lineHeights[3]};
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
        padding: 0 48px;
        ${({ hideRadio, radioIconPosition }) =>
            !hideRadio &&
            `
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: ${
                    radioIconPosition && radioIconPosition.top
                        ? radioIconPosition.top
                        : '50%'
                };
                transform: translateY(-50%);
                border-radius: 50%;
                transition: all 0.3s ease;
                transition-property: transform, border-color;
            }
        `}
        
        &:before {
            left: 0;
            width: 20px;
            height: 20px;
            border: 2px solid ${({ theme }) => theme.colors.ambience[6]};
        }
        &:after {
            left: 5px;
            width: 10px;
            height: 10px;
            background: ${({ theme, filledRadioColor, radioIconColor }) =>
                radioIconColor
                    ? select(radioIconColor)(theme.colors)
                    : filledRadioColor
                    ? theme.colors.accentPrimary[1]
                    : theme.colors.accentPrimary[0]};
            display: none;
        }
        &.disableStyle {
            color: ${({ theme }) => theme.colors.ambience[0]} !important;
            opacity: 0.2;
            pointer-events: none;
        }
    }
    ${({ alignment }) =>
        alignment === 'top' &&
        `
        label {
            &:before {
                top: 2px;
                transform: translateY(0);
            }
            &:after {
                top: 12px;
                transform: translateY(0);
            }
        }
        ${IconWrapper}{
            top: 0;
            transform: translateY(0);
        }
    `}
    ${({ iconBeforeLabel }) =>
        iconBeforeLabel &&
        `
        label {
            padding-left: 94px;
            padding-right: 0;
        }
    `}
    ${({ radioStyles }) => radioStyles && radioStyles}
`;

const LeftCol = styled.div`
    flex: 1;
    max-width: 585px;
`;
export default RadioButton;
