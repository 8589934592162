import isNotInvitedSpeaker from 'utils/isNotInvitedSpeaker';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { useMemo } from 'react';

const useIsNonInvitedSpeaker = () => {
    const {
        airmeet: {
            data: {
                currentAirmeet: { speakers, unmappedSpeakers },
            },
        },
        user: { id: userId },
    } = useLiveAirmeetContext();
    return useMemo(
        () => isNotInvitedSpeaker(speakers, unmappedSpeakers, userId),
        [speakers, unmappedSpeakers, userId]
    ) as boolean;
};

export default useIsNonInvitedSpeaker;
