import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FirebaseRTMClient from '../../containers/FirebaseRTMClient';

function useRTMClient({ airmeetId, firebaseClient }) {
    const [client, setClient] = useState(null);
    const authUser = useSelector((state) => state.auth.user);

    useEffect(() => {
        if (firebaseClient && authUser.id && airmeetId) {
            const instance = new FirebaseRTMClient(
                firebaseClient,
                authUser.id,
                airmeetId
            );
            instance.init().then(() => {
                setClient(instance);
            });
        }
    }, [airmeetId, firebaseClient, authUser.id]);

    return client;
}

export default useRTMClient;
