import SVGIcon from 'foundations/icon';
import React from 'react';
import styled from 'styled-components';
import {
    background,
    border,
    color,
    flexbox,
    grid,
    layout,
    position,
    shadow,
    space,
    typography,
} from 'styled-system';
import { noop } from 'utils/constants/common';

const Btn = React.forwardRef((props, ref) => {
    const {
        onClick = noop,
        variant = 'primary',
        size = 'large',
        iconOnly,
        icon,
        iconSize = '15px',
        children,
        loading = false,
        isResponsive = false,
        isFullWidth,
        hasShine,
        hasGlow,
        ...rest
    } = props || {};

    const commonProps = {
        ...rest,
        ref,
        variant,
        size,
        iconOnly,
        icon,
        iconSize,
        isResponsive,
        isFullWidth,
        hasShine,
        hasGlow,
    };
    let Button = null;

    switch (variant) {
        case 'primary':
            Button = PrimaryButton;
            break;
        case 'secondary':
            Button = SecondaryButton;
            break;
        case 'danger':
            Button = DangerButton;
            break;
        case 'link':
            Button = LinkButton;
            break;
        case 'selectable':
            Button = SelectableButton;
            break;
        default:
            break;
    }

    if (Button)
        return (
            <Button
                {...commonProps}
                onClick={!loading ? onClick : undefined}
                loading={loading}
            >
                {loading ? (
                    <BtnLoader aria-label='Loading'>
                        <span className='btn-loader-wrapper' aria-hidden='true'>
                            <span className='btn-loader' aria-hidden='true' />
                        </span>
                        <span className='btn-loader-content' aria-hidden='true'>
                            {iconOnly ? (
                                <SVGIcon
                                    icon={icon}
                                    width={iconSize}
                                    height={iconSize}
                                />
                            ) : (
                                children
                            )}
                        </span>
                    </BtnLoader>
                ) : (
                    <>
                        {iconOnly ? (
                            <SVGIcon
                                icon={icon}
                                width={iconSize}
                                height={iconSize}
                            />
                        ) : (
                            children
                        )}
                    </>
                )}
            </Button>
        );

    return null;
});

const StyledButton = styled.button`
    ${layout}
    ${space}
    ${flexbox}
    ${color}
    ${position}
    ${border}
    ${background}  
    ${typography}
    ${grid}
    ${shadow}

    border-radius: ${({ theme }) => theme.radii.default};
    border: 0px;
    background-color: transparent;
    padding: ${({ theme }) => theme.space.x2} ${({ theme }) => theme.space.x4};
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    min-height: ${({ theme }) => theme.space.x8};
    width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'auto')};

    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: ${({ theme }) => theme.fontSizes.x2};
    line-height: ${({ theme }) => theme.lineHeights.x2};

    ${space}

    ${({ loading }) =>
        loading &&
        `
        pointer-events: none;
        cursor: default;
        position: relative;
        min-width: 60px; // Avoid loader overflow
    `}

    ${({ iconOnly, theme }) =>
        iconOnly &&
        `
        width: 40px;
        border-radius: 50%;
        padding: ${theme.space.x2};
        justify-content: center;
    `}

    &:hover {
        opacity: 0.6;
    }

    &:focus {
        outline: 2px solid ${({ theme }) => theme.colors.border.other1};
        box-shadow: inset 0 0 0 2px ${({ theme }) =>
            theme.colors.fill.default1};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.colors.grey.fill2};
        color: ${({ theme }) => theme.colors.text.default.disabled};
        p {
            color: ${({ theme }) => theme.colors.text.default.disabled};
        }
        svg {
            fill: ${({ theme }) => theme.colors.text.default.disabled};
        }
        box-shadow: inset 0 0 0 1px
            ${({ theme }) => theme.colors.border.default1};
        cursor: not-allowed;
        pointer-events: none;
        &:hover {
            opacity: 1;
        }
    }

    ${({ size, theme }) =>
        size === 'small' &&
        `
        min-height: 28px;
        padding: ${theme.space.x1} ${theme.space.x3};
        
        font-size: ${theme.fontSizes.x1};
        line-height: ${theme.lineHeights.x1};
        `}
    ${({ size, iconOnly, theme }) =>
        size === 'small' &&
        iconOnly &&
        `
            width: 28px;
            padding: ${theme.space.x1};
        `}

    ${({ hasShine, theme }) =>
        hasShine &&
        `
        position: relative;
        overflow: hidden;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            width: 20px;
            height: 160px;
            transform: rotate(20deg);
            background: rgba(255, 255, 255, 0.60);
            filter: blur(12px);
            animation: shine 8s ease-in-out infinite;
        }
    `}

    @keyframes shine {
        0% {
            left: -100%;
            transition-property: left;
        }
        10%, 100% {
            left: 100%;
            transition-property: left;
        }
    }

    @media (max-width: 767px) {
        ${({ theme, isResponsive, iconOnly }) =>
            isResponsive
                ? `
                    min-height: 28px;
                    padding: ${theme.space.x1} ${theme.space.x4};
                    
                    font-size: ${theme.fontSizes.x1};
                    line-height: ${theme.lineHeights.x1};

                    ${
                        iconOnly
                            ? `
                        width: 28px;
                        padding: ${theme.space.x1};
                    `
                            : ''
                    }
        `
                : ''}
    }
`;
const PrimaryButton = styled(StyledButton)`
    background-color: ${({ theme }) => theme.colors.brandDefault};
    color: ${({ theme }) =>
        theme?.isDashboardBrandingPage
            ? theme.colors.text.other.primary
            : //TODO: Remove white fallback when theme v2 is complete
              theme?.primaryButtonTextColor ||
              theme.colors.white ||
              theme.colors.text.other.primary};
    svg {
        fill: ${({ theme }) =>
            theme?.isDashboardBrandingPage
                ? theme.colors.text.other.primary
                : //TODO: Remove white fallback when theme v2 is complete
                  theme?.primaryButtonTextColor ||
                  theme.colors.white ||
                  theme.colors.text.other.primary};
    }
    ${({ hasGlow, theme }) =>
        hasGlow &&
        `
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.10), 0px 0px 10px 1px ${theme.colors.brandDefault};
    `}
`;

const SecondaryButton = styled(StyledButton)`
    background-color: ${({ theme }) => theme.colors.grey.fill2};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.border.default1};
    color: ${({ theme }) => theme.colors.text.default.primary};
    svg {
        fill: ${({ theme }) => theme.colors.text.default.primary};
    }
    &:focus {
        box-shadow: inset 0 0 0 1px
            ${({ theme }) => theme.colors.border.default1};
    }
`;

const SelectableButton = styled(SecondaryButton)`
    background-color: ${({ theme }) => theme.colors.grey.fill2};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.border.default1};
    color: ${({ theme }) => theme.colors.text.default.primary};
    border: 2px solid transparent;
    svg {
        fill: ${({ theme }) => theme.colors.text.default.primary};
    }
    &:hover {
        opacity: 1;
        border: 2px solid ${({ theme }) => theme.colors.text.default.primary};
    }
    &:focus {
        border: 0px;
        outline: 2px solid ${({ theme }) => theme.colors.border.other1};
        box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.fill.default1};
    }

    ${({ isChecked, theme }) =>
        isChecked
            ? `
                border: 0px;
                background-color: ${theme.colors.brandDefault};
                color: ${
                    //TODO: Remove white fallback when theme v2 is complete
                    theme?.primaryButtonTextColor ||
                    theme.colors.white ||
                    theme.colors.text.other.primary
                };
                &:hover {
                    border: 0px;
                }
                svg {
                    fill: ${
                        theme?.primaryButtonTextColor ||
                        theme.colors.white ||
                        theme.colors.text.other.primary
                    };
                }
            `
            : ''}
`;

const DangerButton = styled(StyledButton)`
    background-color: ${({ theme }) => theme.colors.sem.error};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.border.default1};
    color: ${({ theme }) => theme.colors.text.other.primary};
    svg {
        fill: ${({ theme }) => theme.colors.text.other.primary};
    }
`;

const LinkButton = styled(StyledButton)`
    color: ${({ theme }) =>
        theme?.isDashboardBrandingPage
            ? theme.colors.brandDefault
            : theme.colors.text.default.primary};
    min-height: auto;
    svg {
        fill: ${({ theme }) =>
            theme?.isDashboardBrandingPage
                ? theme.colors.brandDefault
                : theme.colors.text.default.primary};
    }
    padding: 0;
    text-decoration: underline;
    border-radius: 0;

    &:hover {
        opacity: 1;
        background-color: ${({ theme }) => theme.colors.fill.default2};
    }

    &:focus {
        outline: none;
        box-shadow: none;
        background-color: ${({ theme }) => theme.colors.fill.other3};
        color: ${({ theme }) => theme.colors.text.default.primary};
        svg {
            fill: ${({ theme }) => theme.colors.text.default.primary};
        }
    }

    &:focus-visible {
        outline: 1px solid ${({ theme }) => theme.colors.text.default.primary};
    }

    &:disabled {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.text.default.disabled};
        svg {
            fill: ${({ theme }) => theme.colors.text.default.disabled};
        }
        box-shadow: none;
        cursor: not-allowed;
        pointer-events: none;
        &:hover {
            opacity: 1;
        }
    }
`;

const BtnLoader = styled.span`
    .btn-loader-wrapper {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
    .btn-loader-content {
        visibility: hidden;
    }

    .btn-loader,
    .btn-loader:before,
    .btn-loader:after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        animation-fill-mode: both;
        animation: bblFadInOut 1.8s infinite ease-in-out;
    }
    .btn-loader {
        color: inherit;
        font-size: 4px;
        position: relative;
        text-indent: -9999em;
        transform: translateZ(0);
        animation-delay: -0.16s;
        display: inline-block;
        margin-bottom: 2.7em;
    }
    .btn-loader:before,
    .btn-loader:after {
        content: '';
        position: absolute;
        top: 0;
    }
    .btn-loader:before {
        left: -3.5em;
        animation-delay: -0.32s;
    }
    .btn-loader:after {
        left: 3.5em;
    }

    @keyframes bblFadInOut {
        0%,
        80%,
        100% {
            box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
            box-shadow: 0 2.5em 0 0;
        }
    }
`;

export default Btn;
