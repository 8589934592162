import size from 'lodash/size';
import { cdnImage } from 'utils/core';
import { isEventOrganizer } from 'utils/userAccessControl';

export const BREAKOUT_ACTIONS = {
    CREATE_BREAKOUT: 'CREATE_BREAKOUT',
    UPDATE_BREAKOUT: 'UPDATE_BREAKOUT',
    CREATE_REQUEST: 'CREATE_REQUEST',
    DELETE_REQUEST: 'DELETE_REQUEST',
    UPDATE_BREAKOUT_STATUS: 'UPDATE_BREAKOUT_STATUS',
    ADD_SESSION_TIMER: 'ADD_SESSION_TIMER',
    UPDATE_BREAKOUT_ROOM: 'UPDATE_BREAKOUT_ROOM',
    ADD_BREAKOUT_ROOMS: 'ADD_BREAKOUT_ROOMS',
    DELETE_BREAKOUT: 'DELETE_BREAKOUT',
    ADD_ANNOUNCEMENT: 'ADD_ANNOUNCEMENT',
    UPDATE_ROOM_JOIN_REQUEST: 'UPDATE_ROOM_JOIN_REQUEST',
    CLEAR_BREAKOUT_PENDING_REQUESTS: 'CLEAR_BREAKOUT_PENDING_REQUESTS',
};

export const BREAKOUT_STATUS = {
    DRAFT: 'DRAFT',
    CREATED: 'CREATED',
    ONGOING: 'ONGOING',
    FINISHED: 'FINISHED',
};

export const BREAKOUT_JOINING_MODES = {
    RESTRICTED: 'RESTRICTED',
    DISCOVERABLE: 'DISCOVERABLE',
};

export const BREAKOUT_TYPES = {
    RANDOM: 'RANDOM',
    UPLOAD_LIST: 'UPLOAD_LIST',
};

export const BREAKOUT_CARD_TEXT = {
    [BREAKOUT_TYPES.RANDOM]: {
        heading: 'Random Assignment',
        description:
            'Participants will be divided into specified size and the respective rooms are assigned.',
    },
    [BREAKOUT_TYPES.UPLOAD_LIST]: {
        heading: 'Upload List',
        description:
            'Upload the CSV file containing list of participants and room names.',
    },
};

export const BREAKOUT_ROOM_ACTION = {
    ADD_USER: 'ADD',
    REMOVE_USER: 'REMOVE',
};

export const OPEN_EDIT_BREAKOUT_ROOM = 'OPEN_EDIT_BREAKOUT_ROOM';
export const CLOSE_EDIT_BREAKOUT_ROOM = 'CLOSE_EDIT_BREAKOUT_ROOM';

export const OPEN_LIST_UPLOAD_OVERLAY = 'OPEN_LIST_UPLOAD_OVERLAY';
export const CLOSE_LIST_UPLOAD_OVERLAY = 'CLOSE_LIST_UPLOAD_OVERLAY';
export const CANCEL_UPLOAD_ERROR = 'CANCEL_UPLOAD';

export const getHostSpeakerBreakoutRoomNumber = () => '0';

export const getHostSpeakerBreakoutRoomId = (breakoutId) =>
    breakoutId ? `${breakoutId}-${getHostSpeakerBreakoutRoomNumber()}` : null;

export const getBreakoutRoomsData = ({
    users,
    userLimit,
    authUserId,
    breakoutId,
    sessionHostAndSpeakerIds = [],
}) => {
    if (!userLimit || !users.length) {
        return {};
    }
    let tableNumber = 1;
    const hostSpeakerTableId = getHostSpeakerBreakoutRoomId(breakoutId);
    const hostAndSpeakers = sessionHostAndSpeakerIds.reduce(
        (hostAndSpeakersData, userId) => {
            hostAndSpeakersData.tableMapData[userId] = { id: userId };
            hostAndSpeakersData.userMapData[userId] = {
                allocatedTables: {
                    [hostSpeakerTableId]: {
                        addedBy: authUserId,
                    },
                },
            };
            return hostAndSpeakersData;
        },
        { tableMapData: {}, userMapData: {} }
    );
    return users.reduce(
        (acc, user) => {
            if (!Boolean(acc.tables[hostSpeakerTableId])) {
                acc.tables[hostSpeakerTableId] = {
                    tableNumber: 0,
                    info: { title: 'Hosts & Speakers' },
                    isRestricted: true,
                    users: hostAndSpeakers.tableMapData,
                };
            }
            if (
                sessionHostAndSpeakerIds.includes(user.id) ||
                isEventOrganizer(user.id)
            ) {
                return acc;
            }

            let tableId = `${breakoutId}-${tableNumber}`;
            if (!Boolean(acc.tables[tableId])) {
                acc.tables[tableId] = {
                    tableNumber,
                    info: {
                        title: `Breakout room ${
                            tableNumber <= 9 ? '0' : ''
                        }${tableNumber}`,
                    },
                    users: {},
                };
            }
            acc.tables[tableId].users[user.id] = { id: user.id };
            acc.users[user.id] = {
                allocatedTables: {
                    [tableId]: {
                        addedBy: authUserId,
                    },
                },
            };
            if (size(acc.tables[tableId]?.users) === userLimit) {
                tableNumber += 1;
            }

            return acc;
        },
        { tables: {}, users: hostAndSpeakers.userMapData || {} }
    );
};

export const getBreakoutLoungeNode = (sessionId) => {
    if (!sessionId) throw new Error('sessionId is required');
    return `breakouts/${sessionId}`;
};

export const getBreakoutNodePrefix = (airmeetId, sessionId) => {
    if (!airmeetId) {
        throw new Error('airmeetId is required for breakout node');
    }

    if (!sessionId) {
        throw new Error('sessionId is required for breakout node');
    }

    return `${airmeetId}/${getBreakoutLoungeNode(sessionId)}`;
};

export const getUserAlreadyExistString = (usersId) => {
    return `User(s) already existing - [${usersId}]`;
};

export const DEFAULT_GRID_USERS_COUNT = 3;
export const MAX_GRID_USER_SLOTS = 4;

export const MIN_USER_PER_ROOM = 2;

export const JOIN_BREAKOUT_ROOM = 'JOIN_BREAKOUT_ROOM';

export const OPEN_BREAKOUT_CREATION_OVERLAY = 'OPEN_BREAKOUT_CREATION_OVERLAY';
export const CLOSE_BREAKOUT_CREATION_OVERLAY =
    'CLOSE_BREAKOUT_CREATION_OVERLAY';

export const OPEN_BREAKOUT_LIST_PREVIEW_OVERLAY =
    'OPEN_BREAKOUT_LIST_PREVIEW_OVERLAY';
export const CLOSE_BREAKOUT_LIST_PREVIEW_OVERLAY =
    'CLOSE_BREAKOUT_LIST_PREVIEW_OVERLAY';

export const BREAKOUT_LIST_TEMPLATE_URL =
    'https://airmeet-files.s3.ap-south-1.amazonaws.com/templates/breakout_sample_list.csv';

export const EMPTY_PROFILE_IMAGE = cdnImage('images/empty_profile_img.svg');

export const filtersOverride = {
    searchKeys: ['name'],
};

export const BREAKOUT_ALREADY_CLOSED = 'Breakout already finished';
