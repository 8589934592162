import Btn from 'atoms/Button/btn';
import Select from 'atoms/SelectV2';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import { getAirmeetId } from 'context/LiveAirmeet';
import { alpha } from 'foundations/theme';
import { Text } from 'foundations/themeV2/text/';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import keys from 'locale/keys';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { DEFAULT_FONT_FAMILY, FONTS } from 'utils/constants/fonts';
import firebaseLogger from 'utils/firebaseLogger';
import Tooltip from '../../../../../../../atoms/Tooltip/Tooltip';
import UploadFontsPanel from './UploadFontsPanel';

export default function CustomizeFont({
    value,
    type,
    prop,
    onChange,
    title = null,
    subtitle = null,
}) {
    const { t } = useCustomTranslation();
    const [fontFamily, setFontFamily] = useState(
        value?.fontFamily || DEFAULT_FONT_FAMILY
    );
    const [showUploadPanel, setShowUploadPanel] = useState(false);
    const [fontList, setFontList] = useState(
        uniqBy([...FONTS, ...(value?.fontList || [])], 'id')
    );
    const airmeetId = useLiveAirmeetContext(getAirmeetId);

    const handleFontFamilyChange = (option, src, isCustom = false) => {
        const newFont = {
            id: option?.id,
            label: option?.label,
            src,
            isCustom,
            value: option?.id,
        };
        setFontList(
            uniqBy([...FONTS, ...(value?.fontList || []), newFont], 'id')
        );
        setFontFamily(option?.id);
        const finalValue = {
            ...(value || {}),
            fontFamily: (option?.id || '').includes('NotoSans')
                ? null
                : option?.id,
            src,
            isCustom,
            fontList: uniqBy([...(value?.fontList || []), newFont], 'id'),
            ...(fontList.find((font) => font.id === option?.id) || {}),
        };
        onChange(type, cloneDeep(finalValue), prop);
        firebaseLogger.customFont('fontChanged', {
            font_type: finalValue?.isCustom ? 'custom' : 'default',
            font_name: option?.label,
        });
    };

    const handleFontUpload = () => {
        firebaseLogger.customFont('customFontIconClicked');
        setShowUploadPanel(true);
    };

    const handleDeleteFont = (id) => {
        const newList = uniqBy([...(fontList || [])], 'id').filter(
            (font) => font.id !== id
        );
        setFontList(newList);
        const finalValue = {
            ...(value || {}),
            fontList: newList,
        };
        onChange(type, cloneDeep(finalValue), prop);
    };

    const handleClose = () => {
        setShowUploadPanel(false);
    };

    const scrollToSelected = () => {
        const selectedFont = document.getElementById('selected-font');
        if (selectedFont) {
            selectedFont.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    };

    const selectedFont = useMemo(
        () => fontList.find((font) => font.id === fontFamily),
        [fontList, fontFamily]
    );

    return (
        <FlexDiv flexDirection='column' alignItems='stretch' pt='16px'>
            {title && (
                <Text variant='h5' color='text.default.primary' pb='4px'>
                    {title}
                </Text>
            )}
            {subtitle && (
                <Text
                    variant='caption'
                    color='text.default.secondary'
                    pb='12px'
                >
                    {subtitle}
                </Text>
            )}

            <FlexDiv alignItems='center'>
                <FlexDiv flex='1' flexDirection='column' alignItems='stretch'>
                    <Select
                        options={fontList.map((item) => ({
                            ...item,
                            value: item?.id,
                        }))}
                        value={{
                            ...(selectedFont || {}),
                            value: selectedFont?.id,
                        }}
                        onChange={handleFontFamilyChange}
                        onDelete={handleDeleteFont}
                        menuPlacement='top'
                    />
                </FlexDiv>
                <Text variant='caption' color='text.default.secondary' px='8px'>
                    or
                </Text>
                <Tooltip content={t(keys.CUSTOM_FONTS_UPLOAD_TOOlTIP_TEXT)}>
                    <Btn variant='secondary' onClick={handleFontUpload}>
                        <StyledSvgSprite
                            icon='arrow-upload'
                            width='16px'
                            height='16px'
                            fill='brandDefault'
                            mr='8px'
                        />
                        Upload
                    </Btn>
                </Tooltip>
            </FlexDiv>

            {selectedFont?.isCustom || selectedFont?.showWarning ? (
                <WarningTextWrapper p={1} mt={2}>
                    <WarningIcon icon='warning' fill='text.default.primary' />
                    {selectedFont?.showWarning ? (
                        <Text variant='caption' color='text.default.primary'>
                            {t(keys.CUSTOM_FONTS_WARNING_TEXT_3)}
                        </Text>
                    ) : (
                        <Box>
                            <Text
                                variant='caption'
                                color='text.default.primary'
                            >
                                {t(keys.CUSTOM_FONTS_WARNING_TEXT_1)}
                            </Text>
                            <Text
                                variant='caption'
                                color='text.default.primary'
                                mt={1}
                            >
                                {t(keys.CUSTOM_FONTS_WARNING_TEXT_2)}
                            </Text>
                        </Box>
                    )}
                </WarningTextWrapper>
            ) : null}

            {showUploadPanel ? (
                <UploadFontsPanel
                    fontList={fontList}
                    onClose={handleClose}
                    airmeetId={airmeetId}
                    onChange={handleFontFamilyChange}
                    scrollToSelected={scrollToSelected}
                />
            ) : null}
        </FlexDiv>
    );
}

const WarningTextWrapper = styled(FlexDiv)`
    background: ${({ theme }) => alpha(theme.colors.ambience[20], 0.5)};
    border-radius: 8px;
`;

const WarningIcon = styled(StyledSvgSprite)`
    width: 11px;
    height: 11px;
    min-width: 11px;
    margin: 2px 4px 0 0;
`;
