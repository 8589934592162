import firebase from 'firebase/app';
import { bindModuleLogger } from 'utils/logger';
import {
    Listeners,
    RealtimeActionTypes,
    RealTimeDataSource,
    Unsubscribe,
} from '../types';
import convertChannelToRef from './firestore/RefHelper';

const logger = bindModuleLogger('Firestore DataSource');

class FirestoreDataSource implements RealTimeDataSource {
    private client: firebase.firestore.Firestore;
    constructor(firestoreClient: firebase.firestore.Firestore) {
        if (!firestoreClient) {
            throw new Error('Invalid Firestore Client');
        }
        this.client = firestoreClient;
    }

    subscribe(
        channel: string,
        listeners: Listeners,
        options: any = {}
    ): Unsubscribe {
        logger.debug(
            'Adding Listeners',
            Object.keys(listeners),
            Object.keys(options)
        );

        let activeListeners = new Map();

        if (listeners.onSnapshot) {
            const subscription = (snapshot) => {
                const value = snapshot.data();
                listeners.onSnapshot({
                    payload: value,
                    metadata: null,
                    action: RealtimeActionTypes.SNAPSHOT,
                });
            };

            const ref = convertChannelToRef(channel, this.client);
            if (!ref) {
                throw new Error(`Could not create ref for channel ${channel}`);
            }
            activeListeners.set('value', ref.onSnapshot(subscription));
        }

        return () => {
            activeListeners.forEach((value, key) => {
                logger.info('Removing Subscription', key, channel);
                value();
            });
        };
    }

    fetch(query: object): Promise<any> {
        throw new Error('Not Yet implemented');
    }

    fetchOnce(metaData: any): Promise<any> {
        throw new Error('Not Yet implemented');
    }
    registerOnDisconnect(channel: string, message: any): Promise<boolean> {
        throw new Error('Not Yet implemented');
    }

    cancelOnDisconnect(channel: string): Promise<boolean> {
        throw new Error('Not Yet implemented');
    }
}

export default FirestoreDataSource;
