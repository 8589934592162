const stageReactionWriter = ({
    airmeetId,
    sessionId,
    client,
    payload,
    metaData,
}) => {
    if (!sessionId) {
        throw new Error('Invalid stage reactions firebase key');
    }
    const fireBaseKey = sessionId
        ? `${airmeetId}/liveAirmeet/sessions/${sessionId}/reactions`
        : null;

    if (metaData?.key) {
        return client.setDataAsync(`${fireBaseKey}/${metaData.key}`, payload);
    } else {
        return client.pushData(fireBaseKey, payload);
    }
};

export default stageReactionWriter;
