import { useEffect, useState } from 'react';
import useLiveAirmeetContext from '../useLiveAirmeetContext';

function useRealtimeData({ firebaseClient: inClient, path }) {
    const firebaseClient = useLiveAirmeetContext(
        (s) => s.firebaseClient,
        inClient
    );
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!path || !firebaseClient) {
            return;
        }

        const onValueChanged = ({ value }) => {
            if (value !== null) {
                setData(value);
            }
        };

        // Reset current value
        setData(null);
        // Setup a sync for the value
        firebaseClient.getDataSync(path, onValueChanged);

        return () => {
            if (!path || !firebaseClient) {
                return;
            }

            firebaseClient.clearDataSync(path, onValueChanged);
        };
    }, [firebaseClient, path]);

    return data;
}

export default useRealtimeData;
