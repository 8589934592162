import { createEventBus, slot } from 'ts-event-bus';
import { Unsubscribe } from 'ts-event-bus/build/Slot';
import ChannelBridge from './ChannelBridge';
import {
    AppBridgeEvents,
    ChannelBridgeEvents,
    DebugCallback,
    EventMap,
} from './types';

export default class AppEventsBridge<TAppEvents extends AppBridgeEvents> {
    defaultChannel: TAppEvents = null;
    otherChannels: {
        [channelName: string]: ChannelBridge<ChannelBridgeEvents>;
    } = {};

    defaultSubs: {
        [channelName: string]: {
            unsubscribe: Unsubscribe;
            callback: (event: any) => void;
        }[];
    } = {};
    subs: { [channelName: string]: Unsubscribe[] } = {};

    debug: DebugCallback[] = [];

    constructor(events?: TAppEvents) {
        this.defaultChannel = createEventBus<TAppEvents>({
            events: {
                ...events,
                notify: slot<{ from: string; event: string; data: any }>(),
                notifyTo: slot<{ from: string; event: string; data: any }>(),
                notifyAll: slot<{ from: string; event: string; data: any }>(),
            },
        });

        this.defaultChannel.notify.on((opts) => {
            this.processDebug({ channelName: opts.from || 'all', ...opts });
        });
    }

    setDebug(callback: DebugCallback) {
        this.debug.push(callback);
    }

    resetDebug(callback: DebugCallback) {
        const found = this.debug.indexOf(callback);
        if (found >= 0) {
            this.debug.splice(found, 1);
        }
    }

    processDebug(opts: {
        channelName: string;
        event: string;
        from?: string;
        to?: string;
        data: any[];
    }) {
        this.debug.forEach((c) => c({ ...opts, ts: Date.now() }));
    }

    register(
        channelName: string,
        bridge: ChannelBridge<ChannelBridgeEvents>,
        subs: string[] = []
    ) {
        if (this.otherChannels[channelName]) {
            console.warn(
                `Attempting to register channel with name ${channelName} more than once! Ignoring this call.`
            );
            return;
        }
        this.otherChannels[channelName] = bridge;

        // Hook into the default channel callbacks
        const notifHook = this.defaultChannel.notifyTo.on(
            channelName,
            ({ from, event, data }) => {
                bridge.mainChannel.notify({ from, event, data });
            }
        );

        // Feed events into the default channel
        const feedHook = bridge.mainChannel.notification.on(
            ({ to, event, data }) => {
                if (to === 'all') {
                    this.defaultChannel.notifyAll(event, {
                        from: channelName,
                        event,
                        data,
                    });
                } else if (to) {
                    this.defaultChannel.notifyTo(to, {
                        from: channelName,
                        event,
                        data,
                    });
                } else {
                    this.defaultChannel.notify({
                        from: channelName,
                        event,
                        data,
                    });
                }
            }
        );

        if (process.env.REACT_APP_ENV !== 'production') {
            // Debug hook
            bridge.mainChannel.notify.on(({ from, event, data }) => {
                this.processDebug({ channelName, from, event, data });
            });
        }

        const allHook = this.defaultChannel.notifyAll.on(
            ({ from, event, data }) => {
                if (from !== channelName) {
                    bridge.mainChannel.notify({ from, event, data });
                }
            }
        );

        const subHooks = subs
            .filter((s) => Boolean(this.otherChannels[s]))
            .map((sub) =>
                this.otherChannels[sub].mainChannel.notify.on(
                    ({ event, data }) => {
                        bridge.mainChannel.notify({ from: sub, event, data });
                    }
                )
            );

        if (!this.subs[channelName]) {
            this.subs[channelName] = [];
        }
        this.subs[channelName].push(notifHook, allHook, feedHook, ...subHooks);
    }

    unregister(channelName: string) {
        // Unhook into the default channel callbacks
        if (this.subs[channelName]) {
            this.subs[channelName].forEach((unsub) => unsub());
            delete this.subs[channelName];
        }

        if (this.otherChannels[channelName]) {
            delete this.otherChannels[channelName];
        }
    }

    notify(from: string, event: string, ...args: any[]) {
        this.defaultChannel.notify({ from, event, data: args });
    }

    notifyAll(from: string, event: string, ...args: any[]) {
        this.defaultChannel.notifyAll({ from, event, data: args });
    }

    notifyChannel(
        channelName: string,
        from: string,
        event: string,
        ...args: any[]
    ) {
        if (this.otherChannels[channelName]) {
            this.defaultChannel.notifyTo(channelName, {
                from,
                event,
                data: args,
            });
        }
    }

    channel(channelName: string | 'all') {
        return channelName === 'all'
            ? this.defaultChannel
            : this.otherChannels[channelName];
    }

    onEvents(events: EventMap) {
        Object.keys(events).forEach((e) => {
            if (!this.defaultSubs[e]) {
                this.defaultSubs[e] = [];
            }
            this.defaultSubs[e].push(
                {
                    unsubscribe:
                        e === '*'
                            ? this.defaultChannel.notifyAll.on(events[e])
                            : this.defaultChannel.notifyAll.on(e, events[e]),
                    callback: events[e],
                },
                {
                    unsubscribe:
                        e === '*'
                            ? this.defaultChannel.notify.on(events[e])
                            : this.defaultChannel.notify.on(e, events[e]),
                    callback: events[e],
                }
            );
        });
    }

    offEvents(events: EventMap) {
        Object.keys(events).forEach((e) => {
            if (!this.defaultSubs[e]) {
                return;
            }
            const foundIdx = this.defaultSubs[e].findIndex(
                (c) => c.callback === events[e]
            );
            if (foundIdx >= 0) {
                // unsub
                this.defaultSubs[e][foundIdx].unsubscribe();
                this.defaultSubs[e].splice(foundIdx, 1);
            }

            if (this.defaultSubs[e].length <= 0) {
                delete this.defaultSubs[e];
            }
        });
    }

    onChannelEvents(channelName: string, events: EventMap) {
        const channel = this.otherChannels[channelName] || null;
        if (channel) {
            Object.entries(events).forEach(([event, callback]) =>
                channel.onEvent(event, callback)
            );
        }
    }

    offChannelEvents(channelName: string, events: EventMap) {
        const channel = this.otherChannels[channelName] || null;
        if (channel) {
            Object.entries(events).forEach(([event, callback]) =>
                channel.offEvent(event, callback)
            );
        }
    }
}
