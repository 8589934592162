import download from 'downloadjs';
import {
    addToast,
    removeToast,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS,
} from '../store/actions/toasts';
import { getServiceBaseUrl } from './apiService';
import AuthService from './authService';
import { logger } from './logger';

export async function parseJsonResponse(response) {
    let json = null;

    try {
        json = await response.json();
    } catch (e) {
        // TODO Do something if response has no, or invalid JSON
        console.error(e);
    }

    if (response.ok) {
        return json;
    }

    const error = new Error(response.statusText);

    error.isFromServer = true;
    error.response = response;
    error.message = json && json.message;
    error.code = json && json.code;
    error.responseJson = json;

    throw error;
}

export async function processBlobDownload(response) {
    try {
        const header = response.headers.get('content-disposition');
        const filename = header.split('filename=')[1].split(';')[0];
        if (filename) {
            const sanitizedFileName = (filename || '')
                .trim()
                .replace(/[\"]/g, '')
                .replace(/[^a-zA-Z0-9_.]/g, '_');
            download(await response.blob(), sanitizedFileName);
        }
    } catch (e) {
        logger.error('Download Failed', e);
    }
}

export default async (method = 'GET', path, body, headers = {}, service) => {
    const BASE_URI = getServiceBaseUrl(service);
    const uri = `${BASE_URI}/${path}`;

    // eslint-disable-next-line no-param-reassign
    if (AuthService.token) {
        headers['X-AccessToken'] = AuthService.token;
    }

    headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
    };

    const fetchProperties = {
        method,
        headers,
        credentials: headers['X-AccessToken'] ? 'omit' : 'include',
    };

    if (body) {
        fetchProperties.body = JSON.stringify(body);
    }

    const response = await fetch(uri, fetchProperties);

    // Handling for blob data type for download
    // TODO: Create handler functions for suppporting different header types
    const header = response.headers.get('content-disposition');
    if (header && header.indexOf('attachment') === 0) {
        return processBlobDownload(response);
    }

    return parseJsonResponse(response);
};

/**
 * A response handler for showing toast messages after an API call action is dispatched
 * errMsg - Shows the custom error message if this field is set, or if the API sends back an error message
 * successMsg - Displays a toast on success, if this field is set.
 * @param {function} dispatch Store dispatch function to dispatch actions from inside components
 * @param {object} response API response object
 * @param {object} messageObj Custom messages for the toast, errMsg & successMsg
 * @param {function} resolve API call success callback
 * @param {function} reject API call error callback
 */
export const responseToastHandler = (
    dispatch,
    response,
    messageObj,
    resolve = () => {},
    reject = () => {},
    currentToastId,
    errorToastDisabled = false
) => {
    if (response) {
        currentToastId && dispatch(removeToast(currentToastId));
        if (response.error) {
            // Failure handler
            const { json } = response.payload;
            // Use custom message, if set or error message from the API response if available
            const { errMsg } = messageObj;
            let errorMessage = errMsg
                ? errMsg
                : json && json.message
                ? json.message
                : json && json.error
                ? json.error
                : null;
            if (errorMessage && !errorToastDisabled) {
                errorMessage = errorMessage.replace('<', '&#60');
                errorMessage = errorMessage.replace('>', '&#62');
                dispatch(
                    addToast({ type: TOAST_TYPE_ERROR, message: errorMessage })
                );
            }
            reject(response.payload);
        } else {
            // Success handler
            // Show a toast only if successMsg is set
            const { successMsg, title = '', version = '' } = messageObj;
            if (successMsg) {
                dispatch(
                    addToast({
                        type: TOAST_TYPE_SUCCESS,
                        message: successMsg,
                        title,
                        version,
                    })
                );
            }
            resolve(response.payload);
        }
    }
};

/**
 * Function to make External api calls.
 * @param {object} props Config object for external api calls
 */
export const makeExternalRequest = async ({
    method = 'get',
    path,
    body,
    headers = {},
}) => {
    const fetchProperties = {
        method,
        headers,
    };

    if (body) {
        fetchProperties.body = JSON.stringify(body);
    }

    const response = await fetch(path, fetchProperties);
    return parseJsonResponse(response);
};
