const DEFAULT_IMAGE_GEN_LIMIT = 10;
export const getMaxImageGenAttempts = (val) => val || DEFAULT_IMAGE_GEN_LIMIT;

export const getImageGenUserDataPath = ({ airmeetId, module, userId }) =>
    `${airmeetId}/features/imageGeneration/${module}/${userId}`;

export const AIR_GENIE_IMAGE_GEN_MODULES = {
    LANDING_PAGE_SLIDES: 'landingPageSlides',
    EVENT_BACKGROUND: 'eventBackground',
};
