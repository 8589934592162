import { Form } from '@rocketseat/unform';
import Btn from 'atoms/Button/btn';
import LocationSearch from 'atoms/LocationSearch';
import RadioGroupV2 from 'atoms/Radio/RadioGroupV2';
import WhiteSideBar from 'atoms/WhiteSideBar/';
import WithTooltip from 'components/general/WithTooltip';
import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { EVENT_SUB_TYPES, EVENT_TYPES } from 'utils/constants/airmeet';
import { SESSION_TYPES } from 'utils/constants/sessions';
import EventDetails from './EventDetails';
import FormAccordion from './FormAccordion';
import DurationField from './FormFields/DurationField';
import { Name } from './FormFields/Name';
import SessionTypeField from './FormFields/SessionTypeField';
import { CreateAirmeetDate } from './FormFields/CreateAirmeetDate';
import { CreateAirmeetTime } from './FormFields/CreateAirmeetTime';
import { TimeZone } from './FormFields/TimeZone';
import VenueCapacity from './FormFields/VenueCapacity';
import TimeZoneScreen from './screens/TimeZoneScreen';
import {
    ACCORDION_MAP,
    EVENT_SESSION_TYPE_OPTIONS,
    MEETUP_SUB_TYPE_OPTIONS,
} from './utils';

const DEFAULT_FORMAT = 'MMM d, yyyy';
export default function FormContent({
    eventCreated,
    showSidemenu,
    editMode,
    handleEditSubmit,
    handleSubmit,
    formData,
    changeFormData,
    nameRef,
    errors,
    onboardingInitiation,
    pageMode,
    setTypeDateTime,
    setShowSideMenu,
    getMinDate,
    getMinTime,
    onStateChanged,
    typeDateTime,
    selectedOption,
    handleNameError,
    onValueChange,
    setActiveOption,
    loading,
    isFormValid,
    submitBtnId,
    logOnboardingAnalyticsEvent,
    analyticsEventType,
    timeZone,
    isFullScreen,
    airmeetId,
    eventEntryValue,
    setEventEntryValue,
    domainList,
    setDomainList,
    isEventEnded,
    isHybridEvent,
    handleLocationChange,
    fixedEventEntryType = null,
    openAirGenie,
    isLongDescVisible,
    airmeetFormat,
    eventType,
    isLiteMeetup,
    communityId,
    setFormData,
    setErrors,
    isInPersonEvent,
}) {
    const { theme } = useTheme();
    const maxStartDate = moment(new Date(), moment.defaultFormat)
        .add({
            years: 1,
        })
        .subtract({
            days: 1,
        })
        .toDate();
    const maxEndDate = moment(formData.initDate, moment.defaultFormat)
        .add({ years: 5 })
        .subtract({
            days: 1,
        })
        .toDate();

    const [isBasicInfoOpen, setIsBasicInfoOpen] = useState(true);
    const [isEventEntryOpen, setIsEventEntryOpen] = useState(true);
    const [isAdditionalInfoOpen, setIsAdditionalInfoOpen] = useState(true);

    // Check is a field in an accordion has error and open it if closed
    useEffect(() => {
        const errorKeys = Object.keys(errors).filter((key) => !!errors[key]);
        if (
            !isBasicInfoOpen &&
            errorKeys.some((r) => ACCORDION_MAP.basicInfo.includes(r))
        ) {
            setIsBasicInfoOpen(true);
        }

        if (
            !isAdditionalInfoOpen &&
            errorKeys.some((r) => ACCORDION_MAP.additionalInfo.includes(r))
        ) {
            setIsAdditionalInfoOpen(true);
        }
    }, [errors, isBasicInfoOpen, isAdditionalInfoOpen]);
    const sessionTypeSelectedOption = useMemo(() => {
        const value =
            formData?.sessionType || EVENT_SESSION_TYPE_OPTIONS[0]?.value;
        return EVENT_SESSION_TYPE_OPTIONS.find((item) => item?.value === value);
    }, [formData]);
    const sessionVideos =
        Array.isArray(formData?.sessionMeta?.videos) &&
        formData?.sessionMeta?.videos.length > 0
            ? formData?.sessionMeta?.videos
            : [];
    const isDisabled =
        sessionTypeSelectedOption?.value === SESSION_TYPES.PRE_RECORDED &&
        !sessionVideos.length;
    return (
        !eventCreated && (
            <StyledContainer isFullScreen={isFullScreen}>
                <Form
                    onSubmit={editMode ? handleEditSubmit : handleSubmit}
                    initialData={
                        pageMode && onboardingInitiation
                            ? { initDate: formData?.initDate }
                            : formData
                    }
                    onChange={changeFormData}
                    noValidate
                >
                    <FormAccordion
                        title='Basic information'
                        isOpen={isBasicInfoOpen}
                        setIsOpen={setIsBasicInfoOpen}
                    >
                        <Box className='form-content'>
                            <WithTooltip
                                tooltip={
                                    isEventEnded &&
                                    `Can't edit name of an ${airmeetFormat} that has been ended.`
                                }
                                place='bottom'
                                isThemeV2={true}
                            >
                                <Box
                                    className='form-field-outer'
                                    mb={2}
                                    ref={nameRef}
                                    width={pageMode ? '45%' : 'auto'}
                                >
                                    <Name
                                        formData={formData}
                                        errors={errors}
                                        newOnBoardingFlow={onboardingInitiation}
                                        pageMode={pageMode}
                                        onEventNameFocus={() => {
                                            logOnboardingAnalyticsEvent(
                                                analyticsEventType.changedEventName
                                            );
                                        }}
                                        isEventEnded={isEventEnded}
                                        openAirGenie={openAirGenie}
                                        airmeetFormat={airmeetFormat}
                                    />
                                </Box>
                            </WithTooltip>
                            {pageMode && (
                                <Box mb={3}>
                                    <FlexDiv
                                        justifyContent={'space-between'}
                                        mb={3}
                                        width={'45%'}
                                    >
                                        <CreateAirmeetDate
                                            formData={formData}
                                            setTypeDateTime={setTypeDateTime}
                                            newOnBoardingFlow={
                                                onboardingInitiation
                                            }
                                            pageMode={pageMode}
                                            name={'initDate'}
                                            id={'initDate'}
                                            label={'Start date *'}
                                            dateFormat={DEFAULT_FORMAT}
                                            value={formData['initDate']}
                                            placeholder={'Select Date'}
                                            onChange={onStateChanged(
                                                'initDate'
                                            )}
                                            minDate={getMinDate(
                                                'initDate',
                                                true
                                            )}
                                            maxDate={maxStartDate}
                                        />

                                        <CreateAirmeetTime
                                            value={formData?.initDate}
                                            timezone={formData?.timeZone}
                                            id={'initTime'}
                                            name={'initTime'}
                                            label={'Start time *'}
                                            newOnBoardingFlow={
                                                onboardingInitiation
                                            }
                                            pageMode={pageMode}
                                            disabled
                                            hasTimePicker={true}
                                            min={getMinTime('initTime')}
                                            onChange={onStateChanged(
                                                'initTime'
                                            )}
                                            defaultTime={formData?.initDate}
                                            popperClassName={
                                                'custom-time-dropdown'
                                            }
                                            dateFormat={'HH:mm'}
                                            popperPlacement='bottom-start'
                                            timeFormat={'HH:mm'}
                                            wrapperClassName={
                                                'custom-time-picker'
                                            }
                                            onStartTimeFocus={() => {
                                                logOnboardingAnalyticsEvent(
                                                    analyticsEventType.changedStartTime
                                                );
                                            }}
                                        />
                                    </FlexDiv>
                                </Box>
                            )}
                            {onboardingInitiation && (
                                <>
                                    {pageMode && (
                                        <Box>
                                            <CreateEventNoteText
                                                variant={'subtext2'}
                                                color={'ambience.12'}
                                            >
                                                Don't fret! You can change these
                                                details later.
                                            </CreateEventNoteText>
                                        </Box>
                                    )}
                                </>
                            )}
                            {eventType === EVENT_TYPES.MEETUP && (
                                <>
                                    <MeetupSubTypeWrapper>
                                        <RadioGroupV2
                                            label='What type of webinar are you looking at? *'
                                            options={MEETUP_SUB_TYPE_OPTIONS}
                                            onValueChange={(v) =>
                                                onValueChange(
                                                    'meetupSubType',
                                                    v
                                                )
                                            }
                                            value={
                                                formData?.meetupSubType ||
                                                EVENT_TYPES.MEETUP
                                            }
                                            disabled={editMode}
                                        />
                                    </MeetupSubTypeWrapper>
                                    {isLiteMeetup && (
                                        <SessionTypeField
                                            formData={formData}
                                            onValueChange={onValueChange}
                                            communityId={communityId}
                                            setFormData={setFormData}
                                            setErrors={setErrors}
                                            editMode={editMode}
                                            airmeetFormat={airmeetFormat}
                                        />
                                    )}
                                </>
                            )}
                            {!onboardingInitiation && (
                                <>
                                    <FlexDiv
                                        flexDirection='column'
                                        alignItems='stretch'
                                        maxWidth={isLiteMeetup ? '' : '30rem'}
                                    >
                                        <FlexDiv mb='x2'>
                                            <CreateAirmeetDate
                                                setTypeDateTime={
                                                    setTypeDateTime
                                                }
                                                newOnBoardingFlow={
                                                    onboardingInitiation
                                                }
                                                pageMode={pageMode}
                                                name={'initDate'}
                                                id={'initDate'}
                                                label={'Start date *'}
                                                dateFormat={DEFAULT_FORMAT}
                                                value={formData?.['initDate']}
                                                placeholder={'Select Date'}
                                                onChange={onStateChanged(
                                                    'initDate'
                                                )}
                                                minDate={getMinDate(
                                                    'initDate',
                                                    true
                                                )}
                                                maxDate={maxStartDate}
                                            />
                                            <CreateAirmeetTime
                                                value={formData?.initDate}
                                                timezone={formData?.timeZone}
                                                id={'initTime'}
                                                name={'initTime'}
                                                label={'Start time *'}
                                                newOnBoardingFlow={
                                                    onboardingInitiation
                                                }
                                                pageMode={pageMode}
                                                disabled
                                                hasTimePicker={true}
                                                min={getMinTime('initTime')}
                                                onChange={onStateChanged(
                                                    'initTime'
                                                )}
                                                defaultTime={formData?.initDate}
                                                popperClassName={
                                                    'custom-time-dropdown'
                                                }
                                                dateFormat={'HH:mm'}
                                                timeFormat={'HH:mm'}
                                                wrapperClassName={
                                                    'custom-time-picker'
                                                }
                                            />
                                            {isLiteMeetup && (
                                                <DurationField
                                                    formData={formData}
                                                    onValueChange={
                                                        onValueChange
                                                    }
                                                    errors={errors}
                                                />
                                            )}
                                        </FlexDiv>
                                        {!isLiteMeetup && (
                                            <FlexDiv mb='x6'>
                                                <CreateAirmeetDate
                                                    id={'finalDate'}
                                                    name={'finalDate'}
                                                    value={
                                                        formData['finalDate']
                                                    }
                                                    label='End date *'
                                                    setTypeDateTime={
                                                        setTypeDateTime
                                                    }
                                                    newOnBoardingFlow={
                                                        onboardingInitiation
                                                    }
                                                    dateFormat={DEFAULT_FORMAT}
                                                    placeholder={'Select Date'}
                                                    onChange={onStateChanged(
                                                        'finalDate'
                                                    )}
                                                    minDate={getMinDate(
                                                        'finalDate',
                                                        true
                                                    )}
                                                    maxDate={maxEndDate}
                                                />
                                                <CreateAirmeetTime
                                                    value={formData?.finalDate}
                                                    timezone={
                                                        formData?.timeZone
                                                    }
                                                    id={'finalTime'}
                                                    name={'finalTime'}
                                                    label={'End time *'}
                                                    placeholder={
                                                        'Select End Time'
                                                    }
                                                    newOnBoardingFlow={
                                                        onboardingInitiation
                                                    }
                                                    pageMode={pageMode}
                                                    disabled
                                                    hasTimePicker={true}
                                                    min={getMinTime(
                                                        'finalTime'
                                                    )}
                                                    onChange={onStateChanged(
                                                        'finalTime'
                                                    )}
                                                    defaultTime={
                                                        formData?.finalDate
                                                    }
                                                    popperClassName={
                                                        'custom-time-dropdown'
                                                    }
                                                    dateFormat={'HH:mm'}
                                                    timeFormat={'HH:mm'}
                                                    wrapperClassName={
                                                        'custom-time-picker'
                                                    }
                                                />
                                            </FlexDiv>
                                        )}
                                        <TimeZoneWrapper>
                                            <Text
                                                variant='body2'
                                                color='text.default.primary'
                                                pr='x6'
                                            >
                                                Timezone
                                            </Text>
                                            <TimeZone
                                                timeZone={timeZone}
                                                showSidemenu={showSidemenu}
                                                setShowSideMenu={
                                                    setShowSideMenu
                                                }
                                                selectedOption={selectedOption}
                                                newOnBoardingFlow={
                                                    onboardingInitiation
                                                }
                                                airmeetFormat={airmeetFormat}
                                            />
                                            <Btn
                                                onClick={() =>
                                                    setShowSideMenu('timezone')
                                                }
                                                variant='link'
                                                ml='x4'
                                                type='button'
                                            >
                                                Change
                                            </Btn>
                                        </TimeZoneWrapper>
                                        {isLiteMeetup && (
                                            <Text
                                                variant='caption'
                                                color='text.default.primary'
                                                pt='x3'
                                            >
                                                After the scheduled start time,
                                                attendees can automatically
                                                enter webinar.
                                            </Text>
                                        )}
                                    </FlexDiv>
                                    <FlexDiv alignItems='flex-start'>
                                        {isHybridEvent || isInPersonEvent ? (
                                            <FlexDiv flex='1' maxWidth='32rem'>
                                                <LocationSearch
                                                    title={
                                                        formData?.eventVenue
                                                            ?.venue_title || ''
                                                    }
                                                    errors={errors}
                                                    newOnBoardingFlow={
                                                        onboardingInitiation
                                                    }
                                                    pageMode={pageMode}
                                                    handleLocationChange={
                                                        handleLocationChange
                                                    }
                                                />
                                            </FlexDiv>
                                        ) : null}
                                        {isInPersonEvent ? (
                                            <Box mt='x6' pl='x4'>
                                                <VenueCapacity
                                                    formData={formData}
                                                    errors={errors}
                                                    newOnBoardingFlow={
                                                        onboardingInitiation
                                                    }
                                                    pageMode={pageMode}
                                                    isEventEnded={isEventEnded}
                                                />
                                            </Box>
                                        ) : null}
                                    </FlexDiv>
                                </>
                            )}
                            {errors.initDate || errors.initTime ? (
                                <Box mb={3} id={'data-time-error'}>
                                    <Typography
                                        color={'semantic.1'}
                                        variant={'caption'}
                                    >
                                        {errors.initDate || errors.initTime}
                                    </Typography>
                                </Box>
                            ) : null}
                            {showSidemenu && (
                                <WhiteSideBar
                                    isAgnostic={
                                        theme?.isLightTheme ||
                                        onboardingInitiation
                                    }
                                    pageMode={pageMode}
                                    ambienceCode={
                                        theme?.isLightTheme ? '24' : '2'
                                    }
                                >
                                    <FlexDiv
                                        flexDirection={'column'}
                                        width={'100%'}
                                    >
                                        {showSidemenu === 'timezone' && (
                                            <TimeZoneScreen
                                                defaultValue={timeZone}
                                                onChange={onStateChanged(
                                                    'timeZone'
                                                )}
                                                activeOption={setActiveOption}
                                                onClose={() => {
                                                    setShowSideMenu('');
                                                    handleNameError();
                                                }}
                                                newOnBoardingFlow={
                                                    theme?.isLightTheme ||
                                                    onboardingInitiation
                                                }
                                                airmeetFormat={airmeetFormat}
                                            />
                                        )}
                                    </FlexDiv>
                                </WhiteSideBar>
                            )}
                        </Box>
                    </FormAccordion>
                    <Footer
                        alignItems={'center'}
                        pageMode={pageMode}
                        isFullScreen={isFullScreen}
                        editMode={editMode}
                    >
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            width={'100%'}
                        >
                            <StyledBtn
                                type='submit'
                                id={submitBtnId || 'create-event-btn'}
                                variant='primary'
                                loading={loading}
                                disabled={
                                    (onboardingInitiation && !isFormValid()) ||
                                    isDisabled
                                }
                                ml='0px'
                                isDisabled={isDisabled}
                            >
                                {editMode ? 'Save' : `Create ${airmeetFormat}`}
                            </StyledBtn>
                        </Box>
                    </Footer>
                </Form>
                <EventDetails
                    formData={formData}
                    onValueChange={onValueChange}
                    errors={errors}
                    pageMode={pageMode}
                    eventEntryValue={eventEntryValue}
                    setEventEntryValue={setEventEntryValue}
                    domainList={domainList}
                    setDomainList={setDomainList}
                    openAirGenie={openAirGenie}
                    isLongDescVisible={isLongDescVisible}
                    editMode={editMode}
                    airmeetId={airmeetId}
                    airmeetFormat={airmeetFormat}
                    isHybridEvent={isHybridEvent}
                    fixedEventEntryType={fixedEventEntryType}
                    isFullScreen={isFullScreen}
                    isInPersonEvent={isInPersonEvent}
                    isEventEntryOpen={isEventEntryOpen}
                    setIsEventEntryOpen={setIsEventEntryOpen}
                    isAdditionalInfoOpen={isAdditionalInfoOpen}
                    setIsAdditionalInfoOpen={setIsAdditionalInfoOpen}
                />
            </StyledContainer>
        )
    );
}
const StyledBtn = styled(Btn)`
    ${({ isDisabled }) =>
        isDisabled &&
        `}
cursor:not-allowed;
pointer-events:all !important;`}
`;
const Footer = styled(FlexDiv)`
    width: 100%;
    position: fixed;
    bottom: 30px;
    z-index: 2;
    ${({ pageMode, theme }) =>
        !pageMode &&
        `
            bottom: 0 !important;
            right: 0 !important;
            padding: ${theme.space.x6} 0 !important;
            button {
                width: 100%;
            }
    `}
    ${({ isFullScreen, theme }) =>
        isFullScreen
            ? ''
            : `
        padding: ${theme.space.x6} ${theme.space.x7} !important;
        background-color: ${theme.colors.fill.default1};
    `}
    ${({ editMode }) =>
        editMode
            ? `
                max-width: 800px; // Avoids flickering when opening edit drawer
            `
            : ''}
`;

const CreateEventNoteText = styled(Typography)`
    font-size: 15px;
`;

const TimeZoneWrapper = styled(Box)`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > div {
        margin-left: 5px;
        label {
            display: none;
        }
    }
    #timeZonePicker {
        padding: 0;
        background: transparent;
        margin: 0;
        min-height: 1px;
        pointer-events: none;

        p {
            margin: 0;

            &:nth-child(2) {
                &:before {
                    content: ' ,';
                    display: inline-flex;
                    margin-right: 5px;
                }
            }
        }
        &:before {
            right: -130px;
        }
    }
`;

const StyledContainer = styled(FlexDiv)`
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: 50rem;
    margin-bottom: ${({ theme }) => theme.space.x6};
    ${({ isFullScreen, theme }) =>
        isFullScreen
            ? `
                margin-top: ${theme.space.x7};
            `
            : ''}
    @media only screen and (max-width: 929px) {
        flex-wrap: wrap;
    }

    ${({ isFullScreen, theme }) =>
        isFullScreen &&
        `
        flex-wrap: wrap;
        padding-bottom: 140px;
        
        ${Footer}{
            bottom: 0;
            left: 0;
            right: 0;
            padding: 24px 0 !important;
            box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
            background: ${theme.colors.fill.default1}; 
            border-top: 1px solid ${theme.colors.border.default2};

            > div {
                max-width: 50rem;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .form-field.active, #timeZonePicker.active {
            &:before {
                display: none;
            }
        }
    `}
`;

const MeetupSubTypeWrapper = styled(FlexDiv)`
    flex-direction: column;
    align-items: stretch;
    margin-bottom: ${({ theme }) => theme.space.x6};
    label {
        margin-bottom: ${({ theme }) => theme.space.x2};
    }
    .RadioGroupRoot {
        flex-direction: row;
        .ItemWrapper {
            margin-right: 3rem;
        }
    }
`;
