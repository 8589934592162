import { useEffect } from 'react';

/**
 * A hook for detecting clicks outside of the target containers
 * Use cases include - overflow menus, dismissable modals etc
 * @param {Ref} ref
 * @param {Function} onOutsideClick
 * @param {Ref} excludes One or more optional refs that are exceptions.
 */
const useOutsideClickHandler = (ref, onOutsideClick, ...excludes) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            // do not invoke onOutsideClick if one of the exceptions
            // were clicked.
            for (let i = 0; i < excludes.length; i++) {
                const exclude = excludes[i].current;
                if (exclude && exclude.contains) {
                    if (
                        event.target === exclude ||
                        exclude.contains(event.target)
                    ) {
                        return;
                    }
                }
            }
            if (ref && ref.current && !ref.current.contains(event.target)) {
                if (onOutsideClick instanceof Function) {
                    onOutsideClick();
                }
            }
        };
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, onOutsideClick, excludes]);
};

export default useOutsideClickHandler;
