import CcLangsSelect from 'components/community/editPublicAirmeet/ClosedCaptions/CcLangsSelect';
import useTheme from 'hooks/useTheme';
import React, { useCallback } from 'react';
import { getClosedCaptionsSelectStyles } from 'components/community/editPublicAirmeet/ClosedCaptions';

export default function LangsSelect({ liveConfig, onChange, airmeetId }) {
    const { theme } = useTheme();

    const selectStyles = getClosedCaptionsSelectStyles(theme);

    const handleChange = useCallback(
        (option) => {
            const newLiveConfig = {
                ...(liveConfig || {}),
                ccLangs: option || [],
            };
            onChange('live_config', newLiveConfig);
        },
        [liveConfig]
    );

    return (
        <CcLangsSelect
            selectStyles={selectStyles}
            onChange={handleChange}
            liveConfig={liveConfig}
            airmeetId={airmeetId}
            style={style}
        />
    );
}

const style = { paddingTop: '0px', paddingBottom: '1rem' };
