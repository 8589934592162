import { CALL_API } from 'store/api';

export const UPDATE_ALLOW_TO_CAMERA_STATUS = 'UPDATE_ALLOW_TO_CAMERA_STATUS';
export const TOGGLE_PERMISSION_DIALOG = 'TOGGLE_PERMISSION_DIALOG';
export const FIREWALL_BLOCKING_CONNECTION = 'FIREWALL_BLOCKING_CONNECTION';
export const AIRMEET_WEBRTC_TURN_CONFIG_REQUEST =
    'AIRMEET_WEBRTC_TURN_CONFIG_REQUEST';
export const AIRMEET_WEBRTC_TURN_CONFIG_RESPONSE =
    'AIRMEET_WEBRTC_TURN_CONFIG_RESPONSE';
export const SET_IS_LIGHT_THEME_PAGE = 'SET_IS_LIGHT_THEME_PAGE';
export const SET_IS_EVENT_LANDING_BRANDING_PAGE =
    'SET_IS_EVENT_LANDING_BRANDING_PAGE';
export const SET_ALLOW_FETCH_ICONS_FOR_EVENT_LANDING_PAGE =
    'SET_ALLOW_FETCH_ICONS_FOR_EVENT_LANDING_PAGE';
export const SET_IS_EVENT_BRANDING_PAGE = 'SET_IS_EVENT_BRANDING_PAGE';
export const SET_IS_DASHBOARD_BRANDING_PAGE = 'SET_IS_DASHBOARD_BRANDING_PAGE';
export const SET_IS_NEW_ONBOARDING_PAGE = 'SET_IS_NEW_ONBOARDING_PAGE';
export const SET_IS_USER_WATCHING_REPLAY = 'SET_IS_USER_WATCHING_REPLAY';
export const LITEMODE_SESSION_IN_PROGRESS = 'LITEMODE_SESSION_IN_PROGRESS';
export const SET_IS_INVITE_TO_STAGE = 'SET_IS_INVITE_TO_STAGE';
export const SET_IS_FULLSTORY_RECORDING_ENABLED =
    'SET_IS_FULLSTORY_RECORDING_ENABLED';
export const SET_ARE_ALL_COOKIES_ALLOWED = 'ET_ARE_ALL_COOKIES_ALLOWED';
export const SET_IS_SERIES_LANDING_BRANDING_PAGE =
    'SET_IS_SERIES_LANDING_BRANDING_PAGE';

export const ALLOW_TO_CAMERA_STATUS = {
    init: 'init',
    requesting: 'requesting',
    accepted: 'accepted',
    declined: 'declined',
};

export function updateToAllowCameraStatus(status) {
    return {
        payload: {
            status,
        },
        type: UPDATE_ALLOW_TO_CAMERA_STATUS,
    };
}

export function isPermissionDialogVisible(isVisible) {
    return {
        type: TOGGLE_PERMISSION_DIALOG,
        payload: isVisible,
    };
}

export function updateFirwallBlockState(status) {
    return {
        type: FIREWALL_BLOCKING_CONNECTION,
        payload: status,
    };
}

export function getAirmeetWebRtcTURNServerConfig(userId, airmeetId) {
    return {
        [CALL_API]: {
            types: [
                AIRMEET_WEBRTC_TURN_CONFIG_REQUEST,
                AIRMEET_WEBRTC_TURN_CONFIG_RESPONSE,
            ],
            endpoint: `/config/webrtc/turn?user_id=${userId}&airmeet_id=${airmeetId}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function setIsLightThemePage(payload) {
    return {
        type: SET_IS_LIGHT_THEME_PAGE,
        payload,
    };
}

export function setIsEventLandingBrandingPage(payload) {
    return {
        type: SET_IS_EVENT_LANDING_BRANDING_PAGE,
        payload,
    };
}
export function setIsSeriesLandingBrandingPage(payload) {
    return {
        type: SET_IS_SERIES_LANDING_BRANDING_PAGE,
        payload,
    };
}
export function setAllowFetchIcons(payload) {
    return {
        type: SET_ALLOW_FETCH_ICONS_FOR_EVENT_LANDING_PAGE,
        payload,
    };
}

export function setIsEventBrandingPage(payload) {
    return {
        type: SET_IS_EVENT_BRANDING_PAGE,
        payload,
    };
}

export function setIsDashboardBrandingPage(payload) {
    return {
        type: SET_IS_DASHBOARD_BRANDING_PAGE,
        payload,
    };
}

export function setIsNewOnboardingPage(payload) {
    return {
        type: SET_IS_NEW_ONBOARDING_PAGE,
        payload,
    };
}

export function setIsUserWatchingReplay(payload) {
    return {
        type: SET_IS_USER_WATCHING_REPLAY,
        payload,
    };
}

export function setLiteModeSessionInProgress(payload) {
    return {
        type: LITEMODE_SESSION_IN_PROGRESS,
        payload,
    };
}

export function setIsInviteToStage(payload) {
    return {
        type: SET_IS_INVITE_TO_STAGE,
        payload,
    };
}

export function setIsFullstoryRecordingEnabled(payload) {
    return {
        type: SET_IS_FULLSTORY_RECORDING_ENABLED,
        payload,
    };
}

export function setAreAllCookiesAllowed(payload) {
    return {
        type: SET_ARE_ALL_COOKIES_ALLOWED,
        payload,
    };
}
