import { useLiveSessionContext } from 'context/Session';
import { useShallowEqualSelector } from 'hooks/common';
import useActiveSpeaker from 'hooks/live-airmeet/useActiveSpeaker';
import useAudioHandler from 'hooks/live-airmeet/useStageAudioHandler';
import useStageMuteListener from 'hooks/live-airmeet/useStageMuteListener';
import useVideoHandler from 'hooks/live-airmeet/useStageVideoHandler';
import useStreamPublisher from 'hooks/live-airmeet/useStreamPublisher';
import {
    useRTCStageAreaContext,
    getPersistentBSLocationsData,
} from 'context/RTCStageArea';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserIdService from 'services/users/UserIdService';
import { setUsersInfo } from 'store/actions/Lounge';
import { getStageUsersVisibility } from 'store/selectors';
import { useUserDataMulti } from 'store/selectors/users';
import { Events } from 'utils/airmeet';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import {
    getCustomMediaUserId,
    getScreenShareUserId,
    isCustomMediaStream,
    isScreenShareStream,
} from 'utils/constants/live-airmeet';
import { logger } from 'utils/logger';
import PermissionUtils from 'utils/permission-utils';
import useChannelConnectionStatus from 'utils/useChannelConnectionStatus';
import {
    isSessionHostOrCohost,
    isSpeaker,
    isOrganizer,
    isAttendee,
} from 'utils/userAccessControl';
import { emptyArray } from 'utils/constants/common';

const EMPTY_LIST = { stageUserIds: [] };
const LiveStreams = React.createContext(null);
const BackstageStreams = React.createContext(null);

export const STREAM_PLAYER_PREFIX = 'stage-stream-video-';

function airmeetSelector(state) {
    return state.lounge.airmeet;
}

const getPublisherId = (publishers, id) => {
    return (
        publishers?.[id]?.userId || publishers?.[id]?.user?.id // if screen-share just has id or if screen-share doesn't have id but user metadata
    );
};
export function LiveStreamsProvider({
    children,
    hideUserStreams,
    isLive,
    idSeq,
    airmeetId,
    userStageRole,
    isPersistentBackstageArea = false,
    stageService,
    channelName,
    allowToPublish,
    raiseHandActiveUsers = emptyArray,
}) {
    const streamPlayerId = `${STREAM_PLAYER_PREFIX}${channelName}-`;
    const dispatch = useDispatch();

    const isPersistentBackstageEnabled = useLiveSessionContext(
        (ctx) => ctx.isPersistentBackstageEnabled
    );
    const { liveStageUsers } = useRTCStageAreaContext(
        getPersistentBSLocationsData
    );
    const { is_custom_registration_enabled } = useSelector(airmeetSelector);
    const { screen, screen_backstage } = useShallowEqualSelector(
        (store) => store.liveAirmeet.genral
    );
    const screenUser = isPersistentBackstageArea ? screen_backstage : screen;
    const mirrorLocalVideo = useShallowEqualSelector(
        (store) => store.liveAirmeet.mirrorVideo
    );
    const streamStoppedStatus = useRef({});
    const isLoading = useSelector((ctx) => ctx.recordedVideo?.isLoading);

    const stageChannelConnectionsStatus = useChannelConnectionStatus(
        stageService
    );

    const { currentSession } = useLiveSessionContext();
    const sessionId = currentSession?.sessionid;
    const methods = useRef({});
    const streamsKeyRef = useRef({});
    const getViewableStreamsSyncTime = useRef(null);
    const [streams, setStreams] = useState([]);
    const userVisibilityState = useShallowEqualSelector(
        getStageUsersVisibility
    );
    const getStreamMetaData = useCallback(
        (userId) => {
            return streams.find((i) => i.id === userId)?.streamMetaData;
        },
        [streams]
    );
    const userVisibilityKey = useMemo(() => {
        if (isPersistentBackstageEnabled) {
            return liveStageUsers.join('-');
        }
        if (!userVisibilityState) {
            return '';
        }
        const visibleUserIds = [];
        for (const userId in userVisibilityState) {
            if (
                userVisibilityState[userId] &&
                userVisibilityState[userId]['visibility']
            ) {
                visibleUserIds.push(userId);
            }
        }
        return visibleUserIds.join('-');
    }, [isPersistentBackstageEnabled, userVisibilityState, liveStageUsers]);

    const shouldHideUserStream = useCallback(
        (userInfo) => {
            if (
                !userInfo ||
                hideUserStreams ||
                PermissionUtils.isUserEventCloudHost(userInfo)
            ) {
                return true;
            }
            const isSpeakerOrHost =
                isSpeaker(userInfo.id) || isSessionHostOrCohost(userInfo.id);
            const isSpeakerOrHostOrganizer =
                isSpeakerOrHost || isOrganizer(userInfo.id);

            if (isPersistentBackstageEnabled && isSpeakerOrHostOrganizer) {
                const isUserSittingOnStage = liveStageUsers.includes(
                    userInfo.id
                );
                const shouldHideUser = isPersistentBackstageArea
                    ? isUserSittingOnStage
                    : !isUserSittingOnStage;
                return shouldHideUser;
            }
            if (isSpeakerOrHost) {
                return (
                    !userVisibilityState[userInfo.id] ||
                    userVisibilityState[userInfo.id]['visibility'] !== true
                );
            }

            // hide the attendee if his raise hand request is not active
            if (
                isAttendee(userInfo.id) &&
                !raiseHandActiveUsers.includes(userInfo.id)
            ) {
                logger.info(
                    'The attendee with the raised hand is hidden because their request status is not active.',
                    {
                        raiseHandActiveUsers,
                        userId: userInfo.id,
                    }
                );
                return true;
            }

            return false;
        },
        [
            hideUserStreams,
            isPersistentBackstageEnabled,
            isPersistentBackstageArea,
            liveStageUsers,
            userVisibilityState,
            raiseHandActiveUsers,
        ]
    );

    const publishers = useStreamPublisher(
        airmeetId,
        channelName,
        idSeq,
        userStageRole,
        is_custom_registration_enabled,
        isLive,
        sessionId,
        stageService,
        allowToPublish
    );

    const { stageUserIds, stageUserIdMap } = useMemo(() => {
        if (publishers) {
            let ids = [];
            let map = new Map();
            for (let key in publishers) {
                const id = publishers[key]?.userId;
                if (id) {
                    ids.push(id);
                    map.set(id, { id_seq: Number(key) }); // cannot set screen share id to user id_seq so diff handling
                } else {
                    // for screen-share user
                    const screenUserId = publishers[key]?.user?.id;
                    if (
                        Boolean(screenUserId) &&
                        false === ids.includes(screenUserId)
                    ) {
                        ids.push(screenUserId);
                    }
                }
            }
            return { stageUserIds: ids, stageUserIdMap: map };
        } else {
            return EMPTY_LIST;
        }
    }, [publishers]);

    const publishingUsers = useUserDataMulti(stageUserIds, {
        idSeqMap: stageUserIdMap,
    });

    const stopRemoteStream = (userId) => {
        if (!userId || streamStoppedStatus.current[userId]) {
            return;
        }
        stageService?.stop && stageService.stop(userId);
        streamStoppedStatus.current[userId] = Date.now();
        logger.info('stop stream requested as user got hidden', { userId });
    };
    const clearStopStreamData = (userId) => {
        if (!userId || !streamStoppedStatus.current[userId]) {
            return;
        }
        delete streamStoppedStatus.current[userId];
        logger.info('clear the stop stream flag data', { userId });
    };

    methods.current.getViewableStreams = async () => {
        if (!getAirmeetUtilInstance()) {
            return;
        }
        const syncTime = Date.now();
        // Set streams value only for last promise resolve, ignore previous promises
        getViewableStreamsSyncTime.current = syncTime;
        // const { remoteStreams } = stageService;
        const streamList = {};
        const streamsKey = {};
        const newUsersFound = [];
        const addInStreamList = async (id, streamProps = {}) => {
            const { stream = false, streamMetaData } = streamProps;
            const isScreenShare = isScreenShareStream(id);
            const isCustomMedia = isCustomMediaStream(id);
            if (isCustomMedia && isLoading) return;
            const userIdSeq = isScreenShare
                ? getScreenShareUserId(id)
                : isCustomMedia
                ? getCustomMediaUserId(id)
                : id;

            // either from user or screen-share, as user can still go on hide mode, we should be able to get
            // user from screen id as well
            const userId =
                getPublisherId(publishers, userIdSeq) ||
                getPublisherId(publishers, id);

            let userInfo = publishingUsers.get(userId);
            if (!userInfo && streamProps?.streamMetaData?.user) {
                userInfo = streamProps.streamMetaData.user;
                const { role, ...userData } = userInfo;
                newUsersFound.push(userData);
            }
            if (!userInfo) {
                userInfo = await UserIdService.getInstance({
                    airmeetId,
                }).getId(userIdSeq);
            }

            if (
                !userInfo ||
                (!isScreenShare &&
                    !isCustomMedia &&
                    shouldHideUserStream(userInfo))
            ) {
                stopRemoteStream(userInfo?.id_seq);
                !userInfo &&
                    logger.info(
                        'User details cannot be retrieved as the UserIdService is unable to find any information',
                        {
                            userId,
                            userIdSeq,
                            id,
                        }
                    );
                return;
            }
            if (userInfo && !userInfo.role) {
                userInfo.role = 'attendee';
            }
            clearStopStreamData(userInfo?.id_seq);
            streamList[id] = {
                id: parseInt(id),
                userId: userInfo?.id_seq,
                isScreenShare,
                isCustomMedia,
                stream: !!stream,
                playerId: `${streamPlayerId}${id}`,
                streamMetaData,
                user: userInfo,
            };
            streamsKey[id] = !!stream;
        };

        const publisherKeys = Object.keys(publishers || {});
        const addStreamsFromClients = async () => {
            const promise = [];
            const screenShareStream = [];

            publisherKeys.forEach((remoteStreamId) => {
                if (publishers[remoteStreamId].type === 'screenShare') {
                    screenShareStream.push(remoteStreamId);
                    return;
                }
                promise.push(
                    new Promise((streamResolve, streamReject) => {
                        addInStreamList(remoteStreamId, {
                            streamMetaData: publishers[remoteStreamId],
                        })
                            .then(streamResolve)
                            .catch(streamReject);
                    })
                );
            });

            const newStreams = stageService.getStreams();
            newStreams.forEach(async (stream) => {
                const id = stream.getId();
                if (streamList[id]) {
                    streamList[id].stream = stream;
                    streamsKey[id] = !!stream;
                } else {
                    promise.push(
                        new Promise((streamResolve, streamReject) => {
                            addInStreamList(id, {
                                stream,
                                streamMetaData: publishers[id],
                            })
                                .then(streamResolve)
                                .catch(streamReject);
                            logger.info(
                                'LiveStreamsProvider:not found in firebase stream data:',
                                {
                                    stream: id,
                                }
                            );
                        })
                    );
                }
            });

            screenShareStream.forEach((screenShareId) => {
                const userIdSeq = getScreenShareUserId(screenShareId);

                const screenPublisherId = getPublisherId(
                    publishers,
                    screenShareId
                );
                // delete streams which is curretly not sharing
                if (screenPublisherId !== screenUser) {
                    delete streamList[screenShareId];
                    return;
                }
                if (
                    streamList[screenShareId] ||
                    (!PermissionUtils.isEventCloudHost() &&
                        !stageService.getRemoteStreamData(screenShareId) &&
                        !stageService.getStreamById(userIdSeq))
                ) {
                    return;
                }
                promise.push(
                    new Promise((streamResolve, streamReject) => {
                        addInStreamList(screenShareId, {
                            streamMetaData: publishers[screenShareId],
                        })
                            .then(streamResolve)
                            .catch(streamReject);
                    })
                );
            });
            return Promise.all(promise);
        };
        await addStreamsFromClients();
        const newStreamList = Object.values(streamList);
        streamsKeyRef.current = streamsKey;
        if (newUsersFound.length > 0) {
            dispatch(setUsersInfo(newUsersFound));
        }
        if (getViewableStreamsSyncTime.current !== syncTime) {
            return;
        }
        if (PermissionUtils.isEventCloudHost()) {
            logger.info('LiveStreamsProvider:viewable streams data', {
                publisherKeys,
                screenUser: screenUser ? screenUser : null,
                remoteStreams: Object.keys(stageService.getRemoteStreams()),
                streamsKey,
            });
        }
        setStreams(newStreamList);
    };

    useEffect(() => {
        methods.current.getViewableStreams();
    }, [hideUserStreams, screenUser, userVisibilityKey, raiseHandActiveUsers]);

    useEffect(() => {
        const removeStream = () => {
            methods.current.getViewableStreams();
        };

        const addStream = () => {
            methods.current.getViewableStreams();
        };

        const remoteStreamAdded = () => {
            methods.current.getViewableStreams();
        };

        const setRemoteStreamsAudioModeUpdate = ({ mute }) => {
            methods.current.setRemoteStreamsAudioModeUpdate({ mute });
        };

        stageService.on(Events.rtcLiveStreamRemoved, removeStream);
        stageService.on(Events.rtcLiveStreamAdded, addStream);
        stageService.on(Events.rtcLiveRemoteStreamAdded, remoteStreamAdded);
        stageService.on(
            Events.remoteStreamsAudioModeUpdate,
            setRemoteStreamsAudioModeUpdate
        );

        return () => {
            if (!stageService) {
                return;
            }
            stageService.off(Events.rtcLiveStreamRemoved, removeStream);
            stageService.off(Events.rtcLiveStreamAdded, addStream);
            stageService.off(
                Events.rtcLiveRemoteStreamAdded,
                remoteStreamAdded
            );
            stageService.off(
                Events.remoteStreamsAudioModeUpdate,
                setRemoteStreamsAudioModeUpdate
            );
        };
    }, []);

    useEffect(() => {
        methods.current.getViewableStreams();
    }, [publishers, isLoading]);

    const streamKeys = streamsKeyRef.current;

    useEffect(() => {
        if (!stageService) {
            return;
        }
        stageService.updateLocalVideoMirror(mirrorLocalVideo);
    }, [mirrorLocalVideo, stageService]);

    const screenStreamData = useMemo(
        () =>
            streams.find(
                ({ isScreenShare, isCustomMedia }) =>
                    isScreenShare || isCustomMedia
            ),
        [streams]
    );
    const userAndScreenStreamsData = useMemo(
        () => streams.filter(({ isCustomMedia }) => !isCustomMedia),
        [streams]
    );
    const { activeSpeakerStreams } = useActiveSpeaker({ streams, streamKeys });
    const {
        isVideoMuted,
        muteVideoHandler,
        unMuteVideoHandler,
    } = useVideoHandler({ stageService });
    const {
        isAudioMuted,
        muteAudioHandler,
        unMuteAudioHandler,
    } = useAudioHandler({ stageService });
    useStageMuteListener({
        isAudioMuted,
        stageService,
        isPersistentBackstageArea,
    });
    const props = {
        streams,
        userAndScreenStreamsData,
        isLive,
        channelName,
        stageChannelConnectionsStatus,
        streamKeys,
        activeSpeakerStreams,
        screenStreamData,
        audioVideoOptions: { isVideoMuted, isAudioMuted },
        muteAudioHandler,
        unMuteAudioHandler,
        muteVideoHandler,
        unMuteVideoHandler,
        getStreamMetaData,
        raiseHandActiveUsers,
    };

    const LiveStreamProvider = isPersistentBackstageArea
        ? BackstageStreams
        : LiveStreams;
    return (
        <>
            <LiveStreamProvider.Provider value={props}>
                {children}
            </LiveStreamProvider.Provider>
        </>
    );
}

export default LiveStreams;

/**
 * Accepts a selector function which can be used to select a value at any
 * level of the LiveStreams context, like the `useSelector` hook from redux
 *
 * @param {(context) => {}} callback
 */
export function useLiveStreamsContext(callback = (context) => context) {
    // The live airmeet context
    const ctx = useContext(LiveStreams);

    // Return the up-to-date selected value
    return callback(ctx);
}

export function useBackstageStreamsContext(callback = (context) => context) {
    // The live airmeet context
    const ctx = useContext(BackstageStreams);

    // Return the up-to-date selected value
    return callback(ctx);
}
