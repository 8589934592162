import useCustomBaseUrl from 'hooks/useCustomBaseUrl';
import React from 'react';
import { redirectToHomePage } from 'utils/redirections';
import { cdnImage } from '../../utils/core';
import Errors from './';

const NotFound = () => {
    const { baseUrl } = useCustomBaseUrl();

    const handleClick = () => {
        redirectToHomePage(baseUrl);
    };

    return (
        <Errors
            errorIcon={cdnImage('/images/404-not-found.png')}
            iconAltText='Page not found'
            handleClick={handleClick}
            header='The page you’re looking for does not exist'
            subText="We are very sorry for inconvenience. It looks like you're trying to access a page that has either been deleted or never existed."
            buttonText='Go to Home'
        />
    );
};

export default NotFound;
