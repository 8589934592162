export const accessibilityOnKeyPress = (
    event: React.KeyboardEvent
): Boolean => {
    return event.key === 'Enter';
};

type TCallback<T = unknown, R = void> = (...args: T[]) => R;
const isCallable = (cb: TCallback) => typeof cb === 'function';

const defaultKeyPressOptions = {
    arguments: [],
    customKeys: [],
    context: null,
    isEventReq: false,
};
export const onKeyPress = (
    event: React.KeyboardEvent,
    cb: TCallback,
    options?: Partial<typeof defaultKeyPressOptions>
) => {
    if (!isCallable(cb)) return null;

    const keyPressOptions = {
        ...defaultKeyPressOptions,
        ...(options || {}),
    };

    if (!Array.isArray(keyPressOptions.arguments)) {
        keyPressOptions.arguments = [keyPressOptions.arguments];
    }

    let argsForCallback = [];
    if (keyPressOptions.isEventReq) {
        argsForCallback.push(event);
    }
    argsForCallback = [...argsForCallback, ...keyPressOptions.arguments];

    const shouldRunCallback = keyPressOptions.customKeys.length
        ? keyPressOptions.customKeys.includes(event.key)
        : accessibilityOnKeyPress(event);

    if (shouldRunCallback) {
        keyPressOptions.context === null
            ? cb(...argsForCallback)
            : cb.apply(keyPressOptions.context, argsForCallback);
    }
};
