import {
    PENDING_LANDING_PAGE_SCREEN_SOURCE,
    QUESTIONS_SCREEN_SOURCE,
    ACTIVE_LANDING_PAGE_SCREEN_SOURCE,
} from 'components/newOnboardingFlow/config';
import React from 'react';
import OnBoardingButton from '../OnBoardingButton';
import OnBoardingCmDetails from '../OnboardingCmDetails';

export default function BasicHeader({
    source,
    imgUrl = '',
    name = '',
    logoutHandler = () => {},
    isOnlyMobile,
    isTablet,
}) {
    const HEADER_LEFT = [];
    const HEADER_CONTENT = [
        [ACTIVE_LANDING_PAGE_SCREEN_SOURCE].includes(source) &&
            !isOnlyMobile && (
                <OnBoardingCmDetails imgUrl={imgUrl} name={name} />
            ),
    ];
    const HEADER_RIGHT = [
        [
            QUESTIONS_SCREEN_SOURCE,
            PENDING_LANDING_PAGE_SCREEN_SOURCE,
            ACTIVE_LANDING_PAGE_SCREEN_SOURCE,
        ].includes(source) && (
            <OnBoardingButton
                source={source}
                logoutHandler={logoutHandler}
                isMobile={isOnlyMobile}
                isTablet={isTablet}
            />
        ),
    ];

    return [HEADER_LEFT, HEADER_CONTENT, HEADER_RIGHT];
}
