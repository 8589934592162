export const OPEN_CREATE_MEETING_OVERLAY = 'OPEN_CREATE_MEETING_OVERLAY';
export const CLOSE_CREATE_MEETING_OVERLAY = 'CLOSE_CREATE_MEETING_OVERLAY';
export const SHOW_MANAGE_MEETING_POPOVER = 'SHOW_MANAGE_MEETING_POPOVER';
export const HIDE_MANAGE_MEETING_POPOVER = 'HIDE_MANAGE_MEETING_POPOVER';
export const UPDATE_ATTENDEE_LIST = 'UPDATE_ATTENDEE_LIST';
export const TOGGLE_SCHEDULE_MEETING_TOAST = 'TOGGLE_SCHEDULE_MEETING_TOAST';

export function openCreateMeetingOverlay() {
    return {
        payload: [],
        type: OPEN_CREATE_MEETING_OVERLAY,
    };
}

export function closeCreateMeetingOverlay() {
    return {
        payload: [],
        type: CLOSE_CREATE_MEETING_OVERLAY,
    };
}

export function updateAttendeeList(data) {
    return {
        payload: data,
        type: UPDATE_ATTENDEE_LIST,
    };
}

export function scheduleMeetingToast(data) {
    return {
        payload: data,
        type: TOGGLE_SCHEDULE_MEETING_TOAST,
    };
}
