import * as RdxToast from '@radix-ui/react-toast';
import { ToastViewport } from 'atoms/Toast';
import ToastAtom from 'atoms/Toast/';
import { FULLSCREEN_PORTAL_ID } from 'components/general/fullScreen';
import Portal from 'components/general/Portal';
import { useFullScreenContext } from 'context/FullScreen';
import Icon from 'foundations/icon';
import theme from 'foundations/theme';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch } from 'react-redux';
import { closeAppModal } from 'store/actions/layout';
import { removeToast, TOAST_TYPE_CUSTOM } from 'store/actions/toasts';
import styled, { css, keyframes } from 'styled-components';

const ToastPopup = ({
    id,
    show = false,
    title = '',
    message = '',
    type = 'success',
    minHeight = '',
    width = '',
    zIndex = '',
    onClose = () => {},
    duration: time = 5000,
    iconPos = '',
    isMobile = false,
    renderElement,
    icon = null,
    iconWidth = '',
    iconHeight = '',
    startCount = null,
    endCount = null,
    closeToastOnly = false,
    positionTop = '',
    positionRight = '',
    positionLeft = '',
    padding = '',
    resetStyleValues = false,
    enableAnimations = true,
    children,
}) => {
    const { isLightTheme } = useTheme();
    const ToastType = {
        success: {
            icon: 'checkmark_circle',
            fill: theme.colors.semantic[2],
        },
        duplicateventsuccess: {
            icon: 'thumbsup_duplicatevent',
        },
        duplicateventerror: {
            icon: 'thumbsdown_duplicatevent',
        },
        error: {
            icon: 'error_circle',
            fill: theme.colors.semantic[1],
        },
        warning: {
            icon: 'warning',
            fill: theme.colors.semantic[0],
        },
        info: {
            icon: 'info_circle',
            fill: theme.colors.accentPrimary[1],
        },
    };

    const { fullScreen = false } = useFullScreenContext() || {};

    const dispatch = useDispatch();

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (id) {
                dispatch(removeToast(id));
            }
            if (!closeToastOnly) {
                dispatch(closeAppModal());
            }
        }, time);

        return () => clearTimeout(timeOut);
    }, []);

    if (!message && !title && type !== TOAST_TYPE_CUSTOM) {
        return null;
    }

    const toast = () => (
        <Toast
            minHeight={minHeight}
            width={width}
            zIndex={zIndex}
            positionTop={positionTop}
            positionRight={positionRight}
            positionLeft={positionLeft}
            padding={padding}
            resetStyleValues={resetStyleValues}
            disaply={show}
            isMobile={isMobile}
            enableAnimations={enableAnimations}
            role='alert'
        >
            {type === TOAST_TYPE_CUSTOM ? (
                renderElement ? (
                    renderElement({ onClose, removeToast, id })
                ) : (
                    children
                )
            ) : (
                <Box>
                    <IconWrapper>
                        <Icon
                            width={iconWidth ? iconWidth : 32}
                            height={iconHeight ? iconHeight : 32}
                            icon={icon || ToastType[type].icon}
                            fill={ToastType[type].fill}
                        />
                    </IconWrapper>
                    <ToastWrapper>
                        {title && (
                            <Typography
                                variant={'body1'}
                                color={
                                    isLightTheme ? 'ambience.5' : 'ambience.19'
                                }
                            >
                                {title}
                            </Typography>
                        )}
                        {message && (
                            <ToastMessage
                                variant={'subtext1'}
                                color='ambience.13'
                            >
                                {message?.component
                                    ? message.component
                                    : ReactHtmlParser(
                                          message.markup || message
                                      )}
                            </ToastMessage>
                        )}
                    </ToastWrapper>
                    <CloseButton
                        iconPos={iconPos}
                        onClick={() => {
                            onClose();
                            if (id) dispatch(removeToast(id));
                        }}
                    >
                        <Icon
                            width={24}
                            height={24}
                            icon={'dismiss_circle'}
                            fill={
                                isLightTheme
                                    ? theme.colors.ambience[10]
                                    : theme.colors.ambience[4]
                            }
                        />
                    </CloseButton>
                    {startCount && endCount && (
                        <StepTypography
                            variant={'body1'}
                            color={isLightTheme ? 'ambience.5' : 'ambience.19'}
                        >
                            {startCount} of {endCount}
                        </StepTypography>
                    )}
                </Box>
            )}
        </Toast>
    );

    return type === TOAST_TYPE_CUSTOM ? (
        <Portal container={fullScreen ? FULLSCREEN_PORTAL_ID : 'root'}>
            {toast()}
        </Portal>
    ) : (
        <RdxToast.Provider swipeDirection='up'>
            <ToastAtom
                toast={{ id, type, duration: time, title, message, onClose }}
                key={id}
            />
            <ToastViewport />
        </RdxToast.Provider>
    );
};

export default ToastPopup;

const slideInDown = keyframes`
 0% { transform: translateY(-500%); }
 100% { transform: translateY(0); }
`;
const slideInUp = keyframes`
 0% { transform: translateY(500%); }
 100% { transform: translateY(0); }
`;

const Toast = styled.div`
    position: fixed;
    top: ${({ positionTop }) => (positionTop ? positionTop : '75px')};
    left: ${({ positionLeft, positionRight }) =>
        positionLeft ? positionLeft : positionRight ? 'auto' : '0px'};
    right: ${({ positionRight }) => (positionRight ? positionRight : '0px')};
    margin: 0 auto;
    background: ${({ theme }) =>
        theme.isLightTheme
            ? theme.colors.ambience[24]
            : theme.colors.ambience[0]};
    ${({ theme }) =>
        theme.isLightTheme &&
        `
        border: ${`1px solid ${theme.colors.ambience[21]}`};
        box-shadow: 0px 0px 10px rgba(18, 20, 23, 0.2);
    `};
    border-radius: 8px;
    padding: ${({ padding }) => (padding ? padding : '12px 18px')};
    z-index: ${({ zIndex }) => (zIndex ? zIndex : '9999')};
    max-width: ${({ width }) => (width ? width : '446px')};
    width: 100%;
    min-height: ${({ minHeight }) => (minHeight ? minHeight : '68px')};
    align-items: center;
    ${({ resetStyleValues }) =>
        resetStyleValues &&
        `
            all: unset;
        `}
    display: none;
    transition: all ease 0.6s;
    animation-name: ${({ enableAnimations }) =>
        enableAnimations ? slideInDown : 'none'};
    ${({ enableAnimations }) =>
        enableAnimations &&
        `
        animation-delay: 0s;
        animation-duration: 1s;
        animation-iteration-count: 1;
    `};

    ${(props) =>
        props.disaply &&
        `
        display: flex;
    `}

    @media(max-width: 480px) {
        width: 90%;
        box-shadow: 0 0 0px 800px rgb(0 0 0 / 35%);
    }

    ${(props) =>
        props.isMobile &&
        css`
            @media (max-width: 991px) {
                top: auto;
                bottom: 25px;
                animation-name: ${({ enableAnimations }) =>
                    enableAnimations ? slideInUp : 'none'};

                ${IconWrapper} {
                    display: none;
                }
                ${CloseButton} {
                    display: none;
                }
                ${ToastWrapper} {
                    padding: 0;
                }
            }
        `};
`;

const Box = styled.div`
    position: relative;
    width: 100%;
`;

const ToastMessage = styled(Typography)`
    margin-top: 8px;
`;

const IconWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
`;

const ToastWrapper = styled.div`
    padding: 0 40px 0 48px;
`;

const CloseButton = styled.div`
    cursor: pointer;
    position: absolute;
    ${(props) =>
        props.iconPos === 'top'
            ? `right: -3px;
    top: 20%;`
            : `right: 0;
    top: 50%;`};
    transform: translateY(-50%);
`;

const StepTypography = styled(Typography)`
    text-align: right;
`;
