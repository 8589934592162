import Box from 'atoms/Box';
import Btn from 'atoms/Button/btn';
import { Text } from 'foundations/themeV2/text';
import useCustomText from 'hooks/useCustomText';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import keys from 'locale/keys';
import React from 'react';
import styled from 'styled-components';

export const MessageReportModal = ({ onDismiss }) => {
    const { t } = useCustomTranslation();
    return (
        <>
            <ReportModal>
                <StyledHeader variant='h4'>
                    {t(keys.RHS_ACTIONS_REPORT_HEADER)}
                </StyledHeader>
                <Box mt='x3' mb='x5'>
                    <Description variant='caption'>
                        {t(keys.RHS_ACTIONS_REPORT_DESC)}
                    </Description>
                </Box>
                <Btn onClick={onDismiss}>{t(keys.GENERAL_OK)}</Btn>
            </ReportModal>
            <ReportBackDrop />
        </>
    );
};

export const StreamLimitModal = ({ onDismiss }) => {
    const { t } = useCustomTranslation();
    return (
        <>
            <ReportModal>
                <StyledHeader variant='h5'>
                    {t(keys.STAGE_STREAM_LIMIT)}
                </StyledHeader>
                <Box mt='x3' mb='x5'>
                    <Description variant='caption'>
                        {t(keys.STAGE_STREAM_LIMIT_DESC)}
                    </Description>
                </Box>
                <Btn variant='secondary' size='small' onClick={onDismiss}>
                    {t(keys.GENERAL_OK)}
                </Btn>
            </ReportModal>
            <ReportBackDrop />
        </>
    );
};

export const UnblockUserModal = ({ user, onDismiss, onConfirm }) => {
    const { t } = useCustomTranslation();
    const customText = useCustomText();

    return (
        <>
            <ReportModal>
                <StyledHeader variant='h5' mb='x4'>{`${t(
                    keys.GENERAL_UNBLOCK
                )} ${user.name}?`}</StyledHeader>
                <Description variant='caption' color='text.default.secondary'>
                    {t(keys.RHS_ACTIONS_UNBLOCK_USER_DESC2, {
                        loungeLabel: (
                            customText?.lounge ||
                            t(keys.LOUNGE_SOCIAL_LOUNGE) ||
                            ''
                        ).toLowerCase(),
                    })}
                </Description>
                <Box
                    display='flex'
                    width='100%'
                    alignSelf='flex-end'
                    className='button-wrapper'
                    mt='x4'
                >
                    <Btn
                        variant='danger'
                        size='small'
                        onClick={onConfirm}
                        mr={'x2'}
                    >
                        {t(keys.GENERAL_UNBLOCK)}
                    </Btn>
                    <Btn variant='secondary' size='small' onClick={onDismiss}>
                        {t(keys.GENERAL_CANCEL)}
                    </Btn>
                </Box>
            </ReportModal>
            <ReportBackDrop />
        </>
    );
};

const ReportModal = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999;
    background-color: ${({ theme }) => theme.colors.fill.default1};
    border: solid 1px ${({ theme }) => theme.colors.border.default1};
    border-radius: ${({ theme }) => theme.radii.x3};

    transform: translate(-50%, -50%);
    padding: ${({ theme }) => theme.space.x4};
    width: 90%;
`;
const ReportBackDrop = styled(Box)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.overlay.default1};
    z-index: 1;
`;
const StyledHeader = styled(Text)``;
const Description = styled(Text)`
    color: ${({ theme }) => theme.colors.text.default.secondary};
`;
