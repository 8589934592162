import createReducer from 'utils/createReducer';
import {
    ANNOUNCEMENT_JOIN,
    ANNOUNCEMENT_IS_LIVE,
} from '../actions/liveAnnouncement';

const initialState = {
    isLive: false,
    announcementJoined: false,
};

const handlers = {
    [ANNOUNCEMENT_JOIN]: (state, { payload }) => {
        return { ...state, announcementJoined: payload };
    },
    [ANNOUNCEMENT_IS_LIVE]: (state, { payload }) => {
        return { ...state, isLive: payload };
    },
};

export default createReducer(initialState, handlers);
