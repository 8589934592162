const LOAD_LIMIT = {
    TABLE_CHATS: 100,
    TABLE_INVITES: 3,
    DM_CHATS: 100,
    RHS_FEEDS: 100,
    REPORT_MESSAGES: 200,
    SCHEDULE_MEETINGS: 100,
    POLLS: 100,
};

export default LOAD_LIMIT;
