import { alpha } from 'foundations/theme';
import { Text } from 'foundations/themeV2/text';
import { Typography } from 'foundations/typography';
import { motion } from 'framer-motion';
import {
    Col as GridCol,
    Container as GridContainer,
    Row as GridRow,
} from 'react-grid-system';
import styled, { css } from 'styled-components/macro';
import {
    background,
    border,
    color,
    flexbox,
    grid,
    layout,
    letterSpacing,
    position,
    shadow,
    space,
    system,
    typography,
} from 'styled-system';

const whiteSpace = system({ whiteSpace: true });
const boxSizing = system({ boxSizing: true });
export const cursor = system({ cursor: true });
const objectFit = system({ objectFit: true });
const gap = system({ gap: true });
const wordBreak = system({ wordBreak: true });

export const Box = styled.div`
    ${flexbox}
    ${layout}
    ${typography}
    ${letterSpacing}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${whiteSpace}
    ${boxSizing}
    ${cursor}
`;

export const FlexDiv = styled.div`
    display: flex;
    ${flexbox}
    ${layout}
    ${typography}
    ${letterSpacing}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${whiteSpace}
    ${boxSizing}
    ${cursor}
    ${gap}
`;

export const MotionDiv = styled(motion.div)`
    ${flexbox}
    ${layout}
    ${typography}
    ${letterSpacing}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${whiteSpace}
    ${boxSizing}
    ${cursor}
`;

export const GridDiv = styled.div`
    display: grid;
    ${grid}
    ${layout}
    ${typography}
    ${letterSpacing}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${whiteSpace}
    ${boxSizing}
    ${cursor}
    ${gap}
`;

/**
 *  Add ellipsis at end of an over-flowing text
 */
export const EllipsisTypography = styled(Typography)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${layout}
    ${typography}
    ${letterSpacing}
    ${space}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${whiteSpace}
    ${boxSizing}
    ${cursor}
`;
export const EllipsisText = styled(Text)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${layout}
    ${typography}
    ${letterSpacing}
    ${space}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${whiteSpace}
    ${boxSizing}
    ${cursor}
`;

/**
 * Clamps the text by adding an ellipsis at the end after the defined number of lines (default to 3 lines)
 */
export const ClampedTypography = styled(Typography)`
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => (props.lines ? props.lines : '3')};
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    ${layout}
    ${typography}
    ${letterSpacing}
    ${space}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${whiteSpace}
    ${boxSizing}
    ${cursor}
    ${wordBreak}
`;

export const ClampedText = styled(Text)`
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => (props.lines ? props.lines : '3')};
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    word-wrap: anywhere;
`;

/**
 *  General component for labels
 */
export const Label = styled(Typography).attrs((props) => ({
    variant: props.variant || 'button2',
    padding: props.padding || '6px 10px',
}))`
    border-radius: 20px;
    ${color}
    ${position}
    ${whiteSpace}
    ${boxSizing}
    ${cursor}
`;

/**
 *  Makes a text non selectable
 */
export const NonSelectableText = styled(Typography)`
    user-select: none;
    ${whiteSpace}
`;
export const NonSelectableText2 = styled(Text)`
    user-select: none;
    ${whiteSpace}
`;

export default {
    Box,
    FlexDiv,
    GridDiv,
    EllipsisTypography,
    ClampedTypography,
    Label,
    NonSelectableText,
};

/**
 *  Overlay
 */

export const Overlay = styled(Box)`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${(props) => alpha(props.theme.colors.ambience[23], 0.8)};
`;

export const accessibilityStyles = (
    outlineThickness = '1px',
    { isDark = false } = {}
) => css`
    &:focus-visible {
        outline: ${outlineThickness} solid
            ${({ theme }) => (theme.isLightTheme || isDark ? 'black' : 'white')} !important;
    }
`;
/**
 *  Custom File Uploader
 */
export const CustomUpload = styled(Box)`
    text-align: ${(props) => (props.horizontal ? 'left' : 'center')};
    padding: ${(props) => (props.horizontal ? '27px 30px' : '34px 60px')};
    position: relative;
    cursor: pointer;
    background-color: ${({ theme }) =>
        theme.isLightTheme
            ? theme.colors.ambience[23]
            : theme.colors.ambience[20]};
    border-radius: 8px;
    border: 1px dashed ${({ theme }) => theme.colors.ambience[13]};
    .browse {
        color: ${({ theme }) => theme.colors.accentPrimary[0]};
    }
    input[type='file'] {
        background-color: rgb(0, 0, 0, 0);
        color: rgb(0, 0, 0, 0);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        width: 100%;
        cursor: pointer;
        ${accessibilityStyles()}
    }
    input[type='file']::file-selector-button {
        all: unset;
        background-color: rgb(0, 0, 0, 0);
        color: rgb(0, 0, 0, 0);
    }
    input[type='file']::-webkit-file-upload-button {
        all: unset;
        background-color: rgb(0, 0, 0, 0);
        color: rgb(0, 0, 0, 0);
    }
    .upload-details {
        position: absolute;
        top: -26px;
        right: 0;
        z-index: 3;
        span {
            display: none;
        }
        .delete-upload {
            display: inline-block;
            font-family: ${({ theme }) => theme.fonts.book};
            color: ${(props) => props.theme.colors.accentPrimary[0]};
        }
        .delete-upload-icon {
            display: none;
        }
    }
    .image-preview {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        border-radius: 8px;
        bottom: 0;
        z-index: 1;
        overflow: hidden;
        background: ${(props) => props.theme.colors.ambience[23]};
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            max-height: 100% !important;
            max-width: 100% !important;
        }
        .edit-upload {
            opacity: 0;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            border-radius: 8px;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.7);
            transition: opacity 0.3s ease;
        }
        .combined-icons {
            position: absolute;
            top: 0px;
            right: 3px;
            flex-direction: column;
            justify-content: space-between;
            height: 90%;
            padding: 4px 5px;
            background: #26252d;
            border: 1px solid #565365;
            border-radius: 20px;
            display: none;
        }
    }
    &:hover {
        .edit-upload {
            opacity: 1;
            svg {
                display: block !important;
            }
        }
        .upload-details {
            .delete-upload-icon {
                display: block;
            }
        }
        .combined-icons {
            opacity: 1;
            display: flex !important;
        }
    }
    .upload-error {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 16px;
        background-color: rgba(241, 42, 42, 0.1);
        border: 1px dashed ${(props) => props.theme.colors.semantic[1]};
        border-radius: 8px;
        text-align: center;
        .icon-error {
            width: 24px;
            height: 24px;
        }
        h5 {
            font-size: 12px;
            color: ${(props) => props.theme.colors.ambience[1]};
            margin-top: 12px;
            margin-bottom: 4px;
        }
    }
`;

export const LoadingSpinner = styled(Box)`
    @-webkit-keyframes loading-spinner {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-moz-keyframes loading-spinner {
        0% {
            -moz-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -moz-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-o-keyframes loading-spinner {
        0% {
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-ms-keyframes loading-spinner {
        0% {
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes loading-spinner {
        0% {
            transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 101;
    cursor: initial;
    background: rgba(0, 0, 0, 0.7);
    padding-top: 60px;
`;
export const SpinIcon = styled(Box)`
    -webkit-animation: loading-spinner 800ms linear infinite;
    -moz-animation: loading-spinner 800ms linear infinite;
    -ms-animation: loading-spinner 800ms linear infinite;
    -o-animation: loading-spinner 800ms linear infinite;
    animation: loading-spinner 800ms linear infinite;
`;

export const Separator = styled(Box)`
    border-top: 1px solid ${({ theme }) => theme.colors.ambience[19]};
    ${({ isActive, theme }) =>
        isActive &&
        `
        border-color: ${theme.colors.ambience[15]};
    `}
`;

export const StyledImage = styled.img`
    ${flexbox}
    ${layout}
    ${typography}
    ${letterSpacing}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${whiteSpace}
    ${boxSizing}
    ${cursor}
    ${objectFit}
`;

export const StyledInput = styled.input`
    ${layout}
    ${typography}
    ${letterSpacing}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${whiteSpace}
    ${boxSizing}
    ${cursor}
    ${objectFit}
`;

export const UnstyledButton = styled.button`
    all: unset;
    &:focus {
        outline: none !important;
    }
    ${accessibilityStyles()}
    ${flexbox}
    ${layout}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
`;

export const Container = styled(GridContainer)`
    ${layout}
    ${space}
`;

export const Col = styled(GridCol)`
    ${layout}
    ${space}
`;

export const Row = styled(GridRow)`
    ${layout}
    ${space}
`;

export const StyledLink = styled.a`
    ${layout}
    ${typography}
    ${letterSpacing}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${whiteSpace}
    ${boxSizing}
    ${cursor}
    ${wordBreak}
    display: inline-block;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.accentPrimary[0]};
    &:hover {
        color: ${({ theme }) => theme.colors.accentPrimary[0]};
    }
`;

export const Span = styled.span`
    ${layout}
    ${typography}
    ${letterSpacing}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${whiteSpace}
    ${boxSizing}
    ${cursor}   
`;

export const StyledLabel = styled.label`
    ${layout}
    ${typography}
    ${letterSpacing}
    ${space}
    ${color}
    ${position}
    ${background}
    ${border}
    ${shadow}
    ${whiteSpace}
    ${boxSizing}
    ${cursor}
`;

export const AnchorLink = styled.a`
    color: ${({ theme }) => theme.colors.accentPrimary[1]};
`;

export const HiddenBox = styled(Box)`
    visibility: hidden;
`;

export const ScreenReaderText = styled(Box)`
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    margin: -1px;
`;
