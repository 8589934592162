import InputV2 from 'atoms/Input';
import { alpha } from 'foundations/theme';
import useTheme from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';
import { border, color, flexbox, layout, space } from 'styled-system';
import { noop } from 'utils/constants/common';

const TextArea = (props) => {
    const { themeWrapper, isLightTheme, baseColor } = useTheme();
    const theme = themeWrapper(
        baseColor,
        props.lightThemeOverride || isLightTheme
    );

    const {
        onChange = noop,
        style = {},
        name = '',
        value = null,
        id = '',
        placeholder = '',
        required = false,
        labelText = '',
        maxLength,
        state = 'normal',
        errorMsg,
        minHeight,
        variant = '',
        showErrMsg = true,
        wrapperStyle = {},
        showCount = false,
        floatLabel = false,
        floatLabelText = '',
        hideReqdError = false,
        labelStyles = {},
        useValue,
        isLightAmbience,
        isThemeV2 = false,
        minRows = 1,
        ...rest
    } = props || {};

    const [val, setVal] = useState(value);
    const [isInputFocus, setInputFocus] = useState(false);

    const onValueChange = (e) => {
        setVal(e.target.value);
        onChange(e);
    };

    useEffect(() => {
        setVal(value);
    }, [value]);

    return isThemeV2 ? (
        <InputV2
            id={id}
            name={name}
            required={required}
            onChange={onValueChange}
            placeholder={placeholder}
            value={value}
            maxLength={maxLength}
            aria-required={required ? true : false}
            label={
                floatLabelText || labelText
                    ? `${floatLabelText || labelText}${required ? '*' : ''}`
                    : ''
            }
            errorMsg={errorMsg}
            asType='textarea'
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            aria-label={floatLabelText || labelText || ''}
            style={{ height: '5rem' }}
            {...rest}
        />
    ) : (
        <TextAreaWrapper
            state={state}
            style={{ ...wrapperStyle }}
            isLightTheme={theme.isLightTheme}
            floatLabel={floatLabel}
            isActive={isInputFocus}
            theme={theme}
        >
            {variant === 'expandable' ? (
                <ExpandableTextArea
                    placeholder={placeholder}
                    value={useValue ? value : val}
                    name={name}
                    onChange={onValueChange}
                    maxLength={maxLength}
                    required={required}
                    id={id}
                    state={state}
                    style={{ ...style }}
                    showerrmsg={`${showErrMsg}`}
                    showCount={showCount}
                    minRows={minRows}
                    {...rest}
                />
            ) : (
                <Textarea
                    minHeight={minHeight}
                    placeholder={placeholder}
                    value={useValue ? value : val}
                    name={name}
                    onChange={onValueChange}
                    maxLength={maxLength}
                    required={required}
                    id={id}
                    state={state}
                    style={{ ...style }}
                    showerrmsg={`${showErrMsg}`}
                    showCount={showCount}
                    floatLabel={floatLabel}
                    aria-label={floatLabelText || labelText || ''}
                    onFocus={() => setInputFocus(true)}
                    onBlur={() => setInputFocus(false)}
                    {...rest}
                />
            )}
            {floatLabel && (
                <FloatLabelDom
                    value={value}
                    state={state}
                    style={{ ...labelStyles }}
                >
                    {state === 'error'
                        ? value.length > 0
                            ? errorMsg
                            : //     : floatLabelText + ' cannot be empty'
                            // : `${floatLabelText}${required && '*'}`}
                            !hideReqdError
                            ? floatLabelText + ' cannot be empty'
                            : errorMsg
                        : `${floatLabelText}${required ? '*' : ''}`}
                </FloatLabelDom>
            )}
            {showErrMsg && !floatLabel && (
                <LabelDom className='error-label'>
                    {state === 'error' ? (
                        <InputError>
                            {required
                                ? errorMsg?.length > 0
                                    ? errorMsg
                                    : labelText + ' cannot be empty'
                                : errorMsg}
                        </InputError>
                    ) : null}
                </LabelDom>
            )}
            {showCount && maxLength && (
                <CharCount
                    isLightAmbience={isLightAmbience}
                    className='field-count'
                >
                    {val && val.length ? val.length : 0}/{maxLength}
                </CharCount>
            )}
        </TextAreaWrapper>
    );
};

export default TextArea;

const TextAreaWrapper = styled.div`
    position: relative;
    overflow: hidden;
    ${(props) =>
        props.state === 'error' &&
        `
        border-color: ${props.theme.colors.semantic[1]};
    `}

    ${({ theme, floatLabel }) =>
        floatLabel &&
        `
            border-radius: 8px;
            padding-top: 28px;
            border: ${
                theme.isLightTheme
                    ? `1px solid ${theme.colors.ambience[15]}`
                    : `1px solid ${theme.colors.ambience[14]}`
            };
            &:hover {
                border: 1px solid
                    ${
                        theme.isLightTheme
                            ? theme.colors.accentPrimary[1]
                            : theme.colors.accentPrimary[0]
                    };
            }
        `};
    ${(props) =>
        props.isActive &&
        props.floatLabel &&
        `
        border: 1px solid
            ${
                props.theme.isLightTheme
                    ? props.theme.colors.accentPrimary[1]
                    : props.theme.colors.accentPrimary[0]
            };
        box-shadow: 0px 0px 2px 2px
            ${
                props.theme.isLightTheme
                    ? alpha(props.theme.colors.accentPrimary[1], 0.4)
                    : alpha(props.theme.colors.accentPrimary[0], 0.4)
            };
    `}
`;

const CharCount = styled.span`
    position: absolute;
    z-index: 1;
    font-size: 12px;
    line-height: 20px;
    color: ${({ theme, isLightAmbience }) =>
        isLightAmbience ? theme.colors.ambience[24] : theme.colors.ambience[0]};
    font-family: ${({ theme }) => theme.fonts.book};
    top: 27px;
    right: 15px;
    transition: all 0.4s ease;
`;

const InputError = styled.span`
    color: ${({ theme }) => theme.colors.semantic[1]};
    font-size: 12px;
`;

const FloatLabelDom = styled.label`
    position: absolute;
    left: 0;
    top: 18px;
    text-align: left;
    font-weight: normal;
    font-family: ${({ theme }) => theme.fonts.book};
    z-index: 1;
    cursor: text;
    padding: 8px 20px 0;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) =>
        theme.isLightTheme
            ? theme.colors.ambience[10]
            : theme.colors.ambience[12]};
    transition: all 0.4s ease;
    pointer-events: none;

    ${InputError} {
        font-size: 16px;
        line-height: 20px;
    }
    ${(props) =>
        props.state === 'error' &&
        css`
            color: ${({ theme }) => theme.colors.semantic[1]};
        `}
`;

const Textarea = styled.textarea`
    width: 100%;
    padding: 20px;
    font-family: ${({ theme }) => theme.fonts.book};
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.ambience[6]};
    resize: none;
    box-sizing: border-box;
    outline: none;
    z-index: 1;
    position: relative;
    border-radius: 8px;
    background-color: transparent;
    &:focus {
        outline: 0;
    }
    ${({ floatLabel }) =>
        !floatLabel
            ? `
        border: ${({ theme }) =>
            theme.isLightTheme
                ? `1px solid ${theme.colors.ambience[21]}`
                : `1px solid ${theme.colors.ambience[14]}`};
        &:hover {
            border: 1px solid
                ${({ theme }) =>
                    theme.isLightTheme
                        ? theme.colors.accentPrimary[1]
                        : theme.colors.accentPrimary[0]};
        }
        &:focus {
            border: 1px solid
                ${({ theme }) =>
                    theme.isLightTheme
                        ? theme.colors.accentPrimary[1]
                        : theme.colors.accentPrimary[0]};
            box-shadow: 0px 0px 2px 2px
                ${({ theme }) =>
                    theme.isLightTheme
                        ? alpha(theme.colors.accentPrimary[1], 0.4)
                        : alpha(theme.colors.accentPrimary[0], 0.4)};
        }
    `
            : `
        border: none;
    `}
    display: block;
    &::placeholder {
        color: ${({ theme }) => theme.colors.ambience[13]};
    }
    ${(props) =>
        props.state === 'error' &&
        props.showerrmsg === 'true' &&
        `
        padding-top: 30px;
    `}

    ${(props) =>
        props.floatLabel &&
        props.value !== '' &&
        css`
            & + ${FloatLabelDom} {
                font-size: 12px;
                top: 0;
            }
            & ~ ${CharCount} {
                top: 8px;
            }
        `}
    ${(props) =>
        props.floatLabel &&
        css`
            padding-top: 0px !important;
            &:focus {
                & + ${FloatLabelDom} {
                    font-size: 12px;
                    top: 0;
                }
                & ~ ${CharCount} {
                    top: 8px;
                }
            }
        `}

    ::-webkit-scrollbar {
        width: 6px;
        border-radius: 6px;
        background: ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.ambience[22]
                : alpha(theme.colors.white, 0.1)};
    }
    ::-webkit-scrollbar-track {
        background: ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.ambience[22]
                : alpha(theme.colors.white, 0.1)};
        padding-right: 30px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.ambience[18]
                : alpha(theme.colors.white, 0.1)};
    }

    &.textarea-mobile-large {
        @media (max-width: 470px) {
            padding-top: 20px !important;
        }
    }

    ${layout}
`;

const LabelDom = styled.label`
    position: absolute;
    left: 0;
    top: 0;
    text-align: left;
    font-weight: normal;
    font-family: ${({ theme }) => theme.fonts.book};
    z-index: 0;
    cursor: text;
    padding: 10px 20px 0;
    width: 100%;
`;

const ExpandableTextArea = styled(TextareaAutosize)`
    border-radius: 8px;
    background-color: ${({ theme }) =>
        theme.isLightTheme ? theme.colors.ambience[24] : 'transparent'};
    border: ${({ theme }) =>
        theme.isLightTheme
            ? `1px solid ${theme.colors.ambience[21]}`
            : `1px solid ${theme.colors.ambience[14]}`};
    ${flexbox}
    ${color}
    ${border}
    ${space}
    ${layout}

    resize: none;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    color: ${({ theme }) => theme.colors.ambience[0]};
    outline: none;
    box-shadow: none;
    &::-webkit-scrollbar {
        width: 0px;
    }
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */

    padding: 20px;
    font-family: ${({ theme }) => theme.fonts.book};
    color: ${({ theme }) => theme.colors.ambience[6]};
    box-sizing: border-box;
    z-index: 1;
    position: relative;
    display: block;

    &:hover {
        border: 1px solid
            ${({ theme }) =>
                theme.isLightTheme
                    ? theme.colors.accentPrimary[1]
                    : theme.colors.accentPrimary[0]};
    }
    &:focus {
        border: 1px solid
            ${({ theme }) =>
                theme.isLightTheme
                    ? theme.colors.accentPrimary[1]
                    : theme.colors.accentPrimary[0]};
        box-shadow: 0px 0px 2px 2px
            ${({ theme }) =>
                theme.isLightTheme
                    ? alpha(theme.colors.accentPrimary[1], 0.4)
                    : alpha(theme.colors.accentPrimary[0], 0.4)};
    }
    &::placeholder {
        color: ${({ theme }) => theme.colors.ambience[13]};
    }
    ${(props) =>
        props.state === 'error' &&
        props.showerrmsg === 'true' &&
        `
        padding-top: 30px;
    `}

    ${(props) =>
        props.state !== 'error' &&
        `
        &:focus{
            background: ${props.theme.colors.ambience[22]};
        }
    `}
`;
