import { SET_SHOW_MODAL, SET_REMOVE_MODAL } from 'store/actions/systemCheck';

const initialState = {
    isShow: false,
    root: '',
    onClose: () => {},
    userData: {},
    isAVSettings: false,
};

function systemCheck(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case SET_SHOW_MODAL:
            return {
                ...state,
                isShow: true,
                ...payload,
            };
        case SET_REMOVE_MODAL:
            return {
                isShow: false,
                ...initialState,
            };
        default:
            return state;
    }
}

export default systemCheck;
