import { CALL_API } from '../api';

export const GET_AIRMEET_RESOURCES_RESPONSE = 'GET_AIRMEET_RESOURCES_RESPONSE';
export const GET_AIRMEET_RESOURCES_REQUEST = 'GET_AIRMEET_RESOURCES_REQUEST';
export const GET_SESSION_RESOURCES_REQUEST = 'GET_SESSION_RESOURCES_REQUEST';
export const GET_SESSION_RESOURCES_RESPONSE = 'GET_SESSION_RESOURCES_RESPONSE';

export function getEventResources(airmeetId) {
    return {
        [CALL_API]: {
            types: [
                GET_AIRMEET_RESOURCES_REQUEST,
                GET_AIRMEET_RESOURCES_RESPONSE,
            ],
            endpoint: `/resource?entityId=${airmeetId}&entityType=AIRMEET`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function getSessionResources(sessionId) {
    return {
        [CALL_API]: {
            types: [
                GET_SESSION_RESOURCES_REQUEST,
                GET_SESSION_RESOURCES_RESPONSE,
            ],
            endpoint: `/resource?entityId=${sessionId}&entityType=SESSION`,
            type: 'json',
            method: 'GET',
            extra: { sessionId: sessionId },
        },
    };
}
