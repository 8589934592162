import { CLOUD_FN_REPLICATING_FEATURES } from 'hooks/attendance/replicaFeatures';
import { logger } from 'utils/logger';
import FirebaseClient from './FirebaseClient';

let attachedWritesProxy = false;
export default class WriterReplicaFirebaseClient extends FirebaseClient {
    private writer: Function;
    private primary: boolean;
    private timeStampListenerInit: boolean;
    private shardId: string;

    constructor(
        appName = 'liveAirmeet',
        appInfo,
        flagInfo,
        firebaseProxyConfig,
        writer: Function,
        primary: boolean
    ) {
        super(appName, appInfo, flagInfo, firebaseProxyConfig);
        this.writer = writer;
        this.primary = primary;
        this.shardId = appInfo.shardId;
    }

    async init(authId, airmeetId) {
        this.authId = authId;
        this.airmeetId = airmeetId;
        if (this.primary) {
            await super.init(authId, airmeetId);
        }
        this.attachWritesProxy();
    }

    resetWritesProxy() {
        if (attachedWritesProxy) {
            attachedWritesProxy = false;
        }
    }

    attachWritesProxy() {
        if (attachedWritesProxy) {
            logger.info('Writes proxy already attached, returning');
            return;
        }
        // @ts-ignore: proxy attach
        const putInternal = this.database.repo_.persistentConnection_
            .putInternal;
        // @ts-ignore: proxy attach
        this.database.repo_.persistentConnection_.putInternal = (
            action,
            channel,
            data,
            ...args
        ) => {
            putInternal.call(
                // @ts-ignore: proxy attach
                this.database.repo_.persistentConnection_,
                action,
                channel,
                data,
                ...args
            );
            this.writeToReplicas(action, channel, data);
        };
        attachedWritesProxy = true;
    }

    async getCurrentTimeStampDifference(...args) {
        if (!this.timeStampListenerInit && !this.primary) {
            super._initTimestampOffsetListener();
            this.timeStampListenerInit = true;
        }
        // @ts-ignore
        return super.getCurrentTimeStampDifference(...args);
    }

    writeToReplicas(action, channel, data) {
        const replicatingFeatures = CLOUD_FN_REPLICATING_FEATURES;

        if (action === 'm') {
            logger.warn('Replicate modify action', {
                channel,
                data,
            });
            if (channel.includes('reports')) {
                return;
            }
        }

        for (let index = 0; index < replicatingFeatures.length; index++) {
            let path = replicatingFeatures[index].path.replace(
                '{airmeetId}',
                this.airmeetId
            );
            if (channel.startsWith(path)) {
                return;
            }
        }

        this.writer(data, {
            channel,
            action,
            sourceShard: this.shardId,
        });
    }
}
