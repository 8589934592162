import RadioGroupV2 from 'atoms/Radio/RadioGroupV2';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text/';
import cloneDeep from 'lodash/cloneDeep';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { getCurrentTenantData } from '../../../../../../../utils/tenant';
import ColorBackground from './ColorBackground';
import ImageBackground from './ImageBackground';
import PatternBackground from './PatternBackground';
import {
    COLOR_BACKGROUND_TYPE_OPTIONS,
    DEFAULT_BACKGROUND_TYPE,
} from './utils';

const primaryColor = getCurrentTenantData('primaryColor');
const defaultColor = `hsla(${primaryColor.H},${primaryColor.S}%,${primaryColor.L}%,1)`;
const defaultColor2 = 'hsla(276,56%,60%,1)';

export default function CustomizeBackground({
    value,
    type,
    prop,
    onChange,
    is_light_theme,
    setAmbienceSuggestionVisible,
    colorBackgroundTypes = COLOR_BACKGROUND_TYPE_OPTIONS,
    title = null,
    subtitle = null,
    hideImage = false,
    showOpacity = false,
}) {
    const backgroundType = useMemo(
        () => value?.type || DEFAULT_BACKGROUND_TYPE,
        [value]
    );

    const handleChange = (t, val, k, isMeta = false, metadata = {}) => {
        if (onChange && backgroundType) {
            let finalValue = {};
            if (isMeta) {
                finalValue = {
                    ...(value || {}),
                    type: backgroundType,
                    meta: val,
                };
            } else {
                finalValue = {
                    ...(value || {}),
                    type: backgroundType,
                    value: val,
                    meta: { ...(value?.meta || {}), ...(metadata || {}) },
                };
            }
            onChange(type, finalValue, prop);
        }
    };

    const handleFinalValueChange = (value) => {
        if (onChange && backgroundType) {
            const finalValue = {
                ...(value || {}),
                type: backgroundType,
            };
            onChange(type, finalValue, prop);
        }
    };

    const handleBackgroundTypeChange = (backgroundType) => {
        // Reset value and set background
        const finalValue = {
            ...(value || {}),
            type: backgroundType,
            value:
                backgroundType === 'solidColor'
                    ? defaultColor
                    : backgroundType === 'radialGradient' ||
                      backgroundType === 'linearGradient'
                    ? [defaultColor, defaultColor2]
                    : null,
        };
        onChange(type, cloneDeep(finalValue), prop);
    };

    return (
        <Wrapper flexDirection='column' alignItems='stretch'>
            {title && (
                <Text
                    variant='h5'
                    color='text.default.primary'
                    pb={subtitle ? '4px' : '16px'}
                >
                    {title}
                </Text>
            )}
            {subtitle && (
                <Text
                    variant='caption'
                    color='text.default.secondary'
                    pb='16px'
                >
                    {subtitle}
                </Text>
            )}
            <RadioGroupV2
                options={[
                    { value: DEFAULT_BACKGROUND_TYPE, label: 'None' },
                    {
                        value:
                            colorBackgroundTypes.length > 1
                                ? (colorBackgroundTypes || []).find(
                                      (type) => type?.id === backgroundType
                                  )
                                    ? backgroundType
                                    : 'solidColor'
                                : colorBackgroundTypes[0]?.id,
                        label:
                            colorBackgroundTypes.length > 1
                                ? 'Color'
                                : colorBackgroundTypes[0]?.label,
                        activeChildren: (
                            <ColorBackground
                                backgroundType={backgroundType}
                                onBackgroundTypeChange={
                                    handleBackgroundTypeChange
                                }
                                onChange={handleChange}
                                value={value}
                                is_light_theme={is_light_theme}
                                setAmbienceSuggestionVisible={
                                    setAmbienceSuggestionVisible
                                }
                                types={colorBackgroundTypes}
                            />
                        ),
                    },
                    {
                        value: 'pattern',
                        label: 'Pattern',
                        activeChildren: (
                            <PatternBackground
                                onChange={handleFinalValueChange}
                                value={value}
                                showOpacity={showOpacity}
                            />
                        ),
                    },
                    hideImage
                        ? {}
                        : {
                              value: 'image',
                              label: 'Image',
                              activeChildren: (
                                  <ImageBackground
                                      onChange={handleFinalValueChange}
                                      value={value}
                                  />
                              ),
                          },
                ]}
                onValueChange={handleBackgroundTypeChange}
                value={backgroundType}
            />
        </Wrapper>
    );
}

const Wrapper = styled(FlexDiv)`
    .RadioGroupRoot {
        .LabelRoot {
            margin-bottom: 1em;
        }
    }
    .custom-upload {
        background-color: transparent !important;
    }
`;
