import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import useTheme from 'hooks/useTheme';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import ChartCard from '../components/ChartCard';
import Pill from '../components/Pill';
import {
    getCommonBarOptions,
    getEmptyBannerCommonProps,
    topBarOptions,
} from '../utils';

const getChartContent = ({ canvas, chartLabels, chartData, isEmpty }) => {
    let backgroundColor = '#26252D';
    if (!isEmpty) {
        const context = canvas.getContext('2d');
        const grad = context.createLinearGradient(0, 100, 600, 100);
        grad.addColorStop(0.25, 'rgba(83, 50, 230, 1)');
        grad.addColorStop(1, 'rgba(164, 95, 210, 1)');
        backgroundColor = grad;
    }

    return {
        labels: chartLabels || [],
        datasets: [
            {
                data: chartData,
                backgroundColor,
                borderWidth: 0,
            },
        ],
    };
};

export default function RegistrationDetailsCard({
    loading,
    data,
    airmeetFormat,
}) {
    const { theme } = useTheme();
    const [selectedField, setSelectedField] = useState();

    const fieldNames = useMemo(() => Object.keys(data || {}), [data]);
    useEffect(() => {
        if (Array.isArray(fieldNames) && fieldNames.length > 0) {
            setSelectedField(fieldNames[0]);
        }
    }, [fieldNames]);

    const {
        chartLabels,
        chartData,
        chartLabels2,
        chartData2,
        isEmpty,
    } = useMemo(() => {
        if (selectedField) {
            const fieldsItems = get(data, selectedField) || {};
            const fieldData = orderBy(
                Object.keys(fieldsItems).map((key) => ({
                    key,
                    count: fieldsItems[key],
                })),
                ['count', 'key'],
                ['desc', 'asc']
            );

            let chartLabels = [];
            let chartData = [];
            let chartLabels2 = [];
            let chartData2 = [];
            fieldData.forEach((item, index) => {
                if (index < 5) {
                    chartLabels.push(item.key);
                    chartData.push(item.count);
                } else if (index < 10) {
                    chartLabels2.push(item.key);
                    chartData2.push(item.count);
                }
            });

            const isEmpty = !Array.isArray(chartData) || chartData.length === 0;

            if (isEmpty) {
                chartLabels = Array(5).fill('');
                chartLabels2 = Array(5).fill('');

                chartData = Array(5).fill(5);
                chartData2 = Array(5).fill(5);
            }

            return {
                chartLabels,
                chartData,
                chartLabels2,
                chartData2,
                isEmpty,
            };
        }

        return {};
    }, [data, selectedField]);

    const chartContent = useCallback(
        (canvas) =>
            getChartContent({ canvas, chartLabels, chartData, isEmpty }),
        [chartData, chartLabels, isEmpty]
    );

    const chartContent2 = useCallback(
        (canvas) => {
            if ((chartData2 || []).length > 0) {
                return getChartContent({
                    canvas,
                    chartLabels: chartLabels2,
                    chartData: chartData2,
                    isEmpty,
                });
            }

            return null;
        },
        [chartData2, chartLabels2, isEmpty]
    );

    const chartOptions = useMemo(() => {
        const options = {
            ...getCommonBarOptions({
                yTicksDisplay: false,
                xAxesMax: isEmpty
                    ? 300
                    : Array.isArray(chartData) && chartData.length > 0
                    ? chartData[0]
                    : 0,
                theme,
            }),
            ...topBarOptions({ theme }),
            ...(isEmpty
                ? {
                      animation: {},
                      tooltips: {
                          enabled: false,
                      },
                  }
                : {}),
        };

        return options;
    }, [isEmpty, chartData, theme]);

    return (
        <ChartCard
            title='Registration Details'
            loading={loading}
            isEmpty={isEmpty}
            emptyBannerProps={getEmptyBannerCommonProps({ airmeetFormat })}
        >
            <FlexDiv flexDirection='column' flexGrow='1'>
                <FlexDiv alignItems='center' py={3}>
                    {fieldNames.map((fieldName) => (
                        <Pill
                            title={fieldName}
                            mr={2}
                            selected={selectedField === fieldName}
                            onClick={() => setSelectedField(fieldName)}
                            key={fieldName}
                        />
                    ))}
                </FlexDiv>
                <Box className='row' flexGrow='1'>
                    <Box className='col-sm-6'>
                        <Box
                            style={{
                                flexGrow: 1,
                                minHeight: '270px',
                            }}
                        >
                            <HorizontalBar
                                data={chartContent}
                                options={chartOptions}
                            />
                        </Box>
                    </Box>
                    {(chartData2 || []).length > 0 && (
                        <Box className='col-sm-6'>
                            <Box
                                style={{
                                    flexGrow: 1,
                                    minHeight: '270px',
                                }}
                            >
                                <HorizontalBar
                                    data={chartContent2}
                                    options={chartOptions}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </FlexDiv>
        </ChartCard>
    );
}
