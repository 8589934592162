import * as Label from '@radix-ui/react-label';
import * as Switch from '@radix-ui/react-switch';
import {
    FlexDiv,
    ScreenReaderText,
} from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text/';
import React from 'react';
import styled from 'styled-components';

const ToggleSwitch = ({
    checked,
    id,
    uncheckedLabel,
    checkedLabel,
    size,
    customLabel,
    ...rest
}) => (
    <Wrapper>
        <SwitchRoot
            className='SwitchRoot'
            checked={checked}
            id={id}
            size={size}
            {...rest}
        >
            <SwitchThumb className='SwitchThumb' size={size} />
        </SwitchRoot>
        {uncheckedLabel && (
            <Label.Root
                className='LabelRoot'
                htmlFor={!customLabel ? id : null}
            >
                <Text variant={size === 'small' ? 'caption10' : 'caption'}>
                    {checked ? checkedLabel : uncheckedLabel}
                </Text>
            </Label.Root>
        )}
        {customLabel && (
            <ScreenReaderText>
                <Label.Root htmlFor={id}>{customLabel}</Label.Root>
            </ScreenReaderText>
        )}
    </Wrapper>
);

export default ToggleSwitch;

const Wrapper = styled(FlexDiv)`
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    .LabelRoot {
        color: ${({ theme }) => theme.colors.text.default.primary};
        padding-right: ${({ theme }) => theme.space.x2};
        margin-bottom: 0;
    }
`;

const SwitchRoot = styled(Switch.Root)`
    width: 44px;
    height: 24px;
    background-color: ${({ theme }) => theme.colors.fill.default2};
    border: solid 1px ${({ theme }) => theme.colors.border.other1};
    border-radius: ${({ theme }) => theme.radii.x4};
    position: relative;
    padding: 0;

    &[data-state='checked'] {
        background-color: ${({ theme }) => theme.colors.brandDefault};
        border-color: transparent;
    }

    &:disabled {
        background-color: ${({ theme }) => theme.colors.fill.other3};
        border-color: transparent;
        color: ${({ theme }) => theme.colors.text.default.disabled};
        box-shadow: none;
        cursor: not-allowed;
    }
    &:disabled + label p {
        color: ${({ theme }) => theme.colors.text.default.disabled};
    }

    ${({ size }) =>
        size === 'small' &&
        `
        width: 28px;
        height: 16px;
    `}
`;

const SwitchThumb = styled(Switch.Thumb)`
    display: block;
    width: 16px;
    height: 16px;
    background-color: ${({ theme }) => theme.colors.fill.other2};
    border-radius: 100%;
    transition: transform 100ms;
    transform: translateX(3px);
    will-change: transform;

    &[data-state='checked'] {
        transform: translateX(24px);
        background-color: ${({ theme }) => theme.colors.fill.default1};
    }

    &[data-disabled] {
        background-color: ${({ theme }) => theme.colors.fill.other3};
    }

    ${({ size }) =>
        size === 'small' &&
        `
        width: 12px;
        height: 12px;
        transform: translateX(2px);
        
        &[data-state='checked'] {
            transform: translateX(13px);
        }
    `}
`;
