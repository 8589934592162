import LRUCache from 'quick-lru';
import { callApi } from 'utils/apiService';
import { bindModuleLogger } from 'utils/logger';
import { UserIdDetails, UserIdServiceParams } from './types';
import {
    isCustomMediaStream,
    isScreenShareStream,
    getScreenShareUserId,
    getCustomMediaUserId,
} from 'utils/constants/live-airmeet';
const logger = bindModuleLogger('User ID Service');

type PendingUserDetailsResults = {
    promise: Promise<UserIdDetails>;
    resolve: (details: UserIdDetails) => void;
    reject: (error: Error) => void;
    waitingTimer: number;
};

const WAIT_BEFORE_FETCH = 100;
export function getUserIdSeq(requestIdSeq: any): number {
    const isScreenShare = isScreenShareStream(requestIdSeq);
    const isCustomMedia = isCustomMediaStream(requestIdSeq);

    const id_seq = isScreenShare
        ? getScreenShareUserId(requestIdSeq)
        : isCustomMedia
        ? getCustomMediaUserId(requestIdSeq)
        : requestIdSeq;

    return parseInt(id_seq);
}

export default class UserIdService {
    private static service: UserIdService;
    static getInstance(params: UserIdServiceParams): UserIdService {
        if (UserIdService.service) {
            return UserIdService.service;
        }
        UserIdService.service = new UserIdService(params);
        return UserIdService.service;
    }

    private params: UserIdServiceParams;
    private cache: LRUCache<number, PendingUserDetailsResults>;

    constructor(params: UserIdServiceParams) {
        logger.debug('Initialized user id service');
        this.cache = new LRUCache({ maxSize: 500 });
        this.params = params;
    }

    async getId(requestIdSeq: number): Promise<UserIdDetails> {
        const idSeq = getUserIdSeq(requestIdSeq);

        if (this.cache.has(idSeq)) {
            return this.cache.get(idSeq).promise;
        }

        let pendingResult = {
            promise: null,
            resolve: null,
            reject: null,
            waitingTimer: -1,
        };

        this.cache.set(idSeq, pendingResult);
        let promise = new Promise<UserIdDetails>((resolve, reject) => {
            this.cache.get(idSeq).resolve = (...args) => {
                let waitingTimer = this.cache.get(idSeq).waitingTimer;
                if (waitingTimer >= 0) {
                    clearTimeout(waitingTimer);
                }
                resolve(...args);
            };
            this.cache.get(idSeq).reject = reject;

            this.startFallbackTimer(idSeq);
        });

        this.cache.get(idSeq).promise = promise;
        return promise;
    }

    startFallbackTimer(idSeq: number) {
        if (this.cache.get(idSeq).waitingTimer > 0) {
            // Someone already started loading
            return;
        }
        this.cache.get(idSeq).waitingTimer = setTimeout(() => {
            this.fetch(idSeq);
        }, WAIT_BEFORE_FETCH);
    }

    private async fetch(requestIdSeq: number) {
        const idSeq = getUserIdSeq(requestIdSeq);

        const URL = `/airmeet/${this.params.airmeetId}/users?filter=${idSeq}&type=id`;

        try {
            let results = await callApi({
                endpoint: URL,
            });
            if (results[0]) {
                this.onUserIdDetailsLoaded(idSeq, {
                    id: results[0].id,
                    metadata: results[0],
                });
            } else {
                logger.error('User not found by id seq', idSeq);
                this.cache.get(idSeq).reject(new Error('Not found'));
            }
        } catch (e) {
            logger.error('Failed to fetch user by id seq', idSeq, e);
            this.cache.get(idSeq).reject(e);
        }
    }

    public onUserIdDetailsLoaded(idSeq: any, details: UserIdDetails) {
        const id_seq = getUserIdSeq(idSeq);
        if (isNaN(id_seq)) {
            return;
        }
        if (details?.id) {
            if (false === this.cache.has(id_seq)) {
                this.getId(id_seq); //initial cache entry
            }
            this.cache.get(id_seq).resolve(details); // mark cache promise as resolved and clear fallback timer
        }
    }
}
