import { RESET_STORE } from '../actions/emailLogin';
import {
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
} from '../actions/forgotPassword';

const initialState = {
    error: null,
    emailSent: false,
    reset: false,
    processing: false,
};

function forgotPassword(state = initialState, { type, payload }) {
    switch (type) {
        case FORGOT_PASSWORD_REQUEST:
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                error: null,
                processing: true,
            };
        case FORGOT_PASSWORD_SUCCESS:
            if (payload instanceof Error) {
                return {
                    ...state,
                    error: payload.json.message,
                    processing: false,
                };
            }

            return {
                ...state,
                emailSent: true,
                processing: false,
            };
        case RESET_PASSWORD_SUCCESS:
            if (payload instanceof Error) {
                return {
                    ...state,
                    error: payload.json.message,
                    processing: false,
                };
            }

            return {
                ...state,
                reset: true,
                processing: false,
            };
        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
}

export default forgotPassword;
