import React from 'react';
import {
    isAndroid,
    isIOS,
    isIPad13,
    isMobile,
    isTablet,
} from 'react-device-detect';
import UAParser from 'ua-parser-js';
import { isSafari } from 'utils/browserCheck';

const AppContext = React.createContext(null);

export function AppProvider({ children }) {
    const parser = new UAParser();
    const deviceDetail = parser.getResult();
    const Tablet =
        deviceDetail.device.type === 'tablet' || isTablet || isIPad13;

    const isIPadLessThan13 =
        isTablet &&
        deviceDetail.os.name === 'iOS' &&
        +deviceDetail.os.version.slice(0, 2) <= 13;

    const isIpadSafari13 =
        isTablet &&
        deviceDetail.os.name === 'Mac OS' &&
        deviceDetail.os.version === '10.15.4';

    const isIpadLessThanEqualTo13 = isIPadLessThan13 || isIpadSafari13;
    const isIPad = deviceDetail.device.model === 'iPad';

    const deviceInfo = {
        ...deviceDetail,
        browserName: deviceDetail.browser.name,
        browserMajor: deviceDetail.browser.major,
        isMobile: isMobile && !Tablet,
        isTablet: Tablet,
        isAndroid: isAndroid,
        deviceType: deviceDetail.device.type,
        isDesktopMode: !isMobile && !isTablet,
        isIOS,
        isIpadLessThanEqualTo13,
        isIPad,
        isSafari: Tablet ? deviceDetail.browser.name === 'Safari' : isSafari(),
        isMacOs: deviceDetail.os.name === 'Mac OS',
    };

    const removeDeviceViewClass = () => {
        const bodyNode = document.body;
        bodyNode.classList.remove('mobi-view', 'web-view');
    };

    const setDeviceViewClass = (options) => {
        const bodyNode = document.body;
        if (isMobile && !Tablet) {
            bodyNode.classList.add('mobi-view');
        } else {
            bodyNode.classList.add('web-view');
        }

        if (options && options.tabletReset) {
            if (Tablet) {
                const bodyNode = document.body;
                bodyNode.classList.add('web-view');
            }
        }
    };

    const data = {
        deviceInfo,
        removeDeviceViewClass,
        setDeviceViewClass,
    };

    return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
}

export default AppContext;
