import { useLiveSessionContext } from 'context/Session';
import { useStageConfig } from 'context/StageConfigProvider';
import { useShallowEqualSelector } from 'hooks/common';
import useDataWriter from 'hooks/useDataWriter';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import useSpeakerOrders from 'hooks/useSpeakerOrders';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import { useLiveStreamsContext } from './LiveStreams';

const { STAGE_LAYOUT } = FEATURE_NAMES;
export const LAYOUTS = {
    USERS: 'users', // active speaker and non active speaker
    CONTENT: 'content', // for screen share and pre-recorded video
};

export const LAYOUT_MODES = {
    ACTIVE_SPEAKER: 'activeSpeaker',
    GRID: 'grid',
    ONE_ON_ONE: 'oneOnOne',
    SIDE_BAR: 'sideBar',
    SPOTLIGHT: 'Spotlight',
    NEWS: 'news',
};

export const STAGE_INTERNAL_VIEWS = {
    PINNED_SPEAKERS: 'bottomBarMultipleUsers',
    BOTTOM_BAR: 'bottomBarSingleUser',
    MATRIX: 'matrix',
    HEAD_TO_HEAD_CONTENT: 'HEAD_TO_HEAD_CONTENT',
    HEAD_TO_HEAD: 'HeadToHead',
    SINGLE_SPEAKERS: 'SINGLE_SPEAKERS',
    SIDE_ACTIVE_SPEAKERS: 'sideActiveSpeakers',
    SIDE_SPEAKERS: 'sideSpeakers',
    FOCUS_CONTENT: 'focusContent',
    USER_WITH_CONTENT: 'userWithContent',
};

export const STAGE_VIEWS = {
    BOTTOM_BAR: 'bottomBarSingleUser',
    MATRIX: 'matrix',
    SIDE_SPEAKERS: 'sideSpeakers',
    HEAD_TO_HEAD: 'HeadToHead',
    FOCUS: 'focus',
    USER_WITH_CONTENT: 'userWithContent',
};

export const STAGE_VIEW_MAPPING = {
    [STAGE_INTERNAL_VIEWS.PINNED_SPEAKERS]: STAGE_VIEWS.BOTTOM_BAR,
    [STAGE_INTERNAL_VIEWS.BOTTOM_BAR]: STAGE_VIEWS.BOTTOM_BAR,
    [STAGE_INTERNAL_VIEWS.MATRIX]: STAGE_VIEWS.MATRIX,
    [STAGE_INTERNAL_VIEWS.HEAD_TO_HEAD]: STAGE_VIEWS.HEAD_TO_HEAD,
    [STAGE_INTERNAL_VIEWS.SINGLE_SPEAKERS]: STAGE_VIEWS.FOCUS,
    [STAGE_INTERNAL_VIEWS.SIDE_ACTIVE_SPEAKERS]: STAGE_VIEWS.SIDE_SPEAKERS,
    [STAGE_INTERNAL_VIEWS.SIDE_SPEAKERS]: STAGE_VIEWS.SIDE_SPEAKERS,
    [STAGE_INTERNAL_VIEWS.FOCUS_CONTENT]: STAGE_VIEWS.FOCUS,
    [STAGE_INTERNAL_VIEWS.USER_WITH_CONTENT]: STAGE_VIEWS.USER_WITH_CONTENT,
    [STAGE_INTERNAL_VIEWS.HEAD_TO_HEAD_CONTENT]: STAGE_VIEWS.HEAD_TO_HEAD,
};

export const DEFAULT_USERS_BLOCKS_ON_STAGE = {
    [STAGE_INTERNAL_VIEWS.PINNED_SPEAKERS]: 30,
    [STAGE_INTERNAL_VIEWS.BOTTOM_BAR]: 30,
    [STAGE_INTERNAL_VIEWS.MATRIX]: 12,
    [STAGE_INTERNAL_VIEWS.SINGLE_SPEAKERS]: 1,
    [STAGE_INTERNAL_VIEWS.SIDE_ACTIVE_SPEAKERS]: 1,
    [STAGE_INTERNAL_VIEWS.HEAD_TO_HEAD_CONTENT]: 1,
    [STAGE_INTERNAL_VIEWS.HEAD_TO_HEAD]: 2,
    [STAGE_INTERNAL_VIEWS.SIDE_SPEAKERS]: 20,
    [STAGE_INTERNAL_VIEWS.USER_WITH_CONTENT]: 1,
};

export const USER_LAYOUTS = [
    {
        mode: LAYOUT_MODES.GRID,
        display: true,
        icon: 'stage_grid_layout',
        selected: 'stage_grid_layout_white',
        analyticEvent: 'gridLayout',
        type: LAYOUTS.USERS,
        isActive: false,
    },
    {
        mode: LAYOUT_MODES.ACTIVE_SPEAKER,
        display: true,
        icon: 'stage_active_speaker',
        selected: 'stage_active_speaker_white',
        analyticEvent: 'activeSpeakerLayout',
        type: LAYOUTS.USERS,
        isActive: true,
    },
];

export const CONTENT_LAYOUTS = [
    {
        mode: LAYOUT_MODES.SIDE_BAR,
        display: true,
        icon: 'stage_side_speaker',
        selected: 'stage_side_speaker_white',
        analyticEvent: 'sidebarLayout',
        type: LAYOUTS.CONTENT,
    },
    {
        mode: LAYOUT_MODES.SPOTLIGHT,
        display: true,
        icon: 'stage_content_layout',
        selected: 'stage_content_layout_white',
        analyticEvent: 'contentLayout',
        type: LAYOUTS.CONTENT,
    },
    {
        mode: LAYOUT_MODES.NEWS,
        display: true,
        icon: 'stage_news_layout',
        selected: 'stage_news_layout_white',
        analyticEvent: 'newsLayout',
        type: LAYOUTS.CONTENT,
    },
    {
        mode: LAYOUT_MODES.ONE_ON_ONE,
        display: true,
        icon: 'stage_ooo_layout',
        selected: 'stage_ooo_layout_white',
        analyticEvent: 'oneOnOne',
        type: LAYOUTS.CONTENT,
    },
];

export const getLayoutsForHostControl = () => {
    const layoutsForDisplay = [...USER_LAYOUTS, ...CONTENT_LAYOUTS].filter(
        (i) => i.display
    );
    return layoutsForDisplay;
};

const StageLayout = React.createContext(null);
const closedCaptionsEnabledSelector = ({
    closedCaptions: { isEnabled, isLoaded },
}) => isEnabled && isLoaded;

export function StageLayoutProvider({ children }) {
    const isClosedCaptionsEnabled = useShallowEqualSelector(
        closedCaptionsEnabledSelector
    );

    const activeSpeakerStreams = useLiveStreamsContext(
        (ctx) => ctx.activeSpeakerStreams
    );
    const { selectedLayout } = useStageLayoutMode();
    const haveActiveSpeakerStreams = useMemo(
        () => Object.keys(activeSpeakerStreams).length > 0,
        [activeSpeakerStreams]
    );

    const isSelectedLayoutActive = selectedLayout?.[LAYOUTS.USERS]?.isActive;
    const enableActiveSpeakerMode = useMemo(() => {
        return (
            haveActiveSpeakerStreams &&
            (isClosedCaptionsEnabled || isSelectedLayoutActive)
        );
    }, [
        isClosedCaptionsEnabled,
        isSelectedLayoutActive,
        haveActiveSpeakerStreams,
    ]);
    const streams = useLiveStreamsContext((ctx) => ctx.streams);
    const { isPipMode } = useStageConfig();
    const props = {
        streams,
        enableActiveSpeakerMode,
        isPipMode,
        selectedLayout,
        children,
    };
    return <StageLayoutProviderV2 {...props} />;
}

function StageLayoutProviderV2({
    streams,
    enableActiveSpeakerMode,
    selectedLayout,
    isPipMode,
    raiseHandActiveUsers,
    children,
}) {
    const {
        gridStreams,
        galleryStreams,
        pictureInPicture,
        layout,
        maxStageUsersLimit,
        isContentShared,
        isFullScreenControlOnMediaContent,
        enabledShowMoreForLayout,
    } = useSpeakerOrders(streams, selectedLayout, isPipMode);
    const props = {
        enableActiveSpeakerMode,
        selectedLayout,
        gridStreams,
        galleryStreams,
        pictureInPicture,
        layout,
        maxStageUsersLimit,
        isContentShared,
        isFullScreenControlOnMediaContent,
        enabledShowMoreForLayout,
        raiseHandActiveUsers,
    };
    return (
        <StageLayout.Provider value={props}>{children}</StageLayout.Provider>
    );
}

export default StageLayout;

/**
 * Accepts a selector function which can be used to select a value at any
 * level of the StageLayout context, like the `useSelector` hook from redux
 *
 * @param {(context) => {}} callback
 */
export function useStageLayoutContext(callback = (context) => context) {
    // The live airmeet context
    const ctx = useContext(StageLayout);

    // Return the up-to-date selected value
    return callback(ctx);
}

const defaultLayout = {
    [LAYOUTS.USERS]: {
        isActive: false,
    },
    [LAYOUTS.CONTENT]: {
        isActive: false,
        layoutView: LAYOUT_MODES.SIDE_BAR,
    },
};
export function useStageLayoutMode() {
    const [selectedLayout, setLayoutMode] = useState(defaultLayout);
    const stageRoomNode = useLiveSessionContext((ctx) => ctx?.stageRoomNode);
    const stageId = useLiveSessionContext((ctx) => ctx?.stageId);

    const channelLayout = stageRoomNode
        ? `${stageRoomNode}/layout/layoutMode`
        : null;

    const firebaseClient = useLiveAirmeetContext((ctx) => ctx.firebaseClient);

    const { write: dataWriter } = useDataWriter(FEATURE_NAMES.STAGE_LAYOUT);

    useEffect(() => {
        const updateLayout = ({ value }) => {
            setLayoutMode(value || defaultLayout);
        };
        if (channelLayout) {
            firebaseClient.getDataSync(channelLayout, updateLayout);

            return () => {
                firebaseClient.clearDataSync(channelLayout, updateLayout);
            };
        }
    }, [channelLayout, firebaseClient]);

    const changeLayoutMode = useCallback(
        (updateLayout, isContentShared) => {
            dataWriter(updateLayout, {
                actionType: FEATURE_ACTIONS[STAGE_LAYOUT].CHANGE_LAYOUT_MODE,
                stageId,
            });
            // if layout is grid then unpin all the users
            if (!isContentShared) {
                if (updateLayout?.[LAYOUTS.USERS]?.isActive) {
                    dataWriter(null, {
                        actionType: FEATURE_ACTIONS[STAGE_LAYOUT].FOCUS,
                        stageId,
                    });
                    dataWriter(LAYOUT_MODES.ACTIVE_SPEAKER, {
                        actionType: FEATURE_ACTIONS[STAGE_LAYOUT].CHANGE_LAYOUT,
                        stageId,
                    });
                } else if (!updateLayout?.[LAYOUTS.USERS]?.isActive) {
                    dataWriter(LAYOUT_MODES.GRID, {
                        actionType: FEATURE_ACTIONS[STAGE_LAYOUT].CHANGE_LAYOUT,
                        stageId,
                    });
                }
            }
        },
        [stageId]
    );

    return {
        selectedLayout,
        setLayoutMode: changeLayoutMode,
    };
}
