import { createSelectOption, renderColorStyles } from 'atoms/Select/common';
import useTheme from 'hooks/useTheme';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';
import { noComponent, noop } from 'utils/constants/common';
import isEmail from 'utils/isEmail';

const EMAIL_ERROR = {
    INVALID_EMAIL_ADDRESS: 'INVALID_EMAIL_ADDRESS',
    MAX_LIMIT_REACHED: 'MAX_LIMIT_REACHED',
    DUPLICATE_EMAIL: 'DUPLICATE_EMAIL',
};

const getUniqueValueArray = (array, propertyName = 'value') => {
    const uniqueArray = array.filter(
        (data, index, self) =>
            index ===
            self.findIndex((t) => t[propertyName] === data[propertyName])
    );

    return { uniqueArray, hasDuplicates: array.length !== uniqueArray.length };
};

const EmailCreatable = ({
    customStyles = {},
    value,
    setValue,
    inputValue,
    setInputValue,
    maxLimit,
    onChange: handleChange = noop,
    onInputChange: handleInputChange = noop,
    onCreateOption: handleCreateOption = noop,
    handleError = noop,
    resetError = noop,
    ...rest
}) => {
    const { theme } = useTheme();
    const onChange = (data, actionMeta) => {
        if (actionMeta?.action === 'select-option') {
            setInputValue(inputValue);
            return;
        }

        const newUpdatedValue = (data || []).map((el) => {
            const label = (el.__isNew__ ? el.value : el.label).trim();
            return {
                ...el,
                value: el.value.trim(),
                label,
            };
        });

        const { uniqueArray } = getUniqueValueArray(newUpdatedValue);

        if (maxLimit) {
            (uniqueArray || []).splice(maxLimit);
        }

        handleChange(uniqueArray);
        setValue(uniqueArray);
        resetError();
    };
    const onInputChange = (data, actionMeta) => {
        if (actionMeta.action !== 'input-change') return;
        if (value.length === maxLimit) {
            handleError({
                type: EMAIL_ERROR.MAX_LIMIT_REACHED,
                message: `You can enter a maximum of ${maxLimit} email addresses.`,
            });
            return;
        }

        // Check for space/comma in the input
        if (data?.trim() && /[\s,]+/.test(data)) {
            const values = data.split(/[\s,]+/);
            onCreateMultiOptions(values);
        } else {
            setInputValue(data?.trim());
        }
        handleInputChange(data);
        resetError();
    };

    const onCreateMultiOptions = (values) => {
        const emails = values.filter((val) => isEmail(val.trim()));
        if (values.length && emails.length) {
            const newEmailOptions = emails.map((email) =>
                createSelectOption(email)
            );
            setValue((prev) => {
                const { uniqueArray, hasDuplicates } = getUniqueValueArray([
                    ...prev,
                    ...newEmailOptions,
                ]);
                if (hasDuplicates) {
                    if (emails.length === 1) {
                        setInputValue(emails[0]);
                        handleError({
                            type: EMAIL_ERROR.DUPLICATE_EMAIL,
                            message: 'Email ID is already added.',
                        });
                    } else {
                        handleError({
                            type: EMAIL_ERROR.DUPLICATE_EMAIL,
                            message:
                                'Duplicate emails has been removed from the input.',
                        });
                        setInputValue('');
                    }
                } else {
                    resetError();
                    setInputValue('');
                }
                return maxLimit ? uniqueArray.slice(0, maxLimit) : maxLimit;
            });
        } else {
            setInputValue(values[0]);
            handleError({
                type: EMAIL_ERROR.INVALID_EMAIL_ADDRESS,
                message:
                    'Check if you have typed your email address correctly.',
            });
        }
    };

    const onCreateOption = (data) => {
        const trimmedValue = (data || '').trim();
        if (!trimmedValue) {
            return;
        }
        if (isEmail(trimmedValue)) {
            setValue((prev) => {
                const { uniqueArray, hasDuplicates } = getUniqueValueArray([
                    ...prev,
                    createSelectOption(trimmedValue),
                ]);
                if (hasDuplicates) {
                    setInputValue(data);
                    handleError({
                        type: EMAIL_ERROR.DUPLICATE_EMAIL,
                        message: 'Email ID is already added.',
                    });
                } else {
                    resetError();
                    setInputValue('');
                }
                return maxLimit ? uniqueArray.slice(0, maxLimit) : uniqueArray;
            });
        } else {
            setInputValue(data);
            handleError({
                type: EMAIL_ERROR.INVALID_EMAIL_ADDRESS,
                message:
                    'Check if you have typed your email address correctly.',
            });
        }
        handleCreateOption(data);
    };

    return (
        <CreatableSelect
            value={value}
            inputValue={inputValue}
            onChange={onChange}
            onInputChange={onInputChange}
            onCreateOption={onCreateOption}
            styles={{ ...colourStyles(theme), ...customStyles }}
            components={{
                DropdownIndicator: noComponent,
                IndicatorSeparator: noComponent,
                Menu: noComponent,
            }}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    neutral0: '#131317',
                    neutral10: '#21212D',
                    neutral80: 'white',
                },
            })}
            isTags
            backspaceRemovesValue
            onFocus={noop}
            onBlur={noop}
            isMulti
            isClearable={false}
            wrap
            dropdownIndicator={false}
            indicatorSeparator={false}
            showEmptyMenu={false}
            {...rest}
        />
    );
};

const CustomEmailCreatable = styled(EmailCreatable)`
    &.custom-email-input {
        .custom__control {
            min-height: 48px;
            background: ${({ theme }) => theme.colors.ambience[20]};
            border-radius: 4px;
            border: none;
            box-shadow: none;
        }

        .custom__placeholder {
            font-family: ${({ theme }) => theme.fonts.book};
            font-size: 10px;
            line-height: 14px;
            padding: 4px;
        }

        .custom__multi-value {
            background-color: ${({ theme }) => theme.colors.white};
            border-radius: 4px;
            overflow: hidden;
            .custom__multi-value__label {
                font-size: 10px;
                color: ${({ theme }) => theme.colors.black};
                font-family: ${({ theme }) => theme.fonts.medium};
                line-height: 20px;
                vertical-align: middle;
            }
            .custom__multi-value__remove {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                svg {
                    fill: ${({ theme }) => theme.colors.ambience[22]};
                }
            }
        }

        .custom__input {
            input {
                &:active,
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
`;

export default CustomEmailCreatable;

const DefaultColorStyles = renderColorStyles({ isMobile: false });

const colourStyles = (theme) => {
    return {
        ...DefaultColorStyles,

        control: (styles) => ({
            ...styles,
            ...DefaultColorStyles.control,
        }),
        option: (styles) => ({
            ...styles,
            ...DefaultColorStyles.option,
            backgroundColor: '#26252D',
            color: 'white',

            '&:hover': {
                backgroundColor: '#26252D',
                color: 'white',
            },
        }),

        singleValue: (styles) => ({
            ...styles,
            ...DefaultColorStyles.singleValue,
            color: 'white',
        }),
        valueContainer: (provided) => ({
            ...DefaultColorStyles.valueContainer,
            ...provided,

            color: 'white',
        }),
        multiValue: (styles) => ({
            ...styles,
            fontSize: '14px',
            fontFamily: 'NotoSans-Regular',
        }),
        multiValueLabel: (styles) => ({
            ...styles,
            fontFamily: 'NotoSans-Regular',
            fontSize: '14px',
            paddingLeft: '8px',
            paddingRight: '8px',
        }),
        multiValueRemove: (styles) => ({
            ...styles,
            padding: '0 4px',
        }),
        menu: (styles) => ({
            ...styles,
            ...DefaultColorStyles.menu,

            color: 'white',
        }),
        menuList: (styles) => ({
            ...styles,
            ...DefaultColorStyles.menuList,

            color: 'white',
        }),
        input: (styles) => ({
            ...styles,
            color: theme?.colors?.ambience[0],
        }),
    };
};
