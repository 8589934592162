import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EVENT_SUB_TYPES, EVENT_TYPES } from 'utils/constants/airmeet';

const { CONFERENCE } = EVENT_TYPES;

const EMPTY_OBJ = {};
const getLiveConfig = (store) => store.lounge.airmeet?.live_config || EMPTY_OBJ;

function useFeatureFlags({ data, enabledFeatures }) {
    const {
        currentAirmeet: {
            is_branding_enabled: branding,
            is_custom_tables_enabled: tableEdit,
            event_type: eventType,
            is_metering_allowed: isMeteringAllowed,
            is_custom_attendee_exp_enabled: isCustomAttendeeExpEnabled,
            enabled_feature_data_v2: enabledFeatureData,
            is_ugc_enabled: isUGCEnabled,
            event_rating_card: newEventRatingCardEnabled,
            is_breakouts_enabled: isBreakoutsEnabled,
            breakouts_room_max_size: breakoutRoomMaxSize,
            in_session_cta_enabled: isInSessionCTAEnabled,
            event_sub_type: eventSubType,
            tracks,
        },
    } = data;

    const {
        is_session_replay_enabled: isSessionReplayEnabled,
        is_custom_nav_labels_enabled: isCustomNavLabelsEnabled,
        is_custom_booth_enabled: isCustomBoothEnabled,
        is_booth_broadcast_enabled: isBoothBroadcastEnabled,
        is_schedule_meetings_enabled: isScheduleMeetingEnabled,
    } = enabledFeatureData || {};

    const { hideSocialLounge, hideResourcesTab } = useSelector(getLiveConfig);
    const hasTrackAdded = eventType === CONFERENCE && (tracks || []).length > 0;

    const flags = useMemo(() => {
        const isConferenceEvent = eventType === CONFERENCE;
        const isLiteEvent = Boolean(
            eventSubType && eventSubType === EVENT_SUB_TYPES.LITE
        );
        const isLoungeAllowed = !isLiteEvent;
        const lounge = !hideSocialLounge;
        return {
            isLiteEvent,
            isMeetupLiteEvent: isLiteEvent && !isConferenceEvent,
            branding: Boolean(branding),
            tableEdit: Boolean(tableEdit),
            isConferenceEvent,
            hideResourcesTab,
            lounge: Boolean(lounge),
            isLoungeAllowed,
            isMeteringAllowed,
            isCustomAttendeeExpEnabled,
            isSessionReplayEnabled,
            isCustomNavLabelsEnabled,
            isCustomBoothEnabled,
            isBoothBroadcastEnabled,
            isScheduleMeetingEnabled,
            isUGCEnabled,
            isLiveAnnouncementEnabled: true,
            isLiveIntegrationEnabled: true, // TODO: Remove flag dependency for live integration
            rhsV2: true, // Use RHS v2 for collapsible RHS,
            newEventRatingCardEnabled,
            hasTrackAdded,
            isBreakoutsEnabled,
            isInSessionCTAEnabled,
            breakoutRoomMaxSize,
        };
    }, [
        branding,
        tableEdit,
        eventType,
        isMeteringAllowed,
        isCustomAttendeeExpEnabled,
        hideResourcesTab,
        hideSocialLounge,
        isSessionReplayEnabled,
        isCustomNavLabelsEnabled,
        isCustomBoothEnabled,
        isBoothBroadcastEnabled,
        isScheduleMeetingEnabled,
        isUGCEnabled,
        newEventRatingCardEnabled,
        hasTrackAdded,
        isBreakoutsEnabled,
        breakoutRoomMaxSize,
        isInSessionCTAEnabled,
        eventSubType,
    ]);

    return flags;
}

export default useFeatureFlags;
