import { CALL_API } from 'store/api';
import { API_SERVICE } from 'utils/apiService';

export const CREATE_BREAKOUT_REQUEST = 'CREATE_BREAKOUT_REQUEST';
export const CREATE_BREAKOUT_RESPONSE = 'CREATE_BREAKOUT_RESPONSE';
export const UPDATE_BREAKOUT_REQUEST = 'UPDATE_BREAKOUT_REQUEST';
export const UPDATE_BREAKOUT_RESPONSE = 'UPDATE_BREAKOUT_RESPONSE';
export const UPDATE_BREAKOUT_ROOM_REQUEST = 'UPDATE_BREAKOUT_ROOM_REQUEST';
export const UPDATE_BREAKOUT_ROOM_RESPONSE = 'UPDATE_BREAKOUT_ROOM_RESPONSE';
export const UPDATE_BREAKOUT_ROOM_USERS_REQUEST =
    'UPDATE_BREAKOUT_ROOM_USERS_REQUEST';
export const UPDATE_BREAKOUT_ROOM_USERS_RESPONSE =
    'UPDATE_BREAKOUT_ROOM_USERS_RESPONSE';
export const DELETE_BREAKOUT_REQUEST = 'DELETE_BREAKOUT_REQUEST';
export const DELETE_BREAKOUT_RESPONSE = 'DELETE_BREAKOUT_RESPONSE';
export const UPDATE_REQUESTING_USERS_REQUEST =
    'UPDATE_REQUESTING_USERS_REQUEST';
export const UPDATE_REQUESTING_USERS_RESPONSE =
    'UPDATE_REQUESTING_USERS_RESPONSE';
export const GET_PARTICIPANTS_DATA_REQUEST = 'GET_PARTICIPANTS_DATA_REQUEST';
export const GET_PARTICIPANTS_DATA_RESPONSE = 'GET_PARTICIPANTS_DATA_RESPONSE';

export const SET_DRAFT_BREAKOUT = 'SET_DRAFT_BREAKOUT';
export const DELETE_DRAFT_BREAKOUT = 'DELETE_DRAFT_BREAKOUT';

interface CreateBreakoutProps {
    airmeetId: string;
    sessionId: string;
    csvS3Link?: string;
    title?: string;
    assignmentType?: string;
    joiningMode: string;
    status: string;
    duration?: string;
    initialNumberOfUsers?: number;
    excludedUserIds?: string[];
}

export function createBreakout({
    airmeetId,
    sessionId,
    csvS3Link,
    title,
    assignmentType,
    joiningMode,
    status,
    duration,
    initialNumberOfUsers,
    excludedUserIds,
}: CreateBreakoutProps) {
    const body = {};
    if (Boolean(csvS3Link)) {
        body['csvS3Link'] = csvS3Link;
    }
    if (Boolean(title)) {
        body['title'] = title;
    } else {
        body['title'] = 'Breakout Rooms';
    }

    if (Boolean(joiningMode)) {
        body['joiningMode'] = joiningMode;
    }
    if (Boolean(assignmentType)) {
        body['assignmentType'] = assignmentType;
    }
    if (Boolean(status)) {
        body['status'] = status;
    }
    if (Boolean(duration)) {
        body['duration'] = duration;
    }
    if (Boolean(initialNumberOfUsers)) {
        body['initialNumberOfUsers'] = initialNumberOfUsers;
    }
    if (excludedUserIds) {
        body['excludedUserIds'] = excludedUserIds;
    }

    return {
        [CALL_API]: {
            types: [CREATE_BREAKOUT_REQUEST, CREATE_BREAKOUT_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/breakout`,
            method: 'POST',
            body,
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateBreakout({ airmeetId, sessionId, breakoutId, data }) {
    return {
        [CALL_API]: {
            types: [UPDATE_BREAKOUT_REQUEST, UPDATE_BREAKOUT_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/breakout/${breakoutId}`,
            method: 'PATCH',
            body: data,
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateBreakoutRoom({
    airmeetId,
    sessionId,
    breakoutId,
    tableId,
    data,
}) {
    return {
        [CALL_API]: {
            types: [
                UPDATE_BREAKOUT_ROOM_REQUEST,
                UPDATE_BREAKOUT_ROOM_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/breakout/${breakoutId}/table/${tableId}`,
            method: 'PATCH',
            type: 'json',
            body: data,
            service: API_SERVICE.BFF,
        },
    };
}

export function reassignBreakoutRoomUsers({
    airmeetId,
    sessionId,
    breakoutId,
    data,
}) {
    return {
        [CALL_API]: {
            types: [
                UPDATE_BREAKOUT_ROOM_USERS_REQUEST,
                UPDATE_BREAKOUT_ROOM_USERS_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/breakout/${breakoutId}/reassign`,
            method: 'PATCH',
            body: data,
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}

export function deleteBreakout({ airmeetId, sessionId, breakoutId }) {
    return {
        [CALL_API]: {
            types: [DELETE_BREAKOUT_REQUEST, DELETE_BREAKOUT_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/breakout/${breakoutId}`,
            method: 'DELETE',
            service: API_SERVICE.BFF,
        },
    };
}

export function assignRequestingUsers({
    airmeetId,
    sessionId,
    breakoutId,
    data,
}) {
    return {
        [CALL_API]: {
            types: [
                UPDATE_REQUESTING_USERS_REQUEST,
                UPDATE_REQUESTING_USERS_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/breakout/${breakoutId}/assign-requesting-users`,
            method: 'PATCH',
            body: data,
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}

export function getParticipantsData({ airmeetId, data }) {
    return {
        [CALL_API]: {
            types: [
                GET_PARTICIPANTS_DATA_REQUEST,
                GET_PARTICIPANTS_DATA_RESPONSE,
            ],
            endpoint: `/${airmeetId}/participants-search`,
            method: 'POST',
            body: { ...data, pageNumber: 1, pageSize: 500 },
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}

export function setDraftBreakout(payload) {
    return {
        type: SET_DRAFT_BREAKOUT,
        payload,
    };
}

export function deleteDraftBreakout() {
    return {
        type: DELETE_DRAFT_BREAKOUT,
    };
}
