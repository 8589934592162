import Box from 'atoms/Box';
import Loader from 'components/general/Loader';
import { themeWrapper } from 'foundations/theme';
import useTheme from 'hooks/useTheme';
import React, { createContext, useContext, useMemo } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { noop } from 'utils/constants/common';
import useContainerChannel, {
    APICallback,
    GetRouteCallback,
    GetStoreCallback,
    LoggerCallback,
    NotificationCallback,
    NotifyCallback,
    SetRouteCallback,
    SetStoreCallback,
} from './useContainerChannel';
import useContainerNotifications from './useContainerNotifications';
import useContainerRoute from './useContainerRoute';
import useContainerStore from './useContainerStore';
import useContainerUtils from './useContainerUtils';

// Re-export other common container hooks (for easy access via one file)
export {
    useContainerNotifications,
    useContainerRoute,
    useContainerStore,
    useContainerUtils,
};

// Context defs
// Main context
export const Container = createContext<{
    containerId: string;
    loaded: boolean;
}>(null);

// Container Storage
export const ContainerStore = createContext<{
    state: any;
    getState: GetStoreCallback;
    setState: SetStoreCallback;
}>(null);

// Container Routing
export const ContainerRoute = createContext<{
    location: string;
    route: string;
    getRoute: GetRouteCallback;
    setRoute: SetRouteCallback;
}>(null);

// Container Notifications
export const ContainerNotifications = createContext<{
    notify: NotifyCallback;
    onNotify: NotificationCallback;
    offNotify: NotificationCallback;
}>(null);

// General utils
export const ContainerUtils = createContext<{
    logger: LoggerCallback;
    apiCall: APICallback;
}>(null);

export const useContainerContext = () => useContext(Container);

function AirmeetContainer({
    containerId,
    initialState = {},
    initialRoute = '',
    handlers = {},
    baseColor = null,
    baseTheme = null,
    loader = null,
    beforeLoad = noop,
    afterLoad = noop,
    children,
    // Styled system/components props
    className = '',
    ...styledSystemProps
}) {
    if (!containerId) {
        throw new Error('`containerId` is required!');
    }

    const parentLocation = useContext(ContainerRoute) || {
        // The overall location
        location: '',
        // Currnet container location
        route: '',
        // All beadcrumbs leading up to, and including this container
        // breadcrumbs: [],
    };
    const parentStore = useContext(ContainerStore) || { state: null };
    const { theme: parentTheme, isLightTheme } = useTheme();

    const currentTheme = useMemo(() => {
        return baseColor
            ? themeWrapper(baseColor, isLightTheme)
            : baseTheme || parentTheme;
    }, [baseColor, baseTheme, parentTheme, isLightTheme]);

    const {
        // Load-state
        loaded,
        // Routing
        route,
        getRoute,
        setRoute,
        // Store
        state,
        getState,
        setState,
        // Notifications
        onNotify,
        offNotify,
        notify,
        // Utils
        logger,
        apiCall,
    } = useContainerChannel({
        containerId,
        initialRoute,
        initialState,
        handlers,
        beforeLoad,
        afterLoad,
    });

    const location = useMemo(
        () =>
            `${`${parentLocation.location}${
                parentLocation.location ? '/' : ''
            }${containerId}${route ? '/' : ''}${route}`}`,
        [parentLocation.location, route]
    );

    return loaded ? (
        <ThemeProvider theme={currentTheme}>
            <Container.Provider
                value={{
                    containerId,
                    loaded,
                }}
            >
                <ContainerUtils.Provider value={{ logger, apiCall }}>
                    <ContainerNotifications.Provider
                        value={{ notify, onNotify, offNotify }}
                    >
                        <ContainerRoute.Provider
                            value={{ location, route, getRoute, setRoute }}
                        >
                            <ContainerStore.Provider
                                value={{
                                    state: { ...parentStore.state, ...state },
                                    getState,
                                    setState,
                                }}
                            >
                                {/* TODO: How can we validate only Styled system porops are part of this object spread  */}
                                <StyledContainer
                                    {...styledSystemProps}
                                    className={className}
                                    display={
                                        // @ts-ignore
                                        state.hidden
                                            ? 'none'
                                            : styledSystemProps.display ||
                                              'initial'
                                    }
                                >
                                    {children}
                                </StyledContainer>
                            </ContainerStore.Provider>
                        </ContainerRoute.Provider>
                    </ContainerNotifications.Provider>
                </ContainerUtils.Provider>
            </Container.Provider>
        </ThemeProvider>
    ) : loader === true ? (
        <Loader />
    ) : (
        loader
    );
}

const StyledContainer = styled(Box)<{ display: any }>``;

export default AirmeetContainer;
