import keys from 'locale/keys';
import { TRANSACTION_FAIL_REASONS } from 'utils/constants/common';
import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import { TABLE_DROP_OFF_TOLERANCE_THRESHOLD_WITH_FLAG } from 'utils/constants/live-table';
import { LOG_LEVEL } from 'utils/constants/logger';
import {
    adminSlot,
    chairName,
    getLoungePath,
    MODERATOR_SLOT,
} from '../../utils/constants/social-lounge';
import { logger } from '../../utils/logger';
const { LOUNGE_TABLES } = FEATURE_NAMES;

const localLog = logger.init('tablePresenceWriter', 'yellow', LOG_LEVEL.INFO);

const errorLog = logger.init('tablePresenceWriter', 'red', LOG_LEVEL.ERROR);

const _getTablesPath = (
    airmeetId: string,
    loungeNode: string,
    loungeId: string
) => `${getLoungePath(airmeetId, loungeNode, loungeId)}/tables`;

export const getTablePath = (
    airmeetId: string,
    loungeNode: string,
    loungeId: string,
    code: string
) => {
    return `${_getTablesPath(airmeetId, loungeNode, loungeId)}/${code}`;
};

export const getScreenPath = (
    airmeetId: string,
    loungeNode: string,
    loungeId: string,
    code: string
) => `${getTablePath(airmeetId, loungeNode, loungeId, code)}/screen`;

export const getPeer2PeerChannelBasePath = (airmeetId, userId) => {
    const PEER_2_PEER_CHANNEL = 'peer-2-peer';
    const REAL_TIME_MESSAGES_NODE_PATH = 'realtime-messages';
    return `${REAL_TIME_MESSAGES_NODE_PATH}/${airmeetId}/${PEER_2_PEER_CHANNEL}/${userId}`;
};

export const getChairPath = (
    airmeetId: string,
    loungeNode: string,
    loungeId: string,
    code: string,
    chair: string
) =>
    `${getTablePath(airmeetId, loungeNode, loungeId, code)}/chairs${
        chair ? `/${chair}` : ''
    }`;

export const getMetaDataPath = (
    airmeetId: string,
    loungeNode: string,
    loungeId: string,
    code: string,
    userId: string
) =>
    `${getTablePath(
        airmeetId,
        loungeNode,
        loungeId,
        code
    )}/userMetaData/${userId}`;

export const cancelOnDisconnect = async ({
    client,
    enableDisconnectTimer,
    chairPath,
    path,
}) => {
    const metaDataPath = enableDisconnectTimer
        ? path + '/disconnectTime'
        : path;
    localLog('Cancelling disconnect', chairPath);
    client.cancelOnDisconnect(chairPath);
    localLog('Cancelling disconnect', metaDataPath);
    client.cancelOnDisconnect(metaDataPath);
};

export const registerOnDisconnect = ({
    client,
    airmeetId,
    loungeNode,
    loungeId,
    code,
    userId,
    enableDisconnectTimer,
}) => {
    if (!code) {
        throw new Error('Missing table code');
    }

    if (enableDisconnectTimer) {
        registerDisconnectTimer({
            client,
            airmeetId,
            loungeNode,
            loungeId,
            code,
            userId,
        });
    } else {
        registerChairCleanupOnDisconnect({
            client,
            airmeetId,
            loungeNode,
            loungeId,
            code,
            userId,
        });
    }
};

const registerDisconnectTimer = ({
    client,
    airmeetId,
    loungeNode,
    loungeId,
    code,
    userId,
}) => {
    const metaDataPath = getMetaDataPath(
        airmeetId,
        loungeNode,
        loungeId,
        code,
        userId
    );
    client.replaceOnDisconnect(
        metaDataPath + '/disconnectTime',
        client.getServerTimestampRef()
    );
};

const registerChairCleanupOnDisconnect = ({
    client,
    airmeetId,
    loungeNode,
    loungeId,
    code,
    userId,
}) => {
    const chairPath = getChairPath(
        airmeetId,
        loungeNode,
        loungeId,
        code,
        userId
    );
    const metaDataPath = getMetaDataPath(
        airmeetId,
        loungeNode,
        loungeId,
        code,
        userId
    );
    client.replaceOnDisconnect(chairPath, null);
    client.replaceOnDisconnect(metaDataPath, null);
};

export const registerOnScreenDisconnect = ({
    client,
    airmeetId,
    loungeNode,
    loungeId,
    code,
}) => {
    client.replaceOnDisconnect(
        getScreenPath(airmeetId, loungeNode, loungeId, code),
        null
    );
};

export const cancelOnScreenDisconnect = ({
    client,
    airmeetId,
    loungeNode,
    loungeId,
    code,
}) => {
    client.cancelOnDisconnect(
        getScreenPath(airmeetId, loungeNode, loungeId, code)
    );
};

const wrapTransaction = async (transactionFunction) => {
    let err;
    const errorMessage = (error) => {
        if (!error) {
            return false;
        }
        errorLog('Transaction failed', { reason: error.message, error });
        switch (error.message) {
            case TRANSACTION_FAIL_REASONS.DISCONNECT:
                return keys.TOASTS_ERROR_NETWORK_UNAVAILABLE_TRY_AGAIN;
            default:
                return keys.TOASTS_FACED_ISSUE_TRY_AGAIN;
        }
    };
    try {
        const { error, committed } = await transactionFunction();
        if (error) {
            err = errorMessage(error);
        } else {
            err = !committed;
        }
    } catch (error) {
        err = errorMessage(error);
    }

    return { error: err };
};

function findOccupiedChairs(chairs, userMetaData, currentTime) {
    const occupiedChairs = [];
    for (const [userId, chair] of Object.entries(chairs)) {
        const metaData = userMetaData[userId];
        if (
            metaData?.disconnectTime &&
            currentTime - metaData.disconnectTime >
                TABLE_DROP_OFF_TOLERANCE_THRESHOLD_WITH_FLAG
        ) {
            continue;
        } else {
            occupiedChairs.push(chair);
        }
    }
    return occupiedChairs;
}

const tablePresenceWriter = async ({
    airmeetId,
    client,
    payload,
    metaData,
    logger,
}) => {
    if (
        !(payload.loungeId || payload.tableCode) &&
        !(
            payload?.actionType ===
                FEATURE_ACTIONS[LOUNGE_TABLES].MUTE_OTHER_USER_AUDIO ||
            payload?.actionType ===
                FEATURE_ACTIONS[LOUNGE_TABLES].MUTE_OTHER_USER_VIDEO
        )
    ) {
        throw new Error('Invalid Table Presence firebase key');
    }

    const {
        loungeId,
        loungeNode,
        tableCode: code,
        chairNo,
        userId,
        userIdSeq,
    } = payload;
    const {
        maxLoungeTableCapacity,
        enableDisconnectTimer,
        allocateUserBaseChair,
    } = metaData;

    // Table Join writer
    if (payload?.actionType === FEATURE_ACTIONS[LOUNGE_TABLES].JOIN) {
        const serverTimeDiff = await client.getCurrentTimeStampDifference();

        const reserveTable = async (code, chair) => {
            if (!code) {
                return false;
            }

            const occupyChair = (code, tableData, chairs, occupying) => {
                chairs[userId] = occupying;
                return { ...tableData, chairs };
            };

            localLog('Reserving table', code, chair);
            const tablePath = getTablePath(
                airmeetId,
                loungeNode,
                loungeId,
                code
            );

            const { error } = await wrapTransaction(() => {
                if (allocateUserBaseChair) {
                    return client
                        .ref(tablePath)
                        .once('value')
                        .then((snapshot) => {
                            let tableData = snapshot.val();
                            if (!tableData) {
                                tableData = {};
                                logger.info('No table data found');
                            }

                            const chairs = tableData.chairs || {};
                            const occupied = enableDisconnectTimer
                                ? findOccupiedChairs(
                                      chairs,
                                      tableData.userMetaData,
                                      Date.now() - serverTimeDiff
                                  )
                                : Object.values(chairs);

                            if (
                                (occupied || []).length >=
                                maxLoungeTableCapacity
                            ) {
                                logger.info(
                                    'Table joining failed - max capacity has been reached'
                                );
                                return;
                            }

                            const joinTable = {};
                            joinTable[
                                `${tablePath}/chairs/${userId}`
                            ] = `chair-${userId}`;
                            joinTable[`${tablePath}/userMetaData/${userId}`] = {
                                id_seq: userIdSeq,
                            };
                            return client
                                .atomicUpdate(joinTable)
                                .then(() => ({ committed: true }))
                                .catch(() => ({
                                    error: true,
                                }));
                        });
                } else {
                    return client.runTransaction(
                        tablePath,
                        function (tableData) {
                            if (!tableData) {
                                tableData = {};
                            }

                            // @TODO: remove BCTABLELIMIT (added for backward compatibility)
                            if (
                                ['expanding', undefined].includes(
                                    tableData.info?.tableLimit
                                )
                            ) {
                                tableData.info = tableData.info || {};
                                tableData.info.chairCount = maxLoungeTableCapacity;
                            }

                            tableData.userMetaData =
                                tableData.userMetaData || {};

                            tableData.userMetaData[userId] = {
                                id_seq: userIdSeq,
                            };

                            let occupying = chair || chairName(0);

                            const chairs = tableData.chairs || {};

                            //If user already has a chair
                            if (chairs[userId]) {
                                // We connected back, remove disconnect time
                                const res = occupyChair(
                                    code,
                                    tableData,
                                    chairs,
                                    chairs[userId]
                                );
                                if (!res) {
                                    errorLog(
                                        'Reserving table error - trying to occupy old chair. ',
                                        res,
                                        tableData
                                    );
                                }
                                return res;
                            }

                            const { chairCount = maxLoungeTableCapacity } =
                                tableData.info || {};

                            const occupied = enableDisconnectTimer
                                ? findOccupiedChairs(
                                      chairs,
                                      tableData.userMetaData,
                                      Date.now() - serverTimeDiff
                                  )
                                : Object.values(chairs);
                            const hasAdmin = !!occupied.includes(adminSlot());
                            const hasModerator = !!occupied.includes(
                                MODERATOR_SLOT
                            );

                            // Immediately grant admin a chair
                            // if not already occupied
                            if (occupying === adminSlot() && !hasAdmin) {
                                const res = occupyChair(
                                    code,
                                    tableData,
                                    chairs,
                                    occupying
                                );
                                if (!res) {
                                    errorLog(
                                        'Reserving table error - trying to occupy admin chair. ',
                                        res,
                                        tableData
                                    );
                                }
                                return res;
                            }

                            // Immediately assign moderator a chair
                            if (occupying === MODERATOR_SLOT) {
                                const res = occupyChair(
                                    code,
                                    tableData,
                                    chairs,
                                    occupying
                                );
                                if (!res) {
                                    errorLog(
                                        'Reserving table error - trying to occupy moderator chair. ',
                                        res,
                                        tableData
                                    );
                                }
                                return res;
                            }

                            // For others
                            const attendeChairs =
                                occupied.length -
                                (hasAdmin ? 1 : 0) -
                                (hasModerator ? 1 : 0);

                            const maxChairs = parseInt(chairCount);

                            // Validate we have remaining chairs
                            if (attendeChairs >= maxChairs) {
                                // Abort the txn
                                localLog(
                                    'Aborting txn - no remaining chairs ',
                                    occupied,
                                    attendeChairs,
                                    maxChairs
                                );
                                return;
                            }

                            // Try to occupy our requested chair
                            if (!occupied.includes(occupying)) {
                                const res = occupyChair(
                                    code,
                                    tableData,
                                    chairs,
                                    occupying
                                );
                                if (!res) {
                                    errorLog(
                                        'Reserving table error - trying to occupy requested chair. ',
                                        res,
                                        tableData,
                                        occupied,
                                        occupying
                                    );
                                }
                                return res;
                            }

                            // Finally, look for an available chair
                            occupying = null;
                            for (let i = 0; i < maxChairs; i++) {
                                const check = chairName(i);
                                if (occupied.includes(check)) {
                                    continue;
                                } else {
                                    occupying = check;
                                    break;
                                }
                            }

                            if (occupying) {
                                const res = occupyChair(
                                    code,
                                    tableData,
                                    chairs,
                                    occupying
                                );
                                if (!res) {
                                    errorLog(
                                        'Reserving table error - trying to occupy available chair ',
                                        res,
                                        tableData,
                                        occupied,
                                        occupying
                                    );
                                }
                                return res;
                            }

                            localLog(
                                'Aborting txn - no available chair',
                                occupied,
                                occupying
                            );
                            // Abort the txn as no available chair was found
                            return;
                        },
                        null,
                        true
                    );
                }
            });

            return { error: error };
        };

        return reserveTable(code, chairNo);
    }

    // Table Leave Writer
    if (payload?.actionType === FEATURE_ACTIONS[LOUNGE_TABLES].LEAVE) {
        const updates = {};
        const path = getChairPath(
            airmeetId,
            loungeNode,
            loungeId,
            code,
            userId
        );
        updates[path] = null;

        const metaDataPath = getMetaDataPath(
            airmeetId,
            loungeNode,
            loungeId,
            code,
            userId
        );
        updates[metaDataPath] = null;
        try {
            await client.atomicUpdateAsync(updates);
        } catch (error) {
            errorLog('Atomic Update to leave table failed ', error);
        }
    }

    if (payload?.actionType === FEATURE_ACTIONS[LOUNGE_TABLES].RESERVE_SCREEN) {
        const path = getScreenPath(airmeetId, loungeNode, loungeId, code);

        const { error } = await wrapTransaction(() =>
            client.runTransaction(
                path,
                function (screenUser) {
                    if (!screenUser || screenUser === userId) {
                        return userId;
                    }
                    return;
                },
                null,
                true
            )
        );
        return { error: error };
    }

    if (payload?.actionType === FEATURE_ACTIONS[LOUNGE_TABLES].LEAVE_SCREEN) {
        const path = getScreenPath(airmeetId, loungeNode, loungeId, code);
        await client.setDataAsync(path, null);
    }

    if (
        payload?.actionType ===
            FEATURE_ACTIONS[LOUNGE_TABLES].MUTE_OTHER_USER_AUDIO ||
        payload?.actionType ===
            FEATURE_ACTIONS[LOUNGE_TABLES].MUTE_OTHER_USER_VIDEO
    ) {
        const path = getPeer2PeerChannelBasePath(
            airmeetId,
            payload?.recieverId
        );
        const ref = client.ref(path);
        const key = ref.push().getKey();
        const messageData = {
            key,
            type: payload?.channelActionType,
            senderUid: userId,
            timestamp: client.getServerTimestampRef(),
        };
        await client.setDataAsync(`${path}/${key}`, messageData);
    }
};

export default tablePresenceWriter;
