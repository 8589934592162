import { useEffect, useMemo, useState } from 'react';
import { makeExternalRequest } from 'utils/api';
import { logger } from 'utils/logger';

/**
 * This hook gives the location info associated with any IP
 * @see https://ipregistry.co/ for reference
 * @param {string} ip IP address for which location info is to be fetched
 */
const useIpMetaInfo = ({ ip = '', isAuto = true }) => {
    const [metaInfo, setMetaInfo] = useState(null);

    // Fetching user location info from api
    useEffect(() => {
        if (isAuto) {
            (async () => {
                const metaInfo = await queryMetaInfo(ip);
                setMetaInfo(metaInfo);
            })();
        }
    }, [isAuto, ip]);

    const queryMetaInfo = async (ip = '') => {
        const API_KEY = process.env.REACT_APP_IPLOOKUP_API_KEY;
        const baseUrl = `https://api.ipregistry.co/?key=${API_KEY}`;
        let response = null;
        try {
            response = await makeExternalRequest({
                path: ip ? `${baseUrl}&ip=${ip}` : baseUrl,
            });
        } catch (error) {
            logger.error(error.name, error.message);
        }
        return getIpMetaInfo(response);
    };

    const getIpMetaInfo = (metaInfo) => {
        if (!metaInfo) {
            return {};
        }

        const { location, time_zone, user_agent, connection } = metaInfo;

        let payload = {
            ip: metaInfo.ip,
            user_agent,
            connection,
        };

        if (location) {
            const { country, continent, city } = location;
            payload = {
                ...payload,
                country: country?.name,
                location: {
                    country: country?.name,
                    continent: continent?.name,
                    city: city,
                },
            };
        }

        if (time_zone) {
            const { id, abbreviation, in_daylight_saving } = time_zone;
            payload = {
                ...payload,
                time_zone: {
                    id,
                    abbreviation,
                    in_daylight_saving,
                },
            };
        }

        return payload;
    };

    return { ipMetaInfo: metaInfo, queryMetaInfo };
};

export default useIpMetaInfo;
