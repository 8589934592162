import 'cropperjs/dist/cropper.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import styled from 'styled-components';

import Box from 'atoms/Box';
import { cdnImage } from 'utils/core';
import { emptyObject } from 'utils/constants/common';

// use when defining onInitialized. pass setCropper to onInitialized to set cropper & pass setCropData to set cropped img
export const getCropData = (cropper, setCropData) => {
    if (typeof cropper !== 'undefined') {
        setCropData(cropper.getCroppedCanvas().toDataURL());
    }
};

const ImageEditor = ({
    img = '',
    zoom = 1,
    rotate = 0,
    initialAspectRatio = NaN,
    aspectRatio = 16 / 9,
    zoomWithScroll = false,
    showGrid = false,
    style = {},
    previewClass = '',
    minCropBoxHeight = 10,
    minCropBoxWidth = 10,
    background = false,
    responsive = false,
    autoCropArea = 0.6,
    autoCrop = true,
    viewMode = 0,
    checkOrientation = false,
    restoreOnWindowChange = true,
    keepImgMovable = true,
    allowRotation = false,
    allowZoom = true,
    zoomable = true,
    zoomOnTouch = true,
    zoomOnWheel = true,
    wheelZoomRatio = 0.1,
    cropBoxMovable = false,
    cropBoxResizable = false,
    dragMode = 'move',
    toggleDragModeOnDblclick = false,
    minContainerWidth = 200,
    minContainerHeight = 100,
    minCanvasWidth = 0,
    minCanvasHeight = 0,
    highlightAroundCropArea = true,
    onReady = null,
    cropStart = null,
    cropMove = null,
    cropEnd = null,
    onCrop = null,
    onInitialized = null,
    cropBoxBorderRadius = 0,
    imgFormat = 'image/jpeg',
    skipCDNise = false,
    wrapperStyles = emptyObject,
    fillColor = 'transparent',
    ...props
}) => {
    const [image, setImage] = useState('');
    const imgCropperRef = useRef();

    useEffect(() => {
        if (typeof img === 'object' && img instanceof File) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
                imgCropperRef.current.cropper.reset();
            };
            reader.readAsDataURL(img);
        } else if (typeof img === 'string') {
            if (skipCDNise) {
                setImage(img);
            } else {
                const imgPath = img.split('/').at(-1);
                const cdnURL = cdnImage(imgPath);
                setImage(cdnURL);
            }
        }
    }, [img, skipCDNise]);

    useEffect(() => {
        imgCropperRef.current.cropper.scale(zoom);
    }, [zoom]);

    const onCropHandler = useCallback(() => {
        if (onCrop && !onInitialized) {
            const cropper = imgCropperRef?.current?.cropper;
            onCrop(
                cropper.getCroppedCanvas({ fillColor })?.toDataURL(imgFormat)
            );
        }
    }, [imgFormat, onCrop, onInitialized, fillColor]);

    return (
        <>
            <CropperWrapper
                cropBoxBorderRadius={cropBoxBorderRadius}
                style={wrapperStyles}
                fillColor={fillColor}
            >
                <Cropper
                    ref={imgCropperRef}
                    style={style}
                    rotate={rotate}
                    initialAspectRatio={initialAspectRatio}
                    aspectRatio={aspectRatio}
                    preview={previewClass}
                    src={image}
                    viewMode={viewMode}
                    modal={highlightAroundCropArea}
                    minCropBoxHeight={minCropBoxHeight}
                    minCropBoxWidth={minCropBoxWidth}
                    background={background}
                    responsive={responsive}
                    cropBoxResizable={cropBoxResizable}
                    autoCropArea={autoCropArea}
                    checkOrientation={checkOrientation}
                    restore={restoreOnWindowChange}
                    movable={keepImgMovable}
                    autoCrop={autoCrop}
                    rotatable={allowRotation}
                    scalable={allowZoom}
                    zoomable={zoomable}
                    zoomOnTouch={zoomOnTouch}
                    zoomOnWheel={zoomOnWheel}
                    wheelZoomRatio={wheelZoomRatio}
                    cropBoxMovable={cropBoxMovable}
                    toggleDragModeOnDblclick={toggleDragModeOnDblclick}
                    minContainerWidth={minContainerWidth}
                    minContainerHeight={minContainerHeight}
                    minCanvasHeight={minCanvasHeight}
                    minCanvasWidth={minCanvasWidth}
                    onInitialized={onInitialized}
                    ready={onReady}
                    cropstart={cropStart}
                    cropend={cropEnd}
                    cropmove={cropMove}
                    crop={onCrop ? onCropHandler : null}
                    guides={showGrid}
                    dragMode={dragMode}
                    {...props}
                />
            </CropperWrapper>
        </>
    );
};

export default ImageEditor;

const CropperWrapper = styled(Box)`
    width: 100%;
    height: 320px;

    & > div {
        background-color: #000 !important;
    }

    ${(cropBoxBorderRadius) =>
        cropBoxBorderRadius &&
        `& .cropper-view-box {
        border-radius: ${cropBoxBorderRadius}px;
    }`}

    & .cropper-modal {
        background-color: ${({ theme, fillColor }) =>
            !fillColor || fillColor === 'transparent'
                ? theme.colors.ambience[24]
                : fillColor};
        opacity: ${({ fillColor }) =>
            !fillColor || fillColor === 'transparent' ? '0.6' : '1'};
    }
    & .cropper-view-box {
        outline-color: ${({ theme }) => theme.colors.ambience[0]};
    }
`;
