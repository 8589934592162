import * as Label from '@radix-ui/react-label';
import {
    CharLimit,
    ClearIcon,
    InputIcon,
    rightPadding,
} from 'atoms/Input/utils';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';

type SizeEnum = 'small' | 'large';

interface InputV2Props {
    label?: string;
    value: string;
    width?: string;
    placeholder?: string;
    onChange: () => void;
    errorMsg?: string;
    maxLength?: number;
    showLimit?: boolean;
    type?: string;
    onClear?: any;
    asType?: string;
    rows?: number;
    rightIcon?: string;
    isAutoHeight?: boolean;
    size?: SizeEnum;
    subLabel?: string;
    required?: boolean;
    inputWrapper?: { [key: string]: string };
}

const InputV2: React.FC<InputV2Props> = React.forwardRef(
    (
        {
            label,
            value,
            placeholder,
            width = '100%',
            onChange = noop,
            errorMsg,
            maxLength,
            showLimit,
            type = 'text',
            onClear,
            rows = 3,
            rightIcon,
            asType = 'input',
            isAutoHeight,
            size = 'large',
            required,
            subLabel,
            inputWrapper = {},
            ...rest
        },
        ref
    ) => {
        const [val, setVal] = useState(value);
        const onValueChange = (e) => {
            setVal(e.target.value);
            onChange(e);
        };
        useEffect(() => {
            setVal(value);
        }, [value]);

        return (
            <>
                {label && (
                    <InputLabel>
                        <Text variant={size === 'small' ? 'caption' : 'body2'}>
                            {label}
                        </Text>
                        {subLabel && (
                            <Text
                                color='text.default.secondary'
                                variant={
                                    size === 'small' ? 'caption10' : 'caption'
                                }
                            >
                                {subLabel}
                            </Text>
                        )}
                    </InputLabel>
                )}
                <FlexDiv
                    width={width}
                    flexWrap='wrap'
                    position='relative'
                    style={{ ...inputWrapper }}
                >
                    <InputField
                        value={val}
                        placeholder={placeholder}
                        onChange={onValueChange}
                        hasError={errorMsg}
                        maxLength={maxLength}
                        type={type}
                        hasPadding={rightPadding(rightIcon, onClear, value)}
                        as={asType}
                        rows={rows}
                        isAutoHeight={isAutoHeight}
                        sz={size}
                        aria-invalid={errorMsg}
                        ref={ref}
                        aria-errormessage={errorMsg ? 'inputError' : null}
                        required={required}
                        aria-required={required}
                        {...rest}
                    />
                    <InputIcon
                        onClear={onClear}
                        value={value}
                        rightIcon={rightIcon}
                        size={size}
                    />
                    <ClearIcon onClear={onClear} value={value} size={size} />
                    <InputInfo>
                        {errorMsg && (
                            <ErrorMsg role='alert'>
                                <Text
                                    variant='caption'
                                    color='sem.error'
                                    id='inputError'
                                >
                                    {errorMsg}
                                </Text>
                            </ErrorMsg>
                        )}
                        {maxLength && showLimit && (
                            <CharLimit value={value} maxLength={maxLength} />
                        )}
                    </InputInfo>
                </FlexDiv>
            </>
        );
    }
);

export default InputV2;

const InputField = styled.input`
    color: ${({ theme }) => theme.colors.text.default.primary};
    background-color: ${({ theme }) => theme.colors.fill.default2};
    border: 1px solid ${({ theme }) => theme.colors.border.default1};
    border-radius: ${({ theme }) => theme.radii.default};
    padding: ${({ theme, sz }) =>
        sz === 'small'
            ? `${theme.space.x1} ${theme.space.x3}`
            : `${theme.space.x2} ${theme.space.x4}`};
    font-size: ${({ theme, sz }) =>
        sz === 'small' ? theme.fontSizes.x1 : theme.fontSizes.x2};
    font-family: ${({ theme }) => theme.fonts.regular};
    width: 100%;
    height: ${({ isAutoHeight, sz, theme }) =>
        isAutoHeight ? 'auto' : sz === 'small' ? '28px' : theme.space.x8};
    transition: ${({ isAutoHeight }) =>
        isAutoHeight ? 'none' : 'all 0.25s ease-in'};
    resize: none;
    box-sizing: border-box;

    ${({ hasPadding, theme }) =>
        hasPadding &&
        `
        padding-right: calc(${theme.space.x7} + ${theme.space.x2});
    `}

    &::placeholder {
        color: ${({ theme }) => theme.colors.text.default.secondary};
    }

    &:placeholder-shown {
        text-overflow: ellipsis;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.fill.default1};
        border-color: ${({ theme }) => theme.colors.border.other1};
    }

    &:focus,
    &:active {
        background-color: ${({ theme }) => theme.colors.fill.default1};
        border-color: ${({ theme }) => theme.colors.border.other1};
        outline: 4px solid ${({ theme }) => theme.colors.border.default1};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.colors.fill.default2};
        border-color: ${({ theme }) => theme.colors.border.default2};
        color: ${({ theme }) => theme.colors.text.default.disabled};
        cursor: default;
        pointer-events: none;
    }

    ${({ hasError, theme }) =>
        hasError
            ? `
        border: 1px solid ${theme.colors.sem.error};
        box-shadow: 0 0 0 4px ${theme.colors.fill.default2};
        &:hover {
            border-color: ${theme.colors.sem.error};
        }
    `
            : ''}
`;

const InputLabel = styled(Label.Root)`
    display: block;
    margin-bottom: ${({ theme }) => theme.space.x1};
`;

const InputInfo = styled(FlexDiv)`
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`;

const ErrorMsg = styled.div`
    display: inline-flex;
    padding: 0;
    margin-top: ${({ theme }) => theme.space.x1};
    margin-right: ${({ theme }) => theme.space.x3};
`;
