import { stringify } from 'querystring';
import { API_SERVICE } from 'utils/apiService';
import { CALL_API } from '../api';
import { isRecordingDurationInvalid } from '../../components/community/mediaLibrary/hooks/utils';

export const VIDEO_UPLOADER_REQUEST = 'VIDEO_UPLOADER_REQUEST';
export const VIDEO_UPLOADER_RESPONSE = 'VIDEO_UPLOADER_RESPONSE';
export const VIDEO_GET_REQUEST = 'VIDEO_GET_REQUEST';
export const VIDEO_GET_RESPONSE = 'VIDEO_GET_RESPONSE';
export const SESSION_RECORDING_GET_REQUEST = 'SESSION_RECORDING_GET_REQUEST';
export const SESSION_RECORDING_GET_RESPONSE = 'SESSION_RECORDING_GET_RESPONSE';
export const SESSION_RECORDING_DELETE_REQUEST =
    'SESSION_RECORDING_DELETE_REQUEST';
export const SESSION_RECORDING_DELETE_RESPONSE =
    'SESSION_RECORDING_DELETE_RESPONSE';
export const VIDEO_SAVE_REQUEST = 'VIDEO_SAVE_REQUEST';
export const VIDEO_SAVE_RESPONSE = 'VIDEO_SAVE_RESPONSE';
export const VIDEO_DELETE_REQUEST = 'VIDEO_DELETE_REQUEST';
export const VIDEO_DELETE_RESPONSE = 'VIDEO_DELETE_RESPONSE';
export const COMMUNITY_CLIPS_GET_REQUEST = 'COMMUNITY_CLIPS_GET_REQUEST';
export const COMMUNITY_CLIPS_GET_RESPONSE = 'COMMUNITY_CLIPS_GET_RESPONSE';
export const COMMUNITY_CLIPS_DELETE_REQUEST = 'COMMUNITY_CLIPS_DELETE_REQUEST';
export const COMMUNITY_CLIPS_DELETE_RESPONSE =
    'COMMUNITY_CLIPS_DELETE_RESPONSE';

export function getSignedUrl(body) {
    return {
        [CALL_API]: {
            types: [VIDEO_UPLOADER_REQUEST, VIDEO_UPLOADER_RESPONSE],
            endpoint: `/aws-pre-signed-url`,
            method: 'POST',
            type: 'json',
            body,
        },
    };
}

export function getVideosList(params) {
    const queryParams = stringify(params);
    return {
        [CALL_API]: {
            types: [VIDEO_GET_REQUEST, VIDEO_GET_RESPONSE],
            endpoint: `/airmeet/features?${queryParams}`,
            method: 'GET',
            type: 'json',
        },
    };
}

export function getSessionRecordings(params) {
    const queryParams = stringify(params);
    return {
        [CALL_API]: {
            types: [
                SESSION_RECORDING_GET_REQUEST,
                SESSION_RECORDING_GET_RESPONSE,
            ],
            endpoint: `/airmeet/v2/recordings?${queryParams}`,
            method: 'GET',
            type: 'json',
            transform: (response) => {
                const recordings = response.recordings
                    .filter((recording) => !recording.is_deleted)
                    .map((recording) => {
                        const { recording_status, duration } = recording || {};
                        const PROCESSING_STATUS = 'PROCESSING';

                        return {
                            ...recording,
                            _isSelectionDisabled:
                                recording_status === PROCESSING_STATUS ||
                                isRecordingDurationInvalid({ duration }),
                            size:
                                recording_status === PROCESSING_STATUS
                                    ? 'Recording being processed'
                                    : recording.size,
                        };
                    });

                return {
                    ...response,
                    recordings,
                };
            },
        },
    };
}

export function saveMedia(body) {
    return {
        [CALL_API]: {
            types: [VIDEO_SAVE_REQUEST, VIDEO_SAVE_RESPONSE],
            endpoint: '/airmeet/feature/media',
            method: 'POST',
            type: 'json',
            body,
        },
    };
}
export function deleteMedia(body) {
    return {
        [CALL_API]: {
            types: [VIDEO_DELETE_REQUEST, VIDEO_DELETE_RESPONSE],
            endpoint: '/airmeet/feature/media',
            method: 'DELETE',
            type: 'json',
            body,
        },
    };
}

export function deleteSessionRecordings(session_ids, communityId) {
    return {
        [CALL_API]: {
            types: [
                SESSION_RECORDING_DELETE_REQUEST,
                SESSION_RECORDING_DELETE_RESPONSE,
            ],
            endpoint: `/community/${communityId}/recordings`,
            method: 'DELETE',
            type: 'json',
            body: {
                session_ids,
            },
        },
    };
}

export function getCommunityClips(params, communityId) {
    const queryParams = stringify(params);
    return {
        [CALL_API]: {
            types: [COMMUNITY_CLIPS_GET_REQUEST, COMMUNITY_CLIPS_GET_RESPONSE],
            endpoint: `/community/${communityId}/project?${queryParams}`,
            method: 'GET',
            type: 'json',
            transform: (response) => {
                const clips = response
                    .filter((clip) => !clip.is_deleted)
                    .map((clip) => {
                        const { status } = clip || {};
                        const PROCESSING_STATUS = 'PROCESSING';
                        const FAILED_STATUS = 'FAILED';

                        const shouldDisableClipActions = (row) => {
                            return row.status === PROCESSING_STATUS;
                        };

                        return {
                            ...clip,
                            _isSelectionDisabled: [
                                PROCESSING_STATUS,
                                FAILED_STATUS,
                            ].includes(status),
                            shouldDisable: shouldDisableClipActions,
                            visibleActions:
                                status === FAILED_STATUS ? ['delete'] : null,
                            size:
                                status === PROCESSING_STATUS
                                    ? 'Recording being processed'
                                    : clip.size,
                        };
                    });
                return {
                    ...response,
                    clips,
                };
            },
            service: API_SERVICE.BFF,
        },
    };
}

export function deleteCommunityClips(session_ids, communityId) {
    return {
        [CALL_API]: {
            types: [
                COMMUNITY_CLIPS_DELETE_REQUEST,
                COMMUNITY_CLIPS_DELETE_RESPONSE,
            ],
            endpoint: '/projects',
            method: 'DELETE',
            type: 'json',
            service: API_SERVICE.BFF,
            body: {
                communityId,
                projectIdList: session_ids,
            },
        },
    };
}
