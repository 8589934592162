import { toDateStr, toTimeStr } from 'components/community/common/utils';
import { getDateStr } from 'components/molecules/card/AirmeetCard/utils';
import { SESSION_TYPES } from 'utils/constants/sessions';
import * as Yup from 'yup';

export const CHAR_LIMIT_MAP = {
    name: { min: 1, max: 100 },
    summary: { min: 0, max: 10000 },
    duration: { min: 1, max: 99999 },
    tags: { min: 1, max: 20 },
    tagsCount: { min: 0, max: 100 },
    liveSessionTranslationToken: {
        min: 0,
        max: 100,
    },
};

const SessionFormSchema = Yup.object().shape({
    name: Yup.string()
        .trim()
        .min(
            CHAR_LIMIT_MAP.name.min,
            `Please enter a valid value containing at least ${CHAR_LIMIT_MAP.name.min} character.`
        ),
    startTime: Yup.date().test({
        name: 'start-time-validation',
        test: function (startTime) {
            const { timeZone } = this.options.context.zonedEventTime;
            const currentZonedTime = new Date(
                getDateStr({
                    start_time: new Date(),
                    timezone: timeZone,
                    format: 'YYYY-MM-DD HH:mm:ss',
                })
            );
            const isCreateMode = this.options.context.sessionData
                ? false
                : true;

            // Don't allow sessions to be created in past, allow edits to retain past start time
            if (isCreateMode && startTime < currentZonedTime) {
                if (toDateStr(startTime) < toDateStr(currentZonedTime)) {
                    return this.createError({
                        message: 'Cannot create session for past date',
                        path: 'startTime',
                    });
                } else if (toTimeStr(startTime) < toTimeStr(currentZonedTime)) {
                    return this.createError({
                        message: 'Cannot create session for past time',
                        path: 'initTime',
                    });
                }
            }
            return true;
        },
    }),
    duration: Yup.number().test({
        name: 'duration',
        test: function (duration) {
            const { type, sessionMeta: { videos = [] } = {} } =
                this.options?.parent || {};

            /**
             * if session type is PRE_RECORDED and no video is selected,
             * duration can be empty as session duration is auto calculated to added video duration
             * for any other session type, duration is required
             */

            if (
                type !== SESSION_TYPES.PRE_RECORDED &&
                isNaN(duration) &&
                videos.length > 0
            ) {
                return this.createError({
                    message: 'Duration is required.',
                    path: 'duration',
                });
            }

            if (
                type !== SESSION_TYPES.PRE_RECORDED &&
                (isNaN(duration) ||
                    duration > CHAR_LIMIT_MAP.duration.max ||
                    duration < CHAR_LIMIT_MAP.duration.min)
            ) {
                return this.createError({
                    message: 'Please enter a valid value',
                    path: 'duration',
                });
            }

            return true;
        },
    }),
    host_id: Yup.array().of(Yup.string()).required('Please select a host'),
    interprefyToken: Yup.string()
        .trim()
        .min(
            CHAR_LIMIT_MAP.liveSessionTranslationToken.min,
            'Please enter a valid value.'
        ),
    createRtmpKey: Yup.boolean(),
    sessionVideo: Yup.object().test({
        name: 'session-video-validation',
        test: function () {
            const { type, sessionMeta: { videos = [] } = {} } =
                this.options?.parent || {};

            if (type === SESSION_TYPES.PRE_RECORDED && videos.length === 0) {
                return this.createError({
                    message: 'Please select a video',
                    path: this.path,
                });
            }

            return true;
        },
    }),
});

export const validateFormDataV2 = async (data, options, setErrors) => {
    try {
        await SessionFormSchema.validate(data, options);
        setErrors({});
        return true;
    } catch (error) {
        let errorData = [];
        (error.inner || []).map((inr) => {
            if (!errorData[inr.path]) {
                errorData[inr.path] = inr.message;
            }
            return inr;
        });
        setErrors(errorData);
        return false;
    }
};

export const validateSingleFieldV2 = async (
    name,
    value,
    setErrors,
    opts = {}
) => {
    try {
        await Yup.reach(SessionFormSchema, name).validate(value, opts);
        setErrors((errors) => ({ ...errors, [name]: '' }));
    } catch (error) {
        setErrors((errors) => ({ ...errors, [name]: error.message }));
    }
};

export const castFormDataToSchema = (formData) => {
    return SessionFormSchema.cast(formData);
};
