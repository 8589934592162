import { useDispatch, useSelector } from 'react-redux';
import { setPageProps } from '../store/actions/layout';
import usePropStack from './usePropStack';

/**
 * Allows switching page styles at a component/view level
 */
function usePageStyle(props = {}) {
    const dispatch = useDispatch();
    const { page } = useSelector((state) => state.layout);

    const setNewProps = (newProps) => {
        dispatch(setPageProps(newProps));
    };

    const resetOldProps = (oldProps) => {
        dispatch(setPageProps(oldProps));
    };

    usePropStack({
        resetProps: page,
        setProps: props,
        onSet: setNewProps,
        onReset: resetOldProps,
    });

    return null;
}

export default usePageStyle;
