import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import React from 'react';
import ThemeSlider from './ThemeSlider';

export default function BlurSlider({ value, onChange }) {
    return (
        <FlexDiv flexDirection='column' textAlign='center'>
            <Typography color='ambience.6' variant='subtext2' pb='1'>
                Blur {value}%
            </Typography>
            <ThemeSlider
                value={value}
                onChange={onChange}
                min={0}
                max={100}
                step={1}
                wrapperStyles={{ pb: '8px' }}
            />
        </FlexDiv>
    );
}
