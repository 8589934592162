import { stringify } from 'querystring';
import { API_SERVICE } from '../../utils/apiService';
import { CALL_API } from '../api';
import queryString from 'query-string';
export const SELECT_TEMPLATE_REQUEST = 'SELECT_TEMPLATE_REQUEST';
export const SELECT_TEMPLATE_RESPONSE = 'SELECT_TEMPLATE_RESPONSE';
export const SELECT_FORM_TRIGGER_REQUEST = 'SELECT_FORM_TRIGGER_REQUEST';
export const SELECT_FORM_TRIGGER_RESPONSE = 'SELECT_FORM_TRIGGER_RESPONSE';
export const FETCH_CONTENT_HUB_DETAILS_RESPONSE =
    'FETCH_CONTENT_HUB_DETAILS_RESPONSE';
export const FETCH_CONTENT_HUB_DETAILS_REQUEST =
    'FETCH_CONTENT_HUB_DETAILS_REQUEST';
export const FETCH_CONTENT_LIBRARY_RESPONSE = 'FETCH_CONTENT_LIBRARY_RESPONSE';
export const FETCH_CONTENT_LIBRARY_REQUEST = 'FETCH_CONTENT_LIBRARY_REQUEST';
export const ADD_CONTENT_LIBRARY_RESPONSE = 'ADD_CONTENT_LIBRARY_RESPONSE';
export const ADD_CONTENT_LIBRARY_REQUEST = 'ADD_CONTENT_LIBRARY_REQUEST';
export const FETCH_UPLOADED_TYPES_RESPONSE = 'FETCH_UPLOADED_TYPES_RESPONSE';
export const FETCH_UPLOADED_TYPES_REQUEST = 'FETCH_UPLOADED_TYPES_REQUEST';
export const FETCH_UPLOADED_MEMBERS_RESPONSE =
    'FETCH_UPLOADED_MEMBERS_RESPONSE';
export const FETCH_UPLOADED_MEMBERS_REQUEST = 'FETCH_UPLOADED_MEMBERS_REQUEST';
export const FETCH_PAGINATED_HUBS_REQUEST = 'FETCH_PAGINATED_HUBS_REQUEST';
export const FETCH_PAGINATED_HUBS_RESPONSE = 'FETCH_PAGINATED_HUBS_RESPONSE';
export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_RESPONSE = 'FETCH_EVENTS_RESPONSE';
export const FETCH_TEMPLATES_REQUEST = 'FETCH_TEMPLATES_REQUEST';
export const FETCH_TEMPLATES_RESPONSE = 'FETCH_TEMPLATES_RESPONSE';
export const CREATE_HUB_REQUEST = 'CREATE_HUB_REQUEST';
export const CREATE_HUB_RESPONSE = 'CREATE_HUB_RESPONSE';
export const ARCHIVE_HUB_REQUEST = 'ARCHIVE_HUB_REQUEST';
export const ARCHIVE_HUB_RESPONSE = 'ARCHIVE_HUB_RESPONSE';
export const DELETE_CONTENT_REQUEST = 'DELETE_CONTENT_REQUEST';
export const DELETE_CONTENT_RESPONSE = 'DELETE_CONTENT_RESPONSE';
export const EDIT_CONTENT_REQUEST = 'EDIT_CONTENT_REQUEST';
export const EDIT_CONTENT_RESPONSE = 'EDIT_CONTENT_RESPONSE';
export const EDIT_CONTENT_HUB_REQUEST = 'EDIT_CONTENT_HUB_REQUEST';
export const EDIT_CONTENT_HUB_RESPONSE = 'EDIT_CONTENT_HUB_RESPONSE';
export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';
export const GET_REGISTRATION_FORM_REQUEST = 'GET_REGISTRATION_FORM_REQUEST';
export const GET_REGISTRATION_FORM_RESPONSE = 'GET_REGISTRATION_FORM_RESPONSE';
export const FETCH_PAGINATED_EVENTS_REQUEST = 'FETCH_PAGINATED_EVENTS_REQUEST';
export const FETCH_PAGINATED_EVENTS_RESPONSE =
    'FETCH_PAGINATED_EVENTS_RESPONSE';
export const RESET_PAGINATED_EVENTS = 'RESET_PAGINATED_EVENTS';

export function getTemplates(communityId, userId) {
    return {
        [CALL_API]: {
            types: [FETCH_TEMPLATES_REQUEST, FETCH_TEMPLATES_RESPONSE],
            endpoint: `/templates/${communityId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.CONTENT_HUB,
        },
    };
}
export function getPaginatedHubs(params, communityId, userId) {
    const queryParams = stringify(params || {});
    return {
        [CALL_API]: {
            types: [
                FETCH_PAGINATED_HUBS_REQUEST,
                FETCH_PAGINATED_HUBS_RESPONSE,
            ],
            endpoint: `/content-hub/${communityId}/?${queryParams}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.CONTENT_HUB,
        },
    };
}
export function getContentHubDetails(communityId, contentHubId) {
    return {
        [CALL_API]: {
            types: [
                FETCH_CONTENT_HUB_DETAILS_REQUEST,
                FETCH_CONTENT_HUB_DETAILS_RESPONSE,
            ],
            endpoint: `/content-hub/${communityId}/${contentHubId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.CONTENT_HUB,
        },
    };
}

export function createHub(payload, communityId, userId) {
    return {
        [CALL_API]: {
            types: [CREATE_HUB_REQUEST, CREATE_HUB_RESPONSE],
            endpoint: `/content-hub`,
            service: API_SERVICE.CONTENT_HUB,
            body: { ...payload },
            type: 'json',
            method: 'POST',
        },
    };
}
export function selectTemplate(communityId, contentHubId, template_id) {
    return {
        [CALL_API]: {
            types: [SELECT_TEMPLATE_REQUEST, SELECT_TEMPLATE_RESPONSE],
            endpoint: `/content-hub/template/${contentHubId}`,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.CONTENT_HUB,
            body: {
                template_id: template_id,
                page: {},
                community_id: communityId,
            },
        },
    };
}
export function archiveHub(communityId, userId, contentHubId, is_archive) {
    return {
        [CALL_API]: {
            types: [ARCHIVE_HUB_REQUEST, ARCHIVE_HUB_RESPONSE],
            endpoint: `/content-hub/archive/${contentHubId}`,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.CONTENT_HUB,
            body: { is_archive: !is_archive, community_id: communityId },
        },
    };
}

export function addContentLibrary(payload) {
    return {
        [CALL_API]: {
            types: [ADD_CONTENT_LIBRARY_REQUEST, ADD_CONTENT_LIBRARY_RESPONSE],
            endpoint: `/content-library`,
            service: API_SERVICE.CONTENT_HUB,
            body: { ...payload },
            type: 'json',
            method: 'POST',
        },
    };
}
export function getContentLibrary(params, communityId, contentHubId) {
    // const queryParams = stringify(params || {});
    const queryParams = queryString.stringify(params, {
        arrayFormat: 'bracket',
    });

    return {
        [CALL_API]: {
            types: [
                FETCH_CONTENT_LIBRARY_REQUEST,
                FETCH_CONTENT_LIBRARY_RESPONSE,
            ],
            endpoint: `/content-library/${communityId}/${contentHubId}/?${queryParams}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.CONTENT_HUB,
        },
    };
}
export function getUploadedTypes(communityId, contentHubId) {
    return {
        [CALL_API]: {
            types: [
                FETCH_UPLOADED_TYPES_REQUEST,
                FETCH_UPLOADED_TYPES_RESPONSE,
            ],
            endpoint: `/get-uploaded-types/${communityId}/${contentHubId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.CONTENT_HUB,
        },
    };
}
export function getUploadedMembers(communityId, contentHubId) {
    return {
        [CALL_API]: {
            types: [
                FETCH_UPLOADED_MEMBERS_REQUEST,
                FETCH_UPLOADED_MEMBERS_RESPONSE,
            ],
            endpoint: `/get-uploaded-members/${communityId}/${contentHubId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.CONTENT_HUB,
        },
    };
}

export function deleteContentLibrary(contentHubId, communityId, contentIdList) {
    return {
        [CALL_API]: {
            types: [DELETE_CONTENT_REQUEST, DELETE_CONTENT_RESPONSE],
            endpoint: `/content-library`,
            type: 'json',
            method: 'DELETE',
            service: API_SERVICE.CONTENT_HUB,
            body: {
                id: contentIdList,
                community_id: communityId,
                content_hub_id: contentHubId,
            },
        },
    };
}
export function editContentLibrary(contentId, contentDetails) {
    return {
        [CALL_API]: {
            types: [EDIT_CONTENT_REQUEST, EDIT_CONTENT_RESPONSE],
            endpoint: `/content-library/${contentId}`,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.CONTENT_HUB,
            body: contentDetails,
        },
    };
}
export function editContentHub(editData, contentHubId) {
    return {
        [CALL_API]: {
            types: [EDIT_CONTENT_HUB_REQUEST, EDIT_CONTENT_HUB_RESPONSE],
            endpoint: `/content-hub/edit/${contentHubId}`,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.CONTENT_HUB,
            body: editData,
        },
    };
}

export function uploadMedia(body) {
    return {
        [CALL_API]: {
            types: [UPLOAD_MEDIA],
            endpoint: '/content-library',
            type: 'json',
            method: 'POST',
            body: body,
            service: API_SERVICE.CONTENT_HUB,
        },
    };
}

export function selectFormTrigger(body, trigger_id) {
    return {
        [CALL_API]: {
            types: [SELECT_FORM_TRIGGER_REQUEST, SELECT_FORM_TRIGGER_RESPONSE],
            endpoint: `/form-trigger/${trigger_id}`,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.CONTENT_HUB,
            body: body,
        },
    };
}
export function getRegistrationForm(data) {
    return {
        [CALL_API]: {
            types: [
                GET_REGISTRATION_FORM_REQUEST,
                GET_REGISTRATION_FORM_RESPONSE,
            ],
            endpoint: `/form?entityId=${data.entityId}&entityType=${data.entityType}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.CONTENT_HUB,
        },
    };
}

export function getEvents(params, communityId, userId) {
    const queryParams = stringify(params || {});
    return {
        [CALL_API]: {
            types: [FETCH_EVENTS_REQUEST, FETCH_EVENTS_RESPONSE],
            endpoint: `/airmeet/community/v2/getAirmeets?${queryParams}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function getPaginatedEvents(params, communityId, userId) {
    const queryParams = stringify(params || {});

    return {
        [CALL_API]: {
            types: [
                FETCH_PAGINATED_EVENTS_REQUEST,
                FETCH_PAGINATED_EVENTS_RESPONSE,
            ],
            endpoint: `/airmeet/community/v2/getAirmeets?${queryParams}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function resetPaginatedEvents() {
    return {
        type: RESET_PAGINATED_EVENTS,
    };
}
