import { ALL_FEATURES_CONFIG } from 'hooks/useFeatureControl';
import { useEffect, useMemo } from 'react';
import { RTC_SOURCE_MODULES } from 'utils/constants/common';
import { useDispatch } from 'react-redux';
import { updateRTCVendorConfig } from 'store/actions/liveAirmeet';
import { DEFAULT_VENDOR } from './constants';

const MODULES_WITH_VENDOR_CHANGE_SUPPORT = [
    RTC_SOURCE_MODULES.SOCIAL_LOUNGE,
    RTC_SOURCE_MODULES.BOOTH_LOUNGE,
    RTC_SOURCE_MODULES.SCHEDULED_MEETING,
];

export const useRTCVendor = ({ featuresConfig, platformConfig }) => {
    const dispatch = useDispatch();

    const newVendorConfig = useMemo(() => {
        let newConfig = {};

        const airmeetVendorConfig =
            featuresConfig?.[ALL_FEATURES_CONFIG.RTC_CONFIG] || {};
        const platformVendorConfig =
            platformConfig?.[ALL_FEATURES_CONFIG.RTC_CONFIG] || {};

        MODULES_WITH_VENDOR_CHANGE_SUPPORT.forEach((m) => {
            newConfig[m] =
                airmeetVendorConfig[m]?.vendorName ||
                platformVendorConfig[m]?.vendorName ||
                DEFAULT_VENDOR;
        });

        return newConfig;
    }, [featuresConfig, platformConfig]);

    useEffect(() => {
        dispatch(updateRTCVendorConfig(newVendorConfig));
    }, [newVendorConfig, dispatch]);
};
