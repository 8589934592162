export const TOGGLE_CLOSED_CAPTIONS = 'TOGGLE_CLOSED_CAPTIONS';
export const SET_CLOSED_CAPTIONS_LOADED = 'SET_CLOSED_CAPTIONS_LOADED';
export const SET_CLOSED_CAPTIONS_CONFIG = 'SET_CLOSED_CAPTIONS_CONFIG';

export const toggleClosedCaptions = (value) => ({
    type: TOGGLE_CLOSED_CAPTIONS,
    payload: value,
});

export const setClosedCaptionsLoaded = (value) => ({
    type: SET_CLOSED_CAPTIONS_LOADED,
    payload: value,
});

export const setClosedCaptionsConfig = (value) => ({
    type: SET_CLOSED_CAPTIONS_CONFIG,
    payload: value,
});
