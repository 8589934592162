import { FlexDiv } from 'components/molecules/utils/basicComponents';
import RelativeToolTip from 'components/tooltip/relativeToolTip';
import { Typography } from 'foundations/typography';
import React from 'react';
import styled from 'styled-components';

export default function StatBox({ value, title, info }) {
    return (
        <FlexDiv
            flexDirection='column'
            pr={5}
            pb={5}
            pt={3}
            alignItems='flex-start'
        >
            <Typography variant='h4' color='ambience.0'>
                {value}
            </Typography>
            <Content alignItems='center'>
                <Typography
                    variant='subtext2'
                    color='ambience.1'
                    pr={info ? 1 : '0px'}
                >
                    {title}
                </Typography>
                {info && (
                    <RelativeToolTip
                        place='relative-tooltip top'
                        svgWidth='13.34px'
                        svgHeight='13.34px'
                        svgIcon='icon-fill-info'
                        svgFill='accentPrimary.0'
                        tooltipText={info}
                        textColor='ambience.18'
                        textVariant='caption'
                        textAlign='left'
                        minWidth={250}
                    />
                )}
            </Content>
        </FlexDiv>
    );
}

const Content = styled(FlexDiv)`
    svg {
        fill: ${({ theme }) => theme.colors.accentPrimary[0]};
    }
`;
