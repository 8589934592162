import {
    SET_ONLINE_USERS,
    SET_CLOUD_USERS_COUNT,
} from 'store/actions/onlineUsers';
import createReducer from '../../utils/createReducer';

const initialState = {
    users: [],
    ids: new Map(),
};

const handlers = {};
handlers[SET_ONLINE_USERS] = (state = initialState, action) => {
    return { ...state, ...action.payload };
};

handlers[SET_CLOUD_USERS_COUNT] = (state, action) => {
    const payload = action.payload;
    return { ...state, ...payload };
};

export default createReducer(initialState, handlers);
