import TooltipV2 from 'atoms/Tooltip/Tooltip.tsx';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import RelativeToolTip from 'components/tooltip/relativeToolTip';
import SVGIcon from 'foundations/icon';
import { Text } from 'foundations/themeV2/text';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import React from 'react';
import styled from 'styled-components';

const tooltipContent =
    'You do not have permission to perform this action. Reach out to your account administrator for any queries';
const tooltipHeading = 'Restricted Access';

export const TooltipHead = ({
    darkTheme = true,
    isLightThemePage,
    iconWidth = '14px',
    iconHeight = '17.5px',
    heading = tooltipHeading,
}) => {
    const { theme } = useTheme();

    return (
        <ToolTipText>
            <CustomIconBtn
                icon='icon-close-Lock'
                fill={
                    darkTheme && !isLightThemePage
                        ? theme.colors.ambience[24]
                        : theme.colors.ambience[0]
                }
                width={iconWidth}
                height={iconHeight}
            />
            <ToolTipHead
                variant={'subtext0'}
                isDarkTheme={darkTheme}
                isLightThemePage={isLightThemePage}
            >
                {heading}
            </ToolTipHead>
        </ToolTipText>
    );
};

export const TooltipContent = ({
    tooltipChildren = null,
    darkTheme = true,
    isLightThemePage,
    content = tooltipContent,
}) =>
    tooltipChildren || (
        <ToolTipContent
            variant={'subtext0'}
            isDarkTheme={darkTheme}
            isLightThemePage={isLightThemePage}
        >
            {content}
        </ToolTipContent>
    );

const RestrictedAccountTooltip = ({
    marginRight,
    svgWidth = '16px',
    svgHeight = '20px',
    tooltipPosition = 'bottomCenter',
    textVariant = 'button2',
    tooltipText = '',
    textColor = 'ambience.19',
    textAlign = 'left',
    tooltipChildren = null,
    tooltipContainerStyle = {},
    tooltipPortal = false,
    iconFillColor = 'ambience.13',
    portalTop,
    portalLeft,
    minWidth = 283,
    darkTheme = true,
    svgIcon = 'icon-close-Lock',
    tooltipIconWidth = '14px',
    tooltipIconHeight = '17.5px',
    heading = tooltipHeading,
    content = tooltipContent,
    isThemeV2 = false,
    ...rest
}) => {
    const { isLightTheme } = useTheme();

    return isThemeV2 ? (
        <TooltipV2
            side={tooltipPosition}
            content={
                <>
                    <FlexDiv pb='x1'>
                        <StyledSvgSprite
                            icon='icon-close-Lock'
                            fill='text.default.primary'
                            width={tooltipIconWidth}
                            height={tooltipIconHeight}
                            mr='x1'
                        />
                        <Text variant='h5' color='text.default.primary'>
                            {heading}
                        </Text>
                    </FlexDiv>
                    {tooltipChildren || (
                        <Text variant='caption' color='text.default.secondary'>
                            {content}
                        </Text>
                    )}
                </>
            }
            hasWrapper={true}
            maxWidth='200px'
            marginRight={marginRight || 'x2'}
        >
            <StyledSvgSprite
                icon='icon-close-Lock'
                fill={iconFillColor}
                width={tooltipIconWidth}
                height={tooltipIconHeight}
                {...rest}
            />
        </TooltipV2>
    ) : (
        <RelativeToolTip
            place={tooltipPosition}
            svgWidth={svgWidth}
            svgHeight={svgHeight}
            tooltipArrowTop='-6px'
            svgIcon={svgIcon}
            svgFill={iconFillColor}
            textColor={textColor}
            textAlign={textAlign}
            textVariant={textVariant}
            tooltipText={
                heading && (
                    <TooltipHead
                        darkTheme={darkTheme}
                        isLightThemePage={isLightTheme}
                        iconWidth={tooltipIconWidth}
                        iconHeight={tooltipIconHeight}
                        heading={heading}
                    />
                )
            }
            minWidth={minWidth}
            marginRight={marginRight || '9px'}
            renderElement={
                <TooltipContent
                    darkTheme={darkTheme}
                    tooltipChildren={tooltipChildren}
                    isLightThemePage={isLightTheme}
                    content={content}
                />
            }
            tooltipContainerStyle={tooltipContainerStyle}
            portal={tooltipPortal}
            showStyledSvgSprite={true}
            portalTop={portalTop}
            portalLeft={portalLeft}
            {...rest}
        />
    );
};
const ToolTipText = styled.p`
    margin-bottom: 8px;
    display: flex;
    align-items: center;
`;
const ToolTipHead = styled(Typography)`
    font-size: 14px !important;
    font-family: ${({ theme }) => theme.fonts.bold};
    color: ${({ theme, isDarkTheme, isLightThemePage }) =>
        isDarkTheme && !isLightThemePage
            ? theme.colors.ambience[20]
            : theme.colors.ambience[0]} !important;
`;

const ToolTipContent = styled(Typography)`
    font-size: 14px !important;
    font-family: ${({ theme }) => theme.fonts.book} !important;
    color: ${({ theme, isDarkTheme, isLightThemePage }) =>
        isDarkTheme && !isLightThemePage
            ? theme.colors.ambience[16]
            : theme.colors.ambience[0]} !important;
    text-align: left;
    white-space: normal;
`;

const CustomIconBtn = styled(SVGIcon)`
    margin-right: 12px;
`;

export default RestrictedAccountTooltip;
