import useContainerNotifications from 'atoms/Container/useContainerNotifications';
import EditUserButton from 'components/AirmeetUser/EditUserButton';
import AudioVideoSettingsButton from 'components/AudioVideoSettingsButton/';
import {
    CUSTOMIZE_EVENT_SETTINGS,
    CUSTOMIZE_THEME,
    INTERACTABLE,
    VISIBLE,
} from 'components/community/team/constants';
import { MODAL_TYPE_LANGUAGE } from 'components/modals';
import useUserPermission from 'hooks/roles-and-permissions/useUserPermission';
import useAuthActionHandler from 'hooks/useAuthActionHandler';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import usePostEventReplayAccess from 'hooks/usePostEventReplayAccess';
import keys from 'locale/keys';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showAppModal } from 'store/actions/layout';
import styled from 'styled-components';
import { layout, space, typography } from 'styled-system';
import { noop } from 'utils/constants/common';
import { LOGOUT } from 'utils/constants/containers/notifications.js';
import firebaseLogger from 'utils/firebaseLogger';
import { useSidebarRouter } from '../SidebarRouter';
import CustomizePanel from './CustomizePanel';
import NavItem from './widgets/NavItem';

const MODAL_CLASS = 'POPUP_MODAL_COMMON_CLASS';

const SettingsTab = ({
    isConferenceEvent,
    subDrawer: customizePanelType,
    setSubDrawer: setCustomizePanelType,
}) => {
    const { t } = useCustomTranslation();
    const dispatch = useDispatch();
    const { route } = useSidebarRouter();
    const { isLoggedIn, logout } = useAuthActionHandler();
    const subscriptionData = useSelector(
        (state) => state.Community.subscriptionData
    );
    const myCommunity = useSelector((state) => state.Community.myCommunity);

    const {
        airmeet: {
            data,
            featureFlags: {
                isCustomNavLabelsEnabled,
                isScheduleMeetingEnabled,
            },
        },
        isUGCEnabled,
    } = useLiveAirmeetContext();

    const { currentAirmeet, userRole, userStageRole } = data;

    const airmeet_id = currentAirmeet.airmeetId;

    const { notify } = useContainerNotifications();

    const logoutClicked = useCallback(() => {
        notify(LOGOUT, { source: 'page-unloaded' });
        logout();
    }, []);

    const { isPostEventEntryAllowed } = usePostEventReplayAccess({
        status: currentAirmeet?.status,
        postEventEntry: currentAirmeet?.post_event_entry,
    });
    const { getUserPermissionValues } = useUserPermission();

    const showLanguageModal = () => {
        dispatch(
            showAppModal(MODAL_TYPE_LANGUAGE, {
                airmeetId: currentAirmeet.airmeetId,
                className: MODAL_CLASS,
                backdropClassName: MODAL_CLASS,
            })
        );
    };

    const [customiseEventSettings, customiseTheme] = getUserPermissionValues([
        CUSTOMIZE_EVENT_SETTINGS,
        CUSTOMIZE_THEME,
    ]);
    const isPaidSubscription = useMemo(() => {
        if (!isConferenceEvent) {
            if (subscriptionData?.subscription_type) {
                if (subscriptionData?.subscription_type !== 'FREE') {
                    return true;
                }
            } else {
                // Get subscription_type for meetup for em/cm
                let currentCommunity = null;

                currentCommunity = (myCommunity?.em || []).find(
                    (comm) => comm.communityId === currentAirmeet?.community_id
                );
                if (!currentCommunity) {
                    currentCommunity = (myCommunity?.cm || []).find(
                        (comm) =>
                            comm.communityId === currentAirmeet?.community_id
                    );
                }

                if (
                    currentCommunity?.subscription_type &&
                    currentCommunity?.subscription_type !== 'FREE'
                ) {
                    return true;
                }
            }
            return false;
        }
    }, [myCommunity, currentAirmeet, isConferenceEvent, subscriptionData]);

    return (
        <Container>
            <MainWrapper hide={customizePanelType}>
                {customiseEventSettings[VISIBLE] && (
                    <ActionButton
                        className={`highlight-button ${
                            route?.activeMenu === 'CUSTOMIZE_EVENT'
                                ? 'animate'
                                : ''
                        }`}
                        onClick={
                            customiseEventSettings[INTERACTABLE]
                                ? () => setCustomizePanelType('SETTINGS')
                                : noop
                        }
                        disabled={!customiseEventSettings[INTERACTABLE]}
                    >
                        <NavItem
                            title={t(keys.RHS_SETTINGS_CUSTOMIZE_TITLE)}
                            desc={t(
                                isConferenceEvent
                                    ? keys.RHS_SETTINGS_CUSTOMIZE_SUBTITLE_CONF
                                    : keys.RHS_SETTINGS_CUSTOMIZE_SUBTITLE_MEETUP
                            )}
                            hasSubNav={true}
                            restricted={!customiseEventSettings[INTERACTABLE]}
                        />
                    </ActionButton>
                )}
                {customiseTheme[VISIBLE] && (
                    <ActionButton
                        onClick={
                            customiseEventSettings[INTERACTABLE]
                                ? () => {
                                      firebaseLogger.customFont(
                                          'eventBrandingClicked'
                                      );
                                      setCustomizePanelType('BRANDING');
                                  }
                                : noop
                        }
                        disabled={!customiseTheme[INTERACTABLE]}
                    >
                        <NavItem
                            title={t(keys.RHS_SETTINGS_BRANDING_TITLE)}
                            desc={t(
                                isConferenceEvent || isPaidSubscription
                                    ? keys.RHS_SETTINGS_BRANDING_SUBTITLE_CONF
                                    : keys.RHS_SETTINGS_BRANDING_SUBTITLE_MEETUP
                            )}
                            hasSubNav={true}
                            restricted={!customiseTheme[INTERACTABLE]}
                        />
                    </ActionButton>
                )}

                {!isPostEventEntryAllowed && (
                    <Box p='8px 6px 8px 0' mb='16px'>
                        <EditUserButton
                            className={MODAL_CLASS}
                            backdropClassName={MODAL_CLASS}
                        >
                            <NavItem
                                title={t(keys.RHS_SETTINGS_PROFILE_TITLE)}
                                desc={t(keys.RHS_SETTINGS_PROFILE_SUBTITLE)}
                            />
                        </EditUserButton>
                    </Box>
                )}

                {!isPostEventEntryAllowed && (
                    <Box p='8px 6px 8px 0' mb='16px'>
                        <AudioVideoSettingsButton
                            className={MODAL_CLASS}
                            backdropClassName={MODAL_CLASS}
                        >
                            <NavItem
                                title={t(keys.RHS_SETTINGS_AV_TITLE)}
                                desc={t(keys.RHS_SETTINGS_AV_SUBTITLE)}
                            />
                        </AudioVideoSettingsButton>
                    </Box>
                )}

                <LinkButton
                    href={`/airmeets/${airmeet_id}/summary`}
                    target='_blank'
                >
                    <NavItem
                        title={t(keys.RHS_SETTINGS_DASHBOARD_TITLE)}
                        desc={t(keys.RHS_SETTINGS_DASHBOARD_SUBTITLE)}
                    />
                </LinkButton>

                <ActionButton onClick={showLanguageModal}>
                    <NavItem
                        title={t(keys.RHS_SETTINGS_LANGUAGE_TITLE)}
                        desc={t(keys.RHS_LANGUAGE_PREFERENCE)}
                    />
                </ActionButton>

                {isLoggedIn && (
                    <ButtonWrapper>
                        <LogoutButton onClick={logoutClicked}>
                            {t(keys.RHS_SETTINGS_LOGOUT)}
                        </LogoutButton>
                    </ButtonWrapper>
                )}
            </MainWrapper>
            {customizePanelType && (
                <CustomizePanel
                    onToggle={setCustomizePanelType}
                    isConference={isConferenceEvent}
                    userRole={userRole}
                    userStageRole={userStageRole}
                    isCustomNavLabelsEnabled={isCustomNavLabelsEnabled}
                    isScheduleMeetingEnabled={isScheduleMeetingEnabled}
                    isUGCEnabled={isUGCEnabled}
                    type={customizePanelType}
                />
            )}
        </Container>
    );
};

export default SettingsTab;

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex: 1;
    flex-basis: 0;
    overflow: auto;
    position: relative;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
        width: 0px;
        background: transparent;
    }
`;

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 17px;
    margin-bottom: 17px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    will-change: opacity, transform;
    opacity: 1;
    transform: translateX(0);
    transition: opacity, transform 0.4s ease;

    ${({ hide }) =>
        hide
            ? `
                opacity: 0;
                transform: translateX(-100%);
            `
            : ''}
`;

const LinkButton = styled.a`
    outline: 0 !important;
    text-decoration: none !important;
    padding: 8px 6px 8px 0;
    margin-bottom: 16px;
    cursor: pointer;
`;

const Box = styled.div`
    ${space}
    ${layout}
    ${typography}
`;

const ButtonWrapper = styled.div`
    padding-bottom: 16px;
`;

const ActionButton = styled.button`
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-family: ${({ theme }) => theme.fonts.book};
    outline: 0 !important;
    box-shadow: none;
    border: 0;
    padding: 8px 6px 8px 0;
    margin-bottom: 16px;
    background: transparent;

    @keyframes flash {
        0%,
        25%,
        75% {
            background: rgba(157, 154, 172, 0.16);
        }

        50%,
        100% {
            background: transparent;
        }
    }

    &.highlight-button {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -20px;
            right: -20px;
            z-index: -1;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            display: none;
        }

        &.animate {
            &:before {
                animation-name: flash;
                display: block;
            }
        }
    }
    ${({ lockCustomizeEvent, theme }) =>
        lockCustomizeEvent &&
        `
        p {
            color: ${theme.text.default.secondary};
            cursor: auto !important;
        }
    `}
    ${({ disabled }) =>
        disabled &&
        `
        cursor: not-allowed !important;
    `}
`;

const LogoutButton = styled.button`
    margin-top: 8px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text.default.primary};
    font-size: ${({ theme }) => theme.fontSizes.x2};
    line-height: ${({ theme }) => theme.lineHeights.x2};
    font-family: ${({ theme }) => theme.fonts.bold};
    outline: 0 !important;
    box-shadow: none;
    border: 0;
    padding: 0;
    background: transparent;
`;
