import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import {
    PERSISTENT_BACKSTAGE_BAR,
    hasAccess,
    isOrganizer,
} from 'utils/userAccessControl';
import { SESSION_TYPES } from 'utils/constants/sessions';
import { useLiveSessionContext } from 'context/Session';
import { getStageUsersLocation } from 'store/selectors';
import PermissionUtils from 'utils/permission-utils';

const useCanAccessPersistentStage = () => {
    const user = useLiveAirmeetContext((ctx) => ctx.user);

    const isLive = useLiveSessionContext((ctx) => ctx.isLive);
    const currentSession = useLiveSessionContext(
        (ctx) => ctx.currentSession || {}
    );
    const isPersistentBackstageEnabled = useLiveSessionContext(
        (ctx) => ctx.isPersistentBackstageEnabled
    );
    const stageUsersLocation = useSelector(getStageUsersLocation);

    const _liveUsersVisibility = useMemo(() => {
        const userData = Object.values(stageUsersLocation)
            .filter((i) => i?.visibility?.visible)
            .map((i) => i.userId);
        return userData;
    }, [stageUsersLocation]);

    const _isUserVisibilityExists = useMemo(
        () =>
            !!Object.values(stageUsersLocation).find(
                (i) => i.userId === user.id
            ),
        [stageUsersLocation, user.id]
    );

    const allowToPublishOnStage = useMemo(
        () => _liveUsersVisibility.includes(user.id),
        [_liveUsersVisibility, user.id]
    );
    const isUserAllowedToPublishOnStage = useCallback(
        (userId) => {
            return _liveUsersVisibility.includes(userId);
        },
        [_liveUsersVisibility]
    );
    const canAccessPersistentBar =
        !PermissionUtils.isEventCloudHost() &&
        isPersistentBackstageEnabled &&
        currentSession?.type === SESSION_TYPES.HOSTING &&
        hasAccess(PERSISTENT_BACKSTAGE_BAR.VIEW) &&
        (isLive && isOrganizer() ? _isUserVisibilityExists : true); // if event is live, org can see join backstage button
    const canAccessMinimizedPersistentBar =
        !PermissionUtils.isEventCloudHost() &&
        isPersistentBackstageEnabled &&
        hasAccess(PERSISTENT_BACKSTAGE_BAR.VIEW);

    const allowToPublishOnBackstage =
        !allowToPublishOnStage &&
        hasAccess(PERSISTENT_BACKSTAGE_BAR.JOIN_BACKSTAGE);

    return {
        allowToPublishOnStage,
        allowToPublishOnBackstage,
        canAccessPersistentBar,
        canAccessMinimizedPersistentBar,
        isUserAllowedToPublishOnStage,
    };
};

export default useCanAccessPersistentStage;
