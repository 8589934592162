import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { WORKER_NAME_LOGGER } from '../utils/constants/workers';
import { initWorker as initLogWorker } from '../utils/logger';
import { stopWorker } from '../workers';

function useLogWorkerManager() {
    const airmeetId = useSelector(
        (state) =>
            state.cmDashboard?.airmeet?.airmeetId ||
            state.lounge?.airmeet?.airmeetId
    );
    const userId = useSelector((state) => state.auth.user?.id);
    useEffect(() => {
        initLogWorker({ id: userId }, airmeetId);

        return () => {
            const stopLogWorker = async () => {
                await stopWorker(WORKER_NAME_LOGGER);
            };

            stopLogWorker();
        };
    }, [airmeetId, userId]);
}

export default useLogWorkerManager;
