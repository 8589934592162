import { API_SERVICE } from 'utils/apiService';
import {
    REACT_APP_PLATFORM_BASE_URL,
    REACT_APP_PLATFORM_BASE_URL_EU,
    REACT_APP_PLATFORM_BASE_URL_IN,
    REACT_APP_PLATFORM_BASE_URL_US,
} from 'utils/constants/airmeet';
import { DATA_CENTER_REGION_IDS } from 'utils/constants/dataCenter';
import { whiteLabelString } from 'utils/core';
import { CALL_API } from '../../api';

export const INTEGRATION_LIST_REQUEST = 'INTEGRATION_LIST_REQUEST';
export const AIRMEET_INTEGRATION_LIST_REQUEST =
    'AIRMEET_INTEGRATION_LIST_REQUEST';
export const ORGANIZATION_LIST_REQUEST = 'ORGANIZATION_LIST_REQUEST';
export const EVENTS_LIST_REQUEST = 'EVENTS_LIST_REQUEST';
export const ADD_PRIVATE_TOKEN_REQUEST = 'ADD_PRIVATE_TOKEN_REQUEST';
export const SAVE_INTEGRATION_REQUEST = 'SAVE_INTEGRATION_REQUEST';
export const REMOVE_INTEGRATION_REQUEST = 'REMOVE_INTEGRATION_REQUEST';
export const DISABLE_INTEGRATION_REQUEST = 'DISABLE_INTEGRATION_REQUEST';
export const CRM_REDIRECTION_URL = 'CRM_REDIRECTION_URL';
export const WEBHOOK_URL_REQUEST = 'WEBHOOK_URL_REQUEST';
export const AIRMEET_FIELDS = 'AIRMEET_FIELDS';
export const MAILCHIMP_FIELDS = 'MAILCHIMP_FIELDS';
export const INTEGRATION_FILTERS = 'INTEGRATION_FILTERS';
export const INTEGRATION_DETAILS = 'INTEGRATION_DETAILS';
export const INTEGRATION_FEATURE_PLANS = 'INTEGRATION_FEATURE_PLANS';
export const INTEGRATION_JWT_TOKEN = 'INTEGRATION_JWT_TOKEN';
export const INTEGRATION_FEATURE_PLANS_CRM_COMMUNITY =
    'INTEGRATION_FEATURE_PLANS_COMMUNITY';
export const COBALT_TEMPLATES = 'COBALT_TEMPLATES';
export const INTEGRATION_CRM_METADATA = 'INTEGRATION_CRM_METADATA';
export const DEEPER_INTEGRATIONS_BULK = 'DEEPER_INTEGRATIONS_BULK';
export const COMMUNITY_TOKEN = 'COMMUNITY_TOKEN';
export const GET_SLACK_INTEGRATION = 'GET_SLACK_INTEGRATION';
export const GET_HUBSPOT_INTEGRATION = 'GET_HUBSPOT_INTEGRATION';
export const GET_HUBSPOT_SYNC = 'GET_HUBSPOT_SYNC';
export const GET_EVENT_SERIES_INTEGRATIONS = 'GET_EVENT_SERIES_INTEGRATIONS';
export const GET_EVENT_SERIES_CRM_DATA = 'GET_EVENT_SERIES_CRM_DATA';
export const ENABLE_PIXEL_GTM = 'ENABLE_PIXEL_GTM';
export const DISABLE_EVENT_SERIES = 'DISABLE_EVENT_SERIES';
export const GET_HUBSPOT_SYNC_REQUEST = 'GET_HUBSPOT_SYNC_REQUEST';
export const GET_HUBSPOT_SYNC_RESPONSE = 'GET_HUBSPOT_SYNC_RESPONSE';
export const GET_COMMUNITY_CUSTOM_FIELDS = 'GET_COMMUNITY_CUSTOM_FIELDS';
export const GET_COMMUNITY_CRM_FIELDS = 'GET_COMMUNITY_CRM_FIELDS';
export const GET_COMMUNITY_CUSTOM_ACTIVITY = 'GET_COMMUNITY_CUSTOM_ACTIVITY';
export const SAVE_COMMUNITY_CONFIG = 'SAVE_COMMUNITY_CONFIG';
export const GET_COMMUNITY_CONFIG = 'GET_COMMUNITY_CONFIG';
export const UPDATE_SAVED_CONFIG = 'UPDATE_SAVED_CONFIG';
export const RESYNC_INTEGRATION = 'RESYNC_INTEGRATION';
export const ENABLE_DEEPER_INTEGRATION = 'ENABLE_DEEPER_INTEGRATION';

export function getIntegrationList(communityId, data) {
    return {
        [CALL_API]: {
            types: [INTEGRATION_LIST_REQUEST],
            endpoint: `/integration/community/${communityId}`,
            type: 'json',
            body: data,
            method: 'POST',
            service: API_SERVICE.PLATFORM,
            transform: (response) => {
                response.integrations = (response.integrations || []).map(
                    (integration) => {
                        const newIntegration = { ...(integration || {}) };
                        newIntegration.description = whiteLabelString(
                            newIntegration.description
                        );
                        return newIntegration;
                    }
                );
                return response;
            },
        },
    };
}
export function getAllCategories() {
    return {
        [CALL_API]: {
            types: [INTEGRATION_FILTERS],
            endpoint: '/integration/categories',
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}
export function getIntegrationDetail(communityId, integrationId) {
    return {
        [CALL_API]: {
            types: [INTEGRATION_DETAILS],
            endpoint: `/integration/${integrationId}/community/${communityId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function getAirmeetIntegrations(airmeetId) {
    return {
        [CALL_API]: {
            types: [AIRMEET_INTEGRATION_LIST_REQUEST],
            endpoint: `/integration/airmeet/${airmeetId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
            transform: (response) => {
                response.integrations = (response.integrations || []).map(
                    (integration) => {
                        const newIntegration = { ...(integration || {}) };
                        newIntegration.description = whiteLabelString(
                            newIntegration.description
                        );
                        return newIntegration;
                    }
                );
                return response;
            },
        },
    };
}

export function getOrganizations(crm, communityId, value) {
    const query = {
        crm,
        communityId,
    };
    if (value) {
        query.organisationName = value;
    }

    return {
        [CALL_API]: {
            types: [ORGANIZATION_LIST_REQUEST],
            endpoint: '/integration/organisation-list',
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
            query,
        },
    };
}

export function getEvents(crm, communityId, orgId) {
    const query = {
        crm,
        communityId,
    };
    if (orgId) {
        query.organisationId = orgId;
    }

    return {
        [CALL_API]: {
            types: [EVENTS_LIST_REQUEST],
            endpoint: '/crm-events-list',
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
            query,
        },
    };
}

export function enableIntegration(data) {
    const { airmeetId, crm } = data;
    return {
        [CALL_API]: {
            types: [],
            endpoint: `integration/airmeet/${airmeetId}/crm/${crm}`,
            body: data,
            type: 'json',
            method: 'POST',
        },
    };
}

export function addPrivateToken(data) {
    const { airmeetCommunityId } = data;
    return {
        [CALL_API]: {
            types: [ADD_PRIVATE_TOKEN_REQUEST],
            endpoint: `/integration/community/${airmeetCommunityId}/airmeet-community-keys`,
            body: data,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function saveIntegration(data) {
    const {
        webhook: { entityId: airmeetId, crmName },
    } = data;
    return {
        [CALL_API]: {
            types: [SAVE_INTEGRATION_REQUEST],
            endpoint: `/integration/airmeet/${airmeetId}/crm/${crmName}`,
            body: data,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.PLATFORM,
        },
    };
}
export function updateIntegration(data, crmWebhookId, airmeetId) {
    return {
        [CALL_API]: {
            types: [SAVE_INTEGRATION_REQUEST],
            endpoint: `/crm-webhook/${crmWebhookId}/airmeet/${airmeetId}`,
            body: data,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function removeIntegration(communityId, crm, authExpire = false) {
    return {
        [CALL_API]: {
            types: [REMOVE_INTEGRATION_REQUEST],
            endpoint: `/integration/community/${communityId}/crm/${crm}${
                authExpire ? '?authExpire=true' : ''
            }`,
            type: 'json',
            method: 'DELETE',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function disableIntegration(airmeetId, integrationId) {
    return {
        [CALL_API]: {
            types: [DISABLE_INTEGRATION_REQUEST],
            endpoint: `/integration/${integrationId}/airmeet/${airmeetId}`,
            type: 'json',
            method: 'DELETE',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function getCrmRedirectionUrl(crm, communityId) {
    return {
        [CALL_API]: {
            types: [CRM_REDIRECTION_URL],
            endpoint: `/oauth/fetch-redirect-url/crm/${crm}/community/${communityId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function getWebhookUrl(crm, airmeetId) {
    return {
        [CALL_API]: {
            types: [WEBHOOK_URL_REQUEST],
            endpoint: `/webhook/${crm}/airmeet/${airmeetId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function getInegration(airmeetId, crmWebhookId) {
    return {
        [CALL_API]: {
            types: [AIRMEET_FIELDS],
            endpoint: `/crm-webhook/${crmWebhookId}/airmeet/${airmeetId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}
export function getAirmeetFields(airmeetId, crm = '') {
    const customFields = [
        'hubspot',
        'marketo',
        'pipedrive',
        'activecampaign',
        'keap',
        'pardot',
    ];
    let flag = customFields.includes(crm);
    return {
        [CALL_API]: {
            types: [AIRMEET_FIELDS],
            endpoint: flag
                ? `/fetch-airmeet-custom-fields?airmeetId=${airmeetId}&allCustomFields=true`
                : `/fetch-airmeet-custom-fields?airmeetId=${airmeetId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function getMailchimpFields(crm, crmOrgId, airmeetId) {
    return {
        [CALL_API]: {
            types: [MAILCHIMP_FIELDS],
            endpoint: `/fetch-custom-fields?name=${crm}&entityId=${crmOrgId}&airmeetId=${airmeetId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}
export function getCustomActivity(crm, airmeetId) {
    return {
        [CALL_API]: {
            types: [INTEGRATION_FILTERS],
            endpoint: `/deeper-integrations/airmeet/${airmeetId}?crmName=${crm}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}
export function updateCustomActivity(crm, airmeetId, data) {
    return {
        [CALL_API]: {
            types: [INTEGRATION_FILTERS],
            endpoint: `/deeper-integrations/airmeet/${airmeetId}/crmName/${crm}`,
            body: data,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.PLATFORM,
        },
    };
}

// feature-plans
export function getFeaturePlans(communityId) {
    return {
        [CALL_API]: {
            types: [INTEGRATION_FEATURE_PLANS],
            endpoint: `/integration/community/${communityId}/feature-plans`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}
// Get JWT Token
export function getIntegrationPlatformJWTToken(
    communityId,
    integrationPlatform
) {
    return {
        [CALL_API]: {
            types: [INTEGRATION_JWT_TOKEN],
            endpoint: `/generate-jwt-token/crm/${integrationPlatform}/community/${communityId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}
// community feature-plans
export function getCRMCommunityBasedOnFeaturePlans(crmName, data, region) {
    let platformServiceEndpoint = REACT_APP_PLATFORM_BASE_URL;
    switch (region) {
        case DATA_CENTER_REGION_IDS.IN:
            platformServiceEndpoint = REACT_APP_PLATFORM_BASE_URL_IN;
            break;
        case DATA_CENTER_REGION_IDS.EU:
            platformServiceEndpoint = REACT_APP_PLATFORM_BASE_URL_EU;
            break;
        case DATA_CENTER_REGION_IDS.US:
            platformServiceEndpoint = REACT_APP_PLATFORM_BASE_URL_US;
            break;
        default:
            break;
    }
    return {
        [CALL_API]: {
            types: [INTEGRATION_FEATURE_PLANS_CRM_COMMUNITY],
            endpoint: `${platformServiceEndpoint}/integration/community/feature-plans/crm/${crmName}`,
            body: data,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.PLATFORM_MULTI_REGION,
        },
    };
}

// feature-plans
export function getCrmMetaData(crm, communityId) {
    return {
        [CALL_API]: {
            types: [INTEGRATION_CRM_METADATA],
            endpoint: `/community/${communityId}/crmName/${crm}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}
export function deeperIntegrationBulk(crmName, airmeetId, data) {
    return {
        [CALL_API]: {
            types: [DEEPER_INTEGRATIONS_BULK],
            endpoint: `/deeper-integrations/bulk/airmeet/${airmeetId}/crmName/${crmName}`,
            body: data,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function getCommunityToken({ crmName, communityId }) {
    return {
        [CALL_API]: {
            types: [COMMUNITY_TOKEN],
            endpoint: `/community-token/${communityId}/crmName/${crmName}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}
export function getSlackIntegration({ airmeetId }) {
    return {
        [CALL_API]: {
            types: [GET_SLACK_INTEGRATION],
            endpoint: `/deeper-integrations/airmeet/${airmeetId}?crmName=slack`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
            headers: {
                'Content-Type': 'application/json',
            },
        },
    };
}

export function getHubspotSync({ communityId }) {
    return {
        [CALL_API]: {
            types: [GET_HUBSPOT_SYNC_REQUEST, GET_HUBSPOT_SYNC_RESPONSE],
            endpoint: `/segmentation/community/${communityId}/hubspot-sync`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.ANALYTICS,
            headers: {
                'Content-Type': 'application/json',
            },
        },
    };
}
export function getCommunityFields({ communityId }) {
    return {
        [CALL_API]: {
            types: [GET_COMMUNITY_CUSTOM_FIELDS],
            endpoint: `/community/${communityId}/fetch-community-custom-fields`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function getCommunityCrmFields({ communityId, crm }) {
    return {
        [CALL_API]: {
            types: [GET_COMMUNITY_CRM_FIELDS],
            endpoint: `/community/${communityId}/fetch-custom-fields?name=${crm}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function getCommunityCustomActivity({ communityId, crm }) {
    return {
        [CALL_API]: {
            types: [GET_COMMUNITY_CUSTOM_ACTIVITY],
            endpoint: `/community/${communityId}/deeper-integration?crmName=${crm}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function SaveCommunityConfig({ communityId, crm, data }) {
    return {
        [CALL_API]: {
            types: [SAVE_COMMUNITY_CONFIG],
            endpoint: `/community/${communityId}/default-integration-setting/crm/${crm}`,
            type: 'json',
            method: 'POST',
            body: data,
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function getSavedCommunityConfig({ communityId, crm, airmeetId = '' }) {
    return {
        [CALL_API]: {
            types: [GET_COMMUNITY_CONFIG],
            endpoint: airmeetId
                ? `/community/${communityId}/default-integration-setting/${crm}?entity_id=${airmeetId}`
                : `/community/${communityId}/default-integration-setting/${crm}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function UpdateSavedConfig({ communityId, id, data }) {
    return {
        [CALL_API]: {
            types: [UPDATE_SAVED_CONFIG],
            endpoint: `/community/${communityId}/default-integration-setting/${id}`,
            body: data,
            method: 'PATCH',
            type: 'json',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function ResyncCrm({ airmeetId, crm }) {
    return {
        [CALL_API]: {
            types: [RESYNC_INTEGRATION],
            endpoint: `/integration/crm-sync/airmeet/${airmeetId}/crm/${crm}`,
            method: 'POST',
            type: 'json',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function getEventSeriesIntegrations({ seriesId }) {
    return {
        [CALL_API]: {
            types: [GET_EVENT_SERIES_INTEGRATIONS],
            endpoint: `/integration/eventSeries/${seriesId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function enableEnrichmentStatus({ communityId, crm, data }) {
    return {
        [CALL_API]: {
            types: ['ENABLE_ENRICHMENT'],
            endpoint: `/community/${communityId}/crm/${crm}/deeper-integration`,
            method: 'POST',
            type: 'json',
            body: data,
            service: API_SERVICE.PLATFORM,
        },
    };
}
export function getEventSeriesCrmData({ seriesId, crm }) {
    return {
        [CALL_API]: {
            types: [GET_EVENT_SERIES_CRM_DATA],
            endpoint: `/integration/eventSeries/${seriesId}/crm/${crm}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function EnablePixelGtm({ seriesId, crm, data }) {
    return {
        [CALL_API]: {
            types: [ENABLE_PIXEL_GTM],
            endpoint: `/integration/eventSeries/${seriesId}/crm/${crm}`,
            body: data,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function updateEnrichmentStatus({ communityId, id, data }) {
    return {
        [CALL_API]: {
            types: ['UPDATE_ENRICHMENT'],
            endpoint: `/community/${communityId}/deeper-integration/${id}`,
            method: 'PUT',
            type: 'json',
            body: data,
            service: API_SERVICE.PLATFORM,
        },
    };
}
export function disableEventSeries({ seriesId, integrationId }) {
    return {
        [CALL_API]: {
            types: [DISABLE_EVENT_SERIES],
            endpoint: `/integration/${integrationId}/eventSeries/${seriesId}`,
            method: 'DELETE',
            type: 'json',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function getSeriesLandingPage({ eventSeriesId }) {
    return {
        [CALL_API]: {
            types: ['GET_SERIES_LANDING_PAGE'],
            endpoint: `/integration/eventSeries/dashboard/${eventSeriesId}`,
            method: 'GET',
            type: 'json',
            service: API_SERVICE.PLATFORM,
        },
    };
}

export function saveCobaltIntegration({ airmeetId, crm, data, update }) {
    return {
        [CALL_API]: {
            types: ['CREATE_CONFIG'],
            endpoint: `/airmeet/${airmeetId}/crmName/${crm}`,
            method: update ? 'PUT' : 'POST',
            type: 'json',
            body: data,
            service: API_SERVICE.PLATFORM,
        },
    };
}
export function enableDeeperIntegration({ communityId, crm, type, isEnabled }) {
    return {
        [CALL_API]: {
            types: [ENABLE_DEEPER_INTEGRATION],
            endpoint: `/community/${communityId}/enable-deeper-integration/crm/${crm}`,
            body: { type, isEnabled },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.PLATFORM,
        },
    };
}
