import { WORKER_IDS, terminateTimeoutWorker } from 'utils/timeoutWorker';
import LogInterface from './LogInterface';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';

export type EventCallback = (data: HeartBeat) => void;

export interface HeartBeatEvent {
    [key: string]: string | number | object | any;
}
export interface HeartBeat {
    airmeet_id: string;
    airmeet_visit_id: string;
    attendee_id: string;
    timestamp_utc: number;
    event_name: string;
    event_source: string;
    [key: string]: string | number | object | any;
}
// Observer class for heartbeats, behaves like an event emitter
export class HeartBeatObserver {
    constructor(logger: LogInterface) {
        if (HeartBeatObserver.instance) {
            throw new Error('HeartBeatObserver already instantiated!');
        }
        HeartBeatObserver.instance = this;
        this.logger = logger;
    }
    private callbacks: EventCallback[] = [];
    static instance: HeartBeatObserver = null;
    private logger: LogInterface = null;
    private eventStatus: string = '';
    private heartBeatCommon: HeartBeat = {
        airmeet_id: '',
        airmeet_visit_id: '',
        attendee_id: '',
        event_name: '',
        event_source: '',
        context_visit_ids: [],
        timestamp_utc: 0,
    };

    private historicalContextData = [];

    setHeartbeatCommon(opts: HeartBeat) {
        this.heartBeatCommon = opts;
    }

    getHeartbeatMeta() {
        return this.heartBeatCommon;
    }

    listenHeartbeats(callback: EventCallback): void {
        this.callbacks.push(callback);
    }

    stopListening(callback: EventCallback): void {
        this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    }

    onAirmeetFinished(status: string): void {
        this.eventStatus = status;
        this.removeAllListeners();
    }

    sendHeartbeat(data: HeartBeatEvent, context_visit_id: string): void {
        if (this.callbacks.length === 0) {
            this.logger.info(
                `No listeners for heartbeats, ${
                    this.eventStatus === AIRMEET_STATUS.FINISHED
                        ? 'event is in FINISHED state'
                        : ''
                }`
            );
            return;
        }
        let _heartBeat: HeartBeat;
        /* ---- An empty heartbeat means user has left a child context (e.g., session, booth, table) and still
        in the event, in that case default heartbeat needs to be sent ---- */
        if (Object.keys(data).length === 0) {
            _heartBeat = {
                ...this.heartBeatCommon,
                context_visit_ids: [
                    ...this.heartBeatCommon.context_visit_ids,
                    ...this.historicalContextData,
                ],
            };
        } else {
            this.historicalContextData.push({ context_visit_id });
            _heartBeat = {
                ...this.heartBeatCommon,
                ...data,
                context_visit_ids: [
                    ...this.heartBeatCommon.context_visit_ids,
                    ...this.historicalContextData,
                ],
            };
        }
        this.callbacks.forEach((callback) => callback(_heartBeat));
    }

    removeAllListeners(): void {
        this.callbacks = [];
        terminateTimeoutWorker(WORKER_IDS.HEARTBEATS);
    }
}
