export const MODAL_TYPES = {
    ADD_SPONSOR: 'Add a Sponsor',
    EDIT_SPONSOR: 'Edit Sponsor',
    MANAGE_TIERS: 'Manage Sponsor Tiers',
};

export const TIER_TYPE = 'groupHeader';
export const SPONSOR_IMAGE_REQUEST_KEY = 'sponsorImage';

export const MAX_SPONSORS_PER_TIER = 50;
export const MAX_TIERS = 10;
export const MIN_TIERS = 1;
export const TIER_REQUIRED = 'Tier name is required';
export const DUPLICATE_TIER_NAME = 'Tier name is duplicate';

export const SPONSORS_LIST_UPDATE_TYPE = {
    CHANGE_ORDER: 'CHANGE_ORDER',
    DELETE_SPONSOR: 'DELETE_SPONSOR',
};

export const SPONSOR_IMG_FILE_PREFIX = 'sponsor_logo';
