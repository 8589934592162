import { useProfiling } from 'context/Profiling';
import { useEffect } from 'react';
import useGetMyCommunityCheck from 'hooks/community/useGetMyCommunityCheck';
export default function useRouteBase(props) {
    const { perf } = useProfiling();
    const airmeetId = props?.computedMatch?.params?.id;
    useGetMyCommunityCheck({ ...props, airmeetId });
    useEffect(() => {
        if (airmeetId) {
            perf.addAttribute('airmeetId', airmeetId);
        }
    }, [airmeetId, perf]);

    return null;
}
