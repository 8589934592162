import Button from 'atoms/Button/button';
import { EVENTS_PAGE_TAB } from 'components/community/constants';
import { Typography } from 'foundations/typography';
import useCustomBaseUrl from 'hooks/useCustomBaseUrl';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { color, flexbox, layout, space, typography } from 'styled-system';
import { cdnImage } from 'utils/core';
import { redirectToHomePage } from 'utils/redirections';
import { getCurrentTenantData } from 'utils/tenant';

function ThirdPartyOfferDeprecated({ isLoggedIn = false }) {
    const history = useHistory();
    const myCommunity = useSelector((state) => state.Community.myCommunity);
    const { baseUrl } = useCustomBaseUrl();

    const handler = () => {
        const communityList = myCommunity.list ? myCommunity.list : [];

        if (isLoggedIn) {
            if (communityList.length > 0) {
                history.push(
                    `/community-manager/${communityList[0].communityId}/${EVENTS_PAGE_TAB}`
                );
            } else {
                // if no communities, redirect to home/root page
                redirectToHomePage(baseUrl);
            }
        } else {
            history.push('/signin');
        }
    };

    return (
        <Box>
            <Image
                src={cdnImage('/images/appsumo_deprecated.svg')}
                alt=' appsum0-depricated'
                width={295}
                height={125}
            />
            <Typography variant='h4' color='ambience.1' mt={4} mb={4}>
                Our AppSumo deal has ended!
            </Typography>
            <Typography variant='body2' color='ambience.3'>
                Our Appsumo deal has ended on{' '}
                <BoldText fontWeight='bold'>10th March 2021</BoldText>. Check
                out the{' '}
                <Links
                    href='https://appsumo.com/airmeet/'
                    color='accentPrimary.0'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    {'Terms & Conditions'}
                </Links>{' '}
                for further details.
            </Typography>
            {!isLoggedIn && (
                <Typography variant='body2' color='ambience.0' mt={5}>
                    Meanwhile, you can try {getCurrentTenantData('name')} to
                    host your first event for free, and see how it goes. We are
                    sure you’re going to love it. ❤️
                </Typography>
            )}

            <StyledButton variant='primary' onClick={handler} mt={5}>
                {isLoggedIn ? 'Go to dashboard' : 'Sign in'}
            </StyledButton>
        </Box>
    );
}

export default ThirdPartyOfferDeprecated;

const Box = styled.div`
    ${space}
    ${layout}
${color}
${flexbox}
`;

const BoldText = styled.span`
    ${typography}
`;

const Links = styled.a`
    text-decoration: none !important;
    color: ${(props) => props.color} !important;
`;

const StyledButton = styled(Button)`
    ${space}
    ${layout}
`;

const Image = styled.img``;
