import Btn from 'atoms/Button/btn';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import BaseModalV2 from 'components/modals/templates/BaseModalV2';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import React from 'react';
import styled from 'styled-components';
import { rgbaAlpha } from 'utils/colors';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import keys from 'locale/keys';

export default function CcConfirmModal({ onClose, onConfirm }) {
    const { t } = useCustomTranslation();
    return (
        <BaseModalV2
            onClose={onClose}
            contentStyle={{
                padding: '1rem',
                maxWidth: '20rem',
            }}
            hideClose
        >
            <Wrapper flexDirection='column' alignItems='stretch'>
                <Text variant='h5' pb='x2'>
                    {t(keys.CLOSED_CAPTION_LANG_CHANGE_MODAL_TITLE)}
                </Text>
                <Text variant='caption' color='text.default.primary' pb='x5'>
                    {t(keys.CLOSED_CAPTION_LANG_CHANGE_MODAL_MESSAGE)}
                </Text>
                <WarningBox>
                    <StyledSvgSprite
                        icon='ic-info-circle-o'
                        fill='sem.warning'
                        height='1.25rem'
                        width='1.25rem'
                    />
                    <Text
                        variant='caption'
                        color='text.default.secondary'
                        pl='x1'
                    >
                        {t(keys.CLOSED_CAPTION_LANG_CHANGE_MODAL_DESCRIPTION)}
                    </Text>
                </WarningBox>
                <FlexDiv gap='0.5rem'>
                    <Btn size='small' variant='danger' onClick={onConfirm}>
                        {t(keys.GENERAL_CONFIRM)}
                    </Btn>
                    <Btn size='small' variant='secondary' onClick={onClose}>
                        {t(keys.GENERAL_CANCEL)}
                    </Btn>
                </FlexDiv>
            </Wrapper>
        </BaseModalV2>
    );
}

const Wrapper = styled(FlexDiv)`
    text-align: left;
    flex-direction: column;
    align-items: stretch;
`;

const WarningBox = styled(FlexDiv)`
    margin-bottom: ${({ theme }) => theme.space.x5};
    padding: ${({ theme }) => theme.space.x3};
    border-radius: ${({ theme }) => theme.radii.x2};
    border: solid 1px ${({ theme }) => theme.colors.border.default1};
    background-color: ${({ theme }) =>
        rgbaAlpha(theme.colors.sem.warning, 0.2)};
`;
