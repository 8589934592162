import {
    ADD_SCRIPT,
    getWidgetScript,
    REMOVE_SCRIPT,
    scriptOperation,
} from './utils';

export const FC_SCRIPT_ID = 'fcChatWidget';
export const freshChatScript = `
            function initFreshChat() {
                window.fcWidget.init({
                    token: '9a4fd043-637a-43c1-85bb-0871c8a74739',
                    host: 'https://wchat.in.freshchat.com',
                    config: {
                        headerProperty: {
                            hideChatButton: true,
                        },
                    },
                });
    
                window.fcWidget.on('widget:closed', function () {
                    document.getElementById('open_fc_widget').style.visibility =
                        'visible';
                });
    
                window.fcWidget.on('widget:opened', function (resp) {
                    document.getElementById('open_fc_widget').style.visibility =
                        'hidden';
                });
            }
            function initialize(i, t) {
                var e;
                i.getElementById(t)
                    ? initFreshChat()
                    : (((e = i.createElement('script')).id = t),
                      (e.async = !0),
                      (e.src = 'https://wchat.in.freshchat.com/js/widget.js'),
                      (e.onload = initFreshChat),
                      i.head.appendChild(e));
            }
            function initiateCall() {
                if (!window.location.href.includes('embedded'))
                    initialize(document, 'freshchat-js-sdk');
            }

            if (document.readyState === 'complete') {
                initiateCall();
              } else {
                window.addEventListener
                ? window.addEventListener('load', initiateCall, !1)
                : window.attachEvent('load', initiateCall, !1);
            }
            window.recaptchaOptions = {
                useRecaptchaNet: true,
            };
            
            function openFreshChatWidget() {
                if (window.fcWidget && window.fcWidget.isOpen() != true) {
                    window.fcWidget.open();
                }
            }`;

export const addFreshChatButton = () => {
    if (!getWidgetScript('custom_fc_button')) {
        const html = document.createElement('div');
        html.id = 'custom_fc_button';
        const link = document.createElement('button');
        link.id = 'open_fc_widget';
        link.className = 'open-fc-widget';

        link.addEventListener('click', function () {
            window.openFreshChatWidget();
        });

        const imageChild = document.createElement('img');
        imageChild.id = 'imageicon';
        imageChild.src =
            'https://www.airmeet.com/hub/wp-content/uploads/2021/03/Help-Icon.svg';
        imageChild.alt = 'Help Icon';
        link.appendChild(imageChild);
        html.appendChild(link);

        document.body.appendChild(html);
    }
};

export const removeFreshChatButton = () => {
    const link = document.getElementById('open_fc_widget');
    if (link) {
        link.removeEventListener('click', function () {
            window.openFreshChatWidget();
        });
        document.body.removeChild(getWidgetScript('custom_fc_button'));
    }
};

export const addFreshChat = () => {
    addFreshChatButton();
    scriptOperation(ADD_SCRIPT, FC_SCRIPT_ID, freshChatScript);
};

export const removeFreshChat = () => {
    removeFreshChatButton();
    scriptOperation(REMOVE_SCRIPT, FC_SCRIPT_ID);
};
