import { logger } from 'utils/logger';
type Task<T> = (any?) => Promise<T>;
interface Execution<T> {
    requestName: string;
    task: Task<T>;
    onComplete: Function;
    onError: Function;
}
export default class RequestQueue<T> {
    private currentPending: Promise<T>;
    private pendingExecutions: Array<Execution<T>> = [];
    private runningExecutions: Array<Execution<T>> = [];
    private maxConcurrent = 1;
    enqueue(task: Task<T>, requestName: string) {
        return new Promise<T>(async (resolve, reject) => {
            try {
                if (
                    this.pendingExecutions.some(
                        (req) => req.requestName === requestName
                    )
                ) {
                    logger.info('Removing duplicate request', requestName);
                    return;
                }

                this.pendingExecutions.push({
                    requestName,
                    task,
                    onComplete: resolve,
                    onError: reject,
                });

                await this.process();
            } catch (e) {
                logger.error('Request processing failed', e);
            }
        });
    }

    async process() {
        while (this.shouldRun()) {
            if (this.currentPending) {
                await this.currentPending;
            }

            // remove request from pending execution array
            let pendingExecution = this.pendingExecutions.shift();

            // push that request to running execution
            this.runningExecutions.push(pendingExecution);

            // current pending now has a task ( waiting to be resolved )
            this.currentPending = pendingExecution.task();

            await this.currentPending
                .then((response) => {
                    // remove from running execution
                    this.runningExecutions.shift();
                    pendingExecution.onComplete(response);
                    this.currentPending = null;
                })
                .catch((err) => {
                    // TODO - Add a error queue to handle failed requests
                    pendingExecution.onComplete(err);
                    this.currentPending = null;
                    this.runningExecutions.shift();
                });
        }

        return false;
    }

    shouldRun() {
        return (
            this.runningExecutions.length < this.maxConcurrent &&
            this.pendingExecutions.length > 0
        );
    }
}
