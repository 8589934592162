import { getIsMeetupLiteEvent } from 'context/LiveAirmeet';
import useSessionReplayAccessURL from 'hooks/live-airmeet/useSessionReplayAccessURL';
import useDeviceInfo from 'hooks/useDeviceInfo';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import usePostEventReplayAccess from 'hooks/usePostEventReplayAccess';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SESSION_STATUS } from 'utils/constants/sessions';
import firebaseLogger from 'utils/firebaseLogger';

const useSessionPlayback = (props) => {
    const sessionId = props ? props.sessionid : null;
    const {
        airmeet: {
            airmeetId,
            data: {
                userRole,
                currentAirmeet: {
                    status: currentAirmeetStatus,
                    post_event_entry: postEventEntry,
                    live_config,
                },
            },
            featureFlags: { isSessionReplayEnabled },
        },
        user: { id: userId },
        featureDataClients: { stage: firebaseClient },
    } = useLiveAirmeetContext();

    const airmeet = useSelector((state) => state.lounge.airmeet);
    const { isMobile } = useDeviceInfo();
    const sessionStatus = useSelector(
        (state) =>
            state.lounge.airmeet?.sessions?.find(
                (session) => session.sessionid === sessionId
            )?.status
    );
    const { isSessionPlaybackDisabled } = live_config || {};

    const isMeetupLiteEvent = useLiveAirmeetContext(getIsMeetupLiteEvent);

    const { isPostEventEntryAllowed } = usePostEventReplayAccess({
        status: currentAirmeetStatus,
        postEventEntry: postEventEntry,
    });

    const isSessionPlaybackGlobalEnabled = isMeetupLiteEvent
        ? isPostEventEntryAllowed
        : !isSessionPlaybackDisabled;

    const playbackVideoDataPath = `${airmeetId}/meta-data/sessions/${sessionId}/playbackVideoInfo`;
    const [playbackAvailable, setPlaybackAvailable] = useState(
        !!firebaseClient.getLoadedValues(playbackVideoDataPath) || false
    );
    const videoPlayedDurationKey = `recordedVideoPlayedDuration-${sessionId}`;

    const sessionPlaybackFeatureEnabled = isSessionReplayEnabled;

    const sessionPlaybackEventLogRef = useRef(null);
    sessionPlaybackEventLogRef.current = (event, data) => {
        firebaseLogger.sessionPlayback(event, {
            airmeet_title: airmeet.name,
            users_id: userId,
            user_role: userRole,
            is_mobile_device: isMobile,
            ...data,
        });
    };

    // Session level firebase flag for session playback
    const dataPath = `${airmeetId}/meta-data/sessions/${sessionId}/genral/isSessionPlayback`;

    const [isSessionPlaybackEnabled, setIsSessionPlaybackEnabled] = useState(
        firebaseClient.getLoadedValues(dataPath) !== false
    );

    useEffect(() => {
        if (!sessionPlaybackFeatureEnabled || !sessionId) {
            return;
        }
        const getValue = ({ value }) =>
            setIsSessionPlaybackEnabled(value !== false);
        firebaseClient.getDataSync(dataPath, getValue);

        return () => {
            firebaseClient.clearDataSync(dataPath, getValue);
        };
    }, [firebaseClient, dataPath, sessionPlaybackFeatureEnabled, sessionId]);

    // recordings firebase data for session playback

    const canSessionPlayback = useMemo(
        () =>
            sessionPlaybackFeatureEnabled &&
            isSessionPlaybackEnabled &&
            sessionStatus === SESSION_STATUS.FINISHED &&
            playbackAvailable,
        [
            sessionPlaybackFeatureEnabled,
            isSessionPlaybackEnabled,
            sessionStatus,
            playbackAvailable,
        ]
    );

    const playbackVideoInfo = useSessionReplayAccessURL({
        userId,
        airmeetId,
        sessionId: sessionId,
        live: false,
        validateUrl: true,
        canFetch: canSessionPlayback && props && props.fetchPlaybackURL,
    });

    const {
        isAllowed: isAllowToAccess,
        url: playbackVideoInfoUrl,
        isMP4URL,
        isM3U8URL,
        isValidUrl,
        error,
    } = useMemo(() => playbackVideoInfo || {}, [playbackVideoInfo]);

    const videoPlayedDuration = useMemo(
        () =>
            sessionStorage.getItem(videoPlayedDurationKey) ||
            (!isMP4URL && isValidUrl ? 0 : -1),
        [videoPlayedDurationKey, isMP4URL, isValidUrl]
    );
    useEffect(() => {
        if (!sessionPlaybackFeatureEnabled || !sessionId) {
            return;
        }
        const getValue = ({ value }) => {
            setPlaybackAvailable(!!value);
        };
        firebaseClient
            .getEmitter()
            .on(`${playbackVideoDataPath}_value`, getValue);

        if (firebaseClient.hasAlreadyLoadedDataRef(playbackVideoDataPath)) {
            getValue({
                value: firebaseClient.getLoadedValues(playbackVideoDataPath),
            });
        }
        firebaseClient.getDataRef(playbackVideoDataPath);

        return () => {
            firebaseClient
                .getEmitter()
                .off(`${playbackVideoDataPath}_value`, getValue);
        };
    }, [
        firebaseClient,
        sessionPlaybackFeatureEnabled,
        playbackVideoDataPath,
        sessionId,
        dataPath,
    ]);

    const sessionPlaybackURL = useMemo(() => {
        if (
            !isSessionPlaybackEnabled ||
            !isSessionPlaybackGlobalEnabled ||
            !isAllowToAccess ||
            !isValidUrl ||
            !playbackVideoInfoUrl
        ) {
            return;
        }
        if (isMP4URL) {
            return `${playbackVideoInfoUrl}#t=${videoPlayedDuration}`;
        }

        return playbackVideoInfoUrl;
    }, [
        isAllowToAccess,
        isSessionPlaybackEnabled,
        isSessionPlaybackGlobalEnabled,
        isValidUrl,
        isMP4URL,
        playbackVideoInfoUrl,
        videoPlayedDuration,
    ]);

    return {
        isSessionPlaybackEnabled:
            sessionPlaybackFeatureEnabled && isSessionPlaybackEnabled,
        sessionPlaybackURL: sessionPlaybackFeatureEnabled && sessionPlaybackURL,
        canSessionPlayback,
        isSessionPlaybackGlobalEnabled:
            sessionPlaybackFeatureEnabled && isSessionPlaybackGlobalEnabled,
        sessionPlaybackEventLogRef,
        isSecure: playbackVideoInfo.isSecure,
        isMP4URL,
        isM3U8URL,
        videoStartPosition: !isMP4URL && isValidUrl ? videoPlayedDuration : 0,
        error,
    };
};

export default useSessionPlayback;
