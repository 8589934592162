import Button from 'atoms/Button/button';
import InputField from 'atoms/Input/input';
import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import { getCustomScroll } from 'components/molecules/utils/customStyles';
import Icon from 'foundations/icon';
import { alpha, stringToHSL } from 'foundations/theme';
import { Typography } from 'foundations/typography';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import useTheme from 'hooks/useTheme';
import keys from 'locale/keys';
import Slider from 'rc-slider';
import React, { useState } from 'react';
import usePortal from 'react-cool-portal';
import styled from 'styled-components';
import { background } from 'styled-system';
import { hexToHSL, hslToHex, isValidHex } from 'utils/colors';
import { noop } from 'utils/constants/common';

export default function ColorPicker({
    onSave,
    onModalClose = noop,
    value = '',
    portalContainer = '',
    showInsidePortal = true,
    isEmailTemplate = false,
    enableBackDropFilter = false,
    RecentColorsPallete = [],
    hideBackdrop = false,
    top,
    right,
}) {
    const { t } = useCustomTranslation();
    const { isLightTheme, theme } = useTheme();
    let defaultHex = '#6A4CFF',
        defaultH = 250,
        defaultS = 100,
        defaultL = 65;
    if (value) {
        if (value.startsWith('hsl(')) {
            const values = stringToHSL(value);
            if (Array.isArray(values) && values.length >= 4) {
                defaultHex = hslToHex(values[1], values[2], values[3]);
                defaultH = values[1];
                defaultS = values[2];
                defaultL = values[3];
            }
        } else if (isValidHex(value)) {
            defaultHex = value;
            const { h, s, l } = hexToHSL(value);
            defaultH = h;
            defaultS = s;
            defaultL = l;
        }
    }

    const { Portal } = usePortal({
        clickOutsideToHide: false,
        escToHide: false,
        containerId: portalContainer ? portalContainer : 'sidebarWrapper',
    });

    const [color, setColor] = useState({
        h: defaultH,
        s: defaultS,
        l: defaultL,
        hex: defaultHex,
    });
    const [hue, setHue] = useState(defaultH);
    const [saturation, setSaturation] = useState(defaultS);
    const [lightness, setLightness] = useState(defaultL);
    const [hex, setHex] = useState(defaultHex);

    const handleHexChange = (e) => {
        const value = e.target.value;
        setHex(value);

        if (isValidHex(value)) {
            const HSL = hexToHSL(value);

            let newColor = { ...color };
            newColor.h = HSL.h;
            newColor.s = HSL.s;
            newColor.l = HSL.l;
            newColor.hex = value;
            setColor(newColor);
            setHue(newColor.h);
            setSaturation(newColor.s);
            setLightness(newColor.l);
        }
    };

    const handleHSLChange = (value, colorKey) => {
        switch (colorKey) {
            case 'h':
                setHue(value);
                break;
            case 's':
                setSaturation(value);
                break;
            case 'l':
                setLightness(value);
                break;
            default:
                break;
        }

        const newColor = { ...(color || {}) };
        newColor[colorKey] = value;
        const hex = hslToHex(newColor.h, newColor.s, newColor.l);
        if (hex) {
            newColor.hex = hex;
            setHex(hex);
            setColor(newColor);
        }
    };

    const onColorSave = () => {
        onSave(color);
        onModalClose && onModalClose();
    };

    const onModalCancel = () => {
        onModalClose && onModalClose();
    };

    const inputLabelStyle = {
        zIndex: 1,
        top: '12px',
        color: `${theme.colors.ambience[12]}`,
        lineHeight: '16px',
    };
    const inputStyle = {
        color: isLightTheme
            ? theme.colors.ambience[4]
            : theme.colors.ambience[2],
        background: isLightTheme
            ? alpha(theme.colors.ambience[24], 0.64)
            : alpha(theme.colors.ambience[21], 0.64),
    };

    const inpLabelStyle = {
        display: 'none',
    };

    const inpStyle = {
        padding: '8px 12px',
        background: isLightTheme
            ? alpha(theme.colors.ambience[24], 0.64)
            : alpha(theme.colors.ambience[21], 0.64),
    };

    const handleKeyDown = (e) => {
        if (e.key === '.') {
            e.preventDefault();
        }
    };

    const activePaletteColor = `hsl(${defaultH},${defaultS}%,${defaultL}%)`;
    const colorPickers = () => {
        return (
            <>
                <PickerContainer
                    isEmailTemplate={isEmailTemplate}
                    top={top}
                    right={right}
                >
                    <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        mb={isEmailTemplate ? 3 : 4}
                    >
                        <Typography
                            variant='subtitle2'
                            color={theme.colors.ambience[0]}
                        >
                            {t(keys.COLOR_PICKER_TITLE)}
                        </Typography>
                        <Box>
                            {!isEmailTemplate && (
                                <IconButton onClick={onColorSave}>
                                    <Icon
                                        icon='checkmark'
                                        width={16}
                                        height={16}
                                        fill={
                                            isLightTheme
                                                ? theme.colors.semantic[2]
                                                : alpha(
                                                      theme.colors.ambience[0],
                                                      0.8
                                                  )
                                        }
                                    />
                                </IconButton>
                            )}
                            <IconButton
                                onClick={onModalCancel}
                                isEmailTemplate={isEmailTemplate}
                            >
                                <Icon
                                    icon='dismiss'
                                    width={16}
                                    height={16}
                                    fill={
                                        isLightTheme
                                            ? theme.colors.semantic[3]
                                            : alpha(
                                                  theme.colors.ambience[0],
                                                  0.8
                                              )
                                    }
                                />
                            </IconButton>
                        </Box>
                    </Box>
                    <ScrollView
                        verticalWidth={'6px'}
                        isEmailTemplate={isEmailTemplate}
                    >
                        {isEmailTemplate &&
                            RecentColorsPallete &&
                            RecentColorsPallete.length > 0 && (
                                <ColorSwatchWrapper>
                                    {RecentColorsPallete.map((color, index) => (
                                        <SwatchWrap
                                            key={'color_palette_' + index}
                                        >
                                            <ColorSwatch
                                                background={color}
                                                isChecked={
                                                    color === activePaletteColor
                                                }
                                                onClick={() => onSave(color)}
                                            />
                                            {color === activePaletteColor && (
                                                <IconWrapper>
                                                    <Icon
                                                        icon={
                                                            'checkmark_circle'
                                                        }
                                                        fill={
                                                            theme.colors
                                                                .ambience[
                                                                isLightTheme
                                                                    ? 24
                                                                    : 0
                                                            ]
                                                        }
                                                        width={16}
                                                        height={16}
                                                    />
                                                </IconWrapper>
                                            )}
                                        </SwatchWrap>
                                    ))}
                                </ColorSwatchWrapper>
                            )}
                        <ColorPreviewBox background={color.hex} />
                        <Box mt='16px' mb='24px'>
                            <InputField
                                type='text'
                                name='color-code'
                                id='color-code'
                                required={true}
                                labelText='Hex code'
                                labelHtmlFor='color-code'
                                value={hex}
                                onChange={handleHexChange}
                                labelStyle={inputLabelStyle}
                                style={inputStyle}
                            />
                        </Box>
                        <PickerWrapper>
                            <Box mb='16px' display='flex' alignItems='center'>
                                <Typography
                                    variant='subtext2'
                                    color={theme.colors.ambience[12]}
                                >
                                    H
                                </Typography>
                                <Box flex={1} px='16px'>
                                    <HueSlider
                                        value={hue}
                                        min={0}
                                        max={360}
                                        step={1}
                                        onChange={(e) =>
                                            handleHSLChange(e, 'h')
                                        }
                                    />
                                </Box>
                                <Box width='56px'>
                                    <InputField
                                        type='number'
                                        name='hue-value'
                                        id='hue-value'
                                        required={true}
                                        labelText=''
                                        value={hue}
                                        onChange={(e) =>
                                            handleHSLChange(
                                                e?.target?.value,
                                                'h'
                                            )
                                        }
                                        labelStyle={inpLabelStyle}
                                        style={inpStyle}
                                        min={0}
                                        max={360}
                                        hideInputArrows={true}
                                    />
                                </Box>
                            </Box>
                            <Box mb='16px' display='flex' alignItems='center'>
                                <Typography
                                    variant='subtext2'
                                    color={theme.colors.ambience[12]}
                                >
                                    S
                                </Typography>
                                <Box flex={1} px='16px'>
                                    <SaturationSlider
                                        value={saturation}
                                        currentColor={color.h}
                                        min={0}
                                        max={100}
                                        defaultValue={100}
                                        step={1}
                                        onChange={(e) =>
                                            handleHSLChange(e, 's')
                                        }
                                    />
                                </Box>
                                <Box width='56px'>
                                    <InputField
                                        type='number'
                                        name='sat-value'
                                        id='sat-value'
                                        required={true}
                                        labelText=''
                                        value={saturation}
                                        onChange={(e) =>
                                            handleHSLChange(
                                                e?.target?.value,
                                                's'
                                            )
                                        }
                                        labelStyle={inpLabelStyle}
                                        style={inpStyle}
                                        min={0}
                                        max={100}
                                        hideInputArrows={true}
                                        onKeyDown={handleKeyDown}
                                    />
                                </Box>
                            </Box>
                            <Box display='flex' alignItems='center'>
                                <Typography
                                    variant='subtext2'
                                    color={theme.colors.ambience[12]}
                                >
                                    L
                                </Typography>
                                <Box flex={1} px='16px'>
                                    <LightnessSlider
                                        value={lightness}
                                        currentColor={color.h}
                                        min={0}
                                        max={100}
                                        defaultValue={50}
                                        step={1}
                                        onChange={(e) =>
                                            handleHSLChange(e, 'l')
                                        }
                                        onKeyDown={handleKeyDown}
                                    />
                                </Box>
                                <Box width='56px'>
                                    <InputField
                                        type='number'
                                        name='lightness-value'
                                        id='lightness-value'
                                        required={true}
                                        labelText=''
                                        value={lightness}
                                        onChange={(e) =>
                                            handleHSLChange(
                                                e?.target?.value,
                                                'l'
                                            )
                                        }
                                        labelStyle={inpLabelStyle}
                                        style={inpStyle}
                                        min={0}
                                        max={100}
                                        hideInputArrows={true}
                                        onKeyDown={handleKeyDown}
                                    />
                                </Box>
                            </Box>
                        </PickerWrapper>
                    </ScrollView>
                    {isEmailTemplate && (
                        <FlexDiv mt={3}>
                            <StyledButton
                                variant='primary'
                                onClick={onColorSave}
                            >
                                Done
                            </StyledButton>
                        </FlexDiv>
                    )}
                </PickerContainer>
                {hideBackdrop ? null : (
                    <BackDrop
                        isEmailTemplate={isEmailTemplate}
                        enableBackDropFilter={enableBackDropFilter}
                    />
                )}
            </>
        );
    };
    return showInsidePortal ? (
        <Portal>{colorPickers()}</Portal>
    ) : (
        colorPickers()
    );
}

const BackDrop = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9992;
    background-color: ${({ theme, isEmailTemplate }) =>
        isEmailTemplate
            ? alpha(theme.colors.ambience[20], 0.1)
            : alpha(theme.colors.ambience[24], 0.64)};
    backdrop-filter: ${({ enableBackDropFilter }) =>
        enableBackDropFilter ? 'blur(1px)' : 'none'};
`;

const IconButton = styled.button`
    outline: 0 !important;
    border: 0;
    background: ${({ theme, isEmailTemplate }) =>
        theme.isLightTheme
            ? theme.colors.ambience[24]
            : isEmailTemplate
            ? 'transparent'
            : alpha(theme.colors.ambience[23], 0.64)};

    width: ${({ isEmailTemplate }) => (isEmailTemplate ? '24px' : '36px')};
    height: ${({ isEmailTemplate }) => (isEmailTemplate ? '24px' : '36px')};
    border-radius: 50%;
    margin-left: 8px;
    transition: all 0.4s ease;
    ${({ theme }) =>
        theme.isLightTheme &&
        `
        box-shadow: 0px 2px 2px ${alpha(theme.colors.black, 0.08)};
    `}
    &:hover {
        opacity: 0.9;
    }
    &:first-child {
        margin-left: 0px;
    }
`;

const PickerContainer = styled.div`
    max-width: ${({ isEmailTemplate }) =>
        isEmailTemplate ? '408px' : '402px'};
    width: 100%;
    background: ${({ theme, isEmailTemplate }) =>
        theme.isLightTheme
            ? theme.colors.ambience[24]
            : isEmailTemplate
            ? theme.colors.ambience[20]
            : theme.colors.ambience[22]};
    padding: ${({ isEmailTemplate }) => (isEmailTemplate ? '24px' : '32px')};
    border: 1px solid rgba(157, 154, 172, 0.16);
    border-radius: 16px;
    position: ${({ isEmailTemplate }) =>
        isEmailTemplate ? 'absolute' : 'fixed'};
    top: ${({ top }) => top || '50%'};
    transform: ${({ isEmailTemplate }) =>
        isEmailTemplate ? 'translate(50%, -50%)' : 'translateY(-50%)'};
    right: ${({ isEmailTemplate, right }) =>
        right || (isEmailTemplate ? '50%' : '290px')};
    z-index: 9993;
    max-height: 90vh;

    .input-wrapper {
        border: 1px solid
            ${({ theme }) => alpha(theme.colors.ambience[21], 0.64)};
        ${({ isEmailTemplate, theme }) =>
            isEmailTemplate &&
            `
                background: ${theme.colors.ambience[19]};
                input{
                    background: transparent !important;
                } 
            `};
    }
`;

const ColorPreviewBox = styled.div`
    ${background}
    max-width: 100%;
    width: 100%;
    height: 64px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => alpha(theme.colors.ambience[9], 0.16)};
    margin-bottom: 16px;
`;

const PickerWrapper = styled.div`
    .input-wrapper {
        margin-bottom: 0;
    }
`;

const HueSlider = styled(Slider)`
    position: relative;
    height: 14px;
    padding: 5px 0;
    width: 100%;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: ${({ theme }) => alpha(theme.colors.black, 0)};

    .rc-slider-rail {
        position: absolute;
        width: 100%;
        height: 4px;
        border-radius: 6px;
        background: -webkit-linear-gradient(
            left,
            hsla(0, 100%, 50%, 1),
            hsla(10, 100%, 50%, 1),
            hsla(20, 100%, 50%, 1),
            hsla(30, 100%, 50%, 1),
            hsla(40, 100%, 50%, 1),
            hsla(50, 100%, 50%, 1),
            hsla(60, 100%, 50%, 1),
            hsla(70, 100%, 50%, 1),
            hsla(80, 100%, 50%, 1),
            hsla(90, 100%, 50%, 1),
            hsla(100, 100%, 50%, 1),
            hsla(110, 100%, 50%, 1),
            hsla(120, 100%, 50%, 1),
            hsla(130, 100%, 50%, 1),
            hsla(140, 100%, 50%, 1),
            hsla(150, 100%, 50%, 1),
            hsla(160, 100%, 50%, 1),
            hsla(170, 100%, 50%, 1),
            hsla(180, 100%, 50%, 1),
            hsla(190, 100%, 50%, 1),
            hsla(200, 100%, 50%, 1),
            hsla(210, 100%, 50%, 1),
            hsla(220, 100%, 50%, 1),
            hsla(230, 100%, 50%, 1),
            hsla(240, 100%, 50%, 1),
            hsla(250, 100%, 50%, 1),
            hsla(260, 100%, 50%, 1),
            hsla(270, 100%, 50%, 1),
            hsla(280, 100%, 50%, 1),
            hsla(290, 100%, 50%, 1),
            hsla(300, 100%, 50%, 1),
            hsla(310, 100%, 50%, 1),
            hsla(320, 100%, 50%, 1),
            hsla(330, 100%, 50%, 1),
            hsla(340, 100%, 50%, 1),
            hsla(350, 100%, 50%, 1),
            hsla(360, 100%, 50%, 1)
        );
        background: -moz-linear-gradient(
            left,
            hsla(0, 100%, 50%, 1),
            hsla(10, 100%, 50%, 1),
            hsla(20, 100%, 50%, 1),
            hsla(30, 100%, 50%, 1),
            hsla(40, 100%, 50%, 1),
            hsla(50, 100%, 50%, 1),
            hsla(60, 100%, 50%, 1),
            hsla(70, 100%, 50%, 1),
            hsla(80, 100%, 50%, 1),
            hsla(90, 100%, 50%, 1),
            hsla(100, 100%, 50%, 1),
            hsla(110, 100%, 50%, 1),
            hsla(120, 100%, 50%, 1),
            hsla(130, 100%, 50%, 1),
            hsla(140, 100%, 50%, 1),
            hsla(150, 100%, 50%, 1),
            hsla(160, 100%, 50%, 1),
            hsla(170, 100%, 50%, 1),
            hsla(180, 100%, 50%, 1),
            hsla(190, 100%, 50%, 1),
            hsla(200, 100%, 50%, 1),
            hsla(210, 100%, 50%, 1),
            hsla(220, 100%, 50%, 1),
            hsla(230, 100%, 50%, 1),
            hsla(240, 100%, 50%, 1),
            hsla(250, 100%, 50%, 1),
            hsla(260, 100%, 50%, 1),
            hsla(270, 100%, 50%, 1),
            hsla(280, 100%, 50%, 1),
            hsla(290, 100%, 50%, 1),
            hsla(300, 100%, 50%, 1),
            hsla(310, 100%, 50%, 1),
            hsla(320, 100%, 50%, 1),
            hsla(330, 100%, 50%, 1),
            hsla(340, 100%, 50%, 1),
            hsla(350, 100%, 50%, 1),
            hsla(360, 100%, 50%, 1)
        );
    }
    .rc-slider-track {
        position: absolute;
        left: 0;
        height: 4px;
        border-radius: 6px;
        background: transparent;
    }
    .rc-slider-step {
        position: absolute;
        width: 100%;
        height: 4px;
        background: transparent;
    }
    .rc-slider-handle {
        position: absolute;
        width: 14px;
        height: 14px;
        cursor: pointer;
        cursor: -webkit-grab;
        margin-top: -5px;
        cursor: grab;
        border-radius: 50%;
        border: 2px solid rgba(157, 154, 172, 0.16);
        background-color: #fcfcfc;
        touch-action: pan-x;
    }
    .rc-slider-mark {
        position: absolute;
        top: 18px;
        left: 0;
        width: 100%;
        font-size: 12px;
    }
`;

const SaturationSlider = styled(HueSlider)`
    .rc-slider-rail {
        background: -webkit-linear-gradient(
            left,
            hsla(${({ currentColor }) => currentColor}, 0%, 50%, 1),
            hsla(${({ currentColor }) => currentColor}, 20%, 50%, 1),
            hsla(${({ currentColor }) => currentColor}, 40%, 50%, 1),
            hsla(${({ currentColor }) => currentColor}, 60%, 50%, 1),
            hsla(${({ currentColor }) => currentColor}, 80%, 50%, 1),
            hsla(${({ currentColor }) => currentColor}, 100%, 50%, 1)
        );
        background: -moz-linear-gradient(
            left,
            hsla(${({ currentColor }) => currentColor}, 0%, 50%, 1),
            hsla(${({ currentColor }) => currentColor}, 20%, 50%, 1),
            hsla(${({ currentColor }) => currentColor}, 40%, 50%, 1),
            hsla(${({ currentColor }) => currentColor}, 60%, 50%, 1),
            hsla(${({ currentColor }) => currentColor}, 80%, 50%, 1),
            hsla(${({ currentColor }) => currentColor}, 100%, 50%, 1)
        );
    }
`;
const LightnessSlider = styled(HueSlider)`
    .rc-slider-rail {
        background: -webkit-linear-gradient(
            left,
            hsla(${({ currentColor }) => currentColor}, 100%, 0%, 1),
            hsla(${({ currentColor }) => currentColor}, 100%, 20%, 1),
            hsla(${({ currentColor }) => currentColor}, 100%, 40%, 1),
            hsla(${({ currentColor }) => currentColor}, 100%, 60%, 1),
            hsla(${({ currentColor }) => currentColor}, 100%, 80%, 1),
            hsla(${({ currentColor }) => currentColor}, 100%, 100%, 1)
        );
        background: -moz-linear-gradient(
            left,
            hsla(${({ currentColor }) => currentColor}, 100%, 0%, 1),
            hsla(${({ currentColor }) => currentColor}, 100%, 20%, 1),
            hsla(${({ currentColor }) => currentColor}, 100%, 40%, 1),
            hsla(${({ currentColor }) => currentColor}, 100%, 60%, 1),
            hsla(${({ currentColor }) => currentColor}, 100%, 80%, 1),
            hsla(${({ currentColor }) => currentColor}, 100%, 100%, 1)
        );
    }
`;
const ColorSwatchWrapper = styled(FlexDiv)`
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32px, 1fr));
    grid-gap: 12px 12px;
    align-items: center;
    margin-bottom: 24px;
`;
const SwatchWrap = styled(FlexDiv)`
    min-width: 32px;
    justify-content: center;
    margin-right: 16px;
    position: relative;
    &:last-child {
        margin-right: 0;
    }
`;
const ColorSwatch = styled(Box)`
    ${background}
    cursor: pointer;
    border-radius: 56%;
    width: ${({ isChecked }) => (isChecked ? '32px' : '24px')};
    height: ${({ isChecked }) => (isChecked ? '32px' : '24px')};

    box-shadow: ${({ isChecked, theme }) =>
        isChecked ? 'none' : '0 0 0 4px rgba(157, 154, 172, 0.16)'};

    &:after {
        content: '';
        width: 32px;
        height: 32px;
        box-shadow: ${({ theme }) =>
            `0 0 0 2px ${
                theme.isLightTheme
                    ? theme.colors.ambience[6]
                    : theme.colors.ambience[0]
            }`};
        position: absolute;
        border-radius: 56%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: ${({ isChecked }) => (isChecked ? '1' : '0')};
        visibility: ${({ isChecked }) => (isChecked ? 'visible' : 'hidden')};
        background: ${({ background }) => background && background};
        transition: all 0.3s;
    }

    &:hover {
        box-shadow: none;
        &:after {
            opacity: 1;
            visibility: visible;
        }
    }
`;

const IconWrapper = styled(FlexDiv)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    justify-content: center;
`;

const StyledButton = styled(Button)`
    width: auto;
    height: unset;
    padding: 10px 51px;
`;

const ScrollView = styled(Box)`
    ${({ verticalWidth, theme }) => getCustomScroll({ theme, verticalWidth })};
    overflow: auto;
    max-height: calc(90vh - 162px);
    padding: 5px 24px 0;
    margin: 0 -24px;
`;
