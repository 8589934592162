import Box from 'atoms/Box';
import Btn from 'atoms/Button/btn';
import ScrollSection from 'atoms/ScrollSection/ScrollSection';
import LoaderV2 from 'components/general/LoaderV2';
import Portal from 'components/general/Portal';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { Text } from 'foundations/themeV2/text';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import keys from 'locale/keys';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { layout } from 'styled-system';
import Prompt from './Prompt';

const Header = ({ title, subtitle, handleCancel, handleClose }) => {
    return (
        <HeaderWrapper>
            <Box pt=''>
                <BackIcon
                    width={24}
                    height={24}
                    icon='arrow_left'
                    onClick={handleCancel}
                    fill='text.default.primary'
                />
            </Box>
            <Box p='0 10px' flex={1}>
                <Text variant='h4' color='text.default.primary'>
                    {title}
                </Text>
                {subtitle && (
                    <Text variant='caption' color='text.default.secondary'>
                        {subtitle}
                    </Text>
                )}
            </Box>
            <Box>
                <CloseSVGSprite
                    icon='dismiss'
                    width={24}
                    height={24}
                    onClick={handleClose}
                    fill='text.default.primary'
                />
            </Box>
        </HeaderWrapper>
    );
};

const Content = ({
    children,
    headerTitle = 'Customize event',
    headerSubtitle,
    handleSave,
    onCancel,
    onClose,
    promptData,
    setPromptData,
    isLoading,
    settings,
    onDiscardChanges,
    wrapperId = 'customize-event-panel',
    wrapperStyle = {},
    wrapperClassName = 'customize-event-panel',
    showPageOverlay = false,
    onPageOverlayClick = null,
    disableSave = false,
}) => {
    const { t } = useCustomTranslation();
    const [originalSettings, setOriginalSettings] = useState();
    const [hasSettingsChanged, setHasSettingsChanged] = useState(false);

    // Save original settings info on mount
    useEffect(() => {
        const oSettings = cloneDeep(settings);
        setOriginalSettings(oSettings);
    }, []);

    // Disable outside click close when settings are changed but not saved
    useEffect(() => {
        const changed = !isEqual(originalSettings, settings);
        setHasSettingsChanged(changed);
    }, [originalSettings, settings]);

    const handlePromptReject = () => {
        if (promptData?.onReject) {
            promptData.onReject();
        } else {
            setPromptData(null);
        }
    };

    const handlePromptResolve = () => {
        if (promptData?.onResolve) {
            promptData.onResolve(promptData);
        } else {
            setPromptData(null);
        }
    };

    const promptOnSettingsChange = (action = 'cancel') => {
        if (!isEqual(originalSettings, settings)) {
            setPromptData({
                title: t(keys.RHS_CUSTOMIZE_DISCARD_TITLE),
                description: t(keys.RHS_CUSTOMIZE_DISCARD_SUBTITLE),
                resolveText: t(keys.RHS_CUSTOMIZE_DISCARD_BTN),
                onResolve: () =>
                    onDiscardChanges &&
                    onDiscardChanges(originalSettings, action),
            });
            return false;
        }
        return true;
    };

    const handleCancel = () => {
        if (promptOnSettingsChange('cancel')) onCancel && onCancel(false);
    };

    const handleClose = () => {
        if (promptOnSettingsChange('close')) onClose && onClose();
    };

    const handlePageOverlayClick = () => {
        if (onPageOverlayClick) onPageOverlayClick();
        else handleClose();
    };

    return (
        <>
            <MainWrapper
                className={wrapperClassName}
                id={wrapperId}
                style={wrapperStyle}
            >
                <Header
                    title={headerTitle}
                    subtitle={headerSubtitle}
                    handleCancel={handleCancel}
                    handleClose={handleClose}
                />
                <ScrollSection>
                    <Wrapper className={'customize-event-scrollview'}>
                        {children}
                    </Wrapper>
                </ScrollSection>
                <BottomActionBar>
                    <Btn
                        variant={'primary'}
                        onClick={() =>
                            handleSave && handleSave(originalSettings)
                        }
                        disabled={
                            isLoading || !hasSettingsChanged || disableSave
                        }
                        mr='x4'
                    >
                        {t(keys.GENERAL_SAVE)}
                    </Btn>
                    <Btn
                        variant={'secondary'}
                        onClick={handleCancel}
                        disabled={isLoading}
                    >
                        {t(keys.GENERAL_CANCEL)}
                    </Btn>
                </BottomActionBar>
                {promptData && (
                    <Prompt
                        {...promptData}
                        onResolve={handlePromptResolve}
                        onReject={handlePromptReject}
                        portalContainer={wrapperId}
                        rejectText={t(keys.GENERAL_CANCEL)}
                    />
                )}
                {isLoading && (
                    <Overlay>
                        <Box height='50px' width='50px' margin='auto'>
                            <LoaderV2 />
                        </Box>
                    </Overlay>
                )}
            </MainWrapper>
            {hasSettingsChanged || showPageOverlay ? (
                <PageOverlay onClick={handlePageOverlayClick} />
            ) : null}
        </>
    );
};

function CustomizeTab({ children, portalContainerId, ...rest }) {
    return portalContainerId ? (
        <Portal container={portalContainerId}>
            <Content {...rest}>{children}</Content>
        </Portal>
    ) : (
        <Content {...rest}>{children}</Content>
    );
}

export default CustomizeTab;

const MainWrapper = styled.div`
    @keyframes slideInRight {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0%);
        }
    }

    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    width: 360px;
    z-index: 982;
    animation: slideInRight 0.4s ease;
    will-change: opacity, transform;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const BackIcon = styled(StyledSvgSprite)`
    ${layout}
    cursor: pointer;
`;

const CloseSVGSprite = styled(StyledSvgSprite)`
    cursor: pointer;
`;

const HeaderWrapper = styled.div`
    padding: 16px 0;
    display: flex;
    align-items: center;
    margin: 0 24px;
`;

const Wrapper = styled.div`
    padding: 0 24px;
    flex: 1;
`;

const BottomActionBar = styled.div`
    padding: 16px;
    display: flex;
    align-items: center;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 14;
    background-color: ${({ theme }) => theme.colors.overlay.default1};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 12px;
`;

const PageOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
`;
