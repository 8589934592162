import { useCustomTranslation } from 'hooks/useCustomTranslation';
import useTheme from 'hooks/useTheme';
import keys from 'locale/keys';
import React from 'react';
import styled from 'styled-components';
import { flexbox } from 'styled-system';
import { Typography } from '../../../foundations/typography';
import Loader from '../../general/Loader';
import SVGSprite from '../../general/SVGSprite';
import BacklessModal from './templates/BacklessModal';

const NetworkLostModal = () => {
    const { isLightTheme } = useTheme();

    // Removed notification sound on disconnect
    // useDisconnectedNotification({ isDisconnected: true });
    const { t } = useCustomTranslation();
    return (
        <ModalContainer contentClassName='networkLossModal'>
            <FittedLoader />
            <Box alignItems='center' justifyContent='center'>
                <SVGSprite
                    style={{
                        height: '30px',
                        width: '30px',
                        marginRight: '5px',
                    }}
                    icon='icon-warning-triangle-filled'
                />
                <Typography
                    variant='subtitle1'
                    color={isLightTheme ? 'ambience.24' : ''}
                >
                    {t(keys.TOASTS_DISCONNECTED)}
                </Typography>
            </Box>
            <Typography
                variant={'body2'}
                mt={10}
                className='dot-animation'
                color={isLightTheme ? 'ambience.24' : ''}
            >
                {t(keys.TOASTS_RECONNECT)}
            </Typography>
        </ModalContainer>
    );
};

const FittedLoader = styled(Loader)`
    height: 5rem;
`;

const ModalContainer = styled(BacklessModal)`
    z-index: 9999;
    .networkLossModal {
        width: 100vw;
    }
    .dot-animation {
        font-size: 12px;
        margin-top: 20px;
        &:after {
            content: '.';
            animation: dots 1s steps(5, end) infinite;
        }
    }
    @keyframes dots {
        0%,
        20% {
            color: rgba(0, 0, 0, 0);
            text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
            color: white;
            text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
            text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
            text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
        }
    }
`;

const Box = styled.div`
    ${flexbox}
    display: flex;
    margin-top: 24px;
`;

export default NetworkLostModal;
