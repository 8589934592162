import useQuery from 'hooks/useQuery';
import { useMemo } from 'react';
import { CENTER_CONTAINER } from 'utils/constants/containers/containerIds';

const DEEPLINK_QUERY_PARAM = 'dl';
const ALLOWED_LINKS_CONTAINER = {
    arena: {
        containerID: CENTER_CONTAINER,
        preEventAllowed: true,
    },
};
function useDeepLink() {
    const query = useQuery();
    const linkStr = query.get(DEEPLINK_QUERY_PARAM) || '';

    const deepLinkPresent = linkStr ? true : false;

    const { root, path } = useMemo(() => {
        let params = [];
        if (linkStr) {
            try {
                params = atob(linkStr).split('.');
            } catch {}
        }
        return {
            root: params[0],
            path: params[1],
        };
    }, [linkStr]);

    const isValidDeepLink = (params) => {
        switch (root) {
            case 'arena':
                return validateArenaLink(params);
            default:
                return false;
        }
    };

    const allowedPreEventLinkAccess = () => {
        switch (root) {
            case 'arena':
                return ALLOWED_LINKS_CONTAINER[root].preEventAllowed;
            default:
                return false;
        }
    };

    const validateArenaLink = ({ boothId: exhibitorBoothId }) => {
        const [route, boothId] = path.split('/');
        if (
            !route ||
            !boothId ||
            route !== 'booth' ||
            boothId !== exhibitorBoothId
        ) {
            return false;
        }
        return true;
    };

    return {
        root,
        path,
        isValidDeepLink,
        deepLinkParamPresent: deepLinkPresent,
        containerID:
            ALLOWED_LINKS_CONTAINER[root] &&
            ALLOWED_LINKS_CONTAINER[root].containerID,
        allowedPreEventLinkAccess,
        deepLinkStr: linkStr,
    };
}

export default useDeepLink;
