/**************************************************************
 * @fileOverview Metered Pricing Actions (WIP)
 **************************************************************/

import { CALL_API } from 'store/api';
import { API_SERVICE } from 'utils/apiService';

export const CANCEL_ALERT = 'CANCEL_ALERT';
export const FETCH_REGISTRANT_STRIP_INFO_REQUEST =
    'FETCH_REGISTRANT_STRIP_INFO_REQUEST';
export const FETCH_REGISTRANT_STRIP_INFO_RESPONSE =
    'FETCH_REGISTRANT_STRIP_INFO_RESPONSE';
export const FETCH_REGISTRANT_ALERT_REQUEST = 'FETCH_REGISTRANT_ALERT_REQUEST';
export const FETCH_REGISTRANT_ALERT_RESPONSE =
    'FETCH_REGISTRANT_ALERT_RESPONSE';
export const RESET_METER_DATA = 'RESET_METER_DATA';
export const RESET_METER_SWITCH = 'RESET_METER_SWITCH';

export function getRegistrantStripInfo(communityId, airmeetId) {
    return {
        [CALL_API]: {
            types: [
                FETCH_REGISTRANT_STRIP_INFO_REQUEST,
                FETCH_REGISTRANT_STRIP_INFO_RESPONSE,
            ],
            endpoint: `/airmeet/registration/stats?communityId=${communityId}&airmeetId=${airmeetId}`,
            method: 'GET',
            service: API_SERVICE.ZEUS,
            type: 'json',
        },
    };
}

export function getRegistrantAlert(communityId) {
    return {
        [CALL_API]: {
            types: [
                FETCH_REGISTRANT_ALERT_REQUEST,
                FETCH_REGISTRANT_ALERT_RESPONSE,
            ],
            endpoint: `/community/registration/alerts?communityId=${communityId}`,
            method: 'GET',
            service: API_SERVICE.ZEUS,
            type: 'json',
        },
    };
}

export function cancelMeterAlert(type) {
    return {
        type: CANCEL_ALERT,
        payload: {
            alertType: type,
        },
    };
}

export function resetMeterData() {
    return {
        type: RESET_METER_DATA,
    };
}

export function resetMeterSwitch(meterSwitchData) {
    return {
        type: RESET_METER_SWITCH,
        payload: {
            meterStatus: meterSwitchData?.meterStatus,
            meterEnabled: meterSwitchData?.meterEnabled,
        },
    };
}
