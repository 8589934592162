import { useProfiling } from 'context/Profiling';
import ReportingContext from 'context/ReportingContext';
import useDataWriter from 'hooks/useDataWriter';
import { ALL_FEATURES_CONFIG } from 'hooks/useFeatureControl';
import UserDataFactory from 'hooks/users/factory/UserDataFactory';
import debounce from 'lodash/debounce';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useStore } from 'react-redux';
import { setCloudUsersCount, setOnlineUsers } from 'store/actions/onlineUsers';
import AuthService from 'utils/authService';
import { FEATURE_NAMES } from 'utils/constants/featureNames';
import { getUserInfo } from 'utils/constants/users';
import { logger } from 'utils/logger';
import { HeartBeatObserver } from 'utils/loggers/HeartbeatObserver';
import { v4 as uuidv4 } from 'uuid';
import { setUsersInfo } from '../../store/actions/Lounge';
import { LIVE_CONNECTION_STATE } from '../../utils/constants/live-airmeet';
import PermissionUtils from '../../utils/permission-utils';

const PERF_TRACES = {
    LOAD_USERS_FB: 'loadUsersFB',
};
const ACTIONS = {
    ADDED: 1,
    CHANGE: 2,
    REMOVE: 3,
};
const USER_ONLINE_UUID = 'USER_ONLINE_UUID';
const getUUID = () => {
    let ownUUID = sessionStorage
        ? sessionStorage.getItem(USER_ONLINE_UUID)
        : null;
    if (!ownUUID) {
        ownUUID = uuidv4();
        sessionStorage && sessionStorage.setItem(USER_ONLINE_UUID, ownUUID);
    }
    return ownUUID;
};

const EMPTY_OBJECT = {};

function useMemberOnlineState({
    airmeetId,
    isReadyForOnline,
    firebaseClient,
    user,
    isUsingOnlineUserNode,
    liveContext,
}) {
    const { perf } = useProfiling();
    const reportingContext = useContext(ReportingContext);

    const {
        featureDataClients: {
            [FEATURE_NAMES.USER_PROFILE]: userProfileClient,
            [FEATURE_NAMES.USER_PRESENCE]: userPresenceClient,
        },
        airmeet: { data },
    } = liveContext;

    const currentAirmeet = data?.currentAirmeet || EMPTY_OBJECT;

    const dispatch = useDispatch();

    const onlineMembers = useRef({
        users: [],
        ids: new Map(),
    });
    const [applyObserver, setApplyObserver] = useState(false);
    const [connectionState, setConnectionState] = useState(
        LIVE_CONNECTION_STATE.NOT_CONNECTED
    );
    const [foundNewerLogin, setFoundNewerLogin] = useState(false);
    const cloudUsers = useRef(new Map());

    const connectRetry = useRef(0);

    const connectedStats = useMemo(
        () => ({
            uuid: getUUID(),
        }),
        []
    );
    const userId = user?.id;
    useEffect(() => {
        if (!userId) {
            return;
        }
        logger.info(
            `useMemberOnlineState user connectedStats data: ${userId}`,
            connectedStats
        );
    }, [connectedStats, userId]);

    const store = useStore();

    let dataWriterConfig =
        liveContext?.platformConfig &&
        liveContext.platformConfig[ALL_FEATURES_CONFIG.DATA_WRITER_CONFIG];

    if (
        liveContext?.featuresConfig &&
        liveContext.featuresConfig[ALL_FEATURES_CONFIG.DATA_WRITER_CONFIG]
    ) {
        dataWriterConfig =
            liveContext?.featuresConfig[ALL_FEATURES_CONFIG.DATA_WRITER_CONFIG];
    }

    const isPresenceWriterEnabled =
        dataWriterConfig?.applyToAll ||
        Object.keys(dataWriterConfig?.features || {}).includes(
            FEATURE_NAMES.USER_PRESENCE
        );

    let factory = UserDataFactory.getInstance();
    if (!factory) {
        UserDataFactory.initFactory({ airmeetId, token: AuthService.token });
        factory = UserDataFactory.getInstance();
    }

    const connectionStatePath = '.info/connected';
    const onlineUsersPath = airmeetId ? `${airmeetId}/onlineUsers` : null;
    const userLoginPath =
        user && user.id ? `${onlineUsersPath}/${user.id}` : null;

    const isConnectedRef = useRef(false);

    const getAirmeetUserInfo = () => {
        const { is_custom_registration_enabled } =
            store.getState()['lounge']?.['airmeet'] || {};
        return getUserInfo(
            user,
            {
                eventEnterTime: firebaseClient.getServerTimestampRef(),
            },
            is_custom_registration_enabled
        );
    };

    const shouldUpdate = (updatedUser) => {
        if (!updatedUser.id) {
            return false;
        }

        const state = store.getState();

        let assocUserValue = state.lounge.airmeet.assocUsers[updatedUser.id];
        if (!assocUserValue) {
            return true;
        }
        if (updatedUser.hash && updatedUser.hash !== assocUserValue.hash) {
            return true;
        }
        if (updatedUser.guest && updatedUser.guest !== assocUserValue.guest) {
            return true;
        }

        return false;
    };

    const { write } = useDataWriter(FEATURE_NAMES.USER_PROFILE, {
        liveContextOverride: liveContext,
    });

    const setSelfOnlineRef = useRef();
    const setSelfOnline = (callback) => {
        if (foundNewerLogin) {
            logger.info(
                `useMemberOnlineState - Found new user login state, So skip for this uuid: ${connectedStats?.uuid}`
            );
            return;
        }

        // add disconnectTime for handling disconnection from cloud function - onlineUserDisconnect
        if (
            currentAirmeet?.firebaseReplicas?.[FEATURE_NAMES.USER_PROFILE]
                ?.length > 0 ||
            currentAirmeet?.firebaseReplicas?.[FEATURE_NAMES.DEFAULT]?.length >
                0
        ) {
            userProfileClient.replaceOnDisconnect(
                `${userLoginPath}/disconnectTime`,
                firebaseClient.getServerTimestampRef()
            );
        } else {
            logger.info('Setting on disconnect on onlineUsers', userId);
            userProfileClient.replaceOnDisconnect(userLoginPath, null);
        }

        // 1. Set ourself into the online user list
        write({
            ...connectedStats,
            info: getAirmeetUserInfo(),
            timestamp: firebaseClient.getServerTimestampRef(),
        })
            .then((response) => {
                setApplyObserver(true);
                logger.info('Added to attendee list');
                callback && callback(null, response);
            })
            .catch((error) => {
                logger.error('Failed to add to attendee list', error, {
                    message: error.message,
                    ...connectedStats,
                });

                if (reportingContext && error) {
                    reportingContext.reportIncident({
                        tag: 'Firebase',
                        message: 'Attendee failed to set value',
                        meta: {
                            message: error.message,
                        },
                        config: {
                            fatalThreshold: 0,
                        },
                    });
                }
                callback && callback(error);
            });
    };
    setSelfOnlineRef.current = setSelfOnline;

    const initialLoadCompletedRef = useRef(false);
    const buffer = useRef([]);

    const onOnlineUsersChanged = () => {
        if (false === initialLoadCompletedRef.current) {
            logger.debug(
                'OnlineUsersChanged waiting for initial load to finish'
            );
            return false;
        }

        const bufferedUpdates = [...buffer.current];
        buffer.current.length = 0;

        logger.debug('Running online user changed', bufferedUpdates.length);

        if (!bufferedUpdates?.length) {
            return;
        }

        let previousIds = new Map(onlineMembers.current.ids);

        let initialValues = {
            ids: previousIds,
            newUsers: [],
        };

        const results = bufferedUpdates.reduce((acc, update) => {
            // snapshot updates do not have incremental key as we do not iterate over it to save CPU cycles
            const it = update.value;
            const action = update.action;

            if (!it || !it.info) {
                return acc;
            }

            if (action === ACTIONS.REMOVE) {
                acc.ids.delete(it.info.id);
                cloudUsers.current.delete(it.info.id);
                return acc;
            }

            // TODO: timestamp field to be removed
            if (it.info) {
                it.info.timestamp = it.info.eventEnterTime;
            }
            acc.ids.set(it.info.id, it.info);

            const changedUser = it.info;
            const uid = changedUser.id;

            if (PermissionUtils.isUserEventCloudHost(it.info)) {
                cloudUsers.current.set(it.info.id, true);
            }

            if (false === shouldUpdate(changedUser)) {
                return acc;
            }

            // add the other info with user data
            let userUpdate = changedUser;
            const assocUsers = store.getState().lounge.airmeet.assocUsers;
            if (assocUsers[uid]) {
                userUpdate = {
                    ...assocUsers[uid],
                    ...changedUser,
                };
            }

            acc.newUsers.push(userUpdate);
            return acc;
        }, initialValues);

        let users = Array.from(results.ids.values());

        logger.debug(
            'Running online user changed finished, total users',
            users.length
        );

        onlineMembers.current = { users, ids: results.ids };

        dispatch(setCloudUsersCount(cloudUsers.current.size));

        ReactDOM.unstable_batchedUpdates(() => {
            dispatch(setOnlineUsers(onlineMembers.current));
            if (results.newUsers.length > 0) {
                dispatch(setUsersInfo(results.newUsers));
            }
        });
    };

    const onOnlineUsersChangedRef = useRef();
    onOnlineUsersChangedRef.current = onOnlineUsersChanged;

    // Handle user changes on firebase
    useEffect(() => {
        if (!firebaseClient || !isReadyForOnline) {
            return;
        }

        const onConnectionStateChanged = ({ value }) => {
            isConnectedRef.current = value;
            if (value === true) {
                // We're online now
                // (could be a fresh connect, or a reconnect)
                logger.info('Connected to database');
                if (connectRetry.current > 0) {
                    if (isPresenceWriterEnabled) {
                        userPresenceClient.setDataAsync(
                            `${airmeetId}/userPresence/${user.id}/disconnectTime`,
                            null
                        );
                        userPresenceClient.replaceOnDisconnect(
                            `${airmeetId}/userPresence/${user.id}/disconnectTime`,
                            firebaseClient.getServerTimestampRef()
                        );
                    }

                    // cleanup disconnectTime when user is online
                    userProfileClient.setDataAsync(
                        `${airmeetId}/onlineUsers/${user.id}/disconnectTime`,
                        null
                    );
                }

                setSelfOnlineRef.current(() => {
                    // TODO: Why are we setting only when not set online?
                    setConnectionState(LIVE_CONNECTION_STATE.CONNECTED);
                    connectRetry.current += 1;
                    logger.info('Connection Retry Count', {
                        userId: user?.id,
                        retryCount: connectRetry.current,
                    });
                });
            } else {
                // Offline now
                if (window.navigator.onLine) {
                    logger.warn(
                        `Disconnected from database but connected to internet ${
                            PermissionUtils.isSessionScreenRecordingUser()
                                ? '- Screen Recorder'
                                : PermissionUtils.isEventCloudHost()
                                ? '- Cloud Host'
                                : ''
                        }`
                    );
                } else {
                    logger.info('Disconnected from database');
                }
                setConnectionState(LIVE_CONNECTION_STATE.NOT_CONNECTED);
                // TODO: Should we also reset the online users here?
            }
        };

        firebaseClient.getDataSync(
            connectionStatePath,
            onConnectionStateChanged
        );

        const debouncedChange = debounce(() => {
            onOnlineUsersChangedRef.current();
        }, 300);

        const onBufferChange = (applyNow = false) => {
            if (buffer.current.length > 1000 || applyNow) {
                onOnlineUsersChangedRef.current();
            } else {
                debouncedChange();
            }
        };

        const onAction = (value, action) => {
            buffer.current.push({
                value,
                action,
            });
            onBufferChange();
        };

        const onOnlineUserAdded = (...args) => {
            onAction(args[0].val(), ACTIONS.ADDED);
        };

        const onOnlineUserChanged = (...args) => {
            onAction(args[0].val(), ACTIONS.CHANGE);
        };

        const onOnlineUsersRemoved = (...args) => {
            onAction(args[0].val(), ACTIONS.REMOVE);
        };

        let ref = userProfileClient
            .ref(onlineUsersPath)
            .orderByChild('timestamp');
        let newUsersRef;
        const startListeners = async () => {
            // use firebase timestamp
            let serverTime = await firebaseClient.getCurrentTimeStamp();
            logger.debug('Attaching online listener since', {
                serverTime,
                now: Date.now(),
            });

            /* setup incremental listener on early timestamp so that we don't miss people who join after API data has been loaded in b/w
        There will be no duplicates as we use a map and not array
        */
            newUsersRef = ref.startAt(serverTime - 1000);
            newUsersRef.on('child_added', onOnlineUserAdded);

            // changed and removed we want to listen for all users, not just newly added, updates are buffered until the first full snapshot arrives

            ref.on('child_changed', onOnlineUserChanged);
            ref.on('child_removed', onOnlineUsersRemoved);

            const trace = perf.trace(PERF_TRACES.LOAD_USERS_FB);
            ref.once('value', (snapshot) => {
                const values = [];
                // We need to add child one by one for save in same order in which data received
                snapshot.forEach((child) => {
                    values.push({
                        value: {
                            ...child.val(),
                        },
                        action: ACTIONS.ADDED,
                    });
                });

                trace.incrementMetric('count', values.length);
                perf.traceStop(PERF_TRACES.LOAD_USERS_FB);
                //apply buffered changes on top of snapshot
                buffer.current = values.concat(buffer.current);
                // Apply any pending buffered items
                initialLoadCompletedRef.current = true;
                onBufferChange(true);
            });
        };

        if (isUsingOnlineUserNode) {
            startListeners();
        }

        return () => {
            if (!firebaseClient) {
                return;
            }

            firebaseClient.clearDataSync(
                connectionStatePath,
                onConnectionStateChanged
            );
            newUsersRef && newUsersRef.off('child_added', onOnlineUserAdded);
            ref.off('child_changed', onOnlineUserChanged);
            ref.off('child_removed', onOnlineUsersRemoved);
        };
    }, [firebaseClient, isReadyForOnline, isUsingOnlineUserNode]);

    useEffect(() => {
        if (!applyObserver) {
            return;
        }
        if (connectionState !== LIVE_CONNECTION_STATE.CONNECTED) {
            return;
        }
        if (foundNewerLogin) {
            if (HeartBeatObserver.instance)
                HeartBeatObserver.instance.removeAllListeners();
            logger.info(
                `useMemberOnlineState - Found new user login state, So skip the observer for check self user logged in two place of uuid: ${connectedStats?.uuid}`
            );
            return;
        }
        const checkSelfValue = ({ key, value }) => {
            // Check if our own user ID has been set again
            if (
                key === userId &&
                value &&
                value.uuid &&
                value.uuid !== connectedStats.uuid
            ) {
                logger.info(
                    `useMemberOnlineState Own UUID has been updated for user(${key}):`,
                    {
                        updatedValue: value?.uuid,
                        connectedStatsUuid: connectedStats?.uuid,
                    }
                );
                userProfileClient.cancelOnDisconnect(userLoginPath);
                setFoundNewerLogin(true);
                return;
            }

            if (!value && isConnectedRef.current) {
                logger.error(`Value removed from attendee list for ${userId}`);
                setSelfOnlineRef.current();
            }
        };

        const onlineUserReadDelay = 2000;
        logger.info(
            'useMemberOnlineState adding the observer for check self user logged in two place.'
        );
        const timeout = setTimeout(() => {
            // Load refs
            userProfileClient.getDataSync(
                `${onlineUsersPath}/${userId}`,
                checkSelfValue
            );
        }, onlineUserReadDelay);

        return () => {
            userProfileClient.clearDataSync(
                `${onlineUsersPath}/${userId}`,
                checkSelfValue
            );
            clearTimeout(timeout);
        };
    }, [
        foundNewerLogin,
        userProfileClient,
        connectedStats,
        onlineUsersPath,
        userId,
        userLoginPath,
        connectionState,
        applyObserver,
    ]);

    useEffect(() => {
        if (foundNewerLogin && userProfileClient) {
            userProfileClient.cleanup();
        }
    }, [foundNewerLogin, userProfileClient]);

    useEffect(() => {
        if (
            userLoginPath &&
            connectionState === LIVE_CONNECTION_STATE.CONNECTED
        ) {
            logger.debug('User updated Hash', user?.hash);
            write({
                ...connectedStats,
                info: getAirmeetUserInfo(),
                timestamp: firebaseClient.getServerTimestampRef(),
            });
        }
    }, [user?.hash]);

    return {
        isConnected: connectionState === LIVE_CONNECTION_STATE.CONNECTED,
        connectionState,
        foundNewerLogin,
    };
}

export default useMemberOnlineState;
