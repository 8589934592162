import { CALL_API } from '../../api';

export const CREATE_SPEAKER_REQUEST = 'CM_DASHBOARD/CREATE_SPEAKER_REQUEST';
export const CREATE_SPEAKER_RESPONSE = 'CM_DASHBOARD/CREATE_SPEAKER_RESPONSE';
export const UPDATE_SPEAKER_REQUEST = 'CM_DASHBOARD/UPDATE_SPEAKER_REQUEST';
export const UPDATE_SPEAKER_RESPONSE = 'CM_DASHBOARD/UPDATE_SPEAKER_RESPONSE';
export const DELETE_SPEAKER_REQUEST = 'CM_DASHBOARD/DELETE_SPEAKER_REQUEST';
export const DELETE_SPEAKER_RESPONSE = 'CM_DASHBOARD/DELETE_SPEAKER_RESPONSE';
export const SEND_SPEAKER_INVITE_REQUEST =
    'CM_DASHBOARD/SEND_SPEAKER_INVITE_REQUEST';
export const SEND_SPEAKER_INVITE_RESPONSE =
    'CM_DASHBOARD/SEND_SPEAKER_INVITE_RESPONSE';
export const SPEAKER_INCOMPLETE_STATUS = 'SPEAKER_INCOMPLETE_STATUS';
export const UPDATE_SPEAKER_ORDER_REQUEST = 'UPDATE_SPEAKER_ORDER_REQUEST';
export const UPDATE_SPEAKER_ORDER_RESPONSE = 'UPDATE_SPEAKER_ORDER_RESPONSE';
export const UPDATE_SPEAKER_ORDER_FOR_SESSION_REQUEST =
    'UPDATE_SPEAKER_ORDER_FOR_SESSION_REQUEST';
export const UPDATE_SPEAKER_ORDER_FOR_SESSION_RESPONSE =
    'UPDATE_SPEAKER_ORDER_FOR_SESSION_RESPONSE';

export function createAirmeetSpeaker({ airmeetId, speaker }) {
    return {
        [CALL_API]: {
            types: [CREATE_SPEAKER_REQUEST, CREATE_SPEAKER_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/speaker`,
            body: speaker,
            type: 'json',
            method: 'POST',
        },
    };
}

export function deleteAirmeetSpeaker({ airmeetId, speakerId, isInitiated }) {
    return {
        [CALL_API]: {
            types: [DELETE_SPEAKER_REQUEST, DELETE_SPEAKER_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/speaker/${speakerId}${
                isInitiated ? `?initiated=true` : ''
            }`,
            type: 'json',
            method: 'DELETE',
        },
    };
}

export function updateAirmeetSpeaker({ airmeetId, speakerId, speaker }) {
    return {
        [CALL_API]: {
            types: [UPDATE_SPEAKER_REQUEST, UPDATE_SPEAKER_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/speaker/${speakerId}`,
            body: speaker,
            type: 'json',
            method: 'PUT',
        },
    };
}

export function updateSpeakersIncompleteStatus(isIncomplete) {
    return {
        type: SPEAKER_INCOMPLETE_STATUS,
        payload: isIncomplete,
    };
}

export function sendSpeakerInvite({ airmeetId, body }) {
    return {
        [CALL_API]: {
            types: [SEND_SPEAKER_INVITE_REQUEST, SEND_SPEAKER_INVITE_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/speaker-invitation-bulk`,
            type: 'json',
            method: 'POST',
            body,
        },
    };
}

export function updateSpeakerOrder(airmeetId, payload) {
    return {
        [CALL_API]: {
            types: [
                UPDATE_SPEAKER_ORDER_REQUEST,
                UPDATE_SPEAKER_ORDER_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/speaker/order`,
            body: payload,
            method: 'PUT',
            type: 'json',
        },
    };
}

export function updateSpeakerOrderForSession(airmeetId, sessionId, payload) {
    return {
        [CALL_API]: {
            types: [
                UPDATE_SPEAKER_ORDER_FOR_SESSION_REQUEST,
                UPDATE_SPEAKER_ORDER_FOR_SESSION_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/speaker/order`,
            body: payload,
            method: 'PUT',
            type: 'json',
        },
    };
}
