import firebase from 'firebase/app';
import 'firebase/performance';
import Performance from 'models/performance';
import React, {
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'store/selectors/users';
import { FIREBASE_APP_CONFIG } from 'utils/constants/firebase';
import { logger } from 'utils/logger';
import { ProfilingContext, ProfilingOptions } from '../hoc/withProfiling/types';

const Profiling = React.createContext<ProfilingContext>(null);

export function ProfilingProvider({
    children,
    options,
}: {
    children?: ReactNode;
    options?: ProfilingOptions;
}) {
    const [activeAll] = useState<boolean>(() => {
        let profilingAudience = process.env.REACT_APP_PROFILING_AUDIENCE;
        let percentage = Number(profilingAudience);
        if (false === isNaN(percentage)) {
            return Math.random() < percentage;
        }
        return false;
    });

    let isTracingEnabled = activeAll;
    let tracingAudience = process.env.REACT_APP_TRACING_AUDIENCE;
    let percentage = Number(tracingAudience);
    if (false === isNaN(percentage)) {
        isTracingEnabled = Math.random() < percentage;
    }

    const [active, setActive] = useState<Set<string>>(new Set<string>());
    const [opts, setOpts] = useState<ProfilingOptions>(options || {});

    const activateProfilingOn = useCallback(
        (id: string) => {
            setActive((prev) => {
                prev.add(id);
                return new Set(prev);
            });
        },
        [setActive]
    );

    const deactivateProfilingOn = useCallback(
        (id: string) => {
            setActive((prev) => {
                if (prev.has(id)) {
                    prev.delete(id);
                    return new Set(prev);
                }

                return prev;
            });
        },
        [setActive]
    );

    const setProfilingOption = useCallback(
        (key: string, value: any) => {
            setOpts((prev) => ({
                ...prev,
                [key]: value,
            }));
        },
        [setOpts]
    );

    useEffect(() => {
        Object.entries({
            pa: activateProfilingOn,
            pd: deactivateProfilingOn,
            ps: setProfilingOption,
        }).forEach(([key, f]) => {
            window[key] = f;
        });
    }, [activateProfilingOn, deactivateProfilingOn, setProfilingOption]);

    useEffect(() => {
        logger.info(
            `Profiling active on ${activeAll ? 'all' : active.size} components${
                activeAll ? '' : `: ${Array.from(active).join(', ')}`
            }`
        );

        logger.info('Tracing active status', isTracingEnabled);
    }, [active, activeAll, isTracingEnabled]);

    let perf = useMemo(() => {
        let firebasePerf;

        if (isTracingEnabled || activeAll) {
            // Firebase profiling only works when using default firebase project
            const defaultApp = firebase.apps.find((app) => {
                return app.name === '[DEFAULT]';
            });
            if (defaultApp) {
                logger.debug('Using existing default firebase app');
            } else {
                firebase.initializeApp(FIREBASE_APP_CONFIG);
            }
            firebasePerf = firebase.performance();
        }

        if (activeAll) {
            return new Performance(firebasePerf, activeAll);
        } else {
            // Performance wrapper handles the null case by providing empty methods for calls
            return new Performance(null, false);
        }
    }, [activeAll, isTracingEnabled]);

    const userInfo = useSelector(selectAuthUser);

    useEffect(() => {
        if (userInfo) {
            perf.addAttribute('userId', userInfo.id || 'guest');
        }
    }, [userInfo, perf]);

    return (
        <Profiling.Provider
            value={{
                opts,
                allowed: true,
                active,
                activeAll,
                perf,
                activateProfilingOn,
                deactivateProfilingOn,
                setProfilingOption,
            }}
        >
            {children}
        </Profiling.Provider>
    );
}

export function useProfiling() {
    let context = useContext(Profiling);
    if (!context) {
        throw new Error(
            'useProfiling can only be called from children of ProfilingProvider'
        );
    }
    return context;
}

export default Profiling;
