export const SET_ACTIVE_LOUNGE = 'SET_ACTIVE_LOUNGE';
export const SET_ACTIVE_BOOTH_SPACE = 'SET_ACTIVE_BOOTH_SPACE';
export const SET_ACTIVE_RESOURCES = 'SET_ACTIVE_RESOURCES';

export function setActiveLounge(lounge) {
    return {
        type: SET_ACTIVE_LOUNGE,
        payload: {
            activeLounge: lounge,
        },
    };
}
export function setActiveBoothSpace(boothSpace) {
    return {
        type: SET_ACTIVE_BOOTH_SPACE,
        payload: {
            activeBoothSpace: boothSpace,
        },
    };
}

export function setActiveResources(resources) {
    return {
        type: SET_ACTIVE_RESOURCES,
        payload: {
            activeResources: resources,
        },
    };
}
