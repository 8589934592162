import FormField from 'components/community/common/FormField';
import React from 'react';
import { CHAR_LIMIT_MAP } from '../../CreateAirmeetV2/validations';

export const Organiser = ({
    formData,
    errors,
    newOnBoardingFlow = false,
    pageMode = false,
    onOrgNameFocus = () => {},
    label,
    hideSubLabel = false,
    subLabel,
    ...rest
}) => (
    <FormField
        id='organizer'
        name='eventOrganiserName'
        label='Organized by*'
        placeholder='Organized by'
        filledValue={formData.eventOrganiserName}
        isError={errors.eventOrganiserName}
        errorMsg={errors.eventOrganiserName}
        maxLength={CHAR_LIMIT_MAP['eventOrganiserName'].max}
        subLabel={hideSubLabel ? '' : pageMode ? '' : subLabel}
        newOnBoardingFlow={newOnBoardingFlow}
        inputValue={pageMode ? formData?.eventOrganiserName : null}
        value={formData?.eventOrganiserName}
        onFocus={onOrgNameFocus}
        isThemeV2={true}
        {...rest}
    />
);
