export const BUY_MORE_SEATS_CARD = 'BUY_MORE_SEATS_CARD';
export const REDUCE_ADDON_SEATS_CARD = 'REDUCE_ADDON_SEATS_CARD';
export const REDUCE_SEAT_LIMIT_STEP_1 = 'STEP_1';
export const REDUCE_SEAT_LIMIT_STEP_2 = 'STEP_2';
export const MANAGE_SEATS_PAGE = 'manage-seats';
export const CONTINUE_CTA_CLICKED = 'continue';
export const NO_CTA_CLICKED = 'no-cta-clicked';
export const CANCEL_CTA_CLICKED = 'cancel';

export const staticTextConfig = {
    [BUY_MORE_SEATS_CARD]: {
        headerText: 'Buy more seats',
        secondaryAdditionalText: `Do you want to add more team members for an upcoming event or you just want to increase your plan's seat limit?`,
    },
    [REDUCE_ADDON_SEATS_CARD]: {
        headerText: 'Reduce add-on seats',
        primaryTextList: [
            'You can only remove additional seats that were purchased online.',
            'You can remove the additional seats by reducing the seat counts.',
            'You will need to revoke your additional team members access before proceeding further to reduce the count.',
            'You will be charged for the remaining additional seats as per your current bill cycle.',
        ],
    },
};
