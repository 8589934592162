import SVGIcon from 'foundations/icon';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import React from 'react';
import { EmptyContent, EmptyWrapper } from '../styledComponents';

const commonProps = { fill: 'ambience.8', height: '24', width: '24' };

const EmptyCardBanner = ({
    style,
    justifyContent = 'flex-end',
    alignItems = 'flex-end',
    title = 'Still being generated. Check back later.',
    borderColor = '#f1a50d',
    showIcons = false,
    emptyContentStyle = {},
}) => {
    const { theme } = useTheme();
    const defaultContentStyle = {
        padding: '16px',
        marginTop: '0',
        ...(emptyContentStyle || {}),
    };
    return (
        <EmptyWrapper
            style={{
                justifyContent,
                alignItems,
                inset: '60px 40px 50px 24px',
                backgroundColor: theme?.isLightTheme
                    ? 'rgba(255, 255, 255, 0.4)'
                    : 'rgba(14, 13, 17, 0.4)',
                zIndex: 5,
                ...style,
            }}
        >
            <EmptyContent style={defaultContentStyle} borderColor={borderColor}>
                {showIcons && (
                    <>
                        <SVGIcon icon='data_scatter' {...commonProps} />
                        <SVGIcon icon='data_pie' {...commonProps} />
                        <SVGIcon icon='data_histogram' {...commonProps} />
                        <SVGIcon icon='data_line' {...commonProps} />
                    </>
                )}
                <Typography variant='body2' color='ambience.0'>
                    {title}
                </Typography>
            </EmptyContent>
        </EmptyWrapper>
    );
};

export default EmptyCardBanner;
