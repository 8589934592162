import Drawer from 'atoms/Drawer/drawer';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { AnimatePresence } from 'framer-motion';
import React, { useMemo } from 'react';
import SessionStreamEmpty from '../Sessions/SessionStreamEmpty';
import StreamKeyHeader from './StreamKeyHeader';
import StreamKeySection from './StreamKeySection';

export default function StreamKeysDrawer({
    visible,
    onClose,
    keyDetails,
    airmeetFormat,
    isThemeV2 = false,
}) {
    const streamKeys = useMemo(
        () => [
            ...(keyDetails?.airmeet_level_key?.stream_key
                ? [keyDetails?.airmeet_level_key]
                : []),
            ...(keyDetails?.session_level_keys || []),
            ...keyDetails?.track_level_keys,
        ],
        [keyDetails]
    );
    return (
        <AnimatePresence>
            {visible && (
                <Drawer
                    wrapperClosable={true}
                    showClose={true}
                    onClose={onClose}
                    title={
                        <StreamKeyHeader
                            showGenerateNewKey={false}
                            airmeetFormat={airmeetFormat}
                            isThemeV2={isThemeV2}
                        />
                    }
                >
                    <FlexDiv
                        flexDirection='column'
                        alignItems='stretch'
                        width='100%'
                        pt={3}
                    >
                        {streamKeys?.length ? (
                            streamKeys.map((item) => (
                                <StreamKeySection
                                    entityName={
                                        item?.session_name || item?.track_name
                                    }
                                    key={
                                        item?.session_id ||
                                        item?.track_id ||
                                        item?.stream_key
                                    }
                                    streamKey={item?.stream_key}
                                    streamUrl={item?.stream_url}
                                    airmeetFormat={airmeetFormat}
                                    isThemeV2={isThemeV2}
                                />
                            ))
                        ) : (
                            <SessionStreamEmpty />
                        )}
                    </FlexDiv>
                </Drawer>
            )}
        </AnimatePresence>
    );
}
