import { getStageFirebaseClient } from 'context/LiveAirmeet';
import { useEffect, useRef, useState } from 'react';
import { noop } from 'utils/constants/common';
import { logger } from 'utils/logger';
import useLiveAirmeetContext from './useLiveAirmeetContext';
const getStartTime = (value) => value;
function useLocalSessionTimer({
    key,
    onStart = noop,
    onCompleted = noop,
    onUpdate = noop,
    duration = 15,
    getStartTimeCallback = getStartTime,
}) {
    const firebaseClient = useLiveAirmeetContext(getStageFirebaseClient);
    const prevTimer = useRef(0);
    const callbackMethods = useRef({});
    const [timer, setTimerState] = useState(0);
    const [timerEndValue, setTimerEndValue] = useState(0);

    callbackMethods.current.onStart = onStart;
    callbackMethods.current.onCompleted = onCompleted;
    callbackMethods.current.onUpdate = onUpdate;

    useEffect(() => {
        let interval;
        let counter = 0;
        if (timerEndValue) {
            const updateTimerValue = async () => {
                const localCounter = counter;
                const timerDiff = await firebaseClient.getRemainingTimeInSec(
                    timerEndValue
                );
                if (timerDiff >= 0 && localCounter === counter) {
                    setTimerState(timerDiff);
                    counter = timerDiff;
                }
                // reset timer
                if (timerDiff < 0 && localCounter === counter) {
                    logger.info('Reset the local timer value', {
                        timerDiff,
                    });
                    setTimerState((prevTimer) =>
                        prevTimer > 0 ? 0 : timerDiff
                    );
                    counter = timerDiff;
                }
                if (timerDiff <= 0 && interval) {
                    clearInterval(interval);
                }
                return timerDiff;
            };
            updateTimerValue().then((shouldStart) => {
                if (shouldStart > 0) {
                    interval = setInterval(updateTimerValue, 100);
                }
            });
            return () => {
                interval && clearInterval(interval);
            };
        }
    }, [timerEndValue, firebaseClient]);

    useEffect(() => {
        if (prevTimer.current !== timer) {
            if (timer === duration) {
                callbackMethods.current.onStart();
            } else if (timer === 0) {
                callbackMethods.current.onCompleted();
            }
            callbackMethods.current.onUpdate(timer);
            prevTimer.current = timer;
        }
    }, [timer, duration]);

    useEffect(() => {
        const valueUpdated = ({ key, value }) => {
            value = getStartTimeCallback(value);
            logger.debug('Local:Updated timer value', value);
            const updatedValue = value ? value + duration * 1000 : 0;
            setTimerEndValue(updatedValue);
        };
        firebaseClient.getDataSync(key, valueUpdated);
        return () => {
            firebaseClient.clearDataSync(key, valueUpdated);
        };
    }, [key, firebaseClient, duration, getStartTimeCallback]);

    return { show: timer > 0, timer };
}

export default useLocalSessionTimer;
