import { LOG_LEVEL, LOG_LEVEL_PREFIX } from 'utils/constants/logger';
import safeStringify from '../SafeStringify';

interface InterceptorOpts {
    level: number;
    prefix?: string;
    color?: string;
    sync?: boolean;
}

type InterceptorFunction = (opts: InterceptorOpts, ...args: any[]) => void;

export interface LogInterceptor {
    key: string;
    minLevel: number;
    acceptedLevels?: number;
    excludedLevels?: number;
    intercept: InterceptorFunction;
    setUser?: (
        user: { id: string; token: string },
        onError?: (error: Error) => void
    ) => void;
    setTags?: (tags: { [key: string]: string }) => void;
}

export interface LoggerOptions {
    interceptors?: LogInterceptor[];
    prefix?: string;
    color?: string;
    stringified?: boolean;
}

export interface LogCallbackOptions {
    prefix?: string;
    color?: string;
    level: number;
}

const DEFAULT_DATA_OBJ_DEPTH = 2;
const allLogLevels = Object.values(LOG_LEVEL).sort((a, b) => a - b);

export default class LogInterface {
    options: LoggerOptions = {
        interceptors: [],
        prefix: '',
    };

    constructor(options: LoggerOptions) {
        this.setOptions(options);
    }

    setOptions(options: LoggerOptions) {
        this.options = { ...this.options, ...options };

        // Initialize each interceptor's log levels
        this.options.interceptors.forEach((i) => {
            this.setInterceptorLevel(i);
        });
    }

    setInterceptorLevel(interceptor: LogInterceptor, minLevel?: number) {
        if (interceptor.acceptedLevels !== undefined) {
            return;
        }

        if (minLevel !== undefined && minLevel !== interceptor.minLevel) {
            interceptor.minLevel = minLevel;
        }

        interceptor.acceptedLevels = allLogLevels.reduce(
            (all, curr) =>
                curr >= interceptor.minLevel &&
                (interceptor.excludedLevels === undefined ||
                    (curr & interceptor.excludedLevels) !== curr)
                    ? all + curr
                    : all,
            0
        );
    }

    setInterceptorLevelByKey(interceptorKey: string, minLevel: number) {
        const interceptor = this.options.interceptors.find(
            (i) => i.key === interceptorKey
        );

        if (interceptor) {
            this.setInterceptorLevel(interceptor, minLevel);
        }
    }

    _log(
        level: number,
        { prefix = this.options.prefix, color },
        ...args: any[]
    ) {
        // Strigify the logs args if requested
        if (this.options.stringified) {
            args = args.map((a) =>
                typeof a === 'function'
                    ? '[Function]'
                    : typeof a === 'object' && a.name === 'Error' && a.stack
                    ? a
                    : typeof a === 'object'
                    ? safeStringify(a, null, null, DEFAULT_DATA_OBJ_DEPTH)
                    : a
            );
        }

        // Pass the log through all configured interceptors
        this.options.interceptors.forEach((interceptor) => {
            if ((level & interceptor.acceptedLevels) === level) {
                interceptor.intercept({ level, color, prefix }, ...args);
            }
        });
    }

    _formattedPrefix(level: number, prefix = this.options.prefix) {
        return `${prefix ? `${prefix}:` : ''}${
            LOG_LEVEL_PREFIX[level] || LOG_LEVEL_PREFIX[LOG_LEVEL.VERBOSE]
        }:`;
    }

    logCommon(opts: LogCallbackOptions, ...args: any[]) {
        const { prefix, color, level } = opts;
        this._log(
            level || LOG_LEVEL.VERBOSE,
            {
                prefix: this._formattedPrefix(level, prefix),
                color: color || this.options.color || '#F5F8FA',
            },
            ...args
        );
    }

    init(prefix: string, color: string, level = LOG_LEVEL.VERBOSE) {
        return (...args: any[]) =>
            this.logCommon({ prefix, color, level }, ...args);
    }

    log(...args: any[]) {
        this.logCommon(
            {
                level: LOG_LEVEL.VERBOSE,
                color: this.options.color || '#F5F8FA',
                prefix: this._formattedPrefix(LOG_LEVEL.VERBOSE),
            },
            ...args
        );
    }

    debug(...args: any[]) {
        this.logCommon(
            {
                level: LOG_LEVEL.DEBUG,
                color: this.options.color || '#48AFF0',
                prefix: this._formattedPrefix(LOG_LEVEL.DEBUG),
            },
            ...args
        );
    }
    info(...args: any[]) {
        this.logCommon(
            {
                level: LOG_LEVEL.INFO,
                color: this.options.color || '#48AFF0',
                prefix: this._formattedPrefix(LOG_LEVEL.INFO),
            },
            ...args
        );
    }
    warn(...args: any[]) {
        this.logCommon(
            {
                level: LOG_LEVEL.WARN,
                color: this.options.color || '#FFC940',
                prefix: this._formattedPrefix(LOG_LEVEL.WARN),
            },
            ...args
        );
    }
    error(...args: any[]) {
        this.logCommon(
            {
                level: LOG_LEVEL.ERROR,
                color: this.options.color || '#DB3737',
                prefix: this._formattedPrefix(LOG_LEVEL.ERROR),
            },
            ...args
        );
    }
    fatal(...args: any[]) {
        this.logCommon(
            {
                level: LOG_LEVEL.FATAL,
                color: this.options.color || '#FF7373',
                prefix: this._formattedPrefix(LOG_LEVEL.FATAL),
            },
            ...args
        );
    }

    logEvent(...args: any[]) {
        this.logCommon(
            {
                level: LOG_LEVEL.LOG_EVENT,
                color: this.options.color || '#F5F8FA',
                prefix: this._formattedPrefix(LOG_LEVEL.LOG_EVENT),
            },
            ...args
        );
    }

    rawJSON(message: { [key: string]: any }) {
        this.logCommon(
            {
                level: LOG_LEVEL.RAW_JSON,
            },
            message
        );
    }
}
