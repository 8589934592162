import { Form } from '@rocketseat/unform';
import Button from 'atoms/Button/button';
import FormField from 'components/community/common/FormField';
import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import { getCustomScroll } from 'components/molecules/utils/customStyles';
import { alpha } from 'foundations/theme';
import { getCardBackgroundStyle } from 'foundations/themeV2/utils';
import { Typography } from 'foundations/typography';
import styled, { createGlobalStyle, css } from 'styled-components';
import { space } from 'styled-system';

export const BackDrop = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: ${({ theme }) => alpha(theme.colors.ambience[24], 0.5)};
`;

export const TabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
`;

export const NameFormField = styled(FormField)`
    &.form-field {
        display: flex;
        align-items: center;
    }
`;
export const SessionTagsFormField = styled(FormField)`
    &.form-field {
        font-family: ${({ theme }) => theme.fonts.regular} !important;
        border: 1px solid
            ${({ theme, isDisabled }) =>
                isDisabled
                    ? theme.colors.border.default2
                    : theme.colors.border.default1};
        color: ${({ theme, isDisabled }) =>
            isDisabled
                ? theme.colors.text.default.disabled
                : theme.colors.text.default.primary};
        background-color: ${({ theme, isDisabled }) =>
            isDisabled
                ? theme.colors.fill.default2
                : theme.colors.fill.default2};
        min-height: 40px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        padding: ${({ theme }) => `${theme.space.x1} ${theme.space.x2}`};

        input,
        textarea {
            min-height: 0 !important;
        }

        &.select div[class$='-control'] {
            min-height: 1px;

            .custom__multi-value {
                ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? `
                        ${getCardBackgroundStyle({ theme })}
                        border: 1px solid ${theme.colors.border.default1};
                        padding: 0;
                    `
                        : ''}
                .custom__multi-value__label {
                    font-size: ${({ theme }) => theme.fontSizes.x1};
                    color: ${({ theme }) =>
                        theme.colors.text.default.secondary};
                    font-family: ${({ theme }) => theme.fonts.regular};
                    line-height: ${({ theme }) => theme.lineHeights.x1};
                    vertical-align: middle;
                    padding: ${({ theme }) =>
                        `${theme.space.x1} ${theme.space.x1} ${theme.space.x1} ${theme.space.x3}`};
                }
                .custom__multi-value__remove {
                    border-top-right-radius: ${({ theme }) =>
                        theme.radii.default};
                    border-bottom-right-radius: ${({ theme }) =>
                        theme.radii.default};
                    padding: ${({ theme }) =>
                        `${theme.space.x1} ${theme.space.x3} ${theme.space.x1} 0px`};
                    svg {
                        fill: ${({ theme }) =>
                            theme.colors.text.default.secondary};
                    }
                }
            }
        }

        &:hover {
            background-color: ${({ theme }) => theme.colors.fill.default1};
            border-color: ${({ theme }) => theme.colors.border.other1};
        }
        .custom__placeholder {
            margin-left: 3px;
            font-family: ${({ theme }) => theme.fonts.regular} !important;
        }
    }
`;

export const StyledLink = styled.a`
    ${space}
    text-decoration: underline !important;
    display: inline-block;
    color: ${({ theme }) => theme.colors.brandDefault} !important;
`;
export const StyledBox = styled(Box)`
    ${({ customStyle }) =>
        customStyle &&
        css`
            ${customStyle}
        `}
`;

export const PremiumIconImg = styled.img`
    height: 16px;
    width: auto;
    cursor: pointer;
`;

export const StyledList = styled.ul`
    padding-left: 18px;
`;

export const CssOverride = createGlobalStyle`
    textarea#summary {
        max-height:43px;
    }
    .dashboard-session{
        max-width: 271px;
        .detail{
            border-radius: 8px;
            padding: 17px 22px;
            background-color: ${({ theme }) => theme.colors.ambience[1]};
            color:${({ theme }) => theme.colors.ambience[24]};
        }
    }
`;

export const HideHostButton = styled(Button)`
    position: absolute;
    top: calc(50% + 20px);
    right: 20px;
    z-index: 20;
    transform: translateY(-50%);
`;

export const TimeBox = styled(Box)`
    .form-field {
        position: relative;
        display: flex;
        align-items: center;
        input {
            padding-right: 55px;
            position: relative;
            z-index: 1;
        }
        &.filled:after {
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
            content: 'Mins';
            font-size: 14px;
            line-height: 16px;
            font-family: ${({ theme }) => theme.fonts.book};
            color: ${({ theme }) => theme.colors.ambience[9]};
            z-index: 0;
        }
        &.error.filled {
            input {
                padding-right: 0px;
            }
            &:after {
                content: normal;
            }
        }
        &:not(.filled) {
            input {
                padding-right: 0px;
            }
        }
    }
`;

export const StyledForm = styled(Form)`
    height: 100%;
    display: flex;
    align-items: flex-start;
    .form-content {
        height: 100%;
        max-height: calc(100vh - 190px);
        overflow-y: hidden;
        & > .row {
            height: 100%;
        }
    }
`;

export const StyledTypography = styled(Typography)``;

export const ChangeSessionType = styled.div`
    align-self: flex-end;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    flex-grow: 1;
    flex-basis: 0;
    padding-left: 18px;
    ${StyledTypography} {
        display: inline-block;
        cursor: pointer;
    }
`;

export const FormFieldWIcon = styled(FormField)`
    .react-datepicker-wrapper {
        display: block;
        width: 100%;
    }
    .react-datepicker__input-container {
        position: relative;
        z-index: 1;
        display: block;
    }
    &.form-field {
        display: flex;
        align-items: center;
    }
    ${({ hasTimePicker }) =>
        hasTimePicker &&
        `
        &.form-field{
            padding:0 !important;
        }
        .custom-time-picker {
            input{
                padding-right:25px;
            }
        }
    `};
    .custom-time-dropdown {
        margin-top: 0px !important;
        width: 100%;
        max-width: 100%;
        z-index: 2;
        .react-datepicker {
            font-family: ${({ theme }) => theme.fonts.book};
            background-color: ${({ theme }) => theme.colors.ambience[1]};
            border-radius: 8px;
            overflow: hidden;
            border: 0;
            width: 100%;
            .react-datepicker__time-container {
                width: 100%;
                .react-datepicker__header--time {
                    display: none;
                }
                .react-datepicker__time {
                    background-color: ${({ theme }) =>
                        theme.colors.fill.default1};
                    border-radius: 0;
                    border: 1px solid
                        ${({ theme }) => theme.colors.border.default1};
                    .react-datepicker__time-box {
                        width: 100%;
                        .react-datepicker__time-list {
                            ${({ theme, forceDarkMode, verticalWidth }) =>
                                getCustomScroll({
                                    theme,
                                    forceDarkMode,
                                    verticalWidth,
                                })};
                            max-height: 164px;
                            overflow-y: auto;
                            padding: 16px 0;
                            .react-datepicker__time-list-item {
                                width: 100%;
                                margin: 0;
                                padding: 10px 16px;
                                height: unset;
                                font-size: 14px;
                                line-height: 16px;
                                text-align: left;
                                transition: all 0.3s ease-in-out;
                                text-transform: lowercase;
                                color: ${({ theme }) =>
                                    theme.colors.text.default.primary};
                                &.react-datepicker__time-list-item--disabled {
                                    opacity: 0.5;
                                    &:hover {
                                        background-color: transparent;
                                    }
                                }
                                &.react-datepicker__time-list-item {
                                    background-color: transparent;
                                }
                                &:first-child {
                                    padding-top: 0;
                                }
                                &:last-child {
                                    padding-bottom: 0;
                                }
                                &:hover {
                                    background-color: ${({ theme }) =>
                                        theme.colors.fill.other3};
                                }
                            }
                        }
                    }
                }
            }
            .react-datepicker__triangle {
                display: none;
            }
        }
    }
    .react-datepicker {
        &__day--keyboard-selected,
        &__month-text--keyboard-selected,
        &__quarter-text--keyboard-selected {
            background-color: transparent;
            color: inherit;
            &:hover {
                background-color: #f0f0f0;
            }
        }
        &__day--outside-month {
            color: #ccc;
        }
    }
    .react-datepicker__day--disabled.react-datepicker__day--selected {
        &:hover {
            background-color: #216ba5;
        }
    }
`;

export const StyledFlexDiv = styled(FlexDiv)`
    @media only screen and (max-width: 1059px) {
        flex-wrap: wrap;
    }
`;

export const StyledFormField = styled(FormField)`
    &.form-field {
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;

        &.select div[class$='-control'] {
            min-height: 1px;
            .[class$='-Input'] {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        ${({ isThemeV2, theme, isDisabled }) =>
            isThemeV2
                ? `
                    input, textarea {
                        min-height: 0;
                    }
                    font-family: ${theme.fonts.regular} !important;
                    min-height: ${theme.space.x8};
                    border: 1px solid ${theme.colors.border.default1};
                    color: ${theme.colors.text.default.primary};
                    background-color: ${theme.colors.fill.default2};
                    cursor: pointer;

                    &::placeholder {
                        color: ${theme.colors.text.default.secondary};
                        font-family: ${theme.fonts.regular} !important;
                    }
                
                    &:hover {
                        background-color: ${theme.colors.fill.default1};
                        border-color: ${theme.colors.border.other1};
                    }
                
                    &:focus,
                    &:active {
                        background-color: ${theme.colors.fill.default1};
                        border-color: ${theme.colors.border.other1};
                        outline: 4px solid ${theme.colors.border.default1};
                    }

                    ${
                        isDisabled
                            ? `
                                background-color: ${theme.colors.fill.default2};
                                border-color: ${theme.colors.border.default2};
                                color: ${theme.colors.text.default.disabled};
                                cursor: default;
                                pointer-events: none;
                            `
                            : ''
                    }
                `
                : `
                cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
                border: 1px solid ${theme.colors.ambience[14]};
                &:hover {
                    border: 1px solid
                        ${
                            isDisabled
                                ? theme.colors.ambience[14]
                                : theme.isLightTheme
                                ? theme.colors.accentPrimary[1]
                                : theme.colors.accentPrimary[0]
                        };
                }
                &:focus {
                    border: 1px solid
                        ${
                            theme.isLightTheme
                                ? theme.colors.accentPrimary[1]
                                : theme.colors.accentPrimary[0]
                        };
                    box-shadow: 0px 0px 2px 2px
                        ${
                            theme.isLightTheme
                                ? alpha(theme.colors.accentPrimary[1], 0.4)
                                : alpha(theme.colors.accentPrimary[0], 0.4)
                        };
                }
            `}
    }
    &.disable-form-field {
        ${({ isThemeV2, theme }) =>
            isThemeV2
                ? `
                    background-color: ${theme.colors.fill.default2};
                    border-color: ${theme.colors.border.default2};
                    color: ${theme.colors.text.default.disabled};
                    cursor: default;
                    pointer-events: none;
                `
                : `
                    border: 0;
                    min-height:52px;
                    cursor: not-allowed;
                    input {
                        cursor: not-allowed;
                        &:focus,
                        &:hover {
                            border: 1px solid ${theme.colors.ambience[14]};
                            box-shadow: none;
                        }
                    }
                    &:focus,
                    &:hover {
                        border: 0;
                        box-shadow: none;
                    }
                `}
    }
`;

export const TabsWrapper = styled(FlexDiv)`
    height: 100%;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    .tabsContent {
        display: none;
    }
    .tabsTrigger {
        flex: 0;
        white-space: nowrap;
        padding: 0 ${({ theme }) => theme.space.x3};
    }
`;
