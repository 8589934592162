export const WORKER_NAME_LOGGER = 'logger';
export const WORKER_NAME_TRANSITIONS = 'transition-monitor';
export const WORKER_NAME_ANALYTICS = 'analytics-monitor';
export const WORKER_NAME_INTERVALS = 'interval-tracker';
export const WORKER_NAME_USER_CACHE = 'user-cache';
export const WORKER_NAME_PROFILING = 'profiling-monitor';

export const isWorkerThread =
    typeof WorkerGlobalScope !== 'undefined' &&
    // eslint-disable-next-line no-restricted-globals
    self instanceof WorkerGlobalScope;
