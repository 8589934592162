import { useLiveSessionContext } from 'context/Session';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import useDataWriter from 'hooks/useDataWriter';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import useToasts from 'hooks/useToasts';
import keys from 'locale/keys';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import UserService from 'services/users/UserService';
import {
    getIsStageMemberLimitReached,
    getStageUsersVisibility,
} from 'store/selectors';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import { TOAST_VERSION_2 } from 'utils/constants/toasts';
import firebaseLogger from 'utils/firebaseLogger';
import { logger } from 'utils/logger';

const { REMOVE } = FEATURE_ACTIONS[FEATURE_NAMES.STREAM_PUBLISHER];

const emptyObj = {};
const eventLogs = (message) => {
    logger.info(`User visibility event: ${message}`);
};

export const USER_STAGE_VISIBILITY = 'userVisibility';

const useUserVisibilityOnStageAction = ({ channelName } = {}) => {
    const {
        featureDataClients: { stage: firebaseClient },
        airmeet: {
            airmeetId,
            data: {
                currentSession,
                userStageRole,
                currentAirmeet: { community_id, community_name },
            },
        },
        user,
    } = useLiveAirmeetContext();
    const { cohost_ids = [], host_id = [], speaker_id = [] } =
        currentSession || emptyObj;
    const stageRoomNode = useLiveSessionContext((ctx) => ctx?.stageRoomNode);
    const sessionHostAndSpeakerList = useMemo(() => {
        return [...cohost_ids, ...host_id, ...speaker_id];
    }, [cohost_ids, host_id, speaker_id]);
    const { t } = useCustomTranslation();
    const { successToast, errorToast } = useToasts();
    const visibleUserState = useSelector(getStageUsersVisibility) || emptyObj;
    const hasStageMemberLimitReached = useSelector(
        getIsStageMemberLimitReached
    );
    const { write: publisherDataWriter } = useDataWriter(
        FEATURE_NAMES.STREAM_PUBLISHER
    );
    const userVisibilityKey =
        stageRoomNode && `${stageRoomNode}/genral/${USER_STAGE_VISIBILITY}`;

    const sessionId = currentSession ? currentSession.sessionid : airmeetId;

    const channelPublisherKey =
        channelName &&
        `${airmeetId}/meta-data/broadcastChannel/${channelName}/channelPublisher`;
    const logFirebaseEvent = useCallback(
        (event, otherParams = {}) => {
            const { peerRole } = otherParams;
            firebaseLogger.hideUnhideUser(event, {
                community_id: community_id,
                community_name: community_name,
                airmeet_id: airmeetId,
                user_role: userStageRole,
                userid: user.id,
                airmeet_session_id: sessionId,
                ...(peerRole && { peer_role: peerRole }),
            });
        },
        [
            community_id,
            community_name,
            airmeetId,
            userStageRole,
            user.id,
            sessionId,
        ]
    );

    const hideUserFromStage = useCallback(
        async (id, extraParams = {}) => {
            const {
                forceUpdate = false,
                selfAction = false,
                peerRole,
            } = extraParams;
            if (!id || !firebaseClient || !userVisibilityKey) return;
            firebaseClient.setData(`${userVisibilityKey}/${id}`, {
                visibility: false,
                timestamp: firebaseClient.getServerTimestampRef(),
                updatedBy: user.id,
            });
            if (!selfAction) logFirebaseEvent('hideUser', { peerRole });
            eventLogs(
                `User ${id} visibility state changed to false by ${user.id}`
            );

            // Clear the channel publisher node of the user
            const userInfo = await UserService.getInstance().fetchUser(id);
            if (userInfo.id_seq && channelName) {
                publisherDataWriter(
                    {
                        data: null,
                    },
                    {
                        action: REMOVE,
                        channelPublisherKey,
                        id: userInfo.id_seq,
                    }
                );
            }
        },
        [
            userVisibilityKey,
            firebaseClient,
            user,
            channelPublisherKey,
            publisherDataWriter,
            logFirebaseEvent,
        ]
    );

    const showUserOnStage = useCallback(
        async (id, extraParams = {}) => {
            const { selfAction = false, peerRole } = extraParams;
            if (!id || !firebaseClient || !userVisibilityKey) return;
            if (hasStageMemberLimitReached && !selfAction) {
                errorToast(t(keys.STAGE_USER_LIMIT_REACHED_FOR_SPEAKER));
                return;
            }
            const shouldVisibleSelfUser =
                selfAction && hasStageMemberLimitReached ? false : true;
            firebaseClient.setData(`${userVisibilityKey}/${id}`, {
                visibility: shouldVisibleSelfUser,
                timestamp: firebaseClient.getServerTimestampRef(),
                updatedBy: user.id,
            });

            const userInfo = await UserService.getInstance().fetchUser(id);
            if (!selfAction) logFirebaseEvent('unhideUser', { peerRole });
            eventLogs(
                `User ${id} visibility state changed to ${true} by ${user.id}`
            );

            !selfAction &&
                successToast({
                    title:
                        id === user.id
                            ? t(keys.STAGE_SHOW_USER_TOAST_SELF)
                            : t(keys.STAGE_SHOW_USER_TOAST, {
                                  userName: userInfo.name,
                              }),
                    version: TOAST_VERSION_2,
                });
        },
        [
            userVisibilityKey,
            firebaseClient,
            stageRoomNode,
            logFirebaseEvent,
            errorToast,
            successToast,
            hasStageMemberLimitReached,
            user.id,
            t,
        ]
    );

    const clearSelfVisibility = useCallback(() => {
        if (!user.id || !firebaseClient || !userVisibilityKey) return;
        eventLogs(`User cleared self visibility ${user.id}`);
        firebaseClient.setData(`${userVisibilityKey}/${user.id}`, null);
    }, [userVisibilityKey, firebaseClient, user.id]);

    const clearUserVisibility = useCallback(
        (userId) => {
            if (!userId || !firebaseClient || !userVisibilityKey) return;
            eventLogs(`Cleared user - ${userId} visibility by ${user.id}`);
            firebaseClient.setData(`${userVisibilityKey}/${userId}`, null);
        },
        [firebaseClient, userVisibilityKey, user.id]
    );

    const resetVisibility = useCallback(() => {
        Object.keys(visibleUserState).forEach((id) => {
            if (!sessionHostAndSpeakerList.includes(id) && userVisibilityKey) {
                eventLogs(`Resetting visibility for ${id}`);
                firebaseClient.setData(`${userVisibilityKey}/${id}`, null);
                eventLogs(`Visibility has been reset for the user - ${id}`);
            }
        });
    }, [
        userVisibilityKey,
        firebaseClient,
        sessionHostAndSpeakerList,
        visibleUserState,
    ]);

    return {
        hideUserFromStage,
        showUserOnStage,
        clearSelfVisibility,
        clearUserVisibility,
        resetVisibility,
    };
};

export default useUserVisibilityOnStageAction;
