import { Text } from 'foundations/themeV2/text/index';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import React from 'react';
import styled from 'styled-components';
import { stateToLocalSettings } from '../utils';
import SettingItem from './SettingItem';

function PanelSettings({
    liveConfig,
    setPromptData,
    onChange,
    settings,
    isConference = true,
    title,
    description,
    logEvent,
}) {
    const {
        airmeet: {
            data: {
                currentAirmeet: {
                    enabled_feature_data_v2: {
                        closed_captions: closeCaptionFlag,
                    },
                },
            },
        },
    } = useLiveAirmeetContext();

    const { eventSettings, liveConfigData } = stateToLocalSettings(
        settings,
        liveConfig,
        closeCaptionFlag
    );

    return (
        <WidgetWrapper>
            <Text variant='body1' color='text.default.primary' mb='8px'>
                {title}
            </Text>
            <Text variant='caption' color='text.default.secondary'>
                {description}
            </Text>
            <Wrapper>
                {(eventSettings || []).map((item) => (
                    <SettingItem
                        key={item?.key}
                        liveConfig={liveConfigData}
                        setPromptData={setPromptData}
                        onChange={onChange}
                        eventSettings={eventSettings}
                        isConference={isConference}
                        logEvent={logEvent}
                        item={item}
                    />
                ))}
            </Wrapper>
        </WidgetWrapper>
    );
}

export default PanelSettings;

const WidgetWrapper = styled.div`
    margin-top: 0;
    margin-bottom: 56px;
`;

const Wrapper = styled.div`
    margin-top: 24px;
`;
