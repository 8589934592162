import moment from 'moment';
import React, { useContext } from 'react';
import { AnalyticsContext } from '../AnalyticsProvider';
import HorizontalBarsCard from '../components/HorizontalBarsCard';
import { formatDuration, getCustomTooltip } from '../utils';

const getSessionStartEndTime = (item, format = 'HH:mm') => {
    if (!item) return;

    const startTime = moment(item?.start_time);
    const endTime = moment(item?.start_time).add(
        item?.duration || 0,
        'minutes'
    );

    return (
        startTime.isValid() &&
        endTime.isValid() &&
        `${startTime.format(format)} - ${endTime.format(format)}`
    );
};

const getTooltipTitle = (title, bodyLines) => {
    const label = bodyLines?.[0]?.[0]?.metaData?.session_name || '';

    return `
        <tr>
            <th style="color: #BEBCC8; border-color: transparent; border-width: 0; font-size: 10px; line-height: 1.5em;">
                ${label}
            </th>
        </tr>
    `;
};

const getTooltipBody = (bdy) => {
    const { attendance, average_time_spent } = bdy?.metaData || {};

    const startEndTime = getSessionStartEndTime(bdy?.metaData, 'h:mm A');

    return `
        <tr>
            <td style="color: #BEBCC8; border-color: transparent; border-width: 0; font-size: 10px; line-height: 1.5em;">
                ${startEndTime}
            </td>
        </tr>
        <tr>
            <td style="color: #FCFCFC; font-size: 18px; line-height: 1em; font-weight: bold; padding-top: 20px;">
                ${attendance}<br />
                <span style="font-size: 10px; line-height: 1.5em; font-weight: normal;">
                    Attendance
                </span>
            </td>
            <td style="color: #FCFCFC; font-size: 18px; line-height: 1em; font-weight: bold; padding-top: 20px; text-align-left;">
                ${formatDuration(average_time_spent)}<br />
                <span style="font-size: 10px; line-height: 1.5em; font-weight: normal;">
                    Average Time Spent
                </span>
            </td>
        </tr>
    `;
};

function getTooltip(tooltipModel) {
    const ref = this;
    return getCustomTooltip({
        ref,
        tooltipModel,
        getTooltipTitle,
        getTooltipBody,
    });
}

export default function SessionAttendanceCard({ airmeetFormat }) {
    const { getRequest, timezone, sessionTags, sessionOptions } = useContext(
        AnalyticsContext
    );

    const getChartLabel = (item) => {
        const startEndTime = getSessionStartEndTime(item);
        return startEndTime
            ? `${startEndTime} | ${item?.session_name}`
            : item?.session_name;
    };

    return (
        <HorizontalBarsCard
            searchTags={sessionTags}
            searchOptions={sessionOptions}
            title='Session Attendance'
            getRequest={getRequest}
            timezone={timezone}
            perDayDataPath='stats.per_day'
            topDataInfo={[
                {
                    key: 'stats.average_attendance',
                    title: 'Average Attendance',
                    info: `This figure represents the all-day or per-day average attendance in your ${airmeetFormat}, based on your selection.`,
                },
                {
                    key: 'stats.average_time_spent',
                    title: 'Average Time Spent',
                    info: `This figure represents the all-day or per-day average time spent by all attendees in your ${airmeetFormat}, based on your selection.`,
                },
            ]}
            averageKey='stats.average_attendance'
            getChartLabel={getChartLabel}
            getTooltip={getTooltip}
        />
    );
}
