import Icon from 'foundations/icon';
import useTheme from 'hooks/useTheme';
import React from 'react';
import { components } from 'react-select';
import { DropdownArrow, SelectWrapper } from './styles';

const {
    ValueContainer,
    Placeholder,
    MultiValueContainer,
    DropdownIndicator,
    ClearIndicator,
} = components;

export const CustomValueContainer = ({ children, ...props }) => {
    const isTags = props.selectProps.isTags;
    const { theme } = useTheme();
    const hasInputValue = props.selectProps && props.selectProps.inputValue;
    const wrap = props.selectProps.wrap;
    const multiTagStyles = isTags
        ? {
              display: 'flex',
              flexWrap: wrap ? 'wrap' : 'nowrap',
              marginTop:
                  props.hasValue || hasInputValue
                      ? wrap
                          ? '0px'
                          : '15px'
                      : '0',
              fontFamily: 'NotoSans-Regular',
              width: '100%',
          }
        : undefined;
    const multiStyles =
        props.isMulti && !isTags
            ? {
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
              }
            : undefined;
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            <div
                style={{
                    ...multiStyles,
                    ...multiTagStyles,
                    height: '100%',
                }}
            >
                {React.Children.map(children, (child) => {
                    return child && child.type !== Placeholder ? child : null;
                })}
            </div>
        </ValueContainer>
    );
};
const webEllipsis = (allSelected, initialValue, index) => {
    let val = initialValue;
    const allSelectedLabels = allSelected.map((selected) => selected.label);
    const fixedLength = 50;
    let targetIndex = 0;
    let previousLength = 0;
    allSelectedLabels.some((selectedLabel, index) => {
        const totalLength = selectedLabel.length + previousLength;
        if (totalLength >= fixedLength) {
            targetIndex = index;
            return true;
        }
        previousLength = totalLength;
        return false;
    });
    if (targetIndex > 0) {
        if (index === targetIndex) {
            val = `...${allSelected.length - index} more`;
        } else if (index > targetIndex) {
            val = '';
        }
    }
    return val;
};

const mobileEllipsis = (allSelected, initialValue, index) => {
    let val = initialValue;
    const allSelectedLabels = allSelected.map((selected) => selected.label);
    const fixedLength = 22;
    let targetIndex = 0;
    let previousLength = 0;
    let lengthBeforeCurrentIndex = 0;
    allSelectedLabels.some((selectedLabel, index) => {
        const totalLength = selectedLabel.length + previousLength;
        if (totalLength >= fixedLength) {
            targetIndex = index;
            lengthBeforeCurrentIndex = previousLength;
            return true;
        }
        previousLength = totalLength;
        return false;
    });
    if (targetIndex > 0) {
        if (index < targetIndex) {
            return val;
        } else if (index === targetIndex) {
            const remainingLength = fixedLength - lengthBeforeCurrentIndex;
            return val.substring(0, remainingLength).trim() + '...';
        } else {
            return '';
        }
    }
    return val;
};

export const multiValueContainer = (props) => {
    const { data, selectProps } = props;
    const label = data.label;
    const allSelected = selectProps.value;
    const isMobile = selectProps.isMobile;
    const index = allSelected.findIndex((selected) => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? `` : ', ';
    let val = `${label}${labelSuffix}`;
    if (isMobile) {
        val = mobileEllipsis(allSelected, val, index);
    } else {
        val = webEllipsis(allSelected, val, index);
    }
    return <MultiValueContainer {...props}>{val}</MultiValueContainer>;
};

export const MaxLengthInputContainer = (props) => {
    const {
        selectProps: { maxCharLength },
    } = props;
    return <components.Input {...props} maxLength={maxCharLength} />;
};

export const SwitchOffAutoCompleteSelectContainer = ({
    autoComplete,
    ...props
}) => <components.Input {...props} autoComplete='off' />;

export const SwitchOnAutoCompleteSelectContainer = ({
    autoComplete,
    ...props
}) => <components.Input {...props} />;

export const CustomSelectContainer = ({ children, ...props }) => {
    return (
        <SelectWrapper>
            <components.SelectContainer {...props}>
                {children}
            </components.SelectContainer>
        </SelectWrapper>
    );
};

export const CustomDownChevron = (props) => {
    return (
        <DropdownIndicator {...props}>
            <DropdownArrow />
        </DropdownIndicator>
    );
};

export const CustomClearIcon = (props) => {
    return (
        <ClearIndicator {...props}>
            <Icon icon={'dismiss'} width={16} height={16} fill={'ambience.6'} />
        </ClearIndicator>
    );
};

export const renderColorStyles = ({ isMobile }) => ({
    control: (styles) => ({
        ...styles,
        backgroundColor: '#26252D',
        color: 'white',
        borderRadius: '8px',
        fontSize: '16px',
        lineHeight: '20px',
        paddingTop: 15,
        paddingBottom: 15,
        marginBottom: 20,
        border: 0,
        boxShadow: 'none',
        fontFamily: 'NotoSans-Regular',
    }),
    option: (styles) => ({
        ...styles,
        backgroundColor: '#393743',
        fontSize: 14,
        color: '#BEBCC8',
        fontFamily: 'NotoSans-Regular',
        padding: '8px 12px 8px 15px',
        '&:hover': {
            backgroundColor: '#26252D',
        },
    }),
    placeholder: (styles, state) => {
        return {
            ...styles,
            position: 'absolute',
            top: state.hasValue || state.selectProps.inputValue ? 5 : '50%',
            transition: 'top 0.1s, font-size 0.1s',
            fontSize: state.hasValue || state.selectProps.inputValue ? 12 : 16,
            padding:
                state.hasValue || state.selectProps.inputValue
                    ? 0
                    : isMobile
                    ? 5
                    : 10,
            marginLeft: state.hasValue || state.selectProps.inputValue ? 10 : 0,
            color: '#726E87',
            whiteSpace:
                state.hasValue || state.selectProps.inputValue
                    ? 'nowrap'
                    : 'normal',
            overflow:
                state.hasValue || state.selectProps.inputValue
                    ? 'hidden'
                    : 'visible',
            width:
                state.hasValue || state.selectProps.inputValue ? '90%' : '100%',
            textOverflow:
                state.hasValue || state.selectProps.inputValue
                    ? 'ellipsis'
                    : 'initial',
        };
    },
    dropdownIndicator: (styles) => ({
        ...styles,
    }),
    singleValue: (styles) => ({
        color: '#BEBCC8',
        fontSize: 16,
        marginTop: 15,
        marginLeft: 10,
        display: 'inline-block',
        maxWidth: '90%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: isMobile ? 46 : undefined,
        display: 'block',
    }),
    multiValue: (styles) => ({
        color: '#BEBCC8',
        fontSize: 16,
        marginTop: 15,
        marginLeft: 10,
        float: 'left',
    }),
    menu: (styles) => ({
        ...styles,
        zIndex: 2,
        marginTop: -15,
        marginBottom: 0,
        padding: '5px 0px',
        backgroundColor: '#393743',
        borderRadius: 8,
        border: 'none',
        maxHeight: '250px',
        boxShadow: 'none',
    }),
    menuList: (styles) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
        marginRight: 5,
        border: 'none',
        maxHeight: '230px',
    }),
});

export const createSelectOption = (value) => ({
    value,
    label: value,
});
