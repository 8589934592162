// Action names
import { CALL_API } from 'store/api';
import { API_SERVICE } from 'utils/apiService';
export const LIVE_AIRMEET_TOGGLE_HOST_VISIBILITY =
    'LIVE_AIRMEET_TOGGLE_HOST_VISIBILITY';
export const LIVE_AIRMEET_UPDATE_STREAMS_LAYOUT =
    'LIVE_AIRMEET_UPDATE_STREAMS_LAYOUT';

export const LIVE_AIRMEET_UPDATE_REACTIONS_LIST =
    'LIVE_AIRMEET_UPDATE_REACTIONS_LIST';
export const LIVE_AIRMEET_SET_DATA = 'LIVE_AIRMEET_SET_DATA';
export const SET_INVITED_SPEAKERS_DATA = 'SET_INVITED_SPEAKERS_DATA';
export const LIVE_AIRMEET_SET_LAYOUT = 'LIVE_AIRMEET_SET_LAYOUT';
export const SET_LITE_MODE_ENABLED = 'SET_LITE_MODE_ENABLED';
export const SET_ACTIVE_WATCH_MODE = 'SET_ACTIVE_WATCH_MODE';
export const SET_STAGE_INVITATION_DATA = 'SET_STAGE_INVITATION_DATA';
export const CHANGE_INVITE_CARD_VISIBILITY = 'CHANGE_INVITE_CARD_VISIBILITY';
export const SET_STREAM_LIMIT_MODAL_VISIBILITY =
    'SET_STREAM_LIMIT_MODAL_VISIBILITY';
export const SET_STAGE_MEMBER_LIMIT_REACHED = 'SET_STAGE_MEMBER_LIMIT_REACHED';
export const SET_TABLE_CODE = 'SET_TABLE_CODE';
export const CHANGE_RESOLUTION_MENU_STATUS = 'CHANGE_RESOLUTION_MENU_STATUS';
export const UPDATE_STAGE_ONLINE_MEMBERS = 'UPDATE_STAGE_ONLINE_MEMBERS';
export const UPDATE_USER_PRESENCE_MEMBERS = 'UPDATE_USER_PRESENCE_MEMBERS';
export const SET_AIRMEET_VISIT_ID = 'SET_AIRMEET_VISIT_ID';
export const SET_LOCAL_STREAM_IN_MIRROR = 'SET_LOCAL_STREAM_IN_MIRROR';
export const UPDATE_CHECK_DM_NOTIFICATION_THRESHOLD =
    'UPDATE_CHECK_DM_NOTIFICATION_THRESHOLD';
export const SET_LAST_DM_NOTIFICATION_TIME = 'SET_LAST_DM_NOTIFICATION_TIME';
export const SET_SOCIAL_STREAMING = 'SET_SOCIAL_STREAMING';
export const SET_FILTER_INFO = 'SET_FILTER_INFO';
export const SET_MUSIC_MODE = 'SET_MUSIC_MODE';
export const SET_VOLUME_CONTROL = 'SET_VOLUME_CONTROL';
export const SET_PERMISSION_WORKFLOW_VERSION =
    'SET_PERMISSION_WORKFLOW_VERSION';
export const SET_BREAKOUT_ACTIVE = 'SET_BREAKOUT_ACTIVE';
export const BREAKOUT_ROOM_JOIN_STATUS = 'BREAKOUT_ROOM_JOIN_STATUS';
export const SET_PERMISSION_SETUP_COMPLETE = 'SET_PERMISSION_SETUP_COMPLETE';
export const SET_IN_AIRMEET_PERMISSION_SETUP_COMPLETE =
    'SET_IN_AIRMEET_PERMISSION_SETUP_COMPLETE';
export const AUTHENTICATE_UNAUTHENTICATE_INVITED_SPEAKER =
    'AUTHENTICATE_UNAUTHENTICATE_INVITED_SPEAKER';
export const SET_STAGE_JOIN_REQUEST_DATA = 'SET_STAGE_JOIN_REQUEST_DATA';

export const UPDATE_SESSION_PLAYBACK_ACCESS_REQUEST =
    'UPDATE_SESSION_PLAYBACK_ACCESS_REQUEST';

export const UPDATE_SESSION_PLAYBACK_ACCESS_RESPONSE =
    'UPDATE_SESSION_PLAYBACK_ACCESS_RESPONSE';

export const DELETE_STAGE_JOIN_REQUEST_DATA = 'DELETE_STAGE_JOIN_REQUEST_DATA';
export const SET_STAGE_ID = 'SET_STAGE_ID';
export const SET_BANNER_FORM_DATA = 'SET_BANNER_FORM_DATA';
export const SET_BANNER_PREVIEW = 'SET_BANNER_PREVIEW';
export const IS_PLAY_VIDEO_ON_STAGE = 'IS_PLAY_VIDEO_ON_STAGE';
export const INVITE_TO_STAGE_PERMISSION_CHECK =
    'INVITE_TO_STAGE_PERMISSION_CHECK';
export const SET_VIDEO_HEADLINES_LIST = 'SET_VIDEO_HEADLINES_LIST';
export const sessionBaseProps = {
    reactions: {},
    raiseHands: {},
};

export const UPDATE_RTC_VENDOR_CONFIG = 'UPDATE_RTC_VENDOR_CONFIG';

// Action dispatchers
// export const toggleHostVisibility = (visible) => ({
//     type: LIVE_AIRMEET_TOGGLE_HOST_VISIBILITY,
//     payload: visible,
// });

// export const setLiveEventLayout = (layout) => ({
//     type: LIVE_AIRMEET_UPDATE_STREAMS_LAYOUT,
//     payload: layout,
// });

export const setPermissionSetupComplete = (payload) => ({
    type: SET_PERMISSION_SETUP_COMPLETE,
    payload: payload,
});

export const setInAirmeetPermissionSetupComplete = (payload) => ({
    type: SET_IN_AIRMEET_PERMISSION_SETUP_COMPLETE,
    payload: payload,
});
export const updateReactionsList = (sessionId, reactions) => ({
    type: LIVE_AIRMEET_UPDATE_REACTIONS_LIST,
    payload: {
        sessionId,
        reactions,
    },
});
export const updateUserPresenceMembers = (presenceIn, members) => ({
    type: UPDATE_USER_PRESENCE_MEMBERS,
    payload: {
        presenceIn,
        members,
    },
});

export const updateStageMembers = (stageId, members) => ({
    type: UPDATE_STAGE_ONLINE_MEMBERS,
    payload: {
        stageId,
        members,
    },
});

export const setLiteModeEnabled = (isEnabled) => ({
    type: SET_LITE_MODE_ENABLED,
    payload: isEnabled,
});

export const setActiveWatchMode = (mode) => ({
    type: SET_ACTIVE_WATCH_MODE,
    payload: mode,
});

export function liveSessionPlayVideoOnStage(payload) {
    return {
        type: IS_PLAY_VIDEO_ON_STAGE,
        payload: payload,
    };
}

export const setResolutionMenuOpen = (status) => ({
    type: CHANGE_RESOLUTION_MENU_STATUS,
    payload: status,
});

export const setLiveAirmeetData = (data) => ({
    type: LIVE_AIRMEET_SET_DATA,
    payload: data,
});

export const setStageInvitationData = (data) => ({
    type: SET_STAGE_INVITATION_DATA,
    payload: data,
});

export const changeInviteCardVisibilityFlag = (data) => ({
    type: CHANGE_INVITE_CARD_VISIBILITY,
    payload: data,
});

export const setStageMemberLimitReached = (data) => ({
    type: SET_STAGE_MEMBER_LIMIT_REACHED,
    payload: data,
});

export const setIsStreamLimitModalVisible = (data) => ({
    type: SET_STREAM_LIMIT_MODAL_VISIBILITY,
    payload: data,
});

export const setTableCode = (code) => ({
    type: SET_TABLE_CODE,
    payload: code,
});

export const setAirmeetVisitId = (visitId) => ({
    type: SET_AIRMEET_VISIT_ID,
    payload: visitId,
});

export const toggleLocalStreamMirror = () => ({
    type: SET_LOCAL_STREAM_IN_MIRROR,
});

export const updateCheckDmNotificationThreshold = (value) => ({
    type: UPDATE_CHECK_DM_NOTIFICATION_THRESHOLD,
    payload: value,
});

export const setLastDmNotificationTime = (value) => ({
    type: SET_LAST_DM_NOTIFICATION_TIME,
    payload: value,
});

export const setSocialStreaming = (value) => ({
    type: SET_SOCIAL_STREAMING,
    payload: value,
});

export const setFilterInfo = (value) => ({
    type: SET_FILTER_INFO,
    payload: value,
});

export const setPermissionWorkflowVersion = (value) => ({
    type: SET_PERMISSION_WORKFLOW_VERSION,
    payload: value,
});

export const setBreakoutActive = (isActive) => ({
    type: SET_BREAKOUT_ACTIVE,
    payload: isActive,
});

export const setStageId = (stageId) => ({
    type: SET_STAGE_ID,
    payload: stageId,
});

export const setBreakoutRoomJoinStatus = (value) => ({
    type: BREAKOUT_ROOM_JOIN_STATUS,
    payload: value,
});

export function authenticateUnauthenticateInvitedSpeaker({
    airmeetId,
    data,
    type,
}) {
    return {
        [CALL_API]: {
            types: [AUTHENTICATE_UNAUTHENTICATE_INVITED_SPEAKER],
            endpoint: `/airmeet/${airmeetId}/session/speaker/live`,
            body: data,
            type: 'json',
            method: type,
            service: API_SERVICE.ZEUS,
        },
    };
}

export function updateSessionPlaybackAccess({ airmeetId, sessionId, data }) {
    return {
        [CALL_API]: {
            types: [
                UPDATE_SESSION_PLAYBACK_ACCESS_REQUEST,
                UPDATE_SESSION_PLAYBACK_ACCESS_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/replaySettings`,
            body: {
                enabled: data?.replayEnable,
            },
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.ZEUS,
        },
    };
}

export const setInvitedSpeakersData = (value) => ({
    type: SET_INVITED_SPEAKERS_DATA,
    payload: value,
});

export const setStageJoinRequestData = (payload) => ({
    type: SET_STAGE_JOIN_REQUEST_DATA,
    payload,
});

export const clearStageJoinRequestData = (payload) => ({
    type: DELETE_STAGE_JOIN_REQUEST_DATA,
    payload,
});

export const setBannerFormData = (payload) => ({
    type: SET_BANNER_FORM_DATA,
    payload,
});

export const setBannerPreview = (payload) => ({
    type: SET_BANNER_PREVIEW,
    payload,
});

export const setInviteToStagePermissionProcess = (data) => ({
    type: INVITE_TO_STAGE_PERMISSION_CHECK,
    payload: data,
});

export const setMusicMode = (value) => ({
    type: SET_MUSIC_MODE,
    payload: value,
});

export const setVolumeControl = (value) => ({
    type: SET_VOLUME_CONTROL,
    payload: value,
});

export const updateRTCVendorConfig = (payload) => ({
    type: UPDATE_RTC_VENDOR_CONFIG,
    payload,
});

export const setUsersHeadlinesList = (payload) => ({
    type: SET_VIDEO_HEADLINES_LIST,
    payload,
});
