/**
 * @module useStageUsersRoles
 * @file src/hooks/useStageUsersRoles.js
 * @description module to provide role information of stage users;
 * information about user roles like:
 * event organiser(organiser), speaker, attendee, host(session host)
 *
 * @returns {Object}
 * values for event host(organiser),
 * session host, speakers, logged in user's stage role,
 * functions to get a user's stage role and the current session object
 *
 * @version 1.0.1
 * @copyright Airmeet Networks Pvt. Ltd.
 **/

import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { USER_ROLE } from 'utils/constants/users';

import { getInvitedSpeakers } from 'store/selectors/liveAirmeet';
const { HOST, ORGANIZER, SPEAKER, ATTENDEE, COHOST } = USER_ROLE;

const useStageUsersRoles = (props = {}) => {
    const {
        user: inputUser = null,
        airmeet: inputAirmeet = null,
        session: inputSession = null,
        sessionId,
        // invitedSpeakers = null,
    } = props;
    const user = useSelector(({ auth }) => (inputUser ? inputUser : auth.user));
    const airmeet = useSelector(({ lounge }) =>
        inputAirmeet ? inputAirmeet : lounge ? lounge.airmeet : null
    );
    const { airmeet: { data: { currentSession } = {} } = {} } =
        useLiveAirmeetContext() || {};

    const session = inputSession
        ? inputSession
        : sessionId
        ? getSessionObj(airmeet, sessionId)
        : currentSession;

    const invitedSpeakers = useRef();
    invitedSpeakers.current = useSelector(getInvitedSpeakers);

    return user
        ? getStageUsersRoles(user, airmeet, session, invitedSpeakers.current)
        : {};
};

export const getStageUsersRoles = (user, airmeet, session, invitedSpeakers) => {
    const airmeetOrganiserId = airmeet.host;

    // user id of a session host, can be either assigned host or event organiser
    const stageHostId = isEmpty(session)
        ? airmeet.host
        : first(session.host_id);

    const getIsUserInvitedToStage = (id) =>
        invitedSpeakers &&
        session &&
        invitedSpeakers[session.sessionid] &&
        invitedSpeakers[session.sessionid][id];

    // user objects of speakers for the current session
    // default : []
    const stageSpeakers = !isEmpty(session) ? session.speaker_id : [];

    const conferenceEvent =
        airmeet.parallelTrackData &&
        airmeet.parallelTrackData.isParallelTrackEvent;

    /**
     * finds and returns the stage role of the provided user id
     * @param {String} id user id
     */
    const getUserStageRole = (id, targetSessionId) => {
        let user = { id };

        return getRole(
            airmeet,
            user,
            getIsUserInvitedToStage(id),
            targetSessionId ? getSessionObj(airmeet, targetSessionId) : session,
            conferenceEvent
        );
    };

    // stage role of the current logged in user
    const userStageRole = getUserStageRole(user.id);

    return {
        airmeetOrganiserId,
        getUserStageRole,
        stageHostId,
        stageSpeakers,
        userStageRole,
    };
};
/* HELPER FUNCTIONS */

/**
 * helper function to retreive the stage role of a user by id
 * @param {Object} airmeet airmeet info
 * @param {Object} userObj the current logged in user
 * @param {Boolean} isUserInvitedToStage indicates whether the logged in user is on stage through raise hand or is invited
 */

export const getRole = (
    airmeet,
    userObj,
    isUserInvitedToStage,
    session = null,
    conferenceEvent = false
) => {
    let role = ATTENDEE;

    if (isEmpty(airmeet) || isEmpty(userObj)) {
        return role;
    }

    if (isEmpty(session)) {
        if (userObj.id === airmeet.host) {
            if (conferenceEvent) {
                role = ORGANIZER;
            } else {
                role = HOST;
            }
        }
    } else if (session.host_id.includes(userObj.id)) {
        role = HOST;
    } else if (session.cohost_ids && session.cohost_ids.includes(userObj.id)) {
        role = COHOST;
    } else if (!conferenceEvent && !session && userObj.id === airmeet.host) {
        role = ORGANIZER;
    } else if (
        session.speaker_id.includes(userObj.id) ||
        isUserInvitedToStage
    ) {
        role = SPEAKER;
    } else if (session.cloud_user_id === userObj.id) {
        role = SPEAKER;
    }

    return role;
};

/**
 * returns the JSON object of the upcoming or current session
 * @param {Object} airmeet airmeet info
 * @returns {Object} session or empty object
 */

const getSessionObj = (airmeet, sessionId) =>
    airmeet.session_enabled
        ? airmeet.sessions.find(({ sessionid, status }) =>
              sessionId ? sessionid === sessionId : status !== 'FINISHED'
          )
        : {};

export default useStageUsersRoles;
