import { motion } from 'framer-motion';
import useTheme from 'hooks/useTheme';
import React from 'react';
import { Dot, ProgressContainer } from './styledComponents';

const DotLoader = ({ duration, theme }) => {
    return (
        <svg width='16' height='16' style={{ transform: 'rotate(-90deg)' }}>
            <motion.circle
                stroke={theme.colors.accentPrimary[1]}
                strokeWidth='2'
                fill='transparent'
                r='7'
                cx='8'
                cy='8'
                strokeDasharray='44 44'
                initial={{
                    strokeDasharray: '0 44',
                }}
                animate={{
                    strokeDasharray: '44 44',
                }}
                transition={{
                    duration,
                }}
            />
        </svg>
    );
};

export default function PollCarouselProgress({ active, onClick, duration }) {
    const { theme } = useTheme();

    return (
        <ProgressContainer onClick={onClick}>
            {active && <DotLoader duration={duration} theme={theme} />}
            <Dot
                background={
                    active
                        ? theme.colors.accentPrimary[1]
                        : theme.colors.ambience[13]
                }
                scale={active ? 0.9 : 0.75}
            />
        </ProgressContainer>
    );
}
