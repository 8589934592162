import _omit from 'lodash/omit';
import { ADD_DND_USERS, REMOVE_DND_USERS } from 'store/actions/DndUsers';

const initialState = {};

function dndUsers(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case ADD_DND_USERS:
            return { ...state, ...payload };
        case REMOVE_DND_USERS:
            return _omit(state, payload);
        default:
            return state;
    }
}

export default dndUsers;
