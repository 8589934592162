import { useEffect, useState } from 'react';
import { isAutomationMode } from 'utils/constants/common';
import useQuery from './useQuery';

const AUTOMATION_KEYS = ['ma', 'mv', 'vc', 'vm', 'cs', 'clean', 'rp'];

function useAutomationParams({ airmeetId }) {
    const query = useQuery();
    const [params, setParams] = useState(null);

    useEffect(() => {
        if (!isAutomationMode()) {
            return;
        }

        const finalParams = {};
        for (let i = 0; i < AUTOMATION_KEYS.length; i++) {
            const paramVal = query.get(AUTOMATION_KEYS[i]) || null;

            if (paramVal !== null) {
                finalParams[AUTOMATION_KEYS[i]] = paramVal;
            }
        }

        setParams(finalParams);
    }, []);

    useEffect(() => {
        if (!isAutomationMode()) {
            return;
        }

        if (params && airmeetId) {
            if (params.clean) {
                Object.entries(localStorage).forEach(([key]) => {
                    if (
                        key.startsWith(`automation-`) &&
                        key.endsWith(`-${airmeetId}`)
                    )
                        delete localStorage[key];
                });
            }
            Object.entries(params).forEach(([key, val]) => {
                localStorage.setItem(
                    `automation-${key}-${airmeetId}`,
                    `${val}`
                );
            });
        }
    }, [airmeetId, params]);
}

export default useAutomationParams;
