import { useMemo } from 'react';
import useLiveAirmeetContext from '../useLiveAirmeetContext';
import useRealtimeData from './useRealtimeData';

function useRealtimeFeatureConfig({
    airmeetId: inAirmeetId,
    userId: inUserId,
    firebaseClient,
    featureName,
}) {
    const airmeetId = useLiveAirmeetContext(
        (s) => s.airmeet.airmeetId,
        inAirmeetId
    );
    const userId = useLiveAirmeetContext((s) => s.user.id, inUserId);

    const airmeetConfig = useRealtimeData({
        firebaseClient,
        path:
            airmeetId && featureName
                ? `${airmeetId}/featureConfig/${featureName}`
                : null,
    });

    const userConfig = useRealtimeData({
        firebaseClient,
        path:
            airmeetId && userId && featureName
                ? `${airmeetId}/featureConfig/${userId}/${featureName}`
                : null,
    });

    const data = useMemo(
        () => ({
            ...airmeetConfig,
            ...userConfig,
        }),
        [airmeetConfig, userConfig]
    );

    return data;
}

export default useRealtimeFeatureConfig;
