import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
const { EVENT_CHAT } = FEATURE_NAMES;
const eventChatWriter = ({ airmeetId, client, payload, metaData, logger }) => {
    if (!metaData?.channelTag) {
        throw new Error('Invalid event chat key');
    }
    const fireBaseKey = `${airmeetId}/chats/${metaData?.channelTag}`;

    const handleAddmessage = () => {
        logger.info('Sending Event Chat');

        if (metaData?.key) {
            return client.setDataAsync(
                `${fireBaseKey}/${metaData.key}`,
                payload
            );
        }
        return client.pushData(fireBaseKey, payload);
    };

    const handleRemoveMessage = () => {
        logger.info('Removing Event Message');
        if (!metaData.chatKey || !metaData.userId) {
            throw new Error('Invalid remove message firebase key');
        }
        return client.setData(`${fireBaseKey}/${metaData.chatKey}`, null);
    };

    switch (metaData?.action) {
        case FEATURE_ACTIONS[EVENT_CHAT].ADD_MESSAGE:
            return handleAddmessage();
        case FEATURE_ACTIONS[EVENT_CHAT].REMOVE_MESSAGE:
            return handleRemoveMessage();
        default:
            throw new Error('Invalid Event Chat action');
    }
};

export default eventChatWriter;
