import DetectRTC from 'detectrtc';
import localIpUrl from 'local-ip-url';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { browserName } from 'react-device-detect';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import {
    isBlockedByFirewallConditions,
    webRTCFirewallDetectionStates,
} from 'utils/constants/live-airmeet';
import { USER_ROLE } from 'utils/constants/users';

export const getUserDataPath = ({ airmeetId, userId, username }) =>
    `${airmeetId}/userInfo/${userId}/${username
        .replace(/[^a-zA-Z\s ]/g, '')
        .replace(/\t+/g, ' ')}`;

const { ORGANIZER, HOST, SPEAKER, ATTENDEE, EXHIBITOR, COHOST } = USER_ROLE;

const loweredCase = (input) =>
    typeof input === 'string' ? input?.toLowerCase() : '';

export const isOrganizer = (role = '') => loweredCase(role) === ORGANIZER;
export const isHost = (role = '') => loweredCase(role) === HOST;
export const isCoHost = (role = '') => loweredCase(role) === COHOST;
export const isAttendee = (role = '') => loweredCase(role) === ATTENDEE;
export const isSpeaker = (role = '') => loweredCase(role) === SPEAKER;
export const isExhibitor = (role = '') => loweredCase(role) === EXHIBITOR;

export const isRoleEqual = (role1, role2) =>
    loweredCase(role1) === loweredCase(role2);

export const includesRole = (roles, role = '') => {
    const roleToTest = loweredCase(role);
    return !!roles.find((role) => loweredCase(role) === roleToTest);
};

let lastSavedUserData = null;
export const updateUserInfo = ({
    airmeetId,
    userId,
    username,
    firebaseClient,
    hasBlockedWebRTCConnection,
}) => {
    let browserNotification = 'Granted';
    DetectRTC.load(function () {
        if ('Notification' in window) {
            browserNotification = Notification.permission;
        }

        let data = {
            device: DetectRTC.isMobileDevice ? 'Mobile' : 'Desktop',
            webcam: DetectRTC.isWebsiteHasWebcamPermissions,
            microphone: DetectRTC.isWebsiteHasMicrophonePermissions,
            id: userId,
            browserNotification: browserNotification,
            username: username,
            url: window.location.href,
            browserDetails: {
                name: browserName,
                version: DetectRTC.browser.version,
            },
            osDetails: {
                name: DetectRTC.osName,
                version: DetectRTC.osVersion,
            },
            ip: localIpUrl('public'),
            Time: {
                userEnteredTime: 0,
                userExitTime: 0,
            },
            isBlockedByFirewall: isBlockedByFirewallConditions.includes(
                hasBlockedWebRTCConnection
            )
                ? true
                : false,
            isConnectedViaProxy: getAirmeetUtilInstance()
                ? getAirmeetUtilInstance().connectViaProxy ||
                  getAirmeetUtilInstance().useAirmeetProxy
                : false,
            proxyType:
                hasBlockedWebRTCConnection ===
                    webRTCFirewallDetectionStates.proxySuccess ||
                hasBlockedWebRTCConnection ===
                    `${webRTCFirewallDetectionStates.proxySuccess}${webRTCFirewallDetectionStates.customTURNSuffix}`
                    ? 'agora'
                    : hasBlockedWebRTCConnection ===
                          webRTCFirewallDetectionStates.airmeetProxySuccess ||
                      hasBlockedWebRTCConnection ===
                          `${webRTCFirewallDetectionStates.airmeetProxySuccess}${webRTCFirewallDetectionStates.customTURNSuffix}`
                    ? 'airmeet'
                    : 'none',
            isUsingCustomTURN: hasBlockedWebRTCConnection.includes(
                webRTCFirewallDetectionStates.customTURNSuffix
            )
                ? true
                : false,
        };

        if (false === isEqual(lastSavedUserData, data)) {
            lastSavedUserData = data;
            _updateUserInfo({
                airmeetId,
                userId,
                username,
                firebaseClient,
                hasBlockedWebRTCConnection,
                data,
            });
        }
    });
};

const _updateUserInfo = ({
    airmeetId,
    userId,
    username,
    firebaseClient,
    data,
}) => {
    const path = getUserDataPath({
        airmeetId,
        userId,
        username,
    });
    firebaseClient.setData(path, data);
};

export const getUserCurrentLanguage = (user) => {
    const userSetLang = user?.metadata?.lang;
    if (userSetLang?.is_user_set) {
        return userSetLang?.key;
    }
    return null;
};

export const getStageUserIds = (airmeetData) => {
    let userFilter = [];
    if (airmeetData) {
        if (airmeetData.sessions) {
            userFilter = airmeetData.sessions.reduce(
                (users, sess) => [
                    ...users,
                    ...sess.host_id,
                    ...sess.speaker_id,
                    ...sess.cohost_ids,
                    sess.cloud_user_id,
                    sess.recording_user_id,
                ],
                []
            );
        }

        // Adding airmeet host as part of user api call.
        if (airmeetData.host) {
            userFilter.push(airmeetData.host);
        }
    }
    return uniq(userFilter);
};

// Derive name from first/last name if name is not present
export const populateNameFromFirstLastName = (user) => {
    if (!isEmpty(user) && !user?.name) {
        const newUser = { ...(user || {}) };
        const firstName = newUser?.firstName || newUser?.first_name || '';
        const lastName = newUser?.lastName || newUser?.last_name || '';
        newUser.name = [firstName, lastName].filter(Boolean).join(' ');

        return newUser;
    }

    return user;
};
