import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { bindModuleLogger } from '../utils/logger';
import { WORKER_IDS, setTimeoutWorker } from 'utils/timeoutWorker';
import momenttz from 'moment-timezone';
import {
    HeartBeatObserver,
    EventCallback,
} from '../utils/loggers/HeartbeatObserver';
import PermissionUtils from 'utils/permission-utils';

const logger = bindModuleLogger('heartbeatObserver');

const HEART_BEAT_INTERVAL = 5 * 60 * 1000;
export const analyticsMethodsToIntercept = {
    ATTENDEE_JOIN: 'attendeeJoinedSession',
    ATTENDEE_EXIT: 'attendeeExitedSession',
    ATTENDEE_JOINED_TABLE: 'attendeeJoinedTable',
    ATTENDEE_LEFT_TABLE: 'attendeeLeftTable',
    BOOTH_PAGE: 'boothPage',
    ENTERED_AIRMEET: 'enteredAirmeet',
};

const logHeartbeatCallback: EventCallback = (beat) => {
    function loggerCallback() {
        // update timestamp of the heartbeat
        beat = { ...beat, timestamp_utc: momenttz.tz('Asia/Kolkata').unix() };
        logger.logEvent('heartbeat', beat);
        return true;
    }
    setTimeoutWorker(
        loggerCallback,
        HEART_BEAT_INTERVAL,
        true,
        WORKER_IDS.HEARTBEATS
    );
};

/* ---- Following method intercepts few firebase analytics events, and first emit them as heartbeats
and then a proxy function calls the actual log method ---- */

export const interceptFbAnalyticsLog = (analyticsObj, callbackFn) => {
    Object.keys(analyticsObj).forEach((method) => {
        if (Object.values(analyticsMethodsToIntercept).includes(method)) {
            const prop = analyticsObj[method];
            if (typeof prop === 'function') {
                const originalFn = prop;
                analyticsObj[method] = (...args) => {
                    callbackFn(method, args);
                    return Reflect.apply(originalFn, analyticsObj, args);
                };
            }
        }
    });
};

const useHeartBeatObserver = () => {
    const heartbeatObserver = useRef<HeartBeatObserver>();
    const airmeetId = useSelector(
        (state) =>
            state.cmDashboard?.airmeet?.airmeetId ||
            state.lounge?.airmeet?.airmeetId
    );
    const userId = useSelector((state) => state.auth.user?.id);
    const airmeetVisitId = useSelector((state) => {
        return state.liveAirmeet.airmeetVisitId;
    });

    const commonHeartbeatData = {
        airmeet_visit_id: airmeetVisitId,
        event_name: 'heartbeat',
        event_source: 'web',
        context_visit_ids: [{ context_visit_id: airmeetVisitId }],
        timestamp_utc: momenttz.tz('Asia/Kolkata').unix(),
    };

    useEffect(() => {
        if (!airmeetId || !userId || PermissionUtils.isEventCloudHost()) return;
        try {
            const hbObserver = new HeartBeatObserver(logger);
            hbObserver.setHeartbeatCommon({
                ...commonHeartbeatData,
                airmeet_id: airmeetId,
                attendee_id: userId,
            });
            hbObserver.listenHeartbeats(logHeartbeatCallback);
            heartbeatObserver.current = hbObserver;
        } catch (err) {
            logger.error(
                'Error occurred while initialising heartbeats observer',
                err
            );
        }
        return () => {
            heartbeatObserver.current.removeAllListeners();
        };
    }, [airmeetId, userId]);

    return {
        heartbeatObserver,
    };
};

export default useHeartBeatObserver;
