import { FlexDiv } from 'components/molecules/utils/basicComponents';
import RelativeToolTip from 'components/tooltip/relativeToolTip';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import React, { useMemo, useState } from 'react';
import BarChartCard from '../components/BarChartCard';

const TYPES = ['Device Type', 'Operating System', 'Web Browser'];

export default function DevicesCard({
    loading,
    devices,
    browsers,
    os,
    eventStarted,
    airmeetFormat,
}) {
    const [selectedType, setSelectedType] = useState(TYPES[0]);

    const isEmptyData = useMemo(() => {
        return isEmpty(devices) && isEmpty(browsers) && isEmpty(os);
    }, [devices, browsers, os]);

    const { topData, chartLabels, chartData } = useMemo(() => {
        let data = {};
        switch (selectedType) {
            case 'Device Type':
                data = devices || {};
                break;
            case 'Operating System':
                data = os || {};
                break;
            case 'Web Browser':
                data = browsers || {};
                break;
            default:
                break;
        }

        const orderedData = orderBy(
            Object.keys(data).map((key) => ({ key, count: data[key] })),
            ['count', 'key'],
            ['desc', 'asc']
        );

        const chartLabels = [];
        const chartData = [];
        if (!isEmptyData) {
            orderedData.forEach((item) => {
                chartLabels.push(item.key);
                chartData.push(item.count);
            });
        }

        return {
            topData: orderedData,
            chartLabels,
            chartData,
        };
    }, [selectedType, devices, browsers, os, isEmptyData]);

    return (
        <BarChartCard
            title={
                <FlexDiv alignItems='center'>
                    Device Attributes
                    <RelativeToolTip
                        place='top'
                        svgWidth='13px'
                        svgHeight='13px'
                        svgIcon='icon-fill-info'
                        svgFill='accentPrimary.0'
                        tooltipText='This section lets you see how many attendees joined from various devices and platforms.'
                        textColor='ambience.18'
                        textVariant='caption'
                        minWidth={290}
                        style={{ marginLeft: '10px' }}
                        showStyledSvgSprite={true}
                    />
                </FlexDiv>
            }
            topData={topData}
            chartLabels={chartLabels}
            chartData={chartData}
            types={TYPES}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            loading={loading}
            isEmpty={isEmptyData}
            label={selectedType}
            eventStarted={eventStarted}
            airmeetFormat={airmeetFormat}
        />
    );
}
