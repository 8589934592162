import TooltipV2 from 'atoms/Tooltip/Tooltip.tsx';
import FullscreenPortal from 'components/general/FullScreenPortal';
import { alpha } from 'foundations/theme';
import React, {
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import usePortal from 'react-cool-portal';
import ReactTooltip from 'react-tooltip';
import { createGlobalStyle } from 'styled-components';
import { localIDs } from '../../utils/id-generator';
import ChildrenWithProps from '../general/ChildrenWithProps';

const tooltipIDs = localIDs(0);

export const FullScreenToolTipPortal = ({ containerId, children }) => {
    return (
        <FullscreenPortal container={containerId}>{children}</FullscreenPortal>
    );
};

function Tooltip({
    place = 'top',
    className,
    style,
    toolClass,
    toolStyles,
    renderTrigger = null,
    renderElement = null,
    children,
    clickable = false,
    defaultOpen = false,
    afterHide = () => {},
    customEnable,
    offset,
    effect = 'solid',
    enableTimeout = false,
    timeoutDuration = 1000,
    port = true,
    disable = false,
    containerId = null,
    isCapture = false,
    rebuild = false,
    resetRebuild = () => {},
    wrapperProps = {},
    fullscreenSupport = true,
    isThemeV2 = false,
    maxWidth = '200px',
    hasWrapper = true,
}) {
    const tipID = useMemo(() => tooltipIDs.next().value, []);
    const [enable, setEnable] = useState(clickable || defaultOpen);
    const tooltipRef = useRef();
    const tooltipButtonRef = useRef();
    const { Portal } = usePortal({
        clickOutsideToHide: false,
        escToHide: false,
        containerId: containerId || 'root',
    });

    const toolTipOpen = () => {
        setEnable(true);
        tooltipButtonRef.current && ReactTooltip.show(tooltipButtonRef.current);
    };

    const toolTipClose = () => {
        tooltipButtonRef.current && ReactTooltip.hide(tooltipButtonRef.current);
        // setEnable(false);
    };

    useEffect(() => {
        if (defaultOpen && enable) {
            setTimeout(toolTipOpen, 10);
        }
    }, []);

    useEffect(() => {
        if (rebuild) {
            toolTipClose();
            setTimeout(() => {
                toolTipOpen();
            }, 0);
        }
    }, [rebuild]);

    const onMouseOverHandler = () => {
        if (!enable) setEnable(true);
    };
    const onMouseLeaveHandler = (event) => {
        setEnable(false);
    };

    useLayoutEffect(() => {
        // Don't run custom handling in case the prop is not set
        if (typeof customEnable === 'undefined' || customEnable === null) {
            return;
        }

        if (customEnable) {
            tooltipButtonRef.current &&
                ReactTooltip.show(tooltipButtonRef.current);
        } else {
            tooltipButtonRef.current &&
                ReactTooltip.hide(tooltipButtonRef.current);
        }
    }, [customEnable]);

    useEffect(() => {
        // Close the tooltip when will click outside from this
        if (clickable) {
            const handleClickOutside = (event) => {
                if (
                    tooltipButtonRef.current &&
                    !tooltipButtonRef.current.contains(event.target) &&
                    tooltipRef.current &&
                    !tooltipRef.current.contains(event.target)
                ) {
                    toolTipClose();
                }
            };
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [clickable, tooltipRef, tooltipButtonRef]);

    const props = clickable
        ? {
              onMouseOver: onMouseOverHandler,
              'data-event': 'click',
              'data-tip': 'custom show and hide',
          }
        : customEnable
        ? {
              'data-event': 'click',
              'data-tip': 'custom show and hide',
          }
        : {
              onMouseOver: onMouseOverHandler,
              onMouseLeave: onMouseLeaveHandler,
          };

    if (isThemeV2) {
        return (
            <TooltipV2
                content={renderElement}
                hasWrapper={hasWrapper}
                side={place}
                sideOffset={offset}
                className={className}
                containerId={containerId}
                maxWidth={maxWidth}
            >
                {renderTrigger || children}
            </TooltipV2>
        );
    }

    const tooltipBlock = () =>
        (enable || customEnable) && (
            <div ref={tooltipRef}>
                <ReactTooltip
                    disable={disable}
                    globalEventOff='click'
                    clickable={clickable}
                    isCapture={isCapture}
                    id={`tooltip-${tipID}`}
                    effect={effect}
                    place={place}
                    className={` ${toolClass ? `${toolClass}` : ''}`}
                    afterShow={
                        enableTimeout
                            ? () => setTimeout(toolTipClose, timeoutDuration)
                            : () => {}
                    }
                    afterHide={() => {
                        afterHide();
                        setEnable(false);
                        resetRebuild();
                    }}
                    {...toolStyles}
                >
                    {clickable ? (
                        <ChildrenWithProps
                            hideTooltipHandler={toolTipClose}
                            showTooltipHandler={toolTipOpen}
                        >
                            {renderElement}
                        </ChildrenWithProps>
                    ) : (
                        renderElement
                    )}
                </ReactTooltip>
            </div>
        );

    return (
        <>
            <div
                className={` ${className ? `${className}` : ''}`}
                style={style}
                data-tip
                data-offset={offset}
                data-for={`tooltip-${tipID}`}
                data-effect={effect}
                {...props}
                ref={tooltipButtonRef}
                {...(wrapperProps || {})}
            >
                {renderTrigger || children}
            </div>
            {fullscreenSupport ? (
                <FullScreenToolTipPortal
                    containerId={
                        containerId ? containerId : port ? 'root' : null
                    }
                >
                    {tooltipBlock()}
                </FullScreenToolTipPortal>
            ) : port ? (
                <Portal>{tooltipBlock()}</Portal>
            ) : (
                tooltipBlock()
            )}
        </>
    );
}

export default Tooltip;

export const TooltipStyles = createGlobalStyle`
    .darkThemeTooltip {
        background-color: ${({ theme }) =>
            theme.colors.ambience[24]} !important;
        color: ${({ theme }) =>
            theme?.isLightTheme && theme?.isDashboardBrandingPage
                ? theme.colors.ambience[0]
                : ''} !important;
        padding: 8px 12px !important;
        opacity: 1 !important;
        z-index: 9999999 !important;
        border: 1px solid ${({ theme }) =>
            theme.colors.ambience[18]} !important;
        border-radius: 4px !important;
        &.place-bottom {
            &:before {
                border-bottom-color: ${({ theme }) =>
                    theme.colors.ambience[18]} !important;
                border-bottom-style: solid !important;
                border-bottom-width: 7px !important;
                top: -7px !important;
            }
            &:after {
                border-bottom-color: ${({ theme }) =>
                    theme.colors.ambience[24]} !important;
                border-bottom-style: solid !important;
                border-bottom-width: 6px !important;
            }
        }
        &.place-top {
            &:before {
                border-top-color: ${({ theme }) =>
                    theme.colors.ambience[18]} !important;
                border-top-style: solid !important;
                border-top-width: 7px !important;
                bottom: -7px !important;
            }
            &:after {
                border-top-color: ${({ theme }) =>
                    theme.colors.ambience[24]} !important;
                border-top-style: solid !important;
                border-top-width: 6px !important;
            }
        }
        &.place-right {
            &:before {
                border-right-color: ${({ theme }) =>
                    theme.colors.ambience[18]} !important;
                border-right-style: solid !important;
                border-right-width: 7px !important;
            }
            &:after {
                border-right-color: ${({ theme }) =>
                    theme.colors.ambience[24]} !important;
                border-right-style: solid !important;
                border-right-width: 6px !important;
            }
        }
        &.place-left {
            &:before {
                border-left-color: ${({ theme }) =>
                    theme.colors.ambience[18]} !important;
                border-left-style: solid !important;
                border-left-width: 7px !important;
                bottom: -7px !important;
            }
            &:after {
                border-left-color: ${({ theme }) =>
                    theme.colors.ambience[24]}!important;
                border-left-style: solid !important;
                border-left-width: 6px !important;
            }
        }
        &.leadsTooltip {
            color:  ${({ theme }) =>
                theme.isLightTheme ? 'black !important' : 'white !important'};
            ::selection {
                background: ${({ theme }) => theme.colors.accentPrimary[1]};
                color: ${({ theme }) =>
                    theme?.primaryButtonTextColor ||
                    (theme.isLightTheme
                        ? theme.colors.ambience[24]
                        : theme.colors.ambience[0])};
            }
        }
        &.booth-resource-tooltip {
            padding: 7px 12px !important;
            border-radius: 8px !important;
             &:before,
            &:after {
                display: none;
            }
        }
        &.hide-arrow {
            &:before,
            &:after {
                display: none;
            }
        }
        &.communitySwitchTooltip {
            padding: 5px 9px !important;
            font-size: 12px !important;
            border-radius: 8px !important;
        }
        &.trackTooltip {
            padding: 4px 12px !important;
            font-size: 12px !important;
            color: ${({ theme }) => theme.colors.ambience[0]};
        }
        &.no-padding {
            padding: 0 !important;
        }
        &.offset-x-50.place-top{
            &:before{
                margin-left: -57px !important;
            }
            &:after{
                margin-left: -57px !important;
            }
        }
        &.boothtableTooltip {
            border: 0 !important;
            padding: 4px 12px !important;
            border-radius: 4px;
        }
    }
    .stage-tooltip {
        opacity: 1 !important;
        border-radius: 10px;
        border: 1px solid ${({ theme }) => theme.colors.ambience[17]}!important;
        background-color: ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.ambience[23]
                : theme.colors.ambience[20]} !important;
        color: ${({ theme }) => theme.colors.ambience[20]} !important;
            &.place-bottom {
                margin-top: 20px !important;
                margin-left: 8px !important;
                &:before {
                    border-bottom-color: ${({ theme }) =>
                        theme.colors.ambience[17]} !important;
                    border-bottom-style: solid !important;
                    border-bottom-width: 6px !important;
                    border-left: 6px solid transparent !important;
                    border-right: 6px solid transparent !important;
                    top: -6px !important;
                    margin-left: 0;
                    transform: translateX(-50%);
                }
                &:after {
                    border-bottom-color: ${({ theme }) =>
                        theme.isLightTheme
                            ? theme.colors.ambience[23]
                            : theme.colors.ambience[20]} !important;
                    border-bottom-style: solid !important;
                    border-bottom-width: 6px !important;
                    margin-left: 0 !important;
                    transform: translateX(-50%);
                    border-left: 6px solid transparent !important;
                    border-right: 6px solid transparent !important;
                    top: -5px !important;
                }
            }
    }

    .recording-tooltip {
        opacity: 1 !important;
        border-radius: 5px;
        border: 1px solid ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.ambience[18]
                : theme.colors.ambience[0]}!important;
            &.place-top {
                margin-top: -3px !important;
                &:before {
                    border-top-color: ${({ theme }) =>
                        theme.isLightTheme
                            ? theme.colors.ambience[18]
                            : theme.colors.ambience[0]} !important;
                }
            }
    }
    .backstageRecording {
        background-color: transparent !important;
        padding: 0 !important;
        opacity: 1 !important;
        z-index: 9999999 !important;
        margin-left: -59px !important;
        margin-top: 2px !important;
        border-top-width: 8px !important;
        &.announcement {
            margin-left: 0px !important;
            margin-top: -7px !important;
        }
        &.place-top {
            &:after {
                left: 82% !important;
                border-top-color: ${({ theme }) =>
                    theme.colors.ambience[0]} !important;
            }
            &.announcement {
                &:after {
                    left: 21% !important;
                } 
            }
        }
        .confirmation-tooltip {
            border-radius: 6px;
            background-color: ${({ theme }) => theme.colors.ambience[0]};
            max-width: 370px;
            p{
                margin-right: 24px;
                margin-bottom: 20px;
                color: ${({ theme }) => theme.colors.ambience[24]};
            }
            .btn-reject {
                background: none;
                width: 57px;
                min-width: 57px;
                border: none;
                color: ${({ theme }) =>
                    theme.isLightTheme
                        ? theme.colors.ambience[12]
                        : theme.colors.ambience[12]};
                &:hover {
                    background: none;
                    border: none;
                }
            }
        }
    }
    .attendeeTheme {
        background-color: transparent !important;
        padding: 0 !important;
        opacity: 1 !important;
        z-index: 9999999 !important;
        &.table-more-tool {
            z-index: 99 !important;
        }
        &.resource-more-options-tool {
            z-index: 9 !important;
        }
        &.place-bottom {
            &:after {
                border-bottom-color: ${({ theme }) =>
                    theme.colors.ambience[0]} !important;
                border-bottom-style: solid !important;
                border-bottom-width: 6px !important;
            }
        }
        &.place-top {
            &:after {
                border-top-color: ${({ theme }) =>
                    theme.colors.ambience[0]} !important;
                border-top-style: solid !important;
                border-top-width: 6px !important;
            }
            &.custom-place-top-left {
                > div {
                    transform: translateX(-40%);
                }
            }
        }
        &.place-right {
            &:after {
                border-right-color: ${({ theme }) =>
                    theme.colors.ambience[0]} !important;
                border-right-style: solid !important;
                border-right-width: 6px !important;
            }
        }
        &.place-left {
            &:after {
                border-left-color: ${({ theme }) =>
                    theme.colors.ambience[0]} !important;
                border-left-style: solid !important;
                border-left-width: 6px !important;
            }
        }
        &.custom-arrow-right {
            &.place-bottom {
                &:after {
                    left: auto;
                    right: 35px;
                }
            }
            &.place-top {
                &:after {
                    left: auto;
                    right: 35px; 
                }
            }
        }
        
        &.custom-arrow-curve {
            &.place-bottom,
            &.place-top,
            &.place-right,
            &.place-left {
                &:before {
                    display: none;
                }
                &:after {
                    width: 26px;
                    height: 26px;
                    border-radius: 8px;
                    background: ${({ theme }) => theme.colors.ambience[0]};
                    border: 0;
                    margin: 0;
                    left: 50%;
                    transform: translateX(-50%) rotate(45deg);
                    z-index: -1;
                }
            }
            &.place-left {
                &:after {
                    transform: translateY(-50%) rotate(45deg);
                    left: auto;
                }
            }
            &.place-right {  
                &:after {
                    transform: translateY(-50%) rotate(45deg);
                    left: -6px;
                }
            }
        }
        &.stipe-connect-tooltip.custom-arrow-curve {
            &.place-bottom {
                &:after {
                    left: 10px;
                    transform: translateX(0%) rotate(45deg);
                }
            }
        }
        &.overwrite-root-zIndex {
            z-index: 9999999 !important;
        }
        &.hide-arrow {
            &:before,
            &:after {
                display: none;
            }
        }
        @media (max-width: 991px) {
            &.show {
                display: none !important;
            }
            &.show.showOnMobile {
                display: block !important;
            }
        }
    }
    .summitTooltip {
        background-color: ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.ambience[24]
                : alpha(theme.colors.ambience[1], 0.95)} !important;
        box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.35);
        padding: 4px 8px !important;
        opacity: 1 !important;
        z-index: 9999999 !important;
        max-width: 180px !important;
        text-align: center !important;
        &.wideWidth{
            max-width: 260px !important;
        }
        &.autoWidth{
            max-width: 100% !important;
        }
        &.place-bottom {
            &:after {
                border-bottom-color: ${({ theme }) =>
                    theme.isLightTheme
                        ? theme.colors.ambience[24]
                        : alpha(theme.colors.ambience[1], 0.95)} !important;
                border-bottom-style: solid !important;
                border-bottom-width: 5px !important;
            }
        }
        &.place-top {
            &:after {
                border-top-color: ${({ theme }) =>
                    alpha(
                        theme.isLightTheme
                            ? theme.colors.ambience[23]
                            : theme.colors.ambience[1],
                        0.95
                    )} !important;
                border-top-style: solid !important;
                border-top-width: 5px !important;
            }
        }
        &.place-right {
            &:after {
                border-right-color: ${({ theme }) =>
                    theme.isLightTheme
                        ? theme.colors.ambience[24]
                        : alpha(theme.colors.ambience[1], 0.95)} !important;
                border-right-style: solid !important;
                border-right-width: 5px !important;
            }
        }
        &.place-left {
            &:after {
                border-left-color: ${({ theme }) =>
                    theme.isLightTheme
                        ? theme.colors.ambience[24]
                        : alpha(theme.colors.ambience[1], 0.95)} !important;
                border-left-style: solid !important;
                border-left-width: 5px !important;
            }
        }
        &.customize {
            padding: 10px !important;
            border-radius: 8px !important;
            max-width: 261px !important;
            &.morePadding {
                padding: 16px !important;
            }
        }
        
        p{
            color: ${({ theme }) =>
                theme.isLightTheme
                    ? theme.colors.ambience[5]
                    : theme.colors.ambience[16]};
        }
    }
    .inline-tooltip{
        display: inline;
    }
    .breakout-learn-more {
        background-color: ${({ theme }) => theme.colors.ambience[0]} !important;
        max-width: 370px !important;
        padding: 16px !important;
        z-index: 9999999 !important;
        border-radius: 8px !important;
        margin-left: 100px !important;
        opacity: 1 !important;
        &.place-bottom {
            margin-top: 20px !important;
            &:after {
                border-bottom-color: ${({ theme }) =>
                    alpha(theme.colors.ambience[0], 1)} !important;
                border-bottom-style: solid !important;
                border-bottom-width: 5px !important;
                transform: rotate(45deg);
                content: '';
                height: 26px;
                width: 26px;
                border-radius: 8px;
                background-color: ${({ theme }) =>
                    theme.colors.ambience[0]} !important;
                top: -9px !important;
                left: 15% !important;
            }
        }
    }
    .billing-plan-tooltip {
        background-color: ${({ theme }) =>
            alpha(theme.colors.ambience[0], 1)} !important;
        padding: 16px !important;
        opacity: 1 !important;
        z-index: 9999999 !important;
        text-align: center !important;
        border-radius: 8px !important;
        max-width: 288px !important;
        &.place-bottom {
            margin-top: 15px !important;
            &:after {
                border-bottom-color: ${({ theme }) =>
                    alpha(theme.colors.ambience[0], 1)} !important;
                border-bottom-style: solid !important;
                border-bottom-width: 5px !important;
                transform: rotate(45deg);
                content: '';
                height: 26px;
                width: 26px;
                border-radius: 8px;
                background-color: ${({ theme }) =>
                    alpha(theme.colors.ambience[0], 1)} !important;
                top: -9px !important;
                left: 80% !important;
            }
        }
        &.place-top {
            margin-bottom: 15px !important;
            &:after {
                border-top-color: ${({ theme }) =>
                    alpha(theme.colors.ambience[0], 1)} !important;
                border-top-style: solid !important;
                border-top-width: 5px !important;
                transform: rotate(45deg);
                content: '';
                height: 26px;
                width: 26px;
                border-radius: 8px;
                background-color: ${({ theme }) =>
                    alpha(theme.colors.ambience[0], 1)} !important;
                bottom: -9px !important;
                left: 80% !important;
            }
        }
        &.place-right {
            margin-left: 105px !important;
            padding: 18px !important;
            &:after {
                border-right-color: ${({ theme }) =>
                    alpha(theme.colors.ambience[1], 0.95)} !important;
                border-right-style: solid !important;
                border-right-width: 5px !important;
                transform: rotate(45deg);
                content: '';
                height: 26px;
                width: 26px;
                border-radius: 8px;
                background-color: ${({ theme }) =>
                    alpha(theme.colors.ambience[0], 1)} !important;
                left: -9px !important;
                top: 40% !important;
            }
        }
        &.place-left {
            margin-left: 75px !important;
            padding: 18px !important;
            &:after {
                border-left-color: ${({ theme }) =>
                    alpha(theme.colors.ambience[1], 0.95)} !important;
                border-left-style: solid !important;
                border-left-width: 5px !important;
                transform: rotate(45deg);
                content: '';
                height: 26px;
                width: 26px;
                border-radius: 8px;
                background-color: ${({ theme }) =>
                    alpha(theme.colors.ambience[0], 1)} !important;
                right: -9px !important;
                top: 40% !important;
            }
        }
    }
    .widget__chat__tooltip {
        max-width: 300px;
    }
    .remind-me-tooltip {
        background: transparent !important;
        &:after,&:before {
            display: none;
        }
    }
    .deletePopupTooltip {
        background-color: ${({ theme }) =>
            theme.colors.ambience[24]} !important;
        text-align: left !important;
        color: ${({ theme }) => theme.colors.ambience[0]}!important;
        max-width: 260px;
        opacity: 1 !important;
        &.place-bottom {
            &:after {
                border:none !important;
            }
        }
        &.place-top {
            &:after {
                border:none !important;
            }
            
        }
        &.place-right {
            &:after {
                border:none !important;
            }
        }
        &.place-left {
            
            &:after {
                border:none !important;
            }
        }
    }

    .smallInfoTooltip {
        background-color: ${({ theme }) =>
            theme.colors.ambience[24]} !important;
        padding: 4px 12px !important;
        opacity: 1 !important;
        border: 1px solid ${({ theme }) =>
            theme.colors.ambience[18]} !important;
        border-radius: 4px !important;
        margin-top: -5px !important;
        &.place-top {
            &:after, &:before {
                display :none !important;
            }   
        }
    }

    .stageAudioMuteUnMute {
        opacity: 1 !important;
        background-color: ${({ theme }) =>
            theme.colors.fill.default1} !important;
        border: 1px solid ${({ theme }) =>
            theme.colors.border.default2} !important;
        &.place-bottom {
            &:before {
                border-bottom-color: ${({ theme }) =>
                    theme.colors.border.default2} !important;
                border-bottom-style: solid !important;
            }
            &:after {
                border-bottom-color: ${({ theme }) =>
                    theme.colors.fill.default1} !important;
                border-bottom-style: solid !important;
            }
        }
        &.place-top {    
            margin-left: 130px !important;
            margin-top: -6px !important;;
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
            &.right {
                margin-left: 114px !important;
            }
            &.disabled {
                margin-left: 150px !important; 
            }
        }
    }

    .checkBoxTooltip {
        ${({ theme }) => theme.colors.ambience[24]} !important;
        display: flex !important;
    }

    .hideArrow {
        &:after, &:before {
            display :none !important;
        }
    }
    .alertDelete {
        padding:4px 6px !important;
        &.place-top {
            margin-top: -3px !important;
        }
    }
    .eventLandingPage{
        background-color: ${({ theme }) =>
            theme.isLightTheme
                ? `${theme.colors.ambience[0]} !important`
                : `${theme.colors.ambience[24]} !important`}
    }
    .uploadResource{
        width:200px !important;
        padding:0px !important
    }
`;
