import Box from 'atoms/Box';
import Btn from 'atoms/Button/btn';
import Divider from 'atoms/Divider/Divider';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import Icon from 'foundations/icon';
import { Text } from 'foundations/themeV2/text';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import useTheme from 'hooks/useTheme';
import keys from 'locale/keys';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DEFAULT_NAV_LABELS } from '../../common/constants';

const ErrorText = ({ isLightTheme, isValid, label, isEmpty }) => (
    <Box display={'flex'} alignItems={'center'} mt={'5px'}>
        <Icon
            icon={isValid || isEmpty ? 'checkmark_circle' : 'dismiss_circle'}
            width={16}
            height={16}
            fill={
                isEmpty
                    ? 'text.default.secondary'
                    : isValid
                    ? 'sem.success'
                    : 'sem.error'
            }
        />
        <Text
            variant='caption'
            color={
                isEmpty ? 'text.default.secondary' : 'text.default.secondary'
            }
            ml={'9px'}
        >
            {label}
        </Text>
    </Box>
);

const getErrorState = (data, inactiveLabels) => {
    if (!data?.value || data?.value.trim() === '') return 'required';

    // Case insensitive duplicate string check
    const duplicate = (inactiveLabels || []).find((inactiveLabel) => {
        const label = (inactiveLabel || '').trim().toLowerCase();
        const value = (data?.value || '').trim().toLowerCase();

        return label !== '' && value !== '' && label === value;
    });
    if (duplicate) return 'duplicate';

    if ((data?.value || '').length > 15) return 'maxLength';

    return null;
};

const NavLabel = ({
    data,
    isLightTheme,
    handleChangeLabel,
    activeLabelKey,
    setActiveLabelKey,
    inactiveLabels,
    t,
}) => {
    const isActive = data.key === activeLabelKey;
    const errorState = isActive && getErrorState(data, inactiveLabels);
    const disabled = errorState && errorState !== 'required';

    const handleSubmit = () => {
        setActiveLabelKey &&
            setActiveLabelKey(data.key === activeLabelKey ? null : data.key);
    };

    const handleKeyUp = (e) => {
        if (e.keyCode === 13 && isActive && !disabled) {
            handleSubmit();
        }
    };

    const handleBlur = () => {
        if (isActive && !disabled) {
            handleSubmit();
        }
    };

    return (
        <>
            <LabelWrapper>
                <Box>
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <LabelInput
                            value={data?.value}
                            disabled={!isActive}
                            onChange={(e) => handleChangeLabel(e, data.key)}
                            onKeyUp={handleKeyUp}
                            onBlur={handleBlur}
                            id={`nav_label_inp_${data.key}`}
                            autoComplete='off'
                        />
                        <Box position='relative'>
                            {isActive && <Overlay />}
                            <Btn
                                onClick={handleSubmit}
                                disabled={disabled || isActive}
                                variant='link'
                            >
                                <StyledSvgSprite
                                    icon='text_edit_style'
                                    width={16}
                                    height={16}
                                    fill={
                                        isActive
                                            ? 'text.default.secondary'
                                            : 'text.default.primary'
                                    }
                                    mr='4px'
                                />
                                {t(keys.RHS_CUSTOMIZE_RENAME_CTA)}
                            </Btn>
                        </Box>
                    </Box>

                    {isActive && (
                        <>
                            <Text
                                variant='caption'
                                color='text.default.secondary'
                                mt='11px'
                            >
                                {t(keys.RHS_CUSTOMIZE_RENAME_PRESS_ENTER)}
                            </Text>
                            <ValidationInfoBox>
                                <Box display={'flex'} alignItems={'center'}>
                                    <StyledSvgSprite
                                        icon='info_circle'
                                        width={16}
                                        height={16}
                                        fill='text.default.primary'
                                    />
                                    <Text
                                        variant='caption'
                                        color='text.default.primary'
                                        ml='9px'
                                    >
                                        {t(keys.RHS_CUSTOMIZE_RENAME_SIMPLE)}
                                    </Text>
                                </Box>

                                {(!data?.value ||
                                    data?.value.trim() === '') && (
                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        mt={'5px'}
                                    >
                                        <Icon
                                            icon='dismiss_circle'
                                            width={16}
                                            height={16}
                                            fill='semantic.3'
                                        />
                                        <Text
                                            variant='caption'
                                            color='text.default.primary'
                                            ml='9px'
                                        >
                                            {t(
                                                keys.RHS_CUSTOMIZE_RENAME_REQUIRED_ERROR
                                            )}
                                        </Text>
                                    </Box>
                                )}
                                <ErrorText
                                    isLightTheme={isLightTheme}
                                    label={t(
                                        keys.RHS_CUSTOMIZE_RENAME_UNIQUE_ERROR
                                    )}
                                    isValid={errorState !== 'duplicate'}
                                    isEmpty={!data?.value || data?.value === ''}
                                />
                                <ErrorText
                                    isLightTheme={isLightTheme}
                                    label={t(
                                        keys.RHS_CUSTOMIZE_RENAME_MAX_LENGTH_ERROR
                                    )}
                                    isValid={errorState !== 'maxLength'}
                                    isEmpty={!data?.value || data?.value === ''}
                                />
                            </ValidationInfoBox>
                        </>
                    )}
                </Box>
            </LabelWrapper>
            <Divider />
        </>
    );
};

const stateToLocalCustomText = (customText, t) => {
    const navItems = {};

    DEFAULT_NAV_LABELS.forEach((k) => {
        let value = null;

        const cText = (customText || []).find(
            (c) => (c?.type || '')?.toLowerCase() === k?.toLowerCase()
        );
        if (cText) {
            //TODO: Make it compatible to more languages
            const enItem = (cText?.values || []).find(
                (obj) => obj?.lan === 'en'
            );
            if (enItem && enItem.value) {
                value = enItem.value;
            }
        }

        // If value is not present then set default value
        if (!value || value.trim() === '') {
            value = t(keys[k.toUpperCase()]);
        }

        navItems[k.toLowerCase()] = value;
    });

    return navItems;
};

const localToStateCustomText = (navItems) => {
    const customText = [];
    Object.keys(navItems).forEach((k) => {
        customText.push({
            type: k,
            values: [{ lan: 'en', value: navItems[k] }],
        });
    });

    return customText;
};

function CustomizeNavigationLabels({ onChange, customText, setDisableSave }) {
    const { t } = useCustomTranslation();
    const { isLightTheme } = useTheme();
    const [activeLabelKey, setActiveLabelKey] = useState(null);
    const isResourceManagementEnabled = useSelector(
        (state) => state.lounge?.airmeet?.is_resource_management_enabled
    );

    const [navItems, setNavItems] = useState(
        stateToLocalCustomText(customText, t)
    );
    const [inactiveLabels, setInactiveLabels] = useState([]);
    const handleChangeLabel = (e, key) => {
        const newNavLabels = { ...navItems };
        newNavLabels[key] = e.target.value;
        setNavItems(newNavLabels);
    };

    useEffect(() => {
        if (
            isResourceManagementEnabled &&
            !DEFAULT_NAV_LABELS.includes('RESOURCES')
        )
            DEFAULT_NAV_LABELS.push('RESOURCES');
    }, [isResourceManagementEnabled]);

    useEffect(() => {
        if (activeLabelKey) {
            const labels = [];
            Object.keys(navItems).forEach((key) => {
                if (key !== activeLabelKey) {
                    labels.push(navItems[key]);
                }
            });
            setInactiveLabels(labels);
        } else {
            setInactiveLabels([]);
        }
    }, [activeLabelKey, navItems]);

    useEffect(() => {
        setDisableSave && setDisableSave(!!activeLabelKey);
    }, [activeLabelKey, setDisableSave]);

    const handleSetActiveLabelKey = (key) => {
        setActiveLabelKey(key);
        if (key) {
            setTimeout(() => {
                document.getElementById(`nav_label_inp_${key}`).focus();
            }, 0);
        }

        if (!key) {
            const newNavItems = { ...navItems };

            let updated = false;
            // Set label to default if empty
            Object.keys(newNavItems).forEach((k) => {
                if (!newNavItems[k] || newNavItems[k].trim() === '') {
                    newNavItems[k] = t(keys[k]);
                    updated = true;
                } else {
                    newNavItems[k] = newNavItems[k].trim();
                }
            });
            if (updated) {
                setNavItems(newNavItems);
            }

            // Sync data to parent
            onChange('custom_text', localToStateCustomText(newNavItems));
        }
    };

    return (
        <WidgetWrapper>
            <Text variant='body1' color='text.default.primary' mb='4px'>
                {t(keys.RHS_CUSTOMIZE_RENAME_TITLE)}
            </Text>
            <Text variant='caption' color='text.default.secondary'>
                {t(keys.RHS_CUSTOMIZE_RENAME_SUBTITLE)}
            </Text>
            <Wrapper>
                {DEFAULT_NAV_LABELS.map((key) => {
                    const localKey = (key || '').toLowerCase();
                    return (
                        <NavLabel
                            data={{ key: localKey, value: navItems[localKey] }}
                            key={localKey}
                            isLightTheme={isLightTheme}
                            inactiveLabels={inactiveLabels}
                            activeLabelKey={activeLabelKey}
                            setActiveLabelKey={handleSetActiveLabelKey}
                            handleChangeLabel={handleChangeLabel}
                            t={t}
                        />
                    );
                })}
            </Wrapper>
        </WidgetWrapper>
    );
}

export default CustomizeNavigationLabels;

const WidgetWrapper = styled.div`
    margin-top: 24px;
    margin-bottom: 56px;
`;

const Wrapper = styled.div`
    margin-top: 12px;
`;

const LabelInput = styled.input`
    padding: 0;
    border: 0;
    background: transparent;
    outline: 0 !important;
    font-size: 14px;
    line-height: 20px;
    font-family: ${({ theme }) => theme.fonts.regular};
    color: ${({ theme }) => theme.colors.text.default.secondary};
    flex: 1;
`;

const LabelWrapper = styled.div`
    padding: 16px 0;
    &:last-child {
        border-bottom: 0;
    }
`;

const ValidationInfoBox = styled.div`
    margin-top: 18px;
`;

// To avoid onClick on onBlur event propagation
const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
`;
