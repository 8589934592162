import { API_SERVICE } from 'utils/apiService';
import { CALL_API } from '../api';

export const EMAIL_LOGIN_REQUEST = 'EMAIL_LOGIN_REQUEST';
export const EMAIL_LOGIN_SUCCESS = 'EMAIL_LOGIN_SUCCESS';
export const RESET_STORE = 'RESET_STORE';
export const USER_QUERY_REQUEST = 'USER_QUERY_REQUEST';
export const USER_OTP_VERIFY_REQUEST = 'USER_OTP_VERIFY_REQUEST';
export const USER_OTP_VERIFY_RESPONSE = 'USER_OTP_VERIFY_RESPONSE';
export const USER_QUERY_RESPONSE = 'USER_QUERY_RESPONSE';
export const USER_QUERY_ELIGIBILITY_REQUEST = 'USER_QUERY_ELIGIBILITY_REQUEST';
export const USER_QUERY_ELIGIBILITY_RESPONSE =
    'USER_QUERY_ELIGIBILITY_RESPONSE';
export const SSO_LOGIN_REQUEST = 'SSO_LOGIN_REQUEST';

export function emailLogin({
    email,
    password,
    secure = false,
    signupIntent,
    ...props
}) {
    return {
        [CALL_API]: {
            types: [EMAIL_LOGIN_REQUEST, EMAIL_LOGIN_SUCCESS],
            endpoint: secure ? '/user/secure/login' : '/user/login',
            body: {
                email,
                password,
                signupIntent,
                ...props,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}

export function resetStore() {
    return {
        type: RESET_STORE,
    };
}

export function queryUser({ email, signupIntent, ...props }) {
    return {
        [CALL_API]: {
            types: [USER_QUERY_REQUEST, USER_QUERY_RESPONSE],
            endpoint: '/user/query',
            body: {
                email,
                signupIntent,
                ...props,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}

export function queryUserEligibility({ email, airmeetId }) {
    return {
        [CALL_API]: {
            types: [
                USER_QUERY_ELIGIBILITY_REQUEST,
                USER_QUERY_ELIGIBILITY_RESPONSE,
            ],
            endpoint: '/airmeet/order/eligibility',
            body: {
                email,
                airmeetId,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}

export function otpVerify({ email, otp }) {
    return {
        [CALL_API]: {
            types: [USER_OTP_VERIFY_REQUEST, USER_OTP_VERIFY_RESPONSE],
            endpoint: '/user/otp/verify',
            body: {
                email,
                otp,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}

export function ssoLogin({ domain, signupIntent, ...props }) {
    return {
        [CALL_API]: {
            types: [SSO_LOGIN_REQUEST],
            endpoint: '/sso/login',
            body: {
                domain,
                signupIntent,
                ...props,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}
