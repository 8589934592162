import { EVENTS_PAGE_TAB } from 'components/community/constants';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    EMAIL_SCREEN,
    EXPIRED_TOKEN_ID,
    INVALID_TOKEN_ID,
    INVITE_FLOW_SOURCE,
    LOGIN_FLOW,
    OTP_SCREEN,
    SWITCH_ACCOUNT_ID,
    UNIFIED_FLOW,
} from '../../components/auth/utils';
import {
    MODAL_TYPE_CREATE_COMMUNITY,
    MODAL_TYPE_STATUS_MODAL,
} from '../../components/modals/constants';
import config from '../../config';
import history from '../../history';
import { logoutUser } from '../../store/actions/auth';
import { getCommunity, getMyCommunities } from '../../store/actions/community';
import {
    updateInvitation,
    validateInvitation,
} from '../../store/actions/community/member';
import { queryUser } from '../../store/actions/emailLogin';
import {
    closeAppModal,
    setInvitedUserState,
    setUserInviteScreenPageProps,
    showAppModal,
    showFixedModal,
} from '../../store/actions/layout';
import firebaseLogger from '../../utils/firebaseLogger';
import useAuthActionHandler from '../useAuthActionHandler';

const INVALID_TOKEN_MESSAGE = 'Your invite is invalid';
const TEAM_MEMBER_LIMIT_BREACHED_MESSAGE =
    'Limits breached. Please upgrade plan';
export const INVITATION_STATUS = {
    exipred: 'EXPIRED',
    invited: 'INVITED',
    accepted: 'ACCEPTED',
};

const useInviteTeamMemberHandler = () => {
    const dispatch = useDispatch();
    const { isLoggedIn, tryLogin, trySignUp, user } = useAuthActionHandler();
    const { myCommunity, current } = useSelector((state) => state.Community);
    const {
        isNewManager,
        continueSignUpFlow,
        userEmail,
        userCommunityId,
        invitationToken,
        providerMethod,
        authSuccess,
        modalClose,
        invitationStatus,
        userPlanIntent,
    } = useSelector((state) => state.layout.invitedUserState);

    useEffect(() => {
        if (isLoggedIn && invitationToken && userEmail && userCommunityId) {
            (async () => {
                if (user?.email?.toLowerCase() === userEmail.toLowerCase()) {
                    if (
                        invitationStatus === INVITATION_STATUS['accepted'] ||
                        !authSuccess
                    )
                        return;
                    const response = await dispatch(
                        updateInvitation({
                            token: invitationToken,
                            status: INVITATION_STATUS['accepted'],
                        })
                    );
                    if (response.error) {
                        firebaseLogger.invitationAcceptanceError(
                            userCommunityId,
                            userEmail,
                            response.payload?.json?.message ||
                                'Something went wrong'
                        );
                        setInvitedUserState({
                            invitationToken: null,
                        });
                        return;
                    }
                    setInvitedUserState({
                        invitationStatus: INVITATION_STATUS['accepted'],
                        invitationToken: null,
                    });
                    onAcceptingInvitation();
                } else {
                    dispatch(
                        setUserInviteScreenPageProps({
                            mainText: !providerMethod
                                ? 'Switch Accounts'
                                : `${
                                      config.providers[providerMethod]
                                          ?.displayName || 'Email'
                                  } account not matching invite`,
                            subText: !providerMethod
                                ? 'To accept the invite, login to a different account.'
                                : `The invite was sent to <u>${userEmail}</u>, please switch to this account to accept the invite and join this community.`,
                            middleText: !providerMethod
                                ? 'Currently logged in with email:'
                                : '',
                            middleTextEmail: !providerMethod
                                ? `${user.email}`
                                : '',
                            bottomText: !providerMethod
                                ? `The invite was sent to ${userEmail}`
                                : '',
                            buttonText: !providerMethod
                                ? 'Switch account'
                                : 'Switch to Invited Account',
                            onClose: resetInvitation,
                            onButtonClick: handleSwitchAccount,
                            buttonSecondaryEvent: handleContinueSignup,
                            buttonSecondaryText: !!providerMethod
                                ? 'Continue anyway'
                                : '',

                            condition: 'SWITCH',
                        })
                    );

                    history.push({
                        pathname: `/invite/${SWITCH_ACCOUNT_ID}`,
                        state: { id: SWITCH_ACCOUNT_ID },
                    });
                }
            })();
        }
    }, [
        isLoggedIn,
        dispatch,
        invitationToken,
        userEmail,
        userCommunityId,
        providerMethod,
        authSuccess,
    ]);

    useEffect(() => {
        if (!isEmpty(myCommunity)) {
            const { cm, em } = myCommunity;
            if ((cm && cm.length > 0) || (em && em.length > 0)) {
                // log('Is already a manager');
                dispatch(setInvitedUserState({ isNewManager: false }));
            } else {
                // log('Is New Manager');
                dispatch(setInvitedUserState({ isNewManager: true }));
            }
        }
    }, [myCommunity]);

    useEffect(() => {
        if (
            authSuccess &&
            user?.email?.toLowerCase() === userEmail?.toLowerCase()
        ) {
            resetInvitation();
        }
    }, [authSuccess, user, userEmail]);
    const selectedCommunity =
        current && current.communityId
            ? current
            : user && user.community
            ? user.community
            : null;

    const navigateToDashboard = (dashboardCommunityId) => {
        if (dashboardCommunityId) {
            history.push(
                `/community-manager/${dashboardCommunityId}/${EVENTS_PAGE_TAB}`
            );
        } else if (user && user.community && selectedCommunity) {
            history.push(
                `/community-manager/${selectedCommunity.communityId}/${EVENTS_PAGE_TAB}`
            );
        }
    };

    useEffect(() => {
        if (continueSignUpFlow) {
            if (isNewManager) {
                dispatch(showAppModal(MODAL_TYPE_CREATE_COMMUNITY));
            } else {
                navigateToDashboard();
            }
        }
    }, [continueSignUpFlow, isNewManager]);

    useEffect(() => {
        if (!authSuccess && modalClose) {
            resetInvitation();
        }
    }, [modalClose, authSuccess]);

    const invalidTokenHandler = (invitationStatus = '') => {
        const isInviteUsed = invitationStatus === INVITATION_STATUS['accepted'];

        dispatch(
            setUserInviteScreenPageProps({
                imagePath: 'images/bad-luck.svg',
                mainText: isInviteUsed
                    ? 'Invitation link no longer valid!'
                    : 'Community not found',
                subText: isInviteUsed
                    ? 'The invitation link has either already been used, or has expired. Please reach out to your community manager for any further queries.'
                    : 'Contact a member of this community to check if the invite link is right.',
                imageAlt: 'Community not found',
                condition: 'NOTIFY',
                cmNotFound: true,
                subTextMaxWidth: 'auto',
            })
        );
        history.push({
            pathname: `/invite/${INVALID_TOKEN_ID}`,
            state: { id: INVALID_TOKEN_ID },
        });
    };

    const expiredTokenHandler = (communityManagerName) => {
        dispatch(
            setUserInviteScreenPageProps({
                imagePath: 'images/watch.png',
                mainText: 'Your invitation has expired',
                subText: `Please reach out to ${communityManagerName} or another member of this community in order to get a fresh invite`,
                imageAlt: 'Raise Hands',
                condition: 'NOTIFY',
                imageWrapBg: 'transparent',
                imageWidth: '200px',
                imageHeight: '200px',
                imageWrapHeight: 'auto',
            })
        );
        history.push({
            pathname: `/invite/${EXPIRED_TOKEN_ID}`,
            state: { id: EXPIRED_TOKEN_ID },
        });
    };
    const accessNotFoundTokenHandler = () => {
        dispatch(
            setUserInviteScreenPageProps({
                imagePath: 'images/thumb-down.svg',
                mainText: 'Ooops. It looks like the invite is invalid.',
                subText:
                    'Looks like you do not have access to this community. Please contact community manager if you are wrongly denied access.',
                imageAlt: 'Dead End',
                subtextVariant: 'body2',
                condition: 'NOTIFY',
                subTextMaxWidth: '472px',
                imageWrapBg: 'transparent',
                imageHeight: '200px',
                imageWidth: '150px',
                imageWrapWidth: 'auto',
                imageWrapHeight: 'auto',
            })
        );
    };
    const getUserDetails = async (token) => {
        const userInfoResponse = await dispatch(validateInvitation({ token }));
        if (
            userInfoResponse?.error ||
            userInfoResponse?.status === INVITATION_STATUS['accepted']
        ) {
            if (
                [
                    INVALID_TOKEN_MESSAGE,
                    TEAM_MEMBER_LIMIT_BREACHED_MESSAGE,
                ].includes(userInfoResponse?.payload?.json?.message)
            ) {
                accessNotFoundTokenHandler();
            } else {
                invalidTokenHandler(userInfoResponse?.status);
            }
            return {};
        }

        const {
            email,
            communityId,
            cmName,
            status,
            planIntent,
            inviteeFirstName,
            inviteeLastName,
        } = userInfoResponse;
        await dispatch(setInvitedUserState({ invitationStatus: status }));
        if (status === INVITATION_STATUS['exipred']) {
            expiredTokenHandler(cmName);
            return {};
        }
        await dispatch(
            setInvitedUserState({
                userEmail: email,
                userCommunityId: communityId,
                userPlanIntent: planIntent,
            })
        );
        return {
            email,
            communityId,
            planIntent,
            inviteeFirstName,
            inviteeLastName,
        };
    };

    const handleProvider = (lastOAuth, email, hasPassword) => {
        let providerName = '',
            providerMessage = '',
            providerDisplayMessage = '';
        if (
            lastOAuth &&
            Object.keys(config.providers).indexOf(lastOAuth) !== -1
        ) {
            providerName = config.providers[lastOAuth].key;
            providerDisplayMessage = config.providers[lastOAuth].displayName;
            providerMessage = `Welcome back! Looks like you’ve signed up with <u>${email}</u> using your ${providerName} account before.`;
        } else {
            providerName = 'email';
            providerDisplayMessage = 'Email';
            providerMessage = `Looks you have signed up with ${email} before. Continue logging in with email or a social account associated with the same email.`;
        }
        return { providerMessage, providerName, providerDisplayMessage };
    };

    const handleSwitchAccount = async () => {
        dispatch(closeAppModal(MODAL_TYPE_STATUS_MODAL));
        await dispatch(logoutUser());
        handleInvitationFlow(userEmail, false, userPlanIntent);
    };

    const onAcceptingInvitation = async () => {
        const communityInfo = await dispatch(getCommunity(userCommunityId));
        const {
            payload: { name, manager_id: managerId },
        } = communityInfo || { payload: {} };
        resetInvitation();
        await dispatch(getMyCommunities());
        navigateToDashboard(userCommunityId);
        if (invitationStatus !== INVITATION_STATUS['accepted']) {
            firebaseLogger.invitationAccepted(
                userCommunityId,
                managerId,
                userEmail
            );
            setTimeout(() => {
                dispatch(
                    showFixedModal(MODAL_TYPE_STATUS_MODAL, {
                        imagePath: 'images/celebrate.svg',
                        mainText: `You have joined ${
                            name || 'the community'
                        } successfully`,
                        subText:
                            'You can now create, edit and host virtual events together with your team. Have fun!',
                        imageAlt: 'Celebration',
                        // onClose: onAcceptingInvitation,
                        condition: 'NOTIFY',
                    })
                );
            }, 1000);
        }
    };

    const handleContinueSignup = async () => {
        dispatch(closeAppModal(MODAL_TYPE_STATUS_MODAL));
        resetInvitation();
        await dispatch(getMyCommunities());
        await dispatch(setInvitedUserState({ continueSignUpFlow: true }));
    };

    const resetInvitation = async () => {
        await dispatch(setInvitedUserState({ invitationToken: null }));
    };

    const closeAuthModal = async () => {
        await dispatch(setInvitedUserState({ modalClose: true }));
    };
    const handleInvitationFlow = async (
        email,
        userLoggedIn,
        planIntent = '',
        inviteeFirstName,
        inviteeLastName
    ) => {
        const currentUser = await dispatch(queryUser({ email }));

        const {
            payload: { exists, lastOAuth, hasPassword },
        } = currentUser || { payload: {} };
        if (!exists && !userLoggedIn) {
            trySignUp({
                onSuccess: async (token, method, source) => {
                    // firebaseLogger.signedUp(method, source);
                    await dispatch(
                        setInvitedUserState({
                            providerMethod: method,
                            authSuccess: true,
                        })
                    );
                },
                onClose: closeAuthModal,
                source: INVITE_FLOW_SOURCE,
                email: `${email}`,
                firstName: inviteeFirstName,
                lastName: inviteeLastName,
                emailDisabled: true,
                search: planIntent ? `plan_intent=${planIntent}` : '',
                subHeadingProps: `Signup with ${email} or a social account associated with the same email to join this community.`,
            });
        } else if (exists && !userLoggedIn) {
            const {
                providerName,
                providerMessage,
                providerDisplayMessage,
            } = handleProvider(lastOAuth, email, hasPassword);
            tryLogin({
                onSuccess: async (token, method, source) => {
                    // firebaseLogger.signedUp(method, source);
                    await dispatch(setInvitedUserState({ authSuccess: true }));
                },
                onClose: closeAuthModal,
                source: INVITE_FLOW_SOURCE,
                subHeadingProps:
                    lastOAuth && !hasPassword
                        ? `Continue logging in with your ${providerDisplayMessage} Account.`
                        : providerMessage,
                lastAuthProviderProps: providerName,
                authStepProps:
                    lastOAuth && !hasPassword ? OTP_SCREEN : EMAIL_SCREEN,
                extraInfoProps: lastOAuth && !hasPassword ? email : '',
                email: email,
                emailDisabled: true,
                action: lastOAuth && !hasPassword ? LOGIN_FLOW : UNIFIED_FLOW,
                isOtherSourceLogin: lastOAuth && !hasPassword ? true : false,
                isSocialAuthUser: !hasPassword,
                firstName: inviteeFirstName,
                lastName: inviteeLastName,
            });
        } else if (exists && userLoggedIn) {
            await dispatch(setInvitedUserState({ authSuccess: true }));
        }
    };

    const onLanding = async ({ userInviteToken = null }) => {
        // TODO , What is this fire price Event handling
        if (!userInviteToken) return;
        await dispatch(
            setInvitedUserState({ invitationToken: userInviteToken })
        );
        const {
            email,
            communityId,
            planIntent,
            inviteeFirstName,
            inviteeLastName,
        } = await getUserDetails(userInviteToken);
        if (!email || !communityId) {
            await dispatch(setInvitedUserState({ invitationToken: null }));
            return;
        }

        handleInvitationFlow(
            email,
            !!isLoggedIn,
            planIntent,
            inviteeFirstName,
            inviteeLastName
        );
    };
    return { onLanding };
};

export default useInviteTeamMemberHandler;
