import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';

const useChatReactionMultipleEmojiFeatureFlag = (): {
    isMultipleEmoji: boolean;
} => {
    const {
        airmeet: {
            data: {
                currentAirmeet: {
                    is_multiple_emoji_enabled: isMultipleEmoji = false,
                } = {},
            } = {},
        } = {},
    } = useLiveAirmeetContext() || {};

    return {
        isMultipleEmoji,
    };
};

export default useChatReactionMultipleEmojiFeatureFlag;
