import { FETCH_ATTENDEE_ADDONS_RESPONSE } from 'store/actions/cmDashboard/addons';

const initialState = {
    attendeeAddons: {},
};

const addons = (state = initialState, action) => {
    const { type, payload, error } = action;

    switch (type) {
        case FETCH_ATTENDEE_ADDONS_RESPONSE:
            if (error) {
                return {
                    ...state,
                    attendeeAddons: {
                        ...state.attendeeAddons,
                        error: true,
                    },
                };
            }

            const {
                attendee_addon_left,
                attendee_addon_limit,
                attendee_addon_used,
                airmeets,
            } = payload || {};

            return {
                ...state,
                attendeeAddons: {
                    ...state.attendeeAddons,
                    error: false,
                    remaining: attendee_addon_left,
                    used: attendee_addon_used,
                    limit: attendee_addon_limit,
                    airmeets,
                },
            };
        default:
            return state;
    }
};

export default addons;
