import useDeviceInfo from 'hooks/useDeviceInfo';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { useEffect, useMemo, useState } from 'react';

const useActiveBreakoutId = () => {
    const [activeBreakoutId, setActiveBreakoutId] = useState();
    const { isMobile } = useDeviceInfo();
    const {
        firebaseClient,
        airmeet: {
            airmeetId,
            data: { currentSession },
        },
    } = useLiveAirmeetContext();

    const sessionId = currentSession && currentSession.sessionid;
    const activeBreakoutKey = useMemo(() => {
        if (!airmeetId || !sessionId) return null;
        return `${airmeetId}/meta-data/sessions/${sessionId}/genral/activeBreakoutId`;
    }, [airmeetId, sessionId]);

    useEffect(() => {
        const updateActiveBreakoutId = ({ value }) => {
            setActiveBreakoutId(value);
        };

        if (firebaseClient && activeBreakoutKey && isMobile) {
            firebaseClient.getDataSync(
                activeBreakoutKey,
                updateActiveBreakoutId
            );

            return () => {
                firebaseClient.clearDataSync(
                    activeBreakoutKey,
                    updateActiveBreakoutId
                );
            };
        }
    }, [firebaseClient, activeBreakoutKey, isMobile]);

    return activeBreakoutId;
};

export default useActiveBreakoutId;
