export const LOG_LEVEL = {
    VERBOSE: 1 << 0,
    DEBUG: 1 << 1,
    INFO: 1 << 2,
    WARN: 1 << 3,
    ERROR: 1 << 4,
    FATAL: 1 << 5,
    LOG_EVENT: 1 << 6,
    RAW_JSON: 1 << 7,
};

export const LOG_LEVEL_PREFIX = {
    [LOG_LEVEL.VERBOSE]: 'VERBOSE',
    [LOG_LEVEL.DEBUG]: 'DEBUG',
    [LOG_LEVEL.INFO]: 'INFO',
    [LOG_LEVEL.WARN]: 'WARN',
    [LOG_LEVEL.ERROR]: 'ERROR',
    [LOG_LEVEL.FATAL]: 'FATAL',
    [LOG_LEVEL.LOG_EVENT]: 'LOG_EVENT',
    [LOG_LEVEL.RAW_JSON]: 'RAW_JSON',
};

export const LOG_METHODS = {
    [LOG_LEVEL.VERBOSE]: 'log',
    [LOG_LEVEL.DEBUG]: 'log',
    [LOG_LEVEL.INFO]: 'info',
    [LOG_LEVEL.WARN]: 'warn',
    [LOG_LEVEL.ERROR]: 'error',
    [LOG_LEVEL.FATAL]: 'error',
    [LOG_LEVEL.LOG_EVENT]: 'log_event',
    [LOG_LEVEL.RAW_JSON]: 'log',
};
