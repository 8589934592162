import {
    ADD_SCRIPT,
    getWidgetScript,
    REMOVE_SCRIPT,
    scriptOperation,
} from './utils';

export const DRIFT_SCRIPT_ID = 'driftChatWidget';
export const driftScript = `
            "use strict";
            !function() {
            var t = window.driftt = window.drift = window.driftt || [];
            if (!t.init) {
                if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
                t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ], 
                t.factory = function(e) {
                return function() {
                    var n = Array.prototype.slice.call(arguments);
                    return n.unshift(e), t.push(n), t;
                };
                }, t.methods.forEach(function(e) {
                t[e] = t.factory(e);
                }), t.load = function(t) {
                var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
                o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
                var i = document.getElementsByTagName("script")[0];
                i.parentNode.insertBefore(o, i);
                };
            }
            }();
            drift.SNIPPET_VERSION = '0.3.1';
            drift.load('hicwduckykxu');`;

export const addDriftChat = () => {
    scriptOperation(ADD_SCRIPT, DRIFT_SCRIPT_ID, driftScript);
    const controllerElement = getWidgetScript('drift-frame-controller');
    const chatElement = getWidgetScript('drift-frame-chat');
    if (controllerElement && chatElement) {
        controllerElement.style.display = 'block';
        chatElement.style.display = 'block';
    }
};
export const removeDriftChat = () => {
    const controllerElement = getWidgetScript('drift-frame-controller');
    const chatElement = getWidgetScript('drift-frame-chat');
    if (controllerElement && chatElement) {
        controllerElement.style.display = 'none';
        chatElement.style.display = 'none';
    }

    scriptOperation(REMOVE_SCRIPT, DRIFT_SCRIPT_ID);
};

export const updateStylesForDriftElements = () => {
    const controllerElement = getWidgetScript('drift-frame-controller');
    const chatElement = getWidgetScript('drift-frame-chat');
    if (controllerElement && chatElement) {
        controllerElement.style.zIndex = '99999900';
        chatElement.style.zIndex = '99999900';

        return true;
    }
    return false;
};
