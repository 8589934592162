import {
    ANALYTICS_EVENTS_TAB,
    ANALYTICS_TAB,
    APP_SUMO_PAGE,
    AUDIENCE_TAB,
    BILLING_PAGE_TAB,
    CONTENT_HUB_TAB,
    EVENTS_PAGE_TAB,
    GETTING_STARTED_TAB,
    LIBRARY_PAGE_TAB,
    SETTINGS_TAB,
    TEAM_PAGE_TAB,
    VIDEO_LIBRARY_PAGE_TAB,
} from 'components/community/constants';
import {
    ANALYTICS_AUDIENCE_TAB,
    BILLING_ACCESS,
    COMMUNITY_DOWNLOAD_RECORDING,
    COMMUNITY_EDIT_RECORDING,
    COMMUNITY_WATCH_RECORDING,
    INTEGRATION_ACCESS,
    INTERACTABLE,
    TEAM_ACCESS,
    VIDEO_LIBRARY_ACCESS,
    VISIBLE,
} from 'components/community/team/constants';
import usePlanFlags from 'hooks/billing-plans/usePlanFlags';
import useUserPermission from 'hooks/roles-and-permissions/useUserPermission';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { canShowPremiumFeatures } from 'utils/core';
import { IS_CUSTOM_TENANT } from 'utils/tenant';
import { showGettingStarted } from 'views/community/utils';
import history from '../../history';
import useDashboardFeatureFlags from './useDashboardFeatureFlags';

function useCommunitySideMenu() {
    const communityData = useSelector((state) => state.Community);
    const { getUserPermissionValues } = useUserPermission();
    const { isFreeTrialPlan } = usePlanFlags();

    const [
        teamAccess,
        videosTabAccess,
        integrationAccess,
        billingAccess,
        audienceTabAccess,
        watchRecording,
        downloadRecording,
        deleteRecording,
    ] = getUserPermissionValues([
        TEAM_ACCESS,
        VIDEO_LIBRARY_ACCESS,
        INTEGRATION_ACCESS,
        BILLING_ACCESS,
        ANALYTICS_AUDIENCE_TAB,
        COMMUNITY_WATCH_RECORDING,
        COMMUNITY_DOWNLOAD_RECORDING,
        COMMUNITY_EDIT_RECORDING,
    ]);

    const user = useSelector((state) => state.auth.user);
    const isCommunityManager = user?.id === communityData?.current?.manager_id;
    const subscriptionType = communityData?.current?.subscription_type;
    const isPaidPlan = canShowPremiumFeatures(subscriptionType, false);
    const { is_content_hub_enabled } = useDashboardFeatureFlags({});
    const canAccessVideoLibraryRecordingsTab = !![
        watchRecording,
        downloadRecording,
        deleteRecording,
    ].filter((permission) => permission[VISIBLE] === true).length;

    const sideMenuConfig = useMemo(() => {
        return {
            [GETTING_STARTED_TAB]: {
                id: 'getting-started',
                disabled: IS_CUSTOM_TENANT || !showGettingStarted,
                isSvg: true,
                displayText: 'Getting Started',
                icon: 'icon-rocket',
                canClickComponent: true,
                showPremium: false,
            },
            [EVENTS_PAGE_TAB]: {
                id: 'airmeets',
                disabled: false,
                isSvg: true,
                displayText: 'Events',
                icon: IS_CUSTOM_TENANT ? 'calendar_starred' : 'icon-airmeet',
                showPremium: false,
                canClickComponent: true,
            },
            [CONTENT_HUB_TAB]: {
                id: 'contentHub',
                disabled: !is_content_hub_enabled,
                isSvg: true,
                displayText: 'Content Hubs',
                icon: 'icon-content-hub',
                betaTag: {
                    title: 'Content Hub is in closed beta!',
                    tagContainerStyle: { marginLeft: '0px' },
                },
                canClickComponent: true,
            },
            [VIDEO_LIBRARY_PAGE_TAB]: {
                id: 'videoLibrary',
                disabled: true,
                isSvg: false,
                displayText: 'Video Library',
                icon: 'video',
                showPremium: !isPaidPlan,
                canClickComponent: videosTabAccess[INTERACTABLE],
            },
            [LIBRARY_PAGE_TAB]: {
                id: 'library',
                disabled: [
                    videosTabAccess[VISIBLE],
                    canAccessVideoLibraryRecordingsTab,
                ].every((value) => !value),
                isSvg: false,
                displayText: 'Library',
                icon: 'folder',
                canClickComponent: true,
            },
            [TEAM_PAGE_TAB]: {
                id: 'team',
                disabled: !teamAccess[VISIBLE],
                isSvg: true,
                displayText: 'Team',
                icon: 'icon-members',
                canClickComponent: teamAccess[INTERACTABLE],
                showPremium: false,
            },
            [SETTINGS_TAB]: {
                id: 'integration',
                disabled: !integrationAccess[VISIBLE],
                isSvg: true,
                displayText: 'Integrations',
                icon: 'icon-integration',
                showPremium: false,
                canClickComponent: integrationAccess[INTERACTABLE],
            },
            [BILLING_PAGE_TAB]: {
                id: 'billing-plans',
                disabled:
                    IS_CUSTOM_TENANT ||
                    communityData?.current?.coupon_type ||
                    !billingAccess[VISIBLE]
                        ? true
                        : false,
                isSvg: false,
                displayText: 'Billing',
                icon: 'billing-plans',
                showPremiumIcon: isPaidPlan && !isFreeTrialPlan ? false : true,
                premiumText: isPaidPlan && !isFreeTrialPlan ? null : 'BUY NOW',
                canClickComponent: billingAccess[INTERACTABLE],
            },
            [APP_SUMO_PAGE]: {
                id: 'appSumoPlans',
                disabled:
                    communityData?.current?.coupon_type && isCommunityManager
                        ? false
                        : true,
                isSvg: false,
                displayText: 'AppSumo Plan',
                icon: 'plan_badge',
                showPremium: false,
                canClickComponent: true,
            },
            [ANALYTICS_TAB]: {
                id: ANALYTICS_TAB,
                disabled: !audienceTabAccess[VISIBLE],
                isSvg: false,
                displayText: 'Analytics',
                icon: 'ic-data-line',
                childrenIds: [ANALYTICS_EVENTS_TAB, AUDIENCE_TAB],
                canClickComponent: audienceTabAccess[INTERACTABLE],
            },
            [ANALYTICS_EVENTS_TAB]: {
                id: ANALYTICS_EVENTS_TAB,
                isSvg: false,
                displayText: 'Events',
                disabled: !audienceTabAccess[VISIBLE],
                icon: 'calendar_starred',
                parentId: ANALYTICS_TAB,
                dependentId: ANALYTICS_TAB,
                canClickComponent: audienceTabAccess[INTERACTABLE],
            },
            [AUDIENCE_TAB]: {
                id: AUDIENCE_TAB,
                isSvg: false,
                displayText: 'Audience',
                disabled: !audienceTabAccess[VISIBLE],
                icon: 'ic-audience',
                parentId: ANALYTICS_TAB,
                canClickComponent: audienceTabAccess[INTERACTABLE],
            },
        };
    }, [communityData, communityData?.current?.communityId]);

    const menuSwitcherHandler = (menuName) => {
        history.push(
            `/community-manager/${communityData?.current?.communityId}/${menuName}`
        );
    };

    return { sideMenuConfig, menuSwitcherHandler };
}

export default useCommunitySideMenu;
