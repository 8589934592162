import { useEffect } from 'react';

function useVWO() {
    const vwoEmbedFunction = (
        accountId,
        settingsTolerance,
        libraryTolerance,
        useExistingJquery,
        isSpa,
        hideElement
    ) => {
        return `(function () {
            var account_id = ${accountId},
                settings_tolerance = ${settingsTolerance},
                library_tolerance = ${libraryTolerance},
                use_existing_jquery = ${useExistingJquery},
                is_spa = ${isSpa},
                hide_element = '${hideElement}',
                /* DO NOT EDIT BELOW THIS LINE */
                f = false,
                d = document,
                code = {
                    use_existing_jquery: function () {
                        return use_existing_jquery;
                    },
                    library_tolerance: function () {
                        return library_tolerance;
                    },
                    finish: function () {
                        if (!f) {
                            f = true;
                            var a = d.getElementById('_vis_opt_path_hides');
                            if (a) a.parentNode.removeChild(a);
                        }
                    },
                    finished: function () {
                        return f;
                    },
                    load: function (a) {
                        var b = d.createElement('script');
                        b.src = a;
                        b.type = 'text/javascript';
                        b.innerText;
                        b.onerror = function () {
                            _vwo_code.finish();
                        };
                        d.getElementsByTagName('head')[0].appendChild(b);
                    },
                    init: function () {
                        window.settings_timer = setTimeout(
                            '_vwo_code.finish()',
                            settings_tolerance
                        );
                        var a = d.createElement('style'),
                            b = hide_element
                                ? hide_element +
                                  '{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}'
                                : '',
                            h = d.getElementsByTagName('head')[0];
                        a.setAttribute('id', '_vis_opt_path_hides');
                        a.setAttribute('type', 'text/css');
                        if (a.styleSheet) a.styleSheet.cssText = b;
                        else a.appendChild(d.createTextNode(b));
                        h.appendChild(a);
                        this.load(
                            'https://dev.visualwebsiteoptimizer.com/j.php?a=' +
                                account_id +
                                '&u=' +
                                encodeURIComponent(d.URL) +
                                '&f=' +
                                +is_spa +
                                '&r=' +
                                Math.random()
                        );
                        return settings_timer;
                    },
                };
            window._vwo_settings_timer = code.init();
            return code;
        })();`;
    };

    useEffect(() => {
        // if (process.env.REACT_APP_ENV !== 'production') {
        //     return;
        // }

        const vwoEmbedScript = document.createElement('script');

        vwoEmbedScript.type = 'text/javascript';
        const inlineConfigScript = document.createTextNode(
            `window._vwo_code = window._vwo_code || ${vwoEmbedFunction(
                process.env.REACT_APP_VWO_PRODUCT_ID,
                process.env.REACT_APP_VWO_SETTINGS_TOLERANCE,
                process.env.REACT_APP_VWO_LIBRARY_TOLERANCE,
                JSON.parse(process.env.REACT_APP_VWO_USE_EXISTING_JQUERY),
                process.env.REACT_APP_VWO_IS_SPA,
                process.env.REACT_APP_VWO_HIDE_ELEMENT
            )}`
        );
        vwoEmbedScript.appendChild(inlineConfigScript);

        document.head.appendChild(vwoEmbedScript);
    }, []);
}

export default useVWO;
