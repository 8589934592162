const speakerQuestionModerationToggle = ({
    airmeetId,
    client,
    payload,
    metaData,
    logger,
}) => {
    logger.info('changing speaker question moderation');
    const fireBaseKey = metaData?.speakerModerationToggleDataPath;
    return client.setData(fireBaseKey, payload);
};

export default speakerQuestionModerationToggle;
