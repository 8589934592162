import useShowOnStage from 'hooks/live-airmeet/useShowOnStage';
import useShowOnStageContent from 'hooks/live-airmeet/useShowOnStageContent';
import identity from 'lodash/identity';
import React, {
    createContext,
    memo,
    useCallback,
    useContext,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'utils/constants/common';
import useLiveAirmeetContext from '../hooks/useLiveAirmeetContext';
import { getBannerData } from 'store/selectors';

const ManageShowOnStage = createContext(null);

export const STAGE_OVERLAY_TYPE = {
    MAIN_STAGE_AREA: 'mainStageArea',
    LOWER_THIRDS: 'lowerThirds',
    CTA_BANNER_AREA: 'ctaBannerArea',
    BANNER_AREA: 'bannerArea',
};

export const useManageShowOnStageContext = (callback = identity) => {
    const ctx = useContext(ManageShowOnStage);

    // Return the up-to-date selected value
    const finalValue = callback(ctx || noop);
    return useMemo(() => finalValue, [finalValue]);
};

export const ManageShowOnStageProvider = memo(({ children }) => {
    const {
        airmeet: {
            airmeetId,
            data: { currentSession },
        },
    } = useLiveAirmeetContext();
    const stageId = currentSession ? currentSession.sessionid : airmeetId;
    const stageRoomNode = `${airmeetId}/meta-data/sessions/${stageId}`;
    const {
        reserveOnStage,
        hideFromStage,
        stageFeatureData,
        featureData,
        featureNames,
        logStageEvent,
    } = useShowOnStage({ stageRoomNode, stageId });
    const {
        addStageContent,
        removeStageContent,
        removeStageContentType,
        getContentTypeData,
        stageContentData,
        contentTypes,
        logStageContentEvent,
    } = useShowOnStageContent({ stageRoomNode, stageId });

    const mainStageAreaData = useMemo(() => {
        if (
            stageFeatureData?.overlayType === STAGE_OVERLAY_TYPE.MAIN_STAGE_AREA
        ) {
            return stageFeatureData;
        }
        if (!stageContentData) {
            return;
        }
        let data = null;
        Object.keys(stageContentData).forEach((key) => {
            Object.keys(stageContentData[key]).forEach((contentKey) => {
                const contentData = stageContentData[key][contentKey];
                if (
                    !data &&
                    contentData?.overlayType ===
                        STAGE_OVERLAY_TYPE.MAIN_STAGE_AREA
                ) {
                    data = contentData;
                }
            });
        });
        return data;
    }, [stageContentData, stageFeatureData]);

    const isMainStageArea = !!mainStageAreaData;

    const isLowerThirds =
        stageFeatureData?.overlayType === STAGE_OVERLAY_TYPE.LOWER_THIRDS;

    const isFeatureContentOnStage = useCallback(
        (featureId, featureName) => {
            return (
                featureData?.featureId === featureId &&
                featureData?.featureName === featureName
            );
        },
        [featureData]
    );

    // handle banner Preview states
    const allBannerData = useSelector(getBannerData);
    const currentEntityBannersForPreview = allBannerData?.[stageId] || {};

    const updatedStageContent = {
        ...stageContentData,
        ...currentEntityBannersForPreview,
    };

    const value = useMemo(() => {
        return {
            stageFeatureData,
            featureData,
            reserveOnStage,
            hideFromStage,
            featureNames,
            isAllow: true,
            isMainStageArea,
            isLowerThirds,
            isFeatureContentOnStage,
            logStageEvent,
            addStageContent,
            removeStageContent,
            removeStageContentType,
            getContentTypeData,
            stageContentData: updatedStageContent,
            contentTypes,
            logStageContentEvent,
        };
    }, [
        stageFeatureData,
        featureData,
        reserveOnStage,
        hideFromStage,
        featureNames,
        isMainStageArea,
        isLowerThirds,
        isFeatureContentOnStage,
        logStageEvent,
        addStageContent,
        removeStageContent,
        removeStageContentType,
        getContentTypeData,
        updatedStageContent,
        contentTypes,
        logStageContentEvent,
    ]);

    return (
        <ManageShowOnStage.Provider value={value}>
            {children}
        </ManageShowOnStage.Provider>
    );
});

export default ManageShowOnStage;
