import { useEffect, useMemo } from 'react';
import { ALL_FEATURES_CONFIG } from 'hooks/useFeatureControl';
import { setVolumeControl } from 'store/actions/liveAirmeet';
import { useDispatch } from 'react-redux';

const useVolumeControlFeature = ({ featuresConfig }) => {
    const dispatch = useDispatch();
    const isEnabled = useMemo(() => {
        const volumeChangeEnabled =
            featuresConfig?.[ALL_FEATURES_CONFIG.VOLUME_CONTROL];
        return volumeChangeEnabled !== null
            ? Boolean(volumeChangeEnabled)
            : false;
    }, [featuresConfig]);

    useEffect(() => {
        dispatch(setVolumeControl(isEnabled));
    }, [isEnabled, dispatch]);
};

export default useVolumeControlFeature;
