import Icon from 'foundations/icon';
import theme from 'foundations/theme';
import { Typography } from 'foundations/typography';
import * as React from 'react';
import styled from 'styled-components';

export default function Dropdown({ title, content, handleClick = null }) {
    const [active, setActive] = React.useState(false);

    return (
        <Wrapper>
            <Typography
                variant={'subtitle1'}
                color='ambience.6'
                py={10}
                onClick={() => {
                    setActive((active) => !active);
                }}
            >
                {title}
            </Typography>
            <IconWrap
                active={active}
                onClick={() => {
                    setActive((active) => !active);
                }}
            >
                <Icon
                    width={22.5}
                    height={22.5}
                    icon={'chevron_down'}
                    fill={theme.colors.ambience[10]}
                />
            </IconWrap>
            <Menu active={active}>
                {content.map(({ name, link }, index) => (
                    <MenuItem active={active} key={index}>
                        {link ? (
                            <FooterLink
                                href={link}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <Typography
                                    variant={'subtext1'}
                                    color='ambience.12'
                                    py={10}
                                >
                                    {name}
                                </Typography>
                            </FooterLink>
                        ) : (
                            <FooterWrap>{name}</FooterWrap>
                        )}
                    </MenuItem>
                ))}
            </Menu>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin: 0 auto;
    position: relative;
    width: 100%;
`;

const Menu = styled.ul`
    margin: 0;
    padding: 0px;
    max-height: ${({ active }) => (active ? '500px' : '0px')};
    transform: ${({ active }) => (active ? 'scaleY(1)' : 'scaleY(0)')};
    transform-origin: top;
    transition: all 0.3s ease-in-out;
`;

const IconWrap = styled.div`
    position: absolute;
    top: 10px;
    right: 0px;
    transform: ${({ active }) => (active ? 'rotateX(180deg)' : 'rotateX(0)')};
    transition: all 0.3s ease-in-out;
`;

const MenuItem = styled.li`
    display: block;
    width: max-content;
`;

const FooterWrap = styled.div`
    padding: 10px 0px;
    width: max-content;
`;

const FooterLink = styled.a`
    :link {
        text-decoration: none;
    }
`;
