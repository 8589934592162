import Drawer from 'atoms/Drawer/drawer';
import useTheme from 'hooks/useTheme';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import Select from 'react-select';
import styled from 'styled-components';
import { logger } from 'utils/logger';
import DataTable, { SortOrder } from '../../../common/DataTable';
import { AnalyticsContext } from '../AnalyticsProvider';
import {
    boothHeaders,
    compareData,
    getCommonBarOptions,
    sessionHeaders,
    topBarOptions,
} from '../utils';
import AllButton from './AllButton';
import ChartCard from './ChartCard';
import EmptyCardBanner from './EmptyCardBanner';

const boothsOrderByOptions = [
    { value: 'VISITS', label: 'Visits', key: 'visits' },
    { value: 'CTA_CLICKS', label: 'CTA Clicks', key: 'cta_clicks' },
    { value: 'VIDEO_VIEWS', label: 'Video views', key: 'video_views' },
];
const sessionsOrderByOptions = [
    { value: 'ATTENDANCE', label: 'Attendance', key: 'attendance' },
    { value: 'DURATION', label: 'Duration (in mins)', key: 'duration' },
    { value: 'MESSAGES', label: 'Messages', key: 'messages' },
    { value: 'QUESTIONS', label: 'Questions', key: 'questions' },
];

const boothsBackgroundColors = [
    'rgba(83, 137, 242, 1)',
    'rgba(83, 137, 242, 0.88)',
    'rgba(83, 137, 242, 0.72)',
    'rgba(83, 137, 242, 0.64)',
    'rgba(83, 137, 242, 0.56)',
];
const sessionsBackgroundColors = [
    'rgba(255, 159, 183, 1)',
    'rgba(255, 159, 183, 0.88)',
    'rgba(255, 159, 183, 0.72)',
    'rgba(255, 159, 183, 0.64)',
    'rgba(255, 159, 183, 0.56)',
];

const customStyles = (theme) => {
    return {
        menu: (provided) => ({
            ...provided,
            width: '150px',
            backgroundColor: '#26252D',
            borderRadius: '8px',
            position: 'absolute',
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: '0',
            color: state.isSelected ? '#FCFCFC' : '#A8A5B6',
            padding: '8px 16px',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            '&:hover': {
                color: '#FCFCFC',
            },
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        control: () => ({
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            border: 0,
            borderRadius: '8px',
            color: '#FCFCFC',
            cursor: 'pointer',
            '&:hover, &:active, &:focus': {
                backgroundColor: 'rgba(38, 37, 45, 0.64)',
            },
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return {
                ...provided,
                opacity,
                transition,
                color: theme?.isLightTheme ? '#000000' : '#FCFCFC',
                position: 'static',
                top: 'auto',
                left: 'auto',
                transform: 'none',
                maxWidth: 'none',
            };
        },
        valueContainer: (provided) => ({ ...provided, paddingRight: '0' }),
    };
};

export default function TopBarsCard({ type = 'booths' }) {
    const { theme } = useTheme();
    const {
        eventStarted,
        logEvent,
        reportNotGenerated,
        getRequest,
        setBoothsApiSuccess,
    } = useContext(AnalyticsContext);
    const isBooths = type === 'booths';
    const [modalVisible, setModalVisible] = useState(false);
    const [orderBy, setOrderBy] = useState(
        isBooths ? boothsOrderByOptions[0] : sessionsOrderByOptions[0]
    );
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const defaultChartInfo = {
        labels: Array(5).fill(''),
        data: Array(5).fill(5),
        max: 5,
        options: {
            ...getCommonBarOptions({
                yTicksDisplay: false,
                xAxesMax: 300,
                theme,
            }),
            animation: {},
            tooltips: {
                enabled: false,
            },
        },
        backgroundColor: Array(5).fill('#26252D'),
    };

    const [chartInfo, setChartInfo] = useState(defaultChartInfo);

    const fetchData = useCallback(async () => {
        if (!reportNotGenerated) {
            setLoading(true);
            let apiFailed = true;

            try {
                const { data, statusCode } = await getRequest({
                    endpoint: isBooths
                        ? 'booth-analytics'
                        : 'session-analytics',
                });
                if (statusCode === 200) {
                    let listData = [];

                    // Make backward compatible
                    if (Array.isArray(data)) {
                        listData = data;
                    } else {
                        if (isBooths) {
                            listData = data?.booths;
                        } else {
                            listData = data?.sessions;
                        }
                    }

                    setData(listData || []);
                    if (!isEmpty(data)) {
                        apiFailed = false;
                    }
                } else {
                    setData(null);
                    setChartInfo(defaultChartInfo);
                }
            } catch (e) {
                logger.error(e);
            }

            setLoading(false);

            if (isBooths) {
                setBoothsApiSuccess(!apiFailed);
            }
        }
    }, [isBooths, reportNotGenerated, setBoothsApiSuccess, getRequest]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // On orderBy change sort the data
    useEffect(() => {
        if (Array.isArray(data) && data.length) {
            const orderedData = [...data].sort(
                (a, b) => -1 * compareData(a[orderBy.key], b[orderBy.key])
            );

            // Chart Data
            let chartLabels = [];
            let chartData = [];
            (orderedData || []).slice(0, 5).forEach((item) => {
                chartLabels.push(
                    item[isBooths ? 'booth_name' : 'session_name']
                );
                chartData.push(item[orderBy.key] || 0);
            });

            const chartMax = chartData && chartData.length ? chartData[0] : 0;
            setChartInfo({
                labels: chartLabels,
                data: chartData,
                max: chartMax,
                options: {
                    ...getCommonBarOptions({ yTicksDisplay: false }),
                    ...topBarOptions({ theme }),
                },
                backgroundColor: isBooths
                    ? boothsBackgroundColors
                    : sessionsBackgroundColors,
            });
        }
    }, [data, orderBy, isBooths, theme]);

    // Handle table sorting
    const onSort = (key, dir) => {
        const orderedData = (Array.isArray(data) ? data : []).sort(
            (a, b) =>
                compareData(
                    a[key],
                    b[key],
                    ['session_name', 'booth_name'].includes(key)
                        ? 'string'
                        : 'number'
                ) * (dir === SortOrder.DESC ? -1 : 1)
        );
        setData([...orderedData]);
    };

    const handleOpenAll = () => {
        setModalVisible && setModalVisible(true);
        logEvent('seeAllClicked', {
            widget_type: type === 'booths' ? 'booth' : 'session',
        });
    };

    const isEmptyChart = !(Array.isArray(data) && data.length > 0);

    if (isEmptyChart) return null;

    return (
        <>
            <ChartCard
                loading={loading}
                title={`Top ${isBooths ? 'Booths' : 'Sessions'}`}
                actions={
                    isEmptyChart ? null : (
                        <Select
                            options={
                                isBooths
                                    ? boothsOrderByOptions
                                    : sessionsOrderByOptions
                            }
                            styles={customStyles(theme)}
                            value={orderBy}
                            onChange={setOrderBy}
                            isSearchable={false}
                        />
                    )
                }
                bodyStyle={{ flexDirection: 'column', alignItems: 'stretch' }}
                minLoadingHeight={'270px'}
            >
                <div
                    style={{
                        flexGrow: 1,
                        minHeight: '270px',
                    }}
                >
                    <HorizontalBar
                        data={{
                            labels: chartInfo?.labels,
                            datasets: [
                                {
                                    data: chartInfo?.data,
                                    backgroundColor: chartInfo?.backgroundColor,
                                    borderWidth: 0,
                                },
                            ],
                        }}
                        options={chartInfo?.options}
                    />
                </div>
                {isEmptyChart ? null : <AllButton onClick={handleOpenAll} />}
                {reportNotGenerated && eventStarted ? (
                    <EmptyCardBanner />
                ) : null}
            </ChartCard>
            {modalVisible && (
                <Drawer
                    wrapperClosable={false}
                    width='80vw'
                    title={`All ${isBooths ? 'Booths' : 'Sessions'}`}
                    subtitle='Click on the headers to change how the data is sorted'
                    onClose={() => {
                        setModalVisible(false);
                    }}
                    showClose
                    portal={true}
                    bodyStyle={{ overflowX: 'auto' }}
                >
                    <StyledDataTable>
                        <DataTable
                            headers={isBooths ? boothHeaders : sessionHeaders}
                            dataSource={Array.isArray(data) ? data : []}
                            dense={true}
                            arrowSortIcon={true}
                            onSort={onSort}
                            wrapperClassName='analytics-table'
                        />
                    </StyledDataTable>
                </Drawer>
            )}
        </>
    );
}

const StyledDataTable = styled.div`
    ${({ theme }) =>
        theme?.isLightTheme
            ? `
        .analytics-table .table-container .table-inner-container {
            overflow-x: auto;
            thead tr {
                background-color: ${theme.colors.ambience[23]} !important;
                border: solid 1px ${theme.colors.ambience[23]} !important;
                th span {
                    color: ${theme.colors.ambience[0]} !important;
                }
            }
            tbody tr td {
                div {
                    color: ${theme.colors.ambience[0]} !important;
                }
            }
        }
    `
            : ''}
`;
