import { VENDOR_AGORA, VENDOR_DAILY } from 'utils/constants/common';

export const VENDORS = {
    AGORA: VENDOR_AGORA,
    agora: VENDOR_AGORA,
    Agora: VENDOR_AGORA,
    DAILY: VENDOR_DAILY,
    Daily: VENDOR_DAILY,
    daily: VENDOR_DAILY,
} as const;

export const DEFAULT_VENDOR = VENDORS.AGORA;
