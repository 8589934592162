const isNotInvitedSpeaker = (speakers, unmappedSpeakers, userId) => {
    if (!(Object.keys(speakers) || []).includes(userId)) {
        return (unmappedSpeakers || []).some(
            (speaker) => speaker.speaker_id === userId && !speaker?.isInvited
        );
    } else if (speakers[userId]?.isInvited) {
        return false;
    }
    return true;
};

export default isNotInvitedSpeaker;
