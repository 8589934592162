import {
    DailyEventObjectNetworkQualityEvent,
    DailyRoomInfo,
} from '@daily-co/daily-js';
import { GM_MEDIA_STATE } from 'utils/DailyUIWrapper/constants';
import { IDailyUIWrapper } from 'utils/DailyUIWrapper/IDailyUIWrapper';

// action names
export const GROUP_MEETING_SET_WRAPPER = 'GROUP_MEETING_SET_WRAPPER';
export const GROUP_MEETING_RESET_TO_DEFAULT = 'GROUP_MEETING_RESET_TO_DEFAULT';
export const GROUP_MEETING_CHAT_TAB_VISIBILITY = 'GROUP_MEETING_RHS_VISIBILITY';
export const GROUP_MEETING_PEOPLE_TAB_VISIBILITY =
    'GROUP_MEETING_PEOPLE_TAB_VISIBILITY';
export const GROUP_MEETING_ADD_CHAT_MESSAGE = 'GROUP_MEETING_ADD_CHAT_MESSAGE';
export const GROUP_MEETING_CLEAR_ALL_MESSAGES =
    'GROUP_MEETING_CLEAR_ALL_MESSAGES';
export const GROUP_MEETING_PARTICIPANT_JOINED =
    'GROUP_MEETING_PARTICIPANT_JOINED';
export const GROUP_MEETING_PARTICIPANT_LEFT = 'GROUP_MEETING_PARTICIPANT_LEFT';
export const GROUP_MEETING_LOCAL_SCREEN_SHARED =
    'GROUP_MEETING_LOCAL_SCREEN_SHARED';
export const GROUP_MEETING_NETWORK_QUALITY_CHANGE =
    'GROUP_MEETING_NETWORK_QUALITY_CHANGE';
export const GROUP_MEETING_UPDATE_AV_STATE = 'GROUP_MEETING_UPDATE_AV_STATE';
export const GROUP_MEETING_ADD_SCREEN_SHARE = 'GROUP_MEETING_ADD_SCREEN_SHARE';
export const GROUP_MEETING_REMOVE_SCREEN_SHARE =
    'GROUP_MEETING_REMOVE_SCREEN_SHARE';
export const GROUP_MEETING_SET_MAX_TILES_PER_PAGE =
    'GROUP_MEETING_SET_MAX_TILES_PER_PAGE';
export const GROUP_MEETING_ERROR = 'GROUP_MEETING_ERROR';
export const GROUP_MEETING_RECORDING_ACTIVE = 'GROUP_MEETING_RECORDING_ACTIVE';
export const GROUP_MEETING_ROOM_INFO = 'GROUP_MEETING_ROOM_INFO';
export const GROUP_MEETING_START_TIMER = 'GROUP_MEETING_START_TIMER';
export const GROUP_MEETING_WEBVIEW = 'GROUP_MEETING_WEBVIEW';
export const GROUP_MEETING_WEBVIEW_CALL_LEFT =
    'GROUP_MEETING_WEBVIEW_CALL_LEFT';

// actions
export const setLocalAVState = (avState: GM_MEDIA_STATE) => ({
    type: GROUP_MEETING_UPDATE_AV_STATE,
    payload: avState,
});

export const addScreenShare = (screenUserId: string) => ({
    type: GROUP_MEETING_ADD_SCREEN_SHARE,
    payload: screenUserId,
});

export const removeScreenShare = (screenUserId: string) => ({
    type: GROUP_MEETING_REMOVE_SCREEN_SHARE,
    payload: screenUserId,
});

export const setGroupMeetingNetworkQuality = (
    networkQuality: DailyEventObjectNetworkQualityEvent
) => ({
    type: GROUP_MEETING_NETWORK_QUALITY_CHANGE,
    payload: networkQuality,
});

export const setGroupMeetingError = (error: any) => ({
    type: GROUP_MEETING_ERROR,
    payload: error,
});

export const setDailyWrapperInstance = (wrapper: IDailyUIWrapper) => ({
    type: GROUP_MEETING_SET_WRAPPER,
    payload: wrapper,
});

export const resetGroupMeeting = () => ({
    type: GROUP_MEETING_RESET_TO_DEFAULT,
});

export const setChatTabVisibility = (isVisible: boolean) => ({
    type: GROUP_MEETING_CHAT_TAB_VISIBILITY,
    payload: isVisible,
});

export const setPeopleTabVisibility = (isVisible: boolean) => ({
    type: GROUP_MEETING_PEOPLE_TAB_VISIBILITY,
    payload: isVisible,
});

export const addChatMessage = (message) => ({
    type: GROUP_MEETING_ADD_CHAT_MESSAGE,
    payload: message,
});

export const clearAllMessages = () => ({
    type: GROUP_MEETING_CLEAR_ALL_MESSAGES,
});

export const addParticipant = (id: string, isLocal: boolean = false) => ({
    type: GROUP_MEETING_PARTICIPANT_JOINED,
    payload: { id, isLocal },
});

export const removeParticipant = (id: string) => ({
    type: GROUP_MEETING_PARTICIPANT_LEFT,
    payload: id,
});

export const setLocalScreenShared = (isScreenShared: boolean) => ({
    type: GROUP_MEETING_LOCAL_SCREEN_SHARED,
    payload: isScreenShared,
});

export const setMaxTilesPerPage = (maxTilesPerPage: number) => ({
    type: GROUP_MEETING_SET_MAX_TILES_PER_PAGE,
    payload: maxTilesPerPage,
});

export const setRecordingActive = (isActive: boolean) => ({
    type: GROUP_MEETING_RECORDING_ACTIVE,
    payload: isActive,
});

export const setRoomInfo = (roomInfo: DailyRoomInfo) => ({
    type: GROUP_MEETING_ROOM_INFO,
    payload: roomInfo,
});

export const setGroupMeetingStartTimer = (sessionId: string) => ({
    type: GROUP_MEETING_START_TIMER,
    payload: sessionId,
});

export const setGroupMeetingWebview = (isEnabled: boolean) => ({
    type: GROUP_MEETING_WEBVIEW,
    payload: isEnabled,
});

export const setGroupMeetingWebviewCallLeft = (left: boolean) => ({
    type: GROUP_MEETING_WEBVIEW_CALL_LEFT,
    payload: left,
});
