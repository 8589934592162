import axios from 'axios';
import { CANCEL_UPLOAD_ERROR } from 'components/StageRoom/BreakoutRooms/constants';
import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSignedUrl } from 'store/actions/videoUplaoder';
import { emptyObject } from 'utils/constants/common';
import { logger } from 'utils/logger';

function useFileUploader({ logPrefix = 'Breakout CSV' } = emptyObject) {
    const [uploadProgressPercent, setUploadProgressPercent] = useState(0);
    const dispatch = useDispatch();
    const axiosTokenSourceRef = useRef(null);

    const beforeCloseWindow = useCallback((event) => {
        const confirmationMessage =
            'Uploading still running...\nAre you sure you want to leave?';

        (event || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Webkit, Safari, Chrome etc.
    }, []);

    const uploadProgressHandler = useCallback((event) => {
        if (!event.loaded || !event.total) {
            return;
        }

        const percent = Math.round((event.loaded / event.total) * 100);
        setUploadProgressPercent(percent);
    }, []);

    const resetUploadProgress = useCallback(() => setUploadProgressPercent(0), [
        setUploadProgressPercent,
    ]);

    const uploadFile = useCallback(
        async (file: File, endPoint: string) =>
            axios.put(endPoint, file, {
                headers: { 'Access-Control-Allow-Origin': '*' },
                onUploadProgress: uploadProgressHandler,
                cancelToken: axiosTokenSourceRef.current.token,
            }),
        [uploadProgressHandler]
    );

    const onUpload = useCallback(
        async (file, entityData) => {
            try {
                window.addEventListener(
                    'beforeunload',
                    beforeCloseWindow,
                    true
                );
                axiosTokenSourceRef.current = axios.CancelToken.source();
                const signedUrlRes = await dispatch(
                    getSignedUrl({
                        file_name: file.name,
                        file_type: file.type,
                        ...entityData,
                    })
                );
                logger.debug(
                    `${logPrefix} : Signed URL response : `,
                    signedUrlRes
                );
                if (signedUrlRes.error) {
                    logger.error(
                        'Error uploading file : ',
                        signedUrlRes?.payload?.json?.message
                    );
                } else {
                    const uploadResponse = await uploadFile(
                        file,
                        signedUrlRes.payload.uploadUrl
                    );
                    logger.debug(
                        `${logPrefix} : File uploaded successfully : `,
                        uploadResponse
                    );
                }
                return signedUrlRes;
            } catch (error) {
                logger.error('Error uploading file : ', error);
                return { error };
            } finally {
                window.removeEventListener(
                    'beforeunload',
                    beforeCloseWindow,
                    true
                );
            }
        },
        [dispatch, uploadFile, beforeCloseWindow, logPrefix]
    );

    const onCancelUpload = useCallback(() => {
        if (axiosTokenSourceRef.current?.cancel) {
            logger.info(`${logPrefix} : File upload cancelled`);
            axiosTokenSourceRef.current.cancel(CANCEL_UPLOAD_ERROR);
        }
    }, [logPrefix]);

    return {
        onUpload,
        uploadProgressPercent,
        onCancelUpload,
        resetUploadProgress,
    };
}

export default useFileUploader;
