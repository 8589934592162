import { commonDoughnutOptions } from 'components/community/editPublicAirmeet/Analytics/utils';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import useTheme from 'hooks/useTheme';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import styled from 'styled-components';
import { logger } from 'utils/logger';
import LoadingOverlay from './components/LoadingOverlay';
import { Card, LegendDot } from './styledComponents';
import { getViewers } from './utils/api';

const getData = ({ theme, data = {} }) => {
    const total =
        (data?.new_attendees || 0) +
        (data?.no_show || 0) +
        (data?.live_attendees || 0);

    return {
        total,
        data: [
            {
                id: 'new_attendees',
                label: 'New attendees',
                value: data?.new_attendees,
                percent: total
                    ? parseFloat((data?.new_attendees / total) * 100).toFixed(2)
                    : 0,
                color: theme.colors.module.viz.carrot['600'],
            },
            {
                id: 'no_show',
                label: 'No show',
                value: data?.no_show,
                percent: total
                    ? parseFloat((data?.no_show / total) * 100).toFixed(2)
                    : 0,
                color: theme.colors.module.viz.sky['600'],
            },
            {
                id: 'live_attendees',
                label: 'Attended live',
                value: data?.live_attendees,
                percent: total
                    ? parseFloat((data?.live_attendees / total) * 100).toFixed(
                          2
                      )
                    : 0,
                color: theme.colors.module.viz.orchid['800'],
            },
        ],
    };
};

function ViewerTypesCard({ airmeetId, sessionId }) {
    const { theme } = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const [apiData, setApiData] = useState({});

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await getViewers({
                airmeetId,
                sessionId,
            });
            if (!isEmpty(response?.data)) {
                setApiData(response?.data);
            }
        } catch (e) {
            logger.error('PostEventTab.fetchData: ', e);
        } finally {
            setIsLoading(false);
        }
    }, [airmeetId, sessionId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const { total, data } = useMemo(() => getData({ theme, data: apiData }), [
        theme,
        apiData,
    ]);

    return (
        <Card flex={['1', '50%', '30%']}>
            <Text variant='body2' color='text.default.primary' pb='x6'>
                On-demand viewers
            </Text>
            <FlexDiv justifyContent='center'>
                <FlexDiv
                    width='10rem'
                    height='10rem'
                    justifyContent='flex-end'
                    position='relative'
                >
                    <Doughnut
                        data={{
                            labels: data.map((i) => i?.label),
                            datasets: [
                                {
                                    label: data.map((i) => i?.label),
                                    data: data.map((i) => i?.value),
                                    backgroundColor: data.map((i) => i?.color),
                                    borderWidth: 0,
                                },
                            ],
                        }}
                        options={{
                            ...(commonDoughnutOptions || {}),
                            cutoutPercentage: 70,
                        }}
                    />
                    <CenterContent>
                        <StyledSvgSprite
                            icon='people_community_outline'
                            fill='text.default.secondary'
                            height='1rem'
                            width='1rem'
                        />
                        <Text
                            variant='caption10'
                            color='text.default.secondary'
                            pb='x2'
                        >
                            On-demand viewers
                        </Text>
                        <Text variant='h3' color='text.default.primary'>
                            {total}
                        </Text>
                    </CenterContent>
                </FlexDiv>
            </FlexDiv>
            <FlexDiv
                pt='x5'
                flexDirection='column'
                aligItems='stretch'
                gap='0.25rem'
            >
                {data.map((item) => (
                    <FlexDiv key={item?.id} alignItems='center'>
                        <LegendDot bg={item?.color} />
                        <Text
                            variant='caption10'
                            color='text.default.primary'
                            px='x2'
                        >
                            {item?.label}
                        </Text>
                        <Text
                            variant='caption10'
                            color='text.default.secondary'
                        >
                            {item?.percent}% ({item?.value})
                        </Text>
                    </FlexDiv>
                ))}
            </FlexDiv>
            {isLoading && <LoadingOverlay />}
        </Card>
    );
}

export default ViewerTypesCard;

const CenterContent = styled(FlexDiv)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    flex-direction: column;
    align-items: center;
`;
