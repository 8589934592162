import InputV2 from 'atoms/Input';
import { PRE_RECORDED_VIDEO_TAB } from 'components/community/editPublicAirmeet/constants/tabs';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import React from 'react';
import styled from 'styled-components';

export default function DurationField({ formData, onValueChange, errors }) {
    const handleChange = (e) => {
        onValueChange && onValueChange('duration', e.target.value);
    };
    return (
        <Wrapper
            flexDirection='column'
            alignItems='stretch'
            flex='1'
            errorMsg={errors.duration}
            isValueEmpty={!formData?.duration}
        >
            <InputV2
                label='Duration *'
                placeholder='Duration cannot be empty'
                name='duration'
                onChange={handleChange}
                value={formData?.duration}
                errorMsg={errors.duration}
                id='duration'
                type={'number'}
                disabled={formData?.sessionMeta?.type === 'PRE_RECORDED'}
            />
        </Wrapper>
    );
}

const Wrapper = styled(FlexDiv)`
    & > div {
        position: relative;
        &:after {
            position: absolute;
            top: ${({ errorMsg }) => (errorMsg ? '35%' : '50%')};
            right: 16px;
            transform: translateY(-50%);
            content: 'Mins';
            font-size: 14px;
            line-height: 16px;
            font-family: ${({ theme }) => theme.fonts.book};
            color: ${({ theme }) => theme.colors.ambience[9]};
            z-index: 0;
        }
        ${({ isValueEmpty }) =>
            isValueEmpty &&
            `&:after{
        display:none;
    }
    `}
    }
    label {
        margin-bottom: ${({ theme }) => theme.space.x2};
    }
    /* Chrome, Safari, Edge, Opera */
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
    }
    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
`;
