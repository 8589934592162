// From: https://github.com/airmeet/safe-json-stringify
const hasProp = Object.prototype.hasOwnProperty;

function throwsMessage(err: Error) {
    return '[Throws: ' + (err ? err.message : '?') + ']';
}

function safeGetValueFromPropertyOnObject(obj: any, property: string) {
    if (hasProp.call(obj, property)) {
        try {
            return obj[property];
        } catch (err) {
            return throwsMessage(err);
        }
    }

    return obj[property];
}

export function ensureProperties(obj: any, depth?: number) {
    const seen = []; // store references to objects we have seen before
    let currentDepth = 0; // Track our current depth

    function visit(obj: any) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        // We're a level deeper now
        currentDepth++;
        if (depth !== undefined && depth > 0 && currentDepth > depth) {
            // Returning undefined ensures that Stringify will skip these values
            return undefined;
        }

        if (seen.indexOf(obj) !== -1) {
            return '[Circular]';
        }
        seen.push(obj);

        if (typeof obj.toJSON === 'function') {
            try {
                const fResult = visit(obj.toJSON());
                seen.pop();
                return fResult;
            } catch (err) {
                return throwsMessage(err);
            }
        }

        if (Array.isArray(obj)) {
            const aResult = obj.map(visit);
            seen.pop();
            return aResult;
        }

        const result = Object.keys(obj).reduce(function (result, prop) {
            // prevent faulty defined getter properties
            result[prop] = visit(safeGetValueFromPropertyOnObject(obj, prop));
            return result;
        }, {});
        seen.pop();
        return result;
    }

    return visit(obj);
}

export default function safeStringify(
    data: any,
    replacer?: (this: any, key: string, value: any) => any,
    space?: string | number,
    depth?: number
): string {
    return JSON.stringify(ensureProperties(data, depth), replacer, space);
}
