import AuthService from '../../utils/authService';
import { RESET_STORE } from '../actions/emailLogin';
import {
    EMAIL_SIGNUP_COMPLETE_REQUEST,
    EMAIL_SIGNUP_COMPLETE_SUCCESS,
    EMAIL_SIGNUP_INITIATE_REQUEST,
    EMAIL_SIGNUP_INITIATE_SUCCESS,
    RESEND_CODE_REQUEST,
    RESEND_CODE_SUCCESS,
} from '../actions/emailSignup';

const initialState = {
    error: null,
    emailSent: false,
    resent: false,
    signedup: false,
    processing: false,
    token: null,
};

function emailSignup(state = initialState, { type, payload }) {
    switch (type) {
        case EMAIL_SIGNUP_INITIATE_REQUEST:
        case EMAIL_SIGNUP_COMPLETE_REQUEST:
        case RESEND_CODE_REQUEST:
            return {
                ...state,
                error: null,
                processing: true,
            };
        case EMAIL_SIGNUP_INITIATE_SUCCESS:
            if (payload instanceof Error) {
                return {
                    ...state,
                    error: payload.json.message,
                    processing: false,
                };
            }

            return {
                ...state,
                emailSent: true,
                processing: false,
            };
        case EMAIL_SIGNUP_COMPLETE_SUCCESS:
            if (payload instanceof Error) {
                return {
                    ...state,
                    error: payload.json.message,
                    processing: false,
                };
            }

            const { token, ...user } = payload;
            AuthService.user = user;
            return {
                ...state,
                token,
                signedup: true,
                processing: false,
            };
        case RESEND_CODE_SUCCESS:
            if (payload instanceof Error) {
                return {
                    ...state,
                    error: payload.json.message,
                    processing: false,
                };
            }

            return {
                ...state,
                resent: true,
                processing: false,
            };
        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
}

export default emailSignup;
