/**
 * helper function to assign custom icons for
 * event description widget editor toolnar
 * @param {*} name
 * @returns
 */
export const generateIcons = (name) => {
    return `<svg class="icon ${IconMap[name]}" style="fill: #565365; stroke: #565365;"><use xlink:href="#${IconMap[name]}"></use></svg>`;
};

const IconMap = {
    undo: 'arrow-undo',
    redo: 'arrow-redo',
    font: 'ic-font',
    fontsize: 'ic-fontsize',
    paragraph: 'ic-paragraph',
    brush: 'text-color-picker',
    bold: 'text-bold',
    italic: 'text-italic',
    underline: 'text-underline',
    strikethrough: 'text-strikethrough',
    ul: 'text-unordered-list',
    ol: 'text-ordered-list',
    image: 'widget-image',
    link: 'link',
    symbols: 'omega',
    left: 'text-align-left',
    center: 'text-align-center',
    right: 'text-align-right',
    justify: 'text-align-justify',
    bin: 'delete',
    pencil: 'edit',
    valign: 'image-valign',
    ok: 'completed-tick-icon',
    fullsize: 'icon-fullscrenn',
    shrink: 'icon-shrink',
    dots: 'icon-three-dot',
    table: 'table',
    eraser: 'eraser_tool',
    eye: 'eye_show',
    file: 'attach-file',
    unlink: 'unlink',
};

export const FILE_UPLOAD_ERROR_CODES = {
    GENERAL: 'UPLOAD_ERR_GENERAL',
    FILE_SIZE_EXCEEDED: 'UPLOAD_ERR_FILE_SIZE_EXCEEDED',
    UNSUPPORTED_FORMAT: 'UPLOAD_ERR_UNSUPPORTED_FORMAT',
};
