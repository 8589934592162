import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import { LOG_LEVEL } from 'utils/constants/logger';
import { emptyObject } from 'utils/constants/common';

const ShowOnStageDataWriter = ({
    airmeetId,
    client,
    payload,
    metaData,
    logger,
}) => {
    const ShowOnStageLog = logger.init(
        'SHOW_ON_STAGE_DATA_WRITER',
        'green',
        LOG_LEVEL.INFO
    );

    const { ADD } = FEATURE_ACTIONS[FEATURE_NAMES.SHOW_ON_STAGE];
    const { action, stageId } = metaData;

    if (!airmeetId) {
        throw new Error('AirmeetId is required to show on stage.');
    }

    if (!stageId) {
        throw new Error('StageId is required to show on stage.');
    }

    if (!action) {
        throw new Error('No action is specified to show on stage.');
    }

    const _path = `${airmeetId}/meta-data/sessions/${stageId}/showOnStage`;

    const { featureName } = payload || emptyObject;

    const logMessage =
        action === ADD
            ? `Showing ${
                  featureName || 'content'
              } on stage for session ${stageId}.`
            : `Removing content from the stage for session ${stageId}`;

    ShowOnStageLog(logMessage);

    /*
     Showing/Removing CHAT/QUESTION/POLL on/from stage. 
    */
    return client.setDataAsync(_path, payload);
};

export default ShowOnStageDataWriter;
