import createReducer from '../../utils/createReducer';
import {
    AIRMEET_SESSION_START_RECORDING_RESPONSE,
    AIRMEET_SESSION_STOP_RECORDING,
} from '../actions/airmeet';
import { logger } from 'utils/logger';

let recording;

try {
    recording = localStorage.getItem('recoarding')
        ? JSON.parse(localStorage.getItem('recoarding'))
        : {};
} catch {
    logger.info('localStorage is disabled');
    recording = {};
}
const initialState = {
    recording: recording,
};

const handlers = {};
handlers[AIRMEET_SESSION_START_RECORDING_RESPONSE] = (
    state = initialState,
    action
) => {
    if (!action.payload) {
        return state;
    }
    const recording = action.payload;
    //localStorage.setItem('recoarding', JSON.stringify(recording));
    return { ...state, recording };
};

handlers[AIRMEET_SESSION_STOP_RECORDING] = (state = initialState, action) => {
    if (!action.payload) {
        return state;
    }
    // Reset the recroding stats
    return { ...state, recording: {} };
};

export default createReducer(initialState, handlers);
