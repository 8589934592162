const moderationToggleLevel = ({
    airmeetId,
    client,
    payload,
    metaData,
    logger,
}) => {
    logger.info('changing chat moderation level');
    const fireBaseKey = metaData?.chatLevelDataPath;
    return client.setData(fireBaseKey, payload);
};

export default moderationToggleLevel;
