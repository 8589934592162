import firebase from 'firebase/app';

const AIRMEET_ID_REGEX = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i;
function convertChannelToRef(
    channel: string,
    client: firebase.firestore.Firestore
): firebase.firestore.DocumentReference {
    const channelParts = channel.split('/');
    const base = channelParts[0]; // TODO: could be airmeet id or something else
    let airmeetId = null;
    if (AIRMEET_ID_REGEX.test(base)) {
        airmeetId = base;
    }
    if (airmeetId) {
        return featureSpecificRef(channelParts, client);
    }
    return null;
}

function featureSpecificRef(
    channelParts: Array<string>,
    client: firebase.firestore.Firestore
): firebase.firestore.DocumentReference {
    const featureName = channelParts[1];
    switch (featureName) {
        case 'socialLounge':
            return socialLoungeRef(channelParts, client);
    }
    return null;
}

function socialLoungeRef(
    channelParts: Array<string>,
    client: firebase.firestore.Firestore
): firebase.firestore.DocumentReference {
    // /airmeetId/socialLounge/loungeId/tables/tableId
    const loungeFeature = channelParts[3];
    if ('tables' === loungeFeature) {
        const tableId = channelParts[4];
        if (!tableId) {
            return null;
        }
        return client.collection('tables').doc(tableId);
    }
    return null;
}

export default convertChannelToRef;
