import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { SEGMENTS } from './config';

function createScriptAndAppendToBody(id) {
    // check for existing, if none exits then create and append to body

    // cleanup previous and existing noscripts with similar ids
    const noscript = document.createElement('noscript');
    noscript.setAttribute('id', id);
    const iframe = document.createElement('iframe');
    iframe.setAttribute(
        'src',
        `https://www.googletagmanager.com/ns.html?id=${id}`
    );
    iframe.setAttribute('height', '0');
    iframe.setAttribute('width', '0');
    iframe.setAttribute('style', 'display: none, visibility: hidden');
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);
}

function createScriptAndAppendToHeader(id) {
    const script = document.createElement('script');
    const inlineScript = document.createTextNode(`(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.defer = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${id}');`);
    script.setAttribute('id', `script-${id}`);
    script.appendChild(inlineScript);
    document.head.appendChild(script);
}

function removeElementsByIds() {
    const ids = Object.values(SEGMENTS).map((segment) => segment.id);
    const withScriptIds = ids.map((id) => `script-${id}`);
    const finalIds = [...ids, ...withScriptIds];

    for (let i = 0; i < finalIds.length; i += 1) {
        document.getElementById(finalIds[i]) &&
            document.getElementById(finalIds[i]).remove();

        document.querySelector(
            `[src="https://www.googletagmanager.com/gtm.js?id=${finalIds[i]}"]`
        ) &&
            document
                .querySelector(
                    `[src="https://www.googletagmanager.com/gtm.js?id=${finalIds[i]}"]`
                )
                .remove();
    }
}

function getIdByRoute(pathname) {
    const SIGNUP_SEGMENT_ID = 'GTM-MSQNLTG';
    const NON_SIGNUP_SEGMENT_ID = 'GTM-KCR76K3';

    const signupMatch = matchPath(pathname, {
        path: '/signin',
        exact: true,
        strict: true,
    });

    if (!isEmpty(signupMatch) && signupMatch.isExact) {
        return SIGNUP_SEGMENT_ID;
    }

    return NON_SIGNUP_SEGMENT_ID;
}

function useGTMSegmentation() {
    const location = useLocation();

    useEffect(() => {
        const segmentId = getIdByRoute(location.pathname);
        removeElementsByIds();
        createScriptAndAppendToHeader(segmentId);
        createScriptAndAppendToBody(segmentId);
    }, [location.pathname]);
}

export default useGTMSegmentation;
