import { Message } from '../types';
import DataObserverBase from './DataObserverBase';

class TableDataObserver extends DataObserverBase {
    public loungeId: string;
    public tableCode: string;

    subscribe(onData: Function, metaData: any) {
        const onSnapshot = (message: Message) => {
            onData(message);
        };

        if (!metaData.loungeId || !metaData.loungeNode || !metaData.tableCode) {
            return () => {};
        }

        const channel = `${this.airmeetId}/${metaData.loungeNode}/${metaData.loungeId}/tables/${metaData.tableCode}`;
        const dataSourceUnsubscribe = super.subscribeImpl(channel, {
            onSnapshot,
        });

        return () => {
            dataSourceUnsubscribe && dataSourceUnsubscribe();
        };
    }
}

export default TableDataObserver;
