import FormField from 'components/community/common/FormField';
import styled from 'styled-components';

export const RoundedInput = styled(FormField)`
    flex-grow: 1;
    &.rounded-input {
        height: 32px;
        border-radius: 50px;
        border: 1px solid #302e38;
        padding: 0 0 0 30px;
        align-items: center;
        display: flex;
        background-color: ${({ theme }) => theme.colors.ambience[22]};
    }
    input {
        font-size: 14px;
        line-height: 16px;
        padding: 0;
        background-color: transparent !important;
    }
`;
