import useWhiteLabelling from 'hooks/useWhitelabelling';
import React from 'react';
import LiveEventPrompt from '../modals/LiveEventPrompt';

function JoinFromOtherPlace({ airmeetId }) {
    const { isWhiteLabellingEnabled } = useWhiteLabelling();
    const message = `You have been disconnected here as your invitation link was used to join this event from another tab.`;
    const prosp = {
        heading: `Event alert`,
        message: message,
        resolveText: 'Ok',
        rejectEnable: false,
        onClick: () => {
            if (isWhiteLabellingEnabled) {
                return window.location.replace(`/e/${airmeetId}?preview=true`);
            }
            window.location.replace(`/`);
        },
    };
    return <LiveEventPrompt {...prosp}>{message}</LiveEventPrompt>;
}

export default JoinFromOtherPlace;
