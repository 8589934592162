import { useContainerNotifications } from 'atoms/Container/container';
import { CLOSE_BREAKOUT_CREATION_OVERLAY } from 'components/StageRoom/BreakoutRooms/constants';
import { MODAL_TYPE_LIVE_PROMPT } from 'components/modals/constants';
import { getStageFirebaseClient } from 'context/LiveAirmeet';
import { useManageCoHostContext } from 'context/ManageCoHost';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeAppModal } from 'store/actions/layout';
import {
    SESSION_STATUS_FINISHED,
    SESSION_STATUS_PAUSED,
} from 'store/constants';
import { states } from './useSessionLiveAction';
const useEventSessionStop = ({ airmeetId, session, onSessionStop }) => {
    const sessionId = session ? session.sessionid : null;
    const firebaseClient = useLiveAirmeetContext(getStageFirebaseClient);
    const { setShowManageCoHostModal } = useManageCoHostContext();
    const emitter = firebaseClient.getEmitter();
    const dispatch = useDispatch();
    const { notify } = useContainerNotifications();

    const onUpdateValue = useCallback(
        ({ airmeetId, sessionId, status }) => {
            if ([states.stop, states.pause].includes(status)) {
                onSessionStop({
                    airmeetId,
                    sessionId,
                    status:
                        status === states.stop
                            ? SESSION_STATUS_FINISHED
                            : SESSION_STATUS_PAUSED,
                    hasEnded: status === states.stop,
                });
                // Close stage and manage cohost prompt if open
                dispatch(closeAppModal(MODAL_TYPE_LIVE_PROMPT));
                notify(CLOSE_BREAKOUT_CREATION_OVERLAY);
                setShowManageCoHostModal(false);
            }
        },
        [onSessionStop, setShowManageCoHostModal, dispatch, notify]
    );

    useEffect(() => {
        const onValueChanged = ({ value }) => {
            onUpdateValue({ airmeetId, sessionId, status: value });
        };
        if (sessionId) {
            const key = `${airmeetId}/meta-data/sessions/${sessionId}/liveStatus`;
            const statusKey = `${key}/status`;
            firebaseClient.getDataRef(statusKey);
            emitter.on(`${statusKey}_value`, onValueChanged);
            return () => {
                emitter.off(`${statusKey}_value`, onValueChanged);
            };
        }
    }, [sessionId, airmeetId, emitter, firebaseClient, onUpdateValue]);

    return null;
};

export default useEventSessionStop;
