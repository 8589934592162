import { isCallable } from 'utils/common';
import { logger } from 'utils/logger';
import { Message } from '../types';
import DataObserverBase from './DataObserverBase';

const ORDER_BY = 'createdAt';
class ChatObserver extends DataObserverBase {
    subscribe(onData: Function, metaData: any) {
        // TODO: State management should be part of listener, it should prepare the final object and send to on data when ready
        const onAdd = (message: Message) => {
            metaData.onAdd(message);
        };

        const onChange = (message: Message) => {
            metaData.onChange(message);
        };

        const onRemove = (message: Message) => {
            if (isCallable(metaData.onRemove)) {
                metaData.onRemove(message);
            }
        };

        metaData.txnId = metaData.txnId || '';

        let isActive = true;
        let dataSourceUnsubscribe;
        this.fetch((data) => {
            if (!isActive) {
                return;
            }
            metaData.onInit(data);
            dataSourceUnsubscribe = super.subscribeImpl(
                this.getChannelName(metaData),
                {
                    onAdd,
                    onChange,
                    onRemove,
                },
                {
                    orderBy: ORDER_BY,
                    onRemoveLimit: metaData.onRemoveLimit,
                }
            );
        }, metaData);

        return () => {
            isActive = false;
            dataSourceUnsubscribe && dataSourceUnsubscribe();
        };
    }

    getChannelName(metadata: any) {
        if (!metadata.channel) {
            throw new Error('Cannot be empty channel');
        }
        return metadata.channel;
        // TODO: Use feature specific channel name
        // switch (featureName) {
        //     case FEATURE_NAMES.EVENT_CHAT:
        //         return `${this.airmeetId}/chats/General`;
        //     case FEATURE_NAMES.SESSION_CHAT:
        //         return '';
        //     case FEATURE_NAMES.STAGE_CHAT:
        //         return '';
        //     case FEATURE_NAMES.DM_CHAT:
        //         return '';
        //     case FEATURE_NAMES.SESSION_CHAT_MODERATION:
        //         return '';
        //     case FEATURE_NAMES.MODERATION_TOGGLE_LEVEL:
        // }
    }

    fetch(onData: Function, metadata?: any): void {
        super
            .fetchImpl({
                orderBy: ORDER_BY,
                pageSize: 100,
                pageNumber: -1,
                metadata: {
                    channel: this.getChannelName(metadata),
                },
            })
            .then((data) => onData(data))
            .catch(logger.error);
    }
}

export default ChatObserver;
