import { SESSION_ACCESSIBILITY_INFO_RESPONSE } from 'store/actions/sessionAccessible.js';

const initialState = {
    sessionAccess: {},
};

function sessionAccessible(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case SESSION_ACCESSIBILITY_INFO_RESPONSE:
            return {
                ...state,
                sessionAccess: { ...payload },
            };
        default:
            return state;
    }
}

export default sessionAccessible;
