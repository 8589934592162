import Checkbox from 'atoms/Checkbox/Checkbox';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import RelativeToolTip from 'components/tooltip/relativeToolTip';
import { Typography } from 'foundations/typography';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useMemo, useState } from 'react';
import BarChart from '../components/BarChart';
import ChartCard from '../components/ChartCard';
import StatBox from '../components/StatBox';
import { getEmptyBannerCommonProps } from '../utils';

const BACKGROUND_COLORS = [
    '#9580FF',
    '#EE51AF',
    '#3EA5FC',
    '#22aa99',
    '#994499',
    '#316395',
    '#b82e2e',
];

export default function TicketsCard({
    loading,
    data,
    eventStarted,
    airmeetFormat,
}) {
    const [selectedTickets, setSelectedTickets] = useState([]);

    const { ticketDataset, countryKeys, isEmptyData } = useMemo(() => {
        const countryKeys = [];
        const ticketDataset = {};

        const orderedData = orderBy(
            Object.keys(data || {}).map((key) => ({ key, ...data[key] })),
            ['count', 'key'],
            ['desc', 'asc']
        );

        const isEmptyData =
            !Array.isArray(orderedData) || orderedData.length === 0;

        if (!isEmptyData) {
            const ticketTypes = new Set();
            orderedData.forEach((item) => {
                Object.keys(item?.tiers || {}).forEach((key) => {
                    ticketTypes.add(key);
                });
            });

            if (ticketTypes.size > 0) {
                orderedData.forEach((item) => {
                    countryKeys.push(item.key);
                    ticketTypes.forEach((type) => {
                        if (!Array.isArray(ticketDataset[type])) {
                            ticketDataset[type] = [];
                        }
                        ticketDataset[type].push((item?.tiers || {})[type]);
                    });
                });
            }
        }

        return { ticketDataset, countryKeys, isEmptyData };
    }, [data]);

    const handleClick = ({ name, value }) => {
        let newSelectedTickets = [...(selectedTickets || [])];
        if (value) {
            newSelectedTickets.push(name);
        } else {
            newSelectedTickets = newSelectedTickets.filter(
                (val) => name !== val
            );
        }
        setSelectedTickets(newSelectedTickets);
    };

    const ticketNames = useMemo(() => Object.keys(ticketDataset || {}), [
        ticketDataset,
    ]);
    useEffect(() => {
        setSelectedTickets(ticketNames);
    }, [ticketNames]);

    const datasets = useMemo(
        () =>
            isEmptyData
                ? []
                : (ticketNames || []).map((key, index) => ({
                      label: key,
                      data: ticketDataset[key],
                      backgroundColor:
                          BACKGROUND_COLORS[index % BACKGROUND_COLORS.length],
                      hidden: !selectedTickets.includes(key),
                  })),
        [selectedTickets, ticketDataset, isEmptyData, ticketNames]
    );

    return (
        <ChartCard
            title={
                <FlexDiv alignItems='center'>
                    Ticket Distribution (By Location)
                    <RelativeToolTip
                        place='top'
                        svgWidth='13px'
                        svgHeight='13px'
                        svgIcon='icon-fill-info'
                        svgFill='accentPrimary.0'
                        tooltipText='This section lets you see how many attendees belonging to a ticket type came from a particular location.'
                        textColor='ambience.18'
                        textVariant='caption'
                        minWidth={290}
                        style={{ marginLeft: '10px' }}
                        showStyledSvgSprite={true}
                    />
                </FlexDiv>
            }
            loading={loading}
            isEmpty={isEmptyData}
            emptyBannerProps={getEmptyBannerCommonProps({
                eventStarted,
                airmeetFormat,
            })}
        >
            <FlexDiv
                flexDirection='column'
                flexGrow={1}
                style={{ minWidth: 0, overflow: 'hidden' }}
            >
                <FlexDiv>
                    {countryKeys.slice(0, 6).map((key) => (
                        <StatBox
                            value={data[key]?.count}
                            title={key}
                            key={key}
                        />
                    ))}
                </FlexDiv>
                <FlexDiv alignItems='stretch' justifyContent='space-between'>
                    <FlexDiv flexGrow='1'>
                        <BarChart
                            isEmpty={isEmptyData}
                            chartLabels={countryKeys}
                            datasets={datasets}
                            displayLegend={false}
                            options={{
                                responsive: true,
                                legend: {
                                    display: false,
                                },
                                scales: {
                                    xAxes: [
                                        {
                                            stacked: true,
                                        },
                                    ],
                                    yAxes: [
                                        {
                                            stacked: true,
                                        },
                                    ],
                                },
                            }}
                        />
                    </FlexDiv>
                    <FlexDiv flexDirection='column'>
                        {ticketNames.map((label, index) => (
                            <FlexDiv alignItems='center' key={label}>
                                <Checkbox
                                    filledSvgColor={BACKGROUND_COLORS[index]}
                                    onClick={handleClick}
                                    isChecked={(selectedTickets || []).includes(
                                        label
                                    )}
                                    name={label}
                                    iconSize='20px'
                                />
                                <Typography
                                    variant='caption'
                                    color='ambience.6'
                                    pl={1}
                                    style={{
                                        fontSize: '10px',
                                        lineHeight: '12px',
                                    }}
                                >
                                    {label}
                                </Typography>
                            </FlexDiv>
                        ))}
                    </FlexDiv>
                </FlexDiv>
            </FlexDiv>
        </ChartCard>
    );
}
