import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
const { SESSION_CHAT } = FEATURE_NAMES;

const sessionChatWriter = ({
    airmeetId,
    sessionId,
    client,
    payload,
    metaData,
    logger,
}) => {
    if (!sessionId) {
        throw new Error('Invalid Session chat key');
    }
    const fireBaseKey = `${airmeetId}/liveAirmeet/sessions/${sessionId}/messages`;

    const handleAddmessage = () => {
        logger.info('Sending Session Chat');
        if (metaData?.key) {
            return client.setDataAsync(
                `${fireBaseKey}/${metaData.key}`,
                payload
            );
        }
        return client.pushData(fireBaseKey, payload);
    };

    const handleRemoveMessage = () => {
        logger.info('Removing Session Message');
        if (!metaData?.chatKey || !metaData.userId) {
            throw new Error('Invalid remove message firebase key');
        }
        return client.setData(`${fireBaseKey}/${metaData.chatKey}`, null);
    };

    switch (metaData?.action) {
        case FEATURE_ACTIONS[SESSION_CHAT].ADD_MESSAGE:
            return handleAddmessage();
        case FEATURE_ACTIONS[SESSION_CHAT].REMOVE_MESSAGE:
            return handleRemoveMessage();
        default:
            throw new Error('Invalid Session Chat action');
    }
};

export default sessionChatWriter;
