import { bindModuleLogger } from 'utils/loggers/localLogger';

const GROUP_MEETING_PREFIX: string = 'Group Meeting:: ';
const GroupMeetingLogger = (prefix: string = '', color = 'green') => {
    if (prefix) {
        prefix = `${GROUP_MEETING_PREFIX} ${prefix}`;
    } else {
        prefix = GROUP_MEETING_PREFIX;
    }
    return bindModuleLogger(prefix, color);
};

const gmLogger = GroupMeetingLogger();
const gmApiLogger = GroupMeetingLogger('Rest API: ');
const gmEventLogger = GroupMeetingLogger('Daily Event: ');
const gmRecordingLogger = GroupMeetingLogger('Recording: ');

export { gmLogger, gmApiLogger, gmEventLogger, gmRecordingLogger };

export default GroupMeetingLogger;
