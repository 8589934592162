import FormField from 'components/community/common/FormField';
import React from 'react';

export default function VenueCapacity({
    formData,
    errors,
    newOnBoardingFlow = false,
    pageMode = false,
    isEventEnded,
}) {
    const value = formData?.venue_capacity;

    return (
        <FormField
            id='venue_capacity'
            name='venue_capacity'
            label='Venue Capacity'
            subLabel='Maximum capacity of physical venue'
            placeholder='Enter venue capacity'
            filledValue={value}
            isError={errors.venue_capacity}
            errorMsg={errors.venue_capacity}
            variant='v2'
            newOnBoardingFlow={newOnBoardingFlow}
            inputValue={pageMode ? value : null}
            value={value}
            disabled={isEventEnded}
            className={isEventEnded ? 'form_disabled' : ''}
            min={0}
            isThemeV2={true}
        />
    );
}
