import { API_SERVICE } from '../../../utils/apiService';
import { CALL_API } from '../../api';

export const GET_TICKET_REQUEST = 'CM_DASHBOARD/GET_TICKET_REQUEST';
export const GET_TICKET_RESPONSE = 'CM_DASHBOARD/GET_TICKET_RESPONSE';
export const GET_COUPON_REQUEST = 'CM_DASHBOARD/GET_COUPON_REQUEST';
export const GET_COUPON_RESPONSE = 'CM_DASHBOARD/GET_COUPON_RESPONSE';
export const UPDATE_COUPON_REQUEST = 'CM_DASHBOARD/UPDATE_COUPON_REQUEST';
export const CREATE_TICKET_REQUEST = 'CM_DASHBOARD/CREATE_TICKET_REQUEST';
export const CREATE_TICKET_RESPONSE = 'CM_DASHBOARD/CREATE_TICKET_RESPONSE';
export const UPDATE_TICKET_REQUEST = 'CM_DASHBOARD/UPDATE_TICKET_REQUEST';
export const UPDATE_TICKET_RESPONSE = 'CM_DASHBOARD/UPDATE_TICKET_RESPONSE';
export const DELETE_TICKET_REQUEST = 'CM_DASHBOARD/DELETE_TICKET_REQUEST';
export const DELETE_TICKET_RESPONSE = 'CM_DASHBOARD/DELETE_TICKET_RESPONSE';
export const REORDER_TICKET_REQUEST = 'REORDER_TICKET_REQUEST';
export const REORDER_TICKET_RESPONSE = 'REORDER_TICKET_RESPONSE';
export const GET_CURRENCY_TICKET_REQUEST = 'GET_CURRENCY_TICKET_REQUEST';
export const GET_PRICING_BREAKDOWN_DETAILS_REQUEST =
    'GET_PRICING_BREAKDOWN_DETAILS_REQUEST';

export const GET_STRIPE_KYC_STATUS_REQUEST = 'GET_STRIPE_KYC_STATUS_REQUEST';
export const GET_STRIPE_KYC_STATUS_RESPONSE = 'GET_STRIPE_KYC_STATUS_RESPONSE';

export const GET_STRIPE_OAUTH_REDIRECT_REQUEST =
    'GET_STRIPE_OAUTH_REDIRECT_REQUEST';
export const GET_STRIPE_OAUTH_REDIRECT_RESPONSE =
    'GET_STRIPE_OAUTH_REDIRECT_RESPONSE';

export const GET_STRIPE_CONNECT_LINK_REQUEST =
    'GET_STRIPE_CONNECT_LINK_REQUEST';
export const GET_STRIPE_CONNECT_LINK_RESPONSE =
    'GET_STRIPE_CONNECT_LINK_RESPONSE';

export function getTickets({ airmeetId = '' }) {
    return {
        [CALL_API]: {
            types: [GET_TICKET_REQUEST, GET_TICKET_RESPONSE],
            endpoint: `/ticket/detailed?catalogueId=${airmeetId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function fetChCouponsData(airmeetId) {
    return {
        [CALL_API]: {
            types: [GET_COUPON_REQUEST, GET_COUPON_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/coupons_detailed`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function getTicket({ ticketId }) {
    return {
        [CALL_API]: {
            types: [GET_TICKET_REQUEST, GET_TICKET_RESPONSE],
            endpoint: `/ticket${ticketId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function createTicket({ ticketData }) {
    return {
        [CALL_API]: {
            types: [CREATE_TICKET_REQUEST],
            endpoint: `/ticket`,
            body: ticketData,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}

export function deleteTicket({ ticketId }) {
    return {
        [CALL_API]: {
            types: [DELETE_TICKET_REQUEST, DELETE_TICKET_RESPONSE],
            endpoint: `/ticket/${ticketId}`,
            type: 'json',
            method: 'DELETE',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateTicket({ ticketData }) {
    const ticketId = ticketData ? ticketData.uuid : null;
    return {
        [CALL_API]: {
            types: [UPDATE_TICKET_REQUEST, UPDATE_TICKET_RESPONSE],
            endpoint: `/ticket/?ticketId=${ticketId}`,
            body: ticketData,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateCoupon({ coupon, airmeetId, couponId }) {
    return {
        [CALL_API]: {
            types: [UPDATE_COUPON_REQUEST],
            endpoint: `/airmeet/${airmeetId}/coupon/${couponId}`,
            body: coupon,
            type: 'json',
            method: 'PUT',
            service: API_SERVICE.BFF,
        },
    };
}

export function reorderTickets({ airmeetId, reorderedData }) {
    return {
        [CALL_API]: {
            types: [REORDER_TICKET_REQUEST],
            endpoint: `/tickets?catalogueId=${airmeetId}`,
            service: API_SERVICE.BFF,
            body: reorderedData,
            type: 'json',
            method: 'PUT',
        },
    };
}

export function getCurrencyDetails({ communityId }) {
    return {
        [CALL_API]: {
            types: [GET_CURRENCY_TICKET_REQUEST],
            endpoint: `/currencies/${communityId}`,
            service: API_SERVICE.BFF,
            type: 'json',
            method: 'GET',
        },
    };
}

export function getPricingBreakDownDetails({ priceData }) {
    return {
        [CALL_API]: {
            types: [GET_PRICING_BREAKDOWN_DETAILS_REQUEST],
            endpoint: '/price/breakup',
            service: API_SERVICE.BFF,
            body: priceData,
            type: 'json',
            method: 'POST',
        },
    };
}
export function checkStripeKycStatus(communityId) {
    return {
        [CALL_API]: {
            types: [
                GET_STRIPE_KYC_STATUS_REQUEST,
                GET_STRIPE_KYC_STATUS_RESPONSE,
            ],
            endpoint: `/kyc/stripe/account/${communityId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function generateStripeConnectLink(data) {
    return {
        [CALL_API]: {
            types: [
                GET_STRIPE_CONNECT_LINK_REQUEST,
                GET_STRIPE_CONNECT_LINK_RESPONSE,
            ],
            body: data,
            endpoint: `/kyc/stripe/account/link`,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}

export function getStripeOAuthRedirect(data) {
    return {
        [CALL_API]: {
            types: [
                GET_STRIPE_OAUTH_REDIRECT_REQUEST,
                GET_STRIPE_OAUTH_REDIRECT_RESPONSE,
            ],
            body: data,
            endpoint: `/kyc/stripe/oauth/redirect`,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}
