import { BLOCKED_USER_LOCALSTORAGE_KEY } from 'utils/constants/access';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import FirebaseLogger from 'utils/firebaseLogger';
import { logger } from 'utils/logger';

export const logEnteredEvent = (eventStatus, source, data = {}) => {
    let localStorageData = '';
    try {
        localStorageData = localStorage.getItem(BLOCKED_USER_LOCALSTORAGE_KEY);
    } catch (e) {
        logger.error('AnalyticsLog: Error fetching blocked user data : ', e);
    }
    const [blockedUserId, blockedAirmeetId] = (localStorageData || '').split(
        ':::'
    );
    if (eventStatus === AIRMEET_STATUS.PRE_EVENT_ACCESS) {
        FirebaseLogger.preEventAndSocialShare('entered_pre_event', {
            source,
            wasBlocked: data.attendeeId && data.airmeetId === blockedAirmeetId,
            blockedUserId: blockedUserId || null,
            ...data,
        });
    } else {
        FirebaseLogger.enteredAirmeet({
            source,
            wasBlocked: data.attendeeId && data.airmeetId === blockedAirmeetId,
            blockedUserId: blockedUserId || null,
            ...data,
        });
    }
};
