import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    ADD_SCRIPT,
    fsScriptClassname,
    isOnOrganizerFlowBeforeCommunityCreation,
    REMOVE_SCRIPT,
    scriptOperation,
    fullStoryInlineConfigScriptSnippet,
} from './utils';
import isFunction from 'lodash/isFunction';

export default function useInitFullStory() {
    const { cm, em } = useSelector((state) => state?.Community?.myCommunity);
    const user = useSelector((state) => state.auth.user);
    const location = useLocation();
    const isFullstoryRecordingEnabled = useSelector(
        ({ genral }) => genral.isFullstoryRecordingEnabled
    );

    useEffect(() => {
        const configScript = document.createElement('script');
        configScript.className = fsScriptClassname;

        configScript.appendChild(
            document.createTextNode(fullStoryInlineConfigScriptSnippet)
        );

        // Conditions for script enabling
        const isCommunityOrEventManager = cm?.length > 0 || em?.length > 0;

        if (
            process.env.REACT_APP_USE_FULLSTORY_RECORDINGS === 'true' &&
            (isCommunityOrEventManager ||
                isOnOrganizerFlowBeforeCommunityCreation(location.pathname) ||
                isFullstoryRecordingEnabled)
        ) {
            scriptOperation(ADD_SCRIPT, configScript);
        } else if (window.FS && isFunction(window.FS.shutdown)) {
            // Stops recording the session
            // Does not remove script
            window.FS.shutdown(); // idempotent
            if (location.pathname.indexOf('/event/') === -1)
                scriptOperation(REMOVE_SCRIPT);
        }
    }, [cm, em, location.pathname, isFullstoryRecordingEnabled]);

    useEffect(() => {
        if (window.FS && isFunction(window.FS.identify) && user?.id) {
            window.FS.identify(user.id);
        }
    }, [user?.id]);
}
