import { REPLAY_ENTRY } from 'components/community/editPublicAirmeet/PostEventEntry/constants.js';
import withErrorBoundary, {
    DashboardModules,
} from 'components/hocs/withErrorBoundary';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import { AnalyticsContext } from '../AnalyticsProvider';
import PostEventTabEmpty from './PostEventTabEmpty';
import RegistrationsCard from './RegistrationsCard';
import TopAttendeesCard from './TopAttendeesCard';
import TrafficSourcesCard from './TrafficSourcesCard';
import ViewerTypesCard from './ViewerTypesCard';
import ViewersDistributionCard from './ViewersDistributionCard';
import ViewingTimeStatCards from './ViewingTimeStatCards';
import { TextSelect } from './styledComponents';
import { POST_EVENT_DEFAULT_SESSION_OPTION } from './utils/';

function PostEventTab() {
    const { logEvent, airmeetId } = useContext(AnalyticsContext);

    const [selectedSession, setSelectedSession] = useState(
        POST_EVENT_DEFAULT_SESSION_OPTION
    );
    const sessions =
        useSelector((state) => state?.cmDashboard?.airmeet?.sessions) || [];

    const sessionOptions = useMemo(() => {
        return [
            POST_EVENT_DEFAULT_SESSION_OPTION,
            ...(sessions || []).map((s) => ({
                value: s?.sessionid,
                label: s?.name,
            })),
        ];
    }, [sessions]);

    const handleSessionFilterChange = (option) => {
        logEvent &&
            logEvent('sessionFilterChange', {
                prevOption: selectedSession,
                option,
            });
        setSelectedSession(option);
    };

    return (
        <FlexDiv flexDirection='column' alignItems='stretch' gap='1.5rem'>
            <FlexDiv flexDirection='column' alignItems='stretch' gap='1rem'>
                <Text variant='h4' color='text.default.primary'>
                    Registration data
                </Text>
                <RegistrationsCard logEvent={logEvent} airmeetId={airmeetId} />
                <TrafficSourcesCard logEvent={logEvent} airmeetId={airmeetId} />
            </FlexDiv>
            <FlexDiv flexDirection='column' alignItems='stretch' gap='0.25rem'>
                <Text variant='h4' color='text.default.primary'>
                    Viewership data
                </Text>
                <FlexDiv alignItems='center'>
                    <Text variant='caption' color='text.default.secondary'>
                        Show data for
                    </Text>
                    <TextSelect
                        options={sessionOptions}
                        hideIndicatorSeparator={true}
                        value={selectedSession}
                        onChange={handleSessionFilterChange}
                        hasBorder={false}
                        flex='1'
                        isSearchable={false}
                        minWidth='130px'
                    />
                </FlexDiv>
            </FlexDiv>
            <FlexDiv flexDirection='column' alignItems='stretch' gap='1rem'>
                <FlexDiv alignItems='stretch' gap='1rem'>
                    <ViewerTypesCard
                        airmeetId={airmeetId}
                        sessionId={
                            selectedSession?.value !==
                            POST_EVENT_DEFAULT_SESSION_OPTION?.value
                                ? selectedSession?.value
                                : null
                        }
                    />
                    <ViewersDistributionCard
                        airmeetId={airmeetId}
                        sessionId={
                            selectedSession?.value !==
                            POST_EVENT_DEFAULT_SESSION_OPTION?.value
                                ? selectedSession?.value
                                : null
                        }
                    />
                </FlexDiv>
                <ViewingTimeStatCards
                    airmeetId={airmeetId}
                    sessionId={
                        selectedSession?.value !==
                        POST_EVENT_DEFAULT_SESSION_OPTION?.value
                            ? selectedSession?.value
                            : null
                    }
                />
            </FlexDiv>
            <TopAttendeesCard
                logEvent={logEvent}
                sessionId={
                    selectedSession?.value !==
                    POST_EVENT_DEFAULT_SESSION_OPTION?.value
                        ? selectedSession?.value
                        : null
                }
            />
        </FlexDiv>
    );
}

function PostEventTabWrapper() {
    const { eventStatus, post_event_entry } = useContext(AnalyticsContext);

    if (eventStatus !== AIRMEET_STATUS.FINISHED) {
        return (
            <PostEventTabEmpty
                title='Your event has not ended yet'
                description='Event replay analytics will be available once you end the event and enable event replay.'
            />
        );
    }

    const isPostEventReplayEnabled =
        post_event_entry && post_event_entry !== REPLAY_ENTRY.nobody;

    if (!isPostEventReplayEnabled) {
        return (
            <PostEventTabEmpty
                title='Event replay is disabled'
                description='Enable event replay to generate more leads. You can view the analytics here once the replay is enabled.'
                showCTA
            />
        );
    }

    return <PostEventTab />;
}

export default withErrorBoundary(
    PostEventTabWrapper,
    DashboardModules.ANALYTICS_POST_EVENT
);
