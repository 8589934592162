import Button from 'atoms/Button/button';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import React from 'react';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';

const DeletePopup = ({
    onCancel = () => {},
    onDelete = () => {},
    message,
    title,
    position,
    backdropHeight = null,
    backdropStyle = {},
    onBackdropClick = noop,
    deleteStyle = {},
    cancelStyle = {},
    style = {},
    deleteText = 'Delete',
    loading = false,
    version = 1,
    arrowColor = 'white',
}) => {
    const { theme } = useTheme();
    let setPosition = '';
    switch (position) {
        case 'bottomCenter':
            setPosition = 'position-bottom-center';
            break;
        case 'bottomRight':
            setPosition = 'position-bottom-right';
            break;
        case 'bottomLeft':
            setPosition = 'position-bottom-left';
            break;
        case 'topRight':
            setPosition = 'position-top-right';
            break;
        case 'topLeft':
            setPosition = 'position-top-left';
            break;
        default:
            break;
    }

    return (
        <>
            <Container
                className={`cd-popup-container ${setPosition}`}
                style={style || {}}
                arrowColor={arrowColor}
            >
                <div className='inner'>
                    <StyledTypo variant={'subtext2-bold'} m={'0px !important'}>
                        {title || 'Confirm Deletion'}
                    </StyledTypo>
                    <StyledTypo variant={'subtext2'}>
                        {message ||
                            'Are you sure you want to delete this? You won’t be able to recover it.'}
                    </StyledTypo>
                    {version === 2 ? (
                        <FlexDiv alignItems='center'>
                            <Button
                                variant='link'
                                color={
                                    theme.isLightTheme
                                        ? theme.colors.ambience[2]
                                        : theme.colors.ambience[21]
                                }
                                onClick={onCancel}
                                style={cancelStyle || {}}
                                disabled={loading}
                                mr='10px'
                            >
                                Cancel
                            </Button>
                            <DeleteButtonV2
                                loading={loading}
                                onClick={onDelete}
                                style={deleteStyle || {}}
                                variant='secondary'
                            >
                                {deleteText}
                            </DeleteButtonV2>
                        </FlexDiv>
                    ) : (
                        <div>
                            <CancelButton
                                onClick={onCancel}
                                style={cancelStyle || {}}
                                disabled={loading}
                            >
                                Cancel
                            </CancelButton>
                            <DeleteButton
                                onClick={onDelete}
                                style={deleteStyle || {}}
                                disabled={loading}
                            >
                                {deleteText}
                            </DeleteButton>
                        </div>
                    )}
                </div>
            </Container>
            <div
                className='cd-backdrop'
                style={{
                    height: backdropHeight ? backdropHeight : '100%',
                    backgroundColor: theme?.isLightTheme
                        ? 'rgba(241,241,244,0.8)'
                        : 'rgba(0,0,0,0.8)',
                    ...(backdropStyle || {}),
                }}
                onClick={onBackdropClick}
            ></div>
        </>
    );
};

export default DeletePopup;

const StyledTypo = styled(Typography)`
    font-size: 13px !important;
    white-space: normal;
    color: ${({ theme }) =>
        theme.isLightTheme
            ? theme.colors.ambience[2]
            : theme.colors.ambience[21]};
`;

const Container = styled.div`
    .inner {
        &:after {
            background: ${({ arrowColor }) => arrowColor};
        }
    }
`;

const DeleteButton = styled.button`
    width: auto;
    height: 24px;
    padding: 0 10px;
    font-size: 1.125em;
    box-shadow: none !important;
    outline: 0 !important;
    color: ${({ theme }) =>
        theme.isLightTheme
            ? theme.colors.ambience[24]
            : theme.colors.ambience[0]};
    background: ${({ theme }) => theme.colors.semantic[3]};
    border: 0;
    border-radius: 8px;
    font-family: ${({ theme }) => theme.fonts.bold};
    &:hover {
        opacity: 0.8;
    }
`;

const CancelButton = styled(DeleteButton)`
    margin-right: 10px;
    color: ${({ theme }) =>
        theme.isLightTheme
            ? theme.colors.ambience[2]
            : theme.colors.ambience[21]};
    background: transparent;
    &:hover {
        opacity: 0.8;
    }
`;

const DeleteButtonV2 = styled(Button)`
    background: ${({ theme }) => theme.colors.semantic[3]};
    width: auto;
    height: auto;
    height: 24px;
    padding: 0 10px;
    font-size: 1.125em;
    display: flex;
    align-items: center;
    border: 0;
    p {
        margin: 0;
    }
`;
