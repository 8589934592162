import { noop } from 'utils/constants/common';
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const REMOVE_TOASTS_BY_GROUP = 'REMOVE_TOASTS_BY_GROUP';
export const REMOVE_ALL_TOASTS = 'REMOVE_ALL_TOASTS';

export const TOAST_TYPE_INFO = 'info';
export const TOAST_TYPE_ERROR = 'error';
export const TOAST_TYPE_WARNING = 'warning';
export const TOAST_TYPE_PRIMARY = 'primary';
export const TOAST_TYPE_SUCCESS = 'success';
export const TOAST_TYPE_CUSTOM = 'custom';

let id = 0;

function createToast(
    message,
    type,
    duration,
    closeButton,
    group,
    title,
    version,
    minHeight,
    width,
    renderElement,
    icon,
    startCount,
    endCount,
    iconPos,
    closeToastOnly,
    zIndex,
    positionTop,
    positionRight,
    positionLeft,
    padding,
    enableAnimations,
    resetStyleValues,
    descStyles
) {
    id += 1;
    return {
        message,
        id,
        type,
        duration,
        closeButton,
        group,
        title,
        version,
        minHeight,
        width,
        renderElement,
        icon,
        startCount,
        endCount,
        iconPos,
        closeToastOnly,
        zIndex,
        positionTop,
        positionRight,
        positionLeft,
        padding,
        enableAnimations,
        resetStyleValues,
        descStyles,
    };
}

export function addToast({
    type = TOAST_TYPE_WARNING,
    message = '',
    duration = 5000,
    closeButton = true,
    group,
    title = '',
    version = null,
    minHeight,
    width,
    renderElement = noop,
    icon = null,
    startCount = null,
    endCount = null,
    iconPos,
    closeToastOnly = true,
    resetStyleValues = false,
    zIndex,
    positionTop,
    positionRight,
    positionLeft,
    padding,
    enableAnimations,
    descStyles,
}) {
    return {
        payload: createToast(
            message,
            type,
            duration,
            closeButton,
            group,
            title,
            version,
            minHeight,
            width,
            renderElement,
            icon,
            startCount,
            endCount,
            iconPos,
            closeToastOnly,
            zIndex,
            positionTop,
            positionRight,
            positionLeft,
            padding,
            enableAnimations,
            resetStyleValues,
            descStyles
        ),
        type: ADD_TOAST,
    };
}

export function removeToast(toastId) {
    return {
        payload: toastId,
        type: REMOVE_TOAST,
    };
}

export function removeToastsByGroup(group) {
    return {
        payload: group,
        type: REMOVE_TOASTS_BY_GROUP,
    };
}

export function removeAllToasts() {
    return {
        payload: [],
        type: REMOVE_ALL_TOASTS,
    };
}
