export const ANNOUNCEMENT_JOIN = 'ANNOUNCEMENT_JOIN';
export const ANNOUNCEMENT_IS_LIVE = 'ANNOUNCEMENT_IS_LIVE';

export const joinLiveAnnouncement = (value) => ({
    type: ANNOUNCEMENT_JOIN,
    payload: value,
});

export const setAnnouncementLive = (value) => ({
    type: ANNOUNCEMENT_IS_LIVE,
    payload: value,
});
