import { Box } from 'components/molecules/utils/basicComponents';
import { alpha } from 'foundations/theme';
import { Typography } from 'foundations/typography';
import { default as React, useMemo, useRef, useState } from 'react';
import usePortal from 'react-cool-portal';
import styled from 'styled-components';
import { flexbox, layout, space } from 'styled-system';
import { emptyObject } from 'utils/constants/common';
import SVGSprite, { StyledSvgSprite } from '../general/SVGSprite';

export default function RelativeToolTip({
    textAlign,
    textVariant,
    textColor,
    lightThemeTextColor,
    place,
    svgWidth,
    svgHeight,
    tooltipText,
    renderToolTipText,
    svgIcon,
    svgFill,
    minWidth,
    children,
    variant = 'default',
    renderElement = null,
    svgStyle,
    isEmailTemplate = false,
    tooltipTopPosition = 'calc(100% + 24px)',
    tooltipContainerStyle = emptyObject,
    tooltipV2ContainerStyle = emptyObject,
    arrowPosition,
    portal = false,
    showStyledSvgSprite = false,
    transformStyle = 'translateY(-50%)',
    arrowPositionY = 'translateY(-50%)',
    tooltipArrowTop = '-9px',
    showTooltip = true,
    portalTop = -20,
    portalLeft = 15,
    containerId = null,
    backgroundColor,
    ...props
}) {
    const containerRef = useRef(null);
    const [hovered, setHovered] = useState(false);
    const { Portal } = usePortal({
        clickOutsideToHide: false,
        escToHide: false,
        containerId: containerId || 'root',
    });

    // Calculate tooltip position
    const containerRect = containerRef?.current?.getBoundingClientRect();

    const portalStyles = useMemo(() => {
        return portal
            ? {
                  position: 'fixed',
                  top: `${containerRect?.top + portalTop}px`,
                  left: `${containerRect?.left + portalLeft}px`,
              }
            : {};
    }, [portal, containerRect, portalLeft, portalTop]);

    const handleMouseEnter = () => {
        if (portal) setHovered(true);
    };

    const handleMouseLeave = () => {
        if (portal) setHovered(false);
    };

    let tooltipContent = null;
    if (variant === 'default') {
        tooltipContent = (
            <ToolTipContainer
                className='tooltip-text'
                tooltipTopPosition={tooltipTopPosition}
                arrowPosition={arrowPosition}
                portal={portal}
                hovered={hovered}
                place={place}
                style={{
                    minWidth: minWidth + 'px',
                    ...portalStyles,
                    ...(tooltipContainerStyle || {}),
                }}
                tooltipArrowTop={tooltipArrowTop}
                backgroundColor={backgroundColor}
            >
                <Typography
                    variant={textVariant}
                    color={textColor}
                    lightThemeColor={lightThemeTextColor}
                    style={{ textAlign: textAlign }}
                >
                    {tooltipText}
                </Typography>
                {renderElement ? renderElement : ''}
            </ToolTipContainer>
        );
    }

    return (
        <>
            {variant === 'default' && (
                <Span
                    className={`${place} tooltip-icon-wrapper`}
                    onClick={(e) => e.stopPropagation()}
                    tooltipTopPosition={tooltipTopPosition}
                    arrowPosition={arrowPosition}
                    ref={containerRef}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    {...props}
                >
                    {showStyledSvgSprite ? (
                        <StyledSvgSprite
                            icon={svgIcon}
                            height={svgHeight}
                            width={svgWidth}
                            fill={svgFill}
                            style={svgStyle}
                        />
                    ) : (
                        <SVGSprite
                            icon={svgIcon}
                            height={svgHeight}
                            width={svgWidth}
                            fill={svgFill}
                        />
                    )}
                    {portal ? (
                        <Portal>{tooltipContent}</Portal>
                    ) : (
                        tooltipContent
                    )}
                </Span>
            )}
            {variant === 'v2' && (
                <StyledBox
                    className={place}
                    onClick={(e) => e.stopPropagation()}
                    tooltipTopPosition={tooltipTopPosition}
                    transformStyle={transformStyle}
                    arrowPositionY={arrowPositionY}
                    isEmailTemplate={isEmailTemplate}
                >
                    {children}
                    {showTooltip && (
                        <ToolTipContainer
                            className='tooltip-text'
                            style={{
                                minWidth: minWidth + 'px',
                                ...tooltipV2ContainerStyle,
                            }}
                            tooltipArrowTop={tooltipArrowTop}
                        >
                            {renderToolTipText}
                        </ToolTipContainer>
                    )}
                </StyledBox>
            )}
        </>
    );
}
const StyledBox = styled(Box)`
    cursor: default !important;
    line-height: 0;
    position: relative;
    &.right {
        .tooltip-text {
            left: 32px;
            top: ${({ tooltipTopPosition }) => tooltipTopPosition};
            transform: ${({ transformStyle }) => transformStyle};
            &::after {
                right: unset;
                left: -10px;
                top: 50%;
                transform: ${({ arrowPositionY }) =>
                    `${arrowPositionY} rotate(45deg)`};
            }
        }
    }
    &.left {
        position: initial;
        .tooltip-text {
            border-radius: 4px;
            left: 58px;
            cursor: initial;
            right: auto;
            padding: 10px;
            top: calc(50% - 22px);
            &::after {
                left: -5px;
                top: 17px;
                height: 10px;
                width: 10px;
                border-radius: 3px;
            }
        }
    }
    &.top {
        .tooltip-text {
            right: 0px;
            left: auto;
            top: ${({ tooltipTopPosition }) => tooltipTopPosition};
            &::after {
                right: 18px;
                transform: ${({ arrowPositionY }) =>
                    `${arrowPositionY} rotate(45deg)`};
            }
        }
    }
    &.bottom {
        .tooltip-text {
            right: 0px;
            left: auto;
            top: ${({ tooltipTopPosition }) => tooltipTopPosition};
            ${({ isEmailTemplate, transformStyle }) =>
                isEmailTemplate &&
                `
                transform: ${transformStyle};
            
            `};
            &::after {
                right: 18px;
                transform: ${({ arrowPositionY }) =>
                    `${arrowPositionY} rotate(45deg)`};
            }
        }
    }
    &.bottomCenter {
        .tooltip-text {
            left: 50%;
            transform: translateX(-50%);
            top: calc(100% + 8px);
            &::after {
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
                border-radius: 4px;
                z-index: -1;
            }
        }
    }
    &:hover {
        .tooltip-text {
            opacity: 1;
            visibility: visible;
            border: 1px solid ${({ theme }) => theme.colors.ambience[18]};
        }
    }
    &.topRight {
        .tooltip-text {
            background-color: ${({ theme }) => theme.colors.ambience[24]};
            border-radius: 4px 4px 4px 0;
            border: 1px solid ${({ theme }) => theme.colors.ambience[18]};
            padding: 5px 12px;
            left: 80%;
            bottom: 16px;
            &::after,
            &::before {
                width: 0;
                height: 0;
                border-style: solid;
                content: '';
                display: block;
                position: absolute;
                left: -1px;
                top: 100%;
                border-radius: 0;
                background: transparent;
                transform: none;
            }
            &::after {
                border-width: 8px 8px 0 0;
                left: 0;
                border-color: ${({ theme }) => theme.colors.ambience[24]}
                    transparent transparent transparent;
            }
            &::before {
                border-width: 10px 10px 0 0;
                border-color: ${({ theme }) => theme.colors.ambience[18]}
                    transparent transparent transparent;
            }
        }
    }
    &.topLeft {
        .tooltip-text {
            background-color: ${({ theme }) => theme.colors.ambience[24]};
            border-radius: 4px 4px 0 4px;
            border: 1px solid ${({ theme }) => theme.colors.ambience[18]};
            padding: 5px 12px;
            right: 6px;
            bottom: 24px;
            &::after,
            &::before {
                width: 0;
                height: 0;
                border-style: solid;
                content: '';
                display: block;
                position: absolute;
                right: -1px;
                top: 100%;
                border-radius: 0;
                background: transparent;
                transform: none;
            }
            &::after {
                border-width: 0 8px 8px 0;
                right: 0;
                border-color: transparent
                    ${({ theme }) => theme.colors.ambience[24]} transparent
                    transparent;
            }
            &::before {
                border-width: 0 10px 10px 0;
                border-color: transparent
                    ${({ theme }) => theme.colors.ambience[18]} transparent
                    transparent;
            }
        }
    }
`;
const Span = styled.span`
    ${layout}
    ${space}
    ${flexbox}
    cursor: default !important;
    line-height: 0;
    position: relative;
    &.right {
        .tooltip-text {
        }
    }
    &.left {
        margin-left: 6px;
        position: initial;
        .tooltip-text {
            border-radius: 4px;
            left: 58px;
            cursor: initial;
            right: auto;
            padding: 10px;
            top: calc(50% - 22px);
            &::after {
                left: -5px;
                top: 17px;
                height: 10px;
                width: 10px;
                border-radius: 3px;
            }
        }
    }
    &.top {
        .tooltip-text {
            left: 50%;
            top: calc(-100% + 5px);
            transform: translateX(-50%) translateY(-100%);
            &::after {
                left: ${({ arrowPosition }) =>
                    arrowPosition ? arrowPosition : '50%'};
                transform: translateX(-50%) rotate(45deg);
                top: auto;
            }
        }
    }
    &.top-left {
        .tooltip-text {
            right: -20px;
            left: auto;
            top: -100%;
            transform: translateY(-100%);
            &::after {
                left: auto;
                top: auto;
                right: 15px;
            }
        }
    }
    &.bottom {
        .tooltip-text {
            right: -24px;
            left: auto;
            top: ${({ tooltipTopPosition }) => tooltipTopPosition};
            &::after {
                right: 18px;
            }
        }
    }
    &.bottomCenter {
        .tooltip-text {
            left: 50%;
            transform: translateX(-50%);
            top: calc(100% + 24px);
            &::after {
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
            }
        }
    }
    &:hover {
        .tooltip-text {
            opacity: 1;
            visibility: visible;
        }
    }
    &.peopleCountPosition {
        .tooltip-text {
            left: 250%;
            &:after {
                left: 42%;
            }
        }
    }
`;
const ToolTipContainer = styled.span`
    ${layout}
    ${space}
    ${flexbox}
    position: absolute;
    background-color: ${({ theme }) =>
        theme.isLightTheme
            ? theme.colors.ambience[24]
            : theme.colors.ambience[0]};
    border-radius: 8px;
    padding: 16px 16px;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;
    z-index: 1002;
    ${({ backgroundColor, theme }) =>
        backgroundColor &&
        `
background-color: ${backgroundColor};
border: 1px solid ${theme.colors.ambience[15]} !important;
`}
    ${({ theme }) =>
        theme.isLightTheme &&
        `
        box-shadow :  0px 4px 16px ${alpha(theme.colors.ambience[1], 0.4)};
    `};
    &.tooltip-text::after {
        content: '';
        height: 26px;
        width: 26px;
        border-radius: 8px;
        background-color: ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.ambience[24]
                : theme.colors.ambience[0]};
        display: block;
        position: absolute;
        top: ${({ tooltipArrowTop }) => tooltipArrowTop};
        transform: rotate(45deg);
        ${({ backgroundColor, theme }) =>
            backgroundColor &&
            `
    background-color: ${backgroundColor};
    border-top: 1px solid ${theme.colors.ambience[15]} !important;
    border-left: 1px solid ${theme.colors.ambience[15]} !important;
    `}
    }

    &.tooltip-text {
        ${({ portal, hovered, place, tooltipTopPosition, arrowPosition }) => `
            ${
                portal
                    ? `
                ${
                    hovered &&
                    `
                    opacity: 1;
                    visibility: visible;`
                }
                ${
                    place === 'left' &&
                    `
                    border-radius: 4px;
                    left: 58px;
                    cursor: initial;
                    right: auto;
                    padding: 10px;
                    top: calc(50% - 22px);
                    &::after {
                        left: -5px;
                        top: 17px;
                        height: 10px;
                        width: 10px;
                        border-radius: 3px;
                    }
                `
                }
                ${
                    place === 'top' &&
                    `
                    left: 50%;
                    top: calc(-100% + 5px);
                    transform: translateX(-50%) translateY(-100%);
                    &::after {
                        left: ${arrowPosition ? arrowPosition : '50%'};
                        transform: translateX(-50%) rotate(45deg);
                        top: auto;
                    }
                `
                }
                ${
                    place === 'bottom' &&
                    `
                    right: -24px;
                    left: auto;
                    top: ${tooltipTopPosition};
                    &::after {
                        right: 18px;
                    }
                `
                }
                ${
                    place === 'bottomCenter' &&
                    `
                    left: 50%;
                    transform: translateX(-50%);
                    top: calc(100% + 24px);
                    &::after {
                        left: 50%;
                        transform: translateX(-50%) rotate(45deg);
                    }
                `
                }
            `
                    : ``
            }
        `}
        ${({ place }) =>
            place === 'topRight' &&
            `
                top: calc(-100% + 2px);
                left: -26px;
                transform: translate(0%, -100%);
                &::after {
                    transform: translateX(-50%) rotate(45deg);
                    top: auto;
                    left: 35px;
                }
        `}
        ${({ place }) =>
            place === 'bottomRight' &&
            `
                top: calc(100% + 14px);
                left: 0px;
                &::after {
                    transform: translateX(0) rotate(45deg);
                    left: 50px;
                }
        `}
    }
`;
