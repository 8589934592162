import {
    GETTING_STARTED_TAB,
    HELP_PAGE_TAB,
    LIBRARY_PAGE_TAB,
    VIDEO_LIBRARY_PAGE_TAB,
} from 'components/community/constants';
import useCommunityDashboardAnalytics from 'hooks/community/useCommunityDashboardAnalytics';
import useCommunitySideMenu from 'hooks/community/useCommunitySideMenu';
import useDeviceInfo from 'hooks/useDeviceInfo';
import useTheme from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CmDashboardAnalyticsTypes } from 'utils/constants/community/community';
import { showGettingStarted } from 'views/community/utils';
import {
    setSideMenuActiveId,
    setSideMenuEnabled,
} from '../../store/actions/layout';
import FirebaseLogger, { CmDashboardEvents } from '../../utils/firebaseLogger';
import { logger } from '../../utils/logger';
import CommunityDropdown from '../header/CommunityDropdown';
import Tabs from './Tabs';

const eventLogs = logger.init('Video Library Events', 'blue');

const SideLoader = (props) => {
    const { theme } = useTheme();

    return (
        <ContentLoader
            height={1200}
            width={230}
            speed={1}
            backgroundColor={theme.colors.ambience[21]}
            foregroundColor={theme.colors.ambience[19]}
            {...props}
        >
            <circle cx='50' cy='20' r='20' />
            <rect x='30' y='55' width='140' height='16' />
            <rect x='30' y='80' width='115' height='10' />
        </ContentLoader>
    );
};

function SideMenu() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const { communityId, name, manager_id: managerId } = useSelector(
        (state) => state.Community.current
    );
    const { enabled, visible, ...sideMenuProps } = useSelector(
        (state) => state.layout.sideMenu
    );
    const { logAnalyticsEvent } = useCommunityDashboardAnalytics();

    // Restrict dashboard on mobile and tablets
    const { isMobile, isTablet } = useDeviceInfo();
    const isMobileOrTablet = isMobile || isTablet;

    const { sideMenuConfig, menuSwitcherHandler } = useCommunitySideMenu();

    if (isMobileOrTablet && enabled) {
        dispatch(setSideMenuEnabled(false));
    }

    const { activeId } = sideMenuProps || {};
    const logFirebaseEvent = (tabName) => {
        // log firebase events
        const logData = {
            community_id: communityId,
            community_name: name,
            userId: user.id,
        };
        if (
            tabName === VIDEO_LIBRARY_PAGE_TAB ||
            tabName === LIBRARY_PAGE_TAB
        ) {
            eventLogs('videoLibraryTabClicked', logData);
            FirebaseLogger.videoLibraryEvents(
                'videoLibraryTabClicked',
                logData
            );
        } else if ([GETTING_STARTED_TAB, HELP_PAGE_TAB].includes(tabName)) {
            const isGettingStartedTab = tabName === GETTING_STARTED_TAB;
            const { gettingStarted, helpCenter } = CmDashboardAnalyticsTypes;
            const category = isGettingStartedTab ? gettingStarted : helpCenter;
            const event = isGettingStartedTab
                ? CmDashboardEvents[category].gs_clicked_getting_started
                : CmDashboardEvents[category].hc_clicked_help_center;
            logAnalyticsEvent({
                category,
                event,
                data: {
                    community_id: communityId,
                    user_role:
                        managerId === user?.id
                            ? 'Community Manager'
                            : 'Event Manager',
                },
            });
        }
    };
    const handleMenu = (e, menu) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setSideMenuActiveId(menu));
        if (menu === VIDEO_LIBRARY_PAGE_TAB || menu === LIBRARY_PAGE_TAB)
            logFirebaseEvent(menu);
        else if (menu === GETTING_STARTED_TAB)
            logFirebaseEvent(GETTING_STARTED_TAB);
        else if (menu === HELP_PAGE_TAB) logFirebaseEvent(HELP_PAGE_TAB);
        menuSwitcherHandler(menu);
    };
    const [isLoading, setisLoading] = useState(true);
    useEffect(() => {
        if (communityId) {
            setisLoading(false);
        }
    }, [communityId]);

    return enabled ? (
        <SideBar className='app-sidebar'>
            {user && !isLoading ? (
                <LeftMenu
                    showGettingStarted={showGettingStarted}
                    className={`${
                        visible !== false ? 'menu-visible' : 'menu-hidden'
                    } d-md-block has-scroll custom-scroll`}
                >
                    <CommunityDropdown />
                    <nav className='s-navbar clearfix'>
                        <Tabs
                            tabs={sideMenuConfig}
                            activeId={activeId}
                            handleMenu={handleMenu}
                        />
                    </nav>
                </LeftMenu>
            ) : (
                <LeftMenu>
                    <SideLoader />
                </LeftMenu>
            )}
        </SideBar>
    ) : null;
}

export default SideMenu;

const SideBar = styled.div`
    background-color: ${({ theme }) =>
        theme?.isLightTheme
            ? theme.colors.ambience[24]
            : theme.colors.ambience[21]};
    height: 100%;
`;

const LeftMenu = styled.div`
    min-height: calc(100vh - 110px);
    padding: 10px 0 24px 0;
    left: 0;
    bottom: 0;
    width: 235px;
    top: 0;
    z-index: 100;
    transition: margin-left 300ms ease-out;
    background-color: ${({ theme }) =>
        theme?.isLightTheme
            ? theme.colors.ambience[24]
            : theme.colors.ambience[21]};
    &.has-scroll {
        overflow: auto;
        height: calc(100vh - 110px);
    }
    &.menu-visible {
        margin-left: 0;
    }

    &.menu-hidden {
        margin-left: -235px;
    }

    .comm-link {
        text-decoration: none;
    }

    .s-navbar {
        position: relative;
        z-index: 2;
        li {
            //overflow: hidden;
            margin: 10px 0;
            padding-left: 35px;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s ease;
            p {
                overflow: hidden;
            }
            &:hover {
                & > * {
                    fill: ${({ theme }) => theme.colors.accentPrimary[0]};
                }
            }
            & > * {
                color: ${({ theme }) => theme.colors.ambience[3]};
                fill: ${({ theme }) => theme.colors.ambience[3]};
            }
            &.active {
                background-color: ${({ theme }) =>
                    theme?.isLightTheme
                        ? theme.colors.ambience[23]
                        : theme.colors.ambience[20]};
                position: relative;
                & > * {
                    color: ${({ theme }) => theme.colors.ambience[0]};
                    fill: ${({ theme }) => theme.colors.accentPrimary[0]};
                    font-weight: bold;
                }
                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: ${({ theme }) => theme.colors.accentPrimary[0]};
                    width: 2px;
                }
            }
            &#get-help {
                position: fixed;
                width: 235px;
                bottom: 0;
            }
        }
    }
`;
