import Icon from 'foundations/icon';
import { alpha } from 'foundations/theme';
import Select from 'react-select';
import styled from 'styled-components';

export const SelectWrapper = styled.div`
    width: 100%;
`;

export const DropdownArrow = styled(Icon).attrs(() => ({
    icon: 'chevron_down',
}))`
    fill: ${({ theme, isThemeV2 }) =>
        isThemeV2
            ? theme.colors.text.default.primary
            : theme.colors.ambience[13]};
    width: 14px;
    height: 14px;
    transition: transform 0.3s ease;
`;

export const selectClass = '.airmeet-select';

export const SelectExtend = styled(Select)`
    && {
        [class*='MenuList'] {
            ::-webkit-scrollbar {
                width: 8px;
                border-radius: 8px;
                background: ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.fill.default3
                        : theme.colors.ambience[19]};
            }
            ::-webkit-scrollbar-track {
                background: ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.fill.default3
                        : theme.colors.ambience[19]};
                padding-right: 30px;
            }
            ::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background: ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.fill.other2
                        : theme.colors.ambience[17]};
            }
        }
    }
    ${selectClass} {
        &__control {
            background: transparent;
            border: 1px solid
                ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.border.default1
                        : theme.isLightTheme
                        ? theme.colors.ambience[15]
                        : theme.colors.ambience[14]};
            font-family: ${({ customMulti, theme, isThemeV2 }) =>
                customMulti
                    ? isThemeV2
                        ? theme.fonts.regular
                        : theme.fonts.book
                    : theme.fonts.medium};
            font-size: ${({ customMulti, theme, isThemeV2 }) =>
                customMulti
                    ? isThemeV2
                        ? theme.fontSizes.x1
                        : theme.fontSizes[1]
                    : theme.fontSizes[2]};
            line-height: ${({ theme, isThemeV2 }) =>
                isThemeV2 ? theme.lineHeights.x1 : theme.lineHeights[2]};
            min-height: 0;
            padding: ${({ customMulti, nonMultiSearch }) =>
                customMulti || nonMultiSearch ? '4px 10px' : '20px 10px 10px'};
            margin: ${({ customMulti, nonMultiSearch }) =>
                customMulti || nonMultiSearch ? '8px 8px 0 8px' : '0px'};
            border-radius: 8px;
            cursor: pointer;
            &--is-focused {
                border-color: ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.border.other1
                        : theme.colors.accentPrimary[0]};
                ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? `outline: 4px solid ${theme.colors.border.default1};`
                        : `
                box-shadow: 0px 0px 2px 2px
                    ${
                        theme.isLightTheme
                            ? alpha(theme.colors.accentPrimary[1], 0.4)
                            : alpha(theme.colors.accentPrimary[0], 0.4)
                    };
                `}
            }
            &:hover {
                border-color: ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.border.other1
                        : theme.isLightTheme
                        ? theme.colors.accentPrimary[1]
                        : theme.colors.accentPrimary[0]};
            }
            &:focus-visible {
                outline: ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.border.default1
                        : theme.isLightTheme
                        ? '2px solid black !important'
                        : '2px solid white !important'};
            }
            &--menu-is-open {
                ${DropdownArrow} {
                    transform: rotate(180deg);
                }
            }
        }
        &__value-container {
            padding: 0;
            outline: 0;
            position: static;
            &--has-value {
                ${selectClass}__placeholder {
                    position: absolute;
                    top: 16px;
                    font-size: ${({ theme }) => theme.fontSizes[0]};
                }
            }
            &--is-multi {
                &${selectClass}__value-container--has-value {
                    ${selectClass}__placeholder {
                        display: none;
                    }
                }
            }
        }
        &__indicator-separator {
            display: none;
        }
        &__indicator {
            padding: 0;
        }
        &__placeholder {
            color: ${({ theme, isThemeV2 }) =>
                isThemeV2
                    ? theme.colors.text.default.secondary
                    : theme.colors.ambience[9]};
            transition: all 0.3s ease-in;
        }
        &__input {
            ${({ theme, isThemeV2 }) =>
                isThemeV2
                    ? `
                    color: ${theme.colors.text.default.primary};
                `
                    : ''}
        }
        &__single-value {
            font-family: ${({ theme, isThemeV2 }) =>
                isThemeV2 ? theme.fonts.regular : theme.fonts.book};
            color: ${({ theme, isThemeV2 }) =>
                isThemeV2
                    ? theme.colors.text.default.primary
                    : theme.colors.ambience[13]};
            font-size: ${({ theme }) => theme.fontSizes[2]};
            line-height: ${({ theme }) => theme.lineHeights[1]};
            padding-top: 12px;
        }
        &__multi-value {
            float: left;
            color: ${({ theme, isThemeV2 }) =>
                isThemeV2
                    ? theme.colors.text.default.primary
                    : theme.colors.ambience[10]};
            background-color: transparent;
            margin: 4px 2px;
            &:not(:first-child) {
                display: none;
            }
        }
        &__indicators {
            top: ${({ customMulti, nonMultiSearch }) =>
                customMulti || nonMultiSearch ? '0px' : '-5px'};
            position: relative;
        }
        &__menu {
            font-family: ${(props) => props.theme.fonts.book};
            font-size: ${(props) => props.theme.fontSizes[2]};
            background-color: ${({ theme, isLightTheme, isThemeV2 }) =>
                isThemeV2
                    ? theme.colors.fill.default1
                    : isLightTheme
                    ? theme.colors.ambience[24]
                    : theme.colors.ambience[1]};
            border: ${({ theme, isLightTheme, hasBorder, isThemeV2 }) =>
                (isThemeV2 || isLightTheme) && hasBorder
                    ? `1px solid ${theme.colors.ambience[22]}`
                    : 'none'};
            border-radius: 8px;
            overflow: hidden;
            margin: 4px 0;
            padding: 4px 8px;
            box-shadow: ${({ isLightTheme, hasBorder, isThemeV2 }) =>
                (isThemeV2 || isLightTheme) && hasBorder
                    ? '0px 4px 16px rgba(0, 0, 0, 0.12)'
                    : 'none'};
            position: ${({ customMulti, nonMultiSearch }) =>
                customMulti || nonMultiSearch ? 'relative' : 'absolute'};
        }
        &__menu-list {
            ::-webkit-scrollbar {
                width: 4px;
                border-radius: 4px;
                background: transparent;
            }
            ::-webkit-scrollbar-track {
                background: transparent;
                padding-right: 10px;
            }
            ::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background: ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.fill.other2
                        : theme.colors.ambience[4]};
            }
            // Only works for firefox v64 and above
            scrollbar-color: ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.fill.other2
                        : theme.colors.ambience[4]}
                transparent;
            scrollbar-width: thin;
        }
        &__option {
            color: ${({ theme, isThemeV2 }) =>
                isThemeV2
                    ? theme.colors.text.default.primary
                    : theme.isLightTheme
                    ? theme.colors.ambience[10]
                    : theme.colors.ambience[13]};
            cursor: pointer;
            transition: all 0.3s ease;
            background-color: transparent;
            padding: 8px;
            display: flex;
            align-items: center;
            white-space: nowrap;
            &:hover,
            &:focus-visible,
            &--is-focused {
                background-color: ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? `${theme.colors.fill.other3} !important`
                        : theme.isLightTheme
                        ? `${theme.colors.ambience[23]} !important`
                        : `${theme.colors.ambience[2]}} !important`};
                color: ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.text.default.primary
                        : theme.isLightTheme
                        ? `${theme.colors.ambience[8]} !important`
                        : `${theme.colors.ambience[15]}} !important`};
                border-radius: 8px;
            }
            &--is-selected {
                background-color: ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.fill.other3
                        : theme.isLightTheme
                        ? theme.colors.ambience[24]
                        : theme.colors.ambience[1]};
                color: ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.text.default.primary
                        : theme.isLightTheme
                        ? theme.colors.ambience[3]
                        : theme.colors.ambience[21]};
            }
            &--is-disabled {
                color: ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.text.default.disabled
                        : theme.colors.ambience[8]};
                &:hover {
                    color: ${({ theme, isThemeV2 }) =>
                        isThemeV2
                            ? theme.colors.text.default.disabled
                            : theme.colors.ambience[8]};
                    background-color: transparent;
                }
            }
        }
    }
`;
