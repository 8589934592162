import Btn from 'atoms/Button/btn';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import React from 'react';
import usePortal from 'react-cool-portal';
import styled from 'styled-components';

function Prompt({
    title,
    description,
    rejectText,
    resolveText = 'Continue',
    onResolve,
    onReject,
    portalContainer = '',
    body = null,
}) {
    const { Portal } = usePortal({
        clickOutsideToHide: false,
        escToHide: false,
        containerId: portalContainer ? portalContainer : 'sidebarWrapper',
    });

    const handlePromptReject = () => {
        onReject && onReject();
    };

    const handlePromptResolve = () => {
        onResolve && onResolve();
    };

    return (
        <Portal>
            <Modal>
                <ModalContainer>
                    <Text variant='h4' color='text.default.primary' mb={1}>
                        {title}
                    </Text>
                    <Text variant='body2' color='text.default.secondary'>
                        {description}
                    </Text>
                    {body}
                    <FlexDiv
                        justifyContent='flex-end'
                        alignItems='center'
                        mt={3}
                    >
                        {rejectText && (
                            <Btn
                                variant='secondary'
                                onClick={handlePromptReject}
                                mr={2}
                            >
                                {rejectText}
                            </Btn>
                        )}
                        {resolveText && (
                            <Btn variant='danger' onClick={handlePromptResolve}>
                                {resolveText}
                            </Btn>
                        )}
                    </FlexDiv>
                </ModalContainer>
            </Modal>
        </Portal>
    );
}

export default Prompt;

const Modal = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: ${({ theme }) => theme.colors.overlay.default1};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
`;

const ModalContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.fill.default1};
    border-radius: ${({ theme }) => theme.radii.default};
    padding: 24px;
`;
