import Button from 'atoms/Button/button';
import InputField from 'atoms/Input/input';
import useTimezoneUtils from 'components/community/common/TimezoneSelector/useTimezoneUtils';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import { getCustomScroll } from 'components/molecules/utils/customStyles';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getAmbienceColorId } from 'utils/core';
import { logger } from 'utils/logger';

export default function TimeZoneScreen({
    defaultValue,
    onChange,
    localDefault,
    title = 'TimeZone',
    activeOption,
    onClose,
    newOnBoardingFlow = false,
    airmeetFormat = 'event',
}) {
    const { options, localTzOption } = useTimezoneUtils();
    const [selectedOption, setSelectedOption] = useState(() => {
        logger.debug('Fetching default Tz value', defaultValue);
        if (defaultValue) {
            return options.filter((option) => option.value === defaultValue);
        } else if (localDefault) {
            return options.filter((option) => option === localTzOption);
        }
        return null;
    });
    const [timeZoneInput, setTimeZoneInput] = useState({
        timezone: '',
    });
    useEffect(() => {
        const activeTimeZoneEl = document.getElementById(
            'timezone-checkmark-circle'
        );
        activeTimeZoneEl &&
            activeTimeZoneEl.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
    }, []);
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        activeOption(selectedOption);
    };
    const handleInputChange = ({ target: { name, value } }) => {
        setTimeZoneInput({ ...timeZoneInput, [name]: value });
    };
    const filteredOptions = (options || []).filter((option) => {
        const { timezone } = timeZoneInput;
        // hide EST and MST from timezone list logic
        if (!option?.isHidden) {
            const searchCondition =
                option.value.toLowerCase().includes(timezone.toLowerCase()) ||
                option.label.toLowerCase().includes(timezone.toLowerCase());
            const aliasCondition = option?.alias?.map((it) => it.toLowerCase());
            if (timezone === '') {
                return option;
            } else if (searchCondition) {
                return option;
            } else if (aliasCondition.includes(timezone.toLowerCase())) {
                return option;
            }
        }
    });

    const { theme } = useTheme();
    const getColorId = (colorId) =>
        getAmbienceColorId({ colorId, isAgnostic: newOnBoardingFlow, theme });

    return (
        <>
            <FlexDiv alignItems={'center'} mb={2} position={'relative'}>
                <Typography
                    variant={'subtitle2'}
                    color={`ambience.${getColorId('23')}`}
                >
                    {title}
                </Typography>
                <ButtonClose variant={'link'} onClick={onClose}>
                    <StyledSvgSprite
                        icon={'icon-times'}
                        height={'24px'}
                        width={'24px'}
                        fill={`ambience.${getColorId('23')}`}
                    />
                </ButtonClose>
            </FlexDiv>
            <FlexDiv flexDirection={'column'}>
                <Box>
                    <Typography
                        variant={'caption'}
                        color={`ambience.${getColorId('14')}`}
                        mb={1}
                    >
                        Set the timezone in which your {airmeetFormat} is
                        occuring
                    </Typography>
                </Box>
                <SearchBox ambienceColorId={getColorId}>
                    <StyledInputField
                        id={'search'}
                        type={'text'}
                        name={'timezone'}
                        placeholder={'Search for a country or a timezone'}
                        icon={'search-icon'}
                        style={{ height: '48px' }}
                        onChange={handleInputChange}
                        value={timeZoneInput.timezone}
                        // errorMsg={errorMsg}
                        // variant={variant}
                        // state={state}
                        // onKeyDown={onKeyDown}
                        // onClick={onClick}
                        // onFocus={(e) => (e.target.readOnly = true)}
                        // onBlur={(e) => (e.target.readOnly = false)}
                    />
                </SearchBox>
                <TimeZoneWrapper
                    flexDirection={'column'}
                    pt={2}
                    forceDarkMode={newOnBoardingFlow ? false : true}
                    verticalWidth={'12px'}
                >
                    {(filteredOptions || []).map((option, index) => {
                        return (
                            <OptionsContainer
                                alignItems={'center'}
                                key={index}
                                onClick={() => handleChange(option)}
                                className={`${
                                    selectedOption === index ||
                                    defaultValue === option.value
                                        ? 'active'
                                        : ''
                                }`}
                                htmlFor={option.value}
                            >
                                <RadioButton
                                    type={'radio'}
                                    name='timeZone'
                                    value={option.value}
                                    id={option.value}
                                />
                                <FlexDiv alignItems={'center'}>
                                    <Box mr={2} minWidth={'67px'}>
                                        <Typography
                                            variant={'subtext2'}
                                            color={
                                                selectedOption === index ||
                                                defaultValue === option.value
                                                    ? `ambience.${getColorId(
                                                          '23'
                                                      )}`
                                                    : `ambience.${getColorId(
                                                          '14'
                                                      )}`
                                            }
                                            fontWeight={
                                                selectedOption === index ||
                                                defaultValue === option.value
                                                    ? 'bold'
                                                    : ''
                                            }
                                        >{`UTC ${option.offset}`}</Typography>
                                    </Box>
                                    <Box flex={1}>
                                        <Typography
                                            variant={'subtext1'}
                                            color={
                                                selectedOption === index ||
                                                defaultValue === option.value
                                                    ? `ambience.${getColorId(
                                                          '23'
                                                      )}`
                                                    : `ambience.${getColorId(
                                                          '19'
                                                      )}`
                                            }
                                            fontWeight={
                                                selectedOption === index ||
                                                defaultValue === option.value
                                                    ? 'bold'
                                                    : ''
                                            }
                                            mb={0}
                                        >
                                            {`${option.value.replace(
                                                /_/g,
                                                ' '
                                            )} Time`}
                                        </Typography>
                                        <EllipsisTypography
                                            variant={'caption'}
                                            color={`ambience.${getColorId(
                                                '14'
                                            )}`}
                                            mb={0}
                                            title={option.label}
                                        >
                                            {option.label}
                                        </EllipsisTypography>
                                    </Box>
                                </FlexDiv>
                                {(selectedOption === index ||
                                    defaultValue === option.value) && (
                                    <AbsoluteStyledSvgSprite
                                        id={'timezone-checkmark-circle'}
                                        icon={
                                            newOnBoardingFlow
                                                ? 'icon-checkmark-circle-primary'
                                                : 'icon-checkmark-circle'
                                        }
                                        height={'24px'}
                                        width={'24px'}
                                    />
                                )}
                            </OptionsContainer>
                        );
                    })}
                </TimeZoneWrapper>
            </FlexDiv>
        </>
    );
}
const StyledInputField = styled(InputField)``;
const SearchBox = styled(Box)`
    margin-bottom: 8px;
    > div {
        background: none !important;
        border: 0 !important;
        margin-bottom: 0;
    }
    input {
        padding: 18px 16px 18px 44px !important;
        background: ${({ theme, ambienceColorId }) =>
            theme.colors.ambience[ambienceColorId(3)]} !important;
        color: ${({ theme, ambienceColorId }) =>
            theme.colors.ambience[ambienceColorId(23)]};
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px
                ${({ theme, ambienceColorId }) =>
                    theme.colors.ambience[ambienceColorId(3)]}
                inset;
            -webkit-text-fill-color: ${({ theme, ambienceColorId }) =>
                theme.colors.ambience[ambienceColorId(23)]} !important;
            border: none;
            caret-color: ${({ theme, ambienceColorId }) =>
                theme.colors.ambience[ambienceColorId(23)]};
            transition: background-color 5000s ease-in-out 5000s;
        }
    }
`;
const TimeZoneWrapper = styled(FlexDiv)`
    max-height: calc(100vh - 190px);
    overflow-y: auto;
    overflow-x: hidden;
    ${({ theme, forceDarkMode, verticalWidth }) =>
        getCustomScroll({ theme, forceDarkMode, verticalWidth })};
`;
const OptionsContainer = styled.label`
    margin-bottom: 24px;
    cursor: pointer;
    position: relative;
    padding-right: 32px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const RadioButton = styled.input`
    display: none;
`;
const AbsoluteStyledSvgSprite = styled(StyledSvgSprite)`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
`;
const EllipsisTypography = styled(Typography)`
    white-space: nowrap;
    max-width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
`;
const ButtonClose = styled(Button)`
    position: absolute;
    right: 0px;
    z-index: 1090;
    &:hover {
        opacity: 0.85;
    }
    &:focus {
        outline: 0;
        box-shadow: none;
    }
`;
