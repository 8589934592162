export const EDIT_BOOTH_ENABLED = 'EDIT_BOOTH_ENABLED';
export const MAIN_NAVIGATION = 'MAIN_NAVIGATION';
export const SAVE_BOOTH_CHANGES = 'SAVE_BOOTH_CHANGES';

export const BOOTH_INFO_UPDATED = 'BOOTH_INFO_UPDATED';
export const CANCEL_EDIT_BOOTH = 'CANCEL_EDIT_BOOTH';
export const BACK_BUTTON_PRESSED = 'BACK_BUTTON_PRESSED';
export const GM_BACK_BUTTON_PRESSED = 'GM_BACK_BUTTON_PRESSED';
export const MAIN_NAVIGATION_DISABLED = 'MAIN_NAVIGATION_DISABLED';

export const HEADER_DETAILS = 'HEADER_DETAILS';
export const SHOW_BACK_BUTTON = 'SHOW_BACK_BUTTON';

export const CHECK_IF_ALREADY_IN_BOOTH = 'CHECK_IF_ALREADY_IN_BOOTH';
export const CHECK_IF_ALREADY_IN_SESSION = 'CHECK_IF_ALREADY_IN_SESSION';
export const MOBILE_TAB_SWITCH = 'MOBILE_TAB_SWITCH';

export const GO_TO_DM = 'GO_TO_DM';

export const APPLY_CUSTOM_BOOTH_CHANGES = 'APPLY_CUSTOM_BOOTH_CHANGES';
export const CANCEL_CUSTOM_BOOTH_CHANGES = 'CANCEL_CUSTOM_BOOTH_CHANGES';

export const MCC_ROUTE_CHANGE = 'MCC_ROUTE_CHANGE';

export const API_RUN_START = 'API_RUN_START';
export const API_RUN_COMPLETE = 'API_RUN_COMPLETE';

export const SHOW_LANGUAGE_SETTING = 'SHOW_LANGUAGE_SETTING';
export const JOIN_LOUNGE_TABLE = 'JOIN_LOUNGE_TABLE';
export const LEAVE_LOUNGE_TABLE = 'LEAVE_LOUNGE_TABLE';
export const ADD_MEETINGS_TO_SCHEDULE_PAGE = 'ADD_MEETINGS_TO_SCHEDULE_PAGE';
export const UPDATE_MEETING_IN_SCHEDULE_PAGE =
    'UPDATE_MEETING_IN_SCHEDULE_PAGE';
export const REMOVE_MEETING_FROM_SCHEDULE_PAGE =
    'REMOVE_MEETING_FROM_SCHEDULE_PAGE';
export const LHS_TAB_CLICKED = 'LHS_TAB_CLICKED';
export const JOIN_SESSION_CLICKED = 'JOIN_SESSION_CLICKED';
export const JOIN_SCHEDULED_MEETING = 'JOIN_SCHEDULED_MEETING';
export const LOGOUT = 'LOGOUT';
export const ANNOUNCEMENT_CLICKED = 'ANNOUNCEMENT_CLICKED';
export const JOIN_BOOTH_SESSION = 'JOIN_BOOTH_SESSION';
export const JOIN_GROUP_MEETING = 'JOIN_GROUP_MEETING';
export const LEAVE_GROUP_MEETING = 'LEAVE_GROUP_MEETING';
export const GROUP_MEETING_ENDED = 'GROUP_MEETING_ENDED';
export const SHOW_EDIT_PROFILE = 'SHOW_EDIT_PROFILE';
export const SHOW_LEADERBOARD = 'SHOW_LEADERBOARD';
export const JOIN_TABLE = 'JOIN_TABLE';
export const SHOW_EVENT_TAB = 'SHOW_EVENT_TAB';
export const SHOW_EVENT_FEED_MODAL = 'SHOW_EVENT_FEED_MODAL';
export const SHOW_PEOPLE_TAB = 'SHOW_PEOPLE_TAB';
export const SHOW_ALERTS_TAB = 'SHOW_ALERTS_TAB';
export const SHOW_RAISE_HAND_TAB = 'SHOW_RAISE_HAND_TAB';
export const GO_TO_LOUNGE = 'GO_TO_LOUNGE';
export const GO_TO_LOUNGE_JOIN_TABLE = 'GO_TO_LOUNGE_JOIN_TABLE';
export const GO_TO_BOOTH = 'GO_TO_BOOTH';
export const LEAVE_STAGE_RTC_CHANNEL = 'LEAVE_STAGE_RTC_CHANNEL';

export const SET_USER_CURRENT_ACTIVITY = 'SET_USER_CURRENT_ACTIVITY';

export const TABLES_LOADED = 'TABLES_LOADED';
export const START_VIDEO_ANNOUNCEMENT = 'START_VIDEO_ANNOUNCEMENT';

export const BANNER_LOADED = 'BANNER_LOADED';

export const VIEW_SCHEDULE_TOGGLE = 'VIEW_SCHEDULE_TOGGLE';
export const HIDE_VIEW_SCHEDULE = 'HIDE_VIEW_SCHEDULE';

export const SHOW_STAGE_LAYOUT_SWITCH_CONFIRMATION =
    'SHOW_STAGE_LAYOUT_SWITCH_CONFIRMATION';
export const OPEN_OVERLAY = 'OPEN_OVERLAY';
export const SHOW_VIEW_SCHEDULE = 'SHOW_VIEW_SCHEDULE';

export const HIDE_OVERLAYS = 'HIDE_OVERLAYS';

export const RHS_OVERLAY_OPENED = 'RHS_OVERLAY_OPENED';
export const RHS_OVERLAY_CLOSED = 'RHS_OVERLAY_CLOSED';
export const TOGGLE_RICH_PROFILE_OVERLAY = 'TOGGLE_RICH_PROFILE_OVERLAY';
export const HIDE_QNA_COMMENTS = 'HIDE_QNA_COMMENTS';
export const BREAKOUT_HAS_STARTED = 'BREAKOUT_HAS_STARTED';
export const HIDE_TABLE_RHS = 'HIDE_TABLE_RHS';
export const GO_TO_SCHEDULE = 'GO_TO_SCHEDULE';

export const RESUME_LOCAL_STREAM_CHECK = 'RESUME_LOCAL_STREAM_CHECK';
export const GET_SESSION_REPLAY_TIMESTAMP = 'GET_SESSION_REPLAY_TIMESTAMP';
export const STAGE_OR_BACKSTAGE_MUTED = 'STAGE_OR_BACKSTAGE_MUTED';
export const SESSION_MOVE_SCREENSHARE = 'SESSION_MOVE_SCREENSHARE';
export const SHOW_BRANDING_TAB = 'SHOW_BRANDING_TAB';

export const FOCUS_SCHEDULE_BTN = 'FOCUS_SCHEDULE_BTN';
export const MOBILE_EXIT_SESSION = 'MOBILE_EXIT_SESSION';
