import { StorybookContext } from 'context/StorybookMetaContext';
import { ThemeContext as StoryThemeContext } from 'context/Theme';
import { ThemeContext } from 'context/ThemeProvider';
import { useContext } from 'react';

export default function useTheme() {
    let ThemeContextTemp = useContext(StorybookContext)
        ? StoryThemeContext
        : ThemeContext;
    const { theme, isLightTheme, themeWrapper, baseColor } = useContext(
        ThemeContextTemp
    );

    return { theme, isLightTheme, themeWrapper, baseColor };
}
