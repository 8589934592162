import { defaultColor, objToHSL } from 'foundations/theme';
import { rgbObjToStr } from 'utils/colors';
import { cornerRadius, overlay } from './constants';
import { colors } from './palette';

export const getColorPalette = ({
    isHighContrast,
    isLightTheme,
    brandDefault = null,
    forceAirmeetBrand = false,
}) => {
    let palette = {};
    if (isHighContrast) {
        palette = colors.highContrast;
    } else if (isLightTheme) {
        palette = colors.light;
    } else {
        palette = colors.dark;
    }

    if (!isHighContrast) {
        if (forceAirmeetBrand) {
            palette.brandDefault = palette?.brandDashboard;
        } else if (brandDefault) {
            // TODO: Avoid Airmeet's default purple v2 is complete
            if (brandDefault === objToHSL(defaultColor)) {
                palette.brandDefault = '#9580FF';
            } else {
                palette.brandDefault = brandDefault;
            }
        }
    }

    return palette;
};

export const getBlurOverlay = ({ isHighContrast, isLightTheme }) => {
    if (isHighContrast) {
        return overlay.overlayHC;
    } else if (isLightTheme) {
        return overlay.blurOverlayLight;
    } else {
        return overlay.blurOverlayDark;
    }
};

export const getCornerRadius = ({ radiusType }) => {
    const radius = { ...(cornerRadius || {}) };

    switch (radiusType) {
        case 'rounded':
            radius.default = cornerRadius.custom[2];
            break;
        case 'sharp':
            radius.default = cornerRadius.custom[0];
            break;
        default:
            radius.default = cornerRadius.custom[4];
            break;
    }

    return radius;
};

export const getCardBackgroundStyle = ({ theme, checkUnderlay = false }) => {
    return theme?.isDashboardBrandingPage
        ? `background-color: ${theme.colors.grey.fill2};`
        : theme?.eventBranding?.card?.bgType === 'solidColor' &&
          theme?.eventBranding?.card?.bgColor
        ? `background-color: ${rgbObjToStr(
              theme?.eventBranding?.card?.bgColor
          )};`
        : !checkUnderlay || theme?.eventBranding?.showUnderlay
        ? `
        background-color: ${theme.colors.background.card};
        backdrop-filter: ${theme.overlay.bgBlur};
    `
        : '';
};

export const getTopNavBackgroundStyle = ({ theme }) => {
    return !theme?.eventBranding?.topNav?.bgType ||
        theme?.eventBranding?.topNav?.bgType === 'glassmorphic'
        ? `
            background-color: ${theme.overlay.bgColor};
            backdrop-filter: ${theme.overlay.bgBlur};
        `
        : theme?.eventBranding?.topNav?.bgType === 'solidColor' &&
          theme?.eventBranding?.topNav?.bgColor
        ? `
            background-color: ${rgbObjToStr(
                theme?.eventBranding?.topNav?.bgColor
            )};
        `
        : `
            background-color: transparent;
            box-shadow: none;
        `;
};
