import { SET_IS_RHS_COLLAPSED } from 'store/actions/RHS';
import createReducer from 'utils/createReducer';

const initialState = {
    isRHSCollapsed: false,
};

const handlers = {
    [SET_IS_RHS_COLLAPSED]: (state, { payload }) => {
        return state.isRHSCollapsed === payload
            ? state
            : {
                  ...state,
                  isRHSCollapsed: payload,
              };
    },
};

export default createReducer(initialState, handlers);
