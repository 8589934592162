import {
    DEFAULT_TENANT_DETAILS,
    TENANT_DETAILS,
} from 'utils/constants/tenantDetails';

const CURRENT_TENANT =
    TENANT_DETAILS.find((platform) =>
        process.env.REACT_APP_ENV === 'production'
            ? (platform?.baseUrls || []).includes(window.location.hostname)
            : (window.location.hostname || '').includes(
                  (platform?.id || '').toLowerCase()
              )
    ) || {};

export const IS_CUSTOM_TENANT = !!CURRENT_TENANT?.id;

export const getCurrentTenantData = (key) => {
    return CURRENT_TENANT[key] || DEFAULT_TENANT_DETAILS[key];
};
