import Button from 'atoms/Button/button';
import FocusTrap from 'atoms/FocusTrap';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import withErrorBoundary, {
    DashboardModules,
} from 'components/hocs/withErrorBoundary';
import {
    FlexDiv,
    UnstyledButton,
} from 'components/molecules/utils/basicComponents';
import {
    getCustomScroll,
    getStaticColorScroll,
} from 'components/molecules/utils/customStyles';
import { alpha } from 'foundations/theme';
import { getCardBackgroundStyle } from 'foundations/themeV2/utils';
import { Typography } from 'foundations/typography';
import { motion } from 'framer-motion';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import get from 'lodash/get';
import React from 'react';
import usePortal from 'react-cool-portal';
import styled, { createGlobalStyle, useTheme } from 'styled-components';
import { onKeyPress } from 'utils/accessibilityOnKeyPress';

export const AnimateDirection = {
    RIGHT: 'right',
    BOTTOM: 'bottom',
};

const getAnimateValue = (animateDirection) => {
    const { RIGHT, BOTTOM } = AnimateDirection;
    switch (animateDirection) {
        case RIGHT:
            return {
                initial: { opacity: 0, x: '50%' },
                animate: { opacity: 1, x: '0' },
                exit: { opacity: 0, x: '50%' },
            };

        case BOTTOM:
            return {
                initial: { opacity: 0, y: '50%' },
                animate: { opacity: 1, y: '0' },
                exit: { opacity: 0, y: '50%' },
            };
        default:
            return {
                initial: { opacity: 0, x: '50%' },
                animate: { opacity: 1, x: '0' },
                exit: { opacity: 0, x: '50%' },
            };
    }
};

function Drawer({
    onClose,
    isReArrangeSpeakers = false,
    closeIconAlignment = false,
    closeButtonAlignment = false,
    activeLightTheme = false,
    hideCloseActiveBack = false,
    showClose = false,
    showBackButton = false,
    title,
    subtitle,
    subtitleVariant = 'caption',
    subtitleColor: subtitleColorProp,
    children,
    footer = null,
    wrapperClosable = false,
    width = '480px',
    zIndex = 1050,
    headerStyle = {},
    footerStyle = {},
    bodyStyle = {},
    titleStyle = {},
    subtitleStyle = {},
    onEditClick,
    onDeleteClick,
    hideDrawerComponents = false,
    headerActions = null,
    showHeader = true,
    portalContainerId = undefined,
    portal = true,
    closeButtonRound = false,
    verticalWidth = '12px',
    backdropFilter = '4px',
    showBackDropFilter = false,
    animateDirection = AnimateDirection.RIGHT,
    height = '100%',
    isStaticColorScroll = false,
    backdropStyles = {},
    hideOverlay = false,
    showSaveBtn = false,
    saveBtnText = 'Save',
    cancelBtnText = 'Cancel',
    onSaveBtnClick,
    isBtnLoading = false,
    titleVariant: titleVariantProp,
    closeButtonStyles = {},
    showCancelVariant,
    showSaveVariant,
    forceLockBodyScroll = false,
    closeButtonTextStyles = {},
    customCloseRoundButtonStyle,
    closeBtnIconSize: closeBtnIconSizeProp,
    closeBtnFill = '',
    scrollThumbColor = '',
    scrollTrackColor = '',
    ScrollTrackRadius = '',
    scrollCornerRadius = '',
    backIconStyles = {},
    focusTrapSupport = false,
    isThemeV2 = false,
    onBackBtnClick = null,
    bg = '',
    headerWrapperStyle = {},
    id = 'drawer',
    ...rest
}) {
    useLockBodyScroll();
    const theme = useTheme();
    const { initial, animate, exit } = getAnimateValue(animateDirection);

    const titleVariant = titleVariantProp || (isThemeV2 ? 'h2' : 'h6');
    const subtitleColor =
        subtitleColorProp ||
        (isThemeV2 ? 'text.default.secondary' : 'ambience.14');
    const closeBtnIconSize =
        closeBtnIconSizeProp || (isThemeV2 ? '1.5rem' : '24px');

    const { Portal } = usePortal({
        clickOutsideToHide: false,
        escToHide: false,
        containerId: portalContainerId,
    });

    const onKeyPressClose = (event) => {
        onKeyPress(event, onClose);
    };

    const content = (
        <>
            {hideOverlay ? null : (
                <DrawerOverlay
                    onClick={
                        wrapperClosable && onClose
                            ? () => {
                                  onClose();
                              }
                            : undefined
                    }
                    zIndex={zIndex}
                    hideDrawerComponents={hideDrawerComponents}
                    activeLightTheme={activeLightTheme}
                    style={backdropStyles}
                    showBackDropFilter={showBackDropFilter}
                    backdropFilter={backdropFilter}
                    isThemeV2={isThemeV2}
                    width={width}
                />
            )}
            <DrawerContainer
                animateDirection={animateDirection}
                key='drawer'
                initial={initial}
                animate={animate}
                exit={exit}
                hideDrawerComponents={hideDrawerComponents}
                transition={{
                    ease: 'easeOut',
                    duration: 0.3,
                }}
                width={width}
                height={height}
                {...rest}
                zIndex={zIndex}
                isThemeV2={isThemeV2}
                bg={bg}
                id={id}
            >
                {showHeader ? (
                    <Header
                        width={width}
                        style={headerStyle || {}}
                        activeLightTheme={activeLightTheme}
                        showBackButton={showBackButton}
                        isThemeV2={isThemeV2}
                        bg={bg}
                    >
                        <FlexDiv style={headerWrapperStyle || {}}>
                            {showBackButton && (
                                <UnstyledButton
                                    aria-label='close'
                                    onClick={
                                        onBackBtnClick
                                            ? onBackBtnClick
                                            : onClose
                                    }
                                >
                                    <StyledSvgSprite
                                        icon={'icon-back-arrowlight'}
                                        height={'24px'}
                                        width={'24px'}
                                        fill={
                                            isThemeV2
                                                ? 'text.default.primary'
                                                : activeLightTheme
                                                ? 'ambience.23'
                                                : 'ambience.0'
                                        }
                                        mr={isThemeV2 ? 'x4' : '26px'}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        {...backIconStyles}
                                    />
                                </UnstyledButton>
                            )}

                            {title && (
                                <Typography
                                    variant={
                                        isReArrangeSpeakers
                                            ? 'subtitle1'
                                            : titleVariant
                                    }
                                    color={
                                        isThemeV2
                                            ? 'text.default.primary'
                                            : activeLightTheme
                                            ? 'ambience.23'
                                            : 'ambience.0'
                                    }
                                    style={{ flexGrow: 1, ...titleStyle }}
                                    isThemeV2={isThemeV2}
                                >
                                    {title}
                                </Typography>
                            )}
                            <HeaderActions
                                alignItems='flex-end'
                                showClose={showClose}
                            >
                                {headerActions}
                                {showSaveBtn && (
                                    <SaveBtnContainer>
                                        <StyledButton
                                            variant={
                                                showCancelVariant
                                                    ? showCancelVariant
                                                    : 'primary'
                                            }
                                            onClick={onClose}
                                            closeIconAlignment={
                                                closeIconAlignment
                                            }
                                            minWidth={'84px'}
                                            mr={2}
                                            style={{
                                                backgroundColor:
                                                    theme.colors.ambience[
                                                        theme?.isLightTheme
                                                            ? 23
                                                            : 18
                                                    ],
                                                border: theme?.isLightTheme
                                                    ? `solid 1px ${theme.colors.ambience[18]}`
                                                    : '',
                                            }}
                                            darkText={theme?.isLightTheme}
                                        >
                                            {cancelBtnText}
                                        </StyledButton>
                                        <StyledButton
                                            variant={
                                                showSaveVariant
                                                    ? showSaveVariant
                                                    : 'primary'
                                            }
                                            onClick={onSaveBtnClick}
                                            closeIconAlignment={
                                                closeIconAlignment
                                            }
                                            minWidth={'77px'}
                                            loading={isBtnLoading}
                                        >
                                            {saveBtnText}
                                        </StyledButton>
                                    </SaveBtnContainer>
                                )}
                            </HeaderActions>
                        </FlexDiv>
                        {closeIconAlignment && subtitle && (
                            <Typography
                                variant={subtitleVariant}
                                color={subtitleColor}
                                mt={0}
                                style={{
                                    maxWidth: isReArrangeSpeakers
                                        ? '330px'
                                        : '100%',
                                    ...(subtitleStyle || {}),
                                }}
                                isThemeV2={isThemeV2}
                            >
                                {subtitle}
                            </Typography>
                        )}
                        {showClose && (
                            <ButtonClose
                                variant={'link'}
                                onClick={onClose}
                                onKeyPress={onKeyPressClose}
                                closeIconAlignment={closeIconAlignment}
                                isReArrangeSpeakers={isReArrangeSpeakers}
                                closeButtonAlignment={closeButtonAlignment}
                                closeButtonRound={closeButtonRound}
                                style={closeButtonStyles}
                                hideCloseActiveBack={hideCloseActiveBack}
                                customCloseRoundButtonStyle={
                                    customCloseRoundButtonStyle
                                }
                                btnTextStyles={closeButtonTextStyles}
                                aria-label='close'
                                isThemeV2={isThemeV2}
                                closeBtnFill={closeBtnFill}
                            >
                                <StyledSvgSprite
                                    icon={'icon-times'}
                                    height={closeBtnIconSize}
                                    width={closeBtnIconSize}
                                    fill={
                                        closeBtnFill
                                            ? closeBtnFill
                                            : isThemeV2
                                            ? 'text.default.primary'
                                            : activeLightTheme
                                            ? 'ambience.23'
                                            : 'ambience.0'
                                    }
                                />
                            </ButtonClose>
                        )}
                    </Header>
                ) : null}
                {subtitle && !closeIconAlignment && (
                    <Typography
                        variant={
                            isThemeV2
                                ? subtitleVariant
                                : subtitleVariant || 'body2'
                        }
                        color={
                            isThemeV2 ? 'text.default.secondary' : 'ambience.8'
                        }
                        pt={isThemeV2 ? '' : 0}
                        pb={3}
                        px={4}
                        style={subtitleStyle}
                        isThemeV2={isThemeV2}
                    >
                        {subtitle}
                    </Typography>
                )}
                <Body
                    activeLightTheme={activeLightTheme}
                    footer={footer}
                    style={bodyStyle || {}}
                    verticalWidth={verticalWidth}
                    thumbColor={scrollThumbColor}
                    trackColor={scrollTrackColor}
                    trackRadius={ScrollTrackRadius}
                    cornerRadius={scrollCornerRadius}
                    height={height}
                    isStaticColorScroll={isStaticColorScroll}
                    isThemeV2={isThemeV2}
                >
                    {children}
                </Body>
                {footer && (
                    <Footer
                        style={footerStyle || {}}
                        width={width}
                        isThemeV2={isThemeV2}
                        bg={bg}
                    >
                        {footer}
                    </Footer>
                )}
            </DrawerContainer>
            {forceLockBodyScroll && <LockBodyScrollStyle />}
        </>
    );

    const withPortal = (content) => {
        return (
            <Portal>
                {focusTrapSupport ? <FocusTrap>{content}</FocusTrap> : content}
            </Portal>
        );
    };

    return portal ? withPortal(content) : content;
}

export default withErrorBoundary(Drawer, DashboardModules.GENERAL_DRAWER);

const getBgColor = ({ theme, isThemeV2, bg }) =>
    get(theme.colors, bg)
        ? `background-color: ${get(theme.colors, bg)};`
        : isThemeV2
        ? theme?.isDashboardBrandingPage
            ? `background-color: ${theme.colors.fill.default1};`
            : getCardBackgroundStyle({ theme })
        : `background: ${theme.colors.ambience[theme.isLightTheme ? 24 : 21]};`;

const StyledButton = styled(Button)`
    border-radius: 4px;
    height: 36px;
    padding: 5px;
    ${({ darkText, theme }) =>
        darkText
            ? `
        p {
            color: ${theme.colors.ambience[1]};
        }
    `
            : ''}
`;

const DrawerOverlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${({ zIndex }) => zIndex};
    background-color: ${({ activeLightTheme, theme, isThemeV2 }) =>
        isThemeV2
            ? theme.overlay.bgColor
            : activeLightTheme
            ? alpha(theme.colors.ambience[2], 0.6)
            : theme?.isLightTheme
            ? 'rgba(10, 9, 11, 0.5)'
            : alpha(theme.colors.ambience[24], 0.8)};
    ${({ showBackDropFilter, backdropFilter, isThemeV2, theme }) =>
        showBackDropFilter &&
        (isThemeV2
            ? `backdrop-filter: ${theme.overlay.bgBlur};`
            : `backdrop-filter: blur(${backdropFilter});`)}
    ${({ hideDrawerComponents }) =>
        hideDrawerComponents &&
        `
        opacity: 0 !important;
        visibility: hidden;
    `};
`;
const Header = styled.div`
    max-width: ${({ width }) => width};
    width: 100%;
    display: block;
    padding: ${({ isThemeV2, theme }) =>
        isThemeV2
            ? `${theme.space.x6} ${theme.space.x7} ${theme.space.x3} ${theme.space.x7}`
            : '40px 32px 12px 32px'};
    ${({ theme, isThemeV2, bg }) => getBgColor({ theme, isThemeV2, bg })};
    ${({ editDeleteIcons }) =>
        editDeleteIcons &&
        `
        display: flex;
        justify-content: space-between;
    `}
    ${({ showBackButton }) =>
        showBackButton &&
        `
        display: flex;
        align-items: center;
    `}
`;

const SaveBtnContainer = styled(FlexDiv)``;

const Footer = styled.div`
    max-width: ${({ width }) => width};
    width: 100%;
    display: block;
    padding: 12px 32px 32px 32px;
    ${({ theme, isThemeV2, bg }) => getBgColor({ theme, isThemeV2, bg })};
    z-index: 2;
`;

const Body = styled(FlexDiv)`
    height: ${({ height }) => height};
    overflow: hidden auto;
    padding: ${({ isThemeV2, footer, theme }) =>
        isThemeV2
            ? `${theme.space.x3} ${theme.space.x7} ${theme.space.x6} ${theme.space.x7}`
            : `12px 32px ${footer ? '12px' : '32px'} 32px`};
    flex-grow: 1;

    ${({ activeLightTheme }) =>
        activeLightTheme &&
        `
        padding-top: 0px;
    `}

    ${({
        verticalWidth,
        theme,
        isStaticColorScroll,
        thumbColor,
        trackColor,
        trackRadius,
        cornerRadius,
    }) =>
        isStaticColorScroll
            ? getStaticColorScroll({
                  verticalWidth,
                  thumbColor,
                  trackColor,
                  trackRadius,
                  cornerRadius,
                  theme,
              })
            : getCustomScroll({ theme, verticalWidth })};
`;
const DrawerContainer = styled(motion.div)`
    max-width: ${({ width }) => width};
    width: 100%;
    position: fixed;
    height: ${({ height }) => height};
    ${({ theme, isThemeV2, bg }) => getBgColor({ theme, isThemeV2, bg })};
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    text-align: left;
    z-index: ${({ zIndex }) => zIndex};
    ${({ animateDirection }) =>
        animateDirection === AnimateDirection.BOTTOM
            ? `
        border-radius: 24px 24px 0 0;
        bottom:0;
    `
            : `
        right: 0;
        top: 0;
    `};
    ${({ hideDrawerComponents }) =>
        hideDrawerComponents &&
        `
        opacity: 0 !important;
        visibility: hidden;
    `};
    ${({ theme, isThemeV2 }) =>
        !isThemeV2 &&
        theme.isLightTheme &&
        `
            box-shadow: 0 0 10px ${alpha(theme.colors.ambience[0], 0.3)};
    `};
    ${({ drawerCustomStyles }) => drawerCustomStyles && drawerCustomStyles};
`;

const ButtonClose = styled(Button)`
    position: absolute;
    top: ${({ isThemeV2, theme }) => (isThemeV2 ? theme.space.x6 : '24px')};
    right: ${({ isThemeV2, theme }) => (isThemeV2 ? theme.space.x7 : '32px')};
    z-index: 1050;
    &:hover {
        opacity: 0.85;
    }
    &:focus {
        outline: 0;
        box-shadow: none;
    }
    ${({ closeIconAlignment }) =>
        closeIconAlignment &&
        `
        top: 44px;
    `}
    ${({ closeButtonAlignment }) =>
        closeButtonAlignment &&
        `
        top: 32px;
    `}

    ${({ isReArrangeSpeakers, closeIconAlignment }) =>
        isReArrangeSpeakers &&
        closeIconAlignment &&
        `
        top: 39px;
    `}
    ${({ closeButtonRound, theme }) =>
        closeButtonRound &&
        `
        width: 48px;
        height: 48px;
        border-radius: 100%;
        background: ${alpha(theme.colors.ambience[9], 0.16)};
        top: 32px;
    `}

    ${({ closeButtonRound, customCloseRoundButtonStyle }) =>
        closeButtonRound &&
        customCloseRoundButtonStyle &&
        customCloseRoundButtonStyle}

    ${({ hideCloseActiveBack }) =>
        hideCloseActiveBack &&
        `
        display: flex;
        align-items: center;
    `}
    ${({ isThemeV2, closeBtnFill, theme }) =>
        isThemeV2
            ? `
                svg {
                    fill: ${closeBtnFill || theme.colors.text.default.primary};
                }
            `
            : ''}
`;

const HeaderActions = styled(FlexDiv)`
    ${({ showClose }) =>
        showClose &&
        `
        padding-right: 2em;
    `}
`;

const LockBodyScrollStyle = createGlobalStyle`
    body,html {
        overflow: hidden !important;
    }
`;
