import moment from 'moment';
import React, { useMemo } from 'react';
import {
    ANALYTICS_ENGAGEMENT_TAB,
    ANALYTICS_POST_EVENT_TAB,
    ANALYTICS_REGISTRATIONS_TAB,
    ANALYTICS_SUMMARY_TAB,
} from '../constants/tabs';
import EngagementTab from './EngagementTab';
import PostEventTab from './PostEventTab';
import RegistrationsTab from './RegistrationsTab';
import SummaryTab from './SummaryTab';
import { CUT_OFF_DATE_ENGAGEMENT_ANALYTICS } from './utils';

const analyticsTabs = [
    {
        id: ANALYTICS_SUMMARY_TAB,
        title: 'Summary',
        disabled: false,
        component: SummaryTab,
    },
    {
        id: ANALYTICS_REGISTRATIONS_TAB,
        title: 'Registrations',
        disabled: false,
        component: RegistrationsTab,
        visibleBeforeEventStart: true,
    },
    {
        id: ANALYTICS_ENGAGEMENT_TAB,
        title: 'Engagement',
        disabled: false,
        component: EngagementTab,
    },
    {
        id: ANALYTICS_POST_EVENT_TAB,
        title: 'On-demand event',
        disabled: false,
        component: PostEventTab,
    },
];

export const getAnalyticsTabs = (
    eventStarted,
    airmeetStartTime,
    isOldEvent = false,
    isPaidPlan,
    isInPersonEvent = false,
    isFreeTrialPlanDeactivated = false,
    userEmail = ''
) => {
    const isAlphaUser = (userEmail || '').trim().endsWith('@airmeet.com');

    return isOldEvent
        ? [analyticsTabs.find((tab) => tab?.id === analyticsTabs[0]?.id)]
        : analyticsTabs
              .filter(
                  (item) =>
                      (eventStarted || item?.visibleBeforeEventStart) &&
                      (item?.id !== ANALYTICS_ENGAGEMENT_TAB ||
                          moment(airmeetStartTime).isAfter(
                              CUT_OFF_DATE_ENGAGEMENT_ANALYTICS
                          )) &&
                      (!isInPersonEvent || item?.visibleBeforeEventStart) &&
                      (item?.id !== ANALYTICS_POST_EVENT_TAB || isAlphaUser)
              )
              .map((tab) => ({
                  ...tab,
                  isPremium:
                      tab?.id !== analyticsTabs[0]?.id &&
                      (!isPaidPlan || isFreeTrialPlanDeactivated),
              }));
};

export default function TabContent({ activeTabId, tabs }) {
    const Component = useMemo(
        () => (tabs || []).find((tab) => tab?.id === activeTabId)?.component,
        [activeTabId, tabs]
    );

    return Component ? <Component /> : null;
}
