import React, { useContext } from 'react';
import { AnalyticsContext } from '../AnalyticsProvider';
import SelectiveBarChartCard from '../components/SelectiveBarChartCard';

const topDataInfo = [
    {
        key: 'stats.activity.total',
        title: 'Booth Active Visitors',
        info: 'This is the number of unique users who performed an activity.',
    },
    {
        key: 'stats.activity.cta',
        title: 'Clicked CTA',
        backgroundRGB: '255,185,0',
    },
    {
        key: 'stats.activity.broadcast',
        title: 'Watched Live Broadcast',
        backgroundRGB: '0,152,98',
    },
    {
        key: 'stats.activity.banner_video',
        title: 'Watched Banner Video',
        backgroundRGB: '0,152,98',
    },
    {
        key: 'stats.activity.interacted_exhibitor',
        title: 'Interacted With Exhibitor',
        backgroundRGB: '0,101,65',
    },
];

const dataInfo = topDataInfo.filter(
    (item) => item.key !== 'stats.activity.total'
);

export default function BoothActiveAttendanceCard() {
    const { getRequest, timezone, boothTags, boothOptions } = useContext(
        AnalyticsContext
    );

    return (
        <SelectiveBarChartCard
            title='Booth Active Visitors'
            type='booth'
            icon='booths'
            searchTags={boothTags}
            searchOptions={boothOptions}
            getRequest={getRequest}
            timezone={timezone}
            topDataInfo={topDataInfo}
            dataInfo={dataInfo}
            endpoint='booth-analytics'
            perDayDataPath='stats.attendance_per_day'
        />
    );
}
