import React from 'react';
import { SUPPORTED_THIRD_PARTIES } from 'views/third-party-coupons';
import { cdnImage } from '../../utils/core';

function ThirdPartyLogo({ thirdParty }) {
    const logoPath = cdnImage('/images/appsumo-logo.svg');
    return (
        <span className='logo'>
            {thirdParty === SUPPORTED_THIRD_PARTIES.APPSUMO ? (
                <img
                    src={logoPath}
                    alt='AppSumo'
                    title='AppSumo'
                    className='m-100'
                />
            ) : (
                ''
            )}
        </span>
    );
}

export default ThirdPartyLogo;
