import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import { logger } from 'utils/logger';

const { WRITE } = FEATURE_ACTIONS[FEATURE_NAMES.USER_HEAR_BEAT];

const heartBeatWriter = ({ airmeetId, client, metaData, payload }) => {
    const { action, userId, contextType, contextId } = metaData;

    if (!action) {
        throw new Error('No action is specified for heart beat update');
    }

    if (!contextType) {
        throw new Error('No contextType is specified for heart beat update');
    }

    if (!contextId) {
        throw new Error('No contextId is specified for heart beat update');
    }

    if (!userId) {
        throw new Error('No userId is specified for heart beat update');
    }

    if (action === WRITE) {
        const _path = `${airmeetId}/heartBeat/${contextType}/${contextId}/${userId}`;
        client.setDataAsync(_path, payload);
        logger.info(`Sent heart beat for ${contextType} ${contextId}`);
    }
};

export default heartBeatWriter;
