import Box from 'atoms/Box';
import Tooltip from 'components/tooltip/Tooltip';
import Icon from 'foundations/icon';
import useDashboardFeatureFlags from 'hooks/community/useDashboardFeatureFlags';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { IS_CUSTOM_TENANT } from 'utils/tenant';
import { Typography } from '../../foundations/typography';
import { showAppModal } from '../../store/actions/layout';
import { cdnImage } from '../../utils/core';
import {
    MODAL_TYPE_COMMUNITY_SWITCHER,
    MODAL_TYPE_CREATE_MULTI_COMMUNITY,
} from '../modals';

function CommunityDropdown() {
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const { isRolesAndPermissionsEnabled } = useDashboardFeatureFlags({});

    const { myCommunity, current } = useSelector((state) => state.Community);
    const roleName = useSelector((state) => state.members.roleName) || 'Admin';
    const isCurrentCommunityManager = current?.manager_id === user?.id;

    const getRoleDescription = (role = '') => {
        if (isRolesAndPermissionsEnabled) {
            if (isCurrentCommunityManager) return 'You are an Owner';

            return `You are ${
                ['A', 'E', 'I', 'O', 'U', 'a', 'e', 'i', 'o', 'u'].includes(
                    roleName.charAt(0)
                )
                    ? 'an'
                    : 'a'
            } ${roleName}`;
        } else {
            return ` ${
                role === 'owner'
                    ? 'You are a community manager'
                    : role === 'admin'
                    ? 'You are an event manager'
                    : ''
            }`;
        }
    };

    const showCreateCommunityModal = () => {
        dispatch(showAppModal(MODAL_TYPE_CREATE_MULTI_COMMUNITY, { user }));
    };

    const handleModalOpen = () => {
        dispatch(
            showAppModal(MODAL_TYPE_COMMUNITY_SWITCHER, {
                showCreateCommunityModal,
                getRoleDescription,
                isRolesAndPermissionsEnabled,
            })
        );
    };
    return (
        <Box pl={'32px'} pr={'16px'}>
            <Tooltip
                effect={'float'}
                toolClass={'darkThemeTooltip communitySwitchTooltip hide-arrow'}
                place={'bottom'}
                toolStyles={{
                    offset: { left: -85 },
                }}
                renderElement={
                    <ToolTipContent>
                        <Typography variant={'subtext2'} color={'ambience.0'}>
                            Switch between communities
                        </Typography>
                    </ToolTipContent>
                }
                renderTrigger={
                    <CommunityWrapper
                        onClick={handleModalOpen}
                        data-tip='Switch between communities'
                        id='community-switch-btn'
                    >
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <Icon
                                icon={'icon-polygon-right'}
                                width={24}
                                height={17}
                                fill={'accentPrimary.0'}
                            />
                        </Box>
                        {user && myCommunity.list && myCommunity.list.length ? (
                            <>
                                <CommunityBadge
                                    backgroundImage={`url(${current.community_image_url})`}
                                    showDefault={!!!current.community_image_url}
                                />
                                {myCommunity.list.length ? (
                                    <Typography
                                        variant='subtitle2'
                                        color='ambience.0'
                                    >
                                        {current.name
                                            ? current.name
                                            : 'Community Dashboard'}
                                    </Typography>
                                ) : (
                                    <Typography
                                        variant='subtitle2'
                                        color='ambience.0'
                                    >
                                        {current.name
                                            ? current.name
                                            : 'Community Dashboard'}
                                    </Typography>
                                )}
                                <Typography
                                    variant='subtext2'
                                    color='ambience.1'
                                    mt={'4px'}
                                >
                                    {getRoleDescription(
                                        isCurrentCommunityManager
                                            ? 'owner'
                                            : 'admin'
                                    )}
                                </Typography>
                            </>
                        ) : null}
                    </CommunityWrapper>
                }
            />

            <Typography
                variant={'subtext2'}
                color={'ambience.1'}
                mt={'19px'}
                mb={'16px'}
            >
                Manage your organization:
            </Typography>
        </Box>
    );
}

export default CommunityDropdown;

const CommunityBadge = styled(Box)`
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.ambience[19]};
    background-position: center;
    background-size: cover;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    height: 32px;
    width: 32px;

    ${({ showDefault }) =>
        showDefault &&
        `
            background-image: url(${cdnImage(
                '/images/community/default-community-logo.svg'
            )});
            background-repeat: no-repeat;
            background-size: 16px;
        `}
`;

const CommunityWrapper = styled(Box)`
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid
        ${({ theme }) =>
            IS_CUSTOM_TENANT
                ? theme.colors.accentPrimary[1]
                : theme.colors.lighting[3]};
    cursor: pointer;
`;

const ToolTipContent = styled(Box)``;
