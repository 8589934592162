import { serializeError } from 'serialize-error';
import serialize from 'serialize-javascript';
import ConsoleInterceptor from './interceptors/ConsoleInterceptor';
import SentryInterceptor from './interceptors/SentryInterceptor';
import LogInterface from './LogInterface';

// A local instance of the logger (fallback in case the worker is not bound yet)
export const localLogger = new LogInterface({
    prefix: 'LOCAL',
    interceptors: [ConsoleInterceptor, SentryInterceptor],
});

// Allow creating new interfaces via this default instance
export const bindModuleLogger = (prefix, color) => {
    return new LogInterface({
        ...localLogger.options,
        prefix,
        color,
    });
};

// Allow reusing a certain level only fromt he local logger
export const bindFunctionLogger = (prefix, color, level) => {
    return localLogger.init(prefix, color, level);
};

// Allow attaching the worker at runtime
export const attachWorker = (worker) => {
    if (worker && worker.log) {
        // Pass all log calls into the worker
        LogInterface.prototype.logCommon = function (opts, ...args) {
            const mapped = args.map(_serialize);
            opts.color = opts.color || this.options.color;
            opts.prefix = opts.prefix || this.options.prefix;
            worker.log(opts, ...mapped);
        };
    }
};

// Utility
function _serialize(args) {
    try {
        if (args instanceof Error) {
            return serializeError(args);
        } else if (typeof args === 'string') {
            return args;
        } else {
            return serialize(args, { isJSON: true, ignoreFunction: true });
        }
    } catch (err) {
        console.log(
            'Non serializable log detected, please check log data',
            args
        );
        return `[NON-SERIALIZABLE-ENTITY-${(typeof args).toUpperCase()}]`;
    }
}
