import { createEventBus } from 'ts-event-bus';
import { Unsubscribe } from 'ts-event-bus/build/Slot';
import AppEventsBridge from './AppEventsBridge';
import {
    AppBridgeEvents,
    BridgeCallbackFunction,
    ChannelBridgeEvents,
    ChannelBridgeOptions,
} from './types';

export default class ChannelBridge<TEvents extends ChannelBridgeEvents> {
    name: string;
    options: ChannelBridgeOptions;

    bridge: AppEventsBridge<AppBridgeEvents>;
    mainChannel: TEvents;

    callbacks: { [event: string]: BridgeCallbackFunction[] };

    defaultSubs: Unsubscribe[] = [];
    eventSubs: {
        [event: string]: Unsubscribe;
    } = {};

    constructor(name: string, events: TEvents, opts?: ChannelBridgeOptions) {
        this.name = name;
        this.options = opts;
        this.callbacks = {};

        this.mainChannel = createEventBus<TEvents>({
            events,
        });
    }

    initialize() {
        if (this.options) {
            const { emitter, callbacks, events } = this.options;

            // If events are provided, hook them into the channel
            if (emitter && events && events.length) {
                for (const evtKey of events) {
                    emitter.on(evtKey, (...args) =>
                        this.mainChannel.notification({
                            event: evtKey,
                            data: args,
                        })
                    );
                }
            }

            // If callbacks ae provided, hook them into the channel, and bridge
            if (callbacks && Object.keys(callbacks).length) {
                Object.entries(callbacks).forEach(([event, callback]) =>
                    this.onEvent(event, callback)
                );
            }
        }
    }

    cleanup() {
        // Clean up local subs
        this.defaultSubs.forEach((s) => s());
        Object.values(this.eventSubs).forEach((unsub) => unsub());

        this.defaultSubs = [];
        this.eventSubs = {};
    }

    setupDefaultSubs() {
        // Do this only once
        if (this.defaultSubs.length) return;

        // Hook into general notifications on the main bridge
        this.defaultSubs.push(
            this.bridge.defaultChannel.notify.on(({ from, event, data }) => {
                if (this.callbacks[event]) {
                    this.callbacks[event].forEach((c) =>
                        c({ event, from, data })
                    );
                }
            })
        );

        // Hook into current channel notifications
        this.defaultSubs.push(
            this.mainChannel.notify.on(({ from, event, data }) => {
                if (this.callbacks[event]) {
                    this.callbacks[event].forEach((c) =>
                        c({ event, from, data })
                    );
                }
            })
        );
    }

    register(bridge: AppEventsBridge<AppBridgeEvents>) {
        this.bridge = bridge;

        // Initialize our channel using this main bridge
        this.initialize();

        // Register ourselves as a channel on the main bridge
        this.bridge.register(this.name, this);
    }

    unregister() {
        // Remove ourselves from the main bridge
        this.bridge.unregister(this.name);

        // Cleanup
        this.cleanup();
    }

    onEvent(event: string, callback: BridgeCallbackFunction) {
        // Register the event, and handler
        if (!this.callbacks[event]) {
            this.callbacks[event] = [];
        }
        this.callbacks[event].push(callback);

        // Setup default subs, if not already done
        if (!this.defaultSubs.length) {
            this.setupDefaultSubs();
        }

        // No existing global sub
        if (!this.eventSubs[event]) {
            this.eventSubs[
                event
            ] = this.bridge.defaultChannel.notifyAll.on(
                event,
                ({ event, from, data }) =>
                    this.callbacks[event].forEach((c) =>
                        c({ event, from, data })
                    )
            );
        }
    }

    offEvent(event: string, callback: BridgeCallbackFunction) {
        if (!this.callbacks[event]) return;

        // Unregister all handlers on the event
        this.callbacks[event] = this.callbacks[event].filter(
            (c) => c !== callback
        );

        // No more listeners on this event
        if (this.callbacks[event].length <= 0 && this.eventSubs[event]) {
            this.eventSubs[event]();

            delete this.eventSubs[event];
            delete this.callbacks[event];
        }

        // No more callbacks
        if (Object.keys(this.callbacks).length <= 0) {
            this.cleanup();
        }
    }
}
