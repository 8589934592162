import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text/';
import React from 'react';
import styled from 'styled-components';
import { cdnImage } from 'utils/core';
import { getCurrentTenantData } from 'utils/tenant';

export default function SessionStreamEmpty() {
    return (
        <FlexDiv
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            flexGrow={1}
            py={4}
        >
            <Image
                src={cdnImage('/images/bad-luck.svg')}
                alt='No stream keys available'
            />

            <Text variant='h5' color='text.default.primary' mb='x2'>
                No Stream keys generated
            </Text>
            <Text variant='caption' color='text.default.secondary'>
                Create a “Stream into {getCurrentTenantData('name')}” activity
                to generate stream keys
            </Text>
        </FlexDiv>
    );
}

const Image = styled.img`
    width: auto;
    height: 140px;
    margin-bottom: 30px;
`;
