import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import FirebaseLogger from 'utils/firebaseLogger';

export default function useCommunityDashboardAnalytics() {
    const { current: community } = useSelector((state) => state.Community);
    const { user } = useSelector((state) => state.auth);

    const logAnalyticsEvent = useCallback(
        ({ category, event, data = {} }) => {
            FirebaseLogger.cmDash({
                category,
                event,
                eventData: {
                    community_id: community?.communityId,
                    community_name: community?.name,
                    user_role:
                        community?.manager_id === user?.id
                            ? 'Community Manager'
                            : 'Event Manager',
                    uid: user?.id,
                    ...data,
                },
            });
        },
        [community, user]
    );

    return { logAnalyticsEvent };
}
