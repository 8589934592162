import debounce from 'lodash/debounce';
import identity from 'lodash/identity';
import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
// eslint-disable-next-line no-restricted-imports
import { Trans as _Trans, useTranslation } from 'react-i18next';

const AirmeetTranslation = createContext(null);

export const useCustomTranslation = (callback = identity) => {
    const ctx = useContext(AirmeetTranslation);

    // Return the up-to-date selected value
    const finalValue = callback(ctx);
    return useMemo(() => finalValue, [finalValue]);
};

const xpathExpression = "//*[starts-with(text(),'##')]";

const highlightTranslatedStrings = () => {
    let translations = document.evaluate(
        xpathExpression,
        document.body,
        null,
        XPathResult.ANY_TYPE,
        null
    );
    var thisTranslation = translations.iterateNext();
    const nodes = [];
    while (thisTranslation) {
        nodes.push(thisTranslation);
        thisTranslation = translations.iterateNext();
    }
    nodes.forEach((node) => {
        node.innerHTML = node.innerHTML.replace(/##(.*)##/g, '<mark>$1</mark>');
    });
};

const debouncedHighlightTranslatedStrings = debounce(
    highlightTranslatedStrings,
    300
);

export function AirmeetTranslationProvider({ children }) {
    const { t, ...rest } = useTranslation();
    const [isTestTranslationEnabled, setIsTestTranslationEnabled] = useState(
        false
    );

    const tr = useCallback(
        (key, param = {}) => {
            debouncedHighlightTranslatedStrings();
            return `##${t(key, param)}##`;
        },
        [t]
    );

    if (['dev', 'test'].includes(process.env.REACT_APP_ENV)) {
        window.testTranslations = (val) => {
            setIsTestTranslationEnabled(val);
            return val;
        };
    }

    return (
        <AirmeetTranslation.Provider
            value={{
                t: isTestTranslationEnabled ? tr : t,
                ...rest,
            }}
        >
            {children}
        </AirmeetTranslation.Provider>
    );
}

export const Trans = _Trans;
