import {
    FETCH_DEMO_EVENTS_RESPONSE,
    UPDATE_SLIDER_STATE,
} from 'store/actions/onboarding';
import { LOUNGE_SLIDER } from 'components/newOnboardingFlow/config';
const initialState = {
    demoEventsTotalCount: 0,
    demoEventsTotalPages: 0,
    sliderState: LOUNGE_SLIDER,
};

function onboarding(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_DEMO_EVENTS_RESPONSE:
            return {
                ...state,
                demoEventsTotalCount: payload.total_count,
                demoEventsTotalPages: payload.total_pages,
            };
        case UPDATE_SLIDER_STATE:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
}

export default onboarding;
