import { CALL_API } from '../../api';
import { API_SERVICE } from '../../../utils/apiService';
export const FETCH_ONBOARDING_REQUEST = 'FETCH_ONBOARDING_REQUEST';
export const FETCH_ONBOARDING_RESPONSE = 'FETCH_ONBOARDING_RESPONSE';
export const FETCH_DEMO_EVENTS_REQUEST = 'FETCH_DEMO_EVENTS_REQUEST';
export const FETCH_DEMO_EVENTS_RESPONSE = 'FETCH_DEMO_EVENTS_RESPONSE';
export const FETCH_OPEN_EVENTS_REQUEST = 'FETCH_OPEN_EVENTS_REQUEST';
export const FETCH_OPEN_EVENTS_RESPONSE = 'FETCH_OPEN_EVENTS_RESPONSE';
export const UPDATE_SLIDER_STATE = 'UPDATE_SLIDER_STATE';

export function fetchOnboardingData() {
    return {
        [CALL_API]: {
            types: [FETCH_ONBOARDING_REQUEST, FETCH_ONBOARDING_RESPONSE],
            endpoint: '/metadata/community-onboarding',
            service: API_SERVICE.BFF,
            type: 'json',
            method: 'GET',
        },
    };
}

export function fetchDemoEvents({ communityId, pageNumber, pageSize }) {
    return {
        [CALL_API]: {
            types: [FETCH_DEMO_EVENTS_REQUEST, FETCH_DEMO_EVENTS_RESPONSE],
            endpoint: `/airmeet/community/demo-airmeets?community_id=${communityId}&page_number=${pageNumber}&page_size=${pageSize}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.CAS_BFF,
        },
    };
}

export function fetchOpenEvents() {
    return {
        [CALL_API]: {
            types: [FETCH_OPEN_EVENTS_REQUEST, FETCH_OPEN_EVENTS_RESPONSE],
            endpoint: `/airmeet/featured-airmeets/ongoing`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.CAS_BFF,
        },
    };
}

export function updateSliderState(state) {
    return {
        type: UPDATE_SLIDER_STATE,
        payload: {
            sliderState: state,
        },
    };
}
