import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import cloneDeep from 'lodash/cloneDeep';
import isNumber from 'lodash/isNumber';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { AnalyticsContext } from '../AnalyticsProvider';
import ChartCard from '../components/ChartCard';
import Pill from '../components/Pill';
import { Chart } from '../styledComponents';
import { commonDoughnutOptions, getPercentage } from '../utils';
import { Legend } from './styledComponents';

const defaultDoughnutOptions = cloneDeep({ ...commonDoughnutOptions });
defaultDoughnutOptions.tooltips.callbacks = {
    label: function (tooltipItem, data) {
        let dataset = data.datasets[tooltipItem.datasetIndex];
        let index = tooltipItem.index;

        let labels = [];
        if (Array.isArray(dataset?.labels)) {
            labels = dataset.labels;
        } else {
            labels = data.labels;
        }

        return `${labels[index]?.label}: ${labels[index]?.value} (${dataset.data[index]}%)`;
    },
};

export default function TurnoutCard({ loading, data }) {
    const { theme } = useTheme();
    const [selectedType, setSelectedType] = useState('all');
    const { all, repeat } = data?.event || {};
    const { hasTicketingData } = useContext(AnalyticsContext);

    const {
        registrations,
        attendees,
        invite_attendees,
        self_attendees,
        ticket_attendees,
    } = useMemo(() => {
        if (selectedType === 'repeat') {
            return repeat || {};
        }
        return all || {};
    }, [selectedType, all, repeat]);

    const isEmpty = !isNumber(registrations);
    const selfAttendees = hasTicketingData ? ticket_attendees : self_attendees;

    const turnoutPercent = useMemo(
        () => getPercentage(attendees, registrations),
        [registrations, attendees]
    );

    const inviteAttendeesPercent = useMemo(
        () => getPercentage(invite_attendees, selfAttendees + invite_attendees),
        [selfAttendees, invite_attendees]
    );

    const chartData = useCallback(
        (canvas) => {
            const context = canvas.getContext('2d');
            const grad = context.createLinearGradient(0, 0, 80, 80);
            grad.addColorStop(0, 'rgba(83, 50, 230, 1)');
            grad.addColorStop(1, 'rgba(164, 95, 210, 1)');

            return {
                labels: [{ label: 'Attended', value: attendees }],
                datasets: [
                    {
                        data: [turnoutPercent, 100 - turnoutPercent],
                        backgroundColor: [
                            grad,
                            theme?.isLightTheme
                                ? theme.colors.ambience[21]
                                : '#26252D',
                        ],
                        borderWidth: 0,
                    },
                ],
            };
        },
        [turnoutPercent, attendees]
    );

    const attendeeData = useCallback(
        (canvas) => {
            const context = canvas.getContext('2d');
            const grad = context.createLinearGradient(0, 0, 80, 80);
            grad.addColorStop(0, 'rgba(255, 185, 0, 1)');
            grad.addColorStop(1, 'rgba(215, 41, 62, 1)');

            const grad2 = context.createLinearGradient(0, 0, 80, 80);
            grad2.addColorStop(0, 'rgba(106, 219, 149, 1)');
            grad2.addColorStop(1, 'rgba(255, 185, 0, 1)');

            const isEmptyChart =
                isEmpty || (!selfAttendees && !invite_attendees);

            return {
                labels: isEmptyChart
                    ? []
                    : [
                          {
                              label: hasTicketingData
                                  ? 'Ticketed Attendees'
                                  : 'Self-Registered Attendees',
                              value: selfAttendees,
                          },
                          {
                              label: 'Invited Attendees',
                              value: invite_attendees,
                          },
                      ],
                datasets: [
                    {
                        data: [
                            100 - inviteAttendeesPercent,
                            inviteAttendeesPercent,
                        ],
                        backgroundColor: isEmptyChart
                            ? theme?.isLightTheme
                                ? theme.colors.ambience[21]
                                : '#26252D'
                            : [grad, grad2],
                        borderWidth:
                            isEmptyChart || inviteAttendeesPercent === 0
                                ? 0
                                : 1,
                        borderColor: '#0c0b0e',
                    },
                ],
            };
        },
        [
            inviteAttendeesPercent,
            selfAttendees,
            invite_attendees,
            isEmpty,
            hasTicketingData,
            theme,
        ]
    );

    const handlePillClick = (val) => {
        setSelectedType(val);
    };

    return (
        <ChartCard
            title={
                <FlexDiv alignItems='center'>
                    <FlexDiv pr={2}>Participant Turnout</FlexDiv>
                    <Pill
                        title='All Participants'
                        selected={selectedType === 'all'}
                        onClick={() => handlePillClick('all')}
                        mr={1}
                    />
                    <Pill
                        title='Repeat Participants'
                        selected={selectedType === 'repeat'}
                        onClick={() => handlePillClick('repeat')}
                        mr={1}
                    />
                </FlexDiv>
            }
            loading={loading}
            isEmpty={isEmpty}
        >
            <Box className='row' flexGrow='1'>
                <Box
                    className='col-sm-6'
                    style={{ borderRight: '2px solid rgba(105,101,123, 0.1)' }}
                >
                    <FlexDiv justifyContent='space-between'>
                        <FlexDiv flexDirection='column'>
                            <FlexDiv alignItems='center' pb={1}>
                                <Legend
                                    background={
                                        theme?.isLightTheme
                                            ? theme.colors.ambience[21]
                                            : '#302E38'
                                    }
                                />
                                <Typography
                                    variant='caption'
                                    color='ambience.1'
                                    pl={1}
                                >
                                    Registered
                                </Typography>
                            </FlexDiv>
                            <FlexDiv alignItems='center'>
                                <Legend background='linear-gradient(132.33deg, #5332E6 -0.67%, #A45FD2 102.54%);' />
                                <Typography
                                    variant='caption'
                                    color='ambience.1'
                                    pl={1}
                                >
                                    Attended
                                </Typography>
                            </FlexDiv>
                        </FlexDiv>
                        <Chart mx={4} dimensions='100px'>
                            <Doughnut
                                data={chartData}
                                options={{
                                    ...(defaultDoughnutOptions || {}),
                                }}
                            />
                        </Chart>
                        <FlexDiv
                            flexDirection='column'
                            alignItems='center'
                            px={2}
                        >
                            <Typography
                                textAlign='center'
                                variant='subtitle1'
                                color='ambience.0'
                            >
                                {registrations}
                            </Typography>
                            <Typography
                                textAlign='center'
                                variant='subtext0'
                                color='ambience.1'
                            >
                                Registered
                            </Typography>
                        </FlexDiv>
                        <FlexDiv
                            flexDirection='column'
                            alignItems='center'
                            pr={2}
                        >
                            <Typography
                                textAlign='center'
                                variant='subtitle1'
                                color='ambience.0'
                            >
                                {attendees}
                            </Typography>
                            <Typography
                                textAlign='center'
                                variant='subtext0'
                                color='ambience.1'
                            >
                                Attended
                            </Typography>
                        </FlexDiv>
                    </FlexDiv>
                </Box>
                <Box className='col-sm-6'>
                    <FlexDiv justifyContent='space-between'>
                        <FlexDiv flexDirection='column'>
                            <FlexDiv alignItems='center' pb={1}>
                                <Legend background='linear-gradient(135deg, #FFB900 0%, #D7293E 100%);' />
                                <Typography
                                    variant='caption'
                                    color='ambience.1'
                                    pl={1}
                                >
                                    {hasTicketingData
                                        ? 'Ticketed'
                                        : 'Self-Registered'}{' '}
                                    Attendees
                                </Typography>
                            </FlexDiv>
                            <FlexDiv alignItems='center'>
                                <Legend background='linear-gradient(132.33deg, #6ADB95 -0.67%, #FFB900 102.54%);' />
                                <Typography
                                    variant='caption'
                                    color='ambience.1'
                                    pl={1}
                                >
                                    Invited Attendees
                                </Typography>
                            </FlexDiv>
                        </FlexDiv>
                        <Chart mx={4} dimensions='100px'>
                            <Doughnut
                                data={attendeeData}
                                options={{
                                    ...(defaultDoughnutOptions || {}),
                                    cutoutPercentage: 88,
                                }}
                            />
                        </Chart>
                        <FlexDiv
                            flexDirection='column'
                            alignItems='center'
                            justifyContent='center'
                            px={2}
                        >
                            <Typography
                                textAlign='center'
                                variant='subtitle1'
                                color='ambience.0'
                            >
                                {selfAttendees}
                            </Typography>
                            <Typography
                                textAlign='center'
                                variant='subtext0'
                                color='ambience.1'
                            >
                                {hasTicketingData
                                    ? 'Ticketed'
                                    : 'Self-Registered'}{' '}
                                Attendees
                            </Typography>
                        </FlexDiv>
                        <FlexDiv
                            flexDirection='column'
                            alignItems='center'
                            justifyContent='center'
                            pr={2}
                        >
                            <Typography
                                textAlign='center'
                                variant='subtitle1'
                                color='ambience.0'
                            >
                                {invite_attendees}
                            </Typography>
                            <Typography
                                textAlign='center'
                                variant='subtext0'
                                color='ambience.1'
                            >
                                Invited Attendees
                            </Typography>
                        </FlexDiv>
                    </FlexDiv>
                </Box>
            </Box>
        </ChartCard>
    );
}
