import upperFirst from 'lodash/upperFirst';
import { getCurrentTenantData } from 'utils/tenant';

export const CONFERENCE_REPLAY_DESC =
    'Showcase your session recordings, sponsor, booths and more after the event has ended. Interactive features like chats, polls, Q&A, lounge, etc will be disabled. Event Replay analytics report can be generated from the analytics tab on demand.';

export const MEETUP_REPLAY_DESC =
    'Showcase your session recordings after the event has ended. Interactive features like chats, polls, Q&A, lounge, etc will be disabled. Event Replay analytics report can be generated from the analytics tab on demand.';

export const REPLAY_INFO =
    'Event Replay can be enabled only after ending the event.';

export const EVENT_REPLAY_INTRO_MEETUP = `The event has now ended however, you can let participants enter to watch session recordings by enabling 'Event Replay'.`;

export const EVENT_REPLAY_INTRO_CONF = `The event has now ended however, you can let participants enter to watch session recordings, visit booths etc. by enabling 'Event Replay'.`;
export const ENABLE_REPLAY_INFO_CONF =
    'Event Replay will let your attendees enter the event after it’s over to watch session recordings and visit booths. Interactive features like chat, polls, lounge and Q&A will be disabled. Event Replay analytics report can be generated from the analytics tab on demand.';

export const REPLAY_ENTRY_LABEL = {
    login: 'Registered participants only',
    email: 'Registered participants only',
    invitation: 'Only people who were invited can enter',
    ticketed: 'Ticket holders and invited participants can access Event Replay',
    domain: 'Registered participants only',
};

export const getRegisteredEntryDesc = (airmeetFormat = 'event') => ({
    login: [
        `Only participants who had registered for the ${airmeetFormat} can access ${upperFirst(
            airmeetFormat
        )} Replay.`,
        'No new registrations will be allowed.',
    ],
    email: [
        `Only participants who had registered for the ${airmeetFormat} can access ${upperFirst(
            airmeetFormat
        )} Replay.`,
        'No new registrations will be allowed.',
    ],
    invitation: [
        `Only invitees with magic links can access ${upperFirst(
            airmeetFormat
        )} Replay.`,
        'Invitees who received a magic link but did not register earlier, will be counted as a new registration subject to your plan’s registration limit.',
    ],
    domain: [
        `Only participants who had registered for the ${airmeetFormat} can access ${upperFirst(
            airmeetFormat
        )} Replay.`,
        'No new registrations will be allowed.',
    ],
    ticketed: `Ticket holders and invitees with magic links can access ${upperFirst(
        airmeetFormat
    )} Replay. Invitees who received a magic link but did not register earlier, will be counted as a new registration subject to your plan’s registration limit.`,
});

export const getOndemandEntryDescLogin = (airmeetFormat = 'event') => [
    `Anyone can access the replay after signing in with either Google, Linkedin, Facebook, Twitter, Apple ID or ${getCurrentTenantData(
        'name'
    )} login. This step verifies the person's email.`,
    `Existing participants who had registered for the ${airmeetFormat} can also watch ${upperFirst(
        airmeetFormat
    )} Replay.`,
    `New registrations will show up on the ${airmeetFormat}’s participant list.`,
    `Your plan limits will dictate the maximum number of participants who can register. Pay-as-you-go option is currently not supported.`,
];

export const getOndemandEntryDesc = (airmeetFormat = 'event') => [
    `Anyone can access the replay in just 2-steps with a unique link that will be sent to the email address provided by the registrant. This step verifies the person's email.`,
    `Existing participants who had registered for the ${airmeetFormat} can also watch ${upperFirst(
        airmeetFormat
    )} Replay.`,
    `New registrations will show up on the ${airmeetFormat}’s participant list.`,
    `Your plan limits will dictate the maximum number of participants who can register. Pay-as-you-go option is currently not supported.`,
];

export const REPLAY_ENTRY = {
    registered: 'REGISTERED_PARTICIPANTS',
    nobody: 'NOBODY',
    onDemand: 'ON_DEMAND',
};

export const REPLAY_ENTRY_INVERSE = {
    REGISTERED_PARTICIPANTS: 'registered',
    NOBODY: 'nobody',
    ON_DEMAND: 'onDemand',
};

export const EVENT_ENDED =
    'The event has now ended. Event entry type cannot be changed.';

export const EVENT_REPLAY_ENABLED =
    'You have allowed participants to enter the event. They will not have access to interactive any features like chat, polls, Q&A, lounge, etc.';
