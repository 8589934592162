import { MODAL_TYPE_ATTENDEE_BLOCKED } from 'components/modals';
import useToasts from 'hooks/useToasts';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    getMagicLinkInfo,
    loggedInWithToken,
    updateExhibitor,
} from 'store/actions/auth';
import { showFixedModal } from 'store/actions/layout';
import { verifyExhibitorToken } from 'store/actions/Lounge';
import apiRequest from 'utils/api';
import {
    CLOUD_HOST_API_LOG_PREFIX,
    isHeadlessBrowser,
} from 'utils/cloudHostLog';
import {
    CODE,
    EXHIBITOR_CODE,
    MAGIC_LINK_DETAILS,
    SERIES_MAGIC_LINK,
} from 'utils/constants/airmeet';
import { noop } from 'utils/constants/common';
import { logger } from 'utils/logger';

const INVITE_AUTH_TOKEN_API_URL = 'airmeet/session/invite/auth';

export const BLOCKED = 'BLOCKED';
export const REJECTED = 'REJECTED';
export const WAITLISTED = 'WAITLISTED';

export const MAGIC_LINK_ERRORS = {
    REGISTRATION_LIMIT_EXCEEDED: 'REGISTRATION_LIMIT_EXCEEDED',
    EVENT_FINISHED: 'EVENT_FINISHED',
    USER_DOMAIN_NOT_ALLOWED: 'USER_DOMAIN_NOT_ALLOWED',
    DEACTIVATED_COMMUNITY: 'DEACTIVATED_COMMUNITY',
    FORCE_LOGIN: 'FORCE_LOGIN',
    [WAITLISTED]: 'WAITLISTED',
};

const isValidAccessCode = (eventDetails, airmeetId, isEventSeries) => {
    return isEventSeries
        ? eventDetails?.seriesId === airmeetId
        : eventDetails?.airmeetId === airmeetId;
};
export const accessCodeInUrl = (queryParam, history) => {
    const query = new URLSearchParams(history.location.search);
    return query.get(queryParam);
};
export const getQueryParamFromUrl = (history) => {
    const query = new URLSearchParams(history.location.search);
    if (query.get(CODE)) {
        return CODE;
    } else if (query.get(EXHIBITOR_CODE)) {
        return EXHIBITOR_CODE;
    }
    return '';
};
export const getQueryParamFromSession = (airmeetId) => {
    let accessCodeDetail = getAccessCode(CODE, airmeetId, {
        eventDetail: true,
    });
    if (accessCodeDetail?.[CODE]) {
        return CODE;
    } else if (accessCodeDetail?.[EXHIBITOR_CODE]) {
        return EXHIBITOR_CODE;
    }
    return '';
};
export const getAccessCodeFromSession = (isEventSeries = false) => {
    let eventDetail = sessionStorage.getItem(
        isEventSeries ? SERIES_MAGIC_LINK : MAGIC_LINK_DETAILS
    );
    if (!eventDetail) {
        return {};
    }
    return JSON.parse(eventDetail);
};
export const setAccessCodeInSession = ({
    queryParam,
    tokenVal,
    airmeetId,
    replaceUrl = '',
    isSpeaker = false,
    isEventSeries = false,
}) => {
    let eventDetail = {
        [queryParam]: tokenVal,
        [isEventSeries ? 'seriesId' : 'airmeetId']: airmeetId,
    };
    if (isSpeaker) {
        eventDetail['isSpeaker'] = true;
    }
    try {
        sessionStorage.setItem(
            isEventSeries ? SERIES_MAGIC_LINK : MAGIC_LINK_DETAILS,
            JSON.stringify(eventDetail)
        );
        if (replaceUrl) {
            window.location.href = replaceUrl;
        }
        return true;
    } catch (error) {
        logger.error(
            'Error in setting up magic link details in session storage',
            eventDetail
        );
        return false;
    }
};
export const authenticateAccessCode = (airmeetId, isEventSeries = false) => {
    let eventDetail = getAccessCodeFromSession((isEventSeries = false));
    if (
        !isEmpty(eventDetail) &&
        !isValidAccessCode(eventDetail, airmeetId, isEventSeries)
    ) {
        const logMsg = isEventSeries
            ? 'Series access code authentication failed'
            : 'access code authentication failed';
        logger.info(logMsg, {
            eventDetails: eventDetail,
            airmeetId,
        });
        sessionStorage.removeItem(
            isEventSeries ? SERIES_MAGIC_LINK : MAGIC_LINK_DETAILS
        );
    }
};
export const getAccessCode = (queryName = '', airmeetId, options = {}) => {
    let eventDetails = getAccessCodeFromSession(options?.isEventSeries);
    if (
        !eventDetails ||
        !isValidAccessCode(eventDetails, airmeetId, options?.isEventSeries)
    ) {
        return '';
    }
    return options?.eventDetail ? eventDetails : eventDetails[queryName];
};

const useAccessCodeUserToken = (onSucess, onError, props = {}) => {
    const {
        queryName = CODE,
        airmeetId = null,
        refreshDirectUser = false,
        isMobileWebView,
        liveAirmeet = false,
        showRestrictedSSOModal,
        isSessionUpdated,
        handleForceLoginState = noop,
    } = props;
    const dispatch = useDispatch();
    const { errorToast } = useToasts();
    const history = useHistory();
    const [accessCodeUserData, setAccessCodeUserData] = useState(null);
    const airmeet = useSelector((state) => state.lounge?.airmeet);
    const redirectAndReloadToEventLandingPage = (
        imediateRedirect = false,
        airmeetId
    ) => {
        sessionStorage.removeItem(MAGIC_LINK_DETAILS);
        logger.info('Redirecting back to the the event landing page.');
        if (imediateRedirect) {
            window.location.href = `/e/${airmeetId}`;
        } else {
            setTimeout(() => {
                window.location.href = `/e/${airmeetId}?preview=true`;
            }, 3000);
        }
    };
    const ticketsConfigured = useSelector(
        (state) => state?.lounge?.airmeet?.ticketsInfo?.ticketsConfigured
    );
    const submit = async (token) => {
        try {
            if (queryName === 't') {
                const response = await apiRequest(
                    'POST',
                    INVITE_AUTH_TOKEN_API_URL,
                    {
                        token,
                    }
                );
                if (response.oAuthRequired) {
                    logger.error(
                        'EM is entering as a speaker via speaker link, redirecting to landing page'
                    );
                    redirectAndReloadToEventLandingPage(
                        true,
                        response.session.airmeet_id
                    );
                    return;
                }
                // No error thrown means success
                onSucess(response);
                setAccessCodeUserData({
                    user: response.user,
                    authToken: response.authToken,
                    accessCode: token,
                    airmeetId: response.session.airmeet_id,
                });
            } else if (queryName === EXHIBITOR_CODE) {
                const response = await dispatch(
                    verifyExhibitorToken(airmeetId, token)
                );
                if (response?.payload?.message === 'BLOCKED') {
                    dispatch(
                        showFixedModal(MODAL_TYPE_ATTENDEE_BLOCKED, {
                            airmeet,
                        })
                    );
                    return;
                }
                if (response.error) {
                    return;
                }
                dispatch(
                    updateExhibitor({
                        validToken: response.is_token_valid,
                        token: token,
                    })
                );
            } else if (airmeetId) {
                dispatch(getMagicLinkInfo({ airmeetId, code: token })).then(
                    (response) => {
                        if (response.error) {
                            const {
                                REGISTRATION_LIMIT_EXCEEDED,
                                EVENT_FINISHED,
                                USER_DOMAIN_NOT_ALLOWED,
                                DEACTIVATED_COMMUNITY,
                                WAITLISTED,
                            } = MAGIC_LINK_ERRORS;

                            if (
                                response.payload?.json?.code ===
                                USER_DOMAIN_NOT_ALLOWED
                            ) {
                                showRestrictedSSOModal({
                                    onClickBtn: redirectAndReloadToEventLandingPage,
                                });
                            } else if (
                                [
                                    REGISTRATION_LIMIT_EXCEEDED,
                                    EVENT_FINISHED,
                                ].includes(response.payload?.json?.code)
                            ) {
                                errorToast(response.payload?.json?.message);
                                redirectAndReloadToEventLandingPage(
                                    false,
                                    airmeetId
                                );
                            } else if (
                                response.payload?.json?.code ===
                                DEACTIVATED_COMMUNITY
                            ) {
                                redirectAndReloadToEventLandingPage(
                                    true,
                                    airmeetId
                                );
                            } else if (
                                response.payload?.json?.code ===
                                MAGIC_LINK_ERRORS.FORCE_LOGIN
                            ) {
                                // When an organiser is registered through an event series, then on event landing page with magic link, this case will trigger.
                                handleForceLoginState();
                            } else {
                                if (response.payload?.json?.code === BLOCKED) {
                                    dispatch(
                                        showFixedModal(
                                            MODAL_TYPE_ATTENDEE_BLOCKED
                                        )
                                    );
                                }
                                ![BLOCKED, WAITLISTED, REJECTED].includes(
                                    response.payload?.json?.code
                                ) && onError();
                            }
                        } else {
                            // No error thrown means success
                            const {
                                auth_token: authToken,
                                ...user
                            } = response.payload;
                            const data = {
                                user: {
                                    ...user,
                                    ...(user.params || {}),
                                    guest: user.is_guest,
                                    direct: user.is_guest,
                                },
                                authToken,
                                accessCode: token,
                                airmeetId,
                            };
                            onSucess(data);
                            setAccessCodeUserData(data);
                        }
                    }
                );
            }
        } catch (error) {
            if (
                error?.code === 'INVALID_ATTENDANCE_TYPE' &&
                error.response?.status === 400
            ) {
                onError(error.responseJson);
                return;
            }
            if (error.code === 'NOT_FOUND' && error.response.status === 404) {
                onError();
                return;
            }
            errorToast('Could not verify. Please try after a while.');
            if (isHeadlessBrowser()) {
                logger.error(
                    `${CLOUD_HOST_API_LOG_PREFIX}: Auth - ${INVITE_AUTH_TOKEN_API_URL}`
                );
            }
        }
    };

    const accessCode = useMemo(() => {
        if (queryName === 't' || isMobileWebView) {
            const query = new URLSearchParams(history.location.search);
            return query.get(queryName);
        }
        return getAccessCode(queryName, airmeetId);
    }, [queryName, isSessionUpdated, airmeetId, history, isMobileWebView]);

    const isTicketingEnabled = useCallback(() => {
        if (
            [EXHIBITOR_CODE, CODE].includes(
                getQueryParamFromSession(airmeetId)
            ) &&
            !getAccessCodeFromSession().isSpeaker &&
            !liveAirmeet
        ) {
            if (isBoolean(ticketsConfigured)) {
                return true;
            }
            return false;
        }
        return true;
    }, [ticketsConfigured]);

    useEffect(() => {
        // Next, try to log the speaker in
        accessCode && isTicketingEnabled() && submit(accessCode);
    }, [accessCode, refreshDirectUser, isTicketingEnabled]);

    const loginWithAccessCodeUser = useCallback(async () => {
        const { authToken, accessCode, user } = accessCodeUserData;
        dispatch(
            loggedInWithToken({
                token: authToken,
                accessCode,
                user: { guest: false, ...user },
            })
        );
    });

    return {
        accessCodeUser: accessCodeUserData ? accessCodeUserData.user : null,
        airmeetId: accessCodeUserData ? accessCodeUserData.airmeetId : null,
        accessCode: queryName !== EXHIBITOR_CODE ? accessCode : '',
        exhibitorToken: queryName === EXHIBITOR_CODE ? accessCode : '',
        loginWithAccessCodeUser,
    };
};

export default useAccessCodeUserToken;
