import FocusTrapLib from 'focus-trap-react';
import React, { Fragment } from 'react';
import { emptyObject } from 'utils/constants/common';

interface IFocusTrappedProps {
    children: React.ReactNode;
    [key: string]: FocusTrapLib.Props[keyof FocusTrapLib.Props];
}

const DEFAULT_OPTIONS = {
    allowOutsideClick: true,
    clickOutsideDeactivates: true,
    fallbackFocus: document.getElementById('fallbackFocus'),
    initialFocus: false,
};

const withWrapperEl = (children: React.ReactNode) => <div>{children}</div>;

const shouldWrapWithEl = (element: React.ReactNode) => {
    if (!React.isValidElement(element)) return false;
    if (
        (Array.isArray(element) && element.length > 1) ||
        element.type === Fragment
    )
        return true;
    return false;
};
const FocusTrap = ({
    children,
    hasTrap = true,
    focusTrapOptions = emptyObject,
    ...rest
}: IFocusTrappedProps) => {
    const shouldWrap = shouldWrapWithEl(children);

    if (!hasTrap) {
        return children;
    }

    return (
        <FocusTrapLib
            focusTrapOptions={{
                ...DEFAULT_OPTIONS,
                ...focusTrapOptions,
            }}
            {...rest}
        >
            {shouldWrap ? withWrapperEl(children) : children}
        </FocusTrapLib>
    );
};

export default FocusTrap;
