import { logger } from 'utils/logger';
import createReducer from '../../utils/createReducer';
import {
    UPDATE_RECORDED_VIDEO_CURRENT_TIME,
    UPDATE_RECORDED_VIDEO_INFO_PARAM,
    UPDATE_RECORDED_VIDEO_PARAM,
} from '../actions/recordedVideo';
import PermissionUtils from 'utils/permission-utils';

const initialState = {
    volumeLevel: 100,
    thumbnail: '',
};

const handlers = {
    [UPDATE_RECORDED_VIDEO_PARAM]: (state, { payload }) => {
        console.log(
            'UPDATE_RECORDED_VIDEO_PARAM',
            JSON.stringify(state),
            JSON.stringify(payload)
        );
        if (payload && payload.uuid !== state.uuid) {
            return {
                volumeLevel: state.volumeLevel,
                thumbnail: '',
                isLoading: null,
                currentTime: 0,
                error: '',
                uuid: payload ? state.uuid : null,
                ...payload,
            };
        }
        return {
            volumeLevel: state.volumeLevel,
            thumbnail: payload ? state.thumbnail : '',
            isLoading: payload ? state.isLoading : null,
            currentTime: payload ? state.currentTime : 0,
            uuid: payload ? state.uuid : null,
            ...payload,
        };
    },
    [UPDATE_RECORDED_VIDEO_INFO_PARAM]: (state, { payload }) => {
        console.log(
            'UPDATE_RECORDED_VIDEO_INFO_PARAM',
            JSON.stringify(state),
            JSON.stringify(payload)
        );
        // add check here
        if (payload && payload.uuid !== state.uuid) {
            if (PermissionUtils.isSessionCloudHost()) {
                logger.info(
                    'uuid changed in metadata, please check the cloudwatch UPDATE_RECORDED_VIDEO_INFO_PARAM data',
                    { existingUuid: state.uuid, updatedUuid: payload.uuid }
                );
                return state;
            } else {
                return {
                    ...state,
                    currentTime: 0,
                    error: '',
                    ...payload,
                    thumbnail: payload ? payload.thumbnail : '',
                };
            }
        }
        return {
            ...state,
            ...payload,
            thumbnail: payload ? payload.thumbnail : '',
        };
    },
    [UPDATE_RECORDED_VIDEO_CURRENT_TIME]: (state, { payload }) => {
        console.log(
            'UPDATE_RECORDED_VIDEO_CURRENT_TIME',
            JSON.stringify(state),
            JSON.stringify(payload)
        );
        if (state?.uuid !== payload.videoDataUUID) {
            return state;
        }
        return {
            ...state,
            currentTime: payload.currentTime,
        };
    },
};

export default createReducer(initialState, handlers);
