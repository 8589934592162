import useContainerAPIStore from 'atoms/Container/useContainerAPIStore';
import { useEffect, useState } from 'react';
import { API_GET_AIRMEET_VENUE_INFO } from 'services/api/constants';
import { logger } from 'utils/logger';

function useVenueData({ airmeetId, isConferenceEvent }) {
    const [venueData, setVenueData] = useState({
        data: null,
        loading: true,
        error: null,
    });

    const config = isConferenceEvent
        ? {
              [API_GET_AIRMEET_VENUE_INFO]: {
                  airmeetId,
              },
          }
        : null;
    const { [API_GET_AIRMEET_VENUE_INFO]: venueInfo } = useContainerAPIStore(
        config
    );

    useEffect(() => {
        if (!venueInfo) {
            setVenueData((prev) => ({
                ...prev,
                loading: false,
            }));
            return;
        }

        const { loading, data, error } = venueInfo;

        if (loading) {
            return;
        }

        if (error) {
            logger.warn('useVenueData:: Error in venue API : ', error);
            return;
        }

        if (!data) {
            venueInfo.refresh();
        }

        if (!loading) {
            setVenueData((prev) => ({
                ...prev,
                data,
                loading: false,
                error,
            }));
        }
    }, [venueInfo]);

    return venueData;
}

export default useVenueData;
