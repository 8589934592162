// If getting started tab needs to be re-enabled based on flag,
// use communityData.current.show_getting_started redux value
export const showGettingStarted = false;

export const isAllowedRegex = (text) => {
    // allowed values => Alphanumeric + spaces + ' + , + - + _
    const alphaRegex = RegExp("^[a-zA-Z0-9', _-]*$");
    const value = text.trim();
    return alphaRegex.test(value);
};
export const validateFormField = (orgName = '', charLimitMap) => {
    const isWithinCharacterLimits =
        orgName.trim().length >= charLimitMap.min &&
        orgName.trim().length <= charLimitMap.max;

    const isRegexPassed = isAllowedRegex(orgName);

    return { isWithinCharacterLimits, isRegexPassed };
};
