import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';

const { SOCIAL_STREAMING } = FEATURE_NAMES;

const socialStreamingWriter = async ({
    client,
    metaData,
    payload,
    airmeetId,
    sessionId,
}) => {
    const { actionType } = metaData;

    if (actionType === FEATURE_ACTIONS[SOCIAL_STREAMING].START) {
        delete payload.timestamp;
        client.setData(
            `${airmeetId}/liveAirmeet/sessions/${sessionId}/socialStreaming`,
            payload
        );
    }
    if (actionType === FEATURE_ACTIONS[SOCIAL_STREAMING].STOP) {
        client.setData(
            `${airmeetId}/liveAirmeet/sessions/${sessionId}/socialStreaming`,
            payload
        );
    }
};

export default socialStreamingWriter;
