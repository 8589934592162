// Adding constant Languages here for now
// Later it will be stored at backend
export const Languages = [
    {
        key: 'en',
        changeTo: 'Change to English',
        seeAll: 'See all languages',
        name: 'English',
        localizedName: 'English',
    },
    {
        key: 'zh',
        changeTo: '更改为 简体中文',
        seeAll: '查看所有语言',
        localizedName: '简体中文',
        name: 'Chinese (Simplified)',
    },
    {
        key: 'fr',
        changeTo: 'Changer en Français',
        seeAll: 'Voir toutes les langues',
        localizedName: 'Français',
        name: 'French',
    },
    {
        key: 'de',
        changeTo: 'Zu Deutsch wechseln',
        seeAll: 'Alle Sprachen anzeigen',
        localizedName: 'Deutsch',
        name: 'German',
    },
    {
        key: 'ja',
        changeTo: '日本語 に変更します',
        seeAll: 'すべての言語を表示',
        localizedName: '日本語',
        name: 'Japanese',
    },
    {
        key: 'ko',
        changeTo: '한국어 로 변경',
        seeAll: '모든 언어 보기',
        localizedName: '한국어',
        name: 'Korean',
    },
    {
        key: 'mn',
        changeTo: 'монгол болгон өөрчлөх',
        seeAll: 'Бүх хэлийг үзнэ үү',
        localizedName: 'монгол',
        name: 'Mongolian',
    },
    {
        key: 'no',
        changeTo: 'Bytt til Norsk',
        seeAll: 'Se alle språk',
        localizedName: 'Norsk',
        name: 'Norwegian',
    },
    {
        key: 'pl',
        changeTo: 'Zmień na Polski',
        seeAll: 'Zobacz wszystkie języki',
        localizedName: 'Polski',
        name: 'Polish',
    },
    {
        key: 'pt',
        changeTo: 'Alterar para Português',
        seeAll: 'Ver todos os idiomas',
        localizedName: 'Português',
        name: 'Portuguese',
    },
    {
        key: 'es',
        changeTo: 'Cambiar a Español',
        seeAll: 'Ver todos los idiomas',
        localizedName: 'Español',
        name: 'Spanish',
    },
    {
        key: 'tr',
        changeTo: 'Türk olarak değiştir',
        seeAll: 'Tüm dilleri görün',
        localizedName: 'Türk',
        name: 'Turkish',
    },
];

export const Whitelist = Languages?.map((lang) => lang.key);

export const getLanguageByKey = (key) => {
    return Languages.find((lang) => lang.key === key);
};
