export enum SessionActionTypes {
    JOINING_STARTED = 'sessionState/JOINING_STARTED',
    JOINING_FINISHED = 'sessionState/JOINING_FINISHED',
    SESSION_ENTERED = 'sessionState/SESSION_ENTERED',
    SESSION_EXITED = 'sessionState/SESSION_EXITED',
    SESSION_FINISHING = 'sessionState/SESSION_FINISHING',
    SESSION_FINISHING_CLEAR = 'sessionState/SESSION_FINISHING_CLEAR',
    SESSION_FINISHED = 'sessionState/SESSION_FINISHED',
}

export const CONTEXT_TYPE = {
    JOINING: 'JOINING',
    PRESENCE: 'PRESENCE',
    FINISHING: 'FINISHING',
};

type Nullable<T> = null | T;
export type SessionMeta = Nullable<{ [key: string]: unknown }>;

export interface SessionActionPayload {
    context: string;
    contextId: string;
    meta?: Nullable<SessionMeta>;
}

interface SessionAction<TActionType, TPayload = SessionActionPayload> {
    type: TActionType;
    payload: TPayload;
}

export type SessionStateActionTypes = SessionAction<SessionActionTypes>;

type JoiningStartedAction = SessionAction<SessionActionTypes.JOINING_STARTED>;

type JoiningFinishedAction = SessionAction<SessionActionTypes.JOINING_FINISHED>;

type SessionEnteredAction = SessionAction<SessionActionTypes.SESSION_ENTERED>;

type SessionExitedAction = SessionAction<SessionActionTypes.SESSION_EXITED>;

type SessionFinishingAction = SessionAction<
    SessionActionTypes.SESSION_FINISHING
>;

type SessionFinishedAction = SessionAction<SessionActionTypes.SESSION_FINISHED>;

type SessionFinishedClearAction = SessionAction<
    SessionActionTypes.SESSION_FINISHING_CLEAR,
    null
>;

export const joinStarted = (
    context: string,
    contextId: string,
    meta: SessionMeta = null
): JoiningStartedAction => ({
    type: SessionActionTypes.JOINING_STARTED,
    payload: { context, contextId, meta },
});

export const joiningFinished = (
    context: string,
    contextId: string
): JoiningFinishedAction => ({
    type: SessionActionTypes.JOINING_FINISHED,
    payload: { context, contextId },
});

export const sessionEntered = (
    context: string,
    contextId: string,
    meta: SessionMeta = null
): SessionEnteredAction => ({
    type: SessionActionTypes.SESSION_ENTERED,
    payload: { context, contextId, meta },
});

export const sessionExited = (
    context: string,
    contextId: string
): SessionExitedAction => ({
    type: SessionActionTypes.SESSION_EXITED,
    payload: { context, contextId },
});

export const sessionFinishing = (
    context: string,
    contextId: string,
    meta: SessionMeta = null
): SessionFinishingAction => ({
    type: SessionActionTypes.SESSION_FINISHING,
    payload: { context, contextId, meta },
});

export const sessionFinished = (
    context: string,
    contextId: string
): SessionFinishedAction => ({
    type: SessionActionTypes.SESSION_FINISHED,
    payload: { context, contextId },
});

export const clearFinishContext = (): SessionFinishedClearAction => ({
    type: SessionActionTypes.SESSION_FINISHING_CLEAR,
    payload: null,
});
