import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from 'atoms/Button/button';
import useDeviceInfo from 'hooks/useDeviceInfo';
import { useDispatch } from 'react-redux';
import { setLiteModeEnabled } from 'store/actions/liveAirmeet';
import { Typography } from 'foundations/typography';
import { cdnImage } from 'utils/core';
import useWhiteLabelling from 'hooks/useWhitelabelling';
import {
    NetworkErrorTypes,
    NonInteractiveDrawbacks,
} from 'utils/constants/eventLoader';
import FirebaseLogger from 'utils/firebaseLogger';
import { logger } from 'utils/logger';

const ListItem = ({ errorMsg, link, index }) => {
    return (
        <ListItemComponent>
            <Typography
                style={{ marginBottom: '4px', lineHeight: '18px' }}
                variant={'body2'}
                color='ambience.6'
            >
                {`${index}. ${errorMsg}`}
            </Typography>
            <Typography
                variant={'button2'}
                color='accentPrimary.0'
                style={{ marginTop: '2px' }}
            >
                <a href={link} target='_blank' rel='noopener noreferrer'>
                    Learn more
                </a>
            </Typography>
        </ListItemComponent>
    );
};

const getStepsToResolve = (state) => {
    return [
        {
            step: 'Check if you are connected to the Internet',
            link:
                'https://help.airmeet.com/en/support/solutions/articles/82000630116-how-to-check-your-internet-connection-current-bandwidth-',
        },
        {
            step:
                'Try to connect to a different network / a different service provider',
            link:
                'https://help.airmeet.com/en/support/solutions/articles/82000630117-how-to-change-to-different-internet-networks-',
        },
        {
            step: `Update your ${state?.reasonForError}`,
            link: state?.linkToResolve,
        },
    ];
};

export default function NetworkErrorComponent({
    errorType,
    userRole,
    airmeetId,
    attendeeId,
    device,
}) {
    const state = NetworkErrorTypes[errorType];

    const dispatch = useDispatch();

    const isAttendee = userRole === 'attendee';

    const { isMobile, isTablet } = useDeviceInfo();
    const isMobileOrTab = isMobile || isTablet;

    const showContinueOption =
        state?.reasonForError !==
            NetworkErrorTypes.firebaseError.reasonForError && isAttendee;

    const { whiteLabelText } = useWhiteLabelling();

    const stepsToResolve = getStepsToResolve(state);

    const onConnectToAirmeetClicked = () => {
        window.location.reload();
    };

    const toggleLiteMode = () => {
        FirebaseLogger.joinInLiteModeClicked(
            airmeetId,
            attendeeId,
            userRole,
            device
        );
        logger.info('User joining event in non-interactive mode');
        dispatch(setLiteModeEnabled(true));
    };

    useEffect(() => {
        FirebaseLogger.networkErrorTypes(errorType, {
            airmeetId,
            attendeeId,
            userRole,
            device,
        });
    }, [errorType, airmeetId, attendeeId, userRole, device]);

    if (state === undefined) return null;

    return (
        <Container>
            {!isMobileOrTab && (
                <Image
                    src={cdnImage(
                        '/images/eventLoader/desktop-connection-failed.svg'
                    )}
                    alt='error emoticon'
                />
            )}
            <InnerContainer>
                <HeaderContainer>
                    {isMobileOrTab && (
                        <ImageMob
                            src={cdnImage(
                                '/images/eventLoader/mobile-error-emoticon.svg'
                            )}
                            alt='error emoticon'
                        />
                    )}
                    <Header>
                        <Typography variant={'h3'} color='ambience.1' mb={1}>
                            Unable to Connect
                        </Typography>
                        <Typography variant={'body2'} color='ambience.1'>
                            We are having trouble connecting to{' '}
                            {whiteLabelText('Airmeet', 'Event')} due to your
                            Internet connection
                        </Typography>
                    </Header>
                </HeaderContainer>
                <Box>
                    <Typography variant={'subtitle1'} color='ambience.1' mb={2}>
                        Try one of these steps to resolve this:
                    </Typography>
                    <List>
                        {stepsToResolve.map((stepItem, index) => {
                            return (
                                <ListItem
                                    index={index + 1}
                                    errorMsg={stepItem.step}
                                    link={stepItem.link}
                                    key={stepItem.step}
                                />
                            );
                        })}
                    </List>
                </Box>
                <ButtonContainer>
                    <Button
                        variant='primary'
                        style={{
                            width: isMobileOrTab ? '100%' : '280px',
                            marginRight: '24px',
                            marginBottom: '12px',
                        }}
                        onClick={onConnectToAirmeetClicked}
                    >
                        Try connecting to {whiteLabelText('Airmeet', 'Event')}{' '}
                        now
                    </Button>
                    {showContinueOption && (
                        <Button
                            variant='gray'
                            style={{ width: isMobileOrTab ? '100%' : '280px' }}
                            color={'accentPrimary.0'}
                            onClick={toggleLiteMode}
                        >
                            Join in non-interactive mode
                        </Button>
                    )}
                </ButtonContainer>
                {showContinueOption && !isMobileOrTab && (
                    <Box>
                        <Typography
                            variant={'subtext1'}
                            color='ambience.1'
                            mb={1}
                        >
                            In the non-interactive mode
                        </Typography>
                        {NonInteractiveDrawbacks.map((drawback) => (
                            <Typography
                                key={drawback}
                                variant={'subtext1'}
                                color='ambience.6'
                                mb={1}
                            >
                                - {drawback}
                            </Typography>
                        ))}
                    </Box>
                )}
            </InnerContainer>
        </Container>
    );
}

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 620px;
    justify-content: space-between;
    margin-bottom: 52px;
    @media (max-width: 960px) {
        align-items: center;
        width: 100%;
        padding: 0 16px;
    }
`;

const Header = styled.div``;

const Image = styled.img`
    width: 230px;
    height: 250px;
    margin-right: 120px;
`;

const ImageMob = styled.img`
    width: 80px;
    height: 64px;
    margin-right: 18px;
`;

const Box = styled.div`
    display: flex;
    width: 620px;
    flex-direction: column;
    margin-bottom: 52px;
    @media (max-width: 960px) {
        width: 100%;
        padding: 0 16px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 620px;
    flex-direction: row;
    margin-bottom: 24px;
    @media (max-width: 960px) {
        flex-direction: column;
        width: 100%;
        padding: 0 16px;
    }
`;

const List = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const ListItemComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    border: 1px solid #353335;
    margin-top: -1px;
    @media (max-width: 960px) {
        flex-direction: column;
    }
`;
