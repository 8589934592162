import React from 'react';
import CardBgSetting from './CardBgSetting';
import TopNavBgSetting from './TopNavBgSetting';

export default function ElementSettings({ live_config, onChange, theme_info }) {
    return (
        <>
            <TopNavBgSetting live_config={live_config} onChange={onChange} />
            <CardBgSetting
                live_config={live_config}
                onChange={onChange}
                theme_info={theme_info}
            />
            {/* <RadiusTypeSetting live_config={live_config} onChange={onChange} /> */}
        </>
    );
}
