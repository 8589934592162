// people layout
export const GRID_VIEW = 'peopleTab/GRID_VIEW';
export const LIST_VIEW = 'peopleTab/LIST_VIEW';
export const CELL_PADDING = 4;

export const PEOPLE_DM_INNER_TAB_LABEL = 'Direct Messages';
export const PEOPLE_DM_INNER_TAB_LABEL_MOBILE = 'Chats';
export const PEOPLE_ATTENDEES_INNER_TAB_LABEL = 'Attendees';

// people actions
export const ACTION_DM_START = 'person/ACTION_DM_START';
export const ACTION_BLOCK = 'person/ACTION_BLOCK';
export const ACTION_EDIT_VIDEO_HEADLINE = 'person/ACTION_EDIT_VIDEO_HEADLINE';
export const ACTION_STAGE_INVITATION = 'person/ACTION_STAGE_INVITATION';
export const ACTION_INVITE_TO_STAGE = 'person/ACTION_INVITE_TO_STAGE';
export const ACTION_REMOVE_FROM_STAGE = 'person/ACTION_REMOVE_FROM_STAGE';

// tab titles
export const SHOW_ON_STAGE_QUESTION_VIDEO_DISABLED =
    'Videos cannot be played while a question is shown on stage';
export const SHOW_ON_STAGE_POLL_VIDEO_DISABLED =
    'Videos cannot be played while a poll is shown on stage';
export const SHOW_ON_STAGE_VIDEO_DISABLED = 'Videos cannot be played';

export const SHOW_PEOPLE_COUNT = 'SHOW_PEOPLE_COUNT';

export const PEOPLE_TAB_SOURCE_CONFIG = {
    LIVE: 'live',
    REGISTERED_API: 'registered_api',
    PAGINATED_API: 'paginated_api',
    PRE_EVENT: 'pre_event',
    LIVE_PATCH: 'live_patch',
};

// views
export const PERSON_CARD_GRID_VIEW = 'PERSON_CARD_GRID_VIEW';
export const PERSON_CARD_LIST_VIEW = 'PERSON_CARD_LIST_VIEW';

export const getLayoutOptions = (t, keys) => [
    {
        value: LIST_VIEW,
        label: t(keys.RHS_PEOPLE_ATTENDEES_LIST_VIEW),
        icon: 'apps_list_outline',
    },
    {
        value: GRID_VIEW,
        label: t(keys.RHS_PEOPLE_ATTENDEES_GRID_VIEW),
        icon: 'grid_outline_2',
    },
];
