import createReducer from '../../utils/createReducer';
import { BOOTH_RESPONSE } from '../actions/cmDashboard/booth';

const initialState = {
    boothData: [],
    boothTags: [],
};

const handlers = {
    [BOOTH_RESPONSE]: (state = initialState, { payload }) => {
        const { booths, error } = payload;
        const boothTags = (booths || [])
            .map((booth) =>
                (booth?.tags || []).map((tag) => ({ value: tag, label: tag }))
            )
            .flat(Infinity);
        return {
            ...state,
            boothData: error ? [] : booths,
            boothTags: error ? [] : boothTags,
        };
    },
};

export default createReducer(initialState, handlers);
