import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getMyCommunities,
    getOrganiserRole,
} from '../../store/actions/community';
import { emptyObject } from 'utils/constants/common';

function useGetMyCommunityCheck({ airmeetId, path, location }) {
    const { id: authUserId, guest: guestUser } =
        useSelector((state) => state.auth?.user) || emptyObject;
    const { list = [] } =
        useSelector((state) => state?.Community?.myCommunity) || {};
    const isCheckEmCmCalled = useRef(false);
    const dispatch = useDispatch();

    const shouldCheckEmCmInfo =
        list?.length === 0 &&
        ['/e/:id/edit', '/e/:id', '/event/:id'].includes(path) &&
        !location.search.includes('edit=true') &&
        authUserId &&
        !guestUser &&
        !isCheckEmCmCalled.current;

    const checkEmCmInfo = useCallback(async () => {
        const response = await dispatch(getOrganiserRole(airmeetId));
        if (response?.payload && response?.payload?.isEventModerator) {
            dispatch(getMyCommunities());
        }
    }, [airmeetId, dispatch]);
    useEffect(() => {
        if (shouldCheckEmCmInfo) {
            isCheckEmCmCalled.current = true;
            checkEmCmInfo();
        }
    }, [checkEmCmInfo, isCheckEmCmCalled, shouldCheckEmCmInfo]);

    return null;
}

export default useGetMyCommunityCheck;
