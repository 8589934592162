import axios from 'axios';
import useToasts from 'hooks/useToasts';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isSafari } from 'utils/browserCheck';
import { EVENT_SUB_TYPES } from 'utils/constants/airmeet';
import { noop, PRE_RECORDED_VIDEO_MODULE } from 'utils/constants/common';
import { TOAST_VERSION_2 } from 'utils/constants/toasts';
import { uploadBase64Image } from '../store/actions/fileUplaoder';
import { getSignedUrl, saveMedia } from '../store/actions/videoUplaoder';
import FirebaseLogger from '../utils/firebaseLogger';
import { logger } from '../utils/logger';
const logs = logger.init('Video Library Events', 'blue');

let axTokenSource;

export default function useVideoUploader({
    progPerRef,
    addToLibraryRef,
    progressRef,
    setFileData,
    fileData,
    videoItems,
    setModelState = noop,
    refreshGrid,
    entityData = {},
    communityData = {},
    communityVideos = [],
    logData = {},
    getVideoData = noop,
    returnProgressData = false,
    refreshCommunityVideos = false,
    skipAirmeetMapping = false,
    showSuccessToast = true,
    uploadCancelledToastText = null,
    uploadCancelledToastType = 'error',
    showUploadCancelledToast = true,
    formData = {},
}) {
    const dispatch = useDispatch();
    const { successToast, errorToast } = useToasts();
    const wait = (ms) => new Promise((r) => setTimeout(r, ms));
    const [uploadStatus, setUploadStatus] = useState({ status: 'not-started' });
    const [uploadError, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const method = useRef({});
    const [uploadProgress, setUploadProgress] = useState(0);
    const [thumbnailURL, setThumbnailURL] = useState('');
    const [videoTitle, setTitle] = useState('');
    const beforeCloseWindow = (event) => {
        const confirmationMessage =
            'Uploading still running...\nAre you sure you want to leave?';

        (event || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Webkit, Safari, Chrome etc.
    };
    const uploadFile = (file, endPoint) => {
        logger.info('Starting to upload the video file');
        axTokenSource = axios.CancelToken.source();
        axios
            .put(endPoint, file, {
                headers: { 'Access-Control-Allow-Origin': '*' },
                onUploadProgress: progressHandler,
                cancelToken: axTokenSource.token,
            })
            .then(() => {
                logger.info('Video file uploaded to s3 successfully');
                method.current.completeHandler();
            })
            .catch((err) => {
                logger.error(
                    `Video upload failed for ${entityData.entity_type}:: ${
                        err && err.message ? err.message : err
                    }`
                );
                let message = `We faced some internal issue while uploading your video.`;
                if (axios.isCancel(err)) {
                    if (err.message === 'cancelled') {
                        message = uploadCancelledToastText
                            ? uploadCancelledToastText
                            : 'Video upload interrupted in between.';
                    } else {
                        setError({ message: 'Upload aborted.' });
                    }
                } else {
                    setError({
                        message: 'Something went wrong, please try again.',
                    });
                }
                setFileData((prevState) => ({
                    ...prevState,
                    status: false,
                    canUpload: false,
                }));
                setUploadStatus({ status: 'not-started' });
                if (showUploadCancelledToast) {
                    if (uploadCancelledToastType === 'success') {
                        successToast({
                            title: 'Video removed',
                            message,
                            version: TOAST_VERSION_2,
                        });
                    } else {
                        errorToast({
                            title: 'Video upload failed',
                            message,
                            version: TOAST_VERSION_2,
                        });
                    }
                }
            })
            .finally(() => {
                window.removeEventListener(
                    'beforeunload',
                    beforeCloseWindow,
                    true
                );
            });

        setUploadStatus({ status: 'uploading' });
        setFileData((prev) => ({ ...prev, canUpload: false }));
        // event log
        const eventName =
            entityData.entity_type === 'community'
                ? 'libraryVideoUploadStart'
                : 'videoUploadStarted';
        logs(eventName, logData);
        FirebaseLogger.videoLibraryEvents(eventName, logData);
        window.addEventListener('beforeunload', beforeCloseWindow, true);
    };
    const progressHandler = (event) => {
        if (!returnProgressData && !progPerRef.current) {
            axTokenSource.cancel();
            return;
        }

        const percent = Math.round((event.loaded / event.total) * 100);

        if (returnProgressData) {
            setUploadProgress(percent);

            return;
        }

        progPerRef.current.innerText = `Uploading ${percent}%`;
        progressRef.current.style.width = `${percent}%`;
    };

    method.current.completeHandler = async () => {
        setTitle(fileData.fileName.replace('.mp4', ''));
        setUploadStatus({ status: 'finishing' });

        const capturePoster = (url) => {
            return new Promise((resolve, reject) => {
                const video = document.createElement('video');
                video.addEventListener('loadedmetadata', function () {
                    video.currentTime = 2;
                });
                const capture = function () {
                    const canvas = document.createElement('canvas');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    if (!canvas.width && !canvas.height) {
                        logger.info('Poster image capture failed', {
                            canvasWidth: canvas.width,
                            canvasHeight: canvas.height,
                        });
                        return reject('canvas width or height is zero');
                    }
                    canvas
                        .getContext('2d')
                        .drawImage(video, 0, 0, canvas.width, canvas.height);
                    const image = canvas.toDataURL();
                    const success = image.length !== 'data:,';
                    if (success) {
                        return resolve(image);
                    }
                    return reject('could not generated image');
                };
                video.addEventListener(
                    isSafari() ? 'loadeddata' : 'seeked',
                    capture
                );
                video.preload = 'metadata';
                video.src = url;
                video.crossOrigin = 'anonymous';
                // Load video in Safari / IE11
                video.muted = true;
                video.playsInline = true;
                const playPromise = video.play();
                if (playPromise !== undefined) {
                    playPromise.catch((error) => {
                        return reject(error);
                    });
                }
            });
        };
        if (
            !fileData.poster &&
            fileData.path &&
            fileData.capturePosterAfterUpload
        ) {
            logger.info(
                'Starting to capture the poster image from the video file',
                fileData.path
            );
            try {
                fileData.poster = await capturePoster(fileData.path);
                logger.info('Poster image from the video file successfully');
            } catch (e) {
                logger.info('Poster image from the video file failed', e);
            }
        }

        let thumbDetails;

        // save video poster
        if (fileData.poster) {
            logger.info('Starting upload the poster image');
            try {
                thumbDetails = await dispatch(
                    uploadBase64Image(null, fileData.poster, 'pre-recorded')
                );
                logger.info('Poster image upload api promise resolved');
                if (!thumbDetails.error) {
                    setThumbnailURL(thumbDetails.payload.file.imageURL);
                    logger.info('Poster image upload success');
                } else {
                    logger.info(
                        'Poster image upload failed, thumbnail.error',
                        thumbDetails?.error
                    );
                }
            } catch (e) {
                logger.info('Poster image upload failed', e);
            }
        }
        // update the media data
        const mediaItem = {
            url: fileData.path,
            type: 'video/mp4',
            owner: '',
            title: fileData?.fileName?.replace('.mp4', ''),
            thumbnail: thumbDetails?.payload?.file?.imageURL,
            module: PRE_RECORDED_VIDEO_MODULE.MEDIA,
            created: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        };

        // Don't save video data to DB in case on venue and booth
        if (['venue', 'booth'].includes(entityData.entity_type)) {
            setUploadStatus({ status: 'finished' });
            await getVideoData(mediaItem);
            setUploadStatus({ status: 'not-started' });
            return;
        }

        if (!skipAirmeetMapping) {
            const existingMedia = videoItems || [];
            dispatch(
                saveMedia({
                    media_data: [...existingMedia, mediaItem],
                    feature: 'MEDIA',
                    ...entityData,
                })
            ).then(() => {
                setUploadStatus({ status: 'finished' });
                wait(1000).then(() => {
                    setModelState(false);
                    if (showSuccessToast)
                        successToast({
                            title:
                                entityData.entity_type === 'community' &&
                                formData.meetupSubType !== EVENT_SUB_TYPES.LITE
                                    ? 'Video uploaded successfully. Remember to link the video to an event.'
                                    : 'Video uploaded successfully.',
                            version: TOAST_VERSION_2,
                            closeToastOnly: true,
                        });
                    refreshGrid();
                });
            });
        }
        // save to library if enabled for airmeet only
        if (
            entityData.entity_type === 'airmeet' &&
            addToLibraryRef?.current?.checked
        ) {
            const existingMedia = communityVideos || [];
            dispatch(
                saveMedia({
                    media_data: [...existingMedia, mediaItem],
                    feature: 'MEDIA',
                    ...communityData,
                })
            );

            if (skipAirmeetMapping) {
                setUploadStatus({ status: 'finished' });
                wait(1000).then(() => {
                    setModelState(false);
                    successToast({
                        title:
                            entityData.entity_type === 'community' &&
                            formData.meetupSubType !== EVENT_SUB_TYPES.LITE
                                ? 'Video uploaded successfully. Remember to link the video to an event.'
                                : 'Video uploaded successfully.',
                        version: TOAST_VERSION_2,
                        closeToastOnly: true,
                    });
                    refreshGrid();
                });
            }

            refreshCommunityVideos && refreshGrid();
        }
        // event log
        const eventName =
            entityData.entity_type === 'community'
                ? 'libraryVideoUploaded'
                : 'videoUploaded';
        const eventData = {
            ...logData,
            ...(entityData.entity_type === 'airmeet' && {
                added_to_library: addToLibraryRef?.current?.checked,
            }),
        };
        logs(eventName, eventData);
        FirebaseLogger.videoLibraryEvents(eventName, eventData);
    };

    const submitHandler = (obj) => {
        if (loading) return false;

        setLoading(true);
        const { file } =
            fileData && fileData.file && fileData.file.name ? fileData : obj;
        dispatch(
            getSignedUrl({
                file_name: file.name,
                file_type: 'video/mp4',
                ...entityData,
            })
        )
            .then((result) => {
                if (result.error) {
                    setError({ message: result.payload.json.message });
                } else if (result && result.payload) {
                    setError(null);
                    setFileData((prevState) => ({
                        ...prevState,
                        path: result.payload.fileUrl,
                        fileName: file.name,
                    }));
                    uploadFile(file, result.payload.uploadUrl);
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                logger.error('Failed to upload video', error, {
                    message: error?.message,
                });
            });
        return false;
    };

    const abortUpload = () => {
        if (uploadStatus.status === 'uploading') {
            axTokenSource.cancel(`cancelled`);
        }
    };

    const resetState = useCallback(() => {
        setLoading(false);
        setUploadProgress(0);
        setError(null);
        setUploadStatus({ status: 'not-started' });
        setThumbnailURL('');
        setTitle('');
        setFileData({});
    }, [
        setLoading,
        setUploadProgress,
        setError,
        setUploadStatus,
        setThumbnailURL,
        setTitle,
        setFileData,
    ]);

    useEffect(() => {
        logger.info('uploadStatus in useVideoUploader', uploadStatus);
    }, [uploadStatus]);

    useEffect(() => {
        logger.info('loading state in useVideoUploader', loading);
    }, [loading]);

    return {
        submitHandler,
        uploadStatus,
        uploadError,
        abortUpload,
        loading,
        uploadProgress,
        thumbnailURL,
        videoTitle,
        resetState,
    };
}
