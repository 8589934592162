import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

function Page({ children }) {
    const { restricted: restrictedWidth, fillHeight, classes } = useSelector(
        (state) => state.layout.page
    );
    const { enabled: sideMenuEnabled } = useSelector(
        (state) => state.layout.sideMenu
    );

    // Allow callers to specify specific classes for the page
    const customClasses = Array.isArray(classes) ? classes : [classes];

    const pageClasses = classNames(
        'page',
        restrictedWidth === true ? 'page--restricted' : 'page--full',
        {
            'page--fill': fillHeight === true,
        },
        ...customClasses
    );

    const containerClasses = classNames('page-container', {
        'has-sidemenu': sideMenuEnabled === true,
    });

    return (
        <div className={pageClasses}>
            <div className={containerClasses}>{children}</div>
        </div>
    );
}

export default Page;
