import WithTooltip from 'components/general/WithTooltip';
import React from 'react';
import styled from 'styled-components';
import {
    background,
    border,
    color,
    flexbox,
    grid,
    layout,
    position,
    shadow,
    space,
    system,
} from 'styled-system';

const fill = system({
    fill: {
        property: 'fill',
        scale: 'colors',
    },
});
const stroke = system({
    stroke: {
        property: 'stroke',
        scale: 'colors',
    },
});

function SVGIcon({ icon, toolTip = '', tooltipPlace = 'top', ...props }) {
    return (
        <WithTooltip tooltip={toolTip} place={tooltipPlace}>
            <IconContainer {...props}>
                <use xlinkHref={`#${icon}`} />
            </IconContainer>
        </WithTooltip>
    );
}

export const iconList = [
    'access_time',
    'accessibility',
    'activity',
    'add',
    'add_circle',
    'address_book_number',
    'airplane',
    'alert',
    'alert_off',
    'alert_on',
    'alert_snooze',
    'alert_urgent',
    'app_folder',
    'app_generic',
    'app_recent',
    'app_span',
    'app_store',
    'app_title',
    'app_unspan',
    'apps',
    'apps_add_in',
    'apps_list',
    'archive',
    'arrow_clockwise',
    'arrow_counterclockwise',
    'arrow_curve_right',
    'arrow_down',
    'arrow_down_left',
    'arrow_down_right_circle',
    'arrow_download',
    'arrow_enter',
    'arrow_expand',
    'arrow_forward',
    'arrow_import',
    'arrow_left',
    'arrow_maximize',
    'arrow_minimize',
    'arrow_next',
    'arrow_previous',
    'arrow_redo',
    'arrow_repeat_all',
    'arrow_repeat_all_off',
    'arrow_reply',
    'arrow_reply_all',
    'arrow_right',
    'arrow_right_circle',
    'arrow_rotate_clockwise',
    'arrow_rotate_counterclockwise',
    'arrow_rotate_icon',
    'arrow_sort',
    'arrow_swap',
    'arrow_sync',
    'arrow_sync_circle',
    'arrow_trending',
    'arrow_undo',
    'arrow_up',
    'arrow_up_circle',
    'arrow_up_left',
    'arrow_up_right',
    'arrows_bidirectional',
    'assignments',
    'attach',
    'attach_with_text',
    'autocorrect',
    'autosum',
    'backspace',
    'backward',
    'badge',
    'bank',
    'battery_0',
    'battery_1',
    'battery_2',
    'battery_3',
    'battery_4',
    'battery_5',
    'battery_6',
    'battery_7',
    'battery_8',
    'battery_9',
    'battery_charge',
    'battery_full',
    'battery_saver',
    'battery_warning',
    'beaker',
    'bed',
    'block',
    'bluetooth',
    'bluetooth_connected',
    'bluetooth_disabled',
    'bluetooth_searching',
    'board',
    'book_formula_compatibility',
    'book_formula_database',
    'book_formula_date',
    'book_formula_engineering',
    'book_formula_financial',
    'book_formula_information',
    'book_formula_logical',
    'book_formula_lookup',
    'book_formula_math',
    'book_formula_recent',
    'book_formula_statistics',
    'book_formula_text',
    'bookmark',
    'bookmark_off',
    'bot',
    'bot_add',
    'branch',
    'briefcase',
    'bring_to_front',
    'broad_activity_feed',
    'bug_report',
    'building',
    'calendar',
    'calendar_3_day',
    'calendar_add',
    'calendar_agenda',
    'calendar_availability',
    'calendar_cancel',
    'calendar_date',
    'calendar_day',
    'calendar_empty',
    'calendar_later',
    'calendar_month',
    'calendar_overdue',
    'calendar_recurring',
    'calendar_starred',
    'calendar_sync',
    'calendar_week_numbers',
    'calendar_week_start',
    'call_add',
    'call_end',
    'call_forward',
    'call_inbound',
    'call_missed',
    'call_outbound',
    'call_park',
    'calligraphy_pen',
    'camera',
    'camera_add',
    'camera_switch',
    'cast',
    'cellular_3g',
    'cellular_4g',
    'cellular_data_1',
    'cellular_data_2',
    'cellular_data_3',
    'cellular_data_4',
    'cellular_data_5',
    'cellular_data_off',
    'cellular_off',
    'cellular_unavailable',
    'certificate',
    'channel',
    'channel_follow',
    'channel_notifications',
    'channel_unfollow',
    'chat',
    'chat_help',
    'checkbox_checked',
    'checkbox_unchecked',
    'checkmark',
    'checkmark_circle',
    'checkmark_lock',
    'checkmark_square',
    'chevron_down',
    'chevron_down_circle',
    'chevron_left',
    'chevron_right',
    'chevron_up',
    'city',
    'class',
    'classification',
    'clear_formatting',
    'clipboard',
    'clipboard_code',
    'clipboard_paste',
    'clipboard_search',
    'clipboard_text',
    'clock',
    'closed_caption',
    'cloud',
    'cloud_backup',
    'cloud_download',
    'cloud_off',
    'cloud_offline',
    'cloud_sync_complete',
    'code',
    'collections',
    'collections_add',
    'color',
    'color_background',
    'color_fill',
    'color_line',
    'column_triple',
    'comment',
    'comment_add',
    'comment_delete',
    'comment_next',
    'comment_previous',
    'comment_resolve',
    'compose',
    'conference_room',
    'connector',
    'contact_card',
    'contact_card_group',
    'contacts',
    'content_settings',
    'convert_to_table',
    'convert_to_text',
    'cookies',
    'copy',
    'copy_image',
    'copy_link',
    'copy_move',
    'crop',
    'crop_interim',
    'crop_interim_off',
    'cube',
    'currency',
    'dark_theme',
    'dashboard',
    'data_area',
    'data_bar_horizontal',
    'data_bar_vertical',
    'data_funnel',
    'data_histogram',
    'data_line',
    'data_pie',
    'data_scatter',
    'data_sunburst',
    'data_treemap',
    'data_usage',
    'data_waterfall',
    'data_whisker',
    'delete',
    'delete_forever',
    'delete_off',
    'design_ideas',
    'desktop',
    'developer_board',
    'device_eq',
    'dialpad',
    'dialpad_off',
    'dictionary',
    'dictionary_add',
    'directions',
    'dismiss',
    'dismiss_circle',
    'divider_short',
    'divider_tall',
    'dock',
    'dock_left',
    'dock_row',
    'document',
    'document_autosave',
    'document_briefcase',
    'document_catch_up',
    'document_copy',
    'document_discard',
    'document_edit',
    'document_endnote',
    'document_footer',
    'document_footer_remove',
    'document_header',
    'document_header_footer',
    'document_header_remove',
    'document_landscape',
    'document_margins',
    'document_none',
    'document_one_page',
    'document_page',
    'document_page_bottom_center',
    'document_page_bottom_left',
    'document_page_bottom_right',
    'document_page_break',
    'document_page_top_center',
    'document_page_top_left',
    'document_page_top_right',
    'document_pdf',
    'document_search',
    'document_toolbox',
    'document_width',
    'double_swipe_down',
    'double_swipe_up',
    'double_tap_swipe_down',
    'double_tap_swipe_up',
    'drafts',
    'drag',
    'drag_vertical',
    'drink',
    'drive_mode',
    'duo',
    'duo_add',
    'duo_desktop',
    'duo_error',
    'duo_group',
    'duo_home_lock',
    'duo_link_setup',
    'duo_page_header',
    'duo_pagination',
    'duo_screen_time',
    'duo_status_bar',
    'duo_tablet',
    'duo_to_pc',
    'duo_update',
    'duo_vertical_scroll',
    'duo_vibrate',
    'earth',
    'edit',
    'emoji',
    'emoji_add',
    'emoji_angry',
    'emoji_sad',
    'emoji_surprise',
    'erase',
    'eraser_tool',
    'error_circle',
    'error_circle_white',
    'export',
    'extended_dock',
    'extension',
    'eye_hide',
    'icon-hide-me',
    'icon-show-me',
    'eye_show',
    'fast_acceleration',
    'fast_forward',
    'fast_mode',
    'favorites',
    'filter',
    'fingerprint',
    'fit_width',
    'flag',
    'flag_off',
    'flag_pride',
    'flash_auto',
    'flash_off',
    'flash_on',
    'flashlight',
    'flashlight_off',
    'folder',
    'folder_add',
    'folder_junk',
    'folder_link',
    'folder_move',
    'folder_open',
    'folder_public',
    'folder_zip',
    'font_decrease',
    'font_increase',
    'font_space_tracking_in',
    'font_space_tracking_out',
    'food',
    'form_new',
    'forward',
    'fps_240',
    'fps_960',
    'full_screen_zoom',
    'games',
    'gesture',
    'gif',
    'gift',
    'glance',
    'globe',
    'globe_add',
    'globe_desktop',
    'globe_location',
    'globe_search',
    'globe_video',
    'grid',
    'group',
    'group_list',
    'guest',
    'handshake',
    'hdr',
    'headphones',
    'headset',
    'headset_vr',
    'heart',
    'help_circle',
    'highlight',
    'history',
    'home',
    'home_add',
    'icons',
    'image',
    'image_add',
    'image_alt_text',
    'image_copy',
    'image_edit',
    'image_library',
    'image_search',
    'immersive_reader',
    'important',
    'incognito',
    'info',
    'inking_tool',
    'inprivate_account',
    'inspect',
    'ios_arrow_left',
    'ios_chevron',
    'javascript',
    'key',
    'keyboard',
    'keyboard_dock',
    'keyboard_layout_float',
    'keyboard_layout_one_handed_left',
    'keyboard_layout_resize',
    'keyboard_layout_split',
    'keyboard_shift',
    'keyboard_shift_uppercase',
    'keyboard_tab',
    'laptop',
    'large',
    'lasso',
    'launcher_settings',
    'layer',
    'leaf',
    'leaf_two',
    'library',
    'lightbulb',
    'lightbulb_circle',
    'lightbulb_filament',
    'likert',
    'link',
    'list',
    'live',
    'local_language',
    'location',
    'location_not_found',
    'lock',
    'lock_shield',
    'magic_wand',
    'mail',
    'mail_add',
    'mail_all',
    'mail_all_accounts',
    'mail_copy',
    'mail_inbox',
    'mail_move_to_focussed',
    'mail_outbox',
    'mail_read',
    'mail_unread',
    'mail_unsubscribe',
    'malware_detected',
    'manufacturer',
    'map',
    'map_drive',
    'mark_read',
    'mark_unread',
    'match_app_layout',
    'meet_now',
    'megaphone',
    'megaphone_off',
    'mention',
    'mic_off',
    'mic_on',
    'mic_settings',
    'midi',
    'missing_metadata',
    'mobile_optimized',
    'more',
    'more_vertical',
    'movies_and_tv',
    'multiselect',
    'music',
    'my_location',
    'navigation',
    'network_check',
    'new',
    'news',
    'next',
    'none',
    'note',
    'note_add',
    'notebook',
    'notebook_error',
    'notebook_lightning',
    'notebook_question_mark',
    'notebook_section',
    'notebook_sync',
    'notepad',
    'number_row',
    'number_symbol',
    'office_apps',
    'open',
    'open_folder',
    'open_in_browser',
    'organisation_chart',
    'owner',
    'page_fit',
    'paint_brush',
    'paint_bucket',
    'pair',
    'pane_close',
    'pane_open',
    'patient',
    'pause',
    'payment',
    'pen_settings',
    'people',
    'people_add',
    'people_community',
    'people_community_add',
    'people_search',
    'people_team',
    'person',
    'person_accounts',
    'person_add',
    'person_arrow_right',
    'person_available',
    'person_block',
    'person_prohibited',
    'person_board',
    'person_delete',
    'person_feedback',
    'person_leave',
    'person_support',
    'person_swap',
    'person_tentative',
    'person_voice',
    'phone',
    'phone_add_new_app',
    'phone_desktop',
    'phone_error',
    'phone_home_lock',
    'phone_laptop',
    'phone_link_setup',
    'phone_mobile',
    'phone_page_header',
    'phone_pagination',
    'phone_screen_time',
    'phone_shake',
    'phone_status_bar',
    'phone_tablet',
    'phone_to_pc',
    'phone_update',
    'phone_vertical_scroll',
    'phone_vibrate',
    'photo_filter',
    'picture_in_picture',
    'pin',
    'pin_off',
    'play',
    'play_circle',
    'plug_disconnected',
    'point_scan',
    'poll',
    'power',
    'predictions',
    'premium',
    'presenter',
    'preview_link',
    'previous',
    'print',
    'proofread_language',
    'protocol_handler',
    'pstn_call',
    'qr_code',
    'quiz_new',
    'radio_button',
    're_order',
    're_order_dots',
    'read_aloud',
    'read_only',
    'reading_list',
    'reading_list_add',
    'reading_mode',
    'reading_mode_mobile',
    'recommended',
    'record',
    'remove',
    'remove_recent',
    'rename',
    'resize_image',
    'resize_video',
    'reward',
    'rewind',
    'rocket',
    'router',
    'row_triple',
    'ruler',
    'safe',
    'save',
    'save_as',
    'save_copy',
    'savings',
    'scan',
    'scratchpad',
    'screenshot',
    'search',
    'search_info',
    'search_square',
    'select_all',
    'select_all_off',
    'select_object',
    'send',
    'send_copy',
    'send_logging',
    'send_to_back',
    'serial_port',
    'set_top_stack',
    'settings',
    'settings_dev',
    'shapes',
    'shape',
    'share',
    'share_android',
    'share_close_tray',
    'share_desktop',
    'share_ios',
    'share_screen',
    'share_stop',
    'shield',
    'shifts',
    'shifts_pending',
    'shifts_team',
    'sign_out',
    'signature',
    'signed',
    'site_blocked',
    'site_warning',
    'sleep',
    'slide_add',
    'slide_design',
    'slide_hide',
    'slide_layout',
    'slide_text',
    'slow_mode',
    'small',
    'snooze',
    'sound_source',
    'spacebar',
    'speaker_0',
    'speaker_1',
    'speaker',
    'speaker_bluetooth',
    'speaker_edit',
    'speaker_none',
    'speaker_off',
    'speaker_off_1',
    'speaker_settings',
    'star',
    'star_add',
    'star_half',
    'star_none',
    'star_off',
    'star_one_quarter',
    'star_three_quarter',
    'status',
    'sticker',
    'sticker_add',
    'stop',
    'storage',
    'store_icon',
    'store_microsoft',
    'style_guide',
    'sub_grid',
    'subway',
    'suggestion',
    'surface_earbuds',
    'surface_hub',
    'swipe_down',
    'swipe_right',
    'swipe_up',
    'switch_video',
    'symbols',
    'system',
    'tab',
    'tab_arrow_clockwise',
    'tab_image',
    'tab_in_private',
    'tab_new',
    'tab_sweep',
    'tab_tracking_prevention',
    'table',
    'table_add',
    'table_cells_merge',
    'table_cells_split',
    'table_delete',
    'table_edit',
    'table_settings',
    'tablet',
    'tabs',
    'tag',
    'tap_double',
    'tap_single',
    'target',
    'target_edit',
    'team_add',
    'team_delete',
    'teddy',
    'temperature',
    'test_call',
    'text',
    'text_add',
    'text_add_space_after',
    'text_add_space_before',
    'text_align_center',
    'text_align_distributed',
    'text_align_justify',
    'text_align_left',
    'text_align_right',
    'text_bold',
    'text_bullet_list',
    'text_bullet_list_square',
    'text_bullet_list_tree',
    'text_change_accept',
    'text_change_case',
    'text_change_next',
    'text_change_reject',
    'text_change_settings',
    'text_clear_formatting',
    'text_collapse',
    'text_color',
    'text_column_one',
    'text_column_three',
    'text_column_two',
    'text_column_two_left',
    'text_column_two_right',
    'text_description',
    'text_direction',
    'text_direction_horizontal_ltr',
    'text_direction_rotate_270',
    'text_direction_rotate_270_ac',
    'text_direction_rotate_90',
    'text_direction_vertical',
    'text_edit_style',
    'text_effects',
    'text_expand',
    'text_field',
    'text_first_line',
    'text_font',
    'text_font_size',
    'text_footnote',
    'text_grammar_options',
    'text_hanging',
    'text_indent_decrease',
    'text_indent_increase',
    'text_italic',
    'text_line_spacing',
    'text_number_format',
    'text_number_list_ltr',
    'text_number_list_rtl',
    'text_proofing_tools',
    'text_quote',
    'text_strikethrough',
    'text_subscript',
    'text_superscript',
    'text_underline',
    'text_word_count',
    'textbox',
    'textbox_align',
    'textbox_align_bottom',
    'textbox_align_middle',
    'textbox_align_top',
    'textbox_vertical',
    'thinking',
    'thumb_dislike',
    'thumb_like',
    'ticket',
    'time_and_weather',
    'time_picker',
    'timeline',
    'timer_10',
    'timer',
    'timer_2',
    'timer_off',
    'timer_outline',
    'toggle_right',
    'toolbox',
    'top_speed',
    'track_change_previous',
    'translate',
    'trophy',
    'undo',
    'uninstall_app',
    'unlock',
    'upgrade',
    'upload',
    'usb_port',
    'usb_stick',
    'video',
    'video_background_effect',
    'video_clip',
    'video_incoming',
    'video_incoming_off',
    'video_off',
    'video_play_pause',
    'video_security',
    'view_desktop',
    'view_desktop_mobile',
    'voicemail',
    'wallpaper',
    'warning',
    'weather_blowing_snow',
    'weather_cloudy',
    'weather_duststorm',
    'weather_fog',
    'weather_hail_day',
    'weather_hail_night',
    'weather_moon',
    'weather_partly_cloudy_day',
    'weather_partly_cloudy_night',
    'weather_rain',
    'weather_rain_showers_day',
    'weather_rain_showers_night',
    'weather_rain_snow',
    'weather_snow',
    'weather_snow_shower_day',
    'weather_snow_shower_night',
    'weather_snowflake',
    'weather_squalls',
    'weather_sunny',
    'weather_thunderstorm',
    'web_asset',
    'weekend',
    'whiteboard',
    'wifi_1',
    'wifi_2',
    'wifi_3',
    'wifi_4',
    'wifi_protected',
    'window_dev_tools',
    'window_shield',
    'xbox_console',
    'zoom_in',
    'zoom_out',
    'icon_funnel_unchecked',
    'icon_funnel_checked',
    'arrow_round_circle',
    'billing-plans',
    'Billing_Info_Icon',
    'eye_full',
    'icon-Arrow-right-side',
    'icon-Ather',
    'icon-Sequoia',
    'icon-Agora',
    'icon-Upwork',
    'icon-Microsoft',
    'icon-HackerRank',
    'icon-DigitalOcean',
    'icon-Frame',
    'icon-SWLogos',
    'icon-Walmart',
    'icon-Volvo',
    'icon-airmeet-logo-dark',
    'icon-Office-Apps',
    'icon-View-Desktop',
    'icon-Building',
    'icon-People-Audience',
    'icon-forbes',
    'icon-bosch',
    'icon-johnson',
    'icon-philips',
    'icon-Stream-in',
    'icon-Stream-out',
    'icon-Sponsors',
    'icon-Branding',
    'icon-Exhibitor-booth',
    'icon-Speed-networking',
    'icon-Social-lounge',
    'icon-Stage',
    'icon-Reception',
    'icon-italic-info',
    'icon-circle-tick',
    'icon-illustration-search',
    'loudspeaker',
    'footer-facebook',
    'footer-twitter',
    'footer-instagram',
    'footer-linkedin',
    'cdn-setting',
    'cdn-delete',
    'cdn-link',
    'add-plus',
    'text-section-delete',
    'banner',
];

const IconContainer = styled.svg`
    ${space}
    ${color}
    ${layout}
    ${flexbox}
    ${grid}
    ${background}
    ${border}
    ${position}
    ${shadow}
    ${fill}
    ${stroke}
`;

export default SVGIcon;
