import Box from 'atoms/Box';
import { Typography } from 'foundations/typography';
import React from 'react';
import { cdnImage } from 'utils/core';

const UploadError = ({ isThemeV2 = false }) => (
    <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
    >
        <img
            src={cdnImage('/images/dashboard/file-error.svg')}
            width={97}
            height={132}
            alt={'upload'}
        />
        <Typography
            variant={isThemeV2 ? 'h5' : 'button2'}
            color={isThemeV2 ? 'text.default.primary' : 'ambience.24'}
            mt={isThemeV2 ? 'x4' : '12px'}
            style={{
                textAlign: 'center',
            }}
            isThemeV2={isThemeV2}
        >
            Your file had some issue
        </Typography>
        <Typography
            variant={'caption'}
            color={isThemeV2 ? 'text.default.secondary' : 'ambience.14'}
            isThemeV2={isThemeV2}
        >
            There was some error while uploading, please upload the file again.
        </Typography>
    </Box>
);

export default UploadError;
