import BetaTag from 'atoms/BetaTag/BetaTag';
import Btn from 'atoms/Button/btn';
import Button from 'atoms/Button/button';
import Divider from 'atoms/Divider/Divider';
import Drawer from 'atoms/Drawer/drawer';
import BlurSlider from 'atoms/RangeSlider/BlurSlider';
import OpacitySlider from 'atoms/RangeSlider/OpacitySlider';
import ZoomSlider from 'atoms/RangeSlider/ZoomSlider';
import Select from 'atoms/SelectV2';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { postAirGenieAutogenerateImage } from 'components/modals/AirGenieModal/apis';
import {
    AirGenieSectionFooter,
    AirGenieTextarea,
    GenButton,
} from 'components/modals/AirGenieModal/styledComponents';
import {
    AIR_GENIE_IMAGE_STYLE_OPTIONS,
    AIR_GENIE_MAX_DESC_LENGTH,
    getErrorMessage,
    validateDescription,
} from 'components/modals/AirGenieModal/utils';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { getCustomScroll } from 'components/molecules/utils/customStyles';
import { Text } from 'foundations/themeV2/text/';
import { getZIndex } from 'foundations/themeV2/zIndex';
import { Typography } from 'foundations/typography';
import useToasts from 'hooks/useToasts';
import animationData from 'lotties/threeDotsLoader.json';
import React, { useEffect, useMemo, useState } from 'react';
import usePortal from 'react-cool-portal';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { uplaodFile } from 'store/actions/fileUplaoder';
import styled from 'styled-components';
import { TOAST_VERSION_2 } from 'utils/constants/toasts';
import firebaseLogger from 'utils/firebaseLogger';
import { logger } from 'utils/logger';
import { v4 as uuid } from 'uuid';
import {
    DEFAULT_BACKGROUND_BLUR,
    DEFAULT_BACKGROUND_OPACITY,
    DEFAULT_BACKGROUND_ZOOM,
} from './utils';
import useAirGenieUserAttempts from 'hooks/airGenie/useAirGenieUserAttempts';
import WithTooltip from 'components/general/WithTooltip';
import { getImageGenUserDataPath } from 'hooks/airGenie/constants';

const MAX_ZOOM_LIMIT = 5;

function AirGenieImageSuggestionsDrawerWrapper({
    title = 'Background Image',
    placeholderPrefix = 'Describe about the image',
    uploadFilePrefix = 'aiImage',
    airmeetId,
    apiParams = {},
    entityName = 'airmeet',
    communityId = null,
    label = 'Event name',
    value,
    onChange,
    onClose,
    renderPreviewComponent,
    top = '0px',
    drawerHeight = '100vh',
    portal = false,
    hideAutoGenerate = false,
    hasImageCropper = false,
    imgUrl = null,
    previewHeight = '100%',
    firebaseClient,
    featuresConfig,
    platformConfig,
    module,
    imageHeight = 720,
    imageWidth = 1440,
}) {
    const dispatch = useDispatch();
    const userId = useSelector((s) => s?.auth?.user?.id);
    const { errorToast } = useToasts();

    const [loading, setLoading] = useState(false);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [style, setStyle] = useState(AIR_GENIE_IMAGE_STYLE_OPTIONS[0]);
    const [description, setDescription] = useState('');
    const [responses, setResponses] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [imageUrl, setImageUrl] = useState(imgUrl);
    const [opacity, setOpacity] = useState(DEFAULT_BACKGROUND_OPACITY);
    const [blur, setBlur] = useState(DEFAULT_BACKGROUND_BLUR);
    const [zoom, setZoom] = useState(DEFAULT_BACKGROUND_ZOOM);
    const [cropData, setCropData] = useState('');

    const { hasResponses, isResponsesLoading } = useMemo(() => {
        const hasResponses = Array.isArray(responses) && responses.length > 0;
        let isResponsesLoading = false;
        if (hasResponses) {
            isResponsesLoading = responses.some(
                (response) => response?.isLoading
            );
        }
        return { hasResponses, isResponsesLoading };
    }, [responses]);

    const { imageGenAttempts, maxImageGenAttempts } = useAirGenieUserAttempts({
        firebaseClient,
        airmeetId,
        featuresConfig,
        platformConfig,
        module,
        userId,
    });

    const attemptsExhausted = useMemo(
        () => imageGenAttempts === maxImageGenAttempts,
        [imageGenAttempts, maxImageGenAttempts]
    );

    useEffect(() => {
        if (
            !responses[activeIndex]?.isLoading &&
            responses?.[activeIndex]?.url
        ) {
            setImageUrl(responses[activeIndex].url);
        }
    }, [responses, activeIndex]);

    const handleChange = (e) => {
        setDescription(e?.target?.value);
    };

    const handleOpacityChange = (opacity) => {
        setOpacity(opacity);
    };

    const handleBlurChange = (blur) => {
        setBlur(blur);
    };

    const handleZoomChange = (zoom) => {
        setZoom(zoom);
    };

    const handleGenerate = async () => {
        try {
            const isValid = validateDescription({ description, errorToast });

            if (isValid) {
                setLoading(true);
                firebaseLogger.airGenie('clickGenerate', {
                    isRegenerated: hasResponses,
                    airmeet_id: airmeetId,
                    userId,
                    label: title,
                });

                const response = await postAirGenieAutogenerateImage({
                    description,
                    style: style?.label,
                    entityName,
                    entityId:
                        entityName === 'airmeet' ? airmeetId : communityId,
                    ...(apiParams || {}),
                });

                const choices = response?.payload?.choices;
                if (Array.isArray(choices) && choices.length > 0) {
                    const responses = choices.map((item, index) => {
                        // Pre-load image
                        var img = new Image();
                        const src = `data:image/png;base64,${item?.b64_json}`;
                        img.src = src;
                        img.onload = () => {
                            handleOnLoad(index);
                        };

                        return {
                            url: src,
                            isLoading: true,
                        };
                    });
                    setResponses(responses);
                    setActiveIndex(0);
                    const attemptsUpdatePath = getImageGenUserDataPath({
                        airmeetId,
                        module,
                        userId,
                    });
                    await firebaseClient.setData(
                        attemptsUpdatePath,
                        imageGenAttempts + 1
                    );
                } else {
                    throw new Error(
                        response?.error || 'Error while generating images'
                    );
                }
            }
        } catch (e) {
            const errorMessage = getErrorMessage(e);
            logger.error('AirGenieImageSuggestions.handleGenerate: ', {
                errorMessage,
                e,
            });
            errorToast({
                title: errorMessage,
                version: TOAST_VERSION_2,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleOnLoad = (index) => {
        setResponses((responses) => {
            if (Array.isArray(responses) && responses.length > index) {
                const newResponses = [...responses];
                newResponses[index].isLoading = false;
                return newResponses;
            }
            return responses;
        });
        if (!imageUrl && responses[index]) {
            setImageUrl(responses[index].url);
        }
    };

    const handleSubmit = () => {
        if (imageUrl) {
            firebaseLogger.airGenie('clickApply', {
                airmeet_id: airmeetId,
                userId,
                selectedOptionNb: activeIndex + 1,
                label,
            });
            handleAirGenieImageChange(imageUrl);
        }
    };

    const handleAirGenieImageChange = async (url) => {
        try {
            setIsUploading(true);
            if (onChange) {
                let imgUrl = imageUrl;
                if (cropData) {
                    const file = await fetch(cropData)
                        .then((res) => res.arrayBuffer())
                        .then(
                            (buf) =>
                                new File([buf], `New BG Image ${uuid()}`, {
                                    type: ['image/png'],
                                })
                        );
                    const response = await dispatch(
                        uplaodFile(uploadFilePrefix, file, 'ag_img')
                    );
                    imgUrl = response?.payload?.file?.url;
                } else {
                    const file = await fetch(url)
                        .then((res) => res.blob())
                        .then((blob) => {
                            const file = new File([blob], 'File name', {
                                type: 'image/png',
                            });
                            return file;
                        });
                    const response = await dispatch(
                        uplaodFile(uploadFilePrefix, file)
                    );
                    imgUrl = response?.payload?.file?.url;
                }
                if (imgUrl) {
                    onChange({
                        ...(value || {}),
                        value: imgUrl,
                        meta: {
                            ...(value?.meta || {}),
                            zoom,
                            blur,
                            opacity,
                            images: [
                                ...(value?.meta?.images || []),
                                { url: imgUrl },
                            ],
                        },
                    });
                }
            }
            setIsUploading(false);
            onClose();
        } catch (e) {
            logger.error('Image generation submit: ', e);
            setIsUploading(false);
        }
    };

    const handleReset = () => {
        setOpacity(DEFAULT_BACKGROUND_OPACITY);
        setBlur(DEFAULT_BACKGROUND_BLUR);
        setZoom(DEFAULT_BACKGROUND_ZOOM);
    };

    const handleNext = () => {
        if (hasResponses) {
            if (hasImageCropper) {
                setIsImageLoading(true);
                setZoom(DEFAULT_BACKGROUND_ZOOM);
            }
            setActiveIndex((val) =>
                val >= responses.length - 1 ? 0 : val + 1
            );
        }
    };

    const handlePrev = () => {
        if (hasResponses) {
            if (hasImageCropper) {
                setIsImageLoading(true);
                setZoom(DEFAULT_BACKGROUND_ZOOM);
            }
            setActiveIndex((val) =>
                val <= 0 ? responses.length - 1 : val - 1
            );
        }
    };

    const scaledZoom = useMemo(() => (zoom / 100) * MAX_ZOOM_LIMIT, [zoom]);
    const disableReset = useMemo(
        () =>
            blur === DEFAULT_BACKGROUND_BLUR &&
            opacity === DEFAULT_BACKGROUND_OPACITY &&
            zoom === DEFAULT_BACKGROUND_ZOOM,
        [blur, opacity, zoom]
    );

    return (
        <Drawer
            title={title}
            width='90vw'
            portal={portal}
            bodyStyle={bodyStyle}
            showBackButton
            onClose={onClose}
            style={{
                top,
            }}
            height={drawerHeight}
            headerStyle={{ padding: '28px 32px 20px' }}
        >
            <Body width={'100%'}>
                <LeftWrapper>
                    {hideAutoGenerate ? null : (
                        <>
                            <FlexDiv alignItems='center'>
                                <StyledSvgSprite
                                    icon='magic_wand'
                                    fill='accentRose.1'
                                    marginRight='8px'
                                    height='24px'
                                    width='24px'
                                />
                                <Typography variant='h6' color='accentRose.1'>
                                    AirGenie
                                </Typography>
                                <BetaTag v2={true} tooltipText={null} />
                            </FlexDiv>
                            <Typography
                                variant='subtext0'
                                color='text.default.primary'
                                pb={1}
                            >
                                Autogenerate images using AI
                            </Typography>
                            <FlexDiv alignItems='center' pb='12px'>
                                <Typography
                                    variant='body2'
                                    color='ambience.0'
                                    pr='8px'
                                >
                                    Style
                                </Typography>
                                <Select
                                    options={AIR_GENIE_IMAGE_STYLE_OPTIONS}
                                    value={style}
                                    onChange={setStyle}
                                    wrapperStyles={{ flex: 1 }}
                                    roundCorners
                                />
                            </FlexDiv>
                            <FlexDiv
                                flexDirection='column'
                                alignItems='stretch'
                                flex='1'
                            >
                                <AirGenieTextarea
                                    id='userInput'
                                    name='userInput'
                                    value={description}
                                    onChange={handleChange}
                                    variant='normal'
                                    placeholder={`${placeholderPrefix} using a few keywords and click on 'Generate' for suggestions by AirGenie`}
                                    disabled={loading}
                                    maxLength={AIR_GENIE_MAX_DESC_LENGTH}
                                    placeholderColor='ambience.14'
                                />
                                <AirGenieSectionFooter
                                    variant='normal'
                                    borderColor='ambience.14'
                                >
                                    <Typography
                                        variant='caption'
                                        color='ambience.6'
                                        flex='1'
                                    >
                                        {description.length}/
                                        {AIR_GENIE_MAX_DESC_LENGTH}
                                    </Typography>
                                    <FlexDiv
                                        flexDirection='column'
                                        alignItems='flex-end'
                                    >
                                        <WithTooltip
                                            tooltip={
                                                attemptsExhausted
                                                    ? 'You have used all attempts.'
                                                    : description?.length < 10
                                                    ? 'Minimum 10 characters are required for a valid prompt.'
                                                    : null
                                            }
                                        >
                                            <GenButton
                                                variant={
                                                    hasResponses
                                                        ? 'link'
                                                        : 'primary'
                                                }
                                                onClick={handleGenerate}
                                                loading={
                                                    loading ||
                                                    isResponsesLoading
                                                }
                                                color={
                                                    hasResponses
                                                        ? 'accentPrimary.0'
                                                        : ''
                                                }
                                                disabled={
                                                    description?.length < 10 ||
                                                    attemptsExhausted
                                                }
                                                padding={
                                                    hasResponses
                                                        ? '0px'
                                                        : '0px 12px'
                                                }
                                            >
                                                {hasResponses ? (
                                                    <StyledSvgSprite
                                                        icon='arrow_sync'
                                                        fill='accentPrimary.0'
                                                        height='20px'
                                                        width='20px'
                                                        mr='4px'
                                                    />
                                                ) : null}
                                                {hasResponses
                                                    ? 'Regenerate'
                                                    : 'Generate'}
                                            </GenButton>
                                        </WithTooltip>
                                        <Text
                                            variant='caption'
                                            color='ambience.6'
                                            mt='x1'
                                            ml={hasResponses ? '12px' : '0px'}
                                        >
                                            {imageGenAttempts}/
                                            {maxImageGenAttempts} attempts
                                        </Text>
                                    </FlexDiv>
                                </AirGenieSectionFooter>
                            </FlexDiv>
                            <Divider bg='ambience.14' minHeight='1px' my={2} />
                        </>
                    )}
                    <Text variant='h4' color='text.default.primary' pb='10px'>
                        Adjust image
                    </Text>
                    <Text variant='caption' color='text.default.secondary'>
                        The uploaded image would be applied to the background
                        with a 50% black overlay for better accessibility.
                    </Text>
                    <FlexDiv flex='1'>
                        <FlexDiv
                            flexDirection='column'
                            alignItems='stretch'
                            py='12px'
                            flex='1'
                        >
                            <ZoomSlider
                                value={zoom}
                                onChange={handleZoomChange}
                                disabled={isImageLoading}
                            />
                            <BlurSlider
                                value={blur}
                                onChange={handleBlurChange}
                            />
                            <OpacitySlider
                                value={opacity}
                                onChange={handleOpacityChange}
                            />
                        </FlexDiv>
                    </FlexDiv>
                    <ResetButton
                        variant='secondary'
                        onClick={handleReset}
                        disabled={disableReset}
                    >
                        Reset to Default
                    </ResetButton>
                </LeftWrapper>
                <RightWrapper>
                    {hideAutoGenerate ? null : (
                        <Typography
                            variant='subtitle1'
                            color='text.default.primary'
                            pb={2}
                        >
                            AirGenie suggestions
                        </Typography>
                    )}
                    <Typography
                        variant='subtext0-bold'
                        color='text.default.primary'
                        pb={2}
                    >
                        Preview
                    </Typography>
                    <FlexDiv
                        flexDirection='column'
                        alignItems='stretch'
                        flex='1'
                        maxWidth={'100%'}
                        height={'calc(100% - 150px)'}
                    >
                        <FlexDiv
                            height={'calc(100% - 48px)'}
                            position='relative'
                            flexDirection='column'
                        >
                            {hasResponses && !loading ? (
                                <FlexDiv
                                    alignItems='center'
                                    justifyContent='center'
                                    mb='12px'
                                >
                                    <Button
                                        variant='link'
                                        onClick={handlePrev}
                                        mr='12px'
                                        disabled={isImageLoading}
                                    >
                                        <StyledSvgSprite
                                            icon='chevron_left'
                                            height='16px'
                                            width='16px'
                                            fill='ambience.6'
                                        />
                                    </Button>
                                    <Typography
                                        variant='subtext0'
                                        color='ambience.0'
                                    >
                                        {activeIndex + 1}/{responses.length}{' '}
                                        images
                                    </Typography>
                                    <Button
                                        variant='link'
                                        onClick={handleNext}
                                        ml='12px'
                                        disabled={isImageLoading}
                                    >
                                        <StyledSvgSprite
                                            icon='chevron_right'
                                            height='16px'
                                            width='16px'
                                            fill='ambience.6'
                                        />
                                    </Button>
                                </FlexDiv>
                            ) : null}
                            {loading ? (
                                <LoaderWrapper height={previewHeight}>
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: animationData,
                                            rendererSettings: {
                                                preserveAspectRatio:
                                                    'xMidYMid slice',
                                            },
                                        }}
                                        height={'40px'}
                                        width={'80px'}
                                        isClickToPauseDisabled={true}
                                    />
                                </LoaderWrapper>
                            ) : null}
                            {renderPreviewComponent({
                                blur,
                                opacity,
                                scaledZoom,
                                imgUrl: imageUrl,
                                isImageLoading,
                                setIsImageLoading,
                                setCropData,
                                previewHeight,
                                imageHeight,
                                imageWidth,
                            })}
                        </FlexDiv>
                        <FlexDiv
                            alignItems='center'
                            alignSelf='flex-end'
                            mt={1}
                        >
                            <FooterButton
                                variant='primary'
                                mr='13px'
                                onClick={handleSubmit}
                                disabled={!imageUrl || loading}
                                loading={isUploading}
                            >
                                Save
                            </FooterButton>
                            <FooterButton variant='secondary' onClick={onClose}>
                                Cancel
                            </FooterButton>
                        </FlexDiv>
                    </FlexDiv>
                </RightWrapper>
            </Body>
        </Drawer>
    );
}

export default function AirGenieImageSuggestionsDrawer({
    showInsidePortal = false,
    portalContainer,
    ...rest
}) {
    const { Portal } = usePortal({
        clickOutsideToHide: false,
        escToHide: false,
        containerId: portalContainer ? portalContainer : 'sidebarWrapper',
    });

    return showInsidePortal ? (
        <Portal>
            <AirGenieImageSuggestionsDrawerWrapper {...rest} />
        </Portal>
    ) : (
        <AirGenieImageSuggestionsDrawerWrapper {...rest} />
    );
}

const LeftWrapper = styled(FlexDiv)`
    background-color: ${({ theme }) => theme.colors.fill.default2};
    width: 360px;
    flex-direction: column;
    align-items: stretch;
    padding: 24px;
    overflow-y: auto;
    ${({ theme, verticalWidth }) => getCustomScroll({ theme, verticalWidth })};
`;

const RightWrapper = styled(FlexDiv)`
    padding: 20px;
    flex-direction: column;
    flex: 1;
`;

const LoaderWrapper = styled(FlexDiv)`
    position: absolute;
    height: ${({ height }) => (height ? height : '100%')};
    width: 100%;
    align-items: center;
    z-index: ${({ theme }) =>
        getZIndex({
            zIndexType: theme.zIndices.types.above,
            zIndexRange: theme.zIndices.ranges.xl,
        })};
    background-color: ${({ theme }) => theme.colors.overlay.default1};
    border-radius: ${({ theme }) => theme.radii.default};
`;

const bodyStyle = { padding: 0, paddingLeft: '32px' };

const Body = styled(FlexDiv)`
    border-top: 1px solid rgba(255, 255, 255, 0.2);
`;

const FooterButton = styled(Btn)`
    padding-left: 24px;
    padding-right: 24px;
`;

const ResetButton = styled(Btn)`
    align-self: flex-start;
    margin-top: 16px;
`;
