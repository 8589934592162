import { accessibilityStyles } from 'components/molecules/utils/basicComponents';
import React from 'react';
import styled from 'styled-components';
import {
    background,
    border,
    color,
    flexbox,
    grid,
    layout,
    position,
    shadow,
    space,
    system,
} from 'styled-system';
import { select } from 'utils/constants/common';
import WithTooltip from './WithTooltip';

function SVGSprite({
    icon,
    className = '',
    fluent = false,
    toolTip = '',
    tooltipPlace = 'top',
    useProps = {},
    ...props
}) {
    return (
        <WithTooltip tooltip={toolTip} place={tooltipPlace}>
            <svg className={`icon ${icon} ${className}`} {...props}>
                <use xlinkHref={`#${icon}`} {...useProps} />
            </svg>
        </WithTooltip>
    );
}

export default SVGSprite;

const fill = system({
    fill: {
        property: 'fill',
        scale: 'colors',
    },
});

export const StyledSvgSprite = styled(SVGSprite)`
    ${space}
    ${color}
    ${layout}
    ${flexbox}
    ${grid}
    ${background}
    ${border}
    ${position}
    ${shadow}
    ${fill}
    ${({ theme, lightThemeFill }) =>
        theme.isLightTheme &&
        lightThemeFill &&
        `fill: ${select(lightThemeFill)(theme.colors)};`}
    ${accessibilityStyles()}
`;
