import { CONVERSATIONS_TAB } from 'components/StageRoom/SessionRHS/constants';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { emptyObject, noop } from 'utils/constants/common';
import { LIST_VIEW } from './peopleTab/constants';

// Fix circular imports
const EVENT_FEED_INNER_TAB_LABEL = 'Feed';
export const SESSION_FEED_INNER_TAB_LABEL = 'Chat';
const SESSION_RESOURCES_INNER_TAB_LABEL = ' Resources';

const defaultRoute = {
    activeTab: null,
    activeSessionTab: CONVERSATIONS_TAB,
    attendeesLayout: LIST_VIEW,
    dmChatId: null,
    eventFeedInnerTab: EVENT_FEED_INNER_TAB_LABEL,
    sessionFeedInnerTab: SESSION_FEED_INNER_TAB_LABEL,
    previousTab: null,
    activeMenu: null, // Active menu inside the tab to be highlighted
    sessionManageInnerTab: null,
    sessionResourcesInnerTab: SESSION_RESOURCES_INNER_TAB_LABEL,
    routeProps: emptyObject, // Pass any other props with routes
};

const SidebarRouteContext = createContext(defaultRoute);

const SidebarRouteUpdaterContext = createContext(noop);

export const useSidebarRouter = () => {
    const route = useContext(SidebarRouteContext);
    const updateRoute = useContext(SidebarRouteUpdaterContext);
    return {
        route,
        updateRoute,
    };
};

const SidebarRouter = ({ children }) => {
    // sidebar routing
    const [route, setRoute] = useState(defaultRoute);

    const updateRoute = useCallback((newRoute) => {
        setRoute((route) => ({
            ...route,
            routeProps: emptyObject,
            ...newRoute,
        }));
    }, []);

    return (
        <SidebarRouteUpdaterContext.Provider value={updateRoute}>
            <SidebarRouteContext.Provider value={route}>
                {children}
            </SidebarRouteContext.Provider>
        </SidebarRouteUpdaterContext.Provider>
    );
};

export default SidebarRouter;
