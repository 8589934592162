import { MODAL_TYPE_PERMISSION_SETUP } from 'components/modals';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showFixedModal } from 'store/actions/layout';
import styled from 'styled-components';
import { DEVICE_SETTING_SOURCE } from 'utils/constants/deviceSettings';
const AudioVideoSettingsButton = ({
    children,
    onClick,
    triggerAction,
    successText,
    onClose,
    className,
    backdropClassName,
    ...rest
}) => {
    const dispatch = useDispatch();
    const {
        airmeet: { airmeetId, data: { userRole } = {} },
    } = useLiveAirmeetContext();
    const authUserId = useSelector((s) => s.auth.user.id);

    const showPermissionModal = useCallback(() => {
        dispatch(
            showFixedModal(MODAL_TYPE_PERMISSION_SETUP, {
                onClose,
                successText,
                manualTrigger: true,
                hasBG: false,
                userRole,
                userId: authUserId,
                airmeetId,
                triggerAction,
                className,
                backdropClassName,
                source: DEVICE_SETTING_SOURCE.RHS_AV_SETTINGS,
            })
        );
    }, [
        triggerAction,
        userRole,
        authUserId,
        airmeetId,
        dispatch,
        successText,
        onClose,
        className,
        backdropClassName,
    ]);

    return (
        <ButtonWrapper onClick={showPermissionModal} {...rest}>
            {children}
        </ButtonWrapper>
    );
};

export default AudioVideoSettingsButton;

const ButtonWrapper = styled.button`
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    box-shadow: none;
    background-color: transparent;

    &:visited,
    &:hover,
    &:focus,
    &:active,
    &:disabled {
        padding: 0;
        margin: 0;
        border: 0;
        outline: none;
        box-shadow: none;
        background-color: transparent;
    }
`;
