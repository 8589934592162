import Button from 'atoms/Button/button';
import { Typography } from 'foundations/typography';
import React from 'react';
import styled from 'styled-components';
import { cdnImage } from 'utils/core';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import keys from 'locale/keys';

const ErrorTemplate = ({
    errorMessage,
    height,
    defaultHeading,
    style = {},
    icon = true,
}) => {
    const { t } = useCustomTranslation();
    return (
        <Container
            className='something-wrong'
            height={height || '50%'}
            style={style}
        >
            {icon && (
                <FailedImageBox mr={1}>
                    <Image
                        alt='bad-luck'
                        src={cdnImage('/images/bad-luck.svg')}
                    />
                </FailedImageBox>
            )}
            <Typography pb={'5px'} variant='subtitle2-bold'>
                {errorMessage ||
                    defaultHeading ||
                    t(keys.ERROR_BOUNDRY_LOAD_FAILED)}
            </Typography>
            {!errorMessage && (
                <>
                    <Typography
                        pb={'24px'}
                        variant='subtext2'
                        color='ambience.6'
                    >
                        {t(keys.ERROR_BOUNDRY_TRY_AGAIN)}
                    </Typography>

                    <ReloadButton
                        onClick={() => window.location.reload()}
                        variant={'primary'}
                    >
                        {t(keys.ERROR_BOUNDRY_RELOAD_CTA)}
                    </ReloadButton>
                </>
            )}
        </Container>
    );
};

const Image = styled.img`
    max-width: 100%;
`;

const ReloadButton = styled(Button)`
    cursor: pointer;
    width: 150px;
    padding: 8px 24px;
`;

const FailedImageBox = styled(FlexDiv)`
    height: 98px;
    width: 88px;
    margin: auto;
    margin-bottom: 27px;
    ${Image} {
        height: 98px;
        width: 88px;
    }
`;

const Container = styled(FlexDiv)`
    width: 60%;
    margin: auto;
    margin-top: 50px;
    flex-direction: column;
    align-self: center;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

export default ErrorTemplate;
