import {
    FETCH_PAGINATED_HUBS_RESPONSE,
    ARCHIVE_HUB_RESPONSE,
    CREATE_HUB_RESPONSE,
    FETCH_CONTENT_HUB_DETAILS_RESPONSE,
    GET_REGISTRATION_FORM_RESPONSE,
    FETCH_CONTENT_LIBRARY_RESPONSE,
    FETCH_EVENTS_RESPONSE,
    FETCH_TEMPLATES_RESPONSE,
    SELECT_TEMPLATE_RESPONSE,
    FETCH_UPLOADED_TYPES_RESPONSE,
    FETCH_UPLOADED_MEMBERS_RESPONSE,
    SELECT_FORM_TRIGGER_RESPONSE,
    FETCH_PAGINATED_EVENTS_RESPONSE,
    RESET_PAGINATED_EVENTS,
} from '../actions/contentHub';
import createReducer from '../../utils/createReducer';

const handlers = {};
const initialState = {
    hubs: [],
};

handlers[FETCH_PAGINATED_HUBS_RESPONSE] = (state = initialState, action) => {
    let { contentHub } = state || {};
    if (!Array.isArray(contentHub)) {
        contentHub = [];
    }
    let payload = [];
    if (Array.isArray(action.payload.data.content_hubs)) {
        payload = action.payload.data.content_hubs;
    }

    const newHubs = [...payload];
    // const newHubs = [...contentHub, ...payload];
    return { ...state, hubs: newHubs };
};

handlers[FETCH_CONTENT_HUB_DETAILS_RESPONSE] = (
    state = initialState,
    action
) => {
    let { hubDetails } = state || {};

    let payload = [];
    if (Array.isArray(action.payload.data) && action.payload.data.length > 0) {
        payload = action.payload.data[0];
    }
    const newContentHubDetails = { ...payload };
    return { ...state, hubDetails: newContentHubDetails };
};

handlers[CREATE_HUB_RESPONSE] = (state = initialState, action) => {
    const hub = action.payload;
    let responseState = {};
    if (hub.id) {
        responseState = {
            createdHubId: hub.id,
            errors: false,
        };
    } else {
        responseState = {
            errors: true,
        };
    }

    // const newHub = [...hub, ...responseState];

    return { ...state, responseState };
};

handlers[ARCHIVE_HUB_RESPONSE] = (state = initialState, action) => {
    const hub = action.payload;
    let responseState = {};
    if (hub.id) {
        responseState = {
            createdHubId: hub.id,
            errors: false,
        };
    } else {
        responseState = {
            errors: true,
        };
    }

    // const newHub = [...hub, ...responseState];

    return { ...state, responseState };
};

handlers[GET_REGISTRATION_FORM_RESPONSE] = (state = initialState, action) => {
    const form = action.payload;
    let leads = {};
    if (form.data?.data) {
        leads = {
            form: form.data.data,
            errors: false,
        };
    } else {
        leads = {
            errors: true,
        };
    }
    return { ...state, leads };
};

handlers[FETCH_CONTENT_LIBRARY_RESPONSE] = (state = initialState, action) => {
    let { contentLibrary } = state || {};

    if (!Array.isArray(contentLibrary)) {
        contentLibrary = [];
    }
    let payload = [];
    if (Array.isArray(action.payload.data.content_libraries)) {
        payload = action.payload.data.content_libraries;
    }

    const newContentLibrary = [...payload];
    return { ...state, contentLibrary: newContentLibrary };
};

handlers[SELECT_TEMPLATE_RESPONSE] = (state = initialState, action) => {
    let { selectedTemplate } = state || {};

    let payload = action.payload.data;

    const newSelectedTemplate = { ...selectedTemplate, ...payload };
    return { ...state, selectedTemplate: newSelectedTemplate };
};
handlers[SELECT_FORM_TRIGGER_RESPONSE] = (state = initialState, action) => {
    let { selectedFormTrigger } = state || {};
    let payload = action.payload.data;

    const newSelectedFormTrigger = { ...selectedFormTrigger, ...payload };
    return { ...state, selectedFormTrigger: newSelectedFormTrigger };
};

handlers[FETCH_EVENTS_RESPONSE] = (state = initialState, action) => {
    let { event } = state || {};

    if (!Array.isArray(event)) {
        event = [];
    }
    let payload = [];
    if (Array.isArray(action.payload)) {
        payload = action.payload;
    }

    // const newEvents = [...event, ...payload];
    const newEvents = [...payload];
    return { ...state, event: newEvents };
};
handlers[FETCH_TEMPLATES_RESPONSE] = (state = initialState, action) => {
    let { template } = state || {};

    if (!Array.isArray(template)) {
        template = [];
    }
    let payload = [];
    if (Array.isArray(action.payload.data.templates)) {
        payload = action.payload.data.templates;
    }

    const newTemplates = [...payload];

    return { ...state, template: newTemplates };
};

handlers[FETCH_UPLOADED_TYPES_RESPONSE] = (state = initialState, action) => {
    let { types } = state || {};
    if (!Array.isArray(types)) {
        types = [];
    }
    let payload = [];
    if (Array.isArray(action.payload.data.uniqueValues)) {
        payload = action.payload.data.uniqueValues;
    }

    const newTypes = [...payload];

    return { ...state, types: newTypes };
};
handlers[FETCH_UPLOADED_MEMBERS_RESPONSE] = (state = initialState, action) => {
    let { members } = state || {};
    if (!Array.isArray(members)) {
        members = [];
    }
    let payload = [];
    if (Array.isArray(action.payload.data)) {
        payload = action.payload.data;
    }

    const newMembers = [...payload];

    return { ...state, members: newMembers };
};

handlers[FETCH_PAGINATED_EVENTS_RESPONSE] = (state = initialState, action) => {
    let { paginatedEvents } = state || {};
    if (!Array.isArray(paginatedEvents)) {
        paginatedEvents = [];
    }
    let newEvents = [];
    if (Array.isArray(action.payload)) {
        newEvents = action.payload;
    }

    return {
        ...state,
        paginatedEvents: [...paginatedEvents, ...newEvents],
    };
};

handlers[RESET_PAGINATED_EVENTS] = (state = initialState) => {
    return {
        ...state,
        paginatedEvents: [],
    };
};

export default createReducer(initialState, handlers);
