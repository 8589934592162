import {
    ACCESSIBILITY_TAB,
    ACCESS_RULES_TAB,
    ANALYTICS_ENGAGEMENT_TAB,
    ANALYTICS_POST_EVENT_TAB,
    ANALYTICS_REGISTRATIONS_TAB,
    ANALYTICS_SUMMARY_TAB,
    ANALYTICS_TAB,
    ATTENDEE_ENGAGEMENT_TAB,
    AUDIENCE_TAB,
    BOOTHS_TAB,
    BRANDING_TAB,
    EMAILS_TAB,
    INTEGRATIONS_TAB,
    LANDING_PAGE_TAB,
    LEADERBOARD_TAB,
    LIVE_STREAM_TAB,
    POST_EVENT_ENTRY_TAB,
    PRE_EVENT_ENTRY_TAB,
    PRE_RECORDED_VIDEO_TAB,
    RECORDINGS_SETTINGS_TAB,
    RECORDINGS_TAB,
    REGISTRATIONS_TAB,
    SCHEDULE_TAB,
    SETTINGS_TAB,
    SPEAKERS_TAB,
    SPONSORS_TAB,
    STAGE_TAB,
    STREAMING_RESOLUTION,
    STREAM_INTO_AIRMEET_TAB,
    SUMMARY_TAB,
    TICKETS_TAB,
    VENUE_SETTINGS_TAB,
} from 'components/community/editPublicAirmeet/constants/tabs';
import {
    ANALYTICS_ACCESS,
    ANALYTICS_ENGAGEMENT_SCREEN,
    ANALYTICS_REGISTRATION_SCREEN,
    ANALYTICS_SUMMARY_VIEW,
    ATTENDEE_ENGAGEMENT_ACCESS,
    ATTENDEE_ENGAGEMENT_VIEW,
    BASIC_INFO_ACCESS,
    BASIC_INFO_VIEW,
    BOOTHS_ACCESS,
    BOOTHS_VIEW,
    EMAIL_CUSTOMIZATION_VIEW,
    EVENT_ENTRY_ACCESS,
    EVENT_ENTRY_VIEW,
    EVENT_REPLAY_ENTRY_VIEW,
    EVENT_SPECIFIC_INTEGRATION_ACCESS,
    EVENT_SPECIFIC_INTEGRATION_VIEW,
    FULL_HD_STREAMING_AND_RECORDING_VIEW,
    INTERACTABLE,
    LANDING_PAGE_CUSTOMIZATION_VIEW,
    LIVE_STREAM_ACCESS,
    LIVE_STREAM_ADD,
    LIVE_STREAM_DELETE,
    LIVE_STREAM_UPDATE,
    LIVE_STREAM_VIEW,
    PRE_EVENT_ENTRY_VIEW,
    PROMOTE_ON_AIRMEET_VIEW,
    RECORDINGS_ACCESS,
    RECORDINGS_VIEW,
    SCHEDULE_ACCESS,
    SCHEDULE_VIEW,
    SPEAKER_HOST_ACCESS,
    SPEAKER_HOST_VIEW,
    SPONSORS_ACCESS,
    SPONSORS_VIEW,
    STAGE_BACKDROP_ACCESS,
    STAGE_BACKDROP_VIEW,
    STAGE_WAITING_SCREEN_ACCESS,
    STAGE_WAITING_SCREEN_VIEW,
    STREAMING_KEYS_VIEW,
    TICKET_ACCESS,
    TICKET_VIEW,
    VENUE_SETTINGS_ACCESS,
    VENUE_SETTINGS_VIEW,
    VIDEOS_ACCESS,
    VIDEOS_VIEW,
    VISIBLE,
} from 'components/community/team/constants';
import useUserPermission from 'hooks/roles-and-permissions/useUserPermission';

const useEventDashboardPermissions = () => {
    const { getUserPermissionValues } = useUserPermission();

    const [
        accessSummary,
        viewSummary,
        accessEventEntry,
        viewEventEntry,
        accessVenueSettings,
        viewEventSettings,
        accessSchedule,
        viewSchedule,
        accessSpeakerHost,
        viewSpeakerHost,
        accessBooth,
        viewBooth,
        accessSponsors,
        viewSponsors,
        accessAttendeeEngagement,
        viewAttendeeEngagement,
        accessStageBackdrop,
        viewStageBackdrop,
        accessVideos,
        viewVideos,
        accessAnalytics,
        accessRecordings,
        viewRecordings,
        accessEventIntegrations,
        viewEventIntegrations,
        viewAnalyticsSummary,
        analyticsRegistrationScreen,
        analyticsEngagementScreen,
        accessTickets,
        viewTickets,
        accessLiveStream,
        viewLiveStream,
        addLiveStream,
        updateLiveStream,
        deleteLiveStream,
        viewLandingPageCustomization,
        viewEmailCustomization,
        viewPreEventEntry,
        viewPostEventEntry,
        viewStreamingKeys,
        viewFullHDStreamingAndRecording,
        viewPromoteOnAirmeet,
        accessStageWaitingScreen,
        viewStageWaitingScreen,
    ] = getUserPermissionValues([
        BASIC_INFO_ACCESS,
        BASIC_INFO_VIEW,
        EVENT_ENTRY_ACCESS,
        EVENT_ENTRY_VIEW,
        VENUE_SETTINGS_ACCESS,
        VENUE_SETTINGS_VIEW,
        SCHEDULE_ACCESS,
        SCHEDULE_VIEW,
        SPEAKER_HOST_ACCESS,
        SPEAKER_HOST_VIEW,
        BOOTHS_ACCESS,
        BOOTHS_VIEW,
        SPONSORS_ACCESS,
        SPONSORS_VIEW,
        ATTENDEE_ENGAGEMENT_ACCESS,
        ATTENDEE_ENGAGEMENT_VIEW,
        STAGE_BACKDROP_ACCESS,
        STAGE_BACKDROP_VIEW,
        VIDEOS_ACCESS,
        VIDEOS_VIEW,
        ANALYTICS_ACCESS,
        RECORDINGS_ACCESS,
        RECORDINGS_VIEW,
        EVENT_SPECIFIC_INTEGRATION_ACCESS,
        EVENT_SPECIFIC_INTEGRATION_VIEW,
        ANALYTICS_SUMMARY_VIEW,
        ANALYTICS_REGISTRATION_SCREEN,
        ANALYTICS_ENGAGEMENT_SCREEN,
        TICKET_ACCESS,
        TICKET_VIEW,
        LIVE_STREAM_ACCESS,
        LIVE_STREAM_VIEW,
        LIVE_STREAM_ADD,
        LIVE_STREAM_UPDATE,
        LIVE_STREAM_DELETE,
        LANDING_PAGE_CUSTOMIZATION_VIEW,
        EMAIL_CUSTOMIZATION_VIEW,
        PRE_EVENT_ENTRY_VIEW,
        EVENT_REPLAY_ENTRY_VIEW,
        STREAMING_KEYS_VIEW,
        FULL_HD_STREAMING_AND_RECORDING_VIEW,
        PROMOTE_ON_AIRMEET_VIEW,
        STAGE_WAITING_SCREEN_ACCESS,
        STAGE_WAITING_SCREEN_VIEW,
    ]);

    const permissionMappingToTabs = {
        [SUMMARY_TAB]: {
            visible: accessSummary[VISIBLE],
            clickable: accessSummary[INTERACTABLE],
            viewable: viewSummary[INTERACTABLE] && viewSummary[VISIBLE],
        },
        [ACCESS_RULES_TAB]: {
            visible: accessEventEntry[VISIBLE],
            clickable: accessEventEntry[INTERACTABLE],
            viewable: viewEventEntry[INTERACTABLE] && viewEventEntry[VISIBLE],
        },
        [VENUE_SETTINGS_TAB]: {
            visible: accessVenueSettings[VISIBLE],
            clickable: accessVenueSettings[INTERACTABLE],
            viewable:
                viewEventSettings[INTERACTABLE] && viewEventSettings[VISIBLE],
        },
        [SCHEDULE_TAB]: {
            visible: accessSchedule[VISIBLE],
            clickable: accessSchedule[INTERACTABLE],
            viewable: viewSchedule[INTERACTABLE] && viewSchedule[VISIBLE],
        },
        [SPEAKERS_TAB]: {
            visible: accessSpeakerHost[VISIBLE],
            clickable: accessSpeakerHost[INTERACTABLE],
            viewable: viewSpeakerHost[INTERACTABLE] && viewSpeakerHost[VISIBLE],
        },
        [BOOTHS_TAB]: {
            visible: accessBooth[VISIBLE],
            clickable: accessBooth[INTERACTABLE],
            viewable: viewBooth[INTERACTABLE] && viewBooth[VISIBLE],
        },
        [SPONSORS_TAB]: {
            visible: accessSponsors[VISIBLE],
            clickable: accessSponsors[INTERACTABLE],
            viewable: viewSponsors[INTERACTABLE] && viewSponsors[VISIBLE],
        },

        [ATTENDEE_ENGAGEMENT_TAB]: {
            visible: accessAttendeeEngagement[VISIBLE],
            clickable: accessAttendeeEngagement[INTERACTABLE],
            viewable:
                viewAttendeeEngagement[INTERACTABLE] &&
                viewAttendeeEngagement[VISIBLE],
        },
        [STAGE_TAB]: {
            visible:
                accessStageBackdrop[VISIBLE] ||
                accessStageWaitingScreen[VISIBLE],
            clickable:
                accessStageBackdrop[INTERACTABLE] ||
                accessStageWaitingScreen[INTERACTABLE],
            viewable:
                (viewStageBackdrop[INTERACTABLE] &&
                    viewStageBackdrop[VISIBLE]) ||
                (viewStageWaitingScreen[VISIBLE] &&
                    viewStageWaitingScreen[INTERACTABLE]),
        },
        [PRE_RECORDED_VIDEO_TAB]: {
            visible: accessVideos[VISIBLE],
            clickable: accessVideos[INTERACTABLE],
            viewable: viewVideos[INTERACTABLE] && viewVideos[VISIBLE],
        },
        [LIVE_STREAM_TAB]: {
            visible: accessLiveStream[VISIBLE],
            clickable: accessLiveStream[INTERACTABLE],
            componentPermissions: {
                canClickAddLiveStream: addLiveStream[INTERACTABLE],
                canClickUpdateLiveStream: updateLiveStream[INTERACTABLE],
                canClickDeleteLiveStream: deleteLiveStream[INTERACTABLE],
                isAddLiveStreamVisible: addLiveStream[VISIBLE],
                isUpdateLiveStreamVisible: updateLiveStream[VISIBLE],
                isDeleteLiveStreamVisible: deleteLiveStream[VISIBLE],
            },
            viewable: viewLiveStream[INTERACTABLE] && viewLiveStream[VISIBLE],
        },
        [TICKETS_TAB]: {
            visible: accessTickets[VISIBLE],
            clickable: accessTickets[INTERACTABLE],
            viewable: viewTickets[INTERACTABLE] && viewTickets[VISIBLE],
        },
        [RECORDINGS_TAB]: {
            visible: accessRecordings[VISIBLE],
            clickable: accessRecordings[INTERACTABLE],
            viewable: viewRecordings[INTERACTABLE] && viewRecordings[VISIBLE],
        },
        [INTEGRATIONS_TAB]: {
            visible: accessEventIntegrations[VISIBLE],
            clickable: accessEventIntegrations[INTERACTABLE],
            viewable:
                viewEventIntegrations[INTERACTABLE] &&
                viewEventIntegrations[VISIBLE],
        },
        /* new dashboard tabs listed from here
         - top level tabs will be visible and clickable by default for all roles
         - child tabs will use permissions given in sheet
        */
        [REGISTRATIONS_TAB]: {
            visible: true,
            clickable: true,
        },
        [BRANDING_TAB]: {
            visible: true,
            clickable: true,
        },
        [ANALYTICS_TAB]: {
            visible: accessAnalytics[VISIBLE],
            clickable: accessAnalytics[INTERACTABLE],
        },
        [SETTINGS_TAB]: {
            visible: true,
            clickable: true,
        },
        [RECORDINGS_SETTINGS_TAB]: {
            visible: accessRecordings[VISIBLE],
            clickable: accessRecordings[INTERACTABLE],
            viewable: viewRecordings[INTERACTABLE] && viewRecordings[VISIBLE],
        },
        [LEADERBOARD_TAB]: {
            visible: accessAttendeeEngagement[VISIBLE],
            clickable: accessAttendeeEngagement[INTERACTABLE],
            viewable:
                viewAttendeeEngagement[VISIBLE] &&
                viewAttendeeEngagement[INTERACTABLE],
        },
        [PRE_EVENT_ENTRY_TAB]: {
            visible: viewPreEventEntry[VISIBLE],
            clickable: viewPreEventEntry[INTERACTABLE],
            viewable:
                viewPreEventEntry[INTERACTABLE] && viewPreEventEntry[VISIBLE],
        },
        [POST_EVENT_ENTRY_TAB]: {
            visible: viewPostEventEntry[VISIBLE],
            clickable: viewPostEventEntry[INTERACTABLE],
            viewable:
                viewPostEventEntry[INTERACTABLE] && viewPostEventEntry[VISIBLE],
        },
        [LANDING_PAGE_TAB]: {
            visible: viewLandingPageCustomization[VISIBLE],
            clickable: viewLandingPageCustomization[INTERACTABLE],
            viewable:
                viewLandingPageCustomization[INTERACTABLE] &&
                viewLandingPageCustomization[VISIBLE],
        },
        [STREAM_INTO_AIRMEET_TAB]: {
            visible: viewStreamingKeys[VISIBLE],
            clickable: viewStreamingKeys[INTERACTABLE],
            viewable:
                viewStreamingKeys[VISIBLE] && viewStreamingKeys[INTERACTABLE],
        },
        [STREAMING_RESOLUTION]: {
            visible: viewFullHDStreamingAndRecording[VISIBLE],
            clickable: viewFullHDStreamingAndRecording[INTERACTABLE],
            viewable:
                viewFullHDStreamingAndRecording[VISIBLE] &&
                viewFullHDStreamingAndRecording[INTERACTABLE],
        },
        [ACCESSIBILITY_TAB]: {
            visible: true,
            clickable: true,
            viewable: true,
        },
        // [SESSIONS_TAB]: {
        //     visible: true,
        //     clickable: false,
        // },
        // [SESSION_RESOURCES_TAB]: {
        //     visible: isVideosVisible,
        //     clickable: canClickVideos,
        // },

        // [PARTICIPANTS_TAB]: {
        //     visible: isEventEntryVisible,
        //     clickable: canClickEventEntry,
        // },
        // [REGISTRATION_FORM_TAB]: {
        //     visible: true,
        //     clickable: true,
        // },
        // [EVENT_BRANDING_TAB]: {
        //     visible: true,
        //     clickable: true,
        // },
        // [RECEPTION_TAB]: {
        //     visible: true,
        //     clickable: true,
        // },
        [EMAILS_TAB]: {
            visible: viewEmailCustomization[VISIBLE],
            clickable: viewEmailCustomization[INTERACTABLE],
            viewable:
                viewEmailCustomization[VISIBLE] &&
                viewEmailCustomization[INTERACTABLE],
        },
        [AUDIENCE_TAB]: {
            visible: accessAnalytics[VISIBLE],
            clickable: accessAnalytics[INTERACTABLE],
        },
        [ANALYTICS_SUMMARY_TAB]: {
            viewable:
                viewAnalyticsSummary[INTERACTABLE] &&
                viewAnalyticsSummary[VISIBLE],
            visible: true,
            clickable: true,
        },
        [ANALYTICS_REGISTRATIONS_TAB]: {
            viewable:
                analyticsRegistrationScreen[INTERACTABLE] &&
                analyticsRegistrationScreen[VISIBLE],
            visible: true,
            clickable: true,
        },
        [ANALYTICS_ENGAGEMENT_TAB]: {
            viewable:
                analyticsEngagementScreen[INTERACTABLE] &&
                analyticsEngagementScreen[VISIBLE],
            visible: true,
            clickable: true,
        },
        [ANALYTICS_POST_EVENT_TAB]: {
            viewable:
                analyticsEngagementScreen[INTERACTABLE] &&
                analyticsEngagementScreen[VISIBLE],
            visible: true,
            clickable: true,
        },
    };

    return { permissionMappingToTabs };
};

export default useEventDashboardPermissions;
