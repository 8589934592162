import { SESSION_TYPES } from 'utils/constants/sessions';
import { SESSION_STATUS } from './sessions';

export const CARD_TYPE = {
    SESSION: 'Session',
    LOUNGE: 'Lounge',
    BOOTH: 'Booth',
};

export const SECONDARY_CARD_SLIDE_DURATION = 3;

/**
 * Function to get the relevant sessions in order for the user
 * Order of precedance of sessions
 * 1. Sessions user is host of
 * 2. Sessions user is speaker of
 * 3. Live sessions
 * 4. Upcoming sessions
 *
 * @param {Array} sessions
 * @param {Object} userId
 */
export function getRelevantSessions({
    sessions,
    userId,
    isConferenceEvent,
    checkSessionDiscovery,
}) {
    const { ONGOING, PAUSED, FINISHED } = SESSION_STATUS;
    const sortedSessions = [...(sessions || [])]
        .filter(
            ({ status, type, sessionid }) =>
                status !== FINISHED &&
                type !== SESSION_TYPES.BREAK &&
                (isConferenceEvent ||
                    type !== SESSION_TYPES.SPEED_NETWORKING) &&
                !checkSessionDiscovery(sessionid)
        )
        .sort((session1, session2) => {
            // Host for first session
            if (session1.host_id.includes(userId)) {
                // Host for second session
                return session2.host_id.includes(userId) ? 0 : -1;
            }

            // Speaker for first session
            if (session1.speaker_id.includes(userId)) {
                // Host for second session
                return session2.host_id.includes(userId)
                    ? 1
                    : // Speaker for second session
                    session2.speaker_id.includes(userId)
                    ? 0
                    : -1;
            }

            // Host or speaker for second session
            if (
                session2.host_id.includes(userId) ||
                session2.speaker_id.includes(userId)
            ) {
                return 1;
            }

            // First session is Live
            if (session1.status === ONGOING) {
                // Second session is Live
                return session2.status === ONGOING ? 0 : -1;
            }

            // First session is Paused
            if (session1.status === PAUSED) {
                // Second session is Live
                return session2.status === ONGOING
                    ? 1
                    : // Second session is Paused
                    session2.status === PAUSED
                    ? 0
                    : -1;
            }

            // Second session is Live or Paused
            return [ONGOING, PAUSED].includes(session2.status) ? 1 : 0;
        });
    return sortedSessions;
}

export const DEFAULT_RECEPTION_BANNER_URL =
    '/images/default-reception-banner.jpg';
