import { SAVE_RECEPTION_LAYOUT } from 'store/actions/customReception';
import createReducer from 'utils/createReducer';

const initialState = {};

const handlers = {};
handlers[SAVE_RECEPTION_LAYOUT] = (state = initialState, action) => {
    if (!action.payload) {
        return state;
    }
    const receptionLayout = action.payload;
    return { ...state, receptionLayout };
};

export default createReducer(initialState, handlers);
