import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import { LOG_LEVEL } from 'utils/constants/logger';

const StreamPublisherWriter = ({ client, payload, metaData, logger }) => {
    const PublisherLog = logger.init(
        'STREAM_PUBLISHER_DATA_WRITER',
        'green',
        LOG_LEVEL.INFO
    );
    const PublisherErrorLog = logger.init(
        'STREAM_PUBLISHER_DATA_WRITER',
        'green',
        LOG_LEVEL.ERROR
    );

    const { ADD, REMOVE, ON_DISCONNECT } = FEATURE_ACTIONS[
        FEATURE_NAMES.STREAM_PUBLISHER
    ];
    const { action, channelPublisherKey, id } = metaData;

    const errorHandling = (message) => {
        PublisherErrorLog(message);
        throw new Error(message);
    };

    if (!action) {
        const message = 'No action is specified for stream publisher';
        errorHandling(message);
    }

    if (!channelPublisherKey) {
        const message =
            'No channelPublisherKey is specified for stream publisher';
        errorHandling(message);
    }

    if (!id) {
        const message = 'No id is specified for stream publisher';
        errorHandling(message);
    }

    const actionHandler = {
        /*
        ACTION: Set Stream Publisher
        DATA: PUBLISHER DETAILS 
        */
        [ADD]: ({ data }) => {
            return new Promise((resolve, reject) => {
                client.runTransaction(
                    `${channelPublisherKey}/${id}`,
                    () => {
                        PublisherLog(
                            `Setting publisher data. Channel publisher key : ${channelPublisherKey}, stream id : ${id}`
                        );
                        data = {
                            ...data,
                            time: client.getServerTimestampRef(),
                        };
                        return data;
                    },
                    (error, committed) => {
                        if (error) {
                            PublisherErrorLog(
                                'Transaction failed abnormally!',
                                error
                            );
                            return reject(error);
                        }
                        if (!committed) {
                            return reject('Transaction is not committed.');
                        }
                        resolve(committed);
                    }
                );
            });
        },
        /*
        ACTION: Remove Stream Publisher
        DATA : null
        */
        [REMOVE]: ({ data }) => {
            PublisherLog(
                `Removing publisher data. Channel publisher key : ${channelPublisherKey}, stream id : ${id}`
            );
            return client.setData(`${channelPublisherKey}/${id}`, data);
        },
        /*
        ACTION: Remove Stream Publisher On Disconnect
        DATA : null

        */
        [ON_DISCONNECT]: ({ data }) => {
            PublisherLog(
                `Clear the publisher data when disconnect. Channel publisher key : ${channelPublisherKey}, stream id : ${id}`
            );
            return client.setOnDisconnect(`${channelPublisherKey}/${id}`, data);
        },
    };

    return actionHandler[action](payload);
};

export default StreamPublisherWriter;
