import identity from 'lodash/identity';
import React, { createContext, useContext, useMemo, useState } from 'react';

const ManageCoHost = createContext(null);

export const useManageCoHostContext = (callback = identity) => {
    const ctx = useContext(ManageCoHost);

    // Return the up-to-date selected value
    const finalValue = callback(ctx);
    return useMemo(() => finalValue, [finalValue]);
};

export const ManageCoHostProvider = ({ children }) => {
    const [showManageCoHostModal, setShowManageCoHostModal] = useState(false);

    return (
        <ManageCoHost.Provider
            value={{
                showManageCoHostModal,
                setShowManageCoHostModal,
            }}
        >
            {children}
        </ManageCoHost.Provider>
    );
};

export default ManageCoHost;
