import Button from 'atoms/Button/button';
import Drawer from 'atoms/Drawer/drawer';
import { CreateAirmeetV2 } from 'components/community/CreateAirmeetForm/CreateAirmeetV2';
import { CHAR_LIMIT_MAP } from 'components/community/CreateAirmeetForm/CreateAirmeetV2/validations';
import {
    MEETUP_MODE,
    NEW_ONBOARDING_INITIATION,
} from 'components/community/CreateAirmeetForm/constants';
import {
    ACTIVE_LANDING_PAGE_SCREEN_SOURCE,
    PENDING_LANDING_PAGE_SCREEN_SOURCE,
    QUESTIONS_SCREEN_SOURCE,
} from 'components/newOnboardingFlow/config';
import { getSubstring } from 'components/newOnboardingFlow/utils';
import { AnimatePresence } from 'framer-motion';
import { useShallowEqualSelector } from 'hooks/common';
import useOnboardingAnalytics from 'hooks/onboarding/useOnboardingAnalytics';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import LocalizedString from '../../translation/header.json';

const ProfileButton = React.lazy(() => import('./ProfileButton'));

const MobileProfileButton = ({
    showDrawer,
    setShowDrawer,
    profileImg,
    profileProps,
}) => (
    <>
        <AvatarImgWrap
            onClick={() => {
                setShowDrawer(true);
            }}
        >
            <AvatarImg src={profileImg} alt='profile_img' />
        </AvatarImgWrap>
        <AnimatePresence>
            {showDrawer && (
                <Drawer
                    width={'calc(100vw/1.481)'}
                    activeLightTheme={true}
                    wrapperClosable={true}
                    bodyStyle={{
                        background: '#fff',
                        padding: '17px 10px 0px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                    headerStyle={{
                        background: '#fff',
                        padding: '0px',
                    }}
                    onClose={() => {
                        setShowDrawer(false);
                    }}
                    portal={false}
                >
                    <ProfileButton
                        {...profileProps}
                        undismissableDropdown={true}
                    />
                </Drawer>
            )}
        </AnimatePresence>
    </>
);

const DesktopProfileButton = (props) => <ProfileButton {...props} />;

const SHOW_PROFILE_MENU_BUTTON = false;

function OnBoardingButton({ source, logoutHandler, isMobile, isTablet }) {
    const [isCreateFormOpen, setCreateFormVisibility] = useState(false);
    const [createMode, setCreateMode] = useState('');
    const [communityId, setCommunityId] = useState('');
    const [showDrawer, setShowDrawer] = useState(false);
    const { analyticsEventType, logAnalyticsEvent } = useOnboardingAnalytics();
    const { profile_img } = useShallowEqualSelector(
        (state) => state.auth.user || {}
    );
    const { id } = useShallowEqualSelector((state) => state.auth.user || {});
    const { defaultCommunity } = useSelector((state) => state.Community);
    const { name: communityName } = defaultCommunity || {};

    useEffect(() => {
        if (!isEmpty(defaultCommunity)) {
            setCommunityId(defaultCommunity?.communityId || '');
        }
    }, [defaultCommunity]);

    const handleButtonClick = (source) => {
        logAnalyticsEvent(analyticsEventType.clickedCreateEvent);
        setCreateFormVisibility(true);
        setCreateMode(MEETUP_MODE);
    };

    const commonProps = {
        newOnBoardingFlow:
            source === ACTIVE_LANDING_PAGE_SCREEN_SOURCE ? false : true,
        localString: LocalizedString['en'].profileMenu,
        logoutHandler: logoutHandler,
        dotsMenu: isMobile ? false : true,
    };

    return (
        <Wrapper source={source} isMobile={isMobile}>
            {[
                PENDING_LANDING_PAGE_SCREEN_SOURCE,
                ACTIVE_LANDING_PAGE_SCREEN_SOURCE,
            ].includes(source) &&
                !isMobile && (
                    <>
                        <CreateEventButton
                            variant='primary'
                            onClick={() => {
                                handleButtonClick(source);
                            }}
                        >
                            Create event
                        </CreateEventButton>
                    </>
                )}
            {SHOW_PROFILE_MENU_BUTTON &&
                [
                    QUESTIONS_SCREEN_SOURCE,
                    PENDING_LANDING_PAGE_SCREEN_SOURCE,
                    ACTIVE_LANDING_PAGE_SCREEN_SOURCE,
                ].includes(source) &&
                (!isMobile ? (
                    <DesktopProfileButton {...commonProps} />
                ) : (
                    <MobileProfileButton
                        showDrawer={showDrawer}
                        setShowDrawer={setShowDrawer}
                        profileImg={profile_img}
                        profileProps={commonProps}
                    />
                ))}
            {!isMobile && (
                <AnimatePresence>
                    {createMode && isCreateFormOpen && (
                        <CreateAirmeetV2
                            createMode={createMode}
                            onClose={() => {
                                setCreateFormVisibility(false);
                                setCreateMode('');
                            }}
                            initiatedFrom={NEW_ONBOARDING_INITIATION}
                            defaultCommunityId={communityId}
                            hideRightSection={isTablet ? true : false}
                            defaultCommunityName={getSubstring(
                                communityName,
                                0,
                                CHAR_LIMIT_MAP.eventOrganiserName.max
                            )}
                        />
                    )}
                </AnimatePresence>
            )}
        </Wrapper>
    );
}

export default OnBoardingButton;

const Wrapper = styled.div`
    display: flex;
    width: auto;
    align-items: center;
    .profile-container {
        ${({ isMobile }) => !isMobile && `align-self: flex-end;`};
        .arrow {
            display: none !important;
        }
        .profile {
            padding: 0 !important;
            background-color: transparent !important;
            display: flex !important;
            align-items: center !important;
            img {
                ${({ isMobile }) =>
                    !isMobile &&
                    `
                height: 40px !important;
                width: 40px !important;
                border-radius: 100% !important;
                object-fit: cover !important;
                `}
        }
      
    }
    .dropdown-menu {
        background: #fff !important;
        ${({ isMobile }) =>
            !isMobile &&
            `
            border: 1px solid #eceff8 !important;
            border-radius: 8px !important;
            border-top: none !important;
            border-top-right-radius: 0px !important;
            border-top-left-radius: 0px !important;
            border-top-color: transparent !important;

        `}
        min-width: ${({ isMobile }) =>
            isMobile ? 'calc(100vw/1.553) !important' : '112px !important'};
        box-shadow: none !important;
        top: ${({ isMobile, source }) =>
            isMobile
                ? '30px !important'
                : source === QUESTIONS_SCREEN_SOURCE
                ? '47px !important'
                : '45px !important'};
        li {
            padding: 3px 8px !important;
            p {
                font-size:${({ isMobile }) =>
                    isMobile ? '14px !important' : '12px !important'};
                line-height: ${({ isMobile }) =>
                    isMobile ? '18px !important' : '16px !important'};
            }
            &:not(:last-child) {
                p {
                    color: ${({ theme }) =>
                        `${theme.colors.ambience[9]} !important`};
                }
            }
        }
    }
`;

const CreateEventButton = styled(Button)`
    margin-right: 22px;
    padding: 0px 24px;
    height: 40px;
    border: ${({ theme }) => `1px solid ${theme.colors.accentPrimary[1]}`};
`;

const AvatarImg = styled.img`
    width: 30px !important;
    height: 30px !important;
    border-radius: 100% !important;
`;
const AvatarImgWrap = styled.div``;
