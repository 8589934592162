import { Box } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import isNumber from 'lodash/isNumber';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { logger } from 'utils/logger';
import { AnalyticsContext } from '../AnalyticsProvider';
import EmptyCardBanner from '../components/EmptyCardBanner';
import {
    Card,
    CardBody,
    CardTitle,
    HrVertical,
    Loading,
    LoadingWrapper,
} from '../styledComponents';
import { getCommonBarOptions } from '../utils';

const MAX_BARS = 6;
const barColors = [
    'rgba(109, 198, 151, 1)',
    'rgba(109, 198, 151, 0.88)',
    'rgba(109, 198, 151, 0.72)',
    'rgba(109, 198, 151, 0.64)',
    'rgba(109, 198, 151, 0.56)',
    'rgba(109, 198, 151, 0.48)',
];

export default function TicketingCard() {
    const { theme } = useTheme();
    const {
        airmeetId,
        reportNotGenerated,
        getRequest,
        setTicketingApiSuccess,
        showTicketingCard,
    } = useContext(AnalyticsContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const fetchData = useCallback(async () => {
        if (airmeetId && !reportNotGenerated) {
            setLoading(true);
            let apiFailed = true;
            try {
                const { data, statusCode } = await getRequest({
                    endpoint: 'ticket-analytics',
                    forceOldApi: true,
                });
                if (statusCode === 200) {
                    setData(data);
                    apiFailed = false;
                } else {
                    setData([]);
                }
            } catch (e) {
                logger.error(e);
            }
            setLoading(false);

            setTicketingApiSuccess(!apiFailed);
        }
    }, [reportNotGenerated, setTicketingApiSuccess, airmeetId, getRequest]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (!showTicketingCard) return null;

    const isTicketSalesEmpty = !(
        Array.isArray(data?.ticketSales) && data?.ticketSales.length > 0
    );
    const chartOptions = {
        ...(getCommonBarOptions({ theme }) || {}),
    };
    if (isTicketSalesEmpty) {
        chartOptions.animation = {};
        chartOptions.tooltips = {
            enabled: false,
        };
    }

    return (
        <Card>
            <Box className='row' style={{ height: '100%' }}>
                <Box
                    className='col-sm-3'
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <CardTitle>Ticket earnings</CardTitle>
                    {loading ? (
                        <LoadingWrapper style={{ minHeight: '200px' }}>
                            <Loading />
                        </LoadingWrapper>
                    ) : (
                        <CardBody
                            style={{
                                display: 'flex',
                                flexGrow: 1,
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-start',
                                paddingBottom: '0.5em',
                            }}
                        >
                            {Array.isArray(data?.totalTicketEarnings) &&
                            data?.totalTicketEarnings.length ? (
                                <Typography
                                    variant='subtext1'
                                    color='ambience.4'
                                    style={{
                                        marginBottom: '8px',
                                    }}
                                >
                                    You have made
                                </Typography>
                            ) : null}
                            <p
                                style={{
                                    color:
                                        Array.isArray(
                                            data?.totalTicketEarnings
                                        ) && data?.totalTicketEarnings.length
                                            ? '#6DC697'
                                            : '#7C7891',
                                    fontWeight: 'bold',
                                    fontSize: '24px',
                                    lineHeight: '24px',
                                    marginBottom: '8px',
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                }}
                            >
                                {Array.isArray(data?.totalTicketEarnings) &&
                                data?.totalTicketEarnings.length ? (
                                    <>
                                        <div
                                            style={{
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                marginRight: '4px',
                                            }}
                                        >
                                            {
                                                data?.totalTicketEarnings[0]
                                                    ?.currency
                                            }
                                        </div>
                                        <div>
                                            {data?.totalTicketEarnings[0]?.amount
                                                .toString()
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ','
                                                ) || 0}
                                        </div>
                                    </>
                                ) : (
                                    ' -'
                                )}
                            </p>

                            {isNumber(data?.totalTicketSaleCount) ? (
                                <Typography
                                    variant='subtext1'
                                    color='ambience.4'
                                >
                                    through {data?.totalTicketSaleCount || 0}{' '}
                                    ticket sales
                                </Typography>
                            ) : null}
                        </CardBody>
                    )}
                </Box>
                <Box
                    className='col-sm-1'
                    style={{ paddingTop: '16px', paddingBottom: '16px' }}
                >
                    <HrVertical />
                </Box>
                <Box className='col-sm-8'>
                    <CardTitle>Ticket sales</CardTitle>
                    {loading ? (
                        <LoadingWrapper style={{ minHeight: '200px' }}>
                            <Loading />
                        </LoadingWrapper>
                    ) : (
                        <CardBody>
                            <HorizontalBar
                                data={{
                                    labels: isTicketSalesEmpty
                                        ? Array(MAX_BARS).fill('')
                                        : (data?.ticketSales || []).map(
                                              (sale) => sale.ticket_name
                                          ),
                                    datasets: [
                                        {
                                            data: isTicketSalesEmpty
                                                ? Array(MAX_BARS).fill(5)
                                                : (data?.ticketSales || []).map(
                                                      (sale) =>
                                                          sale.ticket_count
                                                  ),
                                            backgroundColor: isTicketSalesEmpty
                                                ? Array(MAX_BARS).fill(
                                                      '#26252D'
                                                  )
                                                : (data?.ticketSales || []).map(
                                                      (sale, index) =>
                                                          barColors[
                                                              index %
                                                                  barColors.length
                                                          ]
                                                  ),
                                            borderWidth: 0,
                                        },
                                    ],
                                }}
                                options={chartOptions}
                            />
                        </CardBody>
                    )}
                </Box>
            </Box>
            {reportNotGenerated ? (
                <EmptyCardBanner
                    style={{ bottom: '40px', paddingBottom: '10px' }}
                />
            ) : null}
        </Card>
    );
}
