import { API_SERVICE } from 'utils/apiService';
import { CALL_API } from '../api';

export const EMAIL_SIGNUP_INITIATE_REQUEST = 'EMAIL_SIGNUP_INITIATE_REQUEST';
export const EMAIL_SIGNUP_INITIATE_SUCCESS = 'EMAIL_SIGNUP_INITIATE_SUCCESS';
export const EMAIL_SIGNUP_COMPLETE_REQUEST = 'EMAIL_SIGNUP_COMPLETE_REQUEST';
export const EMAIL_SIGNUP_COMPLETE_SUCCESS = 'EMAIL_SIGNUP_COMPLETE_SUCCESS';
export const RESEND_CODE_REQUEST = 'RESEND_CODE_REQUEST';
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS';

export function emailSignupInitiate({ name, email, token, useV3Captcha }) {
    return {
        [CALL_API]: {
            types: [
                EMAIL_SIGNUP_INITIATE_REQUEST,
                EMAIL_SIGNUP_INITIATE_SUCCESS,
            ],
            endpoint: '/user/signup/initiate',
            body: {
                name,
                email,
                token,
                useV3Captcha,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}

export function emailSignupComplete({
    name,
    email,
    secure = false,
    otp,
    password,
    couponCodes,
    metadata,
    signupIntent,
    ...props
}) {
    return {
        [CALL_API]: {
            types: [
                EMAIL_SIGNUP_COMPLETE_REQUEST,
                EMAIL_SIGNUP_COMPLETE_SUCCESS,
            ],
            endpoint: secure
                ? '/user/secure/signup/complete'
                : '/user/signup/complete',
            body: {
                name,
                email,
                otp,
                password,
                couponCodes,
                metadata,
                signupIntent,
                ...props,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}

export function resendCode({
    firstName,
    lastName,
    email,
    token,
    useV3Captcha,
}) {
    return {
        [CALL_API]: {
            types: [RESEND_CODE_REQUEST, RESEND_CODE_SUCCESS],
            endpoint: '/user/resend-verification-code',
            body: {
                firstName,
                lastName,
                email,
                token,
                useV3Captcha,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}
