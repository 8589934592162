import ThirdPartyOfferDeprecated from 'components/ThirdPartyCoupon/ThirdPartyOfferDeprecated';
import useAuthActionHandler from 'hooks/useAuthActionHandler';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { layout } from 'styled-system';
import NotFound from '../../components/general/NotFound';
import { HEADER_TEMPLATES } from '../../components/header/templates';
import useFooter from '../../hooks/useFooter';
import useHeader from '../../hooks/useHeader';
import usePageStyle from '../../hooks/usePageStyle';

/**
 * List of supported third parties. Values correspond to the url endpoints
 * ex. /appsumo will be endpoint for Appsumo
 */
export const SUPPORTED_THIRD_PARTIES = {
    APPSUMO: 'appsumo',
};

function ThirdPartyCoupon() {
    const { thirdParty } = useParams();
    const { isLoggedIn } = useAuthActionHandler();

    usePageStyle({ restricted: false });

    const isSupportedThirdParty = Object.values(
        SUPPORTED_THIRD_PARTIES
    ).includes(thirdParty);

    useHeader(HEADER_TEMPLATES.basic, {
        home_header: false,
        onThirdParty: isSupportedThirdParty,
        thirdParty,
        redirect: false,
        source: 'app sumo header',
    });
    useFooter({
        visible: !isSupportedThirdParty,
        showHomeV2Footer: isSupportedThirdParty,
    });
    return (
        <>
            {thirdParty === SUPPORTED_THIRD_PARTIES.APPSUMO ? (
                <>
                    {/* <PageOverride />
                    <SignUp thirdParty={thirdParty} />
                    <Testimonials />
                    <Wrapper>
                        <Wrapper maxWidth={'1200px'}>
                            <CommunitiesHeader
                                localString={LocalizedString['en'].communities}
                            />
                            <Communities />
                        </Wrapper>
                    </Wrapper> */}
                    <PageOverride />
                    <DeprecatedWrapper>
                        <ThirdPartyOfferDeprecated isLoggedIn={isLoggedIn} />
                    </DeprecatedWrapper>
                </>
            ) : (
                <NotFound />
            )}
        </>
    );
}

export default ThirdPartyCoupon;

const PageOverride = createGlobalStyle`
.page-container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    max-width: 100% !important;
}
    #root {
        background: ${({ theme }) => theme.colors.ambience[22]};
    }
`;
const Wrapper = styled.div`
    ${layout}
    background: ${({ theme }) => theme.colors.ambience[23]};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
`;
const DeprecatedWrapper = styled.div`
    text-align: center;
    max-width: 400px;
    padding-top: 92px;
    margin: 16px 16px 48px 16px;
`;
