import { FREE_ATTENDEE } from 'hooks/metered-pricing/config';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const FREE_PLAN = 'FREE';

const useCommunityPlanInfo = (community_id) => {
    const subscription_type = useSelector((state) => {
        const currentCommunity = state.Community?.myCommunity?.list?.find(
            ({ communityId }) => communityId === community_id
        );
        return currentCommunity?.subscription_type;
    });

    const isPaidPlan = useMemo(
        () =>
            subscription_type &&
            ![FREE_PLAN, FREE_ATTENDEE].includes(subscription_type),
        [subscription_type]
    );

    return {
        isPaidPlan,
    };
};

export default useCommunityPlanInfo;
