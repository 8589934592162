const sessionChatModerationWriter = ({
    airmeetId,
    sessionId,
    client,
    payload,
    logger,
}) => {
    if (!sessionId) {
        throw new Error('Invalid Session chat key');
    }
    logger.info('Sending Session Moderation Chat');
    const fireBaseKey = `${airmeetId}/liveAirmeet/sessions/${sessionId}/moderateWriteNode`;
    return client.pushData(fireBaseKey, payload);
};

export default sessionChatModerationWriter;
