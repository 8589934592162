import cloneDeep from 'lodash/cloneDeep';
import { ENTRYPOINT_RESPONSE_V1 } from 'store/actions/airmeetRules';
import { populateNameFromFirstLastName } from 'utils/users';
import {
    CREATE_AIRMEET_RESPONSE,
    EDIT_AIRMEET_RESPONSE,
    FETCH_AIRMEET_CLIPS_RESPONSE,
    FETCH_AIRMEET_RESPONSE,
    FETCH_CREATED_AIRMEET_RESPONSE,
    FETCH_LEADERBOARD_ACTIONS_RESPONSE,
    FETCH_SPONSORS_RESPONSE,
    GET_LEADERBOARD_INFO_RESPONSE,
    IS_SETUP_REPLAY_BANNER_CLICKED,
    MANAGE_EVENT_ENTRY_PARTICIPANTS_RESPONSE,
    MANAGE_RECORDINGS_RESPONSE,
    PATCH_ATTENDEES_CHECK_IN_RESPONSE,
    RESET_AIRMEET_DATA,
    RESET_CREATED_STATE,
    SET_REGISTRANTS_DATA,
    SHOW_INTERESTS_TAB_BY_DEFAULT,
    UPDATE_AIRMEET_DATA,
} from '../../actions/cmDashboard/airmeet';
import { GET_HUBSPOT_SYNC_RESPONSE } from '../../actions/cmDashboard/integrations';
import {
    SESSION_EMPTY_HOST,
    SESSION_INCOMPLETE_STATUS,
} from '../../actions/cmDashboard/session';
import { SPEAKER_INCOMPLETE_STATUS } from '../../actions/cmDashboard/speaker';

const initialState = {
    airmeetId: null,
    airmeet: {},
    createdAirmeet: {},
    registrants: [],
    recordings: [],
    speakersIncomplete: false,
    sessionsIncomplete: false,
    isSessionHostEmpty: false,
    eventEntryOption: {},
    showInterestsTabByDefault: false,
    sponsors: [],
    clips: [],
    isHubspotV3Enabled: false,
};

function cmDashboard(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_AIRMEET_RESPONSE:
            return {
                ...state,
                airmeetId: payload && payload.uuid,
            };
        case EDIT_AIRMEET_RESPONSE:
            return { ...state };
        case FETCH_AIRMEET_RESPONSE:
            return {
                ...state,
                ...transformFetchAirmeetResponse(state, 'airmeet', action),
            };
        case FETCH_CREATED_AIRMEET_RESPONSE:
            return {
                ...state,
                airmeetId: action.extra,
                ...transformFetchAirmeetResponse(
                    state,
                    'createdAirmeet',
                    action
                ),
            };
        case FETCH_SPONSORS_RESPONSE:
            return {
                ...state,
                airmeetId: action.extra,
                sponsors: (action.payload.sponsor_tiers || []).reduce(
                    (acc, el) => [
                        ...acc,
                        ...[{ ...el, type: 'groupHeader' }].concat(
                            (el.sponsors || []).map((sponsor) => ({
                                ...sponsor,
                            }))
                        ),
                    ],
                    []
                ),
            };
        case MANAGE_EVENT_ENTRY_PARTICIPANTS_RESPONSE:
            return {
                ...state,
                registrants: action.payload,
            };
        case PATCH_ATTENDEES_CHECK_IN_RESPONSE:
            // Update checked_in state in registrants
            const checkedInUsers =
                payload?.checked_in_users ||
                payload?.data?.checked_in_users ||
                [];
            if (Array.isArray(checkedInUsers) || checkedInUsers.length > 0) {
                const registrants = state?.registrants?.registrants;
                if (Array.isArray(registrants) && registrants.length > 0) {
                    const newRegistrants = cloneDeep(registrants || []).map(
                        (registrant) => {
                            if (
                                !registrant?.checked_in &&
                                checkedInUsers.includes(registrant?.id)
                            ) {
                                registrant.checked_in = true;
                                return registrant;
                            }
                            return registrant;
                        }
                    );

                    return {
                        ...state,
                        registrants: {
                            ...(state?.registrants || {}),
                            registrants: newRegistrants,
                        },
                    };
                }
            }
            return state;
        case SET_REGISTRANTS_DATA:
            return {
                ...state,
                registrants: {
                    ...(state?.registrants || {}),
                    registrants: action.payload,
                },
            };
        case MANAGE_RECORDINGS_RESPONSE:
            return {
                ...state,
                recordings: action.payload,
            };

        case RESET_CREATED_STATE:
            const { airmeetId, createdAirmeet } = initialState;
            return { ...state, airmeetId, createdAirmeet };
        case SPEAKER_INCOMPLETE_STATUS:
            return { ...state, speakersIncomplete: action.payload };
        case SESSION_INCOMPLETE_STATUS:
            return { ...state, sessionsIncomplete: action.payload };
        case SESSION_EMPTY_HOST:
            return { ...state, isSessionHostEmpty: action.payload };
        case UPDATE_AIRMEET_DATA:
            return {
                ...state,
                airmeet: { ...state.airmeet, ...action.payload },
            };
        case RESET_AIRMEET_DATA:
            return {
                ...state,
                airmeet: initialState.airmeet,
            };
        case GET_LEADERBOARD_INFO_RESPONSE:
            return {
                ...state,
                leaderboardInfo: action.payload.data,
            };
        case FETCH_LEADERBOARD_ACTIONS_RESPONSE:
            return {
                ...state,
                leaderboardActions: action.payload.data?.actions,
            };
        case IS_SETUP_REPLAY_BANNER_CLICKED:
            return {
                ...state,
                isSetupReplayBannerClicked: action.payload.status,
            };
        case ENTRYPOINT_RESPONSE_V1:
            return {
                ...state,
                eventEntryOption: payload,
            };
        case SHOW_INTERESTS_TAB_BY_DEFAULT:
            return {
                ...state,
                showInterestsTabByDefault: action.payload.status,
            };
        case FETCH_AIRMEET_CLIPS_RESPONSE:
            return {
                ...state,
                clips: action.payload,
            };
        case GET_HUBSPOT_SYNC_RESPONSE:
            return {
                ...state,
                isHubspotV3Enabled:
                    action.payload?.data?.syncingEnabled === 'ACTIVE',
            };
        default:
            return state;
    }
}

const transformFetchAirmeetResponse = (state, key, action) => {
    const { payload, error } = action;
    // API error or no response
    if (error || !payload) {
        // Nothing to do
        return state;
    }

    if (Object.keys(payload).length === 0) {
        return {
            ...state,
            [key]: {
                error: 'not-found',
                errorId: action.extra,
            },
        };
    }

    if (typeof payload.networking_enabled === 'undefined') {
        payload.networking_enabled = true;
    }
    if (typeof payload.session_enabled === 'undefined') {
        payload.session_enabled = true;
    }
    const airmeet = payload;
    if (action.extra) airmeet.airmeetId = action.extra;
    const users = {};
    const usersInt = {};
    if (!airmeet.users) {
        airmeet.users = [];
    }

    let roleIds = [];
    if (airmeet.host) {
        roleIds[airmeet.host] = 'Host';
    }
    if (airmeet.sessions) {
        airmeet.sessions.forEach((session) => {
            if (session.host_id) {
                session.host_id.forEach((host) => {
                    roleIds[host] = 'Host';
                });
            }

            if (session.speaker_id) {
                // Merge speaker, and corresponding user data
                const speakers = (Array.isArray(session.speaker_id)
                    ? session.speaker_id
                    : [session.speaker_id]
                ).map((sid) => ({
                    ...airmeet.users.find((item) => item.id === sid),
                    ...session.speakerList.find((item) => item.id === sid),
                }));

                speakers.forEach((speaker) => {
                    roleIds[speaker.id] = 'Speaker';
                    if (
                        typeof speaker.is_moderator !== 'undefined' &&
                        speaker.is_moderator
                    ) {
                        roleIds[speaker.id] = 'Moderator';
                    }
                });
            }
        });
    }

    airmeet.users.forEach((user) => {
        if (typeof roleIds[user.id] !== 'undefined') {
            user.role = roleIds[user.id];
        } else {
            user.role = 'Attendee';
        }
        users[user.id] = user;
        usersInt[user.id_seq] = user;
    });

    airmeet.assocUsers = users;
    airmeet.assocIntUsers = usersInt;

    if (
        Array.isArray(airmeet?.session_hosts) &&
        airmeet.session_hosts.length > 0 &&
        airmeet.session_hosts.some((h) => !h?.name)
    ) {
        // Derive name from first/last name if name is not present
        airmeet.session_hosts = airmeet.session_hosts.map((user) =>
            populateNameFromFirstLastName(user)
        );
    }

    if (
        Array.isArray(airmeet?.session_cohosts) &&
        airmeet.session_cohosts.length > 0 &&
        airmeet.session_cohosts.some((h) => !h?.name)
    ) {
        // Derive name from first/last name if name is not present
        airmeet.session_cohosts = airmeet.session_cohosts.map((user) =>
            populateNameFromFirstLastName(user)
        );
    }

    return { [key]: airmeet };
};

export default cmDashboard;
