import { useEffect, useState } from 'react';

/**
 * Remembers the first value before onSet, and
 */
function usePropStack({ resetProps, setProps, onSet, onReset }) {
    // Locally store the initial props, used during cleanup
    const [oldProps, setOldProps] = useState(null);

    // Callback to set new props
    const setNewProps = (props) => {
        onSet(props);
    };

    // Callback to reset old props
    const resetOldProps = () => {
        onReset(oldProps);
    };

    // Run any time the incoming props change
    useEffect(
        () => {
            if (!oldProps) {
                setOldProps({ ...resetProps });
            }

            setNewProps(setProps);

            // Cleanup
            return resetOldProps;
        },
        !!setProps ? Object.values(setProps) : []
    );

    // No return
    return null;
}

export default usePropStack;
