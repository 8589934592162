import React from 'react';
import Box from 'atoms/Box';
import { space } from 'styled-system';
import styled from 'styled-components';
import { Typography } from 'foundations/typography';

const ErrorRemountFallback = ({ errorBoundaryProps, reloadFn }) => {
    return (
        <Container
            className='something-wrong'
            height={errorBoundaryProps?.height || '100%'}
        >
            <Typography variant='subtitle1'>
                {errorBoundaryProps?.errorMessage || 'Something went wrong'}
            </Typography>
            <Button
                mt={3}
                className='btn btn-primary'
                onClick={() => reloadFn()}
            >
                Reload
            </Button>
        </Container>
    );
};

const Container = styled(Box)`
    width: 30%;
    margin: auto;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Button = styled.button`
    ${space}
`;

export default ErrorRemountFallback;
