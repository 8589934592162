import { Typography } from 'foundations/typography';
import React from 'react';
import styled from 'styled-components';
import { accessibilityStyles } from 'components/molecules/utils/basicComponents';

const CloseButton = styled((props) => (
    <button {...props}>
        <Typography
            variant='button2'
            color={props?.iconstyle?.color || 'ambience.0'}
            style={props.iconstyle ? props.iconstyle : {}}
        >
            &times;
        </Typography>
    </button>
))`
    cursor: pointer;
    border: none;
    padding: none;
    background: ${({ theme }) =>
        theme.isLightTheme
            ? theme.colors.ambience[22]
            : theme.colors.semantic[1]};

    opacity: 1;
    border-radius: 50%;

    min-width: 1.5rem;
    min-height: 1.5rem;
    max-width: 1.5rem;
    max-height: 1.5rem;

    float: right;

    text-shadow: none;
    &:focus {
        outline: 0;
    }
    ${accessibilityStyles()}
    &:hover {
        filter: ${({ theme }) =>
            theme.isLightTheme ? 'none' : 'brightness(1.2)'};
    }
    &.disable,
    &:disabled {
        pointer-events: none;
        background: ${({ theme }) => theme.colors.ambience[21]};
        opacity: 0.08;
    }

    z-index: 99;
`;

export default CloseButton;
