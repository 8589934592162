const sendTraceReport = ({ perf, source, key, metrics }) => {
    if (!perf) {
        return;
    }

    const { tc, tq, tb, tr } = metrics;

    // tr-tc → End to end latency
    perf.traceRecord(`${source}_END_TO_END_LATENCY`, tr - tc);

    // tq-tc → Latency due to post API
    perf.traceRecord(`${source}_POST_API_LATENCY`, tq - tc);

    // tb-tq → time spent in buffer
    perf.traceRecord(`${source}_BUFFER_TIME_LATENCY`, tb - tq);

    // tb-tc  → latency due to our service
    perf.traceRecord(`${source}_SERVICE_LATENCY`, tb - tc);

    // tr-tb → latency due to realtime provider
    perf.traceRecord(`${source}_REALTIME_PROVIDER_LATENCY`, tr - tb);
};

export default sendTraceReport;
