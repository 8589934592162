import createReducer from 'utils/createReducer';
import {
    SET_ARE_FILTERS_APPLIED,
    SET_FILTER_BODY,
    SET_SEARCH_KEYS,
    SET_SEARCH_STRING,
} from '../actions/PeopleSearch';
interface PeopleSearchState {
    searchKeys: Array<string>;
    searchValue: string;
    filterBody: object;
    areFiltersApplied: boolean;
}

const initialState: PeopleSearchState = {
    searchKeys: [],
    searchValue: '',
    filterBody: {},
    areFiltersApplied: false,
};

const handlers = {
    [SET_SEARCH_STRING]: (
        state: PeopleSearchState = initialState,
        { payload }
    ) => ({
        ...state,
        searchValue: payload,
    }),
    [SET_ARE_FILTERS_APPLIED]: (
        state: PeopleSearchState = initialState,
        { payload }
    ) => ({
        ...state,
        areFiltersApplied: payload,
    }),
    [SET_FILTER_BODY]: (
        state: PeopleSearchState = initialState,
        { payload }
    ) => ({
        ...state,
        filterBody: payload,
    }),
    [SET_SEARCH_KEYS]: (
        state: PeopleSearchState = initialState,
        { payload }
    ) => ({
        ...state,
        searchKeys: payload,
    }),
};

export default createReducer(initialState, handlers);
