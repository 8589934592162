import Button from 'atoms/Button/button';
import Modal from 'atoms/Modal/modal';
import EmailCreatable from 'atoms/Select/EmailCreatable';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { alpha } from 'foundations/theme';
import { Typography } from 'foundations/typography';
import React, { useState } from 'react';
import styled from 'styled-components';
import { emptyArray } from 'utils/constants/common';
import { StyledSvgSprite } from '../general/SVGSprite';

const MAX_RECIPIENTS_LIMIT = 5;
const EMAIL_SEPARATOR_INFO_TEXT =
    'Use commas to separate multiple email addresses.';

type TestMailModalPropType = {
    onClose: () => void;
    modalStyles?: object;
    maxLimit?: string;
    onSend?: (data: string[]) => void;
    recipients?: string[];
};

const TestMailModal: React.FC<TestMailModalPropType> = ({
    onClose,
    modalStyles,
    maxLimit = MAX_RECIPIENTS_LIMIT,
    onSend,
    recipients: initialRecipients = emptyArray,
    logFirebaseEvent,
    emailLogData = {},
}) => {
    const [recipients, setRecipients] = useState(initialRecipients);
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(null);
    const [sending, setSending] = useState(false);

    const resetError = () => {
        if (error) setError(null);
    };

    const handleError = (e) => {
        if (e?.message) setError(e?.message);
    };

    const handleSend = async () => {
        logFirebaseEvent('test_email_sent', emailLogData);
        setSending(true);
        await onSend(recipients.map((recipient) => recipient.value));
        setSending(false);
    };

    const infoText =
        inputValue || recipients.length ? EMAIL_SEPARATOR_INFO_TEXT : null;

    return (
        <Modal
            style={{
                maxWidth: '448px',
                top: '35%',
                transform: 'translateY(-35%)',
                height: 'auto',
                ...modalStyles,
            }}
            wrapperStyle={{
                backdropFilter: 'blur(4px)',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
            }}
            showCloseIcon
            onClose={onClose}
            buttonclose={{ background: 'transparent', fontSize: '24px' }}
        >
            <FlexDiv flexDirection='column' m={4}>
                <Typography
                    variant={'subtitle1'}
                    color='ambience.0'
                    textAlign='center'
                    mb={3}
                >
                    Send a test email to
                </Typography>
                <EmailCreatable
                    placeholder={'Type something here..'}
                    defaultValue={initialRecipients}
                    value={recipients}
                    inputValue={inputValue}
                    setValue={setRecipients}
                    setInputValue={setInputValue}
                    resetError={resetError}
                    handleError={handleError}
                    maxLimit={maxLimit}
                    className={'custom-email-input'}
                    classNamePrefix={'custom'}
                />
                <FlexDiv mt={1}>
                    {error || infoText ? (
                        <Typography
                            variant='caption-regular'
                            color={error ? 'semantic.1' : 'accentSea.1'}
                        >
                            {error || infoText}
                        </Typography>
                    ) : null}
                    <Typography
                        variant='caption-regular'
                        color='ambience.6'
                        ml='auto'
                    >
                        {recipients.length}/{maxLimit} recipients
                    </Typography>
                </FlexDiv>
                <InfoContainer mt={3}>
                    <StyledSvgSprite
                        icon='info'
                        width='20px'
                        height='20px'
                        fill='semantic.0'
                    />
                    <Typography variant='subtext2' color='ambience.1' ml={1}>
                        {
                            'Some merge tags like {{full name}} will show dummy data and not the real time data.'
                        }
                    </Typography>
                </InfoContainer>
                <ButtonContainer mt={3}>
                    <Button
                        variant='secondary'
                        width='auto'
                        p='0 20px'
                        height='48px'
                        mr={3}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='primary'
                        width='auto'
                        p='0 20px'
                        height='48px'
                        onClick={handleSend}
                        disabled={!recipients.length}
                        loading={sending}
                    >
                        Send Test
                    </Button>
                </ButtonContainer>
            </FlexDiv>
        </Modal>
    );
};

export default TestMailModal;

const ButtonContainer = styled(FlexDiv)`
    justify-content: center;
`;

const InfoContainer = styled(FlexDiv)`
    background-color: ${({ theme }) => alpha(theme.colors.ambience[15], 0.2)};
    padding: 12px;
    border-radius: 8px;
`;
