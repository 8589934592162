import React, { useContext, useMemo } from 'react';
import { EVENT_TYPES } from 'utils/constants/airmeet';
import { AnalyticsContext } from '../AnalyticsProvider';
import SelectiveBarChartCard from '../components/SelectiveBarChartCard';

const TOP_DATA = [
    {
        key: 'event.lounge_attendees',
        title: 'Lounge Active Joiners',
        info: 'This is the number of unique users who performed an activity.',
    },
    {
        key: 'networking.total_who_used_sl',
        title: 'Joined Tables',
        backgroundRGB: '62,165,252',
    },
    {
        key: 'event.meeting_attendees',
        title: 'Joined Meetings',
        backgroundRGB: '2,94,173',
    },
    {
        key: 'networking.total_who_used_sn',
        title: 'Joined Speed Networking',
        backgroundRGB: '66,70,148',
    },
];

const dataInfo = TOP_DATA.filter(
    (item) => item.key !== 'event.lounge_attendees'
);

export default function LoungeActiveAttendanceCard() {
    const { getRequest, timezone, event_type } = useContext(AnalyticsContext);

    const topDataInfo = useMemo(
        () =>
            TOP_DATA.filter(
                ({ key }) =>
                    key !== 'event.meeting_attendees' ||
                    event_type === EVENT_TYPES.CONFERENCE
            ),
        [event_type]
    );

    return (
        <SelectiveBarChartCard
            title='Lounge Active Joiners'
            getRequest={getRequest}
            timezone={timezone}
            topDataInfo={topDataInfo}
            dataInfo={dataInfo}
            endpoint='attendance-analytics'
            perDayDataPath='networking.sl_attendance'
        />
    );
}
