import Button from 'atoms/Button/button';
import { Typography } from 'foundations/typography';
import useCustomBaseUrl from 'hooks/useCustomBaseUrl';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { flexbox, space } from 'styled-system';
import { getEnvUrl } from 'utils/constants/airmeet';
import { redirectToHomePage } from 'utils/redirections';
import { cdnImage } from '../../utils/core';

const btnStyle = {
    borderRadius: '8px',
    marginTop: '30px',
};
function NotFound() {
    const { baseUrl } = useCustomBaseUrl();

    return (
        <>
            <PageContainerOverride />
            <Container justifyContent='center'>
                <Box
                    p={2}
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <SVGWrapper>
                        <img
                            src={cdnImage('/images/404-not-found.png')}
                            alt='Not found'
                        />
                    </SVGWrapper>
                    <Typography variant='h5' color='ambience.2' mt={4}>
                        The page you’re looking for does not exist
                    </Typography>
                    <Typography variant='subtext1' color='ambience.13' mt={1}>
                        We are very sorry for inconvenience. It looks like
                        you're trying to access a page that has either been
                        deleted or never existed.
                    </Typography>
                    <BtnContainer>
                        <Button
                            variant='primary'
                            style={btnStyle}
                            onClick={() =>
                                redirectToHomePage(getEnvUrl(baseUrl))
                            }
                        >
                            <Typography variant='span' color='ambience.0'>
                                Go to Home
                            </Typography>
                        </Button>
                    </BtnContainer>
                </Box>
            </Container>
        </>
    );
}
export default NotFound;
const Container = styled.div`
    display: flex;
    ${flexbox}
    min-height: calc(100vh - 210px);
    @media (max-width: 768px) and (orientation: portrait) {
        min-height: calc(100vh - 350px);
    }
`;
const Box = styled.div`
    ${space}
    ${flexbox}
    display: flex;
    max-width: 600px;
    width: 100%;
    text-align: center;
    @media (max-width: 768px) {
        h5 {
            margin-top: 8px;
            font-size: 24px;
            line-height: 30px;
        }
    }
`;
const BtnContainer = styled.div`
    width: 200px;
`;
const SVGWrapper = styled.div`
    img {
        max-width: 100%;
        width: 30vw;
        @media (max-width: 768px) {
            max-width: 80%;
            width: 50vw;
        }
    }
`;
const PageContainerOverride = createGlobalStyle`
    .page-container {
        max-width: 100% !important;
    }
    .page--fill{
        min-height: 0;
    }
`;
