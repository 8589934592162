import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setMaxTilesPerPage } from 'store/actions/groupMeeeting';
import { DEFAULT_MAX_TILES_PER_PAGE } from 'utils/DailyUIWrapper/constants';
import { gmLogger } from 'utils/loggers/groupMeeting';
import { ALL_FEATURES_CONFIG } from '../useFeatureControl';

const useGroupMeeting = ({ featuresConfig, platformConfig }): void => {
    const dispatch = useDispatch();

    const maxTilesPerPage: number = useMemo(() => {
        const maxTilesPerPageEvent = featuresConfig
            ? featuresConfig[
                  ALL_FEATURES_CONFIG.GROUP_MEETING_MAX_TILES_PER_PAGE
              ]
            : -1;

        const maxTilesPerPagePlatform = platformConfig
            ? platformConfig[
                  ALL_FEATURES_CONFIG.GROUP_MEETING_MAX_TILES_PER_PAGE
              ]
            : -1;

        if (maxTilesPerPageEvent > 0) {
            gmLogger.info(
                `Max tiles per page at event level is set to ${maxTilesPerPageEvent}`
            );
            return maxTilesPerPageEvent;
        } else if (maxTilesPerPagePlatform > 0) {
            gmLogger.info(
                `Max tiles per page at platform level is set to ${maxTilesPerPageEvent}`
            );
            return maxTilesPerPagePlatform;
        }
        return DEFAULT_MAX_TILES_PER_PAGE;
    }, [featuresConfig, platformConfig]);

    useEffect(() => {
        dispatch(setMaxTilesPerPage(maxTilesPerPage));
    }, [dispatch, maxTilesPerPage]);
};

export default useGroupMeeting;
