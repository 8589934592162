import { Listeners } from '../types';
import FirebaseDataSource from './FirebaseDataSource';
import AblyDataSource from './AblyDataSource';
import AblyService from './AblyService';
import FirebaseClient from 'containers/FirebaseClient';
import { logger } from 'utils/logger';

export class AblyWrappedFirebaseDataSource extends FirebaseDataSource {
    private ablySource: AblyDataSource;
    constructor(firebaseClient: FirebaseClient, perf: any) {
        super(firebaseClient, perf);
        this.ablySource = new AblyDataSource(
            AblyService.getInstance().getClient(),
            perf
        );
    }

    subscribe(channel: string, listeners: Listeners, options?: any): Function {
        const superResult = super.subscribe(channel, listeners, options);
        const addLogger = (...args) => {
            logger.debug('Got ably message on channel', channel, ...args);
        };
        const ablyResult = this.ablySource.subscribe(
            channel,
            { onAdd: addLogger },
            options
        );
        return () => {
            superResult && superResult();
            ablyResult && ablyResult();
        };
    }
}
