import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { stringify } from 'querystring';
import { EVENT_TYPES } from 'utils/constants/airmeet';
import { logger } from 'utils/logger';
import { getStageUserIds } from 'utils/users';
import { getDefaultBackground } from '../../components/lounge/rhsV2/settingsTab/CustomizePanel/CustomizeBranding/CustomizeBackground/utils';
import { API_SERVICE } from '../../utils/apiService';
import { userTransform } from '../../utils/constants/users';
import { getCroppedAttendeeImageURL, getRandomBetween } from '../../utils/core';
import { CALL_API } from '../api';
export const FETCH_AIRMEET_INFO = 'FETCH_AIRMEET_INFO';
export const FETCH_FORBIDDEN_ACTIONS = 'FETCH_FORBIDDEN_ACTIONS';
export const FETCH_AIRMEET_COMBINED_INFO = 'FETCH_AIRMEET_COMBINED_INFO';
export const FETCH_AIRMEET_STATUS = 'FETCH_AIRMEET_STATUS';
export const FETCH_AIRMEET_USERS = 'FETCH_AIRMEET_USERS';
export const FETCH_AIRMEET_USERS_PAGINATION = 'FETCH_AIRMEET_USERS_PAGINATION';
export const FETCH_AIRMEET_SESSIONS = 'FETCH_AIRMEET_SESSIONS';
export const FETCH_AIRMEET_SPONSORS = 'FETCH_AIRMEET_SPONSORS';
export const VERIFY_EXHIBITOR_TOKEN = 'VERIFY_EXHIBITOR_TOKEN';
export const SET_USERS_INFO = 'SET_USERS_INFO';

export const FETCH_AIRMEET_RESPONSE = 'FETCH_AIRMEET_RESPONSE';
export const FETCH_AIRMEET_COMBINED_RESPONSE =
    'FETCH_AIRMEET_COMBINED_RESPONSE';
export const FETCH_AIRMEET_USER_REQUEST = 'FETCH_AIRMEET_USER_REQUEST';
export const FETCH_AIRMEET_USER_RESPONSE = 'FETCH_AIRMEET_USER_RESPONSE';
export const CREAT_AIRMEET_REQUEST = 'CREAT_AIRMEET_REQUEST';
export const CREAT_AIRMEET_RESPONSE = 'CREAT_AIRMEET_RESPONSE';
export const ADD_CHAT = 'ADD_CHAT';
export const SET_CHATS = 'SET_CHATS';
export const CLEAR_CHAT = 'CLEAR_CHAT';
export const UPDATE_RSVP_REQUEST = 'UPDATE_RSVP_REQUEST';
export const UPDATE_RSVP_RESPONSE = 'UPDATE_RSVP_RESPONSE';
export const CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST';
export const CREATE_SESSION_RESPONSE = 'CREATE_SESSION_RESPONSE';
export const SET_IS_BACKSTAGE = 'SET_IS_BACKSTAGE';
export const SET_CURRENT_SESSION_ID = 'SET_CURRENT_SESSION_ID';
export const SET_SUBSCRIBE_TRACK = 'SET_SUBSCRIBE_TRACK';
export const SET_REQUEST_PRODUCT_TOUR = 'SET_REQUEST_PRODUCT_TOUR';
export const ADD_SESSION_SPEAKER_REQUEST = 'ADD_SESSION_SPEAKER_REQUEST';
export const ADD_SESSION_SPEAKER_RESPONSE = 'ADD_SESSION_SPEAKER_RESPONSE';
export const CREATE_SESSION_EDIT_REQUEST = 'CREATE_SESSION_EDIT_REQUEST';
export const CREATE_SESSION_EDIT_RESPONSE = 'CREATE_SESSION_EDIT_RESPONSE';
export const PUT_AIRMEET_GUEST = 'PUT_AIRMEET_GUEST';
export const UPDATE_AIRMEET_GUEST = 'UPDATE_AIRMEET_GUEST';
export const ADD_REPORT_MESSAGE = 'ADD_REPORT_MESSAGE';
export const CLEAR_REPORT_MESSAGES = 'CLEAR_REPORT_MESSAGES';
export const SET_BLOCKED_USERS = 'SET_BLOCKED_USERS';
export const CLEAR_BLOCKED_USERS = 'CLEAR_BLOCKED_USERS';
export const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER';
export const SET_QUESTION_TAB = 'SET_QUESTION_TAB';
export const UPDATE_SESSION_HOST_REQUEST = 'UPDATE_SESSION_HOST_REQUEST';
export const UPDATE_SESSION_HOST_RESPONSE = 'UPDATE_SESSION_HOST_RESPONSE';
export const UPDATE_SESSION_STATUS = 'UPDATE_SESSION_STATUS';
export const ADD_SESSION_COHOSTS = 'ADD_SESSION_COHOSTS';
export const VERIFY_DOMAIN = 'VERIFY_DOMAIN';
export const VERIFY_EMAIL_INVITE_V3 = 'VERIFY_EMAIL_INVITE_V3';
export const RESEND_INIVITE_EMAIL = 'RESEND_INIVITE_EMAIL';
export const SAVE_ATTENDEE_INFO = 'SAVE_ATTENDEE_INFO';
export const SET_AIRMEET_STATE = 'SET_AIRMEET_STATE';
export const SET_IS_WATCH_REPLAY_CLICKED = 'SET_IS_WATCH_REPLAY_CLICKED';
export const SET_TABLES_LIST = 'SET_TABLES_LIST';
export const SET_ALL_TABLE = 'SET_ALL_TABLE';
export const SET_LOUNGE_TABLES_CONFIG = 'SET_LOUNGE_TABLES_CONFIG';
export const SET_CAN_ATTENDEE_CREATE_TABLE = 'SET_CAN_ATTENDEE_CREATE_TABLE';
export const SET_USER_REGISTRATION_COMPLETE = 'SET_USER_REGISTRATION_COMPLETE';
export const SET_NETWORKING_SESSION_MEETUP_DETAILS =
    'SET_NETWORKING_SESSION_MEETUP_DETAILS';
export const INTRO_VIDEO_REQUEST = 'INTRO_VIDEO_REQUEST';
export const INTRO_VIDEO_RESPONSE = 'INTRO_VIDEO_RESPONSE';
export const RESET_LOUNGE_AIRMEET_DATA = 'RESET_LOUNGE_AIRMEET_DATA';

const TRACK_COLOR = {
    0: 'accentSky.1',
    1: 'accentRose.1',
    2: 'accentSun.1',
    3: 'accentSpring.1',
    4: 'accentBrick.1',
    5: 'accentSea.1',
};

const mapInTracks = (airmeet) => {
    if (!airmeet.tracks) {
        airmeet.tracks = [];
    }

    const session_tracks = {};
    const track_sessions = {};
    airmeet.tracks = airmeet.tracks.map((track, index) => {
        track_sessions[track.uid] = track.sessions;
        track.sessions.forEach((sessionId) => {
            if (!session_tracks[sessionId]) {
                session_tracks[sessionId] = [];
            }
            session_tracks[sessionId].push(track.uid);
        });
        track.colorCode = TRACK_COLOR[index];
        return track;
    });

    airmeet.session_tracks = session_tracks;
    airmeet.track_sessions = track_sessions;
    return airmeet;
};
const infoTransform = (airmeet) => {
    if (!airmeet) {
        return airmeet;
    }

    airmeet = mapInTracks(airmeet);

    airmeet.safeSessions = airmeet.sessions
        ? airmeet.sessions.map((session) => ({
              ...session,
              speakerList: session.speakerList.map((speaker) => ({
                  ...speaker,
                  speaker_img: getCroppedAttendeeImageURL(speaker.speaker_img),
                  speaker_img_original: speaker.speaker_img,
              })),
          }))
        : [];
    const isParallelTrackEvent = airmeet.event_type === EVENT_TYPES.CONFERENCE;
    const parallelTrackData = { isParallelTrackEvent };
    airmeet.parallelTrackData = parallelTrackData;
    if (!airmeet?.live_config) {
        airmeet.live_config = {};
    }

    // Update the default background state
    if (isEmpty(airmeet?.live_config?.eventBranding?.background)) {
        set(
            airmeet,
            'live_config.eventBranding.background',
            getDefaultBackground()
        );
    }

    return airmeet;
};

const customRegistrationFormTransform = (
    customRegistrationForm,
    airmeetInfo
) => {
    if (!customRegistrationForm) {
        return customRegistrationForm;
    }

    const returnObj = cloneDeep(customRegistrationForm || {});

    // Remove in person option if hybrid_events_in_person_attendance_enabled is false
    if (!airmeetInfo?.hybrid_events_in_person_attendance_enabled) {
        const formFieldsConstant = [];
        (customRegistrationForm?.formFieldsConstant || {}).forEach((field) => {
            if (field?.name === 'attendanceType') {
                const newField = { ...field };
                newField.options = (newField?.options || []).filter(
                    (option) =>
                        (option?.displayValue || '').toLowerCase() !==
                        'in-person'
                );
                formFieldsConstant.push(newField);
            } else {
                formFieldsConstant.push(field);
            }
        });
        returnObj.formFieldsConstant = formFieldsConstant;
    }

    return returnObj;
};

export function fetchAirmeet(airmeetId, includes = '', options = {}) {
    const { includeUserIds } = options;
    const extra = includes.split(',');
    const baseRequestPath = `/airmeet/${airmeetId}`;
    const infoRetryConfig = {
        retries: 5,
        retryDelay: function (attempt, error, response) {
            const pow = attempt <= 3 ? attempt : 3;
            const backOffTime = Math.pow(2, pow) * 1000; // 2000, 4000, 8000, 8000, 8000 ...
            const jitter = getRandomBetween(100, 500); // jitter so that all users do not try at the same time
            const delay = backOffTime + jitter;
            logger.info(`Scheduling Retry for fetch airmeet after - ${delay}`, {
                time: Date.now(),
                error,
                response,
            });
            return delay;
        },
    };
    const actions = [
        options.info
            ? {
                  types: [FETCH_AIRMEET_INFO],
                  endpoint: `${baseRequestPath}/info`,
                  method: 'GET',
                  transform: infoTransform,
                  retryConfigOverrides: infoRetryConfig,
              }
            : {
                  types: [FETCH_AIRMEET_COMBINED_INFO],
                  endpoint: `${baseRequestPath}/info/combined`,
                  method: 'GET',
                  query: {
                      include: 'table_count, venue',
                  },
                  transform: (json) => {
                      if (!json) return json;
                      return {
                          ticketsInfo: json.ticketsInfo,
                          customFormInfo: {
                              ...(customRegistrationFormTransform(
                                  json.customRegistrationForm,
                                  json?.airmeetInfo
                              ) || {}),
                          },
                          venueInfo: json.venueInfo,
                          ...infoTransform(json?.airmeetInfo),
                      };
                  },
                  retryConfigOverrides: infoRetryConfig,
                  service: API_SERVICE.BFF,
              },
    ];
    const postActions = [];

    // Possibly include users
    if (
        extra.includes('users') ||
        extra.includes('stageUsers') ||
        includeUserIds
    ) {
        postActions.push({
            types: [FETCH_AIRMEET_USERS],
            endpoint: `/airmeet/${airmeetId}/users`,
            query: (state, airmeetData) => {
                if (extra.includes('users')) {
                    return {};
                }

                let userFilter = includeUserIds || [];
                if (extra.includes('stageUsers') && airmeetData) {
                    userFilter = [
                        ...userFilter,
                        ...getStageUserIds(airmeetData),
                    ];
                }
                userFilter = uniq(userFilter);
                return { filter: userFilter.join(',') };
            },
            transform: (json) => ({
                users: json.map(userTransform),
            }),
            method: 'GET',
        });
    }

    // FETCH_AIRMEET_RESPONSE
    return {
        [CALL_API]: {
            actions,
            postActions,
            finalType: FETCH_AIRMEET_RESPONSE,
            extra: { airmeetId, options },
        },
    };
}

export function fetchAirmeetInfo(airmeetId) {
    return {
        [CALL_API]: {
            types: [FETCH_AIRMEET_INFO, FETCH_AIRMEET_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/info`,
            method: 'GET',
            extra: { airmeetId },
            transform: infoTransform,
        },
    };
}
export function fetchAirmeetCombinedInfoDashBoard(airmeetId, { include } = {}) {
    let req = {
        types: [FETCH_AIRMEET_COMBINED_INFO, FETCH_AIRMEET_COMBINED_RESPONSE],
        endpoint: `/airmeet/${airmeetId}/dashboard/info/combined`,
        method: 'GET',
        extra: { airmeetId },
        transform: (json) => {
            if (!json) return json;
            return {
                rawData: json,
                ticketsInfo: json.ticketsInfo,
                customFormInfo: {
                    ...(customRegistrationFormTransform(
                        json.customRegistrationForm,
                        json?.airmeetInfo
                    ) || {}),
                },
                ...infoTransform(json?.airmeetInfo),
            };
        },
        service: API_SERVICE.BFF,
    };
    if (include) {
        req.query = {
            include: include.join(','),
        };
    }
    return {
        [CALL_API]: req,
    };
}
export function fetchAirmeetCombinedInfo(
    airmeetId,
    { include, overrideRegionUrl } = {}
) {
    let req = {
        types: [FETCH_AIRMEET_COMBINED_INFO, FETCH_AIRMEET_COMBINED_RESPONSE],
        endpoint: `/airmeet/${airmeetId}/info/combined`,
        method: 'GET',
        extra: { airmeetId },
        transform: (json) => {
            if (!json) return json;
            return {
                rawData: json,
                ticketsInfo: json.ticketsInfo,
                customFormInfo: {
                    ...(customRegistrationFormTransform(
                        json.customRegistrationForm,
                        json?.airmeetInfo
                    ) || {}),
                },
                ...infoTransform(json?.airmeetInfo),
            };
        },
        service: API_SERVICE.BFF,
    };
    if (overrideRegionUrl) {
        req.service = API_SERVICE.CAS_BFF;
    }
    if (include) {
        req.query = {
            include: include.join(','),
        };
    }
    return {
        [CALL_API]: req,
    };
}
export function fetchAirmeetUsers(airmeetId, filter) {
    if (filter && !filter.length) {
        console.trace('Filter passed without any ids');
    }
    return {
        [CALL_API]: {
            types: [FETCH_AIRMEET_USERS],
            endpoint: `/airmeet/${airmeetId}/users`,
            query: { filter: filter.join(',') },
            transform: (users) => users.map(userTransform),
            method: 'GET',
        },
    };
}

export function fetchRegisteredUsers(airmeetId, query) {
    return {
        [CALL_API]: {
            types: [FETCH_AIRMEET_USERS_PAGINATION],
            endpoint: `/${airmeetId}/users`,
            body: query,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}

export function fetchAirmeetSponsors(airmeetId) {
    return {
        [CALL_API]: {
            types: [FETCH_AIRMEET_SPONSORS],
            endpoint: `/airmeet/${airmeetId}/sponsor`,
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function fetchAirmeetStatus(airmeetId) {
    return {
        [CALL_API]: {
            types: [FETCH_AIRMEET_STATUS],
            endpoint: `/airmeet/${airmeetId}/status`,
            method: 'GET',
        },
    };
}

export function verifyExhibitorToken(airmeetId, token) {
    return {
        [CALL_API]: {
            types: [VERIFY_EXHIBITOR_TOKEN],
            endpoint: `/airmeet/${airmeetId}/booth/verifyExhibitor`,
            method: 'POST',
            body: {
                token,
            },
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}

export function fetchAirmeetUser(airmeetId, userId) {
    const queryParams = stringify({ airmeetId, userId });
    return {
        [CALL_API]: {
            types: [FETCH_AIRMEET_USER_REQUEST, FETCH_AIRMEET_USER_RESPONSE],
            endpoint: `/airmeet/user?${queryParams}`,
            transform: userTransform,
            method: 'GET',
        },
    };
}

export function createAirmeet({
    name,
    host,
    tags,
    startTime,
    session_enabled,
    noauth_enabled,
    endTime,
    numOfUsers,
    masterImageUrl,
    shortDesc,
    longDesc,
    organiserName,
    organiserEmail,
    organiserUrl,
    is_private,
    community_id,
}) {
    return {
        [CALL_API]: {
            types: [CREAT_AIRMEET_REQUEST, CREAT_AIRMEET_RESPONSE],
            endpoint: `/airmeet/`,
            body: {
                name,
                host,
                tags,
                start_time: moment(startTime).toISOString(),
                end_time: moment(endTime).toISOString(),
                num_of_users: parseInt(numOfUsers, 10),
                master_image_url: masterImageUrl,
                short_desc: shortDesc,
                long_desc: longDesc,
                organiser_name: organiserName,
                organiser_email: organiserEmail,
                organiser_url: organiserUrl,
                is_private,
                session_enabled,
                noauth_enabled,
                community_id,
            },
            type: 'json',
            method: 'POST',
        },
    };
}

export function createSession({
    sessionName,
    airmeetId,
    start_time,
    duration,
    masterImageUrl,
    introImageUrl,
}) {
    return {
        [CALL_API]: {
            types: [CREATE_SESSION_REQUEST, CREATE_SESSION_RESPONSE],
            endpoint: `/airmeet/session`,
            body: {
                sessionName,
                airmeetId,
                start_time,
                duration: parseInt(duration, 10),
                master_image_url: masterImageUrl,
                intro_image_url: introImageUrl,
            },
            type: 'json',
            method: 'POST',
        },
    };
}

export function editSession({
    sessionName,
    sessionId,
    start_time,
    duration,
    masterImageUrl,
    introImageUrl,
}) {
    return {
        [CALL_API]: {
            types: [CREATE_SESSION_EDIT_REQUEST, CREATE_SESSION_EDIT_RESPONSE],
            endpoint: `/airmeet/session`,
            body: {
                sessionId,
                sessionName,
                start_time,
                duration: parseInt(duration, 10).toString(),
            },
            type: 'json',
            method: 'PUT',
        },
    };
}

export function addSessionSpeaker({
    sessionId,
    spemail,
    spname,
    sporganisation,
    spdesignation,
    spWebURL,
    speakerImgPath,
    speakerIntro,
    spcity,
    spcountry,
}) {
    return {
        [CALL_API]: {
            types: [ADD_SESSION_SPEAKER_REQUEST, ADD_SESSION_SPEAKER_RESPONSE],
            endpoint: `/airmeet/session/speaker`,
            body: {
                sessionId,
                speakerEmail: spemail,
                speakerName: spname,
                speakerOrg: sporganisation,
                speakerDesignation: spdesignation,
                speakerWebURL: spWebURL,
                speakerImgPath,
                speakerIntro,
                speakerCity: spcity,
                speakerCountry: spcountry,
                isModerator: false,
            },
            type: 'json',
            method: 'POST',
        },
    };
}

export function addChat({ content, chatKey }) {
    return {
        type: ADD_CHAT,
        payload: {
            content,
            chatKey,
        },
    };
}

export function setChats({ room, chats }) {
    return {
        type: SET_CHATS,
        payload: {
            room,
            chats,
        },
    };
}

export function clearChat(channel) {
    return {
        type: CLEAR_CHAT,
        payload: {
            channel,
        },
    };
}

export function setIsBackStage(status) {
    return {
        type: SET_IS_BACKSTAGE,
        payload: {
            status,
        },
    };
}

export function setRequestProductTour(tourKey) {
    return {
        type: SET_REQUEST_PRODUCT_TOUR,
        payload: tourKey,
    };
}

export function updateRSVP({ tags, airmeetId, userId }) {
    return {
        [CALL_API]: {
            types: [UPDATE_RSVP_REQUEST, UPDATE_RSVP_RESPONSE],
            endpoint: `/airmeet/user`,
            body: {
                tags,
                airmeetId,
                userId,
            },
            type: 'json',
            method: 'PUT',
        },
    };
}

export function updateAirmeetGuest({
    airmeetId,
    guestId,
    name,
    profileImage,
    tags,
    ...params
}) {
    return {
        [CALL_API]: {
            types: [PUT_AIRMEET_GUEST, UPDATE_AIRMEET_GUEST],
            endpoint: `/airmeet/guest`,
            body: {
                airmeetId,
                userId: guestId,
                name,
                profile_img: profileImage,
                tags,
                params: params || {},
            },
            type: 'json',
            method: 'PUT',
        },
    };
}

export function addReportMessage(payload) {
    return {
        type: ADD_REPORT_MESSAGE,
        payload,
    };
}
export function clearReportMessages() {
    return {
        type: CLEAR_REPORT_MESSAGES,
    };
}
export function setUsersInfo(users) {
    return {
        type: SET_USERS_INFO,
        payload: users,
    };
}

export function setBlockedUsers(payload) {
    return {
        type: SET_BLOCKED_USERS,
        payload,
    };
}
export function clearBlockedUsers() {
    return {
        type: CLEAR_BLOCKED_USERS,
    };
}
export function setSelectedOrder(selectedOrder, room, sessionid) {
    return {
        type: SET_SELECTED_ORDER,
        payload: {
            selectedOrder,
            room,
            sessionid,
        },
    };
}

export function setQuestionTab(item) {
    return {
        type: SET_QUESTION_TAB,
        payload: {
            item,
        },
    };
}
export function resetLoungeAirmeetData() {
    return {
        type: RESET_LOUNGE_AIRMEET_DATA,
    };
}
export function updateAirmeetData(airmeetId, invitedSpeakerList, payload) {
    // FETCH_AIRMEET_RESPONSE
    return {
        type: FETCH_AIRMEET_RESPONSE,
        payload: payload,
        extra: { airmeetId, invitedSpeakerList },
    };
}

/**
 * @description API action to update the host of one or more sessions
 * @param {String} airmeetId
 * @param {Array} sessionIds
 * @param {String} hostId
 * @example
 * {
 *  "hostId": userId,
 *  "sessionIds": [sessionId1, sessionId2]
 * }
 */
export function updateSessionHost(airmeetId, sessionIds, hostId) {
    return {
        [CALL_API]: {
            types: [UPDATE_SESSION_HOST_REQUEST, UPDATE_SESSION_HOST_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/sessionshost`,
            method: 'PUT',
            body: {
                hostId,
                sessionIds,
            },
            type: 'json',
        },
    };
}

export function setSelectedSession(sessionId) {
    return {
        type: SET_CURRENT_SESSION_ID,
        payload: {
            currentSessionId: sessionId,
        },
    };
}

export function setSubscribeTrackId(trackId) {
    return {
        type: SET_SUBSCRIBE_TRACK,
        payload: {
            subscribedTrackId: trackId,
        },
    };
}

export function updateSessionStatus(sessionId, newStatus) {
    return {
        type: UPDATE_SESSION_STATUS,
        payload: {
            sessionId,
            status: newStatus,
        },
    };
}

export function addSessionCohosts({ airmeetId, sessionId, coHostIds }) {
    return {
        [CALL_API]: {
            types: [ADD_SESSION_COHOSTS],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}`,
            body: { cohost_ids: coHostIds },
            type: 'json',
            method: 'PATCH',
        },
    };
}

export function verifyDomain(airmeetId, email, consent, recaptchaValue) {
    return {
        [CALL_API]: {
            types: [VERIFY_DOMAIN],
            endpoint: `/airmeet/${airmeetId}/register`,
            method: 'POST',
            body: {
                email,
                isConsentGiven: consent,
                token: recaptchaValue,
                useV3Captcha: true,
            },
            type: 'json',
        },
    };
}
export function verifyEventEntryV3(entityId, body, isEventSeries) {
    return {
        [CALL_API]: {
            types: [VERIFY_EMAIL_INVITE_V3],
            endpoint: isEventSeries
                ? `/series/${entityId}/verify-attendee`
                : `/airmeet/${entityId}/invite-attendee`,
            method: 'POST',
            body: body,
            type: 'json',
        },
    };
}
export function resendMagicLinkInvite(airmeetId, body, isEventSeries) {
    return {
        [CALL_API]: {
            types: [RESEND_INIVITE_EMAIL],
            endpoint: isEventSeries
                ? `/series/${airmeetId}/resend-attendee-invite`
                : `/airmeet/${airmeetId}/resend-attendee-invite`,
            method: 'POST',
            body: body,
            type: 'json',
        },
    };
}
export function saveAttendeeInfo(airmeetId, data) {
    return {
        [CALL_API]: {
            types: [SAVE_ATTENDEE_INFO],
            endpoint: `/airmeet/${airmeetId}/user`,
            method: 'PUT',
            body: data,
            type: 'json',
        },
    };
}
export function getIntroVideo(airmeetId) {
    return {
        [CALL_API]: {
            types: [INTRO_VIDEO_REQUEST, INTRO_VIDEO_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/intro-video`,
            method: 'GET',
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}
export function setAirmeetState(payload) {
    return {
        type: SET_AIRMEET_STATE,
        payload,
    };
}

export function setIsWatchReplayClicked(status) {
    return {
        type: SET_IS_WATCH_REPLAY_CLICKED,
        payload: {
            status,
        },
    };
}

/* set only search/filtered table only */
export function setTablesList(tables) {
    return {
        type: SET_TABLES_LIST,
        payload: {
            tablesList: tables,
        },
    };
}

/* set all the table present in lounge section */
export function setAllTable(tables) {
    return {
        type: SET_ALL_TABLE,
        payload: {
            allTable: tables,
        },
    };
}

export function setLoungeConfig(loungeConfig) {
    return {
        type: SET_LOUNGE_TABLES_CONFIG,
        payload: {
            loungeConfig,
        },
    };
}

export function setCanAttendeeCreateTable(canAttendeeCreateTable) {
    return {
        type: SET_CAN_ATTENDEE_CREATE_TABLE,
        payload: {
            canAttendeeCreateTable,
        },
    };
}

export function setUserRegistrationComplete(status) {
    return {
        type: SET_USER_REGISTRATION_COMPLETE,
        payload: {
            status,
        },
    };
}
