import FormField from 'components/community/common/FormField';
import { Box } from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { logger } from 'utils/logger';

const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

let autoComplete;
const loadScript = (url, callback, setIsScriptLoaded) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'googleMapsScript';

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (
                script.readyState === 'loaded' ||
                script.readyState === 'complete'
            ) {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }
    const getScriptId = document.getElementById('googleMapsScript');
    if (getScriptId) {
        setIsScriptLoaded(true);
        logger.info('Location script loaded successfully');
        return;
    }

    script.src = url;
    document.getElementsByTagName('body')[0].appendChild(script);
};

function handleScriptLoad(updateQuery, id) {
    const getElement = document.getElementById(id);
    autoComplete = new window.google.maps.places.Autocomplete(getElement, {});

    autoComplete.setFields([
        'address_components',
        'formatted_address',
        'place_id',
        'geometry',
    ]);

    autoComplete.addListener('place_changed', () =>
        handlePlaceSelect(updateQuery)
    );
}
async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    updateQuery(addressObject);
}

const LocationSearch = ({
    title,
    errors,
    newOnBoardingFlow = false,
    placeholder = 'Enter venue name',
    id = 'eventVenue',
    name = 'eventVenue',
    label = 'Event venue',
    subLabel = 'Name of the physical venue where the event is happening for in-person attendees',
    handleLocationChange,
}) => {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [clickedDropDown, setClickedDropDown] = useState(false);

    useEffect(() => {
        if (isScriptLoaded) {
            handleScriptLoad(handleOnPlaceUpdate, id);
        } else {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=places`,
                () => handleScriptLoad(handleOnPlaceUpdate, id),
                setIsScriptLoaded
            );
        }
    }, [isScriptLoaded]);

    const handleOnChange = (e) => {
        e.stopPropagation();
        const { value } = e.target;
        const formatData = {
            formatted_address: value,
        };
        handleOnPlaceUpdate(formatData, true);
    };

    const handleOnPlaceUpdate = (selectedPlace, isManual) => {
        let { formatted_address, geometry, place_id } = selectedPlace;

        const getLat = geometry?.location.lat() || '';
        const getLng = geometry?.location.lng() || '';
        if (!isManual) {
            const element = document.getElementById(id);
            formatted_address = element?.value;
            !clickedDropDown && setClickedDropDown(true);
        }

        const eventVenueData = formatted_address
            ? {
                  venue_title: formatted_address,
                  metadata: {
                      place_id: place_id || '',
                  },
                  latitude: getLat.toString(),
                  longitude: getLng.toString(),
              }
            : {};
        handleLocationChange(eventVenueData, isManual);
    };
    return (
        <>
            <GlobalStyles />
            <FieldWrapper>
                {!clickedDropDown ? (
                    <FormField
                        id={id}
                        name={name}
                        label={label}
                        subLabel={subLabel}
                        placeholder={placeholder}
                        filledValue={title}
                        defaultValue={title}
                        isError={errors.eventVenue}
                        errorMsg={errors.eventVenue}
                        variant={'v2'}
                        newOnBoardingFlow={newOnBoardingFlow}
                        inputValue={title}
                        value={title}
                        autoComplete='off'
                        onChange={handleOnChange}
                        isThemeV2={true}
                    />
                ) : (
                    <FormField
                        id={id}
                        name={name}
                        className='filled'
                        label={label}
                        subLabel={subLabel}
                        placeholder={placeholder}
                        defaultValue={title}
                        isError={errors.eventVenue}
                        errorMsg={errors.eventVenue}
                        variant={'v2'}
                        newOnBoardingFlow={newOnBoardingFlow}
                        inputValue={title}
                        autoComplete='off'
                        onChange={handleOnChange}
                        isThemeV2={true}
                    />
                )}
                <FieldIcon
                    icon='ic-location'
                    width='16px'
                    height='16px'
                    fill='text.default.secondary'
                    hasSubLabel={subLabel}
                />
            </FieldWrapper>
        </>
    );
};

export default LocationSearch;

const GlobalStyles = createGlobalStyle`
    .pac-container {
        z-index: 1051;
        background-color: ${({ theme }) => theme.colors.ambience[21]};
        border:0;
        margin-top: 5px;
        border-radius: 8px;
        overflow: hidden;

        &.pac-logo {
            &:after {
                display: none;
            }
        }
        .pac-item {
            border: 0;
            color:${({ theme }) => theme.colors.ambience[6]};
            font-size: 10px;
            padding: 5px 10px;
            font-family: ${({ theme }) => theme.fonts.book};

            &-selected,&:hover {
                background-color: ${({ theme }) => theme.colors.ambience[19]};
            }

            .pac-item-query {
                color:${({ theme }) => theme.colors.ambience[6]};
                font-size: 14px;
                line-height: 18px;
                font-family: ${({ theme }) => theme.fonts.bold};
            }
        }
    }
`;

const FieldWrapper = styled(Box)`
    flex: 1;
    position: relative;
    margin-top: ${({ theme }) => theme.space.x6};
    input {
        padding-right: ${({ theme }) => theme.space.x8} !important;
    }
`;

const FieldIcon = styled(Icon)`
    position: absolute;
    bottom: ${({ theme }) => theme.space.x4};
    right: ${({ theme }) => theme.space.x4};
    pointer-events: none;
`;
