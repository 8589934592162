import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { getUser } from 'context/LiveAirmeet';
import { UserData } from 'utils/typeDefs/userDataTypes';

const useLiveEventSelfUser = (): UserData => {
    return useLiveAirmeetContext(getUser);
};

export const useLiveEventSelfUserId = () => useLiveEventSelfUser()?.id;

export default useLiveEventSelfUser;
