import {
    SET_WAITING_SCREEN_PREVIEW,
    WAITING_SCREEN_GET_RESPONSE,
} from 'store/actions/stageWaitingScreen';
const initialState = {
    preview: null,
    list: [],
    session: null,
};

function stageWaitingScreen(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_WAITING_SCREEN_PREVIEW:
            return {
                ...state,
                preview: payload,
            };
        case WAITING_SCREEN_GET_RESPONSE: {
            return {
                ...state,
                list: payload?.data,
            };
        }
        default:
            return state;
    }
}

export default stageWaitingScreen;
