import EventEmitter from 'events';
import { logger } from '../utils/logger';

const REAL_TIME_MESSAGES_NODE_PATH = 'realtime-messages';
const DEFAULT_CHANNEL = 'global';
const PEER_2_PEER_CHANNEL = 'peer-2-peer';
const CHANNELS_NODE = 'channels';

export default class FirebaseRTMClient extends EventEmitter {
    constructor(client, accountUid, baseKey = '') {
        super();
        this.client = client;
        this.disableLogs = false;
        this.accountUid = accountUid;
        this.channels = {};
        this.prefixKey = baseKey
            ? `${REAL_TIME_MESSAGES_NODE_PATH}/${baseKey}`
            : REAL_TIME_MESSAGES_NODE_PATH;
    }

    init() {
        return this.client.getCurrentTimeStamp().then((timestamp) => {
            this.timestamp = timestamp;
            this.joinPeerRoom();
            return this.joinChannel(DEFAULT_CHANNEL, this.timestamp);
        });
    }

    joinPeerRoom() {
        this.bindPeerEvent(this.getPeerRoom(this.accountUid));
    }

    getChannelRefName(channelName) {
        return `${this.prefixKey}/${CHANNELS_NODE}/${channelName}`;
    }

    getChannelMessagesRefName(channelName) {
        return `${this.getChannelRefName(channelName)}/messages`;
    }

    // @TODO
    // getChannelMembersRefName(channelName) {
    //     return `${this.getChannelRefName(channelName)}/members`;
    // }

    joinChannel(channelName, channelTimestamp, options = {}) {
        return (channelTimestamp
            ? Promise.resolve(channelTimestamp)
            : this.client.getCurrentTimeStamp()
        ).then((timestamp) => {
            const channelNodePath = this.getChannelRefName(channelName);
            const messagesNodePath = this.getChannelMessagesRefName(
                channelName
            );
            // const membersNodePath = this.getChannelMembersRefName(channelName);
            const messagesRef = this.client.ref(messagesNodePath);
            this.channels[channelName] = {
                messagesNodePath,
                channelNodePath,
                //  membersNodePath,
                messagesRef: messagesRef,
                emitter: new EventEmitter(),
                messages: {},
            };
            this.bindChannelEvent(channelName, timestamp, options);
            return this.channels[channelName];
        });
    }

    receiveChannelMessage(channelName, message) {
        if (this.channels[channelName]) {
            message.asSender = message.uid === this.accountUid;
            const messageType = message.type ? message.type : 'info';
            this.channels[channelName].messages[message.key] = message;
            this.logger(
                `Recive Channel Message: ${channelName}`,
                this.channels[channelName].messages[message.key]
            );
            this.channels[channelName].emitter.emit(
                `${messageType}-type-message`,
                message
            );
        }
    }

    getChannelEmmiter(channelName = DEFAULT_CHANNEL) {
        if (!this.channels[channelName]) {
            return;
        }

        return this.channels[channelName].emitter;
    }

    sendChannelMessage(type, content, channelName = DEFAULT_CHANNEL) {
        if (!this.channels[channelName]) {
            return;
        }
        this.send(this.channels[channelName].messagesNodePath, type, content);
    }

    leaveChannel(channelName = DEFAULT_CHANNEL) {
        if (this.channels[channelName]) {
            this.channels[channelName].messagesRef.off('child_added');
            delete this.channels[channelName];
        }
    }

    getChannelMessages(channelName = DEFAULT_CHANNEL) {
        return this.channels[channelName]
            ? Object.values(this.channels[channelName].messages)
            : [];
    }

    bindChannelEvent(channelName, timestamp, options = {}) {
        const messagesRef = this.channels[channelName].messagesRef
            .orderByChild('timestamp')
            .startAt(timestamp);

        if (options.limit) {
            messagesRef.limitToLast(options.limit);
        }

        messagesRef.on('child_added', (snapshot) => {
            this.receiveChannelMessage(channelName, snapshot.val());
        });
    }

    bindPeerEvent(channelName) {
        this.logger('Bind Peer 2 Peer Message timestamp:', {
            channelName,
            timestamp: this.timestamp,
        });
        const ref = this.client.ref(channelName);
        ref.orderByChild('timestamp')
            .startAt(this.timestamp)
            .on('child_added', (snapshot) => {
                // console.log(snapshot.key, snapshot.val());
                this.reciveMessage(snapshot.val());
            });
    }

    getPeerRoom(uid) {
        return `${this.prefixKey}/${PEER_2_PEER_CHANNEL}/${uid}`;
    }

    getPeerToPeerRoom(uid) {
        return uid < this.accountUid
            ? `${uid}-${this.accountUid}`
            : `${this.accountUid}-${uid}`;
    }

    sendMessage(type, reciverUid, content) {
        const channelName = this.getPeerRoom(reciverUid);
        this.send(channelName, type, content);
    }

    send(channelName, type, content) {
        const ref = this.client.ref(channelName);
        const key = ref.push().getKey();
        const messageData = {
            key,
            type,
            content,
            senderUid: this.accountUid,
            timestamp: this.client.getServerTimestampRef(),
        };

        ref.child(key).set(messageData);
        this.logger(`Send Message: [${channelName}]`, messageData);
    }

    reciveMessage(message) {
        this.logger('Recive Peer 2 Peer Message:', message);
        const messageType = message.type ? message.type : 'info';
        this.emit(`peer-2-peer-${messageType}-type-message`, message);
    }

    logger(...args) {
        if (this.disableLogs) {
            return;
        }
        logger.info(...args);
    }
}
