const emptyObj = {};

export const getInvitedSpeakers = (store) =>
    store.liveAirmeet.invitedSpeakers || emptyObj;

export const getInvitationInfo = (store) =>
    store.liveAirmeet.invitations || emptyObj;

export const getInviteCardVisibility = (store) =>
    store.liveAirmeet.inviteCardVisible;

export const getIsStageLayoutEnabled = (store) =>
    store.liveAirmeet.genral.is_stage_layout_v2_enabled;

export const getStageJoinRequests = (store) =>
    store.liveAirmeet.stageJoinRequests || emptyObj;

export const getInviteToStagePermissionProcess = (store) =>
    store.liveAirmeet.isInviteToStagePermissionInProgress;

export const getIsVideoPlayOnStage = (store) =>
    !!store.liveAirmeet.isVideoPlayOnStage;

export const getIsVideoPlayWithoutHLS = (store) =>
    !!store.recordedVideo.thumbnail;

export const sharedScreenIdSelector = (store) =>
    store.liveAirmeet.genral?.screen || null;

export const sharedBackstageScreenIdSelector = (store) =>
    store.liveAirmeet.genral?.screen_backstage || null;

export const isCustomMediaSelector = (state) =>
    sharedScreenIdSelector(state) === 'media';
export const getRTCConfig = (store) => store.liveAirmeet.rtcConfig;

export const closedCaptionsSelector = ({ closedCaptions }) =>
    closedCaptions.isEnabled && closedCaptions.isLoaded;

export const closedCaptionsConfigSelector = ({ closedCaptions }) =>
    closedCaptions?.config || emptyObj;
