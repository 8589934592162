import {
    addBreadcrumb,
    captureException,
    configureScope,
    init as initializeSentry,
    setTags as setSentryTags,
    Severity,
} from '@sentry/browser';
import { deserializeError } from 'serialize-error';
import { LOG_LEVEL, LOG_METHODS } from 'utils/constants/logger';
import { LogInterceptor } from 'utils/loggers/LogInterface';

const LOG_SEVERITY = {
    [LOG_LEVEL.VERBOSE]: Severity.fromString(LOG_METHODS[LOG_LEVEL.VERBOSE]),
    [LOG_LEVEL.INFO]: Severity.Info,
    [LOG_LEVEL.WARN]: Severity.Warning,
    [LOG_LEVEL.ERROR]: Severity.Error,
    [LOG_LEVEL.FATAL]: Severity.Fatal,
};

const SENTRY_IGNORED_ERRORS = [
    'Object Not Found Matching Id',
    'TypeError: Illegal invocation',
    'ResizeObserver loop limit exceeded',
    "Failed to execute 'removeChild' on 'Node'",
    "Cannot read property 'vid' of undefined",
    'AbortError: The play() request was interrupted by a call to pause()',
];

// NOTE: Add 'dev' below to test logging locally
if (['true'].includes(process.env.REACT_APP_ALLOW_SENTRY_LOGS)) {
    initializeSentry({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        ignoreErrors: SENTRY_IGNORED_ERRORS,
        environment: process.env.REACT_APP_ENV,
        debug: true,
        release: process.env.BUILD_ID || process.env.npm_package_version,
        /* enable this if interventions needed to be logged
        integrations: [
            new ReportingObserverIntegration({
                types: ['intervention'],
            }),
        ],*/
    });
}

export function setUser({ id: userId }) {
    // Set the user scope on Sentry
    configureScope(function (scope) {
        scope.setUser({ id: userId });
    });
}

export function setTags(tags: { [key: string]: string }) {
    // Configure sentry
    setSentryTags(tags);
}

export const SentryInterceptor: LogInterceptor = {
    key: 'sentry',
    intercept: ({ level, prefix }, ...args) => {
        if (level >= LOG_LEVEL.ERROR) {
            let deserializedError = null;
            for (let i = 0; i < args.length; i++) {
                const arg = args[i];
                let currentDeserializedError = deserializeError(arg);
                if (
                    currentDeserializedError.toString().includes('NonError') ===
                    false
                ) {
                    deserializedError = currentDeserializedError;
                    break;
                }
            }
            if (!deserializedError) {
                deserializedError = new Error(args[0]);
            }
            captureException(deserializedError, {
                extra: { args },
                tags: {
                    module: prefix || 'unspecified',
                    errorInfo: (Array.isArray(args) && args[0]) || '',
                },
            });
        } else {
            addBreadcrumb({
                category: prefix,
                message: args[0],
                data: { args },
                level: LOG_SEVERITY[level] || LOG_SEVERITY[LOG_LEVEL.VERBOSE],
            });
        }
    },
    acceptedLevels:
        LOG_LEVEL.INFO | LOG_LEVEL.WARN | LOG_LEVEL.ERROR | LOG_LEVEL.FATAL,
    setUser,
    setTags,
};

export default SentryInterceptor;
