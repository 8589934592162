import EventBridge from 'event-bridge';
import ChannelBridge from 'event-bridge/ChannelBridge';
import { DefaultChannelEvents } from 'event-bridge/constants';
import { BridgeCallbackFunction } from 'event-bridge/types';

import set from 'lodash/set';
import { useCallback, useEffect, useRef, useState } from 'react';
import { callApi } from 'store/api';
import { slot } from 'ts-event-bus';
import { noop } from 'utils/constants/common';
import { WORKER_NAME_LOGGER } from 'utils/constants/workers';
import ContainerEvents from './ContainerEvents';

export type APICallback = (
    endpoint: string,
    method: string,
    params: { [key: string]: any }
) => Promise<{ payload: Object | null; error: Error | null }>;

export type LoggerCallback = (event: string, data: any) => void;

export type NotifyCallback = (event: string, data?: any, to?: string) => void;

export type NotificationCallback = (
    event: string,
    callback: BridgeCallbackFunction
) => void;

export type SetRouteCallback = (
    path: string,
    targetContainerId?: string
) => void;

export type GetRouteCallback = (targetContainerId?: string) => Promise<string>;

export type SetStoreCallback = (
    keyPath: string,
    data: any,
    targetContainerId?: string
) => void;

export type GetStoreCallback = (
    keyPath: string,
    targetContainerId?: string
) => Promise<any>;

const DefaultContainerEvents = {
    ...DefaultChannelEvents,

    setRoute: slot<string>(),
    getRoute: slot<void, string>(),

    setStore: slot<{ keyPath: string; data: any }>(),
    getStore: slot<string, any>(),
};

function useContainerChannel({
    containerId,
    handlers = {},
    initialState = {},
    initialRoute = '',
    beforeLoad = noop,
    afterLoad = noop,
}) {
    const [loaded, setLoaded] = useState(false);
    const [containerState, setContainerState] = useState({
        hidden: false,
        ...initialState,
    });
    const [containerRoute, setContainerRoute] = useState(initialRoute);

    const containerBridge = useRef(
        new ChannelBridge<ContainerEvents>(
            containerId,
            DefaultContainerEvents,
            { callbacks: handlers }
        )
    ).current;

    const onGetRoute = useCallback(
        (targetContainerId?: string) => {
            if (targetContainerId) {
                // Fetch the target bridge
                const targetBridge = EventBridge.channel(
                    targetContainerId
                ) as ChannelBridge<ContainerEvents>;

                if (targetBridge) {
                    return targetBridge.mainChannel.getRoute();
                } else {
                    return Promise.resolve(null);
                }
            } else {
                return Promise.resolve(containerRoute);
            }
        },
        [containerRoute]
    );

    const onSetRoute: SetRouteCallback = useCallback(
        (path, targetContainerId) => {
            if (targetContainerId) {
                // Fetch the target bridge
                const targetBridge = EventBridge.channel(
                    targetContainerId
                ) as ChannelBridge<ContainerEvents>;

                if (targetBridge) {
                    targetBridge.mainChannel.setRoute(path);
                }
            } else {
                setContainerRoute(path || '');
            }
        },
        [setContainerRoute]
    );

    const onGetState: GetStoreCallback = useCallback(
        (keyPath: string, targetContainerId?: string) => {
            if (targetContainerId) {
                // Fetch the target bridge
                const targetBridge = EventBridge.channel(
                    targetContainerId
                ) as ChannelBridge<ContainerEvents>;

                if (targetBridge) {
                    return targetBridge.mainChannel.getStore(keyPath);
                } else {
                    return Promise.resolve(null);
                }
            } else {
                return Promise.resolve(get(containerState, keyPath));
            }
        },
        []
    );

    const onSetState: SetStoreCallback = useCallback(
        (keyPath, data, targetContainerId) => {
            if (targetContainerId) {
                // Fetch the target bridge
                const targetBridge = EventBridge.channel(
                    targetContainerId
                ) as ChannelBridge<ContainerEvents>;

                if (targetBridge) {
                    targetBridge.mainChannel.setStore({
                        keyPath,
                        data,
                    });
                }
            } else {
                setContainerState((prev) => {
                    set(prev, keyPath, data);
                    return { ...prev };
                });
            }
        },
        [setContainerState]
    );

    const handleApiRequest: APICallback = useCallback(
        async (endpoint, method, params) => {
            try {
                const response = await callApi({ endpoint, method, ...params });
                return { payload: response, error: null };
            } catch (error) {
                return {
                    payload: null,
                    error,
                };
            }
        },
        []
    );

    const handleLogging: LoggerCallback = useCallback(
        (event, data) => {
            containerBridge.mainChannel.notifyTo(WORKER_NAME_LOGGER, {
                from: containerId,
                event,
                data,
            });
        },
        [containerId]
    );

    const handleNotify: NotifyCallback = useCallback((event, data, to) => {
        containerBridge.mainChannel.notification({
            to,
            event,
            data,
        });
    }, []);

    const attachListener: NotificationCallback = useCallback(
        (event, callback) => {
            containerBridge.onEvent(event, callback);
        },
        []
    );

    const detachListener: NotificationCallback = useCallback(
        (event, callback) => {
            containerBridge.offEvent(event, callback);
        },
        []
    );

    // Setup our handlers
    // Routing
    const handleGetRoute = useRef<() => string>();
    handleGetRoute.current = () => containerRoute;
    const handleSetRoute = useRef<(path: string) => void>();
    handleSetRoute.current = (path) => {
        setContainerRoute(path || '');
    };

    // Storage
    const handleGetStore = useRef<(key: string) => any>();
    handleGetStore.current = (key) => get(containerState, key);
    const handleSetStore = useRef<(key: string, data: any) => void>();
    handleSetStore.current = (keyPath, data) => onSetState(keyPath, data);

    useEffect(() => {
        const unsubs = [
            containerBridge.mainChannel.getRoute.on(() =>
                handleGetRoute.current()
            ),
            containerBridge.mainChannel.setRoute.on((path) => {
                handleSetRoute.current(path);
            }),
            containerBridge.mainChannel.getStore.on((key) =>
                handleGetStore.current(key)
            ),
            containerBridge.mainChannel.setStore.on(({ keyPath, data }) => {
                handleSetStore.current(keyPath, data);
            }),
        ];

        containerBridge.register(EventBridge);

        // Allow preloading any async values
        Promise.all([beforeLoad()]).then(() => {
            setLoaded(true);
            afterLoad();
        });

        return () => {
            unsubs.map((unsub) => unsub());
            containerBridge.unregister();
        };
    }, []);

    return {
        // Load-state
        loaded,
        // Routing
        route: containerRoute,
        getRoute: onGetRoute,
        setRoute: onSetRoute,
        // Store
        state: containerState,
        getState: onGetState,
        setState: onSetState,
        // Notifications
        notify: handleNotify,
        onNotify: attachListener,
        offNotify: detachListener,
        // Utils
        logger: handleLogging,
        apiCall: handleApiRequest,
    };
}

export default useContainerChannel;
