import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { updateWorkerSettings } from '../utils/logger';
import useRealtimeFeatureConfig from './live-airmeet/useRealtimeFeatureConfig';

function useLoggerIntercept({ airmeetId, firebaseClient }) {
    const authUser = useSelector((state) => state.auth.user);
    const loggerConfig = useRealtimeFeatureConfig({
        airmeetId,
        firebaseClient,
        userId: authUser.id,
        featureName: 'logging',
    });

    useEffect(() => {
        if (loggerConfig.logLevel) {
            updateWorkerSettings({ levels: loggerConfig.logLevel });
        }
    }, [loggerConfig]);
}

export default useLoggerIntercept;
