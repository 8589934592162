import Axios from 'axios';
import EventLoader from 'components/EventLoader';
import NetworkErrorComponent from 'components/NetworkErrorComponent';
import Loader from 'components/general/Loader';
import { PEOPLE_TAB_SOURCE_CONFIG } from 'components/lounge/rhsV2/peopleTab/constants';
import { FIREBASE_ERROR_MESSAGES } from 'containers/FirebaseClient';
import { CONTEXT_TYPE } from 'hooks/attendance/constants';
import usePlanFlags from 'hooks/billing-plans/usePlanFlags';
import useGroupMeeting from 'hooks/groupMeeting/useGroupMeeting';
import {
    getAccessCode,
    getAccessCodeFromSession,
} from 'hooks/live-airmeet/useAccessCodeUserToken';
import useAirmeetUserServicePrefetch from 'hooks/live-airmeet/useAirmeetUserServicePrefetch';
import useDeepLink from 'hooks/live-airmeet/useDeepLink';
import useFetchBookmarkSessions from 'hooks/live-airmeet/useFetchBookmarkSessions';
import useFirebaseClientSetup from 'hooks/live-airmeet/useFirebaseClientSetup';
import useUserPresence from 'hooks/live-airmeet/useUserPresence';
import { useRTCVendor } from 'hooks/rtcVendor/useRTCVendor';
import useClosedCaptionFeature from 'hooks/useClosedCaptionFeature';
import useDataReader from 'hooks/useDataReader';
import useEnableStageVisibility from 'hooks/useEnableStageVisibility';
import useFilterSupport from 'hooks/useFilterSupport';
import useHeartBeatObserver, {
    analyticsMethodsToIntercept,
    interceptFbAnalyticsLog,
} from 'hooks/useHeartBeatObserver';
import useMusicModeFeature from 'hooks/useMusicModeFeature';
import usePermissionWorkFlowConfig from 'hooks/usePermissionWorkflowConfig';
import useSetAnalyticsData from 'hooks/useSetAnalyticsData';
import useToasts from 'hooks/useToasts';
import useVolumeControlFeature from 'hooks/useVolumeControlFeature';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserPresenceService from 'services/presence/UserPresenceService';
import UserService from 'services/users/UserService';
import { checkIntoEvent } from 'store/actions/airmeet';
import { verifyAttendeeAccess } from 'store/actions/airmeetRules';
import { logoutUser, updateExhibitor } from 'store/actions/auth';
import { getHubspotSync } from 'store/actions/cmDashboard/integrations';
import {
    clearAirmeetUtilInstance,
    createAirmeetUtilInstance,
    getAirmeetUtilInstance,
} from 'utils/airmeetUtilInstance';
import AuthService from 'utils/authService';
import {
    AIRMEET_STATUS,
    CODE,
    EVENT_SUB_TYPES,
    EVENT_TYPES,
    EXHIBITOR_CODE,
    MAGIC_LINK_DETAILS,
} from 'utils/constants/airmeet';
import {
    LOADING_EVENTS_TYPES,
    NETWORK_ERROR_TYPES,
} from 'utils/constants/eventLoader';
import { FEATURE_NAMES } from 'utils/constants/featureNames';
import {
    LIVE_AIRMEET_STATE,
    webRTCFirewallDetectionStates,
} from 'utils/constants/live-airmeet';
import {
    QOEUserDropLoggerDirect,
    clientInUse,
    userDropCases,
} from 'utils/constants/qoeLogConstants';
import { SESSION_TYPES, SESSION_VISIT_ID_KEY } from 'utils/constants/sessions';
import { USER_ATTENDANCE_TYPES, USER_ROLE } from 'utils/constants/users';
import { closeCookieBanner } from 'utils/cookieConsentHelper';
import FirebaseLogger from 'utils/firebaseLogger';
import { addLoggerTags, logger } from 'utils/logger';
import { logEnteredEvent } from 'utils/loggers/common';
import { instanceTypes, setRTCSDKEnv } from 'utils/rtcSdkEnv';
import UserRoles from 'utils/userRoles';
import { isOrganizer } from 'utils/users';
import { v4 as uuid } from 'uuid';
import JoinFromOtherPlace from '../components/lounge/JoinFromOtherPlace';
import history from '../history';
import { useMemberOnlineState, useRTMClient } from '../hooks/live-airmeet';
import useAirmeetData from '../hooks/live-airmeet/useAirmeetData';
import useFeatureFlags from '../hooks/live-airmeet/useFeatureFlags';
import useUserStorage from '../hooks/live-airmeet/useUserStorage';
import useAutomationParams from '../hooks/useAutomationParams';
import useCloudHostLiveState from '../hooks/useCloudHostLiveStatus';
import useDeviceInfo from '../hooks/useDeviceInfo';
import useFeatureControl, {
    ALL_FEATURES,
    ALL_FEATURES_CONFIG,
} from '../hooks/useFeatureControl';
import useLoggerIntercept from '../hooks/useLoggerIntercept';
import useNetworkStatus from '../hooks/useNetworkStatus';
import { ErrorEvents } from '../utils/airmeet';
import PermissionUtils from '../utils/permission-utils';
import { useFullScreenContext } from './FullScreen';
import { useProfiling } from './Profiling';
export * from 'context/selectors/LiveAirmeet';

const TRACES = {
    CHECK_FOR_SUPPORTED_BROWSER: 'CHECK_FOR_SUPPORTED_BROWSER',
};

const LiveAirmeet = React.createContext(null);

const THRESHOLD_PERMISSIONS_VERIFICATION = 20; // in seconds

export function LiveAirmeetProvider({
    airmeetId,
    user,
    appWebView = false,
    children,
}) {
    const isRtcEnvSet = useRef(false);
    if (airmeetId && user?.id && !isRtcEnvSet.current) {
        setRTCSDKEnv(instanceTypes.SDK, airmeetId, user.id);
        setRTCSDKEnv(instanceTypes.PRECALL, airmeetId, user.id);
        isRtcEnvSet.current = true;
    }

    const [isFirstAPICallCompleted, setFirstAPICallCompleted] = useState(false);
    const [defaultDevices, setDefaultDevices] = useState({});
    const [isSupportedBrowser, setIsSupportedBrowser] = useState(
        PermissionUtils.isEventCloudHost() ? true : null
    );
    const [isReadyForOnline, setReadyForOnline] = useState(false);
    const [isExitButtonClick, setExitButtonClick] = useState(false);
    const [localStreamsReconnecting, setLocalStreamsReconnecting] = useState(
        []
    );
    const ignoreBeforeWindow = useRef(false);
    const skipLeaveConfirmation = useRef(false);
    const firebaseConnectionStatus = useSelector(
        ({ genral }) => genral.hasBlockedWebRTCConnection
    );
    const airmeetStatus = useSelector((state) => state.lounge.airmeet.status);
    const dispatch = useDispatch();
    const { perf } = useProfiling();

    const { errorToast } = useToasts();
    const { browserName, browserMajor, deviceType } = useDeviceInfo();
    const { fullScreen, setFullScreen } = useFullScreenContext();
    const { isFreeTrialPlanDeactivated } = usePlanFlags();

    const { deepLinkParamPresent, isValidDeepLink } = useDeepLink();
    const [isFirstAPIFailed, setFirstAPIFailed] = useState(false);

    UserRoles.authUser = user;
    // check for info api call, aim is to execute one api
    const infoRequestLoadTime = useRef(Date.now());
    const exhibitorToken = useMemo(() => {
        const eventDetails = getAccessCodeFromSession();
        return eventDetails?.[EXHIBITOR_CODE];
    }, []);

    const handleCheckIn = useRef(false);

    const handleNonCheckedInUserEntry = useMemo(
        () =>
            debounce(async (airmeetId, errorCallback = () => {}) => {
                try {
                    const response = await dispatch(checkIntoEvent(airmeetId));
                    if (response?.error) {
                        const { message } = response?.payload?.json || {};
                        logger.error('Failed to check into event', message);
                        errorCallback();
                    } else {
                        logEnteredEvent(airmeetStatus, 'check_in', {
                            attendeeId: user?.id,
                            airmeetId,
                            airmeetVisitId,
                        });
                    }
                } catch (error) {
                    logger.error('Failed to check into event', error);
                    errorCallback();
                }
            }, 750),
        [dispatch]
    );
    const redirectToPublicPage = useCallback(
        (
            withoutParam = false,
            usePreview = false,
            showRestrictedEventAccessToast = false
        ) => {
            // Need to clean old states and client to avoid conflicts with old data
            logger.info('Redirect to event landing page');
            let queryParam = !withoutParam ? window.location.search || '' : '';
            if (queryParam && usePreview) {
                queryParam = queryParam + '&isPreview=true';
            } else if (usePreview) {
                queryParam = '?preview=true';
            }

            if (showRestrictedEventAccessToast)
                localStorage.setItem('showRestrictedEventAccessToast', true);

            window.location.replace(`/e/${airmeetId}${queryParam}`);
        },
        [airmeetId]
    );
    useEffect(() => {
        if (
            !isEmpty(user) &&
            !user.checked_in &&
            airmeetId &&
            getAccessCode(CODE, airmeetId)
        ) {
            if (!handleCheckIn.current) {
                handleNonCheckedInUserEntry(airmeetId, redirectToPublicPage);
                handleCheckIn.current = true;
            }
        }
    }, [
        airmeetId,
        dispatch,
        redirectToPublicPage,
        user,
        handleNonCheckedInUserEntry,
    ]);

    useEffect(() => {
        if (isFreeTrialPlanDeactivated) {
            redirectToPublicPage(true);
        }
    }, [isFreeTrialPlanDeactivated, redirectToPublicPage]);

    useEffect(() => {
        if (!PermissionUtils.isEventCloudHost()) {
            return;
        }
        document.body.classList.add('screen-recording-view');
    }, []);

    useEffect(() => {
        const checkForSupportedBrowser = async (retryAttempts = 0) => {
            let isSupported = false;
            perf.trace(TRACES.CHECK_FOR_SUPPORTED_BROWSER);
            try {
                const res = await Axios.get(
                    process.env.REACT_APP_SUPPORTED_BROWSER_LIST
                );
                const { supportedBrowserList = [] } = res.data;
                if (supportedBrowserList.length > 0) {
                    for (const browser of supportedBrowserList) {
                        if (
                            browser.browser === browserName &&
                            Number(browser.minVersion) <= Number(browserMajor)
                        ) {
                            isSupported = true;
                            break;
                        }
                    }
                }
                setIsSupportedBrowser(isSupported);
            } catch (err) {
                logger.error(
                    'Error fetching supported browser list:',
                    err.message,
                    ' |  retry attempt : ',
                    retryAttempts
                );
                if (retryAttempts < 2) {
                    // Retry if API fails
                    checkForSupportedBrowser(++retryAttempts);
                } else {
                    // Assuming browser is supported for now to bypass to the event
                    setIsSupportedBrowser(true);
                }
            }
            perf.traceStop(TRACES.CHECK_FOR_SUPPORTED_BROWSER);
        };
        checkForSupportedBrowser();
    }, []);

    const data = useAirmeetData({ airmeetId, user });
    const {
        isAllowedToEnter,
        fetchUpdatedAirmeet,
        updateCurrentState,
        currentAirmeet,
        currentState,
        currentSession,
    } = data;

    const communityId = currentAirmeet?.community_id;

    const isUserOrganizer = isOrganizer(data.userRole);
    useEffect(() => {
        isUserOrganizer &&
            communityId &&
            dispatch(getHubspotSync({ communityId }));
    }, [communityId, dispatch, isUserOrganizer]);

    ignoreBeforeWindow.current =
        !isAllowedToEnter || PermissionUtils.isEventCloudHost();
    const currentSessionRef = useRef('');
    currentSessionRef.current = currentSession;
    /**
     * Group meeting is rendered inside an IFrame. When page is refreshed, although there is a confirmation,
     * content inside the iFrame gets refreshed causing unstable states. Hence, the confirmation check
     * is removed when user is in group meeting.
     * @todo Ask vendor(Daily.co) to provide a more intuitive way to handle this situation.
     */
    skipLeaveConfirmation.current =
        currentSession && currentSession.type === SESSION_TYPES.GROUP_MEETING;

    // Realtime database client
    const {
        firebaseClient,
        chatFirebaseClient,
        reactionsFirebaseClient,
        stageFirebaseClient,
        tableFirebaseClient,
        fluidSpaceClient,
        userProfileClient,
        userPresenceClient,
        boothTablesClient,
        breakoutTablesClient,
    } = useFirebaseClientSetup({
        user,
        airmeetId,
        currentAirmeet: data?.currentAirmeet,
    });

    // Real time messaging client
    const rtmClient = useRTMClient({
        airmeetId,
        firebaseClient,
    });
    // Cloud user storage client
    const userStorage = useUserStorage({
        airmeetId,
        firebaseClient,
    });

    useAutomationParams({
        airmeetId,
    });

    const { isOnline } = useNetworkStatus();

    const sessions =
        currentAirmeet && currentAirmeet.sessions
            ? currentAirmeet.sessions
            : [];

    const {
        loaded,
        enabledControls,
        enabledFeatures,
        enabledPlatformFeatures,
        platformLoaded,
        featuresConfig,
        platformConfig,
    } = useFeatureControl({
        airmeetId,
        firebaseClient,
        caller: 'LIVE_CONTEXT',
    });

    useEnableStageVisibility({
        firebaseClient,
        currentAirmeet,
        enabledFeatures,
        userRole: data.userRole,
    });

    useEffect(() => {
        if (loaded) {
            logger.info('LiveAirmeet Feature Config Change', featuresConfig);
        }
    }, [loaded, featuresConfig]);

    const isSessionCloudHostLive = useCloudHostLiveState({
        airmeetId,
        firebaseClient,
        currentSession,
    });

    const isSessionScreenRecorderCloudHostLive = useCloudHostLiveState({
        airmeetId,
        firebaseClient,
        currentSession,
        userKey: 'recording_user_id',
    });

    const featureFlags = useFeatureFlags({
        airmeetId,
        data,
        enabledFeatures,
    });

    usePermissionWorkFlowConfig();

    useFilterSupport({
        userId: user?.id,
        airmeetId,
    });

    // hook to enable/disable group meeting based on airmeet/platform flag values
    useGroupMeeting({
        featuresConfig,
        platformConfig,
    });

    useRTCVendor({
        featuresConfig,
        platformConfig,
    });

    // hook to enable/disable music-mode based on airmeet/platform flag values
    useMusicModeFeature({ featuresConfig });

    // hook to manage multilingual-closed-caption and cc firebase/backend flags
    const {
        isClosedCaptioningEnabled,
        isCCSubscriptionViaFirebaseEnable,
    } = useClosedCaptionFeature({
        featuresConfig,
        enabledPlatformFeatures,
        currentAirmeet,
    });

    // hook to enable/disable volume based on airmeet/platform flag values
    useVolumeControlFeature({ featuresConfig });

    const isUGCEnabled = useMemo(
        () => (enabledFeatures || []).includes(ALL_FEATURES.USER_CREATE_TABLES),
        [enabledFeatures]
    );

    const hasRestrictedDurations = useMemo(
        // this method is to check the duration validation for free type of events [meetup]
        () => !currentAirmeet?.is_trial,
        [currentAirmeet]
    );

    const isLiveAnnouncementEnabled =
        useMemo(
            () =>
                (enabledFeatures || []).includes(
                    ALL_FEATURES.ENABLE_LIVE_ANNOUNCEMENT
                ),
            [enabledFeatures]
        ) || currentAirmeet?.is_live_announcement_enabled;

    // Initialize web worker based logging
    useLoggerIntercept({
        airmeetId,
        firebaseClient,
    });

    const onAirmeetError = useRef(null);
    onAirmeetError.current = ({ type, error, invalidAuthToken }) => {
        logger.error('Airmeet client forwarded error', error);
        switch (type) {
            case ErrorEvents.RTCTokenError:
                let errorMessage =
                    'We encountered an error processing your request, please refresh to continue.';
                if (invalidAuthToken) {
                    dispatch(logoutUser());
                    errorMessage =
                        'Could not authenticate your request. Please try logging in again.';
                }
                errorToast(errorMessage);
                break;
            default:
                break;
        }
    };

    const airmeetVisitId = useSelector(({ liveAirmeet }) => {
        return liveAirmeet.airmeetVisitId;
    });

    const logCancelClickEvent = useCallback(
        (wantedExitId) => {
            const sessionVisitId = sessionStorage.getItem(SESSION_VISIT_ID_KEY);
            logger.info('Cancelled Exit');
            FirebaseLogger.cancelledExit({
                attendeeId: user.id,
                airmeetId,
                airmeetVisitId,
                wantedExitId,
                sessionVisitId,
            });
        },
        [user, airmeetId, airmeetVisitId]
    );

    // Add logger tags
    useEffect(() => {
        if (!data.currentAirmeet) return;

        if (data.currentSession) {
            addLoggerTags({
                sessionId: data.currentSession.sessionid,
            });
        }

        if (data.userRole) {
            addLoggerTags({
                userRole: data.userRole,
            });
        }
    }, [data.currentSession, data.currentAirmeet, data.userRole]);

    useSetAnalyticsData({
        airmeetId,
        user,
        airmeet: data.currentAirmeet,
        session: data.currentSession,
        userStageRole: data.userStageRole,
        userRole: data.userRole,
    });

    const hasBrowserCheckLoaded = isSupportedBrowser !== null;

    const hasLoaded =
        hasBrowserCheckLoaded &&
        firebaseClient &&
        rtmClient &&
        loaded &&
        platformLoaded &&
        isFirstAPICallCompleted &&
        currentState !== LIVE_AIRMEET_STATE.loading;

    useEffect(() => {
        const {
            currentAirmeet,
            currentSession,
            upcomingSession,
            userRole,
            userStageRole,
            currentState,
        } = data;
        PermissionUtils.assignValues({
            firebaseClient,
            airmeetId,
            sessions,
            currentSession,
            userRole: userStageRole,
            authUser: user,
            origin: 'LiveAirmeet:data update',
        });

        if (
            loaded &&
            isFirstAPICallCompleted &&
            rtmClient &&
            currentAirmeet &&
            currentState === LIVE_AIRMEET_STATE.loading
        ) {
            const geofencing =
                featuresConfig &&
                featuresConfig[ALL_FEATURES_CONFIG.GEOFENCING];
            createAirmeetUtilInstance({
                rtmClient,
                airmeetId,
                geofencing,
                firebaseClient,
            });

            const airmeetUtilInstance = getAirmeetUtilInstance();
            airmeetUtilInstance.is_enable_watermark =
                currentAirmeet.is_enable_watermark;
            airmeetUtilInstance.setAuthUser({
                ...user,
                ...currentAirmeet.assocUsers[user.id],
            });

            // Attach error handler
            airmeetUtilInstance.onErrors(onAirmeetError.current);

            updateCurrentState(
                currentAirmeet,
                currentSession,
                userRole,
                upcomingSession,
                false
            );
        }
    }, [rtmClient, isFirstAPICallCompleted, data, loaded]);

    const { fetchBookmarkedSessions } = useFetchBookmarkSessions();

    useEffect(() => {
        const timeDifference =
            (Date.now() - infoRequestLoadTime.current) / 1000;
        if (
            hasLoaded &&
            isReadyForOnline &&
            timeDifference > THRESHOLD_PERMISSIONS_VERIFICATION
        ) {
            logger.info('Before calling combine api in live airmeet context!');
            //Update with latest airmeet data after enter in event page
            fetchUpdatedAirmeet();
        }
    }, [hasLoaded, isReadyForOnline]);

    useEffect(() => {
        closeCookieBanner(); // to close airmeet cookie popup whenever airmeet is loaded and currentstate changes to prevent popup inside liveairmeet in edge cases
    }, [hasLoaded, currentState]);

    const peopleTabConfig =
        featuresConfig && featuresConfig[ALL_FEATURES_CONFIG.PEOPLE_TAB_CONFIG];

    const peopleTabSource = loaded
        ? peopleTabConfig?.source || PEOPLE_TAB_SOURCE_CONFIG.LIVE
        : null;

    const isUsingOnlineUserNode =
        PEOPLE_TAB_SOURCE_CONFIG.LIVE === peopleTabSource ||
        PEOPLE_TAB_SOURCE_CONFIG.LIVE_PATCH === peopleTabSource;

    //----- heartbeat interception logic ---- //
    const { heartbeatObserver } = useHeartBeatObserver();
    const currentAirmeetStatus = currentAirmeet?.status;
    useEffect(() => {
        if (currentAirmeetStatus === AIRMEET_STATUS.FINISHED)
            heartbeatObserver.current &&
                heartbeatObserver.current.onAirmeetFinished(
                    AIRMEET_STATUS.FINISHED
                );
    }, [currentAirmeetStatus]);

    const interceptCallback = (method, args) => {
        let event = {};
        let context_visit_id = '';
        const data = args[0];
        switch (method) {
            case analyticsMethodsToIntercept.ENTERED_AIRMEET:
                event = {};
                break;
            case analyticsMethodsToIntercept.ATTENDEE_EXIT:
                event = {};
                break;
            case analyticsMethodsToIntercept.ATTENDEE_JOIN:
                const { session_id, sessionVisitId } = data;
                context_visit_id = sessionVisitId;
                event = { session_visit_id: sessionVisitId, session_id };
                break;
            case analyticsMethodsToIntercept.ATTENDEE_JOINED_TABLE:
                const tableData = { ...args[9], tableId: args[3] };
                const { table_visit_id, tableId } = tableData;
                context_visit_id = table_visit_id;
                event = { table_visit_id, tableId };
                break;
            case analyticsMethodsToIntercept.ATTENDEE_LEFT_TABLE:
                event = {};
                break;
            case analyticsMethodsToIntercept.BOOTH_PAGE:
                const boothData = { action: args[0], ...args[1] };
                const { action, booth_id, booth_visit_id } = boothData;
                context_visit_id = booth_visit_id;
                if (action === 'boothJoined') {
                    event = { booth_id, booth_visit_id };
                } else if (action === 'boothLeft') {
                    event = {};
                }
                break;
            default:
                break;
        }

        if (heartbeatObserver.current)
            heartbeatObserver.current.sendHeartbeat(event, context_visit_id);
    };
    useEffect(() => {
        interceptFbAnalyticsLog(FirebaseLogger, interceptCallback);
    }, []);

    // ---- ENDS HERE ---- //

    const liveContext = {
        user: {
            ...user,
            storage: userStorage,
            isOnline,
        },
        setReadyForOnline,
        firebaseClient: firebaseClient,
        featureDataClients: {
            chat: chatFirebaseClient,
            reactions: reactionsFirebaseClient,
            tables: tableFirebaseClient,
            fluidSpace: fluidSpaceClient,
            [FEATURE_NAMES.USER_PROFILE]: userProfileClient,
            stage: stageFirebaseClient,
            [FEATURE_NAMES.USER_PRESENCE]: userPresenceClient,
            [FEATURE_NAMES.BOOTH_TABLES]: boothTablesClient,
            [FEATURE_NAMES.BREAKOUT_TABLES]: breakoutTablesClient,
        },
        rtmClient: rtmClient,
        isSupportedBrowser,
        isEventCloudHostUser: PermissionUtils.isEventCloudHost(),
        airmeet: {
            airmeetId,
            data,
            featureFlags,
            fetchBookmarkedSessions,
        },
        loaded,
        controls: enabledControls,
        features: enabledFeatures,
        platformFeatures: enabledPlatformFeatures,
        featuresConfig,
        platformConfig,
        defaultDevices,
        setDefaultDevices,
        isSessionCloudHostLive,
        isSessionScreenRecorderCloudHostLive,
        fullScreen,
        setFullScreen,
        setExitButtonClick,
        setLocalStreamsReconnecting,
        localStreamsReconnecting,
        hasRestrictedDurations,
        isUGCEnabled,
        isLiveAnnouncementEnabled,
        isClosedCaptioningEnabled,
        enableCCSubscriptionViaFirebase: isCCSubscriptionViaFirebaseEnable,
        heartbeatObserver,
        redirectToPublicPage,
    };

    // Live attendees
    const {
        isConnected,
        connectionState,
        foundNewerLogin,
    } = useMemberOnlineState({
        airmeetId,
        isReadyForOnline,
        firebaseClient,
        user,
        isUsingOnlineUserNode,
        liveContext,
    });

    if (liveContext.user) {
        liveContext.user.isConnected = isConnected;
    }
    if (liveContext.airmeet) {
        liveContext.airmeet.isConnected = isConnected;
        liveContext.airmeet.connectionState = connectionState;
    }

    const { observer: userProfileObserver } = useDataReader(
        FEATURE_NAMES.USER_PROFILE,
        {
            liveContextOverride: liveContext,
        }
    );
    const userRedirectURL = `/e/${airmeetId}`;

    const userService = useMemo(() => {
        if (!userProfileObserver) {
            return null;
        }
        return UserService.newInstance({
            observer: userProfileObserver,
            airmeetId,
        });
    }, [airmeetId, userProfileObserver]);

    useAirmeetUserServicePrefetch(currentAirmeet, userService);

    const { observer: userPresenceObserver } = useDataReader(
        FEATURE_NAMES.USER_PRESENCE,
        {
            liveContextOverride: liveContext,
        }
    );

    useEffect(() => {
        if (!userPresenceObserver) {
            return null;
        }
        UserPresenceService.newInstance({
            observer: userPresenceObserver,
            airmeetId,
        });
    }, [userPresenceObserver, airmeetId]);

    useEffect(() => {
        if (foundNewerLogin) {
            return;
        }
        const haveLoggedInAt = AuthService.loggedInAt;
        const beforeCloseWindow = (e) => {
            if (localStorage.getItem('active-screen-share')) {
                localStorage.removeItem('active-screen-share');
            }
            const wantedExitId = uuid(); // To link wantedExit and cancelledExit event
            const sessionVisitId = sessionStorage.getItem(SESSION_VISIT_ID_KEY);
            logger.info('WantedExit Event Triggered');
            FirebaseLogger.wantedExit({
                attendeeId: user.id,
                airmeetId,
                airmeetVisitId,
                wantedExitId,
                sessionVisitId,
                airmeetSessionId: currentSessionRef.current?.sessionid,
            });
            let pressedCancel = false;
            var confirmationMessage = 'Are you sure you want to leave?';
            // as per Manu's suggestion
            setTimeout(function () {
                setTimeout(function () {
                    pressedCancel = true;
                    setExitButtonClick(false);
                    logCancelClickEvent(wantedExitId);
                }, 1000);
                const airmeetUtilInstance = getAirmeetUtilInstance();
                if (pressedCancel === false && airmeetUtilInstance) {
                    const rtcClient = clientInUse();
                    Object.keys(rtcClient).forEach((key) => {
                        if (airmeetUtilInstance[key] != null) {
                            const state = airmeetUtilInstance[
                                key
                            ].client.getConnectionState();
                            if (state == 'CONNECTING') {
                                QOEUserDropLoggerDirect(
                                    userDropCases.REFRESHED_WHILE_RECONNECTING
                                        .label,
                                    key,
                                    airmeetUtilInstance[key]
                                );
                            }
                        }
                    });
                }
            }, 1);
            if (
                ignoreBeforeWindow.current ||
                skipLeaveConfirmation.current ||
                (haveLoggedInAt && !AuthService.loggedInAt)
            ) {
                return;
            }
            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Webkit, Safari, Chrome etc.
        };

        PermissionUtils.assignValues({
            firebaseClient,
            airmeetId,
            authUser: user,
            origin: 'LiveAirmeet:before fetching updated airmeet data',
        });

        fetchUpdatedAirmeet({
            isFullLoad: true,
        })
            .then(({ airmeet, isAllowed }) => {
                infoRequestLoadTime.current = Date.now();

                if (airmeet.error || !isAllowed) {
                    logger.info(
                        'LiveContext: Redirecting because of airmeet error!!',
                        airmeet.error,
                        isAllowed
                    );
                    return redirectToPublicPage(false, false, !isAllowed);
                }
                const { event_sub_type, event_type } = airmeet || {};

                const isHybridEvent =
                    event_sub_type === EVENT_SUB_TYPES.HYBRID_CONFERENCE &&
                    event_type === EVENT_TYPES.CONFERENCE;

                infoRequestLoadTime.current = Date.now();
                const { SPEAKER, HOST, ORGANIZER } = USER_ROLE;
                return user.guest ||
                    PermissionUtils.isEventCloudHost() ||
                    ([SPEAKER, HOST, ORGANIZER].includes(
                        UserRoles.getUserEventRole(user?.id)
                    ) &&
                        !exhibitorToken)
                    ? Promise.resolve({
                          payload: { isHybridEvent },
                      })
                    : dispatch(
                          verifyAttendeeAccess({
                              airmeetId,
                              exhibitorToken,
                          })
                      );
            })
            .then(({ payload }) => {
                const {
                    access_type = '',
                    additional_context = {},
                    code = '',
                    json,
                    isHybridEvent = false,
                } = payload;
                logger.info(
                    'LiveContext: After calling verify magic link!',
                    payload
                );
                if (payload?.json?.code === 'BLOCKED') {
                    history.replace(userRedirectURL);
                }
                if (
                    isHybridEvent &&
                    UserRoles.getUserEventRole(user?.id) ===
                        USER_ROLE.SPEAKER &&
                    user?.attendee_type === USER_ATTENDANCE_TYPES.IN_PERSON
                ) {
                    try {
                        const eventDetail = JSON.parse(
                            sessionStorage.getItem(MAGIC_LINK_DETAILS) || ''
                        );
                        if (eventDetail?.isSpeaker && eventDetail?.code) {
                            logger.info(
                                'Redirecting Speaker to speaker magic link url because of in-person attendance type.'
                            );
                            return history.push(
                                `/event/session?t=${eventDetail?.code}`
                            );
                        } else {
                            logger.info(
                                'Redirecting user to public event page, Not allowed to enter because of in-person attendance type.'
                            );
                            return redirectToPublicPage(true);
                        }
                    } catch (e) {}
                }
                if (access_type === 'EXHIBITOR') {
                    dispatch(
                        updateExhibitor({
                            validToken:
                                additional_context.isExhibitorTokenValid,
                            booth: additional_context.exhibitorBoothId,
                        })
                    );
                }

                if (json?.code === 'INVALID_ATTENDANCE_TYPE') {
                    logger.info(
                        'Redirecting user to public event page because of invalid attendance type.'
                    );
                    return redirectToPublicPage(true);
                }

                if (
                    (deepLinkParamPresent &&
                        !isValidDeepLink({
                            boothId:
                                additional_context &&
                                additional_context.exhibitorBoothId,
                        })) ||
                    [
                        'INVALID_EXHIBITOR_TOKEN_AND_ACCESS_TOKEN',
                        'INVALID_BOOTH_AND_TOKEN',
                        'INVALID_TOKEN',
                    ].includes(code)
                ) {
                    logger.info(
                        'Redirecting user to public event page',
                        deepLinkParamPresent,
                        code
                    );
                    return redirectToPublicPage(true, true);
                }

                setFirstAPICallCompleted(true);
                window.addEventListener('beforeunload', beforeCloseWindow);

                if (json?.code === 'INVALID_ATTENDANCE_TYPE') {
                    try {
                        const eventDetail = JSON.parse(
                            sessionStorage.getItem(MAGIC_LINK_DETAILS) || ''
                        );
                        if (eventDetail?.isSpeaker && eventDetail?.code) {
                            return history.push(
                                `/event/session?t=${eventDetail?.code}`
                            );
                        }
                    } catch (e) {}
                    return redirectToPublicPage(true);
                }

                if (
                    (deepLinkParamPresent &&
                        !isValidDeepLink({
                            boothId:
                                additional_context &&
                                additional_context.exhibitorBoothId,
                        })) ||
                    [
                        'INVALID_EXHIBITOR_TOKEN_AND_ACCESS_TOKEN',
                        'INVALID_BOOTH_AND_TOKEN',
                        'INVALID_TOKEN',
                    ].includes(code)
                ) {
                    return redirectToPublicPage();
                }

                setFirstAPICallCompleted(true);
                window.addEventListener('beforeunload', beforeCloseWindow);
            })
            .catch((err) => {
                logger.error('Error in loading combined API', err);
                setFirstAPIFailed(true);
            });

        return () => {
            window.removeEventListener('beforeunload', beforeCloseWindow);
            const airmeetUtilInstance = getAirmeetUtilInstance();
            if (!airmeetUtilInstance) {
                return;
            }
            // Cleanup
            airmeetUtilInstance.beforeUnload();

            clearAirmeetUtilInstance();
        };
    }, [airmeetId, foundNewerLogin]);

    useEffect(() => {
        if (currentAirmeet && !isAllowedToEnter) {
            // Use window.location for redirections to event public
            logger.info(
                'Redirect to event public page due to not have permission to enter'
            );
            redirectToPublicPage();
            return;
        }
    }, [currentAirmeet, isAllowedToEnter, redirectToPublicPage]);

    useEffect(() => {
        const onUnLoadWindow = (e) => {
            const airmeetUtilInstance = getAirmeetUtilInstance();
            if (isExitButtonClick) {
                const rtcClient = clientInUse(currentState);
                Object.keys(rtcClient).forEach((key) => {
                    const state = airmeetUtilInstance?.[
                        key
                    ]?.client.getConnectionState();
                    if (state == 'CONNECTED') {
                        QOEUserDropLoggerDirect(
                            userDropCases.GENUINE_LEAVE.label,
                            key,
                            airmeetUtilInstance[key],
                            currentState
                        );
                    }
                });
            }

            airmeetUtilInstance && airmeetUtilInstance.beforeUnload();
        };
        window.addEventListener('unload', onUnLoadWindow);
        return () => {
            window.removeEventListener('unload', onUnLoadWindow);
        };
    }, [isExitButtonClick, currentState]);

    /*
    Start a error timer when firebase client is created
    Cancel the timer when has loaded becomes true
    */
    const errorTaskCancelRef = useRef(null);
    useEffect(() => {
        if (hasLoaded) {
            errorTaskCancelRef.current && errorTaskCancelRef.current();
            return;
        }

        if (!firebaseClient) {
            return;
        }
        //if firebase client is not intialised it will return before error scheduler

        errorTaskCancelRef.current = firebaseClient.startErrorEmitTimer(
            FIREBASE_ERROR_MESSAGES.UNABLE_TO_LOAD_IN_TIME
        );

        let errorReason = !hasBrowserCheckLoaded
            ? 'hasBrowserCheckLoaded'
            : !rtmClient
            ? 'rtmClient'
            : !loaded
            ? 'loaded'
            : !platformLoaded
            ? 'platformLoaded'
            : !isFirstAPICallCompleted
            ? 'isFirstAPICallCompleted'
            : currentState === LIVE_AIRMEET_STATE?.loading
            ? 'curretnAirmeetState is loading'
            : 'undef';
        logger.info(
            'has loaded not completed in given time due to falsy ' + errorReason
        );

        return () => {
            errorTaskCancelRef.current && errorTaskCancelRef.current();
        };
    }, [firebaseClient, hasLoaded]);

    return !isAllowedToEnter ? (
        <Loader type='logo' fullPage={true} />
    ) : webRTCFirewallDetectionStates.firebaseFailed.includes(
          firebaseConnectionStatus
      ) || isFirstAPIFailed ? (
        <NetworkErrorComponent
            errorType={NETWORK_ERROR_TYPES.firebaseError}
            userRole={data.userRole}
            airmeetId={airmeetId}
            attendeeId={user.id}
            device={deviceType}
        />
    ) : hasLoaded ? (
        foundNewerLogin ? (
            <JoinFromOtherPlace airmeetId={airmeetId} />
        ) : (
            <LiveAirmeet.Provider value={liveContext}>
                {isReadyForOnline && <EventPresence airmeetId={airmeetId} />}
                {children}
            </LiveAirmeet.Provider>
        )
    ) : appWebView ? (
        <Loader type='logo' fullPage={true} />
    ) : hasBrowserCheckLoaded && !PermissionUtils.isEventCloudHost() ? (
        <EventLoader
            currentState={LOADING_EVENTS_TYPES.networkCheck}
            airmeetId={airmeetId}
            attendeeId={user.id}
            userRole={data.userRole}
        />
    ) : (
        !hasBrowserCheckLoaded && (
            <EventLoader
                currentState={LOADING_EVENTS_TYPES.browserCheck}
                airmeetId={airmeetId}
                attendeeId={user.id}
                userRole={data.userRole}
            />
        )
    );
}

const EventPresence = React.memo(({ airmeetId }) => {
    useUserPresence(airmeetId, {
        shouldAddSelf: true,
        shouldAddListener: false,
        context: CONTEXT_TYPE.EVENT,
    });

    return null;
});

export default LiveAirmeet;
