import BetaTag from 'atoms/BetaTag/BetaTag';
import RestrictedAccountTooltip from 'atoms/RestrictedAccountTooltip';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import { Text } from 'foundations/themeV2/text';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';
import { cdnImage } from 'utils/core';
import { getCurrentTenantData } from 'utils/tenant';
import { Typography } from '../../foundations/typography';
import SVGSprite, { StyledSvgSprite } from '../general/SVGSprite';

export default function Tabs({ tabs: tabsProps = {}, activeId, handleMenu }) {
    return isEmpty(tabsProps) ? null : (
        <ul className='no-style'>
            {Object.keys(tabsProps || {}).map((elem) => {
                const {
                    id,
                    disabled,
                    displayText,
                    isSvg,
                    icon,
                    parentId,
                    childrenIds,
                    dependentId,
                    showPremiumIcon,
                    premiumText,
                    betaTag,
                    canClickComponent,
                    rightImg,
                } = tabsProps[elem];

                const isActive = [
                    elem,
                    dependentId,
                    ...(childrenIds || []),
                ].includes(activeId);

                return disabled ? null : (
                    <StyledLi
                        className={
                            isActive
                                ? 'active comm-dash_nav-item'
                                : 'comm-dash_nav-item'
                        }
                        key={`comm-dash_nav-item_${id}`}
                        id={`comm-dash_nav-item_${id}`}
                        onClick={(e) =>
                            handleMenu && canClickComponent
                                ? handleMenu(e, elem)
                                : noop
                        }
                        isActive={isActive}
                        hasParent={parentId}
                        hasChildren={
                            Array.isArray(childrenIds) && childrenIds.length > 0
                        }
                    >
                        {!!isSvg && canClickComponent && (
                            <SVGSprite
                                icon={icon}
                                style={{
                                    width: '18px',
                                    height: '18px',
                                }}
                            />
                        )}
                        {!isSvg && canClickComponent && (
                            <Icon
                                icon={icon}
                                style={{
                                    width: '18px',
                                    height: '18px',
                                }}
                            />
                        )}
                        {!canClickComponent && (
                            <RestrictedAccountTooltip
                                svgWidth='18px'
                                svgHeight='18px'
                                marginRight='0px'
                                tooltipPosition='topRight'
                                minWidth={212}
                            />
                        )}
                        <Typography
                            variant='subtext1'
                            py={2}
                            px={2}
                            color='ambience.3'
                        >
                            {displayText}
                        </Typography>{' '}
                        {!rightImg && betaTag?.title ? (
                            <BetaTag
                                v2={true}
                                tooltipText={betaTag.title}
                                tooltipPortal={true}
                                tooltipChildren={
                                    <Typography
                                        variant={'subtext1'}
                                        color='ambience.12'
                                        pt={1}
                                    >
                                        This is a phase at{' '}
                                        {getCurrentTenantData('name')} where we
                                        provide early access to our latest
                                        features, and{' '}
                                        <AnchorLink href='mailto:feedback@airmeet.com'>
                                            collect feedback
                                        </AnchorLink>{' '}
                                        to make our product robust for users.
                                    </Typography>
                                }
                                tooltipContainerStyle={{
                                    maxWidth: '332px',
                                    transition: 'none',
                                }}
                                tagContainerStyle={betaTag.tagContainerStyle}
                            />
                        ) : null}
                        {!rightImg && showPremiumIcon && (
                            <TabPremiumTag hasText={!!premiumText}>
                                <StyledSvgSprite
                                    icon='icon-premium-diamond'
                                    height='20px'
                                    width='20px'
                                    fill={
                                        premiumText
                                            ? 'text.other.primary'
                                            : 'white'
                                    }
                                />
                                {!!premiumText && (
                                    <Text
                                        variant='caption10-bold'
                                        color='text.other.primary'
                                        ml='x1'
                                    >
                                        {premiumText}
                                    </Text>
                                )}
                            </TabPremiumTag>
                        )}
                        {rightImg && (
                            <Img src={cdnImage(rightImg)} alt={displayText} />
                        )}
                    </StyledLi>
                );
            })}
        </ul>
    );
}

const StyledLi = styled.li`
    ${({ hasParent, isActive, theme }) =>
        hasParent &&
        `
        margin-top: 0 !important;
        padding-left: 45px !important;
        ${
            isActive &&
            `
            & > p {
                color: ${theme.colors.accentPrimary[0]} !important;
            }
        `
        }
        &::after {
            display: none;
        }
    `}
    ${({ hasChildren }) =>
        hasChildren &&
        `
            margin-bottom: 0 !important;
    `}
`;

const TabPremiumTag = styled(FlexDiv)`
    margin-left: auto;
    margin-right: 15px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background-color: ${({ theme, hasText }) =>
        hasText ? theme.colors.sem.warning : theme.colors.accentSun[3]};
    & > svg {
        height: 14px;
        width: auto;
    }
    ${({ hasText, theme }) =>
        hasText &&
        `
            height: 1rem;
            border-radius: ${theme.radii.x2};
            width: auto;
            padding: 0 ${theme.space.x1};
            border: 1px solid ${theme.colors.border.default1};
            margin-left: ${theme.space.x2};
        `}
`;

export const AnchorLink = styled.a`
    color: ${({ theme }) => theme.colors.accentPrimary[1]};
`;

const Img = styled.img`
    height: ${({ theme }) => theme.space.x6};
    width: auto;
`;
