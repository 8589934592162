import keys from 'locale/keys';
import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import { TRANSACTION_FAIL_REASONS } from 'utils/constants/common';

const { LIVE_VIDEO_ANNOUNCEMENT } = FEATURE_NAMES;
const wrapTransaction = async (transactionFunction, logger) => {
    let err;
    const errorMessage = (error) => {
        if (!error) {
            return false;
        }
        logger.error('Transaction failed', { reason: error.message, error });
        switch (error.message) {
            case TRANSACTION_FAIL_REASONS.DISCONNECT:
                return keys.TOASTS_ERROR_NETWORK_UNAVAILABLE_TRY_AGAIN;
            default:
                return '';
        }
    };
    try {
        const { error, committed } = await transactionFunction();
        if (error) {
            err = errorMessage(error);
        } else {
            err = !committed;
        }
    } catch (error) {
        err = errorMessage(error);
    }

    return { error: err };
};

const liveVideoAnnouncementWriter = async ({
    client,
    metaData,
    payload,
    logger,
}) => {
    const { action, onError, userId, path } = metaData;
    if (action === FEATURE_ACTIONS[LIVE_VIDEO_ANNOUNCEMENT].LEAVE) {
        logger.info('Leaving/cleanup announcement session');
        await client.runTransaction(path, (value) => {
            return value ? payload : value;
        });
        return client.cancelOnDisconnect(path);
    }

    if (action === FEATURE_ACTIONS[LIVE_VIDEO_ANNOUNCEMENT].RESERVE) {
        const reserve = async (payload, onError) => {
            logger.info('Reserving announcement session', payload);
            const { error } = await wrapTransaction(
                () =>
                    client.runTransaction(
                        path,
                        function (data) {
                            if (!data || data.broadcaster === userId) {
                                client.replaceOnDisconnect(path, null);
                                return payload;
                            }
                            return;
                        },
                        null,
                        true
                    ),
                logger
            );

            if (error) {
                onError && onError(typeof error === 'string' ? error : '');
                return false;
            } else {
                return true;
            }
        };

        return reserve(payload, onError);
    }
};

export default liveVideoAnnouncementWriter;
