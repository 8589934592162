import {
    ROLES_RESPONSE,
    SET_IS_ORGANIZER_MAPPED_TO_EVENT,
    TEAM_MEMBERS_RESPONSE,
    USER_PERMISSIONS_RESPONSE,
} from 'store/actions/community/member';
import { populateNameFromFirstLastName } from 'utils/users';

const initialState = {
    teamMembers: [],
    userPermissions: {},
    roleName: null,
    allRoles: [],
    utilizedLimitCommunityManagers: 0,
    utilizedLimits: 0,
    utilizedLimitsEventManagers: 0,
    utilizedLimitsInvitedMembers: 0,
    isCurrentUserRoleMappedToEvent: null,
};

function members(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case TEAM_MEMBERS_RESPONSE:
            return {
                ...state,
                teamMembers: error
                    ? []
                    : (payload.team_members || []).map((member) => {
                          // Derive name from first/last name if name is not present
                          return populateNameFromFirstLastName(member);
                      }),
                utilizedLimitCommunityManagers: error
                    ? []
                    : payload.utilized_limit_community_managers,
                utilizedLimits: error ? [] : payload.utilized_limits,
                utilizedLimitsEventManagers: error
                    ? []
                    : payload.utilized_limits_event_managers,
                utilizedLimitsInvitedMembers: error
                    ? []
                    : payload.utilized_limits_invited_members,
            };
        case ROLES_RESPONSE:
            return {
                ...state,
                allRoles: error ? [] : payload.data,
            };
        case USER_PERMISSIONS_RESPONSE:
            return {
                ...state,
                userPermissions: error ? {} : payload.permissions,
                roleName: error ? null : payload.role_name,
            };
        case SET_IS_ORGANIZER_MAPPED_TO_EVENT:
            return {
                ...state,
                isCurrentUserRoleMappedToEvent: payload,
            };
        default:
            return state;
    }
}

export default members;
