import createReducer from '../../utils/createReducer';
import { INTEGRATION_KEY_RESPONSE } from '../actions/cmDashboard/integrationTab';

const initialState = {
    integrationTableData: [],
};

const handlers = {
    [INTEGRATION_KEY_RESPONSE]: (state = initialState, { payload }) => {
        const { response, error } = payload;
        return { ...state, integrationTableData: error ? [] : response };
    },
};

export default createReducer(initialState, handlers);
