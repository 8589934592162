import SVGIcon from 'foundations/icon';
import useTheme from 'hooks/useTheme';
import React from 'react';

export default function PollCustomArrow({
    onClick,
    direction,
    style,
    ...rest
}) {
    const { theme } = useTheme();

    return (
        <SVGIcon
            icon={`arrow_${direction}`}
            width={12}
            height={12}
            onClick={onClick}
            fill={theme.colors.ambience[4]}
            style={{
                position: 'absolute',
                top: '5px',
                right: direction === 'right' ? '0px' : '24px',
                cursor: 'pointer',
                '&:focus': {
                    outline: '0 !important',
                    border: '0 !important',
                    boxShadow: 'none',
                },
                ...(style || {}),
            }}
            {...rest}
        />
    );
}
