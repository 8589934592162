import OpacitySlider from 'atoms/RangeSlider/OpacitySlider';
import React from 'react';
import ImageSelector from '../../../widgets/ImageSelector';
import { DEFAULT_BACKGROUND_OPACITY, DEFAULT_PATTERNS } from './utils';

export default function PatternBackground({
    onChange,
    value,
    showOpacity = false,
}) {
    const opacity = value?.meta?.opacity || DEFAULT_BACKGROUND_OPACITY;

    const handleOpacityChange = (opacity) => {
        onChange({
            ...(value || {}),
            meta: { ...(value?.meta || {}), opacity },
        });
    };

    const handleImageChange = (image) => {
        onChange &&
            onChange({
                ...(value || {}),
                value: image?.id,
            });
    };

    return (
        <>
            <ImageSelector
                title='Choose your background pattern'
                subtitle='Pattern color applied same as ‘link and button color’'
                images={DEFAULT_PATTERNS}
                optionStyle={{
                    backgroundSize: '200%',
                    backgroundPosition: 'center top',
                }}
                onChange={handleImageChange}
                value={value?.value}
            />
            {showOpacity && (
                <OpacitySlider value={opacity} onChange={handleOpacityChange} />
            )}
        </>
    );
}
