import { REPLAY_ENTRY } from 'components/community/editPublicAirmeet/PostEventEntry/constants.js';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';

const usePostEventReplayAccess = ({ status, postEventEntry }) => {
    const isPostEventEntryAllowed =
        status === AIRMEET_STATUS.FINISHED &&
        postEventEntry !== REPLAY_ENTRY.nobody;

    const restrictPostEventNewRegistration =
        postEventEntry === REPLAY_ENTRY.registered;

    return {
        isPostEventEntryAllowed,
        restrictPostEventNewRegistration,
    };
};

export default usePostEventReplayAccess;
