import { useEffect } from 'react';
import { removeDriftChat } from './driftChatScript';
import { addFreshChat } from './freshChatScripts';

function useChatWidget() {
    useEffect(() => {
        addFreshChat();
        removeDriftChat();
    }, []);
}

export default useChatWidget;
