import LiveAirmeetContext from 'context/LiveAirmeet';
import { useStageRTCContext } from 'context/StageRTCProvider';
import useRaiseHandActions, {
    useUserRaiseHandRequest,
} from 'hooks/live-airmeet/useRaiseHandActions';
import useDataWriter from 'hooks/useDataWriter';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import UserIdService from 'services/users/UserIdService';
import { noop } from 'utils/constants/common';
import { Events } from 'utils/constants/containers/airmeet';
import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import {
    getCustomMediaUserId,
    getScreenShareUserId,
} from 'utils/constants/live-airmeet';
import { getUserInfo, USER_ROLE } from 'utils/constants/users';
import firebaseLogger from 'utils/firebaseLogger';
import { logger } from 'utils/logger';
import PermissionUtils from 'utils/permission-utils';
import { isAttendee } from 'utils/userAccessControl';
import { includesRole } from 'utils/users';

const { HOST, ORGANIZER, SPEAKER } = USER_ROLE;

const { ADD, REMOVE, ON_DISCONNECT } = FEATURE_ACTIONS[
    FEATURE_NAMES.STREAM_PUBLISHER
];

const useStreamPublisher = (
    airmeetId,
    channelName,
    idSeq,
    userStageRole,
    is_custom_registration_enabled,
    isLive,
    sessionId,
    stageService,
    allowToPublish
) => {
    const [streamPublisher, setStreamPublisher] = useState({});
    const {
        featureDataClients: { stage: firebaseClient },
        user,
        airmeet,
    } = useContext(LiveAirmeetContext);
    const myRequest = useUserRaiseHandRequest(sessionId, user?.id);
    const handRaiseService = useRaiseHandActions({
        sessionId,
        airmeetId,
        user,
    });
    const isAllowToPublishForAttendee = useMemo(() => {
        if (!myRequest) {
            return false;
        }
        return (
            handRaiseService.isAccepted(myRequest) ||
            handRaiseService.isActive(myRequest)
        );
    }, [myRequest, handRaiseService]);

    const allowToAddInFirebase =
        (allowToPublish || isAllowToPublishForAttendee) &&
        !PermissionUtils.isSessionCloudHost();
    const emitter = firebaseClient.getEmitter();

    const methods = useRef({});
    const streamData = useRef({});
    const setPublisher = useRef(noop);

    const networkConnection = useRef({
        isConnectionEstablished: false,
        connectionStatus: false,
    });

    methods.current.channelNameMissingLog = (msg) => {
        logger.error(`Channel name is missing : ${msg}`, {
            userStageRole,
            isLive,
        });
    };

    const { write: dataWriter } = useDataWriter(FEATURE_NAMES.STREAM_PUBLISHER);

    const channelPublisherKey = channelName
        ? `${airmeetId}/meta-data/broadcastChannel/${channelName}/channelPublisher`
        : null;

    const userInfo = useMemo(
        () =>
            getUserInfo(
                user,
                { role: userStageRole },
                is_custom_registration_enabled
            ),
        [is_custom_registration_enabled, userStageRole, user]
    );

    setPublisher.current = async ({ stream, type }) => {
        const data = {
            type: type,
            settings: stageService.getStreamInfo(stream)?.settings || {},
            mediaStatus: stream.mediaStatus || 'camera',
            userId: user?.id || '',
            user: userInfo,
            platform: 'web',
        };
        if (type === 'user') delete data.mediaStatus;
        else delete data.platform;

        try {
            await dataWriter(
                {
                    data,
                },
                {
                    action: ADD,
                    channelPublisherKey,
                    id: stream.getId(),
                }
            );

            const streamPublishing =
                type === 'user'
                    ? stageService?.hasUserStreamPublished()
                    : stageService.getStream(stream.getId());

            // validating again once the transaction is completed.
            if (!streamPublishing) {
                logger.info(
                    `Localstream ${type} not found, clearing channel publisher`
                );
                removeChannelPublisher({ id: stream.getId(), type });
            }
        } catch (err) {
            logger.error(`Error while setting publisher, ${err}`);
        }
    };
    methods.current.setChannelPublisher = ({ localStream }) => {
        if (!localStream) {
            return;
        }
        if (PermissionUtils.isEventCloudHost()) {
            return;
        }
        if (!allowToAddInFirebase) {
            logger.info(
                'User is not allowed to add user data in firebase channelPublisher node'
            );
            return;
        }
        if (!channelPublisherKey) {
            return methods.current.channelNameMissingLog(
                'on setting channel publisher'
            );
        }
        const type = 'user';
        streamData.current.isPublishingStream = localStream.getId();
        setPublisher.current({ stream: localStream, type });
    };

    const setChannelPublisher = (params = {}) => {
        methods.current.setChannelPublisher(params);
    };

    methods.current.setStreamsPublisher = ({ stream }, type) => {
        if (!channelPublisherKey)
            return methods.current.channelNameMissingLog(
                `on setting ${type} streams publisher`
            );
        if (stream) {
            if (type === 'screenShare')
                streamData.current.isSharingScreen = stream.getId();
            if (type === 'customMedia')
                streamData.current.isSharingCustomMedia = stream.getId();
            setPublisher.current({ stream, type });
        } else {
            if (type === 'screenShare' && streamData.current.isSharingScreen) {
                dataWriter(
                    { data: null },
                    {
                        action: REMOVE,
                        id: streamData.current.isSharingScreen,
                        channelPublisherKey,
                    }
                );
                streamData.current.isSharingScreen = null;
            }
            if (
                type === 'customMedia' &&
                streamData.current.isSharingCustomMedia
            ) {
                dataWriter(
                    { data: null },
                    {
                        action: REMOVE,
                        id: streamData.current.isSharingCustomMedia,
                        channelPublisherKey,
                    }
                );
                streamData.current.isSharingCustomMedia = null;
            }
        }
    };

    const removeChannelPublisher = ({ id, type = 'user' }) => {
        if (!channelPublisherKey)
            return methods.current.channelNameMissingLog(
                'on removing channel publisher'
            );
        if (['number', 'string'].includes(typeof id)) {
            //Ignore this case
        } else {
            id = id.getId();
        }
        if (type === 'user') {
            streamData.current.isPublishingStream = null;
        }
        if (type === 'screenShare') {
            streamData.current.isSharingScreen = null;
        }
        if (type === 'customMedia') {
            streamData.current.isSharingCustomMedia = null;
        }
        dataWriter(
            { data: null },
            {
                action: REMOVE,
                channelPublisherKey,
                id,
            }
        );
    };

    const updatePublisher = ({ value }) => {
        if (!channelPublisherKey)
            return methods.current.channelNameMissingLog('on update publisher');

        let publishers = value || {};
        for (let key in publishers) {
            let publisherData = publishers[key];
            let user = publisherData?.user || {
                id: publisherData?.userId || publisherData?.id,
            };
            UserIdService.getInstance({ airmeetId }).onUserIdDetailsLoaded(
                key,
                user
            );
        }
        setStreamPublisher(publishers);
    };

    const updateChannelPublisherOnDisconnect = (id) => {
        if (!channelPublisherKey)
            return methods.current.channelNameMissingLog(
                'on update channel publisher on disconnect'
            );
        if (id) {
            dataWriter(
                { data: null },
                {
                    action: ON_DISCONNECT,
                    id,
                    channelPublisherKey,
                }
            );
        }
    };

    useEffect(() => {
        return () => {
            if (!channelPublisherKey) return;
            if (streamData.current.isPublishingStream) {
                removeChannelPublisher({
                    id: streamData.current.isPublishingStream,
                });
            }

            if (streamData.current.isSharingScreen) {
                removeChannelPublisher({
                    id: streamData.current.isSharingScreen,
                    type: 'screenShare',
                });
            }
            if (streamData.current.isSharingCustomMedia) {
                removeChannelPublisher({
                    id: streamData.current.isSharingCustomMedia,
                    type: 'customMedia',
                });
            }
        };
    }, [channelPublisherKey]);

    useEffect(() => {
        // Reset the local user channel publisher value if not set in firebase

        const localStream = stageService
            ? stageService.getLocalUserStream()
            : null;
        const hasPublished = stageService ? stageService.hasPublished() : null;
        const shouldAdd =
            airmeet.isConnected === true &&
            allowToAddInFirebase &&
            !isAttendee() &&
            channelPublisherKey &&
            !!stageService;

        const shouldAddUserStream =
            shouldAdd &&
            streamData.current.isPublishingStream &&
            localStream &&
            hasPublished &&
            !streamPublisher[localStream.getId()];
        if (shouldAddUserStream) {
            setChannelPublisher({ localStream });
        }
    });

    useEffect(() => {
        if (
            !allowToAddInFirebase &&
            !isAttendee() &&
            streamPublisher[idSeq] &&
            channelPublisherKey
        ) {
            removeChannelPublisher({ id: idSeq });
        }
    }, [allowToAddInFirebase, isAttendee(), channelPublisherKey]);

    useEffect(() => {
        if (!channelPublisherKey)
            return methods.current.channelNameMissingLog(
                'on attaching stream events'
            );
        const setShareScreenPublisher = (streamParams) => {
            if (
                streamParams &&
                streamParams.stream &&
                getScreenShareUserId(streamParams.stream.getId()) === idSeq
            ) {
                methods.current.setStreamsPublisher(
                    streamParams,
                    'screenShare'
                );
            } else if (
                streamParams &&
                streamParams.id &&
                getScreenShareUserId(streamParams.id) === idSeq &&
                !stageService.hasScreenStream()
            ) {
                methods.current.setStreamsPublisher({}, 'screenShare');
            }
        };

        const setCustomMediaPublisher = (streamParams) => {
            logger.info('setCustomMediaPublisher', streamParams);
            if (
                streamParams &&
                streamParams.stream &&
                getCustomMediaUserId(streamParams.stream.getId()) === idSeq
            ) {
                methods.current.setStreamsPublisher(
                    streamParams,
                    'customMedia'
                );
            } else if (
                streamParams &&
                streamParams.id &&
                getCustomMediaUserId(streamParams.id) === idSeq
            ) {
                methods.current.setStreamsPublisher({}, 'customMedia');
            }
        };

        const removeScreenShare = (streamParams) => {
            if (
                parseInt(streamParams.userId) === idSeq &&
                streamData.current.isSharingScreen
            ) {
                removeChannelPublisher({
                    id: streamData.current.isSharingScreen,
                    type: 'screenShare',
                });
            }
        };

        stageService.on(Events.rtcLiveStreamPublished, setChannelPublisher);
        stageService.on(
            Events.rtcLiveStreamUnpublished,
            removeChannelPublisher
        );
        stageService.on(Events.streamAddedScreen, setShareScreenPublisher);
        stageService.on(Events.streamAddedCustomMedia, setCustomMediaPublisher);
        stageService.on(Events.streamRemoveScreen, removeScreenShare);
        stageService.on(
            Events.streamRemoveCustomMedia,
            setCustomMediaPublisher
        );

        emitter.on(`${channelPublisherKey}_value`, updatePublisher);

        if (firebaseClient.hasAlreadyLoadedDataRef(channelPublisherKey)) {
            updatePublisher({
                value: firebaseClient.getLoadedValues(channelPublisherKey),
            });
        }
        firebaseClient.getDataRef(channelPublisherKey);

        return () => {
            if (stageService && channelPublisherKey) {
                stageService.off(
                    Events.rtcLiveStreamPublished,
                    setChannelPublisher
                );
                stageService.off(
                    Events.rtcLiveStreamUnpublished,
                    removeChannelPublisher
                );
                stageService.off(
                    Events.streamAddedScreen,
                    setShareScreenPublisher
                );
                stageService.off(
                    Events.streamAddedCustomMedia,
                    setCustomMediaPublisher
                );
                stageService.off(Events.streamRemoveScreen, removeScreenShare);
                stageService.off(
                    Events.streamRemoveCustomMedia,
                    setCustomMediaPublisher
                );
            }

            if (emitter && channelPublisherKey) {
                emitter.off(`${channelPublisherKey}_value`, updatePublisher);
            }
        };
    }, [idSeq, channelPublisherKey]);

    useEffect(() => {
        if (streamData.current.isPublishingStream) {
            updateChannelPublisherOnDisconnect(
                streamData.current.isPublishingStream
            );
        }
    }, [streamData.current.isPublishingStream, channelPublisherKey]);

    useEffect(() => {
        if (streamData.current.isSharingScreen) {
            updateChannelPublisherOnDisconnect(
                streamData.current.isSharingScreen
            );
        }
    }, [streamData.current.isSharingScreen, channelPublisherKey]);

    useEffect(() => {
        if (streamData.current.isSharingCustomMedia) {
            updateChannelPublisherOnDisconnect(
                streamData.current.isSharingCustomMedia
            );
        }
    }, [streamData.current.isSharingCustomMedia, channelPublisherKey]);

    const logFirebaseEvent = (event) => {
        const logData = {
            userid: user.id,
            airmeet_id: airmeetId,
            user_role: userStageRole,
            session_id: airmeet.data.currentSession
                ? airmeet.data.currentSession.sessionid
                : null,
            location: airmeet.data.currentState,
        };
        firebaseLogger.connectionStatusEvents(event, logData);
    };

    useEffect(() => {
        if (!firebaseClient) {
            return;
        }
        if (!channelPublisherKey) {
            return methods.current.channelNameMissingLog(
                'on checking network connection status and setting stream publisher'
            );
        }

        const localStream = stageService?.getLocalUserStream();
        // Add in analytics if he is on the stage
        if (
            includesRole([HOST, SPEAKER, ORGANIZER], userStageRole) ||
            localStream
        ) {
            if (
                networkConnection.current.isConnectionEstablished === false &&
                airmeet.isConnected
            ) {
                logFirebaseEvent('connected');
                networkConnection.current.isConnectionEstablished = true;
                // Second condition is to check connection status has been changed or not
            } else if (
                airmeet.isConnected &&
                airmeet.isConnected !=
                    networkConnection.current.connectionStatus
            ) {
                logFirebaseEvent('reconnected');
            }

            if (airmeet.isConnected === false) {
                logFirebaseEvent('disconnected');
            }
            networkConnection.current.connectionStatus = airmeet.isConnected;
        }

        if (airmeet.isConnected === true) {
            if (localStream) {
                setChannelPublisher({ localStream });
            }

            if (stageService.hasScreenStream()) {
                const localStream = stageService?.getLocalScreenShareStream();

                methods.current.setStreamsPublisher(
                    { stream: localStream },
                    'screenShare'
                );
            }

            // store the data in live channel when switch channel executed
            if (stageService.hasPublishPreRecordedStream()) {
                const localStream = stageService?.getLocalPreRecordedStream();

                methods.current.setStreamsPublisher(
                    { stream: localStream },
                    'customMedia'
                );
            }

            if (streamData.current.isPublishingStream) {
                updateChannelPublisherOnDisconnect(
                    streamData.current.isPublishingStream
                );
            }

            if (streamData.current.isSharingScreen) {
                updateChannelPublisherOnDisconnect(
                    streamData.current.isSharingScreen
                );
            }

            if (streamData.current.isSharingCustomMedia) {
                updateChannelPublisherOnDisconnect(
                    streamData.current.isSharingCustomMedia
                );
            }
        }
    }, [
        firebaseClient,
        airmeet.isConnected,
        channelPublisherKey,
        stageService,
    ]);

    return streamPublisher ? streamPublisher : [];
};

export default useStreamPublisher;

export const useCustomMediaPublisher = () => {
    const {
        featureDataClients: { stage: firebaseClient },
        airmeet: {
            airmeetId,
            data: { userStageRole },
        },
        user,
    } = useLiveAirmeetContext();
    const { write: dataWriter } = useDataWriter(FEATURE_NAMES.STREAM_PUBLISHER);
    const { stageService, stageChannelId: channelName } = useStageRTCContext();

    const channelPublisherKey = channelName
        ? `${airmeetId}/meta-data/broadcastChannel/${channelName}/channelPublisher`
        : null;

    const methods = useRef({});
    const streamData = useRef({});
    methods.current.setStreamsPublisher = ({ stream }, type) => {
        if (!channelPublisherKey) return;
        if (stream) {
            streamData.current.isSharingCustomMedia = stream.getId();

            stream = stageService.getStream(stream.getId());

            if (!stream) return;
            firebaseClient.setData(`${channelPublisherKey}/${stream.getId()}`, {
                type: type,
                isAudioEnable: stream.isAudioOn(),
                isVideoEnable: stream.isVideoOn(),
                settings: stream
                    ? stageService.getStreamInfo(stream).settings || {}
                    : {},
                mediaStatus: stream.mediaStatus || 'camera',
                userId: user?.id || '',
                user: getUserInfo(user, { role: userStageRole }),
                time: firebaseClient.getServerTimestampRef(),
            });
        } else {
            firebaseClient.setData(
                `${channelPublisherKey}/${streamData.current.isSharingCustomMedia}`,
                null
            );
            streamData.current.isSharingCustomMedia = null;
        }
    };

    useEffect(() => {
        const setCustomMediaPublisher = (streamParams) => {
            logger.info('setCustomMediaPublisher', streamParams);
            if (
                streamParams &&
                streamParams.stream &&
                getCustomMediaUserId(streamParams.stream.getId()) ===
                    user?.id_seq
            ) {
                methods.current.setStreamsPublisher(
                    streamParams,
                    'customMedia'
                );
            } else if (
                streamParams &&
                streamParams.id &&
                getCustomMediaUserId(streamParams.id) === user?.id_seq
            ) {
                methods.current.setStreamsPublisher({}, 'customMedia');
            }
        };

        stageService.on(Events.streamAddedCustomMedia, setCustomMediaPublisher);

        stageService.on(
            Events.streamRemoveCustomMedia,
            setCustomMediaPublisher
        );

        return () => {
            if (stageService && channelPublisherKey) {
                stageService.off(
                    Events.streamAddedCustomMedia,
                    setCustomMediaPublisher
                );
                stageService.off(
                    Events.streamRemoveCustomMedia,
                    setCustomMediaPublisher
                );
            }
        };
    }, [channelPublisherKey, user?.id_seq]);

    // remove the video data from backstage channel id
    const removeChannelPublisher = ({ id, type = 'user' }) => {
        if (!channelPublisherKey)
            return methods.current.channelNameMissingLog(
                'on removing channel publisher'
            );
        if (['number', 'string'].includes(typeof id)) {
            //Ignore this case
        } else {
            id = id.getId();
        }
        if (type === 'customMedia') {
            streamData.current.isSharingCustomMedia = null;
        }
        dataWriter(
            { data: null },
            {
                action: REMOVE,
                channelPublisherKey,
                id,
            }
        );
    };

    useEffect(() => {
        return () => {
            if (!channelPublisherKey) return;
            if (streamData.current.isSharingCustomMedia) {
                removeChannelPublisher({
                    id: streamData.current.isSharingCustomMedia,
                    type: 'customMedia',
                });
            }
        };
    }, [channelPublisherKey]);
};
