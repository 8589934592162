import { SET_SHOW_LEAVE_SESSION_MODAL } from 'store/actions/leaveSession';
import { SET_LEAVE_SESSION_MODAL_RESPONSE } from 'store/actions/leaveSession';
import { RESET_LEAVE_SESSION_MODAL } from 'store/actions/leaveSession';

const initialState = {
    modalResponse: null,
    showModal: null,
};

function leaveSession(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case SET_SHOW_LEAVE_SESSION_MODAL:
            return {
                ...state,
                showModal: { ...state.showModal, ...payload },
                modalResponse: null,
            };
        case SET_LEAVE_SESSION_MODAL_RESPONSE:
            return {
                ...state,
                modalResponse: state.showModal,
                showModal: null,
            };
        case RESET_LEAVE_SESSION_MODAL:
            return {
                modalResponse: null,
                showModal: null,
            };
        default:
            return state;
    }
}

export default leaveSession;
