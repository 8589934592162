import { useCustomTranslation } from 'hooks/useCustomTranslation';
import { FLAG_NAMES } from 'hooks/useFeatureControl';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import keys from 'locale/keys';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import LangsSelect from './ClosedCaptions/LangsSelect';
import PrimaryLangSelect from './ClosedCaptions/PrimaryLangSelect';
import PanelSettings from './PanelSettings';
import { closedCaptionsConfigSelector } from 'store/selectors/liveAirmeet';

function CustomizeEventExperience({
    liveConfig,
    setPromptData,
    onChange,
    logEvent,
    isScheduleMeetingEnabled,
}) {
    const { t } = useCustomTranslation();
    const scheduleMeetingKey = 'hideScheduleMeetings';
    const leaderboardPointsFlagKey = 'leaderboardEarnPointsVisibility';
    const isLeaderboardEnabled = useSelector(
        (state) => state.leaderboard.isLeaderboardEnabled
    );
    const { resourceList } = useSelector((state) => state.resources);
    const {
        closed_captions: closeCaptionFlag,
        isCCTranslationEnabled,
    } = useSelector(closedCaptionsConfigSelector);
    const isResourceVisible = useMemo(
        () =>
            (resourceList || []).some(
                (resource) => resource?.visible_to_attendees
            ),
        [resourceList]
    );

    const {
        features,
        airmeet: {
            data: {
                currentAirmeet: {
                    status,
                    is_chat_gif_enabled,
                    is_advanced_people_search_enabled,
                },
            },
            featureFlags: { isSessionReplayEnabled, isLoungeAllowed },
        },
    } = useLiveAirmeetContext();

    const isPeopleSearchFirebaseFlagEnabled = features?.includes(
        FLAG_NAMES.ADVANCED_PEOPLE_SEARCH
    );

    const isResourceManagementEnabled = useSelector(
        (state) => state.lounge.airmeet.is_resource_management_enabled
    );

    const isAdvancedPeopleSearchEnabled = useSelector(
        (state) => !state.lounge.airmeet?.live_config?.hideAdvancedPeopleSearch
    );

    const EVENT_SETTINGS = useMemo(
        () => [
            ...(isScheduleMeetingEnabled
                ? [
                      {
                          key: 'hideScheduleMeetings',
                          label: t(keys.RHS_CUSTOMIZE_SCHEDULE_MEETING_TITLE),
                          hint: t(keys.RHS_CUSTOMIZE_SCHEDULE_MEETING_SUBTITLE),
                          isHidden: false,
                          isClearEnabled: false,
                      },
                  ]
                : []),
            {
                key: 'hideEventFeed',
                label: t(keys.RHS_CUSTOMIZE_FEED_LABEL),
                isHidden: false,
                isClearEnabled: true,
                clearTitle: t(keys.RHS_CUSTOMIZE_FEED_CLEAR_TITLE),
                clearDescription: t(keys.RHS_CUSTOMIZE_FEED_CLEAR_DESCRIPTION),
            },
            {
                key: 'hideEventPolls',
                label: t(keys.RHS_CUSTOMIZE_EVENT_POLLS_LABEL),
                isHidden: false,
                isClearEnabled: true,
                clearTitle: t(keys.RHS_CUSTOMIZE_EVENT_POLLS_CLEAR_TITLE),
                clearDescription: t(
                    keys.RHS_CUSTOMIZE_EVENT_POLLS_CLEAR_DESCRIPTION
                ),
            },
            is_chat_gif_enabled
                ? {
                      key: 'hideEventGifs',
                      isHidden: false,
                      label: t(keys.RHS_CHAT_GIFS),
                      hint: t(keys.RHS_CHAT_GIFS_TOOLTIP),
                      isClearEnabled: false,
                  }
                : {},

            isLoungeAllowed
                ? {
                      key: 'hideSocialLounge',
                      label: t(keys.LOUNGE_SOCIAL_LOUNGE),
                      isHidden: false,
                      isClearEnabled: false,
                      showPromptOnHide: true,
                      promptTitle: t(
                          keys.RHS_CUSTOMIZE_HIDE_SOCIAL_LOUNGE_PROMPT_TITLE
                      ),
                      promptMessage: t(
                          keys.RHS_CUSTOMIZE_SOCIAL_LOUNGE_PROMPT_MSG
                      ),
                  }
                : {},
            {
                key: 'hideEventAttendeeList',
                label: t(keys.RHS_CUSTOMIZE_PEOPLE_IN_EVENT_LABEL_V2),
                hint: t(keys.RHS_CUSTOMIZE_PEOPLE_IN_EVENT_HINT),
                isHidden: false,
                isClearEnabled: false,
            },
            {
                key: 'hidePeopleCount',
                label: t(keys.RHS_CUSTOMIZE_PEOPLE_COUNT_LABEL),
                hint: t(keys.RHS_CUSTOMIZE_PEOPLE_COUNT_HINT),
                isHidden: false,
                isClearEnabled: false,
                parentKey: 'hideEventAttendeeList',
            },
            ...(is_advanced_people_search_enabled ||
            isPeopleSearchFirebaseFlagEnabled
                ? [
                      {
                          key: 'hideAdvancedPeopleSearch',
                          label: t(
                              keys.RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_NEW_KEY
                          ),
                          hint: t(
                              keys.RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_KEY_HINT
                          ),
                          isHidden: false,
                          isClearEnabled: false,
                      },
                      {
                          key: 'onlyAttendeeCardSearchDisabled',
                          label: t(
                              keys.RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_LABEL
                          ),
                          hint: t(
                              keys.RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_HINT_NEW
                          ),
                          isHidden: !isAdvancedPeopleSearchEnabled,
                          isClearEnabled: false,
                          parentKey: 'hideAdvancedPeopleSearch',
                      },
                  ]
                : []),
            {
                key: 'hideDirectMessage',
                label: 'Direct Messages',
                hint: 'This will hide direct messages.',
                isHidden: false,
                isClearEnabled: false,
            },
            {
                key: 'leaderboardEarnPointsVisibility',
                label: t(keys.RHS_CUSTOMIZE_SHOW_LEADERBOARD_POINTS_LABEL),
                hint: t(keys.RHS_CUSTOMIZE_SHOW_LEADERBOARD_POINTS_HINT),
                isHidden: false,
                isClearEnabled: false,
            },
            ...(isSessionReplayEnabled
                ? [
                      {
                          key: 'isSessionPlaybackDisabled',
                          label: t(keys.RHS_CUSTOMIZE_SESSION_REPLAY_LABEL),
                          hint: t(keys.RHS_CUSTOMIZE_SESSION_REPLAY_HINT),
                          isHidden: false,
                          isClearEnabled: false,
                          notChangeChildSettings: true,
                          showDetails: true,
                          detailsHeading: t(
                              keys.RHS_CUSTOMIZE_SESSION_REPLAY_HEADING
                          ),
                          detailsSubHeading: t(
                              keys.RHS_CUSTOMIZE_SESSION_REPLAY_SUBHEADING
                          ),
                          isPostEventVisible: true,
                      },
                      {
                          key: 'showFeedForReplay',
                          label: t(
                              keys.RHS_CUSTOMIZE_SESSION_REPLAY_FEED_LABEL
                          ),
                          hint: t(keys.RHS_CUSTOMIZE_SESSION_REPLAY_FEED_HINT),
                          isHidden: false,
                          isClearEnabled: false,
                          parentKey: 'isSessionPlaybackDisabled',
                          isInverted: true,
                          isPostEventVisible: true,
                      },
                      {
                          key: 'showQnAForReplay',
                          label: t(keys.RHS_CUSTOMIZE_SESSION_REPLAY_QNA_LABEL),
                          hint: t(keys.RHS_CUSTOMIZE_SESSION_REPLAY_QNA_HINT),
                          isHidden: false,
                          isClearEnabled: false,
                          parentKey: 'isSessionPlaybackDisabled',
                          isInverted: true,
                          isPostEventVisible: true,
                      },
                      {
                          key: 'showPollsForReplay',
                          label: t(
                              keys.RHS_CUSTOMIZE_SESSION_REPLAY_POLLS_LABEL
                          ),
                          hint: t(keys.RHS_CUSTOMIZE_SESSION_REPLAY_POLLS_HINT),
                          isHidden: false,
                          isClearEnabled: false,
                          showDetails: true,
                          parentKey: 'isSessionPlaybackDisabled',
                          isInverted: true,
                          isPostEventVisible: true,
                      },
                  ]
                : []),
            {
                key: 'hideBookmarkCount',
                label: t(keys.SESSION_BOOKMARK_COUNT),
                hint: t(keys.SESSION_BOOKMARK_FEATURE_DESCRIPTION),
                isHidden: false,
                isClearEnabled: false,
            },
            ...(isResourceManagementEnabled
                ? [
                      {
                          key: 'hideResourcesTab',
                          label: t(keys.RHS_CUSTOMIZE_SHOW_RESOURCES_TAB_LABEL),
                          hint: t(
                              keys.RHS_CUSTOMIZE_SHOW_RESOURCES_TAB_TOOLTIP
                          ),
                          isHidden:
                              !isResourceManagementEnabled ||
                              !isResourceVisible,
                          isClearEnabled: false,
                          disabled: !isResourceVisible,
                          tooltip:
                              !isResourceVisible &&
                              t(keys.RHS_CUSTOMISE_RESOURCES_DISABLED),
                          isPostEventVisible: true,
                      },
                  ]
                : []),
            ...(closeCaptionFlag === 'SHOW'
                ? [
                      {
                          key: 'showCloseCaption',
                          label: t(keys.RHS_CUSTOMIZE_CLOSE_CAPTION_LABEL),
                          hint: t(keys.RHS_CUSTOMIZE_CLOSE_CAPTION_HINT),
                          isHidden: false,
                          isClearEnabled: false,
                          isInverted: true,
                          childComponent: PrimaryLangSelect,
                      },
                      isCCTranslationEnabled && {
                          key: 'allowTranslatedCaption',
                          label: t(keys.RHS_CUSTOMIZE_TRANSLATED_CAPTION_LABEL),
                          hint: t(keys.RHS_CUSTOMIZE_TRANSLATED_CAPTION_HINT),
                          isHidden: false,
                          isClearEnabled: false,
                          parentKey: 'showCloseCaption',
                          isInverted: true,
                          childComponent: LangsSelect,
                      },
                  ]
                : []),
            {
                key: 'hideAirmeetExitSurvey',
                label: t(keys.RHS_CUSTOMIZE_EXIT_SURVEY_LABEL2),
                hint: t(keys.RHS_CUSTOMIZE_EXIT_SURVEY_HINT2),
                isHidden: false,
                isClearEnabled: false,
            },
        ],
        [
            t,
            is_chat_gif_enabled,
            is_advanced_people_search_enabled,
            isPeopleSearchFirebaseFlagEnabled,
            isAdvancedPeopleSearchEnabled,
            isResourceManagementEnabled,
            isResourceVisible,
            closeCaptionFlag,
            isCCTranslationEnabled,
            isScheduleMeetingEnabled,
            isSessionReplayEnabled,
        ]
    );

    const hideEventSetting = useCallback(
        (key, preCondition) => {
            if (!preCondition) return;
            const keyIndex = EVENT_SETTINGS.findIndex((obj) => obj.key === key);
            if (keyIndex !== -1) EVENT_SETTINGS.splice(keyIndex, 1);
        },
        [EVENT_SETTINGS]
    );

    useEffect(() => {
        hideEventSetting(scheduleMeetingKey, !isScheduleMeetingEnabled);
        hideEventSetting(leaderboardPointsFlagKey, !isLeaderboardEnabled);
    }, [
        hideEventSetting,
        isLeaderboardEnabled,
        isScheduleMeetingEnabled,
        isResourceManagementEnabled,
    ]);

    return (
        <PanelSettings
            liveConfig={liveConfig}
            setPromptData={setPromptData}
            onChange={onChange}
            settings={
                status === AIRMEET_STATUS.FINISHED
                    ? EVENT_SETTINGS.filter((item) => item?.isPostEventVisible)
                    : EVENT_SETTINGS
            }
            title={t(keys.RHS_CUSTOMIZE_EVENT_EXP_TITLE)}
            description={t(keys.RHS_CUSTOMIZE_EVENT_EXP_SUBTITLE)}
            logEvent={logEvent}
        />
    );
}

export default CustomizeEventExperience;
