import { cssPxValueFormatter } from 'components/molecules/utils/helper';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { layout, space } from 'styled-system';

const AvatarImage = (props) => {
    const { width, radius } = props;
    const formattedWidth = cssPxValueFormatter(width);
    return (
        <CircularContainer
            alt=''
            {...{ ...props, width: formattedWidth, radius: radius }}
        />
    );
};

AvatarImage.defaultProps = {
    width: '48px',
};
AvatarImage.propTypes = {
    src: PropTypes.string,
    width: PropTypes.string,
};

export default AvatarImage;

const CircularContainer = styled.img`
    border-radius: ${(props) => (props.radius ? props.radius : '50%')};
    object-fit: cover;
    height: ${(props) => (props.width ? props.width : '48px')};
    min-height: ${(props) => (props.width ? props.width : '48px')};
    width: ${(props) => (props.width ? props.width : '48px')};
    min-width: ${(props) => (props.width ? props.width : '48px')};
    user-select: none;
    pointer-events: none;
    ${layout}
    ${space}
`;
