import { INVITATION_STATUS } from 'hooks/home/useInviteTeamMemberHandler';
import createReducer from '../../utils/createReducer';
import {
    APP_FOOTER_SET_PROPS,
    APP_FOOTER_VISIBILITY,
    APP_HEADER_CLEAR_PROPS,
    APP_HEADER_SET_PROPS,
    APP_HEADER_TOGGLE,
    APP_INVITED_USER_CLEAR_STATE,
    APP_INVITED_USER_SET_STATE,
    APP_MODAL_HIDE,
    APP_MODAL_SHOW,
    APP_PAGE_SET_PROPS,
    APP_PAGE_WIDTH_FULL,
    APP_PAGE_WIDTH_RESTRICTED,
    APP_SIDEMENU_ACTIVE_ITEM,
    APP_SIDEMENU_CLEAR_PROPS,
    APP_SIDEMENU_SET_PROPS,
    APP_SIDEMENU_TOGGLE,
    APP_SIGNUP_PAGE_CLEAR_PROPS,
    APP_SIGNUP_PAGE_SET_PROPS,
    APP_USER_INVITE_CLEAR_SCREEN_PROPS,
    APP_USER_INVITE_SET_SCREEN_PROPS,
    APP_SHOW_ONBOARDING_COMMUNITY_LOADER,
    APP_HIDE_ONBOARDING_COMMUNITY_LOADER,
    APP_SHOW_ONBOARDING_PROGRESS_BAR,
    APP_HIDE_ONBOARDING_PROGRESS_BAR,
    APP_HEADER_UPDATE_PROPS,
    APP_VIDEO_EDTIOR_SET_PROPS,
    APP_VIDEO_EDTIOR_RESET_PROPS,
} from '../actions/layout';
import { FETCH_CREATE_PROJECT_RESPONSE } from '../actions/videoEditor';

const initialState = {
    header: {
        template: 'basic',
        hidden: false,
        transparent: false,
        sticky: false,
        absolute: false,
        translucent: false,
        showDemoButton: false,
    },
    page: {
        restricted: true,
        fillHeight: false,
        classes: [],
    },
    sideMenu: {
        enabled: false,
        activeId: null,
    },
    modal: {
        active: null,
        hide: null,
        props: {},
    },
    footer: {
        hidden: false,
        isLocalisation: true,
    },
    signUpPage: {
        props: {},
    },
    userInviteScreen: {
        props: {},
    },
    invitedUserState: {
        isNewManager: null,
        continueSignUpFlow: false,
        userEmail: null,
        userCommunityId: null,
        invitationToken: null,
        providerMethod: null,
        authSuccess: null,
        modalClose: null,
        invitationStatus: INVITATION_STATUS['invited'],
        userPlanIntent: null,
    },
    onboarding: {
        showOnboardingCommunityLoader: false,
        showProgressBar: false,
    },
    videoEditor: {
        airmeetId: '',
        projectId: '',
        sessionId: '',
        communityId: '',
        url: '',
        name: '',
        airmeetName: '',
        level: '',
    },
};

const handlers = {
    [APP_HEADER_TOGGLE]: (state = initialState, action) => {
        const newHeader = { ...state.header, hidden: action.payload === false };
        return {
            ...state,
            header: newHeader,
        };
    },
    [APP_HEADER_SET_PROPS]: (state = initialState, action) => {
        const newHeader = { ...initialState.header, ...action.payload };
        return {
            ...state,
            header: newHeader,
        };
    },
    [APP_HEADER_SET_PROPS]: (state = initialState, action) => {
        const newHeader = { ...initialState.header, ...action.payload };
        return {
            ...state,
            header: newHeader,
        };
    },
    [APP_HEADER_UPDATE_PROPS]: (state = initialState, action) => {
        return {
            ...state,
            header: {
                ...state.header,
                ...action.payload,
            },
        };
    },
    [APP_HEADER_CLEAR_PROPS]: (state = initialState) => {
        const newHeader = { ...initialState.header };
        return {
            ...state,
            header: newHeader,
        };
    },
    [APP_PAGE_WIDTH_FULL]: (state = initialState) => {
        const newPage = { ...state.page, restricted: false };
        return {
            ...state,
            page: newPage,
        };
    },
    [APP_PAGE_WIDTH_RESTRICTED]: (state = initialState) => {
        const newPage = { ...state.page, restricted: true };
        return {
            ...state,
            page: newPage,
        };
    },
    [APP_PAGE_SET_PROPS]: (state = initialState, { payload }) => {
        const newPage = { ...initialState.page, ...payload };
        return {
            ...state,
            page: newPage,
        };
    },
    [APP_SIDEMENU_TOGGLE]: (state = initialState, { payload }) => {
        const newSidemenu = { ...state.sideMenu, enabled: payload === true };
        return {
            ...state,
            sideMenu: newSidemenu,
        };
    },
    [APP_SIDEMENU_ACTIVE_ITEM]: (state = initialState, { payload }) => {
        const newSidemenu = { ...state.sideMenu, activeId: payload };
        return {
            ...state,
            sideMenu: newSidemenu,
        };
    },
    [APP_SIDEMENU_SET_PROPS]: (state = initialState, { payload }) => {
        const newSidemenu = { ...initialState.sideMenu, ...payload };
        return {
            ...state,
            sideMenu: newSidemenu,
        };
    },
    [APP_SIDEMENU_CLEAR_PROPS]: (state = initialState) => {
        const newSidemenu = { ...initialState.sideMenu };
        return {
            ...state,
            sideMenu: newSidemenu,
        };
    },
    [APP_MODAL_SHOW]: (state = initialState, { payload }) => {
        const newModal = {
            ...initialState.modal,
            active: payload.active,
            props: { ...payload.props },
            hide: null,
        };

        if (payload.allowClose !== undefined) {
            newModal.allowClose = payload.allowClose !== false;
        }

        return {
            ...state,
            modal: newModal,
        };
    },
    [APP_MODAL_HIDE]: (state = initialState, { payload = {} }) => {
        console.log('Closing modal', payload);
        const newModal = { ...initialState.modal, active: null, ...payload };
        return {
            ...state,
            modal: newModal,
        };
    },
    [APP_FOOTER_VISIBILITY]: (state = initialState, { payload }) => {
        const newFooter = { ...initialState.footer, hidden: payload === false };
        return {
            ...state,
            footer: newFooter,
        };
    },
    [APP_FOOTER_SET_PROPS]: (state = initialState, { payload }) => {
        const newFooter = { ...initialState.footer, ...payload };
        return {
            ...state,
            footer: newFooter,
        };
    },
    [APP_SIGNUP_PAGE_SET_PROPS]: (state = initialState, { payload }) => {
        const newPageProps = {
            ...initialState.signUpPage,
            props: { ...payload.props },
        };
        return {
            ...state,
            signUpPage: newPageProps,
        };
    },
    [APP_SIGNUP_PAGE_CLEAR_PROPS]: (state = initialState, { payload = {} }) => {
        const newPageProps = {
            ...initialState.signUpPage,
            ...payload,
        };
        return {
            ...state,
            signUpPage: newPageProps,
        };
    },
    [APP_INVITED_USER_SET_STATE]: (state = initialState, { payload = {} }) => {
        const newPageProps = {
            ...state.invitedUserState,
            ...payload,
        };
        return {
            ...state,
            invitedUserState: newPageProps,
        };
    },
    [APP_INVITED_USER_CLEAR_STATE]: (
        state = initialState,
        { payload = {} }
    ) => {
        const newPageProps = {
            ...initialState.invitedUserState,
            ...payload,
        };
        return {
            ...state,
            invitedUserState: newPageProps,
        };
    },
    [APP_USER_INVITE_SET_SCREEN_PROPS]: (
        state = initialState,
        { payload = {} }
    ) => {
        const newPageProps = {
            ...state.userInviteScreen,
            props: { ...payload.props },
        };
        return {
            ...state,
            userInviteScreen: newPageProps,
        };
    },
    [APP_USER_INVITE_CLEAR_SCREEN_PROPS]: (
        state = initialState,
        { payload = {} }
    ) => {
        const newPageProps = {
            ...state.userInviteScreen,
            ...payload,
        };
        return {
            ...state,
            userInviteScreen: newPageProps,
        };
    },
    [APP_SHOW_ONBOARDING_COMMUNITY_LOADER]: (
        state = initialState,
        { payload = {} }
    ) => {
        const newPageProps = {
            ...state.onboarding,
            ...payload,
        };
        return {
            ...state,
            onboarding: newPageProps,
        };
    },
    [APP_HIDE_ONBOARDING_COMMUNITY_LOADER]: (
        state = initialState,
        { payload = {} }
    ) => {
        const newPageProps = {
            ...state.onboarding,
            ...payload,
        };
        return {
            ...state,
            onboarding: newPageProps,
        };
    },
    [APP_SHOW_ONBOARDING_PROGRESS_BAR]: (
        state = initialState,
        { payload = {} }
    ) => {
        const newPageProps = {
            ...state.onboarding,
            ...payload,
        };
        return {
            ...state,
            onboarding: newPageProps,
        };
    },
    [APP_HIDE_ONBOARDING_PROGRESS_BAR]: (
        state = initialState,
        { payload = {} }
    ) => {
        const newPageProps = {
            ...state.onboarding,
            ...payload,
        };
        return {
            ...state,
            onboarding: newPageProps,
        };
    },
    [APP_VIDEO_EDTIOR_SET_PROPS]: (state = initialState, { payload = {} }) => {
        const newPageProps = {
            ...state.videoEditor,
            ...payload,
        };
        return {
            ...state,
            videoEditor: newPageProps,
        };
    },
    [APP_VIDEO_EDTIOR_RESET_PROPS]: (state = initialState) => {
        const newPageProps = {
            ...initialState.videoEditor,
        };
        return {
            ...state,
            videoEditor: newPageProps,
        };
    },
    [FETCH_CREATE_PROJECT_RESPONSE]: (
        state = initialState,
        { payload = {} }
    ) => {
        const newPageProps = {
            ...state.videoEditor,
            projectId: payload.projectId,
        };
        return {
            ...state,
            videoEditor: newPageProps,
        };
    },
};

export default createReducer(initialState, handlers);
