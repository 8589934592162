import { API_SERVICE } from '../../utils/apiService';
import { CALL_API } from '../api';

export const GET_URL_PARAMS_REQUEST = 'GET_URL_PARAMS_REQUEST';
export const GET_URL_PARAMS_RESPONSE = 'GET_URL_PARAMS_RESPONSE';
export const CREATE_URL_PARAMS_REQUEST = 'CREATE_URL_PARAMS_REQUEST';
export const UPDATE_URL_PARAMS_REQUEST = 'UPDATE_URL_PARAMS_REQUEST';
export const DELETE_URL_PARAMS_REQUEST = 'DELETE_URL_PARAMS_REQUEST';

//Urlparams API
export function getUrlParamLinks(airmeetId) {
    return {
        [CALL_API]: {
            types: [GET_URL_PARAMS_REQUEST, GET_URL_PARAMS_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/utm-links`,
            service: API_SERVICE.BFF,
            type: 'json',
            method: 'GET',
        },
    };
}

export function createUrlParams(payload) {
    const { airmeetId, urlParamsInfo } = payload;
    return {
        [CALL_API]: {
            types: [CREATE_URL_PARAMS_REQUEST],
            endpoint: `/airmeet/${airmeetId}/utm-links`,
            service: API_SERVICE.BFF,
            body: urlParamsInfo,
            type: 'json',
            method: 'POST',
        },
    };
}
export function updateUrlParams(payload) {
    const { airmeetId, urlParamLinkId, urlParamsInfo } = payload;
    return {
        [CALL_API]: {
            types: [UPDATE_URL_PARAMS_REQUEST],
            endpoint: `/airmeet/${airmeetId}/utm-links/${urlParamLinkId}`,
            service: API_SERVICE.BFF,
            body: urlParamsInfo,
            type: 'json',
            method: 'PUT',
        },
    };
}

export function deleteUrlParams(payload) {
    const { airmeetId, urlParamLinkId } = payload;
    return {
        [CALL_API]: {
            types: [DELETE_URL_PARAMS_REQUEST],
            endpoint: `/airmeet/${airmeetId}/utm-links/${urlParamLinkId}`,
            service: API_SERVICE.BFF,
            type: 'json',
            method: 'DELETE',
        },
    };
}
