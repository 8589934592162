import ColorInput from 'atoms/ColorInput/ColorInput';
import ColorPicker from 'atoms/ColorPicker';
import ColorPickerV2 from 'atoms/ColorPicker/ColorPickerV2';
import WithTooltip from 'components/general/WithTooltip';
import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import { stringToHSL } from 'foundations/theme';
import { Text } from 'foundations/themeV2/text/';
import { LazyLoaded } from 'hooks/common';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import useTheme from 'hooks/useTheme';
import keys from 'locale/keys';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { background } from 'styled-system';
import {
    getHexVal,
    hexaToHSLA,
    nearestHexColorCode,
    stringToHSLA,
} from 'utils/colors';

import { getCurrentTenantData } from 'utils/tenant';

const defaultColor = getCurrentTenantData('primaryColor');

const DEFAULT_COLORS = [
    `hsl(${defaultColor.H},${defaultColor.S}%,${defaultColor.L}%)`,
    'hsl(220,86%,64%)',
    'hsl(184,44%,55%)',
    'hsl(36,86%,55%)',
    'hsl(345,86%,69%)',
];
const GetColorData = LazyLoaded(
    React.lazy(() =>
        import('components/lounge/rhsV2/settingsTab/widgets/colorData')
    )
);
export const getIsLightColor = (color) => {
    const currentColor = Array.isArray(color)
        ? color.length > 0
            ? color[0]
            : ''
        : color;
    const hslValues = isString(currentColor)
        ? stringToHSL(currentColor) || stringToHSLA(currentColor)
        : [];

    return (
        Array.isArray(hslValues) && hslValues.length >= 4 && hslValues[3] >= 80
    );
};

const getHslValue = ({ color, isOpacityEnabled }) => {
    const value = hexaToHSLA(color);
    const colorValue = isOpacityEnabled
        ? `hsla(${value.h},${value.s}%,${value.l}%,${value.a})`
        : `hsl(${value.h},${value.s}%,${value.l}%)`;
    return colorValue;
};

const getCustomColorArray = ({ colorValue, isOpacityEnabled, colorData }) => {
    let colArr = [];
    if (colorValue) {
        if (Array.isArray(colorValue)) {
            colorValue.forEach((color) => {
                if (color.includes('#')) {
                    color = getHslValue({ color, isOpacityEnabled });
                }
                const colorHex = getHexVal({ color, isOpacityEnabled });
                const clrVal = nearestHexColorCode({
                    colorHex,
                    colorData,
                });
                colArr.push(clrVal);
            });
        } else {
            if (colorValue.includes('#')) {
                colorValue = getHslValue({
                    color: colorValue,
                    isOpacityEnabled,
                });
            }
            const colorHex = getHexVal({
                color: colorValue,
                isOpacityEnabled,
            });
            const clrVal = nearestHexColorCode({
                colorHex,
                colorData,
            });
            colArr.push(clrVal);
        }
    }
    return colArr;
};

export default function ColorSelector({
    value,
    is_light_theme,
    onChange,
    setShowPageOverlay,
    setAmbienceSuggestionVisible,
    type = 'primary_color',
    title,
    subtitle,
    prop,
    defaultColors = DEFAULT_COLORS,
    swatchShape = 'square',
    swatchSize = 34,
    customColorEnabled = true,
    gradientType = 'linear',
    colorPickerV2 = true,
    gradient = false,
    isOpacityEnabled = false,
    styleObj = {},
    mode = 'SWATCH',
    showSelectColorTooltip = false,
    fromVideoEditor = false,
    fullWidth = false,
}) {
    const { t } = useCustomTranslation();
    const [originalPrimaryColor, setOriginalPrimaryColor] = useState();
    const { theme } = useTheme();
    const [enableColorPicker, setEnableColorPicker] = useState(false);
    const [customColor, setCustomColor] = useState(null);
    const [colorData, setColorData] = useState([]);

    // Save original primary color on mount
    useEffect(() => {
        if (!isEmpty(value)) setOriginalPrimaryColor(value);
    }, []);

    useEffect(() => {
        if (!value) setCustomColor(null);
    }, [value]);

    useEffect(() => {
        setShowPageOverlay && setShowPageOverlay(enableColorPicker);
    }, [enableColorPicker, setShowPageOverlay]);

    const handleChange = (color, value, hex, rgba) => {
        onChange(type, color, prop, value, hex, rgba);

        if (value && setAmbienceSuggestionVisible) {
            if (is_light_theme) {
                setAmbienceSuggestionVisible(value.l >= 80);
            } else {
                setAmbienceSuggestionVisible(value.l <= 20);
            }
        }
    };

    const onSave = (value) => {
        setEnableColorPicker(false);

        const color = `hsl(${value.h},${value.s}%,${value.l}%)`;
        if (![originalPrimaryColor, ...(defaultColors || [])].includes(color)) {
            setCustomColor(color);
        }
        handleChange(color, value);
    };

    const handleV2Save = (value, hex, rgba) => {
        setEnableColorPicker(false);

        if (Array.isArray(value) && value.length > 1) {
            const colors = value.map((val, index) =>
                isOpacityEnabled
                    ? `hsla(${val.h},${val.s}%,${val.l}%,${val.a})`
                    : `hsl(${val.h},${val.s}%,${val.l}%)`
            );
            if (
                ![originalPrimaryColor, ...(defaultColors || [])].includes(
                    colors.join('-')
                )
            ) {
                setCustomColor(colors);
            }
            handleChange(colors, value[0], hex, rgba);
        } else {
            const color = isOpacityEnabled
                ? `hsla(${value.h},${value.s}%,${value.l}%,${value.a})`
                : `hsl(${value.h},${value.s}%,${value.l}%)`;
            if (
                ![originalPrimaryColor, ...(defaultColors || [])].includes(
                    color
                )
            ) {
                setCustomColor(color);
            }
            handleChange(color, value, hex, rgba);
        }
    };
    const onModalClose = () => {
        setEnableColorPicker(false);
    };

    const showColorPicker = () => {
        setEnableColorPicker(true);
    };

    const onSwatchChange = (color) => {
        setEnableColorPicker(false);
        setCustomColor(null);
        let stringColor = '';
        if (Array.isArray(color) && color.length > 0) {
            stringColor = color[0];
        } else {
            stringColor = color;
        }
        const colors = stringToHSL(stringColor);
        let value = {};
        if (Array.isArray(colors) && colors.length > 3) {
            value = { h: colors[1], s: colors[2], l: colors[3] };
        }
        handleChange(color, value);
    };

    const isCustomOriginalPrimaryColor = useMemo(() => {
        if (Array.isArray(originalPrimaryColor)) {
            const newDefaultColors = (defaultColors || []).map((color) => {
                if (Array.isArray(color)) {
                    return color.join('-');
                } else return color;
            });

            let newCustomColor = customColor;
            if (Array.isArray(customColor)) {
                newCustomColor = customColor.join('-');
            }

            return ![...newDefaultColors, newCustomColor].includes(
                originalPrimaryColor.join('-')
            );
        }
        if (originalPrimaryColor) {
            return ![...(defaultColors || []), customColor].includes(
                originalPrimaryColor
            );
        }
        return false;
    }, [customColor, defaultColors, originalPrimaryColor]);

    const customColorArray = useMemo(
        () =>
            getCustomColorArray({
                colorValue: customColor,
                isOpacityEnabled,
                colorData,
            }),
        [customColor, isOpacityEnabled, colorData]
    );
    const originalPrimaryCustomColor = useMemo(
        () =>
            getCustomColorArray({
                colorValue: originalPrimaryColor,
                isOpacityEnabled,
                colorData,
            }),
        [originalPrimaryColor, isOpacityEnabled, colorData]
    );
    const valueColorArray = useMemo(
        () =>
            getCustomColorArray({
                colorValue: value,
                isOpacityEnabled,
                colorData,
            }),
        [value, isOpacityEnabled, colorData]
    );

    const defaultColorsList = useMemo(() => {
        return (defaultColors || []).map((color) => {
            let stringColor = '';
            if (Array.isArray(color)) {
                stringColor = color.join('-');
            } else {
                stringColor = color;
            }

            let stringValue = '';
            if (Array.isArray(value)) {
                stringValue = value.join('-');
            } else {
                stringValue = value;
            }

            const isChecked = stringColor === stringValue;
            let names = [];
            if (gradient) {
                color.forEach((item) => {
                    const colorVal = nearestHexColorCode({
                        colorHex: item,
                        colorData,
                    });
                    names.push(colorVal);
                });
            } else {
                const hexVal = getHexVal({ color });
                const colorVal = nearestHexColorCode({
                    colorHex: hexVal,
                    colorData,
                });
                names.push(colorVal);
            }

            return { color, names, isChecked };
        });
    }, [colorData, defaultColors, gradient, value]);

    const commonSwatchProps = {
        theme: theme,
        gradient: gradient,
        gradientType: gradientType,
        swatchShape: swatchShape,
        swatchSize: swatchSize,
        isNameLoading: colorData.length === 0,
        onChange: onSwatchChange,
    };

    return (
        <WidgetWrapper
            fromVideoEditor={fromVideoEditor}
            {...styleObj?.widgetWrapper}
            fullWidth={fullWidth}
        >
            <GetColorData callBack={setColorData} />
            {title && (
                <Text
                    variant='h5'
                    color='text.default.primary'
                    pb={subtitle ? '4px' : '0px'}
                >
                    {title}
                </Text>
            )}
            {subtitle && (
                <Text variant='caption' color='text.default.secondary'>
                    {subtitle}
                </Text>
            )}
            {mode === 'SWATCH' ? (
                <ColorSwatchWrapper
                    swatchSize={swatchSize}
                    pt={title || subtitle ? '20px' : '0px'}
                >
                    {customColorEnabled && (
                        <WithTooltip
                            renderElement={
                                showSelectColorTooltip ? (
                                    <Text variant='caption' color='ambience.0'>
                                        Select {gradient ? 'Gradient' : 'Color'}
                                    </Text>
                                ) : null
                            }
                            offset={'{"top": -20}'}
                            toolClass='darkThemeTooltip hide-arrow'
                            place='top'
                        >
                            <ButtonCustomColor
                                swatchShape={swatchShape}
                                swatchSize={swatchSize}
                                onClick={showColorPicker}
                            >
                                <Icon
                                    icon='plus-icon'
                                    fill='ambience.0'
                                    width={16}
                                    height={16}
                                />
                            </ButtonCustomColor>
                        </WithTooltip>
                    )}
                    {(defaultColorsList || []).map((item, index) => {
                        return (
                            <SwatchItem
                                {...commonSwatchProps}
                                key={`default_swatch_${index}`}
                                names={item?.names}
                                isChecked={item?.isChecked}
                                color={item?.color}
                            />
                        );
                    })}
                    {isCustomOriginalPrimaryColor && originalPrimaryColor && (
                        <SwatchItem
                            {...commonSwatchProps}
                            key='original_color'
                            names={originalPrimaryCustomColor}
                            isChecked={originalPrimaryColor === value}
                            color={originalPrimaryColor}
                        />
                    )}
                    {customColor && (
                        <SwatchItem
                            {...commonSwatchProps}
                            key='swatch_custom_color'
                            names={customColorArray}
                            isChecked={true}
                            color={customColor}
                        />
                    )}
                </ColorSwatchWrapper>
            ) : (
                <FlexDiv
                    alignItems='center'
                    pt={title || subtitle ? '12px' : '0px'}
                >
                    {!fromVideoEditor && (
                        <Text
                            variant='caption'
                            color='text.default.secondary'
                            pr='12px'
                        >
                            {t(keys.RHS_CUSTOMIZE_SELECTED_COLOR)}
                        </Text>
                    )}
                    <ColorInput
                        name={
                            colorData.length > 0 && valueColorArray.length > 0
                                ? `${
                                      valueColorArray[0]?.isExactMatch
                                          ? ''
                                          : '~ '
                                  }${valueColorArray[0]?.name}`
                                : ''
                        }
                        value={
                            colorData.length > 0 && valueColorArray.length > 0
                                ? gradient
                                    ? valueColorArray.map(
                                          (item) => item?.originalHex
                                      )
                                    : valueColorArray[0]?.originalHex
                                : ''
                        }
                        onClick={showColorPicker}
                        gradientType={gradient ? gradientType : ''}
                        fullWidth={fullWidth}
                    />
                </FlexDiv>
            )}
            {enableColorPicker &&
                (colorPickerV2 || gradient ? (
                    <ColorPickerV2
                        onClose={onModalClose}
                        value={value}
                        onSave={handleV2Save}
                        gradient={gradient}
                        isOpacityEnabled={isOpacityEnabled}
                        title={'Select color'}
                        colorData={colorData}
                        {...styleObj.colorPicker}
                    />
                ) : (
                    <ColorPicker
                        onModalClose={onModalClose}
                        onSave={onSave}
                        value={value}
                    />
                ))}
        </WidgetWrapper>
    );
}

const SwatchItem = ({
    isNameLoading = false,
    gradient = false,
    onChange,
    isChecked = false,
    names = [],
    color,
    theme,
    gradientType,
    swatchShape = 'square',
    swatchSize = 34,
}) => (
    <WithTooltip
        toolClass='darkThemeTooltip'
        place='top'
        renderElement={
            !isNameLoading ? (
                gradient && names.length > 1 ? (
                    <Text
                        variant='caption'
                        style={{
                            maxWidth: '200px',
                        }}
                    >
                        {names[0]?.isExactMatch ? '' : '~ '}
                        {names[0]?.name} {'  '} {names[0]?.originalHex} <br />
                        {names[1]?.isExactMatch ? '' : '~ '}
                        {names[1]?.name} {'  '} {names[1]?.originalHex}
                    </Text>
                ) : (
                    <Text
                        variant='caption'
                        style={{
                            maxWidth: '200px',
                        }}
                    >
                        {names[0]?.isExactMatch ? '' : '~ '}
                        {names[0]?.name} <br /> {names[0]?.originalHex}
                    </Text>
                )
            ) : (
                <Text variant='caption' style={{ maxWidth: '200px' }}>
                    Loading...
                </Text>
            )
        }
    >
        <SwatchWrap swatchSize={swatchSize}>
            <ColorSwatch
                background={color}
                isChecked={isChecked}
                onClick={() => onChange && onChange(color)}
                swatchShape={swatchShape}
                swatchSize={swatchSize}
                gradientType={gradientType}
            />
            {isChecked && <CheckIcon theme={theme} color={color} />}
        </SwatchWrap>
    </WithTooltip>
);

const CheckIcon = ({ theme, color }) => {
    let stringColor = '';
    if (Array.isArray(color) && color.length > 0) {
        stringColor = color[0];
    } else {
        stringColor = color;
    }

    return (
        <IconWrapper>
            <Icon
                icon={'checkmark_circle'}
                fill={
                    theme.colors.ambience[
                        getIsLightColor(stringColor)
                            ? theme?.isLightTheme
                                ? 0
                                : 24
                            : theme?.isLightTheme
                            ? 24
                            : 0
                    ]
                }
                width={16}
                height={16}
            />
        </IconWrapper>
    );
};

const WidgetWrapper = styled(Box)`
    padding-top: ${({ fromVideoEditor }) => (fromVideoEditor ? '0px' : '16px')};
    padding-bottom: ${({ fromVideoEditor }) =>
        fromVideoEditor ? '0px' : '20px'};
    ${({ fullWidth }) => fullWidth && 'width:100%;'}
`;

export const ColorSwatchWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

export const SwatchWrap = styled.div`
    display: flex;
    justify-content: center;
    margin-right: 8px;
    margin-bottom: 8px;
    position: relative;
`;

export const ColorSwatch = styled.div`
    ${background}
    ${({ background, gradientType }) =>
        Array.isArray(background) &&
        background.length > 1 &&
        gradientType === 'linear'
            ? `background: linear-gradient(135deg, ${background[0]} 0%, ${background[1]} 100%) !important;`
            : `background: radial-gradient(circle, ${background[0]} 0%, ${background[1]} 100%) !important;`}
    cursor: pointer;
    border-radius: ${({ swatchShape }) =>
        swatchShape === 'square' ? '8px' : '56%'};
    width: ${({ swatchSize }) => `${swatchSize}px`};
    height: ${({ swatchSize }) => `${swatchSize}px`};
    border: solid
        ${({ isChecked, theme, background }) =>
            isChecked
                ? `2px ${getIsLightColor(background) ? 'black' : 'white'}`
                : `1px ${theme?.colors.ambience[9]}`};
    &:focus,
    &:hover {
        border: solid
            ${({ background }) =>
                `2px ${getIsLightColor(background) ? 'black' : 'white'}`};
    }
`;

export const ButtonCustomColor = styled(FlexDiv)`
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: ${({ swatchShape }) =>
        swatchShape === 'square' ? '8px' : '56%'};
    width: ${({ swatchSize }) => `${swatchSize}px`};
    height: ${({ swatchSize }) => `${swatchSize}px`};
    border: ${({ theme }) =>
        `solid 1px ${
            theme.isLightTheme
                ? theme.colors.ambience[18]
                : theme.colors.ambience[0]
        }`};
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
`;
