import * as Tabs from '@radix-ui/react-tabs';
import React from 'react';
import styled from 'styled-components';

interface TabsProps {
    tabs: Array<any>;
    activeTab?: any;
    contentPadding?: string;
    notifications?: Array<string>;
    tabKey?: number;
}

const TabsV2: React.FC<TabsProps> = ({
    tabs,
    activeTab,
    contentPadding = '1rem 0',
    notifications = [],
    tabKey,
    ...rest
}) => {
    const defaultTab = activeTab || Array.from(tabs)[0]?.id;
    return (
        <TabsRoot
            key={tabKey}
            defaultValue={defaultTab}
            className='tabsRoot'
            {...rest}
        >
            <TabsList className='tabsList'>
                {tabs.length > 1 &&
                    (tabs || []).map((tab) => {
                        const hasNotification = notifications.includes(
                            tab?.heading || tab?.label
                        );
                        return (
                            <React.Fragment key={tab?.id}>
                                <TabsTrigger
                                    value={tab?.id}
                                    className='tabsTrigger'
                                    disabled={tab?.disable}
                                >
                                    {tab?.heading || tab?.label}
                                    {hasNotification && <NotificationIcon />}
                                </TabsTrigger>
                            </React.Fragment>
                        );
                    })}
            </TabsList>
            {(tabs || []).map((tab) => {
                return (
                    <React.Fragment key={tab?.id}>
                        <TabsContent
                            padding={contentPadding}
                            value={tab?.id}
                            className='tabsContent'
                        >
                            {tab?.content}
                        </TabsContent>
                    </React.Fragment>
                );
            })}
        </TabsRoot>
    );
};

export default TabsV2;

const TabsRoot = styled(Tabs.Root)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TabsList = styled(Tabs.List)`
    flex-shrink: 0;
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.default2};
`;

const TabsTrigger = styled(Tabs.Trigger)`
    all: unset;
    background-color: transparent;
    padding: 0;
    height: 40px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.fontSizes.x2};
    font-family: ${({ theme }) => theme.fonts.regular};
    line-height: 1;
    user-select: none;
    color: ${({ theme }) => theme.colors.text.default.secondary};
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-in;

    &:hover {
        background-color: ${({ theme }) => theme.colors.fill.other3};
    }
    &[data-state='active'] {
        color: ${({ theme }) => theme.colors.brandDefault};
        font-family: ${({ theme }) => theme.fonts.bold};
        &:hover {
            background-color: transparent;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 4px;
            background: ${({ theme }) => theme.colors.brandDefault};
            border-radius: 20px 20px 0px 0px;
        }
    }
    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: 4px solid ${({ theme }) => theme.colors.border.default1};
    }
`;

const TabsContent = styled(Tabs.Content)`
    flex-grow: 1;
    color: ${({ theme }) => theme.colors.text.default.primary};
    outline: none;
    padding: ${({ padding }) => padding};
`;

const NotificationIcon = styled.div`
    align-self: flex-start;
    margin-top: 4px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.sem.error};
`;
