import * as Separator from '@radix-ui/react-separator';
import React from 'react';
import styled from 'styled-components';
import {
    background,
    border,
    color,
    flexbox,
    layout,
    position,
    space,
} from 'styled-system';

interface DividerProps {
    orientation?: string;
    bg?: string;
}

const Divider: React.FC<DividerProps> = ({
    orientation = 'horizontal',
    bg = 'border.default1',
    ...rest
}) => (
    <SeparatorRoot
        className='SeparatorRoot'
        bg={bg}
        orientation={orientation}
        {...rest}
    />
);

export default Divider;

const SeparatorRoot = styled(Separator.Root)`
    ${color}
    ${space}
    ${color}
    ${border}
    ${background}
    ${layout}
    ${flexbox}
    ${position}

    &[data-orientation='horizontal'] {
        height: 1px;
        width: 100%;
    }

    &[data-orientation='vertical'] {
        height: 100%;
        width: 1px;
    }
`;
