import classnames from 'classnames';
import useTheme from 'hooks/useTheme';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import styled from 'styled-components';
//import DroppableTbody from './droppableTbody';
import { Typography } from 'foundations/typography';
import SVGSprite from '../../../general/SVGSprite';
import DraggableTbody from './DraggableTbody';
import './dataTable.scss';
import Tbody from './tbody';
import THead from './thead';

/**
 * A Custom Table Component
 * @param {array} headers array of header configs for the table headers
 * @param {array} dataSource array of data objects to be rendered in the body
 * @param {array} actions array of action configs to be rendered for each row
 * @param {function} onSort callback function with headerKey as the param and direction, invoked when sort icon is clicked
 * @param {boolean} isLoading boolean value for showing a loading spinner
 * @param {object} rowSelection object, if key enabled = true, will show a row selector in the table
 */
const defaultPaginationConfig = {
    previousLabel: <SVGSprite icon={'icon-left-arrow'} />,
    nextLabel: <SVGSprite icon={'icon-right-arrow'} />,
    breakLabel: '...',
    breakClassName: 'break-me',
    pageCount: 1,
    marginPagesDisplayed: 2,
    pageRangeDisplayed: 5,
    onPageChange: () => {},
    containerClassName: 'pagination',
    subContainerClassName: 'pages pagination',
    activeClassName: 'active',
};

const DataTable = ({
    headers,
    dataSource,
    actions,
    className = '',
    wrapperClassName = '',
    isLoading,
    forceLoading = false,
    onSort = () => {},
    rowSelection = {
        enabled: false,
        onChange: () => {},
    },
    showPagination,
    paginationData,
    actionHeader = 'Actions',
    reorder = false,
    isVirtualized = false,
    actionsWidth = '',
    tableWidth,
    isEventSeries,
    onDragEnd,
    noDataMsg = false,
    getGroupHeaderText = () => {},
    getGroupHeaderEmptyText = () => {},
    tableMinWidth = '900px',
    renderEmptyState = () => {},
    itemsPerPage = false,
    canModifyPageCount = true,
    itemsPerPageTitle = '',
    pageRangeDisplayCount,
    marginPagesDisplayCount,
    currentPage = false,
    getCurrentPage = null,
    enablePagedArrowIcon = false,
    handlePageSizeChange = () => {},
    dense = false,
    arrowSortIcon = false,
    sortOrderObj = {},
    checkboxBgColor = null,
    selectedHeader = null,
    filesListById = {},
    itemsPerPageOptions = null,
    metaData,
    dropDownZindex = 9,
    rowHeight,
    deletePlaceBottomIndex,
    preselectedRows,
    forcePageSize = null,
    liveEvent = false,
    paddingBottom,
    itemsPerPageLabel = '',
    isViewSegmentTable = false,
}) => {
    const { theme } = useTheme();
    const [selectedRows, setSelectedRows] = useState(preselectedRows || {});
    const [rowData, setRowData] = useState(dataSource || []);
    const [sortOrder, setSortOrder] = useState(sortOrderObj);
    const selectableRowsLength = useMemo(() => {
        return (dataSource || []).filter(
            (data) => !data._isSelectionDisabled && !data._isSelectionHidden
        ).length;
    }, [dataSource]);

    const pageSizeProps = useMemo(() => {
        if (isNumber(forcePageSize)) {
            return {
                value: {
                    label: forcePageSize,
                    value: forcePageSize,
                },
            };
        }
        return {};
    }, [forcePageSize]);
    const _onSelect = (row, checked) => {
        let selectedRowsObj = { ...selectedRows };
        if (!row) {
            // Select all rows
            selectedRowsObj = checked
                ? rowData
                      .filter(
                          (row) =>
                              !row._isSelectionDisabled &&
                              !row._isSelectionHidden
                      )
                      .reduce((acc, row) => ({ ...acc, [row._id]: row }), {})
                : {};
        } else {
            if (!checked) {
                delete selectedRowsObj[row._id];
            } else {
                selectedRowsObj[row._id] = row;
            }
        }

        setSelectedRows(selectedRowsObj);
        rowSelection.onChange(
            Object.values(selectedRowsObj).map(stripPrivateKeys),
            !!row,
            checked
        );
    };
    const selectAllState = useMemo(() => {
        let selectAllCheckboxState = null;
        const currentSelectedLength = Object.keys(selectedRows).length;
        if (currentSelectedLength === selectableRowsLength) {
            selectAllCheckboxState = 'selected';
        } else if (currentSelectedLength === 0) {
            selectAllCheckboxState = null;
        } else {
            selectAllCheckboxState = 'indeterminate';
        }

        if (selectableRowsLength === 0) {
            selectAllCheckboxState = 'disabled';
        }

        return selectAllCheckboxState;
    }, [selectableRowsLength, selectedRows]);

    let paginationConfig = {};
    if (showPagination && paginationData) {
        paginationConfig = {
            ...defaultPaginationConfig,
            ...paginationData,
            containerClassName: paginationData.containerClassName
                ? `${defaultPaginationConfig.containerClassName} ${paginationData.containerClassName}`
                : `${defaultPaginationConfig.containerClassName}`,
            subContainerClassName: paginationData.subContainerClassName
                ? `${defaultPaginationConfig.subContainerClassName} ${paginationData.subContainerClassName}`
                : `${defaultPaginationConfig.subContainerClassName}`,

            activeClassName: paginationData.activeClassName
                ? `${defaultPaginationConfig.activeClassName} ${paginationData.activeClassName}`
                : `${defaultPaginationConfig.activeClassName}`,
        };
    }
    useEffect(() => {
        if (dataSource) {
            setRowData(dataSource);
            setSelectedRows(preselectedRows || []);
        }
    }, [dataSource, preselectedRows]);

    useEffect(() => {
        setRowData(
            (dataSource || []).map((row, id) => {
                // Adding a private id for each row.
                row._id = id;
                if (selectedRows[row._id]) {
                    // Setting a private selected flag for each row.
                    row._isSelected = true;
                } else {
                    row._isSelected = false;
                }
                return row;
            })
        );
    }, [dataSource, selectedRows]);

    useEffect(() => {
        setSortOrder(sortOrderObj);
    }, []);

    const _onSort = (key) => {
        let direction = SortOrder.ASC;
        if (sortOrder[key]) {
            direction =
                sortOrder[key] === SortOrder.ASC
                    ? SortOrder.DESC
                    : SortOrder.ASC;
        }
        setSortOrder({
            [key]: direction,
        });
        onSort(key, direction);
    };

    const {
        previousLabel,
        nextLabel,
        pageCount,
        marginPagesDisplayed,
        pageRangeDisplayed,
        onPageChange,
        containerClassName,
        subContainerClassName,
        activeClassName,
        forcePage,
    } = paginationConfig;

    const ItemPerPageConfig = itemsPerPageOptions || [
        {
            label: 10,
            value: 10,
        },
        {
            label: 50,
            value: 50,
        },
        {
            label: 100,
            value: 100,
        },
    ];

    const SelectStyles = {
        indicatorSeparator: (styles) => ({
            display: 'none !important',
        }),
        singleValue: (styles) => ({
            ...styles,
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.colors.ambience[0],
            fontFamily: theme.fonts.book,
        }),
        selectContainer: (styles) => ({
            ...styles,
            display: 'inline-block',
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            '> div': {
                padding: '0',
            },
        }),
        valueContainer: (styles) => ({
            ...styles,
            width: '32px',
            padding: '0',
        }),
        control: (styles) => ({
            ...styles,
            padding: '0',
            borderRadius: '0',
            background: 'transparent',
            border: '0',
            minHeight: '25px',
            boxShadow: 'none',
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: dropDownZindex,
            fontSize: '14px',
        }),
        menu: (styles) => ({
            ...styles,
            background: theme.colors.ambience[theme?.isLightTheme ? 24 : 21],
            borderRadius: '8px',
        }),
        option: (styles) => ({
            ...styles,
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.colors.ambience[4],
            padding: '4px 8px',
            fontFamily: theme.fonts.book,
            background: theme.colors.ambience[theme?.isLightTheme ? 24 : 21],
            '&:hover': {
                background:
                    theme.colors.ambience[theme?.isLightTheme ? 23 : 20],
            },
        }),
    };

    return (
        <Wrapper
            className={classnames('dashboard-table-outer', wrapperClassName)}
            style={{ paddingBottom: paddingBottom }}
        >
            <div className={'table-container'}>
                <div className={'table-inner-container'}>
                    {reorder ? (
                        <DragDropContext onDragEnd={onDragEnd}>
                            <table
                                className={classnames(
                                    'dashboard-custom-table',
                                    { 'data-table--dense': dense },
                                    className
                                )}
                                style={
                                    tableWidth
                                        ? {
                                              width: tableWidth,
                                              minWidth: tableMinWidth,
                                              tableLayout: 'fixed',
                                              height: isVirtualized
                                                  ? '800px'
                                                  : 'auto',
                                          }
                                        : null
                                }
                            >
                                <THead
                                    headers={headers}
                                    actions={actions}
                                    onSort={_onSort}
                                    onSelect={_onSelect}
                                    rowSelection={rowSelection.enabled}
                                    checkboxState={selectAllState}
                                    actionHeader={actionHeader}
                                    arrowSortIcon={arrowSortIcon}
                                    sortOrder={sortOrder}
                                    checkboxBgColor={checkboxBgColor}
                                    selectedHeader={selectedHeader}
                                    isBooth={metaData?.isBooth}
                                    deletePlaceBottomIndex={
                                        deletePlaceBottomIndex
                                    }
                                />

                                <DraggableTbody
                                    headers={headers}
                                    rowData={rowData}
                                    actions={actions}
                                    isLoading={isLoading}
                                    onSelect={_onSelect}
                                    rowSelection={rowSelection.enabled}
                                    actionsWidth={actionsWidth}
                                    getGroupHeaderText={getGroupHeaderText}
                                    getGroupHeaderEmptyText={
                                        getGroupHeaderEmptyText
                                    }
                                    checkboxBgColor={checkboxBgColor}
                                    isVirtualized={isVirtualized}
                                    metaData={metaData}
                                    rowHeight={rowHeight}
                                    deletePlaceBottomIndex={
                                        deletePlaceBottomIndex
                                    }
                                    liveEvent={liveEvent}
                                />
                            </table>
                        </DragDropContext>
                    ) : (
                        <div className={'table-container'}>
                            <table
                                className={classnames(
                                    'dashboard-custom-table',
                                    { 'data-table--dense': dense },
                                    className
                                )}
                            >
                                <THead
                                    headers={headers}
                                    actions={actions}
                                    onSort={_onSort}
                                    onSelect={_onSelect}
                                    rowSelection={rowSelection.enabled}
                                    checkboxState={selectAllState}
                                    actionHeader={actionHeader}
                                    arrowSortIcon={arrowSortIcon}
                                    sortOrder={sortOrder}
                                    checkboxBgColor={checkboxBgColor}
                                    selectedHeader={selectedHeader}
                                />
                                <Tbody
                                    headers={headers}
                                    rowData={rowData}
                                    actions={actions}
                                    isLoading={isLoading}
                                    forceLoading={forceLoading}
                                    onSelect={_onSelect}
                                    rowSelection={rowSelection.enabled}
                                    actionsWidth={actionsWidth}
                                    noDataMsg={noDataMsg}
                                    renderEmptyState={renderEmptyState}
                                    checkboxBgColor={checkboxBgColor}
                                    filesListById={filesListById}
                                    liveEvent={liveEvent}
                                />
                            </table>
                        </div>
                    )}
                </div>
            </div>
            <div
                className='table-footer dashboard-table-bottom'
                style={itemsPerPageLabel ? { width: '100%' } : {}}
            >
                <BottomActonWrapper
                    enableBottomActions={
                        itemsPerPage || currentPage ? true : false
                    }
                >
                    {itemsPerPageLabel && (
                        <Typography
                            variant='subtext0'
                            color='ambience.6'
                            pr='10px'
                            flex='1'
                        >
                            {itemsPerPageLabel}
                        </Typography>
                    )}
                    {itemsPerPage && canModifyPageCount && (
                        <ItemsPerPageWrapper>
                            <label className='dashboard-table__items-per-page'>
                                {itemsPerPageTitle}
                            </label>
                            <div>
                                <Select
                                    options={ItemPerPageConfig}
                                    defaultValue={ItemPerPageConfig[0]}
                                    onChange={handlePageSizeChange}
                                    isSearchable={false}
                                    isMobile={false}
                                    styles={{ ...SelectStyles }}
                                    switchOffAutoComplete={true}
                                    menuPortalTarget={
                                        isViewSegmentTable
                                            ? null
                                            : document.body
                                    }
                                    menuPlacement={'auto'}
                                    {...(pageSizeProps || {})}
                                />
                            </div>
                        </ItemsPerPageWrapper>
                    )}
                    {currentPage ? (
                        <DataCount>
                            {currentPage}-{pageCount} of {pageCount}
                        </DataCount>
                    ) : getCurrentPage ? (
                        getCurrentPage()
                    ) : null}
                    {showPagination
                        ? paginationData && (
                              <ReactPaginate
                                  previousLabel={
                                      enablePagedArrowIcon ? (
                                          <SVGSprite
                                              fill={'#FCFCFC'}
                                              icon={'chevron_left'}
                                          />
                                      ) : (
                                          previousLabel
                                      )
                                  }
                                  nextLabel={
                                      enablePagedArrowIcon ? (
                                          <SVGSprite
                                              fill={'#FCFCFC'}
                                              icon={'chevron_right'}
                                          />
                                      ) : (
                                          nextLabel
                                      )
                                  }
                                  pageCount={pageCount}
                                  marginPagesDisplayed={
                                      marginPagesDisplayCount ||
                                      marginPagesDisplayed
                                  }
                                  pageRangeDisplayed={
                                      pageRangeDisplayCount ||
                                      pageRangeDisplayed
                                  }
                                  onPageChange={onPageChange}
                                  containerClassName={containerClassName}
                                  subContainerClassName={subContainerClassName}
                                  activeClassName={activeClassName}
                                  forcePage={forcePage}
                              />
                          )
                        : null}
                </BottomActonWrapper>
            </div>
        </Wrapper>
    );
};

export const stripPrivateKeys = (row) => {
    return Object.keys(row)
        .filter((key) => key.indexOf('_') !== 0)
        .reduce((acc, key) => ({ ...acc, [key]: row[key] }), {});
};

export const SortOrder = {
    ASC: 'asc',
    DESC: 'desc',
};

DataTable.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.object).isRequired,
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    actions: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    dense: PropTypes.bool,
    arrowSortIcon: PropTypes.bool,
};

export default DataTable;

const ItemsPerPageWrapper = styled.div`
    display: flex;
    align-items: center;

    label {
        margin-bottom: 0;
        margin-right: 16px;
        font-size: 12px;
        line-height: 16px;
        color: ${({ theme }) => theme.colors.ambience[8]};
        font-family: ${({ theme }) => theme.fonts.book};
    }
`;

const DataCount = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.ambience[8]};
    font-family: ${({ theme }) => theme.fonts.book};
`;

const BottomActonWrapper = styled.div`
    ${(props) =>
        props.enableBottomActions &&
        `
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 16px;
        ${DataCount}{
            margin-left: 30px;
        }
        .pagination {
            margin: 0 !important;
            margin-left: 8px !important;
        }
`}
`;

const Wrapper = styled.div`
    &.dashboard-table-outer {
        .pagination li.active a {
            border-color: ${({ theme }) =>
                theme.colors.accentPrimary[0]} !important;
        }
        .dashboard-custom-table {
            thead tr,
            tbody tr {
                ${({ theme }) =>
                    theme?.isLightTheme
                        ? `
                background: ${theme.colors.ambience[24]} !important;
                color: ${theme.colors.ambience[0]} !important;
                svg:not(.icon-fill-info):not(.tick):not(.view-transcript):not(.cut) {
                    fill: ${theme.colors.ambience[1]} !important;
                }
            `
                        : ''}
                td.action-body {
                    span {
                        &::after {
                            border-color: ${({ theme }) =>
                                theme?.isLightTheme
                                    ? theme.colors.ambience[21]
                                    : ''};
                        }
                    }
                }
            }
            thead .checkbox-outer {
                input {
                    &:not(:checked):not(:indeterminate) ~ .checkmark {
                        border-color: ${({ theme }) =>
                            theme.colors.ambience[0]} !important;
                    }
                    &:checked ~ .checkmark,
                    &:indeterminate ~ .checkmark {
                        &:after {
                            border-color: ${({ theme }) =>
                                theme.colors.ambience[24]} !important;
                        }
                    }
                }
            }
            tbody .checkbox-outer {
                input {
                    &:checked ~ .checkmark,
                    &:indeterminate ~ .checkmark {
                        &:after {
                            border-color: ${({ theme }) =>
                                theme.colors.ambience[24]} !important;
                        }
                    }
                }
            }
        }
        .dashboard-table-bottom {
            .pagination {
                a {
                    color: ${({ theme }) =>
                        theme.colors.ambience[0]} !important;
                    svg {
                        fill: ${({ theme }) => theme.colors.ambience[0]};
                    }
                }
            }
        }
    }
`;
