import Button from 'atoms/Button/button';
import TooltipV2 from 'atoms/Tooltip/Tooltip.tsx';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import WithTooltip from 'components/general/WithTooltip';
import {
    Box,
    UnstyledButton,
} from 'components/molecules/utils/basicComponents';
import Tooltip from 'components/tooltip/Tooltip';
import Icon from 'foundations/icon';
import { Typography } from 'foundations/typography';
import React from 'react';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';
import { getCurrentTenantData } from 'utils/tenant';
import { STREAMING_HELP_URL } from '../constants';

const defaultDescription = (airmeetFormat = 'event') => {
    return (
        <>
            A common RTMP ‘Server URL’ and ‘Stream Key’ are generated for the
            entire {airmeetFormat}. Copy and paste them in the third party
            streaming platform used to Stream the session into{' '}
            {getCurrentTenantData('name')}. To view new generated keys, save the{' '}
            {airmeetFormat === 'webinar' ? airmeetFormat : 'sessions'}.{' '}
            <StyledA
                href={STREAMING_HELP_URL}
                target='_blank'
                rel='noopener noreferrer'
            >
                Learn more
            </StyledA>
        </>
    );
};
export default function StreamKeyHeader({
    variant = 'dark',
    title = 'Stream Keys',
    airmeetFormat = 'event',
    description = defaultDescription(airmeetFormat),
    onGenerateNewRTMPKey = noop,
    disableCreateNewKey,
    showGenerateNewKey = true,
    isThemeV2 = false,
    onBack,
    showBack = false,
}) {
    const isDark = variant === 'dark';

    return (
        <>
            <Box display={'flex'} mb={1} width='400px'>
                {showBack ? (
                    <UnstyledButton onClick={onBack}>
                        <StyledSvgSprite
                            icon='icon-back-arrowlight'
                            height='24px'
                            width='24px'
                            fill='text.default.primary'
                            mr='x4'
                        />
                    </UnstyledButton>
                ) : null}
                <Typography
                    variant={isThemeV2 ? 'h2' : 'subtitle1'}
                    color={
                        isThemeV2
                            ? 'text.default.primary'
                            : isDark
                            ? 'ambience.0'
                            : 'ambience.23'
                    }
                    isThemeV2={isThemeV2}
                    flex='1'
                >
                    {title}
                </Typography>

                {showGenerateNewKey ? (
                    <WithTooltip
                        place='bottom'
                        isThemeV2={true}
                        tooltip={
                            isThemeV2 && disableCreateNewKey
                                ? 'A new stream key was already generated for this session and cannot be generated again'
                                : null
                        }
                    >
                        <Button
                            variant='link'
                            color={
                                isThemeV2
                                    ? ''
                                    : disableCreateNewKey
                                    ? 'ambience.6'
                                    : 'accentPrimary.1'
                            }
                            mr={isThemeV2 ? '' : 4}
                            onClick={onGenerateNewRTMPKey}
                            disabled={disableCreateNewKey}
                            cursor={
                                disableCreateNewKey ? 'not-allowed' : 'disabled'
                            }
                            isThemeV2={isThemeV2}
                        >
                            Generate New Key
                            {isThemeV2 ? (
                                disableCreateNewKey ? (
                                    <StyledSvgSprite
                                        icon='info'
                                        width={14}
                                        height={14}
                                        ml='x2'
                                    />
                                ) : (
                                    <TooltipV2
                                        side='bottom'
                                        content='Generate a new key for this session. This can be especially useful for parallel or overlapping sessions.'
                                        hasWrapper={true}
                                        maxWidth='200px'
                                    >
                                        <StyledSvgSprite
                                            icon='info'
                                            width={14}
                                            height={14}
                                            ml='x2'
                                        />
                                    </TooltipV2>
                                )
                            ) : (
                                <Tooltip
                                    type='eventInfo'
                                    toolClass='attendeeTheme custom-arrow-curve'
                                    offset={`{ 'right': ${-60}}`}
                                    className='d-inline'
                                    place='bottom'
                                    renderElement={
                                        <TooltWrapper>
                                            <ToolTipText
                                                variant={'subtext1'}
                                                color={'ambience.23'}
                                                mb={1}
                                            >
                                                {disableCreateNewKey
                                                    ? 'A new stream key was already generated for this session and cannot be generated again'
                                                    : 'Generate a new key for this session. This can be especially useful for parallel or overlapping sessions.'}
                                            </ToolTipText>
                                        </TooltWrapper>
                                    }
                                    renderTrigger={
                                        <Icon
                                            icon={'info'}
                                            width={14}
                                            height={14}
                                            fill={
                                                disableCreateNewKey
                                                    ? 'ambience.6'
                                                    : 'accentPrimary.1'
                                            }
                                            ml={'8px'}
                                        />
                                    }
                                />
                            )}
                        </Button>
                    </WithTooltip>
                ) : null}
            </Box>
            <Typography
                variant={isThemeV2 ? 'caption' : 'caption-light'}
                color={
                    isThemeV2
                        ? 'text.default.secondary'
                        : isDark
                        ? 'ambience.13'
                        : 'ambience.19'
                }
                mb={isThemeV2 ? 'x4' : '12px'}
                isThemeV2={isThemeV2}
            >
                {description}
            </Typography>
        </>
    );
}

const ToolTipText = styled(Typography)`
    position: relative;
    z-index: 1;
`;

const TooltWrapper = styled(Box)`
    max-width: 245px;
    background-color: ${({ theme }) => theme.colors.ambience[0]};
    border-radius: 8px;
    padding: 16px 24px;
`;
const StyledA = styled.a`
    color: ${({ theme }) => theme.colors.brandDefault};
    text-decoration: none;
    &:hover {
        color: ${({ theme }) => theme.colors.brandDefault};
        text-decoration: none;
    }
`;
