import { useProfiling } from 'context/Profiling';
import { useMemo, useRef } from 'react';
import FirebaseDataSource from 'services/realtime/source/FirebaseDataSource';
import FirestoreDataSource from 'services/realtime/source/FirestoreDataSource';
import { ALL_FEATURES_CONFIG } from './useFeatureControl';
import useFeatureFirebaseClient from './useFeatureFirebaseClient';
import useFirestoreClient from './useFirestoreClient';
import useLiveAirmeetContext from './useLiveAirmeetContext';
import { AblyWrappedFirebaseDataSource } from '../services/realtime/source/AblyWrappedFirebaseDataSource';
import { DataReaderOptions } from './useDataReader';

import { logger } from 'utils/logger';

const useRealTimeDataSource = (
    featureName: string,
    options?: DataReaderOptions
) => {
    const { client: firebaseClient } = useFeatureFirebaseClient(
        featureName,
        options
    );

    const { perf } = useProfiling();
    const perfRef = useRef(perf);

    const { client: firestoreClient } = useFirestoreClient(featureName);
    const { featuresConfig, platformConfig } =
        useLiveAirmeetContext() || options?.liveContextOverride;

    let dataReaderConfig =
        platformConfig &&
        platformConfig[ALL_FEATURES_CONFIG.DATA_READER_CONFIG];

    if (
        featuresConfig &&
        featuresConfig[ALL_FEATURES_CONFIG.DATA_READER_CONFIG]
    ) {
        dataReaderConfig =
            featuresConfig[ALL_FEATURES_CONFIG.DATA_READER_CONFIG];
    }

    const isAblySourceEnabled = Object.keys(
        dataReaderConfig?.ablyEnabledFeatures || {}
    ).includes(featureName);

    const isFirestoreSourceEnabled = Object.keys(
        dataReaderConfig?.firestoreEnabledFeatures || {}
    ).includes(featureName);

    const dataSource = useMemo(() => {
        if (isFirestoreSourceEnabled && firestoreClient) {
            return new FirestoreDataSource(firestoreClient);
        }

        if (isAblySourceEnabled && firebaseClient) {
            logger.info('Using ably wrapped data source');
            return new AblyWrappedFirebaseDataSource(
                firebaseClient,
                perfRef.current
            );
        }
        if (firebaseClient) {
            return new FirebaseDataSource(firebaseClient, perfRef.current);
        }
    }, [
        firebaseClient,
        firestoreClient,
        isAblySourceEnabled,
        isFirestoreSourceEnabled,
    ]);

    return dataSource;
};

export default useRealTimeDataSource;
