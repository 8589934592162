import { format } from 'date-fns-tz';
import isValid from 'date-fns/isValid';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import momenttz from 'moment-timezone';
import React from 'react';
import { currencyToSymbolMap } from '../../../utils/currency';

export const _getNearestTimeByFactor = (
    date = new Date(),
    minuteFactor,
    minDiffInMinutes = 0
) => {
    const nearestStartMinutes =
        (Math.floor(moment(date).get('minute') / minuteFactor) + 1) *
        minuteFactor;
    return moment(date).set({
        minute: nearestStartMinutes + minDiffInMinutes,
        second: 0,
    });
};

export const getNearestQuarter = (date) => _getNearestTimeByFactor(date, 15);
export const getNearestThirdQuarter = (date) =>
    _getNearestTimeByFactor(date, 15, 30);

export const toDateStr = (date) =>
    isValid(date) ? format(date, 'yyyy-MM-dd') : '';
export const toTimeStr = (date) => (isValid(date) ? format(date, 'HH:mm') : '');

/**
 * Utility function to adjust native date as per the timezone offset
 * @param {Date} date Date to be adjusted
 * @param {string} offset offset of the timezone as per which native date has to be adjusted
 */
export const adjustDateByTimeZone = (date, offset, keepSecs = false) => {
    const format = `YYYY-MM-DD HH:mm${keepSecs ? ':ss' : ''}`;
    const localDateStr = moment(date).format(format);
    const adjustedTzTime = moment(
        `${localDateStr} ${offset}`,
        `${format} Z ZZ`
    );
    return adjustedTzTime.toDate();
};

export const getCurrencyList = () => {
    const currencyObj = currencyToSymbolMap();

    let currencyList = Object.keys(currencyObj).map((currencyKey) => {
        return {
            value: currencyKey,
            label: `${currencyKey}`,
        };
    });

    return currencyList;
};

export const getCurrency = (currency) => {
    if (Object.prototype.toString.call(currency) === '[object Object]') {
        return (currency && currency.value) || currency;
    } else {
        return currency;
    }
};

export const getFirstName = (name) => {
    let firstName = name.split(' ').slice(0, 1).join(' ');
    return firstName;
};

export const getLastName = (name) => {
    let lastName = name.split(' ');
    lastName.splice(0, 1);
    return lastName.slice(-1).join(' ');
};

export const getFullName = (user) => {
    if (user?.name) {
        return user.name;
    } else if (user?.firstName) {
        return `${user.firstName} ${user.lastName}`;
    }
    return '';
};

export const splitName = (user) => {
    if (!user?.firstName) {
        const nameArr = (user?.name || '').split(' ');
        const firstName =
            nameArr.length === 1
                ? nameArr[0]
                : (user?.name || '').split(' ').slice(0, -1).join(' ');
        const lastName =
            nameArr.length === 1
                ? ''
                : (user?.name || '').split(' ').slice(-1).join(' ');
        return { firstName, lastName };
    }
    return { firstName: user.firstName, lastName: user.lastName };
};

export const getDefaultCommunity = (communityData, communityId = null) => {
    const { cm, em } = communityData;
    let defaultCommunity;
    if (communityId) {
        const cmCommunityObj = (cm || []).find(
            (obj) => obj.communityId === communityId
        );
        if (!isEmpty(cmCommunityObj)) return cmCommunityObj;
        const emCommunityObj = (em || []).find(
            (obj) => obj.communityId === communityId
        );
        if (!isEmpty(emCommunityObj)) return emCommunityObj;
    }
    if (cm && cm.length > 0) {
        defaultCommunity = cm[0];
    } else if (em && em.length > 0) {
        defaultCommunity = em[0];
    }
    return defaultCommunity;
};

export const textEllipsis = (
    str = '',
    maxLength,
    { ellipsis = '...' } = {}
) => {
    if (str.length > maxLength) {
        return str.slice(0, maxLength - ellipsis.length) + ellipsis;
    }
    return str;
};

export const renderFormBottomText = ({
    inputValue,
    maxValue,
    charCountText = '',
}) => {
    return (
        <div className='count text-right w-100'>
            {`${inputValue?.length}/${maxValue} ${charCountText}`}
        </div>
    );
};

export const getAbsoluteUrl = (url) => {
    const checkHttpReg = new RegExp('^(http|https)://', 'i');
    if (!checkHttpReg.test(url)) {
        return `https://${url}`;
    }
    return url;
};

export const getZonedTime = (time, timezone) => {
    const zonedTime = moment(time).tz(timezone);
    return zonedTime && zonedTime.isValid() ? zonedTime : moment();
};

export const getTimeStringWithoutZone = (time = '') => {
    const timeFormat = 'YYYY-MM-DD HH:mm:ss';
    const timeObject = moment(time);
    return timeObject && timeObject.isValid()
        ? timeObject.format(timeFormat)
        : moment().format(timeFormat);
};

/**
 * Utility function to give same clock time value in local timezone for a given date for a given timezone
 * @param {Date} time Date for calculation of clock value in given timezone
 * @param {string} timezone Time zone to be considered for clock time
 * @returns Local date instance for same clock time
 */
export const getSameClockTimeValue = (time, timezone) => {
    return time
        ? new Date(getTimeStringWithoutZone(getZonedTime(time, timezone)))
        : null;
};

/**
 * Utility function to give same clock time value in foreign timezone for a given date in local timezone
 * @param {Date} time Date for calculation of clock value
 * @param {string} timezone Time zone for which clock value is to calculated
 * @returns Local date instance for same clock time in given timezone
 */
export const getForeignTimezoneSameClockValue = (time, timezone) => {
    return time
        ? new Date(momenttz.tz(getTimeStringWithoutZone(time), timezone))
        : null;
};
