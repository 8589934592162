import isNumber from 'lodash/isNumber';

export const CUT_OFF_DATE_REAL_TIME_ANALYTICS = '2021-06-09';
export const CUT_OFF_DATE_ENGAGEMENT_ANALYTICS = '2021-12-23';

const MAX_LABEL_LENGTH = 35;
export const FONT_FAMILY = `"NotoSans-Regular", serif`;

// Custom tooltip to fix overflow clipping issue
export function customToolTip(tooltipModel) {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<table></table>';
        document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }

    function getBody(bodyItem) {
        return bodyItem.lines;
    }

    let bodyLines = [];
    // Set Text
    if (tooltipModel.body) {
        let titleLines = tooltipModel.title || [];
        bodyLines = tooltipModel.body.map(getBody);

        let innerHtml = '<thead>';

        titleLines.forEach(function (title) {
            innerHtml += '<tr><th>' + title + '</th></tr>';
        });
        innerHtml += '</thead><tbody>';

        bodyLines.forEach(function (body, i) {
            let colors = tooltipModel.labelColors[i];
            let style = 'background:' + colors.backgroundColor;
            style += '; border-color:' + colors.borderColor;
            style += '; border-width: 2px';
            let span = '<span style="' + style + '"></span>';
            innerHtml += '<tr><td>' + span + body + '</td></tr>';
        });
        innerHtml += '</tbody>';

        let tableRoot = tooltipEl.querySelector('table');
        tableRoot.innerHTML = innerHtml;
    }

    // `this` will be the overall tooltip
    let position = this._chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left =
        position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top =
        position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.fontFamily = FONT_FAMILY;
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.padding =
        tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.borderRadius = '8px';
    tooltipEl.style.border =
        Array.isArray(bodyLines) && bodyLines.length > 0
            ? 'solid 1px rgba(241, 241, 241, 0.2)'
            : '0';
    tooltipEl.style.backgroundColor =
        Array.isArray(bodyLines) && bodyLines.length > 0
            ? 'rgba(38, 37, 45, 1)'
            : 'transparent';
}

export const commonDoughnutOptions = {
    tooltips: {
        // Disable the on-canvas tooltip
        enabled: false,
        custom: customToolTip,
        filter: function (tooltipItem, data) {
            let dataset = data.datasets[tooltipItem.datasetIndex];

            let labels = [];
            if (Array.isArray(dataset?.labels)) {
                labels = dataset.labels;
            } else {
                labels = data.labels;
            }

            let label = labels[tooltipItem.index];
            if (!label) {
                return false;
            } else {
                return true;
            }
        },
        callbacks: {
            label: function (tooltipItem, data) {
                let dataset = data.datasets[tooltipItem.datasetIndex];
                let index = tooltipItem.index;

                let labels = [];
                if (Array.isArray(dataset?.labels)) {
                    labels = dataset.labels;
                } else {
                    labels = data.labels;
                }

                return `${labels[index]}: ${dataset.data[index]}%`;
            },
        },
    },
    cutoutPercentage: 90,
    layout: {
        padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
    title: {
        display: false,
    },
};

export const getCommonBarOptions = ({
    yTicksDisplay = true,
    labelPosition = { x: 8, y: 4 },
    padding = {
        left: 0,
        right: 50,
        top: 0,
        bottom: 0,
    },
    yAxesMax = null,
    xAxesMax = null,
    xTicks = {},
    yTicks = {},
    barThickness = 4,
    maxBarThickness = 4,
    cornerRadius = 6,
    fullCornerRadius = true,
    barPercentage = 0.9,
    categoryPercentage = 0.8,
    fontColor,
    theme,
}) => {
    const returnObj = {
        cornerRadius,
        fullCornerRadius,
        layout: {
            padding,
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        hover: {
            animationDuration: 0,
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                        beginAtZero: true,
                        fontColor:
                            fontColor ||
                            (theme?.isLightTheme
                                ? 'hsl(250,10%,3%)'
                                : '#D3D2DA'),
                        ...(xTicks || {}),
                    },
                },
            ],
            yAxes: [
                {
                    categoryPercentage,
                    barPercentage,
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        display: yTicksDisplay,
                        fontColor:
                            fontColor ||
                            (theme?.isLightTheme
                                ? 'hsl(250,10%,3%)'
                                : '#D3D2DA'),
                        ...(yTicks || {}),
                    },
                },
            ],
        },
        tooltips: {
            // Disable the on-canvas tooltip
            enabled: false,
            custom: customToolTip,
        },
        animation: {
            onComplete: function () {
                let chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                ctx.font = `14px ${FONT_FAMILY}`;
                ctx.fillStyle = theme?.isLightTheme
                    ? 'hsl(250,10%,3%)'
                    : '#D3D2DA';

                this.data.datasets.forEach(function (dataset, i) {
                    let meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        let data = dataset.data[index];
                        ctx.fillText(
                            data,
                            bar._model.x + labelPosition?.x,
                            bar._model.y + labelPosition?.y
                        );
                    });
                });
            },
        },
    };

    if (yAxesMax) {
        returnObj.scales.yAxes[0].ticks.max = yAxesMax;
    }

    if (xAxesMax) {
        returnObj.scales.xAxes[0].ticks.max = xAxesMax;
    }

    if (barThickness) {
        returnObj.scales.yAxes[0].barThickness = barThickness;
    }

    if (maxBarThickness) {
        returnObj.scales.yAxes[0].maxBarThickness = maxBarThickness;
    }

    return { ...returnObj };
};

export const topBarOptions = ({ theme }) => {
    return {
        animation: {
            onComplete: function () {
                let chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                ctx.fillStyle = '#D3D2DA';

                this.data.datasets.forEach(function (dataset, i) {
                    let meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        let data = dataset.data[index];
                        ctx.font = `12px ${FONT_FAMILY}`;
                        ctx.fillStyle = theme?.isLightTheme
                            ? '#7E7E81'
                            : '#D3D2DA';
                        ctx.fillText(data, bar._model.x + 8, bar._model.y + 4);

                        let label = bar._model.label;
                        if (label && label.length > MAX_LABEL_LENGTH) {
                            label = `${label.substring(
                                0,
                                MAX_LABEL_LENGTH - 1
                            )}...`;
                        }
                        let xOffset = 10;
                        let yOffset = bar._model.y - 16;
                        ctx.font = `12px ${FONT_FAMILY}`;
                        if (index === 0) {
                            ctx.fillStyle = theme?.isLightTheme
                                ? '#000000'
                                : '#FCFCFC';
                        } else {
                            ctx.fillStyle = theme?.isLightTheme
                                ? '#7E7E81'
                                : '#D3D2DA';
                        }
                        ctx.fillText(label, xOffset, yOffset);
                    });
                });
            },
        },
    };
};

export const boothHeaders = [
    {
        key: 'booth_name',
        title: 'Booth Name',
        headerClass: 'text-left',
        colClass: 'text-truncate text-left',
        isSortable: true,
    },
    {
        key: 'visits',
        title: 'Visits',
        isSortable: true,
    },
    {
        key: 'cta_clicks',
        title: 'CTA Clicks',
        isSortable: true,
    },
    {
        key: 'video_views',
        title: 'Video Views',
        isSortable: true,
    },
];

export const sessionHeaders = [
    {
        key: 'session_name',
        title: 'Schedule',
        headerClass: 'text-left',
        colClass: 'text-truncate text-left',
        isSortable: true,
    },
    {
        key: 'duration',
        title: 'Duration',
        isSortable: true,
    },
    {
        key: 'attendance',
        title: 'Attendance',
        isSortable: true,
    },
    {
        key: 'messages',
        title: 'Messages',
        isSortable: true,
    },
    {
        key: 'questions',
        title: 'Questions Asked',
        isSortable: true,
    },
];

export const compareData = (a, b, type = 'number') => {
    if (type === 'number') {
        return (a || 0) - (b || 0);
    } else {
        let nameA = (a || '').toString().toUpperCase();
        let nameB = (b || '').toString().toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }
};

export const getPercentage = (numerator, denominator) => {
    let turnoutPercent = null;
    if (isNumber(numerator) && isNumber(denominator)) {
        if (denominator > 0) {
            turnoutPercent = Math.round((numerator * 100) / denominator);
        } else {
            turnoutPercent = 0;
        }
    }

    return turnoutPercent;
};

export const DEFAULT_DATE_PILL = { value: 'ALL_DAYS', label: 'All days' };

export const getEmptyBannerCommonProps = ({
    eventStarted = false,
    airmeetFormat = 'event',
    title = '',
    emptyContentStyle = {},
}) => ({
    justifyContent: 'center',
    alignItems: 'center',
    showIcons: true,
    borderColor: '#69657B',
    title: eventStarted
        ? 'This report is still being generated, check back soon!'
        : `This chart will be available after the ${airmeetFormat} has started`,
    emptyContentStyle,
});

export const formatDuration = (seconds) => {
    if (!isNumber(seconds)) return '-';

    let mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    let hours = Math.floor(mins / 60);
    mins = mins % 60;

    let returnText = '';
    if (hours) {
        returnText += `${hours}h`;
    }
    if (mins) {
        if (hours) {
            returnText += ' ';
        }
        returnText += `${mins}m`;
    }
    if (secs) {
        if (hours || mins) {
            returnText += ' ';
        }
        returnText += `${secs}s`;
    }

    return returnText || '0s';
};

export function getCustomTooltip({
    ref,
    tooltipModel,
    getTooltipTitle,
    getTooltipBody,
}) {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<table></table>';
        document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }

    function getBody(bodyItem) {
        return bodyItem.lines;
    }

    let bodyLines = [];
    // Set Text
    if (tooltipModel.body) {
        let titleLines = tooltipModel.title || [];
        bodyLines = tooltipModel.body.map(getBody);

        let innerHtml = '<thead>';

        titleLines.forEach((title, i) => {
            innerHtml += getTooltipTitle
                ? getTooltipTitle(title, bodyLines)
                : `
                <tr>
                    <th style="color: #BEBCC8; border-color: transparent; border-width: 0; font-size: 10px; line-height: 1.5em;">
                        ${title}
                    </th>
                </tr>
            `;
        });
        innerHtml += '</thead><tbody>';

        bodyLines.forEach((body) => {
            body.forEach((bdy) => {
                innerHtml += getTooltipBody
                    ? getTooltipBody(bdy)
                    : `
                    <tr>
                        <td style="color: #FCFCFC; font-size: 18px; line-height: 1em; font-weight: bold; padding-top: 20px; text-align-left;">
                            ${bdy}
                        </td>
                    </tr>
                `;
            });
        });
        innerHtml += '</tbody>';

        let tableRoot = tooltipEl.querySelector('table');
        tableRoot.innerHTML = innerHtml;
    }

    // ref will be the overall tooltip
    let position = ref._chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left =
        position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top =
        position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.fontFamily = FONT_FAMILY;
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.padding =
        tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.borderRadius = '4px';
    tooltipEl.style.border = '0';
    tooltipEl.style.backgroundColor = '#26252D';
    tooltipEl.style.padding = '8px';
}

export const getSearchMarkLabel = (searchText, label, color = '#BEBCC8') => {
    let returnLabel = label || '';
    if (searchText && searchText !== '') {
        const pos = returnLabel.toLowerCase().indexOf(searchText.toLowerCase());

        returnLabel =
            pos === -1
                ? returnLabel
                : returnLabel.substring(0, pos) +
                  `<mark style="background-color: rgba(149,128,255,0.25); color: ${color}; padding-left: 0; padding-right: 0;">${returnLabel.substring(
                      pos,
                      pos + searchText.length
                  )}</mark>` +
                  (label || '').substring(pos + searchText.length);
    }

    return returnLabel;
};
