export const SET_ONLINE_USERS = 'SET_ONLINE_USERS';
export const SET_CLOUD_USERS_COUNT = 'SET_CLOUD_USERS_COUNT';

export function setOnlineUsers(onlineUsers) {
    return {
        payload: onlineUsers,
        type: SET_ONLINE_USERS,
    };
}

export function setCloudUsersCount(count) {
    return {
        payload: {
            cloudUsersCount: count,
        },
        type: SET_CLOUD_USERS_COUNT,
    };
}
