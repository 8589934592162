import { API_SERVICE, callApi } from 'utils/apiService';

export const postAirGenieAutogenerate = ({
    entityName = 'airmeet',
    entityId,
    tone,
    type = 'eventTitle',
    description = '',
    eventSubType,
    ...rest
}) =>
    callApi({
        endpoint: '/autogenerate',
        method: 'POST',
        type: 'json',
        body: {
            entityName,
            entityId,
            type,
            description,
            tone,
            eventSubType,
            ...rest,
        },
        service: API_SERVICE.AIRGENIE,
    });

export const postAirGenieAutogenerateImage = ({
    entityName = 'airmeet',
    entityId,
    style = 'illustration',
    description = '',
    ...rest
}) =>
    callApi({
        endpoint: '/autogenerate-image',
        method: 'POST',
        type: 'json',
        body: { entityName, entityId, style, description, ...rest },
        service: API_SERVICE.AIRGENIE,
    });
