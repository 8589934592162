import { useShallowEqualSelector } from 'hooks/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    getIsVideoPlayOnStage,
    isCustomMediaSelector,
} from 'store/selectors/liveAirmeet';
import { Events } from 'utils/airmeet';
import { isCustomMediaStream } from 'utils/constants/live-airmeet';
import { PERSISTENT_STAGE_LOCATIONS } from 'utils/constants/sessions';
import { logger } from 'utils/logger';
import PermissionUtils from 'utils/permission-utils';

function useHideUserStreams({
    stageService,
    mode = PERSISTENT_STAGE_LOCATIONS.LIVESTAGE,
}) {
    const [isCustomMedia, setIsCustomMedia] = useState(false);

    const hideUserStreamsState = useShallowEqualSelector((state) => {
        return (
            !!state.liveAirmeet.genral?.hideUserStreams &&
            mode !== PERSISTENT_STAGE_LOCATIONS.BACKSTAGE
        );
    });
    const isVideoPlayOnStage = useShallowEqualSelector(getIsVideoPlayOnStage);

    const isCustomMediaState = useSelector(isCustomMediaSelector);
    const hideUserStreams =
        hideUserStreamsState && (isCustomMediaState || isVideoPlayOnStage);

    useEffect(() => {
        if (
            mode !== PERSISTENT_STAGE_LOCATIONS.BACKSTAGE &&
            PermissionUtils.isEventCloudHost() &&
            !PermissionUtils.isSessionScreenRecordingUser()
        ) {
            const checkCustomMedia = () => {
                const isCustomMedia =
                    stageService &&
                    stageService
                        .getStreams()
                        .find((stream) => isCustomMediaStream(stream.getId()));
                logger.debug('isCustomMedia Event', isCustomMedia);
                setIsCustomMedia(isCustomMedia);
            };

            stageService.on(Events.streamAddedCustomMedia, checkCustomMedia);
            stageService.on(Events.streamRemoveCustomMedia, checkCustomMedia);
            return () => {
                if (!stageService) {
                    return;
                }
                stageService.off(
                    Events.streamAddedCustomMedia,
                    checkCustomMedia
                );
                stageService.off(
                    Events.streamRemoveCustomMedia,
                    checkCustomMedia
                );
            };
        }
    }, [mode]);

    if (
        !hideUserStreams ||
        isVideoPlayOnStage ||
        !PermissionUtils.isEventCloudHost() ||
        PermissionUtils.isSessionScreenRecordingUser()
    ) {
        return hideUserStreams;
    }

    return isCustomMedia ? hideUserStreams : false;
}
export default useHideUserStreams;
