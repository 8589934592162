import { useEffect } from 'react';

/**
 * This is the same script injected in the hub homepage for handling tapfiliate clicks
 * and storing them in local storage.
 * This script should be present only on non prod envs.
 */
function useTapfiliateClickScript() {
    const clickScriptFunctionality = (accountId) => {
        return `(function (t, a, p) {
            t.TapfiliateObject = a;
            t[a] =
                t[a] ||
                function () {
                    (t[a].q = t[a].q || []).push(arguments);
                };
        })(window, 'tap');
        tap('create', '${accountId}', { integration: 'javascript' });
        function getJsonFromUrl(url) {
            if (!url) url = location.href;
            var question = url.indexOf('?');
            var hash = url.indexOf('#');
            if (hash == -1 && question == -1) return {};
            if (hash == -1) hash = url.length;
            var query =
                question == -1 || hash == question + 1
                    ? url.substring(hash)
                    : url.substring(question + 1, hash);
            var result = {};
            query.split('&').forEach(function (part) {
                if (!part) return;
                part = part.split('+').join(' '); // replace every + with space, regexp-free version
                var eq = part.indexOf('=');
                var key = eq > -1 ? part.substr(0, eq) : part;
                var val =
                    eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : '';
                var from = key.indexOf('[');
                if (from == -1) result[decodeURIComponent(key)] = val;
                else {
                    var to = key.indexOf(']', from);
                    var index = decodeURIComponent(key.substring(from + 1, to));
                    key = decodeURIComponent(key.substring(0, from));
                    if (!result[key]) result[key] = [];
                    if (!index) result[key].push(val);
                    else result[key][index] = val;
                }
            });
            return result;
        }
        const qs = window.location.search || '?';
        const params = getJsonFromUrl(qs);
        const referralCode = params['ref'];
        tap('detect', {}, (data) => {
            if ('id' in data) {
                const clickData = {
                    clickId: data['id'],
                    referralCode,
                    conversionPage: window.location.origin,
                };
                localStorage.setItem(
                    'new_tapfiliate_user',
                    JSON.stringify(clickData)
                );
            }
        });`;
    };
    useEffect(() => {
        // Skipping this script for prod and preprod environments, as is handled by hub homepage.
        if (process.env.REACT_APP_ENV === 'production') {
            return;
        }

        const mainEmbedScript = document.createElement('script');
        mainEmbedScript.src = 'https://script.tapfiliate.com/tapfiliate.js';
        mainEmbedScript.type = 'text/javascript';
        mainEmbedScript.async = true;

        const clickScript = document.createElement('script');
        clickScript.type = 'text/javascript';
        const inlineConfigClickScript = document.createTextNode(
            clickScriptFunctionality(
                process.env.REACT_APP_TAPFILIATE_ACCOUNT_ID
            )
        );
        clickScript.appendChild(inlineConfigClickScript);

        document.head.appendChild(mainEmbedScript);
        document.head.appendChild(clickScript);
    }, []);
}

export default useTapfiliateClickScript;
