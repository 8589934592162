import { webRTCFirewallDetectionStates } from 'utils/constants/live-airmeet';
import createReducer from '../../utils/createReducer';
import {
    ALLOW_TO_CAMERA_STATUS,
    FIREWALL_BLOCKING_CONNECTION,
    LITEMODE_SESSION_IN_PROGRESS,
    SET_ALLOW_FETCH_ICONS_FOR_EVENT_LANDING_PAGE,
    SET_ARE_ALL_COOKIES_ALLOWED,
    SET_IS_DASHBOARD_BRANDING_PAGE,
    SET_IS_EVENT_BRANDING_PAGE,
    SET_IS_EVENT_LANDING_BRANDING_PAGE,
    SET_IS_SERIES_LANDING_BRANDING_PAGE,
    SET_IS_FULLSTORY_RECORDING_ENABLED,
    SET_IS_INVITE_TO_STAGE,
    SET_IS_LIGHT_THEME_PAGE,
    SET_IS_NEW_ONBOARDING_PAGE,
    SET_IS_USER_WATCHING_REPLAY,
    TOGGLE_PERMISSION_DIALOG,
    UPDATE_ALLOW_TO_CAMERA_STATUS,
} from '../actions/genral';

const handlers = {};
const initialState = {
    allowToCameraStatus: ALLOW_TO_CAMERA_STATUS.init,
    permissionBoxVisible: false,
    hasBlockedWebRTCConnection: webRTCFirewallDetectionStates.init,
    isLightThemePage: false,
    isEventLandingBrandingPage: false,
    isSeriesLandingBrandingPage: false,
    isUserWatchingSessionReplay: false,
    litemodeSessionInProgress: false,
    isNewOnboardingPage: false,
    isInvitedToStage: false,
    isFullstoryRecordingEnabled: false,
    isFullScreen: false,
    isDashboardBrandingPage: false,
    allowFetchIcons: false,
    areAllCookiesAllowed: false,
};

handlers[UPDATE_ALLOW_TO_CAMERA_STATUS] = (state = initialState, action) => {
    return { ...state, allowToCameraStatus: action.payload.status };
};

handlers[TOGGLE_PERMISSION_DIALOG] = (state = initialState, action) => {
    return { ...state, permissionBoxVisible: action.payload };
};

handlers[FIREWALL_BLOCKING_CONNECTION] = (state = initialState, action) => {
    return { ...state, hasBlockedWebRTCConnection: action.payload };
};

handlers[FIREWALL_BLOCKING_CONNECTION] = (state = initialState, action) => {
    return { ...state, hasBlockedWebRTCConnection: action.payload };
};

handlers[SET_IS_LIGHT_THEME_PAGE] = (state = initialState, action) => {
    return { ...state, isLightThemePage: !!action.payload };
};

handlers[SET_IS_EVENT_LANDING_BRANDING_PAGE] = (
    state = initialState,
    action
) => {
    return { ...state, isEventLandingBrandingPage: !!action.payload };
};
handlers[SET_IS_SERIES_LANDING_BRANDING_PAGE] = (
    state = initialState,
    action
) => {
    return { ...state, isSeriesLandingBrandingPage: action.payload };
};
handlers[SET_ALLOW_FETCH_ICONS_FOR_EVENT_LANDING_PAGE] = (
    state = initialState,
    action
) => {
    return { ...state, allowFetchIcons: !!action.payload };
};
handlers[SET_IS_EVENT_BRANDING_PAGE] = (state = initialState, action) => {
    return { ...state, isEventBrandingPage: !!action.payload };
};

handlers[SET_IS_DASHBOARD_BRANDING_PAGE] = (state = initialState, action) => {
    return { ...state, isDashboardBrandingPage: !!action.payload };
};

handlers[SET_IS_NEW_ONBOARDING_PAGE] = (state = initialState, action) => {
    return { ...state, isNewOnboardingPage: !!action.payload };
};

handlers[SET_IS_USER_WATCHING_REPLAY] = (state = initialState, action) => {
    return { ...state, isUserWatchingSessionReplay: action.payload };
};

handlers[LITEMODE_SESSION_IN_PROGRESS] = (state = initialState, action) => {
    return {
        ...state,
        litemodeSessionInProgress: action.payload,
    };
};

handlers[SET_IS_INVITE_TO_STAGE] = (state = initialState, action) => {
    return {
        ...state,
        isInvitedToStage: action.payload,
    };
};

handlers[SET_IS_FULLSTORY_RECORDING_ENABLED] = (
    state = initialState,
    action
) => {
    return { ...state, isFullstoryRecordingEnabled: !!action.payload };
};
handlers[SET_ARE_ALL_COOKIES_ALLOWED] = (state = initialState, action) => {
    return {
        ...state,
        areAllCookiesAllowed: action.payload,
    };
};

export default createReducer(initialState, handlers);
