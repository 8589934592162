import { useSelector } from 'react-redux';
import { getUserLevelSessionState } from 'store/selectors';
import { CONTEXT_TYPE } from 'store/actions/userLevelSessionUpdates';
import { SESSION_TYPES } from 'components/Schedule/types';

interface FluidSpacePresenceCtx {
    [sessionId: string]: {
        meta: {
            isPreviewSession: boolean;
        };
    };
}
export const useGetFluidSpacePresenceCtx = (): FluidSpacePresenceCtx => {
    return useSelector(
        getUserLevelSessionState(
            CONTEXT_TYPE.PRESENCE,
            SESSION_TYPES.FLUID_LOUNGE
        )
    );
};

const useIsInFluidSpace = () => {
    const context = useGetFluidSpacePresenceCtx();
    return !!context;
};

export default useIsInFluidSpace;
