import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPermissionWorkflowVersion } from 'store/actions/liveAirmeet';
import { logger } from 'utils/logger';

const DEFAULT_PW_VERSION = 3;

const usePermissionWorkFlowConfig = () => {
    const dispatch = useDispatch();

    const updatePWVersion = useCallback(
        async (newVersion) => {
            logger.info(`Updating PW version. New version: ${newVersion}`);
            window.pwVersion = () => {
                return `Permission Workflow version is: ${newVersion}`;
            };
            dispatch(setPermissionWorkflowVersion(newVersion));
        },
        [dispatch]
    );

    useEffect(() => {
        updatePWVersion(DEFAULT_PW_VERSION);
    }, [updatePWVersion]);
};

export default usePermissionWorkFlowConfig;
