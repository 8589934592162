import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import useRouteBase from './common';
import AuthService from 'utils/authService';
import { updateUserInfo } from 'store/actions/auth';
import { callApi } from 'utils/apiService';
import history from '../../history';
import Loader from 'components/general/Loader';
import { fetchAirmeetUsers } from 'store/actions/Lounge';
import { GUEST_USER_JOIN_RESPONSE } from 'store/actions/airmeet';
import useFooter from 'hooks/useFooter';
import useHeader from 'hooks/useHeader';
import { HEADER_TEMPLATES } from 'components/header/templates';

const AppWebViewRoute = ({
    component: Component,
    loginComponent: LoginComponent,
    allowGuest = false,
    ...rest
}) => {
    const dispatch = useDispatch();
    const hasUser = useSelector((state) => !!state.auth.user);
    const userAccessCode = useSelector((state) => state.auth.accessCode);
    const isExactMatch = rest.exact;
    const isEventRoute = isExactMatch && rest.path === '/event/:id';
    const airmeetId = rest.computedMatch.params.id;
    const [initializeUser, setInitializeUser] = React.useState(false);
    useHeader(HEADER_TEMPLATES.none);
    useFooter({ visible: false });
    const ignoreLoggedInUser = useMemo(() => {
        if (!isEventRoute) {
            return false;
        }
        const query = new URLSearchParams(history.location.search);
        const code = query.get('code');
        return (
            code &&
            (hasUser || AuthService.isLogin(allowGuest)) &&
            (!userAccessCode || userAccessCode !== code)
        );
    }, [isEventRoute, hasUser, userAccessCode, allowGuest]);
    const redirectToLogin = () => {
        return history.replace('/');
    };
    const handleMobileError = (error) => {
        if ('Android' in window) {
            return window.Android.onRegistrationError(error);
        } else if ('webkit' in window) {
            return window.webkit.messageHandlers.onRegistrationError.postMessage(
                error
            );
        }
    };
    useEffect(() => {
        async function fetchUser() {
            try {
                const token =
                    document.cookie
                        .match('(^|;)\\s*accessToken\\s*=\\s*([^;]+)')
                        ?.pop() || null;
                if (!token) {
                    const userId =
                        document.cookie
                            .match('(^|;)\\s*userID\\s*=\\s*([^;]+)')
                            ?.pop() || null;
                    if (userId) {
                        dispatch(fetchAirmeetUsers(airmeetId, [userId])).then(
                            (response) => {
                                dispatch({
                                    type: GUEST_USER_JOIN_RESPONSE,
                                    payload: response[0],
                                });
                                setInitializeUser(true);
                            }
                        );
                    }
                } else if (token) {
                    await callApi({
                        endpoint: '/user',
                        method: 'GET',
                        transform: (response) => {
                            AuthService.setTokenUser(response, token);
                            AuthService.user = response;
                            //set user's value in redux.
                            dispatch(updateUserInfo(response));
                        },
                        token,
                    });
                    setInitializeUser(true);
                }
            } catch (error) {
                const formattedError = JSON.stringify({
                    message: error.message,
                });
                handleMobileError(formattedError);
                setInitializeUser(true);
            }
        }
        fetchUser();
        document.body.classList.add('hide-help-icon');
    }, [airmeetId, dispatch]);
    useRouteBase({ ...rest });

    const renderComponent = (props) => {
        if (!initializeUser) return <Loader type='logo' />;
        else if (
            !ignoreLoggedInUser &&
            (hasUser || AuthService.isLogin(allowGuest))
        )
            return <Component {...props} />;
        else if (LoginComponent) return <LoginComponent {...props} />;
        else redirectToLogin();
    };

    return <Route {...rest} render={(props) => renderComponent(props)} />;
};

export default AppWebViewRoute;
