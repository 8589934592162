import { API_SERVICE } from 'utils/apiService';
import { CALL_API } from '../api';

export const POST_EVENT_SERIES_REQUEST =
    'ES_DASHBOARD/POST_EVENT_SERIES_REQUEST';
export const POST_EVENT_SERIES_RESPONSE =
    'ES_DASHBOARD/POST_EVENT_SERIES_RESPONSE';

export const PATCH_EVENT_SERIES_REQUEST =
    'ES_DASHBOARD/PATCH_EVENT_SERIES_REQUEST';
export const PATCH_EVENT_SERIES_RESPONSE =
    'ES_DASHBOARD/PATCH_EVENT_SERIES_RESPONSE';

export const GET_EVENT_SERIES_REQUEST = 'ES_DASHBOARD/GET_EVENT_SERIES_REQUEST';
export const GET_EVENT_SERIES_RESPONSE =
    'ES_DASHBOARD/GET_EVENT_SERIES_RESPONSE';

export const POST_EVENT_SERIES_AIRMEET_REQUEST =
    'ES_DASHBOARD/POST_EVENT_SERIES_AIRMEET_REQUEST';
export const POST_EVENT_SERIES_AIRMEET_RESPONSE =
    'ES_DASHBOARD/POST_EVENT_SERIES_AIRMEET_RESPONSE';

export const DELETE_EVENT_SERIES_AIRMEET_REQUEST =
    'ES_DASHBOARD/DELETE_EVENT_SERIES_AIRMEET_REQUEST';
export const DELETE_EVENT_SERIES_AIRMEET_RESPONSE =
    'ES_DASHBOARD/DELETE_EVENT_SERIES_AIRMEET_RESPONSE';

export const GET_EVENT_SERIES_AIRMEETS_REQUEST =
    'ES_DASHBOARD/GET_EVENT_SERIES_AIRMEETS_REQUEST';
export const GET_EVENT_SERIES_AIRMEETS_RESPONSE =
    'ES_DASHBOARD/GET_EVENT_SERIES_AIRMEETS_RESPONSE';

export const GET_EVENT_SERIES_UPCOMING_AIRMEET_REQUEST =
    'ES_DASHBOARD/GET_EVENT_SERIES_UPCOMING_AIRMEET_REQUEST';

export const GET_EVENT_SERIES_COMBINED_INFO_REQUEST =
    'ES_DASHBOARD/GET_EVENT_SERIES_COMBINED_INFO_REQUEST';
export const GET_EVENT_SERIES_COMBINED_INFO_RESPONSE =
    'ES_DASHBOARD/GET_EVENT_SERIES_COMBINED_INFO_RESPONSE';

export const MANAGE_SERIES_ENTRY_PARTICIPANTS_RESPONSE =
    'MANAGE_SERIES_ENTRY_PARTICIPANTS_RESPONSE';
export const MANAGE_SERIES_ENTRY_PARTICIPANTS_REQUEST =
    'MANAGE_SERIES_ENTRY_PARTICIPANTS_REQUEST';
export const POST_EVENT_SERIES_REGISTRATION = 'POST_EVENT_SERIES_REGISTRATION';
export const EVENT_SERIES_INFO_UPDATE = 'EVENT_SERIES_INFO_UPDATE';
export const UPDATE_SERIES_SPEAKER_ORDER = 'UPDATE_SERIES_SPEAKER_ORDER';
export const CLEAR_EVENT_SERIES_DATA = 'CLEAR_EVENT_SERIES_DATA';
export const VERIFY_SERIES_ACCESS_CODE = 'VERIFY_SERIES_ACCESS_CODE';

export function postEventSeries({ body }) {
    return {
        [CALL_API]: {
            types: [POST_EVENT_SERIES_REQUEST, POST_EVENT_SERIES_RESPONSE],
            endpoint: '/series',
            body,
            type: 'json',
            method: 'POST',
        },
    };
}
export function updateSpeakerOrder({ body, seriesId }) {
    return {
        [CALL_API]: {
            types: [UPDATE_SERIES_SPEAKER_ORDER],
            endpoint: `/series/${seriesId}/update-speakers-config`,
            body,
            type: 'json',
            method: 'PUT',
        },
    };
}
export const setEventSeriesUpdate = (payload) => {
    return {
        type: EVENT_SERIES_INFO_UPDATE,
        payload,
    };
};
export function patchEventSeries({ body }) {
    const { uid, ...rest } = body || {};

    return {
        [CALL_API]: {
            types: [PATCH_EVENT_SERIES_REQUEST, PATCH_EVENT_SERIES_RESPONSE],
            endpoint: `/series/${uid}`,
            body: rest,
            type: 'json',
            method: 'PATCH',
        },
    };
}

export function getEventSeries({ eventSeriesId }) {
    return {
        [CALL_API]: {
            types: [GET_EVENT_SERIES_REQUEST, GET_EVENT_SERIES_RESPONSE],
            endpoint: `/series/${eventSeriesId}`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function getEventSeriesCombinedInfo(eventSeriesId, includes) {
    const req = {
        types: [
            GET_EVENT_SERIES_COMBINED_INFO_REQUEST,
            GET_EVENT_SERIES_COMBINED_INFO_RESPONSE,
        ],
        endpoint: `/series/${eventSeriesId}/info/combined`,
        type: 'json',
        method: 'GET',
        service: API_SERVICE.BFF,
    };
    if (includes) {
        req.query = {
            include: includes.join(','),
        };
    }
    return {
        [CALL_API]: req,
    };
}
export function getSeriesDashboardCombinedInfo(eventSeriesId, includes) {
    const req = {
        types: [
            GET_EVENT_SERIES_COMBINED_INFO_REQUEST,
            GET_EVENT_SERIES_COMBINED_INFO_RESPONSE,
        ],
        endpoint: `/series/${eventSeriesId}/dashboard/info/combined`,
        type: 'json',
        method: 'GET',
        service: API_SERVICE.BFF,
    };
    if (includes) {
        req.query = {
            include: includes.join(','),
        };
    }
    return {
        [CALL_API]: req,
    };
}

export function postEventSeriesAirmeet({ eventSeriesId, airmeetId }) {
    return {
        [CALL_API]: {
            types: [
                POST_EVENT_SERIES_AIRMEET_REQUEST,
                POST_EVENT_SERIES_AIRMEET_RESPONSE,
            ],
            endpoint: `/series/${eventSeriesId}/airmeet/${airmeetId}`,
            type: 'json',
            method: 'POST',
        },
    };
}

export function registerForSeries({ eventSeriesId, payload }) {
    return {
        [CALL_API]: {
            types: [POST_EVENT_SERIES_REGISTRATION],
            endpoint: `/series/${eventSeriesId}/register-attendee`,
            type: 'json',
            body: payload,
            method: 'POST',
        },
    };
}
export function registerForEventInSeries({
    eventSeriesId,
    airmeetId,
    payload,
}) {
    return {
        [CALL_API]: {
            types: [POST_EVENT_SERIES_REGISTRATION],
            endpoint: `/series/${eventSeriesId}/airmeet/${airmeetId}/register-attendee`,
            type: 'json',
            body: payload,
            method: 'POST',
        },
    };
}
export const verifySeriesAccessCode = ({ seriesId, code }) => {
    return {
        [CALL_API]: {
            types: [VERIFY_SERIES_ACCESS_CODE],
            endpoint: `/series/${seriesId}/direct-access-user`,
            type: 'json',
            body: { code },
            method: 'POST',
        },
    };
};
export function deleteEventSeriesAirmeet({ eventSeriesId, airmeetId }) {
    return {
        [CALL_API]: {
            types: [
                DELETE_EVENT_SERIES_AIRMEET_REQUEST,
                DELETE_EVENT_SERIES_AIRMEET_RESPONSE,
            ],
            endpoint: `/series/${eventSeriesId}/airmeet/${airmeetId}`,
            type: 'json',
            method: 'DELETE',
        },
    };
}

export function getEventSeriesAirmeets({
    eventSeriesId,
    query = {},
    showAllEvent = false,
}) {
    return {
        [CALL_API]: {
            types: [
                GET_EVENT_SERIES_AIRMEETS_REQUEST,
                GET_EVENT_SERIES_AIRMEETS_RESPONSE,
            ],
            endpoint: `/series/${eventSeriesId}/events-listing`,
            type: 'json',
            method: 'GET',
            extra: { page: query.page },
            query: showAllEvent
                ? { show_all_events: true }
                : {
                      includes: 'events_list',
                      ...query,
                  },
        },
    };
}
export function clearEventSeriesState() {
    return {
        type: CLEAR_EVENT_SERIES_DATA,
        payload: true,
    };
}
