import Btn from 'atoms/Button/btn';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text/';
import isString from 'lodash/isString';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { getHexColorValue } from 'utils/colors';

interface ColorInputProps {
    value: string;
    name?: string;
    gradientType?: string;
    onClick?: () => void;
    fullWidth?: boolean;
}

const ColorInput: React.FC<ColorInputProps> = ({
    value,
    name,
    gradientType = '',
    onClick,
    fullWidth = false,
}) => {
    const hexValue = useMemo(
        () =>
            Array.isArray(value)
                ? value.map((item) => getHexColorValue(item))
                : getHexColorValue(value),
        [value]
    );

    return (
        <Wrapper variant='link' onClick={onClick} fullWidth={fullWidth}>
            <ColorCircle
                color={hexValue}
                gradientType={gradientType}
                aria-hidden='true'
            />
            {hexValue && isString(hexValue) && !gradientType && (
                <Text variant='caption' color='text.default.primary' pr='4px'>
                    {hexValue.replace('#', '').toUpperCase()}
                </Text>
            )}
            {name && !gradientType && hexValue && (
                <Text
                    variant='caption'
                    color='text.default.secondary'
                    aria-hidden='true'
                >
                    &#x2022;
                </Text>
            )}
            {(gradientType || hexValue) && (
                <Name variant='caption' color='text.default.secondary' pl='4px'>
                    {gradientType ? `${gradientType} gradient` : name}
                </Name>
            )}
        </Wrapper>
    );
};

export default ColorInput;

const ColorCircle = styled(FlexDiv)`
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
    margin-right: 8px;
    ${({ gradientType, color }) =>
        gradientType
            ? gradientType === 'linear' &&
              Array.isArray(color) &&
              color.length > 1
                ? `background: linear-gradient(90deg, ${color[0]} 0%, ${color[1]} 100%)`
                : `background: radial-gradient(circle, ${color[0]} 0%, ${color[1]} 100%)`
            : `background-color: ${color}`};
    border-radius: 50%;
    border: solid 1px ${({ theme }) => theme.colors.border.default1};
`;

const Wrapper = styled(Btn)`
    text-decoration: none;
    background-color: ${({ theme }) => theme.colors.fill.default2};
    border: 1px solid ${({ theme }) => theme.colors.border.default1};
    border-radius: ${({ theme }) => theme.radii.default};
    padding: 6px 12px 6px 6px;
    height: auto;
    &:hover,
    &:focus {
        background-color: ${({ theme }) => theme.colors.fill.default3};
    }
    ${({ fullWidth, theme }) =>
        fullWidth &&
        `width:100%; justify-content:flex-start; padding-left: ${theme.space.x2}`}
`;

const Name = styled(Text)`
    text-transform: capitalize;
`;
