import { useSelector } from 'react-redux';
import FirebaseLogger, {
    EventAnalyticsLoggerTypes,
} from 'utils/firebaseLogger';

export default function useOnboardingAnalytics() {
    const { user } = useSelector((state) => state.auth);

    const { onboarding } = EventAnalyticsLoggerTypes;

    const logAnalyticsEvent = (eventName, data = {}) => {
        FirebaseLogger.onboarding(eventName, {
            ...(user?.id ? { user_id: user.id } : {}),
            ...data,
        });
    };

    return { logAnalyticsEvent, analyticsEventType: onboarding };
}
