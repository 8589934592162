import { API_SERVICE } from 'utils/apiService';
import { CALL_API } from '../api';

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';

export function verifyEmail({ token }) {
    return {
        [CALL_API]: {
            types: [VERIFY_EMAIL_REQUEST, VERIFY_EMAIL_SUCCESS],
            endpoint: '/user/verify',
            body: {
                token,
            },
            type: 'json',
            method: 'POST',
            service: API_SERVICE.CAS_ZEUS,
        },
    };
}
