import { cdnImage } from 'utils/core';
import { getCurrentTenantData } from 'utils/tenant';

export const DEFAULT_BACKGROUND_TYPE = 'none';

export const DEFAULT_BACKGROUND_OPACITY = 1;
export const DEFAULT_BACKGROUND_BLUR = 0;
export const DEFAULT_BACKGROUND_ZOOM = 0;

export const DEFAULT_PATTERNS = [
    {
        isDefault: true,
        id: 1,
        thumbnail: cdnImage(
            '/images/summit/event-branding/patterns/thumbnails/pattern-1.svg'
        ),
    },
    {
        isDefault: true,
        id: 2,
        thumbnail: cdnImage(
            '/images/summit/event-branding/patterns/thumbnails/pattern-2.svg'
        ),
    },
    {
        isDefault: true,
        id: 3,
        thumbnail: cdnImage(
            '/images/summit/event-branding/patterns/thumbnails/pattern-3.svg'
        ),
    },
    {
        isDefault: true,
        id: 4,
        thumbnail: cdnImage(
            '/images/summit/event-branding/patterns/thumbnails/pattern-4.svg'
        ),
    },
    {
        isDefault: true,
        id: 5,
        thumbnail: cdnImage(
            '/images/summit/event-branding/patterns/thumbnails/pattern-5.svg'
        ),
    },
];

export const DEFAULT_BACKGROUND_IMAGES = [
    {
        url: cdnImage('/images/summit/event-branding/bg-img/bg-img-1-2.jpeg'),
        isDefault: true,
    },
    {
        url: cdnImage('/images/summit/event-branding/bg-img/bg-img-2-2.jpeg'),
        isDefault: true,
    },
    {
        url: cdnImage('/images/summit/event-branding/bg-img/bg-img-3-2.jpeg'),
        isDefault: true,
    },
    {
        url: cdnImage('/images/summit/event-branding/bg-img/bg-img-4-1.jpg'),
        isDefault: true,
        isDefaultDarkThemeBg: true,
    },
    {
        url: cdnImage('/images/summit/event-branding/bg-img/bg-img-5-1.jpg'),
        isDefault: true,
        isDefaultLightThemeBg: true,
    },
];

const defaultColor = getCurrentTenantData('primaryColor');
export const DEFAULT_COLORS = [
    `hsl(${defaultColor.H},${defaultColor.S}%,${defaultColor.L}%)`,
    'hsl(220,86%,64%)',
    'hsl(184,44%,55%)',
    'hsl(36,86%,55%)',
    'hsl(345,86%,69%)',
];

export const DEFAULT_GRADIENTS = [
    ['#826AED', '#D4F5E1'],
    ['#FFB900', '#D7293E'],
    ['#6ADB95', '#3EA5FC'],
    ['#F4426C', '#FBF2B1'],
    ['#C2137B', '#3EA5FC'],
    ['#6ADB95', '#FFB900'],
    ['#5332E6', '#A45FD2'],
];

export const COLOR_BACKGROUND_TYPE_OPTIONS = [
    { id: 'solidColor', label: 'Solid' },
    { id: 'radialGradient', label: 'Radial Gradient' },
    { id: 'linearGradient', label: 'Linear Gradient' },
];

export const getDefaultBackground = (value = '') => {
    return {
        meta: {
            blur: 0,
            zoom: 0,
            opacity: 1,
        },
        type: 'image',
        value:
            value ||
            cdnImage('/images/summit/event-branding/bg-img/bg-img-4-1.jpg'),
    };
};
