import { API_SERVICE } from 'utils/apiService';
import { CALL_API } from '../api';

export const ENTRYPOINT_REQUEST = 'ENTRYPOINT_REQUEST';
export const ENTRYPOINT_RESPONSE = 'ENTRYPOINT_RESPONSE';
export const UPLOAD_CSV_REQUEST = 'UPLOAD_CSV_REQUEST';
export const UPLOAD_CSV_RESPONSE = 'UPLOAD_CSV_RESPONSE';
export const VERIFY_ACCESS_REQUEST = 'VERIFY_ACCESS_REQUEST';
export const VERIFY_ACCESS_RESPONSE = 'VERIFY_ACCESS_RESPONSE';
export const SAVE_REGISTRATION_FORM_REQUEST = 'SAVE_REGISTRATION_FORM_REQUEST';
export const SAVE_REGISTRATION_FORM_RESPONSE =
    'SAVE_REGISTRATION_FORM_RESPONSE';
export const UPDATE_REGISTRATION_FORM_REQUEST =
    'UPDATE_REGISTRATION_FORM_REQUEST';
export const UPDATE_REGISTRATION_FORM_RESPONSE =
    'UPDATE_REGISTRATION_FORM_RESPONSE';
export const GET_REGISTRATION_FORM_REQUEST = 'GET_REGISTRATION_FORM_REQUEST';
export const GET_REGISTRATION_FORM_RESPONSE = 'GET_REGISTRATION_FORM_RESPONSE';
export const ENTRYPOINT_REQUEST_V1 = 'ENTRYPOINT_REQUEST_V1';
export const ENTRYPOINT_RESPONSE_V1 = 'ENTRYPOINT_RESPONSE_V1';
export const EVENT_REPLAY_ENTRY_REQUEST = 'EVENT_REPLAY_ENTRY_REQUEST';

export function updateAirmeetEntrypoints({ airmeetId, entrypoints }) {
    return {
        [CALL_API]: {
            types: [ENTRYPOINT_REQUEST, ENTRYPOINT_RESPONSE],
            endpoint: '/airmeet',
            body: {
                airmeetId,
                ...entrypoints,
            },
            type: 'json',
            method: 'PUT',
        },
    };
}

export function newUpdateAirmeetEntrypoints({ airmeetId, body }) {
    return {
        [CALL_API]: {
            types: [ENTRYPOINT_REQUEST, ENTRYPOINT_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/event-entry-rules`,
            body,
            type: 'json',
            method: 'PUT',
        },
    };
}

export function getEventEntrypoints({ airmeetId }) {
    return {
        [CALL_API]: {
            types: [ENTRYPOINT_REQUEST_V1, ENTRYPOINT_RESPONSE_V1],
            endpoint: `/airmeet/${airmeetId}/event-entry-rules`,
            type: 'json',
            method: 'GET',
        },
    };
}

export function uploadCSV({
    airmeetId,
    csv,
    sendEmailInvite,
    toRegisterAttendees,
    allowPartialRegistrations,
    isEventSeries = false,
}) {
    const body = new FormData();
    body.append('csv', csv);
    body.append('sendEmailInvite', sendEmailInvite);
    body.append('toRegisterAttendees', toRegisterAttendees);
    body.append('allowPartialRegistrations', allowPartialRegistrations);
    return {
        [CALL_API]: {
            types: [UPLOAD_CSV_REQUEST, UPLOAD_CSV_RESPONSE],
            endpoint: isEventSeries
                ? `/series/${airmeetId}/attendee-list-upload`
                : `/airmeet/${airmeetId}/attendee-list-upload`,
            body,
            method: 'POST',
        },
    };
}

export function saveRegistrationForm({ airmeetId, formToUpdate }) {
    return {
        type: SAVE_REGISTRATION_FORM_REQUEST,
        [CALL_API]: {
            types: [
                SAVE_REGISTRATION_FORM_REQUEST,
                SAVE_REGISTRATION_FORM_RESPONSE,
            ],
            body: formToUpdate,
            endpoint: `/airmeet/${airmeetId}/custom/registration`,
            method: 'POST',
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateRegistrationForm({ airmeetId, formToUpdate, formId }) {
    return {
        type: UPDATE_REGISTRATION_FORM_REQUEST,
        [CALL_API]: {
            types: [
                UPDATE_REGISTRATION_FORM_REQUEST,
                UPDATE_REGISTRATION_FORM_RESPONSE,
            ],
            body: formToUpdate,
            endpoint: `/airmeet/${airmeetId}/custom/registration/${formId}`,
            method: 'PUT',
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}

export function getRegistrationForm({ airmeetId }) {
    return {
        type: GET_REGISTRATION_FORM_REQUEST,
        [CALL_API]: {
            types: [
                GET_REGISTRATION_FORM_REQUEST,
                GET_REGISTRATION_FORM_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/custom/registration`,
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function updateEventReplayEntry({ airmeetId, entryType }) {
    return {
        [CALL_API]: {
            types: [EVENT_REPLAY_ENTRY_REQUEST],
            endpoint: `/airmeet/${airmeetId}`,
            body: {
                postEventEntryType: entryType,
            },
            type: 'json',
            method: 'PATCH',
        },
    };
}

export function verifyAttendeeAccess({ airmeetId, exhibitorToken = '' }) {
    let queryParam = '';
    if (exhibitorToken) {
        queryParam = `?exhibitorToken=${exhibitorToken}`;
    }
    return {
        [CALL_API]: {
            types: [VERIFY_ACCESS_REQUEST, VERIFY_ACCESS_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/verify-magic-link/${queryParam}`,
        },
    };
}
