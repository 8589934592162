export const CONVERSATIONS_TAB = 'Conversations';
export const PEOPLE_IN_SESSION_TAB = 'People in Session';
export const FINISHED_CONVERSATION_TAB = 'Conversation History';

export const QUESTION_STATUS = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
};

export function sortByName(alphabet) {
    return function (a, b) {
        var index_a = alphabet.indexOf(a.name[0].toLowerCase()),
            index_b = alphabet.indexOf(b.name[0].toLowerCase());

        if (index_a === index_b) {
            // same first character, sort regular
            if (a < b) {
                return -1;
            } else if (a > b) {
                return 1;
            }
            return 0;
        } else {
            return index_a - index_b;
        }
    };
}

export const sorter = sortByName(
    '*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyz'
);
