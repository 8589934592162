import useVenueData from 'hooks/reception/useVenueData';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { LAYOUT_TYPES } from 'utils/constants/customLayout/customLayout';

const useNewDefaultReceptionFlag = () => {
    const {
        airmeet: {
            data: {
                currentAirmeet: { is_default_reception: isDefaultReception },
            },
            featureFlags: { isConferenceEvent },
            airmeetId,
        },
    } = useLiveAirmeetContext();

    const { loading: isVenueDataLoading, data: venueData } = useVenueData({
        airmeetId,
        isConferenceEvent,
    });

    if (!venueData && isVenueDataLoading) {
        return null;
    }

    const { DEFAULT } = LAYOUT_TYPES;
    const { layoutType = DEFAULT } = venueData || {};

    return layoutType === DEFAULT && isDefaultReception && isConferenceEvent;
};

export default useNewDefaultReceptionFlag;
