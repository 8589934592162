import React, { Suspense, useMemo } from 'react';
import {
    getQueryParamFromUrl,
    getAccessCodeFromSession,
    getQueryParamFromSession,
    accessCodeInUrl,
    setAccessCodeInSession,
    authenticateAccessCode,
} from 'hooks/live-airmeet/useAccessCodeUserToken';
import LoaderV2 from 'components/general/LoaderV2';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import history from '../../history';
import AuthService from '../../utils/authService';
import useRouteBase from './common';
import { EXHIBITOR_CODE } from 'utils/constants/airmeet';

const PrivateRoute = ({
    component: Component,
    loginComponent: LoginComponent,
    allowGuest = false,
    componentProps = {},
    isLazy = false,
    ...rest
}) => {
    useRouteBase({ ...rest });
    const hasUser = useSelector((state) => !!state.auth.user);
    const userAccessCode = useSelector((state) => state.auth.accessCode);
    const isExactMatch = rest.exact;
    const isEventRoute = isExactMatch && ['/event/:id'].includes(rest.path);

    const ignoreLoggedInUser = useMemo(() => {
        if (!isEventRoute) {
            return false;
        }
        let queryName = getQueryParamFromUrl(history);
        if (queryName) {
            // checking if query params is present in the url, if present in the url then setting it to session storage and removing it from url.
            setAccessCodeInSession({
                queryParam: queryName,
                tokenVal: accessCodeInUrl(queryName, history),
                airmeetId: rest?.computedMatch?.params?.id,
                replaceUrl: rest?.location?.pathname,
            });
            return false;
        }
        authenticateAccessCode(rest?.computedMatch?.params?.id);
        let queryNameInSession = getQueryParamFromSession(
            rest?.computedMatch?.params?.id
        );
        const magicCodeDetail = getAccessCodeFromSession();
        const code = magicCodeDetail && magicCodeDetail[queryNameInSession];

        const baseCondn = code && (hasUser || AuthService.isLogin(allowGuest));
        let returnVal = false;
        if (queryNameInSession === EXHIBITOR_CODE) {
            returnVal = baseCondn && userAccessCode;
        } else {
            returnVal =
                baseCondn && (!userAccessCode || userAccessCode !== code);
        }
        return returnVal;
    }, [
        isEventRoute,
        hasUser,
        userAccessCode,
        rest?.location?.pathname,
        rest?.computedMatch?.params.id,
        allowGuest,
    ]);

    const redirectToLogin = () => {
        // Add redirection link to the same page
        const redirectURL = encodeURIComponent(window.location.href);
        return history.replace(`/signin?redirect=${redirectURL}`);
    };

    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to homepage
        // In case of an event, though, redirect them to /e/:id page
        <Route
            {...rest}
            render={(props) =>
                !ignoreLoggedInUser &&
                (hasUser || AuthService.isLogin(allowGuest)) ? (
                    isLazy ? (
                        <Suspense
                            fallback={
                                <LoaderWrapper>
                                    <LoaderV2 />
                                </LoaderWrapper>
                            }
                        >
                            <Component {...props} {...(componentProps || {})} />
                        </Suspense>
                    ) : (
                        <Component {...props} {...(componentProps || {})} />
                    )
                ) : LoginComponent ? (
                    <LoginComponent {...props} />
                ) : (
                    redirectToLogin()
                )
            }
        />
    );
};

export default PrivateRoute;

const LoaderWrapper = styled(FlexDiv)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 146px);
`;
