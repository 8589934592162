import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

function useDeepEqualState<T>(state: T, defaultState = state) {
    const [latestState, setLatestState] = useState<T>(defaultState);
    useEffect(() => {
        setLatestState((prevState) => {
            const onlyReferenceChange = isEqual(state, prevState);
            return onlyReferenceChange ? prevState : state;
        });
    }, [state]);
    return latestState;
}

export default useDeepEqualState;
