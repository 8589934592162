import { Typography } from 'foundations/typography';
import moment from 'moment';
import React from 'react';
import { getCurrentTenantData } from 'utils/tenant';

// Leads
export const VENUE_SETTINGS_LEAD = 'venue_settings';
export const SPONSORS_LEAD = 'sponsors';
export const BOOTHS_LEAD = 'booths';
export const BRANDING_LEAD = 'branding';

export const SHOW = 'SHOW';
export const HIDE = 'HIDE';
export const ATTRACT = 'ATTRACT';

export const ATTENDEE_LIST_TEMPLATE_URL =
    'https://airmeet-files.s3.ap-south-1.amazonaws.com/templates/sample_attendee_list_v2.csv';

export const CUSTOM_REGISTRATION_DEFAULT_SAMPLE_ATTENDEE_LIST =
    'https://airmeet-files.s3.ap-south-1.amazonaws.com/templates/custom_registration_default_sample_attendee_list_v2.csv';

// AIRMEET EVENT AND SESSION STATUS
export const STATUS_CREATED = 'CREATED';
export const STATUS_ONGOING = 'ONGOING';
export const STATUS_PAUSED = 'PAUSED';
export const STATUS_FINISHED = 'FINISHED';

export const ENTRY_TYPES = {
    LOGIN_BASED: 'LOGIN_BASED',
    DOMAIN_RESTRICTED: 'DOMAIN_RESTRICTED',
    CSV_UPLOAD: 'CSV_UPLOAD',
    EMAIL_AUTHENTICATION: 'EMAIL_AUTHENTICATION',
    PUBLIC: 'PUBLIC',
};
export const IN_PERSON_EVENT_ENTRIES = ['email', 'login', 'invitation'];

export const getEventEntryAccessOptions = ({
    hideAuthProviders = false,
    isInPersonEvent = false,
    airmeetFormat = 'event',
    isOnRbpPlan = null,
}) => {
    const eventEntryOptions = {
        email: {
            value: 'email',
            label: isInPersonEvent
                ? 'Anyone can register'
                : 'Anyone can enter via their unique link',
            desc: isInPersonEvent
                ? `Users can register for the ${airmeetFormat} directly without having to verify their email ID. Their email ID will still be captured at the time of registration`
                : `People can enter the ${airmeetFormat} hassle-free without requiring OTP or sign in. The unique ${airmeetFormat} access link will be sent to the email address provided by the registrant.`,
            infoText: (
                <>
                    <p>
                        Use this to verify the identity of attendees and manage
                        abuse effectively.
                    </p>
                    <p>
                        Please note that the registration shall be completed
                        only when he/she clicks on the unique link.
                    </p>
                </>
            ),
            entryType: ENTRY_TYPES.EMAIL_AUTHENTICATION,
            icon: 'mail',
            recommended: true,
            labelV2: isInPersonEvent
                ? 'Anyone can register'
                : 'Enter via unique link',
            labelV2Full: isInPersonEvent
                ? 'Anyone can register'
                : 'Register with email verification - Enter via unique link',
            descV2: isInPersonEvent
                ? 'Users can register for the event directly without having to verify their email ID. Their email ID will still be captured at the time of registration'
                : 'Users will only be able to enter via a unique link sent to their registered email.',
            group: 'email',
            changeLabel:
                'This entry type would allow the users to join only via a unique link sent to their registered email id.',
        },
        login: {
            value: 'login',
            label: isInPersonEvent
                ? 'Anyone can register after email verification'
                : 'Anyone can enter after sign in',
            desc: isInPersonEvent
                ? `Users can register for the event only after after signing in with either Google, LinkedIn, Facebook, Twitter, Apple ID or ${getCurrentTenantData(
                      'name'
                  )} login.`
                : `People can enter the ${airmeetFormat} after signing in with ${
                      hideAuthProviders
                          ? ''
                          : 'either Google, LinkedIn, Facebook, Twitter, Apple ID or'
                  } ${getCurrentTenantData('name')} login.`,
            infoText: `Use this to verify the identity of attendees and keep them signed-in to the ${airmeetFormat}.`,
            entryType: ENTRY_TYPES.LOGIN_BASED,
            icon: isInPersonEvent ? 'globe' : 'safe',
            recommended: false,
            labelV2: isInPersonEvent
                ? 'Anyone can register after email verification'
                : 'Enter via sign-in',
            labelV2Full: isInPersonEvent
                ? 'Anyone can register after email verification'
                : 'Register with email verification - Enter via sign-in',
            descV2: isInPersonEvent
                ? `Users can register for the event only after after signing in with either Google, LinkedIn, Facebook, Twitter, Apple ID or ${getCurrentTenantData(
                      'name'
                  )} login.`
                : `Users can enter the ${airmeetFormat} after signing in with either Google, LinkedIn, Facebook, X (formerly Twitter), Apple ID or ${getCurrentTenantData(
                      'name'
                  )} login.`,
            group: 'email',
            changeLabel: isInPersonEvent
                ? `This registration access type would require the users to register after signing in with either Google, LinkedIn, Facebook, Twitter, Apple ID or ${getCurrentTenantData(
                      'name'
                  )} login.`
                : `This entry type would require the users to enter after signing in with either Google, LinkedIn, Facebook, Twitter, Apple ID or ${getCurrentTenantData(
                      'name'
                  )} login.`,
        },
        link: {
            value: 'link',
            label: 'Anyone can enter without sign in',
            desc: `People can enter the ${airmeetFormat} link without any authentication. The email addresses of the attendees might not be captured. It is recommended NOT to change this access rule after ${airmeetFormat} has opened to avoid potential attendee experience issues.`,
            infoText:
                'Please note that it will not be possible to verify the identity of attendees through this method. Hence customization and access to multiple features will be restricted for security reasons.',
            entryType: ENTRY_TYPES.PUBLIC,
            icon: 'globe',
            labelV2: 'Register without email verification',
            labelV2Full: 'Register without email verification',
            descV2:
                'Users are not required to enter their email ID. Users will be required to fill the registration form.',
            changeLabel:
                'This entry type allows the users to enter without providing their email ID. Users will be required to fill the registration form.',
        },
        invitation: {
            value: 'invitation',
            label: 'Only invited people can enter',
            desc: isInPersonEvent
                ? 'Only users added to the event by the organiser would be allowed to register by clicking on the unique link sent to their email. Users cannot register from the landing page'
                : `People on the participant list can enter the ${airmeetFormat} after clicking on the unique link sent to their email address.`,

            infoText: (
                <>
                    <p>Use this when you have prepared a list of invitees.</p>
                    <p>
                        Please note that the registration shall be completed
                        only when he/she clicks on the unique link.
                    </p>
                </>
            ),
            entryType: ENTRY_TYPES.CSV_UPLOAD,
            icon: 'lock_shield',
            labelV2: isInPersonEvent
                ? 'Only invited people can enter'
                : 'Only invited people can enter',
            labelV2Full: isInPersonEvent
                ? 'Only invited people can enter'
                : 'Only invited people can enter',
            descV2: isInPersonEvent
                ? 'Only users added to the event by the organiser would be allowed to register by clicking on the unique link sent to their email. Users cannot register from the landing page'
                : `Only users added to the ${airmeetFormat} by the organizer would be allowed to enter by clicking on the unique link sent to their email. Users cannot register from the landing page.`,
            changeLabel: isInPersonEvent
                ? `This registration access type only allows users added by the organizers to register for the ${airmeetFormat}. Users cannot register from the landing page`
                : `This entry type only allows users added by the organizers to enter the ${airmeetFormat}. Users cannot register from the landing page`,
        },
    };

    if (isInPersonEvent) {
        const options = {};

        IN_PERSON_EVENT_ENTRIES.forEach((key) => {
            options[key] = eventEntryOptions[key];
        });

        if (isOnRbpPlan) {
            delete options['email'];
        }

        return options;
    } else {
        return eventEntryOptions;
    }
};

export const getSeriesEntryAccessOptions = () => ({
    email: {
        value: 'email',
        label: `Anyone can enter via their unique link (Recommended for public event series)`,
        desc:
            'People can enter the event series hassle-free without requiring OTP or sign in. The unique event series access link will be sent to the email address provided by the registrant.',
        infoText: (
            <>
                <Typography variant={'subtext2'} color={'ambience.12'}>
                    Use this to verify the identity of attendees and manage
                    abuse effectively.
                </Typography>
                <Typography
                    variant={'subtext2'}
                    color={'ambience.12'}
                    mt={'16px'}
                >
                    Please note that the registration shall be completed only
                    when he/she clicks on the unique link.
                </Typography>
            </>
        ),
        entryType: ENTRY_TYPES.EMAIL_AUTHENTICATION,
        icon: 'mail',
        labelV2: 'Enter via unique link',
        descV2:
            'Users will only be able to enter via a unique link sent to their registered email.',
        changeLabel:
            'This would change the entry type for all events in the series. This entry type would allow the users to join only via a unique link sent to their registered email id.',
    },
    login: {
        value: 'login',
        label: 'Anyone can enter after sign in',
        desc: `People can enter the event series after signing in with either Google, LinkedIn, Facebook, Twitter, Apple ID or ${getCurrentTenantData(
            'name'
        )} login.`,
        infoText: (
            <>
                <Typography variant={'subtext2'} color={'ambience.12'}>
                    Use this to verify the identity of attendees and keep them
                    signed-in to the event series.
                </Typography>
            </>
        ),
        entryType: ENTRY_TYPES.LOGIN_BASED,
        icon: 'safe',
        labelV2: 'Enter via sign-in',
        descV2: `Users can enter the event series after signing in with either Google, LinkedIn, Facebook, X (formerly Twitter), Apple ID or ${getCurrentTenantData(
            'name'
        )} login.`,
        changeLabel: `This would change the entry type for all events in the series. This entry type would require the users to enter after signing in with either Google, LinkedIn, Facebook, Twitter, Apple ID or ${getCurrentTenantData(
            'name'
        )} login.`,
    },
    invitation: {
        value: 'invitation',
        label: 'Only people who are invited can enter',
        desc:
            'People on the participant list can enter the event series after clicking on the unique link sent to their email address.',
        infoText: (
            <>
                <Typography variant={'subtext2'} color={'ambience.12'}>
                    Use this when you have prepared a list of invitees.
                </Typography>
                <Typography
                    variant={'subtext2'}
                    color={'ambience.12'}
                    mt={'16px'}
                >
                    Please note that the registration shall be completed only
                    when he/she clicks on the unique link.
                </Typography>
            </>
        ),
        entryType: ENTRY_TYPES.CSV_UPLOAD,
        icon: 'lock_shield',
        labelV2: 'Only invited people can enter',
        descV2:
            'Only users added to the event series by the organizer would be allowed to enter by clicking on the unique link sent to their email. Users cannot register from the landing page.',
        changeLabel:
            'This would change the entry type for all events in the series. This entry type only allows users added by the organizers to enter the event series. Users cannot register from the landing page',
    },
});

export const SPECIFIC_DOMAIN = 'Only people from a specific domain can enter';

export const ATTENDANCE_TYPE_FIELD_NAME = 'attendanceType';

export const RECOMMENDED_ENTRY_TYPE_SUFFIX = ' (recommended)';

// Cut off dates
export const CUT_OFF_DATE_RECORDINGS = '2020-12-02';
export const CUT_OFF_DATE_AUDIENCE = '2022-02-09';
export const CUT_OFF_DATE_NEW_LANDING_PAGE =
    process.env.REACT_APP_ENV === 'production' ? '2022-10-07' : '2022-08-01';
export const CUT_OFF_DATE_LANDING_PAGE_NEW_TAG = moment(
    CUT_OFF_DATE_NEW_LANDING_PAGE
)
    .add(60, 'days')
    .format('YYYY-MM-DD');
export const CUT_OFF_DATE_LIGHT_THEME_DASHBOARD =
    process.env.REACT_APP_ENV === 'production' ? '2023-05-18' : '2023-02-01';
export const CUT_OFF_DATE_EVENT_DASH_V3 =
    process.env.REACT_APP_ENV === 'production' ? '2023-07-26' : '2023-07-26';
export const SHOW_RECORDINGS_FOR_ALL_AIRMEETS = JSON.parse(
    process.env.REACT_APP_SHOW_RECORDINGS_FOR_ALL_AIRMEETS || false
);

// GUIDELINES FOR UPLOADING A BACKDROP
export const BACKDROP_GUIDELINES_LINK =
    'https://help.airmeet.com/support/solutions/articles/82000443828-how-to-add-stage-backdrop-in-airmeet-sessions';
export const SESSION_TYPE_HOSTING = 'HOSTING';
export const SESSION_TYPE_STREAMING = 'STREAMING';
export const SESSION_TYPE_BOOTH = 'BOOTH';
export const STREAMING_HELP_URL =
    'https://help.airmeet.com/support/solutions/articles/82000443829-how-to-stream-in-your-events-into-airmeet-via-rtmp-input';
