import { useEffect, useState } from 'react';

const useChannelConnectionStatus = (rtcClient) => {
    const [isConnected, setIsConnected] = useState(
        !!rtcClient?.isChannelConnected()
    );
    useEffect(() => {
        const onConnectionStateChange = (newState) => {
            if (typeof newState === 'object') {
                newState = newState?.currState;
            }
            if (newState === 'CONNECTED') {
                setIsConnected(true);
            } else if (newState === 'DISCONNECTED') {
                setIsConnected(false);
            }
        };

        if (rtcClient) {
            rtcClient.on('connection-state-change', onConnectionStateChange);
        }

        return () => {
            if (rtcClient) {
                rtcClient.off(
                    'connection-state-change',
                    onConnectionStateChange
                );
            }
        };
    }, [rtcClient]);

    return isConnected;
};

export default useChannelConnectionStatus;
