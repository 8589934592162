import { useProfiling } from 'context/Profiling';
import { usePrevious } from 'hooks/common';
import useDeviceInfo from 'hooks/useDeviceInfo';
import usePostEventReplayAccess from 'hooks/usePostEventReplayAccess';
import { getStageUsersRoles } from 'hooks/useStageUsersRoles';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvitedSpeakers } from 'store/selectors/liveAirmeet';
import {
    AIRMEET_STATUS,
    EVENT_SUB_TYPES,
    EVENT_TYPES,
    MAGIC_LINK_DETAILS,
} from 'utils/constants/airmeet';
import { noop } from 'utils/constants/common';
import { LIVE_AIRMEET_STATE } from 'utils/constants/live-airmeet';
import {
    LAST_SESSION_VISITED_KEY,
    SESSION_STATUS,
    SESSION_TYPES,
} from 'utils/constants/sessions';
import { logger } from 'utils/logger';

import { USER_ROLE } from 'utils/constants/users';
import FirebaseLogger from 'utils/firebaseLogger';
import UserRoles from 'utils/userRoles';
import { isAttendee } from 'utils/users';
import { fetchAirmeet, setIsBackStage } from '../../store/actions/Lounge';
import PermissionUtils from '../../utils/permission-utils';
import useDeepLink from './useDeepLink';

const { HOST, ATTENDEE, SPEAKER, ORGANIZER, COHOST } = USER_ROLE;

const PERF_TRACES = {
    FETCH_AIRMEET_API: 'fetchAirmeetApi',
};
const isAllowToEnter = (airmeetStatus, role) => {
    return (
        [HOST, ORGANIZER, COHOST, SPEAKER].includes(role) ||
        PermissionUtils.isEventCloudHost() ||
        (role === ATTENDEE && airmeetStatus === AIRMEET_STATUS.ONGOING)
    );
};

const emptyObj = {};

function useAirmeetData({ airmeetId, user }) {
    const dispatch = useDispatch();
    const isRequestProcessed = useRef(false);
    const isBackStage = useSelector((store) => store.lounge.isBackStage);
    const [isAllowedToEnter, setAllowedToEnter] = useState(false);
    const allInvitedSpeakers = useRef({});
    const parallelTrackDataRef = useRef({});
    const isIntroVideoEnabled = useRef(false);
    const { isMobile } = useDeviceInfo();

    const { allowedPreEventLinkAccess } = useDeepLink();

    const storeAirmeet = useSelector(({ lounge }) => {
        const airmeet = lounge.airmeet || null;
        return airmeet &&
            airmeet.airmeetId === airmeetId &&
            !!airmeet.table_count
            ? airmeet
            : null;
    });

    const isSessionBasedStageEnabled =
        storeAirmeet?.is_session_level_stage_enabled;

    const invitedSpeakerList =
        storeAirmeet && storeAirmeet.invitedSpeakerList
            ? storeAirmeet.invitedSpeakerList
            : null;

    const { isPostEventEntryAllowed } = usePostEventReplayAccess({
        status: storeAirmeet?.status,
        postEventEntry: storeAirmeet?.post_event_entry,
    });

    const previousAirmeetStatus = usePrevious(
        storeAirmeet?.status,
        storeAirmeet?.status
    );

    const isEventRecentlyEnded = useMemo(() => {
        return (
            previousAirmeetStatus === AIRMEET_STATUS.ONGOING &&
            storeAirmeet?.status === AIRMEET_STATUS.FINISHED
        );
    }, [previousAirmeetStatus, storeAirmeet]);

    const isConferenceEvent =
        storeAirmeet?.event_type === 'CONFERENCE' || false;

    const isMeetupLiteEvent =
        (!isConferenceEvent &&
            storeAirmeet?.event_sub_type === EVENT_SUB_TYPES.LITE) ||
        false;

    useEffect(() => {
        if (isEventRecentlyEnded && isMeetupLiteEvent) {
            logger.info('Stop Allowing to enter on recently event ended.');
            sessionStorage.removeItem(MAGIC_LINK_DETAILS);
            setAllowedToEnter(false);
        }
    }, [isEventRecentlyEnded, isMeetupLiteEvent]);

    allInvitedSpeakers.current = useSelector(getInvitedSpeakers);

    const [liveEventData, setLiveEventData] = useState({
        userRole: ATTENDEE,
        userStageRole: ATTENDEE,
        currentAirmeet: false,
        currentSession: false,
        upcomingSession: false,
        currentState: LIVE_AIRMEET_STATE.loading,
        airmeetOrganiserId: null,
        getUserStageRole: null,
        stageHostId: null,
        stageSpeakers: null,
    });

    const updateCurrentState = (
        airmeet,
        session,
        role,
        upcomingSession,
        forceUpdate = false
    ) => {
        let updateState = liveEventData.currentState;
        let updatedAirmeet = airmeet;
        let updatedSession = session;

        if (!updatedAirmeet) {
            return;
        }
        /**
         * For updating the current state, need to wait till if any process is running in fetchUpdatedAirmeet,
         * If you want to execute this without block base on process request, then you need to pass forceUpdate value true
         */
        if (!forceUpdate && isRequestProcessed.current) {
            return;
        }

        const {
            userStageRole,
            airmeetOrganiserId,
            getUserStageRole,
            stageHostId,
            stageSpeakers,
        } = getStageUsersRoles(
            user,
            airmeet,
            upcomingSession,
            allInvitedSpeakers.current
        );

        const allowedToEnter =
            isAllowToEnter(airmeet?.status, role) ||
            allowedPreEventLinkAccess() ||
            isPostEventEntryAllowed ||
            (airmeet.status === AIRMEET_STATUS.CREATED &&
                window.location.pathname ===
                    `/webview/event/${airmeetId}/booth-mobile`) ||
            (updatedAirmeet.status === AIRMEET_STATUS.PRE_EVENT_ACCESS &&
                (!isMobile ||
                    window.location.pathname ===
                        `/webview/event/${airmeetId}/booth-mobile`));

        if (updatedAirmeet.status === AIRMEET_STATUS.FINISHED) {
            updateState = LIVE_AIRMEET_STATE.finish;
        } else if (
            airmeet.session_enabled &&
            updatedSession &&
            updatedSession.status === 'ONGOING'
        ) {
            updateState = LIVE_AIRMEET_STATE.goLive;
        } else if (
            airmeet.session_enabled &&
            isBackStage &&
            updatedSession &&
            !PermissionUtils.isEventCloudHost() &&
            !isAttendee(role)
        ) {
            updateState = LIVE_AIRMEET_STATE.backStage;
        } else if (
            airmeet.session_enabled &&
            isBackStage &&
            !updatedSession &&
            updatedAirmeet.sessions.length > 0 &&
            !PermissionUtils.isEventCloudHost() &&
            !isAttendee(role)
        ) {
            updateState = LIVE_AIRMEET_STATE.backStage;
        } else if (allowedToEnter) {
            updateState = LIVE_AIRMEET_STATE.socialLounge;
        }
        const speakersById = {};
        airmeet.sessions.forEach((session) => {
            session.speakerList.forEach(
                (speaker) => (speakersById[speaker.id] = speaker)
            );
        });
        updatedAirmeet.speakers = speakersById;
        let newLiveData = {
            ...liveEventData,
            currentSession: updatedSession,
            currentAirmeet: updatedAirmeet,
            upcomingSession,
            userRole: role,
            currentState: updateState,
            userStageRole,
            airmeetOrganiserId,
            getUserStageRole,
            stageHostId,
            stageSpeakers,
        };

        setLiveEventData(newLiveData);
        if (
            !isBackStage &&
            updateState === LIVE_AIRMEET_STATE.waiting &&
            !isAttendee(role)
        ) {
            if (
                !PermissionUtils.isEventCloudHost() &&
                !isSessionBasedStageEnabled
            ) {
                dispatch(setIsBackStage(true));
            }
        } else if (
            isBackStage &&
            liveEventData.currentState === LIVE_AIRMEET_STATE.backStage &&
            updateState !== LIVE_AIRMEET_STATE.backStage
        ) {
            dispatch(setIsBackStage(false));
        }
        return newLiveData;
    };

    useEffect(() => {
        if (
            isBackStage &&
            liveEventData.currentState !== LIVE_AIRMEET_STATE.backStage
        ) {
            dispatch(setIsBackStage(false));
        }
    }, [liveEventData.currentState]);

    const handelTheUpdatedResponse = useRef(noop);

    handelTheUpdatedResponse.current = (airmeet, forceUpdate = false) => {
        let role = liveEventData.userRole;
        let newLiveData = null;
        let session;

        /**
         * For updating the current state, need to wait till if any process is running in fetchUpdatedAirmeet,
         * If you want to execute this without block base on process request, then you need to pass forceUpdate value true
         */
        if (!forceUpdate && isRequestProcessed.current) {
            return;
        }
        if (
            airmeet &&
            airmeet.airmeetId === airmeetId &&
            airmeet.safeSessions
        ) {
            const safeSession = airmeet.session_enabled
                ? airmeet.safeSessions.find(
                      ({ status, type }) =>
                          type !== SESSION_TYPES.BREAK &&
                          status !== SESSION_STATUS.FINISHED
                  )
                : null;

            let updatedUpcomingSession;
            // ## For Conference event - [currentSession === upcomingSession]
            // upcomingSession is always equals to currentSession

            // ## for Meetup event with multiple stage enabled - [currentSession may or may not be equal to upcomingSession]
            // upcoming session will always be the immediate session which needs to be start, or live, or pause. And current session will be the current selected session.

            // ## For Meetup event without stage enabled - [currentSession === upcomingSession]
            // upcomingSession and currentSession will always be same. The immediate session which is which needs to be start, or live, or pause.
            const {
                currentSessionId: selectedSessionId,
            } = airmeet.parallelTrackData;
            const isConferenceEvent =
                airmeet.event_type === EVENT_TYPES.CONFERENCE;

            const currentSessionId = isSessionBasedStageEnabled
                ? selectedSessionId
                : safeSession?.sessionid;
            // Take user to the last visited session if two or more sessions are ONGOING
            const lastVisitedSessionId = isConferenceEvent
                ? sessionStorage.getItem(LAST_SESSION_VISITED_KEY)
                : null;
            if (lastVisitedSessionId || currentSessionId) {
                session = airmeet.sessions.find(
                    ({ sessionid, status, type }) =>
                        (sessionid === lastVisitedSessionId &&
                            ![
                                SESSION_TYPES.SPEED_NETWORKING,
                                SESSION_TYPES.GROUP_MEETING,
                            ].includes(type) &&
                            status === SESSION_STATUS.ONGOING) ||
                        sessionid === currentSessionId
                );
            }
            if (!isConferenceEvent) {
                updatedUpcomingSession = safeSession
                    ? airmeet.sessions.find(
                          ({ sessionid }) => sessionid === safeSession.sessionid
                      )
                    : null;
                if (
                    updatedUpcomingSession === null &&
                    !session &&
                    isMeetupLiteEvent
                ) {
                    session = airmeet.sessions.find(
                        ({ sessionid }) => !!sessionid
                    );
                    updatedUpcomingSession = session;
                }
            } else {
                // for conference event, upcoming session is same as current session.
                updatedUpcomingSession = session;
            }
            UserRoles.setData({
                user,
                airmeet,
                session,
                allInvitedSpeakers: allInvitedSpeakers.current,
            });

            if (UserRoles.roles[user.id] && UserRoles.roles[user.id].role) {
                role = UserRoles.roles[user.id].role;
            }

            const isInPersonEvent =
                isConferenceEvent &&
                airmeet?.event_sub_type ===
                    EVENT_SUB_TYPES.IN_PERSON_CONFERENCE;

            const isAllowed = isInPersonEvent
                ? false
                : isAllowToEnter(airmeet?.status, role) ||
                  allowedPreEventLinkAccess() ||
                  isPostEventEntryAllowed ||
                  (airmeet.status === AIRMEET_STATUS.CREATED &&
                      window.location.pathname ===
                          `/webview/event/${airmeetId}/booth-mobile`) ||
                  (airmeet.status === AIRMEET_STATUS.PRE_EVENT_ACCESS &&
                      (!isMobile ||
                          window.location.pathname ===
                              `/webview/event/${airmeetId}/booth-mobile`));

            FirebaseLogger.enablePreviewMode(airmeet?.status === 'CREATED');

            if (!isAllowed) {
                setAllowedToEnter(false);
                return { airmeet, isAllowed: false, role, isInPersonEvent };
            }
            if (airmeet?.is_intro_video_enabled) {
                isIntroVideoEnabled.current = airmeet?.is_intro_video_enabled;
            }

            if (liveEventData.currentState === LIVE_AIRMEET_STATE.loading) {
                newLiveData = {
                    ...liveEventData,
                    currentSession: session, // this needs to be set only if a user is a part of the stage route or in pip mode
                    currentAirmeet: airmeet,
                    upcomingSession: updatedUpcomingSession,
                    isIntroVideoEnabled: isIntroVideoEnabled.current,
                    userRole: role,
                    ...getStageUsersRoles(
                        user,
                        airmeet,
                        session,
                        allInvitedSpeakers.current
                    ),
                };
                setAllowedToEnter(true);
                setLiveEventData(newLiveData);
            } else {
                newLiveData = updateCurrentState(
                    airmeet,
                    session,
                    role,
                    updatedUpcomingSession,
                    forceUpdate
                );
            }
        }
        return { airmeet, role, isAllowed: true, updatedLiveData: newLiveData };
    };

    const currentSessionId =
        storeAirmeet &&
        storeAirmeet.airmeetId === airmeetId &&
        storeAirmeet.parallelTrackData &&
        isSessionBasedStageEnabled
            ? storeAirmeet.parallelTrackData.currentSessionId
            : null;

    parallelTrackDataRef.current =
        storeAirmeet && storeAirmeet.airmeetId === airmeetId
            ? storeAirmeet.parallelTrackData
            : null;

    const safeSessions =
        storeAirmeet &&
        storeAirmeet.airmeetId === airmeetId &&
        storeAirmeet.safeSessions
            ? storeAirmeet.safeSessions
            : null;

    const currentSessionStatus = liveEventData.currentSession?.status;
    const preCurrentSessionStatus = usePrevious(
        currentSessionStatus,
        currentSessionStatus
    );

    const preCurrentSessionId = usePrevious(currentSessionId, currentSessionId);

    useEffect(() => {
        if (!storeAirmeet) {
            return;
        }
        if (storeAirmeet.airmeetId === airmeetId) {
            const reqForceUpdate =
                (preCurrentSessionStatus &&
                    currentSessionStatus !== preCurrentSessionStatus) ||
                (preCurrentSessionId &&
                    currentSessionId !== preCurrentSessionId);
            handelTheUpdatedResponse.current(storeAirmeet, reqForceUpdate);
        }
    }, [
        invitedSpeakerList,
        currentSessionId,
        safeSessions,
        airmeetId,
        currentSessionStatus,
        preCurrentSessionStatus,
    ]);

    const { perf } = useProfiling();
    const fetchUpdatedAirmeet = useCallback(
        (props = {}) => {
            return (async () => {
                const {
                    isFullLoad = false,
                    errorOnEmptyResponse = false,
                    ...params
                } = props;
                const options = params || {};
                const includeData = [];
                const includes = isFullLoad ? includeData.join(',') : '';
                isRequestProcessed.current = true;
                options.info = !isFullLoad;
                let trace;
                trace = perf.trace(PERF_TRACES.FETCH_AIRMEET_API, {
                    attributes: {
                        includes: includes || 'default',
                    },
                });
                // const options = {};
                if (isFullLoad) {
                    options.authUser = user;
                }
                const response = await dispatch(
                    fetchAirmeet(airmeetId, includes, options)
                );

                trace.incrementMetric(
                    'users',
                    response?.payload?.users?.length
                );
                perf.traceStop(PERF_TRACES.FETCH_AIRMEET_API);

                isRequestProcessed.current = false;
                if (!response) {
                    logger.error('Failed to fetch info api, empty response');
                    if (errorOnEmptyResponse) {
                        throw new Error('Failed to fetch info api');
                    }
                    return response;
                }

                const airmeet = response.payload;
                if (parallelTrackDataRef.current) {
                    airmeet.parallelTrackData = {
                        ...parallelTrackDataRef.current,
                        ...airmeet.parallelTrackData,
                    };
                }

                const dataPayload = handelTheUpdatedResponse.current(airmeet);
                return dataPayload;
            })();
        },
        [airmeetId, dispatch, perf, user]
    );

    const updatedAirmeetPromiseRef = useRef(null);
    const getUpdatedAirmeetPromise = useCallback(() => {
        if (!updatedAirmeetPromiseRef.current) {
            updatedAirmeetPromiseRef.current = new Promise(
                (resolve, reject) => {
                    fetchUpdatedAirmeet({ errorOnEmptyResponse: true })
                        .then(resolve)
                        .catch(reject)
                        .finally(() => {
                            updatedAirmeetPromiseRef.current = null;
                        });
                }
            );
        }
        return updatedAirmeetPromiseRef.current;
    }, [fetchUpdatedAirmeet]);

    const assocUsers = liveEventData.currentAirmeet?.assocUsers || emptyObj;

    return {
        isAllowedToEnter,
        ...liveEventData,
        users: assocUsers,
        fetchUpdatedAirmeet,
        updateCurrentState,
        getUpdatedAirmeetPromise,
    };
}

export default useAirmeetData;
