import Drawer from 'atoms/Drawer/drawer';
import UploadProgress from 'components/community/editPublicAirmeet/Sessions/SessionForm/SessionActivity/SessionVideos/UploadProgress';
import { Box, StyledInput } from 'components/molecules/utils/basicComponents';
import { getStaticColorScroll } from 'components/molecules/utils/customStyles';
import Icon from 'foundations/icon';
import { Text } from 'foundations/themeV2/text/';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import useFileUploader from 'hooks/useFileUploader';
import useToasts from 'hooks/useToasts';
import keys from 'locale/keys';
import React, { useState } from 'react';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';
import { TOAST_VERSION_2 } from 'utils/constants/toasts';
import firebaseLogger from 'utils/firebaseLogger';
import { logger } from 'utils/logger';

const FONT_FILE_TYPES = ['.ttf', '.otf', '.woff', '.woff2'];
const MAX_FONT_FAMILY_LENGTH = 20;

const sanitizeFileName = (str) => {
    str = str.replace(/[^a-z0-9 \._-]/gim, '');
    return str
        .slice(0, Math.min(str.indexOf('.'), MAX_FONT_FAMILY_LENGTH))
        .trim();
};

const UploadFontsPanel = ({
    fontList,
    onChange,
    onClose,
    airmeetId,
    scrollToSelected = noop,
}) => {
    const [uploadError, setUploadError] = useState(null);
    const [isUploadInProgress, setIsUploadInProgress] = useState(false);
    const {
        onUpload,
        uploadProgressPercent,
        onCancelUpload,
        resetUploadProgress,
    } = useFileUploader({ logPrefix: 'Customize Fonts' });
    const { errorToast } = useToasts();
    const { t } = useCustomTranslation();

    const handleFileUpload = async (event) => {
        setUploadError(null);
        try {
            if (event.target.files.length > 0) {
                const file = event.target.files[0];
                const name = sanitizeFileName(file.name);
                setIsUploadInProgress(true);
                if (
                    FONT_FILE_TYPES.every(
                        (fileType) =>
                            !file.name.toLowerCase().includes(fileType)
                    )
                ) {
                    logger.info(
                        `File with type ${file.type} is not supported for fonts.`
                    );
                    errorToast({
                        title: t(
                            keys.CUSTOM_FONTS_FILE_NOT_SUPPORTED_ERROR_TOAST_TEXT,
                            {
                                fileType: file.type,
                            }
                        ),
                        version: TOAST_VERSION_2,
                    });
                    setUploadError(
                        t(keys.CUSTOM_FONTS_FILE_NOT_SUPPORTED_ERROR_TEXT, {
                            fileType: file.type,
                        })
                    );
                    setIsUploadInProgress(false);
                    return;
                }

                const duplicateEntry = fontList.find(
                    (font) => font.id === name
                );

                if (duplicateEntry) {
                    logger.info(
                        `File with name ${file.name} is already present.`
                    );
                    errorToast({
                        title: t(
                            keys.CUSTOM_FONTS_DUPLICATE_FILE_ERROR_TOAST_TEXT,
                            {
                                fileName: file.name,
                            }
                        ),
                        version: TOAST_VERSION_2,
                    });
                    setUploadError(
                        t(keys.CUSTOM_FONTS_DUPLICATE_FILE_ERROR_TEXT, {
                            fileName: file.name,
                        })
                    );
                    setIsUploadInProgress(false);
                    return;
                }

                const uploadResponse = await onUpload(file, {
                    entity_type: 'airmeet',
                    entity_id: airmeetId,
                });

                if (uploadResponse.error) {
                    errorToast({
                        title: t(keys.BREAKOUT_CSV_ERROR_UPLOADING_FILE),
                        version: TOAST_VERSION_2,
                    });
                    setUploadError(t(keys.BREAKOUT_CSV_ERROR_UPLOADING_FILE));
                } else {
                    firebaseLogger.customFont('customFontUploaded', {
                        font_name: name,
                    });
                    onChange(
                        { id: name, label: name },
                        uploadResponse.payload.fileUrl,
                        true
                    );
                    scrollToSelected();
                }

                setIsUploadInProgress(false);
                resetUploadProgress();
                onClose();
            }
        } catch (e) {
            errorToast({
                title: t(keys.BREAKOUT_CSV_ERROR_UPLOADING_FILE),
                version: TOAST_VERSION_2,
            });
        }
    };

    const handleClose = () => {
        onCancelUpload();
        resetUploadProgress();
        onClose();
    };

    return (
        <Drawer
            wrapperClosable={false}
            width={'456px'}
            showClose
            onClose={handleClose}
            zIndex={9992}
            title={t(keys.CUSTOM_FONTS_UPLOAD_HEADING)}
            titleVariant={'subtitle1'}
            headerStyle={{
                padding: '24px 24px 0',
            }}
            bodyStyle={{
                padding: '0',
                flexDirection: 'column',
            }}
            className='DRAWER_COMMON_CLASS'
        >
            <Box px='24px' pt='8px' pb='32px'>
                <Text variant='caption' color='ambience.6'>
                    {t(keys.CUSTOM_FONTS_UPLOAD_SUBHEADING)}
                </Text>
            </Box>
            <ScrollView>
                <Box>
                    <UploadContainer isError={uploadError}>
                        {isUploadInProgress ? (
                            <UploadProgress
                                progress={uploadProgressPercent}
                                onCancel={onCancelUpload}
                                title={t(
                                    keys.BOOTH_CUSTOM_BOOTH_VIDEO_UPLOADING
                                )}
                            />
                        ) : (
                            <>
                                <Icon
                                    icon='add_document'
                                    fill='text.default.primary'
                                    width={24}
                                    height={24}
                                />
                                <Text
                                    variant='body2'
                                    color='text.default.primary'
                                    mt='16px'
                                >
                                    {t(keys.CUSTOM_FONTS_UPLOAD_DRAG_DROP_TEXT)}
                                </Text>
                                <Text
                                    variant='body2'
                                    color='brandDefault'
                                    mt='4px'
                                >
                                    {t(
                                        keys.CUSTOM_FONTS_UPLOAD_CLICK_TO_UPLOAD_TEXT
                                    )}
                                </Text>
                                <CSVUploadInput
                                    type='file'
                                    id='uploadFonts'
                                    name='uploadFonts'
                                    onChange={handleFileUpload}
                                />
                            </>
                        )}
                    </UploadContainer>
                    <Text
                        variant='caption'
                        color={
                            uploadError ? 'sem.error' : 'text.default.secondary'
                        }
                        mt='8px'
                    >
                        {uploadError
                            ? uploadError
                            : t(keys.CUSTOM_FONTS_UPLOAD_FILE_FORMATS_TEXT)}
                    </Text>
                </Box>
            </ScrollView>
        </Drawer>
    );
};

export default UploadFontsPanel;

const UploadContainer = styled(Box)`
    position: relative;
    height: 164px;
    max-width: 100%;
    background: ${({ theme }) => theme.colors.ambience[21]};
    border: 1px dashed
        ${({ theme, isError }) =>
            isError ? theme.colors.sem.error : theme.colors.border.default1};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const CSVUploadInput = styled(StyledInput).attrs({
    type: 'file',
    accept: FONT_FILE_TYPES.join(','),
})`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
`;

const ScrollView = styled(Box)`
    flex 1;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 24px;

    ${({ theme, verticalWidth = '12px' }) =>
        getStaticColorScroll({ theme, verticalWidth })}
`;
