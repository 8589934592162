import AvatarImage from 'atoms/Avatar/AvatarImage';
import { Box } from 'components/molecules/utils/basicComponents';
import SVGIcon from 'foundations/icon';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import isNumber from 'lodash/isNumber';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart } from '../../styledComponents';
import { commonDoughnutOptions } from '../../utils';
import { EmptyAvatar, EmptyContent } from './styledComponents';

export default function PollItem({
    showBorderRight = false,
    showDetail = false,
    data = {},
    airmeetFormat,
}) {
    const { theme } = useTheme();
    const {
        poll_level,
        poll_options,
        user_profile_img,
        user_name,
        session_name,
        poll_question,
        total_response,
    } = data || {};
    let styleObj = {
        borderRight: showBorderRight
            ? `1px solid ${theme.colors.ambience[22]}`
            : '0px',
        flexGrow: 1,
    };
    if (showDetail) {
        styleObj = { ...styleObj, padding: '12px 32px' };
    } else {
        styleObj = { ...styleObj, margin: '12px 0' };
    }

    const topPercent = Number(
        Array.isArray(poll_options) && poll_options.length
            ? (poll_options[0]?.response_count_percentage || '').replace(
                  '%',
                  ''
              )
            : '0'
    );

    return (
        <Box style={styleObj}>
            <Box>
                <Box display='flex' alignItems='center'>
                    <Box pr={1}>
                        {user_profile_img ? (
                            <AvatarImage
                                src={user_profile_img}
                                width='24px'
                                alt={user_name}
                            />
                        ) : (
                            <EmptyAvatar />
                        )}
                    </Box>
                    {user_name ? (
                        <>
                            <Typography
                                variant='subtext2'
                                color='ambience.8'
                                style={{ marginRight: '4px' }}
                            >
                                {user_name}
                            </Typography>
                            <Typography
                                variant='subtext2'
                                color='ambience.12'
                                style={{ marginRight: '4px' }}
                            >
                                asked in{' '}
                            </Typography>
                            <Typography variant='subtext2' color='ambience.8'>
                                {poll_level === 'Event Level'
                                    ? `this ${airmeetFormat}`
                                    : `"${session_name}"`}
                            </Typography>
                        </>
                    ) : (
                        <EmptyContent />
                    )}
                </Box>
                {poll_question ? (
                    <Typography
                        variant='subtext1'
                        color='ambience.4'
                        mb={2}
                        mt={2}
                    >
                        {poll_question}
                    </Typography>
                ) : (
                    <Box
                        display='flex'
                        flexGrow='1'
                        flexDirection='column'
                        mt='16px'
                    >
                        <EmptyContent
                            style={{ width: '100%', marginBottom: '16px' }}
                        />
                        <EmptyContent
                            style={{ width: '30%', marginBottom: '16px' }}
                        />
                    </Box>
                )}
                <Box display='flex' alignItems='center'>
                    <SVGIcon
                        icon='poll'
                        height='16'
                        width='16'
                        fill={`ambience.${isNumber(total_response) ? 18 : 16}`}
                        style={{
                            marginRight: '4px',
                        }}
                    />
                    <Typography variant='subtext2' color='ambience.8'>
                        {isNumber(total_response) ? total_response : '-'}
                    </Typography>
                </Box>
            </Box>
            {showDetail ? (
                Array.isArray(poll_options) ? (
                    poll_options.length ? (
                        <Box display='flex' alignItems='center' mt='54px'>
                            <Box mr={3} flexGrow={1} width={'50px'}>
                                <Typography
                                    variant='subtext2'
                                    color='ambience.8'
                                    mb={1}
                                >
                                    {poll_options[0].response_count_percentage}{' '}
                                    people voted
                                </Typography>
                                <Typography
                                    variant='subtext1'
                                    color='ambience.0'
                                >
                                    "{poll_options[0].option}"
                                </Typography>
                            </Box>
                            <Chart ml={4}>
                                <Doughnut
                                    data={{
                                        labels: [poll_options[0].option],
                                        datasets: [
                                            {
                                                data: [
                                                    topPercent,
                                                    topPercent > 100
                                                        ? 0
                                                        : 100 - topPercent,
                                                ],
                                                backgroundColor: [
                                                    showBorderRight
                                                        ? '#5AB8BF'
                                                        : '#8981DD',
                                                    theme.colors.ambience[21],
                                                ],
                                                borderWidth: 0,
                                            },
                                        ],
                                    }}
                                    options={{
                                        ...commonDoughnutOptions,
                                    }}
                                />
                            </Chart>
                        </Box>
                    ) : null
                ) : (
                    <Box display='flex' alignItems='center' mt='54px'>
                        <Box mr={3} flexGrow={1}>
                            <Typography
                                variant='body1'
                                color='ambience.12'
                                mb={1}
                            >
                                - % people voted
                            </Typography>
                            <EmptyContent
                                style={{
                                    width: '100%',
                                    marginBottom: '8px',
                                }}
                            />
                            <EmptyContent
                                style={{
                                    width: '50%',
                                    marginBottom: '8px',
                                }}
                            />
                        </Box>
                        <Chart ml={4}>
                            <Doughnut
                                data={{
                                    labels: ['', ''],
                                    datasets: [
                                        {
                                            data: [100, 0],
                                            backgroundColor: [
                                                theme.colors.ambience[21],
                                                theme.colors.ambience[21],
                                            ],
                                            borderWidth: 0,
                                        },
                                    ],
                                }}
                                options={{
                                    ...commonDoughnutOptions,
                                }}
                            />
                        </Chart>
                    </Box>
                )
            ) : null}
        </Box>
    );
}
