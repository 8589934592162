export const CLOUD_FN_REPLICATING_FEATURES = [
    { name: 'Features', path: '/{airmeetId}/features' },
    { name: 'Feature control', path: '/{airmeetId}/featureControl' },
    { name: 'Schedule Meeting', path: '/{airmeetId}/meetings' },
    { name: 'Table Invite', path: '/{airmeetId}/tableInvites' },
    {
        name: 'SpeedNetworkingMatches',
        path: '/{airmeetId}/networkingSessions/{sessionId}/matches/{matchId}',
    },
    {
        name: 'SpeedNetworkingUsers',
        path: '/{airmeetId}/networkingSessions/{sessionId}/users/{userId}',
    },
    {
        name: 'TableInfo',
        path: '/{airmeetId}/socialLounge',
    },
    {
        name: 'TableListTitle',
        path: '/{airmeetId}/socialLounge',
    },
    {
        name: 'Breakouts',
        path: '/{airmeetId}/breakouts/{sessionId}/{breakoutId}',
    },
    {
        name: 'MetaDataBroadCastChannelPublisher',
        path: '/{airmeetId}/meta-data',
    },
    {
        name: 'MetaDataSessionsFeatures',
        path: '/{airmeetId}/meta-data',
    },
];
