import SVGIcon from 'foundations/icon';
import React from 'react';
import PollItem from './PollItem';
import { ListItem } from './styledComponents';

export default function PollListItem({
    selected = false,
    onClick,
    name,
    ...rest
}) {
    return (
        <ListItem selected={selected} onClick={onClick} name={name}>
            <PollItem {...rest} />
            <SVGIcon
                icon='chevron_right'
                height='30px'
                width='30px'
                fill='rgba(157, 154, 172, 0.56)'
                style={{
                    marginLeft: '20px',
                    minWidth: '30px',
                }}
            />
        </ListItem>
    );
}
