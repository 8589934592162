import EventEmitter from 'events';
export const deviceSettingEmitter = new EventEmitter();

export const PREF_VIDEO_IN = 'pref_video_in';
export const PREF_AUDIO_IN = 'pref_audio_in';
export const PREF_FILTER = 'pref_filter';
export const DEVICE_TYPE_CAMERA = 'Camera';
export const DEVICE_TYPE_MIC = 'mic';
export const PREF_AUDIO_OUT = 'pref_audio_out';

export const DEVICE_SETTING_SOURCE = {
    RHS_AV_SETTINGS: 'RHS_AV_SETTINGS',
};

export const DEVICE_SETTING_EVENTS = {
    DEVICE_SETTING_MOUNT: 'DEVICE_SETTING_MOUNT',
    DEVICE_SETTING_UNMOUNT: 'DEVICE_SETTING_UNMOUNT',
    SYSTEM_CHECK_MOUNT: 'SYSTEM_CHECK_MOUNT',
    SYSTEM_CHECK_UNMOUNT: 'SYSTEM_CHECK_UNMOUNT',
};
