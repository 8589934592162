import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import firebaseLogger from 'utils/firebaseLogger';
import useWhiteLabelling from './useWhitelabelling';

export default function useCustomBaseUrl(props) {
    // use state.cmDashboard or state.lounge accordingly
    const subdomainLive = useSelector(
        (state) => state.lounge?.airmeet?.subdomain
    );
    const subdomainDashboard = useSelector(
        (state) => state.cmDashboard?.airmeet?.subdomain
    );
    const subdomainCommunity = useSelector(
        (state) => state?.Community?.current?.subdomain
    );
    const subdomain = subdomainLive || subdomainDashboard || subdomainCommunity;

    const domainLive = useSelector(
        (state) => state.lounge?.airmeet?.custom_domain?.domainName
    );
    const domainDashboard = useSelector(
        (state) => state.cmDashboard?.airmeet?.custom_domain?.domainName
    );
    const domainCommunity = useSelector(
        (state) => state?.Community?.current?.custom_domain?.domainName
    );
    const domain = domainLive || domainDashboard || domainCommunity;

    const { isWhiteLabellingEnabled, ...rest } = useWhiteLabelling({
        forceApply: true,
    });
    const baseUrl = useMemo(() => {
        if (isWhiteLabellingEnabled) {
            if (domain) {
                return domain.includes('http') ? domain : `https://${domain}`;
            } else if (subdomain) {
                let url = '';
                if (!subdomain.includes('http')) {
                    url += 'https://';
                }
                url += subdomain;
                if (!url.includes('.airmeet.com')) {
                    if (
                        (window.location.hostname || '').endsWith(
                            '.test.airmeet.com'
                        )
                    ) {
                        if (url.endsWith('.test')) {
                            url += '.airmeet.com';
                        } else {
                            url += '.test.airmeet.com';
                        }
                    } else {
                        url += '.airmeet.com';
                    }
                }

                return url;
            }
        }
        return process.env.REACT_APP_BASE_URL;
    }, [isWhiteLabellingEnabled, subdomain, domain]);

    const navigateToEventPage = useCallback(
        ({
            logData = {},
            airmeetId,
            preview = false,
            edit = false,
            params = '',
        }) => {
            if (logData && !isEmpty(logData))
                firebaseLogger.preEventAirmeetVisited(logData || {});

            window.open(
                `${baseUrl}/${preview ? 'e' : 'event'}/${airmeetId}${
                    edit ? '/edit' : ''
                }${preview ? '?preview=true' : ''}${params}`,
                '_blank'
            );
        },
        [baseUrl]
    );

    const navigateToEventSeriesPage = useCallback(
        ({
            logData = {},
            eventSeriesId,
            edit = false,
            params = '',
            preview = '',
        }) => {
            if (logData && !isEmpty(logData))
                firebaseLogger.preEventAirmeetVisited(logData || {});

            window.open(
                `${baseUrl}/es/${eventSeriesId}${edit ? '/edit' : ''}${
                    preview ? '?preview=true' : ''
                }${params}`,
                '_blank'
            );
        },
        [baseUrl]
    );

    return {
        baseUrl,
        isWhiteLabellingEnabled,
        navigateToEventPage,
        navigateToEventSeriesPage,
        ...rest,
    };
}
