import {
    CREATE_BREAKOUT_RESPONSE,
    DELETE_BREAKOUT_RESPONSE,
    DELETE_DRAFT_BREAKOUT,
    SET_DRAFT_BREAKOUT,
} from 'store/actions/breakout';
import createReducer from 'utils/createReducer';

const initialState = {};

const handlers = {
    [CREATE_BREAKOUT_RESPONSE]: (state, { payload, error }) => {
        if (error) {
            return { ...state, error };
        }
        return {
            ...state,
            error: null,
        };
    },

    [DELETE_BREAKOUT_RESPONSE]: (state, { error }) => {
        if (error) {
            return { ...state, error };
        }

        return {
            ...state,
            error: null,
            draft: null,
        };
    },

    [SET_DRAFT_BREAKOUT]: (state, { payload }) => {
        return {
            ...state,
            draft: {
                ...state.draft,
                ...payload,
            },
        };
    },

    [DELETE_DRAFT_BREAKOUT]: (state) => {
        return {
            ...state,
            draft: null,
        };
    },
};

export default createReducer(initialState, handlers);
