import isEmpty from 'lodash/isEmpty';
import createReducer from '../../utils/createReducer';
import {
    GET_EVENT_SERIES_AIRMEETS_RESPONSE,
    GET_EVENT_SERIES_COMBINED_INFO_RESPONSE,
    GET_EVENT_SERIES_RESPONSE,
    MANAGE_SERIES_ENTRY_PARTICIPANTS_RESPONSE,
    PATCH_EVENT_SERIES_RESPONSE,
    EVENT_SERIES_INFO_UPDATE,
    CLEAR_EVENT_SERIES_DATA,
} from '../actions/eventSeries';
import cloneDeep from 'lodash/cloneDeep';

const isInvalidAction = (action) => {
    return isEmpty(action?.payload) || action?.error;
};

const initialState = {
    dashEventSeries: {},
    events: [],
    eventsCount: 0,
    upcomingEvent: {},
    combinedInfo: {},
    registrants: [],
    latestEvent: {},
};

const handlers = {};
handlers[PATCH_EVENT_SERIES_RESPONSE] = (state = initialState, action) => {
    if (isInvalidAction(action)) {
        if (!action?.error && !isEmpty(action?.body)) {
            return {
                ...state,
                dashEventSeries: {
                    ...(state?.dashEventSeries || {}),
                    ...action?.body,
                },
            };
        }

        return state;
    }

    return { ...state, dashEventSeries: action?.payload };
};

handlers[GET_EVENT_SERIES_RESPONSE] = (state = initialState, action) => {
    if (isInvalidAction(action)) {
        return state;
    }

    return { ...state, dashEventSeries: action?.payload };
};

handlers[MANAGE_SERIES_ENTRY_PARTICIPANTS_RESPONSE] = (
    state = initialState,
    action
) => {
    if (isEmpty(action?.payload)) {
        return state;
    }

    return { ...state, registrants: action?.payload };
};

handlers[GET_EVENT_SERIES_COMBINED_INFO_RESPONSE] = (
    state = initialState,
    action
) => {
    if (isInvalidAction(action)) {
        return state;
    }
    return { ...state, combinedInfo: action?.payload };
};
handlers[EVENT_SERIES_INFO_UPDATE] = (state = initialState, action) => {
    if (isInvalidAction(action)) {
        return state;
    }
    const seriesInfo = cloneDeep({
        seriesInfo: {
            ...(state?.combinedInfo?.seriesInfo || {}),
            ...(action?.payload || {}),
        },
    });
    return {
        ...state,
        combinedInfo: {
            ...state?.combinedInfo,
            ...seriesInfo,
        },
    };
};
handlers[GET_EVENT_SERIES_AIRMEETS_RESPONSE] = (
    state = initialState,
    action
) => {
    if (isInvalidAction(action)) {
        return state;
    }

    const { page = 1 } = action?.extra || {};
    const { airmeets: events, airmeetsCount, latest_event } =
        action?.payload || {};

    return {
        ...state,
        events:
            page <= 1 ? events || [] : [...(state?.events || []), ...events],
        eventsCount: airmeetsCount,
        latestEvent: latest_event,
    };
};

handlers[CLEAR_EVENT_SERIES_DATA] = (state = initialState) => {
    return {
        dashEventSeries: {},
        events: [],
        eventsCount: 0,
        upcomingEvent: {},
        combinedInfo: {},
        registrants: [],
    };
};

export default createReducer(initialState, handlers);
