export const MEDIA_TAB_ID = 'media';
export const RECORDINGS_TAB_ID = 'session-recordings';
export const EMAIL_TEMPLATES_TAB_ID = 'email-templates';
export const CLIPS_TAB_ID = 'clips';

export const NAV_TABS = [
    { id: MEDIA_TAB_ID, title: 'Videos', disabled: false },
    {
        id: RECORDINGS_TAB_ID,
        title: 'Recordings & transcripts',
        disabled: false,
    },
    {
        id: CLIPS_TAB_ID,
        title: 'Clips',
        disabled: false,
    },
    { id: EMAIL_TEMPLATES_TAB_ID, title: 'Email Templates', disabled: false },
];

export const DEFAULT_FILTER_PARAMETERS = {
    from_date: '',
    to_date: '',
    search_text: '',
    content_type: [],
};

export const DEFAULT_SORTING_PARAMETERS = {
    created_by: [],
    sort_type: '',
    sort_by: '',
};

export const DEFAULT_FILTERS = {
    ...DEFAULT_FILTER_PARAMETERS,
    ...DEFAULT_SORTING_PARAMETERS,
};

export const DEFAULT_NAV_TAB_ID = NAV_TABS[0]?.id;

export const SORT_OPTIONS = [
    { id: 0, label: 'Latest', sort_type: 'desc', sort_by: 'created_at' },
    { id: 1, label: 'Oldest', sort_type: 'asc', sort_by: 'created_at' },
    {
        id: 2,
        label: 'Last Modified',
        sort_type: 'desc',
        sort_by: 'updated_at',
    },
    { id: 3, label: 'Name (A - Z)', sort_type: 'asc', sort_by: 'name' },
    { id: 4, label: 'Name (Z - A)', sort_type: 'desc', sort_by: 'name' },
];

export const date = {
    fromLabel: 'From date',
    toLabel: 'To date',
    placeholder: 'Select Date',
};

export const DATE_FORMAT = 'dd/MMM/yyyy';

export const PAGE_SIZE = 10;

export const VIDEO_CLIP_DATA = {
    title: 'Create video clip',
    text:
        'You can use recordings to create clips, change layouts & add styling components to the video.',
    altText: 'Want to create clips by trimming the recordings?',
    image: '/images/create-video-clip.svg',
};

export const EMBED_RECORDING_DATA = {
    title: 'Embed recordings',
    text:
        'You can re-use the recordings on your website to increase visibility.',
    image: '/images/embed-recordings.svg',
};
