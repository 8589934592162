import { cdnImage } from './utils/core';

const config = Object.freeze({
    providers: {
        // airmeet: {
        //     key: 'airmeet',
        //     icon: cdnImage('/images/social/local-logo.png'),
        //     oAuthURL: `${window.location.origin}/auth/airmeet`
        // },

        google: {
            key: 'google',
            displayName: 'Google',
            icon: cdnImage('/images/social/google-logo.svg'),
        },
        apple: {
            key: 'apple',
            displayName: 'Apple',
            icon: cdnImage('images/social/apple-logo.svg'),
        },
        facebook: {
            key: 'facebook',
            displayName: 'Facebook',
            icon: cdnImage('/images/social/fb-logo.svg'),
        },
        twitter: {
            key: 'twitter',
            displayName: 'Twitter',
            icon: cdnImage('/images/social/twitter-logo.svg'),
        },
        linkedin: {
            key: 'linkedin',
            displayName: 'LinkedIn',
            icon: cdnImage('/images/social/linkedin-logo.svg'),
        },
        sso: {
            key: 'sso',
            displayName: 'sso',
            icon: '',
        },
    },
});

export default config;
