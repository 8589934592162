import { getStageFirebaseClient } from 'context/LiveAirmeet';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { useCallback, useEffect, useState } from 'react';
import { logger } from 'utils/logger';

const TOTAL_PROCESS_TIME = 11000; // 10 Sec Countdown + 10 Sec Connecting + 1 Sec Ready State
export const states = {
    created: 'created',
    ongoing: 'ongoing',
    pause: 'pause',
    stop: 'stop',
};

const sendNotificationForSessionStateChange = ({
    rtmClient,
    sessionId,
    state,
}) => {
    const data = { sessionid: sessionId };
    if ([states.ongoing].includes(state)) {
        data.status = 'ONGOING';
    } else if (state === states.pause) {
        data.status = 'PAUSED';
    } else if (state === states.stop) {
        data.status = 'FINISHED';
    }
    if (data.status) {
        rtmClient.sendChannelMessage('session-updated', data);
    }
};

const useSessionLiveAction = ({ sessionId }) => {
    const { rtmClient } = useLiveAirmeetContext();

    const sendNotification = useCallback(
        (state) => {
            sendNotificationForSessionStateChange({
                rtmClient,
                sessionId,
                state,
            });
        },
        [rtmClient, sessionId]
    );

    const onSessionStart = useCallback(() => {
        sendNotification(states.ongoing);
    }, [sendNotification]);

    const onSessionEnd = useCallback(
        (markAsFinished) => {
            const state = markAsFinished ? states.stop : states.pause;
            sendNotification(state);
        },
        [sendNotification]
    );

    return {
        onSessionStart,
        onSessionEnd,
    };
};

export const steps = {
    notStart: 'not-start',
    countDown: 'count-down',
    connecting: 'connecting',
    ready: 'ready',
    start: 'start',
    pause: 'pause',
    stop: 'stop',
};

export const useSessionLiveStatusData = ({ airmeetId, sessionId }) => {
    const firebaseClient = useLiveAirmeetContext(getStageFirebaseClient);
    const [liveStatusData, setLiveStatusData] = useState({
        status: states.created,
    });
    const key = sessionId
        ? `${airmeetId}/meta-data/sessions/${sessionId}/liveStatus`
        : null;

    useEffect(() => {
        if (!key) {
            return;
        }

        const onUpdate = ({ value }) => {
            setLiveStatusData(value);
            logger.info(`Session liveStatus data: ${key}`, value);
        };
        firebaseClient.getDataSync(key, onUpdate);

        return () => {
            firebaseClient.clearDataSync(key, onUpdate);
        };
    }, [key, firebaseClient]);

    return liveStatusData;
};

export const useSessionLiveState = ({
    airmeetId,
    sessionId,
    userId,
    totalTimerValue = 0,
}) => {
    const [step, setStep] = useState(steps.notStart);
    const firebaseClient = useLiveAirmeetContext(getStageFirebaseClient);
    const liveStatusData = useSessionLiveStatusData({
        airmeetId,
        sessionId,
        userId,
    });

    const timerEndValue =
        liveStatusData?.status === states.ongoing
            ? liveStatusData.lastStartAt +
              (totalTimerValue ? totalTimerValue : TOTAL_PROCESS_TIME)
            : null;

    useEffect(() => {
        let interval;
        if (timerEndValue) {
            const updateTimerValue = async () => {
                const timerDiff = await firebaseClient.getRemainingTimeInSec(
                    timerEndValue
                );
                if (timerDiff > 1) {
                    setStep(steps.countDown);
                    // To add connecting state
                    // } else if (timerDiff > 11) {
                    //     setStep(steps.connecting);
                } else if (timerDiff === 1) {
                    setStep(steps.ready);
                } else {
                    setStep(steps.start);
                    interval && clearInterval(interval);
                }
                return timerDiff;
            };

            updateTimerValue().then((shouldStart) => {
                if (shouldStart > 0) {
                    interval = setInterval(updateTimerValue, 100);
                }
            });
            return () => {
                interval && clearInterval(interval);
            };
        }
    }, [timerEndValue, firebaseClient]);

    useEffect(() => {
        if (!liveStatusData || liveStatusData.status === states.created) {
            setStep(steps.notStart);
            return;
        }

        if (liveStatusData.status && liveStatusData.status !== states.ongoing) {
            setStep(
                liveStatusData.status === states.pause
                    ? steps.pause
                    : steps.stop
            );
        }
    }, [liveStatusData]);

    return step;
};

export default useSessionLiveAction;
