import FormField from 'components/community/common/FormField';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import {
    FlexDiv,
    UnstyledButton,
} from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import React from 'react';
import { CHAR_LIMIT_MAP } from '../../CreateAirmeetV2/validations';

export const Name = ({
    formData,
    errors,
    newOnBoardingFlow = false,
    pageMode = false,
    onEventNameFocus = () => {},
    isForcedLight = false,
    isEventEnded,
    openAirGenie,
    airmeetFormat,
}) => (
    <FormField
        id='name'
        name='name'
        label={
            <FlexDiv alignItems='center' flex='1'>
                <FlexDiv flex='1'>Name *</FlexDiv>
                {openAirGenie ? (
                    <UnstyledButton onClick={openAirGenie} type='button'>
                        <Text variant='caption-bold' color='module.airgenie'>
                            <StyledSvgSprite
                                icon='magic_wand'
                                width='1rem'
                                height='1rem'
                                mr='x1'
                                fill='module.airgenie'
                            />
                            Auto-generate
                        </Text>
                    </UnstyledButton>
                ) : null}
            </FlexDiv>
        }
        placeholder={`Give your ${airmeetFormat} an exciting name`}
        filledValue={formData.name}
        isError={errors.name}
        errorMsg={errors.name}
        maxLength={CHAR_LIMIT_MAP['name'].max}
        variant={'v2'}
        subLabel={null}
        newOnBoardingFlow={newOnBoardingFlow}
        inputValue={pageMode ? formData?.name : null}
        value={formData?.name}
        onFocus={onEventNameFocus}
        isForcedLight={isForcedLight}
        disabled={isEventEnded}
        labelStyle={{ display: 'flex' }}
        labelTextStyle={{ display: 'flex', flex: 1 }}
        isThemeV2={true}
    />
);
