import { useCustomTranslation } from 'hooks/useCustomTranslation';
import keys from 'locale/keys';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setResolutionMenuOpen } from '../../../store/actions/liveAirmeet';
import { STAGE_STREAM_MODES } from '../../../utils/constants/stageStreamModes';

const LowNetworkSwitchResolution = () => {
    const dispatch = useDispatch();
    const { t } = useCustomTranslation();
    const activeMode =
        useSelector((state) => state.liveAirmeet.activeWatchMode) ||
        STAGE_STREAM_MODES.highResolution;

    const openResolutionMenu = () => {
        dispatch(setResolutionMenuOpen(true));
    };
    return (
        <>
            {activeMode === STAGE_STREAM_MODES.highResolution ? (
                <span className='switch-mode' onClick={openResolutionMenu}>
                    {t(keys.NETWORK_SWITCH_LOW_BW_MODE)}
                </span>
            ) : null}
        </>
    );
};

export default LowNetworkSwitchResolution;
