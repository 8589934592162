export const SET_PIP_VISIT_ID = 'SET_PIP_VISIT_ID';
export const SET_PIP_SESSION_ID = 'SET_PIP_SESSION_ID';

export const setPipVisitId = (value) => ({
    type: SET_PIP_VISIT_ID,
    payload: value,
});

export const setPipSessionId = (value) => ({
    type: SET_PIP_SESSION_ID,
    payload: value,
});
