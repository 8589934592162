import {
    ABSENT_CARD,
    ADD_EXTRA_REGISTRANTS,
    UPGRADE,
} from 'components/meteredPricing/MeterModal/constants';
import { EVENT_TYPES } from 'utils/constants/airmeet';

export const FREE_PLAN = 'FREE';
export const BUSINESS_PLAN = 'STANDARD';
export const ENTERPRISE_PLAN = 'ENTERPRISE';
export const APPSUMO = 'APPSUMO';
export const APPSUMO_USER = 'Appsumo';
export const COMMUNITY_DASHBOARD = 'COMMUNITY_DASHBOARD';
export const AIRMEET_DASHBOARD = 'AIRMEET_DASHBOARD';
export const REGISTERED_COUNT_LEFT_WARNING_COLOR = '#EB4747';
export const BUSINESS_PRO_EL = 'BUSINESS_PRO_EL';
export const BUSINESS_PRO_CL = 'BUSINESS_PRO_CL';
export const CONFERENCE_STARTER_PLAN = 'CONFERENCE_STARTER';
export const CONFERENCE_PRO_PLAN = 'CONFERENCE_PRO';
export const SOCIAL_WEBINAR_PRO_EL = 'SOCIAL_WEBINAR_PRO_EL'; // Standard yearly plan

// attenee plans values
export const FREE_ATTENDEE = 'FREE_ATTENDEE';
export const STARTER_ATTENDEE = 'STARTER_ATTENDEE';
export const PRO_ATTENDEE = 'PRO_ATTENDEE';
export const ENTERPRISE_ATTENDEE = 'ENTERPRISE_ATTENDEE';

// analytics event names constants
export const DASHBOARD_SWITCH_EVENT = 'dashboardSwitch';
export const EDIT_BUTTON_WITHIN_AIRMEET_EVENT = 'editButtonWithinAirmeet';
export const CONTACT_SALES_WITHIN_MODAL_EVENT = 'contactSalesWithinModal';
export const SWITCH_AIRMEET_WITHIN_MODAL_EVENT = 'switchAirmeetWithinModal';
export const EDUCATE_POLICY_MODAL_BUTTON_CLICK_EVENT =
    'educatePolicyModalButtonClick';
export const NINETY_PERCENT_ALERT_CLOSED = 'ninetyPercentAlertClosed';
export const HUNDRED_PERCENT_ALERT_CLOSED = 'hundredPercentAlertClosed';
export const NINETY_PERCENT_ALERT_SET_REG_LIMIT_CLICKED =
    'ninetyPercentAlertSetRegistrationLimitClicked';
export const HUNDRED_PERCENT_ALERT_SET_REG_LIMIT_CLICKED =
    'hundredPercentAlertSetRegistrationLimitClicked';
export const LIMIT_EXCEEDED_ALERT_SET_REG_LIMIT_CLICKED =
    'limitExceededAlertSetRegistrationLimitClicked';
export const NINETY_PERCENT_ALERT_VIEWED = 'ninetyPercentAlertViewed';
export const HUNDRED_PERCENT_ALERT_VIEWED = 'hundredPercentAlertViewed';
export const EVENT_ACCESS_EDIT_CLICKED = 'eventAccessEdit';
export const PREMIUM_WITHOUT_CARD = 'premiumWithoutCard';
export const ADD_PAYMENT_SOURCE_VIEWED = 'addPaymentSourceViewed';
export const CANCEL_PAYMENT_SOURCE = 'cancelPaymentSource';
export const ADD_PAYMENT_SOURCE_ERROR = 'addPaymentSourceError';
export const ADD_PAYMENT_SOURCE_CANCEL_NUDGE = 'addPaymentSourceCancelNudge';
export const PAYMENT_SOURCE_ADDED_SUCCESSFULLY =
    'paymentSourceAddedSuccessfully';
export const PAYMENT_SOURCE_CARD_DETAILS_FILLED =
    'paymentSourceCardDetailsFilled';
export const PAYMENT_SOURCE_BILLING_DETAILS_FILLED =
    'paymentSourceBillingDetailsFilled';
export const CLICKED_LIVE_ALERT_CLOSE = 'clickedLiveAlertClose';
export const CLICKED_LIVE_ALERT_PRIMARY = 'clickedLiveAlertPrimary';
export const VIEWED_LIVE_ALERT = 'viewedLiveAlert';
export const SPEAKER_LIMIT_MODAL_PRIMARY_CTA = 'speakerLimitModalPrimaryCta';
export const SPEAKER_LIMIT_MODAL_SECONDARY_CTA =
    'speakerLimitModalSecondaryCta';
export const SPEAKER_LIMIT_MODAL_SWITCH_STATE = 'speakerLimitModalSwitchState';
export const EXHIBITOR_LIMIT_MODAL_PRIMARY_CTA =
    'exhibitorLimitModalPrimaryCta';
export const EXHIBITOR_LIMIT_MODAL_SECONDARY_CTA =
    'exhibitorLimitModalSecondaryCta';
export const EXHIBITOR_LIMIT_MODAL_SWITCH_STATE =
    'exhibitorLimitModalSwitchState';

// analytics param
export const ADD_PAYMENT_SOURCE_CANCEL_NUDGE_STATUS_RETAINED = 'retained';
export const ADD_PAYMENT_SOURCE_CANCEL_NUDGE_STATUS_NOT_RETAINED =
    'not retained';

export const METER_PRICING_COMPONENT_BILLING_FLOW = 'billing_page';
export const METER_PRICING_COMPONENT_APPSUMO_FLOW = 'appsumo_page';
export const METER_PRICING_COMPONENT_INSIDE_AIRMEET_FLOW =
    'inside_airmeet_page';

export const ALERT_TYPE = {
    MEETUP: EVENT_TYPES.MEETUP,
    CONFERENCE: EVENT_TYPES.CONFERENCE,
    MEETUPS_CUMULATIVE: 'MEETUPS_CUMULATIVE',
};
export const ENTERPRISE_DISABLED_TEXT = 'Pay as you go billing disabled';

export const RIGHT_PART_FREE_PLAN_CONFIG = {
    cta: 'Upgrade Plan',
    hint: 'Allow extra registrations',
    modalType: UPGRADE,
    isEditIconVisible: false,
    showPaymentDetailsForm: false,
};

export const RIGHT_PART_ENTERPRISE_PLAN_CONFIG = {
    cta: 'See Options',
    hint: 'Add extra registrations',
    modalType: ADD_EXTRA_REGISTRANTS,
};

export const RIGHT_PART_CONTACT_SALES_CONFIG = {
    cta: 'Contact Sales',
    hint: 'Allow extra registrations',
    modalType: ABSENT_CARD,
    isEditIconVisible: false,
    showPaymentDetailsForm: false,
};

export const FREE_PLAN_SECONDARY_TEXT =
    'Upgrade your plan to allow more registrations for this airmeet.';

export const TURN_OFF_SECONDARY_TEXT =
    'You may turn off extra registrations by setting the registrations limit.';

export const APPSUMO_SECONDARY_TEXT =
    'Contact Sales to enable extra registrations beyond your monthly limit.';

export const METER_ALERT_CTA = {
    UPGRADE_PLAN: 'Upgrade Plan',
    SET_REGISTRATION_LIMIT: 'Set registration limit',
    CONTACT_SALES: 'Contact Sales',
    SEE_ALL_PLANS: 'See all plans',
};

export const CONTACT_SALES_URL = 'https://airmeet.typeform.com/to/NfzPPDdq';
export const PRICING_PAGE_URL = 'https://www.airmeet.com/hub/pricing/';

export const CAPTURE_CARD_DETAILS_ALERT_SUBTEXT_ON_MODAL =
    'To allow extra registrations, you will be asked to add your payment information in the next step. Keep your credit card handy.';

export const SPEAKER_ROLE = 'SPEAKER';
export const EXHIBITOR_ROLE = 'EXHIBITOR';
export const ROLE_MODAL_NAME_MAPPING = {
    [SPEAKER_ROLE]: 'CANNOT_ADD_SPEAKER',
    [EXHIBITOR_ROLE]: 'CANNOT_ADD_EXHIBITOR',
};

// for attendee strip config
export const PAID_PLAN_HINT_TEXT =
    'In case there are overages, it will be charged post event';
export const paidPlanWithAddonsHintText = (
    totalAddonsLeft,
    isMeteredPricingEnabled,
    addonsUtilizedForEvent
) => {
    if (totalAddonsLeft > 0) {
        return `This capacity includes ${totalAddonsLeft} count from Attendee bundle which can be used across all events`;
    }

    if (addonsUtilizedForEvent) {
        if (isMeteredPricingEnabled) {
            return 'Attendee bundle exhausted. Pay as you go enabled';
        }
        return 'Attendee bundle exhausted';
    }
    /* In case an event is created AFTER addons have been used up, we 
    don't want to show the exhausted addon message for that event */
    return '';
};
export const FREE_PLAN_HINT_TEXT = 'Allow extra attendees';
export const DEFAULT_INFO_TOOLTIP_TEXT =
    'Total attendee capacity of your existing plan.';
export const ADDONS_INFO_TOOLTIP_TEXT =
    'Total event capacity including plan and available attendee bundle capacity.';
export const PAY_AS_YOU_GO_URL =
    'https://help.airmeet.com/support/solutions/articles/82000502992-manage-registration-limits-and-enable-pay-as-you-go-for-your-account';
export const FREE_ATTENDEE_PLAN_CONTACT_SALES_URL =
    'https://airmeet.typeform.com/to/NfzPPDdq';
