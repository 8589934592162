import { EventEmitter } from 'events';
import { bindModuleLogger } from 'utils/logger';
import { Listeners, Message, WriteStatus } from '../types';

const logger = bindModuleLogger('Local Updates');

interface LocalUpdateOptions {
    emitLocal: boolean;
}

class LocalUpdates {
    private static instance: LocalUpdates;
    // Stores local messages and whether they were emitted locally or not
    private pendingUpdates: Map<string, boolean>;
    private emitter: EventEmitter;

    constructor() {
        this.emitter = new EventEmitter();
        this.emitter.setMaxListeners(100);
        this.pendingUpdates = new Map();
    }

    static getInstance(): LocalUpdates {
        if (LocalUpdates.instance) {
            return LocalUpdates.instance;
        }
        LocalUpdates.instance = new LocalUpdates();
        return LocalUpdates.instance;
    }

    subscribe(featureName, listeners: Listeners) {
        logger.debug(`Subscribe local ${featureName}`);
        if (listeners.onAdd) {
            this.emitter.on(`local_${featureName}_added`, listeners.onAdd);
        }
        if (listeners.onChange) {
            this.emitter.on(`local_${featureName}_changed`, listeners.onChange);
        }
        return () => {
            if (listeners.onAdd) {
                this.emitter.off(`local_${featureName}_added`, listeners.onAdd);
            }
            if (listeners.onChange) {
                this.emitter.off(
                    `local_${featureName}_changed`,
                    listeners.onChange
                );
            }
        };
    }

    addTimestamp(message: Message) {
        if (
            message.payload &&
            (message.payload.createdAt?.['.sv'] ||
                message.payload.timestamp?.['.sv'])
        ) {
            message = { ...message };
            message.payload = { ...message.payload };
            const timestamp = Date.now();
            message.payload.createdAt = timestamp;
            message.payload.timestamp = timestamp;
        }
        return message;
    }

    transform(message: Message) {
        message = this.addTimestamp(message);
        return message;
    }

    addPendingUpdate(
        featureName: string,
        message: Message,
        options: LocalUpdateOptions = {
            emitLocal: false,
        }
    ) {
        const localId = this.getLocalId(featureName, message.metadata.key);

        this.pendingUpdates.set(localId, options.emitLocal);

        if (options.emitLocal) {
            message = this.transform(message);
            this.emitter.emit(`local_${featureName}_added`, message);
        }
    }

    clearPendingUpdate(featureName: string, message: Message) {
        const localId = this.getLocalId(featureName, message.metadata.key);
        setTimeout(() => {
            this.pendingUpdates.delete(localId);
        }, 100); // Added delay for multiple listeners calling on update
    }

    // Returns true if message is emitted
    notifyStatusChange(
        featureName: string,
        message: Message,
        status: WriteStatus
    ): boolean {
        const localId = this.getLocalId(featureName, message.metadata.key);
        if (this.pendingUpdates.has(localId)) {
            message = this.transform(message);

            message.metadata.status = status;
            // either using optimistic updates or this is an success or error handling, which internally notifies everyone
            // including data writer and the data observer
            const isTerminalState =
                status === WriteStatus.SUCCESS || status === WriteStatus.ERROR;

            const isUsingOptimisticUpdates = this.pendingUpdates.get(localId);

            const shouldEmit = isUsingOptimisticUpdates || isTerminalState;

            if (shouldEmit) {
                const eventName = isUsingOptimisticUpdates
                    ? `local_${featureName}_changed`
                    : `local_${featureName}_added`;
                this.emitter.emit(eventName, message);
            }

            if (isTerminalState) {
                this.clearPendingUpdate(featureName, message);
            }

            return shouldEmit;
        }
        return false;
    }

    private getLocalId(featureName: string, messageId: string) {
        return `${featureName}_${messageId}`;
    }
}
export default LocalUpdates;
