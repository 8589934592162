import React from 'react';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import PermissionUtils from 'utils/permission-utils';
import { isSessionHostOrCohost } from 'utils/userAccessControl';
import NetworkStatus from '../../general/NetworkStatus';
const BackStageButton = React.lazy(() => import('../BackStageButton'));
const ProfileButton = React.lazy(() => import('../ProfileButton'));

export default function LiveHeader({
    isBackStage,
    isBackStageEnabled,
    airmeetId,
    currentSession,
    userRole,
    showLowNetworkStatus = false,
    liveBackstageUsers,
    parallelTrackEnabled,
    isDesktopMode,
}) {
    const HEADER_LEFT = [];
    const HEADER_CONTENT = [];

    const HEADER_RIGHT = [
        <div className='d-flex session-action-outer'>
            {isBackStageEnabled ? (
                <BackStageButton
                    isBackStage={isBackStage}
                    airmeetId={airmeetId}
                    currentSession={currentSession}
                    userRole={userRole}
                    liveBackstageUsers={liveBackstageUsers}
                    parallelTrackEnabled={parallelTrackEnabled}
                />
            ) : null}
            {isSessionHostOrCohost() && (
                <div id='session-action-buttons' className='d-flex' />
            )}
        </div>,
        getAirmeetUtilInstance() ? (
            !isDesktopMode ? (
                <ProfileButton iconOnly />
            ) : null
        ) : null,
    ];

    const HEADER_TOP_BAR = [
        getAirmeetUtilInstance() &&
        showLowNetworkStatus &&
        !PermissionUtils.isSessionScreenRecordingUser() ? (
            <NetworkStatus />
        ) : null,
    ];

    return [HEADER_LEFT, HEADER_CONTENT, HEADER_RIGHT, HEADER_TOP_BAR];
}
