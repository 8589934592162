import { DEFAULT_NAV_LABELS } from 'components/lounge/rhsV2/common/constants';
import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function useCustomText() {
    const customText = useSelector(
        (state) => state?.lounge?.airmeet?.custom_text
    );
    const isResourceManagementEnabled = useSelector(
        (state) => state.lounge?.airmeet?.is_resource_management_enabled
    );
    useEffect(() => {
        if (
            isResourceManagementEnabled &&
            !DEFAULT_NAV_LABELS.includes('RESOURCES')
        )
            DEFAULT_NAV_LABELS.push('RESOURCES');
    }, [isResourceManagementEnabled]);

    const result = useMemo(() => {
        const values = {};

        if (customText) {
            DEFAULT_NAV_LABELS.forEach((key) => {
                const customTextValue = customText.find(
                    (obj) =>
                        (obj?.type || '')?.toLowerCase() === key?.toLowerCase()
                );
                let value = null;
                if (customTextValue) {
                    //TODO: Make it dynamic acc. to language selection
                    const engValue = (customTextValue?.values || []).find(
                        (val) => val.lan === 'en'
                    );
                    value = engValue?.value;
                }

                // // Set default value if custom value is not present
                // if (!value) {
                //     value = DEFAULT_NAV_LABELS[key];
                // }

                values[key] = value;
            });
        }

        return values;
    }, [customText]);

    return result || {};
}
