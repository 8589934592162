export const USER_ROLE_HOST = 'Host';

export const USER_ROLE_ORGANIZER = 'Organizer';

export const USER_ROLE_SPEAKER = 'Speaker';

export const USER_ROLE_ATTENDEE = 'Attendee';

export const SESSION_STATUS_ONGOING = 'ONGOING';

export const SESSION_STATUS_PAUSED = 'PAUSED';

export const SESSION_STATUS_FINISHED = 'FINISHED';

export const SESSION_STATUS_CREATED = 'CREATED';

export const SESSION_SOCIAL_LOUNGE = 'SOCIAL_LOUNGE';
