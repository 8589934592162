/**************************************************************
 * @fileOverview Leads Actions => These are actions which talks
 * to the entity-leads api to create,get and update any type
 * of leads to any third-party integration like hubspot etc.
 **************************************************************/

import { LeadEventTypes } from 'hooks/leads/utils';
import { CALL_API } from 'store/api';
import { API_SERVICE } from 'utils/apiService';

const {
    conferenceTrialContactLead,
    conferenceTrialLiveDemo,
    conferenceTrialEventCreated,
} = LeadEventTypes;

export const CREATE_LEADS_REQUEST = 'CREATE_LEADS_REQUEST';
export const CREATE_LEADS_RESPONSE = 'CREATE_LEADS_RESPONSE';
export const FETCH_LEADS_REQUEST = 'FETCH_LEADS_REQUEST';
export const FETCH_LEADS_RESPONSE = 'FETCH_LEADS_RESPONSE';
export const UPDATE_LEADS_REQUEST = 'UPDATE_LEADS_REQUEST';
export const UPDATE_LEADS_RESPONSE = 'UPDATE_LEADS_RESPONSE';
export const CONFERENCE_TRIAL_CONTACT_LEAD = conferenceTrialContactLead;
export const CONFERENCE_TRIAL_LIVE_DEMO = conferenceTrialLiveDemo;
export const CONFERENCE_TRIAL_EVENT_CREATED = conferenceTrialEventCreated;

export function getLeads(payload) {
    const { email, name, communityId, airmeetId } = payload;
    const queryParams = { email, name, communityId, airmeetId };
    const queryString = Object.keys(queryParams)
        .filter((key) => queryParams[key])
        .map((key) => `${key}=${queryParams[key]}`)
        .join('&');
    return {
        [CALL_API]: {
            types: [FETCH_LEADS_REQUEST, FETCH_LEADS_RESPONSE],
            endpoint: `/entity-lead?${queryString}`,
            method: 'GET',
            service: API_SERVICE.BFF,
            type: 'json',
            extra: { leadName: name }, // Lead name as extra param
        },
    };
}

export function createLeads(data) {
    return {
        [CALL_API]: {
            types: [CREATE_LEADS_REQUEST, CREATE_LEADS_RESPONSE],
            endpoint: `/entity-lead`,
            body: data,
            method: 'POST',
            service: API_SERVICE.BFF,
            type: 'json',
        },
    };
}

export function updateLeads(data) {
    return {
        [CALL_API]: {
            types: [UPDATE_LEADS_REQUEST, UPDATE_LEADS_RESPONSE],
            endpoint: `/entity-lead`,
            body: data,
            method: 'PATCH',
            service: API_SERVICE.BFF,
            type: 'json',
        },
    };
}
