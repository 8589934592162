export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
export const SET_ARE_FILTERS_APPLIED = 'SET_ARE_FILTERS_APPLIED';
export const SET_FILTER_BODY = 'SET_FILTER_BODY';
export const SET_SEARCH_KEYS = 'SET_SEARCH_KEYS';

export const setSearchString = (searchString: string) => ({
    type: SET_SEARCH_STRING,
    payload: searchString,
});

export const setAreFiltersApplied = (areFiltersApplied: boolean) => ({
    type: SET_ARE_FILTERS_APPLIED,
    payload: areFiltersApplied,
});

export const setFilterBody = (body: object) => ({
    type: SET_FILTER_BODY,
    payload: body,
});

export const setSearchKeys = (keys: Array<string>) => ({
    type: SET_SEARCH_KEYS,
    payload: keys,
});
