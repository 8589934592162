import React, { useContext, useMemo } from 'react';
import { EVENT_TYPES } from 'utils/constants/airmeet';
import { AnalyticsContext } from '../AnalyticsProvider';
import SelectiveBarChartCard from '../components/SelectiveBarChartCard';

const TOP_DATA = [
    {
        key: 'stats.activity.total',
        title: 'Session Active Joiners',
        info: 'This is the number of unique users who performed an activity.',
    },
    {
        key: 'stats.activity.raised_hand',
        title: 'Raised Hand',
        backgroundRGB: '215,41,62',
    },
    {
        key: 'stats.activity.question',
        title: 'Asked Question',
        backgroundRGB: '250,219,223',
    },
    {
        key: 'stats.activity.poll',
        title: 'Answered Poll',
        backgroundRGB: '238,81,175',
    },
    {
        key: 'stats.activity.messages',
        title: 'Sent Messages',
        backgroundRGB: '194,19,123',
    },
    {
        key: 'stats.activity.reactions',
        title: 'Reacted Emoji',
        backgroundRGB: '97,9,61',
    },
];

const dataInfo = TOP_DATA.filter((item) => item.key !== 'stats.activity.total');

export default function SessionActiveAttendanceCard() {
    const {
        getRequest,
        timezone,
        sessionTags,
        sessionOptions,
        event_type,
    } = useContext(AnalyticsContext);

    const topDataInfo = useMemo(
        () =>
            TOP_DATA.filter(
                ({ key }) =>
                    key !== 'stats.activity.messages' ||
                    event_type === EVENT_TYPES.CONFERENCE
            ),
        [event_type]
    );

    return (
        <SelectiveBarChartCard
            title='Session Active Joiners'
            searchTags={sessionTags}
            searchOptions={sessionOptions}
            getRequest={getRequest}
            timezone={timezone}
            topDataInfo={topDataInfo}
            dataInfo={dataInfo}
            perDayDataPath='stats.per_day'
        />
    );
}
