import { CALL_API } from '../api';

export const HAND_RAISE_REQUESTED_REQUEST = 'HAND_RAISE_REQUESTED_REQUEST';
export const HAND_RAISE_REQUESTED_RESPONSE = 'HAND_RAISE_REQUESTED_RESPONSE';
export const FETCH_HAND_RAISE_REQUESTED_LIST_REQUEST =
    'FETCH_HAND_RAISE_REQUESTED_LIST_REQUEST';
export const FETCH_HAND_RAISE_REQUESTED_LIST_RESPONSE =
    'FETCH_HAND_RAISE_REQUESTED_LIST_RESPONSE';
export const GET_HAND_RAISE_REQUEST = 'GET_HAND_RAISE_REQUEST';
export const WITHDRAW_HAND_RAISE_REQUEST = 'WITHDRAW_HAND_RAISE_REQUEST';
export const ACTIVE_HAND_RAISE_REQUEST = 'ACTIVE_HAND_RAISE_REQUEST';
export const ACCEPT_HAND_RAISE_REQUEST = 'ACCEPT_HAND_RAISE_REQUEST';
export const REJECT_HAND_RAISE_REQUEST = 'REJECT_HAND_RAISE_REQUEST';
export const COMPLETED_HAND_RAISE_REQUEST = 'COMPLETED_HAND_RAISE_REQUEST';
export const SET_FIREBASE_PRIMARY_SHARDS_FOR_RAISE_HAND =
    'SET_FIREBASE_PRIMARY_SHARDS_FOR_RAISE_HAND';
export const HAND_RAISE_REQUESTED_LIST_ON_LOAD =
    'HAND_RAISE_REQUESTED_LIST_ON_LOAD';
export const UPDATE_RAISE_HAND_REQUEST_COUNTER =
    'UPDATE_RAISE_HAND_REQUEST_COUNTER';

export const HAND_RAISE_REQUEST_STATUS = {
    noRequest: 'no-request',
    requesting: 'requesting',
    pending: 'pending',
    accepted: 'accepted',
    declined: 'declined',
    active: 'active',
    withdraw: 'withdraw',
    leaveRequest: 'leave-request',
    completed: 'completed',
};

export function fetchRequestList(sessionId) {
    return {
        [CALL_API]: {
            types: [
                FETCH_HAND_RAISE_REQUESTED_LIST_REQUEST,
                FETCH_HAND_RAISE_REQUESTED_LIST_RESPONSE,
            ],
            endpoint: `/airmeet/table`,
            body: {
                sessionId,
            },
            type: 'json',
            method: 'GET',
        },
    };
}

export function setHandRaiseListOnLoad(sessions) {
    return {
        type: HAND_RAISE_REQUESTED_LIST_ON_LOAD,
        payload: {
            sessions,
        },
    };
}

export function setRaiseHandRequestCounter(sessionId, counter) {
    return {
        type: UPDATE_RAISE_HAND_REQUEST_COUNTER,
        payload: {
            sessionId,
            counter,
        },
    };
}

export function setConnectViaFirebasePrimaryShards(
    connectViaFirebasePrimaryShards
) {
    return {
        type: SET_FIREBASE_PRIMARY_SHARDS_FOR_RAISE_HAND,
        payload: {
            connectViaFirebasePrimaryShards,
        },
    };
}
