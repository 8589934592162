import { cdnImage } from 'utils/core';
import { USER_ROLE } from './users';

export const NEW_LIVE_WINDOW_LAYOUT_GALLERY = 'Gallery';
export const NEW_LIVE_WINDOW_LAYOUT_ACTIVE_SPEAKER = 'Active-Speaker';
export const NEW_LIVE_WINDOW_LAYOUT_SPOTLIGHT = 'Spotlight';

export const TABLE_LAYOUT_ACTIVE_ICONS = {
    [NEW_LIVE_WINDOW_LAYOUT_GALLERY]: 'grid',
    [NEW_LIVE_WINDOW_LAYOUT_ACTIVE_SPEAKER]: 'broad_activity_feed',
    [NEW_LIVE_WINDOW_LAYOUT_SPOTLIGHT]: 'active_speaker',
};

export const TABLE_LAYOUT_ICONS = {
    [NEW_LIVE_WINDOW_LAYOUT_GALLERY]: 'grid_outline',
    [NEW_LIVE_WINDOW_LAYOUT_ACTIVE_SPEAKER]: 'activity_feed_outline',
    [NEW_LIVE_WINDOW_LAYOUT_SPOTLIGHT]: 'active_speaker_outline',
};

export const NEW_LIVE_WINDOW_SPEAKER_COUNT = {
    [NEW_LIVE_WINDOW_LAYOUT_GALLERY]: 7,
    [NEW_LIVE_WINDOW_LAYOUT_ACTIVE_SPEAKER]: 4,
    [NEW_LIVE_WINDOW_LAYOUT_SPOTLIGHT]: 1,
};

export const NEW_LIVE_WINDOW_LAYOUT_TOOLTIP_ICONS = {
    [NEW_LIVE_WINDOW_LAYOUT_GALLERY]: 'gallery_view_tooltip',
    [NEW_LIVE_WINDOW_LAYOUT_ACTIVE_SPEAKER]: 'active_speaker_view_tooltip',
    [NEW_LIVE_WINDOW_LAYOUT_SPOTLIGHT]: 'spotlight_view_tooltip',
};

export const DEFAULT_TABLE_COUNT = 5;
export const MIN_CHAIR_TOTAL = 1;
export const DEFAULT_CHAIR_COUNT = 4;
export const DEFAULT_CHAIR_COUNT_V2 = 8;
export const BOOTH_LOUNGE_TABLE_COUNT = 6;
export const BOOTH_LOUNGE_MAX_TABLE_COUNT = 12;
export const BOOTH_LOUNGE_MIN_TABLE_COUNT = 1;

export const MAX_VISIBLE_CHAIRS = 8;
export const DEFAULT_LARGE_TABLE_CAPACITY = 50;

export const DEFAULT_LOUNGE_NAME = 'common';
export const DEFAULT_LOUNGE_NODE = 'socialLounge';
export const MIN_REQUIRED_TABLE_PERSON_COUNT = 1;
export const SOCIAL_LOUNGE_NAME = 'socialLounge';
export const MEETING_LOUNGE_NAME = 'meetingLounge';
export const BOOTH_LOUNGE_NAME = 'booth';
export const BREAKOUT_LOUNGE_NAME = 'breakout';
export const SINGLE_CHANNEL_PERSON_LIMIT = 126;

export const adminSlot = () => 'admin';
export const chairName = (index) => `chair-${index + 1}`;
export const EMPTY_CHAIR = null;
export const MODERATOR_SLOT = 'moderator';

export const getNextAvailableChair = (
    occupied,
    limit,
    maxLoungeTableCapacity
) => {
    limit = limit || maxLoungeTableCapacity;
    if (Object.keys(occupied).length < limit) {
        const x = new Array(limit)
            .fill(0)
            .reduce(
                (acc, c, index) =>
                    acc ||
                    (!occupied[chairName(index)] ? chairName(index) : null),
                null
            );
        return x;
    } else {
        return null;
    }
};

export const getLoungeTableCapacity = (configValue) => {
    return configValue || DEFAULT_LARGE_TABLE_CAPACITY;
};

const { HOST, COHOST, SPEAKER, ORGANIZER } = USER_ROLE;

export const BACKSTAGE_CTA_BANNER_KEYS = {
    PRIMARY: {
        [HOST]: 'PRIMARY_TEXT_1',
        [COHOST]: 'PRIMARY_TEXT_1',
        [SPEAKER]: 'PRIMARY_TEXT_2',
        [ORGANIZER]: 'PRIMARY_TEXT_1',
    },
    SECONDARY: {
        [HOST]: 'SECONDARY_TEXT_1',
        [COHOST]: 'SECONDARY_TEXT_1',
        [SPEAKER]: 'SECONDARY_TEXT_2',
        [ORGANIZER]: 'SECONDARY_TEXT_1',
    },
};
export const BACKSTAGE_BANNER = 'BACKSTAGE_BANNER';
export const BACKSTAGE_MANAGE_SESSION = 'BACKSTAGE_MANAGE_SESSION';

export const FULL_SCREEN_MODE = 'full_screen';
export const MINIMISED_SCREEN_MODE = 'minimised_screen';

export const TABLE_LAYOUT_EVENT_NAME_MAP = {
    [NEW_LIVE_WINDOW_LAYOUT_GALLERY]: 'gallery_view',
    [NEW_LIVE_WINDOW_LAYOUT_ACTIVE_SPEAKER]: 'active_speaker_view',
    [NEW_LIVE_WINDOW_LAYOUT_SPOTLIGHT]: 'spotlight_view',
    [MINIMISED_SCREEN_MODE]: 'minimised_screen',
    [FULL_SCREEN_MODE]: 'full_screen',
};

export const VACANT_CHAIR_NUMBER = 5;
export const OCCUPIED_COUNT_CHAIR_NUMBER = 6;

export const DefaultTableData = {
    1: {
        chairs: 4,
        logo: cdnImage('/images/tables/meet_n_greet.svg'),
    },
    2: {
        chairs: 2,
        logo: cdnImage('/images/tables/one_one.svg'),
    },
    3: {
        chairs: 8,
        logo: cdnImage('/images/tables/hi.svg'),
    },
};

const TABLE_CODE_SEP = '-';
const TABLE_CODE_SEP_FREQ = 4;

/**
 * getTableNumber is used to extract the table number from the table code.
 * If hasUUIDV4AsPrefix is true, it considers UUIDV4 as a prefix in the table code.
 * @param {string} tableCode - The input table code.
 * @param {boolean} hasUUIDV4AsPrefix - Flag indicating whether the table code has UUIDV4 as a prefix.
 * @returns {string} - Extracted table number.
 */
export const getTableNumber = (tableCode = '', hasUUIDV4AsPrefix = false) => {
    if (hasUUIDV4AsPrefix) {
        const parts = tableCode?.split(TABLE_CODE_SEP) || [];
        if (parts.length >= TABLE_CODE_SEP_FREQ + 2) {
            const [, , , , , ...remainingParts] = parts;
            const tableNumber = remainingParts.join(TABLE_CODE_SEP);
            return tableNumber;
        }
    }

    const codeSplit = tableCode?.split(TABLE_CODE_SEP) || [];
    return codeSplit.length ? codeSplit[codeSplit.length - 1] : tableCode;
};

// Default table count data
export const TABLE_COUNT_OPTIONS = [
    { value: 4, label: '4' },
    { value: 8, label: '8' },
    { value: 12, label: '12' },
    { value: 16, label: '16' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
];

export const TABLE_TYPE_USER = 'user-created';
export const TABLE_TYPE_DEFAULT = 'default-table';

export const OPEN_TABLE_INVITE_OVERLAY = 'OPEN_TABLE_INVITE_OVERLAY';

export const MAX_USER_TABLES_ALLOWED = 50;
export const MAX_ORG_TABLES_ALLOWED = 500;

export const getLoungePath = (airmeetId, loungeNode, loungeId) => {
    if (!airmeetId) throw new Error('airmeetId is required');
    if (!loungeNode) throw new Error('loungeNode is required');
    if (!loungeId) throw new Error('loungeId is required');

    return `${airmeetId}/${loungeNode}/${loungeId}`;
};

export { USER_ROLE };
//function used to derive context name of lounge instance while sending analytics
export const getContextName = (loungeName) => {
    let context_name = '';
    switch (loungeName) {
        case SOCIAL_LOUNGE_NAME:
            context_name = 'LOUNGE_TABLE';
            break;
        case BOOTH_LOUNGE_NAME:
            context_name = 'BOOTH_TABLE';
            break;
        case BREAKOUT_LOUNGE_NAME:
            context_name = 'BREAKOUT_TABLE';
            break;
        default:
            context_name = 'LOUNGE_TABLE';
            break;
    }
    return context_name;
};
