import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HEADER_TEMPLATES } from '../components/header/templates';
import { setHeaderProps } from '../store/actions/layout';
import usePropStack from './usePropStack';

/**
 * Sets the header template for the current page.
 * By default the header remains visible (with the basic template)
 * Can also pass non-function props to the header
 */
function useHeader(template = HEADER_TEMPLATES.basic, props = {}) {
    const dispatch = useDispatch();

    const { header } = useSelector((state) => state.layout);

    const [overwrite] = useState(header.template === template);

    // In case of no template, hide
    props = {
        ...(overwrite ? header : {}),
        ...props,
        hidden: template === HEADER_TEMPLATES.none,
        template,
    };

    const setNewProps = (newProps) => {
        dispatch(setHeaderProps(newProps));
    };

    const resetOldProps = (oldProps) => {
        // Reset an old props
        dispatch(setHeaderProps(oldProps));
    };

    usePropStack({
        resetProps: header,
        setProps: props,
        onSet: setNewProps,
        onReset: resetOldProps,
    });

    return null;
}

export default useHeader;
