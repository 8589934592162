import {
    BREAKOUT_JOINING_MODES,
    BREAKOUT_STATUS,
    getBreakoutLoungeNode,
} from 'components/StageRoom/BreakoutRooms/constants';
import { getIsBreakoutEnabled } from 'context/LiveAirmeet';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getActiveBreakoutId } from 'store/selectors';
import { emptyObject } from 'utils/constants/common';
import { getLoungePath } from 'utils/constants/social-lounge';
import useActiveBreakoutId from './useActiveBreakoutId';
import useBreakoutSessionTimer from './useBreakoutSessionTimer';

const useBreakoutConfig = () => {
    const [breakoutState, setBreakoutState] = useState({});
    const activeBreakoutStore = useSelector(getActiveBreakoutId);
    const activeBreakoutIdFirebase = useActiveBreakoutId();
    const activeBreakoutId = activeBreakoutStore || activeBreakoutIdFirebase;
    const [isDataLoaded, setIsDataLoaded] = useState(!activeBreakoutId);
    const {
        firebaseClient,
        airmeet: {
            airmeetId,
            data: { currentSession },
        },
    } = useLiveAirmeetContext();
    const isBreakoutsEnabled = useLiveAirmeetContext(getIsBreakoutEnabled);
    const {
        timeLeft,
        timeLeftInMin,
        isBreakoutDurationLapsed,
    } = useBreakoutSessionTimer({
        duration: breakoutState?.duration,
        endAt: breakoutState?.endAt,
    });

    const isBreakoutActive =
        activeBreakoutId &&
        [BREAKOUT_STATUS.ONGOING].includes(breakoutState?.status);
    const sessionId = currentSession ? currentSession.sessionid : null;

    const loungeNode = sessionId && getBreakoutLoungeNode(sessionId);

    const breakoutKey = useMemo(() => {
        if (!loungeNode || !activeBreakoutId || !airmeetId) return null;
        const loungePath = getLoungePath(
            airmeetId,
            loungeNode,
            activeBreakoutId
        );
        return `${loungePath}/configs`;
    }, [loungeNode, activeBreakoutId, airmeetId]);

    useEffect(() => {
        if (breakoutKey && firebaseClient) {
            const updateBreakoutState = ({ value }) => {
                if (value === undefined) {
                    return;
                }
                setBreakoutState(value);
                setIsDataLoaded(true);
            };

            firebaseClient.getDataSync(breakoutKey, updateBreakoutState);

            return () => {
                firebaseClient.clearDataSync(breakoutKey, updateBreakoutState);
            };
        }
    }, [firebaseClient, breakoutKey]);

    useEffect(() => {
        if (!breakoutKey) {
            setBreakoutState({});
        }
    }, [breakoutKey]);

    return {
        isDataLoaded,
        breakoutEntityId: sessionId,
        isBreakoutActive:
            isBreakoutActive && !isBreakoutDurationLapsed && isBreakoutsEnabled,
        activeBreakoutId,
        currentBreakout: breakoutKey && breakoutState,
        ...(breakoutKey ? breakoutState : emptyObject),
        timeLeft,
        timeLeftInMin,
        isBreakoutDurationLapsed,
        isDiscoverable:
            breakoutState?.joiningMode === BREAKOUT_JOINING_MODES.DISCOVERABLE,
    };
};

export default useBreakoutConfig;
