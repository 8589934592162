import { useCustomTranslation } from 'hooks/useCustomTranslation';
import keys from 'locale/keys';
import React, { useState } from 'react';
import ColorSelector from '../../widgets/ColorSelector';
import CustomizeAmbience from '../../widgets/CustomizeAmbience';
import AutoBrandBanner from './AutoBrandBanner';
import CustomizeBackground from './CustomizeBackground/';
import CustomizeFont from './CustomizeFont';

export default function ThemeSettings({
    theme_info,
    handleChange,
    isConference,
    isPaidSubscription,
    setShowPageOverlay,
    live_config,
    handleEventBrandingChange,
    logEvent,
}) {
    const { t } = useCustomTranslation();
    const [ambienceSuggestionVisible, setAmbienceSuggestionVisible] = useState(
        false
    );

    return (
        <>
            {(isConference || isPaidSubscription) && (
                <AutoBrandBanner
                    handleChange={handleChange}
                    handleEventBrandingChange={handleEventBrandingChange}
                    theme_info={theme_info}
                    isAutoBrandApplied={
                        live_config?.eventBranding?.isAutoBrandApplied
                    }
                    brandColors={live_config?.eventBranding?.brandColors || []}
                    logEvent={logEvent}
                />
            )}
            <CustomizeAmbience
                themeInfo={theme_info}
                onChange={handleChange}
                ambienceSuggestionVisible={ambienceSuggestionVisible}
                setAmbienceSuggestionVisible={setAmbienceSuggestionVisible}
                primaryColor={theme_info?.primary_color}
            />
            {(isConference || isPaidSubscription) && (
                <>
                    <ColorSelector
                        value={theme_info?.primary_color}
                        is_light_theme={theme_info?.is_light_theme}
                        onChange={handleChange}
                        setShowPageOverlay={setShowPageOverlay}
                        setAmbienceSuggestionVisible={
                            setAmbienceSuggestionVisible
                        }
                        title={t(keys.RHS_CUSTOMIZE_THEME_TITLE)}
                        subtitle={t(keys.RHS_CUSTOMIZE_THEME_SUBTITLE)}
                        mode='INPUT'
                    />
                    <ColorSelector
                        value={
                            live_config?.eventBranding
                                ?.primaryButtonTextColor || 'hsl(0,0%,100%)'
                        }
                        is_light_theme={theme_info?.is_light_theme}
                        onChange={handleEventBrandingChange}
                        setShowPageOverlay={setShowPageOverlay}
                        type='live_config'
                        prop='primaryButtonTextColor'
                        defaultColors={['hsl(0,0%,100%)', 'hsl(250,0%,20%)']}
                        title={t(keys.RHS_CUSTOMIZE_BUTTON_TEXT_COLOR_TITLE)}
                        subtitle={t(
                            keys.RHS_CUSTOMIZE_BUTTON_TEXT_COLOR_SUBTITLE
                        )}
                        mode='INPUT'
                    />
                    <CustomizeBackground
                        value={live_config?.eventBranding?.background}
                        onChange={handleEventBrandingChange}
                        is_light_theme={theme_info?.is_light_theme}
                        type='live_config'
                        prop='background'
                        setAmbienceSuggestionVisible={
                            setAmbienceSuggestionVisible
                        }
                        title={t(keys.RHS_CUSTOMIZE_BACKGROUND_TITLE)}
                        subtitle={t(keys.RHS_CUSTOMIZE_BACKGROUND_SUBTITLE)}
                    />
                    <CustomizeFont
                        value={live_config?.eventBranding?.font || {}}
                        type='live_config'
                        prop='font'
                        title={t(keys.RHS_CUSTOMIZE_FONT_FAMILY_TITLE)}
                        subtitle={t(keys.RHS_CUSTOMIZE_FONT_SUBTITLE)}
                        onChange={handleEventBrandingChange}
                    />
                </>
            )}
        </>
    );
}
