import { INTERACTABLE, VISIBLE } from 'components/community/team/constants';

export const VISIBLE_AND_INTERACTABLE = 'VISIBLE_AND_INTERACTABLE';
export const VISIBLE_AND_NOT_INTERACTABLE = 'VISIBLE_AND_NOT_INTERACTABLE';
export const NOT_VISIBLE_AND_NOT_INTERACTABLE =
    'NOT_VISIBLE_AND_NOT_INTERACTABLE';

// For dev testing
export const PERMISSION_VALUE_SETS = {
    [VISIBLE_AND_INTERACTABLE]: {
        [VISIBLE]: true,
        [INTERACTABLE]: true,
    },
    [VISIBLE_AND_NOT_INTERACTABLE]: {
        [VISIBLE]: true,
        [INTERACTABLE]: false,
    },
    [NOT_VISIBLE_AND_NOT_INTERACTABLE]: {
        [VISIBLE]: false,
        [INTERACTABLE]: false,
    },
};
