import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { position, space } from 'styled-system';
import { Typography } from '../../foundations/typography';
import useTimedCallbacks from '../../hooks/useTimedCallbacks';
import CircularLoader from '../molecules/loader/CircularLoader';
import LogoLoader from '../molecules/loader/LogoLoader';

function Loader({
    type = 'spinner',
    fullPage = false,
    className = '',
    messageTimers = {},
    width = '5rem',
    height = '5rem',
    zIndex = 99,
    ...props
}) {
    const loader = useRef(null);

    const [scale, setScale] = useState(1);

    const {
        current: loadingMessage,
        startTimer,
        resetTimer,
    } = useTimedCallbacks(messageTimers);

    useEffect(() => {
        if (messageTimers) {
            startTimer();
        }

        return resetTimer;
    }, []);

    useLayoutEffect(() => {
        if (!loader.current || !loader.current.parentElement) {
            return;
        }

        const { width, height } =
            loader.current.parentElement.getBoundingClientRect() || {};

        const { width: elWidth, height: elHeight } =
            loader.current.getBoundingClientRect() || {};

        const minScale = parseFloat(
            Math.min(width / elWidth, height / elHeight, 1).toPrecision(2)
        );

        if (minScale > 0 && minScale !== scale) {
            setScale(minScale);
        }
    }, []);

    const LoaderComponent = type === 'spinner' ? CircularLoader : LogoLoader;

    return (
        <OverlayContainer
            fullPage={fullPage}
            className={className}
            wrapperBg={props.wrapperBg}
        >
            <LoaderContainer
                ref={loader}
                scale={scale}
                width={width}
                height={height}
                zIndex={zIndex}
            >
                <LoaderComponent {...props} />
            </LoaderContainer>
            {loadingMessage ? (
                <LoadingMessage
                    variant='button2'
                    color={props.messageColor || 'ambience.1'}
                >
                    {loadingMessage}
                </LoadingMessage>
            ) : null}
        </OverlayContainer>
    );
}

const OverlayContainer = styled.div`
    ${(props) =>
        props.fullPage
            ? css`
                  position: fixed;
                  top: 0;
                  left: 0;
              `
            : css`
                  position: relative;
              `}

    width: 100%;
    height: 100%;

    min-height: 1rem;
    ${({ wrapperBg }) =>
        wrapperBg &&
        `
        background-color: ${wrapperBg};
    `}
`;

const LoaderContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;

    z-index: ${(props) => props.zIndex};

    transform: translate(-50%, -50%) scale(${(props) => props.scale});

    width: ${(props) => props.width};
    height: ${(props) => props.height};

    box-sizing: border-box;
`;

const LoadingMessage = styled(Typography)`
    position: absolute;
    left: 50%;
    bottom: 25px;
    transform: translateX(-50%);
    text-align: center;
`;

export default Loader;

export const PollLoader = styled.div`
    border: 2px solid transparent;
    border-top: 2px solid ${({ theme }) => theme.colors.accentPrimary[0]};
    border-right: 2px solid ${({ theme }) => theme.colors.accentPrimary[0]};
    border-bottom: 2px solid ${({ theme }) => theme.colors.accentPrimary[0]};
    position: absolute;
    top: 8px;
    left: 16px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    ${position}
    ${space}
`;
