const initialState = null;

export default function Login(state = initialState, action) {
    switch (action.type) {
        case 'Login':
            return { ...action.payload };
        case 'Error':
            return { ...action.payload };
        default:
            return state;
    }
}
