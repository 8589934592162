import { useDispatch, useSelector } from 'react-redux';
import {
    EVENT_LANDING_PATH,
    SERIES_LANDING_PATH,
    SIGNUP_PAGE_PATH,
    UNIFIED_FLOW,
} from '../components/auth/utils';
import { MODAL_TYPE_USER_AUTH } from '../components/modals';
import history from '../history';
import { logoutUser } from '../store/actions/auth';
import { setSignUpPageProps, showFixedModal } from '../store/actions/layout';
import { getUrlParams } from 'utils/core';

export default function useAuthActionHandler() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const isAccessCodeUser = useSelector(
        ({ auth }) => !!auth.user && !!auth.accessCode
    );
    // User logged in?
    const isLoggedIn = !!user && !user.guest;
    const isDirectUser = !!user && user.direct;

    /**
     * @desc Show the login modal
     */
    const tryLogin = (props) => {
        if (
            !(
                history.location.pathname.startsWith(EVENT_LANDING_PATH) ||
                history.location.pathname.startsWith(SERIES_LANDING_PATH)
            ) &&
            props.source !== 'AppSumo'
        ) {
            dispatch(
                setSignUpPageProps({
                    source: 'login',
                    action: UNIFIED_FLOW,
                    ...props,
                })
            );
            history.push(SIGNUP_PAGE_PATH);
        } else {
            dispatch(
                showFixedModal(MODAL_TYPE_USER_AUTH, {
                    source: 'login',
                    action: UNIFIED_FLOW,
                    ...props,
                })
            );
        }
    };
    const trySignUp = (props) => {
        if (
            !(
                history.location.pathname.startsWith(EVENT_LANDING_PATH) ||
                history.location.pathname.startsWith(SERIES_LANDING_PATH)
            ) &&
            props.source !== 'AppSumo'
        ) {
            dispatch(
                setSignUpPageProps({
                    source: 'login',
                    action: UNIFIED_FLOW,
                    ...props,
                })
            );
            let search = getUrlParams({ onlyString: true });
            if (props.search) {
                search += `${search ? '&' : ''}${props.search}`;
            }
            history.push({
                pathname: SIGNUP_PAGE_PATH,
                search,
            });
        } else {
            dispatch(
                showFixedModal(MODAL_TYPE_USER_AUTH, {
                    source: 'login',
                    action: UNIFIED_FLOW,
                    ...props,
                })
            );
        }
    };

    /**
     * @desc use for logout user
     */
    const logout = () => {
        dispatch(logoutUser());
        // window.location.reload();
    };

    return {
        isDirectUser,
        isAccessCodeUser,
        isLoggedIn,
        logout,
        tryLogin,
        trySignUp,
        user,
    };
}
