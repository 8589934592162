import { logger } from './logger';

export default class PermissionUtils {
    static currentSession;
    static userRole;
    static authUser;
    static firebaseClient;
    static airmeetId;
    static sessions = [];
    static transcodingMeta = {};
    static cloudUserId = '';
    static allowToRecordSessions = [];

    static assignValues = async ({
        firebaseClient = undefined,
        airmeetId = undefined,
        sessions = undefined,
        currentSession = undefined,
        userRole = undefined,
        authUser = undefined,
        origin = '',
    }) => {
        if (currentSession !== undefined) {
            if (
                this.isEventCloudHost() &&
                currentSession?.sessionid !== this.currentSession?.sessionid
            ) {
                logger.info(
                    'PermissionUtils: currentSession has been updated',
                    {
                        origin,
                        existingSession: this.currentSession?.sessionid,
                        sessionid: currentSession?.sessionid,
                    }
                );
            }
            this.currentSession = currentSession;
        }
        if (authUser !== undefined) {
            if (this.authUser?.id !== authUser?.id) {
                logger.info('PermissionUtils: auth user is assigned', {
                    origin,
                    existingAuthUser: {
                        userId: this.authUser?.id,
                        idSeq: this.authUser?.id_seq,
                    },
                    updatedAuthUser: {
                        userId: authUser?.id,
                        idSeq: authUser?.id_seq,
                    },
                });
            }
            this.authUser = authUser;
        }
        if (userRole !== undefined) this.userRole = userRole;
        if (firebaseClient !== undefined) this.firebaseClient = firebaseClient;
        if (airmeetId !== undefined) this.airmeetId = airmeetId;
        if (sessions !== undefined) {
            this.sessions = sessions;
        }

        const allowToRecordSessions = [];
        for (const session of this.sessions) {
            if (
                session &&
                this.currentSession &&
                session.sessionid === this.currentSession.sessionid
            ) {
                this.cloudUserId = session.cloud_user_id;
            }

            if (session.recording_user_id === this.authUser.id) {
                allowToRecordSessions.push(session.sessionid);
            }

            if (session.cloud_user_id === this.authUser.id) {
                await this.setTranscodingUser(
                    session.sessionid,
                    session.cloud_user_id
                );
            }
        }

        this.allowToRecordSessions = allowToRecordSessions;

        if (
            this.currentSession &&
            this.currentSession.status === 'ONGOING' &&
            !this.isEventCloudHost()
        ) {
            await this.setTranscodingUser(
                this.currentSession.sessionid,
                this.cloudUserId
            );
        }

        if (
            this.currentSession &&
            this.currentSession.status === 'PAUSED' &&
            !this.isEventCloudHost()
        ) {
            await this.clearTranscodingUser(this.currentSession.sessionid);
        }
    };

    static isSessionCloudHost = () => {
        return this.isUserSessionCloudHost(this.authUser);
    };

    static isEventCloudHost = () => {
        return this.isUserEventCloudHost(this.authUser);
    };

    static canSubscribeStream = () => {
        return !this.isEventCloudHost() || this.isSessionScreenRecordingUser();
    };

    static canSendTranscodingUpdates = () => {
        // const val = this.isCloudHost();
        let val = false;
        if (
            this.currentSession &&
            this.transcodingMeta[this.currentSession.sessionid] &&
            this.transcodingMeta[this.currentSession.sessionid]
                .transcoding_user_uid === this.authUser.id
        ) {
            val = true;
        }
        return val;
    };

    static canSendRecordingUpdates = () => {
        return this.isSessionCloudHost();
    };

    static async isSessionCloudHostExit(session = null) {
        if (!session) {
            session = this.currentSession;
        }
        if (!session || !session.cloud_user_id) {
            return false;
        }
        const path = `${this.airmeetId}/onlineUsers/${session.cloud_user_id}`;
        const cloudUserExist = await this.firebaseClient.queryData(path);

        return !!cloudUserExist;
    }

    static isUserSessionCloudHost = (user) => {
        return (
            user &&
            this.currentSession &&
            user.id === this.currentSession.cloud_user_id
        );
    };

    static isSessionScreenRecordingUser = (sessionId = null) => {
        if (!this.isEventCloudHost()) {
            return false;
        }
        sessionId =
            !sessionId && this.currentSession
                ? this.currentSession.sessionid
                : sessionId;

        return (
            this.authUser &&
            sessionId &&
            this.allowToRecordSessions.includes(sessionId)
        );
    };

    static isUserEventCloudHost = (user) => {
        return !!(user && user.user_type === 'cloud_user');
    };

    static isEventScreenRecordingUser = () => {
        return this.isEventCloudHost() && this.allowToRecordSessions.length > 0;
    };

    static async clearTranscodingUser(sessionId, userId) {
        if (!userId) {
            userId = this.authUser.id;
        }

        if (
            this.transcodingMeta[sessionId] &&
            this.transcodingMeta[sessionId].transcoding_user_uid === userId
        ) {
            this.transcodingMeta[sessionId].transcoding_user_uid = null;
            const path = `${this.airmeetId}/meta-data/sessions/${sessionId}/transcoding_user_id`;
            this.firebaseClient.runTransaction(path, (data) => {
                if (data === userId) {
                    data = null;
                }
                return data;
            });
        }
    }

    static async setTranscodingUser(sessionId, cloudUserId) {
        if (!this.transcodingMeta[sessionId]) {
            this.transcodingMeta[sessionId] = {};
        }

        if (this.isEventCloudHost()) {
            try {
                if (
                    this.transcodingMeta[sessionId] &&
                    this.transcodingMeta[sessionId].transcoding_user_uid &&
                    [cloudUserId, this.authUser.id].includes(
                        this.transcodingMeta[sessionId].transcoding_user_uid
                    )
                ) {
                    // do nothing
                } else {
                    const path = `${this.airmeetId}/meta-data/sessions/${sessionId}/transcoding_user_id`;
                    this.firebaseClient.runTransaction(path, (data) => {
                        let transcoding_user_uid;
                        if (data) {
                            // if transcoding user id is not set to cloud host
                            // and if transcoding user id is not same as session host id
                            // overwrite with session host id
                            if (
                                data !== cloudUserId &&
                                data !== this.authUser.id
                            ) {
                                data = this.authUser.id;
                            }

                            transcoding_user_uid = data;
                        }

                        if (!transcoding_user_uid) {
                            transcoding_user_uid = this.authUser.id;
                            data = transcoding_user_uid;
                        }

                        this.transcodingMeta[
                            sessionId
                        ].transcoding_user_uid = transcoding_user_uid;
                        return data;
                    });
                }
            } catch (e) {}
        } else {
            this.transcodingMeta[sessionId].transcoding_user_uid = null;
        }
    }
}
