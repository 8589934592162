import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import Loader from 'components/general/Loader';
import { cdnImage } from '../../../utils/core';
import { getUrlParams } from 'utils/core';
import { getCRMCommunityBasedOnFeaturePlans } from 'store/actions/cmDashboard/integrations';
import { fetchUserPermissions } from 'store/actions/community/member';
import {
    dataCenterRegions,
    getCurrentRegion,
} from 'utils/constants/dataCenter';
import { ORACLE_ELOQUA, SALESFORCE } from 'utils/constants/integrations';
import { showAppModal } from 'store/actions/layout';
import { MODAL_TYPE_INSUFFICIENT_PERMISSIONS } from 'components/modals';
import { INTEGRATION_CREATE, INTERACTABLE, VISIBLE } from '../team/constants';
import { ROLES_AND_PERMISSIONS_FLAG } from 'hooks/community/useDashboardFeatureFlags';
import useToasts from 'hooks/useToasts';
import { logger } from 'utils/logger';

const ERROR_CONFIG = {
    PERMISSION_ERROR: 'Insufficient permissions to enable integrations',
    OTHER_ERROR: 'Something went wrong..',
};

const SelectCommunity = (props) => {
    const dispatch = useDispatch();
    const urlParams = getUrlParams();
    const { myCommunity } = useSelector((state) => state.Community);
    const [selectedCommunityId, setCommunity] = useState(false);
    const [selectedRegionId, setSelectedRegion] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [communities, setCommunities] = useState([]);

    // TODO: Modify this once we get user role
    // in getMyCommunities api
    // const getRoleDescription = (role) => {
    //     return `You are ${role === 'owner' ? 'an admin' : 'an event manager'}`;
    // };

    const { errorToast } = useToasts();

    let list = useMemo(() => {
        return myCommunity.list || [];
    }, [myCommunity.list]);

    const isRolesAndPermissionsEnabledForSelectedId = (selectedId) => {
        const { list = [] } = myCommunity || {};
        const selectedCommunityFeatureData =
            list.find((community) => community.communityId === selectedId)
                ?.enabled_feature_data || {};
        return (
            selectedCommunityFeatureData?.[ROLES_AND_PERMISSIONS_FLAG] || false
        );
    };

    const selectCommunity = (id, region) => {
        setCommunity(id);
        setSelectedRegion(region);
    };

    const currentRegion = getCurrentRegion();
    const crmName = urlParams.crm && urlParams.crm.toUpperCase();

    const getCommunities = useCallback(async () => {
        setIsLoading(true);
        const communityIdsMap = new Map();
        let communities = [];
        let communityIdData = [];

        if (Array.isArray(list) && list.length > 0) {
            const results = await Promise.all(
                dataCenterRegions.map((dataCenterRegion) => {
                    const communitiesData = list
                        .filter(
                            (community) =>
                                community.region === dataCenterRegion.id
                        )
                        .map((element) => {
                            return {
                                community_id: element.communityId,
                                default_plan: element.subscription_type,
                            };
                        });
                    if (
                        communitiesData &&
                        Array.isArray(communitiesData) &&
                        communitiesData.length > 0
                    ) {
                        return dispatch(
                            getCRMCommunityBasedOnFeaturePlans(
                                crmName,
                                communitiesData,
                                dataCenterRegion.id
                            )
                        );
                    } else {
                        return [];
                    }
                })
            );
            if (results && Array.isArray(results) && results.length > 0) {
                results.filter((result) => {
                    if (result && result?.communityIds) {
                        communityIdData = communityIdData.concat(
                            result?.communityIds
                        );
                    }
                    return false;
                });
            }
            list.forEach((field) => {
                communityIdsMap.set(field.communityId, field);
            });
            for (let i = 0; i < communityIdData.length; i++) {
                communities.push(communityIdsMap.get(communityIdData[i]));
            }
        }
        setIsLoading(false);
        return communities;
    }, [list, crmName, dispatch]);

    useEffect(() => {
        const getUpdatedCommunity = async () => {
            const communityIdsData = await getCommunities();
            setCommunities(communityIdsData);
        };
        getUpdatedCommunity();
    }, [list, getCommunities]);

    const handleIntegrationRedirection = (baseUrl) => {
        if (urlParams.installid && crmName === ORACLE_ELOQUA) {
            window.location.replace(
                `${process.env.REACT_APP_ELOQUA_URL}&state=${selectedCommunityId}&redirect_uri=${process.env.REACT_APP_ELOQUA_REDIRECT_URL}?installid=${urlParams.installid}`
            );
            return;
        }
        if (crmName === SALESFORCE) {
            window.location.replace(
                `${baseUrl}/integrations?type=salesforce&communityId=${selectedCommunityId}`
            );
            return;
        }
        window.location.replace(
            `${baseUrl}/community-manager/${selectedCommunityId}/settings`
        );
    };

    const showInsufficientPermissionsModal = () => {
        dispatch(
            showAppModal(MODAL_TYPE_INSUFFICIENT_PERMISSIONS, {
                primaryText: 'You need permission',
                secondaryText:
                    'Please contact the admin to authorize you to configure the integrations.',
                visible: true,
                onClose: () => {
                    setCommunity(false);
                },
            })
        );
    };

    const communitySelectHandler = () => {
        setIsBtnLoading(true);
        const selectedRegion = dataCenterRegions.find(
            (data) => data.id === selectedRegionId
        );
        const baseUrl = selectedRegion?.baseUrl;

        if (selectedCommunityId) {
            if (isRolesAndPermissionsEnabledForSelectedId(selectedCommunityId))
                try {
                    dispatch(
                        fetchUserPermissions(
                            selectedCommunityId,
                            '',
                            selectedRegion?.id
                        )
                    )
                        .then((response) => {
                            const {
                                [VISIBLE]: canViewAddIntegration,
                                [INTERACTABLE]: canClickAddIntegration,
                            } =
                                response?.payload?.permissions?.[
                                    INTEGRATION_CREATE
                                ] || {};

                            if (
                                canViewAddIntegration &&
                                canClickAddIntegration
                            ) {
                                setIsBtnLoading(false);
                                handleIntegrationRedirection(baseUrl);
                            } else {
                                throw new Error(ERROR_CONFIG.PERMISSION_ERROR);
                            }
                        })
                        .catch((error) => {
                            logger.error(
                                error?.message || ERROR_CONFIG.OTHER_ERROR
                            );
                            if (
                                error?.message === ERROR_CONFIG.PERMISSION_ERROR
                            )
                                showInsufficientPermissionsModal();
                            else
                                errorToast({
                                    message: ERROR_CONFIG.OTHER_ERROR,
                                });

                            setIsBtnLoading(false);
                        });
                } catch (error) {
                    logger.error(error?.message || ERROR_CONFIG.OTHER_ERROR);
                    errorToast({
                        message: ERROR_CONFIG.OTHER_ERROR,
                    });
                    setIsBtnLoading(false);
                }
            else {
                setIsBtnLoading(false);
                handleIntegrationRedirection(baseUrl);
            }
        }
        setIsBtnLoading(false);
    };
    return (
        <PageWrapper>
            <OverrideGloablStyles />
            <Wrapper>
                <div className='imageCon'>
                    <img
                        src={cdnImage(
                            `/images/dashboard/events/laptop-button.svg`
                        )}
                        alt='laptop'
                    />
                </div>
                <div className='centerAllign'>
                    <p className='pageTitle'>Please select a community</p>
                    <p className='subTitle'>
                        {' '}
                        Select the community to continue
                    </p>
                </div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        {Array.isArray(communities) &&
                            communities.length > 0 &&
                            communities.map((item) => (
                                <div
                                    key={item?.communityId}
                                    className={`chipContainer ${
                                        selectedCommunityId ===
                                            item?.communityId && 'selctedItem'
                                    }`}
                                    onClick={() =>
                                        selectCommunity(
                                            item?.communityId,
                                            item?.region
                                        )
                                    }
                                >
                                    <div className='imageConatiner'>
                                        {item?.community_image_url !==
                                            'string' && (
                                            <img
                                                src={item?.community_image_url}
                                                alt={item?.name}
                                            />
                                        )}
                                    </div>
                                    <div
                                        className='contentConatiner'
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <p className='title'>{item?.name}</p>
                                        {/* 
                                        TODO: Add this back once we get user role
                                        in getMyCommunities api
                                        <p className='subTitle'>
                                            {getRoleDescription(item?.role)}
                                        </p> */}
                                    </div>
                                </div>
                            ))}
                        {Array.isArray(communities) && communities.length > 0 && (
                            <button
                                type='submit'
                                className='btn btn-primary buttonContainer'
                                onClick={communitySelectHandler}
                                disabled={!selectedCommunityId || isBtnLoading}
                            >
                                Continue
                            </button>
                        )}
                    </>
                )}
            </Wrapper>
        </PageWrapper>
    );
};
const PageWrapper = styled.div`
    position: relative;
    width: 84%;
    margin: 0 auto;
    @media (max-width: 480px) {
        width: 92%;
    }
`;

const Wrapper = styled.div`
    max-width: 400px;
    color: white;
    margin: 0 auto;
    padding: 100px 0 10px;
    width: 100%;
`;
const OverrideGloablStyles = createGlobalStyle`
    .imageCon img {
        margin-left: auto;
        margin-right: auto;
        margin-bottom:40px;
        display: block;
    }
    .chipContainer{
        display:flex;
        max-width: 400px;
        color: white;
        background : #26252D;
        margin: 20px auto;
        padding: 10px;
        width: 100%;
        cursor: pointer;
    }
    .selctedItem{
       border: 1px solid #9D9AAC;
    }
    .buttonContainer{
        margin : 0 auto;
        width: 100%;
    }
    .pageTitle{
        font-size:20px;
        line-height:24px;
        color:#F4F4F6;
        align-items: center;
        margin-bottom:5px;
    }
    .imageConatiner{
        border-radius: 50%;
        background-color: hsl(250,10%,25%);
        height:40px;
        width:40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right:20px;
    }
    .imageConatiner img{
        border-radius: 50%;
        height:40px;
        width:40px;
    }
    .title {
        color :#9D9AAC;
        font-size:16px;
        font-Weight:700;
    }
    .subTitle {
        color :#9D9AAC;
        font-size:16px;
    }
    .centerAllign{
        text-align: center;
        margin-bottom:10px;
    }
`;
export default SelectCommunity;
