import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import useTheme from 'hooks/useTheme';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { logger } from 'utils/logger';
import LoadingOverlay from './components/LoadingOverlay';
import PostEventBarChart from './components/PostEventBarChart';
import { Card, LegendDot, TextSelect } from './styledComponents';
import { POST_EVENT_TIME_FILTER_OPTIONS } from './utils';
import { getViewersDistribution } from './utils/api';

function ViewersDistributionCard({ airmeetId, sessionId, logEvent }) {
    const { theme } = useTheme();
    const [selectedOption, setSelectedOption] = useState(
        POST_EVENT_TIME_FILTER_OPTIONS[0]
    );
    const [isLoading, setIsLoading] = useState(true);
    const [apiData, setApiData] = useState({});

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await getViewersDistribution({
                airmeetId,
                sessionId,
            });
            if (!isEmpty(response?.data)) {
                setApiData(response?.data);
            }
        } catch (e) {
            logger.error('PostEventTab.fetchData: ', e);
        } finally {
            setIsLoading(false);
        }
    }, [airmeetId, sessionId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const { datasets, chartLabels } = useMemo(() => {
        const startDate = moment().startOf(selectedOption?.value);
        const endDate = moment();

        const datasets = [
            {
                id: '__new_attendees',
                label: 'New attendees',
                backgroundColor: theme.colors.module.viz.carrot['600'],
                data: [],
            },
            {
                id: '__no_show',
                label: 'No show',
                backgroundColor: theme.colors.module.viz.sky['600'],
                data: [],
            },
            {
                id: '__live_attendee',
                label: 'Attended live',
                backgroundColor: theme.colors.module.viz.orchid['800'],
                data: [],
            },
        ];
        const chartLabels = [];
        Object.keys(apiData).forEach((key) => {
            if (
                key &&
                moment(key).isSameOrAfter(startDate) &&
                moment(key).isSameOrBefore(endDate)
            ) {
                const obj = apiData[key];
                if (!isEmpty(obj)) {
                    datasets.forEach((item, index) => {
                        datasets[index].data.push(get(obj, item?.id, 0));
                    });
                }
                chartLabels.push(key.replace(' 00:00:00', ''));
            }
        });

        return { datasets, chartLabels };
    }, [apiData, selectedOption, theme]);

    const handleFilterChange = (option) => {
        logEvent &&
            logEvent('viewersDistFilterChange', {
                prevOption: selectedOption,
                option,
            });
        setSelectedOption(option);
    };

    return (
        <Card flex={['1', '50%', '70%']}>
            <FlexDiv alignItems='center' justifyContent='space-between' pb='x6'>
                <Text
                    variant='body2'
                    color='text.default.primary'
                    flex='1'
                    pr='x4'
                >
                    On-demand viewers distribution
                </Text>
                <FlexDiv alignItems='center'>
                    <Text variant='caption' color='text.default.secondary'>
                        Filter by
                    </Text>
                    <TextSelect
                        options={POST_EVENT_TIME_FILTER_OPTIONS}
                        hideIndicatorSeparator={true}
                        value={selectedOption}
                        onChange={handleFilterChange}
                        hasBorder={false}
                        flex='1'
                        isSearchable={false}
                    />
                </FlexDiv>
            </FlexDiv>
            <PostEventBarChart
                chartLabels={chartLabels}
                datasets={datasets}
                height='12rem'
            />
            <FlexDiv alignItems='center' justifyContent='center' pt='x6'>
                {datasets.map((item) => (
                    <FlexDiv key={item?.label} alignItems='center' pr='x7'>
                        <LegendDot bg={item?.backgroundColor} />
                        <Text variant='caption' pl='x1'>
                            {item?.label}
                        </Text>
                    </FlexDiv>
                ))}
            </FlexDiv>
            {isLoading && <LoadingOverlay />}
        </Card>
    );
}

export default ViewersDistributionCard;
