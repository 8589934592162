import { logger } from 'utils/logger';

const MAX_RETRIES = 2;

interface AuthProxyConfig {
    auth1: string;
    auth2: string;
    auth3: string;
}
interface DatabaseProxyConfig {
    webSocketUrl: string;
}
interface FirebaseProxyConfig {
    auth: AuthProxyConfig;
    database: DatabaseProxyConfig;
    defaultUseProxy: boolean;
}

const DEFAULT_FIREBASE_PROXY_CONFIG: FirebaseProxyConfig = {
    auth: {
        auth1: `https://${process.env.REACT_APP_FIREBASE_PROXY_URL}/api1/`,
        auth2: `https://${process.env.REACT_APP_FIREBASE_PROXY_URL}/api2/`,
        auth3: `https://${process.env.REACT_APP_FIREBASE_PROXY_URL}/api3/`,
    },
    database: {
        webSocketUrl: `${process.env.REACT_APP_FIREBASE_PROXY_URL}`,
    },
    defaultUseProxy: false,
};
export default class FirebaseProxyHelper {
    private isEnabledFirebaseAuthProxy: boolean;
    private isEnabledFirebaseWSProxy: boolean;
    private proxyConfig: any;
    private failureCount: number;

    private shouldEnableFirebaseAuthProxy: boolean;
    private shouldEnableFirebaseWSProxy: boolean;

    private static _firebaseProxyHelpers = {};
    private static _isGlobalAuthProxyEnabled = false;
    private static _isGlobalWSProxyEnabled = false;
    private static _proxyConfig: FirebaseProxyConfig = DEFAULT_FIREBASE_PROXY_CONFIG;

    private auth: any;
    private database: any;

    constructor(proxyConfig: FirebaseProxyConfig) {
        if (proxyConfig) {
            // update our global config
            FirebaseProxyHelper._proxyConfig = proxyConfig;
        } else {
            // use default global conifg if not provided
            proxyConfig = FirebaseProxyHelper._proxyConfig;
        }
        this.shouldEnableFirebaseAuthProxy =
            FirebaseProxyHelper._isGlobalAuthProxyEnabled ||
            proxyConfig?.defaultUseProxy ||
            false;
        this.shouldEnableFirebaseWSProxy =
            FirebaseProxyHelper._isGlobalWSProxyEnabled ||
            proxyConfig?.defaultUseProxy ||
            false;
        this.proxyConfig = proxyConfig;
        this.failureCount = 0;

        this.isEnabledFirebaseAuthProxy = false;
        this.isEnabledFirebaseAuthProxy = false;

        if (proxyConfig?.defaultUseProxy) {
            logger.info('Enable proxy by default on FirebaseProxyHelper init');
        }
    }

    static getFirebaseProxyHelperInstance = (
        id: string,
        proxyConfig?: FirebaseProxyConfig
    ) => {
        if (!FirebaseProxyHelper._firebaseProxyHelpers[id]) {
            const helper = new FirebaseProxyHelper(proxyConfig);

            // some other proxy helper being instantiated and we have already setup main proxy helper
            if (FirebaseProxyHelper._isGlobalAuthProxyEnabled) {
                helper.enableFirebaseAuthProxy();
            }

            if (FirebaseProxyHelper._isGlobalWSProxyEnabled) {
                helper.enableFirebaseAuthProxy();
            }

            FirebaseProxyHelper._firebaseProxyHelpers[id] = helper;
        }
        return FirebaseProxyHelper._firebaseProxyHelpers[id];
    };

    // handle onCreateDatabase disconnect callback
    onCreateDatabase(database) {
        if (!database) {
            throw new Error(
                'Error adding disconnect callback, database not created'
            );
        }

        this.database = database;

        // set connection type to webSocket only
        database.repo_.repoInfo_.webSocketOnly = true;

        if (this.shouldEnableFirebaseWSProxy) {
            this.enableFirebaseWSProxy(database);
        }

        const connectionURL = database.repo_.repoInfo_.connectionURL;

        database.repo_.repoInfo_.connectionURL = (type, params) => {
            const originalUrl = connectionURL.call(
                database.repo_.repoInfo_,
                type,
                params
            );

            // database connected status
            const isConnected = database.repo_.persistentConnection_.connected_;

            // check to make sure proxy retry is not happening in case of disconnection in live event
            const hasNeverConnected =
                database.repo_.persistentConnection_.firstConnection_;

            if (!isConnected && hasNeverConnected) {
                logger.info('Database connection retry', {
                    failureCount: this.failureCount,
                });
                if (this.failureCount === MAX_RETRIES) {
                    logger.info(
                        'Failed to connect on url, switching to database proxy',
                        originalUrl
                    );
                    this.enableFirebaseWSProxy(database);
                }
                this.failureCount++;
            }
            return originalUrl;
        };
    }

    enableFirebaseWSProxy(database?) {
        database = database || this.database;
        this.database = database;

        if (this.isEnabledFirebaseWSProxy) return;

        if (!this.proxyConfig?.database?.webSocketUrl) {
            logger.info('Incorrect proxy server config', {
                proxyConfig: this.proxyConfig,
            });
            return;
        }

        if (!this.shouldEnableFirebaseWSProxy) {
            this.shouldEnableFirebaseWSProxy = true;
            FirebaseProxyHelper._enableGlobalWSProxy();
        }

        if (!database) {
            logger.info(
                'Database not initialized, will enable proxy when init is called'
            );
            return;
        }

        this.isEnabledFirebaseWSProxy = true;

        logger.info('Enabling firebase database proxy', {
            proxyConfig: this.proxyConfig,
        });

        database.repo_.repoInfo_.webSocketOnly = true;
        const proxySocketUrl = this.proxyConfig?.database?.webSocketUrl;

        const connectionURL = database.repo_.repoInfo_.connectionURL;

        database.repo_.repoInfo_.connectionURL = (type, params) => {
            const originalUrl = connectionURL.call(
                database.repo_.repoInfo_,
                type,
                params
            );

            let proxyUrl = originalUrl;
            if (proxySocketUrl) {
                proxyUrl = `wss://${proxySocketUrl}/websocket?url=${originalUrl}`;
            }
            if (type === 'websocket') {
                logger.info('Opening websocket URL with proxy', {
                    originalUrl,
                    proxyUrl,
                });
                return proxyUrl;
            } else {
                return originalUrl;
            }
        };
    }

    onCreateAuth(authService) {
        this.auth = authService;
        if (authService && this.shouldEnableFirebaseAuthProxy) {
            this.enableFirebaseAuthProxy();
        }
    }

    enableFirebaseAuthProxy(authService?) {
        authService = authService || this.auth;
        this.auth = authService;

        if (this.isEnabledFirebaseAuthProxy) return false;

        if (!this.proxyConfig?.auth?.auth1) {
            logger.info('Incorrect proxy server config', {
                proxyConfig: this.proxyConfig,
            });
            return;
        }

        if (!this.shouldEnableFirebaseAuthProxy) {
            this.shouldEnableFirebaseAuthProxy = true;
            FirebaseProxyHelper._enableGlobalAuthProxy();
        }

        if (!authService) {
            logger.info(
                'Auth not initialized, will enable proxy when init is called'
            );
            return;
        }
        this.isEnabledFirebaseAuthProxy = true;

        logger.info('Enabling firebase auth proxy', {
            proxyConfig: this.proxyConfig,
        });

        const oldW = authService.b.__proto__.w;
        authService.b.__proto__.w = (a, b, c, d, e, f) => {
            const url = new URL(a);
            if (url.pathname.includes('verifyCustomToken')) {
                a = `${this.proxyConfig?.auth?.auth1}verifyCustomToken${url.search}`;
            } else if (url.pathname.includes('getAccountInfo')) {
                a = `${this.proxyConfig?.auth?.auth1}getAccountInfo${url.search}`;
            }
            oldW.call(authService.b, a, b, c, d, e, f);
        };
    }

    getRetryCount() {
        return this.failureCount;
    }

    getWSProxyStatus() {
        return this.isEnabledFirebaseWSProxy;
    }

    getAuthProxyStatus() {
        return this.isEnabledFirebaseAuthProxy;
    }

    private static _enableGlobalWSProxy() {
        FirebaseProxyHelper._isGlobalWSProxyEnabled = true;

        Object.values(FirebaseProxyHelper._firebaseProxyHelpers).forEach(
            (helper: FirebaseProxyHelper) => {
                if (!helper.shouldEnableFirebaseWSProxy) {
                    helper.enableFirebaseWSProxy();
                }
            }
        );
    }

    private static _enableGlobalAuthProxy() {
        FirebaseProxyHelper._isGlobalAuthProxyEnabled = true;

        Object.values(FirebaseProxyHelper._firebaseProxyHelpers).forEach(
            (helper: FirebaseProxyHelper) => {
                if (!helper.shouldEnableFirebaseAuthProxy) {
                    helper.enableFirebaseAuthProxy();
                }
            }
        );
    }
}
