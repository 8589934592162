import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getBackdropOffsets } from 'utils/constants/live-airmeet';
import { SESSION_TYPES } from 'utils/constants/sessions';
import PermissionUtils from 'utils/permission-utils';
import { logger } from 'utils/logger';

function useLiveAirmeetBackdrop(props = {}) {
    const { firebaseClient, currentSession, airmeetId } = props;
    const [backdropData, setBackdropData] = useState(null);
    const [sessionLevelBackdrop, setSessionLevelBackdrop] = useState(null);
    const [eventLevelBackdrop, setEventLevelBackdrop] = useState(null);
    const isStreamingSession = currentSession?.type === SESSION_TYPES.STREAMING;
    const appliedBackdrop = useSelector(
        (store) => store.liveAirmeet.genral.backdrop
    );
    const isSystemDefaultSelected = appliedBackdrop === 'system_default';
    const hasBackdropApplied = appliedBackdrop !== undefined;

    const backdrop = useMemo(() => {
        if (isSystemDefaultSelected) {
            return eventLevelBackdrop;
        }
        if (hasBackdropApplied || isStreamingSession) {
            return sessionLevelBackdrop;
        }
        return eventLevelBackdrop || '';
    }, [
        isSystemDefaultSelected,
        hasBackdropApplied,
        isStreamingSession,
        eventLevelBackdrop,
        sessionLevelBackdrop,
    ]);

    // get the session level backdrop data
    const sessionLevelBackdropID = appliedBackdrop
        ? Object.keys(appliedBackdrop)[0]
        : null;
    useEffect(() => {
        if (!sessionLevelBackdropID || !currentSession) {
            setSessionLevelBackdrop(null);
            return;
        }

        const path = `${airmeetId}/meta-data/backdrop/${sessionLevelBackdropID}`;
        const getBackdropOffset = ({ value }) => {
            setSessionLevelBackdrop(value);
        };

        firebaseClient.getDataSync(path, getBackdropOffset);

        return () => {
            firebaseClient.clearDataSync(path, getBackdropOffset);
        };
    }, [sessionLevelBackdropID]);

    // get the system default backdrop from the common firebase data
    useEffect(() => {
        if (
            !currentSession ||
            (hasBackdropApplied && !isSystemDefaultSelected)
        ) {
            return;
        }

        const path = `${airmeetId}/meta-data/backdrop/default`;
        const getBackdropOffset = ({ value }) => {
            setEventLevelBackdrop(value);
        };

        firebaseClient.getDataSync(path, getBackdropOffset);

        return () => {
            firebaseClient.clearDataSync(path, getBackdropOffset);
        };
    }, [hasBackdropApplied, isSystemDefaultSelected]);

    // apply the backdrop only when the image is loaded completely
    useEffect(
        function backdropLoaded() {
            if (!backdrop?.image) {
                setBackdropData(null);
                return;
            }

            const image = new Image();
            image.onload = () => {
                let backdropData = backdrop;
                if (!backdrop.offsetTop) {
                    const layoutData = getBackdropOffsets();
                    backdropData = { ...layoutData, ...backdropData };
                }
                setBackdropData(backdropData);
                if (PermissionUtils.isSessionScreenRecordingUser()) {
                    logger.info('Rendering the stage backdrop', backdropData);
                }
            };
            image.src = backdrop?.image;
        },
        [backdrop]
    );
    return backdropData;
}

export default useLiveAirmeetBackdrop;
