import { useEffect, useMemo, useState } from 'react';
import { isNumber } from 'utils/core';
import { ALL_PLATFORM_CONFIG } from 'hooks/useFeatureControl';
import {
    getImageGenUserDataPath,
    getMaxImageGenAttempts,
} from 'hooks/airGenie/constants';

const useAirGenieUserAttempts = ({
    firebaseClient,
    airmeetId,
    featuresConfig,
    platformConfig,
    module,
    userId,
}) => {
    if (!firebaseClient || !module || !airmeetId || !userId) {
        throw new Error(
            'firebaseClient, module, airmeetId and userId props are required and can not be null'
        );
    }

    const [imageGenAttempts, setImageGenAttempts] = useState(0);

    const platformLevelLimit = useMemo(() => {
        return (
            platformConfig &&
            platformConfig[ALL_PLATFORM_CONFIG.MAX_AIR_GENIE_IMAGE_GEN_ATTEMPTS]
        );
    }, [platformConfig]);

    const eventLevelLimit = useMemo(() => {
        return (
            featuresConfig &&
            featuresConfig[ALL_PLATFORM_CONFIG.MAX_AIR_GENIE_IMAGE_GEN_ATTEMPTS]
        );
    }, [featuresConfig]);

    const maxImageGenAttempts = useMemo(
        () => getMaxImageGenAttempts(eventLevelLimit || platformLevelLimit),
        [eventLevelLimit, platformLevelLimit]
    );

    useEffect(() => {
        if (!firebaseClient || !module || !airmeetId || !userId) {
            return;
        }

        const updateData = ({ value }) => {
            if (!!value && isNumber(value)) setImageGenAttempts(value);
        };

        const path = getImageGenUserDataPath({ airmeetId, module, userId });
        firebaseClient.getDataSync(path, updateData);

        return () => {
            firebaseClient.clearDataSync(path, updateData);
        };
    }, [firebaseClient, airmeetId, module, userId]);

    return {
        imageGenAttempts,
        maxImageGenAttempts,
    };
};

export default useAirGenieUserAttempts;
