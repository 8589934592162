import EventEmitter from 'events';
import { logger } from '../utils/logger';

const USER_STORAGE_NODE_PATH = 'user-storage';

/**
 * Private + Public User-scoped Storage on the Cloud
 * This allows storing settings that should sync with the user account, rather than device
 *
 * This is only a contract for data access/manipulation
 * Implementations can rely on the `storage-update` event to keep local state up to date on the entire user-storeage data
 * eg. The React client can be accessed using the hook: `useUserStorage` (Provided through `useLiveAirmeetContext(c => c.user.storage)`)
 */
export default class FirebaseStorageClient extends EventEmitter {
    constructor(client, baseEvent, accountUid, baseKey = '') {
        super();

        // Base firebase client
        this.client = client;
        this.disableLogs = true;
        this.accountUid = accountUid;

        // Base paths
        this.prefixKey = baseKey
            ? `${USER_STORAGE_NODE_PATH}/${baseKey}`
            : USER_STORAGE_NODE_PATH;
        this.overridesPrefixKey = `/eventOverrides/${baseEvent}`;
        this.channelName = this.getPersistantRoom(this.accountUid);

        // Setup sync for user-storage data
        this.joinStorageRoom();
    }

    getPersistantRoom(uid) {
        return `${this.prefixKey}/${uid}`;
    }

    getExternalPersistance(userId, eventOnly) {
        return `${this.prefixKey}/${userId}/${
            eventOnly ? `${this.overridesPrefixKey}/` : ''
        }external`;
    }

    joinStorageRoom() {
        this.bindPersistantEvent();
    }

    bindPersistantEvent() {
        this.client.getDataSync(this.channelName, ({ value }) => {
            this.emit('storage-update', value || {});
        });
    }

    /**
     * Updates a path in the private storage room
     * @param {string} key The Firebase path to update
     * @param {*} value
     * @param {boolean} eventOnly If true, will be added as an eventOverride
     */
    updateStorageKey(key, value, eventOnly) {
        this.client.setData(
            `${this.channelName}/${
                eventOnly ? `${this.overridesPrefixKey}/` : ''
            }${key}`,
            value
        );
    }

    /**
     * Updates a path in the public storage room
     * @param {string} userId target `user.id` to push the update
     * @param {string} key  Key to update
     * @param {function} updateValue Function which receives the value at the requested key, and should return an updated object if required
     * @param {boolean} eventOnly If true, will be added as an eventOverride
     */
    externalStorageKey(userId, key, updateValue, eventOnly) {
        this.client.runTransaction(
            `${this.getExternalPersistance(userId, eventOnly)}/${key}`,
            (value) => {
                value = value || {};
                return updateValue(value, this.accountUid);
            },
            null,
            true
        );
    }

    logger(...args) {
        if (this.disableLogs) {
            return;
        }
        logger.info(...args);
    }
}
