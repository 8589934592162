import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { useStageConfig } from 'context/StageConfigProvider';
import { getStageUsersLocation } from 'store/selectors';
import { emptyArray } from 'utils/constants/common';

const useUsersLocationData = () => {
    const {
        airmeet: {
            data: { currentSession },
        },
    } = useLiveAirmeetContext();
    const {
        allowToPublishOnPersistentBackstage: allowToPublishOnBackstage,
        allowToPublishOnPersistentStage: allowToPublishOnStage,
        canAccessPersistentBar,
    } = useStageConfig();

    const stageUsersLocation = useSelector(getStageUsersLocation);

    const { cohost_ids = [], host_id = [], speaker_id = [] } =
        currentSession || {};

    const firebaseUsers = Object.keys(stageUsersLocation);
    const users = useMemo(
        () => [
            ...new Set([
                ...cohost_ids,
                ...host_id,
                ...firebaseUsers,
                ...speaker_id,
            ]),
        ],
        [cohost_ids, host_id, speaker_id, firebaseUsers]
    );

    const _liveUsersVisibility = useMemo(() => {
        const userData = Object.values(stageUsersLocation)
            .filter((i) => i?.visibility?.visible)
            .map((i) => i.userId);
        return userData;
    }, [stageUsersLocation]);

    const liveStageUsers = useMemo(() => {
        return users.filter((userId) => _liveUsersVisibility.includes(userId));
    }, [users, _liveUsersVisibility]);

    const backStageUsers = useMemo(() => {
        if (!canAccessPersistentBar) {
            return emptyArray;
        }
        return users.filter((userId) => !_liveUsersVisibility.includes(userId));
    }, [users, _liveUsersVisibility, canAccessPersistentBar]);

    return {
        liveStageUsers,
        backStageUsers,
        allowToPublishOnStage,
        allowToPublishOnBackstage,
        liveUsersCount: liveStageUsers.length,
        backStageUsersCount: backStageUsers.length,
    };
};

export default useUsersLocationData;
