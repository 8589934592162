import SettingsTab from 'components/lounge/rhsV2/settingsTab/SettingsTab';
import { LazyLoaded } from 'hooks/common';
import constant from 'lodash/constant';
import React from 'react';
import LOAD_LIMIT from 'utils/constants/featureWiseLoadLimit';
import { SESSION_TYPES } from 'utils/constants/sessions';

const EventTab = LazyLoaded(
    React.lazy(() => import('components/lounge/rhsV2/eventTab/EventTab'))
);
const MessagesTab = LazyLoaded(
    React.lazy(() => import('components/lounge/rhsV2/messagesTab/MessagesTab'))
);
const NotificationsTab = LazyLoaded(
    React.lazy(() =>
        import('components/lounge/rhsV2/notificationsTab/NotificationsTab')
    )
);
const PeopleTab = LazyLoaded(
    React.lazy(() => import('components/lounge/rhsV2/peopleTab/PeopleTab'))
);

const ManageAbuseTab = LazyLoaded(
    React.lazy(() =>
        import('components/lounge/rhsV2/manageAbuseTab/ManageAbuseTab')
    )
);
export const SESSION_TAB = 'SESSION_TAB';
export const EVENT_TAB = 'EVENT_TAB';
export const PEOPLE_TAB = 'PEOPLE_TAB';
export const MANAGE_ABUSE_TAB = 'MANAGE_ABUSE_TAB';
export const NOTIFICATIONS_TAB = 'NOTIFICATIONS_TAB';
export const PROFILE_TAB = 'PROFILE_TAB';
export const SETTINGS_TAB = 'SETTINGS_TAB';
export const MESSAGE_TAB = 'MESSAGE_TAB';
export const ENGAGE_TAB = 'ENGAGE_TAB';

export const EVENT_TAB_LABEL = 'Feed';
export const POLLS_TAB_LABEL = 'Polls';
export const PEOPLE_TAB_LABEL = 'People';
export const ALERTS_TAB_LABEL = 'Alerts';
export const MODERATION_TAB_LABEL = 'Moderation';
export const SETTINGS_TAB_LABEL = 'Settings';
export const MESSAGES_TAB_LABEL = 'Messages';
export const ENGAGE_TAB_LABEL = 'Engage';
export const PROFILE_TAB_LABEL = 'Profile';

export const EVENT_FEED_INNER_TAB_LABEL = 'Feed';
export const EVENT_POLLS_INNER_TAB_LABEL = 'Polls';

export const SESSION_FEED_INNER_TAB_LABEL = 'Chat';
export const SESSION_POLLS_INNER_TAB_LABEL = 'Polls';
export const SESSION_QUESTIONS_INNER_TAB_LABEL = 'Q&A';
export const SESSION_RAISED_HAND_INNER_TAB_LABEL = 'Raised Hands';
export const SESSION_VIDEOS_INNER_TAB_LABEL = 'Videos';
export const SESSION_MANAGE_INNER_TAB_LABEL = 'Manage';
export const SESSION_PEOPLE_INNER_TAB_LABEL = 'People in session';
export const SESSION_ACTIVITIES_INNER_TAB_LABEL = 'Activities';
export const SESSION_BREAKOUT_INNER_TAB_LABEL = 'Breakout Rooms';
export const SESSION_RESOURCES_INNER_TAB_LABEL = 'Resources';
export const SESSION_CTA_INNER_TAB_LABEL = 'Call To Action';
export const SESSION_BRANDING_INNER_TAB_LABEL = 'Branding';
export const LOWER_THIRD_BANNER_INNER_TAB_LABEL = 'Banners';
export const SESSION_INTEGRATIONS_INNER_TAB_LABEL = 'Integrations';

export const EVENT_TABS = [
    EVENT_FEED_INNER_TAB_LABEL,
    EVENT_POLLS_INNER_TAB_LABEL,
];

export const SESSION_TAB_ICONS = {
    [SESSION_MANAGE_INNER_TAB_LABEL]: 'icon-settings-gear-outline',
    [SESSION_FEED_INNER_TAB_LABEL]: 'icon-chat-outline',
    [SESSION_POLLS_INNER_TAB_LABEL]: 'icon-poll-on-stage-outline',
    [SESSION_PEOPLE_INNER_TAB_LABEL]: 'people_community_outline',
    [SESSION_QUESTIONS_INNER_TAB_LABEL]: 'icon-qna-outline',
    [SESSION_BREAKOUT_INNER_TAB_LABEL]: 'breakout-outline2',
    [SESSION_RESOURCES_INNER_TAB_LABEL]: 'file-group',
    [SESSION_INTEGRATIONS_INNER_TAB_LABEL]: 'icon-plugged-in',
    [SESSION_CTA_INNER_TAB_LABEL]: 'icon-gift-outline',
    [SESSION_INTEGRATIONS_INNER_TAB_LABEL]: 'icon-plugged-in-outline',
};

export const SESSION_TAB_ACTIVE_ICONS = {
    [SESSION_MANAGE_INNER_TAB_LABEL]: 'icon-settings-gear',
    [SESSION_FEED_INNER_TAB_LABEL]: 'icon-chat',
    [SESSION_POLLS_INNER_TAB_LABEL]: 'icon-poll-on-stage',
    [SESSION_PEOPLE_INNER_TAB_LABEL]: 'people_community',
    [SESSION_QUESTIONS_INNER_TAB_LABEL]: 'icon-qna',
    [SESSION_BREAKOUT_INNER_TAB_LABEL]: 'breakout-outline2',
    [SESSION_RESOURCES_INNER_TAB_LABEL]: 'file-group',
    [SESSION_INTEGRATIONS_INNER_TAB_LABEL]: 'icon-plugged-in',
    [SESSION_CTA_INNER_TAB_LABEL]: 'icon-gift-outline',
};

export const HOST_SESSION_TABS = [
    SESSION_FEED_INNER_TAB_LABEL,
    SESSION_QUESTIONS_INNER_TAB_LABEL,
    SESSION_POLLS_INNER_TAB_LABEL,
    SESSION_RAISED_HAND_INNER_TAB_LABEL,
    SESSION_VIDEOS_INNER_TAB_LABEL,
    SESSION_INTEGRATIONS_INNER_TAB_LABEL,
];
export const HOST_SESSION_TABS_V2 = [
    SESSION_MANAGE_INNER_TAB_LABEL,
    SESSION_FEED_INNER_TAB_LABEL,
    SESSION_QUESTIONS_INNER_TAB_LABEL,
    SESSION_PEOPLE_INNER_TAB_LABEL,
    SESSION_POLLS_INNER_TAB_LABEL,
    SESSION_BREAKOUT_INNER_TAB_LABEL,
    SESSION_RESOURCES_INNER_TAB_LABEL,
    SESSION_INTEGRATIONS_INNER_TAB_LABEL,
];

export const EM_SESSION_TABS = [
    SESSION_FEED_INNER_TAB_LABEL,
    SESSION_QUESTIONS_INNER_TAB_LABEL,
    SESSION_POLLS_INNER_TAB_LABEL,
    SESSION_RAISED_HAND_INNER_TAB_LABEL,
    SESSION_INTEGRATIONS_INNER_TAB_LABEL,
];
export const EM_SESSION_TABS_V2 = [
    SESSION_MANAGE_INNER_TAB_LABEL,
    SESSION_FEED_INNER_TAB_LABEL,
    SESSION_QUESTIONS_INNER_TAB_LABEL,
    SESSION_PEOPLE_INNER_TAB_LABEL,
    SESSION_POLLS_INNER_TAB_LABEL,
    SESSION_BREAKOUT_INNER_TAB_LABEL,
    SESSION_RESOURCES_INNER_TAB_LABEL,
    SESSION_INTEGRATIONS_INNER_TAB_LABEL,
];

export const SPEAKER_SESSION_TABS = [
    SESSION_FEED_INNER_TAB_LABEL,
    SESSION_QUESTIONS_INNER_TAB_LABEL,
    SESSION_POLLS_INNER_TAB_LABEL,
    SESSION_RAISED_HAND_INNER_TAB_LABEL,
    SESSION_RESOURCES_INNER_TAB_LABEL,
];
export const SPEAKER_SESSION_TABS_V2 = [
    SESSION_MANAGE_INNER_TAB_LABEL,
    SESSION_FEED_INNER_TAB_LABEL,
    SESSION_QUESTIONS_INNER_TAB_LABEL,
    SESSION_PEOPLE_INNER_TAB_LABEL,
    SESSION_POLLS_INNER_TAB_LABEL,
    SESSION_RESOURCES_INNER_TAB_LABEL,
];

export const ATTENDEE_SESSION_TABS = [
    SESSION_FEED_INNER_TAB_LABEL,
    SESSION_QUESTIONS_INNER_TAB_LABEL,
    SESSION_POLLS_INNER_TAB_LABEL,
    SESSION_RESOURCES_INNER_TAB_LABEL,
];

export const ATTENDEE_SESSION_TABS_V2 = [
    SESSION_FEED_INNER_TAB_LABEL,
    SESSION_QUESTIONS_INNER_TAB_LABEL,
    SESSION_PEOPLE_INNER_TAB_LABEL,
    SESSION_POLLS_INNER_TAB_LABEL,
    SESSION_RESOURCES_INNER_TAB_LABEL,
];

export const MANAGE_SESSION_TAB = [
    SESSION_RAISED_HAND_INNER_TAB_LABEL,
    SESSION_CTA_INNER_TAB_LABEL,
    LOWER_THIRD_BANNER_INNER_TAB_LABEL,
    SESSION_VIDEOS_INNER_TAB_LABEL,
    SESSION_BRANDING_INNER_TAB_LABEL,
];

export const BLOCKED_USER_TABS = [
    SESSION_TAB,
    EVENT_TAB,
    PEOPLE_TAB,
    MESSAGE_TAB,
    NOTIFICATIONS_TAB,
];

export const TAB_COMPONENTS = {
    [MANAGE_ABUSE_TAB]: ManageAbuseTab,
    [EVENT_TAB]: EventTab,
    [PEOPLE_TAB]: PeopleTab,
    [NOTIFICATIONS_TAB]: NotificationsTab,
    [PROFILE_TAB]: '',
    [SETTINGS_TAB]: SettingsTab,
    [MESSAGE_TAB]: MessagesTab,
};

export const TAB_KEYS = {
    [MANAGE_ABUSE_TAB]: constant(MANAGE_ABUSE_TAB),
    [EVENT_TAB]: constant(EVENT_TAB),
    [PEOPLE_TAB]: constant(PEOPLE_TAB),
    [NOTIFICATIONS_TAB]: constant(NOTIFICATIONS_TAB),
    [PROFILE_TAB]: constant(PROFILE_TAB),
    [SETTINGS_TAB]: constant(NOTIFICATIONS_TAB),
    [MESSAGE_TAB]: constant(MESSAGE_TAB),
};

export const STREAMING_SESSION_EXCLUDED_TABS = [
    SESSION_RAISED_HAND_INNER_TAB_LABEL,
    SESSION_VIDEOS_INNER_TAB_LABEL,
    SESSION_BREAKOUT_INNER_TAB_LABEL,
];

export const GROUP_MEETING_SESSION_EXCLUDED_TABS = [
    SESSION_RAISED_HAND_INNER_TAB_LABEL,
    SESSION_VIDEOS_INNER_TAB_LABEL,
    SESSION_RESOURCES_INNER_TAB_LABEL,
    SESSION_BRANDING_INNER_TAB_LABEL,
];

const SPEED_NETWORKING_EXCLUDED_TABS = [
    SESSION_RAISED_HAND_INNER_TAB_LABEL,
    SESSION_VIDEOS_INNER_TAB_LABEL,
    SESSION_RESOURCES_INNER_TAB_LABEL,
    SESSION_BRANDING_INNER_TAB_LABEL,
    SESSION_MANAGE_INNER_TAB_LABEL,
    SESSION_BREAKOUT_INNER_TAB_LABEL,
    SESSION_INTEGRATIONS_INNER_TAB_LABEL,
    SESSION_QUESTIONS_INNER_TAB_LABEL,
];

export const SPEED_NETWORKING_EXCLUDED_TABS_CONFERENCE = [
    ...SPEED_NETWORKING_EXCLUDED_TABS,
    SESSION_POLLS_INNER_TAB_LABEL,
    SESSION_FEED_INNER_TAB_LABEL,
];

export const SPEED_NETWORKING_EXCLUDED_TABS_MEETUP = [
    ...SPEED_NETWORKING_EXCLUDED_TABS,
    SESSION_PEOPLE_INNER_TAB_LABEL,
];

export const FLUID_LOUNGE_EXCLUDED_TABS = [
    SESSION_RAISED_HAND_INNER_TAB_LABEL,
    SESSION_VIDEOS_INNER_TAB_LABEL,
    SESSION_RESOURCES_INNER_TAB_LABEL,
    SESSION_BRANDING_INNER_TAB_LABEL,
    SESSION_MANAGE_INNER_TAB_LABEL,
    SESSION_BREAKOUT_INNER_TAB_LABEL,
    SESSION_INTEGRATIONS_INNER_TAB_LABEL,
    SESSION_QUESTIONS_INNER_TAB_LABEL,
    SESSION_PEOPLE_INNER_TAB_LABEL,
];

export const PRE_RECORDED_SESSION_EXCLUDED_TABS = [
    SESSION_RAISED_HAND_INNER_TAB_LABEL,
    SESSION_VIDEOS_INNER_TAB_LABEL,
    SESSION_BREAKOUT_INNER_TAB_LABEL,
];

export const LOUNGE_EXCLUDED_TABS = [
    SESSION_MANAGE_INNER_TAB_LABEL,
    SESSION_BREAKOUT_INNER_TAB_LABEL,
    SESSION_RESOURCES_INNER_TAB_LABEL,
];
export const FINISHED_AIRMEET_EXCLUDED_TABS = [
    SESSION_MANAGE_INNER_TAB_LABEL,
    SESSION_PEOPLE_INNER_TAB_LABEL,
    SESSION_BREAKOUT_INNER_TAB_LABEL,
    SESSION_RESOURCES_INNER_TAB_LABEL,
];

export const SHOW_ON_STAGE_EXCLUDED_SESSION = [
    SESSION_TYPES.STREAMING,
    SESSION_TYPES.GROUP_MEETING,
];

export const SHOW_CHAT_ON_STAGE_EXCLUDED_SESSION = [
    SESSION_TYPES.STREAMING,
    SESSION_TYPES.PRE_RECORDED,
    SESSION_TYPES.GROUP_MEETING,
];

export const CREATED_AIRMEET_EXCLUDED_TABS = [];

export const PAUSED_AIRMEET_EXCLUDED_TABS = [];

export const FINISHED_SESSION_EXCLUDED_TABS = [
    SESSION_BREAKOUT_INNER_TAB_LABEL,
    SESSION_PEOPLE_INNER_TAB_LABEL,
];

export const LITE_MODE_EXCLUDED_TABS = [SESSION_BREAKOUT_INNER_TAB_LABEL];

export const LOADING_MESSAGE = {
    chatKey: '',
    chatMessage: 'loading...',
    createdAt: 0,
    replyTo: '',
    user: 'loading...',
    sessionId: '',
    room: 'General',
};

export const NO_MESSAGE = {
    chatKey: '',
    chatMessage: '',
    createdAt: 0,
    replyTo: '',
    user: '',
    sessionId: '',
    room: 'General',
};

// Different item types
export const EVENT_FEED = 'EVENT_FEED';
export const EVENT_MESSAGE = 'EVENT_MESSAGE';
export const EVENT_POLL = 'EVENT_POLL';
export const SESSION_FEED = 'SESSION_FEED';
export const SESSION_MESSAGE = 'SESSION_MESSAGE';
export const SESSION_QUESTION = 'SESSION_QUESTION';
export const QNA_COMMENTS = 'QNA_COMMENTS';
export const SESSION_HAND_RAISE = 'SESSION_HAND_RAISE';
export const SESSION_POLL = 'SESSION_POLL';
export const SESSION_DONORBOX = 'SESSION_DONORBOX';
export const STAGE_CHAT = 'STAGE_CHAT';
export const BANNER_CTA = 'BANNER_CTA';
export const LOWER_THIRDS = 'LOWER_THIRDS';
export const BOOTH_FEED = 'BOOTH_FEED';

export let DEFAULT_NAV_LABELS = ['RECEPTION', 'LOUNGE', 'BOOTHS'];

export const DIRECT_MESSAGE = 'DIRECT_MESSAGE';

export const RHS_NOTIFICATIONS = {
    EVENT_MESSAGE: {
        showNotificationBadge: false,
    },
    EVENT_POLL: {
        showNotificationBadge: false,
    },
    MESSAGE_TAB: {
        showNotificationBadge: false,
    },
    NOTIFICATIONS_TAB: {
        showNotificationBadge: false,
    },
    MANAGE_ABUSE_TAB: {
        showNotificationBadge: false,
    },
};

export const SESSION_RHS_NOTIFICATIONS = {
    SESSION_MESSAGE: {
        showNotificationBadge: false,
    },
    SESSION_QUESTION: {
        showNotificationBadge: false,
    },
    SESSION_HAND_RAISE: {
        showNotificationBadge: false,
    },
    SESSION_POLL: {
        showNotificationBadge: false,
    },
    SESSION_DONORBOX: {
        showNotificationBadge: false,
    },
    BANNER_CTA: {
        showNotificationBadge: true,
    },
    LOWER_THIRDS: {
        showNotificationBadge: true,
    },
};

export const INITIAL_LOAD_LIMIT = LOAD_LIMIT.RHS_FEEDS;
export const DEBOUNCE_WAIT_TIME = 250;

export const QUES_DEFAULT_SORT_INDEX = {
    sortBy: 0,
    view: 0,
};

export const CLOSE_MY_SCHEDULE_OVERLAY = 'CLOSE_MY_SCHEDULE_OVERLAY';

export const CLOSE_LEADERBOARD_OVERLAY = 'CLOSE_LEADERBOARD_OVERLAY';

export const CLOSE_QNA_SETTINGS_POPUP = 'CLOSE_QNA_SETTINGS_POPUP';

export const CHANGE_SESSION_TAB = 'CHANGE_SESSION_TAB';

export const DISPLAY_EVENT_RHS = 'DISPLAY_EVENT_RHS';

export const POPUP_COMMON_MODAL_CLASS = 'POPUP_MODAL_COMMON_CLASS';

export const GET_VIEW_SESSION_CHAT_TAB_ARGS = {
    key: CHANGE_SESSION_TAB,
    payload: {
        tab: SESSION_FEED_INNER_TAB_LABEL,
    },
};

export const backdropSupportedSession = [
    SESSION_TYPES.HOSTING,
    SESSION_TYPES.PRE_RECORDED,
    SESSION_TYPES.STREAMING,
];

export const waitingScreenSupportedSession = [
    SESSION_TYPES.HOSTING,
    SESSION_TYPES.PRE_RECORDED,
    SESSION_TYPES.STREAMING,
];

export const FEEDS_WITH_CHAT_REACTION_SUPPORT = [EVENT_FEED, SESSION_FEED];

export const getHasChatReactions = (chatType) => {
    return FEEDS_WITH_CHAT_REACTION_SUPPORT.includes(chatType);
};

export const defaultRHSKeys = [
    { label: 'Feed', key: 'FEED' },
    { label: 'Polls', key: 'POLLS' },
    { label: 'People', key: 'PEOPLE' },
    { label: 'Alerts', key: 'ALERTS' },
    { label: 'Moderation', key: 'MODERATION' },
    { label: 'Settings', key: 'SETTINGS' },
    { label: 'Messages', key: 'MESSAGES' },
    { label: 'Engage', key: 'ENGAGE' },
    { label: 'Profile', key: 'RHS_PROFILE_TOOLTIP_GUEST' },
    { label: 'Leaderboard', key: 'LEADERBOARD' },
];
