import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

const RowSelector = ({
    id,
    checked,
    onSelect,
    disabled,
    state,
    checkboxBgColor = null,
}) => {
    const checkboxRef = useRef(null);
    const [isDisabled, setIsDisabled] = useState(disabled);

    useEffect(() => {
        setIsDisabled(disabled);
    }, [disabled]);

    useEffect(() => {
        if (checkboxRef && checkboxRef.current) {
            if (state === 'indeterminate') {
                checkboxRef.current.indeterminate = true;
                checkboxRef.current.checked = false;
            } else if (state === 'selected') {
                checkboxRef.current.indeterminate = false;
                checkboxRef.current.checked = true;
            } else {
                checkboxRef.current.indeterminate = false;
                checkboxRef.current.checked = false;
            }
        }
        if (state === 'disabled') {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [state]);

    return (
        <label>
            <input
                type='checkbox'
                onChange={(e) => onSelect(e.target.checked)}
                id={id}
                checked={!isDisabled && checked}
                disabled={isDisabled}
                className={classnames('check-box', { disabled: isDisabled })}
                ref={checkboxRef}
            />
            <span
                className='checkmark'
                style={
                    !isDisabled &&
                    (checked ||
                        ['indeterminate', 'selected'].includes(state)) &&
                    checkboxBgColor
                        ? {
                              backgroundColor: checkboxBgColor,
                              borderColor: checkboxBgColor,
                          }
                        : {}
                }
            ></span>
        </label>
    );
};

export default RowSelector;
