import { useEffect, useMemo, useRef } from 'react';
import { DataObserver } from 'services/realtime/types';
import useRealTimeDataObserver from './live-airmeet/useRealTimeObserver';
import useChatReactionMultipleEmojiFeatureFlag from './useChatReactionMultipleEmojiFeatureFlag';
import useLiveAirmeetContext from './useLiveAirmeetContext';
import useRealTimeDataSource from './useRealTimeDataSource';

export interface DataReaderOptions {
    sessionId?: string;
    airmeetId?: string;
    userId?: string;
    liveContextOverride?: any;
    targetFeature?: string; // to be used if context if data writer is being used outside live context
}
interface ReaderResponse {
    observer: DataObserver;
}

const useDataReader = (
    featureName: string,
    options?: DataReaderOptions
): ReaderResponse => {
    const {
        airmeet: {
            airmeetId,
            data: { currentSession },
        },
        user: { id: userId },
    } = useLiveAirmeetContext() || options?.liveContextOverride;

    const { isMultipleEmoji } = useChatReactionMultipleEmojiFeatureFlag();

    const optionsRef = useRef(options);
    const sessionId = options?.sessionId || currentSession?.sessionid;

    const eventObserverOptions = useMemo(() => {
        return {
            ...(optionsRef.current || {}),
            airmeetId,
            userId,
        };
    }, [airmeetId, userId]);

    const sessionObserverOptions = useMemo(() => {
        return {
            ...eventObserverOptions,
            sessionId,
        };
    }, [eventObserverOptions, sessionId]);

    // for reading txn updates from feature shard in case of feature shrading

    const targetFeature = options?.targetFeature || featureName;

    const dataSource = useRealTimeDataSource(targetFeature, options);
    const dataObserver = useRealTimeDataObserver(featureName, {
        eventObserverOptions,
        sessionObserverOptions,
        isMultipleEmoji,
    });

    useEffect(() => {
        if (!dataObserver || !dataSource) {
            return;
        }
        dataObserver.setDataSource(dataSource);
    }, [dataObserver, dataSource, featureName]);

    return { observer: dataObserver };
};

export default useDataReader;
