import RadioButton from 'atoms/RadioButton/RadioButton';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import { Typography } from 'foundations/typography';
import useToasts from 'hooks/useToasts';
import upperFirst from 'lodash/upperFirst';
import React, { useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';
import { TOAST_VERSION_2 } from 'utils/constants/toasts';

export default function StreamKeySection({
    entityId = null,
    entityName = null,
    streamKey,
    streamUrl,
    variant = 'dark',
    title: keyTitle = null,
    subtitle: keySubtitle = null,
    serverURLTitle = null,
    streamKeyTitle = null,
    isSelectable = false,
    isSelected = false,
    disableCopy = false,
    handleSessionStreamKeyChange = noop,
    isThemeV2 = false,
    airmeetFormat = 'event',
}) {
    const { successToast } = useToasts();
    const title = useMemo(
        () =>
            keyTitle
                ? keyTitle
                : entityName
                ? `Stream key for ${entityName}`
                : `${upperFirst(airmeetFormat)} level stream key`,
        [keyTitle, entityName]
    );

    const subtitle = useMemo(
        () =>
            keySubtitle
                ? keySubtitle
                : entityName
                ? `Use these stream-in details for ${entityName}`
                : `Use these details for all your stream-in sessions in the ${airmeetFormat}`,
        [keySubtitle, entityName]
    );

    if (!streamUrl || !streamKey) return null;

    const isDark = variant === 'dark';

    return (
        <FlexDiv flexDirection='column' alignItems='stretch' pb={5}>
            {isSelectable ? (
                <RadioButton
                    id={entityId}
                    value={entityId}
                    iconBeforeLabel={false}
                    name={'track_stream_key'}
                    parentStyles={{
                        marginBottom: '0',
                    }}
                    changed={handleSessionStreamKeyChange}
                    isSelected={isSelected}
                    radioLabel={false}
                >
                    <Headings
                        title={title}
                        subtitle={subtitle}
                        isDark={isDark}
                        ml='-20px'
                        isThemeV2={isThemeV2}
                    />
                </RadioButton>
            ) : (
                <Headings
                    title={title}
                    subtitle={subtitle}
                    isDark={isDark}
                    isThemeV2={isThemeV2}
                />
            )}

            <FlexDiv alignItems='center' mt={2}>
                <FlexDiv
                    flexDirection='column'
                    alignItems='stretch'
                    flexGrow='1'
                >
                    <Typography
                        variant='caption'
                        color={
                            isThemeV2
                                ? 'text.default.primary'
                                : isDark
                                ? 'ambience.1'
                                : 'ambience.19'
                        }
                        isThemeV2={isThemeV2}
                    >
                        {serverURLTitle ? serverURLTitle : 'Server URL'}
                    </Typography>
                    <Typography
                        variant='caption'
                        color={
                            isThemeV2
                                ? 'text.default.secondary'
                                : isDark
                                ? 'ambience.6'
                                : 'ambience.14'
                        }
                        isThemeV2={isThemeV2}
                        mt={'4px'}
                    >
                        {streamUrl}
                    </Typography>
                </FlexDiv>
                <CopyToClipboardWithDisable
                    options={{ format: 'text/plain' }}
                    text={streamUrl}
                    onCopy={() =>
                        successToast({
                            title: 'Copied server URL',
                            message: entityName
                                ? `Server URL for ${entityName} has been copied`
                                : `${upperFirst(
                                      airmeetFormat
                                  )} level server URL has been copied`,
                            version: TOAST_VERSION_2,
                        })
                    }
                    disableCopy={disableCopy}
                >
                    <ClickText alignItems='center' disableClick={disableCopy}>
                        <Icon
                            icon='link'
                            fill={
                                disableCopy
                                    ? isThemeV2
                                        ? 'text.default.disabled'
                                        : 'ambience.6'
                                    : isThemeV2
                                    ? 'brandDefault'
                                    : isDark
                                    ? 'accentPrimary.0'
                                    : 'accentPrimary.1'
                            }
                            height='16px'
                            width='16px'
                            mr='6px'
                        />

                        <Typography
                            variant='caption'
                            color={
                                disableCopy
                                    ? isThemeV2
                                        ? 'text.default.disabled'
                                        : 'ambience.6'
                                    : isThemeV2
                                    ? 'brandDefault'
                                    : isDark
                                    ? 'accentPrimary.0'
                                    : 'accentPrimary.1'
                            }
                            isThemeV2={isThemeV2}
                        >
                            Copy server URL
                        </Typography>
                    </ClickText>
                </CopyToClipboardWithDisable>
            </FlexDiv>
            <FlexDiv alignItems='center' mt={2}>
                <FlexDiv
                    flexDirection='column'
                    alignItems='stretch'
                    flexGrow='1'
                >
                    <Typography
                        variant='caption'
                        color={
                            isThemeV2
                                ? 'text.default.primary'
                                : isDark
                                ? 'ambience.1'
                                : 'ambience.19'
                        }
                        isThemeV2={isThemeV2}
                    >
                        {streamKeyTitle ? streamKeyTitle : 'Stream Key'}
                    </Typography>
                    <Typography
                        variant='caption'
                        color={
                            isThemeV2
                                ? 'text.default.secondary'
                                : isDark
                                ? 'ambience.6'
                                : 'ambience.14'
                        }
                        isThemeV2={isThemeV2}
                        mt={'4px'}
                    >
                        {streamKey}
                    </Typography>
                </FlexDiv>
                <CopyToClipboardWithDisable
                    options={{ format: 'text/plain' }}
                    text={streamKey}
                    onCopy={() =>
                        successToast({
                            title: 'Copied stream key',
                            message: entityName
                                ? `Stream key for ${entityName} has been copied`
                                : `${upperFirst(
                                      airmeetFormat
                                  )} level stream key has been copied`,
                            version: TOAST_VERSION_2,
                        })
                    }
                    disableCopy={disableCopy}
                >
                    <ClickText alignItems='center' disableClick={disableCopy}>
                        <Icon
                            icon='key'
                            fill={
                                disableCopy
                                    ? isThemeV2
                                        ? 'text.default.disabled'
                                        : 'ambience.6'
                                    : isThemeV2
                                    ? 'brandDefault'
                                    : isDark
                                    ? 'accentPrimary.0'
                                    : 'accentPrimary.1'
                            }
                            height='16px'
                            width='16px'
                            mr='6px'
                        />
                        <Typography
                            variant='caption'
                            color={
                                disableCopy
                                    ? isThemeV2
                                        ? 'text.default.disabled'
                                        : 'ambience.6'
                                    : isThemeV2
                                    ? 'brandDefault'
                                    : isDark
                                    ? 'accentPrimary.0'
                                    : 'accentPrimary.1'
                            }
                            isThemeV2={isThemeV2}
                        >
                            Copy stream key
                        </Typography>
                    </ClickText>
                </CopyToClipboardWithDisable>
            </FlexDiv>
        </FlexDiv>
    );
}

const CopyToClipboardWithDisable = ({
    disableCopy = false,
    children,
    ...props
}) => {
    if (disableCopy) {
        return <FlexDiv {...props}>{children}</FlexDiv>;
    }

    return <CopyToClipboard {...props}>{children}</CopyToClipboard>;
};

const Headings = ({ title, subtitle, isDark, ...props }) => {
    return (
        <>
            <Title isDark={isDark} {...props}>
                {title}
            </Title>
            <Subtitle isDark={isDark} {...props}>
                {subtitle}
            </Subtitle>
        </>
    );
};

const Title = ({ children, isDark, isThemeV2, ...props }) => {
    return (
        <Typography
            variant={isThemeV2 ? 'h4' : 'subtext0'}
            color={
                isThemeV2
                    ? 'text.default.primary'
                    : isDark
                    ? 'ambience.0'
                    : 'ambience.23'
            }
            fontWeight={isThemeV2 ? '' : 'bold'}
            mb={1}
            isThemeV2={isThemeV2}
            {...props}
        >
            {children}
        </Typography>
    );
};

const Subtitle = ({ children, isDark, isThemeV2, ...props }) => {
    return (
        <Typography
            variant='caption'
            color={
                isThemeV2
                    ? 'text.default.secondary'
                    : isDark
                    ? 'ambience.6'
                    : 'ambience.14'
            }
            isThemeV2={isThemeV2}
            {...props}
        >
            {children}
        </Typography>
    );
};

export const ClickText = styled.div`
    display: flex;
    align-items: center;
    cursor: ${({ disableClick = false }) =>
        disableClick ? 'default' : 'pointer'};
`;
