import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import React from 'react';
import { cdnImage } from 'utils/core';
import { PieChartImg } from '../styledComponents';

export default function TopBanner({ title, subtitle, imgSrc, lastUpdatedAt }) {
    return (
        <FlexDiv alignItems='center' pb={3}>
            <FlexDiv flexDirection='column' flexGrow='1'>
                <Typography variant='subtext1' color='ambience.0' mb={1}>
                    {title}
                </Typography>
                <Typography variant='subtext2' color='ambience.6'>
                    {subtitle}
                </Typography>
            </FlexDiv>
            {lastUpdatedAt && (
                <Typography
                    variant='subtext2'
                    color='ambience.6'
                    my='0px'
                    mx='12px'
                >
                    Last updated: {lastUpdatedAt}
                </Typography>
            )}
            <PieChartImg src={cdnImage(imgSrc)} alt='Illustration' />
        </FlexDiv>
    );
}
