import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import keys from 'locale/keys';
import { LOG_LEVEL } from 'utils/constants/logger';

const REQUESTED_INVITE_OVERWRITE_TIME = 3 * 60 * 1000;

const InviteToStageWriter = ({
    sessionId: sessionIdFromProps,
    airmeetId,
    client,
    payload,
    metaData,
    logger,
}) => {
    const InviteLog = logger.init(
        'INVITE_TO_STAGE_DATA_WRITER',
        'green',
        LOG_LEVEL.INFO
    );

    const {
        SEND_INVITE,
        REMOVE_FROM_STAGE,
        UPDATE_USER_INVITATION_DATA,
        UPDATE_INIVITATION_STATUS,
    } = FEATURE_ACTIONS[FEATURE_NAMES.STAGE_INVITE];
    const {
        action,
        userId,
        sessionId: sessionIdFromMetaData,
        sessions,
    } = metaData;

    const sessionId = sessionIdFromMetaData || sessionIdFromProps;

    if (!airmeetId) {
        throw new Error('AirmeetId is required for stage invitation');
    }

    if (!sessionId) {
        throw new Error('SessionId is required for stage invitation');
    }

    if (!userId) {
        throw new Error('UserId is required for stage invitation');
    }

    if (!action) {
        throw new Error('No action is specified for stage invitation');
    }

    const actionHandler = {
        /*
        ACTION: Send invite to stage 
        */
        [SEND_INVITE]: async ({ authUser, t }) => {
            return new Promise((resolve, reject) => {
                client.runTransaction(
                    `${airmeetId}/liveAirmeet/invitations/user/${userId}`,
                    (data) => {
                        // Overwrite 'requested' invites older than 5 minutes
                        const isOlderThanThreeMinutes =
                            !data ||
                            !data.timestamp ||
                            Date.now() - data.timestamp >
                                REQUESTED_INVITE_OVERWRITE_TIME;

                        // Reject invite only if it's in requested state in last three minutes
                        if (
                            !data ||
                            data.status !== 'requested' ||
                            // Handle the case if host refresh amidst pending invite
                            (data.status === 'requested' &&
                                (data.senderUserId === authUser.id ||
                                    isOlderThanThreeMinutes))
                        ) {
                            InviteLog(
                                `Requesting user ${userId} to stage in session ${sessionId}`
                            );
                            return {
                                senderUserId: authUser.id,
                                senderUserName: authUser.name,
                                sessionId: sessionId,
                                status: 'requested',
                                newRole: 'speaker',
                                timestamp: Date.now(),
                            };
                        }

                        const message = data.senderUserName
                            ? t(keys.TOASTS_USER_ALREADY_INVITED_1, {
                                  username: data.senderUserName,
                              })
                            : t(keys.TOASTS_USER_ALREADY_INVITED_2);
                        reject(message);
                        return data;
                    },
                    async (error, committed) => {
                        if (error) {
                            logger.error(
                                'Transaction failed abnormally!',
                                error
                            );
                            reject(t(keys.TOASTS_FACED_TECH_ISSUE));
                        } else if (!committed) {
                            logger.error(
                                'We aborted the transaction (because data already exists).'
                            );
                            reject(t(keys.TOASTS_FACED_TECH_ISSUE));
                        } else {
                            resolve();
                        }
                    },
                    true
                );
            });
        },

        /*
        ACTION: Remove user from stage
        */
        [REMOVE_FROM_STAGE]: async () => {
            const sessionList =
                Array.isArray(sessions) && sessions.length > 0
                    ? sessions
                    : [sessionId];

            for (const userSessionId of sessionList) {
                InviteLog(
                    `Removing user ${userId} from stage for session ${userSessionId}`
                );
                const invitedSpeakersTrackKey = `${airmeetId}/liveAirmeet/genral/invitedSpeakers/${userSessionId}/${userId}`;
                await client.setDataAsync(invitedSpeakersTrackKey, null);
            }
        },

        /*
        ACTIONS: Withdrawing user invite request, invite To stage Removed, invite to stage joined, invite to stage not allowed.  
        DATA : {senderUserId, sessionId, status} || null
        */
        [UPDATE_USER_INVITATION_DATA]: async ({ data, message }) => {
            InviteLog(`${message} for session ${sessionId}`);
            const userInvitationKey = `${airmeetId}/liveAirmeet/invitations/user/${userId}`;
            await client.setDataAsync(userInvitationKey, data);
        },

        /*
        ACTIONS: Accepting or rejecting invitation
        DATA : {senderUserId, sessionId, status}
        STATUS:  'accepting','accepted','rejected'
        */
        [UPDATE_INIVITATION_STATUS]: async ({ data, status }) => {
            InviteLog(
                `Updating stage invitation status: ${status} for user ${userId} for session ${sessionId}`
            );

            const userInvitationKey = `${airmeetId}/liveAirmeet/invitations/user/${userId}`;
            await client.setDataAsync(userInvitationKey, data);

            const invitedSpeakersTrackKey = `${airmeetId}/liveAirmeet/genral/invitedSpeakers/${sessionId}/${userId}`;

            if (status === 'accepted') {
                await client.setDataAsync(
                    invitedSpeakersTrackKey,
                    client.getServerTimestampRef()
                );
            }
        },
    };

    return actionHandler[action](payload);
};

export default InviteToStageWriter;
