import { alpha } from 'foundations/theme';
import { emptyObject } from 'utils/constants/common';

export const getCustomScroll = ({
    theme,
    forceDarkMode = false,
    verticalWidth = '6px',
    showScrollbar = false,
    alwaysShowScroll = false,
    overflowX,
}) =>
    `
    &::-webkit-scrollbar {
        width: ${verticalWidth} !important;
        margin-right: ${verticalWidth};
    }

    &::-webkit-scrollbar-track {
        background: ${
            forceDarkMode ? theme.colors.ambience[3] : 'rgba(209, 133, 133, 0)'
        };
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${
            forceDarkMode
                ? theme.colors.ambience[14]
                : alpha(theme.colors.white, 0)
        } !important;
        border-radius: 5px;
    }
    &::-webkit-scrollbar:horizontal {
        width: ${verticalWidth};
    }

    &::-webkit-scrollbar-track:horizontal {
        background: ${alpha(theme.colors.white, 0)};
    }

    &::-webkit-scrollbar-thumb:horizontal {
        background: ${alpha(theme.colors.white, 0)};
        border-radius: 5px;
    }

    ${
        showScrollbar
            ? `&::-webkit-scrollbar-track {
            background: ${
                theme.isLightTheme
                    ? theme.colors.ambience[22]
                    : theme.colors.ambience[21]
            };
            border-radius: 8px;
        }
    
        &::-webkit-scrollbar-thumb {
            background: ${
                theme.isLightTheme
                    ? theme.colors.ambience[18]
                    : theme.colors.ambience[18]
            } !important;
        }
    
        &::-webkit-scrollbar-track:horizontal {
            background: ${
                theme.isLightTheme
                    ? theme.colors.ambience[22]
                    : alpha(theme.colors.white, 0.1)
            };
        }
    
        &::-webkit-scrollbar-thumb:horizontal {
            background: ${
                theme.isLightTheme
                    ? theme.colors.ambience[18]
                    : alpha(theme.colors.white, 0.4)
            };
        }`
            : ` &:hover{
        &::-webkit-scrollbar-track {
            background: ${
                theme.isLightTheme
                    ? forceDarkMode
                        ? theme.colors.ambience[3]
                        : theme.colors.ambience[22]
                    : forceDarkMode
                    ? theme.colors.ambience[3]
                    : alpha(theme.colors.white, 0.1)
            };
        }
    
        &::-webkit-scrollbar-thumb {
            background: ${
                theme.isLightTheme
                    ? forceDarkMode
                        ? theme.colors.ambience[14]
                        : theme.colors.ambience[18]
                    : forceDarkMode
                    ? theme.colors.ambience[14]
                    : alpha(theme.colors.white, 0.4)
            } !important;
        }
    
        &::-webkit-scrollbar-track:horizontal {
            background: ${
                theme.isLightTheme
                    ? theme.colors.ambience[22]
                    : forceDarkMode
                    ? theme.colors.ambience[3]
                    : alpha(theme.colors.white, 0.1)
            };
        }
    
        &::-webkit-scrollbar-thumb:horizontal {
            background: ${
                theme.isLightTheme
                    ? theme.colors.ambience[18]
                    : forceDarkMode
                    ? theme.colors.ambience[6]
                    : alpha(theme.colors.white, 0.4)
            };
        }
    }`
    }
    ${
        alwaysShowScroll &&
        `&::-webkit-scrollbar-track {
        background: ${
            theme.isLightTheme
                ? theme.colors.ambience[22]
                : forceDarkMode
                ? theme.colors.ambience[3]
                : alpha(theme.colors.white, 0.1)
        };
    }

    &::-webkit-scrollbar-thumb {
        background: ${
            theme.isLightTheme
                ? theme.colors.ambience[18]
                : forceDarkMode
                ? theme.colors.ambience[14]
                : alpha(theme.colors.white, 0.4)
        } !important;
    }

    &::-webkit-scrollbar-track:horizontal {
        background: ${
            theme.isLightTheme
                ? theme.colors.ambience[22]
                : forceDarkMode
                ? theme.colors.ambience[3]
                : alpha(theme.colors.white, 0.1)
        };
    }

    &::-webkit-scrollbar-thumb:horizontal {
        background: ${
            theme.isLightTheme
                ? theme.colors.ambience[18]
                : forceDarkMode
                ? theme.colors.ambience[6]
                : alpha(theme.colors.white, 0.4)
        };
    }`
    };


    // Only works for firefox v64 and above
    scrollbar-color: ${
        theme.isLightTheme
            ? theme.colors.ambience[18]
            : forceDarkMode
            ? theme.colors.ambience[6]
            : alpha(theme.colors.white, 0)
    };
    scrollbar-width: thin;
    
    &:hover {
        scrollbar-color: ${
            theme.isLightTheme
                ? theme.colors.ambience[18]
                : forceDarkMode
                ? theme.colors.ambience[6]
                : alpha(theme.colors.white, 0.4)
        };
    }

    ${overflowX ? `overflow-x:${overflowX} !important;` : ''}
`;

export const getCustomScrollWithThumbOnly = (theme) => `
    &::-webkit-scrollbar {
        width: 4px;
        margin-right: 5px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 5px;
    }
    &::-webkit-scrollbar:horizontal {
        width: 4px;
    }

    &::-webkit-scrollbar-track:horizontal {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb:horizontal {
        background: transparent;
        border-radius: 5px;
    }

    &:hover{
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: ${theme.colors.ambience[18]};
        }

        &::-webkit-scrollbar-track:horizontal {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb:horizontal {
            background: ${theme.colors.ambience[18]};
        }
    }



    // Only works for firefox v64 and above
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    &:hover {
        scrollbar-color: ${theme.colors.ambience[18]} transparent;
    }
`;
export const getStaticColorScroll = ({
    verticalWidth = '6px',
    verticalHeight = '6px',
    thumbColor: thumbColorProp,
    trackColor: trackColorProp,
    trackRadius = '5px',
    cornerRadius = '5px',
    theme,
} = emptyObject) => {
    const thumbColor =
        thumbColorProp || (theme?.isLightTheme ? '#A8A5B6' : '#565365');
    const trackColor =
        trackColorProp || (theme?.isLightTheme ? '#E9E9ED' : '#302E38');

    return `
    &::-webkit-scrollbar {
        width: ${verticalWidth} !important;
        height: ${verticalHeight} !important;
        margin-right: ${verticalWidth};
    }

    &::-webkit-scrollbar-track {
        background: ${trackColor};
        border-radius: ${trackRadius};

        border-radius: ${cornerRadius};
    }

    &::-webkit-scrollbar-thumb {
        background: ${thumbColor} !important;
        border-radius: ${trackRadius};
        border-radius: ${cornerRadius};
    }
    &::-webkit-scrollbar:horizontal {
        width: ${verticalWidth};
        height: ${verticalHeight};
    }

    &::-webkit-scrollbar-track:horizontal {
        background: ${trackColor};
    }

    &::-webkit-scrollbar-thumb:horizontal {
        background: ${thumbColor};
        border-radius: ${cornerRadius};
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }

     &:hover{
        &::-webkit-scrollbar-track {
            background: ${trackColor};
        }
    
        &::-webkit-scrollbar-thumb {
            background: ${thumbColor} !important;
        }
    
        &::-webkit-scrollbar-track:horizontal {
            background: ${trackColor};
        }
    
        &::-webkit-scrollbar-thumb:horizontal {
            background: ${thumbColor};
        }
    }


    // Only works for firefox v64 and above
    scrollbar-color: ${thumbColor};
    scrollbar-width: thin;
    
    &:hover {
        scrollbar-color: ${thumbColor};
    }
`;
};
