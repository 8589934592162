import classNames from 'classnames';
import {
    ACTIVE_LANDING_PAGE_SCREEN_SOURCE,
    PENDING_LANDING_PAGE_SCREEN_SOURCE,
    QUESTIONS_SCREEN_SOURCE,
} from 'components/newOnboardingFlow/config';
import Icon from 'foundations/icon';
import { alpha } from 'foundations/theme';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import useWhiteLabelling from 'hooks/useWhitelabelling';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useScrollYPosition } from 'react-use-scroll-position';
import styled from 'styled-components';
import { background } from 'styled-system';
import useDeviceInfo from '../../hooks/useDeviceInfo';
import useLocalStorage from '../../hooks/useLocalStorage';
import { putUserProfileDetails } from '../../store/actions/login';
import LocalizedString from '../../translation/header.json';
import ThirdPartyLogo from '../ThirdPartyCoupon/thirdPartyLogo';
import AppLogo from './AppLogo';
import SideMenuToggle from './SideMenuToggle';
import { HEADER_TEMPLATES, useTemplateHeader } from './templates';
import useAuthActionHandler from 'hooks/useAuthActionHandler';
import isFunction from 'lodash/isFunction';
import { Box } from '../molecules/utils/basicComponents';
import cloneDeep from 'lodash/cloneDeep';

const ENGLISH = { name: 'English', key: 'en' };
const LoginButton = React.lazy(() => import('./LoginButton'));
const SideMenu = React.lazy(() => import('./SideMenu'));
const LangButton = React.lazy(() => import('./LangButton'));
const ProfileButton = React.lazy(() => import('./ProfileButton'));
const SupportButton = React.lazy(() => import('./SupportButton'));

/**
 * The header is highly customizable due to a rigid structure.
 * It's divided into 3 sections:
 * 1. Left: can contain main nav elements for the current template
 * 2. Title: can contain main title element for the current template
 * 3. Right: can contain secondary nav elements for the current template
 */
export default function Header() {
    const dispatch = useDispatch();
    const headerProps = useSelector((state) => state.layout.header);
    const isEventSeries = useSelector(
        (state) => !!state?.eventSeries?.combinedInfo?.seriesInfo?.uid
    );
    const {
        hidden,
        template,
        showAuth = true,
        onPricing = false,
        featureFlags,
        onThirdParty = false,
        thirdParty = '',
        redirect = true,
        enableLang = false,
        source = 'home page header',
        airmeetData,
        logoOnly = false,
        handleBackBtnClick = null,
        onLoginClick = () => {},
    } = headerProps;
    const {
        logoUrl,
        isWhiteLabellingEnabled,
        isWhiteLabellingLoading,
        isAirmeetBrandingRestricted,
    } = useWhiteLabelling({
        logoUrl: airmeetData?.venueData?.logo_url,
    });
    const { isLoggedIn, isDirectUser } = useAuthActionHandler();
    const { isMobile, isTablet } = useDeviceInfo();
    const isMobileOrTablet = isMobile || isTablet;
    const isOnlyMobile = isMobile && !isTablet;

    const headerRef = useRef(null);
    const [
        leftElements,
        contentElements,
        rightElements = [],
        topBarElements = [],
    ] = useTemplateHeader(template, {
        ...headerProps,
        isMobileOrTablet,
        isOnlyMobile,
        isTablet,
    });
    const location = useLocation();
    const { theme, isLightTheme } = useTheme();
    const user = useSelector((state) => state.auth.user);
    const isSideMenuEnabled = useSelector(
        (state) => state.layout?.sideMenu?.enabled
    );
    const [currentLang, setCurrentLang] = useLocalStorage('lang', ENGLISH);
    useEffect(() => {
        if (location.pathname === '/') {
            if (user && user.hasOwnProperty('id')) {
                if (
                    !user.hasOwnProperty('metadata') ||
                    (user.hasOwnProperty('metadata') && !user.metadata['lang'])
                ) {
                    setCurrentLang(currentLang || ENGLISH);
                    updateUserDetails(currentLang);
                } else if (user.metadata.lang) {
                    setCurrentLang(user.metadata.lang);
                }
            } else {
                setCurrentLang(currentLang || ENGLISH);
            }
        }
    }, [user]);

    const isOnOnboarding = [
        QUESTIONS_SCREEN_SOURCE,
        PENDING_LANDING_PAGE_SCREEN_SOURCE,
        ACTIVE_LANDING_PAGE_SCREEN_SOURCE,
    ].includes(source);

    const updatedRightElements = useMemo(() => {
        const existingRightElements = cloneDeep(rightElements);

        if (!isSideMenuEnabled && template === HEADER_TEMPLATES.community) {
            existingRightElements.length = 0;
            existingRightElements.push(<SupportButton />);
        }

        if (showAuth) {
            enableLang &&
                existingRightElements.push(<LangButton enable={enableLang} />);
            existingRightElements.push(
                <ProfileButton
                    localString={LocalizedString['en'].profileMenu}
                    toolClass={theme.isNewOnboardingPage ? 'showOnMobile' : ''}
                />
            );

            !isLoggedIn &&
                !isDirectUser &&
                existingRightElements.push(
                    <LoginButton
                        localString={
                            LocalizedString['en'].navbar_right.navmenu[0]
                        }
                        redirect={redirect}
                        source={source}
                        onButtonClick={onLoginClick}
                    />
                );
        }

        return existingRightElements;
    }, [
        showAuth,
        enableLang,
        isLoggedIn,
        isDirectUser,
        redirect,
        source,
        theme.isNewOnboardingPage,
        isSideMenuEnabled,
        template,
    ]);

    const updateUserDetails = async (item) => {
        const payload = {
            metadata: {
                lang: item,
            },
        };
        await dispatch(putUserProfileDetails(payload));
    };

    const scrollY = useScrollYPosition();
    useEffect(() => {
        if (!headerRef.current) {
            return;
        }

        if (
            (scrollY >= 60 || (onPricing && scrollY >= 15)) &&
            source !== 'sign up page'
        ) {
            headerRef.current.classList.add('add-color-scroll');
            if (scrollY >= 750) {
                headerRef.current.classList.add('add-on-scroll');
            } else {
                headerRef.current.classList.remove('add-on-scroll');
            }
        } else {
            headerRef.current.classList.remove('add-color-scroll');
        }
    }, [scrollY]);

    const ContentBlock = !!contentElements
        ? contentElements.map((Component, i) =>
              Component ? (
                  <div key={`header-content-${i}`}>{Component}</div>
              ) : null
          )
        : null;

    const getWindowSize = () => {
        return {
            windowWidth: window?.innerWidth || document.body.clientWidth,
            windowHeight: window?.innerHeight || document.body.clientHeight,
        };
    };

    const [isMobileMenu, setActive] = React.useState(false);

    const {
        absolute,
        translucent,
        transparent,
        sticky,
        home_header,
        hasBranding,
        dashboard_header,
        brandLogo,
        mobileScreenWidth = 991,
        permissionWorkflow,
        borderBottom,
        hideLogo,
    } = headerProps;

    return !!hidden ? null : (
        <>
            <MainHeader
                ref={headerRef}
                id='main-header'
                className={classNames(
                    'app-header',
                    'clearfix',
                    { sticky: sticky === true },
                    { absolute: absolute === true },
                    { transparent: transparent === true },
                    { translucent: translucent === true },
                    { home_header: home_header === true },
                    { permission_workflow: permissionWorkflow === true },
                    { has_branding: hasBranding === true },
                    { dashboard_header: dashboard_header === true },
                    { pricing_header: onPricing === true },
                    { third_party: onThirdParty === true },
                    { borderBottom: borderBottom === true }
                )}
                isDashboardHeader={dashboard_header}
                borderBottom={borderBottom}
            >
                {!!topBarElements
                    ? topBarElements.map((Component, i) =>
                          Component ? (
                              <div key={`header-top-bar-${i}`}>{Component}</div>
                          ) : null
                      )
                    : null}
                <div className='header-main'>
                    <div className='float-left'>
                        {/* Toggle for side menu */}
                        <div className='m-menu d-block d-sm-block d-md-none'>
                            <SideMenuToggle />
                        </div>
                        <nav className='navbar'>
                            <ul
                                className={`${
                                    brandLogo
                                        ? 'no-style main-nav has-brand-logo'
                                        : 'no-style main-nav'
                                }`}
                            >
                                {isFunction(handleBackBtnClick) && (
                                    <ArrowWrap
                                        onClick={handleBackBtnClick}
                                        mr='x4'
                                    >
                                        <Icon
                                            icon={'arrow_left'}
                                            width={'1.75rem'}
                                            height={'1.75rem'}
                                            fill={
                                                theme.colors.text.default
                                                    .primary
                                            }
                                        />
                                    </ArrowWrap>
                                )}
                                {!hideLogo &&
                                    !featureFlags &&
                                    getWindowSize().windowWidth <=
                                        mobileScreenWidth && (
                                        <li>
                                            {/* This is always visible with the header */}
                                            {/* <AppLogo logoOnly={false} /> */}
                                            {isWhiteLabellingEnabled ||
                                            (isAirmeetBrandingRestricted &&
                                                logoUrl &&
                                                !logoUrl.includes(
                                                    'default_logo_image'
                                                )) ? (
                                                <VenueLogo src={logoUrl} />
                                            ) : isWhiteLabellingLoading &&
                                              !isEventSeries ? null : (
                                                <AppLogo
                                                    logoOnly={logoOnly}
                                                    isLightTheme={isLightTheme}
                                                    isOnOnboarding={
                                                        isOnOnboarding
                                                    }
                                                />
                                            )}
                                        </li>
                                    )}
                                {!hideLogo &&
                                    !featureFlags &&
                                    !brandLogo &&
                                    getWindowSize().windowWidth >
                                        mobileScreenWidth && (
                                        <li>
                                            {isWhiteLabellingEnabled ||
                                            (isAirmeetBrandingRestricted &&
                                                logoUrl &&
                                                !logoUrl.includes(
                                                    'default_logo_image'
                                                )) ? (
                                                <VenueLogo src={logoUrl} />
                                            ) : isWhiteLabellingLoading &&
                                              !isEventSeries ? null : (
                                                <AppLogo
                                                    logoOnly={logoOnly}
                                                    isLightTheme={isLightTheme}
                                                    isOnOnboarding={
                                                        isOnOnboarding
                                                    }
                                                />
                                            )}
                                        </li>
                                    )}
                                {onThirdParty &&
                                    getWindowSize().windowWidth >
                                        mobileScreenWidth && (
                                        <>
                                            <Typography
                                                variant='h6'
                                                color={
                                                    theme.colors.ambience[14]
                                                }
                                                condensed={true}
                                                style={{
                                                    marginLeft: '15px',
                                                    marginRight: '15px',
                                                }}
                                            >
                                                &
                                            </Typography>
                                            <li>
                                                <ThirdPartyLogo
                                                    thirdParty={thirdParty}
                                                />
                                            </li>
                                        </>
                                    )}

                                {!!leftElements
                                    ? leftElements.map((Component, i) =>
                                          Component ? (
                                              <li
                                                  key={`header-left-${i}`}
                                                  className={'hide-mobile'}
                                              >
                                                  {Component}
                                              </li>
                                          ) : null
                                      )
                                    : null}
                            </ul>
                        </nav>
                    </div>

                    {/* Large screens  */}
                    <div className='header-sub d-none d-sm-flex'>
                        {ContentBlock}
                    </div>

                    <div className='float-right'>
                        <nav
                            className={classNames('navbar', {
                                'hide-mobile': !isOnOnboarding,
                            })}
                        >
                            <ul className='no-style main-nav'>
                                {updatedRightElements.map((Component, i) =>
                                    Component ? (
                                        <li key={`header-right-${i}`}>
                                            {Component}
                                        </li>
                                    ) : null
                                )}
                            </ul>
                        </nav>
                        {!isOnOnboarding && showAuth && (
                            <div className={'hide-desktop'}>
                                <ToggleMenuButton
                                    onClick={() => {
                                        setActive(
                                            (isMobileMenu) => !isMobileMenu
                                        );
                                    }}
                                >
                                    <Icon
                                        width={24}
                                        height={24}
                                        icon={
                                            isMobileMenu
                                                ? 'icon_close'
                                                : 'icon_hamburger'
                                        }
                                        fill='ambience.0'
                                    />
                                </ToggleMenuButton>
                            </div>
                        )}
                    </div>
                </div>

                {/*  Small screens */}
                {ContentBlock != '' && (
                    <div className='header-sub d-flex d-sm-none'>
                        {ContentBlock}
                    </div>
                )}
            </MainHeader>
            <SideMenu
                enable={enableLang}
                localString={LocalizedString['en']}
                isOpen={isMobileMenu}
                closeMenu={() => setActive((isMobileMenu) => !isMobileMenu)}
            />
        </>
    );
}

const ToggleMenuButton = styled.div`
    padding: 0;
    border: 0;
    background: transparent;
    outline: 0 !important;
    box-shadow: none;
    cursor: pointer;
`;

const VenueLogo = styled.img`
    height: auto;
    max-height: 30px;
`;

const MainHeader = styled.header`
    ${({ theme, isDashboardHeader }) =>
        theme.isLightTheme &&
        `
        background-color: ${
            isDashboardHeader ? theme.colors.ambience[24] : theme.colors.white
        } !important;
    `}
    ${({ theme, borderBottom }) =>
        borderBottom &&
        `
        border-bottom: 1px solid ${
            theme?.isLightTheme ? theme.colors.ambience[21] : '#302e38'
        } !important;
    `}
    ${background}
    box-shadow: ${({ theme }) =>
        theme.isLightTheme
            ? `0px 0px 3.6px ${alpha(theme.colors.black, 0.11)},
                0px 0px 14.4px ${alpha(theme.colors.black, 0.04)}`
            : `0px 2px 3px ${theme.colors.black}`};

    &.permission_workflow {
        background: ${({ theme }) =>
            theme.isLightTheme
                ? theme.colors.white
                : theme.colors.ambience[23]};
        border: ${({ theme }) =>
            !theme.isLightTheme && `1px solid ${theme.colors.ambience[21]}`};
        padding: ${({ theme }) => !theme.isLightTheme && '12px 50px'};
        .best-exp-info-web {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            margin: 0;
        }
    }
    ${({ theme }) =>
        theme.isNewOnboardingPage &&
        `
        background-color: ${theme.colors.ambience[24]} !important;
        position: fixed !important;
    `}
`;

const ArrowWrap = styled(Box)`
    cursor: pointer;
    height: auto;
`;
