import RestrictedAccountTooltip from 'atoms/RestrictedAccountTooltip/LiveRestrictedAccountTooltip';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { Box } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text/index';
import React from 'react';
import styled from 'styled-components';

export default function NavItem({
    title,
    desc,
    hasSubNav = false,
    restricted,
}) {
    return (
        <NavWrapper restricted={restricted}>
            {restricted && (
                <RestrictedAccountTooltip
                    iconFillColor={'text.default.secondary'}
                    tooltipPortal={true}
                    tooltipContainerStyle={{
                        transition: 'none',
                    }}
                    portalTop={38}
                    portalLeft={8}
                    marginRight={'15px'}
                />
            )}
            <Box textAlign='left'>
                <Text variant='h5' color='text.default.primary'>
                    {title}
                </Text>
                <Subtitle
                    mt='4px'
                    variant='caption'
                    color='text.default.secondary'
                >
                    {desc}
                </Subtitle>
            </Box>
            {hasSubNav && (
                <NavIcon>
                    <StyledSvgSprite
                        icon='chevron_right'
                        width={24}
                        height={24}
                        fill='text.default.primary'
                    />
                </NavIcon>
            )}
        </NavWrapper>
    );
}

const NavWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const NavIcon = styled.div`
    padding-left: ${({ theme }) => theme.space.x3};
`;

const Subtitle = styled(Text)`
    &::first-letter {
        text-transform: capitalize;
    }
`;
