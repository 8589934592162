import cloneDeep from 'lodash/cloneDeep';
import { FILE_UPLOAD_STATUS } from '../../components/general/Form/FileUploader';
import createReducer from '../../utils/createReducer';
import {
    FILE_UPLOADER_REMOVE_FROM_UPLOADER,
    FILE_UPLOADER_RESPONSE,
    FILE_UPLOADER_STATUS_CHANGE,
    FILE_UPLOADER_STATUS_RESET,
} from '../actions/fileUplaoder';
import {
    CREATE_SESSION_RESPONSE,
    CREAT_AIRMEET_RESPONSE,
} from '../actions/Lounge';

const initialState = {
    uploadStatus: {},
    files: {},
};

const handlers = {};

handlers[FILE_UPLOADER_RESPONSE] = (state = initialState, action) => {
    const { requestFileId, file } = action.payload;
    let { files: stateFiles, uploadStatus } = state;
    const files = cloneDeep(stateFiles);
    files[requestFileId] = file;
    uploadStatus[requestFileId] = action.error
        ? FILE_UPLOAD_STATUS.ERROR
        : FILE_UPLOAD_STATUS.SUCCESS;
    return { ...state, files, uploadStatus };
};

handlers[CREATE_SESSION_RESPONSE] = (state = initialState, action) => {
    let { files } = state;
    const { createSessionMasterImageUrl, createSessionIntroImageUrl } = files;
    if (action.payload) {
        createSessionMasterImageUrl &&
            delete files['createSessionMasterImageUrl'];
        createSessionIntroImageUrl &&
            delete files['createSessionIntroImageUrl'];
    }
    return { ...state, files };
};

handlers[CREAT_AIRMEET_RESPONSE] = (state = initialState, action) => {
    let { files } = state;
    const { createAirmeetMasterImageUrl } = files;
    if (action.payload && createAirmeetMasterImageUrl) {
        delete files['createAirmeetMasterImageUrl'];
    }
    return { ...state, files };
};

handlers['CREATED_SESSION_SPEAKER'] = (state = initialState, action) => {
    let { files } = state;
    const { createSpeakerImage } = files;
    if (action.payload && createSpeakerImage) {
        delete files['createSpeakerImage'];
    }
    return { ...state, files };
};

handlers[FILE_UPLOADER_REMOVE_FROM_UPLOADER] = (
    state = initialState,
    action
) => {
    let { files, uploadStatus } = state;
    const { defaultFile, requestFileId } = action.payload;
    if (files[requestFileId]) {
        if (defaultFile) {
            files[requestFileId] = defaultFile;
        } else {
            delete files[requestFileId];
        }
    }
    if (uploadStatus[requestFileId]) {
        delete uploadStatus[requestFileId];
    }

    return { ...state, files, uploadStatus };
};

handlers[FILE_UPLOADER_STATUS_CHANGE] = (state = initialState, action) => {
    const { requestFileId, uploadStatus: status } = action.payload;
    let { uploadStatus } = state;
    uploadStatus[requestFileId] = status;
    return {
        ...state,
        uploadStatus,
    };
};

handlers[FILE_UPLOADER_STATUS_RESET] = (state = initialState, action) => {
    const { requestFileId } = action.payload;
    let { uploadStatus } = state;
    if (uploadStatus[requestFileId]) {
        delete uploadStatus[requestFileId];
    }
    return {
        ...state,
        uploadStatus,
    };
};

export default createReducer(initialState, handlers);
