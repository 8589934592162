import isBoolean from 'lodash/isBoolean';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_BRAND_LOGO } from 'utils/constants/tenantDetails';
import { cdnImage, s3toCDNImage, whiteLabelString } from 'utils/core';
import { logger } from 'utils/logger';
import { getCurrentTenantData, IS_CUSTOM_TENANT } from '../utils/tenant';

//return a function which whitelabels a text, based on text string provided to it
export default function useWhiteLabelling(props) {
    const airmeetId = useSelector((state) => state.lounge?.airmeet?.airmeetId);

    const { logoUrl: logoUrlProp, forceApply = false } = props || {};

    // use state.cmDashboard or state.lounge accordingly
    const is_white_labelling_enabled = useSelector(
        (state) => state.lounge?.airmeet?.is_white_labelling_enabled
    );
    const is_white_labelling_enabled_dashboard = useSelector(
        (state) => state.cmDashboard?.airmeet?.is_white_labelling_enabled
    );

    const customDomain = useSelector(
        (state) => state.lounge?.airmeet?.custom_domain
    );
    const customDomainDashboard = useSelector(
        (state) => state.cmDashboard?.airmeet?.custom_domain
    );

    const isHidePoweredText = useSelector(
        (state) => state.lounge?.airmeet?.is_hide_powered_text
    );

    const flagEnabled = useMemo(() => {
        if (isBoolean(is_white_labelling_enabled))
            return is_white_labelling_enabled;
        return is_white_labelling_enabled_dashboard;
    }, [is_white_labelling_enabled, is_white_labelling_enabled_dashboard]);

    // Take logo from branding_info for meetup
    const branding_logo = useSelector(
        (state) => state.lounge?.airmeet?.branding_info?.branding_logo
    );

    //Take logo from venue_info for conference
    const venue_logo = useSelector(
        (state) => state.lounge?.airmeet?.venueInfo?.data?.logo_url
    );

    // Check where not to apply white labeling
    const pathname = window.location.pathname;
    const notWhiteLabelPage = useMemo(() => {
        return (
            pathname.startsWith('/airmeets') ||
            pathname.startsWith('/community-manager') ||
            pathname.startsWith('/profile') ||
            pathname.startsWith('/signin') ||
            pathname.startsWith('/pre-event-system-check') ||
            pathname.startsWith('/video-editor')
        );
    }, [pathname]);

    const isAirmeetBrandingRestricted = useSelector(
        (state) => state.lounge.airmeet?.is_airmeet_branding_restricted
    );

    // Final logo url to be returned
    const logoUrl = useMemo(() => {
        let logoPath = branding_logo || venue_logo || logoUrlProp;
        if (
            IS_CUSTOM_TENANT &&
            logoPath &&
            (logoPath || '').includes(DEFAULT_BRAND_LOGO)
        ) {
            logoPath = cdnImage(getCurrentTenantData('logoNoTextUrl'));
        }

        if (logoPath && !logoPath.endsWith('airmeet-logo-notext.svg'))
            return s3toCDNImage(logoPath);
        if (logoUrlProp === '') return null;

        let venue_storage;
        try {
            venue_storage = JSON.parse(localStorage.getItem('venue_storage'));
        } catch {
            logger.info('localStorage is disabled');
            return null;
        }
        if (airmeetId && venue_storage && venue_storage[airmeetId])
            return s3toCDNImage(venue_storage[airmeetId]);
        else return null;
    }, [logoUrlProp, branding_logo, venue_logo, airmeetId]);

    // Final flags to be sent
    const { isEnabled, isLoading } = useMemo(() => {
        if (notWhiteLabelPage && !forceApply)
            return { isEnabled: false, isLoading: false };

        if (IS_CUSTOM_TENANT || isBoolean(flagEnabled)) {
            return {
                isEnabled: IS_CUSTOM_TENANT || flagEnabled,
                isLoading: false,
            };
        }

        const isLoading = true;
        let localStorageFlag;
        try {
            localStorageFlag = JSON.parse(
                localStorage.getItem('is_white_labelling_enabled')
            );
        } catch {
            logger.info('localStorage is disabled');
            localStorageFlag = null;
        }
        if (
            airmeetId &&
            localStorageFlag &&
            localStorageFlag[airmeetId] === 'true'
        ) {
            return { isEnabled: true, isLoading };
        }
        return { isEnabled: false, isLoading };
    }, [flagEnabled, notWhiteLabelPage, forceApply, airmeetId]);

    // Function to find and replace the text
    const whiteLabelText = useCallback(
        (text, replaceText) => {
            if (!isEnabled || typeof text !== 'string' || !text) return text;
            if (isEnabled && typeof replaceText === 'string') {
                return replaceText;
            }
            //replace the words in text with their respective values
            return whiteLabelString(text);
        },
        [isEnabled]
    );

    // Update local storage with fetched data for faster retrieval on page visit
    useEffect(() => {
        if (airmeetId) {
            const whiteLabellingEnabled =
                JSON.parse(
                    localStorage.getItem('is_white_labelling_enabled')
                ) instanceof Object
                    ? JSON.parse(
                          localStorage.getItem('is_white_labelling_enabled')
                      )
                    : {};

            whiteLabellingEnabled[airmeetId] = !!flagEnabled;
            localStorage.setItem(
                'is_white_labelling_enabled',
                JSON.stringify(whiteLabellingEnabled)
            );
        }
    }, [flagEnabled, airmeetId]);
    useEffect(() => {
        if (airmeetId) {
            const venueStorage =
                JSON.parse(localStorage.getItem('venue_storage')) || {};
            if (!logoUrl && venueStorage[airmeetId])
                delete venueStorage[airmeetId];
            else venueStorage[airmeetId] = logoUrl;
            localStorage.setItem('venue_storage', JSON.stringify(venueStorage));
        }
    }, [logoUrl, airmeetId]);

    return {
        whiteLabelText,
        logoUrl,
        isWhiteLabellingEnabled: isEnabled,
        isWhiteLabellingLoading: isLoading,
        isWhiteLabellingFlagEnabled: flagEnabled, // Flag value from BE without any checks
        customDomain: customDomain || customDomainDashboard,
        isHidePoweredText,
        isAirmeetBrandingRestricted:
            isAirmeetBrandingRestricted && !notWhiteLabelPage,
    };
}
