import { useContainerNotifications } from 'atoms/Container/container';
import {
    getCurrentSessionStatus,
    getIsEventCloudHostUser,
    getIsSessionBasedStageEnabled,
    getIsMeetupEventChatRHSEnabled,
} from 'context/LiveAirmeet';
import useIsInFluidSpace from 'hooks/fluidSpace/useIsInFluidSpace';
import useStageContent from 'hooks/stage/useStageContent';
import { ALL_FEATURES } from 'hooks/useFeatureControl';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import usePostEventReplayAccess from 'hooks/usePostEventReplayAccess';
import usePreEventConfig from 'hooks/usePreEventConfig';
import identity from 'lodash/identity';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsBackStage } from 'store/selectors';

import { getLiveConfig } from 'store/selectors';
import {
    RHS_OVERLAY_CLOSED,
    RHS_OVERLAY_OPENED,
} from 'utils/constants/containers/notifications';
import { PORTAL_KEYS } from 'utils/constants/overlay';
import { SESSION_STATUS } from 'utils/constants/sessions';
const MainNavigation = createContext(null);

export const useMainNavigation = (callback = identity) => {
    const ctx = useContext(MainNavigation);

    // Return the up-to-date selected value
    const finalValue = callback(ctx);
    return useMemo(() => finalValue, [finalValue]);
};

export const MainNavigationProvider = ({ children }) => {
    const { notify } = useContainerNotifications();

    const notifyRHSState = (state, portalKey) => {
        notify(true === state ? RHS_OVERLAY_OPENED : RHS_OVERLAY_CLOSED, {
            portalKey,
        });
    };
    const [showRHS, _setShowRHS] = useState(false);

    const setShowRHS = (value) => {
        notifyRHSState(value, PORTAL_KEYS.EVENT_RHS);
        _setShowRHS(value);
    };

    const [isManageAbuseTabActive, _setManageAbuseTab] = useState(false);

    const setManageAbuseTab = (value) => {
        notifyRHSState(value, PORTAL_KEYS.MANAGE_ABUSE);
        _setManageAbuseTab(value);
    };

    const [showHeader, setShowHeader] = useState(false);
    const [headerData, setHeaderData] = useState({});
    const [showBackButton, setShowBackButton] = useState(false);
    const [rootLocation, setRootLocation] = useState('');
    const [route, setRoute] = useState('');
    const {
        airmeet: {
            data: {
                currentAirmeet: {
                    status,
                    post_event_entry,
                    airmeetId,
                    live_config,
                },
            },
            featureFlags: { isConferenceEvent },
        },
        features: enabledFeatures,
    } = useLiveAirmeetContext();

    const sessionBasedStageEnabled = useLiveAirmeetContext(
        getIsSessionBasedStageEnabled
    );
    const isMeetupEventChatRHSEnabled = useLiveAirmeetContext(
        getIsMeetupEventChatRHSEnabled
    );

    const liveConfig = useSelector(getLiveConfig);
    const { hideEventFeed, hideEventPolls } = liveConfig || {};

    const isEventCloudHostUser = useLiveAirmeetContext(getIsEventCloudHostUser);
    const isBackStage = useSelector(getIsBackStage);
    const currentSessionStatus = useLiveAirmeetContext(getCurrentSessionStatus);
    const isUserInFluidSpace = useIsInFluidSpace();
    const { stageId } = useStageContent();
    const isLive = useMemo(
        () => currentSessionStatus === SESSION_STATUS.ONGOING,
        [currentSessionStatus]
    );
    const { hideEventFeedForPreEvent } = usePreEventConfig();

    const { isPostEventEntryAllowed } = usePostEventReplayAccess({
        status: status,
        postEventEntry: post_event_entry,
    });
    const [outsideClickDisabled, setOutsideClickDisabled] = useState(false);

    const [isMyScheduleTabActive, setMyScheduleTab] = useState(false);
    const isPollsHidden =
        !(enabledFeatures || []).includes(
            ALL_FEATURES.ADVANCED_POLLS_ENABLED
        ) || hideEventPolls;
    const hideEventFeedFeature = hideEventFeed || hideEventFeedForPreEvent;
    const isEventFeedTabEnabled =
        !isMeetupEventChatRHSEnabled &&
        !isEventCloudHostUser &&
        !isPostEventEntryAllowed &&
        (!hideEventFeedFeature || !isPollsHidden);

    const isEventFeedTabAlwaysOpen =
        isEventFeedTabEnabled &&
        !(
            isConferenceEvent ||
            isEventCloudHostUser ||
            isUserInFluidSpace ||
            isLive ||
            isBackStage ||
            stageId
        );

    return (
        <MainNavigation.Provider
            value={{
                showRHS,
                setShowRHS,
                showHeader,
                setShowHeader,
                headerData,
                setHeaderData,
                showBackButton,
                setShowBackButton,
                rootLocation,
                setRootLocation,
                route,
                setRoute,
                isConferenceEvent,
                sessionBasedStageEnabled,
                isMeetupEventChatRHSEnabled,
                outsideClickDisabled,
                setOutsideClickDisabled,
                isPostEventEntryAllowed,
                isManageAbuseTabActive,
                setManageAbuseTab,
                isMyScheduleTabActive,
                setMyScheduleTab,
                airmeetId,
                isEventFeedTabAlwaysOpen: isEventFeedTabAlwaysOpen,
                hideEventFeedFeature,
                hideEventFeed,
                hideEventFeedForPreEvent,
                isEventFeedTabEnabled,
                hideMySchedule: live_config?.hideMySchedule,
            }}
        >
            {children}
        </MainNavigation.Provider>
    );
};

export default MainNavigation;
