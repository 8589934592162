import React, { Suspense, useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { noop } from 'utils/constants/common';
import { logger } from 'utils/logger';

export function usePrevious(value, defaultPrevious = null) {
    const ref = useRef(defaultPrevious);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}

export function useChange(value, onChange) {
    const previousValue = usePrevious(value, null);
    useEffect(() => {
        if (onChange && previousValue && value) {
            if (previousValue !== value) {
                onChange();
            }
        }
    }, [onChange, previousValue, value]);
}

export function useOnNewListItem({ disabled, itemList, callback }) {
    const prevList = usePrevious(itemList) || [];
    const hasNewItem = itemList.length > prevList.length;
    const hasCleared = itemList.length < prevList.length;

    useEffect(
        function reactOnNewItem() {
            if (!disabled) {
                if (hasNewItem) {
                    callback(false);
                } else if (hasCleared) {
                    callback(true);
                }
            }
        },
        [disabled, hasNewItem, callback, hasCleared]
    );
}

/*
! Warning: Should be used if you are returning a new object inside your selector
! WARNING: You should not be doing that already as that is an anti-pattern, this is just in case if you cannot avoid
! it
*/
export function useShallowEqualSelector(selector) {
    return useSelector(selector, shallowEqual);
}

export const LazyLoaded = (Component, fallback) => (props) => (
    <Suspense fallback={fallback || <></>}>
        <Component {...props} />
    </Suspense>
);

export const useLoadScript = ({
    url = '',
    onLoadCallback = noop,
    onErrorCallback = noop,
    id,
    executionType = 'async',
}) => {
    useEffect(() => {
        if (!url) {
            logger.info(`No url passed, current url ${url}`);
            return;
        }
        const script = document.createElement('script');
        script.src = url;
        script[executionType] = true;
        script.id = id;
        document.body.appendChild(script);
        script.onload = () => {
            onLoadCallback();
            logger.info(`JS script loaded having id '${id}'`);
        };
        script.onerror = (e) => {
            onErrorCallback(e);
        };
        return () => {
            document.body.removeChild(document.getElementById(id));
        };
    }, [executionType, id, onLoadCallback, onErrorCallback, url]);
};
