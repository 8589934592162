import styled from 'styled-components';

export const Legend = styled.div`
    min-height: 8px;
    min-width: 8px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: ${({ background }) => background};
`;
