import AuthService from '../../utils/authService';
import {
    EMAIL_LOGIN_REQUEST,
    EMAIL_LOGIN_SUCCESS,
    RESET_STORE,
} from '../actions/emailLogin';

const initialState = {
    error: null,
    token: null,
    processing: false,
};

function emailLogin(state = initialState, { type, payload }) {
    switch (type) {
        case EMAIL_LOGIN_REQUEST:
            return {
                ...state,
                error: null,
                processing: true,
            };
        case EMAIL_LOGIN_SUCCESS:
            if (payload instanceof Error) {
                return {
                    ...state,
                    error: payload.json.message,
                    processing: false,
                };
            }

            const { token, ...user } = payload;
            AuthService.user = user;
            return {
                ...state,
                token,
                processing: false,
            };
        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
}

export default emailLogin;
