import { API_SERVICE } from '../../../utils/apiService';
import { CALL_API } from '../../api';

export const BOOTH_REQUEST = 'BOOTH_REQUEST';
export const BOOTH_RESPONSE = 'BOOTH_RESPONSE';
export const ADD_BOOTH_REQUEST = 'CREATE_BOOTH_REQUEST';
export const UPDATE_BOOTH_REQUEST = 'UPDATE_BOOTH_REQUEST';
export const DELETE_BOOTH_REQUEST = 'DELETE_BOOTH_REQUEST';
export const REORDER_BOOTH_REQUEST = 'REORDER_BOOTH_REQUEST';

//Booths API
export function getBooths(airmeetId) {
    return {
        [CALL_API]: {
            types: [BOOTH_REQUEST, BOOTH_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/booth`,
            service: API_SERVICE.BFF,
            type: 'json',
            method: 'GET',
        },
    };
}

export function addBooth(payload) {
    const { airmeetId, boothInfo } = payload;
    return {
        [CALL_API]: {
            types: [ADD_BOOTH_REQUEST],
            endpoint: `/airmeet/${airmeetId}/booth`,
            service: API_SERVICE.BFF,
            body: boothInfo,
            type: 'json',
            method: 'POST',
        },
    };
}
export function updateBooth(payload) {
    const { airmeetId, boothId, boothInfo } = payload;
    return {
        [CALL_API]: {
            types: [UPDATE_BOOTH_REQUEST],
            endpoint: `/airmeet/${airmeetId}/booth/${boothId}`,
            service: API_SERVICE.BFF,
            body: boothInfo,
            type: 'json',
            method: 'PUT',
        },
    };
}

export function deleteBooth(airmeetId, boothId) {
    return {
        [CALL_API]: {
            types: [DELETE_BOOTH_REQUEST],
            endpoint: `/airmeet/${airmeetId}/booth/${boothId}`,
            service: API_SERVICE.BFF,
            type: 'json',
            method: 'DELETE',
        },
    };
}

export function reorderBooths(airmeetId, data) {
    return {
        [CALL_API]: {
            types: [REORDER_BOOTH_REQUEST],
            endpoint: `/airmeet/${airmeetId}/booth`,
            service: API_SERVICE.BFF,
            body: data,
            type: 'json',
            method: 'PUT',
        },
    };
}
