import { useEffect, useMemo } from 'react';
import { ALL_FEATURES_CONFIG } from 'hooks/useFeatureControl';
import { setMusicMode } from 'store/actions/liveAirmeet';
import { useDispatch } from 'react-redux';

const useMusicModeFeature = ({ featuresConfig }) => {
    const dispatch = useDispatch();
    const isEnabled = useMemo(() => {
        const musicModeEnabled =
            featuresConfig?.[ALL_FEATURES_CONFIG.MUSIC_MODE];
        return musicModeEnabled !== null ? Boolean(musicModeEnabled) : false;
    }, [featuresConfig]);

    useEffect(() => {
        dispatch(setMusicMode(isEnabled));
    }, [isEnabled, dispatch]);
};

export default useMusicModeFeature;
