import { SWITCH_DISPLAY_BANNER } from '../actions/banner';

const initialState = {
    summitUIBanner: false,
};
function UIBanner(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SWITCH_DISPLAY_BANNER:
            return {
                ...state,
                summitUIBanner: payload,
            };
        default:
            return state;
    }
}

export default UIBanner;
