import { SET_PIP_SESSION_ID, SET_PIP_VISIT_ID } from 'store/actions/pipMode';

const initialState = {
    pipVisitId: null,
    pipSessionId: null,
    pipReplayUrl: null,
};

function pipMode(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case SET_PIP_VISIT_ID:
            return { ...state, pipVisitId: payload };
        case SET_PIP_SESSION_ID:
            return { ...state, pipSessionId: payload };
        default:
            return state;
    }
}

export default pipMode;
