import {
    getCurrentSession,
    getIsConferenceEvent,
    getIsLoungeEnabled,
    getIsMeetupLiteEvent,
    getIsSessionBasedStageEnabled,
} from 'context/LiveAirmeet';
import { useLiveSessionContext } from 'context/Session';
import useDeviceInfo from 'hooks/useDeviceInfo';
import { ALL_FEATURES } from 'hooks/useFeatureControl';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import useSessionPlayback from 'hooks/useSessionPlayback';
import useBlockUser from 'hooks/users/useBlockUser';
import difference from 'lodash/difference';
import isBoolean from 'lodash/isBoolean';
import React, { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    getBreakoutRoomJoinStatus,
    getIsWatchReplayClicked,
    getPipSessionId,
} from 'store/selectors';
import useLiteModeGracePeriod from 'hooks/live-airmeet/useLiteModeGracePeriod';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import { RAISE_HAND_TAB } from 'utils/constants/controls';
import { canConnectConditions } from 'utils/constants/live-airmeet';
import { SESSION_STATUS, SESSION_TYPES } from 'utils/constants/sessions';
import { USER_ROLE } from 'utils/constants/users';
import {
    HIDE_SHOW_USER,
    RAISE_HAND,
    STAGE_LAYOUT,
    STAGE_STREAM,
    hasAccess,
    isAttendee,
    isEventAttendee,
    isEventOrganizer,
    isOrganizer,
} from 'utils/userAccessControl';
import UserRoles from 'utils/userRoles';
import { includesRole } from 'utils/users';
import useCanAccessPersistentStage from 'components/allOnline/persistentStageBar/useCanAccessPersistentStage';
import useIsInFluidSpace from 'hooks/fluidSpace/useIsInFluidSpace';
import { emptyObject } from 'utils/constants/common';

const { ATTENDEE } = USER_ROLE;

const StageConfig = React.createContext({});

const useFluidSpaceStageConfig = () => {
    const isInFluidSpace = useIsInFluidSpace();
    const isConferenceEvent = useLiveAirmeetContext(getIsConferenceEvent);
    const currentSession =
        useLiveAirmeetContext(getCurrentSession) || emptyObject;
    const isSessionFinished = currentSession.status === SESSION_STATUS.FINISHED;
    const canShowDefaultControls = useCallback(
        () => isSessionFinished && !isInFluidSpace,
        [isInFluidSpace, isSessionFinished]
    );
    const canShowSessionRHS = useCallback(
        () => !isConferenceEvent && !isInFluidSpace,
        [isInFluidSpace]
    );
    return {
        canShowDefaultControls,
        canShowSessionRHS,
        isInFluidSpace,
    };
};

const getHasBlockedWebRTCConnection = (store) =>
    store.genral.hasBlockedWebRTCConnection;

const StageConfigProvider = ({ children }) => {
    const {
        controls,
        features: enabledFeatures,
        airmeet: {
            featureFlags: { isConferenceEvent },
            data: { currentAirmeet },
        },
        isSupportedBrowser,
        fullScreen: fullScreenMode,
    } = useLiveAirmeetContext();
    const isPersistentBackstageEnabled = useLiveSessionContext(
        (ctx) => ctx.isPersistentBackstageEnabled
    );
    const isLoungeEnabled = useLiveAirmeetContext(getIsLoungeEnabled);
    const sessionBasedStageEnabled = useLiveAirmeetContext(
        getIsSessionBasedStageEnabled
    );
    const isMeetupLiteEvent = useLiveAirmeetContext(getIsMeetupLiteEvent);

    const isClosedCaptioningEnabled = useLiveAirmeetContext(
        (ctx) => ctx.isClosedCaptioningEnabled
    );

    const isBreakoutRoomJoined = useSelector(getBreakoutRoomJoinStatus);

    const fluidSpaceStageConfigs = useFluidSpaceStageConfig();

    const {
        allowToPublishOnBackstage: allowToPublishOnPersistentBackstage,
        allowToPublishOnStage: allowToPublishOnPersistentStage,
        canAccessPersistentBar,
        canAccessMinimizedPersistentBar,
        isUserAllowedToPublishOnStage: isUserAllowedToPublishOnPersistentStage,
    } = useCanAccessPersistentStage();

    const {
        isLive,
        liteModeActive,
        userStageRole,
        currentSession = {},
        sessionReplayId,
        showCDNStreams,
        isGroupMeetingLayoutV2Enabled: showGroupMeetingLayoutV2,
    } = useLiveSessionContext();
    const { isMobile, isTablet, isSafari } = useDeviceInfo();
    const canConnectToWebRTCCurrentState = useSelector(
        getHasBlockedWebRTCConnection
    );
    const { blockedControls, isCurrentUserBlocked } = useBlockUser();
    const enabledControls = useMemo(
        () => difference(controls, blockedControls),
        [controls, blockedControls]
    );

    const userEventRole = UserRoles.getUserEventRole();

    // Stage Chat Control
    const allowSpeakerChat =
        isEventOrganizer() ||
        !(sessionBasedStageEnabled ? isAttendee() : isEventAttendee());

    const showStageChat =
        enabledFeatures.includes(ALL_FEATURES.STAGE_CHAT) && allowSpeakerChat;

    // Stage Hide User Control
    const showHideUserControl =
        !isPersistentBackstageEnabled &&
        hasAccess(HIDE_SHOW_USER.MANAGE) &&
        ![SESSION_TYPES.PRE_RECORDED].includes(currentSession?.type);

    // Show Reaction Button
    const hideSessionEmojiReactions =
        currentSession?.live_config?.hideSessionEmojiReactions;
    const showReactionButton =
        isLive &&
        currentSession &&
        (!enabledFeatures ||
            enabledFeatures.indexOf(ALL_FEATURES.REACTIONS) >= 0) &&
        !(isBoolean(hideSessionEmojiReactions)
            ? hideSessionEmojiReactions
            : currentAirmeet?.live_config?.hideSessionsEmojiReactions);

    // Show Raise Hand
    const showRaiseHand =
        isLive &&
        currentSession?.sessionid &&
        currentSession?.type === SESSION_TYPES.HOSTING &&
        hasAccess(
            isPersistentBackstageEnabled
                ? RAISE_HAND.REQUEST_ON_PERSISTENT_BACKSTAGE
                : RAISE_HAND.REQUEST
        ) &&
        !liteModeActive &&
        (!enabledControls || enabledControls.indexOf(RAISE_HAND_TAB) >= 0);

    const isCCAvailableForEvent =
        currentAirmeet?.live_config?.showCloseCaption !== undefined
            ? currentAirmeet?.live_config?.showCloseCaption
            : isClosedCaptioningEnabled;
    // Show close caption
    const showCloseCaption =
        !liteModeActive &&
        currentSession?.type === SESSION_TYPES.HOSTING &&
        isCCAvailableForEvent;

    // Show Lite Mode
    const shouldRestrictToLiteMode = isMobile || (isTablet && showCDNStreams);
    const showLiteMode =
        (enabledFeatures.indexOf(ALL_FEATURES.LITE_MODE) > -1 &&
            liteModeActive &&
            isLive &&
            userStageRole === USER_ROLE.ATTENDEE) ||
        shouldRestrictToLiteMode;

    const currentSessionStatus = currentSession?.status;

    const liteModeGracePeriod = useLiteModeGracePeriod(
        showLiteMode,
        currentSessionStatus
    );

    // Show Session Replay
    const {
        canSessionPlayback,
        isSessionPlaybackGlobalEnabled,
    } = useSessionPlayback({
        sessionid: sessionReplayId,
    });

    const isSessionPlaybackEnabled = isMeetupLiteEvent
        ? isSessionPlaybackGlobalEnabled && canSessionPlayback
        : canSessionPlayback;
    const isWatchReplayClicked = useSelector(getIsWatchReplayClicked);

    const pipSessionId = useSelector(getPipSessionId);
    const checkSessionStateForReplay = isConferenceEvent
        ? currentSession?.status === 'FINISHED'
        : currentSession?.status !== 'ONGOING';

    const enableStagePip =
        isConferenceEvent && isAttendee() && !isTablet && !isMobile;

    const isPipMode =
        enableStagePip && pipSessionId === currentSession?.sessionid;

    const showSessionReplay =
        checkSessionStateForReplay &&
        !isMobile &&
        isSessionPlaybackEnabled &&
        isWatchReplayClicked;

    // Show recorded video seek confirmation
    const showVideoSeekConfirmation =
        currentSession?.type === SESSION_TYPES.PRE_RECORDED;

    // Disable stream publishing
    const showStreamControls =
        currentSession?.type !== SESSION_TYPES.PRE_RECORDED;

    // Show Audio Control
    const showAudioVideoControl = showStreamControls;

    // Show screen share
    const showScreenShare = showStreamControls;

    // Show Audio Video pref
    const showAudioVideoPref = showStreamControls;

    // Show Audio Video pref
    const showLayoutSwitcher =
        showStreamControls &&
        hasAccess(STAGE_LAYOUT.MANAGE, { sessionSpecific: true });

    // Show Audio only mode
    const canShowAudioOnlyMode =
        !isSafari && currentSession?.type !== SESSION_TYPES.PRE_RECORDED;

    // Show lite mode
    const canShowLiteMode = isLive;

    const stageWatchModes = { canShowAudioOnlyMode, canShowLiteMode };

    const canJoinInteractiveMode =
        canConnectConditions.includes(canConnectToWebRTCCurrentState) &&
        isSupportedBrowser &&
        !isMobile &&
        !isTablet &&
        !isCurrentUserBlocked;

    const showReactionButtonRHS =
        showReactionButton &&
        showStreamControls &&
        isLive &&
        !isAttendee() &&
        !isOrganizer();

    const currentSessionType = currentSession.type;
    const showReactionButtonLayoutV2 =
        showReactionButton &&
        (isAttendee() ||
            isOrganizer() ||
            [SESSION_TYPES.STREAMING, SESSION_TYPES.PRE_RECORDED].includes(
                currentSessionType
            ));

    // To show recording control
    const showRecordingControl = useMemo(() => {
        return (
            !isLive &&
            currentSession &&
            !SESSION_STATUS.FINISHED.includes(currentSession?.status)
        );
    }, [isLive, currentSession]);

    const isSpeedNetworking =
        currentSession?.type === SESSION_TYPES.SPEED_NETWORKING;

    const showBackButton = useMemo(() => {
        if (isMeetupLiteEvent && !isBreakoutRoomJoined) return false;

        if (isSpeedNetworking && isLive && !isConferenceEvent) return false;

        return (
            !fullScreenMode &&
            ((!isLive && (isConferenceEvent || isLoungeEnabled)) ||
                (sessionBasedStageEnabled &&
                    (isConferenceEvent ||
                        !includesRole([ATTENDEE], userEventRole))) ||
                isBreakoutRoomJoined)
        );
    }, [
        isSpeedNetworking,
        isLive,
        isConferenceEvent,
        fullScreenMode,
        isLoungeEnabled,
        sessionBasedStageEnabled,
        userEventRole,
        isBreakoutRoomJoined,
        isMeetupLiteEvent,
    ]);

    const showSessionEndedState = useMemo(
        () =>
            SESSION_STATUS.FINISHED.includes(currentSession?.status) &&
            isLoungeEnabled === false &&
            !isConferenceEvent &&
            !fluidSpaceStageConfigs.isInFluidSpace &&
            ((isMeetupLiteEvent &&
                ![SESSION_TYPES.HOSTING].includes(currentSessionType)) ||
                (sessionBasedStageEnabled
                    ? UserRoles.getUserSessionRole()
                    : userEventRole) === ATTENDEE ||
                currentAirmeet?.status === AIRMEET_STATUS.FINISHED),
        [
            currentSession,
            currentSessionType,
            isMeetupLiteEvent,
            isConferenceEvent,
            isLoungeEnabled,
            userEventRole,
            currentAirmeet,
            sessionBasedStageEnabled,
            userStageRole,
            fluidSpaceStageConfigs.isInFluidSpace,
        ]
    );

    // Does user have access to publish stream on stage
    const organizerMeetupBackstageAccess =
        !isConferenceEvent && !isEventAttendee();
    const organizerConferenceBackstageAccess = isOrganizer();
    const canOrganizerPublishOnPersistentBackstage =
        isPersistentBackstageEnabled && allowToPublishOnPersistentStage;
    const canOrganizerPublishOnLiveSession =
        canOrganizerPublishOnPersistentBackstage || !isLive;
    const hasAccessToPublishOnStage =
        hasAccess(STAGE_STREAM.PUBLISH) ||
        (canOrganizerPublishOnLiveSession &&
            (organizerConferenceBackstageAccess ||
                organizerMeetupBackstageAccess));

    const config = {
        showRaiseHand,
        showCloseCaption,
        showStageChat,
        showHideUserControl,
        showGroupMeetingLayoutV2,
        showReactionButton,
        showLiteMode,
        showSessionReplay,
        showVideoSeekConfirmation,
        showAudioVideoControl,
        showScreenShare,
        showAudioVideoPref,
        showLayoutSwitcher,
        showReactionButtonRHS,
        showReactionButtonLayoutV2,
        showRecordingControl,
        hasAccessToPublishOnStage,
        canJoinInteractiveMode,
        enableStagePip,
        isPipMode,
        isBreakoutRoomJoined,
        stageWatchModes,
        showBackButton,
        showSessionEndedState,
        liteModeGracePeriod,
        allowToPublishOnPersistentBackstage,
        allowToPublishOnPersistentStage,
        canAccessPersistentBar,
        canAccessMinimizedPersistentBar,
        isUserAllowedToPublishOnPersistentStage,
        fluidSpaceStageConfigs,
    };

    return (
        <StageConfig.Provider value={{ ...config }}>
            {children}
        </StageConfig.Provider>
    );
};

export default StageConfigProvider;

export function useStageConfig(callback = (context) => context) {
    // The stage config context
    const ctx = useContext(StageConfig);

    // Return the up-to-date selected value
    return callback(ctx);
}
