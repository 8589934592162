export const ADD_INVITE_TABLE_DND_USERS = 'ADD_INVITE_TABLE_DND_USERS';
export const REMOVE_INVITE_TABLE_DND_USERS = 'REMOVE_INVITE_TABLE_DND_USERS';
export const TOGGLE_INVITE_TABLE_DND_MODAL = 'TOGGLE_INVITE_TABLE_DND_MODAL';

export const addInviteTableDndUsers = (dndUsers) => {
    return {
        payload: dndUsers,
        type: ADD_INVITE_TABLE_DND_USERS,
    };
};

export const removeInviteTableDndUser = (userId) => {
    return {
        payload: userId,
        type: REMOVE_INVITE_TABLE_DND_USERS,
    };
};

export const toggleInviteTableDndModal = (value) => {
    return {
        type: TOGGLE_INVITE_TABLE_DND_MODAL,
        payload: value,
    };
};
