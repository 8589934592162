import Box from 'atoms/Box';
import Btn from 'atoms/Button/btn';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text/';
import useToasts from 'hooks/useToasts';
import React, { useEffect, useMemo, useState } from 'react';
import {
    HexAlphaColorPicker,
    HexColorInput,
    HexColorPicker,
} from 'react-colorful';
import usePortal from 'react-cool-portal';
import styled from 'styled-components';
import {
    alphaToHexAlpha,
    getHexAlphaPercent,
    getHexColorValue,
    hexToHSL,
    hexaToHSLA,
    hexaToRgba,
    nearestHexColorCode,
} from 'utils/colors';
import { noop } from 'utils/constants/common';
import { logger } from 'utils/logger';
import SearchSelectColor from './SearchSelectColor';

const DEFAULT_COLOR = '#6A4CFF';
const DEFAULT_COLOR2 = '#A45FD2';

function ColorPicker({
    title = 'Custom color',
    onClose = noop,
    outsideClose = false,
    onSave = noop,
    gradient = false,
    isOpacityEnabled = false,
    value,
    colorData,
    ...rest
}) {
    const { successToast } = useToasts();

    const [nearestHexColor, setNearestHexColor] = useState({});
    const [color, setColor] = useState(
        Array.isArray(value)
            ? value.length > 0
                ? getHexColorValue(value[0], isOpacityEnabled)
                : DEFAULT_COLOR
            : getHexColorValue(value, isOpacityEnabled) || DEFAULT_COLOR
    );
    const [nearestHexColor2, setNearestHexColor2] = useState({});
    const [color2, setColor2] = useState(
        Array.isArray(value)
            ? value.length > 1
                ? getHexColorValue(value[1], isOpacityEnabled, DEFAULT_COLOR2)
                : value.length > 0
                ? getHexColorValue(value[0], isOpacityEnabled, DEFAULT_COLOR2)
                : DEFAULT_COLOR2
            : getHexColorValue(value, isOpacityEnabled, DEFAULT_COLOR2) ||
                  DEFAULT_COLOR2
    );
    const [activePicker, setActivePicker] = useState(1);

    const [eyeDropperIsSupported, setEyeDropperIsSupported] = useState(false);

    const activeColor = useMemo(() => (activePicker === 1 ? color : color2), [
        activePicker,
        color,
        color2,
    ]);
    const handleOpacityChange = (e) => {
        const value = parseInt(
            (e?.target?.value || '').trim().replace('%', '')
        );

        const hexAlpha = alphaToHexAlpha(value);

        if (!gradient || activePicker === 1) {
            setColor((color) => color.slice(0, 7) + hexAlpha);
        } else if (gradient && activePicker === 2) {
            setColor2((color) => color.slice(0, 7) + hexAlpha);
        }
    };

    const handleColorChange = (color) => {
        if (!gradient || activePicker === 1) {
            setColor(color);
        } else if (gradient && activePicker === 2) {
            setColor2(color);
        }
    };

    const handleClose = () => {
        onClose && onClose();
    };

    const handleSave = () => {
        onSave &&
            onSave(
                gradient
                    ? isOpacityEnabled
                        ? [hexaToHSLA(color), hexaToHSLA(color2)]
                        : [hexToHSL(color), hexToHSL(color2)]
                    : isOpacityEnabled
                    ? hexaToHSLA(color)
                    : hexToHSL(color),
                gradient ? [color, color2] : color, // hex
                gradient
                    ? [hexaToRgba(color), hexaToRgba(color2)]
                    : hexaToRgba(color) // rgba object
            );
        handleClose();
    };

    useEffect(() => {
        const splittedActiveColor =
            color.length > 7 ? color.substring(0, 7) : color;
        setNearestHexColor(
            nearestHexColorCode({
                colorHex: splittedActiveColor,
                colorData,
            })
        );

        const splittedActiveColor2 =
            color2.length > 7 ? color2.substring(0, 7) : color2;
        setNearestHexColor2(
            nearestHexColorCode({
                colorHex: splittedActiveColor2,
                colorData,
            })
        );
    }, [colorData, color, color2]);

    const handleChange = (option) => {
        if (activePicker === 1) {
            setColor(`#${option?.value}`);
            setNearestHexColor({
                originalHex: option?.value,
                matchedHex: option?.value,
                name: option?.label,
                isExactMatch: true,
            });
        } else {
            setColor2(`#${option?.value}`);
            setNearestHexColor2({
                originalHex: option?.value,
                matchedHex: option?.value,
                name: option?.label,
                isExactMatch: true,
            });
        }
    };

    useEffect(() => {
        if (window.EyeDropper) {
            setEyeDropperIsSupported(true);
        }
    }, []);

    const handleEyeDropper = () => {
        if (window.EyeDropper) {
            const eyeDropper = new window.EyeDropper();

            eyeDropper
                .open()
                .then((result) => {
                    if (result?.sRGBHex) {
                        if (!gradient || activePicker === 1) {
                            setColor(result?.sRGBHex);
                        } else if (gradient && activePicker === 2) {
                            setColor2(result?.sRGBHex);
                        }

                        successToast('Color captured successfully');
                    }
                })
                .catch((e) => {
                    logger.error('ColorPickerV2.handleEyeDropper: ', e);
                });
        }
    };

    return (
        <>
            <PickerContainer
                flexDirection='column'
                alignItems='stretch'
                {...rest}
            >
                <Header alignItems='center'>
                    {title && (
                        <Text
                            variant='body1'
                            color='text.default.primary'
                            flex='1'
                        >
                            {title}
                        </Text>
                    )}
                    <CloseButton
                        variant='link'
                        color='text.default.primary'
                        onClick={handleClose}
                    >
                        <StyledSvgSprite
                            icon='icon_close'
                            height='20px'
                            width='20px'
                            fill='text.default.primary'
                        />
                    </CloseButton>
                </Header>
                <SearchSelectColor
                    handleChange={handleChange}
                    colorData={colorData}
                />
                {gradient ? (
                    <GradientWrapper>
                        <FlexDiv
                            alignItems='center'
                            justifyContent='space-between'
                            pb='8px'
                        >
                            <ColorSelectBox
                                onClick={() => setActivePicker(1)}
                                isActive={activePicker === 1}
                                color={color}
                                nearestHexColor={nearestHexColor}
                            />
                            <ColorSelectBox
                                onClick={() => setActivePicker(2)}
                                isActive={activePicker === 2}
                                color={color2}
                                nearestHexColor={nearestHexColor2}
                            />
                        </FlexDiv>
                        <GradientStrip color={color} color2={color2} />
                    </GradientWrapper>
                ) : (
                    <FlexDiv alignItems='center' py='8px'>
                        <ColorCircle
                            style={{
                                backgroundColor: (
                                    nearestHexColor?.originalHex || ''
                                ).startsWith('#')
                                    ? nearestHexColor?.originalHex
                                    : `#${nearestHexColor?.originalHex}`,
                            }}
                        />
                        <Box ml='6px'>
                            <Text
                                variant='caption'
                                color='text.default.primary'
                            >
                                {!nearestHexColor?.isExactMatch && '~ '}
                                {nearestHexColor?.name}
                            </Text>
                            <Text
                                variant='caption10'
                                color='text.default.secondary'
                            >
                                {(
                                    nearestHexColor?.originalHex || ''
                                ).startsWith('#')
                                    ? nearestHexColor?.originalHex
                                    : `#${nearestHexColor?.originalHex}`}
                            </Text>
                        </Box>
                    </FlexDiv>
                )}
                <FlexDiv position='relative'>
                    <PickerWrapper
                        eyeDropperIsSupported={eyeDropperIsSupported}
                    >
                        {isOpacityEnabled ? (
                            <HexAlphaColorPicker
                                color={activeColor}
                                onChange={handleColorChange}
                            />
                        ) : (
                            <HexColorPicker
                                color={activeColor}
                                onChange={handleColorChange}
                            />
                        )}
                        {eyeDropperIsSupported && (
                            <PickerIcon
                                icon='eyedroppper'
                                height='20px'
                                width='20px'
                                fill='text.default.primary'
                                isOpacityEnabled={isOpacityEnabled}
                                onClick={handleEyeDropper}
                            />
                        )}
                    </PickerWrapper>
                </FlexDiv>
                <FlexDiv alignItems='center' mt='14px'>
                    <HexInputWrapper isOpacityEnabled={isOpacityEnabled}>
                        <HexInput
                            color={activeColor}
                            onChange={handleColorChange}
                            prefixed={true}
                        />
                    </HexInputWrapper>
                    {isOpacityEnabled && (
                        <AlphaInputWrapper>
                            <StyledInput
                                value={`${getHexAlphaPercent(activeColor)}%`}
                                onChange={handleOpacityChange}
                            />
                        </AlphaInputWrapper>
                    )}
                </FlexDiv>
                <FlexDiv alignItems='center' pt='16px'>
                    <Btn
                        variant='primary'
                        onClick={handleSave}
                        mr='16px'
                        size='small'
                    >
                        Save
                    </Btn>
                    <Btn variant='secondary' onClick={handleClose} size='small'>
                        Cancel
                    </Btn>
                </FlexDiv>
            </PickerContainer>
            <Overlay onClick={outsideClose ? handleClose : noop} />
        </>
    );
}

const ColorSelectBox = ({ onClick, isActive, color, nearestHexColor }) => (
    <ColorBox onClick={() => onClick && onClick(1)} isActive={isActive}>
        <FlexDiv flexDirection='column' alignItems='center'>
            <FlexDiv style={{ position: 'relative' }}>
                <ColorCircle
                    style={{ backgroundColor: color }}
                    isActive={isActive}
                />
            </FlexDiv>
            <StyledSvgSprite
                icon='triangle-down'
                fill='fill.other1'
                mt='4px'
                style={{ visibility: isActive ? 'visible' : 'hidden' }}
                height='6px'
                width='12px'
            />
        </FlexDiv>
        <FlexDiv
            flex='1'
            flexDirection='column'
            alignItems='stretch'
            pl='10px'
            style={{ overflow: 'hidden' }}
        >
            <ClrName
                variant='caption'
                color='text.default.primary'
                title={nearestHexColor?.name}
            >
                {!nearestHexColor?.isExactMatch && '~ '}
                {nearestHexColor?.name}
            </ClrName>
            <Text variant='caption10' color='text.default.secondary'>
                {color}
            </Text>
        </FlexDiv>
    </ColorBox>
);

const CONTAINER_WIDTH = '248px';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9992;
    background-color: rgba(0, 0, 0, 0.8);
`;

const PickerContainer = styled(FlexDiv)`
    background: ${({ theme }) => theme.colors.fill.default1};
    padding: ${({ theme }) => theme.space.x4};
    border-radius: ${({ theme }) => theme.radii.default};
    border: solid 1px ${({ theme }) => theme.colors.border.default2};
    position: fixed;
    top: ${({ top }) => top || '50%'};
    transform: ${({ isEmailTemplate }) =>
        isEmailTemplate ? 'translate(50%, -50%)' : 'translateY(-50%)'};
    right: ${({ isEmailTemplate, right }) =>
        right || (isEmailTemplate ? '50%' : '290px')};
    z-index: 9993;
    width: ${CONTAINER_WIDTH};
`;

const Header = styled(FlexDiv)`
    padding-bottom: 11px;
`;

const InputWrapper = styled(FlexDiv)`
    border: 1px solid ${({ theme }) => theme.colors.border.default1};
    height: 28px;
    align-items: center;
    border-radius: ${({ theme }) => theme.radii.default};
    width: auto;
    background-color: ${({ theme }) => theme.colors.fill.default2};
`;

const HexInputWrapper = styled(InputWrapper)`
    flex: 1;
    width: 80%;
    ${({ isOpacityEnabled }) =>
        isOpacityEnabled
            ? `
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    `
            : ''}
`;

const AlphaInputWrapper = styled(InputWrapper)`
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0;
    width: 50px;
`;

const HexInput = styled(HexColorInput)`
    outline: 0;
    background: transparent !important;
    box-shadow: none !important;
    border: 0;
    color: ${({ theme }) => theme.colors.text.default.primary};
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.x2};
    line-height: ${({ theme }) => theme.lineHeights.x2};
    padding: 6px 8px;
    height: 28px;
`;

const StyledInput = styled.input`
    outline: 0;
    background: transparent !important;
    box-shadow: none !important;
    border: 0;
    color: ${({ theme }) => theme.colors.text.default.primary};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 8px;
    width: auto;
    height: 24px;
`;

const PickerWrapper = styled(FlexDiv)`
    position: relative;
    flex: 1;

    .react-colorful {
        width: 100%;
        .react-colorful__saturation {
            border-radius: ${({ theme }) => theme.radii.default};
            width: ${({ theme }) =>
                `calc(${CONTAINER_WIDTH} - ${theme.space.x4} - ${theme.space.x4})`};
            height: 120px;
        }
        .react-colorful__saturation-pointer {
            width: 10px;
            height: 10px;
            border: solid 1px #ffffff;
        }
        .react-colorful__hue-pointer,
        .react-colorful__alpha-pointer {
            width: 12px;
            height: 12px;
            border: solid 1px #ffffff;
        }

        .react-colorful__hue,
        .react-colorful__alpha {
            height: 12px;
            border-radius: 24px;
            margin-top: 14px;
            margin-left: ${({ eyeDropperIsSupported }) =>
                eyeDropperIsSupported ? '36px' : ''};
        }
        .react-colorful__alpha {
            margin-top: 12px;
        }
    }
`;

const GradientWrapper = styled(FlexDiv)`
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    padding-top: 12px;
    padding-bottom: 14px;
`;

const GradientStrip = styled(FlexDiv)`
    border-radius: 24px;
    flex: 1;
    height: 10px;
    min-height: 10px;
    background: ${({ color, color2 }) =>
        `linear-gradient(270deg, ${color2} -0.23%, ${color} 99.77%)`};
`;

const ColorCircle = styled(Box)`
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.border.default1};
    display: inline-block;
    ${({ isActive, theme }) =>
        isActive
            ? `
        &::after {
            content: ' ';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 2px solid ${theme.colors.border.other1};
        }
    `
            : ''}
`;

const CloseButton = styled(Btn)`
    padding: 0;
    text-transform: none;
    svg {
        fill: ${({ theme }) => theme.colors.text.default.primary};
    }
    &:hover {
        background: transparent;
        svg {
            fill: ${({ theme }) => theme.colors.text.default.primary};
        }
    }
`;

const PickerIcon = styled(StyledSvgSprite)`
    position: absolute;
    bottom: ${({ isOpacityEnabled }) => (isOpacityEnabled ? '8px' : '0px')};
    left: 0;
    cursor: pointer;
`;

const ColorBox = styled(FlexDiv)`
    flex: 1;
    max-width: 50%;
    border-radius: ${({ theme }) => theme.radii.x3};
    align-items: center;
    padding: 4px 8px;
    border: solid 1px transparent;
    ${({ isActive, theme }) =>
        isActive
            ? `
        border-color: ${theme.colors.border.default2};
        background-color: ${theme.colors.fill.other3};
    `
            : ''}
`;

const ClrName = styled(Text)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export default function ColorPickerV2({
    showInsidePortal = false,
    portalContainer,
    colorData,
    ...rest
}) {
    const { Portal } = usePortal({
        clickOutsideToHide: false,
        escToHide: false,
        containerId: portalContainer ? portalContainer : 'sidebarWrapper',
    });

    return showInsidePortal ? (
        <Portal>
            <ColorPicker colorData={colorData} {...rest} />
        </Portal>
    ) : (
        <ColorPicker colorData={colorData} {...rest} />
    );
}
