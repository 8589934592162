import identity from 'lodash/identity';
import React, { createContext, useContext, useMemo, useState } from 'react';

const FullScreen = createContext(null);

export const useFullScreenContext = (callback = identity) => {
    const ctx = useContext(FullScreen);

    // Return the up-to-date selected value
    const finalValue = callback(ctx);
    return useMemo(() => finalValue, [finalValue]);
};

export const FullScreenProvider = ({ children }) => {
    const [fullScreen, setFullScreen] = useState(false);
    return (
        <FullScreen.Provider
            value={{
                fullScreen,
                setFullScreen,
            }}
        >
            {children}
        </FullScreen.Provider>
    );
};

export default FullScreen;
