import Btn from 'atoms/Button/btn';
import LoaderV2 from 'components/general/LoaderV2';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import {
    Box,
    FlexDiv,
    UnstyledButton,
} from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import { Text } from 'foundations/themeV2/text';
import useDeviceInfo from 'hooks/useDeviceInfo';
import useToasts from 'hooks/useToasts';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVideosList } from 'store/actions/videoUplaoder';
import styled from 'styled-components';
import { CmDashboardAnalyticsTypes } from 'utils/constants/community/community';
import { TOAST_VERSION_2 } from 'utils/constants/toasts';
import firebaseLogger, { CmDashboardEvents } from 'utils/firebaseLogger';
import { featureMediaVideoFilter } from 'utils/mediaLibrary';
import UploadVideo from './UploadVideo';
import VideoLibrary from './VideoLibrary';

const HeaderData = {
    uploadVideo: {
        title: 'Upload Video',
        subTitle:
            'The video you will upload will be autoplayed in the session. You can upload video only in mp4 format. Maximum file size allowed is 5GB.',
        infoText:
            'Video will be compressed at 720p resolution to optimize performance. If 1080p Video quality is enabled for your event the original uncompressed video will be played.',
    },
    selectVideo: {
        title: 'Add from videos',
        subTitle:
            'Select a video from your uploaded videos on organization video library. This will not upload the video again and will be using the existing video only.',
        infoText: '',
    },
};

export default function SessionVideoPanel({
    onClose = Function,
    screenType = String,
    communityId = String,
    mapVideoToSession = Function,
    airmeetId = String,
    sessionObjDetails = {},
    isSummaryPage = false,
    isNOPreviewUpload = false,
    airmeetFormat,
    formData = {},
}) {
    const dispatch = useDispatch();
    const { errorToast } = useToasts();

    const [loading, setLoading] = useState(false);
    const [videosList, setVideosList] = useState([]);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [uploadStatus, setUploadStatus] = useState({ status: 'not-started' });
    const [
        shouldShowConfirmationModal,
        setShouldShowConfirmationModal,
    ] = useState(false);

    const { user: { id: uid } = {} } = useSelector(
        (state = {}) => state?.auth || {}
    );
    const { current: { manager_id } = {} } = useSelector(
        (state = {}) => state?.Community || {}
    );

    const {
        browser: { name: browser },
    } = useDeviceInfo();

    useEffect(() => {
        firebaseLogger.cmDash({
            category: CmDashboardAnalyticsTypes.sessions,
            event:
                CmDashboardEvents.Sessions[
                    screenType === 'uploadVideo'
                        ? 'upload_video_clicked'
                        : 'select_from_videos_clicked'
                ],
            eventData: {
                airmeetId,
                communityId,
                uid,
                user_role:
                    uid === manager_id ? 'Community Manager' : 'Event Manager',
                browser,
            },
        });
    }, []);

    const getVideos = useCallback(async () => {
        let videos = [];
        if (!communityId) {
            return videos;
        }

        setLoading(true);

        const info = await dispatch(
            getVideosList({
                entity_type: 'community',
                entity_id: communityId,
                include_data: true,
            })
        );

        if (info.error) {
            errorToast({
                title: 'Something went wrong..',
                version: TOAST_VERSION_2,
            });

            return videos;
        }

        if (info?.payload) {
            const videos = info.payload.MEDIA
                ? info.payload.MEDIA.meta_data
                : [];
            // sort initially based on created date
            videos.sort((a, b) => {
                let fieldA = a['created'];
                let fieldB = b['created'];
                if (fieldA < fieldB) {
                    return 1;
                }
                if (fieldA > fieldB) {
                    return -1;
                }
                return 0;
            });
            const filteredVideos = featureMediaVideoFilter(videos);
            setVideosList(filteredVideos);
        }

        setLoading(false);
        setShouldRefresh(false);
    }, [
        dispatch,
        errorToast,
        setLoading,
        setShouldRefresh,
        setVideosList,
        communityId,
    ]);

    useEffect(() => {
        getVideos();
    }, [getVideos]);

    const refreshGrid = useCallback(() => {
        setShouldRefresh(true);
    }, [setShouldRefresh]);

    const handleOnClose = () => {
        if (['uploading', 'finishing'].includes(uploadStatus.status)) {
            setShouldShowConfirmationModal(true);

            return;
        }

        onClose();
    };

    return (
        <FlexDiv flexDirection='column' flex={1} position='relative'>
            <Header screenType={screenType} onClose={handleOnClose} />
            {loading ? (
                <LoaderV2 isThemeV2={true} />
            ) : (
                <FlexDiv flexDirection='column' flex={1}>
                    {screenType === 'uploadVideo' && (
                        <UploadVideo
                            mapVideoToSession={mapVideoToSession}
                            onClose={onClose}
                            videosList={videosList}
                            refreshGrid={refreshGrid}
                            setUploadStatus={setUploadStatus}
                            shouldShowConfirmationModal={
                                shouldShowConfirmationModal
                            }
                            setShouldShowConfirmationModal={
                                setShouldShowConfirmationModal
                            }
                            isThemeV2={true}
                            sessionObjDetails={sessionObjDetails}
                            isSummaryPage={isSummaryPage}
                            isNOPreviewUpload={isNOPreviewUpload}
                            formData={formData}
                        />
                    )}
                    {screenType === 'selectVideo' && (
                        <VideoLibrary
                            videosList={videosList}
                            onClose={onClose}
                            mapVideoToSession={mapVideoToSession}
                            getVideos={getVideos}
                            shouldRefresh={shouldRefresh}
                            airmeetId={airmeetId}
                            isThemeV2={true}
                            airmeetFormat={airmeetFormat}
                        />
                    )}
                </FlexDiv>
            )}
        </FlexDiv>
    );
}

const Header = ({ screenType, onClose }) => {
    const { title = '', subTitle = '', infoText = '' } =
        HeaderData[screenType] || {};

    return (
        <>
            <Box>
                <FlexDiv textAlign='left' mb='x4'>
                    <BackButton
                        key='button'
                        variant='link'
                        onClick={onClose}
                        mr='x5'
                    >
                        <StyledSvgSprite
                            icon={'icon-goback'}
                            height={'24px'}
                            width={'24px'}
                            fill='text.default.primary'
                        />
                    </BackButton>
                    <Text variant='h2' color='text.default.primary'>
                        {title}
                    </Text>
                    <StyledButton onClick={onClose}>
                        <Icon
                            icon='dismiss'
                            width={20}
                            height={20}
                            fill='text.default.primary'
                        />
                    </StyledButton>
                </FlexDiv>
                <Text variant='caption' color='text.default.secondary' mb='x4'>
                    {subTitle}
                </Text>
                {infoText && (
                    <FlexDiv mb='x6'>
                        <Icon
                            icon='icon-info-outline'
                            minWidth='1rem'
                            width='1rem'
                            height='1rem'
                            fill='sem.warning'
                            mr='x2'
                        />
                        <Text variant='caption' color='text.default.secondary'>
                            {infoText}
                        </Text>
                    </FlexDiv>
                )}
            </Box>
        </>
    );
};
const BackButton = styled(Btn)`
    &:focus {
        box-shadow: none;
        outline: 0;
    }
    svg {
        fill: ${({ theme }) => theme.colors.text.default.primary};
    }
`;
const StyledButton = styled(UnstyledButton)`
    margin-left: auto;
`;
