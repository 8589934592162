import { Message, RealtimeActionTypes } from '../types';
import DataObserverBase from './DataObserverBase';
import LocalReaction from './LocalReaction';
import { v4 as uuid } from 'uuid';

class ReactionObserver extends DataObserverBase {
    subscribe(onData: Function, metaData: any) {
        if (!this.sessionId) {
            throw new Error('Invalid Channel Id');
        }

        const onAdd = (message: Message) => {
            onData(message);
        };

        const localReaction = LocalReaction.getInstance();
        const localReactionUnsubscribe = localReaction.subscribe((payload) => {
            const message: Message = {
                action: RealtimeActionTypes.ADDED,
                metadata: { key: uuid() },
                payload: payload,
            };
            onAdd(message);
        });

        const channel = `${this.airmeetId}/liveAirmeet/sessions/${this.sessionId}/reactions`;
        const dataSourceUnsubscribe = super.subscribeImpl(channel, { onAdd });

        return () => {
            dataSourceUnsubscribe() && localReactionUnsubscribe();
        };
    }
}

export default ReactionObserver;
