import { LogWithPrefixInterface } from './filterLogger';
import { localLogger } from './localLogger';

export default function PWLogger(prefix: string = '', color: string = 'green') {
    return new LogWithPrefixInterface({
        ...localLogger.options,
        logPrefix: prefix,
        color,
    });
}

export const pwLoggerV3 = PWLogger('PW-V3 :', '#00acee');
export const postPwLoggerV3 = PWLogger('postPW-V3 :', '#0080b3');
export const setupModalLoggerV3 = PWLogger('SetupModal-V3 :', '#99e2ff');

export const pwLogs = {
    showPermissionDenied: 'Moving to permission denied page',
    showSetupPreview: 'Moving to setup preview page',
    joinInListeningMode:
        'Joining event in listening mode without mic and camera',
};
