import { useMemo } from 'react';
import { emptyArray, emptyObject } from 'utils/constants/common';

export default function useRTMPKeyDetails({ airmeet }) {
    const { rtmp_key_details, sessions, tracks } = airmeet || emptyObject;

    // Format keyDetails
    const keyDetails = useMemo(() => {
        const sessionsDetails = (
            rtmp_key_details?.session_level_keys || emptyArray
        ).map((item) => {
            if (item?.session_id) {
                // Fill session name
                const session = (sessions || emptyArray).find(
                    (session) => session?.sessionid === item?.session_id
                );
                return {
                    ...(item || emptyObject),
                    session_name: session?.name,
                };
            }
            return item;
        });
        const trackDetails = tracks?.length
            ? (rtmp_key_details?.track_level_keys || emptyArray).map((item) => {
                  if (item?.track_id) {
                      // Fill track name
                      const track = tracks.find(
                          (track) => track?.uid === item.track_id
                      );
                      return {
                          ...(item || emptyObject),
                          track_name: track?.name,
                      };
                  }
                  return item;
              })
            : emptyArray;
        return {
            ...(rtmp_key_details || emptyObject),
            session_level_keys: sessionsDetails,
            track_level_keys: trackDetails,
        };
    }, [rtmp_key_details, sessions, tracks]);

    return keyDetails;
}
