import { HAND_RAISE_REQUEST_STATUS } from 'store/actions/handRaise';
import { LOG_LEVEL } from 'utils/constants/logger';

const RaiseHandDataWriter = ({
    sessionId,
    airmeetId,
    client,
    payload,
    metaData,
    logger,
}) => {
    const QOELog = logger.init('RAISE_HAND', 'green', LOG_LEVEL.INFO);
    const { userId, action } = metaData;
    const raiseHandPrefixKey = client
        ? `${airmeetId}/liveAirmeet/sessions/${sessionId}/raiseHands`
        : null;

    const requestStatusLog = (requestId, status, updatedStatus, actionType) => {
        QOELog(
            `${actionType}: Hand Raise request #[${requestId}] status update from ${status} to ${updatedStatus}: by user id :${userId} in session ${sessionId}`
        );
    };

    const actionHandler = {
        accepted: async ({ requestId }) => {
            await client.runTransaction(
                `${raiseHandPrefixKey}/${requestId}/status`,
                (status) => {
                    const updateStatus =
                        status === HAND_RAISE_REQUEST_STATUS.pending
                            ? HAND_RAISE_REQUEST_STATUS.accepted
                            : status;
                    requestStatusLog(
                        requestId,
                        status,
                        updateStatus,
                        'ACCEPTED'
                    );
                    return updateStatus;
                }
            );
        },
        active: async ({ requestId }) => {
            // If request still in accepted mode then allow to update state of request to active
            const { snapshot } = await client.runTransaction(
                `${raiseHandPrefixKey}/${requestId}/status`,
                (status) => {
                    const updateStatus =
                        status === HAND_RAISE_REQUEST_STATUS.accepted
                            ? HAND_RAISE_REQUEST_STATUS.active
                            : status;
                    requestStatusLog(requestId, status, updateStatus, 'ACTIVE');
                    return updateStatus;
                }
            );
            return snapshot.val() === HAND_RAISE_REQUEST_STATUS.active;
        },
        activeHandler: async ({ requestId }) => {
            await client.runTransaction(
                `${raiseHandPrefixKey}/${requestId}/status`,
                (status) => {
                    const updateStatus = [
                        HAND_RAISE_REQUEST_STATUS.accepted,
                        HAND_RAISE_REQUEST_STATUS.active,
                    ].includes(status)
                        ? HAND_RAISE_REQUEST_STATUS.completed
                        : status;
                    requestStatusLog(
                        requestId,
                        status,
                        updateStatus,
                        'ACTIVE_HANDLER'
                    );
                    return updateStatus;
                }
            );
        },
        rejected: async ({ requestId }) => {
            await client.runTransaction(
                `${raiseHandPrefixKey}/${requestId}/status`,
                (status) => {
                    const updateStatus = [
                        HAND_RAISE_REQUEST_STATUS.pending,
                        HAND_RAISE_REQUEST_STATUS.accepted,
                    ].includes(status)
                        ? HAND_RAISE_REQUEST_STATUS.declined
                        : status;
                    requestStatusLog(
                        requestId,
                        status,
                        updateStatus,
                        'REJECTED'
                    );
                    return updateStatus;
                }
            );
        },
        leave: async ({ requestId }) => {
            await client.runTransaction(
                `${raiseHandPrefixKey}/${requestId}/status`,
                (status) => {
                    const updateStatus = [
                        HAND_RAISE_REQUEST_STATUS.pending,
                        HAND_RAISE_REQUEST_STATUS.accepted,
                        HAND_RAISE_REQUEST_STATUS.active,
                        HAND_RAISE_REQUEST_STATUS.leaveRequest,
                    ].includes(status)
                        ? HAND_RAISE_REQUEST_STATUS.completed
                        : status;
                    requestStatusLog(requestId, status, updateStatus, 'LEAVE');
                    return updateStatus;
                }
            );
        },
        request: async ({ errorCallback, user }) => {
            const ref = client.ref(`${raiseHandPrefixKey}`);
            const requestId = ref.push().getKey();

            QOELog(
                `REQUEST: Hand Raise request #[${requestId}]: requested by user id :${user?.id} in session ${sessionId}`
            );
            try {
                await client.setData(`${raiseHandPrefixKey}/${requestId}`, {
                    sessionId,
                    user,
                    requestId,
                    status: HAND_RAISE_REQUEST_STATUS.pending,
                });
            } catch (error) {
                QOELog(
                    `Error: ${error?.message} making raise hand request #[${requestId}]: requested by user id :${user?.id} in session ${sessionId}`
                );
                errorCallback && errorCallback(error);
                return;
            }

            return requestId;
        },
        withdraw: async ({ requestId }) => {
            await client.runTransaction(
                `${raiseHandPrefixKey}/${requestId}/status`,
                (status) => {
                    const updateStatus = [
                        HAND_RAISE_REQUEST_STATUS.pending,
                    ].includes(status)
                        ? HAND_RAISE_REQUEST_STATUS.withdraw
                        : status;
                    requestStatusLog(
                        requestId,
                        status,
                        updateStatus,
                        'WITHDRAW'
                    );
                    return updateStatus;
                }
            );
        },
        leaveRequest: async ({ requestId, channelName, requestUser }) => {
            await client.runTransaction(
                `${raiseHandPrefixKey}/${requestId}/status`,
                (status) => {
                    // Remove the firebase node during a leave request
                    const channelPublisherKey = channelName
                        ? `${airmeetId}/meta-data/broadcastChannel/${channelName}/channelPublisher`
                        : null;

                    if (
                        requestUser &&
                        requestUser.id_seq &&
                        channelPublisherKey
                    ) {
                        client.setData(
                            `${channelPublisherKey}/${requestUser.id_seq}`,
                            null
                        );
                    }
                    const updateStatus = [
                        HAND_RAISE_REQUEST_STATUS.active,
                        HAND_RAISE_REQUEST_STATUS.accepted,
                    ].includes(status)
                        ? HAND_RAISE_REQUEST_STATUS.leaveRequest
                        : status;
                    requestStatusLog(
                        requestId,
                        status,
                        updateStatus,
                        'LEAVE_REQUEST'
                    );
                    return updateStatus;
                }
            );
        },
        bindOffLineEvent: ({ requestId, status, requestedUserId }) => {
            // Here only bind the user request
            if (requestedUserId !== userId) {
                return;
            }

            const key = `${raiseHandPrefixKey}/${requestId}/status`;
            // Cancel the bind previous disconnect event added on this node
            client.cancelOnDisconnect(key);

            if (
                [
                    HAND_RAISE_REQUEST_STATUS.active,
                    HAND_RAISE_REQUEST_STATUS.leaveRequest,
                ].includes(status)
            ) {
                client.setOnDisconnect(
                    key,
                    HAND_RAISE_REQUEST_STATUS.completed
                );
            } else if (
                [
                    HAND_RAISE_REQUEST_STATUS.pending,
                    HAND_RAISE_REQUEST_STATUS.accepted,
                ].includes(status)
            ) {
                client.setOnDisconnect(key, HAND_RAISE_REQUEST_STATUS.withdraw);
            }
        },
    };

    return actionHandler[action](payload);
};

export default RaiseHandDataWriter;
