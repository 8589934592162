import { defaultColor } from 'foundations/theme';

export const DEFAULT_BRAND_LOGO = 'venue_settings_default_logo_image';

export const DEFAULT_TENANT_DETAILS = {
    id: 'airmeet',
    name: 'Airmeet',
    shortName: 'Airmeet',
    baseUrls: ['airmeet.com'],
    logoUrl: '/images/airmeet-logo.svg',
    logoLightThemeUrl: '/images/airmeet-logo-light.svg',
    logoNoTextUrl: '/images/airmeet-logo-notext.svg',
    logoOnlyTextUrl: '/images/airmeet-logo-onlytext-white.svg',
    logoOnlyTextLightThemeUrl: '/images/airmeet-logo-onlytext-dark.svg',
    primaryColor: defaultColor,
    website: 'airmeet.com',
};

export const TENANT_DETAILS = [
    {
        id: 'ringCentral',
        name: 'RingCentral Events',
        shortName: 'RingCentral',
        baseUrls: [
            'ringcentral.airmeet.com',
            'ringcentral-preprod.airmeet.com',
        ],
        logoUrl: '/images/tenants/ringCentral/logo.svg',
        logoLightThemeUrl: '/images/tenants/ringCentral/logo.svg',
        logoNoTextUrl: '/images/tenants/ringCentral/logo-no-text.png',
        logoOnlyTextUrl: '/images/tenants/ringCentral/logo.svg',
        logoOnlyTextLightThemeUrl: '/images/tenants/ringCentral/logo.svg',
        primaryColor: { H: 202, S: 93, L: 35 },
        website: 'ringcentral.com',
    },
];
