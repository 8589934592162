import FormField from 'components/community/common/FormField';
import { Box } from 'components/molecules/utils/basicComponents';
import { getCustomScroll } from 'components/molecules/utils/customStyles';
import React from 'react';
import styled from 'styled-components';
import { StyledSvgSprite } from '../../../../general/SVGSprite';
import DatePicker from 'components/general/Form/DateTimePicker';

export const CreateAirmeetDate = ({
    value,
    name,
    id,
    label,
    setTypeDateTime,
    newOnBoardingFlow = false,
    pageMode = false,
    ...props
}) => (
    <StyledBox
        onClick={() => {
            setTypeDateTime('date');
        }}
    >
        <FormFieldWIcon
            id={id}
            name={name}
            custom
            variant={'v2'}
            label={label}
            subLabel=''
            filledValue={value}
            newOnBoardingFlow={newOnBoardingFlow}
            inputValue={pageMode ? value : null}
            isThemeV2={true}
        >
            <DatePicker {...props} defaultDate={value} name={name} />
            <StyledSvgSprite
                position={'absolute'}
                top={'12px'}
                right={'18px'}
                icon={'calendar-icon'}
                height={16}
                width={16}
                fill={'ambience.8'}
                style={{
                    pointerEvents: 'none',
                }}
            />
        </FormFieldWIcon>
    </StyledBox>
);

const StyledBox = styled(Box)`
    width: 100%;
    max-width: 250px;
    margin-right: 25px;
    &:last-child {
        margin-right: 0;
    }
    .custom-time-dropdown {
        width: 100%;
        max-width: 100%;
        z-index: 2;
        .react-datepicker {
            font-family: ${({ theme }) => theme.fonts.book};
            background-color: ${({ theme }) => theme.colors.ambience[1]};
            border-radius: 8px;
            overflow: hidden;
            border: 0;
            width: 100%;
            .react-datepicker__time-container {
                width: 100%;
                .react-datepicker__header--time {
                    display: none;
                }
                .react-datepicker__time {
                    background-color: ${({ theme }) =>
                        theme.colors.ambience[1]};
                    border-radius: 0;
                    .react-datepicker__time-box {
                        width: 100%;
                        .react-datepicker__time-list {
                            ${({ theme, forceDarkMode }) =>
                                getCustomScroll({ theme, forceDarkMode })};
                            max-height: 164px;
                            overflow-y: auto;
                            padding: 16px 0;
                            .react-datepicker__time-list-item {
                                width: 100%;
                                margin: 0;
                                padding: 10px 16px;
                                height: unset;
                                font-size: 14px;
                                line-height: 16px;
                                text-align: left;
                                transition: all 0.3s ease-in-out;
                                text-transform: lowercase;
                                color: ${({ theme }) =>
                                    theme.colors.ambience[18]};
                                &.react-datepicker__time-list-item--disabled {
                                    opacity: 0.5;
                                    &:hover {
                                        background-color: transparent;
                                    }
                                }
                                &.react-datepicker__time-list-item {
                                    background-color: transparent;
                                }
                                &:first-child {
                                    padding-top: 0;
                                }
                                &:last-child {
                                    padding-bottom: 0;
                                }
                                &:hover {
                                    background-color: ${({ theme }) =>
                                        theme.colors.ambience[2]};
                                }
                            }
                        }
                    }
                }
            }
            .react-datepicker__triangle {
                display: none;
            }
        }
    }
`;

const FormFieldWIcon = styled(FormField)`
    ${({ forTimePicker }) =>
        forTimePicker &&
        `
    &.form-field {
        padding:0 !important;
    }
    .react-datepicker__input-container {
        input {
            cursor: pointer;
        }
    }
    `};
    background-color: ${({ theme }) => theme.colors.ambience[22]} !important;
    &.form-field {
        cursor: pointer;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker__input-container {
        position: relative;
        input {
            color: ${({ theme }) => theme.colors.ambience[2]} !important;
            font-family: ${({ theme }) => theme.fonts.book} !important;
            font-size: 14px !important;
        }
    }
`;
