import ReportingContext from 'context/ReportingContext';
import { CONTEXT_TYPE } from 'hooks/attendance/constants';
import { usePrevious } from 'hooks/common';
import useDataWriter from 'hooks/useDataWriter';
import { ALL_FEATURES_CONFIG } from 'hooks/useFeatureControl';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import debounce from 'lodash/debounce';
import { useCallback, useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { QueueType } from 'services/realtime/types';
import { getTotalUsersCount } from 'store/selectors';
import {
    FEATURE_NAMES,
    PRESENCE_ACTION_TYPE,
} from 'utils/constants/featureNames';
import { getUserInfo } from 'utils/constants/users';
import { isAttendee } from 'utils/userAccessControl';
import { updateUserPresenceMembers } from '../../store/actions/liveAirmeet';
import { bindModuleLogger } from 'utils/logger';

const logger = bindModuleLogger('useUserPresence Hook');

const ACTIONS = {
    ADDED: 1,
    CHANGE: 2,
    REMOVE: 3,
};

const EMPTY_LIST = {};

export { CONTEXT_TYPE };

function getRandomInt(max) {
    return Math.floor(Math.random() * max) + 1;
}

const getDelayForSetUser = (count) => {
    if (count <= 100) {
        return getRandomInt(5000);
    } else if (count <= 200) {
        return getRandomInt(15000);
    } else if (count <= 500) {
        return getRandomInt(30000);
    } else if (count <= 2000) {
        return getRandomInt(60000);
    } else {
        return getRandomInt(120000);
    }
};

const liveAirmeetCallback = ({
    airmeet,
    firebaseClient,
    featureDataClients,
    user,
    featuresConfig,
    platformConfig,
}) => {
    return {
        airmeetId: airmeet.airmeetId,
        firebaseClient,
        featureDataClients,
        isConnected: airmeet.isConnected,
        user,
        userId: user?.id,
        featuresConfig,
        platformConfig,
    };
};

export const getMemberInContextKey = (key, context = '') => {
    return context ? `${context}-${key}` : key;
};
export const getMembersInContextPath = (airmeetId, key, context = '') => {
    return `${airmeetId}/userPresence/${getMemberInContextKey(key, context)}`;
};

const getSessionStatusPath = (airmeetId, sessionId) => {
    return `${airmeetId}/meta-data/sessions/${sessionId}/liveStatus`;
};

export const clearUserPresenceContext = ({
    airmeetId,
    userPresenceKey,
    firebaseClient,
    context,
    delay,
}) => {
    const userPresencePath = getMembersInContextPath(
        airmeetId,
        userPresenceKey,
        context
    );
    logger.info(`Clearing presence data for session ${userPresenceKey}`);
    firebaseClient.setDataAsync(userPresencePath, null, { delay });
};

export const MAX_ALLOWED_LIMIT = 5000;
function useUserPresence(inContextKey, props = {}) {
    const { context, shouldAddSelf = true, shouldAddListener = false } = props;

    let { limit } = props;
    if (!limit) {
        limit = context === CONTEXT_TYPE.EVENT ? MAX_ALLOWED_LIMIT : 1000;
    }

    const reportingContext = useContext(ReportingContext);
    const {
        airmeetId,
        firebaseClient,
        isConnected,
        userId,
        user,
        featuresConfig,
        platformConfig,
        featureDataClients: {
            [FEATURE_NAMES.USER_PRESENCE]: userPresenceClient,
        },
    } = useLiveAirmeetContext(liveAirmeetCallback);

    const dispatch = useDispatch();
    const people = useRef(new Map());

    const store = useStore();
    const userPresenceKey = inContextKey
        ? getMemberInContextKey(inContextKey, context)
        : '';
    const userPresencePath = inContextKey
        ? getMembersInContextPath(airmeetId, inContextKey, context)
        : null;
    const userPath =
        userId && userPresencePath ? `${userPresencePath}/${userId}` : null;
    const userPresenceValue = useRef();

    const userEnteredAt = useRef(null);

    let dataWriterConfig =
        platformConfig &&
        platformConfig[ALL_FEATURES_CONFIG.DATA_WRITER_CONFIG];

    if (
        featuresConfig &&
        featuresConfig[ALL_FEATURES_CONFIG.DATA_WRITER_CONFIG]
    ) {
        dataWriterConfig =
            featuresConfig[ALL_FEATURES_CONFIG.DATA_WRITER_CONFIG];
    }

    const isPresenceWriterEnabled =
        dataWriterConfig?.applyToAll ||
        Object.keys(dataWriterConfig?.features || {}).includes(
            FEATURE_NAMES.USER_PRESENCE
        );

    const isConnectedRef = useRef(isConnected);
    isConnectedRef.current = isConnected;
    const getAirmeetUserInfo = useCallback(() => {
        const { is_custom_registration_enabled } =
            store.getState()['lounge']?.['airmeet'] || {};
        return getUserInfo(user, {}, is_custom_registration_enabled);
    }, [store, user]);

    const { write } = useDataWriter(FEATURE_NAMES.USER_PRESENCE, {
        useFrontendWriterFallback: true,
        queueType: QueueType.GLOBAL,
    });
    const writeRef = useRef();
    writeRef.current = write;

    const methodsRef = useRef({});
    const onlineUsersCount = useRef(0);
    const fetchLastKnownContext = () => {
        return new Promise((resolve) => {
            userPresenceClient.getDataOnce(
                `${airmeetId}/userPresence/${user.id}`,
                ({ value }) => {
                    if (
                        value &&
                        value.disconnectTime &&
                        value.contextId &&
                        value.context
                    ) {
                        const lastKnownContext =
                            value.contextId !== inContextKey
                                ? {
                                      context: value.context,
                                      contextId: value.contextId,
                                  }
                                : null;
                        resolve(lastKnownContext);
                    } else {
                        resolve(null);
                    }
                }
            );
        });
    };
    onlineUsersCount.current = useSelector(getTotalUsersCount);
    methodsRef.current.setSelfIn = async (callback) => {
        if (!shouldAddSelf) return;

        // 1. Set ourself into the presence user list
        const payload = {
            isOnline: true,
            metadata: {
                ...getAirmeetUserInfo(),
            },
            time: firebaseClient.getServerTimestampRef(),
        };

        // only run when presence data writer is enabled and entering event,
        // it will automatically take care of exiting whatever current context is at the time of exit
        let lastKnownContext = null;
        if (
            isPresenceWriterEnabled === true &&
            context === CONTEXT_TYPE.EVENT
        ) {
            userPresenceClient.replaceOnDisconnect(
                `${airmeetId}/userPresence/${user.id}/disconnectTime`,
                userPresenceClient.getServerTimestampRef()
            );
            lastKnownContext = await fetchLastKnownContext();
        }

        writeRef
            .current(payload, {
                context,
                contextId: inContextKey,
                userId,
                isAttendee: isAttendee(),
                actionType: PRESENCE_ACTION_TYPE.JOIN,
                residualContext: lastKnownContext,
            })
            .then((response) => {
                logger.info('Added to presence attendee list', {
                    contextId: inContextKey,
                    context,
                });
                userEnteredAt.current = Date.now();
                callback && callback(null, response);
            })
            .catch((error) => {
                logger.error('Failed to add to presence attendee list', error, {
                    message: error.message,
                    contextId: inContextKey,
                    context,
                });

                if (reportingContext && error) {
                    reportingContext.reportIncident({
                        tag: 'Firebase',
                        message: 'Attendee failed to set value',
                        meta: {
                            message: error.message,
                        },
                        config: {
                            fatalThreshold: 0,
                        },
                    });
                }
                callback && callback(error);
            });
    };

    const initialLoadCompletedRef = useRef(false);
    const buffer = useRef([]);

    const onUsersChanged = () => {
        if (false === initialLoadCompletedRef.current) {
            logger.debug(
                `Presence Users Changed waiting for initial load to finish ${userId}`,
                {
                    contextId: inContextKey,
                    context,
                }
            );
            return false;
        }

        const bufferedUpdates = [...buffer.current];
        buffer.current.length = 0;
        logger.debug('Running presence user changed', bufferedUpdates.length, {
            contextId: inContextKey,
            context,
        });

        if (!bufferedUpdates?.length) {
            return;
        }

        let initialValues = people.current;
        const results = bufferedUpdates.reduce((acc, update) => {
            // snapshot updates do not have incremental key as we do not iterate over it to save CPU cycles
            const it = update.value;
            const action = update.action;
            if (!it) {
                return acc;
            }
            if (action === ACTIONS.REMOVE) {
                acc.delete(it.id);
            } else {
                //TODO: timestamp field to be removed
                acc.set(it.id, it);
            }
            return acc;
        }, initialValues);
        people.current = results;

        ReactDOM.unstable_batchedUpdates(() => {
            const members = Object.fromEntries(people.current.entries());
            dispatch(updateUserPresenceMembers(userPresenceKey, members));
        });
    };

    const onUsersChangedRef = useRef();
    onUsersChangedRef.current = onUsersChanged;

    // Handle user changes on firebase
    useEffect(() => {
        if (!userPresenceClient || !userPresencePath || !shouldAddListener) {
            return;
        }

        const debouncedChange = debounce(() => {
            onUsersChangedRef.current();
        }, 300);

        const onBufferChange = (applyNow = false) => {
            if (buffer.current.length > 2000 || applyNow) {
                onUsersChangedRef.current();
            } else {
                debouncedChange();
            }
        };

        const onAction = (key, value, action) => {
            buffer.current.push({
                value: {
                    id: key,
                    ...(value || {}),
                },
                action,
            });
            onBufferChange();
        };

        const onUserAdded = (...args) => {
            onAction(args[0].key, args[0].val(), ACTIONS.ADDED);
        };

        const onUserChanged = (...args) => {
            onAction(args[0].key, args[0].val(), ACTIONS.CHANGE);
        };

        const onUsersRemoved = (...args) => {
            onAction(args[0].key, args[0].val(), ACTIONS.REMOVE);
        };

        let ref = userPresenceClient
            .ref(userPresencePath)
            .limitToFirst(
                limit > 0 && limit < MAX_ALLOWED_LIMIT
                    ? limit
                    : MAX_ALLOWED_LIMIT
            )
            .orderByChild('time');

        ref.on('child_added', onUserAdded);
        ref.on('child_changed', onUserChanged);
        ref.on('child_removed', onUsersRemoved);

        initialLoadCompletedRef.current = true;

        return () => {
            if (!userPresenceClient) {
                return;
            }
            ref.off('child_added', onUserAdded);
            ref.off('child_changed', onUserChanged);
            ref.off('child_removed', onUsersRemoved);
        };
    }, [
        userPresenceKey,
        dispatch,
        userPresenceClient,
        userPresencePath,
        limit,
        shouldAddListener,
    ]);

    useEffect(() => {
        let didAddSelf;
        if (!shouldAddSelf) {
            return;
        }
        if (userPath) {
            let unmount = false;
            const checkSelfValue = ({ value }) => {
                const key = userId;
                let action = ACTIONS.ADDED;
                if (!value) {
                    if (userPresenceValue?.current) {
                        logger.info(
                            `Value removed from presence attendee list for ${key}`,
                            {
                                contextId: inContextKey,
                                context,
                            }
                        );
                    }
                    action = ACTIONS.REMOVE;
                    methodsRef.current.setSelfIn();
                }

                userPresenceValue.current = {
                    id: key,
                    ...(value || {}),
                };
                // Add this to user to list for shown in list if already there have more then 500
                buffer.current.push({
                    value: userPresenceValue.current,
                    action,
                });
                onUsersChangedRef.current();
            };

            const delay = isAttendee()
                ? getDelayForSetUser(onlineUsersCount.current)
                : 1;

            let timeout = setTimeout(() => {
                // Load refs
                methodsRef.current.setSelfIn(() => {
                    if (unmount) {
                        if (didAddSelf) {
                            logger.info(
                                'user presence - cleaning up the self user presence data in case of unmount',
                                {
                                    unmount,
                                    context,
                                }
                            );
                            writeRef.current(null, {
                                context,
                                contextId: inContextKey,
                                userId,
                                actionType: PRESENCE_ACTION_TYPE.LEAVE,
                            });
                        }
                        logger.info(
                            'user presence - skip to attach the listener which is already detached',
                            {
                                unmount,
                                context,
                            }
                        );
                        return;
                    }
                    firebaseClient.getDataSync(userPath, checkSelfValue);
                });

                didAddSelf = true;
            }, delay);

            return () => {
                unmount = true;
                try {
                    let sessionStatus = {};
                    if (context === CONTEXT_TYPE.STAGE) {
                        firebaseClient.getDataSync(
                            getSessionStatusPath(airmeetId, inContextKey),
                            ({ value }) => {
                                sessionStatus = value;
                            }
                        );
                    }
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                    dispatch(
                        updateUserPresenceMembers(userPresenceKey, EMPTY_LIST)
                    );
                    firebaseClient.clearDataSync(userPath, checkSelfValue);

                    // When presence writer is enabled, event leave is already handled by cloud function.
                    if (
                        isPresenceWriterEnabled === true &&
                        context === CONTEXT_TYPE.EVENT
                    ) {
                        return;
                    }

                    const isLeavingAnEndedSession =
                        sessionStatus?.lastStopAt < userEnteredAt.current;

                    if (isLeavingAnEndedSession) {
                        logger.info(
                            `${userId} leaving an already ended session ${inContextKey}`
                        );
                    }

                    const isSessionStopped =
                        sessionStatus?.status === 'stop' &&
                        !isLeavingAnEndedSession;

                    if (didAddSelf) {
                        writeRef.current(null, {
                            context,
                            contextId: inContextKey,
                            isSessionStopped,
                            isAttendee: isAttendee(),
                            userId,
                            actionType: PRESENCE_ACTION_TYPE.LEAVE,
                        });
                    }
                } catch (error) {}
            };
        }
    }, [
        userPresencePath,
        userPath,
        firebaseClient,
        dispatch,
        userPresenceKey,
        context,
        userId,
        inContextKey,
        shouldAddSelf,
        isPresenceWriterEnabled,
    ]);

    const previousHash = usePrevious(user?.hash);
    const userHash = user?.hash;
    useEffect(() => {
        if (
            userPath &&
            isConnectedRef.current &&
            previousHash &&
            previousHash !== userHash
        ) {
            logger.debug('User updated Hash', inContextKey, userHash);
            firebaseClient.runTransaction(`${userPath}/metadata`, (value) => {
                return value ? getAirmeetUserInfo() : value;
            });
        }
    }, [userHash]);
}

export default useUserPresence;
