import { DELAY_TIME_IN_DAYS } from 'components/conferenceTrial/constants';
import { MODAL_TYPE_CONFERENCE_TRIAL } from 'components/modals/constants';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showFixedModal } from 'store/actions/layout';
import { IS_CUSTOM_TENANT } from 'utils/tenant';
import useLeadEvents from './leads/useLeadEvents';
import { CREATE_LEAD, UPDATE_LEAD } from './leads/utils';
import useIpMetaInfo from './useIpMetaInfo';

function useConferenceTrialLeadHandler() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const {
        handleLeadOperation,
        getLeadDataFromStore,
        eventTypes,
    } = useLeadEvents();

    const {
        conferenceTrialContactLead: CONFERENCE_TRIAL_CONTACT_LEAD,
        conferenceTrialLiveDemo: CONFERENCE_TRIAL_LIVE_DEMO,
        conferenceTrialEventCreated: CONFERENCE_TRIAL_EVENT_CREATED,
    } = eventTypes;

    const leads = getLeadDataFromStore(CONFERENCE_TRIAL_CONTACT_LEAD);
    const seeDemoLead = getLeadDataFromStore(CONFERENCE_TRIAL_LIVE_DEMO);
    const createTrialLead = getLeadDataFromStore(
        CONFERENCE_TRIAL_EVENT_CREATED
    );

    const [loading, setLoading] = useState(false);
    const { queryMetaInfo } = useIpMetaInfo({ isAuto: false });

    useEffect(() => {
        handleLeadOperation({
            leadName: CONFERENCE_TRIAL_CONTACT_LEAD,
            setLoading,
        });
        handleLeadOperation({
            leadName: CONFERENCE_TRIAL_LIVE_DEMO,
            setLoading,
        });
        handleLeadOperation({
            leadName: CONFERENCE_TRIAL_EVENT_CREATED,
            setLoading,
        });
    }, []);

    /* 
        Used to check if given number of days have passed 
        since previous click time
    */
    const isVisibleAfterDelay = (clickTime = null) => {
        if (clickTime) {
            const currentDate = new Date();
            const currentTimeInMs = currentDate.getTime();
            const buttonClickTimeInMs = moment
                .duration(DELAY_TIME_IN_DAYS, 'days')
                .asMilliseconds();
            return currentTimeInMs > clickTime + buttonClickTimeInMs;
        }
        return true;
    };

    /*
        Renderes a component based on close button status
    */
    const shouldShowComponent = () => {
        const closeButton =
            (!isEmpty(leads) &&
                leads[0].metadata?.hasOwnProperty('closeButtonClicked') &&
                leads[0].metadata?.closeButtonClicked) ||
            false;
        if (!loading && hasContactSalesClicked()) {
            return false;
        } else if (!loading && !closeButton) {
            return true;
        } else if (
            closeButton &&
            isVisibleAfterDelay(leads[0]?.metadata?.closeButtonClickTime)
        ) {
            return true;
        }
        return false;
    };

    const hasContactSalesClicked = () => {
        const isLeadsPresent = !isEmpty(leads);
        if (isLeadsPresent && !loading) {
            const isContactSales = leads[0]?.value === 'true' ? true : false;
            return isContactSales;
        }
        return !loading ? false : true;
    };

    const shouldShowLiveBanner = () => {
        const closeButton =
            (!isEmpty(leads) &&
                leads[0].metadata?.hasOwnProperty('closeButtonClicked') &&
                leads[0].metadata?.closeButtonClicked) ||
            false;
        if ((!closeButton || !hasContactSalesClicked()) && !loading) {
            return true;
        }
        return false;
    };

    const contactUsModalHandler = (isLightThemeModal = false) => {
        updateUserLeads();
        dispatch(
            showFixedModal(MODAL_TYPE_CONFERENCE_TRIAL, {
                imagePath: 'images/community/success.svg',
                mainText: 'Thank you for your interest',
                imageAlt: 'Success',
                subText: `Our sales team will reach out to you.${
                    IS_CUSTOM_TENANT
                        ? ''
                        : ' <br/>  For further queries, contact us at <br/> <a href="mailto:support@airmeet.com" target="_blank">support@airmeet.com</a> '
                }`,
                subTextVarient: 'body2',
                mainTextVarient: 'subtitle1',
                btnText: 'OK',
                btnClick: () => {},
                isLightThemeModal,
            })
        );
    };

    const fetchCountryInfo = async (isLeadsPresent, leads) => {
        if (
            !isLeadsPresent ||
            (isLeadsPresent && !leads[0].metadata?.hasOwnProperty('country'))
        ) {
            const { country } = await queryMetaInfo();
            return country;
        }
    };

    const updateUserLeads = async (
        contactedSales = true,
        closeButtonClicked = false
    ) => {
        const currentDate = new Date();
        const currentTimeInMs = currentDate.getTime();
        const isLeadsPresent = !isEmpty(leads);
        let metadata = {
            closeButtonClicked: closeButtonClicked,
        };

        if (closeButtonClicked) {
            metadata = Object.assign(metadata, {
                closeButtonClickTime: currentTimeInMs,
            });
        } else if (contactedSales) {
            metadata = Object.assign(metadata, {
                contactSalesClickTime: currentTimeInMs,
            });
        }

        // Fetch country info if not already exists in lead data
        const country = await fetchCountryInfo(isLeadsPresent, leads);
        if (country) {
            metadata = {
                ...metadata,
                country,
            };
        }

        if (isLeadsPresent) {
            const leadsData = leads.map((lead) => {
                return {
                    ...lead,
                    value: contactedSales,
                    metadata: { ...lead.metadata, ...metadata },
                };
            });
            const payload = {
                leads: leadsData,
            };
            handleLeadOperation({
                leadName: CONFERENCE_TRIAL_CONTACT_LEAD,
                payload,
                operation: UPDATE_LEAD,
            });
        } else {
            const payload = {
                leads: [
                    {
                        email: user?.email,
                        metadata: metadata,
                        name: CONFERENCE_TRIAL_CONTACT_LEAD,
                        value: contactedSales,
                    },
                ],
            };
            handleLeadOperation({
                leadName: CONFERENCE_TRIAL_CONTACT_LEAD,
                payload,
                operation: CREATE_LEAD,
            });
        }
    };

    const handleNewLeads = async (leadName, leadState, leadValue) => {
        let metadata = {};
        const isLeadsPresent = !isEmpty(leadState);
        const country = await fetchCountryInfo(isLeadsPresent, leadState);
        if (country) {
            metadata = {
                country,
            };
        }
        if (!isLeadsPresent) {
            const payload = {
                leads: [
                    {
                        email: user?.email,
                        metadata: metadata,
                        name: leadName,
                        value: leadValue,
                    },
                ],
            };
            handleLeadOperation({ leadName, payload, operation: CREATE_LEAD });
        }
    };

    const handleSeeDemoLead = async () => {
        if (isEmpty(seeDemoLead)) {
            handleNewLeads(CONFERENCE_TRIAL_LIVE_DEMO, seeDemoLead, true);
        }
    };

    const handleCreateTrialLead = async () => {
        if (isEmpty(createTrialLead)) {
            handleNewLeads(
                CONFERENCE_TRIAL_EVENT_CREATED,
                createTrialLead,
                true
            );
        }
    };

    return {
        updateUserLeads,
        contactUsModalHandler,
        shouldShowComponent,
        handleCreateTrialLead,
        handleSeeDemoLead,
        shouldShowLiveBanner: shouldShowLiveBanner(),
        hasContactSalesClicked: hasContactSalesClicked(),
    };
}

export default useConferenceTrialLeadHandler;
