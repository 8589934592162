import Checkbox from 'atoms/Checkbox/Checkbox';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import get from 'lodash/get';
import React, { useEffect, useMemo, useState } from 'react';
import useChartFilterData from '../hooks/useChartFilterData';
import { CardHeader, CardTitle } from '../styledComponents';
import BarChart from './BarChart';
import ChartCard from './ChartCard';
import Pill from './Pill';
import SearchFilter from './SearchFilter';
import StatBox from './StatBox';

export default function SelectiveBarChartCard({
    searchTags,
    searchOptions,
    title,
    icon,
    type,
    yAxisLabel = 'Number of people',
    topDataInfo,
    endpoint = 'session-analytics',
    getRequest,
    timezone,
    dataInfo,
    perDayDataPath,
}) {
    const [selectedTypes, setSelectedTypes] = useState([]);

    useEffect(() => {
        if (Array.isArray(dataInfo) && dataInfo.length > 0) {
            setSelectedTypes(dataInfo.map((item) => item?.key));
        }
    }, [dataInfo]);

    const {
        loading,
        selectedOptions,
        setSelectedOptions,
        isDropdownVisible,
        setIsDropdownVisible,
        topData,
        data,
        pills,
        selectedPill,
        setSelectedPill,
    } = useChartFilterData({
        endpoint,
        topDataInfo,
        getRequest,
        timezone,
        perDayDataPath,
    });

    const handleClick = ({ name, value }) => {
        let newSelectedTypes = [...(selectedTypes || [])];
        if (value) {
            newSelectedTypes.push(name);
        } else {
            newSelectedTypes = newSelectedTypes.filter((val) => name !== val);
        }
        setSelectedTypes(newSelectedTypes);
    };

    const { isEmptyChart } = useMemo(() => {
        return {
            isEmptyChart:
                selectedTypes.length > 0 &&
                (data || []).length === 0 &&
                selectedOptions.length === 0,
        };
    }, [data, selectedTypes, selectedOptions]);

    const { chartData, chartLabels, backgroundColor } = useMemo(() => {
        const chartData = [];
        const chartLabels = [];
        const backgroundColor = [];

        (dataInfo || []).forEach((item) => {
            if ((selectedTypes || []).includes(item?.key)) {
                const value = get(data, `${item?.key}`) || 0;
                chartData.push(value);
                chartLabels.push(item?.title);
                backgroundColor.push(`rgb(${item?.backgroundRGB})`);
            }
        });

        return {
            chartData,
            chartLabels,
            backgroundColor,
        };
    }, [selectedTypes, data, dataInfo]);

    return (
        <FlexDiv flexDirection='column' alignItems='stretch' flex='1'>
            <CardHeader>
                <CardTitle>{title}</CardTitle>
            </CardHeader>
            {Array.isArray(searchOptions) && searchOptions.length > 0 && (
                <SearchFilter
                    tags={searchTags}
                    options={searchOptions}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    type={type}
                    icon={icon}
                    isDropdownVisible={isDropdownVisible}
                    setIsDropdownVisible={setIsDropdownVisible}
                />
            )}
            <ChartCard
                loading={loading}
                isEmpty={isEmptyChart}
                emptyBannerProps={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    showIcons: true,
                    borderColor: '#69657B',
                    title:
                        'This report is still being generated, check back soon!',
                }}
                hideOutline={true}
                minLoadingHeight='831px'
                style={{ paddingTop: '0px' }}
                bodyStyle={{ paddingTop: '0px' }}
            >
                <FlexDiv
                    flexDirection='column'
                    flexGrow={1}
                    style={{ minWidth: 0, overflow: 'hidden' }}
                >
                    <FlexDiv>
                        {(topData || []).slice(0, 6).map((item) => (
                            <StatBox {...(item || {})} />
                        ))}
                    </FlexDiv>
                    {yAxisLabel && yAxisLabel !== '' && (
                        <Typography variant='caption' color='ambience.6' pb={2}>
                            {yAxisLabel}
                        </Typography>
                    )}
                    <FlexDiv
                        alignItems='stretch'
                        justifyContent='space-between'
                    >
                        <FlexDiv flexGrow='1'>
                            <BarChart
                                isEmpty={isEmptyChart}
                                chartLabels={chartLabels}
                                chartData={chartData}
                                displayLegend={false}
                                options={{
                                    responsive: true,
                                    legend: {
                                        display: false,
                                    },
                                    scales: {
                                        xAxes: [
                                            {
                                                stacked: true,
                                            },
                                        ],
                                        yAxes: [
                                            {
                                                stacked: true,
                                            },
                                        ],
                                    },
                                }}
                                backgroundColor={backgroundColor}
                                maxBarThickness={128}
                            />
                        </FlexDiv>
                        {Array.isArray(dataInfo) && dataInfo.length > 0 && (
                            <FlexDiv flexDirection='column'>
                                {(dataInfo || []).map((item) => (
                                    <FlexDiv
                                        alignItems='center'
                                        key={item?.key}
                                    >
                                        <Checkbox
                                            filledSvgColor={`rgb(${item?.backgroundRGB})`}
                                            onClick={handleClick}
                                            isChecked={(
                                                selectedTypes || []
                                            ).includes(item?.key)}
                                            name={item?.key}
                                            iconSize='20px'
                                        />
                                        <Typography
                                            variant='caption'
                                            color='ambience.6'
                                            pl={1}
                                            style={{
                                                fontSize: '10px',
                                                lineHeight: '12px',
                                            }}
                                        >
                                            {item?.title}
                                        </Typography>
                                    </FlexDiv>
                                ))}
                            </FlexDiv>
                        )}
                    </FlexDiv>
                    <FlexDiv alignItems='center' justifyContent='center' pt={3}>
                        {(pills || []).map((pill) => (
                            <Pill
                                title={pill?.label}
                                mr={2}
                                selected={selectedPill === pill?.value}
                                onClick={() =>
                                    setSelectedPill &&
                                    setSelectedPill(pill?.value)
                                }
                                key={pill?.value}
                                isEmpty={isEmptyChart}
                            />
                        ))}
                    </FlexDiv>
                </FlexDiv>
            </ChartCard>
        </FlexDiv>
    );
}
