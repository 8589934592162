import Ably from 'ably';

class AblyService {
    private static service: AblyService;
    private client: Ably.Realtime;

    private constructor(client: Ably.Realtime) {
        this.client = client;
    }

    getClient(): Ably.Realtime {
        return this.client;
    }

    static getInstance(): AblyService {
        if (AblyService.service) {
            return AblyService.service;
        }

        const API_KEY = process.env.REACT_APP_ABLY_REALTIME_API_KEY;
        if (!API_KEY) {
            throw new Error('Invalid Ably API Key');
        }

        AblyService.service = new AblyService(
            new Ably.Realtime({ key: API_KEY, environment: 'airmeet' })
        );

        return AblyService.service;
    }
}

export default AblyService;
