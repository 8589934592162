import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import orderBy from 'lodash/orderBy';
import React, { useCallback, useMemo } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import ChartCard from '../components/ChartCard';
import { getCommonBarOptions, getEmptyBannerCommonProps } from '../utils';

const MapChart = React.lazy(() => import('../components/MapChart'));

export default function DemographicsCard({
    loading,
    data: countries,
    attendanceData,
    airmeetFormat,
}) {
    const { theme } = useTheme();
    const attendees = attendanceData?.event?.all?.attendees;
    const {
        labels,
        data,
        mapData,
        isEmpty,
        topCountriesPercent,
        nbTopCountries,
    } = useMemo(() => {
        const labels = [];
        const data = [];
        const mapData = {};
        let topCountriesTotal = 0;
        let nbTopCountries = 0;

        orderBy(
            Object.keys(countries || {}).map((key) => ({
                key,
                ...countries[key],
            })),
            ['count', 'name'],
            ['desc', 'asc']
        ).forEach((item, index) => {
            const { name, count, key } = item || {};

            if (key && name && index < 10) {
                labels.push(name);
                data.push(count);
                mapData[key] = {
                    name,
                    count,
                };
                topCountriesTotal += count;
                nbTopCountries++;
            }
        });

        const isEmpty = !Array.isArray(data) || data.length === 0;
        const topCountriesPercent =
            attendees > 0
                ? Math.round((topCountriesTotal / attendees) * 100)
                : 0;

        return {
            labels,
            data,
            mapData,
            isEmpty,
            topCountriesPercent,
            nbTopCountries,
        };
    }, [countries, attendees]);

    const chartData = useCallback(
        (canvas) => {
            const context = canvas.getContext('2d');

            const backgroundColor = isEmpty
                ? Array(5).fill('#26252D')
                : Array(10)
                      .fill(0)
                      .map((item, index) => {
                          const grad = context.createLinearGradient(
                              0,
                              100,
                              600,
                              100
                          );
                          grad.addColorStop(
                              0.25,
                              `rgba(83, 50, 230, ${
                                  1 - parseFloat(`0.${index}`)
                              })`
                          );
                          grad.addColorStop(
                              1,
                              `rgba(164, 95, 210, ${
                                  1 - parseFloat(`0.${index}`)
                              })`
                          );

                          return grad;
                      });

            return {
                labels: (isEmpty ? Array(5).fill('') : labels) || [],
                datasets: [
                    {
                        data: isEmpty ? Array(5).fill(5) : data,
                        backgroundColor,
                        borderWidth: 0,
                    },
                ],
            };
        },
        [data, labels, isEmpty]
    );

    const chartOptions = useMemo(() => {
        let barThickness = 4;
        if (data.length <= 1) {
            barThickness = 48;
        } else if (data.length <= 2) {
            barThickness = 32;
        } else if (data.length <= 4) {
            barThickness = 24;
        } else if (data.length <= 6) {
            barThickness = 16;
        } else if (data.length <= 8) {
            barThickness = 8;
        } else if (data.length <= 10) {
            barThickness = 4;
        }

        return isEmpty
            ? {
                  ...getCommonBarOptions({
                      yTicksDisplay: false,
                      xAxesMax: 300,
                  }),
                  animation: {},
                  tooltips: {
                      enabled: false,
                  },
                  theme,
              }
            : {
                  ...getCommonBarOptions({
                      padding: {
                          left: 100,
                          right: 50,
                          top: 0,
                          bottom: 0,
                      },
                      yTicks: {
                          mirror: true,
                          padding: 100,
                      },
                      barThickness,
                      maxBarThickness: barThickness,
                      theme,
                  }),
              };
    }, [isEmpty, data, theme]);

    return (
        <ChartCard
            title='Participant Demographics'
            loading={loading}
            isEmpty={isEmpty}
            emptyBannerProps={getEmptyBannerCommonProps({ airmeetFormat })}
        >
            <Box className='row' flexGrow='1'>
                <Box className='col-sm-6'>
                    <MapChart selectedCountries={mapData} />
                </Box>
                <FlexDiv flexDirection='column' className='col-sm-6'>
                    {!isEmpty && (
                        <Typography
                            variant='subtext2'
                            color='ambience.0'
                            pb={2}
                            flexGrow={1}
                        >
                            <b>{topCountriesPercent}%</b> of your participants
                            attended from{' '}
                            {nbTopCountries === 1
                                ? 'this country'
                                : `these ${nbTopCountries} countries`}
                            :
                        </Typography>
                    )}
                    <Box
                        style={{
                            flexGrow: 1,
                            minHeight: '270px',
                        }}
                    >
                        <HorizontalBar
                            data={chartData}
                            options={chartOptions}
                        />
                    </Box>
                </FlexDiv>
            </Box>
        </ChartCard>
    );
}
