import { Message } from '../types';
import DataObserverBase from './DataObserverBase';

enum CHAT_TYPE {
    SESSION_FEED = 'SESSION_FEED',
    EVENT_FEED = 'EVENT_FEED',
}

interface ChatReactionMetaData {
    chatType: CHAT_TYPE;
    reactionType: string;
    chatKey: string;
    isEventFeedInSession: boolean;
}

class ChatReactionObserverOld extends DataObserverBase {
    subscribe(onData: Function, metaData: ChatReactionMetaData) {
        if (!this.userId || !this.airmeetId) {
            throw new Error('Invalid Channel Id');
        }

        const onSnapshot = (message: Message) => {
            onData(message);
        };

        const channel =
            metaData.chatType === CHAT_TYPE.EVENT_FEED ||
            metaData.isEventFeedInSession
                ? `/${this.airmeetId}/chats/reactions/${metaData.chatKey}/${metaData.reactionType}/${this.userId}/timestamp`
                : `/${this.airmeetId}/liveAirmeet/sessions/${this.sessionId}/reactions/${metaData.chatKey}/${metaData.reactionType}/${this.userId}/timestamp`;
        const dataSourceUnsubscribe = super.subscribeImpl(channel, {
            onSnapshot,
        });

        return () => {
            dataSourceUnsubscribe();
        };
    }
}

export default ChatReactionObserverOld;
