export const isAllCookieAllowed = () => {
    return window.Optanon
        ? window.OptanonActiveGroups === ',C0001,C0002,C0003,C0004,'
        : true;
};

export const allowAllCookies = () => {
    return window.Optanon && window.Optanon.AllowAll();
};

export const rejectAllCookies = () => {
    return window.Optanon && window.Optanon.RejectAll();
};

export const toggleCookieInfoDisplay = () => {
    return window.Optanon && window.Optanon.ToggleInfoDisplay();
};

export const closeCookieBanner = () => {
    window.Optanon &&
        !window.Optanon.IsAlertBoxClosed() &&
        window.Optanon.Close();
};
