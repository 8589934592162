export const breakpoints = ['600px', '960px', '1280px', '1600px'];

export const spaceV2 = [
    '0.125rem',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '2.5rem',
];

const radius = ['0rem', '0.25rem', '0.5rem', '0.75rem', '1.25rem'];

export const cornerRadius = {
    default: radius[4],
    custom: radius,
};

export const fontSizesV2 = [
    '0.625rem',
    '0.75rem',
    '0.875rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '1.75rem',
    '2.25rem',
];

export const lineHeightsV2 = [
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '1.75rem',
    '2rem',
    '2.25rem',
    '3rem',
];

export const shadowsV2 = [
    '4px 16px 20px 0px rgba(0,0,0,0.2)',
    '0px 3px 5px 0px rgba(0,0,0,0.2)',
    '0px 0px 2px 0px rgba(0,0,0,0.6)',
    '0px 0px 3px 0px rgba(0,0,0,0.6)',
];

export const overlay = {
    blur: 'blur(70px)',
    blurOverlayDark: 'rgba(0, 0, 0, 0.6)',
    blurOverlayLight: 'rgba(255, 255, 255, 0.6)',
    overlayHC: 'rgba(0, 0, 0, 1)',
};
