export const ADD_MEETING = 'ADD_MEETING';
export const REMOVE_MEETING = 'REMOVE_MEETING';
export const UPDATE_MEETING = 'UPDATE_MEETING';
export const REMOVE_ALL_MEETING = 'REMOVE_ALL_MEETING';
export const UPDATE_MEETING_STATUS = 'UPDATE_MEETING_STATUS';

export const addMeeting = (meetingInfo) => {
    return {
        payload: meetingInfo,
        type: ADD_MEETING,
    };
};

export const removeMeeting = (meetingId) => {
    return {
        payload: meetingId,
        type: REMOVE_MEETING,
    };
};

export const updateMeeting = (meetingInfo) => {
    return {
        payload: meetingInfo,
        type: UPDATE_MEETING,
    };
};

export const updateMeetingStatus = (meetingInfo) => {
    return {
        payload: meetingInfo,
        type: UPDATE_MEETING_STATUS,
    };
};

export const removeAllMeeting = () => {
    return {
        payload: {},
        type: REMOVE_ALL_MEETING,
    };
};
