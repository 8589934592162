import { cdnImage } from 'utils/core';
import { API_SERVICE } from '../../../utils/apiService';
import { CALL_API } from '../../api';

export const WAITING_SCREEN_GET_REQUEST = 'WAITING_SCREEN_GET_REQUEST';
export const WAITING_SCREEN_GET_RESPONSE = 'WAITING_SCREEN_GET_RESPONSE';
export const WAITING_SCREEN_CREATE_REQUEST = 'WAITING_SCREEN_CREATE_REQUEST';
export const WAITING_SCREEN_CREATE_RESPONSE = 'WAITING_SCREEN_CREATE_RESPONSE';
export const WAITING_SCREEN_SESSION_LEVEL_GET_REQUEST =
    'WAITING_SCREEN_SESSION_LEVEL_GET_REQUEST';
export const WAITING_SCREEN_SESSION_LEVEL_GET_RESPONSE =
    'WAITING_SCREEN_SESSION_LEVEL_GET_RESPONSE';
export const WAITING_SCREEN_SESSION_LEVEL_CREATE_REQUEST =
    'WAITING_SCREEN_SESSION_LEVEL_CREATE_REQUEST';
export const WAITING_SCREEN_SESSION_LEVEL_CREATE_RESPONSE =
    'WAITING_SCREEN_SESSION_LEVEL_CREATE_RESPONSE';
export const WAITING_SCREEN_SESSION_LEVEL_DELETE_RESPONSE =
    'WAITING_SCREEN_SESSION_LEVEL_DELETE_RESPONSE';
export const WAITING_SCREEN_SESSION_LEVEL_DELETE_REQUEST =
    'WAITING_SCREEN_SESSION_LEVEL_DELETE_REQUEST';

export const WAITING_SCREEN_DELETE_REQUEST = 'WAITING_SCREEN_DELETE_REQUEST';
export const WAITING_SCREEN_DELETE_RESPONSE = 'WAITING_SCREEN_DELETE_RESPONSE';
export const WAITING_SCREEN_UPDATE_REQUEST = 'WAITING_SCREEN_UPDATE_REQUEST';
export const WAITING_SCREEN_UPDATE_RESPONSE = 'WAITING_SCREEN_UPDATE_RESPONSE';
export const SET_WAITING_SCREEN_PREVIEW = 'SET_WAITING_SCREEN_PREVIEW';

export function getEventLevelWaitingScreenList(airmeetId) {
    return {
        [CALL_API]: {
            types: [WAITING_SCREEN_GET_REQUEST, WAITING_SCREEN_GET_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/waiting-screen`,
            method: 'GET',
            type: 'json',
            service: API_SERVICE.BFF,
            transform: (json) => {
                if (!json?.data) {
                    return json;
                }
                json.data = json.data.map((item) => {
                    if (item.mediaType === 'VIDEO' && !item.thumbnail) {
                        item.thumbnail = cdnImage(
                            '/images/brandings/waitingScreen/video-placeholder.svg'
                        );
                    }
                    return item;
                });
                return json;
            },
        },
    };
}

export function updateWaitingScreen(body, airmeetId, waitingScreenId) {
    return {
        [CALL_API]: {
            types: [
                WAITING_SCREEN_UPDATE_REQUEST,
                WAITING_SCREEN_UPDATE_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/waiting-screen/${waitingScreenId}`,
            type: 'json',
            method: 'PUT',
            body,
            service: API_SERVICE.BFF,
        },
    };
}

export function removeWaitingScreen(airmeetId, waitingScreenId) {
    return {
        [CALL_API]: {
            types: [
                WAITING_SCREEN_DELETE_REQUEST,
                WAITING_SCREEN_DELETE_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/waiting-screen/${waitingScreenId}`,
            type: 'json',
            method: 'DELETE',
            service: API_SERVICE.BFF,
        },
    };
}

export function addEventLevelWaitingScreenAction(body, airmeetId) {
    return {
        [CALL_API]: {
            types: [
                WAITING_SCREEN_CREATE_REQUEST,
                WAITING_SCREEN_CREATE_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/waiting-screen`,
            method: 'POST',
            type: 'json',
            body,
            service: API_SERVICE.BFF,
        },
    };
}

export function addSessionLevelWaitingScreenAction(payload) {
    const { airmeetId, sessionId, waitingScreenId, requestBody } = payload;

    return {
        [CALL_API]: {
            types: [
                WAITING_SCREEN_SESSION_LEVEL_CREATE_RESPONSE,
                WAITING_SCREEN_SESSION_LEVEL_CREATE_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/waiting-screen/${waitingScreenId}`,
            method: 'PUT',
            type: 'json',
            body: requestBody,
            service: API_SERVICE.BFF,
        },
    };
}

export function fetchSessionLevelWaitingScreenAction(payload) {
    const { airmeetId, sessionId } = payload;

    return {
        [CALL_API]: {
            types: [
                WAITING_SCREEN_SESSION_LEVEL_GET_REQUEST,
                WAITING_SCREEN_SESSION_LEVEL_GET_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/waiting-screen`,
            method: 'GET',
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}

export function deleteSessionLevelWaitingScreen(payload) {
    const { airmeetId, sessionId, waitingScreenId } = payload;

    return {
        [CALL_API]: {
            types: [
                WAITING_SCREEN_SESSION_LEVEL_DELETE_REQUEST,
                WAITING_SCREEN_SESSION_LEVEL_DELETE_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/waiting-screen/${waitingScreenId}`,
            method: 'DELETE',
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}

export function setWaitingScreePreview(payload) {
    return { type: SET_WAITING_SCREEN_PREVIEW, payload };
}
