import { AirmeetRTCClient } from '@airmeet/rtc-sdk';
import { useState } from 'react';

const getStorageKey = (airmeetId) => `pref_audio_mode-${airmeetId}`;
export const NOISE_CANCELLATION = 'noise_cancellation';

export const AUDIO_MODES = {
    NONE: 'none',
    MUSIC: 'music',
    NOISE_CANCELLATION: 'noise-cancellation',
};

export const isMusicModeEnabled = (airmeetId) =>
    localStorage.getItem(getStorageKey(airmeetId)) === AUDIO_MODES.MUSIC;
export const isNoiseSuppressionEnabled = (airmeetId) => {
    if (isMusicModeEnabled(airmeetId)) return false;
    let noiseCancellation = localStorage.getItem(NOISE_CANCELLATION);
    if (!noiseCancellation) {
        localStorage.setItem(
            NOISE_CANCELLATION,
            AUDIO_MODES.NOISE_CANCELLATION
        );
        noiseCancellation = AUDIO_MODES.NOISE_CANCELLATION;
    }
    return noiseCancellation === AUDIO_MODES.NOISE_CANCELLATION;
};

export const onAudioModeSettingChanged = (
    client: AirmeetRTCClient,
    airmeetId
) => {
    if (client) {
        const params = {
            enableMusicMode: isMusicModeEnabled(airmeetId),
            enableNoiseSuppression: isNoiseSuppressionEnabled(airmeetId),
        };
        client.setPendingAudioModeUpdate(params);
    }
};

function useAudioModes(airmeetId) {
    const [mode, setMode] = useState(() => {
        if (isMusicModeEnabled(airmeetId)) return AUDIO_MODES.MUSIC;
        if (isNoiseSuppressionEnabled(airmeetId))
            return AUDIO_MODES.NOISE_CANCELLATION;

        return AUDIO_MODES.NONE;
    });
    const saveToStorage = (newMode) => {
        if (newMode === AUDIO_MODES.NOISE_CANCELLATION) {
            localStorage.setItem(
                NOISE_CANCELLATION,
                AUDIO_MODES.NOISE_CANCELLATION
            );
            localStorage.setItem(getStorageKey(airmeetId), AUDIO_MODES.NONE);
        } else if (newMode === AUDIO_MODES.MUSIC) {
            localStorage.setItem(getStorageKey(airmeetId), AUDIO_MODES.MUSIC);
            localStorage.setItem(NOISE_CANCELLATION, AUDIO_MODES.NONE);
        } else {
            localStorage.setItem(NOISE_CANCELLATION, newMode ?? '');
            localStorage.setItem(getStorageKey(airmeetId), newMode ?? '');
        }
    };

    return {
        mode,
        setMode,
        MODES: AUDIO_MODES,
        saveToStorage,
    };
}

export default useAudioModes;
