import createReducer from '../../utils/createReducer';
import {
    GET_AIRMEET_RESOURCES_RESPONSE,
    GET_SESSION_RESOURCES_RESPONSE,
} from '../actions/resources';

const initialState = {
    resourceList: [],
    sessionResourceList: {},
};

const handlers = {
    [GET_AIRMEET_RESOURCES_RESPONSE]: (state, { payload }) => {
        return {
            ...state,
            resourceList: payload?.data,
        };
    },
    [GET_SESSION_RESOURCES_RESPONSE]: (state, { payload, extra }) => {
        return {
            ...state,
            sessionResourceList: { [extra.sessionId]: payload?.data },
        };
    },
};

export default createReducer(initialState, handlers);
