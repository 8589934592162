export const ErrorModuleNames = {
    SESSION_RHS: 'SessionRHS',
    SESSION_RHS_QUESTIONS: 'SessionQuestions_RHS',
    SESSION_RHS_POLLS: 'SessionPolls_RHS',
    SESSION_RHS_CHATS: 'SessionChats_RHS',
    SESSION_RHS_PEOPLE_TAB: 'SessionPeopleTab_RHS',
    RHS: 'RHS',
    BOOTHS: 'Booths',
    SOCIAL_LOUNGE: 'SocialLounge',
    SESSIONS: 'Sessions',
    LIVE_STAGE: 'LiveStage',
    SPEED_NETWORKING: 'SpeedNetworking',
    SCHEDULED_MEETING: 'ScheduledMeeting',
    RECEPTION: 'Reception',
    FLUID_SPACE: 'FluidSpace',
    SCHEDULE_PAGE: 'SchedulePage',
    LIVE_SESSION_BREAKOUT: 'LiveStage:LiveSessionBreakout',
    BREAKOUT_CREATION: 'LiveStage:BreakoutCreation',
    BREAKOUT_RHS: 'LiveStage:BreakoutRHS',
    RHS_CONTENT_WRAPPER: 'RHS_CONTENT_WRAPPER',
    EVENT_MODERATION: 'EVENT_MODERATION',
};
export const dashboardModuleNames = {
    SUMMARY: 'Dashboard:Summary',
    SESSIONS: 'Dashboard:SessionsTab',
    SPEAKERS_HOST: 'Dashboard:SpeakersHosts',
    HOST_COHOSTS: 'Dashboard:HostCohosts',
    TICKETS: 'Dashboard:Tickets',
    ACCESS_RULES_V2: 'Dashboard:RulesV2',
    ACCESS_RULES_V1: 'Dashboard:RulesV1',
    STAGE_BACKDROP: 'Dashboard:StageBackdrop',
    STAGE_WAITING_SCREEN: 'Dashboard:StageWaitingScreen',
    PRE_RECORDED_VIDEO: 'Dashboard:Video',
    MANAGE_RECORDING: 'Dashboard:Recordings',
    LIVE_STREAM_V2: 'Dashboard:LiveStreamTabV2',
    LIVE_STREAM_V1: 'Dashboard:LiveStreamTabV1',
    SUMMIT_SPONSORS: 'Dashboard:Sponsors',
    BRANDING: 'Dashboard:Branding',
    LANDING_PAGE: 'Dashboard:LandingPage',
    BOOTHS: 'Dashboard:Booths',
    VENUE_SETTINGS: 'Dashboard:Venue',
    AUDIENCE: 'Dashboard:AudienceTab',
    AUDIENCE_SUMMARY: 'Dashboard:AudienceSummaryTab',
    REPORTS: 'Dashboard:ReportsTab',
    ANALYTICS: 'Dashboard:AnalyticsTab',
    LEADERBOARD: 'Dashboard:Leaderboard',
    EVENT_INTEGRATIONS: 'Dashboard:EventIntegrations',
    DASHBOARD_SIDE_MENU: 'Dashboard:DashboardSideMenu',
    INTEGRATION_DETAILS: 'Dashboard:IntegrationDetails',
    PRE_EVENT_ENTRY: 'Dashboard:PreEventEntry',
    POST_EVENT_ENTRY: 'Dashboard:PostEventEntry',
    // subtabs
    PARTICIPANT: 'Dashboard:ParticipantTab',
    EMAIL_MANAGEMENT: 'Dashboard:EmailManagement',
    ANALYTICS_REGISTRATION: 'Dashboard:AnalyticsRegistration',
    ANALYTICS_ENGAGEMENT: 'Dashboard:AnalyticsEngagement',
    ANALYTICS_POST_EVENT: 'Dashboard:AnalyticsPostEvent',
    INTEGRATION_APPS: 'Dashboard:IntegrationApps',
    LIVE_STREAM_DESTINATION: 'Dashboard:LiveStreamDestination',
    API_ACCESS_KEY: 'Dashboard:ApiAccessKey',
    // Community dashboard items.
    EVENTS_TAB: 'Dashboard:EventsTab',
    VIDEO_LIBRARY: 'Dashboard:VideoLibrary',
    MEDIA_LIBRARY: 'Dashboard:MediaLibrary',
    TEAMS: 'Dashboard:TeamsTab',
    APPSUMO_PLANS: 'Dashboard:AppSumoPlan',
    SETTINGS: 'Dashboard:Settings',
    BILLING_PLAN: 'Dashboard:BillingPlan',
    GETTING_STARTED: 'Dashboard:GettingStarted',
    CURRENT_BILLING_PLAN: 'Dashboard:CurrentBillingPlan',
    ANALYTICS_EVENTS_TAB: 'Dashboard:AnalyticsEventsTab',
    EMAIL_TEMPLATES: 'Dashboard:EmailTemplates',
    // Some generic componentDashboard:
    GENERAL_DRAWER: 'Drawer',
    EVENT_DASHBOARD_HOME: 'Dashboard:EventDashboardHome',
    INTRO_VIDEO_PLAYR: 'Dashboard:IntroVideoPlayer',
    // custom registration form
    CUSTOM_REGISTRATION_FIELDS: 'CustomRegistrationFields',
    //series dashboard
    SERIES_EVENT_ENTRY: 'Dashboard:SeriesEventEntry',
    SERIES_EVENT_LISTING_SUMMARY: 'Dashboard:SeriesEventListingSummary',
    SERIES_DETAIL_SECTION: 'Dashborad:SeriesDetailSection',
    SERIES_SETTING_SECTION: 'Dashborad:SeriesSettingSection',
    //series landing page
    SERIES_LANDING_PAGE: 'Dashboard:SeriesLandingPage',
    SERIES_OVERVIEW_PAGE: 'Dashboard:SeriesOverviewPage',
    SERIES_EVENTS_PAGE: 'Dashboard:SeriesEventsPage',
    SERIES_LONG_DESC: 'Dashboard:SeriesLongDesc',
    SERIES_EVENT_CARD: 'Dashboard:SeriesLpEvent',
    SERIES_UPCOMING_EVENT_CARD: 'Dashboard:SeriesUpcomingEvent',
    SERIES_SPONSORS_SECTION: 'Dashboard:Sponsors',
    SERIES_SPEAKERS_SECTION: 'Dashboard:SpeakerSection',
    SERIES_OVERVIEW_SECTION: 'Dashboard:OverviewSection',
    SERIES_REGISTER_FOOTER: 'Dashboard:RegisterFooter',
};
