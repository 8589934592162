import Airmeet from 'utils/airmeet';
let airmeetUtilInstance;
export const createAirmeetUtilInstance = (options) => {
    airmeetUtilInstance = new Airmeet(options);
    return airmeetUtilInstance;
};

export const clearAirmeetUtilInstance = () => {
    airmeetUtilInstance = null;
};

export const getAirmeetUtilInstance = () => {
    return airmeetUtilInstance;
};

window.setWindowAirmeetUtilInstance = () => {
    if ('test' === process.env.REACT_APP_ENV) {
        window.airmeet = airmeetUtilInstance;
    }
};
