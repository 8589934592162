import { useCallback, useEffect, useRef, useState } from 'react';

function useTimedCallbacks(callbacks = {}) {
    const [tick, setTick] = useState(0);

    const localTimer = useRef(null);
    const callbackQueue = useRef([]);

    const [lastResult, setLastResult] = useState(null);

    callbackQueue.current = Object.entries(callbacks)
        .sort((a, b) => a[0] - b[0])
        .filter((v, index) => index >= tick - 1)
        .reduce((all, [timeFromStart, callback]) => {
            const lastTime = all.length ? all[all.length - 1].ts : 0;
            const currGap = timeFromStart - lastTime;
            return [...all, { gap: currGap, ts: timeFromStart, callback }];
        }, []);

    useEffect(() => {
        if (callbackQueue.current.length) {
            const latestResult =
                typeof callbackQueue.current[0].callback === 'function'
                    ? callbackQueue.current[0].callback()
                    : callbackQueue.current[0].callback;
            setLastResult((prev) =>
                prev !== latestResult ? latestResult : prev
            );
        }
    }, [
        callbackQueue.current.length ? callbackQueue.current[0].callback : null,
    ]);

    useEffect(() => {
        if (tick) {
            if (callbackQueue.current.length > 0) {
                setLastResult(
                    typeof callbackQueue.current[0].callback === 'function'
                        ? callbackQueue.current[0].callback()
                        : callbackQueue.current[0].callback
                );

                if (callbackQueue.current.length > 1) {
                    localTimer.current = setTimeout(
                        () => setTick((prev) => prev + 1),
                        callbackQueue.current[1].gap
                    );
                }
            }
        }
    }, [tick]);

    const startTimer = useCallback(() => {
        if (!localTimer.current && callbackQueue.current.length) {
            localTimer.current = setTimeout(
                () => setTick((prev) => prev + 1),
                callbackQueue.current[0].gap
            );
        }
    }, [setTick]);

    const stopTimer = useCallback(() => {
        if (localTimer.current) {
            clearTimeout(localTimer.current);
            localTimer.current = null;
            setTick((prev) => prev || prev - 1);
        }
    }, [setTick]);

    const resetTimer = useCallback(() => {
        if (localTimer.current) {
            clearTimeout(localTimer.current);
            localTimer.current = null;
            setTick(0);
        }
    }, [setTick]);

    return { current: lastResult, startTimer, resetTimer, stopTimer };
}

export default useTimedCallbacks;
