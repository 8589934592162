import {
    SESSION_STATUS,
    SESSION_TYPES,
    Session,
} from 'components/Schedule/types';
import {
    SHOW_FEED_FOR_REPLAY,
    SHOW_POLLS_FOR_REPLAY,
    SHOW_QNA_FOR_REPLAY,
} from 'components/lounge/rhsV2/settingsTab/utils';
import { ALL_FEATURES, ALL_PLATFORM_FEATURES } from 'hooks/useFeatureControl';
import get from 'lodash/get';
import { emptyArray } from 'utils/constants/common';
import { SESSION_REPLAY_CONFIG } from 'utils/constants/sessions';
import PermissionUtils from 'utils/permission-utils';
import LiveAirmeet from '../types/liveAirmeet';
// Selectors
export const getUser = (ctx: LiveAirmeet) => get(ctx, ['user']);
export const getUserId = (ctx: LiveAirmeet) => get(getUser(ctx), ['id']);
const getAirmeetData = (ctx: LiveAirmeet) => get(ctx, ['airmeet', 'data']);
export const getCurrentAirmeet = (ctx: LiveAirmeet) =>
    get(getAirmeetData(ctx), ['currentAirmeet']);
export const getUserRole = (ctx: LiveAirmeet) =>
    get(getAirmeetData(ctx), ['userRole']);
export const getIsConferenceEvent = (ctx: LiveAirmeet) => {
    return get(ctx, ['airmeet', 'featureFlags', 'isConferenceEvent']);
};
export const getHasTrackAdded = (ctx: LiveAirmeet) =>
    get(ctx, ['airmeet', 'featureFlags', 'hasTrackAdded']);
export const getSponsorBannerImage = (ctx: LiveAirmeet) =>
    get(ctx, [
        'airmeet',
        'data',
        'currentAirmeet',
        'branding_info',
        'banner_image',
    ]);
export const getIsEventCloudHostUser = (ctx: LiveAirmeet) =>
    get(ctx, ['isEventCloudHostUser']);
export const getCurrentSession = (ctx: LiveAirmeet) =>
    get(ctx, ['airmeet', 'data', 'currentSession']);
export const getUpcomingSession = (ctx: LiveAirmeet) =>
    get(ctx, ['airmeet', 'data', 'upcomingSession']);
export const getCurrentState = (ctx: LiveAirmeet) =>
    get(ctx, ['airmeet', 'data', 'currentState']);

export const getCurrentSessionId = (ctx: LiveAirmeet) =>
    get(getCurrentSession(ctx), ['sessionid'], '');
export const getCurrentSessionStatus = (ctx: LiveAirmeet) =>
    get(getCurrentSession(ctx), ['status'], '');
export const getCurrentSessionType = (ctx: LiveAirmeet) =>
    get(getCurrentSession(ctx), ['type']);
export const getCurrentSessionHostIds = (ctx: LiveAirmeet) =>
    get(getCurrentSession(ctx), ['host_id']);
export const getCurrentSessionCohostIds = (ctx: LiveAirmeet) =>
    get(getCurrentSession(ctx), ['cohost_ids']);
export const getCurrentSessionSpeakerIds = (ctx: LiveAirmeet) =>
    get(getCurrentSession(ctx), ['speaker_id']);
export const getAirmeetId = (ctx: LiveAirmeet) =>
    get(ctx, ['airmeet', 'airmeetId']);
export const getAirmeetHostId = (ctx: LiveAirmeet) =>
    get(getCurrentAirmeet(ctx), ['host']);
export const getOnlineUsersCount = (ctx: LiveAirmeet) =>
    get(ctx, ['airmeet', 'onlineUsers'], []).length;
export const getFirebaseClient = (ctx: LiveAirmeet) =>
    get(ctx, ['firebaseClient']);
export const getCommunityId = (ctx: LiveAirmeet) =>
    get(ctx, ['airmeet', 'data', 'currentAirmeet', 'community_id']);
export const getCommunityName = (ctx: LiveAirmeet) =>
    get(ctx, ['airmeet', 'data', 'currentAirmeet', 'community_name']);
export const getFeatures = (ctx: LiveAirmeet) => get(ctx, ['features'], []);
export const getStageFirebaseClient = (ctx: LiveAirmeet) =>
    get(ctx, ['featureDataClients', 'stage']);

export const hasRTMPStreamingEnabledFromRecorder = (ctx: LiveAirmeet) => {
    const enabledFeatures = get(ctx, ['features']);
    const isScreenRecorderRTMPEnabled = get(
        getCurrentAirmeet(ctx),
        ['is_screen_recorder_rtmp_enabled'],
        false
    );
    return (
        isScreenRecorderRTMPEnabled ||
        (enabledFeatures &&
            enabledFeatures.includes(
                ALL_FEATURES.PUBLISH_LIVESTREAMING_USING_SCREEN_RECORDER
            ))
    );
};

export const getIsLiveIntegrationEnabled = (ctx: LiveAirmeet) =>
    get(ctx, ['airmeet', 'featureFlags', 'isLiveIntegrationEnabled']);
export const getAirmeetStatus = (ctx: LiveAirmeet) =>
    get(getCurrentAirmeet(ctx), ['status']);
export const getFeaturesConfig = (ctx: LiveAirmeet) =>
    get(ctx, ['featuresConfig']);
export const getLoaded = (ctx: LiveAirmeet) => get(ctx, ['loaded']);
export const getPlatformFeatures = (ctx: LiveAirmeet) =>
    get(ctx, ['platformFeatures']);
export const getEnabledReadStageMembers = (ctx: LiveAirmeet) => {
    const enabledPlatformFeatures = getPlatformFeatures(ctx);
    return (enabledPlatformFeatures || []).includes(
        ALL_PLATFORM_FEATURES.ENABLED_FIREBASE_READ_STAGE_MEMBERS
    );
};
export const getIsBreakoutV1Enabled = (ctx: LiveAirmeet) =>
    get(ctx, ['features'], emptyArray).includes(ALL_FEATURES.SESSION_BREAKOUT);
export const getIsBreakoutV2Enabled = (ctx: LiveAirmeet) =>
    get(ctx, ['airmeet', 'featureFlags', 'isBreakoutsEnabled']);

export const getIsLoungeEnabled = (ctx: LiveAirmeet) =>
    get(ctx, ['airmeet', 'featureFlags', 'lounge']);

export const getIsIntroVideoHLSEnabled = (ctx: LiveAirmeet) =>
    get(ctx, ['features'], emptyArray).includes(
        ALL_FEATURES.INTRO_VIDEO_HLS_ENABLED
    );

export const getIsBreakoutEnabled = (ctx: LiveAirmeet) => {
    const isBreakoutV1Enabled = getIsBreakoutV1Enabled(ctx);
    const isBreakoutV2Enabled = getIsBreakoutV2Enabled(ctx);

    return isBreakoutV1Enabled || isBreakoutV2Enabled;
};

export const getAllowedToSkipAVPermission = (ctx: LiveAirmeet) =>
    get(ctx, ['features'], emptyArray).includes(
        ALL_FEATURES.JOIN_BREAKOUT_ROOM_WITH_PERMISSION
    );

export const getInSessionCTAEnabled = (ctx: LiveAirmeet) => {
    const isFirebaseFlagEnabled = get(ctx, ['features'], emptyArray).includes(
        ALL_FEATURES.BANNER_CTA
    );
    const isEnabledFromAPI = get(ctx, [
        'airmeet',
        'featureFlags',
        'isInSessionCTAEnabled',
    ]);
    return isFirebaseFlagEnabled || isEnabledFromAPI;
};

// TODO: BE Will provide the key for banners in Dec release 2023. Will update accordingly.
export const getBannerLowerThirdsEnabled = (ctx: LiveAirmeet) => {
    return get(ctx, ['airmeet', 'featureFlags', 'isInSessionCTAEnabled']);
};
export const getLiveConfig = (ctx: LiveAirmeet) => {
    return get(getCurrentAirmeet(ctx), ['live_config']);
};
export const getHideStageLabels = (ctx: LiveAirmeet) =>
    get(getLiveConfig(ctx), ['hideStageLabels']);

export const getRTMClient = (ctx: LiveAirmeet) => get(ctx, ['rtmClient']);
export const getAllSessions = (ctx: LiveAirmeet): Session[] =>
    get(getCurrentAirmeet(ctx), ['sessions'], emptyArray);
export const getFullScreen = (ctx: LiveAirmeet) => get(ctx, ['fullScreen']);

export const getOngoingSession = (ctx: LiveAirmeet) => {
    return getAllSessions(ctx).find(
        (session) => session.status === SESSION_STATUS.ONGOING
    );
};

/**
 * Retrieves the next created session excluding break sessions.
 */
export const getNextCreatedSession = (ctx: LiveAirmeet) => {
    return getAllSessions(ctx).find(
        (session) =>
            session.status === SESSION_STATUS.CREATED &&
            session.type !== SESSION_TYPES.BREAK
    );
};

export const getSessionBySessionId = (ctx: LiveAirmeet) => {
    return (sessionId: string): Session => {
        return (
            (getAllSessions(ctx) || []).find(
                (session) => session.sessionid === sessionId
            ) || null
        );
    };
};
export const getBreakoutRoomLimit = (ctx: LiveAirmeet) =>
    parseInt(get(ctx, ['airmeet', 'featureFlags', 'breakoutRoomMaxSize']));

export const getIsPlayVideoV2Enabled = (ctx: LiveAirmeet) =>
    get(ctx, ['features'], emptyArray).includes(
        ALL_FEATURES.ENABLE_PLAY_VIDEO_ON_STAGE
    ) ||
    get(ctx, [
        'airmeet',
        'data',
        'currentAirmeet',
        'is_play_video_on_stage_enabled',
    ]);

export const getIsEnableCustomPreRecordedUrl = (ctx: LiveAirmeet) =>
    getIsPlayVideoV2Enabled(ctx) &&
    get(ctx, ['features'], emptyArray).includes(
        ALL_FEATURES.ENABLE_CUSTOM_PRE_RECORDED_VIDEO_URL
    );

export const hasAccessToPublishCustomVideo = (ctx: LiveAirmeet) =>
    getIsPlayVideoV2Enabled(ctx) &&
    get(ctx, [
        'airmeet',
        'data',
        'currentAirmeet',
        'is_screen_recorder_enabled',
    ]) &&
    !get(ctx, ['airmeet', 'data', 'currentAirmeet', 'is_cloud_host_enabled'])
        ? PermissionUtils.isSessionScreenRecordingUser()
        : PermissionUtils.isSessionCloudHost();

export const getIsSpeakerOnboardingEnabled = (ctx: LiveAirmeet) =>
    get(ctx, ['features'], emptyArray).includes(
        ALL_FEATURES.SPEAKER_ONBOARDING
    );

export const getIsSessionBasedStageEnabled = (ctx: LiveAirmeet) =>
    get(ctx, [
        'airmeet',
        'data',
        'currentAirmeet',
        'is_session_level_stage_enabled',
    ]);

export const getIsPersistentBackstageEnabled = (ctx: LiveAirmeet) =>
    get(ctx, [
        'airmeet',
        'data',
        'currentAirmeet',
        'is_persistent_backstage_enabled',
    ]);
export const getMeetupSessionBasedStageEnabled = (ctx: LiveAirmeet) =>
    getIsSessionBasedStageEnabled(ctx) && !getIsConferenceEvent(ctx);

export const getIsMeetupEventChatRHSEnabled = (ctx: LiveAirmeet) =>
    !getIsSessionBasedStageEnabled(ctx) ||
    get(ctx, [
        'airmeet',
        'data',
        'currentAirmeet',
        'is_session_level_stage_event_chat_enabled',
    ]);

export const getDefaultRoute = (ctx: LiveAirmeet) => {
    if (getIsMeetupLiteEvent(ctx)) {
        return 'sessions';
    }
    return getIsConferenceEvent(ctx) ? 'reception' : 'lounge';
};

const getReplayConfig = (ctx: LiveAirmeet, sessionId: string) =>
    get(getCurrentAirmeet(ctx), ['meta', sessionId, 'replayConfig']);

export const getIsSessionPlaybackDisabled = (ctx: LiveAirmeet) =>
    get(getLiveConfig(ctx), ['isSessionPlaybackDisabled']);

export const getIsRHSEnagagementForReplayEnabled = (ctx: LiveAirmeet) => {
    return (
        (getIsConferenceEvent(ctx) || false) &&
        !(getIsSessionPlaybackDisabled(ctx) || false)
    );
};

export const getIsFeatureEnabledForReplay = (
    ctx: LiveAirmeet,
    sessionId: string,
    featureKey: string
) => {
    const value = get(getReplayConfig(ctx, sessionId), [featureKey]);
    return value === undefined ? true : value;
};

export const getIsQnAEnabledForReplay = (ctx: LiveAirmeet, sessionId: string) =>
    getIsFeatureEnabledForReplay(
        ctx,
        sessionId,
        SESSION_REPLAY_CONFIG.QNA_FLAG
    ) &&
    getIsRHSEnagagementForReplayEnabled(ctx) &&
    (get(getLiveConfig(ctx), [SHOW_QNA_FOR_REPLAY]) || false);

export const getIsSessionFeedAllowedForReplay = (
    ctx: LiveAirmeet,
    sessionId: string
) =>
    getIsFeatureEnabledForReplay(
        ctx,
        sessionId,
        SESSION_REPLAY_CONFIG.SESSION_FEED_FLAG
    ) &&
    getIsRHSEnagagementForReplayEnabled(ctx) &&
    (get(getLiveConfig(ctx), [SHOW_FEED_FOR_REPLAY]) || false);

export const getIsPollsAllowedForReplay = (
    ctx: LiveAirmeet,
    sessionId: string
) =>
    getIsFeatureEnabledForReplay(
        ctx,
        sessionId,
        SESSION_REPLAY_CONFIG.SESSION_POLLS_FLAG
    ) &&
    getIsRHSEnagagementForReplayEnabled(ctx) &&
    (get(getLiveConfig(ctx), [SHOW_POLLS_FOR_REPLAY]) || false);

export const getIsFeatureEnabledForReplayGlobal = (
    ctx: LiveAirmeet,
    configKey: string
) => {
    return (
        getIsRHSEnagagementForReplayEnabled(ctx) &&
        (get(getLiveConfig(ctx), [configKey]) || false)
    );
};

export const getIsMeetupRHSHidden = (ctx: LiveAirmeet) => {
    const liveConfig = getLiveConfig(ctx);
    return (
        (get(liveConfig, ['hideSessionsLiveChat']) || false) &&
        (get(liveConfig, ['hideSessionsPolls']) || false) &&
        (get(liveConfig, ['hideSessionsQA']) || false)
    );
};

export const getIsMeetupLiteEvent = (ctx: LiveAirmeet) =>
    get(ctx, ['airmeet', 'featureFlags', 'isMeetupLiteEvent'], false);

export const getRedirectionToPublicPage = (ctx: LiveAirmeet) =>
    get(ctx, ['redirectToPublicPage']);

export const getFullScreenState = (ctx: LiveAirmeet) => {
    return [get(ctx, ['fullScreen']), get(ctx, ['setFullScreen'])] as const;
};
