import useDeviceInfo from 'hooks/useDeviceInfo';
import useTheme from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { createGlobalStyle } from 'styled-components';
import {
    ANNOUNCEMENT_BANNER_CONTAINER,
    COOKIE_BANNER_CONTAINER,
} from 'utils/constants/containers/containerIds';
import useInfoBanner from '../../hooks/useInfoBanner';
import InfoBanner from '../molecules/infoBanner/infoBanner';
import useWhiteLabelling from 'hooks/useWhitelabelling';

const fundingBannerRoutes = ['/pricing', '/privacy-policy', '/terms-of-use'];
const EVENT_LANDING_PATH = '/e/';

const containsFundingBannerRoute = (path) => {
    // Disabling Funding banner for all routes
    // TODO : Cleanup of unused code without affecting other banners
    return false;
    // if (path === '/' || path.startsWith(EVENT_LANDING_PATH)) {
    //     return true;
    // } else if (
    //     fundingBannerRoutes.filter((fundingPath) => fundingPath === path)
    //         .length > 0
    // ) {
    //     return true;
    // } else {
    //     return false;
    // }
};

function AllInfoBanners({ history }) {
    const cookie = useInfoBanner('cookieBanner', { skipUserCheck: false });
    const funding = useInfoBanner('announcementBanner', {
        skipUserCheck: true,
    });
    const [cookieBannerVisibility, setCookieVisibility] = useState(null);
    const [announcementBannerVisibility, setAnnouncementVisibility] = useState(
        null
    );

    const { isMobile } = useDeviceInfo();
    const { customDomain } = useWhiteLabelling();

    useEffect(() => {
        //showing old cookie banner for whitelabelled events only
        if (cookie !== null && customDomain) {
            setCookieVisibility(cookie);
        }
        if (funding !== null) {
            if (
                funding === true &&
                containsFundingBannerRoute(history.location.pathname)
            )
                setAnnouncementVisibility(funding);
        }
    }, [cookie, funding, customDomain]);

    useEffect(() => {
        if (!containsFundingBannerRoute(history.location.pathname)) {
            setAnnouncementVisibility(false);
        } else if (localStorage.getItem('announcementBanner') === 'true') {
            setAnnouncementVisibility(true);
        }
    }, [history.location.pathname]);

    const { isLightTheme, theme } = useTheme();

    return (
        <>
            <InfoBannerOverride
                announcementBannerVisibility={announcementBannerVisibility}
                path={history.location.pathname}
                cookieBannerVisibility={cookieBannerVisibility}
            />
            {cookieBannerVisibility && (
                <InfoBanner
                    content={
                        'By continuing to use this site you consent to the use of cookies.'
                    }
                    linkContent={'Learn More'}
                    linkPath={'/privacy-policy'}
                    position={'bottom'}
                    storageKeyName={'cookieBanner'}
                    containerId={COOKIE_BANNER_CONTAINER}
                    bgColor={theme.colors.ambience[24]}
                    contentColor={theme.colors.ambience[3]}
                    linkColor={theme.colors.accentPrimary[0]}
                    linkLineBreak={isMobile}
                    setVisibility={setCookieVisibility}
                    style={{ zIndex: isMobile ? 99999 : 100 }}
                />
            )}
            {announcementBannerVisibility && (
                <InfoBanner
                    content={
                        'We raised $12 million to unlock the potential of virtual events.'
                    }
                    linkContent={'Read more.'}
                    linkPath={
                        'https://www.airmeet.com/hub/we-raised-12-million-to-unlock-the-potential-of-virtual-events/'
                    }
                    position={'top'}
                    storageKeyName={'announcementBanner'}
                    containerId={ANNOUNCEMENT_BANNER_CONTAINER}
                    bgColor={theme.colors.semantic[2]}
                    contentColor={theme.colors.ambience[24]}
                    linkColor={theme.colors.accentPrimary}
                    bannerVisibility={announcementBannerVisibility}
                    setVisibility={setAnnouncementVisibility}
                    centerText={true}
                    py={['12px', '20px', '12px']}
                />
            )}
        </>
    );
}

export default withRouter(AllInfoBanners);

const InfoBannerOverride = createGlobalStyle`
    .app-header {
        position: ${({ announcementBannerVisibility, path }) =>
            announcementBannerVisibility
                ? path.startsWith(EVENT_LANDING_PATH)
                    ? 'absolute !important'
                    : 'sticky !important'
                : 'initial'};
        position: ${({ announcementBannerVisibility, path }) =>
            announcementBannerVisibility
                ? path.startsWith(EVENT_LANDING_PATH)
                    ? 'absolute !important'
                    : '-webkit-sticky !important'
                : 'initial'};
        top: ${({ announcementBannerVisibility }) =>
            announcementBannerVisibility && '40px !important'};

        z-index: ${({ announcementBannerVisibility, path }) =>
            announcementBannerVisibility &&
            path.startsWith(EVENT_LANDING_PATH) &&
            '998 !important'};

        @media (min-width: 641px) and (max-width: 960px) {
            top: ${({ announcementBannerVisibility }) =>
                announcementBannerVisibility && '56px !important'};
        }

        @media (max-width: 640px) {
            top: ${({ announcementBannerVisibility }) =>
                announcementBannerVisibility && '52px !important'};
        }
    }
    .submenu-scrollview {
        height: ${({ announcementBannerVisibility }) =>
            announcementBannerVisibility && 'calc(100vh - 115px) !important'};
    }
    .submenu-container {
        top: ${({ announcementBannerVisibility }) =>
            announcementBannerVisibility && '64px !important'};
    }
    .event-landing-navbar {
        top: ${({ announcementBannerVisibility }) =>
            announcementBannerVisibility && '40px !important'};

        @media (min-width: 641px) and (max-width: 960px) {
            top: ${({ announcementBannerVisibility }) =>
                announcementBannerVisibility && '56px !important'};
        }

        @media (max-width: 640px) {
            top: ${({ announcementBannerVisibility }) =>
                announcementBannerVisibility && '52px !important'};
        }
    }

    .event-landing-side-panel {
        top: ${({ announcementBannerVisibility }) =>
            announcementBannerVisibility && '72px !important'};
    }

    .app-content {
        padding-top: ${({ announcementBannerVisibility, path }) =>
            announcementBannerVisibility &&
            path.startsWith(EVENT_LANDING_PATH) &&
            '100px !important'};
    }

    .event-access-btn {
        @media (max-width: 960px) {
            bottom: ${({ cookieBannerVisibility, path }) =>
                cookieBannerVisibility &&
                path.startsWith(EVENT_LANDING_PATH) &&
                '56px !important'};
        }
        @media (max-width: 640px) {
            bottom: ${({ cookieBannerVisibility, path }) =>
                cookieBannerVisibility &&
                path.startsWith(EVENT_LANDING_PATH) &&
                '68px !important'};
        }
    }
    .page-ticketing .page--fill { {
        @media only screen 
        and (min-device-width : 768px) 
        and (max-device-width : 1024px) 
        and (orientation : portrait) {
            min-height: ${({ cookieBannerVisibility, path }) =>
                cookieBannerVisibility && 'calc(100vh - 380px) !important'};
        }

        @media(max-width: 767px){
            min-height: ${({ cookieBannerVisibility, path }) =>
                cookieBannerVisibility && 'calc(100vh - 410px) !important'};
        }
    }
    .page-ticketing .event-access-btn {
        @media only screen 
        and (min-device-width : 768px) 
        and (max-device-width : 1024px) 
        and (orientation : portrait) {
            bottom: ${({ cookieBannerVisibility, path }) =>
                cookieBannerVisibility &&
                path.startsWith(EVENT_LANDING_PATH) &&
                '56px !important'};
        }
        @media (max-width: 767px) and (min-width: 641px) {
            bottom: ${({ cookieBannerVisibility, path }) =>
                cookieBannerVisibility &&
                path.startsWith(EVENT_LANDING_PATH) &&
                '56px !important'};
        }
        @media (max-width: 640px) {
            bottom: ${({ cookieBannerVisibility, path }) =>
                cookieBannerVisibility &&
                path.startsWith(EVENT_LANDING_PATH) &&
                '68px !important'};
        }
    }

    .gdpr-footer-row {
        padding-bottom: ${({ cookieBannerVisibility }) =>
            cookieBannerVisibility && '92px !important'};

        @media (max-width: 960px) {
            padding-bottom: ${({ cookieBannerVisibility }) =>
                cookieBannerVisibility && '112px !important'};
        }

        @media (max-width: 640px) {
            padding-bottom: ${({ cookieBannerVisibility }) =>
                cookieBannerVisibility && '98px !important'};
        }
    }

    .gdpr-footer-row-mobile {
        padding-bottom: ${({ cookieBannerVisibility }) =>
            cookieBannerVisibility && '98px !important'};
    }

    .h-footer {
        padding-bottom: ${({ cookieBannerVisibility, path }) =>
            cookieBannerVisibility &&
            path.startsWith(EVENT_LANDING_PATH) &&
            '51px !important'};

        @media (max-width: 960px) {
            padding-bottom: ${({ cookieBannerVisibility, path }) =>
                cookieBannerVisibility &&
                path.startsWith(EVENT_LANDING_PATH) &&
                '71px !important'};
        }

        @media (max-width: 640px) {
            padding-bottom: ${({ cookieBannerVisibility, path }) =>
                cookieBannerVisibility &&
                path.startsWith(EVENT_LANDING_PATH) &&
                '88px !important'};
        }
    }
`;
