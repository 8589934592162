import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

function useBeamerWidget() {
    const template = useSelector((state) => state.layout.header.template);

    useEffect(() => {
        const beamerEmbedScript = document.querySelector(
            'script[src="https://app.getbeamer.com/js/beamer-embed.js"]'
        );

        if (
            !beamerEmbedScript &&
            process.env.REACT_APP_BEAMER_WIDGET_ENABLED === 'true' &&
            template === 'community'
        ) {
            const configScript = document.createElement('script');
            const embedScript = document.createElement('script');

            const inlineConfigScript = document.createTextNode(
                `var beamer_config = {product_id: '${process.env.REACT_APP_BEAMER_PRODUCT_ID}', selector: '.beamerButton', button: false, first_visit_unread: 0,};`
            );
            configScript.appendChild(inlineConfigScript);

            embedScript.type = 'text/javascript';
            embedScript.src = 'https://app.getbeamer.com/js/beamer-embed.js';
            embedScript.defer = true;

            document.body.appendChild(configScript);
            document.body.appendChild(embedScript);
        } else if (template !== 'community') {
            const beamerLastPostTitle = document.getElementById(
                'beamerLastPostTitle'
            );
            const beamerPopperScript = document.getElementById(
                'beamerPopperScript'
            );

            [
                beamerEmbedScript,
                beamerLastPostTitle,
                beamerPopperScript,
            ].forEach((script) => {
                try {
                    if (script && document.body.contains(script)) {
                        document.body.removeChild(script);
                    }
                } catch {
                    return false;
                }
            });
        }
    }, [template]);

    const updateBeamerUserData = useCallback((beamerUserData) => {
        if (
            window.Beamer &&
            !_isEmpty(window.beamer_config) &&
            !_isEqual(window.beamer_config, {
                ...window.beamer_config,
                ...beamerUserData,
            })
        ) {
            window.Beamer.update(beamerUserData);
        }
    }, []);

    const createBeamerSegmentString = useCallback((preConvertedData) => {
        if (_isArray(preConvertedData)) {
            return preConvertedData.join(';');
        }
        return null;
    }, []);

    return { updateBeamerUserData, createBeamerSegmentString };
}

export default useBeamerWidget;
