let zIndexTypes = {
    above: 'above',
    below: 'below',
    top: 'top',
    bottom: 'bottom',
};

const zIndexRanges = {
    initial: 'initial',
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    '2xl': '2xl',
    '3xl': '3xl',
};

const zIndexRangeMap = {
    initial: 1,
    xs: 99,
    sm: 499,
    md: 1050, // to handle modals
    lg: 1999,
    xl: 2999,
    '2xl': 4999,
    '3xl': 9999,
};

const defaultStep = 1;

const getWithImportance = (val, isImportant) =>
    isImportant ? `${val} !important` : val;

const resetZIndex = ({ zIndexRange, step = defaultStep }) =>
    (zIndexRangeMap[zIndexRange] = zIndexRangeMap[zIndexRange] - step);

const getZIndex = ({
    zIndexType,
    zIndexRange,
    isImportant = false,
    step = defaultStep,
}) => {
    switch (zIndexType) {
        case zIndexTypes.above:
            zIndexRangeMap[zIndexRange] = zIndexRangeMap[zIndexRange] + step;
            return getWithImportance(zIndexRangeMap[zIndexRange], isImportant);

        case zIndexTypes.below:
            const belowZIndexVal = zIndexRangeMap[zIndexRange] - step;
            return getWithImportance(belowZIndexVal, isImportant);

        case zIndexTypes.top:
            const max = zIndexRangeMap[zIndexRangeMap['3xl']] + step;
            return getWithImportance(max, isImportant);

        case zIndexTypes.bottom: {
            const min = zIndexRangeMap[zIndexRangeMap.initial] - step;
            return getWithImportance(min, isImportant);
        }
        default: {
            return getWithImportance(
                zIndexRangeMap[zIndexRangeMap.initial],
                isImportant
            );
        }
    }
};

export { zIndexTypes, zIndexRanges, getZIndex, resetZIndex };
