export const ADD_DND_USERS = 'ADD_DND_USERS';
export const REMOVE_DND_USERS = 'REMOVE_DND_USER';

export const addDndUsers = (dndUsers) => {
    return {
        payload: dndUsers,
        type: ADD_DND_USERS,
    };
};

export const removeDndUser = (userId) => {
    return {
        payload: userId,
        type: REMOVE_DND_USERS,
    };
};
