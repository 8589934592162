export const DEFAULT_THUMBNAIL = {
    IMAGE: 'gallery_thumbnail',
    DOCUMENT: 'document',
    Spreadsheet: 'ic-spreadsheet',
    AUDIO: 'ic-audio',
    VIDEO: 'ic-video',
    LINK: 'link',
    Presentation: 'ic-presentation',
};

export const DEFAULT_THUMBNAIL_V2 = {
    IMAGE: 'file-type-image',
    DOCUMENT: 'file-type-document',
    Spreadsheet: 'file-type-document',
    AUDIO: 'file-type-audio',
    VIDEO: 'file-type-video',
    LINK: 'file-type-link',
    Presentation: 'file-type-document',
};

export const FILE_TYPE_MAP = {
    DOCUMENT: [
        '.pdf',
        '.ppt',
        '.pptx',
        '.csv',
        '.txt',
        '.odt',
        '.csv',
        '.xls',
        '.xlsx',
        '.xlsm',
        '.ods',
        '.doc',
        '.docx',
        '.key',
        '.odp',
        '.numbers',
    ],
    AUDIO: ['.mp3', '.wav'],
    VIDEO: ['.avi', '.h264', '.h265', '.mov', '.mp4'],
    IMAGE: ['.gif', '.jpg', '.jpeg', '.png', '.webp', '.svg'],
};

export const NON_VIEWABLE_VIDEO_AVI_FORMATS = ['video/avi', 'video/x-msvideo'];
export const UNDETECTED_MEDIA_FILE_TYPE = [
    { ext: '.numbers', type: 'application/vnd.apple.numbers' },
    { ext: '.key', type: 'application/vnd.apple.keynote' },
];

export const MAX_RESOURCE_SIMULTANEOUS_LIMIT = 200;
export const MAX_RESOURCE_LIMIT = 500;
export const CAPS_TO_LOW_RESOURCENAMES = {
    VIDEO: 'Video',
    DOCUMENT: 'Document',
    PPT: 'PPT',
    IMAGE: 'Image',
    AUDIO: 'Audio',
    LINK: 'Link',
};

export const ATTENDEE_FILTERS = [
    { key: 'ALL', label: 'All' },
    { key: 'DOCUMENT', label: 'Document' },
    { key: 'VIDEO', label: 'Video' },
    { key: 'AUDIO', label: 'Audio' },
    { key: 'IMAGE', label: 'Image' },
    { key: 'LINK', label: 'Link' },
];

export const ORGANIZER_FILTERS = [
    { key: 'ALL', label: 'All' },
    { key: 'DOCUMENT', label: 'Document' },
    { key: 'VIDEO', label: 'Video' },
    { key: 'AUDIO', label: 'Audio' },
    { key: 'IMAGE', label: 'Image' },
    { key: 'LINK', label: 'Link' },
    { key: 'HIDDEN', label: 'Hidden' },
];

export const LINK_INITIAL_STATE = {
    fileName: '',
    fileLink: '',
    isEditMode: true,
};

export const RESOURCES_CONTAINER_WIDTH = 75;

export const RESOURCES_GUTTER_SIZE = 16;

export const RESOURCES_CARD_WIDTH = 250 + RESOURCES_GUTTER_SIZE;
