import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import React from 'react';

export default function Pill({
    title,
    selected = false,
    style = {},
    isEmpty = false,
    ...rest
}) {
    const { theme } = useTheme();

    return (
        <FlexDiv
            py={0}
            px={1}
            backgroundColor={(selected || isEmpty) && 'ambience.21'}
            borderRadius='6px'
            border={`1px solid ${
                selected || isEmpty
                    ? theme?.isLightTheme
                        ? theme.colors.ambience[22]
                        : '#302E38'
                    : 'transparent'
            }`}
            style={{
                cursor: 'pointer',
                ...(isEmpty
                    ? {
                          minHeight: '22px',
                          minWidth: '70px',
                      }
                    : {}),
                ...(style || {}),
            }}
            {...rest}
        >
            <Typography
                variant='caption'
                color='ambience.0'
                style={{
                    fontSize: '10px',
                    lineHeight: '12px',
                    cursor: 'pointer',
                }}
            >
                {isEmpty ? ' ' : title}
            </Typography>
        </FlexDiv>
    );
}
