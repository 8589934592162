import { v4 as uuid } from 'uuid';
import { startWorker, stopWorker } from '../workers';
import { WORKER_NAME_LOGGER } from './constants/workers';
import {
    attachWorker,
    bindFunctionLogger,
    bindModuleLogger,
    localLogger,
} from './loggers/localLogger';
import { closeInterceptorConnection } from './WebsocketService';

export const logger = localLogger;
export { bindModuleLogger, bindFunctionLogger };

let attachedWorker = null;
const loggerMeta = {};

// The logger stores a tracking ID in session storage
try {
    if (window.sessionStorage) {
        let storedLogTrace = window.sessionStorage.getItem(
            'airmeet-log-tracking-id'
        );

        if (!storedLogTrace) {
            storedLogTrace = uuid();
            window.sessionStorage.setItem(
                'airmeet-log-tracking-id',
                storedLogTrace
            );
        }

        loggerMeta.trackingID = storedLogTrace;
    }
} catch {
    logger.error('Session Storage is disabled');
}

async function setEventMetaData(user, airmeetId, logWorker) {
    await logWorker.setTags({ airmeetId, ...loggerMeta });
    await logWorker.setUser(user);
}

export async function initWorker(user, airmeetId) {
    try {
        if (attachedWorker !== null) {
            await stopWorker(WORKER_NAME_LOGGER);
            closeInterceptorConnection();
        }
    } catch (error) {
        logger.error('Error re-initialising Log Worker', error);
    }

    const logWorker = await startWorker(WORKER_NAME_LOGGER);
    setEventMetaData(user, airmeetId, logWorker);

    addLoggerTags({
        buildID:
            process.env.BUILD_ID ||
            process.env.npm_package_version ||
            process.env.REACT_APP_ENV,
    });

    attachWorker(logWorker);
    attachedWorker = logWorker;
}

export async function updateWorkerSettings(settings) {
    // Never update local settings
    if (!attachedWorker) {
        return;
    }

    // Update the attached worker settings
    attachedWorker.updateSettings(settings);
}

export async function addLoggerTags(tags) {
    const logWorker = await startWorker(WORKER_NAME_LOGGER);
    await logWorker.setTags(tags);
}

export const log = localLogger.log.bind(localLogger);

window.addEventListener('error', function (e) {
    try {
        logger.error(e.message, e.error.stack);
    } catch (e) {
        /* Error in error handling, that sucks */
    }
});

window.addEventListener('unhandledrejection', function (e) {
    try {
        logger.error(e.message, e.error.stack);
    } catch (e) {
        /* Error in error handling, that sucks */
    }
});
