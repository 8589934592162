export const CREATE_LEAD = 'CREATE_LEAD';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const GET_LEAD = 'GET_LEAD';

export const LeadEventTypes = {
    createsEvent: 'CREATED_EVENT',
    conferenceTrialContactLead: 'CONFERENCE_TRIAL_CONTACT_SALES',
    conferenceTrialLiveDemo: 'CONFERENCE_TRIAL_LIVE_DEMO',
    conferenceTrialEventCreated: 'CONFERENCE_TRIAL_EVENT_CREATED',
};
