import { AirmeetTranslationProvider } from 'hooks/useCustomTranslation';
import React from 'react';

export const StorybookContext = React.createContext(null);
export function StorybookMetaContext({ children }) {
    const isStorybook = true;
    // if (['dev', 'test'].includes(process.env.REACT_APP_ENV)) {
    //     //fullproof prod bypass here
    // }
    return (
        <StorybookContext.Provider value={{ isStorybook }}>
            <AirmeetTranslationProvider>{children}</AirmeetTranslationProvider>
        </StorybookContext.Provider>
    );
}
