import { emptyObject } from 'utils/constants/common';
import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import { LOG_LEVEL } from 'utils/constants/logger';

const ShowOnStageContentDataWriter = ({
    airmeetId,
    client,
    payload,
    metaData,
    logger,
}) => {
    const ShowOnStageContentLog = logger.init(
        'SHOW_ON_STAGE_CONTENT_DATA_WRITER',
        'green',
        LOG_LEVEL.INFO
    );

    const { ADD, CLEAR } = FEATURE_ACTIONS[FEATURE_NAMES.SHOW_ON_STAGE_CONTENT];
    const { action, stageId, contentType, contentId } = metaData;

    if (!action) {
        throw new Error('No action is specified to show on stage content.');
    }

    if (!airmeetId) {
        throw new Error('AirmeetId is required to show on stage content.');
    }

    if (!stageId) {
        throw new Error('StageId is required to show on stage content.');
    }

    if (!contentType) {
        throw new Error(
            'contentType is required to show/remove on stage content.'
        );
    }

    if (action !== CLEAR && !contentId) {
        throw new Error(
            'contentId is required to show/remove on stage content.'
        );
    }
    const contentPath =
        action === CLEAR ? `${contentType}` : `${contentType}/${contentId}`;
    const _path = `${airmeetId}/meta-data/sessions/${stageId}/showOnStageContent/${contentPath}`;

    const { featureName } = payload || emptyObject;

    const logMessage =
        action === ADD
            ? `Adding ${
                  featureName || 'content'
              } on stage for session ${stageId}.`
            : `Removing content from the stage for session ${stageId}`;

    ShowOnStageContentLog(logMessage);

    /*
     Adding content on stage/Removing from stage. 
    */
    return client.setDataAsync(_path, payload);
};

export default ShowOnStageContentDataWriter;
