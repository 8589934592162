import { isChromeBrowser } from 'utils/browserCheck';
import { PREF_AUDIO_OUT } from 'utils/constants/deviceSettings';
import PWLogger from 'utils/loggers/pwLogger';

const BLANK = '';

interface IDeviceList {
    audioDevices: MediaDeviceInfo[];
    audioOutputDevices: MediaDeviceInfo[];
    videoDevices: MediaDeviceInfo[];
}

interface IConfigDefaults {
    cameraId?: string;
    microphoneId?: string;
    speakerId?: string;
}

export const DEFAULT_SYSTEM_SPEAKER = {
    deviceId: 'default',
    label: 'System Default Speaker Device',
    kind: 'audiooutput',
};

export const fetchDefaultSpeaker = (
    devices: IDeviceList,
    configDefaults: IConfigDefaults = {}
) => {
    const { audioOutputDevices } = devices;
    if (!Array.isArray(audioOutputDevices) || !audioOutputDevices.length) {
        /**
         * If audioOutputDevices are not present then send DEFAULT_SYSTEM_SPEAKER for non-chrome browsers.
         * In non-chrome browsers, speakers are not accessible through browser.
         * If browser is chrome but still list is empty, then speakers are not available in system.
         */
        return isChromeBrowser() ? BLANK : DEFAULT_SYSTEM_SPEAKER;
    }

    const prevSelectedSpeakerId =
        configDefaults.speakerId || localStorage.getItem(PREF_AUDIO_OUT);
    const prevSelectedSpeaker = audioOutputDevices.find(
        (device) => device.deviceId === prevSelectedSpeakerId
    );

    return prevSelectedSpeaker || audioOutputDevices[0];
};

export const checkStreamDefaultDevices = (
    devices: IDeviceList,
    stream: any,
    configDefaults: IConfigDefaults
) => {
    const videoTrack = getVideoTrackFromStream(stream) as MediaStreamTrack;
    const audioTrack = getAudioTrackFromStream(stream) as MediaStreamTrack;
    const streamMicId = audioTrack?.getSettings()?.deviceId;
    const streamCameraId = videoTrack?.getSettings()?.deviceId;
    return {
        microphone: devices
            ? audioTrack
                ? devices.audioDevices.find(
                      (device) => device.deviceId === streamMicId
                  )
                : configDefaults && configDefaults.microphoneId
                ? devices.audioDevices.find(
                      (device) =>
                          device.deviceId === configDefaults.microphoneId
                  )
                : ''
            : '',
        camera: devices
            ? videoTrack
                ? devices.videoDevices.find(
                      (device) => device.deviceId === streamCameraId
                  )
                : configDefaults && configDefaults.cameraId
                ? devices.videoDevices.find(
                      (device) => device.deviceId === configDefaults.cameraId
                  )
                : ''
            : '',
        speaker: fetchDefaultSpeaker(devices, configDefaults),
    };
};

const STREAM_INIT_TIMEOUT = 30 * 1000;

const streamInitLogger = PWLogger('PW-V3 StreamInit :', '#00acee');

export const streamInitPromise = (stream) => {
    return new Promise((resolve) => {
        InitStream(stream)
            .then((result) => {
                streamInitLogger.info(
                    'Browser permission stream init successfully'
                );
                resolve(null);
            })
            .catch((error) => {
                streamInitLogger.info('Browser permission stream init failed');
                resolve(error);
            });
    });
};

const InitStream = (stream) => {
    return new Promise<void>((resolve, reject) => {
        if (stream?.init != null) {
            // for Vendor APIs
            const timerId = setTimeout(() => {
                streamInitLogger.error(
                    `Browser permission stream init timeout after ${STREAM_INIT_TIMEOUT} ms`
                );
            }, STREAM_INIT_TIMEOUT);
            streamInitLogger.info(
                'Browser permission stream init promise called'
            );
            stream.init(
                () => {
                    if (timerId) clearTimeout(timerId);
                    resolve();
                },
                (err) => {
                    if (timerId) clearTimeout(timerId);
                    reject(err);
                }
            );
        } else {
            // for browser APIs
            if (stream) {
                resolve();
            } else {
                reject('Permission Check Failed, No stream found');
            }
        }
    });
};

function getVideoTrackFromStream(stream) {
    if (stream?.getVideoTrack != null) {
        // for Vendor APIs
        return stream?.getVideoTrack?.() ?? null;
    } else {
        // for browser APIs
        return stream?.videoStream?.getVideoTracks?.()?.[0] ?? null;
    }
}

function getAudioTrackFromStream(stream) {
    if (stream?.getAudioTrack != null) {
        // for Vendor APIs
        return stream?.getAudioTrack?.() ?? null;
    } else {
        // for browser APIs
        return stream?.audioStream?.getAudioTracks?.()?.[0] ?? null;
    }
}
