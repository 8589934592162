import { utcToZonedTime } from 'date-fns-tz';
import add from 'date-fns/add';
import endOfDay from 'date-fns/endOfDay';
import React from 'react';
import styled from 'styled-components';
import { Box } from 'components/molecules/utils/basicComponents';
import TimePicker from 'components/general/Form/TimepPicker';
import { FormFieldWIcon } from '../../../editPublicAirmeet/Sessions/SessionForm/SessionActivity/styledComponents';
import { StyledSvgSprite } from 'components/general/SVGSprite';

export const CreateAirmeetTime = ({
    value,
    timezone,
    name,
    id,
    label = '',
    subLabel = '',
    disabled,
    newOnBoardingFlow = false,
    pageMode = false,
    onStartTimeFocus = () => {},
    ...props
}) => {
    const DEFAULT_MAX_TIME = endOfDay(
        utcToZonedTime(add(new Date(), { years: 5 }), timezone)
    );

    return (
        <StyledBox>
            <FormFieldWIcon
                id={id}
                name={name}
                autoComplete={'off'}
                custom
                variant={'v2'}
                label={label}
                subLabel={subLabel}
                filledValue={value}
                newOnBoardingFlow={newOnBoardingFlow}
                inputValue={pageMode ? value : null}
                onFocus={onStartTimeFocus}
                isThemeV2={true}
            >
                <TimePicker {...props} max={DEFAULT_MAX_TIME} />
                <StyledSvgSprite
                    position={'absolute'}
                    top={'50%'}
                    right={'18px'}
                    icon={'icon-time-nofill'}
                    height={16}
                    width={16}
                    fill={'ambience.8'}
                    style={{
                        transform: 'translateY(-50%)',
                        zIndex: 1,
                        pointerEvents: 'none',
                    }}
                />
            </FormFieldWIcon>
        </StyledBox>
    );
};

const StyledBox = styled(Box)`
    width: 100%;
    max-width: 250px;
    margin-right: 25px;
    &:last-child {
        margin-right: 0;
    }
    .react-datepicker__input-container {
        background-color: ${({ theme }) =>
            theme.colors.ambience[22]} !important;
        border-radius: 8px;
        input {
            color: ${({ theme }) => theme.colors.ambience[2]} !important;
            font-family: ${({ theme }) => theme.fonts.book} !important;
            font-size: 14px !important;
        }
    }
`;
