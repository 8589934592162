export const POST_EVENT_DEFAULT_SESSION_OPTION = {
    value: 'all',
    label: 'All sessions',
};

export const POST_EVENT_TIME_FILTER_OPTIONS = [
    { value: 'year', label: 'This year' },
    { value: 'month', label: 'This month' },
    { value: 'week', label: 'This week' },
];
