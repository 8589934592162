import createReducer from '../../utils/createReducer';
import {
    AIRMEET_FEATURED_SUCCESS,
    RESET_COMMUNITY_LOADER,
} from '../actions/airmeet';

const initialState = {
    isLoading: false,
};

const handlers = {};
handlers[AIRMEET_FEATURED_SUCCESS] = (state = initialState, action) => {
    if (!action.payload) {
        return state;
    }
    const airmeet = action.payload;
    return { ...state, airmeet };
};
handlers[RESET_COMMUNITY_LOADER] = (state = initialState, action) => {
    const { payload } = action;
    if (!action.payload) {
        return state;
    }
    const isLoading = payload?.isLoading;
    return { ...state, isLoading };
};

export default createReducer(initialState, handlers);
