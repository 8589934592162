import useBanner from 'hooks/useBanner';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { select } from 'utils/constants/common';
import { DEFAULT_BRAND_LOGO } from 'utils/constants/tenantDetails';
import { cdnImage } from 'utils/core';
import { getCurrentTenantData, IS_CUSTOM_TENANT } from 'utils/tenant';

function useSummitBranding() {
    const hasBranding = useLiveAirmeetContext(
        select('airmeet.featureFlags.branding', false)
    );

    const { brandLogo, title } = useBanner();
    const isAirmeetLogo = brandLogo?.includes(DEFAULT_BRAND_LOGO);

    let logoSrc = cdnImage(getCurrentTenantData('logoNoTextUrl'));
    let logoAlt = 'logo';
    if (IS_CUSTOM_TENANT && brandLogo && !isAirmeetLogo) {
        logoSrc = brandLogo;
        logoAlt = `${title} logo`;
    } else if (!IS_CUSTOM_TENANT && brandLogo) {
        logoSrc = brandLogo;
        logoAlt = `${isAirmeetLogo ? 'Airmeet' : title} logo`;
    }

    return {
        hasBranding,
        logo: {
            // In case of API failure default to airmeet logo
            src: logoSrc,
            alt: logoAlt,
        },
        airmeetLogo: {
            src: cdnImage(getCurrentTenantData('logoNoTextUrl')),
            alt: 'Logo',
        },
    };
}

export default useSummitBranding;
