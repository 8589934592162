import { API_SERVICE } from 'utils/apiService';
import { CALL_API } from '../api';

export const GET_AIRMEET_TICKET_REQUEST = 'GET_AIRMEET_TICKET_REQUEST';
export const GET_AIRMEET_TICKET_RESPONSE = 'GET_AIRMEET_TICKET_RESPONSE';

export const GET_AIRMEET_COUPON_REQUEST = 'GET_AIRMEET_COUPON_REQUEST';
export const GET_AIRMEET_COUPON_RESPONSE = 'GET_AIRMEET_COUPON_RESPONSE';

export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_RESPONSE = 'CREATE_ORDER_RESPONSE';

export const GET_ORDER_DETAIL_REQUEST = 'GET_ORDER_DETAIL_REQUEST';
export const GET_ORDER_DETAIL_RESPONSE = 'GET_ORDER_DETAIL_RESPONSE';

export const RESEND_TICKET_REQUEST = 'RESEND_TICKET_REQUEST';
export const RESEND_TICKET_RESPONSE = 'RESEND_TICKET_RESPONSE';

export const VERIFY_TICKET_REQUEST = 'VERIFY_TICKET_REQUEST';
export const VERIFY_TICKET_RESPONSE = 'VERIFY_TICKET_RESPONSE';

export function getTicketDetails(airmeetId) {
    return {
        [CALL_API]: {
            types: [GET_AIRMEET_TICKET_REQUEST, GET_AIRMEET_TICKET_RESPONSE],
            endpoint: `/ticket?catalogueId=${airmeetId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function getCouponDetails(airmeetId) {
    return {
        [CALL_API]: {
            types: [GET_AIRMEET_COUPON_REQUEST, GET_AIRMEET_COUPON_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/coupons_public`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function createOrder(data) {
    return {
        [CALL_API]: {
            types: [CREATE_ORDER_REQUEST, CREATE_ORDER_RESPONSE],
            endpoint: `/order`,
            body: data,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}

export function getOrderDetail(orderId) {
    return {
        [CALL_API]: {
            types: [GET_ORDER_DETAIL_REQUEST, GET_ORDER_DETAIL_RESPONSE],
            endpoint: `/order?orderId=${orderId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function resendTicket(data) {
    return {
        [CALL_API]: {
            types: [RESEND_TICKET_REQUEST, RESEND_TICKET_RESPONSE],
            endpoint: `/order/resend`,
            body: data,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}

export function verifyTicket(data) {
    return {
        [CALL_API]: {
            types: [VERIFY_TICKET_REQUEST, VERIFY_TICKET_RESPONSE],
            endpoint: `/resend/ticket`,
            body: data,
            type: 'json',
            method: 'POST',
            service: API_SERVICE.BFF,
        },
    };
}
