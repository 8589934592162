import {
    CONTEXT_TYPE,
    SessionActionPayload,
    SessionActionTypes,
    SessionMeta,
    SessionStateActionTypes,
} from 'store/actions/userLevelSessionUpdates';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

type ContextData = null | {
    meta?: SessionMeta;
};
interface Context {
    [context: string]: {
        [contextId: string]: ContextData;
    };
}

enum UPDATE_TYPE {
    ADD,
    REMOVE,
}

type SessionUpdate = {
    [K in CONTEXT_TYPE]: Context;
};

const initialState: SessionUpdate = {
    [CONTEXT_TYPE.JOINING]: {},
    [CONTEXT_TYPE.PRESENCE]: {},
    [CONTEXT_TYPE.FINISHING]: {},
};
const add = (
    state: SessionUpdate,
    payload: SessionActionPayload,
    contextType: string
) => {
    const updatedState = { ...state };
    const currentContext = updatedState[contextType][payload.context] || {};
    updatedState[contextType][payload.context] = {
        ...currentContext,
        [payload.contextId]: {
            meta: payload.meta,
        },
    };
    return updatedState;
};

const remove = (
    state: SessionUpdate,
    payload: SessionActionPayload,
    contextType: string
) => {
    const updatedState = { ...state };
    if (payload === null) {
        updatedState[contextType] = {};
        return updatedState;
    }
    const context = updatedState[contextType][payload.context];
    if (!!context) {
        updatedState[contextType][payload.context] = omit(
            context,
            payload.contextId
        );
        if (isEmpty(updatedState[contextType][payload.context])) {
            updatedState[contextType] = omit(
                updatedState[contextType],
                payload.context
            );
        }

        return updatedState;
    }
    return state;
};

const update = (
    state: SessionUpdate,
    payload: SessionActionPayload,
    contextType: string,
    updateType: UPDATE_TYPE
) => {
    if (updateType === UPDATE_TYPE.ADD) return add(state, payload, contextType);
    if (updateType === UPDATE_TYPE.REMOVE)
        return remove(state, payload, contextType);
    return state;
};
export default function userLevelSessionUpdates(
    state = initialState,
    action: SessionStateActionTypes
): SessionUpdate {
    switch (action.type) {
        case SessionActionTypes.JOINING_STARTED:
            return update(
                state,
                action.payload,
                CONTEXT_TYPE.JOINING,
                UPDATE_TYPE.ADD
            );
        case SessionActionTypes.JOINING_FINISHED:
            return update(
                state,
                action.payload,
                CONTEXT_TYPE.JOINING,
                UPDATE_TYPE.REMOVE
            );

        case SessionActionTypes.SESSION_ENTERED:
            return update(
                state,
                action.payload,
                CONTEXT_TYPE.PRESENCE,
                UPDATE_TYPE.ADD
            );

        case SessionActionTypes.SESSION_EXITED:
            return update(
                state,
                action.payload,
                CONTEXT_TYPE.PRESENCE,
                UPDATE_TYPE.REMOVE
            );

        case SessionActionTypes.SESSION_FINISHING:
            return update(
                state,
                action.payload,
                CONTEXT_TYPE.FINISHING,
                UPDATE_TYPE.ADD
            );
        case SessionActionTypes.SESSION_FINISHED:
            return update(
                state,
                action.payload,
                CONTEXT_TYPE.FINISHING,
                UPDATE_TYPE.REMOVE
            );
        case SessionActionTypes.SESSION_FINISHING_CLEAR:
            return update(
                state,
                null,
                CONTEXT_TYPE.FINISHING,
                UPDATE_TYPE.REMOVE
            );

        default:
            return state;
    }
}
