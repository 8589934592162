import { StyledSvgSprite } from 'components/general/SVGSprite';
import WithTooltip from 'components/general/WithTooltip';
import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import { Text } from 'foundations/themeV2/text/';
import useOutsideClickHandler from 'hooks/useOutsideClickHandler';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';
import ImageUploader from 'components/community/common/ImageUploader';

const renderPlaceholder = ({ style = {} }) => {
    return (
        <UploadOption style={style || {}}>
            <ActionWrapper>
                <StyledSvgSprite
                    icon='icon-add-circle'
                    fill='accentPrimary.0'
                    height='24px'
                    width='24px'
                />
            </ActionWrapper>
        </UploadOption>
    );
};

export default function ImageSelector({
    title,
    subtitle,
    images = [],
    value,
    optionWrapperStyle = {},
    optionStyle = {},
    onChange,
    onClear,
    uploadFileId = 'customImage',
    showEditOption = false,
    showDeleteOption = false,
    onEditImage = noop,
    onDeleteImage = noop,
    showUploadOption = false,
    onUploadClick = noop,
    successUploadHandler = noop,
    isPaidPlan,
    handleUploaderCallbacks = noop,
}) {
    const handleChange = (image) => {
        if (onChange) {
            onChange(image);
        }
    };

    return (
        <WidgetWrapper>
            {title && (
                <Text
                    variant='caption'
                    color={`text.default.${subtitle ? 'primary' : 'secondary'}`}
                    pb={subtitle ? '8px' : '0px'}
                >
                    {title}
                </Text>
            )}
            {subtitle && (
                <Text variant='caption' color='text.default.secondary'>
                    {subtitle}
                </Text>
            )}
            <Box mt={isPaidPlan ? '0px' : '1em'}>
                <ImageOptionsWrapper>
                    {showUploadOption &&
                        (isPaidPlan ? (
                            <OptionWrapper
                                onClick={onUploadClick}
                                style={optionWrapperStyle || {}}
                            >
                                <WithTooltip
                                    place='bottom'
                                    className='upload-tooltip'
                                    toolClass='darkThemeTooltip'
                                    renderElement={
                                        <Text
                                            variant='caption'
                                            color='text.default.primary'
                                        >
                                            Upload
                                        </Text>
                                    }
                                    offset={`{ 'top': 20, 'left': 0}`}
                                >
                                    <ActionWrapper backgroundColor='fill.other3'>
                                        <StyledSvgSprite
                                            icon='add'
                                            height='28px'
                                            width='28px'
                                            fill='ambience.0'
                                        />
                                    </ActionWrapper>
                                </WithTooltip>
                            </OptionWrapper>
                        ) : (
                            <WithTooltip
                                place='bottom'
                                className='upload-tooltip'
                                toolClass='darkThemeTooltip'
                                renderElement={
                                    <Text
                                        variant='caption'
                                        color='text.default.primary'
                                    >
                                        Upload
                                    </Text>
                                }
                                offset={"{ 'top': 40, 'left': 10}"}
                            >
                                <ImageUploader
                                    name='eventImage'
                                    renderPlaceholder={() =>
                                        renderPlaceholder({
                                            style: optionWrapperStyle,
                                        })
                                    }
                                    filePrefix='airmeet'
                                    imageUrl={''}
                                    requestFileId={uploadFileId}
                                    formats={['image/png', 'image/jpeg']}
                                    errorMsg='Only JPEG, JPG or PNG format & no larger than 5MB.'
                                    sizeLimit={5}
                                    style={imageUploaderStyle}
                                    placeholderWrapperStyle={
                                        placeholderWrapperStyle
                                    }
                                    successUploadHandler={successUploadHandler}
                                    getRequiredCalback={handleUploaderCallbacks}
                                />
                            </WithTooltip>
                        ))}
                    {onClear && (
                        <OptionWrapper style={optionWrapperStyle || {}}>
                            <WithTooltip
                                place='bottom'
                                className='upload-tooltip'
                                toolClass='darkThemeTooltip'
                                renderElement={
                                    <Text
                                        variant='caption'
                                        color='text.default.primary'
                                    >
                                        None
                                    </Text>
                                }
                                offset={`{ 'top': 20, 'left': 0}`}
                            >
                                <ActionWrapper
                                    isChecked={!value}
                                    onClick={() => onClear()}
                                >
                                    <StyledSvgSprite
                                        icon='icon-not-allowed'
                                        height='24px'
                                        width='24px'
                                    />
                                </ActionWrapper>
                            </WithTooltip>
                        </OptionWrapper>
                    )}
                    {(images || []).map((image) =>
                        image?.id || image?.url ? (
                            <ImageOption
                                key={image?.id || image?.url}
                                image={image}
                                optionWrapperStyle={optionWrapperStyle}
                                optionStyle={optionStyle}
                                isChecked={(image?.id || image?.url) === value}
                                onChange={handleChange}
                                showEditOption={showEditOption}
                                showDeleteOption={showDeleteOption}
                                onEditImage={onEditImage}
                                onDeleteImage={onDeleteImage}
                            />
                        ) : null
                    )}
                </ImageOptionsWrapper>
            </Box>
        </WidgetWrapper>
    );
}

const ImageOption = ({
    image,
    optionWrapperStyle,
    optionStyle,
    isChecked,
    onChange,
    showEditOption = false,
    showDeleteOption = false,
    onEditImage = noop,
    onDeleteImage = noop,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const handleMenuIconClick = (e) => {
        e.stopPropagation();

        setIsMenuOpen(true);
    };

    useOutsideClickHandler(menuRef, () => {
        setIsMenuOpen(false);
    });

    const showImageOption =
        showEditOption || (showDeleteOption && !image?.isDefault);

    return (
        <OptionWrapper style={optionWrapperStyle || {}}>
            <Option
                isChecked={isChecked}
                image={image?.thumbnail || image?.url}
                style={optionStyle || {}}
                onClick={isChecked ? noop : () => onChange && onChange(image)}
            >
                {showImageOption && (
                    <>
                        <MenuIconWrapper isChecked={isChecked}>
                            <StyledSvgSprite
                                icon='icon-three-dot'
                                height='20px'
                                width='20px'
                                fill='text.default.primary'
                                onClick={handleMenuIconClick}
                            />
                        </MenuIconWrapper>
                        {isMenuOpen && (
                            <MenuWrapper ref={menuRef}>
                                {showEditOption && (
                                    <MenuItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (onEditImage) {
                                                onEditImage(image);
                                                setIsMenuOpen(false);
                                            }
                                        }}
                                    >
                                        <StyledSvgSprite
                                            icon='icon-fill-edit'
                                            height='20px'
                                            width='20px'
                                            fill='text.default.primary'
                                        />
                                        <Text
                                            variant='caption'
                                            color='text.default.primary'
                                            pl='12px'
                                        >
                                            Edit
                                        </Text>
                                    </MenuItem>
                                )}
                                {showDeleteOption && image?.isDefault ? null : (
                                    <MenuItem
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (onDeleteImage) {
                                                onDeleteImage(image, isChecked);
                                                setIsMenuOpen(false);
                                            }
                                        }}
                                    >
                                        <StyledSvgSprite
                                            icon='icon-fill-delete'
                                            height='20px'
                                            width='20px'
                                            fill='text.default.primary'
                                        />
                                        <Text
                                            variant='caption'
                                            color='text.default.primary'
                                            pl='12px'
                                        >
                                            Delete
                                        </Text>
                                    </MenuItem>
                                )}
                            </MenuWrapper>
                        )}
                    </>
                )}
                {isChecked && <CheckIcon />}
            </Option>
        </OptionWrapper>
    );
};

const CheckIcon = () => {
    return (
        <IconWrapper>
            <Icon
                icon={'checkmark_circle'}
                fill='text.default.primary'
                width={20}
                height={20}
            />
        </IconWrapper>
    );
};

const WidgetWrapper = styled.div`
    position: relative;
    padding-bottom: 20px;
`;

const ImageOptionsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .upload-tooltip {
        flex: 50%;
    }
    .image-preview {
        display: none;
    }
    #loading-spinner {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 16px;
        bottom: 16px;
        border-radius: ${({ theme }) => theme.radii.default};
        .spin-icon {
            height: 20px;
            width: 20px;
            border-width: 2px;
        }
    }
    .upload-error {
        right: 16px !important;
        bottom: 16px !important;
        padding: 5px !important;
        border-radius: ${({ theme }) => theme.radii.default} !important;
        svg.icon {
            display: none !important;
        }
        h5 {
            margin: 0 !important;
            color: ${({ theme }) =>
                theme.colors.text.default.primary} !important;
        }
        p {
            color: ${({ theme }) =>
                theme.colors.text.default.secondary} !important;
        }
    }
`;

const OptionWrapper = styled(FlexDiv)`
    flex: 50%;
    max-width: 50%;
    height: 91px;
    padding-right: 16px;
    padding-bottom: 16px;
    align-items: stretch;
    justify-content: stretch;
`;

const MenuIconWrapper = styled(FlexDiv)`
    display: none;
    position: absolute;
    justify-content: flex-end;
    align-items: flex-start;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px;
    cursor: pointer;
    ${({ isChecked }) => isChecked && 'display: flex;'}
`;

const Option = styled.div`
    position: relative;
    cursor: pointer;
    flex: 1;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: ${({ theme }) => theme.radii.default};
    background-image: url("${({ image }) => image}");
    background-color: ${({ theme }) =>
        theme?.isLightTheme ? theme.colors.fill.default2 : 'black'};
    border: solid 1px
    ${({ theme, isChecked }) =>
        isChecked ? theme.colors.text.default.primary : 'transparent'};
    &:hover {
        border: solid 1px ${({ theme }) => theme.colors.text.default.primary};
        ${MenuIconWrapper} {
            display: flex;
            background: ${({ theme }) =>
                theme.isLightTheme
                    ? `
                        background: rgba(255,255,255, 0.4);
                        background: -moz-radial-gradient(circle, rgba(255,255,255,0.004) 0%, rgba(255,255,255,0.4) 100%);
                        background: -webkit-radial-gradient(circle, rgba(255,255,255,0.004) 0%, rgba(255,255,255,0.4) 100%);
                        background: radial-gradient(circle, rgba(255,255,255,0.004) 0%, rgba(255,255,255,0.4) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
                    `
                    : `
                        background: rgba(0,0,0, 0.4);
                        background: -moz-radial-gradient(circle, rgba(0,0,0,0.004) 0%, rgba(0,0,0,0.4) 100%);
                        background: -webkit-radial-gradient(circle, rgba(0,0,0,0.004) 0%, rgba(0,0,0,0.4) 100%);
                        background: radial-gradient(circle, rgba(0,0,0,0.004) 0%, rgba(0,0,0,0.4) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1); 
                    `};
        }
    }
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
`;

const ActionWrapper = styled(FlexDiv)`
    height: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex: 1;
    border-radius: ${({ theme }) => theme.radii.default};
    border: ${({ isChecked }) => (isChecked ? '2px' : '1px')} solid
        ${({ theme, isChecked }) =>
            isChecked
                ? theme.colors.border.other1
                : theme.colors.border.default1};
`;

const UploadOption = styled(OptionWrapper)`
    flex: 1;
    max-width: none;
    padding: 0;
    svg {
        height: 24px !important;
        width: 24px !important;
    }
`;

const imageUploaderStyle = {
    padding: 0,
    width: 'auto',
    flex: '50%',
    height: '91px',
    paddingRight: '16px',
    paddingBottom: '16px',
    backgroundColor: 'transparent',
    borderRadius: 0,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
};

const placeholderWrapperStyle = {
    flex: '1',
    alignItems: 'stretch',
    justifyContent: 'stretch',
};

const MenuWrapper = styled.ul`
    position: absolute;
    background: ${({ theme }) => theme.colors.fill.default1};
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
    border-radius: ${({ theme }) => theme.radii.default};
    padding: 8px 0;
    margin: 0;
    list-style: none;
    z-index: 5;
    right: 0;
    top: 10px;
`;

const MenuItem = styled.li`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
        background: ${({ theme }) => theme.colors.fill.default2};
    }
`;
