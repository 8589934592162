import FluidspaceClientPath from 'components/FluidSpace/utils/fluidClientPath';

const fluidSpaceReactionWriter = ({
    airmeetId,
    client,
    payload,
    metaData,
    firebaseLogger,
}) => {
    const { socialFloorId, zoneId, userId, communityId, userRole } = metaData;
    if (!socialFloorId || !zoneId || !userId) {
        throw new Error('Invalid fluid space reactions firebase key');
    }
    const fluidspaceClientPath = new FluidspaceClientPath({
        airmeetId,
        sessionId: socialFloorId,
        userId: userId,
        zoneId: zoneId,
    });

    const analyticsPayload = {
        community_id: communityId,
        airmeet_id: airmeetId,
        session_id: socialFloorId,
        room_id: zoneId,
        room_number: parseInt(zoneId?.slice(zoneId?.lastIndexOf('-') + 1)),
        user_id: userId,
        user_role: userRole,
        reaction_name: payload?.reaction,
    };
    firebaseLogger.fluidLounge('fsReacted', analyticsPayload);

    return client.setData(fluidspaceClientPath.selfUserReaction, payload);
};

export default fluidSpaceReactionWriter;
