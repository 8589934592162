import { useCallback, useEffect, useState } from 'react';
import { callApi } from 'utils/apiService';
import { logger } from 'utils/logger';

const emptyObject = {};

const useSessionReplayAccessURL = ({
    airmeetId,
    sessionId,
    userId,
    canFetch = false,
    validateUrl = false,
}) => {
    const [replayInfo, setReplayURLInfo] = useState({});
    const [retry, setRetry] = useState(0);
    const fetchStreamURLInfo = useCallback(() => {
        const options = {
            airmeetId,
            sessionId,
            userId,
        };
        const body = {
            userId,
        };
        return callApi({
            endpoint: `/airmeet/${airmeetId}/${sessionId}/replay`,
            method: 'POST',
            body,
            type: 'json',
            withRetry: true,
            canIgnoreCredentials: false,
        })
            .then(async (response) => {
                logger.info('Fetch replay access info success!', {
                    ...options,
                    extension: response?.extension,
                    type: response?.type,
                });
                const data = {
                    isAllowed: true,
                    url: response.url,
                    isM3U8URL: response.extension === 'm3u8',
                    isMP4URL: response.extension === 'mp4',
                    isSecure: true,
                    isValidate: validateUrl,
                };
                if (!validateUrl) {
                    return data;
                }
                try {
                    data.isValidUrl = await checkForReplayAccessURL(data.url, {
                        isSecure: data.isSecure,
                    });
                } catch (error) {
                    data.isValidUrl = false;
                    data.errorMessage = error?.message || 'NOT_VALID_URL';
                    data.error = {
                        isValidUrl: false,
                        message: data.errorMessage,
                    };
                } finally {
                    return data;
                }
            })
            .catch((error) => {
                logger.error(
                    'Fetch replay access info failed!',
                    options,
                    error
                );
                return {
                    error: error?.json,
                    errorMessage: error?.message,
                };
            });
    }, [airmeetId, sessionId, userId, validateUrl]);

    useEffect(() => {
        setReplayURLInfo(emptyObject);
        if (canFetch && retry < 10) {
            fetchStreamURLInfo().then((data) => {
                setReplayURLInfo(data);
            });
        }
    }, [fetchStreamURLInfo, canFetch, retry]);

    const retryCallback = useCallback(() => {
        setRetry((preRetry) => preRetry++);
    }, []);

    (replayInfo || emptyObject).retryCallback = retryCallback;
    return replayInfo;
};

export default useSessionReplayAccessURL;

export const checkForReplayAccessURL = (replayURL, props = {}) => {
    return new Promise((resolve, reject) => {
        if (!replayURL) {
            return reject({
                message: 'EMPTY_URL',
            });
        }
        const { isSecure = true } = props;
        const fetchOptions = {
            method: 'HEAD',
        };

        if (isSecure) {
            fetchOptions['credentials'] = 'include';
            fetchOptions['headers'] = {
                Pragma: 'no-cache',
                'Cache-Control': 'no-cache',
            };
        }
        fetch(`${replayURL}`, fetchOptions)
            .then((response) => {
                if (response && response.status === 200) {
                    return resolve(true);
                }
                logger.error('Validation for replay access url failed!', {
                    replayURL,
                    statusCode: response?.status,
                });
                if (response && response.status === 404) {
                    return reject({
                        message: 'NOT_FOUND',
                    });
                }
                if (response && response.status === 403) {
                    return reject({
                        message: 'NOT_ALLOWED',
                    });
                }
                return reject({
                    message: 'NOT_VALIDATE',
                });
            })
            .catch((error) => {
                logger.error('Validation for replay access url failed!', error);
                return reject({
                    message: 'NOT_VALIDATE',
                });
            });
    });
};
