import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';

const { QNACOMMENTS } = FEATURE_NAMES;

const commentsWriter = async ({
    airmeetId,
    sessionId,
    client,
    payload,
    metaData,
    logger,
}) => {
    if (!airmeetId || !sessionId) {
        throw new Error('Invalid question firebase key');
    } else {
        let commentBasePath = `${airmeetId}/liveAirmeet/sessions/${sessionId}/comments/${metaData.questionId}`;
        let questionsDataPath = `${airmeetId}/liveAirmeet/sessions/${sessionId}/questions/${metaData.questionId}`;
        switch (metaData.action) {
            case FEATURE_ACTIONS[QNACOMMENTS].ADD_COMMENT:
                logger.info('Adding New Comment');
                const updateData = {};
                updateData[`${commentBasePath}/${metaData.chatKey}`] = payload;
                updateData[
                    `${questionsDataPath}/commentCount`
                ] = client.getIncrementRef(1);

                if (!metaData.chatKey || !metaData.userId) {
                    throw new Error('Invalid add comment firebase key');
                }
                return client.atomicUpdate(updateData);
            case FEATURE_ACTIONS[QNACOMMENTS].POST_COMMENT_AS_ANSWER:
                logger.info('Posted comment as answer');
                const updatedData = {};
                updatedData[`${commentBasePath}/${metaData.chatKey}`] = payload;
                updatedData[`${questionsDataPath}/isAnswered`] = true;
                updatedData[
                    `${questionsDataPath}/commentCount`
                ] = client.getIncrementRef(1);
                return client.atomicUpdate(updatedData);
            case FEATURE_ACTIONS[QNACOMMENTS].MARK_ANSWER:
                logger.info('Posted comment as answer');
                const updatedCommentData = {};
                updatedCommentData[
                    `${commentBasePath}/${metaData.chatKey}/isAnswered`
                ] = true;
                updatedCommentData[`${questionsDataPath}/isAnswered`] = true;
                return client.atomicUpdate(updatedCommentData);
            case FEATURE_ACTIONS[QNACOMMENTS].REMOVE_COMMENT:
                logger.info('Removed comment');
                return client.deleteNode(
                    `${commentBasePath}/${metaData.chatKey}`
                );
            case FEATURE_ACTIONS[QNACOMMENTS].DECREMENT_COMMENT:
                logger.info('Decrement Count');
                const updatedCommentCount = {};
                updatedCommentCount[
                    `${questionsDataPath}/commentCount`
                ] = client.getIncrementRef(-1);
                return client.atomicUpdate(updatedCommentCount);
            default:
                return null;
        }
    }
};

export default commentsWriter;
