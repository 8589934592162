export const SESSIONS_TAB = 'infoActive';
export const RAISE_HAND_TAB = 'rhActive';
export const ATTENDEES_TAB = 'attendeesActive';
export const CHAT_TAB = 'chatActive';
export const QUESTIONS_TAB = 'questionActive';
export const MANAGE_TAB = 'manageActive';
export const DEVICE_CHOOSER_TAB = 'deviceChooser';
export const POLLS_TAB = 'pollActive';
export const PRE_RECORDED_VIDEOS_TAB = 'prvActive';
export const DOWNLOAD_CHROME_LINK = 'https://www.google.com/intl/en_in/chrome/';
export const DOWNLOAD_EDGE_LINK = 'https://www.microsoft.com/en-us/edge';
export const FILTER_SUPPORTED = 'filter_supported';
export const ENABLE_MULTIPLE_LOUNGE = 'enableMultipleLounge';

export const BLOCKED_USER_CONTROLS = [
    'chatActive',
    'questionActive',
    'rhActive',
    'attendeesActive',
];
