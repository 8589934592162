import { TOAST_VERSION_2 } from 'utils/constants/toasts';

export const AIR_GENIE_MAX_DESC_LENGTH = 150;

export const AIR_GINIE_TONE_OPTIONS = [
    {
        value: 'friendly',
        label: 'Friendly',
    },
    {
        value: 'professional',
        label: 'Professional',
    },
    {
        value: 'witty',
        label: 'Witty',
    },
];

export const AIR_GENIE_IMAGE_STYLE_OPTIONS = [
    {
        value: 'illustration',
        label: 'Illustration',
    },
    {
        value: 'painting',
        label: 'Painting',
    },
    {
        value: 'realistic',
        label: 'Realistic',
    },
    {
        value: 'minimalistic',
        label: 'Minimalistic',
    },
];

export const airGenieModalStyleObj = {
    closeBtnStyle: {
        minWidth: '24px',
        minHeight: '24px',
        maxWidth: '24px',
        maxHeight: '24px',
        background: 'transparent',
        padding: 0,
    },
    closeIconStyle: {
        fontFamily: 'NotoSans-SemiBold,sans-serif',
        fontSize: 28,
        lineHeight: '24px',
        fontWeight: '100',
    },
    modalStyle: {
        maxWidth: '1000px',
    },
};

export const getErrorMessage = (e) => {
    const apiErrorUserMessage = e?.json?.payload?.error?.userMessage;
    const apiErrorMessage = e?.json?.payload?.error?.message;
    const errorMessage = e?.message;
    if (apiErrorUserMessage || apiErrorMessage || errorMessage) {
        if (apiErrorUserMessage) {
            return apiErrorUserMessage;
        }
        if ((apiErrorMessage || '').includes('ConnectionResetError')) {
            return 'Request limit reached. Please try again after sometime';
        }
        if ((apiErrorMessage || '').includes('Please reduce your prompt')) {
            return 'Maximum length exceeded. Please reduce description length.';
        }
        if ((apiErrorMessage || '').toLowerCase().includes('timeout')) {
            return 'Server busy. Please try again after sometime.';
        }
        if ((errorMessage || '').includes('Failed to fetch')) {
            return 'Failed to fetch. Please try again after sometime';
        }
        return apiErrorMessage;
    } else {
        return 'Unable to generate suggestions. Please try again with different input';
    }
};

export const validateDescription = ({ description, errorToast }) => {
    let isValid = true;

    if (!description) {
        errorToast &&
            errorToast({
                title: 'Description is required',
                version: TOAST_VERSION_2,
            });
        isValid = false;
    } else if (!/^[a-zA-Z0-9 !@\$%\&\)\(+=._:,;\n'-]+$/g.test(description)) {
        errorToast &&
            errorToast({
                title: 'Special characters not allowed',
                version: TOAST_VERSION_2,
            });
        isValid = false;
    }

    return isValid;
};
