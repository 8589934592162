import ErrorBanner from 'components/allInfoBanners/errorBanner';
import ReportingContext from 'context/ReportingContext';
import throttle from 'lodash/throttle';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LOG_LEVEL } from 'utils/constants/logger';
import { logger } from 'utils/logger';

const IncidentLogger = logger.init(
    'User reported Incident',
    'red',
    LOG_LEVEL.ERROR
);

const defaultConfig = {
    message: 'Facing issues?',
    resolutionMessage: 'Send Report',
    hideDelay: 10000,
    fatalThreshold: 3,
    onConfirm: () => {},
};

const INCIDENT_BUFFER_SIZE = 10;
const INCIDENT_BUFFER = [];
const THROTTLE_WAIT = 60 * 1000;

let isAllowedToSend = false;
const addIncidentInBuffer = (incident) => {
    if (INCIDENT_BUFFER.length >= INCIDENT_BUFFER_SIZE) {
        INCIDENT_BUFFER.shift();
    }
    INCIDENT_BUFFER.push(incident);
};

let lastIncidentTime = 0;
const MINUTE = 60 * 1000;

function getConfigForNewIncident(config) {
    let now = new Date().getTime();
    if (lastIncidentTime) {
        if (now - lastIncidentTime < MINUTE) {
            if (INCIDENT_BUFFER.length > config.fatalThreshold) {
                // WTF
                config.resolutionMessage = 'Refresh browser';
                config.permanent = true;
                let originalConfirm = config.onConfirm;
                config.onConfirm = () => {
                    originalConfirm();
                    window.location.reload();
                };
            }
        }
    }
    lastIncidentTime = now;
    return config;
}

export default function IncidentObserver() {
    const ctx = React.useContext(ReportingContext);

    const [incident, setIncident] = useState(null);
    const [configSource, setConfigSource] = useState(null);

    const clearIncidents = useCallback(() => {
        setIncident(null);
        INCIDENT_BUFFER.length = 0;
        lastIncidentTime = null;
        setConfigSource(null);
    }, []);

    const throttledSetIncident = useMemo(() => {
        return throttle(setIncident, THROTTLE_WAIT);
    }, [setIncident]);

    const sendIncidentReports = useCallback(() => {
        isAllowedToSend = true;
        if (INCIDENT_BUFFER.length) {
            INCIDENT_BUFFER.forEach(function (incidentReport) {
                IncidentLogger(incidentReport);
            });
        }
        clearIncidents();
    }, [clearIncidents]);

    const onIncident = useCallback(
        (newIncident) => {
            if (!isAllowedToSend) {
                throttledSetIncident(newIncident);
            }
            addIncidentInBuffer(newIncident);
            setConfigSource(newIncident);
            if (isAllowedToSend) {
                sendIncidentReports();
                return;
            }
        },
        [throttledSetIncident, sendIncidentReports]
    );

    let config = useMemo(() => {
        let newConfig = { ...defaultConfig };
        if (configSource?.config) {
            newConfig = { ...newConfig, ...configSource.config };
        }
        newConfig.onConfirm = sendIncidentReports;
        newConfig = getConfigForNewIncident(newConfig);
        return newConfig;
    }, [configSource, sendIncidentReports]);

    useEffect(() => {
        ctx.observeIncidents((newIncident) => {
            onIncident(newIncident);
        });
        return () => {
            ctx.stopObservingIncidents(onIncident);
        };
    }, [ctx, sendIncidentReports, onIncident]);

    if (incident) {
        return (
            <ErrorBanner
                error={incident}
                {...config}
                clearIncident={clearIncidents}
            />
        );
    }

    return null;
}
