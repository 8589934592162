import React from 'react';
import useTheme from 'hooks/useTheme';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import Modal from 'atoms/Modal/modal';
import Button from 'atoms/Button/button';
import { alpha } from 'foundations/theme';
import { Typography } from 'foundations/typography';
import { Box, FlexDiv } from 'components/molecules/utils/basicComponents';
import keys from 'locale/keys';
import { noop } from 'utils/constants/common';

const AudioResumeModal = ({ onResume }) => {
    const { theme } = useTheme();
    const { t } = useCustomTranslation();
    return (
        <Modal
            focusTrapSupport
            onClose={noop}
            style={{
                maxWidth: '460px',
                maxHeight: '441px',
                top: 'calc(50% - 50px)',
                padding: '5px 0px',
                transform: 'translateY(-50%)',
                height: 'auto',
                borderRadius: '24px',
                backgroundColor: `${theme.colors.ambience[21]}`,
            }}
            wrapperStyle={{
                backgroundColor: alpha(theme.colors.ambience[24], 0.8),
            }}
        >
            <Box>
                <FlexDiv flexDirection={'column'} textAlign='left'>
                    <Typography
                        variant={'h6'}
                        color={'ambience.0'}
                        px={4}
                        textAlign={'center'}
                    >
                        {t(keys.RESUME_AUDIO_CONTEXT)}
                    </Typography>
                    <FlexDiv flexDirection={'row'} textAlign='center'>
                        <Button
                            m={4}
                            variant='primary'
                            style={{
                                width: '182px',
                                height: '26px',
                                padding: '0px 5px',
                            }}
                            onClick={onResume}
                        >
                            {t(keys.GENERAL_RESUME)}
                        </Button>
                    </FlexDiv>
                </FlexDiv>
            </Box>
        </Modal>
    );
};

export default AudioResumeModal;
