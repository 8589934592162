import {
    GET_TICKET_RESPONSE,
    UPDATE_TICKET_RESPONSE,
    GET_COUPON_RESPONSE,
} from '../../actions/cmDashboard/tickets';

const initialState = {
    ticketData: [],
    couponData: [],
};

function tickets(state = initialState, action) {
    const { type, payload, error } = action;

    let ticket = [];

    if (payload) {
        ticket = payload.data;
    }

    switch (type) {
        case UPDATE_TICKET_RESPONSE:
            return { ...state };
        case GET_TICKET_RESPONSE:
            return {
                ...state,
                ticketData: error ? [] : ticket,
            };
        case GET_COUPON_RESPONSE:
            return {
                ...state,
                couponData: error ? [] : payload.data,
            };
        default:
            return state;
    }
}

export default tickets;
