import classnames from 'classnames';
import theme from 'foundations/theme';
import PropTypes from 'prop-types';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import styled from 'styled-components';
import { Typography } from '../../../../foundations/typography';
import Loader from '../../../general/Loader';
import ActionItem from './actionItem';
import RowSelector from './rowSelector';

const GROUP_HEADER = 'groupHeader';
const itemSize = 72 + 20;

const DraggableTbody = ({
    headers,
    rowData,
    actions,
    isLoading,
    rowSelection,
    onSelect,
    actionsWidth,
    getGroupHeaderText = () => {},
    getGroupHeaderEmptyText = () => {},
    checkboxBgColor = null,
    isVirtualized,
    metaData,
    rowHeight,
    deletePlaceBottomIndex = 1,
    liveEvent = false,
}) => {
    let emptyColSpan = headers.length;
    if (rowSelection) {
        emptyColSpan = emptyColSpan + 1;
    }
    if (actions) {
        emptyColSpan = emptyColSpan + 1;
    }

    const getItemStyle = (
        isDragging,
        draggableStyle,
        additionalStyle = {}
    ) => ({
        userSelect: 'none',
        display: isDragging ? 'flex' : '',
        borderSpacing: isDragging ? '0px' : '',
        borderCollapse: isDragging ? 'unset' : '',
        background: isDragging ? '#302E38' : '',
        alignItems: isDragging ? 'center' : '',
        ...draggableStyle,
        ...additionalStyle,
    });

    React.useEffect(() => {
        ReactTooltip.rebuild();
    });

    const Row = (props) => {
        const { index, style, data } = props;
        const height = data.height;
        return (
            <div style={style}>
                <Draggable
                    draggableId={index.toString()}
                    index={index}
                    key={index}
                    isDragDisabled={
                        rowData[index].type === GROUP_HEADER ? true : false
                    }
                >
                    {(provided, snapshot) => {
                        if (rowData[index].type === GROUP_HEADER) {
                            return (
                                <>
                                    <tr
                                        key={`tr-${index}`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <td
                                            {...provided.dragHandleProps}
                                            colSpan={emptyColSpan}
                                            className='category-row'
                                        >
                                            {getGroupHeaderText(rowData[index])}
                                        </td>
                                    </tr>
                                    {rowData[index].groupEmpty && (
                                        <tr
                                            key={`tr-${index}-empty-state`}
                                            {...provided.draggableProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <td
                                                {...provided.dragHandleProps}
                                                colSpan={emptyColSpan}
                                                className='text-center'
                                                style={{}}
                                            >
                                                <Typography
                                                    variant={'subtext1'}
                                                    color={
                                                        theme.colors
                                                            .ambience[16]
                                                    }
                                                >
                                                    {getGroupHeaderEmptyText(
                                                        rowData[index]
                                                    )}
                                                </Typography>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            );
                        }
                        return (
                            <tr
                                key={`tr-${index}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                    {
                                        display: 'flex',
                                        alignItems: 'center',
                                    }
                                )}
                            >
                                {rowSelection && (
                                    <td className='checkbox-outer'>
                                        {!rowData[index]._isSelectionHidden && (
                                            <RowSelector
                                                id={`check-${index}`}
                                                className={'row-checkbox'}
                                                onSelect={(checked) =>
                                                    onSelect(
                                                        rowData[index],
                                                        checked
                                                    )
                                                }
                                                checked={
                                                    rowData[index]._isSelected
                                                }
                                                disabled={
                                                    rowData[index]
                                                        ._isSelectionDisabled
                                                }
                                                checkboxBgColor={
                                                    checkboxBgColor
                                                }
                                            />
                                        )}
                                    </td>
                                )}
                                {headers.map((header, id) => (
                                    <TableCell
                                        key={`td-${id}`}
                                        className={header.colClass}
                                        cellKey={header.key}
                                        value={
                                            header.render
                                                ? header.render(rowData[index])
                                                : rowData[index][header.key]
                                        }
                                        width={header.width}
                                        dragHandleProps={
                                            id === 0
                                                ? provided.dragHandleProps
                                                : ''
                                        }
                                    />
                                ))}
                                {actions && (
                                    <td
                                        className='action-body text-center'
                                        style={
                                            actionsWidth
                                                ? {
                                                      width: actionsWidth,
                                                  }
                                                : null
                                        }
                                    >
                                        {actions.map((action, id) => (
                                            <ActionItem
                                                key={`action-${id}`}
                                                {...action}
                                                row={rowData[index]}
                                                index={index}
                                                isDisabled={
                                                    rowData[index]
                                                        ?.shouldDisable ||
                                                    action.shouldDisable
                                                }
                                                deletePosition={
                                                    index <
                                                    deletePlaceBottomIndex
                                                        ? 'bottomRight'
                                                        : ''
                                                }
                                                backdropHeight={height}
                                                metaData={metaData}
                                                liveEvent={liveEvent}
                                            />
                                        ))}
                                    </td>
                                )}
                            </tr>
                        );
                    }}
                </Draggable>
            </div>
        );
    };

    return (
        <Droppable
            droppableId='table'
            mode={isVirtualized ? 'virtual' : null}
            renderClone={
                isVirtualized
                    ? (provided, snapshot, rubric) => {
                          const item = rowData[rubric.source.index];
                          return (
                              <tr
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                  )}
                              >
                                  {headers.map((header, id) => (
                                      <TableCell
                                          className={header.colClass}
                                          cellKey={header.key}
                                          value={
                                              header.render
                                                  ? header.render(item)
                                                  : item[header.key]
                                          }
                                          width={header.width}
                                      />
                                  ))}
                              </tr>
                          );
                      }
                    : null
            }
        >
            {(droppableProvided, snapshot) => (
                <tbody
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                    height={isVirtualized ? '800px' : 'auto'}
                >
                    {isVirtualized
                        ? rowData &&
                          rowData.length && (
                              <>
                                  <AutoSizer>
                                      {({ height, width }) => {
                                          return (
                                              <List
                                                  key={`${width}${height}boothTable`}
                                                  height={height}
                                                  width={width}
                                                  itemCount={rowData.length}
                                                  itemSize={
                                                      rowHeight || itemSize
                                                  }
                                                  outerRef={
                                                      droppableProvided.innerRef
                                                  }
                                                  itemData={{
                                                      height:
                                                          itemSize *
                                                          rowData.length,
                                                  }}
                                              >
                                                  {Row}
                                              </List>
                                          );
                                      }}
                                  </AutoSizer>
                              </>
                          )
                        : rowData &&
                          rowData.map((row, id) => {
                              return (
                                  <Draggable
                                      draggableId={id.toString()}
                                      index={id}
                                      key={id}
                                      isDragDisabled={
                                          row.type === GROUP_HEADER
                                              ? true
                                              : false
                                      }
                                  >
                                      {(provided, snapshot) => {
                                          if (row.type === GROUP_HEADER) {
                                              return (
                                                  <>
                                                      <Tr
                                                          key={`tr-${id}`}
                                                          ref={
                                                              provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          style={getItemStyle(
                                                              snapshot.isDragging,
                                                              provided
                                                                  .draggableProps
                                                                  .style
                                                          )}
                                                      >
                                                          <td
                                                              {...provided.dragHandleProps}
                                                              colSpan={
                                                                  emptyColSpan
                                                              }
                                                              className='category-row'
                                                          >
                                                              {getGroupHeaderText(
                                                                  row
                                                              )}
                                                          </td>
                                                      </Tr>
                                                      {row.groupEmpty && (
                                                          <tr
                                                              key={`tr-${id}-empty-state`}
                                                              {...provided.draggableProps}
                                                              style={getItemStyle(
                                                                  snapshot.isDragging,
                                                                  provided
                                                                      .draggableProps
                                                                      .style
                                                              )}
                                                          >
                                                              <td
                                                                  {...provided.dragHandleProps}
                                                                  colSpan={
                                                                      emptyColSpan
                                                                  }
                                                                  className='text-center'
                                                                  style={{}}
                                                              >
                                                                  <Typography
                                                                      variant={
                                                                          'subtext1'
                                                                      }
                                                                      color={
                                                                          theme
                                                                              .colors
                                                                              .ambience[16]
                                                                      }
                                                                  >
                                                                      {getGroupHeaderEmptyText(
                                                                          row
                                                                      )}
                                                                  </Typography>
                                                              </td>
                                                          </tr>
                                                      )}
                                                  </>
                                              );
                                          }
                                          return (
                                              <tr
                                                  key={`tr-${id}`}
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  style={getItemStyle(
                                                      snapshot.isDragging,
                                                      provided.draggableProps
                                                          .style
                                                  )}
                                              >
                                                  {rowSelection && (
                                                      <td className='checkbox-outer'>
                                                          {!row._isSelectionHidden && (
                                                              <RowSelector
                                                                  id={`check-${id}`}
                                                                  className={
                                                                      'row-checkbox'
                                                                  }
                                                                  onSelect={(
                                                                      checked
                                                                  ) =>
                                                                      onSelect(
                                                                          row,
                                                                          checked
                                                                      )
                                                                  }
                                                                  checked={
                                                                      row._isSelected
                                                                  }
                                                                  disabled={
                                                                      row._isSelectionDisabled
                                                                  }
                                                                  checkboxBgColor={
                                                                      checkboxBgColor
                                                                  }
                                                              />
                                                          )}
                                                      </td>
                                                  )}
                                                  {headers.map((header, id) => (
                                                      <TableCell
                                                          key={`td-${id}`}
                                                          className={
                                                              header.colClass
                                                          }
                                                          cellKey={header.key}
                                                          value={
                                                              header.render
                                                                  ? header.render(
                                                                        row
                                                                    )
                                                                  : row[
                                                                        header
                                                                            .key
                                                                    ]
                                                          }
                                                          width={header.width}
                                                          dragHandleProps={
                                                              id === 0
                                                                  ? provided.dragHandleProps
                                                                  : ''
                                                          }
                                                      />
                                                  ))}
                                                  {actions && (
                                                      <ActionTd
                                                          className='action-body text-center'
                                                          style={
                                                              actionsWidth
                                                                  ? {
                                                                        width: actionsWidth,
                                                                    }
                                                                  : null
                                                          }
                                                      >
                                                          {actions.map(
                                                              (
                                                                  action,
                                                                  index
                                                              ) => (
                                                                  <ActionItem
                                                                      key={`action-${index}`}
                                                                      {...action}
                                                                      row={row}
                                                                      isDisabled={
                                                                          row?.shouldDisable ||
                                                                          action.shouldDisable
                                                                      }
                                                                      metaData={
                                                                          metaData
                                                                      }
                                                                      index={id}
                                                                      liveEvent={
                                                                          liveEvent
                                                                      }
                                                                  />
                                                              )
                                                          )}
                                                      </ActionTd>
                                                  )}
                                              </tr>
                                          );
                                      }}
                                  </Draggable>
                              );
                          })}
                    {(!rowData || rowData.length === 0) && (
                        <EmptyTableState
                            isLoading={isLoading}
                            colSpan={emptyColSpan}
                        />
                    )}
                    {droppableProvided.placeholder}
                </tbody>
            )}
        </Droppable>
    );
};

const TableCell = ({
    cellKey,
    className = '',
    value,
    width = '',
    dragHandleProps,
}) => {
    return (
        <td
            style={
                width
                    ? {
                          width: width,
                      }
                    : null
            }
            className={className}
            {...dragHandleProps}
        >
            <div className={classnames(cellKey, className)}>{value}</div>
        </td>
    );
};

const EmptyTableState = ({ isLoading, colSpan }) => {
    return (
        <tr>
            <td colSpan={colSpan}>
                {isLoading && <Loader style={{ marginTop: '100px' }} />}
                {!isLoading && (
                    <div className='no-record'>No records found</div>
                )}
            </td>
        </tr>
    );
};

DraggableTbody.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.object),
    rowData: PropTypes.arrayOf(PropTypes.object),
    actions: PropTypes.arrayOf(PropTypes.object),
};

TableCell.propTypes = {
    cellKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
};

export default DraggableTbody;

const Tr = styled.tr`
    border-radius: 0 !important;
    td {
        ${({ theme }) =>
            theme?.isLightTheme
                ? `
        border-radius: 0;
        background-color: ${theme.colors.ambience[23]} !important;
    `
                : ''}
    }
`;

const ActionTd = styled.td`
    .action-item {
        ${({ theme }) =>
            theme?.isLightTheme
                ? `
                svg {
                    fill: ${theme.colors.ambience[1]} !important;
                }
            `
                : ''}
    }
`;
