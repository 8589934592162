import React from 'react';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import RestrictedAccountTooltip, {
    TooltipContent as LiveTooltipContent,
    TooltipHead as LiveTooltipHead,
} from './index';
import keys from 'locale/keys';

export const TooltipContent = (props) => {
    const { t } = useCustomTranslation();
    return (
        <LiveTooltipContent
            content={t(keys.RESTRICTED_ACCOUNT_TOOLTIP_CONTENT)}
            {...props}
        />
    );
};

export const TooltipHead = (props) => {
    const { t } = useCustomTranslation();
    return (
        <LiveTooltipHead
            heading={t(keys.RESTRICTED_ACCOUNT_TOOLTIP_HEAD)}
            {...props}
        />
    );
};

const LiveRestrictedAccountToolTip = (props) => {
    const { t } = useCustomTranslation();
    return (
        <RestrictedAccountTooltip
            heading={t(keys.RESTRICTED_ACCOUNT_TOOLTIP_HEAD)}
            content={t(keys.RESTRICTED_ACCOUNT_TOOLTIP_CONTENT)}
            {...props}
        />
    );
};
export default LiveRestrictedAccountToolTip;
