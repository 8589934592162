import * as RdxToast from '@radix-ui/react-toast';
import Icon from 'foundations/icon';
import { getZIndex } from 'foundations/themeV2/zIndex';
import isString from 'lodash/isString';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch } from 'react-redux';
import { removeToast } from 'store/actions/toasts';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';
import { cdnImage } from 'utils/core';

type TypeEnum = 'success' | 'warning' | 'error' | 'primary' | 'info';

interface ToastObj {
    id: number;
    title?: string;
    message: any;
    duration: number;
    type: TypeEnum;
    closeButton: boolean;
    version: string;
    descStyles?: Object;
}

interface ToastProps {
    toast: ToastObj;
    time?: number;
}

const Toast: React.FC<ToastProps> = ({ toast, time }) => {
    const dispatch = useDispatch();
    const {
        id,
        title,
        message,
        duration,
        type,
        closeButton = true,
        onClose = noop,
        descStyles = {},
    } = toast || {};
    const [open, setOpen] = useState(true);
    const dispatchTimeoutRef = useRef(null);

    if (duration) {
        time = duration;
    }
    if (time === 'forever') time = 1000000;
    if (!time) time = 5000;

    const closeToast = useCallback(() => {
        setOpen(false);
        onClose && onClose();
        dispatchTimeoutRef.current = setTimeout(() => {
            dispatch(removeToast(id));
        }, 300);
    }, [dispatch, id]);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            closeToast();
        }, time);

        return () => {
            clearTimeout(timeOut);
            clearTimeout(dispatchTimeoutRef.current);
        };
    }, [id, dispatch, time, closeToast]);

    // Backward compatibility
    const { finalTitle, finalMessage } = useMemo(() => {
        if (!title && isString(message) && message !== '') {
            return {
                finalTitle: message,
                finalMessage: null,
            };
        }

        return {
            finalTitle: title,
            finalMessage: message?.component
                ? message.component
                : message?.markup || message
                ? ReactHtmlParser(message?.markup || message)
                : '',
        };
    }, [title, message]);

    if (!finalMessage && !finalTitle) {
        return null;
    }

    return (
        <ToastRoot open={open} onOpenChange={setOpen} duration={time}>
            <Img src={cdnImage(`/images/toast-ic-${type}.svg`)} alt={type} />
            <Content>
                {finalTitle ? (
                    <ToastTitle className='ToastTitle'>{finalTitle}</ToastTitle>
                ) : null}
                {finalMessage ? (
                    <RdxToast.Description asChild>
                        <ToastDescription style={descStyles}>
                            {finalMessage}
                        </ToastDescription>
                    </RdxToast.Description>
                ) : null}
            </Content>
            {closeButton ? (
                <ToastClose aria-label='Close' onClick={closeToast}>
                    <CloseIcon
                        icon='ic-close'
                        width='1.25rem'
                        height='1.25rem'
                        aria-hidden
                    />
                </ToastClose>
            ) : null}
        </ToastRoot>
    );
};

export default Toast;

const toastViewportPadding = '25px';

export const ToastViewport = styled(RdxToast.Viewport)`
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: ${toastViewportPadding};
    margin: 0;
    list-style: none;
    z-index: ${({ theme }) =>
        getZIndex({
            zIndexType: theme.zIndices.types.above,
            zIndexRange: theme.zIndices.ranges['3xl'],
        })};
    outline: none;

    width: 500px;
    max-width: 100vw;
    @media (max-width: 640px) {
        min-width: 100vw;
        width: 100vw;
        max-width: 100vw;
    }
`;

const ToastRoot = styled(RdxToast.Root)`
    background-color: ${({ theme }) => theme.colors.fill.other1};
    border-radius: ${({ theme }) => theme.radii.default};
    padding: ${({ theme }) => `${theme.space.x4} ${theme.space.x5}`};
    border: 1px solid ${({ theme }) => theme.colors.border.default1};
    box-shadow: ${({ theme }) => theme.shadows.x0};
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    &[data-state='open'] {
        animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state='closed'] {
        animation: hide 100ms ease-in;
    }
    &[data-swipe='move'] {
        transform: translateY(var(--radix-toast-swipe-move-y));
    }
    &[data-swipe='cancel'] {
        transform: translateY(0);
        transition: transform 200ms ease-out;
    }
    &[data-swipe='end'] {
        animation: swipeOut 100ms ease-out;
    }

    @keyframes hide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    @keyframes slideIn {
        from {
            transform: translateY(calc(-100% - ${toastViewportPadding}));
        }
        to {
            transform: translateY(0);
        }
    }

    @keyframes swipeOut {
        from {
            transform: translateY(var(--radix-toast-swipe-end-y));
        }
        to {
            transform: translateY(calc(-100% - ${toastViewportPadding}));
        }
    }
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    padding-right: 20px;
`;

const ToastTitle = styled(RdxToast.Title)`
    color: ${({ theme }) => theme.colors.text.other.primary};
    font-size: ${({ theme }) => theme.fontSizes.x3};
    line-height: ${({ theme }) => theme.lineHeights.x3};
`;

const ToastDescription = styled.div`
    color: ${({ theme }) => theme.colors.text.other.secondary};
    font-size: ${({ theme }) => theme.fontSizes.x1};
    line-height: ${({ theme }) => theme.lineHeights.x1};
`;

const ToastClose = styled(RdxToast.Close)`
    outline: none;
    box-shadow: none;
    background: transparent;
    border: 0;

    &:hover {
        outline: none;
        box-shadow: none;
        background: transparent;
        border: 0;
    }
`;

const CloseIcon = styled(Icon)`
    fill: ${({ theme }) => theme.colors.text.other.primary};
`;

const Img = styled.img`
    margin-right: ${({ theme }) => theme.space.x4};
    height: ${({ theme }) => theme.space.x7};
    width: ${({ theme }) => theme.space.x7};
`;
