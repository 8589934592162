import Btn from 'atoms/Button/btn';
import Drawer from 'atoms/Drawer/drawer';
import BlurSlider from 'atoms/RangeSlider/BlurSlider';
import OpacitySlider from 'atoms/RangeSlider/OpacitySlider';
import ZoomSlider from 'atoms/RangeSlider/ZoomSlider';
import useNewDefaultReceptionFlag from 'components/DefaultReception/hooks/useNewDefaultReceptionFlag';
import ImageEditor from 'components/molecules/imageEditor/ImageEditor';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { getCustomScroll } from 'components/molecules/utils/customStyles';
import { Text } from 'foundations/themeV2/text/';
import useVenueData from 'hooks/reception/useVenueData';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import useTheme from 'hooks/useTheme';
import isNumber from 'lodash/isNumber';
import throttle from 'lodash/throttle';
import React, { useMemo, useState } from 'react';
import usePortal from 'react-cool-portal';
import { useDispatch } from 'react-redux';
import { uplaodFile } from 'store/actions/fileUplaoder';
import styled from 'styled-components';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import { LAYOUT_TYPES } from 'utils/constants/customLayout/customLayout';
import { USER_ROLE } from 'utils/constants/users';
import { cdnImage } from 'utils/core';
import { logger } from 'utils/logger';
import { v4 as uuid } from 'uuid';
import {
    DEFAULT_BACKGROUND_BLUR,
    DEFAULT_BACKGROUND_OPACITY,
    DEFAULT_BACKGROUND_ZOOM,
} from './utils';
import { noop } from 'utils/constants/common';

const MAX_ZOOM_LIMIT = 5;
const DEFAULT_ZOOM = 1;
const ROTATION = 0;
const UPLOAD_IMAGE_REQUEST_KEY = 'customAirGenieBackgroundImage';

const { EXHIBITOR, HOST, SPEAKER, ORGANIZER, COHOST } = USER_ROLE;

function PreviewImageDrawerWrapper({
    fileData,
    imageUrl,
    isDefaultImage,
    value,
    onChange,
    onClose,
    onRemoveImage = noop,
    imageHeight = 720,
    imageWidth = 1440,
}) {
    const { theme } = useTheme();
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const isNewDefaultReception = useNewDefaultReceptionFlag();
    const previewImage = `/images/summit/event-branding/reception-top-overlay-image-${
        theme.isLightTheme ? 'light' : 'dark'
    }.png`;
    const newPreviewImage = `/images/summit/event-branding/lounge-overlay-image2-${
        theme.isLightTheme ? 'light' : 'dark'
    }.png`;
    const {
        airmeet: {
            data: {
                currentAirmeet: { status },
                userRole,
            },
            featureFlags: { isConferenceEvent },
            airmeetId,
        },
    } = useLiveAirmeetContext();
    const { data: venueData } = useVenueData({
        airmeetId,
        isConferenceEvent,
    });
    const { CUSTOM } = LAYOUT_TYPES;
    const { layoutType = CUSTOM } = venueData || {};
    const showAirmeetStatusBar = useMemo(
        () =>
            [
                AIRMEET_STATUS.CREATED,
                AIRMEET_STATUS.PAUSED,
                AIRMEET_STATUS.FINISHED,
                AIRMEET_STATUS.PRE_EVENT_ACCESS,
            ].includes(status) &&
            [EXHIBITOR, COHOST, HOST, ORGANIZER, SPEAKER].includes(userRole),
        [status, userRole]
    );
    const [opacity, setOpacity] = useState(
        value?.meta?.opacity ?? DEFAULT_BACKGROUND_OPACITY
    );
    const [blur, setBlur] = useState(
        value?.meta?.blur ?? DEFAULT_BACKGROUND_BLUR
    );
    const [zoom, setZoom] = useState(
        value?.meta?.zoom ?? DEFAULT_BACKGROUND_ZOOM
    );
    const [cropData, setCropData] = useState('');

    const handleOpacityChange = throttle((opacity) => {
        setOpacity(opacity);
    }, 300);

    const handleBlurChange = throttle((blur) => {
        setBlur(blur);
    }, 300);

    const handleZoomChange = throttle((zoom) => {
        setZoom(zoom);
    }, 300);
    const removeImageOnClick = () => {
        if (onRemoveImage) {
            onRemoveImage();
        }
        onClose();
    };
    const handleSave = async () => {
        try {
            let imgUrl = imageUrl;
            if (cropData) {
                setIsSaving(true);
                const { name, ...rest } = fileData || {};
                const file = await fetch(cropData)
                    .then((res) => res.arrayBuffer())
                    .then(
                        (buf) =>
                            new File([buf], name || `New BG Image ${uuid()}`, {
                                ...(rest || { type: ['image/png'] }),
                            })
                    );
                const response = await dispatch(
                    uplaodFile(UPLOAD_IMAGE_REQUEST_KEY, file, 'ag_img')
                );
                imgUrl = response?.payload?.file?.url;
            }
            onChange &&
                onChange({
                    ...(value || {}),
                    meta: {
                        ...(value?.meta || {}),
                        zoom,
                        blur,
                        opacity,
                        images:
                            isDefaultImage ||
                            (value?.meta?.images || []).find(
                                (img) => img?.url === imgUrl
                            )
                                ? value?.meta?.images
                                : [
                                      ...(value?.meta?.images || []),
                                      { url: imgUrl },
                                  ],
                    },
                    value: imgUrl,
                });
        } catch (err) {
            logger.error('Image upload through modal failed', err);
        } finally {
            setIsSaving(false);
            onClose && onClose();
        }
    };

    const handleReset = () => {
        setOpacity(DEFAULT_BACKGROUND_OPACITY);
        setBlur(DEFAULT_BACKGROUND_BLUR);
        setZoom(DEFAULT_BACKGROUND_ZOOM);
    };

    const topValue = () => {
        if (
            isConferenceEvent &&
            showAirmeetStatusBar &&
            !isNewDefaultReception &&
            layoutType !== CUSTOM
        ) {
            return '64px';
        } else {
            return '0px';
        }
    };

    const scaledZoom = useMemo(() => (zoom / 100) * MAX_ZOOM_LIMIT, [zoom]);

    return (
        <Drawer
            title='Background Image'
            width='90vw'
            portal={false}
            bodyStyle={bodyStyle}
            showClose={true}
            onClose={removeImageOnClick}
            style={{
                top: topValue(),
            }}
            height={showAirmeetStatusBar ? 'calc(100vh - 64px)' : '100vh'}
        >
            <Body width={'100%'}>
                <LeftWrapper>
                    <Text variant='h4' color='text.default.primary' pb='10px'>
                        Adjust background image
                    </Text>
                    <Text variant='caption' color='text.default.secondary'>
                        The uploaded image would be applied to the background
                        with a 50% black overlay for better accessibility.
                    </Text>
                    <FlexDiv flex='1'>
                        {imageUrl && (
                            <FlexDiv
                                flexDirection='column'
                                alignItems='stretch'
                                py='60px'
                                flex='1'
                            >
                                <ZoomSlider
                                    value={zoom}
                                    onChange={handleZoomChange}
                                />
                                <BlurSlider
                                    value={blur}
                                    onChange={handleBlurChange}
                                />
                                <OpacitySlider
                                    value={opacity}
                                    onChange={handleOpacityChange}
                                />
                            </FlexDiv>
                        )}
                    </FlexDiv>
                    <ResetButton variant='secondary' onClick={handleReset}>
                        Reset to Default
                    </ResetButton>
                </LeftWrapper>
                <RightWrapper>
                    <Text variant='h5p' color='text.default.primary' pb={2}>
                        Preview
                    </Text>
                    <FlexDiv
                        flexDirection='column'
                        alignItems='stretch'
                        flex='1'
                        maxWidth={'100%'}
                        height={'calc(100% - 48px)'}
                    >
                        <ImagePreviewWrapper>
                            <ImageCropperWrapper blur={blur}>
                                <ImageEditor
                                    zoom={
                                        scaledZoom
                                            ? scaledZoom + DEFAULT_ZOOM
                                            : DEFAULT_ZOOM
                                    }
                                    rotate={ROTATION}
                                    aspectRatio={imageWidth / imageHeight}
                                    initialAspectRatio={
                                        imageWidth / imageHeight
                                    }
                                    img={imageUrl}
                                    onCrop={setCropData}
                                    style={{
                                        backgroundColor: '#C4C4C4',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    wrapperStyles={{
                                        height: '100%',
                                    }}
                                    zoomOnTouch={false}
                                    zoomOnWheel={false}
                                    cropBoxResizable={false}
                                    minCropBoxHeight={imageHeight}
                                    minCropBoxWidth={imageWidth}
                                    autoCropArea={1}
                                    cropBoxBorderRadius={8}
                                    skipCDNise={true}
                                />
                                <PreviewImage
                                    src={cdnImage(
                                        isNewDefaultReception ||
                                            !isConferenceEvent ||
                                            layoutType === CUSTOM
                                            ? newPreviewImage
                                            : previewImage
                                    )}
                                    alt='Live Session'
                                />
                                <Overlay opacity={1 - opacity} />
                            </ImageCropperWrapper>
                        </ImagePreviewWrapper>
                        <Text
                            variant='caption'
                            color='text.default.secondary'
                            pb='20px'
                            mt='12px'
                        >
                            Recommended Dimensions, Format, Size: 1440 x 720px |
                            JPG, SVG, PNG | Maximum up to 5 MB
                        </Text>
                        <FlexDiv
                            alignSelf='flex-end'
                            alignItems='center'
                            mt={'auto'}
                        >
                            <FooterButton
                                variant='primary'
                                mr='13px'
                                onClick={handleSave}
                                loading={isSaving}
                            >
                                Save
                            </FooterButton>
                            <FooterButton
                                variant='secondary'
                                onClick={removeImageOnClick}
                            >
                                Cancel
                            </FooterButton>
                        </FlexDiv>
                    </FlexDiv>
                </RightWrapper>
            </Body>
        </Drawer>
    );
}

export default function PreviewImageDrawer({
    showInsidePortal = false,
    portalContainer,
    ...rest
}) {
    const { Portal } = usePortal({
        clickOutsideToHide: false,
        escToHide: false,
        containerId: portalContainer ? portalContainer : 'sidebarWrapper',
    });

    return showInsidePortal ? (
        <Portal>
            <PreviewImageDrawerWrapper {...rest} />
        </Portal>
    ) : (
        <PreviewImageDrawerWrapper {...rest} />
    );
}

const LeftWrapper = styled(FlexDiv)`
    background-color: ${({ theme }) => theme.colors.fill.default2};
    width: 360px;
    flex-direction: column;
    align-items: stretch;
    padding: 24px;
    overflow-y: auto;
    ${({ theme, verticalWidth }) => getCustomScroll({ theme, verticalWidth })};
`;

const RightWrapper = styled(FlexDiv)`
    padding: 20px;
    flex-direction: column;
    flex: 1;
`;

const bodyStyle = { padding: 0, paddingLeft: '32px' };

const Body = styled(FlexDiv)`
    border-top: 1px solid rgba(255, 255, 255, 0.2);
`;

const ImagePreviewWrapper = styled(FlexDiv)`
    width: 100%;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.ambience[14]};
    padding: 16px;
`;

const ImageCropperWrapper = styled(FlexDiv)`
    position: relative;
    justify-content: center;
    height: 400px;
    width: 100%;

    & .cropper-view-box {
        outline-color: hsla(185, 100%, 61%, 1);
    }

    & .cropper-container {
        border: 0.405px dashed #39eeff;
        background: rgba(74, 239, 255, 0.3);
    }

    ${({ blur }) =>
        isNumber(blur) && blur > 0
            ? `
            & .cropper-container img {
                filter: blur(${blur / 20}px);
            }
    `
            : ''}
`;

const FooterButton = styled(Btn)`
    padding-left: 24px;
    padding-right: 24px;
`;
const PreviewImage = styled.img`
    width: auto;
    max-width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
`;

const Overlay = styled(FlexDiv)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    ${({ opacity, theme }) =>
        isNumber(opacity) &&
        `
        background-color: ${theme.colors.text.other.primary};
        opacity: ${opacity}
    `};
`;

const ResetButton = styled(Btn)`
    align-self: flex-start;
    margin-top: 16px;
`;
