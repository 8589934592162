import { EventEmitter } from 'events';
import React, { useEffect } from 'react';
import { logger } from 'utils/logger';
const localLog = logger.init('Reporting context');
const INCIDENT_EVENT = 'incident';
const ErrorEventEmitter = new EventEmitter();

export interface IncidentBannerConfig {
    fatalThreshold: Number;
}
export interface IncidentReport {
    tag?: String;
    message?: String;
    meta?: Object;
    timestamp?: Number; //epoch in milliseconds
    config?: IncidentBannerConfig;
}

const reportIncident = (report: IncidentReport) => {
    if (!report.timestamp) {
        report.timestamp = new Date().getTime();
    }
    ErrorEventEmitter.emit(INCIDENT_EVENT, report);
};

type IncidentListenerCallback = (...args: any[]) => void;

const observeIncidents = (callback: IncidentListenerCallback) => {
    ErrorEventEmitter.on(INCIDENT_EVENT, callback);
};

const stopObservingIncidents = (callback: IncidentListenerCallback) => {
    ErrorEventEmitter.off(INCIDENT_EVENT, callback);
};

const value = { reportIncident, observeIncidents, stopObservingIncidents };

const ReportingContext = React.createContext(value);

export function ReportingProvider({ children }) {
    useEffect(() => {
        // do nothing
        return () => {
            localLog('clearing all listeners');
            ErrorEventEmitter.removeAllListeners();
        };
    }, []);
    return (
        <ReportingContext.Provider value={value}>
            {children}
        </ReportingContext.Provider>
    );
}

export default ReportingContext;
