import { useShallowEqualSelector } from 'hooks/common';
import useDataWriter from 'hooks/useDataWriter';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import get from 'lodash/get';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import { getIsMeetupEventChatRHSEnabled } from 'context/LiveAirmeet';
import { EVENT_TYPES } from 'utils/constants/airmeet';
import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import { LOG_LEVEL } from 'utils/constants/logger';
import firebaseLogger from 'utils/firebaseLogger';
import { logger } from 'utils/logger';
import { isSessionHostOrCohost } from 'utils/userAccessControl';
const localLog = logger.init('useShowOnStage', 'yellow', LOG_LEVEL.INFO);
const featureNames = {
    QUESTION: 'question',
    CHAT: 'chat',
    POLL: 'poll',
    STAGE_VIDEO: 'video',
    STAGE_VIDEO_URL: 'video_url',
    IN_SESSION_CTA: 'in_session_cta',
    LOWER_THIRDS_BANNER: 'lower_thirds_banner',
};

const { ADD, REMOVE } = FEATURE_ACTIONS[FEATURE_NAMES.SHOW_ON_STAGE];

const getBlockedMessages = (state) =>
    get(state, 'lounge.reports.blockedMessages', []);

function useShowOnStage({ stageRoomNode, stageId }) {
    const [featureData, setFeatureData] = useState(null);
    const [stageFeatureData, setStageFeatureData] = useState(null);
    const {
        user,
        firebaseClient,
        featureDataClients: { chat: chatFirebaseClient },
        airmeet: {
            airmeetId,
            data: { userRole, userStageRole, currentAirmeet },
        },
    } = useLiveAirmeetContext();
    const isMeetupEventChatRHSEnabled = useLiveAirmeetContext(
        getIsMeetupEventChatRHSEnabled
    );
    const blockedMessages = useShallowEqualSelector(getBlockedMessages);

    const { write: dataWriter } = useDataWriter(FEATURE_NAMES.SHOW_ON_STAGE, {
        useFrontendWriterFallback: false,
    });
    const logStageEvent = useCallback(
        (eventName, source, otherParam = {}) => {
            const payload = {
                airmeet_id: airmeetId,
                community_name: currentAirmeet.community_name,
                community_id: currentAirmeet.community_id,
                user_role: userRole,
                user_stage_role: userStageRole,
                airmeet_session_id: stageId,
                uid: user.id,
            };

            if (source) {
                payload.source = source;
            }

            firebaseLogger.showOnStage(eventName, {
                ...payload,
                ...otherParam,
            });
        },
        [
            airmeetId,
            currentAirmeet.community_name,
            currentAirmeet.community_id,
            userRole,
            userStageRole,
            stageId,
            user.id,
        ]
    );

    const sessionId = stageId;

    const _path = useMemo(() => `${stageRoomNode}/showOnStage`, [sessionId]);

    const getFeatureFirebaseConfig = useCallback(
        (props) => {
            const firebaseConfig = {
                key: null,
                client: firebaseClient,
            };
            if (props.featureName === featureNames.QUESTION) {
                firebaseConfig.key = `${airmeetId}/liveAirmeet/sessions/${props.chatSessionId}/questions/${props.featureId}`;
            }

            if (props.featureName === featureNames.CHAT) {
                if (
                    currentAirmeet.event_type === EVENT_TYPES.MEETUP &&
                    isMeetupEventChatRHSEnabled
                ) {
                    firebaseConfig.key = `${airmeetId}/chats/General/${props.featureId}`;
                } else {
                    firebaseConfig.key = `${airmeetId}/liveAirmeet/sessions/${props.chatSessionId}/messages/${props.featureId}`;
                }
                firebaseConfig.client = chatFirebaseClient;
            }

            if (props.featureName === featureNames.POLL) {
                firebaseConfig.key = `${airmeetId}/polls/session/${props.chatSessionId}/${props.featureId}`;
            }
            return firebaseConfig;
        },
        [
            airmeetId,
            currentAirmeet,
            firebaseClient,
            chatFirebaseClient,
            isMeetupEventChatRHSEnabled,
        ]
    );

    const reserveOnStage = useCallback(
        async (data) => {
            localLog('Reserving stage for ', data);
            try {
                await dataWriter(data, {
                    action: ADD,
                    stageId,
                });
            } catch (e) {
                localLog('Unable to update content on stage', e);
            }
        },
        [dataWriter, stageId]
    );

    const hideFromStage = useCallback(async () => {
        localLog('Leaving stage');

        try {
            await dataWriter(null, {
                action: REMOVE,
                stageId,
            });
        } catch (e) {
            localLog('Unable to remove content from stage ', e);
        }
    }, [dataWriter, stageId]);

    useEffect(() => {
        const handleChanges = ({ value }) => {
            logger.debug(
                'got handle form database value change form firebase',
                value
            );
            setFeatureData(value);
        };
        if (!firebaseClient) {
            return;
        }
        firebaseClient.getDataSync(_path, handleChanges);
        return () => {
            if (!firebaseClient) {
                return;
            }
            firebaseClient.clearDataSync(_path, handleChanges);
        };
    }, [sessionId]);

    useEffect(() => {
        if (!featureData) {
            setStageFeatureData(null);
            return;
        }
        const { key, client } = getFeatureFirebaseConfig(featureData);

        if (!client) {
            setStageFeatureData(null);
            return;
        }

        const handleChanges = ({ value }) => {
            logger.debug(
                'got handle form database value change form firebase',
                value
            );
            if (value) {
                setStageFeatureData({ ...value, ...featureData });
            }
        };

        // const featureNode = getFeatureNode(featureData);
        if (!key) {
            handleChanges({ value: {} });
            return;
        }
        client.getDataSync(key, handleChanges);
        return () => {
            client.clearDataSync(key, handleChanges);
        };
    }, [getFeatureFirebaseConfig, featureData]);

    useEffect(() => {
        if (!getAirmeetUtilInstance()) {
            return;
        }

        const onQuestionCleanUp = (data) => {
            if (
                featureData?.featureName === featureNames.QUESTION &&
                data?.sessionId === featureData.chatSessionId
            ) {
                hideFromStage();
            }
        };

        getAirmeetUtilInstance().on('cleanup-question', onQuestionCleanUp);

        return () => {
            if (!getAirmeetUtilInstance()) {
                return;
            }

            getAirmeetUtilInstance().off('cleanup-question', onQuestionCleanUp);
        };
    }, [featureData]);

    useEffect(() => {
        if (!getAirmeetUtilInstance()) {
            return;
        }

        const onChatCleanUp = (data) => {
            if (
                featureData?.featureName === featureNames.CHAT &&
                data?.sessionId === featureData.chatSessionId
            ) {
                hideFromStage();
            }
        };

        getAirmeetUtilInstance().on('cleanup-chat', onChatCleanUp);

        return () => {
            if (!getAirmeetUtilInstance()) {
                return;
            }

            getAirmeetUtilInstance().off('cleanup-chat', onChatCleanUp);
        };
    }, [featureData, hideFromStage]);

    useEffect(() => {
        if (
            _path &&
            firebaseClient &&
            isSessionHostOrCohost() &&
            (blockedMessages || []).includes(featureData?.featureId)
        ) {
            firebaseClient.runTransaction(_path, () => null);
        }
    }, [firebaseClient, _path, featureData, blockedMessages, userRole]);

    return {
        reserveOnStage,
        hideFromStage,
        featureData,
        stageFeatureData,
        featureNames,
        logStageEvent,
    };
}

export default useShowOnStage;
