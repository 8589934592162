import { zonedTimeToUtc } from 'date-fns-tz';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import timezones from './timezones.json';

const NAME_ALPHA_REGEX = /^[A-Z]+$/;

/**
 * Utils related to timezone options, local timezone etc.
 */
const useTimezoneUtils = () => {
    const options = useMemo(() => {
        const optionsArr = [];
        moment.tz
            .names()
            .filter((tz) => {
                return Object.keys(timezones).includes(tz);
            })
            .reduce((memo, tz) => {
                memo.push({
                    name: tz,
                    offset: moment.tz(tz).utcOffset(),
                });

                return memo;
            }, [])
            .sort((a, b) => {
                return a.offset - b.offset;
            })
            .reduce((memo, tz) => {
                const offsetString = moment.tz(tz.name).format('Z');
                let zoneName = moment.tz(tz.name).zoneAbbr();

                // Setting offset if not found an abbr for a zone
                zoneName = NAME_ALPHA_REGEX.test(zoneName)
                    ? zoneName
                    : `UTC${tz.offset !== 0 ? offsetString : ''}`;

                const tzObject = timezones[tz.name];
                optionsArr.push({
                    value: tz.name,
                    label: `(UTC${tz.offset ? offsetString : ''}) ${
                        tzObject.label
                    }`,
                    offset: offsetString,
                    zoneName: zoneName,
                    alias: tzObject.alias || [],
                    custom: tzObject.customTimezone ? true : false,
                    isHidden: tzObject.isHidden || false,
                });
            }, '');

        return optionsArr;
    }, []);

    const localTz = moment.tz.guess(true);

    let localTzOption = options.filter((option) => option.value === localTz);

    // Support for compatibility of deprecated TimeZone names which Intl API
    // returns on some browers e.g 'Asia/Calcutta' instead of 'Asia/Kolkata'
    // Check if localTz exists as an alias;
    if (localTzOption.length === 0) {
        localTzOption = options.filter((option) =>
            option.alias.includes(localTz)
        );
    }

    // If still not matched, check using offset and return first matched.
    if (localTzOption.length === 0) {
        const timezone = moment.tz(localTz).format('Z');
        localTzOption = options.filter((option) => option.offset === timezone);
    }

    /**
     * Function to get offset for a particular timezone
     * @param {string} zoneName IANA timezone name string
     * @param {string|Date} refDate Reference Date ISO string or Date for which offset is requested
     * @param {boolean} isZonedTime If passed date reference is zoned time
     */
    const getOffsetForZone = (zoneName, refDate, isZonedTime) => {
        const zone = options.find(
            (option) =>
                option.value === zoneName ||
                option.alias.find((it) => it === zoneName)
        );

        // Can't find zone, return
        if (!zone) {
            return null;
        }

        let zoneOffset = zone.offset;
        // If refDate is set, calculate offset based on refDate
        if (refDate) {
            // If is zonedTime, convert back to UTC time
            if (isZonedTime) {
                refDate = zonedTimeToUtc(refDate, zoneName);
            }
            zoneOffset = moment(refDate).tz(zoneName).format('Z');
        }

        return zoneOffset;
    };

    /**
     * Util function to get the timezone details based on IANA timezone string
     * @param {string} tzName IANA timezone name string
     * @param {string|Date} refDate Reference Date UTC String or Date for which the tz abbr is requested
     */
    const getZoneDetails = (tzName, refDate) => {
        const zone = options.find(
            (option) =>
                option.value === tzName ||
                option.alias.find((it) => it === tzName)
        );

        // If refDate is set, look out for zone abbr on the reference Date.
        let refDateZoneName = zone?.zoneName;
        if (refDate && tzName) {
            refDateZoneName = moment(refDate).tz(tzName).zoneAbbr();
            // If no valid abbr found, fallback to original offset string
            if (!NAME_ALPHA_REGEX.test(refDateZoneName)) {
                refDateZoneName = zone?.zoneName;
            }
        }

        if (zone) {
            zone.zoneName = refDateZoneName;
        }
        return zone;
    };

    const getTimezoneCountryCode = (timezone) => {
        if (timezone && timezone in timezones) {
            return ((timezones[timezone] || {})?.iso2 || '').toLowerCase();
        } else {
            let iso2 = '';
            for (const key of Object.keys(timezones)) {
                const tz = timezones[key];
                if ((tz?.alias || []).includes(timezone)) {
                    if (tz?.iso2Alias && timezone in tz?.iso2Alias) {
                        iso2 = tz?.iso2Alias[timezone];
                    } else {
                        iso2 = tz?.iso2;
                    }
                    break;
                }
            }
            return (iso2 || '').toLowerCase();
        }
    };

    return {
        options,
        localTz,
        localTzOption: localTzOption.length > 0 && localTzOption[0],
        getOffsetForZone,
        getZoneDetails,
        getTimezoneCountryCode,
    };
};

export default useTimezoneUtils;
