import Tooltip from 'components/tooltip/Tooltip';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export function TruncatedTypography({
    text,
    toolClass,
    tooltipStyle,
    tooltipPlace = 'top',
    tooltipRenderElementProps = {},
    tooltipProps = {},
    maxChars = false,
    isThemeV2 = false,
    ...rest
}) {
    const [isTextTruncated, setIsTextTruncated] = useState(false);
    const textPlaceholder = useRef(null);
    const { isLightTheme } = useTheme();
    const isTruncated = () => {
        return (
            textPlaceholder.current.scrollWidth >
                textPlaceholder.current.clientWidth ||
            (maxChars && text.length > maxChars)
        );
    };

    // isTruncated decides when to add tooltip
    useEffect(() => {
        setIsTextTruncated(isTruncated());
    }, [text]);

    return isTextTruncated ? (
        <Tooltip
            renderElement={
                <Typography
                    style={tooltipStyle}
                    variant='caption'
                    isThemeV2={isThemeV2}
                    {...(tooltipRenderElementProps || [])}
                >
                    {text}
                </Typography>
            }
            place={tooltipPlace}
            toolClass={toolClass}
            isThemeV2={isThemeV2}
            hasWrapper={false}
            {...(tooltipProps || {})}
            toolStyles={{ type: isLightTheme ? 'light' : 'dark' }}
        >
            <TruncatedText
                ref={textPlaceholder}
                isThemeV2={isThemeV2}
                {...rest}
            >
                {text}
            </TruncatedText>
        </Tooltip>
    ) : (
        <TruncatedText ref={textPlaceholder} isThemeV2={isThemeV2} {...rest}>
            {text}
        </TruncatedText>
    );
}

const TruncatedText = styled(Typography)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* added to disable default safari tooltip */
    &::after {
        content: '';
        display: block;
    }
`;
