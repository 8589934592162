import Btn from 'atoms/Button/btn';
import RadioGroupV2 from 'atoms/Radio/RadioGroupV2';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { stringToHSL } from 'foundations/theme';
import { Text } from 'foundations/themeV2/text/';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import keys from 'locale/keys';
import React from 'react';
import styled from 'styled-components';

const TYPE = 'is_light_theme';

function CustomizeAmbience({
    themeInfo,
    onChange,
    ambienceSuggestionVisible = false,
    setAmbienceSuggestionVisible,
    primaryColor = null,
}) {
    const { t } = useCustomTranslation();

    const handleChange = (isLightTheme) => {
        onChange(TYPE, isLightTheme);

        if (setAmbienceSuggestionVisible && primaryColor) {
            const hslArr = stringToHSL(primaryColor);
            if (isLightTheme) {
                setAmbienceSuggestionVisible(hslArr[3] >= 80);
            } else {
                setAmbienceSuggestionVisible(hslArr[3] <= 20);
            }
        }
    };

    return (
        <FlexDiv pb='x4' flexDirection='column' alignItems='stretch'>
            <Text variant='h5' color='text.default.primary' mb='4px'>
                {t(keys.RHS_CUSTOMIZE_AMBIENCE_TITLE)}
            </Text>
            <Text variant='caption' color='text.default.secondary'>
                {t(keys.RHS_CUSTOMIZE_AMBIENCE_SUBTITLE)}
            </Text>
            <FlexDiv alignItems='center' pt='x4'>
                <RadioGroupV2
                    options={[
                        {
                            value: false,
                            label: t(keys.RHS_CUSTOMIZE_AMBIENCE_DARK),
                        },
                        {
                            value: true,
                            label: t(keys.RHS_CUSTOMIZE_AMBIENCE_LIGHT),
                        },
                    ]}
                    onValueChange={handleChange}
                    value={themeInfo?.is_light_theme}
                    variant='cta'
                />
            </FlexDiv>
            {ambienceSuggestionVisible && (
                <FlexDiv alignItems='center' pt='8px'>
                    <StyledSvgSprite
                        icon='error_circle'
                        fill='sem.warning'
                        height='14px'
                        width='14px'
                        mr='6px'
                    />
                    <Text variant='caption' color='text.default.primary'>
                        This theme would create readability issues.
                    </Text>
                </FlexDiv>
            )}
        </FlexDiv>
    );
}

export default CustomizeAmbience;

const StyledBtn = styled(Btn)`
    flex: 1;
    text-align: center;
    justify-content: center;
`;
