import styled, { keyframes } from 'styled-components';

const spinKeyframes = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

const CircularLoader = styled.div`
    width: 100%;
    height: 100%;
    border: 2px solid
        ${({ theme, forceDark, forceWhite }) =>
            forceDark
                ? theme.colors.black
                : forceWhite
                ? theme.colors.white
                : theme.isLightTheme
                ? theme.colors.black
                : theme.colors.white};
    border-radius: 50%;
    border-top-color: transparent;
    border-bottom-color: transparent;
    animation: ${spinKeyframes} 1s linear infinite;
    -webkit-animation: ${spinKeyframes} 2s linear infinite;
`;

export default CircularLoader;
