import Button from 'atoms/Button/button';
import Checkbox from 'atoms/Checkbox/Checkbox';
import FormField from 'components/community/common/FormField';
import { CardWrapper } from 'components/community/common/styledComponents.js';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import { Typography } from 'foundations/typography';
import useOutsideClickHandler from 'hooks/useOutsideClickHandler';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { DEFAULT_DATE_PILL, getSearchMarkLabel } from '../utils';
import Pill from './Pill';

const MAX_NB_VISIBLE_PILLS = 5;

export default function ChartPills({
    pills,
    selectedPill,
    setSelectedPill,
    isEmptyChart,
}) {
    const searchDropdownRef = useRef(null);
    const [visiblePills, setVisiblePills] = useState(
        (pills || []).slice(0, MAX_NB_VISIBLE_PILLS)
    );
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [searchText, setSearchText] = useState('');

    useOutsideClickHandler(searchDropdownRef, () => {
        setDropdownVisible(false);
    });

    const showAdd = useMemo(() => {
        return (pills || []).length > MAX_NB_VISIBLE_PILLS;
    }, [pills]);

    const visiblePillOptions = useMemo(() => {
        return searchText && searchText !== ''
            ? (pills || []).filter((pill) =>
                  (pill?.label || '')
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
              )
            : pills;
    }, [pills, searchText]);

    const toggleDropdownVisible = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleSelect = (value) => {
        setVisiblePills(() => {
            const pill = (visiblePills || []).find(
                (pill) => pill?.value === value
            );
            if (pill) {
                return (visiblePills || []).filter(
                    (pill) => pill?.value !== value
                );
            }

            if (visiblePills.length >= MAX_NB_VISIBLE_PILLS)
                return visiblePills;

            const newPill = (pills || []).find((pill) => pill?.value === value);
            return [...(visiblePills || []), newPill];
        });
    };

    return (
        Array.isArray(pills) &&
        pills.length > 0 && (
            <Wrapper>
                <Pill
                    title={DEFAULT_DATE_PILL?.label}
                    mr={2}
                    selected={selectedPill === DEFAULT_DATE_PILL?.value}
                    onClick={() =>
                        setSelectedPill &&
                        setSelectedPill(DEFAULT_DATE_PILL?.value)
                    }
                    key={DEFAULT_DATE_PILL?.value}
                    isEmpty={isEmptyChart}
                />
                {(visiblePills || []).map((pill) => (
                    <Pill
                        title={pill?.label}
                        mr={2}
                        selected={selectedPill === pill?.value}
                        onClick={() =>
                            setSelectedPill && setSelectedPill(pill?.value)
                        }
                        key={pill?.value}
                        isEmpty={isEmptyChart}
                    />
                ))}
                {showAdd && (
                    <>
                        <AddButton
                            variant='outline'
                            onClick={toggleDropdownVisible}
                        >
                            <Icon
                                icon='add_circle'
                                width={13}
                                height={13}
                                fill='accentPrimary.0'
                            />
                            <FlexDiv pl={0}>Add</FlexDiv>
                        </AddButton>
                        {dropdownVisible && (
                            <Dropdown ref={searchDropdownRef}>
                                <Typography
                                    variant='subtext0'
                                    color='ambience.6'
                                    pb={2}
                                    style={{
                                        fontSize: '10px',
                                        lineHeight: '14px',
                                    }}
                                >
                                    Select calendar dates to view (max 5)
                                </Typography>
                                <SearchWrapper>
                                    <Icon
                                        icon='search'
                                        fill='ambience.14'
                                        height='15px'
                                        width='15px'
                                        position='absolute'
                                        mt='5px'
                                        ml='10px'
                                    />
                                    <SearchInput
                                        id='search'
                                        name='search'
                                        placeholder='Search'
                                        variant='v2'
                                        className='search-input'
                                        value={searchText}
                                        onChange={(e) =>
                                            setSearchText(e?.target?.value)
                                        }
                                        autocomplete='off'
                                    />
                                </SearchWrapper>
                                <FlexDiv
                                    flexDirection='column'
                                    alignItems='stretch'
                                    alignSelf='stretch'
                                    justifyContent='flex-start'
                                    overflow='auto'
                                    flexGrow={1}
                                    pt={2}
                                >
                                    {(visiblePillOptions || []).map((pill) => {
                                        const label = getSearchMarkLabel(
                                            searchText,
                                            pill?.label
                                        );
                                        return (
                                            <Checkbox
                                                label={
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: label,
                                                        }}
                                                    />
                                                }
                                                mb={1}
                                                uncheckedFill='ambience.10'
                                                iconSize='28px'
                                                filledSvgColor='accentPrimary.1'
                                                allowClickOnLabel={true}
                                                name='newsLetterOptIn'
                                                isChecked={(
                                                    visiblePills || []
                                                ).find(
                                                    (p) =>
                                                        p?.value === pill?.value
                                                )}
                                                onClick={() =>
                                                    handleSelect(pill?.value)
                                                }
                                                labelVariant='subtext0'
                                                labelColor='ambience.5'
                                                key={pill?.label}
                                            />
                                        );
                                    })}
                                </FlexDiv>
                            </Dropdown>
                        )}
                    </>
                )}
            </Wrapper>
        )
    );
}

const Wrapper = styled(FlexDiv)`
    align-items: center;
    justify-content: center;
    padding: 8px 80px;
    position: relative;
`;

const AddButton = styled(Button)`
    align-self: flex-end;
    height: auto;
    width: auto;
    padding: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    border-radius: 4px;
    background-color: ${({ theme }) =>
        theme.colors.ambience[theme?.isLightTheme ? 23 : 19]};
    display: flex;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 20px;
    z-index: 2;
`;

const Dropdown = styled(CardWrapper)`
    position: absolute;
    right: 20px;
    top: 45px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    padding: 16px;
    z-index: 2;
    width: 240px;
    height: 344px;
    flex-direction: column;
    align-items: stretch;
`;

const SearchWrapper = styled(FlexDiv)`
    flex-basis: 0;
    min-width: 0;
    position: relative;
    input {
        border: 0 !important;
        box-shadow: none !important;
    }
`;

const SearchInput = styled(FormField)`
    flex-grow: 1;
    &.search-input {
        height: 24px;
        border-radius: 8px;
        padding: 0 0 0 30px;
        align-items: center;
        display: flex;
        background-color: ${({ theme }) => theme.colors.ambience[20]};
    }
    input {
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        padding: 0;
    }
`;
