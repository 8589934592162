export const SEND_BROWSER_NOTIFICATION = 'SEND_BROWSER_NOTIFICATION';

export function displayNotification({
    title,
    body,
    sound = '/assets/BrowserNotification/sound.wav',
    duplicates = false, // system will notify when receive same notification again continously
}) {
    return {
        type: SEND_BROWSER_NOTIFICATION,
        payload: {
            title,
            body,
            sound,
            duplicates,
        },
    };
}
