import { getCurrentRegion } from 'utils/constants/dataCenter';
import AuthService from '../../utils/authService';
import createReducer from '../../utils/createReducer';
import { USER_LOGOUT_RESPONSE } from '../actions/auth';
import {
    CREATE_COMMUNITY_RESPONSE,
    FETCH_COMMUNITY_AIRMEETS_RESPONSE,
    FETCH_COMMUNITY_CURRENT_BILLING_PLANS_RESPONSE,
    FETCH_COMMUNITY_RESPONSE,
    FETCH_FIRST_CREATED_AIRMEET_RESPONSE,
    FETCH_MY_COMMUNITIES_RESPONSE,
    FETCH_PAGINATED_AIRMEETS_RESPONSE,
    RESET_COMMUNITY_AIRMEETS,
    RESET_COMMUNITY_LOADING_INDICATOR,
    RESET_USER_BILLING_PLAN,
    SET_AIRMEETS,
    SET_DEFAULT_COMMUNITY,
    SET_FIRST_CREATED_AIRMEET,
    UPDATE_EVENT_MANAGERS_LIST,
    GET_EVENT_ORGANISER_RESPONSE,
} from '../actions/community';
import {
    RESET_PLAN_ESTIMATES,
    FETCH_BILLING_PLANS_RESPONSE,
    UPDATE_PLAN_DETAILS_GET_ESTIMATE_RESPONSE,
    RESET_BILLING_PLANS_DATA,
} from '../actions/community/billingCheckout';

const handlers = {};
const initialState = {
    myCommunity: {},
    current: {},
    airmeet: [],
    subscriptionData: {},
    isCurrentCommunityLoading: true,
    plans: [],
    defaultCommunity: {},
    firstCreatedAirmeet: null,
    isCommunityCreationComplete: false,
    isEventModerator: null,
    planEstimate: {},
};
const currentRegion = getCurrentRegion();
handlers[FETCH_MY_COMMUNITIES_RESPONSE] = (state = initialState, action) => {
    let { myCommunity } = initialState;
    let communityData = action.payload;
    let communityList = [];
    let all_community = [];
    if (communityData.cm && communityData.cm.length > 0) {
        // if (!AuthService.user.community) {
        //   let userRole = 'Community Manager';
        //   let community = {
        //     communityId: communityData.cm[0].communityId,
        //     name: communityData.cm[0].name,
        //     role: 'owner'
        //   };
        //   AuthService.user = { ...AuthService.user, userRole, community };
        // }
        for (let ik = 0; ik < communityData.cm.length; ik++) {
            if (!all_community.includes(communityData.cm[ik].communityId)) {
                all_community.push(communityData.cm[ik].communityId);
                communityList.push({
                    communityId: communityData.cm[ik].communityId,
                    name: communityData.cm[ik].name,
                    role: 'owner',
                    community_image_url:
                        communityData.cm[ik].community_image_url,
                    created_at: communityData.cm[ik].created_at,
                    status: communityData.cm[ik].status,
                    subscription_type: communityData.cm[ik].subscription_type,
                    region: communityData.cm[ik].region,
                    enabled_feature_data:
                        communityData.cm[ik].enabled_feature_data,
                });
            }
        }
    }
    if (communityData.em && communityData.em.length > 0) {
        // if (!AuthService.user.community) {
        //   let userRole = 'Event Manager';
        //   let community = {
        //     communityId: communityData.em[0].communityId,
        //     name: communityData.em[0].name,
        //     role: 'admin'
        //   };
        //   AuthService.user = { ...AuthService.user, userRole, community };
        // }
        for (let ik = 0; ik < communityData.em.length; ik++) {
            if (!all_community.includes(communityData.em[ik].communityId)) {
                all_community.push(communityData.em[ik].communityId);
                communityList.push({
                    communityId: communityData.em[ik].communityId,
                    name: communityData.em[ik].name,
                    role: 'admin',
                    community_image_url:
                        communityData.em[ik].community_image_url,
                    created_at: communityData.em[ik].created_at,
                    status: communityData.em[ik].status,
                    subscription_type: communityData.em[ik].subscription_type,
                    region: communityData.em[ik].region,
                    enabled_feature_data:
                        communityData.em[ik].enabled_feature_data,
                });
            }
        }
    }
    const cmRegion = (communityData.cm || []).filter(
        (obj) => obj.region === currentRegion?.id
    );
    const emRegion = (communityData.em || []).filter(
        (obj) => obj.region === currentRegion?.id
    );
    const communityObj = {
        cm: cmRegion,
        em: emRegion,
        showAddOrganization: communityData.show_add_organization ?? null,
    };
    if (cmRegion.length > 0 || emRegion.length > 0) {
        localStorage.setItem('isOrganiser', true);
    } else {
        localStorage.removeItem('isOrganiser');
    }
    myCommunity = { ...communityObj };
    myCommunity.list = communityList;
    return { ...state, myCommunity, isError: !!action.error };
};
handlers[UPDATE_EVENT_MANAGERS_LIST] = (state = initialState, action) => {
    const { emList } = { ...action.payload };
    const current = { ...state.current, event_manager_list: emList };
    return { ...state, current };
};

handlers[FETCH_COMMUNITY_RESPONSE] = (state = initialState, action) => {
    const current = { ...action.payload };
    const { user } = AuthService;

    if (!user) {
        return { ...state, current, isCurrentCommunityLoading: false };
    } else {
        let community = {
            communityId: current.communityId,
            name: current.name,
        };
        AuthService.user = { ...AuthService.user, community };
        if (user.id === current.manager_id) {
            current.role = 'owner';
        }
        return { ...state, current, isCurrentCommunityLoading: false };
    }
};

handlers[FETCH_COMMUNITY_AIRMEETS_RESPONSE] = (
    state = initialState,
    action
) => {
    let { airmeet } = initialState;
    airmeet = [...action.payload];
    return { ...state, airmeet };
};

handlers[FETCH_FIRST_CREATED_AIRMEET_RESPONSE] = (
    state = initialState,
    action
) => {
    if (Array.isArray(action.payload) && action.payload.length > 0) {
        return { ...state, firstCreatedAirmeet: action.payload[0] };
    } else {
        return { ...state, firstCreatedAirmeet: null };
    }
};

handlers[FETCH_PAGINATED_AIRMEETS_RESPONSE] = (
    state = initialState,
    action
) => {
    let { airmeet } = state || {};

    if (!Array.isArray(airmeet)) {
        airmeet = [];
    }
    let payload = [];
    if (Array.isArray(action.payload)) {
        payload = action.payload;
    }

    const newAirmeets = [...airmeet, ...payload];

    return { ...state, airmeet: newAirmeets };
};

handlers[FETCH_COMMUNITY_CURRENT_BILLING_PLANS_RESPONSE] = (
    state = initialState,
    action
) => {
    let subscriptionData = {
        ...action.payload,
    };

    if (action.error) {
        subscriptionData = { ...subscriptionData, error: true };
        return { ...state, subscriptionData };
    } else {
        return { ...state, subscriptionData };
    }
};

handlers[FETCH_BILLING_PLANS_RESPONSE] = (state = initialState, action) => {
    if (action.error) {
        return { ...state, plans: [] };
    } else {
        return { ...state, plans: action.payload };
    }
};

handlers[RESET_BILLING_PLANS_DATA] = (state = initialState, action) => {
    const { plans } = initialState;

    if (action.type === RESET_BILLING_PLANS_DATA) {
        return { ...state, plans };
    }
};

handlers[UPDATE_PLAN_DETAILS_GET_ESTIMATE_RESPONSE] = (
    state = initialState,
    action
) => {
    if (action.error) {
        const genericErrorMsg = 'Please try again later';
        return {
            ...state,
            planEstimate: {
                error: true,
                errorMsg:
                    action.payload?.status === 400
                        ? action.payload?.message || genericErrorMsg
                        : genericErrorMsg,
            },
        };
    }
    return { ...state, planEstimate: action.payload };
};

handlers[RESET_PLAN_ESTIMATES] = (state = initialState, action) => {
    const { planEstimate } = initialState;

    if (action.type === RESET_PLAN_ESTIMATES) {
        return { ...state, planEstimate };
    }
};

handlers[RESET_USER_BILLING_PLAN] = (state = initialState, action) => {
    const { subscriptionData } = initialState;

    if (action.type === RESET_USER_BILLING_PLAN) {
        return { ...state, subscriptionData };
    }

    return state;
};

handlers[RESET_COMMUNITY_LOADING_INDICATOR] = (state = initialState) => {
    return {
        ...state,
        isCurrentCommunityLoading: initialState.isCurrentCommunityLoading,
    };
};
handlers[USER_LOGOUT_RESPONSE] = (state = initialState, action) => {
    return { ...initialState };
};
handlers[SET_DEFAULT_COMMUNITY] = (state = initialState, action) => {
    const { community } = action?.payload;
    return { ...state, defaultCommunity: community };
};

handlers[RESET_COMMUNITY_AIRMEETS] = (state = initialState) => {
    return { ...state, airmeet: initialState.airmeet };
};

handlers[SET_AIRMEETS] = (state = initialState, action) => {
    const airmeet = [...action.payload];
    return { ...state, airmeet };
};

handlers[SET_FIRST_CREATED_AIRMEET] = (state = initialState, action) => {
    return { ...state, firstCreatedAirmeet: action?.payload };
};

handlers[CREATE_COMMUNITY_RESPONSE] = (state = initialState, action) => {
    return {
        ...state,
        isCommunityCreationComplete: !action.error,
    };
};
handlers[GET_EVENT_ORGANISER_RESPONSE] = (state = initialState, action) => {
    return {
        ...state,
        isEventModerator: action?.payload?.isEventModerator,
    };
};

export default createReducer(initialState, handlers);
