import SelectV2 from 'atoms/SelectV2';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { getCardBackgroundStyle } from 'foundations/themeV2/utils';
import styled from 'styled-components';

export const Card = styled(FlexDiv)`
    position: relative;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    padding: ${({ theme }) => theme.space.x5};
    border-radius: ${({ theme }) => theme.radii.default};
    border: 1px solid ${({ theme }) => theme.colors.border.default2};
    ${({ theme }) => getCardBackgroundStyle({ theme })}
`;

export const TextSelect = styled(SelectV2)`
    div[class$='-control'] {
        cursor: pointer;
        background: transparent;
        border: 0;
        outline: none !important;
        min-height: 0;
        min-width: ${({ minWidth }) => minWidth || '102px'};
        div[class$='-ValueContainer'] {
            padding-left: 0.25rem;
            div[class$='-singleValue'] {
                font-size: ${({ theme }) => theme.fontSizes.x1};
                line-height: ${({ theme }) => theme.lineHeights.x1};
                font-family: ${({ theme }) => theme.fonts.bold};
                overflow: hidden;
                position: relative;
                transform: none;
            }
        }
        div[class$='-indicatorContainer'] {
            padding: 0 ${({ theme }) => theme.space.x1};
        }
    }
    div[class$='-menu'] {
        width: auto;
        div[class$='-MenuList'] {
            div[class$='-option'] {
                span {
                    max-width: 300px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    padding-right: ${({ theme }) => theme.space.x4};
                }
            }
        }
    }
`;

export const LegendDot = styled(FlexDiv)`
    height: ${({ theme }) => theme.space.x3};
    width: ${({ theme }) => theme.space.x3};
    min-width: ${({ theme }) => theme.space.x3};
    border-radius: 50%;
`;
