import useTimezoneUtils from 'components/community/common/TimezoneSelector/useTimezoneUtils';
import {
    adjustDateByTimeZone,
    getNearestQuarter,
    toDateStr,
    toTimeStr,
} from 'components/community/common/utils';
import { getDateStr } from 'components/molecules/card/AirmeetCard/utils';
import { format } from 'date-fns-tz';
import endOfDay from 'date-fns/endOfDay';
import isValid from 'date-fns/isValid';
import enUS from 'date-fns/locale/en-US';
import startOfDay from 'date-fns/startOfDay';
import moment from 'moment';
import { useMemo } from 'react';

const useTimeAndDateFormUtils = ({
    formData,
    setFormData,
    errorMap,
    updateErrorMap = () => {},
    isDuplication = false,
    dateDiff = null,
    templateTotalSessionDuration,
}) => {
    const getMinTime = (key) => {
        const { initDate, finalDate, timeZone } = formData;

        if (!isValid(initDate) || !isValid(finalDate)) {
            return null;
        }

        const currentZonedTime = new Date(
            getDateStr({
                start_time: new Date(),
                timezone: timeZone,
                format: 'YYYY-MM-DD HH:mm:ss',
            })
        );
        const refDate = key === 'initTime' ? initDate : finalDate;
        const targetDate =
            key === 'initTime'
                ? currentZonedTime
                : initDate > currentZonedTime
                ? initDate
                : currentZonedTime;
        // Base case, startDate cannot be before zoned current date
        if (toDateStr(refDate) < toDateStr(currentZonedTime)) {
            return endOfDay(currentZonedTime);
        }

        if (toDateStr(refDate) < toDateStr(targetDate)) {
            // End of day when final < init || init < current
            return endOfDay(targetDate);
        } else if (toDateStr(refDate) === toDateStr(targetDate)) {
            // if same day, get nearest quarter of target || current
            return getNearestQuarter(
                targetDate > currentZonedTime ? targetDate : currentZonedTime
            ).toDate();
        } else {
            // start of target
            return startOfDay(targetDate);
        }
    };
    const getMinDate = (key, currentDate = false) => {
        const { initDate, timeZone } = formData;

        const currentZonedTime = new Date(
            getDateStr({
                start_time: new Date(),
                timezone: timeZone,
                format: 'YYYY-MM-DD HH:mm:ss',
            })
        );
        if (key === 'initDate') {
            return currentDate
                ? currentZonedTime
                : initDate > currentZonedTime
                ? initDate
                : currentZonedTime;
        }
        if (key === 'finalDate') {
            return initDate > currentZonedTime ? initDate : currentZonedTime;
        }
        return currentZonedTime;
    };

    const { localTz, getOffsetForZone } = useTimezoneUtils();
    const localTimezoneString = useMemo(() => {
        const tzName = format(new Date(), 'zzzz', {
            timeZone: localTz,
            locale: enUS,
        });
        const offset = format(new Date(), 'XXX', {
            timeZone: localTz,
            locale: enUS,
        });
        return `${tzName} (GMT${offset})`;
    }, [localTz]);

    const validateFormData = (data, isCreateMode) => {
        let isValid = true;
        const mandatoryFields = ['initDate', 'finalDate'];
        Object.keys(data).forEach((key) => {
            if (mandatoryFields.includes(key) && !data[key]) {
                updateErrorMap([key], { status: true });
                isValid = false;
            }
            if (['initDate', 'finalDate'].includes(key)) {
                const currentZonedTime = new Date(
                    getDateStr({
                        start_time: new Date(),
                        timezone: data.timeZone,
                        format: 'YYYY-MM-DD HH:mm:ss',
                    })
                );

                // Start Date should not be less than current date, allow edits to retain past dates
                if (isCreateMode && data.initDate < currentZonedTime) {
                    if (
                        toDateStr(data.initDate) < toDateStr(currentZonedTime)
                    ) {
                        updateErrorMap('initDate', {
                            status: true,
                            msg: 'Cannot create event for past date',
                        });
                    } else if (
                        toTimeStr(data.initDate) < toTimeStr(currentZonedTime)
                    ) {
                        updateErrorMap('initTime', {
                            status: true,
                        });
                    }
                    isValid = false;
                }

                // Start Date should not be greater than 1 year from current date
                if (data.initDate > moment().add({ years: 1 })) {
                    updateErrorMap('initDate', {
                        status: true,
                        msg: 'Cannot enter date 1 year from current date',
                    });
                    isValid = false;
                }

                // End Date should not be less than Start Date

                if (data.finalDate < data.initDate) {
                    if (toDateStr(data.finalDate) < toDateStr(data.initDate)) {
                        updateErrorMap('finalDate', {
                            status: true,
                            msg: 'End Date cannot be earlier than start date',
                        });
                    } else if (
                        toTimeStr(data.finalDate) < toTimeStr(data.initDate)
                    ) {
                        updateErrorMap('finalTime', {
                            status: true,
                        });
                    }
                    isValid = false;
                }
                // End Date should not be greater than 5 years from start date
                if (data.finalDate > moment(data.initDate).add({ years: 5 })) {
                    updateErrorMap('finalDate', {
                        status: true,
                        msg: 'Cannot enter a date 5 years from start date',
                    });
                    isValid = false;
                }
            }
        });
        return isValid;
    };

    const getTzAdjustedPayload = (formData, keepSecs) => {
        const { initDate, finalDate, timeZone } = formData;
        return {
            initDate: adjustDateByTimeZone(
                initDate,
                getOffsetForZone(timeZone, initDate, true),
                keepSecs
            ),
            finalDate: adjustDateByTimeZone(
                finalDate,
                getOffsetForZone(timeZone, finalDate, true),
                keepSecs
            ),
            timeZone,
        };
    };

    const onStateChanged = (key) => (e) => {
        // For TimePicker components
        if (key === 'timeZone') {
            setFormData({
                ...formData,
                [key]: e.value,
            });
            updateErrorMap(key, { status: false });
        } else if (['initTime', 'finalTime'].includes(key)) {
            const selectedTime = moment(e.value);

            // Function to modify only time values in the state date object.
            const setSelectedTime = (oldDate, duration = 0) => {
                return moment(oldDate)
                    .set({
                        minute: selectedTime.get('minute'),
                        hour: selectedTime.get('hour'),
                    })
                    .add(duration, 'h')
                    .toDate();
            };

            let { initDate, finalDate } = formData;

            if (key === 'initTime') {
                initDate = setSelectedTime(initDate);
                if (templateTotalSessionDuration) {
                    finalDate = setSelectedTime(
                        initDate,
                        templateTotalSessionDuration / 60
                    );
                }
                if (initDate >= finalDate) {
                    finalDate = setSelectedTime(initDate, 1);
                }
            } else if (key === 'finalTime') {
                finalDate = setSelectedTime(finalDate);
            }
            if (isDuplication) {
                finalDate = moment(initDate).add(dateDiff, 'ms').toDate();
            }
            setFormData((formData) => ({
                ...formData,
                initDate,
                finalDate,
            }));

            // reset errorMap entries for both date and time keys
            updateErrorMap(key === 'initTime' ? 'initDate' : 'finalDate', {
                status: false,
            });
            updateErrorMap(key, {
                status: false,
            });
        } else if (['initDate', 'finalDate'].includes(key)) {
            const selectedDate = moment(e);
            // Function to modify only date values in the state date object.
            const setSelectedDate = (oldDate, duration) => {
                return moment(oldDate)
                    .set({
                        date: selectedDate.get('date'),
                        month: selectedDate.get('month'),
                        year: selectedDate.get('year'),
                    })
                    .add(duration, 'h')
                    .toDate();
            };
            const setDateDuration = (oldDate, duration, durationType = 'h') => {
                return moment(oldDate).add(duration, durationType).toDate();
            };
            let { initDate, finalDate } = formData;
            if (key === 'initDate') {
                initDate = setSelectedDate(initDate);
            } else if (key === 'finalDate') {
                finalDate = setSelectedDate(finalDate);
            }
            if (initDate > finalDate) {
                finalDate = setDateDuration(initDate, 1);
            }
            if (isDuplication) {
                finalDate = setDateDuration(initDate, dateDiff, 'ms');
            }
            if (!isValid(initDate) || !isValid(finalDate)) {
                // skip setting date if invalid
                return;
            }

            setFormData({
                ...formData,
                initDate,
                finalDate,
            });
            // reset errorMap entries for both date and time keys
            updateErrorMap(key, { status: false });
            updateErrorMap(key === 'initDate' ? 'initTime' : 'finalTime', {
                status: false,
            });
        }
    };

    return {
        getMinTime,
        getMinDate,
        localTimezoneString,
        validateFormData,
        getTzAdjustedPayload,
        onStateChanged,
    };
};

export default useTimeAndDateFormUtils;
