import { MAGIC_LINK_ERRORS } from 'hooks/live-airmeet/useAccessCodeUserToken';
import AuthService from '../../utils/authService';
import { GUEST_USER_JOIN_RESPONSE } from '../actions/airmeet';
import {
    LOCALSTORAGE_LOGGED_IN_AT,
    MAGIC_LINK_RESPONSE,
    RESET_FIRST_TIME_SIGNUP,
    SET_FIRST_TIME_SIGNUP,
    UPDATE_SERIES_MAGIC_LINK_INFO,
    USER_LANGUAGE_UPDATE_RESPONSE,
    USER_LOGIN_WITH_TOKEN,
    USER_UPDATE_METADATA,
} from '../actions/auth';

const initialState = {
    user: null,
    token: null,
    accessCode: null,
    loggedInAt: null,
    processing: true,
    exhibitor: {
        validToken: null,
        token: null,
        booth: null,
    },
    redirectToSalesforce: false,
    firstTimeOauthSignup: false, // including first time sso signups
    isWaitlisted: false, // for in person email auth entry events
};

function auth(state = initialState, { type, payload, error }) {
    let newState = null;
    let newUser = null;
    // Set first and last name from name

    const getUserData = (user) => {
        if (user && user.name && !user.first_name) {
            [user.first_name, user.last_name] = user.name.split(' ');
        }
        return user;
    };

    const getIsLightThemeDashboard = (user) => {
        return user?.metadata?.isLightThemeDashboard;
    };
    const isMagicLinkUser = (user, accessCode) => {
        if (user?.id && user?.direct && accessCode) return true;
        return false;
    };

    // eslint-disable-next-line default-case
    switch (type) {
        case 'USER_LOGIN_SUCCESS':
            if (isMagicLinkUser(state?.user, state?.accessCode)) {
                // to prevent race condition, if magic link info is already there then not to overwrite with login user data
                return state;
            }
            if (!payload.name) {
                payload.name = `${payload.first_name || ''} ${
                    payload.last_name || ''
                }`.trim();
            }
            // User must have an id
            const oldDetails =
                state.user && state.user.id && state.user.id === payload.id
                    ? state.user
                    : null;
            newState =
                payload.id || payload.id_seq
                    ? {
                          user: getUserData({ ...oldDetails, ...payload }),
                          isLightThemeDashboard: getIsLightThemeDashboard({
                              ...oldDetails,
                              ...payload,
                          }),
                      }
                    : { user: null };
            break;
        case GUEST_USER_JOIN_RESPONSE:
            if (payload.error) {
                newState = {};
            } else {
                if (!payload.name)
                    payload.name = `${payload.first_name || ''} ${
                        payload.last_name || ''
                    }`.trim();
                const guestPayload = payload.params
                    ? { ...payload, ...payload.params }
                    : payload;
                newState = payload.id
                    ? { user: { ...state.user, ...guestPayload, guest: true } }
                    : { user: null };
            }
            break;
        case 'INVITED_USER_LOGIN_SUCCESS':
            newState = { user: { ...state.user, ...payload.user } };
            break;
        case 'USER_LOGOUT_RESPONSE':
            //Cleanup from FE side after BE api response come
            AuthService.logout();
            newState = { ...initialState, user: null, processing: false };
            break;
        case USER_LOGIN_WITH_TOKEN:
            newState = {
                ...initialState,
                user: payload.user,
                token: payload.token,
                accessCode: payload.accessCode,
                processing: false,
            };
            AuthService.setTokenUser(newState.user, newState.token);
            break;
        case LOCALSTORAGE_LOGGED_IN_AT:
            newState = { loggedInAt: payload.loggedInAt };
            break;
        case 'UPDATE_EXHIBITOR':
            newState = { exhibitor: { ...state.exhibitor, ...payload } };
            break;
        case UPDATE_SERIES_MAGIC_LINK_INFO:
            newState = { user: payload.user, accessCode: payload.accessCode };
            break;
        case 'USER_LOGIN_FAILURE':
            newState = { ...payload };
            break;
        case 'USER_DATA_UPDATE':
            if (!payload.name) {
                payload.name = `${payload.first_name || ''} ${
                    payload.last_name || ''
                }`.trim();
            }
            newState = {
                user: {
                    ...state.user,
                    ...payload,
                    name: payload?.name || state.user?.name,
                },
            };
            break;
        case 'AUTH_PROCESSING':
            newState = { processing: payload === true };
            break;
        case 'USER_MANAGED_COMMUNITY_UPDATE':
            newUser = { ...state.user, ...(payload || {}) };
            newState = { user: newUser };
            break;
        case 'SET_USER_AS_GUEST':
            newState = { user: { ...state.user, ...(payload || {}) } };
            break;
        case 'SET_SALESFORCE_REDIRECT':
            newState = { ...state, redirectToSalesforce: true };
            break;
        case 'RESET_SALESFORCE_REDIRECT':
            newState = { ...state, redirectToSalesforce: false };
            break;
        case MAGIC_LINK_RESPONSE:
            if (payload.json?.code === 'BLOCKED') {
                newState = { ...state, is_blocked: true };
            } else if (payload.json?.code === MAGIC_LINK_ERRORS.WAITLISTED) {
                newState = { ...state, isWaitlisted: true };
            } else if (payload.json?.code === 'REJECTED') {
                newState = { ...state, isRejected: true };
            }
            break;
        case USER_LANGUAGE_UPDATE_RESPONSE:
            newState = { user: { ...state.user, metadata: payload.metadata } };
            break;
        case SET_FIRST_TIME_SIGNUP:
            newState = { ...state, firstTimeOauthSignup: true };
            break;
        case RESET_FIRST_TIME_SIGNUP:
            newState = { ...state, firstTimeOauthSignup: false };
            break;
        case USER_UPDATE_METADATA:
            const userObj = {
                ...state.user,
                metadata: payload?.metadata || {},
            };
            newState = {
                user: userObj,
                isLightThemeDashboard: getIsLightThemeDashboard(userObj),
            };
            break;
    }

    if (!newState) {
        return state;
    }

    if (newState && typeof newState.user !== 'undefined') {
        newState.user = getUserData(newState.user);
        /*
         * Direct users are the ones landing from magic links
         * They are special, perhaps disabled, in the sense that they vanish
         * on refresh
         * See: ../../utils/authService.js
         */
        // const isDirectUser = newState.user && newState.user.direct;
        const userType =
            newState.user && newState.user.guest ? 'guest' : 'user';

        if (!newState.token && !error) AuthService[userType] = newState.user;
    }
    return { ...state, ...newState };
}

export default auth;
