import basic from './basic';
import community from './community';
import live from './live';

export const HEADER_TEMPLATES = {
    basic: 'basic',
    community: 'community',
    live: 'live',
    none: 'none',
};

const HEADER_TEMPLATE_MAP = {
    basic,
    community,
    live,
};

export function useTemplateHeader(
    template = HEADER_TEMPLATES.basic,
    props = {}
) {
    if (!(template in HEADER_TEMPLATE_MAP)) {
        template = HEADER_TEMPLATES.basic;
    }

    const useSelectedTemplate = HEADER_TEMPLATE_MAP[template];
    return useSelectedTemplate(props);
}
