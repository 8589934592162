import FluidspaceClientPath from 'components/FluidSpace/utils/fluidClientPath';

const startMegaphone = ({ airmeetId, client, payload, metaData, logger }) => {
    if (!airmeetId) {
        throw new Error('Invalid fluid space firebase key');
    }
    const fluidspaceClientPath = new FluidspaceClientPath({
        airmeetId,
        sessionId: metaData?.sessionId,
        userId: metaData?.userId,
        zoneId: metaData?.zoneId,
    });
    logger.info('toggling megaphone for', metaData?.userId);
    return client.setData(fluidspaceClientPath.userMegaphone, payload);
};

export default startMegaphone;
