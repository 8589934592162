import AvatarImage from 'atoms/Avatar/AvatarImage';
import Button from 'atoms/Button/button';
import Drawer from 'atoms/Drawer/drawer';
import { Box } from 'components/molecules/utils/basicComponents';
import SVGIcon from 'foundations/icon';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import upperFirst from 'lodash/upperFirst';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Events, scroller } from 'react-scroll';
import { logger } from 'utils/logger';
import { v4 as uuid } from 'uuid';
import { AnalyticsContext } from '../../AnalyticsProvider';
import AllButton from '../../components/AllButton';
import EmptyCardBanner from '../../components/EmptyCardBanner';
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Loading,
    LoadingWrapper,
} from '../../styledComponents';
import { compareData } from '../../utils';
import PollCarouselProgress from './PollCarouselProgress';
import PollsCustomArrow from './PollCustomArrow';
import PollItem from './PollItem';
import PollListItem from './PollListItem';
import PollOption from './PollOption';
import { EmptyContainer, RadioPill } from './styledComponents';

const responsive = {
    mobile: {
        breakpoint: { max: 959, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1249, min: 960 },
        items: 2,
        slidesToSlide: 2,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1250 },
        items: 2,
        slidesToSlide: 2,
    },
};

const cardBodyStyles = {
    paddingTop: '36px',
    paddingBottom: '36px',
    height: '100%',
};

const processData = (data) => {
    let orderedData = (data?.polls || []).sort(
        (a, b) =>
            -1 * compareData(a?.total_response || 0, b?.total_response || 0)
    );
    let totalResponses = 0;
    let nbEventPolls = 0;
    let nbSessionPolls = 0;
    orderedData = orderedData.map((item) => {
        totalResponses += item?.total_response || 0;
        if (item.poll_level === 'Event Level') {
            nbEventPolls += 1;
        } else {
            nbSessionPolls += 1;
        }
        item.id = uuid();

        return item;
    });
    const nbAllPolls = orderedData.length;

    return {
        orderedData,
        totalResponses,
        nbEventPolls,
        nbSessionPolls,
        nbAllPolls,
    };
};

export default function PollsCard({
    slideDuration = 5,
    airmeetFormat = 'event',
}) {
    const { theme } = useTheme();
    const { logEvent, reportNotGenerated, getRequest } = useContext(
        AnalyticsContext
    );
    const [modalVisible, setModalVisible] = useState(false);
    const [pollType, setPollType] = useState('all');
    const [activePoll, setActivePoll] = useState(null);
    const [activePollIndex, setActivePollIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [tableData, setTableData] = useState([]);
    const [nbAllPolls, setNbAllPolls] = useState(0);
    const [nbEventPolls, setNbEventPolls] = useState(0);
    const [nbSessionPolls, setNbSessionPolls] = useState(0);
    const [avgResponses, setAvgResponses] = useState(0);

    const fetchData = useCallback(async () => {
        if (!reportNotGenerated) {
            setLoading(true);

            try {
                const { data, statusCode } = await getRequest({
                    endpoint: 'poll-analytics',
                });

                if (statusCode === 200) {
                    const {
                        orderedData,
                        totalResponses,
                        nbEventPolls,
                        nbSessionPolls,
                        nbAllPolls,
                    } = processData(data);

                    setData(orderedData);
                    setTableData(orderedData);
                    setNbAllPolls(nbAllPolls);
                    setAvgResponses(Math.round(totalResponses / nbAllPolls));
                    setNbEventPolls(nbEventPolls);
                    setNbSessionPolls(nbSessionPolls);
                } else {
                    setData([]);
                }
            } catch (e) {
                logger.error(e);
            }

            setLoading(false);
        }
    }, [reportNotGenerated, getRequest]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        setActivePoll(tableData[activePollIndex]);
    }, [activePollIndex, tableData]);

    const handleOpenAll = () => {
        setModalVisible(true);
        logEvent('seeAllClicked', {
            widget_type: 'poll',
        });
    };

    const scrollTo = (index) => {
        let goToContainer = new Promise((resolve, reject) => {
            Events.scrollEvent.register('end', () => {
                resolve();
                Events.scrollEvent.remove('end');
            });

            scroller.scrollTo('scroll-container');
        });

        goToContainer.then(() => {
            scroller.scrollTo(index, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart',
                containerId: 'scroll-container',
            });

            // Scroll right panel to top
            scroller.scrollTo('right-top', {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart',
                containerId: 'right-scroll-container',
            });
        });
    };

    const updateActivePollIndex = useCallback((index) => {
        setActivePollIndex(index);
        scrollTo(index);
    }, []);

    useEffect(() => {
        const tableData = (data || []).filter((item) => {
            if (pollType === 'all') return true;
            else if (pollType === 'event' && item.poll_level === 'Event Level')
                return true;
            else if (
                pollType === 'session' &&
                item.poll_level === 'Session Level'
            )
                return true;
            return false;
        });
        setTableData(tableData);

        updateActivePollIndex(0);
    }, [pollType, data, updateActivePollIndex]);

    const handlePrev = () => {
        const index = activePollIndex - 1;
        updateActivePollIndex(index);
    };

    const handleNext = () => {
        const index = activePollIndex + 1;
        updateActivePollIndex(index);
    };

    if (!loading && (reportNotGenerated || !nbAllPolls)) return null;

    return (
        <Box className='row' style={{ height: '100%' }}>
            <Box className='col-lg-12'>
                <Card>
                    <Box className='row' style={{ height: '100%' }}>
                        <Box className='col-sm-2'>
                            <CardHeader>
                                <CardTitle>Polls at a glance</CardTitle>
                            </CardHeader>
                            {loading ? (
                                <LoadingWrapper style={{ minHeight: '200px' }}>
                                    <Loading />
                                </LoadingWrapper>
                            ) : (
                                <CardBody style={cardBodyStyles}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexGrow: 1,
                                            flexDirection: 'column',
                                            borderRight: `1px solid ${theme.colors.ambience[22]}`,
                                            alignSelf: 'stretch',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div>
                                            <Typography
                                                variant='body1'
                                                color='ambience.0'
                                                style={{
                                                    marginBottom: '8px',
                                                }}
                                            >
                                                <SVGIcon
                                                    icon='chat'
                                                    height='20'
                                                    width='20'
                                                    fill='#5389F2'
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                />
                                                {nbAllPolls ?? '-'}
                                            </Typography>
                                            <Typography
                                                variant='subtext1'
                                                color='ambience.8'
                                                style={{
                                                    marginBottom: '32px',
                                                }}
                                            >
                                                Polls conducted
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography
                                                variant='body1'
                                                color='ambience.0'
                                                style={{
                                                    marginBottom: '8px',
                                                }}
                                            >
                                                <SVGIcon
                                                    icon='data_bar_vertical'
                                                    height='20'
                                                    width='20'
                                                    fill='ambience.16'
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                />
                                                {avgResponses ?? '-'}
                                            </Typography>
                                            <Typography
                                                variant='subtext1'
                                                color='ambience.8'
                                            >
                                                Average responses
                                            </Typography>
                                        </div>
                                    </div>
                                </CardBody>
                            )}
                        </Box>
                        <Box className='col-sm-10'>
                            <CardHeader>
                                <CardTitle
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: '15px',
                                    }}
                                >
                                    Top polls by responses
                                </CardTitle>
                            </CardHeader>
                            {loading ? (
                                <LoadingWrapper>
                                    <Loading />
                                </LoadingWrapper>
                            ) : (
                                <CardBody
                                    style={{
                                        paddingTop: '0',
                                        height: '100%',
                                    }}
                                >
                                    {Array.isArray(data) && data.length ? (
                                        <Carousel
                                            arrows={true}
                                            swipeable={true}
                                            draggable={true}
                                            showDots={true}
                                            responsive={responsive}
                                            infinite={true}
                                            keyBoardControl={true}
                                            customTransition='transform 1s cubic-bezier(0.25, 1, 0.5, 1)'
                                            containerClass='carousel-container'
                                            dotListClass='custom-dot-list-style'
                                            customRightArrow={
                                                <PollsCustomArrow
                                                    direction={'right'}
                                                />
                                            }
                                            customLeftArrow={
                                                <PollsCustomArrow
                                                    direction={'left'}
                                                />
                                            }
                                            autoPlay={true}
                                            autoPlaySpeed={slideDuration * 1000}
                                            customDot={
                                                <PollCarouselProgress
                                                    duration={slideDuration}
                                                />
                                            }
                                        >
                                            {data
                                                .slice(0, 6)
                                                .map((item, index) => (
                                                    <PollItem
                                                        key={item?.id}
                                                        showBorderRight={
                                                            index % 2 === 0
                                                        }
                                                        showDetail={true}
                                                        data={item}
                                                        airmeetFormat={
                                                            airmeetFormat
                                                        }
                                                    />
                                                ))}
                                        </Carousel>
                                    ) : (
                                        <EmptyContainer>
                                            <PollItem
                                                showBorderRight={true}
                                                showDetail={true}
                                            />
                                            <PollItem showDetail={true} />
                                        </EmptyContainer>
                                    )}
                                </CardBody>
                            )}
                        </Box>
                    </Box>
                    {Array.isArray(data) && data.length ? (
                        <Box
                            className='row'
                            display='flex'
                            justifyContent='flex-end'
                        >
                            <AllButton onClick={handleOpenAll} />
                        </Box>
                    ) : null}
                    {reportNotGenerated ? <EmptyCardBanner /> : null}
                </Card>
                {modalVisible && (
                    <Drawer
                        wrapperClosable={false}
                        width='80vw'
                        title={'All Polls'}
                        onClose={() => {
                            setModalVisible(false);
                        }}
                        showClose
                        subtitle='Click through the questions to see detailed poll responses'
                        portal={true}
                    >
                        <Box
                            style={{
                                border: `1px solid ${theme.colors.ambience[21]}`,
                                flexGrow: 1,
                                borderRadius: '12px',
                                overflow: 'hidden',
                                flexBasis: 0,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                style={{
                                    backgroundColor: theme.colors.ambience[21],
                                    padding: '16px',
                                    borderTopLeftRadius: '12px',
                                    borderTopRightRadius: '12px',
                                }}
                            >
                                <RadioPill
                                    onClick={() => setPollType('all')}
                                    checked={pollType === 'all'}
                                >
                                    All polls ({nbAllPolls})
                                </RadioPill>
                                {nbEventPolls > 0 ? (
                                    <RadioPill
                                        onClick={() => setPollType('event')}
                                        checked={pollType === 'event'}
                                    >
                                        {airmeetFormat} polls ({nbEventPolls})
                                    </RadioPill>
                                ) : null}
                                {nbSessionPolls > 0 ? (
                                    <RadioPill
                                        onClick={() => setPollType('session')}
                                        checked={pollType === 'session'}
                                    >
                                        Session polls ({nbSessionPolls})
                                    </RadioPill>
                                ) : null}
                            </Box>
                            <Box
                                className='row'
                                style={{
                                    flexGrow: 1,
                                    minHeight: 0,
                                    flexBasis: 0,
                                    backgroundColor: theme.colors.ambience[23],
                                }}
                            >
                                <Box
                                    className='col-sm-4'
                                    style={{
                                        display: 'flex',
                                        height: '100%',
                                    }}
                                >
                                    <ul
                                        style={{
                                            overflow: 'hidden auto',
                                            borderRight: `1px solid ${theme.colors.ambience[21]}`,
                                            margin: '0',
                                            padding: '0',
                                        }}
                                        id='scroll-container'
                                    >
                                        {tableData.map((poll, index) => (
                                            <PollListItem
                                                key={poll?.id}
                                                data={poll}
                                                selected={
                                                    activePoll?.id === poll?.id
                                                }
                                                onClick={() =>
                                                    updateActivePollIndex(index)
                                                }
                                                name={index}
                                            />
                                        ))}
                                    </ul>
                                </Box>
                                <Box
                                    className='col-sm-8'
                                    display='flex'
                                    height='100%'
                                >
                                    <Box
                                        p='30px'
                                        pl='0px'
                                        flexGrow={1}
                                        overflow='hidden auto'
                                        id='right-scroll-container'
                                        name='right-top'
                                    >
                                        <Box
                                            display='flex'
                                            alignItems='stretch'
                                            mb={3}
                                        >
                                            <Box
                                                display='flex'
                                                flexDirection='column'
                                                alignItems='flex-start'
                                                flexGrow={1}
                                            >
                                                <Box
                                                    display='flex'
                                                    alignItems='center'
                                                    mb='12px'
                                                >
                                                    <Box pr={1}>
                                                        <AvatarImage
                                                            src={
                                                                activePoll?.user_profile_img
                                                            }
                                                            width='24px'
                                                        />
                                                    </Box>
                                                    <Typography
                                                        variant='subtext1'
                                                        color='ambience.4'
                                                        mr={0}
                                                    >
                                                        {activePoll?.user_name}
                                                    </Typography>
                                                    <Typography
                                                        variant='subtext1'
                                                        color='ambience.8'
                                                        mr={0}
                                                    >
                                                        asked in
                                                    </Typography>
                                                    <Typography
                                                        variant='subtext1'
                                                        color='ambience.4'
                                                    >
                                                        {activePoll?.poll_level ===
                                                        `${upperFirst(
                                                            airmeetFormat
                                                        )} Level`
                                                            ? `this ${airmeetFormat}`
                                                            : activePoll?.session_name}
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    variant='subtitle1'
                                                    color='ambience.1'
                                                    mb='12px'
                                                >
                                                    {activePoll?.poll_question}
                                                </Typography>
                                                <Box
                                                    display='flex'
                                                    alignItems='flex-end'
                                                >
                                                    <SVGIcon
                                                        icon='poll'
                                                        height='24px'
                                                        width='24px'
                                                        fill='ambience.8'
                                                        mr={1}
                                                    />
                                                    <Typography
                                                        variant='subtext1'
                                                        color='ambience.8'
                                                    >
                                                        {activePoll?.total_response ||
                                                            0}{' '}
                                                        responses
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box
                                                display='flex'
                                                flexDirection='column'
                                                justifyContent='space-between'
                                            >
                                                <Button
                                                    variant='link'
                                                    p={2}
                                                    disabled={
                                                        activePollIndex <= 0
                                                    }
                                                    onClick={handlePrev}
                                                    style={{
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                        outline: 'none',
                                                    }}
                                                >
                                                    <SVGIcon
                                                        icon='chevron_up'
                                                        fill='ambience.8'
                                                        height='24px'
                                                        width='24px'
                                                        opacity={
                                                            activePollIndex <= 0
                                                                ? 0.5
                                                                : 1
                                                        }
                                                    />
                                                </Button>
                                                <Button
                                                    variant='link'
                                                    p={2}
                                                    disabled={
                                                        activePollIndex >=
                                                        tableData.length - 1
                                                    }
                                                    onClick={handleNext}
                                                    style={{
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                        outline: 'none',
                                                    }}
                                                >
                                                    <SVGIcon
                                                        icon='chevron_down'
                                                        fill='ambience.8'
                                                        height='24px'
                                                        width='24px'
                                                        m={2}
                                                        opacity={
                                                            activePollIndex >=
                                                            tableData.length - 1
                                                                ? 0.5
                                                                : 1
                                                        }
                                                    />
                                                </Button>
                                            </Box>
                                        </Box>
                                        <Box
                                            display='flex'
                                            flexDirection='column'
                                            alignItems='stretch'
                                        >
                                            {(
                                                activePoll?.poll_options || []
                                            ).map((option, index) => (
                                                <PollOption
                                                    percent={Number(
                                                        (
                                                            option?.response_count_percentage ||
                                                            ''
                                                        ).replace('%', '') ||
                                                            '0'
                                                    )}
                                                    highest={index === 0}
                                                    key={index}
                                                    nbResponses={
                                                        option?.response_count ||
                                                        0
                                                    }
                                                    label={option?.option || ''}
                                                    index={index}
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Drawer>
                )}
            </Box>
        </Box>
    );
}
