import { Text } from 'foundations/themeV2/text/';
import get from 'lodash/get';
import React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import {
    color,
    flexbox,
    layout,
    space,
    system,
    typography,
} from 'styled-system';

const NotoBold = `https://assets.airmeet.com/assets/fonts/NotoSans-Bold.ttf`;
const NotoSemiBold = `https://assets.airmeet.com/assets/fonts/NotoSans-SemiBold.ttf`;
const NotoRegular = `https://assets.airmeet.com/assets/fonts/NotoSans-Regular.ttf`;
const NotoManipuriRegular = `https://assets.airmeet.com/assets/fonts/NotoSansMeeteiMayek-Regular.ttf`;

const Typography = React.forwardRef((props, ref) => {
    let {
        children,
        variant,
        condensed,
        color,
        lightThemeColor,
        isThemeV2 = false,
        ...rest
    } = props;

    if (isThemeV2) {
        return <Text {...props} ref={ref} />;
    }

    let commonProps = {
        ...rest,
        color,
        lightThemeColor,
        letterSpacing: condensed ? 1 : undefined,
        textTransform: condensed ? 'uppercase' : undefined,
        lineHeight: 3,
        fontSize: 3,
        ref,
        condensed,
        variant,
    };
    let Component = null;

    switch (variant) {
        case 'h1':
            Component = H1;
            commonProps = {
                ...commonProps,
                fontSize: [7, 9, 10],
                lineHeight: [7, 9, 10],
                ...rest,
            };
            break;
        case 'h2':
            Component = H2;
            commonProps = {
                ...commonProps,
                fontSize: [7, 8, 9],
                lineHeight: [7, 8, 9],
            };
            break;
        case 'h3':
            Component = H3;
            commonProps = {
                ...commonProps,
                fontSize: [4, 7, 8],
                lineHeight: [4, 7, 8],
            };
            break;
        case 'h4':
            Component = H4;
            commonProps = {
                ...commonProps,
                fontSize: 7,
                lineHeight: 7,
            };
            break;
        case 'h5':
            Component = H5;
            commonProps = {
                ...commonProps,
                fontSize: [4, 6, 6],
                lineHeight: [4, 6, 6],
            };
            break;
        case 'h6':
            Component = H6;
            commonProps = {
                ...commonProps,
                fontSize: [4, 5, 5],
                lineHeight: [4, 5, 5],
            };
            break;
        case 'subtitle1':
        case 'subtitle1-light':
        case 'subtitle1-bold':
            Component = H6;
            commonProps = {
                ...commonProps,
                fontSize: [3, 4, 4],
                lineHeight: 4,
            };
            break;
        case 'subtitle2-bold':
        case 'subtitle2':
        case 'subtitle2-regular':
        case 'body1':
        case 'closed-captions-body1':
        case 'body2':
        case 'caption-container':
        case 'button1':
            Component = Paragraph;
            break;

        case 'body3':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 15,
                lineHeight: 5,
            };
            break;
        case 'body-small':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: '12px',
                lineHeight: '18px',
            };
            break;
        case 'subtext0':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 11,
                lineHeight: 11,
            };
            break;
        case 'subtext0-bold':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 11,
                lineHeight: 11,
            };
            break;
        case 'subtext1':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 2,
                lineHeight: 2,
            };
            break;
        case 'button2':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: [1, 2, 2],
                lineHeight: [1, 2, 2],
            };
            break;
        case 'button3':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 11,
                lineHeight: 11,
            };
            break;
        case 'subtext2':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 1,
                lineHeight: 1,
            };
            break;
        case 'subtext2-bold':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 1,
                lineHeight: 1,
            };
            break;
        case 'caption':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 0,
                lineHeight: 0,
            };
            break;
        case 'caption8':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: '8px',
                lineHeight: '10px',
            };
            break;
        case 'caption2':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: '10px',
                lineHeight: '12px',
            };
            break;
        case 'caption8-light':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: '8px',
                lineHeight: '10px',
            };
            break;
        case 'caption-light':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 0,
                lineHeight: 1,
            };
            break;
        case 'span':
            Component = Span;
            commonProps = {
                ...commonProps,
                fontSize: 2,
                lineHeight: 1,
            };
            break;
        case 'subheading5':
            Component = SH5;
            commonProps = {
                ...commonProps,
                fontSize: [4, 5, 12],
                lineHeight: [2, 3, 4],
            };
            break;
        case 'display2':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 13,
                lineHeight: 12,
            };
            break;
        case 'display3':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 14,
                lineHeight: 10,
            };
            break;
        case 'caption-bold':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 0,
                lineHeight: 13,
            };
            break;
        case 'caption-regular':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 0,
                lineHeight: 13,
            };
            break;
        case 'title':
            Component = Paragraph;
            commonProps = {
                ...commonProps,
                fontSize: 15,
                lineHeight: 14,
            };
            break;
        default:
            break;
    }

    if (Component) return <Component {...commonProps}>{children}</Component>;

    return null;
});

export { Typography };
export const FontImport = createGlobalStyle`
    @font-face {
        font-family: ${({ theme }) =>
            theme?.eventBranding?.font?.fontFamily || 'NotoSans-Regular'};
        src: ${({ theme }) =>
            theme?.eventBranding?.font?.fontFamily
                ? theme?.eventBranding?.font?.src
                    ? `local("${theme?.eventBranding?.font?.fontFamily}"), url(${theme?.eventBranding?.font?.src}) format("truetype")`
                    : `local("${theme?.eventBranding?.font?.fontFamily}")`
                : `url(${NotoRegular}) format("truetype")`};
        font-weight: normal;
        font-style: normal;
        font-display: fallback;
    }

    @font-face {
        font-family: ${({ theme }) =>
            theme?.eventBranding?.font?.fontFamily || 'NotoSans-SemiBold'};
        src: ${({ theme }) =>
            theme?.eventBranding?.font?.fontFamily
                ? theme?.eventBranding?.font?.src
                    ? `local("${theme?.eventBranding?.font?.fontFamily}"), url(${theme?.eventBranding?.font?.src}) format("truetype")`
                    : `local("${theme?.eventBranding?.font?.fontFamily}")`
                : `url(${NotoSemiBold}) format("truetype")`};
        font-weight: 600;
        font-style: normal;
        font-display: fallback;
    }

    @font-face {
        font-family: ${({ theme }) =>
            theme?.eventBranding?.font?.fontFamily || 'NotoSans-Bold'};
        src: ${({ theme }) =>
            theme?.eventBranding?.font?.fontFamily
                ? theme?.eventBranding?.font?.src
                    ? `local("${theme?.eventBranding?.font?.fontFamily}"), url(${theme?.eventBranding?.font?.src}) format("truetype")`
                    : `local("${theme?.eventBranding?.font?.fontFamily}")`
                : `url(${NotoBold}) format("truetype")`};
        font-weight: 700;
        font-style: normal;
        font-display: fallback;
    }
    
    @font-face {
        font-family: 'NotoSansMeeteiMayek-Regular';
        src: ${`url(${NotoManipuriRegular}) format("truetype")`};
        font-weight: normal;
        font-style: normal;
        font-display: fallback;
    }

    body {
        --default-font: ${({ theme }) => theme.fonts.book};
        --default-font-medium: ${({ theme }) => theme.fonts.medium};
        --default-font-bold: ${({ theme }) => theme.fonts.bold};
        font-family: ${({ theme }) =>
            theme.fonts.book || 'NotoSans-Regular'}, sans-serif !important;

        .emoji-mart,.atcb-list {
            font-family: ${({ theme }) =>
                theme.fonts.book || 'NotoSans-Regular'}, sans-serif !important;
        }
    }
    h1,h2,h3,h4,h5,h6,p {
        margin: 0px;
        padding: 0px;
        font-weight: normal;
    }
`;

const getColor = (theme, color) =>
    color && (get(theme, `colors.${color}`) || color);

const commonStyles = css`
    ${space}
    ${color}
    ${typography}
    ${flexbox}
    ${layout}
    ${system({ cursor: true })}
    ${system({ whiteSpace: true })}
    ${system({ wordBreak: true })}
    text-transform: ${(props) => props.textTransform};
    word-wrap: break-word;
    color: ${({ theme, color, lightThemeColor }) =>
        theme.isLightTheme && lightThemeColor
            ? getColor(theme, lightThemeColor)
            : getColor(theme, color) || theme.colors.ambience[0]};
    font-family: ${({ theme, variant }) => {
        switch (variant) {
            case 'body1':
            case 'caption':
            case 'span':
                return theme.fonts.medium;
            case 'body2':
            case 'body3':
            case 'body-small':
            case 'subtext0':
            case 'subtext1':
            case 'subtext2':
            case 'display2':
            case 'display3':
            case 'caption-regular':
            case 'subheading5':
            case 'caption-light':
            case 'subtitle1-light':
            case 'subtitle2-regular':
            case 'caption8-light':
                return theme.fonts.book;
            case 'caption-container':
            case 'closed-captions-body1':
                return `NotoSansMeeteiMayek-Regular, ${theme.fonts.book}, Helvetica, sans-serif`;
            default:
                return theme.fonts.bold;
        }
    }};
    ::selection {
        background: ${({ theme }) => theme.colors.accentPrimary[1]};
        color: ${({ theme }) =>
            theme?.primaryButtonTextColor ||
            (theme.isLightTheme
                ? theme.colors.ambience[24]
                : theme.colors.ambience[0])};
    }
`;

const H1 = styled.h1`
    ${commonStyles}
`;

const H2 = styled.h2`
    ${commonStyles}
`;

const H3 = styled.h3`
    ${commonStyles}
`;

const H4 = styled.h4`
    ${commonStyles}
`;

const H5 = styled.h5`
    ${commonStyles}
`;

const SH5 = styled.h5`
    ${commonStyles}
`;

const H6 = styled.h6`
    ${commonStyles}
`;

const Paragraph = styled.p`
    ${commonStyles}
`;

const Span = styled.span`
    ${commonStyles}
`;

Typography.defaultProps = {
    variant: 'h1',
    condensed: false,
};
