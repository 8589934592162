import React, { Component } from 'react';
import {
    dashboardModuleNames,
    ErrorModuleNames as moduleNames,
} from 'utils/constants/errorBoundary';
import { logger } from 'utils/logger';
import ErrorRemountFallback from './ErrorRemountFallback';
import ErrorTemplate from './ErrorTemplate';

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || '';
}

const withErrorBoundary = (
    WrappedComponent,
    moduleName = '',
    errorBoundaryProps = { isAutoRemountEnabled: true }
) =>
    class extends Component {
        constructor(props) {
            super(props);
            this.state = { hasError: false, key: false, retryCount: 1 };
        }

        static getDerivedStateFromError(error) {
            return { hasError: true };
        }

        componentDidCatch(error, errorInfo) {
            const errorMessage = `ErrorBoundary:${moduleName}:${
                errorBoundaryProps?.displayName ||
                getDisplayName(WrappedComponent)
            }:${error?.message}`;
            if (
                errorBoundaryProps.isAutoRemountEnabled &&
                this.state.retryCount <= 1
            ) {
                this.handleReloadClick();
                const errMsg = `Tried remounting ${
                    errorBoundaryProps?.displayName ||
                    getDisplayName(WrappedComponent)
                }, ${this.state.retryCount} times`;
                logger.info(errMsg, error, errorInfo);
            }
            if (this.state.retryCount > 1) {
                logger.error(errorMessage, error, errorInfo);
            }
        }

        handleReloadClick = () => {
            this.setState(({ key, retryCount }) => ({
                key: !key,
                hasError: false,
                retryCount: retryCount + 1,
            }));
            if (!errorBoundaryProps.isAutoRemountEnabled) {
                logger.info(
                    `Tried remounting ${
                        errorBoundaryProps?.displayName ||
                        getDisplayName(WrappedComponent)
                    }, ${this.state.retryCount} times, through manual reload}`
                );
            }
        };

        render() {
            if (this.state.hasError) {
                return !errorBoundaryProps.isAutoRemountEnabled &&
                    this.state.retryCount <= 1 ? (
                    <ErrorRemountFallback
                        errorBoundaryProps={errorBoundaryProps}
                        reloadFn={this.handleReloadClick}
                    />
                ) : (
                    <ErrorTemplate
                        errorMessage={errorBoundaryProps?.errorMessage}
                        height={errorBoundaryProps?.height}
                    />
                );
            }

            return <WrappedComponent key={this.state.key} {...this.props} />;
        }
    };

export default withErrorBoundary;

export const ErrorModuleNames = moduleNames;
export const DashboardModules = dashboardModuleNames;
