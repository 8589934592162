// !Only use snake_case convention for values
export const CONTEXT_TYPE = {
    EVENT: 'event',
    BOOTH: 'booth',
    STAGE: 'stage',
    FLUID_SPACE: 'fluid_space',
    FLUID_SPACE_ZONE: 'fluid_space_zone',
    GROUP_MEETING: 'groupMeeting', //TODO: CHANGE IT TO SNAKE CASE
};

export const ATTENDANCE_PATH = 'liveCounter';
