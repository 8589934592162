import { FREE_ATTENDEE, FREE_PLAN } from 'hooks/metered-pricing/config';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EVENT_TYPES } from 'utils/constants/airmeet';

/**
 * This hook gives the feature flags for the CN dashboard
 * @param {object} airmeet Airmeet object from getAirmeet or getAirmeetInfo API
 * @param {object} devOverride Override flag, if set, reads from the env file for quick dev iterations
 */

// Feature flag names
export const ROLES_AND_PERMISSIONS_FLAG = 'is_roles_and_permissions_enabled';

function useDashboardFeatureFlags({ airmeet, devOverride = false }) {
    const community = useSelector((state) => state.Community.current);

    const flags = useMemo(() => {
        // BE driven flags
        const {
            is_branding_enabled: airmeet_branding_enabled,
            is_custom_tables_enabled,
            enabled_feature_data_v2: enabled_feature_data,
            event_type,
            session_time_limit_applicable,
            is_custom_attendee_exp_enabled,
            is_leaderboard_enabled,
            is_custom_interests_enabled,
            subscription_type,
            hybrid_events_in_person_attendance_enabled,
            is_waitlist_feature_allowed,
            is_waitlist_feature_enabled,
        } = airmeet || {};

        const {
            booths,
            venue_settings,
            sponsors,
            branding,
            recordings_contact_us,
            is_venue_settings_logo_enabled,
            is_custom_reception_enabled,
            allowed_max_supported_streaming_resolution,
            is_automated_workflow_enabled_v2,
        } = enabled_feature_data || {};

        const {
            is_branding_enabled: community_branding_enabled,
            enabled_feature_data: community_enabled_featured_data,
            metadata,
            is_event_series_enabled,
            is_video_editor_enabled,
        } = community || {};

        const {
            is_ticketing_enabled,
            is_analytics_enabled,
            is_custom_email_template_enabled,
            is_content_hub_enabled,
            hybrid_events_enabled,
            in_person_events_enabled,
            [ROLES_AND_PERMISSIONS_FLAG]: isRolesAndPermissionsEnabled,
            is_engagement_enabled,
            is_unified_profiles_enabled,
        } = community_enabled_featured_data || {};
        const is_branding_enabled = airmeet_branding_enabled
            ? airmeet_branding_enabled
            : community_branding_enabled;

        const { leads } = metadata || {};

        // Base list of feature flags
        const featureFlagList = {
            is_branding_enabled,
            is_custom_tables_enabled,
            booths,
            venue_settings,
            sponsors,
            branding,
            // Overriding BE, Enabling only for Conference events
            is_session_host_enabled: event_type === EVENT_TYPES.CONFERENCE,
            leads,
            recordings_contact_us,
            is_metered_pricing_enabled:
                process.env.REACT_APP_USE_METERED_PRICING === 'true',
            is_ticketing_enabled,
            is_analytics_enabled,
            is_new_onboarding_enabled:
                process.env.REACT_APP_USE_NEW_ONBOARDING === 'true',
            session_time_limit_applicable,
            is_custom_email_template_enabled,
            is_custom_attendee_exp_enabled,
            isLeaderboardEnabled: is_leaderboard_enabled,
            isCustomInterestsEnabled: is_custom_interests_enabled,
            is_manage_subscription_feature_enabled:
                process.env.REACT_APP_USE_MANAGE_SUBSCRIPTION === 'true',
            is_v3_onboarding_enabled:
                process.env.REACT_APP_USE_ONBOARDING_V3 === 'true',
            is_custom_reception_enabled,
            is_venue_settings_logo_enabled,
            allowed_max_supported_streaming_resolution,
            is_free_plan_user_in_meetup:
                !subscription_type ||
                ([FREE_PLAN, FREE_ATTENDEE].includes(subscription_type) &&
                    event_type !== EVENT_TYPES.CONFERENCE),
            is_pre_recorded_session_enabled: !!airmeet?.is_pre_recorded_session_enabled,
            is_content_hub_enabled,
            isHybridEnabled: hybrid_events_enabled,
            isRolesAndPermissionsEnabled,
            hybrid_events_in_person_attendance_enabled,
            isResourcePersonalizationEnabled:
                event_type === EVENT_TYPES.CONFERENCE,
            isAutomatedWorkflowEnabled: is_automated_workflow_enabled_v2,
            isEngagementEnabled: is_engagement_enabled,
            isUnifiedProfilesEnabled: is_unified_profiles_enabled,
            isInPersonEventEnabled: in_person_events_enabled,
            isWaitlistFeatureAllowed: is_waitlist_feature_allowed,
            isWaitlistFeatureEnabled: is_waitlist_feature_enabled,
            is_event_series_enabled,
            is_video_editor_enabled,
        };
        return Object.keys(featureFlagList).reduce((acc, key) => {
            // Override for dev env for testing
            if (process.env.NODE_ENV === 'development' && devOverride) {
                if (
                    [
                        'is_venue_settings_enabled',
                        'is_booths_enabled',
                        'is_sponsors_enabled',
                        'is_session_host_enabled',
                    ].includes(key)
                ) {
                    acc[key] =
                        process.env.REACT_APP_VIRTUAL_SUMMIT_DASHBOARD ===
                        'true';
                    return acc;
                }
            }
            acc[key] = featureFlagList[key];
            return acc;
        }, {});
    }, [airmeet, community, devOverride]);

    return flags;
}

export default useDashboardFeatureFlags;
