import useContainerNotifications from 'atoms/Container/useContainerNotifications';
import { useMemo } from 'react';
import { BREAKOUT_HAS_STARTED } from 'utils/constants/containers/notifications';
import useBreakoutConfig from './useBreakoutConfig';

const useActiveBreakout = () => {
    const breakoutState = useBreakoutConfig();
    const {
        isBreakoutActive,
        activeBreakoutId,
        breakoutEntityId,
    } = breakoutState;
    const { notify } = useContainerNotifications();
    useMemo(() => {
        if (isBreakoutActive) {
            notify(BREAKOUT_HAS_STARTED, {
                activeBreakoutId,
                breakoutEntityId,
            });
        }
    }, [isBreakoutActive, activeBreakoutId, breakoutEntityId, notify]);

    return breakoutState;
};

export default useActiveBreakout;
