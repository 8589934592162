import { CONTEXT_TYPE } from 'hooks/attendance/constants';
import { getMemberInContextKey } from 'hooks/live-airmeet/useUserPresence';
import LRUCache from 'quick-lru';
import { bindModuleLogger } from 'utils/logger';
import { Presence, UserPresenceServiceParams } from './types';
const logger = bindModuleLogger('Presence Service hook');

export interface PresenceContext {
    name: string;
    id: string;
}

interface SubscribeOptions {
    context: PresenceContext;
}
export default class UserPresenceService {
    private static service: UserPresenceService;

    static getInstance(): UserPresenceService {
        if (UserPresenceService.service) {
            return UserPresenceService.service;
        }

        throw new Error('Access before creation');
    }

    private params: UserPresenceServiceParams;
    private cache: LRUCache<string, Presence>;

    static newInstance(params: UserPresenceServiceParams): UserPresenceService {
        if (UserPresenceService.service) {
            return UserPresenceService.service;
        }
        UserPresenceService.service = new UserPresenceService(params);
        return UserPresenceService.service;
    }

    constructor(params: UserPresenceServiceParams) {
        this.cache = new LRUCache({ maxSize: 500 });
        this.params = params;
    }

    getDefaultContext() {
        return {
            name: CONTEXT_TYPE.EVENT,
            id: this.params.airmeetId,
        };
    }

    // returns a function to call to unsubscribe
    subscribe(
        id: string,
        callback: (presence: Presence) => void,
        options?: SubscribeOptions
    ): Function {
        logger.debug('Adding presence subscription', id);

        let cacheKey = this.getCacheKey(id, options?.context);
        const subscription = (snap) => {
            const value = snap.payload;
            this.onPresenceLoaded(cacheKey, value);
            callback(this.cache.get(cacheKey));
        };

        const unsubscribe = this.params.observer.subscribe(subscription, {
            userId: id,
            context: options?.context,
        });

        return () => {
            logger.debug('Cancelling presence subscription', id);
            unsubscribe && unsubscribe();
        };
    }

    getCacheKey(id: string, context?: PresenceContext) {
        context = context || this.getDefaultContext();
        return `${getMemberInContextKey(context.id, context.name)}/${id}`;
    }

    getCached(id: string, context?: PresenceContext): Presence | undefined {
        return this.cache.get(this.getCacheKey(id, context));
    }

    async isOnlineNow(id: string, context?: PresenceContext): Promise<boolean> {
        let cacheKey = this.getCacheKey(id, context);
        if (this.cache.has(cacheKey)) {
            return Promise.resolve(this.cache.get(cacheKey).isOnline);
        }

        return new Promise<boolean>((resolve) => {
            const subscription = (snap) => {
                const value = snap.payload;
                this.onPresenceLoaded(cacheKey, value);
                resolve(this.cache.get(cacheKey).isOnline);
            };

            this.params.observer.subscribe(subscription, {
                userId: id,
                context,
            });
        });
    }

    private onPresenceLoaded(key: string, value: any) {
        const presence: Presence = {
            isOnline: false,
            metadata: null,
            isLoading: false,
        };
        if (value) {
            presence.isOnline = true;
            presence.metadata = value;
        }

        logger.debug('Loaded presence', key, presence);
        this.cache.set(key, presence);
    }
}
