import { EVENTS_PAGE_TAB } from 'components/community/constants';
import history from 'history.js';
import get from 'lodash/get';

export const navigateToEventListPage = ({ communityId, history }) => {
    const backTab = history?.location?.state?.tab || EVENTS_PAGE_TAB;
    history.push(`/community-manager/${communityId}/${backTab}`);
};

export const isKeyValueTrueOrKeyDoesNotExistForTab = (key, tab) =>
    tab?.[key] || !tab?.hasOwnProperty(key);

export const isKeyValueFalseAndKeyExistsForTab = (key, tab) =>
    // Inverse of above function
    tab?.hasOwnProperty(key) && !tab[key];

export const doesTabHaveTruthyValuesForAllKeys = (keys = [], tab) =>
    keys.every((key) => isKeyValueTrueOrKeyDoesNotExistForTab(key, tab));

const doesTabHaveFalsyValuesForSomeKeys = (keys = [], tab) =>
    keys.some((key) => isKeyValueFalseAndKeyExistsForTab(key, tab));

export const areSomeOfCurrentNavTabsSubTabsVisibleAndClickable = (currentTab) =>
    currentTab.tabs.some((subTab) =>
        doesTabHaveTruthyValuesForAllKeys(['clickable', 'visible'], subTab)
    );

export const getNearestChildTabUrl = ({
    tab,
    parentTab = null,
    isRolesAndPermissionsEnabled = false,
}) => {
    const hasSubTabs = get(tab, 'tabs.0.id');

    if (!isRolesAndPermissionsEnabled && tab?.component) {
        return tab?.url;
    } else if (
        isRolesAndPermissionsEnabled &&
        doesTabHaveTruthyValuesForAllKeys(
            ['viewable', 'clickable', 'visible'],
            tab
        ) &&
        !hasSubTabs &&
        tab?.component
    ) {
        return tab?.url;
    } else if (
        hasSubTabs &&
        doesTabHaveTruthyValuesForAllKeys(
            ['viewable', 'clickable', 'visible'],
            tab
        )
    ) {
        // Is the current tab has sub tabs then find the nearest valid child in the children
        return getNearestChildTabUrl({
            tab: get(tab, 'tabs.0'),
            parentTab: tab,
            isRolesAndPermissionsEnabled,
        });
    } else if (
        parentTab &&
        (!tab?.component ||
            doesTabHaveFalsyValuesForSomeKeys(
                ['viewable', 'clickable', 'visible'],
                tab
            ))
    ) {
        // If the first item in the tabs array is not valid then find the next valid item
        const nextChild = (parentTab?.tabs || []).find(
            (tab) =>
                tab?.component &&
                doesTabHaveTruthyValuesForAllKeys(
                    ['viewable', 'clickable', 'visible'],
                    tab
                )
        );
        if (nextChild) {
            return nextChild?.url;
        }
    }
    return '';
};

export const openDrawerRoute = (drawerId) => {
    const params = new URLSearchParams(window?.location?.search || '');
    params.set('drawer', drawerId);
    history.push({
        pathname: window?.location?.pathname,
        search: params.toString(),
    });
};
