export const ADD_SCRIPT = 'ADD_SCRIPT';
export const REMOVE_SCRIPT = 'REMOVE_SCRIPT';

export const getWidgetScript = (scriptId) =>
    document.getElementById(`${scriptId}`);

export const scriptOperation = (operation, scriptId, widgetScript = null) => {
    switch (operation) {
        case ADD_SCRIPT:
            if (!getWidgetScript(scriptId)) {
                const configScript = document.createElement('script');
                configScript.id = scriptId;
                configScript.appendChild(document.createTextNode(widgetScript));
                document.body.appendChild(configScript);
            }
            break;
        case REMOVE_SCRIPT:
            if (getWidgetScript(scriptId))
                document.body.removeChild(getWidgetScript(scriptId));
            break;
        default:
            break;
    }
};
