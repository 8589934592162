import Box from 'atoms/Box';
import Button from 'atoms/Button/button';
import Dropdown from 'atoms/Dropdown';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import {
    FlexDiv,
    UnstyledButton,
} from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import { alpha } from 'foundations/theme';
import { Text } from 'foundations/themeV2/text';
import { Typography } from 'foundations/typography';
import React from 'react';
import styled from 'styled-components';
import { cdnImage } from 'utils/core';

const UploadSuccess = ({
    fileData = Object,
    uploadNew,
    clearSelection,
    isVariantV2,
    isThemeV2 = false,
}) => {
    const handleChange = (id) => {
        switch (id) {
            case 'upload-new':
                uploadNew && uploadNew();
                break;
            case 'remove':
                clearSelection && clearSelection();
                break;
            default:
                break;
        }
    };

    return (
        <SuccessWrapper>
            {isThemeV2 ? (
                <FlexDiv
                    flexDirection='column'
                    alignItems='stretch'
                    justifyContent='stretch'
                    height='100%'
                    width='100%'
                >
                    <ImgPlaceholder
                        flex='1'
                        img={fileData?.poster}
                    ></ImgPlaceholder>
                    <Footer flexDirection='column' alignItems='stretch'>
                        <FlexDiv alignItems='center' pb='x2'>
                            <Text
                                variant='caption'
                                color='text.default.primary'
                                flex='1'
                            >
                                {fileData?.fileName}
                            </Text>
                            <Dropdown
                                options={[
                                    {
                                        value: 'upload-new',
                                        label: 'Upload New',
                                    },
                                    { value: 'remove', label: 'Remove Video' },
                                ]}
                                onChange={handleChange}
                            >
                                <UnstyledButton>
                                    <StyledSvgSprite
                                        icon='ic-more-dots'
                                        fill='text.default.primary'
                                        height='1.25rem'
                                        width='1.25rem'
                                    />
                                </UnstyledButton>
                            </Dropdown>
                        </FlexDiv>
                        <FlexDiv alignItems='center'>
                            <Text
                                variant='caption'
                                color='text.default.secondary'
                                flex='1'
                            >
                                Video
                            </Text>
                        </FlexDiv>
                    </Footer>
                </FlexDiv>
            ) : (
                <>
                    <FlexDiv
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <img
                            src={cdnImage('/images/dashboard/video-upload.svg')}
                            width={176}
                            height={123}
                            alt='upload'
                        />
                        <Typography
                            variant={isThemeV2 ? 'h5' : 'button2'}
                            color={
                                isThemeV2
                                    ? 'text.default.primary'
                                    : 'ambience.24'
                            }
                            mt={'12px'}
                            px={'8px'}
                            style={{
                                textAlign: 'center',
                                wordBreak: 'break-word',
                            }}
                            isThemeV2={isThemeV2}
                        >
                            Your {fileData?.fileName} file was uploaded
                            successfully
                        </Typography>
                        <Typography
                            variant={'caption'}
                            color={
                                isThemeV2
                                    ? 'text.default.secondary'
                                    : 'ambience.14'
                            }
                            pt={1}
                            isThemeV2={isThemeV2}
                        >
                            This will now get auto played, when the event opens.
                        </Typography>
                    </FlexDiv>
                    <ActionWrapper>
                        <img
                            src={cdnImage('/images/dashboard/play-video.svg')}
                            width={116}
                            height={76}
                            alt={'play'}
                        />
                        <FlexDiv
                            alignItems={'center'}
                            width={'100%'}
                            justifyContent={'center'}
                            mt={'24px'}
                        >
                            {!isVariantV2 && (
                                <Button
                                    variant={'primary'}
                                    maxWidth={isThemeV2 ? '' : '164px'}
                                    height={isThemeV2 ? '' : '44px'}
                                    mr={isThemeV2 ? 'x7' : '24px'}
                                    p={isThemeV2 ? '' : '0px'}
                                    onClick={uploadNew}
                                    isThemeV2={isThemeV2}
                                >
                                    <Icon
                                        icon={'upload'}
                                        width={16}
                                        height={16}
                                        mr={'8px'}
                                        fill={isThemeV2 ? '' : 'ambience.0'}
                                    />
                                    Upload New
                                </Button>
                            )}
                            <Button
                                variant={'link'}
                                color={isThemeV2 ? '' : 'accentPrimary.0'}
                                onClick={clearSelection}
                                isThemeV2={isThemeV2}
                            >
                                <Icon
                                    icon={'delete'}
                                    width={16}
                                    height={16}
                                    mr={isThemeV2 ? 'x1' : '8px'}
                                    fill={isThemeV2 ? '' : 'accentPrimary.0'}
                                />
                                {isVariantV2 ? 'Remove Video' : 'Delete Video'}
                            </Button>
                        </FlexDiv>
                    </ActionWrapper>
                </>
            )}
        </SuccessWrapper>
    );
};

export default UploadSuccess;

export const ActionWrapper = styled(Box)`
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    background: ${({ theme }) => alpha(theme.colors.ambience[24], 0.8)};
    align-items: center;
    justify-content: center;
    flex-direction: column;

    button:hover {
        opacity: 1;
    }
`;

export const SuccessWrapper = styled(Box)`
    position: relative;
    z-index: 2;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    &:hover {
        ${ActionWrapper} {
            display: flex;
        }
    }
`;

const Footer = styled(FlexDiv)`
    position: relative;
    padding: ${({ theme }) => theme.space.x4};
    background-color: ${({ theme }) => theme.colors.fill.other3};
    border-radius: ${({ theme }) =>
        `0 0 ${theme.radii.default} ${theme.radii.default}`};
`;

const ImgPlaceholder = styled(FlexDiv)`
    background-image: url('${({ img }) => img}');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: ${({ theme }) =>
        `${theme.radii.default} ${theme.radii.default} 0 0`};
`;
