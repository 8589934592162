// All the constant keys will be stored here
// which will be part of localization
const keys = {
    RECEPTION: 'RECEPTION',
    RESOURCES: 'RESOURCES',
    RECEPTION_WELCOME_TITLE: 'RECEPTION_WELCOME_TITLE',
    RECEPTION_SUBHEADER: 'RECEPTION_SUBHEADER',
    RECEPTION_EVENT_PARTNERS: 'RECEPTION_EVENT_PARTNERS',
    RECEPTION_SUPPORTED_BY: 'RECEPTION_SUPPORTED_BY',
    BOOTH: 'BOOTH',
    RECEPTION_VISIT_WEBSITE: 'RECEPTION_VISIT_WEBSITE',
    RECEPTION_ALL_SESSION_FINISHED: 'RECEPTION_ALL_SESSION_FINISHED',
    RECEPTION_NETWORKING_TEXT: 'RECEPTION_NETWORKING_TEXT',
    RECEPTION_TABLES_TEXT: 'RECEPTION_TABLES_TEXT',
    RECEPTION_EDIT_WELCOME_MESSAGE: 'RECEPTION_EDIT_WELCOME_MESSAGE',
    RECEPTION_PRIMARY_CARD_IMAGE_ALT: 'RECEPTION_PRIMARY_CARD_IMAGE_ALT',
    RECEPTION_PRIMARY_CARD_GOTO_BOOTH: 'RECEPTION_PRIMARY_CARD_GOTO_BOOTH',
    RECEPTION_LOUNGE_CARD_TITLE: 'RECEPTION_LOUNGE_CARD_TITLE',
    IFRAME_STATIC_INFO: 'IFRAME_STATIC_INFO',
    RECEPTION_SECONDARY_CARD_SPONSORED_BOOTH:
        'RECEPTION_SECONDARY_CARD_SPONSORED_BOOTH',
    RECEPTION_WALK_THROUGH_HEADER: 'RECEPTION_WALK_THROUGH_HEADER',
    RECEPTION_WALK_THROUGH_SUBHEADER2: 'RECEPTION_WALK_THROUGH_SUBHEADER2',
    RECEPTION_LIVE_UPCOMING: 'RECEPTION_LIVE_UPCOMING',
    SESSIONS: 'SESSIONS',
    SESSION_GO_BACKSTAGE: 'SESSION_GO_BACKSTAGE',
    SESSION_GO_BACKSTAGE_TOOLTIP_HEADER: 'SESSION_GO_BACKSTAGE_TOOLTIP_HEADER',
    SESSION_GO_BACKSTAGE_TOOLTIP_DESCRIPTION:
        'SESSION_GO_BACKSTAGE_TOOLTIP_DESCRIPTION',
    SPEAKERS: 'SPEAKERS',
    SESSION_YOUR_ROLE_HOST: 'SESSION_YOUR_ROLE_HOST',
    SESSION_YOUR_ROLE_ORGANIZER: 'SESSION_YOUR_ROLE_ORGANIZER',
    SESSION_YOUR_ROLE_SPEAKER: 'SESSION_YOUR_ROLE_SPEAKER',
    SESSION_YOUR_ROLE_EXHIBITOR: 'SESSION_YOUR_ROLE_EXHIBITOR',
    SESSION_YOUR_ROLE_ATTENDEE: 'SESSION_YOUR_ROLE_ATTENDEE',
    SESSION_YOUR_ROLE_COHOST: 'SESSION_YOUR_ROLE_COHOST',
    SESSION_STARTING_SOON: 'SESSION_STARTING_SOON',
    ALL_SESSIONS: 'ALL_SESSIONS',
    MY_SCHEDULE: 'MY_SCHEDULE',
    MY_BOOKMARKS: 'MY_BOOKMARKS',
    MY_MEETINGS: 'MY_MEETINGS',
    FILTER_TRACKS: 'FILTER_TRACKS',
    FILTER_TAGS: 'FILTER_TAGS',
    FILTER_TYPES: 'FILTER_TYPES',
    FILTER_LOCATION: 'FILTER_LOCATION',
    SESSION_STATUS: 'SESSION_STATUS',
    SESSION_SEARCH_SESSION: 'SESSION_SEARCH_SESSION',
    SESSION_UPCOMING: 'SESSION_UPCOMING',
    SESSION_COMPLETED: 'SESSION_COMPLETED',
    SESSION_ADD_SCHEDULE: 'SESSION_ADD_SCHEDULE',
    SESSION_SPEAKERS_HOST: 'SESSION_SPEAKERS_HOST',
    SESSION_FILTER_TAGS: 'SESSION_FILTER_TAGS',
    SESSION_NO_SESSION_IN_SCHEDULE: 'SESSION_NO_SESSION_IN_SCHEDULE',
    SESSION_ADD_YOUR_SCHEDULE_1: 'SESSION_ADD_YOUR_SCHEDULE_1',
    SESSION_ADD_YOUR_SCHEDULE_2: 'SESSION_ADD_YOUR_SCHEDULE_2',
    SESSION_SESSION_CREATED: 'SESSION_SESSION_CREATED',
    SESSION_SESSION_ENDED: 'SESSION_SESSION_ENDED',
    SESSION_SESSION_RESUME: 'SESSION_SESSION_RESUME',
    SESSION_JOIN_SESSION: 'SESSION_JOIN_SESSION',
    SESSION_JOIN_SESSION_POPUP_HEADING: 'SESSION_JOIN_SESSION_POPUP_HEADING',
    SESSION_JOIN_SESSION_POPUP_QUESTION: 'SESSION_JOIN_SESSION_POPUP_QUESTION',
    SESSION_JOIN_SESSION_POPUP_DISMISS: 'SESSION_JOIN_SESSION_POPUP_DISMISS',
    SESSION_RESUME_SESSION: 'SESSION_RESUME_SESSION',
    SESSION_START_SESSION_BTN: 'SESSION_START_SESSION_BTN',
    SESSION_START_SESSION: 'SESSION_START_SESSION',
    SESSION_START_SESSION_POPUP_QUESTION:
        'SESSION_START_SESSION_POPUP_QUESTION',
    SESSION_START_SESSION_POPUP_CONFIRM_MESSAGE_1:
        'SESSION_START_SESSION_POPUP_CONFIRM_MESSAGE_1',
    SESSION_START_SESSION_POPUP_STREAM_MESSAGE:
        'SESSION_START_SESSION_POPUP_STREAM_MESSAGE',
    SESSION_WAIT_OTHERS_TO_JOIN: 'SESSION_WAIT_OTHERS_TO_JOIN',
    SESSION_ALL_CONNECTED: 'SESSION_ALL_CONNECTED',
    SESSION_GOOD_TO: 'SESSION_GOOD_TO',
    SESSION_GO: 'SESSION_GO',
    SESSION_TAKE_BREAK: 'SESSION_TAKE_BREAK',
    SESSION_PAUSE_SESSIONS_HEADER: 'SESSION_PAUSE_SESSIONS_HEADER',
    SESSION_PAUSE_SESSIONS_MESSAGE: 'SESSION_PAUSE_SESSIONS_MESSAGE',
    SESSION_END_SESSION_BTN: 'SESSION_END_SESSION_BTN',
    SESSION_END_SESSION: 'SESSION_END_SESSION',
    SESSION_END_SESSION_MESSAGE: 'SESSION_END_SESSION_MESSAGE',
    SESSION_START_ERROR: 'SESSION_START_ERROR',
    SESSION_PAUSE_ERROR: 'SESSION_PAUSE_ERROR',
    SESSION_END_ERROR: 'SESSION_END_ERROR',
    SESSION_PIP_ENTER_TEXT: 'SESSION_PIP_ENTER_TEXT',
    SESSION_END_IN_PIP: 'SESSION_END_IN_PIP',
    LOUNGE: 'LOUNGE',
    LOUNGE_SOCIAL_LOUNGE: 'LOUNGE_SOCIAL_LOUNGE',
    LOUNGE_DEFAULT_TITLE_SUMMIT: 'LOUNGE_DEFAULT_TITLE_SUMMIT',
    LOUNGE_DEFAULT_TITLE: 'LOUNGE_DEFAULT_TITLE',
    LOUNGE_TAKE_A_SEAT: 'LOUNGE_TAKE_A_SEAT',
    LOUNGE_DEFAULT_SUBTITLE: 'LOUNGE_DEFAULT_SUBTITLE',
    LOUNGE_IS_HERE: 'LOUNGE_IS_HERE',
    LOUNGE_WAITING_FOR_OTHERS: 'LOUNGE_WAITING_FOR_OTHERS',
    LOUNGE_CONFIGURE_SOCIAL_LOUNGE: 'LOUNGE_CONFIGURE_SOCIAL_LOUNGE',
    LOUNGE_SOCIAL_LOUNGE_TITLE: 'LOUNGE_SOCIAL_LOUNGE_TITLE',
    LOUNGE_SOCIAL_LOUNGE_SUBTITLE: 'LOUNGE_SOCIAL_LOUNGE_SUBTITLE',
    LOUNGE_YOU_ARE_HERE: 'LOUNGE_YOU_ARE_HERE',
    LOUNGE_NO_CAPTIONS_AVAILABLE: 'LOUNGE_NO_CAPTIONS_AVAILABLE',
    LOUNGE_EDIT: 'LOUNGE_EDIT',
    ARENA: 'ARENA',
    ARENA_FILTER_BY_TAG: 'ARENA_FILTER_BY_TAG',
    ARENA_FILTER_BY_TRACK: 'ARENA_FILTER_BY_TRACK',
    ARENA_ALL_TAGS: 'ARENA_ALL_TAGS',
    ARENA_SEARCH_TAGS: 'ARENA_SEARCH_TAGS',
    ARENA_SEARCH_BOOTH: 'ARENA_SEARCH_BOOTH',
    BOOTHS_VIEW_ALL: 'BOOTHS_VIEW_ALL',
    BOOTH_EDIT_BOOTH_INFO: 'BOOTH_EDIT_BOOTH_INFO',
    BOOTH_CONNECT_WITH_US: 'BOOTH_CONNECT_WITH_US',
    BOOTH_RESOURCES: 'BOOTH_RESOURCES',
    BOOTH_JOIN_TABLE_MEMBERS: 'BOOTH_JOIN_TABLE_MEMBERS',
    BOOTH_CONNECT_ON_TABLES: 'BOOTH_CONNECT_ON_TABLES',
    BOOTH_REGISTER_INTEREST_LABEL: 'BOOTH_REGISTER_INTEREST_LABEL',
    BOOTH_REGISTER_INTEREST_ENTER_EMAIL_LABEL_1:
        'BOOTH_REGISTER_INTEREST_ENTER_EMAIL_LABEL_1',
    BOOTH_REGISTER_INTEREST_ENTER_EMAIL_LABEL_2:
        'BOOTH_REGISTER_INTEREST_ENTER_EMAIL_LABEL_2',
    BOOTH_REGISTER_INTEREST_EMAIL_EMPTY_ERROR:
        'BOOTH_REGISTER_INTEREST_EMAIL_EMPTY_ERROR',
    BOOTH_REGISTER_INTEREST_EMAIL_INVALID_ERROR:
        'BOOTH_REGISTER_INTEREST_EMAIL_INVALID_ERROR',
    BOOTH_SHARED_EMAIL_MSG_1: 'BOOTH_SHARED_EMAIL_MSG_1',
    BOOTH_SHARED_EMAIL_MSG_2: 'BOOTH_SHARED_EMAIL_MSG_2',
    BOOTH_REGISTERED: 'BOOTH_REGISTERED',
    BOOTH_EDIT_BOOTH_HEADER: 'BOOTH_EDIT_BOOTH_HEADER',
    BOOTH_EDIT_BOOTH_DETAILS: 'BOOTH_EDIT_BOOTH_DETAILS',
    BOOTH_EDIT_BOOTH_LOGO: 'BOOTH_EDIT_BOOTH_LOGO',
    BOOTH_EDIT_BOOTH_DRAG_DROP: 'BOOTH_EDIT_BOOTH_DRAG_DROP',
    BOOTH_EDIT_BOOTH_LOGO_SIZE: 'BOOTH_EDIT_BOOTH_LOGO_SIZE',
    BOOTH_EDIT_BOOTH_SIZE_UPTO: 'BOOTH_EDIT_BOOTH_SIZE_UPTO',
    BOOTH_EDIT_BOOTH_BANNER_SIZE_UPTO: 'BOOTH_EDIT_BOOTH_BANNER_SIZE_UPTO',
    BOOTH_EDIT_BOOTH_BOOTH_CARD_IMG: 'BOOTH_EDIT_BOOTH_BOOTH_CARD_IMG',
    BOOTH_EDIT_BOOTH_BOOTH_CARD_IMG_DESC:
        'BOOTH_EDIT_BOOTH_BOOTH_CARD_IMG_DESC',
    BOOTH_EDIT_BOOTH_BANNER_SIZE: 'BOOTH_EDIT_BOOTH_BANNER_SIZE',
    BOOTH_EDIT_BOOTH_BOOTH_LAYOUT: 'BOOTH_EDIT_BOOTH_BOOTH_LAYOUT',
    BOOTH_EDIT_BOOTH_CARD: 'BOOTH_EDIT_BOOTH_CARD',
    BOOTH_EDIT_BOOTH_CARD_DESCRIPTION: 'BOOTH_EDIT_BOOTH_CARD_DESCRIPTION',
    BOOTH_EDIT_BOOTH_BOOTH_LAYOUT_DESC: 'BOOTH_EDIT_BOOTH_BOOTH_LAYOUT_DESC',
    BOOTH_EDIT_BOOTH_LAYOUT_TYPE_DEFAULT:
        'BOOTH_EDIT_BOOTH_LAYOUT_TYPE_DEFAULT',
    BOOTH_EDIT_BOOTH_LAYOUT_TYPE_CUSTOM: 'BOOTH_EDIT_BOOTH_LAYOUT_TYPE_CUSTOM',
    BOOTH_EDIT_BOOTH_LAYOUT_TYPE_POSTERS:
        'BOOTH_EDIT_BOOTH_LAYOUT_TYPE_POSTERS',
    BOOTH_EDIT_BOOTH_LAYOUT_TYPE_DEFAULT_DESC:
        'BOOTH_EDIT_BOOTH_LAYOUT_TYPE_DEFAULT_DESC',
    BOOTH_EDIT_BOOTH_LAYOUT_TYPE_CUSTOM_DESC:
        'BOOTH_EDIT_BOOTH_LAYOUT_TYPE_CUSTOM_DESC',
    BOOTH_EDIT_BOOTH_LAYOUT_TYPE_POSTERS_DESC:
        'BOOTH_EDIT_BOOTH_LAYOUT_TYPE_POSTERS_DESC',
    BOOTH_EDIT_BOOTH_VIDEO_TO_PLAY: 'BOOTH_EDIT_BOOTH_VIDEO_TO_PLAY',
    BOOTH_EDIT_BOOTH_VIDEO_TO_PLAY_INFO: 'BOOTH_EDIT_BOOTH_VIDEO_TO_PLAY_INFO',
    BOOTH_EDIT_BOOTH_SHORT_DESCRIPTION: 'BOOTH_EDIT_BOOTH_SHORT_DESCRIPTION',
    BOOTH_EDIT_BOOTH_SHORT_DESCRIPTION_INFO:
        'BOOTH_EDIT_BOOTH_SHORT_DESCRIPTION_INFO',
    BOOTH_EDIT_BOOTH_SHORT_DESCRIPTION_PH:
        'BOOTH_EDIT_BOOTH_SHORT_DESCRIPTION_PH',
    BOOTH_EDIT_BOOTH_FULL_DESCRIPTION: 'BOOTH_EDIT_BOOTH_FULL_DESCRIPTION',
    BOOTH_EDIT_BOOTH_FULL_DESCRIPTION_PH:
        'BOOTH_EDIT_BOOTH_FULL_DESCRIPTION_PH',
    BOOTH_EDIT_BOOTH_RESOURCES_LABEL: 'BOOTH_EDIT_BOOTH_RESOURCES_LABEL',
    BOOTH_EDIT_BOOTH_RESOURCES_DISPLAY_NAME:
        'BOOTH_EDIT_BOOTH_RESOURCES_DISPLAY_NAME',
    BOOTH_EDIT_BOOTH_RESOURCES_DISPLAY_NAME_PH:
        'BOOTH_EDIT_BOOTH_RESOURCES_DISPLAY_NAME_PH',
    BOOTH_EDIT_BOOTH_RESOURCES_LINK: 'BOOTH_EDIT_BOOTH_RESOURCES_LINK',
    BOOTH_EDIT_BOOTH_RESOURCES_LINK_PH: 'BOOTH_EDIT_BOOTH_RESOURCES_LINK_PH',
    BOOTH_EDIT_BOOTH_RESOURCES_ADD_MORE: 'BOOTH_EDIT_BOOTH_RESOURCES_ADD_MORE',
    BOOTH_EDIT_BOOTH_SOCIAL_LINKS_LABEL: 'BOOTH_EDIT_BOOTH_SOCIAL_LINKS_LABEL',
    BOOTH_EDIT_BOOTH_SOCIAL_LINKS_ENTER_WEBSITE:
        'BOOTH_EDIT_BOOTH_SOCIAL_LINKS_ENTER_WEBSITE',
    BOOTH_EDIT_BOOTH_SOCIAL_LINKS_ENTER_FACEBOOK:
        'BOOTH_EDIT_BOOTH_SOCIAL_LINKS_ENTER_FACEBOOK',
    BOOTH_EDIT_BOOTH_SOCIAL_LINKS_PLACEHOLDER:
        'BOOTH_EDIT_BOOTH_SOCIAL_LINKS_PLACEHOLDER',
    BOOTH_EDIT_BOOTH_EMPTY_BANNER: 'BOOTH_EDIT_BOOTH_EMPTY_BANNER',
    BOOTH_EDIT_BOOTH_EMPTY_LOGO: 'BOOTH_EDIT_BOOTH_EMPTY_LOGO',
    BOOTH_EDIT_BOOTH_EMPTY_DESCRIPTION: 'BOOTH_EDIT_BOOTH_EMPTY_DESCRIPTION',
    BOOTH_ABOUT_BOOTH: 'BOOTH_ABOUT_BOOTH',
    BOOTH_EDIT_BOOTH_EMPTY_RESOURCES: 'BOOTH_EDIT_BOOTH_EMPTY_RESOURCES',
    BOOTH_EDIT_BOOTH_EMPTY_LINKS: 'BOOTH_EDIT_BOOTH_EMPTY_LINKS',
    BOOTH_EDIT_BOOTH_FILE_UPLOAD_ERROR: 'BOOTH_EDIT_BOOTH_FILE_UPLOAD_ERROR',
    BOOTH_EDIT_BOOTH_FILE_UPLOAD_ERROR_DESC:
        'BOOTH_EDIT_BOOTH_FILE_UPLOAD_ERROR_DESC',
    BOOTH_EDIT_BOOTH_BANNER_FILE_UPLOAD_ERROR_DESC:
        'BOOTH_EDIT_BOOTH_BANNER_FILE_UPLOAD_ERROR_DESC',
    BOOTH_EDIT_BOOTH_SAVE_BOOTH: 'BOOTH_EDIT_BOOTH_SAVE_BOOTH',
    BOOTH_EDIT_SAVE_SUCCESS_MSG: 'BOOTH_EDIT_SAVE_SUCCESS_MSG',
    BOOTH_EDIT_SAVE_ERROR_TITLE: 'BOOTH_EDIT_SAVE_ERROR_TITLE',
    BOOTH_EDIT_SAVE_ERROR_MESSAGE: 'BOOTH_EDIT_SAVE_ERROR_MESSAGE',
    BOOTH_CUSTOM_BOOTH_CREATE_YOUR_BOOTH:
        'BOOTH_CUSTOM_BOOTH_CREATE_YOUR_BOOTH',
    BOOTH_CUSTOM_BOOTH_CREATE_YOUR_BOOTH_DESC:
        'BOOTH_CUSTOM_BOOTH_CREATE_YOUR_BOOTH_DESC',
    BOOTH_CUSTOM_BOOTH_CREATE_CUSTOM_BOOTH:
        'BOOTH_CUSTOM_BOOTH_CREATE_CUSTOM_BOOTH',
    BOOTH_CUSTOM_BOOTH_CREATE_CUSTOM_BOOTH_DESC:
        'BOOTH_CUSTOM_BOOTH_CREATE_CUSTOM_BOOTH_DESC',
    BOOTH_CUSTOM_BOOTH_BACKGROUND_IMAGE: 'BOOTH_CUSTOM_BOOTH_BACKGROUND_IMAGE',
    BOOTH_CUSTOM_BOOTH_BACKGROUND_IMAGE_DESC:
        'BOOTH_CUSTOM_BOOTH_BACKGROUND_IMAGE_DESC',
    BOOTH_CUSTOM_BOOTH_BACKGROUND_IMAGE_UPLOAD_HEADER:
        'BOOTH_CUSTOM_BOOTH_BACKGROUND_IMAGE_UPLOAD_HEADER',
    BOOTH_CUSTOM_BOOTH_BACKGROUND_IMAGE_UPLOAD_UPLOAD_IMAGE_DESC:
        'BOOTH_CUSTOM_BOOTH_BACKGROUND_IMAGE_UPLOAD_UPLOAD_IMAGE_DESC',
    BOOTH_CUSTOM_BOOTH_BACKGROUND_IMAGE_UPLOAD_SIZE_LIMIT:
        'BOOTH_CUSTOM_BOOTH_BACKGROUND_IMAGE_UPLOAD_SIZE_LIMIT',
    BOOTH_CUSTOM_BOOTH_VIDEOS_DESC: 'BOOTH_CUSTOM_BOOTH_VIDEOS_DESC',
    BOOTH_CUSTOM_BOOTH_VIDEO_UPLOAD: 'BOOTH_CUSTOM_BOOTH_VIDEO_UPLOAD',
    BOOTH_CUSTOM_BOOTH_LINKS: 'BOOTH_CUSTOM_BOOTH_LINKS',
    BOOTH_CUSTOM_BOOTH_LINKS_DESC: 'BOOTH_CUSTOM_BOOTH_LINKS_DESC',
    BOOTH_CUSTOM_BOOTH_LINKS_UPLOAD_HEADER:
        'BOOTH_CUSTOM_BOOTH_LINKS_UPLOAD_HEADER',
    BOOTH_CUSTOM_BOOTH_LINKS_UPLOAD_HEADER_DESC:
        'BOOTH_CUSTOM_BOOTH_LINKS_UPLOAD_HEADER_DESC',
    BOOTH_CUSTOM_BOOTH_LINKS_UPLOAD_LINK_NAME:
        'BOOTH_CUSTOM_BOOTH_LINKS_UPLOAD_LINK_NAME',
    BOOTH_CUSTOM_BOOTH_LINKS_UPLOAD_LINK_NAME_PH:
        'BOOTH_CUSTOM_BOOTH_LINKS_UPLOAD_LINK_NAME_PH',
    BOOTH_CUSTOM_BOOTH_LINKS_UPLOAD_EXTERNAL_URL:
        'BOOTH_CUSTOM_BOOTH_LINKS_UPLOAD_EXTERNAL_URL',
    BOOTH_CUSTOM_BOOTH_LINKS_UPLOAD_EXTERNAL_URL_PH:
        'BOOTH_CUSTOM_BOOTH_LINKS_UPLOAD_EXTERNAL_URL_PH',
    BOOTH_VISITORS: 'BOOTH_VISITORS',
    POSTER_BOOTH_ABOUT_LABEL: 'POSTER_BOOTH_ABOUT_LABEL',
    SELECT_BOOTH_HALL: 'SELECT_BOOTH_HALL',
    EMPTY_BOOTH: 'EMPTY_BOOTH',
    EMPTY_PAST_VISITOR: 'EMPTY_PAST_VISITOR',
    PREPARING_PAST_VISITOR_REPORT_HEADER:
        'PREPARING_PAST_VISITOR_REPORT_HEADER',
    PREPARING_PAST_VISITOR_REPORT_MESSAGE:
        'PREPARING_PAST_VISITOR_REPORT_MESSAGE',
    IN_BOOTH: 'IN_BOOTH',
    COPY_BOOTH_LINK: 'COPY_BOOTH_LINK',
    BOOTH_LINK_COPIED: 'BOOTH_LINK_COPIED',
    SHOW_NEW_LEADS: 'SHOW_NEW_LEADS',
    NO_LEADS_GENERATED: 'NO_LEADS_GENERATED',
    GENERAL_LEADS: 'GENERAL_LEADS',
    GENERAL_PAST_VISITORS: 'GENERAL_PAST_VISITORS',
    GENERAL_VISITORS: 'GENERAL_VISITORS',
    GENERAL_START_AT: 'GENERAL_START_AT',
    WITH_EXHIBITORS: 'WITH_EXHIBITORS',
    WITH_LIVE_BROADCASTS: 'WITH_LIVE_BROADCASTS',
    FEED: 'FEED',
    RHS_FEED_TOOLTIP: 'RHS_FEED_TOOLTIP',
    RHS_FEED_TOOLTIP2: 'RHS_FEED_TOOLTIP2',
    RHS_FEED_HEADER: 'RHS_FEED_HEADER',
    RHS_FEED_SUB_HEADER: 'RHS_FEED_SUB_HEADER',
    RHS_FEED_NO_FEED: 'RHS_FEED_NO_FEED',
    RHS_CHAT: 'RHS_CHAT',
    RHS_FEED_NO_FEED_EXT: 'RHS_FEED_NO_FEED_EXT',
    PEOPLE: 'PEOPLE',
    PERSON: 'PERSON',
    RHS_PEOPLE_TOOLTIP: 'RHS_PEOPLE_TOOLTIP',
    RHS_PEOPLE_TOOLTIP2: 'RHS_PEOPLE_TOOLTIP2',
    RHS_PEOPLE_SUB_HEADER: 'RHS_PEOPLE_SUB_HEADER',
    ATTENDEES: 'ATTENDEES',
    RHS_PEOPLE_ATTENDEES_SEARCH: 'RHS_PEOPLE_ATTENDEES_SEARCH',
    RHS_PEOPLE_ATTENDEES_VIEW_TOOLTIP: 'RHS_PEOPLE_ATTENDEES_VIEW_TOOLTIP',
    RHS_PEOPLE_ATTENDEES_GRID_VIEW: 'RHS_PEOPLE_ATTENDEES_GRID_VIEW',
    RHS_PEOPLE_ATTENDEES_LIST_VIEW: 'RHS_PEOPLE_ATTENDEES_LIST_VIEW',
    STAGE_GRID_VIEW_MESSAGE: 'STAGE_GRID_VIEW_MESSAGE',
    DIRECT_MESSAGES: 'DIRECT_MESSAGES',
    RHS_PEOPLE_DIRECT_MESSAGES_NO_MESSAGE:
        'RHS_PEOPLE_DIRECT_MESSAGES_NO_MESSAGE',
    RHS_PEOPLE_DIRECT_MESSAGES_VIEW_PROFILE:
        'RHS_PEOPLE_DIRECT_MESSAGES_VIEW_PROFILE',
    RHS_PEOPLE_DIRECT_MESSAGES_HIDE_PROFILE:
        'RHS_PEOPLE_DIRECT_MESSAGES_HIDE_PROFILE',
    RHS_PEOPLE_DIRECT_MESSAGES_BLOCK_CONVERSATION:
        'RHS_PEOPLE_DIRECT_MESSAGES_BLOCK_CONVERSATION',
    RHS_PEOPLE_DIRECT_MESSAGES_HIDE_PROFILE_INFO:
        'RHS_PEOPLE_DIRECT_MESSAGES_HIDE_PROFILE_INFO',
    RHS_PEOPLE_DIRECT_MESSAGES_EMPTY_DIRECT_CHAT_1:
        'RHS_PEOPLE_DIRECT_MESSAGES_EMPTY_DIRECT_CHAT_1',
    RHS_PEOPLE_DIRECT_MESSAGES_EMPTY_DIRECT_CHAT_2:
        'RHS_PEOPLE_DIRECT_MESSAGES_EMPTY_DIRECT_CHAT_2',
    ALERTS: 'ALERTS',
    RHS_ALERTS_TOOLTIP: 'RHS_ALERTS_TOOLTIP',
    RHS_ALERTS_TOOLTIP2: 'RHS_ALERTS_TOOLTIP2',
    RHS_ALERTS_SUB_HEADER: 'RHS_ALERTS_SUB_HEADER',
    RHS_ALERTS_NOTIFICATION_SOUND: 'RHS_ALERTS_NOTIFICATION_SOUND',
    RHS_ALERTS_MUTE_SOUND: 'RHS_ALERTS_MUTE_SOUND',
    RHS_ALERTS_EMPTY_ALERTS: 'RHS_ALERTS_EMPTY_ALERTS',
    RHS_ALERTS_EMPTY_ALERTS_EXT: 'RHS_ALERTS_EMPTY_ALERTS_EXT',
    RHS_ALERTS_ANNOUNCEMENT_HEADER: 'RHS_ALERTS_ANNOUNCEMENT_HEADER',
    RHS_ALERTS_ANNOUNCEMENT_DESC: 'RHS_ALERTS_ANNOUNCEMENT_DESC',
    RHS_ALERTS_ANNOUNCEMENT_LINK: 'RHS_ALERTS_ANNOUNCEMENT_LINK',
    RHS_ALERTS_ANNOUNCEMENT_CREATE_ANNOUNCEMENT:
        'RHS_ALERTS_ANNOUNCEMENT_CREATE_ANNOUNCEMENT',
    RHS_ALERTS_ANNOUNCEMENT_ENTER_MESSAGE:
        'RHS_ALERTS_ANNOUNCEMENT_ENTER_MESSAGE',
    RHS_ALERTS_ANNOUNCEMENT_ENTER_MESSAGE_ERROR:
        'RHS_ALERTS_ANNOUNCEMENT_ENTER_MESSAGE_ERROR',
    RHS_ALERTS_ANNOUNCEMENT_ADD_ACTION: 'RHS_ALERTS_ANNOUNCEMENT_ADD_ACTION',
    RHS_ALERTS_ANNOUNCEMENT_WHERE_TO_GO: 'RHS_ALERTS_ANNOUNCEMENT_WHERE_TO_GO',
    RHS_ALERTS_ANNOUNCEMENT_ADD_ACTION_EXTERNAL_LINK:
        'RHS_ALERTS_ANNOUNCEMENT_ADD_ACTION_EXTERNAL_LINK',
    RHS_ALERTS_ANNOUNCEMENT_ADD_ACTION_EXTERNAL_LINK_WEBSITE:
        'RHS_ALERTS_ANNOUNCEMENT_ADD_ACTION_EXTERNAL_LINK_WEBSITE',
    RHS_ALERTS_ANNOUNCEMENT_EXTERNAL_LINK_URL:
        'RHS_ALERTS_ANNOUNCEMENT_EXTERNAL_LINK_URL',
    RHS_ALERTS_ANNOUNCEMENT_EXTERNAL_LINK_ADD_A_LINK:
        'RHS_ALERTS_ANNOUNCEMENT_EXTERNAL_LINK_ADD_A_LINK',
    RHS_ALERTS_ANNOUNCEMENT_EXTERNAL_LINK_ADD_A_LINK_EMPTY:
        'RHS_ALERTS_ANNOUNCEMENT_EXTERNAL_LINK_ADD_A_LINK_EMPTY',
    RHS_ALERTS_ANNOUNCEMENT_EXTERNAL_LINK_ADD_A_LINK_ERROR:
        'RHS_ALERTS_ANNOUNCEMENT_EXTERNAL_LINK_ADD_A_LINK_ERROR',
    RHS_ALERTS_ANNOUNCEMENT_EXTERNAL_LINK_ADD_A_LINK_ERROR_IN_URL:
        'RHS_ALERTS_ANNOUNCEMENT_EXTERNAL_LINK_ADD_A_LINK_ERROR_IN_URL',
    RHS_ALERTS_ANNOUNCEMENT_SHOW_PREVIEW:
        'RHS_ALERTS_ANNOUNCEMENT_SHOW_PREVIEW',
    RHS_ALERTS_ANNOUNCEMENT_LINKS_EXTERNAL:
        'RHS_ALERTS_ANNOUNCEMENT_LINKS_EXTERNAL',
    RHS_ALERTS_ANNOUNCEMENT_LINKS_LOUNGE:
        'RHS_ALERTS_ANNOUNCEMENT_LINKS_LOUNGE',
    RHS_ALERTS_ANNOUNCEMENT_LINKS_RESOURCES:
        'RHS_ALERTS_ANNOUNCEMENT_LINKS_RESOURCES',
    RHS_ALERTS_ANNOUNCEMENT_LINKS_ARENA: 'RHS_ALERTS_ANNOUNCEMENT_LINKS_ARENA',
    RHS_ALERTS_ANNOUNCEMENT_LINKS_BOOTH: 'RHS_ALERTS_ANNOUNCEMENT_LINKS_BOOTH',
    RHS_ALERTS_ANNOUNCEMENT_LINKS_SPEED_NETWORKING:
        'RHS_ALERTS_ANNOUNCEMENT_LINKS_SPEED_NETWORKING',
    RHS_ALERT_ANNOUNCEMENT_LIVE_BROADCAST_ANNOUNCEMENT:
        'RHS_ALERT_ANNOUNCEMENT_LIVE_BROADCAST_ANNOUNCEMENT',
    MODERATION: 'MODERATION',
    RHS_MODERATION_TOOLTIP: 'RHS_MODERATION_TOOLTIP',
    RHS_MODERATION_TOOLTIP2: 'RHS_MODERATION_TOOLTIP2',
    RHS_MODERATION_SUB_HEADER: 'RHS_MODERATION_SUB_HEADER',
    REPORTS: 'REPORTS',
    RHS_MODERATION_REPORTS_ALL_REPORTS: 'RHS_MODERATION_REPORTS_ALL_REPORTS',
    RHS_MODERATION_REPORTS_REMOVE_POST: 'RHS_MODERATION_REPORTS_REMOVE_POST',
    RHS_MODERATION_REPORTS_IGNORE: 'RHS_MODERATION_REPORTS_IGNORE',
    ACTIONS_TAKEN: 'ACTIONS_TAKEN',
    RHS_MODERATION_ACTIONS_TAKEN_REMOVED_POST:
        'RHS_MODERATION_ACTIONS_TAKEN_REMOVED_POST',
    RHS_MODERATION_ACTIONS_TAKEN_BLOCKED_USER:
        'RHS_MODERATION_ACTIONS_TAKEN_BLOCKED_USER',
    RHS_MODERATION_ACTIONS_TAKEN_UNDO_REMOVE:
        'RHS_MODERATION_ACTIONS_TAKEN_UNDO_REMOVE',
    RHS_MODERATION_ACTIONS_TAKEN_UNDO_IGNORE:
        'RHS_MODERATION_ACTIONS_TAKEN_UNDO_IGNORE',
    RHS_MODERATION_ACTIONS_TAKEN_UNBLOCK_USER:
        'RHS_MODERATION_ACTIONS_TAKEN_UNBLOCK_USER',
    RHS_PROFILE_TOOLTIP2: 'RHS_PROFILE_TOOLTIP2',
    RHS_PROFILE_TOOLTIP_GUEST: 'RHS_PROFILE_TOOLTIP_GUEST',
    RHS_PROFILE_MY_PROFILE: 'RHS_PROFILE_MY_PROFILE',
    RHS_PROFILE_EXIT: 'RHS_PROFILE_EXIT',
    RHS_ACTIONS_CHAT_ACTIONS_PIN: 'RHS_ACTIONS_CHAT_ACTIONS_PIN',
    RHS_ACTIONS_CHAT_ACTIONS_REPLY: 'RHS_ACTIONS_CHAT_ACTIONS_REPLY',
    RHS_ACTIONS_CHAT_ACTIONS_STAGE_INVITE:
        'RHS_ACTIONS_CHAT_ACTIONS_STAGE_INVITE',
    RHS_ACTIONS_CHAT_ACTIONS_STAGE_INVITE_AS_SPEAKER:
        'RHS_ACTIONS_CHAT_ACTIONS_STAGE_INVITE_AS_SPEAKER',
    RHS_ACTIONS_CHAT_ACTIONS_BLOCK_USER: 'RHS_ACTIONS_CHAT_ACTIONS_BLOCK_USER',
    RHS_ACTIONS_CHAT_ACTIONS_PINNED: 'RHS_ACTIONS_CHAT_ACTIONS_PINNED',
    RHS_ACTIONS_INVITE_TO_STAGE_INVITATION_SENT:
        'RHS_ACTIONS_INVITE_TO_STAGE_INVITATION_SENT',
    RHS_ACTIONS_INVITE_TO_STAGE_AWAITING_RESPONSE:
        'RHS_ACTIONS_INVITE_TO_STAGE_AWAITING_RESPONSE',

    RHS_ACTIONS_BLOCK_USER_BLOCK: 'RHS_ACTIONS_BLOCK_USER_BLOCK',
    RHS_ACTIONS_BLOCK_USER_DESC2: 'RHS_ACTIONS_BLOCK_USER_DESC2',
    RHS_ACTIONS_UNBLOCK_USER_DESC2: 'RHS_ACTIONS_UNBLOCK_USER_DESC2',
    RHS_ACTIONS_REPORT_HEADER: 'RHS_ACTIONS_REPORT_HEADER',
    RHS_ACTIONS_REPORT_DESC: 'RHS_ACTIONS_REPORT_DESC',
    CONVERSATIONS: 'CONVERSATIONS',
    CHATS: 'CHATS',
    SESSION_RHS_CONVERSATIONS_CHATS_NO_SESSION_FEEDS:
        'SESSION_RHS_CONVERSATIONS_CHATS_NO_SESSION_FEEDS',
    SESSION_EVENT_FEED_WARNING_MESSAGE: 'SESSION_EVENT_FEED_WARNING_MESSAGE',
    SESSION_RHS_CONVERSATIONS_CHATS_EMPTY_SESSION_FEED:
        'SESSION_RHS_CONVERSATIONS_CHATS_EMPTY_SESSION_FEED',
    SESSION_RHS_CONVERSATIONS_CHATS_EMPTY_SESSION_FEED_EXT:
        'SESSION_RHS_CONVERSATIONS_CHATS_EMPTY_SESSION_FEED_EXT',
    Q_A: 'Q_A',
    SESSION_RHS_CONVERSATIONS_Q_A_EMPTY_QUESTIONS:
        'SESSION_RHS_CONVERSATIONS_Q_A_EMPTY_QUESTIONS',
    NO_COMMENTS: 'NO_COMMENTS',
    SESSION_RHS_CONVERSATIONS_Q_A_SORT_BY:
        'SESSION_RHS_CONVERSATIONS_Q_A_SORT_BY',
    SESSION_RHS_CONVERSATIONS_Q_A_UPVOTES:
        'SESSION_RHS_CONVERSATIONS_Q_A_UPVOTES',
    SESSION_RHS_CONVERSATIONS_Q_A_UPVOTE_TOOLTIP:
        'SESSION_RHS_CONVERSATIONS_Q_A_UPVOTE_TOOLTIP',
    SESSION_RHS_CONVERSATIONS_Q_A_UNDO_UPVOTE_TOOLTIP:
        'SESSION_RHS_CONVERSATIONS_Q_A_UNDO_UPVOTE_TOOLTIP',
    SESSION_RHS_CONVERSATIONS_Q_A_ANSWERED:
        'SESSION_RHS_CONVERSATIONS_Q_A_ANSWERED',
    SESSION_RHS_CONVERSATIONS_Q_A_UNANSWERED:
        'SESSION_RHS_CONVERSATIONS_Q_A_UNANSWERED',
    SESSION_RHS_CONVERSATIONS_Q_A_REMOVE_QUESTION:
        'SESSION_RHS_CONVERSATIONS_Q_A_REMOVE_QUESTION',
    SESSION_RHS_CONVERSATIONS_Q_A_MARK_ANSWERED:
        'SESSION_RHS_CONVERSATIONS_Q_A_MARK_ANSWERED',
    SESSION_RHS_CONVERSATIONS_Q_A_MARK_UNANSWERED:
        'SESSION_RHS_CONVERSATIONS_Q_A_MARK_UNANSWERED',
    SESSION_RHS_CONVERSATIONS_RAISED_HANDS_TOOLTIP:
        'SESSION_RHS_CONVERSATIONS_RAISED_HANDS_TOOLTIP',
    SESSION_RHS_CONVERSATIONS_RAISED_HANDS_STAGE_GROUP:
        'SESSION_RHS_CONVERSATIONS_RAISED_HANDS_STAGE_GROUP',
    SESSION_RHS_CONVERSATIONS_RAISED_HANDS_EMPTY_RAISED_HANDS:
        'SESSION_RHS_CONVERSATIONS_RAISED_HANDS_EMPTY_RAISED_HANDS',
    SESSION_RHS_CONVERSATIONS_RAISED_HANDS_REQUESTS:
        'SESSION_RHS_CONVERSATIONS_RAISED_HANDS_REQUESTS',
    SESSION_RHS_CONVERSATIONS_RAISED_HANDS_HAND_MIC:
        'SESSION_RHS_CONVERSATIONS_RAISED_HANDS_HAND_MIC',
    SESSION_RHS_CONVERSATIONS_RAISED_HANDS_REMOVE_FROM_STAGE:
        'SESSION_RHS_CONVERSATIONS_RAISED_HANDS_REMOVE_FROM_STAGE',
    SESSION_RHS_REMOVE_AS_SPEAKER: 'SESSION_RHS_REMOVE_AS_SPEAKER',
    SESSION_RHS_CONVERSATIONS_VIDEOS_EMPTY_VIDEOS:
        'SESSION_RHS_CONVERSATIONS_VIDEOS_EMPTY_VIDEOS',
    SESSION_RHS_CONVERSATIONS_VIDEOS_EMPTY_VIDEOS_EXT:
        'SESSION_RHS_CONVERSATIONS_VIDEOS_EMPTY_VIDEOS_EXT',
    PEOPLE_IN_SESSION: 'PEOPLE_IN_SESSION',
    SESSION_RHS_PEOPLE_IN_SESSION_ENABLED_TOOLTIP:
        'SESSION_RHS_PEOPLE_IN_SESSION_ENABLED_TOOLTIP',
    SESSION_RHS_PEOPLE_IN_SESSION_DISABLED_TOOLTIP:
        'SESSION_RHS_PEOPLE_IN_SESSION_DISABLED_TOOLTIP',
    BLOCKED_USER_NAME: 'BLOCKED_USER_NAME',
    BLOCKED_MESSAGE_TEXT: 'BLOCKED_MESSAGE_TEXT',
    BLOCKED_QUESTION_TEXT: 'BLOCKED_QUESTION_TEXT',
    BLOCKED_USER_TOAST: 'BLOCKED_USER_TOAST',
    BLOCKED_USER_TOOLTIP: 'BLOCKED_USER_TOOLTIP',
    BLOCKED_USER_MODAL_MESSAGE_1: 'BLOCKED_USER_MODAL_MESSAGE_1',
    BLOCKED_USER_MODAL_MESSAGE_2: 'BLOCKED_USER_MODAL_MESSAGE_2',
    POLL_LABEL: 'POLL_LABEL',
    POLLS: 'POLLS',
    POLLS_VOTE: 'POLLS_VOTE',
    POLLS_STATUS_PUBLISHED: 'POLLS_STATUS_PUBLISHED',
    POLLS_STATUS_DRAFTS: 'POLLS_STATUS_DRAFTS',
    POLLS_STATUS_CLOSED: 'POLLS_STATUS_CLOSED',
    POLLS_CLOSE_POLL: 'POLLS_CLOSE_POLL',
    POLL_ANSWERS: 'POLL_ANSWERS',
    POLLS_CREATE_DESC: 'POLLS_CREATE_DESC',
    POLLS_CREATE_DESC_WEBINAR: 'POLLS_CREATE_DESC_WEBINAR',
    POLLS_CREATE_LINK: 'POLLS_CREATE_LINK',
    POLLS_NO_POLLS: 'POLLS_NO_POLLS',
    POLLS_NO_POLLS_DESC: 'POLLS_NO_POLLS_DESC',
    POLLS_CREATE_POLL_HEADER: 'POLLS_CREATE_POLL_HEADER',
    POLLS_CREATE_ENTER_QUESTION: 'POLLS_CREATE_ENTER_QUESTION',
    POLLS_CREATE_ASK_QUESTION: 'POLLS_CREATE_ASK_QUESTION',
    POLLS_CREATE_ENTER_ANSWERS: 'POLLS_CREATE_ENTER_ANSWERS',
    POLLS_CREATE_ANSWER: 'POLLS_CREATE_ANSWER',
    POLLS_CREATE_ADD_ANSWERS: 'POLLS_CREATE_ADD_ANSWERS',
    POLLS_CREATE_ALLOW_ANSWERS: 'POLLS_CREATE_ALLOW_ANSWERS',
    POLLS_CREATE_SHOW_RESULTS: 'POLLS_CREATE_SHOW_RESULTS',
    POLLS_CREATE_SAVE_DRAFT: 'POLLS_CREATE_SAVE_DRAFT',
    POLLS_CREATE_SUCCESSFUL: 'POLLS_CREATE_SUCCESSFUL',
    POLLS_CREATE_SUCCESSFUL_LITE_EVENT: 'POLLS_CREATE_SUCCESSFUL_LITE_EVENT',
    POLLS_CREATE_SHOW_RESULT_AFTER_VOTE: 'POLLS_CREATE_SHOW_RESULT_AFTER_VOTE',
    POLLS_CREATE_SHOW_RESULT_AFTER_POLL_END:
        'POLLS_CREATE_SHOW_RESULT_AFTER_POLL_END',
    POLLS_CREATE_SHOW_RESULT_HIDE: 'POLLS_CREATE_SHOW_RESULT_HIDE',
    POLLS_SHOW_VOTE_COUNT: 'POLLS_SHOW_VOTE_COUNT',
    POLLS_SHOW_VOTE_COUNT_DESCRIPTION: 'POLLS_SHOW_VOTE_COUNT_DESCRIPTION',
    CHAT_BOX_MESSAGE_PLACEHOLDER: 'CHAT_BOX_MESSAGE_PLACEHOLDER',
    CHAT_BOX_BASIC_CHAT: 'CHAT_BOX_BASIC_CHAT',
    CHAT_BOX_QA_CHAT: 'CHAT_BOX_QA_CHAT',
    CHAT_BOX_FREQUENTLY_USED: 'CHAT_BOX_FREQUENTLY_USED',
    CHAT_BOX_SMILEYS_PEOPLE: 'CHAT_BOX_SMILEYS_PEOPLE',
    FEEDBACK_EXPERIENCE_QUESTION2: 'FEEDBACK_EXPERIENCE_QUESTION2',
    FEEDBACK_VERY_GOOD: 'FEEDBACK_VERY_GOOD',
    FEEDBACK_VERY_BAD: 'FEEDBACK_VERY_BAD',
    FEEDBACK_PROBLEM_QUESTION: 'FEEDBACK_PROBLEM_QUESTION',
    FEEDBACK_OPTIONS_INTERACTION: 'FEEDBACK_OPTIONS_INTERACTION',
    FEEDBACK_OPTIONS_SCREENSHARE: 'FEEDBACK_OPTIONS_SCREENSHARE',
    FEEDBACK_OPTIONS_AUDIO_VIDEO: 'FEEDBACK_OPTIONS_AUDIO_VIDEO',
    FEEDBACK_OPTIONS_COMPLEX: 'FEEDBACK_OPTIONS_COMPLEX',
    FEEDBACK_OPTIONS_OTHER: 'FEEDBACK_OPTIONS_OTHER',
    FEEDBACK_TELL_MORE: 'FEEDBACK_TELL_MORE',
    TABLE_LABEL: 'TABLE_LABEL',
    TABLE_SCREEN_START_SCREEN_SHARE: 'TABLE_SCREEN_START_SCREEN_SHARE',
    TABLE_SCREEN_SINGLE_SCREEN_SHARE: 'TABLE_SCREEN_SINGLE_SCREEN_SHARE',
    TABLE_SCREEN_STOP_SCREEN_SHARE: 'TABLE_SCREEN_STOP_SCREEN_SHARE',
    TABLE_SCREEN_SCREEN_SHARE_MESSAGE2: 'TABLE_SCREEN_SCREEN_SHARE_MESSAGE2',
    TABLE_CHANGE_LAYOUT: 'TABLE_CHANGE_LAYOUT',
    TABLE_TABLE_CHAT: 'TABLE_TABLE_CHAT',
    TABLE_RHS_CLOSE: 'TABLE_RHS_CLOSE',
    TABLE_TABLE_SETTINGS: 'TABLE_TABLE_SETTINGS',
    TABLE_EDIT_HEADER: 'TABLE_EDIT_HEADER',
    TABLE_IMAGE: 'TABLE_IMAGE',
    TABLE_SUPPORTED_IMAGE_FORMAT: 'TABLE_SUPPORTED_IMAGE_FORMAT',
    TABLE_IMAGE_SIZE_LIMIT: 'TABLE_IMAGE_SIZE_LIMIT',
    TABLE_NUMBER_OF_SEATS: 'TABLE_NUMBER_OF_SEATS',
    TABLE_SEAT_FIXED: 'TABLE_SEAT_FIXED',
    TABLE_SEAT_EXPANDING: 'TABLE_SEAT_EXPANDING',
    TABLE_SEAT_EXPANDING_MESSAGE_NEW: 'TABLE_SEAT_EXPANDING_MESSAGE_NEW',
    TABLE_SEAT_EXPANDING_MESSAGE: 'TABLE_SEAT_EXPANDING_MESSAGE',
    TABLE_SEAT_FIXED_MESSAGE: 'TABLE_SEAT_FIXED_MESSAGE',
    TABLE_JOINED_ANOTHER: 'TABLE_JOINED_ANOTHER',
    TABLE_NO_SEATS: 'TABLE_NO_SEATS',
    TABLE_REMOVE_USER: 'TABLE_REMOVE_USER',
    SPEED_NETWORKING_START_SPEED_NETWORKING:
        'SPEED_NETWORKING_START_SPEED_NETWORKING',
    SPEED_NETWORKING_ONE_ON_ONE_SPEED_NETWORKING_TITLE:
        'SPEED_NETWORKING_ONE_ON_ONE_SPEED_NETWORKING_TITLE',
    SPEED_NETWORKING_GET_MATCHED_WITH_NEW_PEOPLE:
        'SPEED_NETWORKING_GET_MATCHED_WITH_NEW_PEOPLE',
    SPEED_NETWORKING_SPEED_NETWORKING_IS:
        'SPEED_NETWORKING_SPEED_NETWORKING_IS',
    SPEED_NETWORKING_SPEED_NETWORKING_DEFAULT_MESSAGE:
        'SPEED_NETWORKING_SPEED_NETWORKING_DEFAULT_MESSAGE',
    SPEED_NETWORKING_SPEED_NETWORKING_TIME_LIMIT:
        'SPEED_NETWORKING_SPEED_NETWORKING_TIME_LIMIT',
    SPEED_NETWORKING_UNAVAILABLE: 'SPEED_NETWORKING_UNAVAILABLE',
    SPEED_NETWORKING_FINDING_MATCH: 'SPEED_NETWORKING_FINDING_MATCH',
    SPEED_NETWORKING_USER_UNAVAILABLE: 'SPEED_NETWORKING_USER_UNAVAILABLE',
    SPEED_NETWORKING_USER_DISCONNECTED: 'SPEED_NETWORKING_USER_DISCONNECTED',
    SPEED_NETWORKING_TAKING_LONGER: 'SPEED_NETWORKING_TAKING_LONGER',
    SPEED_NETWORKING_WAITING: 'SPEED_NETWORKING_WAITING',
    SPEED_NETWORKING_NO_MATCH: 'SPEED_NETWORKING_NO_MATCH',
    SPEED_NETWORKING_STOP: 'SPEED_NETWORKING_STOP',
    SPEED_NETWORKING_EXIT: 'SPEED_NETWORKING_EXIT',
    STAGE_CHANGE_LAYOUT: 'STAGE_CHANGE_LAYOUT',
    STAGE_LAYOUTS: 'STAGE_LAYOUTS',
    STAGE_LAYOUT_CHANGE_FOR_EVERYONE: 'STAGE_LAYOUT_CHANGE_FOR_EVERYONE',
    STAGE_CLOSED_CAPTIONS_STATUS: 'STAGE_CLOSED_CAPTIONS_STATUS',
    STAGE_RAISE_HAND: 'STAGE_RAISE_HAND',
    STAGE_RAISE_HAND_PROCESSING_MESSAGE: 'STAGE_RAISE_HAND_PROCESSING_MESSAGE',
    STAGE_RAISE_HAND_CONFIRM_MESSAGE: 'STAGE_RAISE_HAND_CONFIRM_MESSAGE',
    STAGE_RAISE_HAND_NEW_REQUEST_NOTIFICATION_HEADING:
        'STAGE_RAISE_HAND_NEW_REQUEST_NOTIFICATION_HEADING',
    STAGE_RAISE_HAND_NEW_REQUEST_NOTIFICATION_MESSAGE:
        'STAGE_RAISE_HAND_NEW_REQUEST_NOTIFICATION_MESSAGE',
    STAGE_RAISE_HAND_MAX_REQUEST_LIMIT_REACH_MESSAGE:
        'STAGE_RAISE_HAND_MAX_REQUEST_LIMIT_REACH_MESSAGE',
    STAGE_REACTIONS: 'STAGE_REACTIONS',
    STAGE_THUMBS_UP: 'STAGE_THUMBS_UP',
    STAGE_CLAPPING: 'STAGE_CLAPPING',
    STAGE_SMILEY: 'STAGE_SMILEY',
    STAGE_HEART: 'STAGE_HEART',
    STAGE_INSIGHTFUL: 'STAGE_INSIGHTFUL',
    STAGE_THANK_YOU: 'STAGE_THANK_YOU',
    STAGE_SAD: 'STAGE_SAD',
    STAGE_LAUGHTER: 'STAGE_LAUGHTER',
    STAGE_SURPRISE: 'STAGE_SURPRISE',
    STAGE_SWITCH_LOW_BW: 'STAGE_SWITCH_LOW_BW',
    STAGE_SWITCH_HIGH_BW: 'STAGE_SWITCH_HIGH_BW',
    STAGE_HD: 'STAGE_HD',
    STAGE_LD: 'STAGE_LD',
    STAGE_REC: 'STAGE_REC',
    STAGE_BROADCAST_WILL: 'STAGE_BROADCAST_WILL',
    STAGE_BEGIN_SHORTLY: 'STAGE_BEGIN_SHORTLY',
    STAGE_AUDIO_MODE: 'STAGE_AUDIO_MODE',
    STAGE_LITE_MODE: 'STAGE_LITE_MODE',
    STAGE_LITE_MODE_DESC2: 'STAGE_LITE_MODE_DESC2',
    STAGE_LITE_MODE_NOTE: 'STAGE_LITE_MODE_NOTE',
    STAGE_SWITCHED_TO_HD: 'STAGE_SWITCHED_TO_HD',
    STAGE_SWITCHED_TO_LD: 'STAGE_SWITCHED_TO_LD',
    STAGE_SWITCHED_TO_AUDIO_MODE: 'STAGE_SWITCHED_TO_AUDIO_MODE',
    STAGE_SWITCHED_TO_LITE_MODE: 'STAGE_SWITCHED_TO_LITE_MODE',
    STAGE_FACING_PROBLEMS: 'STAGE_FACING_PROBLEMS',
    STAGE_RAISED_HANDS_DESC: 'STAGE_RAISED_HANDS_DESC',
    STAGE_PRESENT_AUDIENCE: 'STAGE_PRESENT_AUDIENCE',
    STAGE_PRESENT_BACKSTAGE_USERS: 'STAGE_PRESENT_BACKSTAGE_USERS',
    YOUR_SCREEN_VISIBLE_TO_AUDIENCE: 'YOUR_SCREEN_VISIBLE_TO_AUDIENCE',
    YOUR_SCREEN_VISIBLE_TO_STAGE: 'YOUR_SCREEN_VISIBLE_TO_STAGE',
    YOUR_SCREEN_VISIBLE_TO_BACKSTAGE: 'YOUR_SCREEN_VISIBLE_TO_BACKSTAGE',
    STAGE_AT_BACKSTAGE: 'STAGE_AT_BACKSTAGE',
    STAGE_HIDE_YOURSELF: 'STAGE_HIDE_YOURSELF',
    STAGE_HIDE_YOURSELF_ALERT_HEADER: 'STAGE_HIDE_YOURSELF_ALERT_HEADER',
    STAGE_HIDE_YOURSELF_ALERT_SUBHEADER: 'STAGE_HIDE_YOURSELF_ALERT_SUBHEADER',
    STAGE_HIDE_YOURSELF_ALERT_CONFIRM: 'STAGE_HIDE_YOURSELF_ALERT_CONFIRM',
    STAGE_UNHIDE_YOURSELF_ALERT_HEADER: 'STAGE_UNHIDE_YOURSELF_ALERT_HEADER',
    STAGE_UNHIDE_YOURSELF_ALERT_SUBHEADER:
        'STAGE_UNHIDE_YOURSELF_ALERT_SUBHEADER',
    STAGE_UNHIDE_YOURSELF_ALERT_CONFIRM: 'STAGE_UNHIDE_YOURSELF_ALERT_CONFIRM',
    STAGE_HIDE_YOURSELF_ALERT_SUCCESS: 'STAGE_HIDE_YOURSELF_ALERT_SUCCESS',
    STAGE_SHOW_YOURSELF: 'STAGE_SHOW_YOURSELF',
    STAGE_HOST_CLOSED_CAPTIONS: 'STAGE_HOST_CLOSED_CAPTIONS',
    STAGE_MORE_OPTIONS: 'STAGE_MORE_OPTIONS',
    STAGE_BACKSTAGE_LABEL: 'STAGE_BACKSTAGE_LABEL',
    STAGE_BACKSTAGE_DESC: 'STAGE_BACKSTAGE_DESC',
    STAGE_ATTENDEE_ACCESS_ERROR: 'STAGE_ATTENDEE_ACCESS_ERROR',
    STAGE_ATTENDEE_STAGE_INVITE: 'STAGE_ATTENDEE_STAGE_INVITE',
    STAGE_ATTENDEE_STAGE_INVITE_MSG: 'STAGE_ATTENDEE_STAGE_INVITE_MSG',
    BACKSTAGE_ATTENDEE_STAGE_INVITE_MSG: 'BACKSTAGE_ATTENDEE_STAGE_INVITE_MSG',
    STAGE_ATTENDEE_REJECT: 'STAGE_ATTENDEE_REJECT',
    STAGE_ATTENDEE_ACCEPT_INVITE: 'STAGE_ATTENDEE_ACCEPT_INVITE',
    STAGE_ATTENDEE_INVITATION_DISMISS: 'STAGE_ATTENDEE_INVITATION_DISMISS',
    STAGE_MANAGE_CO_HOST_LABEL: 'STAGE_MANAGE_CO_HOST_LABEL',
    NEW_STAGE_LAYOUT_LABEL: 'NEW_STAGE_LAYOUT_LABEL',
    NEW_STAGE_LAYOUT_DESC: 'NEW_STAGE_LAYOUT_DESC',
    STAGE_MANAGE_VIDEO_SETTINGS_LABEL: 'STAGE_MANAGE_VIDEO_SETTINGS_LABEL',
    STAGE_MANAGE_CO_HOST_LIVE_TOOLTIP: 'STAGE_MANAGE_CO_HOST_LIVE_TOOLTIP',
    STAGE_MANAGE_CO_HOST_FINISHED_TOOLTIP:
        'STAGE_MANAGE_CO_HOST_FINISHED_TOOLTIP',
    STAGE_MANAGE_CO_HOST_DESC: 'STAGE_MANAGE_CO_HOST_DESC',
    STAGE_MANAGE_CO_HOST_ADD_CO_HOST: 'STAGE_MANAGE_CO_HOST_ADD_CO_HOST',
    STAGE_MANAGE_CO_HOST_TYPE_NAME_PLACEHOLDER:
        'STAGE_MANAGE_CO_HOST_TYPE_NAME_PLACEHOLDER',
    STAGE_MANAGE_CO_HOST_USER_NOT_AVAILABLE:
        'STAGE_MANAGE_CO_HOST_USER_NOT_AVAILABLE',
    STAGE_MANAGE_CO_HOST_USER_NOT_AVAILABLE_IN_SESSION:
        'STAGE_MANAGE_CO_HOST_USER_NOT_AVAILABLE_IN_SESSION',
    STAGE_MANAGE_CO_HOST_UNVERIFIED_USER_TOOLTIP:
        'STAGE_MANAGE_CO_HOST_UNVERIFIED_USER_TOOLTIP',
    STAGE_MANAGE_CO_HOST_USER_NOT_AVAILABLE_TOOLTIP:
        'STAGE_MANAGE_CO_HOST_USER_NOT_AVAILABLE_TOOLTIP',
    STAGE_MANAGE_CO_HOST_USER_NOT_AVAILABLE_IN_SESSION_TOOLTIP:
        'STAGE_MANAGE_CO_HOST_USER_NOT_AVAILABLE_IN_SESSION_TOOLTIP',
    STAGE_MANAGE_CO_HOST_SAVE_CHANGES: 'STAGE_MANAGE_CO_HOST_SAVE_CHANGES',
    STAGE_SWITCH_TO_NORMAL: 'STAGE_SWITCH_TO_NORMAL',
    GO_BACK: 'GO_BACK',
    PEOPLE_ON_STAGE: 'PEOPLE_ON_STAGE',
    GENERAL_YOU: 'GENERAL_YOU',
    GENERAL_LOWER_YOU: 'GENERAL_LOWER_YOU',
    GENERAL_AND: 'GENERAL_AND',
    GENERAL_VISIT_NOW: 'GENERAL_VISIT_NOW',
    STAGE_AUDIO_VIDEO_SETTINGS: 'STAGE_AUDIO_VIDEO_SETTINGS',
    STAGE_PRESENT_TO_AUDIENCE_HEADER_DESC:
        'STAGE_PRESENT_TO_AUDIENCE_HEADER_DESC',
    STAGE_PRESENT_TO_AUDIENCE_SELECT_PDF:
        'STAGE_PRESENT_TO_AUDIENCE_SELECT_PDF',
    STAGE_PRESENT_TO_AUDIENCE_SELECT_PDF_DESC:
        'STAGE_PRESENT_TO_AUDIENCE_SELECT_PDF_DESC',
    STAGE_PRESENT_TO_AUDIENCE_SCREEN_SHARE_DESC:
        'STAGE_PRESENT_TO_AUDIENCE_SCREEN_SHARE_DESC',
    STAGE_PRESENT_TO_AUDIENCE_PRESENTING:
        'STAGE_PRESENT_TO_AUDIENCE_PRESENTING',
    STAGE_PRESENT_TO_AUDIENCE_IS_PRESENTING:
        'STAGE_PRESENT_TO_AUDIENCE_IS_PRESENTING',
    STAGE_PRESENT_TO_AUDIENCE_PREVIEW_SLIDES:
        'STAGE_PRESENT_TO_AUDIENCE_PREVIEW_SLIDES',
    STAGE_PRESENT_TO_AUDIENCE_SCREEN_SHARE_PRESENTING_PRESENT_TEXT2:
        'STAGE_PRESENT_TO_AUDIENCE_SCREEN_SHARE_PRESENTING_PRESENT_TEXT2',
    STAGE_PRESENT_TO_AUDIENCE_SCREEN_SHARE_PRESENTING_PRESENT_TEXT_SAFARI:
        'STAGE_PRESENT_TO_AUDIENCE_SCREEN_SHARE_PRESENTING_PRESENT_TEXT_SAFARI',
    STAGE_PRESENT_TO_AUDIENCE_SCREEN_SHARE_PRESENTING_USER_PRESENT_TEXT:
        'STAGE_PRESENT_TO_AUDIENCE_SCREEN_SHARE_PRESENTING_USER_PRESENT_TEXT',
    STAGE_PRESENT_TO_AUDIENCE_SCREEN_SHARE_PRESENTING_SOMEONE_PRESENT_TEXT:
        'STAGE_PRESENT_TO_AUDIENCE_SCREEN_SHARE_PRESENTING_SOMEONE_PRESENT_TEXT',
    STAGE_PRESENT_TO_AUDIENCE_STOP_PRESENTING:
        'STAGE_PRESENT_TO_AUDIENCE_STOP_PRESENTING',
    STAGE_THE_SESSION_IS: 'STAGE_THE_SESSION_IS',
    STAGE_LIVE: 'STAGE_LIVE',
    STAGE_NOBODY_IS_ON_STAGE: 'STAGE_NOBODY_IS_ON_STAGE',
    STAGE_WAITING_YOU_CAN_MOVE_PEOPLE: 'STAGE_WAITING_YOU_CAN_MOVE_PEOPLE',
    STAGE_WAITING_FOR_HOST_TO_JOIN: 'STAGE_WAITING_FOR_HOST_TO_JOIN',
    STAGE_NO_CAPTIONS_AVAILABLE: 'STAGE_NO_CAPTIONS_AVAILABLE',
    STAGE_NO_USERS_ON_BACKSTAGE: 'STAGE_NO_USERS_ON_BACKSTAGE',
    STAGE_NO_USERS_ON_LIVESTAGE: 'STAGE_NO_USERS_ON_LIVESTAGE',
    STAGE_CANNOT_HEARD_ON_STAGE: 'STAGE_CANNOT_HEARD_ON_STAGE',
    SCREEN_SHARE_ERROR_ANOTHER_USER: 'SCREEN_SHARE_ERROR_ANOTHER_USER',
    STAGE_SESSION_HAS_BEEN_COMPLETED: 'STAGE_SESSION_HAS_BEEN_COMPLETED',
    SCREEN_SHARE_ERROR_ONE_USER: 'SCREEN_SHARE_ERROR_ONE_USER',
    SCREEN_SHARE_ERROR_VIDEO_PLAYED: 'SCREEN_SHARE_ERROR_VIDEO_PLAYED',
    SCREEN_SHARE_ERROR_BROWSER_SETTING: 'SCREEN_SHARE_ERROR_BROWSER_SETTING',
    SCREEN_SHARE_ERROR_TRY_AGAIN: 'SCREEN_SHARE_ERROR_TRY_AGAIN',
    SCREEN_SHARE_WARNING_VIDEO: 'SCREEN_SHARE_WARNING_VIDEO',
    SCREEN_SHARE_ERROR_BROWSER_NOT_ALLOWED:
        'SCREEN_SHARE_ERROR_BROWSER_NOT_ALLOWED',
    SCREEN_SHARE_LEARN_FIX: 'SCREEN_SHARE_LEARN_FIX',
    SCREEN_SHARE_ABLE_TO_SEE_VIDEO: 'SCREEN_SHARE_ABLE_TO_SEE_VIDEO',
    AUDIO_VIDEO_SETTINGS_HEADER: 'AUDIO_VIDEO_SETTINGS_HEADER',
    AUDIO_VIDEO_SETTINGS_TEST_SPEAKER_MIC:
        'AUDIO_VIDEO_SETTINGS_TEST_SPEAKER_MIC',
    AUDIO_VIDEO_SETTINGS_SETUP_CONFIRMATION_COMPLETE:
        'AUDIO_VIDEO_SETTINGS_SETUP_CONFIRMATION_COMPLETE',
    AUDIO_VIDEO_SETTINGS_SETUP_CONFIRMATION_INCOMPLETE:
        'AUDIO_VIDEO_SETTINGS_SETUP_CONFIRMATION_INCOMPLETE',
    AUDIO_VIDEO_SETTINGS_RETEST_SETUP: 'AUDIO_VIDEO_SETTINGS_RETEST_SETUP',
    AUDIO_VIDEO_SETTINGS_SPEAKER_TESTING_SPEAKER_MESSAGE:
        'AUDIO_VIDEO_SETTINGS_SPEAKER_TESTING_SPEAKER_MESSAGE',
    AUDIO_VIDEO_SETTINGS_SPEAKER_NO_SPEAKERS:
        'AUDIO_VIDEO_SETTINGS_SPEAKER_NO_SPEAKERS',
    AUDIO_VIDEO_SETTINGS_SPEAKER_DO_HEAR_RINGTONE:
        'AUDIO_VIDEO_SETTINGS_SPEAKER_DO_HEAR_RINGTONE',
    AUDIO_VIDEO_SETTINGS_SPEAKER_LETS_TEST_SPEAKER:
        'AUDIO_VIDEO_SETTINGS_SPEAKER_LETS_TEST_SPEAKER',
    AUDIO_VIDEO_SETTINGS_SPEAKER_PLAY_RINGTONE:
        'AUDIO_VIDEO_SETTINGS_SPEAKER_PLAY_RINGTONE',
    AUDIO_VIDEO_SETTINGS_SPEAKER_PLAYING:
        'AUDIO_VIDEO_SETTINGS_SPEAKER_PLAYING',
    AUDIO_VIDEO_SETTINGS_MIC_LABEL: 'AUDIO_VIDEO_SETTINGS_MIC_LABEL',
    AUDIO_VIDEO_SETTINGS_MIC_MICROPHONE: 'AUDIO_VIDEO_SETTINGS_MIC_MICROPHONE',
    AUDIO_VIDEO_SETTINGS_MIC_TESTING_MICROPHONE_MESSAGE:
        'AUDIO_VIDEO_SETTINGS_MIC_TESTING_MICROPHONE_MESSAGE',
    AUDIO_VIDEO_SETTINGS_MIC_NO_MICROPHONES:
        'AUDIO_VIDEO_SETTINGS_MIC_NO_MICROPHONES',
    AUDIO_VIDEO_SETTINGS_MIC_TO_TEST_MIC:
        'AUDIO_VIDEO_SETTINGS_MIC_TO_TEST_MIC',
    AUDIO_VIDEO_SETTINGS_MIC_MIC_INPUT_LEVEL:
        'AUDIO_VIDEO_SETTINGS_MIC_MIC_INPUT_LEVEL',
    AUDIO_VIDEO_SETTINGS_MIC_CHANGE_MIC: 'AUDIO_VIDEO_SETTINGS_MIC_CHANGE_MIC',
    AUDIO_VIDEO_SETTINGS_SUCCESS: 'AUDIO_VIDEO_SETTINGS_SUCCESS',
    TOASTS_DISCONNECTED: 'TOASTS_DISCONNECTED',
    TOASTS_RECONNECT: 'TOASTS_RECONNECT',
    GENERAL_ROLES_HOST: 'GENERAL_ROLES_HOST',
    HOST: 'HOST',
    GENERAL_ROLES_ORGANIZER: 'GENERAL_ROLES_ORGANIZER',
    ORGANIZER: 'ORGANIZER',
    SPEAKER: 'SPEAKER',
    GENERAL_ROLES_UPPER_SPEAKER: 'GENERAL_ROLES_UPPER_SPEAKER',
    GENERAL_ROLES_EXHIBITOR: 'GENERAL_ROLES_EXHIBITOR',
    EXHIBITOR: 'EXHIBITOR',
    GENERAL_ROLES_ATTENDEE: 'GENERAL_ROLES_ATTENDEE',
    ATTENDEE: 'ATTENDEE',
    GENERAL_ROLES_COHOST: 'GENERAL_ROLES_COHOST',
    CO_HOST: 'CO_HOST',
    GENERAL_SESSION_STATUS_LIVE: 'GENERAL_SESSION_STATUS_LIVE',
    GENERAL_SESSION_STATUS_PAUSED: 'GENERAL_SESSION_STATUS_PAUSED',
    GENERAL_SESSION_STATUS_ONGOING: 'GENERAL_SESSION_STATUS_ONGOING',
    GENERAL_SESSION_STATUS_UPCOMING: 'GENERAL_SESSION_STATUS_UPCOMING',
    GENERAL_REPORT: 'GENERAL_REPORT',
    GENERAL_PUBLISH: 'GENERAL_PUBLISH',
    GENERAL_HELP: 'GENERAL_HELP',
    GENERAL_DIRECT_MESSAGE: 'GENERAL_DIRECT_MESSAGE',
    GENERAL_MARK_AS_ANSWER: 'GENERAL_MARK_AS_ANSWER',
    GENERAL_SUBMIT: 'GENERAL_SUBMIT',
    GENERAL_THANKS_YOU: 'GENERAL_THANKS_YOU',
    GENERAL_PROCEED: 'GENERAL_PROCEED',
    GENERAL_CANCEL: 'GENERAL_CANCEL',
    GENERAL_SAVE: 'GENERAL_SAVE',
    GENERAL_MESSAGE: 'GENERAL_MESSAGE',
    GENERAL_SEND: 'GENERAL_SEND',
    GENERAL_SUGGESTIONS: 'GENERAL_SUGGESTIONS',
    GENERAL_ALL: 'GENERAL_ALL',
    GENERAL_VIEW: 'GENERAL_VIEW',
    GENERAL_LATEST: 'GENERAL_LATEST',
    GENERAL_REFRESH: 'GENERAL_REFRESH',
    GENERAL_SHOW: 'GENERAL_SHOW',
    GENERAL_FROM: 'GENERAL_FROM',
    GENERAL_OK: 'GENERAL_OK',
    GENERAL_OKAY: 'GENERAL_OKAY',
    GENERAL_YES: 'GENERAL_YES',
    GENERAL_NO: 'GENERAL_NO',
    GENERAL_CONTINUE: 'GENERAL_CONTINUE',
    GENERAL_DESCRIPTION: 'GENERAL_DESCRIPTION',
    GENERAL_CUSTOM: 'GENERAL_CUSTOM',
    GENERAL_CONNECTING: 'GENERAL_CONNECTING',
    GENERAL_CAMERA: 'GENERAL_CAMERA',
    GENERAL_DONE: 'GENERAL_DONE',
    GENERAL_MUTED_MESSAGE: 'GENERAL_MUTED_MESSAGE',
    GENERAL_NEW_MUTED_MESSAGE: 'GENERAL_NEW_MUTED_MESSAGE',
    GENERAL_FULL_SCREEN: 'GENERAL_FULL_SCREEN',
    GENERAL_EXIT_FULL_SCREEN: 'GENERAL_EXIT_FULL_SCREEN',
    GENERAL_CHARACTERS_LIMIT: 'GENERAL_CHARACTERS_LIMIT',
    GENERAL_SCREEN_SHARE_BY: 'GENERAL_SCREEN_SHARE_BY',
    GENERAL_TITLE: 'GENERAL_TITLE',
    GENERAL_LEAVE: 'GENERAL_LEAVE',
    GENERAL_UPLOAD_IMAGE: 'GENERAL_UPLOAD_IMAGE',
    GENERAL_JOIN: 'GENERAL_JOIN',
    GENERAL_JOINING: 'GENERAL_JOINING',
    GENERAL_JOIN_ROOM: 'GENERAL_JOIN_ROOM',
    GENERAL_MOVE_HERE: 'GENERAL_MOVE_HERE',
    GENERAL_LOADING: 'GENERAL_LOADING',
    GENERAL_UNBLOCK: 'GENERAL_UNBLOCK',
    GENERAL_DOWNLOAD: 'GENERAL_DOWNLOAD',
    GENERAL_PREVIEW: 'GENERAL_PREVIEW',
    GENERAL_EXIT_PREVIEW: 'GENERAL_EXIT_PREVIEW',
    GENERAL_OPEN_LINK: 'GENERAL_OPEN_LINK',
    VIDEOS: 'VIDEOS',
    RAISED_HANDS: 'RAISED_HANDS',
    GENERAL_SOMETHING_WENT_WRONG: 'GENERAL_SOMETHING_WENT_WRONG',
    GENERAL_STARTING_IN: 'GENERAL_STARTING_IN',
    GENERAL_ENDING_IN: 'GENERAL_ENDING_IN',
    GENERAL_POSTED: 'GENERAL_POSTED',
    GENERAL_DATE_AND_TIME: 'GENERAL_DATE_AND_TIME',
    GENERAL_DATE: 'GENERAL_DATE',
    GENERAL_DAY_AGO: 'GENERAL_DAY_AGO',
    GENERAL_DAYS_AGO: 'GENERAL_DAYS_AGO',
    GENERAL_HOUR_AGO: 'GENERAL_HOUR_AGO',
    GENERAL_HOURS_AGO: 'GENERAL_HOURS_AGO',
    GENERAL_HOUR: 'GENERAL_HOUR',
    GENERAL_HOURS: 'GENERAL_HOURS',
    GENERAL_JUST_NOW: 'GENERAL_JUST_NOW',
    GENERAL_MIN_AGO: 'GENERAL_MIN_AGO',
    GENERAL_MINS_AGO: 'GENERAL_MINS_AGO',
    GENERAL_TRY_AGAIN: 'GENERAL_TRY_AGAIN',
    GENERAL_REMOVE: 'GENERAL_REMOVE',
    GENERAL_APPLY_CHANGES: 'GENERAL_APPLY_CHANGES',
    GENERAL_UPLOAD: 'GENERAL_UPLOAD',
    GENERAL_ADD: 'GENERAL_ADD',
    GENERAL_VIEW_ALL: 'GENERAL_VIEW_ALL',
    GENERAL_NOW: 'GENERAL_NOW',
    GENERAL_READ_MORE: 'GENERAL_READ_MORE',
    GENERAL_READ_LESS: 'GENERAL_READ_LESS',
    GENERAL_OTHERS: 'GENERAL_OTHERS',
    GENERAL_OTHER: 'GENERAL_OTHER',
    GENERAL_LOGIN: 'GENERAL_LOGIN',
    GENERAL_SCROLL_TOP: 'GENERAL_SCROLL_TOP',
    GENERAL_ACCESS_DENIED: 'GENERAL_ACCESS_DENIED',
    GENERAL_VERIFY: 'GENERAL_VERIFY',
    GENERAL_ZOOM: 'GENERAL_ZOOM',
    GENERAL_OH_NO: 'GENERAL_OH_NO',
    GENERAL_PROCESSING: 'GENERAL_PROCESSING',
    GENERAL_ASSIGNED: 'GENERAL_ASSIGNED',
    GENERAL_EVENTS: 'GENERAL_EVENTS',
    BOOTH_ENTER_CONTACT_INFO: 'BOOTH_ENTER_CONTACT_INFO',
    BOOTH_EDIT_BOOTH_BROWSE: 'BOOTH_EDIT_BOOTH_BROWSE',
    BOOTH_EDIT_CUSTOM_BOOTH: 'BOOTH_EDIT_CUSTOM_BOOTH',
    BOOTH_CUSTOM_BOOTH_VIDEO_UPLOADING: 'BOOTH_CUSTOM_BOOTH_VIDEO_UPLOADING',
    BOOTH_CUSTOM_BOOTH_VIDEO_PROCESSING: 'BOOTH_CUSTOM_BOOTH_VIDEO_PROCESSING',
    BOOTH_CUSTOM_BOOTH_VIDEO_UPLOADED: 'BOOTH_CUSTOM_BOOTH_VIDEO_UPLOADED',
    BOOTH_CUSTOM_BOOTH_DESTINATION: 'BOOTH_CUSTOM_BOOTH_DESTINATION',
    BOOTH_CUSTOM_BOOTH_SELECT_DESTINATION:
        'BOOTH_CUSTOM_BOOTH_SELECT_DESTINATION',
    BOOTH_CUSTOM_BOOTH_CREATE_YOUR_RECEPTION:
        'BOOTH_CUSTOM_BOOTH_CREATE_YOUR_RECEPTION',
    BOOTH_CUSTOM_BOOTH_CREATE_CUSTOM_RECEPTION_DESC:
        'BOOTH_CUSTOM_BOOTH_CREATE_CUSTOM_RECEPTION_DESC',
    SESSION_REMOVE_SCHEDULE: 'SESSION_REMOVE_SCHEDULE',
    SESSION_SESSION_START: 'SESSION_SESSION_START',
    SESSION_PAUSE_SESSIONS_QUESTION: 'SESSION_PAUSE_SESSIONS_QUESTION',
    SESSION_STOP_STREAMING: 'SESSION_STOP_STREAMING',
    SESSION_PAUSE_STREAMING: 'SESSION_PAUSE_STREAMING',
    POLLS_CREATE_SAVE_DRAFT_SUCCESS: 'POLLS_CREATE_SAVE_DRAFT_SUCCESS',
    GENERAL_OFF: 'GENERAL_OFF',
    GENERAL_ON: 'GENERAL_ON',
    GENERAL_CONFIRM: 'GENERAL_CONFIRM',
    GENERAL_ALERT: 'GENERAL_ALERT',
    GENERAL_GUEST_USER: 'GENERAL_GUEST_USER',
    STAGE_LIVE_SESSION_RECORDING_ON: 'STAGE_LIVE_SESSION_RECORDING_ON',
    STAGE_LIVE_SESSION_RECORDING_OFF: 'STAGE_LIVE_SESSION_RECORDING_OFF',
    STAGE_COHOST_CLOSED_CAPTIONS: 'STAGE_COHOST_CLOSED_CAPTIONS',
    STAGE_LEAVE_THE_STAGE: 'STAGE_LEAVE_THE_STAGE',
    STAGE_MANAGE_CO_HOST_DATA_ERROR: 'STAGE_MANAGE_CO_HOST_DATA_ERROR',
    STAGE_MANAGE_CO_HOST_LIST_FULL: 'STAGE_MANAGE_CO_HOST_LIST_FULL',
    STAGE_MANAGE_CO_HOST_LIST_EMPTY: 'STAGE_MANAGE_CO_HOST_LIST_EMPTY',
    EDIT_VIDEO_HEADLINE: 'EDIT_VIDEO_HEADLINE',
    VIDEO_HEADLINE_UPDATED: 'VIDEO_HEADLINE_UPDATED',
    VIDEO_HEADLINE_UPDATE_ERROR: 'VIDEO_HEADLINE_UPDATE_ERROR',
    VIEW_PROFILE: 'VIEW_PROFILE',
    VIDEO_HEADLINE: 'VIDEO_HEADLINE',
    ENTER_VIDEO_HEADLINE: 'ENTER_VIDEO_HEADLINE',
    VIDEO_HEADLINE_TO_DISPLAYED: 'VIDEO_HEADLINE_TO_DISPLAYED',
    VIDEO_HEADLINE_DESC_UPDATE_PROFILE: 'VIDEO_HEADLINE_DESC_UPDATE_PROFILE',
    VIDEO_HEADLINE_DESC: 'VIDEO_HEADLINE_DESC',
    KEEP_THE_TITLE_LENGTH_MINIMUM: 'KEEP_THE_TITLE_LENGTH_MINIMUM',
    STAGE_PRESENT_TO_AUDIENCE_HIDE_PREVIEW:
        'STAGE_PRESENT_TO_AUDIENCE_HIDE_PREVIEW',
    AUDIO_VIDEO_SETTINGS_MIC_NO_MICROPHONES_TEST:
        'AUDIO_VIDEO_SETTINGS_MIC_NO_MICROPHONES_TEST',
    AUDIO_VIDEO_SETTINGS_MIC_SELECT_MICROPHONE:
        'AUDIO_VIDEO_SETTINGS_MIC_SELECT_MICROPHONE',
    AUDIO_VIDEO_SETTINGS_MIC_MUSIC_MODE: 'AUDIO_VIDEO_SETTINGS_MIC_MUSIC_MODE',
    AUDIO_VIDEO_SETTINGS_MIC_MUSIC_MODE_HINT:
        'AUDIO_VIDEO_SETTINGS_MIC_MUSIC_MODE_HINT',
    AUDIO_VIDEO_SETTINGS_MIC_NOISE_CANCELLATION_MODE:
        'AUDIO_VIDEO_SETTINGS_MIC_NOISE_CANCELLATION_MODE',
    AUDIO_VIDEO_SETTINGS_MIC_NOISE_CANCELLATION_MODE_HINT:
        'AUDIO_VIDEO_SETTINGS_MIC_NOISE_CANCELLATION_MODE_HINT',
    AUDIO_VIDEO_SETTINGS_MIC_SELECT_CAMERA:
        'AUDIO_VIDEO_SETTINGS_MIC_SELECT_CAMERA',
    AUDIO_VIDEO_SETTINGS_SPEAKER: 'AUDIO_VIDEO_SETTINGS_SPEAKER',
    SETUP_PREVIEW_CONFIG_TEXT: 'SETUP_PREVIEW_CONFIG_TEXT',
    SETUP_PREVIEW_CONFIG_CAMERA_ACCESS_BLOCKED:
        'SETUP_PREVIEW_CONFIG_CAMERA_ACCESS_BLOCKED',
    SETUP_PREVIEW_CONFIG_CAMERA_ACCESS_STEPS:
        'SETUP_PREVIEW_CONFIG_CAMERA_ACCESS_STEPS',
    SETUP_PREVIEW_CONFIG_UNABLE_TO_ACCESS_CAMERA:
        'SETUP_PREVIEW_CONFIG_UNABLE_TO_ACCESS_CAMERA',
    SETUP_PREVIEW_ENTER_VENUE: 'SETUP_PREVIEW_ENTER_VENUE',
    SCREEN_SHARE_RECORDED_VIDEO_MESSAGE: 'SCREEN_SHARE_RECORDED_VIDEO_MESSAGE',
    SCREEN_SHARE_CAMERA_ACCESS: 'SCREEN_SHARE_CAMERA_ACCESS',
    SCREEN_SHARE_DURING_CAMERA_PREVIEW: 'SCREEN_SHARE_DURING_CAMERA_PREVIEW',
    MEDIA_DEVICE_NO_DEVICES: 'MEDIA_DEVICE_NO_DEVICES',
    STAGE_MANAGE_CO_HOST_REMOVE_CO_HOST: 'STAGE_MANAGE_CO_HOST_REMOVE_CO_HOST',
    LITE_MODE_RESOLVE_ISSUES: 'LITE_MODE_RESOLVE_ISSUES',
    LITE_MODE_REFRESH_YOUR_BROWSER: 'LITE_MODE_REFRESH_YOUR_BROWSER',
    LITE_MODE_CHECK_SYSTEM_SOUND: 'LITE_MODE_CHECK_SYSTEM_SOUND',
    LITE_MODE_SWITCH_TO_CHROME: 'LITE_MODE_SWITCH_TO_CHROME',
    LITE_MODE_LOW_BANDWIDTH_MODE: 'LITE_MODE_LOW_BANDWIDTH_MODE',
    LITE_MODE_ABOUT: 'LITE_MODE_ABOUT',
    LITE_MODE_DESC2: 'LITE_MODE_DESC2',
    LITE_MODE_DESC_NOTE: 'LITE_MODE_DESC_NOTE',
    LITE_MODE_SWITCH: 'LITE_MODE_SWITCH',
    LITE_MODE_KEYWORD_YOU_HAVE_A: 'LITE_MODE_KEYWORD_YOU_HAVE_A',
    LITE_MODE_KEYWORD_RAISE_HAND: 'LITE_MODE_KEYWORD_RAISE_HAND',
    RHS_ALERTS_ANNOUNCEMENT: 'RHS_ALERTS_ANNOUNCEMENT',
    RHS_ALERTS_ANNOUNCEMENT_CLEARED_DESC2:
        'RHS_ALERTS_ANNOUNCEMENT_CLEARED_DESC2',
    GENERAL_NO_RESULTS_FOUND: 'GENERAL_NO_RESULTS_FOUND',
    GENERAL_NO_RESULTS: 'GENERAL_NO_RESULTS',
    STAGE_FAILED_JOIN: 'STAGE_FAILED_JOIN',
    BACKSTAGE_FAILED_JOIN: 'BACKSTAGE_FAILED_JOIN',
    STAGE_FAILED_JOIN_MSG: 'STAGE_FAILED_JOIN_MSG',
    SESSION_RHS_CONVERSATIONS_Q_A_EMPTY_QUESTIONS_HOSTS:
        'SESSION_RHS_CONVERSATIONS_Q_A_EMPTY_QUESTIONS_HOSTS',
    SESSION_RHS_CONVERSATIONS_VIDEOS_SELECT:
        'SESSION_RHS_CONVERSATIONS_VIDEOS_SELECT',
    SESSION_RHS_CONVERSATIONS_NO_VIDEO_HEADING:
        'SESSION_RHS_CONVERSATIONS_NO_VIDEO_HEADING',
    SESSION_RHS_CONVERSATIONS_NO_VIDEO_DESC:
        'SESSION_RHS_CONVERSATIONS_NO_VIDEO_DESC',
    SESSION_RHS_CONVERSATIONS_NOT_FOUND_VIDEO_HEADING:
        'SESSION_RHS_CONVERSATIONS_NOT_FOUND_VIDEO_HEADING',
    SESSION_RHS_CONVERSATIONS_NOT_FOUND_VIDEO_DESC:
        'SESSION_RHS_CONVERSATIONS_NOT_FOUND_VIDEO_DESC',
    SESSION_RHS_CONVERSATIONS_VIDEOS_TO_PLAY:
        'SESSION_RHS_CONVERSATIONS_VIDEOS_TO_PLAY',
    SESSION_RHS_CONVERSATIONS_VIDEOS_DISABLED_HEADING:
        'SESSION_RHS_CONVERSATIONS_VIDEOS_DISABLED_HEADING',
    SESSION_RHS_CONVERSATIONS_VIDEOS_DISABLED_DESC:
        'SESSION_RHS_CONVERSATIONS_VIDEOS_DISABLED_DESC',
    GENERAL_SORTED_BY: 'GENERAL_SORTED_BY',
    RECEPTION_EDIT_WELCOME_ERROR: 'RECEPTION_EDIT_WELCOME_ERROR',
    SPEED_NETWORKING: 'SPEED_NETWORKING',
    SESSIONS_SEARCH_SHOWING: 'SESSIONS_SEARCH_SHOWING',
    SESSIONS_SEARCH_TAGS: 'SESSIONS_SEARCH_TAGS',
    SESSIONS_SEARCH_SINGLE_TAG: 'SESSIONS_SEARCH_SINGLE_TAG',
    GENERAL_RESET_ALL: 'GENERAL_RESET_ALL',
    LANGUAGE: 'LANGUAGE',
    LANGUAGE_MODAL_HEADER: 'LANGUAGE_MODAL_HEADER',
    LANGUAGE_MODAL_DESC2: 'LANGUAGE_MODAL_DESC2',
    RHS_PEOPLE_DIRECT_MESSAGES_EMPTY_1: 'RHS_PEOPLE_DIRECT_MESSAGES_EMPTY_1',
    RHS_PEOPLE_DIRECT_MESSAGES_EMPTY_2: 'RHS_PEOPLE_DIRECT_MESSAGES_EMPTY_2',
    RHS_TAB_CHAT_SUBTITLE: 'RHS_TAB_CHAT_SUBTITLE',
    RHS_TAB_CHAT_SUBTITLE_WEBINAR: 'RHS_TAB_CHAT_SUBTITLE_WEBINAR',
    RHS_TAB_QNA_SUBTITLE: 'RHS_TAB_QNA_SUBTITLE',
    RHS_TAB_POLLS_SUBTITLE: 'RHS_TAB_POLLS_SUBTITLE',
    RHS_TAB_PEOPLE_SUBTITLE: 'RHS_TAB_PEOPLE_SUBTITLE',
    STAGE_STREAM_LIMIT: 'STAGE_STREAM_LIMIT',
    STAGE_STREAM_LIMIT_DESC: 'STAGE_STREAM_LIMIT_DESC',
    TABLE_LEAVE: 'TABLE_LEAVE',
    TABLE_SETTING_UP: 'TABLE_SETTING_UP',
    BREAKOUT_ROOM_SETTING_UP: 'BREAKOUT_ROOM_SETTING_UP',
    GENERAL_REJOIN_MEETING: 'GENERAL_REJOIN_MEETING',
    GENERAL_SKIP: 'GENERAL_SKIP',
    SPEED_NETWORKING_SKIP_MATCH: 'SPEED_NETWORKING_SKIP_MATCH',
    GENERAL_CHAT: 'GENERAL_CHAT',
    GENERAL_END: 'GENERAL_END',
    RHS_MODERATION_ACTIONS_TAKEN_BLOCKED_THE_USER:
        'RHS_MODERATION_ACTIONS_TAKEN_BLOCKED_THE_USER',
    GENERAL_LOWER_IGNORED: 'GENERAL_LOWER_IGNORED',
    GENERAL_LOWER_REMOVED: 'GENERAL_LOWER_REMOVED',
    GENERAL_LOWER_QUESTION: 'GENERAL_LOWER_QUESTION',
    GENERAL_LOWER_POST: 'GENERAL_LOWER_POST',
    GENERAL_QUESTION: 'GENERAL_QUESTION',
    GENERAL_POST: 'GENERAL_POST',
    GENERAL_CHANGE: 'GENERAL_CHANGE',
    RHS_MODERATION_ACTIONS_TAKEN_REPORTED:
        'RHS_MODERATION_ACTIONS_TAKEN_REPORTED',
    GENERAL_MINIMUM: 'GENERAL_MINIMUM',
    GENERAL_MAXIMUM: 'GENERAL_MAXIMUM',
    GENERAL_LOWER_SEATS: 'GENERAL_LOWER_SEATS',
    GENERAL_LOWER_VALUE: 'GENERAL_LOWER_VALUE',
    TABLE_EDIT_NUMBER_ERROR: 'TABLE_EDIT_NUMBER_ERROR',
    TABLE_CHAT_BOX_PH: 'TABLE_CHAT_BOX_PH',
    GENERAL_OF_CHARACTERS: 'GENERAL_OF_CHARACTERS',
    STAGE_USER_LEFT: 'STAGE_USER_LEFT',
    STAGE_REMOVE_SPEAKER_QUESTION_1: 'STAGE_REMOVE_SPEAKER_QUESTION_1',
    REMOVE_SPEAKER_QUESTION_1: 'REMOVE_SPEAKER_QUESTION_1',
    STAGE_REMOVE_SPEAKER_QUESTION_2: 'STAGE_REMOVE_SPEAKER_QUESTION_2',
    STAGE_REMOVE_SPEAKER_QUESTION_3: 'STAGE_REMOVE_SPEAKER_QUESTION_3',
    STAGE_DELETE_SPEAKER_1: 'STAGE_DELETE_SPEAKER_1',
    STAGE_DELETE_SPEAKER_2: 'STAGE_DELETE_SPEAKER_2',
    GENERAL_REMOVE_SPEAKER: 'GENERAL_REMOVE_SPEAKER',
    STAGE_SPEAKER_REMOVED_SUCCESS: 'STAGE_SPEAKER_REMOVED_SUCCESS',
    TOASTS_RAISED_HANDS_ATTENDEE_ALREADY:
        'TOASTS_RAISED_HANDS_ATTENDEE_ALREADY',
    TOASTS_ERROR_ATTENDEE_INVITE: 'TOASTS_ERROR_ATTENDEE_INVITE',
    TOASTS_USER_ALREADY_INVITED_1: 'TOASTS_USER_ALREADY_INVITED_1',
    TOASTS_USER_ALREADY_INVITED_2: 'TOASTS_USER_ALREADY_INVITED_2',
    TOASTS_FACED_TECH_ISSUE: 'TOASTS_FACED_TECH_ISSUE',
    LOUNGE_CONFIGURE_SOCIAL_LOUNGE_HEADER:
        'LOUNGE_CONFIGURE_SOCIAL_LOUNGE_HEADER',
    LOUNGE_CONFIGURE_TITLE_SUGGESTION_LABEL:
        'LOUNGE_CONFIGURE_TITLE_SUGGESTION_LABEL',
    LOUNGE_CONFIGURE_TITLE_SUGGESTION_1: 'LOUNGE_CONFIGURE_TITLE_SUGGESTION_1',
    LOUNGE_CONFIGURE_TITLE_SUGGESTION_2: 'LOUNGE_CONFIGURE_TITLE_SUGGESTION_2',
    GENERAL_ERROR_VALID_TITLE: 'GENERAL_ERROR_VALID_TITLE',
    GENERAL_ERROR_VALID_SUBTITLE: 'GENERAL_ERROR_VALID_SUBTITLE',
    GENERAL_MINUTES: 'GENERAL_MINUTES',
    GENERAL_MINUTE: 'GENERAL_MINUTE',
    GENERAL_MINS: 'GENERAL_MINS',
    SPEED_NETWORKING_END_PROMPT_QUESTION:
        'SPEED_NETWORKING_END_PROMPT_QUESTION',
    SPEED_NETWORKING_END_PROMPT_GO_NEXT: 'SPEED_NETWORKING_END_PROMPT_GO_NEXT',
    SPEED_NETWORKING_END_PROMPT_END_NETWORK:
        'SPEED_NETWORKING_END_PROMPT_END_NETWORK',
    SPEED_NETWORKING_FEEDBACK_QUESTION: 'SPEED_NETWORKING_FEEDBACK_QUESTION',
    SPEED_NETWORKING_FEEDBACK_THANK_YOU: 'SPEED_NETWORKING_FEEDBACK_THANK_YOU',
    POLLS_EDIT_POLL: 'POLLS_EDIT_POLL',
    SESSIONS_NO_ACCESS: 'SESSIONS_NO_ACCESS',
    SESSIONS_NOT_LIVE_YET: 'SESSIONS_NOT_LIVE_YET',
    SESSIONS_NOT_LIVE_YET_EXT: 'SESSIONS_NOT_LIVE_YET_EXT',
    SESSION: 'SESSION',
    STAGE_INVITE_SPEAKER_HEADER: 'STAGE_INVITE_SPEAKER_HEADER',
    STAGE_INVITE_SPEAKER_MESSAGE: 'STAGE_INVITE_SPEAKER_MESSAGE',
    GENERAL_JOIN_NOW: 'GENERAL_JOIN_NOW',
    GENERAL_DONT_JOIN: 'GENERAL_DONT_JOIN',
    GENERAL_JOIN_LATER: 'GENERAL_JOIN_LATER',
    STAGE_JOIN: 'STAGE_JOIN',
    STAGE_JOIN_BACKSTAGE: 'STAGE_JOIN_BACKSTAGE',
    STAGE_INVITATION_REJECTED_1: 'STAGE_INVITATION_REJECTED_1',
    STAGE_INVITATION_REJECTED_2: 'STAGE_INVITATION_REJECTED_2',
    STAGE_INVITE_BY_HOST: 'STAGE_INVITE_BY_HOST',
    TOASTS_USER_ALREADY_SPEAKER: 'TOASTS_USER_ALREADY_SPEAKER',
    TOASTS_USER_LEFT_INVITE_DISMISSED: 'TOASTS_USER_LEFT_INVITE_DISMISSED',
    TOASTS_SESSION_STATUS_CHANGE_INVITE_DISMISSED:
        'TOASTS_SESSION_STATUS_CHANGE_INVITE_DISMISSED',
    TOAST_FAILED_INVITE_TO_STAGE_AS_STAGE_SPEAKER_LIMIT_REACHED:
        'TOAST_FAILED_INVITE_TO_STAGE_AS_STAGE_SPEAKER_LIMIT_REACHED',
    RHS_ACTIONS_INVITE_TO_STAGE_REJECTED:
        'RHS_ACTIONS_INVITE_TO_STAGE_REJECTED',
    RHS_ACTIONS_INVITE_TO_STAGE_ACCEPTED:
        'RHS_ACTIONS_INVITE_TO_STAGE_ACCEPTED',
    RHS_ACTIONS_INVITE_TO_STAGE_JOINING: 'RHS_ACTIONS_INVITE_TO_STAGE_JOINING',
    GENERAL_RESEND: 'GENERAL_RESEND',
    GENERAL_CANCEL_REQUEST: 'GENERAL_CANCEL_REQUEST',
    TOASTS_THANK_ACCEPTING_INVITE: 'TOASTS_THANK_ACCEPTING_INVITE',
    STAGE_INVITATION_BY_HOST: 'STAGE_INVITATION_BY_HOST',
    STAGE_JOIN_SUCCESS: 'STAGE_JOIN_SUCCESS',
    BACKSTAGE_JOIN_SUCCESS: 'BACKSTAGE_JOIN_SUCCESS',
    TOAST_NO_LONGER_SPEAKER: 'TOAST_NO_LONGER_SPEAKER',
    TOAST_DIS_ALLOW_INVITE_TO_STAGE: 'TOAST_DIS_ALLOW_INVITE_TO_STAGE',
    TOAST_ERROR_JOIN_STAGE_MIC_ISSUE: 'TOAST_ERROR_JOIN_STAGE_MIC_ISSUE',
    TOAST_USER_JOINED_STAGE: 'TOAST_USER_JOINED_STAGE',
    TOAST_USER_JOINED_BACKSTAGE: 'TOAST_USER_JOINED_BACKSTAGE',
    TOAST_USER_ACCEPTED_INVITATION: 'TOAST_USER_ACCEPTED_INVITATION',
    TOAST_USER_REJECTED_INVITATION: 'TOAST_USER_REJECTED_INVITATION',
    TOAST_CANNOT_JOIN_STAGE_MISS_PERMISSION:
        'TOAST_CANNOT_JOIN_STAGE_MISS_PERMISSION',
    TOAST_JOIN_STAGE_AFTER_PERMISSION_GRANT:
        'TOAST_JOIN_STAGE_AFTER_PERMISSION_GRANT',
    TOAST_ADDED_YOU_AS_SPEAKER: 'TOAST_ADDED_YOU_AS_SPEAKER',
    GENERAL_OPTIONAL: 'GENERAL_OPTIONAL',
    LOUNGE_UNAVAILABLE: 'LOUNGE_UNAVAILABLE',
    RESOURCES_UNAVAILABLE: 'RESOURCES_UNAVAILABLE',
    RHS_CUSTOMISE_RESOURCES_DISABLED: 'RHS_CUSTOMISE_RESOURCES_DISABLED',
    ARENA_UNAVAILABLE: 'ARENA_UNAVAILABLE',
    BOOTH_UNAVAILABLE: 'BOOTH_UNAVAILABLE',
    TOASTS_ANNOUNCEMENT_SENT_ATTENDEES: 'TOASTS_ANNOUNCEMENT_SENT_ATTENDEES',
    TOASTS_ERROR_UNABLE_CREATE_ANNOUNCEMENT:
        'TOASTS_ERROR_UNABLE_CREATE_ANNOUNCEMENT',
    GENERAL_POWERED_BY: 'GENERAL_POWERED_BY',
    GENERAL_PRIVACY_POLICY: 'GENERAL_PRIVACY_POLICY',
    GENERAL_TERMS_OF_USE: 'GENERAL_TERMS_OF_USE',
    SESSION_DESCRIPTION: 'SESSION_DESCRIPTION',
    AUDIO_VIDEO_SETTINGS_MIRROR_VIDEO: 'AUDIO_VIDEO_SETTINGS_MIRROR_VIDEO',
    STAGE_STREAM_BRING_FOCUS: 'STAGE_STREAM_BRING_FOCUS',
    STAGE_STREAM_REMOVE_FOCUS: 'STAGE_STREAM_REMOVE_FOCUS',
    STAGE_STREAM_PIN_VIDEO: 'STAGE_STREAM_PIN_VIDEO',
    STAGE_STREAM_UNPIN_VIDEO: 'STAGE_STREAM_UNPIN_VIDEO',
    STAGE_STREAM_PIN_NOT_ALLOWED_ACTIVE_SPEAKER:
        'STAGE_STREAM_PIN_NOT_ALLOWED_ACTIVE_SPEAKER',
    STAGE_STREAM_PIN_PEOPLE_MESSAGE: 'STAGE_STREAM_PIN_PEOPLE_MESSAGE',
    STAGE_CANT_UNMUTE_OTHERS: 'STAGE_CANT_UNMUTE_OTHERS',
    TABLE_CANT_UNMUTE_OTHERS_VIDEO: 'TABLE_CANT_UNMUTE_OTHERS_VIDEO',
    POLLS_YES_NO: 'POLLS_YES_NO',
    POLLS_YES_OR_NO: 'POLLS_YES_OR_NO',
    POLLS_ASK_AUDIENCE_YES_NO: 'POLLS_ASK_AUDIENCE_YES_NO',
    POLLS_START_VOTE: 'POLLS_START_VOTE',
    POLLS_ENTER_QUESTION: 'POLLS_ENTER_QUESTION',
    POLLS_AUDIENCE_IS_VOTING_1: 'POLLS_AUDIENCE_IS_VOTING_1',
    POLLS_AUDIENCE_IS_VOTING_2: 'POLLS_AUDIENCE_IS_VOTING_2',
    POLLS_RESULTS: 'POLLS_RESULTS',
    POLLS_START_NEW: 'POLLS_START_NEW',
    POLLS_RESPOND_TO: 'POLLS_RESPOND_TO',
    STAGE_AUDIO_ONLY: 'STAGE_AUDIO_ONLY',
    STAGE_FAILED_TO_UNMUTE_AUDIO: 'STAGE_FAILED_TO_UNMUTE_AUDIO',
    STAGE_FAILED_TO_UNMUTE_VIDEO: 'STAGE_FAILED_TO_UNMUTE_VIDEO',
    STAGE_LITE: 'STAGE_LITE',
    SESSION_RHS_CONVERSATIONS_RAISED_HANDS_MIC_REQUESTS:
        'SESSION_RHS_CONVERSATIONS_RAISED_HANDS_MIC_REQUESTS',
    SESSION_RHS_CONVERSATIONS_RAISED_HANDS_WITHDRAW:
        'SESSION_RHS_CONVERSATIONS_RAISED_HANDS_WITHDRAW',
    SESSION_RHS_CONVERSATIONS_RAISED_HANDS_DISMISS_ALL:
        'SESSION_RHS_CONVERSATIONS_RAISED_HANDS_DISMISS_ALL',
    SESSION_RHS_CONVERSATIONS_NO_HANDS_RAISED:
        'SESSION_RHS_CONVERSATIONS_NO_HANDS_RAISED',
    SESSION_RHS_CONVERSATIONS_RAISED_HANDS_PENDING_REQUEST:
        'SESSION_RHS_CONVERSATIONS_RAISED_HANDS_PENDING_REQUEST',
    SESSION_RHS_CONVERSATIONS_RAISED_HANDS_PROVIDING_MIC:
        'SESSION_RHS_CONVERSATIONS_RAISED_HANDS_PROVIDING_MIC',
    SESSION_RHS_CONVERSATIONS_RAISED_HANDS_NOW_SPEAKING:
        'SESSION_RHS_CONVERSATIONS_RAISED_HANDS_NOW_SPEAKING',
    SESSION_RHS_CONVERSATIONS_RAISED_HANDS_NOW_ACTIVE:
        'SESSION_RHS_CONVERSATIONS_RAISED_HANDS_NOW_ACTIVE',
    SESSION_RHS_CONVERSATIONS_RAISED_HAND:
        'SESSION_RHS_CONVERSATIONS_RAISED_HAND',
    TOASTS_FAIL_PUBLISH_SCREEN_SHARE: 'TOASTS_FAIL_PUBLISH_SCREEN_SHARE',
    STAGE_HOST_MUTED_VIDEO: 'STAGE_HOST_MUTED_VIDEO',
    STAGE_HOST_MUTED_AUDIO: 'STAGE_HOST_MUTED_AUDIO',
    STAGE_HOST_STOPPED_PRESENTING: 'STAGE_HOST_STOPPED_PRESENTING',
    SESSION_RHS_CONVERSATIONS_Q_A_MOVE_ANSWERED:
        'SESSION_RHS_CONVERSATIONS_Q_A_MOVE_ANSWERED',
    TOASTS_UNBLOCKED_BY_MODERATOR: 'TOASTS_UNBLOCKED_BY_MODERATOR',
    TOASTS_ERROR_PASSWORD_FILE_TITLE: 'TOASTS_ERROR_PASSWORD_FILE_TITLE',
    TOASTS_ERROR_PASSWORD_FILE_MESSAGE: 'TOASTS_ERROR_PASSWORD_FILE_MESSAGE',
    TOASTS_ERROR_FILE_FORMAT_SUPPORT_TITLE:
        'TOASTS_ERROR_FILE_FORMAT_SUPPORT_TITLE',
    TOASTS_ERROR_FILE_FORMAT_SUPPORT_MESSAGE:
        'TOASTS_ERROR_FILE_FORMAT_SUPPORT_MESSAGE',
    GENERAL_MINIMIZE: 'GENERAL_MINIMIZE',
    GENERAL_MAXIMIZE: 'GENERAL_MAXIMIZE',
    GENERAL_VOLUME: 'GENERAL_VOLUME',
    STAGE_STREAM_PUBLISHING: 'STAGE_STREAM_PUBLISHING',
    STAGE_REMOVE_CO_HOST: 'STAGE_REMOVE_CO_HOST',
    STAGE_REMOVE_CO_HOST_QUESTION_1: 'STAGE_REMOVE_CO_HOST_QUESTION_1',
    STAGE_REMOVE_CO_HOST_QUESTION_2: 'STAGE_REMOVE_CO_HOST_QUESTION_2',
    GENERAL_CLEAR: 'GENERAL_CLEAR',
    STAGE_SESSION_ENABLE_RECORDING: 'STAGE_SESSION_ENABLE_RECORDING',
    STAGE_SESSION_ENABLE_RECORDING_MESSAGE:
        'STAGE_SESSION_ENABLE_RECORDING_MESSAGE',
    STAGE_SESSION_DISABLE_RECORDING: 'STAGE_SESSION_DISABLE_RECORDING',
    STAGE_SESSION_DISABLE_RECORDING_MESSAGE:
        'STAGE_SESSION_DISABLE_RECORDING_MESSAGE',
    STAGE_SESSION_ENABLE_RECORDING_DESCRIPTION:
        'STAGE_SESSION_ENABLE_RECORDING_DESCRIPTION',
    STAGE_SESSION_DISABLE_RECORDING_DESCRIPTION:
        'STAGE_SESSION_DISABLE_RECORDING_DESCRIPTION',
    RECORD_SESSION: 'RECORD_SESSION',
    RECORD_SESSION_CTA: 'RECORD_SESSION_CTA',
    DO_NOT_RECORD_SESSION: 'DO_NOT_RECORD_SESSION',
    BACKSTAGE_NOT_RECORDED: 'BACKSTAGE_NOT_RECORDED',
    ONLY_LIVE_SESSION_RECORDED: 'ONLY_LIVE_SESSION_RECORDED',
    CHOICE_ON: 'CHOICE_ON',
    CHOICE_OFF: 'CHOICE_OFF',
    LIVE_SESSION_WILL_BE_RECORDED: 'LIVE_SESSION_WILL_BE_RECORDED',
    LIVE_SESSION_WILL_NOT_BE_RECORDED: 'LIVE_SESSION_WILL_NOT_BE_RECORDED',
    STAGE_BACKSTAGE_EXIT_CONFIRMATION_HEADING:
        'STAGE_BACKSTAGE_EXIT_CONFIRMATION_HEADING',
    YES_LEAVE_BS: 'YES_LEAVE_BS',
    STAGE_ARE_YOU_SURE_JOIN_BACKSTAGE: 'STAGE_ARE_YOU_SURE_JOIN_BACKSTAGE',
    YES_JOIN_BS: 'YES_JOIN_BS',
    STAGE_PERSISTENT_JOIN_BACKSTAGE: 'STAGE_PERSISTENT_JOIN_BACKSTAGE',
    STAGE_PERSISTENT_LEAVE_BACKSTAGE: 'STAGE_PERSISTENT_LEAVE_BACKSTAGE',
    STAGE: 'STAGE',
    NOT_LIVE: 'NOT_LIVE',
    MOVE_TO_STAGE: 'MOVE_TO_STAGE',
    ONLY_ONE_PERSON_CAN_SHARE_SCREEN: 'ONLY_ONE_PERSON_CAN_SHARE_SCREEN',
    MOVE_TO_BACKSTAGE: 'MOVE_TO_BACKSTAGE',
    YOU_ARE_ON_BACKSTAGE: 'YOU_ARE_ON_BACKSTAGE',
    YOU_ARE_ON_STAGE: 'YOU_ARE_ON_STAGE',
    STAGE_PRESENT_TO_AUDIENCE_SURE_STOP_PRESENTING:
        'STAGE_PRESENT_TO_AUDIENCE_SURE_STOP_PRESENTING',
    CLOSE_BACKSTAGE_TRY_TO_SEE_CAPTION: 'CLOSE_BACKSTAGE_TRY_TO_SEE_CAPTION',
    MEDIA_MODE_SPEAKER_CAN_NOT_SEEN: 'MEDIA_MODE_SPEAKER_CAN_NOT_SEEN',
    TOASTS_DISCONNECTED_FROM_STAGE: 'TOASTS_DISCONNECTED_FROM_STAGE',
    TOASTS_CONNECTION_INTERRUPTED: 'TOASTS_CONNECTION_INTERRUPTED',
    GENERAL_CONNECTED: 'GENERAL_CONNECTED',
    GENERAL_NOT_CONNECTED: 'GENERAL_NOT_CONNECTED',
    TOAST_LOST_CONNECTION_RETRYING: 'TOAST_LOST_CONNECTION_RETRYING',
    STAGE_LITE_MODE_LAGS: 'STAGE_LITE_MODE_LAGS',
    TOAST_DIS_ALLOW_INVITE_TO_STAGE_MOBILE_USER:
        'TOAST_DIS_ALLOW_INVITE_TO_STAGE_MOBILE_USER',
    FEEDBACK_THANK_YOU: 'FEEDBACK_THANK_YOU',
    TOASTS_USER_JOINED_TABLE: 'TOASTS_USER_JOINED_TABLE',
    TOASTS_USER_LEFT_TABLE: 'TOASTS_USER_LEFT_TABLE',
    SPEED_NETWORKING_TRY_TO_LEAVE_REJOIN:
        'SPEED_NETWORKING_TRY_TO_LEAVE_REJOIN',
    GENERAL_OR: 'GENERAL_OR',
    GENERAL_CLICK_HERE: 'GENERAL_CLICK_HERE',
    GENERAL_TO_REFRESH: 'GENERAL_TO_REFRESH',
    GENERAL_PLAY: 'GENERAL_PLAY',
    SESSION_RHS_CONVERSATIONS_PLAY_VIDEO:
        'SESSION_RHS_CONVERSATIONS_PLAY_VIDEO',
    TOASTS_NETWORKING_PROVIDE_CAM_MIC_ACCESS:
        'TOASTS_NETWORKING_PROVIDE_CAM_MIC_ACCESS',
    TOASTS_NETWORKING_PROVIDE_MIC_ACCESS:
        'TOASTS_NETWORKING_PROVIDE_MIC_ACCESS',
    TOASTS_NETWORKING_HOST_DISABLED_1: 'TOASTS_NETWORKING_HOST_DISABLED_1',
    TOASTS_NETWORKING_HOST_DISABLED_2: 'TOASTS_NETWORKING_HOST_DISABLED_2',
    DIRECT_MESSAGES_REQUESTS: 'DIRECT_MESSAGES_REQUESTS',
    DIRECT_MESSAGES_REQUEST_DESC: 'DIRECT_MESSAGES_REQUEST_DESC',
    GENERAL_CLOSE: 'GENERAL_CLOSE',
    CAM_MIC_ONLY_MIC: 'CAM_MIC_ONLY_MIC',
    CAM_MIC: 'CAM_MIC',
    CAM_MIC_LOWER: 'CAM_MIC_LOWER',
    CAM_MIC_ALWAYS_ALLOW: 'CAM_MIC_ALWAYS_ALLOW',
    CAM_MIC_UNBLOCK_CHROME_MSG_1: 'CAM_MIC_UNBLOCK_CHROME_MSG_1',
    CAM_MIC_UNBLOCK_CHROME_MSG_2: 'CAM_MIC_UNBLOCK_CHROME_MSG_2',
    CAM_MIC_BOTH: 'CAM_MIC_BOTH',
    CAM_MIC_UNABLE_TO_ACCESS: 'CAM_MIC_UNABLE_TO_ACCESS',
    CAM_MIC_ACCESS_BLOCKED: 'CAM_MIC_ACCESS_BLOCKED',
    CAM_MIC_NOT_ACCESSIBLE: 'CAM_MIC_NOT_ACCESSIBLE',
    CAM_MIC_CANNOT_ACCESS_DESC2_1: 'CAM_MIC_CANNOT_ACCESS_DESC2_1',
    CAM_MIC_CANNOT_ACCESS_DESC2_2: 'CAM_MIC_CANNOT_ACCESS_DESC2_2',
    CAM_MIC_UNBLOCK_FIREFOX_MSG_1: 'CAM_MIC_UNBLOCK_FIREFOX_MSG_1',
    CAM_MIC_UNBLOCK_FIREFOX_MSG_2: 'CAM_MIC_UNBLOCK_FIREFOX_MSG_2',
    CAM_MIC_UNBLOCK_FIREFOX_BLOCKED_TEMP:
        'CAM_MIC_UNBLOCK_FIREFOX_BLOCKED_TEMP',
    CAM_MIC_UNBLOCK_SAFARI_MSG_1: 'CAM_MIC_UNBLOCK_SAFARI_MSG_1',
    CAM_MIC_UNBLOCK_SAFARI_MSG_2: 'CAM_MIC_UNBLOCK_SAFARI_MSG_2',
    CAM_MIC_UNBLOCK_SAFARI_SETTING: 'CAM_MIC_UNBLOCK_SAFARI_SETTING',
    CAM_MIC_BROWSER_ERROR_1: 'CAM_MIC_BROWSER_ERROR_1',
    CAM_MIC_BROWSER_ERROR_2: 'CAM_MIC_BROWSER_ERROR_2',
    BOOTH_EDIT_BOOTH_ENTER_VIDEO_LINK: 'BOOTH_EDIT_BOOTH_ENTER_VIDEO_LINK',
    GENERAL_CANNOT_BE_EMPTY: 'GENERAL_CANNOT_BE_EMPTY',
    GRID: 'GRID',
    STRIP: 'STRIP',
    STAGE_MANAGE_CO_HOST_ARE_NOW_CO_HOST:
        'STAGE_MANAGE_CO_HOST_ARE_NOW_CO_HOST',
    STAGE_MANAGE_CO_HOST_IS_NOW_CO_HOST: 'STAGE_MANAGE_CO_HOST_IS_NOW_CO_HOST',
    STAGE_MANAGE_CO_HOST_YOU_HAVE_MADE: 'STAGE_MANAGE_CO_HOST_YOU_HAVE_MADE',
    STAGE_MANAGE_CO_HOST_HAS_MADE: 'STAGE_MANAGE_CO_HOST_HAS_MADE',
    STAGE_MANAGE_CO_HOST_HOST_HAS_MADE: 'STAGE_MANAGE_CO_HOST_HOST_HAS_MADE',
    STAGE_MANAGE_CO_HOST_YOU_HAVE_MADE_SESSION:
        'STAGE_MANAGE_CO_HOST_YOU_HAVE_MADE_SESSION',
    STAGE_MANAGE_CO_HOST_USER_HAS_MADE_SESSION:
        'STAGE_MANAGE_CO_HOST_USER_HAS_MADE_SESSION',
    STAGE_MANAGE_CO_HOST_HOST_HAS_MADE_SESSION:
        'STAGE_MANAGE_CO_HOST_HOST_HAS_MADE_SESSION',
    STAGE_MANAGE_CO_HOST_REMOVED_FOR_SESSION:
        'STAGE_MANAGE_CO_HOST_REMOVED_FOR_SESSION',
    STAGE_MANAGE_CO_HOST_REMOVED: 'STAGE_MANAGE_CO_HOST_REMOVED',
    STAGE_MANAGE_CO_HOST_USER_REMOVED_FOR_SESSION:
        'STAGE_MANAGE_CO_HOST_USER_REMOVED_FOR_SESSION',
    STAGE_MANAGE_CO_HOST_USER_REMOVED: 'STAGE_MANAGE_CO_HOST_USER_REMOVED',
    LANGUAGE_SETTINGS: 'LANGUAGE_SETTINGS',
    LANGUAGE_SETTINGS_DESC: 'LANGUAGE_SETTINGS_DESC',
    SESSION_YOU_DONT_HAVE_ACCESS: 'SESSION_YOU_DONT_HAVE_ACCESS',
    BLOCKED_USER_QUESTION_HIDDEN: 'BLOCKED_USER_QUESTION_HIDDEN',
    GENERAL_SEARCH: 'GENERAL_SEARCH',
    ADD_PARTICIPANTS: 'ADD_PARTICIPANTS',
    POLLS_UNABLE_TO_CREATE: 'POLLS_UNABLE_TO_CREATE',
    POLLS_HAS_BEEN_CLOSED: 'POLLS_HAS_BEEN_CLOSED',
    POLLS_UNABLE_TO_UPDATE: 'POLLS_UNABLE_TO_UPDATE',
    POLLS_DELETED_SUCCESSFULLY: 'POLLS_DELETED_SUCCESSFULLY',
    POLLS_UNABLE_TO_DELETE: 'POLLS_UNABLE_TO_DELETE',
    POLLS_UNABLE_TO_VOTE: 'POLLS_UNABLE_TO_VOTE',
    POLLS_YOU_VOTED_FOR: 'POLLS_YOU_VOTED_FOR',
    POLLS_VOTES: 'POLLS_VOTES',
    POLLS_NO_RESULTS_BY_CREATOR: 'POLLS_NO_RESULTS_BY_CREATOR',
    POLLS_RESULTS_AFTER_CLOSE_BY_CREATOR:
        'POLLS_RESULTS_AFTER_CLOSE_BY_CREATOR',
    POLLS_CONFIRM_DELETION: 'POLLS_CONFIRM_DELETION',
    POLLS_SURE_TO_DELETE: 'POLLS_SURE_TO_DELETE',
    GENERAL_DELETE: 'GENERAL_DELETE',
    GENERAL_ENABLED: 'GENERAL_ENABLED',
    GENERAL_DISABLED: 'GENERAL_DISABLED',
    GENERAL_ENABLED_LOWER: 'GENERAL_ENABLED_LOWER',
    GENERAL_DISABLED_LOWER: 'GENERAL_DISABLED_LOWER',
    GENERAL_LOWER_ON: 'GENERAL_LOWER_ON',
    GENERAL_LOWER_OFF: 'GENERAL_LOWER_OFF',
    BOOTHS: 'BOOTHS',
    MESSAGES: 'MESSAGES',
    RHS_ALERTS_ANNOUNCEMENT_LINKS_BOOTHS:
        'RHS_ALERTS_ANNOUNCEMENT_LINKS_BOOTHS',
    EVENT: 'EVENT',
    FEATURE_DISABLED_BY_ORGANIZER: 'FEATURE_DISABLED_BY_ORGANIZER',
    SPEED_NETWORKING_DISABLED: 'SPEED_NETWORKING_DISABLED',
    LOUNGE_DISABLED: 'LOUNGE_DISABLED',
    RESOURCES_DISABLED: 'RESOURCES_DISABLED',
    TABLE_CANNOT_FIX_MORE_PEOPLE: 'TABLE_CANNOT_FIX_MORE_PEOPLE',
    TABLE_ERROR_LEAVING: 'TABLE_ERROR_LEAVING',
    TABLE_PARTICIPANTS: 'TABLE_PARTICIPANTS',
    TURN_ON_AUDIO: 'TURN_ON_AUDIO',
    TURN_OFF_AUDIO: 'TURN_OFF_AUDIO',
    TURN_ON_VIDEO: 'TURN_ON_VIDEO',
    TURN_OFF_VIDEO: 'TURN_OFF_VIDEO',
    TURN_ON_CAPTION: 'TURN_ON_CAPTION',
    TURN_OFF_CAPTION: 'TURN_OFF_CAPTION',
    COLLAPSE_PERSISTENT_BAR: 'COLLAPSE_PERSISTENT_BAR',
    EXPEND_PERSISTENT_BAR: 'EXPEND_PERSISTENT_BAR',
    CAMERA_NO_DEVICE_FOUND: 'CAMERA_NO_DEVICE_FOUND',
    JOINED_FROM_MOBILE: 'JOINED_FROM_MOBILE',
    CAMERA_NOT_ACCESSIBLE: 'CAMERA_NOT_ACCESSIBLE',
    MIC_NOT_FOUND: 'MIC_NOT_FOUND',
    MIC_NOT_ACCESSIBLE: 'MIC_NOT_ACCESSIBLE',
    TABLE_LEAVING: 'TABLE_LEAVING',
    TABLE_DISCONNECTED: 'TABLE_DISCONNECTED',
    TOASTS_CONNECTION_DISRUPTED: 'TOASTS_CONNECTION_DISRUPTED',
    TOASTS_FACED_ISSUE_TRY_AGAIN: 'TOASTS_FACED_ISSUE_TRY_AGAIN',
    GALLERY_VIEW: 'GALLERY_VIEW',
    ACTIVE_SPEAKER_VIEW: 'ACTIVE_SPEAKER_VIEW',
    ACTIVE_SPEAKER_VIEW_MESSAGE: 'ACTIVE_SPEAKER_VIEW_MESSAGE',
    SPOTLIGHT_VIEW: 'SPOTLIGHT_VIEW',
    TABLE_SINGLE_USER_SCREEN_SHARE_DISABLED:
        'TABLE_SINGLE_USER_SCREEN_SHARE_DISABLED',
    TABLE_SINGLE_USER_CLOSED_CAPTIONS_DISABLED:
        'TABLE_SINGLE_USER_CLOSED_CAPTIONS_DISABLED',
    TABLE_SINGLE_USER_MESSAGE: 'TABLE_SINGLE_USER_MESSAGE',
    SPEED_NETWORKING_ENDING_IN_MINUTE: 'SPEED_NETWORKING_ENDING_IN_MINUTE',
    SPEED_NETWORKING_ENDING_IN_MINUTES: 'SPEED_NETWORKING_ENDING_IN_MINUTES',
    SPEED_NETWORKING_ENDING_IN_SECOND: 'SPEED_NETWORKING_ENDING_IN_SECOND',
    SPEED_NETWORKING_ENDING_IN_SECONDS: 'SPEED_NETWORKING_ENDING_IN_SECONDS',
    GENERAL_LOST_CONNECTION: 'GENERAL_LOST_CONNECTION',
    STAGE_MESSAGE_HIDE_HOST_SPEAKER: 'STAGE_MESSAGE_HIDE_HOST_SPEAKER',
    STAGE_MESSAGE_SHOW_HOST_SPEAKER: 'STAGE_MESSAGE_SHOW_HOST_SPEAKER',
    STAGE_STREAM_SWITCH_MEDIA_MODE: 'STAGE_STREAM_SWITCH_MEDIA_MODE',
    STAGE_STREAM_SWITCH_NORMAL_MODE: 'STAGE_STREAM_SWITCH_NORMAL_MODE',
    GENERAL_HIDE: 'GENERAL_HIDE',
    TOASTS_CLICK_PLAY_TO_PLAY_ICON_VIDEO:
        'TOASTS_CLICK_PLAY_TO_PLAY_ICON_VIDEO',
    VIDEO_PLAYER_STOP_PLAYING_VIDEO: 'VIDEO_PLAYER_STOP_PLAYING_VIDEO',
    VIDEO_PLAYER_STOP_PLAYING_VIDEO_MESSAGE:
        'VIDEO_PLAYER_STOP_PLAYING_VIDEO_MESSAGE',
    VIDEO_PLAYER_STOP_VIDEO: 'VIDEO_PLAYER_STOP_VIDEO',
    POLL_DELETE: 'POLL_DELETE',
    RHS_MESSAGES_TOOLTIP: 'RHS_MESSAGES_TOOLTIP',
    RHS_MESSAGES_SEND_MESSAGE: 'RHS_MESSAGES_SEND_MESSAGE',
    RHS_MESSAGES_EMPTY_STATE: 'RHS_MESSAGES_EMPTY_STATE',
    RHS_EMPTY_STATE_FOR_PAUSED_ATTENDEE: 'RHS_EMPTY_STATE_FOR_PAUSED_ATTENDEE',
    BOOTH_RESET_TAGS: 'BOOTH_RESET_TAGS',
    TOASTS_ERROR_NETWORK_UNAVAILABLE_TRY_AGAIN:
        'TOASTS_ERROR_NETWORK_UNAVAILABLE_TRY_AGAIN',
    NETWORK_MESSAGE_UNSTABLE_INTERNET: 'NETWORK_MESSAGE_UNSTABLE_INTERNET',
    NETWORK_SWITCH_LOW_BW_MODE: 'NETWORK_SWITCH_LOW_BW_MODE',
    PROFILE_YOUR_PROFILE: 'PROFILE_YOUR_PROFILE',
    PROFILE_PREVIEW_CARD_HEADER: 'PROFILE_PREVIEW_CARD_HEADER',
    PROFILE_PREVIEW_CARD_DESC: 'PROFILE_PREVIEW_CARD_DESC',
    PROFILE_FIELD_NAME: 'PROFILE_FIELD_NAME',
    PROFILE_FIELD_FIRST_NAME: 'PROFILE_FIELD_FIRST_NAME',
    PROFILE_FIELD_LAST_NAME: 'PROFILE_FIELD_LAST_NAME',
    PROFILE_FIELD_DESIGNATION: 'PROFILE_FIELD_DESIGNATION',
    PROFILE_FIELD_JOB_TITLE: 'PROFILE_FIELD_JOB_TITLE',
    PROFILE_FIELD_ORGANIZATION: 'PROFILE_FIELD_ORGANIZATION',
    PROFILE_FIELD_CITY: 'PROFILE_FIELD_CITY',
    PROFILE_FIELD_COUNTRY: 'PROFILE_FIELD_COUNTRY',
    PROFILE_FIELD_EMAIL: 'PROFILE_FIELD_EMAIL',
    PROFILE_FIELD_STATE: 'PROFILE_FIELD_STATE',
    PROFILE_FIELD_PHONE_NO: 'PROFILE_FIELD_PHONE_NO',
    PROFILE_TOAST_ERROR_DETAILS_NOT_UPDATED:
        'PROFILE_TOAST_ERROR_DETAILS_NOT_UPDATED',
    PROFILE_TOAST_DETAILS_UPDATED_SUCCESSFULLY:
        'PROFILE_TOAST_DETAILS_UPDATED_SUCCESSFULLY',
    STAGE_MEET_SPEAKERS_ON_BACKSTAGE: 'STAGE_MEET_SPEAKERS_ON_BACKSTAGE',
    STAGE_MEET_HOST_SPEAKERS_ON_BACKSTAGE:
        'STAGE_MEET_HOST_SPEAKERS_ON_BACKSTAGE',
    STAGE_PEOPLE_ARE_ON_BACKSTAGE: 'STAGE_PEOPLE_ARE_ON_BACKSTAGE',
    STAGE_PERSON_IS_ON_BACKSTAGE: 'STAGE_PERSON_IS_ON_BACKSTAGE',
    SESSION_PLAYBACK_WATCH_REPLAY: 'SESSION_PLAYBACK_WATCH_REPLAY',
    SESSION_PLAYBACK_RESTRICT_FROM_VOTING:
        'SESSION_PLAYBACK_RESTRICT_FROM_VOTING',
    SESSION_SCHEDULE_SETTINGS: 'SESSION_SCHEDULE_SETTINGS',
    SESSION_REPLAY_OVERLAY_HEADING_1: 'SESSION_REPLAY_OVERLAY_HEADING_1',
    SESSION_REPLAY_OVERLAY_HEADING_2: 'SESSION_REPLAY_OVERLAY_HEADING_2',
    SESSION_PLAYBACK_DISABLE_SESSION_REPLAY:
        'SESSION_PLAYBACK_DISABLE_SESSION_REPLAY',
    SESSION_PLAYBACK_ENABLE_SESSION_REPLAY:
        'SESSION_PLAYBACK_ENABLE_SESSION_REPLAY',
    SESSION_PLAYBACK_DISABLED_INFO_TEXT: 'SESSION_PLAYBACK_DISABLED_INFO_TEXT',
    SESSION_PLAYBACK_ENABLED_INFO_TEXT: 'SESSION_PLAYBACK_ENABLED_INFO_TEXT',
    SESSION_PLAYBACK_ENABLED_CONFIRMATION_TEXT:
        'SESSION_PLAYBACK_ENABLED_CONFIRMATION_TEXT',
    SESSION_PLAYBACK_DISABLED_CONFIRMATION_TEXT:
        'SESSION_PLAYBACK_DISABLED_CONFIRMATION_TEXT',
    SESSION_PLAYBACK_DISABLE_BTN_TEXT: 'SESSION_PLAYBACK_DISABLE_BTN_TEXT',
    SESSION_PLAYBACK_ENABLE_BTN_TEXT: 'SESSION_PLAYBACK_ENABLE_BTN_TEXT',
    SESSION_PLAYBACK_NO_DISMISS: 'SESSION_PLAYBACK_NO_DISMISS',
    TOASTS_ORGANIZER_DISABLED_SESSION_PLAYBACK:
        'TOASTS_ORGANIZER_DISABLED_SESSION_PLAYBACK',
    SESSION_NO_SPEAKER_ADDED: 'SESSION_NO_SPEAKER_ADDED',
    SESSION_PLAYBACK_ENABLE_REPLAY: 'SESSION_PLAYBACK_ENABLE_REPLAY',
    SESSION_PLAYBACK_DISABLE_REPLAY: 'SESSION_PLAYBACK_DISABLE_REPLAY',
    SESSION_PLAYBACK_UNAVAILABLE_TOOLTIP:
        'SESSION_PLAYBACK_UNAVAILABLE_TOOLTIP',
    GENERAL_FIELD_CANNOT_BE_EMPTY: 'GENERAL_FIELD_CANNOT_BE_EMPTY',
    GENERAL_FIELD: 'GENERAL_FIELD',
    PERMISSIONS_NO_ACCESS_CAM_MIC: 'PERMISSIONS_NO_ACCESS_CAM_MIC',
    PERMISSIONS_BROWSER_NO_ACCESS_CAM_MIC:
        'PERMISSIONS_BROWSER_NO_ACCESS_CAM_MIC',
    PERMISSIONS_TO_CHANGE_SYSTEM_SETTINGS_CAM_MIC:
        'PERMISSIONS_TO_CHANGE_SYSTEM_SETTINGS_CAM_MIC',
    PERMISSIONS_ACCESS_BLOCKED_CAM_MIC: 'PERMISSIONS_ACCESS_BLOCKED_CAM_MIC',
    PERMISSIONS_UNBLOCK_CAM_MIC_STEPS: 'PERMISSIONS_UNBLOCK_CAM_MIC_STEPS',
    PERMISSIONS_UNBLOCK_MIC_STEPS: 'PERMISSIONS_UNBLOCK_MIC_STEPS',
    PERMISSIONS_NOT_ACCESSIBLE_CAM_MIC: 'PERMISSIONS_NOT_ACCESSIBLE_CAM_MIC',
    PERMISSIONS_CAN_JOIN_WITHOUT_CAM_MIC_DESC:
        'PERMISSIONS_CAN_JOIN_WITHOUT_CAM_MIC_DESC',
    PERMISSIONS_JOIN_WITHOUT_CAM_MIC: 'PERMISSIONS_JOIN_WITHOUT_CAM_MIC',
    PERMISSIONS_CHROME_RESOLVE_STEPS_1: 'PERMISSIONS_CHROME_RESOLVE_STEPS_1',
    PERMISSIONS_CHROME_RESOLVE_STEPS_2: 'PERMISSIONS_CHROME_RESOLVE_STEPS_2',
    PERMISSIONS_RESOLVE_STEPS_REFRESH_TAB:
        'PERMISSIONS_RESOLVE_STEPS_REFRESH_TAB',
    PERMISSIONS_SAFARI_RESOLVE_STEPS_1: 'PERMISSIONS_SAFARI_RESOLVE_STEPS_1',
    PERMISSIONS_SAFARI_RESOLVE_STEPS_2: 'PERMISSIONS_SAFARI_RESOLVE_STEPS_2',
    PERMISSIONS_SAFARI_RESOLVE_STEPS_3: 'PERMISSIONS_SAFARI_RESOLVE_STEPS_3',
    PERMISSIONS_SAFARI_RESOLVE_STEPS_4: 'PERMISSIONS_SAFARI_RESOLVE_STEPS_4',
    PERMISSIONS_FIREFOX_RESOLVE_STEPS_1: 'PERMISSIONS_FIREFOX_RESOLVE_STEPS_1',
    PERMISSIONS_FIREFOX_RESOLVE_STEPS_2: 'PERMISSIONS_FIREFOX_RESOLVE_STEPS_2',
    PERMISSIONS_FIREFOX_RESOLVE_STEPS_3: 'PERMISSIONS_FIREFOX_RESOLVE_STEPS_3',
    PERMISSIONS_CHROME_RESOLVE_OPTION: 'PERMISSIONS_CHROME_RESOLVE_OPTION',
    GENERAL_NOT_AVAILABLE: 'GENERAL_NOT_AVAILABLE',
    TABLE_JOIN: 'TABLE_JOIN',
    PERMISSIONS_TO_JOIN_TABLE: 'PERMISSIONS_TO_JOIN_TABLE',
    PERMISSIONS_TO_JOIN_STAGE: 'PERMISSIONS_TO_JOIN_STAGE',
    PERMISSIONS_TO_JOIN_BACKSTAGE: 'PERMISSIONS_TO_JOIN_BACKSTAGE',
    PERMISSIONS_CAM_MIC_REQUIRED: 'PERMISSIONS_CAM_MIC_REQUIRED',
    PERMISSIONS_MIC_REQUIRED: 'PERMISSIONS_MIC_REQUIRED',
    PERMISSIONS_PLEASE_ALLOW_CAM_MIC_TABLE:
        'PERMISSIONS_PLEASE_ALLOW_CAM_MIC_TABLE',
    PERMISSIONS_SKIP_PERMISSION: 'PERMISSIONS_SKIP_PERMISSION',
    PERMISSIONS_ENABLE_CAMERA_MIC: 'PERMISSIONS_ENABLE_CAMERA_MIC',
    PERMISSIONS_ENABLE_MIC: 'PERMISSIONS_ENABLE_MIC',
    PERMISSIONS_ALLOW_ACCESS_CAM_MIC: 'PERMISSIONS_ALLOW_ACCESS_CAM_MIC',
    PERMISSIONS_ALLOW_ACCESS_MIC: 'PERMISSIONS_ALLOW_ACCESS_MIC',
    PERMISSIONS_TABLE_FAILURE_BACK_TO_LOUNGE:
        'PERMISSIONS_TABLE_FAILURE_BACK_TO_LOUNGE',
    TURN_ON_SPEAKER: 'TURN_ON_SPEAKER',
    TURN_OFF_SPEAKER: 'TURN_OFF_SPEAKER',
    STOP_LIVE_BROADCAST: 'STOP_LIVE_BROADCAST',
    STOP_BROADCAST_CONFIRMATION: 'STOP_BROADCAST_CONFIRMATION',
    STOP_BROADCAST: 'STOP_BROADCAST',
    START_LIVE_BROADCAST_INFO: 'START_LIVE_BROADCAST_INFO',
    EXIT_TABLE_JOIN_BROADCAST: 'EXIT_TABLE_JOIN_BROADCAST',
    LIVE_BROADCAST: 'LIVE_BROADCAST',
    CHAT_ASK_QUESTIONS_TO_EXHIBITOR: 'CHAT_ASK_QUESTIONS_TO_EXHIBITOR',
    LIVE_BROADCAST_NOTIFICATION: 'LIVE_BROADCAST_NOTIFICATION',
    NO_LIVE_BROADCAST: 'NO_LIVE_BROADCAST',
    GET_NOTIFIED_IN_LIVE_BROADCAST: 'GET_NOTIFIED_IN_LIVE_BROADCAST',
    DISABLE_NOTIFICATION: 'DISABLE_NOTIFICATION',
    NOTIFY_ME: 'NOTIFY_ME',
    START_A_LIVE_BROADCAST: 'START_A_LIVE_BROADCAST',
    BROADCAST_START_INFO: 'BROADCAST_START_INFO',
    START_BROADCAST_CONFIRMATION: 'START_BROADCAST_CONFIRMATION',
    STREAM_PUBLISH: 'STREAM_PUBLISH',
    LIVE_BROADCAST_CONNECTION: 'LIVE_BROADCAST_CONNECTION',
    TALK_TO_US_ON_TABLE: 'TALK_TO_US_ON_TABLE',
    TYPE_MESSAGE_QUESTION: 'TYPE_MESSAGE_QUESTION',
    CHAT_REPLY_QUESTIONS_FROM_VISITORS: 'CHAT_REPLY_QUESTIONS_FROM_VISITORS',
    START_LIVE_BROADCAST: 'START_LIVE_BROADCAST',
    CHAT_WITH_BOOTH_VISITORS: 'CHAT_WITH_BOOTH_VISITORS',
    LIVE_DEMO_TITLE: 'LIVE_DEMO_TITLE',
    LIVE_DEMO_NORMAL_STATE: 'LIVE_DEMO_NORMAL_STATE',
    LIVE_DEMO_SUCCESS_STATE: 'LIVE_DEMO_SUCCESS_STATE',
    LIVE_DEMO_CONTACT_SALES: 'LIVE_DEMO_CONTACT_SALES',
    AUDIO_VIDEO_SETTINGS_TOOLTIP: 'AUDIO_VIDEO_SETTINGS_TOOLTIP',
    AUDIO_VIDEO_SETTINGS_MUTE_AUDIO: 'AUDIO_VIDEO_SETTINGS_MUTE_AUDIO',
    AUDIO_VIDEO_SETTINGS_MUTE_AUDIO_MSG: 'AUDIO_VIDEO_SETTINGS_MUTE_AUDIO_MSG',
    AUDIO_VIDEO_SETTINGS_MUTE_VIDEO: 'AUDIO_VIDEO_SETTINGS_MUTE_VIDEO',
    AUDIO_VIDEO_SETTINGS_MUTE_VIDEO_MSG: 'AUDIO_VIDEO_SETTINGS_MUTE_VIDEO_MSG',
    SOCIAL_WEBINAR_TYPE: 'SOCIAL_WEBINAR_TYPE',
    SHORT_EVENT_TYPE: 'SHORT_EVENT_TYPE',
    HYBRID_CONFERENCE: 'HYBRID_CONFERENCE',
    IN_PERSON_EVENT: 'IN_PERSON_EVENT',
    VIRTUAL_SERIES: 'VIRTUAL_SERIES',
    HYBRID_EVENT: 'HYBRID_EVENT',
    SIMPLE_WEBINAR: 'SIMPLE_WEBINAR',
    SHORT_EVENT_CONTENT: 'SHORT_EVENT_CONTENT',
    MEETUP_TYPE: 'MEETUP_TYPE',
    CONFERENCE_NEW_CONTENT: 'CONFERENCE_NEW_CONTENT',
    CONFERENCE_TYPE: 'CONFERENCE_TYPE',
    CREATE_EVENT_TEXT: 'CREATE_EVENT_TEXT',
    WATCH_DEMO_CONFERENCE: 'WATCH_DEMO_CONFERENCE',
    AUDIO_VIDEO_SETTINGS_PLEASE_ENSURE_THAT:
        'AUDIO_VIDEO_SETTINGS_PLEASE_ENSURE_THAT',
    AUDIO_VIDEO_SETTINGS_CAMERA_NOT_AVAILABLE:
        'AUDIO_VIDEO_SETTINGS_CAMERA_NOT_AVAILABLE',
    AUDIO_VIDEO_SETTINGS_MIC_NOT_AVAILABLE:
        'AUDIO_VIDEO_SETTINGS_MIC_NOT_AVAILABLE',
    AUDIO_VIDEO_SETTINGS_AIRMEET_HAS_ACCESS_YOUR_CAM2:
        'AUDIO_VIDEO_SETTINGS_AIRMEET_HAS_ACCESS_YOUR_CAM2',
    AUDIO_VIDEO_SETTINGS_OTHER_APPLICATION_NOT_USING_CAM:
        'AUDIO_VIDEO_SETTINGS_OTHER_APPLICATION_NOT_USING_CAM',
    LIVE_BROADCAST_IS_ON: 'LIVE_BROADCAST_IS_ON',
    CHAT_WITH_TEXT: 'CHAT_WITH_TEXT',
    CHAT_WITH_US: 'CHAT_WITH_US',
    CHAT_WITH_VISITORS: 'CHAT_WITH_VISITORS',
    CHAT_WITH_BOOTH_EXHIBITOR: 'CHAT_WITH_BOOTH_EXHIBITOR',
    BOOTH_CHAT_PLACEHOLDER_MOBILE: 'BOOTH_CHAT_PLACEHOLDER_MOBILE',
    START_LIVE_BROADCAST_MSG: 'START_LIVE_BROADCAST_MSG',
    START_LIVE_BROADCAST_BOOTH_MSG: 'START_LIVE_BROADCAST_BOOTH_MSG',
    BOOTH_CONFIRMATION_LEAVE_TABLE_JOIN_BROADCAST_MSG:
        'BOOTH_CONFIRMATION_LEAVE_TABLE_JOIN_BROADCAST_MSG',
    BOOTH_JOIN_BROADCAST: 'BOOTH_JOIN_BROADCAST',
    SEND_MESSAGE_BOOTH_PLACEHOLDER: 'SEND_MESSAGE_BOOTH_PLACEHOLDER',
    SETTINGS: 'SETTINGS',
    RHS_SETTINGS_SUB_HEADER: 'RHS_SETTINGS_SUB_HEADER',
    RHS_SETTINGS_TOOLTIP: 'RHS_SETTINGS_TOOLTIP',
    RHS_SETTINGS_TOOLTIP2: 'RHS_SETTINGS_TOOLTIP2',
    RHS_SETTINGS_CUSTOMIZE_TITLE: 'RHS_SETTINGS_CUSTOMIZE_TITLE',
    RHS_SETTINGS_CUSTOMIZE_SUBTITLE_CONF:
        'RHS_SETTINGS_CUSTOMIZE_SUBTITLE_CONF',
    RHS_SETTINGS_CUSTOMIZE_SUBTITLE_MEETUP:
        'RHS_SETTINGS_CUSTOMIZE_SUBTITLE_MEETUP',
    RHS_SETTINGS_BRANDING_TITLE: 'RHS_SETTINGS_BRANDING_TITLE',
    RHS_SETTINGS_BRANDING_SUBTITLE_CONF: 'RHS_SETTINGS_BRANDING_SUBTITLE_CONF',
    RHS_SETTINGS_BRANDING_SUBTITLE_MEETUP:
        'RHS_SETTINGS_BRANDING_SUBTITLE_MEETUP',
    RHS_SETTINGS_PROFILE_TITLE: 'RHS_SETTINGS_PROFILE_TITLE',
    RHS_SETTINGS_PROFILE_SUBTITLE: 'RHS_SETTINGS_PROFILE_SUBTITLE',
    RHS_SETTINGS_AV_TITLE: 'RHS_SETTINGS_AV_TITLE',
    RHS_SETTINGS_AV_SUBTITLE: 'RHS_SETTINGS_AV_SUBTITLE',
    RHS_SETTINGS_DASHBOARD_TITLE: 'RHS_SETTINGS_DASHBOARD_TITLE',
    RHS_SETTINGS_DASHBOARD_SUBTITLE: 'RHS_SETTINGS_DASHBOARD_SUBTITLE',
    RHS_SETTINGS_LANGUAGE_TITLE: 'RHS_SETTINGS_LANGUAGE_TITLE',
    RHS_SETTINGS_LANGUAGE_SUBTITLE2: 'RHS_SETTINGS_LANGUAGE_SUBTITLE2',
    RHS_SETTINGS_LOGOUT: 'RHS_SETTINGS_LOGOUT',
    RHS_CUSTOMIZE_TITLE: 'RHS_CUSTOMIZE_TITLE',
    RHS_CUSTOMIZE_TOAST_SUCCESS: 'RHS_CUSTOMIZE_TOAST_SUCCESS',
    RHS_CUSTOMIZE_CLEAR: 'RHS_CUSTOMIZE_CLEAR',
    RHS_CUSTOMIZE_HIDE: 'RHS_CUSTOMIZE_HIDE',
    RHS_CUSTOMIZE_SHOW: 'RHS_CUSTOMIZE_SHOW',
    RHS_CUSTOMIZE_TOAST_CLEAR_SUCCESS: 'RHS_CUSTOMIZE_TOAST_CLEAR_SUCCESS',
    RHS_CUSTOMIZE_AMBIENCE_TITLE: 'RHS_CUSTOMIZE_AMBIENCE_TITLE',
    RHS_CUSTOMIZE_AMBIENCE_SUBTITLE: 'RHS_CUSTOMIZE_AMBIENCE_SUBTITLE',
    RHS_CUSTOMIZE_AMBIENCE_DARK: 'RHS_CUSTOMIZE_AMBIENCE_DARK',
    RHS_CUSTOMIZE_AMBIENCE_LIGHT: 'RHS_CUSTOMIZE_AMBIENCE_LIGHT',
    RHS_CUSTOMIZE_COLORS: 'RHS_CUSTOMIZE_COLORS',
    RHS_CUSTOMIZE_THEME_TITLE: 'RHS_CUSTOMIZE_THEME_TITLE',
    RHS_CUSTOMIZE_SELECTED_COLOR: 'RHS_CUSTOMIZE_SELECTED_COLOR',
    RHS_CUSTOMIZE_CHOOSE_COLOR_STYLE: 'RHS_CUSTOMIZE_CHOOSE_COLOR_STYLE',
    RHS_CUSTOMIZE_BORDER_RADIUS_TITLE: 'RHS_CUSTOMIZE_BORDER_RADIUS_TITLE',
    RHS_CUSTOMIZE_BORDER_RADIUS_SUBTITLE:
        'RHS_CUSTOMIZE_BORDER_RADIUS_SUBTITLE',
    RHS_CUSTOMIZE_TOP_NAV_BG_TITLE: 'RHS_CUSTOMIZE_TOP_NAV_BG_TITLE',
    RHS_CUSTOMIZE_TOP_NAV_BG_SUBTITLE: 'RHS_CUSTOMIZE_TOP_NAV_BG_SUBTITLE',
    RHS_CUSTOMIZE_CARD_PANEL_BG_TITLE: 'RHS_CUSTOMIZE_CARD_PANEL_BG_TITLE',
    RHS_CUSTOMIZE_CARD_PANEL_BG_SUBTITLE:
        'RHS_CUSTOMIZE_CARD_PANEL_BG_SUBTITLE',
    RHS_CUSTOMIZE_THEME_SUBTITLE: 'RHS_CUSTOMIZE_THEME_SUBTITLE',
    RHS_CUSTOMIZE_BUTTON_TEXT_COLOR_TITLE:
        'RHS_CUSTOMIZE_BUTTON_TEXT_COLOR_TITLE',
    RHS_CUSTOMIZE_BUTTON_TEXT_COLOR_SUBTITLE:
        'RHS_CUSTOMIZE_BUTTON_TEXT_COLOR_SUBTITLE',
    RHS_CUSTOMIZE_FONT_TITLE: 'RHS_CUSTOMIZE_FONT_TITLE',
    RHS_CUSTOMIZE_FONT_FAMILY_TITLE: 'RHS_CUSTOMIZE_FONT_FAMILY_TITLE',
    RHS_CUSTOMIZE_FONT_SUBTITLE: 'RHS_CUSTOMIZE_FONT_SUBTITLE',
    RHS_CUSTOMIZE_BACKGROUND_TITLE: 'RHS_CUSTOMIZE_BACKGROUND_TITLE',
    RHS_CUSTOMIZE_BACKGROUND_SUBTITLE: 'RHS_CUSTOMIZE_BACKGROUND_SUBTITLE',
    RHS_CUSTOMIZE_EVENT_EXP_TITLE: 'RHS_CUSTOMIZE_EVENT_EXP_TITLE',
    RHS_CUSTOMIZE_EVENT_EXP_SUBTITLE: 'RHS_CUSTOMIZE_EVENT_EXP_SUBTITLE',
    RHS_CUSTOMIZE_FEED_LABEL: 'RHS_CUSTOMIZE_FEED_LABEL',
    RHS_CUSTOMIZE_FEED_CLEAR_TITLE: 'RHS_CUSTOMIZE_FEED_CLEAR_TITLE',
    RHS_CUSTOMIZE_FEED_CLEAR_DESCRIPTION:
        'RHS_CUSTOMIZE_FEED_CLEAR_DESCRIPTION',
    RHS_CUSTOMIZE_ATTENDEE_LIST_LABEL: 'RHS_CUSTOMIZE_ATTENDEE_LIST_LABEL',
    RHS_CUSTOMIZE_WALKTHROUGH_VIDEO_LABEL:
        'RHS_CUSTOMIZE_WALKTHROUGH_VIDEO_LABEL',
    RHS_CUSTOMIZE_WALKTHROUGH_VIDEO_HINT:
        'RHS_CUSTOMIZE_WALKTHROUGH_VIDEO_HINT',
    RHS_CUSTOMIZE_EXIT_SURVEY_LABEL2: 'RHS_CUSTOMIZE_EXIT_SURVEY_LABEL2',
    RHS_CUSTOMIZE_EXIT_SURVEY_HINT2: 'RHS_CUSTOMIZE_EXIT_SURVEY_HINT2',
    RHS_CUSTOMIZE_RAISE_HAND_LABEL: 'RHS_CUSTOMIZE_RAISE_HAND_LABEL',
    RHS_CUSTOMIZE_RAISE_HAND_HINT: 'RHS_CUSTOMIZE_RAISE_HAND_HINT',
    RHS_CUSTOMIZE_STAGE_LABELS: 'RHS_CUSTOMIZE_STAGE_LABELS',
    RHS_CUSTOMIZE_STAGE_LABELS_HINT: 'RHS_CUSTOMIZE_STAGE_LABELS_HINT',
    RHS_CUSTOMIZE_DIRECT_MESSAGES_HINT: 'RHS_CUSTOMIZE_DIRECT_MESSAGES_HINT',
    RHS_CUSTOMIZE_RENAME_TITLE: 'RHS_CUSTOMIZE_RENAME_TITLE',
    RHS_CUSTOMIZE_RENAME_SUBTITLE: 'RHS_CUSTOMIZE_RENAME_SUBTITLE',
    RHS_CUSTOMIZE_RENAME_CTA: 'RHS_CUSTOMIZE_RENAME_CTA',
    RHS_CUSTOMIZE_RENAME_PRESS_ENTER: 'RHS_CUSTOMIZE_RENAME_PRESS_ENTER',
    RHS_CUSTOMIZE_RENAME_SIMPLE: 'RHS_CUSTOMIZE_RENAME_SIMPLE',
    RHS_CUSTOMIZE_RENAME_REQUIRED_ERROR: 'RHS_CUSTOMIZE_RENAME_REQUIRED_ERROR',
    RHS_CUSTOMIZE_RENAME_UNIQUE_ERROR: 'RHS_CUSTOMIZE_RENAME_UNIQUE_ERROR',
    RHS_CUSTOMIZE_RENAME_MAX_LENGTH_ERROR:
        'RHS_CUSTOMIZE_RENAME_MAX_LENGTH_ERROR',
    RHS_CUSTOMIZE_SESSION_EXP_TITLE: 'RHS_CUSTOMIZE_SESSION_EXP_TITLE',
    RHS_CUSTOMIZE_SESSION_EXP_SUBTITLE: 'RHS_CUSTOMIZE_SESSION_EXP_SUBTITLE',
    RHS_CUSTOMIZE_LIVE_CHAT_LABEL: 'RHS_CUSTOMIZE_LIVE_CHAT_LABEL',
    RHS_CUSTOMIZE_LIVE_CHAT_CLEAR_TITLE: 'RHS_CUSTOMIZE_LIVE_CHAT_CLEAR_TITLE',
    RHS_CUSTOMIZE_LIVE_CHAT_CLEAR_DESCRIPTION:
        'RHS_CUSTOMIZE_LIVE_CHAT_CLEAR_DESCRIPTION',
    RHS_CUSTOMIZE_QA_LABEL: 'RHS_CUSTOMIZE_QA_LABEL',
    RHS_CUSTOMIZE_QA_CLEAR_TITLE: 'RHS_CUSTOMIZE_QA_CLEAR_TITLE',
    RHS_CUSTOMIZE_QA_CLEAR_DESCRIPTION: 'RHS_CUSTOMIZE_QA_CLEAR_DESCRIPTION',
    RHS_CUSTOMIZE_POLLS_LABEL: 'RHS_CUSTOMIZE_POLLS_LABEL',
    RHS_CUSTOMIZE_POLLS_CLEAR_TITLE: 'RHS_CUSTOMIZE_POLLS_CLEAR_TITLE',
    RHS_CUSTOMIZE_POLLS_CLEAR_DESCRIPTION:
        'RHS_CUSTOMIZE_POLLS_CLEAR_DESCRIPTION',
    RHS_CUSTOMIZE_PEOPLE_IN_SESSION_LABEL:
        'RHS_CUSTOMIZE_PEOPLE_IN_SESSION_LABEL',
    RHS_CUSTOMIZE_PEOPLE_IN_SESSION_LABEL_V2:
        'RHS_CUSTOMIZE_PEOPLE_IN_SESSION_LABEL_V2',
    RHS_CUSTOMIZE_PEOPLE_IN_SESSION_HINT:
        'RHS_CUSTOMIZE_PEOPLE_IN_SESSION_HINT',
    RHS_CUSTOMIZE_GIF_IN_EVENT_LABEL: 'RHS_CUSTOMIZE_GIF_IN_EVENT_LABEL',
    RHS_CUSTOMIZE_GIF_IN_EVENT_HINT: 'RHS_CUSTOMIZE_GIF_IN_EVENT_HINT',
    RHS_CUSTOMIZE_EMOJI_REACTIONS_LABEL: 'RHS_CUSTOMIZE_EMOJI_REACTIONS_LABEL',
    RHS_CUSTOMIZE_EMOJI_REACTIONS_HINT: 'RHS_CUSTOMIZE_EMOJI_REACTIONS_HINT',
    RHS_CUSTOMIZE_ATTENDEE_COUNT_LABEL: 'RHS_CUSTOMIZE_ATTENDEE_COUNT_LABEL',
    RHS_CUSTOMIZE_ATTENDEE_COUNT_LABEL_V2:
        'RHS_CUSTOMIZE_ATTENDEE_COUNT_LABEL_V2',
    RHS_CUSTOMIZE_DIRECT_MESSAGES_LABEL: 'RHS_CUSTOMIZE_DIRECT_MESSAGES_LABEL',
    RHS_CUSTOMIZE_PEOPLE_IN_EVENT_LABEL: 'RHS_CUSTOMIZE_PEOPLE_IN_EVENT_LABEL',
    RHS_CUSTOMIZE_PEOPLE_IN_EVENT_LABEL_V2:
        'RHS_CUSTOMIZE_PEOPLE_IN_EVENT_LABEL_V2',
    RHS_CUSTOMIZE_PEOPLE_IN_EVENT_HINT: 'RHS_CUSTOMIZE_PEOPLE_IN_EVENT_HINT',
    RHS_CUSTOMIZE_FEEDBACK_RATING_LABEL: 'RHS_CUSTOMIZE_FEEDBACK_RATING_LABEL',
    COLOR_PICKER_TITLE: 'COLOR_PICKER_TITLE',
    SCHEDULE_MEETING_ADD_PEOPLE: 'SCHEDULE_MEETING_ADD_PEOPLE',
    SCHEDULE_MEETING_ADD_PEOPLE_UPTO: 'SCHEDULE_MEETING_ADD_PEOPLE_UPTO',
    SCHEDULE_MEETING_ADD_PEOPLE_LIST_DESC:
        'SCHEDULE_MEETING_ADD_PEOPLE_LIST_DESC',
    SCHEDULE_MEETING_ADD_PEOPLE_SEARCH_PH:
        'SCHEDULE_MEETING_ADD_PEOPLE_SEARCH_PH',
    SCHEDULE_MEETING_PEOPLE_ADDED: 'SCHEDULE_MEETING_PEOPLE_ADDED',
    SCHEDULE_MEETING_TOASTS_APP_PEOPLE_LIMIT:
        'SCHEDULE_MEETING_TOASTS_APP_PEOPLE_LIMIT',
    SCHEDULE_MEETING_TITLE_DEFAULT: 'SCHEDULE_MEETING_TITLE_DEFAULT',
    SCHEDULE_MEETING: 'SCHEDULE_MEETING',
    SCHEDULE_MEETING_CREATE_MEETING_TITLE:
        'SCHEDULE_MEETING_CREATE_MEETING_TITLE',
    SCHEDULE_MEETING_CREATE_MEETING_DATE:
        'SCHEDULE_MEETING_CREATE_MEETING_DATE',
    SCHEDULE_MEETING_CREATE_MEETING_START_TIME:
        'SCHEDULE_MEETING_CREATE_MEETING_START_TIME',
    SCHEDULE_MEETING_CREATE_MEETING_END_TIME:
        'SCHEDULE_MEETING_CREATE_MEETING_END_TIME',
    SCHEDULE_MEETING_SEND_REQUEST: 'SCHEDULE_MEETING_SEND_REQUEST',
    SCHEDULE_MEETING_REMOVE_YOURSELF_TOOLTIP:
        'SCHEDULE_MEETING_REMOVE_YOURSELF_TOOLTIP',
    SCHEDULE_MEETING_TOASTS_USER_LOSE_INVITE:
        'SCHEDULE_MEETING_TOASTS_USER_LOSE_INVITE',
    SCHEDULE_MEETING_TOASTS_INVITE_SUCCESS:
        'SCHEDULE_MEETING_TOASTS_INVITE_SUCCESS',
    SCHEDULE_MEETING_TOASTS_ERROR_OCCURRED:
        'SCHEDULE_MEETING_TOASTS_ERROR_OCCURRED',
    SCHEDULE_MEETING_REQUEST_ACCEPTED: 'SCHEDULE_MEETING_REQUEST_ACCEPTED',
    SCHEDULE_MEETING_REQUEST_DECLINED: 'SCHEDULE_MEETING_REQUEST_DECLINED',
    SCHEDULE_MEETING_REQUEST_RECEIVED: 'SCHEDULE_MEETING_REQUEST_RECEIVED',
    SCHEDULE_MEETING_UPCOMING: 'SCHEDULE_MEETING_UPCOMING',
    SCHEDULE_MEETING_REQUEST_FROM: 'SCHEDULE_MEETING_REQUEST_FROM',
    SCHEDULE_A_MEETING: 'SCHEDULE_A_MEETING',
    SCHEDULE_MEETING_INVITE: 'SCHEDULE_MEETING_INVITE',
    SCHEDULE_MEETING_STOP_REQUESTS: 'SCHEDULE_MEETING_STOP_REQUESTS',
    SCHEDULE_MEETING_STOP_DESCRIPTION: 'SCHEDULE_MEETING_STOP_DESCRIPTION',
    SCHEDULE_MEETING_MANAGE_BOOKMARKED: 'SCHEDULE_MEETING_MANAGE_BOOKMARKED',
    GENERAL_VIEW_LESS: 'GENERAL_VIEW_LESS',
    GENERAL_VIEW_MORE: 'GENERAL_VIEW_MORE',
    GENERAL_DECLINE: 'GENERAL_DECLINE',
    GENERAL_ACCEPT: 'GENERAL_ACCEPT',
    GENERAL_WAITING: 'GENERAL_WAITING',
    SCHEDULE_MEETING_INVITEES: 'SCHEDULE_MEETING_INVITEES',
    SCHEDULE_MEETING_POPUP_CANCEL_MEETING_MSG:
        'SCHEDULE_MEETING_POPUP_CANCEL_MEETING_MSG',
    SCHEDULE_MEETING_SUGGESTION_POPOVER: 'SCHEDULE_MEETING_SUGGESTION_POPOVER',
    SCHEDULE_MEETING_REMOVE: 'SCHEDULE_MEETING_REMOVE',
    SCHEDULE_MEETING_REMOVE_CONFIRM: 'SCHEDULE_MEETING_REMOVE_CONFIRM',
    SCHEDULE_MEETING_NEW: 'SCHEDULE_MEETING_NEW',
    SCHEDULE_MEETING_DND: 'SCHEDULE_MEETING_DND',
    SCHEDULE_MEETING_DND_DESC: 'SCHEDULE_MEETING_DND_DESC',
    SCHEDULE_MEETING_DND_NONE_TO_DISTURB:
        'SCHEDULE_MEETING_DND_NONE_TO_DISTURB',
    SCHEDULE_MEETING_DND_WANT_NO_REQUESTS:
        'SCHEDULE_MEETING_DND_WANT_NO_REQUESTS',
    SCHEDULE_MEETING_DND_YOU_CAN_DISABLE:
        'SCHEDULE_MEETING_DND_YOU_CAN_DISABLE',
    SCHEDULE_MEETING_DND_USE_TO_STOP_PEOPLE_REQUESTS:
        'SCHEDULE_MEETING_DND_USE_TO_STOP_PEOPLE_REQUESTS',
    SCHEDULE_MEETING_EMPTY_STATE: 'SCHEDULE_MEETING_EMPTY_STATE',
    MY_SCHEDULE_RHS_EMPTY_STATE: 'MY_SCHEDULE_RHS_EMPTY_STATE',
    MY_SCHEDULE_MOBILE_EMPTY_STATE: 'MY_SCHEDULE_MOBILE_EMPTY_STATE',
    MY_SCHEDULE_RHS_DISABLED: 'MY_SCHEDULE_RHS_DISABLED',
    MY_SCHEDULE_RHS_ON_STAGE: 'MY_SCHEDULE_RHS_ON_STAGE',
    MY_SCHEDULE_RHS_ON_BACKSTAGE: 'MY_SCHEDULE_RHS_ON_BACKSTAGE',
    MY_SCHEDULE_RHS_NEW_REQUEST: 'MY_SCHEDULE_RHS_NEW_REQUEST',
    SCHEDULE_MEETING_META_FROM_USER: 'SCHEDULE_MEETING_META_FROM_USER',
    GENERAL_MEETING: 'GENERAL_MEETING',
    SCHEDULE_MEETING_TOASTS_CANCELLED: 'SCHEDULE_MEETING_TOASTS_CANCELLED',
    SCHEDULE_MEETING_MANAGE: 'SCHEDULE_MEETING_MANAGE',
    MEETING_LEAVE: 'MEETING_LEAVE',
    MEETING_CHAT: 'MEETING_CHAT',
    SCHEDULE_MEETING_INVITE_OR_ACCEPT_SUB_TEXT:
        'SCHEDULE_MEETING_INVITE_OR_ACCEPT_SUB_TEXT',
    SCHEDULE_MEETING_DECLINED_SUB_TEXT: 'SCHEDULE_MEETING_DECLINED_SUB_TEXT',
    SCHEDULE_MEETING_CANCELLED_SUB_TEXT: 'SCHEDULE_MEETING_CANCELLED_SUB_TEXT',
    SCHEDULE: 'SCHEDULE',
    RHS_MESSAGES_SUB_HEADER: 'RHS_MESSAGES_SUB_HEADER',
    SESSION_NO_SESSION_MEETING_IN_SCHEDULE:
        'SESSION_NO_SESSION_MEETING_IN_SCHEDULE',
    TOASTS_USER_JOINED_MEETING: 'TOASTS_USER_JOINED_MEETING',
    TOASTS_USER_LEFT_MEETING: 'TOASTS_USER_LEFT_MEETING',
    TOASTS_USER_MEMEBER_AUDIO_MUTED: 'TOASTS_USER_MEMEBER_AUDIO_MUTED',
    TOASTS_USER_MEMEBER_VIDEO_MUTED: 'TOASTS_USER_MEMEBER_VIDEO_MUTED',
    TOASTS_USER_JOINED_BREAKOUT_ROOM: 'TOASTS_USER_JOINED_BREAKOUT_ROOM',
    TOASTS_USER_LEFT_BREAKOUT_ROOM: 'TOASTS_USER_LEFT_BREAKOUT_ROOM',
    START_BROADCAST_BTN: 'START_BROADCAST_BTN',
    END_BROADCAST_BTN: 'END_BROADCAST_BTN',
    ATTEND_LIVE_BROADCAST: 'ATTEND_LIVE_BROADCAST',
    LIVE: 'LIVE',
    CONVERSATION_INFO_BOOTH: 'CONVERSATION_INFO_BOOTH',
    BOOTH_CHAT_GENERAL_INFO: 'BOOTH_CHAT_GENERAL_INFO',
    GALLERY_VIEW_TOOLTIP_DESCRIPTION: 'GALLERY_VIEW_TOOLTIP_DESCRIPTION',
    ACTIVE_SPEAKER_VIEW_TOOLTIP_DESCRIPTION:
        'ACTIVE_SPEAKER_VIEW_TOOLTIP_DESCRIPTION',
    SPOTLIGHT_VIEW_TOOLTIP_DESCRIPTION: 'SPOTLIGHT_VIEW_TOOLTIP_DESCRIPTION',
    SESSION_NO_SESSION_MEETING_IN_SCHEDULE_DESC_1:
        'SESSION_NO_SESSION_MEETING_IN_SCHEDULE_DESC_1',
    SESSION_NO_SESSION_MEETING_IN_SCHEDULE_DESC_2:
        'SESSION_NO_SESSION_MEETING_IN_SCHEDULE_DESC_2',
    SESSION_NO_SESSION_MEETING_IN_SCHEDULE_DESC_3:
        'SESSION_NO_SESSION_MEETING_IN_SCHEDULE_DESC_3',
    SESSION_BOOKMARK_COUNT_TOOLTIP: 'SESSION_BOOKMARK_COUNT_TOOLTIP',
    SESSION_REMIND_ME: 'SESSION_REMIND_ME',
    SESSION_REMIND_ME_TOOLTIP_HEADER: 'SESSION_REMIND_ME_TOOLTIP_HEADER',
    SESSION_REMIND_ME_TOOLTIP_DESC: 'SESSION_REMIND_ME_TOOLTIP_DESC',
    SESSION_REMINDER_ADDED: 'SESSION_REMINDER_ADDED',
    SESSION_REMINDER_SENT: 'SESSION_REMINDER_SENT',
    SESSION_ADDED_MY_SCHEDULE: 'SESSION_ADDED_MY_SCHEDULE',
    ADD_BOOKMARK_REMINDER: 'ADD_BOOKMARK_REMINDER',
    SESSION_BOOKMARKED_ADDED_SCHEDULE: 'SESSION_BOOKMARKED_ADDED_SCHEDULE',
    SESSION_BOOKMARKED: 'SESSION_BOOKMARKED',
    SESSION_CALENDAR_INVITE: 'SESSION_CALENDAR_INVITE',
    SESSION_BOOKMARK_REMOVE: 'SESSION_BOOKMARK_REMOVE',
    SESSION_REMOVED_MY_SCHEDULE: 'SESSION_REMOVED_MY_SCHEDULE',
    SESSION_EMAIL_REMINDER_SENT: 'SESSION_EMAIL_REMINDER_SENT',
    SESSION_STARTING_IN_FEW_MINUTES: 'SESSION_STARTING_IN_FEW_MINUTES',
    SESSION_BOOKMARK_REMINDER: 'SESSION_BOOKMARK_REMINDER',
    SESSION_BOOKMARK_DESCRIPTION: 'SESSION_BOOKMARK_DESCRIPTION',
    SESSION_BOOKMARK_THANKYOU: 'SESSION_BOOKMARK_THANKYOU',
    SESSION_BOOKMARK_COUNT: 'SESSION_BOOKMARK_COUNT',
    SESSION_BOOKMARK: 'SESSION_BOOKMARK',
    SESSION_BOOKMARK_FEATURE_DESCRIPTION:
        'SESSION_BOOKMARK_FEATURE_DESCRIPTION',
    GENERAL_BOOKMARKED: 'GENERAL_BOOKMARKED',
    GENERAL_SHOW_MORE: 'GENERAL_SHOW_MORE',
    GENERAL_SHOW_LESS: 'GENERAL_SHOW_LESS',
    GENERAL_AVAILABLE: 'GENERAL_AVAILABLE',
    GENERAL_EXHIBITORS: 'GENERAL_EXHIBITORS',
    SESSION_NO_SESSION_MEETING_IN_SCHEDULE_DESC_4:
        'SESSION_NO_SESSION_MEETING_IN_SCHEDULE_DESC_4',
    SCHEDULE_MEETING_REMOVE_YOURSELF_TOOLTIP_2:
        'SCHEDULE_MEETING_REMOVE_YOURSELF_TOOLTIP_2',
    SESSION_SEARCH_SESSION_AND_MEETING: 'SESSION_SEARCH_SESSION_AND_MEETING',
    SESSIONS_SEARCH_RESULTS: 'SESSIONS_SEARCH_RESULTS',
    SESSION_LIVE_SESSIONS: 'SESSION_LIVE_SESSIONS',
    SESSION_UPCOMING_SESSIONS: 'SESSION_UPCOMING_SESSIONS',
    SESSION_COMPLETED_SESSIONS: 'SESSION_COMPLETED_SESSIONS',
    SESSION_MEETINGS: 'SESSION_MEETINGS',
    REMOVE_SESSION: 'REMOVE_SESSION',
    REMOVE_SESSION_CONFIRM: 'REMOVE_SESSION_CONFIRM',
    SHOW_ON_STAGE: 'SHOW_ON_STAGE',
    SHOWN_ON_STAGE: 'SHOWN_ON_STAGE',
    SHOW_AGAIN: 'SHOW_AGAIN',
    HIDE_FROM_STAGE: 'HIDE_FROM_STAGE',
    HIDE_POLL_FROM_STAGE: 'HIDE_POLL_FROM_STAGE',
    SHOW_POLL_ON_STAGE: 'SHOW_POLL_ON_STAGE',
    SHOW_POLL_ON_STAGE_QUESTION: 'SHOW_POLL_ON_STAGE_QUESTION',
    SHOW_POLL_ON_STAGE_QUESTION_SUBTEXT: 'SHOW_POLL_ON_STAGE_QUESTION_SUBTEXT',
    RHS_HIDE_VIDEO_FROM_STAGE: 'RHS_HIDE_VIDEO_FROM_STAGE',
    RHS_SHOW_VIDEO_ON_STAGE: 'RHS_SHOW_VIDEO_ON_STAGE',
    RHS_VIDEO_NO_CH_WARNING: 'RHS_VIDEO_NO_CH_WARNING',
    MISSING_OUT: 'MISSING_OUT',
    SM_PARTICIPANTS: 'SM_PARTICIPANTS',
    SESSION_MEETING_ENDED_BOOTH_TOAST_HEADER:
        'SESSION_MEETING_ENDED_BOOTH_TOAST_HEADER',
    SESSION_MEETING_ENDED_BOOTH_TOAST_SUBHEADER:
        'SESSION_MEETING_ENDED_BOOTH_TOAST_SUBHEADER',
    GENERAL_DURATION: 'GENERAL_DURATION',
    VIEW_SCHEDULE: 'VIEW_SCHEDULE',
    VIEW_SCHEDULE_TOOLTIP: 'VIEW_SCHEDULE_TOOLTIP',
    VIEW_SCHEDULE_TOOLTIP_CONF: 'VIEW_SCHEDULE_TOOLTIP_CONF',
    GENERAL_APPLIED_FILTERS: 'GENERAL_APPLIED_FILTERS',
    GENERAL_FILTER_BY: 'GENERAL_FILTER_BY',
    BACKSTAGE_BANNER_PRIMARY_TEXT_1: 'BACKSTAGE_BANNER_PRIMARY_TEXT_1',
    BACKSTAGE_BANNER_PRIMARY_TEXT_2: 'BACKSTAGE_BANNER_PRIMARY_TEXT_2',
    BACKSTAGE_BANNER_SECONDARY_TEXT_1: 'BACKSTAGE_BANNER_SECONDARY_TEXT_1',
    BACKSTAGE_BANNER_SECONDARY_TEXT_2: 'BACKSTAGE_BANNER_SECONDARY_TEXT_2',
    BACKSTAGE_MANAGE_SESSION_PRIMARY_TEXT_1:
        'BACKSTAGE_MANAGE_SESSION_PRIMARY_TEXT_1',
    BACKSTAGE_MANAGE_SESSION_PRIMARY_TEXT_2:
        'BACKSTAGE_MANAGE_SESSION_PRIMARY_TEXT_2',
    BACKSTAGE_MANAGE_SESSION_SECONDARY_TEXT_1:
        'BACKSTAGE_MANAGE_SESSION_SECONDARY_TEXT_1',
    BACKSTAGE_MANAGE_SESSION_SECONDARY_TEXT_2:
        'BACKSTAGE_MANAGE_SESSION_SECONDARY_TEXT_2',
    POLLS_POLL_TYPE: 'POLLS_POLL_TYPE',
    NO_LOUNGE_PRIMARY_TEXT: 'NO_LOUNGE_PRIMARY_TEXT',
    NO_LOUNGE_SECONDARY_TEXT: 'NO_LOUNGE_SECONDARY_TEXT',
    EVENT_POLLS: 'EVENT_POLLS',
    WEBINAR_POLLS: 'WEBINAR_POLLS',
    REGISTER_INTEREST_BUTTON: 'REGISTER_INTEREST_BUTTON',
    REGISTER_INTEREST_BUTTON_DESCRIPTION:
        'REGISTER_INTEREST_BUTTON_DESCRIPTION',
    BUTTON_TEXT: 'BUTTON_TEXT',
    OFFER_PREVIEW: 'OFFER_PREVIEW',
    BUTTON_TEXT_PLACEHOLDER: 'BUTTON_TEXT_PLACEHOLDER',
    OFFER_PREVIEW_PLACEHOLDER: 'OFFER_PREVIEW_PLACEHOLDER',
    PROVIDE_OFFERS: 'PROVIDE_OFFERS',
    PROVIDE_OFFERS_SUBTEXT: 'PROVIDE_OFFERS_SUBTEXT',
    OFFER_DETAILS: 'OFFER_DETAILS',
    OFFER_DETAILS_SENTENCE: 'OFFER_DETAILS_SENTENCE',
    OFFER_LINK: 'OFFER_LINK',
    OFFER_DETAILS_PLACEHOLDER: 'OFFER_DETAILS_PLACEHOLDER',
    ADD_LINK: 'ADD_LINK',
    LINK_TEXT: 'LINK_TEXT',
    LINK_TEXT_PLACEHOLDER: 'LINK_TEXT_PLACEHOLDER',
    LINK_URL: 'LINK_URL',
    LINK_URL_PLACEHOLDER: 'LINK_URL_PLACEHOLDER',
    EMPTY_FEED_AIRMEET_TEXT: 'EMPTY_FEED_AIRMEET_TEXT',
    EMPTY_FEED_DESCRIPTION_TEXT: 'EMPTY_FEED_DESCRIPTION_TEXT',
    SESSION_FEED_ACTION_UNPIN_TEXT: 'SESSION_FEED_ACTION_UNPIN_TEXT',
    SESSION_FEED_ACTION_REPLY_TEXT: 'SESSION_FEED_ACTION_REPLY_TEXT',
    SESSION_FEED_ACTION_SHOW_ON_STAGE_TEXT:
        'SESSION_FEED_ACTION_SHOW_ON_STAGE_TEXT',
    SESSION_FEED_ACTION_HIDE_FROM_STAGE_TEXT:
        'SESSION_FEED_ACTION_HIDE_FROM_STAGE_TEXT',
    SESSION_JOIN_NETWORKING_SESSION: 'SESSION_JOIN_NETWORKING_SESSION',
    SESSION_START_NETWORKING_SESSION: 'SESSION_START_NETWORKING_SESSION',
    SESSION_STOP_NETWORKING_SESSION: 'SESSION_STOP_NETWORKING_SESSION',
    SESSION_RESTART_NETWORKING_SESSION: 'SESSION_RESTART_NETWORKING_SESSION',
    START_BOOTH_SESSION_MODAL_TITLE: 'Start Booth session',
    START_BOOTH_SESSION_MODAL_SUBTITLE: 'START_BOOTH_SESSION_MODAL_SUBTITLE',
    END_BOOTH_SESSION_TOAST_MESSAGE_ORGANIZER:
        'END_BOOTH_SESSION_TOAST_MESSAGE_ORGANIZER',
    END_BOOTH_SESSION_MODAL_TITLE: 'END_BOOTH_SESSION_MODAL_TITLE',
    END_BOOTH_SESSION_MODAL_SUBTITLE: 'END_BOOTH_SESSION_MODAL_SUBTITLE',
    BOOTH_NOT_FOUND: 'BOOTH_NOT_FOUND',
    START_NETWORKING_SESSION_MODAL_TITLE:
        'START_NETWORKING_SESSION_MODAL_TITLE',
    START_NETWORKING_SESSION_MODAL_SUBTITLE:
        'START_NETWORKING_SESSION_MODAL_SUBTITLE',
    START_NETWORKING_SESSION_MODAL_DESCRIPTION:
        'START_NETWORKING_SESSION_MODAL_DESCRIPTION',
    START_NETWORKING_SESSION_TOAST_TITLE:
        'START_NETWORKING_SESSION_TOAST_TITLE',
    START_NETWORKING_SESSION_TOAST_MESSAGE:
        'START_NETWORKING_SESSION_TOAST_MESSAGE',
    END_NETWORKING_SESSION_TOAST_TITLE: 'END_NETWORKING_SESSION_TOAST_TITLE',
    END_NETWORKING_SESSION_TOAST_MESSAGE_ATTENDEE:
        'END_NETWORKING_SESSION_TOAST_MESSAGE_ATTENDEE',
    END_NETWORKING_SESSION_TOAST_MESSAGE_ORGANIZER:
        'END_NETWORKING_SESSION_TOAST_MESSAGE_ORGANIZER',
    END_NETWORKING_SESSION_MODAL_TITLE: 'END_NETWORKING_SESSION_MODAL_TITLE',
    END_NETWORKING_SESSION_MODAL_SUBTITLE:
        'END_NETWORKING_SESSION_MODAL_SUBTITLE',
    END_NETWORKING_SESSION_MODAL_DESCRIPTION:
        'END_NETWORKING_SESSION_MODAL_DESCRIPTION',
    NETWORKING_SESSION_MOBILE_LABEL: 'NETWORKING_SESSION_MOBILE_LABEL',
    ORG_COMMUNICATION_TITLE: 'ORG_COMMUNICATION_TITLE',
    ORG_COMMUNICATION_TITLE_PERSISTENT_STAGE:
        'ORG_COMMUNICATION_TITLE_PERSISTENT_STAGE',
    ORG_COMMUNICATION_BUTTON_TEXT: 'ORG_COMMUNICATION_BUTTON_TEXT',
    ORG_COMMUNICATION_ALERT_TEXT: 'ORG_COMMUNICATION_ALERT_TEXT',
    BOOTH_FEED_NO_FEED: 'BOOTH_FEED_NO_FEED',
    BOOTH_INVALID_ID_TOOL_MSG: 'BOOTH_INVALID_ID_TOOL_MSG',
    CLICK_HERE_TO_JOIN: 'CLICK_HERE_TO_JOIN',
    GENERAL_SHORTCUT_KEYBOARD: 'GENERAL_SHORTCUT_KEYBOARD',
    REACTION_CLAPS: 'REACTION_CLAPS',
    REACTION_LOVE: 'REACTION_LOVE_IT',
    REACTION_LIKE: 'REACTION_LIKE',
    REACTION_SUPERB: 'REACTION_SUPERB',
    REACTION_LAUGH: 'REACTION_LAUGH',
    REACTION_CELEBRATION: 'REACTION_CELEBRATE',
    REACTION_GREAT: 'REACTION_GREAT',
    REACTION_WOW: 'REACTION_WOW',
    OFFER_PREVIEW_HELP_TEXT: 'OFFER_PREVIEW_HELP_TEXT',
    OFFER_DETAILS_HELP_TEXT: 'OFFER_DETAILS_HELP_TEXT',
    SPEAKER_JOINED_BACKSTAGE_PROMPT_MESSAGE:
        'SPEAKER_JOINED_BACKSTAGE_PROMPT_MESSAGE',
    VIEW_OFFER_DETAILS: 'VIEW_OFFER_DETAILS',
    MANDATORY_FIELD: 'MANDATORY_FIELD',
    VIDEO_ERROR_STATE: 'VIDEO_ERROR_STATE',
    FROM_EXHIBITOR: 'FROM_EXHIBITOR',
    THANK_YOU: 'THANK_YOU',
    THANK_YOU_MSG: 'Thank you for registering',
    EMAIL_SHARED_MSG: 'EMAIL_SHARED_MSG',
    STAGE_LIVE_TRANSLATION_ICON_TEXT: 'STAGE_LIVE_TRANSLATION_ICON_TEXT',
    STAGE_LIVE_TRANSLATION_ICON_TOOLTIP: 'STAGE_LIVE_TRANSLATION_ICON_TOOLTIP',
    STAGE_MUTE_SPEAKER_AUDIO_TOOLTIP: 'STAGE_MUTE_SPEAKER_AUDIO_TOOLTIP',
    STAGE_UNMUTE_SPEAKER_AUDIO_TOOLTIP: 'STAGE_UNMUTE_SPEAKER_AUDIO_TOOLTIP',
    STAGE_JOINING_LIVE_STAGE: 'STAGE_JOINING_LIVE_STAGE',
    STAGE_PEOPLE_CAN_HEAR_YOU: 'STAGE_PEOPLE_CAN_HEAR_YOU',
    STAGE_JOINING_THE_BACKSTAGE: 'STAGE_JOINING_THE_BACKSTAGE',
    STAGE_MUTED_ADJUST_VOLUME: 'STAGE_MUTED_ADJUST_VOLUME',
    GENERAL_SCREENSHARE: 'GENERAL_SCREENSHARE',
    STAGE_LISTENING_TO_BACKSTAGE: 'STAGE_LISTENING_TO_BACKSTAGE',
    STAGE_LISTENING_TO_STAGE: 'STAGE_LISTENING_TO_STAGE',
    BACKSTAGE_IS_MUTED: 'BACKSTAGE_IS_MUTED',
    STAGE_IS_MUTED: 'STAGE_IS_MUTED',
    STAGE_TOGGLE_SPEAKER_AUDIO_TOOLTIP_MESSAGE:
        'STAGE_TOGGLE_SPEAKER_AUDIO_TOOLTIP_MESSAGE',
    MUTE_STAGE: 'MUTE_STAGE',
    UNMUTE_STAGE: 'UNMUTE_STAGE',
    MUTE_BACKSTAGE: 'MUTE_BACKSTAGE',
    UNMUTE_BACKSTAGE: 'UNMUTE_BACKSTAGE',
    STAGE_VOLUME_CONTROL: 'STAGE_VOLUME_CONTROL',
    BACKSTAGE_VOLUME_CONTROL: 'BACKSTAGE_VOLUME_CONTROL',
    ADJUST_STAGE_VOLUME_LEVEL: 'ADJUST_STAGE_VOLUME_LEVEL',
    ADJUST_BACKSTAGE_VOLUME_LEVEL: 'ADJUST_BACKSTAGE_VOLUME_LEVEL',
    CANNOT_UNMUTE_STAGE: 'CANNOT_UNMUTE_STAGE',
    CANNOT_MUTE_STAGE_MIC_ON: 'CANNOT_MUTE_STAGE_MIC_ON',
    STAGE_HELP: 'STAGE_HELP',
    DM_NOTIFICATION_UNREAD_MESSAGES: 'DM_NOTIFICATION_UNREAD_MESSAGES',
    CHAT: 'GENERAL_CHAT',
    ENTER_EMAIL_ADDRESS: 'ENTER_EMAIL_ADDRESS',
    GENERAL_MAYBE_LATER: 'GENERAL_MAYBE_LATER',
    HERE_IS_YOUR_REWARD: 'HERE_IS_YOUR_REWARD',
    INTRO_TEXT: 'INTRO_TEXT',
    INTRO_TEXT_PLACEHOLDER: 'INTRO_TEXT_PLACEHOLDER',
    YOU_ARE_VISIBLE_TO_ATTENDEES_TEXT: 'YOU_ARE_VISIBLE_TO_ATTENDEES_TEXT',
    METER_LIVE_ALERT_UPGRADE: 'METER_LIVE_ALERT_UPGRADE',
    METER_LIVE_ALERT_ALLOW_REGISTRATIONS:
        'METER_LIVE_ALERT_ALLOW_REGISTRATIONS',
    METER_LIVE_ALERT_CONTACT_SALES: 'METER_LIVE_ALERT_CONTACT_SALES',
    METER_LIVE_ALERT_GENERAL_TXT: 'METER_LIVE_ALERT_GENERAL_TXT',
    METER_LIVE_ALERT_PRIMARY_TXT2: 'METER_LIVE_ALERT_PRIMARY_TXT2',
    METER_LIVE_ALERT_SECONDARY_TXT: 'METER_LIVE_ALERT_SECONDARY_TXT',
    METER_LIVE_ALERT_THIRD_TXT_FIRST: 'METER_LIVE_ALERT_THIRD_TXT_FIRST',
    METER_LIVE_ALERT_THIRD_TXT_SECOND: 'METER_LIVE_ALERT_THIRD_TXT_SECOND',
    METER_LIVE_ALERT_THIRD_TXT_THIRD: 'METER_LIVE_ALERT_THIRD_TXT_THIRD',
    STAGE_HIDDEN_PEOPLE: 'STAGE_HIDDEN_PEOPLE',
    STAGE_HIDDEN_FROM_STAGE: 'STAGE_HIDDEN_FROM_STAGE',
    YET_TO_JOIN_STAGE: 'YET_TO_JOIN_STAGE',
    YET_TO_JOIN_EVENT: 'YET_TO_JOIN_EVENT',
    NOT_IN_THE_EVENT: 'NOT_IN_THE_EVENT',
    STAGE_HIDDEN_USER_LIMIT: 'STAGE_HIDDEN_USER_LIMIT',
    STAGE_USER_LIMIT_REACHED_FOR_RAISE_HAND:
        'STAGE_USER_LIMIT_REACHED_FOR_RAISE_HAND',
    PERSISTENT_BACKSTAGE_USER_LIMIT_REACHED_FOR_RAISE_HAND:
        'PERSISTENT_BACKSTAGE_USER_LIMIT_REACHED_FOR_RAISE_HAND',
    STAGE_USER_LIMIT_REACHED_FOR_SPEAKER:
        'STAGE_USER_LIMIT_REACHED_FOR_SPEAKER',
    STAGE_USER_LIMIT_REACHED_FOR_SPEAKER_PERSISTENT_BS:
        'STAGE_USER_LIMIT_REACHED_FOR_SPEAKER_PERSISTENT_BS',
    PERSISTENT_BS_ALLOWED_LIMIT_TO_MOVE_USER_TO_STAGE:
        'PERSISTENT_BS_ALLOWED_LIMIT_TO_MOVE_USER_TO_STAGE',
    PERSISTENT_BS_ALLOWED_LIMIT_TO_MOVE_USERS_TO_STAGE:
        'PERSISTENT_BS_ALLOWED_LIMIT_TO_MOVE_USERS_TO_STAGE',
    PERSISTENT_BS_SCREEN_CAN_NOT_MOVED_STAGE_CHECKBOX:
        'PERSISTENT_BS_SCREEN_CAN_NOT_MOVED_STAGE_CHECKBOX',
    PERSISTENT_BS_SCREEN_CAN_NOT_MOVED_BACKSTAGE_CHECKBOX:
        'PERSISTENT_BS_SCREEN_CAN_NOT_MOVED_BACKSTAGE_CHECKBOX',
    PERSISTENT_BS_ITEM_CAN_NOT_MOVED_STAGE_CHECKBOX:
        'PERSISTENT_BS_ITEM_CAN_NOT_MOVED_STAGE_CHECKBOX',
    STAGE_HIDDEN_USER_TRAY_ZERO_STATE: 'STAGE_HIDDEN_USER_TRAY_ZERO_STATE',
    STAGE_HIDDEN_CAMERA_DISABLE: 'STAGE_HIDDEN_CAMERA_DISABLE',
    STAGE_HIDDEN_CAMERA_DISABLE_HOST: 'STAGE_HIDDEN_CAMERA_DISABLE_HOST',
    STAGE_HIDDEN_MIC_DISABLE: 'STAGE_HIDDEN_MIC_DISABLE',
    STAGE_HIDDEN_MIC_DISABLE_HOST: 'STAGE_HIDDEN_MIC_DISABLE_HOST',
    STAGE_HIDDEN_CAM_MIC_DISABLE: 'STAGE_HIDDEN_CAM_MIC_DISABLE',
    STAGE_SHOW_USER_TOAST_SELF: 'STAGE_SHOW_USER_TOAST_SELF',
    STAGE_SHOW_USER_TOAST: 'STAGE_SHOW_USER_TOAST',
    STAGE_SHOW_USER_MIC_CAMERA_MESSAGE: 'STAGE_SHOW_USER_MIC_CAMERA_MESSAGE',
    STAGE_HIDDEN_USER_TOOLTIP_TITLE: 'STAGE_HIDDEN_USER_TOOLTIP_TITLE',
    STAGE_HIDDEN_USER_TOOLTIP_MESSAGE: 'STAGE_HIDDEN_USER_TOOLTIP_MESSAGE',
    STAGE_HIDDEN_USER_HOST_MESSAGE: 'STAGE_HIDDEN_USER_HOST_MESSAGE',
    STAGE_HIDDEN_BUTTON_HOVER_TITLE: 'STAGE_HIDDEN_BUTTON_HOVER_TITLE',
    STAGE_HIDDEN_BUTTON_HOVER_MESSAGE: 'STAGE_HIDDEN_BUTTON_HOVER_MESSAGE',
    STAGE_EVERYONE_CAN_HEAR_HOVER_TITLE: 'STAGE_EVERYONE_CAN_HEAR_HOVER_TITLE',
    STAGE_EVERYONE_CAN_HEAR_HOVER_MESSAGE:
        'STAGE_EVERYONE_CAN_HEAR_HOVER_MESSAGE',
    LET_CONNECT: 'LET_CONNECT',
    IM_INTERESTED_BUTTON: 'IM_INTERESTED_BUTTON',
    MODERATE_QUESTIONS: 'MODERATE_QUESTIONS',
    QNA_SETTINGS: 'QNA_SETTINGS',
    MODERATE_FEED: 'MODERATE_FEED',
    PROFANITY_FILTERING: 'PROFANITY_FILTERING',
    PROFANITY_FILTERING_TOOLTIP_CONTENT: 'PROFANITY_FILTERING_TOOLTIP_CONTENT',
    MODERATE_QUESTIONS_TOOLTIP: 'MODERATE_QUESTIONS_TOOLTIP',
    MODERATE_FEED_TOOLTIP: 'MODERATE_FEED_TOOLTIP',
    MODERATE_FEED_REVIEW_TOOLTIP: 'MODERATE_FEED_REVIEW_TOOLTIP',
    MODERATE_FEED_SHOW: 'MODERATE_FEED_SHOW',
    MODERATE_CHAT_SHOW: 'MODERATE_CHAT_SHOW',
    MODERATE_FEED_HELD: 'MODERATE_FEED_HELD',
    MODERATION_ON_TITLE: 'MODERATION_ON_TITLE',
    MODERATION_ON_DESC_HOST: 'MODERATION_ON_DESC_HOST',
    MODERATION_ON_DESC_ATTENDEE: 'MODERATION_ON_DESC_ATTENDEE',
    MODERATION_PENDING_APPROVAL: 'MODERATION_PENDING_APPROVAL',
    MODERATION_ONLY_VISIBLE: 'MODERATION_ONLY_VISIBLE',
    MODERATION_AWAITING_APPROVAL: 'MODERATION_AWAITING_APPROVAL',
    MODERATION_PLEASE_APPROVE: 'MODERATION_PLEASE_APPROVE',
    MODERATION_REJECTED: 'MODERATION_REJECTED',
    MODERATION_ONLY_VISIBLE_REJECTED: 'MODERATION_ONLY_VISIBLE_REJECTED',
    RICH_PROFILE_SEE_FULL_PROFILE: 'RICH_PROFILE_SEE_FULL_PROFILE',
    RICH_PROFILE_NUDGE_MESSAGE: 'RICH_PROFILE_NUDGE_MESSAGE',
    RICH_PROFILE_NUDGE_LINK: 'RICH_PROFILE_NUDGE_LINK',
    RICH_PROFILE_INTRODUCTION: 'RICH_PROFILE_INTRODUCTION',
    RICH_PROFILE_INTRODUCTION_SUBTEXT: 'RICH_PROFILE_INTRODUCTION_SUBTEXT',
    RICH_PROFILE_INTRODUCTION_SUBLABEL: 'RICH_PROFILE_INTRODUCTION_SUBLABEL',
    RICH_PROFILE_INTRODUCTION_PLACEHOLDER:
        'RICH_PROFILE_INTRODUCTION_PLACEHOLDER',
    RICH_PROFILE_INTERESTS: 'RICH_PROFILE_INTERESTS',
    RICH_PROFILE_INTERESTS_SUBTEXT: 'RICH_PROFILE_INTERESTS_SUBTEXT',
    RICH_PROFILE_INTERESTS_SUBLABEL: 'RICH_PROFILE_INTERESTS_SUBLABEL',
    RICH_PROFILE_INTERESTS_PLACEHOLDER: 'RICH_PROFILE_INTERESTS_PLACEHOLDER',
    RICH_PROFILE_EDIT_PROFILE: 'RICH_PROFILE_EDIT_PROFILE',
    RICH_PROFILE_COMPLETE_PROFILE: 'RICH_PROFILE_COMPLETE_PROFILE',
    RICH_PROFILE_NAME_PLACEHOLDER: 'RICH_PROFILE_NAME_PLACEHOLDER',
    RICH_PROFILE_DESIGNATION_PLACEHOLDER:
        'RICH_PROFILE_DESIGNATION_PLACEHOLDER',
    RICH_PROFILE_COMPANY_PLACEHOLDER: 'RICH_PROFILE_COMPANY_PLACEHOLDER',
    RICH_PROFILE_CITY_PLACEHOLDER: 'RICH_PROFILE_CITY_PLACEHOLDER',
    RICH_PROFILE_COUNTRY_PLACEHOLDER: 'RICH_PROFILE_COUNTRY_PLACEHOLDER',
    RICH_PROFILE_EXTERNAL_LINKS: 'RICH_PROFILE_EXTERNAL_LINKS',
    RICH_PROFILE_EXTERNAL_LINKS_SUBTEXT: 'RICH_PROFILE_EXTERNAL_LINKS_SUBTEXT',
    RICH_PROFILE_LINKEDIN_LABEL: 'RICH_PROFILE_LINKEDIN_LABEL',
    RICH_PROFILE_LINKEDIN_PLACEHOLDER: 'RICH_PROFILE_LINKEDIN_PLACEHOLDER',
    RICH_PROFILE_TWITTER_LABEL: 'RICH_PROFILE_TWITTER_LABEL',
    RICH_PROFILE_X_LABEL: 'RICH_PROFILE_X_LABEL',
    RICH_PROFILE_TWITTER_PLACEHOLDER: 'RICH_PROFILE_TWITTER_PLACEHOLDER',
    RICH_PROFILE_FACEBOOK_LABEL: 'RICH_PROFILE_FACEBOOK_LABEL',
    RICH_PROFILE_FACEBOOK_PLACEHOLDER: 'RICH_PROFILE_FACEBOOK_PLACEHOLDER',
    RICH_PROFILE_INSTAGRAM_LABEL: 'RICH_PROFILE_INSTAGRAM_LABEL',
    RICH_PROFILE_INSTAGRAM_PLACEHOLDER: 'RICH_PROFILE_INSTAGRAM_PLACEHOLDER',
    RICH_PROFILE_WEBSITE_LABEL: 'RICH_PROFILE_WEBSITE_LABEL',
    RICH_PROFILE_WEBSITE_PLACEHOLDER: 'RICH_PROFILE_WEBSITE_PLACEHOLDER',
    RICH_PROFILE_PRO_TIPS: 'RICH_PROFILE_PRO_TIPS',
    RICH_PROFILE_PICTURE_TIP: 'RICH_PROFILE_PICTURE_TIP',
    RICH_PROFILE_ADD_PROFILE_PIC: 'RICH_PROFILE_ADD_PROFILE_PIC',
    RICH_PROFILE_INTEREST_TIP: 'RICH_PROFILE_INTEREST_TIP',
    RICH_PROFILE_INTEREST_DESC: 'RICH_PROFILE_INTEREST_DESC',
    RICH_PROFILE_TOPIC_TITLE: 'RICH_PROFILE_TOPIC_TITLE',
    RICH_PROFILE_TOPIC_SUB_TITLE: 'RICH_PROFILE_TOPIC_SUB_TITLE',
    GENERAL_CLEAR_ALL: 'GENERAL_CLEAR_ALL',
    BACKSTAGE_LEAVE_PROMPT_HEADING: 'BACKSTAGE_LEAVE_PROMPT_HEADING',
    BACKSTAGE_LEAVE_PROMPT_MESSAGE: 'BACKSTAGE_LEAVE_PROMPT_MESSAGE',
    STAGE_LEAVE_PROMPT_HEADING: 'STAGE_LEAVE_PROMPT_HEADING',
    STAGE_LEAVE_PROMPT_MESSAGE: 'STAGE_LEAVE_PROMPT_MESSAGE',
    GENERAL_MORE: 'GENERAL_MORE',
    SPEED_NETWORKING_STOP_TITLE: 'SPEED_NETWORKING_STOP_TITLE',
    APPROVE: 'APPROVE',
    REJECT: 'REJECT',
    AUTOAPPROVE_QA: 'AUTOAPPROVE_QA',
    MODERATION_OFF: 'MODERATION_OFF',
    TABLE_WAIT_MESSAGE: 'TABLE_WAIT_MESSAGE',
    SESSION_RHS_CONVERSATIONS_Q_A_MY_QUES:
        'SESSION_RHS_CONVERSATIONS_Q_A_MY_QUES',
    QUESTIONS: 'QUESTIONS',
    SENT: 'SENT',
    WILL_BE_SENT_SHORTLY: 'WILL_BE_SENT_SHORTLY',
    TOO_MANY_ITEMS_PLEASE_WAIT: 'TOO_MANY_ITEMS_PLEASE_WAIT',
    SESSION_LIMIT_TIME_CHECK: 'SESSION_LIMIT_TIME_CHECK',
    SESSION_LIMIT_AUTOEND_IN: 'SESSION_LIMIT_AUTOEND_IN',
    SESSION_LIMIT_AUTO_ENDING: 'SESSION_LIMIT_AUTO_ENDING',
    SESSION_LIMIT_SUMMARIZE_CLOSURE: 'SESSION_LIMIT_SUMMARIZE_CLOSURE',
    SESSION_LIMIT_ENDS_IN: 'SESSION_LIMIT_ENDS_IN',
    SESSION_LIMIT_TIME_TO_END: 'SESSION_LIMIT_TIME_TO_END',
    SESSION_LIMIT_PLEASE_END_NOW: 'SESSION_LIMIT_PLEASE_END_NOW',
    SESSION_LIMIT_WILL_AUTO_END_IN: 'SESSION_LIMIT_WILL_AUTO_END_IN',
    GENERAL_OPEN: 'GENERAL_OPEN',
    GENERAL_ENDED: 'GENERAL_ENDED',
    SN_BANNER_PEOPLE_CONVERSING: 'SN_BANNER_PEOPLE_CONVERSING',
    SN_BANNER_PERSON_CONVERSING: 'SN_BANNER_PERSON_CONVERSING',
    BANNER_PERSON_WAITING: 'BANNER_PERSON_WAITING',
    SN_BANNER_MINS_AVAILABLE: 'SN_BANNER_MINS_AVAILABLE',
    TABLE_DEFAULT_TITLE: 'TABLE_DEFAULT_TITLE',
    TABLE_FULL: 'TABLE_FULL',
    TABLE_SEATS_LEFT: 'TABLE_SEATS_LEFT',
    TABLE_RESERVED_SEAT_LEFT: 'TABLE_RESERVED_SEAT_LEFT',
    TABLE_SUPPORTED_IMAGE_FORMATS: 'TABLE_SUPPORTED_IMAGE_FORMATS',
    TABLE_TITLE_ERROR_MSG: 'TABLE_TITLE_ERROR_MSG',
    SWITCH_TO_PORTRAIT: 'SWITCH_TO_PORTRAIT',
    ROTATE_YOUR_PHONE: 'ROTATE_YOUR_PHONE',
    SPEED_NETWORKING_EXTEND_TIME: 'SPEED_NETWORKING_EXTEND_TIME',
    SPEED_NETWORKING_ENDS_IN_MINUTES: 'SPEED_NETWORKING_ENDS_IN_MINUTES',
    SPEED_NETWORKING_ENDS_IN_SECONDS: 'SPEED_NETWORKING_ENDS_IN_SECONDS',
    ALLOW_CAMERA_AND_MIC_HEADER: 'ALLOW_CAMERA_AND_MIC_HEADER',
    ALLOW_CAMERA_AND_MIC_SUBHEADER: 'ALLOW_CAMERA_AND_MIC_SUBHEADER',
    GENERAL_ALLOW: 'GENERAL_ALLOW',
    PERMISSION_DENIED_SUBHEADER: 'PERMISSION_DENIED_SUBHEADER',
    MORE_EXHIBITORS_AVAILABLE_TEXT: 'MORE_EXHIBITORS_AVAILABLE_TEXT',
    IS_AVAILABLE_TEXT: 'IS_AVAILABLE_TEXT',
    UNSUPPORTED_BROWSER_SESSION_WILL_START_SOON:
        'UNSUPPORTED_BROWSER_SESSION_WILL_START_SOON',
    UNSUPPORTED_BROWSER_USER_MISSING_OUT:
        'UNSUPPORTED_BROWSER_USER_MISSING_OUT',
    UNSUPPORTED_BROWSER_USER_MISSING_OUT_SUBTITLE:
        'UNSUPPORTED_BROWSER_USER_MISSING_OUT_SUBTITLE',
    UNSUPPORTED_BROWSER_USER_MISSING_OUT_SUBTEXT2:
        'UNSUPPORTED_BROWSER_USER_MISSING_OUT_SUBTEXT2',
    RHS_CUSTOMIZE_SCHEDULE_MEETING_TITLE:
        'RHS_CUSTOMIZE_SCHEDULE_MEETING_TITLE',
    RHS_CUSTOMIZE_SCHEDULE_MEETING_SUBTITLE:
        'RHS_CUSTOMIZE_SCHEDULE_MEETING_SUBTITLE',
    RHS_CUSTOMIZE_DISCARD_BTN: 'RHS_CUSTOMIZE_DISCARD_BTN',
    RHS_CUSTOMIZE_DISCARD_TITLE: 'RHS_CUSTOMIZE_DISCARD_TITLE',
    RHS_CUSTOMIZE_DISCARD_SUBTITLE: 'RHS_CUSTOMIZE_DISCARD_SUBTITLE',
    UNSUPPORTED_BROWSER_USER_MISSING_OUT_V2:
        'UNSUPPORTED_BROWSER_USER_MISSING_OUT_V2',
    UNSUPPORTED_BROWSER_USER_MISSING_OUT_SUBTITLE_V2:
        'UNSUPPORTED_BROWSER_USER_MISSING_OUT_SUBTITLE_V2',
    UNSUPPORTED_BROWSER_USER_MISSING_OUT_BOOTH_SUBTITLE_V2:
        'UNSUPPORTED_BROWSER_USER_MISSING_OUT_BOOTH_SUBTITLE_V2',
    MEETUP_DISABLE_REPLAY: 'MEETUP_DISABLE_REPLAY',
    MEETUP_DISABLE_REPLAY_BACKSTAGE: 'MEETUP_DISABLE_REPLAY_BACKSTAGE',
    CONFIGURE_REPLAY_TOOLTIP: 'CONFIGURE_REPLAY_TOOLTIP',
    INVITE_TO_TABLE_NOT_NOW: 'INVITE_TO_TABLE_NOT_NOW',
    INVITE_TO_TABLE_JOIN_DISCUSSION: 'INVITE_TO_TABLE_JOIN_DISCUSSION',
    INVITE_TO_TABLE_LEAVE_SESSION: 'INVITE_TO_TABLE_LEAVE_SESSION',
    INVITE_TO_TABLE_JOIN_IN_LOUNGE: 'INVITE_TO_TABLE_JOIN_IN_LOUNGE',
    INVITE_TO_TABLE_JOIN_IN_LOUNGE_DYN: 'INVITE_TO_TABLE_JOIN_IN_LOUNGE_DYN',
    INVITE_TO_TABLE_SEND_INVITE: 'INVITE_TO_TABLE_SEND_INVITE',
    INVITE_TO_TABLE_PUBLISH_INVITE: 'INVITE_TO_TABLE_PUBLISH_INVITE',
    INVITE_TO_TABLE_SHARE_INVITE_TEXT: 'INVITE_TO_TABLE_SHARE_INVITE_TEXT',
    INVITE_TO_TABLE_SHARE_INVITE_TEXT_MEETUP:
        'INVITE_TO_TABLE_SHARE_INVITE_TEXT_MEETUP',
    INVITE_TO_TABLE_INVITE_PREVIEW: 'INVITE_TO_TABLE_INVITE_PREVIEW',
    INVITE_TO_TABLE_RECENTLY_SENT_TOOLTIP:
        'INVITE_TO_TABLE_RECENTLY_SENT_TOOLTIP',
    TABLE_IS_FULL_UPDATED: 'TABLE_IS_FULL_UPDATED',
    INVITE_TO_TABLE_INVITE_SENT: 'INVITE_TO_TABLE_INVITE_SENT',
    INVITE_TO_TABLE_ALREADY_ON_TABLE: 'INVITE_TO_TABLE_ALREADY_ON_TABLE',
    INVITE_TO_TABLE: 'INVITE_TO_TABLE',
    INVITE_TO_TABLE_INVITE_PEOPLE: 'INVITE_TO_TABLE_INVITE_PEOPLE',
    INVITE_TO_TABLE_SELECT_PEOPLE: 'INVITE_TO_TABLE_SELECT_PEOPLE',
    INVITE_TO_TABLE_INDIVIDUALS: 'INVITE_TO_TABLE_INDIVIDUALS',
    INVITE_TO_TABLE_PUBLIC: 'INVITE_TO_TABLE_PUBLIC',
    INVITE_TO_TABLE_PUBLISHED_IN_FEED: 'INVITE_TO_TABLE_PUBLISHED_IN_FEED',
    INVITE_TO_TABLE_INTERESTED_CAN_JOIN: 'INVITE_TO_TABLE_INTERESTED_CAN_JOIN',
    INVITE_TO_TABLE_PREVIEW: 'INVITE_TO_TABLE_PREVIEW',
    INVITE_TO_TABLE_SEATS: 'INVITE_TO_TABLE_SEATS',
    INVITE_TO_TABLE_SEAT: 'INVITE_TO_TABLE_SEAT',
    INVITE_TO_TABLE_NEW_INVITE: 'INVITE_TO_TABLE_NEW_INVITE',
    INVITE_TO_TABLE_NEW_INVITES: 'INVITE_TO_TABLE_NEW_INVITES',
    INVITE_TO_TABLE_INVITED_TO_JOIN: 'INVITE_TO_TABLE_INVITED_TO_JOIN',
    INVITE_TO_TABLE_TABLE_FULL: 'INVITE_TO_TABLE_TABLE_FULL',
    INVITE_TO_TABLE_NOT_AVAILABLE: 'INVITE_TO_TABLE_NOT_AVAILABLE',
    INVITE_TO_TABLE_EXPLORE_LOUNGE: 'INVITE_TO_TABLE_EXPLORE_LOUNGE',
    WATCH_REPLAY_PECA_TITLE: 'WATCH_REPLAY_PECA_TITLE',
    WATCH_REPLAY_PECA_DESC: 'WATCH_REPLAY_PECA_DESC',
    SESSION_REPLAY_HEADING: 'SESSION_REPLAY_HEADING',
    NO_CHATS: 'NO_CHATS',
    NO_QUESTIONS: 'NO_QUESTIONS',
    VIEW_SESSIONS: 'VIEW_SESSIONS',
    NO_DM: 'NO_DM',
    RESTRICTED_MODE_TITLE_TEXT: 'RESTRICTED_MODE_TITLE_TEXT',
    RESTRICTED_MODE_SUBTITLE_TEXT_1: 'RESTRICTED_MODE_SUBTITLE_TEXT_1',
    RESTRICTED_MODE_SUBTITLE_TEXT_2: 'RESTRICTED_MODE_SUBTITLE_TEXT_2',
    ACCESS_RESTRICTED_MODE_TOAST_TITLE: 'ACCESS_RESTRICTED_MODE_TOAST_TITLE',
    ACCESS_RESTRICTED_MODE_TOAST_MESSAGE:
        'ACCESS_RESTRICTED_MODE_TOAST_MESSAGE',
    ACCESS_REVOKED_MODE_TOAST_TITLE: 'ACCESS_REVOKED_MODE_TOAST_TITLE',
    ACCESS_REVOKED_MODE_TOAST_MESSAGE: 'ACCESS_REVOKED_MODE_TOAST_MESSAGE',
    ALLOW_SCHEDULE_MEETING_REQUESTS: 'ALLOW_SCHEDULE_MEETING_REQUESTS',
    STAGE_CHAT_TITLE: 'STAGE_CHAT_TITLE',
    STAGE_CHAT_SUBTITLE: 'STAGE_CHAT_SUBTITLE',
    STAGE_CHAT_NEW_MESSAGE_TEXT: 'STAGE_CHAT_NEW_MESSAGE_TEXT',
    STAGE_CHAT_NEW_MESSAGE_DEFAULT_TEXT: 'STAGE_CHAT_NEW_MESSAGE_DEFAULT_TEXT',
    STAGE_CHAT_TOOLTIP_MESSAGE: 'STAGE_CHAT_TOOLTIP_MESSAGE',

    LEADERBOARD_ACTIVITIES_ORGANIZER_SUBTEXT:
        'LEADERBOARD_ACTIVITIES_ORGANIZER_SUBTEXT',
    LEADERBOARD_ACTIVITIES_ATTENDEE_SUBTEXT:
        'LEADERBOARD_ACTIVITIES_ATTENDEE_SUBTEXT',
    LEADERBOARD_FEED_HEADER_SUBTEXT: 'LEADERBOARD_FEED_HEADER_SUBTEXT',
    REFRESH: 'REFRESH',
    RANKING: 'RANKING',
    ACTIVITIES: 'ACTIVITIES',
    HISTORY: 'HISTORY',
    LEADERBOARD_ORGANIZER_ENDED_TEXT: 'LEADERBOARD_ORGANIZER_ENDED_TEXT',
    LEADERBOARD_ATTENDEE_ENDED_TEXT: 'LEADERBOARD_ATTENDEE_ENDED_TEXT',
    LEADERBOARD_OVERLAY_DESC: 'LEADERBOARD_OVERLAY_DESC',
    VIEW_PRIZES: 'VIEW_PRIZES',
    END_LEADERBOARD_TOOLTIP: 'END_LEADERBOARD_TOOLTIP',
    ANNOUNCE_LEADERBOARD_TOOLTIP: 'ANNOUNCE_LEADERBOARD_TOOLTIP',
    END_LEADERBOARD: 'END_LEADERBOARD',
    ANNOUNCE_WINNERS: 'ANNOUNCE_WINNERS',
    END_LEADERBOARD_SUBTEXT: 'END_LEADERBOARD_SUBTEXT',
    END_LEADERBOARD_CONFIRMATION: 'END_LEADERBOARD_CONFIRMATION',
    LEADERBOARD: 'LEADERBOARD',
    SHARE_LEADERBOARD_SHARE_TITLE: 'SHARE_LEADERBOARD_SHARE_TITLE',
    SHARE_LEADERBOARD_SHARE_SUBTEXT: 'SHARE_LEADERBOARD_SHARE_SUBTEXT',
    ANNOUNCE_LEADERBOARD_TOAST: 'ANNOUNCE_LEADERBOARD_TOAST',
    END_LEADERBOARD_TOAST_SUBTEXT: 'END_LEADERBOARD_TOAST_SUBTEXT',
    END_LEADERBOARD_TOAST_TITLE: 'END_LEADERBOARD_TOAST_TITLE',
    GENERAL_POINTS: 'GENERAL_POINTS',
    CHECK_RANK: 'CHECK_RANK',
    SHARE_LEADERBOARD_TEXT: 'SHARE_LEADERBOARD_TEXT',
    YOU_ARE_AT: 'YOU_ARE_AT',
    WINNER_HEADER_ENGAGE_TEXT: 'WINNER_HEADER_ENGAGE_TEXT',
    WINNER_HEADER_SHARE_TOOLTIP: 'WINNER_HEADER_SHARE_TOOLTIP',
    WINNERS: 'WINNERS',
    WIN_PRIZES: 'WIN_PRIZES',
    WINNERS_MODAL_TEXT: 'WINNERS_MODAL_TEXT',
    VIEW_WINNERS_BTN_TEXT: 'VIEW_WINNERS_BTN_TEXT',
    LEADERBOARD_CHECK_RANKINGS: 'LEADERBOARD_CHECK_RANKINGS',
    LEADERBOARD_PARTICIPATE: 'LEADERBOARD_PARTICIPATE',
    LEADERBOARD_ENTRY_AND_REGISTRATION: 'LEADERBOARD_ENTRY_AND_REGISTRATION',
    LEADERBOARD_NETWORK: 'LEADERBOARD_NETWORK',
    LEADERBOARD_SPONSORS: 'LEADERBOARD_SPONSORS',
    LEADERBOARD_ADD_SOCIAL_LINK: 'LEADERBOARD_ADD_SOCIAL_LINK',
    LEADERBOARD_RAISE_HAND_ACCEPTED: 'LEADERBOARD_RAISE_HAND_ACCEPTED',
    LEADERBOARD_ASK_QUESTION: 'LEADERBOARD_ASK_QUESTION',
    LEADERBOARD_UPVOTES_RECEIVED: 'LEADERBOARD_UPVOTES_RECEIVED',
    LEADERBOARD_ANSWER_POLL: 'LEADERBOARD_ANSWER_POLL',
    LEADERBOARD_EXPRESS_EMOJIS: 'LEADERBOARD_EXPRESS_EMOJIS',
    LEADERBOARD_SUBMIT_TYPEFORM: 'LEADERBOARD_SUBMIT_TYPEFORM',
    LEADERBOARD_SEND_DM: 'LEADERBOARD_SEND_DM',
    LEADERBOARD_REPLY_DM: 'LEADERBOARD_REPLY_DM',
    LEADERBOARD_CONVERSE_SN: 'LEADERBOARD_CONVERSE_SN',
    LEADERBOARD_ATTEND_SM: 'LEADERBOARD_ATTEND_SM',
    LEADERBOARD_INTERACT_TABLE: 'LEADERBOARD_INTERACT_TABLE',
    LEADERBOARD_CHAT_BOOTH: 'LEADERBOARD_CHAT_BOOTH',
    LEADERBOARD_JOIN_BOOTH_TABLE: 'LEADERBOARD_JOIN_BOOTH_TABLE',
    LEADERBOARD_SUBMIT_INTEREST: 'LEADERBOARD_SUBMIT_INTEREST',
    LEADERBOARD_VISIT_SPONSOR: 'LEADERBOARD_VISIT_SPONSOR',
    LEADERBOARD_ONCE_PER_EVENT: 'LEADERBOARD_ONCE_PER_EVENT',
    LEADERBOARD_ONCE_PER_SESSION: 'LEADERBOARD_ONCE_PER_SESSION',
    LEADERBOARD_PER_UPVOTE: 'LEADERBOARD_PER_UPVOTE',
    LEADERBOARD_PER_ANSWER: 'LEADERBOARD_PER_ANSWER',
    LEADERBOARD_ONCE_PER_CHAT: 'LEADERBOARD_ONCE_PER_CHAT',
    LEADERBOARD_ONCE_PER_MATCH: 'LEADERBOARD_ONCE_PER_MATCH',
    LEADERBOARD_ONCE_PER_MEETING: 'LEADERBOARD_ONCE_PER_MEETING',
    LEADERBOARD_ONCE_PER_BOOTH: 'LEADERBOARD_ONCE_PER_BOOTH',
    LEADERBOARD_ONCE_PER_SPONSOR: 'LEADERBOARD_ONCE_PER_SPONSOR',
    MODIFY_LOUNGE_HEADER: 'MODIFY_LOUNGE_HEADER',
    MODIFY_LOUNGE_SUB_HEADER: 'MODIFY_LOUNGE_SUB_HEADER',
    MODIFY_LOUNGE_TITLE_SUB_HEADER: 'MODIFY_LOUNGE_TITLE_SUB_HEADER',
    GENERAL_SUBTITLE: 'GENERAL_SUBTITLE',
    MODIFY_LOUNGE_SUB_TITLE_SUB_HEADER: 'MODIFY_LOUNGE_SUB_TITLE_SUB_HEADER',
    MODIFY_LOUNGE_NUMBER_ORG_TABLES: 'MODIFY_LOUNGE_NUMBER_ORG_TABLES',
    MODIFY_LOUNGE_CHOOSE_TABLES: 'MODIFY_LOUNGE_CHOOSE_TABLES',
    MODIFY_LOUNGE_MAX_TABLES_ALLOWED: 'MODIFY_LOUNGE_MAX_TABLES_ALLOWED',
    MODIFY_LOUNGE_MAX_PEOPLE_JOIN: 'MODIFY_LOUNGE_MAX_PEOPLE_JOIN',
    MODIFY_LOUNGE_ATTENDEES_NOT_ALLOWED_TABLES:
        'MODIFY_LOUNGE_ATTENDEES_NOT_ALLOWED_TABLES',
    MODIFY_LOUNGE_ATTENDEES_ALLOWED_TABLES:
        'MODIFY_LOUNGE_ATTENDEES_ALLOWED_TABLES',
    MODIFY_LOUNGE_ATTENDEES_TABLES_CREATED:
        'MODIFY_LOUNGE_ATTENDEES_TABLES_CREATED',
    MODIFY_LOUNGE_ATTENDEES_TABLES_PENDING:
        'MODIFY_LOUNGE_ATTENDEES_TABLES_PENDING',
    MODIFY_LOUNGE_CONFIGURE_TABLE_MODERATION:
        'MODIFY_LOUNGE_CONFIGURE_TABLE_MODERATION',
    MODIFY_LOUNGE_CONFIGURE_TABLE_MODERATION_SUB_HEADER:
        'MODIFY_LOUNGE_CONFIGURE_TABLE_MODERATION_SUB_HEADER',
    MODIFY_LOUNGE_ENABLE_TABLE_MODERATION:
        'MODIFY_LOUNGE_ENABLE_TABLE_MODERATION',
    MODIFY_LOUNGE_ENABLE_TABLE_MODERATION_DESC:
        'MODIFY_LOUNGE_ENABLE_TABLE_MODERATION_DESC',
    MODIFY_LOUNGE_CONFIGURE_SPEED_NETWORK:
        'MODIFY_LOUNGE_CONFIGURE_SPEED_NETWORK',
    MODIFY_LOUNGE_CONFIGURE_SPEED_NETWORK_SUB_HEADER:
        'MODIFY_LOUNGE_CONFIGURE_SPEED_NETWORK_SUB_HEADER',
    MODIFY_LOUNGE_ENABLE_SPEED_NETWORK: 'MODIFY_LOUNGE_ENABLE_SPEED_NETWORK',
    MODIFY_LOUNGE_DISABLED_TABLE_NUMBERS:
        'MODIFY_LOUNGE_DISABLED_TABLE_NUMBERS',
    GENERAL_CUSTOM_TIME: 'GENERAL_CUSTOM_TIME',
    TABLE_NEW_TABLE: 'TABLE_NEW_TABLE',
    TABLE_NEW_TABLE_SUB_HEADER: 'TABLE_NEW_TABLE_SUB_HEADER',
    TABLE_EDIT_TABLE: 'TABLE_EDIT_TABLE',
    TABLE_EDIT_TABLE_SUB_HEADER: 'TABLE_EDIT_TABLE_SUB_HEADER',
    TABLE_TOPIC: 'TABLE_TOPIC',
    TABLE_TOPIC_PLACEHOLDER: 'TABLE_TOPIC_PLACEHOLDER',
    TABLE_TOPIC_SUB_HEADER: 'TABLE_TOPIC_SUB_HEADER',
    TABLE_ADD_IMAGE: 'TABLE_ADD_IMAGE',
    TABLE_ADD_IMAGE_SUB_HEADER: 'TABLE_ADD_IMAGE_SUB_HEADER',
    TABLE_CHAIR_DESCRIPTION_FIXED: 'TABLE_CHAIR_DESCRIPTION_FIXED',
    TABLE_CHAIR_DESCRIPTION_FIXED_BOOTH: 'TABLE_CHAIR_DESCRIPTION_FIXED_BOOTH',
    TABLE_CHAIR_DESCRIPTION_EXPANDING: 'TABLE_CHAIR_DESCRIPTION_EXPANDING',
    TABLE_CHAIR_DESCRIPTION_EXPANDING_BOOTH:
        'TABLE_CHAIR_DESCRIPTION_EXPANDING_BOOTH',
    TABLE_PLAN_UPGRADE_BANNER_HEADER: 'TABLE_PLAN_UPGRADE_BANNER_HEADER',
    TABLE_PLAN_UPGRADE_BANNER_SUB_HEADER:
        'TABLE_PLAN_UPGRADE_BANNER_SUB_HEADER',
    TABLE_PLAN_UPGRADE_VIEW_PLAN: 'TABLE_PLAN_UPGRADE_VIEW_PLAN',
    TABLE_DISCUSSION_CARD_HEADER_ORG: 'TABLE_DISCUSSION_CARD_HEADER_ORG',
    TABLE_DISCUSSION_CARD_HEADER_ATTENDEE:
        'TABLE_DISCUSSION_CARD_HEADER_ATTENDEE',
    TABLE_DISCUSSION_CARD_ALLOW_ATTENDEE:
        'TABLE_DISCUSSION_CARD_ALLOW_ATTENDEE',
    TABLE_DISCUSSION_CARD_ALLOW_ATTENDEE_DESC:
        'TABLE_DISCUSSION_CARD_ALLOW_ATTENDEE_DESC',
    TABLE_DISCUSSION_CARD_ATTENDEE_DESC: 'TABLE_DISCUSSION_CARD_ATTENDEE_DESC',
    TABLE_CREATE_NEW_TABLE: 'TABLE_CREATE_NEW_TABLE',
    TABLE_LIVE_LEAVING_HEADER: 'TABLE_LIVE_LEAVING_HEADER',
    TABLE_LIVE_LEAVING_STAY: 'TABLE_LIVE_LEAVING_STAY',
    TABLE_LIVE_LEAVING_DELETE_NOW: 'TABLE_LIVE_LEAVING_DELETE_NOW',
    TABLE_LIVE_LEAVING_DELETE_TABLE: 'TABLE_LIVE_LEAVING_DELETE_TABLE',
    TABLE_LIVE_LEAVING_DELETE_EMPTY: 'TABLE_LIVE_LEAVING_DELETE_EMPTY',
    TABLE_LEAVE_HEADER: 'TABLE_LEAVE_HEADER',
    TABLE_LEAVE_BODY: 'TABLE_LEAVE_BODY',
    TABLE_LEAVE_NOT_NOW: 'TABLE_LEAVE_NOT_NOW',
    TABLE_LEAVE_YES: 'TABLE_LEAVE_YES',
    SESSION_LEAVE_HEADER: 'SESSION_LEAVE_HEADER',
    SESSION_LEAVE_BODY_TABLE: 'SESSION_LEAVE_BODY_TABLE',
    SESSION_LEAVE_BODY_BACKSTAGE: 'SESSION_LEAVE_BODY_BACKSTAGE',
    SESSION_LEAVE_BODY_SESSION: 'SESSION_LEAVE_BODY_SESSION',
    SESSION_LEAVE_BODY_RECEPTION_SESSION_BANNER:
        'SESSION_LEAVE_BODY_RECEPTION_SESSION_BANNER',
    SESSION_LEAVE_BODY_GROUP_MEETING: 'SESSION_LEAVE_BODY_GROUP_MEETING',
    SESSION_LEAVE_BODY_GROUP_MEETING_REPLAY:
        'SESSION_LEAVE_BODY_GROUP_MEETING_REPLAY',
    SESSION_LEAVE_BODY_SCHEDULED_MEETING:
        'SESSION_LEAVE_BODY_SCHEDULED_MEETING',
    SESSION_LEAVE_BODY_FLUID_SPACE: 'SESSION_LEAVE_BODY_FLUID_SPACE',
    SESSION_LEAVE_BODY_SN_SESSION: 'SESSION_LEAVE_BODY_SN_SESSION',
    SESSION_LEAVE_BODY_REPLAY: 'SESSION_LEAVE_BODY_REPLAY',
    SESSION_LEAVE_NOT_NOW: 'SESSION_LEAVE_NOT_NOW',
    SESSION_LEAVE_YES: 'SESSION_LEAVE_YES',
    TABLE_DISABLE_ATTENDEE_TABLE_HEADER: 'TABLE_DISABLE_ATTENDEE_TABLE_HEADER',
    TABLE_DISABLE_ATTENDEE_TABLE_SUB_HEADER:
        'TABLE_DISABLE_ATTENDEE_TABLE_SUB_HEADER',
    TABLE_DISABLE_ATTENDEE_DELETE_NOW: 'TABLE_DISABLE_ATTENDEE_DELETE_NOW',
    TABLE_DISABLE_ATTENDEE_DELETE_EMPTY: 'TABLE_DISABLE_ATTENDEE_DELETE_EMPTY',
    TABLE_DELETE_HEADER: 'TABLE_DELETE_HEADER',
    TABLE_DELETE_SUB_HEADER: 'TABLE_DELETE_SUB_HEADER',
    TABLE_DISCUSSION_CARD_ATTENDEE_EXISTING_MESSAGE:
        'TABLE_DISCUSSION_CARD_ATTENDEE_EXISTING_MESSAGE',
    TABLE_DISCUSSION_CARD_ATTENDEE_LIMIT_MESSAGE:
        'TABLE_DISCUSSION_CARD_ATTENDEE_LIMIT_MESSAGE',
    TOAST_MAX_ATTENDEE_TABLE_HEADER: 'TOAST_MAX_ATTENDEE_TABLE_HEADER',
    TOAST_MAX_ATTENDEE_TABLE_SUB_HEADER: 'TOAST_MAX_ATTENDEE_TABLE_SUB_HEADER',
    TOAST_MAX_TABLE_HEADER: 'TOAST_MAX_TABLE_HEADER',
    TOAST_MAX_TABLE_SUB_HEADER: 'TOAST_MAX_TABLE_SUB_HEADER',
    TOAST_TABLE_CANNOT_REDUCE_HEADER: 'TOAST_TABLE_CANNOT_REDUCE_HEADER',
    TOAST_TABLE_CANNOT_REDUCE_SUB_HEADER:
        'TOAST_TABLE_CANNOT_REDUCE_SUB_HEADER',
    TABLE_SUPPORTED_IMAGE_FORMATS_SIZE: 'TABLE_SUPPORTED_IMAGE_FORMATS_SIZE',
    TOAST_TABLE_IS_DELETED: 'TOAST_TABLE_IS_DELETED',
    TRY_OTHER_FILTERS: 'TRY_OTHER_FILTERS',
    TRY_OTHER_KEYWORDS: 'TRY_OTHER_KEYWORDS',
    CLEAR_FILTERS: 'CLEAR_FILTERS',
    GENERAL_EDIT: 'GENERAL_EDIT',
    INVITE_TO_TABLE_DELETED_TABLE: 'INVITE_TO_TABLE_DELETED_TABLE',
    PERMISSION_ALLOW_ACCESS_CAM_MIC_TO_JOIN:
        'PERMISSION_ALLOW_ACCESS_CAM_MIC_TO_JOIN',
    PERMISSION_TRY_ONE_OF_THESE_STEPS: 'PERMISSION_TRY_ONE_OF_THESE_STEPS',
    PERMISSION_LEARN_MORE: 'PERMISSION_LEARN_MORE',
    PERMISSION_ALLOW_DEVICE_1: 'PERMISSION_ALLOW_DEVICE_1',
    PERMISSION_ALLOW_DEVICE_2: 'PERMISSION_ALLOW_DEVICE_2',
    PERMISSION_CLEAR_OTHER_APPS_USING_CAM_MIC:
        'PERMISSION_CLEAR_OTHER_APPS_USING_CAM_MIC',
    PERMISSION_CLEAR_SITE_CACHE_AND_REFRESH:
        'PERMISSION_CLEAR_SITE_CACHE_AND_REFRESH',
    PERMISSION_JOIN_WITHOUT_DEVICE: 'PERMISSION_JOIN_WITHOUT_DEVICE',
    CAM_MIC_ONLY_CAM: 'CAM_MIC_ONLY_CAM',
    PERMISSION_TEST_AUDIO_VIDEO: 'PERMISSION_TEST_AUDIO_VIDEO',
    AUDIO_VIDEO_SETTINGS_BACKGROUND: 'AUDIO_VIDEO_SETTINGS_BACKGROUND',
    AUDIO_VIDEO_SETTINGS_BACKGROUND_DESCRIPTION:
        'AUDIO_VIDEO_SETTINGS_BACKGROUND_DESCRIPTION',
    PERMISSION_READY_TO_GO: 'PERMISSION_READY_TO_GO',
    PERMISSION_READY_TO_GO_DESCRIPTION: 'PERMISSION_READY_TO_GO_DESCRIPTION',
    PERMISSION_YOU_LOOK_GOOD: 'PERMISSION_YOU_LOOK_GOOD',
    GENERAL_YOUR: 'GENERAL_YOUR',
    PERMISSION_FINALIZE_AUDIO_SETTINGS: 'PERMISSION_FINALIZE_AUDIO_SETTINGS',
    GENERAL_NEXT: 'GENERAL_NEXT',
    AUDIO_VIDEO_SETTINGS_CONFIRM_REPLAY: 'AUDIO_VIDEO_SETTINGS_CONFIRM_REPLAY',
    AUDIO_VIDEO_SETTINGS_CHANGE_MIC: 'AUDIO_VIDEO_SETTINGS_CHANGE_MIC',
    AUDIO_VIDEO_SETTINGS_RECORD_VOICE: 'AUDIO_VIDEO_SETTINGS_RECORD_VOICE',
    AUDIO_VIDEO_SETTINGS_ALL_GOOD: 'AUDIO_VIDEO_SETTINGS_ALL_GOOD',
    AUDIO_VIDEO_SETTINGS_LAST_STEP: 'AUDIO_VIDEO_SETTINGS_LAST_STEP',
    AUDIO_VIDEO_SETTINGS_TEST_SPEAKERS: 'AUDIO_VIDEO_SETTINGS_TEST_SPEAKERS',
    AUDIO_VIDEO_SETTINGS_CHANGE_SPEAKER: 'AUDIO_VIDEO_SETTINGS_CHANGE_SPEAKER',
    AUDIO_VIDEO_SETTINGS_READY: 'AUDIO_VIDEO_SETTINGS_READY',
    AUDIO_VIDEO_SETTINGS_GOOD_TO_GO: 'AUDIO_VIDEO_SETTINGS_GOOD_TO_GO',
    APPLY_FILTER_FAILURE: 'APPLY_FILTER_FAILURE',
    SESSION_JOIN_BOOTH_SESSION_POPUP_HEADING:
        'SESSION_JOIN_BOOTH_SESSION_POPUP_HEADING',
    SESSION_JOIN_BOOTH_SESSION_QUESTION: 'SESSION_JOIN_BOOTH_SESSION_QUESTION',
    SESSION_PLAYBACK_UNAVAILABLE_TOOLTIP_ATTENDEE:
        'SESSION_PLAYBACK_UNAVAILABLE_TOOLTIP_ATTENDEE',
    RHS_CUSTOMIZE_ATTENDEE_COUNT_HINT: 'RHS_CUSTOMIZE_ATTENDEE_COUNT_HINT',
    RHS_CUSTOMIZE_ATTENDEE_COUNT_HINT_V2:
        'RHS_CUSTOMIZE_ATTENDEE_COUNT_HINT_V2',
    RHS_CUSTOMIZE_PEOPLE_COUNT_LABEL: 'RHS_CUSTOMIZE_PEOPLE_COUNT_LABEL',
    RHS_CUSTOMIZE_CLOSE_CAPTION_LABEL: 'RHS_CUSTOMIZE_CLOSE_CAPTION_LABEL',
    RHS_CUSTOMIZE_CLOSE_CAPTION_HINT: 'RHS_CUSTOMIZE_CLOSE_CAPTION_HINT',
    RHS_CUSTOMIZE_TRANSLATED_CAPTION_LABEL:
        'RHS_CUSTOMIZE_TRANSLATED_CAPTION_LABEL',
    RHS_CUSTOMIZE_TRANSLATED_CAPTION_HINT:
        'RHS_CUSTOMIZE_TRANSLATED_CAPTION_HINT',
    RHS_CUSTOMIZE_MY_SCHEDULE_LABEL: 'RHS_CUSTOMIZE_MY_SCHEDULE_LABEL',
    RHS_CUSTOMIZE_MY_SCHEDULE_HINT: 'RHS_CUSTOMIZE_MY_SCHEDULE_HINT',
    RHS_CUSTOMIZE_PEOPLE_COUNT_HINT: 'RHS_CUSTOMIZE_PEOPLE_COUNT_HINT',
    SN_MEETUP_TAKE_A_BREAK_FROM_NETWORKING:
        'SN_MEETUP_TAKE_A_BREAK_FROM_NETWORKING',
    SN_MEETUP_UNABLE_TO_MATCH_AND_CONNECT_SUBTITLE:
        'SN_MEETUP_UNABLE_TO_MATCH_AND_CONNECT_SUBTITLE',
    SN_MEETUP_DO_YOU_WANT_TO_TAKE_A_BREAK:
        'SN_MEETUP_DO_YOU_WANT_TO_TAKE_A_BREAK',
    SN_MEETUP_TAKE_A_BREAK_BUTTON: 'SN_MEETUP_TAKE_A_BREAK_BUTTON',
    GENERAL_START: 'GENERAL_START',
    GENERAL_RESUME: 'GENERAL_RESUME',
    SN_MEETUP_YOUR_NETWORKING_IS_PAUSED_RIGHT_NOW:
        'SN_MEETUP_YOUR_NETWORKING_IS_PAUSED_RIGHT_NOW',
    SN_MEETUP_YOUR_NETWORKING_IS_PAUSED: 'SN_MEETUP_YOUR_NETWORKING_IS_PAUSED',
    SN_MEETUP_START_NETWORKING: 'SN_MEETUP_START_NETWORKING',
    SN_MEETUP_RESUME_NETWORKING: 'SN_MEETUP_RESUME_NETWORKING',
    SN_MEETUP_PEOPLE_CONVERSING: 'SN_MEETUP_PEOPLE_CONVERSING',
    SN_MEETUP_MATCH_WITH_PEOPLE: 'SN_MEETUP_MATCH_WITH_PEOPLE',
    SN_MEETUP_JOIN_NOW: 'SN_MEETUP_JOIN_NOW',
    SN_MEETUP_JOIN_LATER: 'SN_MEETUP_JOIN_LATER',
    SN_MEETUP_ENDING: 'SN_MEETUP_ENDING',
    SN_MEETUP_BANNER_MINS_AVAILABLE: 'SN_MEETUP_BANNER_MINS_AVAILABLE',
    RHS_CUSTOMIZE_HIDE_SOCIAL_LOUNGE_PROMPT_TITLE:
        'RHS_CUSTOMIZE_HIDE_SOCIAL_LOUNGE_PROMPT_TITLE',
    RHS_CUSTOMIZE_SOCIAL_LOUNGE_PROMPT_MSG:
        'RHS_CUSTOMIZE_SOCIAL_LOUNGE_PROMPT_MSG',
    RHS_CUSTOMIZE_EMOJI_REACTION: 'RHS_CUSTOMIZE_EMOJI_REACTION',
    RHS_CUSTOMIZE_EMOJI_HINT: 'RHS_CUSTOMIZE_EMOJI_HINT',
    MY_SCHEDULE_SESSION_REPLAY: 'MY_SCHEDULE_SESSION_REPLAY',
    PECA_RHS_PROFILE_TOOLTIP: 'PECA_RHS_PROFILE_TOOLTIP',
    PECA_RHS_DM_TOOLTIP: 'PECA_RHS_DM_TOOLTIP',
    PECA_RHS_SETTINGS_TOOLTIP: 'PECA_RHS_SETTINGS_TOOLTIP',
    PORTRAIT_ROTATE_TITLE: 'PORTRAIT_ROTATE_TITLE',
    PORTRAIT_ROTATE_SUBTITLE_1: 'PORTRAIT_ROTATE_SUBTITLE_1',
    PORTRAIT_ROTATE_SUBTITLE_2: 'PORTRAIT_ROTATE_SUBTITLE_2',
    FLUID_SPACE_INTRO_HEADER: 'FLUID_SPACE_INTRO_HEADER',
    FLUID_SPACE_INTRO_DESC: 'FLUID_SPACE_INTRO_DESC',
    FLUID_SPACE_INTRO_BTN: 'FLUID_SPACE_INTRO_BTN',
    UPCOMING_SESSIONS_NOTIFICATION_SHOW_MORE:
        'UPCOMING_SESSIONS_NOTIFICATION_SHOW_MORE',
    UPCOMING_SESSIONS_NOTIFICATION_SEE_MORE:
        'UPCOMING_SESSIONS_NOTIFICATION_SEE_MORE',
    UPCOMING_SESSIONS_NOTIFICATION_ONGOING_SESSION_HEADING:
        'UPCOMING_SESSIONS_NOTIFICATION_ONGOING_SESSION_HEADING',
    UPCOMING_SESSIONS_NOTIFICATION_ONGOING_SESSIONS_HEADING:
        'UPCOMING_SESSIONS_NOTIFICATION_ONGOING_SESSIONS_HEADING',
    UPCOMING_SESSIONS_NOTIFICATION_MULTIPLE_ONGOING_SESSIONS_HEADING:
        'UPCOMING_SESSIONS_NOTIFICATION_MULTIPLE_ONGOING_SESSIONS_HEADING',
    UPCOMING_SESSIONS_NOTIFICATION_ONGOING_SESSIONS_MESSAGE:
        'UPCOMING_SESSIONS_NOTIFICATION_ONGOING_SESSIONS_MESSAGE',
    UPCOMING_SESSIONS_NOTIFICATION_ALREADY_ONGOING_SESSIONS_MESSAGE:
        'UPCOMING_SESSIONS_NOTIFICATION_ALREADY_ONGOING_SESSIONS_MESSAGE',
    UPCOMING_SESSIONS_NOTIFICATION_UPCOMING_SESSION_HEADING:
        'UPCOMING_SESSIONS_NOTIFICATION_UPCOMING_SESSION_HEADING',
    UPCOMING_SESSIONS_NOTIFICATION_UPCOMING_SESSIONS_HEADING:
        'UPCOMING_SESSIONS_NOTIFICATION_UPCOMING_SESSIONS_HEADING',
    UPCOMING_SESSIONS_PROMPT_HEADING: 'UPCOMING_SESSIONS_PROMPT_HEADING',
    UPCOMING_SESSIONS_PROMPT_SESSION_AUTO_JOIN_MESSAGE:
        'UPCOMING_SESSIONS_PROMPT_SESSION_AUTO_JOIN_MESSAGE',
    UPCOMING_SESSIONS_PROMPT_SESSION_AUTO_JOIN_TIME:
        'UPCOMING_SESSIONS_PROMPT_SESSION_AUTO_JOIN_TIME',
    STAGE_LEAVE_BUTTON_LABEL: 'STAGE_LEAVE_BUTTON_LABEL',
    UPCOMING_SESSIONS_HEADING: 'UPCOMING_SESSIONS_HEADING',
    GENERAL_BACKSTAGE: 'GENERAL_BACKSTAGE',
    STAGE_BACKSTAGE_NOT_STARTED_TEXT: 'STAGE_BACKSTAGE_NOT_STARTED_TEXT',
    STAGE_BACKSTAGE_PAUSED_TEXT: 'STAGE_BACKSTAGE_PAUSED_TEXT',
    STAGE_BACKSTAGE_FINISHED_TEXT: 'STAGE_BACKSTAGE_FINISHED_TEXT',
    GENERAL_SESSION_STATUS_NOT_STARTED: 'GENERAL_SESSION_STATUS_NOT_STARTED',
    GENERAL_SESSION_STATUS_COMPLETED: 'GENERAL_SESSION_STATUS_COMPLETED',
    STAGE_PERSON_IN_SESSION: 'STAGE_PERSON_IN_SESSION',
    STAGE_PEOPLE_IN_SESSION: 'STAGE_PEOPLE_IN_SESSION',
    SESSION_STARTS_AT: 'SESSION_STARTS_AT',
    STAGE_RECORD_SESSION: 'STAGE_RECORD_SESSION',
    SHOW_RHS_PANEL: 'SHOW_RHS_PANEL',
    HIDE_RHS_PANEL: 'HIDE_RHS_PANEL',
    RHS_CUSTOMIZE_EVENT_ATTENDEE_COUNT_HINT:
        'RHS_CUSTOMIZE_EVENT_ATTENDEE_COUNT_HINT',
    RHS_CUSTOMIZE_EVENT_ATTENDEE_COUNT_HINT_V2:
        'RHS_CUSTOMIZE_EVENT_ATTENDEE_COUNT_HINT_V2',
    PRE_EVENT_BANNER_HEADING: 'PRE_EVENT_BANNER_HEADING',
    PRE_EVENT_BANNER_SUB_HEADING: 'PRE_EVENT_BANNER_SUB_HEADING',
    PRE_EVENT_BANNER_SCHEDULE_TITLE: 'PRE_EVENT_BANNER_SCHEDULE_TITLE',
    PRE_EVENT_BANNER_SCHEDULE_SUBTITLE: 'PRE_EVENT_BANNER_SCHEDULE_SUBTITLE',
    PRE_EVENT_BANNER_SCHEDULE_CTA: 'PRE_EVENT_BANNER_SCHEDULE_CTA',
    PRE_EVENT_BANNER_PEOPLE_TITLE: 'PRE_EVENT_BANNER_PEOPLE_TITLE',
    PRE_EVENT_BANNER_PEOPLE_SUBTITLE: 'PRE_EVENT_BANNER_PEOPLE_SUBTITLE',
    PRE_EVENT_BANNER_PEOPLE_CTA: 'PRE_EVENT_BANNER_PEOPLE_CTA',
    PRE_EVENT_BANNER_PROFILE_TITLE: 'PRE_EVENT_BANNER_PROFILE_TITLE',
    PRE_EVENT_BANNER_PROFILE_SUBTITLE: 'PRE_EVENT_BANNER_PROFILE_SUBTITLE',
    PRE_EVENT_BANNER_PROFILE_CTA: 'PRE_EVENT_BANNER_PROFILE_CTA',
    TABLE_NAME_SEARCH: 'TABLE_NAME_SEARCH',
    NO_RESULT_FOUND: 'NO_RESULT_FOUND',
    SEARCH_RETRY: 'SEARCH_RETRY',
    PERMISSION_CHECK_APPEARANCE: 'PERMISSION_CHECK_APPEARANCE',
    PERMISSION_CHECK_NO_ONE_CAN_HEAR: 'PERMISSION_CHECK_NO_ONE_CAN_HEAR',
    PERMISSION_CHECK_NO_ONE_CAN_HEAR_FREE_EVENT:
        'PERMISSION_CHECK_NO_ONE_CAN_HEAR_FREE_EVENT',
    FILTER_VIEW_SUPPORTED_BROWSER: 'FILTER_VIEW_SUPPORTED_BROWSER',
    PERMISSION_STILL_NEED_HELP: 'PERMISSION_STILL_NEED_HELP',
    PERMISSION_CONTACT_SUPPORT: 'PERMISSION_CONTACT_SUPPORT',
    ADD_BACKGROUND: 'ADD_BACKGROUND',
    DO_NOT_USE_BACKGROUND: 'DO_NOT_USE_BACKGROUND',
    BLUR_MY_BACKGROUND: 'BLUR_MY_BACKGROUND',
    ADD_IMAGE_AS_BACKGROUND: 'ADD_IMAGE_AS_BACKGROUND',
    ADD_CUSTOM_IMAGE_AS_BACKGROUND: 'ADD_CUSTOM_IMAGE_AS_BACKGROUND',
    VIRTUAL_BG_COMIC_CITY: 'VIRTUAL_BG_COMIC_CITY',
    VIRTUAL_BG_MOUNTAINS: 'VIRTUAL_BG_MOUNTAINS',
    VIRTUAL_BG_CITY_1: 'VIRTUAL_BG_CITY_1',
    VIRTUAL_BG_PARTY: 'VIRTUAL_BG_PARTY',
    VIRTUAL_BG_OUTDOOR_BEACH: 'VIRTUAL_BG_OUTDOOR_BEACH',
    VIRTUAL_BG_COZY_INDOOR: 'VIRTUAL_BG_COZY_INDOOR',
    VIRTUAL_BG_CITY_2: 'VIRTUAL_BG_CITY_2',
    VIRTUAL_BG_BEACH: 'VIRTUAL_BG_BEACH',
    VIRTUAL_BG_COZY_INDOOR_2: 'VIRTUAL_BG_COZY_INDOOR_2',
    VIRTUAL_BG_WHITISH_INDOOR: 'VIRTUAL_BG_WHITISH_INDOOR',
    VIRTUAL_BG_CITY_3: 'VIRTUAL_BG_CITY_3',
    VIRTUAL_BG_DEEP_FOREST: 'VIRTUAL_BG_DEEP_FOREST',
    VIRTUAL_BG_COSMIC_WOW: 'VIRTUAL_BG_COSMIC_WOW',
    VIRTUAL_BG_DESSERT: 'VIRTUAL_BG_DESSERT',
    VIRTUAL_BG_NOT_AVAILABLE: 'VIRTUAL_BG_NOT_AVAILABLE',
    VIRTUAL_BG_NOT_SUPPORTED_MACHINE_BROWSER:
        'VIRTUAL_BG_NOT_SUPPORTED_MACHINE_BROWSER',
    CUSTOM_BG_FILE_SIZE: 'CUSTOM_BG_FILE_SIZE',
    CUSTOM_BG_FILE_TYPE: 'CUSTOM_BG_FILE_TYPE',
    CUSTOM_BG_UPLOAD_FAILURE: 'CUSTOM_BG_UPLOAD_FAILURE',
    CUSTOM_BG_DELETE_FAILURE: 'CUSTOM_BG_DELETE_FAILURE',
    QA_COMMENTS_ALLOW: 'QA_COMMENTS_ALLOW',
    QA_COMMENTS_ALLOW_SUBTEXT: 'QA_COMMENTS_ALLOW_SUBTEXT',
    QA_COMMENTS_SINGLE_COUNT: 'QA_COMMENTS_SINGLE_COUNT',
    QA_COMMENTS_COUNT: 'QA_COMMENTS_COUNT',
    QA_COMMENTS_COMMENTED: 'QA_COMMENTS_COMMENTED',
    COMMENTS_INFO_SETTINGS: 'COMMENTS_INFO_SETTINGS',
    QUESTION_COMMENT: 'QUESTION_COMMENT',
    QUESTION_COMMENTS: 'QUESTION_COMMENTS',
    QUESTION_COMMENT_COUNT_SINGULAR: 'QUESTION_COMMENT_COUNT_SINGULAR',
    QUESTION_COMMENT_COUNT_PLURAL: 'QUESTION_COMMENT_COUNT_PLURAL',
    QA_COMMENTS_MARK_ANSWER: 'QA_COMMENTS_MARK_ANSWER',
    QA_COMMENTS_MARK_ASK_QUESTIONS: 'QA_COMMENTS_MARK_ASK_QUESTIONS',
    QA_COMMENTS_TYPE_COMMENT: 'QA_COMMENTS_TYPE_COMMENT',
    PAUSED_SESSION: 'PAUSED_SESSION',
    SESSION_IS_PAUSED: 'SESSION_IS_PAUSED',
    SESSION_IS_PAUSED_DESCRIPTION: 'SESSION_IS_PAUSED_DESCRIPTION',
    NEW_LEADERBOARD_OVERLAY_DESC: 'NEW_LEADERBOARD_OVERLAY_DESC',
    LOUNGE_WELCOME_TEXT: 'LOUNGE_WELCOME_TEXT',
    BOOTHS_WELCOME_TEXT: 'BOOTHS_WELCOME_TEXT',
    LOUNGE_PRE_EVENT_DESC: 'LOUNGE_PRE_EVENT_DESC',
    BOOTHS_PRE_EVENT_DESC: 'BOOTHS_PRE_EVENT_DESC',
    SESSION_REMIND_ME_PRE_EVENT_WARNING: 'SESSION_REMIND_ME_PRE_EVENT_WARNING',
    FEEDBACK_RATING_TERRIBLE: 'FEEDBACK_RATING_TERRIBLE',
    FEEDBACK_RATING_BAD: 'FEEDBACK_RATING_BAD',
    FEEDBACK_RATING_GOOD: 'FEEDBACK_RATING_GOOD',
    FEEDBACK_RATING_L1_1: 'FEEDBACK_RATING_L1_1',
    FEEDBACK_RATING_L1_1_OPTION_1: 'FEEDBACK_RATING_L1_1_OPTION_1',
    FEEDBACK_RATING_L1_1_OPTION_2: 'FEEDBACK_RATING_L1_1_OPTION_2',
    FEEDBACK_RATING_L1_1_OPTION_3: 'FEEDBACK_RATING_L1_1_OPTION_3',
    FEEDBACK_RATING_L1_1_OPTION_4: 'FEEDBACK_RATING_L1_1_OPTION_4',
    FEEDBACK_RATING_L1_2: 'FEEDBACK_RATING_L1_2',
    FEEDBACK_RATING_L1_2_OPTION_1: 'FEEDBACK_RATING_L1_2_OPTION_1',
    FEEDBACK_RATING_L1_2_OPTION_2: 'FEEDBACK_RATING_L1_2_OPTION_2',
    FEEDBACK_RATING_L1_2_OPTION_3: 'FEEDBACK_RATING_L1_2_OPTION_3',
    FEEDBACK_RATING_L1_2_OPTION_4: 'FEEDBACK_RATING_L1_2_OPTION_4',
    FEEDBACK_RATING_L1_3: 'FEEDBACK_RATING_L1_3',
    FEEDBACK_RATING_L1_3_OPTION_1: 'FEEDBACK_RATING_L1_3_OPTION_1',
    FEEDBACK_RATING_L1_3_OPTION_2: 'FEEDBACK_RATING_L1_3_OPTION_2',
    FEEDBACK_RATING_L1_3_OPTION_3: 'FEEDBACK_RATING_L1_3_OPTION_3',
    FEEDBACK_RATING_L1_3_OPTION_4: 'FEEDBACK_RATING_L1_3_OPTION_4',
    FEEDBACK_RATING_L1_4: 'FEEDBACK_RATING_L1_4',
    FEEDBACK_RATING_L1_4_OPTION_1: 'FEEDBACK_RATING_L1_4_OPTION_1',
    FEEDBACK_RATING_L1_4_OPTION_2: 'FEEDBACK_RATING_L1_4_OPTION_2',
    FEEDBACK_RATING_L1_4_OPTION_3: 'FEEDBACK_RATING_L1_4_OPTION_3',
    FEEDBACK_RATING_L1_4_OPTION_4: 'FEEDBACK_RATING_L1_4_OPTION_4',
    FEEDBACK_RATING_L1_5: 'FEEDBACK_RATING_L1_5',
    FEEDBACK_RATING_L1_5_OPTION_1: 'FEEDBACK_RATING_L1_5_OPTION_1',
    FEEDBACK_RATING_L1_5_OPTION_2: 'FEEDBACK_RATING_L1_5_OPTION_2',
    FEEDBACK_RATING_LOVE_PLATFORM: 'FEEDBACK_RATING_LOVE_PLATFORM',
    FEEDBACK_RATING_RATE_US: 'FEEDBACK_RATING_RATE_US',
    FEEDBACK_RATING_HELP_US_IMPROVE: 'FEEDBACK_RATING_HELP_US_IMPROVE',
    FEEDBACK_RATING_HELP_US_IMPROVE_DESCRIPTION:
        'FEEDBACK_RATING_HELP_US_IMPROVE_DESCRIPTION',
    FEEDBACK_RATING_THANK_YOU: 'FEEDBACK_RATING_THANK_YOU',
    FLUID_NETWORKING: 'FLUID_NETWORKING',
    START_FLUID_NETWORKING_SESSION: 'START_FLUID_NETWORKING_SESSION',
    START_FLUID_NETWORKING_SESSION_SUBTITLE:
        'START_FLUID_NETWORKING_SESSION_SUBTITLE',
    START_FLUID_NETWORKING_DESCRIPTION: 'START_FLUID_NETWORKING_DESCRIPTION',
    END_FLUID_NETWORKING_SESSION: 'END_FLUID_NETWORKING_SESSION',
    END_FLUID_NETWORKING_SESSION_SUBTITLE:
        'END_FLUID_NETWORKING_SESSION_SUBTITLE',
    END_FLUID_NETWORKING_DESCRIPTION: 'END_FLUID_NETWORKING_DESCRIPTION',
    STOP_FLUID_SPACE: 'STOP_FLUID_SPACE',
    START_FLUID_SPACE: 'START_FLUID_SPACE',
    ENTER_FLUID_SPACE: 'ENTER_FLUID_SPACE',
    GENERAL_RESET: 'GENERAL_RESET',
    LEADERBOARD_RESET_TEXT: 'LEADERBOARD_RESET_TEXT',
    START_LEADERBOARD: 'START_LEADERBOARD',
    LEADERBOARD_RANKINGS: 'LEADERBOARD_RANKINGS',
    LEADERBOARD_EARN_POINTS: 'LEADERBOARD_EARN_POINTS',
    LEADERBOARD_HISTORY: 'LEADERBOARD_HISTORY',
    RESET_CONFIRMATION_TEXT: 'RESET_CONFIRMATION_TEXT',
    END_CONFIRMATION_TEXT: 'END_CONFIRMATION_TEXT',
    RESET_LEADERBOARD_CONFIRMATION: 'RESET_LEADERBOARD_CONFIRMATION',
    LEADERBOARD_ANNOUNCE_WINNERS: 'LEADERBOARD_ANNOUNCE_WINNERS',
    NOT_NOW: 'NOT_NOW',
    ORGANIZER_USER_JOURNEY_TEXT: 'ORGANIZER_USER_JOURNEY_TEXT',
    USER_JOURNEY_TEXT: 'USER_JOURNEY_TEXT',
    LEADERBOARD_ICON_TOOLTIP: 'LEADERBOARD_ICON_TOOLTIP',
    LEADERBOARD_ICON_TOOLTIP2: 'LEADERBOARD_ICON_TOOLTIP2',
    NEW_LEADERBOARD_TEXT: 'NEW_LEADERBOARD_TEXT',
    LEADERBOARD_END_CONFIRMATION_TEXT: 'LEADERBOARD_END_CONFIRMATION_TEXT',
    MALICIOUS_FIELD_VALIDATION_ERROR_MESSAGE:
        'MALICIOUS_FIELD_VALIDATION_ERROR_MESSAGE',
    MALICIOUS_DATA_RESPONSE: 'MALICIOUS_DATA_RESPONSE',
    INVITE_TABLE_DND_NONE_TO_DISTURB: 'INVITE_TABLE_DND_NONE_TO_DISTURB',
    INVITE_TABLE_DND_WANT_NO_REQUESTS: 'INVITE_TABLE_DND_WANT_NO_REQUESTS',
    INVITE_TABLE_DND_USE_TO_STOP_PEOPLE_REQUESTS:
        'INVITE_TABLE_DND_USE_TO_STOP_PEOPLE_REQUESTS',
    INVITE_TABLE_DND: 'INVITE_TABLE_DND',
    INVITE_TABLE_CTA: 'INVITE_TABLE_CTA',
    INVITE_TABLE_CTA_TOOLTIP: 'INVITE_TABLE_CTA_TOOLTIP',
    INVITE_TABLE_CTA_FULLSCREEN: 'INVITE_TABLE_CTA_FULLSCREEN',
    INVITE_TABLE_INVITE_SENT: 'INVITE_TABLE_INVITE_SENT',
    INVITE_TABLE_INVITE_SENT_MANY: 'INVITE_TABLE_INVITE_SENT_MANY',
    GROUP_MEETING: 'GROUP_MEETING',
    START_GROUP_MEETING: 'START_GROUP_MEETING',
    JOIN_GROUP_MEETING: 'JOIN_GROUP_MEETING',
    END_GROUP_MEETING: 'END_GROUP_MEETING',
    START_MEETING: 'START_MEETING',
    END_MEETING: 'END_MEETING',
    START_GROUP_MEETING_MODAL_TITLE: 'START_GROUP_MEETING_MODAL_TITLE',
    END_GROUP_MEETING_MODAL_TITLE: 'END_GROUP_MEETING_MODAL_TITLE',
    START_GROUP_MEETING_MODAL_SUBTITLE: 'START_GROUP_MEETING_MODAL_SUBTITLE',
    END_GROUP_MEETING_MODAL_SUBTITLE: 'END_GROUP_MEETING_MODAL_SUBTITLE',
    START_GROUP_MEETING_MODAL_DESCRIPTION:
        'START_GROUP_MEETING_MODAL_DESCRIPTION',
    END_GROUP_MEETING_MODAL_DESCRIPTION: 'END_GROUP_MEETING_MODAL_DESCRIPTION',
    START_GROUP_MEETING_TOAST_TITLE: 'START_GROUP_MEETING_TOAST_TITLE',
    START_GROUP_MEETING_TOAST_MESSAGE: 'START_GROUP_MEETING_TOAST_MESSAGE',
    SESSION_JOIN_GROUP_MEETING: 'SESSION_JOIN_GROUP_MEETING',
    END_GROUP_MEETING_TOAST_TITLE: 'END_GROUP_MEETING_TOAST_TITLE',
    END_GROUP_MEETING_TOAST_MESSAGE_ORGANIZER:
        'END_GROUP_MEETING_TOAST_MESSAGE_ORGANIZER',
    END_GROUP_MEETING_TOAST_MESSAGE_ATTENDEE:
        'END_GROUP_MEETING_TOAST_MESSAGE_ATTENDEE',
    END_GROUP_MEETING_LEAVE_PROMPT_HEADING:
        'END_GROUP_MEETING_LEAVE_PROMPT_HEADING',
    END_GROUP_MEETING_LEAVE_PROMPT_MESSAGE:
        'END_GROUP_MEETING_LEAVE_PROMPT_MESSAGE',
    GROUP_MEETING_FULL: 'GROUP_MEETING_FULL',
    PLEASE_TRY_AGAIN_LATER: 'PLEASE_TRY_AGAIN_LATER',
    REDIRECT_TO_SCHEDULE_PAGE: 'REDIRECT_TO_SCHEDULE_PAGE',
    GROUP_MEETING_HOST_ERROR: 'GROUP_MEETING_HOST_ERROR',
    GROUP_MEETING_SCREEN_SHARE_NOT_SUPPORTED:
        'GROUP_MEETING_SCREEN_SHARE_NOT_SUPPORTED',
    GROUP_MEETING_CURRENTLY_IN_GROUP_MEETING:
        'GROUP_MEETING_CURRENTLY_IN_GROUP_MEETING',
    LINK_SENT_TO_EMAIL: 'LINK_SENT_TO_EMAIL',
    LINK_SENT_TO_EMAIL_DESC: 'LINK_SENT_TO_EMAIL_DESC',
    USER_ALREADY_REGISTERED: 'USER_ALREADY_REGISTERED',
    USER_ALREADY_REGISTERED_DESC_V2: 'USER_ALREADY_REGISTERED_DESC_V2',
    ANNOUNCEMENT_ARE_YOU_SURE_YOU_WANT_TO_STOP_BROADCAST:
        'ANNOUNCEMENT_ARE_YOU_SURE_YOU_WANT_TO_STOP_BROADCAST',
    ANNOUNCEMENT_END_BROADCAST: 'ANNOUNCEMENT_END_BROADCAST',
    ANNOUNCEMENT_LIVE_BROADCAST: 'ANNOUNCEMENT_LIVE_BROADCAST',
    ANNOUNCEMENT_SETUP_LIVE_BROADCAST: 'ANNOUNCEMENT_SETUP_LIVE_BROADCAST',
    ANNOUNCEMENT_SWITCH_TO_MINIMIZE_VIEW:
        'ANNOUNCEMENT_SWITCH_TO_MINIMIZE_VIEW',
    ANNOUNCEMENT_SWITCH_TO_FULLSCREEN: 'ANNOUNCEMENT_SWITCH_TO_FULLSCREEN',
    ANNOUNCEMENT_LIVE: 'ANNOUNCEMENT_LIVE',
    ANNOUNCEMENT_VIDEO_ANNOUNCEMENT: 'ANNOUNCEMENT_VIDEO_ANNOUNCEMENT',
    ANNOUNCEMENT_ILLUSTRATION_BODY: 'ANNOUNCEMENT_ILLUSTRATION_BODY',
    ANNOUNCEMENT_PROCEED_TO_SETUP: 'ANNOUNCEMENT_PROCEED_TO_SETUP',
    ANNOUNCEMENT_PRESS_PROCEED_WHEN_READY:
        'ANNOUNCEMENT_PRESS_PROCEED_WHEN_READY',
    ANNOUNCEMENT_BROADCAST_LIVE_VIDEO_ANNOUNCEMENT_TO_ALL:
        'ANNOUNCEMENT_BROADCAST_LIVE_VIDEO_ANNOUNCEMENT_TO_ALL',
    ANNOUNCEMENT_START_BROADCAST: 'ANNOUNCEMENT_START_BROADCAST',
    ANNOUNCEMENT_CHECK_AUDIO_VIDEO: 'ANNOUNCEMENT_CHECK_AUDIO_VIDEO',
    ANNOUNCEMENT_CREATE_VIDEO_ANNOUNCEMENT_FOR_ALL:
        'ANNOUNCEMENT_CREATE_VIDEO_ANNOUNCEMENT_FOR_ALL',
    ANNOUNCEMENT_CREATE_BROADCAST: 'ANNOUNCEMENT_CREATE_BROADCAST',
    ANNOUNCEMENT_VIDEO_ANNOUNCEMENT_IN_BETA_DESCRIPTION:
        'ANNOUNCEMENT_VIDEO_ANNOUNCEMENT_IN_BETA_DESCRIPTION',
    ANNOUNCEMENT_VIDEO_ANNOUNCEMENT_IN_BETA:
        'ANNOUNCEMENT_VIDEO_ANNOUNCEMENT_IN_BETA',
    ANNOUNCEMENT_VIDEO_ANNOUNCEMENT_COLLECT_FEEDBACK:
        'ANNOUNCEMENT_VIDEO_ANNOUNCEMENT_COLLECT_FEEDBACK',
    ANNOUNCEMENT_TO_MAKE_ROBUST: 'ANNOUNCEMENT_TO_MAKE_ROBUST',
    ANNOUNCEMENT_BROADCAST_IS_ALREADY_RUNNING:
        'ANNOUNCEMENT_BROADCAST_IS_ALREADY_RUNNING',
    ANNOUNCEMENT_ANOTHER_ORGANIZER_ALREADY_STARTED_ANNOUNCEMENT:
        'ANNOUNCEMENT_ANOTHER_ORGANIZER_ALREADY_STARTED_ANNOUNCEMENT',
    CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
    SEND_OUT_TEXT_NOTIFICATION_TO_ALL: 'SEND_OUT_TEXT_NOTIFICATION_TO_ALL',
    VIDEO_ANNOUNCEMENTS: 'VIDEO_ANNOUNCEMENTS',
    VIDEO_ANNOUNCEMENTS_HAS_ENDED: 'VIDEO_ANNOUNCEMENTS_HAS_ENDED',
    ANNOUNCEMENT_VIEW_PRICING_PLANS: 'ANNOUNCEMENT_VIEW_PRICING_PLANS',
    SN_PERMISSION_SUCCESS_TEXT: 'SN_PERMISSION_SUCCESS_TEXT',
    SN_PERMISSION_TITLE_TEXT: 'SN_PERMISSION_TITLE_TEXT',
    FAILED_TO_JOIN: 'FAILED_TO_JOIN',
    FAILED_TO_PLAY_REPLAY: 'FAILED_TO_PLAY_REPLAY',
    FLUID_SPACE_JOIN_IOS13_LABEL: 'FLUID_SPACE_JOIN_IOS13_LABEL',
    MEGAPHONE_PLACEHOLDER: 'MEGAPHONE_PLACEHOLDER',
    ROOMS: 'ROOMS',
    SINGLE_ROOM_DESC: 'SINGLE_ROOM_DESC',
    MULTI_ROOM_DESC: 'MULTI_ROOM_DESC',
    FLUID_SPACE_SESSION_FULL: 'FLUID_SPACE_SESSION_FULL',
    SN_MEETUP_DISABLE_WATCH_REPLAY: 'SN_MEETUP_DISABLE_WATCH_REPLAY',
    FLUID_SPACE_ANNOUNCEMENT: 'FLUID_SPACE_ANNOUNCEMENT',
    BREAKOUT_LIVE_TABLE_WAITING_MESSAGE: 'BREAKOUT_LIVE_TABLE_WAITING_MESSAGE',
    ANNOUNCEMENT_KEEP_THE_AUDIENCE_ENGAGE:
        'ANNOUNCEMENT_KEEP_THE_AUDIENCE_ENGAGE',
    ANNOUNCEMENT_GUIDE_THE_AUDIENCE: 'ANNOUNCEMENT_GUIDE_THE_AUDIENCE',
    ANNOUNCEMENT_GIVE_A_SHOUTOUT: 'ANNOUNCEMENT_GIVE_A_SHOUTOUT',
    ANNOUNCEMENT_PLEASE_TURN_MIC_OR_CAMERA:
        'ANNOUNCEMENT_PLEASE_TURN_MIC_OR_CAMERA',
    ADDITIONAL_COMMENTS: 'ADDITIONAL_COMMENTS',
    BREAKOUT_CLOSE: 'BREAKOUT_CLOSE',
    BREAKOUT_CLOSES_IN: 'BREAKOUT_CLOSES_IN',
    BREAKOUT_WAITING_AREA_TITLE_1: 'BREAKOUT_WAITING_AREA_TITLE_1',
    BREAKOUT_WAITING_AREA_MESSAGE: 'BREAKOUT_WAITING_AREA_MESSAGE',
    BREAKOUT_WAITING_AREA_TOOLTIP_MESSAGE:
        'BREAKOUT_WAITING_AREA_TOOLTIP_MESSAGE',
    BREAKOUT_ROOM_DISCOVERABLE_TITLE: 'BREAKOUT_ROOM_DISCOVERABLE_TITLE',
    BREAKOUT_START_TIMER_MESSAGE: 'BREAKOUT_START_TIMER_MESSAGE',
    GENERAL_SEC: 'GENERAL_SEC',
    BREAKOUT_ROOM_RESTRICTED_MESSAGE: 'BREAKOUT_ROOM_RESTRICTED_MESSAGE',
    BREAKOUT_ROOM_NOT_ASSIGNED_ORGANIZER:
        'BREAKOUT_ROOM_NOT_ASSIGNED_ORGANIZER',
    BREAKOUT_ROOM_NOT_ASSIGNED_ATTENDEE_1:
        'BREAKOUT_ROOM_NOT_ASSIGNED_ATTENDEE_1',
    BREAKOUT_REQUEST_SENT: 'BREAKOUT_REQUEST_SENT',
    BREAKOUT_REQUEST_TO_JOIN: 'BREAKOUT_REQUEST_TO_JOIN',
    BREAKOUT_ANNOUNCEMENT_CREATE_TITLE: 'BREAKOUT_ANNOUNCEMENT_CREATE_TITLE',
    GENERAL_NEW: 'GENERAL_NEW',
    BREAKOUT_CLOSE_MODAL_TITLE: 'BREAKOUT_CLOSE_MODAL_TITLE',
    BREAKOUT_CLOSE_MODAL_SUBTITLE: 'BREAKOUT_CLOSE_MODAL_SUBTITLE',
    BREAKOUT_CLOSE_MODAL_CONFIRM_BUTTON: 'BREAKOUT_CLOSE_MODAL_CONFIRM_BUTTON',
    BREAKOUT_CLOSE_MODAL_CANCEL_BUTTON: 'BREAKOUT_CLOSE_MODAL_CANCEL_BUTTON',
    BREAKOUTS_NEW_BREAKOUT_TITLE: 'BREAKOUTS_NEW_BREAKOUT_TITLE',
    BREAKOUTS_EDIT_BREAKOUT_TITLE: 'BREAKOUTS_EDIT_BREAKOUT_TITLE',
    BREAKOUTS_NEW_BREAKOUT_SUBTITLE: 'BREAKOUTS_NEW_BREAKOUT_SUBTITLE',
    BREAKOUTS_RANDOM_ASSIGNMENT_TITLE: 'BREAKOUTS_RANDOM_ASSIGNMENT_TITLE',
    BREAKOUTS_RANDOM_ASSIGNMENT_SUBTITLE:
        'BREAKOUTS_RANDOM_ASSIGNMENT_SUBTITLE',
    BREAKOUTS_LIST_UPLOAD_TITLE: 'BREAKOUTS_LIST_UPLOAD_TITLE',
    BREAKOUTS_LIST_UPLOAD_SUBTITLE: 'BREAKOUTS_LIST_UPLOAD_SUBTITLE',
    BREAKOUTS_PARTICIPANTS_PER_ROOM_TEXT:
        'BREAKOUTS_PARTICIPANTS_PER_ROOM_TEXT',
    BREAKOUTS_PARTICIPANTS_PER_ROOM_SUBTEXT:
        'BREAKOUTS_PARTICIPANTS_PER_ROOM_SUBTEXT',
    BREAKOUTS_PARTICIPANTS_PER_ROOM_ERROR_TEXT:
        'BREAKOUTS_PARTICIPANTS_PER_ROOM_ERROR_TEXT',
    BREAKOUTS_SPEAKER_OR_HOST: 'BREAKOUTS_SPEAKER_OR_HOST',
    BREAKOUTS_SPEAKERS_AND_HOSTS: 'BREAKOUTS_SPEAKERS_AND_HOSTS',
    BREAKOUTS_ACTIVE_PARTICIPANTS: 'BREAKOUTS_ACTIVE_PARTICIPANTS',
    BREAKOUTS_PREVIEW_ROOMS: 'BREAKOUTS_PREVIEW_ROOMS',
    BREAKOUTS_DISCOVERABLE_TOGGLE_TITLE: 'BREAKOUTS_DISCOVERABLE_TOGGLE_TITLE',
    BREAKOUTS_DISCOVERABLE_TOGGLE_SUBTITLE:
        'BREAKOUTS_DISCOVERABLE_TOGGLE_SUBTITLE',
    BREAKOUTS_DISCOVERABLE_TOGGLE_INFO_TEXT:
        'BREAKOUTS_DISCOVERABLE_TOGGLE_INFO_TEXT',
    BREAKOUTS_SPEAKER_HOST_MOVE_FREELY_INFO_TEXT:
        'BREAKOUTS_SPEAKER_HOST_MOVE_FREELY_INFO_TEXT',
    BREAKOUTS_START: 'BREAKOUTS_START',
    BREAKOUTS_SAVE_FOR_LATER: 'BREAKOUTS_SAVE_FOR_LATER',
    BREAKOUTS_BREAKOUT_SAVED_TOAST_TITLE:
        'BREAKOUTS_BREAKOUT_SAVED_TOAST_TITLE',
    BREAKOUTS_BREAKOUT_SAVED_TOAST_MESSAGE_SINGULAR:
        'BREAKOUTS_BREAKOUT_SAVED_TOAST_MESSAGE_SINGULAR',
    BREAKOUTS_BREAKOUT_SAVED_TOAST_MESSAGE_PLURAL:
        'BREAKOUTS_BREAKOUT_SAVED_TOAST_MESSAGE_PLURAL',
    BREAKOUTS_NEW_ROOMS_ADDED_MODAL_HEADING:
        'BREAKOUTS_NEW_ROOMS_ADDED_MODAL_HEADING',
    BREAKOUTS_NEW_ROOMS_ADDED_MODAL_MESSAGE:
        'BREAKOUTS_NEW_ROOMS_ADDED_MODAL_MESSAGE',
    BREAKOUTS_OPEN_BREAKOUT_INFO_TEXT: 'BREAKOUTS_OPEN_BREAKOUT_INFO_TEXT',
    BREAKOUTS_OPEN_BREAKOUT: 'BREAKOUTS_OPEN_BREAKOUT',
    BREAKOUTS_SAVE_BREAKOUT: 'BREAKOUTS_SAVE_BREAKOUT',
    BREAKOUTS_PREVIEW_BREAKOUT_TITLE: 'BREAKOUTS_PREVIEW_BREAKOUT_TITLE',
    BREAKOUTS_PREVIEW_BREAKOUT_SUBTITLE: 'BREAKOUTS_PREVIEW_BREAKOUT_SUBTITLE',
    BREAKOUTS_ROOM_COUNT: 'BREAKOUTS_ROOM_COUNT',
    BREAKOUTS_ROOMS_COUNT: 'BREAKOUTS_ROOMS_COUNT',
    BREAKOUTS_EDIT_NAME_ERROR_MESSAGE: 'BREAKOUTS_EDIT_NAME_ERROR_MESSAGE',
    BREAKOUTS_EDIT_BREAKOUT_SUBTITLE: 'BREAKOUTS_EDIT_BREAKOUT_SUBTITLE',
    BREAKOUTS_EDIT_ROOM: 'BREAKOUTS_EDIT_ROOM',
    BREAKOUTS_EDIT_ROOM_NAME: 'BREAKOUTS_EDIT_ROOM_NAME',
    BREAKOUTS_REMOVE_PARTICIPANTS_TITLE: 'BREAKOUTS_REMOVE_PARTICIPANTS_TITLE',
    BREAKOUTS_REMOVE_PARTICIPANTS_SUBTITLE:
        'BREAKOUTS_REMOVE_PARTICIPANTS_SUBTITLE',
    BREAKOUT_USER_NOT_PRESENT_IN_ROOM: 'BREAKOUT_USER_NOT_PRESENT_IN_ROOM',
    BREAKOUTS_ROOMS_EDITED_TOAST_TITLE: 'BREAKOUTS_ROOMS_EDITED_TOAST_TITLE',
    BREAKOUTS_ROOMS_EDITED_TOAST_MESSAGE:
        'BREAKOUTS_ROOMS_EDITED_TOAST_MESSAGE',
    BREAKOUTS_BREAKOUT_RHS_TITLE: 'BREAKOUTS_BREAKOUT_RHS_TITLE',
    BREAKOUTS_ANNOUNCEMENT_SUBTEXT: 'BREAKOUTS_ANNOUNCEMENT_SUBTEXT',
    BREAKOUTS_ADD_TIMER: 'BREAKOUTS_ADD_TIMER',
    BREAKOUTS_SEARCH_ATTENDEES_PLACEHOLDER:
        'BREAKOUTS_SEARCH_ATTENDEES_PLACEHOLDER',
    BREAKOUTS_USER_NOT_IN_ROOM: 'BREAKOUTS_USER_NOT_IN_ROOM',
    BREAKOUTS_USER_NOT_FOUND: 'BREAKOUTS_USER_NOT_FOUND',
    BREAKOUTS_CREATE_ANNOUNCEMENT: 'BREAKOUTS_CREATE_ANNOUNCEMENT',
    BREAKOUTS_CREATE_ANNOUNCEMENT_TOOLTIP_TEXT:
        'BREAKOUTS_CREATE_ANNOUNCEMENT_TOOLTIP_TEXT',
    BREAKOUTS_BACKSTAGE_DISABLED_WARNING_TEXT:
        'BREAKOUTS_BACKSTAGE_DISABLED_WARNING_TEXT',
    BREAKOUTS_PERSON_REQUESTING_JOIN: 'BREAKOUTS_PERSON_REQUESTING_JOIN',
    BREAKOUTS_PEOPLE_REQUESTING_JOIN: 'BREAKOUTS_PEOPLE_REQUESTING_JOIN',
    BREAKOUTS_JOIN_REQUEST_MODAL_TITLE: 'BREAKOUTS_JOIN_REQUEST_MODAL_TITLE',
    BREAKOUTS_JOIN_REQUEST_MODAL_SUBTITLE:
        'BREAKOUTS_JOIN_REQUEST_MODAL_SUBTITLE',
    BREAKOUTS_JOIN_REQUEST_MODAL_SECONDARY_TEXT:
        'BREAKOUTS_JOIN_REQUEST_MODAL_SECONDARY_TEXT',
    BREAKOUTS_JOIN_REQUEST_MODAL_SECONDARY_SUBTEXT:
        'BREAKOUTS_JOIN_REQUEST_MODAL_SECONDARY_SUBTEXT',
    BREAKOUTS_JOIN_REQUEST_MODAL_NO_REQUESTS_TEXT:
        'BREAKOUTS_JOIN_REQUEST_MODAL_NO_REQUESTS_TEXT',
    BREAKOUTS_JOIN_REQUEST_MODAL_SELECT_ALL:
        'BREAKOUTS_JOIN_REQUEST_MODAL_SELECT_ALL',
    BREAKOUTS_JOIN_REQUEST_MODAL_DESELECT_ALL:
        'BREAKOUTS_JOIN_REQUEST_MODAL_DESELECT_ALL',
    BREAKOUTS_CSV_UPLOADER_TEXT: 'BREAKOUTS_CSV_UPLOADER_TEXT',
    BREAKOUTS_CSV_UPLOADER_SUBTEXT: 'BREAKOUTS_CSV_UPLOADER_SUBTEXT',
    BREAKOUTS_DOWNLOAD_TEMPLATE: 'BREAKOUTS_DOWNLOAD_TEMPLATE',
    BREAKOUTS_DOWNLOAD_TEMPLATE_SUBTEXT: 'BREAKOUTS_DOWNLOAD_TEMPLATE_SUBTEXT',
    BREAKOUTS_UPLOAD_CSV_FILE: 'BREAKOUTS_UPLOAD_CSV_FILE',
    BREAKOUTS_UPLOAD_CSV_FILE_SUBTEXT: 'BREAKOUTS_UPLOAD_CSV_FILE_SUBTEXT',
    BREAKOUTS_DOWNLOAD_ERROR_CSV: 'BREAKOUTS_DOWNLOAD_ERROR_CSV',
    BREAKOUTS_REQUIRED_DETAILS_HEADING: 'BREAKOUTS_REQUIRED_DETAILS_HEADING',
    BREAKOUTS_REQUIRED_DETAILS_MESSAGE: 'BREAKOUTS_REQUIRED_DETAILS_MESSAGE',
    GENERAL_UPDATE: 'GENERAL_UPDATE',
    BREAKOUTS_NEED_MORE_ROOMS_TITLE: 'BREAKOUTS_NEED_MORE_ROOMS_TITLE',
    BREAKOUTS_NEED_MORE_ROOMS_SUBTITLE: 'BREAKOUTS_NEED_MORE_ROOMS_SUBTITLE',
    BREAKOUTS_CREATE_NEW_ROOMS: 'BREAKOUTS_CREATE_NEW_ROOMS',
    CREATE_BREAKOUT_ROOMS: 'CREATE_BREAKOUT_ROOMS',
    CREATE_BREAKOUT_ROOMS_DESC: 'CREATE_BREAKOUT_ROOMS_DESC',
    BREAKOUTS_NO_MORE_PARTICIPANTS: 'BREAKOUTS_NO_MORE_PARTICIPANTS',
    BREAKOUTS_TAB_TITLE: 'BREAKOUTS_TAB_TITLE',
    BREAKOUTS_TAB_SUBTITLE: 'BREAKOUTS_TAB_SUBTITLE',
    BREAKOUTS_UNREGISTERED_USERS_INFO_TEXT:
        'BREAKOUTS_UNREGISTERED_USERS_INFO_TEXT',
    BREAKOUTS_ROOM_FULL_INFO_TEXT: 'BREAKOUTS_ROOM_FULL_INFO_TEXT',
    BREAKOUTS_RESTRICTED_ROOM_INFO_TEXT: 'BREAKOUTS_RESTRICTED_ROOM_INFO_TEXT',
    BREAKOUTS_CREATE_NEW: 'BREAKOUTS_CREATE_NEW',
    GENERAL_WARNING: 'GENERAL_WARNING',
    GENERAL_OPTION_AVAILABLE_AFTER_SESSION_START:
        'GENERAL_OPTION_AVAILABLE_AFTER_SESSION_START',
    BREAKOUT_TYPE_CHANGE_OPTION_DISABLED_TOOLTIP_TEXT:
        'BREAKOUT_TYPE_CHANGE_OPTION_DISABLED_TOOLTIP_TEXT',
    BREAKOUT_TYPE_SELECTION_HEADING: 'BREAKOUT_TYPE_SELECTION_HEADING',
    BREAKOUT_PARTICIPANT_PER_ROOM_HEADING:
        'BREAKOUT_PARTICIPANT_PER_ROOM_HEADING',
    BREAKOUT_PARTICIPANT_PER_ROOM_SUBHEADING:
        'BREAKOUT_PARTICIPANT_PER_ROOM_SUBHEADING',
    BREAKOUT_PARTICIPANT_PER_ROOM_POINT_FIRST:
        'BREAKOUT_PARTICIPANT_PER_ROOM_POINT_FIRST',
    BREAKOUT_PARTICIPANT_PER_ROOM_POINT_SECOND:
        'BREAKOUT_PARTICIPANT_PER_ROOM_POINT_SECOND',
    BREAKOUT_TYPE_SELECTION_DISABLED: 'BREAKOUT_TYPE_SELECTION_DISABLED',
    BREAKOUT_UPLOAD_PARTICIPANTS_LIST_HEADING:
        'BREAKOUT_UPLOAD_PARTICIPANTS_LIST_HEADING',
    BREAKOUT_UPLOAD_PARTICIPANTS_LIST_SUBHEADING:
        'BREAKOUT_UPLOAD_PARTICIPANTS_LIST_SUBHEADING',
    BREAKOUT_PARTICIPANTS_DETAILS_UPLOAD_SUCCESS:
        'BREAKOUT_PARTICIPANTS_DETAILS_UPLOAD_SUCCESS',
    BREAKOUT_ERROR_DELETING_FILE: 'BREAKOUT_ERROR_DELETING_FILE',
    BREAKOUT_UPLOAD_FAILED_TEXT: 'BREAKOUT_UPLOAD_FAILED_TEXT',
    BREAKOUT_UPLOAD_USERS_LIST: 'BREAKOUT_UPLOAD_USERS_LIST',
    BREAKOUT_UPLOAD_NEW_FILE: 'BREAKOUT_UPLOAD_NEW_FILE',
    BREAKOUT_MODIFY_LIST: 'BREAKOUT_MODIFY_LIST',
    BREAKOUT_DISCOVERABLE_TOGGLE_HEADING:
        'BREAKOUT_DISCOVERABLE_TOGGLE_HEADING',
    BREAKOUT_DISCOVERABLE_TOGGLE_SUBHEADING:
        'BREAKOUT_DISCOVERABLE_TOGGLE_SUBHEADING',
    BREAKOUT_LIMIT_WARNING_TEXT: 'BREAKOUT_LIMIT_WARNING_TEXT',
    BREAKOUT_NOT_INVITED_USERS_ERROR_TEXT:
        'BREAKOUT_NOT_INVITED_USERS_ERROR_TEXT',
    BREAKOUT_CSV_ERROR_DELETING_FILE: 'BREAKOUT_CSV_ERROR_DELETING_FILE',
    BREAKOUT_CSV_DELETED_SUCCESSFULLY: 'BREAKOUT_CSV_DELETED_SUCCESSFULLY',
    BREAKOUT_USERS_ADDED_TITLE_SINGULAR: 'BREAKOUT_USERS_ADDED_TITLE_SINGULAR',
    BREAKOUT_USERS_ADDED_TITLE_PLURAL: 'BREAKOUT_USERS_ADDED_TITLE_PLURAL',
    BREAKOUT_USERS_ADDED_SUBTITLE: 'BREAKOUT_USERS_ADDED_SUBTITLE',
    BREAKOUT_CSV_FORMAT_NOT_SUPPORTED: 'BREAKOUT_CSV_FORMAT_NOT_SUPPORTED',
    BREAKOUT_CSV_FILE_UPLOAD_CANCELLED: 'BREAKOUT_CSV_FILE_UPLOAD_CANCELLED',
    BREAKOUT_CSV_ERROR_UPLOADING_FILE: 'BREAKOUT_CSV_ERROR_UPLOADING_FILE',
    BREAKOUT_CSV_FILE_UPLOADED_SUCCESS: 'BREAKOUT_CSV_FILE_UPLOADED_SUCCESS',
    BREAKOUT_CSV_FILE_UPLOADED_SUCCESS_SUBTEXT:
        'BREAKOUT_CSV_FILE_UPLOADED_SUCCESS_SUBTEXT',
    BREAKOUT_CSV_DETAILS_UPLOAD_SUCCESS_SINGULAR:
        'BREAKOUT_CSV_DETAILS_UPLOAD_SUCCESS_SINGULAR',
    BREAKOUT_CSV_DETAILS_UPLOAD_SUCCESS_PLURAL:
        'BREAKOUT_CSV_DETAILS_UPLOAD_SUCCESS_PLURAL',
    BREAKOUT_CSV_DETAILS_HAVE_ISSUES_SINGULAR:
        'BREAKOUT_CSV_DETAILS_HAVE_ISSUES_SINGULAR',
    BREAKOUT_CSV_DETAILS_HAVE_ISSUES_PLURAL:
        'BREAKOUT_CSV_DETAILS_HAVE_ISSUES_PLURAL',
    BREAKOUT_CSV_UPLOAD_WAS_CANCELLED: 'BREAKOUT_CSV_UPLOAD_WAS_CANCELLED',
    BREAKOUT_CSV_HAD_ISSUES: 'BREAKOUT_CSV_HAD_ISSUES',
    BREAKOUT_CSV_UPLOAD_ABORTED: 'BREAKOUT_CSV_UPLOAD_ABORTED',
    BREAKOUT_CSV_FILE_HAD_ERROR: 'BREAKOUT_CSV_FILE_HAD_ERROR',
    BREAKOUT_CSV_UPDATE_AND_UPLOAD: 'BREAKOUT_CSV_UPDATE_AND_UPLOAD',
    BREAKOUT_CSV_DOCUMENT_UPLOADED: 'BREAKOUT_CSV_DOCUMENT_UPLOADED',
    BREAKOUT_CSV_DOCUMENT_UPLOADED_SUBTEXT:
        'BREAKOUT_CSV_DOCUMENT_UPLOADED_SUBTEXT',
    BREAKOUT_CSV_UPLOAD_AGAIN_TITLE: 'BREAKOUT_CSV_UPLOAD_AGAIN_TITLE',
    BREAKOUT_CSV_UPLOAD_AGAIN_SUBTITLE: 'BREAKOUT_CSV_UPLOAD_AGAIN_SUBTITLE',
    BREAKOUT_CSV_UPLOAD_NEW: 'BREAKOUT_CSV_UPLOAD_NEW',
    BREAKOUT_CSV_DELETE: 'BREAKOUT_CSV_DELETE',
    BREAKOUT_ARE_OPEN_TOAST_TITLE: 'BREAKOUT_ARE_OPEN_TOAST_TITLE',
    BREAKOUT_ARE_OPEN_TOAST_SUBTITLE: 'BREAKOUT_ARE_OPEN_TOAST_SUBTITLE',
    BREAKOUT_UPLOADING_DOCUMENT: 'BREAKOUT_UPLOADING_DOCUMENT',
    BREAKOUT_CSV_FILE_MULTIPLE_ERRORS: 'BREAKOUT_CSV_FILE_MULTIPLE_ERRORS',
    BREAKOUT_ROOM_NAME_MISSING_ERROR_TEXT:
        'BREAKOUT_ROOM_NAME_MISSING_ERROR_TEXT',
    BREAKOUT_CSV_FILE_MULTIPLE_ERRORS_TEXT:
        'BREAKOUT_CSV_FILE_MULTIPLE_ERRORS_TEXT',
    BREAKOUT_CSV_FILE_EMPTY: 'BREAKOUT_CSV_FILE_EMPTY',
    BREAKOUT_CSV_FILE_EMPTY_MESSAGE: 'BREAKOUT_CSV_FILE_EMPTY_MESSAGE',
    BREAKOUT_CSV_FILE_EMPTY_CAUSE: 'BREAKOUT_CSV_FILE_EMPTY_CAUSE',
    BREAKOUT_CSV_FILE_INVALID_HEADERS: 'BREAKOUT_CSV_FILE_INVALID_HEADERS',
    BREAKOUT_JOIN_SUCCESS_TOAST_TITLE: 'BREAKOUT_JOIN_SUCCESS_TOAST_TITLE',
    BREAKOUT_JOIN_SUCCESS_TOAST_DESCRIPTION:
        'BREAKOUT_JOIN_SUCCESS_TOAST_DESCRIPTION',
    RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_LABEL:
        'RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_LABEL',
    RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_HINT:
        'RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_HINT',
    PEOPLE_SEARCH_DROPDOWN_TITLE: 'PEOPLE_SEARCH_DROPDOWN_TITLE',
    GENERAL_FILTERS: 'GENERAL_FILTERS',
    GENERAL_APPLY: 'GENERAL_APPLY',
    DISABLE_FS_MAXIMISE_VIEW: 'DISABLE_FS_MAXIMISE_VIEW',
    FLUID_SPACE_ONBOARDING_TITLE_1: 'FLUID_SPACE_ONBOARDING_TITLE_1',
    FLUID_SPACE_ONBOARDING_CONTENT_1: 'FLUID_SPACE_ONBOARDING_CONTENT_1',
    FLUID_SPACE_ONBOARDING_TITLE_2: 'FLUID_SPACE_ONBOARDING_TITLE_2',
    FLUID_SPACE_ONBOARDING_CONTENT_2_a: 'FLUID_SPACE_ONBOARDING_CONTENT_2_a',
    FLUID_SPACE_ONBOARDING_CONTENT_2_b: 'FLUID_SPACE_ONBOARDING_CONTENT_2_b',
    FLUID_SPACE_ONBOARDING_TITLE_3: 'FLUID_SPACE_ONBOARDING_TITLE_3',
    FLUID_SPACE_ONBOARDING_CONTENT_3: 'FLUID_SPACE_ONBOARDING_CONTENT_3',
    FLUID_SPACE_ONBOARDING_CONTENT_4: 'FLUID_SPACE_ONBOARDING_CONTENT_4',
    FLUID_SPACE_ONBOARDING_CONTENT_5: 'FLUID_SPACE_ONBOARDING_CONTENT_5',
    FLUID_SPACE_ONBOARDING_CONTENT_6: 'FLUID_SPACE_ONBOARDING_CONTENT_6',
    FEEDBACK_RATING_FEEDBACK_SAVED: 'FEEDBACK_RATING_FEEDBACK_SAVED',
    OTHER_ATTENDEE_SPEAKERS: 'OTHER_ATTENDEE_SPEAKERS',
    OTHER_ATTENDEE_SPEAKER: 'OTHER_ATTENDEE_SPEAKER',
    ADVANCED_PEOPLE_SEARCH_PLACEHOLDER: 'ADVANCED_PEOPLE_SEARCH_PLACEHOLDER',
    DISABLE_TOOLTIP_CHECKBOX: 'DISABLE_TOOLTIP_CHECKBOX',
    GENERAL_RESULTS_FOUND_PLURAL: 'GENERAL_RESULTS_FOUND_PLURAL',
    GENERAL_RESULTS_FOUND_SINGULAR: 'GENERAL_RESULTS_FOUND_SINGULAR',
    SELECT_A_COUNTRY: 'SELECT_A_COUNTRY',
    GENERAL_ENTER_A: 'GENERAL_ENTER_A',
    GENERAL_ENTER: 'GENERAL_ENTER',
    FLUID_SPACE_PREVIEW: 'FLUID_SPACE_PREVIEW',
    SHOW_SPEAKERS: 'SHOW_SPEAKERS',
    HIDE_SPEAKERS: 'HIDE_SPEAKERS',
    SCREEN_SHARE_ERROR_RESTART_BROWSER: 'SCREEN_SHARE_ERROR_RESTART_BROWSER',
    FS_GAME_START: 'FS_GAME_START',
    STAGE_SWITCHED_TO_LAYOUT_MODE_GRID: 'STAGE_SWITCHED_TO_LAYOUT_MODE_GRID',
    STAGE_SWITCHED_TO_SCREESHARE_OFF: 'STAGE_SWITCHED_TO_SCREESHARE_OFF',
    STAGE_SWITCHED_TO_SCREESHARE_ON: 'STAGE_SWITCHED_TO_SCREESHARE_ON',
    STAGE_SWITCHED_TO_PRERECORDED_VIDEO: 'STAGE_SWITCHED_TO_PRERECORDED_VIDEO',
    STAGE_SWITCHED_TO_LAYOUT_MODE_ONE_ON_ONE:
        'STAGE_SWITCHED_TO_LAYOUT_MODE_ONE_ON_ONE',
    STAGE_SWITCHED_TO_LAYOUT_MODE_ONE_ON_ONE_CONTENT:
        'STAGE_SWITCHED_TO_LAYOUT_MODE_ONE_ON_ONE_CONTENT',
    STAGE_SWITCHED_TO_LAYOUT_MODE_ACTIVE_SPEAKER:
        'STAGE_SWITCHED_TO_LAYOUT_MODE_ACTIVE_SPEAKER',
    STAGE_SWITCHED_TO_LAYOUT_MODE_GRID_CONTENT:
        'STAGE_SWITCHED_TO_LAYOUT_MODE_GRID_CONTENT',
    STAGE_SWITCHED_TO_LAYOUT_MODE_ACTIVE_SPEAKER_CONTENT:
        'STAGE_SWITCHED_TO_LAYOUT_MODE_ACTIVE_SPEAKER_CONTENT',
    STAGE_SWITCHED_TO_LAYOUT_MODE_SIDEBAR_SPEAKER:
        'STAGE_SWITCHED_TO_LAYOUT_MODE_SIDEBAR_SPEAKER',
    STAGE_SWITCHED_TO_LAYOUT_MODE_CONTENT:
        'STAGE_SWITCHED_TO_LAYOUT_MODE_CONTENT',
    STAGE_SWITCHED_TO_LAYOUT_MODE_NEWS: 'STAGE_SWITCHED_TO_LAYOUT_MODE_NEWS',
    STAGE_SWITCHED_TO_LAYOUT_MODE_SIDEBAR_CONTENT:
        'STAGE_SWITCHED_TO_LAYOUT_MODE_SIDEBAR_CONTENT',
    STAGE_SWITCHED_TO_LAYOUT_MODE_CONTENT_LAYOUT:
        'STAGE_SWITCHED_TO_LAYOUT_MODE_CONTENT_LAYOUT',
    STAGE_SWITCHED_TO_LAYOUT_MODE_NEWS_CONTENT:
        'STAGE_SWITCHED_TO_LAYOUT_MODE_NEWS_CONTENT',
    SYSTEM_CHECK_CANCEL_TEST: 'SYSTEM_CHECK_CANCEL_TEST',
    SYSTEM_CHECK_CHECK_AGAIN: 'SYSTEM_CHECK_CHECK_AGAIN',
    SYSTEM_CHECK_STOP_RECORDING: 'SYSTEM_CHECK_STOP_RECORDING',
    SYSTEM_CHECK_BROWSER_SUPPORTED_TITLE:
        'SYSTEM_CHECK_BROWSER_SUPPORTED_TITLE',
    SYSTEM_CHECK_BROWSER_SUPPORTED_SUBTITLE:
        'SYSTEM_CHECK_BROWSER_SUPPORTED_SUBTITLE',
    SYSTEM_CHECK_BROWSER_NOT_SUPPORTED_TITLE:
        'SYSTEM_CHECK_BROWSER_NOT_SUPPORTED_TITLE',
    SYSTEM_CHECK_BROWSER_NOT_SUPPORTED_SUBTITLE:
        'SYSTEM_CHECK_BROWSER_NOT_SUPPORTED_SUBTITLE',
    GENERAL_BROWSER: 'GENERAL_BROWSER',
    GENERAL_VERSION: 'GENERAL_VERSION',
    GENERAL_OPERATING_SYSTEM: 'GENERAL_OPERATING_SYSTEM',
    SYSTEM_CHECK_CAMERA_SUPPORTED_TITLE: 'SYSTEM_CHECK_CAMERA_SUPPORTED_TITLE',
    SYSTEM_CHECK_CAMERA_SUPPORTED_SUBTITLE:
        'SYSTEM_CHECK_CAMERA_SUPPORTED_SUBTITLE',
    SYSTEM_CHECK_CAMERA_NOT_SUPPORTED_TITLE:
        'SYSTEM_CHECK_CAMERA_NOT_SUPPORTED_TITLE',
    SYSTEM_CHECK_CAMERA_NOT_SUPPORTED_SUBTITLE:
        'SYSTEM_CHECK_CAMERA_NOT_SUPPORTED_SUBTITLE',
    SYSTEM_CHECK_CAMERA_NO_PERMISSION_TEXT:
        'SYSTEM_CHECK_CAMERA_NO_PERMISSION_TEXT',
    GENERAL_CONNECTIVITY: 'GENERAL_CONNECTIVITY',
    SYSTEM_CHECK_RESULTS_BROWSER: 'SYSTEM_CHECK_RESULTS_BROWSER',
    SYSTEM_CHECK_RESULTS_CAMERA: 'SYSTEM_CHECK_RESULTS_CAMERA',
    SYSTEM_CHECK_RESULTS_MIC: 'SYSTEM_CHECK_RESULTS_MIC',
    SYSTEM_CHECK_RESULTS_SPEAKER: 'SYSTEM_CHECK_RESULTS_SPEAKER',
    SYSTEM_CHECK_RESULTS_CONNECTIVITY: 'SYSTEM_CHECK_RESULTS_CONNECTIVITY',
    SYSTEM_CHECK_RESULTS_SCREENSHARE: 'SYSTEM_CHECK_RESULTS_SCREENSHARE',
    SYSTEM_CHECK_SPEAKER_SUPPORTED_TITLE:
        'SYSTEM_CHECK_SPEAKER_SUPPORTED_TITLE',
    SYSTEM_CHECK_SPEAKER_SUPPORTED_SUBTITLE:
        'SYSTEM_CHECK_SPEAKER_SUPPORTED_SUBTITLE',
    SYSTEM_CHECK_SPEAKER_NOT_SUPPORTED_TITLE:
        'SYSTEM_CHECK_SPEAKER_NOT_SUPPORTED_TITLE',
    SYSTEM_CHECK_SPEAKER_NOT_SUPPORTED_SUBTITLE:
        'SYSTEM_CHECK_SPEAKER_NOT_SUPPORTED_SUBTITLE',
    SYSTEM_CHECK_PLAY_RINGTONE_AGAIN: 'SYSTEM_CHECK_PLAY_RINGTONE_AGAIN',
    SYSTEM_CHECK_PLAY_SOUND_FOR_TESTING: 'SYSTEM_CHECK_PLAY_SOUND_FOR_TESTING',
    GENERAL_TESTING_SPEAKER: 'GENERAL_TESTING_SPEAKER',
    SYSTEM_CHECK_DIAGNOSIS_IN_PROGRESS: 'SYSTEM_CHECK_DIAGNOSIS_IN_PROGRESS',
    SYSTEM_CHECK_HEADING: 'SYSTEM_CHECK_HEADING',
    SYSTEM_CHECK_HEAR_YOUR_VOICE: 'SYSTEM_CHECK_HEAR_YOUR_VOICE',
    SYSTEM_CHECK_VOICE_RECORDING: 'SYSTEM_CHECK_VOICE_RECORDING',
    SYSTEM_CHECK_RECORD_YOUR_VOICE: 'SYSTEM_CHECK_RECORD_YOUR_VOICE',
    SYSTEM_CHECK_CLICK_TO_RECORD: 'SYSTEM_CHECK_CLICK_TO_RECORD',
    SYSTEM_CHECK_MIC_SUPPORTED_TITLE: 'SYSTEM_CHECK_MIC_SUPPORTED_TITLE',
    SYSTEM_CHECK_MIC_SUPPORTED_SUBTITLE: 'SYSTEM_CHECK_MIC_SUPPORTED_SUBTITLE',
    SYSTEM_CHECK_MIC_NOT_SUPPORTED_TITLE:
        'SYSTEM_CHECK_MIC_NOT_SUPPORTED_TITLE',
    SYSTEM_CHECK_MIC_NOT_SUPPORTED_SUBTITLE:
        'SYSTEM_CHECK_MIC_NOT_SUPPORTED_TITLE',
    SYSTEM_CHECK_MIC_NO_PERMISSION_TEXT: 'SYSTEM_CHECK_MIC_NO_PERMISSION_TEXT',
    START_TEST: 'START_TEST',
    SYSTEM_CHECK_SUBHEADING: 'SYSTEM_CHECK_SUBHEADING',
    SYSTEM_CHECK_START_TAB_SUBTITLE: 'SYSTEM_CHECK_START_TAB_SUBTITLE',
    SYSTEM_CHECK_RESULT_TAB_SUPPORTED_TITLE:
        'SYSTEM_CHECK_RESULT_TAB_SUPPORTED_TITLE',
    SYSTEM_CHECK_RESULT_TAB_SUPPORTED_SUBTITLE:
        'SYSTEM_CHECK_RESULT_TAB_SUPPORTED_SUBTITLE',
    SYSTEM_CHECK_RESULT_TAB_NOT_SUPPORTED_SUBTITLE:
        'SYSTEM_CHECK_RESULT_TAB_NOT_SUPPORTED_SUBTITLE',
    SYSTEM_CHECK_RESULT_TAB_NOT_SUPPORTED_TITLE_SINGLE_ISSUE:
        'SYSTEM_CHECK_RESULT_TAB_NOT_SUPPORTED_TITLE_SINGLE_ISSUE',
    SYSTEM_CHECK_RESULT_TAB_NOT_SUPPORTED_TITLE_MULTIPLE_ISSUES:
        'SYSTEM_CHECK_RESULT_TAB_NOT_SUPPORTED_TITLE_MULTIPLE_ISSUES',
    SYSTEM_CHECK_SUPPORTED_TEXT: 'SYSTEM_CHECK_SUPPORTED_TEXT',
    SYSTEM_CHECK_BROWSER_NOT_SUPPORTED_TEXT:
        'SYSTEM_CHECK_BROWSER_NOT_SUPPORTED_TEXT',
    SYSTEM_CHECK_SPEAKER_NOT_SUPPORTED_TEXT:
        'SYSTEM_CHECK_SPEAKER_NOT_SUPPORTED_TEXT',
    SYSTEM_CHECK_CAMERA_NOT_SUPPORTED_TEXT:
        'SYSTEM_CHECK_CAMERA_NOT_SUPPORTED_TEXT',
    SYSTEM_CHECK_MIC_NOT_SUPPORTED_TEXT: 'SYSTEM_CHECK_MIC_NOT_SUPPORTED_TEXT',
    SYSTEM_CHECK_CAMERA_ACCESS_BLOCKED: 'SYSTEM_CHECK_CAMERA_ACCESS_BLOCKED',
    SYSTEM_CHECK_MIC_ACCESS_BLOCKED: 'SYSTEM_CHECK_MIC_ACCESS_BLOCKED',
    SYSTEM_CHECK_CONNECTIVITY_NOT_SUPPORTED_TEXT:
        'SYSTEM_CHECK_CONNECTIVITY_NOT_SUPPORTED_TEXT',
    SYSTEM_CHECK_CONNECTIVITY_SUPPORTED_TEXT:
        'SYSTEM_CHECK_CONNECTIVITY_SUPPORTED_TEXT',
    SYSTEM_CHECK_FIREBASE_ERROR: 'SYSTEM_CHECK_FIREBASE_ERROR',
    SYSTEM_CHECK_WEBRTC_ERROR: 'SYSTEM_CHECK_WEBRTC_ERROR',
    SYSTEM_CHECK_CONNECTIVITY_SUPPORTED_TITLE:
        'SYSTEM_CHECK_CONNECTIVITY_SUPPORTED_TITLE',
    SYSTEM_CHECK_CONNECTIVITY_SUPPORTED_SUBTITLE:
        'SYSTEM_CHECK_CONNECTIVITY_SUPPORTED_SUBTITLE',
    SYSTEM_CHECK_CONNECTIVITY_GOOD_TITLE:
        'SYSTEM_CHECK_CONNECTIVITY_GOOD_TITLE',
    SYSTEM_CHECK_CONNECTIVITY_GOOD_SUBTITLE:
        'SYSTEM_CHECK_CONNECTIVITY_GOOD_SUBTITLE',
    SYSTEM_CHECK_CONNECTIVITY_NOT_SUPPORTED_TITLE:
        'SYSTEM_CHECK_CONNECTIVITY_NOT_SUPPORTED_TITLE',
    SYSTEM_CHECK_CONNECTIVITY_NOT_SUPPORTED_SUBTITLE:
        'SYSTEM_CHECK_CONNECTIVITY_NOT_SUPPORTED_SUBTITLE',
    SYSTEM_CHECK_CONNECTIVITY_HEALTH_GOOD_TITLE:
        'SYSTEM_CHECK_CONNECTIVITY_HEALTH_GOOD_TITLE',
    SYSTEM_CHECK_CONNECTIVITY_HEALTH_GOOD_SUBTITLE:
        'SYSTEM_CHECK_CONNECTIVITY_HEALTH_GOOD_SUBTITLE',
    SYSTEM_CHECK_CONNECTIVITY_HEALTH_BAD_TITLE:
        'SYSTEM_CHECK_CONNECTIVITY_HEALTH_BAD_TITLE',
    SYSTEM_CHECK_CONNECTIVITY_HEALTH_BAD_SUBTITLE:
        'SYSTEM_CHECK_CONNECTIVITY_HEALTH_BAD_SUBTITLE',
    SYSTEM_CHECK_CONNECTIVITY_RUN_TEST_AGAIN_TITLE:
        'SYSTEM_CHECK_CONNECTIVITY_RUN_TEST_AGAIN_TITLE',
    SYSTEM_CHECK_CONNECTIVITY_STATS_MORE_DETAILS:
        'SYSTEM_CHECK_CONNECTIVITY_STATS_MORE_DETAILS',
    SYSTEM_CHECK_CONNECTIVITY_STATS_CLOSE_DETAILS:
        'SYSTEM_CHECK_CONNECTIVITY_STATS_CLOSE_DETAILS',
    SYSTEM_CHECK_CONNECTIVITY_FIREBASE_TITLE:
        'SYSTEM_CHECK_CONNECTIVITY_FIREBASE_TITLE',
    SYSTEM_CHECK_CONNECTIVITY_FIREBASE_TITLE_2:
        'SYSTEM_CHECK_CONNECTIVITY_FIREBASE_TITLE_2',
    SYSTEM_CHECK_CONNECTIVITY_WEBRTC_TITLE:
        'SYSTEM_CHECK_CONNECTIVITY_WEBRTC_TITLE',
    SYSTEM_CHECK_CONNECTIVITY_WEBRTC_TITLE_2:
        'SYSTEM_CHECK_CONNECTIVITY_WEBRTC_TITLE_2',
    SYSTEM_CHECK_CONNECTIVITY_SUPPORTED: 'SYSTEM_CHECK_CONNECTIVITY_SUPPORTED',
    SYSTEM_CHECK_CONNECTIVITY_NOT_SUPPORTED:
        'SYSTEM_CHECK_CONNECTIVITY_NOT_SUPPORTED',
    SYSTEM_CHECK_CONNECTIVITY_DOWNLOAD_STATS:
        'SYSTEM_CHECK_CONNECTIVITY_DOWNLOAD_STATS',
    SYSTEM_CHECK_CONNECTIVITY_UPLOAD_STATS:
        'SYSTEM_CHECK_CONNECTIVITY_UPLOAD_STATS',
    SYSTEM_CHECK_CONNECTIVITY_CHECK_AGAIN:
        'SYSTEM_CHECK_CONNECTIVITY_CHECK_AGAIN',
    SYSTEM_CHECK_MIC_SWITCH_ERROR: 'SYSTEM_CHECK_MIC_SWITCH_ERROR',
    SYSTEM_CHECK_CAMERA_SWITCH_ERROR: 'SYSTEM_CHECK_CAMERA_SWITCH_ERROR',
    SYSTEM_CHECK_CONNECTIVITY_AUDIO_ERROR:
        'SYSTEM_CHECK_CONNECTIVITY_AUDIO_ERROR',
    SYSTEM_CHECK_CONNECTIVITY_VIDEO_ERROR:
        'SYSTEM_CHECK_CONNECTIVITY_VIDEO_ERROR',
    SYSTEM_CHECK_VIDEO_AUDIO_NOT_AVAILABLE:
        'SYSTEM_CHECK_VIDEO_AUDIO_NOT_AVAILABLE',
    SYSTEM_CHECK_CONNECTIVITY_IN_PROGRESS:
        'SYSTEM_CHECK_CONNECTIVITY_IN_PROGRESS',
    SYSTEM_CHECK_CONNECTIVITY_TAB_ERROR_PERMISSIONS:
        'SYSTEM_CHECK_CONNECTIVITY_TAB_ERROR_PERMISSIONS',
    SYSTEM_CHECK_CONNECTIVITY_TAB_ERROR_ACCESS_DENIED:
        'SYSTEM_CHECK_CONNECTIVITY_TAB_ERROR_ACCESS_DENIED',
    SYSTEM_CHECK_CONNECTIVITY_TAB_ERROR_UNKNOWN:
        'SYSTEM_CHECK_CONNECTIVITY_TAB_ERROR_UNKNOWN',
    SYSTEM_CHECK_CONNECTIVITY_RECOMMENDATIONS:
        'SYSTEM_CHECK_CONNECTIVITY_RECOMMENDATIONS',
    SYSTEM_CHECK_CONNECTIVITY_RECOMMENDATIONS_CHECK_SETTINGS:
        'SYSTEM_CHECK_CONNECTIVITY_RECOMMENDATIONS_CHECK_SETTINGS',
    SYSTEM_CHECK_CONNECTIVITY_RECOMMENDATIONS_SWITCH_WIFI:
        'SYSTEM_CHECK_CONNECTIVITY_RECOMMENDATIONS_SWITCH_WIFI',
    SYSTEM_CHECK_CONNECTIVITY_RECOMMENDATIONS_AVOID_ACTIVITIES:
        'SYSTEM_CHECK_CONNECTIVITY_RECOMMENDATIONS_AVOID_ACTIVITIES',
    SYSTEM_CHECK_MOBILE_RESTRICTED_TEXT: 'SYSTEM_CHECK_MOBILE_RESTRICTED_TEXT',
    SYSTEM_CHECK_CONNECTIVITY_FIREBASE_CONNECTION_TOOLTIP:
        'SYSTEM_CHECK_CONNECTIVITY_FIREBASE_CONNECTION_TOOLTIP',
    SYSTEM_CHECK_CONNECTIVITY_WEBRTC_CONNECTION_TOOLTIP:
        'SYSTEM_CHECK_CONNECTIVITY_WEBRTC_CONNECTION_TOOLTIP',
    GENERAL_DETAILS: 'GENERAL_DETAILS',
    SYSTEM_CHECK_DROPDOWN_NO_DEVICE: 'SYSTEM_CHECK_DROPDOWN_NO_DEVICE',
    LIVE_ANNOUNCEMENT_CONNECTION: 'LIVE_ANNOUNCEMENT_CONNECTION',
    GENERAL_OLDEST: 'GENERAL_OLDEST',
    QNA_NEW_QUESTIONS_ADDED: 'QNA_NEW_QUESTIONS_ADDED',
    QNA_REFRESH_VOTES: 'QNA_REFRESH_VOTES',
    POST_ANNOUNCEMENT: 'POST_ANNOUNCEMENT',
    RHS_CUSTOMIZE_SHOW_LEADERBOARD_POINTS_LABEL:
        'RHS_CUSTOMIZE_SHOW_LEADERBOARD_POINTS_LABEL',
    RHS_CUSTOMIZE_SHOW_LEADERBOARD_POINTS_HINT:
        'RHS_CUSTOMIZE_SHOW_LEADERBOARD_POINTS_HINT',
    PROFILE_FIELD_JOBTITLE: 'PROFILE_FIELD_JOBTITLE',
    CHAT_MODERATE_FEED_TOOLTIP: 'CHAT_MODERATE_FEED_TOOLTIP',
    ACTIVATE_CHAT_MOD: 'ACTIVATE_CHAT_MOD',
    GENERAL_NOTE: 'GENERAL_NOTE',
    ANOTHER_SESSION_ONGOING_HEADING: 'ANOTHER_SESSION_ONGOING_HEADING',
    ANOTHER_SESSION_ONGOING_SUBHEADING: 'ANOTHER_SESSION_ONGOING_SUBHEADING',
    ANOTHER_SESSION_ONGOING_COMMON_HOST_SUBHEADING:
        'ANOTHER_SESSION_ONGOING_COMMON_HOST_SUBHEADING',
    MULTIPLE_SESSIONS_ONGOING_HEADING: 'MULTIPLE_SESSIONS_ONGOING_HEADING',
    MULTIPLE_SESSIONS_ONGOING_SUBHEADING:
        'MULTIPLE_SESSIONS_ONGOING_SUBHEADING',
    SESSION_START_BLOCKED: 'SESSION_START_BLOCKED',
    SESSION_RESUMED_BLOCKED: 'SESSION_RESUMED_BLOCKED',
    GENERAL_WHY: 'GENERAL_WHY',
    SYSTEM_CHECK_SETTINGS_NOT_SAVED: 'SYSTEM_CHECK_SETTINGS_NOT_SAVED',
    SYSTEM_CHECK_SCREENSHARE_NEEDS_USER_INTERACTION:
        'SYSTEM_CHECK_SCREENSHARE_NEEDS_USER_INTERACTION',
    SYSTEM_CHECK_SCREENSHARE_NEEDS_USER_INTERACTION_SUBTITLE:
        'SYSTEM_CHECK_SCREENSHARE_NEEDS_USER_INTERACTION_SUBTITLE',
    SYSTEM_CHECK_SCREENSHARE_SUPPORTED_TEXT:
        'SYSTEM_CHECK_SCREENSHARE_SUPPORTED_TEXT',
    SYSTEM_CHECK_SCREENSHARE_NOT_SUPPORTED_TEXT:
        'SYSTEM_CHECK_SCREENSHARE_NOT_SUPPORTED_TEXT',
    SYSTEM_CHECK_SCREENSHARE_NOT_SUPPORTED_SUBTITLE:
        'SYSTEM_CHECK_SCREENSHARE_NOT_SUPPORTED_SUBTITLE',
    SYSTEM_CHECK_SCREENSHARE_ACCESS_DENIED:
        'SYSTEM_CHECK_SCREENSHARE_ACCESS_DENIED',
    SYSTEM_CHECK_SCREENSHARE_START_CTA: 'SYSTEM_CHECK_SCREENSHARE_START_CTA',
    SYSTEM_CHECK_SCREENSHARE_SUPPORTED_TEXT_SUBTITLE:
        'SYSTEM_CHECK_SCREENSHARE_SUPPORTED_TEXT_SUBTITLE',
    SYSTEM_CHECK_SCREENSHARE_RETRY_CTA: 'SYSTEM_CHECK_SCREENSHARE_RETRY_CTA',
    SYSTEM_CHECK_SCREENSHARE_STOP_CTA: 'SYSTEM_CHECK_SCREENSHARE_STOP_CTA',
    SYSTEM_CHECK_SCREENSHARE_ACCESS_DENIED_SUBTITLE:
        'SYSTEM_CHECK_SCREENSHARE_ACCESS_DENIED_SUBTITLE',
    SYSTEM_CHECK_RESULT_TAB_SEEMS_FINE_HEADING:
        'SYSTEM_CHECK_RESULT_TAB_SEEMS_FINE_HEADING',
    SYSTEM_CHECK_RESULT_TAB_SEEMS_FINE_SUBHEADING:
        'SYSTEM_CHECK_RESULT_TAB_SEEMS_FINE_SUBHEADING',
    ERROR_BOUNDRY_LOAD_FAILED: 'ERROR_BOUNDRY_LOAD_FAILED',
    ERROR_BOUNDRY_TRY_AGAIN: 'ERROR_BOUNDRY_TRY_AGAIN',
    ERROR_BOUNDRY_RELOAD_CTA: 'ERROR_BOUNDRY_RELOAD_CTA',
    EDGE_CAM_MIC_UNBLOCK_MSG_1: 'EDGE_CAM_MIC_UNBLOCK_MSG_1',
    EDGE_CAM_MIC_UNBLOCK_MSG_2: 'EDGE_CAM_MIC_UNBLOCK_MSG_2',
    CHROME_CAM_MIC_UNBLOCK_MSG_1: 'CHROME_CAM_MIC_UNBLOCK_MSG_1',
    CHROME_CAM_MIC_UNBLOCK_MSG_2: 'CHROME_CAM_MIC_UNBLOCK_MSG_2',
    FIREFOX_CAM_MIC_UNBLOCK_MSG_1: 'FIREFOX_CAM_MIC_UNBLOCK_MSG_1',
    FIREFOX_CAM_MIC_UNBLOCK_MSG_2: 'FIREFOX_CAM_MIC_UNBLOCK_MSG_2',
    SAFARI_CAM_MIC_UNBLOCK_MSG_1: 'SAFARI_CAM_MIC_UNBLOCK_MSG_1',
    SAFARI_CAM_MIC_UNBLOCK_MSG_2: 'SAFARI_CAM_MIC_UNBLOCK_MSG_2',
    RICH_PROFILE_JOBTITLE_PLACEHOLDER: 'RICH_PROFILE_JOBTITLE_PLACEHOLDER',
    RHS_CUSTOMIZE_SHOW_RESOURCES_TAB_LABEL:
        'RHS_CUSTOMIZE_SHOW_RESOURCES_TAB_LABEL',
    RHS_CUSTOMIZE_SHOW_RESOURCES_TAB_TOOLTIP:
        'RHS_CUSTOMIZE_SHOW_RESOURCES_TAB_TOOLTIP',
    GENERAL_GIF: 'GENERAL_GIF',
    ARE_YOU_PLAYING_VIDEOS: 'ARE_YOU_PLAYING_VIDEOS',
    ENABLE_SCREEN_SHARE_ENHANCEMENT_IF_PLAYING_VIDEO:
        'ENABLE_SCREEN_SHARE_ENHANCEMENT_IF_PLAYING_VIDEO',
    BIG_NUDGE_VIEW_ALL_SESSIONS: 'BIG_NUDGE_VIEW_ALL_SESSIONS',
    BIG_NUDGE_VIEW_FULL_SCHEDULE_TEXT: 'BIG_NUDGE_VIEW_FULL_SCHEDULE_TEXT',
    BIG_NUDGE_JOIN_A_SESSION_HEADING: 'BIG_NUDGE_JOIN_A_SESSION_HEADING',
    BIG_NUDGE_JOIN_A_SESSION_SUBHEADING: 'BIG_NUDGE_JOIN_A_SESSION_SUBHEADING',
    GROUP_MEETING_ENABLE_RECORDING_DESCRIPTION:
        'GROUP_MEETING_ENABLE_RECORDING_DESCRIPTION',
    GROUP_MEETING_DISABLE_RECORDING_DESCRIPTION:
        'GROUP_MEETING_DISABLE_RECORDING_DESCRIPTION',
    GROUP_MEETING_RECORD_MEETING_CTA: 'GROUP_MEETING_RECORD_MEETING_CTA',
    GROUP_MEETING_STOP_RECORD_MEETING_CTA:
        'GROUP_MEETING_STOP_RECORD_MEETING_CTA',
    GROUP_MEETING_RECORD: 'GROUP_MEETING_RECORD',
    GROUP_MEETING_RECORDING_STARTED: 'GROUP_MEETING_RECORDING_STARTED',
    GROUP_MEETING_RECORDING_STOPPED: 'GROUP_MEETING_RECORDING_STOPPED',
    SYSTEM_CHECK_CHECK_TAB_IN_PROGRESS: 'SYSTEM_CHECK_CHECK_TAB_IN_PROGRESS',
    BACK_TO_SESSION: 'BACK_TO_SESSION',
    GENERAL_ONLINE: 'GENERAL_ONLINE',
    USER_ALREADY_IN_SESSION: 'USER_ALREADY_IN_SESSION',
    SCHEDULE_HEADING: 'SCHEDULE_HEADING',
    SESSION_REPLAY_CTA: 'SESSION_REPLAY_CTA',
    EARLY_ACCESS_JOIN_BUTTON_TOOLTIP: 'EARLY_ACCESS_JOIN_BUTTON_TOOLTIP',
    FLUID_SPACE_MOVE_AROUND: 'FLUID_SPACE_MOVE_AROUND',
    FLUID_SPACE_MOVE_AROUND_DESCRIPTION: 'FLUID_SPACE_MOVE_AROUND_DESCRIPTION',
    ALREADY_IN_GROUP_MEETING: 'ALREADY_IN_GROUP_MEETING',
    GENERAL: 'GENERAL',
    GENERAL_REPLACE: 'GENERAL_REPLACE',
    GENERAL_LAYOUT: 'GENERAL_LAYOUT',
    GENERAL_FEATURES: 'GENERAL_FEATURES',
    GENERAL_STANDARD: 'GENERAL_STANDARD',
    GENERAL_POSTERS: 'GENERAL_POSTERS',
    GENERAL_REPLACE_IMAGE: 'GENERAL_REPLACE_IMAGE',
    UPLOAD_IMAGE_EMPTY_PH: 'UPLOAD_IMAGE_EMPTY_PH',
    BOOTH_EDIT_BOOTH_NAME: 'BOOTH_EDIT_BOOTH_NAME',
    BOOTH_EDIT_BOOTH_NAME_PH: 'BOOTH_EDIT_BOOTH_NAME_PH',
    BOOTH_EDIT_BOOTH_NAME_SUBTITLE: 'BOOTH_EDIT_BOOTH_NAME_SUBTITLE',
    BOOTH_EDIT_BOOTH_NAME_ERROR: 'BOOTH_EDIT_BOOTH_NAME_ERROR',
    BOOTH_EDIT_BOOTH_NAME_UNIQUE_ERROR: 'BOOTH_EDIT_BOOTH_NAME_UNIQUE_ERROR',
    BOOTH_EDIT_BOOTH_LOGO_TOOLTIP_TEXT: 'BOOTH_EDIT_BOOTH_LOGO_TOOLTIP_TEXT',
    BOOTH_EDIT_BOOTH_LOGO_SUBTITLE: 'BOOTH_EDIT_BOOTH_LOGO_SUBTITLE',
    BOOTH_EDIT_BOOTH_TAG_SUBTITLE: 'BOOTH_EDIT_BOOTH_TAG_SUBTITLE',
    BOOTH_EDIT_BOOTH_TAG_PH_CREATE: 'BOOTH_EDIT_BOOTH_TAG_PH_CREATE',
    BOOTH_EDIT_BOOTH_TAG_PH: 'BOOTH_EDIT_BOOTH_TAG_PH',
    BOOTH_EDIT_BOOTH_LONG_DESC_ERROR: 'BOOTH_EDIT_BOOTH_LONG_DESC_ERROR',
    BOOTH_EDIT_BOOTH_LAYOUT: 'BOOTH_EDIT_BOOTH_LAYOUT',
    BOOTH_EDIT_BOOTH_LAYOUT_SUBTITLE: 'BOOTH_EDIT_BOOTH_LAYOUT_SUBTITLE',
    BOOTH_EDIT_BOOTH_BANNER_IMAGE: 'BOOTH_EDIT_BOOTH_BANNER_IMAGE',
    BOOTH_EDIT_BOOTH_BANNER_THUMBNAIL: 'BOOTH_EDIT_BOOTH_BANNER_THUMBNAIL',
    BOOTH_EDIT_BOOTH_BANNER_TOOLTIP: 'BOOTH_EDIT_BOOTH_BANNER_TOOLTIP',
    BOOTH_EDIT_BOOTH_BANNER_SUBTITLE: 'BOOTH_EDIT_BOOTH_BANNER_SUBTITLE',
    BOOTH_EDIT_BOOTH_BANNER_SUBTITLE_THUMB:
        'BOOTH_EDIT_BOOTH_BANNER_SUBTITLE_THUMB',
    BOOTH_EDIT_DOCUMENT_UPLOAD: 'BOOTH_EDIT_DOCUMENT_UPLOAD',
    BOOTH_EDIT_UPLOAD_PDF_FILE: 'BOOTH_EDIT_UPLOAD_PDF_FILE',
    BOOTH_EDIT_UPLOAD_PDF_SIZE: 'BOOTH_EDIT_UPLOAD_PDF_SIZE',
    BOOTH_EDIT_UPLOAD_PDF_BUTTON: 'BOOTH_EDIT_UPLOAD_PDF_BUTTON',
    BOOTH_EDIT_UPLOAD_PDF_ERROR: 'BOOTH_EDIT_UPLOAD_PDF_ERROR',
    BOOTH_EDIT_BANNER_VIDEO: 'BOOTH_EDIT_BANNER_VIDEO',
    BOOTH_EDIT_BANNER_VIDEO_SUBTITLE: 'BOOTH_EDIT_BANNER_VIDEO_SUBTITLE',
    BOOTH_EDIT_CUSTOM_BANNER_TITLE: 'BOOTH_EDIT_CUSTOM_BANNER_TITLE',
    BOOTH_EDIT_CUSTOM_BANNER_SUBTITLE: 'BOOTH_EDIT_CUSTOM_BANNER_SUBTITLE',
    BOOTH_EDIT_CUSTOM_BANNER_EDIT: 'BOOTH_EDIT_CUSTOM_BANNER_EDIT',
    BOOTH_EDIT_FEATURE_CHAT_SUBTITLE: 'BOOTH_EDIT_FEATURE_CHAT_SUBTITLE',
    BOOTH_EDIT_FEATURE_LOUNGE_SUBTITLE: 'BOOTH_EDIT_FEATURE_LOUNGE_SUBTITLE',
    BOOTH_EDIT_FEATURE_LOUNGE_SELECT_TABLES:
        'BOOTH_EDIT_FEATURE_LOUNGE_SELECT_TABLES',
    BOOTH_EDIT_FEATURE_LOUNGE_TABLES_MIN_TOOLTIP:
        'BOOTH_EDIT_FEATURE_LOUNGE_TABLES_MIN_TOOLTIP',
    BOOTH_EDIT_FEATURE_LOUNGE_TABLES_MAX_TOOLTIP:
        'BOOTH_EDIT_FEATURE_LOUNGE_TABLES_MAX_TOOLTIP',
    BOOTH_EDIT_LIVE_BROADCAST_SUBTITLE: 'BOOTH_EDIT_LIVE_BROADCAST_SUBTITLE',
    BOOTH_EDIT_REGISTER_INTEREST_SUBTITLE:
        'BOOTH_EDIT_REGISTER_INTEREST_SUBTITLE',
    BOOTH_EDIT_INTRO_TEXT: 'BOOTH_EDIT_INTRO_TEXT',
    BOOTH_EDIT_PROVIDE_OFFER_SUBTITLE: 'BOOTH_EDIT_PROVIDE_OFFER_SUBTITLE',
    BOOTH_EDIT_OFFER_LINKS: 'BOOTH_EDIT_OFFER_LINKS',
    BOOTH_EDIT_LAYOUT_STANDARD_DESC: 'BOOTH_EDIT_LAYOUT_STANDARD_DESC',
    BOOTH_EDIT_LAYOUT_CUSTOM_DESC: 'BOOTH_EDIT_LAYOUT_CUSTOM_DESC',
    BOOTH_EDIT_LAYOUT_POSTER_DESC: 'BOOTH_EDIT_LAYOUT_POSTER_DESC',
    BOOTH_EDIT_CUSTOM_BANNER_ERROR: 'BOOTH_EDIT_CUSTOM_BANNER_ERROR',
    BOOTH_EDIT_FIELDS_DISABLED_CUSTOM_BANNER_MASG:
        'BOOTH_EDIT_FIELDS_DISABLED_CUSTOM_BANNER_MASG',
    GROUP_MEETING_DEFAULT_SUMMARY: 'GROUP_MEETING_DEFAULT_SUMMARY',
    GROUP_MEETING_PEOPLE_IN_MEETING: 'GROUP_MEETING_PEOPLE_IN_MEETING',
    RESOURCES_TAB_DASH_TEXT: 'RESOURCES_TAB_DASH_TEXT',
    RESOURCES_TAB_TEXT: 'RESOURCES_TAB_TEXT',
    RESOURCES_EMPTY_ORG_TEXT: 'RESOURCES_EMPTY_ORG_TEXT',
    RESOURCES_EMPTY_ATTENDEE_TEXT: 'RESOURCES_EMPTY_ATTENDEE_TEXT',
    RESOURCES_EMPTY_STAGE_TEXT: 'RESOURCES_EMPTY_STAGE_TEXT',
    RESOURCES_EMPTY_STAGE_TEXT2: 'RESOURCES_EMPTY_STAGE_TEXT2',
    SL_CONFIRM_NEW_HEADING: 'SL_CONFIRM_NEW_HEADING',
    SL_CONFIRM_NEW_DESCRIPTION: 'SL_CONFIRM_NEW_DESCRIPTION',
    SL_CONFIRM_NEW_CTA: 'SL_CONFIRM_NEW_CTA',
    SL_CONFIRM_OLD_HEADING: 'SL_CONFIRM_OLD_HEADING',
    SL_CONFIRM_OLD_DESCRIPTION: 'SL_CONFIRM_OLD_DESCRIPTION',
    SL_CONFIRM_OLD_CTA: 'SL_CONFIRM_OLD_CTA',
    SL_SUCCESS_TOAST_NEW_HEADING: 'SL_SUCCESS_TOAST_NEW_HEADING',
    SL_SUCCESS_TOAST_NEW_DESCRIPTION: 'SL_SUCCESS_TOAST_NEW_DESCRIPTION',
    SL_SUCCESS_TOAST_OLD_HEADING: 'SL_SUCCESS_TOAST_OLD_HEADING',
    SL_SUCCESS_TOAST_OLD_DESCRIPTION: 'SL_SUCCESS_TOAST_OLD_DESCRIPTION',
    SL_LEARN_MORE: 'SL_LEARN_MORE',
    SL_REVERT_TO_OLD_LAYOUT: 'SL_REVERT_TO_OLD_LAYOUT',
    SL_TRY_NEW_LAYOUT: 'SL_TRY_NEW_LAYOUT',
    STREAMING_RESOLUTION_DROPDOWN_ITEM_NAME:
        'STREAMING_RESOLUTION_DROPDOWN_ITEM_NAME',
    CURRENT_QUALITY: 'CURRENT_QUALITY',
    SESSION_VIDEO: 'SESSION_VIDEO',
    RECORDING: 'RECORDING',
    CHANGE_QUALITY: 'CHANGE_QUALITY',
    STREAMING_SETTING_MODAL_TEXT_1: 'STREAMING_SETTING_MODAL_TEXT_1',
    STREAMING_SETTING_MODAL_TEXT_2: 'STREAMING_SETTING_MODAL_TEXT_2',
    STREAMING_SETTING_MODAL_TEXT_3: 'STREAMING_SETTING_MODAL_TEXT_3',
    STREAMING_SETTING_MODAL_TEXT_4: 'STREAMING_SETTING_MODAL_TEXT_4',
    STREAMING_SETTING_MODAL_RECORDED_TITLE:
        'STREAMING_SETTING_MODAL_RECORDED_TITLE',
    STREAMING_SETTING_MODAL_RECORDED_TEXT_DESCRIPTION:
        'STREAMING_SETTING_MODAL_RECORDED_TEXT_DESCRIPTION',
    STREAMING_SETTING_MODAL_STREAMING_TITLE:
        'STREAMING_SETTING_MODAL_STREAMING_TITLE',
    STREAMING_SETTING_MODAL_STREAMING_TEXT_DESCRIPTION:
        'STREAMING_SETTING_MODAL_STREAMING_TEXT_DESCRIPTION',
    DASHBOARD_STREAMING_SETTING_TITLE: 'DASHBOARD_STREAMING_SETTING_TITLE',
    DASHBOARD_STREAMING_SETTING_SUBTITLE:
        'DASHBOARD_STREAMING_SETTING_SUBTITLE',
    DASHBOARD_STREAMING_SETTING_DESCRIPTION:
        'DASHBOARD_STREAMING_SETTING_DESCRIPTION',
    DASHBOARD_VIDEO_UPLOAD_DESCRIPTION: 'DASHBOARD_VIDEO_UPLOAD_DESCRIPTION',
    RESUME_FLUID_SPACE: 'RESUME_FLUID_SPACE',
    JOINING_SOON: 'JOINING_SOON',
    PREVIEWING_SESSION: 'PREVIEWING_SESSION',
    END_FLUID_SESSION: 'END_FLUID_SESSION',
    END_FLUID_SESSION_TOAST_MESSAGE_ORGANIZER:
        'END_FLUID_SESSION_TOAST_MESSAGE_ORGANIZER',
    END_FLUID_SESSION_TOAST_MESSAGE_ATTENDEE:
        'END_FLUID_SESSION_TOAST_MESSAGE_ATTENDEE',
    START_SESSION_BTN_DISABLE_TOOLTIP: 'START_SESSION_BTN_DISABLE_TOOLTIP',
    RESUME_SESSION_BTN_DISABLE_TOOLTIP: 'RESUME_SESSION_BTN_DISABLE_TOOLTIP',
    HOW_TO_USE: 'HOW_TO_USE',
    CREATE_A_PRIVATE_MEETING_WITH_OTHERS:
        'CREATE_A_PRIVATE_MEETING_WITH_OTHERS',
    SCHEDULE_MEETING_REMOVE_YOURSELF_TOOLTIP_3:
        'SCHEDULE_MEETING_REMOVE_YOURSELF_TOOLTIP_3',
    VIEW_SESSION_DETAILS: 'VIEW_SESSION_DETAILS',
    ENGAGE: 'ENGAGE',
    ENGAGE_SUBTITLE_PROFILE: 'ENGAGE_SUBTITLE_PROFILE',
    ENGAGE_SUBTITLE: 'ENGAGE_SUBTITLE',
    ENGAGE_PROFILE_SUBTITLE: 'ENGAGE_PROFILE_SUBTITLE',
    ENGAGE_PEOPLE: 'ENGAGE_PEOPLE',
    ENGAGE_PEOPLE_SUBTITLE: 'ENGAGE_PEOPLE_SUBTITLE',
    ENGAGE_DM: 'ENGAGE_DM',
    ENGAGE_DM_UNREAD: 'ENGAGE_DM_UNREAD',
    ENGAGE_DM_SUBTITLE: 'ENGAGE_DM_SUBTITLE',
    ENGAGE_SM_SUBTITLE: 'ENGAGE_SM_SUBTITLE',
    ENGAGE_HELP: 'ENGAGE_HELP',
    ENGAGE_HELP_SUBTITLE: 'ENGAGE_HELP_SUBTITLE',
    ENGAGE_LANGUAGE_SUBTITLE: 'ENGAGE_LANGUAGE_SUBTITLE',
    ENGAGE_EXIT: 'ENGAGE_EXIT',
    ENGAGE_EXIT_SUBTITLE: 'ENGAGE_EXIT_SUBTITLE',
    APP_NUDGE: 'APP_NUDGE',
    APP_NUDGE_BEST_EXPERIENCE: 'APP_NUDGE_BEST_EXPERIENCE',
    APP_NUDGE_DOWNLOAD_APP: 'APP_NUDGE_DOWNLOAD_APP',
    COMMON_IMAGE_UPLOAD_ERROR: 'COMMON_IMAGE_UPLOAD_ERROR',
    SYSTEM_CHECK_CONNECTIVITY_LATENCY_STATS:
        'SYSTEM_CHECK_CONNECTIVITY_LATENCY_STATS',
    SYSTEM_CHECK_CONNECTIVITY_LATENCY_ERROR:
        'SYSTEM_CHECK_CONNECTIVITY_LATENCY_ERROR',
    SYSTEM_CHECK_CONNECTIVITY_DELAY: 'SYSTEM_CHECK_CONNECTIVITY_DELAY',
    SYSTEM_CHECK_CONNECTIVITY_DELAY_DESCRIPTION:
        'SYSTEM_CHECK_CONNECTIVITY_DELAY_DESCRIPTION',
    SYSTEM_CHECK_TAB_RESULTS: 'SYSTEM_CHECK_TAB_RESULTS',
    NO_LIVE_SESSIONS_TITLE: 'NO_LIVE_SESSIONS_TITLE',
    NO_LIVE_SESSIONS_FILTERS_SUB_TITLE: 'NO_LIVE_SESSIONS_FILTERS_SUB_TITLE',
    NO_LIVE_SESSIONS_FILTER_CTA_TEXT: 'NO_LIVE_SESSIONS_FILTER_CTA_TEXT',
    NO_MEETINGS_TITLE: 'NO_MEETINGS_TITLE',
    NO_MEETINGS_FILTERS_SUB_TITLE: 'NO_MEETINGS_FILTERS_SUB_TITLE',
    NO_MEETINGS_FILTER_CTA_TEXT: 'NO_MEETINGS_FILTER_CTA_TEXT',
    WAITING_AREA_AUTO_SESSION_JOIN: 'WAITING_AREA_AUTO_SESSION_JOIN',
    EVENT_ENDED_TEXT: 'EVENT_ENDED_TEXT',
    GENERAL_SPONSOR: 'GENERAL_SPONSOR',
    MANAGE_SCHEDULE: 'MANAGE_SCHEDULE',
    NO_UPCOMING_ACTIVITIES: 'NO_UPCOMING_ACTIVITIES',
    THANK_YOU_FOR: 'THANK_YOU_FOR',
    FOR_JOINING: 'FOR_JOINING',
    JOINING: 'JOINING',
    GENERAL_STARTS_IN: 'GENERAL_STARTS_IN',
    GENERAL_STARTS: 'GENERAL_STARTS',
    GENERAL_STARTS_AT: 'GENERAL_STARTS_AT',
    PREVIEW_SESSION_BTN_DISABLE_TOOLTIP: 'PREVIEW_SESSION_BTN_DISABLE_TOOLTIP',
    GENERAL_UP_NEXT: 'GENERAL_UP_NEXT',
    REDIRECT_TO_DESTINATION_PAGE: 'REDIRECT_TO_DESTINATION_PAGE',
    USER_ALREADY_PREVIEWING_SESSION: 'USER_ALREADY_PREVIEWING_SESSION',
    BOOTH_EDIT_ALLOW_EXHIBITOR: 'BOOTH_EDIT_ALLOW_EXHIBITOR',
    BOOTH_INFO_EDIT_ALLOW_EXHIBITOR_SUBTITLE:
        'BOOTH_INFO_EDIT_ALLOW_EXHIBITOR_SUBTITLE',
    BOOTH_EDIT_DISABLE_TOOLTIP: 'BOOTH_EDIT_DISABLE_TOOLTIP',
    BOOTH_EDIT_BOOTH_NAME_DISABLE_TOOLTIP:
        'BOOTH_EDIT_BOOTH_NAME_DISABLE_TOOLTIP',
    STAGE_JOIN_LIVESTAGE: 'STAGE_JOIN_LIVESTAGE',
    TOAST_REQUESTEE_JOINED_STAGE: 'TOAST_REQUESTEE_JOINED_STAGE',
    TOAST_REQUESTEE_REJECTED_REQUEST: 'TOAST_REQUESTEE_REJECTED_REQUEST',
    NOT_ACCEPT_REQUEST_TO_JOIN: 'NOT_ACCEPT_REQUEST_TO_JOIN',
    TOAST_DISALLOW_REQUEST_TO_STAGE_MOBILE_USER:
        'TOAST_DISALLOW_REQUEST_TO_STAGE_MOBILE_USER',
    TOAST_DISALLOW_REQUEST_TO_STAGE_LITE_MODE:
        'TOAST_DISALLOW_REQUEST_TO_STAGE_LITE_MODE',
    REQUEST_TO_JOIN: 'REQUEST_TO_JOIN',
    REQUEST_TO_JOIN_STAGE_DISMISSED: 'REQUEST_TO_JOIN_STAGE_DISMISSED',
    REQUEST_TO_JOIN_NO_RESPONSE: 'REQUEST_TO_JOIN_NO_RESPONSE',
    REQUEST_TO_JOIN_TITLE: 'REQUEST_TO_JOIN_TITLE',
    REQUEST_TO_JOIN_SESSION_DESCRIPTION: 'REQUEST_TO_JOIN_SESSION_DESCRIPTION',
    REQUEST_SENT: 'REQUEST_SENT',
    BREAKOUT_WAITING_FOR_APPROVAL: 'BREAKOUT_WAITING_FOR_APPROVAL',
    TOASTS_THANK_ACCEPTING_REQUEST: 'TOASTS_THANK_ACCEPTING_REQUEST',
    STAGE_JOIN_REQUEST_PROMPT_HEADING: 'STAGE_JOIN_REQUEST_PROMPT_HEADING',
    JOIN_REQUEST_PROMPT_MESSAGE: 'JOIN_REQUEST_PROMPT_MESSAGE',
    RHS_CUSTOMIZE_SESSION_REPLAY_LABEL: 'RHS_CUSTOMIZE_SESSION_REPLAY_LABEL',
    RHS_CUSTOMIZE_SESSION_REPLAY_HINT: 'RHS_CUSTOMIZE_SESSION_REPLAY_HINT',
    MEETING_RECORDING_TOOLTIP: 'MEETING_RECORDING_TOOLTIP',
    MEETING_FAILED_TO_JOIN_HEADER: 'MEETING_FAILED_TO_JOIN_HEADER',
    MEETING_FAILED_TO_JOIN_REASON: 'MEETING_FAILED_TO_JOIN_REASON',
    ALLOW_SPEAKER_MODERATION_LABEL: 'ALLOW_SPEAKER_MODERATION_LABEL',
    MODERATION_ONLY_VISIBLE_TEXT: 'MODERATION_ONLY_VISIBLE_TEXT',
    CREATE_CALL_TO_ACTION: 'CREATE_CALL_TO_ACTION',
    RHS_TAB_BANNERS_SUBTITLE: 'RHS_TAB_BANNERS_SUBTITLE',
    CREATE_BANNERS: 'CREATE_BANNERS',
    BANNER_CREATION_REMARK: 'BANNER_CREATION_REMARK',
    GENERAL_HIDE_GRAPH: 'GENERAL_HIDE_GRAPH',
    GENERAL_SEE_GRAPH: 'GENERAL_SEE_GRAPH',
    GENERAL_UPLOAD_SPEED: 'GENERAL_UPLOAD_SPEED',
    GENERAL_DOWNLOAD_SPEED: 'GENERAL_DOWNLOAD_SPEED',
    SYSTEM_CHECK_CONNECTIVITY_STATS_DESCRIPTION:
        'SYSTEM_CHECK_CONNECTIVITY_STATS_DESCRIPTION',
    MEETUP_NETWORKING_SESSION_CANNOT_STARTED:
        'MEETUP_NETWORKING_SESSION_CANNOT_STARTED',
    SESSION_CANNOT_START_UNTIL_EVENT_OPENED:
        'SESSION_CANNOT_START_UNTIL_EVENT_OPENED',
    VIRTUAL_EVENT_TYPE: 'VIRTUAL_EVENT_TYPE',
    SESSION_IS_STARTING: 'SESSION_IS_STARTING',
    SESSION_START_YOU_ARE_LIVE: 'SESSION_START_YOU_ARE_LIVE',
    SESSION_START_AUDIENCE_MESSAGE: 'SESSION_START_AUDIENCE_MESSAGE',
    SESSION_START_AUDIENCE_CAN_SEE: 'SESSION_START_AUDIENCE_CAN_SEE',
    GENERAL_SESSION_IS_STARTED: 'GENERAL_SESSION_IS_STARTED',
    SESSION_START_COUNT_DOWN_MESSAGE: 'SESSION_START_COUNT_DOWN_MESSAGE',
    SESSION_START_COUNT_DOWN_MESSAGE_PLURAL:
        'SESSION_START_COUNT_DOWN_MESSAGE_PLURAL',
    GENERAL_WRAP_UP: 'GENERAL_WRAP_UP',
    SESSION_START_AUTO_JOIN: 'SESSION_START_AUTO_JOIN',
    RESTRICTED_ACCOUNT_TOOLTIP_HEAD: 'RESTRICTED_ACCOUNT_TOOLTIP_HEAD',
    RESTRICTED_ACCOUNT_TOOLTIP_CONTENT: 'RESTRICTED_ACCOUNT_TOOLTIP_CONTENT',
    FLUID_SPACE_ANNOUNCEMENT_SUBTITLE: 'FLUID_SPACE_ANNOUNCEMENT_SUBTITLE',
    FLUID_SWITCH_ROOMS: 'FLUID_SWITCH_ROOMS',
    FLUID_SWITCH_ROOMS_DESCRIPTION: 'FLUID_SWITCH_ROOMS_DESCRIPTION',
    FLUID_ROOM_IS_FULL: 'FLUID_ROOM_IS_FULL',
    FLUID_NO_NEW_ROOMS: 'FLUID_NO_NEW_ROOMS',
    ANOTHER_MEETUP_SESSION_ONGOING_HEADING:
        'ANOTHER_MEETUP_SESSION_ONGOING_HEADING',
    ANOTHER_MEETUP_SESSION_ONGOING_SUB_HEADING:
        'ANOTHER_MEETUP_SESSION_ONGOING_SUB_HEADING',
    ANOTHER_MEETUP_SESSION_ONGOING_SUB_HEADING_RESUME:
        'ANOTHER_MEETUP_SESSION_ONGOING_SUB_HEADING_RESUME',
    ANOTHER_MEETUP_SESSION_NOT_COMPLETED:
        'ANOTHER_MEETUP_SESSION_NOT_COMPLETED',
    ANOTHER_MEETUP_SESSION_NOT_COMPLETED_SUB_HEADING:
        'ANOTHER_MEETUP_SESSION_NOT_COMPLETED_SUB_HEADING',
    NO_SESSIONS_TEXT_VIEW_SCHEDULE: 'NO_SESSIONS_TEXT_VIEW_SCHEDULE',
    BANNER_DELETE_MODAL_TITLE: 'BANNER_DELETE_MODAL_TITLE',
    BANNER_DELETE_MODAL_SUBTITLE: 'BANNER_DELETE_MODAL_SUBTITLE',
    BANNER_LOWER_THIRD_DELETE_MODAL_SUBTITLE:
        'BANNER_LOWER_THIRD_DELETE_MODAL_SUBTITLE',
    BANNER_CTA_CARD_ACTIVE_MESSAGE: 'BANNER_CTA_CARD_ACTIVE_MESSAGE',
    BANNER_CARD_ACTIVE_MESSAGE: 'BANNER_CARD_ACTIVE_MESSAGE',
    BANNER_PREVIEW: 'BANNER_PREVIEW',
    BANNER_EXIT_PREVIEW: 'BANNER_EXIT_PREVIEW',
    BANNER_SAVED_SUCCESSFULLY: 'BANNER_SAVED_SUCCESSFULLY',
    GENERAL_CUSTOMIZE: 'GENERAL_CUSTOMIZE',
    BANNER_BG_COLOR_TITLE: 'BANNER_BG_COLOR_TITLE',
    BANNER_BG_COLOR_SUBTITLE: 'BANNER_BG_COLOR_SUBTITLE',
    BANNER_BUTTON_COLOR_TITLE: 'BANNER_BUTTON_COLOR_TITLE',
    BANNER_BUTTON_COLOR_SUBTITLE: 'BANNER_BUTTON_COLOR_SUBTITLE',
    BANNER_FULLSCREEN_IMAGE_UPLOAD_GUIDE:
        'BANNER_FULLSCREEN_IMAGE_UPLOAD_GUIDE',
    BANNER_TOAST_IMAGE_UPLOAD_GUIDE: 'BANNER_TOAST_IMAGE_UPLOAD_GUIDE',
    BANNER_CREATION_CARD_DETAIL_TITLE: 'BANNER_CREATION_CARD_DETAIL_TITLE',
    BANNER_CREATION_CARD_CTA: 'BANNER_CREATION_CARD_CTA',
    BANNER_CREATION_CARD_DETAIL_SUBTITLE:
        'BANNER_CREATION_CARD_DETAIL_SUBTITLE',
    BANNER_CREATION_CALL_TO_ACTION: 'BANNER_CREATION_CALL_TO_ACTION',
    BANNER_CREATION_BUTTON_TEXT: 'BANNER_CREATION_BUTTON_TEXT',
    BANNER_CREATION_BUTTON_LINK: 'BANNER_CREATION_BUTTON_LINK',
    BANNER_CREATION_DESCRIPTION: 'BANNER_CREATION_DESCRIPTION',
    BANNER_IMAGE_UPLOAD_TITLE: 'BANNER_IMAGE_UPLOAD_TITLE',
    BANNER_IMAGE_UPLOAD_SUBTITLE: 'BANNER_IMAGE_UPLOAD_SUBTITLE',
    BANNER_TOAST: 'BANNER_TOAST',
    BANNER_CARD_DESCRIPTION_1: 'BANNER_CARD_DESCRIPTION_1',
    BANNER_FULLSCREEN: 'BANNER_FULLSCREEN',
    BANNER_FULLSCREEN_DESCRIPTION_1: 'BANNER_FULLSCREEN_DESCRIPTION_1',
    BANNER_CARD_SELECTION_TITLE: 'BANNER_CARD_SELECTION_TITLE',
    CTA_CARD_SELECTION_TITLE: 'CTA_CARD_SELECTION_TITLE',
    BANNER_CARD_SELECTION_DESCRIPTION: 'BANNER_CARD_SELECTION_DESCRIPTION',
    CTA_CARD_SELECTION_DESCRIPTION: 'CTA_CARD_SELECTION_DESCRIPTION',
    BANNER_SAVE_DISCARD_TITLE: 'BANNER_SAVE_DISCARD_TITLE',
    BANNER_SAVE_DISCARD_DESCRIPTION: 'BANNER_SAVE_DISCARD_DESCRIPTION',
    BANNER_SAVE_DISCARD_EXIT: 'BANNER_SAVE_DISCARD_EXIT',
    BANNER_HOST_PREVIEW_TEXT: 'BANNER_HOST_PREVIEW_TEXT',
    FLUID_SPACE_ANNOUNCEMENT_SUBTITLE_FOR_ORGANIZER:
        'FLUID_SPACE_ANNOUNCEMENT_SUBTITLE_FOR_ORGANIZER',
    RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_KEY:
        'RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_KEY',
    RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_KEY_HINT:
        'RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_KEY_HINT',
    RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_HINT_NEW:
        'RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_HINT_NEW',
    RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_NEW_KEY:
        'RHS_CUSTOMIZE_PEOPLE_ADVANCED_SEARCH_NEW_KEY',
    SPEAKER_LABEL: 'SPEAKER_LABEL',
    YOUR_UPCOMING_SESSION: 'YOUR_UPCOMING_SESSION',
    JOIN_THE_BACKSTAGE: 'JOIN_THE_BACKSTAGE',
    WELCOME_SPEAKER: 'WELCOME_SPEAKER',
    SPEAKER_REMAINING_UPCOMING_SESSION_COUNT_DESC:
        'SPEAKER_REMAINING_UPCOMING_SESSION_COUNT_DESC',
    JOINED_BACKSTAGE: 'JOINED_BACKSTAGE',
    LEFT_BACKSTAGE: 'LEFT_BACKSTAGE',
    JOINED_SESSION: 'JOINED_SESSION',
    NO_ONE_IN_BACKSTAGE: 'NO_ONE_IN_BACKSTAGE',
    NO_ONE_IN_SESSION: 'NO_ONE_IN_SESSION',
    YOUR_NEXT_SESSION: 'YOUR_NEXT_SESSION',
    YOUR_NEXT_SESSION_IN: 'YOUR_NEXT_SESSION_IN',
    IMAGE_HAS_BEEN_UPLOADED_SUCCESSFULLY:
        'IMAGE_HAS_BEEN_UPLOADED_SUCCESSFULLY',
    SESSION_STARTS_SOON: 'SESSION_STARTS_SOON',
    FILTERS_OVERLAY_SUBTITLE_1: 'FILTERS_OVERLAY_SUBTITLE_1',
    GENERAL_HI_USERNAME: 'GENERAL_HI_USERNAME',
    FILTERS_OVERLAY_SUBTITLE: 'FILTERS_OVERLAY_SUBTITLE',
    BOOTH_CUSTOM_BOOTH_VIDEO_UPLOAD_IMAGE_DESC:
        'BOOTH_CUSTOM_BOOTH_VIDEO_UPLOAD_IMAGE_DESC',
    LATEST_MESSAGES: 'LATEST_MESSAGES',
    RHS_CHAT_GIFS: 'RHS_CHAT_GIFS',
    RHS_CHAT_GIFS_TOOLTIP: 'RHS_CHAT_GIFS_TOOLTIP',
    COMMENTS_REMAIN_UNMODERATED: 'COMMENTS_REMAIN_UNMODERATED',
    FEED_MODAL_TITLE: 'FEED_MODAL_TITLE',
    FEED_MODAL_SUBTITLE: 'FEED_MODAL_SUBTITLE',
    FEED_MODAL_OPEN_CHAT: 'FEED_MODAL_OPEN_CHAT',
    FEED_INFO_MSG: 'FEED_INFO_MSG',
    CHAT_MESSAGES_ARE_PUBLIC: 'CHAT_MESSAGES_ARE_PUBLIC',
    CHAT_MESSAGES_WILL_BE_VISIBLE_TO_EVERYONE:
        'CHAT_MESSAGES_WILL_BE_VISIBLE_TO_EVERYONE',
    WAITING_SCREEN_LOUNGE_NOT_AVAILABLE: 'WAITING_SCREEN_LOUNGE_NOT_AVAILABLE',
    WAITING_SCREEN_LOUNGE_DISABLED: 'WAITING_SCREEN_LOUNGE_DISABLED',
    CUSTOM_FONTS_FILE_NOT_SUPPORTED: 'CUSTOM_FONTS_FILE_NOT_SUPPORTED',
    CUSTOM_FONTS_FILE_NOT_SUPPORTED_ERROR_TEXT:
        'CUSTOM_FONTS_FILE_NOT_SUPPORTED_ERROR_TEXT',
    CUSTOM_FONTS_DUPLICATE_FILE_ERROR_TOAST_TEXT:
        'CUSTOM_FONTS_DUPLICATE_FILE_ERROR_TOAST_TEXT',
    CUSTOM_FONTS_DUPLICATE_FILE_ERROR_TEXT:
        'CUSTOM_FONTS_DUPLICATE_FILE_ERROR_TEXT',
    CUSTOM_FONTS_UPLOAD_HEADING: 'CUSTOM_FONTS_UPLOAD_HEADING',
    CUSTOM_FONTS_UPLOAD_SUBHEADING: 'CUSTOM_FONTS_UPLOAD_SUBHEADING',
    CUSTOM_FONTS_UPLOAD_DRAG_DROP_TEXT: 'CUSTOM_FONTS_UPLOAD_DRAG_DROP_TEXT',
    CUSTOM_FONTS_UPLOAD_CLICK_TO_UPLOAD_TEXT:
        'CUSTOM_FONTS_UPLOAD_CLICK_TO_UPLOAD_TEXT',
    CUSTOM_FONTS_UPLOAD_FILE_FORMATS_TEXT:
        'CUSTOM_FONTS_UPLOAD_FILE_FORMATS_TEXT',
    CUSTOM_FONTS_WARNING_TEXT_1: 'CUSTOM_FONTS_WARNING_TEXT_1',
    CUSTOM_FONTS_WARNING_TEXT_2: 'CUSTOM_FONTS_WARNING_TEXT_2',
    CUSTOM_FONTS_WARNING_TEXT_3: 'CUSTOM_FONTS_WARNING_TEXT_3',
    CUSTOM_FONTS_UPLOAD_TOOlTIP_TEXT: 'CUSTOM_FONTS_UPLOAD_TOOlTIP_TEXT',
    CHAT_SETTINGS: 'CHAT_SETTINGS',
    POLLS_SETTINGS: 'POLLS_SETTINGS',
    QNA_SETTINGS_HEADING: 'QNA_SETTINGS_HEADING',
    QNA_SETTINGS_TOOLTIP_TEXT: 'QNA_SETTINGS_TOOLTIP_TEXT',
    POLLS_SETTINGS_HEADING: 'POLLS_SETTINGS_HEADING',
    POLLS_SETTINGS_TOOLTIP_TEXT: 'POLLS_SETTINGS_TOOLTIP_TEXT',
    SESSION_FEED_SETTINGS_HEADING: 'SESSION_FEED_SETTINGS_HEADING',
    SESSION_FEED_SETTINGS_TOOLTIP_TEXT: 'SESSION_FEED_SETTINGS_TOOLTIP_TEXT',
    GENERAL_QNA: 'GENERAL_QNA',
    GENERAL_SESSION_POLLS: 'GENERAL_SESSION_POLLS',
    GENERAL_SESSION_CHAT: 'GENERAL_SESSION_CHAT',
    GENERAL_DISABLED_SUCCESSFULLY: 'GENERAL_DISABLED_SUCCESSFULLY',
    GENERAL_ENABLED_SUCCESSFULLY: 'GENERAL_ENABLED_SUCCESSFULLY',
    RHS_ACTIONS_BLOCK_USER_DESC_NEW: 'RHS_ACTIONS_BLOCK_USER_DESC_NEW',
    FLUID_SPACE_COUNTDOWN_HEADER: 'FLUID_SPACE_COUNTDOWN_HEADER',
    END_FLUID_SESSION_COUNTDOWN_ORGANIZER:
        'END_FLUID_SESSION_COUNTDOWN_ORGANIZER',
    SN_REMOVED_NEW_INTERACTION: 'SN_REMOVED_NEW_INTERACTION',
    ALREADY_SAME_SESSION: 'ALREADY_SAME_SESSION',
    RHS_ALERTS_CREATED: 'RHS_ALERTS_CREATED',
    RHS_ALERTS_DELETE_ALL: 'RHS_ALERTS_DELETE_ALL',
    RHS_DELETE_ALERT_CONFIRM: 'RHS_DELETE_ALERT_CONFIRM',
    RHS_CONFIRM_DELETE_TEXT: 'RHS_CONFIRM_DELETE_TEXT',
    RHS_CONFIRM_DELETE_SUBTEXT: 'RHS_CONFIRM_DELETE_SUBTEXT',
    AN_ALERT: 'AN_ALERT',
    DELETE_YES: 'DELETE_YES',
    DELETE_YES_ALL: 'DELETE_YES_ALL',
    RHS_DELETE_ALERT: 'RHS_DELETE_ALERT',
    SM_DND: 'SM_DND',
    SM_DND_WANT_NO_REQUESTS: 'SM_DND_WANT_NO_REQUESTS',
    SM_DND_USE_TO_STOP_PEOPLE_REQUESTS: 'SM_DND_USE_TO_STOP_PEOPLE_REQUESTS',
    RHS_LANGUAGE_PREFERENCE: 'RHS_LANGUAGE_PREFERENCE',
    RHS_LANGUAGE_PREFERENCE_DESC: 'RHS_LANGUAGE_PREFERENCE_DESC',
    BOOTH_EDIT_BOOTH_FILE_UPDATED_UPLOAD_ERROR_DESC:
        'BOOTH_EDIT_BOOTH_FILE_UPDATED_UPLOAD_ERROR_DESC',
    RHS_CUSTOMIZE_SESSION_REPLAY_FEED_LABEL:
        'RHS_CUSTOMIZE_SESSION_REPLAY_FEED_LABEL',
    RHS_CUSTOMIZE_SESSION_REPLAY_FEED_HINT:
        'RHS_CUSTOMIZE_SESSION_REPLAY_FEED_HINT',
    RHS_CUSTOMIZE_SESSION_REPLAY_QNA_LABEL:
        'RHS_CUSTOMIZE_SESSION_REPLAY_QNA_LABEL',
    RHS_CUSTOMIZE_SESSION_REPLAY_QNA_HINT:
        'RHS_CUSTOMIZE_SESSION_REPLAY_QNA_HINT',
    RHS_CUSTOMIZE_SESSION_REPLAY_POLLS_LABEL:
        'RHS_CUSTOMIZE_SESSION_REPLAY_POLLS_LABEL',
    RHS_CUSTOMIZE_SESSION_REPLAY_POLLS_HINT:
        'RHS_CUSTOMIZE_SESSION_REPLAY_POLLS_HINT',
    RHS_CUSTOMIZE_SESSION_REPLAY_HEADING:
        'RHS_CUSTOMIZE_SESSION_REPLAY_HEADING',
    RHS_CUSTOMIZE_SESSION_REPLAY_SUBHEADING:
        'RHS_CUSTOMIZE_SESSION_REPLAY_SUBHEADING',
    LOWER_THIRDS_TITLE: 'LOWER_THIRDS_TITLE',
    LOWER_THIRDS_SUBTITLE: 'LOWER_THIRDS_SUBTITLE',
    LOWER_THIRDS_TOOLTIP: 'LOWER_THIRDS_TOOLTIP',
    LOWER_THIRDS_EDIT_BANNER_SUBTITLE: 'LOWER_THIRDS_EDIT_BANNER_SUBTITLE',
    LOWER_THIRDS_EDIT_BANNER_SCROLL_PERMISSION:
        'LOWER_THIRDS_EDIT_BANNER_SCROLL_PERMISSION',
    INTEGRATIONS: 'INTEGRATIONS',
    INTEGRATIONS_SUBTITLE: 'INTEGRATIONS_SUBTITLE',
    SOLID_COLOR_HEADING: 'SOLID_COLOR_HEADING',
    GRADIENT_HEADER: 'GRADIENT_HEADER',
    PATTERNS_HEADER: 'PATTERNS_HEADER',
    SQAURE_LAYOUT_TOOLTIP_TEXT: 'SQAURE_LAYOUT_TOOLTIP_TEXT',
    FULLSCREEN_LAYOUT_TOOLTIP_TEXT: 'FULLSCREEN_LAYOUT_TOOLTIP_TEXT',
    WIDESCREEN_LAYOUT_TOOLTIP_TEXT: 'WIDESCREEN_LAYOUT_TOOLTIP_TEXT',
    PORTRAIT_LAYOUT_TOOLTIP_TEXT: 'PORTRAIT_LAYOUT_TOOLTIP_TEXT',
    RHS_CUSTOMIZE_EVENT_POLLS_LABEL: 'RHS_CUSTOMIZE_EVENT_POLLS_LABEL',
    ON_BACKSTAGE: 'ON_BACKSTAGE',
    RESOURCES_NOT_FOUND: 'RESOURCES_NOT_FOUND',
    RESOURCES_NOT_FOUND_SUBTITLE: 'RESOURCES_NOT_FOUND_SUBTITLE',
    BOOTHS_SEARCH_EMPTY_SUBTITLE: 'BOOTHS_SEARCH_EMPTY_SUBTITLE',
    BRANDING: 'BRANDING',
    BRANDING_SUBTITLE: 'BRANDING_SUBTITLE',
    REMOVE_BACKDROP: 'REMOVE_BACKDROP',
    NO_BACKDROP_ADDED: 'NO_BACKDROP_ADDED',
    UPLOAD_STAGE_BACKDROP: 'UPLOAD_STAGE_BACKDROP',
    STAGE_BACKDROP: 'STAGE_BACKDROP',
    NAME_CARD: 'NAME_CARD',
    STYLE_SPEAKER_DETAILS: 'STYLE_SPEAKER_DETAILS',
    SHOW_USER_LABEL: 'SHOW_USER_LABEL',
    SHOW_DISPLAY_TITLE: 'SHOW_DISPLAY_TITLE',
    SELECTED_BACKDROP_APPLIED_HEADING: 'SELECTED_BACKDROP_APPLIED_HEADING',
    SELECTED_BACKDROP_APPLIED_SUB_HEADING:
        'SELECTED_BACKDROP_APPLIED_SUB_HEADING',
    EDIT_FROM_DASHBOARD: 'EDIT_FROM_DASHBOARD',
    RICH_PROFILE_SOCIAL_MEDIA_PLACEHOLDER:
        'RICH_PROFILE_SOCIAL_MEDIA_PLACEHOLDER',
    RICH_PROFILE_SOCIAL_MEDIA_INVALID_URL_ERROR:
        'RICH_PROFILE_SOCIAL_MEDIA_INVALID_URL_ERROR',
    RHS_CUSTOMIZE_EVENT_POLLS_CLEAR_TITLE:
        'RHS_CUSTOMIZE_EVENT_POLLS_CLEAR_TITLE',
    RHS_CUSTOMIZE_EVENT_POLLS_CLEAR_DESCRIPTION:
        'RHS_CUSTOMIZE_EVENT_POLLS_CLEAR_DESCRIPTION',
    EVENT_DETAILS: 'EVENT_DETAILS',
    OVERVIEW: 'OVERVIEW',
    HOSTS: 'HOSTS',
    LANDING_EVENT_BY: 'LANDING_EVENT_BY',
    LANDING_EVENT_DATE: 'LANDING_EVENT_DATE',
    LANDING_START_TIME: 'LANDING_START_TIME',
    LANDING_END_TIME: 'LANDING_END_TIME',
    LANDING_EVENT_HAS: 'LANDING_EVENT_HAS',
    LANDING_EVENT_IS: 'LANDING_EVENT_IS',
    LANDING_EVENT_STARTS: 'LANDING_EVENT_STARTS',
    LANDING_EVENT_AUTOMATICALLY_STARTS_IN:
        'LANDING_EVENT_AUTOMATICALLY_STARTS_IN',
    LANDING_ENDED: 'LANDING_ENDED',
    LANDING_STARTED: 'LANDING_STARTED',
    LANDING_PAUSED: 'LANDING_PAUSED',
    LANDING_SOON: 'LANDING_SOON',
    LANDING_ENTER_EVENT: 'LANDING_ENTER_EVENT',
    LANDING_APPROVAL_PENDING: 'LANDING_APPROVAL_PENDING',
    LANDING_START_EVENT: 'LANDING_START_EVENT',
    LANDING_REGISTER_JOIN: 'LANDING_REGISTER_JOIN',
    LANDING_REGISTER_ENTER: 'LANDING_REGISTER_ENTER',
    LANDING_REGISTER_FOR_EVENT: 'LANDING_REGISTER_FOR_EVENT',
    LANDING_REPLAY_ALREADY_REGISTERED: 'LANDING_REPLAY_ALREADY_REGISTERED',
    LANDING_REPLAY_REGISTER_NOW: 'LANDING_REPLAY_REGISTER_NOW',
    LANDING_SHARE_EVENT: 'LANDING_SHARE_EVENT',
    LANDING_SHARE_EVENT_ON: 'LANDING_SHARE_EVENT_ON',
    LANDING_SHARE_FRIENDS: 'LANDING_SHARE_FRIENDS',
    LANDIND_SPEAKER_ASSIGNED: 'LANDIND_SPEAKER_ASSIGNED',
    LANDING_ENTER_AS: 'LANDING_ENTER_AS',
    LANDING_EXPERIENCE_SUPPORTED: 'LANDING_EXPERIENCE_SUPPORTED',
    LANDING_PECA_THANK_YOU: 'LANDING_PECA_THANK_YOU',
    LANDING_PECA_REGISTRATION_CONFIRMED: 'LANDING_PECA_REGISTRATION_CONFIRMED',
    LANDING_PECA_START_SOON: 'LANDING_PECA_START_SOON',
    LANDING_PECA_EXPLORE_EVENT: 'LANDING_PECA_EXPLORE_EVENT',
    LANDING_PECA_DO_NEXT: 'LANDING_PECA_DO_NEXT',
    LANDING_PECA_PLAN_AGENDA: 'LANDING_PECA_PLAN_AGENDA',
    LANDING_PECA_CHECK_ATTENDING: 'LANDING_PECA_CHECK_ATTENDING',
    LANDING_PECA_ENHANCE_PROFILE: 'LANDING_PECA_ENHANCE_PROFILE',
    LANDING_EVENT_STARTS_IN: 'LANDING_EVENT_STARTS_IN',
    LANDING_INCASE_SUPPORT: 'LANDING_INCASE_SUPPORT',
    GENERAL_COPY_LINK: 'GENERAL_COPY_LINK',
    GENERAL_LINK_COPIED: 'GENERAL_LINK_COPIED',
    SCHEDULE_ADD_TO_CALENDAR: 'SCHEDULE_ADD_TO_CALENDAR',
    SCHEDULE_SELECT_TRACK: 'SCHEDULE_SELECT_TRACK',
    SCHEDULE_ALL_TRACKS: 'SCHEDULE_ALL_TRACKS',
    SCHEDULE_SELECT_TAG: 'SCHEDULE_SELECT_TAG',
    SCHEDULE_ABOUT_SESSION: 'SCHEDULE_ABOUT_SESSION',
    LOGIN_FULL_NAME: 'LOGIN_FULL_NAME',
    LOGIN_ENTER_EMAIL: 'LOGIN_ENTER_EMAIL',
    LOGIN_ENTER_EMAIL_CONTINUE: 'LOGIN_ENTER_EMAIL_CONTINUE',
    LOGIN_ORGANIZER_ENTER_EMAIL: 'LOGIN_ORGANIZER_ENTER_EMAIL',
    LOGIN_ENTER_EMAIL_DOMAIN: 'LOGIN_ENTER_EMAIL_DOMAIN',
    LOGIN_PASSWORD: 'LOGIN_PASSWORD',
    LOGIN_CREATE_PASSWORD: 'LOGIN_CREATE_PASSWORD',
    LOGIN_TYPE_PASSWORD: 'LOGIN_TYPE_PASSWORD',
    LOGIN_ENTER__NEW_PASSWORD: 'LOGIN_ENTER__NEW_PASSWORD',
    LOGIN_FORGOT_PASSWORD: 'LOGIN_FORGOT_PASSWORD',
    LOGIN_RESET_YOUR_PASSWORD: 'LOGIN_RESET_YOUR_PASSWORD',
    LOGIN_RESET_PASSWORD: 'LOGIN_RESET_PASSWORD',
    LOGIN_USE_SOCIAL_ACCOUNT: 'LOGIN_USE_SOCIAL_ACCOUNT',
    LOGIN_TEAM_SSO_AUTH: 'LOGIN_TEAM_SSO_AUTH',
    LOGIN_PASSWORD_GUIDE: 'LOGIN_PASSWORD_GUIDE',
    LOGIN_WELCOME_TO: 'LOGIN_WELCOME_TO',
    LOGIN_SIGNUP_AGREE: 'LOGIN_SIGNUP_AGREE',
    LOGIN_CONTINUE_AGREE: 'LOGIN_CONTINUE_AGREE',
    LOGIN_AND_THE: 'LOGIN_AND_THE',
    LOGIN_ENTER_SIX_DIGIT: 'LOGIN_ENTER_SIX_DIGIT',
    LOGIN_SIX_DIGIT: 'LOGIN_SIX_DIGIT',
    LOGIN_RESEND_CODE_IN: 'LOGIN_RESEND_CODE_IN',
    LOGIN_RESEND_CODE: 'LOGIN_RESEND_CODE',
    LOGIN_CODE_NOT_RECEIVED: 'LOGIN_CODE_NOT_RECEIVED',
    LOGIN_RESEND_EMAIL_TWICE: 'LOGIN_RESEND_EMAIL_TWICE',
    LOGIN_INVITATION_MAIL_RESENT_V2: 'LOGIN_INVITATION_MAIL_RESENT_V2',
    LOGIN_ACCESS_LINK_RESENT_V2: 'LOGIN_ACCESS_LINK_RESENT_V2',
    LOGIN_INVITATION_MAIL_RESENT_MSG_V2: 'LOGIN_INVITATION_MAIL_RESENT_MSG_V2',
    LOGIN_ACCESS_LINK_RESENT_MSG_V2: 'LOGIN_ACCESS_LINK_RESENT_MSG_V2',
    LOGIN_ALREADY_INVITED: 'LOGIN_ALREADY_INVITED',
    LOGIN_ALREADY_REGISTERED: 'LOGIN_ALREADY_REGISTERED',
    LOGIN_RESEND_EMAIL: 'LOGIN_RESEND_EMAIL',
    LOGIN_REQUEST_ORGANIZER_SHARE: 'LOGIN_REQUEST_ORGANIZER_SHARE',
    LOGIN_ENTER_VIA_UNIQUE_LINK: 'LOGIN_ENTER_VIA_UNIQUE_LINK',
    LOGIN_ALREADY_INVITED_MSG_V2: 'LOGIN_ALREADY_INVITED_MSG_V2',
    LOGIN_ALREADY_REGISTERED_MSG_V3: 'LOGIN_ALREADY_REGISTERED_MSG_V3',
    LOGIN_ENTER_SERIES_VIA_UNIQUE_LINK: 'LOGIN_ENTER_SERIES_VIA_UNIQUE_LINK',
    LOGIN_ALREADY_REGISTERED_QUESTION: 'LOGIN_ALREADY_REGISTERED_QUESTION',
    LOGIN_PLEASE_ENTER_EMAIL: 'LOGIN_PLEASE_ENTER_EMAIL',
    LOGIN_SELECT_ACCEPTANCE: 'LOGIN_SELECT_ACCEPTANCE',
    LOGIN_NETWORK_ISSUE: 'LOGIN_NETWORK_ISSUE',
    LOGIN_INVALID_EMAIL_LIVE: 'LOGIN_INVALID_EMAIL_LIVE',
    LOGIN_INVALID_EMAIL_PECA: 'LOGIN_INVALID_EMAIL_PECA',
    LOGIN_INVALID_DOMAIN_PECA: 'LOGIN_INVALID_DOMAIN_PECA',
    LOGIN_INVALID_EMAIL_DOMAIN: 'LOGIN_INVALID_EMAIL_DOMAIN',
    LOGIN_ACCESS_REPLAY: 'LOGIN_ACCESS_REPLAY',
    LOGIN_READ_ACCEPTED: 'LOGIN_READ_ACCEPTED',
    LOGIN_CHECK_BOX_PROCEED: 'LOGIN_CHECK_BOX_PROCEED',
    LOGIN_ADD_REGISTRATION_DETAILS: 'LOGIN_ADD_REGISTRATION_DETAILS',
    LOGIN_VERIFY_REGISTRATION_DETAILS: 'LOGIN_VERIFY_REGISTRATION_DETAILS',
    LOGIN_WATCH_REPLAY: 'LOGIN_WATCH_REPLAY',
    LOGIN_FILL_REGISTRATION: 'LOGIN_FILL_REGISTRATION',
    LOGIN_REGISTER_FOR_EVENT: 'LOGIN_REGISTER_FOR_EVENT',
    LOGIN_FILL_DETAILS: 'LOGIN_FILL_DETAILS',
    LOGIN_FILL_DETAILS_FINISH_REGISTRATION:
        'LOGIN_FILL_DETAILS_FINISH_REGISTRATION',
    LOGIN_FILL_ALL_FIELDS: 'LOGIN_FILL_ALL_FIELDS',
    LOGIN_WELCOME_BACK: 'LOGIN_WELCOME_BACK',
    LOGIN_ASSOCIATED_ACCOUNT: 'LOGIN_ASSOCIATED_ACCOUNT',
    LOGIN_CONTINUE_GUEST: 'LOGIN_CONTINUE_GUEST',
    LOGIN_ALREADY_HAVE_ACCOUNT: 'LOGIN_ALREADY_HAVE_ACCOUNT',
    LOGIN_ENTER_PREVIEW: 'LOGIN_ENTER_PREVIEW',
    LOGIN_GO_TO_BOOTH: 'LOGIN_GO_TO_BOOTH',
    LOGIN_NO_SEATS: 'LOGIN_NO_SEATS',
    LOGIN_EXPLORE_EVENT: 'LOGIN_EXPLORE_EVENT',
    LOGIN_CHECK_INBOX: 'LOGIN_CHECK_INBOX',
    LOGIN_USE_INVITATION: 'LOGIN_USE_INVITATION',
    LOGIN_EMAIL_NO_TICKET: 'LOGIN_EMAIL_NO_TICKET',
    LOGIN_VERIFY_AGAIN: 'LOGIN_VERIFY_AGAIN',
    LOGIN_TICKET_SENT_TO: 'LOGIN_TICKET_SENT_TO',
    LOGIN_USE_LINK_TICKET: 'LOGIN_USE_LINK_TICKET',
    LOGIN_LOGIN_TO_ENTER: 'LOGIN_LOGIN_TO_ENTER',
    LOGIN_NOT_VERIFY: 'LOGIN_NOT_VERIFY',
    LOGIN_HAVE_TICKET: 'LOGIN_HAVE_TICKET',
    LOGIN_PURCHASED_ALREADY: 'LOGIN_PURCHASED_ALREADY',
    LOGIN_VERIFY_TICKET: 'LOGIN_VERIFY_TICKET',
    LOGIN_JOIN_HERE: 'LOGIN_JOIN_HERE',
    LOGIN_CREATE_ACCOUNT: 'LOGIN_CREATE_ACCOUNT',
    LOGIN_SIGN_UP: 'LOGIN_SIGN_UP',
    LOGIN_ENTER_PASSWORD: 'LOGIN_ENTER_PASSWORD',
    LOGIN_APPLE_NOT_LINKED: 'LOGIN_APPLE_NOT_LINKED',
    LOGIN_BACK_TO_SIGNIN: 'LOGIN_BACK_TO_SIGNIN',
    LOGIN_ACCEPT_INVITATION: 'LOGIN_ACCEPT_INVITATION',
    LOGIN_CONTINUE_SSO: 'LOGIN_CONTINUE_SSO',
    LOGIN_ENTER_EVENT: 'LOGIN_ENTER_EVENT',
    LOGIN_VERIFY_EMAIL: 'LOGIN_VERIFY_EMAIL',
    LOGIN_COMPLETE_PROFILE: 'LOGIN_COMPLETE_PROFILE',
    LOGIN_UPDATE_PASSWORD: 'LOGIN_UPDATE_PASSWORD',
    LOGIN_SSO_AUTH: 'LOGIN_SSO_AUTH',
    LOGIN_HERE_TO: 'LOGIN_HERE_TO',
    LOGIN_SIGNING_UP_WITH: 'LOGIN_SIGNING_UP_WITH',
    LOGIN_CONTINUE_WITH: 'LOGIN_CONTINUE_WITH',
    LOGIN_WITH: 'LOGIN_WITH',
    LOGIN_CHECK_SPAM: 'LOGIN_CHECK_SPAM',
    LOGIN_CHECK_SPAM_LINK: 'LOGIN_CHECK_SPAM_LINK',
    LOGIN_THANKYOU_INTEREST: 'LOGIN_THANKYOU_INTEREST',
    LOGIN_REGISTRATION_PENDING: 'LOGIN_REGISTRATION_PENDING',
    LOGIN_EMAIL_UNIQUE_LINK: 'LOGIN_EMAIL_UNIQUE_LINK',
    LOGIN_CHECK_SPAM_V3: 'LOGIN_CHECK_SPAM_V3',
    LOGIN_CHECK_EMAIL: 'LOGIN_CHECK_EMAIL',
    LOGIN_REGISTRATION_SUCCESSFUL: 'LOGIN_REGISTRATION_SUCCESSFUL',
    LOGIN_EMAIL_RESEND: 'LOGIN_EMAIL_RESEND',
    LOGIN_EMAIL_UNIQUE_LINK_V3: 'LOGIN_EMAIL_UNIQUE_LINK_V3',
    LOGIN_REACH_ORGANIZER: 'LOGIN_REACH_ORGANIZER',
    LOGIN_REACH_ORGANIZER_LINK: 'LOGIN_REACH_ORGANIZER_LINK',
    LOGIN_REGISTRATION_SUCCESSFUL_TOAST: 'LOGIN_REGISTRATION_SUCCESSFUL_TOAST',
    LOGIN_TOOLTIP_ENTER_INVITED_PAUSED: 'LOGIN_TOOLTIP_ENTER_INVITED_PAUSED',
    LOGIN_TOOLTIP_ENTER_INVITED: 'LOGIN_TOOLTIP_ENTER_INVITED',
    LOGIN_TOOLTIP_ENTER_PAUSED: 'LOGIN_TOOLTIP_ENTER_PAUSED',
    LOGIN_TOOLTIP_ENTER: 'LOGIN_TOOLTIP_ENTER',
    LOGIN_SIX_DIGIT_ERROR: 'LOGIN_SIX_DIGIT_ERROR',
    LOGIN_PASSWORD_SECURITY: 'LOGIN_PASSWORD_SECURITY',
    LOGIN_PASSWORD_LENGTH: 'LOGIN_PASSWORD_LENGTH',
    LOGIN_PASSWORD_CASE_NUMERIC: 'LOGIN_PASSWORD_CASE_NUMERIC',
    LOGIN_PASSWORD_SPACE_NOT_CONSIDERED: 'LOGIN_PASSWORD_SPACE_NOT_CONSIDERED',
    LOGIN_PASSWORD_RECOMMENDED_SEQUENCE: 'LOGIN_PASSWORD_RECOMMENDED_SEQUENCE',
    LOGIN_PASSWORD_WEAK: 'LOGIN_PASSWORD_WEAK',
    LOGIN_PASSWORD_STRONG: 'LOGIN_PASSWORD_STRONG',
    LOGIN_PASSWORD_NOT_STRONG: 'LOGIN_PASSWORD_NOT_STRONG',
    LOGIN_ENTER_EMAIL_OR_DOMAIN: 'LOGIN_ENTER_EMAIL_OR_DOMAIN',
    LOGIN_ALREADY_REGISTERED_EMAIL: 'LOGIN_ALREADY_REGISTERED_EMAIL',
    LOGIN_WITHOUT_SSO: 'LOGIN_WITHOUT_SSO',
    LOGIN_SSO_ASSISTANCE: 'LOGIN_SSO_ASSISTANCE',
    LOGIN_ENTER_AS_EXHIBITOR: 'LOGIN_ENTER_AS_EXHIBITOR',
    LOGIN_EXHIBITOR_MAIL_LINK: 'LOGIN_EXHIBITOR_MAIL_LINK',
    LOGIN_CLICK_LINK_REPLAY: 'LOGIN_CLICK_LINK_REPLAY',
    LOGIN_CLICK_LINK_SEATS: 'LOGIN_CLICK_LINK_SEATS',
    LOGIN_CLICK_LINK_ACCESS: 'LOGIN_CLICK_LINK_ACCESS',
    LOGIN_UNIQUE_LINK_EMAIL: 'LOGIN_UNIQUE_LINK_EMAIL',
    LOGIN_SEND_LINK: 'LOGIN_SEND_LINK',
    LOGIN_EMAIL_NOT_FOUND: 'LOGIN_EMAIL_NOT_FOUND',
    LOGIN_EMAIL_ADDRESS: 'LOGIN_EMAIL_ADDRESS',
    LOGIN_ENTER_ADDRESS: 'LOGIN_ENTER_ADDRESS',
    LOGIN_ACCESS_EVENT_EMAIL: 'LOGIN_ACCESS_EVENT_EMAIL',
    LOGIN_INVALID_CODE_REENTER: 'LOGIN_INVALID_CODE_REENTER',
    LOGIN_INCORRECT_USERNAME: 'LOGIN_INCORRECT_USERNAME',
    LOGIN_EMAIL_DOMAIN_EMPTY: 'LOGIN_EMAIL_DOMAIN_EMPTY',
    LOGIN_SERIES_2FA_HEADER: 'LOGIN_SERIES_2FA_HEADER',
    LOGIN_SERIES_2FA_SUBHEADER: 'LOGIN_SERIES_2FA_SUBHEADER',
    TICKETING_USING_DIFFERNT_ACCOUNT: 'TICKETING_USING_DIFFERNT_ACCOUNT',
    TICKETING_LOGGED_IN_AS: 'TICKETING_LOGGED_IN_AS',
    TICKETING_WHERES_TICKET: 'TICKETING_WHERES_TICKET',
    TICKETING_SWITCH_ACCOUNT: 'TICKETING_SWITCH_ACCOUNT',
    TICKETING_BACK_TO_EVENT: 'TICKETING_BACK_TO_EVENT',
    TICKETING_TICKETS: 'TICKETING_TICKETS',
    TICKETING_SALE_STATUS: 'TICKETING_SALE_STATUS',
    TICKETING_SOLD_OUT: 'TICKETING_SOLD_OUT',
    TICKETING_LAST_REMAINING: 'TICKETING_LAST_REMAINING',
    TICKETING_STARTS_FROM: 'TICKETING_STARTS_FROM',
    TICKETING_AVAILABLE: 'TICKETING_AVAILABLE',
    TICKETING_HYBRID_TOOLTIP: 'TICKETING_HYBRID_TOOLTIP',
    TICKETING_ONLINE_TOOLTIP: 'TICKETING_ONLINE_TOOLTIP',
    TICKETING_INPERSON_TOOLTIP: 'TICKETING_INPERSON_TOOLTIP',
    TICKETING_SUBTOTAL: 'TICKETING_SUBTOTAL',
    TICKETING_COUPON_APPLIED: 'TICKETING_COUPON_APPLIED',
    TICKETING_TAXES: 'TICKETING_TAXES',
    TICKETING_TOTAL_PRICE: 'TICKETING_TOTAL_PRICE',
    TICKETING_NOT_AVAILABLE: 'TICKETING_NOT_AVAILABLE',
    TICKETING_APPLY_COUPON: 'TICKETING_APPLY_COUPON',
    TICKETING_COUPON_APPLIED_TICKETS: 'TICKETING_COUPON_APPLIED_TICKETS',
    TICKETING_PROCEED_PAYMENT: 'TICKETING_PROCEED_PAYMENT',
    TICKETING_DONT_SEE_WINDOW: 'TICKETING_DONT_SEE_WINDOW',
    TICKETING_CLICK_HERE_PAYMENT: 'TICKETING_CLICK_HERE_PAYMENT',
    TICKETING_PURCHASE_COMPLETED: 'TICKETING_PURCHASE_COMPLETED',
    TICKETING_PURCHASE_COMPLETED_DESC: 'TICKETING_PURCHASE_COMPLETED_DESC',
    TICKETING_PURCHASE_COMPLETED_NOTE: 'TICKETING_PURCHASE_COMPLETED_NOTE',
    TICKETING_PURCHASE_FAILED: 'TICKETING_PURCHASE_FAILED',
    TICKETING_PURCHASE_FAILED_DESC: 'TICKETING_PURCHASE_FAILED_DESC',
    TICKETING_PURCHASE_PENDING: 'TICKETING_PURCHASE_PENDING',
    TICKETING_PURCHASE_PENDING_DESC: 'TICKETING_PURCHASE_PENDING_DESC',
    TICKETING_NOTE_HOST: 'TICKETING_NOTE_HOST',
    TICKETING_VIEW_EVENT: 'TICKETING_VIEW_EVENT',
    TICKETING_QUERIES_CONTACT: 'TICKETING_QUERIES_CONTACT',
    TICKETING_QUANTITY: 'TICKETING_QUANTITY',
    TICKETING_ITEM: 'TICKETING_ITEM',
    TICKETING_BASE_PRICE: 'TICKETING_BASE_PRICE',
    TICKETING_BACK_TICKETS: 'TICKETING_BACK_TICKETS',
    TICKETING_REGISTRATION_DETAILS: 'TICKETING_REGISTRATION_DETAILS',
    TICKETING_FILL_INFORMATION_BELOW: 'TICKETING_FILL_INFORMATION_BELOW',
    TICKETING_SEND_UNIQUE_LINK: 'TICKETING_SEND_UNIQUE_LINK',
    TICKETING_BILLING_ADDRESS: 'TICKETING_BILLING_ADDRESS',
    TICKETING_ADD_ADDRESS: 'TICKETING_ADD_ADDRESS',
    TICKETING_INFO_INVOICE: 'TICKETING_INFO_INVOICE',
    TICKETING_SELECT_COUNTRY_FIRST: 'TICKETING_SELECT_COUNTRY_FIRST',
    TICKETING_STREET_ADDRESS: 'TICKETING_STREET_ADDRESS',
    TICKETING_ZIP_CODE: 'TICKETING_ZIP_CODE',
    TICKETING_ENTER_COUPON_CODE: 'TICKETING_ENTER_COUPON_CODE',
    TICKETING_BOOK_TICKET: 'TICKETING_BOOK_TICKET',
    TICKETING_HAVE_TICKET: 'TICKETING_HAVE_TICKET',
    TICKETING_WHERE_TICKET: 'TICKETING_WHERE_TICKET',
    TICKETING_VIEW_TICKET: 'TICKETING_VIEW_TICKET',
    TICKETING_COUPON_APPLICABLE: 'TICKETING_COUPON_APPLICABLE',
    TICKETING_CHECKOUT: 'TICKETING_CHECKOUT',
    TICKETING_PROCEEDING_CHECKOUT: 'TICKETING_PROCEEDING_CHECKOUT',
    TICKETING_SENT_TO_EMAIL: 'TICKETING_SENT_TO_EMAIL',
    TICKETING_USE_LINK_SENT: 'TICKETING_USE_LINK_SENT',
    TICKETING_RESEND: 'TICKETING_RESEND',
    TICKETING_EXPLORE_AIRMEET: 'TICKETING_EXPLORE_AIRMEET',
    TICKETNG_COULDNT_PROCESS: 'TICKETNG_COULDNT_PROCESS',
    TICKETING_STILL_PROCESSING: 'TICKETING_STILL_PROCESSING',
    TICKETING_STILL_PROCESSING_UPDATE_SOON:
        'TICKETING_STILL_PROCESSING_UPDATE_SOON',
    TICKETING_ALPHANUMERIC: 'TICKETING_ALPHANUMERIC',
    ATTENDANCE_TYPE_ONLINE: 'ATTENDANCE_TYPE_ONLINE',
    ATTENDANCE_TYPE_IN_PERSON: 'ATTENDANCE_TYPE_IN_PERSON',
    ATTENDANCE_TYPE_HYBRID: 'ATTENDANCE_TYPE_HYBRID',
    LANGUAGE_CHANGE_TO: 'LANGUAGE_CHANGE_TO',
    SEE_ALL_LANGUAGES: 'SEE_ALL_LANGUAGES',
    NO_MATCHING_RESULTS_FOUND: 'NO_MATCHING_RESULTS_FOUND',
    LOUNGE_SEARCH_EMPTY_SUBTITLE: 'LOUNGE_SEARCH_EMPTY_SUBTITLE',
    MORE_INFO_EVENT_VISIT: 'MORE_INFO_EVENT_VISIT',
    ONLY_ORGANIZER_BY_VISIBLE: 'ONLY_ORGANIZER_BY_VISIBLE',
    LATEST_DEAL: 'LATEST_DEAL',
    DEAL_VALUE: 'DEAL_VALUE',
    DEAL_STAGE: 'DEAL_STAGE',
    DEAL_OWNER: 'DEAL_OWNER',
    NEW_LEAD: 'NEW_LEAD',
    GENERAL_OKAY_GOT_IT: 'GENERAL_OKAY_GOT_IT',
    NAVIGATING_WITH_KEYBOARD: 'NAVIGATING_WITH_KEYBOARD',
    INTERACT_WITH_CANVAS: 'INTERACT_WITH_CANVAS',
    MOVE_CANVAS: 'MOVE_CANVAS',
    RESIZE_CANVAS: 'RESIZE_CANVAS',
    SPEED_NETWORKING_USER_SKIPPED: 'SPEED_NETWORKING_USER_SKIPPED',
    SPEED_NETWORKING_USER_NOT_AVAILABLE: 'SPEED_NETWORKING_USER_NOT_AVAILABLE',
    SPEED_NETWORKING_USER_MATCH_ENDED: 'SPEED_NETWORKING_USER_MATCH_ENDED',
    SPEED_NETWORKING_USER_LEFT: 'SPEED_NETWORKING_USER_LEFT',
    SPEED_NETWORKING_MATCH_FINDING_TAKING_LONG_SUBTEXT:
        'SPEED_NETWORKING_MATCH_FINDING_TAKING_LONG_SUBTEXT',
    TIME_REMAINING: 'TIME_REMAINING',
    EVENT_SERIES_PART: 'EVENT_SERIES_PART',
    EVENT_SERIES_VISIT: 'EVENT_SERIES_VISIT',
    SPEED_NETWORKING_NO_PARTICIPANTS: 'SPEED_NETWORKING_NO_PARTICIPANTS',
    EVENT_SERIES_VIEW_DETAILS: 'EVENT_SERIES_VIEW_DETAILS',
    EVENT_SERIES_REGISTER: 'EVENT_SERIES_REGISTER',
    EVENT_SERIES_ADD_CALENDAR_PLEASE_JOIN:
        'EVENT_SERIES_ADD_CALENDAR_PLEASE_JOIN',
    EVENT_SERIES_VIEW_ALL_EVENTS: 'EVENT_SERIES_VIEW_ALL_EVENTS',
    EVENT_SERIES_ALL_EVENTS: 'EVENT_SERIES_ALL_EVENTS',
    EVENT_SERIES_REGISTER_TO_BLOCK: 'EVENT_SERIES_REGISTER_TO_BLOCK',
    EVENT_SERIES_PART_OF: 'EVENT_SERIES_PART_OF',
    EVENT_SERIES_REGISTER_FOR: 'EVENT_SERIES_REGISTER_FOR',
    EVENT_SERIES_REGISTER_FOR_SERIES: 'EVENT_SERIES_REGISTER_FOR_SERIES',
    EVENTS_LIST: 'EVENTS_LIST',
    EVENT_SERIES_CURRENT_NEXT_EVENT: 'EVENT_SERIES_CURRENT_NEXT_EVENT',
    EVENT_SERIES_MISSED_EVENT: 'EVENT_SERIES_MISSED_EVENT',
    EVENT_SERIES_VIEW_RECORDINGS: 'EVENT_SERIES_VIEW_RECORDINGS',
    EVENT_SERIES_REGISTER_THREE_STEPS: 'EVENT_SERIES_REGISTER_THREE_STEPS',
    EVENT_SERIES_REGISTER_TWO_STEPS: 'EVENT_SERIES_REGISTER_TWO_STEPS',
    EVENT_SERIES_VALID_PHONE_NO: 'EVENT_SERIES_VALID_PHONE_NO',
    EVENT_SERIES_PHONE_NO_EMPTY: 'EVENT_SERIES_PHONE_NO_EMPTY',
    EVENT_SERIES_WHICH_EVENT: 'EVENT_SERIES_WHICH_EVENT',
    EVENT_SERIES_NO_UPCOMING_EVENT: 'EVENT_SERIES_NO_UPCOMING_EVENT',
    EVENT_SERIES_REGISTER_ME_FOR_ALL: 'EVENT_SERIES_REGISTER_ME_FOR_ALL',
    EVENT_SERIES_ALL_IN_SERIES: 'EVENT_SERIES_ALL_IN_SERIES',
    EVENT_SERIES_PRIVATE: 'EVENT_SERIES_PRIVATE',
    EVENT_SERIES_ACCESS_RESERVED: 'EVENT_SERIES_ACCESS_RESERVED',
    EVENT_SERIES_NO_EVENTS: 'EVENT_SERIES_NO_EVENTS',
    EVENT_SERIES_NO_EVENTS_DESC: 'EVENT_SERIES_NO_EVENTS_DESC',
    EVENT_SERIES_HAPPENING_NOW: 'EVENT_SERIES_HAPPENING_NOW',
    FREE_TRIAL_EXPIRED_TOOLTIP_TEXT: 'FREE_TRIAL_EXPIRED_TOOLTIP_TEXT',
    RESUME_AUDIO_CONTEXT: 'RESUME_AUDIO_CONTEXT',
    RESUME_AUDIO: 'RESUME_AUDIO',
    ATTENDEE_LIVE_ALERT_NEARLY_REACHED_LIMIT_TITLE:
        'ATTENDEE_LIVE_ALERT_NEARLY_REACHED_LIMIT_TITLE',
    ATTENDEE_LIVE_ALERT_NEARLY_REACHED_LIMIT_DESC:
        'ATTENDEE_LIVE_ALERT_NEARLY_REACHED_LIMIT_DESC',
    ATTENDEE_LIVE_ALERT_REACHED_LIMIT_TITLE:
        'ATTENDEE_LIVE_ALERT_REACHED_LIMIT_TITLE',
    ATTENDEE_LIVE_ALERT_REACHED_LIMIT_DESC:
        'ATTENDEE_LIVE_ALERT_REACHED_LIMIT_DESC',
    ATTENDEE_LIVE_ALERT_ADDITIONAL_TEXT: 'ATTENDEE_LIVE_ALERT_ADDITIONAL_TEXT',
    ATTENDEE_LIVE_ALERT_CTA_TEXT: 'ATTENDEE_LIVE_ALERT_CTA_TEXT',
    GENERAL_ASCENDING: 'GENERAL_ASCENDING',
    GENERAL_DESCENDING: 'GENERAL_DESCENDING',
    GENERAL_FINISHED: 'GENERAL_FINISHED',
    FILTERS_SELECTED: 'FILTERS_SELECTED',
    VALID_EMAIL_ERROR_MSG: 'VALID_EMAIL_ERROR_MSG',
    TABLE_PLAN_UPGRADE_BANNER_HEADER_DYN:
        'TABLE_PLAN_UPGRADE_BANNER_HEADER_DYN',
    SEARCH_SPEAKERS: 'SEARCH_SPEAKERS',
    CLOSED_CAPTION_LANG_CHANGE_MODAL_TITLE:
        'CLOSED_CAPTION_LANG_CHANGE_MODAL_TITLE',
    CLOSED_CAPTION_LANG_CHANGE_MODAL_MESSAGE:
        'CLOSED_CAPTION_LANG_CHANGE_MODAL_MESSAGE',
    CLOSED_CAPTION_LANG_CHANGE_MODAL_DESCRIPTION:
        'CLOSED_CAPTION_LANG_CHANGE_MODAL_DESCRIPTION',
    USER_REG_NOT_APPROVED: 'USER_REG_NOT_APPROVED',
    REGISTER_AGAIN: 'REGISTER_AGAIN',
    REGISTRATION_PENDING_APPROVAL_TITLE: 'REGISTRATION_PENDING_APPROVAL_TITLE',
    REGISTRATION_PENDING_APPROVAL_DESCRIPTION:
        'REGISTRATION_PENDING_APPROVAL_DESCRIPTION',
    REGISTERED: 'REGISTERED',
    REGISTRATION_AWAITING_APPROVAL: 'REGISTRATION_AWAITING_APPROVAL',
    EMBED_FORM_USER_REG_NOT_APPROVED: 'EMBED_FORM_USER_REG_NOT_APPROVED',
};

export default keys;
