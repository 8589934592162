import { Box } from 'components/molecules/utils/basicComponents';
import styled from 'styled-components';

export const CardWrapper = styled(Box)`
    background-color: ${({ theme }) =>
        theme.colors.ambience[theme?.isLightTheme ? 24 : 21]};
    border-radius: ${({ borderRadius }) => borderRadius || '8px'};
    ${({ theme }) =>
        theme?.isLightTheme
            ? `
        border: solid 1px ${theme.colors.ambience[22]};
    `
            : ''}
    ${({ theme, isHover, isDisabled }) =>
        isHover && !isDisabled
            ? `
        cursor: pointer;
        &:hover {
            background-color: ${
                theme.colors.ambience[theme?.isLightTheme ? 21 : 19]
            };
        }
    `
            : ''}
`;
