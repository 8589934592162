import LoaderV2 from 'components/general/LoaderV2';
import { alpha } from 'foundations/theme';
import { motion } from 'framer-motion';
import useWhiteLabelling from 'hooks/useWhitelabelling';
import React from 'react';
import styled from 'styled-components';
import { getCurrentTenantData } from 'utils/tenant';
import { cdnImage } from '../../../utils/core';

const LogoLoader = ({ logoUrl }) => {
    const transition = {
        yoyo: Infinity,
        duration: 4,
        ease: [0.65, 0, 0.35, 1],
    };

    const {
        isWhiteLabellingEnabled,
        isWhiteLabellingLoading,
        isAirmeetBrandingRestricted,
    } = useWhiteLabelling();

    return (
        <>
            {isWhiteLabellingLoading ? (
                <LoaderV2 />
            ) : isWhiteLabellingEnabled || isAirmeetBrandingRestricted ? (
                <LoaderV2 />
            ) : (
                <Container>
                    <Ripple
                        initial={{ scale: 1, opacity: 0.5 }}
                        animate={{ scale: 1.5 }}
                        transition={transition}
                    />
                    <Ripple
                        initial={{ scale: 1.5 }}
                        animate={{ scale: 1 }}
                        transition={transition}
                    />
                    <Ripple
                        initial={{ scale: 0.8 }}
                        // animate={{ scale: 0.8 }}
                        transition={transition}
                        black
                    />
                    <LogoImage
                        src={
                            logoUrl ||
                            cdnImage(getCurrentTenantData('logoNoTextUrl'))
                        }
                        alt='Logo'
                        initial={{ scale: 1 }}
                        animate={{ scale: 0.8 }}
                        transition={transition}
                    />
                </Container>
            )}
        </>
    );
};

const LogoImage = styled(motion.img)`
    position: absolute;
    width: 24px;
    height: auto;
`;

const WhiteLabelContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    width: 9rem;
    margin-left: -1.5rem;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    width: 5rem;
`;

const Ripple = styled(motion.div)`
    position: absolute;
    width: 5rem;
    height: 5rem;
    background-color: ${(props) =>
        props.black
            ? props.theme.colors.ambience[24]
            : alpha(props.theme.colors.ambience[0], 0.08)};
    border-radius: 50%;
`;

export default LogoLoader;
