import NavTab from 'atoms/NavTab';
import withErrorBoundary, {
    DashboardModules,
} from 'components/hocs/withErrorBoundary';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import upperFirst from 'lodash/upperFirst';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAirmeetFormat } from 'utils/airmeet';
import history from '../../../../../history';
import { ANALYTICS_ENGAGEMENT_TAB } from '../../constants/tabs';
import { AnalyticsContext } from '../AnalyticsProvider';
import SpecialRolesFilterSwitch from '../components/SpecialRolesFilterSwitch';
import TopBanner from '../components/TopBanner';
import BoothActiveAttendanceCard from './BoothActiveAttendanceCard';
import BoothAttendanceCard from './BoothAttendanceCard';
import LoungeActiveAttendanceCard from './LoungeActiveAttendanceCard';
import LoungeAttendanceCard from './LoungeAttendanceCard';
import SessionActiveAttendanceCard from './SessionActiveAttendanceCard';
import SessionAttendanceCard from './SessionAttendanceCard';

const NAV_TABS = [
    { id: 'attendance', title: 'Attendance', disabled: false },
    {
        id: 'active-attendees',
        title: 'Active Attendees',
        disabled: false,
        info: 'Number of unique users who performed an activity.',
    },
];

const DEFAULT_NAV_TAB_ID = NAV_TABS[0]?.id;

export default withErrorBoundary(function EngagementTab() {
    const { hasBooths, getFeatureUrl } = useContext(AnalyticsContext);
    const airmeet = useSelector((state) => state.cmDashboard.airmeet);
    const [activeTabId, setActiveTabId] = useState(DEFAULT_NAV_TAB_ID);

    const params = useParams();
    const { subTab, subTab2 } = params || {};
    const tabId = subTab2 || subTab;
    useEffect(() => {
        if (tabId) {
            setActiveTabId(tabId);
        } else {
            setActiveTabId(DEFAULT_NAV_TAB_ID);
        }
    }, [tabId]);

    const handleTabChange = (item) => {
        if (!item?.disabled) {
            history.push(
                `${getFeatureUrl(ANALYTICS_ENGAGEMENT_TAB)}/${item?.id}`
            );
        }
    };
    const { event_type } = airmeet || {};
    const airmeetFormat = getAirmeetFormat({
        eventType: event_type,
    });
    return (
        <>
            <TopBanner
                title={`See if your ${airmeetFormat} are keeping audiences hooked and riveted`}
                subtitle={`Get a deep-dive into your live event engagement. ${upperFirst(
                    airmeetFormat
                )} Replay data is not included here.`}
                imgSrc='/images/dashboard/analytics/il-cheer.svg'
            />
            <FlexDiv alignItems='center' justifyContent='flex-end' pb='x4'>
                <SpecialRolesFilterSwitch tabId={ANALYTICS_ENGAGEMENT_TAB} />
            </FlexDiv>
            <NavTab
                tabs={NAV_TABS}
                activeTabId={activeTabId}
                onChange={handleTabChange}
                borderColor='#393743'
                hideActiveBorder={true}
            />
            <FlexDiv
                flexDirection='column'
                alignItems='stretch'
                p='0 0 24px 0'
                mt='24px'
                position='relative'
            >
                {activeTabId === DEFAULT_NAV_TAB_ID ? (
                    <>
                        <SessionAttendanceCard airmeetFormat={airmeetFormat} />
                        {hasBooths && <BoothAttendanceCard />}
                        <LoungeAttendanceCard />
                    </>
                ) : (
                    <>
                        <SessionActiveAttendanceCard />
                        {hasBooths && <BoothActiveAttendanceCard />}
                        <LoungeActiveAttendanceCard />
                    </>
                )}
            </FlexDiv>
        </>
    );
}, DashboardModules.ANALYTICS_ENGAGEMENT);
