import { IS_CUSTOM_TENANT } from 'utils/tenant';
import history from '../history';
import { logger } from './logger';

export const ExternalUrls = {
    PRICING_PAGE: 'https://www.airmeet.com/hub/pricing',
    TERMS_OF_USE: 'https://www.airmeet.com/hub/terms-of-use/',
    PRIVACY_POLICY: 'https://www.airmeet.com/hub/privacy-policy/',
    PRIVACY_NOTICE: 'https://www.airmeet.com/hub/privacy-notice/',
    HUB_EVENTS: 'https://www.airmeet.com/hub/events/',
    AIRMEET_TWITTER: 'https://twitter.com/airmeet',
    AIRMEET_FACEBOOK: 'https://www.facebook.com/airmeetcom',
    AIRMEET_LINKEDIN: 'https://www.linkedin.com/company/airmeet-com/',
    SUPPORT_LOUNGE:
        'https://www.airmeet.com/e/922ace20-14e1-11ee-b6cb-8dc02d88e80b',
};

export const redirectToHomePage = (baseUrl = null) => {
    // Base url proxy pass should be added at server/nginx level.
    if (process.env.REACT_APP_ENV !== 'production' || IS_CUSTOM_TENANT) {
        // Dont redirect to home page for dev and test envs
        history.replace('/signin');
    } else {
        window.location.replace(baseUrl || process.env.REACT_APP_BASE_URL);
    }
};

export const getUrlParamsString = (
    addPrams = [],
    deleteParams = [],
    fallbackString = ''
) => {
    try {
        const search = history?.location?.search;
        const searchParams = new URLSearchParams(search?.slice(1));
        searchParams.delete('preview');
        if (Array.isArray(deleteParams) && deleteParams.length > 0) {
            deleteParams.forEach((param) => {
                if (param) {
                    searchParams.delete(param);
                }
            });
        }
        if (Array.isArray(addPrams) && addPrams.length > 0) {
            addPrams.forEach((param) => {
                if (param.key && param.value) {
                    searchParams.set(param.key, param.value);
                }
            });
        }
        return `?${searchParams.toString()}`;
    } catch (e) {
        logger.error('getUrlParamsString: ', e);
        return fallbackString;
    }
};
