import Drawer from 'atoms/Drawer/drawer';
import EventLoader from 'components/EventLoader';
import { CHAR_LIMIT_MAP } from 'components/community/CreateAirmeetForm/CreateAirmeetV2/validations';
import {
    CreateEventLoadingStates,
    EVENTS_PAGE_TAB,
    GETTING_STARTED_TAB,
} from 'components/community/constants';
import { getEventEntryAccessOptions } from 'components/community/editPublicAirmeet/constants';
import {
    endDateToStartDateTimeDiff,
    getSubstring,
} from 'components/newOnboardingFlow/utils';
import { WELCOME_MODAL_PATH } from 'components/newOnboardingFlow/v3/config';
import addDays from 'date-fns/addDays';
import addMinutes from 'date-fns/addMinutes';
import isValid from 'date-fns/isValid';
import colorTheme from 'foundations/theme';
import { useShallowEqualSelector } from 'hooks/common';
import useDashboardFeatureFlags from 'hooks/community/useDashboardFeatureFlags';
import useLeadEvents from 'hooks/leads/useLeadEvents';
import { CREATE_LEAD } from 'hooks/leads/utils';
import useOnboardingAnalytics from 'hooks/onboarding/useOnboardingAnalytics';
import useConferenceTrialLeadHandler from 'hooks/useConferenceTrialLeadHandler';
import useDeviceInfo from 'hooks/useDeviceInfo';
import useTheme from 'hooks/useTheme';
import useTimeAndDateFormUtils from 'hooks/useTimeDateFormUtils';
import useTimeZonesValues from 'hooks/useTimezoneValues';
import useToasts from 'hooks/useToasts';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import toNumber from 'lodash/toNumber';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getEventEntrypoints,
    newUpdateAirmeetEntrypoints,
} from 'store/actions/airmeetRules';
import { uploadBase64Image } from 'store/actions/fileUplaoder';
import { setIsNewOnboardingPage } from 'store/actions/genral';
import { getAirmeetFormat, getEventTypeLabel } from 'utils/airmeet';
import {
    AIRMEET_STATUS,
    EVENT_SUB_TYPES,
    EVENT_TYPES,
} from 'utils/constants/airmeet';
import { validationErrors } from 'utils/constants/common';
import { CmDashboardAnalyticsTypes } from 'utils/constants/community/community';
import { SESSION_TYPES } from 'utils/constants/sessions';
import { DEFAULT_TABLE_COUNT } from 'utils/constants/social-lounge';
import { TOAST_VERSION_2 } from 'utils/constants/toasts';
import {
    canShowPremiumFeatures,
    isMaliciousStringPresent,
    validateMaliciousString,
} from 'utils/core';
import firebaseLogger, { CmDashboardEvents } from 'utils/firebaseLogger';
import { logger } from 'utils/logger';
import { getCurrentTenantData } from 'utils/tenant';
import history from '../../../../history';
import {
    createAirmeet,
    editAirmeet,
    fetchAirmeetInfo,
    fetchAirmeetStatus,
    fetchCreatedAirmeetInfo,
    resetCmDashboardCreatedState,
} from '../../../../store/actions/cmDashboard/airmeet';
import { closeAppModal, showAppModal } from '../../../../store/actions/layout';
import { removeToast } from '../../../../store/actions/toasts';
import { MODAL_TYPE_AIR_GENIE } from '../../../modals/constants';
import { SUMMARY_TAB } from '../../editPublicAirmeet/constants/tabs';
import {
    validateFormData,
    validateSingleField,
} from '../CreateAirmeetV2/validations';
import { DASHBOARD_INITIATION, NEW_ONBOARDING_INITIATION } from '../constants';
import FormContent from './FormContent';
import { useInitialValues } from './initialValues';
import { ERROR_CODES, getAirmeetDetails } from './utils';
import { ATTENDEE } from 'components/meteredPricing/MeterModal/constantsV2';

const EVENT_IMAGE_REQUEST_KEY = 'eventImage';

const SOCIAL_LOUNGE_TABLES = {
    EMPTY: 0,
    DEFAULT: DEFAULT_TABLE_COUNT,
};

export const CreateAirmeetV2 = ({
    onClose,
    airmeet,
    airmeetId,
    editMode,
    initiatedFrom = DASHBOARD_INITIATION,
    defaultCommunityId = '',
    defaultCommunityName = '',
    pageMode = false,
    submitBtnId = '',
    isFullScreen = false,
    templateCode,
    templateTotalSessionDuration = 0,
    isEventStepTemplate = false,
    eventTemplateStep,
    setEventTemplateStep,
    handleCloseCreateFlow = () => {},
    currentTemplate = null,
    onSubmitSuccess = null,
    fixedEventEntryType = null,
    createForEventSeries = false,
    eventSubType: eventSubTypeProp,
    eventType: eventTypeProp,
}) => {
    const { theme } = useTheme();
    const { handleCreateTrialLead } = useConferenceTrialLeadHandler();
    const community = useSelector((state) => state.Community.current);
    const subscriptionData =
        useSelector((state) => state.Community.subscriptionData) || {};
    const { subscription_type } = community || {};
    const [loading, setLoading] = useState(false);
    const [showSidemenu, setShowSideMenu] = useState('');
    const [typeDateTime, setTypeDateTime] = useState('');
    const [selectedOption, setActiveOption] = useState([]);
    const [currentLoadingState, setCurrentLoadingState] = useState(
        'eventSetup'
    );
    const [isCreateProgressPage, setIsCreateProgressPage] = useState(false);

    const eventType = editMode ? airmeet?.event_type : eventTypeProp;
    const eventSubType = editMode ? airmeet?.event_sub_type : eventSubTypeProp;
    const isHybridEvent = eventSubType === EVENT_SUB_TYPES.HYBRID_CONFERENCE;
    const isInPersonEvent =
        eventSubType === EVENT_SUB_TYPES.IN_PERSON_CONFERENCE;
    const {
        isHybridEnabled,
        isInPersonEventEnabled,
    } = useDashboardFeatureFlags({});

    const { errorToast, successToast, warningToast } = useToasts();
    const dispatch = useDispatch();
    const currentToastId = useSelector((state) => get(state, 'toasts[0].id'));
    const initialFormData = useInitialValues(
        airmeet,
        community,
        templateTotalSessionDuration,
        eventType
    );
    const [eventCreated, setEventCreated] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [analyticsEventsFired, setAnalyticsEventsFired] = useState([]);
    const uploadedImgUrl = useSelector(
        (state) => state.FileUploader.files[EVENT_IMAGE_REQUEST_KEY]
    );
    const { analyticsEventType, logAnalyticsEvent } = useOnboardingAnalytics();
    const timeZone = useTimeZonesValues({ timezone: formData.timeZone });
    const {
        communityId,
        name: community_name,
        manager_id,
    } = useSelector((state) => get(state, 'Community.current', {}));
    const eventEntryOption =
        useSelector((state) => state?.cmDashboard?.eventEntryOption) || {};

    const isLiteMeetup =
        eventType === EVENT_TYPES.MEETUP &&
        (eventSubType === EVENT_SUB_TYPES.LITE ||
            formData?.meetupSubType === EVENT_SUB_TYPES.LITE);

    const replaceBase64WithS3Imgs = async (longDesc) => {
        try {
            if (longDesc && longDesc.trim() !== 'longDesc') {
                // Generate DOM replica of the HTML string
                const div = document.createElement('div');
                div.innerHTML = longDesc;
                const imgs = [...div.querySelectorAll('img')];
                const base64Imgs = [];
                let hasError = false;

                // Filter out the base64 images
                imgs.forEach((img) => {
                    if (img?.src && img?.src?.startsWith('data:')) {
                        base64Imgs.push(img);
                    }
                });

                if (base64Imgs.length > 0) {
                    // Upload all base64 images to S3
                    const responses = await Promise.all(
                        base64Imgs.map((img) =>
                            dispatch(
                                uploadBase64Image(null, img?.src, 'long-desc')
                            )
                        )
                    );

                    // Set all S3 images to the DOM imgs
                    (responses || []).forEach((response, index) => {
                        if (!response.error) {
                            const imagePath = response?.payload?.file?.url;
                            base64Imgs[index].src = imagePath;
                        } else {
                            hasError = true;
                        }
                    });

                    // Show toast if upload failed
                    if (hasError) {
                        warningToast({
                            title: 'Image upload failed',
                            message:
                                'Could not upload images, please check your image format',
                            version: TOAST_VERSION_2,
                        });
                    }

                    return div.innerHTML;
                }
            }
        } catch (e) {
            logger.error('CreateAirmeetV2.replaceBase64WithS3Imgs', e);
        }

        return longDesc;
    };
    const airmeetFormat = getAirmeetFormat({ eventType });

    useEffect(() => {
        setFormData((val) => ({
            ...(val || {}),
            isWaitlistEnabled:
                eventEntryOption?.isWaitlistEnabled ||
                eventEntryOption?.isWaitListEnabled,
        }));
    }, [eventEntryOption]);

    const EVENT_ENTRY_ACCESS_OPTIONS = getEventEntryAccessOptions({
        isInPersonEvent,
        isOnRbpPlan: subscriptionData?.limit_level !== ATTENDEE,
    });

    const initialEventEntryValue = useMemo(() => {
        if (editMode) {
            let initialEventEntryValue = null;
            Object.keys(EVENT_ENTRY_ACCESS_OPTIONS).forEach((key) => {
                if (
                    get(EVENT_ENTRY_ACCESS_OPTIONS, `${key}.entryType`) ===
                    (fixedEventEntryType
                        ? fixedEventEntryType
                        : eventEntryOption?.entryType)
                ) {
                    initialEventEntryValue = get(
                        EVENT_ENTRY_ACCESS_OPTIONS,
                        `${key}.value`
                    );
                }
            });

            return initialEventEntryValue || 'email';
        }

        if (currentTemplate?.config?.eventEntry) {
            let templateEventEntryValue = null;
            Object.keys(EVENT_ENTRY_ACCESS_OPTIONS).forEach((key) => {
                if (
                    get(EVENT_ENTRY_ACCESS_OPTIONS, `${key}.entryType`) ===
                    (fixedEventEntryType
                        ? fixedEventEntryType
                        : currentTemplate?.config?.eventEntry)
                ) {
                    templateEventEntryValue = get(
                        EVENT_ENTRY_ACCESS_OPTIONS,
                        `${key}.value`
                    );
                }
            });

            return templateEventEntryValue || 'email';
        }

        return isInPersonEvent && subscriptionData?.limit_level !== ATTENDEE
            ? 'login'
            : 'email';
    }, [
        editMode,
        eventEntryOption,
        currentTemplate,
        isInPersonEvent,
        fixedEventEntryType,
        subscriptionData,
    ]);

    const user = useShallowEqualSelector(({ auth }) => auth.user);
    const { id: uid, first_name: firstName, email } = user || {};
    const [eventEntryValue, setEventEntryValue] = useState(
        initialEventEntryValue
    );
    const [domainList, setDomainList] = useState([]);

    const {
        browser: { name: browser },
        isTablet,
        isIOS,
    } = useDeviceInfo();

    const [errors, setErrors] = useState({});
    const nameRef = useRef('');

    const {
        handleLeadOperation,
        eventTypes,
        getLeadDataFromStore,
    } = useLeadEvents();

    const handleNameError = async () => {
        const nameValid = await validateSingleField(
            'name',
            formData.name,
            setErrors,
            false
        );
        !nameValid &&
            nameRef.current.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
    };
    const onboardingInitiation = initiatedFrom === NEW_ONBOARDING_INITIATION;
    const { createsEvent } = eventTypes;

    const createEventLeadData = getLeadDataFromStore(createsEvent);

    // Close AirGenie modal on form close
    useEffect(() => {
        return () => dispatch(closeAppModal(MODAL_TYPE_AIR_GENIE));
    }, [dispatch]);

    useEffect(() => {
        handleLeadOperation({ leadName: createsEvent, setLoading });
    }, [createsEvent]);

    useEffect(() => {
        return () => {
            dispatch(resetCmDashboardCreatedState());
        };
    }, [dispatch, communityId]);
    useEffect(() => {
        if (uploadedImgUrl) {
            setFormData((formData) => ({
                ...formData,
                eventImage: uploadedImgUrl.imageURL,
            }));
        }
    }, [uploadedImgUrl]);

    useEffect(() => {
        if (onboardingInitiation) {
            const exploreAirmeet = `Explores ${getCurrentTenantData('name')}`;

            setFormData((formData) => ({
                ...formData,
                name: `${getSubstring(
                    firstName,
                    0,
                    CHAR_LIMIT_MAP.name.max - (exploreAirmeet.length + 1)
                )} ${exploreAirmeet}`,
                eventOrganiserName: defaultCommunityName || '',
                initDate: addMinutes(new Date(), 15),
                finalDate: addDays(addMinutes(new Date(), 15), 14),
            }));
        }
    }, [firstName, defaultCommunityName, onboardingInitiation]);
    useEffect(() => {
        if (onboardingInitiation) {
            if (errors?.initDate || errors?.initTime) {
                logAnalyticsEvent(
                    analyticsEventType.createEventStartTimeError,
                    {
                        message: errors?.initDate
                            ? errors?.initDate
                            : errors?.initTime,
                        user_id: uid,
                    }
                );
            }
        }
    }, [
        onboardingInitiation,
        errors,
        analyticsEventType.createEventStartTimeError,
        logAnalyticsEvent,
        uid,
    ]);

    const onValueChange = (key, value) => {
        logger.debug('Value', key, value);
        if (key === 'hasVirtualTables' && !airmeet?.tableCount) {
            setFormData((formData) => ({
                ...formData,
                [key]: value,
                tableCount: value
                    ? SOCIAL_LOUNGE_TABLES.DEFAULT
                    : SOCIAL_LOUNGE_TABLES.EMPTY,
            }));
        } else {
            setFormData((formData) => ({ ...formData, [key]: value }));
        }
    };

    const changeFormData = async ({ target: { name, value } }) => {
        if (name) {
            if (name === 'tableCount') {
                if (!value) {
                    value = '1';
                } else if (+value > 500) {
                    value = '500';
                }
            }
            if (
                ['initDate', 'finalDate', 'duration'].includes(name) &&
                (isEmpty(value) || !isValid(value))
            ) {
                return;
            }
            setFormData({
                ...formData,
                [name]: value,
            });
            const onboardingFieldCheck =
                name === 'eventOrganiserName' && onboardingInitiation;
            await validateSingleField(
                name,
                value,
                setErrors,
                onboardingFieldCheck
            );
        }
    };

    const updateDateTimeErrorMap = (key, value) => {
        if (value?.status === false)
            setErrors((prevValue) => ({
                ...prevValue,
                [key]: '',
            }));
    };

    const {
        getTzAdjustedPayload,
        onStateChanged,
        getMinTime,
        getMinDate,
    } = useTimeAndDateFormUtils({
        formData,
        setFormData,
        updateErrorMap: onboardingInitiation
            ? updateDateTimeErrorMap
            : () => {},
        templateTotalSessionDuration,
        templateCode,
    });

    const eventTypeLabel = getEventTypeLabel({
        eventType,
        eventSubType,
    });

    const updateEventEntry = async ({
        airmeetId,
        isHybridEvent,
        isEdit,
        isWaitlistEnabled = false,
    }) => {
        if (isHybridEvent) return;

        const entryType = get(
            EVENT_ENTRY_ACCESS_OPTIONS,
            `${
                fixedEventEntryType ? fixedEventEntryType : eventEntryValue
            }.entryType`
        );
        const initialEntryType = get(
            EVENT_ENTRY_ACCESS_OPTIONS,
            `${initialEventEntryValue}.entryType`
        );

        if (
            ((!isEdit &&
                entryType === 'EMAIL_AUTHENTICATION' &&
                !isWaitlistEnabled) ||
                (isEdit &&
                    initialEntryType === entryType &&
                    !isInPersonEvent)) &&
            !createForEventSeries
        ) {
            return;
        }

        const entryPoint =
            domainList.length > 0
                ? {
                      ...(eventEntryOption || {}),
                      domains: (domainList || []).map((item) => item?.value),
                      entryType,
                      isWaitlistEnabled,
                      isWaitListEnabled: isWaitlistEnabled,
                  }
                : { isWaitlistEnabled, isWaitListEnabled: isWaitlistEnabled };

        const response = await dispatch(
            newUpdateAirmeetEntrypoints({
                airmeetId,
                body: { ...entryPoint, entryType },
            })
        );
        if (response?.error) {
            errorToast({
                title: `Failed to update ${airmeetFormat} entry changes`,
                version: TOAST_VERSION_2,
            });
            return;
        } else {
            const response = await dispatch(getEventEntrypoints({ airmeetId }));
            if (response?.error) {
                logger.error(response.error);
            }
        }
    };

    const handleDateTimeError = (isValid, isEventStepTemplate) => {
        if ((errors?.initDate || errors?.initTime) && !isValid) {
            const getElement = document.getElementById('data-time-error');
            getElement &&
                getElement.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                });
            if (isEventStepTemplate) setEventTemplateStep('templateCreateForm');
        }
    };

    const handleSupportEmailError = (isValid) => {
        if (errors?.supportEmail && !isValid) {
            const getElement = document.getElementById('supportEmail');
            getElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        }
    };

    const handleSubmit = async () => {
        if (loading) {
            return;
        }
        if (validateMaliciousData(formData)) {
            return;
        }
        const isValid = await validateFormData(
            formData,
            {
                abortEarly: false,
                context: {
                    timeZone: formData.timeZone,
                    meetupSubType: formData.meetupSubType,
                    venue_capacity: formData?.venue_capacity,
                },
            },
            setErrors
        );

        let nameValid = true;
        if (onboardingInitiation) {
            nameValid = await validateSingleField(
                'eventOrganiserName',
                formData.eventOrganiserName,
                setErrors,
                onboardingInitiation
            );
        }

        if (!isValid) {
            !showSidemenu && handleNameError();

            handleDateTimeError(isValid, isEventStepTemplate);

            handleSupportEmailError(isValid);
            return false;
        }
        if (!nameValid) return false;

        setLoading(true);

        if (isEventStepTemplate && eventTemplateStep === 'templateCreateForm') {
            setEventTemplateStep('templateChooseEventType');
            setLoading(false);
            return false;
        }

        if (currentToastId) {
            dispatch(removeToast(currentToastId));
        }
        const { metadata, timeInfo, miscInfo } = getAirmeetDetails({
            formData,
            subscription_type,
            eventType,
            getTzAdjustedPayload,
            pageMode,
            onboardingInitiation,
            endDateToStartDateTimeDiff,
        });

        if (miscInfo?.longDesc) {
            miscInfo.longDesc = await replaceBase64WithS3Imgs(
                miscInfo?.longDesc
            );
        }

        let createAirmeetProps = {
            communityId: communityId || defaultCommunityId,
            metadata,
            timeInfo,
            miscInfo,
            eventType,
            eventOrganiserName: formData?.eventOrganiserName,
            supportEmail: formData?.supportEmail,
            enableEmailAuth: true,
        };
        if (templateCode) createAirmeetProps.templateCode = templateCode;

        if (onboardingInitiation) createAirmeetProps.source = 'onboarding';

        if (eventSubType) {
            createAirmeetProps = {
                ...createAirmeetProps,
                eventSubType,
                enableEmailAuth:
                    isInPersonEvent && eventEntryValue === 'email'
                        ? true
                        : false,

                metadata: {
                    ...metadata,
                    private: true,
                    attendeeListEnabled:
                        isInPersonEvent && eventEntryValue === 'email'
                            ? false
                            : true,
                    noauth: false,
                },
            };
        }

        if (isLiteMeetup) {
            createAirmeetProps.eventSubType = EVENT_SUB_TYPES.LITE;

            createAirmeetProps.sessionMeta = {
                type: formData?.sessionType,
                createRtmpKey:
                    formData?.sessionType === SESSION_TYPES.STREAMING,
                hideHost: false,
                isFluidSpaceCustomBg: false,
            };
            if (formData?.sessionType === 'PRE_RECORDED') {
                createAirmeetProps.sessionMeta = {
                    ...createAirmeetProps.sessionMeta,
                    videos: formData?.sessionMeta?.videos || [],
                };
            }
            if (formData?.duration) {
                const hours = Math.floor(formData.duration / 60);
                const minutes = formData.duration % 60;
                createAirmeetProps.timeInfo = {
                    ...(createAirmeetProps.timeInfo || {}),
                    duration: {
                        hh: parseInt(hours),
                        mm: parseInt(minutes),
                    },
                };
            }
        }

        if (!isEmpty(formData?.eventVenue)) {
            createAirmeetProps = {
                ...createAirmeetProps,
                hybridEventVenue: formData?.eventVenue,
            };
        }

        const venueCapacity = toNumber(formData?.venue_capacity);
        if (!isNaN(venueCapacity)) {
            createAirmeetProps = {
                ...createAirmeetProps,
                hybridEventVenue: {
                    ...(createAirmeetProps?.hybridEventVenue || {}),
                    venue_capacity: venueCapacity,
                },
            };
        }
        setIsCreateProgressPage(true);
        const response = await dispatch(createAirmeet(createAirmeetProps));
        if (response.error) {
            setIsCreateProgressPage(false);
            const { code, errors, message } = response.payload?.json || {};
            let errorMsg = `Could not create ${airmeetFormat}, please try again`;
            if (code === ERROR_CODES.AIRMEET_DOWN_TIME_ERROR && message) {
                errorMsg = message;
            } else if (isMaliciousStringPresent(code, errors)) {
                errorMsg = validationErrors.RESPONSE_ERROR;
            } else if (message) {
                errorMsg = message;
            }
            errorToast({
                title: errorMsg,
                version: TOAST_VERSION_2,
            });
            if (
                CHAR_LIMIT_MAP['eventOrganiserName'].max <
                response.body.eventOrganiserName.length
            ) {
                setErrors({
                    ...errors,
                    eventOrganiserName: 'Name has exceeded character limit',
                });
            }
            if (isEventStepTemplate) setIsCreateProgressPage(false);
        } else {
            setCurrentLoadingState('configSetup');
            const { uuid } = response?.payload || {};
            await updateEventEntry({
                airmeetId: uuid,
                isHybridEvent,
                isEdit: false,
                isWaitlistEnabled: formData?.isWaitlistEnabled || false,
            });
            if (onboardingInitiation) {
                if (pageMode)
                    logAnalyticsEvent(
                        analyticsEventType.clickedCreateEventOnboarding
                    );
                dispatch(setIsNewOnboardingPage(false));
            }

            //TODO: Add in-person event logs
            if (isHybridEvent) {
                const data = {
                    community_id: communityId,
                    airmeet_id: uuid,
                    user_role:
                        uid === manager_id
                            ? 'Community Manager'
                            : 'Event Manager',
                    user_id: uid,
                };
                firebaseLogger.hybridEventFirebaseLogs(
                    data,
                    'hybridEventCreated'
                );
                if (createAirmeetProps?.hybridEventVenue?.venue_title) {
                    firebaseLogger.hybridEventFirebaseLogs(
                        {
                            ...data,
                            venue:
                                createAirmeetProps?.hybridEventVenue
                                    ?.venue_title,
                        },
                        'onEnterHybridVenue'
                    );
                }
            } else {
                firebaseLogger.cmDash({
                    category: CmDashboardAnalyticsTypes.events,
                    event: CmDashboardEvents.Events.event_created,
                    eventData: {
                        community_id: communityId,
                        community_name,
                        uid,
                        user_role:
                            uid === manager_id
                                ? 'Community Manager'
                                : 'Event Manager',
                        browser,
                        event_name: formData.name,
                        start_time: formData.initDate,
                        end_time: formData.finalDate,
                        timezone: formData.timeZone,
                        social_lounge_status: formData.hasVirtualTables
                            ? 'enabled'
                            : 'disabled',
                        airmeet_id: uuid,
                        event_type: createAirmeetProps?.eventType,
                        event_sub_type: createAirmeetProps?.eventSubType,
                        duration: formData?.duration,
                        sessionType: formData?.sessionType,
                    },
                });
            }

            if (
                subscription_type !== 'ENTERPRISE' &&
                subscription_type !== 'APPSUMO' && // added this since appsumo users doesnt need conference trial
                eventType === EVENT_TYPES.CONFERENCE
            ) {
                handleCreateTrialLead();
            }

            if (isEmpty(createEventLeadData)) {
                const payload = {
                    leads: [
                        {
                            email: email,
                            name: createsEvent,
                            value: true,
                        },
                    ],
                };
                handleLeadOperation({
                    leadName: createsEvent,
                    payload,
                    operation: CREATE_LEAD,
                });
            }

            // check Status api for configuration setup
            let isConfigSetupFailed = false;
            let isStatusLoaded = false;
            while (!isStatusLoaded) {
                let statusResponse = await dispatch(fetchAirmeetStatus(uuid));
                if (statusResponse === AIRMEET_STATUS.FAILED) {
                    isConfigSetupFailed = true;
                    isStatusLoaded = true;
                } else if (statusResponse === AIRMEET_STATUS.CREATED) {
                    isStatusLoaded = true;
                    setCurrentLoadingState('completed');
                }
            }

            if (isConfigSetupFailed) {
                history.push(`/community-manager/${communityId}/events`);
                setIsCreateProgressPage(false);
                errorToast({
                    title: `Could not create ${airmeetFormat}, please try again`,
                    version: TOAST_VERSION_2,
                });
                handleCloseCreateFlow();
                return;
            }

            if (!onSubmitSuccess) {
                await dispatch(fetchCreatedAirmeetInfo(uuid));

                if (pageMode) {
                    history.push({
                        pathname: `/airmeets/${uuid}/${SUMMARY_TAB}/${WELCOME_MODAL_PATH}`,
                        tab: onboardingInitiation
                            ? GETTING_STARTED_TAB
                            : EVENTS_PAGE_TAB,
                        state: { welcomeModal: true },
                    });
                } else {
                    history.push(`/airmeets/${uuid}/${SUMMARY_TAB}`, {
                        tab: onboardingInitiation
                            ? GETTING_STARTED_TAB
                            : EVENTS_PAGE_TAB,
                    });
                    const label = getEventTypeLabel({
                        eventType: createAirmeetProps?.eventType,
                        eventSubType: createAirmeetProps?.eventSubType,
                    });
                    successToast({
                        title: `${label} created successfully`,
                        message: `You can add additional details to make your ${airmeetFormat} stand out`,
                        version: TOAST_VERSION_2,
                    });
                }
                setEventCreated(true);
                if (!isEventStepTemplate) {
                    onClose();
                }
            } else {
                onSubmitSuccess(uuid);
            }
        }
        setLoading(false);
    };
    const validateMaliciousData = (formData) => {
        let errorMapTemp = {};
        Object.keys(formData).forEach((field) => {
            if (field !== 'longDesc') {
                const isMalicious = validateMaliciousString({
                    value: formData[field],
                    key: field,
                    returnError: false,
                });
                if (isMalicious) {
                    errorMapTemp[field] = validationErrors.FIELD_ERROR;
                }
            }
        });
        if (!isEmpty(errorMapTemp)) {
            setErrors({
                ...errors,
                ...errorMapTemp,
            });
            logger.error('Malicious data present in the form fields');
            return true;
        }
        return false;
    };
    const handleEditSubmit = async () => {
        if (loading) {
            return;
        }
        if (validateMaliciousData(formData)) {
            return;
        }
        const isValid = await validateFormData(
            formData,
            {
                abortEarly: false,
                context: {
                    timeZone: formData.timeZone,
                    editMode,
                    meetupSubType: formData.meetupSubType,
                    venue_capacity: formData?.venue_capacity,
                },
            },
            setErrors
        );
        if (
            formData.tableCount < airmeet.tableCount &&
            formData.hasVirtualTables
        ) {
            setErrors({ tableCount: "You can't reduce the number of tables" });
            return false;
        }
        if (!isValid) {
            return false;
        }
        setLoading(true);
        if (currentToastId) {
            dispatch(removeToast(currentToastId));
        }
        const { metadata, timeInfo, miscInfo } = getAirmeetDetails({
            formData,
            subscription_type,
            eventType,
            getTzAdjustedPayload,
            pageMode,
            onboardingInitiation,
            endDateToStartDateTimeDiff,
        });
        const editAirmeetProps = {
            communityId,
            metadata,
            timeInfo,
            miscInfo,
            eventOrganiserName: formData?.eventOrganiserName,
            supportEmail: formData?.supportEmail,
            hybridEventVenue: formData?.eventVenue,
        };
        if (isLiteMeetup) {
            editAirmeetProps.sessionMeta = {
                type: formData?.sessionType,
                createRtmpKey:
                    formData?.sessionType === SESSION_TYPES.STREAMING,
                hideHost: false,
                isFluidSpaceCustomBg: false,
            };
            if (formData?.sessionType === 'PRE_RECORDED') {
                editAirmeetProps.sessionMeta = {
                    ...editAirmeetProps.sessionMeta,
                    videos: formData?.sessionMeta?.videos || [],
                };
            }
            if (formData?.duration) {
                const hours = Math.floor(formData.duration / 60);
                const minutes = formData.duration % 60;
                editAirmeetProps.timeInfo = {
                    ...(editAirmeetProps.timeInfo || {}),
                    duration: {
                        hh: parseInt(hours),
                        mm: parseInt(minutes),
                    },
                };
            }
        }
        if (miscInfo?.longDesc) {
            miscInfo.longDesc = await replaceBase64WithS3Imgs(
                miscInfo?.longDesc
            );
        }

        let hybridEventVenue = formData?.eventVenue;
        const venueCapacity =
            isString(formData?.venue_capacity) &&
            formData?.venue_capacity.trim() === ''
                ? null
                : toNumber(formData?.venue_capacity);
        if (
            !(
                isEmpty(hybridEventVenue) &&
                (venueCapacity === null || isNaN(venueCapacity))
            )
        ) {
            hybridEventVenue.venue_capacity = venueCapacity;
        }

        const response = await dispatch(
            editAirmeet(airmeetId, editAirmeetProps)
        );
        if (!response.error) {
            if (airmeet?.status !== AIRMEET_STATUS.FINISHED) {
                await updateEventEntry({
                    airmeetId,
                    isHybridEvent,
                    isInPersonEvent,
                    isEdit: true,
                    isWaitlistEnabled: formData?.isWaitlistEnabled || false,
                });
            }
            dispatch(fetchAirmeetInfo(airmeetId));
            let updatedTimeMessage;
            if (isHybridEvent || isInPersonEvent) {
                const data = {
                    community_id: communityId,
                    airmeet_id: airmeetId,
                    user_role:
                        uid === manager_id
                            ? 'Community Manager'
                            : 'Event Manager',
                    user_id: uid,
                };
                if (formData?.eventVenue?.venue_title) {
                    firebaseLogger.hybridEventFirebaseLogs(
                        {
                            ...data,
                            venue: formData?.eventVenue?.venue_title,
                        },
                        'onEnterHybridVenue'
                    );
                }
            }
            if (new Date(airmeet.start_time).getTime() !== timeInfo.startTime) {
                updatedTimeMessage =
                    'Please review schedule of session(s) as well.';
            }
            const label = getEventTypeLabel({
                eventType: airmeet?.event_type,
                eventSubType: airmeet?.event_sub_type,
            });
            successToast({
                title: `${label} details edited successfully`,
                message: !isLiteMeetup && updatedTimeMessage,
                version: TOAST_VERSION_2,
            });
            onClose();
        } else if (response.error) {
            const { code, message = '', errors } = response.payload?.json || {};
            let errorMsg = message;
            if (isMaliciousStringPresent(code, errors)) {
                errorMsg = validationErrors.RESPONSE_ERROR;
            }
            errorToast({
                title: `Could not update the ${airmeetFormat}`,
                message: errorMsg,
                version: TOAST_VERSION_2,
            });
        }
        setLoading(false);
    };
    const isFormValid = () => {
        const errorList = Object.keys(errors);
        for (let ind = 0; ind <= errorList.length; ind++) {
            if (errors[errorList[ind]]) {
                return false;
            }
        }
        return true;
    };

    const logOnboardingAnalyticsEvent = (eventType) => {
        // Allow these onboarding events to fire only once per page load
        if (
            onboardingInitiation &&
            pageMode &&
            !analyticsEventsFired.includes(eventType)
        ) {
            logAnalyticsEvent(eventType);
            setAnalyticsEventsFired(analyticsEventsFired.concat([eventType]));
        }
    };

    const handleLocationChange = (eventVenueData) => {
        const { venue_title = '' } = eventVenueData;

        if (errors.eventVenue && venue_title.length !== 0) {
            setErrors((errors) => ({ ...errors, eventVenue: '' }));
        }

        setFormData((formData) => ({
            ...formData,
            eventVenue: eventVenueData,
        }));
    };

    const [isLongDescVisible, setIsLongDescVisible] = useState(true);
    const handleAirGenieSubmit = async (response, name) => {
        setIsLongDescVisible(false);
        changeFormData({ target: { name, value: response } });
        await dispatch(closeAppModal(MODAL_TYPE_AIR_GENIE));
        setIsLongDescVisible(true);
    };

    const isPaidPlan = canShowPremiumFeatures(subscription_type, false);
    const isEventEnded = airmeet?.status === AIRMEET_STATUS.FINISHED;
    const openAirGenie =
        isPaidPlan && !isEventEnded
            ? ({ name = 'name', ...rest }) => {
                  dispatch(
                      showAppModal(MODAL_TYPE_AIR_GENIE, {
                          onSubmit: (response) =>
                              handleAirGenieSubmit(response, name),
                          airmeetId,
                          communityId,
                          entityName: editMode ? 'airmeet' : 'community',
                          eventSubType,
                          ...rest,
                      })
                  );
              }
            : null;

    const drawerTitle = editMode
        ? `Edit ${airmeetFormat}`
        : `New ${airmeetFormat}`;
    const formContentProps = {
        eventCreated,
        showSidemenu,
        editMode,
        handleEditSubmit,
        handleSubmit,
        formData,
        changeFormData,
        nameRef,
        errors,
        onboardingInitiation,
        pageMode,
        setTypeDateTime,
        setShowSideMenu,
        getMinDate,
        getMinTime,
        onStateChanged,
        typeDateTime,
        selectedOption,
        handleNameError,
        onValueChange,
        setActiveOption,
        loading,
        isFormValid,
        submitBtnId,
        logOnboardingAnalyticsEvent,
        analyticsEventType,
        timeZone,
        isFullScreen,
        airmeetId,
        eventEntryValue,
        setEventEntryValue,
        domainList,
        setDomainList,
        isEventEnded,
        isHybridEvent,
        handleLocationChange,
        fixedEventEntryType,
        openAirGenie,
        isLongDescVisible,
        airmeetFormat,
        eventType,
        isLiteMeetup,
        communityId,
        setFormData,
        setErrors,
        createForEventSeries,
        eventTypeLabel,
        isHybridEnabled,
        isInPersonEventEnabled,
        isPaidPlan,
        isInPersonEvent,
    };
    const eventLoadingStatesObj = CreateEventLoadingStates(airmeetFormat);
    return (
        <>
            {isCreateProgressPage ? (
                <EventLoader
                    currentState={currentLoadingState}
                    loadingStates={eventLoadingStatesObj}
                    logFirebase={false}
                />
            ) : pageMode || isFullScreen ? (
                <FormContent {...formContentProps} />
            ) : (
                <Drawer
                    wrapperClosable={false}
                    width='51rem'
                    title={drawerTitle}
                    onClose={onClose}
                    headerStyle={{
                        background: onboardingInitiation
                            ? colorTheme.colors.ambience[0]
                            : theme.colors.fill.default1,
                    }}
                    bodyStyle={{
                        background: onboardingInitiation
                            ? colorTheme.colors.ambience[0]
                            : theme.colors.fill.default1,
                    }}
                    showClose
                    height={
                        onboardingInitiation && isTablet
                            ? isIOS
                                ? '100%'
                                : '91vh'
                            : '100vh'
                    }
                    isThemeV2={true}
                >
                    <FormContent {...formContentProps} />
                </Drawer>
            )}
        </>
    );
};
