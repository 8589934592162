import { ATTENDEE } from 'components/meteredPricing/MeterModal/constantsV2';
import useDashboardFeatureFlags from 'hooks/community/useDashboardFeatureFlags';
import useEventDashboardFeatures from 'hooks/dashboard/useEventDashboardFeatures';
import isBoolean from 'lodash/isBoolean';
import moment from 'moment';
import React, {
    createContext,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { callApi } from 'store/api';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import firebaseLogger from 'utils/firebaseLogger';
import { logger } from 'utils/logger';
import { SHOW } from '../constants';
import getDummyData, { eventEndTime, eventStartTime } from './dummyData';
import { CUT_OFF_DATE_REAL_TIME_ANALYTICS } from './utils';

export const BASE_URL = `${process.env.REACT_APP_ANALYTICS_BASE_URL}/event-analytics/`;
export const REALTIME_BASE_URL = `${process.env.REACT_APP_ANALYTICS_BASE_URL}/real-time/`;

export const AnalyticsContext = createContext({});

export default function AnalyticsProvider({
    children,
    isOldDashboard,
    currentTab,
    isInPersonEvent = false,
}) {
    const [dataSource, setDataSource] = useState('api');
    const [isOnlyAttendees, setIsOnlyAttendees] = useState(true);

    const limitLevel = useSelector(
        (state) => state?.Community?.subscriptionData?.limit_level
    );
    const isLimitLevelAttendee = limitLevel === ATTENDEE;

    const airmeet = useSelector((state) => state?.cmDashboard?.airmeet || {});
    const {
        start_time,
        end_time,
        status,
        airmeetId,
        name,
        community_id,
        community_name,
        timezone,
        is_post_event_entry_enabled: pecaFeatureFlagEnabled,
        post_event_entry,
        event_type,
    } = airmeet || {};
    const userId = useSelector((state) => state?.auth?.user?.id);
    const {
        is_ticketing_enabled,
        booths: boothsFlag,
    } = useDashboardFeatureFlags({
        airmeet,
    });
    const sessionStoreTags =
        useSelector((state) => state?.cmDashboard?.airmeet?.session_tags) || [];
    const sessions =
        useSelector((state) => state?.cmDashboard?.airmeet?.sessions) || [];
    const { getFeatureUrl } = useEventDashboardFeatures({ isOldDashboard });
    const [lastModified, setLastModified] = useState(null);
    const [lastRefreshed, setLastRefreshed] = useState(moment());
    const [lastRefreshedTime, setLastRefreshedTime] = useState(null);
    const cachedApis = useRef({ timestamp: lastRefreshed, apis: [] });

    // Attendance
    const [attendanceLoading, setAttendanceLoading] = useState(false);
    const [attendanceData, setAttendanceData] = useState(null);

    // Ticketing
    const ticketData = useSelector((state) => state?.tickets?.ticketData) || [];
    const [ticketingApiSuccess, setTicketingApiSuccess] = useState(true);

    // Booths
    const boothsList =
        useSelector((state) => {
            return state?.booth?.boothData;
        }) || [];
    const [boothsApiSuccess, setBoothsApiSuccess] = useState(true);

    const isRealtimeAnalyticsEnabled = useMemo(
        () =>
            start_time &&
            moment(start_time).isAfter(CUT_OFF_DATE_REAL_TIME_ANALYTICS),
        [start_time]
    );

    const eventStarted = useMemo(
        () =>
            ![
                AIRMEET_STATUS.PENDING,
                AIRMEET_STATUS.FAILED,
                AIRMEET_STATUS.CREATED,
            ].includes(status),
        [status]
    );

    const isPastCutoffTime = useMemo(
        () =>
            isRealtimeAnalyticsEnabled ||
            (end_time && moment().isAfter(moment(end_time).add(1, 'days'))),
        [end_time, isRealtimeAnalyticsEnabled]
    );

    const logEvent = useCallback(
        (event, data = {}) =>
            firebaseLogger.analyticsDashboard(event, {
                airmeet_id: airmeetId,
                airmeet_name: name,
                community_id: community_id,
                community_name: community_name,
                user_id: userId,
                event_status: status,
                ...(data || {}),
            }),
        [community_id, community_name, name, userId, airmeetId, status]
    );

    const { hasTicketingData, showTicketingCard } = useMemo(() => {
        const hasTicketingData =
            is_ticketing_enabled &&
            Array.isArray(ticketData) &&
            ticketData.length > 0;
        const showTicketingCard = hasTicketingData && ticketingApiSuccess;

        return { hasTicketingData, showTicketingCard };
    }, [is_ticketing_enabled, ticketData, ticketingApiSuccess]);

    const reportNotGenerated = useMemo(
        () => !isPastCutoffTime || (!attendanceData && !attendanceLoading),
        [isPastCutoffTime, attendanceData, attendanceLoading]
    );

    const getRequest = useCallback(
        async ({ endpoint, query = {}, forceOldApi = false, invalidate }) => {
            // Invalidate cache
            if (
                lastRefreshed &&
                cachedApis.current.timestamp &&
                lastRefreshed.isAfter(cachedApis.current.timestamp)
            ) {
                cachedApis.current = {
                    timestamp: moment(lastRefreshed),
                    apis: [],
                };
            }

            const isApiCached =
                Array.isArray(cachedApis.current.apis) &&
                cachedApis.current.apis.length > 0 &&
                (cachedApis.current.apis || []).includes(endpoint);

            if (isBoolean(invalidate)) {
                query.invalidate = invalidate;
            } else {
                // Send invalidate false if it's cached
                if (isApiCached) {
                    query.invalidate = false;
                } else {
                    query.invalidate = true;
                }
            }

            if (isOnlyAttendees) {
                query.attendees = isOnlyAttendees;
            }

            if (!isApiCached) {
                cachedApis.current.apis.push(endpoint);
            }

            if (dataSource === 'dummyData') {
                const dummyData = getDummyData(endpoint, query);
                if (dummyData) return dummyData;
            }

            const { json, response } = await callApi({
                endpoint: `${
                    isRealtimeAnalyticsEnabled && !forceOldApi
                        ? REALTIME_BASE_URL
                        : BASE_URL
                }${airmeetId}/${endpoint}`,
                credentials: 'include',
                appendBaseUrl: false,
                query,
                returnResponse: true,
                lastRefreshed,
            });

            try {
                const epoch = parseInt(
                    response?.headers?.get?.('last-modified')
                );

                if (epoch && moment(epoch).isValid()) {
                    setLastModified(moment(epoch * 1000));
                }
            } catch (e) {
                logger.error(e);
            }

            try {
                const epoch = parseInt(json?.data?.last_refresh_time);

                if (epoch && moment(epoch).isValid()) {
                    const final_epoch = epoch
                        ? moment(epoch)
                              .tz(timezone)
                              .format('MMM DD, YYYY h:mm A')
                        : null;
                    setLastRefreshedTime(final_epoch);
                }
            } catch (e) {
                logger.error(e);
            }

            return json;
        },
        [
            airmeetId,
            isRealtimeAnalyticsEnabled,
            dataSource,
            lastRefreshed,
            timezone,
            isOnlyAttendees,
        ]
    );

    const { sessionOptions, sessionTags } = useMemo(() => {
        if (dataSource === 'dummyData') {
            const { data } = getRequest({ endpoint: 'session-analytics' });

            const sessionOptions = (data?.sessions || []).map(
                ({ session_id, session_name }, index) => ({
                    id: session_id,
                    label: session_name,
                    tags: Array(index + 1)
                        .fill(0)
                        .map((item, index) => `tag-${index + 1}`),
                })
            );

            const sessionTags = (data?.sessions || []).map(
                (session, index) => ({
                    id: `tag-${index + 1}`,
                    name: `Tag ${index + 1}`,
                })
            );

            return { sessionOptions, sessionTags };
        }

        const sessionOptions = [];
        (sessions || []).forEach((session) => {
            const tags = [];
            (sessionStoreTags || []).forEach((sessionTag) => {
                if (
                    (sessionTag?.session_ids || []).includes(session?.sessionid)
                ) {
                    tags.push(sessionTag?.id);
                }
            });

            sessionOptions.push({
                id: session?.sessionid,
                label: session?.name,
                tags,
            });
        });
        return { sessionOptions, sessionTags: sessionStoreTags };
    }, [sessions, sessionStoreTags, dataSource, getRequest]);

    const booths = useMemo(() => {
        if (dataSource === 'dummyData') {
            const { data } = getRequest({ endpoint: 'booth-analytics' });

            return (data?.booths || []).map((booth, index) => ({
                uid: booth?.booth_id,
                name: booth?.booth_name,
                tags: Array(index + 1)
                    .fill(0)
                    .map((item, index) => `Tag ${index + 1}`),
            }));
        }

        return boothsList || [];
    }, [dataSource, boothsList, getRequest]);

    const hasBooths = useMemo(
        () => boothsFlag === SHOW && Array.isArray(booths) && booths.length > 0,
        [boothsFlag, booths]
    );

    const showBoothsCard = useMemo(() => hasBooths && boothsApiSuccess, [
        hasBooths,
        boothsApiSuccess,
    ]);

    const { boothOptions, boothTags } = useMemo(() => {
        let tags = new Set([]);
        const options = [];

        if (hasBooths) {
            (booths || []).forEach((booth) => {
                tags = new Set([...tags, ...(booth?.tags || [])]);
                options.push({
                    id: booth?.uid,
                    label: booth?.name,
                    tags: booth?.tags,
                });
            });
        }

        return {
            boothOptions: options,
            boothTags: Array.from(tags).map((tag) => ({ id: tag, name: tag })),
        };
    }, [hasBooths, booths]);

    const { airmeetStartTime, airmeetEndTime } = useMemo(() => {
        if (dataSource === 'dummyData') {
            return {
                airmeetStartTime: eventStartTime,
                airmeetEndTime: eventEndTime,
            };
        }
        return { airmeetStartTime: start_time, airmeetEndTime: end_time };
    }, [start_time, end_time, dataSource]);

    return (
        <AnalyticsContext.Provider
            value={{
                airmeetId,
                airmeetStartTime,
                airmeetEndTime,
                timezone,
                isRealtimeAnalyticsEnabled,
                eventStarted,
                isPastCutoffTime,
                hasBooths,
                showBoothsCard,
                boothsApiSuccess,
                setBoothsApiSuccess,
                hasTicketingData,
                showTicketingCard,
                ticketingApiSuccess,
                setTicketingApiSuccess,
                logEvent,
                reportNotGenerated,
                attendanceLoading,
                setAttendanceLoading,
                attendanceData,
                setAttendanceData,
                getRequest,
                dataSource,
                setDataSource,
                sessionOptions,
                sessionTags,
                boothOptions,
                boothTags,
                pecaFeatureFlagEnabled,
                post_event_entry,
                event_type,
                lastModified,
                setLastModified,
                lastRefreshed,
                setLastRefreshed,
                isOldDashboard,
                currentTab,
                getFeatureUrl,
                lastRefreshedTime,
                setLastRefreshedTime,
                isOnlyAttendees,
                setIsOnlyAttendees,
                isLimitLevelAttendee,
                isInPersonEvent,
                communityId: community_id,
                eventStatus: status,
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
}
