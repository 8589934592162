import { Message } from '../types';
import DataObserverBase from './DataObserverBase';

class TxnStatusObserver extends DataObserverBase {
    subscribe(onData: Function, metaData: any) {
        const onAdd = (message: Message) => {
            onData(message);
        };

        metaData.txnId = metaData.txnId || '';

        const channel = `${this.airmeetId}/txn-updates/${this.userId}/${metaData?.featureName}/${metaData.txnId}`;
        const dataSourceUnsubscribe = super.subscribeImpl(channel, { onAdd });

        return () => {
            dataSourceUnsubscribe();
        };
    }
}

export default TxnStatusObserver;
