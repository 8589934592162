import { TooltipContent, TooltipHead } from 'atoms/RestrictedAccountTooltip';
import LoaderV2 from 'components/general/LoaderV2';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import RelativeToolTip from 'components/tooltip/relativeToolTip';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';
import { getCurrentTenantData } from 'utils/tenant';

const primaryColor = getCurrentTenantData('primaryColor');
const defaultColor = `hsl(${primaryColor.H},${primaryColor.S}%,${primaryColor.L}%)`;

const ToggleSwitchButton = ({
    onChange,
    background = 'rgba(157, 154, 172, 0.16)',
    activeBackground = defaultColor,
    thumbColor = '#dedde3',
    thumbActiveColor = '#dedde3',
    className = '',
    borderRadius = 34,
    toggleSize = 20,
    toggleLeft = 2,
    width = 42,
    disabled,
    loading,
    restrictedAccessTooltip,
    toggleWrapperStyle = {},
    ...rest
}) => {
    const translateX = width - (toggleLeft * 2 + toggleSize) - 2;
    const isDisabled = loading || disabled;

    return (
        <FlexDiv alignItems='center' style={toggleWrapperStyle}>
            {loading && (
                <FlexDiv alignItems='center' px={1}>
                    <LoaderV2 size={20} />
                </FlexDiv>
            )}
            <ToggleButton
                activeBG={activeBackground}
                background={background}
                thumbColor={thumbColor}
                thumbActiveColor={thumbActiveColor}
                borderRadius={borderRadius}
                toggleSize={toggleSize}
                toggleLeft={toggleLeft}
                width={width}
                translateX={translateX}
                disabled={isDisabled}
            >
                <label className={`ticket-switch toggle ${className || ''}`}>
                    <input
                        onChange={isDisabled ? noop : onChange}
                        type='checkbox'
                        disabled={isDisabled}
                        {...rest}
                    />
                    <span className='slider round'>
                        {restrictedAccessTooltip && (
                            <RelativeToolTip
                                place='bottomCenter'
                                tooltipText={<TooltipHead />}
                                renderElement={<TooltipContent />}
                                textColor='ambience.19'
                                minWidth={283}
                                svgHeight='24px'
                                svgWidth='40px'
                                portal={true}
                                tooltipContainerStyle={{
                                    transition: 'none',
                                }}
                                portalTop={45}
                                portalLeft={25}
                            />
                        )}
                    </span>
                </label>
            </ToggleButton>
        </FlexDiv>
    );
};

export default ToggleSwitchButton;

const ToggleButton = styled.div`
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
    .ticket-switch.toggle {
        position: relative;
        display: inline-block;
        width: ${({ width }) => width}px;
        height: 24px;
        margin-bottom: 0;
        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid rgba(157, 154, 172, 0.16);
            background-color: ${({ theme, background }) =>
                background
                    ? background.startsWith('#') || background.startsWith('hsl')
                        ? background
                        : get(theme, `colors.${background}`, '')
                    : ''};
            -webkit-transition: 0.4s;
            -webkit-border-radius: ${({ borderRadius }) => `${borderRadius}px`};
            -moz-border-radius: ${({ borderRadius }) => `${borderRadius}px`};
            -ms-border-radius: ${({ borderRadius }) => `${borderRadius}px`};
            border-radius: ${({ borderRadius }) => `${borderRadius}px`};
            transition: 0.4s;
            &:before {
                position: absolute;
                content: '';
                height: ${({ toggleSize }) => `${toggleSize}px`};
                width: ${({ toggleSize }) => `${toggleSize}px`};
                left: ${({ toggleLeft }) => `${toggleLeft}px`};
                top: 50%;
                -webkit-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
                background-color: ${({ thumbColor, theme }) =>
                    thumbColor
                        ? thumbColor.startsWith('#') ||
                          thumbColor.startsWith('hsl')
                            ? thumbColor
                            : get(theme, `colors.${thumbColor}`, '')
                        : ''};
                -webkit-transition: 0.4s;
                transition: 0.4s;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                border-radius: 50%;
            }
        }

        input:checked + .slider {
            border-color: ${({ activeBG, theme }) =>
                activeBG
                    ? activeBG.startsWith('#') || activeBG.startsWith('hsl')
                        ? activeBG
                        : get(theme, `colors.${activeBG}`, '')
                    : ''};
            background-color: ${({ activeBG, theme }) =>
                activeBG
                    ? activeBG.startsWith('#') || activeBG.startsWith('hsl')
                        ? activeBG
                        : get(theme, `colors.${activeBG}`, '')
                    : ''};
        }

        input:disabled + .slider {
            border-color: ${({ theme, background }) =>
                background
                    ? background.startsWith('#') || background.startsWith('hsl')
                        ? background
                        : get(theme, `colors.${background}`, '')
                    : ''} !important;
            background-color: ${({ theme, background }) =>
                background
                    ? background.startsWith('#') || background.startsWith('hsl')
                        ? background
                        : get(theme, `colors.${background}`, '')
                    : ''} !important;
            cursor: not-allowed;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px transparent;
        }

        input:checked + .slider:before {
            -webkit-transform: translate(
                ${({ translateX }) => `${translateX}px`},
                -50%
            );
            -ms-transform: translate(
                ${({ translateX }) => `${translateX}px`},
                -50%
            );
            transform: translate(
                ${({ translateX }) => `${translateX}px`},
                -50%
            );
            background-color: ${({ theme, thumbActiveColor }) =>
                thumbActiveColor
                    ? thumbActiveColor.startsWith('#') ||
                      thumbActiveColor.startsWith('hsl')
                        ? thumbActiveColor
                        : get(theme, `colors.${thumbActiveColor}`, '')
                    : ''};
        }

        &.sm {
            width: 40px;
            height: 20px;

            .slider {
                border: 0;
                &:before {
                    height: 12px;
                    width: 12px;
                    left: 4px;
                }
            }

            input:checked + .slider:before {
                -webkit-transform: translate(20px, -50%);
                -ms-transform: translate(20px, -50%);
                transform: translate(20px, -50%);
            }
        }
    }
`;
