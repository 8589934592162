import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { useLayoutEffect, useRef } from 'react';
import useDeviceInfo from './useDeviceInfo';

/**
 * Hook for disabling the body scrolls when priority elements
 * like modals, nav-menus etc are opened
 */
const useLockBodyScroll = (props) => {
    const bodyElement = useRef(document.body).current;
    const { isMobile, isTablet } = useDeviceInfo();
    const isMobileOrTablet = isMobile || isTablet;

    useLayoutEffect(() => {
        if (!isMobileOrTablet || props?.disableOnAllDevices) {
            disableBodyScroll(bodyElement);

            return () => clearAllBodyScrollLocks();
        }
    }, []);
};

export default useLockBodyScroll;
