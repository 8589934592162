import {
    BREAKOUT_ACTIONS,
    BREAKOUT_JOINING_MODES,
    BREAKOUT_STATUS,
    getBreakoutNodePrefix,
} from 'components/StageRoom/BreakoutRooms/constants';
import size from 'lodash/size';

const {
    CREATE_BREAKOUT,
    UPDATE_BREAKOUT,
    UPDATE_BREAKOUT_STATUS,
    CREATE_REQUEST,
    DELETE_REQUEST,
    ADD_SESSION_TIMER,
    UPDATE_BREAKOUT_ROOM,
    ADD_BREAKOUT_ROOMS,
    DELETE_BREAKOUT,
    ADD_ANNOUNCEMENT,
    UPDATE_ROOM_JOIN_REQUEST,
    CLEAR_BREAKOUT_PENDING_REQUESTS,
} = BREAKOUT_ACTIONS;

const { RESTRICTED } = BREAKOUT_JOINING_MODES;

const { CREATED } = BREAKOUT_STATUS;

const breakoutRoomsDataWriter = async ({
    airmeetId,
    client,
    sessionId,
    metaData,
    payload,
}) => {
    if (!airmeetId) {
        throw new Error('AirmeetId is required to create breakout rooms');
    }

    if (!sessionId) {
        throw new Error('SessionId is required to create breakout rooms');
    }

    if (!metaData?.action) {
        throw new Error('No action is specified for breakout rooms');
    }

    const breakoutsPrefix = getBreakoutNodePrefix(airmeetId, sessionId);

    switch (metaData.action) {
        case CREATE_BREAKOUT: {
            if (!payload?.breakoutId) {
                throw new Error(
                    'Breakout rooms Id is required to create breakout rooms'
                );
            }

            let configData: any = {
                title: payload?.config?.title || 'Breakout Rooms',
                status: payload?.config?.status || CREATED,
                joiningMode: payload?.config?.joiningMode || RESTRICTED,
                initialNumberOfUsers:
                    payload?.config?.initialNumberOfUsers || 7,
                totalUsers: payload?.config?.totalUsers,
                noOfRooms: payload?.config?.noOfRooms,
                duration: payload?.config?.duration || null,
                createdBy: metaData?.userId,
                createdAt: client.getServerTimestampRef(),
                startedAt: client.getServerTimestampRef(),
            };

            if (payload?.duration) {
                configData.duration = payload.duration;
            }

            await client.setData(`${breakoutsPrefix}/${payload.breakoutId}`, {
                configs: configData,
                tables: payload.tables,
                users: payload.users,
            });
            await client.setData(
                `${airmeetId}/meta-data/sessions/${sessionId}/genral/activeBreakoutId`,
                payload.breakoutId
            );

            return payload.breakoutId;
        }

        case UPDATE_BREAKOUT_STATUS: {
            if (!payload?.breakoutId) {
                throw new Error(
                    'Breakout rooms Id is required to start breakout rooms'
                );
            }

            if (payload?.status === BREAKOUT_STATUS.ONGOING) {
                return client.runTransaction(
                    `${breakoutsPrefix}/${payload?.breakoutId}/configs`,
                    (configs) => {
                        return {
                            ...configs,
                            status: payload?.status,
                            startedAt: client.getServerTimestampRef(),
                        };
                    }
                );
            }

            if (payload?.status === BREAKOUT_STATUS.FINISHED) {
                await client.setData(
                    `${airmeetId}/meta-data/sessions/${sessionId}/genral/activeBreakoutId`,
                    null
                );
            }
            return client.setData(
                `${breakoutsPrefix}/${payload?.breakoutId}/configs/status`,
                payload?.status
            );
        }

        case CREATE_REQUEST: {
            if (!payload?.breakoutId) {
                throw new Error('Breakout rooms Id is required to add request');
            }

            if (!payload?.userId) {
                throw new Error('User Id is required to add request');
            }

            return client.setData(
                `${breakoutsPrefix}/${payload?.breakoutId}/requests/${payload?.userId}`,
                {
                    timestamp: client.getServerTimestampRef(),
                }
            );
        }

        case DELETE_REQUEST: {
            if (!payload?.breakoutId) {
                throw new Error(
                    'Breakout rooms Id is required to delete request'
                );
            }

            if (!payload?.userId) {
                throw new Error('User Id is required to delete request');
            }

            return client.setData(
                `${breakoutsPrefix}/${payload?.breakoutId}/requests/${payload?.userId}`,
                null
            );
        }

        case ADD_SESSION_TIMER: {
            if (!payload?.breakoutId) {
                throw new Error('Breakout rooms Id is required to add request');
            }

            if (!payload?.endAt) {
                throw new Error('endAT is required to add session duration');
            }
            return client.runTransaction(
                `${breakoutsPrefix}/${payload?.breakoutId}/configs/endAt`,
                () => payload?.endAt
            );
        }

        case ADD_ANNOUNCEMENT: {
            if (!payload?.breakoutId) {
                throw new Error('Breakout rooms Id is required to add request');
            }

            if (!payload?.announcementAddedBy) {
                throw new Error(
                    'announcementAddedBy is required to add request'
                );
            }

            return client.runTransaction(
                `${breakoutsPrefix}/${payload?.breakoutId}/configs`,
                (configs) => ({
                    ...configs,
                    announcementText: payload?.announcementText,
                    announcementUpdateAt: client.getServerTimestampRef(),
                    announcementAddedBy: payload?.announcementAddedBy,
                })
            );
        }

        case UPDATE_BREAKOUT: {
            if (!payload?.breakoutId) {
                throw new Error(
                    'Breakout rooms Id is required to updated rooms data'
                );
            }
            return client.runTransaction(
                `${breakoutsPrefix}/${payload?.breakoutId}/configs`,
                (configs) => {
                    return {
                        ...configs,
                        ...payload,
                    };
                }
            );
        }

        case UPDATE_BREAKOUT_ROOM: {
            if (!payload?.breakoutId) {
                throw new Error(
                    'Breakout rooms Id is required to updated room data'
                );
            }
            if (!payload?.tableId) {
                throw new Error(
                    'Breakout table Id is required to updated room data'
                );
            }
            if (!payload?.tableUserMap) {
                throw new Error(
                    'Table to users mapping info is required to updated room data'
                );
            }
            if (!payload?.userTableMap) {
                throw new Error(
                    'Users to table mapping info is required to updated room data'
                );
            }
            await client.updateData(
                `${breakoutsPrefix}/${payload?.breakoutId}/tables/${payload?.tableId}/users`,
                payload.tableUserMap
            );
            await client.updateData(
                `${breakoutsPrefix}/${payload?.breakoutId}/users`,
                payload.userTableMap
            );
            return client.runTransaction(
                `${breakoutsPrefix}/${payload?.breakoutId}/configs`,
                (configs) => {
                    return {
                        ...configs,
                        totalUsers:
                            configs.totalUsers + size(payload.userTableMap),
                    };
                }
            );
        }

        case UPDATE_ROOM_JOIN_REQUEST: {
            if (!payload?.breakoutId) {
                throw new Error(
                    'Breakout rooms Id is required to updated room data'
                );
            }
            if (!payload?.userRequests) {
                throw new Error(
                    'Breakout rooms user request data is required to updated data'
                );
            }

            return client.updateData(
                `${breakoutsPrefix}/${payload?.breakoutId}/requests`,
                payload.userRequests
            );
        }

        case ADD_BREAKOUT_ROOMS: {
            if (!payload?.breakoutId) {
                throw new Error(
                    'Breakout rooms Id is required to updated room data'
                );
            }
            if (!payload?.tablesData) {
                throw new Error(
                    'Table to users mapping info is required to updated room data'
                );
            }
            if (!payload?.usersData) {
                throw new Error(
                    'Users to table mapping info is required to updated room data'
                );
            }
            await client.updateData(
                `${breakoutsPrefix}/${payload?.breakoutId}/tables`,
                payload.tablesData
            );
            await client.updateData(
                `${breakoutsPrefix}/${payload?.breakoutId}/users`,
                payload.usersData
            );
            return client.runTransaction(
                `${breakoutsPrefix}/${payload?.breakoutId}/configs`,
                (configs) => {
                    return {
                        ...configs,
                        noOfRooms: configs.noOfRooms + size(payload.tablesData),
                        totalUsers:
                            configs.totalUsers + size(payload.usersData),
                    };
                }
            );
        }

        case DELETE_BREAKOUT: {
            // Delete all breakouts
            return client.setData(`${breakoutsPrefix}`, null);
        }

        case CLEAR_BREAKOUT_PENDING_REQUESTS: {
            if (!payload?.breakoutId) {
                throw new Error(
                    'Breakout room Id is required to clear pending requests'
                );
            }

            return client.setData(
                `${breakoutsPrefix}/${payload?.breakoutId}/requests`,
                null
            );
        }

        default:
            break;
    }
};

export default breakoutRoomsDataWriter;
