import { cdnFile } from 'utils/core';

export const DEFAULT_FONT_FAMILY = 'NotoSans-Regular';

export const FONTS = [
    { id: DEFAULT_FONT_FAMILY, label: 'Noto Sans (default)' },
    { id: 'Arial', label: 'Arial' },
    {
        id: 'Comfortaa',
        label: 'Comfortaa',
        src: cdnFile('assets/fonts/Comfortaa.ttf'),
        showWarning: true,
    },
    // { id: 'Comic Sans MS', label: 'Comic Sans MS' }, not supported on mobile devices
    { id: 'Courier New', label: 'Courier New', showWarning: true },
    { id: 'Georgia', label: 'Georgia' },
    {
        id: 'Merriweather',
        label: 'Merriweather',
        src: cdnFile('assets/fonts/Merriweather.ttf'),
    },
    {
        id: 'Montserrat',
        label: 'Montserrat',
        src: cdnFile('assets/fonts/Montserrat.ttf'),
    },
    {
        id: 'Roboto Mono',
        label: 'Roboto Mono',
        src: cdnFile('assets/fonts/RobotoMono.ttf'),
        showWarning: true,
    },
    { id: 'Times New Roman', label: 'Times New Roman' },
];

export const FONTS_CDN_PATHS = {
    'NotoSans-Regular': 'assets/fonts/NotoSans-Regular.ttf',
    Arial: 'assets/fonts/Arial.ttf',
    Comfortaa: 'assets/fonts/Comfortaa.ttf',
    'Courier New': 'assets/fonts/CourierNew.ttf',
    Merriweather: 'assets/fonts/Merriweather.ttf',
    Montserrat: 'assets/fonts/Montserrat.ttf',
    'Roboto Mono': 'assets/fonts/RobotoMono.ttf',
    'Times New Roman': 'assets/fonts/TimesNewRoman.ttf',
    Georgia: 'assets/fonts/Georgia.ttf',
};
