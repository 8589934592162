import { getEventLandingBannerImgSrc } from 'utils/core';
import { EVENT_SUB_TYPES } from './constants/airmeet';
import { getCurrentTenantData } from './tenant';

export const LANDING_PAGE_AMBIENCE_OPTIONS = [
    { id: 'dark', label: 'Dark' },
    { id: 'light', label: 'Light' },
];

export const LANDING_PAGE_LAYOUT_OPTIONS = [
    {
        id: 'modern',
        title: 'modern_template',
        label: `${getCurrentTenantData('name')} Modern`,
        isDefault: true,
        hideHeroImage: true,
    },
    {
        id: 'classic',
        title: 'classic',
        label: `${getCurrentTenantData('name')} Classic`,
    },
];

export const EVENT_LANDING_PAGE_SECTIONS = [
    {
        id: 'event_details_hero_image',
        label: 'Event Details & Hero Image',
        hide: false,
        matchId: 'event_details',
    },
    {
        id: 'event_details',
        label: 'Event Details',
        hide: false,
        matchId: 'event_details',
    },
    { id: 'hero_image', label: 'Hero Image', hide: false },
    {
        id: 'overview',
        label: 'Overview',
        title: 'Overview',
        hide: false,
    },
    { id: 'schedule', label: 'Schedule', hide: false },
    { id: 'speakers', label: 'Speakers', hide: false },
    { id: 'hosts', label: 'Hosts', hide: false },
    { id: 'sponsors', label: 'Event Partners', hide: false },
];

export const EVENT_STATS_OPTIONS = [
    { id: 'total_time', label: 'Total Time', title: 'Hours' },
    { id: 'nb_speakers', label: 'Number of Speakers', title: 'Speakers' },
    { id: 'nb_sessions', label: 'Number of Sessions', title: 'Sessions' },
];

const defaultColor = getCurrentTenantData('primaryColor');
const DEFAULT_LANDING_PAGE_CONFIG = {
    layout: 'modern',
    ambience: 'dark',
    primaryColor: `hsl(${defaultColor.H},${defaultColor.S}%,${defaultColor.L}%)`,
    primaryButtonTextColor: 'hsl(0,0%,100%)',
    background: {
        type: 'pattern',
        value: 1,
    },
};

export const getDefaultLandingPageConfig = ({
    airmeet = {},
    landingPageBranding = {},
    skipExtra = false,
}) => {
    const config = {
        ...(DEFAULT_LANDING_PAGE_CONFIG || {}),
        ...(airmeet?.live_config?.landingPageBranding || {}),
        ...(landingPageBranding || {}),
    };

    if (airmeet?.event_sub_type === EVENT_SUB_TYPES.IN_PERSON_CONFERENCE) {
        if (!config.misc) {
            config.misc = {};
        }
        config.misc = { ...(config.misc || {}), hideEnterEventAsOption: true };
    }

    if (!skipExtra) {
        config.extra = {
            heroImage: getEventLandingBannerImgSrc(airmeet?.master_img_url),
            socialShareToggle: airmeet?.meta?.socialShareToggle || false,
            eventOrganiserName: airmeet?.event_organiser_name,
            supportEmail: airmeet?.support_email,
        };
    }

    return config;
};
