import _omit from 'lodash/omit';
import {
    ADD_MEETING,
    REMOVE_ALL_MEETING,
    REMOVE_MEETING,
    UPDATE_MEETING,
    UPDATE_MEETING_STATUS,
} from 'store/actions/MeetingList';

const initialState = {};

function meetingList(state = initialState, action) {
    const { payload, type } = action;
    const { meetingId, key, value } = payload || {};
    switch (type) {
        case ADD_MEETING:
            return { ...state, ...payload };
        case REMOVE_MEETING:
            return _omit(state, payload);
        case UPDATE_MEETING:
            return {
                ...state,
                [meetingId]: { ...state[meetingId], [key]: value },
            };
        case UPDATE_MEETING_STATUS:
            return {
                ...state,
                [meetingId]: {
                    ...state[meetingId],
                    recipients: {
                        ...state[meetingId].recipients,
                        [key]: { status: value },
                    },
                },
            };
        case REMOVE_ALL_MEETING:
            return payload;
        default:
            return state;
    }
}

export default meetingList;
