import Box from 'atoms/Box';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import React from 'react';
import { components } from 'react-select';
import Select from 'react-select/async';
import styled, { useTheme } from 'styled-components';

export default function SearchSelectColor({ handleChange, colorData }) {
    const theme = useTheme();
    const colorStyles = {
        singleValue: (styles) => ({
            ...styles,
            color: `${theme.colors.text.default.primary} !important`,
        }),
        valueContainer: (styles) => ({
            ...styles,
            background: 'transparent',
            color: theme.colors.text.default.primary,
        }),
        control: (styles) => ({
            ...styles,
            flex: 1,
            background: theme.colors.fill.default2,
            minHeight: '28px',
            width: '100%',
            borderRadius: theme.radii.default,
            borderColor: theme.colors.border.default1,
        }),
        menu: (styles) => ({
            ...styles,
            border: `solid 1px ${theme.colors.border.default1}`,
            background: theme.colors.fill.default1,
            color: theme.colors.text.default.primary,
            zIndex: '9',
        }),
        menuList: (styles) => ({
            ...styles,
            maxHeight: '145px',
        }),
        option: (styles) => ({
            ...styles,
            background: 'transparent',
            color: theme.colors.text.default.primary,
            ':active': {
                background: theme.colors.fill.other3,
            },
            ':hover': {
                background: theme.colors.fill.other3,
            },
        }),
        input: (styles) => ({
            ...styles,
            color: theme.colors.text.default.primary,
            fontSize: theme.fontSizes.x1,
            lineHeight: theme.lineHeights.x1,
            paddingRight: '48px',
        }),
        indicatorSeparator: (styles) => ({
            display: 'none',
        }),
        placeholder: (styles) => ({
            ...styles,
            fontSize: theme.fontSizes.x1,
            lineHeight: theme.lineHeights.x1,
            color: theme.colors.text.default.secondary,
        }),
    };

    const Option = (props) => {
        const { label, value } = props.data;

        return (
            <components.Option {...props}>
                <FlexDiv alignItems={'center'}>
                    <ColorCircle style={{ backgroundColor: `#${value}` }} />
                    <Box ml={'15px'}>
                        <span>{label}</span>
                    </Box>
                </FlexDiv>
            </components.Option>
        );
    };

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <Icon
                        icon='search'
                        fill={theme.colors.text.default.secondary}
                        height='16px'
                        width='16px'
                        aria-hidden='true'
                    />
                </components.DropdownIndicator>
            )
        );
    };

    const filterColors = (inputValue) => {
        if (inputValue) {
            return (colorData || [])
                .filter((item) =>
                    item[1].toLowerCase().includes(inputValue.toLowerCase())
                )
                .map((item) => {
                    return { label: item[1], value: item[0] };
                });
        } else {
            return (colorData || []).slice(0, 5).map((item) => {
                return { label: item[1], value: item[0] };
            });
        }
    };
    const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(filterColors(inputValue));
        }, 300);
    };

    return (
        <Select
            isMulti={false}
            isSearchable={true}
            components={{
                DropdownIndicator,
                LoadingIndicator: () => null,
                Option,
            }}
            onChange={handleChange}
            styles={colorStyles}
            loadOptions={loadOptions}
            defaultOptions
            placeholder={'Search by name'}
            cacheOptions
        />
    );
}

const ColorCircle = styled(Box)`
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    display: inline-block;
`;
