export const UPDATE_RECORDED_VIDEO_PARAM = 'UPDATE_RECORDED_VIDEO_PARAM';
export const UPDATE_RECORDED_VIDEO_INFO_PARAM =
    'UPDATE_RECORDED_VIDEO_INFO_PARAM';
export const UPDATE_RECORDED_VIDEO_CURRENT_TIME =
    'UPDATE_RECORDED_VIDEO_CURRENT_TIME';

export const updateRecordedVideoParam = (payload) => {
    return {
        type: UPDATE_RECORDED_VIDEO_PARAM,
        payload: payload,
    };
};

export const updateRecordedVideoInfoParam = (payload) => {
    return {
        type: UPDATE_RECORDED_VIDEO_INFO_PARAM,
        payload: payload,
    };
};

export const updateRecordedVideoCurrentTime = (payload) => {
    return {
        type: UPDATE_RECORDED_VIDEO_CURRENT_TIME,
        payload: payload,
    };
};
