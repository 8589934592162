import sortBy from 'lodash/sortBy';
import createReducer from '../../utils/createReducer';
import {
    GET_AIRMEET_TICKET_RESPONSE,
    VERIFY_TICKET_RESPONSE,
    GET_AIRMEET_COUPON_RESPONSE,
} from '../actions/ticketing';

const initialState = {
    ticketList: [],
    ticketEligibility: {},
    couponList: [],
};

const handlers = {
    [GET_AIRMEET_TICKET_RESPONSE]: (state, { payload }) => {
        const orderedTicketList = sortBy(payload?.data, 'position');
        return {
            ...state,
            ticketList: orderedTicketList,
        };
    },
    [GET_AIRMEET_COUPON_RESPONSE]: (state, { payload }) => {
        return {
            ...state,
            couponList: payload?.data,
        };
    },
    [VERIFY_TICKET_RESPONSE]: (state, { payload }) => {
        let ticketPresent = false;
        if (
            typeof payload?.isGuestUser === 'boolean' &&
            typeof payload?.userInfo?.exists === 'boolean' &&
            (payload?.isGuestUser || payload?.userInfo?.exists)
        ) {
            ticketPresent = true;
        }
        return {
            ...state,
            ticketEligibility: { ticketPresent, ...payload },
        };
    },
};

export default createReducer(initialState, handlers);
