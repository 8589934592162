import TagV2 from 'atoms/Tag/Tag';
import WithTooltip from 'components/general/WithTooltip';
import RelativeToolTip from 'components/tooltip/relativeToolTip';
import { Text } from 'foundations/themeV2/text';
import { Typography } from 'foundations/typography';
import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { emptyObject, select } from 'utils/constants/common';

const BetaTag = ({
    tooltipPosition = 'left',
    tooltipText = 'Pay-as-you-go metered pricing is in public Beta',
    faqLink = 'https://help.airmeet.com/support/solutions/articles/82000502992-manage-registration-limits-and-pay-as-you-go-for-your-plan',
    textVariant = 'button2',
    tooltipTextColor = 'ambience.21',
    lightThemeTooltipTextColor = 'ambience.3',
    textAlign = 'left',
    tooltipContainerStyle = {},
    tooltipChildren = null,
    tooltipPortal = false,
    v2 = false,
    tagContainerStyle = emptyObject,
    tagTextColor = v2 ? 'white' : 'ambience.21',
    lightThemeTagTextColor,
    svgFill,
    showStyledSvgSprite,
    isThemeV2 = false,
}) => {
    const tooltipContent = tooltipChildren || (
        <ListWrapper>
            <ListItem>
                <Typography
                    isThemeV2={isThemeV2}
                    variant={isThemeV2 ? 'h5' : 'subtext1'}
                    color={isThemeV2 ? 'text.default.primary' : 'ambience.12'}
                >
                    <Link
                        target='_blank'
                        rel='noopener noreferrer'
                        href={faqLink}
                    >
                        Read
                    </Link>{' '}
                    our FAQs
                </Typography>
            </ListItem>
            <ListItem>
                <Typography
                    isThemeV2={isThemeV2}
                    variant={isThemeV2 ? 'body2' : 'subtext1'}
                    color={isThemeV2 ? 'text.default.secondary' : 'ambience.12'}
                >
                    For queries, you can reach out to us at{' '}
                    <Link href='mailto:feedback@airmeet.com'>
                        feedback@airmeet.com
                    </Link>
                </Typography>
            </ListItem>
        </ListWrapper>
    );

    return isThemeV2 ? (
        <WithTooltip
            isThemeV2={isThemeV2}
            place={tooltipPosition}
            tooltip={tooltipContent}
            maxWidth={tooltipChildren ? '' : '300px'}
        >
            <TagV2 bg='sem.info' size='mini'>
                <Text color='text.other.primary' variant='caption10-bold'>
                    BETA
                </Text>
            </TagV2>
        </WithTooltip>
    ) : (
        <Tag
            ml={1}
            v2={v2}
            tooltipPosition={tooltipPosition}
            svgFill={svgFill}
            {...tagContainerStyle}
        >
            <Typography
                variant='caption'
                color={tagTextColor}
                lightThemeColor={lightThemeTagTextColor}
            >
                BETA
            </Typography>
            {tooltipText ? (
                <RelativeToolTip
                    place={tooltipPosition}
                    svgWidth='10px'
                    svgHeight='10px'
                    svgIcon='icon-fill-info'
                    tooltipText={tooltipText}
                    textColor={tooltipTextColor}
                    lightThemeTextColor={lightThemeTooltipTextColor}
                    textVariant={textVariant}
                    textAlign={textAlign}
                    minWidth={290}
                    renderElement={tooltipContent}
                    tooltipContainerStyle={tooltipContainerStyle}
                    portal={tooltipPortal}
                    showStyledSvgSprite={showStyledSvgSprite}
                />
            ) : null}
        </Tag>
    );
};

const Tag = styled.span`
    ${space}
    background: ${({ theme, v2 }) =>
        v2 ? theme.colors.accentSky[3] : theme.colors.semantic[0]};
    border-radius: ${({ v2 }) => (v2 ? '16px' : '4px')};
    width: 56px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 11px;
    cursor: pointer;
    position: relative;
    &:hover {
        .tooltip-text {
            opacity: 1;
            visibility: visible;
        }
    }
    svg {
        fill: ${({ theme, v2, svgFill }) =>
            svgFill
                ? select(svgFill)(theme.colors)
                : v2
                ? theme.colors.white
                : theme.colors.ambience[24]};
        margin-left: ${({ tooltipPosition }) =>
            tooltipPosition !== 'left' && '6px'};
    }
    
`;

const ListWrapper = styled.ul`
    margin: 0;
    list-style: none;
    padding: 8px 0 0 0 !important;
    text-align: left;
`;

const ListItem = styled.li`
    position: relative;
    padding: 0 0 0 8px !important;
    p {
        line-height: 24px;
        &:before {
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background: ${({ theme }) => theme.colors.ambience[4]};
            display: inline-block;
            margin-right: 5px;
            margin-bottom: 1px;
        }
    }
`;

const Link = styled.a`
    outline: 0 !important;
    text-decoration: none !important;
    color: ${({ theme }) => theme.colors.accentPrimary[1]};
`;

export default BetaTag;
