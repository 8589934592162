import { PREF_FILTER } from './constants/deviceSettings';

class FilterStorageManager {
    static instance: FilterStorageManager = null;
    static airmeetId: string = '';

    airmeetId: string;

    constructor(airmeetId: string) {
        this.airmeetId = airmeetId;
    }

    static setAirmeetId(airmeetId: string) {
        return (this.airmeetId = airmeetId);
    }

    static getInstance() {
        if (!this.instance || this.instance.airmeetId !== this.airmeetId) {
            this.instance = new FilterStorageManager(this.airmeetId);
        }
        return this.instance;
    }

    setFilterInfo(val: string) {
        localStorage.setItem(`${PREF_FILTER}-${this.airmeetId}`, val);
    }

    getFilterInfo() {
        return localStorage.getItem(`${PREF_FILTER}-${this.airmeetId}`);
    }
    getParsedFilterInfo() {
        return JSON.parse(this.getFilterInfo());
    }

    removeFilterInfo() {
        localStorage.removeItem(`${PREF_FILTER}-${this.airmeetId}`);
    }

    removeAllFilterInfo() {
        Object.keys(localStorage).forEach((storageKey) => {
            if (storageKey.startsWith(`${PREF_FILTER}-`)) {
                localStorage.removeItem(storageKey);
            }
        });
    }
}

export default FilterStorageManager;
