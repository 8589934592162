import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import get from 'lodash/get';
import noop from 'lodash/noop';
import useLocalSessionTimer from '../useLocalSessionTimer';

const getStartTimeCallback = (value) => (value ? value.lastStartAt : null);
function useSessionStartTimer({
    airmeetId,
    sessionId,
    onStart = noop,
    onCompleted = noop,
    onUpdate = noop,
    duration = 15,
}) {
    const liveStatusKey = `${airmeetId}/meta-data/sessions/${sessionId}/liveStatus`;
    return useLocalSessionTimer({
        key: liveStatusKey,
        onStart,
        onCompleted,
        onUpdate,
        duration,
        getStartTimeCallback,
    });
}

export const useCurrentSessionStartTimer = () => {
    const { sessionId, airmeetId } = useLiveAirmeetContext((ctx) => ({
        sessionId: get(ctx, 'airmeet.data.currentSession.sessionid', ''),
        airmeetId: get(ctx, 'airmeet.airmeetId', ''),
    }));
    return useSessionStartTimer({
        airmeetId,
        sessionId,
    });
};

export default useSessionStartTimer;
