import { EventEmitter } from 'events';

export default class FirebaseEmitter extends EventEmitter {
    private static emitter: EventEmitter;

    static newInstance(): FirebaseEmitter {
        if (FirebaseEmitter.emitter) {
            return FirebaseEmitter.emitter;
        }
        FirebaseEmitter.emitter = new EventEmitter();
        return FirebaseEmitter.emitter;
    }

    static getInstance(): FirebaseEmitter {
        if (FirebaseEmitter.emitter) {
            return FirebaseEmitter.emitter;
        }
        throw new Error('Call before set instance');
    }
}
