import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';

const { DM_CHAT } = FEATURE_NAMES;

const dmChatWriter = async ({
    airmeetId,
    client,
    payload,
    metaData,
    logger,
}) => {
    if (!metaData.roomId) {
        throw new Error('Invalid DM Chat firebase key');
    }

    if (metaData.action === FEATURE_ACTIONS[DM_CHAT].BLOCK_DM) {
        logger.info('Blocking DM Chat');
        let atomicUpdates = {};

        atomicUpdates[
            `${airmeetId}/chats/Private/rooms/${metaData.roomId}/acceptedBy`
        ] = payload.acceptedBy;

        atomicUpdates[
            `${airmeetId}/chats/Private/rooms/${metaData.roomId}/declinedBy`
        ] = payload.declinedBy;

        return await client.atomicUpdate(atomicUpdates);
    }

    if (metaData.action === FEATURE_ACTIONS[DM_CHAT].ACCEPT_DM) {
        logger.info('Accepting DM Chat');
        let atomicUpdates = {};

        atomicUpdates[
            `${airmeetId}/chats/Private/rooms/${metaData.roomId}/acceptedBy`
        ] = payload.acceptedBy;

        atomicUpdates[
            `${airmeetId}/chats/Private/rooms/${metaData.roomId}/declinedBy`
        ] = payload.declinedBy;

        return await client.atomicUpdate(atomicUpdates);
    }

    if (metaData.action === FEATURE_ACTIONS[DM_CHAT].SEND_CHAT) {
        logger.info('Sending DM Chat');
        const fireBaseKey = `/${airmeetId}/chats/Private/messages/${metaData.roomId}`;
        return client.pushData(fireBaseKey, payload);
    }

    if (metaData.action === FEATURE_ACTIONS[DM_CHAT].CREATE_ROOM) {
        let atomicUpdates = {};
        atomicUpdates[
            `/${airmeetId}/chats/Private/rooms/${metaData.roomId}`
        ] = payload;
        atomicUpdates[
            `${airmeetId}/chats/Private/users/${payload.createdBy}/rooms/${metaData.roomId}`
        ] = { roomId: metaData.roomId, lastViewedAt: 0 };

        atomicUpdates[
            `${airmeetId}/chats/Private/users/${metaData.receiverId}/rooms/${metaData.roomId}`
        ] = { roomId: metaData.roomId, lastViewedAt: 0 };

        return await client.atomicUpdate(atomicUpdates);
    }

    if (metaData.action === FEATURE_ACTIONS[DM_CHAT].UPDATE_LAST_VIEWED) {
        logger.info('Updating last viewed');
        const fireBaseKey = `/${airmeetId}/chats/Private/users/${payload.userId}/rooms/${metaData.roomId}/lastViewedAt`;
        return await client.setData(
            fireBaseKey,
            client.getServerTimestampRef()
        );
    }
};

export default dmChatWriter;
