import { EVENT_SESSION_TYPE_OPTIONS } from 'components/community/CreateAirmeetForm/CreateAirmeetV2/utils';
import { ENTERPRISE } from 'components/community/billing-plans/data';
import { getAnalyticsTabs } from 'components/community/editPublicAirmeet/Analytics/TabContent';
import { CUT_OFF_DATE_REAL_TIME_ANALYTICS } from 'components/community/editPublicAirmeet/Analytics/utils';
import { CHAR_LIMIT_MAP } from 'components/community/editPublicAirmeet/Sessions/SessionForm/utils/validationV2';
import {
    ATTRACT,
    CUT_OFF_DATE_AUDIENCE,
    CUT_OFF_DATE_LANDING_PAGE_NEW_TAG,
    CUT_OFF_DATE_RECORDINGS,
    HIDE,
    SHOW_RECORDINGS_FOR_ALL_AIRMEETS,
} from 'components/community/editPublicAirmeet/constants';
import {
    ACCESSIBILITY_TAB,
    ACCESS_RULES_TAB,
    ADVANCE_EVENT_SPACE_SETTINGS,
    ADVANCE_SESSION_SETTINGS,
    ANALYTICS_REPORTS_DOWNLOADS_TAB,
    ANALYTICS_REPORTS_LIBRARY_TAB,
    ANALYTICS_TAB,
    ATTENDEE_ENGAGEMENT_TAB,
    AUDIENCE_SEGMENTS_TAB,
    AUDIENCE_TAB,
    AUDIENCE_USERS_TAB,
    AUTOMATED_WORKFLOWS_TAB,
    BOOTHS_TAB,
    BRANDING_TAB,
    CLIPS_TAB,
    CLOSED_CAPTIONS_TAB,
    EMAILS_TAB,
    EVENT_BRANDING_TAB,
    EVENT_ENTRY_TAB,
    EVENT_SPACES_TAB,
    EVENT_SPACE_SETTINGS_TAB,
    EXHIBITORS_TAB,
    HOSTS_COHOSTS_TAB,
    HOSTS_TAB,
    INTEGRATIONS_TAB,
    INTERESTS_TAB,
    LANDING_PAGE_TAB,
    LEADERBOARD_TAB,
    LIVE_STREAM_TAB,
    LOUNGE_TAB,
    PARTICIPANTS_TAB,
    PEOPLE_TAB,
    POST_EVENT_ENTRY_TAB,
    PRE_EVENT_ENTRY_TAB,
    PRE_RECORDED_VIDEO_TAB,
    RECEPTION_TAB,
    RECORDINGS_TAB,
    REGISTRATIONS_TAB,
    REG_FORM_TAB,
    RESOURCES_TAB,
    SCHEDULE_TAB,
    SESSIONS_TAB,
    SESSION_RESOURCES_TAB,
    SESSION_SETTINGS_TAB,
    SETTINGS_TAB,
    SPEAKERS_TAB,
    SPONSORS_TAB,
    STAGE_TAB,
    STREAMING_RESOLUTION,
    STREAM_INTO_AIRMEET_TAB,
    SUMMARY_TAB,
    TICKETS_TAB,
    TRACKING_URL_TAB,
    TRANSCRIPTS_TAB,
    VENUE_SETTINGS_TAB,
    WORKFLOWS_TAB,
} from 'components/community/editPublicAirmeet/constants/tabs';
import { RESOLUTIONS_1080P } from 'containers/StageResolutionsList';
import {
    CONFERENCE_STARTER_PLAN,
    ENTERPRISE_ATTENDEE,
    PRO_ATTENDEE,
    STARTER_ATTENDEE,
} from 'hooks/metered-pricing/config';
import upperFirst from 'lodash/upperFirst';
import moment from 'moment';
import { lazy } from 'react';
import { getAirmeetFormat } from 'utils/airmeet';
import {
    AIRMEET_STATUS,
    EVENT_SUB_TYPES,
    EVENT_TYPES,
} from 'utils/constants/airmeet';
import { canShowPremiumFeatures } from 'utils/core';
import { getCurrentTenantData } from 'utils/tenant';

const Summary = lazy(() =>
    import('components/community/editPublicAirmeet/Summary')
);
const Sessions = lazy(() =>
    import('components/community/editPublicAirmeet/Sessions')
);
const Speakers = lazy(() =>
    import('components/community/editPublicAirmeet/Speakers')
);
const EventEntry = lazy(() =>
    import('components/community/editPublicAirmeet/eventEntry/EventEntry.js')
);
const Tickets = lazy(() =>
    import('components/community/editPublicAirmeet/Tickets')
);
const MeetupBranding = lazy(() =>
    import(
        'components/community/editPublicAirmeet/VenueSettings/MeetupBranding'
    )
);
const Stage = lazy(() =>
    import('components/community/editPublicAirmeet/Stage')
);
const Lounge = lazy(() =>
    import('components/community/editPublicAirmeet/Lounge')
);
const Booths = lazy(() =>
    import('components/community/editPublicAirmeet/Booths')
);
const Analytics = lazy(() =>
    import('components/community/editPublicAirmeet/Analytics')
);
const Audience = lazy(() =>
    import('components/community/editPublicAirmeet/Audience')
);
const ReportsLibrary = lazy(() =>
    import('components/community/editPublicAirmeet/Reports/ReportsLibrary')
);
const ReportsDownloads = lazy(() =>
    import('components/community/editPublicAirmeet/Reports/ReportsDownloads')
);
const Integrations = lazy(() =>
    import('components/community/editPublicAirmeet/Integrations')
);
const ManageRecordings = lazy(() =>
    import('components/community/editPublicAirmeet/ManageRecordings')
);
const Transcripts = lazy(() =>
    import('components/community/editPublicAirmeet/Transcripts')
);
const StreamingResolution = lazy(() =>
    import('components/community/editPublicAirmeet/StreamingResolution')
);
const LiveStream = lazy(() =>
    import('components/community/editPublicAirmeet/LiveStream')
);
const LiveStreamV2 = lazy(() =>
    import('components/community/editPublicAirmeet/LiveStreamV2')
);
const PreRecordedVideo = lazy(() =>
    import('components/community/editPublicAirmeet/PreRecordedVideo')
);
const Sponsors = lazy(() =>
    import('components/community/editPublicAirmeet/Sponsors')
);
const VenueSettings = lazy(() =>
    import('components/community/editPublicAirmeet/VenueSettings')
);
const AttendeeEngagement = lazy(() =>
    import('components/community/editPublicAirmeet/AttendeeEngagement')
);
const EmailManagement = lazy(() =>
    import('components/community/editPublicAirmeet/EmailManagement')
);
const AirmeetStreamKey = lazy(() =>
    import('components/community/editPublicAirmeet/AirmeetStreamKey')
);
const Settings = lazy(() =>
    import('components/community/editPublicAirmeet/Settings')
);
const LandingPage = lazy(() =>
    import('components/community/editPublicAirmeet/LandingPage')
);
const Resources = lazy(() =>
    import('components/molecules/summit/center/resources')
);
const AutomatedWorkflows = lazy(() =>
    import('components/community/editPublicAirmeet/AutomatedWorkflows')
);
const ClosedCaptions = lazy(() =>
    import('components/community/editPublicAirmeet/ClosedCaptions')
);
const AdvanceSessionSettings = lazy(() =>
    import('components/community/editPublicAirmeet/AdvanceSessionSettings')
);
const HostCohosts = lazy(() =>
    import('components/community/editPublicAirmeet/HostCohosts')
);
const InterprefyField = lazy(() =>
    import(
        'components/community/editPublicAirmeet/Sessions/SessionForm/SessionActivity/CustomFormComponents/InterprefyField'
    )
);

const Clips = lazy(() =>
    import('components/community/editPublicAirmeet/Clips')
);

export const DEFAULT_DASHBOARD_TAB_ID = 'summary';

/**
 * Builds the event dashboard navigation tree along with the respective dashboard modules.
 */
export const getDashboardTabs = ({
    airmeet,
    hasContactSalesClicked,
    venue_settings,
    is_custom_reception_enabled,
    is_venue_settings_logo_enabled,
    allowed_max_supported_streaming_resolution,
    firebaseClient,
    tableFirebaseClient,
    booths,
    is_free_plan_user_in_meetup,
    sponsors,
    isLeaderboardEnabled,
    isCustomInterestsEnabled,
    branding,
    is_ticketing_enabled,
    is_custom_email_template_enabled_for_community,
    is_custom_email_enabled_for_airmeet,
    is_analytics_v2_enabled,
    subscription_type,
    sessionsIncomplete,
    isSessionHostEmpty,
    permissionMappingToTabs,
    isHybridEvent,
    isNewLandingPage,
    isResourceManagementEnabled,
    isAppSumoUser = false,
    isAutomatedWorkflowEnabled = null,
    isUnifiedProfilesEnabled = false,
    closedCaptionsFlag,
    participantsTabEnhanced = false,
    isInPersonEvent = false,
    isWaitlistFeatureAllowed = false,
    is_video_editor_enabled = false,
    isFreeTrialPlanDeactivated,
    userEmail = '',
}) => {
    const {
        airmeetId,
        start_time,
        status,
        is_trial,
        event_type,
        is_pre_event_enabled,
        is_post_event_entry_enabled,
        event_series_id,
        event_sub_type,
        sessions,
    } = airmeet || {};
    const showPreEventTab =
        event_type !== EVENT_TYPES.MEETUP &&
        is_pre_event_enabled &&
        !isInPersonEvent;
    const showPostEventTab = is_post_event_entry_enabled && !isInPersonEvent;
    const isLiteMeetup =
        event_type === EVENT_TYPES.MEETUP &&
        event_sub_type === EVENT_SUB_TYPES.LITE;
    const getSessionTypeLabel = () => {
        let sessionTypeLabel = '';
        if (isLiteMeetup && Array.isArray(sessions) && sessions.length > 0) {
            const session = sessions[0];
            sessionTypeLabel = EVENT_SESSION_TYPE_OPTIONS.find(
                (item) => item?.value === session?.type
            )?.label;
        }
        return sessionTypeLabel;
    };
    const isEventEnded = airmeet?.status === AIRMEET_STATUS.FINISHED;
    const sessionTypeLabel = getSessionTypeLabel();
    const hideSpeakersTab =
        isLiteMeetup && sessionTypeLabel === 'Session as a video call';
    const hideVideosTab =
        isLiteMeetup && sessionTypeLabel !== 'Live broadcast session';
    const hideLiveStreamTab =
        isLiteMeetup &&
        (sessionTypeLabel === 'Session as a video call' ||
            sessionTypeLabel === 'Stream into Airmeet');
    const commonProps = {
        airmeetId,
        airmeet,
        communityId: airmeet?.community_id,
        isShortEvent: event_type !== EVENT_TYPES.CONFERENCE,
        isLiteMeetup,
        isInPersonEvent,
        isHybridEvent,
    };

    const eventStarted = ![
        AIRMEET_STATUS.PENDING,
        AIRMEET_STATUS.FAILED,
        AIRMEET_STATUS.CREATED,
    ].includes(status);

    const isRealtimeAnalyticsEnabled =
        start_time &&
        moment(start_time).isAfter(CUT_OFF_DATE_REAL_TIME_ANALYTICS);
    const isPaidPlan = canShowPremiumFeatures(subscription_type, false);
    const premiumFeaturesVisible = canShowPremiumFeatures(
        subscription_type,
        is_trial
    );
    const isConferenceStarter = subscription_type === CONFERENCE_STARTER_PLAN;
    const freeOrConfStarterPlan = !isPaidPlan || isConferenceStarter;

    const analyticsTabs = getAnalyticsTabs(
        eventStarted,
        start_time,
        !(isRealtimeAnalyticsEnabled && is_analytics_v2_enabled),
        isPaidPlan,
        isInPersonEvent,
        isFreeTrialPlanDeactivated,
        userEmail
    );

    const isRecordingsHidden =
        !(
            process.env.REACT_APP_HIDE_RECORDINGS !== 'true' &&
            (SHOW_RECORDINGS_FOR_ALL_AIRMEETS ||
                (!!start_time &&
                    moment(CUT_OFF_DATE_RECORDINGS).diff(
                        moment(start_time),
                        'days'
                    ) <= 0))
        ) || isInPersonEvent;
    const airmeetFormat = getAirmeetFormat({
        eventType: event_type,
    });
    const is1080PStreamingAllowed =
        allowed_max_supported_streaming_resolution === RESOLUTIONS_1080P;

    const isBoothsHidden =
        event_type === EVENT_TYPES.MEETUP ||
        booths === HIDE ||
        is_free_plan_user_in_meetup ||
        isInPersonEvent;
    const leaderBoardTab = {
        id: LEADERBOARD_TAB,
        title: 'Leaderboard',
        component: AttendeeEngagement,
        componentProps: {
            ...commonProps,
            sponsorsFlag: sponsors,
            isLeaderboardEnabled,
            isCustomInterestsEnabled,
            currentTab: {
                id: LEADERBOARD_TAB,
                title: 'Leaderboard',
            },
            airmeetFormat,
        },
        isHidden: !isLeaderboardEnabled || isInPersonEvent,
        isHashRoute: true,
        visible: permissionMappingToTabs[LEADERBOARD_TAB].visible,
        clickable: permissionMappingToTabs[LEADERBOARD_TAB].clickable,
        viewable: permissionMappingToTabs[LEADERBOARD_TAB].viewable,
    };
    const resourceTab = {
        id: RESOURCES_TAB,
        title: 'Resources',
        component: Resources,
        componentProps: {
            ...commonProps,
            isDashboard: true,
            isLiteMeetup,
        },
        isHidden: !isResourceManagementEnabled || isInPersonEvent,
    };

    const ccFeatureEnabled = closedCaptionsFlag === 'SHOW';
    const closedCaptionTab = {
        id: CLOSED_CAPTIONS_TAB,
        title: 'Closed Captions',
        component: ClosedCaptions,
        componentProps: {
            ...commonProps,
            title: 'Closed Captions',
            info:
                'This will allow attendees to watch closed captions for the session they are watching.',
        },
        isHashRoute: true,
        isHiddenNav: false,
        isHidden: !ccFeatureEnabled,
        visible: permissionMappingToTabs[SETTINGS_TAB].visible,
        clickable: permissionMappingToTabs[SETTINGS_TAB],
    };

    const isTranscriptsEnabled =
        ccFeatureEnabled &&
        (!isLiteMeetup ||
            (isLiteMeetup && sessionTypeLabel === 'Live broadcast session'));

    const tabs = [
        {
            id: SUMMARY_TAB,
            title: 'Summary',
            component: Summary,
            componentProps: {
                ...commonProps,
                airmeetFormat,
                hideSpeakerUser: hideSpeakersTab,
            },

            visible: permissionMappingToTabs[SUMMARY_TAB].visible,
            clickable: permissionMappingToTabs[SUMMARY_TAB].clickable,
            viewable: permissionMappingToTabs[SUMMARY_TAB].viewable,
        },
        {
            id: REGISTRATIONS_TAB,
            title: 'Registration',
            tabs: [
                {
                    id: EVENT_ENTRY_TAB,
                    title: isInPersonEvent
                        ? 'Registration Access'
                        : `${upperFirst(airmeetFormat)} Access`,
                    component: EventEntry,
                    componentProps: {
                        ...commonProps,
                        airmeetFormat,
                        hasContactSalesClicked,
                        currentTab: {
                            id: EVENT_ENTRY_TAB,
                            title: isInPersonEvent
                                ? 'Registration Access'
                                : `${upperFirst(airmeetFormat)} Access`,
                        },
                        isPaidPlan,
                    },
                    visible: permissionMappingToTabs[ACCESS_RULES_TAB].visible,
                    clickable:
                        permissionMappingToTabs[ACCESS_RULES_TAB].clickable,
                    viewable:
                        permissionMappingToTabs[ACCESS_RULES_TAB].viewable,
                    tabs: [
                        {
                            id: PRE_EVENT_ENTRY_TAB,
                            title: `Pre-${airmeetFormat} Entry`,
                            component: 'placeholder',
                            componentProps: {
                                ...commonProps,
                            },
                            isHidden: !showPreEventTab,
                            isHashRoute: true,
                            isHiddenNav: true,
                        },
                        {
                            id: POST_EVENT_ENTRY_TAB,
                            title: `${upperFirst(airmeetFormat)} Replay`,
                            component: 'placeholder',
                            componentProps: {
                                ...commonProps,
                            },
                            isHidden: !showPostEventTab,
                            isHashRoute: true,
                            isHiddenNav: true,
                        },
                        {
                            id: TRACKING_URL_TAB,
                            title: 'Tracking URL',
                            component: 'placeholder',
                            componentProps: {
                                ...commonProps,
                            },
                            isPremium: !isPaidPlan,
                            isHashRoute: true,
                            isHiddenNav: true,
                        },
                    ],
                },
                {
                    id: REG_FORM_TAB,
                    title: 'Registration Form',
                    component: EventEntry,
                    componentProps: {
                        ...commonProps,
                        hasContactSalesClicked,
                        currentTab: {
                            id: REG_FORM_TAB,
                            title: 'Registration Form',
                        },
                        hideHeader: true,
                        airmeetFormat,
                    },
                    visible: permissionMappingToTabs[ACCESS_RULES_TAB].visible,
                    clickable:
                        permissionMappingToTabs[ACCESS_RULES_TAB].clickable,
                    viewable:
                        permissionMappingToTabs[ACCESS_RULES_TAB].viewable,
                },
                {
                    id: TICKETS_TAB,
                    title: 'Ticketing',
                    component: Tickets,
                    componentProps: {
                        ...commonProps,
                        airmeetFormat,
                    },
                    isHidden:
                        !is_ticketing_enabled ||
                        event_series_id ||
                        isInPersonEvent, //is undefined until feature flag is updated
                    visible: permissionMappingToTabs[TICKETS_TAB].visible,
                    clickable: permissionMappingToTabs[TICKETS_TAB].clickable,
                    viewable: permissionMappingToTabs[TICKETS_TAB].viewable,
                },
            ],
            visible: permissionMappingToTabs[REGISTRATIONS_TAB].visible,
            clickable: permissionMappingToTabs[REGISTRATIONS_TAB].clickable,
            viewable:
                permissionMappingToTabs[TICKETS_TAB].viewable ||
                permissionMappingToTabs[ACCESS_RULES_TAB].viewable,
        },
        {
            id: PEOPLE_TAB,
            title: 'People',
            tabs: [
                {
                    id: PARTICIPANTS_TAB,
                    title: participantsTabEnhanced
                        ? 'Attendees'
                        : 'Participants',
                    component: EventEntry,
                    componentProps: {
                        ...commonProps,
                        hasContactSalesClicked,
                        participantsTabEnhanced,
                        currentTab: {
                            id: PARTICIPANTS_TAB,
                            title: participantsTabEnhanced
                                ? 'Attendees'
                                : 'Participants',
                            subtitle: participantsTabEnhanced
                                ? 'Manage your attendees from here'
                                : 'Manage your attendees & speakers from here',
                        },
                        airmeetFormat,
                        userType: participantsTabEnhanced ? 'ATTENDEE' : null,
                    },
                    visible: permissionMappingToTabs[ACCESS_RULES_TAB].visible,
                    clickable:
                        permissionMappingToTabs[ACCESS_RULES_TAB].clickable,
                    viewable:
                        permissionMappingToTabs[ACCESS_RULES_TAB].viewable,
                },
                {
                    id: SPEAKERS_TAB,
                    title: 'Speakers',
                    component: Speakers,
                    componentProps: {
                        ...commonProps,
                        userType: 'SPEAKER',
                        firebaseClient,
                        title: 'Speakers',
                        airmeetFormat,
                        subtitle: `Manage your list of ${airmeetFormat} speakers from one place`,
                        isEventEnded,
                    },
                    isHidden: hideSpeakersTab,
                    visible: permissionMappingToTabs[SPEAKERS_TAB].visible,
                    clickable: permissionMappingToTabs[SPEAKERS_TAB].clickable,
                    viewable:
                        permissionMappingToTabs[SPEAKERS_TAB].viewable &&
                        permissionMappingToTabs[SCHEDULE_TAB].viewable,
                },
                {
                    id: EXHIBITORS_TAB,
                    title: 'Exhibitors',
                    component: EventEntry,
                    componentProps: {
                        ...commonProps,
                        hasContactSalesClicked,
                        currentTab: {
                            id: PARTICIPANTS_TAB,
                            title: 'Exhibitors',
                            subtitle: `Manage your list of ${airmeetFormat} exhibitor. You can add the exhibitors from the booths form.`,
                        },
                        userType: 'EXHIBITOR',
                        airmeetFormat,
                    },
                    isHidden: !participantsTabEnhanced || isBoothsHidden,
                    visible: permissionMappingToTabs[ACCESS_RULES_TAB].visible,
                    clickable:
                        permissionMappingToTabs[ACCESS_RULES_TAB].clickable,
                    viewable:
                        permissionMappingToTabs[ACCESS_RULES_TAB].viewable,
                },
                {
                    id: isLiteMeetup ? HOSTS_COHOSTS_TAB : HOSTS_TAB,
                    title: isLiteMeetup ? 'Host & Co-hosts' : 'Hosts',
                    component: HostCohosts,
                    componentProps: {
                        ...commonProps,
                        userType: isLiteMeetup ? 'HOST & COHOSTS' : 'HOST',
                        header: 'Host & Co-hosts',
                        hostTitle: 'Hosts',
                        hostSubtitle: `Manage your list of ${airmeetFormat} hosts. ${
                            isLiteMeetup
                                ? ''
                                : 'You may map the hosts to the session from the list of team members at community.'
                        }`,
                        userTypeLabel: isLiteMeetup ? 'cohost' : 'host',
                        cohostTitle: 'Co-hosts',
                        cohostSubtitle: `Manage your list of ${airmeetFormat} co-hosts. ${
                            isLiteMeetup
                                ? ''
                                : 'You may map the co-hosts to the session from the list of team members at community.'
                        }`,
                        airmeetFormat,
                        isEventEnded,
                    },
                    visible: permissionMappingToTabs[SPEAKERS_TAB].visible,
                    clickable: permissionMappingToTabs[SPEAKERS_TAB].clickable,
                    viewable:
                        permissionMappingToTabs[SPEAKERS_TAB].viewable &&
                        permissionMappingToTabs[SCHEDULE_TAB].viewable,
                },
                {
                    id: INTERESTS_TAB,
                    title: 'Attendee Interests',
                    component: AttendeeEngagement,
                    componentProps: {
                        ...commonProps,
                        sponsorsFlag: sponsors,
                        isLeaderboardEnabled,
                        isCustomInterestsEnabled,
                        airmeetFormat,
                        currentTab: {
                            id: INTERESTS_TAB,
                            title: 'Attendee Interests',
                        },
                    },
                    isHidden: !isCustomInterestsEnabled || isInPersonEvent,
                    visible:
                        permissionMappingToTabs[ATTENDEE_ENGAGEMENT_TAB]
                            .visible &&
                        permissionMappingToTabs[ATTENDEE_ENGAGEMENT_TAB]
                            .viewable,
                    clickable:
                        permissionMappingToTabs[ATTENDEE_ENGAGEMENT_TAB]
                            .clickable,
                    viewable:
                        permissionMappingToTabs[ATTENDEE_ENGAGEMENT_TAB]
                            .viewable,
                },
            ],
            visible: permissionMappingToTabs[ACCESS_RULES_TAB].visible,
            clickable: permissionMappingToTabs[ACCESS_RULES_TAB].clickable,
            viewable: permissionMappingToTabs[ACCESS_RULES_TAB].viewable,
        },
        {
            id: SCHEDULE_TAB,
            title: isLiteMeetup ? 'Session' : 'Sessions',
            tabs: [
                {
                    id: SESSIONS_TAB,
                    title: 'Session List',
                    component: Sessions,
                    componentProps: {
                        ...commonProps,
                        sessionsIncomplete,
                        isSessionHostEmpty,
                        firebaseClient,
                    },
                    isHidden: isLiteMeetup,
                    visible: permissionMappingToTabs[SCHEDULE_TAB].visible,
                    clickable: permissionMappingToTabs[SCHEDULE_TAB].clickable,
                    viewable: permissionMappingToTabs[SCHEDULE_TAB].viewable,
                },
                {
                    id: RECORDINGS_TAB,
                    title: isTranscriptsEnabled
                        ? 'Recordings & Transcripts'
                        : isLiteMeetup
                        ? 'Recording'
                        : 'Session Recordings',
                    component: ManageRecordings,
                    componentProps: {
                        ...commonProps,
                        firebaseClient,
                        isTranscriptsEnabled,
                        isLiteMeetup,
                    },
                    isHidden: isRecordingsHidden,
                    visible: permissionMappingToTabs[RECORDINGS_TAB].visible,
                    clickable:
                        permissionMappingToTabs[RECORDINGS_TAB].clickable,
                    viewable:
                        permissionMappingToTabs[RECORDINGS_TAB].viewable &&
                        permissionMappingToTabs[SCHEDULE_TAB].viewable,
                },
                {
                    id: TRANSCRIPTS_TAB,
                    title: 'Transcript',
                    component: Transcripts,
                    componentProps: { ...commonProps, isTranscriptsEnabled },
                    isHiddenNav: true,
                    visible: permissionMappingToTabs[RECORDINGS_TAB].visible,
                    clickable:
                        permissionMappingToTabs[RECORDINGS_TAB].clickable,
                    viewable:
                        permissionMappingToTabs[RECORDINGS_TAB].viewable &&
                        permissionMappingToTabs[SCHEDULE_TAB].viewable,
                },
                {
                    id: CLIPS_TAB,
                    title: 'Clips',
                    component: Clips,
                    componentProps: { ...commonProps, firebaseClient },
                    isHidden: !is_video_editor_enabled || isInPersonEvent,
                    visible: permissionMappingToTabs[RECORDINGS_TAB].visible,
                    clickable:
                        permissionMappingToTabs[RECORDINGS_TAB].clickable,
                    viewable:
                        permissionMappingToTabs[RECORDINGS_TAB].viewable &&
                        permissionMappingToTabs[SCHEDULE_TAB].viewable,
                },
                {
                    id: SESSION_RESOURCES_TAB,
                    title: isLiteMeetup ? 'Videos' : 'Pre-recorded Videos',
                    component: PreRecordedVideo,
                    componentProps: {
                        ...commonProps,
                        needToUpgradePlan: is_free_plan_user_in_meetup,
                        airmeetFormat,
                        isLiteMeetup,
                    },
                    isPremium: is_free_plan_user_in_meetup,
                    isHidden: isInPersonEvent || hideVideosTab,
                    visible:
                        permissionMappingToTabs[PRE_RECORDED_VIDEO_TAB].visible,
                    clickable:
                        permissionMappingToTabs[PRE_RECORDED_VIDEO_TAB]
                            .clickable,
                    viewable:
                        permissionMappingToTabs[PRE_RECORDED_VIDEO_TAB]
                            .viewable &&
                        permissionMappingToTabs[SCHEDULE_TAB].viewable,
                },
                {
                    ...resourceTab,
                    isHidden: !(isResourceManagementEnabled && isLiteMeetup),
                },
                {
                    id: SESSION_SETTINGS_TAB,
                    title: isLiteMeetup ? 'Settings' : 'Session Settings',
                    component: Settings,
                    componentProps: {
                        ...commonProps,
                        firebaseClient,
                        tableFirebaseClient,
                        sourceParentTabId: SCHEDULE_TAB,
                        sourceSubTabId: SESSION_SETTINGS_TAB,
                        title: isLiteMeetup ? 'Settings' : 'Session Settings',
                        airmeetFormat,
                    },
                    isHidden: isInPersonEvent,
                    tabs: [
                        {
                            id: LIVE_STREAM_TAB,
                            title: 'Live Stream',
                            component: commonProps?.airmeet
                                ?.is_streaming_v2_enabled
                                ? LiveStreamV2
                                : LiveStream,
                            componentProps: {
                                ...commonProps,
                                ...permissionMappingToTabs[LIVE_STREAM_TAB]
                                    .componentPermissions,
                                airmeetFormat,
                                isLiteMeetup,
                            },
                            isHidden: hideLiveStreamTab,
                            isHashRoute: true,
                            searchKeywords: [
                                'stream',
                                'broadcast',
                                'youtube',
                                'facebook',
                            ],
                            visible:
                                permissionMappingToTabs[LIVE_STREAM_TAB]
                                    .visible &&
                                permissionMappingToTabs[LIVE_STREAM_TAB]
                                    .viewable,
                            clickable:
                                permissionMappingToTabs[LIVE_STREAM_TAB]
                                    .clickable,
                            viewable:
                                permissionMappingToTabs[LIVE_STREAM_TAB]
                                    .viewable,
                        },
                        {
                            id: STREAM_INTO_AIRMEET_TAB,
                            title: `Stream into ${getCurrentTenantData(
                                'name'
                            )}`,
                            component: AirmeetStreamKey,
                            componentProps: {
                                ...commonProps,
                                isLiteMeetup,
                            },
                            isHidden:
                                !premiumFeaturesVisible ||
                                (isLiteMeetup &&
                                    sessionTypeLabel !== 'Stream into Airmeet'),
                            isHashRoute: true,
                            searchKeywords: ['stream', 'broadcast'],
                            visible:
                                permissionMappingToTabs[STREAM_INTO_AIRMEET_TAB]
                                    .visible,
                            clickable:
                                permissionMappingToTabs[STREAM_INTO_AIRMEET_TAB]
                                    .visible,
                            viewable:
                                permissionMappingToTabs[STREAM_INTO_AIRMEET_TAB]
                                    .viewable,
                        },
                        {
                            id: STREAMING_RESOLUTION,
                            title: 'Streaming Resolution',
                            component: StreamingResolution,
                            componentProps: {
                                airmeetId,
                                isPaidPlan,
                                isLiteMeetup,
                            },
                            isHidden: !is1080PStreamingAllowed,
                            isHashRoute: true,
                            searchKeywords: ['resolution'],
                            visible:
                                permissionMappingToTabs[STREAMING_RESOLUTION]
                                    .visible,
                            clickable:
                                permissionMappingToTabs[STREAMING_RESOLUTION]
                                    .clickable,
                            viewable:
                                permissionMappingToTabs[STREAMING_RESOLUTION]
                                    .viewable,
                        },
                        {
                            ...leaderBoardTab,
                            isHidden: !(isLeaderboardEnabled && isLiteMeetup),
                        },

                        {
                            ...closedCaptionTab,
                            isHidden:
                                !isLiteMeetup ||
                                (isLiteMeetup &&
                                    sessionTypeLabel !==
                                        'Live broadcast session'),
                        },
                        {
                            id: ACCESSIBILITY_TAB,
                            title: 'Accessibility',
                            isHidden:
                                !isLiteMeetup ||
                                (sessions.length > 0 &&
                                    sessions[0].type !== 'HOSTING'),
                            componentProps: {
                                wrapper: {
                                    style: {
                                        mb: '20px',
                                    },
                                },
                                title: 'Accessibility',
                                subtitle:
                                    'Make the platform more inclusive and accessible to all users',
                                showInterprefyField: true,
                                field: {
                                    id: 'interprefyToken',
                                    name: 'interprefyToken',
                                    variant: 'v2',
                                    maxLength:
                                        CHAR_LIMIT_MAP[
                                            'liveSessionTranslationToken'
                                        ].max,
                                },
                            },
                            isHashRoute: true,
                            component: InterprefyField,
                            visible:
                                permissionMappingToTabs[ACCESSIBILITY_TAB]
                                    .visible,
                            clickable:
                                permissionMappingToTabs[ACCESSIBILITY_TAB]
                                    .clickable,
                            viewable:
                                permissionMappingToTabs[ACCESSIBILITY_TAB]
                                    .viewable,
                        },
                        {
                            id: ADVANCE_SESSION_SETTINGS,
                            title: 'Advance settings',
                            component: AdvanceSessionSettings,
                            componentProps: {
                                ...commonProps,
                            },
                            isHashRoute: true,
                        },
                    ],
                    visible: permissionMappingToTabs[SETTINGS_TAB].visible,
                    clickable: permissionMappingToTabs[SETTINGS_TAB].clickable,
                },
            ],
            visible: permissionMappingToTabs[SCHEDULE_TAB].visible,
            clickable: permissionMappingToTabs[SCHEDULE_TAB].clickable,
            viewable: permissionMappingToTabs[SCHEDULE_TAB].viewable,
        },
        {
            id: EVENT_SPACES_TAB,
            title: `${upperFirst(airmeetFormat)} Spaces`,
            isHidden: isLiteMeetup || isInPersonEvent,
            tabs: [
                {
                    id: LOUNGE_TAB,
                    title: 'Lounge',
                    component: Lounge,
                    componentProps: {
                        ...commonProps,
                    },
                },
                {
                    id: BOOTHS_TAB,
                    title: 'Booths',
                    component: Booths,
                    componentProps: {
                        ...commonProps,
                        boothsFlag: booths,
                        firebaseClient,
                    },
                    isHidden: isBoothsHidden,
                    isPremium: booths === ATTRACT,
                    visible: permissionMappingToTabs[BOOTHS_TAB].visible,
                    clickable: permissionMappingToTabs[BOOTHS_TAB].clickable,
                    viewable: permissionMappingToTabs[BOOTHS_TAB].viewable,
                },
                {
                    ...resourceTab,
                },
                {
                    id: EVENT_SPACE_SETTINGS_TAB,
                    title: `${upperFirst(airmeetFormat)} Space Settings`,
                    component: Settings,
                    componentProps: {
                        ...commonProps,
                        firebaseClient,
                        tableFirebaseClient,
                        sourceParentTabId: EVENT_SPACES_TAB,
                        sourceSubTabId: EVENT_SPACE_SETTINGS_TAB,
                        title: `${upperFirst(airmeetFormat)} Space Settings`,
                        subtitle: `Hide and show live space settings specific to people for a customized ${airmeetFormat} experience`,
                        airmeetFormat,
                    },
                    isHidden: isInPersonEvent,
                    tabs: [
                        {
                            ...leaderBoardTab,
                        },
                        { ...closedCaptionTab },
                        {
                            id: ADVANCE_EVENT_SPACE_SETTINGS,
                            title: 'Advance settings',
                            component: AdvanceSessionSettings,
                            componentProps: {
                                ...commonProps,
                                subtitle: `Hide/show ${airmeetFormat} attendees and chat, rename top navigations`,
                                isLiteMeetup,
                            },
                            isHashRoute: true,
                            isHidden: isInPersonEvent,
                        },
                    ],
                    visible: permissionMappingToTabs[SETTINGS_TAB].visible,
                    clickable: permissionMappingToTabs[SETTINGS_TAB].clickable,
                },
            ],
            visible: permissionMappingToTabs[SCHEDULE_TAB].visible,
            clickable: permissionMappingToTabs[SCHEDULE_TAB].clickable,
            viewable: permissionMappingToTabs[SCHEDULE_TAB].viewable,
        },
        {
            id: BRANDING_TAB,
            title: 'Branding',
            tabs: [
                {
                    id: EVENT_BRANDING_TAB,
                    title: `${upperFirst(airmeetFormat)} Branding`,
                    component:
                        branding === HIDE
                            ? venue_settings === HIDE
                                ? null
                                : VenueSettings
                            : MeetupBranding,
                    componentProps: {
                        ...commonProps,
                        premiumFeaturesVisible,
                        venueSettingsFlag: venue_settings,
                        customReceptionEnabledFlag: is_custom_reception_enabled,
                        isVenueSettingsLogoEnabled: is_venue_settings_logo_enabled,
                        showDefaultLogo: true,
                        branding,
                        showIntroVideo:
                            ([
                                'ENTERPRISE',
                                ENTERPRISE,
                                PRO_ATTENDEE,
                                ENTERPRISE_ATTENDEE,
                            ].includes(subscription_type) ||
                                isAppSumoUser) &&
                            !isInPersonEvent,
                        currentTab: {
                            id: VENUE_SETTINGS_TAB,
                            title: `${upperFirst(airmeetFormat)} Branding`,
                        },
                        isNewLandingPage,
                        sponsors,
                        airmeetFormat,
                    },
                    isPremium:
                        branding === HIDE
                            ? venue_settings === ATTRACT
                            : branding === ATTRACT,
                    visible:
                        permissionMappingToTabs[VENUE_SETTINGS_TAB].visible,
                    clickable:
                        permissionMappingToTabs[VENUE_SETTINGS_TAB].clickable,
                    viewable:
                        permissionMappingToTabs[VENUE_SETTINGS_TAB].viewable,
                },
                {
                    id: LANDING_PAGE_TAB,
                    title: 'Landing Page',
                    component: LandingPage,
                    componentProps: {
                        ...commonProps,
                        isNewLandingPage,
                        firebaseClient,
                    },
                    isNew: moment().isSameOrBefore(
                        CUT_OFF_DATE_LANDING_PAGE_NEW_TAG
                    ),
                    visible: permissionMappingToTabs[LANDING_PAGE_TAB].visible,
                    clickable:
                        permissionMappingToTabs[LANDING_PAGE_TAB].clickable,
                    viewable:
                        permissionMappingToTabs[LANDING_PAGE_TAB].viewable,
                },
                {
                    id: RECEPTION_TAB,
                    title: isHybridEvent ? 'Online Reception' : 'Reception',
                    component: VenueSettings,
                    componentProps: {
                        ...commonProps,
                        venueSettingsFlag: venue_settings,
                        customReceptionEnabledFlag: is_custom_reception_enabled,
                        isVenueSettingsLogoEnabled: is_venue_settings_logo_enabled,
                        branding,
                        currentTab: {
                            id: RECEPTION_TAB,
                            title: isHybridEvent
                                ? 'Online Reception'
                                : 'Reception',
                        },
                    },
                    isHidden: venue_settings === HIDE || isInPersonEvent,
                    isPremium: venue_settings === ATTRACT,
                    visible:
                        permissionMappingToTabs[VENUE_SETTINGS_TAB].visible,
                    clickable:
                        permissionMappingToTabs[VENUE_SETTINGS_TAB].clickable,
                },
                {
                    id: STAGE_TAB,
                    title: isHybridEvent ? 'Online Stage' : 'Stage',
                    component: Stage,
                    componentProps: {
                        ...commonProps,
                        firebaseClient,
                        isHybridEvent,
                    },
                    isHidden: is_free_plan_user_in_meetup || isInPersonEvent,
                    visible: permissionMappingToTabs[STAGE_TAB].visible,
                    clickable: permissionMappingToTabs[STAGE_TAB].clickable,
                    viewable: permissionMappingToTabs[STAGE_TAB].viewable,
                },
                {
                    id: SPONSORS_TAB,
                    title: 'Sponsors',
                    component: Sponsors,
                    componentProps: {
                        ...commonProps,
                        sponsorsFlag: sponsors,
                        airmeetFormat,
                    },
                    isHidden: sponsors === HIDE || is_free_plan_user_in_meetup,
                    isPremium: sponsors === ATTRACT,
                    visible: permissionMappingToTabs[SPONSORS_TAB].visible,
                    clickable: permissionMappingToTabs[SPONSORS_TAB].clickable,
                    viewable: permissionMappingToTabs[SPONSORS_TAB].viewable,
                },
            ],
            visible: permissionMappingToTabs[BRANDING_TAB].visible,
            clickable: permissionMappingToTabs[BRANDING_TAB].clickable,
        },
        {
            id: EMAILS_TAB,
            title: 'Emails',
            component: EmailManagement,
            componentProps: {
                ...commonProps,
                isWaitlistFeatureAllowed,
                subscription_type,
                isSegmentsPremium:
                    freeOrConfStarterPlan ||
                    subscription_type === STARTER_ATTENDEE ||
                    isAppSumoUser,
            },
            isHidden:
                !is_custom_email_template_enabled_for_community ||
                !is_custom_email_enabled_for_airmeet,
            visible: permissionMappingToTabs[EMAILS_TAB].visible,
            clickable: permissionMappingToTabs[EMAILS_TAB].clickable,
            viewable: permissionMappingToTabs[EMAILS_TAB].viewable,
        },
        {
            id: INTEGRATIONS_TAB,
            title: 'Integrations',
            component: Integrations,
            componentProps: {
                ...commonProps,
            },
            visible:
                permissionMappingToTabs[INTEGRATIONS_TAB].visible &&
                permissionMappingToTabs[INTEGRATIONS_TAB].viewable,
            clickable: permissionMappingToTabs[INTEGRATIONS_TAB].clickable,
            viewable: permissionMappingToTabs[INTEGRATIONS_TAB].viewable,
        },
        {
            id: WORKFLOWS_TAB,
            title: 'Workflows',
            tabs: [
                {
                    id: AUTOMATED_WORKFLOWS_TAB,
                    title: 'Automated Workflows',
                    component: AutomatedWorkflows,
                    componentProps: {
                        ...commonProps,
                        firebaseClient,
                        tableFirebaseClient,
                        isAutomatedWorkflowEnabled: isAutomatedWorkflowEnabled,
                    },
                    isPremium: isAutomatedWorkflowEnabled === 'ATTRACT',
                    isHidden: isAutomatedWorkflowEnabled === 'HIDE',
                },
            ],
            isPremium: isAutomatedWorkflowEnabled === 'ATTRACT',
            isHidden: isAutomatedWorkflowEnabled === 'HIDE' || isInPersonEvent,
        },
        {
            id: ANALYTICS_TAB,
            title: 'Analytics',
            tabs: [
                ...analyticsTabs.map((tab) => ({
                    ...(tab || {}),
                    title: tab?.title,
                    component: Analytics,
                    componentProps: {
                        ...commonProps,
                        currentTab: {
                            id: tab?.id,
                            title: tab?.title,
                        },
                        airmeetFormat,
                    },
                    id: tab?.id,
                    isHidden: isLiteMeetup && tab?.id === 'engagement',
                    clickable: permissionMappingToTabs?.[tab?.id].clickable,
                    viewable: permissionMappingToTabs?.[tab?.id].viewable,
                })),
                {
                    id: AUDIENCE_USERS_TAB,
                    title: 'Users',
                    component: Audience,
                    isHidden:
                        start_time &&
                        moment(start_time)
                            .tz('Etc/GMT')
                            .isBefore(CUT_OFF_DATE_AUDIENCE),
                    componentProps: {
                        ...commonProps,
                        currentTab: {
                            id: AUDIENCE_USERS_TAB,
                            title: 'Users',
                        },
                        isHybridEvent,
                        isUnifiedProfilesEnabled,
                        isWaitlistFeatureAllowed,
                    },
                    isPremium: !isPaidPlan || isFreeTrialPlanDeactivated,
                    visible:
                        permissionMappingToTabs[AUDIENCE_TAB].visible &&
                        !isAppSumoUser,
                    clickable: permissionMappingToTabs[AUDIENCE_TAB].clickable,
                },
                {
                    id: AUDIENCE_SEGMENTS_TAB,
                    title: 'Segments',
                    isHidden:
                        (start_time &&
                            moment(start_time)
                                .tz('Etc/GMT')
                                .isBefore(CUT_OFF_DATE_AUDIENCE)) ||
                        isAppSumoUser,
                    component: Audience,
                    componentProps: {
                        ...commonProps,
                        currentTab: {
                            id: AUDIENCE_SEGMENTS_TAB,
                            title: 'Segments',
                        },
                        isHybridEvent,
                        isWaitlistFeatureAllowed,
                    },
                    isPremium: !isPaidPlan || isFreeTrialPlanDeactivated,
                    visible:
                        permissionMappingToTabs[AUDIENCE_TAB].visible &&
                        !isAppSumoUser,
                    clickable: permissionMappingToTabs[AUDIENCE_TAB].clickable,
                },
                {
                    id: ANALYTICS_REPORTS_LIBRARY_TAB,
                    title: 'Reports',
                    component: ReportsLibrary,
                    componentProps: {
                        ...commonProps,
                        isPremium:
                            !isPaidPlan ||
                            isAppSumoUser ||
                            isFreeTrialPlanDeactivated,
                        isResourceManagementEnabled,
                        airmeetFormat,
                        isLiteMeetup,
                    },
                    isHidden: isInPersonEvent,
                },
                {
                    id: ANALYTICS_REPORTS_DOWNLOADS_TAB,
                    title: 'Downloads',
                    component: ReportsDownloads,
                    componentProps: {
                        ...commonProps,
                        airmeetFormat,
                    },
                    clickable: true,
                    visible: true,
                    isHidden: false,
                },
            ],
            visible: permissionMappingToTabs[ANALYTICS_TAB].visible,
            clickable: permissionMappingToTabs[ANALYTICS_TAB].clickable,
        },
    ];

    return tabs;
};
