import { useEffect, useMemo } from 'react';
import { createClosedCaptionsConfig } from 'utils/closed-captions';
import { ALL_PLATFORM_FEATURES } from 'hooks/useFeatureControl';
import PermissionUtils from 'utils/permission-utils';
import { useDispatch } from 'react-redux';
import { setClosedCaptionsConfig } from 'store/actions/closedCaptions';

const useClosedCaptionFeature = ({
    featuresConfig,
    enabledPlatformFeatures,
    currentAirmeet,
}) => {
    const dispatch = useDispatch();
    const closedCaptions =
        currentAirmeet?.enabled_feature_data_v2?.closed_captions;
    const enableCCSubscriptionViaFirebase = !!currentAirmeet
        ?.enabled_feature_data_v2?.cc_subscription_via_firebase_enabled;

    const isClosedCaptioningEnabled = useMemo(
        () =>
            !PermissionUtils.isEventCloudHost() &&
            (enabledPlatformFeatures || []).includes(
                ALL_PLATFORM_FEATURES.CLOSED_CAPTIONS
            ) &&
            closedCaptions === 'SHOW',
        [closedCaptions, enabledPlatformFeatures]
    );

    const isCCSubscriptionViaFirebaseEnable = useMemo(
        () =>
            (enabledPlatformFeatures || []).includes(
                ALL_PLATFORM_FEATURES.ENABLE_CC_SUBSCRIPTION_VIA_FIREBASE
            ) && enableCCSubscriptionViaFirebase,
        [enableCCSubscriptionViaFirebase, enabledPlatformFeatures]
    );

    useEffect(() => {
        if (!currentAirmeet) {
            return;
        }
        const dataObject = {
            featuresConfig,
            airmeetId: currentAirmeet?.airmeetId,
            communityId: currentAirmeet?.community_id,
            backendConfig: {
                closedCaptionsServiceProvider:
                    currentAirmeet?.cc_service_provider,
            },
            live_config: currentAirmeet?.live_config,
            closed_captions: closedCaptions,
        };
        // hook to store closed-captions settings
        dispatch(
            setClosedCaptionsConfig(createClosedCaptionsConfig(dataObject))
        );
    }, [featuresConfig, currentAirmeet, closedCaptions, dispatch]);

    return {
        isClosedCaptioningEnabled,
        isCCSubscriptionViaFirebaseEnable,
    };
};

export default useClosedCaptionFeature;
