export const COMMUNITY_LOGIN_SOURCE = 'host an event';
export const INVITATION_SOURCE = 'MEMBER_INVITATION';
export const EVENT_REGISTRATION = 'event registration';
export const SIGNUP_INTENT_ORGANISER = 'ORGANISER';
export const SIGNUP_INTENT_ATTENDEE = 'ATTENDEE';

export const WEAK_PASSWORD = 'weak';
export const PASSWORD_NOT_STRONG = 'notStrong';
export const STRONG_PASSWORD = 'strong';

const getPasswordInputType = (passwordErrors) => {
    const error = passwordErrors.find((error) => error?.isError === true);

    if (!error) return '';
    return error.isWarning ? 'warning' : 'error';
};

export const getErrorColor = (passwordErrors = [], theme) => {
    const type = getPasswordInputType(passwordErrors);
    if (type === 'warning') return theme.colors.accentCarrot[3];
    else if (type === 'error') return theme.colors.accentBrick[2];
    else return '';
};

export const passwordStrengthDetail = {
    [WEAK_PASSWORD]: {
        message: 'Password is weak',
        icon: 'warning',
        iconStyle: { fill: 'semantic.0', height: '12px', width: '12px' },
        passwordColor: 'lighting.7',
    },
    [STRONG_PASSWORD]: {
        message: 'You have selected a strong password',
        icon: 'checkmark_circle',
        iconStyle: { fill: 'semantic.2', height: '13px', width: '13px' },
        passwordColor: 'lighting.0',
    },
    [PASSWORD_NOT_STRONG]: {
        message: 'Password is not strong enough',
        icon: 'warning',
        iconStyle: { fill: 'semantic.0', height: '12px', width: '12px' },
        passwordColor: 'lighting.6',
    },
};

export const PASSWORD_ERROR_TYPES = {
    MIN_LENGTH: 'minLength',
    MAX_LENGTH: 'maxLength',
    NO_LOWERCASE: 'noLowercase',
    NO_UPPERCASE: 'noUppercase',
    NO_SPECIAL_SYMBOL: 'noSpecialSymbol',
    HAS_SPACE: 'hasSpace',
    NO_NUMBER: 'noNumber',
    HAS_SEQUENTIAL: 'hasSequential',
};

export const getPwErrorConfig = ({ errorKey, isError }) => {
    let errConfig = null;

    const {
        MIN_LENGTH,
        MAX_LENGTH,
        NO_LOWERCASE,
        NO_UPPERCASE,
        NO_SPECIAL_SYMBOL,
        HAS_SPACE,
        NO_NUMBER,
        HAS_SEQUENTIAL,
    } = PASSWORD_ERROR_TYPES;

    const commonProps = {
        isError,
        textColor: isError ? 'accentBrick.2' : 'accentSpring.3',
        textStyle: {},
        icon: isError ? 'close-circle-outline' : 'checkmark-circle-outline',
        iconStyle: {
            fill: isError ? 'accentBrick.2' : 'accentSpring.3',
            height: '13px',
            width: '13px',
        },
    };
    switch (errorKey) {
        case MIN_LENGTH:
        case MAX_LENGTH:
            errConfig = {
                message:
                    'Should be 8 characters or more. Maximum 48 characters',
            };
            break;
        case NO_LOWERCASE:
        case NO_UPPERCASE:
            errConfig = {
                message: 'Should contain upper case and lower case letters',
            };
            break;
        case NO_SPECIAL_SYMBOL:
        case NO_NUMBER:
            errConfig = {
                message:
                    'Should contain at least a number and a special character',
            };
            break;
        case HAS_SPACE:
            errConfig = {
                message: 'Should NOT contain empty space ()',
                isHidden: !isError,
            };
            break;
        case HAS_SEQUENTIAL:
            errConfig = {
                message:
                    'More than 5 sequential letters or numbers are not recommended',
                isHidden: !isError,
                textColor: isError ? 'accentCarrot.3' : 'accentSpring.3',
                icon: isError
                    ? 'error-circle-outline'
                    : 'checkmark-circle-outline',
                iconStyle: {
                    ...commonProps.iconStyle,
                    fill: isError ? 'accentCarrot.3' : 'accentSpring.3',
                },
                isWarning: true,
            };
            break;
        default:
            break;
    }

    return {
        ...commonProps,
        ...errConfig,
    };
};
