// Constants

// Action names
export const APP_HEADER_TOGGLE = 'APP_HEADER_TOGGLE';
export const APP_HEADER_SET_PROPS = 'APP_HEADER_SET_PROPS';
export const APP_HEADER_UPDATE_PROPS = 'APP_HEADER_UPDATE_PROPS';
export const APP_HEADER_CLEAR_PROPS = 'APP_HEADER_CLEAR_PROPS';
export const APP_PAGE_SET_PROPS = 'APP_PAGE_SET_PROPS';
export const APP_PAGE_WIDTH_FULL = 'APP_PAGE_WIDTH_FULL';
export const APP_PAGE_WIDTH_RESTRICTED = 'APP_PAGE_WIDTH_RESTRICTED';
export const APP_SIDEMENU_TOGGLE = 'APP_SIDEMENU_TOGGLE';
export const APP_SIDEMENU_ACTIVE_ITEM = 'APP_SIDEMENU_ACTIVE_ITEM';
export const APP_SIDEMENU_SET_PROPS = 'APP_SIDEMENU_SET_PROPS';
export const APP_SIDEMENU_CLEAR_PROPS = 'APP_SIDEMENU_CLEAR_PROPS';
export const APP_MODAL_SHOW = 'APP_MODAL_SHOW';
export const APP_MODAL_HIDE = 'APP_MODAL_HIDE';
export const APP_FOOTER_VISIBILITY = 'APP_FOOTER_VISIBILITY';
export const APP_FOOTER_SET_PROPS = 'APP_FOOTER_SET_PROPS';
export const APP_SIGNUP_PAGE_SET_PROPS = 'APP_SIGNUP_PAGE_SET_PROPS';
export const APP_SIGNUP_PAGE_CLEAR_PROPS = 'APP_SIGNUP_PAGE_CLEAR_PROPS';
export const APP_USER_INVITE_SET_SCREEN_PROPS =
    'APP_USER_INVITE_SET_SCREEN_PROPS';
export const APP_USER_INVITE_CLEAR_SCREEN_PROPS =
    'APP_USER_INVITE_CLEAR_SCREEN_PROPS';
export const APP_INVITED_USER_SET_STATE = 'APP_INVITED_USER_SET_STATE';
export const APP_INVITED_USER_CLEAR_STATE = 'APP_INVITED_USER_CLEAR_STATE';
export const APP_SHOW_ONBOARDING_COMMUNITY_LOADER =
    'APP_SHOW_ONBOARDING_COMMUNITY_LOADER';
export const APP_HIDE_ONBOARDING_COMMUNITY_LOADER =
    'APP_HIDE_ONBOARDING_COMMUNITY_LOADER';
export const APP_SHOW_ONBOARDING_PROGRESS_BAR =
    'APP_SHOW_ONBOARDING_PROGRESS_BAR';
export const APP_HIDE_ONBOARDING_PROGRESS_BAR =
    'APP_HIDE_ONBOARDING_PROGRESS_BAR';
export const APP_VIDEO_EDTIOR_SET_PROPS = 'APP_VIDEO_EDTIOR_SET_PROPS';
export const APP_VIDEO_EDTIOR_RESET_PROPS = 'APP_VIDEO_EDTIOR_RESET_PROPS';
// Action dispatchers
export const toggleAppHeader = (visible) => ({
    type: APP_HEADER_TOGGLE,
    payload: !!visible,
});

export const setHeaderProps = (props) => ({
    type: APP_HEADER_SET_PROPS,
    payload: { ...props },
});

export const updateHeaderProps = (props) => ({
    type: APP_HEADER_UPDATE_PROPS,
    payload: { ...props },
});

export const clearHeaderProps = () => ({
    type: APP_HEADER_CLEAR_PROPS,
});

export const setPageWidthRestricted = (restricted = true) => ({
    type: restricted ? APP_PAGE_WIDTH_RESTRICTED : APP_PAGE_WIDTH_FULL,
});

export const setPageProps = (props = {}) => ({
    type: APP_PAGE_SET_PROPS,
    payload: props,
});

export const setSideMenuEnabled = (enabled = true) => ({
    type: APP_SIDEMENU_TOGGLE,
    payload: enabled === true,
});

export const setSideMenuActiveId = (activeId = null) => ({
    type: APP_SIDEMENU_ACTIVE_ITEM,
    payload: activeId,
});

export const setSideMenuProps = (props = {}) => ({
    type: APP_SIDEMENU_SET_PROPS,
    payload: { ...props },
});

export const clearSideMenuProps = () => ({
    type: APP_SIDEMENU_CLEAR_PROPS,
});

export const showAppModal = (modalName, props = {}) => ({
    type: APP_MODAL_SHOW,
    payload: { active: modalName, props },
});

export const showFixedModal = (modalName, props = {}) => ({
    type: APP_MODAL_SHOW,
    payload: { active: modalName, props, allowClose: false },
});

export const closeAppModal = (modalName) => ({
    type: APP_MODAL_HIDE,
    payload: { hide: modalName },
});

export const closeFixedModal = (modalName) => ({
    type: APP_MODAL_HIDE,
    payload: { hide: modalName, allowClose: true },
});

export const setFooterVisible = (visible = true) => ({
    type: APP_FOOTER_VISIBILITY,
    payload: visible === true,
});

export const setFooterProps = (props = {}) => ({
    type: APP_FOOTER_SET_PROPS,
    payload: props,
});

export const setSignUpPageProps = (props = {}) => ({
    type: APP_SIGNUP_PAGE_SET_PROPS,
    payload: { props },
});

export const clearSignUpPageProps = () => ({
    type: APP_SIGNUP_PAGE_CLEAR_PROPS,
    payload: {},
});

export const setUserInviteScreenPageProps = (props = {}) => ({
    type: APP_USER_INVITE_SET_SCREEN_PROPS,
    payload: { props },
});

export const clearUserInviteScreenPageProps = () => ({
    type: APP_USER_INVITE_CLEAR_SCREEN_PROPS,
    payload: {},
});

export const setInvitedUserState = (payload = {}) => ({
    type: APP_INVITED_USER_SET_STATE,
    payload,
});

export const clearInvitedUserState = () => ({
    type: APP_INVITED_USER_CLEAR_STATE,
    payload: {},
});

export const setShowOnboardingCommunityLoader = () => ({
    type: APP_SHOW_ONBOARDING_COMMUNITY_LOADER,
    payload: { showOnboardingCommunityLoader: true },
});

export const setHideOnboardingCommunityLoader = () => ({
    type: APP_HIDE_ONBOARDING_COMMUNITY_LOADER,
    payload: { showOnboardingCommunityLoader: false },
});

export const setShowOnboardingProgressBar = () => ({
    type: APP_SHOW_ONBOARDING_PROGRESS_BAR,
    payload: { showProgressBar: true },
});

export const setHideOnboardingProgressBar = () => ({
    type: APP_HIDE_ONBOARDING_PROGRESS_BAR,
    payload: { showProgressBar: false },
});

export const setVideoEditorLayoutProps = (props) => ({
    type: APP_VIDEO_EDTIOR_SET_PROPS,
    payload: props,
});

export const resetVideoEditorLayoutProps = () => ({
    type: APP_VIDEO_EDTIOR_RESET_PROPS,
});
