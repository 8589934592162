import { themeWrapper } from 'foundations/theme';
import React, { useEffect } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { getCurrentTenantData } from 'utils/tenant';

const BodyStyles = createGlobalStyle`
    body {
        background: ${({ theme }) => `${theme.colors.ambience[24]} !important`};
    }
`;
const IconVersion = 15;

export const ThemeContext = React.createContext(null);

export function AirmeetThemeProvider({ children }) {
    useEffect(() => {
        const cdnUrl = process.env.CDN_ASSETS_URL;
        const svgPath = `${
            cdnUrl
                ? cdnUrl === '/'
                    ? process.env.REACT_APP_BASE_URL + '/'
                    : cdnUrl
                : ''
        }images/icons.svg?v=${IconVersion}`;

        fetch(svgPath)
            .then((res) => res.text())
            .then((res) => {
                var div = document.createElement('div');
                div.style.display = 'none';
                div.innerHTML = res;
                document.body.insertBefore(div, document.body.childNodes[0]);
            });
    }, []);

    const baseColor = getCurrentTenantData('primaryColor');
    const theme = themeWrapper(baseColor, false);
    const isLightTheme = false;

    //const { theme } = useTheme(); //root cause, context fetch hook called without respective wrapper context

    return (
        <ThemeProvider theme={theme}>
            <ThemeContext.Provider
                value={{
                    theme,
                    isLightTheme,
                    themeWrapper,
                    baseColor,
                }}
            >
                <BodyStyles />
                {children}
            </ThemeContext.Provider>
        </ThemeProvider>
    );
}
