import EventEmitter from 'events';
import { logger } from 'utils/logger';
export default class Stage extends EventEmitter {
    constructor(params) {
        super();
        this.connectViaProxy = params.connectViaProxy;
        this.useAirmeetProxy = params.useAirmeetProxy;
        this.airmeetId = params.airmeetId;
        this.useAirmeetTURNServer = params.useAirmeetTURNServer;
        this.turnServerConfig = params.turnServerConfig;
        this.geofencing = params.geofencing;
        this.authUser = params.authUser;
        this.serviceName = params.serviceName;
    }

    log(...args) {
        logger.info(`Airmeet:Stage-${this.serviceName}`, ...args);
    }

    async setMediaStream(localStream) {
        return localStream;
    }
}
