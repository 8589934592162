export const UPDATE_SHOW_DM_NOTIFICATION = 'UPDATE_SHOW_DM_NOTIFICATION';
export const SET_LAST_DM_NOTIFICATION_TIME = 'SET_LAST_DM_NOTIFICATION_TIME';

export const updateShowDmNotification = (value) => ({
    type: UPDATE_SHOW_DM_NOTIFICATION,
    payload: value,
});

export const setLastDmNotificationTime = (value) => ({
    type: SET_LAST_DM_NOTIFICATION_TIME,
    payload: value,
});
