import { useField } from '@rocketseat/unform';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function DateTimePicker({
    name,
    dateFormat = 'Pp',
    showTime,
    onChange,
    placeholder = '',
    minDate = new Date(),
    maxDate,
    disabled,
    defaultDate,
    ...rest
}) {
    const ref = useRef(null);
    const { fieldName, registerField, defaultValue, error } = useField(name);
    const [selected, setSelected] = useState(defaultValue);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: ref.current,
            path: 'props.selected',
            clearValue: (pickerRef) => {
                pickerRef.clear();
            },
        });
    }, [ref.current, fieldName]); // eslint-disable-line

    const onDateChange = (date) => {
        setSelected(date);

        if (onChange) {
            onChange(date);
        }
    };

    return (
        <>
            <ReactDatePicker
                name={fieldName}
                selected={defaultDate ? defaultDate : selected}
                onChange={onDateChange}
                minDate={minDate}
                maxDate={maxDate}
                autoComplete='off'
                ref={ref}
                showTimeSelect={showTime}
                dateFormat={dateFormat}
                placeholderText={placeholder}
                disabled={disabled}
                className={'custom-time-event'}
                shouldCloseOnSelect={false}
                // Disabling manual edits, use calendar for selecting values
                onBlur={(e) => e.target.blur()}
                {...rest}
            />
            {error && <span>{error}</span>}
        </>
    );
}
