import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';

const { UPDATE_INIVITATION_STATUS } = FEATURE_ACTIONS[
    FEATURE_NAMES.STAGE_INVITE
];
export default function statusUpdateInFirebase({
    status,
    userId,
    senderUserId,
    sessionId,
    dataWriter,
}) {
    const userInviteData = ['accepted', 'removed', 'rejected'].includes(status)
        ? null
        : {
              senderUserId,
              sessionId,
              status,
          };

    dataWriter(
        { data: userInviteData, status },
        {
            action: UPDATE_INIVITATION_STATUS,
            userId,
            sessionId,
        }
    );
}

export function findHandRaisePendingRequest(list, userId) {
    return list.find((l) => l.user.id === userId && l.status === 'pending');
}

export function findHandRaiseActiveRequest(list, userId) {
    return list.find((l) => l.user.id === userId && l.status === 'active');
}
