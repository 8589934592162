import classnames from 'classnames';
import SVGIcon from 'foundations/icon';
import PropTypes from 'prop-types';
import React from 'react';
import { StyledSvgSprite } from '../../../general/SVGSprite';
import { SortOrder } from './';
import RowSelector from './rowSelector';

/**
 * Sample Header config object
 * {
        key: key from data to map to ,
        title: to be displayed as the column header,
        headerClass: class added to header,
        colClass: class added to every column,
        isSortable: allows sorting,
    },
 *  
 */
const THead = ({
    headers,
    actions,
    onSort,
    rowSelection,
    onSelect,
    checkboxState,
    actionHeader,
    arrowSortIcon,
    sortOrder,
    checkboxBgColor = null,
    selectedHeader = null,
    isBooth,
    disableAllSelection = false,
}) => {
    let selectedHeaderColSpan = (headers || []).length;
    if (actions) {
        selectedHeaderColSpan += 1;
    }

    return (
        <thead>
            <tr>
                {rowSelection && (
                    <th className='checkbox-outer'>
                        <RowSelector
                            id={'checkAll'}
                            onSelect={(checked) => onSelect(null, checked)}
                            state={checkboxState}
                            checkboxBgColor={checkboxBgColor}
                            disabled={disableAllSelection}
                        />
                    </th>
                )}
                {selectedHeader &&
                rowSelection &&
                ['indeterminate', 'selected'].includes(checkboxState) ? (
                    <th colSpan={selectedHeaderColSpan}>{selectedHeader}</th>
                ) : (
                    <>
                        {headers &&
                            headers.map((header, id) => (
                                <TableHeaderCell
                                    key={`th-${id}`}
                                    cellKey={header.key}
                                    {...header}
                                    onSort={onSort}
                                    arrowSortIcon={arrowSortIcon}
                                    sortOrder={sortOrder}
                                />
                            ))}
                        {actions && (
                            <th
                                scope='col'
                                className={`action-head ${
                                    isBooth ? '' : 'text-center'
                                }`}
                            >
                                {actionHeader}
                            </th>
                        )}
                    </>
                )}
            </tr>
        </thead>
    );
};

const TableHeaderCell = ({
    cellKey,
    title,
    headerClass,
    isSortable,
    onSort,
    arrowSortIcon = false,
    sortOrder = {},
    renderHeader = null,
}) => {
    const onSortClick = () => {
        onSort(cellKey);
    };

    let sortIcon = null;
    if (isSortable) {
        if (arrowSortIcon) {
            let sortClass = '';
            if (sortOrder && cellKey in sortOrder) {
                sortClass =
                    sortOrder[cellKey] === SortOrder.ASC
                        ? 'data-table__sort-icon--asc'
                        : 'data-table__sort-icon--desc';
            }

            sortIcon = (
                <div className={`data-table__sort-icon ${sortClass}`}>
                    <SVGIcon icon={'arrow_up'} fill='ambience.0' />
                </div>
            );
        } else {
            sortIcon = (
                <StyledSvgSprite icon={'icon-Sort-Icon'} fill='ambience.0' />
            );
        }
    }

    return (
        <th
            scope='col'
            className={classnames('data-table__th', cellKey, headerClass)}
        >
            {renderHeader ? (
                renderHeader({ sortIcon, onSortClick })
            ) : (
                <span
                    className='data-table__th__text'
                    onClick={isSortable ? onSortClick : null}
                >
                    {title} {sortIcon}
                </span>
            )}
        </th>
    );
};

THead.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.object),
    actions: PropTypes.arrayOf(PropTypes.object),
    onSort: PropTypes.func,
};

TableHeaderCell.propTypes = {
    cellKey: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    headerClass: PropTypes.string,
    isSortable: PropTypes.bool,
    onSort: PropTypes.func,
};

export default THead;
