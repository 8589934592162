import { TooltipContent, TooltipHead } from 'atoms/RestrictedAccountTooltip';
import {
    TooltipContent as LiveToolTipContent,
    TooltipHead as LiveTooltipHead,
} from 'atoms/RestrictedAccountTooltip/LiveRestrictedAccountTooltip';
import ToggleSwitchButton from 'atoms/ToggleSwitch/ToggleSwitchButton';
import classnames from 'classnames';
import WithTooltip from 'components/general/WithTooltip';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import RelativeToolTip from 'components/tooltip/relativeToolTip';
import useComponentVisible from 'hooks/useComponentVisible';
import useTheme from 'hooks/useTheme';
import useToasts from 'hooks/useToasts';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';
import SVGSprite from '../../../general/SVGSprite';
import DeletePopup from '../DeletePopup';
import { stripPrivateKeys } from './index';

const ActionItem = ({
    row,
    name,
    iconClass = '',
    onClick = () => {},
    isDisabled,
    isAirmeetEnded = false,
    getIsTicketSold = () => false,
    tooltiptext = '',
    confirmationMessage,
    ticketSoldTipText = '',
    airmeetEndedToolTipText = '',
    resourceUnavailableTooltipText = '',
    actionType,
    onDelete,
    checkedKey,
    onCheckedChange,
    showSwitchLabel,
    labelConfig = {},
    onEdit,
    options,
    renderCustom,
    deletePosition,
    backdropHeight,
    metaData = {},
    index,
    addCustomClasses = true,
    disablePopup = false,
    disableTooltipClass,
    actionDisabled = false,
    actionVisible = true,
    liveEvent = false,
    showTooltipHeadWhenActionDisabled = true,
    actionDisabledTooltipText = undefined,
    actionDisabledTooltipMinWidth = 235,
}) => {
    const [disabled, setDisabled] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isSwitchChecked, setIsSwitchChecked] = useState(
        row[checkedKey] || false
    );
    const { theme } = useTheme();
    const [isMoreOpen, setIsMoreOpen] = useState(false);
    const { on: labelOn, off: labelOff } = labelConfig;
    const [config, setConfig] = useState({
        on: labelOn ? labelOn : 'Enabled',
        off: labelOff ? labelOff : 'Disabled',
    });

    const {
        ref,
        isComponentVisible,
        setIsComponentVisible,
    } = useComponentVisible(false);
    const { primaryToast } = useToasts();

    useEffect(() => {
        if (checkedKey) {
            setIsSwitchChecked(row[checkedKey]);
        }
    }, [checkedKey, row]);

    useEffect(() => {
        if (isDisabled instanceof Function) {
            setDisabled(isDisabled(row));
        }
    }, [isDisabled, row]);

    const _onDeleteClick = () => {
        setIsDeleteOpen(false);
        if (onDelete instanceof Function) {
            onDelete(stripPrivateKeys(row));
        }
    };

    const setConfirmationMessage = () => {
        if (confirmationMessage instanceof Function) {
            return confirmationMessage(stripPrivateKeys(row));
        }
    };

    const _isTicketSold =
        getIsTicketSold instanceof Function
            ? getIsTicketSold(stripPrivateKeys(row))
            : false;

    const _onCheckedChange = (e) => {
        const { checked } = e.target;
        setIsSwitchChecked(checked);

        if (onCheckedChange instanceof Function) {
            onCheckedChange(stripPrivateKeys(row), checked);
        }
    };

    const _onEditClicked = () => {
        onEdit(stripPrivateKeys(row));
    };

    const _dotMenuClicked = () => {
        if (!disabled) {
            setIsMoreOpen(!isMoreOpen);
        }
    };

    const _onDeleteItemClicked = (item) => {
        if (!item.disabled) {
            setIsDeleteOpen(true);
        }
    };

    const _onDeleteCancelClicked = () => {
        setIsDeleteOpen(false);
    };

    const _onDeleteConfirmClicked = (item) => {
        item.onClick(row);
        // hide the delete popup
        setIsDeleteOpen(false);
        // hide the more button dropdown
        setIsComponentVisible(false);
    };

    const _onCustomActionClicked = (item) => {
        item.onClick(row);
        // hide the more button dropdown
        setIsComponentVisible(false);
    };

    const _onCopied = () => {
        primaryToast('Link copied to clipboard');
    };

    const commonTooltipProps = {
        place: 'top',
        tooltipText: !showTooltipHeadWhenActionDisabled ? null : liveEvent ? (
            <LiveTooltipHead />
        ) : (
            <TooltipHead />
        ),
        renderElement: liveEvent ? (
            <LiveToolTipContent />
        ) : (
            <TooltipContent content={actionDisabledTooltipText} />
        ),
        minWidth: 212,
        svgIcon: iconClass,
        svgHeight: '20px',
        svgWidth: '20px',
        svgFill: theme.colors.ambience[4],
        portal: true,
        portalTop: -15,
        portalLeft: 5,
        tooltipContainerStyle: {
            transition: 'none',
        },
        marginRight: '5px !important',
        padding: '0px !important',
    };

    const renderAction = () => {
        let tooltip = tooltiptext;
        let isDisabled = disabled || actionDisabled;
        if (isAirmeetEnded) {
            tooltip = airmeetEndedToolTipText;
            isDisabled = isAirmeetEnded;
        } else if (_isTicketSold) {
            tooltip = ticketSoldTipText;
            isDisabled = _isTicketSold;
        }
        switch (actionType) {
            case 'switch':
                return (
                    <span
                        className={classnames('action-item radio-outer', {
                            disabled: disabled,
                        })}
                    >
                        <FlexDiv alignItems='center' p='0' m='0'>
                            <ToggleSwitchButton
                                checked={isSwitchChecked}
                                id={`switch-${row._id}`}
                                onChange={
                                    disabled ? undefined : _onCheckedChange
                                }
                                disabled={disabled}
                                background='#9D9AAC'
                                activeBackground='#009862'
                                thumbColor='#FCFCFC'
                                thumbActiveColor='#FCFCFC'
                            />
                            {showSwitchLabel && (
                                <p className='switch-label'>
                                    {isSwitchChecked ? config.on : config.off}
                                </p>
                            )}
                        </FlexDiv>
                    </span>
                );
            case 'delete':
                return (
                    actionVisible && (
                        <div className='action-clck'>
                            <span
                                className={classnames(
                                    'action-item action-delete',
                                    {
                                        disabled: isDisabled,
                                    }
                                )}
                                onClick={
                                    isDisabled
                                        ? undefined
                                        : () => setIsDeleteOpen(true)
                                }
                            >
                                <WithTooltip
                                    toolClass={
                                        disableTooltipClass ||
                                        'deletePopupTooltip'
                                    }
                                    renderElement={
                                        disabled &&
                                        disablePopup &&
                                        disablePopup(row, index)
                                    }
                                    place='bottom'
                                >
                                    {!actionDisabled ? (
                                        <>
                                            <SVGSprite
                                                icon={
                                                    iconClass ||
                                                    'icon-fill-delete'
                                                }
                                                aria-hidden='true'
                                            />
                                            {!(disabled && disablePopup) && (
                                                <span
                                                    className={classnames({
                                                        disabledToolTipText: isDisabled,
                                                        tooltiptext: !isDisabled,
                                                    })}
                                                >
                                                    {isDisabled &&
                                                    resourceUnavailableTooltipText
                                                        ? resourceUnavailableTooltipText
                                                        : tooltip || 'Delete'}
                                                </span>
                                            )}
                                        </>
                                    ) : (
                                        <RelativeToolTip
                                            {...commonTooltipProps}
                                            svgIcon={
                                                iconClass || 'icon-fill-delete'
                                            }
                                            svgStyle={{
                                                cursor: 'not-allowed',
                                            }}
                                            portalLeft={10}
                                            marginRight={'0px !important'}
                                        />
                                    )}
                                </WithTooltip>
                            </span>

                            {isDeleteOpen && (
                                <DeletePopup
                                    message={
                                        confirmationMessage
                                            ? setConfirmationMessage()
                                            : "Are you sure you want to delete this? This action can't be undone."
                                    }
                                    onCancel={() => setIsDeleteOpen(false)}
                                    onDelete={_onDeleteClick}
                                    position={deletePosition}
                                    backdropHeight={backdropHeight}
                                />
                            )}
                        </div>
                    )
                );
            case 'edit':
                return (
                    actionVisible && (
                        <span
                            onClick={isDisabled ? undefined : _onEditClicked}
                            className={classnames(
                                'action-item',
                                `action-${name}`,
                                {
                                    disabled: isDisabled,
                                }
                            )}
                        >
                            {!actionDisabled ? (
                                <>
                                    <SVGSprite
                                        icon={iconClass}
                                        aria-hidden='true'
                                    />
                                    <span
                                        className={classnames({
                                            disabledToolTipText: isDisabled,
                                            tooltiptext: !isDisabled,
                                        })}
                                    >
                                        {tooltip || 'Edit'}
                                    </span>
                                </>
                            ) : (
                                <RelativeToolTip
                                    {...commonTooltipProps}
                                    svgStyle={{
                                        cursor: 'not-allowed',
                                    }}
                                    portalLeft={10}
                                    marginRight={'0px !important'}
                                />
                            )}
                        </span>
                    )
                );
            case 'custom':
                return addCustomClasses ? (
                    <span
                        className={classnames('action-item', `action-${name}`, {
                            disabled: isDisabled,
                        })}
                    >
                        {renderCustom(row, index)}
                    </span>
                ) : (
                    renderCustom(row, index)
                );
            case 'more':
                return options.filter((item) => item?.actionVisible === true)
                    .length > 0 ? (
                    <span
                        className={classnames('action-item action-more', {
                            disabled: disabled,
                        })}
                        ref={ref}
                    >
                        <label onClick={_dotMenuClicked} className={'mb-0'}>
                            <SVGSprite
                                icon={'icon-Dot-menu-Icon'}
                                style={{ paddingRight: 8 }}
                                aria-hidden='true'
                            />
                        </label>
                        <DropdownUList
                            className={`more-dropdown mb-0 ${
                                !disabled && isComponentVisible && 'show'
                            }`}
                        >
                            {options &&
                                options.map((item, index) => {
                                    switch (item.actionType) {
                                        case 'custom': {
                                            return item?.actionVisible ? (
                                                <li
                                                    key={
                                                        'action-subItem_' +
                                                        index
                                                    }
                                                    className={
                                                        item?.actionDisabled
                                                            ? 'disabled'
                                                            : ''
                                                    }
                                                >
                                                    <label
                                                        className={
                                                            'd-flex align-item-center'
                                                        }
                                                        onClick={
                                                            item?.actionDisabled
                                                                ? noop
                                                                : () =>
                                                                      _onCustomActionClicked(
                                                                          item
                                                                      )
                                                        }
                                                    >
                                                        {item.iconClass &&
                                                            (!item?.actionDisabled ? (
                                                                <SVGSprite
                                                                    style={{
                                                                        width:
                                                                            '15px',
                                                                        height:
                                                                            '15px',
                                                                        marginRight: 5,
                                                                    }}
                                                                    icon={
                                                                        item.iconClass
                                                                    }
                                                                    aria-hidden='true'
                                                                />
                                                            ) : (
                                                                <RelativeToolTip
                                                                    {...commonTooltipProps}
                                                                    minWidth={
                                                                        actionDisabledTooltipMinWidth
                                                                    }
                                                                    svgIcon={
                                                                        item.iconClass
                                                                    }
                                                                    svgHeight='15px'
                                                                    svgWidth='15px'
                                                                    svgStyle={{
                                                                        cursor:
                                                                            'not-allowed',
                                                                        width:
                                                                            '15px',
                                                                        height:
                                                                            '15px',
                                                                    }}
                                                                    showStyledSvgSprite={
                                                                        true
                                                                    }
                                                                />
                                                            ))}
                                                        {item.title}
                                                    </label>
                                                </li>
                                            ) : (
                                                <></>
                                            );
                                        }
                                        case 'delete': {
                                            return item?.actionVisible ? (
                                                <li
                                                    key={
                                                        'action-subItem_' +
                                                        index
                                                    }
                                                    className={
                                                        item?.actionDisabled
                                                            ? 'disabled'
                                                            : ''
                                                    }
                                                >
                                                    <label
                                                        className={
                                                            'd-flex align-item-center'
                                                        }
                                                        onClick={
                                                            !item?.actionDisabled
                                                                ? () =>
                                                                      _onDeleteItemClicked(
                                                                          item
                                                                      )
                                                                : noop
                                                        }
                                                    >
                                                        {item.iconClass &&
                                                            (!item?.actionDisabled ? (
                                                                <SVGSprite
                                                                    style={{
                                                                        width:
                                                                            '15px',
                                                                        height:
                                                                            '15px',
                                                                        marginRight: 5,
                                                                    }}
                                                                    icon={
                                                                        item.iconClass
                                                                    }
                                                                    aria-hidden='true'
                                                                />
                                                            ) : (
                                                                <RelativeToolTip
                                                                    {...commonTooltipProps}
                                                                    minWidth={
                                                                        actionDisabledTooltipMinWidth
                                                                    }
                                                                    svgIcon={
                                                                        item.iconClass
                                                                    }
                                                                    svgHeight='15px'
                                                                    svgWidth='15px'
                                                                    svgStyle={{
                                                                        cursor:
                                                                            'not-allowed',
                                                                        width:
                                                                            '15px',
                                                                        height:
                                                                            '15px',
                                                                    }}
                                                                    showStyledSvgSprite={
                                                                        true
                                                                    }
                                                                />
                                                            ))}
                                                        {item.title}
                                                    </label>
                                                    {isDeleteOpen && (
                                                        <DeletePopup
                                                            title={
                                                                item.deletePopup
                                                                    .title
                                                            }
                                                            message={
                                                                item.deletePopup
                                                                    .message
                                                            }
                                                            onCancel={
                                                                _onDeleteCancelClicked
                                                            }
                                                            onDelete={(row) =>
                                                                _onDeleteConfirmClicked(
                                                                    item
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </li>
                                            ) : (
                                                <></>
                                            );
                                        }
                                        case 'copy': {
                                            return (
                                                <li
                                                    key={
                                                        'action-subItem_' +
                                                        index
                                                    }
                                                >
                                                    <CopyToClipboard
                                                        options={{
                                                            format:
                                                                'text/plain',
                                                        }}
                                                        text={
                                                            row?.download_link
                                                        }
                                                        onCopy={_onCopied}
                                                    >
                                                        <label
                                                            className={
                                                                'd-flex align-item-center'
                                                            }
                                                        >
                                                            {item.iconClass && (
                                                                <SVGSprite
                                                                    style={{
                                                                        width:
                                                                            '15px',
                                                                        height:
                                                                            '15px',
                                                                        marginRight: 5,
                                                                    }}
                                                                    icon={
                                                                        item.iconClass
                                                                    }
                                                                    aria-hidden='true'
                                                                />
                                                            )}
                                                            {item.title}
                                                        </label>
                                                    </CopyToClipboard>
                                                </li>
                                            );
                                        }
                                        default: {
                                            return (
                                                <li
                                                    onClick={
                                                        item.disabled
                                                            ? undefined
                                                            : () =>
                                                                  item.onClick(
                                                                      stripPrivateKeys(
                                                                          row
                                                                      )
                                                                  )
                                                    }
                                                    key={
                                                        'action-subItem_' +
                                                        index
                                                    }
                                                >
                                                    {item.iconClass && (
                                                        <SVGSprite
                                                            style={{
                                                                width: '15px',
                                                                height: '15px',
                                                                marginRight: 5,
                                                            }}
                                                            icon={
                                                                item.iconClass
                                                            }
                                                            aria-hidden='true'
                                                        />
                                                    )}
                                                    <label
                                                        className={
                                                            'd-flex align-item-center'
                                                        }
                                                    >
                                                        {item.title}
                                                    </label>
                                                </li>
                                            );
                                        }
                                    }
                                })}
                        </DropdownUList>
                    </span>
                ) : (
                    <></>
                );
            default:
                return (
                    actionVisible && (
                        <span
                            onClick={
                                isDisabled
                                    ? undefined
                                    : () => {
                                          onClick(stripPrivateKeys(row));
                                      }
                            }
                            className={classnames(
                                'action-item',
                                `action-${name}`,
                                {
                                    disabled: isDisabled,
                                }
                            )}
                        >
                            {actionDisabled ? (
                                <RelativeToolTip
                                    {...commonTooltipProps}
                                    minWidth={actionDisabledTooltipMinWidth}
                                    svgStyle={{
                                        cursor: 'not-allowed',
                                        width: '20px',
                                        height: '20px',
                                    }}
                                    showStyledSvgSprite={true}
                                />
                            ) : (
                                <>
                                    <SVGSprite
                                        icon={iconClass}
                                        aria-hidden='true'
                                    />
                                    <span
                                        className={classnames({
                                            disabledToolTipText:
                                                !!resourceUnavailableTooltipText &&
                                                isDisabled,
                                            tooltiptext: true,
                                        })}
                                    >
                                        {isDisabled &&
                                        resourceUnavailableTooltipText
                                            ? resourceUnavailableTooltipText
                                            : tooltiptext}
                                    </span>
                                </>
                            )}
                        </span>
                    )
                );
        }
    };
    return renderAction();
};

ActionItem.propTypes = {
    name: PropTypes.string,
    iconClass: PropTypes.string,
    onClick: PropTypes.func,
    isDisabled: PropTypes.func,
};

const DropdownUList = styled.ul`
    background-color: ${({ theme }) =>
        theme?.isLightTheme
            ? theme.colors.ambience[22]
            : theme.colors.ambience[24]} !important;

    li {
        label {
            &:hover {
                border-radius: 4px;
                background-color: ${({ theme }) =>
                    theme?.isLightTheme
                        ? theme.colors.ambience[20]
                        : theme.colors.ambience[22]} !important;
            }
        }
    }
`;

export default ActionItem;
