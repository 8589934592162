import { logger } from './logger';

export const CLOUD_HOST_API_LOG_PREFIX = 'CLOUD_HOST_API_FAILED';

export const isHeadlessBrowser = () => {
    try {
        return (
            /\bHeadlessChrome\//.test(navigator.userAgent) ||
            process.env.REACT_APP_ENV === 'dev'
        );
    } catch (err) {
        return false;
    }
};

export const logErrorForCH = (endpoint, err) => {
    if (isHeadlessBrowser()) {
        logger.error(
            `${CLOUD_HOST_API_LOG_PREFIX}:${process.env.REACT_APP_ENV} - ${endpoint} - ${err}`
        );
    }
};
