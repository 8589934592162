import { dequal } from 'dequal/lite';
import { ALL_FEATURES_CONFIG } from 'hooks/useFeatureControl';
import useFirebaseClient from 'hooks/useFirebaseClient';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { QueueType } from 'services/realtime/types';
import { getConnectViaFirebasePrimaryShardsSelector } from 'store/selectors';
import { DATA_SOURCE, FEATURE_NAMES } from 'utils/constants/featureNames';
import PermissionUtils from 'utils/permission-utils';
import UserRoles from 'utils/userRoles';
import { isAttendee } from 'utils/users';
import useDataWriter from '../useDataWriter';
import { logger } from 'utils/logger';
interface FirebaseClientConfig {
    writeReplicas: Array<any>;
    tag: string;
    source: string;
    shardInfo: any;
    primary: boolean;
    advancedConfig: any;
    isLongPollingDisabled?: boolean;
    firebaseProxyConfig?: any;
}

interface RealtimeServerConfiguration {
    shardConfig: { [x: string]: any };
    firebaseReplicas: any;
    firebaseProxyConfig: any;
}
interface FirebaseClientConfigRequest {
    realtimeServersConfiguration: RealtimeServerConfiguration;
    feature: string;
    replicaWriter?: Function;
    defaultShard?: string;
    isLongPollingDisabled?: boolean;
}

let lastUsedConfiguration: RealtimeServerConfiguration;

const findReplicas = (
    replicaConfig: { [x: string]: any[] },
    featureName: string | number,
    shardSelectedForUser: { shardId: string | number },
    defaultShard = 'primary'
) => {
    const isPrimaryReplicationDisabled =
        replicaConfig?.[featureName]?.[0]?.disablePrimaryReplication || false;

    const primaryReplicas = replicaConfig?.[defaultShard] || [];
    const replicas = {};

    if (false === isPrimaryReplicationDisabled) {
        for (let primary of primaryReplicas) {
            replicas[primary.shardId] = primary;
        }
    }

    const featureReplicas = replicaConfig?.[featureName] || [];

    for (let replica of featureReplicas) {
        replicas[replica.shardId] = replica;
    }

    // avoid circular loop
    delete replicas[shardSelectedForUser?.shardId];

    return Object.values(replicas); // include primary and all others except
};

function firebaseConfig({
    realtimeServersConfiguration,
    feature,
    replicaWriter,
    defaultShard = 'primary',
    isLongPollingDisabled,
}: FirebaseClientConfigRequest): FirebaseClientConfig {
    if (!realtimeServersConfiguration) {
        return null;
    }

    const featureShardInfo =
        realtimeServersConfiguration?.shardConfig?.[feature];
    if (
        featureShardInfo?.type &&
        featureShardInfo.type !== DATA_SOURCE.FIREBASE &&
        process.env.REACT_APP_ENV !== 'production'
    ) {
        return null;
    }

    let shardSelectedForUser =
        realtimeServersConfiguration?.shardConfig?.[feature] ||
        realtimeServersConfiguration?.shardConfig?.[defaultShard];
    if (shardSelectedForUser?.shardId) {
        shardSelectedForUser.shardType = realtimeServersConfiguration
            ?.shardConfig?.[feature]
            ? feature
            : defaultShard;
    }

    const firebaseReplicaConfig =
        realtimeServersConfiguration?.firebaseReplicas;

    const writeReplicas = replicaWriter
        ? null
        : findReplicas(
              firebaseReplicaConfig,
              feature,
              shardSelectedForUser,
              defaultShard
          );
    if (realtimeServersConfiguration?.shardConfig?.[feature]) {
        logger.info(`Feature shard assigned for ${feature}`, {
            shardSelectedForUser: shardSelectedForUser?.shardId,
        });
    }

    return {
        writeReplicas,
        tag: shardSelectedForUser?.shardId,
        source: feature,
        shardInfo: shardSelectedForUser,
        primary: false,
        advancedConfig: replicaWriter ? { writer: replicaWriter } : null,
        isLongPollingDisabled,
        firebaseProxyConfig: realtimeServersConfiguration?.firebaseProxyConfig,
    };
}

export default function useFirebaseClientSetup({
    user,
    airmeetId,
    currentAirmeet,
}) {
    currentAirmeet = {
        ...currentAirmeet,
    };

    const firebaseProxyConfig = currentAirmeet?.firebaseProxyConfig;
    const airmeetVisitId = useSelector(({ liveAirmeet }) => {
        return liveAirmeet.airmeetVisitId;
    });

    const { write } = useDataWriter(FEATURE_NAMES.DEFAULT, {
        liveContextOverride: {
            featuresConfig: {
                [ALL_FEATURES_CONFIG.DATA_WRITER_CONFIG]: {
                    applyToAll: true,
                },
            },
            airmeet: {
                airmeetId,
                data: { currentAirmeet, userRole: user.role },
            },
            user,
            featureDataClients: {},
        },
        useFrontendWriterFallback: false,
        queueType: QueueType.DEDICATED,
    });

    const realtimeServersConfiguration = useMemo<
        RealtimeServerConfiguration
    >(() => {
        if (!currentAirmeet) {
            return null;
        }
        let newConfig: RealtimeServerConfiguration = {
            shardConfig: currentAirmeet.shardConfig,
            firebaseReplicas: currentAirmeet.firebaseReplicas,
            firebaseProxyConfig: firebaseProxyConfig,
        };

        if (
            lastUsedConfiguration &&
            dequal(
                lastUsedConfiguration.firebaseReplicas,
                newConfig.firebaseReplicas
            )
        ) {
            return lastUsedConfiguration;
        }

        lastUsedConfiguration = newConfig;
        return lastUsedConfiguration;
    }, [currentAirmeet, firebaseProxyConfig]);

    const hasDefaultShards = Boolean(
        realtimeServersConfiguration?.shardConfig?.[FEATURE_NAMES.DEFAULT]
    );

    const replicaWriter = hasDefaultShards ? write : null;

    const userStageRole = UserRoles.getUserSessionRole();
    const connectViaFirebasePrimaryShards = useSelector(
        getConnectViaFirebasePrimaryShardsSelector
    );

    const isLongPollingDisabled = currentAirmeet?.is_long_polling_disabled;

    const mainReplicaOptions: FirebaseClientConfig = useMemo(() => {
        if (!userStageRole) {
            return null;
        }
        logger.info(
            `Initialising/re-initialising main replica for user : ${user.id} due to change in `,
            {
                params: {
                    connectViaFirebasePrimaryShards,
                    userStageRole,
                },
                meta: {
                    airmeetId: airmeetId,
                    airmeetVisitId,
                },
            }
        );
        let options: FirebaseClientConfig;
        if (
            isAttendee(userStageRole) &&
            !connectViaFirebasePrimaryShards &&
            !PermissionUtils.isEventCloudHost()
        ) {
            options = firebaseConfig({
                realtimeServersConfiguration,
                feature: hasDefaultShards ? FEATURE_NAMES.DEFAULT : 'primary',
                replicaWriter,
            });
        } else {
            options = firebaseConfig({
                realtimeServersConfiguration,
                feature: 'primary',
                replicaWriter,
            });
        }
        if (options) {
            options.primary = true;
            options.isLongPollingDisabled = isLongPollingDisabled;
        }
        return options;
    }, [
        realtimeServersConfiguration,
        userStageRole,
        connectViaFirebasePrimaryShards,
        replicaWriter,
        hasDefaultShards,
        isLongPollingDisabled,
    ]);

    const defaultShard = mainReplicaOptions?.source;

    const firebaseClient = useFirebaseClient(
        user.id,
        airmeetId,
        mainReplicaOptions?.shardInfo,
        mainReplicaOptions
    );

    const chatOptions = useMemo(() => {
        return firebaseConfig({
            realtimeServersConfiguration,
            feature: 'chat',
            defaultShard,
            isLongPollingDisabled,
        });
    }, [realtimeServersConfiguration, defaultShard, isLongPollingDisabled]);

    const reactionsOptions = useMemo(() => {
        return firebaseConfig({
            realtimeServersConfiguration,
            feature: 'reactions',
            defaultShard,
            isLongPollingDisabled,
        });
    }, [realtimeServersConfiguration, defaultShard, isLongPollingDisabled]);

    const tableOptions = useMemo(() => {
        return firebaseConfig({
            realtimeServersConfiguration,
            feature: FEATURE_NAMES.LOUNGE_TABLES,
            defaultShard,
            isLongPollingDisabled,
        });
    }, [realtimeServersConfiguration, defaultShard, isLongPollingDisabled]);

    const boothTableOptions = useMemo(() => {
        return firebaseConfig({
            realtimeServersConfiguration,
            feature: FEATURE_NAMES.BOOTH_TABLES,
            defaultShard,
            isLongPollingDisabled,
        });
    }, [realtimeServersConfiguration, defaultShard, isLongPollingDisabled]);

    const breakoutTableOptions = useMemo(() => {
        return firebaseConfig({
            realtimeServersConfiguration,
            feature: FEATURE_NAMES.BREAKOUT_TABLES,
            defaultShard,
            isLongPollingDisabled,
        });
    }, [realtimeServersConfiguration, defaultShard, isLongPollingDisabled]);

    const stageOptions = useMemo(() => {
        return firebaseConfig({
            realtimeServersConfiguration,
            feature: FEATURE_NAMES.STAGE,
            defaultShard,
            isLongPollingDisabled,
        });
    }, [realtimeServersConfiguration, defaultShard, isLongPollingDisabled]);

    const fluidSpaceOptions = useMemo(() => {
        return firebaseConfig({
            realtimeServersConfiguration,
            feature: 'fluidSpace',
            defaultShard,
            isLongPollingDisabled,
        });
    }, [realtimeServersConfiguration, defaultShard, isLongPollingDisabled]);

    const userProfileOptions = useMemo(() => {
        return firebaseConfig({
            realtimeServersConfiguration,
            feature: FEATURE_NAMES.USER_PROFILE,
            defaultShard,
            isLongPollingDisabled,
        });
    }, [realtimeServersConfiguration, defaultShard, isLongPollingDisabled]);

    const userPresenceOptions = useMemo(() => {
        return firebaseConfig({
            realtimeServersConfiguration,
            feature: FEATURE_NAMES.USER_PRESENCE,
            defaultShard,
            isLongPollingDisabled,
        });
    }, [realtimeServersConfiguration, defaultShard, isLongPollingDisabled]);

    const chatFirebaseClient = useFirebaseClient(
        user.id,
        airmeetId,
        chatOptions?.shardInfo,
        chatOptions
    );

    const reactionsFirebaseClient = useFirebaseClient(
        user.id,
        airmeetId,
        reactionsOptions?.shardInfo,
        reactionsOptions
    );

    let stageFirebaseClient = useFirebaseClient(
        user.id,
        airmeetId,
        stageOptions?.shardInfo,
        stageOptions
    );

    const tableFirebaseClient = useFirebaseClient(
        user.id,
        airmeetId,
        tableOptions?.shardInfo,
        tableOptions
    );

    const fluidSpaceClient = useFirebaseClient(
        user.id,
        airmeetId,
        fluidSpaceOptions?.shardInfo,
        fluidSpaceOptions
    );

    const userProfileClient = useFirebaseClient(
        user.id,
        airmeetId,
        userProfileOptions?.shardInfo,
        userProfileOptions
    );

    const userPresenceClient = useFirebaseClient(
        user.id,
        airmeetId,
        userPresenceOptions?.shardInfo,
        userPresenceOptions
    );

    const boothTablesClient = useFirebaseClient(
        user.id,
        airmeetId,
        boothTableOptions?.shardInfo,
        boothTableOptions
    );

    const breakoutTablesClient = useFirebaseClient(
        user.id,
        airmeetId,
        breakoutTableOptions?.shardInfo,
        breakoutTableOptions
    );

    stageFirebaseClient = currentAirmeet?.shardConfig?.[FEATURE_NAMES.STAGE]
        ? stageFirebaseClient
        : firebaseClient;

    return {
        firebaseClient,
        chatFirebaseClient,
        reactionsFirebaseClient,
        stageFirebaseClient,
        tableFirebaseClient,
        fluidSpaceClient,
        userProfileClient,
        userPresenceClient,
        boothTablesClient,
        breakoutTablesClient,
    };
}
