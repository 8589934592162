import {
    FETCH_LEADERBOARD_ACTIONS_RESPONSE,
    FETCH_LEADERBOARD_HIGHLIGHTS_RESPONSE,
    FETCH_LEADERBOARD_PRIZE_DETAILS_RESPONSE,
    FETCH_LIVE_LEADERBOARD_RESPONSE,
    UPDATE_LEADERBOARD_ENABLED_STATE,
    UPDATE_LEADERBOARD_STATUS_STATE,
} from '../actions/Leaderboard';

const initialState = {
    liveLeaderboard: [],
    leaderboardStatus: '',
    prizeDetails: {},
    userActions: {},
    hasMorePages: false,
    userScore: '',
    userRank: '',
    isLeaderboardEnabled: false,
    hightlights: [],
    pointsLabel: '',
};

function leaderboard(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FETCH_LIVE_LEADERBOARD_RESPONSE:
            if (payload.data.pageNo === 1) {
                return {
                    ...state,
                    liveLeaderboard: payload.data.liveUserScores,
                    userScore: payload.data.userScore,
                    userRank: payload.data.userRank,
                    hasMorePages:
                        Math.ceil(payload.data.totalResults / 100) >
                        payload.data.pageNo
                            ? true
                            : false,
                    pointsLabel: payload.data.pointsLabel,
                };
            } else {
                return {
                    ...state,
                    liveLeaderboard: [
                        ...state.liveLeaderboard,
                        ...payload.data.liveUserScores,
                    ],
                    userScore: payload.data.userScore,
                    userRank: payload.data.userRank,
                    hasMorePages:
                        Math.ceil(payload.data.totalResults / 100) >
                        payload.data.pageNo
                            ? true
                            : false,
                    pointsLabel: payload.data.pointsLabel,
                };
            }
        case UPDATE_LEADERBOARD_STATUS_STATE: {
            return {
                ...state,
                leaderboardStatus: payload,
            };
        }
        case FETCH_LEADERBOARD_PRIZE_DETAILS_RESPONSE: {
            return {
                ...state,
                prizeDetails: payload.data,
            };
        }
        case FETCH_LEADERBOARD_ACTIONS_RESPONSE: {
            return {
                ...state,
                userActions: payload.data,
            };
        }
        case UPDATE_LEADERBOARD_ENABLED_STATE: {
            return {
                ...state,
                isLeaderboardEnabled: payload,
            };
        }
        case FETCH_LEADERBOARD_HIGHLIGHTS_RESPONSE: {
            return {
                ...state,
                hightlights: [...payload.data],
            };
        }
        default:
            return state;
    }
}

export default leaderboard;
