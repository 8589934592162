import { FILE_TYPE_MAP } from 'views/CMS/componentLibrary/MediaLibrary/constants';
import { cdnImage } from './core';

const fixThumbnail = (thumbnail) => {
    for (let i = 0; i < FILE_TYPE_MAP.image.length; i++) {
        if (thumbnail.includes(FILE_TYPE_MAP.image[i])) return thumbnail;
    }
    return cdnImage('/images/video-placeholder.svg');
};

export const featureMediaVideoFilter = (media = []) =>
    media
        .filter((item) => item?.type?.includes('video'))
        .map((item) => {
            return { ...item, thumbnail: fixThumbnail(item.thumbnail || '') };
        });
