import { API_SERVICE } from 'utils/apiService';
import { CALL_API } from '../../api';

export const CREATE_SESSION_REQUEST = 'CM_DASHBOARD/CREATE_SESSION_REQUEST';
export const CREATE_SESSION_RESPONSE = 'CM_DASHBOARD/CREATE_SESSION_RESPONSE';
export const UPDATE_SESSION_REQUEST = 'CM_DASHBOARD/UPDATE_SESSION_REQUEST';
export const UPDATE_SESSION_RESPONSE = 'CM_DASHBOARD/UPDATE_SESSION_RESPONSE';
export const DELETE_SESSION_REQUEST = 'CM_DASHBOARD/DELETE_SESSION_REQUEST';
export const DELETE_SESSION_RESPONSE = 'CM_DASHBOARD/DELETE_SESSION_RESPONSE';
export const SESSION_INCOMPLETE_STATUS = 'SESSION_INCOMPLETE_STATUS';
export const UPDATE_SESSION_VISIBILITY_REQUEST =
    'CM_DASHBOARD/UPDATE_SESSION_VISIBILITY_REQUEST';
export const UPDATE_SESSION_VISIBILITY_RESPONSE =
    'CM_DASHBOARD/UPDATE_SESSION_VISIBILITY_RESPONSE';
export const SESSION_EMPTY_HOST = 'SESSION_EMPTY_HOST';
export const UPDATE_SN_DETAILS_REQUEST = 'UPDATE_SN_DETAILS_REQUEST';
export const UPDATE_SN_DETAILS_SUCCESS = 'UPDATE_SN_DETAILS_SUCCESS';
export const GET_SN_RULES_CONFIG_REQUEST = 'GET_SN_RULES_CONFIG_REQUEST';
export const GET_SN_RULES_CONFIG_SUCCESS = 'GET_SN_RULES_CONFIG_SUCCESS';
export const GET_SESSION_DETAILS_REQUEST = 'GET_SN_DETAILS_REQUEST';
export const GET_SESSION_DETAILS_SUCCESS = 'GET_SN_DETAILS_SUCCESS';
export const GET_VENUE_INFO = 'GET_VENUE_INFO';
export const CREATE_SESSION_VENUE = 'CREATE_SESSION_VENUE';
export const DELETE_SESSION_VENUE = 'DELETE_SESSION_VENUE';
export const UPDATE_SESSION_VENUE = 'UPDATE_SESSION_VENUE';

export function createSession(airmeetId, session) {
    return {
        [CALL_API]: {
            types: [CREATE_SESSION_REQUEST, CREATE_SESSION_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/session`,
            body: session,
            type: 'json',
            method: 'POST',
        },
    };
}

export function updateSession(airmeetId, sessionId, session) {
    return {
        [CALL_API]: {
            types: [UPDATE_SESSION_REQUEST, UPDATE_SESSION_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}`,
            body: session,
            type: 'json',
            method: 'PUT',
        },
    };
}

export function deleteSession(airmeetId, sessionId) {
    return {
        [CALL_API]: {
            types: [DELETE_SESSION_REQUEST, DELETE_SESSION_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}`,
            type: 'json',
            method: 'DELETE',
        },
    };
}

export function updateSessionIncompleteStatus(isIncomplete) {
    return {
        type: SESSION_INCOMPLETE_STATUS,
        payload: isIncomplete,
    };
}
export function updateSessionEmptyHost(isEmpty) {
    return {
        type: SESSION_EMPTY_HOST,
        payload: isEmpty,
    };
}

export function updateSessionVisibility({ airmeetId, sessionId, data }) {
    return {
        [CALL_API]: {
            types: [UPDATE_SESSION_REQUEST, UPDATE_SESSION_RESPONSE],
            endpoint: `/airmeet/${airmeetId}/session/${sessionId}/visibility`,
            body: data,
            type: 'json',
            method: 'PUT',
        },
    };
}

export function getSNDetails({ airmeetId, sessionId }) {
    return {
        [CALL_API]: {
            types: [UPDATE_SN_DETAILS_REQUEST, UPDATE_SN_DETAILS_SUCCESS],
            endpoint: `/airmeet/${airmeetId}/networking/rules?entityId=${sessionId}`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function getSNSessionRulesConfig({ airmeetId }) {
    return {
        [CALL_API]: {
            types: [GET_SN_RULES_CONFIG_REQUEST, GET_SN_RULES_CONFIG_SUCCESS],
            endpoint: `/airmeet/${airmeetId}/networking/rules/configuration`,
            type: 'json',
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}
export function getVenueDetails({ airmeetId, queryParam }) {
    return {
        [CALL_API]: {
            types: [GET_VENUE_INFO],
            endpoint: `/airmeet/${airmeetId}/hybrid-venue`,
            query: {
                entity_type: queryParam,
            },
            type: 'json',
            method: 'GET',
        },
    };
}
export function addNewVenue({ airmeetId, requestData }) {
    return {
        [CALL_API]: {
            types: [CREATE_SESSION_VENUE],
            endpoint: `/airmeet/${airmeetId}/hybrid-venue`,
            body: requestData,
            type: 'json',
            method: 'POST',
        },
    };
}
export function deleteVenueInfo({ airmeetId, venueId }) {
    return {
        [CALL_API]: {
            types: [DELETE_SESSION_VENUE],
            endpoint: `/airmeet/${airmeetId}/hybrid-venue/${venueId}`,
            type: 'json',
            method: 'DELETE',
        },
    };
}
export function updateVenueInfo({ airmeetId, venueId, requestData }) {
    return {
        [CALL_API]: {
            types: [UPDATE_SESSION_VENUE],
            endpoint: `/airmeet/${airmeetId}/hybrid-venue/${venueId}`,
            body: requestData,
            type: 'json',
            method: 'PUT',
        },
    };
}
