import identity from 'lodash/identity';
import { useContext, useMemo } from 'react';
import LiveAirmeet from 'context/LiveAirmeet';
import ILiveAirmeet from 'context/types/liveAirmeet';

/**
 * Accepts a selector function which can be used to select a value at any
 * level of the LiveAirmeet context, like the `useSelector` hook from redux
 *
 * @template T The type of the LiveAirmeet context
 * @param {(context: ILiveAirmeet) => T} callback
 * @param {T} fallbackValue A fallback value if context is null
 */
function useLiveAirmeetContext<T = ILiveAirmeet>(
    callback: (context: ILiveAirmeet) => T = identity,
    fallbackValue?: T
): T {
    // The live airmeet context
    const ctx = useContext(LiveAirmeet) as ILiveAirmeet;

    // Return the up-to-date selected value
    const finalValue = ctx ? callback(ctx) : (fallbackValue as T);
    return useMemo(() => finalValue, [finalValue]);
}

export default useLiveAirmeetContext;
