import Button from 'atoms/Button/button';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import styled from 'styled-components';
import { border } from 'styled-system';
import { select } from 'utils/constants/common';

export const AirGenieTextarea = styled.textarea`
    background-color: transparent;
    ${({ variant, theme }) =>
        variant === 'normal'
            ? `
        border: solid 1px ${theme.colors.ambience[14]};
        border-bottom: solid 1px ${theme.colors.ambience[21]};`
            : `
        border: solid 1px ${theme.colors.ambience[19]};
    `}
    box-shadow: none;
    resize: none;
    color: ${({ theme }) => theme.colors.ambience[0]};
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    min-height: ${({ variant }) => (variant === 'normal' ? '140px' : '250px')};
    flex: 1;
    padding: 16px;
    ${({ variant }) =>
        variant === 'normal'
            ? `
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    `
            : variant === 'left'
            ? `
        border-top-left-radius: 8px;
    `
            : `
        border-top-right-radius: 8px;
        padding: 16px 42px;
    `}
    &::placeholder {
        color: ${({ theme, placeholderColor }) =>
            placeholderColor
                ? select(placeholderColor)(theme)
                : theme.colors.ambience[18]};
    }
    ${border}
`;

export const AirGenieSectionFooter = styled(FlexDiv)`
    align-items: center;
    border: solid 1px ${({ theme }) => theme.colors.ambience[19]};
    border-top: 0;
    padding: 0 16px;
    height: 68px;
    ${({ variant }) =>
        variant === 'normal'
            ? `
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    `
            : variant === 'left'
            ? `
        border-bottom-left-radius: 8px;
    `
            : `
        border-bottom-right-radius: 8px;
    `}
    ${border}
`;

export const GenButton = styled(Button)`
    width: auto;
    height: 36px;
    padding: 0 12px;
    ${({ theme, color }) =>
        theme.isLightTheme && color
            ? `
                svg path {
                    fill: ${select(color)(theme.colors)};
                }
            `
            : ''}
`;

export const TonePill = styled(Typography)`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 12px;
    min-height: 32px;
    margin-right: 8px;
    border-radius: 16px;
    border: 1px solid
        ${({ theme, isChecked }) =>
            isChecked
                ? theme.colors.accentPrimary[0]
                : theme.colors.ambience[15]};
    background-color: ${({ theme }) => theme.colors.ambience[21]};
`;

export const ArrowBtn = styled(Button)`
    position: absolute;
    top: 30px;
    padding: 16px;
    ${({ type }) =>
        type === 'next'
            ? `
        right: 0;
    `
            : `
        left: 0;
    `}
    z-index: 5;
`;

export const ImagesWrapper = styled(FlexDiv)`
    flex: 1;
    align-items: center;
    justify-content: center;
    border: solid 1px ${({ theme }) => theme.colors.ambience[19]};
    min-height: 250px;
    position: relative;
`;

export const ImgLoaderWrapper = styled(FlexDiv)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    align-items: center;
    justify-content: center;
    z-index: 2;
`;
