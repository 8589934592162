import { EventEmitter } from 'events';
import { bindModuleLogger } from 'utils/logger';

const logger = bindModuleLogger('Local Reaction');

class LocalReaction {
    private static service: LocalReaction;
    private emitter: EventEmitter;

    constructor() {
        this.emitter = new EventEmitter();
    }

    static getInstance(): LocalReaction {
        if (LocalReaction.service) {
            return LocalReaction.service;
        }
        LocalReaction.service = new LocalReaction();
        return LocalReaction.service;
    }

    subscribe(onAdd) {
        logger.debug('Subscribe local reaction');
        this.emitter.on('location_reaction_added', onAdd);
        return () => {
            this.emitter.off('location_reaction_added', onAdd);
        };
    }

    write(payload) {
        logger.debug('Adding local reaction');
        return this.emitter.emit('location_reaction_added', payload);
    }
}
export default LocalReaction;
