import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { getCustomScroll } from 'components/molecules/utils/customStyles';
import { IconVersion } from 'context/ThemeProvider';
import { alpha } from 'foundations/theme';
import useToasts from 'hooks/useToasts';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadBase64Image } from 'store/actions/fileUplaoder';
import styled, { createGlobalStyle } from 'styled-components';
import { TOAST_VERSION_2 } from 'utils/constants/toasts';
import { s3toCDNImage } from 'utils/core';
import animationData from '../../../lotties/overlayLoader.json';
import { generateIcons } from './utils';

const JoditEditor = React.lazy(() => import('jodit-react'));
const Lottie = React.lazy(() => import('react-lottie'));

const StyledJoditWrapper = styled.div`
    margin: 20px 0;
    padding: 10px;
    .jodit_dark_theme .jodit_wysiwyg {
        background-color: #27272e;
        color: rgba(255, 255, 255, 0.5);
    }

    .jodit-toolbar {
        &-button {
            border: 0;
            &:hover {
                background: ${({ theme }) =>
                    theme?.isLightTheme
                        ? theme.colors.ambience[20]
                        : theme.colors.ambience[15]};
                .jodit-toolbar-button__trigger {
                    &:before {
                        border-top: 4px solid
                            ${({ theme }) => theme.colors.ambience[9]};
                    }
                }
            }
            &__trigger,
            &__button {
                background: transparent !important;
            }

            &__trigger {
                opacity: 1;
                svg {
                    display: none;
                }
                &:before {
                    content: '';
                    width: 0;
                    height: 0;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid
                        ${({ theme }) => theme.colors.ambience[14]};
                }
            }
            &__button {
                .jodit-toolbar-button__icon {
                    svg {
                        fill: ${({ theme }) =>
                            theme?.isLightTheme
                                ? theme.colors.ambience[14]
                                : theme.colors.ambience[9]};
                    }
                }
            }
        }
    }
    .jodit-toolbar__box {
        .jodit-toolbar-editor-collection {
            > .jodit-ui-group {
                padding-left: 6px;
                padding-right: 6px;
                padding-top: 4px;
                padding-bottom: 4px;
            }
        }
    }
    pre {
        color: ${({ theme }) => theme.colors.ambience[0]};
        white-space: normal;
    }
    blockquote {
        font-style: italic;
    }
    .jodit-wysiwyg {
        cursor: default;
        padding-right: 6px;

        * {
            white-space: normal !important;
            cursor: auto;
        }

        ${({ theme, alwaysShowScroll = true }) =>
            getCustomScroll({ theme, alwaysShowScroll })};
    }
    .jodit-add-new-line_after {
        svg {
            display: none;
        }
    }
`;

const StyledFlexDiv = styled(FlexDiv)`
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => alpha(theme.colors.black, 0.5)};
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
`;

const useUploadEditorImage = (setLoading, enableImageUploadLoader) => {
    const dispatch = useDispatch();
    const { warningToast, errorToast } = useToasts();
    return (editorInstance) => {
        editorInstance.events.on('afterPaste', () => {
            const getElement = document.getElementsByClassName(
                'jodit-custom-css-class'
            );
            const getElementHeight = getElement[0].scrollHeight;
            getElement[0].scrollTo(0, getElementHeight);
        });

        editorInstance.events.on('errorMessage', (error) => {
            errorToast({
                title: 'Invalid image format',
                message:
                    error === 'Need URL for AJAX request'
                        ? 'SVG is not supported. Please upload a png or a jpeg/jpg image.'
                        : error,
                version: TOAST_VERSION_2,
            });
        });

        editorInstance.events.on('afterInsertImage', async function (image) {
            const base64Data = image.src;
            if (base64Data.startsWith('data:')) {
                if (enableImageUploadLoader) setLoading(true);
                const response = await dispatch(
                    uploadBase64Image(null, base64Data, 'long-desc')
                );
                if (!response.error) {
                    const imagePath = response?.payload?.file?.url;
                    image.src = s3toCDNImage(imagePath);
                } else {
                    warningToast({
                        title: 'Image upload failed',
                        message:
                            'Could not upload image, please check your image format',
                        version: TOAST_VERSION_2,
                    });
                }
                if (enableImageUploadLoader) setLoading(false);
            }
        });
    };
};

const ToolbarButtons = [
    'undo',
    'redo',
    '|',
    'fontsize',
    'font',
    'paragraph',
    'brush',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'ul',
    'ol',
    '|',
    'image',
    'link',
    'symbols',
    '|',
    'left',
    'center',
    'right',
    'justify',
    '|',
    {
        name: '',
        exec: (editor) => {
            editor.toggleFullSize();
        },
        update(button) {
            const editor = button.j,
                mode = editor.isFullSize ? 'shrink' : 'fullsize';
            button.state.activated = editor.isFullSize;
            if (editor.o.textIcons) {
                button.state.text = mode;
            } else {
                button.state.icon.name = mode;
            }
            button.state.tooltip = editor.isFullSize
                ? 'Exit FullScreen'
                : 'Open FullScreen';
        },
    },
];

const defaultConfig = {
    events: {
        getIcon: generateIcons,
        afterGenerateColorPicker: (evt) => {
            setTimeout(() => {
                if (
                    document.getElementsByClassName(
                        'jodit-color-picker__native'
                    ).length
                ) {
                    const element = document.querySelector(
                        '.jodit-color-picker__native input'
                    );
                    const currentColor = element.getAttribute('value');

                    for (
                        let i = 0;
                        i <
                        document.getElementsByClassName(
                            'jodit-color-picker__native'
                        ).length;
                        i++
                    ) {
                        document.getElementsByClassName(
                            'jodit-color-picker__native'
                        )[i].innerHTML = `
                            <p class="color-custom-title">custom</p>
                            <div class="customColorPickerWrapper">
                                <input type="color" value="${currentColor}" id="customColorPicker_${i}" />
                                <label for="customColorPicker_${i}">
                                    <span style="background: ${currentColor}"></span>

                                    <svg class="icon plus" style="fill: #565365;">
                                        <use xlink:href="/images/icons.svg?v=${IconVersion}#plus">
                                        </use>
                                    </svg>
                                </label>
                            </div>`;
                    }
                }
            }, 2000);
        },
    },
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    height: '350px',
    editorCssClass: 'jodit-custom-css-class',
    width: '100%',
    enableDragAndDropFileToEditor: true,
    uploader: {
        insertImageAsBase64URI: true,
        imagesExtensions: ['png', 'jpeg', 'jpg', 'gif', 'webp'],
    },
    removeButtons: [
        'file',
        'hr',
        'copyformat',
        'source',
        'print',
        'selectall',
        'table',
        'eraser',
        'fullsize',
    ],
    toolbarAdaptive: false,
    toolbarSticky: false,
    allowResizeX: false,
    allowResizeY: false,
    statusbar: false,
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    defaultActionOnPaste: 'insert_as_html',
    colorPickerDefaultTab: 'color',
    maxWidth: '100%',
    useNativeTooltip: true,
    buttons: ToolbarButtons,
};

const JoditEditorWrapper = ({
    editorContent,
    onChangeHandler = () => {},
    config = {},
    enableImageUploadLoader = false,
    style = {},
}) => {
    const [isLoading, setLoading] = useState(false);
    const editor = useUploadEditorImage(setLoading, enableImageUploadLoader);
    const [content] = useState(editorContent);
    const addHttpsToLinks = (content) => {
        const updatedDescription = content.replace(
            /href="(?:www\.)?([^"\s]+)"/g,
            function (match, p1) {
                if (!p1.startsWith('http')) {
                    return `href="https://${p1}"`;
                } else {
                    return match;
                }
            }
        );
        return updatedDescription;
    };
    const handleChange = (val) => {
        // there is an issue in the lib for onBlur, it eonds back with the FocusEvent instead of the value
        // https://github.com/jodit/jodit-react/issues/110
        const updatedDescription = addHttpsToLinks(val);
        onChangeHandler(updatedDescription);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    const editorConfig = { ...defaultConfig, ...(config || {}) };

    return useMemo(
        () => (
            // usage of useMemo to not let component render onChange, onBlur solves but has the issue; read more on this thread : https://github.com/jodit/jodit-react/issues/43
            <StyledJoditWrapper style={style}>
                <GlobalEditorStyles />
                <>
                    {isLoading && (
                        <StyledFlexDiv>
                            <Lottie
                                options={defaultOptions}
                                height={'100px'}
                                width={'100px'}
                                isClickToPauseDisabled={true}
                            />
                        </StyledFlexDiv>
                    )}
                    <JoditEditor
                        editorRef={editor}
                        value={content}
                        config={editorConfig}
                        className='jodit-custom-css-class'
                        tabIndex={1} // tabIndex of textarea
                        // onBlur={({ target }) => onChangeHandler(target.innerHTML)} // preferred to use only this option to update the content for performance reasons
                        onChange={handleChange}
                    />
                </>
            </StyledJoditWrapper>
        ),
        [isLoading]
    );
};

export default JoditEditorWrapper;

const GlobalEditorStyles = createGlobalStyle`
    .jodit-container.jodit_theme_default {
        background-color: ${({ theme }) =>
            theme.colors.ambience[theme?.isLightTheme ? 24 : 20]};
        border: ${({ theme }) =>
            theme?.isLightTheme
                ? `solid 1px ${theme.colors.ambience[15]}`
                : '0'};
        border-radius: 8px;

        .jodit-toolbar__box {
            border-radius: 8px 8px 0 0;
        }

        .jodit-toolbar__box:not(:empty) {
            background: transparent;
            border: 0;
        }
        .jodit-toolbar-editor-collection {
            color: ${({ theme }) => theme.colors.ambience[1]};
            .jodit-toolbar-button__button:hover {
                color: ${({ theme }) => theme.colors.ambience[9]};
                background: ${({ theme }) => theme.colors.ambience[16]};
            }
            .jodit-toolbar-button[aria-pressed='true'] {
                background: ${({ theme }) =>
                    theme?.isLightTheme
                        ? theme.colors.ambience[18]
                        : theme.colors.ambience[16]} !important;
                .jodit-toolbar-button__trigger {
                    &:before {
                        border-top: 4px solid
                            ${({ theme }) => theme.colors.ambience[9]};
                    }
                }
            }
            .jodit-toolbar-button__button[aria-pressed='true'] {
                background: ${({ theme }) =>
                    theme?.isLightTheme
                        ? theme.colors.ambience[18]
                        : theme.colors.ambience[16]} !important;
                border-radius: 4px;
            }
            .jodit-toolbar-button {
                margin: 4px 1px;
            }
        }

        .jodit-toolbar-editor-collection_mode_horizontal:after {
            background: transparent;
        }

        .jodit-toolbar-button__trigger:hover:not([disabled]) {
            background: ${({ theme }) => theme.colors.ambience[16]};
        }

        .jodit-workplace {
            border-radius: 0px 0px 8px 8px;
        }

        .jodit-custom-css-class {
            hr {
                background-color: ${({ theme }) =>
                    theme.colors.border.default1};
                color: red;
            }
            ul {
                display: block;
                list-style-type: disc;
                -webkit-margin-before: 1em;
                -webkit-margin-after: 1em;
                -webkit-margin-start: 0px;
                -webkit-margin-end: 0px;
                -webkit-padding-start: 40px;
            }
            background-color: ${({ theme }) => theme.colors.fill.default2};
            color: ${({ theme }) => theme.colors.text.default.primary};
        }

        .jodit-ui-group {
            background: ${({ theme }) => theme.colors.fill.default1};
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .jodit-ui-group {
                justify-content: flex-start;
            }
        }

        .jodit-toolbar-button .jodit-icon {
            stroke: none !important;
            border-radius: 4px;
            height: 20px;
            width: 20px;
            margin-bottom: 0;
        }

        .jodit-toolbar-button:hover {
            border-radius: 4px;
        }

        .jodit-ui-separator {
            border-right: 1px solid ${({ theme }) =>
                theme.colors.border.default1};
            height: 16px;
            margin: 0 3px;
        }
    }

    .jodit-popup-container {
        .jodit-popup {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }
    }

    .jodit-popup-container .jodit-popup__content {
        padding: 12px;
        border-radius: 8px;
        background-color: ${({ theme }) => theme.colors.ambience[19]};
        color: ${({ theme }) => theme.colors.black};
        ${({ theme, alwaysShowScroll = true }) =>
            getCustomScroll({ theme, alwaysShowScroll })};

        .jodit-ui-input__label {
            color: ${({ theme }) => theme.colors.ambience[9]};
        }

        .jodit-drag-and-drop__file-box {
            border: 1px dashed ${({ theme }) => theme.colors.black};
        }

        .jodit-drag-and-drop__file-box:hover {
            background-color: ${({ theme }) => theme.colors.ambience[3]};
        }

        .jodit-ui-checkbox {
            .jodit-ui-checkbox__label {
                color: ${({ theme }) => theme.colors.ambience[9]};
            }
        }

        .jodit-color-picker__group {
            max-width: calc(25px * 10);
            padding: 2px;
        }

        .jodit-color-picker__native > input {
            -webkit-appearance: none;
            padding: 0;
            border: none;
            border-radius: 10px;
            width: 20px;
            height: 20px;
            margin: 2px;
        }

        .jodit-color-picker__native > svg {
            width: 20px;
            height: 20px;
            margin: 2px;
        }

        .jodit-color-picker__native > input::-webkit-color-swatch {
            border: none;
            border-radius: 10px;
            padding: 0;
        }

        .jodit-color-picker__native > input::-webkit-color-swatch-wrapper {
            border: none;
            border-radius: 10px;
            padding: 0;
        }

        .jodit-color-picker__color-item {
            height: 20px;
            width: 20px;
            margin: 2px;
        }

        .jodit-color-picker__group-greyscale .jodit-color-picker__group-palette {
            padding: 0 !important;
        }

        .jodit-color-picker__color-item {
            border-radius: 25px;
        }
    }

    .jodit-toolbar {
        &-button {
            border: 0 !important;
            &:hover {
                background: ${({ theme }) => theme.colors.ambience[15]};
                .jodit-toolbar-button__trigger {
                    &:before {
                        border-top: 4px solid
                            ${({ theme }) => theme.colors.ambience[9]};
                    }
                }
            }

            &__trigger,
            &__button {
                background: transparent !important;
            }
            &__trigger {
                opacity: 1 !important;
            }
            
            &__icon {
                svg.undefined {
                    display: none;
                }
            }
            &__text {
                color: ${({ theme }) => theme.colors.ambience[0]};
                margin-left: 0 !important;
                pre {
                    color: inherit;
                }
                blockquote {
                    font-style: italic;
                }
            }
        }
    }
    .jodit-popup-container .jodit-popup__content .jodit-color-picker__native > svg {
        fill: ${({ theme }) => theme.colors.ambience[9]} !important;
    }
    .jodit-popup-container .jodit-popup__content{
        .jodit-toolbar-editor-collection {
            background-image: none;
            &:after {
                display: none;
            }
            .jodit-toolbar-button__trigger {
                svg {
                    display: none;
                }
                &:before {
                    border-top-color: ${({ theme }) =>
                        theme.colors.ambience[9]} !important;

                }
            }
        }
       .jodit-toolbar-button {
        &__button {
            svg.undefined {
                display: none;
            }
        }
        &__trigger {
            &:before {
                content: '';
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid
                    ${({ theme }) => theme.colors.ambience[14]};
            }
        }
       }
        .jodit-tabs__buttons{
            margin-bottom: 20px;

            .jodit-ui-button {
                border-radius: 0;
                &__icon {
                    svg.undefined {
                        display: none;
                    }
                }
                &__text {
                    color: ${({ theme }) => theme.colors.ambience[9]};
                    margin-left: 0 !important;
                }
                &:hover {
                    background-color: ${({ theme }) =>
                        theme.colors.ambience[15]};
                    .jodit-ui-button__text {
                        color: ${({ theme }) => theme.colors.ambience[0]};
                    }
                }
                &[aria-pressed=true]{
                    background-color: ${({ theme }) =>
                        theme.colors.ambience[15]};
                    box-shadow: 0 4px 0 0 ${({ theme }) =>
                        theme.colors.ambience[3]};
                    .jodit-ui-button__text {
                        color: ${({ theme }) => theme.colors.ambience[0]};
                    }
                }
                &.jodit-ui-button_upload,
                &.jodit-ui-button_link {
                    width: 100px;
                    svg {
                        display: none;
                    }
                }
            }
        }
        .jodit-tabs__wrapper {
            .jodit-drag-and-drop__file-box {
                border: 1px dashed ${({ theme }) => theme.colors.ambience[9]};
                strong,span {
                    color:  ${({ theme }) => theme.colors.ambience[9]};
                }
                &:hover {
                    background: ${({ theme }) => theme.colors.ambience[15]};
                }
            }
        }
        .jodit-ui-group {
            .jodit-toolbar-button {
                .jodit-toolbar-button__button {
                    &:hover {
                        background-color: ${({ theme }) =>
                            theme.colors.ambience[15]};
                    }
                }
                .jodit-toolbar-button__icon {
                    svg {
                        fill: ${({ theme }) => theme.colors.ambience[9]};
                        stroke: none !important;
                    }
                }
                .jodit-toolbar-button__trigger {
                    &:hover {
                        background: ${({ theme }) => theme.colors.ambience[15]};
                    }
                }
            }
            .jodit-toolbar-button__button:active:not([disabled]) {
                background-color: transparent;
                &:hover {
                    background: ${({ theme }) => theme.colors.ambience[15]};
                }
            }
            .jodit-toolbar-button__button[aria-pressed=true]:not([disabled]) {
                background-color: transparent;
                &:hover {
                    background: ${({ theme }) => theme.colors.ambience[15]};
                }

            }
        }
    }
    
    .jodit-dialog, .jodit-dialog__panel {
        background: ${({ theme }) => theme.colors.ambience[19]} !important;
        border-radius: 8px;
        box-shadow: none !important;
        
        .jodit-dialog__header {
            border-bottom: 2px solid ${({ theme }) =>
                theme.colors.ambience[15]} !important;
            .jodit-dialog__header-title {
                color: ${({ theme }) => theme.colors.ambience[0]} !important;
            }
            .jodit-dialog__header-toolbar {
                .jodit-toolbar-collection {
                    &:after {
                        display: none !important;
                    }
                    .jodit-toolbar-button__button {
                        background: transparent !important;

                        .jodit-toolbar-button__icon {
                            svg {
                                fill: ${({ theme }) =>
                                    theme.colors.ambience[9]} !important;
                            }
                        }
                    }
                }
            }
        }

        .jodit-dialog__content {
            .jodit-symbols {
                .jodit-symbols__container_preview {
                    display: none;
                }
                .jodit-symbols__container_table {
                    width: 100%;
                    table {
                        width: 100%;
                        tr td a {
                            color: ${({ theme }) =>
                                theme.colors.ambience[0]} !important;
                            &:hover {
                                outline: 0;
                                background: ${({ theme }) =>
                                    theme.colors.ambience[15]};
                                border-radius: 4px;
                            }
                        }
                    }
                }
            }
            .jodit-tabs__wrapper{
                .jodit-form__group {
                    label {
                        color: ${({ theme }) => theme.colors.ambience[9]};
                    }
                }
            }
            .jodit-tabs__buttons{
                margin-bottom: 20px;
    
                .jodit-ui-button {
                    border-radius: 0;
                    &__icon {
                        svg.undefined {
                            display: none;
                        }
                    }
                    &__text {
                        color: ${({ theme }) => theme.colors.ambience[9]};
                        margin-left: 0 !important;
                    }
                    &:hover {
                        background-color: ${({ theme }) =>
                            theme.colors.ambience[15]};
                        .jodit-ui-button__text {
                            color: ${({ theme }) => theme.colors.ambience[0]};
                        }
                    }
                    &[aria-pressed=true]{
                        background-color: ${({ theme }) =>
                            theme.colors.ambience[15]};
                        box-shadow: 0 4px 0 0 ${({ theme }) =>
                            theme.colors.ambience[3]};
                        .jodit-ui-button__text {
                            color: ${({ theme }) => theme.colors.ambience[0]};
                        }
                    }
                    &.jodit-ui-button_upload,
                    &.jodit-ui-button_link {
                        width: 100px;
                        svg {
                            display: none;
                        }
                    }
                }
            }
        }
        .jodit-dialog__footer {
            .jodit-ui-button {
                background: transparent !important;
                &:hover {
                    opacity: 0.8;
                }
                svg {
                    fill: ${({ theme }) => theme.colors.ambience[0]};
                }
                .jodit-ui-button__text {
                    color: ${({ theme }) => theme.colors.ambience[0]};
                }
            }
        }
    }
    .jodit-popup__content .jodit-ui-group {
        .jodit-toolbar-button {
            &_default,
            &_disc {
                .jodit-toolbar-button__button {
                    .jodit-toolbar-button__icon {
                        width: 8px;
                        height: 8px;
                        background: ${({ theme }) => theme.colors.ambience[0]};
                        border-radius: 100%;
                        margin-right: 8px;
                    }
                }
            }
            &_circle {
                .jodit-toolbar-button__button {
                    .jodit-toolbar-button__icon {
                        width: 8px;
                        height: 8px;
                        border: 1px solid
                            ${({ theme }) => theme.colors.ambience[0]};
                        border-radius: 100%;
                        margin-right: 8px;
                    }
                }
            }
            &_square {
                .jodit-toolbar-button__button {
                    .jodit-toolbar-button__icon {
                        width: 8px;
                        height: 8px;
                        border: 1px solid
                            ${({ theme }) => theme.colors.ambience[0]};
                        margin-right: 8px;
                    }
                }
            }
        }
    }
    .color-custom-title {
        font-size: 14px;
        line-height: 18px;
        color: ${({ theme }) => theme.colors.ambience[0]};
        margin-bottom: 8px;
    }
    .customColorPickerWrapper {
        display: flex;
        align-items: center;
        input {
            opacity: 0;
            position: absolute;
            z-index: -1;
            visibility: hidden;
        }
        span {
            display: inline-block;
            width: 20px !important;
            height: 20px !important;
            border-radius: 100%;
            margin-right: 8px;
        }
        label {
            margin-bottom: 0;
            font-size: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
    .jodit-color-picker {
        a {
            width: 20px !important;
            height: 20px !important;
            border-radius: 100%;
            margin: 2px;
        }
    }
    .jodit-ui-input__wrapper  {
        border-radius: 8px !important;
        border: 0 !important;
        background-color: transparent !important;
    }
    .jodit-input, .jodit-ui-input__wrapper .jodit-ui-input__input,.jodit-select {
        background-color: ${({ theme }) =>
            theme.colors.ambience[21]} !important;
        border: 0 !important;
        border-radius: 8px !important;
        height: 48px !important;
        color: ${({ theme }) => theme.colors.ambience[1]} !important;
        box-shadow: none !important;
    }
    select.jodit-select {
        background-image: none !important;
    }
    
    input.jodit-input::-webkit-outer-spin-button,
    input.jodit-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number].jodit-input {
        -moz-appearance: textfield;
    }

    .jodit-properties__lock {
        svg {
            margin: 0 8px !important;
            fill: ${({ theme }) => theme.colors.ambience[9]} !important;
        }
    }
    .jodit-form__group .jodit-grid.jodit_vertical_middle {
        a.jodit-properties__lock {
            display: none;
        }
        input.jodit-input {
            margin: 0 8px;
        }
    }
    .jodit-properties_image_view {
        border-radius: 8px;
        background-color: ${({ theme }) =>
            theme.colors.ambience[21]} !important;
    }
    div.jodit_fullsize-box_true {
        z-index: 100000!important;
        position: fixed !important;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        bottom: 0 !important;
    }
    .jodit-toolbar-button {
        min-width: 28px;
        height: 28px;

        button {
            min-width: 28px;
            height: 28px;
        }
    }
`;
