import ReactDOM from 'react-dom';

function Portal({ container = 'react-portal-container', children }) {
    const el =
        typeof container === 'string'
            ? document.getElementById(container)
            : container;
    return el && ReactDOM.createPortal(children, el);
}

export default Portal;
