import ProgressBar from 'atoms/ProgressBar';
import { Typography } from 'foundations/typography';
import React, { useEffect } from 'react';
import usePortal from 'react-cool-portal';
import styled from 'styled-components';
import {
    EventLoadingStates,
    LOADING_EVENTS_TYPES,
    SHOW_WELCOME_SCREEN_TIMEOUT,
} from 'utils/constants/eventLoader';
import FirebaseLogger from 'utils/firebaseLogger';

export default function EventLoader({
    currentState,
    setShowWelcomeScreen,
    airmeetId,
    attendeeId,
    userRole,
    loadingStates = EventLoadingStates,
    completedState = LOADING_EVENTS_TYPES.checksComplete,
    logFirebase = true,
}) {
    const state = loadingStates[currentState];

    const { Portal } = usePortal({
        clickOutsideToHide: false,
        escToHide: false,
    });

    useEffect(() => {
        if (logFirebase) {
            FirebaseLogger.eventLoadingStates(currentState, {
                airmeetId,
                attendeeId,
                userRole,
            });
        }
    }, [currentState, airmeetId, attendeeId, userRole]);

    useEffect(() => {
        let timer;
        const isWelcomeState = currentState === completedState;
        if (isWelcomeState) {
            timer = setTimeout(() => {
                setShowWelcomeScreen(false);
            }, SHOW_WELCOME_SCREEN_TIMEOUT);
        }
        return () => clearTimeout(timer);
    }, [currentState, setShowWelcomeScreen, completedState]);

    if (state === undefined) return null;

    return (
        <Portal>
            <Container>
                <Image src={state.img_path} alt={state.title} />
                <Box>
                    <Typography mb={2} variant={'subtitle1'} color='ambience.1'>
                        {state.title}
                    </Typography>
                    {state?.subtitle && (
                        <Typography
                            variant={'subtitle2'}
                            color='ambience.6'
                            style={{
                                textAlign: 'center',
                                lineHeight: '24px',
                            }}
                        >
                            {state.subtitle}
                        </Typography>
                    )}
                </Box>
                <ProgressBar progress={state.progress} />
            </Container>
        </Portal>
    );
}

const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.ambience[24]};
`;

const Box = styled.div`
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 48px;

    @media (max-width: 640px) {
        margin-bottom: 44px;
    }
`;

const Image = styled.img`
    width: 220px;
    height: 200px;
    margin-bottom: 38px;

    @media (max-width: 640px) {
        width: 150px;
        height: 160px;
        margin-bottom: 24px;
    }
`;
