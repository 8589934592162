import {
    CLOSE_CREATE_MEETING_OVERLAY,
    OPEN_CREATE_MEETING_OVERLAY,
    TOGGLE_SCHEDULE_MEETING_TOAST,
    UPDATE_ATTENDEE_LIST,
} from 'store/actions/ScheduleMeeting';

const initialState = {
    showOverlay: false,
    attendeeList: {},
    toastMsg: '',
};

function scheduleMeeting(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case OPEN_CREATE_MEETING_OVERLAY:
            return { ...state, showOverlay: true };
        case CLOSE_CREATE_MEETING_OVERLAY:
            return { ...state, showOverlay: false, toastMsg: '' };
        case UPDATE_ATTENDEE_LIST:
            return { ...state, attendeeList: payload };
        case TOGGLE_SCHEDULE_MEETING_TOAST:
            return { ...state, toastMsg: payload };
        default:
            return state;
    }
}

export default scheduleMeeting;
