import Btn from 'atoms/Button/btn';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import useToasts from 'hooks/useToasts';
import isEmpty from 'lodash/isEmpty';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileUid } from 'store/actions/airProfile';
import styled from 'styled-components';
import { TOAST_VERSION_2 } from 'utils/constants/toasts';
import { cdnImage } from 'utils/core';
import { logger } from 'utils/logger';
import { AnalyticsContext } from '../AnalyticsProvider';
import LoadingOverlay from './components/LoadingOverlay';
import { Card } from './styledComponents';
import { getTopAttendees } from './utils/api';

export default function TopAttendeesCard({ logEvent, sessionId }) {
    const { airmeetId, communityId } = useContext(AnalyticsContext);
    const [isLoading, setIsLoading] = useState(true);
    const [apiData, setApiData] = useState([]);
    const attendeeCard = useSelector(
        (state) => state?.lounge?.airmeet?.customFormInfo?.attendeeCard || []
    );

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await getTopAttendees({
                airmeetId,
                sessionId,
            });
            if (!isEmpty(response?.data)) {
                setApiData(response?.data);
            }
        } catch (e) {
            logger.error('PostEventTab.fetchData: ', e);
        } finally {
            setIsLoading(false);
        }
    }, [airmeetId, sessionId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        Array.isArray(apiData) &&
        apiData.length > 0 && (
            <FlexDiv
                flexDirection='column'
                alignItems='stretch'
                flex='1'
                style={{ position: 'relative' }}
            >
                <Text pb='x4' color='text.default.primary' variant='h4'>
                    Top attendees by watch time
                </Text>
                <FlexDiv flexWrap='wrap' mx='-0.5rem'>
                    {(apiData || []).map((item) => (
                        <UserCard
                            key={item?.user_id}
                            user={item}
                            communityId={communityId}
                            airmeetId={airmeetId}
                            logEvent={logEvent}
                            attendeeCard={attendeeCard}
                        />
                    ))}
                </FlexDiv>
                {isLoading && <LoadingOverlay />}
            </FlexDiv>
        )
    );
}

const UserCard = ({
    user,
    communityId,
    airmeetId,
    logEvent,
    attendeeCard = [],
}) => {
    const { errorToast } = useToasts();
    const dispatch = useDispatch();
    const [isOpenLoading, setIsOpenLoading] = useState(false);

    const name = useMemo(() => {
        return (
            user?.user_name ||
            (user?.user_first_name || user?.user_last_name
                ? `${user?.user_first_name || ''} ${user?.user_last_name || ''}`
                : '') ||
            ''
        );
    }, [user]);

    const openUserProfile = useCallback(
        async (user) => {
            try {
                setIsOpenLoading(true);
                const { uuid, email_id: email, user_id } = user || {};
                let profileId = uuid;
                if (email === 'Guest') {
                    profileId = user_id;
                }
                if (!profileId) {
                    const res = await dispatch(
                        getProfileUid({
                            communityId,
                            email,
                        })
                    );
                    profileId = res?.payload?.data?.uuid;
                    if (res.error || !profileId) {
                        logger.error(
                            'Failed to open air profile, error fetching uuid : ',
                            res.error
                        );
                        errorToast({
                            title: 'Failed to open air profile',
                            version: TOAST_VERSION_2,
                        });
                        return;
                    }
                }
                window.open(
                    `${
                        window.location.origin
                    }/community-manager/${communityId}/user-profile/${profileId}${
                        airmeetId ? `/${airmeetId}` : ''
                    }`,
                    '_blank'
                );
                logEvent &&
                    logEvent('viewProfile', {
                        email,
                        profileId,
                        user_name: name,
                        user_uid: user_id,
                        user_uuid: uuid,
                    });
            } catch (error) {
                logger.error('Failed to open air profile : ', error);
                errorToast({
                    title: 'Failed to open air profile',
                    version: TOAST_VERSION_2,
                });
            } finally {
                setIsOpenLoading(false);
            }
        },
        [airmeetId, communityId, dispatch, errorToast, logEvent, name]
    );

    const subtitle1 = useMemo(
        () =>
            user?.user_profile && attendeeCard
                ? getSubtitle({ attendeeCard, user, start: 2, end: 4 })
                : '',
        [attendeeCard, user]
    );

    const subtitle2 = useMemo(
        () =>
            user?.user_profile && attendeeCard
                ? getSubtitle({ attendeeCard, user, start: 4, end: 6 })
                : '',
        [attendeeCard, user]
    );

    return (
        <FlexDiv flex={`0 0 ${100 / 3}%`} p='x2'>
            <Card>
                <FlexDiv pb='x3'>
                    <Img
                        src={
                            user?.profile_img ||
                            cdnImage('/images/avatar-placeholder.svg')
                        }
                    />
                    <FlexDiv
                        flexDirection='column'
                        alignItems='stretch'
                        pl='0.56rem'
                        flex='1'
                    >
                        <Text
                            variant='h4'
                            color='text.default.primary'
                            flex='1'
                        >
                            {name}
                        </Text>
                        {subtitle1 && (
                            <Text
                                variant='caption'
                                color='text.default.secondary'
                            >
                                {subtitle1}
                            </Text>
                        )}
                        {subtitle2 && (
                            <Text
                                variant='caption'
                                color='text.default.secondary'
                            >
                                {subtitle2}
                            </Text>
                        )}
                    </FlexDiv>
                </FlexDiv>
                <FlexDiv alignItems='center' gap='0.75rem'>
                    <StatBox
                        value={
                            user?.duration
                                ? parseFloat(user?.duration).toFixed(0)
                                : 0
                        }
                    />
                    <DetailBtn
                        variant='secondary'
                        onClick={() => openUserProfile(user)}
                        loading={isOpenLoading}
                    >
                        <StyledSvgSprite
                            icon='arrow_right'
                            fill='text.default.primary'
                            height='1.25rem'
                            width='1.25rem'
                        />
                    </DetailBtn>
                </FlexDiv>
            </Card>
        </FlexDiv>
    );
};

const StatBox = ({ title = 'Total viewing time', value }) => {
    const { val, unit } = useMemo(() => {
        if (value < 60) {
            return {
                val: value,
                unit: `second${value === 1 ? '' : 's'}`,
            };
        }

        const minutes = Math.round(value / 60);
        return {
            val: minutes,
            unit: `minute${minutes === 1 ? '' : 's'}`,
        };
    }, [value]);

    return (
        <StatBoxWrapper>
            <Text variant='caption' color='text.default.secondary' pb='x1'>
                {title}
            </Text>
            <FlexDiv alignItems='flex-end'>
                <Text variant='caption-bold' color='text.default.primary'>
                    {val}
                </Text>
                <Text variant='caption' color='text.default.primary' pl='x1'>
                    {unit}
                </Text>
            </FlexDiv>
        </StatBoxWrapper>
    );
};

const getSubtitle = ({ attendeeCard, user, start, end }) =>
    attendeeCard
        .slice(start, end)
        .map(
            (item) =>
                (user?.user_profile || []).find(
                    (field) => field?.fieldId === item?.uuid
                )?.value
        )
        .filter((item) => item)
        .join(', ');

const Img = styled.img`
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
`;

const StatBoxWrapper = styled(FlexDiv)`
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    border-radius: ${({ theme }) => theme.radii.default};
    background-color: ${({ theme }) => theme.colors.fill.default2};
    padding: ${({ theme }) => theme.space.x2};
`;

const DetailBtn = styled(Btn)`
    padding: 0;
    height: 2.5rem;
    width: 2.5rem;
`;
