export const WORKER_IDS = {
    AUTH_SERVICE: 'AUTH_SERVICE',
    HEARTBEATS: 'HEARTBEATS',
};

const TimeoutWorker = `
let timer
self.onmessage = async function (e) {
    if(timer && e.data.msg === 'terminate') {
        clearTimeout(timer)
    }
    const timeout = e.data.timeout || 0;
    const id = e.data.id;
    timer = setTimeout(() => {
        postMessage({
            msg: 'timer-elapsed',
            id:id,
        })
    }, timeout);
}`;
let workerInstances = [];
let _workerInstance;
const findWorkerInstance = (id) => {
    return workerInstances.find((worker) => worker.id === id);
};
const getTimeoutWorkerInstance = (workerId) => {
    if (!findWorkerInstance(workerId)) {
        const blob = new Blob([TimeoutWorker], {
            type: 'appplication/javascript',
        });
        _workerInstance = new Worker(URL.createObjectURL(blob));
        workerInstances.push({
            id: workerId,
            worker: _workerInstance,
        });
        return _workerInstance;
    }
    _workerInstance = findWorkerInstance(workerId);
    return _workerInstance.worker;
};
export const setTimeoutWorker = (
    callback,
    delay = 1000,
    loop = false,
    workerId
) => {
    const timeoutWorker = getTimeoutWorkerInstance(workerId);
    const id = Number(Date.now());
    function postListenerMsg() {
        timeoutWorker.postMessage({
            msg: 'timeout',
            id,
            timeout: delay,
        });
    }
    timeoutWorker.onmessage = (e) => {
        if (e.data.msg === 'timer-elapsed' && e.data.id === id) {
            const success = callback();
            if (loop && success) {
                postListenerMsg();
            }
        }
    };
    postListenerMsg();
};

export const terminateTimeoutWorker = (workerId) => {
    const instance = findWorkerInstance(workerId);

    if (instance) {
        instance.worker.postMessage({
            msg: 'terminate',
        });
        instance.worker.terminate();
        workerInstances = workerInstances.filter(
            (worker) => worker.id !== workerId
        );
    }
};
