import each from 'lodash/each';
import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
import {
    DEFAULT_LOUNGE_NODE,
    getLoungePath,
    MAX_ORG_TABLES_ALLOWED,
    MAX_USER_TABLES_ALLOWED,
    TABLE_TYPE_DEFAULT,
    TABLE_TYPE_USER,
} from 'utils/constants/social-lounge';
const { SOCIAL_LOUNGE } = FEATURE_NAMES;

const socialLoungeWriter = async ({
    airmeetId,
    client,
    payload,
    metaData,
    logger,
}) => {
    if (!airmeetId || !payload?.actionType) {
        throw new Error('Invalid firebase key for social lounge');
    } else {
        const updates = {};
        const {
            loungeId,
            tableId: tableCode,
            tableData,
            tableType,
            newTableCode,
            isLoungeSearchEnabled,
            canAttendeeCreateCard,
            titles,
            tableIds,
            reduceBy,
            newTablesCount,
            moderationActive,
            chairCount,
        } = metaData;
        const loungePath = getLoungePath(
            airmeetId,
            DEFAULT_LOUNGE_NODE,
            loungeId
        );
        const runOrganizerTableTransaction = async (increaseBy) => {
            let lastNumber;
            const tableCountersPath = `${loungePath}/counter`;
            const { committed, snapshot } = await client.runTransaction(
                tableCountersPath,
                (counter) => {
                    if (counter) {
                        const newOrganizerTables =
                            counter.organizerTables + increaseBy;
                        if (newOrganizerTables <= MAX_ORG_TABLES_ALLOWED) {
                            lastNumber = counter.lastTableNumber;

                            counter.lastTableNumber =
                                counter.lastTableNumber + increaseBy;
                            counter.organizerTables = newOrganizerTables;
                            return counter;
                        } else {
                            return;
                        }
                    } else return counter;
                }
            );
            const { lastTableNumber } = snapshot.val() || {};

            return { lastNumber, committed, lastTableNumber };
        };
        switch (payload.actionType) {
            case FEATURE_ACTIONS[SOCIAL_LOUNGE].ADD_USER_TABLE:
                logger.info('Adding User Table');
                const { committed: success } = await client.runTransaction(
                    `${loungePath}/counter/userTables`,
                    (userTables) => {
                        if (userTables < MAX_USER_TABLES_ALLOWED) {
                            userTables = userTables + 1;
                            return userTables;
                        } else {
                            return;
                        }
                    }
                );
                if (success) {
                    if (isLoungeSearchEnabled) {
                        updates[
                            `${loungePath}/tablesList/${newTableCode}/id`
                        ] = Date.now();
                        updates[
                            `${loungePath}/tablesList/${newTableCode}/title`
                        ] = tableData?.info?.title;
                    } else {
                        updates[
                            `${loungePath}/tablesList/${newTableCode}`
                        ] = Date.now();
                    }
                    updates[`${loungePath}/tables/${newTableCode}`] = tableData;
                    return client.atomicUpdate(updates);
                }
                return null;
            case FEATURE_ACTIONS[SOCIAL_LOUNGE].ADD_ORG_TABLE:
                logger.info('Adding Organizer Table');
                const {
                    committed,
                    lastTableNumber,
                } = await runOrganizerTableTransaction(1);
                const newOrgTableCode = `${loungeId}-${lastTableNumber}`;
                if (committed) {
                    if (isLoungeSearchEnabled) {
                        updates[
                            `${loungePath}/tablesList/${newOrgTableCode}/id`
                        ] = lastTableNumber;
                        updates[
                            `${loungePath}/tablesList/${newOrgTableCode}/title`
                        ] = tableData?.info?.title;
                    } else {
                        updates[
                            `${loungePath}/tablesList/${newOrgTableCode}`
                        ] = lastTableNumber;
                    }
                    updates[
                        `${loungePath}/tables/${newOrgTableCode}`
                    ] = tableData;
                    return client.atomicUpdate(updates);
                }
                return null;
            case FEATURE_ACTIONS[SOCIAL_LOUNGE].ADD_MULTIPLE_TABLE:
                logger.info('Adding Multiple Tables');
                const {
                    lastNumber,
                    committed: executed,
                    lastTableNumber: newLastTableNumber,
                } = await runOrganizerTableTransaction(newTablesCount);
                if (executed) {
                    const updates = {};
                    for (let i = lastNumber + 1; i <= newLastTableNumber; i++) {
                        const tableCode = `${loungeId}-${i}`;
                        updates[`${loungePath}/tables/${tableCode}`] = {
                            info: {
                                chairCount: chairCount,
                                title: `Table ${i}`,
                                // @TODO: remove BCTABLELIMIT
                                tableLimit: 'fixed',
                            },
                            type: TABLE_TYPE_DEFAULT,
                            tableNumber: i,
                        };
                        if (isLoungeSearchEnabled) {
                            updates[
                                `${loungePath}/tablesList/${tableCode}/id`
                            ] = i;
                            updates[
                                `${loungePath}/tablesList/${tableCode}/title`
                            ] = `Table ${i}`;
                        } else {
                            updates[
                                `${loungePath}/tablesList/${tableCode}`
                            ] = i;
                        }
                    }
                    client.atomicUpdate(updates);
                }
                return null;
            case FEATURE_ACTIONS[SOCIAL_LOUNGE].DELETE_TABLE:
                logger.info('Deleting Table');
                if (tableType === TABLE_TYPE_USER)
                    updates[
                        `${loungePath}/counter/userTables`
                    ] = client.getIncrementRef(-1);
                else
                    updates[
                        `${loungePath}/counter/organizerTables`
                    ] = client.getIncrementRef(-1);

                updates[`${loungePath}/tablesList/${tableCode}`] = null;
                updates[`${loungePath}/tables/${tableCode}`] = null;
                return client.atomicUpdate(updates);
            case FEATURE_ACTIONS[SOCIAL_LOUNGE].DELETE_MULTIPLE_TABLE:
                logger.info('Deleting Multiple Tables');
                each(tableIds, (tableId) => {
                    updates[`${loungePath}/tables/${tableId}`] = null;
                    updates[`${loungePath}/tablesList/${tableId}`] = null;
                });
                if (tableType === TABLE_TYPE_USER)
                    updates[
                        `${loungePath}/counter/userTables`
                    ] = client.getIncrementRef(-reduceBy);
                else
                    updates[
                        `${loungePath}/counter/organizerTables`
                    ] = client.getIncrementRef(-reduceBy);
                return client.atomicUpdate(updates);
            case FEATURE_ACTIONS[SOCIAL_LOUNGE].UPDATE_TABLE:
                logger.info('Updating Table');
                updates[`${loungePath}/tables/${tableCode}/info`] = tableData;
                if (isLoungeSearchEnabled) {
                    updates[`${loungePath}/tablesList/${tableCode}/title`] =
                        tableData?.title;
                }
                return client.atomicUpdate(updates);
            case FEATURE_ACTIONS[SOCIAL_LOUNGE].UPDATE_CONFIG:
                logger.info('Updating Lounge Config');
                const titlesPath = `${loungePath}/loungeConfig/titles`;
                return client.setData(titlesPath, titles);
            case FEATURE_ACTIONS[SOCIAL_LOUNGE].UPDATE_FLAG:
                logger.info('Enabling UGC');
                const ugcPath = `${loungePath}/loungeConfig/canAttendeeCreateCard`;
                return client.setData(ugcPath, canAttendeeCreateCard);
            case FEATURE_ACTIONS[SOCIAL_LOUNGE].UPDATE_MODERATION_SETTING:
                logger.info('Updating moderation setting');
                const moderationPath = `${loungePath}/loungeConfig/moderationActive`;
                return client.setData(moderationPath, moderationActive);
            default:
                return null;
        }
    }
};

export default socialLoungeWriter;
