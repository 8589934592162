export const LIVE_CONNECTION_STATE = {
    NOT_CONNECTED: 'disconnected',
    CONNECTED: 'connected',
};

export const LIVE_AIRMEET_STATE = {
    loading: 'loading',
    waiting: 'waiting',
    groupMeeting: 'group-meeting',
    socialLounge: 'social-lounge',
    backStage: 'back-stage',
    goLive: 'go-live',
    finish: 'finish',
    notEnable: 'not-enable',
};

export const JOIN_REQUEST_STATUS = {
    REQUESTED: 'requested',
    CANCELLED: 'cancelled',
    REQUEST_SENT: 'requestSent',
    DISMISSED: 'dismissed',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    MISSING_PERMISSION: 'missingPermission',
    PERMISSION_AWAIT: 'permissionAwait',
    REQUEST_NOT_ALLOWED: 'requestNotAllowed',
};

export const USER_STATUS = {
    inEvent: 'inEvent',
    inSession: 'inSession',
    offline: 'offline',
};

export const ONGOING = 'ONGOING';
export const LIVESTAGE = 'livestage';
export const BACKSTAGE = 'backstage';

export const MAX_RETRIES_USER_REQ = 5;

export const STORAGE_KEY_USER = 'AIRMEET_USER_STORAGE';

export const SESSION_START_COUNTDOWN_TIMER_DURATION = process.env
    .REACT_APP_SESSION_START_COUNTDOWN_TIMER_DURATION
    ? parseInt(process.env.REACT_APP_SESSION_START_COUNTDOWN_TIMER_DURATION)
    : 15;

export const USER_DROP_DURATION =
    process.env.REACT_APP_SESSION_USER_DROP_CHECK_DURATION || 30;

export const LITE_MODE_DELAY = 1000 * 30;

const USER_DROP_RANGE = [
    {
        minUserCount: 0,
        maxUserCount: 50,
        minRange: 15,
    },
    {
        minUserCount: 50,
        maxUserCount: 250,
        minRange: 25,
    },
    {
        minUserCount: 250,
        maxUserCount: 500,
        minRange: 50,
    },
    {
        minUserCount: 500,
        maxUserCount: 600,
        minRange: 60,
    },
    {
        minUserCount: 600,
        maxUserCount: 700,
        minRange: 70,
    },
    {
        minUserCount: 700,
        maxUserCount: 800,
        minRange: 80,
    },
    {
        minUserCount: 800,
        maxUserCount: 900,
        minRange: 90,
    },
    {
        minUserCount: 900,
        maxUserCount: 1000,
        minRange: 100,
    },
    {
        minUserCount: 1000,
        maxUserCount: 5000,
        minRange: 150,
    },
    {
        minUserCount: 5000,
        maxUserCount: 50000,
        minRange: 250,
    },
];

export const webRTCFirewallDetectionStates = {
    init: 'INIT',
    checking: 'ESTABLISHING_WEBRTC_CONNECTION',
    success: 'ESTABLISHED_WEBRTC_CONNECTION',
    failed: 'FAILED_TO_ESTABLISH_WEBRTC_CONNECTION',
    firebaseFailed: 'FAILED_TO_ESTABLISH_FIREBASE_CONNECTION',
    skipped: 'FEATURE_DISABLED_CHECK_SKIPPED',
    proxySuccess: 'ESTABLISHED_WEBRTC_CONNECTION_THROUGH_AGORA_CLOUD_PROXY',
    retryWithProxy: 'RETRYING_WITH_AGORA_CLOUD_PROXY',
    retryWithProxyFailed: 'RETRY_WITH_AGORA_CLOUD_PROXY_FAILED',
    retryWithAirmeetProxy: 'RETRYING_WITH_AIRMEET_CLOUD_PROXY',
    airmeetProxySuccess: 'ESTABLISHED_WEBRTC_CONNECTION_THROUGH_AIRMEET_PROXY',
    retryWithAirmeetProxyFailed: 'RETRY_WITH_AIRMEET_PROXY_FAILED',
    airmeetProxySkipped: 'AIRMEET_PROXY_DISABLED',
    customTURNSuffix: '_WITH_AIRMEET_TURN',
};

export const canConnectConditions = [
    webRTCFirewallDetectionStates.success,
    webRTCFirewallDetectionStates.skipped,
    webRTCFirewallDetectionStates.proxySuccess,
    webRTCFirewallDetectionStates.airmeetProxySuccess,
    `${webRTCFirewallDetectionStates.airmeetProxySuccess}${webRTCFirewallDetectionStates.customTURNSuffix}`,
];

export const failedToConnectConditions = [
    webRTCFirewallDetectionStates.failed,
    webRTCFirewallDetectionStates.retryWithProxyFailed,
    webRTCFirewallDetectionStates.retryWithAirmeetProxyFailed,
    `${webRTCFirewallDetectionStates.retryWithAirmeetProxyFailed}${webRTCFirewallDetectionStates.customTURNSuffix}`,
];

export const isBlockedByFirewallConditions = [
    webRTCFirewallDetectionStates.failed,
    webRTCFirewallDetectionStates.proxySuccess,
    webRTCFirewallDetectionStates.airmeetProxySuccess,
    `${webRTCFirewallDetectionStates.airmeetProxySuccess}${webRTCFirewallDetectionStates.customTURNSuffix}`,
];

export const isRetryingWithProxy = [
    webRTCFirewallDetectionStates.retryWithProxy,
    webRTCFirewallDetectionStates.retryWithAirmeetProxy,
];

export const IS_USER_INTERACTING = 'is_user_interacting';

export const LITE_MODE_WATERMARK_IMAGE_URL =
    'https://airmeet-images.s3.ap-south-1.amazonaws.com/events/logo/transparent1.png';

export const stageElementZIndex = {
    hostSpeakerPane: 98,
    persistentBackstagePane: 99,
    stageReactions: 100,
};
export const AIRMEET_HOMEPAGE_URL_AFTER_EXIT_EVENT =
    'https://www.airmeet.com/?utm_source=product&utm_medium=exitpage';

export const SCREEN_SHARE_USER_ID_START_FROM = 2600000000;
export const CUSTOM_MEDIA_USER_ID_START_FROM = 2900000000;
export const PDF_SHARE_USER_ID_START_FROM = 2300000000;

export const isCustomMediaStream = (uid) => {
    return parseInt(uid, 10) > CUSTOM_MEDIA_USER_ID_START_FROM;
};

export const isPDFShareStream = (uid) => {
    const maskedId = parseInt(uid, 10);
    return (
        maskedId > PDF_SHARE_USER_ID_START_FROM &&
        maskedId < SCREEN_SHARE_USER_ID_START_FROM
    );
};

const isScreenShare = (maskedId) => {
    return (
        maskedId > SCREEN_SHARE_USER_ID_START_FROM &&
        maskedId < CUSTOM_MEDIA_USER_ID_START_FROM
    );
};

export const isScreenShareStream = (uid) => {
    const maskedId = parseInt(uid, 10);
    return isScreenShare(maskedId) || isPDFShareStream(maskedId);
};

export const getScreenShareUserId = (uid) => {
    const prefix = isPDFShareStream(uid)
        ? PDF_SHARE_USER_ID_START_FROM
        : SCREEN_SHARE_USER_ID_START_FROM;
    return parseInt(uid, 10) - prefix;
};

export const getPDFShareUserId = (uid) => {
    return parseInt(uid, 10) - PDF_SHARE_USER_ID_START_FROM;
};

export const getCustomMediaUserId = (uid) => {
    return parseInt(uid, 10) - CUSTOM_MEDIA_USER_ID_START_FROM;
};

export const createCustomMediaUserId = (uid) => {
    return parseInt(uid, 10) + CUSTOM_MEDIA_USER_ID_START_FROM;
};

export const createScreenShareUserId = (uid) => {
    return parseInt(uid, 10) + SCREEN_SHARE_USER_ID_START_FROM;
};

// User Drop check
export const checkIsLargeDrop = (payload) => {
    const { userCountDiff, currUserCount } = payload;
    return (
        USER_DROP_RANGE.findIndex(
            (currIndex) =>
                currUserCount >= currIndex.minUserCount &&
                currUserCount <= currIndex.maxUserCount &&
                userCountDiff >= currIndex.minRange
        ) !== -1
    );
};
export const MAX_PINNED_USERS = { V1: 4, V2: 6 };

export const ANNOUNCEMENT_USER_TYPES = {
    PUBLISHER: 'PUBLISHER',
    AUDIENCE: 'AUDIENCE',
};

export const getStageType = (session) => {
    return session.status === ONGOING ? LIVESTAGE : BACKSTAGE;
};

export const STAGE_DEFAULT_AV_STATES = {
    // isAudioMute: isAutomationMode()
    //     ? localStorage.getItem(`automation-ma-${getAirmeetUtilInstance().airmeetId}`) !== '0'
    //     : false,
    // isVideoMute: isAutomationMode()
    //     ? localStorage.getItem(`automation-mv-${getAirmeetUtilInstance().airmeetId}`) !== '0'
    //     : true,
    isAudioMute: false,
    isVideoMute: true,
};

export const DEFAULT_BACKDROP_LAYOUT_DATA = { offsetTop: 0.27 };

export const getBackdropOffsets = (layoutData) => {
    const offsetData = layoutData
        ? JSON.parse(layoutData)
        : DEFAULT_BACKDROP_LAYOUT_DATA;
    return offsetData;
};

export const getAspectRatiosStyles = (width, height, aspectRatio = 16 / 9) => {
    const aspectRatioWidth = height * aspectRatio;
    if (width > height && aspectRatioWidth <= width) {
        return {
            width: aspectRatioWidth,
            height,
        };
    } else {
        const aspectRatioHeight = width / aspectRatio;
        return {
            width,
            height: aspectRatioHeight,
        };
    }
};

export const USER_STATUS_PERSISTENT_BACKSTAGE = {
    NOT_JOINED: 'not_joined', // when user not joined the session
    JOINED: 'joined', // when user is inside the session
    PUBLISHING: 'publishing', // when user starts the publishing process  or in transition between stage<->backstage
    PUBLISHED: 'published', // when user appeared on stage and user's controls state muted/unmuted completely
    DISCONNECTED: 'disconnected', // when user is disconnected from the event [network]
};

export const HEADLINE_MAX_LENGTH = 50;
export const generateDefaultHeadline = (
    designation,
    company,
    truncation = true
) => {
    let organizationAndDesignation = '';
    if (designation || company) {
        organizationAndDesignation = `${designation}${
            company && designation ? ', ' : ''
        }${company}`;
    }
    if (!truncation) {
        return organizationAndDesignation;
    }
    return organizationAndDesignation?.substring(0, HEADLINE_MAX_LENGTH);
};
