import { logger } from 'utils/logger';
import { bindModuleLogger } from 'utils/loggers/localLogger';
const profilingLogger = bindModuleLogger(`DEV:PROFILING`, 'red');

type AttrMap = {
    [key: string]: string;
};
type MetricsMap = {
    [key: string]: number;
};

interface TraceOptions {
    attributes?: AttrMap;
    metrics?: MetricsMap;
    disableAutoStart?: Boolean;
}
export default class Performance {
    perfImpl: any;
    traceEnabled: Boolean;
    activeTraces: Map<String, Trace>;
    commonAttributes: AttrMap;

    constructor(perf: any, traceEnabled: Boolean) {
        this.perfImpl = perf;
        this.traceEnabled = traceEnabled;
        this.activeTraces = new Map();
        this.commonAttributes = {
            buildID:
                process.env.BUILD_ID ||
                process.env.npm_package_version ||
                process.env.REACT_APP_ENV,
        };
    }

    addAttribute(name: string, value: string) {
        this.commonAttributes[name] = value;
    }

    trace(traceName: string, options?: TraceOptions): Trace {
        let result: Trace;
        if (this.traceEnabled) {
            if (options?.attributes) {
                options.attributes = {
                    ...options.attributes,
                    ...this.commonAttributes,
                };
            } else {
                options = {
                    attributes: { ...this.commonAttributes },
                };
            }
            result = new ActiveTrace(this.perfImpl.trace(traceName), options);
        } else {
            result = new Trace();
        }
        this.activeTraces.set(traceName, result);
        return result;
    }

    traceStop(traceName: string) {
        let trace = this.activeTraces.get(traceName);
        if (trace) {
            this.activeTraces.delete(traceName);
            return trace._stop();
        }
        return false;
    }

    traceRecord(traceName: string, duration: number, options?: TraceOptions) {
        if (options?.attributes) {
            options.attributes = {
                ...options.attributes,
                ...this.commonAttributes,
            };
        } else {
            options = {
                attributes: { ...this.commonAttributes },
            };
        }
        let trace = this.trace(traceName, options);
        this.activeTraces.delete(traceName);
        return trace._record(Date.now(), duration, options);
    }
}

class Trace {
    putAttribute(name: string, value: string) {}

    removeAttribute(name: string) {}

    getAttribute(name: string) {}

    incrementMetric(name: string, value: any) {}

    _start() {}

    _stop() {}

    _record(startTime: number, duration: number, options?: TraceOptions) {}
}

class ActiveTrace extends Trace {
    traceImpl: any;
    options: TraceOptions;
    constructor(trace: any, options?: TraceOptions) {
        super();
        this.traceImpl = trace;
        this.options = options || {};
        if (options?.attributes) {
            Object.entries(options?.attributes).forEach(([key, val]) => {
                this.putAttribute(key, String(val));
            });
        }

        if (options?.metrics) {
            Object.entries(options?.metrics).forEach(([key, val]) => {
                this.incrementMetric(key, val);
            });
        }

        if (!options?.disableAutoStart) {
            this._start();
        }
    }

    putAttribute(name: string, value: string) {
        if (!name || !value) {
            return;
        }
        let attr = this.options?.attributes;
        if (!attr) {
            this.options.attributes = {};
            attr = this.options.attributes;
        }
        attr[name] = value;
        return this.traceImpl.putAttribute(name, value);
    }

    removeAttribute(name: string) {
        let attr = this.options?.attributes;
        if (attr) {
            delete attr[name];
        }
        return this.traceImpl.removeAttribute(name);
    }

    getAttribute(name: string) {
        return this.traceImpl.getAttribute(name);
    }

    incrementMetric(name: string, value: any) {
        if (!name || !value) {
            return;
        }
        let metrics = this.options?.metrics;
        if (!metrics) {
            this.options.metrics = {};
            metrics = this.options.metrics;
        }
        metrics[name] = value;

        return this.traceImpl.incrementMetric(name, value);
    }

    _start() {
        return this.traceImpl.start();
    }

    _stop() {
        try {
            this.traceImpl.stop();
            logger.debug(
                'Profiling: Trace complete',
                this.traceImpl.name,
                this.traceImpl.durationUs / 1000,
                this.options
            );
            profilingLogger.rawJSON({
                componentId: 'Performance',
                name: this.traceImpl.name,
                duration: this.traceImpl.durationUs / 1000,
                startTime: this.traceImpl.startTimeUs / 1000,
            });
        } catch (e) {
            logger.warn('Trace stop failed');
        }
    }

    _record(startTime: number, duration: number, options?: TraceOptions) {
        if (!duration) {
            return;
        }
        let results;
        try {
            results = this.traceImpl.record(startTime, duration, options);
            profilingLogger.rawJSON({
                componentId: 'Performance',
                name: this.traceImpl.name,
                duration: this.traceImpl.durationUs / 1000,
                startTime: this.traceImpl.startTimeUs / 1000,
            });
        } catch (e) {
            logger.warn('Trace record failed');
        }
        return results;
    }
}
