import { logger } from 'utils/logger';
import { callApi as callApiService } from '../utils/apiService';
import AuthService from '../utils/authService';
import { logoutUser } from './actions/auth';

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');

export const callApi = callApiService;

async function callApiAction({ endpoint, ...props }, store, next) {
    const { types, headers = {}, extra = null, preReqData, ...rest } = props;
    if (typeof endpoint === 'function') {
        endpoint = endpoint(store.getState(), preReqData);
    }

    if (typeof rest?.query === 'function') {
        rest.query = rest.query(store.getState(), preReqData);
    }

    if (!Array.isArray(types) || types.length > 2) {
        throw new Error('Expected an array of maximum two action types.');
    }
    if (!types.every((type) => typeof type === 'string')) {
        throw new Error('Expected action types to be strings.');
    }

    const actionWith = (data) => {
        const finalAction = { ...props, ...data };
        delete finalAction[CALL_API];
        return finalAction;
    };

    const [requestType, mainActionType] = types;

    next(actionWith({ type: requestType }));

    try {
        const response = await callApiService({
            endpoint,
            headers,
            ...rest,
        });
        return mainActionType
            ? next(
                  actionWith({
                      payload: response,
                      extra: extra,
                      type: mainActionType,
                  })
              )
            : response;
    } catch (error) {
        if (!error.response || error.response?.status >= 500) {
            // Log to Sentry
            logger.error('Error in API action:', {
                endpoint,
                headers,
                rest,
                responseStatus: error?.response?.status,
                responseStatusText: error?.response?.statusText,
                responseBody: error?.response?.body,
            });
        }
        const isUnauthorizedResponse =
            error && error.response && error.response.status === 401;
        // forcefully logout from site if unauthorized response come for logged-in user
        if (
            isUnauthorizedResponse &&
            (AuthService.loggedInAt ||
                (headers['X-AccessToken'] &&
                    AuthService.token &&
                    AuthService.token === headers['X-AccessToken']))
        ) {
            const logoutAction = logoutUser()[CALL_API];
            if (endpoint !== logoutAction.endpoint) {
                await callApiAction(logoutAction, store, next);
                logger.info(
                    'Forceful logout on unauthorized response for endpoint : ',
                    endpoint
                );
            }
        }
        const payload = error || new Error('Something bad happened');
        return mainActionType
            ? next(
                  actionWith({
                      type: mainActionType,
                      payload,
                      error: true,
                  })
              )
            : { payload, error: true };
    }
}

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store) => (next) => async (action) => {
    let callAPI = action[CALL_API];
    // skip if we're not calling API
    if (typeof callAPI === 'undefined') {
        return next(action);
    }

    // Multiple API calls
    const hasMultiple = typeof callAPI.actions !== 'undefined';
    const actions = hasMultiple ? callAPI.actions : [callAPI];
    const postActions =
        hasMultiple && typeof callAPI.postActions !== 'undefined'
            ? callAPI.postActions
            : [];
    let responses = await Promise.all(
        actions.map((act) => callApiAction(act, store, next))
    );

    if (hasMultiple) {
        if (responses.some(({ error }) => error === true)) {
            // In case of any error, fail all
            return null;
        }

        let payload = responses.reduce(
            (acc, curr) => ({ ...acc, ...curr }),
            {}
        );

        if (postActions.length > 0) {
            let postActionsResponse = await Promise.all(
                callAPI.postActions.map((act) =>
                    callApiAction({ ...act, preReqData: payload }, store, next)
                )
            );

            if (postActionsResponse.some(({ error }) => error === true)) {
                // In case of any error, fail all
                return null;
            }

            payload = postActionsResponse.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                payload
            );
        }

        return callAPI.finalType
            ? next({
                  type: callAPI.finalType,
                  payload: payload,
                  extra: callAPI.extra,
              })
            : responses;
    } else {
        return responses[0];
    }
};
