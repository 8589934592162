export const MEMBER_ROLES = {
    OWNER: 'OWNER',
    ADMIN: 'Admin',
    MANAGER: 'Manager',
    EVENT_EXECUTIVE: 'Event executive',
    BILLING: 'Billing',
};

export const VISIBLE = 'visible';
export const INTERACTABLE = 'interactable';

// individual permissions from user permissions api
export const MY_PROFILE_ACCESS = 'my_profile_access';
export const MY_PROFILE_VIEW = 'my_profile_view';
export const MY_PROFILE_UPDATE = 'my_profile_update';
export const INTEGRATION_ACCESS = 'integration_access';
export const INTEGRATION_CREATE = 'integration_create';
export const INTEGRATION_VIEW = 'integration_view';
export const INTEGRATION_DELETE = 'integration_delete';
export const BILLING_ACCESS = 'billing_access';
export const BILLING_VIEW = 'billing_view';
export const BILLING_UPDATE = 'billing_update';
export const TEAM_ACCESS = 'team_access';
export const TEAM_CREATE = 'team_create';
export const TEAM_VIEW = 'team_view';
export const TEAM_UPDATE = 'team_update';
export const TEAM_DELETE = 'team_delete';
export const ROLE_PERMISSIONS_UPDATE = 'role_permissions_update';
export const ROLE_PERMISSIONS_VIEW = 'role_permissions_view';
export const ASSIGN_ADMIN = 'assign_admin';
export const ASSIGN_MANAGER = 'assign_manager';
export const ASSIGN_EVENT_EXECUTIVE = 'assign_event_executive';
export const MODIFY_ADMIN = 'modify_admin';
export const MODIFY_MANAGER = 'modify_manager';
export const MODIFY_EVENT_EXECUTIVE = 'modify_event_executive';
export const VIDEO_LIBRARY_ACCESS = 'video_library_access';
export const VIDEO_LIBRARY_UPLOAD_VIDEOS = 'video_library_upload_videos';
export const VIDEO_LIBRARY_VIEW = 'video_library_view';
export const VIDEO_LIBRARY_DELETE_VIDEOS = 'video_library_delete_videos';
export const SHOW_ARCHIVE_EVENTS_SELECT = 'show_archive_events_select';
export const CREATE_AIRMEET = 'create_airmeet';
export const COPY_EVENT_LINK = 'copy_event_link';
export const COPY_SERIES_LINK = 'copy_series_link';
export const DUPLICATE_AIRMEET_EVENT = 'duplicate_airmeet_event';
export const PREVIEW_AIRMEET = 'preview_airmeet';
export const START_AIRMEET = 'start_airmeet';
export const END_AIRMEET = 'end_airmeet';
export const PAUSE_AIRMEET = 'pause_airmeet';
export const ARCHIVE_EVENT = 'archive_event';
export const BASIC_INFO_ACCESS = 'basic_info_access';
export const BASIC_INFO_VIEW = 'basic_info_view';
export const BASIC_INFO_UPDATE = 'basic_info_update';
export const EVENT_ENTRY_ACCESS = 'event_entry_access';
export const EVENT_ENTRY_VIEW = 'event_entry_view';
export const EVENT_ENTRY_UPDATE = 'event_entry_update';
export const VENUE_SETTINGS_ACCESS = 'venue_settings_access';
export const VENUE_SETTINGS_VIEW = 'venue_settings_view';
export const VENUE_SETTINGS_UPDATE = 'venue_settings_update';
export const SCHEDULE_ACCESS = 'schedule_access';
export const SCHEDULE_VIEW = 'schedule_view';
export const SCHEDULE_CREATE = 'schedule_create';
export const SCHEDULE_UPDATE = 'schedule_update';
export const SCHEDULE_DELETE = 'schedule_delete';
export const SPEAKER_HOST_ACCESS = 'speaker_host_access';
export const SPEAKER_HOST_VIEW = 'speaker_host_view';
export const SPEAKER_HOST_ADD = 'speaker_host_add';
export const SPEAKER_HOST_DELETE = 'speaker_host_delete';
export const SPEAKER_HOST_UPDATE = 'speaker_host_update';
export const COHOST_CREATE = 'cohost_create';
export const BOOTHS_ACCESS = 'booths_access';
export const BOOTHS_VIEW = 'booths_view';
export const BOOTHS_ADD = 'booths_add';
export const BOOTHS_UPDATE = 'booths_update';
export const BOOTHS_DELETE = 'booths_delete';
export const SPONSORS_ACCESS = 'sponsors_access';
export const SPONSORS_VIEW = 'sponsors_view';
export const SPONSORS_ADD = 'sponsors_add';
export const SPONSORS_UPDATE = 'sponsors_update';
export const SPONSORS_DELETE = 'sponsors_delete';
export const ATTENDEE_ENGAGEMENT_ACCESS = 'attendee_engagement_access';
export const ATTENDEE_ENGAGEMENT_VIEW = 'attendee_engagement_view';
export const ATTENDEE_ENGAGEMENT_UPDATE = 'attendee_engagement_update';
export const STAGE_BACKDROP_ACCESS = 'stage_backdrop_access';
export const STAGE_BACKDROP_VIEW = 'stage_backdrop_view';
export const STAGE_BACKDROP_ADD = 'stage_backdrop_add';
export const STAGE_BACKDROP_DELETE = 'stage_backdrop_delete';
export const STAGE_BACKDROP_UPDATE = 'stage_backdrop_update';
export const STAGE_WAITING_SCREEN_ACCESS = 'stage_waiting_screen_access';
export const STAGE_WAITING_SCREEN_VIEW = 'stage_waiting_screen_view';
export const STAGE_WAITING_SCREEN_ADD = 'stage_waiting_screen_view';
export const STAGE_WAITING_SCREEN_DELETE = 'stage_waiting_screen_delete';
export const STAGE_WAITING_SCREEN_UPDATE = 'stage_waiting_screen_update';
export const VIDEOS_ACCESS = 'videos_access';
export const VIDEOS_VIEW = 'videos_view';
export const VIDEOS_ADD = 'videos_add';
export const VIDEOS_DELETE = 'videos_delete';
export const VIDEOS_UPDATE = 'videos_update';
export const LIVE_STREAM_ACCESS = 'live_stream_access';
export const LIVE_STREAM_VIEW = 'live_stream_view';
export const LIVE_STREAM_ADD = 'live_stream_add';
export const LIVE_STREAM_DELETE = 'live_stream_delete';
export const LIVE_STREAM_UPDATE = 'live_stream_update';
export const TICKET_ACCESS = 'ticket_access';
export const TICKET_VIEW = 'ticket_view';
export const TICKET_ADD = 'ticket_add';
export const TICKET_UPDATE = 'ticket_update';
export const TICKET_DELETE = 'ticket_delete';
export const RECORDINGS_ACCESS = 'recordings_access';
export const RECORDINGS_VIEW = 'recordings_view';
export const RECORDINGS_DELETE = 'recordings_delete';
export const ANALYTICS_ACCESS = 'analytics_access';
export const ANALYTICS_REPORT_SEND = 'analytics_report_send';
export const ANALYTICS_SUMMARY_VIEW = 'analytics_summary_view';
export const ANALYTICS_REGISTRATION_SCREEN = 'analytics_registration_screen';
export const ANALYTICS_ENGAGEMENT_SCREEN = 'analytics_engagement_screen';
export const ANALYTICS_AUDIENCE_TAB = 'analytics_audience_tab';
export const EVENT_SPECIFIC_INTEGRATION_ACCESS =
    'event_specific_integration_access';
export const EVENT_SPECIFIC_INTEGRATION_VIEW =
    'event_specific_integration_view';
export const EVENT_SPECIFIC_INTEGRATION_ADD = 'event_specific_integration_add';
export const EVENT_SPECIFIC_INTEGRATION_UPDATE =
    'event_specific_integration_update';
export const EVENT_SPECIFIC_INTEGRATION_DELETE =
    'event_specific_integration_delete';
export const MODERATE_CHAT = 'moderate_chat';
export const MODERATE_QA = 'moderate_qa';
export const ANNOUNCEMENT_CREATE = 'announcement_create';
export const CUSTOMIZE_THEME = 'customize_theme';
export const CUSTOMIZE_EVENT_SETTINGS = 'customize_event_settings';
export const ACCESS_BACKSTAGE = 'access_backstage';
export const ACCESS_SPEAKER_CHAT = 'access_speaker_chat';
export const VIEW_EVENT_ORGANIZERS_ACCESS = 'view_event_organizers_access';
export const MANAGE_EVENT_ORGANIZERS_UPDATE = 'manage_event_organizers_update';
export const LANDING_PAGE_CUSTOMIZATION_VIEW =
    'landing_page_customization_view';
export const LANDING_PAGE_CUSTOMIZATION_REVERT =
    'landing_page_customization_revert';
export const LANDING_PAGE_CUSTOMIZATION_UPDATE =
    'landing_page_customization_update';
export const EMAIL_CUSTOMIZATION_VIEW = 'email_customization_view';
export const EMAIL_CUSTOMIZATION_CREATE = 'email_customization_create';
export const EMAIL_CUSTOMIZATION_UPDATE = 'email_customization_update';
export const EMAIL_CUSTOMIZATION_PAUSE = 'email_customization_pause';
export const PRE_EVENT_ENTRY_VIEW = 'pre_event_entry_view';
export const PRE_EVENT_ENTRY_UPDATE = 'pre_event_entry_update';
export const PRE_EVENT_ENTRY_DELETE = 'pre_event_entry_delete';
export const EVENT_REPLAY_ENTRY_VIEW = 'event_replay_entry_view';
export const EVENT_REPLAY_ENTRY_UPDATE = 'event_replay_entry_update';
export const STREAMING_KEYS_VIEW = 'streaming_keys_view';
export const FULL_HD_STREAMING_AND_RECORDING_VIEW =
    'full_hd_streaming_and_recording_view';
export const FULL_HD_STREAMING_AND_RECORDING_UPDATE =
    'full_hd_streaming_and_recording_update';
export const PROMOTE_ON_AIRMEET_VIEW = 'promote_on_airmeet_view';
export const PROMOTE_ON_AIRMEET_UPDATE = 'promote_on_airmeet_update';
export const COMMUNITY_WATCH_RECORDING = 'community_watch_recording';
export const COMMUNITY_DOWNLOAD_RECORDING = 'community_download_recording';
export const COMMUNITY_EDIT_RECORDING = 'community_edit_recording';

// variables for event series
export const CREATE_EVENT_SERIES = 'create_event_series';
export const SERIES_LANDING_PAGE_CUSTOMIZATION_VIEW =
    'event_series_landing_page_customization_view';
export const SERIES_LANDING_PAGE_CUSTOMIZATION_REVERT =
    'event_series_landing_page_customization_revert';
export const SERIES_SPONSORS_VIEW = 'event_series_sponsors_view';
export const SERIES_SPONSORS_ADD = 'event_series_sponsors_add';
export const SERIES_SPONSORS_DELETE = 'event_series_sponsors_delete';
export const SERIES_SPONSORS_UPDATE = 'event_series_sponsors_update';
export const SERIES_CUSTOM_REGISTRATION_FORM_VIEW =
    'event_series_custom_registration_form_view';
export const SERIES_VENUE_SETTING_VIEW = 'event_series_venue_settings_view';
export const SERIES_VENUE_SETTING_UPDATE = 'event_series_venue_settings_update';
export const SERIES_ENTRY_VIEW = 'event_series_entry_view';
export const SERIES_EDIT_ACCESS = 'edit_event_series';
export const SERIES_ADD_EVENT_ACCESS = 'edit_event_series_airmeet_mapping';
export const SERIES_CUSTOM_REG_FORM_CREATE =
    'event_series_custom_registration_form_create';
export const SERIES_CUSTOM_REG_FORM_UPDATE =
    'event_series_custom_registration_form_update';
export const SERIES_EMAIL_CUSTOMIZATION_CREATE =
    'event_series_email_customization_create';
export const SERIES_EMAIL_CUSTOMIZATION_UPDATE =
    'event_series_email_customization_update';
export const SERIES_EMAIL_CUSTOMIZATION_VIEW =
    'event_series_email_customization_view';

export const EVENT_SERIES_SPECIFIC_INTEGRATION_ACCESS =
    'event_series_specific_integration_access';
export const EVENT_SERIES_SPECIFIC_INTEGRATION_ADD =
    'event_series_specific_integration_add';
export const EVENT_SERIES_SPECIFIC_INTEGRATION_DELETE =
    'event_series_specific_integration_delete';
export const EVENT_SERIES_SPECIFIC_INTEGRATION_UPDATE =
    'event_series_specific_integration_update';

export const COMMUNITY_LEVEL_EMAIL_TEMPLATE_VIEW =
    'community_level_email_template_view';
export const COMMUNITY_LEVEL_EMAIL_TEMPLATE_CREATE =
    'community_level_email_template_create';
export const COMMUNITY_LEVEL_EMAIL_TEMPLATE_UPDATE =
    'community_level_email_template_update';
