import FluidspaceClientPath from 'components/FluidSpace/utils/fluidClientPath';
import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';
export const FLUID_SPACE_LOG_PREFIX = 'Fluid Space:';
const { FLUID_SPACE } = FEATURE_NAMES;

export function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const fluidSpaceWriter = async ({
    airmeetId,
    client,
    metaData,
    logger,
    firebaseLogger,
}) => {
    if (!airmeetId) {
        throw new Error('Invalid fluid space firebase key');
    }

    const {
        serverTimeDiff,
        userId,
        sessionId,
        communityId,
        zoneId,
        userPresenceClient,
    } = metaData;

    const fluidspaceClientPath = new FluidspaceClientPath({
        airmeetId,
        sessionId,
        userId,
        zoneId,
    });

    const logRoomAdded = (eventName, data = {}) => {
        const payload = {
            community_id: communityId,
            airmeet_id: airmeetId,
            session_id: sessionId,
            ...data,
        };
        firebaseLogger.fluidLounge(eventName, payload);
    };

    const addUserToWaitingList = async () => {
        const waitingUserRef = client.ref(
            fluidspaceClientPath.waitingQueueUser
        );
        const currentTime = Date.now() + serverTimeDiff;
        try {
            await waitingUserRef.set(currentTime);
        } catch (err) {
            logger.error(
                FLUID_SPACE_LOG_PREFIX,
                'Failed to add user to waiting list ',
                {
                    err,
                }
            );
        }
    };

    const getWaitingUsersCount = async () => {
        const order = {
            orderByValue: true,
        };
        let waitingUsers: string[] = [];
        await client
            .ref(fluidspaceClientPath.waitingQueue, order)
            .once('value', (snapshot: firebase.database.DataSnapshot) => {
                if (snapshot.exists()) {
                    waitingUsers = Object.keys(snapshot.val());
                }
            });
        const currentWaitingUserIndex = waitingUsers.indexOf(userId) + 1;
        return currentWaitingUserIndex || 1;
    };

    /* eslint-disable no-loop-func */
    const allotUserInExistingZone = async (
        waitingUsersCount: number,
        userAllotmentInZone: number,
        maxPossibleZones: number
    ) => {
        logger.info(
            FLUID_SPACE_LOG_PREFIX,
            'Using V2 implementation to find zone',
            {
                maxPossibleZones,
                userAllotmentInZone,
            }
        );
        const zoneMap = {};
        let waitingUsersCountLocal = waitingUsersCount;
        for (let i = 1; i <= maxPossibleZones; i++) {
            let zoneForUser: string | null = null;
            const zoneId = `${sessionId}-${i}`;
            await userPresenceClient
                .ref(fluidspaceClientPath.getLiveCountForZone(zoneId))
                .once('value', function (
                    snapshot: firebase.database.DataSnapshot
                ) {
                    if (!snapshot.exists()) {
                        logRoomAdded('New zone created', { zoneId });
                    }
                    const userCountInZone = Number(snapshot.val());
                    logger.info(
                        FLUID_SPACE_LOG_PREFIX,
                        'zone allocation logic',
                        {
                            currentZone: zoneId,
                            userCountInZone: userCountInZone,
                            waitingUsersCountLocal,
                        }
                    );
                    zoneMap[zoneId] = userCountInZone;
                    if (
                        userCountInZone + waitingUsersCountLocal <=
                        userAllotmentInZone // space in zone for current user and waiting users
                    ) {
                        zoneForUser = zoneId;
                    } else if (userCountInZone < userAllotmentInZone) {
                        const usersSpaceInCurrentZone =
                            userAllotmentInZone - userCountInZone;
                        waitingUsersCountLocal =
                            waitingUsersCountLocal - usersSpaceInCurrentZone;
                    }
                });
            if (zoneForUser) {
                logger.info(FLUID_SPACE_LOG_PREFIX, 'Zone map ', zoneMap);
                return zoneForUser;
            }
        }
        logger.info(FLUID_SPACE_LOG_PREFIX, 'Zone map ', zoneMap);
        return null;
    };

    const removeUserFromWaitingList = async () => {
        const waitingUserRef = client.ref(
            fluidspaceClientPath.waitingQueueUser
        );
        // Adding 2-second delay to simulate latency in live counter
        await sleep(2000);
        waitingUserRef.set(null);
    };

    const getUserCountInSession = async () => {
        let userCountInSession = 0;
        await userPresenceClient
            .ref(fluidspaceClientPath.userLiveCountInCurrentSession)
            .once('value', (snapshot: firebase.database.DataSnapshot) => {
                if (snapshot.exists()) {
                    userCountInSession = snapshot.val();
                } else {
                    userCountInSession = 0;
                }
            });
        return userCountInSession;
    };

    if (metaData.action === FEATURE_ACTIONS[FLUID_SPACE].FIND_ZONE) {
        const reserveZone = async () => {
            try {
                const userCountInSession = await getUserCountInSession();
                await addUserToWaitingList();
                const waitingUsersCount = await getWaitingUsersCount();
                if (
                    waitingUsersCount + userCountInSession <=
                    metaData.maxUserCountInSession
                ) {
                    logger.info(
                        FLUID_SPACE_LOG_PREFIX,
                        'Finding zone for user ',
                        {
                            waitingUsersCount,
                            userCountInSession,
                        }
                    );

                    //will first try with allot size and then with max size to reserve zone
                    let zoneId: string | null;
                    zoneId = await allotUserInExistingZone(
                        waitingUsersCount,
                        metaData.userAllotmentInZone, //allot
                        metaData.maxPossibleZones
                    );
                    if (!zoneId) {
                        logger.info(
                            FLUID_SPACE_LOG_PREFIX,
                            'Assuming all zones have exceeded userAllotmentInZone, so invoking allotUserInExistingZone with maxUserCountInZone',
                            {
                                maxUserCountInZone: metaData.maxUserCountInZone,
                                userAllotmentInZone:
                                    metaData.userAllotmentInZone,
                            }
                        );
                        zoneId = await allotUserInExistingZone(
                            waitingUsersCount,
                            metaData.maxUserCountInZone, //max
                            metaData.maxPossibleZones
                        );
                    }
                    removeUserFromWaitingList();
                    return zoneId;
                } else {
                    logger.info(
                        FLUID_SPACE_LOG_PREFIX,
                        'Dropping user, no space in session',
                        { waitingUsersCount, userCountInSession }
                    );
                    logRoomAdded('fsSessionCapacityFull', {});
                    removeUserFromWaitingList();
                    return null;
                }
            } catch (err) {
                logger.error(
                    FLUID_SPACE_LOG_PREFIX,
                    'Failed to add user to zone ',
                    JSON.stringify(err)
                );
            }
            return null;
        };
        let reservedZoneId = await reserveZone();
        return reservedZoneId;
    }
};

export default fluidSpaceWriter;
