import _omit from 'lodash/omit';
import {
    ADD_INVITE_TABLE_DND_USERS,
    REMOVE_INVITE_TABLE_DND_USERS,
    TOGGLE_INVITE_TABLE_DND_MODAL,
} from 'store/actions/inviteTableDndUsers';

const initialState = {
    users: {},
    dndModalVisible: false,
};

function inviteTableDndUsers(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case ADD_INVITE_TABLE_DND_USERS:
            return { ...state, users: { ...state.users, ...payload } };
        case REMOVE_INVITE_TABLE_DND_USERS:
            return { ...state, users: _omit(state.users, payload) };
        case TOGGLE_INVITE_TABLE_DND_MODAL:
            const newVisibilityState =
                payload === undefined ? !state.dndModalVisible : payload;

            return { ...state, dndModalVisible: newVisibilityState };

        default:
            return state;
    }
}

export default inviteTableDndUsers;
