import { getSessionBySessionId } from 'context/LiveAirmeet';
import useContainerDeepLocation from 'hooks/summit/useContainerDeepLocation';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStageId } from 'store/actions/liveAirmeet';
const stageRootPath = 'sessions';
const mobileStageRootPath = 'reception';

export const useGetStageRouteId = (props = {}) => {
    const { isMobile } = props;
    const { route, rootLocation } = useContainerDeepLocation();
    const isSessionStageRoute =
        (rootLocation === isMobile ? mobileStageRootPath : stageRootPath) &&
        route.startsWith('stage/');
    const stageRouteId = useMemo(() => {
        if (!isSessionStageRoute) {
            return null;
        }
        const idIndex = route.lastIndexOf('/') + 1;
        if (idIndex > 0 && idIndex < route.length) {
            return route.substring(idIndex);
        }
        return null;
    }, [isSessionStageRoute, route]);

    return stageRouteId;
};

export const useSetStageRouteId = (props = {}) => {
    const dispatch = useDispatch();
    const stageRouteId = useGetStageRouteId();

    useEffect(() => {
        dispatch(setStageId(stageRouteId));
    }, [stageRouteId, dispatch]);

    return null;
};

const useStageContent = () => {
    const getSession = useLiveAirmeetContext(getSessionBySessionId);
    // this stage id will be set after executing the useEffect in above useSetStageRouteId() hook
    const stageId = useSelector((state) => state.liveAirmeet.stageId);
    const stageSession = useMemo(() => getSession(stageId), [
        stageId,
        getSession,
    ]);

    // this will send the immediate session details based on route
    const routeStageId = useGetStageRouteId();
    const routeStageSession = useMemo(() => getSession(routeStageId), [
        routeStageId,
        getSession,
    ]);

    return { stageId, stageSession, routeStageId, routeStageSession };
};

export default useStageContent;
