import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import {
    fetchUserPermissions,
    setIsCurrentUserRoleMappedToEvent,
} from 'store/actions/community/member';
import { getCommunityId } from 'context/LiveAirmeet';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import useDashboardFeatureFlags, {
    ROLES_AND_PERMISSIONS_FLAG,
} from 'hooks/community/useDashboardFeatureFlags';
import {
    NOT_VISIBLE_AND_NOT_INTERACTABLE,
    PERMISSION_VALUE_SETS,
    VISIBLE_AND_INTERACTABLE,
} from './utils';

const defaultPermissionValue = PERMISSION_VALUE_SETS[VISIBLE_AND_INTERACTABLE];

const useUserPermission = (airmeetId = null, isOrganiser = false) => {
    const userPermissions = useSelector(
        (state) => state.members.userPermissions
    );
    const { isCurrentUserRoleMappedToEvent } = useSelector(
        (state) => state.members
    );
    const { myCommunity } = useSelector((state) => state.Community);
    const dispatch = useDispatch();
    const location = useLocation();

    const communityId = useLiveAirmeetContext(getCommunityId); // Returns undefined when used outside scope of LiveAirmeet Provider
    const {
        isRolesAndPermissionsEnabled: dashboardLevelFlagValue, // Retrieved from current community store value
    } = useDashboardFeatureFlags({});

    /* Inside live event, myCommunity store value is populated before
    current community value. Hence, retrieving flag value from
    myCommunity ensures user permission details (api action dispatched in below effect)
    are fetched early, before page load is completed. */
    const getLiveEventFlagValue = useCallback(
        (communityId) => {
            if (!communityId) return false;

            const { list = [] } = myCommunity || {};
            const currentCommunityFeatureData =
                list.find((community) => community.communityId === communityId)
                    ?.enabled_feature_data || {};
            return (
                currentCommunityFeatureData[ROLES_AND_PERMISSIONS_FLAG] || false
            );
        },
        [myCommunity]
    );

    // Will always be false for non team members inside live event
    const isFeatureFlagEnabled = useMemo(
        () => dashboardLevelFlagValue || getLiveEventFlagValue(communityId),
        [communityId, getLiveEventFlagValue, dashboardLevelFlagValue]
    );

    const getCustomPermissionValues = (permissions = [], customValue) => {
        return new Array(permissions.length).fill(customValue);
    };

    const getUserPermissionValues = useCallback(
        (
            permissions = [],
            customValue = defaultPermissionValue,
            devOverride = false
        ) => {
            if (!isFeatureFlagEnabled)
                return getCustomPermissionValues(
                    permissions,
                    defaultPermissionValue
                );

            if (devOverride)
                return getCustomPermissionValues(permissions, customValue);

            // To prevent any user role who is not assigned to current event
            // from being able to access organizer controls
            if (
                location.pathname.startsWith('/event/') &&
                isCurrentUserRoleMappedToEvent === false
            ) {
                return getCustomPermissionValues(
                    permissions,
                    PERMISSION_VALUE_SETS[NOT_VISIBLE_AND_NOT_INTERACTABLE]
                );
            }

            return permissions.map(
                (permission) =>
                    userPermissions?.[permission] || defaultPermissionValue
            );
        },
        [
            isCurrentUserRoleMappedToEvent,
            isFeatureFlagEnabled,
            location.pathname,
            userPermissions,
        ]
    );

    useEffect(() => {
        const getUserPermissions = async () => {
            const response = await dispatch(
                fetchUserPermissions(communityId, airmeetId)
            );

            if (response?.error) {
                dispatch(setIsCurrentUserRoleMappedToEvent(false));
                return;
            }

            dispatch(setIsCurrentUserRoleMappedToEvent(true));
        };

        if (communityId && isOrganiser && airmeetId && isFeatureFlagEnabled) {
            getUserPermissions();
        }
    }, [communityId, dispatch, isOrganiser, airmeetId, isFeatureFlagEnabled]);

    return {
        getUserPermissionValues,
    };
};

export default useUserPermission;
