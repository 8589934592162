import ToggleSwitchButton from 'atoms/ToggleSwitch/ToggleSwitchButton';
import LoaderV2 from 'components/general/LoaderV2';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import React from 'react';

export default function SettingsSwitch({
    background = '#9D9AAC',
    activeBackground = '#009862',
    borderRadius = 12,
    toggleSize = 16,
    toggleLeft = 4,
    width = 44,
    checked,
    checkedText = 'On',
    uncheckedText = 'Off',
    loading = false,
    ...rest
}) {
    return (
        <FlexDiv alignItems='center'>
            {loading ? (
                <FlexDiv alignItems='center' pr={1}>
                    <LoaderV2 size={20} />
                </FlexDiv>
            ) : (
                <Typography variant='subtext2' color='ambience.0' pr={0}>
                    {checked ? checkedText : uncheckedText}
                </Typography>
            )}
            <ToggleSwitchButton
                background={background}
                activeBackground={activeBackground}
                borderRadius={borderRadius}
                toggleSize={toggleSize}
                toggleLeft={toggleLeft}
                width={width}
                checked={checked}
                {...rest}
            />
        </FlexDiv>
    );
}
