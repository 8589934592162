import {
    SET_RICH_PROFILE_INTERESTS,
    TOGGLE_FULL_PROFILE,
    TOGGLE_PROFILE_NUDGE,
} from 'store/actions/profile';

const initialState = {
    richProfileInterests: {},
    isFullProfileVisible: null,
    isProfileNudgeVisible: false,
};

const profile = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case SET_RICH_PROFILE_INTERESTS:
            return { ...state, richProfileInterests: payload };
        case TOGGLE_FULL_PROFILE:
            return { ...state, isFullProfileVisible: payload };
        case TOGGLE_PROFILE_NUDGE:
            return { ...state, isProfileNudgeVisible: payload };
        default:
            return state;
    }
};

export default profile;
