import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { useSelector } from 'react-redux';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import { isEventAttendee } from 'utils/userAccessControl';

const preEventConfigSelector = (store) => store?.lounge?.airmeet;
const exhibitorSelector = (state) => state?.auth;

function usePreEventConfig() {
    const {
        airmeet: {
            data: {
                currentAirmeet: { status: airmeetStatus },
            },
        },
    } = useLiveAirmeetContext();
    const { preEventConfigurations } = useSelector(preEventConfigSelector);
    const isPreEvent = airmeetStatus === AIRMEET_STATUS.PRE_EVENT_ACCESS;
    const { exhibitor } = useSelector(exhibitorSelector);

    const isExhibitor = exhibitor?.booth?.length ? true : false;
    const isPreEventAndNotOrganizer =
        isPreEvent && isEventAttendee() && !isExhibitor;

    const isScheduleMeetingEnabledForPreEvent = isPreEventAndNotOrganizer
        ? preEventConfigurations?.scheduleMeetings
        : true;

    const showWalkThroughVideoForPreEvent = isPreEventAndNotOrganizer
        ? preEventConfigurations?.hideWalkthroughVideo
        : true;

    const hideEventFeedForPreEvent = isPreEventAndNotOrganizer
        ? !preEventConfigurations?.hideEventFeed
        : false;

    const showMessagesTabForPreEvent = isPreEventAndNotOrganizer
        ? preEventConfigurations?.messages
        : true;

    const showBoothForPreEvent = isPreEventAndNotOrganizer
        ? preEventConfigurations?.booths
        : true;

    const showLoungeForPreEvent = isPreEventAndNotOrganizer
        ? preEventConfigurations?.lounge
        : true;

    const showPreEventResources = isPreEventAndNotOrganizer
        ? preEventConfigurations?.resources
        : true;

    return {
        isScheduleMeetingEnabledForPreEvent,
        showWalkThroughVideoForPreEvent,
        hideEventFeedForPreEvent,
        showMessagesTabForPreEvent,
        showBoothForPreEvent,
        isPreEventAndNotOrganizer,
        isPreEvent,
        showLoungeForPreEvent,
        showPreEventResources,
    };
}

export default usePreEventConfig;
