import useUserProfile from 'hooks/users/useUserProfile';
import _get from 'lodash/get';

export const selectAuthUser = (store) => _get(store, ['auth', 'user']);
export const selectAuthUserId = (state) => _get(selectAuthUser(state), 'id');
export const selectCommunityManagerId = (state) =>
    _get(state, ['Community', 'current', 'manager_id']);

export const getAssocUserById = (id, defaultValue) => (state) =>
    _get(state, ['lounge', 'airmeet', 'assocUsers', id], defaultValue);

export const getAssocUserByIdSeq = (idSeq, defaultValue) => (state) =>
    _get(state, ['lounge', 'airmeet', 'assocIntUsers', idSeq], defaultValue);

export const useUserData = (id, ...args) => {
    let userData = args?.[0]?.prefetchedData;
    const userDataMap = useUserDataMulti([id], ...args);
    if (!id) {
        return userData;
    }
    let results = userDataMap.get(id);
    userData = results.isLoading && userData ? userData : results;
    return userData;
};

export const useUserDataMulti = (ids, ...args) => {
    let result = useUserProfile(ids, ...args);
    return result;
};

export const getMeetingList = (store) => _get(store, 'meetingList');

export const getDndStatusById = (id) => (state) =>
    _get(state, ['dndUsers', id], false);

export const getInviteTableDndStatusById = (id) => (state) =>
    _get(state, ['inviteTableDndUsers', 'users', id], false);

export const getInviteTableDndModalVisible = (state) =>
    _get(state, ['inviteTableDndUsers', 'dndModalVisible'], false);

export const getStageMembers = (stageId) => (state) => {
    return state.liveAirmeet.stageMembers[stageId] || {};
};

export const getMembersCountInStage = (key) => (state) => {
    return Object.keys(getStageMembers(key)(state)).length;
};

export const getMembersInContext = (key) => (state) => {
    return state.liveAirmeet.membersInContext[key] || {};
};

export const getMembersCountInContext = (key) => (state) => {
    return Object.keys(getMembersInContext(key)(state)).length;
};

export const getAuthToken = (state) => state.auth.token;

export const getBlockUserModal = (store) =>
    _get(store, ['blockUserModal', 'user'], false);
