import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import upperFirst from 'lodash/upperFirst';
import React from 'react';
import styled, { css } from 'styled-components';
import { getAmbienceColorId } from 'utils/core';

export const TimeZone = ({
    showSidemenu,
    setShowSideMenu,
    selectedOption,
    newOnBoardingFlow = false,
    timeZone,
    airmeetFormat = 'event',
}) => {
    const { theme } = useTheme();
    const getColorId = (colorId) =>
        getAmbienceColorId({ colorId, isAgnostic: newOnBoardingFlow, theme });

    return (
        <>
            <FlexDiv>
                <Label htmlFor={'timeZonePicker'}>
                    <Typography
                        mb={'4px'}
                        variant={'subtext1'}
                        color={'ambience.1'}
                    >
                        Time zone
                    </Typography>

                    <SubLabel
                        mb={'0px'}
                        variant={'caption'}
                        color={'ambience.14'}
                    >
                        {upperFirst(airmeetFormat)} time zone
                    </SubLabel>
                </Label>
            </FlexDiv>
            <TimeZoneBox
                onClick={() => setShowSideMenu('timezone')}
                id={'timeZonePicker'}
                className={showSidemenu === 'timezone' && 'active'}
                showSidemenu={showSidemenu !== ''}
                mb={3}
                ambienceColorId={getColorId}
                newOnBoardingFlow={newOnBoardingFlow}
            >
                {selectedOption.length !== 0 && (
                    <Text variant='body2' color='text.default.secondary'>
                        {`UTC ${selectedOption.offset}`}
                    </Text>
                )}
                <Text variant='body2' color='text.default.secondary'>
                    {selectedOption.length !== 0
                        ? `${selectedOption.value.replace(/_/g, ' ')}`
                        : timeZone
                        ? timeZone.replace(/_/g, ' ')
                        : timeZone}
                </Text>
            </TimeZoneBox>
        </>
    );
};

const Label = styled.label``;
const SubLabel = styled(Typography)`
    font-family: ${({ theme }) => theme.fonts.book};
`;
const TimeZoneBox = styled(FlexDiv)`
    position: relative;
    padding: 16px;
    cursor: pointer;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.ambience[20]};
    min-height: 55px;
    align-items: center;
    background-color: ${({ theme, isLightBg }) =>
        isLightBg ? theme.colors.ambience[3] : theme.colors.ambience[20]};
    ${({ newOnBoardingFlow }) =>
        newOnBoardingFlow &&
        css`
            background-color: ${({ theme }) =>
                theme.colors.ambience[24]} !important;
            border: ${({ theme }) =>
                `1px solid ${theme.colors.ambience[20]} !important`};
        `}
`;
