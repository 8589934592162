//entity
export const BOOTH = 'BOOTH';
export const SESSION = 'SESSION';
export const ATTENDEE = 'ATTENDEE';
export const POLL = 'POLL';
export const SPONSOR = 'SPONSOR';
export const EVENT = 'EVENT';
export const QUESTION = 'QUESTION';

//actions
export const BOOTH_CHAT = 'BOOTH_CHAT';
export const ASK_QUESTION = 'ASK_QUESTION';
export const UPVOTE_RECEIVE = 'UPVOTE_RECEIVE';
export const POLL_ANSWER = 'POLL_ANSWER';
export const SPONSOR_VISIT = 'SPONSOR_VISIT';
export const BOOTH_INTEREST_SUBMIT = 'BOOTH_INTEREST_SUBMIT';
export const BOOTH_VISIT = 'BOOTH_VISIT';
export const SN_MATCH = 'SN_MATCH';
export const MEETING_SCHEDULE = 'MEETING_SCHEDULE';
export const EVENT_REGISTER = 'EVENT_REGISTER';
export const ENTER_SESSION = 'ENTER_SESSION';
export const CHAT_REPLY = 'CHAT_REPLY';
export const DM_INITIATE = 'DM_INITIATE';
export const EMOJI_EXPRESS = 'EMOJI_EXPRESS';
export const SESSION_RAISE_HAND = 'SESSION_RAISE_HAND';
export const SESSION_RAISE_HAND_ACCEPT = 'SESSION_RAISE_HAND_ACCEPT';
export const ENTER_EVENT = 'ENTER_EVENT';
export const ADD_INTEREST = 'ADD_INTEREST';
export const ADD_SOCIAL_LINK = 'ADD_SOCIAL_LINK';
export const ADD_INTRODUCTION = 'ADD_INTRODUCTION';
export const BOOTH_TABLE_JOIN = 'BOOTH_TABLE_JOIN';
export const MEETING_ATTEND = 'MEETING_ATTEND';
export const TABLE_INTERACTION = 'TABLE_INTERACTION';
export const TYPEFORM_SUBMITTED = 'TYPEFORM_SUBMITTED';
export const RESOURCE_VIEW_DOWNLOAD = 'RESOURCE_VIEW_DOWNLOAD';
export const POST_SESSION_CHAT = 'POST_SESSION_CHAT';
export const POST_EVENT_FEED = 'POST_EVENT_FEED';
export const IN_SESSION_CTA = 'IN_SESSION_CTA';

export const LEADERBOARD_MOBILE_ACTIONS = [
    ENTER_EVENT,
    ENTER_SESSION,
    TABLE_INTERACTION,
    BOOTH_CHAT,
    ASK_QUESTION,
    UPVOTE_RECEIVE,
    POLL_ANSWER,
    BOOTH_INTEREST_SUBMIT,
    SPONSOR_VISIT,
    DM_INITIATE,
    CHAT_REPLY,
    BOOTH_VISIT,
    MEETING_ATTEND,
    MEETING_SCHEDULE,
    SN_MATCH,
    POST_SESSION_CHAT,
    POST_EVENT_FEED,
];
