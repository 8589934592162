import facebookIcon from '@iconify/icons-uil/facebook';
import linkedinIcon from '@iconify/icons-uil/linkedin';
import twitterIcon from '@iconify/icons-uil/twitter';
import { Icon } from '@iconify/react';
import useWhiteLabelling from 'hooks/useWhitelabelling';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    background,
    border,
    color,
    flexbox,
    layout,
    position,
    space,
} from 'styled-system';
import { ExternalUrls } from 'utils/redirections';
import theme from '../../../foundations/theme';
import { Typography } from '../../../foundations/typography';
import useLocalStorage from '../../../hooks/useLocalStorage';
import LocalizedString from '../../../translation/footer.json';
import FirebaseLogger from '../../../utils/firebaseLogger';
import Dropdown from './dropdown';

const ENGLISH = { name: 'English', key: 'en' };
const Footer = () => {
    const footerProps = useSelector((state) => state.layout.footer);
    const { isLocalisation = true } = footerProps;
    var isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
    const user = useSelector((state) => state.auth.user);
    const isBeamerEnabled =
        process.env.REACT_APP_BEAMER_WIDGET_ENABLED === 'true';

    const handleCTAClick = (destination) => {
        window.open(destination, '_self');
    };

    const captureEvent = () => {
        if (user && !user.guest) {
            FirebaseLogger.onClickPricingBtn('Existing user', user.email);
        } else {
            FirebaseLogger.onClickPricingBtn('New user', null);
        }
    };
    const [currentLang, setCurrentLang] = useLocalStorage('lang', ENGLISH);
    useEffect(() => {
        if (
            (user && user.metadata === null) ||
            (user && !Object.keys(user).includes('metadata'))
        ) {
            setCurrentLang(currentLang || ENGLISH);
        } else if (user && user.metadata.lang) {
            setCurrentLang(user.metadata.lang);
        } else if (currentLang) {
            setCurrentLang(currentLang);
        } else {
            setCurrentLang(ENGLISH);
        }
    }, [user]);
    const langKey = isLocalisation ? currentLang.key : ENGLISH.key;

    const { whiteLabelText } = useWhiteLabelling();

    const MobileFooter = () => {
        return (
            <FooterWrap
                bg={theme.colors.ambience[23]}
                px={4}
                pb={30}
                pt={20}
                className={'gdpr-footer-row-mobile'}
            >
                <Dropdown
                    title={LocalizedString[langKey].column1.title}
                    content={[
                        {
                            name: LocalizedString[langKey].column1.navmenu[0],
                            link: 'https://airmeet.freshteam.com/jobs',
                        },
                        {
                            name: LocalizedString[langKey].column1.navmenu[1],
                            link: 'https://www.airmeet.com/hub/contact/',
                        },
                        {
                            name: LocalizedString[langKey].column1.navmenu[2],
                            link: 'https://www.airmeet.com/hub/about-us/',
                        },
                    ]}
                />
                <Hr />
                <Dropdown
                    title={LocalizedString[langKey].column2.title}
                    content={[
                        {
                            name: LocalizedString[langKey].column2.navmenu[0],
                            link: 'https://www.airmeet.com/hub/',
                        },
                        {
                            name: LocalizedString[langKey].column2.navmenu[1],
                            link: 'https://www.airmeet.com/hub/ebook/',
                        },
                        {
                            name: LocalizedString[langKey].column2.navmenu[2],
                            link: 'https://www.airmeet.com/hub/faq/',
                        },
                        {
                            name: LocalizedString[langKey].column2.navmenu[3],
                            link: 'https://www.airmeet.com/hub/press/',
                        },
                    ]}
                />
                <Hr />
                <Dropdown
                    title={LocalizedString[langKey].column3.title}
                    content={[
                        {
                            name: (
                                <FooterLink
                                    onClick={captureEvent}
                                    target='_blank'
                                    rel='noreferrer noopener'
                                    href={ExternalUrls.PRICING_PAGE}
                                >
                                    <Typography
                                        variant={'subtext1'}
                                        color='ambience.12'
                                    >
                                        {
                                            LocalizedString[langKey].column3
                                                .navmenu[0]
                                        }
                                    </Typography>
                                </FooterLink>
                            ),
                            link: '',
                        },
                        {
                            name: LocalizedString[langKey].column3.navmenu[1],
                            link: 'https://www.airmeet.com/hub/case-study/',
                        },
                        {
                            name: LocalizedString[langKey].column3.navmenu[2],
                            link: 'https://www.airmeet.com/hub/comparisons/',
                        },
                        {
                            name: LocalizedString[langKey].column3.navmenu[3],
                            link: 'https://www.airmeet.com/hub/use-cases/',
                        },
                        ...(isBeamerEnabled
                            ? [
                                  {
                                      name: "What's New",
                                      link:
                                          'https://app.getbeamer.com/airmeetcom/',
                                  },
                              ]
                            : []),
                    ]}
                />
                <Hr />
                <Dropdown
                    title={LocalizedString[langKey].column4.title}
                    content={[
                        {
                            name: 'support@airmeet.com',
                            link: 'mailto:support@airmeet.com',
                        },
                        {
                            name: (
                                <Box>
                                    <FooterLink
                                        target='_blank'
                                        rel='noreferrer'
                                        href={ExternalUrls.AIRMEET_FACEBOOK}
                                        mr={50}
                                    >
                                        <Icon
                                            icon={facebookIcon}
                                            color='ambience.5'
                                            width='25'
                                            height='25'
                                        />
                                    </FooterLink>
                                    <FooterLink
                                        target='_blank'
                                        rel='noreferrer'
                                        href={ExternalUrls.AIRMEET_TWITTER}
                                        mr={50}
                                    >
                                        <Icon
                                            icon={twitterIcon}
                                            color='ambience.5'
                                            width='25'
                                            height='25'
                                        />
                                    </FooterLink>
                                    <FooterLink
                                        target='_blank'
                                        rel='noreferrer'
                                        href={ExternalUrls.AIRMEET_LINKEDIN}
                                        mr={50}
                                    >
                                        <Icon
                                            icon={linkedinIcon}
                                            color='ambience.5'
                                            width='25'
                                            height='25'
                                        />
                                    </FooterLink>
                                </Box>
                            ),
                            link: '',
                        },
                    ]}
                />
                <Hr />
                <Dropdown
                    title={LocalizedString[langKey].column5.title}
                    content={[
                        {
                            name: LocalizedString[langKey].column5.navmenu[0],
                            link:
                                'https://www.gmsintercert.com/verify/single-cert-verify.php?cRegName=ISMS2021010',
                        },
                    ]}
                />
                <Hr />
                <Box mx='auto' mt={14} width={1} flexWrap={['wrap']}>
                    <Box mr={2} mt={'10px'}>
                        <FooterLink
                            onClick={() =>
                                handleCTAClick(ExternalUrls.TERMS_OF_USE)
                            }
                            style={{ cursor: 'pointer' }}
                        >
                            <Typography
                                variant={'subtext1'}
                                color='ambience.12'
                            >
                                {
                                    LocalizedString[langKey].footerBottom
                                        .navmenu[0]
                                }
                            </Typography>
                        </FooterLink>
                    </Box>
                    <Box mr={2} mt={'10px'}>
                        <FooterLink
                            onClick={() =>
                                handleCTAClick(ExternalUrls.PRIVACY_POLICY)
                            }
                            style={{ cursor: 'pointer' }}
                        >
                            <Typography
                                variant={'subtext1'}
                                color='ambience.12'
                            >
                                {
                                    LocalizedString[langKey].footerBottom
                                        .navmenu[1]
                                }
                            </Typography>
                        </FooterLink>
                    </Box>
                    <Box mt={'10px'}>
                        <Typography variant={'subtext1'} color='ambience.12'>
                            {whiteLabelText('© 2024 Airmeet Inc.', '')}
                        </Typography>
                    </Box>
                </Box>
            </FooterWrap>
        );
    };

    return (
        <>
            {isMobile ? (
                <MobileFooter />
            ) : (
                <FooterWrap bg={theme.colors.ambience[23]} px={4}>
                    <Box
                        flexDirection={['column', 'row']}
                        width='100%'
                        maxWidth={1100}
                        mx={3}
                        py={[4, 8]}
                        justifyContent={'space-between'}
                    >
                        <Box
                            flexDirection={'column'}
                            mb={[2, 3]}
                            minWidth={150}
                            pb={[4, 0]}
                            borderBottomStyle={'solid'}
                            borderBottomWidth={[1, 0]}
                            borderBottomColor={theme.colors.ambience[19]}
                        >
                            <Typography
                                mt={2}
                                mb={2}
                                variant={'h6'}
                                color='ambience.10'
                            >
                                {LocalizedString[langKey].column1.title}
                            </Typography>
                            <FooterLink
                                target='_blank'
                                rel='noreferrer'
                                href='https://airmeet.freshteam.com/jobs'
                            >
                                <Typography
                                    mt={2}
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].column1
                                            .navmenu[0]
                                    }
                                </Typography>
                            </FooterLink>
                            <FooterLink
                                target='_blank'
                                rel='noreferrer'
                                href='https://www.airmeet.com/hub/contact/'
                            >
                                <Typography
                                    mt={2}
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].column1
                                            .navmenu[1]
                                    }
                                </Typography>
                            </FooterLink>
                            <FooterLink
                                target='_blank'
                                rel='noreferrer'
                                href='https://www.airmeet.com/hub/about-us/'
                            >
                                <Typography
                                    mt={2}
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].column1
                                            .navmenu[2]
                                    }
                                </Typography>
                            </FooterLink>
                        </Box>
                        <Box
                            mb={[2, 3]}
                            flexDirection={'column'}
                            minWidth={150}
                            pb={[4, 0]}
                            borderBottomStyle={'solid'}
                            borderBottomWidth={[1, 0]}
                            borderBottomColor={theme.colors.ambience[19]}
                        >
                            <Typography
                                mt={2}
                                mb={2}
                                variant={'h6'}
                                color='ambience.10'
                            >
                                {LocalizedString[langKey].column2.title}
                            </Typography>
                            <FooterLink
                                target='_blank'
                                rel='noreferrer'
                                href='https://www.airmeet.com/hub/'
                            >
                                <Typography
                                    mt={2}
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].column2
                                            .navmenu[0]
                                    }
                                </Typography>
                            </FooterLink>
                            <FooterLink
                                target='_blank'
                                rel='noreferrer'
                                href='https://www.airmeet.com/hub/ebook/'
                            >
                                <Typography
                                    mt={2}
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].column2
                                            .navmenu[1]
                                    }
                                </Typography>
                            </FooterLink>
                            <FooterLink
                                target='_blank'
                                rel='noreferrer'
                                href='https://www.airmeet.com/hub/faq/'
                            >
                                <Typography
                                    mt={2}
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].column2
                                            .navmenu[2]
                                    }
                                </Typography>
                            </FooterLink>
                            <FooterLink
                                target='_blank'
                                rel='noreferrer'
                                href='https://www.airmeet.com/hub/press/'
                            >
                                <Typography
                                    mt={2}
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].column2
                                            .navmenu[3]
                                    }
                                </Typography>
                            </FooterLink>
                        </Box>
                        <Box
                            mb={[2, 3]}
                            flexDirection={'column'}
                            minWidth={150}
                            pb={[4, 0]}
                            borderBottomStyle={'solid'}
                            borderBottomWidth={[1, 0]}
                            borderBottomColor={theme.colors.ambience[19]}
                        >
                            <Typography
                                mt={2}
                                mb={2}
                                variant={'h6'}
                                color='ambience.10'
                            >
                                {LocalizedString[langKey].column3.title}
                            </Typography>
                            <FooterLink
                                onClick={captureEvent}
                                target='_blank'
                                rel='noreferrer noopener'
                                href={ExternalUrls.PRICING_PAGE}
                            >
                                <Typography
                                    mt={2}
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].column3
                                            .navmenu[0]
                                    }
                                </Typography>
                            </FooterLink>
                            <FooterLink
                                target='_blank'
                                rel='noreferrer'
                                href='https://www.airmeet.com/hub/case-study/'
                            >
                                <Typography
                                    mt={2}
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].column3
                                            .navmenu[1]
                                    }
                                </Typography>
                            </FooterLink>
                            <FooterLink
                                target='_blank'
                                rel='noreferrer'
                                href='https://www.airmeet.com/hub/comparisons/'
                            >
                                <Typography
                                    mt={2}
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].column3
                                            .navmenu[2]
                                    }
                                </Typography>
                            </FooterLink>
                            <FooterLink
                                target='_blank'
                                rel='noreferrer'
                                href='https://www.airmeet.com/hub/use-cases/'
                            >
                                <Typography
                                    mt={2}
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].column3
                                            .navmenu[3]
                                    }
                                </Typography>
                            </FooterLink>
                            {isBeamerEnabled && (
                                <FooterLink
                                    target='_blank'
                                    rel='noreferrer'
                                    href='https://app.getbeamer.com/airmeetcom/'
                                >
                                    <Typography
                                        mt={2}
                                        variant={'body2'}
                                        color='ambience.15'
                                    >
                                        What's New
                                    </Typography>
                                </FooterLink>
                            )}
                        </Box>
                        <Box
                            mb={3}
                            flexDirection={'column'}
                            minWidth={[150, 150, 175]}
                        >
                            <Typography
                                mt={2}
                                mb={2}
                                variant={'h6'}
                                color='ambience.10'
                            >
                                {LocalizedString[langKey].column4.title}
                            </Typography>
                            <FooterLink
                                target='_blank'
                                rel='noreferrer'
                                href='mailto:support@airmeet.com'
                            >
                                <Typography
                                    mt={2}
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    support@airmeet.com
                                </Typography>
                            </FooterLink>
                            <Box
                                mt={3}
                                justifyContent='space-between'
                                width={[1 / 2, 1, 1]}
                            >
                                <FooterLink
                                    target='_blank'
                                    rel='noreferrer'
                                    href={ExternalUrls.AIRMEET_FACEBOOK}
                                >
                                    <Icon
                                        icon={facebookIcon}
                                        color='ambience.5'
                                        width='25'
                                        height='25'
                                    />
                                </FooterLink>
                                <FooterLink
                                    target='_blank'
                                    rel='noreferrer'
                                    href={ExternalUrls.AIRMEET_TWITTER}
                                >
                                    <Icon
                                        icon={twitterIcon}
                                        color='ambience.5'
                                        width='25'
                                        height='25'
                                    />
                                </FooterLink>
                                <FooterLink
                                    target='_blank'
                                    rel='noreferrer'
                                    href={ExternalUrls.AIRMEET_LINKEDIN}
                                >
                                    <Icon
                                        icon={linkedinIcon}
                                        color='ambience.5'
                                        width='25'
                                        height='25'
                                    />
                                </FooterLink>
                            </Box>
                        </Box>
                        <Box
                            flexDirection={'column'}
                            mb={[2, 3]}
                            minWidth={150}
                            pb={[4, 0]}
                            borderBottomStyle={'solid'}
                            borderBottomWidth={[1, 0]}
                            borderBottomColor={theme.colors.ambience[19]}
                        >
                            <Typography
                                mt={2}
                                mb={2}
                                variant={'h6'}
                                color='ambience.10'
                            >
                                {LocalizedString[langKey].column5.title}
                            </Typography>
                            <FooterLink
                                target='_blank'
                                rel='noreferrer'
                                href='https://www.gmsintercert.com/verify/single-cert-verify.php?cRegName=ISMS2021010'
                            >
                                <Typography
                                    mt={2}
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].column5
                                            .navmenu[0]
                                    }
                                </Typography>
                            </FooterLink>
                        </Box>
                    </Box>
                    <Hr />
                    <Box
                        pt={40}
                        pb={56}
                        maxWidth={1100}
                        width='100%'
                        mx={'auto'}
                        flexWrap={['wrap']}
                        className={'gdpr-footer-row'}
                    >
                        <Box mr={5} mt={'10px'}>
                            <FooterLink
                                onClick={() =>
                                    handleCTAClick(ExternalUrls.TERMS_OF_USE)
                                }
                                style={{ cursor: 'pointer' }}
                            >
                                <Typography
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].footerBottom
                                            .navmenu[0]
                                    }
                                </Typography>
                            </FooterLink>
                        </Box>
                        <Box mr={5} mt={'10px'}>
                            <FooterLink
                                onClick={() =>
                                    handleCTAClick(ExternalUrls.PRIVACY_POLICY)
                                }
                                style={{ cursor: 'pointer' }}
                            >
                                <Typography
                                    variant={'body2'}
                                    color='ambience.15'
                                >
                                    {
                                        LocalizedString[langKey].footerBottom
                                            .navmenu[1]
                                    }
                                </Typography>
                            </FooterLink>
                        </Box>
                        <Box mt={'10px'}>
                            <Typography variant={'body2'} color='ambience.15'>
                                {whiteLabelText('© 2024 Airmeet Inc.', '')}
                            </Typography>
                        </Box>
                    </Box>
                </FooterWrap>
            )}
        </>
    );
};

export default Footer;

const FooterWrap = styled.div`
    ${layout}
    ${space}
    ${border}
    ${background}
    ${color}
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
`;

const Box = styled.div`
    ${layout}
    ${space}
    ${flexbox}
    ${position}
    ${border}
    ${background}
    ${color}
    display: flex;

    a,
    span {
        transition: all 0.3s;
        :hover {
            opacity: 0.8;
        }
    }
`;

const FooterLink = styled.a`
    ${space}
    text-decoration: none !important;
    transition: all 0.3s;
    display: inline-block;
    width: max-content;
    :hover {
        opacity: 0.8;
    }
`;

const Hr = styled.hr`
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.ambience[19]};
    opacity: 0.25;
`;
