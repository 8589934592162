import { Box } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import useTheme from 'hooks/useTheme';
import React from 'react';

export default function PollOption({
    highest = false,
    percent = 0,
    nbResponses = 0,
    label = '',
    index = 0,
}) {
    const { theme } = useTheme();

    return (
        <Box
            mb={2}
            background={
                theme?.isLightTheme
                    ? theme.colors.ambience[24]
                    : 'rgba(38, 37, 45, 0.64)'
            }
            borderRadius={1}
            p='12px'
            overflow='hidden'
            position='relative'
        >
            <Box display='flex' alignItems='flex-start'>
                <Box flexGrow={1}>
                    <Typography variant='subtext1' color='ambience.4'>
                        {label}
                    </Typography>
                </Box>
                <Box textAlign='right'>
                    <Typography
                        textAlign='right'
                        variant='body1'
                        color='ambience.0'
                    >
                        {percent}%
                    </Typography>
                    <Typography
                        textAlign='right'
                        variant='caption'
                        color='ambience.8'
                    >
                        {nbResponses} responses
                    </Typography>
                </Box>
            </Box>
            <hr
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    height: '4px',
                    borderRadius: '2px',
                    width: `${percent}%`,
                    backgroundColor: highest ? '#8981DD' : '#5AB8BF',
                    margin: 0,
                    padding: 0,
                    transition: `width 0.5s ease-out ${index / 10}s`,
                }}
            />
        </Box>
    );
}
