import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSideMenuProps } from '../../store/actions/layout';

function SideMenuToggle() {
    const dispatch = useDispatch();
    const { enabled, visible } = useSelector((state) => state.layout.sideMenu);
    const toggleMenuVisible = () =>
        dispatch(setSideMenuProps({ visible: !visible }));

    return enabled ? (
        <div onClick={toggleMenuVisible}>
            <i className='fa fa-bars' aria-hidden='true'></i>
        </div>
    ) : null;
}

export default SideMenuToggle;
