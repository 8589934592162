import Box from 'atoms/Box';
import fscreen from 'fscreen';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { noop } from 'utils/constants/common';
import { logger } from 'utils/logger';

export const FULLSCREEN_PORTAL_ID = 'fullscreen-portal';
export const FULLSCREEN_MOUSE_HOVER_CLASS_NAME = 'fullscreen-hover';
export const FULLSCREEN_IDLE_CLASS_NAME = 'fullscreen-hide-mouse-idle';
export const FULLSCREEN_DOUBLE_CLICK_CLASS_NAME = 'fullscreen-db';
const UPDATE_IDLE_STATE_AFTER_DELAY = 5000;

const FullScreen = ({
    enabled = false,
    onChange = noop,
    children = <></>,
    className = '',
    id = '',
    mouseOverDelay = UPDATE_IDLE_STATE_AFTER_DELAY,
    allowDoubleClick = false,
    allowFullScreen = true,
    wrapperStyle = {},
}) => {
    const nodeRef = useRef();
    const lastHoverTrack = useRef(Date.now());
    const lastHoverWatch = useRef();
    const unmountCallback = useRef(noop);
    const toggleFullScreen = useRef(noop);
    unmountCallback.current = () => {
        if (enabled && onChange) {
            onChange(false);
        }
    };

    const enterFullScreen = useCallback(() => {
        if (fscreen.fullscreenEnabled) {
            fscreen.requestFullscreen(nodeRef.current);
        }
    }, []);

    const leaveFullScreen = useCallback(() => {
        if (fscreen.fullscreenEnabled) {
            fscreen.exitFullscreen();
        }
    }, []);

    toggleFullScreen.current = () => {
        if (enabled) {
            leaveFullScreen();
        } else if (!enabled) {
            enterFullScreen();
        }
    };
    const fullScreenOnDoubleClick = useCallback((e) => {
        const targetEl = e?.target;
        if (!targetEl) {
            return;
        }
        const fullscreenClass = FULLSCREEN_DOUBLE_CLICK_CLASS_NAME;
        if (
            targetEl.classList.contains(fullscreenClass) ||
            (targetEl.closest && targetEl.closest(`.${fullscreenClass}`))
        ) {
            e.preventDefault();
            toggleFullScreen.current();
        }
    }, []);

    const onMouseMoveHandler = useCallback(() => {
        const handler = (mouseMove = false) => {
            if (!nodeRef.current) {
                return;
            }
            if (mouseMove) {
                nodeRef.current.classList.add(
                    FULLSCREEN_MOUSE_HOVER_CLASS_NAME
                );
            } else {
                nodeRef.current.classList.remove(
                    FULLSCREEN_MOUSE_HOVER_CLASS_NAME
                );
            }
        };

        if (nodeRef.current && enabled) {
            if (lastHoverTrack.current + 100 > Date.now()) {
                return;
            }
            lastHoverTrack.current = Date.now();
            handler(true);
            if (lastHoverWatch.current) {
                clearTimeout(lastHoverWatch.current);
            }
            lastHoverWatch.current = setTimeout(() => {
                handler(false);
            }, mouseOverDelay || UPDATE_IDLE_STATE_AFTER_DELAY);
        } else if (nodeRef.current) {
            if (lastHoverWatch.current) {
                clearTimeout(lastHoverWatch.current);
            }
            handler(false);
        }
    }, [mouseOverDelay, enabled]);

    useEffect(() => {
        return () => {
            unmountCallback.current && unmountCallback.current();
        };
    }, []);

    useEffect(() => {
        const fullScreenEnabled = fscreen.fullscreenElement === nodeRef.current;
        if (fullScreenEnabled && !enabled) {
            leaveFullScreen();
            onMouseMoveHandler();
        } else if (!fullScreenEnabled && enabled) {
            onMouseMoveHandler();
            enterFullScreen();
        }
    }, [enabled, enterFullScreen, leaveFullScreen, onMouseMoveHandler]);

    useEffect(() => {
        if (!allowFullScreen) return;
        const detectFullScreen = () => {
            if (!onChange) {
                return;
            }
            logger.debug(fscreen.fullscreenElement === nodeRef.current);
            onChange(fscreen.fullscreenElement === nodeRef.current);
        };
        fscreen.addEventListener('fullscreenchange', detectFullScreen);

        return () => {
            fscreen.removeEventListener('fullscreenchange', detectFullScreen);
        };
    }, [onChange, allowFullScreen]);

    const wrapperClassName = useMemo(() => {
        const wrapperClassName = ['fullscreen-wapper'];
        if (className) {
            wrapperClassName.push(className);
        }
        if (enabled) {
            wrapperClassName.push('fullscreen');
        }
        return wrapperClassName.join(' ');
    }, [className, enabled]);

    const handleMouseMove = () => {
        allowFullScreen && onMouseMoveHandler();
    };

    return (
        <FullscreenWrapper
            className={wrapperClassName}
            onDoubleClick={
                allowDoubleClick ? fullScreenOnDoubleClick : undefined
            }
            ref={nodeRef}
            style={
                enabled
                    ? { ...(wrapperStyle || {}), height: '100%', width: '100%' }
                    : wrapperStyle || undefined
            }
            onMouseMove={handleMouseMove}
            id={id}
        >
            {children}
            {/* Portal to render content when on full screen */}
            <div id={FULLSCREEN_PORTAL_ID} />
            {enabled && <div id='popup-root' />}
        </FullscreenWrapper>
    );
};
export default FullScreen;

const FullscreenWrapper = styled(Box)`
    &.fullscreen:not(.${FULLSCREEN_MOUSE_HOVER_CLASS_NAME}) {
        .${FULLSCREEN_IDLE_CLASS_NAME} {
            opacity: 0;
        }
        .${FULLSCREEN_IDLE_CLASS_NAME}-content {
            display: none;
        }
    }
`;
