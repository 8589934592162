import { useContainerNotifications } from 'atoms/Container/container';
import { useLiveStreamsContext } from 'context/LiveStreams';
import { useManageShowOnStageContext } from 'context/ManageShowOnStage';
import { useLiveSessionContext } from 'context/Session';
import {
    DEFAULT_USERS_BLOCKS_ON_STAGE,
    LAYOUTS,
    LAYOUT_MODES,
    STAGE_INTERNAL_VIEWS,
    STAGE_VIEWS,
    STAGE_VIEW_MAPPING,
} from 'context/StageLayout';
import { useStageRTCContext } from 'context/StageRTCProvider';
import { useShallowEqualSelector } from 'hooks/common';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import { useEffect, useMemo, useState } from 'react';
import { Events } from 'utils/airmeet';
import { emptyArray, emptyObject } from 'utils/constants/common';
import { MAX_PINNED_USERS } from 'utils/constants/live-airmeet';
import {
    FULLSCREEN_MEDIA_CONTENT,
    WINDOWED_SCREEN_MEDIA_CONTENT,
} from 'utils/constants/sessions';
import PermissionUtils from 'utils/permission-utils';

export const maxPinnedUsers = MAX_PINNED_USERS.V2;

const closedCaptionsEnabledSelector = ({
    closedCaptions: { isEnabled, isLoaded },
}) => isEnabled && isLoaded;

function useSpeakerOrders(rawStreams, selectedLayout, isPipMode) {
    const { onNotify, offNotify } = useContainerNotifications();
    const { stageService: client } = useStageRTCContext();
    const [doRender, setReRender] = useState(0);
    const [isFullScreenMediaContent, setFullScreenMediaContent] = useState(
        false
    );
    const isClosedCaptionsEnabled = useShallowEqualSelector(
        closedCaptionsEnabledSelector
    );
    const [pinnedUsers, setPinnedUsers] = useState(null);
    const {
        firebaseClient,
        setFullScreen: setWindowFullScreen,
        fullScreen: isWindowFullScreen,
    } = useLiveAirmeetContext();
    const stageRoomNode = useLiveSessionContext((ctx) => ctx?.stageRoomNode);

    const pinUsersPath = stageRoomNode
        ? `${stageRoomNode}/layout/pinUsers`
        : null;

    useEffect(() => {
        const onData = ({ value }) => {
            setPinnedUsers(value);
        };
        if (pinUsersPath) {
            firebaseClient.getDataSync(pinUsersPath, onData);

            return () => {
                firebaseClient.clearDataSync(pinUsersPath, onData);
            };
        }
    }, [firebaseClient, pinUsersPath]);

    useEffect(() => {
        const updateRenders = () => {
            setReRender(Math.random());
        };
        client.on(Events.newActiveSpeaker, updateRenders);
        client.on(Events.inactiveSpeaker, updateRenders);

        return () => {
            client.off(Events.newActiveSpeaker, updateRenders);
            client.off(Events.inactiveSpeaker, updateRenders);
        };
    }, [client]);

    useEffect(() => {
        const onFullScreen = () => {
            setFullScreenMediaContent(true);
            setWindowFullScreen(true);
        };
        const onExitFullScreen = () => {
            setFullScreenMediaContent(false);
        };
        onNotify(FULLSCREEN_MEDIA_CONTENT, onFullScreen);
        onNotify(WINDOWED_SCREEN_MEDIA_CONTENT, onExitFullScreen);
        return () => {
            offNotify(FULLSCREEN_MEDIA_CONTENT, onFullScreen);
            offNotify(WINDOWED_SCREEN_MEDIA_CONTENT, onExitFullScreen);
            setFullScreenMediaContent(false);
        };
    }, [onNotify, offNotify]);

    useEffect(() => {
        if (!isWindowFullScreen) {
            setFullScreenMediaContent(false);
        }
    }, [isWindowFullScreen]);

    const { isMainStageArea } = useManageShowOnStageContext();

    // Get the media streams
    const screenMediaStream = useLiveStreamsContext(
        (ctx) => ctx.screenStreamData
    );

    const activeSpeakerStreams = useLiveStreamsContext(
        (ctx) => ctx.activeSpeakerStreams
    );

    // Get the active speaker
    const activeSpeakerId = useMemo(
        () =>
            Object.keys(activeSpeakerStreams || emptyObject).find(
                (value) => value !== screenMediaStream?.id
            ),
        [activeSpeakerStreams, screenMediaStream]
    );

    // Get the user streams
    const userRawStreams = useMemo(
        () =>
            screenMediaStream
                ? rawStreams.filter(
                      (streamData) => screenMediaStream.id !== streamData.id
                  )
                : rawStreams,
        [screenMediaStream, rawStreams]
    );

    const sortUserRawStreams = useMemo(() => {
        return userRawStreams
            .map((item) => {
                const activeStreams = client.getActiveStreams();
                const findIndex = activeStreams.indexOf(`${item.id}`);
                const offset = activeSpeakerId ? 1 : 100;
                item.acOrder =
                    findIndex > -1 ? findIndex + offset : item.id + 10000;
                return item;
            })
            .sort(function (a, b) {
                return a.acOrder - b.acOrder;
            });
        // added doRender for sort again base on new value update on event
    }, [userRawStreams, activeSpeakerId, client, doRender]);

    // Get the pin users streams
    const pinUsersStreams = useMemo(
        () =>
            sortUserRawStreams
                .filter((item) => pinnedUsers?.[item.user.id])
                .sort((a, b) => {
                    return pinnedUsers?.[a.user.id] - pinnedUsers?.[b.user.id];
                }),
        [pinnedUsers, sortUserRawStreams]
    );

    // Get the non pin users streams
    const nonPinnedStreams = useMemo(
        () => sortUserRawStreams.filter((item) => !pinnedUsers?.[item.user.id]),
        [pinnedUsers, sortUserRawStreams]
    );
    const totalUsersOnStage = userRawStreams.length;
    const isContentShared = !!screenMediaStream || isMainStageArea;
    const contentLayoutMode = selectedLayout?.[LAYOUTS.CONTENT]?.layoutView;
    const isSelectedLayoutActive = selectedLayout?.[LAYOUTS.USERS]
        ? selectedLayout?.[LAYOUTS.USERS]?.isActive
        : false;
    const preInternalView = useMemo(() => {
        if (isContentShared) {
            if (
                isPipMode ||
                isFullScreenMediaContent ||
                totalUsersOnStage === 0 ||
                contentLayoutMode === LAYOUT_MODES.SPOTLIGHT
            ) {
                return STAGE_INTERNAL_VIEWS.FOCUS_CONTENT;
            }

            if (contentLayoutMode === LAYOUT_MODES.NEWS) {
                return STAGE_INTERNAL_VIEWS.USER_WITH_CONTENT;
            }

            if (contentLayoutMode === LAYOUT_MODES.ONE_ON_ONE) {
                return STAGE_INTERNAL_VIEWS.HEAD_TO_HEAD_CONTENT;
            }
            if (isClosedCaptionsEnabled) {
                // return STAGE_INTERNAL_VIEWS.SIDE_ACTIVE_SPEAKERS;
            }
            return STAGE_INTERNAL_VIEWS.SIDE_SPEAKERS;
        }

        if (isPipMode || isClosedCaptionsEnabled || totalUsersOnStage === 1) {
            return STAGE_INTERNAL_VIEWS.SINGLE_SPEAKERS;
        }

        if (
            totalUsersOnStage ===
                DEFAULT_USERS_BLOCKS_ON_STAGE[
                    STAGE_INTERNAL_VIEWS.HEAD_TO_HEAD
                ] &&
            (isSelectedLayoutActive || pinUsersStreams.length === 1)
        ) {
            return STAGE_INTERNAL_VIEWS.HEAD_TO_HEAD;
        }

        if (isSelectedLayoutActive) {
            return STAGE_INTERNAL_VIEWS.BOTTOM_BAR;
        }

        if (!!pinUsersStreams.length) {
            return STAGE_INTERNAL_VIEWS.PINNED_SPEAKERS;
        }
        return STAGE_INTERNAL_VIEWS.MATRIX;
    }, [
        isContentShared,
        isSelectedLayoutActive,
        contentLayoutMode,
        isPipMode,
        isClosedCaptionsEnabled,
        pinUsersStreams,
        totalUsersOnStage,
        isFullScreenMediaContent,
    ]);

    // process streams
    const allStreams = useMemo(() => {
        const activeSpeakerFilter = () => {
            const gridStreams = sortUserRawStreams.filter(
                (item) => parseInt(activeSpeakerId) === parseInt(item.id)
            );
            const galleryStreams = sortUserRawStreams.filter(
                (item) => parseInt(activeSpeakerId) !== parseInt(item.id)
            );
            return {
                gridStreams: screenMediaStream
                    ? [...gridStreams, screenMediaStream]
                    : gridStreams,
                galleryStreams: galleryStreams,
            };
        };

        const contentSpeakerFilter = () => {
            return {
                gridStreams: screenMediaStream
                    ? [screenMediaStream]
                    : emptyArray,
                galleryStreams: sortUserRawStreams,
            };
        };
        const pinnedSpeakerFilter = () => {
            return {
                gridStreams: pinUsersStreams,
                galleryStreams: nonPinnedStreams,
            };
        };
        switch (preInternalView) {
            case STAGE_INTERNAL_VIEWS.FOCUS_CONTENT:
            case STAGE_INTERNAL_VIEWS.SIDE_ACTIVE_SPEAKERS:
            case STAGE_INTERNAL_VIEWS.SIDE_SPEAKERS: {
                return contentSpeakerFilter();
            }
            case STAGE_INTERNAL_VIEWS.PINNED_SPEAKERS: {
                return pinnedSpeakerFilter();
            }
            case STAGE_INTERNAL_VIEWS.SINGLE_SPEAKERS:
            case STAGE_INTERNAL_VIEWS.USER_WITH_CONTENT:
            case STAGE_INTERNAL_VIEWS.BOTTOM_BAR: {
                return activeSpeakerFilter();
            }
            case STAGE_INTERNAL_VIEWS.HEAD_TO_HEAD_CONTENT:
                return activeSpeakerFilter();
            case STAGE_INTERNAL_VIEWS.HEAD_TO_HEAD: {
                return pinUsersStreams.length
                    ? pinnedSpeakerFilter()
                    : activeSpeakerFilter();
            }
            case STAGE_INTERNAL_VIEWS.MATRIX:
            default: {
                return {
                    gridStreams: sortUserRawStreams,
                    galleryStreams: emptyArray,
                };
            }
        }
    }, [
        preInternalView,
        sortUserRawStreams,
        activeSpeakerId,
        pinUsersStreams,
        nonPinnedStreams,
        screenMediaStream,
    ]);

    const internalView = useMemo(() => {
        if (
            allStreams.galleryStreams.length === 0 &&
            [STAGE_INTERNAL_VIEWS.PINNED_SPEAKERS].includes(preInternalView)
        ) {
            return STAGE_INTERNAL_VIEWS.MATRIX;
        }
        return preInternalView;
    }, [preInternalView, allStreams]);

    const maxStageUsersLimit = useMemo(() => {
        return DEFAULT_USERS_BLOCKS_ON_STAGE[internalView];
    }, [internalView]);

    const stageView = STAGE_VIEW_MAPPING[internalView] || STAGE_VIEWS.MATRIX;

    // cleanup the local fullscreen mode on screenshare and show content on stage
    useEffect(() => {
        setFullScreenMediaContent(false);
    }, [contentLayoutMode, isContentShared]);

    useEffect(() => {
        if (preInternalView === STAGE_INTERNAL_VIEWS.PINNED_SPEAKERS) {
            client.setPinUsers(
                pinUsersStreams.map((x) => {
                    return x?.id;
                })
            );
        } else {
            client.setPinUsers([]);
        }
    }, [preInternalView, client, pinUsersStreams]);

    const enabledShowMoreForLayout =
        !PermissionUtils.isSessionCloudHost() &&
        ![
            STAGE_VIEWS.HEAD_TO_HEAD,
            STAGE_VIEWS.FOCUS,
            STAGE_VIEWS.USER_WITH_CONTENT,
        ].includes(stageView);

    const isFullScreenControlOnMediaContent = [
        LAYOUT_MODES.NEWS,
        LAYOUT_MODES.SIDE_BAR,
    ].includes(contentLayoutMode);

    return {
        ...allStreams,
        layout: {
            stageView,
            pinUsers: pinnedUsers,
            pinnedItemsCount:
                internalView === STAGE_INTERNAL_VIEWS.PINNED_SPEAKERS
                    ? allStreams.gridStreams.length
                    : 0,
            isFullScreenMediaContent,
            inFocusBlockCount:
                (isContentShared && allStreams.gridStreams.length === 0) ||
                stageView === STAGE_VIEWS.HEAD_TO_HEAD
                    ? 1
                    : stageView === STAGE_VIEWS.USER_WITH_CONTENT
                    ? 2
                    : allStreams.gridStreams.length,
        },
        maxStageUsersLimit,
        enabledShowMoreForLayout,
        isContentShared,
        isFullScreenControlOnMediaContent,
    };
}

export default useSpeakerOrders;
