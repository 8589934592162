export const API_GET_AIRMEET_INFO = 'getAirmeetInfo';
export const API_GET_AIRMEET_USERS = 'getAirmeetUsers';
export const API_GET_ARENA_BOOTHS = 'getArenaBooths';
export const API_GET_BOOTH_INFO = 'getBoothInfo';
export const API_PUT_BOOTH_INFO = 'updateBoothInfo';
export const API_GET_AIRMEET_VENUE_INFO = 'getAirmeetVenueInfo';
export const API_GET_SPONSOR_INFO = 'getSponsorInfo';
export const API_CREATE_LEAD = 'createLead';
export const API_GET_LEAD = 'getLead';
export const API_GET_FORBIDDEN_ACTIONS = 'getForbiddenActions';
export const API_CREATE_NETWORKING_SESSION_MATCH_REQUEST =
    'createNetworkingSessionMatchRequest';
export const API_GET_SN_SESSION_DETAILS = 'getSNSessionDetails';
export const API_POST_SUCCESSFUL_SN_MATCH = 'postSuccessfulSNMatch';

// Breakout APIs
export const API_POST_BREAKOUT = 'postBreakout';
export const API_PATCH_BREAKOUT = 'patchBreakout';
export const API_PATCH_BREAKOUT_ROOM = 'patchBreakoutRoom';
export const API_PUT_BREAKOUT_ROOM_USERS = 'putBreakoutRoomUsers';
export const API_DELETE_BREAKOUT = 'deleteBreakout';
export const API_PUT_REQUESTING_USERS = 'putRequestingUsers';
