import Button from 'atoms/Button/button';
import { Typography } from 'foundations/typography';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { flexbox, space } from 'styled-system';
import { noop } from 'utils/constants/common';

const btnStyle = {
    borderRadius: '8px',
    marginTop: '30px',
};

function Errors({
    errorIcon,
    header,
    subText,
    buttonText = '',
    iconAltText,
    handleClick = noop,
    imgStyles = {},
    titleStyles = {},
    subtitleStyles = {},
    titleVariant = 'h5',
    titleColor = 'ambience.2',
    subtitleVariant = 'subtext1',
    subtitleColor = 'ambience.13',
}) {
    return (
        <>
            <PageContainerOverride />
            <Container justifyContent='center'>
                <Box
                    p={2}
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <SVGWrapper>
                        <img
                            src={errorIcon}
                            alt={iconAltText}
                            style={imgStyles}
                        />
                    </SVGWrapper>
                    <Typography
                        variant={titleVariant}
                        color={titleColor}
                        mt={4}
                        style={titleStyles}
                    >
                        {header}
                    </Typography>
                    <Typography
                        variant={subtitleVariant}
                        color={subtitleColor}
                        mt={1}
                        style={subtitleStyles}
                    >
                        {subText}
                    </Typography>
                    {buttonText && (
                        <BtnContainer>
                            <Button
                                variant='primary'
                                style={btnStyle}
                                onClick={handleClick}
                            >
                                <Typography variant='span' color='ambience.0'>
                                    {buttonText}
                                </Typography>
                            </Button>
                        </BtnContainer>
                    )}
                </Box>
            </Container>
        </>
    );
}
export default Errors;
const Container = styled.div`
    display: flex;
    ${flexbox}
    min-height: calc(100vh - 210px);
    @media (max-width: 768px) and (orientation: portrait) {
        min-height: calc(100vh - 350px);
    }
`;
const Box = styled.div`
    ${space}
    ${flexbox}
    display: flex;
    max-width: 600px;
    width: 100%;
    text-align: center;
    @media (max-width: 768px) {
        h5 {
            margin-top: 8px;
            font-size: 24px;
            line-height: 30px;
        }
    }
`;
const BtnContainer = styled.div`
    width: 200px;
`;
const SVGWrapper = styled.div`
    img {
        max-width: 100%;
        width: 30vw;
        @media (max-width: 768px) {
            max-width: 80%;
            width: 50vw;
        }
    }
`;
const PageContainerOverride = createGlobalStyle`
    .page-container {
        max-width: 100% !important;
    }
    .page--fill{
        min-height: 0;
    }
`;
