export enum PORTAL_KEYS {
    EVENT_RHS = 1,
    MANAGE_ABUSE,
    VIEW_SCHEDULE,
    LEADERBOARD,
    VIEW_DETAILS_RECEPTION,
    VIEW_DETAILS_FLUID_SPACE,
    VIEW_DETAILS_BOOTHS,
    VIEW_DETAILS_BREAKS,
    VIEW_DETAILS_GROUP_MEETINGS,
    VIEW_DETAILS_SCHEDULE_MEETING,
    VIEW_DETAILS_SESSION,
    VIEW_DETAILS_SPEED_NETWORKING,
}
