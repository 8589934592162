import Button from 'atoms/Button/button';
import TooltipV2 from 'atoms/Tooltip/Tooltip.tsx';
import {
    DEFAULT_THUMBNAIL,
    DEFAULT_THUMBNAIL_V2,
} from 'components/molecules/summit/center/resources/constant';
import {
    Box,
    FlexDiv,
    UnstyledButton,
} from 'components/molecules/utils/basicComponents';
import { alpha } from 'foundations/theme';
import { Text } from 'foundations/themeV2/text';
import { Typography } from 'foundations/typography';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { cdnImage } from 'utils/core';
import { StyledSvgSprite } from '../../../../../../general/SVGSprite';

const UploadProgress = ({
    icon: iconProp,
    iconMaxWidth = '116px',
    title = '',
    isUploadFailed = false,
    progress,
    onCancel,
    isThemeV2 = false,
    filesMetaData = [],
}) => {
    const icon =
        iconProp || (isThemeV2 ? null : '/images/dashboard/play-video.svg');
    const {
        fileNames,
        truncatedFileNames,
        fileTypes,
        uniqueFileTypes = [],
    } = useMemo(() => {
        const returnObj = { fileNames: '', fileTypes: '' };

        if (isThemeV2) {
            let fileNames = '';
            const uniqueFileTypes = [];
            (filesMetaData || []).forEach((item) => {
                if (fileNames !== '') {
                    fileNames += ', ';
                }
                fileNames += item?.fileName || '';

                if (!uniqueFileTypes.includes(item?.fileType)) {
                    uniqueFileTypes.push(item?.fileType);
                }
            });
            let fileTypes = '';
            if (uniqueFileTypes.length > 0) {
                fileTypes = uniqueFileTypes.join(', ');
            }

            return {
                fileNames,
                truncatedFileNames:
                    fileNames.length > 50
                        ? `${fileNames.substring(0, 47)}...`
                        : fileNames,
                fileTypes:
                    fileTypes.length > 50
                        ? `${fileTypes.substring(0, 47)}...`
                        : fileTypes,
                uniqueFileTypes,
            };
        }

        return returnObj;
    }, [filesMetaData, isThemeV2]);

    const fileTypeIcon =
        icon ||
        (Array.isArray(uniqueFileTypes) && uniqueFileTypes.length > 0
            ? uniqueFileTypes[0]
            : 'DOCUMENT');

    return (
        <UploadProgressBlock isThemeV2={isThemeV2}>
            <UploadIconBlock isThemeV2={isThemeV2}>
                {isThemeV2 ? (
                    <FlexDiv
                        flexDirection='column'
                        flex='1'
                        textAlign='center'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <StyledSvgSprite
                            icon={
                                isThemeV2
                                    ? DEFAULT_THUMBNAIL_V2[fileTypeIcon]
                                    : DEFAULT_THUMBNAIL[fileTypeIcon]
                            }
                            width={24}
                            height={24}
                            fill='sem.info'
                            mb='x3'
                        />
                        <ProgressBar
                            isFailed={isUploadFailed}
                            progress={progress}
                            isThemeV2={isThemeV2}
                        />
                        <Text
                            variant='caption'
                            color='text.default.primary'
                            mt='x3'
                        >
                            {title ? title : 'Uploading video.'}
                        </Text>
                    </FlexDiv>
                ) : (
                    <img
                        src={cdnImage(icon)}
                        width={'100%'}
                        height={'auto'}
                        alt={'play'}
                        style={{
                            maxWidth: iconMaxWidth,
                        }}
                    />
                )}
            </UploadIconBlock>
            <UploadLoadProgressBlock isThemeV2={isThemeV2}>
                {isThemeV2 ? (
                    <>
                        <FlexDiv>
                            <Text
                                variant='caption'
                                color='text.default.primary'
                                pr='x4'
                                flex='1'
                                title={fileNames}
                            >
                                {truncatedFileNames}
                            </Text>
                            <TooltipV2 content='Cancel upload' side='left'>
                                <UnstyledButton onClick={onCancel}>
                                    <StyledSvgSprite
                                        icon={'icon-times'}
                                        height={20}
                                        width={20}
                                        fill='text.default.primary'
                                    />
                                </UnstyledButton>
                            </TooltipV2>
                        </FlexDiv>
                        {fileTypes && (
                            <Text
                                variant='caption'
                                color='text.default.secondary'
                                pt='x2'
                            >
                                {fileTypes}
                            </Text>
                        )}
                    </>
                ) : (
                    <>
                        <Box>
                            <Typography variant={'button2'} color={'black'}>
                                {title ? title : 'Uploading video.'}
                            </Typography>
                        </Box>
                        <Box>
                            <Button
                                variant={'link'}
                                color={'accentPrimary.0'}
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Box>
                        <ProgressBar
                            isFailed={isUploadFailed}
                            progress={progress}
                        />
                    </>
                )}
            </UploadLoadProgressBlock>
        </UploadProgressBlock>
    );
};

export default UploadProgress;

const UploadProgressBlock = styled(Box)`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: ${({ isThemeV2, theme }) =>
        isThemeV2 ? theme.radii.default : '8px'};
    overflow: hidden;
    z-index: 1000;
`;

const UploadIconBlock = styled(Box)`
    background: ${({ theme, isThemeV2 }) =>
        isThemeV2 ? '' : alpha(theme.colors.ambience[24], 0.8)};
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const UploadLoadProgressBlock = styled(FlexDiv)`
    position: relative;
    padding: ${({ theme, isThemeV2 }) =>
        isThemeV2 ? theme.space.x4 : '16px 16px 20px 16px'};
    border-radius: ${({ isThemeV2, theme }) =>
        isThemeV2
            ? `0 0 ${theme.radii.default} ${theme.radii.default}`
            : '0px 0px 8px 8px'};
    overflow: hidden;
    background: ${({ theme, isThemeV2 }) =>
        isThemeV2
            ? theme.colors.fill.other3
            : theme.colors.ambience[theme?.isLightTheme ? 24 : 0]};
    border: 1px dashed ${({ theme }) => theme.colors.ambience[18]};
    border-top: 0;

    ${({ isThemeV2 }) =>
        isThemeV2
            ? `
        flex-direction: column;
        align-items: stretch;
    `
            : `
        align-items: center;
        justify-content: space-between;
    `}
`;

const ProgressBar = styled.div`
    width: 100%;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: ${({ progress }) => progress && progress + '%'};
        background: ${({ theme, isFailed, isThemeV2 }) =>
            isFailed
                ? isThemeV2
                    ? theme.colors.sem.error
                    : theme.colors.lighting[6]
                : isThemeV2
                ? theme.colors.sem.success
                : theme.colors.lighting[0]};
        border-radius: 0px 0px 2px 2px;
        transition: width 0.6s ease;
    }

    ${({ isThemeV2, theme }) =>
        isThemeV2
            ? `
            position: relative;
            background: ${theme.colors.fill.other3};
            border: 1px solid ${theme.colors.border.default2};
            height: ${theme.space.x2};
            border-radius: ${theme.space.x1};
            max-width: 15rem;
            margin: 0 ${theme.space.x4};
    `
            : `
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${theme.colors.ambience[theme?.isLightTheme ? 6 : 1]};
        height: 8px;
        border-radius: 4px;
    `}
`;
