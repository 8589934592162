import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';

const { STAGE_LAYOUT } = FEATURE_NAMES;

const stageLayoutWriter = async ({ client, metaData, payload, airmeetId }) => {
    const { actionType, speakerId, stageId } = metaData;
    const stageRoomNode = getStageRoomNode(airmeetId, stageId);
    if (actionType === FEATURE_ACTIONS[STAGE_LAYOUT].CHANGE_LAYOUT) {
        client.setData(`${stageRoomNode}/mode`, payload);
    }
    if (actionType === FEATURE_ACTIONS[STAGE_LAYOUT].CHANGE_LAYOUT_MODE) {
        client.setData(`${stageRoomNode}/layoutMode`, payload);
    }
    if (actionType === FEATURE_ACTIONS[STAGE_LAYOUT].PIN) {
        client.setData(`${stageRoomNode}/pinUsers/${speakerId}`, payload);
    }
    if (actionType === FEATURE_ACTIONS[STAGE_LAYOUT].UNPIN) {
        client.setData(`${stageRoomNode}/pinUsers/${speakerId}`, payload);
    }
    if (actionType === FEATURE_ACTIONS[STAGE_LAYOUT].FOCUS) {
        payload && delete payload.timestamp;
        client.setData(`${stageRoomNode}/pinUsers`, payload);
    }
    if (actionType === FEATURE_ACTIONS[STAGE_LAYOUT].UPDATE_ORDER) {
        client.setData(`${stageRoomNode}/gridOrder/${speakerId}`, payload);
    }
};

const getStageRoomNode = (airmeetId, stageId) =>
    `${airmeetId}/meta-data/sessions/${stageId}/layout`;

export default stageLayoutWriter;
