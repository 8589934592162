import useToasts from 'hooks/useToasts';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyCommunities } from '../store/actions';
import {
    loginFromLocalStorage,
    logoutUser,
    setProcessing,
    updateLoggedInAt,
    updateUserManagedCommunity,
} from '../store/actions/auth';
import {
    fetchAuthNewUserRegionDetail,
    fetchAuthUserDetail,
} from '../store/actions/login';
import AuthService from '../utils/authService';
import { logger } from '../utils/logger';

const localLog = logger.init('AuthManager:', 'red');

/**
 * Authentication manager wrapper in a React hook
 * This should stay alive for the lifetime of the app,
 * and will setup/breakdown the central user authentication state.
 */
export default function useAuthManager() {
    const dispatch = useDispatch();
    const { errorToast } = useToasts();
    const { user, accessCode } = useSelector((state) => state.auth);
    const loggedInAt = useSelector((state) => state.auth.loggedInAt);
    const communityData = useSelector((state) => state.Community.myCommunity);
    useEffect(() => {
        if (loggedInAt == null && AuthService.loggedInAt) {
            // Update the auth tokens with local storage values, if available
            dispatch(updateLoggedInAt(AuthService.loggedInAt));
        }
        if (user == null && AuthService.getCachedUser()) {
            // Use any cached user data
            dispatch(loginFromLocalStorage());
        }
    }, []);
    // On any change to auth tokens, this should run
    useEffect(() => {
        // Do we have a saved token?
        if (!!loggedInAt) {
            // Fetch the user details, and update local state
            const fetchDetails = async () => {
                dispatch(setProcessing());
                try {
                    localLog('Fetching user detail.');
                    await dispatch(fetchAuthNewUserRegionDetail());
                    await dispatch(fetchAuthUserDetail());
                    // Before sending a request re-check for auth token,
                    // Sometime cleanup of auth token and reset with other token,
                    // In that case we do not called the below method with old token
                    if (
                        AuthService.loggedInAt &&
                        !(
                            window.location.pathname.includes('/e/') ||
                            window.location.pathname.includes('/event/') ||
                            window.location.pathname.includes(
                                '/order-detail'
                            ) ||
                            window.location.pathname.includes('/es/')
                        )
                    ) {
                        await dispatch(getMyCommunities());
                    }
                } catch (e) {
                    console.error(e);
                    dispatch(logoutUser());
                    errorToast('Could not login. Please try after a while.');
                } finally {
                    dispatch(setProcessing(false));
                }
            };

            if (loggedInAt !== AuthService.loggedInAt && loggedInAt) {
                AuthService.loggedInAt = loggedInAt;
                localLog('set the logged in at before fetch user details', {
                    reduxLoggedInAt: loggedInAt,
                    authServiceLoggedInAt: AuthService.loggedInAt,
                });
            }
            fetchDetails();
        } else if (!!user && !accessCode) {
            localLog('Logging out.');
            dispatch(logoutUser());
        } else if (
            (loggedInAt || !AuthService.getCachedUser()) &&
            loggedInAt !== AuthService.loggedInAt
        ) {
            AuthService.loggedInAt = loggedInAt;
            localLog('set the logged in at from useEffect', {
                reduxLoggedInAt: loggedInAt,
                authServiceLoggedInAt: AuthService.loggedInAt,
                cachedUser: AuthService.getCachedUser(),
            });
        }
    }, [loggedInAt]);

    useEffect(() => {
        const { cm, em, list } = communityData;
        let displayRole = '',
            userRole = '',
            defaultCommunity = null;

        if (cm && cm.length > 0) {
            userRole = 'owner';
            defaultCommunity = cm[0];
            displayRole = 'Community Manager';
        } else if (em && em.length > 0) {
            userRole = 'admin';
            defaultCommunity = em[0];
            displayRole = 'Event Manager';
        } else if (list && list.length > 0) {
            userRole = 'owner';
            defaultCommunity = list[0];
            displayRole = 'Community Manager';
        }

        if (defaultCommunity) {
            const community = {
                communityId: defaultCommunity.communityId,
                name: defaultCommunity.name,
                role: userRole,
                status: defaultCommunity?.status || '',
            };

            dispatch(updateUserManagedCommunity(community, displayRole));
        }
    }, [communityData]);

    // No render
    return null;
}
