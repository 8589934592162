import { EVENT_TEMPLATE_TYPES, EVENT_TYPES } from 'utils/constants/airmeet';
import { SESSION_TYPES } from 'utils/constants/sessions';
import { cdnImage } from 'utils/core';

export const EVENTS_PAGE_TAB = 'events';
export const VIDEO_LIBRARY_PAGE_TAB = 'video-library';
export const TEAM_PAGE_TAB = 'team';
export const SETTINGS_TAB = 'settings';
export const BILLING_PAGE_TAB = 'billing-page';
export const APP_SUMO_PAGE = 'appsumo-plans';
export const GETTING_STARTED_TAB = 'getting-started';
export const ONBOARDING_PAGE_TAB = 'onboarding';
export const HELP_PAGE_TAB = 'help';
export const CONTENT_HUB_TAB = 'content-hub';
export const LIBRARY_PAGE_TAB = 'library';
export const ANALYTICS_TAB = 'analytics';
export const AUDIENCE_TAB = 'audience';
export const ANALYTICS_EVENTS_TAB = 'analytics-events';

const thumbBasePath = '/images/dashboard/event-templates/';

const commonProps = {
    eventType: EVENT_TYPES.MEETUP,
};

export const TEMPLATE_FILTERS = [
    {
        id: 'MARKETING',
        title: 'Marketing',
    },
    {
        id: 'CUSTOMER_SUCCESS',
        title: 'Customer Success',
    },
    {
        id: 'EMPLOYEE_SUCCESS',
        title: 'Employee Success',
    },
];

export const EVENT_TEMPLATE_TYPE_TOOLTIP = {
    [EVENT_TYPES.MEETUP]:
        'Use this to host Webinars that perform big on engagement and ROI. Attendees can experience different activities sequentially as per your choice.',
    [EVENT_TYPES.CONFERENCE]:
        'Use this to conduct branded conferences & expos where the attendees can make a choice to experience different activities in different tracks & areas of the event.',
};

export const EVENT_TEMPLATES = [
    {
        ...commonProps,
        id: 'webinar',
        category: 'MARKETING',
        code: EVENT_TEMPLATE_TYPES.WEBINAR,
        title: 'Webinar',
        frequency: 'Weekly/Monthly',
        eventDuration: '1 Hour',
        description:
            'Host a talk or presentation with speakers to educate and engage your audience.',
        thumbnail: `${thumbBasePath}event-template-webinar-thumbnail_v2.png`,
        previewThumb: `${thumbBasePath}event-template-webinar-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Webinar',
                duration: '1 hour',
                type: SESSION_TYPES?.HOSTING,
                sessions: [
                    {
                        icon: '✍🏻',
                        title: 'Get ready with the speakers',
                        description:
                            'On the backstage, you can work with the speakers to get things ready for the session.',
                    },
                    {
                        icon: '🎥',
                        title: 'Host an interactive session',
                        description:
                            'Let the speakers present or talk about the relevant topics. As a host, you can keep showing relevant chat messages on stage and pin the main speaker to highlight them.',
                    },
                    {
                        icon: '🙋🏻‍',
                        title: 'Answer the questions',
                        description:
                            'Once the talk is done, you can take the questions using Q&A, highlight the question on stage and invite the question asker to the stage to elaborate more.',
                    },
                    {
                        icon: '🎤️',
                        title: 'Bring the attendees on stage',
                        description:
                            'You can ask the attendees to raise their hand and come on stage to clear their doubts.',
                    },
                    {
                        icon: '😀️',
                        title: 'Get feedback from the attendees',
                        description:
                            'You can end your session with a feedback survey by enabling typeform on the stage RHS.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        eventType: EVENT_TYPES?.CONFERENCE,
        id: 'brandConference',
        category: 'MARKETING',
        code: EVENT_TEMPLATE_TYPES.BRAND_CONFERENCE,
        title: 'Brand Conference',
        frequency: 'Yearly',
        eventDuration: '3 Hour',
        thumbnail: `${thumbBasePath}event-template-brand-conference-thumbnail.png`,
        description:
            'Showcase your products, services & brand by hosting an interactive brand conference. You can build your brand awareness by discussing the trending topics in the industry and showcase thought leadership.',
        previewThumb: `${thumbBasePath}event-template-brand-conference-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Welcome Party',
                duration: '30 mins',
                type: SESSION_TYPES?.FLUID_LOUNGE,
                sessions: [
                    {
                        icon: '🎩',
                        title: 'Red Carpet Experience',
                        description:
                            'Create a red carpet experience using fluid space to welcome the attendees and speakers. Let them move around in the space to mingle with each other.',
                    },
                ],
            },
            {
                title: 'Keynote',
                duration: '30 mins',
                type: SESSION_TYPES?.HOSTING,
                sessions: [
                    {
                        icon: '🌌',
                        title: 'Set the theme',
                        description:
                            'Set the tone of the event by organizing a keynote session by high-value speakers.',
                    },
                    {
                        icon: '🎥',
                        title: 'Preview videos',
                        description:
                            'Use pre-recorded videos in transition with the speaker to show event previews and contextual videos.',
                    },
                ],
            },
            {
                title: 'Thought Leadership',
                duration: '30 mins',
                type: SESSION_TYPES?.HOSTING,
                sessions: [
                    {
                        icon: '🗣️',
                        title: 'Share innovative insights',
                        description:
                            'Invite industry experts to share new ideas and insights about the industry with the audience.',
                    },
                    {
                        icon: '💬',
                        title: 'Interact with the audience',
                        description:
                            'Use Q&A and chat to interact with the audience by seeking questions on the new ideas.',
                    },
                ],
            },
            {
                title: 'Break',
                duration: '30 mins',
                type: SESSION_TYPES?.SPEED_NETWORKING,
                sessions: [
                    {
                        icon: '💁🏻‍',
                        title: 'Networking',
                        description:
                            'Let the audience match with each other during the break and make connections.',
                    },
                ],
            },
            {
                title: 'Panel Discussion',
                duration: '30 mins',
                type: SESSION_TYPES?.HOSTING,
                sessions: [
                    {
                        icon: '👥‍',
                        title: 'Moderate discussion',
                        description:
                            'Invite high-value speakers to discuss a topic moderated by the session host.',
                    },
                    {
                        icon: '❓',
                        title: 'Take questions from the audience',
                        description:
                            'Use Q&A moderation to take questions from the audience & highlight the question on stage while the speakers answer it.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'townhall',
        category: 'EMPLOYEE_SUCCESS',
        code: EVENT_TEMPLATE_TYPES.TOWNHALL,
        title: 'Townhall',
        frequency: 'Monthly/Quarterly',
        eventDuration: '1 Hour 45 Mins',
        thumbnail: `${thumbBasePath}event-template-townhall-thumbnail.png`,
        description:
            'Host your organization/department town hall to keep the employees engaged and motivated.',
        previewThumb: `${thumbBasePath}event-template-townhall-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Know your colleagues',
                duration: '45 mins',
                type: SESSION_TYPES?.SPEED_NETWORKING,
                sessions: [
                    {
                        icon: '🤝‍',
                        title: 'Let the employees match with each other',
                        description:
                            'Start the speed networking session so that the employees can match among each other randomly so that they can get to know each other.',
                    },
                    {
                        icon: '🤼‍♂️‍',
                        title: 'Set up matching rules',
                        description:
                            'You can also create a custom field for departments with a limited set of options and create matching rules to match specific departments.',
                    },
                ],
            },
            {
                title: 'Town hall',
                duration: '1 hour',
                type: SESSION_TYPES?.HOSTING,
                sessions: [
                    {
                        icon: '👋‍',
                        title: 'Introduce new hires',
                        description:
                            'Introduce new hires by bringing them on the stage one by one and asking them the questions.',
                    },
                    {
                        icon: '🙏🏻',
                        title: 'Express gratitude',
                        description:
                            'Pre-record a video asking the employees to thank and give kudos to their colleagues and play the same video in the session.',
                    },
                    {
                        icon: '🎉',
                        title: 'Celebrate',
                        description:
                            'Celebrate the employees who have their work anniversaries.',
                    },
                    {
                        icon: '❓',
                        title: 'Presentation & Questions',
                        description:
                            'Invite the leaders to share company updates and have an interactive AMA session using the QnA moderation, show questions on stage and polls.',
                    },
                    {
                        icon: '⛳️',
                        title: 'Games',
                        description:
                            'Play a Kahoot quiz with the employees using the Airmeet Kahoot integration.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        eventType: EVENT_TYPES?.CONFERENCE,
        id: 'tradeshow',
        category: 'MARKETING',
        code: EVENT_TEMPLATE_TYPES.TRADESHOW,
        title: 'Tradeshow',
        frequency: 'Yearly',
        eventDuration: '',
        thumbnail: `${thumbBasePath}event-template-tradeshow-thumbnail.png`,
        description:
            'Invite companies and organizations of a particular industry to showcase their products, services, and new innovations to potential customers and other interested parties.',
        previewThumb: `${thumbBasePath}event-template-tradeshow-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Explore booths',
                duration: '6 hour',
                type: SESSION_TYPES?.BOOTH,
                sessions: [
                    {
                        icon: '🗣️',
                        title: 'Set up booths',
                        description:
                            'Ask the exhibitors to set up their booths using branded content.',
                    },
                    {
                        icon: '🪑',
                        title: 'Usher the attendees to the booths',
                        description:
                            'Use live announcements to direct the attendees to the booths. Also, use it to announce breaks where the attendees can gather in the lounge.',
                    },
                    {
                        icon: '🔴',
                        title: 'Live broadcast in booths',
                        description:
                            'Encourage the exhibitors to use live broadcast in the booths to demo their products/services to the booth visitors.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'productWorkshop',
        category: 'MARKETING',
        code: EVENT_TEMPLATE_TYPES.PRODUCT_WORKSHOP,
        title: 'Product Workshop',
        frequency: 'Monthly',
        eventDuration: '3 Hours',
        thumbnail: `${thumbBasePath}event-template-product-workshop-thumbnail.png`,
        description:
            'Workshops are meant to give attendees hands-on experience with the subject matter. Invite your prospects & customers to make the most of your product workshop.',
        previewThumb: `${thumbBasePath}event-template-product-workshop-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Workshop',
                duration: '1 hour',
                type: SESSION_TYPES?.HOSTING,
                sessions: [
                    {
                        icon: '💻',
                        title: 'Showcase the product & features',
                        description:
                            'The workshop session should start with the speakers showcasing the product/feature followed by taking the questions from the attendees.',
                    },
                    {
                        icon: '✋🏻',
                        title: 'Let the attendees try hands-on',
                        description:
                            'After showcasing, you can break out the attendees to make them do the same. The session and breakout can keep alternating. The instructor demonstrates certain activities which are then followed by the attendees in small groups.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'salesTraining',
        category: 'SALES',
        code: EVENT_TEMPLATE_TYPES.SALES_TRAINING,
        title: 'Sales Training',
        frequency: 'Bi-monthly',
        eventDuration: '1 Hour',
        thumbnail: `${thumbBasePath}event-template-sales-training-thumbnail.png`,
        description:
            'Use session as a video call to have an interactive training session with your sales team',
        previewThumb: `${thumbBasePath}event-template-sales-training-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Training session',
                duration: '1 hour',
                type: SESSION_TYPES?.GROUP_MEETING,
                sessions: [
                    {
                        icon: '💰',
                        title: 'Product & Pricing Updates',
                        description:
                            'Use video call as a session to share the product updates & pricing updates to the sales team. Also, share the competitive intelligence and other information that will help them in achieving their goals.',
                    },
                    {
                        icon: '❓',
                        title: 'Take questions from the team',
                        description:
                            'Take questions from the sales team during the session or at the end of the session based on your presentation style.',
                    },
                    {
                        icon: '😀️',
                        title: 'Collect feedback',
                        description:
                            'Conduct polls to understand feedback for the training sessions as well as to discover the pain points that the sales team has.',
                    },
                    {
                        icon: '🎯️',
                        title: 'Kahoot',
                        description:
                            'After the session, run a Kahoot quiz to test the sales team on the knowledge gained through the training and reward the toppers.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'customerCareLounge',
        category: 'CUSTOMER_SUCCESS',
        code: EVENT_TEMPLATE_TYPES.CUSTOMER_CARE_LOUNGE,
        title: 'Customer Care Lounge',
        frequency: 'Daily',
        eventDuration: 'Always ON',
        thumbnail: `${thumbBasePath}event-template-customer-care-lounge-thumbnail.png`,
        description: 'Use the airmeet lounge as a 24 x 7 customer care center.',
        previewThumb: `${thumbBasePath}event-template-customer-care-lounge-preview-thumb.png`,
        eventSchedule: [
            {
                title: '24 x 7 Customer Support',
                duration: 'Always ON',
                type: SESSION_TYPES?.LOUNGE,
                sessions: [
                    {
                        icon: '👨🏻‍🚀️',
                        title: 'Set up customer service desks',
                        description:
                            'Have your customer representatives be available on different tables to help the customers. Publish the customer care event link on your main website and inside the product so that the customers can easily join the lounge.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'virtualOfficeSpace',
        category: 'EMPLOYEE_SUCCESS',
        code: EVENT_TEMPLATE_TYPES.VIRTUAL_OFFICE_SPACE,
        title: 'Virtual Office Space',
        frequency: 'Daily',
        eventDuration: '1 Year',
        thumbnail: `${thumbBasePath}event-template-virtual-office-space-thumbnail.png`,
        description:
            'Use Airmeet as your virtual office space by assigning different tables to different teams.',
        previewThumb: `${thumbBasePath}event-template-virtual-office-space-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Office',
                duration: '1 year',
                type: SESSION_TYPES?.LOUNGE,
                sessions: [
                    {
                        icon: '☕️',
                        title: 'Set up the tables',
                        description:
                            'Use the lounge as your office, create different tables for different teams where they can sit and collaborate.',
                    },
                    {
                        icon: '🪑️',
                        title: 'Create new tables on the go',
                        description:
                            'Create meeting tables, where regular meetings can happen. You will never fall short of tables. Let the employees create new tables as and when needed.',
                    },
                    {
                        icon: '🤝️',
                        title: 'Invite colleagues to your table',
                        description:
                            'Invite people for meetings using the invite to table capability.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'groupProductDemo',
        category: 'MARKETING',
        code: EVENT_TEMPLATE_TYPES.GROUP_PRODUCT_DEMO,
        title: 'Group Product Demo',
        frequency: 'as needed',
        eventDuration: '2 Hours',
        thumbnail: `${thumbBasePath}event-template-group-product-demo-thumbnail.png`,
        description:
            'Show the features and benefits of the product to potential customers & existing customers in an interactive way. ',
        previewThumb: `${thumbBasePath}event-template-group-product-demo-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Product Demo',
                duration: '2 hours',
                type: SESSION_TYPES?.GROUP_MEETING,
                sessions: [
                    {
                        icon: '🎥',
                        title: 'Demo the product on the video call',
                        description:
                            'Treat all your participants equally using `session as a video call` where  everyone is visible all the time. This makes it easy for people to interject and ask questions without waiting.',
                    },
                    {
                        icon: '🙋🏻‍️',
                        title: 'Let the participants ask the questions',
                        description:
                            'Try to open the discussion for questions after every 15 minutes to keep everyone engaged. ',
                    },
                    {
                        icon: '😀️',
                        title: 'Take feedback after the demo',
                        description:
                            'Publish a poll after the session to get the feedback from the participants.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'productLaunch',
        category: 'MARKETING',
        code: EVENT_TEMPLATE_TYPES.PRODUCT_LAUNCH,
        title: 'Product Launch',
        frequency: 'as needed',
        eventDuration: '1 Hour 30 Mins',
        thumbnail: `${thumbBasePath}event-template-product-launch-thumbnail.png`,
        description:
            'Use product launch to debut a new product/feature in the market. Invite loyal customers, bloggers, social media influencers and the media to spread the word before, during and after the product launch.',
        previewThumb: `${thumbBasePath}event-template-product-launch-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Product Launch',
                duration: '1 hour',
                type: SESSION_TYPES?.HOSTING,
                sessions: [
                    {
                        icon: '🎥',
                        title: 'Start with a reveal video',
                        description:
                            'You can start the session with an introductory pre-recorded video revealing the product.',
                    },
                    {
                        icon: '🎤️',
                        title: 'Manage speakers',
                        description:
                            'After the video, you can invite the speakers (product & marketing teams) to the stage to talk about the product.',
                    },
                    {
                        icon: '❓',
                        title: 'Moderate the questions',
                        description:
                            'During the session, take moderated questions from the attendees & highlight them on the stage.',
                    },
                    {
                        icon: '🪑',
                        title: 'Usher the attendees to the lounge',
                        description:
                            'Once you end the session, you can ask the attendees to join the tables in the lounge to talk to your internal teams to clarify doubts/ask questions.',
                    },
                    {
                        icon: '☕️',
                        title: 'Set up the tables',
                        description:
                            'You can name the tables in the lounge as per your teams - `Talk to product team`, `Ask the support`, `Interact with marketing` etc.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'vipProspectExperience',
        category: 'MARKETING',
        code: EVENT_TEMPLATE_TYPES.VIP_PROSPECT_EXPERIENCE,
        title: 'VIP Prospect Experience',
        frequency: 'Daily/Weekly',
        eventDuration: '1 hour 30 Mins',
        thumbnail: `${thumbBasePath}event-template-vip-prospect-experience-thumbnail.png`,
        description:
            'Design a personalized event experience for your VIP prospects using the Airmeet branding capabilities.',
        previewThumb: `${thumbBasePath}event-template-vip-prospect-experience-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Product Showcase',
                duration: '1 hour',
                type: SESSION_TYPES?.GROUP_MEETING,
                sessions: [
                    {
                        icon: '💻',
                        title: 'Give a personalized demo to your VIP prospects',
                        description:
                            'Use the video call session, to give importance to your VIP prospects and let them interject and ask questions during the demo.',
                    },
                    {
                        icon: '😀️',
                        title: 'Get instant feedback & answer queries',
                        description:
                            'You can also use Q&A and Polls to understand their pain points and doubts and answer them during the session.',
                    },
                ],
            },
            {
                title: 'Virtual Cocktail Party',
                duration: '30 mins',
                type: SESSION_TYPES?.FLUID_LOUNGE,
                sessions: [
                    {
                        icon: '🥂',
                        title: 'Engage your prospects over drinks',
                        description:
                            'Create a branded fluid space experience for your VIP prospects and have your account executives in the fluid space so that they can interact in a life-like manner.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'featureDeepDive',
        category: 'MARKETING',
        code: EVENT_TEMPLATE_TYPES.FEATURE_DEEP_DIVE,
        title: 'Feature Deep-dive',
        frequency: 'Monthly',
        eventDuration: '1 Hour 30 mins',
        thumbnail: `${thumbBasePath}event-template-feature-deep-dive-thumbnail_v1.png`,
        description:
            'Conduct a feature deep-dive event to demo and explain the use and benefits of an advanced feature to your prospects & customers. Also, showcase how your customers use the feature.',
        previewThumb: `${thumbBasePath}event-template-feature-deep-dive-preview-thumb_v1.png`,
        eventSchedule: [
            {
                title: 'Feature Name Showcase',
                duration: '1 hour',
                type: SESSION_TYPES?.HOSTING,
                sessions: [
                    {
                        icon: '💻',
                        title: 'Give a thorough feature demo',
                        description:
                            'Use screen share to showcase the nitty gritty details of your feature and share best practices to use the feature for different use cases.',
                    },
                    {
                        icon: '💬',
                        title: 'Engage with the attendees',
                        description:
                            'Use chat to pick up new use cases for the feature and highlight them on stage to talk about them.',
                    },
                ],
            },
            {
                title: 'Feedback',
                duration: '30 mins',
                type: SESSION_TYPES?.LOUNGE,
                sessions: [
                    {
                        icon: '☕️',
                        title: 'Set up tables',
                        description:
                            'Use customizable tables to get feature feedback & suggestions from your prospects & customers.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'communityMeetup',
        category: 'MARKETING',
        code: EVENT_TEMPLATE_TYPES.COMMUNITY_MEETUP,
        title: 'Community Meetup',
        frequency: 'Monthly',
        eventDuration: '2 Hours',
        thumbnail: `${thumbBasePath}event-template-community-meetup-thumbnail.png`,
        description:
            'Organize your customer community meetups to discuss & share the common objectives based on your product/service offering.',
        previewThumb: `${thumbBasePath}event-template-community-meetup-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Find a match',
                duration: '30 mins',
                type: SESSION_TYPES?.SPEED_NETWORKING,
                sessions: [
                    {
                        icon: '👥‍',
                        title: 'Find a match',
                        description:
                            'Let the customers match with each other and learn about how they use your product from each other. You can create a rule to match customers from similar industries and other similar attributes.',
                    },
                ],
            },
            {
                title: 'Bump into each other',
                duration: '90 mins',
                type: SESSION_TYPES?.FLUID_LOUNGE,
                sessions: [
                    {
                        icon: '💭‍',
                        title: 'Bump into each other',
                        description:
                            'Set a contextual background in the fluid space (for example, feature names) and instruct the customers to gather around their favorite feature. You can then go to each group to hear their thoughts.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'salesKickoff',
        category: 'SALES',
        code: EVENT_TEMPLATE_TYPES.SALES_KICKOFF,
        title: 'Sales Kickoff',
        frequency: 'Quarterly',
        eventDuration: '1 Hour',
        thumbnail: `${thumbBasePath}event-template-sales-kickoff-thumbnail.png`,
        description:
            'Have a sales kickoff event at the beginning of a new sales period to motivate and energize the team to provide them with the tools, information, and support they need to achieve their sales goals for the upcoming period.',
        previewThumb: `${thumbBasePath}event-template-sales-kickoff-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Sales Kickoff ',
                duration: '1 hour',
                type: SESSION_TYPES?.HOSTING,
                sessions: [
                    {
                        icon: '📊‍',
                        title: 'Year review',
                        description:
                            'Let the sales leaders talk about the past year results and present the new year goals for the sales teams.',
                    },
                    {
                        icon: '❓',
                        title: 'Q&A time',
                        description:
                            'Take questions from the sales teams on the goals set and on the resources needed to achieve them.',
                    },
                    {
                        icon: '🗳️',
                        title: 'Get the feedback',
                        description:
                            'Create polls to get feedback from the sales team on the goals set as well as the resources and tools available to them.',
                    },
                    {
                        icon: '💬',
                        title: 'Divide & discuss',
                        description:
                            'Start breakout rooms and divide the sales team region wise to discuss their queries and expectations.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'virtualSalesOffice',
        category: 'SALES',
        code: EVENT_TEMPLATE_TYPES.VIRTUAL_SALES_OFFICE,
        title: 'Virtual Sales Office',
        frequency: 'Daily',
        eventDuration: 'Always ON',
        thumbnail: `${thumbBasePath}event-template-virtual-sales-office-thumbnail.png`,
        description:
            'Use the airmeet lounge to run your virtual sales office like never before.',
        previewThumb: `${thumbBasePath}event-template-virtual-sales-office-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Sales Office',
                duration: 'Always ON',
                type: SESSION_TYPES?.LOUNGE,
                sessions: [
                    {
                        icon: '🔡️',
                        title: 'Name the tables',
                        description:
                            'Create your virtual sales office by naming the tables by region.',
                    },
                    {
                        icon: '💁🏻‍️️',
                        title: 'Allocate the tables',
                        description:
                            'Have SDRs on each table to talk to the prospects.',
                    },
                    {
                        icon: '🔗‍️️',
                        title: 'Share the office link',
                        description:
                            'Share the event link on your main website so that the high intent leads can directly come to the office to know more about the product & the pricing.',
                    },
                    {
                        icon: '💰',
                        title: 'Show offers & pricing details',
                        description:
                            'Use the lounge banner to show offers and pricing.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'customerOnboarding',
        category: 'CUSTOMER_SUCCESS',
        code: EVENT_TEMPLATE_TYPES.CUSTOMER_ONBOARDING,
        title: 'Customer Onboarding',
        frequency: 'as needed',
        eventDuration: '1 Hour 30 Mins',
        thumbnail: `${thumbBasePath}event-template-customer-onboarding-thumbnail.png`,
        description:
            'Make your new customers familiar with your product/service by providing a structured and supportive environment for customers to learn about it and hence, accelerate the adoption.',
        previewThumb: `${thumbBasePath}event-template-customer-onboarding-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Welcome',
                duration: '1 hours 30 mins',
                type: SESSION_TYPES?.GROUP_MEETING,
                sessions: [
                    {
                        icon: '🎥',
                        title: 'Have an interactive onboarding',
                        description:
                            'Use session as a video call to have an interactive onboarding session with the customers.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'regionalCustomerMeetup',
        category: 'CUSTOMER_SUCCESS',
        code: EVENT_TEMPLATE_TYPES.REGIONAL_CUSTOMER_MEETUP,
        title: 'Regional Customer Meetup',
        frequency: 'Quarterly',
        eventDuration: '2 Hours',
        thumbnail: `${thumbBasePath}event-template-regional-customer-meetup-thumbnail.png`,
        description:
            'Invite the customers from different regions, group them by regions and interact with them to build better connections.',
        previewThumb: `${thumbBasePath}event-template-regional-customer-meetup-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Customer Meetup',
                duration: '2 hours',
                type: SESSION_TYPES?.LOUNGE,
                sessions: [
                    {
                        icon: '🗺️',
                        title: 'Set up region-wise tables',
                        description:
                            'Create tables by region names and instruct the customers to join their region’s table to interact with the other customers as well as the account management team handling that region.',
                    },
                    {
                        icon: '🎤️',
                        title: 'Do product announcements during the meetup',
                        description:
                            'Use live announcements to inform the customers about the breaks and other important product announcements relevant across regions.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'industryRoundtable',
        category: 'CUSTOMER_SUCCESS',
        code: EVENT_TEMPLATE_TYPES.INDUSTRY_ROUNDTABLE,
        title: 'Industry Roundtable',
        frequency: 'Quarterly',
        eventDuration: '1 Hour 30 Mins',
        thumbnail: `${thumbBasePath}event-template-industry-roundtable-thumbnail.png`,
        description:
            'Host an interactive industry roundtable discussion with industry experts & leaders to discuss the challenges, opportunities and new trends in the industry.',
        previewThumb: `${thumbBasePath}event-template-industry-roundtable-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Industry Roundtable',
                duration: '1 hour 30 mins',
                type: SESSION_TYPES?.GROUP_MEETING,
                sessions: [
                    {
                        icon: '💬',
                        title: 'Have a smooth roundtable discussion',
                        description:
                            'During the roundtable, facilitate the discussion by keeping the conversation on track and making sure that all participants have an opportunity to share their thoughts and ideas.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'customerSpotlight',
        category: 'CUSTOMER_SUCCESS',
        code: EVENT_TEMPLATE_TYPES.CUSTOMER_SPOTLIGHT,
        title: 'Customer Spotlight',
        frequency: 'Quarterly',
        eventDuration: '1 Hour 30 Mins',
        thumbnail: `${thumbBasePath}event-template-customer-spotlight-thumbnail.png`,
        description:
            'Highlight your top customers and how they derive value from your product or service.',
        previewThumb: `${thumbBasePath}event-template-customer-spotlight-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Customer Spotlight',
                duration: '1 hour 30 mins',
                type: SESSION_TYPES?.HOSTING,
                sessions: [
                    {
                        icon: '📉',
                        title: 'Start with the last quarter summary',
                        description:
                            "Start with a presentation about the past quarter and what's upcoming in the next quarter.",
                    },
                    {
                        icon: '💬',
                        title: 'Engage the customers',
                        description:
                            'Publish a poll to ask the customers about their favorite thing about the product.',
                    },
                    {
                        icon: '🎥',
                        title: 'Play a video to show top customers',
                        description:
                            'Introduce top 10 customers using a pre-recorded video showing what they have done in brief.',
                    },
                    {
                        icon: '🔦',
                        title: 'Bring the customers in spotlight',
                        description:
                            'Invite the top 10 customers one by one to stage to talk to them about their experience. ',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'teamBonding',
        category: 'EMPLOYEE_SUCCESS',
        code: EVENT_TEMPLATE_TYPES.TEAM_BONDING,
        title: 'Team Bonding',
        frequency: 'Weekly',
        eventDuration: '90 Mins',
        thumbnail: `${thumbBasePath}event-template-team-bonding-thumbnail.png`,
        description:
            'Create a warm and friendly atmosphere using fluid space to enable team bonding.',
        previewThumb: `${thumbBasePath}event-template-team-bonding-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Mingle with each other',
                duration: '90 mins',
                type: SESSION_TYPES?.FLUID_LOUNGE,
                sessions: [
                    {
                        icon: '🌌',
                        title: 'Add a contextual background',
                        description:
                            'Set up the fluid space by selecting a contextual background like a world map or some other assets.',
                    },
                    {
                        icon: '📣',
                        title: 'Announce the instructions',
                        description:
                            'Start the fluid space and make a live announcement instructing the team to form groups.',
                    },
                    {
                        icon: '🔗',
                        title: 'Mingle',
                        description:
                            'Let the team members form groups and move around to change groups and mingle.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'hiring',
        category: 'EMPLOYEE_SUCCESS',
        code: EVENT_TEMPLATE_TYPES.RECRUITMENT,
        title: 'Recruitment',
        frequency: 'as needed',
        eventDuration: '1 Day',
        thumbnail: `${thumbBasePath}event-template-hiring-thumbnail.png`,
        description:
            'Conduct a hiring event using the airmeet lounge and live announcements.',
        previewThumb: `${thumbBasePath}event-template-hiring-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Interview Room',
                duration: '1 day',
                type: SESSION_TYPES?.LOUNGE,
                sessions: [
                    {
                        icon: '☕️',
                        title: 'Set up the tables',
                        description:
                            'Before the event, set up the tables with table names and one interviewer assigned to each table with 2 seats. Create a few waiting tables where the candidates wait for their interview slot.',
                    },
                    {
                        icon: '💬️',
                        title: 'Message the candidates',
                        description:
                            'Use DMs and event chat to direct the attendees to the correct table.',
                    },
                    {
                        icon: '📣️',
                        title: 'Announce breaks & other information',
                        description:
                            'Use live announcements to announce start, break and end of the interviews.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'onboarding',
        category: 'EMPLOYEE_SUCCESS',
        code: EVENT_TEMPLATE_TYPES.ONBOARDING,
        title: 'Onboarding',
        frequency: 'as needed',
        eventDuration: '1 Hour 30 Mins',
        thumbnail: `${thumbBasePath}event-template-onboarding-thumbnail.png`,
        description:
            "Engage your newly on boarded employees in a way they'd have never experienced.",
        previewThumb: `${thumbBasePath}event-template-onboarding-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Induction',
                duration: '45 mins',
                type: SESSION_TYPES?.HOSTING,
                sessions: [
                    {
                        icon: '👩🏻‍💻️',
                        title: 'Company Presentation',
                        description:
                            'Conduct an induction presentation to communicate the company policies and other important information.',
                    },
                    {
                        icon: '❓️',
                        title: 'Take questions',
                        description:
                            'Encourage the new joinees to ask questions and use ‘show on stage’ to highlight the questions.',
                    },
                ],
            },
            {
                title: 'Get to know each other',
                duration: '45 mins',
                type: SESSION_TYPES?.FLUID_LOUNGE,
                sessions: [
                    {
                        icon: '👋',
                        title: 'Welcome the new hires with warmth',
                        description:
                            'Open the fluid space and set a background with a welcome vibe to let the new joinees move around and introduce themselves to different groups.',
                    },
                ],
            },
        ],
    },
    {
        ...commonProps,
        id: 'hackathon',
        category: 'EMPLOYEE_SUCCESS',
        code: EVENT_TEMPLATE_TYPES.HACKATHON,
        title: 'Hackathon',
        frequency: 'Quarterly',
        eventDuration: '4 Hours',
        thumbnail: `${thumbBasePath}event-template-hackathon-thumbnail.png`,
        description:
            'Host a collaborative event where the participants can group and work together to create innovative solutions to a specific problem or challenge.',
        previewThumb: `${thumbBasePath}event-template-hackathon-preview-thumb.png`,
        eventSchedule: [
            {
                title: 'Hackathon',
                duration: '4 hours',
                type: SESSION_TYPES?.LOUNGE,
                sessions: [
                    {
                        icon: '🔡️',
                        title: 'Set up team-wise tables',
                        description:
                            'Set up the lounge with different table names and decided the number of seats at each table.',
                    },
                    {
                        icon: '📣️',
                        title: 'Announce the instructions',
                        description:
                            'Once the participants enter the event, do a live announcement to instruct them to join the tables as per their groups. Later on, use the same.',
                    },
                    {
                        icon: '🗣️️',
                        title: 'Let the teams invite the moderator',
                        description:
                            'Ask the participants to invite the moderator using table invites to clarify doubts or show updates.',
                    },
                    {
                        icon: '💬️',
                        title: 'Post GIFs & comments to create buzz',
                        description:
                            'Keep the buzz going by posting GIFs in chat and create polls to get some feedback.',
                    },
                ],
            },
        ],
    },
];

export const CreateEventLoadingStates = (airmeetFormat = 'event') => ({
    eventSetup: {
        title: `Setting up your ${airmeetFormat} `,
        progress: 33,
        img_path: cdnImage('/images/eventLoader/checking-network-settings.svg'),
    },
    configSetup: {
        title: 'Applying configurations & visual assets',
        progress: 66,
        img_path: cdnImage('/images/eventLoader/checking-network-settings.svg'),
    },
    completed: {
        title: 'Almost there',
        progress: 100,
        img_path: cdnImage('/images/eventLoader/greeting-user.svg'),
    },
});
