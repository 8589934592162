import { API_SERVICE, callApi } from 'utils/apiService';

export const getTotalRegistrations = ({ airmeetId }) => {
    return callApi({
        endpoint: `/event-replay/${airmeetId}/total-registrations`,
        method: 'GET',
        service: API_SERVICE.ANALYTICS,
        query: {
            sample_by: 'DAY',
        },
    });
};

export const getTrafficSources = ({ airmeetId }) => {
    return callApi({
        endpoint: `/event-replay/${airmeetId}/demographics`,
        method: 'GET',
        service: API_SERVICE.ANALYTICS,
    });
};

export const getViewers = ({ airmeetId, sessionId = null }) => {
    return callApi({
        endpoint: `/event-replay/${airmeetId}/on-demand/viewers`,
        method: 'GET',
        service: API_SERVICE.ANALYTICS,
        query: {
            sessionId,
        },
    });
};

export const getViewersDistribution = ({ airmeetId, sessionId = null }) => {
    return callApi({
        endpoint: `/event-replay/${airmeetId}/on-demand/viewers/distribution`,
        method: 'GET',
        service: API_SERVICE.ANALYTICS,
        query: {
            sessionId,
        },
    });
};

export const getViewingTimeSummary = ({ airmeetId, sessionId = null }) => {
    return callApi({
        endpoint: `/event-replay/${airmeetId}/on-demand/viewers/viewing-time-summary`,
        method: 'GET',
        service: API_SERVICE.ANALYTICS,
        query: {
            sessionId,
        },
    });
};

export const getTopAttendees = ({ airmeetId, sessionId }) => {
    return callApi({
        endpoint: `/event-replay/${airmeetId}/on-demand/top-attendees`,
        method: 'GET',
        service: API_SERVICE.ANALYTICS,
        query: {
            sessionId,
        },
    });
};
