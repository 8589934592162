import { stringToHSL } from 'foundations/theme';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

const DEFAULT_COLOR = '#6A4CFF';

// Validators --------

export const isValidHex = (value, withHash = true) => {
    return withHash
        ? /^#([0-9A-F]{3}){1,2}$/i.test(value)
        : /^([0-9A-F]{3}){1,2}$/i.test(value);
};

const isValidHSL = (hue, sat, light) => {
    const h = parseInt(hue);
    const s = parseInt(sat);
    const l = parseInt(light);

    if (
        !isNaN(h) &&
        !isNaN(s) &&
        !isNaN(l) &&
        h >= 0 &&
        h <= 360 &&
        s >= 0 &&
        s <= 100 &&
        l >= 0 &&
        l <= 100
    )
        return true;

    return false;
};

const isValidHSLA = (hue, sat, light, alpha) => {
    const isHSL = isValidHSL(hue, sat, light);

    if (isHSL) {
        const a = parseInt(alpha);
        if (!isNaN(a) && a >= 0 && a <= 1) return true;
    }

    return false;
};

const isValidRgbObj = (value) => {
    return (
        isObject(value) &&
        isNumber(value.r) &&
        isNumber(value.g) &&
        isNumber(value.b) &&
        isNumber(value.r) >= 0 &&
        isNumber(value.r) <= 255 &&
        isNumber(value.g) >= 0 &&
        isNumber(value.g) <= 255 &&
        isNumber(value.b) >= 0 &&
        isNumber(value.b) <= 255 &&
        (!isNumber(value.a) ||
            (isNumber(value.a) >= 0 && isNumber(value.a) <= 1))
    );
};

// Converters --------

export const stringToHSLA = (color) =>
    color.match(/hsla\((\d+),(\d+)%,(\d+)%,(\d+((.)|(.\d{0,1})?)+)\)/);

export const stringToRgbaValues = (color) =>
    color.match(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*([0-9.]+)\)/);

const rgbComponentToHex = (c) => {
    var hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
};

export const rgbToHex = ({ r, g, b, a, withHash = true, isValid = false }) => {
    if (isValid || isValidRgbObj({ r, g, b, a })) {
        const hexAlpha = isNumber(a) ? alphaToHexAlpha(a * 100) : '';
        return (
            (withHash ? '#' : '') +
            rgbComponentToHex(r) +
            rgbComponentToHex(g) +
            rgbComponentToHex(b) +
            hexAlpha
        );
    }

    return '';
};

export const hexToRgb = (val) => {
    let hex = val;
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex?.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
};

export const hexaToRgba = (val) => {
    const hex = val || '';
    const rgb = hexToRgb(hex.slice(0, hex.startsWith('#') ? 7 : 6));
    if (rgb) {
        rgb.a = getHexAlphaPercent(hex) / 100;
    }
    return rgb;
};

export const alphaToHexAlpha = (alpha) => {
    const buff = Buffer.alloc(4);
    buff.writeInt32BE((alpha * 255) / 100);
    return buff.toString('hex').slice(6);
};

export const hexToHSL = (H) => {
    // Convert hex to RGB first
    let r = 0,
        g = 0,
        b = 0;
    if (H.length === 4) {
        r = '0x' + H[1] + H[1];
        g = '0x' + H[2] + H[2];
        b = '0x' + H[3] + H[3];
    } else if (H.length === 7) {
        r = '0x' + H[1] + H[2];
        g = '0x' + H[3] + H[4];
        b = '0x' + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta === 0) h = 0;
    else if (cmax === r) h = ((g - b) / delta) % 6;
    else if (cmax === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = Math.round(+(s * 100));
    l = Math.round(+(l * 100));

    return { h: h, s: s, l: l };
};

export const hexaToHSLA = (H) => {
    const hsl = hexToHSL(H.slice(0, 7));
    if (H.length === 9) {
        hsl.a = getHexAlphaPercent(H) / 100;
    } else {
        hsl.a = 1;
    }
    return hsl;
};

export const hslToHex = (h, s, l) => {
    if (isValidHSL(h, s, l)) {
        l /= 100;
        const a = (s * Math.min(l, 1 - l)) / 100;
        const f = (n) => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color)
                .toString(16)
                .padStart(2, '0'); // convert to Hex and prefix "0" if needed
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    }

    return null;
};

const hslaToHexa = (h, s, l, a) => {
    if (isValidHSLA(h, s, l, a)) {
        const hex = hslToHex(h, s, l);
        const hexAlpha = alphaToHexAlpha(a * 100);

        return `${hex}${hexAlpha}`;
    }

    return null;
};

export const rgbObjToStr = (rgbObj) => {
    if (isValidRgbObj(rgbObj)) {
        const rgbStr = `${rgbObj?.r},${rgbObj?.g},${rgbObj?.b}`;
        if (isNumber(rgbObj?.a)) {
            return `rgba(${rgbStr},${rgbObj?.a})`;
        } else {
            return `rgb(${rgbStr})`;
        }
    }

    return '';
};

export const stringToRgb = (str) => {
    const arr = str.match(/\d+/g);

    if (arr.length >= 3) {
        return {
            r: parseInt(arr[0]),
            g: parseInt(arr[1]),
            b: parseInt(arr[2]),
        };
    }
};

export const rgbaAlpha = (color, alpha) => {
    const values = stringToRgbaValues(color);
    if (Array.isArray(values) && values.length >= 5) {
        return `rgba(${values[1]}, ${values[2]}, ${values[3]}, ${alpha})`;
    }
    return color;
};

// Getters ----------

export const getHexAlphaPercent = (hex) => {
    return hex.length > 7
        ? ((parseInt(hex.slice(7), 16) / 255) * 100).toFixed(0)
        : 100;
};

export const getHexColorValue = (
    value,
    isOpacityEnabled = false,
    defaultColor = DEFAULT_COLOR
) => {
    // Hex
    if (isValidHex(value)) {
        return value;
    }
    if (isValidHex(value, false)) {
        return `#${value}`;
    }

    // HSL
    if (value && isString(value)) {
        const values = isOpacityEnabled
            ? stringToHSLA(value)
            : stringToHSL(value);
        return isOpacityEnabled
            ? Array.isArray(values) && values.length >= 5
                ? hslaToHexa(values[1], values[2], values[3], values[4])
                : defaultColor
            : Array.isArray(values) && values.length >= 4
            ? hslToHex(values[1], values[2], values[3])
            : defaultColor;
    }

    // RGB
    if (isValidRgbObj(value)) {
        return rgbToHex({ ...value, isValid: true });
    }

    return defaultColor;
};

export const getHexVal = ({ color, isOpacityEnabled }) => {
    const changeHsl = isOpacityEnabled
        ? stringToHSLA(color) || []
        : stringToHSL(color) || [];
    const hexVal = hslToHex(changeHsl[1], changeHsl[2], changeHsl[3]);
    return hexVal;
};

// Color Name Helpers --------

const rgbDistance = (a, b) => {
    return Math.sqrt(
        Math.pow(a?.r - b?.r, 2) +
            Math.pow(a?.g - b?.g, 2) +
            Math.pow(a?.b - b?.b, 2)
    );
};

export const nearestHexColorCode = ({ colorHex, colorData }) => {
    const returnObj = {
        originalHex: colorHex,
        matchedHex: '',
        name: '',
        isExactMatch: false,
    };

    // Sanitize hex color ---

    let color = (colorHex || '').toUpperCase();
    // Invalid hex
    if (!color || !isString(color) || color.length < 3 || color.length > 7)
        return returnObj;
    // Prepend #
    if (color.length % 3 === 0) color = '#' + color;
    // convert to 6 character hex
    if (color.length === 4)
        color =
            '#' +
            color.substr(1, 1) +
            color.substr(1, 1) +
            color.substr(2, 1) +
            color.substr(2, 1) +
            color.substr(3, 1) +
            color.substr(3, 1);

    // Sanitize hex color END ---

    let lowest = Number.POSITIVE_INFINITY;
    for (let i = 0; i < colorData.length; i++) {
        const item = colorData[i];
        const clr = item[0];

        // Check if exact color
        if (color === `#${clr}`) {
            returnObj.matchedHex = item[0];
            returnObj.name = item[1];
            returnObj.isExactMatch = true;
            break;
        }

        // Get nearest color
        const tmp = rgbDistance(hexToRgb(colorHex), hexToRgb(clr));
        if (tmp < lowest) {
            lowest = tmp;
            returnObj.matchedHex = item[0];
            returnObj.name = item[1];
        }
    }

    return returnObj;
};

// WCAG color contrast checker
// Source: https://codepen.io/alvaromontoro/pen/YgpWZG
function luminance(r, g, b) {
    var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}
function contrast(rgb1, rgb2) {
    const luminanceFront = luminance(rgb1.r, rgb1.g, rgb1.b);
    const luminanceBack = luminance(rgb2.r, rgb2.g, rgb2.b);
    return luminanceBack > luminanceFront
        ? (luminanceFront + 0.05) / (luminanceBack + 0.05)
        : (luminanceBack + 0.05) / (luminanceFront + 0.05);
}
function getContrastCategories(colorFront, colorBack) {
    const ratio = contrast(colorFront, colorBack);

    return {
        aaNormal: ratio < 0.22222,
        aaLarge: ratio < 0.33333,
        aaaNormal: ratio < 0.14285,
        aaaLarge: ratio < 0.22222,
    };
}
export const checkColorContrast = (colorFront, colorBack) => {
    const obj = getContrastCategories(colorFront, colorBack);

    return obj?.aaNormal;
};
