import { FEATURE_ACTIONS, FEATURE_NAMES } from 'utils/constants/featureNames';

const { REPORT_MESSAGE } = FEATURE_NAMES;

const reportMessageWriter = ({
    airmeetId,
    client,
    payload,
    metaData,
    logger,
}) => {
    if (metaData.action === FEATURE_ACTIONS[REPORT_MESSAGE].ADD_REPORT) {
        logger.info('Create new report');
        const fireBaseKey = `${airmeetId}/reports/messages`;
        return client.pushData(fireBaseKey, payload);
    }

    if (metaData.action === FEATURE_ACTIONS[REPORT_MESSAGE].UPDATE_REPORT) {
        if (!metaData.nodeId) {
            throw new Error('Invalid Report message firebase key');
        }
        logger.info('Update existing report');
        const fireBaseKey = `${airmeetId}/reports/messages/${metaData.nodeId}`;
        return client.setData(fireBaseKey, payload);
    }
};

export default reportMessageWriter;
