import useNewDefaultReceptionFlag from 'components/DefaultReception/hooks/useNewDefaultReceptionFlag';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import useTheme from 'hooks/useTheme';
import isNumber from 'lodash/isNumber';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import { emptyObject } from 'utils/constants/common';
import { USER_ROLE } from 'utils/constants/users';
import { cdnImage } from 'utils/core';
import AirGenieImageSuggestionsDrawer from './AirGenieImageSuggestionsDrawer';
import PreviewImage from './PreviewImage';
import { AIR_GENIE_IMAGE_GEN_MODULES } from 'hooks/airGenie/constants';

const { EXHIBITOR, HOST, SPEAKER, ORGANIZER, COHOST } = USER_ROLE;

const GenerateBackgroundImageSuggestions = ({
    value,
    onChange,
    onClose,
    showInsidePortal,
    firebaseClient,
    featuresConfig,
    platformConfig,
}) => {
    const isNewDefaultReception = useNewDefaultReceptionFlag();
    const {
        airmeet: {
            airmeetId,
            data: {
                currentAirmeet: { status },
                userRole,
            },
            featureFlags: { isConferenceEvent },
        },
    } = useLiveAirmeetContext();
    const showAirmeetStatusBar = useMemo(
        () =>
            [
                AIRMEET_STATUS.CREATED,
                AIRMEET_STATUS.PAUSED,
                AIRMEET_STATUS.FINISHED,
                AIRMEET_STATUS.PRE_EVENT_ACCESS,
            ].includes(status) &&
            [EXHIBITOR, COHOST, HOST, ORGANIZER, SPEAKER].includes(userRole),
        [status, userRole]
    );
    const topValue = () => {
        if (
            isConferenceEvent &&
            showAirmeetStatusBar &&
            !isNewDefaultReception
        ) {
            return '64px';
        } else {
            return '0px';
        }
    };

    const renderPreviewComponent = (props) => <PreviewComponent {...props} />;

    return (
        <AirGenieImageSuggestionsDrawer
            value={value}
            airmeetId={airmeetId}
            onChange={onChange}
            onClose={onClose}
            showInsidePortal={showInsidePortal}
            isNewDefaultReception={isNewDefaultReception}
            renderPreviewComponent={renderPreviewComponent}
            top={topValue()}
            drawerHeight={showAirmeetStatusBar ? 'calc(100vh - 64px)' : '100vh'}
            uploadFilePrefix={'aiBgImage'}
            previewHeight={'85%'}
            hasImageCropper
            firebaseClient={firebaseClient}
            featuresConfig={featuresConfig}
            platformConfig={platformConfig}
            module={AIR_GENIE_IMAGE_GEN_MODULES.EVENT_BACKGROUND}
        />
    );
};

const PreviewComponent = (props = emptyObject) => {
    const { opacity, imgUrl, previewHeight } = props;
    const { theme } = useTheme();
    const previewImage = `/images/summit/event-branding/reception-top-overlay-image-${
        theme.isLightTheme ? 'light' : 'dark'
    }.png`;
    const newPreviewImage = `/images/summit/event-branding/lounge-overlay-image2-${
        theme.isLightTheme ? 'light' : 'dark'
    }.png`;
    const {
        airmeet: {
            featureFlags: { isConferenceEvent },
        },
    } = useLiveAirmeetContext();
    const isNewDefaultReception = useNewDefaultReceptionFlag();

    return imgUrl ? (
        <PreviewImage {...props}>
            <ReceptionImage
                src={cdnImage(
                    isNewDefaultReception || !isConferenceEvent
                        ? newPreviewImage
                        : previewImage
                )}
                alt='Live Session'
            />
        </PreviewImage>
    ) : (
        <Wrapper previewHeight={previewHeight}>
            <ReceptionImage
                src={cdnImage(
                    isNewDefaultReception || !isConferenceEvent
                        ? newPreviewImage
                        : previewImage
                )}
                alt='Live Session'
            />
            <Overlay opacity={1 - opacity} />
        </Wrapper>
    );
};

export default GenerateBackgroundImageSuggestions;

const ReceptionImage = styled.img`
    width: auto;
    max-width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
`;

const Overlay = styled(FlexDiv)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    ${({ opacity, theme }) =>
        isNumber(opacity) &&
        `
        background-color: ${theme.colors.text.other.primary};
        opacity: ${opacity}
    `};
`;

const Wrapper = styled(FlexDiv)`
    width: 100%;
    position: relative;
    height: 85%;
    max-height: 100%;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.colors.border.default1};
    border-radius: ${({ theme }) => theme.radii.default};
`;
