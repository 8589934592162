export const API_CONTAINER = 'API_CONTAINER';
export const COOKIE_BANNER_CONTAINER = 'COOKIE_BANNER_CONTAINER';
export const ANNOUNCEMENT_BANNER_CONTAINER = 'ANNOUNCEMENT_BANNER_CONTAINER';
export const NEW_UI_BANNER_CONTAINER = 'NEW_UI_BANNER_CONTAINER';
export const CENTER_CONTAINER = 'CENTER_CONTAINER';
export const CONTEXTUAL_CONTENT_CONTAINER = 'CONTEXTUAL_CONTENT_CONTAINER';
export const MAIN_CONTENT_CONTAINER = 'MAIN_CONTENT_CONTAINER';
export const MAIN_NAVIGATION_CONTAINER = 'MAIN_NAVIGATION_CONTAINER';
export const HOME_CONTAINER = 'HOME_CONTAINER';
export const BOOTH_CONTAINER = 'BOOTH_CONTAINER';
export const MEETUP_RHS_CONTAINER = 'MEETUP_RHS_CONTAINER';
export const MEETUP_LOUNGE_CONTAINER = 'MEETUP_LOUNGE_CONTAINER';

//summit mobile view container
export const MOBILE_RECEPTION_CONTAINER = 'MOBILE_RECEPTION_CONTAINER';
