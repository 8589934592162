import RadioGroupV2 from 'atoms/Radio/RadioGroupV2';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text/';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import keys from 'locale/keys';
import React from 'react';
import styled from 'styled-components';
import ColorSelector from '../../../widgets/ColorSelector';
import {
    COLOR_BACKGROUND_TYPE_OPTIONS,
    DEFAULT_COLORS,
    DEFAULT_GRADIENTS,
} from './utils';

export default function ColorBackground({
    backgroundType,
    onBackgroundTypeChange,
    onChange,
    value,
    is_light_theme,
    setAmbienceSuggestionVisible,
    types = COLOR_BACKGROUND_TYPE_OPTIONS,
}) {
    const { t } = useCustomTranslation();
    const typeValues = (types || []).map((type) => type?.id);
    const isGradient = (backgroundType || '')
        .toLowerCase()
        .includes('gradient');

    const handleChange = (type, color, prop, value) => {
        if (onChange) {
            onChange(null, color, null, false, { hslObj: value });
        }
    };

    return (
        <FlexDiv flexDirection='column' alignItems='stretch'>
            {typeValues.includes(backgroundType) && (
                <>
                    <Text
                        variant='caption'
                        color='text.default.secondary'
                        pt={types.length > 1 ? '0px' : '9px'}
                        pb={types.length > 1 ? '10px' : '0px'}
                        pl={types.length > 1 ? '30px' : '0px'}
                    >
                        {t(keys.RHS_CUSTOMIZE_CHOOSE_COLOR_STYLE)}
                    </Text>
                    {types.length > 1 ? (
                        <Wrapper
                            flexDirection='column'
                            alignItems='stretch'
                            pl='30px'
                        >
                            <RadioGroupV2
                                options={(types || []).map((type) => ({
                                    ...type,
                                    value: type?.id,
                                }))}
                                onValueChange={onBackgroundTypeChange}
                                value={backgroundType}
                            />
                            <ColorWidget
                                value={value}
                                is_light_theme={is_light_theme}
                                onChange={handleChange}
                                setAmbienceSuggestionVisible={
                                    setAmbienceSuggestionVisible
                                }
                                isGradient={isGradient}
                                backgroundType={backgroundType}
                            />
                        </Wrapper>
                    ) : (
                        <ColorWidget
                            value={value}
                            is_light_theme={is_light_theme}
                            onChange={handleChange}
                            setAmbienceSuggestionVisible={
                                setAmbienceSuggestionVisible
                            }
                            isGradient={isGradient}
                            backgroundType={backgroundType}
                        />
                    )}
                </>
            )}
        </FlexDiv>
    );
}

const ColorWidget = ({
    value,
    is_light_theme,
    onChange,
    setAmbienceSuggestionVisible,
    isGradient,
    backgroundType,
}) => {
    return (
        <ColorSelector
            value={value?.value}
            is_light_theme={is_light_theme}
            onChange={onChange}
            setAmbienceSuggestionVisible={setAmbienceSuggestionVisible}
            type='live_config'
            prop='primaryBackgroundColor'
            defaultColors={isGradient ? DEFAULT_GRADIENTS : DEFAULT_COLORS}
            gradientType={
                isGradient
                    ? (backgroundType || '').toLowerCase().includes('radial')
                        ? 'radial'
                        : 'linear'
                    : null
            }
            gradient={isGradient}
            isOpacityEnabled={true}
            mode='INPUT'
            styleObj={{ widgetWrapper: { paddingTop: '0px !important' } }}
        />
    );
};

const Wrapper = styled(FlexDiv)`
    .RadioGroupRoot {
        .LabelRoot {
            margin-bottom: 1em;
        }
    }
`;
