import { useSelector } from 'react-redux';
import useLeadHandler from './useLeadHandler';
import { LeadEventTypes, CREATE_LEAD, UPDATE_LEAD, GET_LEAD } from './utils';

function useLeadEvents() {
    const allLeads = useSelector(({ leads }) => leads);

    const {
        handleGetLeadsData,
        handleCreateLead,
        handleUpdateLead,
    } = useLeadHandler();

    const handleLeadOperation = ({
        leadName,
        payload = {},
        operation = GET_LEAD,
        setLoading = null,
    }) => {
        switch (operation) {
            case GET_LEAD:
                handleGetLeadsData(leadName, setLoading);
                break;
            case CREATE_LEAD:
                handleCreateLead(payload, leadName);
                break;
            case UPDATE_LEAD:
                handleUpdateLead(payload, leadName);
                break;
            default:
                break;
        }
    };

    const getLeadDataFromStore = (eventName) => {
        return allLeads?.[eventName]?.filter((item) => item.name === eventName);
    };

    return {
        handleLeadOperation,
        eventTypes: LeadEventTypes,
        getLeadDataFromStore,
    };
}

export default useLeadEvents;
