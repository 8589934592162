import { CALL_API } from 'store/api';

export const SESSION_ACCESSIBILITY_INFO_REQUEST =
    'SESSION_ACCESSIBILITY_INFO_REQUEST';
export const SESSION_ACCESSIBILITY_INFO_RESPONSE =
    'SESSION_ACCESSIBILITY_INFO_RESPONSE';

export function getForbiddenActions(airmeetId) {
    return {
        [CALL_API]: {
            types: [
                SESSION_ACCESSIBILITY_INFO_REQUEST,
                SESSION_ACCESSIBILITY_INFO_RESPONSE,
            ],
            endpoint: `/airmeet/${airmeetId}/forbidden-actions`,
            type: 'json',
            method: 'GET',
        },
    };
}
