import { API_SERVICE } from 'utils/apiService';
import { CALL_API } from '../../api';
export const FETCH_BILLING_PLANS_REQUEST = 'FETCH_BILLING_PLANS_REQUEST';
export const FETCH_BILLING_PLANS_RESPONSE = 'FETCH_BILLING_PLANS_RESPONSE';
export const APPLY_COUPON_CODE_REQUEST = 'APPLY_COUPON_CODE_REQUEST';
export const APPLY_COUPON_CODE_RESPONSE = 'APPLY_COUPON_CODE_RESPONSE';
export const CREATE_PAYMENT_INTENT_REQUEST = 'CREATE_PAYMENT_INTENT_REQUEST';
export const CREATE_PAYMENT_INTENT_RESPONSE = 'CREATE_PAYMENT_INTENT_RESPONSE';
export const CREATE_SUBSCRIPTION_REQUEST = 'CREATE_SUBSCRIPTION_REQUEST';
export const CREATE_SUBSCRIPTION_RESPONSE = 'CREATE_SUBSCRIPTION_RESPONSE';
export const UPDATE_PLAN_DETAILS_GET_ESTIMATE_REQUEST =
    'UPDATE_PLAN_DETAILS_GET_ESTIMATE_REQUEST';
export const UPDATE_PLAN_DETAILS_GET_ESTIMATE_RESPONSE =
    'UPDATE_PLAN_DETAILS_GET_ESTIMATE_RESPONSE';
export const RESET_PLAN_ESTIMATES = 'RESET_PLAN_ESTIMATES';
export const RESET_BILLING_PLANS_DATA = 'RESET_BILLING_PLANS_DATA';

export function getBillingPlans() {
    return {
        [CALL_API]: {
            types: [FETCH_BILLING_PLANS_REQUEST, FETCH_BILLING_PLANS_RESPONSE],
            endpoint: `/subscription/plans`,
            method: 'GET',
            service: API_SERVICE.BFF,
        },
    };
}

export function resetBillingPlans() {
    return {
        type: RESET_BILLING_PLANS_DATA,
        payload: null,
    };
}

export function applyCoupon(payload) {
    return {
        [CALL_API]: {
            types: [APPLY_COUPON_CODE_REQUEST, APPLY_COUPON_CODE_RESPONSE],
            endpoint: `/subscription/apply-coupon`,
            method: 'POST',
            body: {
                ...payload,
            },
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}

export function createPaymentIntent(payload) {
    return {
        [CALL_API]: {
            types: [
                CREATE_PAYMENT_INTENT_REQUEST,
                CREATE_PAYMENT_INTENT_RESPONSE,
            ],
            endpoint: `/subscription/payment-intent`,
            method: 'POST',
            body: {
                ...payload,
            },
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}

export function createSubscription(payload) {
    return {
        [CALL_API]: {
            types: [CREATE_SUBSCRIPTION_REQUEST, CREATE_SUBSCRIPTION_RESPONSE],
            endpoint: `/subscription/create`,
            method: 'POST',
            body: {
                ...payload,
            },
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
}

export const updatePlanDetailsAndGetEstimate = (payload) => {
    return {
        [CALL_API]: {
            types: [
                UPDATE_PLAN_DETAILS_GET_ESTIMATE_REQUEST,
                UPDATE_PLAN_DETAILS_GET_ESTIMATE_RESPONSE,
            ],
            endpoint: '/subscription/estimate',
            method: 'POST',
            body: {
                ...payload,
            },
            type: 'json',
            service: API_SERVICE.BFF,
        },
    };
};

export const resetPlanEstimates = () => {
    return {
        type: RESET_PLAN_ESTIMATES,
        payload: null,
    };
};
