import { useProfiling } from 'context/Profiling';
import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import {
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import ReactDOM from 'react-dom';
import { User, UserMetaData } from 'services/users/types.d';
import UserService from 'services/users/UserService';
import { USER_ROLE } from 'utils/constants/users';
import { cdnImage } from 'utils/core';
import { bindModuleLogger } from 'utils/logger';
import { UserFetchOptions } from '../../services/users/types';
import useDeepEqualState from 'hooks/useDeepEqualState';

const logger = bindModuleLogger('User Service');
export const SUBSCRIPTION_DELAY = 10000;

export const LOADING_USER = {
    id: '',
    id_seq: 0,
    name: '...',
    profile_img: cdnImage('assets/images/shimmer_horizontal.gif'),
    tags: [],
    registration_time: '',
    user_type: USER_ROLE.ATTENDEE,
    metadata: {
        lang: {
            key: 'en',
            name: 'English',
            is_user_set: false,
        },
    },
    email: '',
    designation: '...',
    company: '...',
    city: '...',
    country: '',
    airmeetRoles: [],
    hash: '',
    role: USER_ROLE.ATTENDEE,
    profile_img_original: cdnImage('assets/images/shimmer_horizontal.gif'),
    isLoading: true,
};

type IDSeqMap = Map<string, UserMetaData>;

class DynamicUserMap extends Map<string, User> {
    private idSeqMap: IDSeqMap;
    private userService: UserService;

    get(key: string): User {
        if (!key) return;
        let val = super.get(key);
        let needsUpdate = false;

        if (!val) {
            needsUpdate = true;
            val = Object.assign(
                {},
                LOADING_USER,
                this.userService?.getCached(key)
            );
        }

        if (!val?.id_seq) {
            needsUpdate = true;
            val = Object.assign(val, {
                id: key,
                id_seq: this.idSeqMap?.get(key)?.id_seq,
            });
        }

        if (needsUpdate) {
            this.set(key, val);
        }
        return val;
    }

    entries(): IterableIterator<[string, User]> {
        throw new Error('Dynamic user map does not support iteration');
    }

    keys(): IterableIterator<string> {
        throw new Error('Dynamic user map does not support iteration');
    }

    values(): IterableIterator<User> {
        throw new Error('Dynamic user map does not support iteration');
    }

    get length() {
        return this.size;
    }

    updateSeqMap(idSeqMap: IDSeqMap) {
        this.idSeqMap = idSeqMap;
    }

    static with(
        idSeqMap?: IDSeqMap,
        userService?: UserService
    ): DynamicUserMap {
        let map = new DynamicUserMap();
        map.idSeqMap = idSeqMap;
        map.userService = userService;
        return map;
    }

    static clone(oldMap: DynamicUserMap): DynamicUserMap {
        if (!oldMap) {
            throw new Error('Cannot clone from null');
        }
        let copy = new DynamicUserMap(oldMap);
        copy.idSeqMap = oldMap.idSeqMap;
        copy.userService = oldMap.userService;
        return copy;
    }
}

const DEFAULT_CONFIG = {
    asArray: false,
    enableSubscription: true,
    subscriptionDelay: 0,
    useFallback: true,
    isDebounceRequired: false,
};

interface UserProfileLoadingOptions {
    idSeqMap?: IDSeqMap;
    enableSubscription?: boolean;
    subscriptionDelay?: number;
    service?: UserService;
    asArray: boolean;
    useFallback?: boolean;
    fallbackLoadOptions: UserFetchOptions;
    isDebounceRequired?: boolean;
}
export default function useUserProfile(
    userIds: Array<string>,
    config: UserProfileLoadingOptions
): DynamicUserMap | Array<User> {
    const ids = useDeepEqualState(userIds);
    config = useMemo(() => Object.assign({}, DEFAULT_CONFIG, config), [config]);
    const { idSeqMap, enableSubscription, subscriptionDelay } = config;
    const usersDataCache = useRef<Set<User>>(new Set());
    const service: UserService = useMemo(() => {
        if (config?.service) {
            return config.service;
        }
        if (!config?.enableSubscription) return;
        return UserService.getInstance();
    }, [config]);

    const [userMap, setUserMap] = useState<DynamicUserMap>(
        DynamicUserMap.with(idSeqMap, service)
    );

    const setUserMapWithDebounced = useMemo(() => {
        return debounce((update: SetStateAction<DynamicUserMap>) => {
            return setUserMap(update);
        }, 100);
    }, []);

    if (idSeqMap && userMap?.size !== idSeqMap?.size) {
        userMap.updateSeqMap(idSeqMap);
    }

    let subscriptions = useRef<Map<string, Function>>(new Map());
    const { perf } = useProfiling();
    const timer = useRef<number>(null);

    useEffect(() => {
        function work() {
            const previousIds = Array.from(subscriptions.current.keys());
            const removedIds = difference(previousIds, ids);

            if (removedIds?.length) {
                removedIds.forEach((id) => {
                    let unsubscribe = subscriptions.current.get(id);
                    if (unsubscribe) {
                        unsubscribe();
                        logger.debug('Cancelling subscription', id);
                    }
                    subscriptions.current.delete(id);
                });

                setUserMap((oldMap) => {
                    removedIds.forEach((id) => {
                        oldMap.delete(id);
                    });
                    return DynamicUserMap.clone(oldMap);
                });
            }

            const newIds = difference(ids, previousIds || []);
            if (!newIds.length) {
                return;
            }

            let onChange: (user: User) => void;

            const updateUserWithDataAccumulation = (user: User) => {
                usersDataCache.current.add(user);
                ReactDOM.unstable_batchedUpdates(() => {
                    setUserMapWithDebounced((oldMap) => {
                        for (let user of Array.from(usersDataCache.current)) {
                            oldMap.set(user.id, user);
                        }
                        usersDataCache.current.clear();
                        return DynamicUserMap.clone(oldMap);
                    });
                });
            };

            const updateWithoutAccumulation = (user: User, id: User['id']) => {
                setUserMap((oldMap) => {
                    oldMap.set(id, user);
                    return DynamicUserMap.clone(oldMap);
                });
            };

            newIds.forEach((id) => {
                if (!id || !service) {
                    return;
                }

                onChange = (user) => {
                    if (user) {
                        if (config.isDebounceRequired) {
                            updateUserWithDataAccumulation(user);
                        } else {
                            updateWithoutAccumulation(user, id);
                        }
                    }
                };
                subscriptions.current.set(
                    id,
                    service.subscribe(id, onChange, {
                        useFallback: config.useFallback,
                        fallbackLoadOptions: config.fallbackLoadOptions,
                    })
                );
            });
        }

        if (enableSubscription) {
            timer.current = setTimeout(() => {
                work();
            }, subscriptionDelay);
        }

        return () => {
            if (timer.current && subscriptionDelay > 0) {
                logger.debug('Cancelled pending subscriptions for', ids);
                clearTimeout(timer.current);
                timer.current = null;
            }
        };
    }, [
        ids,
        service,
        config.useFallback,
        config.fallbackLoadOptions,
        enableSubscription,
        subscriptionDelay,
        perf,
        setUserMap,
        config.isDebounceRequired,
        setUserMapWithDebounced,
    ]);

    const clearSubscriptions = useCallback(() => {
        subscriptions.current.forEach((unsubscribe) => {
            unsubscribe();
        });
        subscriptions.current = new Map();
        if (null !== timer.current) {
            clearTimeout(timer.current);
        }
    }, []);

    useEffect(() => {
        return clearSubscriptions;
    }, [clearSubscriptions]);

    const results = useMemo(() => {
        if (config.asArray) {
            let users = [];
            for (let id of ids) {
                users.push(userMap.get(id));
            }
            return users;
        } else {
            return userMap;
        }
    }, [userMap, config.asArray, ids]);

    return results;
}
