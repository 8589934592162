import styled from 'styled-components/macro';
import {
    background,
    border,
    color,
    flexbox,
    grid,
    layout,
    position,
    shadow,
    space,
    system,
} from 'styled-system';

const cursor = system({
    cursor: {
        property: 'cursor',
    },
});

const Box = styled.div(
    {
        boxSizing: 'border-box',
    },
    space,
    color,
    layout,
    flexbox,
    grid,
    background,
    border,
    position,
    shadow,
    cursor
);

export default Box;
